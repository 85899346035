/* Importing the node modules, child components, services and controllers used 
   inside LocationSlider component */
import AppController from "../controller/appController";
import SliderData from "./locationSlider.json";
import GovernmentData from "./governmentSlider.json";

/* LocationSlider Component initialization */
class LocationSlider extends React.Component {
    /* Initializing objects of its LocationSlider class */
    constructor(props) {
        super(props)
        /* LocationSlider Component State variables Initialization */
        this.state = {
            sliderData: SliderData,
            governmentData: GovernmentData
        }
        this.moreInfo = this.moreInfo.bind(this);
    }
    /* It is invoked immediately before mounting occurs */
    componentWillMount() {
        AppController.carousel();
    }
    /* Menu navigation  */
    moreInfo(data) {
        _.assign(data, { amenities_id: '' });
        localStorage.setItem('savedSearch', JSON.stringify(data));
        //    setTimeout(function() { 
        //     this.props.history.push(data.webUrl)
        //    }.bind(this), 50);   
    }
    /* It is invoked to return html content */
    render() {
        const slider = this.props.match.url == "/government-housing" ? this.state.governmentData : this.state.sliderData;
        return (
            <div className="owl-carousel corporate-housing-slider">
                {slider.map((row, index) => {
                    return <div className="item" data-merge="2" key={index}>
                        <img src={row.image} alt="" />
                        <div className="img-details wow fadeInUp" data-wow-delay="0.5s" >
                            <h2 className="mrg-top-50" >{row.title} <i>{row.subTitle}</i></h2>
                            <a href={row.webUrl} style={{ cursor: 'pointer' }} className="img-slide-btn "
                                onClick={() => this.moreInfo(row)} >See All</a>
                            <p className="slider-location">{row.city == "" ? row.country : row.city}, {row.state}</p>
                        </div>
                    </div>
                })}
            </div>
        );
    }
}
export default LocationSlider;