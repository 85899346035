import $ from "jquery";
import CalendarController from "./calendarController";
import moment from 'moment'

import AppController from '../../controller/appController';
import CompanyBookingEmailsService from "../../../services/companyBookingEmailsService";
import companyBookingApiService from "../../../services/companyBookingApis";
import CommunityList from './communityList';
import Booking from "./bookingForm";
import Search from "./search";
import ErrorModel from "./components/errorModal";
import CommunityMoreInfo from "./components/communityMoreInfo";
import MapController from "./components/mapController";
class BookingProcess extends React.Component{
constructor(props){
super(props)
this.state={
    //search
    address: '',
    checkin: '',
    checkout: '',
    roomSizes: [{size:'All',value:0} ,{size:'STU',value:'STU'},{size:'1X',value:'1X'},{size:'2X',value:'2X'},{size:'3X',value:'3X'}],
    searchLoader: false,
    searcherrorMessage: '',
    roomSize: 0,
    addressLat: 0.0,
    addressLng: 0.0,
    communities: [],
    clientTiers: [],
    clientInfo: {},
    ClientPriceRanges: [],
    program: [],
    record_limit: 0,
    radius: 0,
    communitesLoading: false,
    page: 1,
    //booking
    SelectedCommunity:{},
    status:'',
    getInvoiceData: {},
    paymentsummary:false,
    dailyrate:0,
    communityRoomSelected: [],
    communityImages:[],
    index : -1,
    communityMoreInfo:{},
    //Guest
    firstName: '',
    lastName: '',
    emailId: '',
    phoneNo: '',
    phoneCode: '',
    paymentName: '',
    paymentAddress: '',
    paymentCity: '',
    paymentState: '',
    paymentZipCode: '',
    airline: '',
    flight: '',
    ArrivalDate: null,
    ArrivalTime: null,
    arrivalairport: '',
    notes: '',
    cardValidationError: {
        errorcardInfo:0
    },
    submitStatus:0,

    notifyGuest:false,
    bookingLoader:false,
    cardToken:'',
    clientId:24746,
    companyName:'lumentum',
    mapId:0
}
this.setLocation=this.setLocation.bind(this)
this.selectRoomSize=this.selectRoomSize.bind(this)
this.selectRoomSizeOpen=this.selectRoomSizeOpen.bind(this)
this.searchCommunities=this.searchCommunities.bind(this)
this.checkinDate=this.checkinDate.bind(this)
this.checkoutDate=this.checkoutDate.bind(this)
this.pageChange=this.pageChange.bind(this)
this.setValue=this.setValue.bind(this)
this.setNumber = this.setNumber.bind(this);
this.checkInSelection= this.checkInSelection.bind(this);
this.submit= this.submit.bind(this);
this.rateChange= this.rateChange.bind(this);
this.getPhotos = this.getPhotos.bind(this);
this.galleryClose = this.galleryClose.bind(this);
this.GetInvoiceAmount=this.GetInvoiceAmount.bind(this);
this.CardValidation=this.CardValidation.bind(this);
this.handleCheck=this.handleCheck.bind(this);
this.timeSelection=this.timeSelection.bind(this);
this.suiteBookingLoader=this.suiteBookingLoader.bind(this);
this.communityMoreInfo=this.communityMoreInfo.bind(this);
this.mapView=this.mapView.bind(this);
this.streetView=this.streetView.bind(this)
}
async componentWillMount() {
this.datePickerLoad();

   }
async componentDidMount() {
   AppController.ScriptFile();
    await this.getClientProfileDetails();
    
    $('.bedsize .dropdown .dropdown-toggle').html(this.state.roomSize==0? 'All':this.state.roomSize+' <span class="caret"></span>');
     $('.bedsize .dropdown .dropdown-menu #'+this.state.roomSize).addClass("active")
}

// Search Functionality Starts
// Step1
async getClientProfileDetails() { 
    var client= this.state.clientId;
    if (client!='' && client !=undefined && client !=null) {
        const clientId = client;
        const reqObject = {clientid: clientId};
        const profileDetails = await companyBookingApiService.GetCompanyProfileByClientUId(reqObject);
        let SizeRange = []
        _.filter(profileDetails.message.ClientPriceRanges,function(item){
            let Sizecomp = _.lowerCase(item.SuiteSizeCode).replace(" ", "");
            if(Sizecomp.indexOf('1x')>-1 && item.IsActive === true){
                SizeRange['1x'] = {
                    SuiteSizeCode:item.SuiteSizeCode,
                    PriceRangeFrom:item.PriceRangeFrom,
                    PriceRangeTo:item.PriceRangeTo
                }
            }
            if(Sizecomp.indexOf('2x')>-1 && item.IsActive === true){
                SizeRange['2x'] = {
                    SuiteSizeCode:item.SuiteSizeCode,
                    PriceRangeFrom:item.PriceRangeFrom,
                    PriceRangeTo:item.PriceRangeTo
                }
            }
            if(Sizecomp.indexOf('3x')>-1 && item.IsActive === true){
                SizeRange['3x'] = {
                    SuiteSizeCode:item.SuiteSizeCode,
                    PriceRangeFrom:item.PriceRangeFrom,
                    PriceRangeTo:item.PriceRangeTo
                }
            }
            if(Sizecomp.indexOf('stu')>-1 && item.IsActive === true){
                SizeRange['stu'] = {
                    SuiteSizeCode:item.SuiteSizeCode,
                    PriceRangeFrom:item.PriceRangeFrom,
                    PriceRangeTo:item.PriceRangeTo
                }
            }
            
        });
        const clientTiers = profileDetails.message.ClientTiers;
       let activeTires= _.filter(clientTiers,function(item){
        return item.IsActive === true
        })
        await this.setState({clientTiers:activeTires,clientInfo:profileDetails.message.ClientProfileDetails, ClientPriceRanges:SizeRange})
    } 
    else{
        await this.setState({clientTiers:[],clientInfo:{}})
    }   
}
//step2
// Search Community
async searchCommunities(){
    const clientTiers = this.state.clientTiers;
    if(clientTiers) {
        var checkIn = Date.parse(this.state.checkin);
        var checkOut = Date.parse(this.state.checkout);
        const diffDate = AppController.dateDifference(this.state.checkin,this.state.checkout);
        this.setState({communities: [], searchLoader: true, noOfDays: diffDate});
        if (this.state.checkin == '' || this.state.checkout == '' || this.state.address=='') {
            this.setState({searcherrorMessage: 'Please select all fields', communities: [], searchLoader: false});
        } else if(Number(this.formatDate(this.state.checkin)) < Number(this.formatDate(new Date()))){
            this.setState({searcherrorMessage: 'Check in date should be greater than or equal to current date', communities: [], searchLoader: false});
        }else if (checkIn > checkOut) {
            this.setState({searcherrorMessage: 'Check out date should be greater than or equal to check in date', communities: [], searchLoader: false});
        } else {
            this.getMasterAvailability(this.state.checkin, this.state.checkout);
            this.setState({searcherrorMessage: ''});
        }
    }else {
        jQuery(function ($) {
            this.setState({communitesLoading: false,searchLoader: false}); 
            $('#community_master_contactInfo').modal('show');
        }.bind(this)); 
    }
}

async getMasterAvailability(checkIn, checkOut) {
    const clientTiers = this.state.clientTiers;
    if(clientTiers.length > 0) {
        for(var i=0;i<clientTiers.length;i++){
            this.setState({program:clientTiers[i].Program.split(",") , record_limit: clientTiers[i].RecordCount,radius:clientTiers[i].Radius})
            const postObject = {
                "program": clientTiers[i].Program.split(","),
                "checkin": checkIn,
                "checkout": checkOut,
                "record_limit": clientTiers[i].RecordCount,
                "radius": clientTiers[i].Radius
            };
            let responseData = await this.apiRequest(postObject);
            if(responseData.length > 0) {
                await this.getCommunityData(responseData, 0);
                break;
            }else if(i == clientTiers.length - 1){
                this.setState({program:['General'] , record_limit: 0,radius:30})
                const postObjectdata = {
                    "program": ['General'],
                    "checkin": checkIn,
                    "checkout": checkOut,
                    "record_limit": 0,
                    "radius": 30
                };
                let responseDatas = await this.apiRequest(postObjectdata);
                await this.getCommunityData(responseDatas, 0);
            }
        }
    }
    else{
        this.setState({program:['General'] , record_limit: 0,radius:30})
        const postObject = {
            "program": ['General'],
            "checkin": checkIn,
            "checkout": checkOut,
            "record_limit": 0,
            "radius": 30
        };
        let responseData = await this.apiRequest(postObject);
        await this.getCommunityData(responseData, 0);
    }

}
//step4
//Api Hitting
async apiRequest(requestObject) {
    _.assign(requestObject, {
        allowpets: false,
        "latitude": this.state.addressLat,
        "longitude": this.state.addressLng,
        "roomsize" : this.state.roomSize==0 ? '': this.state.roomSize,
        "address":this.state.address
    });
    const Data = await companyBookingApiService.GetAvailableMasters(requestObject)
    return Data;
}
//step5
async getCommunityData(data, communitiesLength, type) {
    if(Object.keys(this.state.ClientPriceRanges).length > 0){
          let _this = this;
          _.filter(data,function(communityItem){
              if(_this.state.ClientPriceRanges['1x'] != undefined){
                  if(communityItem.Cost1x < _this.state.ClientPriceRanges['1x'].PriceRangeFrom || communityItem.Cost1x > _this.state.ClientPriceRanges['1x'].PriceRangeTo){
                  communityItem.Avail1X = 0
                  }
              }
              if(_this.state.ClientPriceRanges['2x'] != undefined){
                  if(communityItem.Cost2x < _this.state.ClientPriceRanges['2x'].PriceRangeFrom || communityItem.Cost2x > _this.state.ClientPriceRanges['2x'].PriceRangeTo){
                      communityItem.Avail2X = 0
                  }
              }
              if(_this.state.ClientPriceRanges['3x'] != undefined){
                  if(communityItem.Cost3x < _this.state.ClientPriceRanges['3x'].PriceRangeFrom || communityItem.Cost3x > _this.state.ClientPriceRanges['3x'].PriceRangeTo){
                  communityItem.Avail3X = 0
                  }
              }
              if(_this.state.ClientPriceRanges['stu'] != undefined){
                  if(communityItem.CostSTU < _this.state.ClientPriceRanges['stu'].PriceRangeFrom || communityItem.CostSTU > _this.state.ClientPriceRanges['stu'].PriceRangeTo){
                  communityItem.STU = 0
                  }
              }
              return communityItem;
          });
     }
    {/** range filter end */}
      
      const Days= AppController.dateDifference(this.state.checkin, this.state.checkout);
      let Cupertino='0A2D899D-3E5B-45D2-88D2-12D4B31CCE11'
      let sanFrancisco ='315D1CA9-D3ED-43C7-8DDE-027F2BFAFD97'
      Cupertino=_.toLower(Cupertino);
      sanFrancisco=_.toLower(sanFrancisco);
      var filteredCommunitys=
       _.filter(data, function(o) { 
           const size=(o.Avail1X!=0 ||  o.Avail2X!=0 || o.Avail3X!=0 || o.STU!=0)
           const Citys=(o.city_uid != Cupertino && o.city_uid != sanFrancisco)
           if(Days<=30){
              return (size&&Citys); 
           }
           else{
              return (size); 
           }
          });
      const filterCommunityData =_.orderBy(filteredCommunitys, ['distance'], ['asc']); 
      for (var i = 0; i < filterCommunityData.length; i++) {
          const datas = {
              address: filterCommunityData[i].address,
              latitude: filterCommunityData[i].latitude,
              longitude: filterCommunityData[i].longitude
          };
          filterCommunityData[i]['walkScore'] = "";
          filterCommunityData[i]['recordlimit'] = this.state.record_limit;
          filterCommunityData[i]['radius'] = this.state.radius;
          filterCommunityData[i]['program'] = this.state.program;
          filterCommunityData[i]['clientInfo'] = AppController.encodeData(JSON.stringify(this.state.clientInfo));
              await this.setState({ communities: _.concat(this.state.communities, filterCommunityData[i])});
              if (filterCommunityData.length>0) {
                this.getwallsckore(datas, communitiesLength + i)
              }   
                         if (filterCommunityData.length == (i + 1)) {
                  await this.setState({communitesLoading: false,searchLoader: false
                      });
              }
      }
      if(filterCommunityData.length>0){
        var datatt={
            hide:'#amenitiesModel',
            top:'#communityScroll'
        }
        this.scrollAction(datatt)
      }else{
          jQuery(function ($) {
              this.setState({communitesLoading: false,searchLoader: false}); 
              $('#community_master_contactInfo').modal('show');
          }.bind(this));
      }   
      $('.range-labels li').removeClass("selected")
      $('.range-labels').find('li').removeClass('active');
      $('#'+this.state.roomSize+'-range-labels').addClass('selected active');
  }
// Googel Auto complete 
setLocation(){
   let _this=this
            var places = new google.maps.places.Autocomplete(document.getElementById('booking_address'));
            google.maps.event.addListener(places, 'place_changed', function () {
            var place = places.getPlace();
            _this.setState({address: places.getPlace().formatted_address,
                            addressLat: place.geometry.location.lat(),
                            addressLng: place.geometry.location.lng()});
                });
            if ($("#booking_address").val() == '') {
                _this.setState({address: ''});
            }
}
// Room Size Selection
selectRoomSize(valueData, e){
    $('.bedsize .dropdown .dropdown-menu li').removeClass("active")
    $('.bedsize .dropdown .dropdown-toggle').html(e.target.text+' <span class="caret"></span>');
    $('.bedsize .dropdown .dropdown-menu #'+valueData).addClass("active")
    var object = {};
    object['roomSize'] = valueData;
    this.setState(object);
}
selectRoomSizeOpen(){
    setTimeout(function() {
        $('.bedsize .dropdown').addClass('open');
        $(".bedsize .dropdown .dropdown-toggle").attr("aria-expanded","true");
}.bind(this),10);
}
formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('');
}

async checkinDate(){
    await this.setState({checkin: '', checkout: ''})
    await CalendarController.checkInCalendar(this, -1, this.state.checkin != ''
    ? this.state.checkin
    : "")
    await CalendarController.checkOutCalendar(this, -1, this.state.checkout != ''
    ? this.state.checkout
    : "")
}
async checkoutDate(){
    await this.setState({checkout: ''})
    await CalendarController.checkOutCalendar(this, 1, this.state.checkin != ''
    ? this.state.checkin
    : "")
    await CalendarController.checkInCalendar(this, 1, this.state.checkout != ''
    ? this.state.checkout
    : "")
}
async getwallsckore(data, index) {
    const walkscore = await AppController.wallsckoreFun(data);
    let updatedPosts = update(this.state.communities, {
        [index]: {
            walkScore: {
                $set: walkscore.walkscore
            }
        }
    });
    await this.setState({communities: updatedPosts});
}
scrollAction(data){
    jQuery(function () {
        $('html, body').animate({
            scrollTop: $(data.top).offset().top}, 1000);
    }.bind(this))
}
datePickerLoad(){
    const _this = this
    $(document).ready(function () {
    jQuery('input[name="checkinrange"]').daterangepicker({
        locale: {
        format: 'MM/DD/YYYY',
        
  }, autoUpdateInput: false, minDate:_this.state.checkin!="" ? moment(_this.state.checkin) : moment()},
        function(start, end, label) {
            _this.setState({resDate: start.format('MM/DD/YYYY') + ' - ' + end.format('MM/DD/YYYY'),
            checkin:start.format('MM/DD/YYYY'),
            checkout:end.format('MM/DD/YYYY') })  
    });
});
}
CountryCodeLoad(){
    $(document).ready(function () {
        jQuery(function ($) {
            $('[data-toggle="tooltip"]').tooltip()
                $("#booking_phone").intlTelInput();
        });
        
    });
}
//Form 
async setValue(field, e) {
    var object = {};
    object[field] = e.target.value;
    this.setState(object);
}
setNumber(field, e) {
    const re = /^[0-9\b]+$/;
    if (e.target.value == '' || re.test(e.target.value)) {
        jQuery(function ($) {
            var countryData = $("#booking_phone").intlTelInput("getSelectedCountryData");
            this.setState({
                phoneNo: e.target.value,
                phoneCode: countryData.dialCode
            })
        }.bind(this));
    }
}
async checkInSelection(date) {
    await this.setState({
        ArrivalDate: moment(date)
    });
}
async timeSelection(date, e) {
    await this.setState({
        ArrivalTime: moment(date)
    });
}
handleCheck() {
    this.setState({
        notifyGuest: !this.state.notifyGuest
    });
}
async pageChange(data,row){
    await this.setState({page:row,SelectedCommunity:data});
    var Req={
    top:row==1?'#communityScroll':'#bookingScroll'
}
await this.scrollAction(Req)
if(row==2){
    await this.GetInvoiceAmount(data,this.state.communityRoomSelected)
}
this.datePickerLoad();
}
async GetInvoiceAmount(community,roomData) {
    await this.CountryCodeLoad();
    this.setState({paymentsummary:true})
    let dailyrent = 0;
    var roomsizebycommunity = _.find(roomData, function (o) { return o.community_id == community.community_id; });

    if (roomsizebycommunity != undefined) {
        dailyrent = roomsizebycommunity.adminDailyRates;
        await this.setState({status:roomsizebycommunity.BookedType,dailyrate:roomsizebycommunity.adminDailyRates})
    }
     else if (community.STU != 0) {
        dailyrent = community.CostSTU;
        this.setState({status:'STU',dailyrate:community.CostSTU})
    } else if (community.Avail1X != 0) {
        dailyrent = community.Cost1x;
        this.setState({status:'1X',dailyrate:community.Cost1x})
    } else if (community.Avail2X != 0) {
        dailyrent = community.Cost2x;
        this.setState({status:'2X',dailyrate:community.Cost2x})
    } else if (community.Avail3X != 0) {
        dailyrent = community.Cost3x;
        this.setState({status:'3X',dailyrate:community.Cost3x})
    }
    let getAmountObject = {
        "moveindate": moment(this.state.checkin).format('MM/DD/YYYY'),
        "moveoutdate": moment(this.state.checkout).format('MM/DD/YYYY'),
        "dailyrent": dailyrent,
        "city_uid": community.city_uid
    }
    let getInvoiceData = await companyBookingApiService.GetInvoiceAmount(getAmountObject);
    if (getInvoiceData.error == 0) {
        await this.setState({ getInvoiceData: getInvoiceData.message ,paymentsummary:false,});
    }
}

async rateChange(rate,row, BookedType){
    const communityIndex = _.findIndex(this.state.communityRoomSelected, function(o) { return o.community_id == row.community_id;});
    const communityRateIndex = _.findIndex(this.state.communityRoomSelected, function(o) { return (o.community_id == row.community_id && o.BookedType == BookedType);  });
    const dailyRate= rate 
    await this.setState({communityRoomSelected: communityIndex<0? this.state.communityRoomSelected : update(this.state.communityRoomSelected, {$splice: [[communityIndex, 1]]})})
    await this.setState({communityRoomSelected: communityRateIndex<0? _.concat(this.state.communityRoomSelected, {adminDailyRates: dailyRate, status: false, community_id: row.community_id, BookedType}) : this.state.communityRoomSelected})
}
async getPhotos(commImages, ind){
        this.setState({index: ind});
        await this.setState({communityImages:commImages});
        await AppController.travelBooking();
    }
async galleryClose(){
        await this.setState({index: -1});
        await this.setState({communityImages: []});
       }
async CardValidation(filed, minLen, maxLen=0) {
        let cardValidationError = this.state.cardValidationError;
        let errorcardInfo = 0;
        
        if(filed == 'firstName' || filed == 'lastName' || filed == 'paymentName' || filed == 'paymentAddress' || filed == 'paymentCity' || filed == 'paymentState' || filed == 'paymentCity' || filed == 'paymentCity' || filed == 'paymentCity' || filed == 'paymentCity'){
            if(this.state[filed].toString().length < minLen){
                errorcardInfo = 1;
                cardValidationError[filed] = 1;
            }else{
                cardValidationError[filed] = 0;
            }
        }
        if(filed == 'phoneNo'){
            if(this.state.phoneNo.toString().length > maxLen || this.state.phoneNo.toString().length < minLen){
                errorcardInfo = 1;
                cardValidationError.phoneNo = 1;
            }else{
                cardValidationError.phoneNo = 0;
            }
        }
        if(filed == 'emailId'){
            if(this.state.emailId!=""){
                var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
                if (!reg.test(this.state.emailId) ) {
                errorcardInfo = 1;
                cardValidationError.emailId = 1;
                }else{
                    cardValidationError.emailId = 0;
                }
            }else{
                cardValidationError.emailId = 0;
            }
        }
        if(filed == 'paymentZipCode'){
            if((this.state.paymentZipCode.toString().length > 7 || this.state.paymentZipCode.toString().length < 4)){
                errorcardInfo = 1;
                cardValidationError.paymentZipCode = 1;
            }else{
                cardValidationError.paymentZipCode = 0;
            } 
        }
        cardValidationError.errorcardInfo = errorcardInfo;
        await this.setState({ cardValidationError: cardValidationError });
    }     
    async suiteBookingLoader() {
        this.setState({bookingLoader: true})
    }
async submit(cardToken){
    if (cardToken == undefined) {
        await this.setState({submitStatus:1 ,});
        this.suiteBooking()
    }
    else {
        await this.setState({ cardToken: cardToken, submitStatus:1 });
        this.suiteBooking()
    }
}
async suiteBooking(){
    const _this = this
    var validationFields = []
    validationFields.push('firstName', 'lastName', 'emailId', 'phoneNo','paymentName', 'paymentAddress', 'paymentCity', 'paymentState', 'paymentZipCode', 'GoogleCaptcha')
    let error = 0;
    _.forEach(validationFields, function (value) {
        if (_this.state[value] == "") {
            error++;
        } 
    });

    if (error == 0 && this.state.cardValidationError.errorcardInfo == 0) {
        this.setState({errorMessage:''})
        if((this.state.cardToken != undefined && this.state.cardToken != "")){
            const CountryCode = this.state.phoneCode == '' ? "+1" : "+"+this.state.phoneCode
            const phonenumber = AppController.formatPhoneNumber(this.state.phoneNo == '' ? '' : this.state.phoneNo)
            const bookingRequest = {
                //Guest Data
                checkin:  moment(this.state.checkin).format('MM-DD-YYYY'),
                checkout: moment(this.state.checkout).format('MM-DD-YYYY') ,
                resident_firstname: this.state.firstName == '' ? '' : this.state.firstName,
                resident_lastname: this.state.lastName == '' ? '' : this.state.lastName,
                resident_middlename: '',
                resident_email: this.state.emailId == '' ? '' : this.state.emailId,
                resident_phone: phonenumber,
                countrycode: CountryCode,
                airline: this.state.airline,
                flight: this.state.flight,
                arrivalairport: this.state.arrivalairport,
                ArrivalDate: this.state.ArrivalDate == '' ? '' : moment(this.state.ArrivalDate).format('MM/DD/YYYY')+ ' '+ (this.state.ArrivalTime != null ? moment(this.state.ArrivalTime).format("HH:mm:ss"):"") ,
                travelnotes: this.state.notes == '' ? '' : this.state.notes,
                billingaddress: this.state.paymentAddress == '' ? '' : this.state.paymentAddress,
                billing_city: this.state.paymentCity == '' ? '' : this.state.paymentCity,
                billing_state: this.state.paymentState == '' ? '' : this.state.paymentState,
                billing_zip: this.state.paymentZipCode == '' ? '' : this.state.paymentZipCode,
                ArrivalTime:moment(this.state.ArrivalTime).format("HH:mm:ss"),
                //community
                community_id: this.state.SelectedCommunity.community_id,
                communityName: this.state.SelectedCommunity.community_name,
                city_name:this.state.SelectedCommunity.city_name,
                communityAddress:this.state.SelectedCommunity.address,
                radius: this.state.SelectedCommunity.radius,
                record_limit: this.state.SelectedCommunity.recordlimit,
                latitude: this.state.SelectedCommunity.latitude,
                longitude: this.state.SelectedCommunity.longitude,
                program:  this.state.SelectedCommunity.program,
                Checkouttime: "10:00 AM",
                checkintime: "11:59 PM" ,
                dailyrate: Number(this.state.dailyrate),
                roomsize: this.state.status,
                //Card and Amount
                subToltal : Number(this.state.getInvoiceData.InvoiceAmount).toFixed(2),
                tax: Number(this.state.getInvoiceData.TotalTax).toFixed(2),
                invoiceamount : Number(this.state.getInvoiceData.TotalInvoiceAmount).toFixed(2),
                sourcetokenid : this.state.cardToken,
                clientId: this.state.clientId,
                //Reserved By
                source: 'AGE',
                changedby: this.state.emailId,
                reservedby: "Ashok K",
                reservedbyemail: this.state.emailId, 
                todays: false ,
                notify:this.state.notifyGuest,
                phoneNo: this.state.phoneNo == '' ? '' : CountryCode + this.state.phoneNo,
            } 
            this.orderCreation(bookingRequest);
        }
        else{
            this.errorMessage({'message':"Invalid card details"});
        }
    }
    else{
            this.errorMessage({'message':'Please Enter Required Fields'})
    }

}
async orderCreation(bookingRequest){
    const response= await companyBookingApiService.CreateStripeOrder(bookingRequest);
    if(response.error=="0"){
        this.mailInformation(response,bookingRequest);
    }
    else{
         location.href='/company/'+this.state.companyName+'/company_booking_orderFailed'
        
    }
}
async mailInformation(response,Request){
    if (response.orderId != '' && response.orderstatus == "QUOTE") {
        Request['OrderId'] = Number(response.orderId)
        Request['mail'] = 'SuiteBooking'
        await CompanyBookingEmailsService.companyBooking(Request)
        location.href='/company/'+this.state.companyName+'/company_booking_quote'
       
    } else {
            Request['OrderId'] = Number(response.orderId)
            Request['mail'] = 'SuiteBooking'
            Request['guestEmail'] = AppController.encodeData(this.state.emailId)
            await CompanyBookingEmailsService.companyBooking(Request)
            localStorage.setItem('guestEmail',AppController.encodeData(this.state.emailId))
            location.href='/company/'+this.state.companyName+'/company_booking_guestsuccess'
    }
}
errorMessage(paymentAuthentication){
    this.scrollAction({
        top:'#bookingScroll'
    });
    this.setState({
        bookingLoader: false,
        errorMessage:paymentAuthentication.message
    }) 
}

communityMoreInfo(row){
    jQuery(function ($) {
        $('#communitymoreinfo').modal('show');
    }.bind(this));
    this.setState({communityMoreInfo:row})
}
streetView() {
    const _this = this;
        setTimeout(function () {
            MapController.streetView(_this.state.communityMoreInfo);
        }, 500);
}
async mapView(){
    await this.setState({mapId: this.state.mapId + 1});
	await MapController.loadMapData(this.state.communityMoreInfo, this, 'https://s3-us-west-1.amazonaws.com/suiteam-website/Website-Images/SuiteAmerica-M' +'ap-Marker-Red.png');
}

render(){
const {communities}=this.state;
return(
<div>
{this.state.page==1?
<div>
   <Search
      {...this.state}
      setLocation={this.setLocation}
      checkinDate={this.checkinDate}
      checkoutDate={this.checkoutDate}
      selectRoomSize={this.selectRoomSize}
      selectRoomSizeOpen={this.selectRoomSizeOpen}
      searchCommunities={this.searchCommunities}
      />
   <div id="communityScroll">
      {communities.length==0 || communities==undefined ? "":
      <CommunityList
         {...this.state}
         communities={communities}
         pageChange={this.pageChange}
         rateChange={this.rateChange}
         getPhotos={this.getPhotos}
         galleryClose={this.galleryClose}
         communityMoreInfo={this.communityMoreInfo}
        
         />
      }
   </div>
</div>
:
<Booking  
{...this.state}
submit={this.submit}
setValue={this.setValue} 
setNumber={this.setNumber}
pageChange={this.pageChange} 
handleCheck={this.handleCheck}
timeSelection={this.timeSelection}
checkInSelection={this.checkInSelection}
GetInvoiceAmount={this.GetInvoiceAmount}
CardValidation={this.CardValidation}
suiteBookingLoader={this.suiteBookingLoader}
community={this.state.SelectedCommunity}
/>
}
<ErrorModel/>
<CommunityMoreInfo community={this.state.communityMoreInfo}  streetView={this.streetView}
         mapView={this.mapView}/>
 </div>
)
}
}
export default BookingProcess;