const Search=(props)=>{
const {
    address,
    checkin,
    checkout,
    calendarDisable,
    searcherrorMessage,
    roomSizes,
    searchLoader} = props;
return(
    <div className="fullslider">
    <div className="tb-slide-content">
       <h1>Reserve Your Stay</h1>
       <p style={{color: 'red'}}>{searcherrorMessage}</p>
       <form className="flex-form">
          <div className="flex-form-item search-input">
             <input id="booking_address" onChange={props.setLocation} type="search" className="form-control iconed" placeholder="City, Address, or Landmark" defaultValue={address} />
             <div className="info-field-icon "><a><i className="fa fa-map-marker" ></i></a></div>
          </div>
          <div className="flex-form-item">
             <input type="text" style={{backgroundColor: calendarDisable? '#eee': '#FFF'}} className="form-control" name="checkinrange" autoComplete="off" value={checkin} placeholder="Check In" disabled={calendarDisable} onChange={props.checkinDate}/>
             <div className="info-field-icon"><a><i className="fa fa-calendar" id="CheckInRange" ></i></a></div>
          </div>
          <div className="flex-form-item">
             <input type="text" style={{backgroundColor: calendarDisable? '#eee': '#FFF'}}  className="form-control" name='checkout' autoComplete="off" value={checkout}  placeholder="Check Out" disabled={true} onChange={props.checkoutDate}/>
             <div className="info-field-icon"><a><i className="fa fa-calendar" id="CheckOutRange"></i></a></div>
          </div>
          <div className="flex-form-item bedsize">
             <div className="dropdown">
                <button className="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown" disabled={(calendarDisable)? true : false }>
                Room Size
                </button>
                <ul className="dropdown-menu">
                   {roomSizes.map((row,index)=>{
                   return(
                   <li id="1" key={index}>
                      <a onClick={(e)=>props.selectRoomSize(row.value, e)}>{row.size}</a>
                   </li>
                   )
                   })}
                </ul>
             </div>
             <div className="info-field-icon" onClick={calendarDisable? null : props.selectRoomSizeOpen}><a><i className="fa fa-bed" ></i></a></div>
          </div>
          <div className="flex-form-item search-btn"><button type="button" className="btn btn-danger" onClick={(calendarDisable || searchLoader)? null : ()=> {props.searchCommunities()}}>{searchLoader?<span><i className="fa fa-spinner fa-spin" style={{fontSize: '16px'}}/></span>  : "Search" }</button></div>
       </form>
    </div>
 </div>
)
}
export default Search;
