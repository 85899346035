import React, { Component } from 'react'
import GuestHeader from '../common/guestHeader';
import SubFooter from "../common/subFooter";
import Footer from '../common/footer';
import ReserveYourStay from '../common/reserveYourStay.js';

export class insuranceLanding extends Component {
    render() { 
        return (
            <div>
                <div className="Insurance-Landing-Page">
                <GuestHeader/>
        <div className="banner-view">
          <img src="https://suiteam-website.s3-us-west-1.amazonaws.com/Website-Images/FamilyBoxExtended.png" alt="" width="100%" />
          <div className="content">
            <h1 className="h1">Temporary Housing Solutions</h1>
            <h2 className="h2">SuiteAmerica cares.</h2>
            <p className="p">If you have been affected by a natural disaster or are a member of a catastrophe team,
              SuiteAmerica's
              temporary housing will get you settled quickly.</p>
          </div>
        </div>
        <ReserveYourStay />
        <div className="center-view">
          <h1 className="h1">Agile Solutions &amp; Superior Service</h1>
          <p>More than just a fast solution, SuiteAmerica's fully-furnished apartments come with 24/7 guest service,
            basic utilities, grocery packages, and housekeeping.</p>
          <p>You have important things to worry about. Your temporary housing should not be one of them.</p>
          <h2>The solution is simple:</h2>
          <h3>SuiteAmerica's fully-furnished and fully-serviced apartments.</h3>
        </div>
        <div className="bottom-view">
          <h1>We can help.</h1>
          <div className="box">
            <div className="item">
              <h2><img src="assets/images/insuranceLanding/ILP_Family.png" className="fa fa-users" /> For Policyholders:</h2>
              <p><span>Efficient:</span> Get into temporary housing quickly.</p>
              <p><span>Proximity:</span> We'll secure your temp housing close to your location.</p>
              <p><span>Prepared:</span> Fully-furnished apartments, including basic utilities, housekeeping
                options, and grocery starter packages.</p>
              <p><span>Accessible:</span> SuiteAmerica's 24/7 Guest Experience Team will answer any questions
                that might come up.</p>
              <p><span>Flexible:</span> Billing options include direct billing to either the homeowner or
                insurance company.</p>
            </div>
            <div className="item">
              <h2><img src="assets/images/insuranceLanding/ILP_Catas.png" className="fa fa-medkit" /> For Catastrophe Teams:</h2>
              <p><span>Experienced:</span> SuiteAmerica's has been in the temporary housing business for 30
                years.</p>
              <p><span>Dedicated:</span> Our team will take care of your lodging needs easily and efficiently.
              </p>
              <p><span>Industry-Leading Technology: </span>Online booking platform allows easy, quick booking
                by bedrooms needed.
              </p>
              <p><span>Ready:</span> Fully-furnished suites, prepared with bathroom, kitchen, laundry, and
                grocery starter kits.</p>
              <p><span>24/7:</span> Our teams are accessible whenever you need us. We're here to serve.</p>
            </div>
          </div>
          <h4>We care. That's it. We care about you and <span>your family. We're here to serve you. </span> </h4>
        </div>
        <SubFooter/>
        <Footer/>
      </div>
            </div>
        )
    }
}

export default insuranceLanding
