import Footer from "../newinterface/footer";
import RequestCallback from "../newinterface/requestCallback";
import BlogHeader from "../newinterface/blogHeader";
import OwlCarousel from "react-owl-carousel";
import managersData from "./managersData";
import { Redirect } from "react-router-dom";
import {
  BaseModal,
  PricingModal,
  NewAccountModal,
  MeetingModal,
  FaqsModal,
  LocationsModal,
  FunfactsModal,
  ThankYouModal,
} from "./popups";
import Typed from "typed.js";

class Manager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "",
    };
    this.managerName = this.props.match.params.managerName.toLocaleLowerCase();
    this.managerInfo = managersData[this.managerName];
    console.log(this.managerName, this.managerInfo);
    this.typed = null;
    this.handleServiceRequest = this.handleServiceRequest.bind(this);
    this.closePopupWithThankYou = this.closePopupWithThankYou.bind(this);
    this.closePopupWithOutThankYou = this.closePopupWithOutThankYou.bind(this);
  }

  async componentDidMount() {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };

    this.typed = new Typed(document.getElementById("typewrite-wrap"), {
      strings: [
        "Relocation Managers ",
        "Interns &amp; Groups ",
        "Traveling Employees ",
        "Government ",
        "Mobility Managers ",
        "HR",
      ],

      typeSpeed: 50,
      loop: true,
      backSpeed: 50,
    });

    $("#baseModal-micheal").on(
      "hidden.bs.modal",
      function () {
        this.closePopupWithOutThankYou();
      }.bind(this)
    );
  }

  componentWillUnmount() {
    this.typed?.destroy();
  }

  async closeWelcomeModal() {
    await jQuery(function ($) {
      $("#welcomeMessage").removeClass("show");
    });

    await localStorage.setItem("shownModal", true);
  }

  async openPropertySearch(type) {
    // let city = type == 'Bay Area' ? 'Mountain View' : type
    await localStorage.setItem("city", type);
    location.href = "/locations";
  }

  handleServiceRequest(event) {
    let tabName = event.target.id;
    this.setState({
      activeTab: tabName,
    });
    if (tabName === "pricing" || tabName === "funFacts") {
      $("#baseModal-micheal").modal("show");
    } else if (tabName === "housingRequest") {
      this.openRequestCallBack();
    } else if (tabName === "meeting") {
      let linkUrl = this.managerInfo.meetingLink;
      window.open(linkUrl, "_blank");
    }
  }

  openRequestCallBack() {
    $("#sideQuote").addClass("active");
    $(".overlay").addClass("active");
  }

  closePopupWithThankYou() {
    this.setState({ activeTab: "thankyou" });
    setTimeout(() => {
      $("#baseModal-micheal").modal("hide");
      this.setState({ activeTab: "" });
    }, 5000).bind(this);
  }

  closePopupWithOutThankYou() {
    this.setState({ activeTab: "" });
  }

  render() {
    let welcomeModal = localStorage.getItem("shownModal");

    if (!(this.managerName in managersData)) {
      return <Redirect to="/no-match-found" />;
    } else {
      return (
        <div className="SAweb_updateUi">
          {welcomeModal == 12 && (
            <div
              id="welcomeMessage"
              className="modal welcomeVideoModal show"
              tabindex="-1"
              role="dialog"
            >
              <div className="overlay"></div>
              <div
                className="modal-dialog modal-lg"
                role="document"
                data-aos="fade-down"
              >
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="welcomeVideoGIF">
                      <button
                        onClick={this.closeWelcomeModal}
                        type="button"
                        className="close btn"
                      >
                        <i className="bi bi-x"></i>
                      </button>
                      <div className="">
                        <img
                          src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/SAWeblogo/salogosmulti_logo_gif.gif"
                          width="100%"
                        />
                      </div>
                      <p>We're excited to show you the new SuiteAmerica. </p>
                      <p>
                        Learn more about our brand identity{" "}
                        <a href="/new-brand-identity" target="blank">
                          here.
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <BlogHeader />
          <div className="overlay sideQuote"></div>

          <section className="SAwebour_commitment text-white text-center py-5">
            <div className="container">
              <h3 className="breakPoint mt-3">
                Our commitment - to make every experience a memorable one.
              </h3>
              <div className="box mt-5 mb-3">
                <div className="item">
                  <div className="image">
                    <img
                      src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/star-w.png"
                      width="40px"
                      alt=""
                    />
                  </div>
                  <p>30+ years of Excellence</p>
                </div>
                <div className="item">
                  <div className="image">
                    <img
                      src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/World_Beating_Tech_W.svg"
                      width="40px"
                      alt=""
                    />
                  </div>
                  <p>Award Winning Technology</p>
                </div>
                <div className="item">
                  <div className="image">
                    <img
                      src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/Elegant_Properties_W.svg"
                      width="30px"
                      alt=""
                    />
                  </div>

                  <p>170+ Elegant Properties</p>
                </div>

                <div className="item">
                  <div className="image">
                    <img
                      src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/Confirmed_Stays_W.svg"
                      width="31px"
                      alt=""
                    />
                  </div>
                  <p>1 Million + </p>
                  <p>Stays</p>
                </div>
              </div>
            </div>
          </section>

          <section className="sa-home-text-animation">
            <div className="container">
              <h2 className="mb-4 text-left">
                <a
                  href=""
                  className="typewrite"
                  // data-period="2000"
                  // data-type='[ "Now Serving ", "Relocation Managers ", "Interns & Groups ", "Traveling Employees ", "Government ", "Mobility Managers ", "HR" ]'
                >
                  Serving{"  "}{" "}
                  <span className="wrap" id="typewrite-wrap"></span>
                </a>
              </h2>

              <h3>SuiteAmerica is the corporate housing leader.</h3>
              <p>
                Our unwavering dedication to *exemplary* service is simply
                unmatched. Award-winning proprietary technology sets us apart
                from the rest.
              </p>
              <h3 className="mt-4">
                Ready to provide your team with absolutely stellar guest
                experiences?
              </h3>
              <p>We're here.</p>
              <div className="user-section">
                <h2 className="mt-5 text-left">
                  Hello, I'm {this.managerInfo.name}.{" "}
                  <span className="heading-text-break"></span> How can I assist
                  you?
                </h2>
                <img src={this.managerInfo.coverImageUrl} alt="" width="100%" />
              </div>

              <ul className="flowBtns" onClick={this.handleServiceRequest}>
                <li>
                  <div id="pricing">Get General Pricing</div>
                </li>
                <li>
                  <div id="housingRequest">Submit Housing Request</div>
                </li>
                <li>
                  <div id="meeting">Set a Meeting</div>
                </li>

                <li>
                  <div id="funFacts">Fun Facts About Me</div>
                </li>
              </ul>
            </div>
          </section>

          <RequestCallback managerEmail={this.managerInfo.email} />
          <BaseModal>
            {this.state.activeTab === "pricing" ? (
              <PricingModal
                closePopupWithThankYou={this.closePopupWithThankYou}
                managerInfo={this.managerInfo}
              />
            ) : null}
            {this.state.activeTab === "funFacts" ? (
              <FunfactsModal managerInfo={this.managerInfo} />
            ) : null}
            {this.state.activeTab === "thankyou" ? (
              <ThankYouModal managerInfo={this.managerInfo} />
            ) : null}
          </BaseModal>

          <Footer />
        </div>
      );
    }
  }
}
export default Manager;
