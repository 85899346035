const StayGoogleFrugal = props => {
  return (
    <div className="googlec banner googlestay">
     <p className="backbtn"  onClick={()=>props.pageChanges(0)}>Back</p>
      <div className="container">
        <h1>
          Stay
          <span><ul className="google_clr_list"><li>G</li><li>o</li><li>o</li><li>g</li><li>l</li><li>e</li></ul> </span>
           Frugal
          <sub>Without Compromising Your Needs</sub>
        </h1>
        <div className="mrg-top-40">
          <img
            className="img-responsive"
            src={s3BucketUrl+"landing-images/Google/Group+17689.png"}
          />
        </div>
        <div className="googlstay_bannertext">
          <p>
            Googlers get special rates when staying with SuiteAmerica —&gt;
            we will offer a discounted rate of $5/day off for Google travel bookings for
            a limited time
          </p>
        </div>
      </div>
    </div>
  );
};
export default StayGoogleFrugal;
