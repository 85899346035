/* Importing the node modules, child components, services and controllers used 
   inside DiverScheduleMap component */
import MapController from "../../../controller/mapController";
/* DiverScheduleMap Component initialization */
class DiverScheduleMap extends React.Component {
  /* Initializing objects of its DiverScheduleMap class */
  constructor(props) {
    super(props)
    /* DiverScheduleMap Component State variables Initialization */
    this.state = {
      activeIndex: 0,
      ordersData: props.ordersData,
      activeMarker: {},
      selectedPlace: {},
      showingInfoWindow: false,
      localSearchData: false
    }
    this.onMarkerClick = this.onMarkerClick.bind(this);
    this.onMapClicked = this.onMapClicked.bind(this);
  }
  /* It is invoked immediately after a component is mounted */
  async componentDidMount() {  
    await MapController.initMap(this.state.ordersData, 'scheduleMap');
  }
  /* To update the state in response to prop changes */
  async componentWillReceiveProps(nextProps) {
    await this.setState({ordersData: nextProps.ordersData})
    await MapController.initMap(this.state.ordersData);
  }
  /* Marker selection on google maps */
  onMarkerClick(props, marker, e) {
    this.setState({selectedPlace: props, activeMarker: marker, showingInfoWindow: true});
  }
  /* Click event on google map */
  onMapClicked(props) {
    if (this.state.showingInfoWindow) {
      this.setState({showingInfoWindow: false, activeMarker: null})
    }
  }
  /* To fetch the places */
  fetchPlaces(mapProps, map) {
    const {google} = mapProps;
    const service = new google.maps.places.PlacesService(map);
  }
  /* To change the index*/ 
  changeIndex(index) {
    this.setState({activeIndex: index});
  }
  /* It is invoked to return html content */
  render() {

    return (
      <div id="pages_5star">

        <div className="container">

          <div className="schedule-mapview-wrap">
            <div className="lc_map">
              {this.state.ordersData.length > 0
                ? <div id={'map'} className="map-alignment"></div>
                : null}
            </div>
          </div>

        </div>

      </div>

    );
  }
}
export default DiverScheduleMap;