/* Importing the node modules, child components, services and controllers used 
   inside adobeOnlineManagement component */    
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import AdobeHeader from "./common/adobeHeader";
   import FAQ from "./common/faq";
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   /* adobeOnlineManagement Component initialization */
   class AdobeOnlineManagement extends React.Component {
       /* Initializing objects of its adobeOnlineManagement class */
       constructor(props) {
           super(props);
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
           MainController.getInitialInformation();
        }
       /* It is invoked to return html content */
       render() {
           return (
            <div className="corporateHousing_Updates">
                <GuestHeader/>
                <header className="codeconveyHeader cc_image_text_main corporate-h">
                    <img className="img-responsive" src={"https://suiteam-website.s3-us-west-1.amazonaws.com/landing-images/Adobe/Adobe_building.jpg"} width="100%" alt=""  />
                    <div className="wow fadeInUp img-on-text top30 width600 text-center left50 img-on-text-center" data-wow-delay="0.5s">
                        <h1>ONLINE MANAGEMENT</h1>
                    </div>
                </header>
                <AdobeHeader />
                <div className="quote-section bg-white company_p">
                    <div className="container">
                        <div className="text-center col-md-8 col-md-offset-2 wow fadeInUp" data-wow-delay="0.5s">
                            <h2>SUITEAMERICA HAS DESIGNED OUR 
                            WEBSITE WITH YOU IN MIND!
                            </h2>
                            <p>With our new client portal, you have access to all your SuiteAmerica details including
                            reservation dates, suite address, phone number, and parking information. View a map with 
                            directions directly to your suite, submit service requests and check the status of any
                            submitted service requests. All with just a single touch.
                            </p>
                        </div>
                        <div className="clearfix"></div>
                        <div className="company_p mrg-top-50">
                            <div className="">
                            <div className="row">
                                <div className="col-md-6 col-sm-6 col-sm-offset-3">
                                    <div className="dual_quote dual_quote_list wow fadeInUp" data-wow-delay="0.5s">
                                        <h3>HERE’S WHAT YOU CAN DO:</h3>
                                        <ul>
                                        <li>View your suite information</li>
                                        <li>Submit and track service requests</li>
                                        <li>Customize your stay with custom amenities from our online store</li>
                                        <li>Explore your new area and find things to do</li>
                                        <li>Receive local deals and discount</li>
                                        </ul>
                                       
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid bg-grey">
                    <div className="container">
                        <FAQ />
                    </div>
                </div>
                <SubFooter/>
                <Footer/>
            </div>   
           );
       }
   }
   export default AdobeOnlineManagement;