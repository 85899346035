import React, { Component} from 'react'
import Footer from '../../common/footer'
import GuestHeader from '../../common/guestHeader'
import SubFooter from '../../common/subFooter'
import SignaturePad from 'react-signature-canvas';


export class Signature extends Component {
    constructor(props) {
        super(props)
        this.sigCanvas = React.createRef();
        this.state = {
            imageURL: '',
            setImageURL: ''
        }
    }



    

    clear() {
        this.sigCanvas.current.clear();
    }

    save() {
        const sigCanvas = this.sigCanvas.current

        if (sigCanvas) {
            this.setState({ setImageURL: sigCanvas.getTrimmedCanvas().toDataURL("image/png") });
        }


    }


    render() {
       
        return (
            <div>
                <GuestHeader />
                <div>
                    <div className="guest-portalnewpage">
                        <div className="confirmation">
                            <div className="container" >

                                <div style={{ backgroundColor: "#fff", marginTop: "10px", border: "1px solid black" }}>
                                    <SignaturePad ref={this.sigCanvas}
                                        canvasProps={{ height: "300", width: "1200", touchaction: "auto", className: "signatureCanvas" }}
                                    />
                                </div>
                                <div style={{ marginTop: "10px", }}>
                                    <button onClick={() => this.clear()}>Clear</button> &nbsp; &nbsp;
                                    <button onClick={() => this.save()}>Save</button>
                                </div>
                                <div style={{ marginTop: "10px",marginBottom: "10px" }}>
                                    <img src={this.state.setImageURL}
                                        style={{ display: "block", margin: "0 auto", border: "1px solid black", width: "150px" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SubFooter />
                <Footer />
            </div>
        )
    }
}

export default Signature
