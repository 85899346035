import SubFooter from "../../common/subFooter";
import GuestHeader from '../../common/guestHeader';
import Footer from '../../common/footer';
import Furniture_Housewares from './furniture_Housewares';
class CePackageHoem extends React.Component{
    constructor(props) {
        super(props)
        this.state={
            furniture_Housewares:Furniture_Housewares
        }
}

render(){
    const {furniture_Housewares}=this.state
return(
    <div className="corporateHousing_Updates">
    <GuestHeader/>

    <div className="quote-section bg-white pad-top-0 ">
     
       <div className="container-fluid mrg-btm-50 mrg-top-0">
          <div className="img-header row" style={{backgroundImage:"url(https://s3-us-west-1.amazonaws.com/suiteam-website/Website-Images/SuiteAmerica-Company-Nike-Internship-Furniture.jpg)"}}>
             <h2>Furniture</h2>
          </div>
       </div>
       <div className="container">
          <div className="col-md-12 boxed_list">
             <h4>Bedroom(s)</h4>
             <ul>
                 {furniture_Housewares[0].Bedroom_s.map((row,index)=>{
                return(<li key={index}>{row}</li>)
                 })}
             </ul>
          </div>
          <div className="col-md-12 boxed_list">
             <h4>Living Room</h4>
             <ul>
                 {furniture_Housewares[0].LivingRoom.map((row,index)=>{
                return(<li key={index}>{row}</li>)
                 })}
             </ul>
          </div>
          <div className="col-md-12 boxed_list">
             <h4>Dining Room</h4>
             <ul>
                 {furniture_Housewares[0].DiningRoom.map((row,index)=>{
                return(<li key={index}>{row}</li>)
                 })}
             </ul>
          </div>
      
       </div>
       <div className="container-fluid mrg-btm-50 mrg-top-50">
          <div className="img-header row" style={{backgroundImage:"url(https://s3-us-west-1.amazonaws.com/suiteam-website/Website-Images/SuiteAmerica-Company-Nike-Internship-Housewares.jpg)"}}>
             <h2>Housewares</h2>
          </div>
       </div>
       <div className="container">
          <div className="col-md-12 boxed_list">
             <h4>BATHROOM</h4>
             <ul>
                 {furniture_Housewares[0].Bathroom.map((row,index)=>{
                return(<li key={index}>{row}</li>)
                 })}
             </ul>
          </div>
          <div className="col-md-12 boxed_list">
             <h4>BEDROOM</h4>
             <ul>
                 {furniture_Housewares[0].Bedroom.map((row,index)=>{
                return(<li key={index}>{row}</li>)
                 })}
             </ul>
          </div>
          <div className="col-md-12 boxed_list">
             <h4>DINING</h4>
             <ul>
                 {furniture_Housewares[0].Dining.map((row,index)=>{
                return(<li key={index}>{row}</li>)
                 })}
             </ul>
          </div>
          <div className="col-md-12 boxed_list">
             <h4>KITCHEN</h4>
             <ul>
                 {furniture_Housewares[0].Kitchen.map((row,index)=>{
                return(<li key={index}>{row}</li>)
                 })}
             </ul>
          </div>
          <div className="col-md-12 boxed_list">
             <h4>Starter Kit</h4>
             <ul>
                 {furniture_Housewares[0].StarterKit.map((row,index)=>{
                return(<li key={index}>{row}</li>)
                 })}
             </ul>
          </div>
          <div className="col-md-12 boxed_list">
             <h4>MISCELLANEOUS</h4>
             <ul>
                 {furniture_Housewares[0].Miscellaneous.map((row,index)=>{
                return(<li key={index}>{row}</li>)
                 })}
             </ul>
          </div>
       </div>
    </div>

   
    <SubFooter/>
    <Footer/>
 </div>
            
           );
       }
   }
export default CePackageHoem;
