/* Importing the node modules, child components, services and controllers used 
   inside Instructions component */
import $ from "jquery";
import AppController from "../../controller/appController";
import Guest from "../../common/guest";
import GuestHeader from '../../common/guestHeader';
import Footer from '../../common/footer';
import QuickRequestForm from './quickRequestForm';
/* Instructions Component initialization */
class Instructions extends React.Component{
	/* Initializing objects of its Instructions class */
    constructor(props) {
        super(props);
        this.state = {
            menuShow: false
        }
	  }
	  /* It is invoked immediately before mounting occurs */
      componentWillMount() {
        AppController.removeLocalStorageData();
        $('html, body').animate({scrollTop: 0});
        AppController.stickyTitles()  
     }
/* It is invoked to return html content */
render(){
    return(
        <div className="service_request moving_in">
            <GuestHeader/>
            <div className="profile_submenu-ht"></div>
            <Guest/>
            <QuickRequestForm menuShow={this.state.menuShow}/>
    <div className="guest_sitenew">

        <div className="intra_staff2">

            <div className="container">

                <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    <div className="panel panel-default">

                        <div className="panel-heading">
                            <h4 className="panel-title">
        <a className="" data-toggle="collapse" data-parent="#accordion" href="#collapse1" aria-expanded="false" aria-controls="collapse1">
        ONLINE ACCESS
        </a>
		             <p></p>		 
      </h4>

                        </div>

                        <div id="collapse1" className="panel-collapse collapse in">
                            <div className="panel-body toppad-0">

                                <div className="request_form">
                                    <div className="">

                                        <div className="row">
                                            <div className="col-sm-6 col-md-6">

                                                <h2>ACCESSING YOUR INTERNET</h2>

                                                <ol>
                                                    <li>Power on your computer or device.</li>

                                                    <li>Locate the wireless network listed.</li>
                                                    <li>Connect to the network & enter the provided password.</li>
                                                    <li>Open your web browser to verify our internet connection.</li>
                                                </ol>

                                            </div>

                                            <div className="col-sm-6 col-md-6 troubleshoot">

                                                <h3 className="pad-left-20 mrg-top-60"><strong>Troubleshooting</strong></h3>

                                                <ol>
                                                    <li>Unplug the power cord in the back of the modem and plug it back in after 30 seconds.</li>

                                                    <li>. Once the modem is fully reset and all the lights are either lit or blinking power your device back on and try to connect again.</li>
                                                    <li>If you are unable to connect to the Internet please call or email Guest Services for assistance.</li>

                                                </ol>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <h4 className="panel-title">
        <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse2" aria-expanded="false" aria-controls="collapse2">
        APARTMENT GUIDELINES
        </a>
				 <p>SuiteAmerica and your community’s property management are in charge of all maintenance of apartment homes.</p>
      </h4>

                        </div>

                        <div id="collapse2" className="panel-collapse collapse">
                            <div className="panel-body">

                                <div className="request_form">
                                    <div className="">

                                        <div className="row">
                                            <div className="col-sm-6 col-md-6">
                                                <div>
                                                    <div className="media-left">
                                                        <i className="fa fa-home"></i>
                                                    </div>
                                                    <div className="media-body">
                                                        <h2>RENTER’S INSURANCE</h2>
                                                        <p>SuiteAmerica does not cover you for property damage, loss or claims of personal injury made against you. We strongly recommend that you contact the insurance company of your choice to look into renter’s insurance for your personal belongings. If you already have insurance, contact your agent to see if they offer renter’s insurance or if your existing insurance extends to your stay here with SuiteAmerica. </p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="media-left">
                                                        <i className="fa fa-lock"></i>
                                                    </div>
                                                    <div className="media-body">
                                                        <h2>APARTMENT SAFETY</h2>
                                                        <p>Keep your doors locked at all times. If someone knocks, check to see who it is before opening the door. If you do not know the person, do not open the door and if they continue to bother you, please call emergency by dialing 911. If the visitor claims to be a service person, please ask them for identification. You may check with us if you are unsure about the service they are there to perform by contacting Guest Services.</p>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-6 troubleshoot">

                                                <div>
                                                    <div className="media-left">
                                                        <i className="fa fa-envelope"></i>
                                                    </div>
                                                    <div className="media-body">
                                                        <h2>RECEIVING MAIL</h2>
                                                        <p>Please check your mailbox on a frequent basis for correspondence from your team at SuiteAmerica. If you do not know the location of your mailbox, please contact Guest Services.
                                                        </p>
                                                        <br/>
                                                        <p>During your stay with SuiteAmerica, you will receive mail at your new apartment address. You may also receive your personal mail here by submitting a change of address request to the post office at usps.com. Please be aware that there can be a delay after you have recorded a change of address with the U.S. Postal Service. Keep this in mind if you have important items mailed to your temporary home near departure. Also, please note that we will have limited access to your mailbox after you move out and will not be able to check your mail once it has been re-occupied. In most cases any mail delivered after will be returned to sender. </p>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <h4 className="panel-title">
        <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse3" aria-expanded="false" aria-controls="collapse3">
        ENERGY & WATER CONSERVATION
        </a>
				 <p>SuiteAmerica wants to reduce energy and water use as much as possible. Please follow these tips in helping us in energy and water conservation.</p>
      </h4>

                        </div>

                        <div id="collapse3" className="panel-collapse collapse">
                            <div className="panel-body">

                                <div className="request_form">
                                    <div className="">

                                        <div className="">

                                            <div>
                                                <div className="media-left">
                                                    <i className="fa fa-leaf"></i>
                                                </div>
                                                <div className="media-body">
                                                    <h2>TIPS FOR CONSERVATION:</h2>
                                                    <div className="row">
                                                        <div className="col-md-6 col-sm-6">
                                                            <div>
                                                                <div className="media-left">
                                                                    <i className="fa fa-thermometer-quarter"></i>
                                                                </div>
                                                                <div className="media-body">

                                                                    <p>Save energy by monitoring your climate control and set thermostat to:
                                                                    </p>
                                                                    <ol>
                                                                        <li>68° (F) or less when you are at home during the winter</li>
                                                                        <li>75° to 78° (F) during the summer when using the air conditioning
                                                                        </li>
                                                                        <li>55° (F) for the heater during the night or when you will be away for the day</li>
                                                                    </ol>
                                                                </div>

                                                            </div>

                                                            <div>
                                                                <div className="media-left">
                                                                    <i className="fa fa-plug"></i>
                                                                </div>
                                                                <div className="media-body">

                                                                    <p>Use your appliances efficiently by making sure to turn off and unplug them while not in use.
                                                                    </p>

                                                                </div>

                                                            </div>

                                                        </div>
                                                        <div className="col-md-6 col-sm-6">
                                                            <div>
                                                                <div className="media-left">
                                                                    <i className="fa fa-lightbulb-o"></i>
                                                                </div>
                                                                <div className="media-body">

                                                                    <p>Be sure to turn off all lights before you leave or when not in use.
                                                                    </p>

                                                                </div>

                                                            </div>
                                                            <div>
                                                                <div className="media-left">
                                                                    <i className="fa fa-tint"></i>
                                                                </div>
                                                                <div className="media-body">

                                                                    <p>Monitor your water usage by:
                                                                    </p>
                                                                    <ol>
                                                                        <li>Limiting running water</li>
                                                                        <li>Waiting to run the washing machine and the dishwasher until loads are full.
                                                                        </li>

                                                                    </ol>
                                                                </div>

                                                            </div>

                                                            <div>
                                                                <div className="media-left">
                                                                    <i className="fa fa-power-off"></i>
                                                                </div>
                                                                <div className="media-body">

                                                                    <p>Set your computers to sleep and/or hibernate when not in use.
                                                                    </p>

                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="panel panel-default no-border-btm">
                        <div className="panel-heading">
                            <h4 className="panel-title">
        <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse4" aria-expanded="false" aria-controls="collapse4">
        HOUSEHOLD HINTS & TIPS
        </a>
			     <p></p>	 
      </h4>

                        </div>

                        <div id="collapse4" className="panel-collapse collapse">
                            <div className="panel-body">

                                <div className="request_form">
                                    <div className="">

                                        <div className="row">
                                            <div className="col-sm-6 col-md-6">
                                                <div>
                                                    <div className="media-left">
                                                        <i className="fa fa-tint"></i>
                                                    </div>
                                                    <div className="media-body">
                                                        <h2>BATHROOM</h2>
                                                        <p>Please do not flush tissues, paper towels, sanitary products or other items down the toilet. </p>
                                                        <p className="paddingtop">Some of our apartment communities are required by the city to use “low flow” using only a very small amount of toilet paper during each flush.</p>

                                                        <h3>Tub & Shower</h3>
                                                        <p>Remember to keep your shower curtain inside the bathtub or if you have a shower door, please slide it fully closed. Serious water damage and/or personal injury can result if water is leaked onto the floor. </p>
                                                        <p className="paddingtop">Turn off any water tap not in use. If water is on the floor please wipe it up immediately with mop or towels.</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="media-left">
                                                        <i className="fa fa-tachometer"></i>
                                                    </div>
                                                    <div className="media-body">
                                                        <h2>CLIMATE CONTROL</h2>
                                                        <p>The climate control should be turned off when the apartment is unoccupied or left at 68°(F). If you have air conditioning, we recommend leaving it around 75°(F).</p>
                                                    </div>
                                                </div>

                                                <div>
                                                    <div className="media-left">
                                                        <i className="fa fa-trash"></i>
                                                    </div>
                                                    <div className="media-body">
                                                        <h2>GARBAGE</h2>
                                                        <p>Do not keep garbage on your patio or stacked in your suite. Food should be wrapped and stored either in the refrigerator or in closed containers.</p>
                                                        <p>Please dispose of garbage daily in your communities dumpster and recycling location.</p>
                                                        <h3>Sink Disposal</h3>

                                                        <p>This is usually located in the drain of most kitchen sinks and may be used to dispose of most food items. Do not use the disposal for any of the following food items: celery, potato peels, egg shells, bones, coffee grounds, artichokes and asparagus. </p>
                                                        <p className="paddingtop">When turning the disposal on be sure to first check that all hands and fingers are out of harms way and there are no utensils or foreign objects in the drain.</p>

                                                    </div>

                                                </div>

                                            </div>
                                            <div className="col-sm-6 col-md-6 troubleshoot">

                                                <div>
                                                    <div className="media-left">
                                                        <i className="fa fa-cutlery"></i>
                                                    </div>
                                                    <div className="media-body">
                                                        <h2>DISHWASHER</h2>
                                                        <p>Please be sure to use dishwasher detergent and not just any liquid soap. </p>
                                                        <h3>Pots & Pans</h3>
                                                        <p>Please do not use glass pans for cooking on the stovetop burners; they are designed for oven baking only. Pots are to be used for stovetop cooking only (not in the oven).</p>
                                                    </div>

                                                </div>

                                                <div>
                                                    <div className="media-left">
                                                        <i className="fa fa-coffee"></i>
                                                    </div>
                                                    <div className="media-body">
                                                        <h2>ELECTRIC APPLIANCES</h2>
                                                        <p>Please turn all electrical appliances off when not in use. Leaving coffee makers, rice cookers, irons and other electrical items on is a fire hazard.</p>
                                                        <h3>Microwave</h3>
                                                        <p>Please use only microwave-safe dishes, containers and glasses. The use of foil, metal and chrome containers will cause damage or fire. </p>
                                                    </div>

                                                </div>

                                                <div>
                                                    <div className="media-left">
                                                        <i className="fa fa-exclamation-triangle"></i>
                                                    </div>
                                                    <div className="media-body">
                                                        <h2>PREVENTING MOLD & MILDEW</h2>
                                                        <p>Air circulation and temperature are the most important factors in avoiding mold and mildew. Please keep the air circulating in your apartment and set the air conditioning & heating to a comfortable temperature.</p>
                                                        <h3>Tips for prevention:</h3>
                                                        <ol>
                                                            <li>Report any overflow liquid from washing machines, bathrooms & kitchens</li>
                                                            <li>Use bathroom fans or open bath windows while bathing or showering and report any fans or windows that do not work.</li>
                                                            <li>Use exhaust fans whenever you are cooking, washing or cleaning.
                                                            </li>
                                                            <li>. Report any water intrusions that may occur such as plumbing leaks and dripping or sweating walls or pipes.</li>
                                                            <li>Dry any visible moisture on all windows, walls and other surfaces.
                                                            </li>
                                                            <li>Report any known problems with air conditioning or heating systems, as well as clogged vents or filters.</li>

                                                        </ol>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
    <Footer/>
</div>
    );
}
}
export default Instructions;

