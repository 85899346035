import OwlCarousel from 'react-owl-carousel';
const Schools = (props) => {
const SchoolInfo=props.SchoolInfo
    return (
      <div>
        <div className="row">
        <OwlCarousel  className="GreatSchools-community" {...{ loop: false, nav:true, dots: true, items: 1, navContainerClass: 'owl-buttons', navText:SchoolInfo.length>3 || props.width<900 ? ["<i class='glyphicon glyphicon-menu-left'></i>", "<i class='glyphicon glyphicon-menu-right'></i>"]:['',''], autoheight:'true',refreshClass:'owl-refresh',loadingClass: 'owl-loaded',responsive:{ 320:{ items:1, nav:true, loop:false }, 767:{ items:2, nav:true, loop:false }, 992:{ items:2, nav:true, loop:false }, 1199:{ items:3, nav:true, loop:false ,autoHeight: true } }}}> 
        {SchoolInfo!=undefined ?SchoolInfo.map((row, index) => { 
         if(row.website != undefined)
         { 
            const website = row.website.search('www')
            var url =''
            if( website == 0) {
             var http ='http://' 
              url = http.concat(row.website) 
           }
            else{  
              url = row.website; 
            } 
        }
          return (
          <div className=" card col-md-12" key={index}>
            <div className="card-body sch_deatils">
              <div className="card_titlee22">
                <a href={row.website==undefined?0:row.website==undefined?null:url} target="_blank"> <span className="card-title">{row.name ==undefined?'':row.name}</span>
                </a>
              </div>
              <div className="clearfix"></div>
              <h6 className="card-subtitle mb-2 text-muted"> <i className="fa fa-star cadr_schicon"></i> Grades  {row.gradeRange ==undefined?'':row.gradeRange}</h6>
              <h6 className="card-subtitle mb-2 text-muted">  <i className="fa fa-graduation-cap cadr_schicon"></i>  {row.enrollment ==undefined?'':row.enrollment} Students</h6>
              <p className="card-text"> <i className="fa fa-map-marker cadr_schicon"></i> {row.address ==undefined?0:row.address._text}</p>
              <div className="card_titlee2233"> <span className="card-subtitle mb-2 text-muted schl_contact"><i className="fa fa-phone"></i> {row.phone ==undefined?'':row.phone} </span>
                <a href={row.ratingsLink==undefined? '':row.ratingsLink._text} target="_blank"><span className="card-subtitle mb-2 text-muted"><span className={row.gsRating ==undefined?'':(row.gsRating=='1' || row.gsRating== undefined)?"badge badge-secondary color1":(row.gsRating>'1' &&  row.gsRating<'5')? "badge badge-secondary color2":"badge badge-secondary color3"} >{row.gsRating ==undefined?'':row.gsRating+'/10'} </span> </span></a>
              </div>
            </div>
            <div className="white-gap"></div>
          </div>) }):null }</OwlCarousel>
      </div>
      </div>
    )
}
export default Schools;
