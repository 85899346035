/* Importing the node modules, child components, services and controllers used 
   inside RequestReservation component */ 
   import AppController from "../../../controller/appController";
   import EmployeeHeader from '../../../common/employeeHeader';
   import Footer from '../../../common/footer';
   import SubFooter from "../../../common/subFooter";

   /* RequestReservation Component initialization */
   class RequestReservation extends React.Component {
       /* Initializing objects of its RequestReservation class */
       constructor(props) {
           super(props)
           this.state={
            orderNumber: '',
            color: '',
            errorMessage: ''
           }
           this.setValue = this.setValue.bind(this);
           this.orderPrint = this.orderPrint.bind(this);
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
           AppController.stickyTitles();
           AppController.carousel();
           AppController.updateAnimation();
           $('html, body').animate({scrollTop: 0});
        }
        setValue(field, e) {
            this.setState({errorMessage:'',color:''})
                var object = {};
                object[field] = e.target.value;
                this.setState(object);
        }
        async orderPrint(divName){
            var reg = /^([+-]?([0-9]*[.])?[0-9]+)$/;
            if(reg.test(this.state.orderNumber)){
                this.setState({loader:true,open:false})
        }
        else{
            this.setState({errorMessage:'Please Enter only Numbers',color:'red'})
        }   
        }
       /* It is invoked to return html content */
       render() {
           return (
            
                <div className="corporateHousing_Updates" id="serviceRequest">
<EmployeeHeader/>
<div className="header-ht-cover bg-grey"></div>
<div className="container-fluid nike-datepicker bg-grey service_request mrg-top-20">
    <h1 className="wow animated zoomIn" style={{textAlign:'center'}}>Confirmation Order</h1>
        <div className="request_form company_form">

<div className="container">
                <form className="well">
                <p style={{color:this.state.color,textAlign:'center'}}>{this.state.errorMessage}</p>
{/* Mail Send */}
                    <div className="row">

                        <div className="col-sm-3 col-md-3">
                            Order Number <span style={{color: 'red'}}>*</span>
                            <input type="text" className="form-control" placeholder="Master #" value={this.state.orderNumber} 
                            onChange={(e) => this.setValue('orderNumber', e)} />
                  </div> 
                  
                  <div className="col-sm-3 col-md-3"> 
                  <button type="button" className=" btn location_btn mrg-top-25" 
                  onClick={() => this.orderPrint('orderprint')}> SUBMIT</button>
                  </div> 
        </div>
        </form>

        <div >

<span id="orderprint">
<div style={{ fontFamily:"'Gill Sans', 'Gill Sans MT', 'Myriad Pro', 'DejaVu Sans Condensed', Helvetica, Arial, 'sans-serif'", fontSize: '14px'}}>
	
  <div style={{textAlign: 'center'}}>
    <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/logo.png" alt="" style={{width: '200px', margin: '20px 0px'}} />	
  </div>
    <p style={{textAlign: 'center'}}><strong>Welcome to your Corporate Apartment! <br/>
  800-367-9501</strong></p>
    
    <div style={{display: 'inline-block', border: '1px solid #555', padding: '5px', float: 'left'}}><strong style={{marginRight: '20px'}}>Confirmation#</strong> 359857</div>
    <div style={{float: 'right'}}>Wednesday, March 28, 2018</div>
    
    <br /><br />
    
    <table style={{width: '100%', margin: '30px 0px'}}>
    <tbody>
      <tr>
        <td style={{padding: '5px 0px', color: '#000080', fontWeight: '700', textTransform: 'uppercase', textAlign: 'left'}}>RESIDENT:</td>
        <td style={{padding: '5px 0px'}}>Ashok Kandipati</td>
        <td></td>
      </tr>
      <tr>
        <td style={{padding: '5px 0px', color: '#000080', fontWeight: '700', textTransform: 'uppercase', textAlign: 'left'}}>YOUR APARTMENT ADDRESS:</td>
        <td>1725 S. Bascom Ave
  Campbell, CA 95008</td>
        <td><strong>Suite:</strong> 230 <br />
          <a href="#" style={{color: '#800002', textDecoration: 'underline'}}>This is a NON SMOKING property</a>
        </td>
      </tr>
      <tr>
        <td style={{padding: '5px 0px', color: '#000080', fontWeight: '700', textTransform: 'uppercase', textAlign: 'left'}}>LEASING OFFICE ADDRESS:</td>
        <td>REVERE
          1725 S. Bascom Ave Suite 1200
          Campbell, CA 95008
        </td>
        <td>
        </td>
      </tr>
      <tr>
        <td style={{padding: '5px 0px', color: '#000080', fontWeight: '700', textTransform: 'uppercase', textAlign: 'left'}}>APT. INFORMATION:</td>
        <td><strong style={{marginRight: "20px"}}>Phone:</strong>408-680-0312
        </td>
        
      </tr>
      <tr>
        <td></td>
        <td>
        <strong>Size:</strong> 2X2 <strong style={{marginLeft: '20px'}}>Floor:</strong> 2 <strong style={{marginLeft: '20px'}}>MailBox:</strong> 230
        </td>
        <td></td>
      </tr>
      <tr>
        <td>
          <ul style={{marginTop: '20px', paddingLeft: "0px"}}>
            <li style={{listStyleType: 'none', lineHeight: '30px'}}><strong style={{ display: 'inline-block'}}>ARRIVAL DATE:</strong> <br/>Wednesday, March 28, 2018</li>
            <li style={{listStyleType: 'none', lineHeight: '30px'}}><strong style={{ display: 'inline-block'}}>DEPARTURE DATE:</strong> <br/>Thursday, March 29, 2018</li>
            <li style={{listStyleType: 'none', lineHeight: '30px'}}><strong style={{ display: 'inline-block'}}>PETS:</strong> <br/>None</li>
          </ul>
        </td>
        <td></td>
        <td>
          <ul style={{marginTop: '20px', paddingLeft: "0px"}}>
            <li style={{listStyleType: 'none', lineHeight: '30px'}}><strong style={{ display: 'inline-block'}}>CHECK IN TIME:</strong> <br/>3:00PM</li>
            <li style={{listStyleType: 'none', lineHeight: '30px'}}><strong style={{ display: 'inline-block'}}>ESTIMATED STAY: </strong> <br/><strong>2 Days</strong></li>
          </ul>
        </td>
       
      </tr>
      </tbody>
    </table>
    
    <p><strong style={{padding: '5px 0px', color: '#000080', fontWeight: '700', textTransform: 'uppercase', textAlign: 'left' }}>ENTRY INSTRUCTIONS</strong> <strong style={{marginLeft: '50px', marginTop: '20px'}}>Lockbox 8188</strong></p>
    <p style={{marginBottom: '5px', fontSize: '16px'}}>Entry Arrangements: A lockbox will be on your front door. To open, line up the four-digit lockbox code provided in this
  confirmation above. Once the numbers are lined up properly, you will be able to open the small door to retrieve your key(s).
  The lockbox stays on the front door. If you have any problems, please contact us at 800-367-9501.</p>
    
    <p style={{marginTop: '25px', marginBottom: '5px'}}><strong style={{padding: '5px 0px' , color: '#000080', fontWeight: '700', textTransform: 'uppercase', textAlign: 'left', marginRight: '20px'}}>PARKING SPACE ASSIGNMENT</strong> <strong>57</strong> <strong style={{padding: '5px 0px', color: '#000080', fontWeight: '700', textTransform: 'uppercase', textAlign: 'left', marginLeft: '50px', marginRight: '20px'}}>2nd Parking Space</strong> 249</p>
    <p style={{marginBottom: '5px', fontSize: '16px'}}>Please be sure to follow all parking rules, which may include, but are not limited to: parking in your assigned parking spot
  (if applicable), utilizing only one parking spot per vehicle, posting parking stickers in your vehicle (if applicable), not
  blocking garages or driveways, not parking in fire zones, and not parking illegally in handicapped spots. Failure to follow all
  parking rules may result in your vehicle being ticketed or towed. Please also keep in mind that other violations of state laws
  (such as expired registration) may result in similar actions. SuiteAmerica is not responsible for any fees associated with
  parking tickets or towing. Please do not leave any valuables in your vehicle.
  If you have any questions, please be sure to contact your SuiteAmerica representative.</p>
    
    <p style={{marginTop: '25px', marginBottom: '5px'}}><strong style={{padding: '5px 0px', color: '#000080', fontWeight: '700', textTransform: 'uppercase', 'textAlign': 'left'}}>ACCOMMODATIONS</strong></p>
    <p style={{marginBottom: '5px', fontSize: '16px'}}>Accommodations: Two Bedroom, Two Bath Apartment. Complete with furniture, housewares, expanded basic cable
  television, local phone service, standard high speed internet, and monthly utilities.
  </p>
    
    <p style={{marginTop: '25px', marginBottom: '5px'}}><strong style={{padding: '5px 0px', color: '#000080', fontWeight: '700', textTransform: 'uppercase', 'textAlign': 'left'}}>PHONE SERVICE</strong></p>
    <p style={{marginBottom: '5px', fontSize: '16px'}}>All long distance charges will be automatically billed to your credit card. A credit card receipt along with an invoice and a
  list of calls will be mailed to the address provided on your Statement of Authorization.</p>
    
    <p style={{marginTop: '25px', marginBottom: '5px'}}><strong style={{padding: '5px 0px', color: '#000080', fontWeight: '700', textTransform: 'uppercase', 'textAlign': 'left'}}>DEPARTURE DATE</strong></p>
    <p style={{marginBottom: '5px', fontSize: '16px'}}>Please note your departure date stated above. If you need to extend your stay, please contact us as soon as possible. We
  will make every effort to extend your stay in the above apartment. Please note that your rate is only guaranteed through
  your current scheduled vacate date. Extensions may be subject to a rate increase and are subject to availability.</p>
    
    <p style={{marginTop: '25px', marginBottom: '5px'}}><strong style={{padding: '5px 0px', color: '#000080', fontWeight: '700', textTransform: 'uppercase', 'textAlign': 'left'}}>RENTERS INSURANCE</strong></p>
    <p style={{marginBottom: '5px', fontSize: '16px'}}>The insurance carried by SuiteAmerica does not cover you for property damage or loss claims of personal injury made
  against you. We strongly recommend that you contact the insurance agency of your choice, to obtain coverage for your
  stay.</p>
    
    <p style={{marginTop: '25px', marginBottom: '5px'}}><strong style={{padding: '5px 0px', color: '#000080', fontWeight: '700', textTransform: 'uppercase', 'textAlign': 'left'}}>OTHER IMPORTANT INFORMATION:</strong></p>
    <p style={{marginBottom: '5px', fontSize: '16px'}}>SuiteAmerica reserves the right to substitute the apartment with an apartment of comparable size and amenities should
  this apartment not be available as scheduled.</p>
    <p style={{marginBottom: '5px', fontSize: '16px'}}>If you have any difficulty checking in, please call us at 800-367-9501. If you are calling after normal business hours,
  please follow the instructions to leave an emergency message. One of our team members will return your call promptly.</p>
    
  </div>
  </span>
  </div>          
            </div>

                            


            </div>

</div>
<SubFooter/>
    <Footer/>
</div>

   
           );
       }
   }
   export default RequestReservation;