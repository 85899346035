import { useState } from "react";
import { encrypt } from "../../../../common/utils";
import { capitalizeFirstLetter, extractCityName } from "../../common/utils";

const CardComponent = ({
  row,
  index,
  changeCommunityImagesModalData,
  changeCommunityName,
  propertyMoreDetailsData,
  updatePropertyMoreDetailsData,
  changeToMoreDetailsAndBookView,
}) => {
  let commImagesList = [
    ...stringToList(row.compleximages),
    ...stringToList(row.interiorimages),
  ];

  const roomTypesList = [
    {
      key: "STU",
      BookedType: "STU",
      name: "Studio",
      imageSrc:
        "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/studiobed.png",
    },
    {
      key: "Avail1X",
      BookedType: "1X",
      name: "1 Bedroom",
      imageSrc:
        "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/singlebedroom.png",
    },
    {
      key: "Avail2X",
      BookedType: "2X",
      name: "2 Bedroom",
      imageSrc:
        "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/doublebedroom.png",
    },
    {
      key: "Avail3X",
      BookedType: "3X",
      name: "3 Bedroom",
      imageSrc:
        "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/triplebedroom.png",
    },
  ];

  const [dailyrate, setDailyrate] = useState(row.dailyrate);
  const [selectedRoomType, setSelectedRoomType] = useState(
    row.Avail1X
      ? "1X"
      : row.Avail2X
      ? "2X"
      : row.Avail3X
      ? "3X"
      : row.STU
      ? "STU"
      : "1X"
  );

  function stringToList(str) {
    if (!str) return [];
    return str.split(",");
  }

  function bookNowData(selectedCommunity, selectedRoomType) {
    updatePropertyMoreDetailsData({
      selectedCommunity: selectedCommunity,
      selectedRoomType: selectedRoomType,
    });

    const queryParamsObject = {
      ...selectedCommunity,
      selectedRoomType,
      checkIn: moment(propertyMoreDetailsData.checkIn).format("DD/MM/YYYY"),
      checkOut: moment(propertyMoreDetailsData.checkOut).format("DD/MM/YYYY"),
    };
    let queryString = objectToQueryString(queryParamsObject);
    const baseUrl = "/reserve-now";

    queryString = encrypt(queryString);
    window.open(
      `${baseUrl}${baseUrl.includes("?") ? "&" : "?"}${queryString}`,
      "_blank"
    );
  }

  function objectToQueryString(obj) {
    const params = new URLSearchParams();

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        params.append(key, obj[key]);
      }
    }

    return params.toString();
  }

  function changeActiveRoomType(roomType) {
    rateChange(roomType);
    setSelectedRoomType(roomType.BookedType);
  }

  function rateChange(roomType) {
    let dailyrate;
    if (roomType.BookedType === "STU") {
      dailyrate = row.sizeSTURate;
    } else if (roomType.BookedType === "1X") {
      dailyrate = row.size1xRate;
    } else if (roomType.BookedType === "2X") {
      dailyrate = row.size2xRate;
    } else if (roomType.BookedType === "3X") {
      dailyrate = row.size3xRate;
    }
    setDailyrate(dailyrate);
  }

  function showMoreDetails(selectedCommunity, selectedRoomType) {
    changeToMoreDetailsAndBookView();
    updatePropertyMoreDetailsData({
      selectedCommunity: selectedCommunity,
      selectedRoomType: selectedRoomType,
    });
  }

  return (
    <div className="col-md-4 col-sm-6" key={index}>
      <div className="community-item">
        <div className="img-div">
          <img src={row.image_name} alt="" />
          <div className="img-hover-dtls">
            <label
              className="community-photos pull-left"
              htmlFor={"op" + index}
            >
              <ul
                onClick={() => {
                  changeCommunityImagesModalData(commImagesList);
                  changeCommunityName(row.community_name);
                }}
                type="button"
                data-toggle="modal"
                data-target="#fullmodel-Carousel1"
              >
                {commImagesList.map((image, comIndex) => {
                  if (comIndex < 2) {
                    return (
                      <li key={comIndex}>
                        <img src={image} alt="" />
                      </li>
                    );
                  }
                })}
              </ul>
              <span>{commImagesList.length} Photos</span>
            </label>
            {row.walkScore ? (
              <div className="walkscore pull-right">
                <div className="arrow_box">
                  {row.walkScore == "" ? 0 : row.walkScore}
                </div>
                <span>Walk Score</span>
              </div>
            ) : null}
          </div>
        </div>
        <div className="community-item-dtls">
          <div className="community-head">
            <h5>{row.community_name}</h5>
          </div>
          <p>{row.address}</p>
          <span className="area">
            <strong>
              {capitalizeFirstLetter(extractCityName(row.city_name))},
            </strong>
            <br />
            {capitalizeFirstLetter(row.state_name)}
          </span>

          <span className="distance">
            <img
              src="https://s3-us-west-1.amazonaws.com/suiteam-website/Travel-Booking/SuiteAmerica-Travel-Booking-Map.png"
              alt=""
            />
            <strong>{row.distance == "" ? 0 : row.distance}</strong> Miles away
          </span>
          <div className="clearfix"></div>
          <ul className="bedroom_list list-inline">
            {roomTypesList.map((roomType, index) => {
              return row[roomType.key] ? (
                <li
                  className="active"
                  key={index}
                  onClick={() => changeActiveRoomType(roomType)}
                >
                  <img src={roomType.imageSrc} /> <p>{roomType.name}</p>
                  <span
                    className={`badge studio ${
                      selectedRoomType === roomType.BookedType ? "active" : ""
                    }`}
                  >
                    {row[roomType.key]}
                  </span>
                </li>
              ) : null;
            })}
            {!row.STU && !row.Avail1X && !row.Avail2X && !row.Avail3X ? (
              <li className="active" key={index}>
                <img src={roomTypesList[0].imageSrc} /> <p>No data</p>
                <span className="badge studio">0</span>
              </li>
            ) : null}
          </ul>
        </div>
        <div className="community-footer">
          <a
            href="#"
            onClick={() => {
              showMoreDetails(row, selectedRoomType);
            }}
          >
            More Info
          </a>
          <a
            href="#"
            data-toggle="modal"
            data-target="#travelbooknow"
            className="btn btn-bordered pull-right"
            onClick={() => bookNowData(row, selectedRoomType)}
          >
            <img
              src="https://s3-us-west-1.amazonaws.com/suiteam-website/Travel-Booking/SuiteAmerica-Travel-Booking-Booknow-Image.png"
              alt=""
            />{" "}
            <span>
              {" "}
              Book Now <span>$ {dailyrate} / Day </span>
            </span>
          </a>
          <div className="clearfix"></div>
        </div>
      </div>
    </div>
  );
};

export default CardComponent;
