import MainController from "../../controller/mainController";
import SubFooter from "../../common/subFooter";
import GuestHeader from '../../common/guestHeader';
import Footer from '../../common/footer';
import MediaFooter from "./common/mediaFooter";
import MetaTags from '../../common/metaTags';
import ServerMetaTags from '../../../server/metaTags';
/* MediaIndustryAwards Component initialization */
class MediaIndustryAwards extends React.Component {
    /* Initializing objects of its MediaIndustryAwards class */
    constructor(props) {
        super(props);
    }
    /* It is invoked immediately before mounting occurs */
    componentWillMount() {
        MainController.getInitialInformation();
    }
    /* It is invoked to return html content */
    render() {
        const metaData = ServerMetaTags.urlString.getMetaData(this.props.match.url);
        return (
            <div>
            <MetaTags 
             metaTitle = {metaData.title}
             metaDesription = {metaData.description}
             metaUrl = {metaData.canonical} />
            <GuestHeader/>
            <div className="cover-tophd-ht company_ht_cover"></div>
            <div className="quote-section bg-white">
                <div className="container mediakit">
                    <div className="plaine-text global-coverage-wrap col-md-10 col-md-offset-1 wow fadeInUp" data-wow-delay="0.5s">
                        <h2 className="mrg-btm-50 color-red">INDUSTRY AWARDS</h2>
                        <p>SuiteAmerica is honored to have won numerous industry awards for our exceptional services, attention to 
                        detail and ground-breaking technology.
                        </p>
                        
                        <h5 className="text-left mrg-btm-0 font700"><strong>CARTUS Global Network</strong></h5>
                        <ul>
                            <li>2016 Supplier Innovation Silver Award</li>
                            <li>2015 Supplier Innovation Gold Award</li>
                            <li>2014 Gold Commitment To Excellence Award</li>
                            <li>2013 Gold Commitment To Excellence Award</li>
                            <li>2013 Silver Supplier Innovation Award</li>
                            <li>2010 Gold Supplier Quality Innovation Award</li>
                            <li>2009 Commitment To Excellence Platinum Award </li>
                         </ul>   

                         
                         <h5 className="text-left mrg-btm-0 mrg-top-30 font700"><strong>MoveCenter's Relocation Supplier Awards</strong></h5>
                        <ul>
                            <li>2017 Supplier of the Year for Corporate Housing & Destination Services</li>
                         </ul> 

                         
                         <h5 className="text-left mrg-btm-0 mrg-top-30 font700"><strong>People's Choice Stevie Awards</strong></h5>
                        <ul>
                            <li>2011 Front-Line Customer Service Professional of the Year</li>
                            <li>2011 Customer Service Department of the Year</li>
                         </ul> 

                         
                         <h5 className="text-left mrg-btm-0 mrg-top-30 font700"><strong>Bridgestreet Global Alliance</strong></h5>
                        <ul>
                            <li>2010 Most Valuable Player</li>
                         </ul> 

                         
                         <h5 className="text-left mrg-btm-0 mrg-top-30 font700"><strong>CHPA</strong></h5>
                         <h5 className="text-left pull-left mrg-btm-0 mrg-top-0 font700"><strong>Corporate Housing Providers Association</strong></h5>
                         <div className="clearfix"></div>
                        <ul>
                            <li>2009 Provider Company Of The Year</li>
                            <li>Tower Of Excellence Award </li>
                         </ul> 

                         
                         <h5 className="text-left mrg-btm-0 mrg-top-30 font700"><strong>Trippel Survey & Research, LLC</strong></h5>
                         <h5 className="text-left pull-left mrg-btm-0 mrg-top-0 font700"><strong>Relocations Managers Survey</strong></h5>
                         <div className="clearfix"></div>
                        <ul>
                            <li>2014 Highest Overall Satisfaction</li>
                            <li>2013 Highest Overall Performance</li>
                            <li>2012 Highest Overall Satisfaction</li>
                            <li>2011 Highest Overall Satisfaction</li>
                         </ul> 

                         
                         <h5 className="text-left mrg-btm-0 mrg-top-30 font700"><strong>Trippel Survey & Research, LLC</strong></h5>
                         <h5 className="text-left pull-left mrg-btm-0 mrg-top-0 font700"><strong>Relocations Employees Survey</strong></h5>
                         <div className="clearfix"></div>
                        <ul>
                            <li>2012 #1 Temporary Housing Provider In Overall & Net Satisfaction</li>
                            <li>2010 #1 Temporary Housing Provider In Overall Satisfaction</li>
                         </ul> 
                    </div>
                    <MediaFooter />
                </div>
            </div>
            <SubFooter />
            <Footer/>
        </div>

        );
    }
}
export default MediaIndustryAwards;