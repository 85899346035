/* Importing the node modules, child components, services and controllers used 
   inside RelocationCorporateHousing component */   
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   import LocationSlider from '../../common/locationSliderData';
   import ReserveYourStay from '../../common/reserveYourStay.js';
   import MetaTags from '../../common/metaTags';
   import ServerMetaTags from '../../../server/metaTags';
   import NavigationFooter from '../../common/navigationFooter';
   import NavigationData from '../../common/navigation.json';
   
   /* RelocationCorporateHousing Component initialization */
   class RelocationCorporateHousing extends React.Component {
       /* Initializing objects of its RelocationCorporateHousing class */
       constructor(props) {
           super(props);
           this.state = {
            navigationData: NavigationData.relocationCorporateHousing
          }
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
           MainController.getProfilePageInformation(5, 2, 2);
        }
       /* It is invoked to return html content */
       render() {
           const metaData = ServerMetaTags.urlString.getMetaData(this.props.match.url);
           return (
            <div className="corporateHousing_Updates">
                <MetaTags 
                metaTitle = {metaData.title}
                metaDesription = {metaData.description}
                metaUrl = {metaData.canonical} />
                <GuestHeader/>
                <header className="codeconveyHeader cc_image_text_main corporate-h video-wrapper">
                    <img className="img-responsive relocation-corporate-housing" src="https://s3-us-west-1.amazonaws.com/suiteam-website/images-website/Home-Landing-Corporate-Housing.jpg" width="100%" alt=""  />
                    <div className="wow fadeInUp img-on-text top35 left45 width600 text-center img-on-text-center" data-wow-delay="0.5s">
                        <h1>CORPORATE HOUSING <br/><i>SOLUTIONS</i></h1>
                        <p>At
SuiteAmerica, we’ve developed a collaborative global program to
ensure your client’s transferees have the best corporate housing
stay possible, all while driving cost efficiencies and providing you
with real-time data and metrics. Streamlined
communication, constant updates, and reduced points of contact. These
are just a few reasons why SuiteAmerica’s the number one choice in
corporate housing. 
                        </p>
                        
                    </div>
                </header>
                <ReserveYourStay />
                <div className="who_weare services_ch">
                    <div className="cc_image_text_main">
                        <img className="img-responsive left-mo" src={s3BucketUrl_WebsiteImages+"Relocation-Corporate-Housing-Customize-Your-Stay.jpg"} alt="" />
                    </div>
                </div>
                <div className="new_txt_sec">
                    <div className="new_txt_content container wow fadeInUp animated">
                        <div className="col-md-8 col-md-offset-2">
                            <h2>CUSTOMIZE YOUR STAY</h2>
                            <p>With almost 30 years of experience servicing housing needs around the globe, we can create a unique, customizable program for each of your clients. Our centralized information system ensures efficient service and real-time reporting regardless of program size, structure or duration.
                            </p>
                            <p> <strong>Intern Programs, Group Moves, Long Term Housing, VIPs, Project Launches </strong> <i>and more</i>
                            </p>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>
                <LocationSlider {...this.props}/>
                <div className="caption_text services_cs">
                    <div className="container">
                        <div className="who_weare_text22 wow fadeInUp" data-wow-delay="0.5s">
                            <h1 className="font-bold">COMFORT ISN’T</h1>
                            <h2><span>it’s a</span></h2>
                            <h1>AN OPTION</h1>
                            <h2><span>necessity</span></h2>
                        </div>
                    </div>
                </div>
                <div className="container-fluid bg-white">
                    <div className="container">
                        <p className="text-center review_rqst_button accomidation_acco_btn color-red collapsed" role="button" data-toggle="collapse" href="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                        Accommodations
                        </p>
                        <div className="collapse row accomidation_acco" id="collapseExample">
                            <div className="company_section wow fadeInUp">
                            <div className="col-md-6">
                                <div>
                                    <p>ALL APARTMENTS INCLUDE</p>
                                    <ul>
                                        <li>Complete furnishings</li>
                                        <li>Separate living, dining, & sleeping areas</li>
                                        <li>Full kitchens complete with housewares</li>
                                        <li>2 Televisions, DVD, & basic cable</li>
                                        <li>Washer & dryer in most apartments</li>
                                        <li>High-speed internet</li>
                                        <li>Utilities</li>
                                        <li>24/7 customer support</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <p>MOST COMMUNITIES FEATURE</p>
                                    <ul>
                                        <li>Fitness facilities</li>
                                        <li>Business Centers</li>
                                        <li>Swimming Pools</li>
                                        <li>Easy Access to Highways</li>
                                        <li>Near Public Transportation</li>
                                        <li>Controlled Access</li>
                                        <li>Parking available on site</li>
                                        <li>Pet-Friendly accommodations</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 company_section">
                            <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/images-website/Home-Landing-Concierge.jpg" className="img-responsive" alt="" /> 
                            <div className="accom_text wow fadeInUp">
                                <h2>Concierge Services</h2>
                                <p>Having the “little things” during the big move is what we’re here for. Our Concierge Services team is available 24/7 to help transferees settle in with additional amenities and services, so you can be confident all of their needs will be met. 
                                </p>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 company_section">
                            <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/images-website/Home-Landing-PTS.jpg" className="img-responsive" alt="" /> 
                            <div className="accom_text wow fadeInUp">
                                <h2>Reduced Contacts</h2>
                                <p>We provide a single point of contact who will assist you and your transferee throughout the transition process. With our team’s dedicated service values and tenured experience in both lease and property management, as well as extensive training prior to field placement, every team member is equipped to meet you and your transferee’s every need.
                                </p>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 company_section">
                            <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/images-website/Home-Landing-Interactive-Tools.jpg" className="img-responsive" alt="" /> 
                            <div className="accom_text wow fadeInUp">
                                <h2>Advanced Interactive Tools</h2>
                                <p>We’ve created a suite of custom, digital tools using the latest technologies to make our time with you and your transferee more efficient and valuable. Our centralized data system ensures accurate real-time information, tracking and reporting data about corporate housing stays or Destination Services tours from any device.
                                </p>
                                <p className="mrg-top-50"><strong>Customizable Dashboards for Real-time Reporting Targeted Property Search Results Service and Maintenance Report Tracking</strong>
                                </p>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="quote-section bg-grey quote_red">
                    <div className="container">
                        <div className="wow fadeInUp" data-wow-delay="0.5s">
                            <h6 className="quote-s"> <img src={s3BucketUrl_WebsiteImages+"Corporation-Corporate-Housing-Quote.png"} alt=""/></h6>
                            <div className="col-md-10 col-md-offset-1">
                            <h5 className="quote-text">Your team has thought of everything to make it feel like 
                                a home away from home. The kids loved their new room, especially 
                                the cozy blankets and stuffed animals. After a long journey from London, 
                                it was hugely welcoming and very much appreciated.
                            </h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="clearfix"></div>
                <NavigationFooter {...this.state} />
                <SubFooter/>
                <Footer/>
            </div>   
           );
       }
   }
   export default RelocationCorporateHousing;