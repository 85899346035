"use strict";

import serviceBase from "./serviceBase";

const bookitApiService = {
  createSession: (data) => serviceBase.post("/bookit/create-session", data),
  checkSession: (data) => serviceBase.post("/bookit/check-session", data),
  deleteSession: (data) => serviceBase.post("/bookit/delete-session", data),
  ClientProfileLogin: (data) =>
    serviceBase.post("/bookit/ClientProfileLogin", data),
  SaveClientProfile: (data) =>
    serviceBase.post("/bookit/SaveClientProfile", data),
  GetClientEmailDecrypt: (data) =>
    serviceBase.post("/bookit/GetClientEmailDecrypt", data),
  ClientProfileCardDetails: (data) =>
    serviceBase.post("/bookit/ClientProfileCardDetails", data),
  ClientOrdersByUserEmail: (data) =>
    serviceBase.post("/bookit/ClientOrdersByUserEmail", data),
  ClientOrderServicesByUserEmail: (data) =>
    serviceBase.post("/bookit/ClientOrderServicesByUserEmail", data),
  GetTravelAvailableMasters: (data) =>
    serviceBase.post("/bookit/GetTravelAvailableMasters", data),
  CreatePaymentIntent: (data) =>
    serviceBase.post("/bookit/CreatePaymentIntent", data),
    GetTeamData: (data) => serviceBase.post("/bookit/GetTeamData", data),
    GetAllAvailableMasters: (data) =>
    serviceBase.post("/bookit/GetAllAvailableMasters", data),
    UpdateOrderMoveout: (data) =>
      serviceBase.post("/bookit/UpdateOrderMoveout", data),
    CreateNewSchedule: (data) =>
      serviceBase.post("/bookit/CreateNewSchedule", data),
    CreateSuiteCareOrder: (data) =>
      serviceBase.post("/bookit/CreateSuiteCareOrder", data),
    CancelScheduleOrService: (data) =>
      serviceBase.post("/bookit/CancelScheduleOrService", data),
};

export default bookitApiService;
