import React, { useState } from "react";
import DatePicker from "react-datepicker";

const FlightInfoModal = ({ orderFlightInfoModal }) => {
  const [state, setState] = useState({
    modalLoader: false,
    message: "",
    color: "",
    community: orderFlightInfoModal.Community,
    checkIn: orderFlightInfoModal.MoveInDate,
    checkOut: orderFlightInfoModal.MoveOutDate,
    firstName: "",
    lastName: "",
    phoneNo: orderFlightInfoModal.BookedByPhone,
    emailId: orderFlightInfoModal.BookedByEmail,
    Flight: "",
    ArrivalAirport: "",
    Airline: "",
    notes: "",
  });

  function setValue(field, event) {
    let value = event.target.value;
    if (field === "phoneNo") {
      isValidPhoneNumber(value) && updateState({ [field]: value });
    } else {
      updateState({ [field]: value });
    }
  }

  function isValidPhoneNumber(number) {
    const phoneRegEx = /^\d{0,10}$/;
    return phoneRegEx.test(number);
  }

  function isValidEmail(email) {
    let emailRegEx = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return emailRegEx.test(email);
  }

  function handleKeyDown(e) {
    if (e.key === "ArrowLeft") {
      e.preventDefault();
      const newCursorPosition = Math.max(0, e.target.selectionStart - 1);
      e.target.setSelectionRange(newCursorPosition, newCursorPosition);
    } else if (e.key === "ArrowRight") {
      e.preventDefault();
      const newCursorPosition = Math.min(
        e.target.value.length,
        e.target.selectionStart + 1
      );
      e.target.setSelectionRange(newCursorPosition, newCursorPosition);
    }
  }

  function updateState(updateObj) {
    setState((prevState) => ({ ...prevState, ...updateObj }));
  }

  function checkInSelection(event) {}

  function flightInfoSubmit() {}

  return (
    <div
      className="modal fade savesearch tesimonial_form bd-example-modal-lg in bookingmodel flight-model "
      id="holdModal"
      role="dialog"
    >
      <div className="modal-dialog">
        <div className="modal-content rm-border-radius">
          <div className="modal-header">
            {state.modalLoader == true ? (
              ""
            ) : (
              <button
                type="button"
                className="close btn"
                data-dismiss="modal"
                aria-label=""
              >
                <i className="bi bi-x"></i>
              </button>
            )}
            <h4 className="modal-title text-center font-bold">
              Guest Information
            </h4>
          </div>
          <div className="modal-body bookit-search-form">
            <p className="text-center" style={{ color: state.color }}>
              {state.message}
            </p>
            <form role="form" method="post">
              <div>
                <div className="col-sm-12 communityName">
                  <span>
                    <strong>Community Name:</strong>
                  </span>
                  <span className="text-red">{state.community}</span>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <DatePicker
                      type="text"
                      className="form-control "
                      name="date"
                      placeholder="Due Date"
                      selected={state.checkIn}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <DatePicker
                      type="text"
                      className="form-control "
                      name="date"
                      placeholder="Due Date"
                      selected={state.checkOut}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First Name *"
                      value={state.firstName}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last Name *"
                      value={state.lastName}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <input
                      className="form-control"
                      id="phone2"
                      placeholder="Phone"
                      maxLength="10"
                      value={state.phoneNo}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Email *"
                      value={state.emailId}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Flight"
                      value={state.Flight}
                      onChange={(e) => setValue("Flight", e)}
                      onKeyDown={handleKeyDown}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="ArrivalAirport"
                      value={state.ArrivalAirport}
                      onChange={(e) => setValue("ArrivalAirport", e)}
                      onKeyDown={handleKeyDown}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <DatePicker
                      type="text"
                      className="form-control"
                      name="date"
                      placeholder="ArrivalDate"
                      selected={state.ArrivalDate}
                      onChange={(e) => checkInSelection(e)}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      timeCaption="time"
                      dateFormat="MM/DD/YYYY HH:mm"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Airline"
                      value={state.Airline}
                      onChange={(e) => state.setValue("Airline", e)}
                      onKeyDown={handleKeyDown}
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      placeholder="Arrival Notes"
                      value={state.notes}
                      onChange={(e) => state.setValue("notes", e)}
                      onKeyDown={handleKeyDown}
                    ></textarea>
                  </div>
                </div>
                <div className="clearfix"></div>
                {state.modalLoader == true ? (
                  <div className="panel-body_loader">
                    {" "}
                    <span
                      style={{
                        fontSize: "25px",
                        width: "100%",
                        margin: "auto",
                        textAlign: "center",
                      }}
                    >
                      <i className="fa fa-spinner fa-spin" /> please wait ...!
                    </span>
                  </div>
                ) : (
                  <button
                    className="btn location_btn"
                    type="button"
                    onClick={() => flightInfoSubmit()}
                  >
                    Submit
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlightInfoModal;
