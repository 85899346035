import SurveyAnalysisModal from './surveyAnalysisModal';
import {Link} from 'react-router-dom';
import ReactTable from "react-table";

const SurveyTableInformation=(props)=>{
    //console.log(props,"props");
    
    const filterMethod = (filter, row) => {
        const id = filter.pivotId || filter.id;
        if (row[id] !== null && typeof row[id] === "string") {
          return (row[id] !== undefined
            ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
            : true);
        }
      }
      function surveyAnalysisData(data){
      props.surveyAnalysisCount(data);

       }
 let columns = [
  {
  Header: 'Guest Name',
  id: 'order11',
  accessor: row=> (row.GFName+' '+row.GLName).toString(),
  style: {'textAlign': 'center'}
  },

  {
    Header: "PO#",
    id: 'purchaseOrder',
    accessor: row=> (row.PurchaseOrder!=null ?(row.PurchaseOrder).toString():'---') ,
    style: {'textAlign': 'center','whiteSpace': 'unset'}
    
    },
  {
    Header: "Order#",
    id: 'community_name',
    accessor: row=> (row.OrderUId),
    style: {'textAlign': 'center','whiteSpace': 'unset'},
    },
    {
    Header: "Info",
    id: 'Address1',
    //accessor: row=>  (<Link to="#" data-toggle="modal" className={"ctd-tbl"} data-target={"#surveyanalysis"} onClick={ (e) => surveyAnalysisData(row._original) }><img src='../images/info-icon.png'/></Link>),
    Cell: ({ row }) => {
        return (
        <div>
         <Link to="#" data-toggle="modal" className={"ctd-tbl"} data-target={"#surveyanalysis"} onClick={ (e) => surveyAnalysisData(row._original) }><img src='../images/info-icon.png'/></Link>
        </div>
    )},
    sortable: false,
    filterable: false,
    style: {'textAlign': 'center','whiteSpace': 'unset'},
    width: 75
    },
    
    
   
]


const {communitysLoading, surveyInfo} = props;
const Data =  columns


return(
<div>
    <div className="col-md-12 mrg-top-15">
    <ReactTable
    multiSort={true}
    defaultFilterMethod={filterMethod}
    loading={communitysLoading}
    data={surveyInfo.message}
    columns={Data}
    defaultPageSize={15}
    pageSizeOptions={[5, 10, 15, 20, 25, 50, 100,surveyInfo.message.length]}
    style={{height: "500px"}}
    sortable={true} 
    className="-striped -highlight analsys_table orderStatus_moadl"
    filterable= {true}
/>
</div>
<SurveyAnalysisModal {...props}/>
    </div>

    
)
}
export default SurveyTableInformation;
