import React, { Component } from "react";
import Guest from "../../common/guest";
import GuestHeader from '../../common/guestHeader';
import Footer from '../../common/footer';
import QuickRequestForm from './quickRequestForm';
import GuestServicesapi from '../../../services/guestServicesapi';
import SubFooter from "../../common/subFooter";
import SessionService from "../../../services/sessionService";
import { withRouter, Redirect } from 'react-router-dom';
import TripInfoData from './tripInfoData';
import moment from "moment";
import OwlCarousel from 'react-owl-carousel';
import DatePicker from 'react-datepicker';
import Loader from "../../common/loader";

export class pendingOrderInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pendingOrderInfo: [],
      userData: '',
      orderInfo: {},
      loader: false,
      showIfPendingInfoEmpty: false,
      ScheduleInfo: {},
      showTripInfo: false,
      ifLockBoxShowGuestPane: false,
      arriveBy: "",
      sameDayFlyAndMoveStatus: '',
      planeSchedule: null,
      arrivalAirport: '',
      airlineName: '',
      flightno: '',
      arrivalExpectation: null,
      extraInfo: '',
      errorMessage: '',
      OrderUId: '',
      alreadyBeInTheArea: '',
      scheduleFlexible: '',
      extraInfoByRoad: '',
      extraInfoByDontKnowYet: '',
      sliderValue: 0,
      standardCheckInTime: null,
      expectToReceiveItinerary: null,
      arrivalInfo: this.props.enableNavigateToActiveInfoFunc,
      image: "",
      ComplexId: "",
      selectedData: {}
    }
    this.closeModal = this.closeModal.bind(this);
    this.planeSchedule = this.planeSchedule.bind(this);
    this.arrivalExpectation = this.arrivalExpectation.bind(this);
    this.onSliderChange = this.onSliderChange.bind(this);
    this.standardCheckInTime = this.standardCheckInTime.bind(this);
    this.expectToReceiveItinerary = this.expectToReceiveItinerary.bind(this);
    this.extraInfoByRoad = this.extraInfoByRoad.bind(this);
    this.extraInfoByDontKnowYet = this.extraInfoByDontKnowYet.bind(this);
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.userAlreadyInPendingTab == "empObj") {
      this.sessionAndPendingInfo()
    }
  }

  async sessionAndPendingInfo() {
    await SessionService.SessionGet({ type: 'guest' }).then((response) => {
      this.getPendingOrderInfo(response.GuestEmail)
    })
  }

  async componentDidMount() {
    
    if (this.props.enableNavigateToActiveInfoFunc !== null) {
      this.setState({ showTripInfo: true, OrderUId: this.props.enableNavigateToActiveInfoFunc.OrderUId, image: this.props.enableNavigateToActiveInfoFunc.image, ComplexId: this.props.enableNavigateToActiveInfoFunc.ComplexId })
      this.navigateToActiveInfo(this.props.enableNavigateToActiveInfoFunc.OrderUId,
         this.props.enableNavigateToActiveInfoFunc.image, 
         this.props.enableNavigateToActiveInfoFunc.ComplexId,
         this.props.enableNavigateToActiveInfoFunc.OrderType)
    }

    await this.setState({ loader: true })
    SessionService.SessionGet({ type: 'guest' }).then((response) => {
      this.setState({ userData: response })
      this.getPendingOrderInfo(this.state.userData.GuestEmail)
    })
  }

  async getPendingOrderInfo(email) {
    // this.setState({ loader: true })
    var guestEmailObj = {
      guestEmail: email,
      status: "pending"
    }
    await GuestServicesapi.GetOrdersByGuestEmail(guestEmailObj).then((response) => {
      if (response.error !== 1) {
        var pendingInfo = response.message.Orders;

        pendingInfo.forEach((item, index) => {
          var community = item.Community
          community = community && community.split(" ");

          var city = item.City
          city = city && city.split(" ");

          var state = item.State
          state = state && state.split(" ");

          for (let i = 0, x = community && community.length; i < x; i++) {
            community[i] = community[i] && community[i][0].toUpperCase() + community[i].substr(1);
          }

          for (let i = 0, x = city && city.length; i < x; i++) {
            city[i] = city[i] && city[i][0].toUpperCase() + city[i].substr(1);
          }

          for (let i = 0, x = state && state.length; i < x; i++) {
            state[i] = state[i] && state[i][0].toUpperCase() + state[i].substr(1);
          }
          state = state && state.join(" ");
          city = city && city.join(" ");
          community = community && community.join(" ");
          state = state && state.charAt(0).toUpperCase() + state.substr(1);
          city = city && city.charAt(0).toUpperCase() + city.substr(1);
          community = community && community.charAt(0).toUpperCase() + community.substr(1);
          pendingInfo[index].State = state
          pendingInfo[index].City = city
          pendingInfo[index].Community = community
          if (pendingInfo.length > 0) {
            pendingInfo.sort(function compare(a, b) {
              var dateA = new Date(a.MoveInDate);
              var dateB = new Date(b.MoveInDate);
              return dateA - dateB;
            });
          }
          this.setState({ pendingOrderInfo: pendingInfo, loader: false, orderInfo: {} });

        })
      } if (response.message.Orders.length == 0) {
        this.setState({ loader: false, showIfPendingInfoEmpty: true, orderInfo: {} })
      }
    })
  }

  async navigateToActiveInfo(orderUID, image, ComplexId, type, Order) {
   console.log(Order,'Order')
    await this.setState({
      OrderUId: orderUID,
      errorMessage: "",
      arriveBy: "",
      sameDayFlyAndMoveStatus: '',
      arrivalAirport: '',
      planeSchedule: null,
      airlineName: '',
      flightno: '',
      arrivalExpectation: null,
      extraInfo: '',
      alreadyBeInTheArea: '',
      scheduleFlexible: '',
      extraInfoByRoad: '',
      sliderValue: 0,
      standardCheckInTime: null,
      expectToReceiveItinerary: null,
      extraInfoByDontKnowYet: '',
      ComplexId: "",
      selectedData: {}
    })

    this.setState({ image, ComplexId });
    var orderUIdObj = {
      OrderUId: orderUID
    }
    console.log(id)
    if (type == "S") {
      if (Order!=undefined) {
        GuestServicesapi.GetTripInfo({ orderUid: orderUIdObj.OrderUId }).then((tripInfoData) => {
          if (tripInfoData == null || tripInfoData.Message == "The request is invalid.") {
            this.setState({ showTripInfo: true, loader: false })
            GuestServicesapi.GetOrderInfoById(orderUIdObj).then((getOrderInfo) => {
              if (getOrderInfo.Message !== "The request is invalid.") {
                if (getOrderInfo.message.orderInfo.Entry == "Lockbox") {
                  this.setState({ ifLockBoxShowGuestPane: true, loader: false })
                }
              }
            })
            this.openArrivalInfoModal()
          } else {
            GuestServicesapi.GetOrderInfoById(orderUIdObj).then((getOrderInfo) => {
              if (getOrderInfo.Message !== "The request is invalid.") {
                this.setState({ orderInfo: getOrderInfo.message.orderInfo, ScheduleInfo: getOrderInfo.message.ScheduleInfo, loader: false })
                let obj = getOrderInfo.message.orderInfo;
                obj["CommunityImage"] = image
                obj["travelBy"] = tripInfoData.TravelBy
                obj["ComplexId"] = ComplexId
                obj["OrderType"] = type
                if ( tripInfoData.TravelBy == "Dont Know Yet" && (new Date() < new Date(obj.MoveInDate))) {
                  this.setState({ showTripInfo: true })
                  this.openArrivalInfoModal()
                } else {
                  this.props.history.push({
                    pathname: "/my-reservations",
                    state: obj
                  })
                }
              } else {
                this.props.history.push({
                  pathname: "/reservations",
                  state: orderUID,
                  isShowGuestPane: false
                })
              }
            })
          }
        })
      } else{
        GuestServicesapi.GetTripInfo({ orderUid: orderUIdObj.OrderUId }).then((tripInfoData) => {
         // console.log(tripInfoData, 'tripInfoData == null')
          if (tripInfoData == null || tripInfoData.Message == "The request is invalid.") {
            this.setState({ showTripInfo: true, loader: false })
            GuestServicesapi.GetOrderInfoById(orderUIdObj).then((getOrderInfo) => {
              if (getOrderInfo.Message !== "The request is invalid.") {
                if (getOrderInfo.message.orderInfo.Entry == "Lockbox") {
                  this.setState({ ifLockBoxShowGuestPane: true, loader: false })
                }
              }
            })
            this.openArrivalInfoModal()
          } else {
            GuestServicesapi.GetOrderInfoById(orderUIdObj).then((getOrderInfo) => {
              if (getOrderInfo.Message !== "The request is invalid.") {
                this.setState({ orderInfo: getOrderInfo.message.orderInfo, ScheduleInfo: getOrderInfo.message.ScheduleInfo, loader: false })
                let obj = getOrderInfo.message.orderInfo;
                obj["CommunityImage"] = image
                obj["travelBy"] = tripInfoData.TravelBy
                obj["ComplexId"] = ComplexId
                obj["OrderType"] = type
                  this.setState({ showTripInfo: true })
                  this.openArrivalInfoModal()
              } else {
                this.props.history.push({
                  pathname: "/reservations",
                  state: orderUID,
                  isShowGuestPane: false
                })
              }
            })
          }
        })

      }

    }
    else {
      this.props.selectedData(Order)
    }


  }




  openArrivalInfoModal() {
    document.body.classList.add('modal-open');
    $("#myModalInstructions").modal("show");
    $(function () {
      $(".fold-table tr.view").on("click", function () {
        $(this).toggleClass("open").next(".fold").toggleClass("open");
      });
    });

    $(document).ready(function () {
      var base_color = "rgb(230,230,230)";
      var active_color = "rgb(237, 40, 70)";



      var child = 1;
      var length = $("section").length - 1;
      $("#prev").addClass("disabled");
      $("#submit").addClass("disabled");

      $("section").not("section:nth-of-type(1)").hide();
      $("section").not("section:nth-of-type(1)").css('transform', 'translateX(100px)');

      var svgWidth = length * 200 + 24;
      $("#svg_wrap").html(
        '<svg version="1.1" id="svg_form_time" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 ' +
        svgWidth +
        ' 24" xml:space="preserve"></svg>'
      );

      function makeSVG(tag, attrs) {
        var el = document.createElementNS("http://www.w3.org/2000/svg", tag);
        for (var k in attrs) el.setAttribute(k, attrs[k]);
        return el;
      }

      for (let i = 0; i < length; i++) {
        var positionX = 12 + i * 200;
        var rect = makeSVG("rect", { x: positionX, y: 9, width: 200, height: 6 });
        document.getElementById("svg_form_time").appendChild(rect);
        // <g><rect x="12" y="9" width="200" height="6"></rect></g>'
        var circle = makeSVG("circle", {
          cx: positionX,
          cy: 12,
          r: 12,
          width: positionX,
          height: 6
        });
        document.getElementById("svg_form_time").appendChild(circle);
      }

      var circle = makeSVG("circle", {
        cx: positionX + 200,
        cy: 12,
        r: 12,
        width: positionX,
        height: 6
      });
      document.getElementById("svg_form_time").appendChild(circle);

      $('#svg_form_time rect').css('fill', base_color);
      $('#svg_form_time circle').css('fill', base_color);
      $("circle:nth-of-type(1)").css("fill", active_color);


      $(".button").click(function () {
        $("#svg_form_time rect").css("fill", active_color);
        $("#svg_form_time circle").css("fill", active_color);
        var id = $(this).attr("id");
        if (id == "next") {
          $("#prev").removeClass("disabled");
          if (child >= length) {
            $(this).addClass("disabled");
            $('#submit').removeClass("disabled");
          }
          if (child <= length) {
            child++;
          }
        } else if (id == "prev") {
          $("#next").removeClass("disabled");
          $('#submit').addClass("disabled");
          if (child <= 2) {
            $(this).addClass("disabled");
          }
          if (child > 1) {
            child--;
          }
        }
        var circle_child = child + 1;
        $("#svg_form_time rect:nth-of-type(n + " + child + ")").css(
          "fill",
          base_color
        );
        $("#svg_form_time circle:nth-of-type(n + " + circle_child + ")").css(
          "fill",
          base_color
        );
        var currentSection = $("section:nth-of-type(" + child + ")");
        currentSection.fadeIn();
        currentSection.css('transform', 'translateX(0)');
        currentSection.prevAll('section').css('transform', 'translateX(-100px)');
        currentSection.nextAll('section').css('transform', 'translateX(100px)');
        $('section').not(currentSection).hide();
      });

    });
  }

  async closeModal() {
    await this.setState({ showTripInfo: false, arrivalInfo: null })
    document.body.classList.remove('modal-open');
    $("#myModalInstructions").modal("hide");
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  sameDayFlyAndMoveStatus(e) {
    this.setState({ sameDayFlyAndMoveStatus: e.target.value })
  }

  setValue(e) {
    this.validate(e.target)
    var state = this.state;
    state[e.target.name] = e.target.value;
    this.setState({ state })
  }

  validate(input) {
    if (/^\s/.test(input.value))
      input.value = '';
  }

  async planeSchedule(date) {
    if (date !== null) {
      await this.setState({ planeSchedule: moment(date) });
    }
  }

  async arrivalExpectation(date) {
    if (date !== null) {
      await this.setState({ arrivalExpectation: moment(date) });
    }
  }

  /**
       * Function to submit by plane
       **/
  async byAir() {
    var currentDate = ""
    await this.getCurrentDateTime().then((response) => {
      currentDate = response
    })
    var planeScheduleDate = moment(this.state.planeSchedule).format("MM/DD/YYYY HH:mm")
    var arrivalExpectationDate = moment(this.state.arrivalExpectation).format("MM/DD/YYYY HH:mm")
    const _this = this
    const validationFields = [
      'sameDayFlyAndMoveStatus',
      'arrivalAirport',
      'airlineName',
      'flightno',
      'extraInfo'];
    let error = 0;
    _.forEach(validationFields, function(value) {
      if(_this.state[value] == ""){
        error++;
      }
  });
    if (this.state.planeSchedule == null) {
      error++
    }
    if (this.state.arrivalExpectation == null) {
      error++
    }
    if (error !== 0) {
      this.setState({ errorMessage: 'Please enter all fields.' })
    } else {
      if (planeScheduleDate < currentDate || arrivalExpectationDate < currentDate) {
        this.setState({ errorMessage: 'Please select valid date and time' })
      } else {
        // const userData = await SessionService.SessionGet({ type: 'guest' });
        this.setState({ errorMessage: '' });
        var planeObj = {
          "OrderUId": this.state.OrderUId,
          "TravelBy": "By Air",
          "Notes": this.state.extraInfo,
          "ChangedBy": "",
          "InTown": "",
          "Hoteltransfers": "",
          "FlyingSameDay": this.state.sameDayFlyAndMoveStatus,
          "FlightArrival": planeScheduleDate + ':00.000',
          "Airline": this.state.airlineName,
          "ArrivalAirport": this.state.arrivalAirport,
          "Flight": this.state.flightno,
          "ArrivalDate": arrivalExpectationDate + ':00.000'
        }

        const SaveOrderTripDetailsResponse = await GuestServicesapi.SaveOrderTripDetails(planeObj)
        if (SaveOrderTripDetailsResponse !== null) {
          if (SaveOrderTripDetailsResponse.hasOwnProperty('message')) {
            await this.setState({ planeSchedule: null, arrivalExpectation: null, sameDayFlyAndMoveStatus: '', uniqueId: SaveOrderTripDetailsResponse.UniqueId, tableId: SaveOrderTripDetailsResponse.TableId })
            this.airlineNameRef.value = "";
            this.arrivalAirportRef.value = "";
            this.flightnoRef.value = "";
            this.extraInfoRef.value = ""
            //  location.href = '/reservations'

            await this.setState({ showTripInfo: false })
            document.body.classList.remove('modal-open');
            $("#myModalInstructions").modal("hide");
            $('body').removeClass('modal-open');
            $('.modal-backdrop').remove();

            var orderUIdObj = {
              OrderUId: this.state.OrderUId
            }
            GuestServicesapi.GetOrderInfoById(orderUIdObj).then((getOrderInfo) => {
              if (getOrderInfo.Message !== "The request is invalid.") {
                this.setState({ orderInfo: getOrderInfo.message.orderInfo, ScheduleInfo: getOrderInfo.message.ScheduleInfo, loader: false })
                let obj = getOrderInfo.message.orderInfo;
                obj["CommunityImage"] = this.state.image;
                obj["ComplexId"] = this.state.ComplexId;
                obj["OrderType"] = this.state.arrivalInfo.OrderType;
                this.props.history.push({
                  pathname: "/my-reservations",
                  state: obj
                })

              } else {
                this.props.history.push({
                  pathname: "/reservations",
                  state: orderUID,
                  isShowGuestPane: false
                })
              }
            })
          } else {
            console.log("Failed to load trip details");
          }
        }
      }
    }
  }

  /**
 * Function to submit by road
 **/
  async byRoad() {
    var currentDate = ""
    await this.getCurrentDateTime().then((response) => {
      currentDate = response
    })
    var standardCheckInDateTime = moment(this.state.standardCheckInTime).format("MM/DD/YYYY HH:mm")
    const _this = this
    const validationFields = [
      'alreadyBeInTheArea',
      'sliderValue',
      'scheduleFlexible',
      'extraInfoByRoad'];
    let error = 0;
    _.forEach(validationFields, function(value) {
      if(_this.state[value] == ""){
        error++;
      }
  });
    if (this.state.standardCheckInTime == null) {
      error++
    }
    if (error !== 0) {
      this.setState({ errorMessage: 'Please enter all fields.' })
    } else {
      if (standardCheckInDateTime < currentDate) {
        this.setState({ errorMessage: 'Please select valid date and time' })
      } else {
        // const userData = await SessionService.SessionGet({ type: 'guest' });
        this.setState({ errorMessage: '' });
        var roadObj = {
          "OrderUId": this.state.OrderUId,
          "TravelBy": "By Road",
          "Notes": this.state.extraInfoByRoad,
          "ChangedBy": "",
          "DrivingMiles": this.state.sliderValue,
          "ArrivalDate": standardCheckInDateTime + ':00.000',
          "AlreadyBeInTheArea": this.state.alreadyBeInTheArea,
          "ScheduleFlexible": this.state.scheduleFlexible,
        }

        const SaveOrderTripDetailsResponse = await GuestServicesapi.SaveOrderTripDetails(roadObj)
        if (SaveOrderTripDetailsResponse !== null) {
          if (SaveOrderTripDetailsResponse.hasOwnProperty('message')) {
            await this.setState({ alreadyBeInTheArea: '', sliderValue: 0, scheduleFlexible: '', extraInfoByRoad: '', standardCheckInTime: null, uniqueId: SaveOrderTripDetailsResponse.UniqueId, tableId: SaveOrderTripDetailsResponse.TableId })
            this.sliderValueRef.value = "";
            this.extraInfoByRoadRef.value = "";
            //location.href = '/reservations'

            await this.setState({ showTripInfo: false })
            document.body.classList.remove('modal-open');
            $("#myModalInstructions").modal("hide");
            $('body').removeClass('modal-open');
            $('.modal-backdrop').remove();

            var orderUIdObj = {
              OrderUId: this.state.OrderUId
            }
            GuestServicesapi.GetOrderInfoById(orderUIdObj).then((getOrderInfo) => {
              if (getOrderInfo.Message !== "The request is invalid.") {
                this.setState({ orderInfo: getOrderInfo.message.orderInfo, ScheduleInfo: getOrderInfo.message.ScheduleInfo, loader: false })
                let obj = getOrderInfo.message.orderInfo;
                obj["CommunityImage"] = this.state.image
                obj["ComplexId"] = this.state.ComplexId
                obj["OrderType"] = this.state.arrivalInfo.OrderType
                this.props.history.push({
                  pathname: "/my-reservations",
                  state: obj
                })

              } else {
                this.props.history.push({
                  pathname: "/reservations",
                  state: orderUID,
                  isShowGuestPane: false
                })
              }
            })


          } else {
            console.log("Failed to load trip details");
          }
        }
      }
    }
  }

  /**
  * Function to submit by Dont know Yet
  **/
  async dontKnowYet() {
    var currentDate = ""
    await this.getCurrentDateTime().then((response) => {
      currentDate = response
    })
    var expectToReceiveItineraryDate = moment(this.state.expectToReceiveItinerary).format("MM/DD/YYYY HH:mm")
    let error = 0;
    if (this.state.expectToReceiveItinerary == null) {
      error++
    } if (this.state.extraInfoByDontKnowYet == '') {
      error++
    } if (error !== 0) {
      this.setState({ errorMessage: 'Please enter all fields.' })
    } else {
      if (expectToReceiveItineraryDate < currentDate) {
        this.setState({ errorMessage: 'Please select valid date and time' })
      } else {
        this.setState({ errorMessage: '' });
        var dontKnowYetObj = {
          "OrderUId": this.state.OrderUId,
          "TravelBy": "Dont Know Yet",
          "Notes": this.state.extraInfoByDontKnowYet,
          "ChangedBy": "",
          "ExpectedDate": expectToReceiveItineraryDate + ':00.000'
        }

        const SaveOrderTripDetailsResponse = await GuestServicesapi.SaveOrderTripDetails(dontKnowYetObj)
        if (SaveOrderTripDetailsResponse !== null) {
          if (SaveOrderTripDetailsResponse.hasOwnProperty('message')) {
            await this.setState({ expectToReceiveItinerary: null })
            this.extraInfoByDontKnowYetRef.value = "";
            //location.href = '/reservations'

            await this.setState({ showTripInfo: false })
            document.body.classList.remove('modal-open');
            $("#myModalInstructions").modal("hide");
            $('body').removeClass('modal-open');
            $('.modal-backdrop').remove();


            var orderUIdObj = {
              OrderUId: this.state.OrderUId
            }
            GuestServicesapi.GetOrderInfoById(orderUIdObj).then((getOrderInfo) => {
              if (getOrderInfo.Message !== "The request is invalid.") {


                this.setState({ orderInfo: getOrderInfo.message.orderInfo, ScheduleInfo: getOrderInfo.message.ScheduleInfo, loader: false })
                let obj = getOrderInfo.message.orderInfo;
                obj["CommunityImage"] = this.state.image
                obj["ComplexId"] = this.state.ComplexId
                obj["travelBy"] = "Dont Know Yet"
                obj["OrderType"] = this.state.arrivalInfo.OrderType
                this.props.history.push({
                  pathname: "/my-reservations",
                  state: obj
                })

              } else {
                this.props.history.push({
                  pathname: "/reservations",
                  state: orderUID,
                  isShowGuestPane: false
                })
              }
            })


          } else {
            console.log("Failed to load trip details");
          }
        }
      }
    }
  }

  /**
  * Function to get Current date and time
  */
  async getCurrentDateTime() {
    var date = moment();
    var curDate = moment(date).format("MM/DD/YYYY HH:mm")
    return curDate;
  }

  alreadyBeInTheArea(e) {
    this.setState({ alreadyBeInTheArea: e.target.value })
  }

  scheduleFlexible(e) {
    this.setState({ scheduleFlexible: e.target.value })
  }

  extraInfoByRoad(e) {
    this.validate(e.target)
    this.setState({ extraInfoByRoad: e.target.value })
  }

  async standardCheckInTime(date) {
    if (date !== null) {
      await this.setState({ standardCheckInTime: moment(date) });
    }
  }

  onSliderChange(e) {
    this.setState({ sliderValue: e.target.value });
  };

  async expectToReceiveItinerary(date) {
    if (date !== null) {
      await this.setState({ expectToReceiveItinerary: moment(date) });
    }
  }

  extraInfoByDontKnowYet(e) {
    this.validate(e.target)
    this.setState({ extraInfoByDontKnowYet: e.target.value })
  }

  render() {
    const nth = function (d) {
      if (d > 3 && d < 21) return 'th';
      switch (d % 10) {
        case 1: return "st";
        case 2: return "nd";
        case 3: return "rd";
        default: return "th";
      }
    }

    var moveOutDay = "",
      moveInDay = "",
      moveInDateYear = "",
      moveInDateMonth = "",
      moveInDate = "",
      moveOutDate = "",
      moveOutDateYear = "",
      moveOutDateMonth = "",
      communityName = "",
      communityCityName = "",
      communityStateName = "",
      communityZipCode = "",
      communityAddress = "",
      noticeToVacateDay = "",
      noticeToVacateYear = "",
      noticeToVacateMonth = "",
      noticeToVacateDate = "",
      suite = "",
      floor = "",
      bed = "",
      parkingSpace = "",
      gateCode = "",
      mailBox = "";
    if (this.state.orderInfo !== null) {
      const {
        MoveInDate,
        MoveOutDate,
        Community,
        CommunityCity,
        CommunityAddress,
        CommunityState,
        MasterSuite,
        MasterFloor,
        MasterNoOfBed,
        MasterParkingSpace,
        MasterGateCode,
        MasterMailBox,
        CommunityZipCode,
        NoticetoVacate
      } = this.state.orderInfo;
      moveInDay = moment(MoveInDate).format('dddd')
      moveInDateYear = moment(MoveInDate).format('YYYY')
      moveInDateMonth = moment(MoveInDate).format('MMMM')
      moveInDate = moment(MoveInDate).format('DD')
      moveOutDay = moment(MoveOutDate).format('dddd')
      moveOutDate = moment(MoveOutDate).format('DD')
      moveOutDateMonth = moment(MoveOutDate).format('MMMM')
      moveOutDateYear = moment(MoveOutDate).format('YYYY')
      communityName = Community
      communityCityName = CommunityCity
      communityStateName = CommunityState
      communityZipCode = CommunityZipCode
      communityAddress = CommunityAddress
      suite = MasterSuite
      floor = MasterFloor
      bed = MasterNoOfBed
      parkingSpace = MasterParkingSpace
      gateCode = MasterGateCode
      mailBox = MasterMailBox
      noticeToVacateDay = moment(NoticetoVacate).format('dddd')
      noticeToVacateYear = moment(NoticetoVacate).format('YYYY')
      noticeToVacateMonth = moment(NoticetoVacate).format('MMMM')
      noticeToVacateDate = moment(NoticetoVacate).format('DD')
    }
    return (
      <div>
        {this.state.arrivalInfo !== null ? <Loader /> : null}
        {this.state.showTripInfo ?
          <div className={"modal modal-fullscreen-xl modal fade animated tab-modal in"} id="myModalInstructions" tabIndex={-1} role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h1>Arrival Info</h1>
                  <button type="button" className="close" onClick={this.closeModal}>
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="communityDetails ">
                    <div className="sliderView arriavlInfo">
                      <div id="svg_wrap" />
                      <section>
                        <h1>ONE LAST STEP...</h1>
                        <h3>Thank you for choosing to stay with us. Let's confirm your arrival information so we can finalize your stay.</h3>
                        <h4>Looks like you still need to confirm your arrival details. This will help us
                          provide
                          a warm welcome
                          to your suite. Please verify below so that we can ensure a stress-free
                          relocation.
                          Need assistance?
                          Give us a call at 800.367.9501 or email us at <a href="mailto:guestserve@suiteamerica.com">guestserve@suiteamerica.com</a></h4>
                        <h5>I will be arriving by:
                        </h5>
                        <div className="arriavlInfoBtns">
                          <button type="button" className={this.state.arriveBy == "Plane" ? "btn active" : "btn"} onClick={() => this.setState({ arriveBy: "Plane" })}>
                            <img src="../images/orderImages/plane.png" alt="" />
                            <img className="activeImg" src="../images/orderImages/plane-2.png" alt="" />
                            <span>Plane</span> </button>
                          <button type="button" className={this.state.arriveBy == "Car" ? "btn active" : "btn"} onClick={() => this.setState({ arriveBy: "Car" })}>
                            <img src="../images/orderImages/car.png" alt="" />
                            <img className="activeImg" src="../images/orderImages/car-2.png" alt="" />
                            <span>Car</span> </button>
                          <button type="button" className={this.state.arriveBy == "QuestionSign" ? "btn active" : "btn"} onClick={() => this.setState({ arriveBy: "QuestionSign" })}>
                            <img src="../images/orderImages/question-sign.png" alt="" />
                            <img className="activeImg" src="../images/orderImages/question-sign-2.png" alt="" />
                            <span>I don't know yet
                            </span> </button>
                        </div>
                      </section>
                      {this.state.arriveBy == "Plane" ?
                        <section>
                          <form action>
                            {/* {this.state.arriveBy=="Plane"?} */}
                            <div>
                              <div className="form-group">
                                <label >Are you flying in the same day that you will be moving into
                                  your
                                  apartment?
                                </label>
                                <div className="arrivalRadiobtn">
                                  <label className="radio-inline">
                                    <input type="radio" name="optradio" checked={this.state.sameDayFlyAndMoveStatus === 'true'} value="true" onChange={(e) => this.sameDayFlyAndMoveStatus(e)} />Yes
                                  </label>
                                  <label className="radio-inline">
                                    <input type="radio" name="optradio" checked={this.state.sameDayFlyAndMoveStatus === 'false'} value="false" onChange={(e) => this.sameDayFlyAndMoveStatus(e)} />No
                                  </label>
                                </div>
                              </div>
                              <div className="form-group">
                                <label> Arrival Airport </label>
                                <input type="text" className="form-control" ref={(ref) => this.arrivalAirportRef = ref} name="arrivalAirport" placeholder="i.e. Chicago International" onChange={this.setValue.bind(this)} />
                              </div>
                              <div className="form-group">
                                <label >What airline will you be flying in on?
                                </label>
                                <div className="arrivalInput">
                                  <input type="text" ref={(ref) => this.airlineNameRef = ref}
                                    className="form-control" name="airlineName" placeholder="i.e. Alaska Airlines" onChange={this.setValue.bind(this)} />
                                </div>
                              </div>
                              <div className="form-group">
                                <label> What is your flight number? </label>
                                <input type="text" name="flightno" ref={(ref) => this.flightnoRef = ref} className="form-control" placeholder="i.e. GFM104" onChange={this.setValue.bind(this)} />
                              </div>
                              <div className="form-group">
                                <label> What time is your plane scheduled to land? </label>
                                {/* <input type="text" className="form-control" placeholder="21:35" /> */}
                                <div className="">
                                  <DatePicker type="text"
                                   className="form-control"
                                    ref={(ref) => this.planeScheduleRef = ref} 
                                    placeholderText="MM/DD/YYYY" 
                                    minDate={new Date()} 
                                    selected={this.state.planeSchedule} 
                                    name="planeSchedule" 
                                    onChange={this.planeSchedule} 
                                    dateFormat="MM/DD/YYYY hh:mm a" 
                                    showTimeSelect 
                                    onKeyDown={e => e.preventDefault()}
                                    timeIntervals={15} />
                                </div>
                              </div>
                              <div className="form-group">
                                <label>What time do you expect to arrive at your community? </label>
                                {/* <input type="text" className="form-control" placeholder="21:35" /> */}
                                <div className="">
                                  <DatePicker type="text"onKeyDown={e => e.preventDefault()} className="form-control" placeholderText="MM/DD/YYYY" minDate={new Date()} selected={this.state.arrivalExpectation} name="arrivalExpectation" onChange={this.arrivalExpectation} dateFormat="MM/DD/YYYY hh:mm a" showTimeSelect timeIntervals={15} />
                                </div>
                              </div>
                              <div className="form-group">
                                <label>Any additional information we should know?</label>
                                <textarea name="extraInfo" ref={(ref) => this.extraInfoRef = ref} className="form-control" rows={6} placeholder="Begin typing here…." defaultValue={""} onChange={this.setValue.bind(this)} />
                              </div>
                            </div>
                          </form>
                        </section>
                        : this.state.arriveBy == "Car" ?
                          <section>
                            <form action>
                              <div className="form-group">
                                <label >Will you already be in the area?
                                </label>
                                <div className="arrivalRadiobtn">
                                  <label className="radio-inline">
                                    <input type="radio" name="optradio1" checked={this.state.alreadyBeInTheArea === 'true'} value="true" onChange={(e) => this.alreadyBeInTheArea(e)} />Yes
                                  </label>
                                  <label className="radio-inline">
                                    <input type="radio" name="optradio1" checked={this.state.alreadyBeInTheArea === 'false'} value="false" onChange={(e) => this.alreadyBeInTheArea(e)} />No
                                  </label>
                                </div>
                              </div>
                              <div className="form-group">
                                <label >How many miles from your community will you be approximately?
                                </label>
                                <div className="arrivalInput">
                                  <div className="progress">
                                    {/* <div className="progress-bar" style={{ width: '60%' }} onChange={this.onSliderChange} step="1">
                                    </div>
                                    <div className="progress-value" ><span>{this.state.sliderValue}</span> <span>200+</span> </div> */}

                                    <input
                                      id="typeinp"
                                      className="progress-bar1"
                                      type="range"
                                      ref={(ref) => this.sliderValueRef = ref}
                                      min="0" max="200"
                                      value={this.state.sliderValue}
                                      onChange={this.onSliderChange}
                                      step="1" />
                                    <div className="progress-value"><span>{this.state.sliderValue}</span> <span>200+</span> </div>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group">
                                <label >Is your schedule flexible?
                                </label>
                                <div className="arrivalRadiobtn">
                                  <label className="radio-inline">
                                    <input type="radio" name="optradio" value="true" checked={this.state.scheduleFlexible === 'true'} onChange={(e) => this.scheduleFlexible(e)} />Yes
                                  </label>
                                  <label className="radio-inline">
                                    <input type="radio" name="optradio" value="false" checked={this.state.scheduleFlexible === 'false'} onChange={(e) => this.scheduleFlexible(e)} />No
                                  </label>
                                </div>
                              </div>
                              <div className="form-group">
                                <label> What time do you expect to arrive at your community? </label>
                                  <label className="small">Standard check in time is between 3p-10p.</label>
                                {/* <input type="text" className="form-control" placeholder="21:35" /> */}
                                <DatePicker type="text"onKeyDown={e => e.preventDefault()} className="form-control" placeholderText="MM/DD/YYYY" minDate={new Date()} selected={this.state.standardCheckInTime} name="standardCheckInTime" onChange={this.standardCheckInTime} dateFormat="MM/DD/YYYY hh:mm a" showTimeSelect timeIntervals={15} />
                              </div>
                              <div className="form-group">
                                <label>Any additional information we should know?</label>
                                <textarea name="issues" className="form-control" rows={6} placeholder="Begin typing here…." defaultValue={""} onChange={this.extraInfoByRoad} ref={(ref) => this.extraInfoByRoadRef = ref} />
                              </div>
                              {/* <div class="view-buttons">
                                      <button type="submit" class="btn ">Submit</button>
                                  </div> */}
                            </form>
                          </section> :
                          <section>
                            <form >
                              <div className="form-group">
                                <label >We understand that you may not know your arrival information
                                  yet. <br />When do you expect to receive your itinerary? We will check in
                                  with you again on that date.
                                </label>
                                <div className="arrivalInput">
                                  {/* <input type="text" className="form-control" placeholder="2021-07-15" /> */}
                                  <DatePicker type="text"onKeyDown={e => e.preventDefault()} className="form-control fa fa-calendar" placeholderText="MM/DD/YYYY" minDate={new Date()} selected={this.state.expectToReceiveItinerary} name="expectToReceiveItinerary" onChange={this.expectToReceiveItinerary} dateFormat="MM/DD/YYYY hh:mm a" showTimeSelect timeIntervals={15} />
                                </div>
                              </div>
                              <b className="m-b-10">Standard check in time is between 3p-10p. 72 hour notice
                                is required to
                                assure your arrival time runs smoothly. Any questions? Give us a call at
                                800.367.9501 or email us at <a href="mailto:guestserve@suiteamerica.com">guestserve@suiteamerica.com</a></b>
                              <div className="form-group m-t-15">
                                <label>Any additional information we should know?</label>
                                <textarea name="extraInfoByDontKnowYet" className="form-control" onChange={this.extraInfoByDontKnowYet} ref={(ref) => this.extraInfoByDontKnowYetRef = ref} rows={6} placeholder="Begin typing here…." defaultValue={""} />
                              </div>
                              {/* <div class="view-buttons">
                                     <button type="submit" class="btn ">Submit</button>
                                 </div> */}
                            </form>
                          </section>}
                      {this.state.errorMessage !== '' ? <p style={{ color: "red" }}>{this.state.errorMessage}</p> : null}
                      <div className="text-right nextView">
                        <div className="button btn mrg-right-15" id="prev" onClick={() => this.setState({ errorMessage: "" })}>Previous</div>

                        <div className="button btn" id={!this.state.arriveBy ? "" : "next"} disabled={!this.state.arriveBy}>Next</div>

                        <div className="button btn" id="submit" onClick={this.state.arriveBy == "Plane" ? () => this.byAir() : this.state.arriveBy == "Car" ? () => this.byRoad() : () => this.dontKnowYet()}>Submit</div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          :
          this.state.pendingOrderInfo.length > 0 ?
            <div>
              {this.state.loader == false ?



                <div className="home-slider">
                  {this.state.pendingOrderInfo.map((pendingData, index) => {
                    return (
                      <div className="card" key={index}>
                        <img src={(pendingData.CommunityImage == null || pendingData.CommunityImage == "") ? pendingData.OrderType == "S" ? "../images/dummyCommunity.jpg" : "../images/s-dummyImage-2.png" : pendingData.CommunityImage} alt="" className="card-img-top " />

                        <div className="card-body">
                          {pendingData.OrderType == "S" ? <h1>{pendingData.Community}</h1> : pendingData.HotelName ? <h1>{pendingData.HotelName}</h1> : <h1>Hotel Name</h1>}
                          {pendingData.OrderType == "S" && <p style={{ textTransform: 'capitalize' }} >{pendingData.City ? (pendingData.City).toLowerCase() : "San Francisco"}, {pendingData.State ? (pendingData.State).toLowerCase() : "California"}</p>}

                          {pendingData.OrderType == "H" &&
                            <p >{pendingData.HotelCity ? (pendingData.HotelCity).toLowerCase() : "San Francisco"}, {pendingData.HotelState ? (pendingData.HotelState).toLowerCase() : "California"}</p>
                          }

                          <span className="btm">
                            <a>{(moment(pendingData.MoveInDate).format('DD'))} {moment(pendingData.MoveInDate).format('MMMM')} {moment(pendingData.MoveInDate).format('YYYY')} - {(moment(pendingData.MoveOutDate).format('DD'))} {moment(pendingData.MoveOutDate).format('MMMM')} {moment(pendingData.MoveOutDate).format('YYYY')}</a>
                            <button type="button" className="btn" onClick={() => this.navigateToActiveInfo(pendingData.OrderUId, pendingData.CommunityImage, pendingData.ComplexId, pendingData.OrderType, pendingData)}>See Details</button>
                          </span>
                        </div>
                      </div>
                    )
                  })
                  }
                </div>

                : <Loader />}
            </div>
            :
            this.state.loader ?
              <Loader /> : this.state.showIfPendingInfoEmpty ?
                <p style={{ color: "red", marginTop: "35px", textAlign: "center" }}>You don't have any upcoming reservations.</p>
                : null
        }


      </div>
    )
  }
}

export default withRouter(pendingOrderInfo)

