/* Importing the node modules, child components, services and controllers used 
   inside BusinessFurnishingPdf component */

   /* BusinessFurnishingPdf Component initialization */
   class BusinessFurnishingPdf extends React.Component{
       /* Initializing objects of its BusinessFurnishingPdf class */
       constructor(props) {
           super(props)
           /* BusinessFurnishingPdf Component State variables Initialization */
           this.state = {
              
           }
       }
       /* It is invoked immediately after a component is mounted */

       componentWillMount(){
        window.location = "https://suiteam-website.s3.us-west-1.amazonaws.com/PDF/Business-furnishing.pdf";
       }

   /* It is invoked to return html content */      
   render(){
    var PDF="https://suiteam-website.s3.us-west-1.amazonaws.com/PDF/Business-furnishing.pdf"
       return(   
             <div className="pdfdownload">
            <img src="assets/images/pdfbg.png" className="pdfbg" />
            <div className="pdfdownload_con">
            <img src="/assets/newUI-images/dean/SA_logoB_widthSM.svg" />
            <div className="col-md-5">
            <p>Your web browser doesn't have a PDF plugin.
            Instead you can click here to</p>
            </div>
            <a href={PDF} className="btn btn-rounded clearfix" style={{zIndex:'999', position:"relative"}}>Download PDF</a>
            </div>
        </div>
       );
   }
   }
   export default BusinessFurnishingPdf;

   
   