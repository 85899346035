const ParkingInfo=(props)=>{
    return(
      <div>
      <div className="parkingInfo">
        <p>If arriving during the business hours of 9am-6pm, proceed directly to the Leasing Office to obtain keys and remotes. If not, please notify your Guest Services Rep of your after-hours arrival time, and they will provide directions about how to obtain your keys and remotes.</p>
      <div className="top_park">
        <div className="item">
        <span className="park_count">1</span>
          <img src="/assets/newUI-images/lakeImages/lakesidepaking-1.png" alt="" width="100%" />
          <p> When arriving to 1250, pull up to the garage gate, labeled 1252. Use the garage remote to open the gate </p>
        </div>
        <div className="item">
        <span className="park_count">2</span>
          <img src="/assets/newUI-images/lakeImages/lakesidepaking-2.png" alt="" width="100%" />
          <p>Pull into the garage and park in any available space. Parking is currently unassigned.</p>
        </div>
      </div>
      <div className="top_park justify-content-center mrg-top-50 mrg-btm-30">
        <div className="item">
        <span className="park_count">3</span>
          <img src="/assets/newUI-images/lakeImages/lakesidepaking-3.png" alt="" width="100%" />
          <p>There are 2 elevators in the parking garage. Both elevators have access to all floor levels</p>
        </div>
        
      </div>
    </div>
      </div>
    )
}

export default ParkingInfo;