const NikeInternFooter  = () => {
        return (            
                 <div className="text-center">
                        
                         <strong>RELOCATION COORDINATOR - KEY ACCOUNTS</strong> 
                         <div className="bottom_intern_add">
                        
                                 <div className="item">
                                 <h5 className="rdtxt-clr">Will Kerr</h5>
                         <h5><i className="fa fa-phone-square"></i>
                             <span><a href="tel:+1-916-573-9029">916.573.9029</a></span>
                         </h5>
                         <h5><a href="mailto:nvidiatravel@suiteamerica.com">nvidiatravel@suiteamerica.com</a></h5>
                                 </div>                                 
                         </div>
                         
                        
                 </div>
         );
}
export default NikeInternFooter;