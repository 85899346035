const CommunityInfo=(props)=>{
const row=props.rowInfo
return(
    <div className="row">
        {row.community_name ==null && row.HotelName!=null ?
        <div className="col-md-6">
            <div className="row">
                <div className="form-group">
                    <label className="col-md-6">Hotel Address</label>
                    <div className="col-md-6 td_foldaddress">
                        <p>{row.HAddress1} </p>
                        <p>{row.HState}</p>
                        <p>{row.HCity + ', ' + row.HZipCode}</p>
                        <label className="input_label">
                         <a href={'https://www.google.com/maps/place/' + row.HAddress1 +','+row.HState +','+row.HCity} target="_blank">Get Directions</a>
                        </label>
                    </div>
                </div>
             </div>
        </div>:<div><div className="col-md-6">
            <div className="row">
                <div className="form-group">
                    <label className="col-md-6">Floor</label>
                    <div className="col-md-6">
                        <label className="input_label">{(row.Floor == null || row.Floor == '') ? "---" : row.Floor}</label>
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-md-6">Suite Phone</label>
                    <div className="col-md-6">
                        <label className="input_label">{(row.SuitePhone == null || row.SuitePhone == '') ? "---" : row.SuitePhone}</label>
                    </div>
                </div>               
                <div className="form-group">
                    <label className="col-md-6">Mailbox</label>
                        <div className="col-md-6">
                            <label className="input_label">{(row.MailBox == null || row.MailBox == '') ? "---" : row.MailBox}</label>
                        </div>
                </div>
                <div className="form-group">
                    <label className="col-md-6">Parking Space</label>
                        <div className="col-md-6">
                            <label className="input_label">{(row.ParkingSpace1 == null || row.ParkingSpace1 == '') ? "---" : row.ParkingSpace1}</label>
                        </div>
                </div>
                <div className="form-group">
                    <label className="col-md-6">Square Feet</label>
                        <div className="col-md-6">
                            <label className="input_label">{(row.SquareFootage == null || row.SquareFootage == '') ? "---" : row.SquareFootage}</label>
                        </div>
                </div>
            </div>
        </div>
        <div className="col-md-6">
            <div className="row">
                <div className="form-group">
                    <label className="col-md-6">Suite Address</label>
                    <div className="col-md-6 td_foldaddress">
                        <p>{row.address} </p>
                        <p>{row.statename}</p>
                        <p>{row.city_name + ', ' + row.ZipCode}</p>
                        <label className="input_label">
                         <a href={'https://www.google.com/maps/place/' + row.address +','+row.statename +','+row.city_name} target="_blank">Get Directions</a>
                        </label>
                    </div>
                </div>
             </div>
        </div></div>}
    </div>

)
}
export default CommunityInfo;


