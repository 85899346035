/* Importing the node modules, child components, services and controllers used 
   inside Teams component */

/* Teams Component initialization */
class Teams extends React.Component{
	/* Initializing objects of its Teams class */
	constructor(props) {
		super(props)
		/* Teams Component State variables Initialization */
		this.state = {
		  showView: -1,
		}
	  }
/* It is invoked to return html content */	
render(){
    return(
        <div id="intranet-teams">
      <div className="container">
    <div className="panel-group" id="accordion">
      <div className="panel panel-default">
        <div className="panel-heading">
          <h4 data-toggle="collapse" data-parent="#accordion" href="#collapse0" className="panel-title expand">
             <div className="right-arrow pull-right">+</div>
          </h4>
                      <p>Teams</p>
        </div>
          <div className="panel-body" id="TEAMS">
{/* only three are displaying  */}
          <div className="row">
          <div className="col-md-3">
              <div className="first_box">
                  <div className="first_boxin">
                      <h1>{this.props.teamsData.length}
                      <p>TEAMS</p>
                      </h1>
                  </div>
              </div>
          </div>
          {this.props.teamsData.slice(0,3)
            .map((row, index) => {
                return (
          <div key={index}>  
        <div className="col-md-3">
			<div className="second_box staff_box">
				<div className="second_boxin">
					<div className="second_centerbox">
						<h1>{row.name}</h1>
					</div>
				</div>
				<p><span>{row.co}</span>Employees</p>
			</div>
		</div>
    </div>
  ) })}
      </div>
{/* after three are displaying */}
      <div id="collapse0" className="panel-collapse collapse">
          <div className="panel-body">
                      {this.props.teamsData.slice(3)
                        .map((row, index) => {
                            return (
                    <div className="col-md-3" key={index}>
                      <div className="second_box staff_box">
                        <div className="second_boxin">
                          <div className="second_centerbox">
                            <h1>{row.name}</h1>
                          </div>
                        </div>
                        <p><span>{row.co}</span>Employees</p>
                      </div>
                </div>
              ) })}
          </div>
        </div>

        </div>
      </div>
    </div> 
  </div>
  
         
         
  </div>
    );
}
}
export default Teams;

