/* Importing the node modules, child components, services and controllers used 
   inside EmploymentSearch component */
import MainController from "../controller/mainController";
import SubFooter from "./subFooter";
import GuestHeader from './guestHeader';
import Footer from './footer';
/* EmploymentSearch Component initialization */
class EmploymentSearch extends React.Component {
    /* Initializing objects of its EmploymentSearch class */
    constructor(props) {
        super(props);
    }
    /* It is invoked immediately before mounting occurs */
    componentWillMount() {
        MainController.getInitialInformation();   
    }
    /* It is invoked to return html content */
    render() {
        return (
            <div>
                <GuestHeader/>
                <div className="company_section testmonial_gap bg-grey">
                    <div className="container privacy_policy">
                        <div className="global-coverage-wrap col-md-10 col-md-offset-1 wow fadeInUp" data-wow-delay="0.5s">
                            
                            <h1 className="text-center f-300 gotham-font large-font mrg-btm-20">SuiteAmerica Career Search</h1>
                            <div className="col-md-8 col-md-offset-2 text-center">
                                <p className="text-center mrg-btm-30">Browse our current career opportunities below.
                                </p>                        
                            </div>
                        </div>
                        <div id="wowslider-container1">
                            <iframe src="https://www.paycomonline.net/v4/ats/index.php?/jobs&amp;clientkey=6CF34458764AECE5D85F85A6596F181E" scrolling="yes" allowtransparency="yes" width="100%" height="750px" frameBorder="0">
                                
                            </iframe>
                        </div>
                    </div>
                </div>
                <SubFooter />
                <Footer/>
            </div>             
        );
    }
}
export default EmploymentSearch;