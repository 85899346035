const ThirdRow = props => {
    const requestDetails = props.requestDetails
    const NotesData = (requestDetails.Notes == '' || requestDetails.Notes == null)? [] : requestDetails.Notes.split('####')
    return (
        <div>
        <div className="sr_form_wraper mrg-top-40">
                   <div className="qwer_half qwer22 notes_writing sc-order-fl-item">
                      <h1>Show Task:</h1>
                      <ul className="mrg-btm-20">
                            <div className="scrollbar" style={{marginTop: '20px'}}>
                                <div><p>{requestDetails.tasks == null ? '---' : requestDetails.tasks}</p></div>
                            </div>
                    </ul>
                   </div>
                   <div className="qwer_half qwer22 notes_writing sc-order-fl-item">
                      <h1>Pick List:</h1>
                      <ul className="mrg-btm-20">
                        <div className="scrollbar" style={{marginTop: '20px'}}>
                            <div><p>{requestDetails.PickList == null ? '---' : requestDetails.PickList}</p></div>
                        </div>
                    </ul>
                   </div>
        </div>

        <div className="sr_form_wraper mrg-top-40">
        <div className="qwer qwer22 notes_writing sc-order-fl-item">
        <h1>NOTES:</h1>
        <ul className="mrg-btm-20">
        {NotesData.length > 0 ?
        <div className="scrollbar" style={{marginTop: '20px'}}>
        {_.filter(NotesData, function(o) { return o!=''; }).map((notesText, index) => {
            return <div key={index} dangerouslySetInnerHTML={{__html: $.parseHTML(notesText)[0].data}}></div>
            })}
        </div> : <div className='text-center'>No notes available for this order</div>}
        </ul>
        </div>
        </div>
</div>
    );
  };

  export default ThirdRow;