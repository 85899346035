import React, { Component } from 'react'
import Footer from '../../common/footer'
import GuestHeader from '../../common/guestHeader'
import SubFooter from '../../common/subFooter'
import GuestServicesapi from '../../../services/guestServicesapi';
import ActiveOrderInfo from './activeOrderInfo';
import moment from 'moment';

export class guestOrderData extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menuShow: false,
      OrderUId: '',
      userData: '',
      orderInfo: {},
      tab: "current",
      isShowGuestPane: true,
      userAlreadyInPendingTab: "empObj",
      pendingOrderInfo: false,
      getorderid: '',
      alldata: {},
      baseid: 9
    }
  }

  async componentDidMount() {
    // Pending, Active,Termed

    let ordersid = {
      orderid: this.props.match.params.id,
    }
    let getdata = await GuestServicesapi.GetOrderInformation(ordersid)
    if (getdata != undefined && getdata.orderUId != null) {

      if ((getdata.Status).toLowerCase() == "active" || (getdata.Status).toLowerCase() == "pending") {

        await this.setState({ baseid: 0 })

      } else if ((getdata.Status).toLowerCase() == "termed" && getdata.MoveOutDate != null) {

        let date1 = moment(getdata.MoveOutDate);
        let date2 = moment();
        var days = date1.diff(date2, 'days');

        console.log(days, 'days')
        
        if (days >= (-30)) {
          await this.setState({ baseid: 0 })
        } else {
          await this.setState({ baseid: 1 })
        }

      } else {
        await this.setState({ baseid: 1 })
      }
      await this.setState({ getorderid: getdata.orderNo, alldata: getdata })

    } else {
      this.props.history.push("/mismatch-orderid")
    }
  }



  render() {
    return (
      <div>
        <GuestHeader />

        {/* <QuickRequestForm menuShow={this.state.menuShow} /> */}
        <div>
          <div className="guest-portalnewpage">
            <div className="confirmation">
              <div className="container">
                {(this.state.getorderid != '' && this.state.baseid == 0) ?
                  <ActiveOrderInfo orderUID={this.props.location.state} getorderid={this.state.getorderid} />
                  : <div>
                    <h2>Your confirmation has been expired.</h2>
                  </div>}
              </div>
            </div>
          </div>
        </div>
        <SubFooter />
        <Footer />
      </div>
    )
  }
}

export default guestOrderData
