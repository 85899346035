const QuickRequest = props => {  
    let object = props.quickobj
    var community;
    var SuiteNo;
    var orderId = object.TableId == '' || object.TableId == null ?   '---':object.TableId;
    var sTableId = object.sTableId == '' || object.sTableId == null ? '---':object.sTableId; 
    var masterId = object.mastertableId == '' || object.mastertableId == null ?   '---':object.mastertableId; 
    if(object.orderType=='H'){
         community = object.HotelName == '' || object.HotelName == null ? '---':object.HotelName;
         SuiteNo = object.HotelSuiteNo == '' || object.HotelSuiteNo == null ? '---':object.HotelSuiteNo;
    }
    else{
         community = object.community_name == '' || object.community_name == null ?   '---':object.community_name; 
         SuiteNo = object.SuiteNo == '' || object.SuiteNo == null ?   '---':object.SuiteNo;
    }
        return (
    <div className="modal fade savesearch urgentRequestPopup" id="quickModal" role="dialog">
    <div className="modal-dialog">
        <div className="modal-content rm-border-radius">
            <div className="modal-header">
            {props.modalLoader? '':
            <button type="button" className="close btn" data-dismiss="modal" aria-label="">
            <i className="bi bi-x"></i>
            </button>}
            </div>
            <div className="modal-body">
            {props.quicksuccess==''&&<h2>Urgent Request</h2>}
           
            {props.quicksuccess==''?  <form role="form" method="post" >
                <div className="topView">
                <div className="form-group">
                    <label>SuiteNo #<b>:</b> </label>
                    <label className="value">{SuiteNo}</label>
                </div>
                <div className="form-group">
                    <label>OrderId #  <b>:</b>  </label>
                    <label className="value">{orderId}</label>
                </div>
                <div className="form-group">
                    <label>Master #  <b>:</b>  </label>
                    <label className="value">{masterId}</label>
                </div>
                <div className="form-group">
                    <label>Record # <b>:</b>  </label>
                    <label className="value">{sTableId}</label>
                </div>
               
                </div>
                <div className="form-group text-left">
                    <label>Community <b>:</b>  </label>
                    <label className="value">{community}</label>
                </div>
                <p className="text-center" style={{color:props.color}}>{props.message}</p>
             <div className="">
                 <div className="mrg-btm-30">
                  <textarea className="form-control" placeholder="Begin typing here…" value={props.quickText} onChange={(e)=>props.setValue('quickText',e)}></textarea>
                  </div>
                </div>
               <div className="clearfix"></div>
            {props.modalLoader? <div className="panel-body_loader" > <span style={{fontSize: '25px', width: '100%', margin: 'auto', textAlign: 'center'}}><i className="fa fa-spinner fa-spin"/> please wait ...!</span>
             </div>:<button
                    className="btn location_btn"
                    type="button" onClick={() =>props.quickSubmit()}>Submit</button>}
            </form>:
            <form role="form" >
            <div className="form-group">
                <div className="col-md-12 mrg-top-10">
                 <p className="alert alert-success">{props.quicksuccess}</p>
                 </div>
               </div>
              <div className="clearfix"></div>
           </form>}
            </div>
        </div>
    </div>
    </div>
    
        );
      };
    
      export default QuickRequest;