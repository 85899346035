/* Importing the node modules, child components, services and controllers used 
   inside PTSImpression component */    
   import $ from "jquery";
   import AppController from "../../controller/appController";
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import NikeServices from '../../../services/nikeService';
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   import ReCAPTCHA from "react-google-recaptcha"
   import SuccessModal from '../../common/successModal';
   /* PTSImpression Component initialization */
   class TourSelection extends React.Component {
       /* Initializing objects of its PTSImpression class */
       constructor(props) {
           super(props)
           this.state={
               CaptchaValue: '',
               communityName: '',
               suiteNumber: '',
               customerNumber: '',
               salesManager: '',
               master: '',
               reservation: '',
               tourDateTime: '',
               leasingOffice: '',
               executiveGiftCard1: '',
               executiveGiftCard2: '',
               lockbox: '',
               pastaBasket: '',
               welcomeGusetBook: '',
               kidsBook: '',
               petBook: '',
               exective: '',
               furniturePackageList: ['Ambassador','Business','Classic'],
               livingRoomList: ['LCD TV 26"','LCD TV 32"','LCD TV 36"','LCD TV 42"','LCD TV 25"','50" - 55" TV'],
               bedRoomList: ['LCD TV 26"','LCD TV 32"','LCD TV 36"','LCD TV 42"','LCD TV 25"','50" - 55" TV'],
               furniturePackage: '',
               livingRoom: '',
               bedRoom: '',
               housewareList:['Standard Houseware Package (White appliances)','Upgraded Housewares Package #2 (stainless appliances)'],
               pationOutdoorList:['Pastic Patio Set (2 chairs & small table)','Upgraded Patio Set','Outdoor Pillow Pkg &  lantern'],
               cookWareList: ['International Package','Bakeware Package','Upgraded Teflon Cookware'],
               houseware: '',
               patioOutDoor: '',
               cookware: '',
               appleTv: '',
               kidsPackage: '',
               kidsAge:'',
               dogsPackage: '',
               catPackage: '',
               officePackage: '',
               starterGroceries: '',
               lancheonOfGuest: '',
               waterSnacksGuest: '',
               comments: '',
               giftBasket:'',
               giftBasketdropdown:['Executive Gift Basket 1', 'Executive Gift Basket 2','Pasta basket','Movie Basket','Coffee Basket','Tea basket'],         
               entryArrangement:'',
               entryArrangementDropdown:['Lockbox','Leasing Office','P/U Keys at Office'],
               gender:'',
               decoPackage:'',
               decoPackageDropdown:['Standard Glass Deco', 'Deco 1', 'Deco 2', 'Deco 3']
            }
            this.setValue = this.setValue.bind(this);
            this.setChecked = this.setChecked.bind(this);
            this.clearData = this.clearData.bind(this);
            this.serviceRequest = this.serviceRequest.bind(this);
            this.onChangeCaptcha = this.onChangeCaptcha.bind(this);
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
           MainController.getInitialInformation();
        }
        /* Form Details object creations  */

      setValue(field, e) {
            var object = {};
            object[field] = e.target.value;
            this.setState(object);
          }
      /* Form Details object creations  */

      setChecked(field, e) {
        var object = {};
        object[field] = e.target.value;
        this.setState(object);
      }   
      checkboxValue(field, e){
        var object = {};
        object[field] = e.target.checked ? "Yes" : "No";
        this.setState(object);
      }
      clearData(){
        this.setState({
            CaptchaValue: '',
            communityName: '',
            suiteNumber: '',
            customerNumber: '',
            salesManager: '',
            master: '',
            reservation: '',
            tourDateTime: '',
            leasingOffice: '',
            executiveGiftCard1: '',
            executiveGiftCard2: '',
            lockbox: '',
            pastaBasket: '',
            welcomeGusetBook: '',
            kidsBook: '',
            petBook: '',
            exective: '',
            furniturePackage: '',
            livingRoom: '',
            bedRoom: '',
            houseware: '',
            patioOutDoor: '',
            cookware: '',
            appleTv: '',
            kidsPackage: '',
            kidsAge:'',
            dogsPackage: '',
            catPackage: '',
            officePackage: '',
            starterGroceries: '',
            lancheonOfGuest: '',
            waterSnacksGuest: '',
            comments: '',
            giftBasket:'',
            entryArrangement:'',
            gender:'',
            decoPackage:''
 
        })
        $('input[name="1"]').prop('checked', false);
        $('input[name="2"]').prop('checked', false);
        $('input[name="3"]').prop('checked', false);
        $('input[name="4"]').prop('checked', false);
        $('input[name="5"]').prop('checked', false);
        $('input[name="6"]').prop('checked', false);
        $('input[name="7"]').prop('checked', false);
        $('input[name="8"]').prop('checked', false);

        $('input[name="checkbox1"]').prop('checked', false);
        $('input[name="checkbox2"]').prop('checked', false);
        $('input[name="checkbox3"]').prop('checked', false);
        $('input[name="checkbox4"]').prop('checked', false);
        $('input[name="checkbox5"]').prop('checked', false);
        $('input[name="checkbox6"]').prop('checked', false);
        $('input[name="checkbox7"]').prop('checked', false);
        $('input[name="checkbox8"]').prop('checked', false);
        $('input[name="checkbox9"]').prop('checked', false);
        $('input[name="checkbox10"]').prop('checked', false);
        $('input[name="checkbox11"]').prop('checked', false);
        AppController.menuTopNavigation('serviceRequest');
      }
       /* Service Request Details send to mail  */
       async serviceRequest(){
           const _this = this;
        const validationFields=['CaptchaValue']
        let error = 0;
        _.forEach(validationFields, function(value) {
           if(_this.state[value] == ""){
             error++;
           }
       });
      if(error == 0){
            const Request={
                CaptchaValue:this.state.CaptchaValue,
                communityName: this.state.communityName,
                suiteNumber: this.state.suiteNumber,
                customerNumber: this.state.customerNumber == '' ? '---' : this.state.customerNumber,
                salesManager: this.state.salesManager == '' ?'---' : this.state.salesManager,
                master: this.state.master == '' ? '---' : this.state.master,
                reservation: this.state.reservation == '' ? '---' : this.state.reservation,
                tourDateTime: this.state.tourDateTime == '' ? '---' : this.state.tourDateTime,
                leasingOffice: this.state.leasingOffice == '' ? '---' : this.state.leasingOffice,
                executiveGiftCard1: this.state.executiveGiftCard1 == '' ? '---' : this.state.executiveGiftCard1,
                executiveGiftCard2: this.state.executiveGiftCard2 == '' ? '---' : this.state.executiveGiftCard2,
                lockbox: this.state.lockbox == '' ? '---' : this.state.lockbox,
                pastaBasket: this.state.pastaBasket == '' ? '---' : this.state.pastaBasket,
                welcomeGusetBook: this.state.welcomeGusetBook == '' ? '---' : this.state.welcomeGusetBook,
                kidsBook: this.state.kidsBook == '' ? '---' : this.state.kidsBook,
                petBook: this.state.petBook == '' ? '---' : this.state.petBook,
                exective: this.state.exective == '' ?  '---' : this.state.exective,
                furniturePackage: this.state.furniturePackage == '' ? '---' : this.state.furniturePackage,
                livingRoom: this.state.livingRoom == '' ? '---' : this.state.livingRoom,
                bedRoom: this.state.bedRoom == '' ? '---' : this.state.bedRoom,
                houseware: this.state.houseware == '' ? '---' : this.state.houseware,
                patioOutDoor: this.state.patioOutDoor == '' ? '---' : this.state.patioOutDoor,
                cookware: this.state.cookware == '' ? '---' : this.state.cookware,
                appleTv: this.state.appleTv == '' ? '---' : this.state.appleTv,
                kidsPackage: this.state.kidsPackage == '' ? '---' : this.state.kidsPackage,
                kidsAge: this.state.kidsAge == '' ? '---' : this.state.kidsAge,
                dogsPackage: this.state.dogsPackage == '' ? '---' : this.state.dogsPackage,
                catPackage: this.state.catPackage == '' ? '---' : this.state.catPackage,
                officePackage: this.state.officePackage == '' ? '---' : this.state.officePackage,
                starterGroceries: this.state.starterGroceries == '' ? '---' : this.state.starterGroceries,
                lancheonOfGuest: this.state.lancheonOfGuest == '' ? '---' : this.state.lancheonOfGuest,
                waterSnacksGuest: this.state.waterSnacksGuest == '' ? '---' : this.state.waterSnacksGuest,
                comments: this.state.comments == '' ? '---' : this.state.comments,
                giftBasket: this.state.giftBasket == '' ? '---' : this.state.giftBasket,
                gender: this.state.gender == '' ? '---' : this.state.gender,
                entryArrangement:this.state.entryArrangement == '' ? '---' : this.state.entryArrangement,
                decoPackage:this.state.decoPackage == '' ? '---' : this.state.decoPackage, 
                mail:'tourselection',
                subject: 'SuiteAmerica Tour Selection Form'
            }
    
        this.setState({
            CaptchaValue: '',
            communityName: '',
            suiteNumber: '',
            customerNumber: '',
            salesManager: '',
            master: '',
            reservation: '',
            tourDateTime: '',
            leasingOffice: '',
            executiveGiftCard1: '',
            executiveGiftCard2: '',
            lockbox: '',
            pastaBasket: '',
            welcomeGusetBook: '',
            kidsBook: '',
            petBook: '',
            exective: '',
            furniturePackage: '',
            livingRoom: '',
            bedRoom: '',
            houseware: '',
            patioOutDoor: '',
            cookware: '',
            appleTv: '',
            kidsPackage: '',
            kidsAge:'',
            dogsPackage: '',
            catPackage: '',
            officePackage: '',
            starterGroceries: '',
            lancheonOfGuest: '',
            waterSnacksGuest: '',
            comments: '', 
            color:'',
            errorMessage:'',
            giftBasket:'',
            entryArrangement:'',
            gender:'',
            decoPackage:''           
 
        })
        $('input[name="1"]').prop('checked', false);
        $('input[name="2"]').prop('checked', false);
        $('input[name="3"]').prop('checked', false);
        $('input[name="4"]').prop('checked', false);
        $('input[name="5"]').prop('checked', false);
        $('input[name="6"]').prop('checked', false);
        $('input[name="7"]').prop('checked', false);
        $('input[name="8"]').prop('checked', false);

        $('input[name="checkbox1"]').prop('checked', false);
        $('input[name="checkbox2"]').prop('checked', false);
        $('input[name="checkbox3"]').prop('checked', false);
        $('input[name="checkbox4"]').prop('checked', false);
        $('input[name="checkbox5"]').prop('checked', false);
        $('input[name="checkbox6"]').prop('checked', false);
        $('input[name="checkbox7"]').prop('checked', false);
        $('input[name="checkbox8"]').prop('checked', false);
        $('input[name="checkbox9"]').prop('checked', false);
        $('input[name="checkbox10"]').prop('checked', false);
        $('input[name="checkbox11"]').prop('checked', false);

        AppController.menuTopNavigation('serviceRequest');
        jQuery(function($) {
            $('#successModal').modal('show');
            setTimeout(function(){
              $('#successModal').modal('hide');
            },4000);
          });
        await NikeServices.serviceRequest(Request)        
        }
        else{
            AppController.menuTopNavigation('serviceRequest');
            this.setState({errorMessage:'Please Select Captcha',color:'red'})
        }
       }
      
       async onChangeCaptcha(CaptchaValue) {
        await this.setState({CaptchaValue})
        }

       /* It is invoked to return html content */
       render() {
           
           return (
            <div className="corporateHousing_Updates" id="serviceRequest">
                <GuestHeader/>
                <div className="cover-tophd-ht"></div>
                <div className="container-fluid nike-datepicker bg-grey">
                    <div className="row">
                        <div className="container">
                            <div className="pts-smg-wrap">
                            <h1 className=""><i>SuiteAmerica Tour Selection Form</i></h1>
                            </div>
                            <div className="service_request">
                            <div className="request_form company_form" >
                                <form className="well">
{/* Please fill out the form below:                            */}
                                    <hr className="toppad-0 mrg-top-0"/>
                                    <h3>Please fill out the form below:</h3>
                                    <div className="text-center mrg-btm-20" style={{color:this.state.color}}>{this.state.errorMessage}</div>
                                    <div className="row">
                                        <div className="col-sm-6 col-md-6">
                                        Community Name : 
                                        <input type="text" className="form-control" placeholder="Community Name" value={this.state.communityName} 
                                        onChange={(e) => this.setValue('communityName', e)}/>
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                        Suite Number :
                                        <input type="text" className="form-control" placeholder="Suite Number" value={this.state.suiteNumber} 
                                        onChange={(e) => this.setValue('suiteNumber', e)}/>
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                        Customer Number :
                                        <input type="text" className="form-control" placeholder="Customer Number" value={this.state.customerNumber} 
                                        onChange={(e) => this.setValue('customerNumber', e)} />
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                        Sales Manager :
                                        <input type="text" className="form-control" placeholder="Sales Manager" value={this.state.salesManager} 
                                        onChange={(e) => this.setValue('salesManager', e)} />
                                        </div>
                                        <div className="col-sm-3 col-md-3">
                                        Master # :
                                        <input type="text" className="form-control" placeholder="Master" value={this.state.master} 
                                        onChange={(e) => this.setValue('master', e)} />
                                        </div>
                                        <div className="col-sm-3 col-md-3">
                                        Reservation # :
                                        <input type="text" className="form-control" placeholder="Reservation" value={this.state.reservation} onChange={(e) => this.setValue('reservation', e)} />
                                        </div>
                                    
                                        <div className="col-sm-6 col-md-6">
                                        Tour Date & Time :
                                        <input type="text" className="form-control" placeholder="Tour Date & Time" value={this.state.tourDateTime} 
                                        onChange={(e) => this.setValue('tourDateTime', e)} />
                                        </div>
                                        <div className="col-sm-3  col-md-3">
                                        Entry Arrangements:<br/>
                                        <label className="custom-select">
                                        <select value={this.state.entryArrangement} 
                                        onChange={(e) => this.setValue('entryArrangement', e)}>                                        
                                        <option value='' disabled>Please Select</option>
                                        {this.state.entryArrangementDropdown.map((row, index) => {
                                        return(
                                        <option key={index} value={row}>{row}</option>
                                        )
                                        })}
                                        </select>
                                    </label>
                                        </div>
                                        <div className="col-sm-3  col-md-3">
                                        Gift  Basket:<br/>
                                        <label className="custom-select">
                                        <select value={this.state.giftBasket} 
                                        onChange={(e) => this.setValue('giftBasket', e)}>                                        
                                        <option value='' disabled>Please Select</option>
                                        {this.state.giftBasketdropdown.map((row, index) => {
                                        return(
                                        <option key={index} value={row}>{row}</option>
                                        )
                                        })}
                                        </select>
                                    </label>
                                        </div>
                                        <div className="col-sm-3  col-md-3">
                                        LockBox:<br/>
                                        <input type="checkbox" name="checkbox10" value={this.state.lockbox} 
                                        onChange={(e) => this.checkboxValue('lockbox', e)} /> Lockbox
                                        </div>
                            
                                    </div>
                                    <hr className="toppad-0 mrg-top-0"/>
                                    <h3>Welcome Information:</h3>
                                    <br/>
                                    <div className="row">
                                    <div className="col-sm-3  col-md-3">
                                        <input type="checkbox" name="checkbox1" value={this.state.welcomeGusetBook} 
                                        onChange={(e) => this.checkboxValue('welcomeGusetBook', e)} /> Welcome Guest Book
                                        </div>
                                    </div>
                                    <br/>
                                    <hr className="toppad-0 mrg-top-0"/>
{/* HouseWare Information */}

                                    <h3>HouseWare Information:</h3>
                                    <br/>
                                   <div className="row">
                                    <div className="col-sm-4 col-md-4">
                                        Furniture Package : 
                                        <label className="custom-select">
                                                    <select value={this.state.furniturePackage} 
                                                    onChange={(e) => this.setValue('furniturePackage', e)}>                                        
                                                    <option value='' disabled>Please Select</option>
                                                    {this.state.furniturePackageList.map((row, index) => {
                                                    return(
                                                    <option key={index} value={row}>{row}</option>
                                                    )
                                                    })}
                                                    </select>
                                                </label>
                                    </div>
                                    <div className="col-sm-4 col-md-4">
                                        living Room : 
                                        <label className="custom-select">
                                                    <select value={this.state.livingRoom} 
                                                    onChange={(e) => this.setValue('livingRoom', e)}>                                        
                                                    <option value='' disabled>Please Select</option>
                                                    {this.state.livingRoomList.map((row, index) => {
                                                    return(
                                                    <option key={index} value={row}>{row}</option>
                                                    )
                                                    })}
                                                    </select>
                                                </label>
                                    </div>
                                    <div className="col-sm-4 col-md-4">
                                        Bed Room :
                                        <label className="custom-select">
                                                    <select value={this.state.bedRoom} 
                                                    onChange={(e) => this.setValue('bedRoom', e)}>                                        
                                                    <option value='' disabled>Please Select</option>
                                                    {this.state.bedRoomList.map((row, index) => {
                                                    return(
                                                    <option key={index} value={row}>{row}</option>
                                                    )
                                                    })}
                                                    </select>
                                                </label>
                                    </div>
                                   
                                    

                                    </div> 
                                    <div className="row">
                                    <div className="col-sm-4 col-md-4">
                                    Deco Package : 
                                         <label className="custom-select">
                                                    <select value={this.state.decoPackage} 
                                                    onChange={(e) => this.setValue('decoPackage', e)}>                                        
                                                    <option value='' disabled>Please Select</option>
                                                    {this.state.decoPackageDropdown.map((row, index) => {
                                                    return(
                                                    <option key={index} value={row}>{row}</option>
                                                    )
                                                    })}
                                                    </select>
                                                </label>
                                    </div>
                                    <div className="col-sm-4 col-md-4">
                                        <div className="row radiosec">
                                            <div className="col-sm-12">
                                                <label>Apple Tv </label>
                                                <div className="row">
                                                    <div className="clearfix"></div>
                                                    <div className="col-sm-6">
                                                    <label className="custom_radio radio-inline">Yes
                                                    <input type="radio" name="5" value="yes" 
                                                    onChange={(e) => this.setChecked('appleTv', e)} />
                                                    <span className="checkmark"></span>
                                                    </label>
                                                    </div>
                                                    <div className="col-sm-6">
                                                    <label className="custom_radio radio-inline">No
                                                    <input type="radio" name="5" value="no" 
                                                    onChange={(e) => this.setChecked('appleTv', e)}/>
                                                    <span className="checkmark"></span>
                                                    </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                      
                                        </div>
                                    </div>
                                   <div className="row">
                                    <div className="col-sm-4 col-md-4">
                                        HouseWares : 
                                        <label className="custom-select">
                                                    <select value={this.state.houseware} 
                                                    onChange={(e) => this.setValue('houseware', e)}>                                        
                                                    <option value='' disabled>Please Select</option>
                                                    {this.state.housewareList.map((row, index) => {
                                                    return(
                                                    <option key={index} value={row}>{row}</option>
                                                    )
                                                    })}
                                                    </select>
                                                </label>
                                    </div>
                                    <div className="col-sm-4 col-md-4">
                                        Patio & Outdoor : 
                                        <label className="custom-select">
                                                    <select value={this.state.patioOutDoor} 
                                                    onChange={(e) => this.setValue('patioOutDoor', e)}>                                        
                                                    <option value='' disabled>Please Select</option>
                                                    {this.state.pationOutdoorList.map((row, index) => {
                                                    return(
                                                    <option key={index} value={row}>{row}</option>
                                                    )
                                                    })}
                                                    </select>
                                                </label>
                                    </div>
                                    <div className="col-sm-4 col-md-4">
                                        Cookware :
                                        <label className="custom-select">
                                                    <select value={this.state.cookware} 
                                                    onChange={(e) => this.setValue('cookware', e)}>                                        
                                                    <option value='' disabled>Please Select</option>
                                                    {this.state.cookWareList.map((row, index) => {
                                                    return(
                                                    <option key={index} value={row}>{row}</option>
                                                    )
                                                    })}
                                                    </select>
                                                </label>
                                    </div>
                                    </div> 
                                    <div className="row">
                                    <div className="col-sm-6 col-md-4">
                                        <div className="row radiosec">
                                            <div className="col-sm-12">
                                                <label>Kids Package :</label>
                                                <div className="row">
                                                    <div className="clearfix"></div>
                                                    <div className="col-sm-3">
                                                    <label className="custom_radio radio-inline">Yes
                                                    <input type="radio" name="1" value="yes" 
                                                    onChange={(e) => this.setChecked('kidsPackage', e)} />
                                                    <span className="checkmark"></span>
                                                    </label>
                                                    </div>
                                                    <div className="col-sm-3">
                                                    <label className="custom_radio radio-inline">No
                                                    <input type="radio" name="1" value="no" 
                                                    onChange={(e) => this.setChecked('kidsPackage', e)}/>
                                                    <span className="checkmark"></span>
                                                    </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                      
                                        </div>
                                        <div className="col-sm-6 col-md-4">
                                        <div className="row radiosec">
                                            <div className="col-sm-12">
                                                <label>Gender :</label>
                                                <div className="row">
                                                    <div className="clearfix"></div>
                                                    <div className="col-sm-3">
                                                    <label className="custom_radio radio-inline">Male
                                                    <input type="radio" name="1" value="Male" 
                                                    onChange={(e) => this.setChecked('gender', e)} />
                                                    <span className="checkmark"></span>
                                                    </label>
                                                    </div>
                                                    <div className="col-sm-3">
                                                    <label className="custom_radio radio-inline">Female
                                                    <input type="radio" name="1" value="Female" 
                                                    onChange={(e) => this.setChecked('gender', e)}/>
                                                    <span className="checkmark"></span>
                                                    </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                      
                                        </div>
                                        <div className="col-sm-6 col-md-4">
                                        Kids Age :
                                        <input type="text" className="form-control" placeholder=" Kids Age" value={this.state.kidsAge} onChange={(e) => this.setValue('kidsAge', e)}/>
                                        </div>


                                  </div>

                                  <hr className="toppad-0 mrg-top-0"/>
 {/* Pet Package:*/}
                                    <h3>Pet Package:</h3>
                                    <br/>
                                    <div className="row">
                                    <div className="col-sm-3 col-md-3">
                                        <div className="row radiosec">
                                            <div className="col-sm-12">
                                                <label>Dog Package : </label>
                                                <div className="row">
                                                    <div className="clearfix"></div>
                                                    <div className="col-sm-6">
                                                    <label className="custom_radio radio-inline">Yes
                                                    <input type="radio" name="2" value="yes" 
                                                    onChange={(e) => this.setChecked('dogsPackage', e)} />
                                                    <span className="checkmark"></span>
                                                    </label>
                                                    </div>
                                                    <div className="col-sm-6">
                                                    <label className="custom_radio radio-inline">No
                                                    <input type="radio" name="2" value="no" 
                                                    onChange={(e) => this.setChecked('dogsPackage', e)}/>
                                                    <span className="checkmark"></span>
                                                    </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="col-sm-3 col-md-3">
                                        Dog Package Includes : dog bed,bowls,treats & toy.
                                       
                                        </div>
                                       
                                        <div className="col-sm-3 col-md-3">
                                        <div className="row radiosec">
                                            <div className="col-sm-12">
                                                <label>Cat Package </label>
                                                <div className="row">
                                                    <div className="clearfix"></div>
                                                    <div className="col-sm-6">
                                                    <label className="custom_radio radio-inline">Yes
                                                    <input type="radio" name="3" value="yes" 
                                                    onChange={(e) => this.setChecked('catPackage', e)} />
                                                    <span className="checkmark"></span>
                                                    </label>
                                                    </div>
                                                    <div className="col-sm-6">
                                                    <label className="custom_radio radio-inline">No
                                                    <input type="radio" name="3" value="no" 
                                                    onChange={(e) => this.setChecked('catPackage', e)}/>
                                                    <span className="checkmark"></span>
                                                    </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="col-sm-3 col-md-3">
                                        Cat Package Includes:cat bed,littler box & litter ,dishes & toy.
                                        </div>

                                  </div>

{/* Misc Package */}
                                  <hr className="toppad-0 mrg-top-0"/>
                                    <h3>Misc Package:</h3>
                                    <br/>
                                    <div className="row">
                                        <div className="col-sm-6 col-md-6">
                                        <input type="checkbox" name="checkbox5" placeholder="Guest Name" value={this.state.officePackage} onChange={(e) => this.setValue('officePackage', e)} /> Office Package
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                        <input type="checkbox" name="checkbox6" placeholder="Guest Name" value={this.state.starterGroceries} onChange={(e) => this.setValue('starterGroceries', e)} /> Starter Groceries 
                                        </div>
                                  </div>
                                  <br/>
                                  <div className="row">
                                        <div className="col-sm-6 col-md-6">
                                        Includes : desk,chair & lamp.
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                          Includes : Non perishable items
                                        </div>
                                  </div>
                                  <br/>
                                  <div className="row">
                                  <div className="col-sm-6 col-md-6">
                                  Luncheon # of Guests :
                                  <input type="text" className="form-control" placeholder="Luncheon # of Guests" value={this.state.lancheonOfGuest} onChange={(e) => this.setValue('lancheonOfGuest', e)} />
                                  </div>
                                        <div className="col-sm-6 col-md-6">
                                        Water & Snacks # of Guests :
                                        <input type="text" className="form-control" placeholder="Water & Snacks # of Guests" value={this.state.waterSnacksGuest} onChange={(e) => this.setValue('waterSnacksGuest', e)} />
                                        </div>
                                  </div>
                                <br/>
                                <div className="row">
                                  <div className="col-sm-12 col-md-12">
                                  Comments:
                                  <textarea rows="8" cols="5" className="form-control" placeholder="Comments" value={this.state.comments} onChange={(e) => this.setValue('comments', e)} />
                                  </div>
                                       
                                  </div>
                                    <div className="row">                                         
                                        <div className="col-sm-12 col-md-12">
                                        <ReCAPTCHA
                                            ref="recaptcha"
                                            sitekey="6Lcbw1AUAAAAAE5K5cYDyhoGu0RJY9C3CLtGFXAo"
                                            onChange={this.onChangeCaptcha}
                                            />
                                        </div>
                                    </div>
                                    
                               
                                
                                </form>
                            </div>
                            </div>
                            <div className="pts-btn-block">
                            <button type="button" className=" btn location_btn" onClick={this.clearData}> CLEAR</button>                           
                            <button type="button" className=" btn location_btn" onClick={this.serviceRequest}> SUBMIT</button>                           
                        </div>
                        </div>
                    </div>
                </div>
                <SubFooter/>
                <Footer/>
                <SuccessModal/>
                </div>   
           );
       }
   }
   export default TourSelection;