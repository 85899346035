/* Importing the node modules, child components, services and controllers used 
   inside ExecutiveStaff component */
import ViewMoreEmployees from './viewMoreEmployees';
import Teams from './teams';
/* ExecutiveStaff Component initialization */
class ExecutiveStaff extends React.Component{
    /* Initializing objects of its ExecutiveStaff class */
	constructor(props) {
        super(props)
        this.EmployeeDetails = this.EmployeeDetails.bind(this);
      }
    /* To get the employee details based on id */
	EmployeeDetails(id){
	this.props.EmployeeDetails(id);
	}
/* It is invoked to return html content */	
render(){
    //Accounts
      var filteredAccounts=[]
      if(this.props.staffData.length !=0 ){
        filteredAccounts = _.filter(this.props.staffData, function (o) 
        {
        return o.Name =='Accounting';
        })
      }
       //Distribution
       var filteredDistribution=[]
      if(this.props.staffData.length !=0 ){
        filteredDistribution = _.filter(this.props.staffData, function (o) 
        {
            return o.Name =='Distribution';
          })
      }
        //Guest Services
   var filteredGuestServices=[]
      if(this.props.staffData.length !=0 ){
        filteredGuestServices = _.filter(this.props.staffData, function (o) 
        {
            return o.Name =='Guest Services';
          })
      }
            //Managers
   var filteredManagers=[]
  if(this.props.staffData.length !=0 ){
    filteredManagers = _.filter(this.props.staffData, function (o) 
    {
        return o.Name =='Managers';
      })
  }
               //Marketing
   var filteredMarketing=[]
  if(this.props.staffData.length !=0 ){
    filteredMarketing = _.filter(this.props.staffData, function (o) 
    {
        return o.Name =='Marketing';
      })
  }
    // Relocation
   var filteredRelocation=[]
   if(this.props.staffData.length !=0 ){
    filteredRelocation = _.filter(this.props.staffData, function (o) 
    {
        return o.Name =='Relocation';
      })
   }
  // Sample
  var filteredSample=[]
  if(this.props.staffData.length !=0 ){
    filteredSample = _.filter(this.props.staffData, function (o) 
    {
        return o.Name =='Sample';
      })
  }
  // Scheduling
  var filteredScheduling=[]
  if(this.props.staffData.length !=0 ){
    filteredScheduling = _.filter(this.props.staffData, function (o) 
    {
        return o.Name =='Scheduling';
      })
  }
  // Supply Chain
  var filteredSupplyChain=[]
  if(this.props.staffData.length !=0 ){
    filteredSupplyChain = _.filter(this.props.staffData, function (o) 
    {
        return o.Name =='Supply Chain';
      })
  }
    return(
      
        <div className="intra_staff2 staff_acco">
        {/* EveryOne */}
        <Teams {...this.props}/> 
        <div className="container">
            <div className="panel-group" id="accordion">
              <div className="panel panel-default">
                <div className="panel-heading">
                  <h4 data-toggle="collapse" data-parent="#accordion" href="#collapse1" className="panel-title expand">
                     <div className="right-arrow pull-right">+</div>
                  </h4>
                 <p>EveryOne</p>
                </div>
                  <div className="panel-body" id="TEAMS">
                  <div className="row">
                  <div className="col-md-3">
                  <div className="first_box">
                      <div className="first_boxin">
                          <h1>{this.props.staffData.length}
                          <p>EveryOne</p>
                          </h1>
                      </div>
                  </div>
              </div>
                {this.props.staffData.slice(0,3).map((row, index) => {
                        return (
                        
                           <div key={index}>                             
                             <ViewMoreEmployees  {...this.props} index={index+1} rowdata={row} EmployeeDetails={this.EmployeeDetails}/>                     
                              </div>
                    
                     ) })}
              </div>
              <div id="collapse1" className="panel-collapse collapse ">
                  <div className="panel-body">
                      <div className="row">
                      {this
                    .props
                    .staffData.slice(3)
                    .map((row, index) => {
                        return (
                    <div key={index}>                            
                       <ViewMoreEmployees  {...this.props} index={index+4} rowdata={row} EmployeeDetails={this.EmployeeDetails}/>                      
                       </div>                     
                     ) })}
                  </div>
                   </div> 
                  </div>
                </div>
              </div>
            </div> 
          </div>
        
  {/* Accounting */}
                   
      <div className="container">
    <div className="panel-group" id="accordion">
      <div className="panel panel-default">
        <div className="panel-heading">
          <h4 data-toggle="collapse" data-parent="#accordion" href="#collapse2" className="panel-title expand">
             <div className="right-arrow pull-right">+</div>
          </h4>
                      <p>ACCOUNTING</p>
        </div>
          <div className="panel-body" id="TEAMS">
          <div className="row">
          <div className="col-md-3">
              <div className="first_box">
                  <div className="first_boxin">
                      <h1>{filteredAccounts.length}
                      <p>ACCOUNTING</p>
                      </h1>
                  </div>
              </div>
          </div>
          {filteredAccounts.slice(0,3)
            .map((row, index) => {
                return (
              <div key={index}>   
                     <ViewMoreEmployees  {...this.props} index={index+1}  rowdata={row} EmployeeDetails={this.EmployeeDetails}/>   
              </div>                     
             ) })}
      </div>
        <div id="collapse2" className="panel-collapse collapse ">
          <div className="panel-body">
              <div className="row">
              {filteredAccounts.slice(3)
                .map((row, index) => {
                    return (
                      <div key={index}> 
                        <ViewMoreEmployees  {...this.props}  index={index+4}  index={index} rowdata={row} EmployeeDetails={this.EmployeeDetails}/>
                        </div> 
                 ) })}
              	
          </div>
           </div> 
          </div>
        </div>
      </div>
    </div> 
  </div>
  
  {/* Distribution */}
      <div className="container">
    <div className="panel-group" id="accordion">
      <div className="panel panel-default">
        <div className="panel-heading">
          <h4 data-toggle="collapse" data-parent="#accordion" href="#collapse3" className="panel-title expand">
             <div className="right-arrow pull-right">+</div>
          </h4>
                      <p>Distribution</p>
        </div>
          <div className="panel-body" id="TEAMS">
          <div className="row">
          <div className="col-md-3">
              <div className="first_box">
                  <div className="first_boxin">
                      <h1>{filteredDistribution.length}
                      <p>Distribution</p>
                      </h1>
                  </div>
              </div>
          </div>
          {filteredDistribution.slice(0,3)
            .map((row, index) => {
                return (
                  <div key={index}> 
                    <ViewMoreEmployees  {...this.props} index={index+1} rowdata={row} EmployeeDetails={this.EmployeeDetails}/>
                  </div>
             ) })}
      </div>
        <div id="collapse3" className="panel-collapse collapse ">
          <div className="panel-body">
              <div className="row">
              {filteredDistribution.slice(3)
                .map((row, index) => {
                    return (
                      <div key={index}> 
                        <ViewMoreEmployees  {...this.props}  index={index+4} rowdata={row} EmployeeDetails={this.EmployeeDetails}/>
                      </div>
                 ) })}
              	
          </div>
           </div> 
          </div>
        </div>
      </div>
    </div> 
  </div>
 
       {/* Guest Services */}
       
      <div className="container">
    <div className="panel-group" id="accordion">
      <div className="panel panel-default">
        <div className="panel-heading">
          <h4 data-toggle="collapse" data-parent="#accordion" href="#collapse4" className="panel-title expand">
             <div className="right-arrow pull-right">+</div>
          </h4>
                      <p>Guest Services</p>
        </div>
          <div className="panel-body" id="TEAMS">
          <div className="row">
          <div className="col-md-3">
              <div className="first_box">
                  <div className="first_boxin">
                      <h1>{filteredGuestServices.length}
                      <p>Guest Services</p>
                      </h1>
                  </div>
              </div>
          </div>
          {filteredGuestServices.slice(0,3)
            .map((row, index) => {
                return (
                  <div key={index}> 
                    <ViewMoreEmployees  {...this.props}  index={index+1} rowdata={row} EmployeeDetails={this.EmployeeDetails}/>
                  </div>
             ) })}
      </div>
        <div id="collapse4" className="panel-collapse collapse ">
          <div className="panel-body">
              <div className="row">
              {filteredGuestServices.slice(3)
                .map((row, index) => {
                    return (
                      <div key={index}> 
                        <ViewMoreEmployees  {...this.props}  index={index+4} rowdata={row} EmployeeDetails={this.EmployeeDetails}/>
                      </div>
                 ) })}
              	
          </div>
           </div> 
          </div>
        </div>
      </div>
    </div> 
  </div>
  {/* Managers */}
      <div className="container">
    <div className="panel-group" id="accordion">
      <div className="panel panel-default">
        <div className="panel-heading">
          <h4 data-toggle="collapse" data-parent="#accordion" href="#collapse5" className="panel-title expand">
             <div className="right-arrow pull-right">+</div>
          </h4>
                      <p>Managers</p>
        </div>
          <div className="panel-body" id="TEAMS">
          <div className="row">
          <div className="col-md-3">
              <div className="first_box">
                  <div className="first_boxin">
                      <h1>{filteredManagers.length}
                      <p>Managers</p>
                      </h1>
                  </div>
              </div>
          </div>
          {filteredManagers.slice(0,3)
            .map((row, index) => {
                return (
                  <div key={index}> 
                    <ViewMoreEmployees  {...this.props}  index={index+1} rowdata={row} EmployeeDetails={this.EmployeeDetails}/>
                  </div>
                ) })}
      </div>
        <div id="collapse5" className="panel-collapse collapse ">
          <div className="panel-body">
              <div className="row">
              {filteredManagers.slice(3)
                .map((row, index) => {
                    return (
                      <div key={index}> 
                        <ViewMoreEmployees  {...this.props}  index={index+4} rowdata={row} EmployeeDetails={this.EmployeeDetails}/>
                 </div>
                 ) })}
              	
          </div>
           </div> 
          </div>
        </div>
      </div>
    </div> 
  </div>
   {/* Marketing */}
      <div className="container">
    <div className="panel-group" id="accordion">
      <div className="panel panel-default">
        <div className="panel-heading">
          <h4 data-toggle="collapse" data-parent="#accordion" href="#collapse6" className="panel-title expand">
             <div className="right-arrow pull-right">+</div>
          </h4>
                      <p>Marketing</p>
        </div>
          <div className="panel-body" id="TEAMS">
          <div className="row">
          <div className="col-md-3">
              <div className="first_box">
                  <div className="first_boxin">
                      <h1>{filteredMarketing.length}
                      <p>Marketing</p>
                      </h1>
                  </div>
              </div>
          </div>
          {filteredMarketing.slice(0,3)
            .map((row, index) => {
                return (
                  <div key={index}> 
                    <ViewMoreEmployees  {...this.props}  index={index+1} rowdata={row} EmployeeDetails={this.EmployeeDetails}/>
                  </div>
             ) })}
      </div>
        <div id="collapse6" className="panel-collapse collapse ">
          <div className="panel-body">
              <div className="row">
              {filteredMarketing.slice(3)
                .map((row, index) => {
                    return (
                      <div key={index}> 
                        <ViewMoreEmployees  {...this.props}  index={index+4} rowdata={row} EmployeeDetails={this.EmployeeDetails}/>
                      </div>
                 ) })}
              	
          </div>
           </div> 
          </div>
        </div>
      </div>
    </div> 
  </div>
   {/* Relocation */}
      <div className="container">
    <div className="panel-group" id="accordion">
      <div className="panel panel-default">
        <div className="panel-heading">
          <h4 data-toggle="collapse" data-parent="#accordion" href="#collapse7" className="panel-title expand">
             <div className="right-arrow pull-right">+</div>
          </h4>
                      <p>Relocation</p>
        </div>
          <div className="panel-body" id="TEAMS">
          <div className="row">
          <div className="col-md-3">
              <div className="first_box">
                  <div className="first_boxin">
                      <h1>{filteredRelocation.length}
                      <p>Relocation</p>
                      </h1>
                  </div>
              </div>
          </div>
          {filteredRelocation.slice(0,3)
            .map((row, index) => {
                return (
                  <div key={index}> 
                    <ViewMoreEmployees  {...this.props}  index={index+1} rowdata={row} EmployeeDetails={this.EmployeeDetails}/>
                  </div>
             ) })}
      </div>
        <div id="collapse7" className="panel-collapse collapse ">
          <div className="panel-body">
              <div className="row">
              {filteredRelocation.slice(3)
                .map((row, index) => {
                    return (
                      <div key={index}> 
                        <ViewMoreEmployees  {...this.props}  index={index+4} rowdata={row} EmployeeDetails={this.EmployeeDetails}/>
                      </div>
                 ) })}
              	
          </div>
           </div> 
          </div>
        </div>
      </div>
    </div> 
  </div>
   {/* Sample */}
      <div className="container">
    <div className="panel-group" id="accordion">
      <div className="panel panel-default">
        <div className="panel-heading">
          <h4 data-toggle="collapse" data-parent="#accordion" href="#collapse8" className="panel-title expand">
             <div className="right-arrow pull-right">+</div>
          </h4>
                      <p>Sample</p>
        </div>
          <div className="panel-body" id="TEAMS">
          <div className="row">
          <div className="col-md-3">
              <div className="first_box">
                  <div className="first_boxin">
                      <h1>{filteredSample.length}
                      <p>Sample</p>
                      </h1>
                  </div>
              </div>
          </div>
          {filteredSample.slice(0,3)
            .map((row, index) => {
                return (
                  <div key={index}> 
                    <ViewMoreEmployees  {...this.props}  index={index+1} rowdata={row} EmployeeDetails={this.EmployeeDetails}/>
                  </div>
             ) })}
      </div>
        <div id="collapse8" className="panel-collapse collapse ">
          <div className="panel-body">
              <div className="row">
              {filteredSample.slice(3)
                .map((row, index) => {
                    return (
                      <div key={index}> 
                        <ViewMoreEmployees  {...this.props}  index={index+4} rowdata={row} EmployeeDetails={this.EmployeeDetails}/>
                      </div>
                 ) })}
              	
          </div>
           </div> 
          </div>
        </div>
      </div>
    </div> 
  </div>
   {/* Scheduling */}
      <div className="container">
    <div className="panel-group" id="accordion">
      <div className="panel panel-default">
        <div className="panel-heading">
          <h4 data-toggle="collapse" data-parent="#accordion" href="#collapse9" className="panel-title expand">
             <div className="right-arrow pull-right">+</div>
          </h4>
                      <p>Scheduling</p>
        </div>
          <div className="panel-body" id="TEAMS">
          <div className="row">
          <div className="col-md-3">
              <div className="first_box">
                  <div className="first_boxin">
                      <h1>{filteredScheduling.length}
                      <p>Scheduling</p>
                      </h1>
                  </div>
              </div>
          </div>
          {filteredScheduling.slice(0,3)
            .map((row, index) => {
                return (
                  <div key={index}> 
                    <ViewMoreEmployees  {...this.props}  index={index+1} rowdata={row} EmployeeDetails={this.EmployeeDetails}/>
                  </div>
             ) })}
      </div>
        <div id="collapse9" className="panel-collapse collapse ">
          <div className="panel-body">
              <div className="row">
              {filteredScheduling.slice(3)
                .map((row, index) => {
                    return (
                      <div key={index}> 
                        <ViewMoreEmployees  {...this.props}  index={index+4} rowdata={row} EmployeeDetails={this.EmployeeDetails}/>
                      </div>
                 ) })}
              	
          </div>
           </div> 
          </div>
        </div>
      </div>
    </div> 
  </div>
   {/* Supply Chain */}
      <div className="container">
    <div className="panel-group" id="accordion">
      <div className="panel panel-default">
        <div className="panel-heading">
          <h4 data-toggle="collapse" data-parent="#accordion" href="#collapse10" className="panel-title expand">
             <div className="right-arrow pull-right">+</div>
          </h4>
                      <p>Supply Chain</p>
        </div>
          <div className="panel-body" id="TEAMS">
          <div className="row">
          <div className="col-md-3">
              <div className="first_box">
                  <div className="first_boxin">
                      <h1>{filteredSupplyChain.length}
                      <p>Supply Chain</p>
                      </h1>
                  </div>
              </div>
          </div>
          {filteredSupplyChain.slice(0,3)
            .map((row, index) => {
                return (
                  <div key={index}> 
                    <ViewMoreEmployees  {...this.props}  index={index+1} rowdata={row} EmployeeDetails={this.EmployeeDetails}/>
                  </div>
              ) })}
      </div>
        <div id="collapse10" className="panel-collapse collapse ">
          <div className="panel-body">
              <div className="row">
              {filteredSupplyChain.slice(3)
                .map((row, index) => {
                    return (
                      <div key={index}> 
                        <ViewMoreEmployees  {...this.props}  index={index+4} rowdata={row} EmployeeDetails={this.EmployeeDetails}/>
                      </div>
                 ) })}
              	
          </div>
           </div> 
          </div>
        </div>
      </div>
    </div> 
  </div>
          </div>


);
}
}
export default ExecutiveStaff;

