import React from 'react'
import GuestServicesapi from '../../../../../services/guestServicesapi';
import SessionService from "../../../../../services/sessionService";
import Footer from '../../../../common/footer';
import Header from '../../header';
// import Header from '../../newinterface/header'
import guestServiceLink from './guestService2'

class Services extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            orderid: this.props.match.params.id == undefined ? '' :this.props.match.params.id,
            message: '',
            color: '',
            loader: false,
            verifyemaillabel: false,
            verifyotplabel: false,
            clientId: '',
            clientSecret: '',
            OtpErrMsg: '',
            isOTPValid: false,
            passwordLessOtp: '',
            resendOtpTimer: 90,
            authCheckRes: {},
            resendMsg: false,
            showGuestService: false,
            userData: '',
            userInfo: ''
        }
    }

    async componentDidMount() {
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
        const GetResponse = ''
        if (GetResponse.error == 0) {
            location.href = '/reservations'
        }
    }

    setValue(field, e) {
        var object = {};
        object[field] = e.target.value;
        object['message'] = '';
        this.setState(object);
        console.log('object', object)
    }


    async authentication() {
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        let errorValue = 0;

        const validationFields = ['email', 'orderid' ]
        const _this = this
        _.forEach(validationFields, function (value) {
            if (_this.state[value] == "") {
                errorValue++;
            }
            if (!reg.test(_this.state.email)) {
                errorValue++;
            }
            if (_this.state.orderid.length!=6) {
                errorValue++;
            }
        });
        if (errorValue == 0) {
            await this.setState({ loader: true });
        if ((this.state.email != '' && reg.test(this.state.email))) {
            const address = '000.00.000.000';
            const loginReqObj = {
                guestEmail: this.state.email,
                // orderid: this.state.orderid,
                guestPassword: '',
                status: 'guest',
                browsertype: platform.name,
                ipaddress: address
            }
            const authCheckRes = await GuestServicesapi.guestUsersLogin(loginReqObj);

            console.log('authCheckRes', authCheckRes)
            if (authCheckRes.error == 0) {
               await this.setState({
                    showGuestService: true,
                    userInfo: authCheckRes.message
                })

                if(this.state.orderid != '' && this.state.orderid.length == 6){
                    
                    var guestEmailObj = {
                        guestEmail: this.state.email,
                       // status: "active",
                        userId: this.state.userInfo.UserId,
                      }
                      await GuestServicesapi.GetServicesInfoRequestByUserId(guestEmailObj).then(async(response) => {
                        if (response.error !== 1) {
                         
                          let OrderList = response.length > 0 && response !== undefined ? 
                                    response   : []
                                    console.log('response678',OrderList)
                           let filterData = OrderList.filter((obj)=>obj.OrderId == this.state.orderid)
                           if(filterData.length <= 0){
                            this.setState({ message: 'order id does not exist', color: 'red', loader: false });
                           
                           }else{
                               console.log('userInfo',this.state.userInfo,filterData)
                               let storeData = {userData:{
                                "GuestEmail":this.state.userInfo.GuestEmail,
                                "GuestFirstName": this.state.userInfo.GuestFirstName,
                                "GuestLastName": this.state.userInfo.GuestLastName,
                                "OrderID": this.state.userInfo.OrderId,
                                "roleId": 2,
                                "Id": this.state.userInfo.UserId,
                                "OrderUId":this.state.userInfo.OrderUId,
                                "status": this.state.userInfo.OrderStatus,
                                "error": 0,
                                "orderid":this.state.orderid},
                                type:'openlinkService'
                               }
                               await SessionService.SessionStore(storeData);
                              
                             this.props.history.push('/get_services_request')
                           }
                        }
                      })
        
                }else{
                    this.setState({ message: 'Enter valid order id ', color: 'red', loader: false });
                   
                }
            } else {
                await this.setState({ message: 'Invalid Email/Order#', color: 'red', loader: false });
               
            }
            this.setState({ loader: false });
        } else {
            this.setState({ message: 'Enter valid email ', color: 'red', loader: false });
           
        }
    }else if(!reg.test(this.state.email) ){
        this.setState({ message: 'Enter valid email ', color: 'red', loader: false });
    }else if(this.state.orderid=='' || this.state.orderid.length!=6){
        this.setState({ message: 'Enter valid order id ', color: 'red', loader: false });
    }else {
        this.setState({ message: 'Enter valid email & order id ', color: 'red', loader: false });
    }

    }
    _handleKeyDown1(e) {
        if (e.key === 'Enter') {
            this.authentication()
        }
    }

    _handleKeyPressset(evt) {
		var theEvent = evt;
		var key = theEvent.keyCode || theEvent.which;
		key = String.fromCharCode(key);
		var regex = /^\d+$/;
		if (!regex.test(key)) {
			theEvent.returnValue = false;
			if (theEvent.preventDefault) theEvent.preventDefault();
		}
	}

    render() {
        
        let dd = this.props.match.params.orderid
        
        return (
            <div className="SAweb_updateUi">
             <Header />
               
                {/* {this.state.showGuestService ?
                    <GuestService2 userData={this.state.userInfo} givenOrderId={this.state.orderid} />
                    : */}

                    <section className="SAweb_guestLoginBanner">
                        <div className="container">
                            <div className="loginForm2">
                                <div>
                                    <div className="item phColor ">

                                        {this.state.message != '' && <div className="alert alert-danger">{this.state.message}</div>}
                                        <input onKeyPress={(e) => this._handleKeyDown1(e)} onChange={(e) => this.setValue('email', e)} value={this.state.email} type="text" className="form-control" placeholder="Email" />
                                        <input onKeyPress={(e) => this._handleKeyPressset(e)} onChange={(e) => this.setValue('orderid', e)} value={this.state.orderid} type="text" className="form-control" placeholder="Order#" 
                                        maxlength='6'/>
                                        {this.state.loader ?
                                            <span style={{ fontSize: '20px', width: '100%', margin: 'auto', textAlign: 'center' }}><i className="fa fa-spinner fa-spin" />
                                                <span style={{ marginLeft: '10px' }}> please wait ...!</span> </span>
                                            :
                                            <div>
                                                <button type="button" onClick={() => this.authentication()} className="btn black-btn"> Get Orders </button>
                                            </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* } */}

                {!this.state.showGuestService && <Footer />}
            </div>


        );
    }
}
export default Services;