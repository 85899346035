/* Importing the node modules, child components, services and controllers used 
   inside CustomAmenities component */
import $ from "jquery";
import AppController from "../../controller/appController";
import Guest from "../../common/guest";
import GuestHeader from '../../common/guestHeader';
import Footer from '../../common/footer';
import QuickRequestForm from './quickRequestForm';
/* CustomAmenities Component initialization */
class CustomAmenities extends React.Component{
	/* Initializing objects of its CustomAmenities class */
      constructor(props) {
		super(props);
	  }
	/* It is invoked immediately before a component is mounted */  
      componentWillMount() {
		AppController.removeLocalStorageData();
        $('html, body').animate({scrollTop: 0});
        AppController.stickyTitles()  
	}
/* It is invoked to return html content */
render(){
    return(
        <div className="service_request moving_in">
		<GuestHeader/>
        <div className="profile_submenu-ht"></div>
        <Guest/>
        <QuickRequestForm />
        <div className="who_weare guest_site">
        <div className="carousel banner_text">
            <img className="img-responsive" src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Guest-Portal-Custom-Amenities.jpg"} alt="" />
              <div className="carousel-caption slide-text_22">
                <div className="">
                    <h1  className="toggleHeading wow zoomIn">Customize </h1>  
                    <h1  className="toggleHeading wow zoomIn">Your Stay</h1>    
                </div>
            </div>  
                
                        <p className="small_caption wow fadeInLeft">Take advantage of our Concierge Services and Custom
    Amenities packages for you, your family and pets</p>              
               
            
        </div>
    </div>
	<Guest/>
	<div className="guest_sitenew">


<div className="intra_staff2">
	
  <div className="container">
	  

	  
    <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
      <div className="panel panel-default">
       
		  <div className="panel-heading">
             <h4 className="panel-title">
        <a className="" data-toggle="collapse" data-parent="#accordion" href="#collapse1" aria-expanded="false" aria-controls="collapse1">
     CUSTOM AMENITIES
        </a>
				 <p>There’s no place like home, but with easy-to-get amenities like these available, you can make this
place feel pretty darn close. Order online and have items delivered right to your suite.</p>
      </h4>

        </div>
        
        <div id="collapse1" className="panel-collapse collapse in">
          <div className="panel-body">
            
			  <div className="moving_third moving_fourth">
			
			  
				  
				  
				  <div className="equalHMWrap eqWrap moningin_thumb">
					<div className="equalHM eq">
						<div className="eqq2">
						  <img className="img-responsive" src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Guest-Portal-Custom-Amenities-Electronics.png"} alt="" />
						</div>
						<p>Electronics</p>
					</div>
					
					<div className="equalHM eq">
						<div className="eqq2">
					  		<img className="img-responsive" src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Guest-Portal-Custom-Amenities-Furniture.png"} alt="" />
						</div>
						<p>Furniture</p>
					</div>
					<div className="equalHM eq">
						<div className="eqq2">
					 		 <img className="img-responsive" src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Guest-Portal-Custom-Amenities-Outdoor-Activities.png"} alt="" />
					 	</div>
						<p>Outdoor Activities</p>
					</div>
					  
					 <div className="equalHM eq">
						 <div className="eqq2">
					  		<img className="img-responsive" src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Guest-Portal-Custom-Amenities-Pets.png"} alt="" />
						</div>
						<p>Pets</p>
					</div>

				  </div>

				  

				  <div className="equalHMWrap eqWrap moningin_thumb">
					<div className="equalHM eq">
						<div className="eqq2">
					  		<img className="img-responsive" src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Guest-Portal-Custom-Amenities-Kids.png"} alt="" />
						</div>
						<p>Kids</p>
					</div>
					<div className="equalHM eq">
						<div className="eqq2">
					 		 <img className="img-responsive" src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Guest-Portal-Custom-Amenities-Kitchen.png"} alt="" />
						</div>
						<p>Kitchen</p>
					</div>
					<div className="equalHM eq">
						<div className="eqq2">
					  		<img className="img-responsive" src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Guest-Portal-Custom-Amenities-Grocery-Packages.png"} alt="" />
						</div>
						<p>Grocery Packages</p>
					</div>
					  
					  <div className="equalHM eq">
						  <div className="eqq2">
					  		<img className="img-responsive" src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Guest-Portal-Custom-Amenities-Furniture.png"} alt="" />
						</div>
						<p>Furniture</p>
					</div>

				  </div>
				  
				  
			  </div>
			  

			</div>
	
		  
		   </div> 
		  
      </div>
		
		<div className="panel panel-default"> 
		    <div className="panel-heading">
             <h4 className="panel-title">
        <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse22" aria-expanded="false" aria-controls="collapse22">
     STARTING ESSENTIALS
        </a>
				 <p>Let our Concierge Services team help with the little things that make life a bit easier.
We’re only a call away.</p>
      </h4>

        </div>
        
        <div id="collapse22" className="panel-collapse collapse">
          <div className="panel-body">
            
			  <div className="request_form">
		  	<div className="">
				
					
        <div className="row">
            <div className="col-sm-6 col-md-6">
				
				<h2>COFFEE STARTER PACK - $35.00</h2>
            	<p>Full-size bag of ground coffee, 10 coffee filters, 10 sugars, 10 Stevias, 10
creamers, 1 box of tea (20), honey, and 4 individually wrapped biscottis</p>
				
			
				<h2>HOUSEHOLD GOODS PACK - $65.00</h2>
            	<p>8 rolls of toilet paper, 8 rolls of paper towels, dish soap, dishwasher
detergent, laundry soap, and dryer sheets</p>
				
					<h2>TOILETRIES (MEN’S) - $75.00</h2>
            	<p>Shaving cream, razor, lotion, toothpaste, deodorant, toothbrush,
comb, hairspray, mouthwash, shampoo, conditioner, and sewing kit</p>
				
					<h2>TOILETRIES (WOMEN’S) - $75.00</h2>
            	<p>Nail grooming set, make up wipes, lotion, toothpaste, toothbrush,
deodorant, hairspray, mouthwash, shampoo, conditioner, and
sewing kit</p>
				
    </div>
			
			<div className="col-sm-6 col-md-6">
				
				<h2>TRAVEL-SIZE TOILETRIES (MEN’S) - $35.00</h2>
            	<p>Shaving cream, razor, lotion, toothpaste, deodorant, toothbrush,
comb, hairspray, mouthwash, shampoo, conditioner, and sewing kit </p>
				
			
				<h2>TRAVEL-SIZE TOILETRIES (WOMEN’S) - $35.00</h2>
            	<p>Nail grooming set, make up wipes, lotion, toothpaste, toothbrush,
deodorant, hairspray, mouthwash, shampoo, conditioner, and sewing kit
</p>
				
					<h2>YOGA KIT - $125.00</h2>
            	<p>Yoga mat, 3 lb weights, yoga ball, and cooling towel</p>
				
				
    </div>
			
                        
        </div>
					
		
				
				
		  </div>
		  </div>
			
			  
			  
          </div>
        </div>
      </div>
		
      <div className="panel panel-default"> 
		    <div className="panel-heading">
             <h4 className="panel-title">
        <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse2" aria-expanded="false" aria-controls="collapse2">
      CONCIERGE SERVICES
        </a>
				 <p>Having the “little things” during the big move is what we’re here for.</p>
      </h4>

        </div>
        
        <div id="collapse2" className="panel-collapse collapse">
			
							<p>Today’s business traveler is savvy, sophisticated, and used to getting what they want, when they want it, not 24 to 48 hours later. Whether you’ve
forgotten your toothbrush or need a patio set for your temporary home, we can deliver these items to you in as little as 2 hours.</p>
          <div className="panel-body">
            
			  <div className="request_form">
		  	<div className="">

					
        <div className="row">
            <div className="col-sm-6 col-md-6">
				<div>
					<div className="media-left">
							<i className="fa">
						<img src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Guest-Portal-Custom-Amenities-Transportation-Services.svg"} alt="" /></i>
					</div>
				<div className="media-body">
				<h2>TRANSPORTATION SERVICES</h2>
            	<p>$200 airport pick-up/drop-off services.</p>
				</div>
				</div>
				<div>
					<div className="media-left">
							<i className="fa">
						<img src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Guest-Portal-Custom-Amenities-Custom-Shopping.svg"} alt="" /></i>
					</div>
				<div className="media-body">
				<h2>CUSTOM SHOPPING</h2>
            	<p>$50 minimum purchase w/ 25% convenience fee. Groceries must
be open to substitutions. Retailer list: Target, Safeway, Whole
Foods, Bed Bath and Beyond, and Lowes. 
</p>
				</div>
				
            </div>
				
				<div>
					<div className="media-left">
							<i className="fa">
						<img src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Guest-Portal-Custom-Amenities-Custom-Installations.svg"} alt="" /></i>
					</div>
				<div className="media-body">
				<h2>CUSTOM INSTALLATIONS</h2>
            	<p>For those extras that make our lives a bit more comfortable:</p>
<p>including drapes, WiFi extender,
cell extender, extra deadbolts, and door guards.
$150/hour installation fee. 
</p>
				</div>
				
            </div>
				
					<div>
					<div className="media-left">
							<i className="fa">
						<img src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Guest-Portal-Custom-Amenities-Delivery.svg"} alt="" /></i>
					</div>
				<div className="media-body">
				<h2>SAME DAY DELIVERY</h2>
					<p className="p2"><span>Same day delivery of standard items that we have in our
inventory:</span></p>
<p>Blenders, printers, gift baskets, towels, kitchen utensils, specialty
cooking & baking items, roll-away beds, air mattresses, mattress
toppers, and specialty linens 
</p>
					
					
						<p className="p2"><span>Same day delivery of non-standard items that are easily
available from area retailers:</span></p>
<p>Premium electronics, cell phone boosters, specialty grocery
items and more! 
</p>
				</div>
				
            </div>
				
    </div>
            <div className="col-sm-6 col-md-6 troubleshoot">
   
			<div>
				<div className="media-left">
						<i className="fa">
						<img src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Guest-Portal-Custom-Amenities-Cable-Internet.svg"} alt="" /></i>
				</div>
				<div className="media-body">
					<h2>CABLE & INTERNET </h2>
            		<p>Call us if you have trouble. If we can’t fix it in 5 minutes over the
						phone, we’ll stop by in person.</p>
			
				</div>
				
            </div>
				
						<div>
							<div className="media-left">
									<i className="fa">
									<img src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Guest-Portal-Custom-Amenities-VIP.svg"} alt="" /></i>
							</div>
							<div className="media-body">
								<h2>VIP EXTRA’S</h2>
								<p>A touch of elegance and comfort: an assortment of luxury
										pillows, robes, & throw blankets. Call for pricing.</p>

							</div>

					  </div>
				
			<div>
				<div className="media-left">
						<i className="fa">
						<img src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Guest-Portal-Custom-Amenities-Delivery.svg"} alt="" /></i>
				</div>
				<div className="media-body">
					<h2>PACKAGE DELIVERY</h2>
            		<p>Have a package waiting for you at the leasing
office you can’t get to? Call us by noon, we’ll
have it in your room that evening.</p>
			
				</div>
				
            </div>	

				
						<div>
							<div className="media-left">
									<i className="fa">
									<img src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Guest-Portal-Custom-Amenities-Moving-Services.svg"} alt="" /></i>
							</div>
							<div className="media-body">
								<h2>MOVING SERVICES</h2>
								<p>Call us if you have trouble. If we can’t fix it in 5
										minutes over the phone, we’ll stop by in person.</p>

							</div>

					  </div>
				
			<div>
				<div className="media-left">
					<i className="fa fa-question"></i>
						
				</div>
				<div className="media-body">
					<h2>HOW CAN WE HELP?</h2>
            		<p>Just ask - We’re here to help! </p>
					<p className="p3">800.367.9501 / suiteamerica.com</p>
				</div>
				
            </div>	
				
						<div>
							<div className="media-left">
									<i className="fa">
									<img src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Guest-Portal-Custom-Amenities-Transportation-Services.svg"} alt="" /></i>
							</div>
							<div className="media-body">
								<h2>CAR RENTAL</h2>
								<p>Car rental program. Call for pricing.</p>

							</div>

					  </div>				
			<div>
				<div className="media-left">
						<i className="fa">
						<img src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Guest-Portal-Custom-Amenities-Paint-Brush.svg"} alt="" /></i>
				</div>
				<div className="media-body">
					<h2>HOUSEKEEPING</h2>
            		<p>Weekly or biweekly housekeeping services
provided based on apartment size. $45-$75 for
weekly service, $60-$120 for biweekly service.</p>
			
				</div>
				
            </div>					
				
				
				
				
				
				
				
				
				
				
            </div>            
        </div>
					
		
				
				
		  </div>
		  </div>
			
			  
			  
          </div>
        </div>
      </div>
      
		
		
		
		
		
		
		
    </div>
  </div>
</div>

</div>	
		<Footer/>
               </div>
    );
}
}
export default CustomAmenities;

