import { Link } from 'react-router-dom';
import CallModal from './callModal';
const SubFooter = () => {
    return (
        <div>
            <div className="banner_bottom footer_top row">
                <div className="col-md-6 col-sm-6 bb_boder foot_top">
                    <div className="bb_text">
                        <img src={"/assets/newUI-images/nike/phone.svg"} className="pull-left" alt="" />
                        <span> <h1>CALL</h1>
                            <p>24 hours a day, 7 days a week</p>
                            <a className="desktop-phone" href="#" data-toggle="modal" data-target="#callModal">
                                800.367.9501</a>
                            <a className="mobile-phone" href="tel:8003679501">800.367.9501</a></span>
                    </div>
                </div>
                <div className="col-md-6 col-sm-6 bb_boder foot_top">
                    <div className="bb_text">
                        <img src={"/assets/newUI-images/nike/mail.svg"} className="pull-left" alt="" />
                        <span> <h1>EMAIL</h1>
                            <p>Ask us anything, we’re here to help</p>
                            <a href="/contactUs">
                                Email Us</a></span>
                    </div>
                </div>
            </div>
            <CallModal />
        </div>
    );
}
export default SubFooter;