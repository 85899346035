'use strict'
import {Link} from 'react-router-dom';
import moment from 'moment'
import $ from 'jquery';
import usersService from '../../services/usersService';
import ReserveNowApi from "../../services/reserveNowApi";

import ReCAPTCHA from "react-google-recaptcha";
class CommunityBookNowModal extends React.Component {
    constructor(props) {
        super(props)
        /* ListView Component State variables Initialization */
        this.state = {
            resLocation: '',
            resDate: moment(),
            resName: '',
            resCompany: '',
            resEmail: '',
            resPhone: '',
            resPlace: '',
            message: '',
            color: '',
            emailmessage: '',
            emailcolor: '',
            loader: false,
            lengthOfStay: '',
            budget: '',
            date: '',
            dateCalendarAdded: false,
            closeButton: true,
            city:(this.props.CommunityDetails == undefined || this.props.CommunityDetails == null || this.props.CommunityDetails == '' )? '': this.props.CommunityDetails.city_name,    
            checkInDate: '',
            checkOutDate: '',
            CaptchaValue:'',
        }
        this.closeModel = this.closeModel.bind(this);
        this.setValue = this.setValue.bind(this);
        this.setNumber = this.setNumber.bind(this);
        this.rangeval = this.rangeval.bind(this);
        this.reserveYourStay = this.reserveYourStay.bind(this);
        this.onChangeCaptcha=this.onChangeCaptcha.bind(this);
    }
    componentDidMount() {       
        const _this = this
        jQuery(function ($) {
            $("#communityBooknowPhone").intlTelInput();
        }.bind(this));
            if(!_this.state.dateCalendarAdded){
                jQuery(function() {                    
                    jQuery('input[name="daterange"]').daterangepicker({                        
                        autoUpdateInput: false,                        
                        locale: {                            
                            cancelLabel: 'Clear'                        
                        },
                        minDate:moment()
                    },
                    function(start, end, label) {
                        _this.setState({dateCalendarAdded: true, date: start.format('MM/DD/YYYY') + ' - ' + end.format('MM/DD/YYYY'),
                        checkInDate:start.format('MM/DD/YYYY'),
                        checkOutDate:end.format('MM/DD/YYYY') })  
                });
            }); 
            }       
    }
    setValue(field, e) {
        this.setState({message: '', color: ''})
        if (e.target.value != '') {
            $('html, body').animate({
                scrollTop: '250px'
            }, 600);
        } else {
            $('html, body').animate({
                scrollTop: '-=250px'
            }, 600);
        }
        if (field == 'resEmail') {
            this.setState({emailmessage: '', emailcolor: ''})
        }
        var object = {};
        object[field] = e.target.value;
        this.setState(object);
    }
    async dateSelection(date, e) {
        this.setState({message: '', color: ''})
        await this.setState({resDate: moment(date)});
    }
    successMessage() {
        this.props.calendarClose()      
        this.setState({
            resLocation: '',
            resDate: moment(),
            lengthOfStay: '',
            resName: '',
            resCompany: '',
            resEmail: '',
            resPhone: '',
            resPlace: '',
            message: '',
            color: '',
            emailmessage: '',
            emailcolor: '',
            budget: '',
            loader: false,
            checkInDate: '',
            checkOutDate: '',
            CaptchaValue:'',
        })
        this.recaptcha.reset() 
    }
    validation(){
        const _this = this
        const validationFields=['location','date','resName','resEmail','CaptchaValue']
        let error = 0;
        _.forEach(validationFields, function(value) {
        if(_this.state[value] == ""){
            error++;
        }
    });
    return error
    }
    async onChangeCaptcha(CaptchaValue) {
        await this.setState({CaptchaValue})
        }
    async reserveYourStay(e) {
        e.preventDefault();
        const error=this.validation();
        this.setState({emailmessage: '', emailcolor: ''})
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (error==0) {
            if (reg.test(this.state.resEmail)&& this.state.resPhone.length == 10) {
                this.setState({emailmessage: '', emailcolor: '', loader: true, closeButton: false})
                const countryCode = $(".country.highlight.active").attr("data-dial-code") == undefined
                    ? "+1"
                    : "+" + $(".country.highlight.active").attr("data-dial-code")
                const reserveYourStay = {
                    Location: this.state.city,
                    MoveInDate: this.state.checkInDate,
                    MoveOutDate: this.state.checkOutDate,
                    Size:'1X',
                    SpecialNote: this.state.budget,
                    GuestFirstName: this.state.resName,
                    ClientReference:  this.state.resCompany,
                    GuestEmail: this.state.resEmail,
                    GuestPhone: this.state.resPhone,
                    length: Number(this.state.lengthOfStay),
                    CreatedBy:this.state.resEmail!=''?this.state.resEmail:'---',
                    BookedBy: 'it@suiteamerica.com',
                    PhContryCode: countryCode,
                }
                const orderResponse = await ReserveNowApi.webLeadQuotes(reserveYourStay);
               
                if (orderResponse.error==0) {
                    const reserveYourStayEmail = {
                        Location: this.state.city,
                        MoveInDate:this.state.checkInDate,
                        MoveOutDate: this.state.checkOutDate,
                        GuestFirstName: this.state.resName,
                        ClientReference: this.state.resCompany,
                        GuestEmail: this.state.resEmail,
                        length: Number(this.state.lengthOfStay),
                        SpecialNote: this.state.budget,
                        GuestPhoneEmailTemp: countryCode + this.state.resPhone,
                        community: this.props.CommunityDetails == undefined
                            ? '---'
                            : this.props.CommunityDetails.community_name,
                        communityAddress: this.props.CommunityDetails == undefined
                            ? '---'
                            : this.props.CommunityDetails.address,
                        communityState: this.props.CommunityDetails == undefined
                            ? '---'
                            : this.props.CommunityDetails.state_name,
                        communityCity: this.props.CommunityDetails == undefined
                            ? '---'
                            : this.props.CommunityDetails.city_name,
                        communityZipCode: this.props.CommunityDetails == undefined
                            ? '---'
                            : this.props.CommunityDetails.ZipCode,
                        status: '1',
                        OrderId:orderResponse.message.orderId
            
                    }
                    const resReserveYourStay = await usersService.reserveYourStay(reserveYourStayEmail);
                    if(resReserveYourStay.status){
                        jQuery(function ($) {
                            $('#community_book_now').modal('hide');
                            $('#reservationsuccessmodal').modal('show');
                        }.bind(this));
                        setTimeout(function () {
                            jQuery('#reservationsuccessmodal').modal('hide');
                        }.bind(this), 3000);
                        this.successMessage()
                    }
                   else{
                    this.setState({message: 'we are unable to sent Email', color: 'red'}) 
                   }
             
                } else {
                    this.setState({message: 'Order Is Not Created ', color: 'red'})
                 
                }
            } else {
                this.setState({message: 'Please Enter Email formate and Phone Number Exactly', color: 'Red'})
            }

        } else {
            this.setState({message: 'Please Enter All The Fields ', color: 'Red'})
        }

    }
    rangeval(val) {console.log('vsl')}
    closeModel() {
        this.props.calendarClose()
    }
    setNumber(e) {
        const re = /^[0-9\b]+$/;
        if (e.target.value == '' || re.test(e.target.value)) {
            this.setState({resPhone: e.target.value})
        }
    }
    render() {
        const communitydetails = this.props.CommunityDetails == undefined
            ? {}
            : this.props.CommunityDetails
        return (
            <div
                className="modal fade savesearch tesimonial_form bd-example-modal-lg"
                data-backdrop="static" data-keyboard="false"
                id="community_book_now"
                role="dialog">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content rm-border-radius booknow-info">
                        <div className="modal-header">
                            {this.state.closeButton
                                ? <button
                                        type="button"
                                        className="close btn"
                                        data-dismiss="modal"
                                        aria-label=""
                                        onClick={this.closeModel}>
                                        <i className="bi bi-x"></i>
                                    </button>
                                : ''}
                        </div>
                        <div className="modal-body">
                            <h2>Reserve Your Stay</h2>
                            <h4
                               style={{color: '#000000',fontWeight:"700"}}>{communitydetails.community_name}</h4>
                            <div
                                style={{
                                color: this.state.color
                            }}>{this.state.message}
                            </div>
                            <form role="form">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            placeholder="City"
                                            onChange={(e) => this.setValue('city', e)}
                                            value={this.state.city}/>
                                        <div className="info-field-icon">
                                            <Link to="#">
                                                <i className="fa fa-map-marker"></i>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Name *"
                                            onChange={(e) => this.setValue('resName', e)}
                                            value={this.state.resName}/>
                                    </div>
                                    <div
                                        className="form-group"
                                        style={{
                                        color: this.state.emailcolor
                                    }}>
                                        {this.state.emailmessage}
                                        <input
                                            className="form-control"
                                            placeholder="E-mail *"
                                            onChange={(e) => this.setValue('resEmail', e)}
                                            value={this.state.resEmail}
                                            type="text"/>
                                    </div>
                                    <div
                                        className="form-group"
                                        style={{
                                        color: this.state.emailcolor
                                    }}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={this.state.lengthOfStay}
                                            placeholder="Length Of Stay"
                                            id="lengthOfStay"
                                            onChange={(e) => this.setValue('lengthOfStay', e)}/>
                                    </div>
                                    <ReCAPTCHA
                    ref={ ref => this.recaptcha = ref }
                    sitekey="6Lcbw1AUAAAAAE5K5cYDyhoGu0RJY9C3CLtGFXAo"
                    onChange={this.onChangeCaptcha}
                    />
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group booknow-date-picker">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder='Select Date *'
                                            name="daterange"
                                            value={this.state.date}
                                            onChange={this.rangeval}/>
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Company"
                                            id="company"
                                            onChange={(e) => this.setValue('resCompany', e)}
                                            value={this.state.resCompany}/>
                                    </div>

                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            id="communityBooknowPhone"
                                            placeholder='Phone *'
                                            maxLength="10"
                                            onChange={(e) => this.setNumber(e)}
                                            value={this.state.resPhone}
                                            autoComplete="off"/>

                                    </div>
                                    <div
                                        className="form-group"
                                        style={{
                                        color: this.state.emailcolor
                                    }}>

                                        <input
                                            type="text"
                                            className="form-control"
                                            value={this.state.budget}
                                            placeholder="Budget"
                                            id="budget"
                                            onChange={(e) => this.setValue('budget', e)}/>
                                    </div>           
                                </div>          
                                <div className="text-center col-sm-12 form-group">
                                    {this.state.loader
                                        ? <div className="panel-body_loader">
                                                <span
                                                    style={{
                                                    fontSize: '25px',
                                                    width: '100%',
                                                    margin: 'auto',
                                                    textAlign: 'center'
                                                }}><i className="fa fa-spinner fa-spin"/>
                                                    please wait ...!</span>
                                            </div>
                                        : <button
                                            className="btn location_btn mrg-top-40"
                                            onClick={(e) => this.reserveYourStay(e)}>Reserve</button>}
                                </div>
                                <div className="text-center col-sm-6 col-sm-offset-3 mrg-btm-50 form-group">
                                    <p>Our service team will call you with options within the hour.
                                    </p>
                                </div>
                            </form>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
               
            </div>
        )
    }

}
export default CommunityBookNowModal