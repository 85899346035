/* Importing the node modules, child components, services and controllers used 
   inside WorldBankWhatsIncluded component */   
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   import WorldBankHeader from "../../common/worldBankHeader";
   
   /* WorldBankWhatsIncluded Component initialization */
   class WorldBankWhatsIncluded extends React.Component {
       /* Initializing objects of its WorldBankWhatsIncluded class */
          constructor(props) {
           super(props);  
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
           MainController.getInitialInformation();
        }   
       /* It is invoked to return html content */
       render() {   
           return (
            <div className="corporateHousing_Updates">
                <GuestHeader/>
                <WorldBankHeader/>
                <header className="codeconveyHeader cc_image_text_main corporate-h video-wrapper worldbnk_banner">
                    <img className="img-responsive" src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/world-bank/WhatsIncluded-Apartment-dark.jpg" width="100%" alt=""  />
                    <div className="wow fadeInUp img-on-text top40 width500 text-center left30 img-on-text-center company_bannertxt" data-wow-delay="0.5s">
                        <h1>What's included in a standard SuiteAmerica apartment home?</h1>
                    </div>
                </header>
                <div className="quote-section bg-white">
                    <div className="container">
                        <div className="text-center col-md-10 col-md-offset-1 wow fadeInUp company_p" data-wow-delay="0.5s">
                            <p>We’ll set up your apartment or home with everything you need to move in and be comfortable. Do you need something we didn’t include? Give us a call or send us an email telling us what you need. We’ll make every effort to have it waiting for you when you move in to your new home.
                            </p>
                            <hr className="hr" />
                        </div>
                        
                        <div className="col-md-12 boxed_list">
                                <h4>STANDARD ITEMS TYPICALLY INCLUDED IN YOUR NEW APARTMENT OR HOME</h4>
                                <ul>
                                    <li>Apartment Rent</li>
                                    <li>All Furnishings</li>
                                    <li>Housewares</li>
                                    <li>Linens</li>
                                    <li>Cable TV/WiFi</li>
                                    <li>Microwave Oven</li>
                                    <li>DVD</li>
                                    <li>Utilities*</li>
                                    <li>Local Phone with Service (long distance is billed to a credit card)</li>
                                    <li>1 Parking Space Included (Additional fees may apply in D.C.)</li>
                                    <li>Welcome folder and local information</li>
                                    <li>*A utility allowance of $100/mo is included</li>
                                </ul>
                            </div>
                    </div>
                    <div className="container-fluid mrg-btm-50 mrg-top-50">
                        <div className="img-header row" style={{backgroundImage:"url(https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/world-bank/Furniture-dark.jpg)"}}>
                            <h2>Furniture</h2>
                        </div>
                    </div>
                    <div className="container">
                        <div className="col-md-12 boxed_list">
                                <h4>DINING ROOM</h4>
                                <ul>
                                    <li>Dining Chairs</li>
                                    <li>Dining Table</li>
                                    <li>Print</li>
                                </ul>
                            </div>
                            <div className="col-md-12 boxed_list">
                                <h4>FIRST BEDROOM</h4>
                                <ul>
                                    <li>Dresser and Mirror</li>
                                    <li>Headboard</li>
                                    <li>Two Lamps</li>
                                    <li>Two Nightstands</li>
                                    <li>Print</li>
                                    <li>Queen Bed</li>
                                    <li>LCD Television</li>
                                </ul>
                            </div>
                            <div className="col-md-12 boxed_list">
                                <h4>LIVING ROOM</h4>
                                <ul>
                                    <li>Chair</li>
                                    <li>Coffee Table</li>
                                    <li>LCD Television w/Stand</li>
                                    <li>End Table</li>
                                    <li>Lamp</li>
                                    <li>Print</li>
                                    <li>Sofa</li>
                                    <li>DVD Player</li>
                                </ul>
                            </div>
                            <div className="col-md-12 boxed_list">
                                <h4>ADDITIONAL ROOM</h4>
                                <ul>
                                    <li>Chest of Drawers</li>
                                    <li>Headboard</li>
                                    <li>Lamp</li>
                                    <li>Nightstand</li>
                                    <li>Print</li>
                                    <li>Queen Bed or Two Twin Beds</li>
                                </ul>
                            </div>
                            <div className="col-md-12 boxed_list">
                                <h4>OFFICE SET</h4>
                                <ul>
                                    <li>Desk</li>
                                    <li>Chair</li>
                                    <li>Lamp</li>
                                    <li>Print</li>
                                </ul>
                            </div>
                    </div>
                    <div className="container-fluid mrg-top-50 bg-grey">
                        <h2 className="mrg-btm-40  mrg-top-40">Sample Dark Furniture</h2>
                    <div className="container">
                        <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/world-bank/SA-Dark-Dining.jpg" className="mrg-right-5 mrg-btm-5" />
                        <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/world-bank/SA-Dark-Bed.jpg" className="mrg-right-5 mrg-btm-5" />
                        <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/world-bank/SA-Dark-Living.jpg" className="mrg-btm-5" />
                    </div>
                    <h2 className="mrg-btm-40 mrg-top-40">Sample Lite Furniture</h2>
                    <div className="container mrg-btm-60">
                        <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/world-bank/SA-Light-Bed.jpg" className="mrg-right-5 mrg-btm-5" />
                        <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/world-bank/SA-Light-Dining.jpg" className="mrg-right-5 mrg-btm-5" />
                        <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/world-bank/SA-Light-Living.jpg" className="mrg-btm-5" />
                    </div>
                    </div>
                    <div className="container-fluid mrg-btm-50">
                        <div className="img-header row" style={{backgroundImage:"url(https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/world-bank/Housewares-dark.jpg)"}}>
                            <h2>Housewares</h2>
                        </div>
                    </div>
                    <div className="container">
                        <div className="col-md-12 boxed_list">
                                <h4>BATHROOM</h4>
                                <ul>
                                    <li>Bath Rug</li>
                                    <li>Four Bath Towels</li>
                                    <li>Four Hand Towels</li>
                                    <li>Shower Curtain</li>
                                    <li>Four Wash Cloths</li>
                                    <li>Waste Basket</li>
                                    <li>Stainless Tooth Bruch Holder</li>
                                    <li>Soap Dish</li>
                                </ul>
                            </div>
                            <div className="col-md-12 boxed_list">
                                <h4>BEDROOM</h4>
                                <ul>
                                    <li>Six Bed Pillows</li>
                                    <li>Luxury Duvet Set with Alternative Down Comforter</li>
                                    <li>Accent Coverlet and Shams</li>
                                    <li>Clock Radio</li>
                                    <li>Twenty Hangers</li>
                                    <li>Mattress Pad</li>
                                    <li>Two Sets of Luxurious Linens</li>
                                    <li>Blanket</li>
                                    	
                                </ul>
                            </div>
                            <div className="col-md-12 boxed_list">
                                <h4>DINING</h4>
                                <ul>
                                    <li>Place Mats</li>
                                    <li>Dinnerware</li>
                                    <li>Flatware</li>
                                    <li>Glasses (juice, water, wine)</li>
                                    <li>Napkins</li>
                                </ul>
                            </div>
                            <div className="col-md-12 boxed_list">
                                <h4>KITCHEN</h4>
                                <ul>
                                    <li>Baking Dish</li>
                                    <li>Carving Knife</li>
                                    <li>Coffee Maker</li>
                                    <li>Colander</li>
                                    <li>Wine Opener</li>
                                    <li>Cutting Board</li>
                                    <li>Dish Towels</li>
                                    <li>Electric Can Opener</li>                                    
                                    <li>Flatware Tray</li>                                    
                                    <li>Kitchen Wastebasket</li>                                    
                                    <li>Measuring Cups</li>                                    
                                    <li>Measuring Spoons</li>                                    
                                    <li>Microwave</li>                                    
                                    <li>Mixing Bowls</li>
                                    <li>Paring Knife</li>
                                    <li>Pitcher</li>
                                    <li>Pot Holders</li>
                                    <li>Salt/Pepper Set</li>
                                    <li>Serving Spoons</li>
                                    <li>Set of Cookware</li>
                                    <li>Large Fork</li>
                                    <li>Spatula</li>
                                    <li>Four Steak Knives</li>
                                    <li>Toaster</li>
                                    <li>Whisk</li>
                                    <li>Stainless Utencil Holder</li>
                                </ul>
                            </div>
                            <div className="col-md-12 boxed_list">
                                <h4>MOVE-IN KIT</h4>
                                <ul>
                                    <li>Bath Tissue</li>
                                    <li>Coffee</li>
                                    <li>Dish Soap</li>
                                    <li>Evening Mints</li>
                                    <li>Laundry Soap</li>
                                    <li>Light Bulbs</li>
                                    <li>Lotion</li>
                                    <li>Paper Towels</li>
                                    <li>Shampoo</li>
                                    <li>Dishwasher Detergent</li>
                                    <li>Soap</li>
                                    <li>Sponge</li>
                                    <li>Sugar/Creamer</li>
                                    <li>Bottled Water</li>
                                    <li>Snack Assortment</li>

                                </ul>
                            </div>
                            <div className="col-md-12 boxed_list">
                                <h4>MISCELLANEOUS</h4>
                                <ul>
                                    <li>Answering Machine</li>
                                    <li>Broom</li>
                                    <li>Dust Pan</li>
                                    <li>Iron</li>
                                    <li>Ironing Board</li>
                                    <li>Telephones</li>
                                    <li>Vacuum</li>
                                    <li>Decor</li>

                                </ul>
                            </div>
                    </div>

                </div>
                <SubFooter/>
                <Footer/>
            </div>
           );
       }
   }
   export default WorldBankWhatsIncluded;