import Settings from "./settings"
const RequestForm = props => {
    const requestDetails = props.requestDetails

    return (
        <div className="f-contact-wrap mrg-btm-50">
        <div className="f-contact-form-style">
           <form>
              <div className="form-group">
                 <textarea className="form-control" name="message" placeholder="Notes about this stop" rows="2" value={props.notesAboutTheStop} onChange={(e) => props.setValue('notesAboutTheStop',e)} ></textarea>
                 <div style={{'color': 'red'}}>{props.notesAboutTheStopError}</div>
              </div>
              <div className="form-group">
                 <textarea rows="2" className="form-control" placeholder="List Missing Items" name="message" value={props.listMissingItems} onChange={(e) => props.setValue('listMissingItems',e)}></textarea>
                 <div style={{'color': 'red'}}>{props.listMissingItemsError}</div>
              </div>
              <Settings/>
              <br/>

              { props.filesLists.length <3 && !props.filesUpload ? <div className="col-sm-12">
              <div className="form-group">
                 <input type="file" name="file-7[]" placeholder="jjj" id="file-7" className="inputfile inputfile-6" data-multiple-caption="{count} files selected" multiple onChange={(e)=> props._handleImageChange(2, 0,e)} />
                 <label htmlFor="file-7"><span>Upload Attachment (Doc, PDF, JPG, ect.)</span> <strong><img src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Form-Cloud-Upload.png"} width="45" alt="" /></strong></label>
              </div> 
            </div> : ''}
            {props.filesLists.length>0 ?
                        <div className="col-sm-12">
                        {props.filesLists.map((row, index) => {
                               return(
                           <div className="intra_uploadfile"  key={index}>
                              <ul>
                                 <li><span>File Name</span></li>
                                 <li><span>File Size</span></li>
                              </ul>
                              <ul>
                                 <li>{row.FileDetails.name}</li>
                                 <li>{row.FileDetails.size} kb</li>
                              </ul>  
                           </div> )})}
                        </div>:null}
                        {props.filesUpload ? 
              <div className="text-center"><span><i className="fa fa-spinner fa-spin"/> please wait file is uploading...!</span></div> : '' }	
              <div className='text-center' style={{color:'green'}}>{props.message}</div>
              {props.loading == true ? <div className="panel-body_loader" > <span style={{fontSize: '25px', width: '100%', margin: 'auto', textAlign: 'center'}}><i className="fa fa-spinner fa-spin"/> please wait ...!</span>
      </div>:<div>
              <div className="text-center mrg-top-20">       
                 <button type="button" className="btn star_button mrg-right-15" onClick={(e)=> props.handleSubmit(1,e)}>SEND</button>
                 <button type="button" className="btn star_button" onClick={()=> props.clearFields()}>CLEAR</button>
              </div>
              <div className="text-center mrg-top-20 mrg-btm-20"> 
              {requestDetails.StartTime == null ? 
                 <button disabled type="button" className="btn star_button">DONE</button> :
                 <button type="button" className="btn star_button" onClick={(e)=>props.handleSubmit(2,e)}>DONE</button>
              }  
              </div>
              </div>}
           </form>
        </div>
     </div>
    );
  };

  export default RequestForm;