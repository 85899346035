/* Importing the node modules, child components, services and controllers used 
   inside AdidasCommunities component */ 
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import AdidasHeader from "../../common/adidasHeader";
   import Location from "../location/locations";
   import ContactUs from "./common/contactUs";
   import Footer from '../../common/footer';
   import AdidasFooter from "./common/adidasFooter";
   /* AdidasCommunities Component initialization */
   class AdidasCommunities extends React.Component {
       /* Initializing objects of its AdidasCommunities class */
       constructor(props) {
           super(props)
           this.state={
               companyname:'Adidas Intern'
           }
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
           MainController.getInitialInformation();
         }
       /* It is invoked to return html content */
       render() {
           return (
            <div className="corporateHousing_Updates">
                <header className="codeconveyHeader cc_image_text_main corporate-h">
                    <img className="img-responsive" src={s3BucketUrl_WebsiteImages+"adidasCommunity.png"}  width="100%" alt=""  />
                    <div className="wow fadeInUp img-on-text top30 width600 text-center left50 img-on-text-center" data-wow-delay="0.5s">
                        <h1>Adidas Communities</h1>
                        <p>Conveniently Located Corporate Housing</p>
                    </div>
                </header>
                <AdidasHeader />
                <div className="container-fluid nike company_section">
                    <div className="container text-center">             
                        <p> Your actual apartment will be selected by SuiteAmerica approximately 20-30 days prior to your arrival date. SuiteAmerica is pleased to offer fully furnished apartments during your internship with Adidas in Portland, Oregon. We provide the furnished apartment with all utilities, linens and wireless internet included.</p>
                        <div className="who_weare_text service_ch_new company_p">
                        <h2 className="">Community Rates</h2>
                            <div className="row">
                                <div className="col-md-6 col-sm-6 col-md-offset-3 col-sm-offset-3">
                                <div className="dual_quote list_block dual_quote_md wow fadeInUp" data-wow-delay="0.5s">
                                    <h3>DOWNTOWN COMMUNITIES</h3>
                                    <p>We offer two options to choose from in the downtown Portland area.</p>
                                    <div className="flex_div flex_list">
                                        <div>
                                            <span>2x2 (2 Occupants)</span>
                                            <span>$126 per person/day</span>
                                        </div>
                                        <div>
                                            <span>2x2 (4 Occupants)</span>
                                            <span>$65 per person/day</span>
                                            <span>2 twin beds per bedroom</span>
                                        </div>
                                    </div>
                                </div>
                                </div>
                       
                            </div>
                    </div>
                    <p className="small-font">*If you are open to sharing a bedroom to further reduce your housing cost, please notate this in the comment section of the questionnaire. Opportunities to share bedrooms will be dependent on amount of interest from fellow interns.</p>
                    
                    
                    </div>
                    
                </div>

                <div className="container-fluid nike text-center">
                <div className="container">
                <p>Click on the properties below for more information.</p>
                        <h2>Communities</h2>
                    </div>
                    </div>

                
                
                <Location companyName={this.state.companyname} props={this.props}/>

                <div className="container-fluid bg-grey">
                    <div className="container">
                        <ContactUs />
                    </div>
                </div>
                <div className="container ft-contact-adrs mrg-btm-30 mrg-top-30">
                    <AdidasFooter />
                </div>
                <SubFooter />
                <Footer/>
            </div>   
           );
       }
   }
   export default AdidasCommunities;