/* Importing the node modules, child components, services and controllers used 
   inside IndividualCorporateHousing component */    
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   import LocationSlider from '../../common/locationSliderData';
   import ReserveYourStay from '../../common/reserveYourStay.js';
   import MetaTags from '../../common/metaTags';
   import ServerMetaTags from '../../../server/metaTags';
   import NavigationFooter from '../../common/navigationFooter';
   import NavigationData from '../../common/navigation.json';
   /* IndividualCorporateHousing Component initialization */
   class IndividualCorporateHousing extends React.Component {
       /* Initializing objects of its IndividualCorporateHousing class */
       constructor(props) {
           super(props);
           this.state = {
              navigationData: NavigationData.individualCorporateHousing,
              all: [
                "Complete furnishings", "Separate living, dining, & sleeping areas", "Full kitchens complete with housewares",
                "2 Televisions, DVD, & basic cable", "Washer & dryer in most apartments", "High-speed internet",
                "Utilities", "24/7 customer support"],
            most: [
                " Fitness facilities", "Business Centers", "Swimming Pools", "Easy Access to Highways",
                "Near Public Transportation", "Controlled Access", "Parking available on site", "Pet-Friendly accommodations"
            ]
           }           
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
            MainController.getProfilePageInformation(13, 4, 4);
        }
       /* It is invoked to return html content */
       render() {
           const metaData = ServerMetaTags.urlString.getMetaData(this.props.match.url);
           const all = this.state.all;
           const most = this.state.most;
           return (
            <div className="corporateHousing_Updates">
                <MetaTags 
                metaTitle = {metaData.title}
                metaDesription = {metaData.description}
                metaUrl = {metaData.canonical} />
                <GuestHeader/>
                <header className="codeconveyHeader cc_image_text_main corporate-h video-wrapper">
                    <img className="img-responsive relocation-corporate-housing" src={s3BucketUrl_WebsiteImages+"Individual-Corporate-Housing-Banner.jpg"} width="100%" alt=""  />
                    <div className="wow fadeInUp img-on-text top40 left15 width500 text-center img-on-text-center" data-wow-delay="0.5s">
                        <h1>CORPORATE HOUSING <i>SOLUTIONS</i></h1>
                        <p>Change is stressful, and moving is all about change.
                             We’re here to help. Need a temporary home during 
                             home renovations? Moving with a lump sum with your 
                             new employer? How about a long-term assignment or 
                             vacation stay? Relax and know that day or night, we’re
                             only a phone call away. 
                        </p>
                    </div>
                </header>
                <ReserveYourStay />
                <div className="who_weare services_ch">
                    <div className="cc_image_text_main">
                        <img className="img-responsive left-mo" src={s3BucketUrl_WebsiteImages+"Individual-Corporate-Housing-Customize-Your-Stay.jpg"} alt="" />
                    </div>
                </div>
                <div className="new_txt_sec">
                    <div className="new_txt_content container wow fadeInUp animated">
                        <div className="col-md-8 col-md-offset-2">
                            <h2>UPGRADE YOUR STAY</h2>
                            <p>Do you need something extra? Is something not quite right? Whether it’s new linens, local information, stronger WiFi or one of our many amenity packages, our Guest Services team is ready to take care of it right away, 24/7.
                            </p>
                            <p> <strong>Electronics, Furniture, Outdoor Activities, Pets, Kids, Kitchen, Car Rental, Housekeeping, Starting Essentials </strong> <i>and more</i>
                            </p>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>
                <LocationSlider {...this.props}/>
                <div className="caption_text services_cs">
                    <div className="container">
                        <div className="who_weare_text22 wow fadeInUp" data-wow-delay="0.5s">
                            <h1 className="font-bold">COMFORT ISN’T</h1>
                            <h2><span>it’s a</span></h2>
                            <h1>AN OPTION</h1>
                            <h2><span>necessity</span></h2>
                        </div>
                    </div>
                </div>
                <div className="container-fluid bg-white">
                    <div className="container">
                        <p className="text-center review_rqst_button accomidation_acco_btn color-red collapsed" role="button" data-toggle="collapse" href="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                        Accommodations
                        </p>
                        <div className="collapse row accomidation_acco" id="collapseExample">
                            <div className="company_section wow fadeInUp">
                            <div className="col-md-6">
                                <div>
                                    <p>ALL APARTMENTS INCLUDE</p>
                                    <ul>
                                            {all.map((row, i) => {
                                                return (<li key={i}>{row}</li>)
                                            })}
                                        </ul>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <p>MOST COMMUNITIES FEATURE</p>
                                    <ul>
                                        {most.map((row, i) => {
                                                return (<li key={i}>{row}</li>)
                                            })}
                                        </ul>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 company_section">
                            <img src={s3BucketUrl_WebsiteImages+"Individual-Corporate-Housing-Conceirge-Services.jpg"} className="img-responsive" alt="" /> 
                            <div className="accom_text wow fadeInUp">
                                <h2>Concierge Services</h2>
                                <p>Having the “little things” during the big move is what we’re here for. Let our Concierge Services team help you settle in with additional amenities and services. From furniture upgrades to customized amenities, we’re only a call (or keystroke) away.
                                </p>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 company_section">
                            <img src={s3BucketUrl_WebsiteImages+"Individual-Corporate-Housing-pts.jpg"} className="img-responsive" alt="" /> 
                            <div className="accom_text wow fadeInUp">
                                <h2>Personal Transition Services</h2>
                                <p>SuiteAmerica’s P.T.S. team offers it’s exclusive, personalized meet & greet services around the clock, so you can be assured that you will arrive safely and settle-in to your new home, hassle-free. Day or night, our team is there to open the door with a smile and provide a warm welcome.
                                </p>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 company_section">
                            <img src="/assets/newUI-images/Website-Images/Corporation-Corporate-Housing-Advanced-Interactive-Tools.jpg" className="img-responsive" alt="" /> 
                            <div className="accom_text wow fadeInUp">
                                <h2>Advanced Interactive Tools</h2>
                                <p>We’ve created a suite of custom, digital tools using the latest technologies to make our time with you more efficient and valuable. From area information, to service requests to information about your community or apartment, you have it all right at your fingertips.
                                </p>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="quote-section bg-grey quote_red">
                    <div className="container">
                        <div className="wow fadeInUp" data-wow-delay="0.5s">
                            <h6 className="quote-s"> <img src={s3BucketUrl_WebsiteImages+"Corporation-Corporate-Housing-Quote.png"} alt=""/></h6>
                            <div className="col-md-10 col-md-offset-1">
                            <h5 className="quote-text">Many thanks to you and the team at SuiteAmerica! This is my first experience 
                                using your services, and I’ve been spreading the word to my colleagues about the 
                                excellent customer service and value you provide. Your team has been stellar! 
                                I will definitely be using this service for upcoming extended trips to the area.
                            </h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="clearfix"></div>
                <NavigationFooter {...this.state} />
                <SubFooter/>
                <Footer/>
            </div>         
   
           );
       }
   }
   export default IndividualCorporateHousing;