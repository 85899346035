/* Importing the node modules, child components, services and controllers used 
   inside Customization component */   
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   import ReserveYourStay from '../../common/reserveYourStay.js';
   import MetaTags from '../../common/metaTags';
   import ServerMetaTags from '../../../server/metaTags';
   import NavigationFooter from '../../common/navigationFooter';
   import NavigationData from '../../common/navigation.json';
   
   /* Customization Component initialization */
   class Customization extends React.Component {
       /* Initializing objects of its Customization class */
       constructor(props) {
           super(props);
           this.state = {
             navigationData: NavigationData.corporationCustomization
         }
       }
       /* It is invoked immediately before a component is mounted */  
       componentWillMount() {
           MainController.getProfilePageInformation(4, 1, 1);
       }
       /* It is invoked to return html content */
       render() {
           const metaData = ServerMetaTags.urlString.getMetaData(this.props.match.url);
           return (
               <div>
                   <MetaTags 
                    metaTitle = {metaData.title}
                    metaDesription = {metaData.description}
                    metaUrl = {metaData.canonical} />
                   <GuestHeader/>
                   <header className="codeconveyHeader cc_image_text_main corporate-h video-wrapper">
                       <img className="img-responsive relocation-corporate-housing" src={s3BucketUrl_WebsiteImages+"Individual-Customization-Banner.jpg"} width="100%" alt=""  />
                       <div className="wow fadeInUp img-on-text top40 width600 text-center left15 img-on-text-center" data-wow-delay="0.5s">
                           <h1>BE OUR GUEST</h1>
                           <p>There’s no place like home, but with easy-to-get amenities, you can make this place feel pretty darn close. Let our Concierge Services team help with the little things that make life a bit easier. We’re only a call away. 
                           </p>
                       </div>
                   </header>
                   <ReserveYourStay />
                   <div className="who_weare services_ch">
                    <div className="cc_image_text_main">
                        <img className="img-responsive left-mo" src={s3BucketUrl_WebsiteImages+"Corporation-Customization-Upgrade-Your-Stay.jpg"} alt="" />
                    </div>
                </div>
                <div className="new_txt_sec">
                    <div className="new_txt_content container wow fadeInUp animated">
                        <div className="col-md-8 col-md-offset-2">
                            <h2>CUSTOMIZE THEIR STAY</h2>
                            <p>Does your transferee need extra towels or linens? Is their mattress too firm or too soft? Let our guest services team know and we’ll take care of it right away, free of charge!  
                            </p>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>
                <div className="bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 company_section">
                            <div className="accom_text wow fadeInUp">
                                <h2>Concierge Services</h2>
                                <p>Your transferee can customize their stay using our Concierge Services and amenities packages. Making them feel secure and comfortable is our number one goal. Whether they require a new mattress or something specific for the kitchen, we’ve got them covered. Items are available in most areas, though in some locations a minimal rental charge and/or delivery charge may apply.
                                </p>
                                <p><strong>Electronics, Furniture, Outdoor Activities, Pets, Kids, Kitchen, Car Rental, Housekeeping, Starting Essentials  </strong> <i>and more</i></p>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid bg-white">
                    <div className="container">
                        <p className="text-center review_rqst_button accomidation_acco_btn color-red collapsed" role="button" data-toggle="collapse" href="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                        Additional Services
                        </p>
                        <div className="collapse row accomidation_acco" id="collapseExample">
                            <div className="company_section wow fadeInUp">
                            <div className="col-md-6">
                                <div>
                                    <ul>
                                        <li>Package delivery</li>
                                        <li>Same day delivery</li>
                                        <li>Transportation services</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <ul>
                                        <li>Custom Intallations</li>
                                        <li>Moving help</li>
                                        <li>and more</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 company_section">
                            <img src={s3BucketUrl_WebsiteImages+"Corporation-Customization-VIP+Extras.jpg"} className="img-responsive" alt="" /> 
                            <div className="accom_text wow fadeInUp">
                                <h2>VIP Extras</h2>
                                <p>Impress your tranferees by adding some luxury to their stay. We will roll out the red carpet and provide a true VIP experience at your behest. We offer upgrades in elegance including lush and sophisticated pillows, robes, textiles, throw blankets and more.
                                </p>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 company_section">
                            <img src={s3BucketUrl_WebsiteImages+"Corporation-Customization-Pets.jpg"} className="img-responsive" alt="" /> 
                            <div className="accom_text wow fadeInUp">
                                <h2>Pets and Children Packages</h2>
                                <p>Happy families mean happy employees. We want your tranferee’s entire family to feel comfortable in their new space. They can let us know what gadgets, equipment, toys, treats, tech, bedding, or décor they need, and we will make it happen. 
                                </p>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 company_section">
                            <img src={s3BucketUrl_WebsiteImages+"Corporation-Customization-Customized-Cooking.jpg"} className="img-responsive" alt="" /> 
                            <div className="accom_text wow fadeInUp">
                                <h2>Customized Cooking Packages</h2>
                                <p>Dietary restrictions, picky eaters, and gourmands are no problem. Whatever the ask, our Concierge Services team will deliver what your tranferees need, right to their door. We can also take the stress out of planning those first few meals—opt for fully stocked refrigerators and pantries to prevent the hassle of finding a store or restaurant right away.
                                </p>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="quote-section bg-grey quote_red">
                    <div className="container">
                        <div className="wow fadeInUp" data-wow-delay="0.5s">
                            <h6 className="quote-s"> <img src={s3BucketUrl_WebsiteImages+"Corporation-Corporate-Housing-Quote.png"} alt=""/></h6>
                            <div className="col-md-10 col-md-offset-1">
                            <h5 className="quote-text">Tess was great and the requests were addressed efficiently. Much appreciated.
                            It was wonderful to see all those groceries in my refrigerator when I arrived. Lifted
                            my spirits after a very long flight and arriving at midnight, very thoughtful. 
                            Thank you! Thank you again for all the great welcome and help.
                            </h5>
                            </div>
                        </div>
                    </div>
                </div>
                   <div className="clearfix"></div>
                   <NavigationFooter {...this.state} />
                   <SubFooter />
                   <Footer/>
                </div>
           );
       }
   }
   export default Customization;