
import { Link } from 'react-router-dom';
import EncryptDecrypt from '../../../services/encryptDecrypt';
import LocationService from '../../../services/locationService';
import ViewPlaceModal from './viewPlaceModal';
import CommunityBookNowModal from '../../controller/communityBookNowModal';
import PdfService from '../../../services/pdfService';
import AppController from "../../controller/appController";
import CommunityMoreInfo from './communityMoreInfo';

class Community extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      company: '',
      walkscore: '',
      viewPlaces: false,
      calendarStatus: false,
      directionsDisplay: '',
      directionsService: '',
      pdfLoading: false,
      accordionOpen: false,
      favourite: false,
      complexImages: [],
      AmenitiesList: [],
      communityDetails: false,
      communityIndex: -1
    }
    this.unFavouriteCommunity = this.unFavouriteCommunity.bind(this);
    this.favouriteCommunity = this.favouriteCommunity.bind(this);
    this.getCommunityDetails = this.getCommunityDetails.bind(this);
    this.viewPlaces = this.viewPlaces.bind(this);
    this.moreInfo = this.moreInfo.bind(this);
    this.calendarInfo = this.calendarInfo.bind(this);
    this.pdfGeneration = this.pdfGeneration.bind(this);
    this.calendarClose = this.calendarClose.bind(this);
  }
  async componentDidMount() {
    const communityData = this.props.row
    const data = { address: communityData.address, latitude: communityData.latitude, longitude: communityData.longitude };
    const result = await AppController.wallsckoreFun(data)
    this.setState({ walkscore: result.walkscore })
  }
  async moreInfo(data, favouriteCheck) {
    if (favouriteCheck != undefined && favouriteCheck.favourite_id != '') {
      data[this.state.favourite] = favouriteCheck.favourite_id;
      data['companydetails'] = this.props.companyName
      localStorage.setItem('moreData', JSON.stringify(data));
    } else {
      data[this.state.favourite] = '';
      data['companydetails'] = this.props.companyName
      localStorage.setItem('moreData', JSON.stringify(data));
    }
    const webGuest = localStorage.getItem('_u_web');
    const response = await EncryptDecrypt.decryptData({ 'type': webGuest })
    const userData = response.recordset[0]
    if (userData != null) {
      const RecentlyViewed = {
        user_id: (userData != undefined && Object.keys(userData).length > 0)
          ? userData.Id
          : '',
        community_id: data.community_id,
        createdBy: (userData != undefined && Object.keys(userData).length > 0)
          ? userData.GuestEmail
          : ''
      }
      await LocationService.saveRecentlyViewedCommunity(RecentlyViewed)
    }
  }
  async viewPlaces() {
    await this.setState({ viewPlaces: !this.state.viewPlaces })
  }
  async calendarInfo(data) {
    await this.setState({ calendarStatus: true })
  }
  async calendarClose() {
    await this.setState({ calendarStatus: false })
  }
  async favouriteCommunity(data) {
    await this.setState({ favourite: true })
    await this.props.favouriteCommunity(data)
    await this.setState({ favourite: false })
  }
  /**
    * when  the person is loggined then only delete the selected community 
    * @param {* communityid} isFavourite 
    */
  async unFavouriteCommunity(isFavourite) {
    await this.setState({ favourite: true })
    await this.props.unFavouriteCommunity(isFavourite);
    await this.setState({ favourite: false })
  }
  async pdfGeneration(data, communityAmenities, appartmentAmenities, complexImages) {
    this.setState({ pdfLoading: true });
    const finalData = { community: data, appAmenities: appartmentAmenities, commAmenities: communityAmenities, communityImages: complexImages };
    let result = await PdfService.pdf(finalData);
    console.log('result', result)
    await window.open(result.FileUrl)
    this.setState({ pdfLoading: false });
  }
  async getCommunityDetails(row, index) {
    this.setState({ communityIndex: index });
    if (this.state.communityIndex == index) {
      $('.panel-collapse').collapse('hide');
    } else {
      $('.panel-collapse.collapse').removeClass("in");
      $('.panel-title a').addClass('collapsed');
      const AmenitiesList = row.AmenitiesList != undefined && row.AmenitiesList != null ? _.groupBy(JSON.parse(row.AmenitiesList), 'communityname') : row.AmenitiesList
      const CommunityImages = row.CommunityImages != undefined && row.CommunityImages != null ? _.groupBy(JSON.parse(row.CommunityImages), 'imageType') : row.CommunityImages
      const complexImages = CommunityImages != undefined && CommunityImages != null ? (CommunityImages.complex != undefined ? CommunityImages.complex : CommunityImages.interior) : []

      this.setState({
        AmenitiesList: AmenitiesList != undefined && AmenitiesList != null ? AmenitiesList : {},
        complexImages: complexImages,
      });
      if (this.state.communityIndex != index) {
        //  await MapController.loadMapData(row, this.props.index, this, '', 'https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/location_pin_2.svg')
      }
    }
  }

  render() {
    const row = this.props.row
    const index = this.props.index
    const isFavourite = (row.favouritestatus != undefined && row.favouritestatus != null && row.favouritestatus != false) ? row.favouritestatus : false
    const favourite = this.state.favourite ? <i style={{ position: 'absolute', bottom: '20px', right: '15px', fontSize: '30px', color: "#FFF" }} className="fa fa-spinner fa-spin" /> : ""
    const ClassName = this.state.favourite ? '' : (isFavourite ? "fa fa-heart" : "fa fa-heart-o")
    const icon = this.state.favourite ? favourite : (this.props.companyName == 'suiteamerica' ? <i className={ClassName} style={{ color: isFavourite ? 'red' : '' }}></i> : null)
    return (<div
      className="location_searchbottom"
      id="accordion"
      role="tablist"
      aria-multiselectable="true"
    >
      <div className="panel panel-default">
        <div className="container">
          <div className="location_searchresult">
            <div className="row">
              <div className="col-md-2 col-sm-2">
                <div className="l_area wow fadeInRight">
                  <h1>{row.state_name}</h1>
                  <p>{row.city_name}  </p>

                </div>
              </div>
              <div className="col-md-3 col-sm-3  wow fadeInLeft">
                <div className="ls_images">

                  {
                    (row.image_name != null && row.image_name != undefined) ?

                      <Link
                        to={'/community/' + this.props.companyName + '/' + row.community_name.replace(/\/| /g, '-')}
                        target="_blank"><img className="img-responsive" src={row.image_name} alt="" /></Link> : <img className="img-responsive" src={s3BucketUrl_WebsiteImages + "SuiteAmerica-Guest-Portal-Main-Location_Down.jpg"} alt="" />
                  }

                  {/* <a
                          style={{
                          'cursor': 'pointer'
                        }}
                          data-toggle="modal"
                          href={this.props.localStorageVal}
                          data-backdrop="static"
                          data-keyboard="false"
                          onClick={isFavourite
                               ? () => this.unFavouriteCommunity(row)
                               : () => this.favouriteCommunity(row)}> 
                                 {icon}
                            
                        </a> */}

                </div>
              </div>
              <div className="col-md-4 col-sm-3">
                <div className="l_address wow zoomIn">
                  <a
                    className="collapsed"
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href={'#expand' + row.community_id}
                    data-id={row.community_id}
                    aria-expanded="false"
                    aria-controls={row.community_id}
                    onClick={() => this.getCommunityDetails(row, index)}> <h1>{row.community_name}</h1>
                    <p>{row.address}</p></a>

                  <div className="panel-heading" role="tab" id="headingThree22">
                    <h4 className="panel-title">
                      <a
                        className="collapsed"
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href={'#expand' + row.community_id}
                        data-id={row.community_id}
                        aria-expanded="false"
                        aria-controls={row.community_id}
                        onClick={() => this.getCommunityDetails(row, index)}></a>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-4">
                <div className="l_highlights wow zoomIn">
                  <ul>
                    <li onClick={this.viewPlaces}>
                      {(this.state.walkscore != '' && this.state.walkscore != null && this.state.walkscore != undefined) ?
                        <a href="#" className="flat_num"
                          data-toggle="modal" data-target="#viewPlaces"
                          style={{
                            'cursor': 'pointer'
                          }} >


                          {this.state.walkscore != '' ? this.state.walkscore : <i className="fa fa-spinner fa-spin" style={{ fontSize: "25px" }} />}
                        </a> : null}
                    </li>
                    {row.community_name != undefined ? <li>
                      <Link
                        to={'/community/' + this.props.companyName + '/' + row.community_name.replace(/\/| /g, '-')}
                        target="_blank"
                        className="flat_num22"
                        style={{
                          'cursor': 'pointer'
                        }}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="More Information"
                        onClick={() => this.moreInfo(row, {})}
                      >i</Link>
                    </li> : ''}
                    <li>
                      <a href="#"
                        data-toggle="modal" data-target="#community_book_now"
                        style={{
                          'cursor': 'pointer'
                        }} onClick={() => this.calendarInfo(row)} >

                        <i className="fa fa-calendar" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a><img className="fa-wheelchair" src={"/assets/newUI-images/locations/Ada-Compliant.png"} alt="" /></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="location_searchresult_neww">
          <div className="row">
            <div
              id={'expand' + row.community_id}
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby="headingThree22">
              <div className="panel-body">

                {this.state.loading == true ? <div className="panel-body_loader"> <span style={{ fontSize: '20px', width: '100%', margin: 'auto', textAlign: 'center' }}><i className="fa fa-spinner fa-spin" /> please wait ...!</span>

                </div> : ((this.state.communityIndex == index) ? <CommunityMoreInfo index={this.props.index}
                  pdfGeneration={() => this.pdfGeneration(row, this.state.AmenitiesList.Community, this.state.AmenitiesList.Unit, this.state.complexImages)} {...this.state} /> : '')

                }
              </div>
            </div>
          </div>
        </div>
      </div>
      {this.state.viewPlaces ? <ViewPlaceModal viewPlaces={this.viewPlaces} latitude={this.props.row.latitude} longitude={this.props.row.longitude} communityName={this.props.row.community_name} /> : ""}
      {this.state.calendarStatus
        ? <CommunityBookNowModal calendarClose={this.calendarClose} CommunityDetails={this.props.row} />
        : ''}
    </div>)
  }
}

export default Community;

