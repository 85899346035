/* Importing the node modules, child components, services and controllers used 
   inside ContactUs component */ 
   import ReserveNowApi from '../../../services/relo';
   import OrdersTable from './ordersTable';
   import { confirmAlert } from 'react-confirm-alert';
   import AppController from "../../controller/appController";
   /* ContactUs Component initialization */
   class OrdersList extends React.Component{
     /* Initializing objects of its ContactUs class */
       constructor(props) {
             super(props);	
             this.state={
                  ordersInfo: [],
                  parent_origin : null,
                  child_source : null,
                  ReloId: '',
                  table: false,
                  loader: false,
                  message: '',
                  color:'',
                  airconditioning: true,
                  availablefrom: true,
                  availableto: true,
                  backgroundreq: true,
                  bathrooms: true,
                  bedrooms: true,
                  currency: true,
                  disabilityaccess: true,
                  floor: true,
                  holdunit: true,
                  internet: true,
                  kitchentype: true,
                  maidservice: true,
                  masterId: true,
                  minimumstay: true,
                  notice: true,
                  orderId: true,
                  parking: true,
                  parkingspaces: true,
                  petfeesandrestrictions: true,
                  petpolicy: true,
                  portalId: true,
                  rate: true,
                  rateperday: true,
                  smokingunit: true,
                  squarefoot: true,
                  style: true,
                  supplier: true,
                  unittype: true,
                  utilitycap: true,
                  washer_dryer: true,
                  SubmitionsData : [],
             }	
             this.identifierSubmit=this.identifierSubmit.bind(this)
             this.orderInfo=this.orderInfo.bind(this)
             this.setValue=this.setValue.bind(this)
             this.handleInputChange=this.handleInputChange.bind(this)
             this.checkData=this.checkData.bind(this)
             this.clear=this.clear.bind(this)
             this.SubmitOrderInfo=this.SubmitOrderInfo.bind(this)
       }
       /* It is invoked immediately before mounting occurs */ 
       handleInputChange(field,e) {
        
        var object = {};
        object[field] = e.target.checked;
        this.setState(object);
      }
       async orderInfo(){
         if(this.state.ReloId!='' ){
          this.setState({table:false,loader:true,message:'',orderInfo:[]})
          const Request={
            ReloQuestId : this.state.ReloId //'a35f90e2-4284-4883-b45e-3d89e5bbb32c'
           }
           
            const userData = await ReserveNowApi.GetOrderQuickQuotes(Request)
            
            this.setState({ordersInfo:userData,table:true,loader:false})
         }
        else{
          this.setState({message:'Please Enter RQ#',color:'red'})
        }
       }
       setValue(field, e) {
        var object = {};
        object['message'] = '';
        object[field] = e.target.value;
        this.setState(object);
      }

       async componentDidMount() {
        localStorage.removeItem('relodata');
        var url=this.props.location.search.split(/[ /\(&\)]+/)
        if(url[0] =='?auth=c3VpdGVBbWVyaWNhQDIwMTk'){
          console.log(';')
        }else{
          location.href = '/pagenotfound'
        }
        
        
       }

       async SubmitOrderInfo(){
        if(this.state.SubmitionsData.length > 0){
if(this.state.SubmitionsData.length == 1){
 var data=this.state.SubmitionsData[0].data
 var Start=AppController.DateFormate(data.availablefrom)
var End=AppController.DateFormate(data.availableto)
 var json = {
   propid: data.portalId,
   unitid: data.suitenumber,
   daterange: Start+"-"+End, 
   Floor: data.floor ,
   bedrooms: data.bedrooms,
   bathrooms: data.bathrooms,
   sqft:  data.squarefoot ,
   unittype: data.unittype,
   Quoted_rate: data.rate
   }
  await localStorage.setItem('relodata', JSON.stringify(json));
  $("#button-identifier-for-submitting").trigger("click");
}
else{
 $('html, body').animate({scrollTop: 0});
 this.setState({message: "Please select only one Record", color: 'red',})
}
}
else{
 $('html, body').animate({scrollTop: 0});
 this.setState({message: "Please select atleast one Record", color: 'red',})
}
      
      }
       async checkData(row){
        this.setState({message: "", color: ''})
        const emailIndex = _.findIndex(this.state.SubmitionsData, function (o) {

          return o.indexVal == row.masterId;
      });
      
      if (emailIndex < 0) {
          await this.setState({
            SubmitionsData: _.concat(this.state.SubmitionsData, {
                  data: row,
                  indexVal: row.masterId
              })
          })

      } else {
          await this.setState({
            SubmitionsData: update(this.state.SubmitionsData, {
                  $splice: [
                      [emailIndex, 1]
                  ]
              }, 0)
          })
      }
       }
  
    clear(){
this.setState({SubmitionsData:[]})
    }
    identifierSubmit(){
           
      const messageString =  'Are you sure you want to Submit !!!' 
    

      confirmAlert({
        title: '',                        // Title dialog
        message: messageString,               // Message dialog
        childrenElement: () => '',       // Custom UI or Component
        confirmLabel: 'Yes',                           // Text button confirm
        cancelLabel: 'No',                             // Text button cancel
        onConfirm: () => {this.SubmitOrderInfo()},    // Action after Confirm
        onCancel: ()=> {},      // Action after Cancel
      })
    }
   /* It is invoked to return html content */
   render(){
       return(        
        <div className="container ">
       
        <h2>RQ Info</h2>
        
        <div className="row mrg-btm-10">
        <div className='col-md-5'>
        <div className="flex-form-item rq-info">
               <input type="text" style={{backgroundColor:'#FFF'}} className="form-control" name='Relocation#'  placeholder="RQ#" value={this.state.ReloId} onChange={(e)=>{this.setValue('ReloId',e)}}/>
               <div className="search-btn">
         <button type="submit" className="btn btn-danger" onClick={this.orderInfo} disabled={this.state.loader?'disabled':''}> Search </button></div>
            </div>

            
            {this.state.ordersInfo.length>0? <p style={{color:this.state.color}}>{this.state.message}</p>:''}
        </div>
        
        {this.state.ordersInfo.length>0 ?<div className='col-md-7'>
       
        <h2 className="rq-id">Order#: <span className="text-light">{this.state.ordersInfo[0].orderId}</span></h2>
        </div> : <div className='col-md-7'>
        <p className="rq-msg-success" style={{color:this.state.color}}>{this.state.message}</p></div>}

        {/* <div className='col-md-9'>
            <div className="form-group">
                <select multiple> 
                  <input type="checkbox" className="form-control" /> option 1
                  <option><input type="checkbox" className="form-control" /> option 1</option>
                  <option><input type="checkbox" className="form-control" /> option 1</option>
                  <option><input type="checkbox" className="form-control" /> option 1</option>
                  <option><input type="checkbox" className="form-control" /> option 1</option>
                </select>
            </div>
        </div> */}
        </div>
        <OrdersTable  {...this.state} checkData={this.checkData}/>
        <button type="submit"  className={this.state.ordersInfo.length<=0?"btn btn-primary mrg-top-20 disbalecolor":"btn btn-primary mrg-top-20 "} onClick={this.SubmitOrderInfo} disabled={this.state.ordersInfo.length<=0?'disabled':''}>Submit</button>
        <button type="submit" id="button-identifier-for-submitting" ></button>
        <span>&nbsp; &nbsp;</span>
        <button type="submit" className={this.state.ordersInfo.length<=0?"btn btn-primary mrg-top-20 disbalecolor":"btn btn-primary mrg-top-20 "}  onClick={this.clear} disabled={this.state.ordersInfo.length<=0?'disabled':''}>Clear</button>
        {/* <ColumnsFilter handleInputChange={this.handleInputChange} {...this.state}/> */}
      </div>
       );
   }
   }
   export default OrdersList;