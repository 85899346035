"use strict";
/* Importing the node modules, child components, services and controllers used */
import { createBrowserHistory } from "history";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import LandingVideo from "./components/landing/landingVideo";
import Locations from "./components/location/locations";
import Login from "./components/guestPortal/guestLogin";
import GuestRegistrationWithoutMail from "./components/guestPortal/guestRegistrationWithoutMail";
import GuestSignupWithMail from "./components/newinterface/guestPortal/guestSignupWithMail";

import GuestProspectiveRegister from "./components/guestRegister";
import GuestForgotPassword from "./components/guestforgotpassword";
import GuestReview from "./components/guestReview";
import newcommunity from "./components/newcommunity/ncommunity";
import communityLocation from "./components/communityLocation";
import IndividualCorporateHousing from "./components/individual/corporateHousing";
import RelocationCorporateHousing from "./components/relocation/corporateHousing";
import DestinationServices from "./components/services/destinationServices";
import Technology from "./components/landing/technology";
import GovernmentTechnology from "./components/government/technology";
import IndividualTechnology from "./components/individual/technology";
import RelocationTechnology from "./components/relocation/technology";
import ClientPortal from "./components/clientPortal/clientPortal";
import ClientRegister from "./components/clientPortal/clientRegister";

import Guestportal from "./components/guestPortal/guestportal";
import ReservationInfo from "./components/guestPortal/reservationInfo";
import CheckIn from "./components/guestPortal/checkIn";
import CheckOut from "./components/guestPortal/checkOut";
import GuestServices from "./components/guestPortal/guestServices";

// import OpenRequest from './components/guestPortal/openRequest';
import MovingIn from "./components/guestPortal/movingIn";
import MovingOut from "./components/guestPortal/movingOut";
import Instructions from "./components/guestPortal/instructions";
import CustomAmenities from "./components/guestPortal/customAmenities";
import SeavedSearch from "./components/SeavedSearch";
//intranet
import IntranetLogin from "./components/intranet/intranetLogin";
import ChangePassword from "./components/intranet/changePassword";
import IntranetForgotPassword from "./components/intranet/intranetForgotPassword";
import IntranetHome from "./components/intranet/intranetHome";
import Staff from "./components/intranet/employees/staff";
import IntranetOrderProcess from "./components/intranet/materials/intranetOrderProcess";
import IntranetForm from "./components/intranet/forms/intranetForm";
import ToForm from "./components/intranet/forms/toForm";
import ConfirmationPdf from "./components/intranet/forms/confirmationpdf";

import ForgotpaswordLink from "./components/intranet/forgotpaswordLink";
// five star pages
import DriversSchedule from "./components/fivestar/schedule/driversSchedule";
import Calender from "./components/fivestar/schedule/calender";
import DiverScheduleMap from "./components/fivestar/schedule/diverScheduleMap";
import ServiceRequestForm from "./components/fivestar/schedule/serviceRequestForm";
import FivekeyList from "./components/fivestar/schedule/keyList";
import ScheduleCheckList from "./components/fivestar/schedule/scheduecheckList";
import OccupancyChecker from "./components/fivestar/occupancy/occupancyChecker";
import CheckList from "./components/fivestar/checkList/checkList";
import Email from "./components/fivestar/email/email";
import VehicleCheckList from "./components/fivestar/vehicleCheckList/vehicleCheckList";
import FiveStarLanding from "./components/fivestar/fiveStarLanding";
import Forgotpassword from "./components/fivestar/forgotpassword";
import GovernmentHousing from "./components/government/governmentHousing";
import Global from "./components/relocation/global";
import Customization from "./components/corporation/customization";
import IndividualCustomization from "./components/individual/customization";
import RelocationCustomization from "./components/relocation/customization";
import GovernmentCustomization from "./components/government/customization";
import communityMapView from "./components/nikeDs/communityMapView";
import DSLanding from "./components/ds/dsLanding";
import PropertyDetails from "./components/ds/propertyDetails";
import PropertyMapView from "./components/ds/propertyMapView";

import NikeHome from "./components/nikePages/nikeHome";
import NikeAddedValue from "./components/nikePages/nikeAddedValue";
import NikeFrequentQuestions from "./components/nikePages/nikeFrequentQuestions";
import NikeLocations from "./components/nikePages/nikeLocations";
import NikeOnlineManagement from "./components/nikePages/nikeOnlineManagement";
import NikeReservationForm from "./components/nikePages/nikeReservationForm";
import NikeWhatsIncluded from "./components/nikePages/nikeWhatsIncluded";

import TeslaHome from "./components/teslaPages/teslaHome";
import TeslaAddedValue from "./components/teslaPages/teslaAddedValue";
import TeslaFrequentQuestions from "./components/teslaPages/teslaFrequentQuestions";
import TeslaLocations from "./components/teslaPages/teslaLocations";
import TeslaOnlineManagement from "./components/teslaPages/teslaOnlineManagement";
import TeslaReservationForm from "./components/teslaPages/teslaReservationForm";
import TeslaWhatsIncluded from "./components/teslaPages/teslaWhatsIncluded";

import AbcHome from "./components/abcPages/abcHome";
import AbcAddedValue from "./components/abcPages/abcAddedValue";
import AbcFrequentQuestions from "./components/abcPages/abcFrequentQuestions";
import AbcLocations from "./components/abcPages/abcLocations";
import AbcOnlineManagement from "./components/abcPages/abcOnlineManagement";
import AbcReservationForm from "./components/abcPages/abcReservationForm";
import AbcWhatsIncluded from "./components/abcPages/abcWhatsIncluded";

// lumentum
import LumentumHome from "./components/lumentum/lumentumHome";
import LumentumAddedValue from "./components/lumentum/lumentumAddedValue";
import LumentumLocations from "./components/lumentum/lumentumLocations";
import LumentumOnlineManagement from "./components/lumentum/lumentumOnlineManagement";
import LumentumReservationForm from "./components/lumentum/lumentumReservationForm";
import LumentumWhatsIncluded from "./components/lumentum/lumentumWhatsIncluded";

// Adobe
import AdobeHome from "./components/adobe/adobeHome";
import AdobeAddedValue from "./components/adobe/adobeAddedValue";
import AdobeLocations from "./components/adobe/adobeLocations";
import AdobeOnlineManagement from "./components/adobe/adobeOnlineManagement";
import AdobeReservationForm from "./components/adobe/adobeReservationForm";
import AdobeWhatsIncluded from "./components/adobe/adobeWhatsIncluded";

import NikeInternshipHome from "./components/nikeInternship/nikeInternshipHome";
import NikeInternshipCommunities from "./components/nikeInternship/nikeInternshipCommunities";
import NikeInternshipApartmentTour from "./components/nikeInternship/nikeInternshipApartmentTour";
import NikeInternshipContactUs from "./components/nikeInternship/nikeInternshipContactUs";
import NikeInternshipQuestionnaire from "./components/nikeInternship/nikeInternshipQuestionnaire";
import NikeInternshipFAQ from "./components/nikeInternship/nikeIntershipFaq";

import NvidiaInternshipHome from "./components/Nvidia/nikeInternshipHome";
import NvidiaInternshipCommunities from "./components/Nvidia/nikeInternshipCommunities";
import NvidiaInternshipApartmentTour from "./components/Nvidia/nikeInternshipApartmentTour";
import NvidiaInternshipContactUs from "./components/Nvidia/nikeInternshipContactUs";
import NvidiaInternshipQuestionnaire from "./components/Nvidia/nikeInternshipQuestionnaire";
import NvidiaInternshipFAQ from "./components/Nvidia/nikeIntershipFaq";

import RequestReservation from "./components/Forms/requestReservation";
import ReservationContactUs from "./components/Forms/contactUs";
import RelocationPriceForm from "./components/Forms/relocationPriceForm";
import InspectionForm from "./components/fivestar/inspectionForm/inspectionForm";
import NoMatchFound from "./common/noMatchFound";
import CompanyInfo from "./common/companyInfo";
import MediaKit from "./components/Media/mediaKit";
import MediaContact from "./components/Media/mediaContact";
import MediaWhoWeAre from "./components/Media/whoWeAre";
import MediaWhatWeDo from "./components/Media/whatWeDo";
import MediaOurPhilosophy from "./components/Media/ourPhilosophy";
import MediaOurMission from "./components/Media/ourMission";
import MediaPressReleases from "./components/Media/pressReleases";
import MediaHousingSolutions from "./components/Media/housingSolutions";
import MediaIndustryAwards from "./components/Media/industryAwards";
import MediaLeadershipTeam from "./components/Media/leadershipTeam";
import MediaCorporateLocations from "./components/Media/corporateLocations";
import MediaPointsOfDifference from "./components/Media/pointsOfDifference";
import MediaOurServices from "./components/Media/ourServices";
import PrivacyPolicy from "./common/privacyPolicy";

import CareerOpportunities from "./common/careerOpportunities";
import Testimonials from "./common/testimonials";
import EmploymentSearch from "./common/employmentSearch";
import CorporationCorporateHousing from "./components/corporation/corporateHousing";
import CorporationDestinationServices from "./components/corporation/destinationServices";
import IndividualDestinationServices from "./components/individual/destinationServices";
import IndividualGlobal from "./components/individual/global";
import CorporationGlobal from "./components/corporation/global";
import TravelBookingAdmin from "./components/travelBooking/admin";
import SuccessPage from "./components/travelBooking/successpage";
import TravelBookingLanding from "./components/travelBooking/landingScreen";
import OurCommunities from "./components/travelBooking/ourCommunities";
import BookingDetails from "./components/travelBooking/bookingDetails";
import HousingSolutions from "./common/housingSolutions";
import LeadershipTeam from "./common/leadershipTeam";
import IndustryAwards from "./common/industryAwards";
import PressReleases from "./common/pressReleases";
import MediaContactUs from "./common/mediaContact";
import CeReservationRequest from "./components/Forms/ceReservationRequest";
import PTSImpression from "./components/Forms/ptsImpression";
import CorporateStudentProgram from "./components/Forms/cspForm";

import TourSelectionForm from "./components/Forms/tourSelectionForm";
import CorporateStudentForm from "./components/Forms/corporateStudentForm";
import XlsUpload from "./components/travelBooking/xlsUpload";
import GSA from "./common/gsa";
import StayWithUS from "./components/Forms/stayWithUs";
import EncryptDecrypt from "../services/encryptDecrypt";
import SessionService from "../services/sessionService";
import LockBox from "./components/lockBox/lockBox";
import WorldBankHome from "./components/worldBank/home";
import WorldBankAddedValue from "./components/worldBank/home2";
import WorldBankDestinationServices from "./components/worldBank/destinationServices";
import WorldBankLocations from "./components/worldBank/locations";
import WorldBankOnlineManagement from "./components/worldBank/onlineManagement";
import WorldBankTransportation from "./components/worldBank/transportation";
import WorldBankWhatsIncluded from "./components/worldBank/whatsIncluded";
import WorldBankRequestReservation from "./components/worldBank/requestReservation";
import WorldBankContactUs from "./components/worldBank/contactUs";
import H1BVisaLanding from "./components/h1BVisa/landingPage";
import RobeCampaignLanding from "./components/robeCompaign/landingPage";
import ClientLogin from "./components/clientPortal/clientLogin";
import clientForgotPassword from "./components/clientPortal/clientForgotPassword";
//SpotLight
import SpotLightCitys from "./components/cityPages/spotLightCitys";
import virtualSpotLightCitys from "./components/virtualCityPages/spotLightCitys";
import GuestLogin from "./common/guestLogin";
import ReserveYourStay from "./components/reserveNow/reserveYourStay";
import ApprenticeshipHome from "./components/apprenticeship/apprenticeshipHome";
import ApprenticeshipCommunities from "./components/apprenticeship/apprenticeshipCommunities";
import ApprenticeshipApartmentTour from "./components/apprenticeship/apprenticeshipApartmentTour";
import ApprenticeshipContactUs from "./components/apprenticeship/apprenticeshipContactUs";
import ApprenticeshipQuestionnaire from "./components/apprenticeship/apprenticeshipQuestionnaire";
import ApprenticeshipFAQ from "./components/apprenticeship/apprenticeshipFaq";
import LockheedHome from "./components/lockheed/lockheedHome";
import LockheedAddedValue from "./components/lockheed/lockheedAddedValue";
import LockheedFrequentQuestions from "./components/lockheed/lockheedFrequentQuestions";
import LockheedLocations from "./components/lockheed/lockheedLocations";
import LockheedOnlineManagement from "./components/lockheed/lockheedOnlineManagement";
import LockheedReservationForm from "./components/lockheed/lockheedReservationForm";
import LockheedWhatsIncluded from "./components/lockheed/lockheedWhatsIncluded";
import CeLogin from "./components/ceApp/ceLogin";
import CeDashboard from "./components/ceApp/ceDashboard";
import GuestProspective from "./components/guestProspective";
//adidas
import AdidasFAQ from "./components/adidas/adidasFaq";
import AdidasHome from "./components/adidas/adidasHome";
import AdidasCommunities from "./components/adidas/adidasCommunities";
import AdidasApartmentTour from "./components/adidas/adidasApartmentTour";
import AdidasContactUs from "./components/adidas/adidasContactUs";
import AdidasQuestionnaire from "./components/adidas/adidasQuestionnaire";

//ServiceNow
import ServiceNowInternFAQ from "./components/serviceNowIntern/serviceNowInternFaq";
import ServiceNowInternHome from "./components/serviceNowIntern/serviceNowInternHome";
import ServiceNowInternCommunities from "./components/serviceNowIntern/serviceNowInternCommunities";
import ServiceNowInternApartmentTour from "./components/serviceNowIntern/serviceNowInternApartmentTour";
import ServiceNowInternContactUs from "./components/serviceNowIntern/serviceNowInternContactUs";
import ServiceNowInternQuestionnaire from "./components/serviceNowIntern/serviceNowInternQuestionnaire";
import BechtelPdf from "./components/employeePdfs/bechtel";
import Artofwelcome from "./components/employeePdfs/artofwelcome";
import BAEPdf from "./components/employeePdfs/bae";
//CePackages
import CePackageHome from "./components/CePackages/cePackageHome";
import OrdersInfoDisplay from "./components/orderInfo/OrdersInfoDisplay";
import CityHomePage from "./components/citylandingpage/cityHomePage";

//Google
import GoogleTravel from "./components/google/googleTravel";

//bookingRedirection Links
import BookingRedirctLinks from "./components/BookingPortal/bookingRedirctLinks";
import DeanHome from "./components/dean/deanHome";
import Bungalow from "./components/Bungalow/bungalow";
import Future from "./components/future/future";
import AutoConfirmMain from "./components/autoConfirmRelo/AutoConfirmMainlist";
import ServerErrors from "./common/error";
import DeanAmenities from "./components/dean/deanAmenities";
import DataPrivacy from "./components/dataPrivacy";
import Gdpr from "./components/Forms/gdpr";
import moreDetails from "./components/autoConfirmRelo/moreDetails";
import DeanParking from "./components/DeanParking/DeanParking";
import MyloParking from "./components/MyloParking/MyloParking";

//RiotGames
import RiotGamesFAQ from "./components/riotgames/riotgamesFaq";
import RiotGamesHome from "./components/riotgames/riotgamesHome";
import RiotGamesCommunities from "./components/riotgames/riotgamesCommunities";
import RiotGamesApartmentTour from "./components/riotgames/riotgamesApartmentTour";
import RiotGamesContactUs from "./components/riotgames/riotgamesContactUs";
import RiotGamesQuestionnaire from "./components/riotgames/riotgamesQuestionnaire";
import Testimonial from "./components/testimonial";
import SafetyMeasures from "./components/safetyMeasures";

import dsHome from "./components/dsHome";
import insuranceLanding from "./components/insuranceLanding";
import GuestOrderData from "./components/guestPortal/guestOrderData";
import TripInfoData from "./components/guestPortal/tripInfoData";
import checkIn from "./components/guestPortal/checkIn";
import checkOut from "./components/guestPortal/checkOut";

//Open Links
import IDGuestServices from "./components/idguestservice/guestServices";
import guestOrderActiveData from "./components/idguestservice/guestOrderData";
import Signature from "./components/idguestservice/signature";
import OrderDetails from "./components/guestPortal/orderDetails";
import MyReservations from "./components/guestPortal/myReservations";
import ExploreYourLocation from "./components/guestPortal/exploreYourLocation";
import ServiceRequest from "./components/guestPortal/serviceRequest";

import AmbassadorPackagePdf from "./components/employeePdfs/ambassadorpackage";
import GroceryPackagePdf from "./components/employeePdfs/grocerypackage";
import BusinessFurnishingPdf from "./components/employeePdfs/businessfurnishing";
import ReRoutes from "./reRoutes";

import NewHomepage from "./components/newinterface/newhomepage";
import AboutUs from "./components/newinterface/aboutUs";
import CorporateHousing from "./components/newinterface/CorporateHousing";
import BusinessTravel from "./components/newinterface/businesstravel";
import GovernmentHousingNew from "./components/newinterface/governmentHousing";
import GuestExperience from "./components/newinterface/guestExperience";
import GuestLoginNew from "./components/newinterface/guestLogin";
import InternHousing from "./components/newinterface/InternHousing";
import Location from "./components/newinterface/location";
import PropertyOptionnew from "./components/newinterface/propertyOptionNew";
import PropertySearchPage from "./components/newinterface/propertySearchPage";
import TechnologyNew from "./components/newinterface/technology";
import PropertyDetailsnew from "./components/newinterface/propertyDetailsnew";
import PrivacyPolicyNew from "./components/newinterface/privacyPolicy";
import ContactUsNew from "./components/newinterface/contactUs";
import LeadershipPageNew from "./components/newinterface/leadershipPage";
import unFolding from "./components/newinterface/unFolding";
import CareerPage from "./components/newinterface/careerPage";
import insurancePage from "./components/newinterface/InsurancePage";
import SpotlightCities from "./components/newinterface/spotlightCities";
import NewGuestRegistration from "./components/newinterface/guestRegister";
import Timeline from "./components/newinterface/timeLine";
import CaseStudies1 from "./components/newinterface/caseStudies1";
import CaseStudies2 from "./components/newinterface/caseStudies2";
import CaseStudies3 from "./components/newinterface/caseStudies3";
import PropertyOwners from "./components/newinterface/propertyOwners";
import newcareerOpportunities from "./components/newinterface/careerOpportunities";
import EmploymentSearchs from "./components/newinterface/employmentSearch";

import NewMyReservations from "./components/newinterface/guestPortal/myReservations";
import NewReservations from "./components/newinterface/guestPortal/newReservation";
import NewMoveout from "./components/newinterface/guestPortal/moveout";
import NewGuestService from "./components/newinterface/guestPortal/guestService";
import NewYourLocation from "./components/newinterface/guestPortal/yourLocation";

import Docusign from "./components/newinterface/docusign";

import LatchHome from "./components/latch/latchHome";
import DeanBuildingA from "./components/latch/deanBuildingA";
import DeanBuildingB from "./components/latch/deanBuildingB";
import DeanBuildingD from "./components/latch/deanBuildingD";
import DeanBuildingD1 from "./components/latch/deanBuildingD1";

import Hiro from "./components/hiro/hiro";

import TangerineHome from "./components/tangerinePages/tangerineHome";
import TangerineWhatsIncluded from "./components/tangerinePages/tangerineWhatsIncluded";
import TangerineAddedValue from "./components/tangerinePages/tangerineAddedValue";
import TangerineOnlineManagement from "./components/tangerinePages/tangerineOnlineManagement";
import TangerineLocations from "./components/tangerinePages/tangerineLocations";
import TangerineReservationForm from "./components/tangerinePages/tangerineReservationForm";
import TangerineFrequentQuestions from "./components/tangerinePages/tangerineFrequentQuestions";

import AltairDean from "./components/dean/altairDean";
import AltairDean1 from "./components/altairDean1/altairDean";
import LakSide from "./components/dean/lakeside1250";
import CartusNolo from "./components/altairDean/cartusNolo";

import StarbucksTravelLanding from "./components/starbucksTravel/landingScreen";
import StarbucksSuccessPage from "./components/starbucksTravel/successpage";
import StarbucksBookingAdmin from "./components/starbucksTravel/admin";
import StarbucksBookingDetails from "./components/starbucksTravel/bookingDetails";
import Inventory from "./components/starbucksTravel/inventory";

import RServices from "./components/newinterface/guestPortal/openlinkService/Services";
import guestServiceLink from "./components/newinterface/guestPortal/openlinkService/guestService2";

import UmpquaBank from "./components/dean/umpquaBank";
import UmpquaBankLanding from "./components/UmpquaBank/landingScreen";
import UmpquaBankSuccessPage from "./components/UmpquaBank/successpage";
import UmpquaBankBookingAdmin from "./components/UmpquaBank/admin";
import UmpquaBankBookingDetails from "./components/UmpquaBank/bookingDetails";
import UmpquaBankInventory from "./components/UmpquaBank/inventory";

import StripeInternshipQuestionnaire from "./components/stripeIntern/stripeIntern";
import StripeInternshipHome from "./components/stripeIntern/stripeInternshipHome";
import StripeInternshipCommunities from "./components/stripeIntern/stripeInternshipCommunities";
import StripeInternshipContactUs from "./components/stripeIntern/stripeInternshipContactUs";
import StripeInternshipApartmentTour from "./components/stripeIntern/stripeInternshipApartmentTour";
import StripeInternshipFaq from "./components/stripeIntern/stripeIntershipFaq";
import InternHousingData from "./components/nikeInternship/InternHousingData";
import ThreeSixtyAppleInternHousing from "./components/nikeInternship/ThreeSixtyAppleInternHousing";
import InternHousingDataV2 from "./components/nikeInternship/InternHousingDataV2";
import ThreeSixtyAppleInternHousingV2 from "./components/nikeInternship/ThreeSixtyAppleInternHousingV2";

import GileadInternshipQuestionnaire from "./components/gileadIntern/stripeIntern";
import GileadInternshipHome from "./components/gileadIntern/stripeInternshipHome";
import GileadInternshipCommunities from "./components/gileadIntern/stripeInternshipCommunities";
import GileadInternshipContactUs from "./components/gileadIntern/stripeInternshipContactUs";
import GileadInternshipApartmentTour from "./components/gileadIntern/stripeInternshipApartmentTour";
import GileadInternshipFaq from "./components/gileadIntern/stripeIntershipFaq";

import Byerley from "./components/dean/byerley";
import LoginOld from "./components/guestPortal/guestLogin";

import AltairLogin from "./components/altairApp/altairLogin";
import AltairDashboard from "./components/altairApp/altairDashboard";

import InternLogin from "./components/InternApp/InternLogin";
import InternDashboard from "./components/InternApp/InternDashboard";
//instant booking
import NewLocations from "./components/newinterface/locations/locations";
import LocationPropertyDetails from "./components/newinterface/locations/LocationsPropertyDetails";

import AppleCeHousing from "./components/nikeInternship/AppleCeHousing";

// pivot
import PivotHome from "./components/pivot/abcHome";
import PivotAddedValue from "./components/pivot/abcAddedValue";
import PivotFrequentQuestions from "./components/pivot/abcFrequentQuestions";
import PivotLocations from "./components/pivot/abcLocations";
import PivotOnlineManagement from "./components/pivot/abcOnlineManagement";
import PivotReservationForm from "./components/pivot/abcReservationForm";
import PivotWhatsIncluded from "./components/pivot/abcWhatsIncluded";
import Blog from "./components/blog/blog";
import IndividualBlogPage from "./components/blog/individualBlogPage";

//import Micheal from './components/micheal/micheal'
//import Erica from "./components/erickaAnderson/erickaAnderson";

import Manager from "./components/coporateHousingManagement/manager";
import insuarnceLandingHawaii from "./components/newinterface/insuarnceLandingHawaii";

//BOOKIT

import BookItRegister from "./components/bookIt-New/BookItRegister";
import BookItLogin from "./components/bookIt-New/BookItLogin";
import Dashboard from "./components/bookIt-New/Dashboard";
import YourTeam from "./components/bookIt-New/YourTeam";
//import InventorySuiteCare from "./components/bookIt-New/InventorySuiteCare";
import InventorySuiteCare from "./components/bookIt-New/inventory-suite-care/InventorySuiteCare";


import BookNow from "./components/bookIt-New/BookNow";
import Reserved from "./components/BookIT/Reserved";

import LoginLatest from "./components/BookIT/LogIn";
import BookItLayout from "./components/bookIt-New/BookItLayout";
import ReserveNow from "./components/bookIt-New/ReserveNow";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
const stripePromise = loadStripe(
  "pk_test_51KdytsSErFrSBLHfPxpcto3P8qKglP1o6dQre1poyKbO67P8On3ABi3q4DklgNW1LrF8ykQmkLYnJWTbE01834vD00ZdQwXtTg"
);


// apple
import AppleInternHome from "./components/appleIntern/AppleInternHome";
import AppleInternshipCommunities from "./components/appleIntern/AppleInternshipCommunities";
import AppleInternshipApartmentTour from "./components/appleIntern/AppleInternshipApartmentTour";
import AppleInternshipContactUs from "./components/appleIntern/AppleInternshipContactUs";
import AppleInternshipQuestionnaire from "./components/appleIntern/AppleInternshipQuestionnaire";
import AppleInternshipFAQ from "./components/appleIntern/AppleInternshipFAQ";


//LinkedIn-interns
import ProtectedRoute from "./ProtectedRoute";
import LinkedinInternsHome from "./components/LinkedIn-interns/LinkedinInternsHome";
import LinkedinInternsAboutSuiteAmerica from "./components/LinkedIn-interns/LinkedinInternsAboutSuiteAmerica";
import LinkedinInternsHowItWorks from "./components/LinkedIn-interns/LinkedinInternsHowItWorks";
import LinkedinInternsFAQs from "./components/LinkedIn-interns/LinkedinInternsFAQs";
import LinkedinInternsLocations from "./components/LinkedIn-interns/LinkedinInternsLocations";
import LinkedinInternsReservationForm from "./components/LinkedIn-interns/LinkedinInternsReservationForm";
import LinkedinInternsWhatsIncluded from "./components/LinkedIn-interns/LinkedinInternsWhatsIncluded";
import Housingkeeping from "./components/newinterface/Housekeeping-instructions";


// Wellsfargo_Interns

import WellsfargoInternsHome from "./components/Wellsfargo_Interns/WellsfargoInternsHome";
import WellsfargoInternsAboutSuiteAmerica from "./components/Wellsfargo_Interns/WellsfargoInternsAboutSuiteAmerica";
import WellsfargoInternsHowItWorks from "./components/Wellsfargo_Interns/WellsfargoInternsHowItWorks";
import WellsfargoInternsFAQs from "./components/Wellsfargo_Interns/WellsfargoInternsFAQs";
import WellsfargoInternsLocations from "./components/Wellsfargo_Interns/WellsfargoInternsLocations";
import WellsfargoInternsReservationForm from "./components/Wellsfargo_Interns/WellsfargoInternsReservationForm";
import WellsfargoInternsWhatsIncluded from "./components/Wellsfargo_Interns/WellsfargoInternsWhatsIncluded";

// IntutiveSurgical-Interns
import IntutiveSurgicalInternsHome from "./components/IntuitiveSurgical-Interns/IntutiveSurgicalInternsHome";
import IntutiveSurgicalInternsAboutSuiteAmerica from "./components/IntuitiveSurgical-Interns/IntutiveSurgicalInternsAboutSuiteAmerica";
import IntutiveSurgicalInternsHowItWorks from "./components/IntuitiveSurgical-Interns/IntutiveSurgicalInternsHowItWorks";
import IntutiveSurgicalInternsFAQs from "./components/IntuitiveSurgical-Interns/IntutiveSurgicalInternsFAQs";
import IntutiveSurgicalInternsLocations from "./components/IntuitiveSurgical-Interns/IntutiveSurgicalInternsLocations";
import IntutiveSurgicalInternsReservationForm from "./components/IntuitiveSurgical-Interns/IntutiveSurgicalInternsReservationForm";
import IntutiveSurgicalInternsWhatsIncluded from "./components/IntuitiveSurgical-Interns/IntutiveSurgicalInternsWhatsIncluded";
import CancelHouseKeepingRequest from "./components/cancelHousekeepingRequest/cancelHousekeepingRequest";
import NoticeToVacate from "./components/noticeToVacate/NoticeToVacate";
 
  
/* Load the components based on Local storage elements empty or not */
function decide() {
  const userData = SessionService.SessionGet({ type: "guest" });
  var p1 = Promise.resolve(userData);
  Promise.all([p1]).then((values) => {
    if (values[0].error == 1) {
      const userData = SessionService.SessionDestory({ type: "guest" });
      location.href = "/guest-login";
    }
  });
}

function client() {
  const web = localStorage.getItem("_u_web");
  const userData = EncryptDecrypt.decryptData({ type: web });
  var p1 = Promise.resolve(userData);
  Promise.all([p1]).then((values) => {
    if (values[0].recordset == false) {
      localStorage.removeItem("_u_web");
      location.href = "/";
    } else if (
      values[0].recordset[0].roleId != 3 ||
      values[0].recordset[0].ClientType == "CE"
    ) {
      location.href = "/";
    }
  });
}

function clientIntern() {
  const userData = SessionService.SessionGet({ type: "Intern" });
  var p1 = Promise.resolve(userData);
  Promise.all([p1]).then((values) => {
    if (values[0].error == 1) {
      const userData = SessionService.SessionDestory({ type: "Intern" });
      location.href = "/";
    } else if (values[0].roleId != 5 && values[0].ClientType != "Intern") {
      location.href = "/";
    }
  });
}

function clientCe() {
  const userData = SessionService.SessionGet({ type: "client" });
  var p1 = Promise.resolve(userData);
  Promise.all([p1]).then((values) => {
    if (values[0].error == 1) {
      const userData = SessionService.SessionDestory({ type: "client" });
      location.href = "/";
    } else if (values[0].roleId != 3 && values[0].ClientType != "client") {
      location.href = "/";
    }
  });
}
function intranet() {
  const userData = SessionService.SessionGet({ type: "intranet" });
  var p1 = Promise.resolve(userData);
  Promise.all([p1]).then((values) => {
    if (values[0].error == 1) {
      const userData = SessionService.SessionDestory({ type: "intranet" });
      //location.href = '/intranet2-login';
      location.href = "/invalid";
    }
  });
}

function fivestar() {
  const userData = SessionService.SessionGet({ type: "fivestar" });
  var p1 = Promise.resolve(userData);
  Promise.all([p1]).then((values) => {
    if (values[0].error == 1) {
      const userData = SessionService.SessionDestory({ type: "fivestar" });
      location.href = "/fivestar";
    }
  });
}

function travelBooking() {
  const emailId = localStorage.getItem("travel-email");
  const password = localStorage.getItem("travel-password");
  if (
    (emailId == undefined || emailId == null) &&
    (password == undefined || password == null)
  ) {
    location.href = "/travel-booking";
  }
}

function starbucksTravel() {
  const emailId = localStorage.getItem("starbuckstravel-email");
  const password = localStorage.getItem("starbuckstravel-password");
  if (
    (emailId == undefined || emailId == null) &&
    (password == undefined || password == null)
  ) {
    location.href = "/starbucks-business-travel-suites";
  }
}

function umpquaBankTravel() {
  const emailId = localStorage.getItem("umpqua-email");
  const password = localStorage.getItem("umpqua-password");
  if (
    (emailId == undefined || emailId == null) &&
    (password == undefined || password == null)
  ) {
    location.href = "/Umpqua-Bank";
  }
}

function clientAltair() {
  const userData = SessionService.SessionGet({ type: "altair" });
  var p1 = Promise.resolve(userData);
  Promise.all([p1]).then((values) => {
    if (values[0].error == 1) {
      const userData = SessionService.SessionDestory({ type: "altair" });
      location.href = "/";
    } else if (values[0].roleId != 4 && values[0].ClientType != "altair") {
      location.href = "/";
    }
  });
}

const history = createBrowserHistory();
export default (
  <Router history={history}>
    <div>
      <Switch>
        <Route exact path="/" component={NewHomepage}></Route>
        <Route exact path="/homepage" component={NewHomepage}></Route>
        <Route exact path="/company-info" component={AboutUs}></Route>
        <Route
          exact
          path="/services/hk/:id"
          component={CancelHouseKeepingRequest}
        ></Route>
        <Route path="/bookit-client-registration" component={BookItRegister} />
        <Route path="/SuiteCare" component={BookItLogin} />
        <Route
          exact
          path="/notice-to-vacate"
          component={NoticeToVacate}
        />
        <Route
          exact
          path="/dashboard"
          render={() => (
            <BookItLayout>
              <Dashboard />
            </BookItLayout>
          )}
          // component={Dashboard}
        ></Route>
         <Route
          exact
          path="/inventory"
          render={() => (
            <BookItLayout>
              <InventorySuiteCare />
            </BookItLayout>
          )}
        ></Route>
         <Route
          exact
          path="/your-team"
          render={() => (
            <BookItLayout>
              <YourTeam />
            </BookItLayout>
          )}
        ></Route>
        <Route
          exact
          path="/booknow-modal"
          render={() => (
            <BookItLayout>
              <BookNow />
            </BookItLayout>
          )}
          // component={BookNow}
        ></Route>
        <Route
          exact
          path="/reserve-now"
          render={() => (
            <Elements stripe={stripePromise}>
              <BookItLayout>
                <ReserveNow />
              </BookItLayout>
            </Elements>
          )}
          // component={ReserveNow}
        ></Route>
        <Route exact path="/reservation-lts" component={Reserved}></Route>

        <Route exact path="/blog" component={Blog}></Route>
        <Route
          exact
          path="/blog/:blogTitle"
          component={IndividualBlogPage}
        ></Route>
        <Route path="/no-match-found" component={NoMatchFound} />
        <Route
          exact
          path="/insurance-landing-Hawaii"
          component={insuarnceLandingHawaii}
        ></Route>
        <Route
          exact
          path="/corporate-housing/:managerName"
          component={Manager}
        ></Route>

        {/* <Route exact path="/corporate-housing/michael" component={Micheal}></Route>
       
        <Route
          exact
          path="/corporate-housing/ericka"
          component={Erica}
        ></Route> */}
        <Route
          exact
          path="/corporatehousing"
          component={CorporateHousing}
        ></Route>
         <Route
          exact
          path="/housekeeping-instructions"
          component={Housingkeeping}
        ></Route>
        <Route exact path="/business-travel" component={BusinessTravel}></Route>
        <Route
          exact
          path="/government-housing"
          component={GovernmentHousingNew}
        ></Route>
        <Route
          exact
          path="/guestExperience"
          component={GuestExperience}
        ></Route>
        <Route exact path="/instant-book" component={NewLocations}></Route>
        <Route
          exact
          path="/instant-booking-details/:masterId"
          component={LocationPropertyDetails}
        ></Route>
        <Route exact path="/internHousing" component={InternHousing}></Route>
        <Route exact path="/city/:state/:city" component={Location}></Route>
        <Route
          exact
          path="/propertyOption"
          component={PropertyOptionnew}
        ></Route>
        <Route exact path="/locations" component={PropertySearchPage}></Route>
        <Route exact path="/technology" component={TechnologyNew}></Route>
        <Route
          exact
          path="/community/:companyname/:name"
          component={PropertyDetailsnew}
        ></Route>
        <Route
          exact
          path="/privacy-policy"
          component={PrivacyPolicyNew}
        ></Route>
        <Route exact path="/contactUs" component={ContactUsNew}></Route>
        <Route
          exact
          path="/leadership-team"
          component={LeadershipPageNew}
        ></Route>
        <Route exact path="/new-brand-identity" component={unFolding}></Route>
        {/* <Route exact path="/serviceExcellence" component={unFolding}></Route> */}
        <Route exact path="/careerpage" component={CareerPage}></Route>
        <Route
          exact
          path="/insurance-landing"
          component={insurancePage}
        ></Route>
        <Route
          exact
          path="/spotlightCities"
          component={SpotlightCities}
        ></Route>
        <Route
          exact
          path="/guest-registration"
          component={NewGuestRegistration}
        ></Route>
        <Route exact path="/timeline" component={Timeline}></Route>
        <Route exact path="/case-study-1" component={CaseStudies1}></Route>
        <Route exact path="/case-study-2" component={CaseStudies2}></Route>
        <Route exact path="/case-study-3" component={CaseStudies3}></Route>
        <Route exact path="/property-owners" component={PropertyOwners}></Route>
        <Route
          exact
          path="/career-opportunities"
          component={newcareerOpportunities}
        ></Route>
        <Route
          exact
          path="/employment-search"
          component={EmploymentSearchs}
        ></Route>
        <Route exact path="/hiro" component={Hiro}></Route>
        {/* <Route exact path="/services_request/:Order:id" component={Services}></Route>
			<Route exact path="/get_services_request" component={guestServiceLink}></Route> */}
        <Route exact path="/services/Order/:id" component={RServices}></Route>
        <Route exact path="/services/Order" component={RServices}></Route>
        <Route
          exact
          path="/get_services_request"
          component={guestServiceLink}
        ></Route>
        {/* Umpqua Bank start */}
        <Route exact path="/Umpqua-Bank" component={UmpquaBank}></Route>
        <Route
          exact
          path="/umpqua-bank-travel"
          component={UmpquaBankLanding}
        ></Route>
        <Route
          exact
          path="/umpqua-bank-adminsuccess"
          component={UmpquaBankSuccessPage}
        ></Route>
        <Route
          exact
          path="/umpqua-bank-guestsuccess"
          component={UmpquaBankSuccessPage}
        ></Route>
        <Route
          exact
          path="/umpqua-bank-quote"
          component={UmpquaBankSuccessPage}
        ></Route>
        <Route
          exact
          path="/umpqua-bank-alreadysent"
          component={UmpquaBankSuccessPage}
        ></Route>
        <Route
          exact
          path="/umpqua-bank-dateexpire"
          component={UmpquaBankSuccessPage}
        ></Route>
        <Route
          exact
          path="/umpqua-bank-travel/:pnr"
          component={UmpquaBankLanding}
        ></Route>
        <Route
          exact
          path="/umpqua-bank-booking-details"
          component={StarbucksBookingDetails}
        ></Route>
        <Route
          exact
          path="/umpqua-bank-admin"
          render={(data) => {
            return umpquaBankTravel() ? (
              <UmpquaBankLanding {...data} />
            ) : (
              <UmpquaBankBookingAdmin {...data} />
            );
          }}
        ></Route>
        <Route
          exact
          path="/umpqua-bank-availability-calendar"
          render={(data) => {
            return umpquaBankTravel() ? (
              <UmpquaBankLanding {...data} />
            ) : (
              <UmpquaBankInventory {...data} />
            );
          }}
        ></Route>
        {/* Umpqua Bank end */}
        {/* {stripeIntern} */}
        <Route
          exact
          path="/company/stripe-internship/questionnaire"
          component={StripeInternshipQuestionnaire}
        />
        <Route
          exact
          path="/company/stripe-internship/home"
          component={StripeInternshipHome}
        />
        <Route
          exact
          path="/company/stripe-internship/communities"
          component={StripeInternshipCommunities}
        />
        <Route
          exact
          path="/company/stripe-internship/contact-us"
          component={StripeInternshipContactUs}
        />
        <Route
          exact
          path="/company/stripe-internship/apartment-tour"
          component={StripeInternshipApartmentTour}
        />
        <Route
          exact
          path="/company/stripe-internship/faq"
          component={StripeInternshipFaq}
        />
        {/* {stripintern end} */}
        {/* gilead-internship start */}
        <Route
          exact
          path="/company/gilead-internship/questionnaire"
          component={GileadInternshipQuestionnaire}
        />
        <Route
          exact
          path="/company/gilead-internship/home"
          component={GileadInternshipHome}
        />
        <Route
          exact
          path="/company/gilead-internship/communities"
          component={GileadInternshipCommunities}
        />
        <Route
          exact
          path="/company/gilead-internship/contact-us"
          component={GileadInternshipContactUs}
        />
        <Route
          exact
          path="/company/gilead-internship/apartment-tour"
          component={GileadInternshipApartmentTour}
        />
        <Route
          exact
          path="/company/gilead-internship/faq"
          component={GileadInternshipFaq}
        />
        {/* gilead-internship end */}
        {/* starbucks-travel start */}
        <Route
          exact
          path="/starbucks-travel"
          component={StarbucksTravelLanding}
        ></Route>
        <Route
          exact
          path="/starbucks-travel-adminsuccess"
          component={StarbucksSuccessPage}
        ></Route>
        <Route
          exact
          path="/starbucks-travel-guestsuccess"
          component={StarbucksSuccessPage}
        ></Route>
        <Route
          exact
          path="/starbucks-travel-quote"
          component={StarbucksSuccessPage}
        ></Route>
        <Route
          exact
          path="/starbucks-travel-alreadysent"
          component={StarbucksSuccessPage}
        ></Route>
        <Route
          exact
          path="/starbucks-travel-dateexpire"
          component={StarbucksSuccessPage}
        ></Route>
        <Route
          exact
          path="/starbucks-travel/:pnr"
          component={StarbucksTravelLanding}
        ></Route>
        <Route
          exact
          path="/starbucks-booking-details"
          component={StarbucksBookingDetails}
        ></Route>
        <Route
          exact
          path="/starbucks-travel-admin"
          render={(data) => {
            return starbucksTravel() ? (
              <StarbucksTravelLanding {...data} />
            ) : (
              <StarbucksBookingAdmin {...data} />
            );
          }}
        ></Route>
        <Route
          exact
          path="/availability-calendar"
          render={(data) => {
            return starbucksTravel() ? (
              <StarbucksTravelLanding {...data} />
            ) : (
              <Inventory {...data} />
            );
          }}
        ></Route>
        <Route
          exact
          path="/starbucks-business-travel-suites"
          component={CartusNolo}
        ></Route>
        {/* starbucks-travel end */}
        {/* Guest portal start */}
        <Route exact path="/guest-login" component={GuestLoginNew}></Route>
        <Route
          exact
          path="/guest-signup/:email/:timeoutkey"
          component={GuestSignupWithMail}
        ></Route>
        <Route
          exact
          path="/reservations"
          render={(data) => {
            return decide() ? (
              <GuestLoginNew {...data} />
            ) : (
              <NewReservations {...data} />
            );
          }}
        ></Route>
        <Route
          exact
          path="/my-reservations"
          render={(data) => {
            return decide() ? (
              <GuestLoginNew {...data} />
            ) : (
              <NewMyReservations {...data} />
            );
          }}
        ></Route>
        <Route
          exact
          path="/moveout"
          render={(data) => {
            return decide() ? (
              <GuestLoginNew {...data} />
            ) : (
              <NewMoveout {...data} />
            );
          }}
        ></Route>
        <Route
          exact
          path="/service-request"
          render={(data) => {
            return decide() ? (
              <GuestLoginNew {...data} />
            ) : (
              <NewGuestService {...data} />
            );
          }}
        ></Route>
        <Route
          exact
          path="/your-location"
          render={(data) => {
            return decide() ? (
              <GuestLoginNew {...data} />
            ) : (
              <NewYourLocation {...data} />
            );
          }}
        ></Route>
        {/* Guest portal end */}
        <Route exact path="/guest-login-demo" component={LoginOld}></Route>
        <Route exact path="/altair-dean" component={AltairDean}></Route>
        <Route exact path="/altair-dean1" component={AltairDean1}></Route>
        <Route exact path="/Byerley" component={Byerley}></Route>
        <Route exact path="/1250-lakeside" component={LakSide}></Route>
        <Route exact path="/docusign" component={Docusign}></Route>
        <Route
          exact
          path="/reserve-your-stay"
          component={ReserveYourStay}
        ></Route>
        <Route exact path="/google-travel" component={GoogleTravel}></Route>
        <Route exact path="/thedean" component={DeanHome}></Route>
        <Route exact path="/mylo" component={DeanHome}></Route>
        <Route exact path="/future" component={Future}></Route>
        <Route exact path="/ds-home" component={dsHome}></Route>
        <Route
          exact
          path="/insurance-landing"
          component={insuranceLanding}
        ></Route>
        <Route exact path="/novo" component={DeanHome}></Route>
        <Route
          exact
          path="/dean-amenities-deleted"
          component={DeanAmenities}
        ></Route>
        <Route exact path="/amberglenylo" component={DeanHome}></Route>
        <Route exact path="/west-parc" component={DeanHome}></Route>
        <Route exact path="/nv-portland" component={DeanHome}></Route>
        <Route exact path="/burlington" component={DeanHome}></Route>
        <Route exact path="/carson" component={DeanHome}></Route>
        <Route exact path="/quotes/:id" component={AutoConfirmMain}></Route>
        <Route exact path="/more-info" component={moreDetails}></Route>
        <Route exact path="/error/:status" component={ServerErrors}></Route>
        <Route
          exact
          path="/guest-prospective/:email/:timoutkey/:guestemail"
          component={GuestProspective}
        ></Route>
        <Route exact path="/bungalow" component={Bungalow}></Route>
        <Route exact path="/dean-parking" component={DeanParking}></Route>
        <Route exact path="/rq_linking" component={OrdersInfoDisplay}></Route>
        <Route exact path="/bechtel" component={BechtelPdf}></Route>
        <Route exact path="/bae-travel" component={BAEPdf}></Route>
        <Route exact path="/ce-package" component={CePackageHome}></Route>
        <Route exact path="/artofwelcome" component={Artofwelcome}></Route>
        <Route exact path="/client-login" component={ClientLogin}></Route>
        <Route
          exact
          path="/ce-reservation-request"
          component={CeReservationRequest}
        ></Route>
        <Route exact path="/gdpr" component={Gdpr}></Route>
        <Route exact path="/celogin" component={CeLogin}></Route>
        <Route exact path="/nikelogin" component={CeLogin}></Route>
        <Route exact path="/mylo-parking" component={MyloParking}></Route>
        <Route exact path="/testimonials" component={Testimonial}></Route>
        <Route
          exact
          path="/covid19-safety-measures"
          component={SafetyMeasures}
        ></Route>
        <Route
          exact
          path="/guest-review/:status/:id"
          component={GuestReview}
        ></Route>
        <Route exact path="/career-experience" component={NikeHome}></Route>
        <Route exact path="/latch" component={LatchHome} />
        <Route exact path="/dean-building-a" component={DeanBuildingA} />
        <Route exact path="/dean-building-b" component={DeanBuildingB} />
        <Route exact path="/dean-building-d" component={DeanBuildingD} />
        <Route exact path="/dean-building-d1" component={DeanBuildingD1} />
        <Route exact path="/dean-building-d1" component={DeanBuildingD1} />
        <Route exact path="/altair-login" component={AltairLogin}></Route>
        <Route
          exact
          path="/altair-dashboard"
          render={(data) => {
            return clientAltair() ? (
              <AltairLogin {...data} />
            ) : (
              <AltairDashboard {...data} />
            );
          }}
        ></Route>
        <Route
          exact
          path="/company/tangerine/home"
          component={TangerineHome}
        ></Route>
        <Route
          exact
          path="/company/tangerine/whats-included"
          component={TangerineWhatsIncluded}
        ></Route>
        <Route
          exact
          path="/company/tangerine/added-value"
          component={TangerineAddedValue}
        ></Route>
        <Route
          exact
          path="/company/tangerine/online-management"
          component={TangerineOnlineManagement}
        ></Route>
        <Route
          exact
          path="/company/tangerine/locations"
          component={TangerineLocations}
        ></Route>
        <Route
          exact
          path="/company/tangerine/reservation-form"
          component={TangerineReservationForm}
        ></Route>
        <Route
          exact
          path="/company/tangerine/frequent-questions"
          component={TangerineFrequentQuestions}
        ></Route>
        {/* <Route exact path="/cedashboard" render={(props) =><ReRoutes componentS={CeDashboard} componentF={CeLogin} props={props} functionType="clientCe" /> }></Route> */}
        <Route
          exact
          path="/cedashboard"
          render={(data) => {
            return clientCe() ? (
              <CeLogin {...data} />
            ) : (
              <CeDashboard {...data} />
            );
          }}
        ></Route>
        <Route exact path="/internlogin" component={InternLogin}></Route>
        <Route
          exact
          path="/interndashboard"
          render={(data) => {
            return clientIntern() ? (
              <InternLogin {...data} />
            ) : (
              <InternDashboard {...data} />
            );
          }}
        ></Route>
        <Route exact path="/lockbox" component={LockBox}></Route>
        {/* <Route exact path="/register.cfm/" component={GuestLogin}></Route> */}
        <Redirect from="/DS-video.cfm" to="/destination-services" push />
        <Route
          exact
          path="/relocation-pricing-request"
          component={RelocationPriceForm}
        ></Route>
        {/* <Route exact path="/locations" component={Locations}></Route> */}
        <Route
          exact
          path="/short-term-corporate-housing/spotlightcities"
          component={SpotLightCitys}
        ></Route>
        <Route
          exact
          path="/virtual/spotlightcities"
          component={virtualSpotLightCitys}
        ></Route>
        {/* <Route exact path="/guest-login" component={Login}></Route> */}
        <Route
          exact
          path="/guest-registration"
          component={GuestRegistrationWithoutMail}
        ></Route>
        <Redirect from="/register.cfm" to="/guest-registration" push />
        {/* <Route exact path="/travel-booking-upload" render={(props) =><ReRoutes componentS={XlsUpload} componentF={TravelBookingLanding} props={props} functionType="travelBooking" /> }></Route> */}
        <Route
          exact
          path="/corporation-corporate-housing"
          component={CorporationCorporateHousing}
        ></Route>
        <Redirect
          from="/corporatehousing.cfm"
          to="/corporation-corporate-housing"
          push
        />
        <Route
          exact
          path="/individual-corporate-housing"
          component={IndividualCorporateHousing}
        ></Route>
        <Route
          exact
          path="/relocation-corporate-housing"
          component={RelocationCorporateHousing}
        ></Route>
        <Route
          exact
          path="/destination-services"
          component={DestinationServices}
        ></Route>
        <Redirect
          from="/Destination-Services.cfm"
          to="/destination-services"
          push
        />
        <Route
          exact
          path="/corporation-destination-services"
          component={CorporationDestinationServices}
        ></Route>
        <Route
          exact
          path="/individual-destination-services"
          component={IndividualDestinationServices}
        ></Route>
        {/* <Route exact path="/technology" component={Technology}></Route> */}
        <Route
          exact
          path="/government-technology"
          component={GovernmentTechnology}
        ></Route>
        <Route
          exact
          path="/individual-technology"
          component={IndividualTechnology}
        ></Route>
        <Route
          exact
          path="/relocation-technology"
          component={RelocationTechnology}
        ></Route>
        {/* <Route exact path="/government-housing" component={GovernmentHousing}></Route> */}
        <Redirect
          from="/GovernmentContracting.cfm"
          to="/government-housing"
          push
        />
        <Route exact path="/global" component={Global}></Route>
        <Route
          exact
          path="/corporation-global"
          component={CorporationGlobal}
        ></Route>
        <Route
          exact
          path="/individual-global"
          component={IndividualGlobal}
        ></Route>
        <Route exact path="/customization" component={Customization}></Route>
        <Route
          exact
          path="/individual-customization"
          component={IndividualCustomization}
        ></Route>
        <Route
          exact
          path="/relocation-customization"
          component={RelocationCustomization}
        ></Route>
        <Route
          exact
          path="/government-customization"
          component={GovernmentCustomization}
        ></Route>
        <Route exact path="/seaved-search" component={SeavedSearch}></Route>
        <Route exact path="/intranet2-login" component={IntranetLogin}></Route>
        {/* <Route exact path="/client-portal"  render={(props) =><ReRoutes componentS={ClientPortal} componentF={LandingVideo} props={props} functionType="_u_web" /> }></Route> */}
        <Route
          exact
          path="/client-portal"
          render={(data) => {
            return client() ? (
              <LandingVideo {...data} />
            ) : (
              <ClientPortal {...data} />
            );
          }}
        ></Route>
        {/* <Route exact path="/company-info" component={CompanyInfo}></Route> */}
        <Redirect from="/AboutUs.cfm" to="/company-info" push />
        <Route
          exact
          path="/housing-solutions"
          component={HousingSolutions}
        ></Route>
        <Route exact path="/leadership-team" component={LeadershipTeam}></Route>
        <Route exact path="/industry-awards" component={IndustryAwards}></Route>
        <Redirect from="/awards.cfm" to="/industry-awards" push />
        <Route exact path="/gsa" component={GSA}></Route>
        <Redirect from="/FederalCivilian.cfm" to="/gsa" push />
        <Route exact path="/stay-with-us" component={StayWithUS}></Route>
        <Route exact path="/press-releases" component={PressReleases}></Route>
        <Route exact path="/media-contact" component={MediaContactUs}></Route>
        <Route exact path="/media-kit" component={MediaKit}></Route>
        <Redirect from="/mediaKit.cfm" to="/media-kit" push />
        <Route exact path="/media-kit-contact" component={MediaContact}></Route>
        <Route
          exact
          path="/media-kit-who-we-are"
          component={MediaWhoWeAre}
        ></Route>
        <Route
          exact
          path="/media-kit-what-we-do"
          component={MediaWhatWeDo}
        ></Route>
        <Route
          exact
          path="/media-kit-our-philosophy"
          component={MediaOurPhilosophy}
        ></Route>
        <Route
          exact
          path="/media-kit-our-mission"
          component={MediaOurMission}
        ></Route>
        <Route
          exact
          path="/media-kit-press-releases"
          component={MediaPressReleases}
        ></Route>
        <Route
          exact
          path="/media-kit-housing-solutions"
          component={MediaHousingSolutions}
        ></Route>
        <Route
          exact
          path="/media-kit-industry-awards"
          component={MediaIndustryAwards}
        ></Route>
        <Route
          exact
          path="/media-kit-leadership-team"
          component={LeadershipPageNew}
        ></Route>
        <Route
          exact
          path="/media-kit-corporate-locations"
          component={MediaCorporateLocations}
        ></Route>
        <Route
          exact
          path="/media-kit-points-of-difference"
          component={MediaPointsOfDifference}
        ></Route>
        <Route
          exact
          path="/media-kit-our-services"
          component={MediaOurServices}
        ></Route>
        {/* <Route exact path="/privacy-policy" component={PrivacyPolicy}></Route> */}
        {/* <Route exact path="/privacy-policy" component={DataPrivacy}></Route> */}
        <Route
          exact
          path="/career-opportunities"
          component={CareerOpportunities}
        ></Route>
        <Redirect from="/CareerOpenings.cfm" to="/career-opportunities" push />
        <Route
          exact
          path="/testimonials-backup"
          component={Testimonials}
        ></Route>
        <Redirect from="/testimonials.cfm" to="/testimonials" push />
        <Route
          exact
          path="/employment-search"
          component={EmploymentSearch}
        ></Route>
        {/* <Route exact path="/travel-booking-admin" render={(props) =><ReRoutes componentS={TravelBookingAdmin} componentF={TravelBookingLanding} props={props} functionType="travelBooking" /> }></Route> */}
        {/* travel booking start */}
        <Route
          exact
          path="/travel-booking-upload"
          render={(data) => {
            return travelBooking() ? (
              <TravelBookingLanding {...data} />
            ) : (
              <XlsUpload {...data} />
            );
          }}
        ></Route>
        <Route
          exact
          path="/travel-booking-admin"
          render={(data) => {
            return travelBooking() ? (
              <TravelBookingLanding {...data} />
            ) : (
              <TravelBookingAdmin {...data} />
            );
          }}
        ></Route>
        <Route
          exact
          path="/travel-booking-adminsuccess"
          component={SuccessPage}
        ></Route>
        <Route
          exact
          path="/travel-booking-guestsuccess"
          component={SuccessPage}
        ></Route>
        <Route
          exact
          path="/travel-booking-quote"
          component={SuccessPage}
        ></Route>
        <Route
          exact
          path="/travel-booking-alreadysent"
          component={SuccessPage}
        ></Route>
        <Route
          exact
          path="/travel-booking-dateexpire"
          component={SuccessPage}
        ></Route>
        <Route
          exact
          path="/travel-booking"
          component={TravelBookingLanding}
        ></Route>
        <Route
          exact
          path="/travel-booking/:pnr"
          component={TravelBookingLanding}
        ></Route>
        {/* travel booking end */}
        <Route exact path="/our-communities" component={OurCommunities}></Route>
        <Route exact path="/booking-details" component={BookingDetails}></Route>
        <Route exact path="/fivestar" component={FiveStarLanding}></Route>
        {/* <Route exact path="/staff" render={(props) =><ReRoutes componentS={Staff} componentF={IntranetLogin} props={props} functionType="intranet" /> }></Route>
			<Route exact path="/intranet-order-process" render={(props) =><ReRoutes componentS={IntranetOrderProcess} componentF={IntranetLogin} props={props} functionType="intranet" /> }></Route>
			<Route exact path="/intranet-form" render={(props) =><ReRoutes componentS={IntranetForm} componentF={IntranetLogin} props={props} functionType="intranet" /> }></Route>
			<Route exact path="/intranet-home" render={(props) =><ReRoutes componentS={IntranetHome} componentF={IntranetLogin} props={props} functionType="intranet" /> }></Route>
			<Route exact path="/intranet-toform" render={(props) =><ReRoutes componentS={ToForm} componentF={IntranetLogin} props={props} functionType="intranet" /> }></Route>
			<Route exact path="/intranet-confirmationpdf" render={(props) =><ReRoutes componentS={ConfirmationPdf} componentF={IntranetLogin} props={props} functionType="intranet" /> }></Route>
			<Route exact path="/intranet-changepassword" render={(props) =><ReRoutes componentS={ChangePassword} componentF={IntranetLogin} props={props} functionType="intranet" /> }></Route>
			 */}
        <Route
          exact
          path="/staff"
          render={(data) => {
            return intranet() ? (
              <IntranetLogin {...data} />
            ) : (
              <Staff {...data} />
            );
          }}
        ></Route>
        <Route
          exact
          path="/intranet-order-process"
          render={(data) => {
            return intranet() ? (
              <IntranetLogin {...data} />
            ) : (
              <IntranetOrderProcess {...data} />
            );
          }}
        ></Route>
        <Route
          exact
          path="/intranet-form"
          render={(data) => {
            return intranet() ? (
              <IntranetLogin {...data} />
            ) : (
              <IntranetForm {...data} />
            );
          }}
        ></Route>
        <Route
          exact
          path="/intranet-home"
          render={(data) => {
            return intranet() ? (
              <IntranetLogin {...data} />
            ) : (
              <IntranetHome {...data} />
            );
          }}
        ></Route>
        <Route
          exact
          path="/intranet-toform"
          render={(data) => {
            return intranet() ? (
              <IntranetLogin {...data} />
            ) : (
              <ToForm {...data} />
            );
          }}
        ></Route>
        <Route
          exact
          path="/intranet-confirmationpdf"
          render={(data) => {
            return intranet() ? (
              <IntranetLogin {...data} />
            ) : (
              <ConfirmationPdf {...data} />
            );
          }}
        ></Route>
        <Route
          exact
          path="/intranet-changepassword"
          render={(data) => {
            return intranet() ? (
              <IntranetLogin {...data} />
            ) : (
              <ChangePassword {...data} />
            );
          }}
        ></Route>
        <Route
          exact
          path="/intranet-forgotpassword"
          component={IntranetForgotPassword}
        ></Route>
        {/* <Route exact path="/guestportal" render={(props) => <ReRoutes componentS={Guestportal} componentF={LandingVideo} props={props} functionType="decide" />}></Route>
			<Route exact path="/reservations" render={(props) => <ReRoutes componentS={GuestOrderData} componentF={LandingVideo} props={props} functionType="decide" />}></Route>
			<Route exact path="/my-reservations" render={(props) => <ReRoutes componentS={MyReservations} componentF={LandingVideo} props={props} functionType="decide" />}></Route>
			<Route exact path="/your-location" render={(props) => <ReRoutes componentS={ExploreYourLocation} componentF={LandingVideo} props={props} functionType="decide" />}></Route>
			<Route exact path="/guest-services" render={(props) => <ReRoutes componentS={ServiceRequest} componentF={LandingVideo} props={props} functionType="decide" />}></Route>
			<Route exact path="/trip-info-data" render={(props) => <ReRoutes componentS={TripInfoData} componentF={LandingVideo} props={props} functionType="decide" />}></Route>
			<Route exact path="/reservation-info" render={(props) => <ReRoutes componentS={ReservationInfo} componentF={LandingVideo} props={props} functionType="decide" />}></Route> */}
        {/* <Route exact path="/guestportal" render={(data) => {
				return decide() ? <LandingVideo {...data} /> : <Guestportal {...data} />;
			}}></Route>
			<Route exact path="/reservations" render={(data) => {
				return decide() ? <LandingVideo {...data} /> : <GuestOrderData {...data} />;
			}}></Route>
			<Route exact path="/my-reservations" render={(data) => {
				return decide() ? <LandingVideo {...data} /> : <MyReservations {...data} />;
			}}></Route>
			<Route exact path="/your-location" render={(data) => {
				return decide() ? <LandingVideo {...data} /> : <ExploreYourLocation {...data} />;
			}}></Route>
			<Route exact path="/guest-services" render={(data) => {
				return decide() ? <LandingVideo {...data} /> : <ServiceRequest {...data} />;
			}}></Route>
			<Route exact path="/trip-info-data" render={(data) => {
				return decide() ? <LandingVideo {...data} /> : <TripInfoData {...data} />;
			}}></Route>
			<Route exact path="/reservation-info" render={(data) => {
				return decide() ? <LandingVideo {...data} /> : <ReservationInfo {...data} />;
			}}></Route> */}
        {/* <Route exact path="/guest-services" render={(data) => {
				return decide() ? <LandingVideo {...data} /> : <GuestServices {...data} />;
			}}></Route> */}
        {/* <Route exact path="/check-in" render={(props) =><ReRoutes componentS={CheckIn} componentF={LandingVideo} props={props} functionType="decide" /> }></Route>
			<Route exact path="/check-out" render={(props) =><ReRoutes componentS={CheckOut} componentF={LandingVideo} props={props} functionType="decide" /> }></Route> */}
        <Route
          exact
          path="/check-in"
          render={(data) => {
            return decide() ? (
              <LandingVideo {...data} />
            ) : (
              <CheckIn {...data} />
            );
          }}
        ></Route>
        <Route
          exact
          path="/check-out"
          render={(data) => {
            return decide() ? (
              <LandingVideo {...data} />
            ) : (
              <CheckOut {...data} />
            );
          }}
        ></Route>
        {/* <Route exact path="/open-request" render={(data) => {
								return decide() ? <LandingVideo {...data}/> : <OpenRequest {...data}/>;
							}}></Route> */}
        {/* <Route exact path="/moving-in" render={(props) =><ReRoutes componentS={MovingIn} componentF={LandingVideo} props={props} functionType="decide" /> }></Route>
			<Route exact path="/moving-out" render={(props) =><ReRoutes componentS={MovingOut} componentF={LandingVideo} props={props} functionType="decide" /> }></Route>
			<Route exact path="/instructions" render={(props) =><ReRoutes componentS={Instructions} componentF={LandingVideo} props={props} functionType="decide" /> }></Route>
			<Route exact path="/custom-amenities" render={(props) =><ReRoutes componentS={CustomAmenities} componentF={LandingVideo} props={props} functionType="decide" /> }></Route> */}
        <Route
          exact
          path="/moving-in"
          render={(data) => {
            return decide() ? (
              <LandingVideo {...data} />
            ) : (
              <MovingIn {...data} />
            );
          }}
        ></Route>
        <Route
          exact
          path="/moving-out"
          render={(data) => {
            return decide() ? (
              <LandingVideo {...data} />
            ) : (
              <MovingOut {...data} />
            );
          }}
        ></Route>
        <Route
          exact
          path="/instructions"
          render={(data) => {
            return decide() ? (
              <LandingVideo {...data} />
            ) : (
              <Instructions {...data} />
            );
          }}
        ></Route>
        <Route
          exact
          path="/custom-amenities"
          render={(data) => {
            return decide() ? (
              <LandingVideo {...data} />
            ) : (
              <CustomAmenities {...data} />
            );
          }}
        ></Route>
        <Route exact path="/communityMapView" component={communityMapView} />
        {/* <Route exact path="/drivers-schedule" render={(props) =><ReRoutes componentS={DriversSchedule} componentF={FiveStarLanding} props={props} functionType="fivestar" /> }></Route>
			<Route exact path="/calender" render={(props) =><ReRoutes componentS={Calender} componentF={FiveStarLanding} props={props} functionType="fivestar" /> }></Route>
			<Route exact path="/diver-schedulemap" render={(props) =><ReRoutes componentS={DiverScheduleMap} componentF={FiveStarLanding} props={props} functionType="fivestar" /> }></Route>
			<Route exact path="/check-list" render={(props) =><ReRoutes componentS={CheckList} componentF={FiveStarLanding} props={props} functionType="fivestar" /> }></Route>
			<Route exact path="/email" render={(props) =><ReRoutes componentS={Email} componentF={FiveStarLanding} props={props} functionType="fivestar" /> }></Route>
			<Route exact path="/vehicle-checklist" render={(props) =><ReRoutes componentS={VehicleCheckList} componentF={FiveStarLanding} props={props} functionType="fivestar" /> }></Route>
			<Route exact path="/occupancy-checker" render={(props) =><ReRoutes componentS={OccupancyChecker} componentF={FiveStarLanding} props={props} functionType="fivestar" /> }></Route>
			<Route exact path="/service-request-form" render={(props) =><ReRoutes componentS={ServiceRequestForm} componentF={FiveStarLanding} props={props} functionType="fivestar" /> }></Route>
			<Route exact path="/schedule-checklist" render={(props) =><ReRoutes componentS={ScheduleCheckList} componentF={FiveStarLanding} props={props} functionType="fivestar" /> }></Route>
			<Route exact path="/keylist" render={(props) =><ReRoutes componentS={FivekeyList} componentF={FiveStarLanding} props={props} functionType="fivestar" /> }></Route>
			<Route exact path="/fivestar-inspectionform" render={(props) =><ReRoutes componentS={InspectionForm} componentF={FiveStarLanding} props={props} functionType="fivestar" /> }></Route> */}
        <Route
          exact
          path="/drivers-schedule"
          render={(data) => {
            return fivestar() ? (
              <FiveStarLanding {...data} />
            ) : (
              <DriversSchedule {...data} />
            );
          }}
        ></Route>
        <Route
          exact
          path="/calender"
          render={(data) => {
            return fivestar() ? (
              <FiveStarLanding {...data} />
            ) : (
              <Calender {...data} />
            );
          }}
        ></Route>
        <Route
          exact
          path="/diver-schedulemap"
          render={(data) => {
            return fivestar() ? (
              <FiveStarLanding {...data} />
            ) : (
              <DiverScheduleMap {...data} />
            );
          }}
        ></Route>
        <Route
          exact
          path="/check-list"
          render={(data) => {
            return fivestar() ? (
              <FiveStarLanding {...data} />
            ) : (
              <CheckList {...data} />
            );
          }}
        ></Route>
        <Route
          exact
          path="/email"
          render={(data) => {
            return fivestar() ? (
              <FiveStarLanding {...data} />
            ) : (
              <Email {...data} />
            );
          }}
        ></Route>
        <Route
          exact
          path="/vehicle-checklist"
          render={(data) => {
            return fivestar() ? (
              <FiveStarLanding {...data} />
            ) : (
              <VehicleCheckList {...data} />
            );
          }}
        ></Route>
        <Route
          exact
          path="/occupancy-checker"
          render={(data) => {
            return fivestar() ? (
              <FiveStarLanding {...data} />
            ) : (
              <OccupancyChecker {...data} />
            );
          }}
        ></Route>
        <Route
          exact
          path="/service-request-form"
          render={(data) => {
            return fivestar() ? (
              <FiveStarLanding {...data} />
            ) : (
              <ServiceRequestForm {...data} />
            );
          }}
        ></Route>
        <Route
          exact
          path="/schedule-checklist"
          render={(data) => {
            return fivestar() ? (
              <FiveStarLanding {...data} />
            ) : (
              <ScheduleCheckList {...data} />
            );
          }}
        ></Route>
        <Route
          path="/keylist"
          render={(data) => {
            return fivestar() ? (
              <FiveStarLanding {...data} />
            ) : (
              <FivekeyList {...data} />
            );
          }}
        ></Route>
        <Route
          path="/fivestar-inspectionform"
          render={(data) => {
            return fivestar() ? (
              <FiveStarLanding {...data} />
            ) : (
              <InspectionForm {...data} />
            );
          }}
        ></Route>
        <Route
          exact
          path="/apple-intern-housing"
          component={InternHousingData}
        />
        <Route
          exact
          path="/3sixty-apple-intern-housing"
          component={ThreeSixtyAppleInternHousing}
        />
        <Route
          exact
          path="/apple-intern-housing-v2"
          component={InternHousingDataV2}
        />
        <Route exact path="/Apple-ce-housing" component={AppleCeHousing} />
        <Route
          exact
          path="/3sixty-apple-intern-housing-v2"
          component={ThreeSixtyAppleInternHousingV2}
        />
        {/* <Route exact path="/order-details" render={(props) =><ReRoutes componentS={OrderDetails} componentF={FiveStarLanding} props={props} functionType="decide"/> }></Route> */}
        <Route
          exact
          path="/order-details"
          render={(data) => {
            return decide() ? (
              <LandingVideo {...data} />
            ) : (
              <OrderDetails {...data} />
            );
          }}
        ></Route>
        <Route exact path="/forgotpassword" component={Forgotpassword}></Route>
        <Route
          exact
          path="/personal-transition-services"
          component={PTSImpression}
        ></Route>
        <Redirect from="/pts.cfm" to="/personal-transition-services" push />
        pts.cfm
        <Route
          exact
          path="/requestReservation"
          component={RequestReservation}
        ></Route>
        <Redirect
          from="/requestReservation.cfm"
          to="/requestReservation"
          push
        />
        <Route exact path="/contactUs" component={ReservationContactUs}></Route>
        <Redirect from="/contact.cfm" to="/contactUs" push />
        <Route
          exact
          path="/sales-managers/h1bvisa"
          component={H1BVisaLanding}
        ></Route>
        <Route
          exact
          path="/sales-managers/robe-campaign/:name"
          component={RobeCampaignLanding}
        ></Route>
        <Redirect
          from="/leslie"
          to="/sales-managers/robe-campaign/leslie"
          push
        />
        <Route
          exact
          path="/communitynewold/:companyname/:name"
          component={newcommunity}
        ></Route>
        <Route
          exact
          path="/oldcommunity/:companyname/:name"
          component={communityLocation}
        ></Route>
        <Route
          exact
          path="/tour-selection-form"
          component={TourSelectionForm}
        ></Route>
        <Route
          exact
          path="/corporate-student-form"
          component={CorporateStudentForm}
        ></Route>
        <Route
          exact
          path="/client-register/"
          component={ClientRegister}
        ></Route>
        <Route
          exact
          path="/guest-register/:email/:guestemail"
          component={GuestProspectiveRegister}
        ></Route>
        <Route
          exact
          path="/guest-forgotpassword/:email/:id/:timeoutkey"
          component={GuestForgotPassword}
        ></Route>
        <Route
          exact
          path="/client-forgotpassword/:email"
          component={clientForgotPassword}
        ></Route>
        <Route
          exact
          path="/intranetforgotpassword"
          component={ForgotpaswordLink}
        ></Route>
        <Route
          exact
          path="/destination-service/itinerary/:companyName/:FileNum/:itineraryId"
          component={DSLanding}
        />
        <Route
          exact
          path="/destination-service/itinerary/property/:companyName/:FileNum/:itineraryId"
          component={PropertyDetails}
        />
        <Route
          exact
          path="/destination-service/itinerary/property/mapview/:companyName/:FileNum/:itineraryId"
          component={PropertyMapView}
        />
        {/* nike pages */}
        <Route exact path="/company/nike/home" component={NikeHome} />
        <Route
          exact
          path="/company/nike/whats-included"
          component={NikeWhatsIncluded}
        />
        <Route
          exact
          path="/company/nike/added-value"
          component={NikeAddedValue}
        />
        <Route
          exact
          path="/company/nike/online-management"
          component={NikeOnlineManagement}
        />
        <Route exact path="/company/nike/locations" component={NikeLocations} />
        <Route
          exact
          path="/company/nike/reservation-form"
          component={NikeReservationForm}
        />
        <Route
          exact
          path="/company/nike/frequent-questions"
          component={NikeFrequentQuestions}
        />
        {/* tesla pages */}
        <Route exact path="/company/tesla/home" component={TeslaHome} />
        <Route
          exact
          path="/company/tesla/whats-included"
          component={TeslaWhatsIncluded}
        />
        <Route
          exact
          path="/company/tesla/added-value"
          component={TeslaAddedValue}
        />
        <Route
          exact
          path="/company/tesla/online-management"
          component={TeslaOnlineManagement}
        />
        <Route
          exact
          path="/company/tesla/locations"
          component={TeslaLocations}
        />
        <Route
          exact
          path="/company/tesla/reservation-form"
          component={TeslaReservationForm}
        />
        <Route
          exact
          path="/company/tesla/frequent-questions"
          component={TeslaFrequentQuestions}
        />
        {/* pivot pages */}
        <Route exact path="/company/Pivot-agency/home" component={PivotHome} />
        <Route
          exact
          path="/company/Pivot-agency/whats-included"
          component={PivotWhatsIncluded}
        />
        {/* <Route exact path="/company/Pivot-agency/added-value" component={PivotAddedValue} />
				<Route exact path="/company/Pivot-agency/online-management" component={PivotOnlineManagement} /> */}
        <Route
          exact
          path="/company/Pivot-agency/locations"
          component={PivotLocations}
        />
        <Route
          exact
          path="/company/Pivot-agency/reservation-form"
          component={PivotReservationForm}
        />
        <Route
          exact
          path="/company/Pivot-agency/frequent-questions"
          component={PivotFrequentQuestions}
        />
{/* Intutive SurgicalInters */}
<ProtectedRoute
          exact
          path="/company/IntuitiveSurgical-interns/home"
          component={IntutiveSurgicalInternsHome}
        />
        <ProtectedRoute
          exact
          path="/company/IntuitiveSurgical-interns/about-suite-america"
          component={IntutiveSurgicalInternsAboutSuiteAmerica}
        />
        <ProtectedRoute
          exact
          path="/company/IntuitiveSurgical-interns/how-it-works"
          component={IntutiveSurgicalInternsHowItWorks}
        />
        <ProtectedRoute
          exact
          path="/company/IntuitiveSurgical-interns/FAQs"
          component={IntutiveSurgicalInternsFAQs}
        />
        <ProtectedRoute
          exact
          path="/company/IntuitiveSurgical-interns/locations"
          component={IntutiveSurgicalInternsLocations}
        />
        <ProtectedRoute
          exact
          path="/company/IntuitiveSurgical-interns/reservation-form"
          component={IntutiveSurgicalInternsReservationForm}
        />
        <ProtectedRoute
          exact
          path="/company/IntuitiveSurgical-interns/whats-included"
          component={IntutiveSurgicalInternsWhatsIncluded}
        />
        <ProtectedRoute
          exact
          path="/company/IntutiveSurgical-interns/home"
          component={IntutiveSurgicalInternsHome}
        />
        <ProtectedRoute
          exact
          path="/company/IntutiveSurgical-interns/about-suite-america"
          component={IntutiveSurgicalInternsAboutSuiteAmerica}
        />
        <ProtectedRoute
          exact
          path="/company/IntutiveSurgical-interns/how-it-works"
          component={IntutiveSurgicalInternsHowItWorks}
        />
        <ProtectedRoute
          exact
          path="/company/IntutiveSurgical-interns/FAQs"
          component={IntutiveSurgicalInternsFAQs}
        />
        <ProtectedRoute
          exact
          path="/company/IntutiveSurgical-interns/locations"
          component={IntutiveSurgicalInternsLocations}
        />
        <ProtectedRoute
          exact
          path="/company/IntutiveSurgical-interns/reservation-form"
          component={IntutiveSurgicalInternsReservationForm}
        />
        <ProtectedRoute
          exact
          path="/company/IntutiveSurgical-interns/whats-included"
          component={IntutiveSurgicalInternsWhatsIncluded}
        />
        {/* ------------------------- */}
{/* Linkedin interns */}
<ProtectedRoute
          exact
          path="/company/LinkedIn-interns/home"
          component={LinkedinInternsHome}
        />
        <ProtectedRoute
          exact
          path="/company/LinkedIn-interns/about-suite-america"
          component={LinkedinInternsAboutSuiteAmerica}
        />
        <ProtectedRoute
          exact
          path="/company/LinkedIn-interns/how-it-works"
          component={LinkedinInternsHowItWorks}
        />
        <ProtectedRoute
          exact
          path="/company/LinkedIn-interns/FAQs"
          component={LinkedinInternsFAQs}
        />
        <ProtectedRoute
          exact
          path="/company/LinkedIn-interns/locations"
          component={LinkedinInternsLocations}
        />
        <ProtectedRoute
          exact
          path="/company/LinkedIn-interns/reservation-form"
          component={LinkedinInternsReservationForm}
        />
        <ProtectedRoute
          exact
          path="/company/LinkedIn-interns/whats-included"
          component={LinkedinInternsWhatsIncluded}
        />
        {/* ----------------------------- */}

{/* Wellsfargo_Interns Pages*/}
<ProtectedRoute
          exact
          path="/company/Wellsfargo-interns/home"
          component={WellsfargoInternsHome}
        />
        <ProtectedRoute
          exact
          path="/company/Wellsfargo-interns/about-suite-america"
          component={WellsfargoInternsAboutSuiteAmerica}
        />
        <ProtectedRoute
          exact
          path="/company/Wellsfargo-interns/how-it-works"
          component={WellsfargoInternsHowItWorks}
        />
        <ProtectedRoute
          exact
          path="/company/Wellsfargo-interns/FAQs"
          component={WellsfargoInternsFAQs}
        />
        <ProtectedRoute
          exact
          path="/company/Wellsfargo-interns/locations"
          component={WellsfargoInternsLocations}
        />
        <ProtectedRoute
          exact
          path="/company/Wellsfargo-interns/reservation-form"
          component={WellsfargoInternsReservationForm}
        />
        <ProtectedRoute
          exact
          path="/company/Wellsfargo-interns/whats-included"
          component={WellsfargoInternsWhatsIncluded}
        />
        {/* ----------------------- */}

        {/* abc pages */}
        <Route exact path="/company/abc/home" component={AbcHome} />
        <Route
          exact
          path="/company/abc/whats-included"
          component={AbcWhatsIncluded}
        />
        <Route
          exact
          path="/company/abc/added-value"
          component={AbcAddedValue}
        />
        <Route
          exact
          path="/company/abc/online-management"
          component={AbcOnlineManagement}
        />
        <Route exact path="/company/abc/locations" component={AbcLocations} />
        <Route
          exact
          path="/company/abc/reservation-form"
          component={AbcReservationForm}
        />
        <Route
          exact
          path="/company/abc/frequent-questions"
          component={AbcFrequentQuestions}
        />
        <Route
          exact
          path="/company/:companyName/company_booking_orderFailed"
          component={BookingRedirctLinks}
        />
        <Route
          exact
          path="/company/:companyName/company_booking_quote"
          component={BookingRedirctLinks}
        />
        <Route
          exact
          path="/company/:companyName/company_booking_guestsuccess"
          component={BookingRedirctLinks}
        />
        {/* lumentum pages */}
        <Route exact path="/company/lumentum/home" component={LumentumHome} />
        <Route
          exact
          path="/company/lumentum/whats-included"
          component={LumentumWhatsIncluded}
        />
        <Route
          exact
          path="/company/lumentum/added-value"
          component={LumentumAddedValue}
        />
        <Route
          exact
          path="/company/lumentum/online-management"
          component={LumentumOnlineManagement}
        />
        <Route
          exact
          path="/company/lumentum/locations"
          component={LumentumLocations}
        />
        <Route
          exact
          path="/company/lumentum/reservation-form"
          component={LumentumReservationForm}
        />
        {/* adobe pages */}
        <Route exact path="/company/adobe/home" component={AdobeHome} />
        <Route
          exact
          path="/company/adobe/whats-included"
          component={AdobeWhatsIncluded}
        />
        <Route
          exact
          path="/company/adobe/added-value"
          component={AdobeAddedValue}
        />
        <Route
          exact
          path="/company/adobe/online-management"
          component={AdobeOnlineManagement}
        />
        <Route
          exact
          path="/company/adobe/locations"
          component={AdobeLocations}
        />
        <Route
          exact
          path="/company/adobe/reservation-form"
          component={AdobeReservationForm}
        />
        {/* Nvidia */}
        <Route
          exact
          path="/company/nvidia/home"
          component={NvidiaInternshipHome}
        />
        <Route
          exact
          path="/company/nvidia/communities"
          component={NvidiaInternshipCommunities}
        />
        <Route
          exact
          path="/company/nvidia/apartment-tour"
          component={NvidiaInternshipApartmentTour}
        />
        <Route
          exact
          path="/company/nvidia/questionnaire"
          component={NvidiaInternshipQuestionnaire}
        />
        <Route
          exact
          path="/company/nvidia/contact-us"
          component={NvidiaInternshipContactUs}
        />
        <Route
          exact
          path="/company/nvidia/faq"
          component={NvidiaInternshipFAQ}
        />
        {/** Apple-Internship */}
        <Route
          exact
          path="/company/Apple-interns/home"
          component={AppleInternHome}
        />
        <Route
          exact
          path="/company/Apple-interns/communities"
          component={AppleInternshipCommunities}
        />
        <Route
          exact
          path="/company/Apple-interns/apartment-tour"
          component={AppleInternshipApartmentTour}
        />
        <Route
          exact
          path="/company/Apple-interns/questionnaire"
          component={AppleInternshipQuestionnaire}
        />
        <Route
          exact
          path="/company/Apple-interns/contact-us"
          component={AppleInternshipContactUs}
        />
        <Route
          exact
          path="/company/Apple-interns/faq"
          component={AppleInternshipFAQ}
        />

        {/* Nike-Internship */}
        <Route
          exact
          path="/company/nike-internship/home"
          component={NikeInternshipHome}
        />
        <Route
          exact
          path="/company/nike-internship/communities"
          component={NikeInternshipCommunities}
        />
        <Route
          exact
          path="/company/nike-internship/apartment-tour"
          component={NikeInternshipApartmentTour}
        />
        <Route
          exact
          path="/company/nike-internship/questionnaire"
          component={NikeInternshipQuestionnaire}
        />
        <Route
          exact
          path="/company/nike-internship/contact-us"
          component={NikeInternshipContactUs}
        />
        <Route
          exact
          path="/company/nike-internship/faq"
          component={NikeInternshipFAQ}
        />
        <Route
          path="/corporate-student-program"
          component={CorporateStudentProgram}
          push
        />
        {/* Adidas */}
        {/* <Route
          exact
          path="/company/adidas-internship/home"
          component={AdidasHome}
        />
        <Route
          exact
          path="/company/adidas-internship/communities"
          component={AdidasCommunities}
        />
        <Route
          exact
          path="/company/adidas-internship/apartment-tour"
          component={AdidasApartmentTour}
        />
        <Route
          exact
          path="/company/adidas-internship/questionnaire"
          component={AdidasQuestionnaire}
        />
        <Route
          exact
          path="/company/adidas-internship/contact-us"
          component={AdidasContactUs}
        />
        <Route
          exact
          path="/company/adidas-internship/faq"
          component={AdidasFAQ}
        /> */}
        {/* Nike-Apprenticeship */}
        <Route
          exact
          path="/company/nike-apprenticeship/home"
          component={ApprenticeshipHome}
        />
        <Route
          exact
          path="/company/nike-apprenticeship/communities"
          component={ApprenticeshipCommunities}
        />
        <Route
          exact
          path="/company/nike-apprenticeship/apartment-tour"
          component={ApprenticeshipApartmentTour}
        />
        <Route
          exact
          path="/company/nike-apprenticeship/questionnaire"
          component={ApprenticeshipQuestionnaire}
        />
        <Route
          exact
          path="/company/nike-apprenticeship/contact-us"
          component={ApprenticeshipContactUs}
        />
        <Route
          exact
          path="/company/nike-apprenticeship/faq"
          component={ApprenticeshipFAQ}
        />
        {/* servicenow 
        <Route
          exact
          path="/company/servicenow-intern/home"
          component={ServiceNowInternHome}
        />
        <Route
          exact
          path="/company/servicenow-intern/communities"
          component={ServiceNowInternCommunities}
        />
        <Route
          exact
          path="/company/servicenow-intern/apartment-tour"
          component={ServiceNowInternApartmentTour}
        />
        <Route
          exact
          path="/company/servicenow-intern/questionnaire"
          component={ServiceNowInternQuestionnaire}
        />
        <Route
          exact
          path="/company/servicenow-intern/contact-us"
          component={ServiceNowInternContactUs}
        />
        <Route
          exact
          path="/company/servicenow-intern/faq"
          component={ServiceNowInternFAQ}
        />*/}
        {/* RiotGames */}
        <Route
          exact
          path="/company/riotgames-intern/home"
          component={RiotGamesHome}
        />
        <Route
          exact
          path="/company/riotgames-intern/communities"
          component={RiotGamesCommunities}
        />
        <Route
          exact
          path="/company/riotgames-intern/apartment-tour"
          component={RiotGamesApartmentTour}
        />
        <Route
          exact
          path="/company/riotgames-intern/questionnaire"
          component={RiotGamesQuestionnaire}
        />
        <Route
          exact
          path="/company/riotgames-intern/contact-us"
          component={RiotGamesContactUs}
        />
        <Route
          exact
          path="/company/riotgames-intern/faq"
          component={RiotGamesFAQ}
        />
        {/* Lockheed Martin */}
        <Route
          exact
          path="/company/lockheed-martin/home"
          component={LockheedHome}
        />
        <Route
          exact
          path="/company/lockheed-martin/whats-included"
          component={LockheedWhatsIncluded}
        />
        <Route
          exact
          path="/company/lockheed-martin/added-value"
          component={LockheedAddedValue}
        />
        <Route
          exact
          path="/company/lockheed-martin/online-management"
          component={LockheedOnlineManagement}
        />
        <Route
          exact
          path="/company/lockheed-martin/locations"
          component={LockheedLocations}
        />
        <Route
          exact
          path="/company/lockheed-martin/reservation-form"
          component={LockheedReservationForm}
        />
        <Route
          exact
          path="/company/lockheed-martin/frequent-questions"
          component={LockheedFrequentQuestions}
        />
        {/* World Bank */}
        <Route exact path="/world-bank/home" component={WorldBankHome} />
        <Route
          exact
          path="/world-bank/added-value"
          component={WorldBankAddedValue}
        />
        <Route
          exact
          path="/world-bank/destination-services"
          component={WorldBankDestinationServices}
        />
        <Route
          exact
          path="/world-bank/locations"
          component={WorldBankLocations}
        />
        <Route
          exact
          path="/world-bank/online-management"
          component={WorldBankOnlineManagement}
        />
        <Route
          exact
          path="/world-bank/transportation"
          component={WorldBankTransportation}
        />
        <Route
          exact
          path="/world-bank/whats-included"
          component={WorldBankWhatsIncluded}
        />
        <Route
          exact
          path="/world-bank/requestReservation"
          component={WorldBankRequestReservation}
        />
        <Route
          exact
          path="/world-bank/contact-us"
          component={WorldBankContactUs}
        />
        <Redirect
          from="/company/nike-internship/index.cfm"
          to="/company/nike-internship/home"
          push
        />
        <Redirect from="/csp2018.cfm" to="/corporate-student-program" push />
        <Route
          exact
          path="/corporate-housing/furnished-apartments/:state/:city"
          component={Location}
        ></Route>
        {/* <Route
          exact
          path="/corporate-housing/furnished-apartments/:state/:city"
          component={CityHomePage}
        ></Route> */}
        <Route
          exact
          path="/corporate-housing/furnished-apartments/:area"
          component={Locations}
        ></Route>
        <Route
          exact
          path="/ambassador-package"
          component={AmbassadorPackagePdf}
        ></Route>
        <Route
          exact
          path="/grocery-package"
          component={GroceryPackagePdf}
        ></Route>
        <Route
          exact
          path="/business-furnishing"
          component={BusinessFurnishingPdf}
        ></Route>
        {/* Redirect Url Start*/}
        {/* 1*/}
        <Redirect from="/WalkScore.cfm" to="/locations" push />
        {/* <Redirect from="/WalkScore.cfm?CID=18642" to="/locations" push />
						<Redirect from="/WalkScore.cfm?CID=17018" to="/locations" push />
						<Redirect from="/WalkScore.cfm?CID=16724" to="/locations" push />
						<Redirect from="/WalkScore.cfm?CID=15761" to="/locations" push />
						<Redirect from="/WalkScore.cfm?CID=15386" to="/locations" push />
						<Redirect from="/WalkScore.cfm?CID=15363" to="/locations" push />
						<Redirect from="/WalkScore.cfm?CID=14543" to="/locations" push />
						<Redirect from="/WalkScore.cfm?CID=12279" to="/locations" push /> */}
        <Redirect
          from="/slideshow-Virginia-Square-Business-Option-Two.cfm"
          to="community/suiteamerica/VIRGINIA-SQUARE"
          push
        />
        <Redirect
          from="/slideshow-The-Residences-at-Springfield-Station-I.cfm"
          to="/community/suiteamerica/RESIDENCES-AT-SPRINGFIELD-STAT"
          push
        />
        <Redirect
          from="/slideshow-The-Residences-at-Springfield-Station-Business-Option-Two.cfm"
          to="/community/suiteamerica/RESIDENCES-AT-SPRINGFIELD-STAT"
          push
        />
        <Redirect
          from="/slideshow-The-Lofts-At-Park-Crest-Business.cfm"
          to="/community/suiteamerica/THE-LOFTS-AT-PARK-CREST"
          push
        />
        <Redirect
          from="/slideshow-The-Grove-Apartments-Business-1005.cfm"
          to="/community/suiteamerica/THE-GROVE"
          push
        />
        <Redirect
          from="/slideshow-Tessera-Orenco-Station-Ambassador.cfm"
          to="/community/suiteamerica/TESSERA-ORENCO-STATION"
          push
        />
        <Redirect
          from="/slideshow-Santana-Row-Ambassador.cfm"
          to="/locations"
          push
        />
        <Redirect
          from="/slideshows/Madera-Ambassador/"
          to="/community/suiteamerica/MADERA"
          push
        />
        <Redirect
          from="/Slideshows/locations/Virginia/BLVD-Reston-Station/"
          to="/community/suiteamerica/BLVD-RESTON-STATION"
          push
        />
        <Redirect
          from="/Slideshows/locations/South-Bay/Kensington-Place/"
          to="/community/suiteamerica/KENSINGTON-PLACE-APARTMENTS"
          push
        />
        <Redirect
          from="/Slideshows/locations/South-Bay/Avalon-Towers-on-the-Peninsula/"
          to="/community/suiteamerica/AVALON-TOWERS-BELLEVUE"
          push
        />
        <Redirect
          from="/slideshows/Avalon-Park-Crest-Business-Option-Two/"
          to="/community/suiteamerica/AVALON-PARK-CREST"
          push
        />
        <Redirect
          from="/slideshows/Avalon-Mission-Bay-Phase-III-Ambassador-1413/"
          to="/community/suiteamerica/AVALON-MISSION-BAY-PHASE-III"
          push
        />
        <Redirect
          from="/slideshow-Revere-Ambassador-412.cfm"
          to="/community/suiteamerica/REVERE"
          push
        />
        <Redirect
          from="/slideshow-Regents-La-Jolla-Ambassador.cfm"
          to="/community/suiteamerica/REGENTS-COURT"
          push
        />
        <Redirect
          from="/slideshow-Park-Place-at-San-Mateo-Business-Option-Two.cfm"
          to="/community/suiteamerica/PARK-PLACE-AT-SAN-MATEO"
          push
        />
        <Redirect
          from="/slideshow-Park-Place-at-San-Mateo-Ambassador-130.cfm"
          to="/community/suiteamerica/PARK-PLACE-AT-SAN-MATEO"
          push
        />
        <Redirect
          from="/slideshow-NV-Portland-Business-1406.cfm"
          to="/community/suiteamerica/NV-PORTLAND"
          push
        />
        <Redirect
          from="/slideshow-NV-Portland-Business-1005.cfm."
          to="/community/suiteamerica/NV-PORTLAND"
          push
        />
        <Redirect
          from="/slideshow-Miramar-Ambassador.cfm"
          to="/community/suiteamerica/MIRAMAR"
          push
        />
        <Redirect
          from="/slideshow-Meridian-at-Ballston-Commons-Business-Option-Two.cfm"
          to="/community/suiteamerica/MERIDIAN-AT-BALLSTON"
          push
        />
        <Redirect
          from="/slideshow-McHenry-Row-Business.cfm"
          to="/community/suiteamerica/MCHENRY-ROW"
          push
        />
        <Redirect
          from="/slideshow-Kensington-Place-Ambassador-230.cfm"
          to="/community/suiteamerica/KENSINGTON-PLACE-APARTMENTS"
          push
        />
        <Redirect
          from="/slideshow-Fountains-at-Emerald-Park-Business-101.cfm"
          to="/community/suiteamerica/FOUNTAINS-AT-EMERALD-PARK"
          push
        />
        <Redirect
          from="/slideshow-Epicenter-Apartments-Ambassador-209.cfm"
          to="/community/suiteamerica/EPICENTER-APARTMENTS"
          push
        />
        <Redirect
          from="/Slideshow-Cobalt-Apartments-Business.cfm"
          to="/community/suiteamerica/COBALT-APARTMENTS"
          push
        />
        <Redirect
          from="/slideshow-Channel-Mission-Bay-Ambassador-Open-House.cfm"
          to="/community/suiteamerica/CHANNEL-MISSION-BAY"
          push
        />
        <Redirect
          from="/slideshow-Biltmore-North-Business-Ambassador-309.cfm"
          to="/community/suiteamerica/BILTMORE-NORTH"
          push
        />
        <Redirect
          from="/slideshow-Aventino-Ambassador.cfm"
          to="/locations"
          push
        />
        <Redirect
          from="/slideshow-Avalon-Walnut-Creek-Business.cfm"
          to="/community/suiteamerica/AVALON-WALNUT-RIDGE"
          push
        />
        <Redirect
          from="/slideshow-Avalon-Park-Crest-Business-Option-Two.cfm"
          to="/community/suiteamerica/AVALON-PARK-CREST"
          push
        />
        <Redirect
          from="/slideshow-Avalon-Mission-Bay-Phase-III-Ambassador-1211.cfm"
          to="/community/suiteamerica/AVALON-MISSION-BAY-PHASE-III"
          push
        />
        <Redirect
          from="/slideshow-Avalon-Mission-Bay-Phase-I-Ambassador-1806.cfm"
          to="/community/suiteamerica/AVALON-MISSION-BAY-PHASE-I"
          push
        />
        <Redirect
          from="/slideshow-Avalon-Mission-Bay-Phase-I-Ambassador-1004-Option-Two.cfm"
          to="/community/suiteamerica/AVALON-MISSION-BAY-PHASE-I"
          push
        />
        <Redirect
          from="/slideshow-Avalon-Meydenbauer-Ambassador.cfm"
          to="/community/suiteamerica/AVALON-MEYDENBAUER"
          push
        />
        <Redirect
          from="/slideshow-Atherton-House-VIP.cfm"
          to="/locations"
          push
        />
        <Redirect
          from="/slideshow-Apex-Apartments-Business.cfm"
          to="/community/suiteamerica/APEX"
          push
        />
        <Redirect
          from="/slideshow-AMLI-Downtown-Business.cfm"
          to="/community/suiteamerica/AMLI-ON-2ND"
          push
        />
        <Redirect
          from="/pdfs%5Camenitysheets%5Clow%20res%20amenity%20sheets%5CTimberlakes%20at%20Red%20Bridge_low-res%20(14651).pdf"
          to="/locations"
          push
        />
        <Redirect
          from="/pdfs%5Camenitysheets%5Clow%20res%20amenity%20sheets%5CThe%20Crest%20at%20Congressional%20Plaza_%20low-res%20(12534).pdf"
          to="/locations"
          push
        />
        <Redirect
          from="/pdfs%5Camenitysheets%5Clow%20res%20amenity%20sheets%5CPinnacle%20at%20Sonata_low%20res%20(11973).pdf"
          to="/locations"
          push
        />
        <Redirect from="/oracle.cfm" to="/locations" push />
        {/* <Redirect from="/oracle.cfm?ST=WA" to="/locations" push />
						<Redirect from="/oracle.cfm?ST=OR" to="/locations" push /> */}
        <Redirect from="/online-booking/index.cfm" to="/" push />
        <Redirect from="/Navy-Housing.cfm" to="/locations" push />
        <Redirect from="/military.cfm" to="/government-housing" push />
        <Redirect from="/login.cfm" to="/locations" push />
        <Redirect
          from="/images/mediakit/pdfs/Sample-Amenity-Sheet.pdf"
          to="/locations"
          push
        />
        {/*issue*/}
        <Redirect
          from="/images/mediaKit/pdfs/Press%20Release%20-%20Dundas.pdf"
          to="/locations"
          push
        />
        <Redirect from="/furniture.cfm" to="/" push />
        <Redirect
          from="/Deal-of-the-Day/Silicon-Valley.cfm"
          to="/locations"
          push
        />
        <Redirect from="/complex_area.cfm" to="/locations" push />
        {/* <Redirect from="/complex_area.cfm?Zone=Utah&cst=UT" to="/locations" push />
						<Redirect from="/complex_area.cfm?Zone=Nebraska&cst=NE" to="/locations" push />
						<Redirect from="/complex_area.cfm?Zone=Arkansas&cst=AR" to="/locations" push />
						<Redirect from="/complex_area.cfm?Zone=&cst=AZ" to="/locations" push />
						<Redirect from="/complex_area.cfm?CST=OR&city=Beaverton&titleText=short%20term%20housing" to="/locations" push />
						<Redirect from="/complex_area.cfm?CST=CA" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Worcester?CST=MA&zone=Massachusetts&area=Massachusetts" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Woodstock&CST=GA&zone=&area=Georgia" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?City=Woodland%20Hills&CST=&zone=Southern%20Cal&area=Los%20Angeles"
          to="/corporate-housing/furnished-apartments/ca/losangeles"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=Woodbury?CST=&zone=&area=Minnesota" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Winter%20Haven&CST=FL&zone=Florida&area=" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?City=Woodland%20Hills&CST=&zone=Southern%20Cal&area=Los%20Angeles"
          to="/corporate-housing/furnished-apartments/ca/losangeles"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=Woodbury?CST=&zone=&area=Minnesota" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Winter%20Haven&CST=FL&zone=Florida&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Winston-Salem&CST=NC&zone=&area=North%20Carolina" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Wilmington?CST=NC&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Wilmington&CST=&zone=&area=North%20Carolina" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Williamsburg?CST=VA&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=White%20Plains&CST=NY&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=White%20Marsh&CST=MD&zone=DC%20Metro%20Area&area=Maryland" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Weston?CST=&zone=Florida&area=Florida" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Weston?CST=&zone=&area=Florida" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Weston&CST=FL&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=West%20Monroe?CST=LA&zone=Louisiana&area=Louisiana" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?city=West%20Los%20Angeles?CST=&zone=Southern%20Cal&area="
          to="/corporate-housing/furnished-apartments/ca/losangeles"
          push
        />
        {/* <Redirect from="/complex_area.cfm?City=West%20Linn&CST=OR&zone=Oregon&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=West%20Linn&CST=OR&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=West%20Chester?CST=OH&zone=Ohio&area=Ohio" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Weehawken&CST=NJ&zone=&area=New%20Jersey" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Weehawken&CST=&zone=New%20Jersey&area=New%20Jersey" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?City=Wappingers%20Falls&CST=&zone=New%20York&area=New%20York"
          to="/corporate-housing/furnished-apartments/ny/newyork"
          push
        />
        {/* <Redirect from="/complex_area.cfm?City=Waltham&CST=MA&zone=&area=Massachusetts" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Waltham&CST=MA&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Waldorf?CST=MD&zone=&area=Maryland" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Waldorf&CST=MD&zone=DC%20Metro%20Area&area=Maryland" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Waldorf&CST=&zone=&area=Maryland" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Vista?CST=CA&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Virginia%20Beach?CST=&zone=Richmond&area=Richmond" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Virginia%20Beach&CST=VA&titleText=Furnished%20Apartments" to="/locations" push /> */}
        {/* 100 */}
        {/* <Redirect from="/complex_area.cfm?city=Villa%20Rica?CST=GA&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Vienna&CST=VA&zone=&area=" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?City=Vienna&CST=UK&zone=DC%20Metro%20Area&area=Tysons/Fairfax"
          to="/corporate-housing/furnished-apartments/va/fairfax"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=Vernon%20Hills?CST=IL&zone=Illinois&area=Illinois" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Vernon%20Hills?CST=IL&zone=Illinois&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Vernon%20Hills&CST=IL&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Vancouver?CST=WA&zone=&area=" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?city=TYSONS?CST=VA&zone=DC%20Metro%20Area&area=Tysons/Fairfax"
          to="/corporate-housing/furnished-apartments/va/fairfax"
          push
        />
        <Redirect
          from="/complex_area.cfm?City=TYSONS&CST=VA&zone=&area="
          to="/corporate-housing/furnished-apartments/va/tysons"
          push
        />
        {/* <Redirect from="/complex_area.cfm?City=Tucson&CST=&zone=&area=Arizona" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Towson?CST=UK&zone=DC%20Metro%20Area&area=Maryland" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Towson&CST=UK&zone=&area=Maryland" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Towson&CST=MD&zone=DC%20Metro%20Area&area=Maryland" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Tewksbury&CST=MA&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Tempe&CST=&zone=Arizona&area=Arizona" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Tacoma?CST=WA&zone=&area=Washington" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Sunrise?CST=FL&zone=Florida&area=Florida" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?City=Sunnyvale&CST=CA&zone=SF%20Bay%20Area&area="
          to="/corporate-housing/furnished-apartments/bayarea"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=Sugar%20Land?CST=&zone=Austin&area=Texas"
          to="/corporate-housing/furnished-apartments/texas/austin"
          push
        />
        <Redirect
          from="/complex_area.cfm?City=Sugar%20Land&CST=TX&zone=Austin&area=Texas"
          to="/corporate-housing/furnished-apartments/texas/austin"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=Studio%20City?CST=CA&zone=Southern%20Cal&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Studio%20City&sortOrder=city" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Strongsville?CST=OH&zone=Ohio&area=Ohio" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Strongsville?CST=OH&zone=&area=Ohio" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?City=Sterling&CST=&zone=DC%20Metro%20Area&area=Herndon/Reston"
          to="/corporate-housing/furnished-apartments/va/herndon"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=St.%20Petersburg?CST=&zone=&area=Florida" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=St.%20Paul?CST=MN&zone=Minnesota&area=Minnesota" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=St.%20Paul?CST=&zone=Minnesota&area=Minnesota" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=St.%20Louis?CST=&zone=Missouri&area=Missouri" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?city=Springfield?CST=&zone=DC%20Metro%20Area&area=Arlington/Alexandria"
          to="/corporate-housing/furnished-apartments/va/arlington"
          push
        />
        <Redirect
          from="/complex_area.cfm?City=Springfield&CST=DC&zone=DC%20Metro%20Area&area=Arlington/Alexandria"
          to="/corporate-housing/furnished-apartments/va/arlington"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=Spring?CST=TX&zone=Austin&area=Texas"
          to="/corporate-housing/furnished-apartments/texas/austin"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=Spring?CST=&zone=&area=Texas" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=South%20Jordan?CST=UT&zone=Utah&area=Utah" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=South%20Jordan?CST=&zone=&area=Utah" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?city=Silver%20Spring?CST=UK&zone=DC%20Metro%20Area&area=Maryland"
          to="/corporate-housing/furnished-apartments/md/silverspring"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=Silver%20Spring?CST=&zone=DC%20Metro%20Area&area=Maryland"
          to="/corporate-housing/furnished-apartments/md/silverspring"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=Silver%20Spring&titleText=furnished%20apartments"
          to="/corporate-housing/furnished-apartments/md/silverspring"
          push
        />
        <Redirect
          from="/complex_area.cfm?City=Silver%20Spring&CST=MD&zone=&area="
          to="/corporate-housing/furnished-apartments/md/silverspring"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=Sherman?CST=&zone=&area=Texas" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Sheboygan?CST=WI&zone=Wisconsin&area=Wisconsin" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Sharon&CST=MA&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Seal%20Beach&CST=CA&zone=Other%20CA%20Cities&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Scottsdale&CST=&zone=&area=Arizona" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Schaumburg?CST=IL&zone=Illinois&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Schaumburg?CST=&zone=Illinois&area=" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?city=Santa%20Rosa?CST=&zone=SF%20Bay%20Area&area=North%20Bay%20/%20Marin"
          to="/corporate-housing/furnished-apartments/bayarea"
          push
        />
        <Redirect
          from="/complex_area.cfm?City=Santa%20Rosa&CST=CA&zone=SF%20Bay%20Area&area=North%20Bay%20/%20Marin"
          to="/corporate-housing/furnished-apartments/bayarea"
          push
        />
        <Redirect
          from="/complex_area.cfm?City=Santa%20Rosa&CST=CA&zone=SF%20Bay%20Area&area="
          to="/corporate-housing/furnished-apartments/bayarea"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=Santa%20Monica?CST=CA&zone=Southern%20Cal&area="
          to="/corporate-housing/furnished-apartments/ca/santamonica"
          push
        />
        {/* <Redirect from="/complex_area.cfm?City=Santa%20Maria&sortOrder=city" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?City=Santa%20Clarita&CST=&zone=Southern%20Cal&area=Orange%20County"
          to="/corporate-housing/furnished-apartments/orangecounty"
          push
        />
        {/* <Redirect from="/complex_area.cfm?City=Santa%20Clarita&CST=&zone=Southern%20Cal&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Sandy?CST=&zone=Utah&area=Utah" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?city=San%20Ramon?CST=CA&zone=SF%20Bay%20Area&area="
          to="/corporate-housing/furnished-apartments/bayarea"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=San%20Ramo?CST=CA&zone=Other%20CA%20Cities&area=" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?City=San%20Rafael&CST=&zone=SF%20Bay%20Area&area="
          to="/corporate-housing/furnished-apartments/bayarea"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=San%20Leandro?CST=CA&zone=&area=" to="/locations" push/> */}
        <Redirect
          from="/complex_area.cfm?city=San%20Leandro?CST=&zone=SF%20Bay%20Area&area="
          to="/corporate-housing/furnished-apartments/bayarea"
          push
        />
        <Redirect
          from="/complex_area.cfm?City=San%20Leandro&CST=CA&zone=SF%20Bay%20Area&area="
          to="/corporate-housing/furnished-apartments/bayarea"
          push
        />
        {/* <Redirect from="/complex_area.cfm?City=San%20Leandro&CST=CA&zone=&area=" to="/locations" push/> */}
        <Redirect
          from="/complex_area.cfm?city=San%20Jose&pageText=San%20Jose&titleText=Short%20Term%20Rentals"
          to="/corporate-housing/furnished-apartments/ca/sanjose"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=San%20Diego?CST=&zone=Southern%20Cal&area="
          to="/corporate-housing/furnished-apartments/ca/sandiego"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=Saint%20Marys?CST=GA&zone=Georgia&area=" to="/locations" push/>
						<Redirect from="/complex_area.cfm?city=Sacramento?CST=CA&zone=&area=" to="/locations" push/>
						<Redirect from="/complex_area.cfm?City=Royal%20Oak&CST=MI&zone=&area=Michigan" to="/locations" push/>
						<Redirect from="/complex_area.cfm?City=Royal%20Oak&CST=&zone=Michigan&area=Michigan" to="/locations" push/> */}
        <Redirect
          from="/complex_area.cfm?city=Round%20Rock?CST=&zone=Austin&area="
          to="/corporate-housing/furnished-apartments/texas/austin"
          push
        />
        <Redirect
          from="/complex_area.cfm?City=Round%20Rock&CST=TX&zone=Austin&area=Texas"
          to="/corporate-housing/furnished-apartments/texas/austin"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=Roseville?CST=MN&zone=Minnesota&area=Minnesota" to="/locations" push/>
						<Redirect from="/complex_area.cfm?city=Roseville?CST=&zone=Minnesota&area=Minnesota" to="/locations" push/>
						<Redirect from="/complex_area.cfm?city=Rockville?CST=UK&zone=DC%20Metro%20Area&area=Maryland" to="/locations" push/>
						<Redirect from="/complex_area.cfm?city=Rockville?CST=&zone=&area=Maryland" to="/locations" push/>
						<Redirect from="/complex_area.cfm?city=Rockford?CST=&zone=&area=Illinois" to="/locations" push/> */}
        <Redirect
          from="/complex_area.cfm?city=Roanoke?CST=&zone=Austin&area=Texas"
          to="/corporate-housing/furnished-apartments/texas/austin"
          push
        />
        {/* <Redirect from="/complex_area.cfm?City=Roanoke&CST=VA&zone=Richmond&area=Richmond" to="/locations" push/>
						<Redirect from="/complex_area.cfm?city=Riverview?CST=&zone=&area=Florida" to="/locations" push/> */}
        <Redirect
          from="/complex_area.cfm?city=Richardson?CST=&zone=Austin&area=Texas"
          to="/corporate-housing/furnished-apartments/texas/austin"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=Richardson?CST=&zone=&area=Texas" to="/locations" push/> */}
        <Redirect
          from="/complex_area.cfm?City=Richardson&CST=&zone=Austin&area=Texas"
          to="/corporate-housing/furnished-apartments/texas/austin"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=Reston?CST=MD&zone=DC%20Metro%20Area&area=Herndon/Reston"
          to="/corporate-housing/furnished-apartments/va/herndon"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=Renton?CST=WA&zone=Washington&area=Washington" to="/locations" push/>
						<Redirect from="/complex_area.cfm?city=Renton?CST=WA&zone=&area=" to="/locations" push/>
						<Redirect from="/complex_area.cfm?city=RENO?CST=&zone=Nevada&area=Nevada" to="/locations" push/> */}
        <Redirect
          from="/complex_area.cfm?city=Redwood%20Shores?CST=CA&zone=SF%20Bay%20Area&area="
          to="/corporate-housing/furnished-apartments/ca/redwoodcity"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=Redwood%20City?CST=&zone=SF%20Bay%20Area&area=Foster%20City/Peninsula"
          to="/corporate-housing/furnished-apartments/ca/redwoodcity"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=Redmond?CST=WA&zone=&area=" to="/locations" push/>
						<Redirect from="/complex_area.cfm?city=Redlands?CST=CA&zone=&area=" to="/locations" push/>
						<Redirect from="/complex_area.cfm?City=Redlands&CST=&zone=Southern%20Cal&area=" to="/locations" push/>
						<Redirect from="/complex_area.cfm?city=Reading?CST=PA&zone=Pennsylvania&area=Pennsylvania" to="/locations" push/>
						<Redirect from="/complex_area.cfm?City=Rancho%20Santa%20Margarita&CST=CA&zone=&area=" to="/locations" push/>
						<Redirect from="/complex_area.cfm?city=Rancho%20Cordova?CST=CA&zone=&area=" to="/locations" push/>
						<Redirect from="/complex_area.cfm?city=Rancho%20Bernardoro?CST=&zone=Southern%20Cal&area=" to="/locations" push/> */}
        <Redirect
          from="/complex_area.cfm?city=Rancho%20Bernardo?CST=CA&zone=Southern%20Cal&area=San%20Diego"
          to="/corporate-housing/furnished-apartments/ca/sandiego"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=Rancho%20Bernardo?CST=&zone=Southern%20Cal&area=" to="/locations" push/> */}
        <Redirect
          from="/complex_area.cfm?city=Raleigh?CST=&zone=North%20Carolina&area=North%20Carolina"
          to="/corporate-housing/furnished-apartments/nc/raleigh"
          push
        />
        {/* <Redirect from="/complex_area.cfm?City=Prince%20Frederick&CST=DC&zone=DC%20Metro%20Area&area=Maryland" to="/locations" push/> */}
        <Redirect
          from="/complex_area.cfm?City=Poughkeepsie&CST=NY&zone=&area=New%20York"
          to="/corporate-housing/furnished-apartments/ny/newyork"
          push
        />
        {/* <Redirect from="/complex_area.cfm?City=Pooler&CST=&zone=&area=Georgia" to="/locations" push/>
						<Redirect from="/complex_area.cfm?City=Pleasanton&CST=CA&zone=&area=" to="/locations" push/> */}
        {/* 200 */}
        {/* <Redirect from="/complex_area.cfm?city=Playa%20Del%20Rey?CST=CA&zone=Southern%20Cal&area=" to="/locations" push/>
						<Redirect from="/complex_area.cfm?City=Playa%20Del%20Rey&CST=CA&zone=Southern%20Cal&area=" to="/locations" push/>
						<Redirect from="/complex_area.cfm?city=Platte%20City?CST=MO&zone=Missouri&area=Missouri" to="/locations" push/>
						<Redirect from="/complex_area.cfm?city=Plainfield?CST=IN&zone=&area=" to="/locations" push/>
						<Redirect from="/complex_area.cfm?city=Plainfield?CST=&zone=&area=Indiana" to="/locations" push/>
						<Redirect from="/complex_area.cfm?city=Pittsburgh?CST=&zone=&area=Pennsylvania" to="/locations" push/>
						<Redirect from="/complex_area.cfm?City=Pittsburgh&sortOrder=city" to="/locations" push/>
						<Redirect from="/complex_area.cfm?city=Pikesville?CST=UK&zone=DC%20Metro%20Area&area=Maryland" to="/locations" push/>
						<Redirect from="/complex_area.cfm?city=Pikesville?CST=UK&zone=&area=Maryland" to="/locations" push/>
						<Redirect from="/complex_area.cfm?city=Phoenix?CST=&zone=&area=Arizona" to="/locations" push/>
						<Redirect from="/complex_area.cfm?city=Pheonix?CST=AZ&zone=&area=" to="/locations" push/> */}
        <Redirect
          from="/complex_area.cfm?City=Petaluma&CST=&zone=SF%20Bay%20Area&area="
          to="/corporate-housing/furnished-apartments/bayarea"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=Pembroke%20Pines?CST=FL&zone=Florida&area=Florida" to="/locations" push/>
						<Redirect from="/complex_area.cfm?city=Pembroke%20Pines?CST=FL&zone=&area=Florida" to="/locations" push/>
						<Redirect from="/complex_area.cfm?city=Pembroke%20Pines?CST=&zone=&area=Florida" to="/locations" push/>
						<Redirect from="/complex_area.cfm?City=Pembroke%20Pines&CST=FL&zone=Florida&area=Florida" to="/locations" push/>
						<Redirect from="/complex_area.cfm?City=Owings%20Mills&CST=UK&zone=&area=Maryland" to="/locations" push/>
						<Redirect from="/complex_area.cfm?city=Oviedo?CST=FL&zone=&area=" to="/locations" push/>
						<Redirect from="/complex_area.cfm?city=Oviedo?CST=&zone=Florida&area=Florida" to="/locations" push/>
						<Redirect from="/complex_area.cfm?city=Orlando?CST=&zone=&area=Florida" to="/locations" push/>
						<Redirect from="/complex_area.cfm?city=Ogden?CST=UT&zone=&area=" to="/locations" push/>
						<Redirect from="/complex_area.cfm?City=Ogden&CST=UT&zone=&area=Utah" to="/locations" push/>
						<Redirect from="/complex_area.cfm?City=ODENTON&CST=&zone=&area=Maryland" to="/locations" push/>
						<Redirect from="/complex_area.cfm?city=Oak%20Park?CST=&zone=Illinois&area=Illinois" to="/locations" push/>
						<Redirect from="/complex_area.cfm?city=Novi?CST=&zone=&area=Michigan" to="/locations" push/> */}
        <Redirect
          from="/complex_area.cfm?City=Novato&CST=CA&zone=SF%20Bay%20Area&area=San%20Francisco"
          to="/corporate-housing/furnished-apartments/ca/sanfrancisco"
          push
        />
        <Redirect
          from="/complex_area.cfm?City=Novato&CST=CA&zone=SF%20Bay%20Area&area=North%20Bay%20/%20Marin"
          to="/corporate-housing/furnished-apartments/bayarea"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=Norwalk?CST=CT&zone=&area=" to="/locations" push/>
						<Redirect from="/complex_area.cfm?city=North%20Royalton?CST=&zone=&area=Ohio" to="/locations" push/> */}
        <Redirect
          from="/complex_area.cfm?city=North%20Raleigh?CST=NC&zone=North%20Carolina&area=North%20Carolina"
          to="/corporate-housing/furnished-apartments/nc/raleigh"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=North%20Raleigh?CST=NC&zone=&area="
          to="/corporate-housing/furnished-apartments/nc/raleigh"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=North%20Raleigh?CST=&zone=North%20Carolina&area=North%20Carolina"
          to="/corporate-housing/furnished-apartments/nc/raleigh"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=North%20Potomac?CST=MD&zone=DC%20Metro%20Area&area=Maryland" to="/locations" push/>
						<Redirect from="/complex_area.cfm?city=North%20Potomac?CST=MD&zone=&area=" to="/locations" push/>
						<Redirect from="/complex_area.cfm?city=North%20Potomac?CST=&zone=DC%20Metro%20Area&area=Maryland" to="/locations" push/> */}
        <Redirect
          from="/complex_area.cfm?city=North%20Bethesda?CST=UK&zone=DC%20Metro%20Area&area=Maryland"
          to="/corporate-housing/furnished-apartments/md/northbethesda"
          push
        />
        <Redirect
          from="/complex_area.cfm?City=North%20Bethesd&CST=MD&zone=&area="
          to="/corporate-housing/furnished-apartments/md/northbethesda"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=Norristown?CST=&zone=&area=Pennsylvania" to="/locations" push/>
						<Redirect from="/complex_area.cfm?city=Norcross?CST=GA&zone=Georgia&area=" to="/locations" push/>
						<Redirect from="/complex_area.cfm?City=Norcross&CST=GA&zone=&area=Georgia" to="/locations" push/>
						<Redirect from="/complex_area.cfm?City=Niceville&CST=FL&zone=&area=Florida" to="/locations" push/>
						<Redirect from="complex_area.cfm?city=Newton?CST=MA&zone=Massachusetts&area=Massachusetts" to="/locations" push/>
						<Redirect from="/complex_area.cfm?City=Newton&CST=&zone=Massachusetts&area=Massachusetts" to="/locations" push/>
						<Redirect from="/complex_area.cfm?city=Newport%20News?CST=CA&zone=Richmond&area=Richmond" to="/locations" push/>
						<Redirect from="/complex_area.cfm?City=Newbury%20Park&CST=CA&zone=Other%20CA%20Cities&area=" to="/locations" push/>
						<Redirect from="/complex_area.cfm?City=Newbury%20Park&CST=&zone=Other%20CA%20Cities&area=" to="/locations" push/>
						<Redirect from="/complex_area.cfm?city=Newark?CST=&zone=Delaware&area=Delaware" to="/locations" push/>
						<Redirect from="/complex_area.cfm?City=Newark&CST=&zone=&area=Delaware" to="/locations" push/> */}
        <Redirect
          from="/complex_area.cfm?city=New%20York?CST=&zone=New%20York&area=New%20York"
          to="/corporate-housing/furnished-apartments/ny/newyork"
          push
        />
        <Redirect
          from="/complex_area.cfm?City=New%20York%20City&CST=NY&zone=New%20York&area=New%20York"
          to="/corporate-housing/furnished-apartments/ny/newyork"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=New%20Orleans?CST=LA&zone=&area=Louisiana" to="/locations" push/>
						<Redirect from="/complex_area.cfm?city=New%20Orleans?CST=&zone=Louisiana&area=" to="/locations" push/>
						<Redirect from="/complex_area.cfm?city=Naperville?CST=IL&zone=&area=" to="/locations" push/>
						<Redirect from="/complex_area.cfm?city=Naperville?CST=&zone=&area=Illinois" to="/locations" push/>
						<Redirect from="/complex_area.cfm?City=Naperville&CST=IL&zone=&area=" to="/locations" push/>
						<Redirect from="/complex_area.cfm?City=Naperville&CST=&zone=&area=Illinois" to="/locations" push/> */}
        <Redirect
          from="/complex_area.cfm?city=N.%20Seattle?CST=WA&zone=&area=Washington"
          to="/corporate-housing/furnished-apartments/wa/seattle"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=Morrisville?CST=NC&zone=North%20Carolina&area=North%20Carolina" to="/locations" push/>
						<Redirect from="/complex_area.cfm?city=Morrisville?CST=&zone=North%20Carolina&area=North%20Carolina" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Morristown?CST=NJ&zone=New%20Jersey&area=New%20Jersey" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Morristown?CST=&zone=&area=New%20Jersey" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Moreno%20Valley&CST=CA&zone=Southern%20Cal&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Montgomery?CST=&zone=Alabama&area=Alabama" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Monroe?CST=LA&zone=Louisiana&area=Louisiana" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Monroe?CST=&zone=&area=Louisiana" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Mobile?CST=&zone=Alabama&area=Alabama" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Mobile?CST=&zone=Alabama&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Mobile&CST=AL&zone=&area=Alabama" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Mobile&CST=&zone=Alabama&area=Alabama" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Missoula?CST=MT&zone=Montana&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Milwaukee&CST=WI&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Milwaukee&CST=&zone=&area=Wisconsin" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Midwest%20City&CST=OK&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Midvale?CST=UT&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Midvale?CST=&zone=&area=Utah" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Midvale&CST=UT&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Midlothian?CST=VA&zone=Richmond&area=Richmond" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Miami&CST=&zone=Florida&area=Florida" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Metairie?CST=LA&zone=&area=Louisiana" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Mesa?CST=&zone=&area=Arizona" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Mequon?CST=WI&zone=Wisconsin&area=Wisconsin" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Mequon&CST=WI&zone=&area=Wisconsin" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?City=Menlo&CST=CA&zone=SF%20Bay%20Area&area="
          to="/corporate-housing/furnished-apartments/bayarea"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=Menlo%20Park?CST=CA&zone=SF%20Bay%20Area&area="
          to="/corporate-housing/furnished-apartments/bayarea"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=Menlo%20Park?CST=&zone=SF%20Bay%20Area&area=Foster%20City/Peninsula"
          to="/corporate-housing/furnished-apartments/ca/fostercity"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=Menlo%20Park?CST=&zone=SF%20Bay%20Area&area="
          to="/corporate-housing/furnished-apartments/bayarea"
          push
        />
        <Redirect
          from="/complex_area.cfm?City=MENLO%20PARK&CST=&zone=SF%20Bay%20Area&area=Foster%20City/Peninsula"
          to="/corporate-housing/furnished-apartments/ca/fostercity"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=Menands?CST=NY&zone=New%20York&area=New%20York"
          to="/corporate-housing/furnished-apartments/ny/newyork"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=Menands?CST=NY&zone=&area=New%20York"
          to="/corporate-housing/furnished-apartments/ny/newyork"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=Menands?CST=NY&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Memphis?CST=TN&zone=Tennessee&area=Tennessee" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?city=Melville?CST=&zone=&area=New%20York"
          to="/corporate-housing/furnished-apartments/ny/newyork"
          push
        />
        {/* <Redirect from="/complex_area.cfm?City=Medina&CST=OH&zone=&area=Ohio" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Mechanicsville&CST=&zone=Other%20VA%20Cities&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Marlborough?CST=MA&zone=&area=Massachusetts" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Marlborough?CST=&zone=Massachusetts&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Marlborough&CST=MA&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Marietta?CST=&zone=&area=Georgia" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Mandeville?CST=&zone=Louisiana&area=" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?City=MANASSAS&CST=DC&zone=DC%20Metro%20Area&area=Herndon/Reston"
          to="/corporate-housing/furnished-apartments/va/herndon"
          push
        />
        {/* 300 */}
        {/* <Redirect from="/complex_area.cfm?city=Madison?CST=&zone=Alabama&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Madison&CST=&zone=Alabama&area=Alabama" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Lynwood?CST=WA&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Lynnwood?CST=WA&zone=Washington&area=Washington" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Lynnwood&CST=WA&zone=Washington&area=Washington" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Lowell?CST=MA&zone=Massachusetts&area=Massachusetts" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Lowell&CST=&zone=Massachusetts&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Louisville?CST=KY&zone=&area=" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?City=Los%20Gatos&CST=CA&zone=SF%20Bay%20Area&area="
          to="/corporate-housing/furnished-apartments/bayarea"
          push
        />
        <Redirect
          from="/complex_area.cfm?City=Los%20Altos&CST=CA&zone=SF%20Bay%20Area&area=Silicon%20Valley%20-%20North"
          to="/corporate-housing/furnished-apartments/siliconvalley"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=Livermore?CST=CA&zone=SF%20Bay%20Area&area=Tri%20Valley"
          to="/corporate-housing/furnished-apartments/bayarea"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=Lisle?CST=&zone=&area=Illinois" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Lexington?CST=&zone=Massachusetts&area=Massachusetts" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Lexington?CST=&zone=&area=Massachusetts" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Lehi?CST=&zone=&area=Utah" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Lees%20Summit&CST=MO&zone=&area=Missouri" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Layton?CST=&zone=&area=Utah" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Laurel?CST=UK&zone=DC%20Metro%20Area&area=Maryland" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Laurel?CST=MD&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Laurel?CST=&zone=&area=Maryland" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?city=Larkspur?CST=&zone=SF%20Bay%20Area&area="
          to="/corporate-housing/furnished-apartments/bayarea"
          push
        />
        {/* <Redirect from="/complex_area.cfm?City=Lakeville&CST=MN&zone=Minnesota&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Lake%20Villa?CST=IL&zone=Illinois&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Lake%20Villa?CST=&zone=Illinois&area=Illinois" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Lake%20Villa&CST=&zone=Illinois&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Lake%20Oswego?CST=&zone=Oregon&area=Oregon" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Lake%20Oswego&CST=OR&zone=Oregon&area=Oregon" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Lake%20Forest?CST=&zone=Southern%20Cal&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Lake%20Bluff?CST=&zone=Illinois&area=Illinois" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Lake%20Bluff&CST=IL&zone=&area=Illinois" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Laguna%20Niguel?CST=CA&zone=Southern%20Cal&area=" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?city=Laguna%20Niguel?CST=&zone=Southern%20Cal&area=Orange%20County"
          to="/corporate-housing/furnished-apartments/orangecounty"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=Ladson?CST=SC&zone=&area=South%20Carolina" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?City=La%20Jolla&CST=CA&zone=Southern%20Cal&area=San%20Diego"
          to="/corporate-housing/furnished-apartments/ca/sandiego"
          push
        />
        {/* <Redirect from="/complex_area.cfm?City=La%20Jolla&CST=CA&zone=Southern%20Cal&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=La%20Jolla&CST=CA&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Kennesaw?CST=GA&zone=Georgia&area=Georgia" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Kennesaw&CST=&zone=&area=Georgia" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Keller&CST=TX&zone=&area=Texas" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?City=Keller&CST=&zone=Austin&area="
          to="/corporate-housing/furnished-apartments/texas/austin"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=Katy?CST=&zone=&area=Texas" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Jacksonville?CST=&zone=Florida&area=Florida" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Jacksonville&CST=FL&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Irving?CST=TX&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Irving?CST=&zone=&area=Texas" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?City=Irving&CST=TX&zone=Austin&area="
          to="/corporate-housing/furnished-apartments/texas/austin"
          push
        />
        {/* <Redirect from="/complex_area.cfm?City=Irvine&CST=CA&zone=Southern%20Cal&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Huntsville&CST=&zone=Alabama&area=Alabama" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Huntersville?CST=NC&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Hummelstown?CST=PA&zone=Pennsylvania&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Hummelstown&CST=&zone=&area=Pennsylvania" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?city=Houston?CST=&zone=&area=Texas"
          to="/corporate-housing/furnished-apartments/tx/houston"
          push
        />
        <Redirect
          from="/complex_area.cfm?City=Houston&CST=TX&zone=&area=Texas"
          to="/corporate-housing/furnished-apartments/tx/houston"
          push
        />
        <Redirect
          from="/complex_area.cfm?City=Houston&CST=TX&zone=&area="
          to="/corporate-housing/furnished-apartments/tx/houston"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=Hilton%20Head?CST=SC&zone=&area=South%20Carolina" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Hillsboro?CST=WA&zone=Oregon&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Highlands%20Ranch&sortOrder=city" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Hickory?CST=&zone=North%20Carolina&area=North%20Carolina" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Hickory&CST=NC&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Herriman?CST=UT&zone=Utah&area=Utah" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Herriman&CST=&zone=Utah&area=Utah" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Hayward&CST=CA&zone=&area=" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?City=Hayward&CST=&zone=SF%20Bay%20Area&area=Hayward/Fremont"
          to="/corporate-housing/furnished-apartments/bayarea"
          push
        />
        {/* <Redirect from="/complex_area.cfm?City=Hartford&CST=CT&zone=Connecticut&area=Connecticut" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Hartford&CST=CT&zone=Connecticut&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Hartford&CST=CT&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Hartford&CST=&zone=Connecticut&area=Connecticut" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Harrisburg?CST=PA&zone=Pennsylvania&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Harrisburg?CST=PA&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Hagerstown&CST=MD&zone=DC%20Metro%20Area&area=Maryland" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Hackensack?CST=&zone=&area=New%20Jersey" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Gurnee&CST=IL&zone=&area=Illinois" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?city=Guilderland%20Center?CST=&zone=&area=New%20York"
          to="/corporate-housing/furnished-apartments/ny/newyork"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=Greer?CST=SC&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Greenville&CST=SC&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Greenville&CST=NC&zone=&area=North%20Carolina" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?City=Grapevine&CST=&zone=Austin&area="
          to="corporate-housing/furnished-apartments/texas/austin"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=Grandview?CST=MO&zone=Missouri&area=Missouri" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Grand%20Rapids?CST=MI&zone=Michigan&area=Michigan" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Grand%20Rapids?CST=MI&zone=&area=Michigan" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Glendale?CST=AZ&zone=Arizona&area=Arizona" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Glendale&CST=&zone=&area=Arizona" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?city=Glen%20Cove?CST=&zone=&area=New%20York"
          to="/corporate-housing/furnished-apartments/ny/newyork"
          push
        />
        {/* <Redirect from="/complex_area.cfm?City=Glen%20Burnie&CST=MD&zone=DC%20Metro%20Area&area=Maryland" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Glen%20Allen&CST=VA&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Gilbert&CST=&zone=&area=Arizona" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Germantown?CST=UK&zone=DC%20Metro%20Area&area=Maryland" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Germantown?CST=MD&zone=&area=Maryland" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Germantown?CST=&zone=Tennessee&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Germantown?CST=&zone=DC%20Metro%20Area&area=Maryland" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Germantown?CST=&zone=&area=Maryland" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Gaithersburg?CST=&zone=&area=Maryland" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Gainesville&CST=FL&zone=&area=Florida" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Fullerton?CST=CA&zone=&area=" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?City=Fullerton&CST=&zone=Southern%20Cal&area=Orange%20County"
          to="/corporate-housing/furnished-apartments/orangecounty"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=Frisco?CST=&zone=&area=Texas"
          to="/locations"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=Fremont?CST=&zone=SF%20Bay%20Area&area="
          to="/corporate-housing/furnished-apartments/bayarea"
          push
        />
        <Redirect
          from="/complex_area.cfm?City=Fremont&CST=CA&zone=SF%20Bay%20Area&area=Hayward/Fremont"
          to="/corporate-housing/furnished-apartments/bayarea"
          push
        />
        <Redirect
          from="/complex_area.cfm?City=Fremont&CST=CA&zone=&area="
          to="/locations"
          push
        />
        <Redirect
          from="/complex_area.cfm?City=Fremont&CST=&zone=SF%20Bay%20Area&area="
          to="/corporate-housing/furnished-apartments/bayarea"
          push
        />
        {/* 400 */}
        {/* <Redirect from="/complex_area.cfm?city=Fredericksburg?CST=VA&zone=&area=" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?City=Fredericksburg&CST=&zone=DC%20Metro%20Area&area=Arlington/Alexandria"
          to="/corporate-housing/furnished-apartments/va/arlington"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=Frederick?CST=UK&zone=DC%20Metro%20Area&area=Maryland" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Frederick?CST=UK&zone=&area=Maryland" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Franklin?CST=&zone=Tennessee&area=Tennessee" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Framingham?CST=MA&zone=Massachusetts&area=Massachusetts" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?city=Fort%20Worth?CST=TX&zone=Austin&area=Texas"
          to="/corporate-housing/furnished-apartments/texas/austin"
          push
        />
        {/* <Redirect from="/complex_area.cfm?City=Fort%20Worth&CST=TX&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Fort%20Wayne?CST=IN&zone=&area=Indiana" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Fort%20Wayne?CST=&zone=&area=Indiana" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Fort%20Wayne&CST=IN&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Fort%20Mill?CST=SC&zone=South%20Carolina&area=South%20Carolina" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Fort%20Mill?CST=&zone=&area=South%20Carolina" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Fort%20Mill&CST=&zone=South%20Carolina&area=South%20Carolina" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Fort%20Lauderdale?CST=FL&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Foothill%20Ranch?CST=CA&zone=Southern%20Cal&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Foothill%20Ranch&CST=CA&zone=Southern%20Cal&area=" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?City=Flower%20Mound&CST=TX&zone=Austin&area="
          to="corporate-housing/furnished-apartments/texas/austin"
          push
        />
        <Redirect
          from="/complex_area.cfm?City=Flower%20Mound&CST=&zone=Austin&area="
          to="corporate-housing/furnished-apartments/texas/austin"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=Florence?CST=SC&zone=South%20Carolina&area=South%20Carolina" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Florence?CST=SC&zone=&area=South%20Carolina" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Florence?CST=SC&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Florence&CST=SC&zone=South%20Carolina&area=South%20Carolina" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Federal%20Way&CST=WA&zone=&area=Washington" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Fayetteville?CST=NC&zone=North%20Carolina&area=North%20Carolina" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Fayetteville&CST=NC&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Farmington%20Hills?CST=MI&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Farmington%20Hills&CST=&zone=&area=Michigan" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Farmers%20Branch?CST=TX&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Falls%20Church?CST=VA&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Fairfield&CST=&zone=Northern%20Cal&area=Sacramento" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?City=Fairfax&CST=UK&zone=DC%20Metro%20Area&area=Tysons/Fairfax"
          to="/corporate-housing/furnished-apartments/va/fairfax"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=Everett?CST=WA&zone=Washington&area=Washington" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Escondido?CST=CA&zone=Southern%20Cal&area=" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?city=Emeryville?CST=CA&zone=SF%20Bay%20Area&area="
          to="/corporate-housing/furnished-apartments/bayarea"
          push
        />
        <Redirect
          from="/complex_area.cfm?City=Emeryville&CST=&zone=SF%20Bay%20Area&area="
          to="/corporate-housing/furnished-apartments/bayarea"
          push
        />
        {/* <Redirect from="/complex_area.cfm?City=Ellicott%20City&CST=UK&zone=&area=Maryland" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Ellicott%20City&CST=DC&zone=&area=Maryland" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Elkridge&CST=MD&zone=DC%20Metro%20Area&area=Maryland" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Elk%20Grove&CST=CA&zone=Northern%20Cal&area=Sacramento" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=El%20Paso?CST=TX&zone=&area=Texas" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Edina?CST=MN&zone=Minnesota&area=Minnesota" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Eden%20Prairie?CST=MN&zone=Minnesota&area=Minnesota" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Eden%20Prairie?CST=MN&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Eden%20Prairie?CST=&zone=Minnesota&area=Minnesota" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Eden%20Prairie?CST=&zone=&area=Minnesota" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Easton&CST=DC&zone=&area=Maryland" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=East%20Stroudsburg?CST=PA&zone=Pennsylvania&area=Pennsylvania" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=East%20Stroudsburg&CST=PA&zone=Pennsylvania&area=Pennsylvania" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Eagleville?CST=PA&zone=Pennsylvania&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Eagleville?CST=PA&zone=&area=Pennsylvania" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Eagleville&CST=PA&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Dunwoody&CST=GA&zone=Georgia&area=Georgia" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Duluth&CST=GA&zone=&area=Georgia" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?city=Dulles?CST=DC&zone=DC%20Metro%20Area&area=Herndon/Reston"
          to="/corporate-housing/furnished-apartments/va/herndon"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=Dublin?CST=&zone=SF%20Bay%20Area&area="
          to="/corporate-housing/furnished-apartments/bayarea"
          push
        />
        {/* <Redirect from="/complex_area.cfm?City=Dublin&CST=OH&zone=&area=Ohio" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Dublin&CST=&zone=&area=Ohio" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Draper?CST=&zone=&area=Utah" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Draper&CST=&zone=&area=Utah" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Denver?CST=UK&zone=Colorado&area=Colorado" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Denver?CST=CO&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Denver?CST=&zone=Colorado&area=Colorado" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Decatur?CST=GA&zone=&area=Georgia" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Dearborn&CST=MI&zone=&area=Michigan" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Davidson&CST=&zone=&area=North%20Carolina" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?City=Danville&CST=&zone=SF%20Bay%20Area&area="
          to="/corporate-housing/furnished-apartments/bayarea"
          push
        />
        {/* <Redirect from="/complex_area.cfm?City=Danbury&CST=CT&zone=&area=Connecticut" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Dana%20Point&CST=CA&zone=Other%20CA%20Cities&area=" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?city=Daly%20City?CST=&zone=SF%20Bay%20Area&area="
          to="/corporate-housing/furnished-apartments/bayarea"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=Dallas?CST=TX&zone=Austin&area=Texas"
          to="/corporate-housing/furnished-apartments/texas/austin"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=Cupertino?CST=CA&zone=&area="
          to="/corporate-housing/furnished-apartments/ca/cupertino"
          push
        />
        <Redirect
          from="/complex_area.cfm?City=Cupertino&sortOrder=city&titleText=extended"
          to="/corporate-housing/furnished-apartments/ca/cupertino"
          push
        />
        {/* <Redirect from="/complex_area.cfm?City=Culver%20City&CST=CA&zone=Other%20Cities&area=Other%20CA%20Cities" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Crofton?CST=DC&zone=&area=Maryland" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Crofton?CST=&zone=DC%20Metro%20Area&area=Maryland" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Crofton?CST=&zone=&area=Maryland" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Crofton&CST=UK&zone=&area=Maryland" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Crestview?CST=&zone=Florida&area=Florida" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Crestview%20Hills&CST=KY&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Covington&CST=LA&zone=Louisiana&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Corpus%20Christi?CST=&zone=&area=Texas" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Coraopolis?CST=PA&zone=Pennsylvania&area=Pennsylvania" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Coraopolis?CST=PA&zone=&area=Pennsylvania" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Coraopolis?CST=&zone=Pennsylvania&area=Pennsylvania" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Coraopolis?CST=&zone=&area=Pennsylvania" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Coppell?CST=&zone=&area=Texas" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Copley?CST=OH&zone=Ohio&area=Ohio" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Copley&CST=OH&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Conroe?CST=TX&zone=&area=" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?city=Concord?CST=CA&zone=SF%20Bay%20Area&area="
          to="/corporate-housing/furnished-apartments/bayarea"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=Columbus?CST=GA&zone=Georgia&area=Georgia" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Columbus?CST=GA&zone=Georgia&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Columbus?CST=&zone=Ohio&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Columbus?CST=&zone=&area=Ohio" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?city=Columbia?CST=SC&zone=South%20Carolina&area=South%20Carolina"
          to="/corporate-housing/furnished-apartments/md/columbia"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=Columbia?CST=&zone=South%20Carolina&area=South%20Carolina"
          to="/corporate-housing/furnished-apartments/md/columbia"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=College%20Park?CST=UK&zone=DC%20Metro%20Area&area=Maryland" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=College%20Park?CST=&zone=DC%20Metro%20Area&area=Maryland" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=College%20Park?CST=&zone=&area=Maryland" to="/locations" push /> */}
        {/* 500 */}
        {/* <Redirect from="/complex_area.cfm?City=College%20Park&CST=&zone=DC%20Metro%20Area&area=Maryland" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Cockeysville&CST=UK&zone=DC%20Metro%20Area&area=Maryland" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Cleveland?CST=&zone=Ohio&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Clearwater?CST=FL&zone=Florida&area=Florida" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Clearwater?CST=FL&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Clearwater?CST=&zone=&area=Florida" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Clearfield?CST=&zone=Utah&area=Utah" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Clayton?CST=MO&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Clayton&CST=MO&zone=&area=Missouri" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Cincinnati?CST=OH&zone=Ohio&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Cincinnati?CST=&zone=&area=Ohio" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?city=Chula%20Vista?CST=&zone=Southern%20Cal&area=San%20Diego"
          to="/corporate-housing/furnished-apartments/ca/sandiego"
          push
        />
        {/* <Redirect from="/complex_area.cfm?City=Chula%20Vista&CST=&zone=Southern%20Cal&area=" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?city=Chicago?CST=IL&zone=Illinois&area=Illinois"
          to="/corporate-housing/furnished-apartments/IL/chicago"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=Chicago?CST=IL&zone=Illinois&area="
          to="/corporate-housing/furnished-apartments/IL/chicago"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=Chicago?CST=&zone=&area=Illinois"
          to="/corporate-housing/furnished-apartments/IL/chicago"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=Chevy%20Chase?CST=UK&zone=DC%20Metro%20Area&area=Maryland" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Chevy%20Chase&CST=UK&zone=DC%20Metro%20Area&area=Maryland" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Chattanooga?CST=&zone=Tennessee&area=Tennessee" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Chattanooga&CST=TN&zone=Tennessee&area=Tennessee" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?City=Charlottesville&CST=VA&zone=&area="
          to="/corporate-housing/furnished-apartments/nc/charlotte"
          push
        />
        <Redirect
          from="/complex_area.cfm?City=Charlotte&CST=&zone=North%20Carolina&area="
          to="/corporate-housing/furnished-apartments/nc/charlotte"
          push
        />
        {/* <Redirect from="/complex_area.cfm?City=Charleston&CST=&zone=South%20Carolina&area=South%20Carolina" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Chandler?CST=AZ&zone=Arizona&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Centreville&CST=VA&zone=Other%20VA%20Cities&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Cary&CST=&zone=North%20Carolina&area=North%20Carolina" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Carmel?CST=IN&zone=Indiana&area=Indiana" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Canton&CST=MI&zone=Michigan&area=Michigan" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?city=Campbell?CST=&zone=SF%20Bay%20Area&area=Silicon%20Valley%20-%20South"
          to="/corporate-housing/furnished-apartments/siliconvalley"
          push
        />
        <Redirect
          from="/complex_area.cfm?City=Campbell&CST=CA&zone=SF%20Bay%20Area&area="
          to="/corporate-housing/furnished-apartments/bayarea"
          push
        />
        <Redirect
          from="/complex_area.cfm?City=Campbell&CST=CA&zone=&area=Silicon%20Valley%20-%20South"
          to="/corporate-housing/furnished-apartments/siliconvalley"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=Cambridge?CST=MA&zone=Massachusetts&area=Massachusetts" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Cambridge?CST=&zone=Massachusetts&area=Massachusetts" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Cambridge&CST=VA&zone=Massachusetts&area=Massachusetts" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Burlington&CST=MA&zone=Massachusetts&area=Massachusetts" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Burlington&CST=&zone=Massachusetts&area=" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?City=Burlingame&CST=&zone=SF%20Bay%20Area&area=Foster%20City/Peninsula"
          to="corporate-housing/furnished-apartments/ca/fostercity"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=Burbank?CST=CA&zone=Southern%20Cal&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Buffalo%20Grove?CST=IL&zone=Illinois&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Buffalo%20Grove&CST=&zone=&area=Illinois" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Broomfield&CST=&zone=Colorado&area=Colorado" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Brookfield?CST=WI&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Broadview%20Heights&CST=OH&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Bridgewater?CST=MA&zone=Massachusetts&area=Massachusetts" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Bridgewater?CST=&zone=Massachusetts&area=Massachusetts" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Brentwood?CST=TN&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Brentwood&CST=TN&zone=Tennessee&area=Tennessee" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Bremerton?CST=WA&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Brandon&CST=FL&zone=&area=Florida" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Bowie?CST=UK&zone=DC%20Metro%20Area&area=Maryland" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Bowie?CST=MD&zone=&area=Maryland" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Boulder&CST=&zone=Colorado&area=Colorado" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=BOTHELL&CST=WA&zone=Washington&area=Washington" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Boise&CST=ID&zone=Idaho&area=Idaho" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Boiling%20Springs&CST=SC&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Blue%20Bell?CST=PA&zone=&area=Pennsylvania" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Blue%20Bell&CST=PA&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Bloomington?CST=IL&zone=&area=Illinois" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Bloomington?CST=&zone=&area=Illinois" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Birmingham?CST=AL&zone=Alabama&area=Alabama" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Birmingham&CST=&zone=Alabama&area=Alabama" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Billerica&CST=MA&zone=&area=Massachusetts" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Billerica&CST=&zone=Massachusetts&area=Massachusetts" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Beverly%20Hills&CST=&zone=Other%20CA%20Cities&area=" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?city=Bethesda?CST=UK&zone=DC%20Metro%20Area&area=Maryland"
          to="/corporate-housing/furnished-apartments/md/northbethesda"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=Bethesda?CST=&zone=DC%20Metro%20Area&area=Maryland"
          to="/corporate-housing/furnished-apartments/md/northbethesda"
          push
        />
        {/* <Redirect from="/complex_area.cfm?City=Bethe&CST=&zone=&area=Maryland" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Bensalem?CST=PA&zone=Pennsylvania&area=Pennsylvania" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Bensalem&CST=PA&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Bel%20Air&CST=UK&zone=&area=Maryland" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Beaverton&CST=OR&zone=&area=Oregon" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Beachwood&CST=OH&zone=Ohio&area=Ohio" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Baton%20Rouge?CST=LA&zone=&area=Louisiana" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Baton%20Rouge?CST=LA&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Baton%20Rouge?CST=&zone=Louisiana&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Bartlett&CST=TN&zone=&area=Tennessee" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Baltimore&CST=MD&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Austin?CST=TX&zone=Austin&area=Texas" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Austin?CST=&zone=&area=Texas" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Austin&CST=&zone=Austin&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Aurora?CST=CO&zone=Colorado&area=Colorado" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Augusta?CST=GA&zone=Georgia&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Auburn&CST=ME&zone=&area=" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?City=Ashburn&CST=VA&zone=&area="
          to="/corporate-housing/furnished-apartments/va/ashburn"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=Arlington?CST=VA&zone=&area="
          to="/corporate-housing/furnished-apartments/va/arlington"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=Arlington&CST=VA&titleText=Furnished%20Apartments"
          to="/corporate-housing/furnished-apartments/va/arlington"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=Andover?CST=&zone=&area=Massachusetts" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Andover&CST=MA&zone=&area=Massachusetts" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Andover&CST=&zone=&area=Massachusetts" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Anaheim?CST=CA&zone=Southern%20Cal&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Amarillo&CST=TX&zone=&area=Texas" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=ALL&CST=VA&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=ALL&CST=UT&zone=Utah&area=Utah" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=ALL&CST=MN&zone=Minnesota&area=Minnesota" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=ALL&CST=MI&zone=Michigan&area=Michigan" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=ALL&CST=CA&zone=Northern%20Cal&area=Sacramento" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=ALL&CST=AZ&zone=Arizona&area=Arizona" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Aliso%20Viejo?CST=CA&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Aliso%20Viejo?CST=&zone=Southern%20Cal&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Aliso%20Viejo&CST=CA&zone=Southern%20Cal&area=" to="/locations" push /> */}
        {/* 600 */}
        {/* <Redirect from="/complex_area.cfm?city=Alexandria&CST=VA&titleText=Corporate-Housing-Alexandria" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?city=Alexandria&CST=VA&titleText=Corporate-Apartments-Arlington"
          to="/corporate-housing/furnished-apartments/va/arlington"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=Albany?CST=NY&zone=New%20York&area=New%20York"
          to="/corporate-housing/furnished-apartments/ny/newyork"
          push
        />
        <Redirect
          from="/complex_area.cfm?City=Alameda&CST=CA&zone=SF%20Bay%20Area&area=Oakland/Alameda/Emeryville"
          to="/corporate-housing/furnished-apartments/bayarea"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=Aiken?CST=SC&zone=South%20Carolina&area=South%20Carolina" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Aiken?CST=SC&zone=&area=South%20Carolina" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=Agoura%20Hills?CST=CA&zone=Southern%20Cal&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?City=Agoura%20Hills&CST=CA&zone=Southern%20Cal&area=" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?City=Agoura%20Hills&CST=&zone=Southern%20Cal&area=Los%20Angeles"
          to="/corporate-housing/furnished-apartments/ca/losangeles"
          push
        />
        <Redirect
          from="/complex_area.cfm?CID=8590&CN=TOWER%20737&BTN=ALL&CITY=San%20Francisco&CST=CA&titletext=extended&sortOrder=city&scrollBack=Yes"
          to="/corporate-housing/furnished-apartments/ca/sanfrancisco"
          push
        />
        <Redirect
          from="/complex_area.cfm?CID=8453&CN=AVALON%20CRESCENT&BTN=ALL&CITY=McLean&CST=VA&titletext=furnished&sortOrder=&scrollBack=Yes"
          to="/locations"
          push
        />
        <Redirect
          from="/complex_area.cfm?CID=14409&CN=TUSCANA&BTN=ALL&CITY=Champions%20Gate&CST=FL&titletext=&sortOrder=&scrollBack=No"
          to="/locations"
          push
        />
        <Redirect
          from="/complex_area.cfm?CID=13926&CN=LOFTS%20AT%20707%20TENTH&BTN=ALL&CITY=San%20Diego&CST=CA&titletext=furnished&sortOrder=city&scrollBack=Yes"
          to="/corporate-housing/furnished-apartments/ca/sandiego"
          push
        />
        <Redirect
          from="/complex_area.cfm?CID=11126&CN=MADRID%20APARTMENTS&BTN=ALL&CITY=Mission%20Viejo&CST=CA&titletext=shortterm&sortOrder=&scrollBack=Yes"
          to="locations"
          push
        />
        <Redirect
          from="/complex_area.cfm?area=Tysons/Fairfax&titleText=furnished%20apartments"
          to="/corporate-housing/furnished-apartments/va/tysons"
          push
        />
        {/* <Redirect from="/complex_area.cfm?Area=Tysons/Fairfax" to="/locations" push /> */}
        <Redirect
          from="/complex_area.cfm?Area=Silicon%20Valley%20-%20South&CST=CA&zone="
          to="/corporate-housing/furnished-apartments/siliconvalley"
          push
        />
        <Redirect
          from="/complex_area.cfm?Area=Silicon%20Valley%20-%20South&city=city"
          to="/corporate-housing/furnished-apartments/siliconvalley"
          push
        />
        <Redirect
          from="/complex_area.cfm?area=San%20Francisco&titleText=Corporate%20Housing"
          to="/corporate-housing/furnished-apartments/ca/sanfrancisco"
          push
        />
        {/* <Redirect from="/complex_area.cfm?Area=ALL&CST=CA&zone=Southern%20Cal" to="/locations" push /> */}
        <Redirect from="/cityscapes/SF/$OG_CANONICAL" to="/locations" push />
        <Redirect from="/Cityscapes/New-Jersey/" to="/locations" push />
        <Redirect from="/www1.free-share-buttons.top" to="/" push />
        <Redirect from="/whyworkwithus.cfm" to="/" push />
        <Redirect from="/what.cfm" to="/media-kit-What-we-do" push />
        <Redirect from="/were-done-with-lockboxes.cfm" to="/" push />
        <Redirect
          from="/washington-furnished-apartments.cfm"
          to="/corporate-housing/furnished-apartments/dc/washington"
          push
        />
        {/* <Redirect from="/walkscore.cfm?cid=13744" to="/" push /> */}
        <Redirect from="/viewamenitysheet.cfm" to="/locations" push />
        {/* <Redirect from="/viewamenitysheet.cfm?cid=9998&cn=bella vista&city=elk grove&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=9993&cn=bridgepointe&city=san mateo&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=9993" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=9965&cn=beacon place&city=gaithersburg&st=md" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=9964&cn=camden fair lakes&city=fairfax&st=va" to="/locations" push /> */}
        <Redirect
          from="/viewamenitysheet.cfm?cid=9761&cn=388 beale&city=san francisco&st=ca"
          to="/corporate-housing/furnished-apartments/ca/sanfrancisco"
          push
        />
        <Redirect
          from="/viewamenitysheet.cfm?cid=9707&cn=americana apartments&city=mountain view&st=ca"
          to="/corporate-housing/furnished-apartments/bayarea"
          push
        />
        {/* <Redirect from="/viewamenitysheet.cfm?cid=9703&cn=the palms at laguna niguel&city=laguna niguel&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=9592&cn=edgewater terrace&city=framingham&st=ma" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=9016&cn=promenade oaks&city=eagan&st=mn" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=8126&cn=lake forest at el dorado hills&city=el dorado hills&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=8116&cn=woodgate apartments&city=reading&st=pa" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=80&cn=lantern cove apartments&city=foster city&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=798&cn=adagio apartments&city=sacramento&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=7936&cn=aviare&city=cupertino&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=7847&cn=versailles&city=woodland hills&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=7624&cn=olympic village&city=bremerton&st=wa" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=7499&cn=avalon silicon valley&city=sunnyvale&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=744&cn=oswego pointe&city=lake oswego&st=or" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=720&cn=park place/mountain view&city=mountain view&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=7157&cn=south parc at bethany&city=portland&st=or" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=6296&cn=harbor cove&city=foster city&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=6295&cn=avalon campbell&city=campbell&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=5903&cn=gateway&city=san leandro&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=5589&cn=la vina&city=livermore&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=5571&cn=edgemoore&city=alexandria&st=va" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=5489&cn=harbor oaks&city=sacramento&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=5446&cn=deer valley&city=roseville&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=5437&cn=ava nob hill&city=san francisco&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=5432&cn=sharon green apartments&city=menlo park&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=5355&cn=oak creek apartments&city=palo alto&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=5294&cn=2000 post apartments&city=san francisco&st=ca"to="/locations" push /> */}
        <Redirect
          from="/viewamenitysheet.cfm?cid=5268&cn=beach cove apartments&city=foster city&st=ca"
          to="/corporate-housing/furnished-apartments/ca/fostercity"
          push
        />
        {/* <Redirect from="/viewamenitysheet.cfm?cid=5229&cn=canyon terrace apartments&city=folsom&st=ca	" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=5227&cn=10600 wilshire&city=los angeles&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=5166&cn=shadow woods&city=oakland&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=5127&cn=larkspur courts apartments&city=larkspur&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=5095&cn=avalon mountain view&city=mountain view&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=18493&cn=blvd reston station&city=reston&st=va" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=18361&cn=reed square apartments&city=sunnyvale&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=18355&cn=indigo 19&city=virginia beach&st=va" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=18165&cn=nv portland&city=portland&st=or" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=18065&cn=1305 dock street&city=baltimore&st=md" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=18055&cn=the bartlett&city=arlington&st=va" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=18032&cn=park avenue west&city=portland&st=or" to="/locations" push /> */}
        <Redirect
          from="/viewamenitysheet.cfm?cid=180&cn=fosters landing apartments&city=foster city&st=ca"
          to="/corporate-housing/furnished-apartments/ca/fostercity"
          push
        />
        {/* <Redirect from="/viewamenitysheet.cfm?cid=17816&cn=100 capitol yards&city=washington&st=dc" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=17815&cn=206 apartments&city=hillsboro&st=or" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=17777&cn=block 17&city=portland&st=or" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=17772&cn=flats at bethesda avenue&city=bethesda&st=md" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=17561&cn=revere&city=campbell&st=ca" to="/locations" push /> */}
        <Redirect
          from="/viewamenitysheet.cfm?cid=17547&cn=spruce apartments&city=sunnyvale&st=ca"
          to="/corporate-housing/furnished-apartments/ca/sunnyvale"
          push
        />
        {/* <Redirect from="/viewamenitysheet.cfm?cid=17547" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=17536&cn=azure san francisco&city=san francisco&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=17470&cn=mode&city=san mateo&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=17454&cn=waterline apartments&city=portland&st=or" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=17400&cn=1340 lombard street&city=san francisco&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=17398&cn=park 20&city=san mateo&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=17397&cn=mb360&city=san francisco&st=ca" to="/locations" push /> */}
        <Redirect
          from="/viewamenitysheet.cfm?cid=17378&cn=avalon hayes valley&city=san francisco&st=ca"
          to="/corporate-housing/furnished-apartments/ca/sanfrancisco"
          push
        />
        {/* <Redirect from="/viewamenitysheet.cfm?cid=17308&cn=seasons of traverse mountain&city=lehi&st=ut" to="/locations" push /> */}
        <Redirect
          from="/viewamenitysheet.cfm?cid=17222&cn=hearth apartments&city=santa clara&st=ca"
          to="/corporate-housing/furnished-apartments/ca/santaclara"
          push
        />
        {/* <Redirect from="/viewamenitysheet.cfm?cid=17190&cn=avalon oak creek&city=agoura hills&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=17187&cn=radius&city=redwood city&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=17087&cn=hidden creek - md&city=gaithersburg&st=md" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=17069&cn=madera&city=mountain view&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=17062&cn=mosso&city=san francisco&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=17031&cn=avalon morrison park&city=san jose&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=17018&cn=element 170&city=beaverton&st=or" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=17011&cn=biltmore north&city=cupertino&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=17&cn=ballena village&city=alameda&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=16994&cn=ovation&city=mclean&st=va" to="/locations" push /> */}
        {/* 700 */}
        {/* <Redirect from="/viewamenitysheet.cfm?cid=16992&cn=the avant/reston town center&city=reston&st=va" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=16931&cn=21 & view=&city=salt lake city&st=ut" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=16903&cn=cresthaven apartments&city=lehi&st=ut" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=16863&cn=nineteen 800&city=cupertino&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=16850&cn=19nineteen clarendon apartment&city=arlington&st=va" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=16846&cn=38 dolores&city=san francisco&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=16843&cn=aire apartments&city=san jose&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=16823&cn=venn on market&city=san francisco&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=16778&cn=venue&city=san francisco&st=ca" to="/locations" push /> */}
        <Redirect
          from="/viewamenitysheet.cfm?cid=16747&cn=cedar falls apartment homes&city=portland&st=or"
          to="/corporate-housing/furnished-apartments/oregon/portland"
          push
        />
        {/* <Redirect from="/viewamenitysheet.cfm?cid=16707&cn=ave somerset&city=somerset&st=nj" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=16670&cn=channel mission bay&city=san francisco&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=16496&cn=121 tasman&city=san jose&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=16406&cn=solaire&city=silver spring&st=md" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=16403&cn=the park at irvine spectrum ce&city=irvine&st=ca" to="/locations" push /> */}
        <Redirect
          from="/viewamenitysheet.cfm?cid=16372&cn=1607 apartments&city=portland&st=or"
          to="/corporate-housing/furnished-apartments/oregon/portland"
          push
        />
        {/* <Redirect from="/viewamenitysheet.cfm?cid=16287&cn=millcrest park&city=fort mill&st=sc" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=16274&cn=village park of royal oak&city=royal oak&st=mi" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=16258&cn=carmel the village&city=mountain view&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=16253&cn=lyon place at clarendon center&city=arlington&st=va" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=16239&cn=eaves lake forest&city=lake forest&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=16221&cn=avalon at florham park&city=florham park&st=nj" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=16217&cn=lake vista&city=loveland&st=co" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=16153&cn=palo alto place&city=palo alto&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=16131&cn=avalon at newton highlands&city=newton&st=ma" to="/locations" push /> */}
        <Redirect
          from="/viewamenitysheet.cfm?cid=16122&cn=edgemont at bethesda metro&city=bethesda&st=md"
          to="/corporate-housing/furnished-apartments/md/northbethesda"
          push
        />
        {/* <Redirect from="/viewamenitysheet.cfm?cid=16103&cn=eaves huntington beach&city=huntington beach&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=16102&cn=pacific shores/hunt beach&city=huntington beach&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=16063&cn=acacia on santa rosa creek&city=santa rosa&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=16051&cn=towne center at englewood&city=englewood&st=nj" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=16046&cn=talleyrand apartments&city=tarrytown&st=ny" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=15980&cn=columns at killian hill&city=snellville&st=ga" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=15952&cn=avalon park crest&city=tysons corner&st=va" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=15949&cn=carriage crossing&city=boise&st=id" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=15936&cn=amli at flatirons&city=broomfield&st=co" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=15883&cn=lawrence station&city=sunnyvale&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=15767&cn=westwind farms&city=ashburn&st=va" to="/locations" push /> */}
        <Redirect
          from="/viewamenitysheet.cfm?cid=15750&cn=the plaza at triton park&city=foster city&st=ca"
          to="/corporate-housing/furnished-apartments/ca/fostercity"
          push
        />
        {/* <Redirect from="/viewamenitysheet.cfm?cid=15718&cn=american tobacco center&city=richmond&st=va" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=15646&cn=camden panther creek&city=frisco&st=tx" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=15601&cn=calvert house apartments&city=washington&st=dc" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=15593&cn=marquis at lantana&city=flower mound&st=tx" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=15575&cn=225 grand apartments&city=jersey city&st=nj" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=15571&cn=the metropolitan at 40 park&city=morristown&st=nj" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=15541&cn=summit ridge apartments&city=lees summit&st=mo" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=15517&cn=archstone preston park&city=plano&st=tx" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=15511&cn=ladd&city=portland&st=or" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=15477&cn=royal oak apartments&city=sioux falls&st=sd" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=15449&cn=5550 wilshire&city=los angeles&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=15411&cn=avalon woodland hills&city=woodland hills&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=15408&cn=the villas of parkhaven&city=sherman&st=tx" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=15386&cn=alta ridgewalk&city=woodstock&st=ga" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=15384&cn=alta woods&city=woodstock&st=ga" to="/locations" push /> */}
        <Redirect
          from="/viewamenitysheet.cfm?cid=15373&cn=360 residences&city=san jose&st=ca"
          to="/corporate-housing/furnished-apartments/ca/sanjose"
          push
        />
        {/* <Redirect from="/viewamenitysheet.cfm?cid=15373&cn=360 residence&city=san jose&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=15361&cn=essex at skyline&city=santa ana&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=15340&cn=linq midtown&city=sacramento&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=15304&cn=333 river street&city=hoboken&st=nj" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=15281&cn=arboretum at burlington&city=burlington&st=ma" to="/locations" push /> */}
        <Redirect
          from="/viewamenitysheet.cfm?cid=15235&cn=living at santa monica&city=santa monica&st=ca"
          to="/corporate-housing/furnished-apartments/ca/santamonica"
          push
        />
        {/* <Redirect from="/viewamenitysheet.cfm?cid=15210&cn=essex house&city=portland&st=or" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=15199&cn=pinnacle at galleria&city=roseville&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=15198&cn=preserve at beckett ridge&city=west chester&st=oh" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=15165&cn=414 water street condos&city=baltimore&st=md" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=15139&cn=cambridge crossing&city=beaverton&st=or" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=15125&cn=slate ridge at fishers landing&city=vancouver&st=wa" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=15098&cn=1210 mass apartments&city=washington&st=dc" to="/locations" push /> */}
        <Redirect
          from="/viewamenitysheet.cfm?cid=15091&cn=dwell vienna metro&city=fairfax&st=va"
          to="/corporate-housing/furnished-apartments/va/fairfax"
          push
        />
        {/* <Redirect from="/viewamenitysheet.cfm?cid=15062&cn=broadstone estates&city=albuquerque&st=nm" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=15046&cn=pacific place apartments&city=daly city&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=15034&cn=cypress legends at the forum&city=fort myers&st=fl" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=15029&cn=town square at mark center&city=alexandria&st=va" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=15012&cn=centerville manor&city=virginia beach&st=va" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14985&cn=upstairs at bethesda row&city=bethesda&st=md" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14984&cn=westchester rockville station&city=rockville&st=md" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14961&cn=1801 l&city=sacramento&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14953&cn=archstone sierra del oro&city=corona&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14945&cn=clayborne apt homes&city=alexandria&st=va" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14914&cn=marque at heritage hunt&city=gainesville&st=va" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14908&cn=pebble creek&city=roanoke&st=va" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14902&cn=alexander at patroon creek&city=albany&st=ny" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14900&cn=the fremont building&city=sacramento&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14891&cn=535 amli&city=seattle&st=wa" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14865&cn=bluwater crossing&city=carlsbad&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14773&cn=park viridian&city=anaheim&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14715&cn=cypress villas&city=redlands&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14713&cn=avalon la jolla colony&city=san diego&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14692&cn=marbella apartment homes&city=carlsbad&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14674&cn=indigo @ twelve west&city=portland&st=or" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14672&cn=park 19&city=portland&st=or" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14663&cn=anavia&city=anaheim&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14662&cn=renaissance at uptown orange&city=orange&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14658&cn=avalon burbank&city=burbank&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14635&cn=eaves columbia town center&city=columbia&st=md" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14615&cn=waterhouse place&city=beaverton&st=or" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14561&cn=southfork village&city=lakeville&st=mn" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14555&cn=the meadows at parkwood&city=idaho falls&st=id" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14479&cn=midtown at town center&city=virginia beach&st=va" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14462&cn=waterside at lynnhaven&city=virginia beach&st=va" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14444&cn=the colonnade at germantown&city=germantown&st=tn" to="/locations" push /> */}
        {/* 800 */}
        {/* <Redirect from="/viewamenitysheet.cfm?cid=14428&cn=charleston pines&city=florence&st=ky" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14406&cn=the polos&city=gainesville&st=fl" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14395&cn=san michele at weston&city=weston&st=fl" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14383&cn=highlands viera west&city=viera&st=fl" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14382&cn=hunters bridge&city=burlington&st=nc" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14379&cn=palladium park&city=high point&st=nc" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14372&cn=florida club at bluewater bay&city=niceville&st=fl" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14354&cn=birch pointe&city=beaverton&st=or" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14352&cn=enso&city=portland&st=or" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14327&cn=1200 east west highway&city=silver spring&st=md" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14312&cn=alexander at ghent&city=norfolk&st=va" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14293&cn=belvedere at quail run&city=naples&st=fl" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14264&cn=the cosmopolitan&city=virginia beach&st=va" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14248&cn=windsor at potomac vista&city=woodbridge&st=va" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14240&cn=camden ashburn farm&city=ashburn&st=va" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14238&cn=park crescent&city=norfolk&st=va" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14229&cn=the cameron&city=silver spring&st=md" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14226&cn=riverside station&city=woodbridge&st=va" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14220&cn=reserve at regency park&city=centreville&st=va" to="/locations" push /> */}
        <Redirect
          from="/viewamenitysheet.cfm?cid=14210&cn=the markham&city=cupertino&st=ca"
          to="/corporate-housing/furnished-apartments/ca/cupertino"
          push
        />
        {/* <Redirect from="/viewamenitysheet.cfm?cid=14177&cn=avalon anaheim&city=anaheim&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14169&cn=axis 2300&city=irvine&st=ca	" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14163&cn=devonwood&city=battle ground&st=wa" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14143&cn=franklin street&city=redwood city&st=ca" to="/corporate-housing/furnished-apartments/ca/redwoodcity" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14140&cn=creeks edge at stony point&city=richmond&st=va" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14109&cn=1301 thomas circle&city=washington&st=dc" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14095&cn=201 twenty one&city=norfolk&st=va" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14088&cn=cupertino city center&city=cupertino&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14043&cn=425 mass&city=washington&st=dc" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14024&cn=kruseway commons&city=lake oswego&st=or" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14020&cn=avalon irvine&city=irvine&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14005&cn=camden college park&city=college park&st=md" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=14000&cn=acappella crossing apartments&city=san bruno&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=13984&cn=parker palo alto&city=palo alto&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=13976&cn=carlyle place apartments&city=alexandria&st=va" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=13964&cn=view tower&city=san francisco&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=13962&cn=avenue 64&city=emeryville&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=13955&cn=andover house&city=washington&st=dc" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=13948&cn=millworks&city=novato&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=13929&cn=estates at brentwood&city=brentwood&st=tn" to="/locations" push /> */}
        <Redirect
          from="/viewamenitysheet.cfm?cid=13902&cn=avalon mission bay phase iii&city=san francisco&st=ca"
          to="/corporate-housing/furnished-apartments/ca/sanfrancisco"
          push
        />
        <Redirect
          from="/viewamenitysheet.cfm?cid=13901&cn=avalon mission bay phase ii&city=san francisco&st=ca"
          to="/corporate-housing/furnished-apartments/ca/sanfrancisco"
          push
        />
        {/* <Redirect from="/viewamenitysheet.cfm?cid=13895&cn=alexan carlyle&city=alexandria&st=va" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=13895&cn=800 carlyle&city=alexandria&st=va" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=13894&cn=marquis at silver oaks&city=grapevine&st=tx" to="/locations" push /> */}
        <Redirect
          from="/viewamenitysheet.cfm?cid=13886&cn=aquatera&city=san diego&st=ca"
          to="/corporate-housing/furnished-apartments/ca/sandiego"
          push
        />
        {/* <Redirect from="/viewamenitysheet.cfm?cid=13881&cn=stone point apartments&city=annapolis&st=md" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=13857&cn=harrison tower&city=portland&st=or	" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=13826&cn=domain at brewers hill&city=baltimore&st=md" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=13813&cn=reserve at tysons corner&city=vienna&st=va" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=13785&cn=avalon fashion valley&city=san diego&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=13780&cn=camden main & jamboree=&city=irvine&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=13763&cn=strata at mission bay&city=san francisco&st=ca" to="/locations" push /> */}
        <Redirect
          from="/viewamenitysheet.cfm?cid=13755&cn=the louisa&city=portland&st=or"
          to="/corporate-housing/furnished-apartments/oregon/portland"
          push
        />
        <Redirect
          from="/viewamenitysheet.cfm?cid=13754&cn=asa&city=portland&st=or"
          to="/corporate-housing/furnished-apartments/oregon/portland"
          push
        />
        <Redirect
          from="/viewamenitysheet.cfm?cid=13754&cn=asa flats & lofts=&city=portland&st=or"
          to="/corporate-housing/furnished-apartments/oregon/portland"
          push
        />
        {/* <Redirect from="/viewamenitysheet.cfm?cid=13744&cn=aventino&city=los gatos&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=13657&cn=the marc palo alto&city=palo alto&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=13646&cn=soma 788&city=san francisco&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=13645&cn=archstone del mar heights&city=san diego&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=13634&cn=camden fallsgrove&city=rockville&st=md" to="/locations" push /> */}
        <Redirect
          from="/viewamenitysheet.cfm?cid=13631&cn=550 moreland apartments&city=santa clara&st=ca"
          to="/corporate-housing/furnished-apartments/ca/santaclara"
          push
        />
        {/* <Redirect from="/viewamenitysheet.cfm?cid=13627&cn=the wyatt&city=portland&st=or" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=13620&cn=canyon creek/or&city=wilsonville&st=or" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=13619&cn=deveraux glen&city=portland&st=or" to="/locations" push /> */}
        <Redirect
          from="/viewamenitysheet.cfm?cid=13617&cn=kearney plaza&city=portland&st=or"
          to="/corporate-housing/furnished-apartments/oregon/portland"
          push
        />
        <Redirect
          from="/viewamenitysheet.cfm?cid=13616&cn=burlington tower&city=portland&st=or"
          to="/corporate-housing/furnished-apartments/oregon/portland"
          push
        />
        <Redirect
          from="/viewamenitysheet.cfm?cid=13612&cn=10th @ hoyt&city=portland&st=or"
          to="/corporate-housing/furnished-apartments/oregon/portland"
          push
        />
        {/* <Redirect from="/viewamenitysheet.cfm?cid=13610&cn=avana orenco station&city=hillsboro&st=or" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=13599&cn=the whitney @ bethesda theatre&city=bethesda&st=md" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=13556&cn=pinnacle at fullerton&city=fullerton&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=13542&cn=2400 m street apartments&city=washington&st=dc" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=13466&cn=edgewater san francisco&city=san francisco&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=13454&cn=camden tuscany&city=san diego&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=13426&cn=residences at rollins ridge&city=rockville&st=md" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=13395&cn=avalon del mar station&city=pasadena&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=13352&cn=bay tree&city=los gatos&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=13351&cn=avalon at bear hill&city=waltham&st=ma" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=13345&cn=camden sierra at otay ranch&city=chula vista&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=13339&cn=masons keepe&city=manassas&st=va" to="/locations" push /> */}
        <Redirect
          from="/viewamenitysheet.cfm?cid=13337&cn=camden fairfax corner&city=fairfax&st=va"
          to="/corporate-housing/furnished-apartments/va/fairfax"
          push
        />
        {/* <Redirect from="/viewamenitysheet.cfm?cid=13299&cn=eaves pleasanton&city=pleasanton&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=13268&cn=aqua via&city=oakland&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=13255&cn=sterling heights&city=vancouver&st=wa" to="/locations" push /> */}
        <Redirect
          from="/viewamenitysheet.cfm?cid=13231&cn=allegro towers&city=san diego&st=ca"
          to="/corporate-housing/furnished-apartments/ca/sandiego"
          push
        />
        {/* <Redirect from="/viewamenitysheet.cfm?cid=13222&cn=ao santa monica&city=santa monica&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=13203&cn=bel air fairway apartments&city=san ramon&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=13197&cn=park central/concord&city=concord&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=13136&cn=trio&city=pasadena&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=13134&cn=the terrace at paseo colorado&city=pasadena&st=ca" to="/locations" push /> */}
        <Redirect
          from="/viewamenitysheet.cfm?cid=13123&cn=regents la jolla&city=la jolla&st=ca"
          to="/corporate-housing/furnished-apartments/ca/sandiego"
          push
        />
        {/* <Redirect from="/viewamenitysheet.cfm?cid=13112&cn=arpeggio&city=pasadena&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=13111&cn=acappella&city=pasadena&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=13105&cn=archstone pasadena&city=pasadena&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=13100&cn=biltmore&city=cupertino&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=13096&cn=archstone redmond campus&city=redmond&st=wa" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=13087&cn=camden harborview&city=long beach&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=13078&cn=siena villas&city=elk grove&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=13046&cn=venu at galleria&city=roseville&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=13032&cn=the edge at fairfax corner&city=fairfax&st=va" to="/locations" push /> */}
        {/* 900 */}
        {/* <Redirect from="/viewamenitysheet.cfm?cid=13000&cn=residences at congressional vi&city=rockville&st=md" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=12995&cn=sendero apartments&city=huntington beach&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=12955&cn=morgan park&city=vacaville&st=ca	" to="/locations" push /> */}
        <Redirect
          from="/viewamenitysheet.cfm?cid=12921&cn=1401 south joyce apartments&city=arlington&st=va"
          to="/corporate-housing/furnished-apartments/va/arlington"
          push
        />
        {/* <Redirect from="/viewamenitysheet.cfm?cid=12872&cn=skyline terrace&city=burlingame&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=12864&cn=vineyards at valley view&city=el dorado hills&st=ca" to="/locations" push /> */}
        <Redirect
          from="/viewamenitysheet.cfm?cid=12845&cn=avalon mission bay phase i&city=san francisco&st=ca"
          to="/corporate-housing/furnished-apartments/ca/sanfrancisco"
          push
        />
        {/* <Redirect from="/viewamenitysheet.cfm?cid=12843&cn=archstone fremont center&city=fremont&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=12838&cn=nob hill chateau&city=san francisco&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=12834&cn=metropolitan&city=san mateo&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=12780&cn=landing at jack london square&city=oakland&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=12776&cn=latitudes apartments&city=virginia beach&st=va" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=12746&cn=sterling ranch&city=el dorado hills&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=12740&cn=avalon at traville&city=rockville&st=md" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=12735&cn=metropolitan at bethesda&city=bethesda&st=md" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=12734&cn=post fallsgrove&city=rockville&st=md" to="/locations" push /> */}
        <Redirect
          from="/viewamenitysheet.cfm?cid=12707&cn=allure at scripps ranch&city=san diego&st=ca"
          to="/corporate-housing/furnished-apartments/ca/sandiego"
          push
        />
        {/* <Redirect from="/viewamenitysheet.cfm?cid=12668&cn=abbotts run&city=alexandria&st=va" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=12639&cn=olympic village - montana&city=billings&st=mt" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=12634&cn=fairway hills&city=rapid city&st=sd" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=12613&cn=huntington apartments&city=boise&st=id" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=12606&cn=the vinings at christiana&city=newark&st=de" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=12594&cn=berkshires at rock spring&city=bethesda&st=md" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=12590&cn=highland court&city=west monroe&st=la" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=12561&cn=stonehaven&city=columbia&st=md" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=12552&cn=marlin cove&city=foster city&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=12536&cn=the gramercy at town center&city=columbia&st=md" to="/locations" push /> */}
        <Redirect
          from="/viewamenitysheet.cfm?cid=12535&cn=avalon at fairway hills&city=columbia&st=md"
          to="/corporate-housing/furnished-apartments/md/columbia"
          push
        />
        <Redirect
          from="/viewamenitysheet.cfm?cid=12491&cn=1800 oak&city=arlington&st=va"
          to="/corporate-housing/furnished-apartments/va/arlington"
          push
        />
        {/* <Redirect from="/viewamenitysheet.cfm?cid=12481&cn=keswick park&city=crofton&st=md" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=12420&cn=amli at riverpark&city=norcross&st=ga" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=12418&cn=prado apartments&city=glendale&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=12309&cn=courts of avalon&city=pikesville&st=md" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=12301&cn=carlyle mill&city=alexandria&st=va" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=12009&cn=sussex at kingstowne&city=alexandria&st=va" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=11924&cn=meridian at braddock station&city=alexandria&st=va" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=11914&cn=ava newport&city=costa mesa&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=11891&cn=serenity at larkspur&city=larkspur&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=11866&cn=the paramount&city=san francisco&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=11837&cn=amador village&city=hayward&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=11753&cn=the allegro at jack london squ&city=oakland&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=11701&cn=columbia town center&city=columbia&st=md" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=11689&cn=cherry orchard&city=sunnyvale&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=11684&cn=avalon towers on the peninsula&city=mountain view&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=11594&cn=park place at san mateo&city=san mateo&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=11593&cn=residences at springfield stat&city=springfield&st=va" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=11496&cn=ashton woods&city=ellicott city&st=md" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=11428&cn=monte vista&city=honolulu&st=hi" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=11407&cn=bayside village&city=san francisco&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=11309&cn=aqua marina del rey&city=marina del rey&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=11299&cn=city place&city=pasadena&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=11253&cn=chancery square&city=morristown&st=nj" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=11047&cn=milestone apartments&city=germantown&st=md" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=10993&cn=the courts of devon&city=gaithersburg&st=md" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=10891&cn=camden russett&city=laurel&st=md" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=10828&cn=alicante&city=aliso viejo&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=10799&cn=alize&city=aliso viejo&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=10719&cn=alborada&city=fremont&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=10695&cn=meridian at ballston&city=arlington&st=va" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=10682&cn=101 san fernando&city=san jose&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=10626&cn=meridian at pentagon city&city=arlington&st=va" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=10618&cn=avalon reston landing&city=reston&st=va" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=10588&cn=meridian at bowie&city=bowie&st=md" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=10553&cn=la salle apartments&city=beaverton&st=or" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=10322&cn=center pointe&city=beaverton&st=or" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=1031&cn=south beach marina apartments&city=san francisco&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=10217&cn=remington dulles town center&city=dulles&st=va" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=10084&cn=arioso&city=cupertino&st=ca" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=10077&cn=eaves fairfax towers&city=fairfax&st=va" to="/locations" push />
						<Redirect from="/viewamenitysheet.cfm?cid=10070&cn=brookside estates&city=andover&st=ma" to="/locations" push /> */}
        <Redirect from="/utah-furnished-apartments.cfm" to="/locations" push />
        <Redirect from="/user/suiteamerica" to="/locations" push />
        {/* <Redirect from="/user/suiteamerica?feature=mhum" to="/locations" push /> */}
        <Redirect from="/travelmanagers.cfm" to="/locations" push />
        <Redirect
          from="/things-to-do-in-silicon-valley.cfm"
          to="/locations"
          push
        />
        <Redirect from="/testimonials.cfm" to="/locations" push />
        <Redirect from="/supplier-diversity-policy.cfm" to="/locations" push />
        <Redirect from="/superbowl-50-reservations.cfm" to="/locations" push />
        {/* <Redirect from="/suitevideos.cfm?id=intro to suiteamerica" to="/locations" push />
						<Redirect from="/suitevideos.cfm?id=avalon mission bay" to="/locations" push /> */}
        <Redirect from="/suitevideos.cfm" to="/locations" push />
        <Redirect from="/suiteamericateam.cfm" to="/locations" push />
        <Redirect from="/story-campaign-v2.cfm" to="/locations" push />
        <Redirect from="/story-campaign.cfm" to="/locations" push />
        <Redirect from="/specials.cfm" to="/locations" push />
        <Redirect
          from="/southern-california-furnished-apartments.cfm"
          to="/locations"
          push
        />
        <Redirect
          from="/slideshow-the-residences-at-springfield-station-i.cfm"
          to="/locations"
          push
        />
        <Redirect
          from="/slideshow-the-monterey-business.cfm"
          to="/locations"
          push
        />
        <Redirect
          from="/slideshow-the-bartlett-business.cfm"
          to="/locations"
          push
        />
        <Redirect
          from="/slideshow-the-acadia-at-metropolitan-park-business.cfm"
          to="/locations"
          push
        />
        <Redirect
          from="/slideshow-spruce-standardapple.cfm"
          to="/locations"
          push
        />
        <Redirect from="/slideshow-solaire-basic.cfm" to="/locations" push />
        <Redirect from="/slideshows.cfm" to="/locations" push />
        <Redirect
          from="/slideshow-nob-hill-penthouse.cfm"
          to="/locations"
          push
        />
        <Redirect from="/slideshow-m-flats-business.cfm" to="/locations" push />
        <Redirect
          from="/slideshow-meridian-at-pentagon-city-business.cfm"
          to="/locations"
          push
        />
        <Redirect
          from="/slideshow-central-place-business.cfm"
          to="/locations"
          push
        />
        <Redirect
          from="/slideshow-blvd-reston-station-business.cfm"
          to="/locations"
          push
        />
        <Redirect
          from="/slideshow-avalon-park-crest.cfm"
          to="/locations"
          push
        />
        <Redirect
          from="/slideshow-1401-south-joyce-business-613.cfm"
          to="/locations"
          push
        />
        <Redirect
          from="/slideshow-1401-joyce-on-pentagon-row.cfm"
          to="/locations"
          push
        />
        {/* 1000*/}
        <Redirect
          from="/slideshow-1301-thomas-circle-ambassador.cfm"
          to="/locations"
          push
        />
        <Redirect
          from="/silicon-valley-transportation.cfm"
          to="/locations"
          push
        />
        <Redirect from="/silicon-valley-restaurants.cfm" to="/locations" push />
        <Redirect
          from="/silicon-valley-furnished-apartments.cfm"
          to="/corporate-housing/furnished-apartments/siliconvalley"
          push
        />
        <Redirect from="/short-term-rentals-san-francisco.cfm" to="/" push />
        <Redirect from="/short-term-lodging.cfm" to="/" push />
        <Redirect from="/sharebutton.to" to="/" push />
        <Redirect from="/services-destination-services.cfm" to="/" push />
        <Redirect from="/services.cfm" to="/media-kit-our-services" push />
        <Redirect from="/requestreservationcallme.cfm" to="/" push />
        <Redirect from="/requestreservation.cfm" to="/" push />
        {/* <Redirect from="/requestreservation.cfm?cn=towne center at englewood&id=16051&city=englewood&st=nj" to="/" push />
						<Redirect from="/requestreservation.cfm?cn=skyline terrace&id=12872&city=burlingame&st=ca" to="/" push />
						<Redirect from="/requestreservation.cfm?cn=regents la jolla&id=13123&city=la jolla&st=ca" to="/" push />
						<Redirect from="/requestreservation.cfm?cn=avalon park crest&id=15952&city=tysons corner&st=va" to="/" push />
						<Redirect from="/requestreservation.cfm?cn=avalon irvine&id=14020&city=irvine&st=ca" to="/" push />
						<Redirect from="/requestreservation.cfm?cn=allegro towers&id=13231&city=san diego&st=ca" to="/" push />
						<Redirect from="/requestreservation.cfm?cn=acappella&id=13111&city=pasadena&st=ca" to="/" push />
						<Redirect from="/requestreservation.cfm?cn=38 dolores&id=16846&city=san francisco&st=ca" to="/" push />
						<Redirect from="/requestreservation.cfm?cn=2000 post apartments&id=5294&city=san francisco&st=ca" to="/" push />
						<Redirect from="/requestreservation.cfm?cn=1401 south joyce apartments&id=12921&city=arlington&st=va" to="/" push />
						<Redirect from="/requestreservation.cfm?cn=1301 thomas circle&id=14109&city=washington&st=dc" to="/" push />
						<Redirect from="/requestreservation.cfm?cn=121 tasman&id=16496&city=san jose&st=ca" to="/" push />
						<Redirect from="/requestreservation.cfm?cn=10th @ hoyt&id=13612&city=portland&st=or" to="/" push />
						<Redirect from="/requestreservation.cfm?cn=101 san fernando&id=10682&city=san jose&st=ca" to="/" push />
						<Redirect from="/requestreservation.cfm?cn=100 capitol yards&id=17816&city=washington&st=dc" to="/" push />
						<Redirect from="/requestreservation.cfm?cid=5294&cn=2000 post apartments&city=san francisco&st=ca" to="/" push />
						<Redirect from="/requestreservation.cfm?cid=13612&cn=10th @ hoyt&city=portland&st=or" to="/" push />
						<Redirect from="/requestreservation.cfm" to="/" push /> */}
        <Redirect from="/rentalcar.cfm" to="/" push />
        <Redirect from="/relotoformtest.cfm" to="/" push />
        <Redirect from="/relotoform.cfm" to="/" push />
        <Redirect from="/register_thanks.cfm" to="/" push />
        {/* <Redirect from="/register.cfm?register=register+now" to="/requestReservation" push /> */}
        {/* <Redirect from="/register.cfm" to="/" push /> */}
        <Redirect from="/pts.cfm" to="/" push />
        <Redirect from="/privacy-policy.cfm" to="/" push />
        <Redirect from="/preferred-transportation-services.cfm" to="/" push />
        <Redirect from="/oregon-furnished-apartments.cfm" to="/" push />
        <Redirect from="/order_service.cfm" to="/" push />
        <Redirect from="/order_included.cfm" to="/" push />
        <Redirect
          from="/online-booking/reservationpage/index.cfm"
          to="/"
          push
        />
        {/* <Redirect from="/online-booking/reservationpage/index.cfm?wcid=16823&complexname=venn on market&cadd1=1844 market street&ccity=san francisco&cst=ca&czip=94102&jpgname=vennonmarket&ssize=1x1&rate=263.000000&petsok=y&wd=y&sp=&ef=y&ch=&fireplace=&con=&arrival=02/15/2016&departure=02/29/2016&masterordnum=228413" to="/" push /> */}
        <Redirect from="/online-booking/index.cfm" to="/" push />
        <Redirect from="/navy-housing.cfm" to="/" push />
        <Redirect from="/military.cfm" to="/" push />
        <Redirect
          from="/mediakit.cfm?page=whoweare"
          to="/media-kit-who-we-are"
          push
        />
        {/* <Redirect from="/mediakit.cfm?page=whatwedo" to="/" push /> */}
        <Redirect
          from="/mediakit.cfm?page=team"
          to="/media-kit-leadership-team"
          push
        />
        {/* <Redirect from="/mediakit.cfm?page=solutions" to="/" push />
						<Redirect from="/mediakit.cfm?page=press" to="/" push />
						<Redirect from="/mediakit.cfm?page=location" to="/" push />
						<Redirect from="/mediakit.cfm?page=home" to="/" push />
						<Redirect from="/mediakit.cfm?page=factsheet" to="/" push />
						<Redirect from="/mediakit.cfm?page=difference" to="/" push /> */}
        <Redirect from="/mediakit.cfm" to="/media-kit" push />
        <Redirect from="/maryland-furnished-apartments.cfm" to="/" push />
        <Redirect from="/login_request.cfm" to="/requestReservation" push />
        <Redirect from="/login.cfm" to="/" push />
        {/* <Redirect from="/locations.cfm?cfid=15664803&cftoken=85939542" to="/" push /> */}
        <Redirect from="/locations.cfm" to="/" push />
        <Redirect from="/living-in-silicon-valley.cfm" to="/" push />
        <Redirect from="/intranet-home" to="/" push />
        <Redirect from="/intern-housing-program.cfm" to="/" push />
        <Redirect from="/index-jpl.cfm" to="/" push />
        <Redirect from="/index.cfm" to="/" push />
        <Redirect from="/guestportalbody.cfm" to="/" push />
        <Redirect from="/guestportal" to="/" push />
        <Redirect from="/gsa_perdiem.cfm" to="/" push />
        <Redirect from="/grandincluded.cfm" to="/" push />
        <Redirect
          from="/governmentcontracting.cfm"
          to="/government-housing"
          push
        />
        <Redirect from="/government.cfm" to="/government-housin" push />
        <Redirect from="/furniture.cfm" to="/" push />
        <Redirect from="/ftime-password.cfm" to="/" push />
        <Redirect from="/form_email.cfm" to="/" push />
        {/* <Redirect from="/form_email.cfm?form=service" to="/" push /> */}
        <Redirect from="/fivestar" to="/" push />
        <Redirect from="/federalcivilian.cfm" to="/government-housing" push />
        <Redirect from="/faq.cfm" to="/" push />
        <Redirect from="/extra-new.cfm" to="/" push />
        <Redirect from="/extra.cfm" to="/" push />
        <Redirect from="/executives.cfm" to="/" push />
        <Redirect from="/entertainment.cfm" to="/" push />
        <Redirect from="/employment.cfm" to="/career-opportunities" push />
        <Redirect from="/education-internship.cfm" to="/" push />
        <Redirect from="/ds-video.cfm" to="/" push />
        <Redirect from="/drivers-schedule" to="/" push />
        <Redirect
          from="/destination-services-our-tools.cfm"
          to="/corporation-destination-services"
          push
        />
        <Redirect
          from="/destination-services-our-services.cfm"
          to="/corporation-destination-services"
          push
        />
        <Redirect from="/destination-services-our-family.cfm" to="/" push />
        <Redirect from="/destination-services-our-dedication.cfm" to="/" push />
        <Redirect from="/destination-services-contact-us.cfm" to="/" push />
        <Redirect
          from="/destination-services.cfm"
          to="/corporation-destination-services"
          push
        />
        <Redirect from="/deal-of-the-day/texas.cfm" to="/" push />
        <Redirect from="/deal-of-the-day/southern-california.cfm" to="/" push />
        <Redirect from="/deal-of-the-day/silicon-valley.cfm" to="/" push />
        <Redirect from="/deal-of-the-day/seattle.cfm" to="/" push />
        <Redirect from="/deal-of-the-day/oregon.cfm" to="/" push />
        <Redirect from="/deal-of-the-day/northern-california.cfm" to="/" push />
        <Redirect from="/deal-of-the-day/north-carolina.cfm" to="/" push />
        <Redirect from="/deal-of-the-day/dod-original.cfm" to="/" push />
        <Redirect from="/deal-of-the-day/dc-metro-area.cfm" to="/" push />
        <Redirect from="/deal-of-the-day.cfm" to="/" push />
        {/* 1100*/}
        <Redirect
          from="/dc-metro-furnished-apartments.cfm"
          to="/corporate-housing/furnished-apartments/dc/washington"
          push
        />
        <Redirect from="/csp2018.cfm" to="/" push />
        <Redirect from="/corporate-housing-cupertino.cfm" to="/" push />
        <Redirect from="/corporatehousing.cfm" to="/" push />
        <Redirect from="/contact.cfm" to="/contactUs" push />
        <Redirect from="/complex_area.cfm" to="/" push />
        {/* <Redirect from="/complex_area.cfm?zone=washington&cst=wa" to="/" push />
						<Redirect from="/complex_area.cfm?zone=utah&cst=ut" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?zone=southern cal&cst=ca&titletext=furnished apartments"
          to="/corporate-housing/furnished-apartments/ca/losangeles"
          push
        />
        <Redirect
          from="/complex_area.cfm?zone=southern cal&cst=ca"
          to="/corporate-housing/furnished-apartments/ca/losangeles"
          push
        />
        <Redirect
          from="/complex_area.cfm?zone=sf bay area&cst=ca&titletext=furnished apartments"
          to="/corporate-housing/furnished-apartments/bayarea"
          push
        />
        <Redirect
          from="/complex_area.cfm?zone=sf bay area&cst=ca"
          to="/corporate-housing/furnished-apartments/bayarea"
          push
        />
        {/* <Redirect from="/complex_area.cfm?zone=richmond&cst=va" to="/" push />
						<Redirect from="/complex_area.cfm?zone=other va cities&cst=va" to="/" push />
						<Redirect from="/complex_area.cfm?zone=other ca cities&cst=ca" to="/" push />
						<Redirect from="/complex_area.cfm?zone=oregon&cst=or" to="/" push />
						<Redirect from="/complex_area.cfm?zone=northern cal&cst=ca&titletext=furnished apartments" to="/" push />
						<Redirect from="/complex_area.cfm?zone=northern cal&cst=ca" to="/" push />
						<Redirect from="/complex_area.cfm?zone=northern cal&cst=&titletext=furnished apartments" to="/" push />
						<Redirect from="/complex_area.cfm?zone=northern cal" to="/" push />
						<Redirect from="/complex_area.cfm?zone=north carolina&cst=nc" to="/" push />
						<Redirect from="/complex_area.cfm?zone=new york&cst=ny" to="/" push />
						<Redirect from="/complex_area.cfm?zone=new jersey&cst=nj" to="/" push />
						<Redirect from="/complex_area.cfm?zone=massachusetts&cst=ma" to="/" push />
						<Redirect from="/complex_area.cfm?zone=florida&cst=fl" to="/" push />
						<Redirect from="/complex_area.cfm?zone=dc metro area&cst=va" to="/" push />
						<Redirect from="/complex_area.cfm?zone=dc metro area&cst=md" to="/" push />
						<Redirect from="/complex_area.cfm?zone=dc metro area&cst=dc" to="/" push />
						<Redirect from="/complex_area.cfm?zone=austin&cst=tx" to="/" push />
						<Redirect from="/complex_area.cfm?zone=all&cst=va" to="/" push />
						<Redirect from="/complex_area.cfm?zone=all&cst=tx" to="/" push />
						<Redirect from="/complex_area.cfm?zone=all&cst=nj" to="/" push />
						<Redirect from="/complex_area.cfm?zone=all&cst=md" to="/" push />
						<Redirect from="/complex_area.cfm?zone=all&cst=dc" to="/" push />
						<Redirect from="/complex_area.cfm?zone=all&cst=ca" to="/" push />
						<Redirect from="/complex_area.cfm?cst=wv" to="/" push />
						<Redirect from="/complex_area.cfm?cst=wa&titletext=washington furnished apartments" to="/" push />
						<Redirect from="/complex_area.cfm?cst=wa&titletext=furnished apartments" to="/" push />
						<Redirect from="/complex_area.cfm?cst=wa&titletext=corporate apartments" to="/" push />
						<Redirect from="/complex_area.cfm?cst=wa" to="/" push />
						<Redirect from="/complex_area.cfm?cst=va&titletext=corporate apartments" to="/" push />
						<Redirect from="/complex_area.cfm?cst=va" to="/" push />
						<Redirect from="/complex_area.cfm?cst=ut&titletext=furnished apartments" to="/" push />
						<Redirect from="/complex_area.cfm?cst=ut" to="/" push />
						<Redirect from="/complex_area.cfm?cst=tx&titletext=furnished apartments" to="/" push />
						<Redirect from="/complex_area.cfm?cst=tx" to="/" push />
						<Redirect from="/complex_area.cfm?cst=tn" to="/" push />
						<Redirect from="/complex_area.cfm?cst=sc" to="/" push />
						<Redirect from="/complex_area.cfm?cst=ri" to="/" push />
						<Redirect from="/complex_area.cfm?cst=pa" to="/" push />
						<Redirect from="/complex_area.cfm?cst=or&titletext=furnished apartments" to="/" push />
						<Redirect from="/complex_area.cfm?cst=or" to="/" push />
						<Redirect from="/complex_area.cfm?cst=oh" to="/" push />
						<Redirect from="/complex_area.cfm?cst=ny" to="/" push />
						<Redirect from="/complex_area.cfm?cst=nv" to="/" push />
						<Redirect from="/complex_area.cfm?cst=nm" to="/" push />
						<Redirect from="/complex_area.cfm?cst=nj&titletext=furnished apartments" to="/" push />
						<Redirect from="/complex_area.cfm?cst=nj" to="/" push />
						<Redirect from="/complex_area.cfm?cst=nh" to="/" push />
						<Redirect from="/complex_area.cfm?cst=nd" to="/" push />
						<Redirect from="/complex_area.cfm?cst=nc" to="/" push />
						<Redirect from="/complex_area.cfm?cst=mo" to="/" push />
						<Redirect from="/complex_area.cfm?cst=mn" to="/" push />
						<Redirect from="/complex_area.cfm?cst=mi" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?cst=md&titletext=corporate apartments"
          to="/corporation-corporate-housing"
          push
        />
        {/* <Redirect from="/complex_area.cfm?cst=md" to="/" push />
						<Redirect from="/complex_area.cfm?cst=ma" to="/" push />
						<Redirect from="/complex_area.cfm?cst=la" to="/" push />
						<Redirect from="/complex_area.cfm?cst=ky" to="/" push />
						<Redirect from="/complex_area.cfm?cst=in" to="/" push />
						<Redirect from="/complex_area.cfm?cst=il" to="/" push />
						<Redirect from="/complex_area.cfm?cst=id" to="/" push />
						<Redirect from="/complex_area.cfm?cst=hi" to="/" push />
						<Redirect from="/complex_area.cfm?cst=ga" to="/" push />
						<Redirect from="/complex_area.cfm?cst=fl" to="/" push />
						<Redirect from="/complex_area.cfm?cst=de" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?cst=dc"
          to="/corporate-housing/furnished-apartments/dc/washington"
          push
        />
        {/* <Redirect from="/complex_area.cfm?cst=ct" to="/" push />
						<Redirect from="/complex_area.cfm?cst=co" to="/" push />
						<Redirect from="/complex_area.cfm?cst=ca" to="/" push />
						<Redirect from="/complex_area.cfm?cst=az" to="/" push />
						<Redirect from="/complex_area.cfm?cst=ar" to="/" push />
						<Redirect from="/complex_area.cfm?cst=al" to="/" push />
						<Redirect from="/complex_area.cfm?cst=ak" to="/" push />
						<Redirect from="/complex_area.cfm?city=woodstock&cst=ga&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=woodland hills&cst=ca&zone=southern cal&area=los angeles" to="/" push />
						<Redirect from="/complex_area.cfm?city=woodbury&cst=mn&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=woodbridge&cst=va&zone=dc metro area&area=arlington/alexandria" to="/" push />
						<Redirect from="/complex_area.cfm?city=woodbridge&cst=va&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=winter haven&cst=fl&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=winston-salem&cst=nc&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=winchester&cst=va&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=wilsonville&cst=or&zone=oregon&area=oregon" to="/" push />
						<Redirect from="/complex_area.cfm?city=wilsonville&cst=or&zone=&area=oregon" to="/" push />
						<Redirect from="/complex_area.cfm?city=wilmington&cst=nc&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=williamsburg" to="/" push />
						<Redirect from="/complex_area.cfm?city=weston&cst=fl&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=westminster&cst=co&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=westlake village" to="/" push />
						<Redirect from="/complex_area.cfm?city=west monroe&cst=la&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=west los angeles&cst=ca&zone=southern cal&area=los angeles" to="/" push /> */}
        {/* 1200*/}
        {/* <Redirect from="/complex_area.cfm?city=west chester&cst=oh&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=waltham&cst=ma&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=walnut creek" to="/" push />
						<Redirect from="/complex_area.cfm?city=virginia beach&cst=va&zone=richmond&area=richmond" to="/" push />
						<Redirect from="/complex_area.cfm?city=virginia beach&cst=va&titletext=furnished apartments" to="/" push />
						<Redirect from="/complex_area.cfm?city=villa rica&cst=ga&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=viera&cst=fl&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=vienna&cst=va&zone=dc metro area&area=tysons/fairfax" to="/" push />
						<Redirect from="/complex_area.cfm?city=vancouver&cst=wa&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=vallejo&cst=ca&zone=sf bay area&area=oakland/alameda/emeryville" to="/" push />
						<Redirect from="/complex_area.cfm?city=vacaville&cst=ca&titletext=furnished apartments" to="/" push />
						<Redirect from="/complex_area.cfm?city=tysons corner&cst=va&zone=dc metro area&area=tysons/fairfax" to="/" push />
						<Redirect from="/complex_area.cfm?city=tucson&cst=az&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=towson&cst=md&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=tigard&cst=or&zone=&area=oregon" to="/" push />
						<Redirect from="/complex_area.cfm?city=thousand oaks&cst=ca&zone=southern cal&area=orange county" to="/" push />
						<Redirect from="/complex_area.cfm?city=the woodlands&cst=tx&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=tarrytown&cst=ny&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=sunrise&cst=fl&zone=&area=" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?city=sunnyvale&cst=ca&zone=sf bay area&area=silicon valley - north"
          to="/corporate-housing/furnished-apartments/ca/sunnyvale"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=sunnyvale&cst=ca&zone=sf bay area&area="
          to="/corporate-housing/furnished-apartments/ca/sunnyvale"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=sunnyvale&cst=ca&titletext=furnished apartments"
          to="/corporate-housing/furnished-apartments/ca/sunnyvale"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=sunnyvale"
          to="/corporate-housing/furnished-apartments/ca/sunnyvale"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=sterling&cst=va&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=stamford&cst=ct&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=st. petersburg&cst=fl&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=springfield&cst=va&zone=dc metro area&area=arlington/alexandria" to="/" push />
						<Redirect from="/complex_area.cfm?city=springfield&cst=va&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=spring&cst=tx&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=spartanburg&cst=sc&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=south jordan&cst=ut" to="/" push />
						<Redirect from="/complex_area.cfm?city=snellville&cst=ga&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=sioux falls&cst=sd&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=silverdale&cst=wa&zone=&area=washington" to="/" push />
						<Redirect from="/complex_area.cfm?city=silverdale&cst=wa&zone=&area=" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?city=silver spring&titletext=furnished apartments"
          to="/corporate-housing/furnished-apartments/md/silverspring"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=silver spring&titletext=extended stay" to="/" push />
						<Redirect from="/complex_area.cfm?city=silver spring&cst=md&zone=dc metro area&area=maryland" to="/" push />
						<Redirect from="/complex_area.cfm?city=silver spring&cst=md&zone=&area=" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?city=silver spring&cst=md&titletext=furnished apartments"
          to="/corporate-housing/furnished-apartments/md/silverspring"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=sherman&cst=tx&zone=austin&area=texas" to="/" push />
						<Redirect from="/complex_area.cfm?city=sherman&cst=tx&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=sheboygan&cst=wi&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=seattle&cst=wa&zone=washington&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=seattle&cst=wa&zone=&area=" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?city=seattle&cst=wa&titletext=furnished apartments"
          to="/corporate-housing/furnished-apartments/wa/seattle"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=santa rosa&cst=ca&zone=sf bay area&area=north bay / marin" to="/" push />
						<Redirect from="/complex_area.cfm?city=santa rosa&cst=ca&zone=sf bay area&area=" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?city=santa monica&cst=ca&zone=southern cal&area=los angeles"
          to="/corporate-housing/furnished-apartments/ca/losangeles"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=santa monica" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?city=santa clara&cst=ca&zone=sf bay area&area=silicon valley - south"
          to="/corporate-housing/furnished-apartments/ca/santaclara"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=santa clara&cst=ca&zone=sf bay area&area="
          to="/corporate-housing/furnished-apartments/ca/santaclara"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=santa clara&cst=ca&zone=&area=silicon valley - south"
          to="/corporate-housing/furnished-apartments/ca/santaclara"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=santa clara&cst=&zone=sf bay area&area=silicon valley - south" to="/" push />
						<Redirect from="/complex_area.cfm?city=santa ana&cst=ca&zone=southern cal&area=orange county" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?city=san ramon&cst=ca&zone=sf bay area&area=tri valley"
          to="/corporate-housing/furnished-apartments/bayarea"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=san ramon&cst=ca&zone=sf bay area&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=san mateo&sortorder=city&titletext=corporate" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?city=san mateo&cst=ca&zone=sf bay area&area=foster city/peninsula"
          to="/corporate-housing/furnished-apartments/bayarea"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=san mateo&cst=ca&zone=sf bay area&area="
          to="/corporate-housing/furnished-apartments/bayarea"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=san leandro&cst=ca&zone=sf bay area&area=hayward/fremont" to="/" push />
						<Redirect from="/complex_area.cfm?city=san jose&titletext=short term rentals" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?city=san jose&titletext=furnished apartments"
          to="/corporate-housing/furnished-apartments/ca/sanjose"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=san jose&pagetext=san jose&titletext=furnished apartments"
          to="/corporate-housing/furnished-apartments/ca/sanjose"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=san jose&pagetext=san jose&titletext=corporate apartments" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?city=san jose&cst=ca&zone=sf bay area&area=silicon valley - south"
          to="/corporate-housing/furnished-apartments/ca/sanjose"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=san jose&cst=ca&zone=sf bay area&area="
          to="/corporate-housing/furnished-apartments/ca/sanjose"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=san jose&cst=ca&zone=&area=silicon valley - south"
          to="/corporate-housing/furnished-apartments/ca/sanjose"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=san jose&cst=ca&titletext=furnished apartments&pagetext=san jose"
          to="/corporate-housing/furnished-apartments/ca/sanjose"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=san francisco&cst=ca&zone=sf bay area&area="
          to="/corporate-housing/furnished-apartments/ca/sanfrancisco"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=san francisco&cst=ca&titletext=furnished apartment"
          to="/corporate-housing/furnished-apartments/ca/sanfrancisco"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=san francisco&cst=ca&titletext=corporate apartments"
          to="/corporate-housing/furnished-apartments/ca/sanfrancisco"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=san diego&cst=ca&zone=southern cal&area=san diego"
          to="/corporate-housing/furnished-apartments/ca/sandiego"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=san diego&cst=ca&zone=southern cal&area="
          to="/corporate-housing/furnished-apartments/ca/sandiego"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=san diego&cst=ca&titletext=military lodging" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?city=san diego&cst=ca&titletext=furnished apartments"
          to="/corporate-housing/furnished-apartments/ca/sandiego"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=san bruno&cst=ca&zone=sf bay area&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=salt lake city&cst=ut&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=salt lake city&cst=ut&titletext=corporate housing" to="/" push />
						<Redirect from="/complex_area.cfm?city=sacramento&cst=ca&zone=northern cal&area=sacramento" to="/" push />
						<Redirect from="/complex_area.cfm?city=sacramento&cst=ca&titletext=furnished apartments" to="/" push />
						<Redirect from="/complex_area.cfm?city=royal oak&cst=mi&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=round rock&cst=tx&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=roswell&cst=ga&zone=&area=	" to="/" push />
						<Redirect from="/complex_area.cfm?city=roseville&zone=northern cal&cst=ca&titletext=furnished apartments" to="/" push />
						<Redirect from="/complex_area.cfm?city=roseville&cst=ca&zone=northern cal&area=sacramento" to="/" push />
						<Redirect from="/complex_area.cfm?city=rockville&cst=md&zone=dc metro area&area=maryland" to="/" push />
						<Redirect from="/complex_area.cfm?city=rockville&cst=md&zone=&area=maryland" to="/" push />
						<Redirect from="/complex_area.cfm?city=rockville&cst=md&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=rockville&cst=md&titletext=furnished apartments" to="/" push />
						<Redirect from="/complex_area.cfm?city=rock hill&cst=sc&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=roanoke&cst=va&titletext=furnished apartments" to="/" push />
						<Redirect from="/complex_area.cfm?city=roanoke&cst=tx&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=rio rancho&cst=nm&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=richmond&cst=va&titletext=furnished apartments" to="/" push />
						<Redirect from="/complex_area.cfm?city=richardson&cst=tx&zone=&area=" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?city=reston&cst=va&titletext=furnished apartments"
          to="/corporate-housing/furnished-apartments/va/reston"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=redwood city&cst=ca&zone=sf bay area&area=foster city/peninsula" to="/" push />
						<Redirect from="/complex_area.cfm?city=redmond&cst=wa&titletext=furnished apartments" to="/" push />
						<Redirect from="/complex_area.cfm?city=redlands&cst=ca&zone=southern cal&area=inland empire" to="/" push /> */}
        {/* 1300*/}
        {/* <Redirect from="/complex_area.cfm?city=redlands&cst=ca&zone=southern cal&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=reading&cst=pa&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=rapid city&cst=sd&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=rancho santa margarita&cst=ca&zone=southern cal&area=orange county" to="/" push />
						<Redirect from="/complex_area.cfm?city=rancho cucamonga&cst=ca&zone=southern cal&area=los angeles" to="/" push />
						<Redirect from="/complex_area.cfm?city=princeton&cst=nj&titletext=furnished apartments" to="/" push />
						<Redirect from="/complex_area.cfm?city=poughkeepsie&cst=ny&zone=&area=" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?city=portland&cst=or&zone=oregon&area=oregon"
          to="/corporate-housing/furnished-apartments/oregon/portland"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=portland&cst=or&zone=oregon&area="
          to="/corporate-housing/furnished-apartments/oregon/portland"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=portland&cst=or&zone=&area=oregon" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?city=portland&cst=or&zone=&area="
          to="/corporate-housing/furnished-apartments/oregon/portland"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=portland&cst=or&titletext=furnished apartments&pagetext=portland"
          to="/corporate-housing/furnished-apartments/oregon/portland"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=portland&cst=or&titletext=furnished apartments"
          to="/corporate-housing/furnished-apartments/oregon/portland"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=portland&cst=or&titletext=corporate housing"
          to="/corporate-housing/furnished-apartments/oregon/portland"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=portland"
          to="/corporate-housing/furnished-apartments/oregon/portland"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=pleasanton&cst=ca&zone=sf bay area&area=tri valley" to="/" push />
						<Redirect from="/complex_area.cfm?city=pleasanton&cst=ca&zone=sf bay area&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=pleasanton" to="/" push />
						<Redirect from="/complex_area.cfm?city=plano&cst=tx&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=plano" to="/" push />
						<Redirect from="/complex_area.cfm?city=plano" to="/" push />
						<Redirect from="/complex_area.cfm?city=petaluma&cst=ca&zone=sf bay area&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=pembroke pines&cst=fl&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=pasadena&cst=ca&zone=southern cal&area=los angeles" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?city=pasadena&cst=ca&titletext=furnished apartments"
          to="/corporate-housing/furnished-apartments/ca/losangeles"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=palo alto&cst=ca&zone=sf bay area&area=palo alto/mountain view" to="/" push />
						<Redirect from="/complex_area.cfm?city=palo alto&cst=ca&titletext=corporate housing" to="/" push />
						<Redirect from="/complex_area.cfm?city=oviedo&cst=fl&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=overland park&cst=ks&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=orange&cst=ca&zone=southern cal&area=orange county" to="/" push />
						<Redirect from="/complex_area.cfm?city=oceanside&cst=ca&zone=southern cal&area=san diego" to="/" push />
						<Redirect from="/complex_area.cfm?city=oakland&cst=ca&zone=sf bay area&area=oakland/alameda/emeryville" to="/" push />
						<Redirect from="/complex_area.cfm?city=oakland&cst=ca&titletext=corporate housing" to="/" push />
						<Redirect from="/complex_area.cfm?city=novato&cst=ca&zone=sf bay area&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=north hollywood&cst=ca&zone=southern cal&area=los angeles" to="/" push />
						<Redirect from="/complex_area.cfm?city=norfolk&cst=va&zone=richmond&area=richmond" to="/" push />
						<Redirect from="/complex_area.cfm?city=norfolk&cst=va&titletext=furnished apartments" to="/" push />
						<Redirect from="/complex_area.cfm?city=norcross&cst=ga&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=niceville&cst=fl&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=newton&cst=ma&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=newport news&cst=va&zone=richmond&area=richmond" to="/" push />
						<Redirect from="/complex_area.cfm?city=newport beach&cst=ca&zone=southern cal&area=orange county" to="/" push />
						<Redirect from="/complex_area.cfm?city=newark&cst=de&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=newark" to="/" push />
						<Redirect from="/complex_area.cfm?city=new york&cst=ny&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=naples&cst=fl&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=naperville&cst=il&zone=&area=" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?city=mountain view&cst=ca&zone=sf bay area&area=palo alto/mountain view"
          to="/corporate-housing/furnished-apartments/bayarea"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=mountain view&cst=ca&zone=sf bay area&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=mountain view&cst=ca&titletext=extended stay" to="/" push />
						<Redirect from="/complex_area.cfm?city=morristown&cst=nj&titletext=furnished apartments" to="/" push />
						<Redirect from="/complex_area.cfm?city=monterey&cst=ca&zone=sf bay area&area=palo alto/mountain view" to="/" push />
						<Redirect from="/complex_area.cfm?city=mobile&cst=al&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=milpitas&cst=ca&zone=sf bay area&area=silicon valley - south" to="/" push />
						<Redirect from="/complex_area.cfm?city=midlothian" to="/" push />
						<Redirect from="/complex_area.cfm?city=metairie&cst=la&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=melville&cst=ny&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=mechanicsville&cst=va&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=mclean&cst=va&zone=dc metro area&area=tysons/fairfax" to="/" push />
						<Redirect from="/complex_area.cfm?city=mason&cst=oh&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=marlborough&cst=ma&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=marlborough" to="/" push />
						<Redirect from="/complex_area.cfm?city=marina del rey&cst=ca&zone=southern cal&area=los angeles" to="/" push />
						<Redirect from="/complex_area.cfm?city=manassas&cst=va&zone=dc metro area&area=herndon/reston" to="/" push />
						<Redirect from="/complex_area.cfm?city=madison" to="/" push />
						<Redirect from="/complex_area.cfm?city=loveland&cst=co&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=los gatos&cst=ca&zone=sf bay area&area=silicon valley - south" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?city=los angeles&cst=ca&zone=southern cal&area=los angeles"
          to="/corporate-housing/furnished-apartments/ca/losangeles"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=lorton&cst=va&zone=dc metro area&area=herndon/reston" to="/" push />
						<Redirect from="/complex_area.cfm?city=long beach&cst=ca&zone=southern cal&area=los angeles" to="/" push />
						<Redirect from="/complex_area.cfm?city=livermore" to="/" push />
						<Redirect from="/complex_area.cfm?city=littleton&cst=co&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=lisle&cst=il&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=lehi&cst=ut&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=leesburg&cst=va&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=lees summit&cst=mo&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=laurel&titletext=extended stay" to="/" push />
						<Redirect from="/complex_area.cfm?city=laurel&cst=md&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=lakeville&cst=mn&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=lake oswego&cst=or&zone=oregon&area=oregon" to="/" push />
						<Redirect from="/complex_area.cfm?city=lake oswego&cst=or&titletext=furnished apartments" to="/" push />
						<Redirect from="/complex_area.cfm?city=lake mary&cst=fl&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=lake forest&cst=ca&zone=southern cal&area=orange county" to="/" push />
						<Redirect from="/complex_area.cfm?city=lake bluff&cst=il&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=laguna niguel&cst=ca&zone=southern cal&area=orange county" to="/" push />
						<Redirect from="/complex_area.cfm?city=la mesa&cst=ca&zone=southern cal&area=san diego" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?city=la jolla&cst=ca&zone=southern cal&area=san diego"
          to="/corporate-housing/furnished-apartments/ca/sandiego"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=la jolla&cst=ca&zone=southern cal&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=knoxville&cst=tn&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=kissimmee&cst=fl&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=kingwood&cst=tx&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=kingwood" to="/" push />
						<Redirect from="/complex_area.cfm?city=kernersville&cst=nc&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=kennesaw&cst=ga&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=keller&cst=tx&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=keller" to="/" push />
						<Redirect from="/complex_area.cfm?city=jersey city&cst=nj&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=jersey city&cst=nj&titletext=furnished apartments" to="/" push />
						<Redirect from="/complex_area.cfm?city=irving&cst=tx&zone=&area=" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?city=irvine&cst=ca&zone=southern cal&area=orange county"
          to="/corporate-housing/furnished-apartments/orangecounty"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=irvine&cst=ca&titletext=furnished apartments" to="/" push /> */}
        {/* 1400*/}
        {/* <Redirect from="/complex_area.cfm?city=idaho falls&cst=id&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=huntsville" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?city=huntington beach&cst=ca&zone=southern cal&area=orange county"
          to="/corporate-housing/furnished-apartments/orangecounty"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=huntersville&cst=nc&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=houston&cst=tx&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=honolulu" to="/" push />
						<Redirect from="/complex_area.cfm?city=hoboken&cst=nj&zone=new jersey&area=new jersey" to="/" push />
						<Redirect from="/complex_area.cfm?city=hoboken&cst=nj&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=hoboken&cst=nj&titletext=furnished apartments" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?city=hillsboro&cst=or&zone=oregon&area=oregon"
          to="/locations"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=hillsboro&cst=or&zone=&area=oregon" to="/" push />
						<Redirect from="/complex_area.cfm?city=hillsboro&cst=or&zone=&area=" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?city=hillsboro&cst=or&titletext=furnished apartments"
          to="/locations"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=high point&cst=nc&zone=&area=" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?city=herndon&cst=va&zone=dc metro area&area=herndon/reston"
          to="/corporate-housing/furnished-apartments/va/herndon"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=hayward&cst=ca&zone=sf bay area&area=hayward/fremont" to="/" push />
						<Redirect from="/complex_area.cfm?city=harrisonburg&cst=va&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=groton&cst=ct&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=greensboro&cst=nc&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=grapevine&cst=tx&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=glendale&cst=ca&zone=southern cal&area=los angeles" to="/" push />
						<Redirect from="/complex_area.cfm?city=gilbert&cst=az&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=germantown&cst=tn&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=germantown&cst=md&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=gaithersburg&cst=md&titletext=furnished apartments" to="/" push />
						<Redirect from="/complex_area.cfm?city=gainesville&cst=va&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=gainesville&cst=fl&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=fullerton&cst=ca&zone=southern cal&area=orange county" to="/" push />
						<Redirect from="/complex_area.cfm?city=frisco&cst=tx&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=fremont&cst=ca&zone=sf bay area&area=hayward/fremont" to="/" push />
						<Redirect from="/complex_area.cfm?city=fremont&cst=ca&zone=sf bay area&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=fremont&cst=ca&titletext=extended stay" to="/" push />
						<Redirect from="/complex_area.cfm?city=fremont" to="/" push />
						<Redirect from="/complex_area.cfm?city=fredericksburg&cst=va&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=frederick&cst=md&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=franklin&cst=tn&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=framingham&cst=ma&zone=&area=" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?city=foster city&cst=ca&zone=sf bay area&area=foster city/peninsula"
          to="/corporate-housing/furnished-apartments/ca/fostercity"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=foster city&cst=ca&zone=sf bay area&area="
          to="/corporate-housing/furnished-apartments/ca/fostercity"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=fort myers&cst=fl&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=fort mill&cst=sc&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=folsom&cst=ca&zone=northern cal&area=sacramento" to="/" push />
						<Redirect from="/complex_area.cfm?city=folsom&cst=ca&titletext=furnished apartments" to="/" push />
						<Redirect from="/complex_area.cfm?city=flower mound&cst=tx&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=florence&cst=sc&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=florence&cst=ky&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=fishers&cst=in&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=fernandina beach&cst=fl&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=falls church&cst=va&zone=dc metro area&area=tysons/fairfax" to="/" push />
						<Redirect from="/complex_area.cfm?city=fairfield&cst=ca&zone=northern cal&area=sacramento" to="/" push />
						<Redirect from="/complex_area.cfm?city=fairfax&cst=va&titletext=furnished apartments" to="/" push />
						<Redirect from="/complex_area.cfm?city=escondido&cst=ca&zone=southern cal&area=san diego" to="/" push />
						<Redirect from="/complex_area.cfm?city=englewood&cst=nj&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=encinitas&cst=ca&zone=southern cal&area=san diego" to="/" push />
						<Redirect from="/complex_area.cfm?city=ellicott city&cst=md&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=elk grove&cst=ca&titletext=furnished apartments" to="/" push />
						<Redirect from="/complex_area.cfm?city=el dorado hills&cst=ca&zone=northern cal&area=sacramento" to="/" push />
						<Redirect from="/complex_area.cfm?city=el dorado hills&cst=ca&titletext=furnished apartments" to="/" push />
						<Redirect from="/complex_area.cfm?city=east stroudsburg&cst=pa&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=eagan&cst=mn&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=duluth&cst=ga&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=dulles&cst=va&zone=dc metro area&area=herndon/reston" to="/" push />
						<Redirect from="/complex_area.cfm?city=dublin&cst=oh&zone=&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=dublin&cst=ca&zone=sf bay area&area=tri valley" to="/" push />
						<Redirect from="/complex_area.cfm?city=dublin&cst=ca&zone=sf bay area&area=" to="/locations" push />
						<Redirect from="/complex_area.cfm?city=decatur&cst=ga&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=davis&cst=ca&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=daly city&cst=ca&zone=sf bay area&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=cupertino&titletext=extended stay" to="/" push />
						<Redirect from="/complex_area.cfm?city=cupertino&cst=ca&zone=sf bay area&area=silicon valley - north" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?city=cupertino&cst=ca&zone=sf bay area&area="
          to="/corporate-housing/furnished-apartments/ca/cupertino"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=cupertino"
          to="/corporate-housing/furnished-apartments/ca/cupertino"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=crofton&cst=md&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=crestview" to="/" push />
						<Redirect from="/complex_area.cfm?city=costa mesa&cst=ca&zone=southern cal&area=orange county" to="/" push />
						<Redirect from="/complex_area.cfm?city=corvallis&cst=or&zone=&area=oregon" to="/" push />
						<Redirect from="/complex_area.cfm?city=conroe&cst=tx&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=concord&cst=ca&zone=sf bay area&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=columbus&cst=ga&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=columbia&cst=md&zone=&area=" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?city=columbia&cst=md&titletext=furnished apartments"
          to="/corporate-housing/furnished-apartments/md/columbia"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=college park&cst=md&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=clifton park&cst=ny&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=clearwater&cst=fl&zone=&area=" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?city=chula vista&cst=ca&zone=southern cal&area=san diego"
          to="/corporate-housing/furnished-apartments/ca/sandiego"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=chester&cst=va&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=chesapeake&cst=va&zone=richmond&area=richmond" to="/" push />
						<Redirect from="/complex_area.cfm?city=chesapeake" to="/" push />
						<Redirect from="/complex_area.cfm?city=charlottesville&cst=va&zone=richmond&area=richmond" to="/" push />
						<Redirect from="/complex_area.cfm?city=charlotte&cst=nc&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=charleston&cst=sc&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=chandler" to="/" push />
						<Redirect from="/complex_area.cfm?city=centreville&cst=va&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=cary&cst=nc&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=carlsbad&cst=ca&zone=southern cal&area=san diego" to="/" push />
						<Redirect from="/complex_area.cfm?city=carlsbad" to="/" push />
						<Redirect from="/complex_area.cfm?city=campbell&cst=ca&zone=sf bay area&area=silicon valley - south" to="/" push />
						<Redirect from="/complex_area.cfm?city=burlington&cst=nc&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=burlington&cst=ma&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=burlingame&cst=ca&zone=sf bay area&area=foster city/peninsula" to="/" push /> */}
        {/* 1500*/}
        {/* <Redirect from="/complex_area.cfm?city=burlingame&cst=ca&zone=sf bay area&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=burbank&cst=ca&zone=southern cal&area=los angeles" to="/" push />
						<Redirect from="/complex_area.cfm?city=buffalo grove&cst=il&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=broomfield&cst=co&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=brentwood&cst=tn&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=bremerton&cst=wa&titletext=furnished apartments" to="/" push />
						<Redirect from="/complex_area.cfm?city=bowie&cst=md&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=boston&cst=ma&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=boise&cst=id&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=boise" to="/" push />
						<Redirect from="/complex_area.cfm?city=bloomington&cst=il&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=birmingham&cst=mi&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=billings&cst=mt&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=billerica&cst=ma&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=beverly hills&cst=ca&zone=other ca cities&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=bethesda&titletext=extended stay" to="/" push />
						<Redirect from="/complex_area.cfm?city=bethesda&cst=md&zone=&area=maryland" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?city=bethesda&cst=md&zone=&area="
          to="/corporate-housing/furnished-apartments/md/northbethesda"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=bethesda&cst=md&titletext=furnished apartments"
          to="/corporate-housing/furnished-apartments/md/northbethesda"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=bellevue&cst=wa&zone=&area=" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?city=beaverton&cst=or&zone=oregon&area=oregon"
          to="/corporate-housing/furnished-apartments/oregon/beaverton"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=beaverton&cst=or&zone=oregon&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=beaverton&cst=or&zone=&area=oregon" to="/" push />
						<Redirect from="/complex_area.cfm?city=beaverton&cst=or&zone=&area=" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?city=beaverton&cst=or&titletext=furnished apartments"
          to="/corporate-housing/furnished-apartments/oregon/beaverton"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=beachwood&cst=oh&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=baton rouge&cst=la&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=baltimore&cst=md&zone=dc metro area&area=baltimore" to="/" push />
						<Redirect from="/complex_area.cfm?city=baltimore&cst=md&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=baltimore&cst=md&titletext=furnished apartments" to="/" push />
						<Redirect from="/complex_area.cfm?city=bakersfield&cst=ca&zone=southern cal&area=los angeles" to="/" push />
						<Redirect from="/complex_area.cfm?city=austin&cst=tx&titletext=corporate housing" to="/" push />
						<Redirect from="/complex_area.cfm?city=ashburn&cst=va&zone=dc metro area&area=herndon/reston" to="/" push />
						<Redirect from="/complex_area.cfm?city=ashburn&cst=va&zone=&area=" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?city=arlington&titletext=furnished apartments"
          to="/corporate-housing/furnished-apartments/va/arlington"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=arlington&cst=va&zone=dc metro area&area=arlington/alexandria" to="/" push />
						<Redirect from="/complex_area.cfm?city=arlington&cst=va&zone=&area=" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?city=arlington&cst=va&titletext=furnished apartments"
          to="/corporate-housing/furnished-apartments/va/arlington"
          push
        />
        <Redirect
          from="/complex_area.cfm?city=arlington&cst=dc&zone=dc metro area&area=arlington/alexandria"
          to="/corporate-housing/furnished-apartments/va/arlington"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=annapolis&cst=md&titletext=furnished apartments" to="/" push />
						<Redirect from="/complex_area.cfm?city=andover&cst=ma&zone=&area=" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?city=anaheim&cst=ca&zone=southern cal&area=orange county"
          to="/corporate-housing/furnished-apartments/orangecounty"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=amarillo&cst=tx&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=alpharetta&cst=ga&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=all&cst=or&zone=oregon&area=oregon" to="/" push />
						<Redirect from="/complex_area.cfm?city=all&cst=ca&zone=southern cal&area=san diego" to="/" push />
						<Redirect from="/complex_area.cfm?city=all&cst=ca&zone=sf bay area&area=silicon valley - south" to="/" push />
						<Redirect from="/complex_area.cfm?city=all&cst=ca&zone=sf bay area&area=silicon valley - north" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?city=all&cst=ca&zone=sf bay area&area=oakland/alameda/emeryville"
          to="/corporate-housing/furnished-apartments/bayarea"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=all&cst=ca&zone=northern cal&area=sacramento" to="/" push />
						<Redirect from="/complex_area.cfm?city=aliso viejo&cst=ca&zone=southern cal&area=orange county" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?city=alexandria&titletext=furnished apartments"
          to="/corporate-housing/furnished-apartments/va/alexandria"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=alexandria&cst=va&zone=&area=" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?city=alexandria&cst=va&titletext=furnished apartments"
          to="/corporate-housing/furnished-apartments/va/alexandria"
          push
        />
        {/* <Redirect from="/complex_area.cfm?city=albuquerque&cst=nm&zone=&area=" to="/" push />
						<Redirect from="/complex_area.cfm?city=albany" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?area=washington dc&titletext=furnished apartments"
          to="/corporate-housing/furnished-apartments/dc/washington"
          push
        />
        {/* <Redirect from="/complex_area.cfm?area=washington dc&cst=va&zone=dc metro area" to="/" push />
						<Redirect from="/complex_area.cfm?area=washington dc&cst=dc&zone=dc metro area" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?area=washington dc"
          to="/corporate-housing/furnished-apartments/dc/washington"
          push
        />
        {/* <Redirect from="/complex_area.cfm?area=tysons/fairfax&titletext=short term housing" to="/" push />
						<Redirect from="/complex_area.cfm?area=tysons/fairfax&cst=va&zone=dc metro area" to="/" push />
						<Redirect from="/complex_area.cfm?area=tysons/fairfax&cst=va&zone=" to="/" push />
						<Redirect from="/complex_area.cfm?area=tysons/fairfax&cst=dc&zone=dc metro area" to="/" push />
						<Redirect from="/complex_area.cfm?area=tri valley&cst=ca&zone=sf bay area" to="/" push />
						<Redirect from="/complex_area.cfm?area=tri valley&cst=ca&zone=" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?area=silicon valley - south&cst=ca&zone=sf bay area"
          to="/corporate-housing/furnished-apartments/bayarea"
          push
        />
        <Redirect
          from="/complex_area.cfm?area=silicon valley - north&cst=ca&zone=sf bay area"
          to="/corporate-housing/furnished-apartments/bayarea"
          push
        />
        {/* <Redirect from="/complex_area.cfm?area=silicon valley - north&cst=ca&zone=" to="/" push />
						<Redirect from="/complex_area.cfm?area=silicon valley - north&cst=ca" to="/" push />
						<Redirect from="/complex_area.cfm?area=san francisco&titletext=furnished apartments" to="/" push />
						<Redirect from="/complex_area.cfm?area=san francisco&titletext=extended stay" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?area=san francisco&titletext=corporate housing"
          to="/corporate-housing/furnished-apartments/ca/sanfrancisco"
          push
        />
        <Redirect
          from="/complex_area.cfm?area=san francisco&cst=ca&zone=sf bay area"
          to="/corporate-housing/furnished-apartments/ca/sanfrancisco"
          push
        />
        <Redirect
          from="/complex_area.cfm?area=san francisco&cst=ca&zone="
          to="/corporate-housing/furnished-apartments/ca/sanfrancisco"
          push
        />
        <Redirect
          from="/complex_area.cfm?area=san diego&cst=ca&zone=southern cal"
          to="/corporate-housing/furnished-apartments/ca/sandiego"
          push
        />
        <Redirect
          from="/complex_area.cfm?area=san diego&cst=ca&zone="
          to="/corporate-housing/furnished-apartments/ca/sandiego"
          push
        />
        <Redirect
          from="/complex_area.cfm?area=san diego&cst=ca&titletext=furnished apartments"
          to="/corporate-housing/furnished-apartments/ca/sandiego"
          push
        />
        {/* <Redirect from="/complex_area.cfm?area=sacramento&cst=ca&zone=" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?area=palo alto/mountain view&cst=ca&zone=sf bay area"
          to="/corporate-housing/furnished-apartments/bayarea"
          push
        />
        <Redirect
          from="/complex_area.cfm?area=palo alto/mountain view&cst=ca&zone="
          to="/"
          push
        />
        <Redirect
          from="/complex_area.cfm?area=orange county&cst=ca&zone=southern cal"
          to="/corporate-housing/furnished-apartments/orangecounty"
          push
        />
        <Redirect
          from="/complex_area.cfm?area=orange county&cst=ca&titletext=furnished apartments"
          to="/corporate-housing/furnished-apartments/orangecounty"
          push
        />
        <Redirect
          from="/complex_area.cfm?area=oakland/alameda/emeryville&cst=ca&zone=sf bay area"
          to="/corporate-housing/furnished-apartments/bayarea"
          push
        />
        {/* <Redirect from="/complex_area.cfm?area=north bay / marin&cst=ca&zone=sf bay area" to="/" push />
						<Redirect from="/complex_area.cfm?area=north bay / marin" to="/" push />
						<Redirect from="/complex_area.cfm?area=maryland&cst=md&zone=" to="/" push />
						<Redirect from="/complex_area.cfm?area=maryland&cst=dc&zone=dc metro area" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?area=los angeles&cst=ca&zone=southern cal"
          to="/corporate-housing/furnished-apartments/ca/losangeles"
          push
        />
        <Redirect
          from="/complex_area.cfm?area=los angeles&cst=ca&zone="
          to="/corporate-housing/furnished-apartments/ca/losangeles"
          push
        />
        <Redirect
          from="/complex_area.cfm?area=los angeles&cst=ca&titletext=furnished apartments"
          to="/corporate-housing/furnished-apartments/ca/losangeles"
          push
        />
        {/* <Redirect from="/complex_area.cfm?area=inland empire&cst=ca&zone=southern cal" to="/" push />
						<Redirect from="/complex_area.cfm?area=inland empire" to="/" push />
						<Redirect from="/complex_area.cfm?area=herndon/reston&cst=va&zone=dc metro area" to="/" push />
						<Redirect from="/complex_area.cfm?area=herndon/reston&cst=va&zone=" to="/" push />
						<Redirect from="/complex_area.cfm?area=herndon/reston&cst=dc&zone=dc metro area" to="/" push />
						<Redirect from="/complex_area.cfm?area=hayward/fremont&cst=ca&zone=sf bay area" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?area=foster city/peninsula&cst=ca&zone=sf bay area"
          to="/corporate-housing/furnished-apartments/ca/fostercity"
          push
        />
        <Redirect
          from="/complex_area.cfm?area=foster city/peninsula&cst=ca&zone="
          to="/corporate-housing/furnished-apartments/ca/fostercity"
          push
        />
        <Redirect
          from="/complex_area.cfm?area=foster city/peninsula"
          to="/corporate-housing/furnished-apartments/ca/fostercity"
          push
        />
        {/* <Redirect from="/complex_area.cfm?area=baltimore&cst=md&zone=" to="/" push /> */}
        {/* 16001*/}
        {/* <Redirect from="/complex_area.cfm?area=arlington/alexandria&cst=va&zone=dc metro area" to="/" push />
						<Redirect from="/complex_area.cfm?area=arlington/alexandria&cst=va&zone=" to="/" push />
						<Redirect from="/complex_area.cfm?area=arlington/alexandria&cst=dc&zone=dc metro area" to="/" push />
						<Redirect from="/complex_area.cfm?area=all&cst=va&zone=richmond" to="/" push />
						<Redirect from="/complex_area.cfm?area=all&cst=va&zone=dc metro area" to="/" push />
						<Redirect from="/complex_area.cfm?area=all&cst=ca&zone=southern cal" to="/" push /> */}
        <Redirect
          from="/complex_area.cfm?area=all&cst=ca&zone=sf bay area"
          to="/corporate-housing/furnished-apartments/bayarea"
          push
        />
        {/* <Redirect from="/complex_area.cfm?area=all&cst=ca&zone=northern cal" to="/" push />
						<Redirect from="/complex_area.cfm?area=all&cst=ca&zone=" to="/" push />
						<Redirect from="/company/nike-internship/index.cfm" to="/" push /> */}
        <Redirect
          from="/charlotte-nc-furnished-apartments.cfm"
          to="/corporate-housing/furnished-apartments/nc/charlotte"
          push
        />
        {/* <Redirect from="/careeropenings.cfm" to="/career-opportunities" push />
						<Redirect from="/brandid.cfm" to="/" push />
						<Redirect from="/bigdifference.cfm" to="/" push />
						<Redirect from="/bay-area-furnished-apartments.cfm" to="/corporate-housing/furnished-apartments/bayarea" push />
						<Redirect from="/awards.cfm" to="/media-kit-industry-awards" push />
						<Redirect from="/austin-furnished-apartments.cfm" to="/corporate-housing/furnished-apartments/texas/austin" push />
						<Redirect from="/alexandria-furnished-apartments.cfm" to="/" push />
						<Redirect from="/adminsvcs.cfm" to="/" push />
						<Redirect from="/account.cfm" to="/" push />
						<Redirect from="/aboutus.cfm" to="/company-info" push /> */}
        {/* Redirect Url End*/}
        <Route
          exact
          path="/openlink-guestservices/:id"
          component={IDGuestServices}
        ></Route>
        <Route
          exact
          path="/confirmation/:id"
          component={guestOrderActiveData}
        ></Route>
        <Route exact path="/signature" component={Signature}></Route>
        <Route path="*" component={NoMatchFound} />
      </Switch>
    </div>
  </Router>
);
