import OwlCarousel from 'react-owl-carousel';
import GuestHeader from '../common/guestHeader';
import Footer from '../common/footer';
import SubFooter from "../common/subFooter";
class SafetyMeasures extends React.Component {
    render() {
        return (<div>
            <GuestHeader />
            <div className="safety-measures-page">
                <div className="container">
                    <header>
                        <div className="sm-header-part">
                            <div className="item">
                                <img src="/assets/newUI-images/SA_logo-B-noSpace.svg" alt="" />
                            </div>
                            <div className="item item2">
                                <h1>
                                    Covid Protocols
                                    {/* <span> New </span> <br />
                                    Multi-Tier Cleaning Protocol */}
                                </h1>
                                <p>
                                    The health and safety of our guests is paramount.
                                </p>
                                <p>
                                    SuiteAmerica has introduced enhanced cleaning and sanitizing procedures guided by the recommendations from the Centers for Disease Control (CDC) and state and local government health agencies.  We have deployed hospital-grade cleaning solutions and leading disinfecting methods, ensuring a clean, convenient and safe experience for our guests.
                                </p>
                                <p>
                                    Every single dish, glass, utensil, and piece of cutlery is sanitized on a high heat wash cycle in-between guests.  As always, freshly sanitized linens and towels are provided in each suite.
                                </p>
                                <p>
                                    We can provide contactless arrivals, for a smooth and stress free arrival.
                                </p>
                            </div>
                        </div>
                        {/* <h2>Guests stay safe while staying with SuiteAmerica.</h2> */}
                    </header>
                    <p className="text-center">in compliance with</p>
                    <div className="logo-icon">
                        <div className="item">
                            <img src="../assets/images/safety-measures/cdc.png" alt="" />
                        </div>
                        <div className="item">
                            <img src="../assets/images/safety-measures/WHO.png" />
                        </div>
                        <div className="item">
                            <img src="../assets/images/safety-measures/gbac.png" alt="" />
                        </div>
                    </div>
                </div>

                <div className="sm-tails-part">
                    <div className="container-fluid">
                        <hr />
                        <h1>The Multi-Tier Approach</h1>
                        <p>
                            Our approach will be dependent on the most effective sanitization
                            method for the size and layout of each suite.
                        </p>
                    </div>
                    <div className="tails">
                        <div className="item item1">
                            <h1>1</h1>
                            <h2>Extensive <span> Hospital-Grade Cleaning </span></h2>
                            <hr />
                            <p>
                                Our Environmental Team wears PPE while servicing suites and
                                adheres to social distancing guidelines.
                            </p>
                            <p>
                                Using hospital-grade cleaning solutions, our Environmental Team
                                disinfects suites from floor to ceiling.
                            </p>
                            <p>
                                Keys and remotes are cleaned with industrial-grade sanitizing
                                products and provided to guests in a sealed envelope.
                            </p>
                            <p>
                                Every single dish, glass, utensil, and piece of cutlery is
                                sanitized on a high heat wash cycle in-between guests.
                            </p>
                            <p>
                                As always, freshly sanitized linens and towels are provided in
                                each suite. No change there.
                            </p>
                        </div>
                        <div className="item item2">
                            <h1>2</h1>
                            <h2>Electrostatic <span> Spraying & Sealing </span></h2>
                            <hr />
                            <p>
                                After they are thoroughly cleaned by our Environmental Team, all
                                suites are further disinfected using electrostatic sprayers.
                            </p>
                            <p>
                                Electrostatic spraying mist aggressively clings to surfaces and
                                objects to eliminate germs and viruses, and does not require
                                wiping, so it stays on the surface longer than conventional
                                methods, thereby eliminating potential of cross-contamination
                                after spraying.
                            </p>
                            <p>
                                The front door seal is marked with tape after electrostatic
                                service to prevent anyone but the arriving guest from accessing
                                the suite.
                            </p>
                        </div>
                        <div className="item item3">
                            <h1>3</h1>
                            <h2>UV Light <span> Sterilization </span></h2>
                            <hr />
                            <p>
                                UVC sterilization is implemented in situations where a departing guest has a confirmed case of COVID.
                            </p>
                            <p>
                                UVC wavelengths are capable of inactivating microorganisms, such
                                as viruses and bacteria.
                            </p>
                            <p>
                                UVC energy is an effective, environmentally-friendly, and
                                chemical-free way to prevent microorganisms from replicating in
                                any environment.
                            </p>
                        </div>
                    </div>

                    <div className="container-fluid">
                        <div className="ul-list-item">
                            <div className="item">
                                <h1>Why Electrostatic Spraying?</h1>
                                <ul>
                                    <li>
                                        Electrostatic spray uses a solution that contains positively
                                        charged particles that aggressively adhere to surfaces and
                                        objects. Because the particles in the spray are positively
                                        charged, they cling to and coat any surface they’re aimed at.
                                    </li>
                                    <li>
                                        Electrostatic applied disinfectant kills bacteria and viruses
                                        on contact, helping to combat the spread of infections.
                                    </li>
                                    <li>
                                        Electrostatic spraying stays on surfaces longer than
                                        conventional disinfecting and sanitizing methods.
                                    </li>
                                </ul>
                            </div>
                            <div className="item item1">
                                <span></span>
                            </div>
                            <div className="item">
                                <h1>Why UVC Sterilization?</h1>
                                <ul>
                                    <li>
                                        UVC utilizes short-wavelength, ultraviolet radiation that is
                                        harmful to microorganisms, such as viruses and bacteria.
                                    </li>
                                    <li>
                                        It prevents microorganisms from infecting and reproducing in
                                        any environment.
                                    </li>
                                    <li>
                                        UVC is eco-friendly, chemical-free, and leaves behind no
                                        residues.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="sm-bg-color">
                        {/* <div className="cleaing-part">
                            <div className="item item2">
                                <h1>Additional Protective Measures</h1>
                                <ul>
                                    <li>
                                        Workstations are sanitized throughout the day, and offices
                                        receive a twice weekly deep cleaning.
                                    </li>
                                    <li>
                                        All employees wear PPE as appropriate to their job functions
                                        and adhere to the CDC’s social distancing guidelines.
                                    </li>
                                    <li>
                                        Daily health screenings include a temperature check and
                                        questions about exposure and potential symptoms, per CDC and
                                        OSHA guidelines.
                                    </li>
                                    <li>
                                        Office HVAC alterations include air scrubbers that
                                        continuously protect and purify the air and attack
                                        contaminants on all surfaces.
                                    </li>
                                </ul>
                            </div>
                            <div className="item">
                                <img src="../assets/images/safety-measures/spray.png" alt="" />
                            </div>
                        </div> */}

                        {/* <div className="slider">
                            <div id="demo">
                                <div>
                                    <a href="#"><img src="http://placehold.it/530x530" /></a>
                                </div>
                                <div>
                                    <a href="#"><img src="http://placehold.it/700x530" /></a>
                                </div>
                                <div>
                                    <a href="#"><img src="http://placehold.it/800x530" /></a>
                                </div>
                                <div>
                                    <a href="#"><img src="http://placehold.it/800x530" /></a>
                                </div>
                                <div>
                                    <a href="#"><img src="http://placehold.it/530x530" /></a>
                                </div>
                            </div>
                        </div> */}
                        <div className="guest_testmonials_slider">
                            <OwlCarousel className="GreatSchools-community" {...{ loop: true, nav: true, dots: true, items: 1, navContainerClass: 'owl-buttons', navText: ["<i class='glyphicon glyphicon-menu-left'></i>", "<i class='glyphicon glyphicon-menu-right'></i>"], autoheight: 'true', refreshClass: 'owl-refresh', loadingClass: 'owl-loaded', responsive: { 320: { items: 1, nav: true, loop: true }, 768: { items: 3, nav: true, loop: true }, 992: { items: 3, nav: true, loop: true }, 1199: { items: 3, nav: true, loop: true, autoHeight: true } } }}>

                                <div className="card">
                                    <img src="../assets/images/safety-measures/tv.png" alt="" />
                                    <h1>WFH Solutions: </h1>
                                    <p>Suites with additional space and desks for those working from home</p>
                                </div>
                                <div className="card">
                                    <img src="../assets/images/safety-measures/ipad.png" alt="" />
                                    <h1>Welcome Home: </h1>
                                    <p>Virtual meet and greets for guest arrivals</p>
                                </div>
                                <div className="card">
                                    <img src="../assets/images/safety-measures/oven.png" alt="" />
                                    <h1>More Space: </h1>
                                    <p>Suites come with ample square footage, full kitchens, and most have private outdoor spaces</p>
                                </div>
                                <div className="card">
                                    <img src="../assets/images/safety-measures/wifi.png" alt="" />
                                    <h1>Stay Connected: </h1>
                                    <p>Optional upgraded high speed, wireless internet to work from home</p>
                                </div>
                                <div className="card">
                                    <img src="../assets/images/safety-measures/box.png" alt="" />
                                    <h1>Digital Support: </h1>
                                    <p>Digital resources and support for contactless orientation to the area</p>
                                </div>
                                <div className="card">
                                    <img src="../assets/images/safety-measures/cart.png" alt="" />
                                    <h1>Contactless Cooking: </h1>
                                    <p>Grocery starter packages and grocery delivery mean less time spent in public spaces</p>
                                </div>

                            </OwlCarousel>
                        </div>
                    </div>

                    <div className="container-fluid">
                        <div className="quote-part">
                            <h1>See what guests have been saying…</h1>
                            <div className="one">
                                <p>
                                    “Due to the COVID-19 crisis, I’ve had no face to face contact
                                    with anyone from SuiteAmerica, but they have always been very
                                    prompt and efficient when answering e-mails. The temporary
                                    housing they have given us is excellent. It’s a beautiful and
                                    modern apartment in a lovely area.”
                                </p>
                                <p className="p1"><span></span></p>
                                <p>
                                    “SuiteAmerica was great to work with. They were very
                                    accommodating and made my stay very welcoming!”
                                </p>
                            </div>
                            <hr />
                            <div className="two">
                                <p>
                                    “Thank you for all the hard work that everyone has done. Moving
                                    with the pandemic was very stressful for me and my family but
                                    with SuiteAmerica’s help and flexibility, it took a lot of the
                                    stress off us.”
                                </p>
                                <p className="p1"><span></span></p>
                                <p>
                                    “I just wanted to make sure I reached out and said how grateful
                                    we are for the apartment. It is absolutely perfect and the boys
                                    could not have been more excited about their room! Thank you so
                                    much for making our arrival as a family so perfect!”
                                </p>
                            </div>
                        </div>

                        {/* <div className="bottom-part">
                            <div className="image-view">
                                <img src="../assets/images/safety-measures/quality-accredited-gbr-provider_0.png" alt="" />
                                <img src="../assets/images/safety-measures/chpa.png" alt="" />
                            </div>
                            <h5>Proud to be ISAAP & CHPA Certified</h5>
                            <p>
                                As an ISAAP quality accredited company, SuiteAmerica has satisfied
                                more than 250 rigorous quality considerations in addition to
                                meeting all prescribed regulations and best practice standards of
                                safety, security and duty of care. This award verifies that, by
                                undergoing comprehensive on-site product inspections, a minimum
                                standard of quality and comfort excellence has been achieved.
                            </p>
                        </div> */}
                    </div>
                </div>
            </div>
            <SubFooter />
            <Footer />
        </div>)
    }

}
export default SafetyMeasures;