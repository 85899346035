import Footer from "./footer";
import Header from "./header";
import OwlCarousel from 'react-owl-carousel';


class unFolding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  async componentDidMount() {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }
  }

  render() {

    return (
      <div className="SAweb_updateUi">
        <Header />
        <section className=" SAweb_salogosbanner" data-aos="fade-up">
          <div className="container">
            <div className="box">
              <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/SAWeblogo/SA_logo-W.svg" alt="" width="100%" />
              <h3>Unfolding the transformation of SuiteAmerica</h3>
            </div>

          </div>

        </section>

        <section className="bg-white SAweb-salogosExperience ">
          <div className="container">
            <h1>Experience the joy of living</h1>
            <div className="box">
              <div className="item">
                <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/SAWeblogo/sa_logo-experience-1.png" alt="" />
              </div>
              <div className="item">
                <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/SAWeblogo/sa_logo-experience-2.png" alt="" />
              </div>
            </div>
          </div>
        </section>

        <section className="SAweb_salogosText">
          <div className="container">
            <h3>
              It’s been over three decades since SuiteAmerica first came into existence. Ever since, the goal has been to deliver incomparable corporate housing and business hospitality solutions. Our endeavor to enhance the guest experience by leveraging technology and modernization has led to innovative results.
            </h3>
            <h3>With the grit to do great things, we’ve evolved our identity.</h3>
          </div>
        </section>

        <section className="SAweb_salogosServiceEx">
          <div className="container">
            <div className="pic">
              <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/SAWeblogo/service_excellence.svg" alt="" />
            </div>
            <h3>A compelling visual overhaul derived from SuiteAmerica’s philosophies - the driving factors behind a successful corporate housing brand.</h3>
          </div>
        </section>

        <section className="bg-white SAweb_salogsIterations">
          <div className="container">
            <div className="row   mx-0" data-aos="fade-up">
              <div className="col-sm-1 ">
                <div className="HeadingRotate">
                  <h2 className="h2_rotate">Logo Iterations</h2>
                </div>
              </div>
              <div className="col-sm-11 ms-2 ps-5 my-5 py-5">
                {/* <h3 className="text-dark">We kept on fine-tuning our logo until we finally stumbled upon something that matched our vision.</h3> */}
                <div className="owl-slider  SAweb_sliderNav ">
                  <div  >
                    <OwlCarousel className="owl-theme owl-carousel"
                      {...{
                        loop: false, margin: 20, nav: false, dots: true, items: 1,
                        autoplay: false,
                        navContainerClass: 'owl-buttons',
                        navText: ["<i class='glyphicon glyphicon-menu-left'></i>",
                          "<i class='glyphicon glyphicon-menu-right'></i>"],
                        responsive: {
                          767: { items: 1 },
                          992: { items: 3 }
                        }
                      }}>
                      <div className="item">

                        <div className="caption">
                          <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/LogoSketch1.png" alt="" />
                        </div>
                      </div>
                      <div className="item">

                        <div className="caption">
                          <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/LogoSketch2.png" alt="" />
                        </div>
                      </div>
                      <div className="item">
                        <div className="caption">
                          <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/LogoSketch3.png" alt="" />
                        </div>
                      </div>
                      <div className="item">
                        <div className="caption">
                          <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/LogoSketch4.png" alt="" />
                        </div>
                      </div>
                      <div className="item">
                        <div className="caption">
                          <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/logo/logo.svg" alt="" />
                        </div>
                      </div>
                    </OwlCarousel>


                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>

        <section className="SAweb_salogosVideo">
          <div className="container">
            <div className="box">
              <div className="text-center">
                <video playsInline autoPlay muted loop  >
                  <source src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/SAWeblogo/salogos_Video.mp4" type="video/mp4" />
                </video>

              </div>

            </div>
          </div>
        </section>

        <section className="SAwebredesigLogoblack">
          <div className="container">
            <h4>
              {/* A major change - redesigning the existing logo. */}
              <span>The new logo alludes to our ambitious nature and emphasizes a future with unlimited possibilities.</span>
            </h4>


          </div>

        </section>
        <section className="SAweb_salogos_equation">
          <img src="/assets/newUI-images/salogos_equation-1.svg" alt="" />
          <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/SAWeblogo/salogos_equation-2.svg" alt="" />
        </section>

        <section className="SAweb_salogos_multiLogos bg-white">

          <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/SAWeblogo/salogosmulti_logo.svg" alt="" />


        </section>

        <section className="SAweb_salogosVideo">
          <div className="container">
            <div className="box">
              <div className="item">
                <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/SAWeblogo/salogosmulti_logo_gif.gif" alt="" width="100%" />
              </div>
              <div className="item">
                <p className="p">
                  Evolution is at the core of everything great. This is the evolution of SuiteAmerica.
                </p>
                <p className="p">Our new visual identity is designed to convey the SuiteAmerica experience – high-touch and service-focused. The modern silhouette of the 'S' epitomizes our journey so far and embodies the enterprising nature of our DNA.</p>
              </div>
            </div>
          </div>
        </section>

        <section className="SAweb_salogos_multiLogos bg-white">
          <h4 className="line"> <span> An assurance of indistinguishable commitment </span> </h4>

          <img className="my-4" src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/SAWeblogo/salogo_onlyLogo.svg" alt="" />
        </section>



        <section className="logosSections">

          <div className="item">
            <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/SAWeblogo/SA_logo-W.svg" alt="" />
          </div>
          <div className="item bg-item">
            <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/SAWeblogo/SA_logo-B.svg" alt="" />
          </div>

          <div className="item text-right">
            <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/SAWeblogo/salogo_dots.svg" alt="" />
          </div>
          <div className="item d-flex">
            <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/SAWeblogo/SA_logo-W.svg" alt="" />
          </div>

        </section>

        <section className="SAweb_salogo_lineImage">
          <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/SAWeblogo/lineImage.png" alt="" />
        </section>

        <section className="SAweb_salogos_tails bg-white">
          <div className="item dotsLogo">
            <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/SAWeblogo/salogo_dots-Dark.svg" alt="" />
          </div>
          <div className="item">
            <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/SAWeblogo/tailImage-1.png" alt="" />
          </div>
          <div className="item ">
            <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/SAWeblogo/tailImage-2.png" alt="" />
          </div>
          <div className="item dotsLogo">
            <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/SAWeblogo/salogo_dots-Dark.svg" alt="" />
          </div>
        </section>

        <section className="SAweb_salogosStepsbystep">
          <div className="item">
            <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/SAWeblogo/SA_logo-W.svg" alt="" />
          </div>
          <div className="item">
            <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/SAWeblogo/salogoStepbystep.png" alt="" width="100%" />
          </div>
        </section>

        <section className="SAwebredesigLogoblack clrChnage bg-white">
          <div className="container">
            {/* <h4>SuiteAmerica’s transformation focuses on one goal - a completely redesigned user experience on the app and website. The attention to detail in this visual overhaul is so meticulous that our users are in for a seamless experience. Embark with us on the new journey of a redesigned SuiteAmerica.</h4> */}
            <h4>We are thrilled to announce the launch of SuiteAmerica's brand new visual identity, marking a significant milestone in our transformation journey. With an unwavering focus on improving the user experience, we have completely overhauled our app and website. <br/><br/>Embark with us on the new journey of a redesigned SuiteAmerica.</h4>

          </div>

        </section>
        <Footer />
      </div>
    );
  }
}
export default unFolding;