import ReactTable from "react-table";
const OrderStatusModal=(props)=>{
    const filterMethod = (filter, row) => {
        const id = filter.pivotId || filter.id;
        if (row[id] !== null && typeof row[id] === "string") {
          return (row[id] !== undefined
            ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
            : true);
        }
      }
 
 let columns = [
  {
  Header: 'Order#',
  id: 'order11',
  accessor: row=> (row.orderno).toString(),
  width: 75
  },

  {
  Header: "Resident",
  id: 'Resident',
  accessor: row=> (row.GuestFirstName),
  style: {'whiteSpace': 'unset'}
  },
  {
    Header: "PO#",
    id: 'purchaseOrder',
    accessor: row=> (row.PurchaseOrder!=null ?(row.PurchaseOrder).toString():'---') ,
    style: {'whiteSpace': 'unset'},
    width: 100
    },
  {
    Header: "Community/Hotel",
    id: 'community_name',
    accessor: row=>  (row.community_name ==null && row.HotelName!=null ?row.HotelName:row.community_name),
    style: {'whiteSpace': 'unset'},
    width: 160
    },
    // {
    // Header: "Address",
    // id: 'Address1',
    // accessor: row=>  (row.address ==null && row.HAddress1!=null ?row.HAddress1:row.address),
    // style: {'whiteSpace': 'unset'},
    
    // },
    {
    Header: "Suite#",
    id: 'Suite No',
    accessor: "SuiteNo",
    width: 75
    
    },
    {
    Header: "MoveIn",
    id: 'movein1',
    accessor:  row=> (moment(row.MoveInDate).format('L')),
    
    },
    {
    Header: "MoveOut",
    id: 'MoveOutDate1',
    accessor:  row=> (moment(row.MoveOutDate).format('L')),
    
    },
   {
    Header: "Housekeeping",
    id: 'housekeeping',
    accessor:  row=> (row.NextHouseKeeping!=null? moment(row.NextHouseKeeping).format('L') :row.BRNextHouseKeeping!=null? moment(row.BRNextHouseKeeping).format('L'):'---'),
    
    },
]
const {statusType, communitysLoading, ordersData} = props;
const Data = statusType != "ACTIVE" ? columns.slice(0, columns.length - 1) : columns


return(
<div className="modal fade" id="orderStatusModal" role="dialog">
<div className="modal-dialog modal-lg">


  <div className="modal-content animated zoomIn">
    <div className="modal-header">
      <button type="button" className="close" data-dismiss="modal">&times;</button>
       <h4 className="modal-title" style={{color:statusType=='ACTIVE'?'#48962a':'#1fa2ff'}}>{statusType} </h4>
    </div>
    <div className="col-md-12 mrg-top-15">
    <ReactTable
    multiSort={true}
    defaultFilterMethod={filterMethod}
    loading={communitysLoading}
    data={ordersData}
    columns={Data}
    defaultPageSize={15}
    pageSizeOptions={[5, 10, 15, 20, 25, 50, 100,ordersData.length]}
    style={{height: "500px"}}
    sortable={true} 
    className="-striped -highlight analsys_table orderStatus_moadl"
    filterable= {true}
/>
</div>
    <div className="modal-footer">
      
    </div>
  </div>
  
</div>
    </div>

    
)
}
export default OrderStatusModal;
