import MainController from "../../controller/mainController";
import SubFooter from "../../common/subFooter";
import GuestHeader from '../../common/guestHeader';
import Footer from '../../common/footer';
import MediaFooter from "./common/mediaFooter";
import MetaTags from '../../common/metaTags';
import ServerMetaTags from '../../../server/metaTags';
/* MediaCorporateLocations Component initialization */
class MediaCorporateLocations extends React.Component {
    /* Initializing objects of its MediaCorporateLocations class */
    constructor(props) {
        super(props);
    }
    /* It is invoked immediately before mounting occurs */
    componentWillMount() {
        MainController.getInitialInformation();
    }
    /* It is invoked to return html content */
    render() {
        const metaData = ServerMetaTags.urlString.getMetaData(this.props.match.url);
        return (
            <div>
            <MetaTags 
             metaTitle = {metaData.title}
             metaDesription = {metaData.description}
             metaUrl = {metaData.canonical} />
            <GuestHeader/>
            <div className="cover-tophd-ht company_ht_cover"></div>
            <div className="quote-section bg-white">
                <div className="container mediakit">
                    <div className="plaine-text global-coverage-wrap col-md-8 col-md-offset-2 wow fadeInUp" data-wow-delay="0.5s">
                        <h2 className="mrg-btm-50 color-red">CORPORATE LOCATIONS</h2>

                        <div className="col-md-6">
                            <h5 className="text-left mrg-btm-0 font700 color-red"><strong>Corporate Headquarters</strong></h5>
                            <ul>
                                <li>El Dorado Hills, CA</li>
                                <li>4970 Windplay Dr.</li>
                                <li>El Dorado Hills, CA 95762 </li>
                                <li>Tel: 800-410-4305 </li>
                                <li>Tel: 916-941-7970</li>
                                <li>Fax: 916-941-7989</li>
                            </ul> 
                            <h5 className="text-left mrg-btm-0 font700 color-red"><strong>National Call Center:</strong></h5>
                            <ul>
                                <li>800-SUITE-4-1</li>
                                <li>800-784-8341 </li>
                                <li><a href="mailto:info@suiteamerica.com">info@suiteamerica.com</a></li>
                            </ul> 
                            <h5 className="text-left mrg-btm-0 font700 color-red"><strong>West Coast Regional Sales Offices:</strong></h5>
                            <h5 className="text-left mrg-btm-0 mrg-top-0 font700"><strong>Mountain View, CA</strong></h5>
                            <ul>
                                <li>800 West El Camino Real #180</li>
                                <li>Mountain View, CA 94040</li>
                                <li>Tel: 650-943-2305 </li>
                            </ul> 
                            <h5 className="text-left mrg-btm-0 font700"><strong>Portland, OR</strong></h5>
                            <ul>
                                <li>10150 SW Nimbus Ave., Suite E-4</li>
                                <li>Portland, OR 97223 </li>
                                <li>Tel: 503-443-2033 </li>
                                <li>Fax: 209-465-3810 </li>
                            </ul> 
                            <h5 className="text-left mrg-btm-0 font700"><strong>San Diego, CA</strong></h5>
                            <ul>
                                <li>9597 Distribution Ave.</li>
                                <li>San Diego, CA 92121 </li>
                                <li>Tel: 858-549-1617 </li>
                                <li>Tel: 800-410-4305  </li>
                                <li>Fax: 858-549-1621 </li>
                            </ul> 
                            <h5 className="text-left mrg-btm-0 font700"><strong>San Francisco, CA</strong></h5>
                            <ul>
                                <li>101 California St. #2710</li>
                                <li>San Francisco, CA 94111</li>
                                <li>Tel: 415-365-7155</li>
                            </ul> 
                            <h5 className="text-left mrg-btm-0 font700"><strong>San Jose, CA</strong></h5>
                            <ul>
                                <li>2033 Gateway Pl.</li>
                                <li>San Jose, CA 95110</li>
                                <li>Tel: 408-573-6925</li>
                            </ul> 
                            <h5 className="text-left mrg-btm-0 font700"><strong>Seattle Area, WA</strong></h5>
                            <ul>
                                <li>26215 29th Ave. NW</li>
                                <li>Stanwood, WA 98292</li>
                                <li>Tel: 206-262-7470</li>
                                <li>Tel: 800-410-4305</li>
                                <li>Fax: 801-285-7401</li>
                            </ul> 
                            <h5 className="text-left mrg-btm-0 font700"><strong>Sunnyvale, CA</strong></h5>
                            <ul>
                                <li>640 West California Ave. #210</li>
                                <li>Sunnyvale, CA 94086</li>
                                <li>Tel: 408-769-6154</li>
                            </ul> 
                        </div>


                        <div className="col-md-6">
                            <h5 className="text-left mrg-btm-0 font700 color-red"><strong>West Coast Reservations & Customer Service:</strong></h5>
                            <ul>
                                <li>Tel: 800-367-9501</li>
                                <li>Tel: 510-429-9700</li>
                                <li>Fax: 510-429-6780</li>
                                <li>Email: <a href="mailto:info@suiteamerica.com">Western Region Sales</a></li>
                            </ul> 
                            <h5 className="text-left mrg-btm-0 font700 color-red"><strong>West Coast Warehouse & Distribution:</strong></h5>
                            <h5 className="text-left mrg-btm-0 mrg-top-0 font700"><strong>Hayward, CA</strong></h5>
                            <ul>
                                <li>3300 Arden Rd.</li>
                                <li>Hayward, CA 94544</li>
                                <li>Tel: 510-429-9700</li>
                                <li>Fax:510-429-6780 </li>
                            </ul> 
                            <h5 className="text-left mrg-btm-0 font700"><strong>San Diego, CA</strong></h5>
                            <ul>
                                <li>9597 Distribution Ave.</li>
                                <li>San Diego, CA 92121</li>
                                <li>Tel: 858-549-1617</li>
                                <li>Tel: 800-410-4305</li>
                                <li>Fax: 858-549-1621</li>
                            </ul> 
                            <h5 className="text-left mrg-btm-0 font700"><strong>Santa Clara, CA</strong></h5>
                            <ul>
                                <li>354 Martin Ave.</li>
                                <li>Santa Clara, CA 95050</li>
                                <li>Tel: 408-727-6949</li>
                            </ul> 
                            <h5 className="text-left mrg-btm-0 font700"><strong>Stockton, CA</strong></h5>
                            <ul>
                                <li>2479 Station Dr. </li>
                                <li>Stockton, CA 95215</li>
                                <li>Tel: 209-465-3383</li>
                                <li>Fax:209-465-3810</li>
                            </ul> 
                            <h5 className="text-left mrg-btm-0 font700 color-red"><strong>Central Region Sales & Distribution:</strong></h5>
                            <h5 className="text-left mrg-btm-0 mrg-top-0 font700"><strong>Austin, TX</strong></h5>
                            <ul>
                                <li>701 Dessau Rd., Suite 801</li>
                                <li>Austin, TX 78754</li>
                                <li>Tel: 512-300-0807</li>
                                <li>Fax: 512-330-4729</li>
                            </ul> 
                            <h5 className="text-left mrg-btm-0 font700 color-red"><strong>East Coast Sales & Distribution:</strong></h5>
                            <h5 className="text-left mrg-btm-0 mrg-top-0 font700"><strong>Alexandria, VA</strong></h5>
                            <ul>
                                <li>6464 General Green Way, Suite F</li>
                                <li>Alexandria, VA 22312</li>
                                <li>Tel: 877-827-8483</li>
                                <li>Fax: 703-461-0227</li>
                            </ul> 
                            <h5 className="text-left mrg-btm-0 font700"><strong>Charlotte, NC</strong></h5>
                            <ul>
                                <li>6201 Fairview Rd., Suite 200</li>
                                <li>Charlotte , NC 22312</li>
                                <li>Tel: 877-827-8483</li>
                                <li>Fax: 703-461-0227</li>
                            </ul>
                            <h5 className="text-left mrg-btm-0 font700 color-red"><strong>East Coast Reservations & Customer Service:</strong></h5>
                            <ul>
                                <li>Tel: 877-827-8483</li>
                                <li>Tel: 703-461-1932</li>
                                <li>Fax: 703-461-0227</li>
                                <li>Email: <a href="mailto:info@suiteamerica.com">Eastern Region Sales</a></li>
                            </ul>
                        </div>    

                    </div>
                    
                    <MediaFooter />
                </div>
            </div>
            <SubFooter />
            <Footer/>
        </div>

        );
    }
}
export default MediaCorporateLocations;