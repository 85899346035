import React from 'react'
import Footer from './footer';
import Header from './header';
import OwlCarousel from 'react-owl-carousel';
import RequestCallback from './requestCallback';
import { Link } from 'react-router-dom';

class GovernmentHousingNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  async componentDidMount() {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }
  }
  handleClick() {
    $('#sideQuote').addClass('active');
    $('.overlay').addClass('active');
    $('.collapse.in').toggleClass('in');
    $('a[aria-expanded=true]').attr('aria-expanded', 'false');
  }
  closed() {
    $('#sideQuote').removeClass('active');
    $('.overlay').removeClass('active');
  }
  render() {
    return (
      <div className="SAweb_updateUi">

        <Header />



        <section className=" SAweb_gHousingBanner" data-aos="fade-up">
          <div className="container">
            {/* <div className="textView" >
              <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/gHousing/GSAlogo.png" alt="" />
            </div> */}
          </div>

        </section>


        <section className="SAweb_gHousingBannerBottom">
          <div className="container">
            <div className="row" data-aos="fade-up">
              <div className="col-sm-5">
                <h2>Government Housing Solutions</h2>
                {/* <button type="button" className="btn">Reserve Now </button> */}
                <a href="#" onClick={this.handleClick} className="btn btn-callback  ">
                  Reserve Now</a>
              </div>
              <div className="col-sm-7">
                <p className="p">SuiteAmerica provides per diem-friendly housing solutions that assist government contractors in the field with fully equipped and customized furnished apartments nationwide, conveniently located near hundreds of city centers, government offices, and military bases.</p>

                <p className="p">  Our apartments are available through all major Global Distribution Systems as well as GSA Schedule 48. </p>
              </div>
            </div>
          </div>
        </section>
        <section className="SAweb_ghousingUltimate bg-white">
          <div className="container">
            <div className="row" data-aos="fade-up">
              <div className="col-sm-6">
                <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/gHousing/image-1.jpg" alt="" width="100%" />
              </div>
              <div className="col-sm-6">
                <h3>Ultimate Convenience</h3>
                <p className="p">Our superior accommodations fall within TDY and most PCS per diems. You have access to hundreds of communities with thousands of apartments nationwide that are conveniently located near city centers, military installations and government offices.</p>
              </div>
            </div>
          </div>
        </section>

        <section className="SAweb_ghousingLocations bg-white">
          <div className="container" data-aos="fade-up">
            <div className="SAweb_topheadingWithArrrow mb-5">
              <h2>Locations</h2>
              <a href="/locations" className='btn' >View All <i className="bi bi-chevron-right"></i></a>

              {/* <button type="buttton" className="btn ">View All <i className="bi bi-chevron-right"></i> </button> */}
            </div>
            <div className="tailsBox">
              <div className="item">
                <div className="pic">
                  <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/San+Diego.jpg" alt="" width="100%" />
                </div>
                <p className="p">San Diego</p>
                <span>20 Properties</span>
              </div>
              <div className="item">
                <div className="pic">
                  <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/Washington+DC1.jpeg" alt="" width="100%" />
                </div>  <p className="p">Washington, D.C.</p>
                <span>50 Properties</span>
              </div>
              <div className="item">
                <div className="pic">
                  <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/Tysons.jpg" alt="" width="100%" />
                </div> <p className="p">Tysons</p>
                <span>40 Properties</span>
              </div>
              <div className="item">
                <div className="pic">
                  <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/BayArea.jpg" alt="" width="100%" />
                </div><p className="p">SF Bay Area</p>
                <span>120 Properties</span>
              </div>
              <div className="item">
                <div className="pic">
                  <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/Sacramento.jpg" alt="" width="100%" />
                </div> <p className="p">Sacramento</p>
                <span>15 Properties</span>
              </div>
              <div className="item">
                <div className="pic">
                  <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/Arlington-VA1.jpg" alt="" width="100%" />
                </div> <p className="p">Arlington</p>
                <span>35 Properties</span>
              </div>



            </div>
          </div>


        </section>

        <section className="SAweb_ghousingBillingSlider">
          <div className="container">
            <OwlCarousel className="owl-theme owl-carousel SAweb_sliderBottomArrows billingSlider sliderButtonsCenter "
              {...{
                loop: false, margin: 20, nav: true, dots: false, items: 1,
                navContainerClass: 'owl-buttons',
                navText: ["<i class='glyphicon glyphicon-menu-left'></i>",
                  "<i class='glyphicon glyphicon-menu-right'></i>"],
                responsive: {
                  1199: { items: 1, slideBy: 1 }
                }
              }}>
              <div className="row align-items-center" data-aos="fade-up">
                <div className="col-sm-6">
                  <h3>Hassle-Free Billing</h3>
                  <p className="p">With SuiteAmerica, you will receive consistent billing accuracy with multiple payment options. We
                    accept Government-issued credit cards, along with Global Distribution System (GDS) pre-approved rates
                    for Government extended stay travelers. </p>
                </div>
                <div className="col-sm-6">
                  <div className="">

                    <div className="owl-slider SAweb_sliderNav">
                      <div className="billingSlider SAweb_sliderBottomArrows">

                        <div className="item">
                          <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/gHousing/slidePic.jpg" alt="" width="100%" />

                        </div>
                      </div>
                    </div>

                  </div>


                </div>

              </div>
              {/* <div className="row align-items-center" data-aos="fade-up">
                <div className="col-sm-6">
                  <h3>Top-Flight Security</h3>
                  <p className="p">Our controlled-access apartments feature top-flight physical security and life
                    safety systems. Your information is safe with our secure web portal,
                    developed with user data protection in mind.</p>
                </div>
                <div className="col-sm-6">
                  <div className="">
                    <div className="owl-slider SAweb_sliderNav">
                      <div className="billingSlider SAweb_sliderBottomArrows">
                        <div className="item">
                          <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/gHousing/slidePic.jpg" alt="" width="100%" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="row align-items-center" data-aos="fade-up">
                <div className="col-sm-6">
                  <h3>Advanced Interactive Tools</h3>
                  <p className="p">We’ve created a suite of custom, digital tools using the latest technologies to
                    make our time with you more efficient and valuable. From area information to
                    service requests to information about your community or apartment, you have
                    it all right at your fingertips.</p>
                </div>
                <div className="col-sm-6">
                  <div className="">
                    <div className="owl-slider SAweb_sliderNav">
                      <div className="billingSlider SAweb_sliderBottomArrows">
                        <div className="item">
                          <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/gHousing/AdavncedInteractiveTools.jpg" alt="" width="100%" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </section>


        <section className="SAweb_guestSlider SAweb_gvtHouse bg-white pt-5" data-aos="fade-up">
          <div className="container SAweb_owl-slider2 py-1">
            <h2 className=" pt-3">What our guests have to say</h2>
            <div className="owl-slider SAweb_sliderNav my-5 pb-5">
              <div  >
                <OwlCarousel className="owl-theme owl-carousel"
                  {...{
                    loop: false, margin: 20, nav: false, dots: true, items: 1,
                    navContainerClass: 'owl-buttons',
                    navText: ["<i class='glyphicon glyphicon-menu-left'></i>",
                      "<i class='glyphicon glyphicon-menu-right'></i>"],
                    responsive: {
                      767: { items: 2, slideBy: 2, loop: false },
                      992: { items: 2, slideBy: 2, loop: false },
                      1199: { items: 3, slideBy: 3, loop: false }
                    }
                  }}>
                  <div className="item">

                    <div className="caption">
                      <i className="bi bi-quote"></i>

                      <p>This by far has been the best accommodation experience I've had. The team is easily reachable and quickly attend to any needs. Thank you for being a great host. </p>
                      <h3>Chukwuemeka, San Mateo </h3>
                      <i className="bi bi-quote"></i>
                    </div>
                  </div>
                  <div className="item">

                    <div className="caption">
                      <i className="bi bi-quote"></i>

                      <p>The checkin and level of care has been terrific. thank you very much.</p>
                      <h3>Eric, Portland </h3>
                      <i className="bi bi-quote"></i>
                    </div>
                  </div>
                  <div className="item">
                    <div className="caption">
                      <i className="bi bi-quote"></i>
                      <p>Everything provided was above and beyond expectation.  </p>
                      <h3>Leilani, Hillsboro </h3>
                      <i className="bi bi-quote"></i>
                    </div>
                  </div>
                  <div className="item">
                    <div className="caption">
                      <i className="bi bi-quote"></i>
                      <p>Nicely set up and felt very welcoming.  Thank you to the SA team for helping us during this transition!</p>
                      <h3>Desiree, Washington, D.C. </h3>
                      <i className="bi bi-quote"></i>
                    </div>
                  </div>
                  <div className="item">
                    <div className="caption">
                      <i className="bi bi-quote"></i>
                      <p>Room is as very nicely set up with a lot of snacks and welcome goodies. Thank you! Team has been very helpful and responsive as well!</p>
                      <h3>Aimee , Portland </h3>
                      <i className="bi bi-quote"></i>
                    </div>
                  </div>

                </OwlCarousel>

              </div>
            </div>
          </div>
        </section>

        {/* <section className="SAweb_gHousingGuestExpc ghPage bg-white">
          <div className="container">
            <div className="rows align-items-center" data-aos="fade-up">
              <div className="col-sm-6 col-main">
                <div>
                  <h2>The Tech That Enables a Superior Guest Experience</h2>
                  <p className="p">Through our TV App, our guests can view their reservation information, file and track service requests and learn about their apartment community.</p>
                </div>
              </div>
              <div className="col-sm-6 col-main">
                <div className="tailBox">
                  <div className="item">
                    <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/gHousing/laptop.png" alt="" />
                    <h3>Guest Portal</h3>
                  </div>
                  <div className="item">
                    <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/gHousing/mobileApp.png" alt="" />
                    <h3>Mobile App*</h3>
                  </div>
                  <div className="item">
                    <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/gHousing/apple-tv.png" alt="" />
                    <h3>TV App</h3>
                  </div>
                  <div className="item">
                    <img src="" alt="" />
                    <h4>*Q2 2023</h4>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section> */}

        {/* <section className="SAweb_ghousingCaseStudies ">
          <div className="container">
            <h2 data-aos="fade-up">Case Studies</h2>
            <div className="row" data-aos="fade-up">
              <div className="col-sm-6">
                <h3>New York City, Ny</h3>
                <h4>Case Study Title</h4>
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren</p>
              </div>
              <div className="col-sm-6">
                <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/gHousing/CaseStudies-1.jpg" alt="" width="100%" />
              </div>
            </div>


            <div className="row" data-aos="fade-up">
              <div className="col-sm-6">
                <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/gHousing/CaseStudies-2.jpg" alt="" width="100%" />
              </div>
              <div className="col-sm-6">
                <h3>New York City, Ny</h3>
                <h4>Case Study Title</h4>
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren</p>
              </div>

            </div>
            <div className="row" data-aos="fade-up">
              <div className="col-sm-6">
                <h3>New York City, Ny</h3>
                <h4>Case Study Title</h4>
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren</p>
              </div>
              <div className="col-sm-6">
                <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/gHousing/CaseStudies-3.jpg" alt="" width="100%" />
              </div>
            </div>


          </div>
        </section> */}
        <section className="border-top border-bottom border-2 border-dark  bg-white">
          <div className="container">
            <div className="row SAweb_caseStudies " data-aos="fade-up">
              <div className="col-md-1">
                <div className="HeadingRotate ">
                  <h2 className="h2_rotate">Case Studies</h2>
                </div>
              </div>
              <div className="col-md-11">
                <div className="topHeading">
                  <p>We’ve helped hundreds of managers and booking agents find the right homes for their teams.</p>
                  {/* <button type="buttton" className="btn ">View All <i className="bi bi-chevron-right"></i> </button> */}
                </div>
                <div className="boxes">
                  <div className="item hover-zoomin fullHeight" >
                    <div className="image">
                      <Link to="/case-study-1" target="_blank">
                        <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/caseStudies-1.jpg" alt="" />
                      </Link>
                    </div>
                    <div className="caption">
                      <h1>Housing solution for a leading global tech company</h1>
                      <p>A global tech company in the Bay Area was looking for a housing solution that could provide guaranteed inventory for a group.. <Link to="/case-study-1" target="_blank"><span>Read More </span> </Link></p>
                    </div>

                  </div>
                  <div className="item hover-zoomin">
                    <div className="image">
                      <Link to="/case-study-2" target="_blank">
                        <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/caseStudies-2.jpg" alt="" />
                      </Link>
                    </div>
                    <h1>Accommodations for a large Government need (Navy)</h1>
                    <p>In July of 2020, in the midst of the pandemic, a private government contractor was in need of a large volume of housing.. <Link to="/case-study-2" target="_blank"> <span>Read More </span></Link> </p>
                  </div>
                  <div className="item hover-zoomin">
                    <div className="image">
                      <Link to="/case-study-3" target="_blank">
                        <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/caseStudies-3.jpg" alt="" />
                      </Link>
                    </div>
                    <h1>Bespoke solution for a year long Intern program</h1>
                    <p>An existing client sought assistance with building a mobility program to attract overseas executives to their U.S. headquarters.. <Link to="/case-study-3" target="_blank"><span>Read More </span></Link> </p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>
        <section className="SAweb_gHousingheaderHere bg-white">

          <div className="container">
            <div className="row align-items-center" data-aos="fade-up">
              <div className="col-sm-6">
                <h2>We’re Leading Providers of Government Housing Solutions</h2>
              </div>
              <div className="col-sm-6">
                <div className="logoBox">
                  <div className="item"> <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/gHousing/headerLogo-1.jpg" alt="" width="100%" /> </div>
                  <div className="item"> <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/gHousing/headerLogo-2.jpg" alt="" width="100%" /> </div>
                  <div className="item"> <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/gHousing/headerLogo-3.jpg" alt="" width="100%" /> </div>
                </div>
              </div>
            </div>
          </div>

        </section>
        <RequestCallback />
        <Footer />



      </div>
    );
  }
}
export default GovernmentHousingNew;