import React, { useState, useEffect } from "react";
import moment from "moment";
import { Table, Input, Button } from "antd";

import { filterDataBySearch, noOfDays } from "../common/utils";
import { Link } from "react-router-dom";
import DetailsPopUpModal from "../modals/DetailsPopUp_Modal";
import Detailsmodal from "../modals/DetailsModal";

const ReservationsTable = ({
  tableData,
  handleStatusChange,
  status,
  searchText,
  setSearchText,
  filteredData,
  setFilteredData,
  changeOrderFlightInfoModal,
}) => {
  const [state, setState] = useState({
    showDrawer: false,
    rowRecord: "",
  });

  const pendingColumns = [
    {
      title: "Order#",
      dataIndex: "OrderId",
      key: "OrderId",
      sorter: (a, b) => a.OrderId - b.OrderId,
    },
    {
      title: "Name",
      dataIndex: "GuestFirstName",
      key: "GuestFirstName",
      render: (text, record) => {
        const fName =
          record.GuestFirstName == null ? "" : record.GuestFirstName;
        const lname = record.GuestLastName == null ? "" : record.GuestLastName;
        const city = (fName + " " + lname)
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
        return `${city}`;
      },
      sorter: (a, b) => {
        let nameOne = a.GuestFirstName || "";
        let nameTwo = b.GuestFirstName || "";
        return nameOne.localeCompare(nameTwo);
      },
    },
    {
      title: "lName",
      dataIndex: "GuestLastName",
      key: "GuestLastName",
      render: (text, record) => {
        const fName =
          record.GuestFirstName == null ? "" : record.GuestFirstName;
        const lname = record.GuestLastName == null ? "" : record.GuestLastName;
        const city = (fName + " " + lname)
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
        return `${city}`;
      },
      sorter: (a, b) => {
        let nameOne = a.GuestFirstName || "";
        let nameTwo = b.GuestFirstName || "";
        return nameOne.localeCompare(nameTwo);
      },
    },
    {
      title: "City",
      dataIndex: "CommunityCity",
      key: "CommunityCity",
      render: (text, record) => {
        const stateName = record.CommunityStateCode;
        const stateCode = stateName.split("-")[1];
        const city = text
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
        return `${city}, ${stateCode}`;
      },
      sorter: (a, b) => {
        let nameOne = a.CommunityCity || "";
        let nameTwo = b.CommunityCity || "";
        return nameOne.localeCompare(nameTwo);
      },
    },
    {
      title: "Community Name",
      dataIndex: "Community",
      key: "Community",
      sorter: (a, b) => {
        let nameOne = a.Community || "";
        let nameTwo = b.Community || "";
        return nameOne.localeCompare(nameTwo);
      },
      render: (text, record) => {
        const communityName = record.Community;
        const changedCommunityName = communityName.replace(/\s+/g, "-");
        return (
          <Link
            to={`/community/suiteamerica/${changedCommunityName}`}
            target={"_blank"}
          >
            {text.toLowerCase().replace(/\b\w/g, (c) => c.toUpperCase())}
            {/* {text} */}
          </Link>
        );
      },
    },
    {
      title: "Suite No",
      dataIndex: "MasterSuite",
      key: "MasterSuite",
      sorter: (a, b) => {
        let nameOne = a.MasterSuite;
        let nameTwo = b.MasterSuite;
        return nameOne.localeCompare(nameTwo);
      },
    },
    {
      title: "MoveIn Date",
      dataIndex: "MoveInDate",
      key: "MoveInDate",
      render: (text, record) => {
        const computedValue = moment(record.MoveInDate).format("MM/DD/YYYY");
        return <span>{computedValue}</span>;
      },
      sorter: (a, b) => (moment(a.MoveInDate).isBefore(b.MoveInDate) ? -1 : 1),
    },
    {
      title: "MoveOut Date",
      dataIndex: "MoveOutDate",
      key: "MoveOutDate",
      render: (text, record) => {
        const computedValue = moment(record.MoveOutDate).format("MM/DD/YYYY");
        return <span>{computedValue}</span>;
      },
      sorter: (a, b) =>
        moment(a.MoveOutDate).isBefore(b.MoveOutDate) ? -1 : 1,
    },

    {
      title: "Size",
      dataIndex: "MasterSuiteSize",
      key: "MasterSuiteSize",
      sorter: (a, b) => {
        let nameOne = a.MasterSuiteSize;
        let nameTwo = b.MasterSuiteSize;
        return nameOne.localeCompare(nameTwo);
      },
    },

    // {
    //   title: "Flight Info",
    //   // dataIndex: "Community",
    //   key: "Flight Info",
    //   render: (text, record) => {
    //     return (
    //       <i
    //         className="mdi mdi-airplane cursor-pointer"
    //         data-toggle="modal"
    //         data-target="#holdModal"
    //         onClick={() => changeOrderFlightInfoModal(record)}
    //       ></i>
    //     );
    //   },
    // },
    {
      title: "BR",
      dataIndex: "BR",
      key: "BR",
      render: (text, record) => {
        const BrVal = record.BR == 0 ? "" : record.BR;
        return <span>{BrVal}</span>;
      },
      sorter: (a, b) => {
        let nameOne = (a.BR || "").toString();
        let nameTwo = (b.BR || "").toString();
        return nameOne.localeCompare(nameTwo);
      },
    },
    // {
    //   title: "PO#",
    //   dataIndex: "PurchaseOrder",
    //   key: "PurchaseOrder",
    //   sorter: (a, b) => {
    //     let nameOne = a.PurchaseOrder || "";
    //     let nameTwo = b.PurchaseOrder || "";
    //     return nameOne.localeCompare(nameTwo);
    //   },
    // },
    {
      title: "",
      // dataIndex: "Community",
      key: "Details Info",
      render: (text, record) => {
        return (
          <i
            className="mdi mdi-dots-vertical cursor-pointer"
            // data-toggle="modal"
            // data-target="#detailsModal"
            onClick={() => handleshowDrawer(record)}
          ></i>
        );
      },
    },
  ];

  const activeTermedColumns = [
    {
      title: "Order#",
      dataIndex: "OrderId",
      key: "OrderId",
      sorter: (a, b) => a.OrderId - b.OrderId,
    },
    {
      title: "Name",
      dataIndex: "GuestFirstName",
      key: "GuestFirstName",
      render: (text, record) => {
        const fName =
          record.GuestFirstName == null ? "" : record.GuestFirstName;
        const lname = record.GuestLastName == null ? "" : record.GuestLastName;
        const city = (fName + " " + lname)
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
        return `${city}`;
      },
      sorter: (a, b) => {
        let nameOne = a.GuestFirstName || "";
        let nameTwo = b.GuestFirstName || "";
        return nameOne.localeCompare(nameTwo);
      },
    },
    {
      title: "lName",
      dataIndex: "GuestLastName",
      key: "GuestLastName",
      render: (text, record) => {
        const fName = record.GuestFirstName;
        const lname = " " + record.GuestLastName;
        const city = (fName + " " + lname)
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
        return `${city}`;
      },
      sorter: (a, b) => {
        let nameOne = a.GuestFirstName || "";
        let nameTwo = b.GuestFirstName || "";
        return nameOne.localeCompare(nameTwo);
      },
    },
    {
      title: "City",
      dataIndex: "CommunityCity",
      key: "CommunityCity",
      render: (text, record) => {
        if (text) {
          const stateName = record.CommunityStateCode;
          const stateCode = stateName.split("-")[1];
          const city = text
            .toLowerCase()
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
          return `${city}, ${stateCode}`;
        } else {
          return "";
        }
      },
      sorter: (a, b) => {
        let nameOne = a.CommunityCity || "";
        let nameTwo = b.CommunityCity || "";
        return nameOne.localeCompare(nameTwo);
      },
    },

    {
      title: "Community Name",
      dataIndex: "Community",
      key: "Community",
      sorter: (a, b) => {
        let nameOne = a.Community || "";
        let nameTwo = b.Community || "";
        return nameOne.localeCompare(nameTwo);
      },
      render: (text, record) => {
        if (text) {
          const communityName = record.Community;
          const changedCommunityName = communityName.replace(/\s+/g, "-");
          return (
            <Link
              to={`/community/suiteamerica/${changedCommunityName}`}
              target={"_blank"}
            >
              {text.toLowerCase().replace(/\b\w/g, (c) => c.toUpperCase())}
            </Link>
          );
        } else {
          return "";
        }
      },
    },
    {
      title: "Suite No",
      dataIndex: "MasterSuite",
      key: "MasterSuite",
      sorter: (a, b) => {
        let nameOne = a.MasterSuite;
        let nameTwo = b.MasterSuite;
        return nameOne.localeCompare(nameTwo);
      },
    },
    {
      title: "MoveIn Date",
      dataIndex: "MoveInDate",
      key: "MoveInDate",
      // defaultSortOrder: "ascend", // Add this line to set default sorting order
      render: (text, record) => {
        const computedValue = moment(record.MoveInDate).format("MM/DD/YYYY");
        return <span>{computedValue}</span>;
      },
      sorter: (a, b) => (moment(a.MoveInDate).isBefore(b.MoveInDate) ? -1 : 1),
    },
    {
      title: "MoveOut Date",
      dataIndex: "MoveOutDate",
      key: "MoveOutDate",
      render: (text, record) => {
        const computedValue = moment(record.MoveOutDate).format("MM/DD/YYYY");
        return <span>{computedValue}</span>;
      },
      sorter: (a, b) =>
        moment(a.MoveOutDate).isBefore(b.MoveOutDate) ? -1 : 1,
    },
    {
      title: "Size",
      dataIndex: "MasterSuiteSize",
      key: "MasterSuiteSize",
      sorter: (a, b) => {
        let nameOne = a.MasterSuiteSize;
        let nameTwo = b.MasterSuiteSize;
        return nameOne.localeCompare(nameTwo);
      },
    },

    // {
    //   title: "Daily Rate",
    //   dataIndex: "Community",
    //   key: "Daily Rate",
    // },

    // {
    //   title: "Flight Info",
    //   // dataIndex: "Community",
    //   key: "Flight Info",
    //   render: (text, record) => {
    //     return <i className="mdi mdi-airplane"></i>;
    //   },
    // },
    {
      title: "BR",
      dataIndex: "BR",
      key: "BR",
      render: (text, record) => {
        const BrVal = record.BR == 0 ? "" : record.BR;
        return <span>{BrVal}</span>;
      },
      sorter: (a, b) => {
        let nameOne = (a.BR || "").toString();
        let nameTwo = (b.BR || "").toString();
        return nameOne.localeCompare(nameTwo);
      },
    },
    // {
    //   title: "PO#",
    //   dataIndex: "PurchaseOrder",
    //   key: "PurchaseOrder",
    //   sorter: (a, b) => {
    //     let nameOne = a.PurchaseOrder || "";
    //     let nameTwo = b.PurchaseOrder || "";
    //     return nameOne.localeCompare(nameTwo);
    //   },
    // },
    {
      title: "",
      // dataIndex: "Community",
      key: "Details Info",
      render: (text, record) => {
        return (
          <i
            className="mdi mdi-dots-vertical cursor-pointer"
            // data-toggle="modal"
            // data-target="#detailsModal"
            onClick={() => handleshowDrawer(record)}
          ></i>
        );
      },
    },
  ];
  // const [searchText, setSearchText] = useState("");
  // const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (!searchText) {
      const sortedOrders = sortByMoveInDate(tableData);
      setFilteredData(sortedOrders);
    }
  }, [searchText, tableData]);
  const sortByMoveInDate = (data) => {
    return [...data].sort((a, b) => {
      const dateA = new Date(a.MoveInDate);
      const dateB = new Date(b.MoveInDate);
      return dateB - dateA;
    });
  };
  function handleshowDrawer(record) {
    setState((prev) => ({ ...prev, showDrawer: true, rowRecord: record }));
  }
  return (
    <div className=" align-self-center p-3 card bg-white m-b-30">
      <div className=" d-flex bg-white align-items-center justify-content-between mrg-top-20 dashboardOrderStatusBtn">
        <div>
          <button
            className={`btn btn-primary rounded-5 mrg-right-20 ${
              status == "active" && "active"
            }`}
            id="active"
            // disabled={searchText.trim() !== "" && status !== "active"}
            onClick={handleStatusChange}
          >
            Active
          </button>
          <button
            className={`btn btn-primary rounded-5 mrg-right-20 ${
              status == "pending" && "active"
            }`}
            id="pending"
            // disabled={searchText.trim() !== "" && status !== "pending"}
            onClick={handleStatusChange}
          >
            Upcoming
          </button>

          <button
            className={`btn btn-primary rounded-5 ${
              status == "termed" && "active"
            }`}
            id="termed"
            // disabled={searchText.trim() !== "" && status !== "termed"}
            onClick={handleStatusChange}
          >
            Moved Out
          </button>
        </div>
        <a
          href="#"
          style={{ color: "#0066cc" }}
          data-toggle="modal"
          data-target="#service-orders-modal"
          className="bookit-dashboard-right-arrow"
        >
          Open Services{" "}
          {/* <img
            src="assets/images/users/share.png"
            alt="user"
            className="rounded-circle"
          /> */}
          <i className="mdi mdi-chevron-right"></i>
        </a>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <Input.Search
          placeholder="Search"
          style={{
            width: 200,
          }}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onPressEnter={() => {
            // const sanitizedSearchText = searchText.replace(/\s+/g, "");
            setFilteredData(
              filterDataBySearch(searchText, tableData, [
                "OrderId",
                "GuestFirstName",
                "GuestLastName",
                "MoveInDate",
                "MoveOutDate",
                "Community",
                "PurchaseOrder",
                "CommunityCity",
                "CombinedGuestName", // Added combined field
                "CombinedCItyState",
              ])
            );
          }}
        />
      </div>

      <Table
        columns={
          status === "pending"
            ? pendingColumns.filter((col) => col.dataIndex !== "GuestLastName")
            : activeTermedColumns.filter(
                (col) => col.dataIndex !== "GuestLastName"
              )
        }
        defaultSortOrder={{ column: "MoveInDate", order: "ascend" }} // Set default sorting here
        dataSource={filteredData}
        pagination={{
          pageSize: 20,
          showSizeChanger: false,
          showQuickJumper: true,
        }}
        onChange={(pagination, filters, sorter) => {
          // Handle pagination, sorting, and filtering
        }}
      />
      {/* <DetailsPopUpModal /> */}
      <Detailsmodal state={state} setState={setState} />
    </div>
  );
};

export default ReservationsTable;
