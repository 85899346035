import HtmlParser from "react-html-parser";

const formatDate = (dateString) => {
  const [day, month, year] = dateString.split("-");
  const monthMap = {
    January: 0,
    February: 1,
    March: 2,
    April: 3,
    May: 4,
    June: 5,
    July: 6,
    August: 7,
    September: 8,
    October: 9,
    November: 10,
    December: 11,
  };
  return new Date(year, monthMap[month], day);
};

const sortByLatest = (blogData) => {
  const sortedBlogPosts = blogData.sort(
    (a, b) => formatDate(b.createdDate) - formatDate(a.createdDate)
  );
  return sortedBlogPosts;
};

const latestBlogs = (sortedBlogsData, currentBlog) => {
  // console.log(sortedBlogsData, currentBlog);
  let fiveLatestBlogs = [];

  for (let i = 0; i < sortedBlogsData.length; i++) {
    if (!(fiveLatestBlogs.length < 5)) {
      // console.log("return", fiveLatestBlogs);
      return fiveLatestBlogs;
    }

    if (!(currentBlog.title === sortedBlogsData[i].title)) {
      fiveLatestBlogs.push(sortedBlogsData[i]);
    }
  }
};

function formatDateForDisplay(inputDate) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [day, month, year] = inputDate.split("-");

  const daySuffix = getDaySuffix(day);

  return HtmlParser(`${day}<sup>${daySuffix}</sup> ${month}, ${year}`);
}

function getDaySuffix(day) {
  if (day >= 11 && day <= 13) {
    return "th";
  }
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

export { formatDate, sortByLatest, latestBlogs, formatDateForDisplay };
