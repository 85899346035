/* Importing the node modules, child components, services and controllers used 
   inside BookNow component */
import $ from "jquery";
import DatePicker from 'react-datepicker'
import moment from 'moment'
import BookNowModel from './model/bookNowModel';
import QuoteOrderModel from "./model/quoteOrderModel";

/* BookNow Component initialization */
class BookNow extends React.Component {
    /* Initializing objects of its BookNow class */
    constructor(props) {
        super(props)
        this.state = {
            checkIn: moment(localStorage.getItem('starbuckscheckIn')),
            checkOut: moment(localStorage.getItem('starbuckscheckOut')),
            communityObject: props.communityObject,
            firstName: '',
            lastName: '',
            emailId: '',
            phoneNo: '',
            mail: '',
            subject: '',
            color: '',
            errorMessage: '',
            orderResponse: [],
            airline: "",
            flight: '',
            pnr: '',
            type: '',
            typeData: ['Virtual Pay', 'Corporate CC'],
            loadingStatus: false,
            reservedbyemail: '',
            purchaseorder: '',
            notes: '',
            ArrivalAirport: '',
            ArrivalDate: null
        }
        this.BookNowModel = new BookNowModel(this)
        this.checkInSelection = this.checkInSelection.bind(this);
        this.checkOutSelection = this.checkOutSelection.bind(this);
        this.setValue = this.setValue.bind(this);
        this.setNumber = this.setNumber.bind(this);
        this.selectionArrivalDate = this.selectionArrivalDate.bind(this);
    }

    async componentDidMount() {
        $(document).ready(function () {
            jQuery(function ($) {
                $("#phone2").intlTelInput();
            });
        });
    }
    setNumber(e) {
        const re = /^[0-9\b]+$/;
        if (e.target.value == '' || re.test(e.target.value)) {
            this.setState({ phoneNo: e.target.value })
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.travelObj == undefined || Object.keys(this.props.travelObj).length === 0) {
            this.setState(({ firstName: '', lastName: '', emailId: '', phoneNo: '', errorMessage: '', color: '', purchaseorder: '' }));
        }
        else {

            var name = (this.props.travelObj.Traveler == null) ? ['', ''] : this.props.travelObj.Traveler.split("/");

            this.setState(({ firstName: this.props.travelObj.Traveler != '' ? name[1] : '', lastName: this.props.travelObj.Traveler != '' ? name[0] : '', emailId: this.props.travelObj.Email != '' ? this.props.travelObj.Email : '', phoneNo: '', errorMessage: '', color: '' }));
        }
    }

    async checkInSelection(date) {
        await this.setState({ checkIn: moment(date) });
    }
    clearData() {
        this.setState({ firstName: '', lastName: '', emailId: '', phoneNo: '' });
    }
    async checkOutSelection(date) {
        await this.setState({ checkOut: moment(date) });
    }
    setValue(field, e) {
        var object = {};
        object[field] = e.target.value;
        this.setState(object);
    }
    onChangevalue(e) {
        const re = /^[a-zA-Z0-9]+$/;
        if (e.target.value == '' || re.test(e.target.value)) {
            this.setState({ purchaseorder: e.target.value })
        }
    }
    async selectionArrivalDate(date) {
        await this.setState({ ArrivalDate: moment(date) });
    }
    /* It is invoked to return html content */
    render() {
        return (
            <div>
                <div className="modal fade savesearch bookingmodel" id="travelbooknow" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content rm-border-radius">
                            <div className="modal-header">
                                <button type="button" className="close btn" data-dismiss="modal" aria-label="">
                                    <i className="bi bi-x"></i>
                                </button>
                                <h4 className="modal-title text-center font-bold">Book Now </h4>
                            </div>
                            <div className="text-center" style={{ color: this.state.color }}>{this.state.errorMessage}</div>
                            <div className="modal-body">

                                <form role="form" method="post" >
                                    <div>

                                        <div className="row">
                                            <div className="col-sm-12 communityName">
                                                <span><strong>Community Name:</strong></span>
                                                <span className="text-red">{this.props.communityObject.community_name}</span>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <DatePicker type="text" className="form-control " name="date" placeholder="Due Date" selected={this.state.checkIn}
                                                        onChange={this.checkInSelection} disabled />
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <DatePicker type="text" className="form-control " name="date" placeholder="Due Date" selected={this.state.checkOut}
                                                        onChange={this.checkOutSelection} disabled />
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <input type="text" className="form-control" placeholder="First Name *" value={this.state.firstName} onChange={(e) => this.setValue('firstName', e)} />
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <input type="text" className="form-control" placeholder="Last Name *" value={this.state.lastName} onChange={(e) => this.setValue('lastName', e)} />
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <input className="form-control" id="phone2" placeholder='Phone' maxLength="15" onChange={(e) => this.setNumber(e)} value={this.state.phoneNo} />
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <input type="text" className="form-control" placeholder="Guest Email *" value={this.state.emailId}
                                                        onChange={(e) => this.setValue('emailId', e)} />
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <input type="text" className="form-control" placeholder="Reserved Email*" value={this.state.reservedbyemail}
                                                        onChange={(e) => this.setValue('reservedbyemail', e)} />
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <input type="text" className="form-control" maxLength="10" placeholder="Cost center" value={this.state.purchaseorder}
                                                        onChange={(e) => this.onChangevalue(e)} />
                                                </div>
                                            </div>
                                        </div>


                                        <hr></hr>

                                        {(this.props.travelObj == undefined || Object.keys(this.props.travelObj).length == 0) ?

                                            <div className="row">
                                                <div className="col-sm-12 communityName">
                                                    <span><strong>Arrival Info:</strong></span>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <input type="text" className="form-control" placeholder="Flight#" value={this.state.flight}
                                                            onChange={(e) => this.setValue('flight', e)} />
                                                    </div>
                                                </div>

                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <input type='text' className="form-control" placeholder="ArrivalAirport" value={this.state.ArrivalAirport} onChange={(e) => this.setValue('ArrivalAirport', e)} />
                                                    </div>
                                                </div>

                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <DatePicker type="text" className="form-control" name="date"
                                                            placeholderText="ArrivalDate"
                                                            selected={this.state.ArrivalDate}
                                                            onChange={(e) => this.selectionArrivalDate(e)}
                                                            showTimeSelect
                                                            timeFormat="HH:mm"
                                                            timeIntervals={15}
                                                            timeCaption="time"
                                                            dateFormat="MM/DD/YYYY HH:mm"
                                                            onKeyDown={(e) => {
                                                                e.preventDefault();
                                                            }}
                                                            autoComplete='off'
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <input type="text" className="form-control" placeholder="Airline" value={this.state.airline}
                                                            onChange={(e) => this.setValue('airline', e)} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <textarea className="form-control" placeholder="Arrival Notes" value={this.state.notes} onChange={(e) => this.setValue('notes', e)}></textarea>
                                                    </div>
                                                </div>
                                                {/* <div className="col-sm-6">
                                     <div className="form-group">
                                      <input type="text" className="form-control" placeholder="PNR" value={this.state.pnr} 
                                      onChange={(e) => this.setValue('pnr', e)}/>
                                    </div>
                                    </div> */}

                                            </div> : ''}
                                        <div className="clearfix"></div>
                                        {this.state.loadingStatus ? <div
                                            className='text-center'
                                            style={{ fontSize: '25px', width: '100%', margin: 'auto', textAlign: 'center' }}
                                        ><i className="fa fa-spinner fa-spin" />
                                            Please wait ...!</div> : <button className="btn location_btn mrg-btm-50" type="button" onClick={() => { this.BookNowModel.bookDetails() }}>Submit</button>}
                                    </div>

                                </form>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade savesearch" id="successModal" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content rm-border-radius">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="">
                                    <span>×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <h2>Thank You!</h2>
                                <p>Your suite has been Reserved</p>
                            </div>
                        </div>
                    </div>
                </div>
                <QuoteOrderModel />
            </div>

        );
    }
}
export default BookNow;