
import AppController from "../../controller/appController";
class Weather extends React.Component {
  render() {
    return (
      <div>
        <div>
          <h2 className="weather-title">Weather</h2>
          <div className="weather-control">
            <div className="weather-city">{this.props.city}, &nbsp;
                              {this.props.state}</div>
            {this.props.weather_current != undefined && this.props.weather!= undefined?
              <div className="weather-card ">
                <div className="weather-card-item ">
                <div className="weather-card-img">
               <div className="weather-dates"> <h4 >Today</h4> </div>
                      <img src={"https://openweathermap.org/img/w/" + this.props.weather_current.weather[0].icon + ".png"} alt="" title="" />
                    </div>
                  <div className="weather-card-controls">
                  <h5>{((this.props.weather_current.main.temp_max * (9 / 5)) + 32).toFixed(0)}<sup>o</sup> F high</h5>
                   <h5> <div>{ this.props.weather_current.main.temp_max.toFixed(0)}<sup>o</sup>C | { this.props.weather_current.main.temp_min.toFixed(0)}<sup>o</sup>C</div></h5>
                  </div>
                </div>
                {this.props.weather.map((row, index) => {
                 let dateResult = row.weatherData.date.split("-");
                 let date =   dateResult[0]+'/'+dateResult[1]+"/"+dateResult[2];
                 return <div className="weather-card-item" key={index}>
                    <div className="weather-dates">
                    <h5>{AppController.getDayName(date)}</h5>
                      <h5>{AppController.getMonthName(date)+' '+AppController.getDay(date)}</h5>
                    </div>
                    <div className="weather-card-img">
                      <img src={"https://openweathermap.org/img/w/" + row.weatherData.weather[0].icon + ".png"} alt="" title="" />
                    </div>

                    <div className="weather-card-controls">
                      <h5>{((row.weatherDataMax * (9 / 5)) + 32).toFixed(0)}<sup>o</sup> F high</h5>
                      <h5>{row.weatherDataMax.toFixed(0)}<sup>o</sup>C | {row.weatherDataMin.toFixed(0)}<sup>o</sup>C</h5>
                    </div>
                  </div>
                })}
              </div> : null}
          </div>
        </div>



      </div>
    )
  }
}
export default Weather;
