/* Importing the node modules, child components, services and controllers used 
   inside CeDashboard component */
import $ from "jquery";
import moment from 'moment';
import ClientApiService from '../../../services/clientApiService';
import MainController from "../../controller/mainController";
import AltairHeader from '../../common/altairHeader';

import SessionService from "../../../services/sessionService";
import ClientPortalController from "../../controller/clientPortalController";

import TableData from './tableData';
import InventroyTableData from './inventroyTableData';

/* CeDashboard Component initialization */
class AltairDashboard extends React.Component {
    /* Initializing objects of its CeDashboard class */
    constructor(props) {
        super(props)
        /* CeDashboard Component State variables Initialization */
        this.state = {
            inventoryData: [],
            ordersData: [],
            collapseIn: false,
            tableID: 0,
            selectValue: 'Community',
            sortby: false,
            inventorySortBy: false,
            index: -1,
            servicesData: [],
            closedData: [],
            singleService: {},
            more: 1,
            clientInfo: {},
            randNumber: -1,
            avgCountLoading: false,
            stayingData: [],
            bookedData: [],
            pastData: [],
            avgLengthofStay: 0,
            savings: 0,
            totalordersGraph: [],
            searchValue: '',
            statusType: 'Inventory',
            moveInDate: '',
            moveOutDate: '',
            type: 0,
            servicesOpenCount: 0,
            servicesAnalysis: [],
            communityAnalysis: [],
            communityList: [],
            countsInfo: [
                { name: 'Reservations#', color: 'blue', status: 'PENDING', moreInfo: [] },
                { name: 'Active#', color: 'green', status: 'ACTIVE', moreInfo: [] },
                { name: 'Open Services', color: 'red', status: 'openservice', moreInfo: [] }
            ],
            analysisCountInfo: [],
            analysisLoading: false,
            AnalysisName: '',
            AnalysisType: '',
            communityAnalysisCountInfo: [],
            analysisModalLoading: false,
            communityAnalysisName: '',
            communityAnalysisType: '',
            analysisMoveInDate: '',
            analysisMoveOutDate: '',
            analysisCountType: 0,
            communitysLoading: false,
            totalOrdersLoading: false,
            guestFirstName: '',
            clientIds: '',
            singlesurveyInfo: '',
            surveyInfo: {
                "message": []
            }
        }

        this.sortBy = this.sortBy.bind(this)
        this.inventorySortBy = this.inventorySortBy.bind(this)
        this.viewSelction = this.viewSelction.bind(this)
        this.communitySelection = this.communitySelection.bind(this)
        this.addressSelection = this.addressSelection.bind(this)
        this.buttonsChanges = this.buttonsChanges.bind(this)
        this.changeSearchValue = this.changeSearchValue.bind(this)
        this.exportExcel = this.exportExcel.bind(this)
        this.analysisCount = this.analysisCount.bind(this)
        this.communityAnalysisCount = this.communityAnalysisCount.bind(this)
        this.changeStatus = this.changeStatus.bind(this)
        this.surveyAnalysisCount = this.surveyAnalysisCount.bind(this)
        this.inventorybyProgram = this.inventorybyProgram.bind(this)

    }

    /* It is invoked immediately before mounting occurs */
    async componentWillMount() {
        MainController.getInitialInformation();
        await this.ClientInfo();
        await this.getSurveyInfo();
        // await this.dashboardInfo(moment().startOf('year').format('MM-DD-YYYY'),moment().endOf('year').format('MM-DD-YYYY'), 1, this.state.statusType, moment().startOf('year').format('YYYY-MM-DD'),moment().endOf('year').format('YYYY-MM-DD'))
        await this.dashboardInfo(moment().startOf('year').format('MM-DD-YYYY'), moment().endOf('year').format('MM-DD-YYYY'), 1, this.state.statusType, moment().startOf('year').format('YYYY-MM-DD'), moment().endOf('year').format('YYYY-MM-DD'))

    }

    async componentDidMount() {
        this.inventorybyProgram();
    }

    async getSurveyInfo() {
        // const result= await ClientApiService.GetAllCESurveyInfo();

        //    if(result.error == 0){
        //         await this.setState({surveyInfo:result});
        //     }
        const result = 0;

        if (result == 0) {
            await this.setState({ surveyInfo: result });
        }


    }
    async surveyAnalysisCount(data) {
        //console.log(data,'data');
        await this.setState({ singlesurveyInfo: data });

    }
    /* To change calendar data */
    calendar(randNumber) {
        const _this = this
        jQuery(function () {
            var start = moment().startOf('year');
            var end = moment().endOf('year');
            function cb(start, end) {
                jQuery('#reportrange' + randNumber + ' span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
            }
            async function myFun(start, end) {
                jQuery('#reportrange' + randNumber + ' span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
                _this.setState({ stayingData: [], bookedData: [], pastData: [] });
                await _this.dashboardInfo(start.format('MM-DD-YYYY'), end.format('MM-DD-YYYY'), -1, _this.state.statusType, start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
                await _this.OrdersList(start.format('MM-DD-YYYY'), end.format('MM-DD-YYYY'), _this.state.statusType);
            }
            jQuery('#reportrange' + randNumber).daterangepicker({
                startDate: start,
                endDate: end,
                minDate: '01-01-2019',
                ranges: {
                    'Today': [moment(), moment()],
                    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                    'This Year': [moment().startOf('year'), moment().endOf('year')],
                    "Last Year": [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
                }
            }, myFun);
            cb(start, end);
        })
    }

    async ClientInfo() {
        const userData = await SessionService.SessionGet({ type: 'altair' });
        //this.setState({clientInfo:userData,clientIds:'22559,26706,26456,26460,17237,26408,26409,26410,28376'})
        // this.setState({clientInfo:userData,clientIds:'10000,10001,28389,0'})
        this.setState({ clientInfo: userData, clientIds: '29402' })
    }

    requestObj(moveIndate, moveOutdate) {
        var clientId = this.state.clientIds;
        const requestObj = { clientemail: clientId, fromdate: moveIndate, todate: moveOutdate }
        return requestObj;
    }

    async dashboardInfo(moveIndate, moveOutdate, type, statusType, analysisMoveIn, analysisMoveOut) {
        const randNumber = type == 1 ? Math.floor(Math.random() * 1000000) + 1 : this.state.randNumber
        await this.setState({
            communityList: [],
            avgCountLoading: true,
            analysisLoading: true,
            totalOrdersLoading: true,
            randNumber,
            type,
            moveInDate: moveIndate,
            moveOutDate: moveOutdate,
            statusType,
            ordersData: [],
            communityAnalysis: [],
            servicesAnalysis: []
        });
        type == 1 ? await this.calendar(randNumber) : ''

        const ReqObj = {
            ClientId: this.state.clientIds,
            MoveInDate: moveIndate,
            MoveOutDate: moveOutdate,

        }
        let ordersCountInfo = await ClientApiService.GetOrdersCountByClientEmailId(ReqObj)

        const requestObj = await this.requestObj(analysisMoveIn, analysisMoveOut);
        let communityAnalysisRes = await ClientApiService.GetWebCategoryServicesInfo(requestObj)
        let serviceAnalysisRes = await ClientApiService.GetWebServicesInfo(requestObj)
        communityAnalysisRes = _.orderBy(communityAnalysisRes, ['CategoryCode'], ['asc'])
        serviceAnalysisRes = _.filter(serviceAnalysisRes, function (o) { return (o.ComplexName != null); });
        serviceAnalysisRes = _.orderBy(serviceAnalysisRes, ['ComplexName'], ['asc'])
        const servicesOpenCount = _.sumBy(serviceAnalysisRes, function (o) { return o.StartedServices; });
        const servicesInfo = _.filter(serviceAnalysisRes, function (o) { return o.StartedServices != 0; });

        await this.setState({
            analysisMoveInDate: analysisMoveIn,
            analysisMoveOutDate: analysisMoveOut,
            communityAnalysis: communityAnalysisRes,
            servicesAnalysis: serviceAnalysisRes,
            analysisLoading: false,

            avgCountLoading: false,
            countsInfo: [
                { name: 'Reservations#', color: 'blue', counts: ordersCountInfo.Pending == null ? 0 : ordersCountInfo.Pending, status: 'PENDING', moreInfo: [] },
                { name: 'Active#', color: 'green', counts: ordersCountInfo.Active == null ? 0 : ordersCountInfo.Active, status: 'ACTIVE', moreInfo: [] },
                { name: 'Open Services', color: 'red', counts: servicesOpenCount, status: 'openservice', moreInfo: servicesInfo },
            ],
            communityList: []
        })
        $('.analsys_table option:last-child').text('All');


        await this.getOrdersByEmail(moveIndate, moveOutdate, '')
    }



    async getOrdersByEmail(moveIndate, moveOutdate) {
        let orderListResult = await this.ordersbyClient(moveIndate, moveOutdate, '')
        orderListResult = _.orderBy(orderListResult, ['orderno'], ['desc']);
        const stayingData = _.filter(orderListResult, function (o) { return o.StatusCode == "ACTIVE"; });
        const bookedData = _.filter(orderListResult, function (o) { return (o.StatusCode == "PENDING" || o.StatusCode == "VOID"); });
        const pastData = _.filter(orderListResult, function (o) { return o.StatusCode == "TERMED"; });

        let stayData = stayingData != undefined ? stayingData : []
        let bookData = bookedData != undefined ? bookedData : []
        let historyData = pastData != undefined ? pastData : []
        orderListResult = _.filter(orderListResult, function (o) { return (o.community_name != null); });
        orderListResult = _.orderBy(orderListResult, ['community_name'], ['asc'])
        let result = _.chain(orderListResult).groupBy("community_name").map(function (v, i) {
            return {
                Community: i,
                Pending: _.filter(v, function (o) { return o.StatusCode == 'PENDING'; }),
                Active: _.filter(v, function (o) { return o.StatusCode == 'ACTIVE'; }),
                Termed: _.filter(v, function (o) { return o.StatusCode == 'TERMED'; }),
                City: _.get(_.find(v, 'city'), 'city'),
            }
        }).value();
        await this.setState({
            totalOrdersLoading: false,
            stayingData: stayData,
            bookedData: bookData,
            pastData: historyData,
            communityList: result
        })
        $('.community_Analysis option:last-child').text('All');
    }


    async inventorybyProgram() {

        await this.setState({ communitysLoading: true, inventoryData: [], statusType: 'Inventory', searchValue: '', index: -1 })
        const request = {
            program: 'altair'
            // program: 'general'     
        }
        const inventoryDataRes = await ClientApiService.getInventoryByProgram(request)
        console.log('inventoryDataRes', inventoryDataRes)
        const inventoryData = _.filter(inventoryDataRes.message, function (o) {
            o.VipLevel = o.VipLevel != null ? (o.VipLevel == "VIP4" ? "CP2" : o.VipLevel) : "---";
            if (o.OrderStatus != null) {
                if (o.OrderStatus == "ACTIVE") {
                    o.OrderStatus = "Staying"
                } else if (o.OrderStatus == "TERMED") {
                    // o.OrderStatus="Moved Out"
                    o.OrderStatus = "---"
                    o.PurchaseOrder = "---"
                    o.GuestMoveOut = "---"
                    o.VipLevel = "---"
                } else if (o.OrderStatus == "PENDING") {
                    o.OrderStatus = "Pending"
                } else {
                    o.OrderStatus = "---";
                }
            }
            else {
                o.OrderStatus = "---";
            }


            if (o.AvailFrom) {
                if (moment(o.AvailFrom).isBefore(moment().format("YYYY-MM-DD"))) {
                    // o.AvailFrom='2021-08-01T00:00:00'
                    // o.AvailFrom = moment();
                    o.AvailFrom = moment().format("YYYY-MM-DD") + 'T00:00:00';
                    // console.log(o.AvailFrom)
                } else {
                    o.AvailFrom = o.AvailFrom
                }
            }
            else {
                o.AvailFrom = "---";
            }

            // if (o.AvailTo) {
            //     if (moment(o.AvailTo).isAfter('2022-07-31')) {
            //         o.AvailTo = '2022-07-31T00:00:00'
            //     } else if (o.MasterId == '363261' || o.MasterId == '363259') {
            //         if (moment(o.AvailTo).isAfter('2022-07-01')) {
            //             o.AvailTo = '2022-07-31T00:00:00'
            //         } else {
            //             o.AvailTo = o.AvailTo
            //         }
            //     } else {
            //         o.AvailTo = o.AvailTo
            //     }
            // }
            // else {
            //     o.AvailTo = "---";
            // }

            return moment(o.AvailTo).isSameOrAfter(moment()) && o
        });

        console.log('inventoryData', inventoryData)


        await this.setState({ inventoryData: inventoryData, communitysLoading: false })

    }


    async ordersbyClient(moveIndate, moveOutdate, statusType) {
        const userEmail = {
            ClientId: this.state.clientIds,
            LoadMore: '',
            MoveInDate: moveIndate,
            MoveOutDate: moveOutdate,
            StatusCode: statusType
        }
        const ordersData = await ClientApiService.getOrdersByClientEmailId(userEmail)


        // console.log("ordersData:",ordersData);


        return ordersData;
    }


    async ordersbyStatus(moveIndate, moveOutdate, statusType, data) {
        await this.setState({ communitysLoading: true, ordersData: [], inventoryData: [] })
        const userEmail = {
            ClientId: this.state.clientIds,
            LoadMore: '',
            MoveInDate: moveIndate,
            MoveOutDate: moveOutdate,
            StatusCode: statusType
        }
        const ordersData = await ClientApiService.getOrdersByClientEmailId(userEmail)
        this.setState({ ordersData: ordersData, communitysLoading: false })
        $('.orderStatus_moadl option:last-child').text('All');
    }

    /* To get the order list */
    async OrdersList(moveIndate, moveOutdate, statusType) {
        switch (statusType) {
            case 'ACTIVE':
                this.state.stayingData.length > 0 ? await this.setState({ ordersData: this.state.stayingData }) : this.ordersbyStatus(moveIndate, moveOutdate, statusType, 'stayingData');
                $('.orderStatus_moadl option:last-child').text('All');
                break;
            case 'PENDING':
                this.state.bookedData.length > 0 ? await this.setState({ ordersData: this.state.bookedData }) : this.ordersbyStatus(moveIndate, moveOutdate, statusType, 'bookedData');
                $('.orderStatus_moadl option:last-child').text('All');
                break;
            case 'TERMED':
                this.state.pastData.length > 0 ? await this.setState({ ordersData: this.state.pastData }) : this.ordersbyStatus(moveIndate, moveOutdate, statusType, 'pastData');
                $('.orderStatus_moadl option:last-child').text('All');
                break;
        }
    }
    /* To change the status type */
    changeStatus(statusType) {
        // console.log(statusType,'statusType');

        this.setState({ statusType: statusType, searchValue: '', index: -1 });
        this.OrdersList(this.state.moveInDate, this.state.moveOutDate, statusType);
    }
    /* Search field value updattion */
    changeSearchValue(field, e) {
        var object = {};
        object[field] = e.target.value;
        this.setState(object);
    }
    buttonsChanges(name) {
        this.setState({ selectValue: name })
    }
    async viewSelction(row, index) {
        if (!this.state.collapseIn || this.state.tableID != row.orderno) {
            this.setState({ 'index': index, selectValue: '' });
            $('.panel-collapse.collapse').removeClass("in")
            $('.panel-title a').addClass('collapsed')
            this.setState({ collapseIn: !this.state.collapseIn, tableID: row.orderno })
            const orderDetails = {
                orderUId: row.UniqueId,
            }
            const orderResponse = await ClientApiService.getServicesByOrderUId(orderDetails);
            const openService = await _.filter(orderResponse, function (o) { return _.toLower(o.FivestarStatus) != "completed"; })
            const closedServices = await _.filter(orderResponse, function (o) { return _.toLower(o.FivestarStatus) == "completed"; })

            await this.setState({ servicesData: openService, guestFirstName: row.GuestFirstName, closedData: closedServices, selectValue: 'Community' })
        }
        else {
            this.setState({ 'index': -1 });
            this.setState({ collapseIn: !this.state.collapseIn })
        }
    }
    /* To sort the list by ascending or descending */
    async sortBy(name) {
        this.setState({ sortby: !this.state.sortby, sortId: !this.state.sortby });
        var sortType = ''
        if (this.state.sortby == true) {
            sortType = 'desc'
        } else {
            sortType = 'asc'
        }
        const sortData = _.orderBy(this.state.ordersData, [name], [sortType]);
        await this.setState({ ordersData: sortData })
    }

    async inventorySortBy(name) {
        this.setState({ inventorySortBy: !this.state.inventorySortBy, sortId: !this.state.inventorySortBy });
        var inventorySortType = ''
        if (this.state.inventorySortBy == true) {
            inventorySortType = 'desc'
        } else {
            inventorySortType = 'asc'
        }
        const sortData = _.orderBy(this.state.inventoryData, [name], [inventorySortType]);
        await this.setState({ inventoryData: sortData })
    }


    newWindowTargetOpen(href) {
        var link = document.createElement('a');
        link.href = href;
        link.target = '_blank';
        link.dispatchEvent(new MouseEvent('click'));
    }
    addressSelection(row) {
        const href = 'https://www.google.com/maps/place/' + row.address + ',' + row.statename + ',' + row.city_name;
        this.newWindowTargetOpen(href);
    }
    communitySelection(row) {
        const href = '/community/suiteamerica' + '/' + row.community_name.replace(/ /g, "-");
        this.newWindowTargetOpen(href);
    }
    /* Download Excel sheet */
    exportExcel(title, row) {
        ClientPortalController.exportToExcel(title, row);
    }
    async analysisCount(data, type, status, count) {
        if (count == 1) {
            const info = {
                name: data[0].ComplexName ? data[0].ComplexName : data[0].CategoryCode,
                type: status
            }
            this.setState({ analysisCountInfo: [], analysisCountType: 0, analysisLoading: true, AnalysisName: data[0].ComplexName ? data[0].ComplexName : data[0].CategoryCode, AnalysisType: status == "Started" ? 'open' : 'close' })
        }
        else {
            this.setState({ analysisCountInfo: [], analysisCountType: 1, analysisLoading: true, AnalysisName: 'OpenServices', AnalysisType: status == "" })
        }
        var i = 0;
        var analysisdata = []
        var totalength = data.length
        for (i = 0; i < totalength; i++) {
            const requestObj = {
                client: this.state.clientIds,
                start: this.state.analysisMoveInDate,
                end: this.state.analysisMoveOutDate,
                status: status
            }
            requestObj[type == 1 ? 'complexid' : 'category'] = type == 1 ? data[i].ComplexId : data[i].CategoryCode
            const analysisResponse = type == 1 ? await ClientApiService.CommunityAnalysis(requestObj) : await ClientApiService.ServiceAnalysis(requestObj);
            analysisdata.push(analysisResponse)
        }
        var result = analysisdata.reduce((o, m) => m.concat(o), []);
        this.setState({ analysisCountInfo: result, analysisLoading: false, })
        $('.analysisCount option:last-child').text('All');
    }
    async communityAnalysisCount(data, type) {

        await this.setState({ analysisModalLoading: true, communityAnalysisCountInfo: [] })
        await this.setState({ communityAnalysisCountInfo: data, analysisModalLoading: false, communityAnalysisName: data[0].community_name, communityAnalysisType: type })
        $('.community_Analysis_Modal option:last-child').text('All');
    }

    /* It is invoked to return html content */
    render() {
        const ordersData = this.state.ordersData;
        const searchValue = this.state.searchValue.toLowerCase();
        const filterData = ordersData.filter(order => {
            return searchValue != '' ? ((order.orderno != null && order.orderno.toString().includes(searchValue))
                || (order.MoveInDate != null && moment(order.MoveInDate).format('L').includes(searchValue))
                || (order.MoveOutDate != null && moment(order.MoveOutDate).format('L').includes(searchValue))
                || (order.SuiteNo != null && order.SuiteNo.toString().toLowerCase().includes(searchValue))
                || (order.SuiteSizeCode != null && order.SuiteSizeCode.toLowerCase().toString().includes(searchValue))
                || (order.IsPetExist != null && order.IsPetExist.toString().toLowerCase().includes(searchValue))
                || (order.PurchaseOrder != null && order.PurchaseOrder.toString().toLowerCase().includes(searchValue))
                || ((order.Vip != null && order.Vip == "VIP4") && ("CP2".toLowerCase().includes(searchValue)))
                || ((order.Vip != null && order.Vip != "VIP4") && (order.Vip.toString().toLowerCase().includes(searchValue)))) : order;
        })


        const inventoryData = this.state.inventoryData;
        //  const filterInventoryData = inventoryData;


        const filterInventoryData = inventoryData.filter(order => {
            return searchValue != '' ? ((order.MasterId != null && order.MasterId.toString().includes(searchValue))
                || (order.SuiteNo != null && order.SuiteNo.toString().toLowerCase().includes(searchValue))
                || (order.SuiteSize != null && order.SuiteSize.toString().toLowerCase().includes(searchValue))
                || (order.AvailFrom != null && moment(order.AvailFrom).format('L').includes(searchValue))
                || (order.AvailTo != null && moment(order.AvailTo).format('L').includes(searchValue))
                || (order.GuestMoveOut != null && moment(order.GuestMoveOut).format('L').includes(searchValue))
                || (order.OrderStatus != null && order.OrderStatus.toString().toLowerCase().includes(searchValue))
                || (order.PurchaseOrder != null && order.PurchaseOrder.toString().toLowerCase().includes(searchValue))
                || (order.VipLevel != null && order.VipLevel.toLowerCase().includes(searchValue))) : order;
        })

        // console.log("filterInventoryData:",filterInventoryData)


        return (
            <div className="altairDB altairDeanBlue">
                <AltairHeader />
                <div className="client_portal ">
                    <div className="container">
                        <div className="row mrg-btm-50">
                            <div className="col-md-6 col-sm-6">
                                <h2 className="color-red" style={{ textTransform: "uppercase" }}>
                                    Altair Dashboard
                                </h2>
                            </div>
                        </div>
                        <div className="community-tabs mrg-top-50">
                            <ul className="nav nav-tabs" role="tablist">
                                {/* <li role="presentation" className="active">
                           <a href="#one" aria-controls="home" role="tab" data-toggle="tab" 
                           onClick={() => this.inventorybyProgram()}>Inventory</a>
       </li> */}
                                <li role="presentation" className="active">
                                    <a href="#one" id="inventoryTab" aria-controls="profile" role="tab" data-toggle="tab" onClick={() => this.inventorybyProgram()}>Inventory</a>
                                </li>
                                <li role="presentation">
                                    <a href="#three" aria-controls="profile" role="tab" data-toggle="tab" onClick={() => this.changeStatus('PENDING')}>Pending</a>
                                </li>
                                <li role="presentation">
                                    <a href="#two" aria-controls="profile" role="tab" data-toggle="tab" onClick={() => this.changeStatus('ACTIVE')}>Staying</a>
                                </li>
                                <li role="presentation">
                                    <a href="#four" aria-controls="profile" role="tab" data-toggle="tab" onClick={() => this.changeStatus('TERMED')}>Moved Out</a>
                                </li>
                            </ul>




                            <div className="tab-content travel-booking-slider">
                                {/*    <div role="tabpanel" className="tab-pane active" id="one">
                           <div className="clearfix"></div>

                           <AverageCounts {...this.state} avgCountLoading={this.state.avgCountLoading} analysisCount={this.analysisCount} changeStatus={this.changeStatus}/>
                          

                           <div className="row">
                           <div className="clearfix"></div>

                       <div className="col-md-6">
                        <Analysis  analysisCount={this.analysisCount} Analysis={this.state.servicesAnalysis} status='1' {...this.state} />
                        </div>
                        <div className="col-md-6">
                        <Analysis  analysisCount={this.analysisCount} Analysis={this.state.communityAnalysis} status='2' {...this.state} /> 
                        </div>
                        <div className="clearfix"></div>
                       </div>
                       <br/>
                       <div className="row">
                       <div className="clearfix"></div>
                       <CommunityAnalysis communityAnalysisCount={this.communityAnalysisCount} {...this.state}/>
                       </div>
                           
                       </div>

                       */}


                                <div role="tabpanel" className="tab-pane active" id="one">
                                    {this.state.statusType == "Inventory" ? <InventroyTableData  {...this.state} searchValue={this.state.searchValue} exportExcel={this.exportExcel} changeSearchValue={this.changeSearchValue} sortBy={this.inventorySortBy} filterData={filterInventoryData} index={this.state.index} viewSelction={this.viewSelction} communitySelection={this.communitySelection} addressSelection={this.addressSelection} type={'Inventory'} buttonsChanges={this.buttonsChanges} selectValue={this.state.selectValue} closedData={this.state.closedData} servicesData={this.state.servicesData} /> : ''}
                                </div>

                                <div role="tabpanel" className="tab-pane " id="two">
                                    {this.state.statusType == "ACTIVE" ? <TableData  {...this.state} searchValue={this.state.searchValue} exportExcel={this.exportExcel} changeSearchValue={this.changeSearchValue} sortBy={this.sortBy} filterData={filterData} index={this.state.index} viewSelction={this.viewSelction} communitySelection={this.communitySelection} addressSelection={this.addressSelection} type={'Active'} buttonsChanges={this.buttonsChanges} selectValue={this.state.selectValue} closedData={this.state.closedData} servicesData={this.state.servicesData} /> : ''}
                                </div>
                                <div role="tabpanel" className="tab-pane" id="three">
                                    {this.state.statusType == "PENDING" ? <TableData  {...this.state} searchValue={this.state.searchValue} exportExcel={this.exportExcel} changeSearchValue={this.changeSearchValue} sortBy={this.sortBy} filterData={filterData} index={this.state.index} viewSelction={this.viewSelction} communitySelection={this.communitySelection} addressSelection={this.addressSelection} type={'Pending'} /> : ''}
                                </div>
                                <div role="tabpanel" className="tab-pane" id="four">
                                    {this.state.statusType == "TERMED" ? <TableData {...this.state} exportExcel={this.exportExcel} changeSearchValue={this.changeSearchValue} searchValue={this.state.searchValue} index={this.state.index} sortBy={this.sortBy} filterData={filterData} viewSelction={this.viewSelction} communitySelection={this.communitySelection} addressSelection={this.addressSelection} type={'termed'} /> : ''}
                                </div>

                            </div>







                        </div>
                    </div>
                </div>

            </div>

        );
    }
}
export default AltairDashboard;

