const Amenities = (props) => {
    const {communityAmenities, appartmentAmenities} = props;
    return (
        <div className="widget">
            <div className="widget_header">
                <ul className="nav nav-tabs community_tabs" role="tablist">
                    <li role="presentation" className="active">
                        <a href="#community" aria-controls="home" role="tab" data-toggle="tab">Community</a>
                    </li>
                    <li role="presentation">
                        <a href="#appartments" aria-controls="profile" role="tab" data-toggle="tab">Apartments</a>
                    </li>
                </ul>
            </div>
            <div className="widget_body">
                <div className="tab-content">
                    <div role="tabpanel" className="tab-pane active" id="community">
                        {communityAmenities.length != 0? 
                        <ul className="amenities_list">
                        {communityAmenities.map((row, index) => {
                        return (
                            <li key={index}><img width="40" src={s3BucketUrl+'Amenities/'+row.replace(/[^A-Z0-9]+/ig, "")+'.png'} /> {row}</li>
                            )
                        })}
                        </ul>
                        : null}
                    </div>
                    <div role="tabpanel" className="tab-pane" id="appartments">
                        {appartmentAmenities.length > 0? 
                        <ul className="amenities_list">
                        {appartmentAmenities.map((row, index) => {
                        return ( 
                            <li key={index}><img width="40" src={s3BucketUrl+'Amenities/'+row.replace(/[^A-Z0-9]+/ig, "")+'.png'} /> {row}</li>
                            )
                        })}
                        </ul>
                        : null}
                    </div>
                </div>
            </div>
        </div>

    )
}
export default Amenities;