/* Importing the node modules, child components, services and controllers used 
   inside AdidasFAQ component */
   import MainController from "../../controller/mainController";
   import AdidasHeader from "../../common/adidasHeader";
   import SubFooter from "../../common/subFooter";
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   import AdidasFooter from "./common/adidasFooter";
   /* AdidasFAQ Component initialization */
   class AdidasFAQ extends React.Component {
       /* Initializing objects of its AdidasFAQ class */
       constructor(props) {
           super(props);
           this.state = {
               fqs: [{
                   'Question': 'When will I find out where I am staying?',
                   'Answer': 'SuiteAmerica can start confirming availability and make an apartment offer to you approximately 20-30 days prior to your arrival. Please note that available options are subject to the interest of other Adidas interns looking for roommates.',
               },
               {
                   'Question': 'Can I request a specific Community and/or Apartment Size?',
                   'Answer': 'SuiteAmerica will make every effort to accommodate your requests but please note that due to apartment availability, we cannot guarantee your exact preferences.',
               },
               {
                   'Question': 'How does the pricing work?',
                   'Answer': 'The pricing is a fixed rate per intern based on the size of the apartment and the number of roommates. See the community section for further details.',
               },
               {
                   'Question': 'If I fill out the intern questionnaire, will I be obligated to the apartment offer that SuiteAmerica sends me?',
                   'Answer': 'No, you will not be obligated untill you sign a rental agreement via docusign.',
               },
               {
                   'Question': 'Can I request a roommate if I have already found one within the program?',
                   'Answer': 'Yes, you may list your requested roommate in the notes section of the questionnaire. Please keep in mind that if you choose to room with someone your dates should match up as close as possible to receive the best pricing.',
               },
               {
                   'Question': 'When will my apartment location be confirmed?',
                   'Answer': 'Few apartments will be confirmed once you and your roommates accept the offer from SuiteAmerica and complete your rental paper work.',
               },
               {
                   'Question': 'What does my apartment include?',
                   'Answer': 'Your apartment will includes all furniture, housewares, linens, housekeeping every two weeks, utilities, cable, wireless internet and home phone service. For full listing of what is included, please visit the Apartment Tour section of the website.',
               },
               {
                   'Question': "Are there any other additional fee's or deposits?",
                   'Answer': 'SuiteAmerica does not charge any deposits, taxes, move-out/cleaning charges.',
               },
               {
                   'Question': 'Is parking included?',
                   'Answer': 'Typically one parking space is included with an apartment if atleast one intern is bringing a vehicle. There ia an additional cost for more then one parking space.',
               }]
           }
       }
       /* It is invoked immediately before mounting occurs */
       componentWillMount() {
           MainController.getInitialInformation();
       }
       /* It is invoked to return html content */
       render() {
           let {fqs} = this.state;
           return (
               <div className="corporateHousing_Updates">
               <GuestHeader />
               <div className="cover-tophd-ht"></div>
               <AdidasHeader />
               <div className="container-fluid nike guest_sitenew">
                  <div className="container intra_staff2 col-sm-8 col-sm-offset-2">
                     <h2 className="text-center company-title">FREQUENT ASKED QUESTIONS</h2>
                     <div id="accordion" className="panel-group mrg-btm-90 company_acdn" role="tablist" aria-multiselectable="true">
                        {fqs.map((row, index) => {
                        return (
                        <div className="panel panel-default" key={index}>
                           <div className="panel-heading">
                              <h4 className="panel-title">
                                 <a className="collapsed"
                                    data-toggle="collapse"
                                    data-parent="#accordion"
                                    href={'#collapse' + index}
                                    aria-expanded="false"
                                    aria-controls={row.id}
                                    >{row.Question}</a>
                              </h4>
                           </div>
                           <div id={'collapse' + index}
                              className="panel-collapse collapse">
                              <div className="panel-body">
                                 <div className="profile-opener">
                                    {row.Answer}
                                 </div>
                              </div>
                           </div>
                        </div>
                        )
                        })}
                     </div>
                  </div>
               </div>
               <div className="container ft-contact-adrs mrg-btm-30 mrg-top-30">
                  <AdidasFooter />
               </div>
               <SubFooter />
               <Footer />
            </div>
   
           );
       }
   }
   export default AdidasFAQ;