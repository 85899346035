
import ReservationInfo from './reservationInfo';
import CommunityInfo from './communityInfo';
import StatusInformation from './statusInformation';
import $ from "jquery";
const TableInformation = (props) => {
    const { filterData, searchValue, sortBy, communitysLoading, type, viewSelction, changeSearchValue, communitySelection, exportExcel, addressSelection, servicesData, closedData, selectValue, guestFirstName, buttonsChanges, index } = props;
    return (
        <div>
            <div className="clearfix"></div>
            <div className="row">
                {/*  <div className="pull-left">
            <ul className="table_clr list-inline">
                <li>Hotel</li>
            </ul>
      </div> */}
                <div className="pull-right">
                    <div className="form-group mrg-right-15">
                        <input className="form-control pull-right" type="text" placeholder="Search" value={searchValue} onChange={(e) => changeSearchValue('searchValue', e)} />
                    </div>
                </div>
                {/* {filterData.length > 0 ? <button className="btn btn-primary pull-right mrg-right-10" onClick={()=> exportExcel(type, filterData)} >Export To Excel</button>: null} */}
            </div>
            <div className="clearfix"></div>
            <div className="table-responsive">
                {communitysLoading ?
                    <div className="panel-body_loader mrg-top-30">
                        <span style={{ fontSize: '20px', width: '100%', margin: 'auto', textAlign: 'center' }}>
                            <i className="fa fa-spinner fa-spin" /> please wait ...!</span>
                    </div> : <table className="table fold-table" >

                        <thead>
                            <tr>
                                <th onClick={() => sortBy('orderno', 1)} style={{ cursor: 'pointer' }}>Order#</th>
                                <th onClick={() => sortBy('MoveInDate')} style={{ cursor: 'pointer' }}>MoveIn</th>
                                <th onClick={() => sortBy('MoveOutDate')} style={{ cursor: 'pointer' }}>MoveOut</th>
                                <th onClick={() => sortBy('SuiteNo')} style={{ cursor: 'pointer' }}>Suite#</th>
                                <th onClick={() => sortBy('SuiteSizeCode')} style={{ cursor: 'pointer' }}>Size</th>
                                <th onClick={() => sortBy('IsPetExist')} style={{ cursor: 'pointer' }}>Pets</th>
                                <th onClick={() => sortBy('PurchaseOrder')} style={{ cursor: 'pointer' }}>File#</th>
                                <th onClick={() => sortBy('Vip')} style={{ cursor: 'pointer' }}>VIP Level</th>
                            </tr>
                        </thead>
                        {filterData.length == 0 && !communitysLoading ?
                            <tbody className=" table fold-table collapsed">
                                <tr className="view">
                                    <td className="text-center" colSpan="8" style={{ color: 'red' }}>No Records Found</td>
                                </tr>
                            </tbody>
                            : filterData.map((row, filterIndex) => {
                                const startDate = moment([Number(moment(row.MoveInDate).utc().format('YYYY')), Number(moment(row.MoveInDate).utc().format('M')) - 1, Number(moment(row.MoveInDate).utc().format('D'))]);
                                const endDate = moment([Number(moment(row.MoveOutDate).utc().format('YYYY')), Number(moment(row.MoveOutDate).utc().format('M')) - 1, Number(moment(row.MoveOutDate).utc().format('D'))]);
                                const diffDate = endDate.diff(startDate, 'days');
                                return (

                                    <tbody className=" table fold-table collapsed" key={filterIndex} >
                                        <tr data-toggle="collapse" data-parent="#accordion" href={'#expand' + row.orderno} className="view" style={{ background: '#ffffff' }}>
                                            <td onClick={() => viewSelction(row, filterIndex)}>{row.orderno}</td>
                                            <td onClick={() => viewSelction(row, filterIndex)}>{row.MoveInDate != null ? moment(row.MoveInDate).format('L') : '---'}</td>
                                            <td onClick={() => viewSelction(row, filterIndex)}>{row.MoveOutDate != null ? moment(row.MoveOutDate).format('L') : '---'}</td>
                                            <td onClick={() => viewSelction(row, filterIndex)}>{row.SuiteNo != null ? row.SuiteNo : '---'}</td>
                                            <td onClick={() => viewSelction(row, filterIndex)}>{row.SuiteSizeCode != null ? row.SuiteSizeCode : '---'}</td>
                                            <td onClick={() => viewSelction(row, filterIndex)}>{row.IsPetExist != null ? row.IsPetExist : '---'}</td>
                                            <td onClick={() => viewSelction(row, filterIndex)}>{row.PurchaseOrder != null ? row.PurchaseOrder : '---'}</td>
                                            <td onClick={() => viewSelction(row, filterIndex)}>{row.Vip != null ? (row.Vip == "VIP4" ? "CP2" : row.Vip) : '---'}</td>
                                            {/*
            <td className="community-hover" onClick={()=>communitySelection(row, filterIndex)} >{row.community_name ==null && row.HotelName!=null ? row.HotelName:row.community_name}</td>
            {row.community_name !=null && row.HotelName==null ?<td className="community-hover" onClick={()=>addressSelection(row, filterIndex)} >{(row.address == null ?  '---' : row.address) + ', ' + (row.city_name == null ? '---' : row.city_name) + ', ' + (row.statename == null ? '---' : row.statename) + ', ' + (row.ZipCode == null ? '---' : row.ZipCode)}</td>:null}
            {row.community_name ==null && row.HotelName!=null ?<td className="community-hover" onClick={()=>addressSelection(row, filterIndex)} >{(row.HAddress1 == null ?  '---' : row.HAddress1) + ', ' + (row.HCity == null ? '---' : row.HCity) + ', ' + (row.HState == null ? '---' : row.HState) + ', ' + (row.HZipCode == null ? '---' : row.HZipCode)}</td>:null}
            <td onClick={()=>viewSelction(row, filterIndex)}>{row.SuiteNo !=null ? row.SuiteNo :'---'}</td>

            {type=='Active'? <td onClick={()=>viewSelction(row, filterIndex)}>{row.NextHouseKeeping !=null ? moment(row.NextHouseKeeping).format('L') : row.BRNextHouseKeeping !=null? moment(row.BRNextHouseKeeping).format('L'):'---'}</td>:null}
            {type=='Active'? <td onClick={()=>viewSelction(row, filterIndex)}>{row.Servicescount}</td>:null}
*/}
                                        </tr>
                                        <tr id={'expand' + row.orderno} className={index == filterIndex ? "panel-collapse collapse fold open" : "panel-collapse collapse fold"}>
                                            <td colSpan="9">
                                                <div className="fold-content">
                                                    <h1>RESERVATION INFO</h1>
                                                    <ReservationInfo rowInfo={row} diffDate={diffDate} />
                                                    {type == 'Active' ?
                                                        <div>
                                                            <ul className="nav nav-tabs mrg-btm-20" role="tablist">
                                                                <li role="presentation" className={selectValue == 'Community' ? "active" : ""}>
                                                                    <a href="#reserveOne" aria-controls="home" role="tab" data-toggle="tab" onClick={() => buttonsChanges('Community', filterIndex)} >{row.community_name == null && row.HotelName != null ? 'Hotel Info' : 'Community Info'}</a>
                                                                </li>
                                                                {servicesData.length > 0 ?
                                                                    <li role="presentation" className={selectValue == 'Opened Services' ? "active" : ""}>
                                                                        <a href="#reserveTwo" aria-controls="profile" role="tab" data-toggle="tab" onClick={() => buttonsChanges('Opened Services', filterIndex)} >Opened Services</a>
                                                                    </li> : null}
                                                                {closedData.length > 0 ? <li role="presentation" className={selectValue == 'Closed Services' ? "active" : ""}>
                                                                    <a href="#reserveThree" aria-controls="profile" role="tab" data-toggle="tab" onClick={() => buttonsChanges('Closed Services', filterIndex)} >Closed Services</a>
                                                                </li> : null}
                                                            </ul>
                                                            {selectValue == 'Community' ?
                                                                <CommunityInfo rowInfo={row} diffDate={diffDate} /> : null}
                                                            {selectValue == 'Opened Services' && servicesData.length > 0 ?
                                                                <StatusInformation servicesData={servicesData} guestFirstName={guestFirstName} status={1} /> : null}
                                                            {selectValue == 'Closed Services' && closedData.length > 0 ?
                                                                <StatusInformation servicesData={closedData} guestFirstName={guestFirstName} status={2} /> : null}
                                                            <div className="clearfix"></div>
                                                        </div> : <div>
                                                            <h1>{row.community_name == null && row.HotelName != null ? 'Hotel Info' : 'Community Info'}</h1>
                                                            <CommunityInfo rowInfo={row} diffDate={diffDate} />
                                                        </div>
                                                    }

                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                )
                            })}
                    </table>}

            </div>
        </div>
    )
}
export default TableInformation;


