const PhotoGallery = props => {
  return (
    <div>
      <div className=" mg-t-20" id="PHOTO GALLERY" />
      {props.communityResponseloader ? (
        <div className="container">
          <div className="dean_gallery">
            <h6 className="mg-t-20">
              <span> &#9670; </span> {props.communityInfo.PHOTOGALLERY.Heading}
              <span> &#9670; </span>
            </h6>
            <section className="gallery-block compact-gallery">
          <div className="row no-gutters">
          {JSON.parse(props.communityResponse.CommunityImages).map((row,index)=>{
              return(
                <div className="col-md-6 col-sm-6" key={index}>
                <div className="item zoom-on-hover">
                        <a className="lightbox" data-toggle="modal" data-target="#galleryview">
                            <img className="img-responsive image" src={row.image_name} onClick={()=>props.gallaeryCorousel(row.image_name)}/>
                        </a>
                    </div>
            </div>
              )
          })
          }
        </div>
         </section>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
export default PhotoGallery;
