/* Importing the node modules, child components, services and controllers used 
   inside KeyList component */   
   import $ from "jquery";
   import AppController from "../../../controller/appController";
   import S3Upload from 'react-s3-uploader/s3upload'   
   import FivestarHeader from '../../../common/fivestarHeader';
   import SuccessModal from '../../../common/successModal';
   import FiveStarService from '../../../../services/fiveStarService';
   import SessionService from "../../../../services/sessionService";
   import FiveStarApiServices from '../../../../services/fiveStarApiServices';
   import FirstRow from "./serviceRequestComponents/firstRow"
   import SecondRow from "./serviceRequestComponents/secondRow"
   import KeyDetails from "./keyComponents/keyDetails"
   import KeyListForm from "./keyComponents/keyListForm"
   import { confirmAlert } from 'react-confirm-alert';
   /* KeyList Component initialization */
   class KeyList extends React.Component{
       /* Initializing objects of its KeyList class */
       constructor(props) {
           super(props)
           /* KeyList Component State variables Initialization */
           this.state = {
               requestDetails: [],
               keyList:[],
               frontDoorKeys:0,
               batteries:0,
               internetTvCabel:0,
               clean:'',
               light:'',
               heavy:'',
               superHeavy:'',
               cc:[],
               extraAppartment:'',
               comment:'',
               flag:0,
               message:'',
               loadingStatus: false,
               message1:'',
               color:'',
               filesUpload: false,
               isSubmitting: false,
               addMore: [],
               keyListData:[],
               keyListQty:[1,2,3,4,5,6,7,8,9,10],
               bathroom:'',
               keylistArraySelected: [],
               driversList:[],
               filesLists: [],
               filesList: [],
               driverManagerInfo: {}
           }
           this.keylistArray=this.keylistArray.bind(this)
           this.buttonToogle=this.buttonToogle.bind(this)
           this.setValue=this.setValue.bind(this)
           this._handleImageChange=this._handleImageChange.bind(this)
           this.keySubmit=this.keySubmit.bind(this)
       }
       /* It is invoked immediately before a component is mounted */
       async componentWillMount() {
           $('html, body').animate({scrollTop: 0});
           AppController.stickyTitles();
          
           const driverInformation= await SessionService.SessionGet({type:'fivestar'});
           this.setState({driverManagerInfo:driverInformation})
           const TableId=localStorage.getItem('requestFormId')
           const driverDetails={uniqueid:TableId}
           const resOrders = await FiveStarApiServices.getServiceScheduleById(driverDetails);
           this.setState({requestDetails: resOrders[0]});
           const masterdata={masterid:this.state.requestDetails.masterid}
           const keyListData= await FiveStarApiServices.getKeyList(masterdata)
           const keyListDataWithQuantity =_.filter(keyListData, function (o) {return o.Guest != 0;})
           await this.setState({keyListData:keyListDataWithQuantity})
        }
        /* It is invoked immediately after a component is mounted */
        async componentDidMount() {
           await this.drivers()
           if(this.state.driversList.length>0)
               {jQuery(function($) {
                   $(".selectpicker").selectpicker();
               });}
           }
             async drivers(){
                const request={date:''}
               const driversList = await FiveStarApiServices.getDriverList(request);
               this.setState({driversList:driversList});
             }
             
        /* To change the selected input field value */

       /* To change the selected input field value */
        setValue(field,value, e) {
           if(value ==2){
               var object = {};
               if(field=='flag'){
                   if(e.target.checked){
                       object[field] = 1;
                   }else{
                       object[field] = 0;
                   }
                 this.setState(object);
               }  }
   
          if(value == 3 && field == 'cc'){
              var arr = []
              for (var i = 0; i<e.target.length; ++i) {
               var opt = e.target[i];
               opt.selected && arr.push(opt.value);
           }
           this.setState({cc: arr})
          }
          if(value == 3 && field != 'cc'){
           var object = {};
           object[field] = e.target.value;
           this.setState(object);
          }
   
         }
        /* Toggle button action events */
        buttonToogle(field, e){
         const data =$('#'+field).attr('aria-pressed')
         const object = {};
         const unitCondition =['clean','light','heavy','superHeavy']
         
         if(field=='frontDoorKeys' || field=='batteries' || field=='internetTvCabel'){
           object[field] = data =='true' ? 0 : 1;
         }
         else{
           object[field] = data =='true' ? '' : field;
         }
         const dataCheck = jQuery.inArray(field, unitCondition)
         if(dataCheck>=0){
           _.forEach(unitCondition, function(value) {
               if(value != field){
                   object[value] = 0;
                   $('#'+value).attr('aria-pressed', false)
                   $('#'+value).removeClass('active')
                   $('#'+value+'Parent label').css({"color": "#c5c5c5", "font-weight": "200"})
                   
               }else{
                   data=='true'? $('#'+value+'Parent label').css({"color": "#c5c5c5", "font-weight": "200"}) : $('#'+value+'Parent label').css({"color": "#08d", "font-weight": "bold"})
               }
             });
         }
         
         this.setState(object);
        }
        /* To upload the images into s3 bucket */
        async _handleImageChange(type, index, e) {
            e.preventDefault();
          if(this.state.filesList.length<=3)
          {
            let reader = new FileReader();
            const fileIndex = e.target.name.split('-')
                 const _this = this
                _.forEach(e.target.files, function(file) {
                    let uniqueId=_this.state.requestDetails.UniqueId.replace(/ /g,"_")
                    uniqueId=_.toUpper(uniqueId);
                    const S3Uploader = new S3Upload({
                        files: [file],
                        signingUrl: '/s3/sign',
                        s3path: _this.state.requestDetails.type+ '/'+uniqueId+'/'+moment().format("MM-DD-YYYY")+'/',
                        signingUrlQueryParams: {},
                        onProgress: _this.onProgress.bind(_this, type, index),
                        onFinishS3Put: _this.onFinish.bind(_this, type, index, file),
                        onError: _this.onError.bind(_this, type, index),
                        uploadRequestHeaders: {'x-amz-acl': 'public-read'},
                        contentDisposition: file.type,
                        server: ''
                    }) 
                })
            }
          else{
          this.setState({message:'Please select only four files',color:'red'})
          setTimeout(function() {
            this.setState({message:'',color:''})
          }.bind(this), 3000);
          }
          }
          /* Progress event for uploading image into s3 bucket */        
          async onProgress(type, fileIndex, progress) {
            this.setState({filesUpload: true})
          }
          /* Error event for uploading image into s3 bucket */    
          onError(type, fileIndex, err) {}
          async onFinish(type, fileIndex, FileDetails, info) {
          const filepath = 'https://suiteam-website.s3.amazonaws.com/' + info.filename;
          await this.setState( {filesUpload : false});
          await this.filesListUpdate(filepath, FileDetails, info ,-1)
          }
          async filesListUpdate(filepath, FileDetails, info, dataIndex) {
            if (dataIndex < 0) {
                await this.setState({
                
                    filesLists: _.concat(this.state.filesLists, {
                        filepath: filepath,
                        FileDetails: FileDetails,
                        info: info
                    })
                })
            } else if (dataIndex >= 0) {
                await this.setState({
                    filesLists: update(this.state.filesLists, {
                        $splice: [
                            [dataIndex, 1]
                        ]
                    }, 0)
                })
            }
          }
      
        /* To insert key list into database and email sending */   
        async keySubmit(){
            const pstDate = AppController.pstDate()
                this.setState({isSubmitting:true})
             
               var finalString = '';
               if(this.state.clean != 0){
                   finalString = this.state.clean;
               }
               if(this.state.light != 0){
                   finalString = this.state.light;
               }
               if(this.state.heavy != 0){
                   finalString = this.state.heavy;
               }
               if(this.state.superHeavy != 0){
                   finalString = this.state.superHeavy;
               }
               const keyDetails={
                   recordUId:this.state.requestDetails.UniqueId,
                   recordType:this.state.requestDetails.type,
                   frontDoorKeys:this.state.frontDoorKeys,
                   batteries:this.state.batteries,
                   internetTvCabel:this.state.internetTvCabel,
                   unitcondition:finalString,
                   flag:this.state.flag,
                   extraWorkInAppartment:this.state.extraAppartment,
                   cc:this.state.cc.toString(),
                   comment:this.state.comment,
                   recordType:this.state.requestDetails.type,
                   SuiteNo: this.state.requestDetails.SuiteNo,
                   sTableId: this.state.requestDetails.sTableId,
                   name: this.state.driverManagerInfo.Identifier,
                   files:this.state.filesLists,
                   keylistArraySelected: this.state.keylistArraySelected,
                   keyListData: this.state.keyListData,
                   orderId:this.state.requestDetails.TableId,
                   masterId:this.state.requestDetails.mastertableId,
                   community:this.state.requestDetails.community_name,
                   createdDate:pstDate,
                   address:this.state.requestDetails.address+','+this.state.requestDetails.state_name+','+this.state.requestDetails.city_name+','+this.state.requestDetails.ZipCode,
                   orderType: this.state.requestDetails.OrderType,
                   HotelName: this.state.requestDetails.HotelName,
                   HotelAddress: this.state.requestDetails.hoteladdress+','+this.state.requestDetails.hotelstate+','+this.state.requestDetails.hotelcity+','+this.state.requestDetails.hotelzipcode,
                   HotelSuiteNo: this.state.requestDetails.hotelsuite,
                   suiteAddress: this.state.requestDetails.AptAddr,
                   suiteState: this.state.requestDetails.LeaseState,
                   suiteCity: this.state.requestDetails.LeaseCity,
                   suiteZipCode: this.state.requestDetails.AptZip,
                }
                this.confirmationAlert(keyDetails);
        }

        confirmationAlert(keyDetails){
            const messageString =  'Are you sure you want to send Email!!!' 
            confirmAlert({
              title: '',                      
              message: messageString,              
              childrenElement: () => '',       
              confirmLabel: 'Yes',                           
              cancelLabel: 'No',                            
              onConfirm: () => {this.keyListSaveAndEmailSent(keyDetails)},    
              onCancel: ()=> {this.setState({isSubmitting:false})},      
            })
          }
          async keyListSaveAndEmailSent(keyDetails) {
            this.setState({loadingStatus:true})
            const resOrders = await FiveStarApiServices.saveKeyList(keyDetails);
            // this.setState({keyList:resOrders.response})
            if(resOrders.unquieId !=''){
                const resEmail = await FiveStarService.keysList(keyDetails);
                await  FiveStarApiServices.CreateServiceRequestEmailLog(resEmail.responseData)
                this.setState({message:'' ,color:'',loadingStatus:false,isSubmitting:false,message1:'',cc:[],filesLists:[]}) 
                jQuery(function($) {
                 $('#successModal').modal('show');
                 setTimeout(function(){
                   $('#successModal').modal('hide');
                 },4000);
               });
             }
            else{
                this.setState({message:'Mail not sent',color:'red',loadingStatus:false,isSubmitting: false})
            }
        }
    
       /* To get the list of selected key list */ 
       keylistArray(){
           var keylistArraySelected=[];
           for (var i = 0; i < this.state.keyListData.length; i++) {
               keylistArraySelected.push($('#select'+i).val())
           } 
           this.setState({keylistArraySelected})
       }
      /* It is invoked to return html content */
       render(){

           return(
               <div id="pages_5star">
                <FivestarHeader/>
                <FirstRow requestDetails={this.state.requestDetails} serviceRequest={false}/>
                <div className="container star_key">
                <SecondRow requestDetails={this.state.requestDetails} driverManagerInfo={this.state.driverManagerInfo} serviceRequest={false}/>
                <KeyDetails keyListData={this.state.keyListData} keylistArraySelected={this.state.keylistArraySelected} keyListQty={this.state.keyListQty} keylistArray={this.keylistArray}/>
                <div className="clearfix"></div>
                <KeyListForm buttonToogle={this.buttonToogle} {...this.state} setValue={this.setValue} _handleImageChange={this._handleImageChange} keySubmit={this.keySubmit}/>
                <SuccessModal/>
                </div>  
               </div>
               
           );
       }
   }
   export default KeyList;  