const StateCityInfo = (props) => {
    return ( 
        <div className="modal fade SuccessModal gtravel_reserve_popup success" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header"> 
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close" >
                      <span aria-hidden="true">Close</span>
                  </button> 
                  <h4 className="modal-title">
                      <ul className="list-inline">
                      <li><a href="https://www.instagram.com/suite_america/" target="_blank"><i className="fa fa-instagram"></i></a></li>
                     <li><a href="https://www.facebook.com/SuiteAmerica" target="_blank"><i className="fa fa-facebook-square"></i></a></li>
                     <li><a href="https://www.linkedin.com/company/108459?trk=saber_s000001e_1000" target="_blank"><i className="fa fa-linkedin"></i></a></li>
                     <li><a href="https://twitter.com/SuiteAmerica" target="_blank"><i className="fa fa-twitter"></i></a></li>
                      </ul>
                  </h4> 
              </div>
              <div className="modal-body topdiv">
                  
                  <h4>
                  <div>Thank you!</div>
                  <div> We will Contact you shortly.</div>
                  </h4>
              </div>

          
         
              <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/Google/g_popup_bg.png" className="img-responsive popup_bg" alt=""/>
          </div>
        </div>
      </div>
  
    );

}
export default StateCityInfo;
