const SuccessModal = () => {
    return(
       <div className="modal fade savesearch" id="clientPasswordSuccess" role="dialog">
           <div className="modal-dialog">
               <div className="modal-content rm-border-radius">
                   <div className="modal-header">
                   <button type="button" className="close btn" data-dismiss="modal" aria-label="">
                   <i className="bi bi-x"></i>
                   </button>
                   </div>
                   <div className="modal-body">
                   <h2>Thank You!</h2>
                   <p>Your password is updated successfully</p>
                   </div>
               </div>
           </div>
       </div>
    )
}   
export default SuccessModal;