const managersData = {
  // michael: {
  //   name: "Michael",
  //   fullName: "MICHAEL JAMES",
  //   designation: "Senior Director of Business Strategy",
  //   email: "michael@suiteamerica.com",
  //   //email: "pavan.kamarajugadda@devrabbit.com",
  //   coverImageUrl:
  //     "/assets/newUI-images/corporateHousingManagement/michael-james.png",
  //   popUpImageUrl:
  //     "/assets/newUI-images/corporateHousingManagement/michael-james.png",
  //   meetingLink: "https://calendly.com/sa-MichaelJames",
  //   funFacts: {
  //     industry: [
  //       ["Years in Corporate Housing", "9"],
  //       [
  //         "Favorite part of the job",
  //         "Building long lasting relationships with clients and developing creative solutions for their program needs. Also, serving in our industry and providing education all the things hospitality.",
  //       ],
  //       [
  //         "Greatest professional skill",
  //         "I'm a strong communicator and pride myself on my ability to resolve potentially difficult situations.",
  //       ],
  //     ],
  //     personal: [
  //       [
  //         "Fun Facts",
  //         "My wife Alaina and I love live music and try to see as many shows we can every year. We go to Bottlerock Napa Valley each year and enjoy finding new bands to follow.",
  //       ],
  //       ["Favorite Vacation Spot", "Mexico, Specifically Cabo."],
  //       [
  //         "Dream Destination	",
  //         "Going somewhere tropical and staying in an overwater bungalow.",
  //       ],
  //       [
  //         "Hobbies/Interests",
  //         "Love music, movies, travel camping, being around any form of water, LOVE FOOD.",
  //       ],
  //       [
  //         "Favorate Quote",
  //         "Living in the moment, it's a gift. That's why they call it the present. - Ted Lasso",
  //       ],
  //     ],
  //   },
  // },
  ericka: {
    name: "Ericka",
    fullName: "Ericka Anderson",
    designation: "VP, Strategic Growth",
    email: "ericka.anderson@suiteamerica.com",
    //email: "pavan.kamarajugadda@devrabbit.com",
    coverImageUrl:
      "/assets/newUI-images/corporateHousingManagement/Ericka Anderson.jpg",
    popUpImageUrl:
      "/assets/newUI-images/corporateHousingManagement/Ericka Anderson.jpg",
    meetingLink: "https://calendly.com/sa-erickaanderson",
    funFacts: {
      industry: [
        ["Years in Corporate Housing", "26"],
        [
          "Favorite part of the job	",
          "Delivering excellent and high quality accommodations is what matters most to me!",
        ],
        ["Greatest professional skill", "Problem solving"],
      ],
      personal: [
        [
          "Fun Facts",
          "I live on a small ranch where we raise alpacas, goats, sheeps, dogs and two teenagers.",
        ],
        ["Favorite Vacation Spot", "Maui"],
        ["Dream Destination	", "New Zealand"],
        [
          "Hobbies/Interests",
          "Time with family & friends except on Sundays which are reserved for mandatory jammy-rammy's (and football!).",
        ],
        [
          "Favorite Quote",
          "The secret of getting ahead is getting started.  -Mark Twain.",
        ],
      ],
    },
  },
  // laura: {
  //   name: "Laura",
  //   fullName: "Laura Blackmun",
  //   designation: "Senior Director, National Sales",
  //   email: "laura@suiteamerica.com",
  //   //email: "pavan.kamarajugadda@devrabbit.com",
  //   coverImageUrl:
  //     "/assets/newUI-images/corporateHousingManagement/laura-gantt.png",
  //   popUpImageUrl:
  //     "/assets/newUI-images/corporateHousingManagement/laura-gantt.png",
  //   meetingLink: "https://calendly.com/sa-LauraBlackmun",
  //   funFacts: {
  //     industry: [
  //       ["Years in Corporate Housing", "24"],
  //       ["Favorite part of the job	", "Guiding and helping people"],
  //       ["Greatest professional skill", "Listening"],
  //     ],
  //     personal: [
  //       ["Fun Facts", "I'm a Grandma"],
  //       ["Favorite Vacation Spot", "Maui."],
  //       ["Dream Destination	", "Greek Islands"],
  //       ["Hobbies/Interests", "Interior design is a passion of mine"],
  //       ["Favorate Quote", "Get busy living, or get busy dying"],
  //     ],
  //   },
  // },
  melissa: {
    name: "Melissa",
    fullName: "Melissa Stevens",
    designation: "VP of Business Development",
    email: "melissa.stevens@suiteamerica.com",
    coverImageUrl:
      "/assets/newUI-images/corporateHousingManagement/melissa-stevens.png",
    popUpImageUrl:
      "/assets/newUI-images/corporateHousingManagement/melissa-stevens.png",
    meetingLink: "https://calendly.com/sa-MelissaStevens",
    funFacts: {
      industry: [
        ["Years in Corporate Housing", "30"],
        [
          "Favorite part of the job	",
          "I enjoy building and leveraging relationships. Our industry is relationship driven and it's amazing to be a part of this close knit industry for so many years.",
        ],
        [
          "Greatest professional skill",
          "Building strengths and leveraging talent.",
        ],
      ],
      personal: [
        ["Fun Facts", "My first commercial flight ever had a crash landing."],
        [
          "Favorite Vacation Spot",
          "Greece, Italy, Switzerland, France, Germany, Austria, Hawaii",
        ],
        [
          "Dream Destination	",
          "Norway, Croatia, Ireland, Scotland, United Kingdom, Maldives, Tahiti",
        ],
        [
          "Hobbies/Interests",
          "I'm all about food and passionate about travel. I enjoy any activities with my family. 100 pound fur baby, friends and live for pilates anytime I can get into the studio.",
        ],
        [
          "Favorate Quote",
          "It's not whether you get knocked down. It's whether you get up. -Vince Lombardi",
        ],
      ],
    },
  },
  kelsey: {
    name: "Kelsey",
    fullName: "Kelsey",
    designation: "Vice President, Business Development",
    email: "kelsey.thompson@suiteamerica.com",
    coverImageUrl: "/assets/newUI-images/corporateHousingManagement/Kelsey.png",
    popUpImageUrl: "/assets/newUI-images/corporateHousingManagement/Kelsey.png",
    meetingLink: "https://calendly.com/sa-kelseythompson",
    funFacts: {
      industry: [
        ["Years in Corporate Housing", "10"],
        [
          "Favorite part of the job	",
          "Helping clients solve their housing need. I enjoy being creative and tailoring a housing program to fit their specific needs. It’s so rewarding when it works out well!",
        ],
        [
          "Greatest professional skill",
          "Very responsive, a great listener, and dedicated to both the company and my clients.",
        ],
      ],
      personal: [
        [
          "Fun Facts",
          "I live in Maryland, but grew up in Connecticut. I went to America University in Washington DC and majored international business. I also lived in Paris for a year and speak French!",
        ],
        ["Favorite Vacation Spot", "The Outer Banks and New England"],
        ["Dream Destination	", "The beach"],
        [
          "Hobbies/Interests",
          "I am an avid reader. Also, I live on a lake so I do a lot of outdoor activities like walking and hiking with my husband, son and 2 black labs.",
        ],
        [
          "Favorate Quote",
          "“It is not about what you say, it is about what you do.”",
        ],
      ],
    },
  },
  olivia: {
    name: "Olivia",
    fullName: "Olivia",
    designation: "VP, Business Development",
    email: "ericka.anderson@suiteamerica.com",
    //email: "pavan.kamarajugadda@devrabbit.com",
    coverImageUrl:
      "/assets/newUI-images/corporateHousingManagement/Olivia Morales_Head Shot Photo.png",
    popUpImageUrl:
      "/assets/newUI-images/corporateHousingManagement/Olivia Morales_Head Shot Photo.png",
    meetingLink: "https://calendly.com/sa-oliviamorales",
    funFacts: {
      industry: [
        [
          "Years in Corporate Housing",
          "I’ve spent 6 years in corporate housing and a total of 13 years in the hospitality world, and it’s been an incredible ride! Each year has brought new challenges, growth, and unforgettable experiences.",
        ],
        [
          "Favorite part of the job	",
          "What I enjoy most is understanding and listening to the needs of program leaders, then collaborating to create a solution that not only meets their needs but also exceeds their expectations.",
        ],
        ["Greatest professional skill", "Listening & Adapting"],
      ],
      personal: [
        [
          "Fun Facts",
          "I was a National Archery Champion several years in a row",
        ],
        ["Favorite Vacation Spot", "Italy"],
        // ["Dream Destination	", "-"],
        [
          "Hobbies/Interests",
          "I live in beautiful Santa Barbara, so the beach is always our go-to spot for family fun. I’m passionate about traveling and exploring new places, especially when there’s delicious food involved. Whether it’s tennis, games, or anything competitive, I’m all in!",
        ],
        ["Favorite Quote", "Always give credit where credit is due"],
      ],
    },
  },
  lily: {
    name: "Lily",
    fullName: "Lily",
    designation: "Senior Business Development Executive",
    email: "ericka.anderson@suiteamerica.com",
    //email: "pavan.kamarajugadda@devrabbit.com",
    coverImageUrl:
      "/assets/newUI-images/corporateHousingManagement/Lily Headshot.png",
    popUpImageUrl:
      "/assets/newUI-images/corporateHousingManagement/Lily Headshot.png",
    meetingLink: "https://calendly.com/sa-LilyMa",
    funFacts: {
      industry: [
        // ["Years in Corporate Housing", "-"],
        [
          "Favorite part of the job	",
          "The opportunity to constantly learn, build relationships with diverse people and contribute to the company's growth by identifying and pursuing new business.",
        ],
        ["Greatest professional skill", "Strategic thinking"],
      ],
      personal: [
        [
          "Fun Facts",
          "When I enjoy a meal at a restaurant, I will attempt to recreate it at home.",
        ],
        ["Favorite Vacation Spot", "Vietnam"],
        ["Dream Destination	", "Greece"],
        [
          "Hobbies/Interests",
          "Making flower arrangements, cooking, volunteering, hiking, weightlifting",
        ],
        [
          "Favorite Quote",
          "'Life is the flower for which love is the honey.'- Victor Hugo",
        ],
      ],
    },
  },
};

export default managersData;
