'use strict'
import EncryptDecrypt from '../../../../services/encryptDecrypt';
import GuestServicesapi from '../../../../services/guestServicesapi';
import userEmails from '../../../../services/userEmails';
import $ from "jquery";
class LoginModal {
  constructor(thisObject, props) {
    this.Obj = thisObject
    this.propsObj = props
    this.state = {
        alertMessage: '',
        color: '',
        message: '',
        confirmPassword: '',
        password: '',
        loginLoader:false,
        email: '',
    }
  }
  async checkEmail(){
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (this.Obj.state.email != '' && reg.test(this.Obj.state.email)) {
      this.updateState({message: '',loginLoader:'true'})
      const checkemail = {
        email: this.Obj.state.email
      }

      const userData = await GuestServicesapi.getSingleGuestDetails(checkemail)
      await this.updateState({userData: userData,loginLoader:'false'})
      if (userData.OrderId !=null && userData.webCount == 0) {
        this.updateState({alertMessage: 'Register with guest', confPass: true,loginLoader:false})
        this.hideAndShowModel();
      } else if (userData.OrderId  ==null && userData.webCount == 0) {
        this.updateState({alertMessage: 'Register with guest prospective'})
        this.updateState({guestprospect: true})
        const emailSend = {
          email: this.Obj.state.email,
          encodeEmail: Base64.encode(this.Obj.state.email),
          mail:'guestProspective'
      }
      const userData = await userEmails.sendMail(emailSend)
        if (userData.response) {
          this.updateState({successMessage: 'Your Message has been successfully sent'});
          jQuery(function ($) {
            $('#mysave1').modal('hide');
            $('#successModal').modal('show');
            setTimeout(function () {
              $('#successModal').modal('hide');
            }, 20);
          });
        } else {
          this.updateState({message: 'We Are unable to sent to mail', color: 'red'})
        }
      } else {
        if (userData.OrderId !=null && userData.webCount == 1) {
          this.updateState({alertMessage: 'Login with guest', role: 1,loginLoader:false})
          this.updateState({confPass: false})
          this.hideAndShowModel();
        } else {

          this.updateState({alertMessage: 'Login with guest Prospective', confPass: false, role: 1,loginLoader:false})
          this.hideAndShowModel();
        }
      }
    } else {
      this.updateState({message: 'Enter Valid Email', color: 'red'})
    }
  }
  async checkPassword(){
  if (this.Obj.state.role == 1) {
    if (this.Obj.state.password != '') {
      this.updateState({message: '',loginLoader:true})
      this.checkLogin();
    } else {
      this.updateState({message: 'Password is required', color: 'red',loginLoader:false})
    }
  } else {
    if (this.Obj.state.password == this.Obj.state.confirmPassword && this.Obj.state.password != '') {
      this.updateState({message: '',loginLoader:true})
      const checkpassword = {
        guestEmailId: this.Obj.state.email,
        guestPwd: this.Obj.state.password,
        guestIsActive: 1,
        guestFirstName: this.Obj.state.userData.GuestFirstName != null
            ? this.Obj.state.userData.GuestFirstName
            : '',
            guestLastName: this.Obj.state.userData.GuestLastName != null
            ? this.Obj.state.userData.GuestLastName
            : '',
            guestPhoneNumber: this.Obj.state.userData.GuestPhoneNo != null
            ? this.Obj.state.userData.GuestPhoneNo
            : '',
            guestOrderId: this.Obj.state.userData.OrderId != null
            ? this.Obj.state.userData.OrderId
            : ''
      }
      const userData = await GuestServicesapi.saveGuestUserInfo(checkpassword)
      if (userData.error == "0") {
        this.checkLogin();
      }
    } else {
      this.updateState({message: 'Password is required', color: 'red'})
    }
  }
}
hideAndShowModel(){
  jQuery(function ($) {
    setTimeout(function () {
      $('#mysave1').modal('hide');
    }.bind(this), 10);
  }.bind(this));
  jQuery(function ($) {
    setTimeout(function () {
      $('#mysave').modal('show');
    }.bind(this), 10);
  }.bind(this));
}
async checkLogin(){
  const checkpassword = {
    guestEmail: this.Obj.state.email,
    guestPassword: this.Obj.state.password,
    status: 'guest'
  }
  const authCheckRes = await GuestServicesapi.guestUsersLogin(checkpassword)
  if (authCheckRes.error == "1") {
    this.updateState({message: 'Invalid Login', color: 'red',loginLoader:false})
  } else {
    jQuery(function ($) {
      setTimeout(function () {
        $('#mysave').modal('hide');
        this.updateState({message: '',loginLoader:false})
      }.bind(this), 10);
    }.bind(this));
    const guestInfo=
    {
        userData:authCheckRes.message,
        type:'guest'
    }
    const guestEncryptResult = await EncryptDecrypt.encryptData(guestInfo)
    localStorage.setItem('_u_web', guestEncryptResult.data)
    const userData= await EncryptDecrypt.decryptData({'type': guestEncryptResult.data})
    switch (userData.recordset[0].roleId) {
      case 1:
          location.href = '/locations'
          break;
      case 2:
         location.href = '/guestportal'
          break;
      case 3:
          location.href = '/client-portal'
          break;
      case 4:
          location.href = '/intranet-home'
          break;
  }  
  }
}
  async updateState(updateObject) {
    await this.Obj.setState(updateObject)
  }
}
export default LoginModal