class PhotoModal extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        result: [],
        img: "https://my.matterport.com/show/?m=hj8MZKxXxx6"
      }
    }
    componentDidMount() {
      let res = this.props.CommunityImages
      const data = localStorage.getItem('item')
  
      const moveFirstToHead = ([head, ...tail], predicate, accumulator = []) =>
        (predicate.call(null, head)) ?
          [head].concat(accumulator).concat(tail) :
          moveFirstToHead(tail, predicate, accumulator.concat(head));
  
      const result = moveFirstToHead(res, o => o.image_name === data);
      this.setState({ result })
    }
  
    setImage(url) {
      this.setState({ img: url })
    }
  
    render() {
      return (
  
        <div>
          <div >
            <div >
              <div >
                <div className="outer">
                  <div id={"sync1" + 15} className="photoG_bgG owl-carousel owl-theme">
                    {this.state.result.map((row, index) => {
                      return (
                        <div className="item" key={index} >
                          <img className="img-responsive image" src={row.image_name} />
                        </div>)
                    })}</div>
  
                  <div id={"sync2" + 15} className="photoG_smG owl-carousel owl-theme">
                    {this.state.result.map((row, index) => {
                      return (
                        <div className="item" key={index}>
                          <img className="img-responsive image" src={row.image_name} />
                        </div>
                      )
                    })}</div>
                </div>
                <div className="floor_gallery">
                  <div className="image">
                    <iframe src={this.state.img} />
                  </div>
                  <div className="detailsFloor">
                    <h1>Floorplans</h1>
                    <ul>
                      <li onClick={() => this.setImage("https://my.matterport.com/show/?m=hj8MZKxXxx6")}><a>1x1 (677 sq ft)</a></li>
                      <li onClick={() => this.setImage("https://my.matterport.com/show/?m=LQbMaFtBycp")}><a>1x1 (775 sq ft)</a></li>
                      <li onClick={() => this.setImage("https://my.matterport.com/show/?m=VBq4TgZxFot")}><a>2x2 (1060 sq ft)</a></li>
                      <li onClick={() => this.setImage("https://my.matterport.com/show/?m=JJNgMBb3G1t")}><a>3x2 (1196 - 1311 sq ft)</a></li>
                    </ul>
  
                  </div>
  
                </div>
                </div>
                </div>
          </div>
        </div>
      )
    }
  }
  export default PhotoModal;