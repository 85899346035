/* Importing the node modules, child components, services and controllers used 
   inside FivestarHeader component */
import {Link} from 'react-router-dom';
import SiteLoader from './siteLoader';
import AppContoller from '../controller/appController';
import SessionService from "../../services/sessionService";
/* FivestarHeader Component initialization */
class FivestarHeader extends React.Component {
  /* Initializing objects of its FivestarHeader class */
    constructor(props) {
        super(props)
        /* FivestarHeader Component State variables Initialization */
        this.state = {
          driverManagerInfo:{},
          navigationData: [
            {
              "name": "Schedule",
              "url": "/drivers-schedule",
              "activeClass": "schedule"
            },
            {
              "name": "Occupancy Checker",
              "url": "/occupancy-checker",
              "activeClass": "checklists"
            },
            {
              "name": "Email",
              "url": "/email",
              "activeClass": "email"
            }
          ]
        }
        this.logOut=this.logOut.bind(this);
        this.changeIndex = this.changeIndex.bind(this);
    }
/* It is invoked immediately after a component is mounted */
    async componentDidMount() {
      AppContoller.removeSiteLoader();
      const driverInformation= await SessionService.SessionGet({type:'fivestar'});
      this.setState({driverManagerInfo:driverInformation})            
    }
/* To clear the local storage data and logout from the application */    
    async logOut() {
       await SessionService.SessionDestory({type:'fivestar'});
      localStorage.removeItem('activeIndex')
      location.href = '/fivestar'
  }
/* To active the selected list element based on the index */  
  changeIndex(index){
    localStorage.setItem('activeIndex', index);
  }
  /* It is invoked to return html content */  
    render() {
      const activeIndex = localStorage.getItem('activeIndex') == null ? localStorage.setItem('activeIndex', 0) : localStorage.getItem('activeIndex');
        return (
            <div>
                <nav className="navbar navbar-default fivestarNavwrap">
                  <div className="container-fluid">
                    <div className="navbar-header">
                      <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar"> <span className="sr-only">Toggle navigation</span> <span className="icon-bar"></span> <span className="icon-bar"></span> <span className="icon-bar"></span> </button>
                      <div className="nav-logo-wrapper"> <a className="logo logo-5star" > </a> </div>
                    </div>
                    <div id="navbar" className="navbar-collapse collapse">
                      <div className="nav-f nav-wrp">
                      <div className="nav-f-item">
                          <div className="language-select-wrap">
                            <div className="language-select"></div>
                            <div className="menu-links">
                            <ul className="translation-links">
                            {/* <li><a href={"https://suiteam-website.s3-us-west-1.amazonaws.com/CH/SACalendar.pdf"} target="_blank"> <i class="fa fa-calendar" aria-hidden="true"></i> </a></li> */}
                           
                            </ul>

                            </div>
                          </div>
                        </div>
                        <div className="nav-f-item">
                          <div className="language-select-wrap">
                            <div className="language-select"></div>
                            <div className="menu-links">
                            {/* <ul className="translation-links">
                            <li><a data-lang="English" id='lang1'>English</a></li>
                            <li className="devider">|</li>
                            <li><a data-lang="Spanish" id='lang2'>Spanish</a></li>
                            </ul> */}

                            </div>
                          </div>
                        </div>
                        <div className="nav-f-item">
                          <div className="menu-links">
                            <ul>
                              <li><a >{this.state.driverManagerInfo.Identifier}</a></li>
                              <li className="devider">|</li>
                              <li onClick={this.logOut}><a >log Out</a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </nav>
                <header className="head-secondary-nav">
                  <nav className="navbar navbar-default fivestarNavwrap">
                  <div className="container">
                    <div className="navbar-header">
                      <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar2" aria-expanded="false" aria-controls="navbar"> <span className="sr-only">Toggle navigation</span> <span className="icon-bar"></span> <span className="icon-bar"></span> <span className="icon-bar"></span> </button>
                    </div>
                    <div id="navbar2" className="navbar-collapse collapse">
                      <div className="main-nav">
                      <ul>
                        {this.state.navigationData.map((row, index) => {
                            return (
                                <li key={index}><Link to={row.url} className={activeIndex == index ? 'active-selection': null} 
                              onClick={() => this.changeIndex(index)}><i className={row.activeClass}></i><span>{row.name}</span></Link></li>
                            )
                          }
                        )}                        
                      </ul>
                    </div>
                    </div>

                  </div>
                </nav>
	            </header>
              <SiteLoader />       
            </div>
        );
    }
}
export default FivestarHeader;