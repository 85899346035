import guestServicesapi from '../../services/guestServicesapi';
class GuestSurvey extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            names: '',
            guestEmail: '',
            notes: '',
            message: '',
            color: '',
            emoji: '',
            error:false
        }
        this.feedback = this.feedback.bind(this);
        this.setValue = this.setValue.bind(this);
        this.getNew = this.getNew.bind(this);
    }
    async getNew(field, e) {
        $(".status").removeClass("active");
        await this.setState({ emoji: field, })
        $("#" + field).addClass("active");
        $(".activeEmoji").removeClass("activeEmoji");
        
    }
    async componentWillMount() {
        const reqObj = {
            orderUId: _.toLower(this.props.match.params.id),
            type:this.props.match.params.status
        }
        const checkSurveyForm = await guestServicesapi.CheckGuestSurveyFeedBack(reqObj);
        if (checkSurveyForm.error == 0) {
            await this.setState({error:false, guestEmail: checkSurveyForm.GuestEmail, names: checkSurveyForm.GuestFirstName });
        }else if (checkSurveyForm.error == 1) {
            await this.setState({classnames: 'alert alert-warning', error:true, message:checkSurveyForm.message+'.'});  
        }else{
            await this.setState({classnames: 'alert alert-warning', error:true, message:checkSurveyForm.Message+'.'});  
        }
    }
    async feedback() {
            const req = {
                'OrderUId': this.props.match.params.id,
                'FeedBack': this.state.emoji,
                'CreatedBy': this.state.guestEmail,
                'Notes': this.state.notes !=''?this.state.notes:'----',
                'Type': this.props.match.params.status
            }
            const checkSurvey = await guestServicesapi.SaveSurveyFeedBack(req);
            if (checkSurvey.error == 0) {
                await this.setState({error:true, classnames: 'alert alert-warning', message: 'Survey already completed.' });
            } else if (checkSurvey.error == 1) {
                await this.setState({error:true, classnames: 'alert alert-warning', message: 'Invalid Order Id.' });
            } else {
                await this.setState({error:true, classnames: 'alert alert-success', message: 'Thank you for giving valuable feedback.' });
            }
    }
    setValue(field, e) {
        var object = {};
        object[field] = e.target.value;
        this.setState(object);
    }
    render() {
        const { names,classnames } = this.state
        return (
            <div>
                {this.state.error?
                    <div className="write-a-reviewpage">
                        <div className="logo">
                            <img src="https://www.suite.solutions/Content/Images/logo_reminder.png" alt="Logo Name" width="180" />
                        </div>
                        <div className={this.state.classnames}>
                       {classnames=='alert alert-success'?<i className="fa fa-check" aria-hidden="true"></i> : <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>}
                            {this.state.message}
                        </div>
                    </div>
                    :
                    <div className="write-a-reviewpage">
                        <div className="logo">
                            <img src="https://www.suite.solutions/Content/Images/logo_reminder.png" alt="Logo Name" width="180" />
                        </div>
                        <div className="content">
                            <p>Dear {names},</p>
                        </div>
                        <div className="emojies">
                        {(this.props.match.params.status == 'Move-Out') ?<p>Considering your complete experience with SA, how likely would you be to recommend us to a friend or colleague?</p>:
                        <p>Did SuiteAmerica provide an easy and stress-free move-in experience? </p>}
                            <div className={this.state.classnames}>
                                {this.state.text}
                            </div>
                            {(this.props.match.params.status == 'Move-Out') ?
                                <div className="activeEmoji">
                                    <ul>
                                        <li><a id="Yes" className="status"> <img onClick={(e) => this.getNew('Yes', e)} src="https://suiteam-website.s3-us-west-1.amazonaws.com/survey-icons/emoji3.png" alt="" />
                                            <span>Yes</span>
                                        </a></li>
                                        <li><a id="Maybe" className="status"> <img onClick={(e) => this.getNew('Maybe', e)} src="https://suiteam-website.s3-us-west-1.amazonaws.com/survey-icons/emoji2.png" alt="" />
                                            <span >Maybe</span>
                                        </a></li>
                                        <li><a id="No" className="status"><img onClick={(e) => this.getNew('No', e)} src="https://suiteam-website.s3-us-west-1.amazonaws.com/survey-icons/emoji1.png" alt="" />
                                            <span >No</span>
                                        </a></li>
                                    </ul>
                                </div> : <div className="activeEmoji">
                                    <ul>
                                        <li><a id="Yes" className="status"> <img onClick={(e) => this.getNew('Yes', e)} src="https://suiteam-website.s3-us-west-1.amazonaws.com/survey-icons/emoji3.png" alt="" />
                                            <span >Yes</span>
                                        </a></li>
                                        <li><a id="No" className="status" > <img onClick={(e) => this.getNew('No', e)} src="https://suiteam-website.s3-us-west-1.amazonaws.com/survey-icons/emoji1.png" alt="" />
                                            <span >No</span>
                                        </a></li>
                                    </ul>
                                </div>}
                        </div>
                        {this.state.emoji?<div className="writearea">
                            <div className="text">
                                <p>Any other comments? (optional)</p>
                            </div>
                            <textarea rows="5" value={this.state.notes}
                                onChange={(e) => this.setValue('notes', e)} placeholder="Write something here...">
                            </textarea>
                            <button className="review-btn" onClick={() => this.feedback()}
                            type="button">Submit</button>
                        </div>:''}
                        
                    </div>}
            </div>
        );
    }
}
export default GuestSurvey;