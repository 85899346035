import React, { Component } from 'react'
import GuestHeaderNav from '../guestPortal/guestHeader';
import GuestServicesapi from '../../../services/guestServicesapi';
import SessionService from "../../../services/sessionService";
import LocationService from '../../../services/locationService';
import SearchMap from '../../components/newcommunity/ExploreMap';
import Loader from "../../common/loader";

class ExploreYourLocation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeandPendingOrdersInfo: [],
      activeOrderInfo: [],
      pendingOrderInfo: [],
      loader: false,
      userData: "",
      selectedCommunity: "",
      communityObject: {},
    }
    this.selectCommunity = this.selectCommunity.bind(this);
  }

  async componentDidMount() {
    await this.setState({ loader: true })
    await SessionService.SessionGet({ type: 'guest' }).then((response) => {
      this.setState({ userData: response })
    })
    await this.getPendingOrderInfo(this.state.userData.GuestEmail)
    await this.getActiveOrderInfo(this.state.userData.GuestEmail)
    let activeandPendingOrdersInfo = this.state.activeOrderInfo.concat(this.state.pendingOrderInfo);

    const limitData = {
      communityName: activeandPendingOrdersInfo[0].OrderType == "S" ? activeandPendingOrdersInfo[0].Community : activeandPendingOrdersInfo[0].HotelName,
      imageType: 'banner'
    };
    const communityObject = await LocationService.getCommunityInfoByName(limitData)
    this.setState({ activeandPendingOrdersInfo, selectedCommunity: activeandPendingOrdersInfo[0].OrderType == "S" ? activeandPendingOrdersInfo[0].Community : activeandPendingOrdersInfo[0].HotelName, communityObject })

    setTimeout(() => {
      this.setState({ loader: false })
      $('.centerSlick').slick({
        centerMode: true,
        centerPadding: '60px',
        slidesToShow: 3,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              arrows: true,
              centerMode: true,
              centerPadding: '40px',
              slidesToShow: 3
            }
          },
          {
            breakpoint: 991,
            settings: {
              arrows: true,
              centerMode: true,
              centerPadding: '40px',
              slidesToShow: 2
            }
          },
          {
            breakpoint: 680,
            settings: {
              arrows: true,
              centerMode: true,
              centerPadding: '40px',
              slidesToShow: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              arrows: true,
              centerMode: true,
              centerPadding: '40px',
              slidesToShow: 1
            }
          }
        ]
      });
    }, 3000)
  }

  /*
*Function to get Active Orders for logged in user
*/
  async getActiveOrderInfo(email) {
    var guestEmailObj = {
      guestEmail: email,
      status: "active"
    }
    await GuestServicesapi.GetOrdersByGuestEmail(guestEmailObj).then((response) => {
      if (response.error !== 1) {
        this.setState({ activeOrderInfo: response.message.Orders });
      }
    })
  }

  async getPendingOrderInfo(email) {
    var guestEmailObj = {
      guestEmail: email,
      status: "pending"
    }
    await GuestServicesapi.GetOrdersByGuestEmail(guestEmailObj).then((response) => {
      if (response.error !== 1) {
        var pendingInfo = response.message.Orders;

        pendingInfo.forEach((item, index) => {
          var community = item.Community
          community = community && community.split(" ");

          var city = item.City
          city = city && city.split(" ");

          var state = item.State
          state = state && state.split(" ");

          for (let i = 0, x = community && community.length; i < x; i++) {
            community[i] = community[i][0].toUpperCase() + community[i].substr(1);
          }

          for (let i = 0, x = city && city.length; i < x; i++) {
            city[i] = city[i][0].toUpperCase() + city[i].substr(1);
          }

          for (let i = 0, x = state && state.length; i < x; i++) {
            state[i] = state[i][0].toUpperCase() + state[i].substr(1);
          }

          state = state && state.join(" ");
          city = city && city.join(" ");
          community = community && community.join(" ");
          state = state && state.charAt(0).toUpperCase() + state.substr(1);
          city = city && city.charAt(0).toUpperCase() + city.substr(1);
          community = community && community.charAt(0).toUpperCase() + community.substr(1);
          pendingInfo[index].State = state
          pendingInfo[index].City = city
          pendingInfo[index].Community = community
          this.setState({ pendingOrderInfo: pendingInfo });
        })
      }
    })
  }

  async selectCommunity(e) {
    this.setState({ selectedCommunity: e.target.value })
    const limitData = {
      communityName: e.target.value,
      imageType: 'banner'
    };

    const communityObject = await LocationService.getCommunityInfoByName(limitData)
    this.setState({ communityObject })
  }

  render() {
    let activeandPendingOrdersInfo = this.state.activeandPendingOrdersInfo && this.state.activeandPendingOrdersInfo.length > 0 ? this.state.activeandPendingOrdersInfo : [];

    var uniq = {};
    let orderInfo=activeandPendingOrdersInfo.filter(item => item.OrderType=="S"&&item.Community&&!uniq[item.Community] && (uniq[item.Community] = true));
    return (
      <div className="guest-Ui">
        <GuestHeaderNav />
        <div className="exploreLocation">
          {this.state.loader ?
            <Loader /> :

            <div>

              <div className="myReservation">
                <div className="communityDetails">
                  <SearchMap {...this.state} latitude={this.state.communityObject ? this.state.communityObject.latitude : 0.0} longitude={this.state.communityObject ? this.state.communityObject.longitude : 0.0}
                    communityName={this.state.communityObject && this.state.communityObject.community_name}
                    communityObject={this.state.communityObject ? this.state.communityObject : {}} type="exploreYourLocation"
                    status={1} orderInfo={orderInfo} selectCommunity={this.selectCommunity} selectedCommunity={this.state.selectedCommunity} />
                </div>
              </div>

            </div>
          }
        </div>
      </div>
    )
  }
}

export default ExploreYourLocation
