/* Importing the node modules, child components, services and controllers used 
   inside NikeInternshipApartmentTour component */
import MainController from "../../controller/mainController";
import SubFooter from "../../common/subFooter";
import NikeInternshipHeader from "../../common/nvidiaHeader";
import ContactUs from "./common/contactUs";
import GuestHeader from '../../common/guestHeader';
import Footer from '../../common/footer';
import NikeInternFooter from "./common/nikeInternFooter";
import TourData from './common/TourData';
/* NikeInternshipApartmentTour Component initialization */
class NikeInternshipApartmentTour extends React.Component {
    /* Initializing objects of its NikeInternshipApartmentTour class */
    constructor(props) {
        super(props);
        this.state = {
            data: TourData
        }
    }
    /* It is invoked immediately before mounting occurs */
    componentWillMount() {
        MainController.getInitialInformation();
    }
    /* It is invoked to return html content */
    render() {
        const { data } = this.state;

        return (
            <div className="corporateHousing_Updates">
                <GuestHeader />
                <header className="codeconveyHeader cc_image_text_main corporate-h">
                    <img className="img-responsive" src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Company-Nike-Internship-Intern.jpg"} width="100%" alt="" />
                    <div className="wow fadeInUp img-on-text top30 width600 text-center left50 img-on-text-center" data-wow-delay="0.5s">
                        <h1>What's included in a standard SuiteAmerica Apartment?</h1>
                    </div>
                </header>
                <NikeInternshipHeader />
                <div className="quote-section bg-white">
                    <div className="container">
                        <div className="text-center col-md-10 col-md-offset-1 wow fadeInUp company_p" data-wow-delay="0.5s">
                            <p>We’ll set up your apartment with everything you need to move in and be comfortable.
                                Do you need something we didn’t include? Give us a call or send us an email telling us what you need. We’ll make every effort to have it waiting for you when you move in to your new home.
                            </p>
                            <h2>Tour a sample of a SuiteAmerica Apartment Now!</h2>
                            <div className="form-group row mrg-top-30">
                            </div>
                            <hr className="hr" />
                        </div>
                        <div className="col-md-12 boxed_list">
                            <h4>STANDARD ITEMS TYPICALLY INCLUDED IN YOUR NEW APARTMENT</h4>
                            <ul>
                                {data.Apartment.map((row, i) => {
                                    return (<li key={i}>{row}</li>)
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="container-fluid mrg-btm-50 mrg-top-50">
                        <div className="img-header row" style={{ backgroundImage: "url("+s3BucketUrl_WebsiteImages+"SuiteAmerica-Company-Nike-Internship-Furniture.jpg)" }}>
                            <h2>Furniture</h2>
                        </div>
                    </div>
                    <div className="container">
                        <div className="col-md-12 boxed_list">
                            <h4>DINING ROOM</h4>
                            <ul>
                                {data.DiningRoom.map((row, i) => {
                                    return (<li key={i}>{row}</li>)
                                })}
                            </ul>
                        </div>
                        <div className="col-md-12 boxed_list">
                            <h4>MASTER BEDROOM</h4>
                            <ul>
                                {data.MasterBedRoom.map((row, i) => {
                                    return (<li key={i}>{row}</li>)
                                })}
                            </ul>
                        </div>
                        <div className="col-md-12 boxed_list">
                            <h4>LIVING ROOM</h4>
                            <ul>
                                {data.LivingRoom.map((row, i) => {
                                    return (<li key={i}>{row}</li>)
                                })}
                            </ul>
                        </div>
                        <div className="col-md-12 boxed_list">
                            <h4>ADDITIONAL BEDROOM</h4>
                            <ul>
                                {data.AdditionalBedroom.map((row, i) => {
                                    return (<li key={i}>{row}</li>)
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="container-fluid mrg-btm-50 mrg-top-50">
                        <div className="img-header row" style={{ backgroundImage: "url("+s3BucketUrl_WebsiteImages+"SuiteAmerica-Company-Nike-Internship-Housewares.jpg)" }}>
                            <h2>Housewares</h2>
                        </div>
                    </div>
                    <div className="container">
                        <div className="col-md-12 boxed_list">
                            <h4>BATHROOM</h4>
                            <ul>
                                {data.BathRoom.map((row, i) => {
                                    return (<li key={i}>{row}</li>)
                                })}
                            </ul>
                        </div>
                        <div className="col-md-12 boxed_list">
                            <h4>BEDROOM</h4>
                            <ul>
                                {data.BedRoom.map((row, i) => {
                                    return (<li key={i}>{row}</li>)
                                })}
                            </ul>
                        </div>
                        <div className="col-md-12 boxed_list">
                            <h4>DINING</h4>
                            <ul>
                                {data.Dining.map((row, i) => {
                                    return (<li key={i}>{row}</li>)
                                })}
                            </ul>
                        </div>
                        <div className="col-md-12 boxed_list">
                            <h4>KITCHEN</h4>
                            <ul>
                                {data.Kitchen.map((row, i) => {
                                    return (<li key={i}>{row}</li>)
                                })}
                            </ul>
                        </div>
                        <div className="col-md-12 boxed_list">
                            <h4>MOVE-IN KIT</h4>
                            <ul>
                                {data.Moveinkit.map((row, i) => {
                                    return (<li key={i}>{row}</li>)
                                })}
                            </ul>
                        </div>
                        <div className="col-md-12 boxed_list">
                            <h4>MISCELLANEOUS</h4>
                            <ul>
                                {data.Miscellaneous.map((row, i) => {
                                    return (<li key={i}>{row}</li>)
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="container-fluid bg-grey">
                    <div className="container">
                        <ContactUs />
                    </div>
                </div>
                <div className="container ft-contact-adrs mrg-btm-30 mrg-top-30">
                    <NikeInternFooter />
                </div>
                <SubFooter />
                <Footer />
            </div>

        );
    }
}
export default NikeInternshipApartmentTour;