const FirstRow = props => {
    const requestDetails = props.requestDetails
    return (
        <div className="container">
        <div className="header-wrap">
                   <h1 className="align-center content-mail-title">{props.serviceRequest?'SERVICE REQUEST FORM':'KEYLIST'}</h1>
                   <div className="sr_form_wraper mrg-top-40">
                   {requestDetails.OrderType=='H'? <div className="qwer1">
                         <h1>Hotel Name:</h1>
                         <p>{requestDetails.HotelName == null ? '---' : requestDetails.HotelName}</p>
                      </div>:<div className="qwer1">
                         <h1>COMMUNITY NAME:</h1>
                         <p>{requestDetails.community_name == null ? '---' : requestDetails.community_name}</p>
                      </div>}
                      {requestDetails.OrderType=='H'?<div className="qwer1">
                         <h1>ADDRESS:</h1>
                         <p>{requestDetails.hoteladdress == null ? '---' : requestDetails.hoteladdress}
                         </p>
                      </div>: <div className="qwer1">
                         <h1>ADDRESS:</h1>
                         <p>{requestDetails.address == null ? '---' : requestDetails.address}
                         </p>
                      </div>}
                      {requestDetails.OrderType=='H'?<div className="qwer1">
                         <h1>City:</h1>
                         <p>{requestDetails.hotelcity == null ? '---' : requestDetails.hotelcity}</p>
                      </div>:<div className="qwer1">
                         <h1>City:</h1>
                         <p>{requestDetails.city_name == null ? '---' : requestDetails.city_name}</p>
                      </div>}
                      {!props.serviceRequest?'':(requestDetails.latitude=='' || requestDetails.latitude==null ||  requestDetails.latitude==undefined)? '':<div className="qwer1" style={{position: 'relative'}}>
                         <h1>Commute: <i  style={{position: 'absolute', right: '10px', fontSize: '50px', cursor: 'pointer'}} className="fa fa-map-marker fa-lg color-suite" data-toggle="tooltip" data-placement="top" title="Access Current Location" onClick={()=>props.getMyLocation()}></i></h1>
                         <p>Distance: {props.distance}</p>
                         <p>Duration: {props.duration}</p>
                      </div>}
                   </div>
                </div>
                </div>
    );
  };

  export default FirstRow;