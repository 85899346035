/* Importing the node modules, child components, services and controllers used 
   inside Email component */
import $ from "jquery";
import AppController from "../../../controller/appController";
import FivestarHeader from '../../../common/fivestarHeader';
import S3Upload from 'react-s3-uploader/s3upload';
import moment from 'moment'
import {confirmAlert} from 'react-confirm-alert';
import FiveStarService from '../../../../services/fiveStarService';
import SessionService from "../../../../services/sessionService";
import SuccessModal from '../../../common/successModal';
import EncryptDecrypt from '../../../../services/encryptDecrypt';
import FiveStarApiServices from '../../../../services/fiveStarApiServices';
/* Email Component initialization */
class Email extends React.Component{
  /* Initializing objects of its Email class */  
    constructor(props) {
      super(props);
      this.state={
        community:{},
        communityName:'',
        email:'',
        subject:'',
        message:'',
        filesUpload:false,
        filesLists: [],
        filesList:[],
        successMesage:'',
        color:'',
        communitys:[],
        date:(localStorage.getItem('startDate') == null || localStorage.getItem('startDate') == undefined)? moment().format('YYYY-MM-DD') : localStorage.getItem('startDate'),
        emailList:['guestservices@suiteamerica.com','drivers@suiteamerica.com','distributionleadership@suiteamerica.com'],
        driverManagerInfo: {},
        requestDetails:{}
      }
      this.setValue = this.setValue.bind(this);
      this._handleImageChange = this._handleImageChange.bind(this);
      this.dropDownSetValue = this.dropDownSetValue.bind(this);
      this.emailSend = this.emailSend.bind(this);
    }
    /* It is invoked immediately before a component is mounted */  
      async componentDidMount() {
        $('html, body').animate({scrollTop: 0});
        AppController.stickyTitles()
        
        const driverInformation=  await SessionService.SessionGet({type:'fivestar'});
        this.setState({driverManagerInfo:driverInformation})
        
        const dateDetails={
          DateRequested:this.state.date,
          DriverUid: this.state.driverManagerInfo.UniqueId,
        }
        const resCommunitys = await FiveStarApiServices.getCommunitysByDriverId(dateDetails)
        const TableId=localStorage.getItem('requestFormId')
        await this.setState({communitys:resCommunitys});
}
setValue(field, e) {
  this.setState({successMesage:'',color:''})
  var object = {};
  object[field] = e.target.value;
  this.setState(object);
}

dropDownSetValue(field, e){
  var filteredCommunity={}
  filteredCommunity = _.find(this.state.communitys, function (o) 
  {
      return o.sTableId == e.target.value
    })
  this.setState({community:filteredCommunity,communityName:e.target.value})
}
async emailSend(){
  const pstDate = AppController.pstDate()

  const emailData={
    companyName:this.state.community.community_name,
    message:this.state.message,
    subject:this.state.subject,
    email:this.state.email,
    filesLists:this.state.filesLists,
    driver:this.state.driverManagerInfo.Identifier,
    createdDate:pstDate,
    orderId:this.state.community.TableId,
    masterId:this.state.community.mastertableId,
    SuiteNo: this.state.community.SuiteNo,
    address:this.state.community.address,
    state_name:this.state.community.state_name,
    city_name:this.state.community.city_name,
    ZipCode:this.state.community.ZipCode,
    sTableId: this.state.community.sTableId,
    type: this.state.community.type,
    orderType: this.state.community.OrderType,
    HotelName: this.state.community.HotelName,
    HotelAddress: this.state.community.hoteladdress,
    HotelCity: this.state.community.hotelcity,
    HotelState: this.state.community.hotelstate,
    HotelZipcode: this.state.community.hotelzipcode,
    HotelSuiteNo: this.state.community.hotelsuite,
  }

const emailSendRes=await FiveStarService.email(emailData)
if(emailSendRes.response){
  this.setState({successMesage:'' ,color:'',
  companyName:'',
  subject:'',
  message:'',
  filesLists: [],
  filesList:[],
}) 
jQuery(function($) {
  $('#successModal').modal('show');
  setTimeout(function(){
    $('#successModal').modal('hide');
  },4000);
});
}
else{
  this.setState({successMesage:'Mail Not Sent',color:'red'})
}
}
async _handleImageChange(type, index, e) {
  e.preventDefault();
if(this.state.filesList.length<=3)
{
  let reader = new FileReader();
  const fileIndex = e.target.name.split('-')
       const _this = this
      _.forEach(e.target.files, function(file) {
          const S3Uploader = new S3Upload({
              files: [file],
              signingUrl: '/s3/sign',
              s3path: 'Fivestar/Email/'+moment().format("MM-DD-YYYY")+'/',
              signingUrlQueryParams: {},
              onProgress: _this.onProgress.bind(_this, type, index),
              onFinishS3Put: _this.onFinish.bind(_this, type, index, file),
              onError: _this.onError.bind(_this, type, index),
              uploadRequestHeaders: {
                  'x-amz-acl': 'public-read'
              },
              contentDisposition: file.type,
              server: ''
          }) 
      })
  }
else{
this.setState({message:'Please select only four files',color:'red'})
setTimeout(function() {
  this.setState({message:'',color:''})
}.bind(this), 3000);
}
}
/* Progress event for uploading image into s3 bucket */        
async onProgress(type, fileIndex, progress) {
  this.setState({filesUpload: true})
}
/* Error event for uploading image into s3 bucket */    
onError(type, fileIndex, err) {}
async onFinish(type, fileIndex, FileDetails, info) {
const filepath = 'https://suiteam-website.s3.amazonaws.com/' + info.filename;
await this.setState( {filesUpload : false});
await this.filesListUpdate(filepath, FileDetails, info ,-1)
}
async deleteAlert(dataIndex){
  confirmAlert({
      title: '',                        // Title dialog
      message: 'Are you sure want to Delete ?',               // Message dialog
      childrenElement: () => '',       // Custom UI or Component
      confirmLabel: 'Confirm',                           // Text button confirm
      cancelLabel: 'Cancel',                             // Text button cancel
      onConfirm: this.deleteItems.bind(this,dataIndex),    // Action after Confirm
      onCancel: ()=> '',      // Action after Cancel
    })
}
async  deleteItems(dataIndex){
  const existingPost = this.state.filesLists[dataIndex];
  /* s3 image check*/
  const getS3Image= ''
  /* s3 image check*/
  await this.filesListUpdate('', {}, {}, dataIndex)
}
async filesListUpdate(filepath, FileDetails, info, dataIndex) {
  if (dataIndex < 0) {
      await this.setState({
          filesLists: _.concat(this.state.filesLists, {
              filepath: filepath,
              FileDetails: FileDetails,
              info: info
          })
      })
  } else if (dataIndex >= 0) {
      await this.setState({
          filesLists: update(this.state.filesLists, {
              $splice: [
                  [dataIndex, 1]
              ]
          }, 0)
      })
  }
}
/* It is invoked to return html content */

render(){
    
    return(
      <div id="pages_5star">
        <FivestarHeader/>
  <div className="container">
  <div className="header-wrap">
    <h1 className="align-center content-mail-title">Contact</h1>
  </div>
</div>
<div className="container">
<div className="f-contact-wrap">
	<div className="f-contact-form-style">
		<form>
  <div className="form-group">
    <select className="form-control" value={this.state.email} 
    onChange={(e) => this.setValue('email', e)}>
    <option disabled value=''>To</option>
    {this.state.emailList.map((row, index) => {
        return (
            <option value={row} key={index}>{row}</option>
          )
      })}
        
	  </select>
  </div>
	<div className="form-group">
  <select className="form-control" value={this.state.communityName} 
  onChange={(e) => this.dropDownSetValue('communityName', e)}>
          <option>Community List</option>
          {this.state.communitys.map((row, index) => {
                                       return (
                                         
                                          (row.community_name!=null || row.HotelName!=null) ? <option value={row.sTableId} key={index}>{ row.OrderType=='H' ?row.HotelName:row.community_name}</option>:''
                                       )
                                   })}
        
        </select>
</div>		
			
  <div className="form-group">
    <input type="text" className="form-control"  placeholder="Subject" value={this.state.subject} onChange={(e) => this.setValue('subject', e)}/>
  </div>
						
  <div className="form-group">
	   <textarea className="form-control" id="message" name="message" placeholder="Comment" rows="5" value={this.state.message} onChange={(e) => this.setValue('message', e)}></textarea>
  </div>
			
  {this.state.filesUpload ? <div className="text-center"><span><i className="fa fa-spinner fa-spin"/> please wait file is uploading...!</span></div> : '' }	
  { this.state.filesLists.length <3 && !this.state.filesUpload ? <div className="col-sm-12">
  <div className="form-group">
     <input type="file" name="file-7[]" placeholder="jjj" id="file-7" className="inputfile inputfile-6" data-multiple-caption="{count} files selected" multiple 
     onChange={(e) => this._handleImageChange(2, 0, e)} />
     <label htmlFor="file-7"><span>Upload Attachment (Doc, PDF, JPG, ect.)</span> <strong><img src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Form-Cloud-Upload.png"} width="45" alt="" /></strong></label>
  </div> 
</div> : ''}

{this.state.filesLists.length>0 ?
            
            <div className="col-sm-12">
            {this.state.filesLists.map((row, index) => {
                   return(
               <div className="intra_uploadfile"  key={index}>
             
                  <ul>
                     <li><span>File Name</span></li>
                     <li><span>File Size</span></li>
                  </ul>
                  <ul>
                     <li>{row.FileDetails.name}</li>
                     <li>{row.FileDetails.size} kb</li>
                  </ul>  
                 
               </div> )})}
            </div>:null}


            {!this.state.filesUpload ?  <div className="text-center">  
              <p style={{color:this.state.color,textAlign:'center'}}>{this.state.successMesage} </p>  
<button type="button" className="btn btn_log" onClick={this.emailSend}> SEND</button>
</div>  :null}     
</form>
	</div>
</div>

	
	
</div>
<SuccessModal/>    
   </div>
               
    );
}
}
export default Email;