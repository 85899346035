import React from "react";
import { Link } from "react-router-dom";
import Footer from "./footer";
import Header from "./header";
import OwlCarousel from "react-owl-carousel";
import RequestCallback from "./requestCallback";

class Housingkeeping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.show3dView = this.show3dView.bind(this);
  }

  async componentDidMount() {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
  }
  handleClick() {
    $("#sideQuote").addClass("active");
    $(".overlay").addClass("active");
    $(".collapse.in").toggleClass("in");
    $("a[aria-expanded=true]").attr("aria-expanded", "false");
  }
  closed() {
    $("#sideQuote").removeClass("active");
    $(".overlay").removeClass("active");
  }
  show3dView() {
    jQuery(
      function ($) {
        $("#threevideoView").modal("show");
      }.bind(this)
    );
  }
  render() {
    return (
        <div class="hk-page SAweb_updateUi">
      <div className="SAweb_updateUi">
        <Header />
        </div>
        <div class="banner">
        <h1>
        Housekeeping
        </h1>
      </div>

      <div class="container">
        <div class="howtoPrepare">
          <div class="item">
            <img src="assets/images/housekeeping/image-2.jpg" alt="" />
          </div>
          <div class="item">
            <h3>How to Prepare</h3>

            <ul>
              <li>Please clear all personal items from all surfaces</li>
              <li>If you have a pet, please contain them</li>
            </ul>
          </div>
        </div>

        <div class="howtoPrepare">
          <div class="item">
            <h3>Light Housekeeping Includes</h3>

            <ul>
              <li>Vacuuming of carpets</li>
              <li>Mopping of floors</li>
              <li>Wiping down of surfaces in bathroom & kitchen</li>
              <li>Dusting of surfaces in living room & bedroom(s)</li>
              <li>Replacement of bed sheets</li>
              <li>Replacement of bathroom towels</li>
              <li>Emptying of trash/recycling bins</li>
            </ul>
          </div>
          <div class="item">
            <img src="assets/images/housekeeping/image-1.jpg" alt="" />
          </div>
        </div>
        <div class="howtoPrepare">
          <div class="item">
            <img src="assets/images/housekeeping/image-3.jpg" alt="" />
          </div>
          <div class="item">
            <h3>Things to Know</h3>

            <ul>
              <li>
                Housekeeping will avoid cleaning surfaces with personal items
              </li>
              <li>Housekeeping will be canceled if a pet is not confined</li>
              <li>Please note duvet covers are not replaced</li>
              <li>Additional services may be requested for a fee</li>
            </ul>
          </div>
        </div>
      </div>
       

        <Footer />
      </div>
    );
  }
}
export default Housingkeeping;
