/* Importing the node modules, child components, services and controllers used 
   inside ClientLogin object */
'use strict'
import SuccessModal from '../../common/successModal';
import EncryptDecrypt from '../../../services/encryptDecrypt';
import ClientApiService from '../../../services/clientApiService';
import GuestHeader from '../../common/guestHeader';
import SubFooter from "../../common/subFooter";
import Footer from '../../common/footer';
import MainController from "../../controller/mainController";
import userEmails from '../../../services/userEmails';
import ReCAPTCHA from "react-google-recaptcha";
import platform from 'platform'
import publicIp from 'public-ip'
class ClientLogin extends React.Component {
    constructor(props) {
        super(props)
        /* ClientLogin Component State variables Initialization */
        this.state = {
            email: '',
            password: '',
            message: '',
            color: '',
            loader: false,
            guest: false,
            client: true,
            forGotpassword: false,
            CaptchaValue: ''
        }
        this.setValue = this.setValue.bind(this);
        this.authentication = this.authentication.bind(this);
        this.changeRoles = this.changeRoles.bind(this);
        this.sendForGotPassword = this.sendForGotPassword.bind(this);
        this.onChangeCaptcha = this.onChangeCaptcha.bind(this);
    }
    async componentWillMount() {
        MainController.getInitialInformation();
    }

    setValue(field, e) {
        var object = {};
        object[field] = e.target.value;
        this.setState(object);
    }

    /**
    * To login into the application with corresponding login credentials
    * @param {*} e
    */
    async authentication(e) {
        e.preventDefault();
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (this.state.client) {
            if ((this.state.client && this.state.password != '') && (this.state.email != '' && (reg.test(this.state.email))) && this.state.CaptchaValue != '') {
                this.setState({ message: '', loader: true })
                const address = await publicIp.v4().then(ip => {
                    return ip
                });
                const clientInfo = {
                    'Email': this.state.email,
                    'PasswordHash': this.state.password,
                    browsertype: platform.name,
                    ipaddress: address
                }
                const authCheckRes = await ClientApiService.clientUsersLogin(clientInfo)
                if (authCheckRes.error == "1") {
                    this.setState({ message: 'Invalid username or password', color: 'red', loader: false })
                }
                else {
                    const clientInfos = {
                        userData: {
                            'ClientEmail': authCheckRes.message.Email,
                            'ClientFirstName': authCheckRes.message.FirstName,
                            'ClientLastName': authCheckRes.message.LastName,
                            'ClientId': authCheckRes.message.Id,
                            'clientid': authCheckRes.message.clientid,
                            'ClientType': 'Normal'
                        }, type: 'client'
                    }
                    localStorage.setItem('guestSession', authCheckRes.sessionId)
                    const guestEncryptResult = await EncryptDecrypt.encryptData(clientInfos)
                    localStorage.setItem('_u_web', guestEncryptResult.data)
                    const userData = await EncryptDecrypt.decryptData({ 'type': guestEncryptResult.data })
                    this.setState({ loader: false })
                    switch (userData.recordset[0].roleId) {
                        case 3:
                        case 18:
                        case 19:
                            location.href = '/client-portal'
                            break;

                    }
                }
            }
            else {
                this.setState({ message: 'Enter Valid Email, Password & Select Captcha', color: 'red', loader: false })
            }
        }
    }
    /**
    * To update the client forgotpassword details 
    * @param {*} e
    */
    async sendForGotPassword(e) {
        e.preventDefault();
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (this.state.email != '' && (reg.test(this.state.email))) {
            this.setState({ message: '', loader: true })
            const clientInfo = {
                email: this.state.email,
                encodeEmail: Base64.encode(this.state.email),
                mail: 'clientForgotPassword'
            }
            const mailsRes = await userEmails.sendMail(clientInfo)
            this.setState({ loader: false })
            if (mailsRes.response) {
                jQuery(function ($) {
                    $('#successModal').modal('show');
                    setTimeout(function () {
                        $('#successModal').modal('hide');
                    }.bind(this), 2000);

                }.bind(this));

                this.setState({ forGotpassword: false, email: '' })
            } else {
                this.setState({ message: 'We Are unable to sent to mail', color: 'red' })
            }
        }
        else {
            this.setState({ message: 'Enter Valid Email', color: 'red', loader: false })
        }
    }
    /**
     * To hide and show the login and forgotpassword details
     * @param {*} e
     */
    changeRoles(status) {
        this.setState({ forGotpassword: status, message: '', color: '' })
    }
    async onChangeCaptcha(CaptchaValue) {
        await this.setState({ CaptchaValue })
    }
    render() {
        return (
            <div className="corporateHousing_Updates bg-grey">
                <GuestHeader />
                <div className="cover-tophd-ht"></div>
                <div className="container-fluid bg-grey">
                    <div className="container">
                        <div className="savesearch form-section col-sm-6 col-sm-offset-3 text-center">
                            <h2>{!this.state.forGotpassword ? 'Client Portal Login' : ' Forgot Password'}</h2>
                            <form role="form" method="post">
                                {this.state.message ? <span style={{ color: this.state.color, textAlign: 'center' }}>
                                    <h4>{this.state.message}</h4>
                                </span> : ''}
                                <div className="form-group">
                                    <input type="text" className="form-control" id="email" placeholder="Email" value={this.state.email}
                                        onChange={(e) => this.setValue('email', e)} />
                                </div>
                                {!this.state.forGotpassword ? <div className="form-group">
                                    <input type="password" className="form-control" placeholder={!this.state.forGotpassword ? "Password" : "New Password"} value={this.state.password}
                                        onChange={(e) => this.setValue('password', e)} />
                                </div> : ''}
                                {!this.state.forGotpassword ? <ReCAPTCHA
                                    ref={ref => this.recaptcha = ref}
                                    sitekey="6Lcbw1AUAAAAAE5K5cYDyhoGu0RJY9C3CLtGFXAo"
                                    onChange={this.onChangeCaptcha}
                                /> : null}
                                <div className="form-group row mrg-top-10">
                                    {this.state.loader ?
                                        <div className="panel-body_loader">
                                            <span style={{ fontSize: '20px', width: '100%', margin: 'auto', textAlign: 'center' }}><i className="fa fa-spinner fa-spin" />
                                                <span style={{ marginLeft: '10px' }}> please wait ...!</span> </span>
                                        </div>
                                        : <span><button className="btn company_btn" type="submit" onClick={!this.state.forGotpassword ? (e) => this.authentication(e) : (e) => this.sendForGotPassword(e)}>{!this.state.forGotpassword ? 'Login' : 'Submit'}</button>
                                            <a onClick={() => this.changeRoles(!this.state.forGotpassword ? true : false)} >{!this.state.forGotpassword ? 'Forgot your password?' : 'Login'}</a></span>
                                    }
                                    <br />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <SubFooter />
                <Footer />
                <SuccessModal />
            </div>
        )
    }



}

export default ClientLogin