const Buttons = props => {
  const row = props.rowData
  const index = props.index
  return (
    <div className="row">
      {props.loaderaptReady == index ? <div className="panel-body_loader" style={{ textAlign: 'left', marginTop: '10px', color: "#00000" }}> <span style={{ fontSize: '20px', width: '100%', margin: 'auto', textAlign: 'left' }}><i className="fa fa-spinner fa-spin" /> please wait ...!</span>
      </div> : <div className="col-sm-6">

        {(row.type == 'schedule') && (row.TypeCode == 'T/O' || row.TypeCode == 'W/T') && (row.AptReady == false) ? <div className="sc-order-fl-btn-links mrg-top-25 fivestaraptupdate" onClick={() => props.conformationAlert(row, index)} style={{ 'cursor': 'pointer' }}>
          Suite Ready To Move In
        </div> : <div className="sc-order-fl-btn-links mrg-top-25" style={{ 'color': 'green', fontWeight: 'bold' }}>
          Completed
        </div>}

      </div>}
    </div>

  );
};

export default Buttons;