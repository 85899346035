import Footer from './footer';
import TransHeader from './transheader';
class insurancePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  async componentDidMount() {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }
  }

  render() {

    return (
      <div className="SAweb_updateUi back_layer" >
        <TransHeader />
        <section className="SAweb_insurancePageBanner">
          {/* <img src="assets/newUI-images/insurance/banner.jpg" alt="" width="100%" /> */}
          <div className='container'>
            {/* <div className="textView">
              <h1 data-aos="fade-up">Temporary Housing Solutions</h1>
              <h3 data-aos="fade-up">SuiteAmerica cares</h3>
              <p className="p" data-aos="fade-up">If you have been affected by a natural disaster or are a member of a catastrophe
                team,
                SuiteAmerica’s temporary housing will get you settled quickly.</p>
            </div> */}
          </div>
        </section>

        <section className="SAweb_insuranceSolutions">
          <div className="container">
            <div className="tails">
              <div className="item">
                <div className="pic top"> <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/insurance/slide-1.jpg" alt="" width="100%" /> </div>
                <div className="pic bottom"> <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/insurance/slide-2.jpg" alt="" width="100%" /> </div>
              </div>
              <div className="item">
                <h2>Agile Solutions & Superior Service</h2>
                <p className="p">More than just a fast solution, SuiteAmerica’s fully-furnished apartments come with 24/7 guest
                  service, basic utilities, grocery packages, and housekeeping. </p>

                <p className="p">You have important things to worry about. Your
                  temporary housing should not be one of them.</p>
              </div>
            </div>
          </div>
        </section>

        <section className="SAweb_insuranceWeCanHelp">
          <h2 className="">We can help</h2>
          <div className="container">
            <div className="tails">
              <div className="item">
                <h3>For Policyholders</h3>
                <ul>
                  <li><span>Efficient:</span>Get into temporary housing quickly.</li>
                  <li><span>Proximity:</span>We’ll secure your temporary housing close to your
                    location.</li>
                  <li><span>Prepared:</span>Fully-furnished apartments, including basic utilities, housekeeping options, and
                    grocery
                    starter packages.</li>
                  <li><span> Accessible:</span>SuiteAmerica’s 24/7 Guest Experience Team will answer any questions that might
                    come up.</li>
                  <li><span>Flexible:</span>Billing options include direct billing to either the homeowner or the insurance
                    company.</li>

                </ul>
              </div>
              <div className="item">
                <h3>For Catastrophe Teams</h3>
                <ul>
                  <li><span>Experienced:</span>SuiteAmerica has been in the temporary housing business for three decades.</li>
                  <li><span>Dedicated:</span>Our team
                    will take care of your lodging needs easily and efficiently.</li>
                  <li><span>Industry-Leading Technology:</span>Online booking platform allows easy, quick booking.</li>
                  <li><span>Ready:</span>Fully-furnished suites, prepared with
                    bathroom, kitchen, laundry, and grocery starter kits.</li>
                  <li><span>24/7:</span>Our teams are accessible whenever you need us.
                    We’re here to serve.</li>

                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="SAweb_insuranceBottomText">
          <h3>We care about you and your family. We’re here to serve you.</h3>
        </section>

        <Footer />
      </div>
    );
  }
}
export default insurancePage;