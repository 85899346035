import React from "react";

function SuccessModalWellsfargo() {
  return (
    <div
      className="modal fade savesearch"
      id="successModalWellsfargo"
      role="dialog"
    >
      <div className="modal-dialog">
        <div className="modal-content rm-border-radius">
          <div className="modal-header">
            <button
              type="button"
              className="close btn"
              data-dismiss="modal"
              aria-label=""
            >
              <i className="bi bi-x"></i>
            </button>
          </div>
          <div className="modal-body">
            <p>
              Thank you! Your housing request has been placed successfully.{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuccessModalWellsfargo;
