/* Importing the node modules, child components, services and controllers used 
   inside ContactUs component */
import AppController from "../../../controller/appController";
import NikeServices from '../../../../services/nikeService';
/* ContactUs Component initialization */
class ContactUs extends React.Component {
    /* Initializing objects of its ContactUs class */
    constructor(props) {
        super(props)
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            subject: '',
            message: '',
            color: '',
            errorMessage: ''
        }
        this.setValue = this.setValue.bind(this);
        this.serviceRequest = this.serviceRequest.bind(this);
    }
    /* It is invoked immediately before mounting occurs */
    componentWillMount() {
        AppController.stickyTitles();
        AppController.carousel();
        AppController.updateAnimation();
        $('html, body').animate({ scrollTop: 0 });
    }
    /* Form Details object creations  */
    setValue(field, e) {
        var object = {};
        object[field] = e.target.value;
        this.setState(object);
    }
    /* Service Request Details send to mail  */
    async serviceRequest() {
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        const _this = this
        const validationFields = ['firstName', 'lastName', 'email']
        let error = 0;
        _.forEach(validationFields, function (value) {
            if (_this.state[value] == "") {
                error++;
            } else if (reg.test(_this.state.email) == false) {
                error++;
            }
        });
        if (error == 0) {
            const Request = {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                subject: this.state.subject == '' ? 'NA' : this.state.subject,
                message: this.state.message == '' ? 'NA' : this.state.message,
                mail: 'stripeInternshipContactUs'
            }

            this.setState({
                firstName: '',
                lastName: '',
                email: '',
                subject: '',
                message: '',
                color: '',
                errorMessage: '',
            })
            AppController.showSuccessModal();
            const Response = await NikeServices.serviceRequest(Request)
        }
        else {
            AppController.menuTopNavigation('serviceRequest');
            this.setState({ errorMessage: 'Please Enter Required Fields and Valid Email', color: 'red' })
        }
    }
    /* It is invoked to return html content */
    render() {
        return (
            <div className="savesearch form-section col-sm-8 col-sm-offset-2 text-center" id="serviceRequest">
                <h2>Contact Us
                    <br /><img src={s3BucketUrl_WebsiteImages + "SuiteAmerica-Telephone.png"} width="25px" />
                    <a href="tel:8004104305" className="mobile-phone">800.410.4305</a>
                </h2>
                <p className="text-left" style={{ color: this.state.color }}>{this.state.errorMessage}</p>
                <form role="form" method="post" action="" className="form-horizontal">
                    <div className="form-group row">
                        <input type="text" className="form-control col-md-5" placeholder="*First Name" value={this.state.firstName}
                            onChange={(e) => this.setValue('firstName', e)} />
                    </div>
                    <div className="form-group row">
                        <input type="text" className="form-control col-md-5" placeholder="*Last Name" value={this.state.lastName}
                            onChange={(e) => this.setValue('lastName', e)} />
                    </div>
                    <div className="form-group row">
                        <input type="text" className="form-control col-md-5" placeholder="*Email" value={this.state.email}
                            onChange={(e) => this.setValue('email', e)} />
                    </div>
                    <div className="form-group row">
                        <input type="text" className="form-control col-md-5" placeholder="Subject" value={this.state.subject}
                            onChange={(e) => this.setValue('subject', e)} />
                    </div>
                    <div className="form-group row">
                        <textarea className="form-control col-md-5" placeholder="Message" value={this.state.message}
                            onChange={(e) => this.setValue('message', e)} />
                    </div>
                    <div className="form-group row">
                        <button className="btn company_btn" type="button" onClick={this.serviceRequest}>SEND</button>
                    </div>
                </form>
            </div>
        );
    }
}
export default ContactUs;