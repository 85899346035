'use strict'
import StarbucksController from '../controllers/starbucksController';
import AppController from "../../../controller/appController";
import moment from 'moment'
import TravelBookingApiService from '../../../../services/travelBookingApiService';
import $ from "jquery";
import CalendarController from "../../../controller/calendarController";
import { setTimeout } from 'timers';


class travelModel {
    constructor(thisObject, props) {
        this.Obj = thisObject
    }
    async DidMount(props) {
        localStorage.removeItem('starbuckssearchedLocation');
        localStorage.removeItem('starbucksaddressLat');
        localStorage.removeItem('starbucksaddressLng');
        var uploadPath = props.match.path;
        uploadPath = uploadPath.substring(0, uploadPath.indexOf('/:'));
        var path = (props.match.params.pnr != "" && props.match.params.pnr != undefined) ?
            uploadPath : props.location.pathname
        localStorage.setItem('starbucksurl', path);
        if (props.match.params.pnr != "" && props.match.params.pnr != undefined) {
            const pnr = AppController.decodeData(props.match.params.pnr)
            const userdetails1 = {
                pnrid: pnr
            }
            const ResponseSent = await TravelBookingApiService.GetTravelBookingByPnrId(userdetails1)

            if (ResponseSent.error == 0 && ResponseSent.message != null) {
                if (ResponseSent.message.length > 0 && ResponseSent.message[0].OrderId == null) {
                    const CheckIn = ResponseSent.message[0].CheckInDate.split("T00:00:00")
                    const CheckOut = ResponseSent.message[0].OutDate.split("T00:00:00")

                    const compareFrom = moment(CheckIn[0]).format('MM/DD/YYYY');
                    const compareTo = moment().format('MM/DD/YYYY');

                    var isAfter = moment(compareFrom).isSameOrAfter(compareTo);
                    if (isAfter) {
                        var checkinPnr = moment(CheckIn[0]).format('MM/DD/YYYY');
                        var checkoutPnr = moment(CheckOut[0]).format('MM/DD/YYYY');
                        await this.Obj.setState({ checkin: checkinPnr, checkout: checkoutPnr, travelerObJect: ResponseSent.message[0] })
                        localStorage.setItem('starbuckscheckIn', checkinPnr);
                        localStorage.setItem('starbuckscheckOut', checkoutPnr);
                    }
                    else {
                        props.history.push("/starbucks-travel-dateexpire")
                    }

                } else {
                    props.history.push("/starbucks-travel-alreadysent")
                }
            }
            else {
                props.history.push("/starbucks-travel-alreadysent")
            }

        }
        if (localStorage.getItem('starbuckscheckIn') != null && localStorage.getItem('starbuckscheckOut') != null) {
            this.Obj.setState({ loading: true, loadingStatus: true });
            this.getMasterAvailability(localStorage.getItem('starbuckscheckIn'), localStorage.getItem('starbuckscheckOut'), localStorage.getItem('starbucksbedSize'));
        }
    }
    async WillMount(props) {
        const _this = this.Obj
        var email = localStorage.getItem('starbuckstravel-email');
        var pass = localStorage.getItem('starbuckstravel-password');
        if ((email == undefined || email == null) && (props.match.params.pnr == "" || props.match.params.pnr == undefined)) {
            localStorage.clear()
        }
        if ((props.match.params.pnr != "" && props.match.params.pnr != undefined)) {
            localStorage.clear()
        }
        $('head').append('<link rel="stylesheet" href="/assets/css/travel-booking.css" type="text/css" />');
        $('html, body').animate({
            scrollTop: 0
        });
        AppController.stickyTitles()

        jQuery(function () {
            setTimeout(function () {
                $("#travel-booking").val(_this.state.searchLocation)
            }, 500)
            setTimeout(function () {
                CalendarController.checkInCalendar(_this, -1, _this.state.checkin != '' ?
                    _this.state.checkin :
                    "")
                CalendarController.checkOutCalendar(_this, -1, _this.state.checkout != '' ?
                    _this.state.checkout :
                    "")
                if (_this.state.checkin != '') {
                    jQuery('input[name="checkin"]')
                        .data('daterangepicker')
                        .setStartDate(_this.state.checkin)
                }
                if (_this.state.checkin != '') {
                    jQuery('input[name="checkout"]')
                        .data('daterangepicker')
                        .setStartDate(_this.state.checkout)
                }
            }, 500)

        });

    }
    async checkInCal() {
        const _this = this.Obj
        localStorage.removeItem('starbuckscheckIn');
        localStorage.removeItem('starbuckscheckOut');
        await this
            .Obj
            .setState({
                checkin: '',
                checkout: ''
            })
        await CalendarController.checkInCalendar(_this, -1, _this.state.checkin != '' ?
            _this.state.checkin :
            "")
        await CalendarController.checkOutCalendar(_this, -1, _this.state.checkout != '' ?
            _this.state.checkout :
            "")

    }
    async checkOutCal() {
        const _this = this.Obj
        localStorage.removeItem('starbuckscheckOut');
        await this.Obj.setState({ checkout: '' })
        await CalendarController.checkOutCalendar(_this, 1, _this.state.checkin != '' ?
            _this.state.checkin :
            "")
        await CalendarController.checkInCalendar(_this, 1, _this.state.checkout != '' ?
            _this.state.checkout :
            "")
    }

    async getCommunityData(data, communitiesLength) {
        const filterCommunityData = data

        for (var i = 0; i < filterCommunityData.length; i++) {
            const data = {
                address: filterCommunityData[i].address,
                latitude: filterCommunityData[i].latitude,
                longitude: filterCommunityData[i].longitude
            };
            filterCommunityData[i]['walkScore'] = "";
            filterCommunityData[i]['distance'] = "";
            filterCommunityData[i]['dailyrate'] = await StarbucksController.dailyRent('1x', this.Obj.state.checkin, this.Obj.state.checkout);
            filterCommunityData[i]['size1xRate'] = filterCommunityData[i].Avail1X > 0 ? await StarbucksController.dailyRent('1X', this.Obj.state.checkin, this.Obj.state.checkout) : 0;
            filterCommunityData[i]['size2xRate'] = filterCommunityData[i].Avail2X > 0 ? await StarbucksController.dailyRent('2X', this.Obj.state.checkin, this.Obj.state.checkout) : 0;
            filterCommunityData[i]['size3xRate'] = filterCommunityData[i].Avail3X > 0 ? await StarbucksController.dailyRent('3X', this.Obj.state.checkin, this.Obj.state.checkout) : 0;
            filterCommunityData[i]['sizeSTURate'] = filterCommunityData[i].STU > 0 ? await StarbucksController.dailyRent('STU', this.Obj.state.checkin, this.Obj.state.checkout) : 0;

            await this.Obj.setState({
                communities: _.concat(this.Obj.state.communities, filterCommunityData[i])
            });
            this.getwalkscore(data, communitiesLength + i)
            this.getDistance(filterCommunityData[i].latitude, filterCommunityData[i].longitude, communitiesLength + i, filterCommunityData.length);
            if (filterCommunityData.length == (i + 1)) {
                await this.Obj.setState({
                    loading: false,
                    loadingStatus: false,
                    communities: _.sortBy(this.Obj.state.communities, [function (o) {
                        return o.distance;
                    }])
                });
            }
        }
        jQuery(function () {
            $('html, body').animate({
                scrollTop: $('#communityScroll').offset().top
            }, 400);
        })
    }

    async getwalkscore(data, index) {
        const communityInfo = {
            address: data.address,
            latitude: data.latitude,
            longitude: data.longitude
        };
        const walkscore = await AppController.wallsckoreFun(communityInfo);
        let updatedPosts = update(this.Obj.state.communities, {
            [index]: {
                walkScore: {
                    $set: walkscore.walkscore
                }
            }
        });
        await this.Obj.setState({
            communities: updatedPosts
        });
    }

    async getDistance(latitude, longitude, index, totalLength) {
        if (latitude != '' && latitude != undefined && latitude != null && longitude != '' && longitude != undefined && longitude != null) {
            const distanceVal = AppController.distance(this.Obj.state.addressLat, this.Obj.state.addressLng, latitude, longitude)

            let updatedPosts = update(this.Obj.state.communities, {
                [index]: {
                    distance: {
                        $set: parseFloat(distanceVal)
                    }
                }
            });
            await this.Obj.setState({
                communities: updatedPosts
            });
        } else {

            let updatedPosts = update(this.Obj.state.communities, {
                [index]: {
                    distance: {
                        $set: 0
                    }
                }
            });
            await this
                .Obj
                .setState({
                    communities: updatedPosts
                });
        }

    }
    async getMasterAvailability(checkIn, checkOut, bedsize) {
        var checkInDate = moment(checkIn, 'MM/DD/YYYY'),
            checkOutDate = moment(checkOut, 'MM/DD/YYYY');
        var date1 = new Date(checkInDate);
        var date2 = new Date(checkOutDate);
        var timeDiff = Math.abs(date2.getTime() - date1.getTime());
        let diffDate = Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1;
        const startDate = moment([
            Number(moment(checkIn).format('YYYY')),
            Number(moment(checkIn).format('M')) - 1,
            Number(moment(checkIn).format('D'))
        ]);
        const endDate = moment([
            Number(moment('01/01/2019').format('YYYY')),
            Number(moment('01/01/2019').format('M')) - 1,
            Number(moment('01/01/2019').format('D'))
        ]);
        const finalDate = endDate.diff(startDate, 'days');
        const userLoginEmailEncode = localStorage.getItem('starbuckstravel-email');
        const userLoginEmail = (userLoginEmailEncode != undefined || userLoginEmailEncode != null) ? 'admin' : 'guest'

        if (diffDate <= 30) {
            this.Obj.setState({
                program: ['SBUX TRAVEL'],
                record_limit: 0,
                radius: 30,
                defaultCommunitiesError: false,
                reqDays: 0
            })
            const postObject1 = {
                "program": ['SBUX TRAVEL'],
                "checkin": checkIn,
                "checkout": checkOut,
                "record_limit": 0,
                "radius": 30,
                "roomsize": userLoginEmail == 'guest' ? '1X' : bedsize,
                "reqDays": 0
            };

            let responseTire1 = await this.apiRequest(postObject1);
            let filterResponseTire1 = (finalDate > 0) ? responseTire1 : _.filter(responseTire1, function (o) { return o.city_uid.toLowerCase() != "0a2d899d-3e5b-45d2-88d2-12d4b31cce11"; });
            if (filterResponseTire1.length > 0) {
                await this.getCommunityData(filterResponseTire1, 0)
            }
            else if (filterResponseTire1.length <= 0) {

                this.Obj.setState({
                    program: ['SBUX TRAVEL'],
                    record_limit: 0,
                    radius: 25,
                    defaultCommunitiesError: false,
                    reqDays: 0
                })
                const postObject3 = {
                    "program": ['SBUX TRAVEL'],
                    "checkin": checkIn,
                    "checkout": checkOut,
                    "record_limit": 0,
                    "radius": 25,
                    "roomsize": userLoginEmail == 'guest' ? '1X' : bedsize,
                    "reqDays": 0
                };

                const responseTire3 = await this.apiRequest(postObject3);

                const filterResponseTire3 = (finalDate > 0) ? responseTire3 : _.filter(responseTire3, function (o) { return o.city_uid.toLowerCase() != "0a2d899d-3e5b-45d2-88d2-12d4b31cce11"; });
                if (filterResponseTire3.length > 0) {
                    await this.getCommunityData(filterResponseTire3, 0)
                } else {
                    await this.defaultCommunitiesRequest()
                }
            }


        } else {
            this.Obj.setState({
                program: ['SBUX TRAVEL'],
                record_limit: 0,
                radius: 30,
                defaultCommunitiesError: false,
                reqDays: 0
            })
            const postObject = {
                "program": ["SBUX TRAVEL"],
                "checkin": checkIn,
                "checkout": checkOut,
                "record_limit": 0,
                "radius": 30,
                "roomsize": userLoginEmail == 'guest' ? '1X' : bedsize,
                "reqDays": 0
            };
            console.log(postObject, 'postObject')
            let responseData = await this.apiRequest(postObject)

            if (responseData.length > 0) {
                await this.getCommunityData(responseData, 0)
            }
            else {
                await this.defaultCommunitiesRequest()
            }
        }

    }
    async apiRequest(requestObject) {
        _.assign(requestObject, {
            allowpets: false,
            complexamenities: [],
            "unitamenities": [],
            "latitude": this.Obj.state.addressLat,
            "longitude": this.Obj.state.addressLng,
            "confirmedMoveOut": true
        });
        console.log(requestObject, 'requestObject')
        const Data = await TravelBookingApiService.GetTravelAvailableBillableMasters(requestObject)
        return Data;
    }
    async defaultCommunitiesRequest() {
        await this.Obj.setState({
            loadingStatus: false,
            defaultCommunitiesError: true
        })

        jQuery(function ($) {
            $('#successTravelModal').modal('show');
            setTimeout(async function () {
            }.bind(this), 5000);
        }.bind(this));

    }
    async getCommunities() {
        var checkIn = Date.parse(this.Obj.state.checkin);
        var checkOut = Date.parse(this.Obj.state.checkout);
        const movieInformate = moment(this.Obj.state.checkin).format('MM/DD/YYYY')
        const startDate = moment([
            Number(moment(movieInformate).format('YYYY')),
            Number(moment(movieInformate).format('MM')) - 1,
            Number(moment(movieInformate).format('DD'))
        ])
        const movieOutformate = moment(this.Obj.state.checkout).format('MM/DD/YYYY')
        const endDate = moment([
            Number(moment(movieOutformate).format('YYYY')),
            Number(moment(movieOutformate).format('MM')) - 1,
            Number(moment(movieOutformate).format('DD'))
        ])
        const diffDate = endDate.diff(startDate, 'days');

        this.Obj.setState({
            communities: [],
            loadingStatus: true,
            noOfDays: diffDate
        });
        if (this.Obj.state.checkin == '' || this.Obj.state.checkout == '') {
            this.Obj.setState({
                errorMessage: 'Please select Check In and Check Out date',
                communities: [],
                loadingStatus: false
            });
        } else if (checkIn > checkOut) {
            this.Obj.setState({
                errorMessage: 'Check Out date should be greater than or equal to Check In date',
                communities: [],
                loadingStatus: false
            });
        } else {
            localStorage.setItem('starbuckssearchedLocation', this.Obj.state.searchLocation);
            localStorage.setItem('starbuckscheckIn', this.Obj.state.checkin);
            localStorage.setItem('starbuckscheckOut', this.Obj.state.checkout);
            localStorage.setItem('starbucksbedSize', this.Obj.state.bedsize);

            localStorage.setItem('starbucksaddressLat', this.Obj.state.addressLat);
            localStorage.setItem('starbucksaddressLng', this.Obj.state.addressLng);

            this.getMasterAvailability(this.Obj.state.checkin, this.Obj.state.checkout, this.Obj.state.bedsize);
            this.Obj.setState({ errorMessage: '' });
        }
    }
    setLocation() {
        const _this = this.Obj;
        var places = new google.maps.places.Autocomplete(document.getElementById('travel-booking'));
        google.maps.event.addListener(places, 'place_changed', function () {
            var place = places.getPlace();
            _this.setState({
                searchLocation: places.getPlace().formatted_address,
                addressLat: place.geometry.location.lat(),
                addressLng: place.geometry.location.lng()
            });
        });
        if ($("#travel-booking").val() == '') {
            _this.setState({
                searchLocation: ''
            });
        }


    }
}

export default travelModel