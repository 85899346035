/* Importing the node modules, child components, services and controllers used
   inside RecentlyViewed component */
import OwlCarousel from 'react-owl-carousel';
import $ from "jquery";
import {Link} from 'react-router-dom';

/* RecentlyViewed Component initialization */
class RecentlyViewed extends React.Component {
  /* Initializing objects of its RecentlyViewed class */
  constructor(props) {
    super(props)

    /* RecentlyViewed Component State variables Initialization */
    this.state = {
      recentlyViewData: [],
      spinner:-1
    }
    this.moreInfo = this.moreInfo.bind(this);
    this.unFavouriteCommunity = this.unFavouriteCommunity.bind(this);
    this.favouriteCommunity = this.favouriteCommunity.bind(this);
  }
  /* To see the more information */
  async moreInfo(data, favouriteCheck) {
    if (this.props.redirectLink == '1') {
      console.log('if')
    } else {
      await this.moreInfoMethodInfo(data, favouriteCheck);
      $('html, body').animate({scrollTop: 0});
    }
    if (favouriteCheck != undefined && favouriteCheck.favourite_id != '') {
      data[this.state.favourite] = favouriteCheck.favourite_id;
      localStorage.setItem('moreData', JSON.stringify(data));
    } else {
      data[this.state.favourite] = '';
      localStorage.setItem('moreData', JSON.stringify(data));
    }

  }
  /* To insert favorite in database */
  async favouriteCommunity(data) {
    if(localStorage.getItem('_u_web')!=null){
      this.setState({spinner:data.community_id})
      await this.props.favouriteCommunity(data)
      this.setState({spinner:-1})
    }

  }
  /* Delete FavouriteDetails by userid */
  async unFavouriteCommunity(data) {
    if(localStorage.getItem('_u_web')!=null){
    this.setState({spinner:data.community_id})
    await this.props.unFavouriteCommunity(data);
    this.setState({spinner:-1})
    }
  }
  /* Callback more information */
  async moreInfoMethodInfo(data, favouriteCheck) {
    localStorage.setItem('moreData', JSON.stringify(data));
    await this
      .props
      .moreInfoMethod(data, favouriteCheck);
  }
  /* It is invoked to return html content */
  render() {
 const userObj=localStorage.getItem('_u_web')
    const localStorageVal = (userObj== null)
      ? "#mysave1"
      : null

    const recentlyViewData = this.props.recentlyViewData == undefined
      ? []
      : this.props.recentlyViewData;
    
    return (

      <div>
        <div className="recently_viewed">
          <div className="container">
            {recentlyViewData.length > 0
              ? <div className="recent-communities">
                  <h1>{this.props.headerText}</h1>
                </div>
              : null}
            <div className="row">

              {recentlyViewData.length > 0
                ? <OwlCarousel
                    ref={inst => this.slider = inst}
                    {...{ loop: false, nav:true, dots: true, items: 1, navContainerClass: 'owl-buttons', navText: ["<i class='glyphicon glyphicon-menu-left'></i>", "<i class='glyphicon glyphicon-menu-right'></i>"], responsive:{ 320:{ items:1, nav:true, loop:false }, 767:{ items:2, nav:true, loop:false }, 992:{ items:3, nav:true, loop:false }, 1199:{ items:4, nav:true, loop:false } }}}>
                    {this
                      .props
                      .recentlyViewData
                      .map((galleyImage, galleyImageIndex) => {
                        const favouriteCheck = _.find(this.props.userFavourites, function (o) {
                          return o.ComplexUId == galleyImage.community_id;
                        })

                          const isFavourite = ( userObj!=null && galleyImage.favouritestatus != undefined && galleyImage.favouritestatus != null && galleyImage.favouritestatus != false)? galleyImage.favouritestatus : false
                          const favourite = (this.state.spinner==galleyImage.community_id)? <i style={{position: 'absolute', bottom: '20px', right: '15px', fontSize: '30px', color: "#FFF"}} className="fa fa-spinner fa-spin"/> : ""
                          const ClassName =isFavourite? "fa fa-heart" : "fa fa-heart-o"
                          const iconData = (this.state.spinner==galleyImage.community_id)? favourite : (this.props.companyName == 'suiteamerica' ?<i className={ClassName} style={{color: isFavourite? 'red' : ''}}></i> : null )
                          
                        return <div key={galleyImageIndex}>
                          <div className="ls_images">
                            <Link
                              className="thumbnail"
                              to={'/community/' + this.props.companyName + '/' + galleyImage
                              .community_name
                              .replace(/ /g, "-")}
                              onClick={() => this.moreInfo(galleyImage, favouriteCheck)}>

                              {galleyImage.image_name != null
                                ? <img className="img-responsive" src={galleyImage.image_name} alt=""/>
                                : <img
                                  className="img-responsive"
                                  src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Guest-Portal-Main-Location_Down.jpg"}
                                  alt=""/>
                              }
                            </Link>
                     

                            <a
                          style={{
                          'cursor': 'pointer'
                        }}
                          data-toggle="modal"
                          href={localStorageVal}
                          data-backdrop="static"
                          data-keyboard="false"
                          onClick={isFavourite
                               ? () => this.unFavouriteCommunity(galleyImage)
                               : () => this.favouriteCommunity(galleyImage)}> 
                                 {iconData}
                            
                        </a>
                          </div>

                          <div className="recently_viewedheading wow zoomIn">
                            <h2>{galleyImage.community_name}</h2>
                            <p>{galleyImage.address}</p>

                          </div>
                        </div>
                      })}</OwlCarousel>
                : null}

            </div>

          </div>
        </div>

      </div>
    );
  }
}
export default RecentlyViewed;