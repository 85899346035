import GuestHeader from '../../common/guestHeader';
import SubFooter from "../../common/subFooter";
import Footer from '../../common/footer';
import CalendarController from "../../controller/calendarController";
import MainController from "../../controller/mainController";
import AppController from "../../controller/appController";
import SuccessModal from "./successModal";
import ReserveYourStayService from "../../../services/reserveYourStayService";
import GoogleForm from './googleForm';
import GoogleMainPage from './googleMainPage';
import GoogleHeader from './googleHeader';
import UniqueExperiences from './uniqueExperiences';
import EarnTripCredit from './earnTripCredit';
import StayGoogleFrugal from './stayGoogleFrugal';
import MetaTags from '../../common/metaTags';
import ServerMetaTags from '../../../server/metaTags';   
class GoogleTravel extends React.Component {

    constructor(props) {
        super(props);
        this.state={
         desiredLocation: '',
         addressLat: 0.0,
         addressLng: 0.0,
         checkin: '',
         checkout: '',
         bedRoom: '',
         firstName: '',
         lastName: '',
         email: '',
         phoneNumber: '',
         pet: '',
         contactMethod: '',
         adult: '',
         children: '',
         bedRoomsList: [{ 'name': 'BedRooms', 'value': '' }, { 'name': 'ALL', 'value': '0' }, { 'name': 'STU', 'value': 'STU' }, { 'name': '1X', 'value': '1X' }, { 'name': '2X', 'value': '2X' }, { 'name': '3X', 'value': '3X' }],
         petsList: [{ 'name': 'Pets', 'value': '' }, { 'name': '1', 'value': '1' }, { 'name': '2', 'value': '2' }, { 'name': '3', 'value': '3' }, { 'name': '4', 'value': '4' }],
         adultsList: [{ 'name': 'Adult', 'value': '' }, { 'name': '1', 'value': '1' }, { 'name': '2', 'value': '2' }, { 'name': '3', 'value': '3' }, { 'name': '4', 'value': '4' }, { 'name': '5', 'value': '5' }],
         childrensList: [{ 'name': 'Children', 'value': '' }, { 'name': '1', 'value': '1' }, { 'name': '2', 'value': '2' }, { 'name': '3', 'value': '3' }, { 'name': '4', 'value': '4' }, { 'name': '5', 'value': '5' }],
         contactMethods: [{ 'name': 'Preferred Contact Method', 'value': '', 'icon': '' }, { 'name': 'Phone', 'value': 'Phone', 'icon': "f2b6" }, { 'name': 'Email', 'value': 'Email', 'icon': '' }, { 'name': 'Text', 'value': 'Text', 'icon': '' }],
         comments: '',
         loading: false,
         errorMessage:'',
         color:'',
         page:0
        }
        this.setLocation = this.setLocation.bind(this);
        this.checkInMethod = this.checkInMethod.bind(this);
        this.checkOutMethod = this.checkOutMethod.bind(this);
        this.setValue = this.setValue.bind(this);
         this.setNumber = this.setNumber.bind(this);
        this.reservationSubmit = this.reservationSubmit.bind(this);
        this.pageChanges= this.pageChanges.bind(this);
      this.close=this.close.bind(this)
     
    }
  async componentWillMount() {
   MainController.getInitialInformation();
   const _this = this
   jQuery(function () {
       setTimeout(function () {
           CalendarController.checkInCalendar(_this, -1, _this.state.checkin != ''
               ? _this.state.checkin
               : "")
           CalendarController.checkOutCalendar(_this, -1, _this.state.checkout != ''
               ? _this.state.checkout
               : "")
               if(_this.state.checkin != ''){
                jQuery('input[name="checkin"]')
                .data('daterangepicker')
                .setStartDate(_this.state.checkin)
               } 
               if(_this.state.checkout != ''){
                jQuery('input[name="checkout"]')
                .data('daterangepicker')
                .setStartDate(_this.state.checkout)
               }

       }, 500)

   });

}
  setLocation() {
   const _this = this;
   var places = new google.maps.places.Autocomplete(document.getElementById('google-stay'));
   google.maps.event.addListener(places, 'place_changed', function () {
       var place = places.getPlace();
       _this.setState({ desiredLocation: places.getPlace().formatted_address, addressLat: place.geometry.location.lat(), addressLng: place.geometry.location.lng() });
   });
   if ($("#google-stay").val() == '') {
       _this.setState({ desiredLocation: '' });
   }
}
async checkInMethod() {
   const _this = this
   await this.setState({ checkin: '', checkout: '' })
   await CalendarController.checkInCalendar(_this, -1, _this.state.checkin != '' ? _this.state.checkin : "")
   await CalendarController.checkOutCalendar(_this, -1, _this.state.checkout != '' ? _this.state.checkout : "")

}
async checkOutMethod() {
   const _this = this
   await this.setState({ checkout: '' })
   await CalendarController.checkOutCalendar(_this, 1, _this.state.checkin != '' ? _this.state.checkin : "")
   await CalendarController.checkInCalendar(_this, 1, _this.state.checkout != '' ? _this.state.checkout : "")
}
setValue(field, e) {
   this.setState({ selectDropDown: true,errorMessage:'',color:'' })
   var object = {};
   object[field] = e.target.value;
   this.setState(object);
}
validation() {
   const _this = this
   const validationFields = ['checkin', 'checkout', 'firstName', 'email']
   let error = 0;
   _.forEach(validationFields, function (value) {
       if (_this.state[value] == "") {
           error++;
       }
   });
   return error
}
async reservationSubmit(event) {
    event.preventDefault();
   const error = this.validation();
   const regEmail = AppController.regularExpressions('Email')
   if (error == 0) {
       if (regEmail.test(this.state.email)) {
           this.setState({ loading: true, errorMessage: '' })
           var length = AppController.dateDifference(this.state.checkin, this.state.checkout)
           var pet=this.state.pet != '' ? this.state.pet: '---';
           var comment=this.state.comments != '' ? this.state.comments : '---'
           const reservationInfo = {
            GuestFirstName: this.state.firstName != '' ? this.state.firstName : '---',
            GuestLastName: this.state.lastName != '' ? this.state.lastName : '---',
            GuestPhone: this.state.phoneNumber != '' ? this.state.phoneNumber : '---',
            GuestEmail: this.state.email != '' ? this.state.email : '---',
            Location: this.state.desiredLocation != '' ? this.state.desiredLocation : '---',
            MoveInDate: this.state.checkin != '' ? this.state.checkin : '---',
            MoveOutDate: this.state.checkout != '' ? this.state.checkout : '---',
            Size: (this.state.bedRoom == '' || this.state.bedRoom == '0') ? 'All' : this.state.bedRoom,
            length: (length != '' && length != null) ? length : '---',
            CreatedBy: this.state.email != '' ? this.state.email : '---',
            Pets: pet,
            Adults: this.state.adult != '' ? this.state.adult : '---',
            Child: this.state.children != '' ? this.state.children : '---',
            contactMethod: this.state.contactMethod != '' ? this.state.contactMethod : '---',
            comments: comment,
           }
            const response = await ReserveYourStayService.googleReserveYourStay(reservationInfo);
            this.setState({ loading: false })
            if (response.status) {
                   jQuery(function ($) {
                       $('.bs-example-modal-lg').modal('hide');
                          $('body').removeAttr("style")
                       $('.SuccessModal').modal('show');
                   }.bind(this));
                   setTimeout(function () { jQuery('#reservationsuccessmodal').modal('hide'); }.bind(this), 3000);
                   this.resetData();
                   document.getElementById("google-stay").value = ''
               }
       } else {
           this.setState({ errorMessage: 'Please Enter Vaild Email', color: 'red' })
       }
   }
   else {
       this.setState({ errorMessage: 'Please Enter Required Fields', color: 'red' })
   }
}
close(){
   this.resetData();
}
resetData() {
   this.setState(
       {
           desiredLocation: '',
           checkin: '',
           checkout: '',
           bedRoom: '',
           firstName: '',
           email: '',
           phoneNumber: '',
           GuestLastName: '',
           pet: '',
           adult: '',
           children: '',
           contactMethod: '',
           comments: '',
           lastName: '',
           loading: false,
           errorMessage:'',
           color:''
       }
   )
   document.getElementById("google-stay").value = ''
}
setNumber(field, e) {
   const re = /^[0-9-+\b]+$/;
   if (e.target.value == '' || re.test(e.target.value)) {
       jQuery(function ($) {
           this.setState({
            phoneNumber: e.target.value,
           })
       }.bind(this));
   }
}
pageChanges(pageNo){
   MainController.getInitialInformation();
this.setState({page:pageNo})
}
    render() {
       const {page}=this.state
       const metaData = ServerMetaTags.urlString.getMetaData(this.props.match.url);
       
        return (
            <div>
               <MetaTags 
                    metaTitle = {metaData.title}
                    metaDesription = {metaData.description}
                    metaUrl = {metaData.canonical} />
            <GuestHeader />
            
            <div className="corporate_menu pd-t-120">
               <div className="google_content">
                  <div className="topdiv">
                     {page==0&&<div><GoogleHeader/><GoogleMainPage pageChanges={this.pageChanges}/></div>}
                     {page==1&&<UniqueExperiences pageChanges={this.pageChanges}/>}
                     {page==2&&<EarnTripCredit pageChanges={this.pageChanges}/>}
                     {page==3&&<StayGoogleFrugal pageChanges={this.pageChanges}/>}
                       <GoogleForm
                        {...this.state} 
                        setLocation={this.setLocation} 
                        checkInMethod={this.checkInMethod} 
                        checkOutMethod={this.checkOutMethod}
                        setValue={this.setValue}
                        reservationSubmit={this.reservationSubmit}
                        setNumber={this.setNumber}
                       />
                     <div className="cloud-img">
                        <img className="img-responsive" src={s3BucketUrl+"landing-images/Google/cloud.svg"}  alt=""/>
                     </div>                    
                  </div>
               </div>         
               <div className="social-footer">
                  <ul className="social_list">
                     <li><a href="https://www.instagram.com/suite_america/" target="_blank"><i className="fa fa-instagram"></i></a></li>
                     <li><a href="https://www.facebook.com/SuiteAmerica" target="_blank"><i className="fa fa-facebook-square"></i></a></li>
                     <li><a href="https://www.linkedin.com/company/108459?trk=saber_s000001e_1000" target="_blank"><i className="fa fa-linkedin"></i></a></li>
                     <li><a href="https://twitter.com/SuiteAmerica" target="_blank"><i className="fa fa-twitter"></i></a></li>
                  </ul>
               </div>
               <SubFooter />
               <Footer />        
            </div>
    {/* <GoogleModal
     {...this.state} 
    setLocation={this.setLocation} 
    checkInMethod={this.checkInMethod} 
    checkOutMethod={this.checkOutMethod}
    setValue={this.setValue}
    reservationSubmit={this.reservationSubmit}
    setNumber={this.setNumber}
    /> */}
        <SuccessModal />
         </div>        
        )
    }
}

export default GoogleTravel;
