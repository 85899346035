/* Importing the node modules, child components, services and controllers used 
   inside NikeInternshipContactUs component */
import MainController from "../../controller/mainController";

import SubFooter from "../../common/subFooter";
import GuestHeader from "../../common/guestHeader";
import Footer from "../../common/footer";

import AppleInternShipHeader from "./common/AppleInternShipHeader";
import AppleInternFooter from "./common/AppleInternFooter";
import AppleInternSubFooter from "./common/AppleInternSubFooter";
import ContactUs from "./common/contactUs";

/* NikeInternshipContactUs Component initialization */
class AppleInternshipContactUs extends React.Component {
  /* Initializing objects of its NikeInternshipContactUs class */
  constructor(props) {
    super(props);
  }
  /* It is invoked immediately before mounting occurs */
  componentWillMount() {
    MainController.getInitialInformation();
  }
  /* It is invoked to return html content */
  render() {
    return (
      <div className="corporateHousing_Updates">
        <GuestHeader />
        <div className="cover-tophd-ht"></div>
        <AppleInternShipHeader />
        <div className="container-fluid bg-grey">
          <div className="container">
            <ContactUs />
          </div>
        </div>
        <div className="container ft-contact-adrs mrg-btm-30 mrg-top-30">
          <AppleInternSubFooter />
          <div className="section-line"></div>
          <AppleInternFooter />
        </div>
        <SubFooter />
        <Footer />
      </div>
    );
  }
}
export default AppleInternshipContactUs;
