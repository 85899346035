const HoldModal = props => {
    return (
        <div className="modal fade savesearch" id="holdModal" role="dialog">
            <div className="modal-dialog">
                <div className="modal-content rm-border-radius">
                    <div className="modal-header">
                        {props.modalLoader == true ? '' : <button type="button" className="btn close" data-dismiss="modal" aria-label="">
                            <i className="bi bi-x"></i>
                        </button>}
                    </div>
                    <div className="modal-body">
                        <h2>Reason for holding</h2>
                        <p className="text-center" style={{ color: props.color }}>{props.message}</p>
                        <form role="form" method="post" >
                            <div className="form-group">
                                <div className="col-md-12 mrg-btm-30">
                                    <textarea className="form-control" placeholder="Message" value={props.holdText} onChange={(e) => props.setValue('holdText', e)}></textarea>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                            {props.modalLoader == true ? <div className="panel-body_loader" > <span style={{ fontSize: '25px', width: '100%', margin: 'auto', textAlign: 'center' }}><i className="fa fa-spinner fa-spin" /> please wait ...!</span>
                            </div> : <button
                                className="btn location_btn"
                                type="button" onClick={() => props.holdSubmit()}>Submit</button>}
                        </form>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default HoldModal;