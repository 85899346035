/* Importing the node modules, child components, services and controllers used 
   inside MovingOut component */
import $ from "jquery";
import AppController from "../../controller/appController";
import Guest from "../../common/guest";
import GuestHeader from '../../common/guestHeader';
import Footer from '../../common/footer';
import QuickRequestForm from './quickRequestForm';
/* MovingOut Component initialization */
class MovingOut extends React.Component{
    /* Initializing objects of its MovingOut class */
    constructor(props) {
        super(props)
        this.state = {
            menuShow: false
        }		
      }
      /* It is invoked immediately before mounting occurs */
      componentWillMount() {
        AppController.removeLocalStorageData();
        $('html, body').animate({scrollTop: 0});
        AppController.stickyTitles()  
    }
/* It is invoked to return html content */
render(){
    return(
        <div>
            <GuestHeader/>
            <div className="profile_submenu-ht"></div>
            <Guest/>
            <QuickRequestForm menuShow={this.state.menuShow}/>
        <div className="service_request moving_out">
  <div className="who_weare guest_site ">
      <div className="carousel banner_text">
        <img className="img-responsive" src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Guest-Portal-Moving-Out.jpg"} alt="" />
            <div className="carousel-caption slide-text_22">
              <div className="">
                  <h1  className="toggleHeading">Moving Out</h1>   
                  <p className="">Before you go review these helpful
  tips for a smooth transition.</p>              
              </div>
          </div>         
      </div>
  </div>
  <Guest/>  
            <div className="request_form">
                <div className="container">
            <h1>DEPARTING INSTRUCTIONS</h1>
          <div className="row">
              <div className="col-sm-6 col-md-6">
                  <h2>LEAVE YOUR KEYS</h2>
                  <ol>
                      <li>Leave one door key in the lock box provided to you or return to
  the leasing office in the key envelope provided. After business
  hours and on weekends or holidays, the key can be left in the
  community drob box near the leasing office.
                      <p>For help view our <a href="#">Lockbox Instructions </a></p>
                      </li>
                      <li>Leave all remaining keys, gate remotes, parking tags or stickers
  and access cards in the provided moving out envelope on the
  kitchen counter.</li>
                      <li>Make sure all doors are locked upon your departure.</li>
                  </ol>
                  <h3><strong>$200 will be charged if all keys are not left upon check
  out for lock changes.</strong></h3>
              </div>
              <div className="col-sm-6 col-md-6">
                  <h2>UPDATE YOUR ADDRESS</h2>
                  <p>Change your address with the US Postal Service online! This takes up
  to 14 days. Please also note that we will have limited access to your
  mailbox after your move out. We are restricted by the Postal Service
  from checking for any mail once the suite has been re-rented and is
  occupied. In most cases, mail will be returned to sender after this time</p>
                  
                  <h2>WE’VE GOT YOU COVERED</h2>
                  <h4>Internet and Cable</h4>
                  <p>Please do not disconnect high speed internet service or cable. We will
  take care of this for you.</p>
                  <h4>Cleaning</h4>
                  <p>We will plan to clean the suite after your departure, including washing
  the linens and towels. We ask that you leave the suite in reasonably
  clean condition and remove any trash, food items and any personal or
  unwanted items from the suite.</p>
              </div>            
          </div>
           
            </div>
            </div>
            <div className="moving_outbottom">
                 <h1>THANK YOU FOR<br/>
  STAYING WITH US</h1>
                 <p>Please take a moment to give us your<br/>
   feedback and share your thoughts.</p>
                 <button type="button" className="btn btn-primary">TAKE OUR SURVEY</button>
            </div>
    </div>  
    <Footer/>
               </div>
    );
}
}
export default MovingOut;