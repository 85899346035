
import GdprService from '../../../services/gdprService';
import GuestHeader from '../../common/guestHeader';
import ReservationSuccesModal from "../../controller/reservationSuccesModal";
import MainController from "../../controller/mainController";
import AppController from "../../controller/appController";
import Footer from '../../common/footer';
import ReCAPTCHA from "react-google-recaptcha"
class Gdpr extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            areyou: '',
            organisationname: '',
            vatnumber: '',
            email: '',
            wantData: '',
            wantDataDelete: '',
            wantDelete: '',
            thirdparty: '',
            wantDatatransfer: '',
            wanttransfer: '',
            informationaccess: '',
            questiondetails: '',
            incorrectdata: '',
            correctdata: '',
            moredetails: '',
            processingdata: '',
            quedetails: '',
            personalinfo: '',
            color: '',
            errorMessage: '',
            CaptchaValue: '',
            personarray: ["i'm a person", "i'm representing an Organisation"],
            wantDataarray: [
                "I want SuiteAmerica to delete my personal data ('right to be forgotten')",
                "I want SuiteAmerica to transfer my data to me or a third party",
                "I want to know how SuiteAmerica is using my personal information",
                "I want to rectify incorrect data SuiteAmerica has about me",
                "I want SuiteAmerica to stop using my information for direct marketing and profiling",
                "I want SuiteAmerica to keep my data, but stop processing it",
                "I want to Object to the way SuiteAmerica is using my personal information"],
            wantDataDeletearray: [
                "SuiteAmerica no longer has any use for my data",
                "I want to withdraw my consent for processing my data",
                "I believe my data is being used unlawfully",
                "I want stop my data from being processed",
                "I want stop my data from being used in direct marketing",
                "I want my data te bo deleted to comply with a legal order",
            ],
            transferdataarray: [
                "I want to receive it myself",
                "I want to transfer it to a third party"
            ],
            informationaccessarray: [
                "I want to Know why SuiteAmerica is processing my data",
                "I want to Know how SuiteAmerica received my personal information",
                "I want to Know the types of personal data that SuiteAmerica collects",
                "I want to Know all third parties SuiteAmerica shares my information with",
                "I want to Know the duration for which my data will be stored and the reason for that decision",
                "I want stop my data from being processed",
                "I want stop my data from being used in direct marketing",
                "I want to know if any automated decision making or profiling is being done using my personal information"
            ],
            processingdataarray: [
                "I believe the data SuiteAmerica has about me is inaccurate",
                "I believe my personal information is being used unlawfully",
                "There is no further need for my personal information to be used",
                "I have a pending complaint and i want SuiteAmerica to stop processing my data while this complaint is pending"
            ]
        }
        this.setValue = this.setValue.bind(this);
        this.serviceRequest = this.serviceRequest.bind(this);
        this.onChangeCaptcha = this.onChangeCaptcha.bind(this);
    }
    setValue(field, e) {
        var object = {};
        object[field] = e.target.value;
        this.setState(object);
    }
    /* Service Request Details send to mail  */
    async serviceRequest(e) {
        e.preventDefault();
        const _this = this
        const { areyou, name, organisationname, vatnumber, email,
            wantData, wantDataDelete,wantDelete, wantDatatransfer, thirdparty, wanttransfer,
            informationaccess, questiondetails, incorrectdata, correctdata,
            moredetails, processingdata, quedetails, personalinfo,
            personarray,wantDataarray,transferdataarray} = this.state;
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        let error = 0;
        const validationFields=['CaptchaValue']
        if (areyou == personarray[0] && wantData == wantDataarray[0]) {
            validationFields.push('areyou', 'name','email', 'wantData', 
            'wantDataDelete', 'wantDelete')
        }
        else if(areyou == personarray[0] && wantData == wantDataarray[1]){
            if (wantDatatransfer == transferdataarray[1]) {
                 validationFields.push('areyou', 'name','email', 'wantData', 
                 'wantDatatransfer', "thirdparty", 'wanttransfer')
                }
            else {
                validationFields.push('areyou', 'name','email', 'wantData',
                 'wantDatatransfer', 'wanttransfer')
            }
        }
        else if(areyou == personarray[0] && wantData == wantDataarray[2]){
            validationFields.push('areyou', 'name','email', 'wantData',
             'informationaccess', 'questiondetails')
        }
        else if(areyou == personarray[0] && wantData == wantDataarray[3]){
            validationFields.push('areyou', 'name','email', 'wantData',
             'incorrectdata', 'correctdata')
        }
        else if(areyou == personarray[0] && wantData == wantDataarray[4]){
            validationFields.push('areyou', 'name','email', 'wantData', 
            'moredetails')
        }
         else if(areyou == personarray[0] && wantData == wantDataarray[5]){
            validationFields.push('areyou', 'name','email', 'wantData', 
            'processingdata', 'quedetails')
        }
        else if(areyou == personarray[0] && wantData == wantDataarray[6]){
            validationFields.push('areyou', 'name','email', 'wantData', 
            'personalinfo')
        }
        ///////
       else if (areyou == personarray[1] && wantData == wantDataarray[0]) {
            validationFields.push('areyou', 'name', "organisationname", "vatnumber",
            'email', 'wantData', 'wantDataDelete', 'wantDelete')
        }
        else if(areyou == personarray[1] && wantData == wantDataarray[1]){
            if (wantDatatransfer == transferdataarray[1]) {
                 validationFields.push('areyou', 'name', "organisationname", "vatnumber",
                 'email', 'wantData', 'wantDatatransfer', "thirdparty", 'wanttransfer')
                }
            else {
                validationFields.push('areyou', 'name', "organisationname", "vatnumber",
                'email', 'wantData', 'wantDatatransfer', 'wanttransfer')
            }
        }
        else if(areyou == personarray[1] && wantData == wantDataarray[2]){
            validationFields.push('areyou', 'name', "organisationname", "vatnumber",
            'email', 'wantData', 'informationaccess', 'questiondetails')
        }
        else if(areyou == personarray[1] && wantData == wantDataarray[3]){
            validationFields.push('areyou', 'name', "organisationname", "vatnumber",
            'email', 'wantData', 'incorrectdata', 'correctdata')
        }
        else if(areyou == personarray[1] && wantData == wantDataarray[4]){
            validationFields.push('areyou', 'name', "organisationname", "vatnumber",
            'email', 'wantData', 'moredetails')
        }
         else if(areyou == personarray[1] && wantData == wantDataarray[5]){
            validationFields.push('areyou', 'name', "organisationname", "vatnumber",
            'email', 'wantData', 'processingdata', 'quedetails')
        }
        else if(areyou == personarray[1] && wantData == wantDataarray[6]){
            validationFields.push('areyou', 'name', "organisationname", "vatnumber",
            'email', 'wantData', 'personalinfo')
        }
        else{
            validationFields.push('areyou', 'name', 'email', 'wantData')
        }
      
        _.forEach(validationFields, function (value) {
            if (_this.state[value] == "") {
                error++;
            }
        });
        _.forEach(validationFields, function (value) {
             if (!reg.test(_this.state.email) ) {
                error++;
            }
        });

             if (error == 0) {
            const reservation = {
                areyou: areyou,
                name: name,
                organisationname: organisationname,
                vatnumber: vatnumber,
                email: email,
                wantData: wantData,
                wantDataDelete: wantDataDelete,
                wantDelete:wantDelete,
                thirdparty: thirdparty,
                wantDatatransfer: wantDatatransfer,
                wanttransfer: wanttransfer,
                informationaccess: informationaccess,
                questiondetails: questiondetails,
                incorrectdata: incorrectdata,
                correctdata: correctdata,
                moredetails: moredetails,
                processingdata: processingdata,
                quedetails: quedetails,
                personalinfo: personalinfo
            }
              const response = await GdprService.formSubmition(reservation);
              if (response.status) {
                jQuery(function ($) {
                    $('#reservationsuccessmodal').modal('show');
                }.bind(this));
                setTimeout(function () {
                    jQuery('#reservationsuccessmodal').modal('hide');
                    location.href='/gdpr';
                }.bind(this), 3000);
                
            }
        }
       
        else {
            AppController.menuTopNavigation('gdpr');
            this.setState({ errorMessage: 'Please Enter Required Fields, Valid Email & Select Captcha', color: 'red' })
        }
    }

    componentWillMount() {
        MainController.getInitialInformation();
    }
    async onChangeCaptcha(CaptchaValue) {
        await this.setState({ CaptchaValue: CaptchaValue })
    }
    /* It is invoked to return html content */
    render() {
        const { personarray, wantDataarray, wantDataDeletearray,
            transferdataarray, informationaccessarray, processingdataarray } = this.state
        return (
            <div>
                <div className="corporateHousing_Updates">
                    <GuestHeader />
                    <div className="p-request-form" id="gdpr">
                        <form className="form-horizontal" method="post" action="#">
                            <div>
                                <h1>Exercise your rights under GDPR</h1>
                                <p>Send your GDPR request to <strong>SuiteAmerica</strong> via the form below.<br />
                                    Your request will be sent to the Data Protection Officer at <strong><a href="https://www.suiteamerica.com/" rel="nofollow" target="_blank">SuiteAmerica</a></strong>.
                        </p>
                            </div>
                            <p className="text-center" style={{ color: this.state.color }}>{this.state.errorMessage}</p>
                            <div className="form-group">
                                <label htmlFor="name" className="cols-sm-2 control-label">Are you a person or an organisation?<span className="clr-red-star">*</span></label>
                                <div className="dropdown">
                                    <select className="dropdown-select" value={this.state.areyou}
                                        onChange={(e) => this.setValue("areyou", e)}>
                                        <option value='' disabled >pick an option</option>
                                        {personarray.map((row, index) => {
                                            return (
                                                <option key={index} value={row}>{row}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="username" className="cols-sm-2 control-label">What's your name?<span className="clr-red-star">*</span></label>
                                <div className="cols-sm-10">
                                    <div className="input-group">
                                        <input type="text" className="form-control" value={this.state.name}
                                            onChange={(e) => this.setValue('name', e)} />
                                    </div>
                                </div>
                            </div>

                            {(this.state.areyou == personarray[1]) ?
                                <div>
                                    <div className="form-group">
                                        <label htmlFor="username" className="cols-sm-2 control-label">What's your organisation's name?<span className="clr-red-star">*</span></label>
                                        <div className="cols-sm-10">
                                            <div className="input-group">
                                                <input type="text" className="form-control" value={this.state.organisationname}
                                                    onChange={(e) => this.setValue('organisationname', e)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="username" className="cols-sm-2 control-label">VAT number for your organisation<span className="clr-red-star">*</span></label>
                                        <div className="cols-sm-10">
                                            <div className="input-group">
                                                <input type="text" className="form-control" value={this.state.vatnumber}
                                                    onChange={(e) => this.setValue('vatnumber', e)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null}

                            <div className="form-group">
                                <label htmlFor="email" className="cols-sm-2 control-label">What's your email address?<span className="clr-red-star">*</span></label>
                                <div className="cols-sm-10">
                                    <div className="input-group">
                                        <input type="text" className="form-control" name="email" id="email" value={this.state.email}
                                            onChange={(e) => this.setValue('email', e)} />
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="name" className="cols-sm-2 control-label">What do you want to get done?<span className="clr-red-star">*</span></label>
                                <div className="dropdown">
                                    <select className="dropdown-select" value={this.state.wantData}
                                        onChange={(e) => this.setValue('wantData', e)}>
                                        <option value='' disabled >pick an option</option>
                                        {wantDataarray.map((row, index) => {
                                            return (
                                                <option key={index} value={row}>{row}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>

                            {(this.state.wantData == wantDataarray[0]) ?
                                <div>
                                    <div className="form-group">
                                        <label htmlFor="name" className="cols-sm-2 control-label">Why do you want your data te bo deleted?<span className="clr-red-star">*</span></label>
                                        <div className="dropdown">
                                            <select className="dropdown-select" value={this.state.wantDataDelete}
                                                onChange={(e) => this.setValue('wantDataDelete', e)}>
                                                <option value='' disabled >pick an option</option>
                                                {wantDataDeletearray.map((row, index) => {
                                                    return (
                                                        <option key={index} value={row}>{row}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="name" className="cols-sm-2 control-label">Optionally, specify which data you want to make sure SuiteAmerica will delete <span className="clr-red-star">*</span></label>
                                        <div className="cols-sm-10">
                                            <div className="input-group">
                                                <textarea className="form-control" value={this.state.wantDelete}
                                                    onChange={(e) => this.setValue('wantDelete', e)} />
                                            </div>
                                        </div>
                                    </div>
                                </div> : ''}


                            {(this.state.wantData == wantDataarray[1]) ?
                                <div>
                                    <div className="form-group">
                                        <label htmlFor="name" className="cols-sm-2 control-label">To whom do you want SuiteAmerica to transfer your data?<span className="clr-red-star">*</span></label>
                                        <div className="dropdown">
                                            <select className="dropdown-select" value={this.state.wantDatatransfer}
                                                onChange={(e) => this.setValue('wantDatatransfer', e)}>
                                                <option value='' disabled >pick an option</option>
                                                {transferdataarray.map((row, index) => {
                                                    return (
                                                        <option key={index} value={row}>{row}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    {(this.state.wantDatatransfer == transferdataarray[1]) ?
                                        <div className="form-group">
                                            <label htmlFor="name" className="cols-sm-2 control-label">Specify the third party<span className="clr-red-star">*</span></label>
                                            <div className="cols-sm-10">
                                                <div className="input-group">
                                                    <textarea className="form-control" value={this.state.thirdparty}
                                                        onChange={(e) => this.setValue('thirdparty', e)} />
                                                </div>
                                            </div>
                                        </div>
                                        : ''}
                                    <div className="form-group">
                                        <label htmlFor="name" className="cols-sm-2 control-label">Optionally, describe your case in more detail. Which data should be transferred?<span className="clr-red-star">*</span></label>
                                        <div className="cols-sm-10">
                                            <div className="input-group">
                                                <textarea className="form-control" value={this.state.wanttransfer}
                                                    onChange={(e) => this.setValue('wanttransfer', e)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : ''}

                            {(this.state.wantData == wantDataarray[2]) ?
                                <div>
                                    <div className="form-group">
                                        <label htmlFor="name" className="cols-sm-2 control-label">What information about you would you like to access?<span className="clr-red-star">*</span></label>
                                        <div className="dropdown">
                                            <select className="dropdown-select" value={this.state.informationaccess}
                                                onChange={(e) => this.setValue('informationaccess', e)}>
                                                <option value='' disabled >pick an option</option>
                                                {informationaccessarray.map((row, index) => {
                                                    return (
                                                        <option key={index} value={row}>{row}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="name" className="cols-sm-2 control-label">Optionally, explain your question in more detail<span className="clr-red-star">*</span></label>
                                        <div className="cols-sm-10">
                                            <div className="input-group">
                                                <textarea className="form-control" value={this.state.questiondetails}
                                                    onChange={(e) => this.setValue('questiondetails', e)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : ''}

                            {(this.state.wantData == wantDataarray[3]) ?
                                <div>
                                    <div className="form-group">
                                        <label htmlFor="name" className="cols-sm-2 control-label">Describe the incorrect data that SuiteAmerica has about you<span className="clr-red-star">*</span></label>
                                        <div className="cols-sm-10">
                                            <div className="input-group">
                                                <textarea className="form-control" value={this.state.incorrectdata}
                                                    onChange={(e) => this.setValue('incorrectdata', e)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="name" className="cols-sm-2 control-label">Describe the correct version of the same data<span className="clr-red-star">*</span></label>
                                        <div className="cols-sm-10">
                                            <div className="input-group">
                                                <textarea className="form-control" value={this.state.correctdata}
                                                    onChange={(e) => this.setValue('correctdata', e)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : ''}
                            {(this.state.wantData == wantDataarray[4]) ?
                                <div className="form-group">
                                    <label htmlFor="name" className="cols-sm-2 control-label">Optionally, specify more details.<span className="clr-red-star">*</span></label>
                                    <div className="cols-sm-10">
                                        <div className="input-group">
                                            <textarea className="form-control" value={this.state.moredetails}
                                                onChange={(e) => this.setValue('moredetails', e)} />
                                        </div>
                                    </div>
                                </div>
                                : ''}
                            {(this.state.wantData == wantDataarray[5]) ?
                                <div>
                                    <div className="form-group">
                                        <label htmlFor="name" className="cols-sm-2 control-label">Why do you want SuiteAmerica to stop processing your data?<span className="clr-red-star">*</span></label>
                                        <div className="dropdown">
                                            <select className="dropdown-select" value={this.state.processingdata}
                                                onChange={(e) => this.setValue('processingdata', e)}>
                                                <option value='' disabled >pick an option</option>
                                                {processingdataarray.map((row, index) => {
                                                    return (
                                                        <option key={index} value={row}>{row}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="name" className="cols-sm-2 control-label">Optionally, explain your question in more detail<span className="clr-red-star">*</span></label>
                                        <div className="cols-sm-10">
                                            <div className="input-group">
                                                <textarea className="form-control" value={this.state.quedetails}
                                                    onChange={(e) => this.setValue('quedetails', e)} />
                                            </div>
                                        </div>
                                    </div>
                                </div> : ''}

                            {(this.state.wantData == wantDataarray[6]) ?
                                <div>
                                    <div className="form-group">
                                        <label htmlFor="name" className="cols-sm-2 control-label">Please describe the behaviour of SuiteAmerica you are objecting to, related to the usage of your personal information.<span className="clr-red-star">*</span></label>
                                        <div className="cols-sm-10">
                                            <div className="input-group">
                                                <textarea className="form-control" value={this.state.personalinfo}
                                                    onChange={(e) => this.setValue('personalinfo', e)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : ''}
                            <ReCAPTCHA
                                ref={ref => this.recaptcha = ref}
                                sitekey="6Lcbw1AUAAAAAE5K5cYDyhoGu0RJY9C3CLtGFXAo"
                                onChange={this.onChangeCaptcha}
                            />
                            <div className="form-group mrg-top-40">
                                <button type="button" className="btn btn-primary btn-lg btn-block login-button" onClick={this.serviceRequest}>
                                    Send my request
                                   </button>
                            </div>
                            <div className="form-group securityMessage">
                                <p> <i className="fa fa-lock"></i>   We implement the best security &amp; privacy practices to process your request.</p>
                            </div>

                        </form>
                        <ReservationSuccesModal />
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}
export default Gdpr;