const WalkScore = (props) => {
    const { walkScoreDetails } = props;
    const filteredWalkScore = _.filter(walkScoreDetails, function (o) {
        return o.Score != null;
    })
    return (
        <div className="wlaks_flex">{filteredWalkScore.map((row, index) => {
            return (
                <div key={index}>
                    {console.log('row.image', row.image)}

                    <div className="walks_topimg text-center">
                        <img src={'https://s3-us-west-2.amazonaws.com/suite-website/' + row.image} style={{ width: row.image == 'BikeScore.png' ? '41px' : '22px' }} />
                    </div>
                    <p className="walks_topimgp text-center"> <span>{row.name}<sup style={{ fontSize: '30px', top: '0px' }}>®</sup></span>
                    </p>
                    <div id="canvas-holder" className="walk_score">
                        <canvas id={row.chartName} width="120" height="120"></canvas>
                        <div className="values_donut">
                            <p>{row.Score == null ? 0 : row.Score}</p> <span>{row.description}</span>
                        </div>
                    </div>
                </div>)
        })}

            <div className="white-gap"></div>
        </div>
    )
}

export default WalkScore;
