/* Importing the node modules, child components, services and controllers used 
   inside GuestService component */
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   import GuestServicesapi from '../../../services/guestServicesapi';
   import SubFooter from "../../common/subFooter";
   import moment from "moment";
   
   /* GuestService Component initialization */
   class GuestService extends React.Component {
       /* Initializing objects of its GuestService class */
       constructor(props) {
           super(props)
           /* GuestService Component State variables Initialization */
           this.state = {
               whatsHappeningInfo: [],
               serviceCategory: [],
               roomServiceCategory: [],
               specificPlaceInfo: [],
               serviceInfoReq: [],
               activeOrderInfo: [],
               openResponse: [],
               closeResponse: [],
               userData: {},
               menuShow: false,
               serviceReqImg: "",
               serviceReqName: "",
               tabIndex: 0,
               whichRoom: "",
               whichPlace: "",
               whatsHappening: "",
               errMsg: false,
               status: "new",
               description: "",
               loader: false,
               categoryCode: "",
               showSuccessMsg: false,
               hideBackButton: false,
               descriptionValid: false,
               isPte: true,
               whatsHappeningUniqueId: "",
               closedModalInfo: {},
               roomuid: "",
               roomtypeuid: "",
               IsActiveOrderExists: true,
               openRequest: {},
               openRequestStatus: false,
               notes: "",
               showIfNotesEmpty: false,
               chatResponse: [],
               openReqInterval: "",
               selectedOrder: ""
           }
           this.back = this.back.bind(this);
           this.next = this.next.bind(this);
           this.description = this.description.bind(this);
           this.submit = this.submit.bind(this);
           this.isPte = this.isPte.bind(this);
       }
   
       /* It is invoked immediately after mounting occurs */
       async componentDidMount() {
           //    await SessionService.SessionGet({ type: 'guest' }).then((response) => {
           //      this.setState({ userData: response })
           //    })
   
           let ordersid = {
               orderid: this.props.match.params.id,
           }
           let getdata = await GuestServicesapi.GetOrderInformation(ordersid)
           // console.log(getdata, 'getdata')
           if (getdata != undefined && getdata.orderUId != null) {
               await this.setState({ userData: getdata })
               await this.getServicesCategory();
               var orderUIdObj = {
                   orderid: this.state.userData.orderUId
               }
               await GuestServicesapi.GetServiceInfoRequestByOrderId(orderUIdObj).then((response) => {
                   // console.log(response, 'response56')
                   if (response.length > 0) {
                       response.forEach((item, index) => {
                           if (item.Status == "Not Started") {
                               response[index].Status = "not_started"
                               var groupByResponse = this.groupBy(response, 'Status')
                               this.setState({ openResponse: groupByResponse.not_started, closeResponse: groupByResponse.completed, serviceInfoReq: response })
                           }
                       })
                   }
               })
           } else {
               this.props.history.push("/mismatch-orderid")
           }
   
   
   
           // console.log(this.state.userData, 'userData')
   
       }
   
       /*
       *Function is to get categories
       */
       async getServicesCategory() {
           await this.setState({ loader: true })
           GuestServicesapi.GetServicesCatergory({ type: 'category' }).then((response) => {
               // console.log(response, 'response')
               if (response.error !== 1) {
                   this.setState({
                       serviceCategory: response.message,
                       loader: false
                   })
               } else {
                   this.setState({
                       loader: false
                   })
               }
           })
       }
   
       /*
     *Assigning Selected categories to state
       */
       async setValue(image, name, code) {
           await this.setState({ serviceReqImg: image, serviceReqName: name, categoryCode: code, tabIndex: this.state.tabIndex + 1 })
           await this.getRoomServices();
       }
   
   
       /*
     *Assigning Selected Room to state
       */
       async pickWhichRoom(room, id) {
           await this.setState({ whichRoom: room, roomuid: id })
       }
   
       /*
       *Assigning Selected Place to state
          */
       pickWhichPlace(place, id) {
           this.setState({ whichPlace: place, roomtypeuid: id })
       }
   
       /*
       *Assigning Selected whatsHappening info to state
          */
       pickWhatsHappening(description, uniqueId) {
           this.setState({ whatsHappening: description, whatsHappeningUniqueId: uniqueId })
       }
   
       /*
       *Function is to get Room Services
          */
       async getRoomServices() {
           await this.setState({ loader: true })
           GuestServicesapi.GetRoomServices({ type: 'room' }).then((response) => {
               if (response.error !== 1) {
                   this.setState({
                       roomServiceCategory: response.message,
                       loader: false
                   })
               } else {
                   this.setState({ loader: false })
               }
           })
       }
   
       async back() {
           await this.setState({ tabIndex: this.state.tabIndex - 1, errMsg: false })
           if (this.state.tabIndex == 3) {
               if (this.state.whatsHappeningInfo.length == 0) {
                   await this.setState({ tabIndex: this.state.tabIndex - 1, errMsg: false })
               }
           }
       }
   
       async next() {
           if (this.state.tabIndex == 1) {
               if (this.state.whichRoom == "") {
                   this.setState({ errMsg: true })
               } else {
                   await this.setState({ tabIndex: this.state.tabIndex + 1, errMsg: false })
                   this.specificPlace()
               }
           } else if (this.state.tabIndex == 2) {
               if (this.state.whichPlace == "") {
                   this.setState({ errMsg: true })
               } else {
                   await this.setState({ loader: true })
                   //Function is to get whats Happening Info
                   GuestServicesapi.GetSubCategoryDescription({
                       category: this.state.serviceReqName,
                       room: this.state.whichRoom,
                       subcategory: this.state.whichPlace
                   }).then((response) => {
                       if (response.message.length > 0) {
                           this.setState({
                               whatsHappeningInfo: response.message,
                               loader: false,
                               tabIndex: this.state.tabIndex + 1
                           })
                       } else if (response.message.length == 0) {
                           this.setState({ loader: false, tabIndex: this.state.tabIndex + 2 })
                       }
                   })
               }
           } else if (this.state.tabIndex == 3) {
               if (this.state.whatsHappening == "") {
                   this.setState({ errMsg: true })
               } else {
                   await this.setState({ tabIndex: this.state.tabIndex + 1 })
               }
           }
       }
   
       /*
     *Function is to get Places
       */
       async specificPlace() {
           await this.setState({ loader: true })
           if (this.state.whichRoom == "OTHER") {
               GuestServicesapi.GetSubCategoryService({ type: 'room', category: this.state.serviceReqName, room: this.state.whichRoom }).then((response) => {
                   if (response.error !== 1) {
                       this.setState({
                           specificPlaceInfo: response.message,
                           loader: false
                       })
                   } else {
                       this.setState({ loader: false })
                   }
               })
           } else {
               GuestServicesapi.GetRoomTypeService({ type: 'roomtype', room: this.state.whichRoom }).then((response) => {
                   if (response.error !== 1) {
                       this.setState({
                           specificPlaceInfo: response.message,
                           loader: false
                       })
                   } else {
                       this.setState({ loader: false })
                   }
               })
           }
       }
   
       description(e) {
           this.setState({ description: e.target.value })
       }
   
       /*
       *Function for seperating particular object from array based on particular property
       */
       groupBy(arr, property) {
           return arr.reduce(function (memo, x) {
               if (!memo[x[property]]) { memo[x[property]] = []; }
               memo[x[property]].push(x);
               return memo;
           }, {});
       }
   
       async submit() {
           await this.setState({ loader: true })
           if (this.state.description !== "") {
               await this.getActiveOrderInfo(this.state.userData.GuestEmail)
               var serviceWebReqDetails = {
                   CategoryCode: this.state.categoryCode,
                   CreatedBy: this.state.userData.GuestEmail,
                   CreatedDate: moment().format('YYYY/MM/DD LT'),
                   Description: this.state.description,
                   LeaseUId: this.state.activeOrderInfo.length > 0 ? this.state.activeOrderInfo[0].MasterUid : "",
                   RequestDate: moment().format('YYYY/MM/DD LT'),
                   Requested: this.state.userData.GuestFirstName,
                   community: this.state.activeOrderInfo.length > 0 ? this.state.activeOrderInfo[0].Community : "",
                   // this.state.selectedOrder!==null?this.state.selectedOrder:this.state.userData.orderUId
                   orderUid: this.state.userData.orderUId,
                   IsPte: !this.state.isPte  ? 0 : 1,
                   roomuid: this.state.roomuid,
                   roomtypeuid: this.state.roomtypeuid,
                   SubCategory: this.state.whatsHappening == "" ? "" : this.state.whatsHappening,
                   ServiceDescriptionUId: this.state.whatsHappeningUniqueId == "" ? "" : this.state.whatsHappeningUniqueId
               }
               if (this.state.activeOrderInfo.length > 0) {
                   GuestServicesapi.SaveServiceWebRequestDetails(serviceWebReqDetails).then((response) => {
                       // console.log(response, 'response')
                       if (response.message == "Inserted Successfully") {
                           this.setState({ showSuccessMsg: true, hideBackButton: true, descriptionValid: false, loader: false })
                       }
                   })
               } else {
                   await this.setState({ IsActiveOrderExists: false, descriptionValid: false })
               }
           } else {
               this.setState({ descriptionValid: true, loader: false })
           }
   
       }
   
       /*
       *Function to get Active Orders for logged in user
       */
       async getActiveOrderInfo(email) {
           var guestEmailObj = {
               guestEmail: email,
               status: "active"
           }
           await GuestServicesapi.GetOrdersByGuestEmail(guestEmailObj).then((response) => {
               if (response.error !== 1) {
                   this.setState({ activeOrderInfo: response.message.Orders });
               }
           })
       }
   
       isPte() {
           this.setState({ isPte: !this.state.isPte })
       }
   
       async openModalResponse(image, category, date, description, EntityUId) {
           await this.setState({ loader: true })
           var splitDate = date.split(" ");
           var openModalObj = {
               description: description,
               category: category,
               date: splitDate[0],
               time: splitDate[1] + " " + splitDate[2],
               image: image,
               EntityUId: EntityUId,
               email: this.state.userData.GuestEmail
           }
           // this.props.history.push({
           //   pathname: '/open-request',
           //   state: { openRequest: openModalObj }
           // })
           var entityUidObj = {
               entityUid: EntityUId
           }
           GuestServicesapi.GetServiceRequestNotesById(entityUidObj).then((response) => {
               this.setState({ chatResponse: response, openRequest: openModalObj, openRequestStatus: true, loader: false })
           })
           var openReqInterval = await setInterval(() => {
               GuestServicesapi.GetServiceRequestNotesById(entityUidObj).then((response) => {
                   this.setState({ chatResponse: response, openRequest: openModalObj })
               })
           }, 5000)
           this.setState({ openReqInterval: openReqInterval })
       }
   
       closedModalResponse(Date, Description, CategoryCode, Room, SubCategory, image) {
           var splitDate = Date.split(" ");
           var closeModalObj = {
               description: Description,
               category: CategoryCode,
               room: Room,
               subCategory: SubCategory,
               date: splitDate[0],
               time: splitDate[1] + " " + splitDate[2],
               image: image
           }
           this.setState({ closedModalInfo: closeModalObj })
       }
   
       async setStatusOpen() {
           clearInterval(this.state.openReqInterval)
           var orderUIdObj = {
               orderid: this.state.userData.orderUId
           }
           await this.setState({ status: "open", loader: true, openRequestStatus: false })
           await GuestServicesapi.GetServiceInfoRequestByOrderId(orderUIdObj).then((response) => {
               if (response.length > 0) {
                   response.forEach((item, index) => {
                       if (item.Status == "Not Started") {
                           response[index].Status = "not_started"
                           var groupByResponse = this.groupBy(response, 'Status')
                           this.setState({ openResponse: groupByResponse.not_started, closeResponse: groupByResponse.completed, serviceInfoReq: response, loader: false })
                       }
                   })
               } else {
                   this.setState({ loader: false })
               }
           })
       }
   
       async setStatusClose() {
           var orderUIdObj = {
               orderid: this.state.userData.orderUId
           }
         
           await this.setState({ status: "close", loader: true, openRequestStatus: false })
           await GuestServicesapi.GetServiceInfoRequestByOrderId(orderUIdObj).then((response) => {
               if (response.length > 0) {
                   response.forEach((item, index) => {
                       if (item.Status == "Not Started") {
                           response[index].Status = "not_started"
                           var groupByResponse = this.groupBy(response, 'Status')
                           this.setState({ openResponse: groupByResponse.not_started, closeResponse: groupByResponse.completed, serviceInfoReq: response, loader: false })
                       }
                   })
               } else {
                   this.setState({ loader: false })
               }
           })
       }
   
       notes(e) {
           this.setState({ notes: e.target.value })
       }
   
       submitOpenRequest() {
           if (this.state.notes == "") {
               this.setState({ showIfNotesEmpty: true })
           } else {
               this.clearOpenRequestInput.value = "";
               var saveServiceRequestObj = {
                   ChangedBy: this.state.openRequest.email,
                   EntityUId: this.state.openRequest.EntityUId,
                   TypeCode: "NOTE",
                   EntryDate: moment().format("YYYY-MM-DDTHH:mm:ss.SSS"),
                   EnteredBy: this.state.openRequest.email,
                   // EnteredBy: 'admin@suiteamerica.com',
                   Notes: this.state.notes,
                   Mode: false
               }
               GuestServicesapi.SaveServiceRequestNotesDetails(saveServiceRequestObj).then((responses) => {
                   if (responses !== null) {
                       var entityUidObj = {
                           entityUid: this.state.openRequest.EntityUId
                       }
                       GuestServicesapi.GetServiceRequestNotesById(entityUidObj).then((response) => {
                           this.setState({ chatResponse: response })
                       })
                   }
               })
   
           }
       }
   
       async backToOpenReq() {
           await clearInterval(this.state.openReqInterval)
           this.setState({ openRequestStatus: false })
       }
   
       /* It is invoked to return html content */
       render() {
           return (
               <div>
                   <GuestHeader />
                   <div className="profile_submenu-ht"></div>
                   {/* <Guest /> */}
                   {/* <QuickRequestForm menuShow={this.state.menuShow} /> */}
   
                   {Object.keys(this.state.userData).length > 0 &&
                    <div className="">
                        <div className="sa_top_stripe">
                       <div className="stripe_row">
                           {this.state.userData.GuestFirstName != '' &&
                            <div className="item">
                                <label>Name</label><b>:</b><span>{this.state.userData.GuestFirstName + ' ' + this.state.userData.GuestLastName}</span>
                           </div>}
                           {this.state.userData.GuestEmail != '' &&
                           <div className="item">
                               <label>Email</label><b>:</b><span>xxxxx@{this.state.userData.GuestEmail.split('@')[1]}</span>
                           </div>}
                           {this.state.userData.communityName != '' &&
                           <div className="item">
                                <label>CommunityName</label><b>:</b><span>{this.state.userData.communityName}</span>
                           </div>}
                           {(this.state.userData.MoveInDate  != null && this.state.userData.MoveOutDate != '') &&
                           <div className="item">
                                 <label>MoveInDate</label><b>:</b><span>{moment(this.state.userData.MoveInDate).format('MM/DD/YYYY')}</span>
                           </div>}
                               {(this.state.userData.MoveOutDate != null && this.state.userData.MoveOutDate != '' )&&
                           <div className="item">
                                 <label>MoveOutDate</label><b>:</b><span>{moment(this.state.userData.MoveOutDate).format('MM/DD/YYYY')}</span>
                           </div>}
                       </div>
                       </div>
                   </div>}
   
                   <div className="service-request-page-view s_r_p_v">
                       <div className="container">
                           <ul className="nav nav-tabs">
                               <li className="active" onClick={() => this.setState({ status: "new", openRequestStatus: false })}><a data-toggle="tab" href="#newService">New</a></li>
                               <li onClick={() => this.setStatusOpen()}><a data-toggle="tab" href="#openService">Open</a></li>
                               <li onClick={() => this.setStatusClose()}><a data-toggle="tab" href="#closeService">Closed</a></li>
                           </ul>
                           <div className="tab-content">
                               {this.state.loader ?
                                   <div className="panel-body_loader">
                                       <span style={{ position: "fixed", zIndex: "1031", top: "50%", left: "50%", transform: "translate('-50%', '-50%')" }}><i className="fa fa-spinner fa-spin" />
                                       </span>
                                   </div>
                                   : null}
                               {this.state.status == "new" ?
                                   this.state.tabIndex == 1 ?
                                       <div>
                                           <div id="newService" className="tab-pane fade in active">
                                               <h1>SERVICE REQUESTS</h1>
                                               <div className="screen-one">
                                                   <div className="tracker">
                                                       <span className={this.state.tabIndex == 1 ? "active" : ""}>1</span>
                                                       <span className="">2</span>
                                                       <span className="">3</span>
                                                       <span className="">4</span>
                                                   </div>
                                                   <h2>LET’S GET SPECIFIC</h2>
                                                   <div className="side-view">
                                                       <div className="tails">
                                                           <div className="item s_r_p_v_darkbg">
                                                               <div className="image">
                                                                   <span><img src={this.state.serviceReqImg} alt="" /></span>
                                                               </div>
                                                               <p>{this.state.serviceReqName}</p>
                                                           </div>
                                                       </div>
                                                       <div className="option-view">
                                                           <h3>WHICH ROOM?</h3>
                                                           <div className="box">
                                                               {this.state.roomServiceCategory.length > 0 ?
                                                                   this.state.roomServiceCategory.map((roomServiceCategoryRes) => {
                                                                       return (
                                                                           <div className="item" key={roomServiceCategoryRes.UniqueId}>
                                                                               <label className="radio-inline" key={roomServiceCategoryRes.UniqueId}>
                                                                                   <input type="radio" onChange={() => this.pickWhichRoom(roomServiceCategoryRes.Display, roomServiceCategoryRes.UniqueId)} name={roomServiceCategoryRes.Display} checked={this.state.whichRoom === roomServiceCategoryRes.Display} />{roomServiceCategoryRes.Display}
                                                                               </label>
                                                                           </div>
                                                                       )
                                                                   })
                                                                   : null}
                                                           </div>
                                                       </div>
                                                   </div>
                                                   {this.state.errMsg ? <p style={{ color: "red" }}>Please select Room</p> : null}
                                                   <div className="btns">
                                                       <button className="btn" type="button" onClick={this.back}>BACK</button>
                                                       <button className="btn" type="button" onClick={this.next}>NEXT</button>
                                                   </div>
                                               </div>
                                               <div className="sub-footer">
                                                   <p>For assistance, please call</p>
                                                   <h2>800.367.9501</h2>
                                               </div>
                                           </div>
                                       </div>
                                       : this.state.tabIndex == 2 ?
                                           <div>
                                               <div id="newService" className="tab-pane fade in active">
                                                   <h1>SERVICE REQUESTS</h1>
                                                   <div className="screen-one">
                                                       <div className="tracker">
                                                           <span>1</span>
                                                           <span className={this.state.tabIndex == 2 ? "active" : ""}>2</span>
                                                           <span className="">3</span>
                                                           <span>4</span>
                                                       </div>
                                                       <h2>LET’S GET SPECIFIC</h2>
                                                       <div className="side-view">
                                                           <div className="tails">
                                                               <div className="item s_r_p_v_darkbg">
                                                                   <div className="image">
                                                                       <span><img src={this.state.serviceReqImg} alt="" /></span>
                                                                   </div>
                                                                   <p>{this.state.serviceReqName}</p>
                                                                   <h6>{this.state.whichRoom}</h6>
                                                               </div>
                                                           </div>
                                                           <div className="option-view">
                                                               <h3>WHERE?</h3>
                                                               <div className="box">
                                                                   {this.state.specificPlaceInfo.length > 0 ?
                                                                       this.state.specificPlaceInfo.map((specificPlaceReq) => {
                                                                           return (
                                                                               <div className="item" key={specificPlaceReq.UniqueId}>
                                                                                   <label className="radio-inline">
                                                                                       <input type="radio" onChange={() => this.pickWhichPlace(specificPlaceReq.Display, specificPlaceReq.UniqueId)} name={specificPlaceReq.Display} checked={this.state.whichPlace === specificPlaceReq.Display} />{specificPlaceReq.Display}
                                                                                   </label>
                                                                               </div>
                                                                           )
                                                                       })
                                                                       : null}
                                                               </div>
                                                           </div>
                                                       </div>
                                                       {this.state.errMsg ? <p style={{ color: "red" }}>Please select Place</p> : null}
                                                       <div className="btns">
                                                           <button className="btn" type="button" onClick={this.back}>BACK</button>
                                                           <button className="btn" type="button" onClick={this.next}>NEXT</button>
                                                       </div>
                                                   </div>
                                                   <div className="sub-footer">
                                                       <p>For assistance, please call</p>
                                                       <h2>800.367.9501</h2>
                                                   </div>
                                               </div>
                                           </div>
                                           : this.state.tabIndex == 3 ?
                                               <div>
                                                   <div id="newService" className="tab-pane fade in active">
                                                       <h1>SERVICE REQUESTS</h1>
                                                       <div className="screen-one">
                                                           <div className="tracker">
                                                               <span>1</span>
                                                               <span>2</span>
                                                               <span className={this.state.tabIndex == 3 ? "active" : ""}>3</span>
                                                               <span>4</span>
                                                           </div>
                                                           <h2>LET’S GET SPECIFIC</h2>
                                                           <div className="side-view">
                                                               <div className="tails">
                                                                   <div className="item s_r_p_v_darkbg">
                                                                       <div className="image">
                                                                           <span><img src={this.state.serviceReqImg} alt="" /></span>
                                                                       </div>
                                                                       <p>{this.state.serviceReqName}</p>
                                                                       <h6>{this.state.whichRoom}</h6>
                                                                       <h6>{this.state.whichPlace}</h6>
                                                                   </div>
                                                               </div>
                                                               <div className="option-view">
                                                                   <h3>WHAT IS HAPPENING?</h3>
                                                                   <div className="box">
                                                                       {this.state.whatsHappeningInfo.length > 0 ?
                                                                           this.state.whatsHappeningInfo.map((whatsHappeningInfo, i) => {
                                                                               return (
                                                                                   <div className="item" key={i}>
                                                                                       <label className="radio-inline">
                                                                                           <input type="radio" onChange={() => this.pickWhatsHappening(whatsHappeningInfo.Description, whatsHappeningInfo.UniqueId)} name={whatsHappeningInfo.Description} checked={this.state.whatsHappening === whatsHappeningInfo.Description} />{whatsHappeningInfo.Description}
                                                                                       </label>
                                                                                   </div>
                                                                               )
                                                                           })
                                                                           : null}
                                                                   </div>
                                                               </div>
                                                           </div>
                                                           <div className="btns">
                                                               <button className="btn" type="button" onClick={this.back}>BACK</button>
                                                               <button className="btn" type="button" onClick={this.next}>NEXT</button>
                                                           </div>
                                                       </div>
                                                       <div className="sub-footer">
                                                           <p>For assistance, please call</p>
                                                           <h2>800.367.9501</h2>
                                                       </div>
                                                   </div>
                                               </div>
                                               : this.state.tabIndex == 4 ?
                                                   <div>
                                                       <div id="newService" className="tab-pane fade in active">
                                                           <h1>SERVICE REQUESTS</h1>
                                                           <div className="screen-one">
                                                               <div className="tracker">
                                                                   <span>1</span>
                                                                   <span>2</span>
                                                                   <span>3</span>
                                                                   <span className={this.state.tabIndex == 4 ? "active" : ""}>4</span>
                                                               </div>
                                                               <div className=" tails">
                                                                   <div className="chat-view mrg-top-30">
                                                                       <div className="item s_r_p_v_darkbg">
                                                                           <h5>ORIGINAL REQUEST</h5>
                                                                           <div className="image">
                                                                               <img className="fiveimg" src={this.state.serviceReqImg} alt="" />
                                                                           </div>
                                                                           <p>{this.state.serviceReqName} <span>{moment().format('YYYY/MM/DD LT')}</span> </p>
                                                                       </div>
                                                                       <div className="item-text">
                                                                           <div className="view side-switch">
                                                                               <div className="input-view">
                                                                                   <span className="top"> <h2>Is Permission To Enter?</h2>
                                                                                       <label className="switch">
                                                                                           <input type="checkbox" checked={this.state.isPte} onChange={this.isPte} />
                                                                                           <span className="slider round"></span>
                                                                                       </label></span>
   
                                                                                   <h3>Notes</h3>
                                                                                   <textarea type="text" className="inputform" placeholder="Begin typing your message here" name="description" value={this.state.description} onChange={this.description} autoComplete="off" />
                                                                                   {this.state.descriptionValid&&<span style={{ color: "red", textAlign: "right" }}>Please enter Notes.</span> }
                                                                                   <div className="btns">
                                                                                       <button className="btn" type="button" onClick={this.submit} disabled={(this.state.showSuccessMsg == true ? true : false)}>Submit</button>
                                                                                   </div>
                                                                               </div>
                                                                           </div>
                                                                       </div>
                                                                   </div>
                                                               </div>
                                                               {!this.state.IsActiveOrderExists? <p style={{ color: "red", textAlign: "right" }}>You are not allowed to submit, because you dont have any active orders.</p> : null}
                                                               {/* this.state.descriptionValid == true ? <p style={{ color: "red", textAlign: "right" }}>Please enter Notes.</p> : null */}
                                                               {this.state.showSuccessMsg == true ? <p style={{ color: "green", textAlign: "right" }}>Request Submitted Successfully.</p> : null}
                                                               {this.state.hideBackButton == false ?
                                                                   <div className="btns">
                                                                       <button className="btn" type="button" onClick={this.back}>BACK</button>
                                                                   </div>
                                                                   : null}
                                                           </div>
                                                           <div className="sub-footer">
                                                               <p>For assistance, please call</p>
                                                               <h2>800.367.9501</h2>
                                                           </div>
                                                       </div>
                                                   </div>
                                                   :
                                                   <div>
                                                       <div id="newService" className="tab-pane fade in active">
                                                           <h1>SERVICE REQUESTS</h1>
                                                           <div className="screen-one">
                                                               <h2>HOW CAN WE HELP?</h2>
                                                               <div className="grid-view tails">
                                                                   {this.state.serviceCategory.length > 0 ?
                                                                       this.state.serviceCategory.map((serviceCategoryResponse) => {
                                                                           return (
                                                                               <div className="item" key={serviceCategoryResponse.UniqueId} onClick={() => this.setValue(serviceCategoryResponse.ImageUrl, serviceCategoryResponse.Display, serviceCategoryResponse.Code)}>
                                                                                   <div className="image">
                                                                                       <span><img className="" src={serviceCategoryResponse.ImageUrl} alt="" /></span>
                                                                                   </div>
                                                                                   <p>{serviceCategoryResponse.Display}</p>
                                                                               </div>
                                                                           )
                                                                       })
                                                                       : null}
                                                               </div>
                                                           </div>
                                                           <div className="sub-footer">
                                                               <p>For assistance, please call</p>
                                                               <h2>800.367.9501</h2>
                                                           </div>
                                                       </div>
                                                   </div>
                                   : this.state.status == "open" ?
                                       <div id="openService" className="tab-pane fade">
                                           <div id="newService" className="tab-pane fade">
                                           </div>
                                           <div id="openService" className="tab-pane fade  in active">
                                               <h1>SERVICE REQUESTS</h1>
                                               <div className="screen-one">
                                                   <h2>OPEN REQUESTS</h2>
                                                   <div className="grid-view tails">
                                                       {this.state.openRequestStatus == false ?
                                                           this.state.openResponse.length > 0 && this.state.loader == false ?
                                                               this.state.openResponse.map((openResponseInfo, i) => {
                                                                   return (
                                                                       <div className="item" key={i} onClick={() => this.openModalResponse(openResponseInfo.ImageUrl, openResponseInfo.CategoryCode, openResponseInfo.CreatedDate, openResponseInfo.Description, openResponseInfo.EntityUId)}>
                                                                           <div className="image">
                                                                               <span><img src={openResponseInfo.ImageUrl} alt="" /></span>
                                                                           </div>
                                                                           <p>{openResponseInfo.CategoryCode} <span>{openResponseInfo.CreatedDate}</span> </p>
                                                                       </div>
                                                                   )
                                                               })
                                                               : this.state.loader == false ? <p className="validationTag">No open requests found for this order.</p> : null
                                                           : this.state.loader == false ?
                                                               <div className="service-request-page-view s_r_p_v">
                                                                   <div className="container">
                                                                       <div className="tab-content">
                                                                           <div id="openService" className="tab-pane fade  in active">
                                                                               <div className="screen-one">
                                                                                   <div className=" tails">
                                                                                       <div className="chat-view mrg-top-30">
                                                                                           <div className="item s_r_p_v_darkbg mrg-top-50">
                                                                                               <h5>ORIGINAL REQUEST</h5>
                                                                                               <div className="image">
                                                                                                   <img className="fiveimg" src={this.state.openRequest.image} alt="" />
                                                                                               </div>
                                                                                               <p>{this.state.openRequest.category} <span>{this.state.openRequest.date} - {this.state.openRequest.time}</span> </p>
                                                                                               <h4>{this.state.openRequest.description}</h4>
                                                                                           </div>
                                                                                           <div className="item-text">
                                                                                               <h2>LET'S CHAT</h2>
                                                                                               <div className="view">
                                                                                                   <p>
                                                                                                       {this.state.chatResponse.length > 0 ?
                                                                                                           this.state.chatResponse.map((chat, i) => {
                                                                                                               return (
                                                                                                                   this.state.openRequest.email == chat.EnteredBy ?
                                                                                                                       <span className="red">{chat.Notes}</span>
                                                                                                                       :
                                                                                                                       <span>{chat.Notes}</span>
                                                                                                               )
                                                                                                           })
                                                                                                           : null}
                                                                                                   </p>
                                                                                                   <div className="input-view input-enter">
                                                                                                       <input type="text" name="notes" value={this.state.notes} className="inputform" placeholder="Begin typing your message here" onChange={(e) => this.notes(e)} ref={(ref) => this.clearOpenRequestInput = ref} />
                                                                                                       <i className="fa fa-caret-right" style={{ cursor: "pointer" }} onClick={() => this.submitOpenRequest()} /> </div>
                                                                                               </div>
                                                                                               {this.state.showIfNotesEmpty == true ? <p style={{ color: "red" }}>Please enter message.</p> : null}
                                                                                           </div>
                                                                                       </div>
                                                                                   </div>
                                                                                   <div className="btns">
                                                                                       <button className="btn" type="button" onClick={() => this.backToOpenReq()}>BACK</button>
                                                                                   </div>
                                                                               </div>
                                                                               <div className="sub-footer">
                                                                                   <p>For assistance, please call</p>
                                                                                   <h2>800.367.9501</h2>
                                                                               </div>
                                                                           </div>
                                                                       </div>
                                                                   </div>
                                                               </div>
                                                               : null}
                                                   </div>
                                               </div>
                                               <div className="sub-footer">
                                                   <p>For assistance, please call</p>
                                                   <h2>800.367.9501</h2>
                                               </div>
                                           </div>
                                       </div>
                                       : this.state.status == "close" ?
                                           <div id="closeService" className="tab-pane fade">
   
                                               <div id="newService" className="tab-pane fade">
                                               </div>
                                               <div id="openService" className="tab-pane fade">
                                               </div>
                                               <div id="closeService" className="tab-pane fade  in active">
                                                   <h1>SERVICE REQUESTS</h1>
                                                   <div className="screen-one closeReq">
                                                       <h2>CLOSED REQUESTS</h2>
                                                       <div className="grid-view tails">
                                                           {this.state.closeResponse.length > 0 && this.state.loader == false ?
                                                               this.state.closeResponse.map((openResponseInfo, i) => {
                                                                   return (
                                                                       <div className="item" key={i} data-toggle="modal" data-target="#myModalView" onClick={() => this.closedModalResponse(openResponseInfo.CreatedDate, openResponseInfo.Description, openResponseInfo.CategoryCode, openResponseInfo.Room, openResponseInfo.SubCategory, openResponseInfo.ImageUrl)}>
                                                                           <div className="image">
                                                                               <span><img src={openResponseInfo.ImageUrl} alt="" /></span>
                                                                           </div>
                                                                           <p>{openResponseInfo.CategoryCode} <span>{openResponseInfo.CreatedDate}</span> </p>
                                                                       </div>
                                                                   )
                                                               })
                                                               : this.state.loader == false ? <p className="validationTag">No closed requests found for this order.</p> : null}
                                                       </div>
                                                   </div>
                                                   <div className="sub-footer">
                                                       <p>For assistance, please call</p>
                                                       <h2>800.367.9501</h2>
                                                   </div>
                                               </div>
                                           </div>
                                           : null
                               }
   
                               <div id="myModalView" className="modal fade myModalService" role="dialog">
                                   <div className="modal-dialog">
                                       {/* Modal content*/}
                                       <div className="modal-content">
                                           <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                               <span aria-hidden="true"> × </span>
                                           </button>
                                           <div className="modal-body">
                                               <div className="modal-serviceView">
                                                   <img src={this.state.closedModalInfo.image} alt="" />
                                                   <h1>{this.state.closedModalInfo.date}</h1>
                                                   <h2>{this.state.closedModalInfo.category} &gt; {this.state.closedModalInfo.room} &gt; {this.state.closedModalInfo.subCategory}</h2>
                                                   <h3>{this.state.closedModalInfo.time}</h3>
                                                   <p>{this.state.closedModalInfo.description}</p>
                                               </div>
                                           </div>
                                       </div>
                                   </div>
                               </div>
   
                           </div>
                       </div>
                   </div>
   
                   <SubFooter />
                   <Footer />
               </div >
           );
       }
   }
   export default GuestService;