/* Importing the node modules, child components, services and controllers used 
   inside IntranetCustomRequesttwo component */   
   import S3Upload from 'react-s3-uploader/s3upload'
   import { confirmAlert } from 'react-confirm-alert';
   /* IntranetCustomRequesttwo Component initialization */
   class IntranetCustomRequesttwo extends React.Component{
       /* Initializing objects of its IntranetCustomRequesttwo class */
       constructor(props) {
           super(props)
           this.state = {
            customProjectInstructions: '',
            projectPurpose: '',
            projectAudience: '',
            filesList:[],
            filesUpload:false,
            message:'',
            color:'',           
           }
           this.setValue = this.setValue.bind(this);
           this._handleImageChange = this._handleImageChange.bind(this);
           this.previous = this.previous.bind(this);
           this.next = this.next.bind(this);
         }
         next(data){             
            const request2={
                page:data
               }
            this.props.next(request2)
            } 
            previous(data){
                this.props.previous(data)    
                }
                setValue(field, e) {
                    var object = {};
                    object[field] = e.target.value;
                    this.props.setValue(object);
                }
         
         /* To upload the images into s3 bucket */ 
        async _handleImageChange(type, index, e) {
            e.preventDefault();
       if(this.state.filesList.length<=3)
       {
            let reader = new FileReader();
           console.log(reader)
            const fileIndex = e.target.name.split('-')
            console.log(fileIndex)
                 const _this = this
                _.forEach(e.target.files, function(file) {
                    const S3Uploader = new S3Upload({
                        files: [file],
                        signingUrl: '/s3/sign',
                        s3path: 'intranet/form/'+moment().format("MM-DD-YYYY")+'/',
                        signingUrlQueryParams: {},
                        onProgress: _this.onProgress.bind(_this, type, index),
                        onFinishS3Put: _this.onFinish.bind(_this, type, index, file),
                        onError: _this.onError.bind(_this, type, index),
                        uploadRequestHeaders: {
                            'x-amz-acl': 'public-read'
                        },
                        contentDisposition: file.type,
                        server: ''
                    }) 
                    console.log(S3Uploader)
                })
            }
    else{
        this.setState({message:'Please select only four files',color:'red'})
        setTimeout(function() {
            this.setState({message:'',color:''})
        }.bind(this), 3000);
    }
        }
/* Progress event for uploading image into s3 bucket */        
async onProgress(type, fileIndex, progress) {
            this.setState({filesUpload: true})
           
    }
/* Error event for uploading image into s3 bucket */    
    onError(type, fileIndex, err) {
       console.log(err)
    }
async onFinish(type, fileIndex, FileDetails, info) {
    const filepath = 'https://suiteam-website.s3.amazonaws.com/' + info.filename;
    await this.setState( {filesUpload : false});
    await this.props.filesListUpdate(filepath, FileDetails, info ,-1)
}

async deleteAlert(dataIndex){
    confirmAlert({
        title: '',                        // Title dialog
        message: 'Are you sure want to Delete ?',               // Message dialog
        childrenElement: () => '',       // Custom UI or Component
        confirmLabel: 'Confirm',                           // Text button confirm
        cancelLabel: 'Cancel',                             // Text button cancel
        onConfirm: this.deleteItems.bind(this,dataIndex),    // Action after Confirm
        onCancel: ()=> '',      // Action after Cancel
      })
  }
async  deleteItems(dataIndex){
    const existingPost = this.props.filesLists[dataIndex];
    console.log(existingPost)
    /* s3 image check*/
    /* s3 image check*/
    await this.props.filesListUpdate('', {}, {}, dataIndex)
  }
   /* It is invoked to return html content */	  
   render(){
     
       return(
        <div className="inet_custom">
   <div className="container">
      <h1 className="wow animated zoomIn">MARKETING REQUEST FORM</h1>
      <div className="inet_custom_form">
         <form id="msform" className="wow animated fadeInLeft">
            {/* <!-- progressbar --> */}
            <ul id="progressbar">
               <li className="active"></li>
               <li className="active"></li>
               <li></li>
               <li> </li>
            </ul>
            {/* <!-- fieldsets --> */}
         </form>
         <form className="inet_custom2 wow animated fadeInRight">
            <div className="col-sm-12">
               <div className="form-group">
                  <p>PROJECT DETAILS</p>
               </div>
            </div>
            <div className="col-sm-12">
               <div className="form-group">
                  <textarea rows="6" cols="50" placeholder="Instructions/Edits (please be as detailed as possible)" value={this.props.customProjectInstructions} 
                  onChange={(e) => this.setValue('customProjectInstructions', e)}></textarea>
               </div>
               <div className="form-group">

                      <textarea rows="3" cols="30" type='text'  placeholder="Project Purpose (what’s it for?)" value={this.props.projectPurpose} 
                      onChange={(e) => this.setValue('projectPurpose', e)}></textarea>
                    </div>
                    <div className="form-group">
                      <textarea rows="3" cols="30" type='text'  placeholder="Project Audience (who’s it for?)" value={this.props.projectAudience} 
                      onChange={(e) => this.setValue('projectAudience', e)}></textarea>
                    </div>
            </div>
            
            {this.state.filesUpload ? <div className="text-center"><span><i className="fa fa-spinner fa-spin"/> please wait file is uploading...!</span></div> : '' }
            { this.props.filesLists.length <3 && !this.state.filesUpload ? <div className="col-sm-12">
               <div className="form-group">
                  <input type="file" name="file-7[]" placeholder="jjj" id="file-7" className="inputfile inputfile-6" data-multiple-caption="{count} files selected" multiple onChange={(e) => this._handleImageChange(2, 0, e)} />
                  <label htmlFor="file-7"><span>Upload Attachment (Doc, PDF, JPG, ect.)</span> <strong><img src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Form-Cloud-Upload.png"} width="45" alt="" /></strong></label>
               </div> 
            </div> : ''}
            {this.props.filesLists.length>0 ?
            
            <div className="col-sm-12">
            {this.props.filesLists.map((row, index) => {
                   return(
               <div className="intra_uploadfile"  key={index}>
             
                  <ul>
                     <li><span>File Name</span></li>
                     <li><span>File Size</span></li>
                  </ul>
                  <ul>
                     <li>{row.FileDetails.name}</li>
                     <li>{row.FileDetails.size} kb</li>
                  </ul>  
                 
               </div> )})}
            </div>:null}
            <div className="text-center">  
               <button type="button" className="button inet_button" 
               onClick={() => this.previous(1)}><i className="fa fa-caret-left"></i> PREVIOUS</button>                     	     
               <button type="button" className="button inet_button" 
               onClick={() => this.next(3)}>NEXT<i className="fa fa-caret-right"></i> </button>  
            </div>
         </form>
      </div>
   </div>
</div>
       );
   }
   }
   export default IntranetCustomRequesttwo;