/* Importing the node modules, child components, services and controllers used 
   inside RiotGamesContactUs component */    
   import MainController from "../../controller/mainController";
   import RiotGamesHeader from "../../common/riotgamesHeader";
   import SubFooter from "../../common/subFooter";
   import ContactUs from "./common/contactUs";
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   import RiotGamesFooter from "./common/riotgamesFooter";
   import RiotGamesSubFooter from "./common/riotgamesSubFooter";
   /* RiotGamesContactUs Component initialization */
   class RiotGamesContactUs extends React.Component {
       /* Initializing objects of its RiotGamesContactUs class */
       constructor(props) {
           super(props);
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
           MainController.getInitialInformation();
        }
       /* It is invoked to return html content */
       render() {
           return (
            <div className="corporateHousing_Updates">
                <GuestHeader/>
                <div className="cover-tophd-ht"></div>    
                <RiotGamesHeader />
                <div className="container-fluid bg-grey">
                    <div className="container">
                        <ContactUs />
                    </div>
                </div>
                <div className="container ft-contact-adrs mrg-btm-30 mrg-top-30">
                    <RiotGamesSubFooter />
                <div className="section-line"></div>
                    <RiotGamesFooter />
                </div>
                <SubFooter />
                <Footer/>
            </div>   
           );
       }
   }
   export default RiotGamesContactUs;