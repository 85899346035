import DatePicker from "react-datepicker";
import corporateHousingManagementService from "../../../services/corporateHousingManagementService";
import Spinner from "../../common/spinner";

class BaseModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        className="modal fade savesearch"
        id="baseModal-micheal"
        role="dialog"
      >
        <div className="modal-dialog">
          <div className="modal-content rm-border-radius">
            <div className="modal-header">
              <button
                type="button"
                className="close btn"
                data-dismiss="modal"
                aria-label=""
                // onClick={() => this.props.closePopupWithOutThankYou()}
              >
                <i className="bi bi-x"></i>
              </button>
            </div>
            <div className="modal-body">{this.props.children}</div>
          </div>
        </div>
      </div>
    );
  }
}

class PricingModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      company: "",
      title: "",
      email: "",
      phone: "",
      location: "",
      staylength: "",
      rmc: "",
      ifrmcyes: "",
      yearlymoves: "",
      housingspends: "",
      housingsneeds: "",
      intern: null,
      relocation: null,
      travel: null,
      group: null,
      other: null,
      include: "",
      isLoading: false,
    };
    this.managerInfo = this.props.managerInfo;
    this.handleRmcChange = this.handleRmcChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckIn = this.handleCheckIn.bind(this);
    this.handleProgram = this.handleProgram.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  handleRmcChange(event) {
    console.log("event", event);
    this.setState({ rmc: event.target.value });
  }

  handleChange(event) {
    let field = event.target.name;
    let value = event.target.value;
    const phoneRegex = /^\d{0,10}$/;
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (!value) {
      $(`#${field}`).addClass("validation");
    } else {
      $(`#${field}`).removeClass("validation");
    }

    if (field === "phone" && phoneRegex.test(value)) {
      console.log("phone");
      this.setState({ [field]: value });
      return;
    }
    if (field === "email") {
      if (!emailRegex.test(value)) {
        $(`#${field}`).addClass("validation");
      } else {
        $(`#${field}`).removeClass("validation");
      }
      this.setState({ [field]: value });
    }

    if (field !== "email" && field !== "phone") {
      this.setState({ [field]: value });
    }
  }

  handleCheckIn(date) {
    this.setState({
      checkIn: moment(date),
      checkInSelected: date.format("YYYY-MM-DD HH:mm:ss"),
    });
  }

  handleProgram(event) {
    let field = event.target.name;
    let value = event.target.checked;

    this.setState({ [field]: value });
  }

  validateForm() {
    const mandatoryFields = ["name", "company", "email", "phone"];
    const phoneRegex = /^\d{10}$/;
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let errors = 0;
    for (let i = 0; i < mandatoryFields.length; i++) {
      if (!this.state[mandatoryFields[i]]) {
        $(`#${mandatoryFields[i]}`).addClass("validation");

        errors++;
      } else {
        $(`#${mandatoryFields[i]}`).removeClass("validation");
      }

      if (
        mandatoryFields[i] === "phone" &&
        !phoneRegex.test(this.state[mandatoryFields[i]])
      ) {
        $(`#${mandatoryFields[i]}`).addClass("validation");
        errors++;
      }

      if (
        mandatoryFields[i] === "email" &&
        !emailRegex.test(this.state[mandatoryFields[i]])
      ) {
        $(`#${mandatoryFields[i]}`).addClass("validation");
        errors++;
      }
    }

    return errors;
  }

  async handleSubmit(event) {
    event.preventDefault();
    let errors = this.validateForm();
    if (!errors) {
      const request = {
        name: this.state.name,
        company: this.state.company,
        title: this.state.title,
        email: this.state.email,
        phone: this.state.phone,
        location: this.state.location || "---",
        staylength: this.state.staylength || "---",
        rmc: this.state.rmc ? "Yes" : "No",
        ifrmcyes: this.state.ifrmcyes || "---",
        yearlymoves: this.state.yearlymoves || "---",
        housingspends: this.state.housingspends || "---",
        housingsneeds: this.state.housingsneeds || "---",
        intern: this.state.intern ? "Yes" : "No",
        relocation: this.state.relocation ? "Yes" : "No",
        travel: this.state.travel ? "Yes" : "No",
        group: this.state.group ? "Yes" : "No",
        other: this.state.other ? "Yes" : "No",
        include: this.state.include ? "Yes" : "No",
        toEmail: this.managerInfo.email,
      };
      this.setState({ isLoading: true });
      let response = await corporateHousingManagementService.generalPricing(
        request
      );

      if (response.status) {
        this.setState({ isLoading: false });
        this.props.closePopupWithThankYou();
      }
    }
  }

  render() {
    return (
      <div className="form-container sa-forms-new">
        <h1 className="modal-title ">Get General Pricing</h1>
        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <input
              type="text"
              placeholder="Name*"
              name="name"
              id="name"
              className="form-control"
              value={this.state.name}
              onChange={this.handleChange}
            />
            <input
              type="text"
              placeholder="Company*"
              name="company"
              className="form-control"
              id="company"
              value={this.state.company}
              onChange={this.handleChange}
            />
            <input
              type="text"
              placeholder="Title"
              name="title"
              id="title"
              className="form-control"
              value={this.state.title}
              onChange={this.handleChange}
            />
            <input
              type="tel"
              placeholder="Phone*"
              name="phone"
              id="phone"
              className="form-control"
              value={this.state.phone}
              autoComplete="off"
              onChange={this.handleChange}
            />

            <input
              type="email"
              placeholder="Email*"
              name="email"
              id="email"
              className="form-control"
              value={this.state.email}
              onChange={this.handleChange}
            />
          </div>

          <div className="form-group">
            <div className="location">
              <label>Locations Needs:</label>
              <div className="list-items">
                <textarea
                  name="location"
                  className="width100per py-2 px-2"
                  placeholder="Cities, states or countries you're interested in "
                  value={this.state.location}
                  onChange={this.handleChange}
                  autoComplete="off"
                />{" "}
              </div>
            </div>
            <div className="staylength">
              <label>Length of stay</label>
              <div className="list-items">
                <textarea
                  name="staylength"
                  className="width100per py-2 px-2"
                  placeholder="How many days typically?"
                  value={this.state.staylength}
                  onChange={this.handleChange}
                />{" "}
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="rmc">
              <p>Are you using an RMC?</p>
              <div className="list-items">
                <div className="d-flex">
                  <label>
                    <input
                      type="radio"
                      name="rmc"
                      value="yes"
                      checked={this.state.rmc === "yes"}
                      onChange={this.handleRmcChange}
                    />{" "}
                    Yes
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="rmc"
                      value="No"
                      checked={this.state.rmc === "No"}
                      onChange={this.handleRmcChange}
                    />{" "}
                    No
                  </label>
                </div>
                <input
                  type="text"
                  name="ifrmcyes"
                  placeholder="If yes, which RMC"
                  value={this.state.ifrmcyes}
                  checked={this.state.ifrmcyes}
                  onChange={this.handleChange}
                />{" "}
              </div>
            </div>
            <div className="volume">
              <label>Volume</label>
              <div className="list-items">
                <input
                  type="text"
                  name="yearlymoves"
                  placeholder="Average yearly moves"
                  value={this.state.yearlymoves}
                  onChange={this.handleChange}
                />{" "}
                <input
                  type="text"
                  name="housingspends"
                  placeholder="Average yearly corporate housing spend"
                  value={this.state.housingspends}
                  onChange={this.handleChange}
                />{" "}
                <input
                  type="text"
                  name="housingsneeds"
                  placeholder="Average annual intern housing needs"
                  value={this.state.housingsneeds}
                  onChange={this.handleChange}
                />{" "}
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="volume">
              <label>Program interest</label>

              <label className="sub-label d-block"> check all that apply</label>
              <div className="list-items">
                <label>
                  <input
                    type="checkbox"
                    name="intern"
                    value="yes"
                    onChange={this.handleProgram}
                  />
                  Intern
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="relocation"
                    value="yes"
                    onChange={this.handleProgram}
                  />
                  Relocation
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="travel"
                    value="yes"
                    onChange={this.handleProgram}
                  />
                  Travel
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="group"
                    value="yes"
                    onChange={this.handleProgram}
                  />
                  Group
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="other"
                    value="yes"
                    onChange={this.handleProgram}
                  />
                  Other
                </label>
              </div>
            </div>
          </div>

          <div className="text-left">
            <label>Anything else to include ?</label>
            <textarea
              placeholder="Are there any particular requests I can accommodate ?"
              name="include"
              className="py-3 px-3 width100per"
              value={this.state.include}
              onChange={this.handleChange}
            ></textarea>
          </div>

          {this.state.isLoading ? (
            <Spinner />
          ) : (
            <button type="submit" className="btn">
              Submit Request
            </button>
          )}
        </form>
      </div>
    );
  }
}

class ThankYouModal extends React.Component {
  constructor(props) {
    super(props);
    this.managerInfo = this.props.managerInfo;
  }

  render() {
    return (
      <div className="text-left thanksModalView">
        <h2>Thank You!</h2>
        <p>We've received your request.</p>
        <br />
        <p>
          We are looking forward to working with you in the near future! Talk to
          you soon.
        </p>

        <p className="mt-5">
          {this.managerInfo.fullName}, CCHP <br />{" "}
          {this.managerInfo.designation} <br /> {this.managerInfo.email}
        </p>
      </div>
    );
  }
}

class FunfactsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "industry",
    };
    this.managerInfo = this.props.managerInfo;
    this.changeActiveTab = this.changeActiveTab.bind(this);
  }

  changeActiveTab(event) {
    this.setState({
      activeTab: event.target.id,
    });
  }

  render() {
    return (
      <div className="profile sa-forms-new">
       

        <div className="content row">
          <div className="col-md-4">
            <img src={this.managerInfo.popUpImageUrl} alt="Person Image" />
          </div>
          <div className="col-md-8">
          <h1 className="text-left">{this.managerInfo.fullName}</h1>
        <p className="text-left">{this.managerInfo.designation}</p>
            <div className="tabs" onClick={this.changeActiveTab}>
              <button
                className={`tab-button ${
                  this.state.activeTab === "industry" ? "active" : ""
                }`}
                id="industry"
              >
                Industry
              </button>
              <button
                className={`tab-button ${
                  this.state.activeTab === "personal" ? "active" : ""
                }`}
                id="personal"
              >
                Personal
              </button>
            </div>
            {this.state.activeTab === "personal" ? (
              <div className="tab-content" id="personal-tab">
                <table>
                  <tbody>
                    {this.managerInfo.funFacts.personal.map((fact, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            {" "}
                            <b> {fact[0]} </b>{" "}
                          </td>
                          <td>{fact[1]}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="tab-content" id="industry-tab">
                <table>
                  <tbody>
                    {this.managerInfo.funFacts.industry.map((fact, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            {" "}
                            <b> {fact[0]} </b>{" "}
                          </td>
                          {index === 0 ? (
                            <td className="spl">{fact[1]}</td>
                          ) : (
                            <td>{fact[1]}</td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export { BaseModal, PricingModal, FunfactsModal, ThankYouModal };
