const UniqueExperiences = (props) => {

    return ( 
        <div className="googleue banner">
    <p className="backbtn"  onClick={()=>props.pageChanges(0)}>Back</p>
        <div className="container">
           <h1><span>SUITEAMERICA </span> UNIQUE EXPERIENCE</h1>
           
           <div className="d-flex justify-content-center mrg-top-40">
              <div className="col-md-10">
                 <div className="row">
                    <div className="col-md-3 col-xs-3">
                       <img src={s3BucketUrl+"landing-images/Google/svg/users.svg"}/>
                       <p>Leading Industry <br/> Experts</p>
                    </div>
                    <div className="col-md-3 col-xs-3">
                       <img src={s3BucketUrl+"landing-images/Google/svg/computer.svg"}/>
                       <p>Local Market <br/> Insight</p>
                    </div>
                    <div className="col-md-3 col-xs-3">
                       <img src={s3BucketUrl+"landing-images/Google/svg/handshake.svg"}/>
                       <p>Cultivating the <br/> Art of Welcome</p>
                    </div>
                    <div className="col-md-3 col-xs-3 ">
                       <img src={s3BucketUrl+"landing-images/Google/svg/destination.svg"}/>
                       <p>Directors of <br/> Guest Journey</p>
                    </div>
                 </div>
              </div>
           </div>
           <div style={{height: "20px"}}></div>
           
           <h2>Find Your Spot!</h2>
           <div className="row">
              <div className="col-md-3">
                 <div className="bannerinner">
                    <img src={s3BucketUrl+"landing-images/Google/uepage2.png"} />
                    <h1>MOUNTAIN VIEW</h1>
                    <p>CALIFORNIA</p>
                 </div>
              </div>
              <div className="col-md-3">
                 <div className="bannerinner">
                    <img src={s3BucketUrl+"landing-images/Google/uepage3.png"} />
                    <h1>SAN JOSE </h1>
                    <p>CALIFORNIA</p>
                 </div>
              </div>
              <div className="col-md-3">
                 <div className="bannerinner">
                    <img src={s3BucketUrl+"landing-images/Google/uepage4.png"} />
                    <h1>SEATTLE</h1>
                    <p>WASHINGTON</p>
                 </div>
              </div>
              <div className="col-md-3">
                 <div className="bannerinner">
                    <img src={s3BucketUrl+"landing-images/Google/uepage5.png"} />
                    <h1>RESTON</h1>
                    <p>VIRGINIA</p>
                 </div>
              </div>
           </div>
        </div>
     </div>
    );

}
export default UniqueExperiences;
