import GuestHeader from "../../common/guestHeader";
import LocationService from "../../../services/locationService";
import AppController from "../../controller/appController";
import MapController from "../../controller/mapController";
import SmoothScrolling from "./smoothScrolling";
import FloorPlan from "./floorPlans";
import NeighBoorHood from "./neighborHood";
import Amenities from "./amenities";
import PhotoGallery from "./photoGallery";
import Home from "./home";
import FloorModal from "./floorModal";
import PhotoModal from "./photoModal";
import CommunityList from "./communitiesList";
import ModalVideo from "./modalVideo";
import AmenitiesModal from "./amenitiesModal";
import Footer from "../../common/footer";
import SubFooter from "../../common/subFooter";

class DeanHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      communityInfo: CommunityList[this.props.match.path],
      communityRequest: {
        communityName: CommunityList[this.props.match.path].Community,
        imageType: "banner"
      },
      communityResponse: {},
      greatSchoolsResponse: {},
      walkScoreResponse: [],
      loader: false,
      communityResponseloader: false,
      poi: [],
      communityName: '',
      latitude: 0.0,
      longitude: 0.0,
      width: 0,
      communityDetails: false,
      floorModal: false,
      photomodal: false,
      floorObject: {},
      dataId: 0,
      floor3d: false,
      floor3dData: {},
      amenitiesModal: false,
      amenitesImage: '',
      defaultAmenities: ['Fitness Center', 'Dog Park', 'Spa / Hot Tub', 'Swimming Pool', 'Business Center', 'Electric Car Charging Stations', 'Picnic area', 'Game Room']
    };

    this.linkAddress = this.linkAddress.bind(this);
    this.amenities = this.amenities.bind(this);
    this.scrollUp = this.scrollUp.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.floorCorousel = this.floorCorousel.bind(this);
    this.gallaeryCorousel = this.gallaeryCorousel.bind(this);
    this.floorImages = this.floorImages.bind(this);
    this.headerChange = this.headerChange.bind(this);
    this.follorIn3d = this.follorIn3d.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.amentiesModalPopup = this.amentiesModalPopup.bind(this);
  }

  async communityInfo() {
    const requestObj = this.state.communityRequest;
    const responseObj = await LocationService.getCommunityInfoByName(
      requestObj
    );

    this.setState({
      communityResponse: responseObj,
      communityResponseloader: true
    });
    Object.keys(this.state.communityResponse).length > 0
      ? this.thirdPartyApis(this.state.communityResponse)
      : "";
    Object.keys(this.state.communityResponse).length > 0
      ? this.map(this.state.communityResponse)
      : "";
  }
  async thirdPartyApis(communityData) {
    const greatSchoolsRes = await AppController.greatSchools(communityData);
    const walkScoreRes = await AppController.wallsckoreFun(communityData);
    this.weather(communityData);
    this.poi(communityData)
    if (
      walkScoreRes != undefined &&
      walkScoreRes != null &&
      walkScoreRes != 0
    ) {
      this.setState({
        loader: true,
        greatSchoolsResponse: greatSchoolsRes,
        walkScoreResponse: [
          {
            Score:
              walkScoreRes.walkscore == undefined
                ? null
                : walkScoreRes.walkscore,
            description:
              walkScoreRes.description == undefined
                ? null
                : walkScoreRes.description,
            name: "WalkScore",
            chartName: "chart1" + communityData.community_id,
            colour: "#fab400",
            image: "WalkScore.png"
          },
          {
            Score:
              walkScoreRes.bike == undefined ? null : walkScoreRes.bike.score,
            description:
              walkScoreRes.bike == undefined
                ? null
                : walkScoreRes.bike.description,
            name: "BikeScore",
            chartName: "chart3" + communityData.community_id,
            colour: "#008000",
            image: "BikeScore.png"
          }
        ]
      });
      const walkScoreDetails = this.state.walkScoreResponse;
      for (var i = 0; i < walkScoreDetails.length; i++) {
        AppController.doughnutChart(walkScoreDetails[i]);
      }
    } else {
      this.setState({
        loader: true,
        greatSchoolsResponse: greatSchoolsRes,
        walkScoreResponse: []
      });
    }
  }
  async map(communityData) {
    var locations = {
      lat: Number(communityData.latitude),
      lng: Number(communityData.longitude)
    };
    if ($("#map" + communityData.community_id).length) {
      const customMap = new google.maps.Map(document.getElementById("map" + communityData.community_id), {
        zoom: 7,
        center: locations,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      });
      await this.setState({ customMap });
      MapController.customMarkers(
        communityData,
        "singlemark",
        this,
        "",
        "https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/location_pin_2.svg",
        customMap
      );
    }
  }
  async componentWillMount() {
    AppController.removeLocalStorageData();
    $('html, body').animate({ scrollTop: 0 });
    AppController.stickyTitles();
    await this.communityInfo();
    await AppController.floorThumbs(this.state.communityResponse.community_id);
  }

  amenities(amenities, type) {
    console.log(amenities, 'amenities12')

    const { defaultAmenities } = this.state
    console.log(defaultAmenities, 'defaultAmenities12')
    const url = this.props.match.path;
    var amenitiesdata = JSON.parse(amenities);
    let filteredAmenites =
      amenitiesdata != null && amenitiesdata != undefined
      && _.filter(amenitiesdata, function (o) {
        return o.communityname == type;
      })

    return (
      <ul>
        {filteredAmenites.map((row, index) => {
          _.find(defaultAmenities, function (o) {
            return o == row.aparment_amenity_name && url == '/thedean';
          });
          return <li key={index}>{row.aparment_amenity_name}</li>;
        })}
      </ul>
    );
  }
  scrollUp(id) {
    SmoothScrolling.scrollTo(id);
  }
  async weather(apidata) {
    const weather = await AppController.weather(apidata)

    var results = weather.list.map(function (el) {
      var o = Object.assign({}, el);
      o.date = o.dt_txt.split(" ")[0];
      return o;
    })
    var data1 = _.chain(results)
      .groupBy("date")
      .map(function (currentItem) {
        let minTEMP = _.minBy(currentItem, function (o) {
          return o.main.temp_min;
        });
        let maxTEMP = _.maxBy(currentItem, function (o) {
          return o.main.temp_max;
        });
        return {
          weatherData: currentItem[0],
          weatherDataMax: maxTEMP.main.temp_max,
          weatherDataMin: minTEMP.main.temp_min
        }
      }).value();
    this.setState({
      weather: data1.splice(1, 4),
      weather_current: data1[0].weatherData,
      weather_currentCityDetails: weather.city,
    })
  }
  async poi(apidata) {
    const data = {
      city: apidata.city_name
    }
    const poi = await AppController.poi(data)

    this.setState({ poi: poi.results })
  }

  handleFormSubmit() {
    console.log(';;')
  }
  floorCorousel(data) {
    AppController.deanThumbs(data);

  }
  async gallaeryCorousel(id) {
    await localStorage.setItem('item', id)
    await this.setState({ photomodal: true })
    await setTimeout(async () => {
      await AppController.deanThumbs(15);
    }, 500);
  }
  floorImages(row) {
    this.setState({ floorModal: true, floorObject: row })
  }
  async headerChange(id) {
    if (id == 1) {
      this.floorCorousel(0)
    }
    //id==4?this.gallaeryCorousel:''
    await this.setState({ dataId: id })
  }
  follorIn3d(row) {
    this.setState({ floor3d: true, floor3dData: row })
  }
  closeModal() {
    $('#sync1' + 15).removeAttr('id');
    $('#sync2' + 15).removeAttr('id');
    this.setState({ floor3d: false, floor3dData: {}, photomodal: false })
  }

  amentiesModalPopup(image) {
    this.setState({ amenitiesModal: true, amenitesImage: image })
  }
  linkAddress(link) {
    localStorage.setItem('amenitieslink', link)
  }

  render() {
    console.log('this.state.communityInfo', this.state.communityInfo)

    return (
      <div>
        <GuestHeader />


        <div className="deanpage">
          <div className="deanpage_banner">
            <div className="deanbannerimg">
              <img src={this.state.communityInfo.BanerImage} />
            </div>
            <div className="banner_text">
              <h2> SuiteAmerica  <span> <b> | </b>{this.state.communityInfo.Name} </span></h2>
              <div className="text_bg">
                <h1 className="ribbon">
                  <strong className="ribbon-content">
                    {this.state.communityInfo.BanerName}
                  </strong>
                </h1>
                <div className="threeborders" />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="deanbeerbottom">
              <h1>
                {this.state.communityInfo.HeadLine}
              </h1>

              {this.state.dataId == 0 ?
                <div>
                  <p dangerouslySetInnerHTML={{ __html: this.state.communityInfo.DEFAULT.Paragraph }} >
                  </p>
                  {this.state.communityInfo.Name == 'THE DEAN' && <div className="deasncenterimges">
                    <a className="item cursor-pointer" href="/dean-amenities-deleted" target="_blank" onClick={() => this.linkAddress('myDiv')} >
                      <div className="img">
                        <img src="/assets/newUI-images/dean/dean-fitness.png" />
                      </div>
                      <h1></h1>
                    </a>
                    <a className="item cursor-pointer" href="/dean-amenities-deleted" target="_blank" onClick={() => this.linkAddress('myDiv2')}>
                      <div className="img">
                        <img src="/assets/newUI-images/dean/dean-pool.png" />
                      </div>
                      <h1></h1>
                    </a>
                  </div>}



                </div>
                : ''}
              {this.state.dataId == 1 ? <p>
                {this.state.communityInfo.FLOORPLANS.Paragraph}
              </p> : ''}
              {this.state.dataId == 2 ? <p>
                {this.state.communityInfo.NEIGHBORHOOD.Paragraph}
              </p> : ''}
              {this.state.dataId == 3 ? <p>
                {this.state.communityInfo.AMENITIES.Paragraph}
              </p> : ''}
              {this.state.dataId == 4 ? <p>
                {this.state.communityInfo.PHOTOGALLERY.Paragraph}
              </p> : ''}


              {this.state.communityResponse == null || this.state.communityResponse == undefined ?
                <div class="error-sms-Quote mrg-top-40">
                  <span class="alert alert-danger">
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                    Data Not Available
                  </span>
                </div>
                :
                <div className="responsive-tabs deanresponsive-tabs">
                  <ul className="nav nav-tabs deantabss" role="tablist">
                    <li role="presentation" className="">
                      <a href="#tab1" aria-controls="tab1" role="tab" data-toggle="tab" onClick={() => this.headerChange(1)}> FLOORPLANS </a>
                    </li>
                    <li role="presentation" className="">
                      <a href="#tab2" aria-controls="tab2" role="tab" data-toggle="tab" onClick={() => this.headerChange(2)}> NEIGHBORHOOD </a>
                    </li>
                    <li role="presentation" className="">
                      <a href="#tab3" aria-controls="tab3" role="tab" data-toggle="tab" onClick={() => this.headerChange(3)}>AMENITIES </a>
                    </li>
                    <li role="presentation" className="">
                      <a href="#tab4" aria-controls="tab4" role="tab" data-toggle="tab" onClick={() => this.headerChange(4)}> PHOTO GALLERY </a>
                    </li>
                  </ul>
                  <div id="tabs-content" className="tab-content panel-group">
                    <div className="panel-heading" role="tab" id="heading1">
                      <a href="#tab1" className="collapsed" role="button" data-toggle="collapse" data-parent="tabs-content" aria-expanded="true"
                        aria-controls="tab1" onClick={() => this.headerChange(1)}>FLOORPLANS</a>
                    </div>
                    <div id="tab1" role="tabpanel" className="tab-pane animated rollIn  panel-collapse collapse " aria-labelledby="heading1" >
                      <FloorPlan {...this.state} floorCorousel={this.floorCorousel} floorImages={this.floorImages} gallaeryCorousel={this.gallaeryCorousel}
                        follorIn3d={this.follorIn3d}
                      />
                    </div>
                    <div className="panel-heading" role="tab" id="heading2">
                      <a href="#tab2" className="collapsed" role="button" data-toggle="collapse" data-parent="tabs-content"
                        aria-expanded="true" aria-controls="tab2" onClick={() => this.headerChange(2)}>NEIGHBORHOOD</a>
                    </div>
                    <div id="tab2" role="tabpanel" className="tab-pane animated bounceInUp panel-collapse collapse" aria-labelledby="heading2">
                      <NeighBoorHood {...this.state} handleFormSubmit={this.handleFormSubmit} />
                    </div>
                    <div className="panel-heading" role="tab" id="heading3">
                      <a href="#tab3" className="collapsed" role="button" data-toggle="collapse" data-parent="tabs-content"
                        aria-expanded="true" aria-controls="tab3" onClick={() => this.headerChange(3)}>AMENITIES</a>
                    </div>
                    <div id="tab3" role="tabpanel" className="tab-pane animated  zoomIn panel-collapse collapse" aria-labelledby="heading3">
                      <Amenities {...this.state} amenities={this.amenities} amentiesModalPopup={this.amentiesModalPopup} />
                    </div>
                    <div className="panel-heading" role="tab" id="heading4">
                      <a href="#tab4" className="collapsed" role="button" data-toggle="collapse" data-parent="tabs-content"
                        aria-expanded="true" aria-controls="tab4" onClick={() => this.headerChange(4)}>PHOTO GALLERY</a>
                    </div>
                    <div id="tab4" role="tabpanel" className="tab-pane animated rotateIn panel-collapse collapse" aria-labelledby="heading4">
                      <PhotoGallery {...this.state} gallaeryCorousel={this.gallaeryCorousel} />
                    </div>
                    {/* <div role="tab" id="heading4">
                      <a href="#tab5" className="collapsed" role="button" data-toggle="collapse" data-parent="tabs-content"
                        aria-expanded="true" aria-controls="tab4" ></a>
                    </div>
                    <div id="tab5" role="tabpanel" className="tab-pane animated bounceInUp  active panel-collapse collapse in" aria-labelledby="heading1">
                      <Home {...this.state} />
                    </div> */}

                  </div>
                </div>
              }



            </div>

            {this.state.communityResponse == null || this.state.communityResponse == undefined ? "" :
              <>
                {this.state.floorModal ? <FloorModal floorObject={this.state.floorObject} /> : ''}
                {this.state.floor3d ? <ModalVideo {...this.state} closeModal={this.closeModal} /> : ''}
                {this.state.photomodal ?
                  <PhotoModal closeModal={this.closeModal}
                    CommunityImages={JSON.parse(this.state.communityResponse.CommunityImages)}
                  />
                  : ''}
                {this.state.amenitiesModal ? <AmenitiesModal {...this.state} /> : ''}
              </>

            }

          </div>
        </div>

        <SubFooter />
        <Footer />
      </div>
    );
  }
}
export default DeanHome;
