
import React, { Component } from 'react'
import Footer from '../../common/footer'
import GuestHeader from '../../common/guestHeader'
import SubFooter from '../../common/subFooter'
import GuestServicesapi from '../../../services/guestServicesapi';
import DatePicker from 'react-datepicker';
import moment from 'moment';


export class tripInfoData extends Component {
    constructor(props) {
        super(props)
        this.state = {
            menuShow: false,
            sameDayFlyAndMoveStatus: '',
            arrivalAirport: '',
            airlineName: '',
            flightno: '',
            extraInfo: '',
            errorMessage: '',
            userData: '',
            OrderUId: '',
            uniqueId: '',
            tableId: '',
            planeSchedule: null,
            arrivalExpectation: null,
            standardCheckInTime: null,
            expectToReceiveItinerary: null,
            sliderValue: 0,
            alreadyBeInTheArea: '',
            scheduleFlexible: '',
            extraInfoByRoad: '',
            extraInfoByDontKnowYet: '',
            // tripInfo:{}
        }
        this.planeSchedule = this.planeSchedule.bind(this);
        this.arrivalExpectation = this.arrivalExpectation.bind(this);
        this.onSliderChange = this.onSliderChange.bind(this);
        this.standardCheckInTime = this.standardCheckInTime.bind(this);
        this.expectToReceiveItinerary = this.expectToReceiveItinerary.bind(this);
        this.extraInfoByRoad = this.extraInfoByRoad.bind(this);
        this.extraInfoByDontKnowYet = this.extraInfoByDontKnowYet.bind(this);
    }

    async planeSchedule(date) {
        if (date !== null) {
            await this.setState({ planeSchedule: moment(date) });
        }
    }

    async arrivalExpectation(date) {
        if (date !== null) {
            await this.setState({ arrivalExpectation: moment(date) });
        }
    }

    async standardCheckInTime(date) {
        if (date !== null) {
            await this.setState({ standardCheckInTime: moment(date) });
        }
    }

    async expectToReceiveItinerary(date) {
        if (date !== null) {
            await this.setState({ expectToReceiveItinerary: moment(date) });
        }
    }

    /**
     * Function to submit by Dont know Yet
     **/
    async dontKnowYet() {
        var currentDate = ""
        await this.getCurrentDateTime().then((response) => {
            currentDate = response
        })
        var expectToReceiveItineraryDate = moment(this.state.expectToReceiveItinerary).format("MM/DD/YYYY HH:mm")
        let error = 0;
        if (this.state.expectToReceiveItinerary == null) {
            error++
        } 
        if (this.state.extraInfoByDontKnowYet == '') {
            error++
        } 
        if (error !== 0) {
            this.setState({ errorMessage: 'Please enter all fields.' })
        } else {
            if (expectToReceiveItineraryDate < currentDate) {
                this.setState({ errorMessage: 'Please select valid date and time' })
            } else {
                let ordersid = {
                    orderid: this.props.getorderid,
                }
                const userData = await GuestServicesapi.GetOrderInformation(ordersid)
                this.setState({ userData: userData, errorMessage: '', OrderUId: userData.orderUId });
                var dontKnowYetObj = {
                    "OrderUId": this.state.OrderUId,
                    "TravelBy": "Dont Know Yet",
                    "Notes": "test",
                    "ChangedBy": "",
                    "ExpectedDate": expectToReceiveItineraryDate + ':00.000'
                }
                const SaveOrderTripDetailsResponse = await GuestServicesapi.SaveOrderTripDetails(dontKnowYetObj)
                if (SaveOrderTripDetailsResponse !== null) {
                    if (SaveOrderTripDetailsResponse.hasOwnProperty('message')) {
                        await this.setState({ expectToReceiveItinerary: null })
                        this.extraInfoByDontKnowYetRef.value = "";
                        location.href = '/guest-order-data'
                    } else {
                        console.log("Failed to load trip details");
                    }
                }
            }
        }
    }

    /**
     * Function to submit by road
     **/
    async byRoad() {
        var currentDate = ""
        await this.getCurrentDateTime().then((response) => {
            currentDate = response
        })
        var standardCheckInDateTime = moment(this.state.standardCheckInTime).format("MM/DD/YYYY HH:mm")
        const _this = this
        const validationFields = [
            'alreadyBeInTheArea',
            'sliderValue',
            'scheduleFlexible',
            'extraInfoByRoad'];
        let error = 0;
        _.forEach(validationFields, function(value) {
            if(_this.state[value] == ""){
              error++;
            }
        });
        if (this.state.standardCheckInTime == null) {
            error++
        }
        if (error !== 0) {
            this.setState({ errorMessage: 'Please enter all fields.' })
        } else {
            if (standardCheckInDateTime < currentDate) {
                this.setState({ errorMessage: 'Please select valid date and time' })
            } else {
                let ordersid = {
                    orderid: this.props.getorderid,
                }
                const userData = await GuestServicesapi.GetOrderInformation(ordersid)
                this.setState({ userData: userData, errorMessage: '', OrderUId: userData.orderUId });
                var roadObj = {
                    "OrderUId": this.state.OrderUId,
                    "TravelBy": "By Road",
                    "Notes": "test",
                    "ChangedBy": "",
                    "DrivingMiles": this.state.sliderValue,
                    "ArrivalDate": standardCheckInDateTime + ':00.000'
                }
                const SaveOrderTripDetailsResponse = await GuestServicesapi.SaveOrderTripDetails(roadObj)
                if (SaveOrderTripDetailsResponse !== null) {
                    if (SaveOrderTripDetailsResponse.hasOwnProperty('message')) {
                        await this.setState({ alreadyBeInTheArea: '', sliderValue: 0, scheduleFlexible: '', extraInfoByRoad: '', standardCheckInTime: null, uniqueId: SaveOrderTripDetailsResponse.UniqueId, tableId: SaveOrderTripDetailsResponse.TableId })
                        this.sliderValueRef.value = "";
                        this.extraInfoByRoadRef.value = "";
                        location.href = '/guest-order-data'
                    } else {
                        console.log("Failed to load trip details");
                    }
                }
            }
        }
    }

    /**
     * Function to get Current date and time
     */
    async getCurrentDateTime() {
        var date = moment();
        var curDate = moment(date).format("MM/DD/YYYY HH:mm")
        return curDate;
    }

    /**
     * Function to submit by plane
     **/
    async byAir() {
        var currentDate = ""
        await this.getCurrentDateTime().then((response) => {
            currentDate = response
        })
        var planeScheduleDate = moment(this.state.planeSchedule).format("MM/DD/YYYY HH:mm")
        var arrivalExpectationDate = moment(this.state.arrivalExpectation).format("MM/DD/YYYY HH:mm")
        const _this = this
        const validationFields = [
            'sameDayFlyAndMoveStatus',
            'arrivalAirport',
            'airlineName',
            'flightno',
            'extraInfo'];
        let error = 0;
        _.forEach(validationFields, function(value) {
            if(_this.state[value] == ""){
              error++;
            }
        });
        if (this.state.planeSchedule == null) {
            error++
        }
        if (this.state.arrivalExpectation == null) {
            error++
        }
        if (error !== 0) {
            this.setState({ errorMessage: 'Please enter all fields.' })
        } else {
            if (planeScheduleDate < currentDate || arrivalExpectationDate < currentDate) {
                this.setState({ errorMessage: 'Please select valid date and time' })
            } else {
                let ordersid = {
                    orderid: this.props.getorderid,
                }
                const userData = await GuestServicesapi.GetOrderInformation(ordersid)
                this.setState({ userData: userData, errorMessage: '', OrderUId: userData.orderUId });
                var planeObj = {
                    "OrderUId": this.state.OrderUId,
                    "TravelBy": "By Air",
                    "Notes": "test",
                    "ChangedBy": "",
                    "InTown": "",
                    "Hoteltransfers": "",
                    "FlyingSameDay": this.state.sameDayFlyAndMoveStatus,
                    "FlightArrival": planeScheduleDate + ':00.000',
                    "Airline": this.state.airlineName,
                    "ArrivalAirport": this.state.arrivalAirport,
                    "Flight": this.state.flightno,
                    "ArrivalDate": arrivalExpectationDate + ':00.000'
                }
                const SaveOrderTripDetailsResponse = await GuestServicesapi.SaveOrderTripDetails(planeObj)
                if (SaveOrderTripDetailsResponse !== null) {
                    if (SaveOrderTripDetailsResponse.hasOwnProperty('message')) {
                        await this.setState({ planeSchedule: null, arrivalExpectation: null, sameDayFlyAndMoveStatus: '', uniqueId: SaveOrderTripDetailsResponse.UniqueId, tableId: SaveOrderTripDetailsResponse.TableId })
                        this.airlineNameRef.value = "";
                        this.arrivalAirportRef.value = "";
                        this.flightnoRef.value = "";
                        this.extraInfoRef.value = ""
                        location.href = '/guest-order-data'
                    } else {
                        console.log("Failed to load trip details");
                    }
                }
            }
        }
    }

    onSliderChange(e) {
        this.setState({ sliderValue: e.target.value });
    }

    sameDayFlyAndMoveStatus(e) {
        this.setState({ sameDayFlyAndMoveStatus: e.target.value })
    }

    alreadyBeInTheArea(e) {
        this.setState({ alreadyBeInTheArea: e.target.value })
    }

    scheduleFlexible(e) {
        this.setState({ scheduleFlexible: e.target.value })
    }

    setValue(e) {
        var state = this.state;
        state[e.target.name] = e.target.value;
        this.setState({ state })
    }

    extraInfoByRoad(e) {
        this.setState({ extraInfoByRoad: e.target.value })
    }

    extraInfoByDontKnowYet(e) {
        this.setState({ extraInfoByDontKnowYet: e.target.value })
    }

    render() {
        return (
            <div className="service_request">
                {this.props.activeTabTripInfoIsNull !== true ?
                    <GuestHeader />
                    : null}
                <div>

                   
                    <div className="guest-portalnewpage">
                        <div className="onelaststep">
                            <div className="container">
                                <h1>ONE LAST STEP...</h1>
                                <h2>Thank you for choosing to stay with us. <br />We look forward to having you in our suite. Let's confirm your
              arrival information so we can finalize your stay.</h2>
                                <p className="alret-red">
                                    Looks like you still need to confirm your arrival details. This will help us provide a warm welcome to
                                    your suite. Please verify below so that we can ensure a stress-free relocation.
              <span className="d-block">Need assistance?</span>
                                    <span className="d-block">Give us a call at 800.367.9501 or email us at guestserve@suiteamerica.com.</span>
                                </p>
                                <h3>I will be arriving by:</h3>
                                <div className="tabs">
                                    <ul className="nav nav-tabs">
                                        <li className="active" onClick={() => this.setState({ errorMessage: '' })}><a data-toggle="tab" href="#menu1">
                                            <img src="/assets/images/guestPortalArrival/aeroplane.png" alt="" />
                                            <span>PLANE</span>
                                        </a></li>
                                        <li onClick={() => this.setState({ errorMessage: '' })}><a data-toggle="tab" href="#menu2"> <img src="/assets/images/guestPortalArrival/sedan-car-front.png" alt="" />
                                            <span>CAR</span> </a></li>
                                        <li onClick={() => this.setState({ errorMessage: '' })}><a data-toggle="tab" href="#menu3"> <img src="/assets/images/guestPortalArrival/question-sign.png" alt="" />
                                            <span>I DON'T KNOW YET</span> </a></li>
                                    </ul>
                                    <div className="tab-content">
                                        <div id="menu1" className="tab-pane fade in active">
                                            <form name="qryform" id="qryform" method="post" action="mail.php" onsubmit="return(validate());" noValidate="novalidate">
                                                <div className="form-group">
                                                    <label> Are you flying in the same day that you will be moving into your apartment? </label>
                                                    <div >
                                                        <label className="radio-inline">
                                                            <input type="radio" name="optradio" checked={this.state.sameDayFlyAndMoveStatus === 'true'} value="true" onChange={(e) => this.sameDayFlyAndMoveStatus(e)} />Yes
                        </label>
                                                        <label className="radio-inline">
                                                            <input type="radio" name="optradio" checked={this.state.sameDayFlyAndMoveStatus === 'false'} value="false" onChange={(e) => this.sameDayFlyAndMoveStatus(e)} />No
                        </label>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label> Arrival Airport </label>
                                                    <input type="text" className="form-control" ref={(ref) => this.arrivalAirportRef = ref} name="arrivalAirport" placeholder="i.e. Chicagio International" onChange={this.setValue.bind(this)} />
                                                </div>
                                                <div className="form-group">
                                                    <label> What airline will you be flying in on? </label>
                                                    <input type="text" ref={(ref) => this.airlineNameRef = ref}
                                                        className="form-control" name="airlineName" placeholder="i.e. Alaska Airlines" onChange={this.setValue.bind(this)} />
                                                </div>
                                                <div className="form-group">
                                                    <label> What is your flight number? </label>
                                                    <input type="text" name="flightno" ref={(ref) => this.flightnoRef = ref} className="form-control" placeholder="i.e. GFM104" onChange={this.setValue.bind(this)} />
                                                </div>
                                                <div className="form-group">
                                                    <label> What time is your plane scheduled to land? </label>
                                                    <div className="">
                                                        <DatePicker type="text" className="form-control" ref={(ref) => this.planeScheduleRef = ref} placeholderText="MM/DD/YYYY" minDate={new Date()} selected={this.state.planeSchedule} name="planeSchedule" onChange={this.planeSchedule} dateFormat="MM/DD/YYYY HH:mm" showTimeSelect timeIntervals={15} />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label> What time do you expect you will arrive at your community? Typical arrival times to the community range between 1-2 hours after your flight lands, depending on traffic. <b>Standard check in time is between 3p-10p.</b></label>
                                                    <div className="">
                                                        <DatePicker type="text" className="form-control" placeholderText="MM/DD/YYYY" minDate={new Date()} selected={this.state.arrivalExpectation} name="arrivalExpectation" onChange={this.arrivalExpectation} dateFormat="MM/DD/YYYY HH:mm" showTimeSelect timeIntervals={15} />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label>Any extra information we should know?</label>
                                                    <textarea name="extraInfo" ref={(ref) => this.extraInfoRef = ref} className="form-control" rows={6} placeholder="Being type here..." defaultValue={""} onChange={this.setValue.bind(this)} />
                                                </div>
                                                {this.state.errorMessage !== '' ? <p style={{ color: "red" }}>{this.state.errorMessage}</p> : null}
                                                <div className="view-buttons">
                                                    <button type="button" className="btn" onClick={() => this.byAir()}>Submit</button>
                                                </div>
                                            </form>
                                        </div>
                                        <div id="menu2" className="tab-pane fade">
                                            <form name="qryform" id="qryform" method="post" action="mail.php" onsubmit="return(validate());" noValidate="novalidate">
                                                <div className="form-group">
                                                    <label> Will you already be in the area?</label>
                                                    <div>
                                                        <label className="radio-inline">
                                                            <input type="radio" name="optradio1" checked={this.state.alreadyBeInTheArea === 'true'} value="true" onChange={(e) => this.alreadyBeInTheArea(e)} />Yes
                        </label>
                                                        <label className="radio-inline">
                                                            <input type="radio" name="optradio1" checked={this.state.alreadyBeInTheArea === 'false'} value="false" onChange={(e) => this.alreadyBeInTheArea(e)} />No
                        </label>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label> How many miles from your community will you be approximately? </label>
                                                    <div className="progress">
                                                        <input
                                                            id="typeinp"
                                                            className=""
                                                            type="range"
                                                            ref={(ref) => this.sliderValueRef = ref}
                                                            min="0" max="200"
                                                            value={this.state.sliderValue}
                                                            onChange={this.onSliderChange}
                                                            step="1" />
                                                        <div className="progress-value"><span>{this.state.sliderValue}</span> <span>200+</span> </div>
                                                    </div>

                                                </div>
                                                <div className="form-group">
                                                    <label> Is your schedule flexible?</label>
                                                    <div >
                                                        <label className="radio-inline">
                                                            <input type="radio" name="optradio" value="true" checked={this.state.scheduleFlexible === 'true'} onChange={(e) => this.scheduleFlexible(e)} />Yes
                        </label>
                                                        <label className="radio-inline">
                                                            <input type="radio" name="optradio" value="false" checked={this.state.scheduleFlexible === 'false'} onChange={(e) => this.scheduleFlexible(e)} />No
                        </label>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label> <b>What time do you expect you will arrive at your community? Standard check in time is
                                                    between
                          3p-10p.</b> </label>
                                                    <div className="">
                                                        <DatePicker type="text" className="form-control" placeholderText="MM/DD/YYYY" minDate={new Date()} selected={this.state.standardCheckInTime} name="standardCheckInTime" onChange={this.standardCheckInTime} dateFormat="MM/DD/YYYY HH:mm" showTimeSelect timeIntervals={15} />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label>Any extra information we should know?</label>
                                                    <textarea name="issues" className="form-control" rows={6} placeholder="Being type here..." defaultValue={""} onChange={this.extraInfoByRoad} ref={(ref) => this.extraInfoByRoadRef = ref} />
                                                </div>
                                                {this.state.errorMessage !== '' ? <p style={{ color: "red" }}>{this.state.errorMessage}</p> : null}
                                                <div className="view-buttons">
                                                    <button type="button" className="btn " onClick={() => this.byRoad()}>Submit</button>
                                                </div>
                                            </form>
                                        </div>
                                        <div id="menu3" className="tab-pane fade">
                                            <form name="qryform" id="qryform" method="post" action="mail.php" onsubmit="return(validate());" noValidate="novalidate">
                                                <div className="form-group">
                                                    <label> We understand that you may not know your arrival information yet. <span className="d-block">When
                                                    do you expect to
                          receive your itinerary? We will check in with you again on that date*. </span> </label>
                                                    <div className="rjdate-picker">

                                                        <DatePicker type="text" className="form-control fa fa-calendar" placeholderText="MM/DD/YYYY" minDate={new Date()} selected={this.state.expectToReceiveItinerary} name="expectToReceiveItinerary" onChange={this.expectToReceiveItinerary} dateFormat="MM/DD/YYYY HH:mm" showTimeSelect timeIntervals={15} />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label><b className="clr-black">Standard check in time is between 3p-10p. 72 hour notice is required to
                                                    assure your arrival
                                                    time runs smoothly. Any questions? Give us a call at 800.367.9501 or email us at
                          guestserve@suiteamerica.com</b></label>
                                                    <label>Any extra information we should know?</label>
                                                    <textarea name="extraInfoByDontKnowYet" className="form-control" onChange={this.extraInfoByDontKnowYet} ref={(ref) => this.extraInfoByDontKnowYetRef = ref} rows={6} placeholder="Being type here..." defaultValue={""} />
                                                </div>
                                                {this.state.errorMessage !== '' ? <p style={{ color: "red" }}>{this.state.errorMessage}</p> : null}
                                                <div className="view-buttons">
                                                    <button type="button" className="btn " onClick={() => this.dontKnowYet()}>Submit</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.props.activeTabTripInfoIsNull !== true ?
                        <div>
                            <SubFooter />
                            <Footer />
                        </div>
                        : null}
                </div>
            </div>
        )
    }
}

export default tripInfoData