/* Importing the node modules, child components, services and controllers used
   inside GuestRegister component */
   import $ from "jquery";
   import AppController from "../controller/appController";
   import GuestHeader from '../common/guestHeader';
   import Footer from '../common/footer';
   import GuestServicesapi from '../../services/guestServicesapi';
   /* GuestRegister Component initialization */
   class GuestRegister extends React.Component {
       /* Initializing objects of its GuestRegister class */
       constructor(props) {
           super(props)
           /* GuestRegister Component State variables Initialization */
           this.state = {
               email: '',
               password: '',
               message: '',
               color: ''
           }
           this.setValue = this.setValue.bind(this);
           this.guestProspectiveRegister = this.guestProspectiveRegister.bind(this);
       }
       /* It is invoked immediately before mounting occurs */
       componentWillMount() {
           $('html, body').animate({scrollTop: 0});
           AppController.stickyTitles()
       }
       /* Update the state object of the form */
       setValue(field, e) {
           var object = {};
           object[field] = e.target.value;
           this.setState(object);
       }
       /* Authentication checking */
       async guestProspectiveRegister(e) {
           e.preventDefault();
           let regularExpression  = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{10,16}$/;
           let minNumberofChars = 10;
           let maxNumberofChars = 16;
           let newPassword=this.state.password;
   
           if (newPassword.length < minNumberofChars || newPassword.length > maxNumberofChars) {
               this.setState({message: 'Your password must be at least 10 characters', color: 'red'})
           } else if(regularExpression.test(newPassword)){
               this.setState({message: ''})
               const emailId = Base64.decode(this.props.match.params.email)
               const register = {
                   guestEmailId: emailId,
                   guestPwd: this.state.password,
                   guestIsActive: 1,
               }
               const userData = await GuestServicesapi.saveGuestUserInfo(register)
        
               if (userData) {
                   this.setState({message: 'Registered Successfully', color: 'green'})
                   location.href = '/locations';
               } else {
                   this.setState({message: 'Your Email Already exist', color: 'green'})
                   location.href = '/locations';
               }        
           }
           else{
               this.setState({message: 'Password should contain atleast one number and one special character', color: 'red'})
           }
       }
       render() {
           const email = Base64.decode(this.props.match.params.email);
   
           return (
               <div className="corporateHousing_Updates bg-grey">
               <div className="cover-tophd-ht"></div>
               <div className="container-fluid bg-grey">
                   <GuestHeader/>
                   <div className="container">
                   <div className="savesearch form-section col-sm-6 col-sm-offset-3 text-center">
                      <h2> Register Now For a SuiteAmerica Account as GuestProspective</h2>
                               <form className="contact-us pattern-bg wow animated fadeInRight f-contact-form-style" onSubmit={(e) => this.guestProspectiveRegister(e)}>
                                   <div className="col-sm-12">
                                       {this.state.message ? <span style={{ color: this.state.color, textAlign: 'right' }}>{this.state.message}</span> : ''}
                                   </div>
                                           <div className="form-group">
                                               <input type="email" id="email" className="form-control" value={email} disabled placeholder="Email"/>
                                           </div>
                                           <div className="form-group icononinput">
                                               <input type="password" id="subject" className="form-control" value={this.state.password} onChange={(e) => this.setValue('password', e)} placeholder="Create Password"/>
                                               <i title="Password should contain atleast 10 characters, one number and one special character"  className="fa fa-lg fa-info-circle icononinput "></i>
                                           </div>
                                           <button type="submit" className="btn company_btn" >REGISTER</button>
                               </form>
                               </div>
                       </div>
                   </div>
                   <Footer/>
               </div>
           );
       }
   }
   export default GuestRegister;