import React from "react";
import SessionService from "../../../../services/sessionService";
import GuestServicesapi from "../../../../services/guestServicesapi";
import Loader from "./loader";
import moment from "moment";
import DatePicker from "react-datepicker";
import NewHotelOrdersModal from "./newHotelOrderModal";
import GuestSidebar from "./guestSidebar";
import MainController from "../../../controller/mainController";
import GuestController from "../../../controller/guestController";

class NewReservations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeOrderInfo: [],
      pendingOrderInfo: [],
      termedOrderInfo: [],
      userData: "",
      orderInfo: {},
      ScheduleInfo: {},
      tab: "active",
      loader: false,
      showIfActiveInfoEmpty: false,
      showTripInfo: false,
      orderUid: "",
      ifLockBoxShowGuestPane: false,
      GuestInfoCommunityDetails: {},
      selectedData: {},
      arriveBy: "Car",
      sucessMessage: false,
      arrivalInfo:
        this.props.history.location.reservationInformation != undefined
          ? this.props.history.location.reservationInformation
          : "",
      images: "",
      types: "",
      complex: "",
    };
    this.selectedData = this.selectedData.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.planeSchedule = this.planeSchedule.bind(this);
    this.arrivalExpectation = this.arrivalExpectation.bind(this);
    this.onSliderChange = this.onSliderChange.bind(this);
    this.standardCheckInTime = this.standardCheckInTime.bind(this);
    this.expectToReceiveItinerary = this.expectToReceiveItinerary.bind(this);
    this.extraInfoByRoad = this.extraInfoByRoad.bind(this);
    this.extraInfoByDontKnowYet = this.extraInfoByDontKnowYet.bind(this);
    this.byRoad = this.byRoad.bind(this);
    this.closeSucessModal = this.closeSucessModal.bind(this);

    this.cancelModal = this.cancelModal.bind(this);
  }

  async componentDidMount() {
    await this.setState({ loader: true });
    if (
      this.props.location.reservationInformation &&
      this.props.location.reservationInformation.showTripInfo
    ) {
      await this.setState({ tab: "upcoming" });
      $("#myModalInstructions").modal("show");
    }
    await this.guest();
    if (
      this.props.location.reservationInformation &&
      this.props.location.reservationInformation.showTripInfo
    ) {
      await this.setState({
        showTripInfo: true,
        OrderUId: this.props.location.reservationInformation.OrderUId,
        image: this.props.location.reservationInformation.image,
        ComplexId: this.props.location.reservationInformation.ComplexId,
      });
      await this.navigateToActiveInfo(
        this.props.location.reservationInformation.OrderUId,
        this.props.location.reservationInformation.image,
        this.props.location.reservationInformation.ComplexId,
        this.props.location.reservationInformation.OrderType
      );
    }

    await SessionService.SessionGet({ type: "guest" }).then(
      async (response) => {
        await this.setState({ userData: response });
        var guestEmailObj = {
          guestEmail: response.GuestEmail,
          status: "active",
        };
        await GuestServicesapi.GetOrdersByGuestEmail(guestEmailObj).then(
          async (responses) => {
            if (responses.error !== 1 && responses.message.Orders.length > 0) {
              await responses.message.Orders.sort(function compare(a, b) {
                var dateA = new Date(a.MoveInDate);
                var dateB = new Date(b.MoveInDate);
                return dateA - dateB;
              });
              await this.setState({
                activeOrderInfo: responses.message.Orders,
                loader: false,
              });
            } else {
              await this.setState({
                loader: false,
                showIfActiveInfoEmpty: true,
              });
            }
          }
        );
        await this.getPendingOrderInfo(this.state.userData.GuestEmail);
        await this.getTermedOrderInfo(this.state.userData.GuestEmail);
      }
    );
  }
  async guest() {
    MainController.removeClassOnNavCollapse();
    const guestLoginResponse = await GuestController.guestLoginResponse();
    await this.setState({ userData: guestLoginResponse });
    var client = await SessionService.SessionGet({ type: "client" });
    if (client.error == 0) {
      await this.setState({
        roleId:
          client.roleId != undefined && Object.keys(client).length > 0
            ? client.roleId
            : 0,
        ClientType:
          client.ClientType != undefined && Object.keys(client).length > 0
            ? client.ClientType
            : "",
      });
    }
    const GetResponse = await SessionService.SessionGet({ type: "guest" });
    if (GetResponse.error == 0) {
      await this.setState({
        roleId:
          GetResponse.roleId != undefined && Object.keys(GetResponse).length > 0
            ? GetResponse.roleId
            : 0,
        ClientType:
          GetResponse.ClientType != undefined &&
          Object.keys(GetResponse).length > 0
            ? GetResponse.ClientType
            : "",
      });
    }
    await this.setState({ guestSessionResponse: GetResponse });
    if (GetResponse.roleId == 2) {
      // var guestEmailObj = {
      //   guestEmail: GetResponse.GuestEmail,
      //   status: "active",
      // };
      // const GetOrdersByGuestEmailResponse =
      //   await GuestServicesapi.GetOrdersByGuestEmail(guestEmailObj);
      // if (GetOrdersByGuestEmailResponse.message.Orders.length == 0) {
      //   this.setState({ hideMenu: true });
      // }
    }
  }
  async selectedData(data) {
    await this.setState({ selectedData: data });
    $("#modalFullBookingDetails").modal("show");
  }

  async getPendingOrderInfo(email) {
    this.setState({ loader: true });
    var guestEmailObj = {
      guestEmail: email,
      status: "pending",
    };
    await GuestServicesapi.GetOrdersByGuestEmail(guestEmailObj).then(
      async (response) => {
        if (response.error !== 1) {
          var pendingInfo = response.message.Orders;

          await pendingInfo.forEach(async (item, index) => {
            var community = item.Community;
            community = community && community.split(" ");

            var city = item.City;
            city = city && city.split(" ");

            var state = item.State;
            state = state && state.split(" ");

            for (let i = 0, x = community && community.length; i < x; i++) {
              community[i] =
                community[i] &&
                community[i][0].toUpperCase() + community[i].substr(1);
            }

            for (let i = 0, x = city && city.length; i < x; i++) {
              city[i] = city[i] && city[i][0].toUpperCase() + city[i].substr(1);
            }

            for (let i = 0, x = state && state.length; i < x; i++) {
              state[i] =
                state[i] && state[i][0].toUpperCase() + state[i].substr(1);
            }
            state = state && state.join(" ");
            city = city && city.join(" ");
            community = community && community.join(" ");
            state = state && state.charAt(0).toUpperCase() + state.substr(1);
            city = city && city.charAt(0).toUpperCase() + city.substr(1);
            community =
              community &&
              community.charAt(0).toUpperCase() + community.substr(1);
            pendingInfo[index].State = state;
            pendingInfo[index].City = city;
            pendingInfo[index].Community = community;
            if (pendingInfo.length > 0) {
              await pendingInfo.sort(function compare(a, b) {
                var dateA = new Date(a.MoveInDate);
                var dateB = new Date(b.MoveInDate);
                return dateA - dateB;
              });
            }
            await this.setState({
              pendingOrderInfo: pendingInfo,
              loader: false,
              orderInfo: {},
            });
          });
        }
        if (response.message.Orders.length == 0) {
          await this.setState({
            loader: false,
            showIfPendingInfoEmpty: true,
            orderInfo: {},
          });
        }
      }
    );
  }

  async getTermedOrderInfo(email) {
    await this.setState({ loader: true });
    var guestEmailObj = {
      guestEmail: email,
      status: "termed",
    };
    await GuestServicesapi.GetOrdersByGuestEmail(guestEmailObj).then(
      async (response) => {
        if (response.error !== 1) {
          var termedInfo = response.message.Orders;

          await termedInfo.forEach(async (item, index) => {
            var community = item.Community;
            community = community && community.split(" ");

            var city = item.City;
            city = city && city.split(" ");

            var state = item.State;
            state = state && state.split(" ");

            for (var i = 0, x = community && community.length; i < x; i++) {
              community[i] =
                community[i] &&
                community[i][0].toUpperCase() + community[i].substr(1);
            }

            for (var i = 0, x = city && city.length; i < x; i++) {
              city[i] = city[i] && city[i][0].toUpperCase() + city[i].substr(1);
            }

            for (var i = 0, x = state && state.length; i < x; i++) {
              state[i] =
                state[i] && state[i][0].toUpperCase() + state[i].substr(1);
            }

            state = state && state.join(" ");
            city = city && city.join(" ");
            community = community && community.join(" ");
            state = state && state.charAt(0).toUpperCase() + state.substr(1);
            city = city && city.charAt(0).toUpperCase() + city.substr(1);
            community =
              community &&
              community.charAt(0).toUpperCase() + community.substr(1);
            termedInfo[index].State = state;
            termedInfo[index].City = city;
            termedInfo[index].Community = community;
            if (termedInfo.length > 0) {
              await termedInfo.sort(function compare(a, b) {
                var dateA = new Date(a.MoveInDate);
                var dateB = new Date(b.MoveInDate);
                return dateA - dateB;
              });
            }
            await this.setState({ termedOrderInfo: termedInfo, loader: false });
          });
        }
        if (response.message.Orders.length == 0) {
          await this.setState({ loader: false, showIfTermedInfoEmpty: true });
        }
      }
    );
  }
  navigateToMyReservation(image, OrderUId, ComplexId, Order, type) {
    var orderUIdObj = {
      OrderUId: OrderUId,
    };
    if (type == "S") {
      GuestServicesapi.GetOrderInfoById(orderUIdObj)
        .then((getOrderInfo) => {
          if (getOrderInfo.error == 0) {
            let obj = getOrderInfo.message.orderInfo;
            obj["CommunityImage"] = image;
            obj["ComplexId"] = ComplexId;
            obj["OrderType"] = type;
            this.props.history.push({
              pathname: "/my-reservations",
              state: obj,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.selectedData(Order);
    }
  }

  GetGuestInfoCommunityByEmailId(data) {
    this.setState({
      GuestInfoCommunityDetails: {},
      type: data.OrderType,
      selectedData: data,
    });

    if (data.OrderType == "S") {
      GuestServicesapi.GetGuestInfoCommunityByEmailId({
        orderid: data.OrderId,
      }).then((response) => {
        this.setState({ GuestInfoCommunityDetails: response || {} });
        let GuestInfoCommunityDetails =
          (Object.keys(response).length > 0 && response) || {};
        var obj = Object.assign({}, GuestInfoCommunityDetails, data);
        this.selectedData(obj);
      });
    } else {
      this.selectedData(data);
    }
  }
  async navigateToActiveInfo(orderUID, image, ComplexId, type, Order) {
    console.log("555", orderUID);
    await this.setState({
      images: image,
      complex: ComplexId,
      types: type,
      orderUid: orderUID,
      errorMessage: "",
      //arriveBy: "",
      sameDayFlyAndMoveStatus: "",
      arrivalAirport: "",
      planeSchedule: null,
      airlineName: "",
      flightno: "",
      arrivalExpectation: null,
      extraInfo: "",
      alreadyBeInTheArea: "",
      scheduleFlexible: "",
      extraInfoByRoad: "",
      sliderValue: 0,
      standardCheckInTime: null,
      expectToReceiveItinerary: null,
      extraInfoByDontKnowYet: "",
      ComplexId: "",
      selectedData: {},
      reservationCardName: Order !== undefined ? Order.Community : null,
    });

    this.setState({ image, ComplexId });
    var orderUIdObj = {
      OrderUId: orderUID,
    };
    if (type == "S") {
      if (Order != undefined) {
        GuestServicesapi.GetTripInfo({ orderUid: orderUIdObj.OrderUId }).then(
          (tripInfoData) => {
            console.log("tripInfoData", tripInfoData);
            if (
              tripInfoData == null ||
              tripInfoData.Message == "The request is invalid."
            ) {
              this.setState({ showTripInfo: true, loader: false });
              GuestServicesapi.GetOrderInfoById(orderUIdObj).then(
                (getOrderInfo) => {
                  if (getOrderInfo.Message !== "The request is invalid.") {
                    if (getOrderInfo.message.orderInfo.Entry == "Lockbox") {
                      this.setState({
                        ifLockBoxShowGuestPane: true,
                        loader: false,
                      });
                    }
                  }
                }
              );
              this.openArrivalInfoModal();
            } else {
              GuestServicesapi.GetOrderInfoById(orderUIdObj).then(
                (getOrderInfo) => {
                  if (getOrderInfo.Message !== "The request is invalid.") {
                    this.setState({
                      orderInfo: getOrderInfo.message.orderInfo,
                      ScheduleInfo: getOrderInfo.message.ScheduleInfo,
                      loader: false,
                    });
                    let obj = getOrderInfo.message.orderInfo;
                    obj["CommunityImage"] = image;
                    obj["travelBy"] = tripInfoData.TravelBy;
                    obj["ComplexId"] = ComplexId;
                    obj["OrderType"] = type;
                    if (
                      tripInfoData.TravelBy == "Dont Know Yet" &&
                      new Date() < new Date(obj.MoveInDate)
                    ) {
                      this.setState({ showTripInfo: true });
                      this.openArrivalInfoModal();
                    } else {
                      this.props.history.push({
                        pathname: "/my-reservations",
                        state: obj,
                      });
                      $(".modal-backdrop").remove();
                    }
                  } else {
                    this.props.history.push({
                      pathname: "/reservations",
                      state: orderUID,
                      isShowGuestPane: false,
                    });
                  }
                }
              );
            }
          }
        );
      } else {
        GuestServicesapi.GetTripInfo({ orderUid: orderUIdObj.OrderUId }).then(
          (tripInfoData) => {
            if (
              tripInfoData == null ||
              tripInfoData.Message == "The request is invalid."
            ) {
              this.setState({ showTripInfo: true, loader: false });
              GuestServicesapi.GetOrderInfoById(orderUIdObj).then(
                (getOrderInfo) => {
                  if (getOrderInfo.Message !== "The request is invalid.") {
                    if (getOrderInfo.message.orderInfo.Entry == "Lockbox") {
                      this.setState({
                        ifLockBoxShowGuestPane: true,
                        loader: false,
                      });
                    }
                  }
                }
              );
              this.openArrivalInfoModal();
            } else {
              GuestServicesapi.GetOrderInfoById(orderUIdObj).then(
                (getOrderInfo) => {
                  if (getOrderInfo.Message !== "The request is invalid.") {
                    this.setState({
                      orderInfo: getOrderInfo.message.orderInfo,
                      ScheduleInfo: getOrderInfo.message.ScheduleInfo,
                      loader: false,
                    });
                    let obj = getOrderInfo.message.orderInfo;
                    obj["CommunityImage"] = image;
                    obj["travelBy"] = tripInfoData.TravelBy;
                    obj["ComplexId"] = ComplexId;
                    obj["OrderType"] = type;
                    this.setState({ showTripInfo: true });
                    this.openArrivalInfoModal();
                  } else {
                    this.props.history.push({
                      pathname: "/reservations",
                      state: orderUID,
                      isShowGuestPane: false,
                    });
                  }
                }
              );
            }
          }
        );
      }
    } else {
      this.selectedData(Order);
    }
  }

  openArrivalInfoModal() {
    document.body.classList.add("modal-open");
    $("#myModalInstructions").modal("show");
  }

  async closeModal() {
    if (this.state.arrivalInfo == null || this.state.arrivalInfo == "") {
      await this.setState({
        showTripInfo: false,
        arrivalInfo: null,
        arriveBy: "Car",
        errorMessage: "",
        alreadyBeInTheArea: "",
        sliderValue: 0,
        scheduleFlexible: "",
        standardCheckInTime: null,
        extraInfoByRoad: "",
        sameDayFlyAndMoveStatus: "",
        planeSchedule: null,
        arrivalExpectation: null,
        expectToReceiveItinerary: null,
      });
      this.extraInfoByRoadRef.value = "";
      this.arrivalAirportRef.value = "";
      this.airlineNameRef.value = "";
      this.flightnoRef.value = "";
      this.extraInfoRef.value = "";
      this.extraInfoByDontKnowYetRef.value = "";
      $("#datepickercar").removeClass("validation");
      $("#extrainfotext").removeClass("validation");
      $("#arrivalairport").removeClass("validation");
      $("#arrivalairline").removeClass("validation");
      $("#arrivalflightnumb").removeClass("validation");
      $("#planescheduled").removeClass("validation");
      $("#arriveSchedule").removeClass("validation");
      $("#additionalInformation").removeClass("validation");
      $("#otherdatepicker").removeClass("validation");
      $("#otherinfo").removeClass("validation");
      document.body.classList.remove("modal-open");
      $("#myModalInstructions").modal("hide");
      $("body").removeClass("modal-open");
      $(".modal-backdrop").remove();
    } else {
      this.props.history.go(-1);
      $(".modal-backdrop").remove();
    }
  }

  sameDayFlyAndMoveStatus(e) {
    this.setState({ sameDayFlyAndMoveStatus: e.target.value });
  }

  setValue(e) {
    this.validate(e.target);
    var state = this.state;
    state[e.target.name] = e.target.value;
    this.setState({ state });
  }

  validate(input) {
    if (/^\s/.test(input.value)) input.value = "";
  }

  async planeSchedule(date) {
    if (date !== null) {
      await this.setState({ planeSchedule: moment(date) });
    }
  }

  async arrivalExpectation(date) {
    if (date !== null) {
      await this.setState({ arrivalExpectation: moment(date) });
    }
  }

  async cancelModal() {
    $("#myModalInstructions").modal("hide");
    var orderUIdObj = {
      OrderUId: this.state.orderUid,
    };
    GuestServicesapi.GetOrderInfoById(orderUIdObj).then((getOrderInfo) => {
      console.log("getOrderInfo", getOrderInfo);
      if (getOrderInfo.message !== "The request is invalid.") {
        this.setState({
          orderInfo: getOrderInfo.message.orderInfo,
          ScheduleInfo: getOrderInfo.message.ScheduleInfo,
          loader: false,
        });
        let obj = getOrderInfo.message.orderInfo;
        obj["CommunityImage"] = this.state.images;
        // obj["travelBy"] = tripInfoData.TravelBy;
        obj["ComplexId"] = this.state.complex;
        obj["OrderType"] = this.state.types;
        this.props.history.push({
          pathname: "/my-reservations",
          state: obj,
        });
      } else {
        this.props.history.push({
          pathname: "/reservations",
          state: this.state.orderUid,
          isShowGuestPane: false,
        });
      }
    });
  }

  /**
   * Function to submit by plane
   **/
  async byAir() {
    // var currentDate = "";
    // await this.getCurrentDateTime().then((response) => {
    //   currentDate = response;
    // });
    var date = moment();
    var currentDate = moment(date).format("MM/DD/YYYY HH:mm");
    var planeScheduleDate = moment(this.state.planeSchedule).format(
      "MM/DD/YYYY HH:mm"
    );
    var arrivalExpectationDate = moment(this.state.arrivalExpectation).format(
      "MM/DD/YYYY HH:mm"
    );
    const dateCurr = moment(currentDate);
    const dateArrivalExp = moment(arrivalExpectationDate);
    const datePlaneSch = moment(planeScheduleDate);
    const _this = this;
    const validationFields = [
      "sameDayFlyAndMoveStatus",
      "arrivalAirport",
      "airlineName",
      "flightno",
      "extraInfo",
    ];
    let error = 0;
    _.forEach(validationFields, function (value) {
      if (_this.state[value] == "") {
        error++;
      }
    });

    if (this.arrivalAirportRef.value == "") {
      $("#arrivalairport").addClass("validation");
    } else {
      $("#arrivalairport").removeClass("validation");
    }

    if (this.airlineNameRef.value == "") {
      $("#arrivalairline").addClass("validation");
    } else {
      $("#arrivalairline").removeClass("validation");
    }

    if (this.flightnoRef.value == "") {
      $("#arrivalflightnumb").addClass("validation");
    } else {
      $("#arrivalflightnumb").removeClass("validation");
    }

    if (this.flightnoRef.value == "") {
      $("#arrivalflightnumb").addClass("validation");
    } else {
      $("#arrivalflightnumb").removeClass("validation");
    }

    if (this.state.planeSchedule == null) {
      $("#planescheduled").addClass("validation");
    } else {
      $("#planescheduled").removeClass("validation");
    }

    if (this.state.arrivalExpectation == null) {
      $("#arriveSchedule").addClass("validation");
    } else {
      $("#arriveSchedule").removeClass("validation");
    }

    if (this.state.additionalInformation == "") {
      $("#additionalInformation").addClass("validation");
    } else {
      $("#additionalInformation").removeClass("validation");
    }
    if (this.state.planeSchedule == null) {
      error++;
    }
    if (this.state.arrivalExpectation == null) {
      error++;
    }
    if (error !== 0) {
      this.setState({ errorMessage: "Please enter all fields." });
    } else {
      // if (
      //   planeScheduleDate < currentDate ||
      //   arrivalExpectationDate < currentDate
      // )
      if (
        datePlaneSch.valueOf() < dateCurr.valueOf() ||
        dateArrivalExp.valueOf() < dateCurr.valueOf()
      ) {
        this.setState({ errorMessage: "Please select valid date and time" });
      } else {
        this.setState({ errorMessage: "" });
        var planeObj = {
          OrderUId: this.state.orderUid,
          TravelBy: "By Air",
          Notes: this.state.extraInfo,
          ChangedBy: "",
          InTown: "",
          Hoteltransfers: "",
          FlyingSameDay: this.state.sameDayFlyAndMoveStatus,
          FlightArrival: planeScheduleDate + ":00.000",
          Airline: this.state.airlineName,
          ArrivalAirport: this.state.arrivalAirport,
          Flight: this.state.flightno,
          ArrivalDate: arrivalExpectationDate + ":00.000",
        };

        const SaveOrderTripDetailsResponse =
          await GuestServicesapi.SaveOrderTripDetails(planeObj);
        if (SaveOrderTripDetailsResponse !== null) {
          if (SaveOrderTripDetailsResponse.hasOwnProperty("message")) {
            await this.setState({
              planeSchedule: null,
              arrivalExpectation: null,
              sameDayFlyAndMoveStatus: "",
              uniqueId: SaveOrderTripDetailsResponse.UniqueId,
              tableId: SaveOrderTripDetailsResponse.TableId,
            });
            this.airlineNameRef.value = "";
            this.arrivalAirportRef.value = "";
            this.flightnoRef.value = "";
            this.extraInfoRef.value = "";
            //  location.href = '/reservations'

            await this.setState({ showTripInfo: false, sucessMessage: true });
            document.body.classList.remove("modal-open");
            $("#myModalInstructions").modal("hide");
            $("body").removeClass("modal-open");
            $(".modal-backdrop").remove();

            $("#successModal").modal("show");
            // var orderUIdObj = {
            //   OrderUId: this.state.orderUid
            // }
            // GuestServicesapi.GetOrderInfoById(orderUIdObj).then((getOrderInfo) => {
            //   if (getOrderInfo.Message !== "The request is invalid.") {
            //     this.setState({ orderInfo: getOrderInfo.message.orderInfo, ScheduleInfo: getOrderInfo.message.ScheduleInfo, loader: false })
            //     let obj = getOrderInfo.message.orderInfo;
            //     obj["CommunityImage"] = this.state.image;
            //     obj["ComplexId"] = this.state.ComplexId;
            //     obj["OrderType"] = this.state.arrivalInfo.OrderType;
            //     this.props.history.push({
            //       pathname: "/my-reservations",
            //       state: obj
            //     })
            //     $('.modal-backdrop').remove();
            //   } else {
            //     this.props.history.push({
            //       pathname: "/reservations",
            //       state: orderUID,
            //       isShowGuestPane: false
            //     })
            //   }
            // })
          } else {
            console.log("Failed to load trip details");
          }
        }
      }
    }
  }

  /**
   * Function to submit by road
   **/
  async byRoad() {
    // var currentDate = "";
    // await this.getCurrentDateTime().then((response) => {
    //   currentDate = response;
    // });
    var date = moment();
    var currentDate = moment(date).format("MM/DD/YYYY HH:mm");

    var standardCheckInDateTime = moment(this.state.standardCheckInTime).format(
      "MM/DD/YYYY HH:mm"
    );
    const dateCurr = moment(currentDate);
    const dateStand = moment(standardCheckInDateTime);
    // if (dateStand.isBefore(dateCurr)) {
    //   console.log("current date is greater than standard");
    // } else {
    //   console.log("current date is less than standard");
    // }
    // console.log(dateCurr.valueOf(), dateStand.valueOf(), "current-stand");

    const _this = this;
    const validationFields = [
      "alreadyBeInTheArea",
      "sliderValue",
      "scheduleFlexible",
      "extraInfoByRoad",
    ];
    let error = 0;
    _.forEach(validationFields, function (value) {
      if (_this.state[value] == "") {
        error++;
      }
    });

    if (this.state.standardCheckInTime == null) {
      $("#datepickercar").addClass("validation");
    } else {
      $("#datepickercar").removeClass("validation");
    }

    if (this.extraInfoByRoadRef.value == "") {
      $("#extrainfotext").addClass("validation");
    } else {
      $("#extrainfotext").removeClass("validation");
    }

    if (this.extraInfoByRoadRef.value == "") {
      $("#extrainfotext").addClass("validation");
    } else {
      $("#extrainfotext").removeClass("validation");
    }

    if (this.state.standardCheckInTime == null) {
      error++;
    }
    if (error !== 0) {
      this.setState({ errorMessage: "Please enter all fields." });
    } else {
      // if (standardCheckInDateTime < currentDate) {
      //   this.setState({ errorMessage: "Please select valid date and time" });
      // }
      if (dateStand.valueOf() < dateCurr.valueOf()) {
        this.setState({ errorMessage: "Please select valid date and time" });
      } else {
        const userData = await SessionService.SessionGet({ type: "guest" });
        this.setState({ errorMessage: "" });
        var roadObj = {
          OrderUId: this.state.orderUid,
          TravelBy: "By Road",
          Notes: this.state.extraInfoByRoad,
          ChangedBy: "",
          DrivingMiles: this.state.sliderValue,
          ArrivalDate: standardCheckInDateTime + ":00.000",
          AlreadyBeInTheArea: this.state.alreadyBeInTheArea,
          ScheduleFlexible: this.state.scheduleFlexible,
        };

        const SaveOrderTripDetailsResponse =
          await GuestServicesapi.SaveOrderTripDetails(roadObj);
        if (SaveOrderTripDetailsResponse !== null) {
          if (SaveOrderTripDetailsResponse.hasOwnProperty("message")) {
            await this.setState({
              alreadyBeInTheArea: "",
              sliderValue: 0,
              scheduleFlexible: "",
              extraInfoByRoad: "",
              standardCheckInTime: null,
              uniqueId: SaveOrderTripDetailsResponse.UniqueId,
              tableId: SaveOrderTripDetailsResponse.TableId,
            });
            this.sliderValueRef.value = "";
            this.extraInfoByRoadRef.value = "";
            await this.setState({ showTripInfo: false, sucessMessage: true });
            document.body.classList.remove("modal-open");
            $("#myModalInstructions").modal("hide");

            $("body").removeClass("modal-open");
            // $('.modal-backdrop').remove();

            $("#successModal").modal("show");

            // var orderUIdObj = {
            //   OrderUId: this.state.orderUid
            // }
            // GuestServicesapi.GetOrderInfoById(orderUIdObj).then((getOrderInfo) => {
            //   if (getOrderInfo.Message !== "The request is invalid.") {
            //     this.setState({ orderInfo: getOrderInfo.message.orderInfo, ScheduleInfo: getOrderInfo.message.ScheduleInfo, loader: false })
            //     let obj = getOrderInfo.message.orderInfo;
            //     obj["CommunityImage"] = this.state.image
            //     obj["ComplexId"] = this.state.ComplexId
            //     obj["OrderType"] = this.state.arrivalInfo.OrderType
            //     this.props.history.push({
            //       pathname: "/my-reservations",
            //       state: obj
            //     })

            //   } else {
            //     this.props.history.push({
            //       pathname: "/reservations",
            //       state: orderUID,
            //       isShowGuestPane: false
            //     })
            //   }
            // })
          } else {
            console.log("Failed to load trip details");
          }
        }
      }
    }
  }

  /**
   * Function to submit by Dont know Yet
   **/
  async dontKnowYet() {
    // var currentDate = "";
    // await this.getCurrentDateTime().then((response) => {
    //   currentDate = response;
    // });
    var date = moment();
    var currentDate = moment(date).format("MM/DD/YYYY HH:mm");
    var expectToReceiveItineraryDate = moment(
      this.state.expectToReceiveItinerary
    ).format("MM/DD/YYYY HH:mm");

    const dateCurr = moment(currentDate);
    const dateExpectedReciveIter = moment(expectToReceiveItineraryDate);

    let error = 0;
    if (this.state.expectToReceiveItinerary == null) {
      error++;
    }
    if (this.state.extraInfoByDontKnowYet == "") {
      error++;
    }

    if (this.state.expectToReceiveItinerary == null) {
      $("#otherdatepicker").addClass("validation");
    } else {
      $("#otherdatepicker").removeClass("validation");
    }
    if (this.extraInfoByDontKnowYetRef.value == "") {
      $("#otherinfo").addClass("validation");
    } else {
      $("#otherinfo").removeClass("validation");
    }
    if (this.extraInfoByDontKnowYetRef.value == "") {
      $("#otherinfo").addClass("validation");
    } else {
      $("#otherinfo").removeClass("validation");
    }

    if (error !== 0) {
      this.setState({ errorMessage: "Please enter all fields." });
    } else {
      // if (expectToReceiveItineraryDate < currentDate)
      if (dateExpectedReciveIter.valueOf() < dateCurr.valueOf()) {
        this.setState({ errorMessage: "Please select valid date and time" });
      } else {
        this.setState({ errorMessage: "" });
        var dontKnowYetObj = {
          OrderUId: this.state.orderUid,
          TravelBy: "Dont Know Yet",
          Notes: this.state.extraInfoByDontKnowYet,
          ChangedBy: "",
          ExpectedDate: expectToReceiveItineraryDate + ":00.000",
        };

        const SaveOrderTripDetailsResponse =
          await GuestServicesapi.SaveOrderTripDetails(dontKnowYetObj);
        if (SaveOrderTripDetailsResponse !== null) {
          if (SaveOrderTripDetailsResponse.hasOwnProperty("message")) {
            await this.setState({ expectToReceiveItinerary: null });
            this.extraInfoByDontKnowYetRef.value = "";
            //location.href = '/reservations'

            await this.setState({ showTripInfo: false, sucessMessage: true });
            document.body.classList.remove("modal-open");

            $("#myModalInstructions").modal("hide");
            $("body").removeClass("modal-open");
            $(".modal-backdrop").remove();

            $("#successModal").modal("show");

            // var orderUIdObj = {
            //   OrderUId: this.state.orderUid
            // }
            // GuestServicesapi.GetOrderInfoById(orderUIdObj).then((getOrderInfo) => {
            //   if (getOrderInfo.Message !== "The request is invalid.") {

            //     this.setState({ orderInfo: getOrderInfo.message.orderInfo, ScheduleInfo: getOrderInfo.message.ScheduleInfo, loader: false })
            //     let obj = getOrderInfo.message.orderInfo;
            //     obj["CommunityImage"] = this.state.image
            //     obj["ComplexId"] = this.state.ComplexId
            //     obj["travelBy"] = "Dont Know Yet"
            //     obj["OrderType"] = this.state.arrivalInfo.OrderType
            //     this.props.history.push({
            //       pathname: "/my-reservations",
            //       state: obj
            //     })
            //     $('.modal-backdrop').remove();
            //   } else {
            //     this.props.history.push({
            //       pathname: "/reservations",
            //       state: orderUID,
            //       isShowGuestPane: false
            //     })
            //   }
            // })
          } else {
            console.log("Failed to load trip details");
          }
        }
      }
    }
  }

  /**
   * Function to get Current date and time
   */
  async getCurrentDateTime() {
    var date = moment();
    var curDate = moment(date).format("MM/DD/YYYY HH:mm");
    return curDate;
  }

  alreadyBeInTheArea(e) {
    this.setState({ alreadyBeInTheArea: e.target.value });
  }

  scheduleFlexible(e) {
    this.setState({ scheduleFlexible: e.target.value });
  }

  extraInfoByRoad(e) {
    this.validate(e.target);
    this.setState({ extraInfoByRoad: e.target.value });
  }

  async standardCheckInTime(date) {
    if (date !== null) {
      await this.setState({ standardCheckInTime: moment(date) });
    }
  }

  onSliderChange(e) {
    this.setState({ sliderValue: e.target.value });
  }

  async expectToReceiveItinerary(date) {
    if (date !== null) {
      await this.setState({ expectToReceiveItinerary: moment(date) });
    }
  }

  extraInfoByDontKnowYet(e) {
    this.validate(e.target);
    this.setState({ extraInfoByDontKnowYet: e.target.value });
  }
  async handletabchange(tabname) {
    await this.setState({
      arriveBy: tabname,
      errorMessage: "",
    });
    if (this.state.arriveBy === "Car") {
      this.setState({
        alreadyBeInTheArea: "",
        sliderValue: 0,
        scheduleFlexible: "",
        standardCheckInTime: null,
        extraInfoByRoad: "",
      });
      this.extraInfoByRoadRef.value = "";
      $("#datepickercar").removeClass("validation");
      $("#extrainfotext").removeClass("validation");
    }
    if (this.state.arriveBy === "Plane") {
      this.setState({
        sameDayFlyAndMoveStatus: "",
        planeSchedule: null,
        arrivalExpectation: null,
      });
      this.arrivalAirportRef.value = "";
      this.airlineNameRef.value = "";
      this.flightnoRef.value = "";
      this.extraInfoRef.value = "";
      $("#arrivalairport").removeClass("validation");
      $("#arrivalairline").removeClass("validation");
      $("#arrivalflightnumb").removeClass("validation");
      $("#planescheduled").removeClass("validation");
      $("#arriveSchedule").removeClass("validation");
      $("#additionalInformation").removeClass("validation");
    }
    if (this.state.arriveBy === "Others") {
      this.setState({
        expectToReceiveItinerary: null,
      });
      this.extraInfoByDontKnowYetRef.value = "";
      $("#otherdatepicker").removeClass("validation");
      $("#otherinfo").removeClass("validation");
    }
  }

  async closeSucessModal() {
    var orderUIdObj = {
      OrderUId: this.state.orderUid,
    };
    await GuestServicesapi.GetOrderInfoById(orderUIdObj).then(
      async (getOrderInfo) => {
        if (getOrderInfo.Message !== "The request is invalid.") {
          await this.setState({
            orderInfo: getOrderInfo.message.orderInfo,
            ScheduleInfo: getOrderInfo.message.ScheduleInfo,
            loader: false,
          });
          let obj = getOrderInfo.message.orderInfo;
          obj["CommunityImage"] = this.state.image;
          obj["ComplexId"] = this.state.ComplexId;
          obj["travelBy"] = "Dont Know Yet";
          obj["OrderType"] = this.state.arrivalInfo.OrderType;
          this.props.history.push({
            pathname: "/my-reservations",
            state: obj,
          });
          $(".modal-backdrop").remove();
        } else {
          this.props.history.push({
            pathname: "/reservations",
            state: orderUID,
            isShowGuestPane: false,
          });
        }
      }
    );
  }

  render() {
    return (
      <div className="SAweb_updateUi">
        <div className="SAwebGuest_reservePage bg-white">
          <GuestSidebar />

          <div id="content">
            <div className="SAwebGuest_reserveTabs">
              <ul className="nav nav-tabs">
                <li className="active">
                  <a data-toggle="tab" href="#tabCurrent">
                    Current
                  </a>
                </li>
                <li>
                  <a data-toggle="tab" href="#tabUpcoming">
                    Upcoming
                  </a>
                </li>
                <li>
                  <a data-toggle="tab" href="#tabPast">
                    Past
                  </a>
                </li>
              </ul>

              <div className="tab-content">
                {this.state.selectedData &&
                  Object.keys(this.state.selectedData).length > 0 && (
                    <NewHotelOrdersModal
                      selectedData={this.state.selectedData}
                    ></NewHotelOrdersModal>
                  )}
                <div id="tabCurrent" className="tab-pane fade in active">
                  {this.state.activeOrderInfo.length > 0 ? (
                    <div className="currentTabTails">
                      {this.state.activeOrderInfo.map(
                        (activeOrderInfo, index) => {
                          return (
                            <div
                              className="item"
                              key={index}
                              onClick={() =>
                                this.navigateToMyReservation(
                                  activeOrderInfo.CommunityImage,
                                  activeOrderInfo.OrderUId,
                                  activeOrderInfo.ComplexId,
                                  activeOrderInfo,
                                  activeOrderInfo.OrderType
                                )
                              }
                            >
                              <div className="pic">
                                <img
                                  src={
                                    activeOrderInfo.CommunityImage == null ||
                                    activeOrderInfo.CommunityImage == ""
                                      ? activeOrderInfo.OrderType == "H"
                                        ? "../images/s-dummyImage-2.png"
                                        : "../images/dummyCommunity.jpg"
                                      : activeOrderInfo.CommunityImage
                                  }
                                  alt=""
                                  width="100%"
                                />
                              </div>
                              <div className="text">
                                {/* <h2>101 San Fernando</h2> */}
                                {activeOrderInfo.OrderType == "S" ? (
                                  <h2>{activeOrderInfo.Community}</h2>
                                ) : activeOrderInfo.HotelName ? (
                                  <h2>{activeOrderInfo.HotelName}</h2>
                                ) : (
                                  <h2>Hotel Name</h2>
                                )}
                                {activeOrderInfo.OrderType == "S" && (
                                  <p style={{ textTransform: "capitalize" }}>
                                    {activeOrderInfo.City.toLowerCase()},{" "}
                                    {activeOrderInfo.State.toLowerCase()}{" "}
                                    <span>
                                      {moment(
                                        activeOrderInfo.MoveInDate
                                      ).format("DD")}{" "}
                                      {moment(
                                        activeOrderInfo.MoveInDate
                                      ).format("MMM")}{" "}
                                      {moment(
                                        activeOrderInfo.MoveInDate
                                      ).format("YYYY")}{" "}
                                      -{" "}
                                      {moment(
                                        activeOrderInfo.MoveOutDate
                                      ).format("DD")}{" "}
                                      {moment(
                                        activeOrderInfo.MoveOutDate
                                      ).format("MMM")}{" "}
                                      {moment(
                                        activeOrderInfo.MoveOutDate
                                      ).format("YYYY")}
                                    </span>
                                  </p>
                                )}
                                {activeOrderInfo.OrderType == "H" && (
                                  <p style={{ textTransform: "capitalize" }}>
                                    {activeOrderInfo.HotelCity
                                      ? activeOrderInfo.HotelCity
                                      : "San Francisco"}
                                    ,{" "}
                                    {activeOrderInfo.HotelState
                                      ? activeOrderInfo.HotelState
                                      : "California"}{" "}
                                    <span>
                                      {moment(
                                        activeOrderInfo.MoveInDate
                                      ).format("DD")}{" "}
                                      {moment(
                                        activeOrderInfo.MoveInDate
                                      ).format("MMM")}{" "}
                                      {moment(
                                        activeOrderInfo.MoveInDate
                                      ).format("YYYY")}{" "}
                                      -{" "}
                                      {moment(
                                        activeOrderInfo.MoveOutDate
                                      ).format("DD")}{" "}
                                      {moment(
                                        activeOrderInfo.MoveOutDate
                                      ).format("MMM")}{" "}
                                      {moment(
                                        activeOrderInfo.MoveOutDate
                                      ).format("YYYY")}
                                    </span>
                                  </p>
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  ) : this.state.loader ? (
                    // <div className="gif_loader">
                    //   <img src="assets/newUI-images/explore_icons/Icon-animation-loop.gif" />
                    // </div>
                    <Loader />
                  ) : this.state.showIfActiveInfoEmpty ? (
                    <p
                      style={{
                        color: "red",
                        marginTop: "35px",
                        textAlign: "center",
                      }}
                    >
                      You don't have any active reservations.
                    </p>
                  ) : null}
                </div>
                <div id="tabUpcoming" className="tab-pane fade">
                  {this.state.pendingOrderInfo.length > 0 ? (
                    <div className="currentTabTails">
                      {this.state.pendingOrderInfo.map((pendingData, index) => {
                        return (
                          <div
                            className="item"
                            key={index}
                            onClick={() =>
                              this.navigateToActiveInfo(
                                pendingData.OrderUId,
                                pendingData.CommunityImage,
                                pendingData.ComplexId,
                                pendingData.OrderType,
                                pendingData
                              )
                            }
                          >
                            <div className="pic">
                              <img
                                src={
                                  pendingData.CommunityImage == null ||
                                  pendingData.CommunityImage == ""
                                    ? pendingData.OrderType == "H"
                                      ? "../images/s-dummyImage-2.png"
                                      : "../images/dummyCommunity.jpg"
                                    : pendingData.CommunityImage
                                }
                                alt=""
                                width="100%"
                              />
                            </div>
                            <div className="text">
                              {/* <h2>101 San Fernando</h2> */}
                              {pendingData.OrderType == "S" ? (
                                <h2>{pendingData.Community}</h2>
                              ) : pendingData.HotelName ? (
                                <h2>{pendingData.HotelName}</h2>
                              ) : (
                                <h2>Hotel Name</h2>
                              )}
                              {pendingData.OrderType == "S" && (
                                <p style={{ textTransform: "capitalize" }}>
                                  {pendingData.City.toLowerCase()},{" "}
                                  {pendingData.State.toLowerCase()}{" "}
                                  <span>
                                    {moment(pendingData.MoveInDate).format(
                                      "DD"
                                    )}{" "}
                                    {moment(pendingData.MoveInDate).format(
                                      "MMM"
                                    )}{" "}
                                    {moment(pendingData.MoveInDate).format(
                                      "YYYY"
                                    )}{" "}
                                    -{" "}
                                    {moment(pendingData.MoveOutDate).format(
                                      "DD"
                                    )}{" "}
                                    {moment(pendingData.MoveOutDate).format(
                                      "MMM"
                                    )}{" "}
                                    {moment(pendingData.MoveOutDate).format(
                                      "YYYY"
                                    )}
                                  </span>
                                </p>
                              )}
                              {pendingData.OrderType == "H" && (
                                <p style={{ textTransform: "capitalize" }}>
                                  {pendingData.HotelCity
                                    ? pendingData.HotelCity
                                    : "San Francisco"}
                                  ,{" "}
                                  {pendingData.HotelState
                                    ? pendingData.HotelState
                                    : "California"}{" "}
                                  <span>
                                    {moment(pendingData.MoveInDate).format(
                                      "DD"
                                    )}{" "}
                                    {moment(pendingData.MoveInDate).format(
                                      "MMM"
                                    )}{" "}
                                    {moment(pendingData.MoveInDate).format(
                                      "YYYY"
                                    )}{" "}
                                    -{" "}
                                    {moment(pendingData.MoveOutDate).format(
                                      "DD"
                                    )}{" "}
                                    {moment(pendingData.MoveOutDate).format(
                                      "MMM"
                                    )}{" "}
                                    {moment(pendingData.MoveOutDate).format(
                                      "YYYY"
                                    )}
                                  </span>
                                </p>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : this.state.loader ? (
                    // <div className="gif_loader">
                    //   <img src="assets/newUI-images/explore_icons/Icon-animation-loop.gif" />
                    // </div>
                    <Loader />
                  ) : this.state.showIfPendingInfoEmpty ? (
                    <p
                      style={{
                        color: "red",
                        marginTop: "35px",
                        textAlign: "center",
                      }}
                    >
                      You don't have any active reservations.
                    </p>
                  ) : null}
                </div>
                <div id="tabPast" className="tab-pane fade">
                  {this.state.termedOrderInfo.length > 0 ? (
                    <div className="currentTabTails">
                      {this.state.termedOrderInfo.map((termedData, index) => {
                        return (
                          <div
                            className="item"
                            key={index}
                            onClick={() =>
                              this.GetGuestInfoCommunityByEmailId(termedData)
                            }
                          >
                            <div className="pic">
                              <img
                                src={
                                  termedData.CommunityImage == null ||
                                  termedData.CommunityImage == ""
                                    ? termedData.OrderType == "H"
                                      ? "../images/s-dummyImage-2.png"
                                      : "../images/dummyCommunity.jpg"
                                    : termedData.CommunityImage
                                }
                                alt=""
                                width="100%"
                              />
                            </div>
                            <div className="text">
                              {/* <h2>101 San Fernando</h2> */}
                              {termedData.OrderType == "S" ? (
                                <h2>{termedData.Community}</h2>
                              ) : termedData.HotelName ? (
                                <h2>{termedData.HotelName}</h2>
                              ) : (
                                <h2>Hotel Name</h2>
                              )}
                              {termedData.OrderType == "S" && (
                                <p style={{ textTransform: "capitalize" }}>
                                  {termedData.City.toLowerCase()},{" "}
                                  {termedData.State.toLowerCase()}{" "}
                                  <span>
                                    {moment(termedData.MoveInDate).format("DD")}{" "}
                                    {moment(termedData.MoveInDate).format(
                                      "MMM"
                                    )}{" "}
                                    {moment(termedData.MoveInDate).format(
                                      "YYYY"
                                    )}{" "}
                                    -{" "}
                                    {moment(termedData.MoveOutDate).format(
                                      "DD"
                                    )}{" "}
                                    {moment(termedData.MoveOutDate).format(
                                      "MMM"
                                    )}{" "}
                                    {moment(termedData.MoveOutDate).format(
                                      "YYYY"
                                    )}
                                  </span>
                                </p>
                              )}
                              {termedData.OrderType == "H" && (
                                <p style={{ textTransform: "capitalize" }}>
                                  {termedData.HotelCity
                                    ? termedData.HotelCity
                                    : "San Francisco"}
                                  ,{" "}
                                  {termedData.HotelState
                                    ? termedData.HotelState
                                    : "California"}{" "}
                                  <span>
                                    {moment(termedData.MoveInDate).format("DD")}{" "}
                                    {moment(termedData.MoveInDate).format(
                                      "MMM"
                                    )}{" "}
                                    {moment(termedData.MoveInDate).format(
                                      "YYYY"
                                    )}{" "}
                                    -{" "}
                                    {moment(termedData.MoveOutDate).format(
                                      "DD"
                                    )}{" "}
                                    {moment(termedData.MoveOutDate).format(
                                      "MMM"
                                    )}{" "}
                                    {moment(termedData.MoveOutDate).format(
                                      "YYYY"
                                    )}
                                  </span>
                                </p>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : this.state.loader ? (
                    // <div className="gif_loader">
                    //   <img src="assets/newUI-images/explore_icons/Icon-animation-loop.gif" />
                    // </div>
                    <Loader />
                  ) : this.state.showIfTermedInfoEmpty ? (
                    <p
                      style={{
                        color: "red",
                        marginTop: "35px",
                        textAlign: "center",
                      }}
                    >
                      You don't have any active reservations.
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            "modal modal-fullscreen-xl modal fade animated tab-modal SAweb_modalPoup in"
          }
          id="myModalInstructions"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn close"
                  onClick={this.closeModal}
                >
                  <i className="bi bi-x"></i>
                </button>

                <div className="SAwebGuest_arrivalInfo">
                  <div>
                    {this.state.sucessMessage ? "Added Sucessfully" : ""}
                  </div>
                  <div className="heading">
                    <h3>
                      Help us make your stay better. You will be arriving by?
                    </h3>
                    <span>{this.state.reservationCardName}</span>
                  </div>
                  <ul className="nav nav-tabs">
                    <li
                      className="active"
                      onClick={() => this.handletabchange("Car")}
                    >
                      <a data-toggle="tab" href="#tabCar">
                        Car
                      </a>
                    </li>
                    <li>
                      <a
                        data-toggle="tab"
                        href="#tabFlight"
                        onClick={() => this.handletabchange("Plane")}
                      >
                        Flight
                      </a>
                    </li>
                    <li>
                      <a
                        data-toggle="tab"
                        href="#tabOthers"
                        onClick={() => this.handletabchange("Others")}
                      >
                        Others
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div id="tabCar" className="tab-pane fade in active">
                      <form action>
                        <div className="form-group">
                          <label> Will you already be in the area?</label>
                          <div>
                            <label className="radio-inline">
                              <input
                                type="radio"
                                name="optradio1"
                                checked={
                                  this.state.alreadyBeInTheArea === "true"
                                }
                                value="true"
                                onChange={(e) => this.alreadyBeInTheArea(e)}
                              />
                              Yes
                            </label>
                            <label className="radio-inline">
                              <input
                                type="radio"
                                name="optradio1"
                                checked={
                                  this.state.alreadyBeInTheArea === "false"
                                }
                                value="false"
                                onChange={(e) => this.alreadyBeInTheArea(e)}
                              />
                              No
                            </label>
                          </div>
                        </div>

                        <div className="form-group">
                          <label>
                            {" "}
                            How many miles from your community will you be
                            approximately?{" "}
                          </label>
                          <div className="progress">
                            <input
                              id="typeinp"
                              className="progress-bar1"
                              type="range"
                              ref={(ref) => (this.sliderValueRef = ref)}
                              min="0"
                              max="200"
                              value={this.state.sliderValue}
                              onChange={this.onSliderChange}
                              step="1"
                            />
                            <div className="progress-value">
                              <span>{this.state.sliderValue}</span>{" "}
                              <span>200+</span>{" "}
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <label> Is your schedule flexible?</label>
                          <div>
                            <label className="radio-inline">
                              <input
                                type="radio"
                                name="optradio"
                                value="true"
                                checked={this.state.scheduleFlexible === "true"}
                                onChange={(e) => this.scheduleFlexible(e)}
                              />
                              Yes
                            </label>
                            <label className="radio-inline">
                              <input
                                type="radio"
                                name="optradio"
                                value="false"
                                checked={
                                  this.state.scheduleFlexible === "false"
                                }
                                onChange={(e) => this.scheduleFlexible(e)}
                              />
                              No
                            </label>
                          </div>
                        </div>
                        <div className="form-group">
                          <label>
                            What time do you expect you will arrive at your
                            community?{" "}
                            <span>
                              Standard check in time is between 3pm-10pm.{" "}
                            </span>{" "}
                          </label>
                          <DatePicker
                            type="text"
                            onKeyDown={(e) => e.preventDefault()}
                            className="form-control"
                            placeholderText="MM/DD/YYYY"
                            minDate={new Date()}
                            selected={this.state.standardCheckInTime}
                            name="standardCheckInTime"
                            onChange={this.standardCheckInTime}
                            dateFormat="MM/DD/YYYY hh:mm a"
                            showTimeSelect
                            timeIntervals={15}
                            id="datepickercar"
                          />
                        </div>

                        <div className="form-group">
                          <label>Any extra information we should know?</label>
                          <textarea
                            id="extrainfotext"
                            name="issues"
                            className="form-control"
                            rows={6}
                            placeholder="Please leave your message here…."
                            defaultValue={""}
                            onChange={this.extraInfoByRoad}
                            ref={(ref) => (this.extraInfoByRoadRef = ref)}
                          />
                        </div>
                      </form>
                    </div>
                    <div id="tabFlight" className="tab-pane fade">
                      <form action>
                        <div className="form-group">
                          <label>
                            Are you flying in the same day that you will be
                            moving into your apartment?
                          </label>
                          <div className="arrivalRadiobtn">
                            <label className="radio-inline">
                              <input
                                type="radio"
                                name="optradio"
                                checked={
                                  this.state.sameDayFlyAndMoveStatus === "true"
                                }
                                value="true"
                                onChange={(e) =>
                                  this.sameDayFlyAndMoveStatus(e)
                                }
                              />
                              Yes
                            </label>
                            <label className="radio-inline">
                              <input
                                type="radio"
                                name="optradio"
                                checked={
                                  this.state.sameDayFlyAndMoveStatus === "false"
                                }
                                value="false"
                                onChange={(e) =>
                                  this.sameDayFlyAndMoveStatus(e)
                                }
                              />
                              No
                            </label>
                          </div>
                        </div>
                        <div className="form-group">
                          <label> Arrival Airport </label>
                          <input
                            id="arrivalairport"
                            type="text"
                            className="form-control"
                            ref={(ref) => (this.arrivalAirportRef = ref)}
                            name="arrivalAirport"
                            placeholder="i.e. Chicago International"
                            onChange={this.setValue.bind(this)}
                          />
                        </div>
                        <div className="form-group">
                          <label>What airline will you be flying in on?</label>
                          <div className="arrivalInput">
                            <input
                              id="arrivalairline"
                              type="text"
                              ref={(ref) => (this.airlineNameRef = ref)}
                              className="form-control"
                              name="airlineName"
                              placeholder="i.e. Alaska Airlines"
                              onChange={this.setValue.bind(this)}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label> What is your flight number? </label>
                          <input
                            id="arrivalflightnumb"
                            type="text"
                            name="flightno"
                            ref={(ref) => (this.flightnoRef = ref)}
                            className="form-control"
                            placeholder="i.e. GFM104"
                            onChange={this.setValue.bind(this)}
                          />
                        </div>
                        <div className="form-group">
                          <label>
                            {" "}
                            What time is your plane scheduled to land?{" "}
                          </label>
                          <div className="">
                            <DatePicker
                              type="text"
                              id="planescheduled"
                              className="form-control"
                              ref={(ref) => (this.planeScheduleRef = ref)}
                              placeholderText="MM/DD/YYYY"
                              minDate={new Date()}
                              selected={this.state.planeSchedule}
                              name="planeSchedule"
                              onChange={this.planeSchedule}
                              dateFormat="MM/DD/YYYY hh:mm a"
                              showTimeSelect
                              onKeyDown={(e) => e.preventDefault()}
                              timeIntervals={15}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label>
                            What time do you expect to arrive at your community?{" "}
                          </label>
                          <div className="">
                            <DatePicker
                              id="arriveSchedule"
                              type="text"
                              onKeyDown={(e) => e.preventDefault()}
                              className="form-control"
                              placeholderText="MM/DD/YYYY"
                              minDate={new Date()}
                              selected={this.state.arrivalExpectation}
                              name="arrivalExpectation"
                              onChange={this.arrivalExpectation}
                              dateFormat="MM/DD/YYYY hh:mm a"
                              showTimeSelect
                              timeIntervals={15}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label>
                            Any additional information we should know?
                          </label>
                          <textarea
                            id="additionalInformation"
                            name="extraInfo"
                            ref={(ref) => (this.extraInfoRef = ref)}
                            className="form-control"
                            rows={6}
                            placeholder="Please leave your message here…."
                            defaultValue={""}
                            onChange={this.setValue.bind(this)}
                          />
                        </div>
                      </form>
                    </div>
                    <div id="tabOthers" className="tab-pane fade">
                      <form action>
                        <div className="form-group">
                          <label>
                            We understand that you may not know your arrival
                            information yet.{" "}
                            <span>
                              When do you expect to receive your itinerary? We
                              will check in with you again on that date.{" "}
                            </span>{" "}
                          </label>
                          <div className="arrivalInput">
                            <DatePicker
                              id="otherdatepicker"
                              type="text"
                              onKeyDown={(e) => e.preventDefault()}
                              className="form-control fa fa-calendar"
                              placeholderText="MM/DD/YYYY"
                              minDate={new Date()}
                              selected={this.state.expectToReceiveItinerary}
                              name="expectToReceiveItinerary"
                              onChange={this.expectToReceiveItinerary}
                              dateFormat="MM/DD/YYYY hh:mm a"
                              showTimeSelect
                              timeIntervals={15}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label>
                            Standard check in time is between 3pm-10pm. 72 hour
                            notice is required to assure your arrival time runs
                            smoothly. Any questions? Give us a call at
                            800.367.9501 or email us at{" "}
                            <a href="#"> guestserve@suiteamerica.com </a>{" "}
                          </label>
                        </div>

                        <div className="form-group">
                          <label>
                            Any additional information we should know?
                          </label>
                          <textarea
                            id="otherinfo"
                            name="extraInfoByDontKnowYet"
                            className="form-control"
                            onChange={this.extraInfoByDontKnowYet}
                            ref={(ref) =>
                              (this.extraInfoByDontKnowYetRef = ref)
                            }
                            rows={6}
                            placeholder="Please leave your message here…."
                            defaultValue={""}
                          />
                        </div>
                      </form>
                    </div>
                    {this.state.errorMessage !== "" ? (
                      <p style={{ color: "red" }}>{this.state.errorMessage}</p>
                    ) : null}

                    <div className="text-right">
                      <button
                        type="button"
                        style={{ marginRight: "7px" }}
                        className="btn white-btn"
                        id="submit"
                        onClick={this.cancelModal}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn black-btn"
                        id="submit"
                        onClick={
                          this.state.arriveBy == "Plane"
                            ? () => this.byAir()
                            : this.state.arriveBy == "Car"
                            ? () => this.byRoad()
                            : () => this.dontKnowYet()
                        }
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade savesearch"
          data-backdrop="static"
          data-keyboard="false"
          id="successModal"
          role="dialog"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  onClick={() => this.closeSucessModal()}
                  className="close"
                  aria-label=""
                >
                  <span>
                    {" "}
                    <i className="bi bi-x"></i>{" "}
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div className="alert alert-success">
                  <strong>Arrival information added successfully!</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default NewReservations;
