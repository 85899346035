/* Importing the node modules, child components, services and controllers used 
   inside Register component */
import $ from "jquery";
import AppController from "./../controller/appController";
import GuestServicesapi from './../../services/guestServicesapi';
import SuccessModal from './../common/successModal';
import authDetails from "./../common/authDetails";
import FiveStarApiServices from './../../services/fiveStarApiServices';
import SessionService from "./../../services/sessionService";
import MainController from './../controller/mainController';
import GuestController from './../controller/guestController';
import moment from "moment";
import { withRouter } from "react-router-dom";
import Loader from "../common/loader";

/* Register Component initialization */
class GuestForgotPassword extends React.Component {
  /* Initializing objects of its Register class */
  constructor(props) {
    super(props)
    /* Register Component State variables Initialization */
    this.state = {
      email: '',
      password: '',
      confirmPassword: '',
      message: '',
      color: '',
      loader: false,
      userData: [],
      auth0Response: '',
      accessToken: '',
      expired: true,
      guestSessionResponse: {},
      roleId: 0,
      hideMenu: false,
      auth0UserExist: "",
      signoutLoader:false
    }
    this.setValue = this.setValue.bind(this);
    this.guestforgotpassword = this.guestforgotpassword.bind(this);
    this.checkExpiration = this.checkExpiration.bind(this);
    this.clearAlerts = this.clearAlerts.bind(this);
  }
  /* It is invoked immediately before mounting occurs */
  async componentWillMount() {
    AppController.removeLocalStorageData();
    $('html, body').animate({ scrollTop: 0 });
    AppController.stickyTitles();
    await this.checkExpiration(this.props.match.params.timeoutkey)
    await this.getUserData(this.props.match.params.email)

  }
  async clearAlerts(e) {
    e.preventDefault();
    this.setState({ message: '', color: '', loader: false });
  }

  async checkExpiration(key) {
    const requestObj = {
      "authkey": key,
      "sourcetype": "guest",
      "requestforkey": false
    }
    const TimeOut = await FiveStarApiServices.GetTimeoutKey(requestObj)
    if (TimeOut.error == "0") {
      this.setState({ expired: true })
    }
    else {
      this.setState({ expired: false })
    }

  }
  async getUserData(email) {
    const login = {
      email: Base64.decode(email)
    }
    const userData = await GuestServicesapi.getSingleGuestDetails(login)
    this.setState({ userData: userData })
  }
  /* Update the state object of the form */
  setValue(field, e) {
    var object = {};
    object[field] = e.target.value;
    this.setState(object);
  }

  async guest() {
    MainController.removeClassOnNavCollapse();
    const guestLoginResponse = await GuestController.guestLoginResponse();
    await this.setState({ userData: guestLoginResponse });
    var client = await SessionService.SessionGet({ type: 'client' });
    if (client.error == 0) {
      await this.setState({
        roleId: (client.roleId != undefined && Object.keys(client).length > 0) ? client.roleId : 0,
        ClientType: (client.ClientType != undefined && Object.keys(client).length > 0 ? client.ClientType : '')
      })
    }
    const GetResponse = await SessionService.SessionGet({ type: 'guest' });
    if (GetResponse.error == 0) {
      await this.setState({
        roleId: (GetResponse.roleId != undefined && Object.keys(GetResponse).length > 0) ? GetResponse.roleId : 0,
        ClientType: (GetResponse.ClientType != undefined && Object.keys(GetResponse).length > 0 ? GetResponse.ClientType : '')
      })
    }
    await this.setState({ guestSessionResponse: GetResponse });
    if (GetResponse.roleId == 2) {
     
      var guestEmailObj = {
        guestEmail: GetResponse.GuestEmail,
        status: "active"
      }
      const GetOrdersByGuestEmailResponse = await GuestServicesapi.GetOrdersByGuestEmail(guestEmailObj)
      if (GetOrdersByGuestEmailResponse.message.Orders.length == 0) {
        this.setState({ hideMenu: true })
      } else {
        var currentDate = moment().format("YYYY-MM-DD");
        var navList = this.state.navigationList
        var orderUIdObj = {
          OrderUId: GetOrdersByGuestEmailResponse.message.Orders[0].OrderUId
        }
        GuestServicesapi.GetOrderInfoById(orderUIdObj).then((getOrderInfo) => {
          var MoveInDate = moment(getOrderInfo.message.orderInfo.MoveInDate).format("YYYY-MM-DD");
          var MoveOutDate = moment(getOrderInfo.message.orderInfo.MoveOutDate).format("YYYY-MM-DD");
          if (MoveInDate == currentDate) {
            GuestServicesapi.GetInOutOrdersLog({ orderUid: orderUIdObj.OrderUId }).then((GetInOutOrdersResponse) => {
              if (GetInOutOrdersResponse.length > 0) {
                var checkIn = GetInOutOrdersResponse.filter(e => e.Type == "IN")
                if (checkIn.length == 0) {
                  navList.push({ name: 'CHECK IN', url: '/check-in' })
                  this.setState({ navigationList: navList })
                } 
              } else {
                navList.push({ name: 'CHECK IN', url: '/check-in' })
                this.setState({ navigationList: navList })
              }
            })
          }
          if (MoveOutDate == currentDate) {
            GuestServicesapi.GetInOutOrdersLog({ orderUid: orderUIdObj.OrderUId }).then((GetInOutOrdersResponse) => {
              if (GetInOutOrdersResponse.length > 0) {
                var checkOut = GetInOutOrdersResponse.filter(e => e.Type == "OUT")
                if (checkOut.length == 0) {
                  navList.push({ name: 'CHECK OUT', url: '/check-out' })
                  this.setState({ navigationList: navList })
                } 
              } else {
                navList.push({ name: 'CHECK IN', url: '/check-in' })
                this.setState({ navigationList: navList })
              }
            })
          }

        })
      }
    }
  }

  async guestforgotpassword(e) {
    e.preventDefault();
    let regularExpression = /^(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@^_`{|}~]{10,16}$/;
    let minNumberofChars = 10;
    let maxNumberofChars = 16;
    let newPassword = this.state.password;
    let confirmPassword = this.state.confirmPassword;
    if (newPassword.length < minNumberofChars || newPassword.length > maxNumberofChars) {
      this.setState({ message: 'yes', color: 'red' })
    } else if (this.state.password != this.state.confirmPassword) {
      this.setState({ message: 'Password & Confirm password should not match', color: 'red' })
    }
    else if (regularExpression.test(newPassword) && regularExpression.test(confirmPassword)) {
      this.setState({ message: '', loader: true })
      await fetch("https://" + authDetails.authCredentails().hostName + "/oauth/token", {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          grant_type: "client_credentials",
          client_id: authDetails.authCredentails().clientId,
          client_secret: authDetails.authCredentails().clientSecret,
          audience: "https://" + authDetails.authCredentails().hostName + "/api/v2/",

        })

      })
        .then(res => res.json())
        .catch(error => console.log('Error:', error))
        .then(response => this.setState({ accessToken: response }))

      await fetch("https://" + authDetails.authCredentails().hostName + "/api/v2/users-by-email?email=" + Base64.decode(this.props.match.params.email), {
        method: 'GET',
        headers: { Authorization: "Bearer " + this.state.accessToken.access_token },

      })
        .then(res => res.json())
        .catch(error => console.log('Error:', error))
        .then(response => this.setState({ auth0UserExist: response }))
      await fetch("https://" + authDetails.authCredentails().hostName + "/api/v2/users/" + this.state.auth0UserExist[0].user_id, {
        method: 'PATCH',
        headers: { 'content-type': 'application/json', Authorization: "Bearer " + this.state.accessToken.access_token },
        body: JSON.stringify({
          password: this.state.password,
          connection: "Username-Password-Authentication"
        })
      })
        .then(res => res.json())
        .catch(error => console.log('Error:', error))
        .then(response => {
          this.setState({ auth0Response: response })
        })
      const register = {
        guestEmailId: Base64.decode(this.props.match.params.email),
        guestPwd: this.state.auth0Response.identities[0].user_id,
        guestId: this.state.userData.WebInfo.Id
      }
       await GuestServicesapi.saveGuestUserInfo(register)
      await this.setState({ message: this.props.location.type == "changePassword" ? 'Your password has been changed successfully.' : 'Your password has been reset successfully.', color: 'green', loader: false })
      setTimeout(function () {
        // this.setState({
        //     login: true,
        //     register: false,
        //     email: '',
        //     password: '',
        //     message: '',
        //     onNext: false
        // })
        location.href = "/reservations";
      }.bind(this), 2000);
    } else {
      this.setState({ message: 'yes', color: 'red' })
    }
  }


  componentDidMount() {
    this.guest()
  }

  async logout() {
    this.setState({signoutLoader:true})
    if(this.state.roleId == 3){
      await GuestServicesapi.ClientUsersLogOut({ sessionId: localStorage.getItem('guestSession') })
    }else{
      await GuestServicesapi.GuestUsersLogOut({ sessionId: localStorage.getItem('guestSession') })
    }
      
    await localStorage.removeItem('guestSession');
    if (this.state.roleId == 2) {
      const userData = await SessionService.SessionDestory({ type: 'guest' });
      if(userData.error == 0){
        location.href = "https://" + authDetails.authCredentails().hostName + "/v2/logout?client_id=" + authDetails.authCredentails().clientId + "&returnTo=" + authDetails.authCredentails().redirectUri
      }
    }
    if (this.state.roleId == 3) {
      const userData = await SessionService.SessionDestory({ type: 'client' });
      if(userData.error == 0){
        location.href = '/celogin'
      }  
    }
    this.setState({signoutLoader:false})
  }

  /* It is invoked to return html content */
  render() {
    return (
      <div className={this.props.location.type == "changePassword" ? "guest-Ui" : "corporateHousing_Updates bg-grey"}>

        {/* <GuestHeader/> */}
        {/* <GuestHeaderNav /> */}
        {this.props.location.type == "changePassword" &&
        <div>
        {this.state.signoutLoader&&<div className="logoutLoader"><Loader/></div>}
          <nav className="navbar navbar-expand-lg navbar-light ">
            <div className="container-fluid">
              <a className="navbar-brand" href="#">
                <img src="../../../images/orderImages/logo.png" alt="logo" width="100%" onClick={() => window.location.href = '/'} />
              </a>
              <button type="button" className="navbar-toggle navbar-toggler collapsed" data-toggle="collapse"
                data-target="#navbar" aria-expanded="false" aria-controls="navbar">
                <span className="navbar-toggler-icon"></span>
              </button>

              <div className="collapse navbar-collapse" id="navbar">
                <ul className="navbar-nav m-auto">
                  <li className="nav-item ">
                    <a className="nav-link" aria-current="page" href="/reservations">My Reservations</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/your-location">Explore Your Location</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/guest-services">Service Request</a>
                  </li>
                </ul>
                <div className="nav-dropsDown nav-btns">
                <ul>
                  <li className="drop userAccount">
                    <a href="#"> <span className="user"> <img src="../../../images/orderImages/user-circle.svg" alt="" width="100%" />
                    </span> <span>{this.state.guestSessionResponse.GuestFirstName} <i>{this.state.guestSessionResponse.GuestEmail}</i> </span> </a>
                    <div className="dropdownContain">
                      <div className="dropOut">
                        <div className="triangle" />
                        <ul>
                          <li onClick={() => this.logout()}>Sign Out</li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
           
              </div>
               </div>
          </nav>
          </div>
        }


        <div className={this.props.location.type == "changePassword"?"login-form-SA guestUiChangePass":"login-form-SA"}>
          <div className="l-form">
            <div className="item item2">
              <div className="card-body">
                <h1>{this.props.location.type == "changePassword" ? 'Change Your Password' : 'Reset Your Password'}</h1>

                {this.state.expired ? <div>
                  {this.state.message ? <span style={{ color: this.state.color, textAlign: 'center' }}>
                    <div className={this.state.color == 'red' ? "alert alert-danger alert-dismissible" : "alert alert-success alert-dismissible"}>
                      <a href="#" className="close" data-dismiss="alert" aria-label="close" onClick={(e) => this.clearAlerts(e)}>&times;</a>

                      {this.state.message == 'yes' ?

                        <div>
                          <ul className="alert-passwordtype">
                            <li>- Minimum of 10 Characters Length</li>
                            <li>- At least one Lower Case Letter (a-z)</li>
                            <li>- At least one Upper Case Letter (A-Z)</li>
                            <li>- At least one Number (0-9)</li>
                            <li>-At least one Special Character (!@#$%^&*)</li>

                          </ul>
                        </div>
                        :
                        <div>{this.state.message}</div>
                      }
                    </div>
                  </span> : ''}

                  <div>
                    {this.props.location.type !== "changePassword" &&
                      <div className="form-group">
                        <input type="text" className="form-control" id="email" placeholder="Email" value={Base64.decode(this.props.match.params.email)} readOnly />
                      </div>
                    }
                    <div className="form-group icononinput">
                      <input type="password" className="form-control" placeholder="Password" value={this.state.password}
                        onChange={(e) => this.setValue('password', e)} />

                    </div>

                    <div className="form-group icononinput">
                      <input type="password" className="form-control" placeholder="Confirm Password" value={this.state.confirmPassword} onChange={(e) => this.setValue('confirmPassword', e)} />

                    </div>
                  </div>

                  {this.state.loader?
                    <Loader/>
                    :
                    <div>

                      <div>
                        {this.props.location.type == "changePassword" ?
                          <button type="button" className="btn btn-primary" onClick={(e) => this.guestforgotpassword(e)}>Change Password</button> :
                          <button type="button" className="btn btn-primary" onClick={(e) => this.guestforgotpassword(e)}>Reset Password</button>
                        }
                      </div>
                    </div>
                  }
                </div>
                  : <div><h1>Your Email is Expired</h1></div>}


              </div>
            </div>
            {/* <div className="item item3" >
              <div className="right-side">
                 <h1>Hello, Guest</h1>
                <p>Already registered?</p>
                <a href="/guest-login" className="btn btn-primary">Sign In</a>
              </div>
              
          </div> 
          <div className="m-login-registercls">
          Already registered?  <a href="/guest-login">Sign In</a>

          </div>  */}
          </div>
        </div>
        {/* <SubFooter/> */}
        {/* <Footer/> */}
        <SuccessModal />
      </div>






    );
  }
}
export default withRouter(GuestForgotPassword);