import MainController from "../../controller/mainController";
import SubFooter from "../../common/subFooter";
import GuestHeader from '../../common/guestHeader';
import Footer from '../../common/footer';
import MediaFooter from "./common/mediaFooter";
import MetaTags from '../../common/metaTags';
import ServerMetaTags from '../../../server/metaTags';

/* MediaWhatWeDo Component initialization */
class MediaWhatWeDo extends React.Component {
    /* Initializing objects of its MediaContact class */
    constructor(props) {
        super(props);
    }
    /* It is invoked immediately before mounting occurs */
    componentWillMount() {
        MainController.getInitialInformation();
    }
    /* It is invoked to return html content */
    render() {
        const metaData = ServerMetaTags.urlString.getMetaData(this.props.match.url);
        return (
            <div>
            <MetaTags 
             metaTitle = {metaData.title}
             metaDesription = {metaData.description}
             metaUrl = {metaData.canonical} />
             <GuestHeader/> 
            <div className="cover-tophd-ht company_ht_cover"></div>
             <div className="quote-section bg-white">
                <div className="container mediakit">
                   <div className="plaine-text global-coverage-wrap col-md-10 col-md-offset-1 wow fadeInUp" data-wow-delay="0.5s">
                   <h2 className="mrg-btm-50 color-red">WHAT WE DO</h2>
                        <p>SuiteAmerica strives to set an unbeatable standard for customer satisfaction through our caring, personalized 
                        services; allowing temporary assignments or relocations to be as stress free and effortless as possible. We 
                        offer Nationwide Coverage of fully furnished luxury homes and apartments all while providing centralized 
                        reservations and guest services to serve all time zones. Our Emergency Services are available 24/7 to ensure 
                        our guests get the service they need when they need it. In addition, we provide easy online account access 
                        24/7, for reservation information, service requests, optional upgrades and much more. Also available on our 
                        convenient iPad and iPhone Apps. </p>
    <p>From personal transition services to guest meet & greets, we are dedicated to helping our guests get settled 
    into their new homes. A wide range of optional furniture & housewares packages, as well as customized 
    amenities are available to all our guests. Providing a customized area guide full of information on local 
    neighborhoods, shops, attractions, weather trends and much more, is just one of SuiteAmerica's key features 
    in assuring each transition is seamless.</p>
                   </div>
                 <MediaFooter />
                 </div>
              </div>   
             <SubFooter />
             <Footer/>
            </div>
        );
    }
}
export default MediaWhatWeDo;