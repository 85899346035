import GuestHeader from "../../common/guestHeader";
import AppController from "../../controller/appController";
import Footer from "../../common/footer";
import SubFooter from "../../common/subFooter";
class MyloParking extends React.Component{
    constructor(props){
        super(props)
        this.state={

        }
    }
    async componentWillMount(){
        AppController.removeLocalStorageData();
        $('html, body').animate({scrollTop: 0});
        AppController.stickyTitles();
      }
render(){
    return(
        <div>
        <GuestHeader/>
        <div className="dean-parkingpage sa-parkingpage">
    <div className="page_banner">
        <div className="bannerimg">
        <img className="width100per" src="assets/images/dean-parking/mylo-parking-banner.jpg" />
        </div>
        <div className="banner_text">
            <h2><span>PARKING AT </span> MYLO </h2>
            <h3>SUITE<span>AMERICA</span></h3>
        </div>
    </div>
    <div className="banner_bootm">
        <div className="item">
        <img  src="/assets/newUI-images/dean/myloP3.jpg" />
        </div>
        <div className="item item_text">
        <img src="assets/images/dean-parking/parking2.jpg" />
            <div className="textonimage">
                <p>EASY ACCESS VIA HALFORD AVENUE JUST OFF EL CAMINO REAL .</p>
                <p>HEAD <b>EAST</b>  DOWN EL CAMINO REAL.</p>
                <p>TURN <b>RIGHT</b>  ONTO HALFORD AVENUE.</p>
                <p>MYLO WILL BE ON THE <b>LEFT.</b> </p>
                <p>TURN <b>LEFT</b> INTO THE PARKING LOT JUST PAST THE CHEVRON STATION.</p>
                <p>  THE LOT HAS A GATE AROUND IT THAT IS ALWAYS OPEN. THERE IS NOT ASSIGNED PARKING YET, SO PARK WHEREVER YOU LIKE! </p>
                <p>  WALK TOWARDS THE ARROW TO EXIT THE PARKING LOT AND FOLLOW THE SIGNS LEADING UP TO THE SUITEAMERICA OFFICE, <b>SUITE 1821</b> .</p>
                <p>THE OFFICE IS A CONVERTED SUITE. YOU’LL WALK THROUGH THE FRONT GATE OUTSIDE AND INTO THE OFFICE.</p>
            </div>
        </div>
        <div className="item">
        <img src="/assets/newUI-images/dean/myloP1.jpg" />
        </div>
        <div className="item">
        <img src="assets/images/dean-parking/myloP2.jpg" />
        </div>
    </div>
    <div className="parking-information">
        <div className="container">
            <h1>ONCE YOU’VE PARKED</h1>
            <ul>
                <li>Your SuiteAmerica representative will meet you in the SuiteAmerica office, located in Suite 1821. </li>
                <li> They will escort you to your suite and give you a tour of your new home. Your representative will show you into your suite, make sure you’re comfortable and have everything you need. They’ll also provide details about things like picking up your mail and where to take out the trash.</li>

            </ul>
        </div>
    </div>
</div>
<SubFooter/>
	<Footer/>
        </div>
    )
}
}
export default MyloParking;