/* Importing the node modules, child components, services and controllers used 
   inside LockheedHome component */ 
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import LockheedHeader from "../../common/lockheedHeader";
   import {Link} from 'react-router-dom';
   import FAQ from "./common/faq";
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   /* LockheedHome Component initialization */
   class LockheedHome extends React.Component {
       /* Initializing objects of its LockheedHome class */
       constructor(props) {
           super(props);
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
            MainController.getInitialInformation();
        }
       /* It is invoked to return html content */
       render() {
           return (
            <div className="corporateHousing_Updates">
                <GuestHeader/>
                <header className="codeconveyHeader cc_image_text_main corporate-h">
                    <img className="img-responsive" src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/lockheed/LANDING-PG_iStock.jpg" width="100%" alt=""  />
                    <div className="wow fadeInUp img-on-text top35 width700 text-center img-on-text-center img-on-text-center-w company_bannertxt" data-wow-delay="0.5s">
                        <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/lockheed/LM_logo_grey.png" className="nike_img" style={{width: "250px"}} alt="" />
                        <h1 className="lockheed-color">SuiteAmerica is Proud to Offer Temporary Housing to Lockheed Martin Employees.</h1>
                        <p className="lockheed-color">Whether You’re an Individual or a Group, 
                            We Can Take Care of Your Housing Needs.
                        </p>
                    </div>
                </header>
                <LockheedHeader />
                <div className="quote-section bg-white">
                    <div className="container">
                        <div className=" text-center col-md-10 col-md-offset-1 wow fadeInUp company_p" data-wow-delay="0.5s">
                            <p>These fully furnished and equipped apartments are offered at daily rates and are larger and better suited for long 
                            term stay as opposed to hotels. Averaging 1,000 sq. ft., SuiteAmerica’s corporate apartments are fully furnished 
                            and offer the amenities of a home, making long term stays as comfortable as possible. As a preferred vendor to 
                            Lockheed Martin, SuiteAmerica is here to help you with your temporary housing needs and can take your reservations directly.
                            </p>
                            <h1 className="gotham-book mrg-top-80 mrg-btm-80">STAYING WITH SUITEAMERICA IS EASY!</h1>
                        </div>
                    </div>
                    <div className="who_weare_text service_ch_new company_p_height">
                        <div className="container">
                            <div className="row rows">
                            <div className="col-md-6 col-sm-6">
                                <div className="wow fadeInUp company_p dual_quote" data-wow-delay="0.5s">
                                    <p>Visit our locations page to see the various 
                                        communities available for your temporary stay 
                                        in Lockheed Martin’s key areas.
                                    </p>
                                    <Link to="/company/lockheed-martin/locations" className="box-slide-btn">VIEW LOCATIONS</Link>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <div className="wow fadeInUp company_p dual_quote" data-wow-delay="0.5s">
                                    <p>Click the Reserve Now button to make a reservation or request that a SuiteAmerica representative contact you.
                                    </p>
                                    <Link to="/company/lockheed-martin/reservation-form" className="box-slide-btn">RESERVE NOW</Link>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid bg-grey">
                    <div className="container">
                        <FAQ />
                    </div>
                </div>
                <SubFooter/>
                <Footer/>
            </div>
           );
       }
   }
   export default LockheedHome;