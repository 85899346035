import React from "react";
import SideBar from "./common/SideBar";
import NewHeader from "./common/Header";
import NewFooter from "./common/Footer";

const BookItLayout = ({ children }) => {
  return (
    <>
      <div id="wrapper" className="sa-admin">
        <SideBar />

        <div className="content-page">
          <div className="content">
            <NewHeader />
            {children}
          </div>
          <NewFooter />
        </div>
      </div>
    </>
  );
};

export default BookItLayout;
