class InspectionFormMaintenance extends React.Component {
    constructor(props) {
        super(props);
        this.setChecked = this.setChecked.bind(this);
        this.setValue = this.setValue.bind(this);
    }
    setChecked(field, e) {
        var object = {};
        object[field] = e.target.value;
        this.setState(object);
        this.props.setChecked(object)
    }
    setValue(field, e) {
        var object = {};
        object[field] = e.target.value;
        this.setState(object);
        this.props.setValue(object)
    }
    render() {
        return (
            <div>
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <h4 className="panel-title">
                            <a
                                className="collapsed"
                                data-toggle="collapse"
                                data-parent="#accordion"
                                href="#housewares"
                                aria-expanded="true"
                                aria-controls="collapse3">
                                Maintenance
                            </a>
                        </h4>
                    </div>
                    <div id="housewares" className="panel-collapse collapse">
                        <div className="panel-body">
                            <div className="profile-opener">
                                <div className="row">
                                    <div className="col-sm-6 col-md-6">
                                        <div className="row radiosec">
                                            <div className="col-sm-12">
                                                <label className="col-sm-3">Heater</label>

                                                <div className="col-sm-3">
                                                    <label className="custom_radio radio-inline">Ok,fixed
                                                        <input
                                                            type="radio"
                                                            name="80"
                                                            value="Ok,fixed"
                                                            onChange={(e) => this.setChecked('heater', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <label className="custom_radio radio-inline">Call Complex
                                                        <input
                                                            type="radio"
                                                            name="80"
                                                            value="Call Complex"
                                                            onChange={(e) => this.setChecked('heater', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="row radiosec">
                                            <div className="col-sm-12">
                                                <label className="col-sm-3">Air Conditioner</label>

                                                <div className="col-sm-3">
                                                    <label className="custom_radio radio-inline">Ok,fixed
                                                        <input
                                                            type="radio"
                                                            name="81"
                                                            value="Ok,fixed"
                                                            onChange={(e) => this.setChecked('airConditioner', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <label className="custom_radio radio-inline">Call Complex
                                                        <input
                                                            type="radio"
                                                            name="81"
                                                            value="no"
                                                            onChange={(e) => this.setChecked('airConditioner', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-sm-6 col-md-6">
                                        <div className="row radiosec">
                                            <div className="col-sm-12">
                                                <label className="col-sm-3">Air Conditioner Filter</label>

                                                <div className="col-sm-3">
                                                    <label className="custom_radio radio-inline">Ok,Fixed
                                                        <input
                                                            type="radio"
                                                            name="82"
                                                            value="Ok,Fixed"
                                                            onChange={(e) => this.setChecked('airConditionerFilter', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <label className="custom_radio radio-inline">Call Complex
                                                        <input
                                                            type="radio"
                                                            name="82"
                                                            value="Call Complex"
                                                            onChange={(e) => this.setChecked('airConditionerFilter', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="row radiosec">
                                            <div className="col-sm-12">
                                                <label className="col-sm-3">Cable</label>

                                                <div className="col-sm-3">
                                                    <label className="custom_radio radio-inline">Ok
                                                        <input
                                                            type="radio"
                                                            name="83"
                                                            value="Ok"
                                                            onChange={(e) => this.setChecked('cable', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <label className="custom_radio radio-inline">Not Working
                                                        <input
                                                            type="radio"
                                                            name="83"
                                                            value="Not Working"
                                                            onChange={(e) => this.setChecked('cable', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-sm-6 col-md-6">
                                        <div className="row radiosec">
                                            <div className="col-sm-12">
                                                <label className="col-sm-3">Internet Connection</label>

                                                <div className="col-sm-3">
                                                    <label className="custom_radio radio-inline">Ok
                                                        <input
                                                            type="radio"
                                                            name="84"
                                                            value="Ok"
                                                            onChange={(e) => this.setChecked('internetConnection', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <label className="custom_radio radio-inline">Not Working
                                                        <input
                                                            type="radio"
                                                            name="84"
                                                            value="Not Working"
                                                            onChange={(e) => this.setChecked('internetConnection', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="row radiosec">
                                            <div className="col-sm-12">
                                                <label className="col-sm-3">Patio/Deck</label>

                                                <div className="col-sm-3">
                                                    <label className="custom_radio radio-inline">Ok
                                                        <input
                                                            type="radio"
                                                            name="85"
                                                            value="Ok"
                                                            onChange={(e) => this.setChecked('patioDeck', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <label className="custom_radio radio-inline">Needs to be Cleaned
                                                        <input
                                                            type="radio"
                                                            name="85"
                                                            value="Needs to be Cleaned"
                                                            onChange={(e) => this.setChecked('patioDeck', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-sm-6 col-md-6">
                                        <div className="row radiosec">
                                            <div className="col-sm-12">
                                                <label className="col-sm-3">Utilities</label>

                                                <div className="col-sm-3">
                                                    <label className="custom_radio radio-inline">Ok,Fixed
                                                        <input
                                                            type="radio"
                                                            name="86"
                                                            value="Ok,Fixed"
                                                            onChange={(e) => this.setChecked('utilities', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <label className="custom_radio radio-inline">Not Working
                                                        <input
                                                            type="radio"
                                                            name="86"
                                                            value="Not Working"
                                                            onChange={(e) => this.setChecked('utilities', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="row radiosec">
                                            <div className="col-sm-12">
                                                <label className="col-sm-3">Cord Management</label>

                                                <div className="col-sm-3">
                                                    <label className="custom_radio radio-inline">Ok
                                                        <input
                                                            type="radio"
                                                            name="87"
                                                            value="Ok"
                                                            onChange={(e) => this.setChecked('cordManagement', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <label className="custom_radio radio-inline">Need to be replaced
                                                        <input
                                                            type="radio"
                                                            name="87"
                                                            value="Need to be replaced"
                                                            onChange={(e) => this.setChecked('cordManagement', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div className="row">

                                    <div className="col-sm-6 col-md-6">
                                        <div className=" radiosec">

                                            <label className="col-sm-3">Lockbox</label>

                                            <div className="col-sm-5">
                                                <label className="custom_radio radio-inline">Missing Call EDH
                                                    <input
                                                        type="radio"
                                                        name="88"
                                                        value="Missing Call EDH"
                                                        onChange={(e) => this.setChecked('lockbox', e)}/>
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                            <div className="col-sm-4">
                                                <label className="custom_radio radio-inline">No Keys
                                                    <input
                                                        type="radio"
                                                        name="88"
                                                        value="No Keys"
                                                        onChange={(e) => this.setChecked('lockbox', e)}/>
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>

                                            <div className="col-sm-5 col-sm-offset-3">
                                                <label className="custom_radio radio-inline">Wrong Code
                                                    <input
                                                        type="radio"
                                                        name="88"
                                                        value="Wrong Code"
                                                        onChange={(e) => this.setChecked('lockbox', e)}/>
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>

                                        </div>

                                    </div>

                                    <div className="col-sm-6 col-md-6">
                                        <div className="row radiosec">
                                            <div className="col-sm-12">
                                                <label className="col-sm-3">Guest
                                                </label>

                                                <div className="col-sm-8">
                                                    <label className="custom_radio radio-inline">Did Not Move Out
                                                        <input
                                                            type="radio"
                                                            name="89"
                                                            value="Did Not Move Out"
                                                            onChange={(e) => this.setChecked('guest', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-sm-6 col-md-6">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Rooms that need Light Bulbs"
                                            value={this.props.roomBulbs}
                                            onChange={(e) => this.setValue('roomBulbs', e)}/>

                                        <div className="row">
                                            <div className="col-sm-6"></div>
                                            <div className="col-sm-6"></div>
                                        </div>

                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="# of Keys in Unit"
                                            value={this.props.keysInUnit}
                                            onChange={(e) => this.setValue('keysInUnit', e)}/>

                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-sm-12 col-md-12">
                                        <textarea
                                            className="form-control"
                                            placeholder="Comments/Notes"
                                            value={this.props.mainteinanceComments}
                                            onChange={(e) => this.setValue('mainteinanceComments', e)}
                                            rows="4"
                                            cols="5"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default InspectionFormMaintenance