const CommunityBookingInfo=(props)=>{
    const {community} = props;
    return(
      <div className="col-sm-4 col-sm-push-8">
      <div className="clearfix"></div>
      <div className="widget_head">
         <h4 className="widget-title">{community.community_name}</h4>
      </div>
      <div className="community-item">
         <div className="img-div">
            <img src={community.image_name} alt="" />
            <div className="img-hover-dtls">
               {(community.walkScore != '' && community.walkScore != undefined && community.walkScore != null) ?
               <div className="walkscore pull-right">
                  <div className="arrow_box">{community.walkScore == '' ? 0 : community.walkScore}</div>
                  <span>Walk Score</span>
               </div>
               : ''}
            </div>
         </div>
         <div className="community-item-dtls">
            <p>{_.startCase(_.toLower(community.address))}, {_.startCase(_.toLower(community.city_name))}, {_.startCase(_.toLower(community.state_name))}</p>
         </div>
         <div className="mrg-top-30">
            <ul className="bedroom_list list-inline in-dtls pamt_bedroom_list">
               {community.STU == 0 ? '' :
               <li>
                  <label className="custom_checkbox radio-inline">
                  <input type="radio" name="1" value="studio" disabled={community.STU == 0 ? true : false} checked={props.status == 'STU' ? true : false} onChange={() => props.GetInvoiceAmount(community,[{adminDailyRates:community.CostSTU,community_id:community.community_id,BookedType:'STU'}])} />
                  <span className={props.status == 'STU' ? "checkmark" : 'unCheck'}></span>
                  </label>
                  <img src={s3BucketUrl+"landing-images/studiobed.png"} />
                  <p>Studio  </p>
                  <span className="badge">{community.STU}</span>
                  <div className="price">${community.CostSTU}</div>
               </li>
               }
               {community.Avail1X == 0 ? '' :
               <li>
                  <label className="custom_checkbox radio-inline">
                  <input type="radio" name="1" value="1bedroom" disabled={community.Avail1X == 0 ? true : false} checked={props.status == '1X' ? true : false} onChange={() => props.GetInvoiceAmount(community,[{adminDailyRates:community.Cost1x,community_id:community.community_id,BookedType:'1X'}])} />
                  <span className={props.status == '1X' ? "checkmark" : 'unCheck'}></span>
                  </label>
                  <img src={s3BucketUrl+"landing-images/singlebedroom.png"} />
                  <p>1 Bedroom</p>
                  <span className="badge">{community.Avail1X}</span>
                  <div className="price">${community.Cost1x}</div>
               </li>
               }
               {community.Avail2X == 0 ? '' :
               <li>
                  <label className="custom_checkbox radio-inline">
                  <input type="radio" name="1" value="2bedroom" disabled={community.Avail2X == 0 ? true : false} checked={props.status == '2X' ? true : false} onChange={() => props.GetInvoiceAmount(community,[{adminDailyRates:community.Cost2x,community_id:community.community_id,BookedType:'2X'}])} />
                  <span className={props.status == '2X' ? "checkmark" : 'unCheck'}></span>
                  </label>
                  <img src={s3BucketUrl+"landing-images/doublebedroom.png"} />
                  <p>2 Bedroom</p>
                  <span className="badge">{community.Avail2X}</span>
                  <div className="price">${community.Cost2x}</div>
               </li>
               }
               {community.Avail3X == 0 ? '' :
               <li>
                  <label className="custom_checkbox radio-inline">
                  <input type="radio" name="1" value="3bedroom" disabled={community.Avail3X == 0 ? true : false} checked={props.status == '3X' ? true : false} onChange={() => props.GetInvoiceAmount(community,[{adminDailyRates:community.Cost3x,community_id:community.community_id,BookedType:'3X'}]) } />
                  <span className={props.status == '3X' ? "checkmark" : 'unCheck'}></span>
                  </label>
                  <img src={s3BucketUrl+"landing-images/triplebedroom.png"} />
                  <p>3 Bedroom</p>
                  <span className="badge">{community.Avail3X}</span>
                  <div className="price">${community.Cost3x}</div>
               </li>
               }
            </ul>
         </div>
      </div>
      <div>
         <div className="widget_head">
            <h4 className="widget-title ">Payment Summary</h4>
         </div>
         <div className="widget">
            {props.paymentsummary ?
            <div className='widget_body pmnt_summary' style={{ fontSize: '20px', textAlign: 'center' }} >
            <i className="fa fa-spinner fa-spin" /> Please wait ...!
         </div>
         :
         <div className="widget_body pmnt_summary">
            <p> Subtotal : <span>${Number(props.getInvoiceData.InvoiceAmount).toFixed(2)}</span></p>
            <p className="mrg-top-2"> Tax <i  className="fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="The actual tax amount will be calculated based on the applicable state and local sales taxes when your order is shipped."></i> : <span>${Number(props.getInvoiceData.TotalTax).toFixed(2)}</span></p>
            <p className="mrg-btm-0 t-amt"> Total Amount:<span>${Number(props.getInvoiceData.TotalInvoiceAmount).toFixed(2)}</span></p>
            <p className="mrg-btm-0 mrg-top-20 text-danger">* You can cancel the reservation for no charge before 3 days of arrival date. (<a >Learn more</a>)
            </p>
         </div>
         }
      </div>
   </div>

   </div>
    )
    }
    export default CommunityBookingInfo;
    