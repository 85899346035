/* Importing the node modules, child components, services and controllers used 
   inside EmployeeHeader component */
   import {Link} from 'react-router-dom';
   import $ from "jquery";   
   import Conference from '../../services/conference';
   import SessionService from "../../services/sessionService";
   import CallModal from './callModal'
   import LoginModal from '../controller/loginmodalController';
   import BookNowModal from '../controller/booknowModal';
   import HambergerMenu from './hambergerMenu.json';
   import AppController from '../controller/appController';
   import SiteLoader from './siteLoader'; 
   /* EmployeeHeader Component initialization */
   class EmployeeHeader extends React.Component {
       /* Initializing objects of its EmployeeHeader class */
       constructor(props) {
           super(props)
           /* EmployeeHeader Component State variables Initialization */
           this.state = {
               name: '',
               company: '',
               location: '',
               phone: '',
               email: '',
               password: '',
               bookingDetails: {},
               BookingData: {},
               iconStatus: false,
               login: false,
               register: false,
               forgotpassword: false,
               message: '',
               onNext: false,
               color: '',
               loader: false,
               menuId: 0,
               clickedMenuId: 0,
               guest:true,
               client:false,
               navIndex: -1,
               phoneNo:'',
               resLocation: '',
               resName: '',
               resCompany: '',
               resEmail: '',
               resPhone: '',
               resPlace:'',
               navigation: false,
               hambergerMenu: HambergerMenu 
           }
           this.navigation = this.navigation.bind(this);
           this.changeIcon = this.changeIcon.bind(this);
           this.clearPhone = this.clearPhone.bind(this);
           this.menuClick = this.menuClick.bind(this);
           this.logout = this.logout.bind(this);
           this.removeNavHover = this.removeNavHover.bind(this);
           this.closePopup = this.closePopup.bind(this);   
       }
   /* It is invoked immediately after a component is mounted */
       componentDidMount() {
           $(document).ready(function () {
              $(".cc_mega_login2").click(function () {
                    $('.navbar-collapse').removeClass('in');
              });
              $(window).scroll(function () {
                   if ($(this).scrollTop() != 0) {
                      $('li.dd_booknow').removeClass('open');
                      $('li.new_userlog').removeClass('open');
                    }
              });
          });
          $("body").removeClass('bodytrns320l no-scroll profile-open')
          AppController.removeSiteLoader();
       }   
        /* To update the state values for the input fields */
        setValue(field, e) {
            var object = {};
            object[field] = e.target.value;
            this.setState(object);
        }
       /* To clear the input field values */ 
       clearPhone(){
           this.setState({phoneNo:'',message:'',color:''})
       }
       /* Call to the entered number in the input field */
       callTo(){
           if(this.state.phoneNo.length <10){
           this.setState({message:'Please enter a valid number',color:'red'})
           }
           else{
               const numbers = [
                   { number:'+18003679501'},
                   { number: '+1'+this.state.phoneNo}];
               Conference.conference(numbers)           
           }
       }
   
       /* On mouse Enters into the page fields should be empty */
        mouserEnter() {
            this.setState({
                name: '',
                company: '',
                location: '',
                phone: '',
                userAllDetails: [],
                email: ''
            });
        }
      /* To insert the bookig details */
       async submitBook() {
    console.log('book')
       }
      /* To change the menu icon in mobile/tab view on menu open*/
       changeIcon() {
           this.setState({
               iconStatus: !this.state.iconStatus
           });
       }
     /* To close the opened menu */    
       closePopup() {
            this.setState({
                resLocation: '',
                resName: '',
                resCompany: '',
                resEmail: '',
                resPhone: '',
                resPlace:''
            })
           $(".navbar-collapse").removeClass('in');
           $("#mobile-nav button.navbar-toggle").addClass('collapsed');
           this.setState({iconStatus: false, navIndex: -1, menuId : 0, clickedMenuId : 0});
           localStorage.setItem('navIndex', -1);
           localStorage.setItem('menuId', 0);
           localStorage.setItem('clickedMenuId', 0);
       }
       /* To clear the login form input fiels values */
       loginClear(){
        this.setState({message: '',client:false,guest:true, color: '',phone:'',email:'',password:'',login :true,onNext :false ,forgotpassword : false})
       }
       menuClick(clickedMenuId, index){
        this.setState({clickedMenuId, navIndex: index});
        localStorage.setItem('clickedMenuId', clickedMenuId);
        localStorage.setItem('navIndex', index);
        localStorage.setItem('menuId', clickedMenuId);
       }
       removeNavHover(){
        this.setState({navIndex: -1, menuId : 0, clickedMenuId : 0});
        localStorage.setItem('navIndex', -1);
        localStorage.setItem('menuId', 0);
        localStorage.setItem('clickedMenuId', 0);
       }
      /* To clear the local storage data and logout from the fivestar application */
      async logout() {
        const userData= await SessionService.SessionDestory({type:'intranet'});
        if(userData.error==0){
            location.href = '/intranet2-login'
        }
      }
      navigation(){
        this.setState({navigation: !this.state.navigation});
      }
       /* It is invoked to return html content */ 
       render() {
           const menuId = JSON.parse(localStorage.getItem('menuId'));   
           return (
               <div className="corporate_menu">
                   <nav className="navbar navbar-inverse cc_megamenu top">
                       <div className="navbar-header">
                           <button
                           type="button"
                           className="navbar-toggle"
                           data-toggle="collapse"
                           data-target="#myNavbar"
                           onClick={this.changeIcon}>
                           <div id="nav-icon1">
                               <span></span>
                               <span></span>
                               <span></span>
                           </div>
                           <div id="nav-icon2">
                               <span></span>
                               <span></span>
                           </div>
                           </button>
                           <span className={this.state.navigation ? "sideweb_barmenu open" : "sideweb_barmenu"} onClick={this.navigation}>&#9776;</span>
                           {this.state.navigation ? <div id="sideweb_barmenu_toggler" className="sidenav">
                            <ul>
                                <li><strong>Company</strong></li>
                                <li><Link to="/company-info">About Us</Link></li>
                                <li><Link to="/housing-solutions">Housing Solutions</Link></li>
                                <li><Link to="/leadership-team">Leadership Team</Link></li>
                                <li><Link to="/industry-awards">Industry Awards</Link></li>
                                <li><Link to="/press-releases">Press Releases</Link></li>
                                <li><Link to="/media-contact">Media Contact</Link></li>
                            </ul>
                            </div> : null}   
                           <button type="button" className="btn btn_submit res_right_btnss">
                           BOOK NOW</button>
                       </div>
                       <div className="collapse navbar-collapse" id="myNavbar">
                           <ul className="nav navbar-nav">
                           <li>
                                    <div  data-toggle="modal" data-target="#callModal" onClick={this.clearPhone} className="helpblock cursor-pointer">
                                        <div className="helpitem help-icon">
                                        <img src={s3BucketUrl_WebsiteImages+"SA-Telephone-Icon-Red.png"}/>
                                        </div>
                                        <div className="helpitem">
                                        <div className="help-text">
                                        <strong>+1 800.367.9501</strong>
                                        </div>
                                        </div>
                                    </div>
                               </li>
                               <li className={menuId == 1? "dropdown open" : 'dropdown'}>
                               <Link to="/intranet-form" onClick={() => this.menuClick(1, -1)}>MARKETING REQUEST
                               <b className="caret"></b>
                               </Link>
                               </li>    

                               <li className={menuId == 3? "dropdown open" : 'dropdown'}>
                               <Link to="/intranet-order-process" onClick={() => this.menuClick(3, -1)}>MATERIALS
                               <b className="caret"></b>
                               </Link>
                               </li> 

                           </ul>
                           <div className="right_btnss">
                               <ul className="">
                               <li className='hidden-xs'>
                               <a>
                               <button
                                   type="button"
                                   className="btn btn_login "
                                   onClick={this.logout}>
                                LOGOUT
                               </button>
                               </a>
                           </li>

                               </ul>
   
                           </div>
                       </div>
                       <div className="head-logo">
                       <Link className="" to="/intranet-home" onClick={this.removeNavHover}>
                           <img src={s3BucketUrl_WebsiteImages+"SA-Logo.png"} alt=""/>
                           </Link>
                           </div>
                 
                   </nav>
                   <nav id="mobile-nav" className="navbar navbar-inverse cc_megamenu top fixed">
                       <div className="navbar-header">
                       <button
                           type="button" 
                           className="navbar-toggle collapsed" 
                           data-toggle="collapse" 
                           data-target="#myNavbar33"
                           onClick={this.changeIcon}>
                           <div id="nav-icon2">
                               <span></span>
                               <span></span>
                           </div>
                           <div id="nav-icon1">
                               <span></span>
                               <span></span>
                               <span></span>
                           </div>
                           </button>
                           <Link className="navbar-brand logo" to="/intranet-home" onClick={this.closePopup}>
                           <img src={s3BucketUrl_WebsiteImages+"SA-Logo.png"} alt=""/>
                           </Link>
                          <a
                           onClick={this.closePopup}>
                           <span className="cc_mega_login"  onClick={this.logout}>LOGOUT</span>
                           </a>
                       </div>
                       <div className="collapse navbar-collapse" id="myNavbar33">
                           <ul className="nav navbar-nav">
                               <li>
                                   <Link to="/intranet-form" onClick={this.closePopup} >MARKETING REQUEST</Link>
                               </li>
                               <li>
                                   <Link to="/intranet-order-process" onClick={this.closePopup}>MATERIALS</Link>
                               </li>
                               <li className="dropdown">
                                   <Link to="#" className="dropdown-toggle" data-toggle="dropdown">COMPANY<b className="caret"></b></Link>
                                   <ul className="dropdown-menu">
                                       {this.state.hambergerMenu.map((row, index) => {
                                            return (
                                                <li key={index}><Link onClick={this.closePopup} to={row.url}>{row.name}</Link></li>
                                            )
                                        })
                                       }
                                   </ul>
                               </li>
                            
                           </ul>
                        
                       </div>
                   </nav>
                   <LoginModal />
                     <CallModal />
                   <div className="res-cover-heder-ht"></div>
                   <BookNowModal/>
                   <SiteLoader />
               </div>   
           );
       }
   }
   export default EmployeeHeader;