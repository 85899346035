import GuestCheckoutNotice1 from "./GuestCheckoutNotice1";
import GuestCheckoutNotice2 from "./GuestCheckoutNotice2";
import GuestCheckoutNotice3 from "./GuestCheckoutNotice3";
import GuestCheckoutNotice4 from "./GuestCheckoutNotice4";
import GuestCheckoutNotice5 from "./GuestCheckoutNotice5";

export default {
  GuestCheckoutNotice1,
  GuestCheckoutNotice2,
  GuestCheckoutNotice3,
  GuestCheckoutNotice4,
  GuestCheckoutNotice5,
};
