import React from "react";
import Footer from "./footer";
import Header from "./header";

class LeadershipPageNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLeaderName: "",
    };
  }

  modalData = {
    ericka: {
      name: "Ericka Anderson",
      designation: "VP, Strategic Growth",
      // src: "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/aboutUS/Ericka-Anderson.png",
      src: "/images/SA-team/Ericka-Anderson.png",
      desc: "Ericka Anderson, Vice President of Business Development, \
    is a seasoned professional with 26 years of experience in corporate housing. \
    Her strong work ethic and dedication are evident in her commitment to finding \
    win-win solutions for clients. Ericka's reputation for making everyone feel \
    included and sharing a laugh sets her apart. She is passionate about encouraging, \
    supporting, and recognizing the unique qualities of those she works with. Ericka's \
    goal is to strengthen existing client relationships and forge new ones, all while \
    collaborating with the expert team at SuiteAmerica to deliver exceptional, trust-building, \
    and wow-factor service.",
    },
    kelsey: {
      name: "Kelsey Thompson",
      designation: "VP, Business Development & Strategic Alliances",
      // src: "/assets/newUI-images/leadershipImages/Kelsey_BW.png",
      src: "/images/SA-team/Kelsey-Thompson.png",
      desc: "Kelsey Thompson, Vice President of Business Development, has a proven track record \
        of driving revenue growth and optimizing sales processes globally. With over 10 years \
        of experience in the corporate housing industry, Kelsey has consistently delivered \
        exceptional results by strategically aligning sales efforts with business objectives. \
        Whether it’s streamlining processes, nurturing client relationships, new client \
        acquisition, or spearheading expansion, Kelsey consistently delivers impactful solutions \
        for both her clients and the organization. She values transparency, being solution \
        oriented, and delivering an exceptional guest experience. She has been recognized by \
        numerous clients for her responsiveness, problem solving and her customer centric approach.",
    },

    laura: {
      name: "Laura Blackmun",
      designation: "VP, Business Development",
      // src: "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/aboutUS/Leadership/LauraGantt.png",
      src: "/images/SA-team/Laura-Blackmun.png",
      desc: "Laura Blackmun, Vice President of Business Development, is a steadfast professional with an \
      impressive 14-year history within the organization. With a remarkable 24 years of corporate \
      housing expertise, Laura is deeply committed to helping individuals navigate through \
      challenging times. She is celebrated for her exceptional listening skills and her ability \
      to provide solutions, lend a supportive hand, and resolve complex issues. Laura's enthusiasm \
      is dedicated to leaving a lasting mark on the corporate housing industry by spearheading growth \
      and nurturing valuable relationships.",
    },

    morgan: {
      name: "Morgan Canchola",
      designation: "VP, Sales Operations",
      // src: "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/aboutUS/MorganC.png",
      src: "/images/SA-team/Morgan-Canchola.png",
      desc: "As Vice President of Sales Operations, Morgan Canchola, brings 13 years of corporate housing\
       experience to SuiteAmerica. She's driven by her passion for operational efficiency and is\
       a specialist in problem-solving. Morgan is widely recognized for her adaptability and her\
      willingness to contribute wherever needed. She finds fulfilment in helping individuals reach\
      their career goals. As we continue to expand, Morgan is dedicated to streamlining processes\
      and enhancing the guest experience. She eagerly anticipates further collaboration with our\
      dynamic sales team and being part of SuiteAmerica's exciting growth journey.",
    },
    kristi: {
      name: "Kristi Saluga",
      designation: "Vice President of Client Services",
      src: "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/aboutUS/Kristi_S.png",
      desc: "Kristi Saluga, Vice President of Client Services at SuiteAmerica, specializes in comprehensive \
            relocation knowledge and is known for her positive industry impact through networking and \
            relationship-building. With 15 years of experience in Global Mobility, including a Certified \
            Relocation Professional Designation, Kristi balances client advocacy and organizational interests \
            as a trusted advisor. She aims to solidify her industry leadership and mentorship role while contributing \
            to SuiteAmerica's growth through expanded technology, services, and partner networks. \
            Kristi's passion lies in nurturing relationships and driving organizational growth.",
    },
  };

  closeLeadershipModal() {
    $("#leadership-modal").modal("hide");
  }

  openLeadershipModal(leaderName) {
    this.setState({ selectedLeaderName: leaderName });
    $("#leadership-modal").modal("show");
  }

  async componentDidMount() {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
  }
  render() {
    return (
      <div className="SAweb_updateUi">
        <Header />
        <section className="SAweb_leaderShipPage">
          <h2>SuiteAmerica Leadership</h2>
          {/* <p>
            Lorem ipsum dolor sit amet consectetur. Donec eget egestas sed ipsum
            tincidunt aliquam massa leo semper. Metus tincidunt velit{" "}
          </p> */}
          <div className="container mt-4">
            <h3 className="text-left"> Executive Leadership</h3>
            <div className="tails">
              <div className="item">
                <div className="pic">
                  {/* <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/aboutUS/Leadership/RobinMasten.png" /> */}

                  <img src="/images/SA-team/Robin-Master.png" />
                </div>
                <h5>Robin Masten</h5>
                <span>President</span>
              </div>
              <div className="item">
                <div className="pic">
                  <img src="/images/SA-team/James-Masten.png" />
                  {/* <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/aboutUS/Leadership/JamesMasten.png" /> */}
                </div>
                <h5>James Masten</h5>
                <span>Chief Executive Officer</span>
              </div>

              <div className="item">
                <div className="pic">
                  {/* <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/aboutUS/Leadership/AshokKandipati.png" /> */}
                  <img src="/images/SA-team/Ashok.png" />
                </div>
                <h5>Ashok Kandipati</h5>
                <span>Director of Software Development</span>
              </div>
              <div
                className="item cursor-pointer"
                onClick={() => this.openLeadershipModal("morgan")}
              >
                <div className="pic">
                  {/* <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/aboutUS/MorganC.png" /> */}
                  <img src="/images/SA-team/Morgan-Canchola.png" />
                </div>
                <h5>Morgan Canchola</h5>
                <span>VP, Sales Operations</span>
                {/* <span
                  className="abt-me"
                  onClick={() => this.openLeadershipModal("morgan")}
                >
                  About Me
                </span> */}
              </div>
              <div
                className="item cursor-pointer"
                onClick={() => this.openLeadershipModal("ericka")}
              >
                <div className="pic">
                  {/* <img
                    className="extraPicsize"
                    // src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/aboutUS/Leadership/MichaelJames.png"
                    src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/aboutUS/Ericka-Anderson.png"
                  /> */}
                  <img src="/images/SA-team/Ericka-Anderson.png" />
                </div>
                <h5>Ericka Anderson</h5>
                <span>VP, Strategic Growth</span>
                {/* <span
                  className="abt-me"
                  onClick={() => this.openLeadershipModal("ericka")}
                >
                  About Me
                </span> */}
              </div>
            </div>

            <h3 className="text-left"> Sales Leadership</h3>
            <div className="tails">
              {/* <div
                className="item cursor-pointer"
                onClick={() => this.openLeadershipModal("laura")}
              >
                <div className="pic">
                  {/* <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/aboutUS/Leadership/LauraGantt.png" /> */}
                  {/*<img src="/images/SA-team/Laura-Blackmun.png" />
                </div>
                <h5>Laura Blackmun</h5>
                <span>VP, Business Development</span> */}
                {/* <span
                  className="abt-me"
                  onClick={() => this.openLeadershipModal("laura")}
                >
                  About Me
                </span> */}
              {/* </div> */}

              <div
                className="item cursor-pointer"
                onClick={() => this.openLeadershipModal("kelsey")}
              >
                <div className="pic">
                  {/* <img
                    className="extraPicsize"
                    // src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/aboutUS/Leadership/MichaelJames.png"
                    src="/assets/newUI-images/leadershipImages/Kelsey_BW.png"
                  /> */}
                  <img src="/images/SA-team/Kelsey-Thompson.png" />
                </div>
                <h5>Kelsey Thompson</h5>
                <span>VP, Business Development & Strategic Alliances</span>
                {/* <span
                  className="abt-me"
                  onClick={() => this.openLeadershipModal("kelsey")}
                >
                  About Me
                </span> */}
              </div>
              <div className="item">
                <div className="pic">
                  {/* <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/aboutUS/Leadership/CoreyTaylor.png" /> */}
                  <img src="/images/SA-team/OliviaMoralesHeadShotPhoto.png" />{" "}
                </div>
                <h5>Olivia Morales</h5>
                <span>VP, Business Development</span>
              </div>
              <div className="item">
                <div className="pic">
                  {/* <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/aboutUS/Leadership/CoreyTaylor.png" /> */}
                  <img src="/images/SA-team/LilyHeadshot.png" />{" "}
                </div>
                <h5>Lily Ma</h5>
                <span>Senior Business Development Executive</span>
              </div>

              <div className="item">
                <div className="pic">
                  {/* <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/aboutUS/Leadership/CoreyTaylor.png" /> */}
                  <img src="/images/SA-team/Corey-Taylor.png" />{" "}
                </div>
                <h5>Corey Taylor</h5>
                <span>Director of Client Services</span>
              </div>
            </div>

            <h3 className="text-left">Operations Leadership</h3>
            <div className="tails">
              <div className="item">
                <div className="pic">
                  {/* <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/aboutUS/Leadership/LeeAnnLarsen.png" /> */}
                  <img src="/images/SA-team/LeeAnn-Larsen.png" />
                </div>
                <h5>LeeAnn Larsen</h5>
                <span>Pacific Northwest Region</span>
              </div>
              <div className="item">
                <div className="pic">
                  {/* <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/aboutUS/Leadership/ShufordBowman.png" /> */}
                  <img src="/images/SA-team/Shuford-Bowman.png" />{" "}
                </div>
                <h5>Shuford Bowman</h5>
                <span>Eastern Region</span>
              </div>
              <div className="item">
                <div className="pic">
                  {/* <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/aboutUS/Leadership/DustinStorie.png" /> */}
                  <img src="/images/SA-team/Dustin-Storle.png" />{" "}
                </div>
                <h5>Dustin Storie</h5>
                <span>National Supplier Partnership Manager</span>
              </div>
              <div className="item">
                <div className="pic">
                  {/* <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/aboutUS/Leadership/CarolNeal.png" /> */}
                  <img src="/images/SA-team/Carol-Neal.png" />
                </div>
                <h5>Carol Neal</h5>
                <span>National Guest Experience Manager</span>
              </div>
            </div>
          </div>
        </section>
        <div
          id="leadership-modal"
          className="modal"
          tabindex="-1"
          role="dialog"
        >
          <div className="overlay"></div>
          <div className="modal-dialog modal-lg" data-aos="fade-down">
            <div className="modal-content">
              <div className="modal-body">
                <button
                  onClick={this.closeLeadershipModal}
                  type="button"
                  className="close btn no-hover"
                >
                  <i className="bi bi-x"></i>
                </button>
                {this.modalData[this.state.selectedLeaderName] ? (
                  <>
                    <div className="sm-d-flex align-items-center justify-content-between px-5 py-5">
                      <img
                        src={this.modalData[this.state.selectedLeaderName].src}
                      />

                      <span className="ms-5 d-block py-4">
                        <div className="mb-5">
                          <h3 className="d-block">
                            {this.modalData[this.state.selectedLeaderName].name}
                          </h3>
                          <h4>
                            {
                              this.modalData[this.state.selectedLeaderName]
                                .designation
                            }
                          </h4>
                        </div>
                        {this.modalData[this.state.selectedLeaderName].desc}
                      </span>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default LeadershipPageNew;
