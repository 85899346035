/* Importing the node modules, child components, services and controllers used 
   inside Technology component */
import MainController from "../../controller/mainController";
import SubFooter from "../../common/subFooter";
import GuestHeader from '../../common/guestHeader';
import Footer from '../../common/footer';
import ReserveYourStay from '../../common/reserveYourStay.js';
import MetaTags from '../../common/metaTags';
import ServerMetaTags from '../../../server/metaTags';
import NavigationFooter from "../../common/navigationFooter";
import NavigationData from '../../common/navigation.json';
/* Technology Component initialization */
class Technology extends React.Component {
    /* Initializing objects of its Technology class */
    constructor(props) {
        super(props);
        this.state = {
            navigationData: NavigationData.technology
         }
    }
    /* It is invoked immediately before mounting occurs */
    componentWillMount() {
        MainController.getProfilePageInformation(3, 1, 1);
    }
    /* It is invoked to return html content */
    render() {
        const metaData = ServerMetaTags.urlString.getMetaData(this.props.match.url);
        return (
            <div>
                <MetaTags 
                metaTitle = {metaData.title}
                metaDesription = {metaData.description}
                metaUrl = {metaData.canonical} />
                <GuestHeader/>
                <div className="res-md res-sm technology">
                    <header className="codeconveyHeader cc_image_text_main corporate-h video-wrapper">
                        <img className="img-responsive relocation-corporate-housing" src={s3BucketUrl_WebsiteImages+"Corporation-Technology-Banner.jpg"} width="100%" alt=""  />
                        <div className="wow fadeInUp img-on-text text-center top40 left60 width500 top35-md left20-md color-black" data-wow-delay="0.5s">
                            <h1>ALWAYS CONNECTED</h1>
                            <p>Settling in and staying in touch has never been easier. We have developed advanced web-based tools to create a level of connectivity never before seen in the industry.  Our centralized information platform connects you and your transferee to a wealth of information updated in real-time and available anywhere in the world, 24/7.</p>
                        </div>
                    </header>
                    <ReserveYourStay />
                    <div className="who_weare services_ch">
                        <div className="cc_image_text_main">
                            <img className="img-responsive left-mo" src={s3BucketUrl_WebsiteImages+"Corporation-Destination-Services-Our-Dedication.jpg"} alt="" />
                        </div>
                    </div>
                    <div className="new_txt_sec mrg-btm-0">
                        <div className="new_txt_content container wow fadeInUp animated">
                            <div className="col-md-8 col-md-offset-2">
                            <h2>CONNECTION THROUGH CONVERSATION AND TECHNOLOGY</h2>
                            <p>From area information, to service requests to apartment specifics, we’ve made communication on-demand for you and your transferees. Our interactive tools are available from any desktop or mobile device. 
                            </p>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                    <div className="services_ch grey-xs">
                        <div className="carousel banner_text">
                            <img className="img-responsive right-15" src={"/assets/newUI-images/Website-Images/Corporation-Technology-Guest-Portal.png"} alt="" />
                            <div className="img-on-text width500 left55 top30 color-dark md-text width300-md left60-md top50-md width300-sm left55-sm top30-sm wow fadeInUp" data-wow-delay="0.5s">
                            <h2>Guest Portal<br/>
                            Online Account Access
                            </h2>
                            <p>Our secure online portal allows your transferee to view their suite information, place and track service requests, find things to do and receive special offers in their new community and customize their stay with custom amenities from our online store.
                            </p>
                            </div>
                        </div>
                    </div>
                    <div className="caption_text services_cs">
                        <div className="container">
                            <div className="who_weare_text22 wow fadeInUp" data-wow-delay="0.5s">
                            <h1 className=" font-bold">THE MOST</h1>
                            <h2 className=""><span>are</span></h2>
                            <h1 className="">EFFECTIVE GESTURES</h1>
                            <h2 className=""><span>unseen</span></h2>
                            </div>
                        </div>
                    </div>
                    <div className="services_ch grey-xs">
                        <div className="carousel banner_text">
                            <img className="img-responsive" src={s3BucketUrl_WebsiteImages+"Corporation-Technology-Automated-Itineraries.jpg"} alt="" />
                            <div className="img-on-text width600 left50 top65 color-dark md-text left0-md width300-md top60-md left55-md width300-sm left55-sm top55-sm wow fadeInUp animated" data-wow-delay="0.5s">
                            <h2>Automated Itineraries</h2>
                            <p>Our Destination Services itineraries receive real-time updates from our centralized database, allowing drivers to edit selections and update routes in an instant. Your transferee can take notes, make changes and rate properties throughout their tour, automatically reporting their assessment to your team and ours.
                            </p>
                            </div>
                        </div>
                    </div>
                    <div className="services_ch white-xs">
                        <div className="carousel banner_text">
                            <img className="img-responsive right-70" src={"/assets/newUI-images/Website-Images/Corporation-Technology-Corporate-Housing.jpg"} alt="" />
                            <div className="img-on-text width500 left5 top25 color-dark text-right md-text width300-md left10-md top30-md width300-sm top30-sm wow fadeInUp animated" data-wow-delay="0.5s">
                            <h2>Corporate Housing<br/>
                            Web Application
                            </h2>
                            <p>Our cloud-based end-to-end solution provides real-time booking, tracking and reporting, while providing a centralized data system that connects and powers all of our ground-breaking digital tools. 
                            </p>
                            </div>
                        </div>
                    </div>
                    <div className="services_ch white-xs">
                        <div className="carousel banner_text">
                            <img className="img-responsive right-0" src={s3BucketUrl_WebsiteImages+"Corporation-Technology-iMac-Scout-Database.jpg"} alt="" />
                            <div className="img-on-text width500 left55 top25 color-dark md-text width300-md top35-md left55-md width300-sm top20-sm left55-sm wow fadeInUp animated" data-wow-delay="0.5s">
                            <h2>Scout Platform</h2>
                            <p>Directly connected to our central database, our proprietary supply chain application streamlines the supply chain process, enhances response times and creates a fully comprehensive supplier partner network. This revolutionary tool is also a directory for all our vetted partners and property management companies, allowing our supply chain team to quickly identify optimal resources in the requested markets. 
                            </p>
                            </div>
                        </div>
                    </div>
                    <div className="services_ch grey-xs">
                        <div className="carousel banner_text">
                            <img className="img-responsive right-70" src={s3BucketUrl_WebsiteImages+"Corporation-Technology-Five-Star.jpg"} alt="" />
                            <div className="img-on-text width600 left5 text-right top60 color-dark md-text width300-md top65-md left15-md width300-sm top55-sm wow fadeInUp animated" data-wow-delay="0.5s">
                            <h2>5 Star Service App</h2>
                            <p>Increases efficiency, providing timely communication between our departments so we can serve you faster and more effectively. Inspection details and images can be uploaded directly into our system. Direct connectivity to our database creates a constant flow of information to all of our teams and vehicles, allowing for schedule changes and updates to take place in real time.  
                            </p>
                            </div>
                        </div>
                    </div>
                    <div className="services_ch grey-xs">
                        <div className="carousel banner_text">
                            <img className="img-responsive right-70" src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/Technology-iPad-Pro-Fleetmatics.jpg" alt="" />
                            <div className="img-on-text width600 left50 top65 color-dark md-text left0-md width300-md top60-md left55-md width300-sm left55-sm top55-sm wow fadeInUp animated" data-wow-delay="0.5s">
                            <h2>Vehicle Tracking Software</h2>
                            <p>Monitors and manages entire mobile workforce for efficient dispatch and improved customer service, while analyzing data for optimized, cost-saving route planning. Allows us to track our entire distribution fleet for efficiency, safety and accountability.
                            </p>
                            </div>
                        </div>
                    </div>
                    <div className="quote-section bg-white quote_red">
                        <div className="container">
                            <div className="wow fadeInUp" data-wow-delay="0.5s">
                            <h6 className="quote-s"> <img src={s3BucketUrl_WebsiteImages+"Corporation-Corporate-Housing-Quote.png"} alt=""/></h6>
                            <div className="col-md-10 col-md-offset-1">
                                <h5 className="quote-text">I filed a service request through the web app and was amazed at how quickly 
                                SuiteAmerica responded to me and handled things. I’m used to waiting at least a few 
                                days for things to get fixed, but they handled it in less than an hour.
                                </h5>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                    <NavigationFooter {...this.state} />
                    <SubFooter />
                    <Footer/>
                </div>
            </div>
        );
    }
}
export default Technology;