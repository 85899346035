import React, { Component } from "react";
import GuestHeader from "../../common/guestHeader";
import Footer from "../../common/footer";
import DatePicker from "react-datepicker";
import moment from "moment";
import nikeService from "../../../services/nikeService";
import ReserveNowApi from "../../../services/reserveNowApi";

export class AppleCeHousing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      phonenumber: "",
      arrivalDate: null,
      DepartureDate: null,
      degreeLevel: "",
      gender: "",
      preferredGenderText: "",
      carAtHousing: "",
      typeOfHousing: "",
      dietaryRestrictions: "",
      specialRequests: "",
      roomMatePreference: "",
      social: "",
      habits: "",
      housekeeping: "",
      validate: "",
      familyAge: "",
      address: "",
      loader: false,
      firstname: "",
      lastname: "",
    };
    this.setValue = this.setValue.bind(this);
    this.setChecked = this.setChecked.bind(this);
    this.serviceRequest = this.serviceRequest.bind(this);
    this.setNumber = this.setNumber.bind(this);
    this.setLocation = this.setLocation.bind(this);
  }

  async componentDidMount() {
    $(document).ready(function () {
      jQuery(function ($) {
        $("#phone").intlTelInput();
      });
    });
  }

  setValue(field, e) {
    if (field == "arrivalDate" || field == "DepartureDate") {
      let object = {};
      object[field] = e;
      this.setState(object);
    } else {
      let object = {};
      object[field] = e.target.value;
      this.setState(object);
    }
  }

  setChecked(field, e) {
    if (field == "gender") {
      this.setState({ gender: e.target.value });
    } else if (field == "carAtHousing") {
      this.setState({ carAtHousing: e.target.value });
    } else if (field == "vehicle") {
      this.setState({ vehicle: e.target.value });
    } else if (field == "social") {
      this.setState({ social: e.target.value });
    } else if (field == "habits") {
      this.setState({ habits: e.target.value });
    } else if (field == "housekeeping") {
      this.setState({ housekeeping: e.target.value });
    }
  }

  setNumber(e) {
    const re = /^[0-9\b]+$/;
    if (e.target.value == "" || re.test(e.target.value)) {
      this.setState({ phonenumber: e.target.value });
    }
  }

  setLocation(field, e) {
    this.setState({ address: e.target.value });
    const _this = this;
    var places = new google.maps.places.Autocomplete(
      document.getElementById("address")
    );
    google.maps.event.addListener(places, "place_changed", function () {
      var place = places.getPlace();
      const newLat = place.geometry.location.lat();
      const newLng = place.geometry.location.lng();
      _this.setState({
        // latitude: newLat, longitude: newLng,
        address: place.formatted_address,
      });
    });
  }

  validation() {
    const _this = this;
    const validationFields = [
      "firstname",
      "lastname",
      "email",
      "phonenumber",
      "DepartureDate",
      "arrivalDate",
      "gender",
      "preferredGenderText",
      "carAtHousing",
    ];
    let error = 0;
    _.forEach(validationFields, function (value) {
      if (value == "email") {
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (!reg.test(_this.state.email) || _this.state.email == "") {
          error++;
        }
      } else if (value == "phonenumber") {
        if (
          _this.state.phonenumber == "" ||
          _this.state.phonenumber.length < 10
        ) {
          error++;
        }
      } else if (value === "preferredGenderText") {
        if (
          _this.state.gender === "Non-Binary/Other Gender/Gender Inclusive" &&
          _this.state.preferredGenderText === ""
        ) {
          error++;
        }
      } else if (_this.state[value] == "" || _this.state[value] == null) {
        error++;
      }
    });

    return error;
  }

  async serviceRequest() {
    const error = await this.validation();
    if (error == 0) {
      await this.setState({ loader: true });
      const countryCode =
        $(".country.highlight.active").attr("data-dial-code") == undefined
          ? "+1"
          : "+" + $(".country.highlight.active").attr("data-dial-code");
      let userInfo = {
        degreeLevel: this.state.degreeLevel,
        gender: this.state.gender,
        carAtHousing: this.state.carAtHousing,
        typeOfHousing: this.state.typeOfHousing,
        familyAge: this.state.familyAge,
        dietaryRestrictions:
          this.state.dietaryRestrictions.length > 0
            ? this.state.dietaryRestrictions
            : "---",
        housekeeping: this.state.housekeeping,
        habits: this.state.habits,
        social: this.state.social,
        specialRequests:
          this.state.specialRequests.length > 0
            ? this.state.specialRequests
            : "---",
        roomMatePreference:
          this.state.roomMatePreference.length > 0
            ? this.state.roomMatePreference
            : "---",
      };

      const reserveYourStay = {
        Location: "Cupertino, CA 95014, USA",
        MoveInDate: this.state.arrivalDate.format("MM-DD-YYYY"),
        MoveOutDate: this.state.DepartureDate.format("MM-DD-YYYY"),
        Size: "1X",
        SpecialNote: JSON.stringify(userInfo),
        GuestFirstName: this.state.firstname,
        GuestLastName: this.state.lastname,
        ClientReference: "",
        GuestEmail: this.state.email,
        GuestPhone: this.state.phonenumber,
        Parking: "",
        length: 0,
        CreatedBy: this.state.email != "" ? this.state.email : "---",
        BookedBy: "ashok@suiteamerica.com",
        PhContryCode: countryCode,
      };
      console.log("reserveYourStay", reserveYourStay);
      //const orderResponse = await ReserveNowApi.webLeadQuotes(reserveYourStay);
      const orderResponse = {
        error: 0,
      };

      if (orderResponse.error == 0) {
        let Request = {
          mail: "AppleCeHousing",
          subject: "Apple CE Housing",
          countryCode: countryCode,
          firstname: this.state.firstname,
          lastname: this.state.lastname,
          email: this.state.email,
          phonenumber: this.state.phonenumber,
          arrivalDate: this.state.arrivalDate.format("MM-DD-YYYY"),
          DepartureDate: this.state.DepartureDate.format("MM-DD-YYYY"),
          degreeLevel: this.state.degreeLevel,
          gender: this.state.gender,
          preferredGender: this.state.preferredGenderText,
          carAtHousing: this.state.carAtHousing,
          typeOfHousing: this.state.typeOfHousing,
          familyAge: this.state.familyAge,
          dietaryRestrictions:
            this.state.dietaryRestrictions.length > 0
              ? this.state.dietaryRestrictions
              : "---",
          housekeeping: this.state.housekeeping,
          habits: this.state.habits,
          social: this.state.social,
          specialRequests:
            this.state.specialRequests.length > 0
              ? this.state.specialRequests
              : "---",
          roomMatePreference:
            this.state.roomMatePreference.length > 0
              ? this.state.roomMatePreference
              : "---",
          orderId: 0,
        };
        await nikeService.serviceRequest(Request);
        await jQuery(
          function ($) {
            $("#nikesuccessmodal").modal("show");
          }.bind(this)
        );
        $('input[name="1"]').prop("checked", false);
        $('input[name="2"]').prop("checked", false);
        $('input[name="3"]').prop("checked", false);
        $('input[name="4"]').prop("checked", false);
        await this.setState({
          loader: false,
          name: "",
          firstname: "",
          lastname: "",
          email: "",
          phonenumber: "",
          arrivalDate: null,
          DepartureDate: null,
          degreeLevel: "",
          gender: "",
          carAtHousing: "",
          typeOfHousing: "",
          dietaryRestrictions: "",
          specialRequests: "",
          roomMatePreference: "",
          social: "",
          habits: "",
          housekeeping: "",
          validate: "",
          familyAge: "",
          address: "",
        });
      } else {
        await this.setState({ validate: orderResponse.message, loader: false });
        await window.scrollTo(0, 0);
      }
    } else {
      await this.setState({ validate: "Please Fill All Mandatory Fields." });
      await window.scrollTo(0, 0);
    }
  }

  render() {
    localStorage.setItem("menuId", 0);

    return (
      <div>
        <div
          className="modal fade savesearch"
          id="nikesuccessmodal"
          role="dialog"
        >
          <div className="modal-dialog">
            <div className="modal-content rm-border-radius">
              <div className="modal-header">
                <button
                  type="button"
                  className="close btn"
                  data-dismiss="modal"
                  aria-label=""
                >
                  <i className="bi bi-x"></i>
                </button>
              </div>
              <div className="modal-body">
                <p style={{ margin: "13px 0px 30px", fontWeight: "700" }}>
                  We have received your questionnaire and sent email
                  confirmation. Apple preferred housing supplier will reach out
                  to you soon.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="corporateHousing_Updates">
          <GuestHeader />
          <div className="cover-tophd-ht appleIntern"></div>
          <div className="container-fluid bg-grey">
            <div className="container" id="appleform">
              <div
                className="savesearch form-section col-sm-8 col-sm-offset-2 text-center"
                id="serviceRequest"
              >
                <h2>CE Shared Housing Questionnaire</h2>
                <p className="text-center validation-red-color">
                  {this.state.validate}
                </p>
                <form
                  role="form"
                  method="post"
                  action=""
                  className="form-horizontal"
                >
                  <div className="form-group row">
                    <label className="col-md-4">First Name *</label>
                    <div className="col-md-8">
                      <input
                        type="text"
                        className="form-control col-md-5"
                        placeholder="First Name"
                        value={this.state.firstname}
                        onChange={(e) => this.setValue("firstname", e)}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4">Last Name *</label>
                    <div className="col-md-8">
                      <input
                        type="text"
                        className="form-control col-md-5"
                        placeholder="Last Name"
                        value={this.state.lastname}
                        onChange={(e) => this.setValue("lastname", e)}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4">Email Address *</label>
                    <div className="col-md-8">
                      <input
                        type="text"
                        className="form-control col-md-5"
                        placeholder="Email Address"
                        onChange={(e) => this.setValue("email", e)}
                        value={this.state.email}
                      />
                    </div>
                  </div>
                  <div className="form-group row Internhousing">
                    <label className="col-md-4"> Phone Number *</label>
                    <div className="col-md-8">
                      <input
                        maxLength="10"
                        autoComplete="off"
                        id="phone"
                        className="form-control col-md-5"
                        placeholder="Phone Number"
                        value={this.state.phonenumber}
                        onChange={(e) => this.setNumber(e)}
                      />
                    </div>
                  </div>
                  <div className="form-group row Internhousing">
                    <label className="col-md-4">Arrival Date *</label>
                    <div className="col-md-8">
                      <DatePicker
                        type="text"
                        className="form-control col-md-5 "
                        placeholderText="Arrival Date"
                        onChange={(e) => this.setValue("arrivalDate", e)}
                        selected={this.state.arrivalDate}
                        minDate={new moment()}
                        maxDate={this.state.DepartureDate || null}
                      />
                    </div>
                    <div className="col-md-8 col-md-offset-4">
                      <label className="note">
                        (You can check in as early as Saturday before your
                        Monday start date)
                      </label>
                    </div>
                  </div>
                  <div className="form-group row Internhousing">
                    <label className="col-md-4">Departure date *</label>
                    <div className="col-md-8">
                      <DatePicker
                        type="text"
                        className="form-control"
                        placeholderText="Departure date"
                        onChange={(e) => this.setValue("DepartureDate", e)}
                        selected={this.state.DepartureDate}
                        minDate={this.state.arrivalDate || new moment()}
                      />
                    </div>
                    <div className="col-md-8 col-md-offset-4">
                      <label className="note">
                        (You can check out as late as Saturday after your Friday
                        end date)
                      </label>
                    </div>
                  </div>
                  {/* <div className="form-group row Internhousing">
                                        <label className="col-md-4">Location *</label>
                                        <div className="col-md-8">
                                            <input
                                                id="address"
                                                type="text"
                                                className="form-control"
                                                placeholder="Location"
                                                onChange={(e) => this.setLocation('address', e)}
                                                value={this.state.address} />
                                        </div>
                                    </div> */}

                  {/* <div className="form-group row">
                                        <label className="col-md-4">Degree Level *</label>
                                        <div className="col-md-8">
                                            <label className="custom-select">
                                                <select value={this.state.degreeLevel} onChange={(e) => this.setValue('degreeLevel', e)}>
                                                    <option value="" >Please Select Degree Level</option>
                                                    <option value="undergrad" >Undergrad</option>
                                                    <option value="graduate" >Graduate</option>
                                                    <option value="PhD" >PhD</option>
                                                </select>
                                            </label>
                                        </div>
                                    </div> */}

                  <div className="form-group row">
                    <label className="col-md-4">Gender *</label>
                    <div className="col-md-8">
                      <label className="custom-select">
                        <select
                          value={this.state.gender}
                          onChange={(e) => this.setChecked("gender", e)}
                        >
                          <option value="">Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Non-Binary/Other Gender/Gender Inclusive">
                            Non-Binary/Other Gender/Gender Inclusive
                          </option>
                        </select>
                      </label>
                    </div>
                  </div>
                  {this.state.gender ===
                  "Non-Binary/Other Gender/Gender Inclusive" ? (
                    <div className="form-group row">
                      <label className="col-md-4">Preferred Gender*</label>
                      <div className="col-md-8">
                        <textarea
                          className="form-control"
                          placeholder="Preferred Gender"
                          value={this.state.preferredGenderText}
                          onChange={(e) =>
                            this.setValue("preferredGenderText", e)
                          }
                          rows="8"
                          cols="4"
                        ></textarea>
                      </div>
                      <div class="col-md-8 col-md-offset-4">
                        <label class="note">
                          (*Please use this field to describe the nature of your
                          preference. We will make all efforts to preserve and
                          respect the privacy of individuals, but additional
                          information may be needed/solicited if necessary.)
                        </label>
                      </div>
                    </div>
                  ) : null}

                  <div className="form-group row">
                    <label className="col-md-4">
                      Are you bringing a car? *
                    </label>
                    <div className="col-md-8">
                      <div className="row">
                        <div className="clearfix"></div>
                        <div className="col-xs-3">
                          <label className="custom_radio radio-inline">
                            Yes
                            <input
                              type="radio"
                              onChange={(e) =>
                                this.setChecked("carAtHousing", e)
                              }
                              value="Yes"
                              name="2"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                        <div className="col-xs-3">
                          <label className="custom_radio radio-inline">
                            No
                            <input
                              type="radio"
                              onChange={(e) =>
                                this.setChecked("carAtHousing", e)
                              }
                              value="No"
                              name="2"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="form-group row">
                                        <label className="col-md-4">Type of housing *</label>
                                        <div className="col-md-8">
                                            <label className="custom-select">
                                                <select value={this.state.typeOfHousing} onChange={(e) => this.setValue('typeOfHousing', e)}>
                                                    <option value="">Type of housing</option>
                                                    <option value="Single">Single</option>
                                                    <option value="Married">Married</option>
                                                    <option value="Family">Family</option>
                                                </select>
                                                {this.state.typeOfHousing == 'Family' && <input type="text" value={this.state.familyAge} onChange={(e) => this.setValue('familyAge', e)} placeholder='Please Enter how many children and ages' className="form-control col-md-5" style={{ marginTop: '10px' }} />}

                                            </label>
                                        </div>

                                    </div> */}
                  <div className="form-group row">
                    <label className="col-md-4">Dietary restrictions</label>
                    <div className="col-md-8">
                      <textarea
                        className="form-control"
                        placeholder="Dietary restrictions"
                        value={this.state.dietaryRestrictions}
                        onChange={(e) =>
                          this.setValue("dietaryRestrictions", e)
                        }
                        rows="8"
                        cols="4"
                      ></textarea>
                    </div>
                  </div>
                  <div className="row">
                    <hr className="questionnaireHr"></hr>
                  </div>

                  <div className="form-group row">
                    <h3>Optional Questionnaire: </h3>

                    <p className="text-left">
                      * Please note that we will attempt to use the optional
                      questionnaire to match roommates, but we cannot guarantee
                      matching based on these answers:
                    </p>
                    <div className="optional-Q-Naire">
                      <div className="form-group row ">
                        <label className="col-md-12 mrg-btm-10">
                          The following statement best describes my feelings
                          towards housekeeping (select one).
                        </label>
                        <div className="col-md-12 ">
                          <div className="row">
                            <div className="clearfix"></div>
                            <div className="col-md-3 col-sm-6">
                              <label className="custom_radio radio-inline">
                                Very Neat
                                <input
                                  type="radio"
                                  onChange={(e) =>
                                    this.setChecked("housekeeping", e)
                                  }
                                  value="Very Neat"
                                  name="1"
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <div className="col-md-2 col-sm-6">
                              <label className="custom_radio radio-inline">
                                Neat
                                <input
                                  type="radio"
                                  onChange={(e) =>
                                    this.setChecked("housekeeping", e)
                                  }
                                  value="Neat"
                                  name="1"
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <div className="col-md-4 col-sm-6">
                              <label className="custom_radio radio-inline">
                                Clean but not neat
                                <input
                                  type="radio"
                                  onChange={(e) =>
                                    this.setChecked("housekeeping", e)
                                  }
                                  value="Clean but not neat"
                                  name="1"
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <div className="col-md-3 col-sm-6">
                              <label className="custom_radio radio-inline">
                                Messy
                                <input
                                  type="radio"
                                  onChange={(e) =>
                                    this.setChecked("housekeeping", e)
                                  }
                                  value="Messy"
                                  name="1"
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-md-12 mrg-btm-10">
                          The following statement best describes my sleeping
                          habits. (select one).
                        </label>
                        <div className="col-md-12">
                          <div className="row">
                            <div className="clearfix"></div>
                            <div className="col-md-3 col-sm-6">
                              <label className="custom_radio radio-inline">
                                Early Bird
                                <input
                                  type="radio"
                                  onChange={(e) => this.setChecked("habits", e)}
                                  value="Early Bird"
                                  name="3"
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <div className="col-md-3 col-sm-6">
                              <label className="custom_radio radio-inline">
                                Night Owl
                                <input
                                  type="radio"
                                  onChange={(e) => this.setChecked("habits", e)}
                                  value="Night Owl"
                                  name="3"
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-md-12 mrg-btm-10">
                          The following statement best describes my feelings
                          towards social activity in the apartment. (select
                          one).
                        </label>
                        <div className="col-md-12 mrg-btm-10">
                          <div className="row">
                            <div className="clearfix"></div>
                            <div className="col-md-3 col-sm-6">
                              <label className="custom_radio radio-inline">
                                Very Social
                                <input
                                  type="radio"
                                  onChange={(e) => this.setChecked("social", e)}
                                  value="Very Social"
                                  name="4"
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <div className="col-md-3 col-sm-6">
                              <label className="custom_radio radio-inline">
                                Social
                                <input
                                  type="radio"
                                  onChange={(e) => this.setChecked("social", e)}
                                  value="Social"
                                  name="4"
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <div className="col-m-3 col-sm-6">
                              <label className="custom_radio radio-inline">
                                Not Social
                                <input
                                  type="radio"
                                  onChange={(e) => this.setChecked("social", e)}
                                  value="Not Social"
                                  name="4"
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-md-12 mrg-btm-10">
                          Special requests/notes
                        </label>
                        <div className="col-md-12 mrg-btm-10">
                          <textarea
                            className="form-control"
                            placeholder="Special requests/notes"
                            value={this.state.specialRequests}
                            onChange={(e) =>
                              this.setValue("specialRequests", e)
                            }
                            rows="8"
                            cols="4"
                          ></textarea>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-md-12 mrg-btm-10">
                          Roommate preference
                        </label>
                        <div className="col-md-12 mrg-btm-10">
                          <textarea
                            className="form-control"
                            placeholder="Roommate preference – specific name of the person you are requesting to live with"
                            value={this.state.roomMatePreference}
                            onChange={(e) =>
                              this.setValue("roomMatePreference", e)
                            }
                            rows="8"
                            cols="4"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.state.loader ? (
                    <span
                      style={{
                        fontSize: "25px",
                        width: "100%",
                        margin: "auto",
                        textAlign: "center",
                      }}
                    >
                      <i className="fa fa-spinner fa-spin" />
                      please wait ...!
                    </span>
                  ) : (
                    <button
                      type="button"
                      className=" btn company_btn mrg-btm-70"
                      onClick={() => this.serviceRequest()}
                    >
                      {" "}
                      SUBMIT
                    </button>
                  )}
                  {/* <button type="button" className=" btn company_btn mrg-btm-70"
                                        onClick={() => this.serviceRequest()}> SUBMIT</button> */}
                </form>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default AppleCeHousing;
