/* Importing the node modules, child components, services and controllers used 
   inside LeadershipTeam component */
import MainController from "../controller/mainController";
import SubFooter from "./subFooter";
import GuestHeader from './guestHeader';
import Footer from './footer';
/* LeadershipTeam Component initialization */
class LeadershipTeam extends React.Component {
    /* Initializing objects of its LeadershipTeam class */
    constructor(props) {
        super(props);
    }
    /* It is invoked immediately before mounting occurs */
    componentWillMount() {
        MainController.getInitialInformation();
    }
    /* It is invoked to return html content */
    render() {
        return (
            <div>
            <GuestHeader/>
            <div className="services_ch">
                <div className="carousel banner_text">
                   <img className="img-responsive relocation-corporate-housing" src={s3BucketUrl_WebsiteImages+"SuiteAmerica-About-Us-Banner-Image.jpg"} alt="" />
                    <div className="img-on-text width500 left40 top40 width500 text-center img-on-text-center img-on-text-center-w wow fadeInUp"  data-wow-delay="0.5s">
                        <h3 className="animated font300">LEADERSHIP TEAM</h3>
                    </div>
                </div>
            </div>
            <div className="quote-section container-fluid bg-white team_section mediakit">
                <div className="col-md-12">
                    <div className="row L-team_member">
                        <div className="team_member">
                            <div className="pic">
                                <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/Staff/SuiteAmerica-Leadership-Staff-Robin+Masten-BW.png" />
                            </div>
                            <b>Robin Masten</b>
                            <p>President</p>
                        </div>
                        <div className="team_member">
                            <div className="pic">
                                <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/staffimages/Jim-Mastin-BW.png" />
                            </div>
                            <b>James Masten</b>
                            <p>Chief Executive Officer</p>
                        </div>
                        <div className="team_member">
                            <div className="pic">
                            <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/Mark-Holland-BW.png" />
                            </div>
                            <b>Mark Holland</b>
                            <p>National Director Supply Chain</p>
                        </div>
                        <div className="team_member">
                            <div className="pic">
                                <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/Staff/SuiteAmerica-Leadership-Staff-Lesley-Batsford-BW.png" />
                            </div>
                            <b>Leslie Batsford</b>
                            <p>Senior Director, National Sales</p>
                        </div>
                       
                        <div className="team_member">
                            <div className="pic">
                                <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/Staff/SuiteAmerica-Leadership-Staff-Laura-Gregersen-BW.png" />
                            </div>
                            <b>Laura Gregersen</b>
                            <p>Senior Director, National Sales</p>
                        </div>
                        <div className="team_member">
                            <div className="pic">
                                <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/Staff/SuiteAmerica-Leadership-Staff-Vanessa-Reiter-BW.png" />
                            </div>
                            <b>Vanessa Reiter</b>
                            <p>Senior Director, National Sales</p>
                        </div>
                        <div className="team_member">
                            <div className="pic">
                                <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/staffimages/Julie Mammini-BW.png" />
                            </div>
                            <b>Julie Mammini</b>
                            <p>Director of Personal Transition Services</p>
                        </div>
                        
                        <div className="team_member">
                            <div className="pic">
                                <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/staffimages/Shuford-Bowman-BW.png" />
                            </div>
                            <b>Shuford Bowman</b>
                            <p>Director of Accounting and Information Services</p>
                        </div>
                        <div className="team_member">
                            <div className="pic">
                                <img src="https://suiteam-website.s3-us-west-1.amazonaws.com/Staff/Kelly+Cockrell.png" />
                            </div>
                            <b>Kelly Cockrell</b>
                            <p>Vice President of Sales, Western Region</p>
                        </div>
                        <div className="team_member">
                            <div className="pic">
                                <img src="https://suiteam-website.s3-us-west-1.amazonaws.com/Staff/Ashok-Kandipati.png" />
                            </div>
                            <b>Ashok Kandipati</b>
                            <p>Information & Data Technology</p>
                        </div>
                    </div>
                </div>
            </div>
            <SubFooter />
            <Footer/>
        </div>
        );
    }
}
export default LeadershipTeam;