/* Importing the node modules, child components, services and controllers used 
   inside GuestProspective component */
import registerService from '../../services/registerService';
import FiveStarApiServices from '../../services/fiveStarApiServices';
import $ from "jquery";
import AppController from "../controller/appController";
import GuestHeader from '../common/guestHeader';
import Footer from '../common/footer';
import Loader from './autoConfirmRelo/loader';
/* GuestProspective Component initialization */
class GuestProspective extends React.Component {
  /* Initializing objects of its GuestProspective class */
  constructor(props) {
    super(props)
    /* GuestProspective Component State variables Initialization */
    this.state = {
      email: '',
      password: '',
      confirmPassword:'',
      message: '',
      onNext: false,
      guestprospect:false,
      userData: [],
      color:'',
      expired:false,
      loader:false
    }
    this.setValue = this.setValue.bind(this);
    this.passwordReset = this.passwordReset.bind(this);
    this.authentication = this.authentication.bind(this);
  }
  /* It is invoked immediately before mounting occurs */ 
  async componentWillMount() {
    
    $('html, body').animate({scrollTop: 0});
    AppController.stickyTitles();
    await this.checkExpiration(this.props.match.params.timoutkey)
  }

  async checkExpiration(key){
    const requestObj = {
      "authkey":key,
      "sourcetype":"fivestar",
      "requestforkey":false
          }
    const TimeOut = await FiveStarApiServices.GetTimeoutKey(requestObj)
    if(TimeOut.error=="0"){
      this.setState({expired:true,loader:true})
    }
    else{
      this.setState({expired:false,loader:true})
    }
    
  }
  /* Update the state object of the form */
  setValue(field, e) {
    var object = {};
    object[field] = e.target.value;
    this.setState(object);
  }
  /* Authentication checking */
  async authentication(e) {
    e.preventDefault();
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    console.log(reg)
    if (this.state.password != '') {
      this.setState({message: ''})
      const emailId=Base64.decode(this.props.match.params.email)
    const register = {
      guest:2,
      email: emailId,
      password: Base64.encode(this.state.password),
    }
    const userData = await registerService.guestRegister(register)
   if(userData.rowsAffected.length>0){
    this.setState({message: 'Registered Successfully',color:'green'})
    location.href = '/locations';
   }
   else{
    this.setState({message: 'Your Email Already exist',color:'green'})
    location.href = '/locations';
   }
    }
else{
  this.setState({message: 'Enter valid Password',color:'red'})
}
  }
  /* To reset the forgetten password */
  async passwordReset(){ 
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    console.log(reg)
    if (this.state.password != '') {
      this.setState({message: ''})
      const emailId=Base64.decode(this.props.match.params.email)   
      if(this.props.match.params.guestemail=='fivestar')
      {
          const updatepassword =
            {
                    Email: emailId,
                    LoginPassword: this.state.password,
                    ChangedBy: emailId
            }
            const userData = await FiveStarApiServices.DriverForgotPassword(updatepassword)
            if(userData.error=="0"){
              this.setState({message: 'Password Rest Successfully',color:'green'})
              location.href = '/fivestar';
             }
             else{
              this.setState({message: 'Unable to update your password at this time',color:'red'})
             }
      }
  else{
    const updatepassword = {
                              email: emailId,
                              password: Base64.encode(this.state.password),
                              update:'guest'
                            }
      const userData = await registerService.updatePassword(updatepassword)
      if(userData.rowsAffected[0]>0)
      {
        this.setState({message: 'Password Rest Successfully',color:'green'})
        location.href = '/locations';
       }
       else
       {
        this.setState({message: 'Unable to update your password at this time',color:'red'})
       }
  }
  }
  else{
    this.setState({message: 'Enter valid Password',color:'red'})
  }
  }
  /* It is invoked to return html content */
  render() {
    const email=Base64.decode(this.props.match.params.email)
    const url=this.props.match.params.guestemail 
    const {expired,loader}=this.state;
    return (
      <div>
        {loader? <div>
           {(url =='password' || url =='guestemail') && <GuestHeader/>}
      <div className="container">
      <div className="login_star">
        <div className="contactus_page">
        {url =='fivestar' &&
           <div>         
          <img src="/assets/newUI-images/5-starLogo.png" alt="" />
          </div> }
        {url =='password' || url =='fivestar' ? <h5  className="wow animated fadeInLeft">Reset Your Password </h5> :<h5 className="wow animated fadeInLeft">
           Register Now For a SuiteAmerica Account as GuestProspective</h5>}         
           {expired ?<div className="contact-us pattern-bg wow animated fadeInRight f-contact-form-style">
            <div className="col-sm-12">
              {this.state.message
                ? <span
                    style={{ color: this.state.color,textAlign: 'right'}}>{this.state.message}</span>
                : ''}
            </div>  
             <div className="">
             <div className="col-sm-12">
                <div className="form-group">
                  <input
                    type="email"
                    id="email"
                    className="form-control"
                    value={email}
                    disabled
                   placeholder="Email"/>
                </div>
              </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <input
                        type="password"
                        id="subject"
                        className="form-control"
                        value={this.state.password}
                        onChange={(e) => this.setValue('password', e)}
                        placeholder="Create Password"/>
                    </div>
                  </div>      
                  {url =='password' || url =='fivestar' ? <div className="text-center">
              <button
                    type="submit"
                    className="button"
                    onClick={this.passwordReset}>Update </button>
              </div>:<div className="text-center">
              <button
                    type="submit"
                    className="button"
                    onClick={(e) => this.authentication(e)}>REGISTER</button>
              </div>}
            </div>
            </div>:<div><h1>Your Email is Expired</h1></div>} 
        </div>
        </div>
      </div>   
      {(url =='password' || url =='guestemail' )&& <Footer/>}
      </div>:<Loader/>}
      </div>
    );
  }
}
export default GuestProspective;