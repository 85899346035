/* Importing the node modules, child components, services and controllers used 
   inside LandingVideo component */
   import $ from "jquery";
   import AppController from "../../controller/appController";
import {Link} from 'react-router-dom';
import SubFooter from "../../common/subFooter";
import GuestHeader from '../../common/guestHeader';
import Footer from '../../common/footer';
import ReserveYourStay from '../../common/reserveYourStay.js';
import MetaTags from '../../common/metaTags';
import ServerMetaTags from '../../../server/metaTags';
import ViewData from "./view.json";

/* LandingVideo Component initialization */
class LandingVideo extends React.Component {
    /* Initializing objects of its LandingVideo class */
    constructor(props) {
        super(props);
        this.state = {
            searchLocation: '',
            errorMessage: '',
            latitude: '',
            longitude: '',
            spotLightCitys: ViewData
        }
        this.setLocation = this.setLocation.bind(this);
        this.locationSearch = this.locationSearch.bind(this);
        this.linksOpen = this.linksOpen.bind(this);     
    }
    /* It is invoked immediately before mounting occurs */
     componentWillMount() {
      
        AppController.removeLocalStorageData();
        $('html, body').animate({scrollTop: 0});
        AppController.stickyTitles();
       
    }
    imagesCorousel() {
      jQuery(function ($) {
      $(function () {
        $('#myCarousel').carousel({
          interval: 5000,
          pause: "false"
        });
      });
    });  } 
    async componentDidMount(){
      await this.imagesCorousel();
      // var video = $('#video-background')[0];
      // video.src = 'https://suiteam-website.s3-us-west-1.amazonaws.com/SA_Future.mp4';
      // $(document).ready(function() {     
      //    var a=0; 
      //    video.onended = function() {
      //       a=a+1;
      //       if(a!=3)
      //       {
      //          video.load();
      //          video.play();
      //          $('.animation-slide').show();
      //          setTimeout(function(){
      //              $('.animation-slide .second, .animation-slide .second .one, .animation-slide .second .two, .animation-slide .second .three, .animation-slide .second .four, .animation-slide .second .five, .animation-slide .second .six').css({'opacity': '1'});                
      //          },19000);
      //        }
      //    };
  
      // });
    }
    autoPlayVideo() {
      const id = '#global-background';
      const source = 'https://suiteam-website.s3-us-west-1.amazonaws.com/Videos/COVID+Video+Series+1.mp4';
      AppController.autoPlayVideo(id, source);
  }
    /* To update the location field auto complete value */
    setLocation(e) {
        const _this = this;
        var places = new google.maps.places.Autocomplete(document.getElementById('locations-search'));
        google.maps.event.addListener(places, 'place_changed', function () {
             var place = places.getPlace();
             const latitude = place.geometry.location.lat();   
             const longitude =  place.geometry.location.lng();
            _this.setState({searchLocation: places.getPlace().formatted_address, latitude, longitude}); 
        });        
    }
    locationSearch() {
        const locationAddress = {            
            "image" : "",
            "title" : "PREMIUM LOCATIONS",
            "subTitle": "WORLDWIDE",
            "city" : "",
            "state" : "",
            "country" : "",
            "countryId" : "",
            "state_id" : "",
            "city_id" : "",
            "address" : this.state.searchLocation,
            "latitude": this.state.latitude,
            "longitude": this.state.longitude,
            "webUrl" : "locations" 
        }
        if(this.state.searchLocation != '') {
            _.assign(locationAddress, {amenities_id : ''});
            localStorage.setItem('savedSearch', JSON.stringify(locationAddress));
            setTimeout(function() { 
             this.props.history.push(locationAddress.webUrl)
            }.bind(this), 50);
        }
        else{
            this.setState({errorMessage: 'Please Enter Location Address'});
        }
    }
    linksOpen(href){
        var link = document.createElement('a');
        link.href = href;
        link.target = '_blank';
        link.dispatchEvent(new MouseEvent('click'));
    }
    /* It is invoked to return html content */
    render() {
        const metaData = ServerMetaTags.urlString.getMetaData(this.props.match.url);
        return (
            <div className="homethree">
            <MetaTags 
               metaTitle = {metaData.title}
               metaDesription = {metaData.description}
               metaUrl = {metaData.canonical} />
            <GuestHeader/>
            {/* <h1 className="h1 mainheading_ribbon"> 
            <strong className="ribbon-content">
            </strong>
            FOR MORE INFORMATION ABOUT THE PG&E POWER OUTAGES IN NORTHERN CALIFORNIA, <a target="_blank" href="https://www.pge.com/en_US/safety/emergency-preparedness/natural-disaster/wildfires/public-safety-event.page?WT.pgeac=GlobalHeader-PSPS-Oct19">CLICK HERE</a>  </h1> */}
            <h1 className="h1 mainheading_ribbon h1-new">  
            <strong className="ribbon-content">
            </strong>
            If you or someone you know has been affected by the Oregon or California wildfires and needs temporary housing, <span>please contact us at&nbsp;<strong>800.367.9501</strong>.</span>  </h1>
 
            <h1 className="h1 mainheading_ribbon"> 
            <strong className="ribbon-content">
            </strong>
            For More Information On SuiteAmerica’s response to COVID-19, <a target="_blank" href="/covid19-safety-measures">Click Here</a>.  </h1>

            <header className="cc_image_text_main corporate-h fullvideo landing">
          {/*  <video autoPlay playsInline muted  loop id="global-background" preload="auto">
                        <source type="video/mp4" />
         </video> */}
            <div id="myCarousel" className="carousel slide sa-fururepagebanner">
            <div className="carousel-inner">
              <div className="item active">
              <img src={"/assets/newUI-images/Website-Images/1250_Lakeside_banner.jpg"} />
              </div>
              <div className="item">
              <Link to='/thedean'>
                <img src={"/assets/newUI-images/Website-Images/DeanBanner2.jpg"} />
                </Link></div>
              <div className="item">
               {/* <Link to='/mylo'> */}
                <img src={"/assets/newUI-images/Website-Images/mylo-banner-home.jpg"} />
                {/* <input type="number" id="test"></input> */}
               {/*   </Link>  */}    </div>  
            </div>
          </div>
          
   







           
       
            {/* 
            <Link to="/future">
            <div className="video-wrapper">
                    <video playsInline autoPlay muted  id="video-background" preload="auto" poster="assets/images/future-banner.png">
                        <source type="video/mp4"/>
                    </video>
                </div></Link> */}
                
                {/* <div className="img-on-text top20 width600 width600-sm text-center img-on-text-center-w">
                    <div className="animation-slide">
                        <h1>EVERYTHING UNDER <br/>ONE ROOF. OURS.</h1>
                        <div className="first">
                        <p>One contact, One dedicated team, One more reason to choose SuiteAmerica. We combine
                            our cutting edge technology and award-winning service to create a premium level experience like no other, world-wide
                        </p>
                        </div>
                        <div className="second text-center">
                        <p className="one">Corporate Housing</p>
                        <p className="two">Serviced Apartments</p>
                        <p className="three">Destination Services</p>
                        <p className="four">Advanced Interactive Tools</p>
                        <p className="five">Real-Time Reporting Solutions</p>
                        <p className="six">Global Coverage</p>
                        </div>
                    </div>
                </div> */}
                <ReserveYourStay/>
                 <img style={{cursor: 'pointer'}} className="img-responsive relocation-corporate-housing mrg-top-0" src={"/assets/newUI-images/Website-Images/DSBanner.jpg"} width="100%" alt=""  onClick={()=>this.linksOpen('/corporation-destination-services')}/>
  
            </header>
            
            <div className="who_weare services_ch">
               <div className="cc_image_text_main">
                  <img className="img-responsive left-mo" src={"/assets/newUI-images/Website-Images/Corporation-Destination-Services-Our-Fleet-Vehicles.jpg"} alt="" />
                  <div className="img-on-text top15 left10 width500 md-text left60-md top30-md left55-sm top30-sm wow fadeInUp text-center" data-wow-delay="0.5s">
                     <h1 className="new_home_head">SUITEAMERICA’S <br/> CORPORATE HOUSING AND DESTINATION SERVICES
                     </h1>
                  </div>
               </div>
            </div>
            <div className="hover_txt_sec mrg-btm-0">
               <div className="new_txt_content container wow fadeInUp animated">
                  <div className="col-md-10 col-md-offset-1">
                     <h2>EXPLORE SERVICED APARTMENTS</h2>
                     <div className="text-center color-red">{this.state.errorMessage}</div>
                     <div className="hover_search_form">
                        <div className="form-group">
                           <input type="text" className="form-control" id="locations-search" 
                           onChange={(e) => this.setLocation(e)}/>
                           <div className="info-field-icon">
                              <Link to="#">
                              <i className="fa fa-search"></i>
                              </Link>
                           </div>
                        </div>
                        <button className="btn btn-primary" onClick={this.locationSearch}>Location Search</button>
                     </div>
                  </div>
               </div>
               <div className="clearfix"></div>
            </div>
            <div className="services_ch grey-xs mrg-top-100 mrg-top-0-md">
               <div className="carousel banner_text">
                  <img className="img-responsive" src={s3BucketUrl_WebsiteImages+"Individual-Corporate-Housing-pts.jpg"} alt="" />
                  <div className="img-on-text width800 left30 top25 md-text left60-md top30-md left55-sm top30-sm wow fadeInUp text-center" data-wow-delay="0.5s">
                     <h1 className="new_home_head">AWARD WINNING SERVICE FROM A DEDICATED TEAM</h1>
                     <h3>
                        AN UNRIVALED LEVEL OF SERVICE TO ENSURE YOU AND YOUR
                        FAMILY FEEL TRULY AT HOME.
                     </h3>
                  </div>
               </div>
            </div>
            <div className="hover_txt_sec mrg-btm-0">
               <div className="new_txt_content container wow fadeInUp animated">
                  <div className="suite-sub-social text-center">
                     <ul className="list-inline per_social_list home_per_social_list text-center">
                        <li><a href="https://www.facebook.com/SuiteAmerica" target='_blank'><i className="fa fa-facebook-square"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/108459?trk=saber_s000001e_1000" target='_blank'><i className="fa fa-linkedin"></i></a></li>
                        <li><a href="https://www.instagram.com/suite_america" target='_blank'><i className="fa fa-instagram"></i></a></li>
                        <li><a href="https://twitter.com/SuiteAmerica" target='_blank'><i className="fa fa-twitter"></i></a></li>
                     </ul>
                  </div>
               </div>
               <div className="clearfix"></div>
            </div>
            <div className="services_ch grey-xs mrg-top-100 mrg-top-0-md">
               <div className="carousel banner_text">
                  <img className="img-responsive" src={"/assets/newUI-images/Website-Images/Corporation-Technology-Five-Star.jpg"} alt="" />
                  <div className="img-on-text width600 left5 top55 md-text color-dark left60-md top50-md left55-sm top30-sm wow fadeInUp text-center" data-wow-delay="0.5s">
                     <h1 className="new_home_head">INDUSTRY LEADING INNOVATION</h1>
                        <h3 className="color_dark">WE CREATED THE MOST ADVANCED <br />
                        TECHNOLOGIES FOR A SEAMLESS <br />
                        RELOCATION EXPERIENCE.</h3>
                     
                  </div>
               </div>
            </div>
            <SubFooter/>
            <Footer/>
         </div>         
        );
    }
}
export default LandingVideo;