import Footer from "./footer";
import Header from "./header";
import RequestCallback from "./requestCallback";
import TransHeader from "./transheader";

class TechnologyNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
  }
  handleClick() {
    $("#sideQuote").addClass("active");
    $(".overlay").addClass("active");
    $(".collapse.in").toggleClass("in");
    $("a[aria-expanded=true]").attr("aria-expanded", "false");
  }
  render() {
    return (
      <div className="SAweb_updateUi">
        <Header />
        <section className=" SAweb_techBanner">
          <div className="container">
            <div className="SAweb_techBannerVideo">
              <div className="textView">
                <h1 data-aos="fade-up">
                  Driven by technology, inspired by you.
                </h1>
                <p data-aos="fade-up" className="p">
                  {" "}
                  At SuiteAmerica, technology and innovation are at the center
                  of everything we do. Our internally developed proprietary
                  software systems help us serve you better while also making us
                  the market leader in the industry.{" "}
                </p>
                <button
                  type="button"
                  className="btn black-btn"
                  onClick={this.handleClick}
                >
                  {" "}
                  Reserve Now{" "}
                </button>
              </div>
              <video width="100%" playsInline autoPlay muted loop>
                <source
                  src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/videos/suite_america_animated_image_bg.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
            <div></div>
          </div>
        </section>
        <section className="SAweb_tachBest py-5">
          <div className="container">
            <div className="tails" data-aos="fade-up">
              <div className="item">
                <h1>What makes our tech the best.</h1>
                <h3>Our technology drivers.</h3>
              </div>
              <div className="item">
                <img
                  src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/technology/cloud-computing.png"
                  alt=""
                />
                <h3>Cloud-based Communications</h3>
                <p>
                  {" "}
                  Our centralized IT ecosystem enables us to provide up-to-date,
                  live information, connecting all internal and external teams.{" "}
                </p>
              </div>

              <div className="item">
                <img
                  src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/technology/technological.png"
                  alt=""
                />
                <h3>World-class in-house technology</h3>
                <p>
                  Built for corporate housing, by corporate housing, our tech is
                  constructed completely around the industry insight and tenure.
                </p>
              </div>
              <div className="item">
                <img
                  src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/technology/api.png"
                  alt=""
                />
                <h3>120+ APIs to serve you better</h3>
                <p>
                  Crafted for instant communication and real-time updates, our
                  APIs help us respond faster to all your requests.
                </p>
              </div>
              <div className="item">
                <img
                  src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/technology/menu.png"
                  alt=""
                />
                <h3>3 Apps and a whole world of difference</h3>
                <p>
                  {" "}
                  Secure and easy to use platforms that help us create a
                  better-Customized guest experience with faster service
                  updates.{" "}
                </p>
              </div>
              <div className="item">
                <img
                  src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/technology/clicking.png"
                  alt=""
                />
                <h3>Tap. Tap. Booked</h3>
                <p>
                  Now, you can literally book stays within 30 seconds with
                  SuiteAmerica Technology.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="SAweb_tachmadeMadeEasy text-center">
          <div className="container">
            <div className="row align-items-center" data-aos="fade-up">
              <div className="col-md-6">
                <h2>Corporate Housing Made Easy With Our Tech</h2>
              </div>
              <div className="col-md-6">
                <div className="tails">
                  <div className="item">
                    <div className="pic">
                      <img
                        src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/marketplace.svg"
                        alt=""
                      />
                    </div>
                    <div className="textView">
                      <h3>The Suite Marketplace</h3>
                      <p>
                        Don’t see a particular locale in our inventory? Our
                        platform aids you in finding the best stays across the
                        U.S. by connecting you to our suppliers and trusted
                        partners. So no matter where you wish to reside, we’ve
                        got you covered.
                      </p>
                    </div>
                  </div>
                  <div className="item">
                    <div className="pic">
                      <img
                        src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/book_it.svg"
                        alt=""
                      />
                    </div>
                    <div className="textView">
                      <h3>Book It</h3>
                      <p>
                        Now, book your stays in an instant with our advanced
                        booking engine. Receive notifications and instant
                        confirmation emails. Our live chat support is always
                        here to help if you’re stuck anywhere.
                      </p>
                    </div>
                  </div>
                  <div className="item">
                    <div className="pic">
                      <img
                        src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/duty_of_care%20Portal.svg"
                        alt=""
                      />
                    </div>
                    <div className="textView">
                      <h3>Duty of Care Portal</h3>
                      <p>
                        Our organized dashboard keeps you in the loop with your
                        employees. From where they’re staying to updates about
                        their service requests, you get real-time insights about
                        their stay with our portal.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="SAweb_tectChapplogo text-center ">
          <img
            src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/technology/chappLogo.png"
            alt=""
            width="335px"
            data-aos="fade-up"
          />
          <h3 data-aos="fade-up">Innovation of the Year Award 2020</h3>
        </section>
        <section className="SAweb_gHousingGuestExpc ghPage bg-white">
          <div className="container">
            <div className="rows align-items-center" data-aos="fade-up">
              <div className="col-sm-6 col-main">
                <div>
                  <h2>The Tech That Enables a Superior Guest Experience</h2>
                  <p className="p">
                    Through our technology, our guests can view their
                    reservation information, file and track service requests and
                    learn about their apartment community.
                  </p>
                </div>
              </div>
              <div className="col-sm-6 col-main">
                <div className="tailBox">
                  <a href="/guest-login">
                    {" "}
                    <div className="item">
                      <img
                        src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/gHousing/laptop.png"
                        alt=""
                      />
                      <h3>Guest Portal</h3>
                    </div>
                  </a>
                  <a
                    href="https://apps.apple.com/app/guestportal/id1492423942"
                    target="_blank"
                  >
                    {" "}
                    <div className="item">
                      <img
                        src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/gHousing/mobileApp.png"
                        alt=""
                      />
                      <h3>Mobile App</h3>
                    </div>
                  </a>
                  <div className="item">
                    <img
                      src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/gHousing/apple-tv.png"
                      alt=""
                    />
                    <h3>TV App</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="SAweb_tachEnables ">
          <div className="container">
            <div className="headings" data-aos="fade-up">
              <h2>Manage stays anywhere, anytime</h2>
            </div>
            <div className="text-center" data-aos="fade-up">
              <img
                className="my-5 img-fluid"
                src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/technology/deviceImage.png"
                alt=""
              />
            </div>

            <div className="tails">
              <a href="/guest-login">
                <div className="item">
                  <h3>Guest Portal</h3>
                  <p className="p min-height-3">
                    An interactive portal to access information from anywhere.
                  </p>
                  <a href="/guest-login" className="btn black-btn">
                    Guest Login
                  </a>
                </div>
              </a>
              <a
                href="https://apps.apple.com/app/guestportal/id1492423942"
                target="_blank"
              >
                {" "}
                <div className="item">
                  <h3>Mobile App</h3>
                  <p className="p min-height-3">
                    User-friendly mobile app to personalize your stay on the go.
                  </p>
                  <a
                    href="https://apps.apple.com/app/guestportal/id1492423942"
                    target="_blank"
                  >
                    <img
                      src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"
                      alt="app store"
                      width={150}
                    ></img>
                  </a>{" "}
                  <a
                    href="https://play.google.com/store/apps/details?id=com.suiteamerica.guestportal&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                    target="_blank"
                    className="p-l-10"
                  >
                    <img
                      src="/assets/newUI-images/google-play-store-badge.svg"
                      alt="Play store"
                      height={50}
                    ></img>
                  </a>
                </div>
              </a>
              <div className="item">
                <h3>TV App</h3>
                <p className="p min-height-3">
                  An App on your TV so you’re in control of the nitty-gritty
                  throughout your stay.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="SAweb_tachSecureCompliant bg-white">
          <div>
            <div className="tails" data-aos="fade-up">
              <div className="item">
                <h2>A Secure & Compliant Technology Suite</h2>
                <p className="p">
                  From Guest Portal to Mobile Apps, all our APIs are compliant
                  with industry standards ensuring data security. Our
                  cloud-based solutions are built using scalable technology to
                  enhance user experience.
                </p>
              </div>
              <div className="item">
                <div className="pic">
                  <img
                    src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/technology/compliant_tech.png"
                    alt=""
                    width="100%"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="SAweb_tachInternal ">
          <div className="container">
            <h2>Internal Technology</h2>
            <div className="box ">
              <div className="item" data-aos="fade-up">
                <div className="image">
                  {" "}
                  <img
                    src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/chapp_webapp.svg"
                    alt=""
                    width="200px"
                  />
                </div>

                <h3>CHAPP</h3>
                <p>
                  CHAPP is our Corporate Housing App that serves as an
                  end-to-end solution to manage all your bookings, inventory and
                  services.
                </p>
              </div>
              <div className="item" data-aos="fade-up">
                <div className="image">
                  {" "}
                  <img
                    src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/five_star_service.svg"
                    alt=""
                    width="170px"
                  />
                </div>

                <h3>5-Star Service App</h3>
                <p>
                  Whether it’s a new delivery or revising the housekeeping
                  schedule, with the 5 Star App, we’re always just a click away,
                  bestowing you with the best business hospitality.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="SAweb_tachInternal text-center bg-white">
   <div className="SAweb_location bg-white ">

    <div className="row   mx-0" data-aos="fade-up">
         <div className="col-md-1 ">
           <div className="HeadingRotate">
            <h2 className="h2_rotate">Internal Technology</h2>
           </div>
                   
         </div>
         <div className="col-md-10 py-5">
    
      
          <div className="box mb-5">
            <div className="item" data-aos="fade-up">
              <div className="image"> <img src="assets/newUI-images/technology/mobile-app.png" alt="" width="200px" /></div>
             
              <h2>Chapp</h2>
              <p>CHAPP is our Corporate Housing App that serves as an end-to-end solution to manage all your bookings, inventory and services.</p>
            </div>
            <div className="item" data-aos="fade-up">
              <div className="image">   <img src="assets/newUI-images/technology/stars.png" alt="" width="170px" /></div>
           
              <h2>5 Star Experience 
                With Our 5 Star App</h2>
              <p> The app enables us to manage new deliveries, service requests, maid schedules, and turnover on real time basis so that our guests always have the best experience.</p>
            </div>
    
          </div>
              
              
         

         </div>
    </div>
</div>
</section> */}
        <RequestCallback />

        <Footer />
      </div>
    );
  }
}
export default TechnologyNew;
