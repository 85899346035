const FunStuffModal = (props) => {
    const row = props.rowData
    const index = props.index
    const funStuff=row.FunStuffList!=undefined?JSON.parse(row.FunStuffList):[]
    return(
        <div className="modal fade savesearch" id="funstuff" role="dialog">
           <div className="modal-dialog">
                <div className="modal-content rm-border-radius">
                    <div className="modal-header">
                    <button type="button" className="close btn" data-dismiss="modal" aria-label="">
                    <i className="bi bi-x"></i>
                    </button>
                    </div>
                    <div className="modal-body">
                    <h2>FunStuff</h2>

                    <p className="text-left mrg-btm-10"><strong className="color-red">Suite# :</strong> {row.SuiteNo}</p>
                    <p className="text-left"><strong className="color-red">Community : </strong> {row.community_name}</p>
                <table className="table table-striped table-bordered table-hover text-left">
                    <tbody>
                <tr>
                    <th>
                        Items
                    </th>
                    <th>
                   Delivey Date
                    </th>
                </tr>
                {funStuff.map((data,i)=>{
                    return(
                        <tr key={i}>
                        <td>{data.Item}</td>
                        <td>{data.DeliverDate}</td>
                    </tr>
                    )
                })}
                </tbody>
                </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default FunStuffModal;