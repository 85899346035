/* Importing the node modules, child components, services and controllers used 
   inside TeslaHome component */    
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import TeslaHeader from "../../common/teslaHeader";
   import {Link} from 'react-router-dom';
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   /* TeslaHome Component initialization */
   class TeslaHome extends React.Component {
       /* Initializing objects of its TeslaHome class */
       constructor(props) {
           super(props);
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
           MainController.getInitialInformation();
        }
       /* It is invoked to return html content */
       render() {
           return (
            <div className="corporateHousing_Updates telsa-page-view">
                <GuestHeader/>

                <header className="codeconveyHeader cc_image_text_main corporate-h">
                    <div className="container-view ">
                    <img className="img-responsive" src="/assets/images/tesla/banner.png" width="100%" alt=""  />
                    <div className="wow fadeInUp    text-center  img-on-text-center company_bannertxt" data-wow-delay="0.5s">
                        {/* <img src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Company-Nike-Logo.png"} className="nike_img" width="150px" alt="" /> */}
                        <h1>SuiteAmerica is Proud to Offer Temporary Housing to Tesla Employees</h1>
                        <p>Whether You’re an Individual or a Group, 
                            We Can Take Care of Your Housing Needs.
                        </p>
                    </div>
                    </div>
                </header>
                <TeslaHeader />
                <div className="quote-section bg-white">
                    <div className="container">
                        <div className=" text-center col-md-10 col-md-offset-1 wow fadeInUp company_p" data-wow-delay="0.5s">
                            <p>As a preferred vendor to Tesla, SuiteAmerica is here to help you with your temporary housing needs in key U.S locations. SuiteAmerica’s apartments are fully furnished and equipped with all the amenities of a home to support a better experience for longer terms stays. We invite you to review this dedicated Tesla site and request a reservation – SuiteAmerica looks forward to having you as our Guest!
                            </p>
                        </div>
                    </div>
                  
                </div>
                <div className="who_weare_text service_ch_new company_p_height">
                        <div className="container">
                        <h1 className="gotham-book mrg-top-20 mrg-btm-40">STAYING WITH SUITEAMERICA IS EASY!</h1>
                            <div className="row rows">
                            <div className="col-md-6 col-sm-6">
                                <div className="wow fadeInUp company_p dual_quote" data-wow-delay="0.5s">
                           
                                   
                                    {/* <p>Visit our location page to see the various communities Available in Tesla’s key areas.
                                    </p> */}
                                    <p>Click View Locations to review Tesla’s preferred properties and locations.</p>
                                    <Link to="/company/tesla/locations" className="box-slide-btn">VIEW LOCATIONS</Link>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <div className="wow fadeInUp company_p dual_quote" data-wow-delay="0.5s">
                                    <p>Click the Reserve Now button to make a reservation request, or request for more information.</p>
                                    <Link to="/company/tesla/reservation-form" className="box-slide-btn">RESERVE NOW</Link>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid bg-grey">
                     <div className="container">
                         <div className="bottom-tesla-text">
                         <h1>Responses to COVID-19</h1>
                        <p> <i className="fa fa-circle"></i> <b>SuiteAmerica’s</b> response to COVID-19, <a href="/covid19-safety-measures" target="_blank">Click Here.</a></p>
                        <p> <i className="fa fa-circle"></i> <b>Tesla’s</b> response to COVID-19, <a href="https://teslamotorsinc.sharepoint.com/sites/COVID-19" target="_blank">Click Here.</a></p>
                         </div>
                        
                     </div>
                   </div>
                <SubFooter/>
                <Footer/>
            </div>   
           );
       }
   }
   export default TeslaHome;