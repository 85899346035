/* Importing the node modules, child components, services and controllers used 
   inside IntranetCustomRequestfour component */   
   
   /* IntranetCustomRequestfour Component initialization */
   class IntranetCustomRequestfour extends React.Component{
       /* Initializing objects of its IntranetCustomRequestfour class */
       constructor(props) {
           super(props)   
           this.state = {
            ContactName: '',
            phone: '',
            email: '',
            address: '',
            specialInstruction: '',
            message:'',
            color:''
           }
           this.setValue = this.setValue.bind(this);
           this.previous = this.previous.bind(this);
           this.submit = this.submit.bind(this);
         }
         previous(data){

            this.props.previous(data)
            } 
            submit(){
                var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
                if((this.props.email!='' && reg.test(this.props.email)) && this.props.ContactName !='' ){
                    this.props.submit()
                }
                else{
                    this.setState({message:'Enter Name and Valid Email',color:'red'})
                }
            }  
            setValue(field, e) {
                this.setState({message:'',color:''})
                var object = {};
                object[field] = e.target.value;
                this.props.setValue(object);
            }
   /* It is invoked to return html content */       
   render(){
       return(
        <div className="inet_custom">
   <div className="container">
      <h1 className="wow animated zoomIn">MARKETING REQUEST FORM</h1>
      <div className="inet_custom_form">
         <form id="msform" className="wow animated fadeInLeft">
            {/* <!-- progressbar --> */}
            <ul id="progressbar">
               <li className="active"></li>
               <li className="active"></li>
               <li className="active"></li>
               <li className="active"></li>
            </ul>
            {/* <!-- fieldsets --> */}
         </form>
         <form className="inet_custom2 wow animated fadeInRight ">
            <div className="col-sm-12">
               <div className="form-group">
                  <p>CONTACT INFORMATION</p>
               </div>
            </div>
            <div style={{color:this.state.color,textAlign:'center'}}><span>{this.state.message}</span></div>
            <div className="col-sm-12">
               <div className="form-group">
               <div className="withinput">
                  <input type='text'  className="form-control" placeholder=" Name*" value={this.props.ContactName} 
                  onChange={(e) => this.setValue('ContactName', e)}/>
               </div>
               </div>
            </div>
            <div className="col-sm-12">
               <div className="form-group">
                  <div className="withinput">
                  <input type='text' className="form-control" placeholder=" Phone " value={this.props.phone} 
                  onChange={(e) => this.setValue('phone', e)}/>
                  </div>
               </div>
            </div>
            <div className="col-sm-12">
               <div className="form-group">
                  <div className="withinput">
                  <input type='text' className="form-control" placeholder=" Email*" value={this.props.email} 
                  onChange={(e) => this.setValue('email', e)}/>
                  </div>
               </div>
            </div>
            <div className="col-sm-12">
               <div className="form-group">
                  <div className="withinput">
                  <input type='text' className="form-control" placeholder=" Address " value={this.props.address} 
                  onChange={(e) => this.setValue('address', e)}/>
                  </div>
               </div>
            </div>
            <div className="col-sm-12">
               <div className="form-group">
                  <textarea rows="4" cols="50" placeholder="Delivery/Special Instructions" value={this.props.specialInstruction} onChange={(e) => this.setValue('specialInstruction', e)}></textarea>
               </div>
            </div>
            
            {this.props.loader ? 
              <div className="panel-body_loader" > <span style={{fontSize: '25px', width: '100%', margin: 'auto', textAlign: 'center'}}><i className="fa fa-spinner fa-spin"/> please wait ...!</span>
              </div>: <div className="text-center">  <button type="button" className="button inet_button" 
              onClick={() => this.previous(3)} ><i className="fa fa-caret-left"></i> PREVIOUS</button>                     	     
                      <button type="button" className="button inet_button sbmt" onClick={this.submit} >SUBMIT</button>   
            </div>}
         </form>
      </div>
   </div>
</div>      
   
       );
   }
   }
   export default IntranetCustomRequestfour;