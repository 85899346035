'use strict'

import LocationService from '../../../../services/locationService';
import EncryptDecrypt from '../../../../services/encryptDecrypt';
import MapController from "../../../controller/mapController";
import AppController from "../../../controller/appController";
import $ from "jquery";

class CommunityLocationModal {
  constructor(thisObject, props) {
    this.Obj = thisObject
    this.propsObj = props
    this.state = {
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      communityObject: {},
      appartmentAmenities: [],
      communityAmenities: [],
      communityImages: [],
      communityId: '',
      address: '',
      latLng: {},
      recentlyViewData: [],
      userFavourites: [],
      destinationAdd: '',
      email: '',
      password: '',
      confirmPassword: '',
      message: '',
      color: '',
      communityALLImages: [],
      userData: {},
      walkscoreLoading: false,
      greatSchools: {},
      walkScoreDetails: [],
      communityName: '',
      latitude: 0.0,
      longitude: 0.0,
      types: [{ name: 'Restaurants', type: 'restaurant', icon: 'https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/mapview/restarent.png' },
      { name: 'Bars', type: 'bar', icon: 'https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/mapview/bars.png' },
      { name: 'Groceries', type: 'supermarket', icon: 'https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/mapview/groceries.png' },
      { name: 'Parks', type: 'park', icon: 'https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/mapview/parks.png' },
      { name: 'Schools', type: 'school', icon: 'https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/mapview/shopping.png' },
      { name: 'Entertainment', type: 'night_club', icon: 'https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/mapview/entertainment.png' },
      ],
      type: ['restaurant'],
      typeName: '',
      location: [],
      placeSearched: [],
      placeSearchedMarkers: [],
      markerList: [],
      showlist: false,
      showSearch: false,
      typeicon: 0,
      directionsService: {},
      directionsDisplay: {},
      width: 0,
      communityDetails: false,
      calendarStatus: false,
      pdfLoading: false,
      PageLoader: false,
      appartmentamenitieslist: [],
      walkscore: '',
      transitscore: '',
      bikescore: ''
    }
  }

  async walkScoreInfo(communityData) {
    this.walkscoredata(communityData);
  }
  async walkscoredata(communityData) {
    const greatSchools = await AppController.greatSchools2(communityData);
    const results = await AppController.wallsckoreFuns(communityData);
    const lines = await AppController.busrailslines(communityData);
    let rails = [];
    let bus = [];
    _.filter(lines, function (o) {
      if (o.route_summary[0].category == 'Bus') {
        bus.push(o);
      }
      if (o.route_summary[0].category == 'Rail') {
        rails.push(o);
      }
    });
    if (results != undefined && results != null && results != 0 ) {
     await this.updateState({
        greatSchools: greatSchools, walkscoreLoading: true,lines:lines,buslines:bus,railines:rails,
        walkscore: results.walkscore, transitscore:results.transit == null?null:results.transit.score, bikescore: results.bike.score,
      })
        
      }
    else {
      await this.updateState({ greatSchools: greatSchools, walkscoreLoading: true, walkScoreDetails: [] })
    }
  }



  async getDetails(data) {
    const communitydetails = await JSON.parse(localStorage.getItem('moreData'))
    var propsCommunity =this.propsObj.match.params.name=='BOULEVARD-APARTMENTS-FREMONT'?
    'BOULEVARD APARTMENTS-FREMONT':this.propsObj.match.params.name=='THE-CARLYLE-SANTA-CLARA'?
    'THE CARLYLE-SANTA CLARA':this.propsObj.match.params.name=='PARK-CENTRAL-CONCORD'?
    'PARK CENTRAL/CONCORD':this.propsObj.match.params.name=='THE-COVE-TIBURON'?
    'THE COVE/TIBURON':this.propsObj.match.params.name=='WATERFORD-PLACE-DUBLIN'?
    'WATERFORD PLACE/DUBLIN':this.propsObj.match.params.name.replace(new RegExp('-', 'gi'),' ');
    var communityName = (communitydetails != null && communitydetails.community_name != '' && communitydetails.community_name != null && communitydetails.community_name != undefined)
      ? communitydetails.community_name
      : propsCommunity
    const limitData = {
      communityName: (data != '' && data != null && data != undefined)
        ? data.community_name
        : communityName,
      imageType: 'banner'
    };
    if(limitData.communityName){
      limitData.communityName = ''+limitData.communityName.replace(/%252B/g, "%2520");
      
     }
    const details = await LocationService.getCommunityInfoByName(limitData)

    await this.updateState({ communityObject: details })
    //this.getNearCommunitysByCommunity()
    const appartmentAmenities = this.Obj.state.communityObject.AmenitiesList != null && this.Obj.state.communityObject.AmenitiesList != undefined ? JSON.parse(this.Obj.state.communityObject.AmenitiesList) : []
    let communityImages = this.Obj.state.communityObject.CommunityImages != null && this.Obj.state.communityObject.CommunityImages != undefined ? JSON.parse(this.Obj.state.communityObject.CommunityImages) : []
    if(this.Obj.state.communityObject.CommunityImages != null && this.Obj.state.communityObject.CommunityImages != undefined){
      communityImages.unshift({ 'image_name': this.Obj.state.communityObject.image_name })
    }
    const communityGroupImages = communityImages.length > 0
      ? _.groupBy(communityImages, 'imageType') : "";

    const complexImages = communityImages != null
      ? communityGroupImages.complex != undefined
        ? communityGroupImages.complex
        : communityGroupImages.interior
      : []
    const apparmentamenites = appartmentAmenities != null && appartmentAmenities != undefined ? _.filter(appartmentAmenities, function (o) {
      return o.communityname == 'Unit';
    }) : [];
    const communityamenites = appartmentAmenities != null && appartmentAmenities != undefined ? _.filter(appartmentAmenities, function (o) {
      return o.communityname == 'Community';
    }) : [];
    await this.walkScoreInfo(details);
    await this.updateState({
      latitude: this.Obj.state.communityObject.latitude, PageLoader: true,
      longitude: this.Obj.state.communityObject.longitude, appartmentAmenities: apparmentamenites, appartmentamenitieslist: appartmentAmenities, communityAmenities: communityamenites, communityImages: complexImages, communityALLImages: communityImages
    })
    var locations = {
      lat: Number(this.Obj.state.communityObject.latitude),
      lng: Number(this.Obj.state.communityObject.longitude)
    };
    if($('#map'+this.Obj.state.communityObject.community_id).length){
      const customMap = new google.maps.Map(document.getElementById('map'+this.Obj.state.communityObject.community_id), {
        zoom: 7,
        center: locations,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      });
    await this.updateState({customMap});
     MapController.customMarkers(this.Obj.state.communityObject, 'singlemark', this.Obj, '', s3BucketUrl_WebsiteImages+'SuiteAmerica-M' +
        'ap-Marker-Red.png',
    customMap); 
    }
    localStorage.removeItem('moreData')
  }

  async getNearCommunitysByCommunity() {
    const webGuest = localStorage.getItem('_u_web');
    const userData = await EncryptDecrypt.decryptData({ 'type': webGuest })
    this.updateState({ userData: userData.recordset[0] })
    var companyName = '';
    switch (this.propsObj.match.params.companyname) {
      case 'Nike':
        companyName = 'Nike';
        break;
      case 'NikeIntern':
        companyName = 'NikeIntern'
        break;
      case 'Adidas Intern':
        companyName = 'Adidas Intern'
        break;
      case 'ServiceNow Intern':
        companyName = 'ServiceNow Intern'
        break;
      case 'Adobe':
        companyName = 'Adobe'
        break;
      case 'Nike Apprenticeship':
        companyName = 'Nike Apprenticeship'
        break;
      case 'Lumentum':
        companyName = 'Lumentum'
        break;
      case 'Tesla':
        companyName = 'Tesla';
        break;
      default:
        companyName = 'suiteamerica';
    }
    const locationdetails = {
      lat: this.Obj.state.communityObject.latitude,
      lon: this.Obj.state.communityObject.longitude,
      communityId: this.Obj.state.communityObject.community_id,
      company: companyName,
      userid: (this.Obj.state.userData != undefined && Object.keys(this.Obj.state.userData).length > 0)
        ? this.Obj.state.userData.Id
        : ''
    }
    const recentlyViewData = await LocationService.getNearCommunitysByCommunity(locationdetails)
    this.updateState({ recentlyViewData: recentlyViewData })
  }
  async favouriteCommunity(data) {
    if (Object.keys(this.Obj.state.userData).length > 0) {
      const communityId = data.community_id
      const favouritedetails = {
        user_id: this.Obj.state.userData.Id,
        community_id: communityId,
        createdBy: this.Obj.state.userData.GuestEmail
      }
      const favourite = await LocationService.saveFavouriteCommunity(favouritedetails)
      await this.getFavourites(favourite, data)
    } else {
      this.updateState({ community: data })
    }
  }
  /**
    * When user login then we get the favourites and the recently viewed data
    */
  async getFavourites(favouriteid, data) {
    const checkCommunityIdInRecentlyViewData = _.findIndex(this.Obj.state.recentlyViewData, function (o) {
      return o.community_id == data.community_id;
    })
    if (checkCommunityIdInRecentlyViewData != -1) {
      const addfavStatusInRecentlyViewed = update(this.Obj.state.recentlyViewData, {
        [checkCommunityIdInRecentlyViewData]: {
          favouritestatus: {
            $set: true
          },
          favouriteId: {
            $set: favouriteid.uniqueid
          }
        }
      });
      await this.updateState({ recentlyViewData: addfavStatusInRecentlyViewed });
    }
  }
  async unFavouriteCommunity(data) {
    const checkCommunityIdInRecentlyViewData = _.findIndex(this.Obj.state.recentlyViewData, function (o) {
      return o.favouriteId == data.favouriteId;
    })
    if (checkCommunityIdInRecentlyViewData != -1) {
      const addfavStatusInRecentlyViewed = update(this.Obj.state.recentlyViewData, {
        [checkCommunityIdInRecentlyViewData]: {
          favouritestatus: {
            $set: false
          }
        }
      });
      await this.updateState({ recentlyViewData: addfavStatusInRecentlyViewed });
    }
    const favouritedetails = {
      favourite_id: data.favouriteId,
      user_id: this.Obj.state.userData.Id
    }
    await LocationService.unFavouriteCommunityByUser(favouritedetails)
  }
  async resetData() {

    await this.updateState({ directionsService: {}, directionsDisplay: {}, destinationAdd: '' });
    document.getElementById('dvDistance').innerHTML = "";
    $('#txtPlaces').val('');
    var locations = {
      lat: Number(this.Obj.state.communityObject.latitude),
      lng: Number(this.Obj.state.communityObject.longitude)
    };
    const customMap = new google.maps.Map(document.getElementById('map' + this.Obj.state.communityObject.community_id), {
      zoom: 7,
      center: locations,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    });
    this.updateState({ customMap });
    await MapController.customMarkers(this.Obj.state.communityObject, 'singlemark', this.Obj, '', s3BucketUrl_WebsiteImages + 'SuiteAmerica-M' +
      'ap-Marker-Red.png',
      this.Obj.state.customMap);
  }
  async updateState(updateObject) {
    await this.Obj.setState(updateObject)
  }
}

export default CommunityLocationModal