import React from 'react'
import GuestSidebar from './guestSidebar';

class NewMoveout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    async componentDidMount() {
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
    }

    render() {

        return (
            <div className="SAweb_updateUi">
                <div className="SAwebGuest_reservePage bg-white">
                    <GuestSidebar />
                    <div id="content" >
                        <div className="SAwebGuest_arrivalInfo">
                            <div className="heading">
                                <h3>Hope to see you soon!</h3>

                            </div>
                            <div className="checkOutList">

                                <ul>
                                    <li>Leave one door key in the lock box provided to you or return to
                                        the leasing office in the key envelope provided. After business
                                        hours and on weekends or holidays, the key can be left in the
                                        community drop box near the leasing office.</li>
                                    <li>For help View our Lockbox Instructions</li>
                                    <li> Leave all remaining keys, gate remotes, parking tags or stickers
                                        and access cards in the provided moving out envelope on the
                                        kitchen counter.</li>
                                    <li>  Make sure all doors are locked upon your departure,</li>
                                    <li> <b>$200 will be charged it all keys are not left upon check out for lock changes </b>  </li>
                                </ul>

                            </div>




                        </div>





                    </div>
                </div>
            </div>
        );
    }
}
export default NewMoveout;