class InspectionFormKitcken extends React.Component {
    constructor(props) {
        super(props)
        this.setChecked = this.setChecked.bind(this);
        this.setValue = this.setValue.bind(this);
    }
    setChecked(field, e) {
        var object = {};
        object[field] = e.target.value;
        this.setState(object);
        this.props.setChecked(object)
    }
    setValue(field, e) {
        var object = {};
        object[field] = e.target.value;
        this.setState(object);
        this.props.setValue(object)
    }
    render() {

        return (
            <div>

                <div className="panel panel-default">
                    <div className="panel-heading">
                        <h4 className="panel-title">
                            <a
                                className="collapsed"
                                data-toggle="collapse"
                                data-parent="#accordion"
                                href="#kitchen"
                                aria-expanded="true"
                                aria-controls="collapse7">
                                Kitchen
                            </a>
                        </h4>
                    </div>
                    <div id="kitchen" className="panel-collapse collapse">
                        <div className="panel-body">
                            <div className="profile-opener">
                                <div className="row">
                                    <div className="col-sm-6 col-md-6">
                                        <div className="row radiosec">
                                            <div className="col-sm-12">
                                                <label className="col-sm-3">Blender</label>

                                                <div className="col-sm-2">
                                                    <label className="custom_radio radio-inline">Ok
                                                        <input
                                                            type="radio"
                                                            name="1"
                                                            value="Ok"
                                                            onChange={(e) => this.setChecked('blender', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <label className="custom_radio radio-inline">Replace
                                                        <input
                                                            type="radio"
                                                            name="1"
                                                            value="Replace"
                                                            onChange={(e) => this.setChecked('blender', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="row radiosec">
                                            <div className="col-sm-12">
                                                <label className="col-sm-3">Toster</label>

                                                <div className="col-sm-2">
                                                    <label className="custom_radio radio-inline">Ok
                                                        <input
                                                            type="radio"
                                                            name="2"
                                                            value="Ok"
                                                            onChange={(e) => this.setChecked('toster', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <label className="custom_radio radio-inline">Replace
                                                        <input
                                                            type="radio"
                                                            name="2"
                                                            value="Replace"
                                                            onChange={(e) => this.setChecked('toster', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-sm-6 col-md-6">
                                        <div className="row radiosec">
                                            <div className="col-sm-12">
                                                <label className="col-sm-3">Coffee Maker</label>

                                                <div className="col-sm-2">
                                                    <label className="custom_radio radio-inline">Ok
                                                        <input
                                                            type="radio"
                                                            name="3"
                                                            value="Ok"
                                                            onChange={(e) => this.setChecked('coffeeMaker', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <label className="custom_radio radio-inline">Replace
                                                        <input
                                                            type="radio"
                                                            name="3"
                                                            value="Replace"
                                                            onChange={(e) => this.setChecked('coffeeMaker', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="row radiosec">
                                            <div className="col-sm-12">
                                                <label className="col-sm-3">Kitchen Towels</label>

                                                <div className="col-sm-2">
                                                    <label className="custom_radio radio-inline">Ok
                                                        <input
                                                            type="radio"
                                                            name="4"
                                                            value="Ok"
                                                            onChange={(e) => this.setChecked('kitchenTowels', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <label className="custom_radio radio-inline">Replace
                                                        <input
                                                            type="radio"
                                                            name="4"
                                                            value="Replace"
                                                            onChange={(e) => this.setChecked('kitchenTowels', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-sm-6 col-md-6">
                                        <div className="row radiosec">
                                            <div className="col-sm-12">
                                                <label className="col-sm-3">Waste Basket</label>

                                                <div className="col-sm-2">
                                                    <label className="custom_radio radio-inline">Ok
                                                        <input
                                                            type="radio"
                                                            name="5"
                                                            value="Ok"
                                                            onChange={(e) => this.setChecked('wasteBasket', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <label className="custom_radio radio-inline">Replace
                                                        <input
                                                            type="radio"
                                                            name="5"
                                                            value="Replace"
                                                            onChange={(e) => this.setChecked('wasteBasket', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="row radiosec">
                                            <div className="col-sm-12">
                                                <label className="col-sm-3">Flatware</label>

                                                <div className="col-sm-2">
                                                    <label className="custom_radio radio-inline">Ok
                                                        <input
                                                            type="radio"
                                                            name="6"
                                                            value="Ok"
                                                            onChange={(e) => this.setChecked('flatware', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <label className="custom_radio radio-inline">Replace
                                                        <input
                                                            type="radio"
                                                            name="6"
                                                            value="Replace"
                                                            onChange={(e) => this.setChecked('flatware', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-sm-6 col-md-6">
                                        <div className="row radiosec">
                                            <div className="col-sm-12">
                                                <label className="col-sm-3">Cookware</label>

                                                <div className="col-sm-2">
                                                    <label className="custom_radio radio-inline">Ok
                                                        <input
                                                            type="radio"
                                                            name="7"
                                                            value="Ok"
                                                            onChange={(e) => this.setChecked('cookware', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <label className="custom_radio radio-inline">Replace
                                                        <input
                                                            type="radio"
                                                            name="7"
                                                            value="Replace"
                                                            onChange={(e) => this.setChecked('cookware', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="row radiosec">
                                            <div className="col-sm-12">
                                                <label className="col-sm-3">Upgraded Cookware</label>

                                                <div className="col-sm-2">
                                                    <label className="custom_radio radio-inline">Ok
                                                        <input
                                                            type="radio"
                                                            name="8"
                                                            value="Ok"
                                                            onChange={(e) => this.setChecked('upgradeCookware', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <label className="custom_radio radio-inline">Replace
                                                        <input
                                                            type="radio"
                                                            name="8"
                                                            value="Replace"
                                                            onChange={(e) => this.setChecked('upgradeCookware', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-sm-6 col-md-6">
                                        <div className="row radiosec">
                                            <div className="col-sm-12">
                                                <label className="col-sm-3">Dishwasher</label>

                                                <div className="col-sm-2">
                                                    <label className="custom_radio radio-inline">Ok
                                                        <input
                                                            type="radio"
                                                            name="9"
                                                            value="Ok"
                                                            onChange={(e) => this.setChecked('dishwasher', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <label className="custom_radio radio-inline">Replace
                                                        <input
                                                            type="radio"
                                                            name="9"
                                                            value="Replace"
                                                            onChange={(e) => this.setChecked('dishwasher', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="row radiosec">

                                            <div className="col-sm-12">
                                                <label className="col-sm-3">Dish Package</label>

                                                <div className="col-sm-2">
                                                    <label className="custom_radio radio-inline">Ok
                                                        <input
                                                            type="radio"
                                                            name="10"
                                                            value="Ok"
                                                            onChange={(e) => this.setChecked('dishPackage', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <label className="custom_radio radio-inline">Replace
                                                        <input
                                                            type="radio"
                                                            name="10"
                                                            value="Replace"
                                                            onChange={(e) => this.setChecked('dishPackage', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-sm-6 col-md-6">
                                        <div className="row radiosec">
                                            <div className="col-sm-12">
                                                <label className="col-sm-3">Stove</label>

                                                <div className="col-sm-2">
                                                    <label className="custom_radio radio-inline">Ok
                                                        <input
                                                            type="radio"
                                                            name="11"
                                                            value="Ok"
                                                            onChange={(e) => this.setChecked('stove', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <label className="custom_radio radio-inline">Call Complex
                                                        <input
                                                            type="radio"
                                                            name="11"
                                                            value="Call Complex"
                                                            onChange={(e) => this.setChecked('stove', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="row radiosec">
                                            <div className="col-sm-12">
                                                <label className="col-sm-3">Microwave</label>

                                                <div className="col-sm-2">
                                                    <label className="custom_radio radio-inline">Ok
                                                        <input
                                                            type="radio"
                                                            name="12"
                                                            value="Ok"
                                                            onChange={(e) => this.setChecked('microwave', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <label className="custom_radio radio-inline">Call Complex
                                                        <input
                                                            type="radio"
                                                            name="12"
                                                            value="Call Complex"
                                                            onChange={(e) => this.setChecked('microwave', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-sm-6 col-md-6">
                                        <div className="row radiosec">
                                            <div className="col-sm-12">
                                                <label className="col-sm-3">Garbage Disposal</label>

                                                <div className="col-sm-2">
                                                    <label className="custom_radio radio-inline">Ok
                                                        <input
                                                            type="radio"
                                                            name="13"
                                                            value="Ok"
                                                            onChange={(e) => this.setChecked('garbageDisposal', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <label className="custom_radio radio-inline">Call Complex
                                                        <input
                                                            type="radio"
                                                            name="13"
                                                            value="Call Complex"
                                                            onChange={(e) => this.setChecked('garbageDisposal', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="row radiosec">
                                            <div className="col-sm-12">
                                                <label className="col-sm-3">Sink</label>

                                                <div className="col-sm-2">
                                                    <label className="custom_radio radio-inline">Ok
                                                        <input
                                                            type="radio"
                                                            name="14"
                                                            value="Ok"
                                                            onChange={(e) => this.setChecked('sink', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <label className="custom_radio radio-inline">Leaking
                                                        <input
                                                            type="radio"
                                                            name="14"
                                                            value="Leaking"
                                                            onChange={(e) => this.setChecked('sink', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-sm-6 col-md-6">
                                        <div className="row radiosec">
                                            <div className="col-sm-12">
                                                <label className="col-sm-3">Refrigerator</label>

                                                <div className="col-sm-2">
                                                    <label className="custom_radio radio-inline">Ok
                                                        <input
                                                            type="radio"
                                                            name="15"
                                                            value="Ok"
                                                            onChange={(e) => this.setChecked('refrigerator', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <label className="custom_radio radio-inline">Call Complex
                                                        <input
                                                            type="radio"
                                                            name="15"
                                                            value="Call Complex"
                                                            onChange={(e) => this.setChecked('refrigerator', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="row radiosec">
                                            <div className="col-sm-12">
                                                <label className="col-sm-3">Touch Up Paint</label>

                                                <div className="col-sm-3">
                                                    <label className="custom_radio radio-inline">Completed
                                                        <input
                                                            type="radio"
                                                            name="16"
                                                            value="Ok,Fixed"
                                                            onChange={(e) => this.setChecked('touchUpPaint', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <label className="custom_radio radio-inline">Detailer
                                                        <input
                                                            type="radio"
                                                            name="16"
                                                            value="Ok"
                                                            onChange={(e) => this.setChecked('touchUpPaint', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className="col-sm-2 col-md-offset-3">
                                                    <label className="custom_radio radio-inline">Ok,Fixed
                                                        <input
                                                            type="radio"
                                                            name="16"
                                                            value="Ok"
                                                            onChange={(e) => this.setChecked('touchUpPaint', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-sm-12 col-md-12">
                                        <textarea
                                            className="form-control"
                                            placeholder="Comments/Notes"
                                            value={this.props.kitchenComments}
                                            onChange={(e) => this.setValue('kitchenComments', e)}
                                            rows="4"
                                            cols="5"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default InspectionFormKitcken