import {Link} from 'react-router-dom';
import DestinationService from '../../../services/destinationService';
import moment from 'moment'
import AppController from '../../controller/appController';
import ViewPlaceModal from '../location/viewPlaceModal';
import SiteLoader from '../../common/siteLoader';
class ApplePropertyDetails extends React.Component {
      constructor(props){
        super(props);
       this.state ={
        headerDetails: [],
        propertyDetails: [],
        length:'',
        latitude:0.0,
        longitude:0.0,
        walkScore: '',
        appPrice:'',
        address:'',
        communityName:''
       }
       this.previous = this.previous.bind(this);
       this.next = this.next.bind(this);
    }
    async componentDidMount(){        
        await this.mapLoad();
        AppController.removeSiteLoader();
    }
    mapLoad(){
      setTimeout(function() {
        var geocoder = new google.maps.Geocoder();
        var address = this.state.propertyDetails.Address+', '+this.state.propertyDetails.City+', '+this.state.propertyDetails.StateCode+', '+this.state.propertyDetails.ZipCode
        const _this= this;
        geocoder.geocode( { 'address': address}, async function(results, status) {
        if (status == google.maps.GeocoderStatus.OK) {
            var latitude = results[0].geometry.location.lat();
            var longitude = results[0].geometry.location.lng();
            await _this.setState({latitude, longitude})
            const Walkscore = await AppController.getIndividualWalkscore(_this.state.propertyDetails, latitude, longitude);
            _this.setState({walkScore: Walkscore});
           
        } 
       })
      }.bind(this), 2000); 
   }
    async propertyDetails(){
      this.props.proprertyData.propertyIndex = this.state.propertyIndex
      const data = this.props.proprertyData;
      const propertyDetails =await DestinationService.getDetails(data)
       const details = propertyDetails.recordset[0]
      var price = ''
      if(details.Size == "1X1"){
        if(details.OneByOneRentHi != null || details.OneByOneRentHi != undefined)
         { 
           price = details.OneByOneRentHi
         }else{
           price = details.OneByOneRentLo
         }
 
      }else if(details.Size == "1X1.5"){
       if(details.OneByOneFiveRentHi != null || details.OneByOneFiveRentHi != undefined)
       { 
         price = details.OneByOneFiveRentHi
       }else{
         price = details.OneByOneFiveRentLo
       }
      }
        else if(details.Size == "1X2"){
         if(details.OneByTwoRentHi != null || details.OneByTwoRentHi != undefined)
         { 
           price = details.OneByTwoRentHi
         }else{
           price = details.OneByTwoRentLo
         }
        }
       else if(details.Size == "1X1/Den"){
         if(details.OneByOneDenRentHi != null || details.OneByOneDenRentHi != undefined)
         { 
           price = details.OneByOneDenRentHi
         }else{
           price = details.OneByOneDenRentLo
         }
       }
          else if(details.Size == "2X1"){
           if(details.TwoByOneRentHi != null || details.TwoByOneRentHi != undefined)
           { 
             price = details.TwoByOneRentHi
           }else{
             price = details.TwoByOneRentLo
           }
        }
             else if(details.Size == "2X2"){
               if(details.TwoByTwoRentHi != null || details.TwoByTwoRentHi != undefined)
               { 
                 price = details.TwoByTwoRentHi
               }else{
                 price = details.TwoByTwoRentLo
               }
                                 }
               else if(details.Size == "3X1"){
                 if(details.ThreeByOneRentHi != null || details.ThreeByOneRentHi != undefined)
                 { 
                   price = details.ThreeByOneRentHi
                 }else{
                   price = details.ThreeByOneRentLo
                 }                
                                          }
               else if(details.Size == "3X2"){
                 if(details.ThreeByTwoRentHi != null || details.ThreeByTwoRentHi != undefined)
                 { 
                   price = details.ThreeByTwoRentHi
                 }else{
                   price = details.ThreeByTwoRentLo
                 }               
                    }
 
                    else if(details.Size == "3X3"){
                     if(details.ThreeByThreeRentHi != null || details.ThreeByThreeRentHi != undefined)
                     { 
                       price = details.ThreeByThreeRentHi
                     }else{
                       price = details.ThreeByThreeRentLo
                     }
       }
       else if(details.Size == "Studio"){
         if(details.StudioRentHi != null || details.StudioRentHi != undefined)
         { 
           price = details.StudioRentHi
         }else{
           price = details.StudioRentLo
         }
 }
 
                                                
             else if(details.Size == "4+"){
               if(details.FourPlusHi != null || details.FourPlusHi != undefined)
               { 
                 price = details.FourPlusHi
               }else{
                 price = details.FourPlusLo
               }                
                                                      }
      await this.setState({propertyDetails: propertyDetails.recordset[0],appPrice:price,communityName:details.Community})
   }
   async componentWillMount(){
     const SortOrder = localStorage.getItem('SortOrder')
     const Length = localStorage.getItem('length')
     await this.setState({propertyIndex: SortOrder,length:Length})
     await this.propertyDetails()
   }

   async previous(){
    if(this.state.propertyIndex > 1){
     const index = Number(this.state.propertyIndex) -1
     await this.setState({propertyIndex:index})
     this.propertyDetails()
     this.mapLoad()
    }
  }

 async next(){
   if(this.state.propertyIndex < this.state.length){
     const index = Number(this.state.propertyIndex) +1 ;
     await this.setState({propertyIndex:index})
     this.propertyDetails()
     this.mapLoad()
    }
}

    render(){
      const communityDetails = this.state.propertyDetails;
      if(communityDetails.Website != undefined){ 
        const website = communityDetails.Website.search('www')
        var url =''
      if( website == 0){
        var http ='http://'
        url = http.concat(communityDetails.Website)
     }else{
       url = communityDetails.Website;
     }
    }
    return(<div  style={{marginBottom: "150px"}}>
        <div className="page-wrapper-inner">
        <Link to={'/destination-service/itinerary/'+this.props.proprertyData.companyName+'/'+this.props.proprertyData.FileNum+'/'+this.props.proprertyData.itineraryId} className="itineraryBlockone"><img src="https://www.suiteamerica.com/images/icons/icon-paper-white.png" alt="" /><br />Itinerary</Link>
<br/><br/>
<div className="column-left">
<span className="time-inner">{moment.utc(communityDetails.time).format('hh:mm A')}</span>
<br/>
<div className="property-number inner"><span>{communityDetails.SortOrder}</span></div><span className="property-name inner">{communityDetails.Community}</span>
<br/>

<div className="property-img-wrapper inner" style={{backgroundImage:'url('+ 'https://s3-us-west-1.amazonaws.com/destination-services-itinerary/'+communityDetails.Photo+'.jpg' +')'}}>

<div className="property-details inner">
<Link to={'/destination-service/itinerary/property/mapview/'+this.props.proprertyData.companyName+'/'+this.props.proprertyData.FileNum+'/'+this.props.proprertyData.itineraryId}><img src="https://s3-us-west-1.amazonaws.com/suiteam-website/Destination-Services/SuiteAmerica-Destination-Icon-Map.png" alt="Map" className="icon-map"/></Link>
{(this.state.walkScore!= '' && this.state.walkScore!= null && this.state.walkScore!=undefined) ? 
<div className="icon-walkscore">
<a data-toggle="modal" data-target="#viewPlaces" target="_blank"><img className="walkscore-icon" src="https://s3-us-west-1.amazonaws.com/suiteam-website/Destination-Services/SuiteAmerica-Destination-Services-Walkscore.svg" alt="Walk Score of 386 Menker Ave" /><span className="count">{this.state.walkScore}</span></a>


</div>: null}

</div>

</div>       



<div className="property-thumbs-wrapper">


<div className="property-thumbs " id="L" style={{backgroundImage:'url('+ 'https://s3-us-west-1.amazonaws.com/destination-services-itinerary/'+communityDetails.Photo+'-1.jpg' +')'}}></div>

<div className="property-thumbs" id="R" style={{backgroundImage:'url('+ 'https://s3-us-west-1.amazonaws.com/destination-services-itinerary/'+communityDetails.Photo+'-2.jpg' +')'}}></div>

<br className="clear"/>

</div>


</div>


<div className="column-right inner" >
<table className="property-data" width="100%"  cellSpacing="2" cellPadding="3">
  <tbody>
    <tr>
      <td className="property-field-title">Address</td>
      <td colSpan="3"><div className="p-address-inner">{communityDetails.Address}, {communityDetails.City}, {communityDetails.StateCode} {communityDetails.ZipCode}</div></td>
    </tr>
    <tr>
      <td className="property-field-title">Website</td>
      <td colSpan="3" className="property-field-website"><a className="p-link" href={url} target="_blank">{communityDetails.Website}</a></td>
    </tr>
    <tr>
      <td className="property-field-title">Phone</td>
      <td colSpan="3"><a href={"tel:"+communityDetails.Phone}>{communityDetails.Phone != null?communityDetails.Phone:'NA'}</a> - <em>{communityDetails.CommunityContactName != null?communityDetails.CommunityContactName:'NA'}</em></td>
    </tr>
   <tr>
      <td className="property-field-title">Size </td>
      <td>
		  {communityDetails.Size}
	   </td>
      <td className="property-field-title">App Fee</td>
      <td><span>
		
			${communityDetails.ApplicationFeeHI != undefined?communityDetails.ApplicationFeeHI:null}{communityDetails.ApplicationFeeLo != undefined?communityDetails.ApplicationFeeLo:null}
		
      </span></td>
    </tr>
    <tr>
    <td className="property-field-title">Sq Ft</td>
      <td>
		  {communityDetails.SqFt == null? '-' : communityDetails}
	   </td>
     
      
      <td className="property-field-title">Deposit</td>
      <td><span>
      ${communityDetails.DepositHi != undefined?communityDetails.DepositHi:null}{communityDetails.DepositLo != undefined?communityDetails.DepositLo:null} 
      </span></td>
    </tr>
    <tr>
    <td className="property-field-title">Price</td>
    <td>
    ${this.state.appPrice}
    
      
    </td>
     
    </tr>
    <tr>
      <td className="property-field-title">Amenities</td>
      <td colSpan="3">{communityDetails.Amenities}</td>
    </tr>
    

  </tbody>
</table>

</div>

</div>

<br className="clear"/>

<a className="btn-previous" onClick={this.previous}></a> 
<a className="btn-next" onClick={this.next}></a> 
<SiteLoader />
    <ViewPlaceModal latitude={this.state.latitude} longitude={this.state.longitude} communityName={this.state.communityName}/>
    </div>)

    }
}
export default ApplePropertyDetails