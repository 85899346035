const AmenitiesModal=(props)=>{
    return(
        <div className="modal " id="amenitesImages" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog " role="document">
          <div className="modal-content animated zoomIn">
            <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true"> <i className="fa fa-times-circle"></i> </span>
              </button>
            </div>
            <div className="modal-body">
            <div className="outer">
            <img className="img-responsive image" src={props.amenitesImage}/>
             </div>
            </div>
          </div>
        </div>
      </div>
    )
    }
    export default AmenitiesModal;