/* Importing the node modules, child components, services and controllers used 
   inside CareerOpportunites component */

import MainController from "../controller/mainController";
import SubFooter from "./subFooter";
import {Link} from 'react-router-dom';
import GuestHeader from './guestHeader';
import Footer from './footer';
import MetaTags from './metaTags';
import ServerMetaTags from '../../server/metaTags';
/* CareerOpportunites Component initialization */
class CareerOpportunites extends React.Component {
    /* Initializing objects of its CareerOpportunites class */
    constructor(props) {
        super(props);
    }
    /* It is invoked immediately before mounting occurs */
    componentWillMount() {
        MainController.getInitialInformation();
    }
    /* It is invoked to return html content */
    render() {
        const metaData = ServerMetaTags.urlString.getMetaData(this.props.match.url);
        return (
            <div>
            <MetaTags 
             metaTitle = {metaData.title}
             metaDesription = {metaData.description}
             metaUrl = {metaData.canonical} />
            <GuestHeader/>
            <div className=""></div>
            <div className="company_section testmonial_gap bg-grey ">
                <div className="container privacy_policy">
                    <div className="global-coverage-wrap col-md-10 col-md-offset-1 wow fadeInUp" data-wow-delay="0.5s">
                        <h1 className="text-center f-300 gotham-font large-font mrg-btm-20">SuiteAmerica <br /> Employment Opportunities</h1>
                        <div className="col-md-8 col-md-offset-2 text-center">
                        <p className="text-center">Be a part of an award winning.
                            Browse our current career opportunities.
                        </p>
                        <Link to="/employment-search" className="btn btn-red mrg-top-60 mrg-btm-60">CAREER OPENINGS</Link>
                        </div>
                        <div className="clearfix"></div>
                        <p className="mrg-btm-40"><i>SuiteAmerica is an Equal Employment Opportunity and Affirmative Action Employer that is committed to 
                        inclusion and diversity.</i>
                        </p>
                        <p className="mrg-btm-40"><i>If you'd like more information about your EEO rights as an applicant, please <a href="https://www1.eeoc.gov/employers/upload/eeoc_self_print_poster.pdf" target="_blank" className="color-red"><i>click here</i></a>.</i></p>
                        <p className="mrg-btm-40"><strong>SuiteAmerica</strong> has offices located in the San Francisco Bay Area, Sacramento, San Diego, Maryland, Portland, 
                        New Jersey and Washington D.C./Virginia
                        </p>
                        <p><strong>SuiteAmerica</strong> provides <strong>corporate apartments, temporary housing, extended stay housing and furnished 
                        fully serviced apartments</strong> nationwide. As we continue to grow we are constantly looking for energetic, 
                        forward thinking individuals who are interested in working for a progressive company with good benefits.
                        </p>
                    </div>
                </div>
            </div>
            <SubFooter />
            <Footer/>
            </div>             
        );
    }
}
export default CareerOpportunites;