import {Link} from 'react-router-dom';
const ReservationInfo=(props)=>{
    const row=props.rowInfo
    const {diffDate} = props;
return(
    <div className="row">
    <div className="col-md-6">
        <div className="row">
        <div className="form-group">
            <label className="col-md-6">Email</label>
            <div className="col-md-6">
                <label className="input_label">
                    <Link to="#">{row.GuestEmail}</Link>
                </label>
            </div>
        </div>
        <div className="form-group">
            <label className="col-md-6">Phone</label>
            <div className="col-md-6">
                <label className="input_label">{row.GuestPhoneNo}</label>
            </div>
        </div>
        <div className="form-group">
            <label className="col-md-6">Size</label>
            <div className="col-md-6">
                <label className="input_label">{row.SuiteSizeCode}</label>
            </div>
        </div>
        <div className="form-group">
            <label className="col-md-6">Pets</label>
            <div className="col-md-6">
                <label className="input_label">{row.petscount}</label>
            </div>
        </div>
        </div>
    </div>
    <div className="col-md-6">
        <div className="row">
        <div className="form-group">
            <label className="col-md-6">Check In Time</label>
            <div className="col-md-6">
                <label className="input_label">{row.CheckInTime}</label>
            </div>
        </div>
        <div className="form-group">
            <label className="col-md-6">Check Out Time</label>
            <div className="col-md-6">
                <label className="input_label">11:59 pm</label>
            </div>
        </div>
        <div className="form-group">
            <label className="col-md-6">Length of Stay</label>
            <div className="col-md-6">
                <label className="input_label">	{diffDate >=0 ? diffDate+1:'invalid date'}</label>
            </div>
        </div>
        <div className="form-group">
            <label className="col-md-6">Account #</label>
            <div className="col-md-6">
                <label className="input_label">{row.accountId}</label>
            </div>
        </div>
        <div className="form-group">
            <label className="col-md-6">PO #</label>
            <div className="col-md-6">
                <label className="input_label">{row.PurchaseOrder}</label>
            </div>
        </div>
        </div>
    </div>
</div>

)
}
export default ReservationInfo;


