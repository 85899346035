
import {Link} from 'react-router-dom';
import EncryptDecrypt from '../../../services/encryptDecrypt';
import LocationService from '../../../services/locationService';
import PdfService from '../../../services/pdfService';
import AppController from "../../controller/appController";

class Community extends React.Component {
    constructor(props) {
        super(props)
        this.state={company: '',
        walkscore: '',
        viewPlaces: false,
        calendarStatus: false,
        directionsDisplay: '',
        directionsService: '',
        pdfLoading: false,
        accordionOpen: false,
        favourite: false,
        complexImages:[],
        AmenitiesList:[],
        communityDetails: false,
        communityIndex: -1
      }
      this.unFavouriteCommunity = this.unFavouriteCommunity.bind(this);
      this.favouriteCommunity = this.favouriteCommunity.bind(this);
      this.getCommunityDetails = this.getCommunityDetails.bind(this);
      this.viewPlaces = this.viewPlaces.bind(this);
      this.moreInfo = this.moreInfo.bind(this);
      this.calendarInfo = this.calendarInfo.bind(this);
      this.pdfGeneration = this.pdfGeneration.bind(this);
      this.calendarClose = this.calendarClose.bind(this);
      }
      async componentDidMount() {
       const communityData = this.props.row
       const data = {address: communityData.address, latitude: communityData.latitude, longitude: communityData.longitude};
       const result = await AppController.wallsckoreFun(data)
       this.setState({walkscore: result.walkscore})
      }      
      async moreInfo(data, favouriteCheck) {
        if (favouriteCheck != undefined && favouriteCheck.favourite_id != '') {
          data[this.state.favourite] = favouriteCheck.favourite_id;
          data['companydetails'] = this.props.companyName
          localStorage.setItem('moreData', JSON.stringify(data));
        } else {
          data[this.state.favourite] = '';
          data['companydetails'] = this.props.companyName
          localStorage.setItem('moreData', JSON.stringify(data));
        }
        const webGuest = localStorage.getItem('_u_web');
        const response = await EncryptDecrypt.decryptData({'type': webGuest})
        const userData = response.recordset[0]
        if (userData != null) {
          const RecentlyViewed = {
            user_id: (userData != undefined && Object.keys(userData).length > 0)
              ? userData.Id
              : '',
            community_id: data.community_id,
            createdBy: (userData != undefined && Object.keys(userData).length > 0)
              ? userData.GuestEmail
              : ''
          }
          const recentlyViewed = await LocationService.saveRecentlyViewedCommunity(RecentlyViewed)
        console.log(recentlyViewed)
        } 
      }
     async viewPlaces(){
         await this.setState({viewPlaces: !this.state.viewPlaces })
      }
      async calendarInfo(data) {
        await this.props.reserveInfo(data);
        //await this.setState({calendarStatus: true})
      }
      async calendarClose() {
        await this.setState({calendarStatus: false })
      }
      async favouriteCommunity(data) {
        await this.setState({favourite: true})
        await this.props.favouriteCommunity(data)
        await this.setState({favourite: false})
     }
     /**
       * when  the person is loggined then only delete the selected community 
       * @param {* communityid} isFavourite 
       */
      async unFavouriteCommunity(isFavourite) {
        await this.setState({favourite: true})
        await this.props.unFavouriteCommunity(isFavourite);
        await this.setState({favourite: false})
     }
      async pdfGeneration(data, communityAmenities, appartmentAmenities, complexImages){
        this.setState({pdfLoading: true});
        const finalData = {community: data, appAmenities: appartmentAmenities, commAmenities: communityAmenities ,communityImages: complexImages};
         await PdfService.pdf(finalData);
        var link = document.createElement('a');
        link.href = AppController.travelBookingUrl()+'community_pdf.pdf';
        link.target = '_blank';
        link.download = data.community_name+'.pdf';
        link.dispatchEvent(new MouseEvent('click'));
        this.setState({pdfLoading: false});
     } 
        async getCommunityDetails(row, index) {
          this.setState({communityIndex: index});
          if(this.state.communityIndex == index) {
            $('.panel-collapse').collapse('hide');
          } else {
            $('.panel-collapse.collapse').removeClass("in");
            $('.panel-title a').addClass('collapsed');
            const AmenitiesList = row.AmenitiesList != undefined && row.AmenitiesList!=null ?_.groupBy(JSON.parse(row.AmenitiesList), 'communityname'):row.AmenitiesList
            const CommunityImages = row.CommunityImages != undefined && row.CommunityImages!=null ?_.groupBy(JSON.parse(row.CommunityImages), 'imageType'):row.CommunityImages
            const complexImages =  CommunityImages!= undefined && CommunityImages!=null? (CommunityImages.complex!= undefined ? CommunityImages.complex : CommunityImages.interior) : []
          
            this.setState({                  
              AmenitiesList: AmenitiesList!= undefined && AmenitiesList!=null? AmenitiesList:{},
              complexImages: complexImages,
          });
          if(this.state.communityIndex != index){
          //  await MapController.loadMapData(row, this.props.index, this, '', 'https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/location_pin_2.svg')
          }
        }
      }

    render(){
        const row = this.props.row
        const index = this.props.index     
        console.log(index)
        const isFavourite = (row.favouritestatus != undefined && row.favouritestatus != null && row.favouritestatus != false)? row.favouritestatus : false
        const favourite = this.state.favourite? <i style={{position: 'absolute', bottom: '20px', right: '15px', fontSize: '30px', color: "#FFF"}} className="fa fa-spinner fa-spin"/> : ""
        const ClassName = this.state.favourite? '' : (isFavourite? "fa fa-heart" : "fa fa-heart-o") 
        const icon = this.state.favourite? favourite : (this.props.companyName == 'suiteamerica' ?<i className={ClassName} style={{color: isFavourite? 'red' : ''}}></i> : null )
      console.log(icon)
        return( 
        <div className=" card col-md-12">
        <div className="card-body sch_deatils">
                
                         {
                          (row.image_name !=null && row.image_name != undefined) ? 
                          
                          <Link
                              to={'/community/' +this.props.companyName+ '/'+ row.community_name.replace(/\/| /g,'-')}
                              target="_blank"><img   src={row.image_name} alt=""/></Link>:<img  src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Guest-Portal-Main-Location_Down.jpg"} alt=""/>
                        }
                        <div className="img-bg"></div>
                        <div className="card_titlee22">
        <h1>{(row.community_name.length > 18)?row.community_name.slice(0,18)+'...':row.community_name}</h1>
                        <p>{row.address}  </p>
                  {/*      <Link to="#"
data-toggle="modal" data-target="#community_book_now"
style={{
'cursor': 'pointer'
}} onClick={() => this.calendarInfo(row)} >

Reserve Now
</Link>   */}

        <Link to="/company/tesla/reservation-form"
        style={{
        'cursor': 'pointer'
        }}  >

        Reserve Now
        </Link>



        </div>
                        </div>
                        <div className="white-gap"></div>
                      
       </div>
        )
    }
}

export default Community;

 