/* Importing the node modules, child components, services and controllers used 
   inside appController object */

'use strict'

import $ from "jquery";

const travelCalendar = {

    checkInCalendar(thisObj, changed, dateString) {
        const _this = this
        const calObj = dateString!=''?  {
           
            locale: {
                format: 'MM/DD/YYYY'
            },
            minDate: moment(),
            autoUpdateInput: false,
            singleDatePicker: true,
            showDropdowns: true,
            maxDate: changed == 1 ? dateString : ''
        } :  {
            locale: {
                format: 'MM/DD/YYYY'
            },
            minDate: moment(),
            autoUpdateInput: false,
            singleDatePicker: true,
            showDropdowns: true,
        }
        jQuery('input[name="checkin"], #CheckInRange')
            .daterangepicker( calObj, function (start, end, label) {
               
                thisObj.setState({
                    checkin: start.format('MM/DD/YYYY'),
                })
                _this.checkOutCalendar(thisObj, 1, start.format('MM/DD/YYYY'))
            });
        if (changed == -1) {
            setTimeout(function () {
                _this.checkOutCalendar(thisObj, 1, dateString)
            }, 250)
        }

        $('#getApprovedStay').length>0&& this.dateDiff(thisObj)
    },
    checkOutCalendar(thisObj, changed, dateString) {
        const calObj = dateString!=''?  {
            locale: {
                format: 'MM/DD/YYYY'
            },
            minDate: changed == 1 ? (moment().format("MM/DD/YYYY") == dateString ? moment() : dateString) : moment(),
            autoUpdateInput: false,
            singleDatePicker: true
        } : {
          
            locale: {
                format: 'MM/DD/YYYY'
            },
            autoUpdateInput: false,
            minDate: moment(),
            singleDatePicker: true,
            showDropdowns: true,
        }
        const _this = this
        jQuery('input[name="checkout"], #CheckOutRange')
            .daterangepicker(calObj, function (start, end, label) {
             
                thisObj.setState({
                    checkout: start.format('MM/DD/YYYY'),
                })
                _this.checkInCalendar(thisObj, 1, start.format('MM/DD/YYYY'))
            });
        if (changed == -1) {
            setTimeout(function () {
                _this.checkInCalendar(thisObj, 1, dateString)
            }, 250)
        }
        $('#getApprovedStay').length>0 && this.dateDiff(thisObj) 
    },
    dateDiff(thisObj){
            const MoveInDate = thisObj.state.checkin
            const MoveOutDate = thisObj.state.checkout
            const startDate = moment([
                Number(moment(MoveInDate).utc().format('YYYY')),
                Number(moment(MoveInDate).utc().format('M')) - 1,
                Number(moment(MoveInDate).utc().format('D'))
            ]);
            const endDate = moment([
                Number(moment(MoveOutDate).utc().format('YYYY')),
                Number(moment(MoveOutDate).utc().format('M')) - 1,
                Number(moment(MoveOutDate).utc().format('D'))
            ]);
            const diffDate = endDate.diff(startDate, 'days');
            thisObj.setState({invitationApprovedStay: diffDate>=0? diffDate+1 : ''})
        }
}
export default travelCalendar