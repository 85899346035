import OwlCarousel from "react-owl-carousel";
import LocationService from "../../../../services/locationService";
import GuestServicesapi from "../../../../services/guestServicesapi";
import moment from "moment";
import Spinner from "./spinner";
import GuestSidebar from "./guestSidebar";
import Loader from "./loader";

class myReservations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reservedData: this.props.location.state,
      GuestInfoCommunityDetails: {},
      tab: "communityDetails",
      siteLoader: true,
      GetOrderCommunityNoteDetails: [],
      communityDetails: {},
      loader: false,
      GetBookedGSRByOrderUIdInfo: {},
      entryMethod: "",
      entryDescription: "",
      entryImages: [],
      threeSixtyView: [],
      lockBoxCode: "",
      communityALLImages: [],
      imgloading: true,
      nearestServiceDate: null,
    };
    this.openServiceRequest = this.openServiceRequest.bind(this);
    this.updateTravelInfo = this.updateTravelInfo.bind(this);
    this.fetchNearestServiceDate = this.fetchNearestServiceDate.bind(this);
    this.findNearestDate = this.findNearestDate.bind(this);
  }

  async componentDidMount() {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };

    const script = document.createElement("script");
    LocationService;
    script.src = "https://cdnjs.cloudflare.com/ajax/libs/aos/2.3.4/aos.js";
    script.async = true;
    script.integrity = "";
    document.body.appendChild(script);

    setTimeout(() => {
      this.setState({ siteLoader: false });
    }, 1500);
    await this.GetGuestInfoCommunityByOrderId(this.props.location.state);
    await this.GetOrderCommunityNoteDetails(this.props.location.state.OrderId);

    if (this.state.reservedData.ComplexId && this.state.reservedData.MasterId) {
      LocationService.View360List({
        MasterId: this.state.reservedData.MasterId,
        ComplexId: this.state.reservedData.ComplexId,
      }).then((response) => {
        this.setState({ threeSixtyView: response.message });
      });
    }

    const limitData = {
      communityName: this.state.reservedData.Community,
      imageType: "banner",
    };
    const communityDetails = await LocationService.getCommunityInfoByName(
      limitData
    );
    console.log("communityDetails567", communityDetails);
    this.setState({ communityDetails: communityDetails });

    let communityALLImages =
      this.state.communityDetails &&
      this.state.communityDetails.CommunityImages != null &&
      this.state.communityDetails.CommunityImages != undefined
        ? JSON.parse(this.state.communityDetails.CommunityImages)
        : [];
    this.setState({
      communityALLImages: communityALLImages,
      imgloading: false,
    });

    this.fetchNearestServiceDate();
  }

  fetchNearestServiceDate() {
    GuestServicesapi.GetMaidSchedulesById({
      orderUId: this.state.reservedData.OrderUId,
    }).then((response) => {
      let nextmaidscheduledates = response.message.nextmaidscheduledates;
      this.setState({
        nearestServiceDate: this.findNearestDate(nextmaidscheduledates),
      });
      console.log(this.findNearestDate(nextmaidscheduledates));
    });
  }

  findNearestDate(dates) {
    if (dates.length === 0) {
      return null;
    }

    const today = new Date();
    let nearestDate = null;
    let nearestDiff = Infinity;

    for (const dateObj of dates) {
      const date = new Date(dateObj.DateRequested);

      if (date >= today) {
        // Only consider future or today's dates
        const diff = date - today;

        if (diff < nearestDiff) {
          nearestDiff = diff;
          nearestDate = date;
        }
      }
    }

    return this.formatToMonthDayYear(nearestDate);
  }

  formatToMonthDayYear(date) {
    // const options = { year: "numeric", month: "short", day: "numeric" };
    // const formattedDate = date.toLocaleDateString(undefined, options);
    // const year = date.getFullYear();
    // return formattedDate.replace(` ${year}`, ` ${year}`);

    return moment(new Date(date).toString().split("GMT")[0]).format(
      "DD MMM, YYYY"
    );
  }

  GetGuestInfoCommunityByOrderId(data) {
    this.setState({ GuestInfoCommunityDetails: {} });
    GuestServicesapi.GetGuestInfoCommunityByEmailId({
      orderid: data.OrderId,
    }).then((response) => {
      this.setState({ GuestInfoCommunityDetails: response || {} });
    });
  }

  GetOrderCommunityNoteDetails(OrderId) {
    this.setState({ GetOrderCommunityNoteDetails: [] });
    // 371811
    GuestServicesapi.GetOrderCommunityNoteDetails({ orderId: OrderId }).then(
      (response) => {
        console.log(response, "entry-info");

        this.setState({ GetOrderCommunityNoteDetails: response });
      }
    );
  }

  changeTab(tab) {
    document.body.classList.add("guestUI-backdrop");
    this.setState({ tab, loader: true, lockBoxCode: "" });
    if (tab == "communityDetails") {
      $("#communityDetails").modal("show");
    }
    if (tab == "yourTeam") {
      $("#yourTeam").modal("show");
      GuestServicesapi.GetBookedGSRByOrderUId({
        OrderUId: this.state.reservedData.OrderUId,
      }).then((response) => {
        if (response.error == 0) {
          this.setState({
            GetBookedGSRByOrderUIdInfo: response.message.orderInfo,
          });
        }
      });
    }
    if (tab == "instructions") {
      $("#instructions").modal("show");
    }
    if (tab == "entryInfo") {
      $("#entryInfo").modal("show");
      this.setState({ entryImages: [] });
      setTimeout(() => {
        this.loadGallryData();
      }, 2000);
      let entryMethod =
        this.state.GetOrderCommunityNoteDetails.length > 0
          ? this.state.GetOrderCommunityNoteDetails[0].PrimaryEntryMethod
            ? this.state.GetOrderCommunityNoteDetails[0].PrimaryEntryMethod
            : this.state.GetOrderCommunityNoteDetails[0].SecondaryEntryMethod
          : "";
      entryMethod = entryMethod ? entryMethod.replace(/[- _/)(]/g, "") : null;
      entryMethod = entryMethod
        ? entryMethod.charAt(0).toUpperCase() + entryMethod.slice(1)
        : null;

      let entryDescription = "";
      let fordescription =
        this.state.GetOrderCommunityNoteDetails.length > 0
          ? this.state.GetOrderCommunityNoteDetails[0].PrimaryEntryMethod
            ? this.state.GetOrderCommunityNoteDetails[0].PrimaryEntryNotes
            : this.state.GetOrderCommunityNoteDetails[0].SecondaryEntryNotes
          : "";
      if (fordescription != null && fordescription != "") {
        entryDescription = fordescription;
      }

      let entryImages = [];
      let forimages =
        this.state.GetOrderCommunityNoteDetails.length > 0
          ? this.state.GetOrderCommunityNoteDetails[0].PrimaryEntryMethod
            ? this.state.GetOrderCommunityNoteDetails[0].PrimaryEntryImages
            : this.state.GetOrderCommunityNoteDetails[0].SecondaryEntryImages
          : "";

      if (forimages != null && forimages != "") {
        entryImages = forimages.split(",");
      }

      this.setState({ entryMethod, entryDescription });
      setTimeout(() => {
        this.setState({ entryImages });
      }, 1500);
    }
    setTimeout(() => {
      this.setState({ loader: false });
    }, 10000);
  }

  loadGallryData() {
    this.setState({ loader: false });
    let modalId = $("#image-gallery");

    $(document).ready(function () {
      loadGallery(true, "a.thumbnail");

      //This function disables buttons when needed
      function disableButtons(counter_max, counter_current) {
        $("#show-previous-image, #show-next-image").show();
        if (counter_max === counter_current) {
          $("#show-next-image").hide();
        } else if (counter_current === 1) {
          $("#show-previous-image").hide();
        }
      }

      /**
       *
       * @param setIDs        Sets IDs when DOM is loaded. If using a PHP counter, set to false.
       * @param setClickAttr  Sets the attribute for the click handler.
       */

      function loadGallery(setIDs, setClickAttr) {
        let current_image,
          selector,
          counter = 0;

        $("#show-next-image, #show-previous-image").click(function () {
          if ($(this).attr("id") === "show-previous-image") {
            current_image--;
          } else {
            current_image++;
          }

          selector = $('[data-image-id="' + current_image + '"]');
          updateGallery(selector);
        });

        function updateGallery(selectors) {
          let $sel = selectors;
          current_image = $sel.data("image-id");
          $("#image-gallery-title").text($sel.data("title"));
          $("#image-gallery-image").attr("src", $sel.data("image"));
          disableButtons(counter, $sel.data("image-id"));
        }

        if (setIDs) {
          $("[data-image-id]").each(function () {
            counter++;
            $(this).attr("data-image-id", counter);
          });
        }
        $(setClickAttr).on("click", function () {
          updateGallery($(this));
        });
      }
    });

    // build key actions
    $(document).keydown(function (e) {
      switch (e.which) {
        case 37: // left
          if (
            (modalId.data("bs.modal") || {})._isShown &&
            $("#show-previous-image").is(":visible")
          ) {
            $("#show-previous-image").click();
          }
          break;

        case 39: // right
          if (
            (modalId.data("bs.modal") || {})._isShown &&
            $("#show-next-image").is(":visible")
          ) {
            $("#show-next-image").click();
          }
          break;

        default:
          return; // exit this handler for other keys
      }
      e.preventDefault(); // prevent the default action (scroll / move caret)
    });
  }

  openServiceRequest() {
    
    let obj = this.props.location.state;
    obj["MasterUid"] =
      this.state.GuestInfoCommunityDetails &&
      this.state.GuestInfoCommunityDetails.LeaseUId;
    obj["OrderType"] = this.state.reservedData.OrderType;
    this.props.history.push({
      pathname: "/service-request",
      orderData: obj,
    });
  }

  async openGalleryImages() {
    this.setState({ loader: true });
    const limitData = {
      communityName: this.state.reservedData.Community,
      imageType: "banner",
    };
    const communityDetails = await LocationService.getCommunityInfoByName(
      limitData
    );
    this.setState({ communityDetails });
    setTimeout(() => {
      this.setState({ loader: false });
    }, 10000);
  }

  updateTravelInfo() {
    this.props.history.push({
      pathname: "/reservations",
      state: {},
      reservationInformation: {
        OrderUId: this.state.reservedData.OrderUId,
        showTripInfo: true,
        image: this.state.reservedData.CommunityImage,
        ComplexId: this.state.reservedData.ComplexId,
        OrderType: this.state.reservedData.OrderType,
      },
    });
  }
  handleOpenCarousel() {
    this.setState({ imgloader: false });
    $("document").ready(function () {
      $("#fullmodel-Carousel1").modal("show");
    });
    jQuery(
      function ($) {
        setTimeout(
          function () {
            this.setState({ imgloader: true });
          }.bind(this),
          500
        );
      }.bind(this)
    );
  }
  async handleclose() {
    $("document").ready(function () {
      $("#fullmodel-Carousel1").modal("hide");
    });
  }
  extractContent(s) {
    var span = document.createElement("span");
    span.innerHTML = s;
    let contentdata = span.textContent || span.innerText;
    return contentdata.substring(0, 213);
  }

  render() {
    let petName = this.state.GuestInfoCommunityDetails.petname
      ? JSON.parse(this.state.GuestInfoCommunityDetails.petname)
      : [];
    let { communityALLImages, imgloading, threeSixtyView } = this.state;
    let currentDate = new Date();
    let moveinn =
      this.state.GuestInfoCommunityDetails.MoveInDate != undefined
        ? this.state.GuestInfoCommunityDetails.MoveInDate
        : "";
    let MoveInDate = new Date(moveinn);
    return (
      <div className="SAweb_updateUi">
        <div className="SAwebGuest_reservePage bg-white">
          <GuestSidebar />
          <div id="content">
            <div className="SAwebGuest_reserveDetailsMain">
              <div className="SAwebGuest_reserveDetails">
                <h1 className="SAwebGuest_reserveDetails_heading">
                  {this.state.reservedData.Community}{" "}
                  <span>Booking ID: # {this.state.reservedData.OrderId}</span>
                </h1>

                {this.state.tab == "communityDetails" ? (
                  <div className="bottom_part">
                    {imgloading ? (
                      <div className="spinnerload">
                        {" "}
                        <Spinner />{" "}
                      </div>
                    ) : (
                      <div className="gallertGrid">
                        {communityALLImages.length > 0 && (
                          <div className="item fullpic">
                            {" "}
                            <div className="pic">
                              <img
                                src={communityALLImages[0].image_name}
                                alt=""
                              />
                              {communityALLImages.length == 1 ? (
                                <button
                                  className="btn"
                                  onClick={() => this.handleOpenCarousel()}
                                >
                                  View
                                </button>
                              ) : (
                                ""
                              )}
                            </div>{" "}
                          </div>
                        )}
                        {communityALLImages.length > 1 && (
                          <div className="item">
                            {" "}
                            <div className="pic">
                              <img
                                src={communityALLImages[1].image_name}
                                alt=""
                              />
                              {communityALLImages.length == 2 ? (
                                <button
                                  className="btn"
                                  onClick={() => this.handleOpenCarousel()}
                                >
                                  View
                                </button>
                              ) : (
                                ""
                              )}
                            </div>{" "}
                          </div>
                        )}
                        {communityALLImages.length > 2 && (
                          <div
                            className={
                              communityALLImages.length > 3
                                ? "item above_three"
                                : "item "
                            }
                          >
                            {" "}
                            <div className="pic ">
                              {" "}
                              <img
                                src={communityALLImages[2].image_name}
                                alt=""
                              />
                              {communityALLImages.length == 3 ? (
                                <button
                                  className="btn"
                                  onClick={() => this.handleOpenCarousel()}
                                >
                                  View
                                </button>
                              ) : (
                                ""
                              )}{" "}
                            </div>
                            {communityALLImages.length > 3 && (
                              <button
                                type="#"
                                className="btn"
                                onClick={() => this.handleOpenCarousel()}
                              >
                                {" "}
                                {
                                  communityALLImages.slice(
                                    2,
                                    communityALLImages.length
                                  ).length
                                }{" "}
                                More Photos
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                    <div>
                      {(threeSixtyView.length > 0 ||
                        this.state.reservedData.SiteMapUrl != null) && (
                        <div className="btngroup">
                          {threeSixtyView.length > 0 && (
                            <button
                              type="button"
                              className="btn black-btn"
                              data-toggle="modal"
                              data-target="#take360Tour"
                            >
                              {" "}
                              Take 360 tour{" "}
                            </button>
                          )}
                          {this.state.reservedData.SiteMapUrl != null ? (
                            <a
                              className="btn black-btn"
                              href={this.state.reservedData.SiteMapUrl}
                              target="__blank"
                            >
                              View site map
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                      )}

                      <h2>Community Details</h2>

                      <ul className="community">
                        <li>
                          {" "}
                          <p>Community</p>
                          <span>{this.state.reservedData.Community}</span>
                        </li>
                        {this.state.GuestInfoCommunityDetails.Floor && (
                          <li>
                            {" "}
                            <p>Floor</p>
                            <span>
                              {this.state.GuestInfoCommunityDetails.Floor}
                            </span>
                          </li>
                        )}
                        <li>
                          {" "}
                          <p>Suite Number</p>
                          <span>
                            {this.state.GuestInfoCommunityDetails.SuiteNo}
                          </span>
                        </li>
                        {this.state.GuestInfoCommunityDetails.SuiteSizeCode && (
                          <li>
                            {" "}
                            <p>Size</p>
                            <span>
                              {
                                this.state.GuestInfoCommunityDetails
                                  .SuiteSizeCode
                              }
                            </span>
                          </li>
                        )}
                        <li>
                          {" "}
                          <p>Suite Address</p>
                          <a
                            target="_blank"
                            href={
                              "https://www.google.com/maps/place/" +
                              this.state.reservedData.CommunityAddress
                            }
                            style={{ textTransform: "capitalize" }}
                          >
                            <span>
                              {this.state.reservedData.CommunityAddress
                                ? this.state.reservedData.CommunityAddress +
                                  ", "
                                : null}
                              {this.state.reservedData.CommunityCity
                                ? this.state.reservedData.CommunityCity.toLowerCase() +
                                  ", "
                                : null}
                              {this.state.reservedData.CommunityState
                                ? this.state.reservedData.CommunityState.toLowerCase() +
                                  " "
                                : null}
                              {this.state.reservedData.CommunityZipCode
                                ? this.state.reservedData.CommunityZipCode
                                : null}
                            </span>
                          </a>
                        </li>
                        {this.state.GuestInfoCommunityDetails.GateCode ? (
                          <li>
                            {" "}
                            <p>Gate Code</p>
                            <span>
                              #{this.state.GuestInfoCommunityDetails.GateCode}
                            </span>
                          </li>
                        ) : (
                          ""
                        )}
                      </ul>

                      <div className="includedGrid">
                        <div className="item">
                          <div className="pic">
                            <img
                              src="assets/newUI-images/guestportal/food.svg"
                              alt=""
                            />
                          </div>

                          <h4>What's Included?</h4>
                          <p>
                            Your Suite comes with furnishing, housewares and
                            starter kit. Please click{" "}
                            <a
                              href={
                                this.state.GuestInfoCommunityDetails
                                  .OfficePackage == "No"
                                  ? "https://suiteam-website.s3.us-west-1.amazonaws.com/PDF/Business-furnishing.pdf"
                                  : "https://suiteam-website.s3.us-west-1.amazonaws.com/PDF/Business-Package.pdf"
                              }
                              target="__blank"
                            >
                              here
                            </a>{" "}
                            to view complete list of items included in your
                            suite.
                          </p>
                        </div>
                        <div className="item">
                          <div className="pic">
                            <img
                              src="assets/newUI-images/guestportal/wifi.svg"
                              alt=""
                            />
                          </div>
                          <h4>Wi-Fi</h4>
                          {this.state.GuestInfoCommunityDetails.WiFiName ? (
                            <div>
                              <p className="network">
                                {" "}
                                <i> Wifi Network </i> <b>:</b>{" "}
                                {this.state.GuestInfoCommunityDetails.WiFiName}{" "}
                              </p>
                              <p className="network">
                                {" "}
                                <i> Wifi Password </i> <b>:</b>{" "}
                                {
                                  this.state.GuestInfoCommunityDetails
                                    .WiFiPassword
                                }{" "}
                              </p>
                            </div>
                          ) : (
                            <p>
                              {
                                "Please reach out to SuiteAmerica team for more information."
                              }
                            </p>
                          )}
                        </div>
                        <div className="item">
                          <div className="pic">
                            <img
                              src="assets/newUI-images/guestportal/mail.svg"
                              alt=""
                            />
                          </div>
                          <h4>
                            MailBox{" "}
                            {this.state.GuestInfoCommunityDetails.MailBox !=
                            null ? (
                              <span>
                                {this.state.GuestInfoCommunityDetails.MailBox}
                              </span>
                            ) : (
                              // <span>{console.log('MailBox',this.state.GuestInfoCommunityDetails.MailBox)}</span>
                              ""
                            )}
                          </h4>
                          {this.state.GuestInfoCommunityDetails
                            .MailboxInstructions != null ? (
                            <p>
                              <span>
                                {this.extractContent(
                                  this.state.GuestInfoCommunityDetails
                                    .MailboxInstructions
                                )}
                              </span>
                              {/* <span dangerouslySetInnerHTML={{__html:this.state.GuestInfoCommunityDetails.MailboxInstructions.substring(0, 500)}}></span> */}
                              {this.state.GuestInfoCommunityDetails
                                .MailboxInstructions.length > 216 ? (
                                <a
                                  data-toggle="modal"
                                  data-target="#mailinstru-model"
                                  style={{
                                    textDecoration: "underline !important",
                                  }}
                                >
                                  more
                                </a>
                              ) : (
                                ""
                              )}
                            </p>
                          ) : (
                            <p>
                              Please contact SuiteAmerica for mailbox
                              instructions.
                            </p>
                          )}{" "}
                        </div>
                        {petName.length > 0 ? (
                          <div className="item">
                            <div className="pic">
                              <img
                                src="assets/newUI-images/guestportal/pets.svg"
                                alt=""
                              />
                            </div>
                            <h4>Pets</h4>

                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Type</th>
                                  <th scope="col">Weight</th>
                                  <th scope="col">Breed</th>
                                </tr>
                              </thead>
                              <tbody>
                                {petName.length > 0 ? (
                                  petName.map((pet, i) => {
                                    return (
                                      <tr key={i}>
                                        <td>{pet.TypeCode}</td>
                                        <td>{pet.Weight}</td>
                                        <td>{pet.Breed}</td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td className="text-center" colSpan={3}>
                                      No pets Data Found
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="item">
                          <div className="pic">
                            <img
                              src="assets/newUI-images/guestportal/Insurance_icon.svg"
                              alt=""
                            />
                          </div>
                          <h4>Insurance </h4>
                          {this.state.GuestInfoCommunityDetails.Insurance ==
                            "Yes- Corporate" ||
                          this.state.GuestInfoCommunityDetails.Insurance ==
                            "Yes- Individual" ? (
                            <p>
                              You have renters insurance included with your
                              reservation. Please contact SuiteAmerica for more
                              information.
                            </p>
                          ) : (
                            <p>
                              We strongly recommend that you secure Renters
                              Insurance for your stay as you are not covered by
                              the SuiteAmerica insurance carrier for property
                              damage or loss claims of personal injury made
                              against you. You may obtain insurance through a
                              provider of your choice, or you may opt-in to
                              coverage through SuiteAmerica.
                            </p>
                          )}
                        </div>
                        <div className="item">
                          <div className="pic">
                            <img
                              src="assets/newUI-images/guestportal/car.svg"
                              alt=""
                            />
                          </div>
                          <h4>
                            Parking{" "}
                            {this.state.GuestInfoCommunityDetails
                              .ParkingSpace1 != null ? (
                              <span>
                                {this.state.GuestInfoCommunityDetails.ParkingSpace1.replace(
                                  `,`,
                                  `, `
                                )}
                              </span>
                            ) : (
                              ""
                            )}
                          </h4>
                          <p>
                            Please be sure to follow all parking rules, which
                            may include, but are not limited to: parking in your
                            assigned parking spot (if applicable), utilizing
                            only one parking spot per vehicle,{" "}
                            <a
                              data-toggle="modal"
                              data-target="#parking-model"
                              style={{ textDecoration: "underline !important" }}
                            >
                              ..more
                            </a>
                          </p>
                        </div>
                      </div>

                      <h2>We are here for you!</h2>
                      <div className="lastpara">
                        <p>
                          {" "}
                          SuiteAmerica reserves the right to substitute the
                          apartment with an apartment of comparable size and
                          amenities should this apartment not be available as
                          scheduled.{" "}
                        </p>
                        <p>
                          {" "}
                          Please click{" "}
                          <a onClick={this.openServiceRequest}> Here </a> to
                          place Service Request. For any after-hours emergency,
                          please call SuiteAmerica at{" "}
                          <a href="#"> 800-367-9501. </a>{" "}
                        </p>
                        <p>
                          If you are experiencing a life-threatening emergency,
                          please call <a href="tel:911">911.</a>
                        </p>
                      </div>
                    </div>
                  </div>
                ) : this.state.tab == "yourTeam" ? (
                  <div className="sa-team">
                    <div className="SAwebGuest_arrivalInfo">
                      <div className="heading closeIcon">
                        <h3>Your Team</h3>
                        {/* <span>101 San Fernando</span> */}
                        {/* <i className="bi bi-x"></i> */}
                      </div>
                      {Object.keys(this.state.GetBookedGSRByOrderUIdInfo)
                        .length > 0 ? (
                        <div className="yourTeamtails">
                          <div className="tails">
                            {this.state.GetBookedGSRByOrderUIdInfo
                              .BookedByName != null ? (
                              <div className="item">
                                <div className="pic">
                                  {" "}
                                  <img
                                    className={
                                      !this.state.GetBookedGSRByOrderUIdInfo
                                        .BookedByUserProfile
                                        ? "imageIcon"
                                        : ""
                                    }
                                    src={
                                      this.state.GetBookedGSRByOrderUIdInfo
                                        .BookedByUserProfile
                                        ? this.state.GetBookedGSRByOrderUIdInfo
                                            .BookedByUserProfile
                                        : "assets/newUI-images/guestportal/icons/user-circle.svg"
                                    }
                                    alt=""
                                  />{" "}
                                </div>
                                <div className="text">
                                  {this.state.GetBookedGSRByOrderUIdInfo
                                    .BookedByName != null ? (
                                    <h3>
                                      {
                                        this.state.GetBookedGSRByOrderUIdInfo
                                          .BookedByName
                                      }
                                    </h3>
                                  ) : (
                                    ""
                                  )}
                                  {this.state.GetBookedGSRByOrderUIdInfo
                                    .BookedByEmail != null ? (
                                    <p>
                                      {" "}
                                      <i className="bi bi-envelope"></i>{" "}
                                      {
                                        this.state.GetBookedGSRByOrderUIdInfo
                                          .BookedByEmail
                                      }
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                  {/* {this.state.GetBookedGSRByOrderUIdInfo.BookedByPhone != null ? <p> <i className="bi bi-telephone"></i> {this.state.GetBookedGSRByOrderUIdInfo.BookedByPhone}</p> : ''} */}
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            {this.state.GetBookedGSRByOrderUIdInfo.GSRName !=
                            null ? (
                              <div className="item">
                                <div className="pic">
                                  {" "}
                                  <img
                                    className={
                                      !this.state.GetBookedGSRByOrderUIdInfo
                                        .BookedByUserProfile
                                        ? "imageIcon"
                                        : ""
                                    }
                                    src={
                                      this.state.GetBookedGSRByOrderUIdInfo
                                        .BookedByUserProfile
                                        ? this.state.GetBookedGSRByOrderUIdInfo
                                            .BookedByUserProfile
                                        : "assets/newUI-images/guestportal/icons/user-circle.svg"
                                    }
                                    alt=""
                                  />{" "}
                                </div>
                                <div className="text">
                                  {this.state.GetBookedGSRByOrderUIdInfo
                                    .GSRName != null ? (
                                    <h3>
                                      {
                                        this.state.GetBookedGSRByOrderUIdInfo
                                          .GSRName
                                      }
                                    </h3>
                                  ) : (
                                    ""
                                  )}
                                  {this.state.GetBookedGSRByOrderUIdInfo
                                    .GSREmail != null ? (
                                    <p>
                                      {" "}
                                      <i className="bi bi-envelope"></i>{" "}
                                      {
                                        this.state.GetBookedGSRByOrderUIdInfo
                                          .GSREmail
                                      }{" "}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                  {/* {this.state.GetBookedGSRByOrderUIdInfo.GSRPhone != null ? <p> <i className="bi bi-telephone"></i> {this.state.GetBookedGSRByOrderUIdInfo.GSRPhone}</p> : ''} */}
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <p>
                            If there is anything we can do to make your stay
                            more comfortable, please feel to contact us at{" "}
                            <a href="#"> 800-367-9501 </a> ext <b> 3 </b> or
                            email us at{" "}
                            <a href="#">guestserve@suiteamerica.com</a>
                          </p>
                        </div>
                      ) : this.state.loader ? (
                        <Loader />
                      ) : (
                        "Team not found"
                      )}
                    </div>
                  </div>
                ) : this.state.tab == "entryInfo" ? (
                  <div className="SAwebGuest_arrivalInfo">
                    <div className="heading closeIcon">
                      <h3>Entry Information </h3>
                      {/* <span>101 San Fernando</span> */}
                      {/* <i className="bi bi-x"></i> */}
                    </div>
                    {this.state.loader ? (
                      <Loader />
                    ) : (
                      <div className="InstructionsList">
                        {this.state.GetOrderCommunityNoteDetails.length > 0 ? (
                          <div className="bottom_part">
                            <ul
                              className={
                                this.state.entryMethod
                                  ? "instruc"
                                  : "instruc shadow-none"
                              }
                            >
                              {this.state.entryMethod ? (
                                <h3 className="enteryHeading">
                                  {" "}
                                  Entry Method:{" "}
                                  <b>
                                    {this.state.entryMethod
                                      ? this.state.entryMethod
                                      : "Entry method is null"}
                                  </b>{" "}
                                </h3>
                              ) : (
                                <h5 className="note">
                                  We are preparing your entry instructions.
                                  Please check back 72 hours before your arrival
                                  time. For any other questions, please contact{" "}
                                  <a href="mailto:guestserve@suiteamerica.com">
                                    guestserve@suiteamerica.com
                                  </a>{" "}
                                </h5>
                              )}

                              {this.state.lockBoxCode ? (
                                <h3 className="enteryHeading">
                                  {" "}
                                  Lockbox-Code:<b>{this.state.lockBoxCode}</b>
                                </h3>
                              ) : null}

                              <li className="text-justify m-t-15">
                                <div
                                  className="showHtmlText"
                                  dangerouslySetInnerHTML={{
                                    __html: this.state.entryDescription
                                      .replace(/&lt;/g, "<")
                                      .replace(/&gt;/g, ">"),
                                  }}
                                ></div>
                              </li>
                            </ul>

                            {this.state.entryImages.length > 0 && (
                              <div className="">
                                <div className="entryInfoGallery">
                                  <div className="itmeGallery">
                                    {this.state.entryImages.length > 0 &&
                                      this.state.entryImages.map((data, i) => {
                                        return (
                                          <div className=" thumb" key={i}>
                                            <a
                                              className="thumbnail"
                                              href="#"
                                              data-image-id
                                              data-toggle="modal"
                                              data-image={data}
                                              data-target="#image-gallery"
                                            >
                                              <img
                                                className="img-thumbnail"
                                                src={data}
                                                alt="Another alt text"
                                              />
                                            </a>
                                          </div>
                                        );
                                      })}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        ) : (
                          <ul className="instruc">
                            <li>
                              <span>No Entry Found.</span>
                            </li>
                          </ul>
                        )}
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
              <div className="SAwebGuest_reserveDetails_sidetails">
                <ul className="checkInOut">
                  <li>
                    <p>
                      Check in date{" "}
                      <span>
                        {moment(
                          this.state.GuestInfoCommunityDetails.MoveInDate
                        ).format("DD")}{" "}
                        {moment(
                          this.state.GuestInfoCommunityDetails.MoveInDate
                        ).format("MMM")}
                        ,{" "}
                        {moment(
                          this.state.GuestInfoCommunityDetails.MoveInDate
                        ).format("YYYY")}{" "}
                        {this.state.GuestInfoCommunityDetails.CheckInTime ==
                        null
                          ? "03:00 PM"
                          : this.state.GuestInfoCommunityDetails.CheckInTime}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      Check out date{" "}
                      <span>
                        {moment(
                          this.state.GuestInfoCommunityDetails.MoveOutDate
                        ).format("DD")}{" "}
                        {moment(
                          this.state.GuestInfoCommunityDetails.MoveOutDate
                        ).format("MMM")}
                        ,{" "}
                        {moment(
                          this.state.GuestInfoCommunityDetails.MoveOutDate
                        ).format("YYYY") + " "}
                        {this.state.GuestInfoCommunityDetails.CheckOutTime ==
                        null
                          ? " 11:59 PM"
                          : this.state.GuestInfoCommunityDetails.CheckOutTime}
                      </span>
                    </p>
                  </li>
                </ul>
                {/* <p>Next Housekeeping  <span>19 Jan, 2022, 9:00 AM</span></p> */}
                {this.state.nearestServiceDate ? (
                  <p>
                    Next Housekeeping Date{" "}
                    <span>{this.state.nearestServiceDate}</span>
                  </p>
                ) : null}
                <p>
                  <span>Suite </span>
                  {this.state.GuestInfoCommunityDetails.SuiteNo}
                </p>
                <p>
                  <span>Address</span>
                  <a
                    target="_blank"
                    href={
                      "https://www.google.com/maps/place/" +
                      this.state.reservedData.CommunityAddress
                    }
                    style={{ textTransform: "capitalize" }}
                  >
                    {this.state.reservedData.CommunityAddress
                      ? this.state.reservedData.CommunityAddress + ", "
                      : null}
                    {this.state.reservedData.CommunityCity
                      ? this.state.reservedData.CommunityCity.toLowerCase() +
                        ", "
                      : null}
                    {this.state.reservedData.CommunityState
                      ? this.state.reservedData.CommunityState.toLowerCase() +
                        " "
                      : null}
                    {this.state.reservedData.CommunityZipCode
                      ? this.state.reservedData.CommunityZipCode
                      : null}
                  </a>
                </p>

                {currentDate < MoveInDate && (
                  <button
                    type="button"
                    className="btn black-btn"
                    onClick={this.updateTravelInfo}
                  >
                    Update Travel Info
                  </button>
                )}

                {this.state.tab == "yourTeam" ||
                this.state.tab == "instructions" ||
                this.state.tab == "entryInfo" ? (
                  <button
                    type="button"
                    className="btn"
                    onClick={() => this.changeTab("communityDetails")}
                  >
                    View More Details
                  </button>
                ) : null}
                <button
                  type="button"
                  className="btn black-btn"
                  onClick={this.openServiceRequest}
                >
                  {" "}
                  <p>
                    Service Request{" "}
                    <span>Click here to submit your request</span>
                  </p>{" "}
                </button>
                <button
                  type="button"
                  className="btn black-btn"
                  onClick={() => this.changeTab("yourTeam")}
                >
                  {" "}
                  <p>
                    Your Team <span>Click here to view your team</span>
                  </p>{" "}
                </button>
                {/* <button type="button" className="btn black-btn" onClick={() => this.changeTab("entryInfo")}> <p>Entry Information <span>Click here to view entry information</span></p> </button> */}
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade guest-Ui SAweb_modalPoup"
          id="parking-model"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content ">
              <div
                className="modal-header"
                style={{ padding: "0px", borderBottom: "0px" }}
              >
                <button
                  type="button"
                  className="btn close"
                  data-dismiss="modal"
                  aria-label=""
                >
                  <i className="bi bi-x"></i>
                </button>
              </div>
              <div className="modal-body">
                <strong className="popupheading">
                  {" "}
                  <span>
                    {" "}
                    <i className="fa fa-car mrg-right-5" /> Parking{" "}
                  </span>
                  {this.state.GuestInfoCommunityDetails.ParkingSpace1 !=
                  null ? (
                    <span>
                      {this.state.GuestInfoCommunityDetails.ParkingSpace1.replace(
                        `,`,
                        `, `
                      )}
                    </span>
                  ) : (
                    ""
                  )}
                </strong>
                <br />
                <p>
                  {" "}
                  Please be sure to follow all parking rules, which may include,
                  but are not limited to: parking in your assigned parking spot
                  (if applicable), utilizing only one parking spot per vehicle,
                  posting parking stickers in your vehicle (if applicable), not
                  blocking garages or driveways, not parking in fire zones, and
                  not parking illegally in handicapped spots. Failure to follow
                  all parking rules may result in your vehicle being ticketed or
                  towed.{" "}
                </p>

                <p>
                  Please also keep in mind that other violations of state laws
                  (such as expired registration) may result in similar actions.{" "}
                </p>

                <p>
                  SuiteAmerica is not responsible for any fees associated with
                  parking tickets or towing. Please do not leave any valuables
                  in your vehicle. If you have any questions, please be sure to
                  contact your SuiteAmerica representative.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade SAweb_modalPoup modal-fullscreen-xl guest-Ui modal fade animated tab-modal"
          id="take360Tour"
          role="dialog"
        >
          <div className="modal-dialog">
            {/* Modal content*/}
            <div className="modal-content">
              <div className="modal-header">
                <h1>360 TOUR</h1>
                <button
                  type="button"
                  className="btn close"
                  data-dismiss="modal"
                >
                  {" "}
                  <i className="bi bi-x"></i>{" "}
                </button>
              </div>
              <div className="modal-body">
                <iframe
                  src={
                    this.state.threeSixtyView.length > 0
                      ? this.state.threeSixtyView.length > 0 &&
                        this.state.threeSixtyView[0].weblink
                      : ""
                  }
                  width="100%"
                  alt=""
                />
              </div>
              <div className="modal-footer"></div>
            </div>
          </div>
        </div>

        {/* <div id="smallmodel-myModalAllPhotos" className=" modal fade SAweb_modalPoup Saweb_MorePhotosPopup " role="dialog" data-backdrop="static">
                    <div className="modal-dialog modal-lg modal-dialog-centered modal-lg-2">
                        <div className="modal-content notification-popup borderRadius"
                        >
                            <div className="modal-header border-0" >
                                <button type="button" className="btn close" data-dismiss="modal" aria-label="Close"> <i className="bi bi-x"></i>  </button>
                            </div>
                            <div className="">


                                {communityALLImages.length > 0 &&
                                    !this.state.imgloader ?
                                    <div className="">
                                        <Spinner fontSize="35px" />
                                    </div> :
                                    <div className="Saweb_MorePhotosSlider">

                                        <OwlCarousel className="owl-theme owl-carousel"
                                            {...{
                                                loop: false, nav: true, dots: true, items: 1,
                                                navContainerClass: 'owl-buttons',
                                                navText: ["<i class='glyphicon glyphicon-menu-left'></i>",
                                                    "<i class='glyphicon glyphicon-menu-right'></i>"],
                                                responsive: {
                                                    600: { items: 1, nav: true, loop: false },
                                                    767: { items: 1, nav: true, loop: false },
                                                    992: { items: 1, nav: true, loop: false },
                                                    1199: { items: 1, nav: true, loop: false }
                                                }
                                            }}>
                                            {communityALLImages.map((row, index) => {
                                                return (
                                                    <div className="item" key={index}>
                                                        <img className="img-responsive" src={row.image_name} alt={row} />
                                                    </div>
                                                )
                                            })}
                                        </OwlCarousel>

                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div> */}
        <div
          className="modal modal-fullscreen-xl modal fade animated tab-modal guest-Ui SAweb_modalPoup"
          id="fullmodel-Carousel1"
          tabIndex={-1}
          data-backdrop="static"
          data-keyboard="false"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content propertyGallery">
              <div className="modal-header border-0 p-0">
                <button
                  type="button"
                  className="btn close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="bi bi-x"></i>
                </button>
                <h2>{this.state.reservedData.Community}</h2>
              </div>
              <div className="modal-body">
                <div></div>
                <div>
                  <ul>
                    {communityALLImages.map((row, index) => {
                      return (
                        <li key={index}>
                          <img src={row.image_name} alt="" width="100%" />
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade guest-Ui SAweb_modalPoup "
          id="mailinstru-model"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content ">
              <div
                className="modal-header"
                style={{ padding: "0px", borderBottom: "0px" }}
              >
                <button
                  type="button"
                  className="btn close"
                  data-dismiss="modal"
                  aria-label=""
                >
                  <i className="bi bi-x"></i>
                </button>
              </div>
              <div className="modal-body">
                <strong className="popupheading">
                  <span>
                    <i className="fa fa-envelope mrg-right-5" /> MailBox{" "}
                  </span>{" "}
                  {this.state.GuestInfoCommunityDetails.MailBox != null ? (
                    <span>{this.state.GuestInfoCommunityDetails.MailBox}</span>
                  ) : (
                    ""
                  )}
                </strong>
                <br />
                <pre
                  dangerouslySetInnerHTML={{
                    __html:
                      this.state.GuestInfoCommunityDetails.MailboxInstructions,
                  }}
                ></pre>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default myReservations;
