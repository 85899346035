
import ReservationInfo from './reservationInfo';
import CommunityInfo from './communityInfo';
import StatusInformation from './statusInformation';
import $ from "jquery";
const TableInformation = (props) => {
    const { filterData, searchValue, sortBy, communitysLoading, type, viewSelction, changeSearchValue, communitySelection, exportExcel, addressSelection, servicesData, closedData, selectValue, guestFirstName, buttonsChanges, index } = props;
    return (
        <div>
            <div className="clearfix"></div>
            <div className="row">
                {/*  <div className="pull-left">
            <ul className="table_clr list-inline">
                <li>Hotel</li>
            </ul>
      </div> */}
                <div className="pull-right">
                    <div className="form-group mrg-right-15">
                        <input className="form-control pull-right" type="text" placeholder="Search" value={searchValue} onChange={(e) => changeSearchValue('searchValue', e)} />
                    </div>
                </div>
                {/* {filterData.length > 0 ? <button className="btn btn-primary pull-right mrg-right-10" onClick={()=> exportExcel(type, filterData)} >Export To Excel</button>: null} */}
            </div>
            <div className="clearfix"></div>
            <div className="table-responsive">
                {communitysLoading ?
                    <div className="panel-body_loader mrg-top-30">
                        <span style={{ fontSize: '20px', width: '100%', margin: 'auto', textAlign: 'center' }}>
                            <i className="fa fa-spinner fa-spin" /> please wait ...!</span>
                    </div> : <table className="table fold-table" >

                        <thead>
                            <tr>
                                <th onClick={() => sortBy('MasterId', 1)} style={{ cursor: 'pointer' }}>Master#</th>
                                <th onClick={() => sortBy('SuiteNo')} style={{ cursor: 'pointer' }}>Suite#</th>
                                <th onClick={() => sortBy('SuiteSize')} style={{ cursor: 'pointer' }}>Size</th>
                                <th onClick={() => sortBy('AvailFrom')} style={{ cursor: 'pointer' }}>Avail From</th>
                                <th onClick={() => sortBy('AvailTo')} style={{ cursor: 'pointer' }}>Avail To</th>
                                <th onClick={() => sortBy('PurchaseOrder')} style={{ cursor: 'pointer' }}>File#</th>
                                <th onClick={() => sortBy('GuestMoveOut')} style={{ cursor: 'pointer' }}>MoveOut</th>
                                <th onClick={() => sortBy('OrderStatus')} style={{ cursor: 'pointer' }}>Status</th>
                                <th onClick={() => sortBy('VipLevel')} style={{ cursor: 'pointer' }}>VIP</th>
                            </tr>
                        </thead>

                        {filterData.length == 0 && !communitysLoading ?
                            <tbody className=" table fold-table collapsed">
                                <tr className="view">
                                    <td className="text-center" colSpan="9" style={{ color: 'red' }}>No Records Found</td>
                                </tr>
                            </tbody>
                            : filterData.map((row, filterIndex) => {

                                return (

                                    <tbody className=" table fold-table collapsed" key={filterIndex} >
                                        <tr data-toggle="collapse" data-parent="#accordion" href={'#expand' + row.orderno} className={index == filterIndex ? (row.community_name == null && row.HotelName != null ? "view open hotel" : "view open") : (row.community_name == null && row.HotelName != null ? "view hotel" : "view ")} style={{ background: row.Vip != null ? '#dddddd' : '#ffffff' }}>
                                            <td>{row.MasterId}</td>
                                            <td>{row.SuiteNo}</td>
                                            <td>{row.SuiteSize}</td>
                                            <td>{(row.AvailFrom != null && row.AvailFrom != "---") ? moment(row.AvailFrom).format('L') : '---'}</td>
                                            <td>{(row.AvailTo != null && row.AvailTo != "---") ? moment(row.AvailTo).format('L') : '---'}</td>
                                            <td>{row.PurchaseOrder != null ? row.PurchaseOrder : '---'}</td>
                                            <td>{(row.GuestMoveOut != null && row.GuestMoveOut != "---") ? moment(row.GuestMoveOut).format('L') : '---'}</td>
                                            <td>{row.OrderStatus}</td>
                                            <td>{row.VipLevel}</td>
                                        </tr>
                                    </tbody>
                                )
                            })}
                    </table>}

            </div>
        </div>
    )
}
export default TableInformation;


