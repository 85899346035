import React from 'react'
import Footer from './footer';
import Header from './header';
class CaseStudies2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
 async componentDidMount() {
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  }
  }

  render() {

    return (
      <div className="SAweb_updateUi">
        <Header />
        <section className="SAweb_homeCaseStudies_Banner bg-white">

<div className="container CaseStudiesContainer">
  <div className="banner">
    <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/caseStudies/Casestudy_2_banner.jpg" alt="" width="100%" />
  </div>
</div>

</section>

<div className="SAweb_homeCaseStudiesHeading">
<div className="container CaseStudiesContainer">
  <h2>Accommodations for a large Government need (Navy)</h2>
</div>
</div>

<div className="SAweb_homeCaseStudiesContent bg-white">
<div className="container CaseStudiesContainer">
  <h3>Overview:</h3>
  <p>In July of 2020, in the midst of the pandemic, a private government contractor was in need of a large volume
of housing units with a short turnaround time. Having reached out to several other vendors who were not
willing or able to assist, SuiteAmerica took on the challenge and delivered.  
 </p>

  <div className="sideImageGrid">
    <div className="item">
      <h3>Client-Required Elements and Goals:</h3>

      <ul>
        <li>Location: the client required apartments in a specific radius of a worksite location</li>
        <li>Inventory: A mixture of apartment sizes was required for both executives and other employees</li>
        <li>Budget: both budget and value were strong driving
factors</li>
        <li>Local presence: SuiteAmerica employees were
required for pre-arrival and post-departure detailed
inspections</li>
        <li>Short lead time: a short turnaround time from request to arrivals</li>
        <li>Compliance: Adherence to a very specific contracting
schedule with specific non-negotiable deliverables

</li>
      </ul>
    </div>

    <div className="item">
      <div className="pic">
        <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/caseStudies/Casestudy_2_subright.jpg" alt="" width="100%"/>
      </div>
    </div>
  </div>

  <h3>Strategy and Approach: </h3>
  <p>Our longevity in the temporary housing industry has allowed us to develop deep relationships with regional
and national property development and management companies. We targeted a new construction property
in lease-up mode and negotiated a deal that would provide the management company with immediate
revenue from our booking and would provide them with a safety net on the back end of the leases.
</p>

<p>
SuiteAmerica also leveraged our vendor partners to support a very quick ramp-up approach. All elements
of a furnished apartment had to be ordered, delivered, and set within a specific amount of time. Our vendor
management team went to work and was able to get nearly 100 apartments ready for occupancy on time
and on budget.
</p>
  <p>We went above and beyond to assist with the group’s arrival, setting up check-in tables in the community
leasing office and organizing orientation meetings. Our teams stayed onsite for the first few days to ensure
that everyone’s questions were answered and show that their comfort was very important to us. We
streamlined the service request process and established a specific Guest Experience team just for this
group, thereby delivering a stress-free temporary housing experience for everyone.  
</p>

  <h3>Results: </h3>
  <p>A winning proposal that resulted in not only a happy client, but a program that was on time and on budget.   </p>

</div>
</div>
        <Footer />
      </div>
    );
  }
}
export default CaseStudies2;