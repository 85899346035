import ReactTable from "react-table";
const AnalysisCountModal = (props) => {
  const filterMethod = (filter, row) => {
    const id = filter.pivotId || filter.id;
    if (row[id] !== null && typeof row[id] === "string") {
      return (row[id] !== undefined
        ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
        : true);
    }
  }
  let columns = [
    {
      Header: 'Order#',
      id: 'OrderId',
      accessor: row => (row.OrderId).toString(),
      width: 75
    },

    {
      Header: "Resident",
      id: 'GuestName',
      accessor: row => (row.GuestFirstName == null ? row.Requested : row.GuestFirstName),
      style: { 'textAlign': 'center', 'whiteSpace': 'unset' },

    },
    {
      Header: "ServiceType",
      id: 'serviceType',
      accessor: row => (row.CategoryCode),
      style: { 'whiteSpace': 'unset' },

    },
    // {
    //   Header: "Description",
    //   id: 'Description',
    //   accessor: row=>  (row.Description),
    //   style: {'whiteSpace': 'unset'}
    //   },
    {
      Header: "Community",
      id: 'Community',
      accessor: row => (row.Community),
      style: { 'whiteSpace': 'unset' }
    },

    {
      Header: "Suite#",
      id: 'suitenumber',
      accessor: "SuiteNo",
      style: { 'textAlign': 'center' },
      width: 75
    },


  ]
  const { analysisCountType, analysisLoading, analysisCountInfo, AnalysisName, AnalysisType } = props;
  const Data = analysisCountType == 0 ? columns.slice(0, columns.length - 2) : columns
  return (
    <div className="modal fade" id="analysisCountInfo" role="dialog">
      <div className="modal-dialog modal-lg">


        <div className="modal-content animated zoomIn">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal">&times;</button>
            <h4 className="modal-title">{AnalysisName} <span style={{ color: 'red' }}>{AnalysisType != null ? (AnalysisType) : ''}</span></h4>
          </div>
          <div className="col-md-12 mrg-top-15">
            <ReactTable
              multiSort={true}
              loading={analysisLoading}
              defaultFilterMethod={filterMethod}
              data={analysisCountInfo}
              columns={Data}
              defaultPageSize={15}
              pageSizeOptions={[5, 10, 15, 20, 25, 50, 100, analysisCountInfo.length]}
              sortable={true}
              className="-striped -highlight analysisCount"
              filterable={true}
              style={{
                height: "500px"
              }}
            />
          </div>
          <div className="modal-footer">

          </div>
        </div>

      </div>
    </div>


  )
}
export default AnalysisCountModal;