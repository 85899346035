const Latchaccess=(props)=>{
    return(
<div>
        {/* <header>
          <img src="images/header.png" alt="" width="100%" />
        </header> */}
        <div className="latch_web">
          <div className="">
            <div className="latch_web_sec1 borderred">
              <div className="image">
                <img src="/assets/newUI-images/latchimages/Latch-1.png" alt="" width="100%" />
              </div>
              <div className="item">
                <h1> Welcome to <img src="/assets/newUI-images/latchimages/latchLOGO.png" alt="" width="100px" /></h1>
                <p>The Dean uses the Latch app for remote, keyless entry. Latch will give you access to buildings, amenities, and elevators, and will unlock the door to your suite.
                </p>
              </div>
            </div>
            <div className="latch_web_sec2 borderred">
              <div className="item">
              <h2>Here is how to activate the Latch app: </h2>
                <ul>
                  <li> You will be receiving an email directly from Latch (not from SuiteAmerica) on how to register for access to your
                    electronic keys that you will need to enter the building upon arrival.
                  </li>
                  <li>Complete this registration prior to your arrival date (check your junk email folder if you don't see an
                    email from Latch in your in-box).
                  </li>
                  <li>If you are traveling internationally, you may need to wait until you are in the U.S. to download the Latch
                    app.
                  </li>
                  <li> <b> Important: </b> You <u>MUST</u> log out of the Latch app after downloading and registering. Then, when you
                    arrive at The Dean, <u>log back into the app</u>, and your 'keys' will appear. DO <u>NOT</u> miss this step.
                  </li>
                </ul>
              </div>
              <div className="item">
                <img src="/assets/newUI-images/latchimages/Latch-2.png" alt="" width="100%" />
              </div>
            </div>
            <div className="latch_web_sec3 borderred m-b-30">
              <div className="item">
                <img src="/assets/newUI-images/latchimages/Latch-3.png" alt="" width="100%" />
              </div>
              <div className="item">
                <h2>How to Use Latch to Unlock a Door</h2>
                <p>When approaching a door, including your suite, follow these steps to unlock it:</p>
                <ul>
                  <li>Open the Latch app on your phone.</li>
                  <li>Hold your phone up to the Latch reader on the door. Keep your phone facing you. There is a bar at the top of the app that will be blue at first.
                  </li>
                  <li>As your phone syncs with the Latch reader, the blue bar will turn green, and dots will connect on the
                    reader. This means the door is unlocked, and you may proceed through!
                  </li>
                </ul>
                <p><i> Important: The first time you use Latch, it may take up to 90 seconds to sync.</i>
                </p>
              </div>
            </div>
           <div className="latch_web_sec3 latch_web_sec4  borderred">
              <div className="item">
                <h2>How to Use Latch on an Elevator</h2>
                <p> Remember, you will use Latch to use the elevator. it's the same process as opening a door. You must use
                  Latch in order to select your floor number. Once on the elevator, follow these steps to access your floor.
                </p>
                <ul>
                  <li>Open the Latch app on your phone.</li>
                  <li>Hold the phone up the Latch sensor.
                  </li>
                  <li>Once your phone syncs to the sensor, you will hear an 'unlocked' signal, and the dots on the sensor will
                    connect.
                  </li>
                  <li>Then, you may select your floor number.</li>
                </ul>
                <p><i> Important: Unlocking the elevator may take up to 5 seconds, and you must hear the 'unlocked' signal
                    before entering your floor number.</i>
                </p>
              </div>
              <div className="item">
                <img src="/assets/newUI-images/latchimages/Latch-4.png" alt="" width="100%" />
              </div>
            </div>
           
          </div>
        </div>
      </div>
    )
}

export default Latchaccess;