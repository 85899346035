'use strict'

import serviceBase from './serviceBase'
const travelBookingApiService = {
    GetOrdersByClient:(data)=>serviceBase.post('/mysuiteamerica-travelbooking/api/GetOrdersByClient',data),
    GetAvailableMasters:(data)=>serviceBase.post('/mysuiteamerica-travelbooking/api/GetAvailableMasters',data),
    CreateOrder:(data)=>serviceBase.post('/mysuiteamerica-travelbooking/api/CreateOrder',data),
    xlUpload:(data)=>serviceBase.post('/mysuiteamerica-travelbooking/api/SavePromotionData',data),
    SendPromotionEmails:(data)=>serviceBase.post('/mysuiteamerica-travelbooking/api/SendPromotionEmails',data),
    GetBookingEmails:(data)=>serviceBase.post('/mysuiteamerica-travelbooking/apis/GetBookingEmails',data),
    adminLogin:(data)=>serviceBase.post('/mysuiteamerica-travelbooking/api/CheckAdminLogin',data),
    SaveTripInfo:(data)=>serviceBase.post('/mysuiteamerica-travelbooking/api/SaveTripInfo',data),
    GetTravelAvailableMasters:(data)=>serviceBase.post('/mysuiteamerica-travelbooking/api/GetTravelAvailableMasters',data),
    CreateTravelOrder:(data)=>serviceBase.post('/mysuiteamerica-travelbooking/api/CreateTravelOrder',data),
    GetTravelBookingByPnrId:(data)=>serviceBase.post(`/mysuiteamerica-travelbooking/apis/GetTravelBookingByPnrId&&pnrid=${data.pnrid}`,{}),
    SaveBookingEmails:(data)=>serviceBase.post('/mysuiteamerica-travelbooking/api/SaveBookingEmails',data),
    GetTravelBookingDetails:(data)=>serviceBase.post('/mysuiteamerica-travelbooking/api/GetTravelBookingDetails',data),
   
    GetTravelAvailableBillableMasters:(data)=>serviceBase.post('/mysuiteamerica-travelbooking/api/GetTravelAvailableBillableMasters',data),
    CreateTravelBillableOrder:(data)=>serviceBase.post('/mysuiteamerica-travelbooking/api/CreateTravelBillableOrder',data),
    GetAvailabilityMastersByProgram:(data)=>serviceBase.post(`/mysuiteamerica-travelbooking/apis/GetAvailabilityMastersByProgram&&program=${data.program}`,{}),

    GetAvailableMastersInfo: (data) => serviceBase.post('/mysuiteamerica-travelbooking/api/GetAvailableMastersInfo', data),
}
export default travelBookingApiService