
const SurveyAnalysisModal=(props)=>{
    
    
  const {singlesurveyInfo} = props;
  
  let singleSurvey={
    gender:'',
    accommodation:'',
    car:'',
    startDateselect:'',
    alergies:'',
    groceries:'',
    prefer:'',
    person:'',
    clutter:'',
    separate:'',
    favorite:'',
    travel:'',
    eatprefer:'',
    message:'',
    marking:'',
  }
  if(singlesurveyInfo != ''){
    singleSurvey = JSON.parse(singlesurveyInfo.Data.replace(/'/g,'"'));
   
  }
  
return(
<div className="modal fade" id="surveyanalysis" role="dialog">
<div className="modal-dialog modal-lg">


<div className="modal-content animated zoomIn">
<div className="modal-header">
  <button type="button" className="close" data-dismiss="modal">&times;</button>
  <h4 className="modal-title">Housing Survey Info </h4>
</div>

<div className="col-md-12 mrg-top-15">

    <p> What gender do you identify as? : {singleSurvey.gender != '' || singleSurvey.gender != undefined ?singleSurvey.gender:'--'}</p>
    <p> Do you have accessibility requirements or any special accommodations? : {singleSurvey.accommodation != '' || singleSurvey.accommodation != undefined ?singleSurvey.accommodation:'--'}</p>
    <p> Will you be bringing your car or renting a car for the duration of your stay? : {singleSurvey.car != '' || singleSurvey.car != undefined ?singleSurvey.car:'--'}</p>
    <p> What date will you arrive to your corporate apartment? : {singleSurvey.startDateselect != '' || singleSurvey.startDateselect != undefined ?singleSurvey.startDateselect:'--'}</p>
    <p> Do you have food allergies? If so, what kind? : {singleSurvey.alergies != '' || singleSurvey.alergies != undefined ?singleSurvey.alergies:'--'}</p>
    <p> Apple provides your first set of groceries. Which grocery package do you prefer? Click on each grocery package to see what’s inside. : {singleSurvey.groceries != '' || singleSurvey.groceries != undefined ?singleSurvey.groceries:'--'}</p>
    <p> Do you prefer background noise or silence when you’re doing things like reading or working? : {singleSurvey.prefer != '' || singleSurvey.prefer != undefined ?singleSurvey.prefer:'--'}</p>
    <p> Are you a morning person or night person? : {singleSurvey.person != '' || singleSurvey.person != undefined ?singleSurvey.person:'--'}</p>
    <p> Do you prefer to keep your space clean and tidy, or are you okay with a little clutter? : {singleSurvey.clutter != '' || singleSurvey.clutter != undefined ?singleSurvey.clutter:'--'}</p>
    <p> Are you okay with sharing things like food, or would you rather keep your items separate? : {singleSurvey.separate != '' || singleSurvey.separate != undefined ?singleSurvey.separate:'--'}</p>
    <p> What are some of your favorite interests? (example: working out, reading, gaming, etc.) : {singleSurvey.favorite != '' || singleSurvey.favorite != undefined ?singleSurvey.favorite:'--'}</p>
    <p> Do you plan to be in town most weekends, or do you want to travel a bit during your days off? : {singleSurvey.travel != '' || singleSurvey.travel != undefined ?singleSurvey.travel:'--'}</p>
    <p> Do you prefer to eat out or stay in and cook? : {singleSurvey.eatprefer != '' || singleSurvey.eatprefer != undefined ?singleSurvey.eatprefer:'--'}</p>
    <p> Is there anything you’d like your roommate to know about you? (Optional) : {singleSurvey.message != '' || singleSurvey.message != undefined ?singleSurvey.message:'--'}</p>
    <p> I give consent to SuiteAmerica to share my bio with my potential roommate. : {singleSurvey.marking != '' || singleSurvey.marking != undefined ?singleSurvey.marking:'--'}</p>
</div>

<div className="modal-footer">
  
</div>
</div>

</div>
</div>

    
)
}
export default SurveyAnalysisModal;

