/* Importing the node modules, child components, services and controllers used 
   inside NikeLocations component */    
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import AbcHeader from "../../common/abcHeader";
   import Location from "../location/locations";
   import FAQ from "./common/abcfaq";
   import Footer from '../../common/footer';
   /* NikeLocations Component initialization */
   class AbcLocations extends React.Component {
       /* Initializing objects of its NikeLocations class */
       constructor(props) {
           super(props)
           this.state={
               companyname:'Abc'
           }
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
           MainController.getInitialInformation();
        }
       /* It is invoked to return html content */
       render() {
           return (
            <div className="corporateHousing_Updates">
                <header className="codeconveyHeader cc_image_text_main corporate-h">
                    <img className="img-responsive" src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Company-Nike-Internship-iStock-170231161.jpg"} width="100%" alt=""  />
                    <div className="wow fadeInUp img-on-text top30 width600 text-center left50 img-on-text-center" data-wow-delay="0.5s">
                        <h1>Locations</h1>
                        <p>Furnished and unfurnished temporary
                            corporate housing apartments throughout
                            the continental United States.
                        </p>
                    </div>
                </header>
                <AbcHeader />
                <Location companyName={this.state.companyname} props={this.props}/>
                <div className="container-fluid bg-grey">
                    <div className="container">
                        <FAQ />
                    </div>
                </div>
                <SubFooter />
            <Footer/>
            </div>   
           );
       }
   }
   export default AbcLocations;