/* Importing the node modules, child components, services and controllers used
   inside ReservationInformation component */
import $ from "jquery";
import AppController from "../../controller/appController";
import moment from 'moment';
import Guest from "../../common/guest";
import GuestHeader from '../../common/guestHeader';
import Footer from '../../common/footer';
import GotAquestion from './gotAquestion';
import QuickRequestForm from './quickRequestForm';
import ReservationInfoModal from './modals/reservationInfoModal';
import GuestEmailService from '../../../services/guestEmailService';
/* ReservationInformation Component initialization */
class ReservationInformation extends React.Component {
    /* Initializing objects of its ReservationInformation class */
    constructor(props) {
        super(props)
        /* ReservationInformation Component State variables Initialization */
        this.ReservationInfoModal = new ReservationInfoModal(this, props)
        this.state = Object.assign(this.ReservationInfoModal.state)
        this.loadMap = this.loadMap.bind(this);
        this.toogle = this.toogle.bind(this);
    }
    /* It is invoked immediately before mounting occurs */
    async componentWillMount() {
        AppController.removeLocalStorageData();
        $('html, body').animate({scrollTop: 0});
        AppController.stickyTitles()
        $(".expand").on("click", function () {
            var expand = $(this).find(">:first-child");
            if (expand.text() == "+") {
                expand.text("-");
            } else {
                expand.text("+");
            }
        });
        this.ReservationInfoModal.guestReservationInforamtion();
    }
    /* To change the input field values */
    setValue(field, e) {
        var object = {};
        object[field] = e.target.value;
        this.setState(object);
    }
    /* It is invoked immediately after mounting occurs */
    async componentDidMount() {
        await Loadjs('/assets/js/slide-menu.js');
    }
    /* To send the email to selected email id */
    async sendEmailToSuite() {
        this.setState({isSubmitting: true, message: '', color: ''})
        const sentDetails = {
            questions: this.state.questions
        }
        const guestOrder = await GuestEmailService.SendMailToSuite(sentDetails)
        if (guestOrder.response) 
            this.setState({isSubmitting: false, message: 'Mail Sent SuccessFully', color: 'green'})
        else {
            this.setState({isSubmitting: false, message: 'Mail Not Sent', color: 'red'})
        }
    }
    /* To initialize the google maps data */
    async loadMap(mapStatus) {
        await this.setState({
            loadMap: mapStatus,
            mapId: this.state.mapId + 1
        })
        setTimeout(async function () {
            if(mapStatus){
             let setdata = await this.ReservationInfoModal.loadMapData()
            }
        }.bind(this), 100);
    }
    /* To show and hide the selected container */
    toogle() {
        this.setState({
            show: !this.state.show
        })
        $("body").hasClass("bodytrns320l")
            ? $("body").removeClass('bodytrns320l no-scroll profile-open')
            : $('body').toggleClass("no-scroll profile-open bodytrns320l")
        $("#test-menu-right").toggle();
        $("#test-menu-right").css('float', 'right !important;');
        $("#test-menu-right").css('right', $('.slide-menu').width() + 'px');
    }
    /* It is invoked to return html content */
    render() {
        return (
            <div className="service_request">
                <GuestHeader/>
                <div className="profile_submenu-ht"></div>
                <Guest/>
                <QuickRequestForm menuShow={this.state.menuShow}/>
                <div className="guest_sitenew">
                    <div className="intra_staff2">
                        <div className="container">
                            <div className="mrg-top-30"></div>
                            <div className="clearfix"></div>
                            <div
                                id="accordion1"
                                className="panel-group mrg-top-70 mrg-btm-0"
                                role="tablist"
                                aria-multiselectable="true">
                                <div className="panel panel-default no-border-btm">
                                    <div className="panel-heading">
                                        <h4 className="panel-title">
                                            <a
                                                className=""
                                                data-toggle="collapse"
                                                data-parent="#accordion1"
                                                href="#collapse3"
                                                aria-expanded="true"
                                                aria-controls="collapse3">
                                                MY RESERVATION
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse3" className="panel-collapse collapse in">
                                        <div className="panel-body">
                                            {this.state.loading 
                                                ? <div className="panel-body_loader">
                                                        <span
                                                            style={{
                                                            fontSize: '20px',
                                                            width: '100%',
                                                            margin: 'auto',
                                                            textAlign: 'center'
                                                        }}><i className="fa fa-spinner fa-spin"/>
                                                            please wait ...!</span>
                                                    </div>
                                                : this
                                                    .state
                                                    .guestOrder
                                                    .map((row, index) => {
                                                        const startDate = moment([
                                                            Number(moment(row.MoveInDate).utc().format('YYYY')),
                                                            Number(moment(row.MoveInDate).utc().format('M')) - 1,
                                                            Number(moment(row.MoveInDate).utc().format('D'))
                                                        ]);
                                                        const endDate = moment([
                                                            Number(moment(row.MoveOutDate).utc().format('YYYY')),
                                                            Number(moment(row.MoveOutDate).utc().format('M')) - 1,
                                                            Number(moment(row.MoveOutDate).utc().format('D'))
                                                        ]);
                                                        const diffDate = endDate.diff(startDate, 'days');
                                                        return (
                                                            <div key={index}>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className="row">
                                                                            <div className="form-group">
                                                                                <label className="col-md-6 col-xs-6">Name</label>
                                                                                <div className="col-md-6 col-xs-6">
                                                                                    <label className="input_label">{row.GuestFirstName==null?'---':row.GuestFirstName}</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <label className="col-md-6 col-xs-6">Email</label>
                                                                                <div className="col-md-6 col-xs-6">
                                                                                    <label className="input_label">{row.GuestEmail==null?'---':row.GuestEmail}</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <label className="col-md-6 col-xs-6">Phone</label>
                                                                                <div className="col-md-6 col-xs-6">
                                                                                    <label className="input_label">{row.GuestPhoneNo==null?'---':row.GuestPhoneNo}</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <label className="col-md-6 col-xs-6"></label>
                                                                                <div className="col-md-6 col-xs-6">
                                                                                    <label className="input_label"></label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <label className="col-md-6 col-xs-6">Size</label>
                                                                                <div className="col-md-6 col-xs-6">
                                                                                    <label className="input_label">{row.SuiteSizeCode==null?'---':row.SuiteSizeCode}</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <label className="col-md-6 col-xs-6">Square Feet</label>
                                                                                <div className="col-md-6 col-xs-6">
                                                                                    <label className="input_label">{row.SquareFootage==null?'---':row.SquareFootage}</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <label className="col-md-6 col-xs-6">Pets</label>
                                                                                <div className="col-md-6 col-xs-6">
                                                                                    <label className="input_label">{row.petname==null?'---':row.petname}</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <label className="col-md-6 col-xs-6">Housekeeping</label>
                                                                                <div className="col-md-6 col-xs-6">
                                                                                    <label className="input_label">{row.NextHouseKeeping==null?'---':row.NextHouseKeeping}</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="row">
                                                                            <div className="form-group">
                                                                                <label className="col-md-6 col-xs-6">Move In Date</label>
                                                                                <div className="col-md-6 col-xs-6">
                                                                                    <label className="input_label">{AppController.DateFormate(row.MoveInDate)}</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <label className="col-md-6 col-xs-6">Check In Time</label>
                                                                                <div className="col-md-6 col-xs-6">
                                                                                    <label className="input_label">{row.CheckInTime==null?'---':row.CheckInTime}</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <label className="col-md-6 col-xs-6"></label>
                                                                                <div className="col-md-6 col-xs-6">
                                                                                    <label className="input_label"></label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <label className="col-md-6 col-xs-6">Move Out Date</label>
                                                                                <div className="col-md-6 col-xs-6">
                                                                                    <label className="input_label">{AppController.DateFormate(row.MoveOutDate)}</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <label className="col-md-6 col-xs-6">Check Out Time</label>
                                                                                <div className="col-md-6 col-xs-6">
                                                                                    <label className="input_label">11:59 pm</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <label className="col-md-6 col-xs-6">Length of Stay</label>
                                                                                <div className="col-md-6 col-xs-6">
                                                                                    <label className="input_label">{diffDate >= 0
                                                                                            ? diffDate + 1
                                                                                            : 'invalid date'}</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <label className="col-md-6 col-xs-6"></label>
                                                                                <div className="col-md-6 col-xs-6">
                                                                                    <label className="input_label"></label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <label className="col-md-6 col-xs-6">Confirmation #</label>
                                                                                <div className="col-md-6 col-xs-6">
                                                                                    <label className="input_label">{row.orderno}</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <label className="col-md-6 col-xs-6">Account #</label>
                                                                                <div className="col-md-6 col-xs-6">
                                                                                    <label className="input_label">{row.accountId==null?'---':row.accountId}</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <label className="col-md-6 col-xs-6">PO #</label>
                                                                                <div className="col-md-6 col-xs-6">
                                                                                    <label className="input_label">{row.PurchaseOrder==null?'---':row.PurchaseOrder}</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="clearfix"></div>

                                                            </div>
                                                        )
                                                    })}
                                        </div>
                                        <div className="mrg-top-40"></div>

                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className="container">
                            <div
                                id="accordion"
                                className="panel-group mrg-btm-50"
                                role="tablist"
                                aria-multiselectable="true">
                                <div
                                    className="panel panel-default"
                                    onClick={() => this.loadMap(!this.state.loadMap)}>
                                    <div className="panel-heading">
                                        <h4 className="panel-title">
                                            <a
                                                className="collapsed"
                                                data-toggle="collapse"
                                                data-parent="#accordion"
                                                href="#collapse2"
                                                aria-expanded="false"
                                                aria-controls="collapse2">
                                                MY COMMUNITY
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse2" className="panel-collapse collapse">
                                        <div className="panel-body">
                                            {this.state.loading
                                                ? <div className="panel-body_loader">
                                                        <span
                                                            style={{
                                                            fontSize: '20px',
                                                            width: '100%',
                                                            margin: 'auto',
                                                            textAlign: 'center'
                                                        }}><i className="fa fa-spinner fa-spin"/>
                                                            please wait ...!</span>
                                                    </div>
                                                : this
                                                    .state
                                                    .guestOrder
                                                    .map((row, index) => {
                                                        return (
                                                            <div className="row" key={index}>
                                                                <div className="col-md-6">
                                                                    <div className="row">
                                                                        <div className="form-group">
                                                                            <div className="col-md-12">
                                                                                {row.image_name != null
                                                                                    ? <img className="img-responsive" src={row.image_name} alt=""/>
                                                                                    : <img
                                                                                        className="img-responsive"
                                                                                        src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Guest-Portal-Main-Location_Down.jpg"}
                                                                                        alt=""/>
}
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label className="col-md-6 col-sm-6 col-xs-6">Community</label>
                                                                            <div className="col-md-6 col-sm-6 col-xs-6">
                                                                                <label className="input_label">
                                                                                    <a href="#">{row.community_name}</a>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label className="col-md-6 col-sm-6 col-xs-6">Floor</label>
                                                                            <div className="col-md-6 col-sm-6 col-xs-6">
                                                                                <label className="input_label">{row.Floor==null?'---':row.Floor }</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label className="col-md-6 col-sm-6 col-xs-6">Suite Phone</label>
                                                                            <div className="col-md-6 col-sm-6 col-xs-6">
                                                                                <label className="input_label">{row.SuitePhone==null?'---':row.SuitePhone}</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label className="col-md-6 col-sm-6 col-xs-6">Mailbox</label>
                                                                            <div className="col-md-6 col-sm-6 col-xs-6">
                                                                                <label className="input_label">{row.MailBox==null?'---':row.MailBox}</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label className="col-md-6 col-sm-6 col-xs-6">Parking Space</label>
                                                                            <div className="col-md-6 col-sm-6 col-xs-6">
                                                                                <label className="input_label">
                                                                                    <a href="#">{row.ParkingSpace1==null?'---':row.ParkingSpace1}</a>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label className="col-md-6 col-sm-6 col-xs-6">Gate Code</label>
                                                                            <div className="col-md-6 col-sm-6 col-xs-6">
                                                                                <label className="input_label">{row.GateCode==null?'---':row.GateCode}</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label className="col-md-6 col-sm-6 col-xs-6">Entry</label>
                                                                            <div className="col-md-6 col-sm-6 col-xs-6">
                                                                                <label className="input_label">
                                                                                    <a href="#">{row.Entry==null?'---':row.Entry}</a>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label className="col-md-6 col-sm-6 col-xs-6">Lockbox Code</label>
                                                                            <div className="col-md-6 col-sm-6 col-xs-6">
                                                                                <label className="input_label">---</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label className="col-md-6 col-sm-6 col-xs-6">Wifi Network</label>
                                                                            <div className="col-md-6 col-sm-6 col-xs-6">
                                                                                <label className="input_label">{row.WiFiName==null?'---':row.WiFiName}</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label className="col-md-6 col-sm-6 col-xs-6">Wifi Password</label>
                                                                            <div className="col-md-6 col-sm-6 col-xs-6">
                                                                                <label className="input_label">{row.WiFiPassword==null?'---':row.WiFiPassword}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="row">
                                                                        <div className="form-group">
                                                                            <div className="col-md-12">
                                                                                <div className="loc_maptop">
                                                                                    <div className="lc_map">
                                                                                        <div
                                                                                            style={{
                                                                                            'width': '100%',
                                                                                            'height': '750px'
                                                                                        }}>
                                                                                            <div
                                                                                                id={'map' + this.state.mapId}
                                                                                                style={{
                                                                                                'width': '100%',
                                                                                                'height': '360px'
                                                                                            }}></div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label className="col-md-6 col-sm-6 col-xs-6">Suite Address</label>
                                                                            <div className="col-md-6 col-sm-6 col-xs-6  td_foldaddress">
                                                                                <p>{row.AptAddr}</p>
                                                                                <p>{row.LeaseState}</p>
                                                                                <p>{row.LeaseCity},{row.AptZip}</p>
                                                                                <label className="input_label">
                                                                                    <a href={'https://www.google.com/maps/place/' + row.AptAddr + ',' + row.LeaseState + ',' + row.LeaseCity} target="_blank">Get Directions</a>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label className="col-md-6 col-sm-6 col-xs-6">Leasing Office</label>
                                                                            <div className="col-md-6 col-sm-6 col-xs-6 td_foldaddress">
                                                                            <p>{row.address}</p>
                                                                            <p>{row.statename}</p>
                                                                            <p>{row.city_name},{row.ZipCode}</p>
                                                                                <label className="input_label">
                                                                                <a href={'https://www.google.com/maps/place/' + row.address + ',' + row.statename + ',' + row.city_name} target="_blank">Get Directions</a>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        )
                                                    })}

                                        </div>
                                        <div
                                            className="profile-opener mrg-top-40"
                                            onClick={this.toogle}>
                                            <p >Arrival Instructions & Information
                                                <i className="fa fa-caret-right"></i>
                                            </p>
                                        </div>
                                        <div className="mrg-top-40"></div>
                                    </div>

                                    <nav
                                        className="slide-menu"
                                        id="test-menu-right"
                                        style={{
                                        right: '0px',
                                        display: this.state.show
                                            ? 'block'
                                            : 'none'
                                    }}>
                                        <ul>
                                            <li>
                                                <a
                                                    onClick={this.toogle}>
                                                    <i className="fa fa-angle-left"></i>
                                                    Arrival Instructions & Information</a>
                                            </li>
                                            <li>
                                                <a className="slidemenu_icons" href="">Entry Instructions</a>
                                                <ul>
                                                    <li>Entry Arrangements: A lockbox will be on the railing outside the leasing
                                                        office. Your apartment # will be on this lockbox. See photos attach for location
                                                        of lockbox. To open the lockbox, line up your 4 digit lockbox code as it appears
                                                        on your confirmation (under “Entry” above). You will find your front door AND
                                                        common key inside. The common key may be used to open the gate. If you have any
                                                        problems, please call us at 800-367-9501.
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <a className="slidemenu_icons" href="#">Parking Space Assignment</a>
                                                <ul>
                                                    <li>please be sure to follow all parking rules,which may include,but are not
                                                        limited to :parking in your assigned parking spot (if applicable), utilizing
                                                        only one parking spot per vehicle, posting parking stickers in your vehicle (if
                                                        applicable), not blocking garages or driveways, not parking in fire zones, and
                                                        not parking illegally in handicapped spots. Failure to follow all parking rules
                                                        may result in your vehicle being ticketed or towed. Please also keep in mind
                                                        that other violations of state laws (such as expired registration) may result in
                                                        similar actions. SuiteAmerica is not responsible for any fees associated with
                                                        parking tickets or towing. Please do not leave any valuables in your vehicle. If
                                                        you have any questions, please be sure to contact your SuiteAmerica
                                                        representative.
                                                    </li>
                                                </ul>
                                            </li>

                                            <li>
                                                <a className="slidemenu_icons" href="#">Accommodations</a>
                                                <ul>
                                                    <li>
                                                        One Bedroom, One Bath apartment.Complete with furniture,housewares,expanded
                                                        basic cable television ,local phone service,standard high speed internet,and
                                                        monthly utlities.
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <a className="slidemenu_icons" href="#">Phone Service</a>
                                                <ul>
                                                    <li>
                                                        There is a long distance block on the phone. Any long distance/toll calls made
                                                        around the block will be billed to the credit card provided on the Statement of
                                                        Authorization to SuiteAmerica.For assistance, please call the number above.
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <a className="slidemenu_icons" href="#">Departure Date</a>
                                                <ul>
                                                    <li>
                                                        Please note your departure date stated above.if you need to extend your
                                                        stay,please contact us as soon as possible.We will make every effort to extend
                                                        your stay in the above apartment.Please note that your current scheduled vacate
                                                        date.Extensions may be subject to a rate increase and are subject to avaliablity
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <a className="slidemenu_icons" href="#">Renters Insurance</a>
                                                <ul>
                                                    <li>
                                                        The insurance carried by SuiteAmerica does not cover you for property damage or
                                                        loss claims of personal injury made against you.We strongly recommend that you
                                                        contact the insurance agency of your choice to look into renters insurance for
                                                        your stay
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <a className="slidemenu_icons" href="#">Other Important Info</a>
                                                <ul>
                                                    <li>
                                                        SuiteAmerica reserves the right to substitute the apartment with an apartment of
                                                        comparable size and amenities should this apartment not be available as
                                                        scheduled. if you have any difficulty checking in,please call us at
                                                        800-367-9501.if you are calling after normal business hours, please follow the
                                                        instructions to leave an emergency message. One of our team memnbers will return
                                                        your call promptly.
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                                {this.state.tourDetails.length > 0
                                    ? <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <h4 className="panel-title">
                                                    <a
                                                        className="collapsed"
                                                        data-toggle="collapse"
                                                        data-parent="#accordion"
                                                        href="#collapse5"
                                                        aria-expanded="true"
                                                        aria-controls="collapse5">
                                                        MY TOUR
                                                    </a>
                                                </h4>
                                            </div>
                                            {this
                                                .state
                                                .tourDetails
                                                .map((row, index) => {
                                                    return (
                                                        <div
                                                            id="collapse5"
                                                            className="panel-collapse collapse"
                                                            key={{
                                                            index
                                                        }}>
                                                            <div className="panel-body panel-body_colors">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className="row">
                                                                            <div className="form-group">
                                                                                <label className="col-md-6 col-sm-6 col-xs-6">Tour Date</label>
                                                                                <div className="col-md-6 col-sm-6 col-xs-6">
                                                                                    <label className="input_label">{moment
                                                                                            .utc(row.ServiceDate)
                                                                                            .format('LL')}</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <label className="col-md-6 col-sm-6 col-xs-6">Tour Start Time</label>
                                                                                <div className="col-md-6 col-sm-6 col-xs-6">
                                                                                    <label className="input_label">{moment
                                                                                            .utc(row.MeetupTime)
                                                                                            .format('hh:mm A')}</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <label className="col-md-6 col-sm-6 col-xs-6">Location</label>
                                                                                <div className="col-md-6 col-sm-6 col-xs-6">
                                                                                    <label className="input_label">{row.label} {row.City}, {row.State}</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <label className="col-md-6 col-sm-6 col-xs-6">Your DS Consultant</label>
                                                                                <div className="col-md-6 col-sm-6 col-xs-6">
                                                                                    <label className="input_label">{row.DSP}</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <label className="col-md-6 col-sm-6 col-xs-6">Phone</label>
                                                                                <div className="col-md-6 col-sm-6 col-xs-6">
                                                                                    <label className="input_label">{row.DSPUserPhone}</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <label className="col-md-6 col-sm-6 col-xs-6">Email</label>
                                                                                <div className="col-md-6 col-sm-6 col-xs-6">
                                                                                    <label className="input_label">
                                                                                        <a href="#">{row.DSPUserEmail}</a>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div
                                                                            className="well pull-right-lg"
                                                                            style={{
                                                                            "border": "0px solid"
                                                                        }}>
                                                                            <p>{moment
                                                                                    .utc(row.ServiceDate)
                                                                                    .format('MMMM YYYY')}</p>
                                                                            <div
                                                                                className=""
                                                                                style={{
                                                                                "padding": "0px"
                                                                            }}>
                                                                                <div
                                                                                    style={{
                                                                                    overflow: 'hidden'
                                                                                }}>
                                                                                    <div className="form-group">
                                                                                        <div className="row">
                                                                                            <div className="col-md-12">
                                                                                                <div id="datetimepicker12"></div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {row.CompanyName == 'Nike'
                                                                    ? <div className="profile-opener">
                                                                            <a href="https://suiteamerica.com/company/nike/home">
                                                                                <p >View Your Itinerary
                                                                                    <i className="fa fa-caret-right"></i>
                                                                                </p>
                                                                            </a>
                                                                        </div>
                                                                    : null
}
                                                                {row.CompanyName == 'Apple'
                                                                    ? <div className="profile-opener">
                                                                            <a
                                                                                href={"https://suiteamerica.com/destination-service/itinerary/apple/" + row.Filenum + '/' + row.ItineraryID}>
                                                                                <p >View Your Itinerary
                                                                                    <i className="fa fa-caret-right"></i>
                                                                                </p>
                                                                            </a>
                                                                        </div>
                                                                    : null
}
                                                                {row.CompanyName == 'SuiteAmerica'
                                                                    ? <div className="profile-opener">
                                                                            <a href="https://suiteamerica.com/locations">
                                                                                <p >View Your Itinerary
                                                                                    <i className="fa fa-caret-right"></i>
                                                                                </p>
                                                                            </a>
                                                                        </div>
                                                                    : null
}
                                                                {row.CompanyName == 'No Pricing '
                                                                    ? <div className="profile-opener">
                                                                            <a
                                                                                href={"https://suiteamerica.com/destination-service/itinerary/nopricing/" + row.Filenum + '/' + row.ItineraryID}>
                                                                                <p >View Your Itinerary
                                                                                    <i className="fa fa-caret-right"></i>
                                                                                </p>
                                                                            </a>
                                                                        </div>
                                                                    : null
}

                                                            </div>
                                                        </div>
                                                    )
                                                })}

                                        </div>
                                    : ''}
                            </div>
                        </div>
                        <GotAquestion/>
                    </div>
                </div>
                <Footer/>
            </div>

        );
    }
}
export default ReservationInformation;