/* Importing the node modules, child components, services and controllers used 
   inside GuestHeader component */
import { Link } from 'react-router-dom';
import SessionService from "../../services/sessionService";
import $ from "jquery";
import Conference from '../../services/conference';
import CallModal from './callModal'
import LoginModal from '../controller/loginmodalController';
import BookNowModal from '../controller/booknowModal';
import SiteLoader from './siteLoader';
import AppContoller from '../controller/appController';
import GuestServicesapi from '../../services/guestServicesapi';
import authDetails from "./authDetails";
/* GuestHeader Component initialization */
class AltairHeader extends React.Component {
    /* Initializing objects of its GuestHeader class */
    constructor(props) {
        super(props)
        /* GuestHeader Component State variables Initialization */
        this.state = {
            name: '',
            company: '',
            location: '',
            phone: '',
            email: '',
            password: '',
            bookingDetails: {},
            iconStatus: false,
            login: false,
            register: false,
            forgotpassword: false,
            message: '',
            onNext: false,
            color: '',
            loader: false,
            menuId: 0,
            clickedMenuId: 0,
            guest: true,
            client: false,
            navIndex: -1,
            phoneNo: '',
            resLocation: '',
            resName: '',
            resCompany: '',
            resEmail: '',
            resPhone: '',
            resPlace: '',
            navigation: false,
            roleId: 0,
            ClientType: '',
            callModalLoading: false
        }
        this.removeNavHover = this.removeNavHover.bind(this);
        this.changeIcon = this.changeIcon.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.loginClear = this.loginClear.bind(this);
        this.logout = this.logout.bind(this);
        this.navigation = this.navigation.bind(this);
        this.clearPhone = this.clearPhone.bind(this);
        this.menuClick = this.menuClick.bind(this);
        this.navSelection = this.navSelection.bind(this);
    }
    /* It is invoked immediately after a component is mounted */
    async componentDidMount() {
        $(document).ready(function () {
            $(".cc_mega_login2").click(function () {
                $('.navbar-collapse').removeClass('in');
            });
            $(window).scroll(function () {
                if ($(this).scrollTop() != 0) {
                    $('li.dd_booknow').removeClass('open');
                    $('li.new_userlog').removeClass('open');
                }
            });
        });
        $("body").removeClass('bodytrns320l no-scroll profile-open')
        AppContoller.removeSiteLoader();
        var client = await SessionService.SessionGet({ type: 'client' });
        if (client.error == 0) {
            await this.setState({
                roleId: (client.roleId != undefined && Object.keys(client).length > 0) ? client.roleId : 0,
                ClientType: (client.ClientType != undefined && Object.keys(client).length > 0 ? client.ClientType : '')
            })
        }

        var altair = await SessionService.SessionGet({ type: 'altair' });
        if (altair.error == 0) {
            await this.setState({
                roleId: (altair.roleId != undefined && Object.keys(altair).length > 0) ? altair.roleId : 0,
                ClientType: (altair.ClientType != undefined && Object.keys(altair).length > 0 ? altair.ClientType : '')
            })
        }

        var web = await SessionService.SessionGet({ type: 'guest' });
        if (web.error == 0) {
            await this.setState({
                roleId: (web.roleId != undefined && Object.keys(web).length > 0) ? web.roleId : 0,
                ClientType: (web.ClientType != undefined && Object.keys(web).length > 0 ? web.ClientType : '')
            })
        }

    }

    /* To update the state values for the input fields */
    setValue(field, e) {
        var object = {};
        object[field] = e.target.value;
        this.setState(object);
    }
    clearPhone() {
        this.setState({ phoneNo: '', message: '', color: '', callModalLoading: true })
    }
    callTo() {
        if (this.state.phoneNo.length < 10) {
            this.setState({ message: 'Please enter a valid number', color: 'red' })
        }
        else {
            const numbers = [
                { number:'+18003679501'},
                { number: '+1' + this.state.phoneNo }];
            Conference.conference(numbers)
        }
    }
    /* To change the menu icon in mobile/tab view on menu open*/
    changeIcon() {
        this.setState({
            iconStatus: !this.state.iconStatus
        });
    }
    /* To close the opened menu */
    closePopup() {
        this.setState({
            resLocation: '',
            resName: '',
            resCompany: '',
            resEmail: '',
            resPhone: '',
            resPlace: ''
        })
        $(".navbar-collapse").removeClass('in');
        $("#mobile-nav button.navbar-toggle").addClass('collapsed');
        this.setState({ iconStatus: false, navIndex: -1, menuId: 0, clickedMenuId: 0 });
        localStorage.setItem('navIndex', -1);
        localStorage.setItem('menuId', 0);
        localStorage.setItem('clickedMenuId', 0);
    }
    /* To clear the login form input fiels values */
    loginClear() {
        this.setState({ message: '', client: false, guest: true, color: '', phone: '', email: '', password: '', login: true, onNext: false, forgotpassword: false })
    }
    menuClick(clickedMenuId, index) {
        this.setState({ clickedMenuId, navIndex: index });
        localStorage.setItem('clickedMenuId', clickedMenuId);
        localStorage.setItem('navIndex', index);
        localStorage.setItem('menuId', clickedMenuId);
    }
    navSelection(index) {
        this.setState({ navIndex: index });
        localStorage.setItem('navIndex', index);
    }
    removeNavHover() {
        this.setState({ navIndex: -1, menuId: 0, clickedMenuId: 0 });
        localStorage.setItem('navIndex', -1);
        localStorage.setItem('menuId', 0);
        localStorage.setItem('clickedMenuId', 0);
    }
    /* To clear the local storage data and logout from the fivestar application */
    async logout() {
        const response = this.state.roleId == 3 ? await GuestServicesapi.ClientUsersLogOut({ sessionId: localStorage.getItem('guestSession') }) : await GuestServicesapi.GuestUsersLogOut({ sessionId: localStorage.getItem('guestSession') })
        localStorage.removeItem('guestSession');
        if (this.state.roleId == 2) {
            const userData = await SessionService.SessionDestory({ type: 'guest' });
            //userData.error==0?location.href = '/guest-login':''
            userData.error == 0 ? location.href = "https://" + authDetails.authCredentails().hostName + "/v2/logout?client_id=" + authDetails.authCredentails().clientId + "&returnTo=" + authDetails.authCredentails().redirectUri : '';
        }
        if (this.state.roleId == 3) {
            const userData = await SessionService.SessionDestory({ type: 'client' });
            userData.error == 0 ? location.href = '/celogin' : ''
        }
        if (this.state.roleId == 4) {
            const userData = await SessionService.SessionDestory({ type: 'altair' });
            userData.error == 0 ? location.href = '/altair-login' : ''
        }
    }
    navigation() {
        this.setState({ navigation: !this.state.navigation });
    }
    /* It is invoked to return html content */
    render() {
        const menuId = JSON.parse(localStorage.getItem('menuId'));
        const navIndex = JSON.parse(localStorage.getItem('navIndex'));

        return (
            <div className="corporate_menu">
                <nav className="navmenu">
                    <div className="head-logo navbar-header">
                        <img src='http://localhost:8082/assets/newUI-images/logo/SA-full-logo.svg' alt="" />
                    </div>

                    <ul>
                        <li>
                            <Link
                                to="/altair-dean"
                                onClick={this.closePopup} target="_blank">The Dean</Link>
                        </li>
                        {this.state.roleId == 4
                            ? <li> <a onClick={this.logout}>   <i className="fa fa-sign-out"></i> </a> </li> : ""}

                    </ul>
                    {/*     <div className="collapse navbar-collapse" id="myNavbar">

                    
                           <div className="right_btnss">
                               <ul className="">
                               <li>
                                   <Link
                                   to="/altair-dean"
                                   onClick={this.closePopup} target="_blank">LOCATIONS</Link>
                               </li>

                               <li className="dropdown new_userlog">

                                   {this.state.roleId == 4
                                   ? 
                                   <div id="service-header" className="dropdown-toggle" data-toggle="dropdown">
                                       <Link to="#">
                                       <i className="fa fa-sign-out"></i>
                                       </Link>
                                       <b className="caret"></b>
                                   </div>
                                   : null} 
                                   <ul className="dropdown-menu ">
                                       <li>
                                           <a onClick={this.logout}>logout</a>
                                       </li>
                                   </ul>
                               </li>
                               
                               </ul>
                           </div>
                   </div>  */}




                </nav>


                {/*        
                   <nav id="mobile-nav" className="navbar navbar-inverse cc_megamenu top fixed">
                       <div className="navbar-header">
                           <button
                           type="button" 
                           className="navbar-toggle collapsed" 
                           data-toggle="collapse" 
                           data-target="#myNavbar33"
                           onClick={this.changeIcon}>
                           <div id="nav-icon2">
                               <span></span>
                               <span></span>
                           </div>
                           <div id="nav-icon1">
                               <span></span>
                               <span></span>
                               <span></span>
                           </div>
                           </button>
                           <Link className="navbar-brand logo" to="/" onClick={this.closePopup}>
                           <img src={s3BucketUrl_WebsiteImages+"SA-Logo.png"} alt=""/>
                           </Link>

                           <li className="dropdown new_userlog">
                               {this.state.roleId == 4 ? 
                               <div id="service-header" className="dropdown-toggle" data-toggle="dropdown">
                               <Link to="#">
                               <i className="fa fa-user"></i>
                               </Link>
                               <b className="caret"></b>
                               </div>
                               : null} 
                             
                               <ul className="dropdown-menu ">
                               <li>
                                   <a onClick={this.logout}>logout</a>
                               </li>
                               </ul>
                           </li>
                           
                       </div>
                       
                   </nav>

       */}

                <LoginModal />
                {this.state.callModalLoading ? <CallModal /> : ''}
                <div className="res-cover-heder-ht"></div>
                <SiteLoader />
            </div>

        );
    }
}
export default AltairHeader;