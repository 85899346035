class Faqs extends React.Component{
    constructor(props){
        super(props)
        this.state={

        }
    }

    componentDidMount(){
        function toggleIcon(e) {
            $(e.target)
                .prev('.panel-heading')
                .find(".short-full")
                .toggleClass('glyphicon-plus glyphicon-minus');
        }
        $('.panel-group').on('hidden.bs.collapse', toggleIcon);
        $('.panel-group').on('shown.bs.collapse', toggleIcon);
    }

render(){
    return(

        <div className="faqView">
        <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
          <div className="panel panel-default">
            <div className="panel-heading" role="tab" id="headingOne">
              <h4 className="panel-title">
                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  {/* <i className="short-full glyphicon glyphicon-plus" /> */}
                  When will I receive my apartment arrival information?
                </a>
              </h4>
            </div>
            <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
              <div className="panel-body">
                You will receive your arrival information 3-7 days before your arrival date, and it will include your temporary address, directions, parking information, and other relevant details to make your arrival at The Dean delightful and easy.
              </div>
            </div>
          </div>
          <div className="panel panel-default">
            <div className="panel-heading" role="tab" id="headingTwo">
              <h4 className="panel-title">
                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  {/* <i className="short-full glyphicon glyphicon-plus" /> */}
                  How do I access my apartment?
                </a>
              </h4>
            </div>
            <div id="collapseTwo" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
              <div className="panel-body">
                The Dean is outfitted with electronic key access through Latch for your suite and the entire community. See a tutorial on how Latch works by clicking <a href="#">here</a>. You will be granted Latch access on the day of your arrival, and will use your mobile phone to access elevators, lobby doors, your suite door, and common area doors. Latch also has a keypad so if your Latch app is slow or not working, you can enter your suite door code manually. If you experience consistent issues with using Latch, SuiteAmerica can provide you with an electronic key card instead.
              </div>
            </div>
          </div>
          <div className="panel panel-default">
            <div className="panel-heading" role="tab" id="headingThree">
              <h4 className="panel-title">
                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  {/* <i className="short-full glyphicon glyphicon-plus" /> */}
                  Can I bring my pet or have guests visit me?
                </a>
              </h4>
            </div>
            <div id="collapseThree" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
              <div className="panel-body">
                Yes, this is your temporary home and we want you to be comfortable and happy. Pets and visitors are welcome. The Dean does have breed restrictions, which can be found <a href="#">here</a>. Please specify the type of pet you have to your Relocation Consultant, and a non-refundable pet fee and pet rent may apply. If you do bring a pet, make sure all vaccination records are up to date as you may be asked to provide a copy to the property manager.
              </div>
            </div>
          </div>
          <div className="panel panel-default">
            <div className="panel-heading" role="tab" id="headingFour">
              <h4 className="panel-title">
                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                  {/* <i className="short-full glyphicon glyphicon-plus" /> */}
                  What happens if I lose my keys?
                </a>
              </h4>
            </div>
            <div id="collapseFour" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFour">
              <div className="panel-body">
                The Dean is outfitted with electronic key access through Latch. If you need assistance with Latch, please contact SuiteAmerica at <b>1-800-367-9501</b>. Keep in mind, your Latch access is through the mobile phone app, so always take your phone with you when you leave your suite.
              </div>
            </div>
          </div>
          <div className="panel panel-default">
            <div className="panel-heading" role="tab" id="headingFive">
              <h4 className="panel-title">
                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                  {/* <i className="short-full glyphicon glyphicon-plus" /> */}
                  What happens if I lock myself out of the apartment / bedroom?
                </a>
              </h4>
            </div>
            <div id="collapseFive" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFive">
              <div className="panel-body">
                If you have left your phone inside your suite and are locked out during and, or after business hours, please dial SuiteAmerica’s toll-free number, <b>1-800-367-9501</b> and a Guest Service representative will be able to assist you. In the event SuiteAmerica is unable to assist you, a hotel accommodation can be made for you, at your cost.
              </div>
            </div>
          </div>
          <div className="panel panel-default">
            <div className="panel-heading" role="tab" id="headingSix">
              <h4 className="panel-title">
                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                  {/* <i className="short-full glyphicon glyphicon-plus" /> */}
                  Who do I contact if there is a maintenance problem with my corporate apartment?
                </a>
              </h4>
            </div>
            <div id="collapseSix" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSix">
              <div className="panel-body">
                SuiteAmerica is here to help you!
                <ul className="ulFaqs">
                  <li>For a non-life-threatening emergency, please dial SuiteAmerica’s toll-free number: <b>1-800-367-9501</b>
                  </li>
                  <li>After normal business hours you may dial this same number and leave a detailed message, a SuiteAmerica Guest Service representative will call you back within a few minutes to assist you!
                  </li>
                  <li>You can send a service request/message through the Apple TV SuiteAmerica App on your living room TV 
                  </li>
                  <li>You can send us an email with your question/request to <a href="mailto:guestserve@suiteamerica.com">guestserve@suiteamerica.com</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="panel panel-default">
            <div className="panel-heading" role="tab" id="headingSeven">
              <h4 className="panel-title">
                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                  {/* <i className="short-full glyphicon glyphicon-plus" /> */}
                  What happens if I break something in my corporate apartment?
                </a>
              </h4>
            </div>
            <div id="collapseSeven" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSeven">
              <div className="panel-body">
                In the event an item breaks and you report it to SuiteAmerica, SuiteAmerica will determine the replacement cost. SuiteAmerica will work with your Relocation Consultant to arrange for payment.
              </div>
            </div>
          </div>
          <div className="panel panel-default">
            <div className="panel-heading" role="tab" id="headingEight">
              <h4 className="panel-title">
                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                  {/* <i className="short-full glyphicon glyphicon-plus" /> */}
                  If my corporate apartment has amenities like a pool and fitness center, am I allowed to use them?
                </a>
              </h4>
            </div>
            <div id="collapseEight" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingEight">
              <div className="panel-body">
                Yes, as a guest of SuiteAmerica you are welcome to enjoy all of the amenities that the community has to offer. Upon arrival your Latch access will include all common areas and amenities at The Dean.
              </div>
            </div>
          </div>
          <div className="panel panel-default">
            <div className="panel-heading" role="tab" id="headingNine">
              <h4 className="panel-title">
                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                  {/* <i className="short-full glyphicon glyphicon-plus" /> */}
                  Do I need to pay for anything while I stay in my corporate apartment?
                </a>
              </h4>
            </div>
            <div id="collapseNine" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingNine">
              <div className="panel-body">
                Your housing accommodations are paid for by your Relocation Management Company. In the event you add or order any specialty items from SuiteAmerica, such as upgraded channels or additional furniture or housewares, you will be financially responsible and SuiteAmerica will collect a credit card from you electronically.
              </div>
            </div>
          </div>
          <div className="panel panel-default">
            <div className="panel-heading" role="tab" id="headingTen">
              <h4 className="panel-title">
                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                  {/* <i className="short-full glyphicon glyphicon-plus" /> */}
                  What is included in my corporate apartment?
                </a>
              </h4>
            </div>
            <div id="collapseTen" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTen">
              <div className="panel-body">
                Your corporate apartment will include a first night’s meal kit and some starter essentials, but it will be your responsibility to purchase food and toiletries throughout your stay. <a href="https://www.suiteamerica.com/business-furnishing" target="_blank">Here is a list of SuiteAmerica’s standard inclusions</a>.
              </div>
            </div>
          </div>
          <div className="panel panel-default">
            <div className="panel-heading" role="tab" id="headingTen1">
              <h4 className="panel-title">
                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTen1" aria-expanded="false" aria-controls="collapseTen1">
                  {/* <i className="short-full glyphicon glyphicon-plus" /> */}
                  Where do I leave my keys when it is time to check-out?
                </a>
              </h4>
            </div>
            <div id="collapseTen1" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTen1">
              <div className="panel-body">
                Because The Dean has electronic keys, your Latch access will expire at Midnight on the day of check-out. There’s nothing else to do when you are ready to leave.
              </div>
            </div>
          </div>
          <div className="panel panel-default">
            <div className="panel-heading" role="tab" id="headingTen2">
              <h4 className="panel-title">
                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTen2" aria-expanded="false" aria-controls="collapseTen2">
                  {/* <i className="short-full glyphicon glyphicon-plus" /> */}
                  Should I clean out the refrigerator when I check-out or will someone do that for me?
                </a>
              </h4>
            </div>
            <div id="collapseTen2" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTen2">
              <div className="panel-body">
                You should remove all perishable food items and dispose all trash in the bins located on each floor. You can donate your non-perishable food items in the Move For Hunger bag that was left on your countertop when you arrived. Just leave the bag on the counter, and SuiteAmerica will collect and donate it on your behalf.
              </div>
            </div>
           
          </div>
          

          <div className="panel panel-default">
            <div className="panel-heading" role="tab" id="headingTen3">
              <h4 className="panel-title">
                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTen3" aria-expanded="false" aria-controls="collapseTen3">
                  {/* <i className="short-full glyphicon glyphicon-plus" /> */}
                  What type of television service will my suite have?
                </a>
              </h4>
            </div>
           
            <div id="collapseTen3" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTen3">
              <div className="panel-body">
              Most suites will have the Comcast streaming service called Flex in each bedroom.  Flex allows you to sign into different streaming apps, just like Apple TV.  See which Apps are available <a href="https://www.xfinity.com/learn/digital-cable-tv/streaming-services?INTCMP=FlexLearn_Flex_ExploreAppsFlex_Flex" target="_blank">here</a>. Some suites will have standard cable services, and all suites come equipped with smart TVs in the living room for a premium viewing experience. If your streaming account is located outside the United States, you may need to access your settings in each app to reset your location to the United States.
              </div>
            </div>
          </div>
        </div>{/* panel-group */}
      </div>
    )
}
}

export default Faqs;