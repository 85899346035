const CommunitesList = {
  "/thedean": {
    Community: "The Dean",
    Name: "THE DEAN",
    Video: { url: "" },
    Video3d: {url: ""},
    BanerImage: "assets/images/DeanImg/DeanBanner1.jpg",
    HeadLine:
      "Now renting fully furnished luxury apartments in Silicon Valley.",
    BanerName:
      "Exclusive Access to the Newest, Most Luxurious Silicon Valley Community",
    DEFAULT: {
      Heading: "",
      Paragraph:
        "<p>This is a beautiful brand new community! The Dean’s 2019 grand opening means you’ll experience one of the nicest and newest communities in Silicon Valley. The in-suite amenities, such as floor-to-ceiling windows, oversized closets, quartz countertops, and stainless steel appliances, are absolutely gorgeous.</p> <p>Three of 4 buildings are complete and securely insulated from the last building still under construction. This first building, where you’ll be staying, is beautifully landscaped and radiates a serene, quiet ambiance, and relaxing atmosphere.</p> <p>Amenities are available but limited due to COVID restrictions by the county. Please reach out to the team for the most up to date reservation system for the amenities.</p>",
     
        Data: [
        {
          Name: "Perfectly Located",
          Image:"assets/images/DeanImg/deanListImg1.png",
          Paragraph:
            "With a nearly perfect Bikescore and outstanding Walkscore, The Dean’s immediate proximity to the Village at San Antonio Center, outdoor trails, public transportation, and major Silicon Valley headquarters make it the perfect location in Mountain View. The Dean only elevates this already impressive area. Walk, bike, and explore your new neighborhood."
        },
        {
          Name: "Unique, Luxurious Elegance",
          Image:"assets/images/DeanImg/deanListImg22.png",
          Paragraph:
            "You won’t find the traditional living experience here at The Dean. Resort style apartment homes featuring floor-to ceiling windows, keyless entry, oversized closets with customizable Elfa® shelving, LATCH™ Keyless entry and Honeywell thermostats. High ceilings and private terraces are the perfect compliments to your evening cocktail hours. Details like energy efficient LED lighting, quartz countertops, and stainless-steel appliances make all the difference."
        },
        {
          Name: "On-Site Concierge",
          Image:"assets/images/DeanImg/deanListImg3.png",
          Paragraph:
            "Have a question about a package delivery? Need a firmer mattress? A friendly face to talk to? At The Dean, our concierge services is always ready and available. Convenient, caring, and there for you every step of the way."
        },
        {
          Name: "Eat, Shop, Move & Play",
          Image:"assets/images/DeanImg/deanListImg4.png",
          Paragraph:
            "Looking for a little balance? Visit the Nekter Juice bar for specialty drinks and cleanses. Stroll through the open-air, garden lined Stanford Shopping Center. Build strength and feel empowered during a barre3 class, or burn off some energy with your pup while exploring Del Medio Park. Find your bliss at The Dean."
        }
      ]
    },
    FLOORPLANS: {
      Heading: "TAKE A VIRTUAL TOUR OF YOUR FUTURE HOME",
      Paragraph:
        "With over 25 unique floor plans to choose from, ranging from studios to lush 3 bedrooms suites, you will be transformed into a world of luxury and comfort. Lavish amenities and resort style grounds, you won’t find the traditional living experience here at the Dean.",
      Data: [
        {
          floors: [
            {
              name: "STUDIO",
              squareFeet: "519 SQ FT",
              plan: "PLAN S1-9",
              image: "assets/images/floorImages/dean/S1-9(wood).png"
            }
          ],
          name: "STUDIO",
          id: "STUDIO"
        },
        {
          floors: [
            {
              name: "1 BED",
              squareFeet: "774 SQ FT",
              plan: " PLAN A1-8",
              image: "assets/images/floorImages/dean/A1-8(metal).png"
            },
            {
              name: "1 BED",
              squareFeet: "774 SQ FT",
              plan: " PLAN A1-16",
              image: "assets/images/floorImages/dean/A1-16(metal).png",
              video3dUrl:"https://my.matterport.com/show/?m=hSwXb5QJWjE"
            },
            {
              name: "1 BED",
              squareFeet: "774 SQ FT",
              plan: " PLAN A1-18",
              image: "assets/images/floorImages/dean/A1-18(wood).png",
              video3dUrl:"https://my.matterport.com/show/?m=aikZn5HW32b"
            },
            {
              name: "1 BED",
              squareFeet: "689 SQ FT",
              plan: " PLAN A2-2",
              image: "assets/images/floorImages/dean/A2-2(wood).png"
            },
            {
              name: "1 BED",
              squareFeet: "814 SQ FT",
              plan: " PLAN A3-3",
              image: "assets/images/floorImages/dean/A3-3(wood).png",
              video3dUrl:"https://my.matterport.com/show/?m=az8v4nYt83N"
            },
            {
              name: "1 BED",
              squareFeet: "781 SQ FT",
              plan: " PLAN A3-4",
              image: "assets/images/floorImages/dean/A3-4(wood).png"
            },
            {
              name: "1 BED",
              squareFeet: "876 SQ FT",
              plan: " PLAN A3-11",
              image: "assets/images/floorImages/dean/A3-11(wood).png"
            },
            {
              name: "1 BED",
              squareFeet: "667 SQ FT",
              plan: " PLAN A4-1",
              image: "assets/images/floorImages/dean/A4-1(wood).png",
              video3dUrl:"https://my.matterport.com/show/?m=az8v4nYt83N"
            },
            {
              name: "1 BED",
              squareFeet: "660 SQ FT",
              plan: " PLAN A4-41",
              image: "assets/images/floorImages/dean/A4-41(wood).png"
            },
            {
              name: "1 BED",
              squareFeet: "806 SQ FT",
              plan: " PLAN A5",
              image: "assets/images/floorImages/dean/A5(wood).png"
            },
            {
              name: "1 BED",
              squareFeet: "779 SQ FT",
              plan: " PLAN A6-1",
              image: "assets/images/floorImages/dean/A6-1(wood).png"
            },
            {
              name: "1 BED",
              squareFeet: "677 SQ FT",
              plan: " PLAN A7-4 ",
              image: "assets/images/floorImages/dean/A7-4(wood).png"
            },
            {
              name: "1 BED",
              squareFeet: "594 SQ FT",
              plan: " PLAN A9",
              image: "assets/images/floorImages/dean/A9(wood).png"
            },
            {
              name: "1 BED",
              squareFeet: "968 SQ FT",
              plan: " PLAN A10",
              image: "assets/images/floorImages/dean/A10(wood).png"
            },
            {
              name: "1 BED",
              squareFeet: "829 SQ FT",
              plan: " PLAN A11",
              image: "assets/images/floorImages/dean/A11(wood).png"
            }
          ],
          name: "1 BED",
          id: "1BED"
        },
        {
          floors: [
            {
              name: "2 BED",
              squareFeet: "1171 SQ FT",
              plan: "PLAN B-16",
              image: "assets/images/floorImages/dean/B-16(wood).png"
            },
            {
              name: "2 BED",
              squareFeet: "1060 SQ FT",
              plan: "PLAN B1-4",
              image: "assets/images/floorImages/dean/B1-4(wood).png"
            },
            {
              name: "2 BED",
              squareFeet: "1171 SQ FT",
              plan: "PLAN B1-16",
              image: "assets/images/floorImages/dean/B1-16(wood).png"
            },
            {
              name: "2 BED",
              squareFeet: "1189 SQ FT",
              plan: "PLAN B2",
              image: "assets/images/floorImages/dean/B2(wood).png",
              video3dUrl:"https://my.matterport.com/show/?m=hYkEqFP8PZo"
            },
            {
              name: "2 BED",
              squareFeet: "1008 SQ FT",
              plan: "PLAN B3",
              image: "assets/images/floorImages/dean/B3(wood).png",
              video3dUrl:"https://my.matterport.com/show/?m=UWrVxCEfBDi"
            },
            {
              name: "2 BED",
              squareFeet: "962 SQ FT",
              plan: "PLAN B3-1",
              image: "assets/images/floorImages/dean/B3-1(metal).png"
            },
            {
              name: "2 BED",
              squareFeet: "1067 SQ FT",
              plan: "PLAN B4",
              image: "assets/images/floorImages/dean/B4(wood).png"
            },
            {
              name: "2 BED",
              squareFeet: "951 SQ FT",
              plan: "PLAN B4-2",
              image: "assets/images/floorImages/dean/B4-2(wood).png"
            },
            {
              name: "2 BED",
              squareFeet: "999 SQ FT",
              plan: "PLAN B5",
              image: "assets/images/floorImages/dean/B5(wood).png"
            },
            {
              name: "2 BED",
              squareFeet: "1005 SQ FT",
              plan: "PLAN B6",
              image: "assets/images/floorImages/dean/B6(wood).png",
              video3dUrl:"https://my.matterport.com/show/?m=YwYDXmrdSqE"
            }
          ],
          name: "2 BED",
          id: "2BED"
        },
        {
          floors: [
            {
              name: "3 BED",
              squareFeet: "1196 SQ FT",
              plan: "PLAN C1-6",
              image: "assets/images/floorImages/dean/C1-6(wood).png",
              video3dUrl:"https://my.matterport.com/show/?m=DwHdvN6Sew2"
            },
            {
              name: "3 BED",
              squareFeet: "1311 SQ FT",
              plan: "PLAN  C2",
              image: "assets/images/floorImages/dean/C2(wood).png"
            }
          ],
          name: "3 BED",
          id: "3BED"
        }
      ]
    },
    NEIGHBORHOOD: {
      Heading: "IT’S A BEAUTIFUL DAY TO EXPLORE",
      Paragraph:
        "Welcome to The Dean Apartments in the heart of Mountain View, just steps away from the San Antonio Caltrain Station and a quick commute from Google headquarters. Stop by Trader Joe’s known for their selection of domestic and imported cheeses, organic fruits and veggies, and locally sourced meats. The Artisan Wine Depot of Mountain View is the logical next stop for their global selection of specialty wines.",
      Data: {}
    },
    AMENITIES: {
      Heading: "THE IMPORTANT LITTLE THINGS",
      Paragraph:
        "Enjoy a well-deserved vacation welcome home to The Dean Apartments. Stop by the clubhouse, featuring shuffleboard, pinball machines, pool tables and dart boards. Choose from our iconic glass-sided pool or junior Olympic lap pool. Work up a sweat in our state of the art fitness center featuring Peloton bikes and the serenity yoga room and we speak woof, meow, blub-bulb and tweet.",
      Data: [
        {
          Name: "Pet Friendly",
          ModalImage: "",
          Image: "assets/images/DeanImg/dog park.png",
          Content:
            "We speak woof, meow, blub-blub and tweet. View our pet policy."
        },
        {
          Name: "Swimming Pool",
          ModalImage: "",
          Image: "assets/images/DeanImg/poolone.png",
          Content:
            "Choose from our iconic glass-sided pool or Junior Olympic lap pool."
        },
        {
          Name: "Fitness Center",
          ModalImage: "",
          Image: "assets/images/DeanImg/fitnessone.png",
          Content:
            "Featuring Peloton® bikes, multi-functional training rack, and Serenity yoga room."
        },
        {
          Name: "Clubhouse",
          ModalImage: "",
          Image: "assets/images/DeanImg/clubone.png",
          Content:
            "Featuring shuffleboard, pinball machines, pool table, game tables, dart board, and kegerator."
        }
      ],
      BottomParagraph:
        "*Communal Amenities under construction – Will Open Fall 2019"
    },
    PHOTOGALLERY: {
      Heading: "TAKE A SCROLL THROUGH OUR GALLERY",

      Paragraph:
        "Enjoy a well-deserved vacation welcome home to The Dean Apartments.  Stop by the clubhouse, featuring shuffleboard, pinball machines, pool tables and dart boards.  Choose from our iconic glass-sided pool or junior Olympic lap pool.  Work up a sweat in our state of the art fitness center featuring Peloton bikes  and the serenity yoga room and we speak woof, meow, blub-bulb and tweet."
    },
   
  },
  "/mylo": {
    Community: "Mylo Santa Clara",
    Name: "MYLO",
    BanerImage: "assets/images/mylo-banner.jpg",
    Video3d: {url: ""},
    Video: { url: "" },
    HeadLine:
      "The most exclusive luxury apartments in the heart of the Silicon Valley",
    BanerName:
      "Exclusive, Fully-Furnished, Luxury Apartments Opening in the Silicon Valley!",
    DEFAULT: {
      Heading: "",
      Paragraph:
        "<p>An absolutely brand-new community, you can be one of the first to rent at Mylo. Experience the latest luxury and finest living in Santa Clara at Mylo. Rent exclusively with SuiteAmerica, and you can be one of the first at Mylo to enjoy the stylishly appointed luxuries.</p> <p>The apartments are absolutely gorgeous with beautiful finishes, including quartz countertops, stainless steel appliances, and hardwood style flooring. Your apartment is just one beautiful place to relax and reconnect.</p><p>There are 10 floor plans for you to choose from. Everything from 700 sq. foot 1 bedroom to 1600 sq. foot 2 bedrooms. You can be the first to rent at this exclusive property.</p><p>Let’s get you into Mylo.</p> ",
      Data: [
        {
          Name: "Life in Sync",
          Image:
            "https://s3-us-west-1.amazonaws.com/suite-ch/19883/19883_c.jpg",
          Paragraph:
            "Nestled amid shopping, dining and endless entertainment options, the Mylo in Santa Clara is your ideal destination for “Life in Sync”."
        },
        {
          Name: "A Vibrant Space",
          Image:
            "https://s3-us-west-1.amazonaws.com/suite-ch/19883/19883_c_1.jpg",
          Paragraph:
            "Step outside your door, a variety of social spaces complement your new home.  Breathe in the fresh air in your choice of six uniquely themed courtyards.  Soak up the sun on our rooftop deck and 20,000 square feet featuring a clubroom, fitness center and a gorgeous pool and spa to relax in."
        },
        {
          Name: "Stylish Appointed Luxury",
          Image:
            "https://s3-us-west-1.amazonaws.com/suite-ch/19883/19883_i.jpg",
          Paragraph:
            "Style mixed with a splash of luxury.  Featuring 1-and 2 bedroom floor plans including 15 live/work residences.  Each home finished in contemporary style with quartz countertops, stainless steel appliances and hardwood-style vinyl flooring."
        },
        {
          Name: "Quick Access",
          Image:
            "https://s3-us-west-1.amazonaws.com/suite-ch/19883/19883_i_3.jpg",
          Paragraph:
            "Grab a coffee or a bit to eat at your choice of many nearby restaurants or café’s.Best of all, our apartments offer quick access to highways 280 and 101, as well as the Lawrence and San Thomas expressways ensures that the Bay Area’s iconic attractions are always within reach."
        }
      ]
    },
    FLOORPLANS: {
      Heading: "TAKE A VIRTUAL TOUR OF YOUR FUTURE HOME",
      Paragraph:
        "With 10 unique floor plans to choose from, ranging from studios to lush 3 bedrooms suites, including the two-story 1600 sq ft “Bodie”,  welcome to your Life in Sync.",
      floorsList: ["1BED", "2BED"],
      Data: [
        {
          floors: [
            {
              name: "1 BED / 1 BATH",
              squareFeet: "721 SQ FT",
              plan: " PLAN CASCADE",
              image: "assets/images/floorImages/mylo/Cascade721.png"
            },
            {
              name: "1 BED / 1 BATH",
              squareFeet: "818 SQ FT",
              plan: " PLAN INYO",
              image: "assets/images/floorImages/mylo/Inyo818.png"
            },
            {
              name: "1 BED / 1 BATH",
              squareFeet: "852 SQ FT",
              plan: " PLAN TRINITY",
              image: "assets/images/floorImages/mylo/Trinity852.png"
            },
            {
              name: "1 BED / 1 BATH",
              squareFeet: "852 SQ FT",
              plan: " PLAN KLAMATH",
              image: "assets/images/floorImages/mylo/Klamath852.png"
            }
          ],
          name: "1 BED",
          id: "1BED"
        },
        {
          floors: [
            {
              name: "2 BED / 2 BATH",
              squareFeet: "1135 SQ FT",
              plan: "PLAN SANTA CRUZ",
              image: "assets/images/floorImages/mylo/SantaCruz1135.png"
            },
            {
              name: "2 BED / 2 BATH",
              squareFeet: "1136-1149 SQ FT",
              plan: "PLAN SIERRA",
              image: "assets/images/floorImages/mylo/Sierra1136-1149.png"
            },
            {
              name: "2 BED / 2 BATH",
              squareFeet: "1240 SQ FT",
              plan: "PLAN CRYSTAL",
              image: "assets/images/floorImages/mylo/Crystal1240.png"
            },
            {
              name: "2 BED / 2 BATH",
              squareFeet: "1263 SQ FT",
              plan: "PLAN MENDOCINO",
              image: "assets/images/floorImages/mylo/Mendocino1263.png"
            },
            {
              name: "2 BED / 2 BATH",
              squareFeet: "1332-1355 SQ FT",
              plan: "PLAN PALISADES",
              image: "assets/images/floorImages/mylo/Palisades1332-1355.png"
            },
            {
              name: "2 BED / 2.5 BATH",
              squareFeet: "1657 SQ FT",
              plan: "PLAN BODIE ",
              image: "assets/images/floorImages/mylo/Bodie1657.png"
            }
          ],
          name: "2 BED",
          id: "2BED"
        }
      ]
    },
    NEIGHBORHOOD: {
      Heading: "IT’S A BEAUTIFUL DAY TO EXPLORE",
      Paragraph:
        "Welcome to the Mylo with engaging attractions that surround you in every direction.  Catch a game at Levi’s Stadium,  tour the storied Winchester Mystery House or explore the historic Municipal Rose Garden. Head to nearby Santana Row and discover a host of upscale entertainment options or explore all that downtown Sunnyvale has to offer.",
      Data: {}
    },
    AMENITIES: {
      Heading: "THE IMPORTANT LITTLE THINGS",
      Paragraph:
        "Each home finished in contemporary style with quartz countertops, stainless steel appliances, and hardwood-style vinyl flooring, and includes conveniences like a fully equipped kitchen and an in-home washer and dryer. Select residences also enjoy a private patio or balcony to extend your living space outdoors.",
      Data: [
        {
          Name: "Pet Friendly",
          ModalImage: "",
          Image: "/assets/newUI-images/dean/pet.png",
          Content: "Yes, we speak woof and meow.View our pet policy."
        },
        {
          Name: "Rooftop Deck",
          ModalImage: "",
          Image: "/assets/newUI-images/dean/swimming.png",
          Content:
            "20,000 sq. ft. of amenity space featuring, clubroom, fitness center, BBQ areas and sparkling pool."
        },
        {
          Name: "Fitness Center",
          ModalImage: "",
          Image: "/assets/newUI-images/dean/fitness.png",
          Content:
            "Sweat away the stress with our fitness center and relaxing yoga studio."
        },
        {
          Name: "The Courtyard",
          ModalImage: "",
          Image: "/assets/newUI-images/dean/club1.png",
          Content:
            "Choose from six uniquely themed courtyards, dog park and one-acre greenway."
        }
      ],
      BottomParagraph:
        "*Communal Amenities under construction – Will Open Fall 2019"
    },
    PHOTOGALLERY: {
      Heading: "TAKE A SCROLL THROUGH OUR GALLERY",
      Paragraph:
        "Enjoy a well-deserved break from the normal,  welcome home to The Mylo Apartments.  Soak up the sin on our rooftop deck with 20,000 square feet of amenity space with multiple BBQ areas,  fireplaces  and our pet-friendly community also boasts spaces for pet washing, movie watching, coworking and more,  welcome to your Life in Sync."
    }
  },
  "/amberglenylo": {
    Community: "AMBERGLEN WEST",
    Name: "AMBERGLEN",
    BanerImage: "assets/images/amberglenylo-banner.jpg",
    Video3d: {url: "https://my.matterport.com/show/?m=mdGpNUG6iE7"},
    Video: { url: "" },
    HeadLine:
      "The epitome of elegance and grace with the enduring comforts of refined living.",
    BanerName: "A stunning European-style apartment village in Hillsboro",
    DEFAULT: {
      Heading: "",
      Paragraph:
        "Modern living meets old world charm at Amberglen. Find your serenity at this impeccable Pacific Northwest community in the middle of Portland’s thriving metropolis. With luxurious amenities, beautiful natural surroundings, and elegant finishes, your home awaits at Amberglen. Host friends at the rustic, chic clubhouse with a full kitchen. Break a sweat at any time of day in the 24 hour fitness center. And don’t forget to sit back and relax by the sparkling swimming pool. Experience refined living at Amberglen.",
      Data: [
        {
          Name: "Convenient",
          Image:
            "assets/images/floorImages/OR-Amberglen/AMBERGLENWEST-18769-community-kitchen-1.jpg",
          Paragraph:
            "Easy access to freeways, Downtown Portland, local shops, and delicious restaurants makes Amberglen the ideal location for any lifestyle. Bike or drive to your favorite place and enjoy the beautiful Pacific Northwest scenery along the way."
        },
        {
          Name: "Old World Charm",
          Image:
            "assets/images/floorImages/OR-Amberglen/AMBERGLENWEST-18769-clubhouse-1.jpeg",
          Paragraph:
            "Brick accents and European inspired architecture transport you to another world. A stroll through Amberglen offers an escape to a quaint town where you’ll immediately feel welcome. Charming buildings line beautifully landscaped streets. Escape by simply stepping outside and exploring your neighborhood."
        },
        {
          Name: "Stylish Appointed Luxury",
          Image:
            "assets/images/floorImages/OR-Amberglen/AMBERGLENWEST-18769-clubhouse-3.jpeg",
          Paragraph:
            "Your new home features include quartz kitchen countertops, stainless steel appliances, wood floors, and walk in closets. Amberglen doesn’t skim over details. Enhanced, classic amenities in your suite offer a touch of luxury living along with a warm, comforting ambiance. This is your space, and the details do not disappoint."
        },
        {
          Name: "Relax Outdoors",
          Image:
            "assets/images/floorImages/OR-Amberglen/AMBERGLENWEST-18769-bedroom-1.jpeg",
          Paragraph:
            "Go for a swim. Take a stroll. Enjoy every season in the beautiful Pacific Northwest. Amberglen’s outdoor areas and proximity to hiking trails, rock climbing, and more, ensure you see the Portland area at its finest. Each season brings new activities and more opportunities to enjoy the moment in Portland’s natural beauty."
        }
      ]
    },
    FLOORPLANS: {
      Heading: "TAKE A VIRTUAL TOUR OF YOUR FUTURE HOME",
      Paragraph:
        "From one to three bedrooms and with floorplans featuring nearly 1,300 square feet, you’ll find all the space you need at Amberglen. Glance through a variety of maximally designed layouts to see which option give your life the most room.",
      Data: [
        {
          floors: [
            {
              name: "1 BED",
              squareFeet: "818 SQ FT",
              plan: " PLAN A1",
              image: "assets/images/floorImages/OR-Amberglen/A1.png"
            },
            {
              name: "1 BED",
              squareFeet: "832 SQ FT",
              plan: " PLAN A1 Bay",
              image: "assets/images/floorImages/OR-Amberglen/A1Bay.png"
            },
            {
              name: "1 BED",
              squareFeet: "809 SQ FT",
              plan: " PLAN A2",
              image: "assets/images/floorImages/OR-Amberglen/A2.png"
            },
            {
              name: "1 BED",
              squareFeet: "376 SQ FT",
              plan: " PLAN A2 ALT",
              image: "assets/images/floorImages/OR-Amberglen/A2Alt.png"
            },
            {
              name: "1 BED",
              squareFeet: "881 SQ FT",
              plan: " PLAN B3.1 Type A",
              image: "assets/images/floorImages/OR-Amberglen/B3.1TypeA.png"
            }
          ],
          name: "1 BED",
          id: "1BED"
        },
        {
          floors: [
            {
              name: "2 BED",
              squareFeet: "1090 SQ FT",
              plan: " PLAN B1",
              image: "assets/images/floorImages/OR-Amberglen/B1.png"
            },
            {
              name: "2 BED",
              squareFeet: "1090 SQ FT",
              plan: " PLAN B1 Type A",
              image: "assets/images/floorImages/OR-Amberglen/B1TypeA.png"
            },
            {
              name: "2 BED",
              squareFeet: "1065 SQ FT",
              plan: " PLAN B2",
              image: "assets/images/floorImages/OR-Amberglen/B2.png"
            },
            {
              name: "2 BED",
              squareFeet: "1093 SQ FT",
              plan: " PLAN B3",
              image: "assets/images/floorImages/OR-Amberglen/B3.png"
            },
            {
              name: "2 BED",
              squareFeet: "1112 SQ FT",
              plan: " PLAN B3 Bay",
              image: "assets/images/floorImages/OR-Amberglen/B3Bay.png"
            },
            {
              name: "2 BED",
              squareFeet: "1072 SQ FT",
              plan: " PLAN B3 ALT",
              image: "assets/images/floorImages/OR-Amberglen/B3Alt.png"
            },
            {
              name: "2 BED",
              squareFeet: "881 SQ FT",
              plan: " PLAN B3.1",
              image: "assets/images/floorImages/OR-Amberglen/B3.1.png"
            },
            {
              name: "2 BED",
              squareFeet: "1255 SQ FT",
              plan: " PLAN B5",
              image: "assets/images/floorImages/OR-Amberglen/B5.png"
            },
            {
              name: "2 BED",
              squareFeet: "1064 SQ FT",
              plan: " PLAN B5.1",
              image: "assets/images/floorImages/OR-Amberglen/B5.1.png"
            },
            {
              name: "2 BED",
              squareFeet: "1237 SQ FT",
              plan: " PLAN UX2",
              image: "assets/images/floorImages/OR-Amberglen/UX2.png"
            },
            {
              name: "2 BED",
              squareFeet: "1131 SQ FT",
              plan: " PLAN UX4",
              image: "assets/images/floorImages/OR-Amberglen/UX4.png"
            },
            {
              name: "2 BED",
              squareFeet: "1111 SQ FT",
              plan: " PLAN UXB1.1",
              image: "assets/images/floorImages/OR-Amberglen/UXB1.1.png"
            },
            {
              name: "2 BED",
              squareFeet: "1009 SQ FT",
              plan: " PLAN UXB1.2",
              image: "assets/images/floorImages/OR-Amberglen/UXB1.2.png"
            }
          ],
          name: "2 BED",
          id: "2BED"
        },
        {
          floors: [
            {
              name: "3 BED ",
              squareFeet: "1359 SQ FT",
              plan: "PLAN C1",
              image: "assets/images/floorImages/OR-Amberglen/C1.png"
            },
            {
              name: "3 BED",
              squareFeet: "1390 SQ FT",
              plan: "PLAN C1 Bay",
              image: "assets/images/floorImages/OR-Amberglen/C1Bay.png"
            },
            {
              name: "3 BED",
              squareFeet: "1334 SQ FT",
              plan: "PLAN UX1",
              image: "assets/images/floorImages/OR-Amberglen/UX1.png"
            },
            {
              name: "3 BED",
              squareFeet: "1331 SQ FT",
              plan: "PLAN UX3",
              image: "assets/images/floorImages/OR-Amberglen/UX3.png"
            }
          ],
          name: "3 BED ",
          id: "3BED"
        }
      ]
    },
    NEIGHBORHOOD: {
      Heading: "IT’S A BEAUTIFUL DAY TO EXPLORE",
      Paragraph:
        "Conveniently located in Hillsboro, Amberglen’s proximity to Downtown Portland and Beaverton means you get to enjoy the city’s best restaurants, breweries, fitness studios, and coffee shops. With an impressive BikeScore, you can easily bike to and from the farmer’s markets on Saturday morning after grabbing a coffee.",
      Data: {}
    },
    AMENITIES: {
      Heading: "THE IMPORTANT LITTLE THINGS",
      Paragraph:
        "Each home finished in contemporary style with quartz countertops, stainless steel appliances, and hardwood-style vinyl flooring, and includes conveniences like a fully equipped kitchen and an in-home washer and dryer. Select residences also enjoy a private patio or balcony to extend your living space outdoors.",
      Data: [
        {
          Name: "Pet Friendly",
          ModalImage: "",
          Image: "/assets/newUI-images/dean/pet.png",
          Content:
            "We speak woof, meow, blub-blub, and tweet. Your best friend is welcome here."
        },
        {
          Name: "Lounge",
          ModalImage: "",
          Image: "/assets/newUI-images/dean/swimming.png",
          Content: "Recharge in the relaxing lounge after a long walk."
        },
        {
          Name: "Fitness Center",
          ModalImage: "",
          Image: "/assets/newUI-images/dean/fitness.png",
          Content:
            "Lift, run, squat, stretch, and bike in the fully-equipped 24-hour fitness center."
        },
        {
          Name: "Secure Access",
          ModalImage: "",
          Image: "/assets/newUI-images/dean/club1.png",
          Content: "Exclusive key fob access for security and convenience."
        }
      ],
      BottomParagraph:
        "*Communal Amenities under construction – Will Open Fall 2019"
    },
    PHOTOGALLERY: {
      Heading: "TAKE A SCROLL THROUGH OUR GALLERY",
      Paragraph:
        "Take a run through the 24-hour fitness center, enjoy the rustic chic lounge with full kitchen, and relax by the glittering outdoor pool. With elegant finishes for a refined lifestyle, you’ll find comfort in all the naturally lit amenities and suites, designed to maximize fresh air and creature comforts."
    }
  },
  "/west-parc": {
    Community: "WEST PARC AT BETHANY",
    Name: "WEST PARC",
    BanerImage: "assets/images/west-parc-banner.jpg",
    Video3d: {url: "https://my.matterport.com/show/?m=mdGpNUG6iE7"},
    Video: { url: "" },
    HeadLine: "Luxury Portland Living in a Truly Ideal Location",
    BanerName: "The Next Level of Living",
    DEFAULT: {
      Heading: "",
      Paragraph:
        "Nestled in the West Hills of Portland, West Parc is your new sanctuary. Every aspect of luxurious living is right at your doorstep. Proximity to Beaverton and Downtown Portland means you’lll have plenty of things to do and places to see, while the relaxing and sophisticated West Parc will always be a welcoming comfort at the end of every day.",
      Data: [
        {
          Name: "Exceptional Living",
          Image:
            "assets/images/floorImages/westparc/WESTPARCATBETHANY-17697-clubhouse-1.jpg",
          Paragraph:
            "Experience premium living with superior features and first-rate services along with the picturesque Pacific Northwest natural beauty. West Parc is elite living immersed in Portland’s finest area."
        },
        {
          Name: "Find Your Activity",
          Image:
            "assets/images/floorImages/westparc/WESTPARCATBETHANY-17697-clubhouse-2.jpg",
          Paragraph:
            "Whether you want to swim, bike, or walk, you’ll find it all at West Parc. With an impressive BikeScore and WalkScore, you’ll easily get around, even if you have no destination in mind. Or, challenge yourself at West Parc’s state of the art fitness center. The Bethany Athletic Club, located right next door, offers plenty of fitness classes if you’re looking for community workout."
        },
        {
          Name: "Remarkable Rooftop",
          Image:
            "assets/images/floorImages/westparc/WESTPARCATBETHANY-17697-courtyard-1.jpeg",
          Paragraph:
            "Enjoy the panoramic views on West Parc’s rooftop terrace. Sip a glass of wine, play a fun game with friends, and take a deep breath at the end of your day while enjoying picturesque views of the Portland skyline."
        },
        {
          Name: "Premier Suites",
          Image:
            "assets/images/floorImages/westparc/WESTPARCATBETHANY-17697-swimming-pool-2.jpg",
          Paragraph:
            "All West Parc’s luxury apartments offer light drenched living spaces, spacious common areas, and ample closet space. Explore the new definition of quality and sophistication in Portland living, including luxurious amenities and additional storage."
        }
      ]
    },
    FLOORPLANS: {
      Heading: "TAKE A VIRTUAL TOUR OF YOUR FUTURE HOME",
      Paragraph:
        "At West Parc, luxurious apartment layouts ensure you have the space you need and deserve for your sanctuary. The 1, 2, and 3 bedroom floorplans provide ample lighting and are expertly designed for comfort and sophistication.",
      Data: [
        {
          floors: [
            {
              name: "STUDIO",
              squareFeet: "479 SQ FT",
              plan: " PLAN A1",
              image: "assets/images/floorImages/westparc/A1.png"
            },
            {
              name: "STUDIO",
              squareFeet: "495 SQ FT",
              plan: " PLAN A2",
              image: "assets/images/floorImages/westparc/A2.png"
            },
            {
              name: "STUDIO",
              squareFeet: "479 SQ FT",
              plan: " PLAN A-AHC",
              image: "assets/images/floorImages/westparc/A-AHC.png"
            },
            {
              name: "STUDIO",
              squareFeet: "376 SQ FT",
              plan: " PLAN B1",
              image: "assets/images/floorImages/westparc/B1.png"
            },
            {
              name: "STUDIO",
              squareFeet: "456 SQ FT",
              plan: " PLAN B2",
              image: "assets/images/floorImages/westparc/B2.png"
            }
          ],
         name: "STUDIO",
         id: "STUDIO"
        },
        {
          floors: [
            {
              name: "1 BED",
              squareFeet: "666 SQ FT",
              plan: " PLAN C1",
              image: "assets/images/floorImages/westparc/C1.png"
            },
            {
              name: "1 BED",
              squareFeet: "640 SQ FT",
              plan: " PLAN C2",
              image: "assets/images/floorImages/westparc/C2.png"
            },
            {
              name: "1 BED",
              squareFeet: "640 SQ FT",
              plan: " PLAN C3",
              image: "assets/images/floorImages/westparc/C3.png"
            },
            {
              name: "1 BED",
              squareFeet: "641 SQ FT",
              plan: " PLAN C4",
              image: "assets/images/floorImages/westparc/C4.png"
            },

            {
              name: "1 BED",
              squareFeet: "730 SQ FT",
              plan: " PLAN D1",
              image: "assets/images/floorImages/westparc/D1.png"
            },
            {
              name: "1 BED",
              squareFeet: "700 SQ FT",
              plan: " PLAN D2",
              image: "assets/images/floorImages/westparc/D2.png"
            },
            {
              name: "1 BED",
              squareFeet: "737 SQ FT",
              plan: " PLAN D3",
              image: "assets/images/floorImages/westparc/D3.png"
            },
            {
              name: "1 BED",
              squareFeet: "740 SQ FT",
              plan: " PLAN D4",
              image: "assets/images/floorImages/westparc/D4.png"
            },
            {
              name: "1 BED",
              squareFeet: "730 SQ FT",
              plan: " PLAN D-AHC",
              image: "assets/images/floorImages/westparc/D-AHC.png"
            }
          ],
          name: "1 BED",
          id: "1BED"
        },

        {
          floors: [
            {
              name: "2 BED",
              squareFeet: "1020 SQ FT",
              plan: " PLAN E1",
              image: "assets/images/floorImages/westparc/E1.png"
            },
            {
              name: "2 BED",
              squareFeet: "1027 SQ FT",
              plan: " PLAN E2",
              image: "assets/images/floorImages/westparc/E2.png"
            },
            {
              name: "2 BED",
              squareFeet: "874 SQ FT",
              plan: " PLAN F1",
              image: "assets/images/floorImages/westparc/F1.png"
            },
            {
              name: "2 BED",
              squareFeet: "881 SQ FT",
              plan: " PLAN F2",
              image: "assets/images/floorImages/westparc/F2.png"
            },
            {
              name: "2 BED",
              squareFeet: "850 SQ FT",
              plan: " PLAN F3",
              image: "assets/images/floorImages/westparc/F3.png"
            },
            {
              name: "2 BED",
              squareFeet: "962 SQ FT",
              plan: " PLAN J",
              image: "assets/images/floorImages/westparc/J.png"
            },
            {
              name: "2 BED",
              squareFeet: "962 SQ FT",
              plan: " PLAN J-AHC",
              image: "assets/images/floorImages/westparc/J-AHC.png"
            }
          ],
          name: "2 BED",
          id: "2BED"
        },
        {
          floors: [
            {
              name: "3 BED",
              squareFeet: "1162 SQ FT",
              plan: " PLAN G",
              image: "assets/images/floorImages/westparc/G.png"
            },
            {
              name: "3 BED",
              squareFeet: "1164 SQ FT",
              plan: " PLAN H",
              image: "assets/images/floorImages/westparc/H.png"
            },
            {
              name: "3 BED",
              squareFeet: "1305 SQ FT",
              plan: " PLAN L1",
              image: "assets/images/floorImages/westparc/L1.png"
            },
            {
              name: "3 BED",
              squareFeet: "1282 SQ FT",
              plan: " PLAN L2",
              image: "assets/images/floorImages/westparc/L2.png"
            },
            {
              name: "3 BED",
              squareFeet: "1282 SQ FT",
              plan: " PLAN L3",
              image: "assets/images/floorImages/westparc/L3.png"
            }
          ],
          name: "3 BED",
          id: "3BED"
        }
      ]
    },
    NEIGHBORHOOD: {
      Heading: "IT’S A BEAUTIFUL DAY TO EXPLORE",
      Paragraph:
        "West Parc’s proximity to Downtown Portland and Beaverton along with it’s impressive WalkScore and BikeScore mean that a wide variety of shops, dining, and entertainment are easily accessible. Eat, shop, and play right in your backyard.",
      Data: {}
    },
    AMENITIES: {
      Heading: "THE IMPORTANT LITTLE THINGS",
      Paragraph:
        "Each home finished in contemporary style with quartz countertops, stainless steel appliances, and hardwood-style vinyl flooring, and includes conveniences like a fully equipped kitchen and an in-home washer and dryer. Select residences also enjoy a private patio or balcony to extend your living space outdoors.",
      Data: [
        {
          Name: "Pet Friendly",
          ModalImage: "",
          Image: "/assets/newUI-images/dean/pet.png",
          Content:
            "Cats and dogs are welcome! We love your furry friends just as much as you do."
        },
        {
          Name: "Swimming Pool",
          ModalImage: "",
          Image: "/assets/newUI-images/dean/swimming.png",
          Content:
            "Soak up the sun and sit back by the ultra-relaxing pool and spa."
        },
        {
          Name: "Fitness Center",
          ModalImage: "",
          Image: "/assets/newUI-images/dean/fitness.png",
          Content:
            "Lift, run, and bike in the fully-equipped 24 hour fitness center."
        },
        {
          Name: "Secure Access",
          ModalImage: "",
          Image: "/assets/newUI-images/dean/club1.png",
          Content:
            "Exclusive controlled community access for security and convenience."
        }
      ],
      BottomParagraph:
        "*Communal Amenities under construction – Will Open Fall 2019"
    },
    PHOTOGALLERY: {
      Heading: "TAKE A SCROLL THROUGH OUR GALLERY",
      Paragraph:
        "Glance through West Parc’s refined living spaces. Elegant, clean details along with natural lighting and the Pacific Northwest’s iconic beauty will welcome you home every day. At West Parc, your exceptional living experience begins every morning. Workout in the fitness center, bike to your favorite coffee shop, and enjoy a short commute to top employers in the area."
    }
  },
  "/nv-portland": {
    Community: "NV PORTLAND",
    Name: "NV PORTLAND",
    BanerImage: "/assets/images/nv-portland-banner.jpg",
    Video3d: {url: "https://my.matterport.com/show/?m=NbSZ2EPg5e7"},
    Video: { url: "https://check.retailview360.com/v/0ogzwADbqE9" },
    HeadLine:
      "NV is the light filled, creatively crafted, modern home you’ve been waiting for.",
    BanerName: "A 26 story urban oasis in Portland’s Pearl District",
    DEFAULT: {
      Heading: "",
      Paragraph:
        "NV’s abundant amenities will inspire fun nights and relaxing mornings. With amble opportunities and spaces to enjoy the outdoors, your new home at NV awaits. Beautiful natural lighting fills artfully designed apartment homes, featuring expansive views. So relax, explore, and enjoy a refined lifestyle at NV Portland.",
      Data: [
        {
          Name: "Concierge Services",
          Image:
            "assets/images/floorImages/nvportland/NVPORTLAND-18165-clubhouse-3.jpg",
          Paragraph:
            "Wondering where the best restaurants are? Need to make a reservation? NV’s concierge knows the best places to dine, shop, and stay. Learn about the hottest new spots and rest assured that your dry cleaning pickup and drop-off are handled. Housekeeping service management, and organic food delivery service are only a phone call away."
        },
        {
          Name: "Relaxing Wine Bar",
          Image:
            "assets/images/floorImages/nvportland/NVPORTLAND-18165-clubhouse-1.jpg",
          Paragraph:
            "Meet friends, relax after work, and linger over a glass of the Pacific Northwest’s nicest wine in NV’s intimate lobby wine bar."
        },
        {
          Name: "Outdoor Entertainment Area",
          Image:
            "assets/images/floorImages/nvportland/NVPORTLAND-18165-bedroom-3.jpg",
          Paragraph:
            "The 7,000 square foot terrace is your outdoor sanctuary, featuring dual fire pits, barbecue grills, ample seating and dining space, and an outdoor theater perfect for a movie night or getting together for the ultimate Timbers game day experience."
        },
        {
          Name: "More Than Pet Friendly",
          Image:
            "assets/images/floorImages/nvportland/NVPORTLAND-18165-kitchen-1.jpeg",
          Paragraph:
            "Not only is your favorite furry friend welcome at NV, you can also make sure they get the exercise they need and deserve. The gated dog run area is perfect for exercise and play. Plus, vetted pet sitters are just one concierge call away."
        }
      ]
    },
    FLOORPLANS: {
      Heading: "TAKE A VIRTUAL TOUR OF YOUR FUTURE HOME",
      Paragraph:
        "Open, light-filled rooms with panoramic views and elegant finishes await. Modern designs blended with accents from the area’s rich industrial history create timeless, tranquil living space. Choose a one or two-bedroom layout, townhomes, or live/work options to have the space you’ve always dreamed of.",
      Data: [
        {
          floors: [
            {
              name: "1 BED",
              squareFeet: "650 SQ FT",
              plan: " PLAN 403",
              image: "assets/images/floorImages/nvportland/403.png"
            },
            {
              name: "1 BED",
              squareFeet: "650 SQ FT",
              plan: " PLAN 603",
              image: "assets/images/floorImages/nvportland/603.png"
            },
            {
              name: "1 BED",
              squareFeet: "672 SQ FT",
              plan: " PLAN 708",
              image: "assets/images/floorImages/nvportland/708.png"
            },
            {
              name: "1 BED",
              squareFeet: "652 SQ FT",
              plan: " PLAN 1206",
              image: "assets/images/floorImages/nvportland/1206.png"
            },
            {
              name: "1 BED",
              squareFeet: "650 SQ FT",
              plan: " PLAN 1509",
              image: "assets/images/floorImages/nvportland/1509.png"
            },
            {
              name: "1 BED",
              squareFeet: "650 SQ FT",
              plan: " PLAN 1603",
              image: "assets/images/floorImages/nvportland/1603.png"
            },
            {
              name: "1 BED",
              squareFeet: "710 SQ FT",
              plan: " PLAN 1705",
              image: "assets/images/floorImages/nvportland/1705.png"
            },
            {
              name: "1 BED",
              squareFeet: "710 SQ FT",
              plan: " PLAN 1911",
              image: "assets/images/floorImages/nvportland/1911.png"
            },
            {
              name: "1 BED",
              squareFeet: "710 SQ FT",
              plan: " PLAN 2011",
              image: "assets/images/floorImages/nvportland/2011.png"
            },
            {
              name: "1 BED",
              squareFeet: "650 SQ FT",
              plan: " PLAN 2203",
              image: "assets/images/floorImages/nvportland/2203.png"
            },
            {
              name: "1 BED",
              squareFeet: "672 SQ FT",
              plan: " PLAN 2408",
              image: "assets/images/floorImages/nvportland/2408.png"
            },
            {
              name: "1 BED",
              squareFeet: "565 SQ FT",
              plan: " PLAN 2512",
              image: "assets/images/floorImages/nvportland/2512.png"
            },
            {
              name: "1 BED",
              squareFeet: "935 SQ FT",
              plan: " PLAN 156",
              image: "assets/images/floorImages/nvportland/156.png"
            }
          ],
          name: "1 BED",
          id: "1BED"
        },
        {
          floors: [
            {
              name: "2 BED ",
              squareFeet: "987 SQ FT",
              plan: " PLAN 601",
              image: "assets/images/floorImages/nvportland/601.png"
            },
            {
              name: "2 BED ",
              squareFeet: "879 SQ FT",
              plan: " PLAN 704",
              image: "assets/images/floorImages/nvportland/704.png"
            },
            {
              name: "2 BED ",
              squareFeet: "987 SQ FT",
              plan: " PLAN 1007",
              image: "assets/images/floorImages/nvportland/1007.png"
            },
            {
              name: "2 BED ",
              squareFeet: "879 SQ FT",
              plan: " PLAN 1110",
              image: "assets/images/floorImages/nvportland/1110.png"
            },

            {
              name: "2 BED ",
              squareFeet: "879 SQ FT",
              plan: " PLAN 1804",
              image: "assets/images/floorImages/nvportland/1804.png"
            },
            // {
            //     "name":"2 BED ",
            //     "squareFeet":"879 SQ FT",
            //     "plan":" PLAN 2110",
            //     "image":"assets/images/floorImages/nvportland/2110.png"
            // },
            {
              name: "2 BED ",
              squareFeet: "879 SQ FT",
              plan: " PLAN 2204",
              image: "assets/images/floorImages/nvportland/2204.png"
            },
            {
              name: "2 BED ",
              squareFeet: "987 SQ FT",
              plan: " PLAN 2301",
              image: "assets/images/floorImages/nvportland/2301.png"
            },
            {
              name: "2 BED ",
              squareFeet: "879 SQ FT",
              plan: " PLAN 2404",
              image: "assets/images/floorImages/nvportland/2404.png"
            },
            {
              name: "2 BED ",
              squareFeet: "987 SQ FT",
              plan: " PLAN 2501",
              image: "assets/images/floorImages/nvportland/2501.png"
            }
            // {
            //     "name":"2 BED ",
            //     "squareFeet":"1262 SQ FT",
            //     "plan":" PLAN 2601",
            //     "image":"assets/images/floorImages/nvportland/2601.png"
            // },
          ],
          name: "2 BED",
          id: "2BED"
        }
      ]
    },
    NEIGHBORHOOD: {
      Heading: "IT’S A BEAUTIFUL DAY TO EXPLORE",
      Paragraph:
        "The Pearl District, Portland’s premier neighborhood, is an epicenter for culture, commerce, and recreation. From art galleries, breweries, restaurants, and parks, to easy downtown access, everything you want from city living is right at your doorstep. Discover the Pearl District’s unique character, just enough off the beaten path, yet conveniently close to where you want to be.",
      Data: {}
    },
    AMENITIES: {
      Heading: "THE IMPORTANT LITTLE THINGS",
      Paragraph:
        "Each home finished in contemporary style with quartz countertops, stainless steel appliances, and hardwood-style vinyl flooring, and includes conveniences like a fully equipped kitchen and an in-home washer and dryer. Select residences also enjoy a private patio or balcony to extend your living space outdoors.",
      Data: [
        {
          Name: "Indoor & Outdoor Fitness Areas",
          ModalImage: "assets/images/NVFitnessCenter.png",
          Image: "/assets/newUI-images/dean/fitness.png",
          Content:
            "Stretch, lift, squat, run, repeat in your state-of-the art indoor fitness facility including a private yoga room and tranquil outdoor fitness and yoga area with a panoramic view of Portland."
        },
        {
          Name: "Complimentary Valet Service",
          ModalImage: "assets/images/amenities/NVvalet.jpg",
          Image: "/assets/newUI-images/dean/key.png",
          Content:
            "Start your day off right with Premiere Valet Service. Simply send a text, and your car will be ready and waiting for you in minutes."
        },
        {
          Name: "Play Area",
          ModalImage: "assets/images/amenities/NVplayarea.png",
          Image: "/assets/newUI-images/dean/playArea.png",
          Content:
            "Kids love the custom designed play area where they can make new friends and spend time outside."
        },
        {
          Name: "Private Dining Room",
          ModalImage: "assets/images/amenities/NVPrivateDiningRoom.png",
          Image: "/assets/newUI-images/dean/dining.png",
          Content:
            "Gather your friends in our chef-inspired private dining room with double-sided fireplaces, floor-to-ceiling windows, and access to the outdoor terrace area."
        }
      ],
      BottomParagraph: ""
    },
    PHOTOGALLERY: {
      Heading: "TAKE A SCROLL THROUGH OUR GALLERY",
      Paragraph:
        "See the difference in NV`s refined, modern spaces. View the eco-friendly apartments featuring panoramic views including the Fremont Bridge, West Hills, and Willamette River. Thoughtful design with industrial inspired touches create a relaxing, upscale community you’ll be calling home in no time. "
    }
  },
  "/burlington": {
    Community: "BURLINGTON TOWER",
    Name: "BURLINGTON TOWER",
    BanerImage: "assets/images/burlington-banner.jpg",
    Video3d: {url: "https://my.matterport.com/show/?m=mdGpNUG6iE7"},
    Video: { url: "" },
    HeadLine:
      "Style and Substance Provide a Classic Backdrop to Your Modern Lifestyle",
    BanerName:
      "Sophisticated Details in the Heart of the Vivacious Pearl District",
    DEFAULT: {
      Heading: "",
      Paragraph:
        "Burlington Tower’s exquisite attention to detail means it’s a real diamond in the Pearl District. With ornate finishings and thoughtful touches, Burlington Towers provides an upscale, peaceful home fit for any sophisticated urbanite. Enjoy your lavish surroundings in one of Portland’s bustling neighborhoods.",
      Data: [
        {
          Name: "Attention to Detail",
          Image:
            "assets/images/floorImages/burlington/BURLINGTONTOWER-13616-living-room-2.jpeg",
          Paragraph:
            "From built-in bookshelves to custom granite counter tops, your home at Burlington Tower features a refined elegance you won’t find anywhere else. Stylish designs and details transport you to a sophisticated way of life. The little things make a world of difference at Burlington Tower."
        },
        {
          Name: "A Luxurious Life",
          Image:
            "assets/images/floorImages/burlington/BURLINGTONTOWER-13616-lobby-2.jpg",
          Paragraph:
            "Service reminiscent of a luxury hotel, the Burlington Tower life is grand. Full-time concierge and fully equipped amenities guarantee a life of leisure and elegant bliss. Get restaurant recommendations, learn more about the Portland area, and rest assured you’re receiving the best service in the Pacific Northwest."
        },
        {
          Name: "Envy-Worthy Proximity",
          Image:
            "assets/images/floorImages/burlington/BURLINGTONTOWER-13616-lobby-3.jpg",
          Paragraph:
            "With the Portland Streetcar stopping right outside Burlington Tower, you’ll have instant access and transportation to wherever you want to go. Located in the hub of Portland’s culture, Burlington Tower provides envious proximity to the city’s best establishments. A nearly perfect WalkScore and BikeScore mean you won’t ever worry about parking when everything’s easily within your reach."
        },
        {
          Name: "Truly at Home",
          Image:
            "assets/images/floorImages/burlington/BURLINGTONTOWER-13616-fitness-center-2.jpg",
          Paragraph:
            "With plenty of space for you and your favorite furry friends, Burlington Tower will become more than a place to rest after a long day. Immerse yourself in this refined sanctuary, featuring Inspiring views of the city, a 24 hour fitness center, and world class concierge service."
        }
      ]
    },
    FLOORPLANS: {
      Heading: "TAKE A VIRTUAL TOUR OF YOUR FUTURE HOME",
      Paragraph:
        "Burlington Tower features a wide range of thoughtful, distinctive floorplans to choose from. Each penthouse has a different layout and were designed for style, comfort, and livability. Each well-designed floorplan is move-in ready at Burlington Tower.",
      Data: [
        {
          floors: [
            {
              name: "1 BED",
              plan: "The B Plan",
              squareFeet: "799 SQ FT",
              image: "assets/images/floorImages/burlington/TheBPlan.png"
            },

            {
              name: "1 BED",
              plan: "The C Plan",
              squareFeet: "647 SQ FT",
              image: "assets/images/floorImages/burlington/TheCPlan.png"
            },
            {
              name: "1 BED",
              plan: "The E Plan",
              squareFeet: "814 SQ FT",
              image: "assets/images/floorImages/burlington/TheEPlan.png"
            },

            {
              name: "1 BED",
              plan: "The F Plan ",
              squareFeet: "741 SQ FT",
              image: "assets/images/floorImages/burlington/TheFPlan.png"
            },
            {
              name: "1 BED",
              plan: "The F SIM ",
              squareFeet: "692 SQ FT",
              image: "assets/images/floorImages/burlington/TheFSimPlan.png"
            },
            {
              name: "1 BED",
              plan: "The G Plan ",
              squareFeet: "513 SQ FT",
              image: "assets/images/floorImages/burlington/TheGPlan.png"
            },
            {
              name: "1 BED",
              plan: "The H Plan ",
              squareFeet: "731 SQ FT",
              image: "assets/images/floorImages/burlington/TheHPlan.png"
            },
            {
              name: "1 BED",
              plan: "The J Plan",
              squareFeet: "1097 SQ FT",
              image: "assets/images/floorImages/burlington/TheJPlan.png"
            },
            {
              name: "1 BED",
              plan: "The M Plan ",
              squareFeet: "975 SQ FT",
              image: "assets/images/floorImages/burlington/TheMPlan.png"
            },
            {
              name: "1 BED",
              plan: "The N Plan ",
              squareFeet: "914 SQ FT",
              image: "assets/images/floorImages/burlington/TheNPlan.png"
            },
            {
              name: "1 BED",
              plan: "The P Plan ",
              squareFeet: "872 SQ FT",
              image: "assets/images/floorImages/burlington/ThePPlan.png"
            },
            {
              name: "1 BED",
              plan: "The Q Plan ",
              squareFeet: "975 SQ FT",
              image: "assets/images/floorImages/burlington/TheQPlan.png"
            },
            {
              name: "1 BED",
              plan: "The R Plan",
              squareFeet: "1087 SQ FT",
              image: "assets/images/floorImages/burlington/TheRPlan.png"
            },
            {
              name: "1 BED",
              plan: "The S Plan ",
              squareFeet: "849 SQ FT",
              image: "assets/images/floorImages/burlington/TheSPlan.png"
            },
            {
              name: "1 BED",
              plan: "The T Plan ",
              squareFeet: "802 SQ FT",
              image: "assets/images/floorImages/burlington/TheTPlan.png"
            }
          ],
          name: "1 BED",
          id: "1BED"
        },
        {
          floors: [
            {
              name: "2 BED",
              plan: "The A Plan ",
              squareFeet: "1277 SQ FT",
              image: "assets/images/floorImages/burlington/TheAPlan.png"
            },
            {
              name: "2 BED",
              plan: "The D Plan ",
              squareFeet: "1135 SQ FT",
              image: "assets/images/floorImages/burlington/TheDPlan.png"
            },
            {
              name: "2 BED",
              plan: "The H Plan ",
              squareFeet: "1500 SQ FT",
              image: "assets/images/floorImages/burlington/TheHCombinedPlan.png"
            },
            {
              name: "2 BED",
              plan: "The X Plan ",
              squareFeet: "1234 SQ FT",
              image: "assets/images/floorImages/burlington/TheXPlan.png"
            },

            {
              name: "2 BED",
              plan: "The K Plan ",
              squareFeet: "1932 SQ FT",
              image: "assets/images/floorImages/burlington/TheKPlan.png"
            },
            {
              name: "2 BED",
              plan: "The L Plan ",
              squareFeet: "1573 SQ FT",
              image: "assets/images/floorImages/burlington/TheLPlan.png"
            }
          ],
          name: "2 BED",
          id: "2BED"
        }
      ]
    },
    NEIGHBORHOOD: {
      Heading: "IT’S A BEAUTIFUL DAY TO EXPLORE",
      Paragraph:
        "Burlington Tower is situated in the midst of The Pearl District, a place that’s easily become the hub of culture and creativity in Portland. With new shops, restaurants, and art galleries, and the Portland Streetcar stopping right outside, you’ll quickly find your favorite places to shop and play right next door.",
      Data: {}
    },
    AMENITIES: {
      Heading: "THE IMPORTANT LITTLE THINGS",
      Paragraph:
        "Each home finished in contemporary style with quartz countertops, stainless steel appliances, and hardwood-style vinyl flooring, and includes conveniences like a fully equipped kitchen and an in-home washer and dryer. Select residences also enjoy a private patio or balcony to extend your living space outdoors.",
      Data: [
        {
          Name: "Indoor & Outdoor Fitness Areas",
          Image: "/assets/newUI-images/dean/pet.png",
          ModalImage: "",
          Content:
            "Stretch, lift, squat, run, repeat in your state-of-the art indoor fitness facility including a private yoga room and tranquil outdoor fitness and yoga area with a panoramic view of Portland."
        },
        {
          Name: "Complimentary Valet Service",
          Image: "/assets/newUI-images/dean/swimming.png",
          ModalImage: "",
          Content:
            "Start your day off right with Premiere Valet Service. Simply send a text, and your car will be ready and waiting for you in minutes."
        },
        {
          Name: "Play Area",
          Image: "/assets/newUI-images/dean/fitness.png",
          ModalImage: "",
          Content:
            "Kids love the custom designed play area where they can make new friends and spend time outside."
        },
        {
          Name: "Private Dining Room ",
          Image: "/assets/newUI-images/dean/club1.png",
          ModalImage: "",
          Content:
            "Gather your friends in our chef-inspired private dining room with double-sided fireplaces, floor-to-ceiling windows, and access to the outdoor terrace area."
        }
      ],
      BottomParagraph:
        "*Communal Amenities under construction – Will Open Fall 2019"
    },
    PHOTOGALLERY: {
      Heading: "TAKE A SCROLL THROUGH OUR GALLERY",
      Paragraph:
        "Enjoy the refined details of Burlington Tower’s luxurious spaces. Picture yourself watching the city lights at night from the comfort of your couch. Or, take a step outside and explore the best places Portland has to offer. Burlington Tower is the stylish, sophisticated home you want with all the amenities and fabulous location you’ve been looking for."
    }
  },
  "/carson": {
    Community: "THE CARSON",
    Name: "THE CARSON",
    BanerImage: "assets/images/thacarson-banner.jpg",
    Video3d: {url: "https://my.matterport.com/show/?m=mm1a2sdL5m1"},
    Video: { url: "https://check.retailview360.com/v/X60qZdMazrB" },
    BanerName: "Modern Elegance Surrounded by Mountain Tops",
    HeadLine:
      "A luxuriously modern community in the heart of Portland’s iconic scenery ",
    DEFAULT: {
      Heading: "",
      Paragraph:
        "Warm, modern, and absolutely stunning, The Carson offers panoramic views of Portland’s iconic mountains. The Carson, an industrial-inspired community, is a walker and biker’s paradise. Close to trails and amazing restaurants, you’ll balance the outdoors and indoors in the breath-taking community.",
      Data: [
        {
          Name: "Scenic Location",
          Image: "assets/images/floorImages/carson/THECARSON-amenities 2.JPG",
          Paragraph:
            "Located in one of Portland’s most up and coming neighborhoods, Slabtown, The Carson boasts impressive WalkScore and BikeScores, spectacular views of Mount Hood, and proximity to Forest Park. Immerse yourself in nature by just opening your front door."
        },
        {
          Name: "Modern Elegance",
          Image: "assets/images/floorImages/carson/THECARSON-interior 4.JPG",
          Paragraph:
            "With an industrial style, The Carson offers modern appeal with a warm, welcoming ambiance. Nature meets bold design in this state-of-the-art apartment community."
        },
        {
          Name: "The Ultimate Convenience",
          Image: "assets/images/floorImages/carson/THECARSON-interior 2.JPG",
          Paragraph:
            "Don’t want to drive into town? You don’t have to with close-by shops, restaurants, cafes, and more. The Carson’s nearly perfect WalkScore ensures you have the convenience of a large city with the charm of a small town, easily within your reach."
        },
        {
          Name: "Relax in Luxury",
          Image: "assets/images/floorImages/carson/THECARSON-amenities 4.JPG",
          Paragraph:
            "Whether you’re enjoying the rooftop deck or cooking a fresh dinner you just picked up at a nearby farmer’s market, The Carson’s emphasis on natural, simple beauty that’s easily accessible supports a mindful lifestyle in the heart of Portland."
        }
      ]
    },
    FLOORPLANS: {
      Heading: "TAKE A VIRTUAL TOUR OF YOUR FUTURE HOME",
      Paragraph:
        "Choose from a variety of floorplans, including studios, one bedroom, two bedrooms, walk-up units, lofts, and penthouses. The Carson’s layouts are perfect for every lifestyle. With large closets, central air conditioning, and stainless-steel appliances, every floorplan is move-in ready.",
      Data: [
        {
          floors: [
            {
              name: "STUDIO",
              plan: "PLAN STC A",
              squareFeet: "398 SQ FT",
              image: "assets/images/floorImages/carson/STCA.png"
            },
            {
              name: "STUDIO",
              plan: "PLAN STC  B",
              squareFeet: "473 SQ FT",
              image: "assets/images/floorImages/carson/STCB.png"
            },
            {
              name: "STUDIO",
              plan: "PLAN STC C",
              squareFeet: "473 SQ FT",
              image: "assets/images/floorImages/carson/STCC.png"
            },
            {
              name: "STUDIO",
              plan: "PLAN STC D ",
              squareFeet: "406 SQ FT",
              image: "assets/images/floorImages/carson/STCD.png"
            },
            {
              name: "STUDIO",
              plan: "PLAN STC E ",
              squareFeet: "400 SQ FT",
              image: "assets/images/floorImages/carson/STCE.png"
            },
            {
              name: "STUDIO",
              plan: "PLAN STC F",
              squareFeet: "477 SQ FT",
              image: "assets/images/floorImages/carson/STCF.png"
            },
            {
              name: "STUDIO",
              plan: "PLAN 294 Plan A ",
              squareFeet: "401 SQ FT",
              image: "assets/images/floorImages/carson/294PLANA.png"
            },
            {
              name: "STUDIO",
              plan: "PLAN 294 Plan B ",
              squareFeet: "415 SQ FT",
              image: "assets/images/floorImages/carson/294PLANB.png"
            },
            {
              name: "STUDIO",
              plan: "PLAN 294 Plan C",
              squareFeet: "425 SQ FT",
              image: "assets/images/floorImages/carson/294PLANC.png"
            },
            {
              name: "STUDIO",
              plan: "PLAN 294 Plan D",
              squareFeet: "449 SQ FT",
              image: "assets/images/floorImages/carson/294PLAND.png"
            },
            {
              name: "STUDIO",
              plan: "PLAN 294 Plan E",
              squareFeet: "477 SQ FT",
              image: "assets/images/floorImages/carson/294PLANE.png"
            },
            {
              name: "STUDIO",
              plan: "PLAN 294 Plan F",
              squareFeet: "496 SQ FT",
              image: "assets/images/floorImages/carson/294PLANF.png"
            },
            {
              name: "STUDIO",
              plan: "PLAN 294 Plan G ",
              squareFeet: "497 SQ FT",
              image: "assets/images/floorImages/carson/294PLANG.png"
            },
            {
              name: "STUDIO",
              plan: "PLAN 294 Plan H",
              squareFeet: "479 SQ FT",
              image: "assets/images/floorImages/carson/294PLANH.png"
            }
          ],
          name: "STUDIO",
          id: "STUDIO"
        },

        {
          floors: [
            {
              name: "1 BED",
              squareFeet: "524 SQ FT",
              plan: "PLAN Plan 1A ",
              image: "assets/images/floorImages/carson/PLAN1A.png"
            },

            {
              name: "1 BED",
              squareFeet: "583 SQ FT",
              plan: "PLAN Plan 1B",
              image: "assets/images/floorImages/carson/PLAN1B.png"
            },

            {
              name: "1 BED",
              squareFeet: "546 SQ FT",
              plan: "PLAN Plan 1C ",
              image: "assets/images/floorImages/carson/PLAN1C.png"
            },

            {
              name: "1 BED",
              squareFeet: "570 SQ FT",
              plan: "PLAN Plan 1D ",
              image: "assets/images/floorImages/carson/PLAN1D.png"
            },

            {
              name: "1 BED",
              squareFeet: "591 SQ FT",
              plan: "PLAN Plan 1E",
              image: "assets/images/floorImages/carson/PLAN1E.png"
            },

            {
              name: "1 BED",
              squareFeet: "600 SQ FT",
              plan: "PLAN Plan 1F ",
              image: "assets/images/floorImages/carson/PLAN1F.png"
            },

            {
              name: "1 BED",
              squareFeet: "622 SQ FT",
              plan: "PLAN Plan 1G",
              image: "assets/images/floorImages/carson/PLAN1G.png"
            },

            {
              name: "1 BED",
              squareFeet: "642 SQ FT",
              plan: "PLAN Plan 1H",
              image: "assets/images/floorImages/carson/PLAN1H.png"
            },

            {
              name: "1 BED",
              squareFeet: "645 SQ FT",
              plan: "PLAN Plan 1I",
              image: "assets/images/floorImages/carson/PLAN1I.png"
            },

            {
              name: "1 BED",
              squareFeet: "699 SQ FT",
              plan: "PLAN Plan 1J",
              image: "assets/images/floorImages/carson/PLAN1J.png"
            },

            {
              name: "1 BED",
              squareFeet: "704 SQ FT",
              plan: "PLAN Plan 1K",
              image: "assets/images/floorImages/carson/PLAN1K.png"
            },

            {
              name: "1 BED",
              squareFeet: "576 SQ FT",
              plan: "PLAN 294 Plan 1A",
              image: "assets/images/floorImages/carson/294PLAN1A.png"
            },

            {
              name: "1 BED",
              squareFeet: "598 SQ FT",
              plan: "PLAN 294 Plan 1B",
              image: "assets/images/floorImages/carson/294PLAN1B.png"
            },

            {
              name: "1 BED",
              squareFeet: "294 SQ FT",
              plan: "PLAN 294 Plan 1C",
              image: "assets/images/floorImages/carson/294PLAN1C.png"
            },

            {
              name: "1 BED",
              squareFeet: "768 SQ FT",
              plan: "PLAN 294 Plan 1D",
              image: "assets/images/floorImages/carson/294PLAN1D.png"
            },

            {
              name: "1 BED",
              squareFeet: "679 SQ FT",
              plan: "PLAN 294 Plan 1E",
              image: "assets/images/floorImages/carson/294PLAN1E.png"
            },

            {
              name: "1 BED",
              squareFeet: "700 SQ FT",
              plan: "PLAN 294 Plan 1F",
              image: "assets/images/floorImages/carson/294PLAN1F.png"
            },

            {
              name: "1 BED",
              squareFeet: "691 SQ FT",
              plan: "PLAN 294 Plan 1G",
              image: "assets/images/floorImages/carson/294PLAN1G.png"
            },

            {
              name: "1 BED",
              squareFeet: "713 SQ FT",
              plan: "PLAN 294 Plan 1H ",
              image: "assets/images/floorImages/carson/294PLAN1H.png"
            },

            {
              name: "1 BED",
              squareFeet: "718 SQ FT",
              plan: "PLAN 294 Plan 1I",
              image: "assets/images/floorImages/carson/294PLAN1I.png"
            },

            {
              name: "1 BED",
              squareFeet: "720 SQ FT",
              plan: "PLAN 294 1J",
              image: "assets/images/floorImages/carson/294PLAN1J.png"
            },

            {
              name: "1 BED",
              squareFeet: "741 SQ FT",
              plan: "PLAN 294 Plan 1K",
              image: "assets/images/floorImages/carson/294PLAN1K.png"
            },

            {
              name: "1 BED",
              squareFeet: "749 SQ FT",
              plan: "PLAN 294 Plan 1L",
              image: "assets/images/floorImages/carson/294PLAN1L.png"
            },

            {
              name: "1 BED",
              squareFeet: "807 SQ FT",
              plan: "PLAN 294 Plan 1M ",
              image: "assets/images/floorImages/carson/294PLAN1M.png"
            },

            {
              name: "1 BED",
              squareFeet: "830 SQ FT",
              plan: "PLAN 294 Plan 1N",
              image: "assets/images/floorImages/carson/294PLAN1N.png"
            },

            {
              name: "1 BED",
              squareFeet: "837 SQ FT",
              plan: "PLAN 294 Plan 1O",
              image: "assets/images/floorImages/carson/294PLAN1O.png"
            },

            {
              name: "1 BED",
              squareFeet: "839 SQ FT",
              plan: "PLAN 294 Plan 1P",
              image: "assets/images/floorImages/carson/294PLAN1P.png"
            },

            {
              name: "1 BED",
              squareFeet: "857 SQ FT",
              plan: "PLAN 294 Plan 1Q",
              image: "assets/images/floorImages/carson/294PLAN1Q.png"
            },

            {
              name: "1 BED",
              squareFeet: "909 SQ FT",
              plan: "PLAN 294 Plan 1R",
              image: "assets/images/floorImages/carson/294PLAN1R.png"
            },

            {
              name: "1 BED",
              squareFeet: "937 SQ FT",
              plan: "PLAN 294 Plan 1S",
              image: "assets/images/floorImages/carson/294PLAN1S.png"
            },

            {
              name: "1 BED",
              squareFeet: "624 SQ FT",
              plan: "PLAN 294 Plan 1T ",
              image: "assets/images/floorImages/carson/294PLAN1T.png"
            },

            {
              name: "1 BED",
              squareFeet: "771 SQ FT",
              plan: "PLAN 294 Plan 1U",
              image: "assets/images/floorImages/carson/294PLAN1U.png"
            },

            {
              name: "1 BED",
              squareFeet: "766 SQ FT",
              plan: "PLAN Plan Loft A ",
              image: "assets/images/floorImages/carson/PLANLOFTA.png"
            },

            {
              name: "1 BED",
              squareFeet: "804 SQ FT",
              plan: "PLAN Plan Loft B ",
              image: "assets/images/floorImages/carson/PLANLOFTB.png"
            },

            {
              name: "1 BED",
              squareFeet: "905 SQ FT",
              plan: "PLAN 294 Plan Loft C ",
              image: "assets/images/floorImages/carson/294PLANLOFTC.png"
            },

            {
              name: "1 BED",
              squareFeet: "949 SQ FT",
              plan: "PLAN 294 Plan Loft B",
              image: "assets/images/floorImages/carson/294PLANLOFTB.png"
            },

            {
              name: "1 BED",
              squareFeet: "1005 SQ FT",
              plan: "PLAN 294 Plan Loft C ",
              image: "assets/images/floorImages/carson/294PLANLOFTC.png"
            }
          ],
          name: "1 BED",
          id: "1BED"
        },
        {
          floors: [
            {
              name: "2 BED",
              plan: "PLAN Plan 2C",
              squareFeet: "762 SQ FT",
              image: "assets/images/floorImages/carson/PLAN2C.png"
            },
            {
              name: "2 BED",
              plan: "PLAN Plan 2D",
              squareFeet: "780 SQ FT",
              image: "assets/images/floorImages/carson/PLAN2D.png"
            },
            {
              name: "2 BED",
              plan: "PLAN Plan 2E",
              squareFeet: "804 SQ FT",
              image: "assets/images/floorImages/carson/PLAN2E.png"
            },
            // {
            // "name":"2 BED",
            // "plan":"PLAN Plan 2F"    ,
            // "squareFeet":"804 SQ FT",
            // "image":"assets/images/floorImages/carson/PLAN2F.png",
            // },
            {
              name: "2 BED",
              plan: "PLAN Plan 2G",
              squareFeet: "880 SQ FT",
              image: "assets/images/floorImages/carson/PLAN2G.png"
            },
            {
              name: "2 BED",
              plan: "PLAN Plan 2H",
              squareFeet: "851 SQ FT",
              image: "assets/images/floorImages/carson/PLAN2H.png"
            },
            {
              name: "2 BED",
              plan: "PLAN Plan 2I",
              squareFeet: "889 SQ FT",
              image: "assets/images/floorImages/carson/PLAN2I.png"
            },
            {
              name: "2 BED",
              plan: "PLAN Plan 2J",
              squareFeet: "897 SQ FT",
              image: "assets/images/floorImages/carson/PLAN2J.png"
            },
            {
              name: "2 BED",
              plan: "PLAN Plan 2K ",
              squareFeet: "857 SQ FT",
              image: "assets/images/floorImages/carson/PLAN2K.png"
            },
            {
              name: "2 BED",
              plan: "PLAN Plan 2L ",
              squareFeet: "901 SQ FT",
              image: "assets/images/floorImages/carson/PLAN2L.png"
            },
            {
              name: "2 BED",
              plan: "PLAN Plan 2M",
              squareFeet: "888 SQ FT",
              image: "assets/images/floorImages/carson/PLAN2M.png"
            },
            {
              name: "2 BED",
              plan: "PLAN Plan 2N",
              squareFeet: "931 SQ FT",
              image: "assets/images/floorImages/carson/PLAN2N.png"
            },
            {
              name: "2 BED",
              plan: "PLAN Plan 2O",
              squareFeet: "1037 SQ FT",
              image: "assets/images/floorImages/carson/PLAN2O.png"
            },
            {
              name: "2 BED",
              plan: "PLAN 294 Plan 2A",
              squareFeet: "835 SQ FT",
              image: "assets/images/floorImages/carson/294PLAN2A.png"
            },
            {
              name: "2 BED",
              plan: "PLAN 294 Plan 2B",
              squareFeet: "870 SQ FT",
              image: "assets/images/floorImages/carson/294PLAN2B.png"
            },
            {
              name: "2 BED",
              plan: "PLAN 294 Plan 2C",
              squareFeet: "990 SQ FT",
              image: "assets/images/floorImages/carson/294PLAN2C.png"
            },
            {
              name: "2 BED",
              plan: "PLAN 294 Plan 2D",
              squareFeet: "904 SQ FT",
              image: "assets/images/floorImages/carson/294PLAN2D.png"
            },
            {
              name: "2 BED",
              plan: "PLAN 294 Plan 2E",
              squareFeet: "933 SQ FT",
              image: "assets/images/floorImages/carson/294PLAN2E.png"
            },
            {
              name: "2 BED",
              plan: "PLAN 294 Plan 2F",
              squareFeet: "979 SQ FT",
              image: "assets/images/floorImages/carson/294PLAN2F.png"
            },
            {
              name: "2 BED",
              plan: "PLAN 294 Plan 2G",
              squareFeet: "1026 SQ FT",
              image: "assets/images/floorImages/carson/294PLAN2G.png"
            },
            {
              name: "2 BED",
              plan: "PLAN 294 Plan 2H",
              squareFeet: "1031 SQ FT",
              image: "assets/images/floorImages/carson/294PLAN2H.png"
            },
            {
              name: "2 BED",
              plan: "PLAN 294 Plan 2I",
              squareFeet: "1037 SQ FT",
              image: "assets/images/floorImages/carson/294PLAN2I.png"
            },
            {
              name: "2 BED",
              plan: "PLAN 294 Plan 2J",
              squareFeet: "1077 SQ FT",
              image: "assets/images/floorImages/carson/294PLAN2J.png"
            },
            {
              name: "2 BED",
              plan: "PLAN 294 Plan 2K",
              squareFeet: "1077 SQ FT",
              image: "assets/images/floorImages/carson/294PLAN2K.png"
            },
            {
              name: "2 BED",
              plan: "PLAN 294 Plan 2L",
              squareFeet: "979 SQ FT",
              image: "assets/images/floorImages/carson/294PLAN2L.png"
            },
            {
              name: "2 BED",
              plan: "PLAN 294 Plan 2M ",
              squareFeet: "1008 SQ FT",
              image: "assets/images/floorImages/carson/294PLAN2M.png"
            },
            {
              name: "2 BED",
              plan: "PLAN 294 Plan 2N",
              squareFeet: "1028 SQ FT",
              image: "assets/images/floorImages/carson/294PLAN2N.png"
            },
            {
              name: "2 BED",
              plan: "PLAN 294 Plan 2O ",
              squareFeet: "1056 SQ FT",
              image: "assets/images/floorImages/carson/294PLAN2O.png"
            },
            {
              name: "2 BED",
              plan: "PLAN 294 Plan 2P",
              squareFeet: "1130 SQ FT",
              image: "assets/images/floorImages/carson/294PLAN2P.png"
            }
          ],
          name: "2 BED",
          id: "2BED"
        }
      ]
    },
    NEIGHBORHOOD: {
      Heading: "IT’S A BEAUTIFUL DAY TO EXPLORE",
      Paragraph:
        "In the newest Portland neighborhood, Slabtown, The Carson is a stroll away from bright, trendy shops, restaurants, and much more. Hike in nearby Forest Park for the afternoon, and meet up with friends for a drink afterwards. At The Carson, you’ll find the perfect balance inside and out.",
      Data: {}
    },
    AMENITIES: {
      Heading: "THE IMPORTANT LITTLE THINGS",
      Paragraph:
        "Each home finished in contemporary style with quartz countertops, stainless steel appliances, and hardwood-style vinyl flooring, and includes conveniences like a fully equipped kitchen and an in-home washer and dryer. Select residences also enjoy a private patio or balcony to extend your living space outdoors.",
      Data: [
        {
          Name: "Pet Friendly",
          ModalImage: "",
          Image: "/assets/newUI-images/dean/pet.png",
          Content:
            "We speak woof, meow, blub-blub, and tweet. Your best friend is welcome here."
        },
        {
          Name: "Rooftop Deck",
          ModalImage: "",
          Image: "/assets/newUI-images/dean/swimming.png",
          Content:
            "Have a cookout or sip on a cocktail while enjoying panoramic views of Mount Hood, Mount Adams, and Mount St. Helens."
        },
        {
          Name: "Fitness Center",
          ModalImage: "",
          Image: "/assets/newUI-images/dean/fitness.png",
          Content:
            "Challenge yourself with the most technological advanced equipment. The Fitness Center includes Peloton bikes and more."
        },
        {
          Name: "Bike Room",
          ModalImage: "",
          Image: "/assets/newUI-images/dean/club1.png",
          Content:
            "This storage and fix-it station allow you to enjoy The Carson’s impeccable BikeScore without having to worry about keeping your favorite mode of transportation safe."
        }
      ],
      BottomParagraph:
        "*Communal Amenities under construction – Will Open Fall 2019"
    },
    PHOTOGALLERY: {
      Heading: "TAKE A SCROLL THROUGH OUR GALLERY",
      Paragraph:
        "Immerse yourself in The Carson’s industrial inspired design with a modern finish. With a nod to well-known Portlander JC Carson, an innovator in the 1800s, The Carson celebrates it natural roots while forging into a bright future."
    }
  },
  "/station-park-green": {
    Community: "Station Park Green",
    Name: "Station Park Green",
    Video3d: {url: "https://my.matterport.com/show/?m=mdGpNUG6iE7"},
    Video: { url: "https://my.matterport.com/show/?m=mdGpNUG6iE7" },
    BanerImage: "https://s3-us-west-1.amazonaws.com/suite-ch/19887/19887_b.jpg",
    HeadLine:
      "The most exclusive luxury apartments in the heart of the Silicon Valley",
    BanerName:
      "Exclusive, Fully-Furnished, Luxury Apartments Opening in August!",
    DEFAULT: {
      Heading: "",
      Paragraph:
        "Enjoy a well-deserved vacation without ever having to step away. At The Dean, you will find everything you need within the building or a short walking distance away. Have drinks with friends in the clubhouse, get work done in the think tank, or take a spin on one of our Peleton™ bikes at the gym. The Dean is compatible with your busy lifestyle.",
      Data: [
        {
          Name: "Vacation at Your Door Step",
          Image:
            "https://s3-us-west-1.amazonaws.com/suite-ch/19836/19836_c_1.jpg",
          Paragraph:
            "With a variety of relaxing social spaces, Napa-inspired outdoor activities, and an easy commute to top teach companies in the Silicon Valley, what’s not to LOVE about your new home at the Dean.  Enjoy a well-deserved vacation without ever having to step away."
        },
        {
          Name: "Unique, Luxurious Elegance",
          Image:
            "https://s3-us-west-1.amazonaws.com/suite-ch/19836/19836_c.jpg",
          Paragraph:
            "You won’t find the traditional living experience here at the Dean. Our resort style apartment homes feature floor-to ceiling windows,  keyless entry,  NEST thermostats, LED lighting, and stainless steel appliances.  Meditate in the serenity yoga room. Challenge yourself on a Peloton™ bike. Relax in one of the three sparkling pools, and then kick back in a hammock or unwind in a trio of private dipping spas. Daily luxuries for your best lifestyle."
        },
        {
          Name: "On-Site Concierge",
          Image:
            "https://s3-us-west-1.amazonaws.com/suite-ch/19836/19836_c_4.jpg",
          Paragraph:
            "Have a question about a package delivery? Need a firmer mattress? A friendly face to talk to?  At the Dean, our concierge services is always ready and available. Convenient, caring and there for you every step of the way. "
        },
        {
          Name: "Eat, Shop, Move & Play",
          Image:
            "https://s3-us-west-1.amazonaws.com/suite-ch/19836/19836_c_2.jpg",
          Paragraph:
            "Looking for a little balance? Visit the Nekter Juice bar for specialty drinks and cleanses. Stroll through the open-air, garden lined Stanford Shopping Center. Build strength and feel empowered during a barre3 class, or burn off some energy with your pup while exploring Del Medio Park. Find your bliss at the Dean."
        }
      ]
    },
    FLOORPLANS: {
      Heading: "TAKE A VIRTUAL TOUR OF YOUR FUTURE HOME",
      Paragraph:
        "With over 25 unique floor plans to choose from, ranging from studios to lush 3 bedrooms suites, you will be transformed into a world of luxury and comfort. Lavish amenities and resort style grounds, you won’t find the traditional living experience here at the Dean.",
      Data: [
        {
          floors: [
            {
              name: "STUDIO",
              squareFeet: "519 SQ FT",
              plan: "PLAN S1-9",
              image: "assets/images/floorImages/Station-Park-Green/Acacia.png"
            }
          ],
          name: "STUDIO",
          id: "STUDIO"
        },
        {
          floors: [
            {
              name: "1 BED",
              squareFeet: "774 SQ FT",
              plan: " PLAN A1-8",
              image: "assets/images/floorImages/Station-Park-Green/Acacia.png"
            },
            {
              name: "1 BED",
              squareFeet: "774 SQ FT",
              plan: " PLAN A1-16",
              image: "assets/images/floorImages/Station-Park-Green/Alder.png"
            },
            {
              name: "1 BED",
              squareFeet: "774 SQ FT",
              plan: " PLAN A1-18",
              image: "assets/images/floorImages/Station-Park-Green/Balsam.png"
            },
            {
              name: "1 BED",
              squareFeet: "689 SQ FT",
              plan: " PLAN A2-2",
              image: "assets/images/floorImages/Station-Park-Green/Beech.png"
            },
            {
              name: "1 BED",
              squareFeet: "814 SQ FT",
              plan: " PLAN A3-3",
              image: "assets/images/floorImages/Station-Park-Green/Birch.png"
            },
            {
              name: "1 BED",
              squareFeet: "781 SQ FT",
              plan: " PLAN A3-4",
              image: "assets/images/floorImages/Station-Park-Green/Cypress.png"
            },
            {
              name: "1 BED",
              squareFeet: "876 SQ FT",
              plan: " PLAN A3-11",
              image: "assets/images/floorImages/Station-Park-Green/Elm.png"
            },
            {
              name: "1 BED",
              squareFeet: "667 SQ FT",
              plan: " PLAN A4-1",
              image: "assets/images/floorImages/Station-Park-Green/Fir.png"
            },
            {
              name: "1 BED",
              squareFeet: "660 SQ FT",
              plan: " PLAN A4-41",
              image: "assets/images/floorImages/Station-Park-Green/Gingko.png"
            },
            {
              name: "1 BED",
              squareFeet: "806 SQ FT",
              plan: " PLAN A5",
              image: "assets/images/floorImages/Station-Park-Green/Juniper.png"
            },
            {
              name: "1 BED",
              squareFeet: "779 SQ FT",
              plan: " PLAN A6-1",
              image: "assets/images/floorImages/Station-Park-Green/Laurel.png"
            },
            {
              name: "1 BED",
              squareFeet: "677 SQ FT",
              plan: " PLAN A7-4 ",
              image: "assets/images/floorImages/Station-Park-Green/Oak.png"
            },
            {
              name: "1 BED",
              squareFeet: "594 SQ FT",
              plan: " PLAN A9",
              image: "assets/images/floorImages/Station-Park-Green/Pine.png"
            },
            {
              name: "1 BED",
              squareFeet: "968 SQ FT",
              plan: " PLAN A10",
              image: "assets/images/floorImages/Station-Park-Green/Poplar.png"
            },
            {
              name: "1 BED",
              squareFeet: "829 SQ FT",
              plan: " PLAN A11",
              image: "assets/images/floorImages/Station-Park-Green/Redwood.png"
            }
          ],
          name: "1 BED",
          id: "1BED"
        },
        {
          floors: [
            {
              name: "2 BED",
              squareFeet: "1171 SQ FT",
              plan: "PLAN B-16",
              image: "assets/images/floorImages/Station-Park-Green/Sequoia.png"
            },
            {
              name: "2 BED",
              squareFeet: "1060 SQ FT",
              plan: "PLAN B1-4",
              image: "assets/images/floorImages/Station-Park-Green/Spruce.png"
            },
            {
              name: "2 BED",
              squareFeet: "1171 SQ FT",
              plan: "PLAN B1-16",
              image: "assets/images/floorImages/Station-Park-Green/Sycamore.png"
            },
            {
              name: "2 BED",
              squareFeet: "1189 SQ FT",
              plan: "PLAN B2",
              image: "assets/images/floorImages/Station-Park-Green/Walnut.png"
            }
          ],
          name: "2 BED",
          id: "2BED"
        },
        {
          floors: [
            {
              name: "3 BED",
              squareFeet: "1196 SQ FT",
              plan: "PLAN C1-6",
              image: "assets/images/floorImages/dean/C1-6(wood).png"
            },
            {
              name: "3 BED",
              squareFeet: "1311 SQ FT",
              plan: "PLAN  C2",
              image: "assets/images/floorImages/dean/C2(wood).png"
            }
          ],
          name: "3 BED",
          id: "3BED"
        }
      ]
    },
    NEIGHBORHOOD: {
      Heading: "IT’S A BEAUTIFUL DAY TO EXPLORE",
      Paragraph:
        "Welcome to The Dean Apartments in the heart of Mountain View, just steps away from the San Antonio Caltrain Station and a quick commute from Google headquarters. Stop by Trader Joe’s known for their selection of domestic and imported cheeses, organic fruits and veggies, and locally sourced meats. The Artisan Wine Depot of Mountain View is the logical next stop for their global selection of specialty wines.",
      Data: {}
    },
    AMENITIES: {
      Heading: "THE IMPORTANT LITTLE THINGS",
      Paragraph:
        "Our apartment homes feature floor-to-ceiling windows, oversized closets with customizable Elfa® shelving, LATCH™ Keyless entry and NEST™ thermostats. High ceilings and private terraces are the perfect complements to your evening cocktail hours. Details like energy efficient LED lighting, quartz countertops, and stainless steel appliances make all the difference.",
      Data: [
        {
          Name: "Pet Friendly",
          ModalImage: "",
          Image: "/assets/newUI-images/dean/pet.png",
          Content:
            "We speak woof, meow, blub-blub and tweet. View our pet policy."
        },
        {
          Name: "Swimming Pool",
          ModalImage: "",
          Image: "/assets/newUI-images/dean/swimming.png",
          Content:
            "Choose from our iconic glass-sided pool or Junior Olympic lap pool."
        },
        {
          Name: "Fitness Center",
          ModalImage: "",
          Image: "/assets/newUI-images/dean/fitness.png",
          Content:
            "Featuring Peloton® bikes, multi-functional training rack, and Serenity yoga room."
        },
        {
          Name: "Clubhouse",
          ModalImage: "",
          Image: "/assets/newUI-images/dean/club1.png",
          Content:
            "Featuring shuffleboard, pinball machines, pool table, game tables, dart board, and kegerator."
        }
      ],
      BottomParagraph:
        "*Communal Amenities under construction – Will Open Fall 2019"
    },
    PHOTOGALLERY: {
      Heading: "TAKE A SCROLL THROUGH OUR GALLERY",

      Paragraph:
        "Enjoy a well-deserved vacation welcome home to The Dean Apartments.  Stop by the clubhouse, featuring shuffleboard, pinball machines, pool tables and dart boards.  Choose from our iconic glass-sided pool or junior Olympic lap pool.  Work up a sweat in our state of the art fitness center featuring Peloton bikes  and the serenity yoga room and we speak woof, meow, blub-bulb and tweet."
    }
  },
  "/monticello": {
    Community: "MONTICELLO APARTMENT HOMES",
    Name: "Monticello",
    Video3d: {url: "https://my.matterport.com/show/?m=mdGpNUG6iE7"},
    Video: { url: "https://my.matterport.com/show/?m=mdGpNUG6iE7" },
    BanerImage:
      "https://s3-us-west-1.amazonaws.com/suite-ch/18827/18827_b.jpeg",
    HeadLine:
      "The most exclusive luxury apartments in the heart of the Silicon Valley",
    BanerName:
      "Exclusive, Fully-Furnished, Luxury Apartments Opening in August!",
    DEFAULT: {
      Heading: "",
      Paragraph:
        "Enjoy a well-deserved vacation without ever having to step away. At The Dean, you will find everything you need within the building or a short walking distance away. Have drinks with friends in the clubhouse, get work done in the think tank, or take a spin on one of our Peleton™ bikes at the gym. The Dean is compatible with your busy lifestyle.",
      Data: [
        {
          Name: "Vacation at Your Door Step",
          Image:
            "https://s3-us-west-1.amazonaws.com/suite-ch/19836/19836_c_1.jpg",
          Paragraph:
            "With a variety of relaxing social spaces, Napa-inspired outdoor activities, and an easy commute to top teach companies in the Silicon Valley, what’s not to LOVE about your new home at the Dean.  Enjoy a well-deserved vacation without ever having to step away."
        },
        {
          Name: "Unique, Luxurious Elegance",
          Image:
            "https://s3-us-west-1.amazonaws.com/suite-ch/19836/19836_c.jpg",
          Paragraph:
            "You won’t find the traditional living experience here at the Dean. Our resort style apartment homes feature floor-to ceiling windows,  keyless entry,  NEST thermostats, LED lighting, and stainless steel appliances.  Meditate in the serenity yoga room. Challenge yourself on a Peloton™ bike. Relax in one of the three sparkling pools, and then kick back in a hammock or unwind in a trio of private dipping spas. Daily luxuries for your best lifestyle."
        },
        {
          Name: "On-Site Concierge",
          Image:
            "https://s3-us-west-1.amazonaws.com/suite-ch/19836/19836_c_4.jpg",
          Paragraph:
            "Have a question about a package delivery? Need a firmer mattress? A friendly face to talk to?  At the Dean, our concierge services is always ready and available. Convenient, caring and there for you every step of the way. "
        },
        {
          Name: "Eat, Shop, Move & Play",
          Image:
            "https://s3-us-west-1.amazonaws.com/suite-ch/19836/19836_c_2.jpg",
          Paragraph:
            "Looking for a little balance? Visit the Nekter Juice bar for specialty drinks and cleanses. Stroll through the open-air, garden lined Stanford Shopping Center. Build strength and feel empowered during a barre3 class, or burn off some energy with your pup while exploring Del Medio Park. Find your bliss at the Dean."
        }
      ]
    },
    FLOORPLANS: {
      Heading: "TAKE A VIRTUAL TOUR OF YOUR FUTURE HOME",
      Paragraph:
        "With over 25 unique floor plans to choose from, ranging from studios to lush 3 bedrooms suites, you will be transformed into a world of luxury and comfort. Lavish amenities and resort style grounds, you won’t find the traditional living experience here at the Dean.",
      Data: [
        {
          floors: [
            {
              name: "STUDIO",
              squareFeet: "519 SQ FT",
              plan: "PLAN S1-9",
              image: "assets/images/floorImages/Monticello/.png"
            }
          ],
          name: "STUDIO",
          id: "STUDIO"
        },
        {
          floors: [
            {
              name: "1 BED",
              squareFeet: "774 SQ FT",
              plan: " PLAN A1-8",
              image: "assets/images/floorImages/Monticello/Plan1.png"
            },
            {
              name: "1 BED",
              squareFeet: "774 SQ FT",
              plan: " PLAN A1-16",
              image: "assets/images/floorImages/Monticello/Plan2.png"
            },
            {
              name: "1 BED",
              squareFeet: "774 SQ FT",
              plan: " PLAN A1-18",
              image: "assets/images/floorImages/Monticello/Plan3.png"
            },
            {
              name: "1 BED",
              squareFeet: "689 SQ FT",
              plan: " PLAN A2-2",
              image: "assets/images/floorImages/Monticello/Plan4.png"
            },
            {
              name: "1 BED",
              squareFeet: "814 SQ FT",
              plan: " PLAN A3-3",
              image: "assets/images/floorImages/Monticello/Plan5.png"
            },
            {
              name: "1 BED",
              squareFeet: "781 SQ FT",
              plan: " PLAN A3-4",
              image: "assets/images/floorImages/Monticello/Plan6.png"
            },
            {
              name: "1 BED",
              squareFeet: "876 SQ FT",
              plan: " PLAN A3-11",
              image: "assets/images/floorImages/Monticello/Plan3.png"
            },
            {
              name: "1 BED",
              squareFeet: "667 SQ FT",
              plan: " PLAN A4-1",
              image: "assets/images/floorImages/Monticello/Plan7.png"
            },
            {
              name: "1 BED",
              squareFeet: "660 SQ FT",
              plan: " PLAN A4-41",
              image: "assets/images/floorImages/Monticello/Plan8.png"
            },
            {
              name: "1 BED",
              squareFeet: "806 SQ FT",
              plan: " PLAN A5",
              image: "assets/images/floorImages/Monticello/Plan9.png"
            },
            {
              name: "1 BED",
              squareFeet: "779 SQ FT",
              plan: " PLAN A6-1",
              image: "assets/images/floorImages/Monticello/Plan10.png"
            },
            {
              name: "1 BED",
              squareFeet: "677 SQ FT",
              plan: " PLAN A7-4 ",
              image: "assets/images/floorImages/Monticello/Plan11.png"
            },
            {
              name: "1 BED",
              squareFeet: "594 SQ FT",
              plan: " PLAN A9",
              image: "assets/images/floorImages/Monticello/Plan12.png"
            },
            {
              name: "1 BED",
              squareFeet: "968 SQ FT",
              plan: " PLAN A10",
              image: "assets/images/floorImages/Monticello/Plan13.png"
            },
            {
              name: "1 BED",
              squareFeet: "829 SQ FT",
              plan: " PLAN A11",
              image: "assets/images/floorImages/Monticello/Plan14.png"
            }
          ],
          name: "1 BED",
          id: "1BED"
        },
        {
          floors: [
            {
              name: "2 BED",
              squareFeet: "1171 SQ FT",
              plan: "PLAN B-16",
              image: "assets/images/floorImages/Monticello/Plan15.png"
            },
            {
              name: "2 BED",
              squareFeet: "1060 SQ FT",
              plan: "PLAN B1-4",
              image: "assets/images/floorImages/Monticello/Plan16.png"
            },
            {
              name: "2 BED",
              squareFeet: "1171 SQ FT",
              plan: "PLAN B1-16",
              image: "assets/images/floorImages/Monticello/Plan17.png"
            },
            {
              name: "2 BED",
              squareFeet: "1189 SQ FT",
              plan: "PLAN B2",
              image: "assets/images/floorImages/Monticello/Plan18.png"
            },
            {
              name: "2 BED",
              squareFeet: "1008 SQ FT",
              plan: "PLAN B3",
              image: "assets/images/floorImages/Monticello/Plan19.png"
            },
            {
              name: "2 BED",
              squareFeet: "962 SQ FT",
              plan: "PLAN B3-1",
              image: "assets/images/floorImages/Monticello/Plan20.png"
            },
            {
              name: "2 BED",
              squareFeet: "1067 SQ FT",
              plan: "PLAN B4",
              image: "assets/images/floorImages/Monticello/Plan21.png"
            },
            {
              name: "2 BED",
              squareFeet: "951 SQ FT",
              plan: "PLAN B4-2",
              image: "assets/images/floorImages/Monticello/Plan22.png"
            },
            {
              name: "2 BED",
              squareFeet: "999 SQ FT",
              plan: "PLAN B5",
              image: "assets/images/floorImages/Monticello/Plan23.png"
            },
            {
              name: "2 BED",
              squareFeet: "1005 SQ FT",
              plan: "PLAN B6",
              image: "assets/images/floorImages/Monticello/Plan24.png"
            }
          ],
          name: "2 BED",
          id: "2BED"
        },
        {
          floors: [
            {
              name: "3 BED",
              squareFeet: "1196 SQ FT",
              plan: "PLAN C1-6",
              image: "assets/images/floorImages/Monticello/Plan25.png"
            },
            {
              name: "3 BED",
              squareFeet: "1311 SQ FT",
              plan: "PLAN  C2",
              image: "assets/images/floorImages/Monticello/Plan26.png"
            },
            {
              name: "3 BED",
              squareFeet: "1311 SQ FT",
              plan: "PLAN  C2",
              image: "assets/images/floorImages/Monticello/Plan27.png"
            },
            {
              name: "3 BED",
              squareFeet: "1311 SQ FT",
              plan: "PLAN  C2",
              image: "assets/images/floorImages/Monticello/Plan28.png"
            },
            {
              name: "3 BED",
              squareFeet: "1311 SQ FT",
              plan: "PLAN  C2",
              image: "assets/images/floorImages/Monticello/Plan29.png"
            },
            {
              name: "3 BED",
              squareFeet: "1311 SQ FT",
              plan: "PLAN  C2",
              image: "assets/images/floorImages/Monticello/Plan30.png"
            }
          ],
          name: "3 BED",
          id: "3BED"
        }
      ]
    },
    NEIGHBORHOOD: {
      Heading: "IT’S A BEAUTIFUL DAY TO EXPLORE",
      Paragraph:
        "Welcome to The Dean Apartments in the heart of Mountain View, just steps away from the San Antonio Caltrain Station and a quick commute from Google headquarters. Stop by Trader Joe’s known for their selection of domestic and imported cheeses, organic fruits and veggies, and locally sourced meats. The Artisan Wine Depot of Mountain View is the logical next stop for their global selection of specialty wines.",
      Data: {}
    },
    AMENITIES: {
      Heading: "THE IMPORTANT LITTLE THINGS",
      Paragraph:
        "Our apartment homes feature floor-to-ceiling windows, oversized closets with customizable Elfa® shelving, LATCH™ Keyless entry and NEST™ thermostats. High ceilings and private terraces are the perfect complements to your evening cocktail hours. Details like energy efficient LED lighting, quartz countertops, and stainless steel appliances make all the difference.",
      Data: [
        {
          Name: "Pet Friendly",
          ModalImage: "",
          Image: "/assets/newUI-images/dean/pet.png",
          Content:
            "We speak woof, meow, blub-blub and tweet. View our pet policy."
        },
        {
          Name: "Swimming Pool",
          ModalImage: "",
          Image: "/assets/newUI-images/dean/swimming.png",
          Content:
            "Choose from our iconic glass-sided pool or Junior Olympic lap pool."
        },
        {
          Name: "Fitness Center",
          ModalImage: "",
          Image: "/assets/newUI-images/dean/fitness.png",
          Content:
            "Featuring Peloton® bikes, multi-functional training rack, and Serenity yoga room."
        },
        {
          Name: "Clubhouse",
          ModalImage: "",
          Image: "/assets/newUI-images/dean/club1.png",
          Content:
            "Featuring shuffleboard, pinball machines, pool table, game tables, dart board, and kegerator."
        }
      ],
      BottomParagraph:
        "*Communal Amenities under construction – Will Open Fall 2019"
    },
    PHOTOGALLERY: {
      Heading: "TAKE A SCROLL THROUGH OUR GALLERY",

      Paragraph:
        "Enjoy a well-deserved vacation welcome home to The Dean Apartments.  Stop by the clubhouse, featuring shuffleboard, pinball machines, pool tables and dart boards.  Choose from our iconic glass-sided pool or junior Olympic lap pool.  Work up a sweat in our state of the art fitness center featuring Peloton bikes  and the serenity yoga room and we speak woof, meow, blub-bulb and tweet."
    }
    
  },
  "/ca-iron-works": {
    Community: "Station Park Green",
    Name: "CA Iron Works",
    Video3d: {url: "https://my.matterport.com/show/?m=mdGpNUG6iE7"},
    Video: { url: "https://my.matterport.com/show/?m=mdGpNUG6iE7" },
    BanerImage: "https://s3-us-west-1.amazonaws.com/suite-ch/19887/19887_b.jpg",
    HeadLine:
      "The most exclusive luxury apartments in the heart of the Silicon Valley",
    BanerName:
      "Exclusive, Fully-Furnished, Luxury Apartments Opening in August!",
    DEFAULT: {
      Heading: "",
      Paragraph:
        "Enjoy a well-deserved vacation without ever having to step away. At The Dean, you will find everything you need within the building or a short walking distance away. Have drinks with friends in the clubhouse, get work done in the think tank, or take a spin on one of our Peleton™ bikes at the gym. The Dean is compatible with your busy lifestyle.",
      Data: [
        {
          Name: "Vacation at Your Door Step",
          Image:
            "https://s3-us-west-1.amazonaws.com/suite-ch/19836/19836_c_1.jpg",
          Paragraph:
            "With a variety of relaxing social spaces, Napa-inspired outdoor activities, and an easy commute to top teach companies in the Silicon Valley, what’s not to LOVE about your new home at the Dean.  Enjoy a well-deserved vacation without ever having to step away."
        },
        {
          Name: "Unique, Luxurious Elegance",
          Image:
            "https://s3-us-west-1.amazonaws.com/suite-ch/19836/19836_c.jpg",
          Paragraph:
            "You won’t find the traditional living experience here at the Dean. Our resort style apartment homes feature floor-to ceiling windows,  keyless entry,  NEST thermostats, LED lighting, and stainless steel appliances.  Meditate in the serenity yoga room. Challenge yourself on a Peloton™ bike. Relax in one of the three sparkling pools, and then kick back in a hammock or unwind in a trio of private dipping spas. Daily luxuries for your best lifestyle."
        },
        {
          Name: "On-Site Concierge",
          Image:
            "https://s3-us-west-1.amazonaws.com/suite-ch/19836/19836_c_4.jpg",
          Paragraph:
            "Have a question about a package delivery? Need a firmer mattress? A friendly face to talk to?  At the Dean, our concierge services is always ready and available. Convenient, caring and there for you every step of the way. "
        },
        {
          Name: "Eat, Shop, Move & Play",
          Image:
            "https://s3-us-west-1.amazonaws.com/suite-ch/19836/19836_c_2.jpg",
          Paragraph:
            "Looking for a little balance? Visit the Nekter Juice bar for specialty drinks and cleanses. Stroll through the open-air, garden lined Stanford Shopping Center. Build strength and feel empowered during a barre3 class, or burn off some energy with your pup while exploring Del Medio Park. Find your bliss at the Dean."
        }
      ]
    },
    FLOORPLANS: {
      Heading: "TAKE A VIRTUAL TOUR OF YOUR FUTURE HOME",
      Paragraph:
        "With over 25 unique floor plans to choose from, ranging from studios to lush 3 bedrooms suites, you will be transformed into a world of luxury and comfort. Lavish amenities and resort style grounds, you won’t find the traditional living experience here at the Dean.",
      Data: [
        {
          floors: [
            {
              name: "STUDIO",
              squareFeet: "519 SQ FT",
              plan: "PLAN S1-9",
              image: "assets/images/floorImages/CA-Iron-Works/.png"
            }
          ],
          name: "STUDIO",
          id: "STUDIO"
        },
        {
          floors: [
            {
              name: "1 BED",
              squareFeet: "774 SQ FT",
              plan: " PLAN A1-8",
              image: "assets/images/floorImages/CA-Iron-Works/Plan1A.png"
            },
            {
              name: "1 BED",
              squareFeet: "774 SQ FT",
              plan: " PLAN A1-16",
              image: "assets/images/floorImages/CA-Iron-Works/Plan1B.png"
            },
            {
              name: "1 BED",
              squareFeet: "774 SQ FT",
              plan: " PLAN A1-18",
              image: "assets/images/floorImages/CA-Iron-Works/Plan2B.png"
            },
            {
              name: "1 BED",
              squareFeet: "689 SQ FT",
              plan: " PLAN A2-2",
              image: "assets/images/floorImages/CA-Iron-Works/Plan2C.png"
            },
            {
              name: "1 BED",
              squareFeet: "814 SQ FT",
              plan: " PLAN A3-3",
              image: "assets/images/floorImages/CA-Iron-Works/Plan2F.png"
            },
            {
              name: "1 BED",
              squareFeet: "781 SQ FT",
              plan: " PLAN A3-4",
              image: "assets/images/floorImages/CA-Iron-Works/Plan2H.png"
            },
            {
              name: "1 BED",
              squareFeet: "876 SQ FT",
              plan: " PLAN A3-11",
              image: "assets/images/floorImages/CA-Iron-Works/Plan2I.png"
            },
            {
              name: "1 BED",
              squareFeet: "667 SQ FT",
              plan: " PLAN A4-1",
              image: "assets/images/floorImages/CA-Iron-Works/Plan2K.png"
            },
            {
              name: "1 BED",
              squareFeet: "660 SQ FT",
              plan: " PLAN A4-41",
              image: "assets/images/floorImages/CA-Iron-Works/Plan3A.png"
            },
            {
              name: "1 BED",
              squareFeet: "806 SQ FT",
              plan: " PLAN A5",
              image: "assets/images/floorImages/CA-Iron-Works/Plan3E.png"
            },
            {
              name: "1 BED",
              squareFeet: "779 SQ FT",
              plan: " PLAN A6-1",
              image: "assets/images/floorImages/CA-Iron-Works/Plan3F.png"
            }
          ],
          name: "1 BED",
          id: "1BED"
        },
        {
          floors: [
            {
              name: "2 BED",
              squareFeet: "1171 SQ FT",
              plan: "PLAN B-16",
              image: "assets/images/floorImages/CA-Iron-Works/.png"
            },
            {
              name: "2 BED",
              squareFeet: "1060 SQ FT",
              plan: "PLAN B1-4",
              image: "assets/images/floorImages/CA-Iron-Works/.png"
            },
            {
              name: "2 BED",
              squareFeet: "1171 SQ FT",
              plan: "PLAN B1-16",
              image: "assets/images/floorImages/CA-Iron-Works/.png"
            },
            {
              name: "2 BED",
              squareFeet: "1189 SQ FT",
              plan: "PLAN B2",
              image: "assets/images/floorImages/CA-Iron-Works/.png"
            },
            {
              name: "2 BED",
              squareFeet: "1008 SQ FT",
              plan: "PLAN B3",
              image: "assets/images/floorImages/dean/B3(wood).png"
            },
            {
              name: "2 BED",
              squareFeet: "962 SQ FT",
              plan: "PLAN B3-1",
              image: "assets/images/floorImages/CA-Iron-Works/.png"
            },
            {
              name: "2 BED",
              squareFeet: "1067 SQ FT",
              plan: "PLAN B4",
              image: "assets/images/floorImages/CA-Iron-Works/.png"
            },
            {
              name: "2 BED",
              squareFeet: "951 SQ FT",
              plan: "PLAN B4-2",
              image: "assets/images/floorImages/CA-Iron-Works/.png"
            },
            {
              name: "2 BED",
              squareFeet: "999 SQ FT",
              plan: "PLAN B5",
              image: "assets/images/floorImages/CA-Iron-Works/.png"
            },
            {
              name: "2 BED",
              squareFeet: "1005 SQ FT",
              plan: "PLAN B6",
              image: "assets/images/floorImages/CA-Iron-Works/.png"
            }
          ],
          name: "2 BED",
          id: "2BED"
        },
        {
          floors: [
            {
              name: "3 BED",
              squareFeet: "1196 SQ FT",
              plan: "PLAN C1-6",
              image: "assets/images/floorImages/dean/C1-6(wood).png"
            },
            {
              name: "3 BED",
              squareFeet: "1311 SQ FT",
              plan: "PLAN  C2",
              image: "assets/images/floorImages/dean/C2(wood).png"
            }
          ],
          name: "3 BED",
          id: "3BED"
        }
      ]
    },
    NEIGHBORHOOD: {
      Heading: "IT’S A BEAUTIFUL DAY TO EXPLORE",
      Paragraph:
        "Welcome to The Dean Apartments in the heart of Mountain View, just steps away from the San Antonio Caltrain Station and a quick commute from Google headquarters. Stop by Trader Joe’s known for their selection of domestic and imported cheeses, organic fruits and veggies, and locally sourced meats. The Artisan Wine Depot of Mountain View is the logical next stop for their global selection of specialty wines.",
      Data: {}
    },
    AMENITIES: {
      Heading: "THE IMPORTANT LITTLE THINGS",
      Paragraph:
        "Our apartment homes feature floor-to-ceiling windows, oversized closets with customizable Elfa® shelving, LATCH™ Keyless entry and NEST™ thermostats. High ceilings and private terraces are the perfect complements to your evening cocktail hours. Details like energy efficient LED lighting, quartz countertops, and stainless steel appliances make all the difference.",
      Data: [
        {
          Name: "Pet Friendly",
          ModalImage: "",
          Image: "/assets/newUI-images/dean/pet.png",
          Content:
            "We speak woof, meow, blub-blub and tweet. View our pet policy."
        },
        {
          Name: "Swimming Pool",
          ModalImage: "",
          Image: "/assets/newUI-images/dean/swimming.png",
          Content:
            "Choose from our iconic glass-sided pool or Junior Olympic lap pool."
        },
        {
          Name: "Fitness Center",
          ModalImage: "",
          Image: "/assets/newUI-images/dean/fitness.png",
          Content:
            "Featuring Peloton® bikes, multi-functional training rack, and Serenity yoga room."
        },
        {
          Name: "Clubhouse",
          ModalImage: "",
          Image: "/assets/newUI-images/dean/club1.png",
          Content:
            "Featuring shuffleboard, pinball machines, pool table, game tables, dart board, and kegerator."
        }
      ],
      BottomParagraph:
        "*Communal Amenities under construction – Will Open Fall 2019"
    },
    PHOTOGALLERY: {
      Heading: "TAKE A SCROLL THROUGH OUR GALLERY",

      Paragraph:
        "Enjoy a well-deserved vacation welcome home to The Dean Apartments.  Stop by the clubhouse, featuring shuffleboard, pinball machines, pool tables and dart boards.  Choose from our iconic glass-sided pool or junior Olympic lap pool.  Work up a sweat in our state of the art fitness center featuring Peloton bikes  and the serenity yoga room and we speak woof, meow, blub-bulb and tweet."
    }
    
  },
  "/novo": {
    Community: "NOVO MOUNTAIN VIEW",
    Name: "NOVO",
    BanerImage: "assets/images/novo-banner.jpg",
    Video3d: {url: ""},
    Video: { url: "" },
    HeadLine:
      "Now renting the finest, fully-furnished apartments in Mountain View.",
    BanerName: "Raising the Bar for Exclusive Mountain View Living",
    DEFAULT: {
      Heading: "",
      Paragraph:
        "Designed with the hard-working and fun-loving in mind, the property is minutes from major tech campuses and incubators, a short walk to quaint downtown shops, restaurants and nightlife, and close to the public transit and highways that take you directly to San Francisco.",
      Data: [
        {
          Name: "Designer Apartments",
          Image:
            "assets/images/floorImages/novo/NOVO-1.png",
          Paragraph:
            "Inspired spaces with generous layouts for plenty of space to unwind and recharge. Walk-in custom closets, quartz countertops, oversized sinks, and in unit washer and dryers. Beautiful finishes and high-touch details define and elevate your new home at Novo."
        },
        {
          Name: "The Ultimate Location",
          Image:
          "assets/images/floorImages/novo/NOVO-2.png",
          Paragraph:
            "Novo is minutes from major tech campuses and headquarters. It’s a short walk to downtown shops, restaurants and nightlife, and it’s also close to major public transportation stops and highways."
        },
        {
          Name: "Outdoor Pool Terrace",
          Image:
          "assets/images/floorImages/novo/NOVO-3.png",
          Paragraph:
            "Socialize and soak up the sun at the relaxing outdoor pool terrace. Escape the heat and hustle of Silicon Valley simply by stepping into your own backyard. This outdoor oasis is perfect for having a good time and enjoying the sunshine."
        },
        {
          Name: "More Than Pet-Friendly",
          Image:
          "assets/images/floorImages/novo/NOVO-4.png",
          Paragraph:
            "Pets aren’t just welcomed at Novo, they’re part of the family! There’s a dog park, as well as a dog spa to keep your furry friends happy and healthy. The spa features wash bays, blow dryers, and groom stations. Everyone’s at home at Novo."
        }       
      ]
    },
    FLOORPLANS: {
      Heading: "TAKE A VIRTUAL TOUR OF YOUR FUTURE HOME",
      Paragraph:
        "Novo’s studio, one and two-bedroom floor plans offer smart-technology amenities and generous spaces that bring life back into balance. From 540 square foot studios to 1,100 square foot two bedrooms, you’ll find the perfect space to call home.",
      Data: [
        {
          floors: [
            {
              name: "STUDIO",
              squareFeet: "540 SQ FT",
              plan: "PLAN S1",
              image: "assets/images/floorImages/novo/floorplan-s1-01.svg"
            }
          ],
          name: "STUDIO",
          id: "STUDIO"
        },
        {
          floors: [
            {
              name: "1 BED",
              squareFeet: "653 SQ FT",
              plan: "PLAN A1",
              image: "assets/images/floorImages/novo/floorplan-a1-01.svg"
            },
            {
              name: "1 BED",
              squareFeet: "752 SQ FT",
              plan: " PLAN A3",
              image: "assets/images/floorImages/novo/floorplan-a3-01.svg"
            },
            {
              name: "1 BED",
              squareFeet: "754 SQ FT",
              plan: " PLAN A4",
              image: "assets/images/floorImages/novo/floorplan-a4-01.svg"
            },
            {
              name: "1 BED",
              squareFeet: "761 SQ FT",
              plan: " PLAN A5",
              image: "assets/images/floorImages/novo/floorplan-a5-01.svg"
            },
            {
              name: "1 BED",
              squareFeet: "761 SQ FT",
              plan: " PLAN A6",
              image: "assets/images/floorImages/novo/floorplan-a6-01.svg"
            },
            {
              name: "1 BED",
              squareFeet: "785 SQ FT",
              plan: " PLAN A7",
              image: "assets/images/floorImages/novo/floorplan-a7-01.svg"
            },
            {
              name: "1 BED",
              squareFeet: "790 SQ FT",
              plan: " PLAN A8",
              image: "assets/images/floorImages/novo/floorplan-a8-01.svg"
            },
            {
              name: "1 BED",
              squareFeet: "797 SQ FT",
              plan: " PLAN A9",
              image: "assets/images/floorImages/novo/floorplan-a9-01.svg"
            },
            {
              name: "1 BED",
              squareFeet: "838 SQ FT",
              plan: " PLAN A10",
              image: "assets/images/floorImages/novo/floorplan-a10-01.svg"
            },
            {
              name: "1 BED",
              squareFeet: "977 SQ FT",
              plan: " PLAN A12",
              image: "assets/images/floorImages/novo/floorplan-a12-01.svg"
            }
          ],
          name: "1 BED",
          id: "1BED"
        },
        {
          floors: [
            {
              name: "2 BED",
              squareFeet: "937 SQ FT",
              plan: "PLAN B10",
              image: "assets/images/floorImages/novo/floorplan-b5-01.svg"
            },
            {
              name: "2 BED",
              squareFeet: "1063 SQ FT",
              plan: "PLAN B5",
              image: "assets/images/floorImages/novo/floorplan-b6-01.svg"
            },
            {
              name: "2 BED",
              squareFeet: "1100 SQ FT",
              plan: "PLAN B6",
              image: "assets/images/floorImages/novo/floorplan-b7-01.svg"
            },
            {
              name: "2 BED",
              squareFeet: "1106 SQ FT",
              plan: "PLAN B7",
              image: "assets/images/floorImages/novo/floorplan-b8-01.svg"
            },
            {
              name: "2 BED",
              squareFeet: "1140 SQ FT",
              plan: "PLAN B8",
              image: "assets/images/floorImages/novo/floorplan-b9-01.svg"
            },
            {
              name: "2 BED",
              squareFeet: "1141 SQ FT",
              plan: "PLAN B9",
              image: "assets/images/floorImages/novo/floorplan-b10-01.svg"
            }
          ],
          name: "2 BED",
          id: "2BED"
        }
      ]
    },
    NEIGHBORHOOD: {
      Heading: "IT’S A BEAUTIFUL DAY TO EXPLORE",
      Paragraph:
        "",
      Data: {}
    },
    AMENITIES: {
      Heading: "THE IMPORTANT LITTLE THINGS",
      Paragraph:
        "Collective living for the forward thinker: Modern, updated interiors with impeccable finishes and refined details for the ultimate Mountain View luxury. Pet spas, real spas, and efficient, work-from-home spaces available 24-hours a day, 7-days a week.",
      Data: [
        {
          Name: "Pet Friendly",
          ModalImage: "",
          Image: "/assets/newUI-images/dean/pet.png",
          Content: "Pets are more than welcome at Novo. They’ll absolutely love the dog park and dog spa."
        },
        {
          Name: "Swimming Pool",
          ModalImage: "",
          Image: "/assets/newUI-images/dean/swimming.png",
          Content:
            "The gorgeous outdoor pool terrace is perfect for socializing and soaking up the sun."
        },
        {
          Name: "Fitness Center",
          ModalImage: "",
          Image: "/assets/newUI-images/dean/fitness.png",
          Content:
            "Sweat it out in Novo’s state of the art, 24 hour fitness and training facility."
        },
        {
          Name: "Bike Support",
          ModalImage: "",
          Image: "/assets/newUI-images/dean/mountain-bike.png",
          Content:
            "Keep your bike secure, safe, and well-maintained at the onsite bike storage and bike repair shop."
        }
      ],
      BottomParagraph:
        "*Communal Amenities under construction – Will Open Fall 2019"
    },
    PHOTOGALLERY: {
      Heading: "TAKE A SCROLL THROUGH OUR GALLERY",
      Paragraph:
        "Scroll through the gallery to see your future space at Novo. Updated cabinetry with ambiance lighting, stainless-steel appliances, quartz countertops, and more inside your home. Beautiful finishes and details await!"
    }
  },
  "/1250-lakeside": {
    Community: "The Dean",
    Name: "THE DEAN",
    Video: { url: "" },
    Video3d: {url: ""},
    BanerImage: "/assets/newUI-images/lakeImages/the-dean-exterior1a.png",
    HeadLine:
      "Now renting the newest, luxury apartments in Sunnyvale.",
    BanerName:
      "Exclusive Access to the Newest, Most Luxurious Silicon Valley Community",
    DEFAULT: {
      Heading: "",
      Paragraph:
        "<p>This is a beautiful brand new community! The Dean’s 2019 grand opening means you’ll experience one of the nicest and newest communities in Silicon Valley. The in-suite amenities, such as floor-to-ceiling windows, oversized closets, quartz countertops, and stainless steel appliances, are absolutely gorgeous.</p> <p>Three of 4 buildings are complete and securely insulated from the last building still under construction. This first building, where you’ll be staying, is beautifully landscaped and radiates a serene, quiet ambiance, and relaxing atmosphere.</p> <p>Amenities are available but limited due to COVID restrictions by the county. Please reach out to the team for the most up to date reservation system for the amenities.</p>",
     
        Data: [
        {
          Name: "A Modern Escape",
          Image:"/assets/newUI-images/lakeImages/capture2.png",
          Paragraph:
            "As a brand-new property, 1250 features spacious floor plans with lavishly appointed finishes. Stylish, modern, and impressive. This unrivaled community is the perfect escape into luxury. Wood style flooring, modern appliances, and floor to ceiling windows, what’s not to love about your new personal oasis?"
        },
        {
          Name: "Community & Collaboration",
          Image:"/assets/newUI-images/lakeImages/copernico-yfmU1uL_mp8-unsplash.png",
          Paragraph:
            "Ample co-working spaces and lounges to inspire your next project. Working and collaborating never felt so enjoyable. Pick from indoor and outdoor lounges, clubrooms, outdoor courtyard with stunning lakeside views, and more! Amenities and shared spaces abound, all featuring state-of-the-art appliances and finishes. Foster your own community, create, inspire, and get things done at this high-end community."
        },
        {
          Name: "Maximum Tranquility",
          Image:"/assets/newUI-images/lakeImages/jubeo-hernandez-ZmWLGkPe1Sg-unsplash.png",
          Paragraph:
            "Relax, recharge, and feel refreshed at 1250. Sit by the resort-style pool or sweat it out in the brand-new fitness studio or spin room. Even four-legged family members will feel relaxed after their trip to the onsite pet spa and expansive dog park. All of this, surrounded by stunning lakeside views. Take a deep breath and unwind in this tranquil oasis."
        }
      ]
    },
    FLOORPLANS: {
      Heading: "TAKE A VIRTUAL TOUR OF YOUR FUTURE HOME",
      Paragraph:
        "With over 25 unique floor plans to choose from, ranging from studios to lush 3 bedrooms suites, you will be transformed into a world of luxury and comfort. Lavish amenities and resort style grounds, you won’t find the traditional living experience here at the Dean.",
      Data: [
        {
          floors: [
            {
              name: "STUDIO",
              squareFeet: "519 SQ FT",
              plan: "PLAN S1-9",
              image: "assets/images/floorImages/dean/S1-9(wood).png"
            }
          ],
          name: "STUDIO",
          id: "STUDIO"
        },
        {
          floors: [
            {
              name: "1 BED",
              squareFeet: "774 SQ FT",
              plan: " PLAN A1-8",
              image: "assets/images/floorImages/dean/A1-8(metal).png"
            },
            {
              name: "1 BED",
              squareFeet: "774 SQ FT",
              plan: " PLAN A1-16",
              image: "assets/images/floorImages/dean/A1-16(metal).png",
              video3dUrl:"https://my.matterport.com/show/?m=hSwXb5QJWjE"
            },
            {
              name: "1 BED",
              squareFeet: "774 SQ FT",
              plan: " PLAN A1-18",
              image: "assets/images/floorImages/dean/A1-18(wood).png",
              video3dUrl:"https://my.matterport.com/show/?m=aikZn5HW32b"
            },
            {
              name: "1 BED",
              squareFeet: "689 SQ FT",
              plan: " PLAN A2-2",
              image: "assets/images/floorImages/dean/A2-2(wood).png"
            },
            {
              name: "1 BED",
              squareFeet: "814 SQ FT",
              plan: " PLAN A3-3",
              image: "assets/images/floorImages/dean/A3-3(wood).png",
              video3dUrl:"https://my.matterport.com/show/?m=az8v4nYt83N"
            },
            {
              name: "1 BED",
              squareFeet: "781 SQ FT",
              plan: " PLAN A3-4",
              image: "assets/images/floorImages/dean/A3-4(wood).png"
            },
            {
              name: "1 BED",
              squareFeet: "876 SQ FT",
              plan: " PLAN A3-11",
              image: "assets/images/floorImages/dean/A3-11(wood).png"
            },
            {
              name: "1 BED",
              squareFeet: "667 SQ FT",
              plan: " PLAN A4-1",
              image: "assets/images/floorImages/dean/A4-1(wood).png",
              video3dUrl:"https://my.matterport.com/show/?m=az8v4nYt83N"
            },
            {
              name: "1 BED",
              squareFeet: "660 SQ FT",
              plan: " PLAN A4-41",
              image: "assets/images/floorImages/dean/A4-41(wood).png"
            },
            {
              name: "1 BED",
              squareFeet: "806 SQ FT",
              plan: " PLAN A5",
              image: "assets/images/floorImages/dean/A5(wood).png"
            },
            {
              name: "1 BED",
              squareFeet: "779 SQ FT",
              plan: " PLAN A6-1",
              image: "assets/images/floorImages/dean/A6-1(wood).png"
            },
            {
              name: "1 BED",
              squareFeet: "677 SQ FT",
              plan: " PLAN A7-4 ",
              image: "assets/images/floorImages/dean/A7-4(wood).png"
            },
            {
              name: "1 BED",
              squareFeet: "594 SQ FT",
              plan: " PLAN A9",
              image: "assets/images/floorImages/dean/A9(wood).png"
            },
            {
              name: "1 BED",
              squareFeet: "968 SQ FT",
              plan: " PLAN A10",
              image: "assets/images/floorImages/dean/A10(wood).png"
            },
            {
              name: "1 BED",
              squareFeet: "829 SQ FT",
              plan: " PLAN A11",
              image: "assets/images/floorImages/dean/A11(wood).png"
            }
          ],
          name: "1 BED",
          id: "1BED"
        },
        {
          floors: [
            {
              name: "2 BED",
              squareFeet: "1171 SQ FT",
              plan: "PLAN B-16",
              image: "assets/images/floorImages/dean/B-16(wood).png"
            },
            {
              name: "2 BED",
              squareFeet: "1060 SQ FT",
              plan: "PLAN B1-4",
              image: "assets/images/floorImages/dean/B1-4(wood).png"
            },
            {
              name: "2 BED",
              squareFeet: "1171 SQ FT",
              plan: "PLAN B1-16",
              image: "assets/images/floorImages/dean/B1-16(wood).png"
            },
            {
              name: "2 BED",
              squareFeet: "1189 SQ FT",
              plan: "PLAN B2",
              image: "assets/images/floorImages/dean/B2(wood).png",
              video3dUrl:"https://my.matterport.com/show/?m=hYkEqFP8PZo"
            },
            {
              name: "2 BED",
              squareFeet: "1008 SQ FT",
              plan: "PLAN B3",
              image: "assets/images/floorImages/dean/B3(wood).png",
              video3dUrl:"https://my.matterport.com/show/?m=UWrVxCEfBDi"
            },
            {
              name: "2 BED",
              squareFeet: "962 SQ FT",
              plan: "PLAN B3-1",
              image: "assets/images/floorImages/dean/B3-1(metal).png"
            },
            {
              name: "2 BED",
              squareFeet: "1067 SQ FT",
              plan: "PLAN B4",
              image: "assets/images/floorImages/dean/B4(wood).png"
            },
            {
              name: "2 BED",
              squareFeet: "951 SQ FT",
              plan: "PLAN B4-2",
              image: "assets/images/floorImages/dean/B4-2(wood).png"
            },
            {
              name: "2 BED",
              squareFeet: "999 SQ FT",
              plan: "PLAN B5",
              image: "assets/images/floorImages/dean/B5(wood).png"
            },
            {
              name: "2 BED",
              squareFeet: "1005 SQ FT",
              plan: "PLAN B6",
              image: "assets/images/floorImages/dean/B6(wood).png",
              video3dUrl:"https://my.matterport.com/show/?m=YwYDXmrdSqE"
            }
          ],
          name: "2 BED",
          id: "2BED"
        },
        {
          floors: [
            {
              name: "3 BED",
              squareFeet: "1196 SQ FT",
              plan: "PLAN C1-6",
              image: "assets/images/floorImages/dean/C1-6(wood).png",
              video3dUrl:"https://my.matterport.com/show/?m=DwHdvN6Sew2"
            },
            {
              name: "3 BED",
              squareFeet: "1311 SQ FT",
              plan: "PLAN  C2",
              image: "assets/images/floorImages/dean/C2(wood).png"
            }
          ],
          name: "3 BED",
          id: "3BED"
        }
      ]
    },
    NEIGHBORHOOD: {
      Heading: "IT’S A BEAUTIFUL DAY TO EXPLORE",
      Paragraph:
        "Welcome to The Dean Apartments in the heart of Mountain View, just steps away from the San Antonio Caltrain Station and a quick commute from Google headquarters. Stop by Trader Joe’s known for their selection of domestic and imported cheeses, organic fruits and veggies, and locally sourced meats. The Artisan Wine Depot of Mountain View is the logical next stop for their global selection of specialty wines.",
      Data: {}
    },
    AMENITIES: {
      Heading: "THE IMPORTANT LITTLE THINGS",
      Paragraph:
        "Enjoy a well-deserved vacation welcome home to The Dean Apartments. Stop by the clubhouse, featuring shuffleboard, pinball machines, pool tables and dart boards. Choose from our iconic glass-sided pool or junior Olympic lap pool. Work up a sweat in our state of the art fitness center featuring Peloton bikes and the serenity yoga room and we speak woof, meow, blub-bulb and tweet.",
      Data: [
        {
          Name: "Pet Friendly",
          ModalImage: "",
          Image: "/assets/newUI-images/lakeImages/dogpark.png",
          Content:
            "We love your four-legged family member almost as much as you do!"
        },
        {
          Name: "Pool & Spa",
          ModalImage: "",
          Image: "/assets/newUI-images/lakeImages/drew-dau-GA2sc8nIOsk-unsplash.png",
          Content:
            "Kick back and relax by the resort-style pool and spa."
        },
        {
          Name: "Fitness Center",
          ModalImage: "",
          Image: "/assets/newUI-images/lakeImages/alexandra-tran-fS3tGOkp0xY-unsplash.png",
          Content:
            "Brand-new, state-of-the-art fitness center, featuring a spin room."
        },
        {
          Name: "Lakeside Views",
          ModalImage: "",
          Image: "assets/images/DeanImg/clubone.png",
          Content:
            "Outdoor courtyards surrounded by stunning lakeside views.."
        }
      ],
      BottomParagraph:
        "*Communal Amenities under construction – Will Open Fall 2019"
    },
    PHOTOGALLERY: {
      Heading: "TAKE A SCROLL THROUGH OUR GALLERY",

      Paragraph:
        "Enjoy a well-deserved vacation welcome home to The Dean Apartments.  Stop by the clubhouse, featuring shuffleboard, pinball machines, pool tables and dart boards.  Choose from our iconic glass-sided pool or junior Olympic lap pool.  Work up a sweat in our state of the art fitness center featuring Peloton bikes  and the serenity yoga room and we speak woof, meow, blub-bulb and tweet."
    },
   
  },
  "/thenolo": {
    Community: "The Nolo",
    Name: "THE NOLO",
    Video: { url: "" },
    Video3d: {url: ""},
    BanerImage: "assets/images/DeanImg/DeanBanner1.jpg",
    HeadLine:
      "Now renting fully furnished luxury apartments in Silicon Valley.",
    BanerName:
      "Exclusive Access to the Newest, Most Luxurious Silicon Valley Community",
    DEFAULT: {
      Heading: "",
      Paragraph:
        "<p>This is a beautiful brand new community! The Nolo’s 2019 grand opening means you’ll experience one of the nicest and newest communities in Silicon Valley. The in-suite amenities, such as floor-to-ceiling windows, oversized closets, quartz countertops, and stainless steel appliances, are absolutely gorgeous.</p> <p>Three of 4 buildings are complete and securely insulated from the last building still under construction. This first building, where you’ll be staying, is beautifully landscaped and radiates a serene, quiet ambiance, and relaxing atmosphere.</p> <p>Amenities are available but limited due to COVID restrictions by the county. Please reach out to the team for the most up to date reservation system for the amenities.</p>",
     
        Data: [
        {
          Name: "Perfectly Located",
          Image:"assets/images/DeanImg/deanListImg1.png",
          Paragraph:
            "With a nearly perfect Bikescore and outstanding Walkscore, The Nolo’s immediate proximity to the Village at San Antonio Center, outdoor trails, public transportation, and major Silicon Valley headquarters make it the perfect location in Mountain View. The Nolo only elevates this already impressive area. Walk, bike, and explore your new neighborhood."
        },
        {
          Name: "Keyless Entry With Latch",
          Image:"assets/images/DeanImg/deanListImg22.png",
          Paragraph:
            "You won’t find the traditional living experience at The Nolo, starting with the keyless electronic entry system, Latch. Latch enables the use of your mobile phone to open doors and elevators throughout the property, including your suite door. <a class='latchAccess'>Click here</a> to view more on how to use Latch."
        },
        {
          Name: "SuiteAmerica Concierge Services",
          Image:"assets/images/DeanImg/deanListImg3.png",
          Paragraph:
            "Have a question about a package delivery? Need a firmer mattress? SuiteAmerica’s Concierge Services is always ready and available to assist you. Call us at 1-800-367-9501 or use the SuiteAmerica app on your Apple TV to ask us a question or request a service."
        },
        {
          Name: "Eat, Shop, Move & Play",
          Image:"assets/images/DeanImg/deanListImg4.png",
          Paragraph:
            "Restaurants and shopping are steps away from you at The Nolo. Trader Joe’s and Whole Foods are walking distance and offer a variety of groceries to fill your kitchen. Visit the nearby open-air Stanford Shopping Center, feel empowered during a Barre3 class, or burn off some energy with your pup while exploring Del Medio Park. <a class='neighborhood'>Click here</a> to see a map of your new neighborhood!"
        }
      ]
    },
    FLOORPLANS: {
      Heading: "TAKE A VIRTUAL TOUR OF YOUR FUTURE HOME",
      Paragraph:
        "With over 25 unique floor plans to choose from, ranging from studios to lush 3 bedrooms suites, you will be transformed into a world of luxury and comfort. Lavish amenities and resort style grounds, you won’t find the traditional living experience here at the Nolo.",
      Data: [
        {
          floors: [
            {
              name: "STUDIO",
              squareFeet: "519 SQ FT",
              plan: "PLAN S1-9",
              image: "assets/images/floorImages/dean/S1-9(wood).png"
            }
          ],
          name: "STUDIO",
          id: "STUDIO"
        },
        {
          floors: [
            {
              name: "1 BED",
              squareFeet: "774 SQ FT",
              plan: " PLAN A1-8",
              image: "assets/images/floorImages/dean/A1-8(metal).png"
            },
            {
              name: "1 BED",
              squareFeet: "774 SQ FT",
              plan: " PLAN A1-16",
              image: "assets/images/floorImages/dean/A1-16(metal).png",
              video3dUrl:"https://my.matterport.com/show/?m=hSwXb5QJWjE"
            },
            {
              name: "1 BED",
              squareFeet: "774 SQ FT",
              plan: " PLAN A1-18",
              image: "assets/images/floorImages/dean/A1-18(wood).png",
              video3dUrl:"https://my.matterport.com/show/?m=aikZn5HW32b"
            },
            {
              name: "1 BED",
              squareFeet: "689 SQ FT",
              plan: " PLAN A2-2",
              image: "assets/images/floorImages/dean/A2-2(wood).png"
            },
            {
              name: "1 BED",
              squareFeet: "814 SQ FT",
              plan: " PLAN A3-3",
              image: "assets/images/floorImages/dean/A3-3(wood).png",
              video3dUrl:"https://my.matterport.com/show/?m=az8v4nYt83N"
            },
            {
              name: "1 BED",
              squareFeet: "781 SQ FT",
              plan: " PLAN A3-4",
              image: "assets/images/floorImages/dean/A3-4(wood).png"
            },
            {
              name: "1 BED",
              squareFeet: "876 SQ FT",
              plan: " PLAN A3-11",
              image: "assets/images/floorImages/dean/A3-11(wood).png"
            },
            {
              name: "1 BED",
              squareFeet: "667 SQ FT",
              plan: " PLAN A4-1",
              image: "assets/images/floorImages/dean/A4-1(wood).png",
              video3dUrl:"https://my.matterport.com/show/?m=az8v4nYt83N"
            },
            {
              name: "1 BED",
              squareFeet: "660 SQ FT",
              plan: " PLAN A4-41",
              image: "assets/images/floorImages/dean/A4-41(wood).png"
            },
            {
              name: "1 BED",
              squareFeet: "806 SQ FT",
              plan: " PLAN A5",
              image: "assets/images/floorImages/dean/A5(wood).png"
            },
            {
              name: "1 BED",
              squareFeet: "779 SQ FT",
              plan: " PLAN A6-1",
              image: "assets/images/floorImages/dean/A6-1(wood).png"
            },
            {
              name: "1 BED",
              squareFeet: "677 SQ FT",
              plan: " PLAN A7-4 ",
              image: "assets/images/floorImages/dean/A7-4(wood).png"
            },
            {
              name: "1 BED",
              squareFeet: "594 SQ FT",
              plan: " PLAN A9",
              image: "assets/images/floorImages/dean/A9(wood).png"
            },
            {
              name: "1 BED",
              squareFeet: "968 SQ FT",
              plan: " PLAN A10",
              image: "assets/images/floorImages/dean/A10(wood).png"
            },
            {
              name: "1 BED",
              squareFeet: "829 SQ FT",
              plan: " PLAN A11",
              image: "assets/images/floorImages/dean/A11(wood).png"
            }
          ],
          name: "1 BED",
          id: "1BED"
        },
        {
          floors: [
            {
              name: "2 BED",
              squareFeet: "1171 SQ FT",
              plan: "PLAN B-16",
              image: "assets/images/floorImages/dean/B-16(wood).png"
            },
            {
              name: "2 BED",
              squareFeet: "1060 SQ FT",
              plan: "PLAN B1-4",
              image: "assets/images/floorImages/dean/B1-4(wood).png"
            },
            {
              name: "2 BED",
              squareFeet: "1171 SQ FT",
              plan: "PLAN B1-16",
              image: "assets/images/floorImages/dean/B1-16(wood).png"
            },
            {
              name: "2 BED",
              squareFeet: "1189 SQ FT",
              plan: "PLAN B2",
              image: "assets/images/floorImages/dean/B2(wood).png",
              video3dUrl:"https://my.matterport.com/show/?m=hYkEqFP8PZo"
            },
            {
              name: "2 BED",
              squareFeet: "1008 SQ FT",
              plan: "PLAN B3",
              image: "assets/images/floorImages/dean/B3(wood).png",
              video3dUrl:"https://my.matterport.com/show/?m=UWrVxCEfBDi"
            },
            {
              name: "2 BED",
              squareFeet: "962 SQ FT",
              plan: "PLAN B3-1",
              image: "assets/images/floorImages/dean/B3-1(metal).png"
            },
            {
              name: "2 BED",
              squareFeet: "1067 SQ FT",
              plan: "PLAN B4",
              image: "assets/images/floorImages/dean/B4(wood).png"
            },
            {
              name: "2 BED",
              squareFeet: "951 SQ FT",
              plan: "PLAN B4-2",
              image: "assets/images/floorImages/dean/B4-2(wood).png"
            },
            {
              name: "2 BED",
              squareFeet: "999 SQ FT",
              plan: "PLAN B5",
              image: "assets/images/floorImages/dean/B5(wood).png"
            },
            {
              name: "2 BED",
              squareFeet: "1005 SQ FT",
              plan: "PLAN B6",
              image: "assets/images/floorImages/dean/B6(wood).png",
              video3dUrl:"https://my.matterport.com/show/?m=YwYDXmrdSqE"
            }
          ],
          name: "2 BED",
          id: "2BED"
        },
        {
          floors: [
            {
              name: "3 BED",
              squareFeet: "1196 SQ FT",
              plan: "PLAN C1-6",
              image: "assets/images/floorImages/dean/C1-6(wood).png",
              video3dUrl:"https://my.matterport.com/show/?m=DwHdvN6Sew2"
            },
            {
              name: "3 BED",
              squareFeet: "1311 SQ FT",
              plan: "PLAN  C2",
              image: "assets/images/floorImages/dean/C2(wood).png"
            }
          ],
          name: "3 BED",
          id: "3BED"
        }
      ]
    },
    NEIGHBORHOOD: {
      Heading: "IT’S A BEAUTIFUL DAY TO EXPLORE",
      Paragraph:
        "Welcome to The Nolo Apartments in the heart of Mountain View, just steps away from the San Antonio Caltrain Station and a quick commute from Google headquarters. Stop by Trader Joe’s known for their selection of domestic and imported cheeses, organic fruits and veggies, and locally sourced meats. The Artisan Wine Depot of Mountain View is the logical next stop for their global selection of specialty wines.",
      Data: {}
    },
    AMENITIES: {
      Heading: "THE IMPORTANT LITTLE THINGS",
      Paragraph:
        "Enjoy a well-deserved vacation welcome home to The Nolo Apartments. Stop by the clubhouse, featuring shuffleboard, pinball machines, pool tables and dart boards. Choose from our iconic glass-sided pool or junior Olympic lap pool. Work up a sweat in our state of the art fitness center featuring Peloton bikes and the serenity yoga room and we speak woof, meow, blub-bulb and tweet.",
      Data: [
        {
          Name: "Space to Stretch",
          ModalImage: "",
          Image: "https://s3-us-west-1.amazonaws.com/suite-ch/20197/20197_i_2.jpg",
          Content:
            "The Travel Suite is a one-bedroom apartment equipped for comfort and convenience."
        },
        {
          Name: "Rooftop Deck",
          ModalImage: "",
          Image: "https://s3-us-west-1.amazonaws.com/suite-ch/20197/20197_c.jpg",
          Content:
            "Stunning Views of Lumen Field, Mount Rainier, Puget Sound, and Downtown Seattle."
        },
        {
          Name: "Fitness Center",
          ModalImage: "",
          Image: "https://res.cloudinary.com/g5-assets-cld/image/upload/x_0,y_26,h_534,w_800,c_crop/q_auto,f_auto,fl_lossy,g_center,h_665,w_998/g5/g5-c-ibsddh6p-pillar-properties-client/g5-cl-55us9521s-the-nolo/uploads/i_stadiumplace_9.jpg",
          Content:
            "Featuring Peloton® bikes, multi-functional training rack, and Serenity yoga room."
        },
        {
          Name: "Shared Amenities at The Wave",
          ModalImage: "",
          Image: "/assets/images/DeanImg/thewave.jpg",
          Content:
            "The 5th floor walkway leads to The Wave next door, where partners can use the amenities and rooftop lounge."
        }
      ],
      BottomParagraph:
        "*Communal Amenities under construction – Will Open Fall 2019"
    },
    PHOTOGALLERY: {
      Heading: "TAKE A SCROLL THROUGH OUR GALLERY",

      Paragraph:
        "Enjoy a well-deserved vacation welcome home to The Nolo Apartments.  Stop by the clubhouse, featuring shuffleboard, pinball machines, pool tables and dart boards.  Choose from our iconic glass-sided pool or junior Olympic lap pool.  Work up a sweat in our state of the art fitness center featuring Peloton bikes  and the serenity yoga room and we speak woof, meow, blub-bulb and tweet."
    },
   
  },
  "/UmpquaBank": {
    Community: "Mercato Grove",
    Name: "Mercato Grove",
    Video: { url: "" },
    Video3d: { url: "" },
    BanerImage: "assets/images/DeanImg/DeanBanner1.jpg",
    HeadLine:
      "Now renting fully furnished luxury apartments in Silicon Valley.",
    BanerName:
      "Exclusive Access to the Newest, Most Luxurious Silicon Valley Community",
    DEFAULT: {
      Heading: "",
      Paragraph:
        "<p>This is a beautiful brand new community! The Nolo’s 2019 grand opening means you’ll experience one of the nicest and newest communities in Silicon Valley. The in-suite amenities, such as floor-to-ceiling windows, oversized closets, quartz countertops, and stainless steel appliances, are absolutely gorgeous.</p> <p>Three of 4 buildings are complete and securely insulated from the last building still under construction. This first building, where you’ll be staying, is beautifully landscaped and radiates a serene, quiet ambiance, and relaxing atmosphere.</p> <p>Amenities are available but limited due to COVID restrictions by the county. Please reach out to the team for the most up to date reservation system for the amenities.</p>",

      Data: [
        {
          Name: "Perfectly Located",
          Image: "assets/images/DeanImg/deanListImg1.png",
          Paragraph:
            "With a nearly perfect Bikescore and outstanding Walkscore, The Nolo’s immediate proximity to the Village at San Antonio Center, outdoor trails, public transportation, and major Silicon Valley headquarters make it the perfect location in Mountain View. The Nolo only elevates this already impressive area. Walk, bike, and explore your new neighborhood.",
        },
        {
          Name: "Keyless Entry With Latch",
          Image: "assets/images/DeanImg/deanListImg22.png",
          Paragraph:
            "You won’t find the traditional living experience at The Nolo, starting with the keyless electronic entry system, Latch. Latch enables the use of your mobile phone to open doors and elevators throughout the property, including your suite door. <a class='latchAccess'>Click here</a> to view more on how to use Latch.",
        },
        {
          Name: "SuiteAmerica Concierge Services",
          Image: "assets/images/DeanImg/deanListImg3.png",
          Paragraph:
            "Have a question about a package delivery? Need a firmer mattress? SuiteAmerica’s Concierge Services is always ready and available to assist you. Call us at 1-800-367-9501 or use the SuiteAmerica app on your Apple TV to ask us a question or request a service.",
        },
        {
          Name: "Eat, Shop, Move & Play",
          Image: "assets/images/DeanImg/deanListImg4.png",
          Paragraph:
            "Restaurants and shopping are steps away from you at The Nolo. Trader Joe’s and Whole Foods are walking distance and offer a variety of groceries to fill your kitchen. Visit the nearby open-air Stanford Shopping Center, feel empowered during a Barre3 class, or burn off some energy with your pup while exploring Del Medio Park. <a class='neighborhood'>Click here</a> to see a map of your new neighborhood!",
        },
      ],
    },
    FLOORPLANS: {
      Heading: "TAKE A VIRTUAL TOUR OF YOUR FUTURE HOME",
      Paragraph:
        "With over 25 unique floor plans to choose from, ranging from studios to lush 3 bedrooms suites, you will be transformed into a world of luxury and comfort. Lavish amenities and resort style grounds, you won’t find the traditional living experience here at the Nolo.",
      Data: [
        {
          floors: [
            {
              name: "STUDIO",
              squareFeet: "519 SQ FT",
              plan: "PLAN S1-9",
              image: "assets/images/floorImages/dean/S1-9(wood).png",
            },
          ],
          name: "STUDIO",
          id: "STUDIO",
        },
        {
          floors: [
            {
              name: "1 BED",
              squareFeet: "774 SQ FT",
              plan: " PLAN A1-8",
              image: "assets/images/floorImages/dean/A1-8(metal).png",
            },
            {
              name: "1 BED",
              squareFeet: "774 SQ FT",
              plan: " PLAN A1-16",
              image: "assets/images/floorImages/dean/A1-16(metal).png",
              video3dUrl: "https://my.matterport.com/show/?m=hSwXb5QJWjE",
            },
            {
              name: "1 BED",
              squareFeet: "774 SQ FT",
              plan: " PLAN A1-18",
              image: "assets/images/floorImages/dean/A1-18(wood).png",
              video3dUrl: "https://my.matterport.com/show/?m=aikZn5HW32b",
            },
            {
              name: "1 BED",
              squareFeet: "689 SQ FT",
              plan: " PLAN A2-2",
              image: "assets/images/floorImages/dean/A2-2(wood).png",
            },
            {
              name: "1 BED",
              squareFeet: "814 SQ FT",
              plan: " PLAN A3-3",
              image: "assets/images/floorImages/dean/A3-3(wood).png",
              video3dUrl: "https://my.matterport.com/show/?m=az8v4nYt83N",
            },
            {
              name: "1 BED",
              squareFeet: "781 SQ FT",
              plan: " PLAN A3-4",
              image: "assets/images/floorImages/dean/A3-4(wood).png",
            },
            {
              name: "1 BED",
              squareFeet: "876 SQ FT",
              plan: " PLAN A3-11",
              image: "assets/images/floorImages/dean/A3-11(wood).png",
            },
            {
              name: "1 BED",
              squareFeet: "667 SQ FT",
              plan: " PLAN A4-1",
              image: "assets/images/floorImages/dean/A4-1(wood).png",
              video3dUrl: "https://my.matterport.com/show/?m=az8v4nYt83N",
            },
            {
              name: "1 BED",
              squareFeet: "660 SQ FT",
              plan: " PLAN A4-41",
              image: "assets/images/floorImages/dean/A4-41(wood).png",
            },
            {
              name: "1 BED",
              squareFeet: "806 SQ FT",
              plan: " PLAN A5",
              image: "assets/images/floorImages/dean/A5(wood).png",
            },
            {
              name: "1 BED",
              squareFeet: "779 SQ FT",
              plan: " PLAN A6-1",
              image: "assets/images/floorImages/dean/A6-1(wood).png",
            },
            {
              name: "1 BED",
              squareFeet: "677 SQ FT",
              plan: " PLAN A7-4 ",
              image: "assets/images/floorImages/dean/A7-4(wood).png",
            },
            {
              name: "1 BED",
              squareFeet: "594 SQ FT",
              plan: " PLAN A9",
              image: "assets/images/floorImages/dean/A9(wood).png",
            },
            {
              name: "1 BED",
              squareFeet: "968 SQ FT",
              plan: " PLAN A10",
              image: "assets/images/floorImages/dean/A10(wood).png",
            },
            {
              name: "1 BED",
              squareFeet: "829 SQ FT",
              plan: " PLAN A11",
              image: "assets/images/floorImages/dean/A11(wood).png",
            },
          ],
          name: "1 BED",
          id: "1BED",
        },
        {
          floors: [
            {
              name: "2 BED",
              squareFeet: "1171 SQ FT",
              plan: "PLAN B-16",
              image: "assets/images/floorImages/dean/B-16(wood).png",
            },
            {
              name: "2 BED",
              squareFeet: "1060 SQ FT",
              plan: "PLAN B1-4",
              image: "assets/images/floorImages/dean/B1-4(wood).png",
            },
            {
              name: "2 BED",
              squareFeet: "1171 SQ FT",
              plan: "PLAN B1-16",
              image: "assets/images/floorImages/dean/B1-16(wood).png",
            },
            {
              name: "2 BED",
              squareFeet: "1189 SQ FT",
              plan: "PLAN B2",
              image: "assets/images/floorImages/dean/B2(wood).png",
              video3dUrl: "https://my.matterport.com/show/?m=hYkEqFP8PZo",
            },
            {
              name: "2 BED",
              squareFeet: "1008 SQ FT",
              plan: "PLAN B3",
              image: "assets/images/floorImages/dean/B3(wood).png",
              video3dUrl: "https://my.matterport.com/show/?m=UWrVxCEfBDi",
            },
            {
              name: "2 BED",
              squareFeet: "962 SQ FT",
              plan: "PLAN B3-1",
              image: "assets/images/floorImages/dean/B3-1(metal).png",
            },
            {
              name: "2 BED",
              squareFeet: "1067 SQ FT",
              plan: "PLAN B4",
              image: "assets/images/floorImages/dean/B4(wood).png",
            },
            {
              name: "2 BED",
              squareFeet: "951 SQ FT",
              plan: "PLAN B4-2",
              image: "assets/images/floorImages/dean/B4-2(wood).png",
            },
            {
              name: "2 BED",
              squareFeet: "999 SQ FT",
              plan: "PLAN B5",
              image: "assets/images/floorImages/dean/B5(wood).png",
            },
            {
              name: "2 BED",
              squareFeet: "1005 SQ FT",
              plan: "PLAN B6",
              image: "assets/images/floorImages/dean/B6(wood).png",
              video3dUrl: "https://my.matterport.com/show/?m=YwYDXmrdSqE",
            },
          ],
          name: "2 BED",
          id: "2BED",
        },
        {
          floors: [
            {
              name: "3 BED",
              squareFeet: "1196 SQ FT",
              plan: "PLAN C1-6",
              image: "assets/images/floorImages/dean/C1-6(wood).png",
              video3dUrl: "https://my.matterport.com/show/?m=DwHdvN6Sew2",
            },
            {
              name: "3 BED",
              squareFeet: "1311 SQ FT",
              plan: "PLAN  C2",
              image: "assets/images/floorImages/dean/C2(wood).png",
            },
          ],
          name: "3 BED",
          id: "3BED",
        },
      ],
    },
    NEIGHBORHOOD: {
      Heading: "IT’S A BEAUTIFUL DAY TO EXPLORE",
      Paragraph:
        "Welcome to The Nolo Apartments in the heart of Mountain View, just steps away from the San Antonio Caltrain Station and a quick commute from Google headquarters. Stop by Trader Joe’s known for their selection of domestic and imported cheeses, organic fruits and veggies, and locally sourced meats. The Artisan Wine Depot of Mountain View is the logical next stop for their global selection of specialty wines.",
      Data: {},
    },
    AMENITIES: {
      Heading: "THE IMPORTANT LITTLE THINGS",
      Paragraph:
        "Enjoy a well-deserved vacation welcome home to The Nolo Apartments. Stop by the clubhouse, featuring shuffleboard, pinball machines, pool tables and dart boards. Choose from our iconic glass-sided pool or junior Olympic lap pool. Work up a sweat in our state of the art fitness center featuring Peloton bikes and the serenity yoga room and we speak woof, meow, blub-bulb and tweet.",
      Data: [
        {
          Name: "Space to Stretch and a Shuttle Too",
          ModalImage: "",
          Image:
            "https://s3-us-west-1.amazonaws.com/suite-ch/20197/20197_i_2.jpg",
          Content:
            "The Travel Suite is a one-bedroom apartment equipped for comfort and convenience. Catch the shuttle to the SSC just a minute away (map is provided inside the suite).",
        },
        {
          Name: "Rooftop Deck",
          ModalImage: "",
          Image:
            "https://s3-us-west-1.amazonaws.com/suite-ch/20197/20197_c.jpg",
          Content:
            "Stunning Views of Lumen Field, Mount Rainier, Puget Sound, and Downtown Seattle.",
        },
        {
          Name: "Fitness Center",
          ModalImage: "",
          Image:
            "https://res.cloudinary.com/g5-assets-cld/image/upload/x_0,y_26,h_534,w_800,c_crop/q_auto,f_auto,fl_lossy,g_center,h_665,w_998/g5/g5-c-ibsddh6p-pillar-properties-client/g5-cl-55us9521s-the-nolo/uploads/i_stadiumplace_9.jpg",
          Content:
            "Featuring Peloton® bikes, multi-functional training rack, and Serenity yoga room.",
        },
        {
          Name: "Shared Amenities at The Wave",
          ModalImage: "",
          Image: "/assets/images/DeanImg/thewave.jpg",
          Content:
            "The 5th floor walkway leads to The Wave next door, where partners can use the amenities and rooftop lounge.",
        },
      ],
      BottomParagraph:
        "*Communal Amenities under construction – Will Open Fall 2019",
    },
    PHOTOGALLERY: {
      Heading: "TAKE A SCROLL THROUGH OUR GALLERY",

      Paragraph:
        "Enjoy a well-deserved vacation welcome home to The Nolo Apartments.  Stop by the clubhouse, featuring shuffleboard, pinball machines, pool tables and dart boards.  Choose from our iconic glass-sided pool or junior Olympic lap pool.  Work up a sweat in our state of the art fitness center featuring Peloton bikes  and the serenity yoga room and we speak woof, meow, blub-bulb and tweet.",
    },
  },
};

module.exports = CommunitesList;
