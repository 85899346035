const GoogleForm = (props) => {
    return ( 
        <div className="gtravel_sec">
        <div className="container">
        <h3 className="text-center">LET’S FIGURE OUT WHAT 
        <ul className="google_clr_list">
            <li>y</li><li>o</li><li>u</li><li>'</li><li>r</li><li>e</li>
        </ul>
        LOOKING FOR…</h3>
    <p className="text-center">So we may better accommodate you!</p>

    <div className="google_reserve_form google_form">
			{props.errorMessage != "" ? <h6 className="text-center text-danger"> {props.errorMessage}</h6> : ""}
				<form onSubmit={props.reservationSubmit} className="clearfix">
					<div className="gflexdiv top  mrg-btm-30">
						<div className="form-group ">
								<input id="google-stay" type="text" className="form-control" autoComplete="sfdsdfsd" placeholder="DESIRED LOCATION (CITY,STATE)"  onChange={props.setLocation} />
                                <div className="info-field-icon">
								</div>
						</div>
						<div className="form-group">
							<input type="text" id="CheckInRange" className="form-control" placeholder="CHECK IN*" autoComplete="off" value={props.checkin} onChange={props.checkInMethod}/>
							<div className="info-field-icon">
							
                                    <i className="fa fa-calendar" id="CheckInRange"></i>
								</div>
						</div>
						<div className="form-group">
							<input type="text"  id="CheckOutRange" className="form-control" placeholder="CHECK OUT*" autoComplete="off" value={props.checkout} onChange={props.checkOutMethod}/>
							<div className="info-field-icon">
									<i className="fa fa-calendar" id="CheckOutRange"></i>
								</div>
						</div>
						<div className="form-group selectIcon">
                        <select value={props.bedRoom} onChange={(e) =>
                                 { props.setValue('bedRoom', e) }} className="form-control "  >
                                 {props.bedRoomsList.map((row, index) => {
                                 return (
                                 <option id="1" key={index} value={row.value}>{row.name}</option>
                                 )
                                 })}
                              </select>
							<div className="info-field-icon">
									<i className="fa fa-bed"></i>
								</div>
						</div>
					</div>
					
					<div className="gflexdiv google_formone">
						<div className="form-group">
							<input type="text" className="form-control" placeholder="FIRST NAME*"  value={props.firstName} onChange={(e)=>{props.setValue('firstName',e)}}/>
						</div>
					
						<div className="form-group">
							<input type="text" className="form-control" placeholder="LAST NAME"  value={props.lastName} onChange={(e)=>{props.setValue('lastName',e)}}/>
						</div>
						<div className="form-group">
							<input type="text" className="form-control" placeholder="EMAIL*"  value={props.email} onChange={(e)=>{props.setValue('email',e)}}/>
						</div>
                        <div className="form-group">
							<input type="text" className="form-control" placeholder="PHONE"  maxLength="16" value={props.phoneNumber} onChange={(e)=>{props.setNumber('phoneNumber',e)}}/>
						</div>
					</div>
					<div className="gflexdiv  google_formone ">
						<div className="form-group">
                            <select value={props.pet}  onChange={(e)=>
                                 {props.setValue('pet',e)}} className="form-control">
                                 {props.petsList.map((row,index)=>{
                                 return(
                                 <option id="1" key={index} value={row.value}>{row.name}</option>
                                 )
                                 })}
                              </select>
						</div>
                        <div className="form-group">
                        <select value={props.adult}  onChange={(e)=>
                                 {props.setValue('adult',e)}} className="form-control"  >
                                 {props.adultsList.map((row,index)=>{
                                 return(
                                 <option id="1" key={index} value={row.value}>{row.name}</option>
                                 )
                                 })}
                              </select>
						</div>
                        <div className="form-group">
                        <select value={props.children}  onChange={(e)=>
                                 {props.setValue('children',e)}} className="form-control"  >
                                 {props.childrensList.map((row,index)=>{
                                 return(
                                 <option id="1" key={index} value={row.value}>{row.name}</option>
                                 )
                                 })}
                              </select>
						</div>
                        <div className="form-group">
                        <select value={props.contactMethod}  onChange={(e)=>
                                 {props.setValue('contactMethod',e)}} className="form-control" >
                                 {props.contactMethods.map((row,index)=>{
                                 return(
                                 <option id="1" key={index} value={row.value}>{row.name} </option>
                                 )
                                 })}
                              </select>
						</div>
					</div>
					<div className="gflexdiv ">
						<div className="form-group">
							<textarea className="form-control" placeholder="Special Note" value={props.comments} onChange={(e) => { props.setValue('comments', e) }}>
							</textarea>
						</div>
					</div>
					
                 
                         
                           <div className="col-md-12 col-xs-12">
                              <button className="google_btn" type="submit">
                                 <ul className="google_clr_list">
                                    <li>g</li><li>t</li><li>r</li><li>a</li><li>v</li><li>e</li><li>l</li>
                                 </ul>
                                 @SUITEAMERICA.COM
                              </button>
                           </div>
                           
                   
                    </form>
			</div>
    </div>
    </div>
    );

}
export default GoogleForm;
