/* Importing the node modules, child components, services and controllers used
   inside Login component */
import {Link} from 'react-router-dom';
import $ from 'jquery';
import DestinationService from '../../../services/destinationService';
import moment from 'moment'
import DsProfileData from '../../common/ds-profile.json';
import DsImagesData from '../../common/ds-images.json';
import AppController from '../../controller/appController';
import SiteLoader from '../../common/siteLoader';
class Login extends React.Component {
  constructor(props){
    super(props);
    this.state ={
      username:'',
      password:'',
      errorMessage:'',
      error:false,
      loginResult:[],
      propertyRating:[],
      notes:'',
      headerDetails: [],
      propertyDetails: [],
      length:'',
      notesArray:[],
      starRating:[],
      successMessage:'',
     // CaptchaValue: '',
      dcModal:false,
      dsProfiles:DsProfileData,
      images:DsImagesData,
      submitloader:false
    }
    this.OpenModal = this.OpenModal.bind(this);
    this.propertyIds = this.propertyIds.bind(this);
    this.clear = this.clear.bind(this);
    this.addStarNotes = this.addStarNotes.bind(this);
    this.addStar = this.addStar.bind(this);
  }
  componentDidMount(){
     this.InitialData()
     AppController.removeSiteLoader();
    AppController.slimScroll();
  }
/*  async onChangeCaptcha(CaptchaValue) {
    await this.setState({ CaptchaValue })
  }  */
  async InitialData(){
    const propertyData = this.props.itineraryData;
    const propertyDetails = await DestinationService.getDetails(propertyData);
    const headerDetails = await DestinationService.headerDetails(propertyData);
    await this.setState({propertyDetails:propertyDetails.recordset,headerDetails:headerDetails.recordset,length:propertyDetails.recordset.length});
  }
  setValue(field, e) {
    var object = {};
    object[field] = e.target.value;
    this.setState(object);
  }
async onStarClick(value,index) {
    const ratingUpdate ={
       propertyId:this.state.loginResult[index].PropertyID,
       itineraryID:this.state.loginResult[index].ItineraryID,
      propertyRating: value,
      type:"rating"
    }
    console.log(ratingUpdate)
    await this.InitialData();
  }
  async addStar(index, PropertyID, rangeVa,Community, e){
      let data;
      const indexData = _.findIndex(this.state.starRating, function(o) { return o.index == index; })
      if(indexData<0){
        data = _.concat(this.state.starRating,{value:e.target.value, index: index, rangeVa: rangeVa, PropertyID: PropertyID,Community:Community});
      }else{
        data = update(this.state.starRating, { [index]: {value: {$set: e.target.value} , index: {$set: index}, rangeVa: {$set: rangeVa}, PropertyID: {$set: PropertyID}, Community: {$set: Community}}});
      }
      await this.setState({starRating: data});
  }
  async addStarNotes(){
     const stars = _.orderBy(this.state.starRating,['index'],['asc'])
     var notesArray=[];
     for (var j = 0; j < this.state.propertyDetails.length; j++) {
       if($('#text'+j).val() != '')
       {
         notesArray.push($('#text'+j).val())
       }
       else{
         notesArray.push("")
       }
     }
   const value = notesArray.indexOf("")
   if( value == -1 && this.state.starRating.length == this.state.propertyDetails.length){
   const dsNotes = $('#dsnotes').val()
   await this.setState({submitloader:true})
    const update ={
        itineraryId:this.state.propertyDetails[0].ItineraryID,
        FileNum:this.state.propertyDetails[0].FileNum,
        notes:notesArray,
        rating:stars,
        employeeName:this.state.headerDetails[0].EmpFirstName + " "+this.state.headerDetails[0].EmpLastName,
        address:this.state.headerDetails[0].City +", " + this.state.headerDetails[0].State,
        to:this.state.headerDetails[0].DSPUserEmail,
        companyName:this.props.itineraryData.companyName,
        type:'group',
        dsNotes:dsNotes
       }
       await this.setState({errorMessage:'',successMessage:'Notes Successfully Added'})
       await DestinationService.updateRating(update)

       const _this=this
       setTimeout(async function() {
        for (var i = 0; i < this.state.propertyDetails.length; i++) {
          ($('#text'+i).val(''))
         }
         ($('#dsnotes').val(''))
          await _this.setState({successMessage:'',starRating:'',submitloader:false})
          }.bind(this), 4000);

      }
      else{
        this.setState({errorMessage:'Please Enter Notes/Rating',successMessage:''})
      }
 }
 async clear(){
  for (var i = 0; i < this.state.propertyDetails.length; i++) {
    ($('#text'+i).val(''))
   }
   ($('#dsnotes').val(''))
   await this.setState({starRating:''})
 }
  propertyIds(SortOrder){
    localStorage.setItem('SortOrder', SortOrder)
    localStorage.setItem('length', this.state.length)
  }
  OpenModal(e){
     this.setState({dcModal:!this.state.dcModal});
  }
  render() {
    
    
    const headerDetail = this.state.headerDetails[0];
    const _this = this
    var filteredStateName = _.find(this.state.images, function (o) {
      if(headerDetail != undefined){
        if (o.name == headerDetail.label.replace(' Tour','').toLowerCase()){
          return o;
         }
      }
    })
    const dsPrfofilePic = headerDetail != undefined ? headerDetail.DSP :   'DS-profile';
    const dsProfileIndex = _.findIndex(this.state.dsProfiles, function(o) { return o.dsName == dsPrfofilePic; });
    const defaultProfileIndex = _.findIndex(this.state.dsProfiles, function(o) { return o.dsName == "DS-profile"; });
    const dsProfileData = dsProfileIndex != -1 ? this.state.dsProfiles[dsProfileIndex] : this.state.dsProfiles[defaultProfileIndex];
    const picSource = dsProfileData.dsImage.trim();
    var image = filteredStateName !=undefined ? 'https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/DS-images/'+filteredStateName.image : 'https://s3-us-west-1.amazonaws.com/suiteam-website/Destination-Services/SuiteAmerica-Destination-Services-Background-Itinerary1.jpg'
    var backgroundClass = filteredStateName !=undefined && filteredStateName.name == 'san diego'? "bg-Itinerary bg-Itinerary_bg":"bg-Itinerary";
    return <div>

     <section className={backgroundClass} style={{backgroundImage: 'url('+image+')'}}>
     <div className="welecome-block">
       <div className="welcome-logo z-index-9"><img src="https://s3-us-west-1.amazonaws.com/suiteam-website/Destination-Services/SuiteAmerica-Destination-Services-Big-Icon.png" alt=""/></div>
       <div className="username z-index-9">
         <h2><span>{headerDetail != undefined ? headerDetail.EmpFirstName :   ''}</span> <span>{headerDetail != undefined ? headerDetail.EmpLastName :   ''}</span></h2>
       </div>
       <div className="welcome-city z-index-9">
         <h1>Welcome to {headerDetail != undefined ? headerDetail.label.replace(' Tour','') :   ''} </h1>
         {this.props.companyNameDetails != 'pricing' ? <img className="width100 mrg-top-60" src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Company-Nike-Logo.png"} alt="" />: null}
       </div>
     </div>
   </section>
   <section className="itinerary-today">
     <div className="container">
       <div className="row">
         <div className="col-sm-5">
           <div className="left-addresblock">
             <div className="yourItinerary-detail">
               <div className="logo"> <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/Destination-Services/SuiteAmerica-Destination-Services-Big-Icon.png" alt=""/> </div>
               <div className="user-Detail">
                 <div className="username-block">
                   <h2><span>{headerDetail != undefined ? headerDetail.EmpFirstName :   ''}</span> <span>{headerDetail != undefined ? headerDetail.EmpLastName :   ''}</span></h2>
                 </div>
                 <div className="addressblock">
                   <h3>Itinerary for <span>{headerDetail != undefined ? headerDetail.label.replace(' Tour','') :   ''}</span> <span>{headerDetail != undefined ? headerDetail.State :   ''}</span></h3>
                   <h3><span>{headerDetail != undefined ? moment.utc(headerDetail.ServiceDate).format('MMMM Do YYYY'): ''}</span></h3>
                 </div>
               </div>
             </div>
             <div className="dc-block">
                {/*start here*/}
                <div className="dsProfile">
                    <div className="dsProfileLeft"> <img src={picSource}/> </div>
                    <div className="dsProfileRight">

                    <div className="dc-detail-block">
                      <div className="user-Detail">
                        <div className="username-block">
                          <h2><span>{headerDetail != undefined ? headerDetail.DSP :   ''}</span></h2>
                        </div>
                        <div className="dc-addressblock">
                          <h3><span>{headerDetail != undefined ? headerDetail.DSPUserTitle :   ''}</span></h3>
                          <h3>Cell:<span>{headerDetail != undefined ? headerDetail.DSPUserPhone :   ''}</span></h3>
                          <h3>email:<span>{headerDetail != undefined ? headerDetail.DSPUserEmail :   ''}</span></h3>
                          <div className="dcHead" >
                              <h2 onClick={(e) => this.OpenModal(e)}>Meet Your Destination Consultant</h2>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="myModal" className={this.state.dcModal === true ? "dc-modal-show":"dc-modal"}>

                      <div className="dc-modal-content">
                      <span style={{cursor: 'pointer'}} className="dc-close" 
                      onClick={(e) => this.OpenModal(e)}>&times;</span>
                        <h2>Hi {headerDetail != undefined ? headerDetail.EmpFirstName :   ''}!</h2>
                        <div className="pick-thumb">
                        <img src={picSource}/>
                        </div>
                        <p>My name is <strong>{headerDetail != undefined ? headerDetail.DSP :   ''}</strong> and I will be your DS Consultant for the duration of
your stay with us at SuiteAmerica. I’m looking forward to our meeting. Please
let me know if I can assist you in any way. Here is a little info about me:</p>
            <div className="dc-list">
            {dsProfileData.homeTown=='---'?'': <p>Hometown: {dsProfileData.homeTown}</p>}
        {dsProfileData.funFact=='---'?'':<p>Fun Fact: {dsProfileData.funFact}</p>}
        {dsProfileData.spentTime=='---'?'':<p>Years with SuiteAmerica: {dsProfileData.spentTime}</p>}

            </div>
            <h3>See you on {headerDetail != undefined ? moment.utc(headerDetail.ServiceDate).format('dddd, MMMM Do YYYY'): ''} for your personalized tour!</h3>

                      </div>
                    </div>
                    </div>
                </div>
                {/*end here*/}
             </div>
           </div>
         </div>
         <div className="col-sm-7">
           <div className="itineraryProperty-block">
             <h1>Your Itinerary for Today</h1>
             <div className="propertyList-block">
               <div id="testDiv">
                 <div className="itinerary-table">
                   <div className="itinerary-row header">
                     <div className="itinerary-cell propertyList-count-width"> </div>
                     <div className="itinerary-cell"> Time </div>
                     <div className="itinerary-cell left-border"> Property </div>
                   </div>
                   <div className="itinerary-row">
                  <div className="itinerary-cell"> </div>
                  <div className="itinerary-cell"> <span className="font-blod">{headerDetail != undefined ? (moment.utc(headerDetail.MeetupTime).format('hh:mm A')):''}</span></div>
                  <div className="itinerary-cell left-border"> General meet and greet </div>
                </div>
                  {this.state.propertyDetails.map((row, index) => {
                    return<div key={index} className="itinerary-row">
                           <div className="itinerary-cell"> <span className="propertyList-count">{index +1}</span> </div>
                          <div className="itinerary-cell"> <span className="font-blod"></span>{moment.utc(row.time).format('hh:mm A')}</div>
                          <div className="itinerary-cell left-border">{row.Community}</div>
                        </div>
                    })
                   }
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
   </section>
   <section className="ItineraryMap-block">
     <div className="container">
     <div className="row">
        {this.state.propertyDetails.map((row,index) =>{
        return<div  key={index} className="col-sm-6">
             <Link to={'/destination-service/itinerary/property/'+this.props.itineraryData.companyName+'/' + this.props.itineraryData.FileNum + '/' + this.props.itineraryData.itineraryId} 
             onClick={() => this.propertyIds(row.SortOrder)}>
           <div className="propertyMapbox-block">
             <div className="propertyMapbox-head">
               <div className="mapbox-pointer">
                 <div className="mapbox-count"><span className="font-blod-x">{index+1}</span></div>
               </div>
               <div className="mapbox-address">{row.Address}, {row.City} {row.StateCode} {row.ZipCode}</div>
               <div className="mapbox-time"><span className="font-blod color-dark-grey">{moment.utc(row.time).format('hh:mm A')}</span></div>
             </div>
             <div className="propertyMapbox-map">
             <img src={'https://s3-us-west-1.amazonaws.com/destination-services-itinerary/'+row.Photo+'.jpg'} alt="" /></div>
           </div>
           </Link>
         </div>
         })
        }
       </div>
     </div>
   </section>
   <section className="ItineraryList-block">
     <div className="container">
       {this.state.propertyDetails.map((row,index) => {
       return<div key={index} className="property-listbox-block">
         <div className="property-listbox-flex">
           <div className="propertyMaplist-block">
             <div className="mapbox-pointer">
               <div className="mapbox-count"><span className="font-blod-x">{index+1}</span></div>
             </div>
             <div className="propertyMaplist-image-block"> <Link to={'/destination-service/itinerary/property/'+this.props.itineraryData.companyName+'/' + this.props.itineraryData.FileNum + '/' + this.props.itineraryData.itineraryId} 
             onClick={() => this.propertyIds(row.SortOrder)}><img src={'https://s3-us-west-1.amazonaws.com/destination-services-itinerary/'+row.Photo+'.jpg'} alt=""/></Link> </div>
             <div className="propertyMaplist-address-block">
               <h1 className="font-blod">{row.Community}</h1>
               <p>{row.Address}, {row.City} {row.StateCode} {row.ZipCode}</p>
             </div>
           </div>
           <div className="propertyMaplist-rating-block">
             <div className="rating-block">
               <div className="property-rate-head"> <i className="fa fa-star-o"></i> Rate this property </div>
               <div className="rating-num-block">

               <ul>
               {_.range(5).map((rangeVa) =>{
                 const indexVal =_.findIndex(_this.state.starRating, function(o) { return o.PropertyID == row.PropertyID && o.rangeVa==( rangeVa+1) ; });

              return  <li key={rangeVa} className={indexVal>=0? 'active font-blod': "font-blod"} value='1'  
              onClick={(e) => _this.addStar(index, row.PropertyID, rangeVa+1,row.Community, e)}>{rangeVa+1}</li>
})}
            </ul>
               </div>
             </div>
             <div className="rating-disc">
             <div className="">
             <textarea name="" id={'text'+index} cols="35" rows="5" className="form-control" placeholder="Notes"></textarea>
             </div>
             </div>
           </div>
         </div>
       </div>
       }
      )}
       <div className="row">
         <div className="col-sm-6 col-sm-offset-3">
           <div className="property-form-block">
             <h3>How did your tour with <span>{headerDetail != undefined ? '"' + (headerDetail.DSP).trim() + '"' : ""}</span> go today?</h3>
             <textarea name="" id={'dsnotes'} cols="20" rows="5" className="form-control" placeholder="Notes" ></textarea>
             {/* <ReCAPTCHA
                                            ref={ ref => this.recaptcha = ref }
                                            sitekey="6Lcbw1AUAAAAAE5K5cYDyhoGu0RJY9C3CLtGFXAo"
                                            onChange={this.onChangeCaptcha}
                                            /> */}
             {/* <p>Email will be sent to you as well as your company, your relocation company, and SuiteAmerica.</p> */}
             <div className="property-but-block">
               <button onClick={this.clear} className="btn btn-primary btn-x-large text-uppercase">Clear</button>
               {this.state.submitloader?
        <button  className="btn btn-primary btn-xxl text-uppercase">Save 
        <span className="btn-pleaseWait"><i class="fa fa-spinner" aria-hidden="true"></i> Please Wait</span>
        </button>:
               <button onClick={this.addStarNotes} className="btn btn-primary btn-x-large text-uppercase">Submit</button>
               }
             </div>
             <div style={{'color': '#ff0000','marginTop':'15px'}}>{this.state.errorMessage}</div>
             <div style={{'color': 'green','marginTop':'15px'}}>{this.state.successMessage}</div>
           </div>
         </div>
       </div>
     </div>
   </section>
      <SiteLoader />
    </div>
  }
}

export default Login;
