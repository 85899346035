import RequestCallback from "./requestCallback";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      year: 2022,
      selectedcommunityName: null,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  async componentDidMount() {
    const d = new Date();
    let year = d.getFullYear();
    await this.setState({ year: year });
  }
  handleClick() {
    this.setState({
      selectedcommunityName: null,
    });
    $("#sideQuote").addClass("active");
    $(".overlay").addClass("active");
    $(".collapse.in").toggleClass("in");
    $("a[aria-expanded=true]").attr("aria-expanded", "false");
  }
  closed() {
    $("#sideQuote").removeClass("active");
    $(".overlay").removeClass("active");
  }
  render() {
    return (
      <div>
        <div className="overlay sideQuote"></div>
        <footer className="py-5">
          <div className="container mt-5">
            <div className="row">
              <div className="col-sm-5">
                <div className="footerLogo">
                  <img
                    src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/logo/full_logo.svg"
                    alt=""
                  />
                </div>
                <h1 className="follow">Reach Us</h1>
                <ul className="socialIcons">
                  <li>
                    <a
                      href="https://www.linkedin.com/company/suiteamerica"
                      target={"_blank"}
                    >
                      {" "}
                      <i className="bi bi-linkedin" target={"_blank"}></i>{" "}
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/SuiteAmerica"
                      target={"_blank"}
                    >
                      {" "}
                      <i className="bi bi-facebook" target={"_blank"}></i>{" "}
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/suite_america/"
                      target={"_blank"}
                    >
                      <i className="bi bi-instagram" target={"_blank"}></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/user/SuiteAmerica?feature=mhum"
                      target={"_blank"}
                    >
                      {" "}
                      <i className="bi bi-youtube" target={"_blank"}></i>{" "}
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/SuiteAmerica"
                      target={"_blank"}
                    >
                      {" "}
                      <i class="bi bi-twitter-x"></i>{" "}
                    </a>
                  </li>
                </ul>
                <ul>
                  <li>
                    {" "}
                    <a href="tel:8003679501">
                      <i className="bi bi-telephone-fill pe-2"></i>&nbsp;+1
                      800.367.9501{" "}
                    </a>
                  </li>
                  <li>
                    <a href="/contactUs">
                      <i className="bi bi-envelope pe-2"></i>&nbsp; Email Us{" "}
                    </a>
                  </li>
                </ul>
                <div className="mrg-btm-20">
                  <button
                    className="btn "
                    type="button"
                    onClick={() => this.handleClick()}
                  >
                    {" "}
                    Reserve Now
                  </button>
                </div>
                <div>
                  <a
                    href="https://apps.apple.com/app/guestportal/id1492423942"
                    target="_blank"
                  >
                    <img
                      src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/Download_on_the_App_Store_Badge_US-UK_RGB_wht_092917.svg"
                      alt="app store"
                      className="footer-logo"
                    ></img>
                  </a>
                </div>
                <div className="m-t-10">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.suiteamerica.guestportal&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                    target="_blank"
                  >
                    <img
                      src="/assets/newUI-images/google-play-store-badge-w.svg"
                      alt="Play store"
                      className="footer-logo"
                    ></img>
                  </a>
                </div>
              </div>
              <div className="col-sm-7">
                <div className="footerBox">
                  <div className="item">
                    <h1>Company</h1>
                    <ul>
                      <li>
                        <a href="/company-info"> About Us </a>
                      </li>
                      <li>
                        <a href="/blog"> Blog </a>
                      </li>
                      <li>
                        <a href="/career-opportunities"> Careers </a>
                      </li>
                      <li>
                        <a href="/privacy-policy"> Privacy Policy </a>
                      </li>
                      <li>
                        <a href="/gdpr">GDPR</a>
                      </li>
                      <li>
                        <a href="/contactUs"> Contact Us </a>
                      </li>
                    </ul>
                  </div>
                  <div className="item">
                    <a href="/technology">
                      {" "}
                      <h1>Technology</h1>
                    </a>
                    <h1>Stay With Us</h1>

                    <ul>
                      <li>
                        <a href="/guestExperience">Guest Experience</a>
                      </li>
                      <li>
                        <a href="/locations">Locations</a>
                      </li>
                      <li>
                        <a href="/spotlightCities"> Spotlight Cities </a>
                      </li>
                    </ul>
                  </div>
                  <div className="item">
                    <h1>Solutions</h1>
                    <ul>
                      <li>
                        <a href="/corporatehousing"> Corporate Housing </a>
                      </li>
                      <li>
                        <a href="/government-housing">Government Housing </a>
                      </li>
                      <li>
                        <a href="/internHousing">Intern & Group Housing</a>
                      </li>
                      <li>
                        <a href="/business-travel">Business Travel</a>
                      </li>
                      <li>
                        <a href="/insurance-landing"> Insurance Housing </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5 pt-5 align-items-center row-reverse-mobile">
              <div className="col-sm-5">
                <h2>
                  Copyright ©{this.state.year} SuiteAmerica | All rights
                  reserved
                </h2>
              </div>
              <div className="col-sm-7">
                <ul className="brandLogos">
                  <li>
                    {" "}
                    <img
                      src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/GSA-logo.png"
                      width="250px"
                      alt=""
                    />{" "}
                  </li>
                  {/* <li> <img src="assets/newUI-images/isaap-logo.png" width="100px" alt="" /> </li> */}
                  <li>
                    {" "}
                    <img
                      src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/chpa-logo.png"
                      width="100px"
                      alt=""
                    />{" "}
                  </li>
                  <li>
                    {" "}
                    <img
                      src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/ada-logo.png"
                      width="150px"
                      alt=""
                    />{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
        <RequestCallback
          selectedcommunityName={this.state.selectedcommunityName}
        />
        {/* <section id="sideQuote">

          <div className="quoteHeading">
            <h4>We would love to help you </h4>
            <div id="dismiss">
              <a onClick={this.closed}><i className="bi bi-x"></i></a>
            </div>
          </div>
          <div className="formQuote">
            <div className="btn-group" role="group" aria-label="Basic outlined example">
              <button type="button" className="btn active">For Businesses</button>
              <button type="button" className="btn ">For Individuals</button>
            </div>
            <form action="">

              <div className="floating-label-group">
                <input type="text" className="form-control" required />
                <label className="floating-label">Username</label>
              </div>
              <div className="floating-label-group">
                <input type="text" className="form-control" required />
                <label className="floating-label">Enter Your Name</label>
              </div>
              <div className="phone">
                <div className="form-group SAweb_selectDrop min-width">
                  <select readOnly value="da" className="form-select" aria-label="Default select example">
                    <option value="">+011</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>

                <div className="floating-label-group">
                  <input type="text" className="form-control" required />
                  <label className="floating-label">Enter Your Phone Number</label>
                </div>

              </div>
              <div className="floating-label-group">
                <input type="text" className="form-control" required />
                <label className="floating-label">Enter Your Email Address</label>
              </div>

              <div className="floating-label-group">
                <input type="text" className="form-control" required />
                <label className="floating-label">Company Name</label>
              </div>

              <div className="form-group SAweb_selectDrop min-width">
                <select readOnly className="form-select" aria-label="Default select example">
                  <option value="">Select Location</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>


              <div className="form-group">

                <label className="form-control">Guests</label>
                <div className="input-group guestCount">

                  <span className="input-group-btn">
                    <button type="button" className="btn btn-default btn-number" disabled="disabled" data-type="minus" data-field="quant[1]">
                      <i className="bi bi-plus"></i>
                    </button>
                  </span>
                  <input readOnly={true} type="text" className="form-control input-number" value="01" min="1" max="10" placeholder="Company Name" />

                  <span className="input-group-btn">
                    <button type="button" className="btn btn-default btn-number" data-type="plus" data-field="quant[1]">
                      <i className="bi bi-dash"></i>
                    </button>
                  </span>
                </div>
              </div>

              <div className="datesView">

                <div className="floating-label-group">
                  <input type="text" className="form-control" required />
                  <label className="floating-label">Check In Date</label>
                </div>
                <div className="floating-label-group">
                  <input type="text" className="form-control" required />
                  <label className="floating-label">Check Out Date</label>
                </div>

              </div>

              <button type="button" className="btn"> Place Request </button>


            </form>


          </div>



        </section> */}
      </div>
    );
  }
}
export default Footer;
