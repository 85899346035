import React from "react";

function SubFooterIntutiveSurgical() {
  return (
    <div>
      <div className="banner_bottom footer_top row linkedIn">
        <div className="bb_boder foot_top">
          <div className="bb_text width100per justify-content-center">
            <img
              src={"/assets/newUI-images/nike/mail.svg"}
              className="pull-left"
              alt=""
            />
            <span>
              {" "}
              <h1>Got Questions? Contact us at</h1>
              <a href="mailto:IntuitiveSurgicalinterns@suiteamerica.com">
                IntuitiveSurgicalinterns@suiteamerica.com
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubFooterIntutiveSurgical;
