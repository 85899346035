import {Link} from 'react-router-dom';
const Buttons = props => {
    const row = props.rowData
    const index = props.index
    return (
        <div className="row">
        <div className="col-sm-6">
          
        {(props.isStarted == row.UniqueId || props.isStarted==-1)? (props.orderStatusLoading && props.ScheduleUniqueId == row.UniqueId) ?<div> <span style={{fontSize: '25px', width: '100%', margin: 'auto', color:'#000000'}}><i className="fa fa-spinner fa-spin"/> please wait ...!</span> </div>:null:null}
        {(props.isStarted == row.UniqueId || props.isStarted==-1)? ( !props.orderStatusLoading && props.ScheduleUniqueId != row.UniqueId) ?<div className="sc-order-fl-btn-links mrg-top-25"> 
            <Link to='/keylist' onClick={()=>props.openServiceRerquestForm(row)}>Key Location</Link> 
             
            {((row.StartTime == null && row.EndTime == null)  || row.StatusHold == 'hold')?<a onClick={()=>props.statusChange(row, index, 1, 'started')}>Start</a> : null }
            {((row.StartTime != null && row.EndTime == null) && (row.startedDriver >= 0) && row.StatusHold != 'hold')?<a onClick={()=>props.holdService(row, index, 3, 'onHold')}>Hold</a> : null }
            {((row.StartTime != null && row.EndTime == null) && (row.startedDriver >= 0 )  && row.StatusHold != 'hold')?<a onClick={()=>props.statusChange(row, index, 2, 'completed')}>Done</a> : null } 
           
          </div> :null:null  } 
        </div>
          </div>
    );
  };

  export default Buttons;