'use strict'
import serviceBase from './serviceBase'
const ThirdPartyApis = {
    thirdPartyApi:(data) => serviceBase.post('/thirdpartyapi', data),
    pointofinterest:(data) => serviceBase.post('/thirdpartyapi/poi', data),
    guestsentemailotp: (data) => serviceBase.post('/loginfun/guestsentemailotp', data),
    guestsentotpverify: (data) => serviceBase.post('/loginfun/guestsentotpverify', data),
    logoutguest: (data) => serviceBase.post('/loginfun/logoutguest'),
    greatSchools:(data) => serviceBase.post('/thirdpartyapi/greatschools', data),
}
export default ThirdPartyApis