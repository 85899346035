import {Link} from 'react-router-dom';
import DestinationService from '../../../services/destinationService';
import SiteLoader from '../../common/siteLoader';
import AppController from '../../controller/appController';
class NoPricingMapView extends React.Component {
    constructor(props){
    super(props);
    this.state={
      headerDetails: [],
      propertyDetails: [],
      locations:[],
      map:{} 
    }
    this.setLocation = this.setLocation.bind(this);
    }
   async componentWillMount(){
    await this.InitialData()
    const community=[];
      setTimeout(async function() {        
        var geocoder = new google.maps.Geocoder();
          for (var i=0;i<this.state.propertyDetails.length;i++){
           const address = this.state.propertyDetails[i].Address+', '+this.state.propertyDetails[i].City+', '+this.state.propertyDetails[i].StateCode+', '+this.state.propertyDetails[i].ZipCode
           const photo = this.state.propertyDetails[i].Photo
          geocoder.geocode({ 'address': address}, async function(results, status) {
          if (status == google.maps.GeocoderStatus.OK) {
              var latitude = results[0].geometry.location.lat();
              var longitude = results[0].geometry.location.lng();   
              community.push({'name':address,'lat':latitude,'long':longitude,'photo':photo});
              }   
         })
        }       
        await this.setState({locations: community})
        }.bind(this), 0);
        setTimeout(async function(){
            const _this = this;
            await _this.loadMultipleMarkers(1, community[0].lat, community[0].long);
          }.bind(this),1000)
      }
      componentDidMount(){
        AppController.removeSiteLoader();
      }
      async InitialData(){
        const propertyData = this.props.itineraryData;
        const propertyDetails = await DestinationService.getDetails(propertyData);
        await this.setState({propertyDetails:propertyDetails.recordset})
      }
      async loadMultipleMarkers(index, lat, long){
        const map = this.map = new google.maps.Map(document.getElementById('map'+index), {
          zoom: 10,
          center: new google.maps.LatLng(lat, long),
          mapTypeId: google.maps.MapTypeId.ROADMAP
        });
        this.setState({map});      
        var infowindow = new google.maps.InfoWindow();      
        var marker, i;
        const _this =this;
        for (i = 0; i < this.state.locations.length; i++) { 
          marker = new google.maps.Marker({
            position: new google.maps.LatLng(this.state.locations[i].lat, this.state.locations[i].long),
            icon: 'https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/SuiteAmerica-Destination-Services-Icon-Map-Marker-Red.png',
            label: (i+1).toString(),
            map: map
          });      
          google.maps.event.addListener(marker, 'click', (function(marker, i) {
            return function() {
              var image_name = 'https://s3-us-west-1.amazonaws.com/destination-services-itinerary/'+_this.state.locations[i].photo+'.jpg'
              var contentString = '<div id="content"><p><img width="300px" height="200px" src="' + image_name + '" alt=""/></p><p>'+_this.state.locations[i].name+'</p>'+'</div>';
              infowindow.setContent(contentString);
              infowindow.open(map, marker);
            }
          })(marker, i));
        }
      }
setLocation() {  
  const _this = this;
  const icon = 'https://s3-us-west-1.amazonaws.com/suiteam-website/Destination-Services/SuiteAmerica-Destination-Services-Icon-Map-Marker-Green.png';
      var places = new google.maps.places.Autocomplete(document.getElementById('pac-input'));
      google.maps.event.addListener(places, 'place_changed', function () {
          var place = places.getPlace();
          const newLat = place.geometry.location.lat();
          const newLng =  place.geometry.location.lng();
        let mark =  new google.maps.Marker({
            position: new google.maps.LatLng(newLat, newLng),
            icon: icon,
            map: _this.state.map
          });
          console.log(mark)
      });
}
render(){
 
    return(<div className="main-map-block">
    <Link to={'/destination-service/itinerary/'+ this.props.itineraryData.companyName+'/'+this.props.itineraryData.FileNum+ '/'+this.props.itineraryData.itineraryId }><div className="map-back" > <i className="fa fa-arrow-left"></i>
  <span className="font-blod">Back To Itinerary</span></div></Link>
  <div id="pac-container">
        <input id="pac-input" type="text" placeholder="Enter a location" 
        onChange={this.setLocation}/>
      </div>
         <div id="map1" style={{'width': '100%','height': '100vh'}}></div>
         <SiteLoader />
    </div>)
}
}

export default NoPricingMapView 