import AmenitesList from "./nikeamenitesList";
import WalkScore from "./walkscore";
import { Link } from 'react-router-dom';
import moment from 'moment'
const NikeCommunitys = (props) => {
    const { communitysList } = props;
    const booked = _.filter(communitysList, function (o) { return o.LinkMaster.IsGuestBooked == true });
    function AmenitiesRes(community, index) {
        const appartmentAmenities = community.AmenitiesList != null && community.AmenitiesList != undefined ? JSON.parse(community.AmenitiesList) : community.AmenitiesList
        const apparmentamenites = appartmentAmenities != null && appartmentAmenities != undefined ? _.filter(appartmentAmenities, function (o) {
            return o.communityname == 'Unit';
        }) : [];
        const communityamenites = appartmentAmenities != null && appartmentAmenities != undefined ? _.filter(appartmentAmenities, function (o) {
            return o.communityname == 'Community';
        }) : [];
        return <AmenitesList valueindex={props.valueindex} indexval={index} appartmentAmenities={apparmentamenites} communityAmenities={communityamenites} LinkMaster={community.LinkMaster} />
    }

    return (

        communitysList.map((community, index) => {
            return (
                <div className="accordion-view" key={index}>
                    <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                        <div className="accordion-item-tail panel panel-default">
                            <div className="tail-view">
                                <div className="image">
                                    <img src={community.banner_image} width="100%" alt="" />
                                </div>
                                <div className="text-view">
                                    <h4>{community.community_name}</h4>
                                    <h1>{community.address + ',' + community.city_name + ',' + community.state_name + ',' + community.ZipCode}</h1>
                                    <h2>Available From: <span>{(community.LinkMaster.MoveInReady != null) ? moment(community.LinkMaster.MoveInReady).format('MMMM DD,YYYY') : community.LinkMaster.MoveInDate}</span></h2>
                                    {(community.LinkMaster.Notes != '' && community.LinkMaster.Notes != null) ?
                                        <h3> <i className="fa fa-info-circle"></i>
                                            {"  " + community.LinkMaster.Notes}
                                        </h3> : null}
                                </div>
                                <div className="btn-view">
                                    {community.LinkMaster.IsGuestBooked && booked.length > 0 ?
                                        <p style={{ color: 'black' }}> You have already booked this suite</p> :
                                        <button type="button" className="btn" onClick={booked.length > 0 ? null : () => props.bookNow(community)} disabled={booked.length > 0 ? true : false}>Book Now</button>
                                    }
                                    <Link to={'/community/suiteamerica/' + community.community_name.replace(/ /g, "-")} target="_blank"><button type="button" className="btn">Know More</button>
                                    </Link>
                                </div>
                            </div>
                            {AmenitiesRes(community, index)}
                        </div>

                    </div>
                </div>
            )
        })



    )
}
export default NikeCommunitys;


