const SecondRowFields = props => {
const row= props.rowData
const manager=props.manager
return (row.OrderType=='H'? null:  <OrderContent rowData={row}  manager={manager}/> )
}
export default SecondRowFields;
const OrderContent= props => {
    const row= props.rowData
    const GSR=(row.GSR!=null && row.GSR!='' && row.GSR!=undefined )?row.GSR.split('/'):'';
    return (<div className="col-sm-4">
      <div className="sc-order-fl-item">
      {props.manager?<ul className="mrg-btm-20">
             <li><span className="font-bold" style={{color:'#dd2a5a'}}>End Time :</span> <span>{row.FivestarStopDateTime == undefined || row.FivestarStopDateTime == null ?'--':moment.utc(row.FivestarStopDateTime).format('YYYY-MM-DD hh:mm:ss A')} </span></li>
             </ul>:null}
       {row.TypeCode=="MAID"? <ul className="mrg-btm-20">
          <li><span className="font-bold">Pets Allowed:</span> <span style={{color: '#00000'}}>{row.petscount == 0? 'No' : 'Yes'}</span></li>
        </ul> : "" }
        <ul className="mrg-btm-20">
          <li><span className="font-bold">Wifi Name :</span> <span>{row.WiFiName == undefined || row.WiFiName == null ?'--':row.WiFiName}</span></li>
        </ul>
        <ul className="mrg-btm-20">
          <li><span className="font-bold">Wifi Password :</span> <span>{row.WiFiPassword == undefined || row.WiFiPassword == null?'--':row.WiFiPassword}</span> </li>
        </ul>
        <ul className="mrg-btm-20">
        <li><span className="font-bold">Gate Code :</span> <span>{row.GateCode == undefined || row.GateCode == null || row.GateCode ==''?'--':row.GateCode} </span></li>
        </ul>
        {row.ParkingSpace1 ==null || row.ParkingSpace1 ==''|| row.ParkingSpace1 ==undefined ? null:<ul className="mrg-btm-20">
          <li><span className="font-bold">Parking 1 : </span><span> {row.ParkingSpace1} </span></li>
        </ul>}
        {row.ParkingSpace2 ==null || row.ParkingSpace2 ==''  || row.ParkingSpace2==undefined ? null :<ul className="mrg-btm-20">
          <li><span className="font-bold">Parking 2 :</span><span> {row.ParkingSpace2}</span></li>
        </ul>}
        <ul className="mrg-btm-20">
          <li><span className="font-bold">Leasing Hours:</span></li>
          <li>{row.OfficeHoursXXX}</li>
        </ul>
        {row.TableId ==null ?'':
        
        <ul className="mrg-btm-20">
          <li><span className="font-bold">GSR:</span></li>
          <li><span className="font-bold">Name :</span> <span>{GSR ==''?'--':GSR[0]} </span></li>
          <li><span className="font-bold">Email :</span> <span>{GSR ==''?'--':GSR[2]} </span></li>
        </ul>}
      </div>       
</div>)
}