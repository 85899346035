/* Importing the node modules, child components, services and controllers used 
   inside SeavedSearch component */
import $ from "jquery";
import SubFooter from "../common/subFooter";
import AppController from "../controller/appController";
import GuestHeader from '../common/guestHeader';
import Footer from '../common/footer';
import EncryptDecrypt from '../../services/encryptDecrypt';
import LocationService from '../../services/locationService';
/* SeavedSearch Component initialization */
class SeavedSearch extends React.Component {
    /* Initializing objects of its SeavedSearch class */
    constructor(props) {
        super(props)
        /* SeavedSearch Component State variables Initialization */
        this.state = {
            favouritesData: [],
            savedSearchData: [],
            loader:true,
            userData:{},
        }
        this.moreInfo = this.moreInfo.bind(this);
        this.favouriteDelete = this.favouriteDelete.bind(this);
    }
    /* userid getting from localstorage 
       getting favourites by userid
       getting savedSearch Details  by userid */
    async favouritedetails() {
        const webGuest = localStorage.getItem('_u_web');
        const userData= await EncryptDecrypt.decryptData({'type': webGuest})
        console.log(userData,'userData')
        this.setState({userData:userData.recordset[0]})
        if(Object.keys(this.state.userData).length>0){
        const favourites = {
            userid: this.state.userData.Id,
            loadMore: "40"
        }
        const favouritesData = await LocationService.getFavouritesListById(favourites)
        const savedSearchData = await LocationService.getSavedSearch(favourites)
        this.setState({favouritesData:favouritesData, savedSearchData:savedSearchData,loader:false})  
    }
    }
    /* It is invoked immediately after a component is mounted */ 
    async componentDidMount() {
        AppController.removeLocalStorageData();
        this.favouritedetails();
            $(document).ready(function () {
                $('html, body').animate({scrollTop: 0});
                AppController.stickyTitles()
            });
    }
    /* Users favourite Community Delete */
    async favouriteDelete(data) {
        const removeFavourites = _.filter(this.state.favouritesData, function (o) {
            return o.community_id !== data.community_id;
          })
          this.setState({favouritesData:removeFavourites})
        const unfavouritedata = {
            user_id: this.state.userData.Id,
            favourite_id: data.favouriteid,
        }
         await LocationService.unFavouriteCommunityByUser(unfavouritedata)
    }
    /* More information about the savedSearch  */
    moreInfo(data) {
        location.href = '/locations'
        localStorage.setItem('savedSearch', JSON.stringify(data));
    }
    /* It is invoked to return html content */
    render() { 
        return (
            <div>
                <GuestHeader/>
                <div className="save_search">

                    <div className="ss_heading">
                        <div className="container">

                            <ul className="nav nav-tabs">
                                <li className="active">
                                    <a href="#sssearch" data-toggle="tab">SAVED SEARCHES</a>
                                </li>
                                <li>
                                    <a href="#sscommunity" data-toggle="tab">SAVED COMMUNITIES</a>
                                </li>
                            </ul>

                        </div>

                    </div>
                    {this.state.loader ? <div className="panel-body_loader" style={{height:'100px',}}> <span style={{fontSize: '30px', width: '100%', margin: 'auto', textAlign: 'center',lineHeight:'50px'}}><i className="fa fa-spinner fa-spin"/> please wait ...!</span>
                 
                         </div>:
                        
                    <div className="ss_body">
                        <div className="container">
                            <div id="myTabContent" className="tab-content">
                                <div className="tab-pane active in" id="sssearch">
                                {this.state.savedSearchData == 0 && <h1 style={{'textAlign': 'center','color': 'red'}}>No Results Found</h1>}
                                    {this
                                        .state
                                        .savedSearchData
                                        .map((row, index) => {
                                            let identifier=''
                                            if(row.state_name!=''){
                                                identifier=row.state_name
                                            }
                                            else if(row.address!='' ){
                                                identifier=row.address
                                            }
                                            else if(row.communityName!='' ){
                                                identifier=row.communityName
                                            }
                                            return (
                                                
                                                <div
                                                    className="ss_main"
                                                    key={index}
                                                    onClick={() => this.moreInfo(row)}
                                                    style={{
                                                    'cursor': 'pointer'
                                                }}>

                                                    <h2>Communities for rent in {identifier }</h2>
                                                    <ul >
                                                    {row.amenities_id !=''? <li>
                                                            <a >Ammenities</a>
                                                        </li>:null}
                                                   
                                                        <li>
                                                            <a >Pet Friendly</a>
                                                        </li>
                                                    </ul>
                                                </div>

                                            )
                                        })}

                                </div>
                                <div className="tab-pane fade" id="sscommunity">

                                    <div className="ss_main2">

                                        <div className="row">
                                            {this.state.favouritesData.map((row, index) => {
                                                    return (
                                                        <div className="col-md-4" key={index}>

                                                            <div className="ss_tail" >
                                                                <a
                                                                    style={{
                                                                    'cursor': 'pointer'
                                                                }}>
                                                                {
                            row.image_name !=null ? <img className="img-responsive"  src={row.image_name} alt=""/>:<img className="img-responsive"  src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Guest-Portal-Main-Location_Down.jpg"} alt=""/>
                          }
                                                              </a>
                                                                <button type="button" className="close btn" data-dismiss="modal" aria-label="" onClick={() => this.favouriteDelete(row)}>
                                                                <i className="bi bi-x"></i>
                                                                </button>
                                                                <h1>{row.community_name}</h1>
                                                                <p>{row.address}</p>

                                                            </div>

                                                        </div>

                                                    )
                                                })}

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
                <SubFooter/>
				<Footer/>	

            </div>
        );
    }
}
export default SeavedSearch;
