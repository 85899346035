import CommunityAnalysisModal from './communityAnalysisModal';
import { Link } from 'react-router-dom';
import ReactTable from "react-table";
const CommunityAnalysis = (props) => {

    const filterMethod = (filter, row) => {
        const id = filter.pivotId || filter.id;
        if (row[id] !== null && typeof row[id] === "string") {
            return (row[id] !== undefined
                ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
                : true);
        } else if (row[id] !== null && typeof row[id] === "number") {
            const str = String(row[id]);
            return (str !== undefined
                ? String(str.toLowerCase()).includes(filter.value.toLowerCase())
                : true);
        }
    }
    const { totalOrdersLoading, communityList, communityAnalysisCount } = props;
    return (
        <div className="col-md-12 mrg-btm-30 " id="communityanalysis">
            <ReactTable

                multiSort={true}
                loading={totalOrdersLoading}
                defaultFilterMethod={filterMethod}
                data={communityList}
                columns={[
                    {
                        Header: "Community",
                        id: 'community1',
                        accessor: row => (row.Community),
                        maxWidth: 400
                    },

                    {
                        Header: "Pending",
                        id: 'Pending Count',
                        style: { 'textAlign': 'center' },

                        accessor: row => (row.Pending.length),
                        Cell: row => (
                            <a href="#" data-toggle="modal" className={row.value == 0 ? "" : "ctd-tbl"} data-target={row.value == 0 ? "" : "#communityanalysisCountInfo"} onClick={row.value == 0 ? null : () => { communityAnalysisCount(row.row._original.Pending, 'PENDING') }}>
                                {(row.value).toString()}</a>
                        )

                    },

                    {
                        Header: "Active",
                        id: 'Active Count',
                        style: { 'textAlign': 'center' },

                        accessor: row => (row.Active.length),
                        Cell: row => (
                            <a href="#" data-toggle="modal" className={row.value == 0 ? "" : "ctd-tbl"} data-target={row.value == 0 ? "" : "#communityanalysisCountInfo"} onClick={row.value == 0 ? null : () => { communityAnalysisCount(row.row._original.Active, 'ACTIVE') }}>
                                {(row.value).toString()}</a>
                        )
                    },

                    {
                        Header: "Termed",
                        id: 'Termed Count',

                        style: { 'textAlign': 'center' },

                        accessor: row => (row.Termed.length),
                        Cell: row => (
                            <a href="#" data-toggle="modal" className={row.value == 0 ? "" : "ctd-tbl"} data-target={row.value == 0 ? "" : "#communityanalysisCountInfo"} onClick={row.value == 0 ? null : () => { communityAnalysisCount(row.row._original.Termed, 'TERMED') }}>
                                {(row.value).toString()}</a>
                        )
                    },


                ]}

                sortable={true}
                defaultPageSize={15}
                pageSizeOptions={[5, 10, 15, 20, 25, 50, 100, communityList.length]}
                style={{ height: "500px" }}
                className="-striped -highlight analsys_table community_Analysis"
                filterable={true}
            />
            <CommunityAnalysisModal {...props} />
        </div>
    )
}
export default CommunityAnalysis;
