/* Importing the node modules, child components, services and controllers used 
   inside LockheedAddedValue component */ 
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import LockheedHeader from "../../common/lockheedHeader";
   import FAQ from "./common/faq";
   import Lock from "./common/lock";
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   /* LockheedAddedValue Component initialization */
   class LockheedAddedValue extends React.Component {
       /* Initializing objects of its LockheedAddedValue class */
       constructor(props) {
           super(props);
           this.state={
               lock:Lock
           }
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
            MainController.getInitialInformation();
        }
       /* It is invoked to return html content */
       render() {
           const {lock}=this.state;
           return (
                <div className="corporateHousing_Updates">
                    <GuestHeader/>
                    <header className="codeconveyHeader cc_image_text_main corporate-h">
                        <img className="img-responsive" src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/lockheed/LANDING-PG_iStock-477921526.jpg" width="100%" alt=""  />
                        <div className="wow fadeInUp img-on-text top50 width600 text-center img-on-text-center img-on-text-center-w" data-wow-delay="0.5s">
                            <h1 className="lockheed-color">Added Value</h1>
                        </div>
                    </header>
                    <LockheedHeader />
                    <div className="who_weare_text service_ch_new company_p">
                        <div className="container">
                            <h1 className="wow fadeInUp" data-wow-delay="0.5s">PERSONAL TRANSITION SERVICES</h1>
                            <p className="wow fadeInUp col-md-10 col-md-offset-1" data-wow-delay="0.5s">Offering a wide array of complimentary and concierge services to assist today’s business traveler, and 
                                ensure the transition to your corporate suite or extended stay apartment is smooth and stress free.
                            </p>
                            <div className="row">
                                <div className="col-md-6 col-sm-6">
                                <div className="dual_quote dual_quote_lg wow fadeInUp" data-wow-delay="0.5s">
                                    <h3>COMPLIMENTARY SERVICES INCLUDE:</h3>
                                    <ul>
                                    {lock.complimentary.map((row, i) => {
                             return( <li key={i}>{row}</li>)
                         })}
                                    </ul>
                                </div>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                <div className="dual_quote dual_quote_lg wow fadeInUp" data-wow-delay="0.5s">
                                    <h3>CONCIERGE SERVICES INCLUDE:</h3>
                                    <ul>
                                    {lock.concierge.map((row, i) => {
                             return( <li key={i}>{row}</li>)
                         })}
                                        <li><span>*Additional fees may apply / Available in select areas</span></li>
                                    </ul>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="services_ch">
                        <div className="carousel banner_text offer_img_company">
                            <img className="img-responsive" src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/lockheed/HANDSHAKE.jpg" alt="" />
                            <div className="img-on-text width600 left30 top25 text-center img-on-text-center wow fadeInUp" data-wow-delay="0.5s">
                                <h2 className="animated lockheed-color">SUITEAMERICA OFFERS MEET AND GREET ORIENTATIONS</h2>
                            </div>
                        </div>
                    </div>
                    <div className="who_weare_text service_ch_new company_p">
                        <div className="container">
                            <h1 className="wow fadeInUp" data-wow-delay="0.5s">MEET AND GREET</h1>
                            <p className="wow fadeInUp" data-wow-delay="0.5s">We will meet with your employees and assist with any of the following:</p>
                            <div className="row">
                                <div className="col-md-6 col-sm-6">
                                <div className="dual_quote dual_quote_lg wow fadeInUp" data-wow-delay="0.5s">
                                    <h3>REVIEW THE BASIC OPERATIONS OF:</h3>
                                    <ul>
                                    {lock.review.map((row, i) => {
                             return( <li key={i}>{row}</li>)
                         })}
                                    </ul>
                                </div>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                <div className="dual_quote dual_quote_lg wow fadeInUp" data-wow-delay="0.5s">
                                    <h3>COMMUNITY TOURS ALSO AVAILABLE TO INCLUDE:</h3>
                                    <ul>
                                    {lock.community.map((row, i) => {
                             return( <li key={i}>{row}</li>)
                         })}
                                        <li><span>*Additional fees may apply</span></li>
                                    </ul>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="services_ch">
                        <div className="carousel banner_text offer_img_company">
                            <img className="img-responsive" src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/lockheed/GROCERY_iStock-836782690.jpg" alt="" />
                            <div className="img-on-text width600 left30 top25 text-center img-on-text-center wow fadeInUp" data-wow-delay="0.5s">
                                <h2 className="animated lockheed-color">SUITEAMERICA OFFERS VARIED GROCERY PACKAGES</h2>
                            </div>
                        </div>
                    </div>
                    <div className="who_weare_text service_ch_new company_p">
                        <div className="container">
                            <h1 className="wow fadeInUp" data-wow-delay="0.5s">GROCERY PACKAGES</h1>
                            <p className="wow fadeInUp" data-wow-delay="0.5s">Have the following waiting for you when you arrive.</p>
                        </div>
                    </div>
                    <div className="container-fluid nike guest_sitenew">
                        <div className="container intra_staff2 col-sm-8 col-sm-offset-2">
                            <div id="accordion" className="panel-group mrg-top-70 mrg-btm-50 company_acdn"  role="tablist" aria-multiselectable="true">
                                <div className="panel panel-default">
                                <div className="panel-heading"  >
                                    <h4 className="panel-title" >
                                        <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#starter-grocery" aria-expanded="false" aria-controls="collapse2">
                                        Starter Grocery: $115.00
                                        </a>
                                    </h4>
                                </div>
                                <div id="starter-grocery" className="panel-collapse collapse">
                                    <div className="panel-body">
                                        <div className="profile-opener">
                                            <div className="row">
                                            <div className="col-sm-4">
                                                <p className="acrd_list_p">Breakfast</p>
                                                <ul className="acrd_list">
                                                {lock.breakfast.map((row, i) => {
                             return( <li key={i}>{row}</li>)
                         })}
                                                </ul>
                                            </div>
                                            <div className="col-sm-4">
                                                <p className="acrd_list_p">Lunch</p>
                                                <ul className="acrd_list">
                                                {lock.lunch.map((row, i) => {
                             return( <li key={i}>{row}</li>)
                         })}
                                                </ul>
                                            </div>
                                            <div className="col-sm-4">
                                                <p className="acrd_list_p">Dinner</p>
                                                <ul className="acrd_list">
                                                {lock.dinner.map((row, i) => {
                             return( <li key={i}>{row}</li>)
                         })}
                                                </ul>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-sm-4">
                                                <p className="acrd_list_p">Beverages</p>
                                                <ul className="acrd_list">
                                                {lock.beverages.map((row, i) => {
                             return( <li key={i}>{row}</li>)
                         })}
                                                </ul>
                                            </div>
                                            <div className="col-sm-4">
                                                <p className="acrd_list_p">Staples</p>
                                                <ul className="acrd_list">
                                                {lock.staples.map((row, i) => {
                             return( <li key={i}>{row}</li>)
                         })}
                                                </ul>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div className="panel panel-default">
                                <div className="panel-heading">
                                    <h4 className="panel-title">
                                        <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#asian-grocery" aria-expanded="true" aria-controls="collapse3">
                                        Asian Grocery: $115.00
                                        </a>
                                    </h4>
                                </div>
                                <div id="asian-grocery" className="panel-collapse collapse">
                                    <div className="panel-body">
                                        <div className="profile-opener">
                                            <div className="row">
                                            <div className="col-sm-4">
                                                <ul className="acrd_list">
                                                {lock.line1.map((row, i) => {
                             return( <li key={i}>{row}</li>)
                         })}
                                                </ul>
                                            </div>
                                            <div className="col-sm-4">
                                                <ul className="acrd_list">
                                                {lock.line2.map((row, i) => {
                             return( <li key={i}>{row}</li>)
                         })}
                                                </ul>
                                            </div>
                                            <div className="col-sm-4">
                                                <ul className="acrd_list">
                                                {lock.line3.map((row, i) => {
                             return( <li key={i}>{row}</li>)
                         })}
                                                </ul>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid bg-grey">
                        <div className="container">
                            <FAQ />
                        </div>
                    </div>
                    <SubFooter/>
                    <Footer/>
                </div>

           );
       }
   }
   export default LockheedAddedValue;