/* Importing the node modules, child components, services and controllers used 
   inside NikeHome component */    
   import $ from "jquery";
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   /* NikeHome Component initialization */
   class DeanBuildingB extends React.Component {
       /* Initializing objects of its NikeHome class */
       constructor(props) {
           super(props);
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
           MainController.getInitialInformation();
        }
       /* It is invoked to return html content */
       render() {
           return (
            <div className="corporateHousing_Updates telsa-page-view">
            <GuestHeader/>

            <div className="latch_web latch_webHeader">

            <div className="container">

<h1 className="text-center m-t-40 m-b-30 p-b-10">The Dean Arrival Instructions - Building B</h1>

<div className="latch_web2_sec borderred-box">
  <img src="/assets/newUI-images/latchimages/Latch3-1.png" alt="" width="100%" />
  <div className="item">
    <h2> Parking & Finding Elevators</h2>
    <p>You will be using the parking garage entrance located off Miller Ave. The garage is across the street from
      Bank of America.
    </p>
    <p>You will drop down into the first level. We suggest parking there in Guest Parking until you have your
      garage
      clicker, which is in your suite.
    </p>
    <p>Find your way to the pedestrian gate closest to where you entered the garage off Miller Ave.
      The elevators will be on the right along the <span className="yellow">yellow wall</span>. Once in the
      elevator, you will use your Latch app
      to access your floor. Learn how to use Latch <a href="/latch" target="_blank">here</a>.</p>
  </div>
</div>
<div className="latch_web2_sec2">
  <div className="item">
    <p><i>The Bank of America outside of the parking garage.</i></p>
    <div className="borderred-box">
      <img src="/assets/newUI-images/latchimages/Latch3-2.png" alt="" width="100%" />
    </div>
  </div>
  <div className="item">
    <p><i>The elevators that lead to the building.</i></p>
    <div className="borderred-box">
      <img src="/assets/newUI-images/latchimages/Latch3-3.png" alt="" width="100%" />
    </div>
  </div>
</div>

<div className="latch_web_sec3 buildingB borderred-box m-b-30">
  <div className="item">
    <img src="/assets/newUI-images/latchimages/Latch2-4.png" alt="" width="100%" />
  </div>
  <div className="item">
    <h2>Finding Your Assigned Parking Space</h2>
    <p>Your garage clicker is located in your suite. Once you get into your suite, you will find it in the Welcome
      packet materials.
    </p>
    <p>This garage clicker will get you into level B2 in the parking garage. B2 parking is in same garage located
      on Miller Ave.
    </p>
    <p>Continue all the way down the garage until you can only make a right. You will see a silver roll up gate.
    </p>
    <p>To enter, press and hold "1" on your clicker for about 5 seconds. Then, proceed to your assigned parking
      space.</p>
  </div>
</div>





</div>


  </div>
  </div>
           );
       }
   }
   export default DeanBuildingB;