/* Importing the node modules, child components, services and controllers used 
   inside Footer component */
   import {Link} from 'react-router-dom';
   import MainController from '../controller/mainController';  
   /* Footer Component initialization */
   class Footer extends React.Component{
       /* Initializing objects of its Footer class */
       constructor(props) {
           super(props);
           /* Footer Component State variables Initialization */
           this.state = {
               currentYear: new Date().getFullYear()
           }   
       }
       /* It is invoked immediately after a component is mounted */
       componentDidMount() {
           MainController.removeClassOnNavCollapse();   
       }
   /* It is invoked to return html content */      
   render(){       
       return(
        <div>
            <div className="inet_footer">
                <div className="container">
                    <ul>
                        <li><a href="https://www.youtube.com/user/SuiteAmerica?feature=mhum" target='_blank'><i className="fa fa-youtube-play"></i></a></li>
                        <li><a href="https://www.facebook.com/SuiteAmerica" target='_blank'><i className="fa fa-facebook"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/108459?trk=saber_s000001e_1000" target='_blank'><i className="fa fa-linkedin-square"></i></a></li>
                    </ul>
                    <ul>
                        <li><Link to="/intranet-form">FORMS</Link></li>
                        <li><Link to="/intranet-order-process">MATERIALS</Link></li>
                        <li><Link to="#">NEWS</Link></li>
                        <li><Link to="#">CALENDAR</Link></li>
                    </ul>
                </div>
            </div>
            <div className="inet_footer_bottom">
            <p>Copyright ©{this.state.currentYear} SuiteAmerica | All rights reserved</p>
            </div>
        </div>
       );
     }
   }
   export default Footer;