/* Importing the node modules, child components, services and controllers used 
   inside NikeWhatsIncluded component */

import MainController from "../../controller/mainController";
import SubFooter from "../../common/subFooter";
import LinkedinInternsHeader from "./common/LinkedinInternsHeader";
import FAQ from "./common/LinkedinInternsFaq";
import GuestHeader from "../../common/guestHeader";
import Footer from "../../common/footer";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Disclaimer from "./common/disclimar";
import SubFooterLinkedIn from "./common/subFooterlinkedIn";
/* NikeWhatsIncluded Component initialization */
class LinkedinInternsWhatsIncluded extends React.Component {
  /* Initializing objects of its NikeWhatsIncluded class */
  constructor(props) {
    super(props);
    this.generateAccordianItem = this.generateAccordianItem.bind(this);
  }
  /* It is invoked immediately before mounting occurs */
  componentWillMount() {
    MainController.getInitialInformation();
  }

  generateAccordianItem(item, index) {
    console.log(item);
    return (
      <div className="panel panel-default">
        <div className="panel-heading">
          <h4 className="panel-title">
            <a
              className="collapsed"
              data-toggle="collapse"
              data-parent="#accordion"
              href={`#accordian-index-${index}`}
              aria-expanded="false"
              aria-controls="collapse2"
            >
              {item[0]}
            </a>
          </h4>
        </div>
        <div
          id={`accordian-index-${index}`}
          className="panel-collapse collapse"
        >
          <div className="panel-body">
            <div className="profile-opener">{item[1]}</div>
          </div>
        </div>
      </div>
    );
  }

  /* It is invoked to return html content */
  render() {
    const accordionItems = [
      [
        "What is corporate housing?",
        "SuiteAmerica provides temporary housing solutions on a short term basis for relocating employees, extended travelers, and interns in apartment communities convenient to your employer.",
      ],
      [
        "Are the apartments furnished or unfurnished?",
        "SuiteAmerica offers flexible solutions to meet every budget. Whether you’re looking for an unfurnished apartment or fully furnished all-inclusive accessorized apartment, SuiteAmerica can help.",
      ],
      [
        "What is included in the housing package? Will I need to bring my own linens and towels?",
        "Our unfurnished apartments include rent, & utilities (electric/gas/water/sewer/trash). You will be responsible for providing your own furnishings, housewares, and linens.\n\nOur fully furnished all-inclusive apartments include rent, furniture, housewares, WIFI, & utilities (electric/gas/water/sewer/trash). Click here to view our all-inclusive package.",
      ],
      [
        "What is NOT covered under the housing package?",
        "Telephone phone service, cable TV, and housekeeping services are NOT included.\nDamages and incidentals are the responsibility of interns.",
      ],
      [
        "Do I need renters insurance?",
        "Renters Insurance is NOT included with your reservation but we highly recommend adding it. The cost is only $1.75 per day to add renters insurance which Includes $50,000 of personal property insurance and up to $15,000 of accidental damage insurance.",
      ],
      [
        "Where are the apartments?",
        <>
          Our apartment communities are located within a 15 mile radius to your
          employer. To view sample locations{" "}
          <a href="/company/LinkedIn-interns/locations" target="blank">
            click here.
          </a>
        </>,
      ],
      [
        "How big are the apartments?",
        "One bedroom one bath apartments average 650 square feet. Two bedroom two bath apartments average 1000 square feet. All apartments have full size kitchens with full size appliances.",
      ],
      [
        "How many roommates will I have?",
        "The choice is yours. Most interns will request a two bedroom two bath apartment to share with 4 interns (dorm style) to save costs. One bedroom apartments can accommodate 1-2 interns. Two bedroom apartments can accommodate 2-4 interns.",
      ],
      [
        "Can I request my own roommate(s)?",
        "Yes, we’d love you to request/choose your own roommates. Simply let us know on the questionnaire who you would like to share with.",
      ],
      [
        "How are roommates matched?",
        "Roommate are typically matched by gender, arrival date, and preferences. Know another intern you’d like to roommate with – let us know!",
      ],
      [
        "Will I be matched with a roommate of the opposite gender?",
        "The choice is yours. Please make sure to indicate your rooming preferences on the rooming request form.",
      ],
      [
        "When will I be notified of my roommate matches?",
        "We will begin matching roommates as soon as there are enough interns signed up for matching. You should hear from us at least 30-45 days prior to your start date.",
      ],
      [
        "What happens after my roommates and I are matched?",
        "Once you are matched with other roommates you will be introduced to each other and presented with an available housing options.",
      ],
      [
        "When will I receive confirmation of my new apartment address?",
        "We will notify you and your roommates of the available housing options 30-45 days prior to your arrival date. Once you and your roommates agree to share an apartment, a reservation agreement will be sent to you all. Once signed, you will receive confirmation with your address.",
      ],
      [
        "How does the pricing work?",
        "The pricing is a fixed rate per intern based on the size of apartment and number of roommates. All rent is divided evenly and all reservations will start and end on the same day.",
      ],
      [
        "Is there a deposit required?",
        "There is a $199 non refundable deposit which will go toward your first month’s rent.",
      ],
      [
        "When is rent due and when does the lease start?",
        "The lease will start and end at the same time for every roommate. Rent is divided equally between roommates and rent for the first month’s rent is due up front and prior to move in. Rent is due on the first of the month thereafter.",
      ],
      [
        "Is parking included in the rental?",
        "Every apartment comes with 1 assigned parking space and additional parking is extremely limited.",
      ],
      [
        "Once I am matched and sign the confirmation with my roommates, can I cancel?",
        "No, once you sign the confirmation along with your roommates – you cannot cancel. You will be obligated for your portion of the rent for the full duration of the reservation.",
      ],
    ];
    return (
      <div className="corporateHousing_Updates">
        <GuestHeader />

        <LinkedinInternsHeader />
        <div className="pt-4">
          <div className="container  intra_staff2 col-sm-8 col-sm-offset-2 py-3">
            <div
              className="text-left col-md-10 col-md-offset-1 wow fadeInUp company_p SAweb_updateUi bg-white"
              data-wow-delay="0.5s"
            >
              <p className="p fw-700">What's Included</p>
              <p className="p fw-300">
                {" "}
                Welcome to SuiteAmerica! Our apartments are designed to provide
                you with a comfortable and convenient stay. Our fully furnished
                apartments include the following items.
              </p>
            </div>
          </div>
          {/* <div className="container-fluid nike guest_sitenew">
               <div className="container intra_staff2 col-sm-8 col-sm-offset-2">
                 <div
                   id="accordion"
                   className="panel-group mrg-top-70 mrg-btm-50 company_acdn"
                   role="tablist"
                   aria-multiselectable="true"
                 >
                   {accordionItems.map((item, index) =>
                     this.generateAccordianItem(item, index)
                   )}
                 </div>
               </div>
             </div>
             <div className="container">
               <p>
                 Due to the high volume of interns coming to the area this summer,
                 housing options are limited and available on a first come first
                 serve basis. We recommend responding to all emails as soon as
                 possible and securing reservations as soon as options come
                 available.{" "}
               </p>
             </div> */}
        </div>
        <div class="quote-section bg-white">
          <div class="container"></div>
        </div>
        <div className="container">
          <div className="teals-foot-navbar">
            <div className="row tesla-row">
              <div className="col-md-3  ">
                <span className="fw-500">BEDROOM</span>
                <ul>
                  <li>
                    <a href="#">Queen size bed fitted with sheets,</a>
                  </li>
                  <li>
                    <a href="#">Pillows, duvet and down comforter</a>
                  </li>
                  <li>
                    <a href="#">Nightstand</a>
                  </li>
                  <li>
                    <a href="#">Dresser</a>
                  </li>
                  <li>
                    <a href="#">Mirror</a>
                  </li>
                  <li>
                    <a href="#">Hangers</a>
                  </li>
                  <li>
                    <a href="#">Alarm clock</a>
                  </li>
                  <li>
                    <a href="#">Lamp</a>
                  </li>
                  <li>
                    <a href="#">TV</a>
                  </li>
                </ul>
              </div>
              <div className="col-md-3  ">
                <span className="fw-500">BATHROOM</span>
                <ul>
                  <li>
                    <a href="#">Four bath, hand, and face towels</a>
                  </li>
                  <li>
                    <a href="#">Shower curtain and rug</a>
                  </li>
                  <li>
                    <a href="#">Tooth brush holder</a>
                  </li>
                  <li>
                    <a href="#">Soap dispenser</a>
                  </li>
                  <li>
                    <a href="#">Toilet brush and plunger</a>
                  </li>
                </ul>
              </div>
              <div className="col-md-3  ">
                <span className="fw-500">KITCHEN</span>
                <ul>
                  <li>
                    <a href="#">Basic cookware essentials</a>
                  </li>
                  <li>
                    <a href="#">Coffee maker</a>
                  </li>
                  <li>
                    <a href="#">Toaster</a>
                  </li>
                  <li>
                    <a href="#">Dish Towels</a>
                  </li>
                  <li>
                    <a href="#">Dinnerware</a>
                  </li>
                  <li>
                    <a href="#">Glassware</a>
                  </li>
                  <li>
                    <a href="#">Utensils</a>
                  </li>
                </ul>
              </div>
              <div className="col-md-3  ">
                <span className="fw-500">LIVING AND DINING ROOM</span>
                <ul>
                  <li>
                    <a href="#">Sofa</a>
                  </li>
                  <li>
                    <a href="#">Coffee table</a>
                  </li>
                  <li>
                    <a href="#">Dining table and chairs</a>
                  </li>
                  <li>
                    <a href="#">Lamp</a>
                  </li>
                  <li>
                    <a href="#">End table</a>
                  </li>
                  <li>
                    <a href="#">TV</a>
                  </li>
                </ul>
              </div>
              <div className="col-md-3  ">
                <span className="fw-500">UTILITIES</span>
                <ul>
                  <li>
                    <a href="#">Basic Cable/Streaming TV</a>
                  </li>
                  <li>
                    <a href="#">High Speed Wireless Internet</a>
                  </li>
                  <li>
                    <a href="#">Water/Gas/Electric</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Disclaimer />
        {/* <div className="pad-top-60"> */}
          {/* <SubFooter /> */}
          <SubFooterLinkedIn />
        {/* </div> */}
        <Footer />
      </div>
    );
  }
}
export default LinkedinInternsWhatsIncluded;
