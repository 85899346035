import OwlCarousel from 'react-owl-carousel';
import Rater from 'react-rater';
import ContentLoader from 'react-content-loader';
const Poi = (props) => {
   const poi = _.filter(props.poi, function (o) {
      return o.photos != undefined;
   })
   return (
      <div className="container cityland_con interest_points mrg-top-50">
         <h4>Points Of Interests</h4>
         <OwlCarousel {...{ loop: false, margin: 5, nav: true, dots: true, items: 1, navContainerClass: 'owl-buttons', navText: poi.length > 3 || props.width < 900 ? ["<i class='glyphicon glyphicon-menu-left'></i>", "<i class='glyphicon glyphicon-menu-right'></i>"] : ['', ''], autoheight: 'true', refreshClass: 'owl-refresh', loadingClass: 'owl-loaded', responsive: { 320: { items: 1, nav: true, loop: false }, 767: { items: 2, nav: true, loop: false }, 992: { items: 2, nav: true, loop: false }, 1199: { items: 4, nav: true, loop: false, } } }}>
            {poi != undefined ? poi.map((row, index) => {
               return (
                  <div key={index}>
                     <div className="text-center interest_item">
                        <h2>{row.name}</h2>
                        <div className="interestimg">
                           <img src={"https://maps.googleapis.com/maps/api/place/photo?maxwidth=1000&photoreference=" + row.photos[0].photo_reference + "&key=AIzaSyDiSo4yN97tS6CIfMqJCXItYCwgzVkIJVc"} className="img-responsive" alt="" />
                        </div>
                        <div className="interest_item_dtls">
                           <ul className="list-inline">
                              <li>
                                 {row.rating}
                                 <Rater rating={row.rating} total={5} interactive={false} />
                              </li>
                              <li>({row.user_ratings_total})</li>
                           </ul>
                           <p className="mrg-btm-5 mrg-top-5 con_head">{row.formatted_address}</p>
                           <a href={'https://www.google.com/maps/place/' + row.formatted_address} target="blank" className="btn btn-default mrg-btm-20">Get Directions</a>
                        </div>
                     </div>
                  </div>
   )
            }) : <ContentLoader></ContentLoader>}</OwlCarousel>
      </div>
   )
}
export default Poi;
