import Buttons from "./buttons";
const WhatsInclude=(props)=>{
    return(
        <div>
           	<div className="container">
				<div className="bungalowmaincontent">
					<h1>{props.whatsInclude.heading}</h1>
					<p>{props.whatsInclude.Paragraph}</p>
                    <div className="row tumb_deatils">
                              {props.whatsInclude.data.map((row,index)=>{
            return(
                <div className="col-md-6" key={index}>
								<div className="tumb">
									<img src={row.Image} className="width100per" />
									<div className="tumb_text">
                                <h2>{row.Name}</h2>
                                <p>{row.Paragraph}</p>
									</div>
								</div>
						</div>
                         )})}
                            </div>
						</div>
			
			</div>
			<Buttons Button={'VIEW LISTINGS'}/>
        </div>
    )
    }
export default WhatsInclude;