import GuestHeader from "../../common/guestHeader";
import Footer from "../../common/footer";
import SubFooter from "../../common/subFooter";

class Byerley extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };


  }
  async componentWillMount() {
    location.href = 'https://my.matterport.com/show/?m=SdouWYwNEbT'
  }

  render() {
    const url = this.props.match.path;
    return (
      <div>
        <GuestHeader />
        <div className="deanpage">
          <div className="container">
            <div className='text-center'
              style={{ fontSize: '25px', paddingTop: "160px", height: '400px', width: '100%', margin: 'auto', textAlign: 'center', color: 'black' }}>
              <i className="fa fa-spinner fa-spin" /> Please wait ...!</div>
          </div>
        </div>
        <SubFooter />
        <Footer />
      </div>
    );
  }
}
export default Byerley;
