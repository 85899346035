/* Importing the node modules, child components, services and controllers used 
   inside PTSImpression component */ 
   import $ from "jquery";
   import AppController from "../../controller/appController";
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import NikeServices from '../../../services/nikeService';
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   import ReCAPTCHA from "react-google-recaptcha"
   import SuccessModal from '../../common/successModal';
   /* PTSImpression Component initialization */
   class PTSImpression extends React.Component {
       /* Initializing objects of its PTSImpression class */
       constructor(props) {
           super(props)
           this.state={
               CaptchaValue:'',
               chooseOne: 'Standard',
               sendResult: 'Meet & Greet',
               ptsRepresentative: '',
               dateOfMeet: '',
               communityName: '',
               guestName: '',
               dsGuest: '',
               companyName: '',
               ptsComments: '',
               scheduleTimeOfMeet: '',
               guestArrivalTime: '',
               dsConsultant: '',
               confirmationNumber: '',
               guestComments: '',
               taskComments: '',
               checkbox1: '',
               checkbox2: '',
               checkbox3: '',
               checkbox4: '',
               checkbox5: '',
               checkbox6: '',
               checkbox7: '',
               checkbox8: '',
               checkbox9: '',
               checkbox10: '',
               checkbox11: '',
               rating: '',
               message: '',
               color:'',
               errorMessage:'',
               activeIndex: -1
           }
           this.setValue = this.setValue.bind(this);
           this.checkboxValue = this.checkboxValue.bind(this);
           this.setChecked = this.setChecked.bind(this);
           this.rating = this.rating.bind(this);
           this.clearData = this.clearData.bind(this);
           this.serviceRequest = this.serviceRequest.bind(this);
           this.onChangeCaptcha = this.onChangeCaptcha.bind(this);
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
           MainController.getInitialInformation();
        }
        /* Form Details object creations  */

      setValue(field, e) {
            var object = {};
            object[field] = e.target.value;
            this.setState(object);
          }
      /* Form Details object creations  */

      setChecked(e) {
        this.setState({dsGuest:e.target.value});
      }   
      checkboxValue(field, e){
        var object = {};
        object[field] = e.target.checked ? "Yes" : "No";
        this.setState(object);
      }
      rating(index){
          this.setState({activeIndex: index, rating: index+1});
      }
      clearData(){
        this.setState({
               CaptchaValue:'',
               chooseOne: 'Standard',
               sendResult: 'Meet & Greet',
               ptsRepresentative: '',
               dateOfMeet: '',
               communityName: '',
               guestName: '',
               dsGuest: '',
               companyName: '',
               ptsComments: '',
               scheduleTimeOfMeet: '',
               guestArrivalTime: '',
               dsConsultant: '',
               confirmationNumber: '',
               guestComments: '',
               taskComments: '',
               rating: '',
               activeIndex: -1,
               checkbox1: '',
               checkbox2: '',
               checkbox3: '',
               checkbox4: '',
               checkbox5: '',
               checkbox6: '',
               checkbox7: '',
               checkbox8: '',
               checkbox9: '',
               checkbox10: '',
               checkbox11: ''        
 
        })
        $('input[name="radio"]').prop('checked', false);
        $('input[name="checkbox1"]').prop('checked', false);
        $('input[name="checkbox2"]').prop('checked', false);
        $('input[name="checkbox3"]').prop('checked', false);
        $('input[name="checkbox4"]').prop('checked', false);
        $('input[name="checkbox5"]').prop('checked', false);
        $('input[name="checkbox6"]').prop('checked', false);
        $('input[name="checkbox7"]').prop('checked', false);
        $('input[name="checkbox8"]').prop('checked', false);
        $('input[name="checkbox9"]').prop('checked', false);
        $('input[name="checkbox10"]').prop('checked', false);
        $('input[name="checkbox11"]').prop('checked', false);
        AppController.menuTopNavigation('serviceRequest');
      }
    
       /* Service Request Details send to mail  */
      
       async serviceRequest(){
           const _this = this;
        const validationFields=['CaptchaValue']
        let error = 0;
        _.forEach(validationFields, function(value) {
           if(_this.state[value] == ""){
             error++;
           }
       });
      if(error == 0){
            const Request={
               CaptchaValue:this.state.CaptchaValue,
               chooseOne: this.state.chooseOne,
               sendResult: this.state.sendResult,
               ptsRepresentative: this.state.ptsRepresentative == '' ? '---' : this.state.ptsRepresentative,
               dateOfMeet: this.state.dateOfMeet == '' ?'---' : this.state.dateOfMeet,
               communityName: this.state.communityName == '' ? '---' : this.state.communityName,
               guestName: this.state.guestName == '' ? '---' : this.state.guestName,
               dsGuest: this.state.dsGuest == '' ? '---' : this.state.dsGuest,
               companyName: this.state.companyName == '' ? '---' : this.state.companyName,
               ptsComments: this.state.ptsComments == '' ? '---' : this.state.ptsComments,
               scheduleTimeOfMeet: this.state.scheduleTimeOfMeet == '' ? '---' : this.state.scheduleTimeOfMeet,
               guestArrivalTime: this.state.guestArrivalTime == '' ? '---' : this.state.guestArrivalTime,
               dsConsultant: this.state.dsConsultant == '' ? '---' : this.state.dsConsultant,
               confirmationNumber: this.state.confirmationNumber == '' ? '---' : this.state.confirmationNumber,
               guestComments: this.state.guestComments == '' ? '---' : this.state.guestComments,
               taskComments: this.state.taskComments == '' ? '---' : this.state.taskComments,
               rating: this.state.rating == '' ?  '---' : this.state.rating,
               checkbox1: this.state.checkbox1 == '' ? '---' : this.state.checkbox1,
               checkbox2: this.state.checkbox2 == '' ? '---' : this.state.checkbox2,
               checkbox3: this.state.checkbox3 == '' ? '---' : this.state.checkbox3,
               checkbox4: this.state.checkbox4 == '' ? '---' : this.state.checkbox4,
               checkbox5: this.state.checkbox5 == '' ? '---' : this.state.checkbox5,
               checkbox6: this.state.checkbox6 == '' ? '---' : this.state.checkbox6,
               checkbox7: this.state.checkbox7 == '' ? '---' : this.state.checkbox7,
               checkbox8: this.state.checkbox8 == '' ? '---' : this.state.checkbox8,
               checkbox9: this.state.checkbox9 == '' ? '---' : this.state.checkbox9,
               checkbox10: this.state.checkbox10 == '' ? '---' : this.state.checkbox10,
               checkbox11: this.state.checkbox11 == '' ? '---' : this.state.checkbox11,
                mail:'pts',
                subject: 'Personal Transition Services SuiteAmerica Meet & Greet'
            }
    
        this.setState({
            CaptchaValue:'',
               chooseOne: 'Standard',
               sendResult: 'Meet & Greet',
               ptsRepresentative: '',
               dateOfMeet: '',
               communityName: '',
               guestName: '',
               dsGuest: '',
               companyName: '',
               ptsComments: '',
               scheduleTimeOfMeet: '',
               guestArrivalTime: '',
               dsConsultant: '',
               confirmationNumber: '',
               guestComments: '',
               taskComments: '',
               rating: '',
               activeIndex: -1,
               checkbox1: '',
               checkbox2: '',
               checkbox3: '',
               checkbox4: '',
               checkbox5: '',
               checkbox6: '',
               checkbox7: '',
               checkbox8: '',
               checkbox9: '',
               checkbox10: '',
               checkbox11: '',
            color:'',
            errorMessage:'',           
 
        })

        
        $('input[name="radio"]').prop('checked', false);
        $('input[name="checkbox1"]').prop('checked', false);
        $('input[name="checkbox2"]').prop('checked', false);
        $('input[name="checkbox3"]').prop('checked', false);
        $('input[name="checkbox4"]').prop('checked', false);
        $('input[name="checkbox5"]').prop('checked', false);
        $('input[name="checkbox6"]').prop('checked', false);
        $('input[name="checkbox7"]').prop('checked', false);
        $('input[name="checkbox8"]').prop('checked', false);
        $('input[name="checkbox9"]').prop('checked', false);
        $('input[name="checkbox10"]').prop('checked', false);
        $('input[name="checkbox11"]').prop('checked', false);

        AppController.menuTopNavigation('serviceRequest');
        jQuery(function($) {
            $('#successModal').modal('show');
            setTimeout(function(){
              $('#successModal').modal('hide');
            },4000);
          });
        await NikeServices.serviceRequest(Request)        
        }
        else{
            AppController.menuTopNavigation('serviceRequest');
            this.setState({errorMessage:'Please Select Captcha',color:'red'})
        }
       }
      
       async onChangeCaptcha(CaptchaValue) {
        await this.setState({CaptchaValue})
        }

       /* It is invoked to return html content */
       render() {
           return (
            <div className="corporateHousing_Updates" id="serviceRequest">
                <GuestHeader/>
                <div className="cover-tophd-ht"></div>
                <div className="container-fluid nike-datepicker bg-grey">
                    <div className="row">
                        <div className="container">
                            <div className="pts-smg-wrap">
                            <h1 className=""><i>Personal Transition Services <br/> SuiteAmerica Meet & Greet</i></h1>
                            <p>Distribution: Community Information & Sitemaps | WIFI Trouble shooting</p>
                            </div>
                            <div className="service_request">
                            <div className="request_form company_form" >
                                <form className="well">
                                    <div className="pts-smg-head-form">
                                        <label className="custom-select">
                                        <select value={this.state.chooseOne} 
                                        onChange={(e) => this.setValue('chooseOne', e)}>                                          
                                            <option value="Please Choose One ..." disabled>Please Choose One ...</option>
                                            <option value="Standard">Standard</option>
                                            <option value="VIP">VIP</option>
                                            <option value="High Touch Guest">High Touch Guest</option>
                                        </select>
                                        </label>
                                        <label className="custom-select">
                                        <select value={this.state.sendResult} 
                                        onChange={(e) => this.setValue('sendResult', e)}>
                                            <option value="Send Result To" disabled>Send Result To</option>
                                            <option value="Meet & Greet">Meet & Greet</option>
                                            <option value="Quality Checklist">Quality Checklist</option>
                                            <option value="Both">Both</option>
                                        </select>
                                        </label>
                                    </div>
                                    <hr className="toppad-0 mrg-top-0"/>
                                    <span>Please fill out the form below:</span>
                                    <div className="text-center mrg-btm-20" style={{color:this.state.color}}>{this.state.errorMessage}</div>
                                    <div className="row">
                                        <div className="col-sm-6 col-md-6">
                                        <input type="text" className="form-control" placeholder="PTS Representative" value={this.state.ptsRepresentative} 
                                        onChange={(e) => this.setValue('ptsRepresentative', e)}/>
                                        <input type="text" className="form-control" placeholder="Community Name" value={this.state.communityName} onChange={(e) => this.setValue('communityName', e)}/>
                                        <input type="text" className="form-control" placeholder="Guest Name" value={this.state.guestName} onChange={(e) => this.setValue('guestName', e)} />
                                        <div className="row radiosec">
                                            <div className="col-sm-12">
                                                <label>DS Guest</label>
                                                <div className="row">
                                                    <div className="clearfix"></div>
                                                    <div className="col-sm-3">
                                                    <label className="custom_radio radio-inline">Yes
                                                    <input type="radio" name="radio" value="yes" 
                                                    onChange={(e) => this.setChecked(e)} />
                                                    <span className="checkmark"></span>
                                                    </label>
                                                    </div>
                                                    <div className="col-sm-3">
                                                    <label className="custom_radio radio-inline">No
                                                    <input type="radio" name="radio" value="no" 
                                                    onChange={(e) => this.setChecked(e)}/>
                                                    <span className="checkmark"></span>
                                                    </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <input type="text" className="form-control" placeholder="Company Name" value={this.state.companyName} onChange={(e) => this.setValue('companyName', e)} />
                                        <div className="row">
                                            <div className="col-sm-12 col-md-12">
                                                <textarea className="form-control" placeholder="PTS Comments" value={this.state.ptsComments} onChange={(e) => this.setValue('ptsComments', e)} rows="8" cols="5"></textarea>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                        <input type="text" className="form-control" placeholder="Date of Meet & Greet" value={this.state.dateOfMeet} onChange={(e) => this.setValue('dateOfMeet', e)} />
                                        <input type="text" className="form-control" placeholder="Schedule Time of Meet & Greet" value={this.state.scheduleTimeOfMeet} 
                                        onChange={(e) => this.setValue('scheduleTimeOfMeet', e)} />                                
                                        <input type="text" className="form-control" placeholder="Guest Arrival Time" value={this.state.guestArrivalTime} 
                                        onChange={(e) => this.setValue('guestArrivalTime', e)} />
                                        <input type="text" className="form-control" placeholder="DS Consultant" value={this.state.dsConsultant} onChange={(e) => this.setValue('dsConsultant', e)} />
                                        <input type="text" className="form-control" placeholder="Confirmation Number" value={this.state.confirmationNumber} onChange={(e) => this.setValue('confirmationNumber', e)} />
                                        <div className="row">
                                            <div className="col-sm-12 col-md-12">
                                                <textarea className="form-control" placeholder="Guest Comments" value={this.state.guestComments} 
                                                onChange={(e) => this.setValue('guestComments', e)} rows="8" cols="5"></textarea>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <label>What was your first impression of the apartment?</label>
                                    <div>(1 = Poor & 10 = Excellent)</div>
                                    <div className="pts-rating-block">
                                    <div className="rating-num-block mrg-top-10">
                                        <ul>
                                        <li className={this.state.activeIndex == 0 ? "font-blod active" : 'font-blod'} value="1" onClick={() => this.rating(0)}>1</li>
                                        <li className={this.state.activeIndex == 1 ? "font-blod active" : 'font-blod'} value="1" onClick={() => this.rating(1)}>2</li>
                                        <li className={this.state.activeIndex == 2 ? "font-blod active" : 'font-blod'} value="1" onClick={() => this.rating(2)}>3</li>
                                        <li className={this.state.activeIndex == 3 ? "font-blod active" : 'font-blod'} value="1" onClick={() => this.rating(3)}>4</li>
                                        <li className={this.state.activeIndex == 4 ? "font-blod active" : 'font-blod'} value="1" onClick={() => this.rating(4)}>5</li>
                                        <li className={this.state.activeIndex == 5 ? "font-blod active" : 'font-blod'} value="1" onClick={() => this.rating(5)}>6</li>
                                        <li className={this.state.activeIndex == 6 ? "font-blod active" : 'font-blod'} value="1" onClick={() => this.rating(6)}>7</li>
                                        <li className={this.state.activeIndex == 7 ? "font-blod active" : 'font-blod'} value="1" onClick={() => this.rating(7)}>8</li>
                                        <li className={this.state.activeIndex == 8 ? "font-blod active" : 'font-blod'} value="1" onClick={() => this.rating(8)} >9</li>
                                        <li className={this.state.activeIndex == 9 ? "font-blod active" : 'font-blod'} value="1" onClick={() => this.rating(9)}>10</li>
                                        </ul>
                                    </div>
                                    </div>
                                    <hr />
                                    <span>Please check the boxes below once you have completed the task:</span>
                                    
                                    <div className="row pts-checkgroup">
                                         <div className="col-md-6">
                                         <div className="checkbox">
                                         <label><input name="checkbox1" type="checkbox" value={this.state.checkbox1} 
                                         onChange={(e) => this.checkboxValue('checkbox1', e)}/>Meet the Guest & Escort to the Apartment.</label>
                                       </div>

                                       <div className="checkbox">
                                         <label><input name="checkbox2" type="checkbox" value={this.state.checkbox2} 
                                         onChange={(e) => this.checkboxValue('checkbox2', e)}/>Review Welcome Area Information & Guest Book.</label>
                                       </div>

                                       <div className="checkbox">
                                         <label><input name="checkbox3" type="checkbox"  value={this.state.checkbox3} 
                                         onChange={(e) => this.checkboxValue('checkbox3', e)}/>Demonstration of the operation of the TV/Cable, Remotes, DVD player & Wireless internet access.</label>
                                       </div>

                                       <div className="checkbox">
                                         <label><input name="checkbox4" type="checkbox" value={this.state.checkbox4} 
                                         onChange={(e) => this.checkboxValue('checkbox4', e)}/>Demonstration of all appliances located in Kitchen.</label>
                                       </div>

                                       <div className="checkbox">
                                         <label><input name="checkbox5" type="checkbox"  value={this.state.checkbox5} 
                                         onChange={(e) => this.checkboxValue('checkbox5', e)}/>Demonstration of the thermostat/control of Air Condition/Heat.</label>
                                       </div>

                                       <div className="checkbox">
                                         <label><input name="checkbox6" type="checkbox"  value={this.state.checkbox6} 
                                         onChange={(e) => this.checkboxValue('checkbox6', e)}/>Review the SuiteAmerica App with Guest.</label>
                                       </div>                           

                                         </div>
                                         
                                         <div className="col-md-6">
                                         <div className="checkbox">
                                         <label><input name="checkbox7" type="checkbox"  value={this.state.checkbox7} 
                                         onChange={(e) => this.checkboxValue('checkbox7', e)}/>Demonstration of Washer & Dryer.</label>
                                       </div>

                                       <div className="checkbox">
                                         <label><input name="checkbox8" type="checkbox"  value={this.state.checkbox8} 
                                         onChange={(e) => this.checkboxValue('checkbox8', e)}/>Explanation of Keys, Remotes & FOBS provided for the Guest & how to Secure the Residence.</label>
                                       </div>

                                       <div className="checkbox">
                                         <label><input name="checkbox9" type="checkbox"  value={this.state.checkbox9} 
                                         onChange={(e) => this.checkboxValue('checkbox9', e)}/>Demonstration of All Access Points into the Apartment Community.</label>
                                       </div>

                                       <div className="checkbox">
                                         <label><input name="checkbox10" type="checkbox"  value={this.state.checkbox10} 
                                         onChange={(e) => this.checkboxValue('checkbox10', e)}/>Demonstration of all appliances located in Kitchen.</label>
                                       </div>
                                       <div className="checkbox">
                                         <label><input name="checkbox11" type="checkbox"  value={this.state.checkbox11} 
                                         onChange={(e) => this.checkboxValue('checkbox11', e)}/>Demonstrate Walkscore of Community to Show Guest Nearest Grocery, Restaurants, Coffee Shops etc.</label>
                                       </div>              

                                         </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 col-md-12">
                                        <textarea className="form-control" placeholder="Task Comments" value={this.state.taskComments} onChange={(e) => this.setValue('taskComments', e)} rows="8" cols="5"></textarea>
                                        </div>
                                    </div>
                                    <div className="row">                                         
                                        <div className="col-sm-12 col-md-12">
                                        <ReCAPTCHA
                                            ref="recaptcha"
                                            sitekey="6Lcbw1AUAAAAAE5K5cYDyhoGu0RJY9C3CLtGFXAo"
                                            onChange={this.onChangeCaptcha}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                            </div>
                            <div className="pts-btn-block">
                            <button type="button" className=" btn location_btn" onClick={this.clearData}> CLEAR</button>                           
                            <button type="button" className=" btn location_btn" onClick={this.serviceRequest}> SUBMIT</button>                           
                        </div>
                        </div>
                    </div>
                </div>
                <SubFooter/>
                <Footer/>
                <SuccessModal/>
                </div>


   
           );
       }
   }
   export default PTSImpression;