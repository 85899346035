class InspectionFormApartment extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            bedroomTypes: [
                '1 Bedroom', '2 Bedrooms', '3 Bedrooms', 'Studio', 'Other'
            ],
            noOfBedroom: ''
        }
        this.setChecked = this.setChecked.bind(this);
        this.setValue = this.setValue.bind(this);
    }
    setChecked(field, e) {
        var object = {};
        object[field] = e.target.value;
        this.setState(object);
        this.props.setChecked(object)
    }
    setValue(field, e) {
        var object = {};
        object[field] = e.target.value;
        this.setState(object);
        this.props.setValue(object)
    }
    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-sm-6 col-md-6">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="*Confirmation Number"
                            value={this.props.confirmationNumber}
                            onChange={(e) => this.setValue('confirmationNumber', e)}/>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="*Inspector Name"
                            value={this.props.inspectorName}
                            onChange={(e) => this.setValue('inspectorName', e)}/>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="*Inspector Email"
                            value={this.props.inspectorEmail}
                            onChange={(e) => this.setValue('inspectorEmail', e)}/>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="*Internet Network ID"
                            value={this.props.internetNetworkId}
                            onChange={(e) => this.setValue('internetNetworkId', e)}/>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="*Internet/cable Provider"
                            value={this.props.internetProvider}
                            onChange={(e) => this.setValue('internetProvider', e)}/>
                        <div className="row">
                            <div className="col-sm-6"></div>
                            <div className="col-sm-6"></div>
                        </div>

                    </div>
                    <div className="col-sm-6 col-md-6">

                        <input
                            type="text"
                            className="form-control"
                            placeholder="*Apartment Complex"
                            value={this.props.apartmentComplex}
                            onChange={(e) => this.setValue('apartmentComplex', e)}/>
                        <label className="custom-select">
                            <select
                                className="form-control"
                                value={this.props.noOfBedroom}
                                onChange={(e) => this.setValue('noOfBedroom', e)}>
                                <option >Select a Size</option>
                                {this
                                    .state
                                    .bedroomTypes
                                    .map((row, index) => {
                                        return (
                                            <option key={index} value={row}>{row}</option>
                                        )
                                    })}

                            </select>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="*Inspector Phone Number"
                            value={this.props.inspectorPhoneNumber}
                            onChange={(e) => this.setValue('inspectorPhoneNumber', e)}/>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="*Internet Password"
                            value={this.props.internetPassword}
                            onChange={(e) => this.setValue('internetPassword', e)}/>

                    </div>

                </div>
            </div>
        )
    }
}

export default InspectionFormApartment