/* Importing the node modules, child components, services and controllers used 
   inside StripeInternshipHome component */    
   import $ from "jquery";
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import StripeInternshipHeader from "./common/stripeInternHeader";
   import {Link} from 'react-router-dom';
   import ContactUs from "./common/contactus";
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   import StripeInternFooter from "./common/stripeInternFooter";   
   /* StripeInternshipHome Component initialization */
   class StripeInternshipHome extends React.Component {
       /* Initializing objects of its StripeInternshipHome class */
       constructor(props) {
           super(props);
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
           MainController.getInitialInformation();
        }
       /* It is invoked to return html content */
       render() {   
           return (
            <div className="corporateHousing_Updates">
                <GuestHeader/>
                <header className="codeconveyHeader cc_image_text_main corporate-h">
                    <img className="img-responsive" src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Company-Nike-Internship-Banner.jpg"} width="100%" alt=""  />
                    <div className="wow fadeInUp img-on-text top30 width500 text-center left50 img-on-text-center company_bannertxt" data-wow-delay="0.5s">
                        {/* <img src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Company-Nike-Logo.png"} className="nike_img" width="150px" alt="" /> */}
                        <img src="../../assets/images/stripe-logo.png" className="nike_img stripe_img" width="150px" alt="" />
                        <h1>Congratulations on Your Upcoming Internship</h1>
                        <p>SuiteAmerica has been selected by Stripe to arrange your shared housing accommodations.
                        </p>
                    </div>
                </header>
                <StripeInternshipHeader />
                <div className="quote-section bg-white">
                    <div className="container">
                        <div className=" text-center col-md-10 col-md-offset-1 wow fadeInUp company_p" data-wow-delay="0.5s">
                            <h2 className="gotham-book mrg-btm-20">Stripe's #1 Corporate Housing Provider</h2>
                            <p>SuiteAmerica has been providing corporate apartments and executive suites nationwide for over 25 years.  Our goal is to match you up with a roommate and then assign you a furnished apartment close to the Stripe campus.
                            </p>
                            <p>Please click the link below to view sample properties in the Seattle areas.
                            </p>
                        </div>
                    </div>
                    <div className="who_weare_text service_ch_new">
                        <div className="container">
                            <div className="row">
                            <div className="col-md-6 col-sm-6">
                                <div className="wow fadeInUp company_p dual_quote" data-wow-delay="0.5s">
                                    <p>Simply visit our Locations page to see sample
                                    communities of what might be available for
                                    your temporary stay in Stripe's key areas.
                                    </p>
                                    <Link to="/company/stripe-internship/communities" className="box-slide-btn">VIEW LOCATIONS</Link>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <div className="wow fadeInUp company_p dual_quote" data-wow-delay="0.5s">
                                    <p>Fill in the Intern questionnaire so that we can best arrange your temporary accommodations.
                                    </p>
                                    <Link to="/company/stripe-internship/questionnaire" className="box-slide-btn">INTERN QUESTIONNAIRE</Link>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid bg-grey">
                    <div className="container">
                        <ContactUs />
                    </div>
                </div>     
                <div className="container ft-contact-adrs mrg-btm-30 mrg-top-30">
                    <StripeInternFooter />
                </div>
                <SubFooter/>
                <Footer/>
            </div>   
           );
       }
   }
   export default StripeInternshipHome;