/* Importing the node modules, child components, services and controllers used 
   inside Footer component */
import { Link } from 'react-router-dom';
import MainController from '../controller/mainController';
import Footers from '../components/newinterface/footer';
import SuiteFooter from './suiteFooter';
class Footer extends React.Component {
   constructor(props) {
      super(props)
      this.state = {
         currentYear: new Date().getFullYear(),
      }
   }
   async componentDidMount() {
      MainController.removeClassOnNavCollapse();
   }

   render() {
      return (
         <div className='SAweb_updateUi'>
            <Footers />
            {/* <div className="new1-footer">
           <footer>
              <div className="container">
                 <div className="row">
                    <div className="col-md-10 col-sm-11 col-sm-offset-1">
                       <div className="row">
                          <div className="col-md-3 col-sm-3">
                             <div className="foot_nav mrg-btm-25">
                                <h1>Corporation</h1>
                                <ul>
                                   <li><Link to="/corporation-corporate-housing">Corporate Housing</Link></li>
                                   <li><Link to="/corporation-global">Global Serviced Apartments</Link></li>
                                   <li><Link to="/insurance-landing">Insurance Housing</Link></li>
                                   <li><Link to="/corporation-destination-services">Destination Services</Link></li>
                                   <li><Link to="/technology">Technology</Link></li>
                                   <li><Link to="/customization">Customization</Link></li>
                                </ul>
                             </div>
                             <div className="foot_nav mrg-btm-25">
                                <h1>Relocation</h1>
                                <ul>
                                   <li><Link to="/relocation-corporate-housing">Corporate Housing</Link></li>
                                   <li><Link to="/global">Global Serviced Apartments</Link></li>
                                   <li><Link to="/insurance-landing">Insurance Housing</Link></li>
                                   <li><Link to="/destination-services">Destination Services</Link></li>
                                   <li><Link to="/relocation-technology">Technology</Link></li>
                                   <li><Link to="/relocation-customization">Customization</Link></li>
                                </ul>
                             </div>
                          </div>
                          <div className="col-md-3 col-sm-3">
                             <div className="foot_nav mrg-btm-25">
                                <h1>Individual</h1>
                                <ul>
                                   <li><Link to="/individual-corporate-housing">Corporate Housing</Link></li>
                                   <li><Link to="/individual-global">Global Serviced Apartments</Link></li>
                                   <li><Link to="/insurance-landing">Insurance Housing</Link></li>
                                   <li><Link to="/individual-destination-services">Destination Services</Link></li>
                                   <li><Link to="/individual-technology">Technology</Link></li>
                                   <li><Link to="/individual-customization">Customization</Link></li>
                                </ul>
                             </div>
                             <div className="foot_nav mrg-btm-25">
                                <h1>Government</h1>
                                <ul>
                                   <li><Link to="/government-housing">Government Housing</Link></li>
                                   <li><Link to="/government-technology">Technology</Link></li>
                                   <li><Link to="/government-customization">Customization</Link></li>
                                </ul>
                             </div>
                          </div>
                          <div className="col-md-3 col-sm-3">
                             <div className="foot_nav mrg-btm-25">
                                <h1>Company</h1>
                                <ul>
                                   <li><Link to="/company-info">About Us</Link></li>
                                   <li><Link to="/contactUs">Contact</Link></li>
                                   <li><Link to="/media-kit">Media Kit</Link></li>
                                   <li><Link to="/career-opportunities">Career Opportunities</Link></li>
                                   <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                   <li><Link to="/gdpr">GDPR</Link></li>
                                   <li><Link to="/testimonials">Testimonials</Link></li>
                                </ul>
                             </div>
                          </div>
                          <div className="col-md-3 col-sm-3">
                             <div className="foot_nav mrg-btm-25">
                                <h1>Stay With Us</h1>
                                <ul>
                                   <li><Link to="/locations">Locations</Link></li>
                                   <li><Link to="/short-term-corporate-housing/spotlightcities">Spotlight Cities</Link></li>
                                   <li><Link to="/requestReservation">Request Information</Link></li>
                                </ul>
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
              </div>
           </footer>
           <SuiteFooter {...this.state} />
        </div>    */}

         </div>

      );
   }
}
export default Footer;


