import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Loader from "../../common/loader";
import { Spin } from "antd";
import ReservationsTable from "./tables/ReservationsTable";
import CommunityOrderStatusTable from "./tables/CommunityOrderStatusTable";

import BookitApiService from "../../../services/bookit";

import ServiceOrdersModal from "./modals/ServiceOrdersModal";
import FlightInfoModal from "./modals/FlightInfoModal";

let clientOrders = [];
let activeOrders = [];
let termedOrders = [];
let pendingOrders = [];
let tableData = pendingOrders;

let serviceOrders = [];

const Dashboard = () => {
  const [state, setState] = useState({
    isLoggedIn: null,
    status: "active",
    isLoading: true,
  });

  const [activePendingOrdersSpin, setActivePendingOrdersSpin] = useState(true);
  const [serviceOrdersSpin, setServiceOrdersSpin] = useState(true);

  const [orderFlightInfoModal, setOrderFlightInfoModal] = useState({});
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const history = useHistory();

  function startLoader() {
    updateState({ isLoading: true });
  }

  function stopLoader() {
    updateState({ isLoading: false });
  }

  async function isUserLoggedIn() {
    try {
      const sessionResponse = await BookitApiService.checkSession();
      if (!sessionResponse.loggedIn) {
        return false;
      }
      updateState({
        ClientProfileCardDetails: sessionResponse.ClientProfileCardDetails,
        email: sessionResponse.email,
      });
      return true;
    } catch (err) {
      return false;
    }
  }

  async function checkUserLoginStatus() {
    if (!(await isUserLoggedIn())) {
      updateState({ isLoggedIn: false });
      return;
    }
    updateState({ isLoggedIn: true });
  }

  function storeInSessionStorage(key, data) {
    const dashboardData = JSON.stringify(data);
    sessionStorage.setItem(key, dashboardData);
  }

  function getDataFromSessionStorage(key) {
    const dataJSON = sessionStorage.getItem(key);

    if (dataJSON) {
      return JSON.parse(dataJSON);
    } else {
      return null;
    }
  }

  function groupDataByStatus(orders) {
    orders.map((order) => {
      if (order.StatusCode === "ACTIVE") {
        activeOrders.push(order);
      } else if (order.StatusCode === "PENDING") {
        pendingOrders.push(order);
      } else if (order.StatusCode === "TERMED") {
        termedOrders.push(order);
      }
    });
    handleStatusChange({ target: { id: "active" } });
    setActivePendingOrdersSpin(false);
    console.log(
      "totalOrders",
      activeOrders.length + pendingOrders.length + termedOrders.length
    );
  }

  async function fetchDashboardData(email) {
    activeOrders = [];
    pendingOrders = [];
    termedOrders = [];

    startLoader();
    try {
      let response = await BookitApiService.ClientOrdersByUserEmail({
        email: email,
      });
      if (response.error == 0) {
        clientOrders = response.message.orderslist;
        clientOrders = response.message.orderslist.filter((order) => {
          if (order.BR === 0) {
            order.BR = "";
          }
          return order.BillableType !== "BILLABLE";
        });
        groupDataByStatus(clientOrders);
      }
    } catch (err) {
      console.log(err);
    }
    stopLoader();
  }

  async function fetchServceorders(email) {
    let response = await BookitApiService.ClientOrderServicesByUserEmail({
      email: email,
    });
    setServiceOrdersSpin(false);
    if (response.error == 0) {
      // serviceOrders = response.message.serviceslist;
      serviceOrders = response.message.serviceslist.filter((order) => {
        return order.Status !== "completed";
      });
      console.log(response);
    }
  }

  async function fetchData(email) {
    await fetchServceorders(email);
    await fetchDashboardData(email);
  }

  function handleStatusChange(e) {
    let id = e.target.id;
    updateState({ status: id });
    if (id == "active") {
      tableData = activeOrders;
      setSearchText("");
      setFilteredData([]);
    } else if (id == "pending") {
      tableData = pendingOrders;
      setSearchText("");
      setFilteredData([]);
    } else if (id == "termed") {
      tableData = termedOrders;
      setSearchText("");
      setFilteredData([]);
    }
  }

  function changeOrderFlightInfoModal(order) {
    setOrderFlightInfoModal(order);
  }

  function updateState(updateObj) {
    setState((prevState) => ({ ...prevState, ...updateObj }));
  }

  function clearOrdersData() {
    clientOrders = [];
    activeOrders = [];
    termedOrders = [];
    pendingOrders = [];
    serviceOrders = [];
  }

  useEffect(() => {
    checkUserLoginStatus();
  }, []);

  useEffect(() => {
    if (state.isLoggedIn && state.email) {
      fetchData(state.email);
      return () => {
        clearOrdersData();
      };
    }
  }, [state.isLoggedIn, state.email]);

  if (state.isLoggedIn === true) {
    return (
      <>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="btn-group float-right">
                    {/* <ol className="breadcrumb hide-phone p-0 m-0">
                      <li className="breadcrumb-item">
                        <a href="#">SuiteAmerica</a>
                      </li>
                      <li className="breadcrumb-item active">Dashboard</li>
                    </ol> */}
                  </div>
                  <h4 className="page-title fw-blod">Dashboard</h4>
                </div>
              </div>
            </div>
            <div className="row widgetWidth">
              <div className="col-md-4">
                <div className="card m-b-30 custom-card-view">
                  <div className="card-body">
                    <div className="d-flex flex-row">
                      <div className="col-xs-4 align-self-center">
                        <div className="round">
                          {/* <i className="mdi mdi-file-check"></i> */}
                          <img
                            src="/images/bookit/active-icon.svg"
                            width={"28px"}
                          />
                        </div>
                      </div>
                      <div className="col-xs-8 align-self-center text-left p-0">
                        <div className="m-l-10">
                          <h5 className="mt-0 mb-0 round-inner">
                            {activePendingOrdersSpin ? (
                              <Spin />
                            ) : (
                              activeOrders.length
                            )}
                          </h5>
                          <p className="mb-0 text-muted">Active</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card m-b-30 custom-card-view">
                  <div className="card-body">
                    <div className="d-flex flex-row">
                      <div className="col-xs-4 align-self-center">
                        <div className="round">
                          {/* <i className="mdi mdi-timer-sand"></i> */}
                          <img
                            src="/images/bookit/upcoming-icon.svg"
                            className="clr"
                            width={"21px"}
                          />
                        </div>
                      </div>
                      <div className="col-xs-8 text-left align-self-center p-0">
                        <div className="m-l-10">
                          <h5 className="mt-0 mb-0 round-inner">
                            {activePendingOrdersSpin ? (
                              <Spin />
                            ) : (
                              pendingOrders.length
                            )}
                          </h5>
                          <p className="mb-0 text-muted">Upcoming</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card m-b-30 custom-card-view">
                  <div
                    className="card-body"
                    // data-toggle="modal"
                    // data-target="#service-orders-modal"
                  >
                    <div className="d-flex flex-row">
                      <div className="col-xs-4 align-self-center">
                        <div className="round">
                          {/* <i className="mdi mdi-account-multiple"></i> */}
                          <img
                            src="/images/bookit/open-services-icon.svg"
                            width={"28px"}
                            // className="open-services-logo"
                          ></img>
                        </div>
                      </div>
                      <div className="col-xs-8 align-self-center text-left p-0">
                        <div className="m-l-10">
                          <h5 className="mt-0 mb-0 round-inner">
                            {serviceOrdersSpin ? (
                              <Spin />
                            ) : (
                              serviceOrders.length
                            )}
                          </h5>
                          <p className="mb-0 text-muted">Open Services</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                {state.isLoading && (
                  <div style={{ minHeight: "100px" }}>
                    <Loader />
                  </div>
                )}
                {!state.isLoading && (
                  <>
                    <ReservationsTable
                      tableData={tableData}
                      handleStatusChange={handleStatusChange}
                      status={state.status}
                      searchText={searchText}
                      filteredData={filteredData}
                      setSearchText={setSearchText}
                      setFilteredData={setFilteredData}
                      changeOrderFlightInfoModa={changeOrderFlightInfoModal}
                    />
                    {/* <CommunityOrderStatusTable /> */}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <ServiceOrdersModal tableData={serviceOrders} />
        <FlightInfoModal orderFlightInfoModal={orderFlightInfoModal} />
      </>
    );
  } else if (state.isLoggedIn === false) {
    history.push("/SuiteCare");
  }
  return <Loader />;
};

export default Dashboard;
