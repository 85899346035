/* Importing the node modules, child components, services and controllers used
   inside WorldBankAddedValue component */   
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   import StateCityInfo from './stateCityInfo';
   import MetaTags from '../../common/metaTags';
   import ServerMetaTags from '../../../server/metaTags'; 
   import SpotLightCities from '../../common/virtualSpotlightCities.json';
   import Cities from './cities.json';
   /* WorldBankAddedValue Component initialization */
   class SpotLightCitys extends React.Component {
       /* Initializing objects of its WorldBankAddedValue class */
       constructor(props) {
           super(props)
           this.state={
               companyname:'suiteamerica',
               changeStaus:false,
               headerCities: SpotLightCities,
               cities: Cities
           }
           this.headerCitiesSelection = this.headerCitiesSelection.bind(this);
           this.onClickCity = this.onClickCity.bind(this);
       }
       /* It is invoked immediately before mounting occurs */
       componentWillMount() {
           MainController.getInitialInformation();
       }
       
       onClickCity(data){
        _.assign(data, {amenities_id : ''});
        
       localStorage.setItem('savedSearch', JSON.stringify(data));
       setTimeout(function() { 
        this.props.history.push(data.webUrl)
       }.bind(this), 50);
       }
       headerCitiesSelection(data) {
          this.onClickCity(data);
       }

       /* It is invoked to return html content */
       render() {
           const metaData = ServerMetaTags.urlString.getMetaData(this.props.match.url);
           return (
               <div className="corporateHousing_Updates">
               <MetaTags 
                metaTitle = {metaData.title}
                metaDesription = {metaData.description}
                metaUrl = {metaData.canonical} />
                  <GuestHeader/>
                    
                        <div className="container">
                            <h4 className="mrgfortopheader">SPOTLIGHT CITIES</h4>
                        </div>

                           <div className="spotlite_cities">
                           {this.state.headerCities.map((row,index)=>{
                                    return(
                            <div className="col-md-2 col-sm-6 col-xs-12" key={index}>
                                <a onClick={() => this.headerCitiesSelection(row)}>
                                        <div className="s_img_div">
                                            <img src={row.image} alt=""/>
                                            <div className="s_img_con">
                                                <h5>{row.imgHeading}</h5>
                                                <p>{row.imgParagraph}</p>
                                            </div>
                                        </div>
                                </a>
          </div>
                    )          })}
                        <div className="clearfix"></div>
                    </div>
                    
                  <StateCityInfo {...this.state} onClickCity={this.onClickCity}/>
                 
                  <SubFooter/>
                  <Footer/>
               </div>
   
           );
       }
   }
   export default SpotLightCitys;
   