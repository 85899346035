const Furniture_Housewares=[
    {
      "Bedroom": [
        "Luxury Duvet Set with Alternative Down Comforter",
        "Accent Coverlet & Shams",
        "Two Sets of Luxurious Linens",
        "Mattress Pad",
        "Blanket",
        "Six Bed Pillows",
        "Clock Radio",
        "Twenty Hangers"
      ],
      "Bathroom": [
        "Four Bath Towels",
        "Four Hand Towels",
        "Four Face Towels",
        "Bath Rug",
        "Shower Curtain",
        "Stainless Tooth Brush Holder & Soap Dish",
        "Wastebasket",
        "Artwork",
        "Hair Dryer",
        "Toilet Brush & Plunger"
      ],
      "Dining": [
        "Dinnerware",
        "Place Mats",
        "Napkins",
        "Flatware",
        "Glassware & Wine Glasses"
      ],
      "Kitchen": [
        "Microwave",
        "Cookware",
        "Mixing Bowls",
        "Baking Dish",
        "Colander",
        "Stainless Utensil Holder",
        "Large Fork",
        "Large Spoon",
        "Spatula",
        "Whisk",
        "Measuring Spoon & Cup",
        "Carving & Paring Knives",
        "Four Steak Knives",
        "Cutting Board",
        "Salt & Pepper Set",
        "Wine Opener",
        "Coffee Maker",
        "Toaster",
        "Electric Can Opener",
        "Flatware Tray",
        "Kitchen Wastebasket",
        "Dish Towels",
        "Pot Holders",
        "Pitcher"
      ],
      "Miscellaneous": [
        "Iron & Ironing Board",
        "Cordless Dual Phone with Digital Answering Machine",
        "Broom",
        "Dust Pan",
        "Vacuum",
        "Decor",
        "Laundry Basket"
      ],
      "StarterKit": [
        "Bath Tissue",
        "Soap",
        "Shampoo & Conditioner",
        "Paper Towels",
        "Sponge",
        "Laundry Soap",
        "Dishwasher Detergent",
        "Liquid Dish Soap",
        "Extra Light Bulbs",
        "Coffee",
        "Sugar",
        "Creamer",
        "Bottled Water",
        "Oatmeal",
        "Breakfast Bars",
        "Peanuts",
        "Chips",
        "Mints",
        "Welcome Information"
      ],
      "Bedroom_s":[
        "Queen Bed",
        "Headboard",
        "Nightstand",
        "Dresser & Mirror",
        "Lamp",
        "TV",
        "Artwork"
      ],
      "DiningRoom":[
        "Dining Table",
        "Dining Chairs",
        "Artwork"
      ],
      "LivingRoom":[
        "Apple TV",
        "Sofa",
        "Chair",
        "End Table",
        "Coffee Table",
        "Lamp",
        "LCD TV w/stand",
        "DVD Player",
        "Artwork"
       
      ]
    }
  ]
  export default Furniture_Housewares;