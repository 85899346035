/* Importing the node modules, child components, services and controllers used
   inside TravelBookingAdmin component */
   
   import $ from "jquery";
   import TravelBookingHeader from '../../common/travelBookingHeader';
   import TravelBookingApiService from '../../../services/travelBookingApiService';
   import TravelBookingFooter from '../../common/travelBookingFooter';
   import BookingController from './controllers/bookingController';
   import axios from 'axios';   
   import DatePicker from 'react-datepicker'
   import moment from 'moment'
   import SuccessModal from '../../common/successModal';
   import AppController from "../../controller/appController";
   import {getJsDateFromExcel as Dateexcel} from 'excel-date-to-js';
   import OldXlsData from './oldXlsData'
   import XlsData from './xlsData'
   
   import { confirmAlert } from 'react-confirm-alert';
import appController from "../../controller/appController";
   
   /* TravelBookingAdmin Component initialization */
   class UploadAdmin extends React.Component {
       /* Initializing objects of its TravelBookingAdmin class */
   
       constructor(props) {
           super(props)
   
           this.state = {
               xlData : [],
               error : "",
               emailsData : [],
               loading : false,
               color : '',
               sendPromotionEmail : 0,
               StayDropDown : _.range(101),
               rateDropDown : ['100 To 199','200 To 299','300 To 399','400 To 499','500 To 599','600 To 699','700 To 799','800 To 899'],
               stay : '',
               rate : '',
               filterData : [],
               isFilter : false,
               isxlsSubmited : 0,
               xlsendLoader : false,
               oldxlRecords : [],
               oldData : 0,
               oldDataFromdatabase : [],
               oldDataLoading: false,
               oldDataFilter: [],
               isOldXlsData: false,
               selectAll : false,
               prmotionNotSent : 0,
           }
           this.fileUpload = this.fileUpload.bind(this);
           this.selectALL= this.selectALL.bind(this);
           this.conformationAlert= this.conformationAlert.bind(this);
           this.sendPromotionAlert = this.sendPromotionAlert.bind(this);
           this.lengthOfStay = this.lengthOfStay.bind(this);
           this.ratefilter = this.ratefilter.bind(this);
           this.sentmailData = this.sentmailData.bind(this);
           this.oldRecords = this.oldRecords.bind(this);
           this.promotionNotSent = this.promotionNotSent.bind(this);
           this.clear = this.clear.bind(this);
           this.refreshFilter = this.refreshFilter.bind(this);
           this.checkData = this.checkData.bind(this);
           this.clearAlert = this.clearAlert.bind(this);
   
       }
       /* It is invoked immediately before mounting occurs */
       async componentDidMount() {
           this.setState({loading: true, error: ''})
           await this.getMailSent()
           this.setState({loading: false, error: ''})
       }
       async getMailSent(){
           const Response = await TravelBookingApiService.GetBookingEmails({}) 
           if(Response.error==0){
            this.setState({oldxlRecords: Response.message, oldDataFilter: Response.message})
           }      
       }
   
       async fileUpload(e) {
           e.preventDefault();
           const file = this.uploadInput.files[0]
   
           if (file != undefined && file.name.split('.').slice(-1)[0] == "csv") {
               this.setState({loading: true, error: ''})
               
               const data = new FormData();
               data.append('file', file);
               data.append('filename', '');
               data.append('fileType', file.name.split('.').slice(-1)[0]);
               const _this = this
   
               this.setState({error: "", loading: true})
               axios
                   .post(AppController.travelBookingUrl()+'uploadFile', data)
                   .then(function (response) {
                       if(response.data.error==0){
                        let xlres = response.data.workSheetsFromFile
                        _this.setState({uploadallData:xlres})
                        let filteredDays = [] 
                        let filteredPnr = []
                        let errorXls = 0
                        let movieIn = ''
                        let movieOut = ''
                        let invoice = ''
                        let PnrArray=[]
                        const oldxlRecords = _this.state.oldxlRecords
                        
                        filteredDays = _.filter(xlres, function (o, index) {
                      
                            if (Object.keys(o).length && o[7]!= undefined) {
                                    
                                try{
                                    movieIn = o[7]
                                    movieOut = o[8]
                                  
                                    //invoice = Dateexcel(o[18])
                                    errorXls = 0;
                                    o[7] = movieIn
                                    o[8] = movieOut
                                    var SuiteRate=  '$'+BookingController.dailyRent('1X',o[7],  o[8]);
                                    var date1 = new Date(movieIn);
                         
                                    var date2 = new Date(moment().format('MM/DD/YYYY'));
                                    var timeDiff = Math.abs(date2.getTime() - date1.getTime());
                                    var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)); 
                                    var lengthOfStayS = new Date(movieIn);
                                    var lengthOfStayE = new Date(movieOut);
                                    var LtimeDiff = Math.abs(lengthOfStayS.getTime() - lengthOfStayE.getTime());
                                    var lengthOfStay = Math.ceil(LtimeDiff / (1000 * 3600 * 24)); 
                                    var PNRARRAY= _.filter(oldxlRecords, function(y) { 
                                        
                                        return (y.PNR == o[3]  && o[3]!='' && o[3]!=null && o[3]!= undefined? [y.PNR] : '')
                                    });
                                   
                                    PNRARRAY.length>0? PnrArray.push(PNRARRAY) : '';    
                              
                                    const startDate = moment([
                                        Number(moment(movieIn).format('YYYY')),
                                        Number(moment(movieIn).format('MM')) - 1,
                                        Number(moment(movieIn).format('DD'))
                                   ]);
                                   const today = moment([
                                        Number(moment().format('YYYY')),
                                        Number(moment().format('MM')) - 1,
                                        Number(moment().format('DD'))
                                   ]);

                                    return (o[6]!='' && o[6]!=null && o[6]!=undefined && errorXls==0 && PNRARRAY.length <=0 && startDate > today && diffDays > 3 && lengthOfStay > 1 && SuiteRate < o[10])? o : '';   
                                }catch(err){
                                    
                                    movieIn = '01/01/1980'
                                    movieOut = '01/01/1980'
                                    //invoice = '01/01/1980'
                                    errorXls = 1;
                                    return ""
                                }
                                
                                
                                
                            }
    
                        })
                        
                        const oldData = PnrArray.length>=0 && filteredDays.length==0? true : false
                        const msg = oldData? "Your uploaded excel data already exists. Please upload new excel." : ''
                        _this.setState({oldData: 0, sendPromotionEmail: 0,prmotionNotSent: 0, isOldXlsData: msg, xlData: filteredDays, loading: false, isxlsSubmited: errorXls==1? -1 : 1, error: errorXls==1? 'Please upload (Check In Date / Out Date column should be 6 and 7) a valid excel format.' : msg})
                        
                       }
                        else{
                            this.setState({
                                loading: false,
                                error: 'Please Upload csv Supported character sets UTF-16LE'
                            })
                        }

                   })
                   .catch(function (error) {
                       console.log(error);
                   });
           } else {
               this.setState({
                   loading: false,
                   error: file == undefined
                       ? "Please upload file."
                       : "Please enter valied file(sample.csv)."
               })
           }
   
       }
       async checkData(row) {
          
           const _this = this
           if((_this.state.oldData==1 || _this.state.sendPromotionEmail==1 || _this.state.prmotionNotSent==1)){
            var arrayColumn = ['','HotelProperty', 'PropertyCity', 'PNR', 'Traveler', 'EmpID', 'Email', 'CheckInDate', 'OutDate', 'Bkgs','RoomRate','Nights', 'Cost', 'TravelType', 'VirtualPayment', 'BookingRegion', 'PreferredStatus']
            const oldArray = row;
            row = []
            _.forEach(_.range(16), function(value) {
               row.push(oldArray[arrayColumn[value]])
             });
             
           }
   
           const emailIndex = _.findIndex(this.state.emailsData, function (o) {

               return o.indexVal == row[3];
           });
           
           if (emailIndex < 0) {
               await this.setState({
                   emailsData: _.concat(this.state.emailsData, {
                       data: row,
                       indexVal: row[3]
                   })
               })
   
           } else {
               await this.setState({
                   emailsData: update(this.state.emailsData, {
                       $splice: [
                           [emailIndex, 1]
                       ]
                   }, 0)
               })
           }
       }
       sendPromotionAlert(){
           
           const messageString =  'Are you sure you want to send promotion email!!!' 
         
   
           confirmAlert({
             title: '',                        // Title dialog
             message: messageString,               // Message dialog
             childrenElement: () => '',       // Custom UI or Component
             confirmLabel: 'Yes',                           // Text button confirm
             cancelLabel: 'No',                             // Text button cancel
             onConfirm: () => {this.sendPromotionEmail()},    // Action after Confirm
             onCancel: ()=> {},      // Action after Cancel
           })
         }
       async sendPromotionEmail() {
           const _this = this
           this.setState({
               xlsendLoader:true
           })
          
           if (this.state.emailsData.length > 0) {
               this.setState({error: "", color: ''})
               const EmailDetails = {
                   exceldata: this.state.xlData,
                   emailDate: this.state.emailsData,
                   url: localStorage.getItem('url')
               }
             
            const rangeData = _.range(0, this.state.xlData.length, 10)
            if(this.state.oldData==0 && this.state.sendPromotionEmail==0 && this.state.prmotionNotSent==0 ){
               var objs = this.state.xlData.map(function(x) { 
                var format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
               let roomrate=format.test(x[10])?x[10].replace(/[&\/\\#,+()$~%'":*?<>{}]/g,''):x[10];
               let cost=format.test(x[12])?x[12].replace(/[&\/\\#,+()$~%'":*?<>{}]/g,''):x[12]
                   return { 
                   HotelProperty: x[1], 
                   PropertyCity: x[2],
                   PNR:x[3],
                   Traveler:x[4],
                   EmpID:x[5],
                   Email:x[6],
                   CheckInDate: x[7], 
                   OutDate:x[8],
                   Bkgs:x[9],
                   Nights:x[11],
                   RoomRate:roomrate,
                   Cost:cost,
                   TravelType:x[13],
                   VirtualPayment:'',
                   BookingRegion:x[14],
                   PreferredStatus:x[15],
                   SendPromotion: 'no',
                   isBookingConfirmed: 'no',
                   CreatedDate:moment().format('YYYY-MM-DD')
                   }; 
                 });
                 const uniqueXlData=_.uniqBy(objs, 'PNR');
                 const ResponseInsert = await TravelBookingApiService.xlUpload(uniqueXlData)
                
            }
         
            var promotionsentData = this.state.emailsData.map(function(x) { 
                var format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
                let roomrate=format.test(x.data[10])?x.data[10].replace(/[&\/\\#,+()$~%'":*?<>{}]/g,''):x.data[10]
                var hotelratebylength = (Number(x.data[11]) * Number(roomrate))
                var cost = Math.abs((Number(x.data[11]) * 220) - ((hotelratebylength + (hotelratebylength * 0.12))))
                return { 
                PNREncode:AppController.encodeData(x.data[3]),
                PNR:x.data[3],
                Email:x.data[6],
                Cost:cost.toFixed(2)
                }; 
              });
            const slectedEmails=_.uniqBy(promotionsentData, 'PNR');
            const ResponseSent = await TravelBookingApiService.SendPromotionEmails(slectedEmails)
            if(ResponseSent.error==0 && ResponseSent.message.type=="Success"){
                await this.getMailSent()
                jQuery(function ($) {
               $('#successModal').modal('show');
               setTimeout(async function () {
                    await this.clear()
                    await this.setState({
                       xlsendLoader:false,
                       selectAll: false
                   })
                   $('#successModal').modal('hide');
                   
               }.bind(this), 2000);
           }.bind(this));
            }
            else{
                this.setState({error: "Emails are not Sended", color: 'red',xlsendLoader:false})
            }
           } else {
               $('html, body').animate({scrollTop: 0});
               this.setState({error: "Please select atleast one email", color: 'red',xlsendLoader:false})
           }
       }
   
       
   
   
   
       clear() {
           this.setState({filterData: [], oldDataFromdatabase: [], xlData: [], oldDataFilter: [], oldData: 0, isOldXlsData: false, xlData: [], emailsData: [], error: '', sendPromotionEmail: 0, prmotionNotSent: 0, isFilter: false, isxlsSubmited:0})
       }
   
       refreshFilter() {
          
           this.setState({error: '', emailsData: [], filterData: [], stay: '', rate: '', isFilter: false, sendPromotionEmail: 0,prmotionNotSent:0, oldData:0})
       }
   
       async lengthOfStay(field, e) {
           
           this.setState({stay: e.target.value, isFilter: false})
           var seperateValue = this
               .state
               .rate
               .split(' To ')
           var filteredStay = {}
           const _this = this
           const oldData= (this.state.oldData==1 || this.state.sendPromotionEmail==1 || this.state.prmotionNotSent==1)? this.state.oldxlRecords : this.state.xlData
          
           filteredStay = _.filter(oldData, function (o) {
   
               const NightsF = (_this.state.oldData==1 || _this.state.sendPromotionEmail==1 || _this.state.prmotionNotSent==1)? (o.Nights == e.target.value && (o.RoomRate > seperateValue[0] && o.RoomRate < seperateValue[1])) : (o[9] == e.target.value && (o[10] > seperateValue[0] && o[10] < seperateValue[1])) 
               const RateF = (_this.state.oldData==1 || _this.state.sendPromotionEmail==1 || _this.state.prmotionNotSent==1)? (o.Nights == e.target.value) : (o[9] == e.target.value)
               const dataFilter = (e.target.value=='' && (_this.state.oldData==1 || _this.state.sendPromotionEmail==1 || _this.state.prmotionNotSent==1))?  (o.Nights > seperateValue[0] && o.RoomRate < seperateValue[1]) : (o[10] > seperateValue[0] && o[10] < seperateValue[1])
               const sendPromotionEmail  = _this.state.sendPromotionEmail==1? o.SendPromotion=='yes' : true
               const prmotionNotSent  = _this.state.prmotionNotSent==1? o.SendPromotion=='No' : true

               return _this.state.rate != ''? (e.target.value==''? (dataFilter && sendPromotionEmail && prmotionNotSent) : (NightsF && sendPromotionEmail && prmotionNotSent)) : (RateF && sendPromotionEmail && prmotionNotSent);
   
           })
          
           let obj = {}
           obj[(this.state.oldData==1 || this.state.sendPromotionEmail==1 || this.state.prmotionNotSent==1)? 'oldDataFilter' : 'filterData'] = (e.target.value=='' && _this.state.rate =='')? oldData : filteredStay
           obj['isFilter'] = true
           this.setState(obj)
        
       }
   
       async ratefilter(field, e) {
          
           this.setState({rate: e.target.value, isFilter: false})
           const oldData= (this.state.oldData==1 || this.state.sendPromotionEmail==1 || this.state.prmotionNotSent==1 )? this.state.oldxlRecords : this.state.xlData
           var seperateValue = e
               .target
               .value
               .split(' To ')
           var filteredrate = {}
           const _this = this
           filteredrate = _.filter(oldData, function (o) {
               const NightsF =  (_this.state.oldData==1 || _this.state.sendPromotionEmail==1 || _this.state.prmotionNotSent==1)? (o.Nights == _this.state.stay && (o.RoomRate > seperateValue[0] && o.RoomRate < seperateValue[1])) : (o[9] == _this.state.stay && (o[10] > seperateValue[0] && o[10] < seperateValue[1])) 
               const RateF = (_this.state.oldData==1 || _this.state.sendPromotionEmail==1 || _this.state.prmotionNotSent==1)? (o.RoomRate> seperateValue[0] && o.RoomRate < seperateValue[1]) : (o[10] > seperateValue[0] && o[10] < seperateValue[1])
               const dataFilter = (e.target.value=='' && (_this.state.oldData==1 || _this.state.sendPromotionEmail==1 || _this.state.prmotionNotSent==1))?  (o.Nights == _this.state.stay) : (o[9] == _this.state.stay)
               const sendPromotionEmail  = _this.state.sendPromotionEmail==1? o.SendPromotion=='yes' : true
               const prmotionNotSent  = _this.state.prmotionNotSent==1? o.SendPromotion=='No' : true
              
               return _this.state.stay != ''? (e.target.value==''? (dataFilter && sendPromotionEmail && prmotionNotSent) : (NightsF && sendPromotionEmail && prmotionNotSent)) : (RateF && sendPromotionEmail && prmotionNotSent);
   
               
           })
   
           let obj = {}
           obj[(this.state.oldData==1 || this.state.sendPromotionEmail==1 || this.state.prmotionNotSent==1)? 'oldDataFilter' : 'filterData'] = (e.target.value=='' && _this.state.stay =='')? oldData : filteredrate
           obj['isFilter'] = true
   
           this.setState(obj)
       
       }
       async sentmailData(e) {
           const sendPromotionEmail = e.target.checked
           await this.setState({
               sendPromotionEmail: sendPromotionEmail
                   ? 1
                   : 0, 
                   oldData: 0,
                   prmotionNotSent: 0,
                   oldDataLoading: false,
                   stay: '', 
                   rate: '',
                   error: '',
                   emailsData: [],
                   isOldXlsData: false,
                   selectAll: false
           });
           
           
           const sendPromotionData = _.filter(this.state.oldxlRecords, function (o) { return o.SendPromotion =="yes"})
           setTimeout(
               function() {
                   sendPromotionEmail? this.setState({oldDataFilter: sendPromotionData, oldDataLoading: false}) :  this.setState({oldDataLoading: false})
               }
               .bind(this),
               sendPromotionEmail? 0 : 0
           );
   
        
   
       }


       async promotionNotSent(e) {
        const prmotionNotSent = e.target.checked
        await this.setState({
            prmotionNotSent: prmotionNotSent
                ? 1
                : 0,
                oldData: 0,
                sendPromotionEmail: 0,
                oldDataLoading: false,
                stay: '', 
                rate: '',
                error: '',
                emailsData: [],
                isOldXlsData: false,
                selectAll: false
        });
        const sendPromotionData = _.filter(this.state.oldxlRecords, function (o) {return (o.SendPromotion =="no" && appController.DateFormate(o.CreatedDate) == appController.DateFormate()) })

        setTimeout(
            function() {
                prmotionNotSent? this.setState({oldDataFilter: sendPromotionData, oldDataLoading: false}) :  this.setState({oldDataLoading: false})
            }
            .bind(this),
            prmotionNotSent? 0 : 0
        );

    }


      async oldRecords(e) {
           const oldData = e.target.checked
           await this.setState({
               oldDataLoading: false,
               oldData: oldData? 1: 0,
               sendPromotionEmail: 0,
               prmotionNotSent: 0,
               stay : '',
               rate : '',
               isFilter: false,
               emailsData: [],
               xlsendLoader: false,
               error: '',
               isOldXlsData: false,
               selectAll: false
           });
           setTimeout(
               function() {
                   oldData? this.setState({oldDataFilter: this.state.oldxlRecords, oldDataLoading: false}) :  this.setState({oldDataLoading: false})
               }
               .bind(this),
               oldData? 0 : 0
           );
          
    
           
       }
   
       async selectALL(checked, type){
          
           const _this = this
           await this.setState({selectAll: checked}) 
          if(checked && type == 1){
             const filterData = this.state.oldDataFilter.map(function(objectData) {
                 let row = []  
                 
                 var arrayColumn = ['','HotelProperty', 'PropertyCity', 'PNR', 'Traveler', 'EmpID', 'Email', 'CheckInDate', 'OutDate', 'Bkgs','RoomRate','Nights', 'Cost', 'TravelType', 'VirtualPayment', 'BookingRegion', 'PreferredStatus']
                
                 
                 _.forEach(_.range(16), function(value) {
                    row.push(objectData[arrayColumn[value]])
                  });
                 
                  return objectData.OrderId>0? {data : [], indexVal: -1} :  {data: row, indexVal: objectData.PNR} 
                });
             
                await this.setState({emailsData: filterData})
               
          
          }else if(checked && type == 2){
     
             const xlData = this.state.isFilter
                 ? this.state.filterData
                 : this.state.xlData
        
                 const filterData = xlData.map(function(objectData) {
                   
                      return {data: objectData, indexVal: objectData[3]} 
                    });
                 
                 await this.setState({emailsData: filterData})
     
     
          }else{
             await this.setState({emailsData: []})
          }
         }
          
         conformationAlert(e){
           
           const messageString =  e.target.checked? 'Are you sure you want to select all!!!' : 'Are you sure you want to deselect all!!!'
           const checked= e.target.checked
           const idVal= e.target.dataset.id
   
           confirmAlert({
             title: '',                        // Title dialog
             message: messageString,               // Message dialog
             childrenElement: () => '',       // Custom UI or Component
             confirmLabel: 'Yes',                           // Text button confirm
             cancelLabel: 'No',                             // Text button cancel
             onConfirm: () => {this.selectALL(checked, idVal)},    // Action after Confirm
             onCancel: ()=> {},      // Action after Cancel
           })
         }
       clearAlert(){
        confirmAlert({
            title: '',                        // Title dialog
            message: 'Are you sure you want clear the data!!!',               // Message dialog
            childrenElement: () => '',       // Custom UI or Component
            confirmLabel: 'Yes',                           // Text button confirm
            cancelLabel: 'No',                             // Text button cancel
            onConfirm: () => {this.clear()},    // Action after Confirm
            onCancel: ()=> {},      // Action after Cancel
          })
       }
   
       render() {
           
           const xlData = this.state.isFilter
               ? this.state.filterData
               : this.state.xlData
           return (
               <div>
                   <TravelBookingHeader/>
                   <div className="travel_header-ht"></div>
                   <div className="travel_header-ht-w"></div>
                   <div className="container upload-table-margin">
                   {this.state.error != ""
                           ? <div
                                   className="form-group"
                                   style={{
                                   color: 'red'
                               }}>{this.state.error}</div>
   
                           : ""}
   
                       {(this.state.xlData.length == 0 &&  !this.state.isOldXlsData && !this.state.loading)
                           ? <form action="" onSubmit={this.fileUpload} encType="multipart/form-data">
                                  
                                   <div className="form-group">
                                       <label htmlFor="exampleInputEmail1"></label>
                                       <input
                                           type="file"
                                           ref={(ref) => {
                                           this.uploadInput = ref;
                                       }}
                                           name="file"
                                           id="file"/>
                                   </div>
                                   <button type="submit" className="btn btn-primary">Submit</button>
                               </form>
                           : ''}
   
                   {this.state.loading? <div className="panel-body_loader">
                                               <span
                                                   style={{
                                                   fontSize: '25px',
                                                   width: '100%',
                                                   margin: 'auto',
                                                   textAlign: 'center'
                                               }}><i className="fa fa-spinner fa-spin"/> please wait ...!</span>
                                           </div>: ''}
                       
                      {!this.state.loading? <div className="form-group mrg-top-20">
                                   <div className="row">
                                       <div className="col-sm-3 col-md-3">
                                           <label >Length Of Stay:</label>
                                           <select
                                               className="form-control"
                                               id="sel1"
                                               value={this.state.stay}
                                               onChange={(e) => this.lengthOfStay('stay', e)}>
                                               <option value="">Select Length Of Stay</option>
                                               {this
                                                   .state
                                                   .StayDropDown
                                                   .map((row, index) => {
                                                       return (
                                                           <option value={row} key={index}>{row}</option>
                                                       )
                                                   })}
                                           </select>
                                       </div>
                                       <div className="col-sm-2 col-md-2">
                                           <label >Rate:</label>
                                           <select
                                               className="form-control"
                                               id="sel1"
                                               value={this.state.rate}
                                               onChange={(e) => this.ratefilter('rate', e)}>
                                               <option value="">Select Rate</option>
                                               {this
                                                   .state
                                                   .rateDropDown
                                                   .map((row, index) => {
                                                       return (
                                                           <option value={row} key={index}>{row}</option>
                                                       )
                                                   })}
                                           </select>
                                       </div>
   
   
                                           <div className="col-sm-2 col-md-2 mrg-top-20">
                                           <div className="checkbox">
                                               <label>
                                               <input type="checkbox" 
                                               onChange={(e) => this.sentmailData(e)}
                                               checked={this.state.sendPromotionEmail == 1
                                               ? true
                                               : false} /> Promotion Email Sent 
                                               </label>
                                           </div>
                                           </div>
   
                                           <div className="col-sm-2 col-md-2 mrg-top-20">
                                           <div className="checkbox">
                                               <label>
                                               <input type="checkbox" 
                                               onChange={(e) => this.oldRecords(e)}
                                                   checked={this.state.oldData == 1
                                                   ? true
                                                   : false} /> Old Records
                                               </label>
                                           </div>
                                           </div>
                                           <div className="col-sm-2 col-md-2 mrg-top-20">
                                           <div className="checkbox">
                                               <label>
                                               <input type="checkbox" 
                                               onChange={(e) => this.promotionNotSent(e)}
                                                   checked={this.state.prmotionNotSent == 1
                                                   ? true
                                                   : false} /> Promotion Email NoSent 
                                               </label>
                                           </div>
                                           </div>
                                       
   
                                       <div className="col-sm-1 col-md-1">
                                          <button
                                               type="submit"
                                               className="btn btn-primary mrg-top-20"
                                               onClick={this.state.oldDataLoading?  '' : (this.state.isOldXlsData && this.state.oldData==0)? this.clear : this.refreshFilter}>{this.state.oldDataLoading? <i className="fa fa-spinner fa-spin" style={{fontSize: '20px'}}/> : (this.state.isOldXlsData && this.state.oldData==0)? 'Clear' : 'Refresh' }</button> 
                                       </div>
                                   </div>
                               </div>: "" }
                           
                      
                          
                        {(!this.state.loading && this.state.xlData.length > 0 && !this.state.oldDataLoading && this.state.oldData==0 && this.state.sendPromotionEmail==0 && this.state.prmotionNotSent==0) ? <XlsData 
                        {...this.state}
                        conformationAlert={this.conformationAlert} 
                        checkData={this.checkData}/> : "" }
                        {(!this.state.loading && (this.state.oldData==1 || this.state.sendPromotionEmail==1 || this.state.prmotionNotSent==1) && !this.state.oldDataLoading)? <OldXlsData 
                        {...this.state}
                        conformationAlert={this.conformationAlert} 
                        checkData={this.checkData} /> : "" }
                        {(!this.state.loading  && (this.state.oldData==1 && !this.state.oldDataLoading) || ((this.state.sendPromotionEmail==1 || xlData.length>0) && !this.state.oldDataLoading)|| ((this.state.prmotionNotSent==1 || xlData.length>0) && !this.state.oldDataLoading)  )?<div>
                           {this.state.xlsendLoader
                                       ? <div className="panel-body_loader">
                                               <span
                                                   style={{
                                                   fontSize: '25px',
                                                   width: '100%',
                                                   margin: 'auto',
                                                   textAlign: 'center'
                                               }}><i className="fa fa-spinner fa-spin"/>
                                                   please wait ...!</span>
                                           </div>
                                       : <button
                                       type="submit"
                                       className="btn btn-primary mrg-top-20"
                                       onClick={this.sendPromotionAlert}>Send Promotion Email</button>}
                                   <span>&nbsp; &nbsp;</span>
                                   <button
                                       type="submit"
                                       className="btn btn-primary mrg-top-20"
                                       onClick={this.clearAlert} style={{color:this.state.xlsendLoader?'white':''}} disabled={this.state.xlsendLoader ? 'disabled':''}>Clear</button>
                               </div>
                           : ''}
                       
                   </div>
                   <SuccessModal/>
                   <TravelBookingFooter/>
               </div>
           );
       }
   }
   export default UploadAdmin;
