import React from 'react'
import Footer from './footer';
import Header from './header';
   class PrivacyPolicyNew extends React.Component {
      
       constructor(props) {
           super(props);
           this.state = {
           }
       }
     
      async componentDidMount(){
        window.onbeforeunload = function () {
          window.scrollTo(0, 0);
        }
      }
       render() {
       
           return (
             
    
                <div className="SAweb_updateUi">

                <Header/>


                <section className=" SAweb_privacyPolicyBanner" >

      {/* <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/aboutUS/privacyPolicyBanner.jpg" alt="" width="100%" /> */}

      <h2 data-aos="fade-up">Privacy Policy</h2>


    </section>

    <section className="SAweb_privacyPolicyTabs bg-white">
    <div className="container box">

      <div className="item1">
        <ul className="nav nav-tabs tabs-left sideways">
          <li className="active"><a href="#tabAnnouncement" data-toggle="tab"> <i className="bi bi-megaphone-fill"></i>
              Announcement</a></li>
          <li><a href="#tabDefinitions" data-toggle="tab"> <i className="bi bi-file-earmark-text-fill"></i>
              Definitions</a></li>
          <li><a href="#tabInfo" data-toggle="tab"> <i className="bi bi-info-circle-fill"></i> Information
              Collection/Use</a></li>
          <li><a href="#TabCompliance" data-toggle="tab"> <i className="bi bi-lock-fill"></i> CCPA Compliance</a></li>
          <li><a href="#tabPersonal" data-toggle="tab"> <i className="bi bi-pie-chart-fill"></i> Processing Personal
              Data</a></li>
          <li><a href="#tabAnalytics" data-toggle="tab"> <i className="bi bi-bar-chart-fill"></i> Analytics</a></li>
          <li><a href="#tabContactUs" data-toggle="tab"> <i className="bi bi-telephone-fill"></i> Contact Us </a></li>
        </ul>
      </div>

      <div className="item2">
        <div className="tab-content">
          <div className="tab-pane active" id="tabAnnouncement">

            <b> Effective date: April 26th, 2019</b> SuiteAmerica ("us", "we", or "our") operates the www.suiteamerica.com website (the
            "Service"). This page informs you of our policies regarding the collection, use, and disclosure of
            personal data when you use our Service and the choices you have associated with that data. We use your
            data to provide and improve the Service. By using the Service, you agree to the collection and use of
            information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in
            this Privacy Policy have the same meanings as in our Terms and Conditions, accessible at
            www.suiteamerica.com
          </div>
          <div className="tab-pane" id="tabDefinitions">

            <div className="tabDefinitionsView">

              <div className="item">
                <i className="bi bi-person-fill"></i>
                <h4>Service</h4>
                <p>www.suiteamerica.com website operated by SuiteAmerica.</p>
              </div>
              <div className="item">
                <i className="fa fa-database"></i>
                <h4>Personal Data
                </h4>
                <p>Personal Data means data about a living individual who can be identified from those data (or from
                  those and other information either in our possession or likely to come into our possession).</p>
              </div>

              <div className="item">
                <i className="bi bi-graph-up-arrow"></i>
                <h4>Usage Data
                </h4>
                <p>Usage Data is data collected automatically either generated by the use of the Service or from the
                  Service infrastructure itself (for example, the duration of a page visit).</p>
              </div>
              <div className="item">
                <i className="bi bi-pie-chart-fill"></i>
                <h4>Cookies</h4>
                <p>Cookies are small pieces of data stored on your device (computer or mobile device).</p>
              </div>
              <div className="item">
                <i className="bi bi-file-earmark-bar-graph-fill"></i>
                <h4>Data Controller
                </h4>
                <p>Data Controller means the natural or legal person who (either alone or jointly or in common with
                  other persons) determines the purposes for which and the manner in which any personal information
                  are, or are to be, processed.</p>
                <p>For the purpose of this Privacy Policy, we are a Data Controller of your Personal Data.</p>
              </div>
              <div className="item">
                <i className="bi bi-bar-chart-line-fill"></i>
                <h4>Data Processors
                </h4>
                <p>Data Processor (or Service Provider) means any natural or legal person who processes the data on
                  behalf of the Data Controller.</p>
                <p>We may use the services of various Service Providers in order to process your data more
                  effectively.</p>
              </div>
              <div className="item">
                <i className="bi bi-menu-button-wide-fill"></i>
                <h4>Data Subject
                </h4>
                <p>Data Subject is any living individual who is using our Service and is the subject of Personal Data.
                </p>
              </div>

            </div>

          </div>
          <div className="tab-pane" id="tabInfo">
            <p>We collect several different types of information for various purposes to provide and improve our
              Service to you.

            </p>
            <h2>Types of Data Collected</h2>
            <h4>Personal Data
            </h4>
            <p>While using our Service, we may ask you to provide us with certain personally identifiable information
              that can be used to contact or identify you ("Personal Data"). Personally identifiable information may
              include, but is not limited to:</p>
            <ul>
              <li>Email address



              </li>
              <li> First name and last name</li>
              <li>Phone number</li>
              <li>Address, State, Province, ZIP/Postal code, City</li>
              <li> Cookies and Usage Data</li>
            </ul>

            <h4>Usage Data
            </h4>
            <p>We may also collect information about how the Service is accessed and used ("Usage Data"). This Usage
              Data may include information such as your computer's Internet Protocol address (e.g. IP address),
              browser type, browser version, the pages of our Service that you visit, the time and date of your visit,
              the time spent on those pages, unique device identifiers, and other diagnostic data.</p>
            <p>We may use your Personal Data to contact you with newsletters, marketing or promotional materials, and
              other information that may be of interest to you. You may opt out of receiving any, or all, of these
              communications from us by following the unsubscribe link or instructions provided in any email we send.
            </p>
            <h4>Tracking & Cookies Data
            </h4>
            <p>We use cookies and similar tracking technologies to track the activity on our Service and hold certain
              information.

            </p>
            <p>Cookies are files with small amounts of data which may include an anonymous unique identifier. Cookies
              are sent to your browser from a website and stored on your device. Tracking technologies also used are
              beacons, tags, and scripts to collect and track information and to improve and analyze our Service.</p>
            <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.
              However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>
            <h5>Examples of Cookies we use:
            </h5>
            <ul>
              <li><b>Session Cookies. </b> We use Session Cookies to operate our Service. </li>
              <li> <b>Preference Cookies.</b> We use Preference Cookies to remember your preferences and various
                settings.</li>
              <li> <b>Security Cookies.</b> We use Security Cookies for security purposes.
              </li>
            </ul>
            <p>SuiteAmerica uses the collected data for various purposes:

            </p>
            <ul>
              <li>To provide and maintain our Service</li>
              <li>To notify you about changes to our Service
              </li>
              <li>To allow you to participate in interactive features of our Service when you choose to do so
              </li>
              <li>To provide customer support
              </li>
              <li>To gather analysis or valuable information so that we can improve our Service
              </li>
              <li>To monitor the usage of our Service
              </li>
              <li>To detect, prevent and address technical issues
              </li>
              <li>To provide you with news, special offers, and general information about other goods, services, and
                events which we offer that are similar to those that you have already purchased or enquired about
                unless you have opted not to receive such information</li>
            </ul>


          </div>
          <div className="tab-pane" id="TabCompliance">
            <h2>California Privacy Information</h2>
            <h4>Effective January 1, 2020
            </h4>
            <p>For individuals who are California residents, The California Consumer Privacy Act (CCPA) requires
              specific disclosures about the information we collect and any third parties with whom we share it with.
              Below is detailed information about the type of information collected.</p>
            <p>The CCPA defines Personal Information as information that identifies, relates to, describes, is
              reasonably capable of being associated with, or could reasonably be linked directly or indirectly with a
              particular consumer or household.</p>
            <p>In compliance with the CCPA, we have noted what Personal Information we may collect from users.

            </p>
            <ul>
              <li>Real name
              </li>
              <li>Alias</li>
              <li>Postal address
              </li>
              <li>Unique personal identifier
              </li>
              <li>Online identifier IP address
              </li>
              <li>Email address
              </li>
              <li>Account name
              </li>
              <li>Social Security Number
              </li>
              <li>Driver’s license number
              </li>
              <li>Passport number
              </li>
            </ul>
            <p>Per the CCPA, individuals have the right to request that we delete any Personal Information that we
              collected or maintained. California residents may also opt-out of the ‘sale’ of their Personal
              Information. SuiteAmerica does not ‘sell’ any Personal Information.</p>
            <p>The CCPA requires companies conducting business in California to list all third parties to which the
              company has disclosed Personal Information for marketing purposes. SuiteAmerica strictly forbids the
              release of your Personal Information to third parties for the use of direct marketing.</p>
            <p>California residents have the right to not be discriminated against for exercising their rights as
              described in this section. We will not discriminate against individuals who exercise their CCPA rights.
              As a California resident, individuals also have the right to designate an agent to exercise these rights
              on their behalf.</p>
            <p>SuiteAmerica exercises appropriate and reasonable technical and organizational measures to protect
              against unauthorized or unlawful process of personal data and takes measures against accidental loss or
              destruction of, or damage to, personal data. These measures reflect our use of the most up-to-date
              technology and software to implement levels of security appropriate to safeguard and deflect a potential
              breach of security. SuiteAmerica utilizes and maintains effective information security protocols to
              protect Client Data and Personal Information.</p>
            <p>If you are a California resident and have questions regarding your Personal Information, please send
              your inquiry to IT@suiteamerica.com.</p>



          </div>
          <div className="tab-pane" id="tabPersonal">
            <p>If you are from the European Economic Area (EEA), SuiteAmerica legal basis for collecting and using the
              personal information described in this Privacy Policy depends on the Personal Data we collect and the
              specific context in which we collect it.</p>
            <p>SuiteAmerica may process your Personal Data because:

            </p>
            <ul>
              <li>We need to perform a contract with you</li>
              <li>You have given us permission to do so</li>
              <li>The processing is in our legitimate interests and it's not overridden by your rights
              </li>
              <li>To comply with the law
              </li>
            </ul>
            <h4>Retention of Data
            </h4>
            <p>SuiteAmerica will retain your Personal Data only for as long as is necessary for the purposes set out
              in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with
              our legal obligations (for example, if we are required to retain your data to comply with applicable
              laws), resolve disputes, and enforce our legal agreements and policies.</p>
            <p>SuiteAmerica will also retain Usage Data for internal analysis purposes. Usage Data is generally
              retained for a shorter period of time, except when this data is used to strengthen the security or to
              improve the functionality of our Service, or we are legally obligated to retain this data for longer
              time period</p>

            <h4>Transfer of Data
            </h4>
            <p>Your information, including Personal Data, may be transferred to — and maintained on — computers
              located outside of your state, province, country or other governmental jurisdiction where the data
              protection laws may differ than those from your jurisdiction.</p>

            <p>If you are located outside the United States and choose to provide information to us, please note that
              we transfer the data, including Personal Data, to the United States and process it there.</p>
            <p>Your consent to this Privacy Policy followed by your submission of such information represents your
              agreement to that transfer.</p>
            <p>SuiteAmerica will take all steps reasonably necessary to ensure that your data is treated securely and
              in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an
              organization or a country unless there are adequate controls in place including the security of your
              data and other personal information.</p>

            <h4>Disclosure of Data</h4>
            <h5>Legal Requirements</h5>

            <p>SuiteAmerica may disclose your Personal Data in the good faith belief that such action is necessary to:
            </p>
            <ul>
              <li>To comply with a legal obligation</li>
              <li>To protect and defend the rights or property of SuiteAmerica
              </li>
              <li>To prevent or investigate possible wrongdoing in connection with the Service
              </li>
              <li>To protect the personal safety of users of the Service or the public
              </li>
              <li>To protect against legal liability
              </li>
            </ul>
            <h4>Security of Data
            </h4>
            <p>The security of your data is important to us but remember that no method of transmission over the
              Internet, or method of electronic storage, is 100% secure. While we strive to use commercially
              acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>
            <h4>"Do Not Track" Signals
            </h4>
            <p>We do not support Do Not Track ("DNT"). Do Not Track is a preference you can set in your web browser to
              inform websites that you do not want to be tracked.</p>

            <p>You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web
              browser.

            </p>
            <p>Your Data Protection Rights Under General Data Protection Regulation (GDPR)</p>
            <p>If you are a resident of the European Economic Area (EEA), you have certain data protection rights.
              SuiteAmerica aims to take reasonable steps to allow you to correct, amend, delete, or limit the use of
              your Personal Data.</p>
            <p>If you wish to be informed what Personal Data we hold about you and if you want it to be removed from
              our systems, please contact us.</p>
            <p>In certain circumstances, you have the following data protection rights:</p>

            <ul>
              <li> <b> The right to access, update or to delete the information we have on you.</b> Whenever made
                possible, you
                can access, update or request deletion of your Personal Data directly within your account settings
                section. If you are unable to perform these actions yourself, please contact us to assist you.</li>
              <li> <b> The right of rectification. </b> You have the right to have your information rectified if that
                information
                is inaccurate or incomplete.</li>
              <li> <b>The right to object.</b> You have the right to object to our processing of your Personal Data.
              </li>
              <li><b>The right of restriction. </b> You have the right to request that we restrict the processing of
                your
                personal information.</li>
              <li> <b>The right to data portability.</b> You have the right to be provided with a copy of the
                information we
                have on you in a structured, machine-readable, and commonly used format.</li>
              <li><b>The right to withdraw consent.</b> You also have the right to withdraw your consent at any time
                where
                SuiteAmerica relied on your consent to process your personal information.</li>
            </ul>
            <p>Please note that we may ask you to verify your identity before responding to such requests.</p>
            <p>You have the right to complain to a Data Protection Authority about our collection and use of your
              Personal Data. For more information, please contact your local data protection authority in the European
              Economic Area (EEA).</p>

            <h4>Service Providers
            </h4>
            <p>We may employ third party companies and individuals to facilitate our Service ("Service Providers"), to
              provide the Service on our behalf, to perform Service-related services, or to assist us in analyzing how
              our Service is used.</p>
            <p>These third parties have access to your Personal Data only to perform these tasks on our behalf and are
              obligated not to disclose or use it for any other purpose.</p>

          </div>
          <div className="tab-pane" id="tabAnalytics">
            <p>We may use third-party Service Providers to monitor and analyze the use of our Service.

            </p>
            <h4>Google Analytics
            </h4>
            <p>Google Analytics is a web analytics service offered by Google that tracks and reports website traffic.
              Google uses the data collected to track and monitor the use of our Service. This data is shared with
              other Google services. Google may use the collected data to contextualize and personalize the ads of its
              own advertising network.</p>
            <p>You can opt-out of having made your activity on the Service available to Google Analytics by installing
              the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js,
              analytics.js, and dc.js) from sharing information with Google Analytics about visits activity.</p>
            <p>For more information on the privacy practices of Google, please visit the Google Privacy & Terms web
              page: <a href="https://policies.google.com/privacy?hl=en" target="_blank"> Privacy Policy – Privacy & Terms – Google </a></p>
            <h4>Twitter</h4>
            <p>Twitter remarketing service is provided by Twitter Inc.</p>
            <p>You can opt-out from Twitter's interest-based ads by following their instructions:{' '}
              <a href="https://help.twitter.com/en/safety-and-security/privacy-controls-for-tailored-ads" target="_blank"> 
              Your privacy options for personalized ads | Twitter Help</a>
            </p>
            <p>You can learn more about the privacy practices and policies of Twitter by visiting their Privacy Policy
              page: <a href="https://twitter.com/en/privacy" target="_blank"> Twitter Privacy Policy </a></p>
            <h4>Behavioral Remarketing
            </h4>
            <p>SuiteAmerica uses remarketing services to advertise on third party websites to you after you visited
              our Service. We and our third-party vendors use cookies to inform, optimize and serve ads based on your
              past visits to our Service.</p>
            <h4>Facebook
            </h4>
            <p>Facebook remarketing service is provided by Facebook Inc.

            </p>
            <p>You can learn more about interest-based advertising from Facebook by visiting this page:
              <a href="https://www.facebook.com/help/516147308587266/?helpref=hc_fnav" target="_blank"> Facebook - Advertising </a>
            </p>
            <p>To opt-out from Facebook's interest-based ads follow these instructions from Facebook:
              <a href="https://www.facebook.com/help/568137493302217" target="_blank">  Facebook | Internet-based ads </a>
            </p>
            <p>Facebook adheres to the Self-Regulatory Principles for Online Behavioral Advertising established by the
              Digital Advertising Alliance. You can also opt-out from Facebook and other participating companies
              through the Digital Advertising Alliance in the USA <a href="https://optout.aboutads.info/?c=2&lang=EN" target="_blank"> Facebook | opt-out</a>,
              the Digital
              Advertising Alliance of Canada in Canada <a href="https://youradchoices.ca/" target="_blank">Facebook - adchoices</a> or the European
              Interactive Digital
              Advertising Alliance in Europe <a href="https://www.youronlinechoices.eu/" target="_blank"> Facebook - opt-out</a>, or opt-out using your
              mobile device
              settings.</p>
            <p>For more information on the privacy practices of Facebook, please visit Facebook's Data Policy:
              <a href="https://www.facebook.com/privacy/policy/?entry_point=data_policy_redirect&entry=0" target="_blank"> Facebook - Privacy Policy</a>
            </p>
            <h4>Pinterest</h4>
            <p>Pinterest remarketing service is provided by Pinterest Inc.

            </p>
            <p>You can opt-out from Pinterest's interest-based ads by enabling the "Do Not Track" functionality of
              your web browser or by following Pinterest instructions:
              <a href="https://help.pinterest.com/en/article/personalization-and-data" target="_blank"> Pinterest | personalization-and-data </a>
            </p>
            <p>You can learn more about the privacy practices and policies of Pinterest by visiting their Privacy
              Policy page: <a href="https://policy.pinterest.com/en/privacy-policy" target="_blank"> Pinterest - Privacy Policy</a></p>
            <h4>Links to Other Sites
            </h4>
            <p>Our Service may contain links to other sites that are not operated by us. If you click on a third party
              link, you will be directed to that third party's site. We strongly advise you to review the Privacy
              Policy of every site you visit.</p>
            <p>We have no control over and assume no responsibility for the content, privacy policies or practices of
              any third party sites or services.</p>
            <h4>Children's Privacy
            </h4>
            <p>Our Service does not address anyone under the age of 18 ("Children").

            </p>
            <p>We do not knowingly collect personally identifiable information from anyone under the age of 18. If you
              are a parent or guardian and you are aware that your child has provided us with Personal Data, please
              contact us. If we become aware that we have collected Personal Data from children without verification
              of parental consent, we take steps to remove that information from our servers.</p>

            <h4>Changes to This Privacy Policy
            </h4>
            <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the
              new Privacy Policy on this page.</p>
            <p>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming
              effective and update the "effective date" at the top of this Privacy Policy.</p>
            <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy
              Policy are effective when they are posted on this page.</p>




          </div>
          <div className="tab-pane" id="tabContactUs">
            <p>If you have any questions about this Privacy Policy, please contact us:

            </p>
            <ul>
              <li>By email: info@suiteamerica.com</li>
              <li> By visiting this page on our website: <a href="/contactUs"> https://www.suiteamerica.com/contactUs </a>
              </li>
              <li>By phone number: 800 367 9501</li>
            </ul>

          </div>
        </div>
      </div>

      <div className="clearfix"></div>

    </div>


  </section>    
                
<Footer/>



          </div>
           );
       }
   }
   export default PrivacyPolicyNew;