import $ from "jquery";
import MapController from "../../controller/mapController";
class SearchMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      latitude: 0.0,
      longitude: 0.0,
      types: [
        {
          name: "Restaurants",
          type: "restaurant",
          fa: "fas fa fa-cutlery",
          icon: "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/mapview/restarent.png",
        },
        {
          name: "Bars",
          type: "bar",
          fa: "fas fa fa-beer",
          icon: "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/mapview/bars.png",
        },
        {
          name: "Groceries",
          type: "supermarket",
          fa: "fas fa fa-shopping-cart",
          icon: "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/mapview/groceries.png",
        },
        {
          name: "Parks",
          type: "park",
          fa: "fas fa fa-tree",
          icon: "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/mapview/parks.png",
        },
        {
          name: "Shopping",
          type: "shopping_mall",
          fa: "fas fa fa-shopping-bag",
          icon: "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/mapview/shopping.png",
        },
        {
          name: "Entertainment",
          type: "night_club",
          fa: "fas fa fa-film",
          icon: "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/mapview/entertainment.png",
        },
      ],
      type: ["restaurant"],
      typeName: "",
      location: [],
      placeSearched: [],
      placeSearchedMarkers: [],
      markerList: [],
      showlist: false,
      showSearch: false,
      typeicon: 0,
      directionsService: {},
      directionsDisplay: {},
      communityName: "",
      destinationAdd: "",
    };
    this.mapClose = this.mapClose.bind(this);
    this.onetype = this.onetype.bind(this);
    this.searchPlace = this.searchPlace.bind(this);
    this.typeView = this.typeView.bind(this);
    this.flyToPlace = this.flyToPlace.bind(this);
    this.methodx = this.methodx.bind(this);
    this.destinationLocation = this.destinationLocation.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.resetData = this.resetData.bind(this);
  }
  async componentDidMount() {
    await this.setState({
      latitude: this.props.latitude,
      longitude: this.props.longitude,
      showlist: false,
      communityName: this.props.communityName,
    });
  }
  async resetData() {
    this.methodx();
  }
  destinationLocation() {
    var address = "";
    const _this = this;
    var places = new google.maps.places.Autocomplete(
      document.getElementById("txtPlaces")
    );
    google.maps.event.addListener(places, "place_changed", function () {
      var place = places.getPlace();
      address = place.formatted_address;
      _this.setState({ destinationAdd: address });
    });
  }
  async handleFormSubmit() {
    const _this = this;
    await _this.searchPlace();
    var locations = {
      lat: Number(this.props.communityObject.latitude),
      lng: Number(this.props.communityObject.longitude),
    };
    const customMap = new google.maps.Map(
      document.getElementById("map" + this.props.communityObject.community_id),
      {
        zoom: 7,
        center: locations,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      }
    );
    await _this.setState({ customMap });
    await MapController.customMarkers(
      this.props.communityObject,
      "singlemark",
      this,
      "",
      "https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/location_pin_2.svg",
      customMap
    );
    const community_lat = this.props.communityObject.latitude;
    const community_long = this.props.communityObject.longitude;
    var request = {
      origin: { lat: Number(community_lat), lng: Number(community_long) },
      destination: this.state.destinationAdd,
      travelMode: google.maps.DirectionsTravelMode.DRIVING,
    };

    _this.state.directionsService.route(request, function (response, status) {
      if (status == google.maps.DirectionsStatus.OK) {
        _this.state.directionsDisplay.setDirections(response);
        var distance = response.routes[0].legs[0].distance.text;
        var duration = response.routes[0].legs[0].duration.text;
        var dvDistance = document.getElementById("dvDistance");
        dvDistance.innerHTML = "";
        dvDistance.innerHTML += "<h4>YOUR COMMUTE SUMMARY</h4>";
        dvDistance.innerHTML += "<p>Drive Time: " + duration + "</p>";
        dvDistance.innerHTML += "<p>Distance: " + distance + "</p>";
        function makeMarker(position, icon, title, map) {
          let mark = new google.maps.Marker({
            position: position,
            map: map,
            icon: icon,
            title: title,
          });
          console.log(mark);
        }
        var leg = response.routes[0].legs[0];
        makeMarker(
          leg.start_location,
          "https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/location_pin_2.svg",
          "title",
          _this.state.customMap
        );
        makeMarker(
          leg.end_location,
          s3BucketUrl_WebsiteImages + "SuiteAmerica-Map-Marker-White.png",
          "title",
          _this.state.customMap
        );
      }
    });
  }
  async methodx() {
    await this.setState({
      directionsService: {},
      directionsDisplay: {},
      destinationAdd: "",
    });
    document.getElementById("dvDistance").innerHTML = "";
    $("#txtPlaces").val("");
    if ($("#map" + this.props.communityObject.community_id).length) {
      var locations = {
        lat: Number(this.props.communityObject.latitude),
        lng: Number(this.props.communityObject.longitude),
      };
      const customMap = new google.maps.Map(
        document.getElementById(
          "map" + this.props.communityObject.community_id
        ),
        {
          zoom: 7,
          center: locations,
          mapTypeId: google.maps.MapTypeId.ROADMAP,
        }
      );
      await this.setState({ customMap });
      MapController.customMarkers(
        this.props.communityObject,
        "singlemark",
        this,
        "",
        s3BucketUrl_WebsiteImages + "SuiteAmerica-M" + "ap-Marker-Red.png",
        customMap
      );
    }
  }
  async loadPlacesWithMarkers(index) {
    const _this = this;
    const markers = [];
    var pyrmont = {
      lat: this.state.latitude,
      lng: this.state.longitude,
    };
    const map = (this.map = new google.maps.Map(
      document.getElementById("map" + this.props.communityObject.community_id),
      {
        zoom: 14,
        center: pyrmont,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      }
    ));
    var service = new google.maps.places.PlacesService(map);
    const request = {
      location: pyrmont,
      radius: 2000,
      type: this.state.type,
    };
    service.nearbySearch(request, async (data, status) => {
      var infowindow = new google.maps.InfoWindow();
      if ("OK" === google.maps.places.PlacesServiceStatus.OK) {
        const location = [];
        var results = _.orderBy(data, ["name"], ["asc"]);
        for (var i = 0; i < results.length; i++) {
          location.push({
            latitude: results[i].geometry.location.lat(),
            longitude: results[i].geometry.location.lng(),
            name: results[i].name,
            address: results[i].vicinity,
          });
          const marker = (this.marker = new google.maps.Marker({
            map: map,
            position: results[i].geometry.location,
            center: results[i].geometry.location,
            icon: this.state.types[this.state.typeicon].icon,
          }));
          google.maps.event.addListener(
            marker,
            "click",
            (function (markerd, ii) {
              return function () {
                var contentString = "";
                var prop = "photos";
                if (results[ii].hasOwnProperty(prop)) {
                  contentString =
                    '<h3 style="font-size:20px; text-align:center">' +
                    results[ii].name +
                    '</h3><img src="' +
                    results[ii].photos[0].getUrl() +
                    '" alt="Loading" width="160" height="80"> <div id="content"><div className=""><div></div><p>' +
                    results[ii].vicinity +
                    '</p> <a href="' +
                    "https://www.google.com/maps/place/" +
                    results[ii].vicinity +
                    '" target="_blank">Get Directions</a></div>' +
                    "</div>";
                } else {
                  contentString =
                    '<h3 style="font-size:20px; text-align:center">' +
                    results[ii].name +
                    '</h3> <div id="content"><div className=""><div></div><p>' +
                    results[ii].vicinity +
                    '</p> <a href="' +
                    "https://www.google.com/maps/place/" +
                    results[ii].vicinity +
                    '" target="_blank">Get Directions</a></div>' +
                    "</div>";
                }
                infowindow.setContent(contentString);
                infowindow.open(map, this);
              };
            })(marker, i)
          );
          markers.push(marker);
        }
        await _this.setState({
          location: location,
          markerList: markers,
        });
        _this.getDistance();
      }
    });
    _this.setState({
      map,
    });
    await MapController.customMarkers(
      this.props.communityObject,
      "singlemark",
      this,
      "",
      s3BucketUrl_WebsiteImages + "SuiteAmerica-M" + "ap-Marker-Red.png",
      map
    );
  }
  flyToPlace(row, index, type) {
    var latLng = new google.maps.LatLng(row.latitude, row.longitude);
    this.map.panTo(latLng);
    const placemarker =
      type == "locations"
        ? this.state.markerList
        : this.state.placeSearchedMarkers;
    google.maps.event.trigger(placemarker[index], "click");
  }
  async getDistance() {
    for (var i = 0; i < this.state.location.length; i++) {
      if (
        this.state.location[i].latitude != "" &&
        this.state.location[i].latitude != undefined &&
        this.state.location[i].latitude != null &&
        this.state.location[i].longitude != "" &&
        this.state.location[i].longitude != undefined &&
        this.state.location[i].longitude != null
      ) {
        const distanceVal = this.distanceCal(
          this.state.latitude,
          this.state.longitude,
          this.state.location[i].latitude,
          this.state.location[i].longitude
        );
        let updatedPosts = update(this.state.location, {
          [i]: {
            distance: {
              $set: parseFloat(distanceVal),
            },
          },
        });
        await this.setState({
          location: updatedPosts,
        });
      } else {
        let updatedPosts = update(this.state.location, {
          [i]: {
            distance: {
              $set: 0,
            },
          },
        });
        await this.setState({
          location: updatedPosts,
        });
      }
      if (this.state.location.length == i + 1) {
        await this.setState({
          location: _.sortBy(this.state.location, [
            function (o) {
              return o.distance;
            },
          ]),
        });
      }
    }
  }
  distanceCal(lat1, lon1, lat2, lon2, unit) {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit == "K") {
      dist = dist * 1.609344;
    }
    if (unit == "N") {
      dist = dist * 0.8684;
    }
    return dist.toFixed(2);
  }
  async onetype(row, type) {
    $(".nav-link").removeClass("active");
    $("#" + row.name).addClass("active");
    await this.setState({
      type: [row.type],
      showlist: true,
      typeName: row.name,
      typeicon: type,
      showSearch: false,
    });
    this.loadPlacesWithMarkers(1, this.state.typeicon);
  }
  async typeView() {
    $(".nav-link").removeClass("active");
    $("#nameone").addClass("active");
    this.setState({
      showlist: false,
      showSearch: false,
    });
    this.methodx();
  }
  searchPlace() {
    this.setState({
      showSearch: true,
      showlist: true,
    });
  }
  mapClose() {
    this.setState({
      showlist: true,
      type: ["restaurant"],
      typeicon: 0,
      showSearch: false,
    });
    this.props.viewPlaces();
  }
  render() {
    var finalLocation = _.orderBy(this.state.location, ["name"], ["asc"]);
    return (
      <div className="">
        <div className="row">
          <div className="col-md-8 pd-r-0-force ">
            <ul className="nav nav-tabs">
              <li className="nav-item" onClick={this.typeView}>
                <a className="nav-link active" id="nameone">
                  {" "}
                  <i className="fas fa fa-home"></i> <span>Commute</span>
                </a>
              </li>
              {this.state.types.map((row, index) => {
                return (
                  <li
                    className="nav-item"
                    key={index}
                    onClick={() => this.onetype(row, index)}
                  >
                    <a className="nav-link" id={row.name}>
                      {" "}
                      <i className={"" + row.fa + ""}></i>{" "}
                      <span>{row.name}</span>
                    </a>
                  </li>
                );
              })}
            </ul>
            <div className="lc_map">
              <div className="map-alignment">
                <div
                  id={"map" + this.props.communityObject.community_id}
                  className="map-alignment"
                ></div>
              </div>
            </div>
          </div>
          <div className="col-md-4 communities-view-listview">
            {this.state.showlist && !this.state.showSearch ? (
              <div className="tab-pane active">
                <h2 className="location_search_head">{this.state.typeName}</h2>
                <ul className="location_area_list">
                  {finalLocation.map((row, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() => this.flyToPlace(row, index, "locations")}
                      >
                        {" "}
                        {row.name} <span>{row.address}</span>
                        {
                          <span className="location_distance">
                            {row.distance} mi
                          </span>
                        }
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : (
              <div>
                <div className="first_view">
                  <p>WHAT’S MY COMMUTE?</p>
                  <input
                    type="text"
                    id="txtPlaces"
                    placeholder="Enter a location"
                    onChange={this.destinationLocation}
                  />
                  <div className="view-btns">
                    <button
                      type="button"
                      className="location_btn"
                      onClick={this.handleFormSubmit}
                    >
                      Search
                    </button>
                    <button
                      type="button"
                      className="location_btn"
                      onClick={this.resetData}
                    >
                      Reset
                    </button>
                  </div>
                  <div id="dvDistance"></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default SearchMap;
