import CommunityMapView  from '../nikeDs/communityMapView';
import NoPricingMapView  from '../noPricingDs/noPricingMapView';
import AppleMapView  from '../appleDs/appleMapView';
import VirtualMapView  from '../virtual/communityMapView';
class PropertyMapView extends React.Component {    
      constructor(props){
        super(props);
        this.state={
            companyArray: ['nike','apple','nopricing']
          }
      }
      templateLoad(){
        const companyInfo = this.props.match.params
        let data;
        if(companyInfo.companyName=='nike' || companyInfo.companyName=='pricing'){
            data= <CommunityMapView itineraryData={companyInfo}/>
        }else if(companyInfo.companyName=='apple'){
            data= <AppleMapView itineraryData={companyInfo}/>
        }else if(companyInfo.companyName=='nopricing'){
            data= <NoPricingMapView itineraryData={companyInfo}/>
        }else if(companyInfo.companyName=='virtual'){
            data= <VirtualMapView itineraryData={companyInfo}/>
        }
        return data;
      }
      render(){        
          return(<span>{this.templateLoad()}</span>)
      }
}
export default PropertyMapView