import Buttons from "./buttons";

const Gallery=(props)=>{
    return(
        <div>
        <div className="container">
         <div className="bungalowmaincontent">
             <h1>{props.images.heading}</h1>
             <p>{props.images.Paragraph}</p>
          <div className="row no-gutters">
          {props.images.data.map((row,index)=>{
              return(
                <div className="col-md-4 col-sm-4 " key={index}>
                <div className="item zoom-on-hover">
                        <a className="lightbox" >
                            <img className="img-responsive image" src={row.Image}/>
                        </a>
                    </div>
            </div>
              )
          })
          }
        </div>
      </div>
     
     </div>
     <Buttons Button={'CONTACT US'}/>
 </div>
)
}
    export default Gallery;