/* Importing the node modules, child components, services and controllers used 
   inside RiotGamesQuestionnaire component */    
   import $ from "jquery";
   import DatePicker from 'react-datepicker'
   import moment from 'moment'
   import AppController from "../../controller/appController";
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import RiotGamesHeader from "../../common/riotgamesHeader";
   import NikeServices from '../../../services/nikeService';
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   import RiotGamesFooter from "./common/riotgamesFooter";
   import ReCAPTCHA from "react-google-recaptcha"
   import SuccessModal from '../../common/successModal';
   /* RiotGamesQuestionnaire  Component initialization */
   class RiotGamesQuestionnaire extends React.Component {
       /* Initializing objects of its RiotGamesQuestionnaire class */
       constructor(props) {
           super(props)
           this.state={
               CaptchaValue:'',
               name: '',
               email: '',
               phone: '',
               moveInDate: moment(),
               moveOutDate: moment(),
               gender: '',
               smoke: '',
               vehicle: '',
               sleepHabbit: 'Early Bird',
               roomateName: '',
               educationLevel: 'Undergraduate',
               bedSize: '1 Bedroom',
               message: '',
               color: '',
               errorMessage: '',
               roommate: '',
               sleepHabbitDropDown:['Early Bird','Night Owl'],
               appartmentSizeDropDown:['1 Bedroom','2 Bedrooms','3 Bedrooms'],
               educationDropDown:['Undergraduate','Graduate']
           }
           this.setValue = this.setValue.bind(this);
           this.setChecked = this.setChecked.bind(this);
           this.handleMoveIn = this.handleMoveIn.bind(this);
           this.handleMoveOut = this.handleMoveOut.bind(this);
           this.checkedStatus = this.checkedStatus.bind(this);
           this.serviceRequest = this.serviceRequest.bind(this);
           this.onChangeCaptcha = this.onChangeCaptcha.bind(this);
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
           MainController.getInitialInformation();
        }   
       async onChangeCaptcha(CaptchaValue) {
        await this.setState({CaptchaValue})
        }
        /* Form Details object creations  */
      setValue(field, e) {
            var object = {};
            object[field] = e.target.value;
            this.setState(object);
          }
      /* Form Details object creations  */
      setChecked(field, e) {
          if(field == 'gender'){
            this.setState({gender:e.target.value});
          }else if(field == 'smoke'){
            this.setState({smoke:e.target.value});
          }else if(field == 'vehicle'){
            this.setState({vehicle:e.target.value});
          }
      }
      checkedStatus(field, e){
        this.setState({roommate:e.target.checked ? 'No' : 'Yes'});
      }   
     /* MoveIndate and MoveOutDate   */
      async handleMoveIn(date){
            await this.setState({moveInDate: moment(date),
            moveInDateSelected:date.format('YYYY-MM-DD HH:mm:ss') });
        }    
      async handleMoveOut(date){
            await this.setState({moveOutDate: moment(date),
            moveOutDateSelected:date.format('YYYY-MM-DD HH:mm:ss') });
        } 
       /* Service Request Details send to mail  */      
       async serviceRequest(){
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        const moveIn = this.state.moveInDateSelected == '' ? this.state.moveInDate.format('YYYY-MM-DD HH:mm:ss'): this.state.moveInDateSelected ;
        const moveOut = this.state.moveOutDateSelected == '' ? this.state.moveOutDate.format('YYYY-MM-DD HH:mm:ss'): this.state.moveOutDateSelected ;
          console.log(moveOut,moveIn)
        const _this = this
           const validationFields=['name','email','phone','moveInDate','moveOutDate','CaptchaValue']
           let error = 0;
           _.forEach(validationFields, function(value) {
              if(_this.state[value] == ""){
                error++;
              }
          });
          if(!reg.test(_this.state.email)){
            error++;
          }
         if(error == 0 ){
        const Request={
            name:this.state.name,
            email:this.state.email,
            phone:this.state.phone,
            moveInDate:this.state.moveInDate.format('YYYY-MM-DD'),
            moveOutDate:this.state.moveOutDate.format('YYYY-MM-DD'),
            gender:this.state.gender == '' ? 'NA' : this.state.gender ,
            smoke:this.state.smoke == '' ? 'No' : this.state.smoke,
            vehicle:this.state.vehicle ==  '' ? 'No' : this.state.vehicle,
            sleepHabbit:this.state.sleepHabbit,
            roomateName:this.state.roomateName,
            educationLevel:this.state.educationLevel,
            bedSize:this.state.bedSize,
            message:this.state.message,
           mail:'serviceNowIntern-questioner',
           roommate: this.state.roommate == '' ? 'Yes' : this.state.roommate,
           subject: 'riotgamesIntern Questionnaire'  
        }  
        this.setState({
            name: '',
            email: '',
            phone: '',
            moveInDate: moment(),
            moveOutDate: moment(),
            gender: '',
            smoke: '',
            vehicle: '',
            sleepHabbit: 'Early Bird',
            roomateName: '',
            educationLevel: 'Undergraduate',
            bedSize: '1 Bedroom',
            message: '',
            roommate: '',
            color:'',
            errorMessage:'',
            CaptchaValue:'',
        })
        this.recaptcha.reset();
        $('input[name="1"]').prop('checked', false);
        $('input[name="2"]').prop('checked', false);
        $('input[name="3"]').prop('checked', false);
        $('input[name="4"]').prop('checked', false);
        AppController.showSuccessModal();    
        const Response=await NikeServices.serviceRequest(Request);  
        console.log(Response)  
    }
    else{
        AppController.menuTopNavigation('serviceRequest');
        this.setState({errorMessage:'Please Enter Required Fields, Valid Email, Captcha & MoveOutDate is greater than are equal to MoveInDate ',color:'red'})
    } 
       }   
       /* It is invoked to return html content */
       render() {   
           return (
            <div className="corporateHousing_Updates">
                <GuestHeader/>
                <div className="cover-tophd-ht"></div>
                <RiotGamesHeader />
                <div className="container-fluid nike company_section" id="serviceRequest">
                    <div className="container text-center">
                        <h2 className="">Intern Questionnaire</h2>
                        <p>Please fill in the questionnaire below and press the Submit button. An email will then be sent to SuiteAmerica with your choices.</p>
                    </div>
                </div>
                <div className="container-fluid nike-datepicker bg-grey service_request">
                    <div className="request_form company_form" >
                        <div className="container">
                            <form className="well">
                                <p style={{color:this.state.color}}>{this.state.errorMessage}</p>
                                <h1>Personal Information</h1>
                                <div className="row">
                                <div className="col-sm-4 col-md-4">
                                    <input type="text" className="form-control" placeholder="*Your Name" value={this.state.name} 
                                    onChange={(e) => this.setValue('name', e)}/>
                                    <label>Gender</label>
                                    <p className="sh-input"><i>(All matches will be based upon gender)</i></p>
                                    <div className="row">
                                        <div className="clearfix"></div>
                                        <div className="col-sm-6">
                                            <label className="custom_radio radio-inline">Male
                                            <input type="radio" name="1" value="male" 
                                            onChange={(e) => this.setChecked('gender', e)} />
                                            <span className="checkmark"></span>
                                            </label>
                                        </div>
                                        <div className="col-sm-6">
                                            <label className="custom_radio radio-inline">Female
                                            <input type="radio" name="1" value="female" 
                                            onChange={(e) => this.setChecked('gender', e)}/>
                                            <span className="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-md-4">
                                    <input type="text" className="form-control" placeholder="*Phone Number" value={this.state.phone} 
                                    onChange={(e) => this.setValue('phone', e)} />
                                    <label>Do You Smoke?</label>
                                    <p className="sh-input"><i>(All communities are non-smoking)</i></p>
                                    <div className="row">
                                        <div className="clearfix"></div>
                                        <div className="col-sm-6">
                                            <label className="custom_radio radio-inline">Yes
                                            <input type="radio" name="2" value="yes" 
                                            onChange={(e) => this.setChecked('smoke', e)} />
                                            <span className="checkmark"></span>
                                            </label>
                                        </div>
                                        <div className="col-sm-6">
                                            <label className="custom_radio radio-inline">No
                                            <input type="radio" name="2" value="no" 
                                            onChange={(e) => this.setChecked('smoke', e)}/>
                                            <span className="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-md-4">
                                    <input type="text" className="form-control" placeholder="*Email" value={this.state.email} onChange={(e) => this.setValue('email', e)}/>
                                    <label>Will you have a vehicle?</label>
                                    <p className="sh-input"><i>(Parking may be subject to additional fees and could be located off site)</i></p>
                                    <div className="row">
                                        <div className="clearfix"></div>
                                        <div className="col-sm-6">
                                            <label className="custom_radio radio-inline">Yes
                                            <input type="radio" name="3" value="yes" 
                                            onChange={(e) => this.setChecked('vehicle', e)} />
                                            <span className="checkmark"></span>
                                            </label>
                                        </div>
                                        <div className="col-sm-6">
                                            <label className="custom_radio radio-inline">No
                                            <input type="radio" name="3" value="no" 
                                            onChange={(e) => this.setChecked('vehicle', e)}/>
                                            <span className="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <hr />
                                <h1>Additional Details</h1>
                                <div className="row">
                                <div className="col-sm-4 col-md-4">
                                    <DatePicker type="text" className="form-control" placeholder="*Arrival Date" selected={this.state.moveInDate}  
                                    onChange={this.handleMoveIn} />
                                    <input type="text" className="form-control" placeholder="*Chosen Roommate Name (If Applicable)" value={this.state.roomateName} 
                                    onChange={(e) => this.setValue('roomateName', e)}/>
                                </div>
                                <div className="col-sm-4 col-md-4">
                                    <DatePicker type="text" className="form-control" placeholder="*Departure Date" selected={this.state.moveOutDate}  
                                    onChange={this.handleMoveOut} />
                                    <label className="custom-select">
                                        <select value={this.state.educationLevel} 
                                        onChange={(e) => this.setValue('educationLevel', e)}>
                                            {this.state.educationDropDown.map((row, index) => {
                                            return(
                                            <option key={index} value={row}>{row}</option>
                                            )
                                            })}
                                        </select>
                                    </label>
                                </div>
                                <div className="col-sm-4 col-md-4">
                                    <label className="custom-select">
                                        <select value={this.state.sleepHabbit} 
                                        onChange={(e) => this.setValue('sleepHabbit', e)}>
                                            {this.state.sleepHabbitDropDown.map((row, index) => {
                                            return(
                                            <option key={index} value={row}>{row}</option>
                                            )
                                            })}
                                        </select>
                                    </label>
                                </div>
                                <div className="col-sm-4 col-md-4">
                                    <label className="custom-select">
                                        <select value={this.state.bedSize} 
                                        onChange={(e) => this.setValue('bedSize', e)}>
                                            {this.state.appartmentSizeDropDown.map((row, index) => {
                                            return(
                                            <option key={index} value={row}>{row}</option>
                                            )
                                            })}
                                        </select>
                                    </label>
                                </div>
                                </div>
                                <div className="row">
                                
                                <div className="container">
                                    <div className="clearfix"></div>
                                    <div className="col-sm-12">
                                        <label className="custom_checkbox checkbox-inline mrg-top-20">
                                        Click here if you would prefer not to have a roommate<br/>
                                        <span><i>(please reference the rate information under the Communities tab for pricing without roommates)</i></span>
                                        <input type="checkbox" name="4" 
                                        onChange={(e) => this.checkedStatus('roommate', e)} />
                                        <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                </div>
                                <hr />
                                <h1>Notes</h1>
                                <div className="row">
                                <div className="col-sm-12 col-md-12">
                                    <textarea className="form-control" placeholder="If you have a disability or special accommodations required for consideration, please note here:" value={this.state.message} 
                                    onChange={(e) => this.setValue('message', e)} rows="8" cols="5"></textarea>
                                </div>
                                </div>
                                            
                                <div className="row">
               <div className="col-sm-12 col-md-12">
                 <ReCAPTCHA
                  ref={ ref => this.recaptcha = ref }
                  sitekey="6Lcbw1AUAAAAAE5K5cYDyhoGu0RJY9C3CLtGFXAo"
                  onChange={this.onChangeCaptcha}
                />
          </div>
          
       </div>
                            

                            </form>
                            <button type="button" className=" btn location_btn mrg-btm-70" 
                            onClick={this.serviceRequest}> SUBMIT</button>
                        </div>
                    </div>
                </div>
                <div className="container ft-contact-adrs mrg-btm-30 mrg-top-30">
                   <RiotGamesFooter />
                </div>    
            <SubFooter/>
            <Footer/>
            <SuccessModal/>
        </div>   
           );
       }
   }
   export default RiotGamesQuestionnaire;