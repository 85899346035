/* Importing the node modules, child components, services and controllers used 
   inside LockBox component */   
   import $ from "jquery";
   import AppController from "../../controller/appController";
   import SubFooter from "../../common/subFooter";
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';   
   /* LockBox Component initialization */
   class LockBox extends React.Component {
       /* Initializing objects of its LockBox class */
       constructor(props) {
           super(props);   
       }
       /* It is invoked immediately before mounting occurs */
       componentWillMount() {
           AppController.removeLocalStorageData();
           $('html, body').animate({scrollTop: 0});
           AppController.stickyTitles();     
       }
       /* It is invoked immediately after mounting occurs */
       componentDidMount(){
           this.autoPlayVideo();
       }
       autoPlayVideo() {
           const id = '#global-background';
           const source = 'https://s3-us-west-1.amazonaws.com/suiteam-website/SA-lockbox-instructions.mp4';
           AppController.autoPlayVideo(id, source);
       }
       /* It is invoked to return html content */
       render() {
           return (
               <div>
               <GuestHeader/>
               <header className="codeconveyHeader corporate-h fullvideo lockbox_video">
                   <div className="video-wrapper">
                       <video autoPlay playsInline muted  loop id="global-background" preload="auto">
                           <source type="video/mp4" />
                       </video>
                   </div>
               </header>               
               <SubFooter/>
               <Footer/>
           </div>
   
           );
       }
   }
   export default LockBox;