/* Importing the node modules, child components, services and controllers used 
   inside RelocationCustomization component */   
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   import ReserveYourStay from '../../common/reserveYourStay.js';
   import MetaTags from '../../common/metaTags';
   import ServerMetaTags from '../../../server/metaTags'; 
   import NavigationFooter from '../../common/navigationFooter';
   import NavigationData from '../../common/navigation.json';
     
   /* RelocationCustomization Component initialization */
   class RelocationCustomization extends React.Component {
       /* Initializing objects of its RelocationCustomization class */
       constructor(props) {
           super(props); 
           this.state = {
            navigationData: NavigationData.relocationCustomization
         }  
       }
       /* It is invoked immediately before a component is mounted */  
       componentWillMount() {
           MainController.getProfilePageInformation(9, 2, 2);
       }
       /* It is invoked to return html content */
       render() {
           const metaData = ServerMetaTags.urlString.getMetaData(this.props.match.url);
           return (
            <div>
                <MetaTags 
                metaTitle = {metaData.title}
                metaDesription = {metaData.description}
                metaUrl = {metaData.canonical} />
                <GuestHeader/>
                <header className="codeconveyHeader cc_image_text_main corporate-h video-wrapper">
                <img className="img-responsive relocation-corporate-housing" src={s3BucketUrl_WebsiteImages+"Relocation-Corporate-Housing-Customize-Your-Stay.jpg"} width="100%" alt=""  />
                <div className="wow fadeInUp img-on-text top40 width500 text-center left10 img-on-text-center" data-wow-delay="0.5s">
                    <h1>BE OUR GUEST</h1>
                    <p>Having the “little things” during the big move is what we’re here for. Our Concierge Services team is available 24/7 to help transferees settle in with additional amenities and services, so you can be confident all of their needs will be met. 
                    </p>
                </div>
                </header>
                <ReserveYourStay />
                <div className="who_weare services_ch">
                <div className="cc_image_text_main">
                    <img className="img-responsive left-mo" src="https://s3-us-west-1.amazonaws.com/suiteam-website/images-website/Home-Landing-Concierge.jpg" alt="" />
                </div>
                </div>
                <div className="new_txt_sec">
                <div className="new_txt_content container wow fadeInUp animated">
                    <div className="col-md-8 col-md-offset-2">
                        <h2>CUSTOMIZE YOUR PROGRAM</h2>
                        <p>With almost 30 years of experience servicing housing needs around the globe, we can create a unique, customizable program for each of your clients. Our centralized information system ensures efficient service and real-time reporting regardless of program size, structure or duration.
                        </p>
                        <p><strong>Intern Programs, Group Moves, Long Term Housing, VIPs, Project Launches </strong><i>and more</i>
                        </p>
                    </div>
                </div>
                <div className="clearfix"></div>
                </div>
                <div className="quote-section bg-grey quote_red">
                <div className="container">
                    <div className="wow fadeInUp" data-wow-delay="0.5s">
                        <h6 className="quote-s"> <img src={s3BucketUrl_WebsiteImages+"Corporation-Corporate-Housing-Quote.png"} alt=""/></h6>
                        <div className="col-md-10 col-md-offset-1">
                            <h5 className="quote-text">It was wonderful to see all those groceries in my refrigerator when I arrived. 
                            Lifted my spirit after a very long f light and arriving at mid-night, very thoughtful. 
                            Thank you again for all the great welcome and help!
                            </h5>
                        </div>
                    </div>
                </div>
                </div>
                <div className="clearfix"></div>
                <NavigationFooter {...this.state} />
                <SubFooter />
                <Footer/>
            </div>
         
           );
       }
   }
   export default RelocationCustomization;