import React, { Component } from 'react'
import SessionService from "../services/sessionService";

export default class ReRoutes extends Component {
    constructor(props) {
        super(props)

        this.state = {
            ValidSession: false
        }
    }

    componentDidMount() {
        const { functionType } = this.props;
        if(functionType == "decide") {
            const userData = SessionService.SessionGet({ type: 'guest' })
            var p1 = Promise.resolve(userData);
            Promise.all([p1]).then(values => {
                if (values[0].error == 1) {
                    const userData = SessionService.SessionDestory({ type: 'guest' });
                    location.href = '/guest-login';
                    this.setState({ ValidSession: false })
                } else {
                    this.setState({ ValidSession: true })
                }
            }).catch(error => {
                this.setState({ ValidSession: true })
            })
        } else if(functionType=="fivestar") {
            const userData = SessionService.SessionGet({ type: 'fivestar' });
            var p1 = Promise.resolve(userData);
            Promise.all([p1]).then(values => {
                if (values[0].error == 1) {
                    const userData = SessionService.SessionDestory({ type: 'fivestar' });
                    location.href = '/fivestar';
                    this.setState({ ValidSession: false })
                } else {
                    this.setState({ ValidSession: true })
                }
            }).catch(error => {
                this.setState({ ValidSession: true })
            })
        }else if(functionType=="intranet") {
            const userData = SessionService.SessionGet({ type: 'intranet' });
            var p1 = Promise.resolve(userData);
            Promise.all([p1]).then(values => {
                if (values[0].error == 1) {
                    const userData = SessionService.SessionDestory({ type: 'intranet' });
                    //location.href = '/intranet2-login';
                    location.href = '/invalid';
                    this.setState({ ValidSession: false })
                }else {
                    this.setState({ ValidSession: true })
                }
            }).catch(error => {
                this.setState({ ValidSession: true })
            })
        }else if(functionType=="travelBooking") {
            const emailId = localStorage.getItem('travel-email');
            const password = localStorage.getItem('travel-password');
            if ((emailId == undefined || emailId == null) && (password == undefined || password == null)) {
                location.href = "/travel-booking";
                this.setState({ ValidSession: false })
            }else {
                this.setState({ ValidSession: true })
            }
        }else if(functionType=="clientCe"){
            const userData = SessionService.SessionGet({ type: 'client' });
            var p1 = Promise.resolve(userData);
            Promise.all([p1]).then(values => {
                if (values[0].error == 1) {
                    const userData = SessionService.SessionDestory({ type: 'client' });
                    location.href = '/';
                    this.setState({ ValidSession: false })
                }
                else if (values[0].roleId != 3 && values[0].ClientType != 'client') {
                    location.href = '/';
                    this.setState({ ValidSession: false })
                }else {
                    this.setState({ ValidSession: true })
                }
            }).catch(error => {
                this.setState({ ValidSession: true })
            })
        }else if(functionType=="_u_web"){
            const web = localStorage.getItem('_u_web');
            const userData = EncryptDecrypt.decryptData({ 'type': web })
            var p1 = Promise.resolve(userData);
            Promise.all([p1]).then(values => {
                if (values[0].recordset == false) {
                    localStorage.removeItem('_u_web')
                    location.href = '/';
                    this.setState({ ValidSession: false })
                }
                else if (values[0].recordset[0].roleId != 3 || values[0].recordset[0].ClientType == 'CE') {
                    location.href = '/';
                    this.setState({ ValidSession: false })
                }else {
                    this.setState({ ValidSession: true })
                }
            }).catch(error => {
                this.setState({ ValidSession: true })
            }) 
        }


    }

    render() {
        const { componentS: ComponentS, componentF: ComponentF, props } = this.props;

        return (
            <React.Fragment>
                {
                    this.state.ValidSession ?
                        <ComponentS {...props} />
                        :
                        <ComponentF {...props} />
                }
            </React.Fragment>
        )
    }
}
