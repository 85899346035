const PhotoModal=(props)=>{
  let res = props.CommunityImages
  const data = localStorage.getItem('item')

  const moveFirstToHead = ([head, ...tail], predicate, accumulator = []) =>
    (predicate.call(null, head)) ?
      [head].concat(accumulator).concat(tail) :
      moveFirstToHead(tail, predicate, accumulator.concat(head));

  const result = moveFirstToHead(res, o => o.image_name === data);
return(
    <div className="modal " id="galleryview" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog " role="document">
      <div className="modal-content animated zoomIn">
        <div className="modal-header">
        <button type="button" className="close btn" data-dismiss="modal" aria-label="Close" onClick={props.closeModal}>
        <i className="bi bi-x"></i>
          </button>
        </div>
        <div className="modal-body">
        <div className="outer">
            <div id={"sync1"+15} className="photoG_bgG owl-carousel owl-theme">
                  {result.map((row, index) => { 
                    return (
                    <div className="item" key={index} >
                        <img className="img-responsive image" src={row.image_name}/>
                      </div>) 
                    })}</div>
              
              <div id={"sync2"+15}  className="photoG_smG owl-carousel owl-theme">
                  {result.map((row, index) => { 
                    return (
                    <div className="item" key={index}>
                    <img className="img-responsive image" src={row.image_name}/>
                      </div>
                      ) })}</div>
                      </div>
        </div>
      </div>
    </div>
  </div>


)
}
export default PhotoModal;