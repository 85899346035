import GuestHeader from "../../common/guestHeader";
import AppController from "../../controller/appController";
import SubFooter from "../../common/subFooter";
import Footer from "../../common/footer";
class Future extends React.Component{
    constructor(props){
        super(props)
        this.state={
          
        }
    }
    async componentWillMount(){
        AppController.removeLocalStorageData();
        $('html, body').animate({scrollTop: 0});
        AppController.stickyTitles();
      }
render(){
    return(
        <div>
        <GuestHeader/>
    	<div className="sa-fururepagebanner">
            <img src="assets/images/future/future-banner.png" alt="" className="width100per" />
                <div className="bannertext">
                    <h1>SUITEAMERICA <span> | THE ENHANCED EXPERIENCE </span></h1>
                </div>
		
	    </div>
        <div className="sa-futurebbootm">
		<div className="top">
		<p>
		Through our attention to detail and enhanced platforms, we’ve created a better, more efficient client experience. We further enhanced our technology because we don’t wait for the next best thing. We create it. Fully-integrated and streamlined, SuiteAmerica is the new wave of Corporate Housing and Destination Services.
		</p><p><span>
		Here’s how we’ve further enhanced the SuiteAmerica Experience over the last year. And a special glimpse into what we’ve got planned next.
		</span>
		</p>
		
		
		</div>
        <div className="tumbs">
					<div className="item">
						<img src="assets/images/future/cog.svg" alt="" />
						<p>MAXIMUM <br/> EFFICIENCIES</p>
					</div>
					<div className="item item2">
						<img src="assets/images/future/user.svg" alt="" />
						<p>BETTER GUEST<br/> EXPERIENCE</p>
					</div>
					<div className="item item3">
						<img src="assets/images/future/key.svg" alt="" />
						<p>INDUSTRY LEADING<br/> INNOVATION</p>
					</div>
				</div>
        </div>

        <div className="container-fluid" style={{ background: "transparent"}}>
			<div className="sa-futurebottom_content">
			
			<div className="col-md-5 paddingzero">
			<div  className="iteminmg iteminmg1">
			<img src="assets/images/future/sa-futurepage1.jpg" className="width100per height100per" alt="" />
			</div>
			</div>
			<div className="col-md-7 paddingzero">
				<div className="main main1" >
					<img src="/assets/newUI-images/future/cog-clr.svg" alt="" />
					<h1>MAXIMUM EFFICIENCIES</h1>
					<h2>With real-time data and streamlined communication, we’ve maximized efficiencies. Inventory options, response rates, and operations are more responsive than ever. APIs allow us to connect faster, anticipate needs, source inventory, respond quicker, and create a truly customized housing solution for every client.</h2>
					<p><b>More Responsive:</b> Automated routine communications reduced time spent sending manual communications by 90%, allowing for more personal connections at a faster response rate.</p>
					<p><b>Greater Inventory Possibilities: </b>Our Corporate Housing App (CHAPP) features its own APIs to connect with external suppliers and booking platforms.</p>
					<p><b>Directly Connected: </b>  Accessed on iPads, our 5 Star App directly connects field teams with live, instant data.</p>
					<p><b>Fully-Integrated API’s: </b> We initiate and integrate APIs internally and with partners, meaning we do more, we do it faster, and information is all in one place.</p>
					<p><b>Expanded Operations: </b>  More hours, more days a week, providing constant support and service.</p>
				</div>
			</div>
		</div>
			<div className="sa-futurebottom_content flex-d-r-r">
			<div className="col-md-5 paddingzero">
			<div  className="iteminmg iteminmg2">
				<img src="assets/images/future/sa-futurepage2.png" className="width100per height100per" alt="" />
			</div>
			</div>
			<div className="col-md-7 paddingzero">
				<div className="main main2">
					<img src="/assets/newUI-images/future/user-clr.svg" alt="" />
					<h1>BETTER GUEST EXPERIENCE</h1>
					<h2>We’ve improved processes to work more efficiently, while at the same time delivering higher service standards. Over the last year, we’ve provided more options and more flexibility for guests, creating a better experience.</h2>
					<p><b>Ease of Use: </b> Our convenient online Guest Portal and Apple TV app hold all information and status updates in one place, accessible anywhere. </p>
                    <p><b>Varied Communication Abilities:</b>  More options for guests to reach out at times and through modes they choose.</p>
                    <p><b>Faster Service: </b>Received instantly through the Apple TV app and Guest Portal, requests are handled faster. We react sooner, providing the industry leading service we’re known for.</p>
                    <p><b>Local Insight: </b>Our team of area insiders provide relevant information while consulting guests.</p>
                    <p><b>Flexible Entertainment Options: </b> Stream, choose a different TV package, and always connect to high speed Wifi. Comcast packages and bundles available for more variety.</p>
                    <p><b>Assigned Guest Experience Coordinator:</b>  One point of contact, expertly trained and experienced in the relocation journey, always ready and available to assist.</p>
				</div>
			</div>
		</div>
			<div className="sa-futurebottom_content">
			<div className="col-md-5 paddingzero">
			<div  className="iteminmg iteminmg3">
			<img src="assets/images/future/sa-futurepage3.png" className="width100per height100per" alt="" />
			</div>
			</div>
			<div className="col-md-7 paddingzero">
				<div className="main main3">
					<img src="assets/newUI-images/future/key-clr.svg" alt="" />
					<h1>INDUSTRY LEADING INNOVATION</h1>
					<h2>SuiteAmerica’s centralized IT ecosystem features diversified software platforms that provide the most up-to-date, live information, allowing internal and external teams to be constantly connected with accurate data through streamlined communication. All of our technology systems are built with mobility in mind and are accessible from any device.</h2>
					<p><b>Apple TV app: </b> Our Apple TV app shows reservation information, service request updates, local area guides, and more. Guests use one platform to view all the details of their stay.</p>
                    <p><b>360 Tours & 3D floorplans: </b> Tour, view, and explore available inventory from anywhere.</p>
					<p><b>Comprehensive Property Pages: </b> A wealth of information, including local school listings, bikescores, walkscores, demographics, amenities, and more, all in one place.</p>
				</div>
			</div>
			
		</div>
	</div>
    
<div className="container-fluid" style={{ background: "transparent"}}>
	<div className="safuturethumbs">
		
		<div className="thumbtext">
			<h1>THE FUTURE WAVE OF CAPABILITIES WITH<span> <b>SUITE</b>AMERICA</span> </h1>
			<div className="tumbimg">
				<div className="item">
					<img src="assets/images/future/smartphone.svg" alt="" />
					<h1>SMS UPDATES</h1>
					<p>Text message updates about booking confirmation, housekeeping dates, services, and more. Guests will have the flexibility to op-in/out of these text-based updates.</p>
				</div>
				<div className="item">
					<img src="assets/images/future/tv.svg" alt="" />
					<h1>APPLE TV APP ENHANCEMENTS </h1>
					<p>Local recommendations of restaurants, attractions, and more. Along with customizable, in-suite options.</p>
				</div>
				<div className="item">
					<img src="assets/images/future/chat.svg" alt="" />
					<h1>CHAT FEATURE</h1>
					<p>Directly connecting us to clients and guests through even more precise, exact channels.</p>
				</div>
			</div>
			
			
			<div className="tumbimg">
				<div className="item">
					<img src="assets/images/future/hand.svg" alt="" />
					<h1>AUTOMATED CONFIRMATIONS </h1>
					<p>Automated and unified communications about reservations provided consistently and precisely.</p>
				</div>
				<div className="item">
					<img src="assets/images/future/guest.svg" alt="" />
					<h1>GUEST PORTAL </h1>
					<p>More interactive, app driven interface providing fully comprehensive information.</p>
				</div>
				<div className="item">
					<img src="assets/images/future/car.svg" alt="" />
					<h1>DESTINATION SERVICES REIMAGINED</h1>
					<p>Three tiered, customizable programs tailored to every relocation journey. Our latest solution to provide more options, assistance, and flexibility for guests.</p>
				</div>
				<div className="item">
					<img src="assets/images/future/stamp.svg" alt="" />
					<h1>EXCLUSIVE HOME FINDING NETWORK</h1>
					<p>Our biggest, most daring innovation to date involves an online hub to conduct research, consult resources, and connect with Destination Services to find the perfect home.</p>
				</div>
			</div>
			
		</div>
	</div>	
	<div className="safuturebottom">
		<div className="container">
			<div className="commas">
			<h1>TESTIMONIALS</h1>
			<h2> <i className="fa fa-quote-left" aria-hidden="true"></i> SuiteAmerica thrives on a culture of anticipating the unexpressed wishes of our guests and providing a white glove service.</h2>
		<p>I’m thankful that our paths have crossed in perfect timing. You don’t know how much I appreciate our convos and allowing me to share this journey with you. Thank you for being a great listener and friend! Would love to meet you in person when you’re in the area (I hope that’s not weird LOL). 
Thank you for all your help and your support in making this transition as smooth as possible.</p>
		<p>A gleaming and positive experience has been Brigette’s help. She has been a life saver; totally accessible, accommodating, understanding and empathetic. She has really gone above and beyond to salvage our sanity. Her work should not go unnoticed. We appreciate all her time and help.</p>
		<p>Ms. Plexico, on behalf of my Family and my own I would like to thank You for everything You have done for me and my Family to make our stay in Arlington the way it was. It was truly a pleasant and enjoyable stay. I didn’t lack anything and You and Your team made my experience in the US the memorable one.</p>
		<p className="text-right"><i className="fa fa-quote-right" aria-hidden="true"></i></p>
		
			</div>
		</div>
	</div>
</div>
<SubFooter/>
<Footer/>
        </div>
    )
}
}
export default Future;