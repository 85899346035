const RescheduleModal = (props) => {
    const row = props.rowData
    const index = props.index
    return (
        <div className="modal fade savesearch" id="reschedule" role="dialog">
            {!row.IsReSchedule ? <div className="modal-dialog">
                <div className="modal-content rm-border-radius">
                    <div className="modal-header">
                        {props.modalLoader == true ? '' : <button type="button" className="close btn" data-dismiss="modal" aria-label="">
                            <i className="bi bi-x"></i>
                        </button>}
                    </div>
                    <div className="modal-body">
                        <h2>Reason For Reschedule</h2>
                        <table className="table table-striped table-bordered table-hover text-left">
                            <tbody>
                                <tr>
                                    <th>
                                        Community
                                    </th>
                                    <td>{row.community_name}</td>
                                </tr>
                                <tr>
                                    <th>
                                        SuiteNo
                                    </th>
                                    <td>{row.SuiteNo}</td>
                                </tr>

                            </tbody>
                        </table>
                        <p className="text-center" style={{ color: props.color }}>{props.message}</p>
                        <form role="form" method="post" >
                            <div className="form-group">
                                <div className="col-md mrg-btm-30">
                                    <textarea className="form-control" placeholder="Reason" value={props.reScheduleText} onChange={(e) => props.setValue('reScheduleText', e)}></textarea>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                            {props.modalLoader == true ? <div className="panel-body_loader" > <span style={{ fontSize: '25px', width: '100%', margin: 'auto', textAlign: 'center' }}><i className="fa fa-spinner fa-spin" /> please wait ...!</span>
                            </div> : <button
                                className="btn location_btn"
                                type="button" onClick={() => props.reSchedule(row, index)}>Submit</button>}
                        </form>

                    </div>
                </div>
            </div> : <div className="modal-dialog">
                <div className="modal-content rm-border-radius">
                    <div className="modal-header">
                        {props.modalLoader == true ? '' : <button type="button" className="close" data-dismiss="modal" aria-label="">
                            <span>×</span>
                        </button>}
                    </div>
                    <div className="modal-body">
                        <h2>Reason For Reschedule</h2>

                        <table className="table table-striped table-bordered table-hover text-left">
                            <tbody>
                                <tr>
                                    <th>
                                        Community
                                    </th>
                                    <td>{row.community_name}</td>
                                </tr>
                                <tr>
                                    <th>
                                        SuiteNo
                                    </th>
                                    <td>{row.SuiteNo}</td>
                                </tr>
                                <tr>
                                    <th className="text-danger">
                                        Reason
                                    </th>
                                    <td>{row.ReScheduleReason}</td>
                                </tr>
                            </tbody>
                        </table>


                    </div>
                </div>
            </div>}
        </div>
    )
}
export default RescheduleModal;