/* Importing the node modules, child components, services and controllers used 
   inside IntranetHome component */   
   import $ from "jquery";
   import {Link} from 'react-router-dom';
   import AppController from "../../controller/appController";
   import EmployeeHeader from '../../common/employeeHeader';
   import EmployeeFooter from '../../common/employeeFooter';
   import Schedules from './schedules.json';
   import UpcomingEvents from './upcomingEvents';
   /* IntranetHome Component initialization */
   class IntranetHome extends React.Component{
	   /* Initializing objects of its IntranetHome class */
	   constructor(props) {
		   super(props);  
		 }
	   /* It is invoked immediately after a component is mounted */  
	   componentDidMount() {
		  $(document).ready(function () {
			$('html, body').animate({scrollTop: 0});
			AppController.stickyTitles();
			$( "pignose-calendar-button-schedule-pin" ).each(function( index ) {
			});			   
		  });
	   }
   calendarLoad(){
	   jQuery(function($) {
		   jQuery('.calendar').pignoseCalendar({
	   scheduleOptions: {
		   colors: {
			   1: '#122333',
			   2: '#b6d8ec',
			   3: '#000000'
		   }
	   },
	   schedules: Schedules,
	   select: function(date, context) {

	   }
   });
   
   jQuery('.pignose-calendar-button-schedule-pin-1').each(function( index ) {
	   $( this ).parent().parent().children('a').css("background-color", "#122333")
	 });
	 jQuery('.pignose-calendar-button-schedule-pin-2').each(function( index ) {
	   $( this ).parent().parent().children('a').css("background-color", "#b6d8ec")
	 });
	 jQuery('.pignose-calendar-button-schedule-pin-3').each(function( index ) {
	   $( this ).parent().parent().children('a').css("background-color", "#000000")
	 });
   
	 $( ".pignose-calendar-top-next, .pignose-calendar-top-prev" ).on( "click", function() {
	   setTimeout(function() { 
   
		   jQuery('.pignose-calendar-button-schedule-pin-1').each(function( index ) {
			   $( this ).parent().parent().children('a').css("background-color", "#122333")
			 });
			 jQuery('.pignose-calendar-button-schedule-pin-2').each(function( index ) {
			   $( this ).parent().parent().children('a').css("background-color", "#b6d8ec")
			 });
			 jQuery('.pignose-calendar-button-schedule-pin-3').each(function( index ) {
			   $( this ).parent().parent().children('a').css("background-color", "#000000")
			 });
		}.bind(this), 150); 
	 });
   });   
   }   
	componentDidMount(){
		this.calendarLoad();
	}
   /* It is invoked to return html content */			
   render(){
	   return(			  
		  <div>
			  <EmployeeHeader/>
			  <div className="header-ht-cover"></div>
				  <div className="intranet_homemain">
			 
				 <div className="container">
				 <div className="intra_header">
					 <div className="row">
						 <div className="col-md-8">
							 <img className="img-responsive" src={s3BucketUrl+'13-03-2018/'+"SuiteAmerica-Intranet-Home-Banner.jpg"} alt="" />
							  
						 </div>
						 <div className="col-md-4">
							 <div className="">
								 <div className="col-md-3 col-xs-6">
								 <Link to="/intranet-form"><div className="inet_head">
										 <img src={s3BucketUrl+'13-03-2018/'+"SuiteAmerica-Intranet-Home-Contact-Book.png"}  alt="" />
										 Marketing Request
									 </div></Link>
									 
								 </div>
								 <div className="col-md-3 col-xs-6">
								 <Link to="/intranet-toform">
									 <div className="inet_head">
										 <img src={s3BucketUrl+'13-03-2018/'+"SuiteAmerica-Intranet-Home-User.png"}  alt="" />
										 T/O Form
									 </div></Link>
								 </div>
								 <div className="col-md-3 col-xs-6">
									
								 </div>
								 <div className="col-md-3 col-xs-6">
									 
								 </div>
							 </div>
							 <div className="">
								 <div className="col-md-3 col-xs-6">
								 <Link to="/tour-selection-form">
								 <div className="inet_head">
								 <img src={s3BucketUrl+'landing-images/'+"tourSelction.png"}  alt="" />
								 Tour Selection
							 </div></Link>
									 
								 </div>
								 <div className="col-md-3 col-xs-6">
									 								
									 <div className="inet_head">
									 </div>
								 </div>
								 <div className="col-md-3 col-xs-6">
									 <div className="inet_head">
										 
									 </div>
								 </div>
								 <div className="col-md-3 col-xs-6">
									 <div className="inet_head">
										 
									 </div>
								 </div>
							 </div>
							 <div className="">
								 <div className="col-md-3 col-xs-6">
									 <div className="inet_head">
										 
									 </div>
									 
								 </div>
								 <div className="col-md-3 col-xs-6">
									 <div className="inet_head">
										 
									 </div>
								 </div>
								 <div className="col-md-3 col-xs-6">
									 <div className="inet_head">
										 
									 </div>
								 </div>
								 <div className="col-md-3 col-xs-6">
									 <div className="inet_head">
										 
									 </div>
								 </div>
							 </div>
							 
						 </div>
					 </div>
				 </div>		 
				 <UpcomingEvents />		 
			   </div>
		 </div>
		 <EmployeeFooter/>
		  </div>
	   );
   }
   }
   export default IntranetHome;
   
   