class InspectionFormCorbonMonoxide extends React.Component {
    constructor(props) {
        super(props)
        this.setValue = this.setValue.bind(this);
        this.setChecked = this.setChecked.bind(this);
    }
    setChecked(field, e) {
        var object = {};
        object[field] = e.target.value;
        this.setState(object);
        this.props.setChecked(object)
    }
    setValue(field, e) {
        var object = {};
        object[field] = e.target.value;
        this.setState(object);
        this.props.setValue(object)
    }
    render() {
        return (
            <div>
   <div className="panel panel-default">
      <div className="panel-heading">
         <h4 className="panel-title">
            <a
               className="collapsed"
               data-toggle="collapse"
               data-parent="#accordion"
               href="#corbondioxide"
               aria-expanded="true"
               aria-controls="collapse3">
            Carbon Monoxide & Smoke Alarm
            </a>
         </h4>
      </div>
      <div id="corbondioxide" className="panel-collapse collapse">
         <div className="panel-body">
            <div className="profile-opener">
               <div className="row">
                  <div className="col-sm-6 col-md-6">
                     <input
                     type="text"
                     className="form-control"
                     placeholder="No of smoke alarms"
                     value={this.props.countSmokeAllarams}
                     onChange={(e) => this.setValue('countSmokeAllarams', e)}/>
                  </div>
                  <div className="col-sm-6 col-md-6">
                     <input
                     type="text"
                     className="form-control"
                     placeholder="No of carbon monoxide detectors"
                     value={this.props.countMonoxideDetectors}
                     onChange={(e) => this.setValue('countMonoxideDetectors', e)}/>
                  </div>
               </div>
               <br/>
               <div className="row">
                  <div className="col-sm-6 col-md-6">
                     <div className="row radiosec">
                        <div className="col-sm-12">
                           <label className="col-sm-3">All smoke alarms functional</label>
                           <div className="col-sm-3">
                              <label className="custom_radio radio-inline">Yes
                              <input
                              type="radio"
                              name="80"
                              value="Yes"
                              onChange={(e) => this.setChecked('smokeAllaram', e)}/>
                              <span className="checkmark"></span>
                              </label>
                           </div>
                           <div className="col-sm-5">
                              <label className="custom_radio radio-inline">No
                              <input
                              type="radio"
                              name="80"
                              value="No"
                              onChange={(e) => this.setChecked('smokeAllaram', e)}/>
                              <span className="checkmark"></span>
                              </label>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-sm-6 col-md-6">
                     <div className="row radiosec">
                        <div className="col-sm-12">
                           <label className="col-sm-3">All carbon monoxide detectors functional </label>
                           <div className="col-sm-3">
                              <label className="custom_radio radio-inline">Yes
                              <input
                              type="radio"
                              name="81"
                              value="Yes"
                              onChange={(e) => this.setChecked('monoxideDetectors', e)}/>
                              <span className="checkmark"></span>
                              </label>
                           </div>
                           <div className="col-sm-5">
                              <label className="custom_radio radio-inline">No
                              <input
                              type="radio"
                              name="81"
                              value="No"
                              onChange={(e) => this.setChecked('monoxideDetectors', e)}/>
                              <span className="checkmark"></span>
                              </label>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-sm-12 col-md-12">
                     <textarea
                     className="form-control"
                     placeholder="Comments/Notes"
                     value={this.props.smokeAllaramsComments}
                     onChange={(e) => this.setValue('smokeAllaramsComments', e)}
                     rows="4"
                     cols="5"></textarea>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
        )
    }
}

export default InspectionFormCorbonMonoxide