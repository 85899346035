const starbucksController = {
  
    dailyRent(size,checkin, checkout) {
        var date1 = new Date(checkin);
        var date2 = new Date(checkout);
        var checkInMonth = date1.getMonth()+1;
        var timeDiff = Math.abs(date2.getTime() - date1.getTime());
        var dateDifference = Math.ceil(timeDiff / (1000 * 3600 * 24))+1;
        if(size=='1X' || size=='STU'){
        //April 1-September 30 1X
        var request1xAprilToSep={
            dateDiff:dateDifference,
            days3:177,
            days14:177,
            days29:177,
            days29Greater:177
        }
        //October 1-March 31 1X
        var request1xOctToMarch={
            dateDiff:dateDifference,
            days3:177,
            days14:177,
            days29:177,
            days29Greater:177
        }
        var rates1=(checkInMonth>=4 && checkInMonth<=9)? this.dateWiseRates(request1xAprilToSep):this.dateWiseRates(request1xOctToMarch);
        return rates1
        } 
        if(size=='2X'){
          //April 1-September 30 2X
        var request2xAprilToSep={
            dateDiff:dateDifference,
            days3:177,
            days14:177,
            days29:177,
            days29Greater:177
        }
          //October 1-March 31 2x
        var request2xOctToMarch={
            dateDiff:dateDifference,
            days3:177,
            days14:177,
            days29:177,
            days29Greater:177
        }
        var rates=(checkInMonth>=4 && checkInMonth<=9)? this.dateWiseRates(request2xAprilToSep):this.dateWiseRates(request2xOctToMarch);
        return rates
    }
    if(size=='3X'){
          //April 1-September 30 3x
        var request3xAprilToSep={
            dateDiff:dateDifference,
            days3:177,
            days14:177,
            days29:177,
            days29Greater:177
        }
          //October 1-March 31 3x
        var request3xOctToMarch={
            dateDiff:dateDifference,
            days3:177,
            days14:177,
            days29:177,
            days29Greater:177
        }
        var rates2=(checkInMonth>=4 && checkInMonth<=9)? this.dateWiseRates(request3xAprilToSep):this.dateWiseRates(request3xOctToMarch);
        return rates2
    }


    },

    dateWiseRates(request){
        const dateDiff=request.dateDiff
        switch (true) {
            case (dateDiff <= 3):
                return request.days3
            case (dateDiff > 3 && dateDiff <= 14):
                return request.days14
            case (dateDiff > 14 && dateDiff <= 29):
                return request.days29
            case (dateDiff > 29):
                return request.days29Greater
        }
    }


}
export default starbucksController