/* Importing the node modules, child components, services and controllers used 
   inside StayWithUs component */    
   import DatePicker from 'react-datepicker'
   import moment from 'moment'
   import AppController from "../../controller/appController";
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import NikeServices from '../../../services/nikeService';
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   import ReCAPTCHA from "react-google-recaptcha"
   import SuccessModal from '../../common/successModal';
   /* StayWithUs Component initialization */
   class StayWithUs extends React.Component {
       /* Initializing objects of its StayWithUs class */
       constructor(props) {
           super(props)
           this.state={
               CaptchaValue:'',
               firstName: '',
               lastName: '',
               apartmentCommunity: '',
               email: '',
               eveningPhone: '',
               cellPhone: '',
               tempHousingRequested: '',
               phone: '',
               arrivalDate:moment(),
               departureDate:moment(),
               message: '',
               color:'',
               errorMessage:'',
               arrivalDateSelected: '',
               departureDateSelected: '',
               reasonForVisit: '',
               perDiem: '',
               size: '',
               pet: '',
               petInformation: '',
               governmentAgency: '',
               hearAboutUs: '',
               reasonList: ['TDY (Temporary Assignment)', 'PCS (Relocation/Conus)', 'Training', 'Internship', 'Other'],
               perDiemList: ['$2500-$3000/month', '$3001-$3500/month', '$3501-$4000/month', '$4001-$4500/month', '$4501-$5000/month', '$5001-$5500/month', '$5501/month or higher'],
               sizeList: ['1 Bedroom', '2 Bedroom', '3 Bedroom'],
               petList: ['Yes', 'No']
           }
           this.setValue = this.setValue.bind(this);
           this.handleMoveIn = this.handleMoveIn.bind(this);
           this.handleMoveOut = this.handleMoveOut.bind(this);
           this.serviceRequest = this.serviceRequest.bind(this);
           this.onChangeCaptcha = this.onChangeCaptcha.bind(this);
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
           MainController.getInitialInformation();
        }
        /* Form Details object creations  */
      setValue(field, e) {
            var object = {};
            object[field] = e.target.value;
            this.setState(object);
          }
     /* MoveIndate and MoveOutDate   */
      async handleMoveIn(date){
            await this.setState({
                arrivalDate: moment(date),
                arrivalDateSelected:date.format('YYYY-MM-DD HH:mm:ss') 
            });
        }
      async handleMoveOut(date){
            await this.setState({
                departureDate: moment(date),
                departureDateSelected:date.format('YYYY-MM-DD HH:mm:ss') 
            });
        }
       /* Service Request Details send to mail  */
       async serviceRequest(){
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        const moveIn = this.state.arrivalDateSelected == '' ? this.state.arrivalDate.format('YYYY-MM-DD HH:mm:ss'): this.state.arrivalDateSelected ;
        const moveOut = this.state.departureDateSelected == '' ? this.state.departureDate.format('YYYY-MM-DD HH:mm:ss'): this.state.departureDateSelected ;
        const _this = this
           const validationFields=['firstName','lastName','email','phone', 'arrivalDate', 'departureDate', 'CaptchaValue']
           let error = 0;
           _.forEach(validationFields, function(value) {
              if(_this.state[value] == ""){
                error++;
              }
          });
          _.forEach(validationFields, function(value) {
             if(!reg.test(_this.state.email)){
              error++;
            }
        });
         if(error == 0 && (moveIn <= moveOut)){
            const Request={
                firstName:this.state.firstName,
                email:this.state.email,
                lastName:this.state.lastName,
                phone:this.state.phone,
                eveningPhone: this.state.eveningPhone == '' ? '---' : this.state.eveningPhone,
                cellPhone: this.state.cellPhone == '' ? '---' : this.state.cellPhone,
                tempHousingRequested: this.state.tempHousingRequested == '' ? '---' : this.state.tempHousingRequested,
                arrivalDate:this.state.arrivalDate.format('YYYY-MM-DD'),
                departureDate:this.state.departureDate.format('YYYY-MM-DD'),
                apartmentCommunity: this.state.apartmentCommunity == '' ? '---' : this.state.apartmentCommunity,
                reasonForVisit: this.state.reasonForVisit == '' ? '---' : this.state.reasonForVisit,
                perDiem: this.state.perDiem == '' ? '---' : this.state.perDiem,
                size: this.state.size == '' ? '---' : this.state.size,
                pet: this.state.pet == '' ? '---' : this.state.pet,
                petInformation: this.state.petInformation == '' ? '---' : this.state.petInformation,
                governmentAgency: this.state.governmentAgency == '' ? '---' : this.state.governmentAgency,
                hearAboutUs: this.state.hearAboutUs == '' ? '---' : this.state.hearAboutUs,
                message: this.state.message == '' ? '---' : this.state.message,
                mail:'staywithus',
                subject: 'Ready To Stay With Us?'
            }
    
        this.setState({
            firstName: '',
            email: '',
            lastName: '',
            phone: '',
            eveningPhone: '',
            cellPhone: '',
            tempHousingRequested: '',
            arrivalDate:moment(),
            departureDate:moment(),
            apartmentCommunity: '',
            reasonForVisit: '',
            petInformation: '',
            governmentAgency: '',
            perDiem: '',
            size: '',
            pet: '',
            hearAboutUs: '',
            message: '',
            color:'',
            errorMessage:'',
           
 
        })
        
        $('input[name="radio"]').prop('checked', false);
        $('input[name="1"]').prop('checked', false);
        AppController.menuTopNavigation('serviceRequest');
        jQuery(function($) {
            $('#successModal').modal('show');
            setTimeout(function(){
              $('#successModal').modal('hide');
            },4000);
          });
      await NikeServices.serviceRequest(Request)
    }
    else{
        AppController.menuTopNavigation('serviceRequest');
        this.setState({errorMessage:'Please Enter Required Fields, Valid Email, Captcha & Move-Out Date is greater than are equal to Move-In Date ',color:'red'})
    }
       }
       async onChangeCaptcha(CaptchaValue) {
        await this.setState({CaptchaValue})
        }
       /* It is invoked to return html content */
       render() {
           return (
            <div className="corporateHousing_Updates">
                <GuestHeader/>
                <div className="cover-tophd-ht"></div>
                <div className="container-fluid nike company_section" id="serviceRequest">
                    <div className="container text-center">
                        <h2 className="">Ready To Stay With Us?</h2>
                        <p>Fill out the form below to request a reservation.</p>
                        <p>Once you submit your request, you will receive an email confirmation that the request was received. Your request will be processed and you will hear from us by phone or email within 2 business hours to confirm your reservation and to clear up any questions.</p>
                    </div>
                </div>
                <div className="container-fluid nike-datepicker bg-grey service_request">
                    <div className="request_form company_form" >
                        <div className="container">
                            <form className="well">
                            <p style={{color:this.state.color}}>{this.state.errorMessage}</p>
                            <h1>Contact Information</h1>
                            <div className="row">
                                <div className="col-sm-6 col-md-6">
                                    <input type="text" className="form-control" placeholder="*First Name" value={this.state.firstName} onChange={(e) => this.setValue('firstName', e)}/>
                                    <input type="text" className="form-control" placeholder="*E-mail Address" value={this.state.email} onChange={(e) => this.setValue('email', e)}/>
                                    <input type="text" className="form-control" placeholder="Evening Phone" value={this.state.eveningPhone} onChange={(e) => this.setValue('eveningPhone', e)} />
                                </div>
                                <div className="col-sm-6 col-md-6">
                                <input type="text" className="form-control" placeholder="*Last Name" value={this.state.lastName} onChange={(e) => this.setValue('lastName', e)} />
                                <input type="text" className="form-control" placeholder="*Phone" value={this.state.phone} 
                                onChange={(e) => this.setValue('phone', e)} />
                                <input type="text" className="form-control" placeholder="Cell Phone" value={this.state.cellPhone} onChange={(e) => this.setValue('cellPhone', e)} />
                                </div>
                            </div>
                            <hr />
                            <h1>Temporary Housing Information</h1>
                            <div className="row">
                                <div className="col-sm-6 col-md-6">
                                <input type="text" className="form-control" placeholder="Temporary Housing Area Requested" value={this.state.tempHousingRequested} onChange={(e) => this.setValue('tempHousingRequested', e)} />
                                <DatePicker type="text" className="form-control" placeholder="*Move-In Date" selected={this.state.arrivalDate}  
                                onChange={this.handleMoveIn} />
                                <input type="text" className="form-control" placeholder="Government Agency/Department" value={this.state.governmentAgency} onChange={(e) => this.setValue('governmentAgency', e)} />
                                <label className="custom-select">
                                        <select value={this.state.reasonForVisit} onChange={(e) => this.setValue('reasonForVisit', e)}>                                        
                                        <option value="">Reason For Your Visit</option>
                                        {this.state.reasonList.map((row, index) => {
                                        return(
                                        <option key={index} value={row}>{row}</option>
                                        )
                                        })}
                                        </select>
                                    </label>                                   
                                </div>
                                <div className="col-sm-6 col-md-6">
                                <input type="text" className="form-control" placeholder="Apartment Community" value={this.state.apartmentCommunity} onChange={(e) => this.setValue('apartmentCommunity', e)} />
                                <DatePicker type="text" className="form-control" placeholder="*Move-Out Date" selected={this.state.departureDate}  
                                onChange={this.handleMoveOut} />
                                <input type="text" className="form-control" placeholder="How did you hear about us?" value={this.state.hearAboutUs} onChange={(e) => this.setValue('hearAboutUs', e)} />
                                <label className="custom-select">
                                        <select value={this.state.perDiem} onChange={(e) => this.setValue('perDiem', e)}>                                        
                                        <option value="">Per Diem Rate</option>
                                        {this.state.perDiemList.map((row, index) => {
                                        return(
                                        <option key={index} value={row}>{row}</option>
                                        )
                                        })}
                                        </select>
                                    </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 col-md-6">
                                <div className="row">
                                    <div className="col-sm-6 col-md-6">
                                    <label className="custom-select">
                                        <select value={this.state.size} onChange={(e) => this.setValue('size', e)}>                                        
                                        <option value="">Size</option>
                                        {this.state.sizeList.map((row, index) => {
                                        return(
                                        <option key={index} value={row}>{row}</option>
                                        )
                                        })}
                                        </select>
                                    </label>   
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                    <label className="custom-select">
                                        <select value={this.state.pet} onChange={(e) => this.setValue('pet', e)}>                                        
                                        <option value="">Pet</option>
                                        {this.state.petList.map((row, index) => {
                                        return(
                                        <option key={index} value={row}>{row}</option>
                                        )
                                        })}
                                        </select>
                                    </label>   
                                    </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-6">
                                    <input type="text" className="form-control" placeholder="Pet Information" value={this.state.petInformation} onChange={(e) => this.setValue('petInformation', e)} />
                                </div>
                            </div>
                            <hr />
                            <h1>Comments / Questions / Special Instructions</h1>
                            <div className="row">
                                <div className="col-sm-12 col-md-12">
                                    <textarea className="form-control" placeholder="Comments / Questions / Special Instructions" value={this.state.message} onChange={(e) => this.setValue('message', e)} rows="8" cols="5"></textarea>
                                </div>
                            </div>
                                <div className="row">
               <div className="col-sm-12 col-md-12">
                 <ReCAPTCHA
                  ref="recaptcha"
                  sitekey="6Lcbw1AUAAAAAE5K5cYDyhoGu0RJY9C3CLtGFXAo"
                  onChange={this.onChangeCaptcha}
                />
          </div>
          
       </div>
                            </form>
                            <button type="button" className=" btn location_btn mrg-btm-70" onClick={this.serviceRequest}> SUBMIT</button>
                        </div>
                    </div>
                </div>
                <SubFooter/>
                <Footer/>
                <SuccessModal/>
                </div>

   
           );
       }
   }
   export default StayWithUs;