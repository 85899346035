/* Importing the node modules, child components, services and controllers used 
   inside NikeApprenticeshipCommunities component */ 
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import NikeApprenticeshipHeader from "../../common/nikeApprenticeshipHeader";
   import Location from "../location/locations";
   import ContactUs from "./common/contactUs";
   import Footer from '../../common/footer';
   import ApprenticeshipFooter from "./common/apprenticeshipFooter";
   /* NikeApprenticeshipCommunities Component initialization */
   class NikeApprenticeshipCommunities extends React.Component {
         /* Initializing objects of its NikeApprenticeshipCommunities class */
         constructor(props) {
           super(props)
           this.state={
               companyname:'NikeIntern'
           }
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
           MainController.getInitialInformation();
        }
       /* It is invoked to return html content */
       render() {
           return (
            <div className="corporateHousing_Updates">
                <header className="codeconveyHeader cc_image_text_main corporate-h">
                    <img className="img-responsive" src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Company-Nike-Internship-OR.jpg"} width="100%" alt=""  />
                    <div className="wow fadeInUp img-on-text top30 width600 text-center left50 img-on-text-center" data-wow-delay="0.5s">
                        <h1>Nike Communities</h1>
                        <p>Conveniently Located Corporate Housing</p>
                    </div>
                </header>
                <NikeApprenticeshipHeader />
                <div className="container-fluid nike company_section">
                    <div className="container text-center">                        
                        <p>Please note that these are sample locations for SuiteAmerica. Your actual apartment will be selected by SuiteAmerica after you have been matched with other participants. SuiteAmerica is pleased to offer fully furnished apartments for you during your stay in Portland, Oregon. Your apartment will include all furniture, housewares, linens, wireless internet, home phone and cable service.</p>                    
                    </div>                    
                </div>
                <div className="container-fluid nike text-center">
                    <div className="container">
                        <p>Click on the properties below for more information.</p>
                        <h2>Communities</h2>
                    </div>
                </div>
                <Location companyName={this.state.companyname} props={this.props}/>
                <div className="container-fluid bg-grey">
                    <div className="container">
                        <ContactUs />
                    </div>
                </div>
                <div className="container ft-contact-adrs mrg-btm-30 mrg-top-30">
                    <ApprenticeshipFooter />
                </div>
                <SubFooter />
                <Footer/>
            </div>
           );
       }
   }
   export default NikeApprenticeshipCommunities;