"use strict";
import BookingController from "./bookingController";
import AppController from "../../../controller/appController";
import moment from "moment";
import TravelBookingApiService from "../../../../services/travelBookingApiService";
import $ from "jquery";
import CalendarController from "../../../controller/calendarController";
import { setTimeout } from "timers";
import LocationController from "../../newinterface/locationController";

class travelModel {
  constructor(thisObject, props) {
    this.Obj = thisObject;
  }
  async DidMount(props) {
    localStorage.removeItem("searchedLocation");
    var uploadPath = props.match.path;
    uploadPath = uploadPath.substring(0, uploadPath.indexOf("/:"));
    var path =
      props.match.params.pnr != "" && props.match.params.pnr != undefined
        ? uploadPath
        : props.location.pathname;
    localStorage.setItem("url", path);
    if (props.match.params.pnr != "" && props.match.params.pnr != undefined) {
      const pnr = AppController.decodeData(props.match.params.pnr);
      const userdetails1 = {
        pnrid: pnr,
      };
      const ResponseSent =
        await TravelBookingApiService.GetTravelBookingByPnrId(userdetails1);

      if (ResponseSent.error == 0 && ResponseSent.message != null) {
        if (
          ResponseSent.message.length > 0 &&
          ResponseSent.message[0].OrderId == null
        ) {
          const CheckIn =
            ResponseSent.message[0].CheckInDate.split("T00:00:00");
          const CheckOut = ResponseSent.message[0].OutDate.split("T00:00:00");

          const compareFrom = moment(CheckIn[0]).format("MM/DD/YYYY");
          const compareTo = moment().format("MM/DD/YYYY");

          var isAfter = moment(compareFrom).isSameOrAfter(compareTo);
          if (isAfter) {
            var checkinPnr = moment(CheckIn[0]).format("MM/DD/YYYY");
            var checkoutPnr = moment(CheckOut[0]).format("MM/DD/YYYY");
            await this.Obj.setState({
              checkin: checkinPnr,
              checkout: checkoutPnr,
              travelerObJect: ResponseSent.message[0],
            });
            localStorage.setItem("checkIn", checkinPnr);
            localStorage.setItem("checkOut", checkoutPnr);
          } else {
            props.history.push("/travel-booking-dateexpire");
          }
        } else {
          props.history.push("/travel-booking-alreadysent");
        }
      } else {
        props.history.push("/travel-booking-alreadysent");
      }
    }
    if (
      localStorage.getItem("checkIn") != null &&
      localStorage.getItem("checkOut") != null
    ) {
      this.Obj.setState({ loading: true, loadingStatus: true });
      this.getMasterAvailability(
        localStorage.getItem("checkIn"),
        localStorage.getItem("checkOut"),
        localStorage.getItem("bedSize")
      );
    }
  }
  async WillMount(props) {
    const _this = this.Obj;
    var email = localStorage.getItem("travel-email");
    var pass = localStorage.getItem("travel-password");
    if (
      (email == undefined || email == null) &&
      (props.match.params.pnr == "" || props.match.params.pnr == undefined)
    ) {
      localStorage.clear();
    }
    if (props.match.params.pnr != "" && props.match.params.pnr != undefined) {
      localStorage.clear();
    }
    $("head").append(
      '<link rel="stylesheet" href="/assets/css/travel-booking.css" type="text/css" />'
    );
    $("html, body").animate({
      scrollTop: 0,
    });
    AppController.stickyTitles();

    jQuery(function () {
      setTimeout(function () {
        $("#travel-booking").val(_this.state.searchLocation);
      }, 500);
      setTimeout(function () {
        CalendarController.checkInCalendar(
          _this,
          -1,
          _this.state.checkin != "" ? _this.state.checkin : ""
        );
        CalendarController.checkOutCalendar(
          _this,
          -1,
          _this.state.checkout != "" ? _this.state.checkout : ""
        );
        if (_this.state.checkin != "") {
          jQuery('input[name="checkin"]')
            .data("daterangepicker")
            .setStartDate(_this.state.checkin);
        }
        if (_this.state.checkin != "") {
          jQuery('input[name="checkout"]')
            .data("daterangepicker")
            .setStartDate(_this.state.checkout);
        }
      }, 500);
    });
  }
  async checkInCal() {
    const _this = this.Obj;
    localStorage.removeItem("checkIn");
    localStorage.removeItem("checkOut");
    await this.Obj.setState({
      checkin: "",
      checkout: "",
    });
    await CalendarController.checkInCalendar(
      _this,
      -1,
      _this.state.checkin != "" ? _this.state.checkin : ""
    );
    await CalendarController.checkOutCalendar(
      _this,
      -1,
      _this.state.checkout != "" ? _this.state.checkout : ""
    );
  }
  async checkOutCal() {
    const _this = this.Obj;
    localStorage.removeItem("checkOut");
    await this.Obj.setState({ checkout: "" });
    await CalendarController.checkOutCalendar(
      _this,
      1,
      _this.state.checkin != "" ? _this.state.checkin : ""
    );
    await CalendarController.checkInCalendar(
      _this,
      1,
      _this.state.checkout != "" ? _this.state.checkout : ""
    );
  }

  async getCommunityData(data, communitiesLength) {
    const filterCommunityData = data;

    for (var i = 0; i < filterCommunityData.length; i++) {
      const data = {
        address: filterCommunityData[i].address,
        latitude: filterCommunityData[i].latitude,
        longitude: filterCommunityData[i].longitude,
      };
      filterCommunityData[i]["walkScore"] = "";
      filterCommunityData[i]["distance"] = "";
      filterCommunityData[i]["dailyrate"] = await BookingController.dailyRent(
        "1x",
        this.Obj.state.checkin,
        this.Obj.state.checkout
      );
      filterCommunityData[i]["size1xRate"] =
        filterCommunityData[i].Avail1X > 0
          ? await BookingController.dailyRent(
              "1X",
              this.Obj.state.checkin,
              this.Obj.state.checkout
            )
          : 0;
      filterCommunityData[i]["size2xRate"] =
        filterCommunityData[i].Avail2X > 0
          ? await BookingController.dailyRent(
              "2X",
              this.Obj.state.checkin,
              this.Obj.state.checkout
            )
          : 0;
      filterCommunityData[i]["size3xRate"] =
        filterCommunityData[i].Avail3X > 0
          ? await BookingController.dailyRent(
              "3X",
              this.Obj.state.checkin,
              this.Obj.state.checkout
            )
          : 0;
      filterCommunityData[i]["sizeSTURate"] =
        filterCommunityData[i].STU > 0
          ? await BookingController.dailyRent(
              "STU",
              this.Obj.state.checkin,
              this.Obj.state.checkout
            )
          : 0;

      await this.Obj.setState({
        communities: _.concat(
          this.Obj.state.communities,
          filterCommunityData[i]
        ),
      });
      this.getwalkscore(data, communitiesLength + i);
      this.getDistance(
        filterCommunityData[i].latitude,
        filterCommunityData[i].longitude,
        communitiesLength + i,
        filterCommunityData.length
      );
      if (filterCommunityData.length == i + 1) {
        await this.Obj.setState({
          loading: false,
          loadingStatus: false,
          communities: _.sortBy(this.Obj.state.communities, [
            function (o) {
              return o.distance;
            },
          ]),
        });
      }
    }
    await this.mapmethod();
    // jQuery(function () {
    //     $('html, body').animate({
    //         scrollTop: $('#communityScroll').offset().top
    //     }, 400);
    // })
  }

  async getwalkscore(data, index) {
    const communityInfo = {
      address: data.address,
      latitude: data.latitude,
      longitude: data.longitude,
    };
    const walkscore = await AppController.wallsckoreFun(communityInfo);
    let updatedPosts = update(this.Obj.state.communities, {
      [index]: {
        walkScore: {
          $set: walkscore.walkscore,
        },
      },
    });
    await this.Obj.setState({
      communities: updatedPosts,
    });
  }

  async getDistance(latitude, longitude, index, totalLength) {
    if (
      latitude != "" &&
      latitude != undefined &&
      latitude != null &&
      longitude != "" &&
      longitude != undefined &&
      longitude != null
    ) {
      const distanceVal = AppController.distance(
        this.Obj.state.addressLat,
        this.Obj.state.addressLng,
        latitude,
        longitude
      );

      let updatedPosts = update(this.Obj.state.communities, {
        [index]: {
          distance: {
            $set: parseFloat(distanceVal),
          },
        },
      });
      await this.Obj.setState({
        communities: updatedPosts,
      });
    } else {
      let updatedPosts = update(this.Obj.state.communities, {
        [index]: {
          distance: {
            $set: 0,
          },
        },
      });
      await this.Obj.setState({
        communities: updatedPosts,
      });
    }
  }
  async getMasterAvailability(checkIn, checkOut, bedsize) {
    var checkInDate = moment(checkIn, "MM/DD/YYYY"),
      checkOutDate = moment(checkOut, "MM/DD/YYYY");
    var date1 = new Date(checkInDate);
    var date2 = new Date(checkOutDate);
    var timeDiff = Math.abs(date2.getTime() - date1.getTime());
    let diffDate = Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1;
    const startDate = moment([
      Number(moment(checkIn).format("YYYY")),
      Number(moment(checkIn).format("M")) - 1,
      Number(moment(checkIn).format("D")),
    ]);
    const endDate = moment([
      Number(moment("01/01/2019").format("YYYY")),
      Number(moment("01/01/2019").format("M")) - 1,
      Number(moment("01/01/2019").format("D")),
    ]);
    const finalDate = endDate.diff(startDate, "days");
    const userLoginEmailEncode = localStorage.getItem("travel-email");
    const userLoginEmail =
      userLoginEmailEncode != undefined || userLoginEmailEncode != null
        ? "admin"
        : "guest";

    if (diffDate <= 30) {
      this.Obj.setState({
        program: ["Apple CE"],
        record_limit: 0,
        radius: 30,
        defaultCommunitiesError: false,
        reqDays: 15,
      });
      const postObject1 = {
        program: ["Apple CE"],
        checkin: checkIn,
        checkout: checkOut,
        record_limit: 0,
        radius: 0,
        roomsize: userLoginEmail == "guest" ? "1X" : bedsize,
        reqDays: 15,
      };

      let responseTire1 = await this.apiRequest(postObject1);
      let filterResponseTire1 =
        finalDate > 0
          ? responseTire1
          : _.filter(responseTire1, function (o) {
              return (
                o.city_uid.toLowerCase() !=
                "0a2d899d-3e5b-45d2-88d2-12d4b31cce11"
              );
            });
      if (filterResponseTire1.length > 0) {
        await this.getCommunityData(filterResponseTire1, 0);
      } else if (filterResponseTire1.length <= 0) {
        this.Obj.setState({
          program: ["General"],
          record_limit: 0,
          radius: 25,
          defaultCommunitiesError: false,
          reqDays: 4,
        });
        const postObject3 = {
          program: ["General"],
          checkin: checkIn,
          checkout: checkOut,
          record_limit: 0,
          radius: 0,
          roomsize: userLoginEmail == "guest" ? "1X" : bedsize,
          reqDays: 4,
        };

        const responseTire3 = await this.apiRequest(postObject3);

        const filterResponseTire3 =
          finalDate > 0
            ? responseTire3
            : _.filter(responseTire3, function (o) {
                return (
                  o.city_uid.toLowerCase() !=
                  "0a2d899d-3e5b-45d2-88d2-12d4b31cce11"
                );
              });
        if (filterResponseTire3.length > 0) {
          await this.getCommunityData(filterResponseTire3, 0);
        } else {
          await this.defaultCommunitiesRequest();
        }
      }
    } else {
      console.log("222");
      this.Obj.setState({
        program: ["Apple CE"],
        record_limit: 0,
        radius: 30,
        defaultCommunitiesError: false,
        reqDays: 120,
      });
      const postObject = {
        program: ["Apple CE"],
        checkin: checkIn,
        checkout: checkOut,
        record_limit: 0,
        radius: 0,
        roomsize: userLoginEmail == "guest" ? "1X" : bedsize,
        reqDays: 0,
      };

      let responseData = await this.apiRequest(postObject);

      if (responseData.length > 0) {
        await this.getCommunityData(responseData, 0);
      } else {
        await this.defaultCommunitiesRequest();
      }
    }
  }
  async apiRequest(requestObject) {
    _.assign(requestObject, {
      allowpets: false,
      complexamenities: [],
      unitamenities: [],
      latitude: this.Obj.state.addressLat,
      longitude: this.Obj.state.addressLng,
      confirmedMoveOut: false,
      masterid: "",
    });

    const Data = await TravelBookingApiService.GetAvailableMastersInfo(
      requestObject
    );

    return Data.message;
  }
  async defaultCommunitiesRequest() {
    await this.Obj.setState({
      loadingStatus: false,
      defaultCommunitiesError: true,
    });
    await this.mapmethod();
    jQuery(
      function ($) {
        $("#successTravelModal").modal("show");
        setTimeout(async function () {}.bind(this), 5000);
      }.bind(this)
    );
  }
  async getCommunities() {
    var checkIn = Date.parse(this.Obj.state.checkin);
    var checkOut = Date.parse(this.Obj.state.checkout);
    const movieInformate = moment(this.Obj.state.checkin).format("MM/DD/YYYY");
    const startDate = moment([
      Number(moment(movieInformate).format("YYYY")),
      Number(moment(movieInformate).format("MM")) - 1,
      Number(moment(movieInformate).format("DD")),
    ]);
    const movieOutformate = moment(this.Obj.state.checkout).format(
      "MM/DD/YYYY"
    );
    const endDate = moment([
      Number(moment(movieOutformate).format("YYYY")),
      Number(moment(movieOutformate).format("MM")) - 1,
      Number(moment(movieOutformate).format("DD")),
    ]);
    const diffDate = endDate.diff(startDate, "days");

    this.Obj.setState({
      communities: [],
      loadingStatus: true,
      noOfDays: diffDate,
      mapLoader: true,
    });
    if (this.Obj.state.checkin == "" || this.Obj.state.checkout == "") {
      this.Obj.setState({
        errorMessage: "Please select Check In and Check Out date",
        communities: [],
        loadingStatus: false,
      });
    } else if (checkIn > checkOut) {
      this.Obj.setState({
        errorMessage:
          "Check Out date should be greater than or equal to Check In date",
        communities: [],
        loadingStatus: false,
      });
    } else {
      localStorage.setItem("searchedLocation", this.Obj.state.searchLocation);
      localStorage.setItem("checkIn", this.Obj.state.checkin);
      localStorage.setItem("checkOut", this.Obj.state.checkout);
      localStorage.setItem("bedSize", this.Obj.state.bedsize);
      this.getMasterAvailability(
        this.Obj.state.checkin,
        this.Obj.state.checkout,
        this.Obj.state.bedsize
      );
      this.Obj.setState({ errorMessage: "" });
    }
  }
  setLocation() {
    const _this = this.Obj;
    var places = new google.maps.places.Autocomplete(
      document.getElementById("travel-booking")
    );
    google.maps.event.addListener(places, "place_changed", function () {
      var place = places.getPlace();
      _this.setState({
        searchLocation: places.getPlace().formatted_address,
        addressLat: place.geometry.location.lat(),
        addressLng: place.geometry.location.lng(),
      });
    });
    if ($("#travel-booking").val() == "") {
      _this.setState({
        searchLocation: "",
      });
    }
  }
  async mapmethod() {
    const _this = this.Obj;
    console.log(";;;;;;", _this.state.communities);
    if (_this.state.communities.length != 0) {
      let locations = _this.state.communities
        .filter((row) => row.latitude !== null)
        .map((row, index) => {
          var address;
          const favourite =
            row.favouritestatus != undefined &&
            row.favouritestatus != null &&
            row.favouritestatus != false
              ? "fa fa-heart"
              : "";
          const color =
            row.favouritestatus != undefined &&
            row.favouritestatus != null &&
            row.favouritestatus != false
              ? "red"
              : "white";
          address =
            '<div id="content">' +
            '<div id="siteNotice">' +
            "</div>" +
            '<div id="bodyContent">' +
            '<div class="bodycontentimg"><img width="100px" height="100px" src="' +
            row.image_name +
            '" /> <i class="' +
            favourite +
            '" style="color:' +
            color +
            '"></i> </div>' +
            "<h1>" +
            row.community_name +
            "</h1>" +
            "<p>" +
            row.address +
            ", " +
            row.city_name +
            ", " +
            row.state_name +
            " </p>" +
            '<a href="https://www.google.com/maps?daddr=' +
            row.latitude +
            "," +
            row.longitude +
            '" target="_blank">Get driving directions</a>' +
            "</div>" +
            "</div>";
          return [address, row.latitude, row.longitude, index];
        });
      locations =
        locations.length > 0 ? locations : ["655 S. Fair Oaks", 0.0, -0.0, 0];

      if (_this.state.communities.length > 0) {
        const markers = [];
        const map = (this.map = new google.maps.Map(
          document.getElementById("map"),
          {
            zoom: 10,
            zoomControl: false,
            center: new google.maps.LatLng(
              _this.state.addressLat,
              _this.state.addressLng
            ),
            // center: _this.state.addressLat != 37.7749295 && _this.state.addressLng != -122.4194155 ? new google.maps.LatLng(locations[0][1], locations[0][2]) : new google.maps.LatLng(_this.state.addressLat, _this.state.addressLng),
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            backgroundColor: "#eeeeee",
            disableDefaultUI: true,
          }
        ));
        var style = [
          {
            elementType: "geometry",
            stylers: [{ saturation: -40 }],
          },
        ];
        var mapType = new google.maps.StyledMapType(style, {
          name: "Grayscale",
        });
        map.mapTypes.set("grey", mapType);
        map.setMapTypeId("grey");
        var customZoomInControlDiv = document.createElement("div");
        this.CustomZoomInControl(customZoomInControlDiv, map);
        customZoomInControlDiv.index = 1;
        map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(
          customZoomInControlDiv
        );
        var infowindow = new google.maps.InfoWindow({});
        var i;
        for (i = 0; i < locations.length; i++) {
          const marker = (this.marker = new google.maps.Marker({
            position: new google.maps.LatLng(locations[i][1], locations[i][2]),
            icon: "https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/location_pin_2.svg",
            map: map,
          }));
          google.maps.event.addListener(
            marker,
            "click",
            function (marker, i) {
              return async function () {
                infowindow.setContent(locations[i][0]);
                infowindow.open(map, marker);
                await _this.setState({
                  activeNumber: _this.state.communities[i].community_name,
                });
              }.bind(this);
            }.bind(this)(marker, i)
          );

          markers.push(marker);
          new markerClusterer.MarkerClusterer({ markers, map });
        }

        google.maps.event.addListener(
          map,
          "dragend",
          async function () {
            let latitude = map.getCenter().lat();
            let longitude = map.getCenter().lng();

            _this.setState({
              addressLat: latitude,
              addressLng: longitude,
            });
            return this.getCommunities();
          }.bind(this)
        );
        // google.maps.event.addListener(map, 'zoom_changed', (async function () {
        //     let latitude = map.getCenter().lat();
        //     let longitude = map.getCenter().lng();

        //     _this.setState({
        //         addressLat: latitude,
        //         addressLng: longitude
        //     })
        //     return this.getCommunities();
        // }.bind(this)))

        await _this.setState({
          markerList: markers,
          addresstext: false,
          communitytext: false,
          mapLoader: false,
        });
        _this.setState({
          map,
        });
      }
      // await this.setState({
      //     loadingStatus: false,
      // })
    } else {
      const limitData = {
        startLimit: 0,
        companyname: "suiteamerica",
        latitude: "",
        longitude: "",
        address: "",
        state_id: "",
        city_id: "",
        amenitiesId: "",
        communityName: "",
        countryId: "",
        userid: "",
        cities: [],
      };
      await this.emptymap(limitData);
    }
  }
  CustomZoomInControl(controlDiv, map) {
    // Set CSS for the control border
    var controlUI = document.createElement("div");

    controlUI.style.cursor = "pointer";
    controlUI.style.display = "-webkit-inline-box";
    controlUI.style.margin = "18px 10px";
    controlUI.style.width = "30px";
    controlUI.style.height = "30px";
    controlDiv.appendChild(controlUI);
    // Set CSS for the control interior
    var controlText = document.createElement("div");
    controlText.innerHTML += `<img style="height:30px;width:30px;margin:0"  src='/assets/newUI-images/plus.svg' />`;
    controlUI.appendChild(controlText);

    // Setup the click event listeners
    google.maps.event.addDomListener(controlUI, "click", function () {
      map.setZoom(map.getZoom() + 1);
    });

    // Set CSS for the control border
    var controlUILeft = document.createElement("div");

    controlUILeft.style.display = "-webkit-inline-box";
    controlUILeft.style.marginLeft = "10px";
    controlUILeft.style.cursor = "pointer";
    controlUILeft.style.width = "30px";
    controlUILeft.style.height = "30px";
    controlDiv.appendChild(controlUILeft);

    var controlTextLeft = document.createElement("div");

    controlTextLeft.innerHTML += `<img style="height:30px;width:30px;margin:0"  src='/assets/newUI-images/minus.svg' />`;
    controlUILeft.appendChild(controlTextLeft);

    // Setup the click event listeners
    google.maps.event.addDomListener(controlUILeft, "click", function () {
      map.setZoom(map.getZoom() - 1);
    });
  }
  flyToPlace(row, index, type) {
    const _this = this.Obj;
    var latLng = new google.maps.LatLng(row.latitude, row.longitude);
    this.map.panTo(latLng);
    const placemarker = _this.state.markerList;
    google.maps.event.trigger(placemarker[index], "click");
  }
  async emptymap() {
    const _this = this.Obj;

    const limitData = {
      startLimit: 0,
      companyname: "suiteamerica",
      latitude: "",
      longitude: "",
      address: "",
      state_id: "",
      city_id: "",
      amenitiesId: "",
      communityName: "",
      countryId: "",
      userid: "",
      cities: [],
    };
    const communityapiData = await LocationController.getCommunityData(
      limitData
    );
    const map = (this.map = new google.maps.Map(
      document.getElementById("map"),
      {
        zoom: 10,
        zoomControl: false,
        center: new google.maps.LatLng(
          _this.state.addressLat,
          _this.state.addressLng
        ),
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        backgroundColor: "#eeeeee",
        disableDefaultUI: true,
      }
    ));
    var style = [
      {
        elementType: "geometry",
        stylers: [{ saturation: -40 }],
      },
    ];
    var mapType = new google.maps.StyledMapType(style, { name: "Grayscale" });
    map.mapTypes.set("grey", mapType);
    map.setMapTypeId("grey");
    var customZoomInControlDiv = document.createElement("div");
    this.CustomZoomInControl(customZoomInControlDiv, map);
    customZoomInControlDiv.index = 1;
    map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(
      customZoomInControlDiv
    );

    google.maps.event.addListener(
      map,
      "dragend",
      async function () {
        let latitude = map.getCenter().lat();
        let longitude = map.getCenter().lng();

        _this.setState({
          addressLat: latitude,
          addressLng: longitude,
        });
        return this.getCommunities();
      }.bind(this)
    );
    // google.maps.event.addListener(map, 'zoom_changed', (async function () {
    //     let latitude = map.getCenter().lat();
    //     let longitude = map.getCenter().lng();

    //     _this.setState({
    //         addressLat: latitude,
    //         addressLng: longitude
    //     })
    //     return this.getCommunities();
    // }.bind(this)))

    await _this.setState({
      loadingStatus: false,
      mapLoader: false,
    });
  }
}

export default travelModel;
