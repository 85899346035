import GuestHeader from '../../common/guestHeader';
import MainController from "../../controller/mainController";
import CalendarController from "../../controller/calendarController";
import AppController from "../../controller/appController";
import ReserveYourStayService from "../../../services/reserveYourStayService";
import $ from "jquery";
import {Link} from 'react-router-dom';
import ReservationSuccesModal from "../../controller/reservationSuccesModal";
import ListData from './listData.json';
   /* BechtelPdf Component initialization */
   class BAEPdf extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            desiredLocation: '',
            addressLat: 0.0,
            addressLng: 0.0,
            checkin: '',
            checkout: '',
            bedRoom: '',
            pet: '',
            contactMethod: '',
            adult: '',
            children: '',
            pricePerNight: '',
            firstName: '',
            companyName: '',
            email: '',
            phoneNumber: '',
            errorMessage: '',
            loading: false,
            bedRoomsList: [{ 'name': 'BedRooms', 'value': '' }, { 'name': 'ALL', 'value': '0' }, { 'name': 'STU', 'value': 'STU' }, { 'name': '1X', 'value': '1X' }, { 'name': '2X', 'value': '2X' }, { 'name': '3X', 'value': '3X' }],
            petsList: [{ 'name': 'Pets', 'value': '' }, { 'name': '1', 'value': '1' }, { 'name': '2', 'value': '2' }, { 'name': '3', 'value': '3' }, { 'name': '4', 'value': '4' }],
            adultsList: [{ 'name': 'Adult', 'value': '' }, { 'name': '1', 'value': '1' }, { 'name': '2', 'value': '2' }, { 'name': '3', 'value': '3' }, { 'name': '4', 'value': '4' }, { 'name': '5', 'value': '5' }],
            childrensList: [{ 'name': 'Children', 'value': '' }, { 'name': '1', 'value': '1' }, { 'name': '2', 'value': '2' }, { 'name': '3', 'value': '3' }, { 'name': '4', 'value': '4' }, { 'name': '5', 'value': '5' }],
            contactMethods: [{ 'name': 'Contact Method', 'value': '', 'icon': '' }, { 'name': 'Phone', 'value': 'Phone', 'icon': "f2b6" }, { 'name': 'Email', 'value': 'Email', 'icon': '' }, { 'name': 'Text', 'value': 'Text', 'icon': '' }],
            priceList: ['$150 - $250', '$250+'],
            isChecked: '',
            CaptchaValue: '',
            comments: '',
            lastName: '',
            ListData:ListData
        }
        this.setLocation = this.setLocation.bind(this);
        this.checkInMethod = this.checkInMethod.bind(this);
        this.checkOutMethod = this.checkOutMethod.bind(this);
        this.setValue = this.setValue.bind(this);
        this.setNumber = this.setNumber.bind(this);
        this.reservationSubmit = this.reservationSubmit.bind(this);
        this.toggleChange = this.toggleChange.bind(this);
        this.onChangeCaptcha = this.onChangeCaptcha.bind(this);
    }
    toggleChange(row, e) {
        this.setState({
            pricePerNight: row,
        });
    }
    setValue(field, e) {
        this.setState({ selectDropDown: true })
        var object = {};
        object[field] = e.target.value;
        this.setState(object);
    }
    setLocation() {
        const _this = this;
        var places = new google.maps.places.Autocomplete(document.getElementById('reserve-yourstay'));
        google.maps.event.addListener(places, 'place_changed', function () {
            var place = places.getPlace();
            _this.setState({ desiredLocation: places.getPlace().formatted_address, addressLat: place.geometry.location.lat(), addressLng: place.geometry.location.lng() });
        });
        if ($("#reserve-yourstay").val() == '') {
            _this.setState({ desiredLocation: '' });
        }
    }
    async checkInMethod() {
        const _this = this
        await this.setState({ checkin: '', checkout: '' })
        await CalendarController.checkInCalendar(_this, -1, _this.state.checkin != '' ? _this.state.checkin : "")
        await CalendarController.checkOutCalendar(_this, -1, _this.state.checkout != '' ? _this.state.checkout : "")

    }
    async checkOutMethod() {
        const _this = this
        await this.setState({ checkout: '' })
        await CalendarController.checkOutCalendar(_this, 1, _this.state.checkin != '' ? _this.state.checkin : "")
        await CalendarController.checkInCalendar(_this, 1, _this.state.checkout != '' ? _this.state.checkout : "")
    }
    async componentWillMount() {
       
        MainController.getInitialInformation();
        const _this = this
        jQuery(function () {
            setTimeout(function () {
                CalendarController.checkInCalendar(_this, -1, _this.state.checkin != ''
                    ? _this.state.checkin
                    : "")
                CalendarController.checkOutCalendar(_this, -1, _this.state.checkout != ''
                    ? _this.state.checkout
                    : "")
                if(_this.state.checkin != ''){
                    jQuery('input[name="checkin"]')
                    .data('daterangepicker')
                    .setStartDate(_this.state.checkin)
                }
                if (_this.state.checkout != ''){
                    jQuery('input[name="checkout"]')
                    .data('daterangepicker')
                    .setStartDate(_this.state.checkout)
                } 
            }, 500)

        });

    }
    componentDidMount() {
        $(document).ready(function () {
            jQuery(function ($) {
                $("#reserveNowPhone").intlTelInput();
            });
        });
    }
    resetData() {
        this.setState(
            {
                desiredLocation: '',
                checkin: '',
                checkout: '',
                bedRoom: '',
                pricePerNight: '',
                firstName: '',
                companyName: '',
                email: '',
                phoneNumber: '',
                length: '',
                GuestLastName: '',
                pet: '',
                adult: '',
                children: '',
                contactMethod: '',
                comments: '',
                CaptchaValue: '',
                lastName: ''

            }
        )
        this.recaptcha.reset()
        $(document).ready(function () {
            jQuery(function ($) {
                $("#reserveNowPhone").intlTelInput("setCountry", "us");
            });
        });

    }
    validation() {
        const _this = this
        const validationFields = ['desiredLocation', 'checkin', 'checkout', 'firstName', 'email',]
        let error = 0;
        _.forEach(validationFields, function (value) {
            if (_this.state[value] == "") {
                error++;
            }
        });
        return error
    }
    async reservationSubmit(event) {
        event.preventDefault();
        const countryCode = $(".country.highlight.active").attr("data-dial-code") == undefined
            ? "+1"
            : "+" + $(".country.highlight.active").attr("data-dial-code")
        const error = this.validation();
        const regEmail = appController.regularExpressions('Email')
        if (error == 0) {

            if (regEmail.test(this.state.email)) {
                this.setState({ loading: true, errorMessage: '' })
                var length = AppController.dateDifference(this.state.checkin, this.state.checkout)
                var price=this.state.pricePerNight != '' ? this.state.pricePerNight : '---';
                var pet=this.state.pet != '' ? this.state.pet: '---';
                var comment=this.state.comments != '' ? this.state.comments : '---'

                const reservationInfo = {
                    Location: this.state.desiredLocation != '' ? this.state.desiredLocation : '---',
                    MoveInDate: this.state.checkin != '' ? this.state.checkin : '---',
                    MoveOutDate: this.state.checkout != '' ? this.state.checkout : '---',
                    Size: (this.state.bedRoom == '' || this.state.bedRoom == '0') ? 'All' : this.state.bedRoom,
                    SpecialNote: " Price: "+price+", pets: "+pet+", Comments: "+comment+"",
                    GuestFirstName: this.state.firstName != '' ? this.state.firstName : '---',
                    ClientReference: this.state.companyName != '' ? this.state.companyName : '---',
                    GuestEmail: this.state.email != '' ? this.state.email : '---',
                    GuestPhoneEmailTemp: this.state.phoneNumber != '' ? countryCode + this.state.phoneNumber : '---',
                    length: (length != '' && length != null) ? length : '---',
                    CreatedBy: this.state.email != '' ? this.state.email : '---',
                    GuestLastName: this.state.lastName != '' ? this.state.lastName : '---',
                    Pets: pet,
                    Adults: this.state.adult != '' ? this.state.adult : '---',
                    Child: this.state.children != '' ? this.state.children : '---',
                    contactMethod: this.state.contactMethod != '' ? this.state.contactMethod : '---',
                    comments: comment,
                    budget: price,
                    BookedBy: 'it@suiteamerica.com',
                    PhContryCode: countryCode,
                    GuestPhone: this.state.phoneNumber != '' ? this.state.phoneNumber : '---',
                }              
                    const response = await ReserveYourStayService.baeReserveYourStay(reservationInfo);
                    this.setState({ loading: false })
                    if (response.status) {
                        jQuery(function ($) {
                            $('#book_now').modal('hide');
                            $('#reservationsuccessmodal').modal('show');
                        }.bind(this));
                        setTimeout(function () { jQuery('#reservationsuccessmodal').modal('hide'); }.bind(this), 3000);
                        this.resetData();
                        document.getElementById("reserve-yourstay").value = ''
                    }
            } else {
                this.setState({ errorMessage: 'Please Enter Vaild Email', color: 'red' })
            }
        }
        else {
            this.setState({ errorMessage: 'Please Enter Required Fields', color: 'red' })
        }
    }
    setNumber(e) {
        const re = /^[0-9\b]+$/;
        if (e.target.value == '' || re.test(e.target.value)) {
            this.setState({ phoneNumber: e.target.value })
        }
    }
    async onChangeCaptcha(CaptchaValue) {
        await this.setState({ CaptchaValue })
    }
   render(){
      const ListDatas =  this.state.ListData
   
       return(   
<div> <GuestHeader />

{/* <div className="ht-w-150"></div> */}

        <div className="container">
		<div className="baepage">
			
			<img src="assets/images/BAESlogo.png" width='220'/>
			
            <div className="choosedst_div d-flex align-items-center">
				
                <div className="">
                    
                    <h1 className="text-center mb-5">CHOOSE YOUR DESTINATION</h1>
                    
                {ListDatas.map((row,index)=>{
                    return(
                    <div key={index} className="col-sm-3 col-xs-6">
                        <div className="img_circle">
                        <Link to={row.webUrl} target="_blank">
                        <img src={row.image} alt=""/>
                        </Link>
                        </div>
                        <p>{row.line}</p>
                </div>) })}
                </div>
                </div>
                <div className="reservestay">
               <div className="tb-slide-content ">
                  <h2 className="text-red">Reserve Your Stay</h2>
                  {this.state.errorMessage != "" ? <h6 style={{ color: 'red' }}>{this.state.errorMessage}</h6> : ""}
                  <div className="">
                     <form onSubmit={this.reservationSubmit}>
                        <div className="flex-form mrg-top-5">
                           <div className="flex-form-item search-input">
                              <input id="reserve-yourstay" onChange={this.setLocation} type="search" className="form-control iconed" placeholder="Desired Location (City, ST) *" />
                              <div className="info-field-icon "><a><i className="fa fa-map-marker" ></i></a></div>
                           </div>
                           <div className="flex-form-item">
                              <input type="text" style={{ backgroundColor: '#FFF' }} className="form-control" name='checkin' autoComplete="off" value={this.state.checkin} placeholder="Check In *" onChange={this.checkInMethod} />
                              <div className="info-field-icon"><a><i className="fa fa-calendar" id="CheckInRange" ></i></a></div>
                           </div>
                           <div className="flex-form-item">
                              <input type="text" style={{ backgroundColor: '#FFF' }} className="form-control" name='checkout' autoComplete="off" value={this.state.checkout} placeholder="Check Out *" onChange={this.checkOutMethod} />
                              <div className="info-field-icon"><a><i className="fa fa-calendar" id="CheckOutRange"></i></a></div>
                           </div>
                           <div className="flex-form-item">
                              <select value={this.state.bedRoom} onChange={(e) =>
                                 { this.setValue('bedRoom', e) }} className="form-control" placeholder="Check In" >
                                 {this.state.bedRoomsList.map((row, index) => {
                                 return (
                                 <option id="1" key={index} value={row.value}>{row.name}</option>
                                 )
                                 })}
                              </select>
                              <div className="info-field-icon" ><a><i className="fa fa-bed" ></i></a></div>
                           </div>
                        </div>
                        <div className="flex-form reserve_switch_con mrg-top-5">
                           <h5>MY PRICE PER NIGHT</h5>
                           {this.state.priceList.map((row, index) => {
                           return (
                           <div className="switch_container flex-form-item" key={index}>
                              <label className="switch">
                              <input type="checkbox" name={row} checked={this.state.pricePerNight == row ? true : false} onChange={(e) => this.toggleChange(row, e)} />
                              <span className="slider round"></span>
                              </label>
                              <p>{row}</p>
                           </div>
                           )
                           })}
                        </div>
                        <div className="flex-form reserve-half">
                           <div className="flex-form-item">
                              <input type="text" style={{backgroundColor:'#FFF'}} className="form-control" name='reserve_firstname'  placeholder="First Name *" value={this.state.firstName} onChange={(e)=>{this.setValue('firstName',e)}}/>
                           </div>
                           <div className="flex-form-item">
                              <input type="text" style={{backgroundColor:'#FFF'}} className="form-control" name='reserve_firstname'  placeholder="Last Name *" value={this.state.lastName} onChange={(e)=>{this.setValue('lastName',e)}}/>
                           </div>
  
                           <div className="flex-form-item">
                              <input type="text" style={{backgroundColor:'#FFF'}} className="form-control" name='reserve_email' placeholder="Email *" value={this.state.email} onChange={(e)=>{this.setValue('email',e)}}/>
                           </div>
                           <div className="flex-form-item">
                              <input type="text"  id="reserveNowPhone"  maxLength="10" autoComplete="off" style={{backgroundColor:'#FFF'}}  className="form-control"  name='reserve_phone' placeholder="Phone"  value={this.state.phoneNumber} onChange={(e)=>{this.setNumber(e)}}/>
                           </div>
                        </div>
                
                       
                        <div className="flex-form reserve-half">
                           <div className="flex-form-item">
                              <select value={this.state.pet}  onChange={(e)=>
                                 {this.setValue('pet',e)}} className="form-control">
                                 {this.state.petsList.map((row,index)=>{
                                 return(
                                 <option id="1" key={index} value={row.value}>{row.name}</option>
                                 )
                                 })}
                              </select>
                              <div className="info-field-icon" ><a><i className="fa fa-paw" ></i></a></div>
                           </div>
                           <div className="flex-form-item">
                              <select value={this.state.adult}  onChange={(e)=>
                                 {this.setValue('adult',e)}} className="form-control"  >
                                 {this.state.adultsList.map((row,index)=>{
                                 return(
                                 <option id="1" key={index} value={row.value}>{row.name}</option>
                                 )
                                 })}
                              </select>
                              <div className="info-field-icon" ><a><i className="fa fa-graduation-cap" ></i></a></div>
                           </div>
                           <div className="flex-form-item">
                              <select value={this.state.children}  onChange={(e)=>
                                 {this.setValue('children',e)}} className="form-control"  >
                                 {this.state.childrensList.map((row,index)=>{
                                 return(
                                 <option id="1" key={index} value={row.value}>{row.name}</option>
                                 )
                                 })}
                              </select>
                              <div className="info-field-icon" ><a><i className="fa fa-child" ></i></a></div>
                           </div>
                           <div className="flex-form-item">
                              <select value={this.state.contactMethod}  onChange={(e)=>
                                 {this.setValue('contactMethod',e)}} className="form-control" >
                                 {this.state.contactMethods.map((row,index)=>{
                                 return(
                                 <option id="1" key={index} value={row.value}>{row.name} </option>
                                 )
                                 })}
                              </select>
                           </div>
                        </div>
                        <br/>
                        <div className="flex-form reserve-half">
                           <div className="flex-textarea-item">
                              <textarea className="form-control" rows="5" id="comment" placeholder="Special Note" value={this.state.comments} onChange={(e) => { this.setValue('comments', e) }} />
                           </div>
                        </div>
                        <br />
                        {/* <div className="col-md-6">
                        <ReCAPTCHA
                           ref={ref =>
                        this.recaptcha = ref}
                        sitekey="6Lcbw1AUAAAAAE5K5cYDyhoGu0RJY9C3CLtGFXAo"
                        onChange={this.onChangeCaptcha}
                        />
                        </div> */}
                        <div className="col-md-12">
                        {this.state.loading ?
                        <div className="panel-body_loader" ><br /> <span style={{ fontSize: '25px', width: '100%', marginTop: '300px', textAlign: 'center', color: '#E93530' }}><i className="fa fa-spinner fa-spin" /> please wait ...!</span>
                        </div>
                        :
                        <div className="search-btn mrg-top-10">
                           <button type="submit" className="btn btn-danger"> Reserve My Stay </button>
                        </div>
                        }
                        </div>
                      <div className="mrg-btm-100"></div>
                      <h3 className="color-red ">
                      Don’t see where you need to be? Contact us and we can start a custom search for you!
                      </h3>
                   <ul className="text-left bullet-list">
                     <li>Stay as little as 5 days in some locations</li>
                     <li>Customize your stay with us to feel right at home on your next business trip</li>
                     <li>2x the space of a hotel room with a similar (or lower!) price</li>
                     <li>Cook a meal, do some laundry, bring your pet…it’s your space on your terms</li>
                   </ul>
                   <div className="mrg-btm-100"></div>
                   </form>
                  </div>
               </div>
            </div>
            <ReservationSuccesModal />
		</div>
	</div>
    </div>  
       );
   }
   }
   export default BAEPdf;

   
        