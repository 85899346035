import DestinationLanding from '../nikeDs/destinationLanding';
import AppleLanding from '../appleDs/appleLanding'
import NoPricingLanding from '../noPricingDs/noPricingLanding'
import VirtualLanding from '../virtual/virtualLanding'

class DsLanding extends React.Component {    
      constructor(props){
        super(props);
        this.state={
            companyArray: ['nike','apple','nopricing']
        }
      }
      templateLoad(){
        const companyInfo = this.props.match.params
        let data;
        if(companyInfo.companyName=='nike'){
            data= <DestinationLanding itineraryData={companyInfo}/>
        }else if(companyInfo.companyName=='pricing'){
            data= <DestinationLanding itineraryData={companyInfo} companyNameDetails="pricing"/>
        }else if(companyInfo.companyName=='apple'){
            data= <AppleLanding itineraryData={companyInfo}/>
        }else if(companyInfo.companyName=='nopricing'){
            data= <NoPricingLanding itineraryData={companyInfo}/>
        }else if(companyInfo.companyName=='virtual'){
            data= <VirtualLanding itineraryData={companyInfo} companyNameDetails="virtual"/>
        }
        return data;
      }
      render(){        
          return(<span>{this.templateLoad()}</span>)
      }
}
export default DsLanding