import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import reserveNowApi from "../../../services/reserveNowApi";
import usersService from "../../../services/usersService";

class RequestCallback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeId: "business",
      count: 0,
      checkIn: "",
      checkOut: "",
      yourName: "",
      phoneNumber: "",
      emailaddress: "",
      companyname: "",
      selectLocation: "",
      guestNumber: "",
      selectedBedRooms: "",
      dailyBudget: "",
      moveInDate: null,
      moveOutDate: null,
      moveInDateSelected: "",
      moveOutDateSelected: "",
      selectLocation: "",
      success: false,
      errorMessage: "",
      loader: false,
    };
    this.managerEmail = this.props.managerEmail;
    this.handleClick = this.handleClick.bind(this);
    this.setLocation = this.setLocation.bind(this);
    this.handleMoveIn = this.handleMoveIn.bind(this);
    this.handleMoveOut = this.handleMoveOut.bind(this);
    this.closed = this.closed.bind(this);
  }

  async componentDidMount() {
    $("#business").addClass("active");
    $(document).ready(function () {
      jQuery(function ($) {
        $("#phoneNumber").intlTelInput();
      });
    });

    await this.setState({
      selectedcommunityName: this.props.selectedcommunityName,
    });
    $(".overlay").on("click", function () {
      console.log("Active overlay clicked!");
      $("#sideQuote").removeClass("active");
      $(".overlay").removeClass("active");
    });
  }
  async componentWillReceiveProps() {
    await this.setState({
      selectedcommunityName: this.props.selectedcommunityName,
    });
  }
  async closed() {
    $("#yourName").removeClass("validation");
    $("#phoneNumber").removeClass("validation");
    $("#emailaddress").removeClass("validation");
    $("#companyname").removeClass("validation");
    $("#selectLocation").removeClass("validation");
    $("#checkOut").removeClass("validation");
    $("#checkIn").removeClass("validation");
    $("#sideQuote").removeClass("active");
    $(".overlay").removeClass("active");

    await this.setState({
      yourName: "",
      phoneNumber: "",
      emailaddress: "",
      companyname: "",
      selectLocation: "",
      count: 0,
      guestNumber: "",
      moveInDate: null,
      moveOutDate: null,
      moveInDateSelected: "",
      moveOutDateSelected: "",
      selectedcommunityName: null,
      errorMessage: "",
    });
  }

  async handleClick(Id) {
    $("#yourName").removeClass("validation");
    $("#phoneNumber").removeClass("validation");
    $("#emailaddress").removeClass("validation");
    $("#companyname").removeClass("validation");
    $("#selectLocation").removeClass("validation");
    $("#checkOut").removeClass("validation");
    $("#checkIn").removeClass("validation");

    $("#business").removeClass("active");

    $("#individuals").removeClass("active");

    $("#" + Id).addClass("active");

    await this.setState({
      activeId: Id,
      yourName: "",
      phoneNumber: "",
      emailaddress: "",
      companyname: "",
      selectLocation: "",
      count: 0,
      guestNumber: "",
      moveInDate: null,
      moveOutDate: null,
      moveInDateSelected: "",
      moveOutDateSelected: "",
      errorMessage: "",
      selectedBedRooms: "",
    });
    $(document).ready(function () {
      jQuery(function ($) {
        $("#phoneNumber").intlTelInput("setCountry", "us");
      });
    });
  }

  handleIncrease() {
    this.setState({
      count: this.state.count + 1,
    });
  }

  handleDecrease() {
    this.setState({
      count: this.state.count - 1,
    });
  }

  async inputHandler(inputName, event) {
    event.persist();
    $(".error").removeClass("validation");
    if (inputName == "guestNumber") {
      this.setState({
        count: event.target.value,
      });
    }

    let inputVal = event.target.value;
    let inputObject = {};
    inputObject[inputName] = inputVal;

    if (
      inputName == "emailaddress" &&
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(inputVal.trim())
    ) {
      $("#" + inputName).addClass("validation");
    } else {
      $("#" + inputName).removeClass("validation");
    }
    await this.setState(inputObject);
    if (inputName == "phoneNumber") {
      var x = event.target.value
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      event.target.value = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + ((x[3] && "-" + x[3]) || "");
      await this.setState({ phoneNumber: event.target.value });
    }

    event.persist();
  }

  setDailyBudget(inputName, event) {
    event.persist();
    let reg = /^\d{0,4}$/;
    if (inputName == "dailyBudget" && reg.test(event.target.value)) {
      this.setState({ [inputName]: event.target.value });
    }
  }
  _handleKeyPressset(evt) {
    var theEvent = evt;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    var regex = /^\d+$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  async handleSubmit() {
    let errorVal = 0;

    if (this.state.yourName == "") {
      $("#yourName").addClass("validation");
      await this.setState({ loader: false });
      errorVal++;
    } else {
      $("#yourName").removeClass("validation");
    }
    if (this.state.phoneNumber == "") {
      $("#phoneNumber").addClass("validation");
      await this.setState({ loader: false });
      errorVal++;
    } else {
      $("#phoneNumber").removeClass("validation");
    }

    if (
      this.state.dailyBudget == "" ||
      !/^\d{2,4}$/.test(this.state.dailyBudget) ||
      Number(this.state.dailyBudget) < 10
    ) {
      $("#dailyBudget").addClass("validation");
      await this.setState({ loader: false });
      errorVal++;
    } else {
      $("#dailyBudget").removeClass("validation");
    }

    if (
      this.state.emailaddress == "" &&
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
        this.state.emailaddress
      )
    ) {
      $("#emailaddress").addClass("validation");
      await this.setState({ loader: false });
      errorVal++;
    } else {
      $("#emailaddress").removeClass("validation");
    }
    if (this.state.activeId == "business") {
      if (this.state.companyname == "") {
        $("#companyname").addClass("validation");
        await this.setState({ loader: false });
        errorVal++;
      } else {
        $("#companyname").removeClass("validation");
      }
    }

    if (this.state.selectLocation == "") {
      $("#selectLocation").addClass("validation");
      await this.setState({ loader: false });
      errorVal++;
    } else {
      $("#selectLocation").removeClass("validation");
    }

    if (this.state.moveOutDateSelected == "") {
      $("#checkOut").addClass("validation");
      await this.setState({ loader: false });
      errorVal++;
    } else {
      $("#checkOut").removeClass("validation");
    }

    if (this.state.moveInDateSelected == "") {
      $("#checkIn").addClass("validation");
      await this.setState({ loader: false });
      errorVal++;
    } else {
      $("#checkIn").removeClass("validation");
    }

    if (this.state.selectedBedRooms == "") {
      $("#bedSizeSelect").addClass("validation");
      await this.setState({ loader: false });
      errorVal++;
    } else {
      $("#bedSizeSelect").removeClass("validation");
    }

    if (errorVal > 0) {
      this.setState({
        errorStatus: true,
        loaderlogin: false,
        fieldserror: true,
        loader: false,
      });
    } else {
      this.setState({ loader: true });
      const countryCode =
        $(".country.highlight.active").attr("data-dial-code") == undefined
          ? "+1"
          : "+" + $(".country.highlight.active").attr("data-dial-code");

      const reserveYourStay = {
        Location: this.state.selectLocation,
        MoveInDate: moment(this.state.moveInDateSelected).format("MM/DD/YYYY"),
        MoveOutDate: moment(this.state.moveOutDateSelected).format(
          "MM/DD/YYYY"
        ),
        Size: "1X",
        GuestFirstName: this.state.yourName,
        ClientReference: this.state.companyname,
        GuestEmail: this.state.emailaddress,
        GuestPhone: this.state.phoneNumber,
        CreatedBy:
          this.state.emailaddress != "" ? this.state.emailaddress : "---",
        BookedBy: "it@suiteamerica.com",
        PhContryCode: countryCode,
        length: moment(this.state.moveOutDateSelected).diff(
          moment(this.state.moveInDateSelected),
          "days"
        ),
        SpecialNote: String(this.state.count),
        selectedBedRooms: this.state.selectedBedRooms,
        dailyBudget: this.state.dailyBudget,
      };
      const orderResponse = await reserveNowApi.webLeadQuotes(reserveYourStay);
      // const orderResponse = { error: 0, message: { orderId: 123456 } };

      if (orderResponse.error == 0) {
        console.log("entered reserve your stay");
        const reserveYourStayEmail = {
          Location: this.state.selectLocation,
          MoveInDate: moment(this.state.moveInDateSelected).format(
            "MM/DD/YYYY"
          ),
          MoveOutDate: moment(this.state.moveOutDateSelected).format(
            "MM/DD/YYYY"
          ),
          GuestFirstName: this.state.yourName,
          ClientReference: this.state.companyname,
          GuestEmail: this.state.emailaddress,
          length: moment(this.state.moveOutDateSelected).diff(
            moment(this.state.moveInDateSelected),
            "days"
          ),
          SpecialNote: String(this.state.count),
          GuestPhoneEmailTemp: countryCode + this.state.phoneNumber,
          community:
            this.props.CommunityDetails == undefined
              ? "---"
              : this.props.CommunityDetails.community_name,
          communityAddress:
            this.props.CommunityDetails == undefined
              ? "---"
              : this.props.CommunityDetails.address,
          communityState:
            this.props.CommunityDetails == undefined
              ? "---"
              : this.props.CommunityDetails.state_name,
          communityCity:
            this.props.CommunityDetails == undefined
              ? "---"
              : this.props.CommunityDetails.city_name,
          communityZipCode:
            this.props.CommunityDetails == undefined
              ? "---"
              : this.props.CommunityDetails.ZipCode,
          status: "1",
          OrderId: orderResponse.message.orderId,
          selectedBedRooms: this.state.selectedBedRooms,
          dailyBudget: this.state.dailyBudget,
          managerEmail: this.managerEmail,
        };
        const resReserveYourStay = await usersService.reserveYourStay(
          reserveYourStayEmail
        );

        if (resReserveYourStay.status == true) {
          await this.setState({
            yourName: "",
            phoneNumber: "",
            emailaddress: "",
            companyname: "",
            selectLocation: "",
            count: 0,
            guestNumber: "",
            moveInDate: null,
            moveOutDate: null,
            moveInDateSelected: "",
            moveOutDateSelected: "",
            selectedBedRooms: "",
            dailyBudget: "",
            success: true,
            selectedcommunityName: null,
            loader: false,
          });
          $(".overlay").removeClass("active");
          $("#sideQuote").removeClass("active");
          $("#successModal").modal("show");
          const _this = this;
          jQuery(function ($) {
            setTimeout(function () {
              _this.setState({ success: false });
              $("#successModal").modal("hide");
              _this.closed();
            }, 4000);
          });
        }
      } else {
        await this.setState({
          loader: false,
          errorMessage: "Order Is Not Created",
        });
      }
    }
  }

  /* To update the location field auto complete value */
  setLocation(e) {
    const _this = this;
    _this.setState({ selectLocation: e.target.value });
    var places = new google.maps.places.Autocomplete(
      document.getElementById("selectLocation")
    );
    google.maps.event.addListener(places, "place_changed", function () {
      _this.setState({ selectLocation: places.getPlace().formatted_address });
    });
  }

  async handleMoveIn(date) {
    await this.setState({
      moveInDate: moment(date),
      moveInDateSelected: date.format("YYYY-MM-DD HH:mm:ss"),
    });
  }
  async handleMoveOut(date) {
    await this.setState({
      moveOutDate: moment(date),
      moveOutDateSelected: date.format("YYYY-MM-DD HH:mm:ss"),
    });
  }

  render() {
    return (
      <div>
        <section id="sideQuote">
          <div className="quoteHeading">
            <h4>We would love to help you </h4>
            <div id="dismiss">
              <a onClick={this.closed}>
                <i className="bi bi-x"></i>
              </a>
            </div>
          </div>
          <div className="formQuote">
            <div
              className="btn-group"
              role="group"
              aria-label="Basic outlined example"
            >
              <button
                type="button"
                id="business"
                className="btn black-btn"
                onClick={() => this.handleClick("business")}
              >
                For Businesses
              </button>
              <button
                type="button"
                id="individuals"
                className="btn black-btn"
                onClick={() => this.handleClick("individuals")}
              >
                For Individuals
              </button>
            </div>
            <form action="">
              <span style={{ color: "red" }}>
                {this.state.errorMessage}
                {/* Order Is Not Created */}
              </span>
              <div className="floating-label-group">
                <input
                  type="text"
                  className="form-control"
                  required
                  value={this.state.yourName}
                  id="yourName"
                  onChange={this.inputHandler.bind(this, "yourName")}
                />
                <label className="floating-label">Name</label>
              </div>
              <div className="phone">
                <div className="floating-label-group requestPlaceHolder">
                  <input
                    type="text"
                    id="phoneNumber"
                    autoComplete="off"
                    className="form-control"
                    required
                    placeholder="Phone"
                    value={this.state.phoneNumber}
                    onChange={this.inputHandler.bind(this, "phoneNumber")}
                  />
                </div>
              </div>
              <div className="floating-label-group">
                <input
                  type="text"
                  className="form-control"
                  required
                  autoComplete="off"
                  id="emailaddress"
                  onChange={this.inputHandler.bind(this, "emailaddress")}
                  value={this.state.emailaddress}
                />
                <label className="floating-label">Email Address</label>
              </div>
              {this.state.selectedcommunityName !== null &&
              this.state.selectedcommunityName !== undefined ? (
                <div className="floating-label-group">
                  <input
                    type="text"
                    className="form-control"
                    required
                    autoComplete="off"
                    id="companyname"
                    readOnly
                    placeholder={""}
                    //onChange={this.inputHandler.bind(this, 'communityname')}
                    value={this.state.selectedcommunityName}
                    disabled
                  />
                  {/* <label className="floating-label">Community Name</label> */}
                </div>
              ) : null}
              {this.state.activeId == "individuals" ? null : (
                <div className="floating-label-group">
                  <input
                    type="text"
                    className="form-control"
                    required
                    autoComplete="off"
                    id="companyname"
                    onChange={this.inputHandler.bind(this, "companyname")}
                    value={this.state.companyname}
                  />
                  <label className="floating-label">Company Name</label>
                </div>
              )}

              <div className="floating-label-group no-placeholder">
                <input
                  type="text"
                  className="form-control"
                  required
                  id="selectLocation"
                  onChange={(e) => this.setLocation(e)}
                  value={this.state.selectLocation}
                  placeholder={""}
                />
                <label className="floating-label">Location Desired</label>
              </div>

              {/* <div className="form-group">
                <label className="form-control">Guests</label>
                <div className="input-group guestCount">
                  <button
                    type="button"
                    className="btn btn-default btn-number"
                    data-type="plus"
                    data-field="quant[1]"
                    onClick={() => this.handleDecrease()}
                    disabled={this.state.count == 0 ? true : false}
                  >
                    <i className="bi bi-dash"></i>
                  </button>

                  <input
                    type="text"
                    className="form-control input-number"
                    value={this.state.count}
                    min="1"
                    max="10"
                    id="guestNumber"
                    onKeyPress={this._handleKeyPressset}
                    onChange={this.inputHandler.bind(this, "guestNumber")}
                  />

                  <button
                    type="button"
                    className="btn btn-default btn-number"
                    data-type="minus"
                    data-field="quant[1]"
                    onClick={() => this.handleIncrease()}
                  >
                    <i className="bi bi-plus"></i>
                  </button>
                </div>
              </div> */}

              <div className="floating-label-group" id="bedSizeSelect">
                <select
                  className="form-control"
                  onChange={(e) => this.inputHandler("selectedBedRooms", e)}
                  value={this.state.selectedBedRooms}
                >
                  <option value="" disabled selected>
                    Size
                  </option>
                  <option value="Studio">Studio</option>
                  <option value="One Bedroom">One Bedroom</option>
                  <option value="Two Bedroom">Two Bedroom</option>
                  <option value="Three Bedroom">Three Bedroom</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              <div className="floating-label-group">
                <input
                  type="text"
                  className="form-control"
                  required
                  value={this.state.dailyBudget}
                  id="dailyBudget"
                  onChange={this.setDailyBudget.bind(this, "dailyBudget")}
                />
                <label className="floating-label">Daily Budget ($)</label>
              </div>

              <div className="datesView">
                <div className="floating-label-group requestPlaceHolder">
                  <DatePicker
                    type="text"
                    className="form-control"
                    placeholder="*Arrival Date"
                    selected={this.state.moveInDate}
                    id="checkIn"
                    minDate={new Date()}
                    maxDate={
                      this.state.moveOutDate !== null
                        ? moment(this.state.moveOutDate, "DD-MM-YYYY").subtract(
                            1,
                            "days"
                          )
                        : null
                    }
                    onChange={this.handleMoveIn}
                    placeholderText="Check In"
                  />
                </div>
                <div className="floating-label-group requestPlaceHolder">
                  <DatePicker
                    type="text"
                    className="form-control"
                    placeholder="*Departure Date"
                    selected={this.state.moveOutDate}
                    id="checkOut"
                    minDate={
                      this.state.moveInDate !== null
                        ? moment(this.state.moveInDate, "DD-MM-YYYY").add(
                            1,
                            "days"
                          )
                        : moment(new Date(), "DD-MM-YYYY")
                    }
                    onChange={this.handleMoveOut}
                    placeholderText="Check Out"
                  />
                </div>
              </div>
              {this.state.loader ? (
                <span className="span_spinner">
                  <i className="fa fa-spinner fa-spin" /> please wait...!
                </span>
              ) : (
                <button
                  type="button"
                  className="btn black-btn"
                  onClick={() => this.handleSubmit()}
                >
                  {" "}
                  Place Request{" "}
                </button>
              )}
            </form>
            {/* {this.state.success ?
            <div className="alert alert-success" role="alert">
              Mail Sent Successfully!
            </div> : ''
          } */}
          </div>
        </section>
        <div
          className="modal fade savesearch SAweb_modalPoup"
          id="successModal"
          role="dialog"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn close"
                  data-dismiss="modal"
                  aria-label=""
                >
                  <span>
                    {" "}
                    <i className="bi bi-x"></i>{" "}
                  </span>
                </button>
              </div>
              <div className="modal-body width100per">
                <div className="alert alert-2 text-center">
                  <strong>Thank You!</strong> We’ll get back to you shortly.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default RequestCallback;
