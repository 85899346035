/* Importing the node modules, child components, services and controllers used 
   inside RequestReservation component */ 
   import DatePicker from 'react-datepicker'
   import moment from 'moment'
   import AppController from "../../controller/appController";
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import NikeServices from '../../../services/nikeService';
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   import ReCAPTCHA from "react-google-recaptcha"
   import SuccessModal from '../../common/successModal';
   /* RequestReservation Component initialization */
   class RequestReservation extends React.Component {
       /* Initializing objects of its RequestReservation class */
       constructor(props) {
           super(props)
           this.state={
               CaptchaValue:'',
               firstName: '',
               lastName: '',
               stateName: 'Alaska',
               cityName: '',
               apartmentCommunity: '',
               budget: '$4000 - $5000/month',
               size: '1 Bedroom',
               email: '',
               phone: '',
               company: '',
               arrivalDate:moment(),
               departureDate:moment(),
               furnishedApartment: '',
               message: '',
               color:'',
               errorMessage:'',
               housing:'Relocating for Job',
               housingDropDown:['Relocating for Job','Contract/Project Work','Internship','Temporary Assignment','Insurance Housing', 'Training and Entertainment Contract'],
               stateDropDown:['Alaska','Alabama','Arkansas','Arizona','California', 'Colorado', 'Connecticut',
               'Washington DC','Delaware','Florida', 'Georgia','Hawaii','Iowa','Idaho', 'Illinois','Indiana',
               'Kansas','Kentucky', 'Louisiana','Massachusetts','Maryland','Maine', 'Michigan','Minnesota','Missouri',
               'Mississippi', 'Montana','North Carolina','North Dakota','Nebraska', 'New Hampshire','New Jersey','New Mexico','Nevada', 
               'New York','Ohio','Oklahoma','Ontario', 'Oregon','Pennsylvania','Rhode Island','South Carolina', 'South Dakota','Tennessee',
               'Texas','Utah', 'Virginia','Vermont','Washington','Wisconsin', 'West Virginia','Wyoming'],
               budgetDropDown:['$4000 - $5000/month','$5001 - $6000/month','$6001 - $7000/month','$7000+/month'],
               sizeDropdown: ['1 Bedroom', '2 Bedrooms', '3 Bedrooms', 'Studio', 'Other'],
               dogsDropdown: ['0', '1', '2', '3'],
               catsDropdown: ['0', '1', '2', '3'],
               hearDropdown: ['SuiteAmerica Guest/Client','Friend or Associate', 'Apartment Community', 'Web Search', 
               'Online News/Press', 'Social Media', 'GSA Website', 'FedRooms', 'Other Web Site', 'Other'],
               hearSize: 'SuiteAmerica Guest/Client',
               catsSize: '0',
               dogsSize: '0',
               petWeight: '',
               arrivalDateSelected: '',
               departureDateSelected: '',
               communities: ''
           }
           this.setValue = this.setValue.bind(this);
           this.checkedStatus = this.checkedStatus.bind(this);
           this.handleMoveIn = this.handleMoveIn.bind(this);
           this.handleMoveOut = this.handleMoveOut.bind(this);
           this.serviceRequest = this.serviceRequest.bind(this);
           this.setChecked = this.setChecked.bind(this);
           this.onChangeCaptcha = this.onChangeCaptcha.bind(this);
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
           MainController.getInitialInformation();
        }
        /* Form Details object creations  */

      setValue(field, e) {
            var object = {};
            object[field] = e.target.value;
            this.setState(object);
          }
      /* Form Details object creations  */

      setChecked(e) {
        this.setState({furnishedApartment:e.target.value});
      }
    
     /* MoveIndate and MoveOutDate   */
      async handleMoveIn(date){
            await this.setState({arrivalDate: moment(date),
                arrivalDateSelected:date.format('YYYY-MM-DD HH:mm:ss') });
        }
    
      async handleMoveOut(date){
            await this.setState({departureDate: moment(date),
                departureDateSelected:date.format('YYYY-MM-DD HH:mm:ss') });
        }
      checkedStatus(field, e){
            this.setState({communities:e.target.checked ? 'Yes' : 'No'});
      }
    
    
       /* Service Request Details send to mail  */
      
       async serviceRequest(){
        const moveIn = this.state.arrivalDateSelected == '' ? this.state.arrivalDate.format('YYYY-MM-DD HH:mm:ss'): this.state.arrivalDateSelected ;
        const moveOut = this.state.departureDateSelected == '' ? this.state.departureDate.format('YYYY-MM-DD HH:mm:ss'): this.state.departureDateSelected ;
        const _this = this
           const validationFields=['firstName','lastName','email','phone', 'company', 'housing', 'stateName', 'cityName', 'budget', 'arrivalDate', 'departureDate', 'size','CaptchaValue']
           let error = 0;
           _.forEach(validationFields, function(value) {
              if(_this.state[value] == ""){
                error++;
              }
          });
         if(error == 0 && (moveIn <= moveOut)){
            const Request={
                firstName:this.state.firstName,
                email:this.state.email,
                company:this.state.company,
                lastName:this.state.lastName,
                phone:this.state.phone,
                housing: this.state.housing,
                stateName: this.state.stateName,
                budget: this.state.budget,
                size: this.state.size,
                cityName:this.state.cityName,
                arrivalDate:this.state.arrivalDate.format('YYYY-MM-DD'),
                departureDate:this.state.departureDate.format('YYYY-MM-DD'),
                apartmentCommunity: this.state.apartmentCommunity,
                dogsSize: this.state.dogsSize,
                petWeight: this.state.petWeight,
                hearSize: this.state.hearSize,
                catsSize: this.state.catsSize,
                furnishedApartment: this.state.furnishedApartment,
                message: this.state.message,
                communities: this.state.communities == '' ? 'No' : this.state.communities,
                mail:'requestReservation',
                subject: 'Request Reservation Form'
            }
    
        this.setState({
            firstName: '',
            email: '',
            company: '',
            lastName: '',
            phone: '',
            housing: '',
            stateName: '',
            budget: '',
            size: '',
            cityName: '',
            arrivalDate:moment(),
            departureDate:moment(),
            apartmentCommunity: '',
            dogsSize: '',
            petWeight: '',
            hearSize: '',
            catsSize: '',
            furnishedApartment: '',
            message: '',
            color:'',
            errorMessage:'',
           
 
        })
        
        $('input[name="radio"]').prop('checked', false);
        $('input[name="1"]').prop('checked', false);
        AppController.menuTopNavigation('serviceRequest');
        jQuery(function($) {
            $('#successModal').modal('show');
            setTimeout(function(){
              $('#successModal').modal('hide');
            },4000);
          });
        await NikeServices.serviceRequest(Request)
        
    }
    else{
        AppController.menuTopNavigation('serviceRequest');
        this.setState({errorMessage:'Please Enter Required Fields, Captcha & Departure Date is greater than are equal to Arrival Date ',color:'red'})
    }
        
       }
      
       async onChangeCaptcha(CaptchaValue) {
        await this.setState({CaptchaValue})
        }
       /* It is invoked to return html content */
       render() {
           return (
            <div className="corporateHousing_Updates">
                <GuestHeader/>
                <div className="cover-tophd-ht"></div>
                <div className="container-fluid nike company_section" id="serviceRequest">
                    <div className="container text-center">
                        <h2 className="">Information Request</h2>
                        <p>As the preferred Business to Business (B2B) relocation choice, our fully-furnished apartments are always ready for you. For more information, simply fill out this form. You will then receive an email confirmation that the request was received and hear from us by phone or email within 2 business hours to confirm your information and answer any questions.</p>
                    </div>
                </div>
                <div className="container-fluid nike-datepicker bg-grey service_request">
                    <div className="request_form company_form" >
                        <div className="container">
                            <form className="well">
                            <p style={{color:this.state.color}}>{this.state.errorMessage}</p>
                            <h1>Contact Information</h1>
                            <div className="row">
                                <div className="col-sm-6 col-md-6">
                                    <input type="text" className="form-control" placeholder="*First Name" value={this.state.firstName} onChange={(e) => this.setValue('firstName', e)}/>
                                    <input type="text" className="form-control" placeholder="*E-mail Address" value={this.state.email} onChange={(e) => this.setValue('email', e)}/>
                                    <input type="text" className="form-control" placeholder="*Company" value={this.state.company} 
                                    onChange={(e) => this.setValue('company', e)} />
                                </div>
                                <div className="col-sm-6 col-md-6">
                                <input type="text" className="form-control" placeholder="*Last Name" value={this.state.lastName} 
                                onChange={(e) => this.setValue('lastName', e)} />
                                <input type="text" className="form-control" placeholder="*Phone" value={this.state.phone} 
                                onChange={(e) => this.setValue('phone', e)} />                                
                                    <label className="custom-select">
                                        <select value={this.state.housing} 
                                        onChange={(e) => this.setValue('housing', e)}>                                        
                                        {this.state.housingDropDown.map((row, index) => {
                                        return(
                                        <option key={index} value={row}>{row}</option>
                                        )
                                        })}
                                        </select>
                                    </label>
                                </div>
                            </div>
                            <hr />
                            <h1>Destination Information</h1>
                            <div className="row">
                                <div className="col-sm-6 col-md-6">
                                    <label className="custom-select">
                                        <select value={this.state.stateName} 
                                        onChange={(e) => this.setValue('stateName', e)}>                                        
                                        {this.state.stateDropDown.map((row, index) => {
                                        return(
                                        <option key={index} value={row}>{row}</option>
                                        )
                                        })}
                                        </select>
                                    </label>
                                    <label className="custom-select">
                                        <select value={this.state.budget} 
                                        onChange={(e) => this.setValue('budget', e)}>                                        
                                        {this.state.budgetDropDown.map((row, index) => {
                                        return(
                                        <option key={index} value={row}>{row}</option>
                                        )
                                        })}
                                        </select>
                                    </label>
                                    <DatePicker type="text" className="form-control" placeholder="*Arrival Date" selected={this.state.arrivalDate}  
                                    onChange={this.handleMoveIn} />
                                    <label className="custom-select">
                                        <select value={this.state.size} 
                                        onChange={(e) => this.setValue('size', e)}>                                        
                                        {this.state.sizeDropdown.map((row, index) => {
                                        return(
                                        <option key={index} value={row}>{row}</option>
                                        )
                                        })}
                                        </select>
                                    </label>

                                    
                                </div>
                                <div className="col-sm-6 col-md-6">
                                <input type="text" className="form-control" placeholder="*City" value={this.state.cityName} 
                                onChange={(e) => this.setValue('cityName', e)} />
                                <DatePicker type="text" className="form-control" placeholder="*Departure Date" selected={this.state.departureDate}  
                                onChange={this.handleMoveOut} />
                                <input type="text" className="form-control" placeholder="*Apartment Community" value={this.state.apartmentCommunity} 
                                onChange={(e) => this.setValue('apartmentCommunity', e)} />
                                </div>
                            </div>
                            <hr />
                            <h1>Additional Information</h1>
                            <div className="row">
                                <div className="col-sm-6 col-md-6">
                                    <label className="custom-select">
                                        <select value={this.state.dogsSize} 
                                        onChange={(e) => this.setValue('dogsSize', e)}>
                                        {this.state.dogsDropdown.map((row,index)=>{
                                        return(
                                        <option key={index} value={row}>{row}</option>
                                        )
                                        })}
                                        </select>
                                    </label>
                                    <input type="text" className="form-control" placeholder="Weight/Breed of Pet" value={this.state.petWeight} 
                                    onChange={(e) => this.setValue('petWeight', e)} />  
                                    <label className="custom-select">
                                        <select value={this.state.hearSize} 
                                        onChange={(e) => this.setValue('hearSize', e)}>
                                        {this.state.hearDropdown.map((row,index)=>{
                                        return(
                                        <option key={index} value={row}>{row}</option>
                                        )
                                        })}
                                        </select>
                                    </label>
                                </div>
                                <div className="col-sm-6 col-md-6">
                                <label className="custom-select">
                                        <select value={this.state.catsSize} 
                                        onChange={(e) => this.setValue('catsSize', e)}>
                                        {this.state.catsDropdown.map((row,index)=>{
                                        return(
                                        <option key={index} value={row}>{row}</option>
                                        )
                                        })}
                                        </select>
                                    </label>
                                    <div className="row radiosec">
                                        <div className="col-sm-12">
                                        <label>Are you looking for a furnished apartment?</label>
                                        <div className="row">
                                            <div className="clearfix"></div>
                                            <div className="col-sm-3">
                                                <label className="custom_radio radio-inline">Yes
                                                <input type="radio" name="radio" value="yes" 
                                                onChange={(e) => this.setChecked(
                                                    e)} />
                                                <span className="checkmark"></span>
                                                </label>
                                            </div>
                                            <div className="col-sm-3">
                                                <label className="custom_radio radio-inline">No
                                                <input type="radio" name="radio" value="no" 
                                                onChange={(e) => this.setChecked(
                                                    e)}/>
                                                <span className="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                            <hr />
                            <h1>Additional Comments</h1>
                            <div className="row">
                                <div className="col-sm-12 col-md-12">
                                    <textarea className="form-control" placeholder="Comments, Special Requirements or Preferred Communities" value={this.state.message} 
                                    onChange={(e) => this.setValue('message', e)} rows="8" cols="5"></textarea>
                                </div>
                            </div>
                            <div className="row">                                
                                <div className="container">
                                    <div className="clearfix"></div>
                                    <div className="col-sm-12">
                                        <label className="custom_checkbox checkbox-inline mrg-top-20">
                                        *We know your time is valuable; there is no need to complete the form more than once if you identify multiple communities on our website that will work for you. By checking the box to the left, we will review all available options for your requested area and timeframe.<br/>
                                        
                                        <input type="checkbox" name="1" 
                                        onChange={(e) => this.checkedStatus('roommate', e)}/>
                                        <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                </div>
                                <div className="row">
               <div className="col-sm-12 col-md-12">
                 <ReCAPTCHA
                  ref="recaptcha"
                  sitekey="6Lcbw1AUAAAAAE5K5cYDyhoGu0RJY9C3CLtGFXAo"
                  onChange={this.onChangeCaptcha}
                />
          </div>
          
       </div>
                            </form>
                            <button type="button" className=" btn location_btn mrg-btm-70" onClick={this.serviceRequest}> SUBMIT</button>
                            <div>Once you submit your request, you will</div>
                            <div>
                                <ul className="bullet_list">
                                    <li>receive an <strong>email confirmation</strong> that the request was received.</li>
                                    <li>hear from us by <strong>phone or email</strong> within <strong>2 business hours</strong> to confirm your information and answer any questions you may have.</li>
                                </ul>
                            </div>    
                            <div className="mrg-btm-30"><i>Our pet friendly communities will not accept more than 2-3 pets.</i></div>
                        </div>
                    </div>
                </div>
                <SubFooter/>
                <Footer/>
                <SuccessModal/>
                </div>

   
           );
       }
   }
   export default RequestReservation;