'use strict'
import serviceBase from './serviceBase'

const FiveStarApiService = {
    CreateServiceRequestEmailLog:(data)=>serviceBase.post(`/mysuiteamerica-fivestar/api/CreateServiceRequestEmailLog`,data),
    GetDriversManagerList:(data)=>serviceBase.post(`/mysuiteamerica-fivestar/apis/GetDriversManagerList&&todate=${data.toDate}`,{}),
    CheckDriverAuthentication:(data)=>serviceBase.post(`/mysuiteamerica-fivestar/api/CheckDriverAuthentication`,data),
    GetServicesSchedulesByDriverIdManager:(data)=>serviceBase.post(`/mysuiteamerica-fivestar/apis/GetServicesSchedulesByDriverIdManager&&uniqueid=${data.uniqueid}&isManager=${data.isManager}&RDate=${data.toDate}&IsVendorManager=${data.IsVendorManager}&company=${data.Company}`,{}),
    SaveDriverSchedules:(data)=>serviceBase.post(`/mysuiteamerica-fivestar/api/SaveDriverSchedules`,data),
    getServiceScheduleById:(data)=>serviceBase.post(`/mysuiteamerica-fivestar/apis/GetServiceScheduleById&&uniqueid=${data.uniqueid}`,{}),
    getKeyList:(data)=>serviceBase.post(`/mysuiteamerica-fivestar/apis/GetKeyList&&masterid=${data.masterid}`,{}),
    getDriverList:(data)=>serviceBase.post(`/mysuiteamerica-fivestar/apis/GetDriverList&&date=${data.date}`,{}),
    saveServiceScheduleNotes:(data)=>serviceBase.post(`/mysuiteamerica-fivestar/api/SaveServiceScheduleNotes`,data),
    saveKeyList:(data)=>serviceBase.post(`/mysuiteamerica-fivestar/api/SaveKeyList`,data),
    getCommunitysByDriverId:(data)=>serviceBase.post(`/mysuiteamerica-fivestar/apis/GetCommunitysByDriverId&&uniqueid=${data.DriverUid}&DateRequested=${data.DateRequested}`,{}),
    updateSuiteId:(data)=>serviceBase.post('/mysuiteamerica-fivestar/api/UpdateSuiteReady',data),
    updateReScheduleDetails:(data)=>serviceBase.post('/mysuiteamerica-fivestar/api/UpdateReScheduleDetails',data),
    SaveFiveStarInspection:(data)=>serviceBase.post('/mysuiteamerica-fivestar/api/SaveFiveStarInspection',data),
    SaveFiveStarMeetGreet:(data)=>serviceBase.post('/mysuiteamerica-fivestar/api/SaveFiveStarMeetGreet',data),
    DriverForgotPassword:(data)=>serviceBase.post('/mysuiteamerica-fivestar/api/DriverForgotPassword',data),
    GetAppCode:(data)=>serviceBase.post(`/mysuiteamerica-fivestar/apis/GetAppCode&&orderid=${data.orderid}`,{}),
    GetTimeoutKey:(data)=>serviceBase.post('/mysuiteamerica-fivestar/api/GetTimeoutKey',data),
    GetActiveCommunities:(data)=>serviceBase.post('/mysuiteamerica-fivestar/api/GetActiveCommunities',data),
    GetMastersByCommunityId:(data)=>serviceBase.post('/mysuiteamerica-fivestar/api/GetMastersByCommunityId',data),
    GetMastersOccupancyInfo:(data)=>serviceBase.post('/mysuiteamerica-fivestar/api/GetMastersOccupancyInfo',data),
    GetDriversListByCompany:(data)=>serviceBase.post('/mysuiteamerica-fivestar/api/GetDriversListByCompany',data),
    AssignDriver:(data)=>serviceBase.post('/mysuiteamerica-fivestar/api/AssignDriver',data)

}
export default FiveStarApiService

