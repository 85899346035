import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";

import NikeService from "../../../../../services/nikeService";
import BookitApiService from "../../../../../services/bookit";

import { SuiteCareContext } from "../context/Context";
import ServiceRequest from "./serviceRequest";

const Order = () => {
  const { suiteCareState, getClientData, closeOrderDetailsModal } =
    React.useContext(SuiteCareContext);
  const { selectedOrder, selectedMasterForOrderDetails, session } =
    suiteCareState;
  const { email } = session;
  // console.log(selectedOrder, "selectedOrder");
  const [createModal, setCreateModal] = React.useState(false);
  const [updateMoveOutModal, setUpDateModveOutModal] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState(false);
  const [popupLoader, setPopUpLoader] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [scheduleData, setScheduleData] = React.useState({
    type: "",
    date: null,
    successMessage: "",
  });

  const [moveOutDate, setmoveOutDate] = React.useState({
    type: "",
    date: null,
  });

  const [masterDetails, setMasterDetails] = React.useState("");
  const [orderDetails, setOrderDetails] = React.useState("");

  const closeModal = React.useCallback(() => {
    $(function () {
      $("#orderDetailsPopUp").modal("hide");
      $("#moveIn").removeClass("is-invalid");
      $("#type").removeClass("is-invalid");
    });
    setCreateModal(false);
    setUpDateModveOutModal(false);
    setSuccessMessage(false);
    setmoveOutDate({
      type: "",
      date: null,
    });
    setScheduleData({
      type: "",
      date: null,
      successMessage: "",
    });
    closeOrderDetailsModal();
  }, []);

  const createNewSchedule = () => {
    setErrorMsg("");
    setCreateModal(true);
    setUpDateModveOutModal(false);
  };

  const showMoveOutUpdate = () => {
    setErrorMsg("");
    setCreateModal(false);
    setUpDateModveOutModal(true);
  };

  const openServiceRequest = (selectedOrder, selectedMasterForOrderDetails) => {
    // console.log(selectedOrder, "selectedOrder", selectedMasterForOrderDetails);

    $(function () {
      $("#orderDetailsPopUp").modal().addClass("d-none");
      $(".modal-backdrop").remove();
      $("#moveIn").removeClass("is-invalid");
      $("#type").removeClass("is-invalid");
      $("#myServiceRequestModal").modal("show");
    });
    try {
      setCreateModal(false);
      setUpDateModveOutModal(false);
      setScheduleData({
        type: "",
        date: null,
        successMessage: "",
      });
      setmoveOutDate({
        type: "",
        date: null,
      });
      setSuccessMessage(false);
      closeOrderDetailsModal();
      setOrderDetails(selectedOrder);
      setMasterDetails(selectedMasterForOrderDetails);
    } catch (error) {}
  };

  const handleMoveInDate = (moveIn) => {
    console.log(moveIn);
    setScheduleData((prev) => ({ ...prev, date: moveIn }));
  };

  const handleMoveOutDate = (moveIn) => {
    console.log(moveIn);
    setmoveOutDate((prev) => ({ ...prev, date: moveIn }));
  };

  const handleTypeChange = (event) => {
    let type = event.target.value;
    setScheduleData((prev) => ({ ...prev, type: type }));
  };

  const handleMessageChange = (event) => {
    let type = event.target.value;
    setScheduleData((prev) => ({ ...prev, message: type }));
  };

  const newScheduleCreate = async () => {
    const sessionResponse = await BookitApiService.checkSession();
    // let userEmail = sessionResponse.email;

    let error = 0;
    if (scheduleData.date == null || scheduleData.date == "") {
      error++;
      $("#moveIn").addClass("is-invalid");
    } else {
      $("#moveIn").removeClass("is-invalid");
    }
    if (scheduleData.type == null || scheduleData.type == "") {
      error++;
      $("#type").addClass("is-invalid");
    } else {
      $("#type").removeClass("is-invalid");
    }
    if (error == 0) {
      setPopUpLoader(true);

      try {
        let requestObj = {
          leaseuid: selectedMasterForOrderDetails.masterUId,
          orderuid: selectedOrder.orderUId,
          typecode: scheduleData.type,
          servicereqdate: moment(scheduleData.date).format("YYYY-MM-DD"),
          message: scheduleData.message,
          createdby: email,
        };
        console.log(requestObj, "requestObjrequestObj");
        let CreateNewScheduleApiCall = await BookitApiService.CreateNewSchedule(
          requestObj
        );
        console.log(CreateNewScheduleApiCall, "CreateNewScheduleApiCall");
        if (CreateNewScheduleApiCall.error) {
          setErrorMsg(CreateNewScheduleApiCall.error);
          setPopUpLoader(false);
        } else {
          let requestData = {
            Uid: sessionResponse.ClientProfileCardDetails.ClientProfileUId,
          };
          let teamrepDetails = await BookitApiService.GetTeamData(requestData);
          let repDetails = teamrepDetails?.message?.filter(
            (team) => team.Type == "Reservation Rep"
          );
          repDetails = Array.isArray(repDetails) ? repDetails : [];
          const sendTo =
            repDetails[0]?.Email == null
              ? "ashok@suiteamerica.com"
              : repDetails[0].Email;

          // ----------------------------------------------------------------
          // sending Email confirmation
          // ----------------------------------------------------------------
          let object = {
            masterId: selectedMasterForOrderDetails.masterId,
            orderId: selectedOrder.orderId,
            serviceDate: moment(scheduleData.date).format("YYYY-MM-DD"),
            serivceType: scheduleData.type,
            mail: "ServiceRequestCreated",
            toEmail: sendTo,
            subject: `Master# ${
              selectedMasterForOrderDetails.masterId
            } - Order# ${selectedOrder.orderId} - Requested Service - ${
              scheduleData.type
            } - ${moment(scheduleData.date).format("YYYY-MM-DD")}`,
          };
          console.log(object, "object");
          await NikeService.serviceRequest(object);

          setSuccessMessage(true);
          setScheduleData((prev) => ({
            ...prev,
            successMessage: "Service request has been successfully created",
          }));
          setErrorMsg("");
          setPopUpLoader(false);
          await getClientData();
        }
      } catch (error) {
        console.log(error, "error");
        setErrorMsg(JSON.stringify(error));
        setPopUpLoader(false);
      }
    }
  };

  const upDateMoveOutDate = async () => {
    let error = 0;
    if (moveOutDate.date == null || moveOutDate.date == "") {
      error++;
      $("#moveOut").addClass("is-invalid");
    } else {
      $("#moveOut").removeClass("is-invalid");
    }

    if (error == 0) {
      setPopUpLoader(true);
      setScheduleData({
        type: "",
        date: null,
      });
      try {
        let requestObj = {
          orderid: selectedOrder.orderId,
          moveoutdate: moment(moveOutDate.date).format("MM-DD-YYYY"),
        };
        let updateMoveOutDateApiCall =
          await BookitApiService.UpdateOrderMoveout(requestObj);
        if (updateMoveOutDateApiCall.error) {
          setErrorMsg(updateMoveOutDateApiCall.error);
          setPopUpLoader(false);
        } else {
          await getClientData();
          setSuccessMessage(true);
          setScheduleData((prev) => ({
            ...prev,
            successMessage: "Successfully moveout date updated",
          }));
          setErrorMsg("");
          setPopUpLoader(false);
        }
      } catch (error) {
        setPopUpLoader(false);
      }
    }
  };

  const sendSuccessmail = async (type) => {
    try {
      setCreateModal(false);
      setUpDateModveOutModal(false);
      setScheduleData({
        type: "",
        date: null,
        successMessage: "",
      });
      setmoveOutDate({
        type: "",
        date: null,
      });
      setSuccessMessage(false);
      closeOrderDetailsModal();
      $(function () {
        $("#orderDetailsPopUp").modal("hide");
        $("#moveIn").removeClass("is-invalid");
        $("#type").removeClass("is-invalid");
      });
    } catch (error) {
      console.log(error);
    }
  };

  let maxDate = moment(selectedOrder.moveoutdate);
  let minDate = moment(selectedOrder.moveindate);
  return (
    <>
      <div
        className="modal fade savesearch"
        data-backdrop="static"
        id="orderDetailsPopUp"
        role="dialog"
      >
        <div className="modal-dialog">
          <div className="modal-content rm-border-radius">
            <div className="modal-header">
              <button
                type="button"
                onClick={closeModal}
                className="close btn"
                data-dismiss="modal"
                aria-label=""
              >
                <i className="bi bi-x"></i>
              </button>
            </div>
            {successMessage == false && (
              <div className="modal-body">
                <h3 className="mb-4">Order# ({selectedOrder.orderId})</h3>
                <form>
                  <div className="form-group">
                    <label>Unit Id</label>
                    <b>:</b>
                    <label className="main">
                      {selectedMasterForOrderDetails.masterId}
                    </label>
                  </div>
                  <div className="form-group">
                    <label>Community</label>
                    <b>:</b>
                    <label className="main">
                      {selectedMasterForOrderDetails.communityname}
                    </label>
                  </div>
                  <div className="form-group">
                    <label>Suite No</label>
                    <b>:</b>
                    <label className="main">
                      {selectedMasterForOrderDetails.suiteno}
                    </label>
                  </div>
                  <div className="form-group">
                    <label>Suite Size</label>
                    <b>:</b>
                    <label className="main">
                      {selectedMasterForOrderDetails.suitesize}
                    </label>
                  </div>

                  <div className="form-group">
                    <label>Guest Name</label>
                    <b>:</b>
                    <label className="main">
                      {(selectedOrder?.guestfirstname || "") +
                        " " +
                        (selectedOrder?.guestlastname || "") || "N/A"}
                    </label>
                  </div>
                  <div className="form-group">
                    <label>Move in Date</label>
                    <b>:</b>
                    <label className="main">
                      {moment(selectedOrder.moveindate).format("MM/DD/YYYY")}
                    </label>
                  </div>
                  <div className="form-group">
                    <label>Move out Date</label>
                    <b>:</b>
                    <label className="main">
                      {moment(selectedOrder.moveoutdate).format("MM/DD/YYYY")}
                    </label>
                  </div>
                </form>
                {selectedOrder.orderstatus != "TERMED" && (
                  <div>
                    <a
                      className="ancher-link"
                      onClick={() => createNewSchedule()}
                    >
                      Request a clean
                    </a>{" "}
                    |{" "}
                    <a
                      className="ancher-link"
                      onClick={() => showMoveOutUpdate()}
                    >
                      Update MoveOut Date
                    </a>{" "}
                    |{" "}
                    <a
                      className="ancher-link"
                      onClick={() =>
                        openServiceRequest(
                          selectedOrder,
                          selectedMasterForOrderDetails
                        )
                      }
                    >
                      Service Request
                    </a>
                  </div>
                )}

                {createModal == true && (
                  <div className="" id="createNewSchedule">
                    <h3 className="mb-1">Request a clean</h3>
                    <h4 style={{ color: "red" }}>{errorMsg}</h4>
                    <div className="">
                      <div className="form-group d-flex align-items-center">
                        <label className="">Requested Service Date</label>
                        <b>:</b>
                        <div className="caldenerWidth width100per">
                          <DatePicker
                            id="moveIn"
                            selected={scheduleData.date}
                            dateFormat="MM/DD/yyyy"
                            selectsStart
                            minDate={maxDate}
                            onChange={(date) => handleMoveInDate(date)}
                            placeholderText={"Service Request Date"}
                            onKeyDown={(e) => e.preventDefault()}
                            className="form-control width100per"
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      <div className="form-group d-flex align-items-center">
                        <label className="">Requested Type</label>
                        <b>:</b>
                        <select
                          id="type"
                          className=" form-control"
                          placeholder="Select the type"
                          value={scheduleData.type}
                          onChange={handleTypeChange}
                        >
                          <option value={""}>select the type</option>
                          <option value={"ROT"}>Roommate Clean</option>
                          <option value={"T/O"}>Full Apartment Clean</option>
                        </select>
                      </div>

                      {popupLoader ? (
                        <div
                          className="text-center"
                          style={{
                            fontSize: "20px",
                          }}
                        >
                          <i className="fa fa-spinner fa-spin" />
                          Please wait ...!
                        </div>
                      ) : (
                        <button
                          className="btn btn-dark rounded-5 mt-3 mb-4"
                          onClick={() => newScheduleCreate()}
                        >
                          Submit Request
                        </button>
                      )}
                    </div>
                  </div>
                )}
                {updateMoveOutModal == true && (
                  <div className="" id="createNewSchedule">
                    {/* {console.log(minDate, typeof minDate, "minDate-xyz")} */}
                    <h3 className="mb-1">Update MoveOut Date</h3>
                    <h4 style={{ color: "red" }}>{errorMsg}</h4>
                    <div className="">
                      <div className="form-group d-flex align-items-center">
                        <label className="">MoveOut Date</label>
                        <b>:</b>
                        <div className="caldenerWidth width100per">
                          <DatePicker
                            id="moveOut"
                            selected={
                              moveOutDate.date ? moveOutDate.date : null
                            }
                            dateFormat="MM/DD/yyyy"
                            selectsStart
                            minDate={minDate}
                            //maxDate={maxDate}
                            onChange={(date) => handleMoveOutDate(date)}
                            // showTimeSelect
                            placeholderText={"MoveOut Date"}
                            onKeyDown={(e) => e.preventDefault()}
                            className="form-control width100per"
                            autoComplete="off"
                          />
                        </div>
                      </div>

                      {popupLoader ? (
                        <div
                          className="text-center"
                          style={{
                            fontSize: "20px",
                          }}
                        >
                          <i className="fa fa-spinner fa-spin" />
                          Please wait ...!
                        </div>
                      ) : (
                        <button
                          className="btn btn-dark rounded-5 mt-3 mb-4"
                          onClick={() => upDateMoveOutDate()}
                        >
                          Update MoveOut Date
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
            {successMessage == true && (
              <div className="modal-body">
                <div className="alert alert-success">
                  {scheduleData.successMessage}
                </div>
                <button
                  className="btn btn-dark rounded-5 mt-3 mb-4"
                  onClick={() => sendSuccessmail()}
                >
                  Ok
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <div
        className="modal modal-fullscreen-xl modal fade animated tab-modal guest-Ui SAweb_modalPoup"
        id="myServiceRequestModal"
        tabIndex={-1}
        data-backdrop="static"
        data-keyboard="false"
        role="dialog"
        aria-hidden="true"
      > */}

      <ServiceRequest
        orderDetails={orderDetails}
        masterDetails={masterDetails}
      />

      {/* </div> */}
    </>
  );
};

export default Order;
