/* Importing the node modules, child components, services and controllers used 
   inside NikeHome component */
import $ from "jquery";
import MainController from "../../controller/mainController";
import SubFooter from "../../common/subFooter";
import GuestHeader from '../../common/guestHeader';
import Footer from '../../common/footer';
/* NikeHome Component initialization */
class DeanBuildingD1 extends React.Component {
  /* Initializing objects of its NikeHome class */
  constructor(props) {
    super(props);
  }
  /* It is invoked immediately before mounting occurs */
  componentWillMount() {
    MainController.getInitialInformation();
  }
  /* It is invoked to return html content */
  render() {
    return (
      <div className="corporateHousing_Updates telsa-page-view">
        <GuestHeader />

        <div className="latch_web latch_webHeader">

          <div className="container">

            <h1 className="text-center m-t-40 m-b-30 p-b-10">The Dean Arrival Instructions - Building D</h1>

            <div className="latch_web2_sec borderred-box">
              <img src="/assets/newUI-images/latchimages/Latch4-1.png" alt="" width="100%" />
              <div className="item">
                <h2> Parking & Finding Elevators</h2>
                <p>You will be using parking garage entrance located on the corner of Fayette Dr. and San Antonio
                  Road. Make a right onto Fayette Dr., where you'll see an open grass area on the right with the community
                  right behind it.
                  After the grass area, you will find the parking garage entrance.</p>
                <p>The paved concrete driveway leading into the bottom of Building D. We suggest parking on the side of the
                  paved driveway first until you have your garage clicker, which is in your suite.
                </p>
                <p>Find your way to the pedestrian entrance on the left of the parking garage. Here, you will use Latch to
                  access the building. Then, use the <span className="blue"> teal elevator </span> to access your floor. Learn how
                  to use Latch <a href="/latch" target="_blank">here</a>.
                </p>
              </div>
            </div>
            <div className="latch_web2_sec2">
              <div className="item">
                <p><i>The entry way in to the parking garage in teal.</i></p>
                <div className="borderred-box">
                  <img src="/assets/newUI-images/latchimages/Latch4-2.png" alt="" width="100%" />
                </div>
              </div>
              <div className="item">
                <p><i>The elevators that lead to the building.</i></p>
                <div className="borderred-box">
                  <img src="/assets/newUI-images/latchimages/Latch4-3.png" alt="" width="100%" />
                </div>
              </div>
            </div>

            <div className="latch_web_sec3 buildingB borderred-box m-b-30">
              <div className="item">
                <img src="/assets/newUI-images/latchimages/Latch2-4.png" alt="" width="100%" />
              </div>
              <div className="item">
                <h2>Finding Your Assigned Parking Space</h2>
                <p>Your garage clicker is located in your suite. Once you get into your suite, you will find it in the Welcome
                  packet materials.
                </p>
                <p>This garage clicker will give you access to the secured parking garage in Building D. Once back in
                  your car, continue toward the garage gate, where you will press and hold "1" or "2" on your clicker for
                  about 5 seconds. You will be on the B1 level of the garage.
                </p>
                <p>To find the B2 level, make an immediate right after entering and continue straight toward the other garage
                  gate. There will be a ramp on the left leading down to B2.</p>
              </div>
            </div>





          </div>


        </div>
      </div>
    );
  }
}
export default DeanBuildingD1;