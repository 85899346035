import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import templates from "./templates";
import BlogHeader from "../newinterface/blogHeader";
import Footer from "../newinterface/footer";
import noticeToVacateService from "../../../services/noticeToVacate";

const NoticeToVacate = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const orderId = queryParams.get("orderid");
  const orderuniqueid = queryParams.get("orderuid");
  const templateid = decodeURIComponent(queryParams.get("templateid")).replace(
    / /g,
    "+"
  );

  const [pageLoader, setPageLoader] = useState(true);
  const [bookingDetails, setBookingDetails] = useState(null);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [sucessResponse, setSucessResponse] = useState(null);

  async function fetchBookingDetails(orderId, orderuniqueid, templateid) {
    try {
      const guestDetails =
        await noticeToVacateService.noticeToVacateGuestDetails({
          orderId,
          orderuniqueid,
          templateid,
        });
      console.log(guestDetails);
      if (guestDetails.error == 0) {
        setBookingDetails({
          guestName: guestDetails.guestinfo.guestname,
          checkoutDate: guestDetails.guestinfo.moveoutdate,
          checkoutTime: guestDetails.guestinfo.moveouttime,
          templateid: guestDetails.guestinfo.templateid,
        });
        return;
      }
    } catch (error) {
      console.error("Error fetching booking details:", error);
    } finally {
      setPageLoader(false);
    }
  }

  async function moveoutHandler() {
    setButtonLoader(true);
    const response = await noticeToVacateService.moveOutRequest({
      orderuniqueid,
      orderId,
    });
    setSucessResponse(response?response:{error:1,message:"Empty Response"});
    setButtonLoader(false);
  }

  async function requestToStayHandler() {
    setButtonLoader(true);
    const response = await noticeToVacateService.requestToStay({
      orderuniqueid,
      orderId,
    });
    
    setSucessResponse(response?response:{error:1,message:"Empty Response"});
    setButtonLoader(false);
  }

  async function iamMoveoutHandler() {
    console.log("I'm moving out");
    setButtonLoader(true);
    const response = await noticeToVacateService.iamMoveout({
      orderuniqueid,
      orderId,
    });
    setSucessResponse(response?response:{error:1,message:"Empty Response"});
    setButtonLoader(false);
  }

  useEffect(() => {
    fetchBookingDetails(orderId, orderuniqueid, templateid);
  }, []);

  useEffect(() => {
    if (bookingDetails) {
      setPageLoader(false);
    }
  }, [bookingDetails]);

  useEffect(() => {
    if (!sucessResponse) return;
    if (sucessResponse.error == 0) {
      function showSuccessModal() {
        $("#successModal-1").modal("show");
      }
      showSuccessModal();
    }
    if (sucessResponse.error == 1) {
      function showFialureModal() {
        $("#failureModal-1").modal("show");
      }
      showFialureModal();
    }
  }, [sucessResponse]);

  if (pageLoader) return <div>loading...</div>;

  if (!pageLoader && !bookingDetails)
    return <div>failed to load guest details</div>;

  return (
    <>
      <div className="SAweb_updateUi">
        <BlogHeader />
        <table
          border={0}
          cellPadding={0}
          cellSpacing={0}
          className="responisveEmailTemp"
          style={{marginLeft: "auto", marginRight: "auto" }}
        >
          <tr>
            <td>
              <table width="600" align="center" cellPadding="0" cellSpacing="0">
                {bookingDetails.templateid == "71" && (
                  <templates.GuestCheckoutNotice1
                    guestName={bookingDetails.guestName}
                    checkoutDate={bookingDetails.checkoutDate}
                    checkoutTime={bookingDetails.checkoutTime}
                    moveoutHandler={moveoutHandler}
                    requestToStayHandler={requestToStayHandler}
                    buttonLoader={buttonLoader}
                  />
                )}
                {bookingDetails.templateid == "72" && (
                  <templates.GuestCheckoutNotice2
                    guestName={bookingDetails.guestName}
                    checkoutDate={bookingDetails.checkoutDate}
                    checkoutTime={bookingDetails.checkoutTime}
                    moveoutHandler={moveoutHandler}
                    requestToStayHandler={requestToStayHandler}
                    buttonLoader={buttonLoader}
                  />
                )}
                {bookingDetails.templateid == "73" && (
                  <templates.GuestCheckoutNotice3
                    guestName={bookingDetails.guestName}
                    checkoutDate={bookingDetails.checkoutDate}
                    checkoutTime={bookingDetails.checkoutTime}
                    moveoutHandler={moveoutHandler}
                    requestToStayHandler={requestToStayHandler}
                    buttonLoader={buttonLoader}
                  />
                )}
                {bookingDetails.templateid == "74" && (
                  <templates.GuestCheckoutNotice4
                  guestName={bookingDetails.guestName}
                  checkoutDate={bookingDetails.checkoutDate}
                  checkoutTime={bookingDetails.checkoutTime}
                  iamMoveoutHandler={iamMoveoutHandler}
                  buttonLoader={buttonLoader}
                />
                )}
                {bookingDetails.templateid == "75" && (
                  <templates.GuestCheckoutNotice5
                    guestName={bookingDetails.guestName}
                    checkoutDate={bookingDetails.checkoutDate}
                    checkoutTime={bookingDetails.checkoutTime}
                    moveoutHandler={moveoutHandler}
                    requestToStayHandler={requestToStayHandler}
                    buttonLoader={buttonLoader}
                  />
                )}
                {!["71", "72", "73", "74", "75"].includes(
                  bookingDetails.templateid
                ) && <tr>
                  <td className="tableDataNoFound">
                  <i class="fa fa-exclamation-triangle"></i>
                  Template not found
                  </td>
                  
                  </tr>}
              </table>
            </td>
          </tr>
        </table>
        <Footer />
        <SuccessModal
          message="Your operation was successful!"
          onClose={() => console.log("Modal closed")}
        />
        <FailureModal
        message={sucessResponse?.message}
        onClose={() => console.log("Modal closed")}
      />
      </div>
    </>
  );
};

export default NoticeToVacate;

// write a component to show a success message
const SuccessModal = ({ message, onClose }) => (
  <div
    className="modal fade"
    id="successModal-1"
    tabIndex="-1"
    role="dialog"
    aria-labelledby="successModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h3 className="modal-title" id="successModalLabel">
            Success
          </h3>
        </div>
        <div className="modal-body">
          <div className="alert alert-success" role="alert">
            {message}
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-primary"
            data-dismiss="modal"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
);

const FailureModal = ({ message, onClose }) => (
  <div
    className="modal fade"
    id="failureModal-1"
    tabIndex="-1"
    role="dialog"
    aria-labelledby="failureModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h3 className="modal-title text-danger" id="failureModalLabel">
            Error
          </h3>
        </div>
        <div className="modal-body">
          <div className="alert alert-danger" role="alert">
            {message}
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
);
