/* Importing the node modules, child components, services and controllers used
   inside TravelBookingAdmin component */
import $ from "jquery";
import AppController from "../../controller/appController";
import { Link } from 'react-router-dom';
import TravelBookingHeader from '../../common/travelBookingHeader';
import TravelBookingApiService from '../../../services/travelBookingApiService';
import TravelBookingFooter from '../../common/travelBookingFooter';
import FlightInfoModal from './controllers/flightInfoModal';
import { setTimeout } from 'timers';
import ReactTable from "react-table";
import approx from "approximate-number";
import moment from 'moment'
import FilghtDetailsSuccessModal from './model/filghtDetailsSuccessModal'
import CommunityAnalysis from "../ceApp/communityAnalysis";
/* TravelBookingAdmin Component initialization */
class TravelBookingAdmin extends React.Component {
    /* Initializing objects of its TravelBookingAdmin class */
    constructor(props) {
        super(props)
        this.state = {
            Staying: 0,
            booked: 0,
            monthlyCount: 0,
            weeklyCount: 0,
            stayingData: [],
            bookedData: [],
            pastData: [],
            isLoading: false,
            loadGraphs: false,
            avgLengthofStay: 0,
            totalSpend: 0,
            savings: 0,
            totalordersGraph: [],
            moveIndate: '',
            moveOutdate: '',
            randNumber: -1,
            Flight: '',
            ArrivalAirport: '',
            ArrivalDate: moment(),
            Airline: '',
            notes: '',
            color: '',
            message: '',
            modalLoader: false,
            checkIn: moment(),
            checkOut: moment(),
            firstName: '',
            lastName: '',
            phoneNo: '',
            emailId: '',
            community: '',
            OrderUId: '',
            TableId: '',
            communityList: [],
            totalOrdersLoading: false,
            analysisModalLoading: false,
            communityAnalysisCountInfo: [],
            communityAnalysisName: '',
        }
        this.setValue = this.setValue.bind(this);
        this.flightInfoSubmit = this.flightInfoSubmit.bind(this);
        this.checkInSelection = this.checkInSelection.bind(this);
        this.flightModal = this.flightModal.bind(this);
        this.communityAnalysisCount = this.communityAnalysisCount.bind(this)

    }
    /* It is invoked immediately before mounting occurs */
    async componentWillMount() {

        const _this = this
        $('html, body').animate({ scrollTop: 0 });
        AppController.stickyTitles()
        this.OrdersList(moment().startOf('year').format('MM-DD-YYYY'), moment().endOf('year').format('MM-DD-YYYY'), 1)

    }
    async communityAnalysisCount(data, type) {
        await this.setState({ analysisModalLoading: true, communityAnalysisCountInfo: [] })
        let newArray = [];
        for (let i = 0; i < data.length; i++) {
            newArray.push({
                orderno: data[i].orderid,
                GuestFirstName: data[i].guestfirstname,
                MoveInDate: data[i].moveindate,
                MoveOutDate: data[i].moveoutdate,
                SuiteNo: data[i].suiteNumber,
            });
        }
        await this.setState({ communityAnalysisCountInfo: newArray, analysisModalLoading: false, communityAnalysisName: data[0].community, communityAnalysisType: type })
        $('.community_Analysis_Modal option:last-child').text('All');
    }
    calendar(randNumber) {
        const _this = this
        jQuery(function () {
            var start = moment().startOf('year');
            var end = moment().endOf('year');

            function cb(start, end) {
                jQuery('#reportrange' + randNumber + ' span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
            }
            async function myFun(start, end) {
                jQuery('#reportrange' + randNumber + ' span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
                await _this.OrdersList(start.format('MM-DD-YYYY'), end.format('MM-DD-YYYY'), -1)
            }

            jQuery('#reportrange' + randNumber).daterangepicker({
                startDate: start,
                endDate: end,
                ranges: {
                    'Today': [moment(), moment()],
                    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],

                    'This Year': [moment().startOf('year'), moment().endOf('year')]
                }
            }, myFun);

            cb(start, end);

        })
    }
    async OrdersList(moveIndate, moveOutdate, type) {

        const randNumber = type == 1 ? Math.floor(Math.random() * 1000000) + 1 : this.state.randNumber
        await this.setState({ isLoading: true, loadGraphs: true, randNumber, totalOrdersLoading: true, });
        type == 1 ? await this.calendar(randNumber) : ''

        let status = {
            CheckInDate: moveIndate,
            CheckOutDate: moveOutdate,
            BookingConfirmed: 'yes'
        }
        var clientId = 16919
        const requestObj = { clientId: clientId, moveindate: moveIndate, moveoutdate: moveOutdate }
        let orderListResult = await TravelBookingApiService.GetOrdersByClient(requestObj)
        orderListResult = _.orderBy(orderListResult, ['orderid'], ['desc']);
        const stayingData = _.filter(orderListResult, function (o) { return o.statuscode == "ACTIVE"; });
        const bookedData = _.filter(orderListResult, function (o) { return (o.statuscode == "PENDING" || o.statuscode == "VOID"); });
        const pastData = _.filter(orderListResult, function (o) { return o.statuscode == "TERMED"; });
        const communitydata = _.filter(orderListResult, function (o) { return o.statuscode != "CANCEL"; });
        let result = _.chain(communitydata).groupBy("community").map(function (v, i) {

            return {
                Community: i,
                Pending: _.filter(v, function (o) { return (o.statuscode == 'PENDING' || o.statuscode == "VOID"); }),
                Active: _.filter(v, function (o) { return o.statuscode == 'ACTIVE'; }),
                Termed: _.filter(v, function (o) { return o.statuscode == 'TERMED'; }),
                City: _.get(_.find(v, 'city'), 'city'),
            }
        }).value();
        result = _.orderBy(result, ['Community'], ['asc']);
        const communityAnalysis = _.filter(result, function (o) { return o.Community != 'null' });
        const xluploadData = await TravelBookingApiService.GetTravelBookingDetails(status)

        const datesLength = _.sumBy(xluploadData.message, function (o) {
            return Number(o.Nights);
        })

        const rateLenght = _.sumBy(xluploadData.message, function (o) {
            return (Number(o.Nights) * o.RoomRate);
        })
        const concatRecords = _.concat(this.state.totalordersGraph, stayingData, bookedData, pastData);

        const DataLength = _.sumBy(concatRecords, function (o) {

            return (o != undefined && o.daysofstay != undefined && o.daysofstay != null) ? o.daysofstay : 0;
        })
        const DataRate = _.sumBy(concatRecords, function (o) {

            return (o != undefined && o.daysofstay != undefined && o.daysofstay != null) ? (o.daysofstay * o.dailyrate) : 0;;
        })

        var groupbycity = _.groupBy(_.orderBy(concatRecords, ['city'], ['asc']), 'city');
        var labels = []
        var average = []
        var color = []
        var i = 0;
        var groupbycityLen = Object
            .keys(groupbycity)
            .length
        var colorVal = [
            '#1fa2ff',
            '#48962a',
            '#F09819',
            '#EB3349',
            '#7f8c8d',
            '#8e44ad',
            '#1fa2ff',
            '#48962a',
            '#F09819',
            '#EB3349',
            '#7f8c8d',
            '#8e44ad',
            '#1fa2ff',
            '#48962a',
            '#F09819',
            '#EB3349',
            '#7f8c8d',
            '#8e44ad'
        ]
        _.forEach(groupbycity, function (row, index) {

            const labelindex = index == 'null'
                ? 'Unknow City'
                : index
            labels.push(labelindex);
            var sumBy = _.sumBy(row, function (o) {
                return o != undefined ? o.dailyrate : 0;
            })
            average.push((sumBy / row.length).toFixed(2))
            color.push(colorVal[i])
            i++;

        })

        var stayData = stayingData != undefined ? stayingData : []
        var bookData = bookedData != undefined ? bookedData : []
        var historyData = pastData != undefined ? pastData : []
        var avgLengthofStay = ((DataLength) / (concatRecords.length)) > 0 ? ((DataLength) / (concatRecords.length)).toFixed(1) : 0
        var totalSpend = approx(DataRate, { prefix: '$', capital: true, decimal: ',' })
        var Savings = approx(Math.abs(((datesLength * 220) - (rateLenght + (rateLenght * 0.12)))), { prefix: '$', capital: true, decimal: ',' })
        this.setState({
            totalOrdersLoading: false,
            communityList: communityAnalysis,
            stayingData: stayData,
            bookedData: bookData,
            pastData: historyData,
            isLoading: false,
            avgLengthofStay: isNaN(avgLengthofStay) ? 0 : approx(avgLengthofStay),
            totalSpend: totalSpend.includes("M") ? approx(DataRate, { prefix: '$', capital: true, }) : totalSpend,
            savings: Savings.includes("K") ? Savings : approx(Math.abs(((datesLength * 220) - (rateLenght + (rateLenght * 0.12)))), { prefix: '$', capital: true })
        })
        this.setState({ loadGraphs: false })
        //this.loadGraphs(stayData, bookData, historyData, labels, average, colorVal, randNumber);
    }

    renderShowsTotal(start, to, total) {
        return (
            <p>booked Showing {start} to {to} of {total} entries</p>
        );
    }
    dateFormat(type, cell, row) {
        return <div>{moment(row[type]).format('L')}</div>
    }
    /* It is invoked to return html content */
    priceFormatter(cell, row) {
        if (cell != null) {
            return '<i class="glyphicon glyphicon-usd"></i> ' + cell;
        }
    }
    async flightModal(row) {
        await this.setState({ checkIn: moment(row.moveindate), checkOut: moment(row.moveoutdate), firstName: row.guestfirstname, lastName: row.guestlastname, phoneNo: row.guestphonenumber, emailId: row.guestemail, community: row.community, Flight: (row.flight == null ? '' : row.flight), ArrivalAirport: (row.arrivalairport == null ? '' : row.arrivalairport), ArrivalDate: ((row.arrivaldate != null && row.arrivaldate != '') ? moment(row.arrivaldate) : moment(row.moveindate)), Airline: (row.airline == null ? '' : row.airline), notes: (row.tripnotes == null ? '' : row.tripnotes), OrderUId: row.orderuid, TableId: row.orderid, message: '', color: '' });
    }
    async flightInfoSubmit() {
        const _this = this;
        const requestObj = {
            OrderUId: this.state.OrderUId,
            Notes: this.state.notes,
            Flight: this.state.Flight,
            ArrivalAirport: this.state.ArrivalAirport,
            ArrivalDate: this.state.ArrivalDate.format('YYYY-MM-DD HH:mm'),
            Airline: this.state.Airline,
            ChangedBy: Base64.decode(localStorage.getItem('travel-email'))
        }
        let tripInfoResult = await TravelBookingApiService.SaveTripInfo(requestObj);
        if (tripInfoResult.error == 0) {
            jQuery(function ($) {
                $('#holdModal').modal('hide');
                $('#flight-details').modal('show');
                setTimeout(async function () {
                    $('#flight-details').modal('hide');
                    await _this.setState({ notes: '', Flight: '', ArrivalAirport: '', ArrivalDate: moment(), Airline: '' });
                    await _this.OrdersList(moment().startOf('year').format('MM-DD-YYYY'), moment().endOf('year').format('MM-DD-YYYY'), 1);
                }.bind(this), 5000);
            }.bind(this));
        } else {
            this.setState({ message: 'Server side failure', color: 'red' });
        }
    }
    setValue(field, e) {
        var object = {};
        object[field] = e.target.value;
        this.setState(object);
    }
    async checkInSelection(date) {
        await this.setState({ ArrivalDate: moment(date) });
    }
    async componentDidMount() {
        Loadjs('/assets/js/Chart.min.js')
        $(document).ready(function () {
            jQuery(function ($) {
                $("#phone2").intlTelInput();
            });
        });
    }

    render() {
        const filterMethod = (filter, row) => {
            const id = filter.pivotId || filter.id;
            if (row[id] !== null && typeof row[id] === "string") {
                return (row[id] !== undefined
                    ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
                    : true);
            } else if (row[id] !== null && typeof row[id] === "number") {
                const str = String(row[id]);
                return (str !== undefined
                    ? String(str.toLowerCase()).includes(filter.value.toLowerCase())
                    : true);
            }
        }
        return (
            <div>
                <TravelBookingHeader />
                <div className="travel_header-ht"></div>
                <div className="travel_header-ht-w"></div>

                <div className="container">

                    <div className="row mrg-btm-50">
                        <div className="col-md-6 col-sm-6">
                            <div><Link to="/travel-booking-upload">
                                <button type="submit" className="btn btn-primary font-xs-13">Excel Upload</button>
                            </Link>
                                <Link to="/travel-booking" style={{ marginLeft: '10px' }}>
                                    <button type="submit" className="btn btn-primary font-xs-13">Travel Booking</button>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6">


                            <div id={"reportrange" + this.state.randNumber} className="pull-right" style={{ background: "#fff", cursor: "pointer", padding: "5px 10px", border: "1px solid #ccc", marginTop: "15px", textAlign: "center" }}>
                                <i className="fa fa-calendar"></i>&nbsp;
                                <span></span> <i className="fa fa-caret-down"></i>
                            </div></div>
                    </div>

                    <div className="row mrg-btm-30">
                        <div className="col-md-4 col-sm-4">
                            <div className="widget-wrap bg-widget-blue">
                                <h3>Current Reservations#</h3>
                                <h1>{this.state.isLoading
                                    ? <i className="fa fa-spinner fa-spin" />
                                    : this.state.bookedData.length}</h1>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-4">
                            <div className="widget-wrap bg-widget-green">
                                <h3>Active#</h3>
                                <h1>{this.state.isLoading
                                    ? <i className="fa fa-spinner fa-spin" />
                                    : this.state.stayingData.length}</h1>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-4">
                            <div className="widget-wrap bg-widget-gray">
                                <h3>Avg length of stay</h3>
                                <h1>{this.state.isLoading
                                    ? <i className="fa fa-spinner fa-spin" />
                                    : this.state.avgLengthofStay}</h1>
                            </div>
                        </div>

                        <div className="col-md-4 col-md-offset-2 col-sm-4">
                            <div className="widget-wrap bg-widget-yellow2">
                                <h3>Total Spend</h3>
                                <h1>{this.state.isLoading
                                    ? <i className="fa fa-spinner fa-spin" />
                                    : this
                                        .state
                                        .totalSpend
                                        .toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD'
                                        })}</h1>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-4">
                            <div className="widget-wrap bg-widget-red">
                                <h3>Savings</h3>
                                <h1>{this.state.isLoading
                                    ? <i className="fa fa-spinner fa-spin" />
                                    : this
                                        .state
                                        .savings
                                        .toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD'
                                        })}</h1>
                            </div>
                        </div>

                    </div>

                    <div className="row">

                        <CommunityAnalysis communityAnalysisCount={this.communityAnalysisCount} {...this.state} />
                        {/* {this.state.loadGraphs
                               ? <div
                                       className='text-center'
                                       style={{
                                       fontSize: '20px'
                                   }}><i className="fa fa-spinner fa-spin"/>
                                       Please wait ...!</div>
                               : <div>
                                   <div className="col-md-8 col-sm-12" id="travelgraph">
                                       <canvas id="bar-chart" height="400"></canvas>
                                   </div>
                                   <div className="col-md-4 col-sm-12 text-center">
                                       {(this.state.bookedData.length>0 || this.state.stayingData.length>0 || this.state.pastData.length>0)? <div id={"graph"+this.state.randNumber}></div> : ""}
                                   </div>
                               </div>} */}


                        <div className="clearfix"></div>
                        <div className="community-tabs mrg-top-70">
                            <ul className="nav nav-tabs" role="tablist">
                                <li role="presentation" className="active">
                                    <a href="#one" aria-controls="home" role="tab" data-toggle="tab">Current Reservations</a>
                                </li>
                                <li role="presentation">
                                    <a href="#two" aria-controls="profile" role="tab" data-toggle="tab">Active</a>
                                </li>
                                <li role="presentation">
                                    <a href="#three" aria-controls="profile" role="tab" data-toggle="tab">History</a>
                                </li>
                            </ul>
                            <div className="tab-content travel-booking-slider">

                                <div role="tabpanel" className="tab-pane active" id="one">
                                    <div className="clearfix"></div>
                                    <div className="table-responsive">
                                        {(!this.state.loadGraphs && this.state.bookedData.length > 0)
                                            ? <ReactTable
                                                multiSort={true}
                                                defaultFilterMethod={filterMethod}
                                                data={this.state.bookedData}
                                                columns={[

                                                    {
                                                        Header: "Order#",
                                                        id: 'orderid',
                                                        maxWidth: 150,
                                                        accessor: row => (row.orderid).toString(),
                                                    },
                                                    {
                                                        Header: "Name",
                                                        accessor: "guestname",
                                                        width: 150
                                                    },
                                                    {
                                                        Header: "MoveIn Date",
                                                        id: 'inDate',
                                                        accessor: row => moment(row.moveindate).format('L'),
                                                        sortMethod: (a, b) => {
                                                            return new Date(a) - new Date(b);
                                                        },

                                                    },
                                                    {
                                                        Header: "MoveOut Date",
                                                        id: 'outDate',
                                                        accessor: row => moment(row.moveoutdate).format('L'),
                                                        sortMethod: (a, b) => {
                                                            return new Date(a) - new Date(b);
                                                        },

                                                    },
                                                    {
                                                        Header: "Community Name",
                                                        accessor: "community",

                                                    },
                                                    {
                                                        Header: "# Of Stay",
                                                        id: 'daysofstay',
                                                        accessor: row => row.daysofstay != null ? (row.daysofstay) : '',

                                                    },
                                                    {
                                                        Header: "Daily Rate",
                                                        id: 'dailyrate',
                                                        accessor: row => row.dailyrate != null ? (row.dailyrate).toString() : '',

                                                    },
                                                    {
                                                        Header: "City",
                                                        accessor: 'city',

                                                    },
                                                    {
                                                        Header: "Flight Info",
                                                        style: { 'textAlign': 'center' },
                                                        id: 'FlightInfo',
                                                        accessor: (row, index) => (
                                                            <a href="#" data-toggle="modal" data-target="#holdModal"><i key={index} className={((row.flight != null && row.flight != '') &&
                                                                (row.airline != null && row.airline != '') &&
                                                                (row.arrivaldate != null && row.arrivaldate != '') &&
                                                                (row.arrivalairport != null && row.arrivalairport != '')) ? "fa fa-plane fa-w-14 fa-7x color-green" : "fa fa-plane fa-w-14 fa-7x color-red"}
                                                                onClick={() => this.flightModal(row)}
                                                                style={{ fontSize: '20px', cursor: 'pointer' }}></i></a>
                                                        )

                                                    },
                                                ]}
                                                defaultPageSize={10}
                                                sortable={true}

                                                className="-striped -highlight"
                                                filterable={true}
                                            />


                                            : this.state.isLoading
                                                ? <div
                                                    className='text-center'
                                                    style={{
                                                        fontSize: '20px'
                                                    }}><i className="fa fa-spinner fa-spin" />
                                                    Please wait ...!</div>
                                                : <div
                                                    className='text-center'
                                                    style={{
                                                        color: 'red'
                                                    }}>No Results Found</div>}
                                    </div>
                                </div>
                                <div role="tabpanel" className="tab-pane " id="two">
                                    <div className="clearfix"></div>
                                    <div className="table-responsive">
                                        {(!this.state.loadGraphs && this.state.stayingData.length > 0)
                                            ? <ReactTable
                                                multiSort={true}
                                                defaultFilterMethod={filterMethod}
                                                data={this.state.stayingData}
                                                columns={[

                                                    {
                                                        Header: "Order#",
                                                        id: 'orderid',
                                                        maxWidth: 150,
                                                        accessor: row => (row.orderid).toString(),
                                                    },
                                                    {
                                                        Header: "Name",
                                                        accessor: "guestname",
                                                        width: 150
                                                    },
                                                    {
                                                        Header: "MoveIn Date",
                                                        id: 'inDate',
                                                        accessor: row => moment(row.moveindate).format('L'),
                                                        sortMethod: (a, b) => {
                                                            return new Date(a) - new Date(b);
                                                        },

                                                    },
                                                    {
                                                        Header: "MoveOut Date",
                                                        id: 'outDate',
                                                        accessor: row => moment(row.moveoutdate).format('L'),
                                                        sortMethod: (a, b) => {
                                                            return new Date(a) - new Date(b);
                                                        },

                                                    },
                                                    {
                                                        Header: "Community Name",
                                                        accessor: "community",

                                                    },
                                                    {
                                                        Header: "# Of Stay",
                                                        id: 'daysofstay',
                                                        accessor: row => row.daysofstay != null ? (row.daysofstay) : '',

                                                    },
                                                    {
                                                        Header: "Daily Rate",
                                                        id: 'dailyrate',
                                                        accessor: row => row.dailyrate != null ? (row.dailyrate).toString() : '',

                                                    },
                                                    {
                                                        Header: "City",
                                                        accessor: 'city',

                                                    },
                                                ]}
                                                defaultPageSize={10}
                                                sortable={true}

                                                className="-striped -highlight"
                                                filterable={true}
                                            />

                                            : this.state.isLoading
                                                ? <div
                                                    className='text-center'
                                                    style={{
                                                        color: 'red'
                                                    }}><i className="fa fa-spinner fa-spin" />
                                                    Please wait ...!</div>
                                                : <div
                                                    className='text-center'
                                                    style={{
                                                        color: 'red'
                                                    }}>No Results Found</div>}
                                    </div>
                                </div>
                                <div role="tabpanel" className="tab-pane" id="three">
                                    <div className="clearfix"></div>
                                    <div className="table-responsive">
                                        {(!this.state.loadGraphs && this.state.pastData.length > 0)
                                            ? <ReactTable
                                                multiSort={true}
                                                defaultFilterMethod={filterMethod}
                                                data={this.state.pastData}
                                                columns={[

                                                    {
                                                        Header: "Order#",
                                                        id: 'orderid',
                                                        maxWidth: 150,
                                                        accessor: row => (row.orderid).toString(),
                                                    },
                                                    {
                                                        Header: "Name",
                                                        accessor: "guestname",
                                                        width: 150
                                                    },
                                                    {
                                                        Header: "MoveIn Date",
                                                        id: 'inDate',
                                                        accessor: row => moment(row.moveindate).format('L'),
                                                        sortMethod: (a, b) => {
                                                            return new Date(a) - new Date(b);
                                                        },

                                                    },
                                                    {
                                                        Header: "MoveOut Date",
                                                        id: 'outDate',
                                                        accessor: row => moment(row.moveoutdate).format('L'),
                                                        sortMethod: (a, b) => {
                                                            return new Date(a) - new Date(b);
                                                        },

                                                    },
                                                    {
                                                        Header: "Community Name",
                                                        accessor: "community",

                                                    },
                                                    {
                                                        Header: "# Of Stay",
                                                        id: 'daysofstay',
                                                        accessor: row => row.daysofstay != null ? (row.daysofstay) : '',

                                                    },
                                                    {
                                                        Header: "Daily Rate",
                                                        id: 'dailyrate',
                                                        accessor: row => row.dailyrate != null ? (row.dailyrate).toString() : '',

                                                    },
                                                    {
                                                        Header: "City",
                                                        accessor: 'city',

                                                    },
                                                ]}
                                                defaultPageSize={10}
                                                sortable={true}

                                                className="-striped -highlight"
                                                filterable={true}
                                            />
                                            : this.state.isLoading
                                                ? <div
                                                    className='text-center'
                                                    style={{
                                                        color: 'red'
                                                    }}><i className="fa fa-spinner fa-spin" />
                                                    Please wait ...!</div>
                                                : <div
                                                    className='text-center'
                                                    style={{
                                                        color: 'red'
                                                    }}>No Results Found</div>}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                <FlightInfoModal {...this.state} flightInfoSubmit={this.flightInfoSubmit} setValue={this.setValue} checkInSelection={this.checkInSelection} />
                <TravelBookingFooter />
                <FilghtDetailsSuccessModal />
            </div>
        );
    }
}
export default TravelBookingAdmin;