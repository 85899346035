/* Importing the node modules, child components, services and controllers used 
   inside adobeLocations component */    
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import AdobeHeader from "./common/adobeHeader";
   import FAQ from "./common/faq";
   import Footer from '../../common/footer';
   import Location from "../location/locations";
   /* adobeLocations Component initialization */
   class AdobeLocations extends React.Component {
       /* Initializing objects of its adobeLocations class */
       constructor(props) {
           super(props)
           this.state={
               companyname:'Adobe'
           }
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
           MainController.getInitialInformation();
        }
       /* It is invoked to return html content */
       render() {
        let img='https://suiteam-website.s3-us-west-1.amazonaws.com/landing-images/Adobe/Adobe_building.jpg '
           return (
            <div className="corporateHousing_Updates">
            <header className="codeconveyHeader cc_image_text_main corporate-h">
                <img className="img-responsive" src={img} width="100%" alt=""  />
                <div className="wow fadeInUp img-on-text top30 width600 text-center left50 img-on-text-center" data-wow-delay="0.5s">
                    <h1>Locations</h1>
                    <p>Furnished and unfurnished temporary
                        corporate housing apartments throughout
                        the continental United States.
                    </p>
                </div>
            </header>
            <AdobeHeader />
            <Location companyName={this.state.companyname} props={this.props}/>
            <div className="container-fluid bg-grey">
                <div className="container">
                    <FAQ />
                </div>
            </div>
            <SubFooter />
        <Footer/>
        </div>   
           );
       }
   }
   export default AdobeLocations;