

import GuestServicesapi from '../../../services/guestServicesapi';
import $ from "jquery";
import moment from "moment";
import publicIp from 'public-ip';
var platform = require('platform');

class Docusign extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            result: true,
            ContractContent: '',
            OrderUId: '',
            succmessage: '',
            IsSignedComplete: false,
            IsSignedCompleteMessage: '',
            trimmedDataURL: null,
            sigPad: {},
            errmessage: ''
        }
    }

    async componentDidMount() {
        var ipaddress = await publicIp.v4();
        const queryParams = new URLSearchParams(window.location.search);
        const id = queryParams.get('uid');
        let Request = { OrderUId: id }
        const GetContractDetails = await GuestServicesapi.GetContractDetails(Request)
        if (GetContractDetails.message) {
            await this.setState({ result: false })
        } else {
            await this.setState({
                result: true,
                OrderUId: GetContractDetails.OrderUId,
                ContractContent: GetContractDetails.ContractContent,
                IsSignedComplete: GetContractDetails.IsSignedComplete,
                IsSignedCompleteMessage: GetContractDetails.Message,
                errmessage: '',
                succmessage: ''
            })
            $("#txtGuestSignedDate").attr("placeholder", 'mm/dd/YYYY');
            $("#txtGuestSignedDate").attr("type", 'text');
            let calObj = {
                locale: {
                    format: 'MM/DD/YYYY'
                },
                minDate: moment(),
                autoUpdateInput: false,
                singleDatePicker: true,
                maxDate: moment().add(3, 'days')
            }
            jQuery('input[name="txtGuestSignedDate"], #txtGuestSignedDate')
                .daterangepicker(calObj, function (start, label) {
                    
                    $('#txtGuestSignedDate').val(moment(start).format('MM/DD/YYYY'));
                });
            
            $('#txtGuestSignedDate').parent().append("<button  id='btnHistory'>Submit</button>");
        }
        $('#chkAcceptTerms').click(function () {
            if ($('#chkAcceptTerms').is(":checked")) {
                $("#chkAcceptTerms").removeClass('docusign_validation');
            } else {
                $("#chkAcceptTerms").addClass('docusign_validation');
            }
        });
        $('#txtGuestName').keyup(function (event) {
            let text = $(this).val();
            if (text == '') {
                $("#chkAcceptTerms").addClass('docusign_validation');
            } else {
                $("#txtGuestName").removeClass('docusign_validation');
            }
        })

        $(document).on("click", "#btnHistory", async () => {
            var ContractSignedDate = moment(new Date($('#txtGuestSignedDate').val())).format("YYYY/MM/DD");
            var textvalue = document.getElementById("txtGuestName").value;
            var inputdate = moment(new Date($('#txtGuestSignedDate').val())).format("YYYY-MM-DD");
            let selectedate = moment(new Date($('#txtGuestSignedDate').val())).format("YYYY-MM-DD")
            let todaydate = moment().format('YYYY-MM-DD');
            // let futuredate = moment("3014-02-03").format('YYYY-MM-DD')
            let gettingyear = new Date($('#txtGuestSignedDate').val())
            let gettingyeartwo = new Date(gettingyear).getFullYear()
            let checkingdatetyping = (gettingyeartwo).toString().length > 4
            let error = 0
            if ($('#chkAcceptTerms').is(":checked")) {
                $("#chkAcceptTerms").removeClass('docusign_validation');
                $('#chkAcceptTerms').attr('checked', true);
            } else {
                $("#chkAcceptTerms").addClass('docusign_validation');
                error++;
            }
            if (textvalue == "") {
                $("#txtGuestName").addClass('docusign_validation');
                error++;
            } else {
                $("#txtGuestName").removeClass('docusign_validation');
                $("#txtGuestName").attr("value", textvalue)
            }
            if (todaydate > selectedate || selectedate.includes("Invalid") || checkingdatetyping) {
                $("#txtGuestSignedDate").addClass('docusign_validation');
                error++;
            } else if (selectedate >= todaydate) {
                $("#txtGuestSignedDate").removeClass('docusign_validation');
            }
            if (error == 0) {
                $('#btnHistory').remove();
                let Requestobj = {
                    ContractSignedContent: this.state.ContractContent.replace('id=\"chkAcceptTerms\"', 'id=\"chkAcceptTerms\" checked=\"checked\"').replace('id=\"txtGuestName\"', 'id=\"txtGuestName\" value=\"' + textvalue + '\"').replace('id="txtGuestSignedDate"', 'id="txtGuestSignedDate" value=\"' + inputdate + '\"'),
                    ContractSignedBy: textvalue,
                    ContractSignedDate: ContractSignedDate,
                    ClientIPAddress: ipaddress,
                    ClientOtherInfo: JSON.stringify(platform),
                    OrderUId: this.state.OrderUId
                }
                const SaveContractDetails = await GuestServicesapi.SaveContractDetails(Requestobj)
                if (SaveContractDetails.error == 1) {
                    await this.setState({ succmessage: '', errmessage: SaveContractDetails.message })
                } else {
                    await this.setState({ errmessage: '', succmessage: SaveContractDetails.message })
                }
            }
        });
    }
    createMarkup() {
        return { __html: this.state.ContractContent };
    }

    render() {
        return (
            <div>
                {!this.state.IsSignedComplete ? <div>
                    {(this.state.succmessage == '' && this.state.errmessage == '' && this.state.result) && <div >
                        <div id="dynamicHtml" dangerouslySetInnerHTML={this.createMarkup()}>
                        </div>

                    </div>}

                    {(this.state.succmessage == '' && !this.state.result) &&
                        <div className='messageViewPage'>
                            <h2 className='wrongEntrymessage'>
                                <i className="bi bi-exclamation-triangle"></i>
                                Some thing Went wrong!</h2>
                        </div>}
                    {this.state.errmessage != '' &&
                        <div className='messageViewPage'>
                            <h2 className='wrongEntrymessage'>
                                <i className="bi bi-exclamation-triangle"></i>
                                {this.state.errmessage}</h2>
                        </div>}
                    {this.state.succmessage != '' &&
                        <div className='messageViewPage'>
                            <h2 className='succmessage'>
                                <i className="bi bi-check-circle"></i>
                                {this.state.succmessage}</h2>
                        </div>}
                </div> : <div className='messageViewPage'>
                    <h2 className='wrongEntrymessage'>
                        <i className="bi bi-exclamation-triangle"></i>
                        {this.state.IsSignedCompleteMessage}</h2>
                </div>}
            </div>

        );
    }
}
export default Docusign;