/* Importing the node modules, child components, services and controllers used 
   inside AutoConfirmMain component */
import $ from "jquery";
import GuestHeader from "../../common/guestHeader";
import Footer from "../../common/footer";
import AmenitesList from "./amenitesList";
import MainController from "../../controller/mainController";
import AppController from "../../controller/appController";
import WalkScore from "./walkscore";
import LocationService from "../../../services/locationService";
import QuoteOrderService from "../../../services/quoteOrderService";
import Communitys from "./nikecommunity"
import Loader from "../newinterface/guestPortal/loader";
import ErrorMessage from "./errorMessage";
import { confirmAlert } from "react-confirm-alert";
import SuccessModal from "../../common/successModal";

import QuestionsModal from "./questionsModal";
import ViewWeb from "./viewWeb";

/* AutoConfirmMain Component initialization */
class AutoConfirmMains extends React.Component {
    /* Initializing objects of its AutoConfirmMain class */
    constructor(props) {
        super(props);
        /* AutoConfirmMain Component State variables Initialization */
        this.state = {
            communityAmenities: [],
            appartmentAmenities: [],
            walkScoreDetails: [],
            walkscoreLoading: false,
            communitysList: [],
            LinkMasters: [],
            error: false,
            Question: "",
            QuestionInCommunity: "",
            FormErrorMessage: '',
            FormLoader: false,
            modalOpen: false,
            errorMessage: '',
            web3d: '',
            confirmData: '',
            valueindex: 0,
            cfmid: props.cfmid

        };
        // this.loadMore = this.loadMore.bind(this)
        this.bookNow = this.bookNow.bind(this);
        this.setValue = this.setValue.bind(this);
        this.SendQuestion = this.SendQuestion.bind(this);
        this.clear = this.clear.bind(this);
        this.modalPopUp = this.modalPopUp.bind(this);
        this.moreInfo = this.moreInfo.bind(this);
        this.webView = this.webView.bind(this);
    }
    /* It is invoked immediately before mounting occurs */
    componentDidMount() {
        const data1 = this.state.data1;
        //this.walkScore(data1);
        this.LinkMastersByOrderUId();
        //this.getCommunityByName();

    }
    async webView(data) {
        await this.setState({ web3d: data.web3d });
        jQuery(function ($) {
            $('#3dView').modal('show');
        }.bind(this));

    }
    moreInfo(data) {
        data.userUrl = _.toLower(this.state.cfmid);
        localStorage.setItem('moreInfo', JSON.stringify(data));
        location.href = '/more-info';
    }
    componentWillMount() {
        MainController.getInitialInformation();
    }
    //Get Link Masters By OrderUid
    async LinkMastersByOrderUId() {
        const req = { orderUId: _.toLower(this.state.cfmid) };
        const LinkMasters = await LocationService.LinkMastersByOrderUId(req);
        //console.log(LinkMasters,"LinkMasters");

        this.setState({ LinkMasters: LinkMasters });
        if (LinkMasters.error == "0" && LinkMasters.message.length > 0) {
            let showTrueCount = 0;
            for (var k = 0; k < LinkMasters.message.length; k++) {
                if (LinkMasters.message[k].ShowOnlineQuote != null & LinkMasters.message[k].ShowOnlineQuote == true) {
                    showTrueCount++;
                }
            }
            //console.log(showTrueCount,"showTrueCount");

            if (showTrueCount > 0) {
                for (var i = 0; i < LinkMasters.message.length; i++) {
                    if (LinkMasters.message[i].ShowOnlineQuote != null & LinkMasters.message[i].ShowOnlineQuote == true) {
                        let req = {
                            communityUId: LinkMasters.message[i].CommunityUId,
                            imageType: "banner"
                        };
                        await this.getCommunityByName(req, LinkMasters.message[i]);
                    }

                }
            } else {
                this.setState({ walkscoreLoading: true, error: true, errorMessage: "Sorry! There are no available communities at this moment. Please check back later. Contact relocation@suiteamerica.com for any assistance." });
            }

        } else if (LinkMasters.error == "0" && LinkMasters.message.length == 0) {
            this.setState({ walkscoreLoading: true, error: true, errorMessage: 'Options not available at the Moment. Please check back later' });
        } else if (LinkMasters.error == "1") {
            this.setState({ walkscoreLoading: true, error: true, errorMessage: 'Quote is Expired. Please contact reservations@suiteamerica.com for any support' });
        }
    }
    // Communitys List By Community Name
    async getCommunityByName(req, linkMaster) {
        const { communitysList } = this.state;
        const details = await LocationService.getCommunityInfoByUid(req);
        if (details != null) {
            const reqObj = {
                complexId: linkMaster.CommunityId,
                leaseId: linkMaster.MasterId
            }


            const webData = await LocationService.View360List(reqObj);


            if (webData.error == "0" && webData.message.length > 0) {

                details.web3d = webData.message[0].weblink;
            } else {
                details.web3d = '';
            }
            details["LinkMaster"] = linkMaster;

            await this.setState({
                communitysList: communitysList.concat(details),
                walkscoreLoading: true
            });
            //	await this.walkScore(details,index)
        }
    }
    // async getWebLink(req, linkMaster){
    //   const { communitysList } = this.state;
    //   console.log(req, linkMaster,"linkMaster");

    // }
    // Walkscore Info Getting
    async walkScore(details, index) {
        const apidata = {
            city: details.city,
            state_name: details.state,
            address: details.address == "" ? details.city : details.address,
            latitude: details.latitude,
            longitude: details.longitude
        };
        const result = await AppController.wallsckoreFun(apidata);
        const info = [
            {
                Score: result.walkscore == undefined ? null : result.walkscore,
                description:
                    result.description == undefined ? null : result.description,
                name: "WalkScore",
                chartName: "chart1" + index,
                colour: "#e1362d",
                image: "WalkScore.png"
            },
            {
                Score: result.bike == undefined ? null : result.bike.score,
                description: result.bike == undefined ? null : result.bike.description,
                name: "BikeScore",
                chartName: "chart2" + index,
                colour: "#e1362d",
                image: "BikeScore.png"
            }
        ];
        await this.setState({
            communitysList: this.state.communitysList.concat(details),
            walkscoreLoading: true,
            walkScoreDetails: this.state.walkScoreDetails.concat([info])
        });
        const walkScoreDetails = this.state.walkScoreDetails;
        for (var i = 0; i < walkScoreDetails.length; i++) {
            AppController.doughnutChart1(walkScoreDetails[i]);
        }
    }
    async bookNow(data) {
        //console.log(Object.keys(data).length,'fsdhfksdf');
        await this.setState({ confirmData: data });
        jQuery(function ($) {
            $('#confirmModal').modal('show');
        }.bind(this));
        //const messageString = `Are you sure you want to book "${data.community_name}" Suite !!!`;
        // confirmAlert({
        //   title: "",
        //   message: messageString,
        //   childrenElement: () => "",
        //   confirmLabel: "Yes",
        //   cancelLabel: "No",
        //   onConfirm: () => {
        //     this.bookNowProcess(data);
        //   },
        //   onCancel: () => {}
        // });
    }
    async bookNowProcess(bookNow) {
        //console.log(bookNow,"bookNow");

        if (
            bookNow.LinkMaster.GuestEmail != null &&
            bookNow.LinkMaster.GuestEmail != undefined &&
            bookNow.LinkMaster.GuestEmail != ""
        ) {
            const request = {
                CommunityName: bookNow.community_name,
                CommunityAddress: bookNow.address,
                CommunityState: bookNow.state_name,
                CommunityCity: bookNow.city_name,
                MoveInDate: bookNow.LinkMaster.MoveInDate,
                MoveOuDate: bookNow.LinkMaster.MoveOuDate,
                OfferedRate: bookNow.LinkMaster.OfferedRate,
                Size: bookNow.LinkMaster.Size,
                DailyRent: bookNow.LinkMaster.DailyRent,
                OrderId: bookNow.LinkMaster.OrderId,
                MasterId: bookNow.LinkMaster.MasterId,
                GuestEmail: bookNow.LinkMaster.GuestEmail,
                GuestName: bookNow.LinkMaster.GuestName,
                BookedBy: bookNow.LinkMaster.BookedByEmail,
            };
            //console.log(request,"request");

            const ResponseApi = await QuoteOrderService.UpdateLinkMaster(request);


            if (ResponseApi.error == "0") {
                const ResponseMailer = await QuoteOrderService.QuoteOrderBookingEmail(
                    request
                );
                jQuery(
                    function ($) {
                        $("#confirmModal").modal("hide");
                        $("#successModal").modal("show");
                        setTimeout(
                            async function () {
                                $("#successModal").modal("hide");
                            }.bind(this),
                            2000
                        );
                    }.bind(this)
                );
                this.setState({
                    communityAmenities: [],
                    appartmentAmenities: [],
                    walkScoreDetails: [],
                    walkscoreLoading: false,
                    communitysList: [],
                    LinkMasters: [],
                    error: false
                });
                this.LinkMastersByOrderUId();
            } else {
                alert("Something went wrong in our side");
            }
        } else {
            alert("we dont have Guest EmailId For Booking Suite");
        }
    }
    async SendQuestion(e) {
        e.preventDefault();
        const { Question, QuestionInCommunity, LinkMasters } = this.state;
        this.setState({
            FormLoader: true,
            FormErrorMessage: ''
        })
        if (Question != '' && QuestionInCommunity != '') {
            const Request = {
                CommunityName: QuestionInCommunity,
                Question: Question,
                MoveInDate: LinkMasters.message[0].MoveInDate,
                MoveOuDate: LinkMasters.message[0].MoveOuDate,
                Size: LinkMasters.message[0].Size,
                GuestName: LinkMasters.message[0].GuestName,
                BookedBy: LinkMasters.message[0].BookedByEmail,
                OrderId: LinkMasters.message[0].OrderId,
                MasterId: LinkMasters.message[0].MasterId,
                GuestEmail: LinkMasters.message[0].GuestEmail
            };
            const ResponseMailer = await QuoteOrderService.Questions(
                Request
            );
            if (ResponseMailer.status == true) {
                jQuery(
                    function ($) {
                        $("#questionmodal").modal("hide");
                        $("#successModal").modal("show");
                        setTimeout(
                            async function () {
                                $("#successModal").modal("hide");
                            }.bind(this),
                            2000
                        );
                    }.bind(this)
                );

                this.setState({
                    FormLoader: false,
                    FormErrorMessage: ''
                })
            }
            else {
                this.setState({
                    FormLoader: false,
                    FormErrorMessage: 'Mail Not Sented'
                })
            }
        } else {
            this.setState({
                FormLoader: false,
                FormErrorMessage: 'Please Enter Required Fields'
            })
        }

    }
    setValue(field, e) {
        var object = {};
        object[field] = e.target.value;
        this.setState(object);
    }
    clear() {
        const { LinkMasters } = this.state
        this.setState({
            Question: '',
            QuestionInCommunity: '',
            FormLoader: false,
            FormErrorMessage: ''
        })
    }

    async modalPopUp() {
        await this.setState({ modalOpen: true })
        jQuery(function ($) {
            $('#questionmodal').modal({ backdrop: 'static', keyboard: false, show: true });
        }.bind(this));
    }

    // valindex(data){
    //     console.log(data,'p')
    //     // const _this = this
    //     // _this.setState({ valueindex: data })
    // }
    /* It is invoked to return html content */
    render() {


        const { LinkMasters, walkscoreLoading, error, modalOpen, errorMessage } = this.state;
        return (
            <div>
                <GuestHeader />
                {walkscoreLoading && !error ? (
                    <div className="nike_page_view SAweb_updateUi p mrg-top-0 pad-top-50 ">
                        <div className="header">
                            <img className="logo logo-black-filter " src="https://s3-us-west-1.amazonaws.com/suiteam-website/Website-Images/SuiteAmerica-Company-Nike-Logo.png" alt="" />
                            <h1>Hi, {LinkMasters.message[0].GuestName}!</h1>

                            <p>Based on your dates of: <span className="font-bold"> {" "}
                                {LinkMasters.message[0].MoveInDate} -{" "}
                                {LinkMasters.message[0].MoveOuDate}{" "}</span></p>
                            <p>We think you'd like these communities. Please select an option for your stay.</p>
                        </div>
                        <div className="container">

                            <div className="content-part">

                                {(LinkMasters.message[0].Header != null && LinkMasters.message[0].Header != "") ? <span><p>{LinkMasters.message[0].Header}</p></span> :
                                    <span><p>*Please note that Downtown Portland is a large urban center and as such, has its share of ambient city noise including train/light rail/mass transit, people, sirens, and construction. Most of the noise will take place during the day, but you may experience some noise during traditional "quiet hours" of 10:00pm to 6:00am. If you think city noise could negatively impact your experience, please let us know.  We can offer noise reducing solutions inside your suite. Another aspect that comes with living in Downtown Portland is the potential for the unhoused population to be present, so please be aware of that in advance.</p>
                                        <p>*Please also note that parking availability is limited and spaces are much smaller than outside the city.  Spaces will typically accommodate compact or standard sized vehicles only. If you are in need of secondary parking arrangements and/or plan to have an oversized vehicle such as a truck or SUV, please contact us at least 3 business days in advance to discuss alternative options.</p> </span>}
                                {LinkMasters.message[0].Comment != null ? <p>{LinkMasters.message[0].Comment}</p> : ""}
                                <a className="blue-a" href="https://suiteam-website.s3-us-west-1.amazonaws.com/PDF/Business-Package.pdf" target="_blank">What's Included?  <i className="fa fa-long-arrow-right cross-arrow"></i></a>
                                <a className="blue-a" href="/covid19-safety-measures" target="_blank">COVID-19 Safety Protocol  <i className="fa fa-long-arrow-right cross-arrow"></i></a>


                            </div>
                            <Communitys  {...this.state} bookNow={this.bookNow} webView={this.webView} moreInfo={this.moreInfo} />
                            {(LinkMasters.message[0].Footer != null && LinkMasters.message[0].Footer != "") ? <span><p>{LinkMasters.message[0].Footer}</p></span> :
                                <span><p>Your company covers your temporary housing.  SuiteAmerica's apartments include furniture, housewares, expanded basic cable television, wireless high-speed internet, parking, housekeeping every other week and all utilities.  Although your company is paying for your temporary housing, we need your credit card information to cover any incidentals, and/or damages that your company does not cover.</p>
                                    <p>Once you confirm if you would like to book one of these options, you will receive a DocuSign link via email which will allow you to securely complete and sign your required rental forms (please check your junk/spam email folders if you do not see a DocuSign email).  Please complete these forms as soon as possible.  Once we receive your completed forms, we can proceed with securing the apartment and will follow up with an email lease confirmation.  The confirmation has all the information regarding your corporate apartment to include address, parking space assignment, lease dates, entry instructions and other useful information. Availability changes very quickly and cannot be guaranteed until all completed agreements are received. </p>
                                    <p>**We strongly recommend that you contact the insurance agency of your choice to secure Renter’s Insurance for your stay, as you are not covered by the SuiteAmerica insurance carrier for property damage or loss claims of personal injury made against you.**</p></span>}

                            <a href="#" onClick={this.modalPopUp} className="query"> <i className="fa fa-envelope-open-o"></i> Any Questions? Contact us here. </a>



                        </div>

                    </div>
                ) : error ?
                    <div className="error-sms-Quote">
                        <span className="alert alert-danger">
                            <i className="fa fa-exclamation-circle" aria-hidden="true"></i> {errorMessage}

                        </span>
                    </div>

                    : (
                        <Loader />
                    )}
                <div className="modal fade" id="confirmModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content animated zoomIn" >
                            <div className="modal-header22">
                                <h5 className="modal-title" id="exampleModalLabel">Are you sure you want to book {this.state.confirmData.community_name} Suite !!!</h5>
                                <button type="button" className="btn close" data-dismiss="modal" aria-label="Close">
                                    <i className="bi bi-x"></i>
                                </button>
                            </div>
                            <div className="modal-body">



                                <button type="button" className="btn btn-primary active mrg-right-15" data-dismiss="modal" >No</button>
                                <button type="button" className="btn btn-primary" onClick={() => this.bookNowProcess(this.state.confirmData)}>Yes</button>

                            </div>
                        </div>
                    </div>
                </div>

                {modalOpen ? (
                    <QuestionsModal
                        {...this.state}
                        setValue={this.setValue}
                        SendQuestion={this.SendQuestion}
                        clear={this.clear}
                    />
                ) : (
                    ""
                )}
                <SuccessModal />
                <Footer />
            </div>
        );
    }
}
export default AutoConfirmMains;
