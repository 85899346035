/* Importing the node modules, child components, services and controllers used 
   inside TravelBookingHeader component */
import { Link } from 'react-router-dom';
import CallModal from '../../common/callModal';
import BookNowCommunity from './booknowCommunity';
import TravelBookingLogin from './login';

/* TravelBookingHeader Component initialization */
class StarbucksBookingHeader extends React.Component {
    /* Initializing objects of its TravelBookingHeader class */
    constructor(props) {
        super(props);
        this.state = {
            phoneNo: '',
            message: '',
            color: ''
        }
        this.clearPhone = this.clearPhone.bind(this);
        this.logOut = this.logOut.bind(this);
    }
    /* To clear input field values */
    clearPhone() {
        this.setState({ phoneNo: '', message: '', color: '' });
    }
    /* To logout from the travel booking */
    async logOut() {
        await localStorage.setItem('starbuckstravel-isLogin', 'false');
        location.href = "/availability-calendar"
    }
    /* It is invoked to return html content */
    render() {

        const emailId = localStorage.getItem('starbuckstravel-email');
        const password = localStorage.getItem('starbuckstravel-password');
        const isLogin = localStorage.getItem('starbuckstravel-isLogin');

        return (
            <div>
                <header className="travel_header">
                    <nav className="navbar navbar-default">
                        <div className="container-fluid">
                            <div className="navbar-header">
                                <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                                    <span className="sr-only">Toggle navigation</span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                                {/* <Link className="navbar-brand" to= {"/starbucks-travel"}> */}
                                <div className="navbar-brand">
                                    <img src={s3BucketUrl_WebsiteImages + "SA-Logo.png"} alt="" />
                                </div>
                                {/* </Link> */}
                            </div>
                            <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                                <ul className="nav navbar-nav">
                                    <li>
                                        <a href="#" className="contact" data-toggle="modal" data-target="#callModal" onClick={this.clearPhone}>
                                            <img src='/assets/newUI-images/nike/phone.svg' alt="" />+1 800.367.9501</a>
                                    </li>
                                </ul>
                                {/* <Link className="navbar-brand travel_brand" to= {"/starbucks-travel"}> */}
                                <div className="navbar-brand travel_brand">
                                    <img src='/assets/newUI-images/logo/SA-full-logo.svg' alt="" />
                                </div>
                                {/* </Link> */}

                                {(emailId == null && password == null && isLogin == "false") ?
                                    <ul className="nav navbar-nav navbar-right tr_booking_nav bg-dark">
                                        <li>
                                            <a data-toggle="modal" data-target="#travel-booking-login">
                                                <i className="fa fa-sign-in"></i> Login</a>
                                        </li>
                                    </ul>
                                    :
                                    (emailId != null && password != null && isLogin == "true" ?
                                        <ul className="nav navbar-nav navbar-right tr_booking_nav bg-dark">
                                            <li>
                                                <Link to='/starbucks-travel-admin'>
                                                    <i className="fa fa-user"></i> Admin</Link>
                                            </li>
                                            <li>
                                                <a onClick={this.logOut}>
                                                    <i className="fa fa-sign-out"></i> Logout</a>
                                            </li>
                                        </ul> :
                                        <ul className="nav navbar-nav navbar-right tr_booking_nav bg-dark">
                                            <li>
                                                <a data-toggle="modal" data-target="#travel-booking-login">
                                                    <i className="fa fa-sign-in"></i> Login</a>
                                            </li>
                                        </ul>)
                                }
                            </div>
                        </div>
                    </nav>

                </header>
                <CallModal />
                <BookNowCommunity />
                <TravelBookingLogin />
            </div>

        );
    }
}
export default StarbucksBookingHeader;