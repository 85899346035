/* Importing the node modules, child components, services and controllers used 
   inside NikeInternshipHeader component */
import { Link } from "react-router-dom";
import $ from "jquery";
import MainController from "../../../controller/mainController";
/* NikeInternshipHeader Component initialization */
class AppleInternShipHeader extends React.Component {
  /* Initializing objects of its NikeInternshipHeader class */
  constructor(props) {
    super(props);
    /* NikeInternshipHeader Component State variables Initialization */
    this.state = {
      name: "",
      company: "",
      location: "",
      phone: "",
      email: "",
      bookingDetails: {},
      BookingData: {},
    };
    // this.selection = this.selection.bind(this);
    this.internQuestionnaire = this.internQuestionnaire.bind(this);
  }
  /* It is invoked immediately after a component is mounted */
  componentDidMount() {
    MainController.removeClassOnNavCollapse();
  }
  /* To uncheck the input fields type radio */
  internQuestionnaire() {
    $('input[name="Yes"]').prop("checked", false);
    $('input[name="No"]').prop("checked", false);
  }

  render() {
    return (
      <div>
        <div className="profile_submenu">
          <div className="container">
            <nav className="navbar navbar-inverse">
              <div className="navbar-header">
                <button
                  type="button"
                  className="navbar-toggle collapsed"
                  data-toggle="collapse"
                  data-target="#guest-login"
                  aria-expanded="false"
                >
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
                <a href="http://nike.com" target="_blank">
                  <img
                    src={
                      s3BucketUrl_WebsiteImages +
                      "SuiteAmerica-Company-Nike-Logo.png"
                    }
                    alt=""
                    className="nike_list_img_mo"
                  />
                </a>
              </div>
              <div className="collapse navbar-collapse" id="guest-login">
                <ul className="nav navbar-nav">
                  <li>
                    <Link to="/company/Apple-interns/home">Home</Link>
                  </li>
                  <li>
                    <Link to="/company/Apple-interns/communities">
                      Communities
                    </Link>
                  </li>
                  <li>
                    <Link to="/company/Apple-interns/apartment-tour">
                      Apartment Tour
                    </Link>
                  </li>
                  <li>
                    <Link to="/company/Apple-interns/faq">FAQ's</Link>
                  </li>
                  <li>
                    <Link to="/company/Apple-interns/questionnaire">
                      Intern Questionnaire
                    </Link>
                  </li>
                  {/* <li><Link to="#" data-toggle="modal" data-target="#internship-confirmation" onClick={this.internQuestionnaire}>Intern Questionnaire</Link></li> */}
                  <li>
                    <Link to="/company/Apple-interns/contact-us">
                      Contact Us
                    </Link>
                  </li>
                </ul>
                {/* <a href="http://nike.com" target="_blank">
                  <img
                    src={
                      s3BucketUrl_WebsiteImages +
                      "SuiteAmerica-Company-Nike-Logo.png"
                    }
                    alt=""
                    className="nike_list_img"
                  />
                </a> */}
              </div>
            </nav>
          </div>
        </div>
        <div className="subHeaderHeight"></div>
      </div>
    );
  }
}
export default AppleInternShipHeader;
