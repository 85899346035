import Loader from "./loader";

const QuestionsModal=(props)=>{
    const {Question,QuestionInCommunity,LinkMasters,FormLoader,FormErrorMessage}=props;
return(
<div className="modal fade" id="questionmodal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content animated zoomIn" >
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Any Questions</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.clear}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <form onSubmit={props.SendQuestion}>
        <p style={{color:'red'}}>{FormErrorMessage}</p>
          <div className="form-group">
            <label htmlFor="recipient-name" className="col-form-label">Community<span className="mandatory">*</span></label>
            <select className="form-control" value={QuestionInCommunity}  onChange={(e) => props.setValue('QuestionInCommunity', e)}>  
            <option value='' disabled>Select</option>
            {LinkMasters.message.map((row, index) => {
            return(<option key={index} value={row.CommunityName}>{row.CommunityName}</option>)
            })}</select>
          </div>
          <div className="form-group">
            <label htmlFor="message-text" className="col-form-label">Question<span className="mandatory">*</span></label>
            <textarea className="form-control" id="message-text" value={Question} onChange={(e)=>props.setValue('Question',e)}></textarea>
          </div>

          {FormLoader?<div className='text-center' style={{fontSize: '25px', width: '100%', margin: 'auto', textAlign: 'center' ,color:'red'}}><i className="fa fa-spinner fa-spin"/> Please wait ...!</div>:<div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={props.clear}>Close</button>
        <button type="submit" className="btn btn-primary" >Send message</button>
      </div>}
        </form>    
      </div>    
    </div>
  </div>
</div>
)

}
export default QuestionsModal;