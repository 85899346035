/* Importing the node modules, child components, services and controllers used 
   inside NikeWhatsIncluded component */

import MainController from "../../controller/mainController";
import SubFooter from "../../common/subFooter";
import LinkedinInternsHeader from "./common/LinkedinInternsHeader";
import FAQ from "./common/LinkedinInternsFaq";
import GuestHeader from "../../common/guestHeader";
import Footer from "../../common/footer";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Disclaimer from "./common/disclimar";
import SubFooterLinkedIn from "./common/subFooterlinkedIn";
/* NikeWhatsIncluded Component initialization */
class LinkedinInternsWhatsIncluded extends React.Component {
  /* Initializing objects of its NikeWhatsIncluded class */
  constructor(props) {
    super(props);
    this.generateAccordianItem = this.generateAccordianItem.bind(this);
  }
  /* It is invoked immediately before mounting occurs */
  componentWillMount() {
    MainController.getInitialInformation();
  }

  generateAccordianItem(item, index) {
    console.log(item);
    return (
      <div className="panel panel-default">
        <div className="panel-heading">
          <h4 className="panel-title">
            <a
              className="collapsed"
              data-toggle="collapse"
              data-parent="#accordion"
              href={`#accordian-index-${index}`}
              aria-expanded="false"
              aria-controls="collapse2"
            >
              {item[0]}
            </a>
          </h4>
        </div>
        <div
          id={`accordian-index-${index}`}
          className="panel-collapse collapse"
        >
          <div className="panel-body">
            <div className="profile-opener">{item[1]}</div>
          </div>
        </div>
      </div>
    );
  }

  /* It is invoked to return html content */
  render() {
    const accordionItems = [
      [
        "What is corporate housing?",
        "SuiteAmerica provides temporary housing solutions on a short term basis for relocating employees, extended travelers, and interns in apartment communities convenient to your employer.",
      ],
      [
        "Are the apartments furnished or unfurnished?",
        "SuiteAmerica apartments are fully furnished and all-inclusive of furniture, housewares, linens, utilities, and WIFI included in the rental. ",
      ],
      [
        "What is included in the housing package? Will I need to bring my own linens and towels?",
        <>
          Our fully furnished all-inclusive apartments include rent, furniture,
          housewares, WIFI, & utilities (electric/gas/water/sewer/trash). Click
          <Link to="/company/LinkedIn-interns/whats-included">here</Link> to
          view what is included in the package.
        </>,
      ],
      [
        "What is NOT covered under the housing package?",
        "Telephone phone service, cable TV, and housekeeping services are NOT included.\nDamages and incidentals are the responsibility of interns.",
      ],
      [
        "Do I need renters insurance?",
        "Renters Insurance is NOT included with your reservation but we highly recommend adding it. The cost is only $1.75 per day to add renters insurance which Includes $50,000 of personal property insurance and up to $15,000 of accidental damage insurance.",
      ],
      [
        "Where are the apartments?",
        <>
          Our apartment communities are located within a 15 mile radius to your
          employer. To view sample locations click
          <a href="/company/LinkedIn-interns/locations" target="blank">
            here.
          </a>
          Please note housing options are limited and available on a first come
          first serve basis.
        </>,
      ],
      [
        "How big are the apartments?",
        "One bedroom one bath apartments average 650 square feet. Two bedroom two bath apartments average 1000 square feet. All apartments have full size kitchens with full size appliances.",
      ],
      [
        "How many roommates will I have?",
        "The choice is yours. Most interns will request a two bedroom two bath apartment to share with 4 interns (dorm style) to save costs. One bedroom apartments can accommodate 1-2 interns. Two bedroom apartments can accommodate 2-4 interns.",
      ],
      [
        "Can I request my own roommate(s)?",
        "Yes, we’d love you to request/choose your own roommates. Simply let us know on the questionnaire who you would like to share with.",
      ],
      [
        "How are roommates matched?",
        "Roommate are typically matched by gender, arrival date, and preferences. Know another intern you’d like to roommate with – let us know!",
      ],
      [
        "Will I be matched with a roommate of the opposite gender?",
        "The choice is yours. Please make sure to indicate your rooming preferences on the rooming request form.",
      ],
      [
        "When will I be notified of my roommate matches?",
        "We will begin matching roommates as soon as there are enough interns signed up for matching. You should hear from us at least 30-45 days prior to your start date.",
      ],
      [
        "What happens after my roommates and I are matched?",
        "Once you are matched you will be contacted by SuiteAmerica with a lease to secure your reservation. Please make sure you respond/confirm within 48 hours, otherwise we will offer the available room to another intern.",
      ],
      [
        "When will I receive confirmation of my new apartment address?",
        "We will notify you and your roommates of the available housing options 30-45 days prior to your arrival date. Once you are matched, you will receive a reservation agreement to secure reservations. Once signed, you will receive confirmation with your address.  You will receive move in instructions approx. 3 days prior to move in.",
      ],
      [
        "How does the pricing work?",
        "The pricing is a fixed rate per intern based on the size of apartment and number of roommates. All rent is divided evenly and all reservations will start and end on the same day.",
      ],
      // [
      //   "Is there a deposit required?",
      //   "There is a $199 non refundable deposit which will go toward your first month’s rent.",
      // ],
      [
        "When is rent due and when does the lease start?",
        "The lease will start and end at the same time for every roommate. Move in day is after 4pm on the Friday before the internship start date. Move out day is before midnight on the Sunday after the internship ends. Rent is divided equally between roommates and rent for the first month’s rent is due 14 days after your move in day. Rent is due on the first of the month thereafter.",
      ],
      [
        "Is parking included in the rental?",
        "Every apartment comes with 1 assigned parking space and additional parking is extremely limited. It’s very important to let us know if you will need parking so we can match interns accordingly. ",
      ],
      [
        "Once I am matched and sign the confirmation with my roommates, can I cancel?",
        "No, once you sign the agreement along with your roommates – you cannot cancel. You will be obligated for your portion of the rent for the full duration of the reservation.",
      ],
      [
        "Is there a deadline to apply for housing?",
        "Yes. Please make sure to apply for housing at least 45 days prior to your internship start date. Housing is available on a first come first serve basis. Housing sells out QUICKLY every year, there will not be enough housing for everyone.  Once you are offered housing, you will have 48 hours to confirm your reservation.  Housing is NOT guaranteed until a le ase has been signed.",
      ],
      [
        "Will I be guaranteed housing?",
        "No. Housing is available on a first come first serve basis. Housing sells out QUICKLY every year, there will not be enough housing for everyone.  Once you are offered housing, you will have 48 hours to confirm your reservation.  Housing is NOT guaranteed until a lease has been signed.",
      ],
    ];
    return (
      <div className="corporateHousing_Updates">
        <GuestHeader />

        <LinkedinInternsHeader />
        <div className="quote-section bg-white">
          <div className="container  intra_staff2 col-sm-8 col-sm-offset-2 py-3">
            <div
              className="text-left col-md-10 col-md-offset-1 wow fadeInUp company_p SAweb_updateUi bg-white"
              data-wow-delay="0.5s"
            >
              <p className="p fw-700">Frequently Asked Questions</p>
              <p className="p fw-300">
                Explore the answers you need in our FAQs section.
              </p>
              <p className="p fw-300">
                {" "}
                From processes to policies, we've got you covered. Consider this
                your central hub for clarity and a smoother experience with us.
                If you can't find what you're looking for, just reach out-we're
                here to assist.
              </p>
            </div>
          </div>
          <div className="container-fluid nike guest_sitenew">
            <div className="container intra_staff2 col-sm-8 col-sm-offset-2">
              <div
                id="accordion"
                className="panel-group mrg-top-70 mrg-btm-50 company_acdn"
                role="tablist"
                aria-multiselectable="true"
              >
                {accordionItems.map((item, index) =>
                  this.generateAccordianItem(item, index)
                )}
              </div>
            </div>
          </div>
          <div className="container">
            <p>
              Due to the high volume of interns coming to the area this summer,
              housing options are limited and available on a first come first
              serve basis. We recommend responding to all emails as soon as
              possible and securing reservations as soon as options come
              available.{" "}
            </p>
          </div>
        </div>
        <Disclaimer />
        {/* <SubFooter /> */}
        <SubFooterLinkedIn />
        <Footer />
      </div>
    );
  }
}
export default LinkedinInternsWhatsIncluded;
