/* Importing the node modules, child components, services and controllers used 
   inside SuccessModal component */
   const SuccessModal = () => {
        return(
            <div className="modal fade savesearch" id="successModal" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content rm-border-radius">
                        <div className="modal-header">
                        <button type="button" className="close btn" data-dismiss="modal" aria-label="">
                        <i className="bi bi-x"></i>
                        </button>
                        </div>
                        <div className="modal-body">
                        <h2>Thank You!</h2>
                        <p>Mail has been sent successfully</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
export default SuccessModal;