import React, { Component } from 'react'
import Footer from '../../common/footer'
import Guest from '../../common/guest'
import GuestHeader from '../../common/guestHeader'
import SubFooter from '../../common/subFooter'
import QuickRequestForm from './quickRequestForm';
import SessionService from "../../../services/sessionService";
import GuestServicesapi from '../../../services/guestServicesapi';
import platform from 'platform';
import publicIp from 'public-ip';

export class checkOut extends Component {
    constructor(props) {
        super(props)
        this.state = {
            menuShow:false,
            orderUId:"",
            guestEmail:"",
            checkOutNotes:"",
            checkOutNotesValid:true,
            checkOutStatus:"",
            isAlreadySubmitted:false,
            alreadySubmitted:""
        }
    }

    componentDidMount(){
        SessionService.SessionGet({ type: 'guest' }).then((sessionResponse) => {
            var guestEmailObj = {
              guestEmail: sessionResponse.GuestEmail,
              status: "active"
            }
            GuestServicesapi.GetOrdersByGuestEmail(guestEmailObj).then((response) => {
                console.log(response,"response")
              if (response.error !== 1 && response.message.Orders.length > 0) {
                this.setState({ orderUId: response.message.Orders[0].OrderUId,guestEmail:sessionResponse.GuestEmail });
    }
            })
        })
    }

    async checkOut(){
        const address = await publicIp.v4().then(ip => {
            return ip
          });
var checkOutObj={
        OrderUId:this.state.orderUId,
        Type:"OUT",
        Time:moment().format("YYYY-MM-DD HH:MM:SS.SSS"),
        BrowserInfo:platform.name, //browser name
        IPAddress:address,
        Source:"Web",
        createdby:this.state.guestEmail,
        CreatedDate:moment().format("YYYY-MM-DD HH:MM:SS.SSS"),
        Notes:this.state.checkOutNotes
}
if(this.state.checkOutNotes=="" || this.state.checkOutNotes==undefined){
    this.setState({checkOutNotesValid:false})
}else{
    GuestServicesapi.SaveInOutOrdersLog(checkOutObj).then((response) => {
if(response.message=="Success"){
    this.setState({checkOutStatus:"Submitted Successfully",checkOutNotesValid:true,isAlreadySubmitted:false})
}
if(response.error==1){
    this.setState({alreadySubmitted:"You are not allowed to submit multiple times.",isAlreadySubmitted:true,checkOutNotesValid:true})
}
    })
}
    }

    checkOutNotes(e){
        this.setState({checkOutNotes:e.target.value})
    }

    render() {
        return (
            <div>
            <GuestHeader />
             <Guest/>
             <QuickRequestForm menuShow={this.state.menuShow}/>
             <div className="sampleTesingView">
             <h1>Check Out</h1>
             <textarea placeholder="Enter Notes..." name="checkOut" onChange={(e)=>this.checkOutNotes(e)} value={this.state.checkOutNotes}></textarea>
                    {!this.state.checkOutNotesValid?<p style={{color:"red"}}>Please enter Notes</p>:null}
        {
            this.state.isAlreadySubmitted?<p style={{color:"red"}}>{this.state.alreadySubmitted}</p>:null
        }
                {this.state.checkOutStatus!==""?<p style={{color:"green"}}>{this.state.checkOutStatus}</p>:null}
                    <button type="button" className="btn" onClick={()=>this.checkOut()}>Submit</button>
                </div>
     <SubFooter />
     <Footer />
     </div>
        )
    }
}

export default checkOut
