/* Importing the node modules, child components, services and controllers used
   inside FiveStarLanding component */
import $ from "jquery";
import AppController from "../../controller/appController";
import IntranetServicesApi from "../../../services/intranetServicesApi";
import SessionService from "../../../services/sessionService";
import ReCAPTCHA from "react-google-recaptcha";
import Spinner from '../../common/spinner';
/* FiveStarLanding Component initialization */
class IntranetLogin extends React.Component {
    /* Initializing objects of its FiveStarLanding class */
    constructor(props) {
        super(props)
        /* FiveStarLanding Component State variables Initialization */
        this.state = {
            email: '',
            password: '',
            message: '',
            loader: false,
            color: '',
            CaptchaValue:''
        }
        this.login = this.login.bind(this);
        this.setValue = this.setValue.bind(this);
        this.onChangeCaptcha=this.onChangeCaptcha.bind(this);
    }
    /* It is invoked immediately after a component is mounted */


    async componentWillMount() {
        AppController.removeLocalStorageData();
        $('html, body').animate({scrollTop: 0});
        AppController.stickyTitles()
        const GetResponse = await SessionService.SessionGet({type:'intranet'});
        if(GetResponse.error==0) {
         location.href = '/intranet-home'
        }
    }
    /* To change the selected input field value */
    setValue(field, e) {
        var object = {};
        object[field] = e.target.value;
        this.setState(object);
    }
    /* Authentication checking before login to the five star application */
    async login(e) {
        e.preventDefault();
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if ((this.state.email != '' && reg.test(this.state.email)) && this.state.password != '') {
            this.setState({loader: true, message: '', color: ''})
            const loginDetails = {
                username: this.state.email,
                password: this.state.password
            }
            const loginResult = await IntranetServicesApi.getUserApi(loginDetails);
            if (loginResult.error == "1") {
                this.setState({message: 'Login Details Are InValid', color: 'red', loader: false})
            } else {
                const userInfo={
                    EmailId: loginResult.message.Email,
                    UserId: loginResult.message.Id,
                    UserActive: loginResult.message.isActive,
                    type: 'intranet',
                    FirstName:loginResult.message.FirstName,
                    LastName:loginResult.message.LastName
                }
                const Intranet = {
                    userData: userInfo,
                    type: 'intranet'
                }
                await SessionService.SessionStore(Intranet);
                const GetResponse = await SessionService.SessionGet({type:'intranet'});
                if(GetResponse.error==0){
                    this.setState({loader: false, message: '', color: ''})
                    location.href = '/intranet-home';
                }
                else{
                    alert('session is expired')
                }
            }
        } else {
            this.setState({message: 'Enter Valid Email, Password & Select Captcha', color: 'red'})
        }
    }
    async onChangeCaptcha(CaptchaValue) {
        await this.setState({CaptchaValue})
    }
    /* It is invoked to return html content */
    render() {
        return (
            <div
                id="pages_5star"
                style={{
                'background': '#f5f5f5'
            }}>
                <div className="login_main">
                    <div className="container-fluid">
                        <div className="login_star">
                            <img
                                src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Logo.png"}
                                alt=""/>
                            <div className="col-sm-12 mrg-btm-10">
                                {this.state.message != ''
                                    ? <span
                                            style={{
                                            color: this.state.color,
                                            textAlign: 'right'
                                        }}>{this.state.message}</span>
                                    : ''}
                            </div>
                            <form
                                onSubmit={(e) => this.login(e)}>
                                <div className="f-contact-form-style">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Email"
                                            value={this.state.email}
                                            onChange={(e) => this.setValue('email', e)}/>
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="password"
                                            className="form-control"
                                            placeholder="Password"
                                            value={this.state.password}
                                            onChange={(e) => this.setValue('password', e)}/>
                                    </div>
                                    <ReCAPTCHA
                                        ref={ ref => this.recaptcha = ref }
                                        sitekey="6Lcbw1AUAAAAAE5K5cYDyhoGu0RJY9C3CLtGFXAo"
                                        onChange={this.onChangeCaptcha}
                                    />
                                    {this.state.loader
                                        ? <div className="panel-body_loader">
                                           <Spinner fontSize="20px"/>
                                            </div>
                                        : <div className="mrg-btm-20 mrg-top-20">
                                            <button type="submit" className="btn">
                                                Login</button>
                                        </div>
}
                                </div>
                            </form>
                        </div>
                    </div>

                </div>

            </div>

        );
    }
}
export default IntranetLogin;