/* Importing the node modules, child components, services and controllers used 
   inside GuestLogin component */
import $ from "jquery";
import MainController from "../../controller/mainController";
import GuestHeader from '../../common/guestHeader';
import GuestServicesapi from '../../../services/guestServicesapi';
import SubFooter from "../../common/subFooter";
import Footer from '../../common/footer';
import SuccessModal from '../../common/successModal';
import SessionService from "../../../services/sessionService";
import AppController from '../../controller/appController';
import platform from 'platform';
import authDetails from "../../common/authDetails";
import ThirdPartyApiService from "../../../services/thirdPartyApis";
import ReserveNowApi from '../../../services/relo';

/* GuestLogin Component initialization */
class AuthLogin extends React.Component {
    /* Initializing objects of its GuestLogin class */
    constructor(props) {
        super(props)
        /* GuestLogin Component State variables Initialization */
        this.state = {
            email: '',
            message: '',
            color: '',
            loader: false,
            verifyemaillabel: false,
            verifyotplabel: false,
            clientId: '',
            clientSecret: '',
            OtpErrMsg: '',
            isOTPValid: false,
            passwordLessOtp: '',
            resendOtpTimer: 90,
            authCheckRes: {},
            resendMsg: false
        }
        this.setValue = this.setValue.bind(this);
        this.authentication = this.authentication.bind(this);
    }
    /* It is invoked immediately before mounting occurs */
    async componentWillMount() {
        AppController.removeLocalStorageData();
        $('html, body').animate({ scrollTop: 0 });
        AppController.stickyTitles()
        MainController.getInitialInformation();
        const GetResponse = await SessionService.SessionGet({ type: 'guest' });
        if (GetResponse.error == 0) {
            location.href = '/reservations'
        }
    }
    /* Update the state object of the form  */
    setValue(field, e) {
        var object = {};
        object[field] = e.target.value;
        object['message'] = '';
        this.setState(object);

    }
    passwordLessOtp(e) {
        this.setState({ passwordLessOtp: e.target.value })
        this.setState({ isOTPValid: false, resendMsg: false })
    }
    async callOtp(Otptype) {
        await this.setState({ isOTPValid: false, passwordLessOtp: '', OtpErrMsg: "", })
        this.setState({
            clientId: authDetails.authCredentails().clientId,
            clientSecret: authDetails.authCredentails().clientSecret,
        })
        if (Otptype == 'email') {
            const body = {
                email: this.state.email.trim(),
            }
            let sentemailotp = await ThirdPartyApiService.guestsentemailotp(body);
            if (sentemailotp == 'response') {
                this.setState({
                    resendMsg: true, verifyotplabel: true,
                    OtpType: 'email', resendOtpTimer: 90, validateOtp: true, loader: false, phonecallStatus: false,
                    otpStatus: true
                })
                this.resendOtpTimerFunc();
            } else if (sentemailotp == 'error') {
                this.setState({ errorStatus: true, loaderlogin: false, message: "Looks like you don't have account with us, please register." })
            }
           
        }
    }
    /* Authentication checking */
    async authentication() {
      
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        await this.setState({ loader: true })
        if ((this.state.email != '' && reg.test(this.state.email))) {
            const address = '000.00.000.000';
            const loginReqObj = {
                guestEmail: this.state.email,
                // guestPassword: this.state.auth0UserProfile.sub.split('|')[1],
                guestPassword: '',
                status: 'guest',
                browsertype: platform.name,
                ipaddress: address
            }
            console.log(loginReqObj, 'loginReqObj')
            const authCheckRes = await GuestServicesapi.guestUsersLogin(loginReqObj);
            console.log(authCheckRes, 'authCheckRes')
            if (authCheckRes.error == "1") {
                await this.setState({ message: "Sorry, we couldn't find an account with that username.", color: 'red', loader: false, socailloader: false });
            } else {
                this.setState({ authCheckRes: authCheckRes, message: '', loader: false, verifyemaillabel: true, verifyotplabel: true })
                this.callOtp('email')
            }
        }
        else {
            this.setState({ message: 'Enter valid email ', color: 'red', loader: false });
        }
    }
    async resendOtpTimerFunc() {
        if (this.state.resendOtpTimer != 0) {
            await this.setState({ resendOtpTimer: this.state.resendOtpTimer - 1 });
            let _this = this;
            setTimeout(function () { _this.resendOtpTimerFunc(); }, 800);
        } else {
            this.setState({
                OtpType: 'email', validateOtp: true, loader: false,
                verifyemaillabel: false, verifyotplabel: false, otpStatus: true, phonecallStatus: false
            })
            this.nameInput.focus();
        }
    }
    async passwordLessLoginOtp() {
        await this.setState({ isOTPValid: false, loader: true })
        let verifyObject = {
            //client_id: this.state.clientId,
            // connection: "email",
            email: this.state.email.trim(),
            verification_code: this.state.passwordLessOtp
        }
        let sentotpverify = await ThirdPartyApiService.guestsentotpverify(verifyObject);
        if (sentotpverify == 'success') {
            this.getAuth0UserInfo();
        } else if (sentotpverify == 'wrong') {
            this.getAuth0UserInfo();
            this.setState({ isOTPValid: true, resendMsg: false, loader: false })
            
        }
        // const headers = { 'Content-Type': 'application/json' }
        // const body = JSON.stringify(verifyObject);
        // axios.post("https://" + authDetails.authCredentails().hostName + "/passwordless/verify", body, {
        //     headers: headers
        // }).then(response => {
        //     console.log(response, "response")
        //     // this.setstate({email:"suitetest3@yopmail.com",password:"Test#1234567"})
        //     // this.authentication()
        //     this.getAuth0UserInfo();
        //     // location.href = "/guest-login"

        // }).catch(error => {
        //     this.setState({ isOTPValid: true, resendMsg: false, loader: false })
        //     //   setTimeout(() => {
        //     //     this.setState({ isOTPValid: true })
        //     //   }, 3000)
        //     // console.log(error, "error")
        // })
    }
    async getAuth0UserInfo() {
        //  else if (authCheckRes.message.OrderId != 0 && authCheckRes.message.OrderId != null) {
        const guestInfoReqObj = {
            userData: this.state.authCheckRes.message,
            type: 'guest',
        }
        localStorage.setItem('guestSession', this.state.authCheckRes.message.sessionId)
         await SessionService.SessionStore(guestInfoReqObj);
        const GetResponse = await SessionService.SessionGet({ type: 'guest' });
        if (GetResponse.roleId == 2) {
            const guestDetails = {
                orderUid: GetResponse.OrderUId
            }
            var guestEmailObj = {
                guestEmail: GetResponse.GuestEmail,
                status: "active"
            }
            const GetOrdersByGuestEmailResponse = await GuestServicesapi.GetOrdersByGuestEmail(guestEmailObj)
            console.log(GetOrdersByGuestEmailResponse, 'GetOrdersByGuestEmailResponse')
            if (GetOrdersByGuestEmailResponse.message.Orders.length > 0) {
                const tripInfoData = await GuestServicesapi.GetTripInfo(guestDetails);
                if (tripInfoData == null) {
                    location.href = '/reservations'
                    //this.setState({ loader: false })
                } else {
                    location.href = '/reservations'
                    //this.setState({ loader: false })
                }
            } else {
                location.href = '/reservations'
                // this.setState({ loader: false })
            }

        } else {
            location.href = '/';
        }
    }
    _handleKeyDown1(e) {
        if (e.key === 'Enter') {
            this.authentication()
        }
    }
    _handleKeyDown2(e) {
        if (e.key === 'Enter') {
            e.preventDefault()
            this.passwordLessLoginOtp()
        }
    }
    render() {

        return (
            <div className="corporateHousing_Updates bg-grey">
                <GuestHeader />
                <div className="login-form-SA">
                    <div className="l-form">
                        <div className="item item2">
                            <div className="card-body">
                                {!this.state.verifyemaillabel && !this.state.verifyotplabel ? <div>
                                    <h1>{'Sign In to Account'}</h1>
                                    {this.state.message != '' && <div className="alert alert-danger" style={{ fontSize: '15px' }}>{this.state.message}</div>}
                                    <div className="form-group">
                                        <input onKeyPress={(e) => this._handleKeyDown1(e)} ref={(input) => { this.nameInput = input; }} type="text" className="form-control" id="email" placeholder="Email" value={this.state.email}
                                            onChange={(e) => this.setValue('email', e)} />
                                    </div>
                                    {this.state.loader ?
                                        <div className="panel-body_loader">
                                            <span style={{ fontSize: '20px', width: '100%', margin: 'auto', textAlign: 'center' }}><i className="fa fa-spinner fa-spin" />
                                                <span style={{ marginLeft: '10px' }}> please wait ...!</span> </span>
                                        </div> :
                                        <div>
                                            <button type="button" className="btn btn-primary" onClick={() => this.authentication()}>{'Passwordless Login'}</button>
                                        </div>}
                                </div> :
                                    // this.state.verifyemaillabel && !this.state.verifyotplabel ?
                                    //     <div>
                                    //         <h1>{'Verify your identity'}</h1>
                                    //         <ul className="verify-user">
                                    //             <li className="flex-grow-1" style={{ fontSize: '40px' }}>
                                    //                 <a href="#" style={{ fontSize: '16px' }} className="link-otp text-info" onClick={(e) => this.callOtp('email')}>
                                    //                     <i className="fa fa-envelope-o"></i>
                                    //                     &nbsp;Email {this.state.email.substring(0, 1)}xxxxxx@{this.state.email.split('@')[1]}</a> </li>
                                    //         </ul>
                                    //     </div> :
                                    (this.state.verifyemaillabel && this.state.verifyotplabel) ?
                                        <div>
                                            <h1>{'Verify your identity'}</h1>
                                            {this.state.isOTPValid&& <div className="alert alert-danger" style={{ fontSize: '15px' }}>{'Please enter valid verification code'}</div> }
                                            {this.state.resendMsg && <div className="alert alert-primary" style={{ color: '#0c5460', backgroundColor: '#d1ecf1', borderColor: '#bee5eb' }}>{'The OTP has been sent to your registered email address.'}</div> }
                                            <form>
                                                <div className="mb-4">
                                                    <div className="form-group otp-view">
                                                        <input onKeyPress={(e) => this._handleKeyDown2(e)} type="text" className="form-control" maxLength="10" placeholder="Enter OTP" onChange={(e) => this.passwordLessOtp(e)} value={this.state.passwordLessOtp} />
                                                    </div>
                                                    {this.state.loader ?
                                                        <div className="panel-body_loader">
                                                            <span style={{ fontSize: '20px', width: '100%', margin: 'auto', textAlign: 'center' }}><i className="fa fa-spinner fa-spin" />
                                                                <span style={{ marginLeft: '10px' }}> please wait ...!</span> </span>
                                                        </div> : <div>
                                                            {this.state.resendOtpTimer == 0 ? null :
                                                                <ul><li className="text-center">
                                                                    <div style={{ padding: "5px 20px", fontSize: "12px", borderRadius: "6px" }} className="alert-warning" disabled>Resend OTP link visible after ( {this.state.resendOtpTimer} secs)</div>
                                                                </li></ul>}
                                                            <button type="button" className="btn btn-primary" onClick={() => this.passwordLessLoginOtp()} disabled={this.state.passwordLessOtp.length > 5 ? false : true}>Verify</button>
                                                        </div>}
                                                </div>
                                            </form>
                                        </div> : ''}
                                <div className="m-login-registercls">
                                    Don't have a guest account with us?   <a href="/guest-registration">Register</a>
                                </div>
                            </div>

                        </div>
                        <div className="item item3" >
                            <div className="right-side">
                                <h1>Hello, Guest</h1>
                                <p>Don't have a guest account with us?</p>
                                <a href="/guest-registration" className="btn btn-primary">Register</a>
                            </div>
                        </div>
                    </div>
                </div>
                <SubFooter />
                <Footer />
                <SuccessModal />
            </div>
        );
    }
}
export default AuthLogin;