const Amenities=(props)=>{
  let communityname=props.communityInfo.Community
    return(
        <div>
        <div className=" mg-t-20 mg-b-40" id="AMENITIES" />
          <div className="dean_amenities">
            <h6>
              <span> &#9670; </span>  {props.communityInfo.AMENITIES.Heading}
              <span> &#9670; </span>
            </h6>
            <div className="row">
            {props.communityInfo.AMENITIES.Data.map((row,index)=>{
                return(
                  <div className="col-md-3 col-sm-6" key={index} >
                  <div className={row.ModalImage!=''?"iteamdeanam cursor-pointer":"iteamdeanam"}>
                    <img className={row.Name=='Clubhouse'?"img-responsive ":"img-responsive" } src={row.Image} data-toggle="modal"
                  data-target={row.ModalImage!=''?"#amenitesImages":''} onClick={row.ModalImage!=''?()=>props.amentiesModalPopup(row.ModalImage):null}/>
                    <h1>{row.Name}</h1>
                    <p>{row.Content}</p>
                  </div>
                </div>
                )
            })}
            </div>
            {props.communityResponseloader ? (
              <div className="row">
                <div className="col-md-6 col-sm-6">
                  <h1 className="text-left">Communal Amenities </h1>
                  {props.amenities(
                    props.communityResponse.AmenitiesList,
                    "Community"
                  )}
               {/* <p><b className="bold">{communityname=='The Dean'? '*Coming early 2020':''}</b></p> */}
                </div>
                
                <div className="col-md-6 col-sm-6">
                  <h1 className="text-left">Apartment Amenities </h1>
                  {props.amenities(
                    props.communityResponse.AmenitiesList,
                    "Unit"
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
            
            <div className="thedeanam-amenities-gallery">
            <img src="../images/altairImages/ameniti-1.png"/>
            <img src="../images/altairImages/ameniti-2.png"/>
            <img src="../images/altairImages/ameniti-3.png"/>
            <img src="../images/altairImages/ameniti-4.png"/>
            <img src="../images/altairImages/ameniti-5.png"/>
            <img src="../images/altairImages/ameniti-6.png"/>
          </div>
            {/* <p>
            {props.communityInfo.AMENITIES.BottomParagraph}
            </p> */}
          </div>
     
        </div>
    )
}
export default Amenities;