import React from 'react'
import Footer from './footer';
import Header from './header';
class CaseStudies3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  async componentDidMount() {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }
  }

  render() {

    return (
      <div className="SAweb_updateUi">
        <Header />
     
    <section className="SAweb_homeCaseStudies_Banner bg-white">

<div className="container CaseStudiesContainer">
  <div className="banner">
    <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/caseStudies/Casestudy_3_banner.jpg" alt="" width="100%" />
  </div>
</div>

</section>

<div className="SAweb_homeCaseStudiesHeading">
<div className="container CaseStudiesContainer">
  <h2>Bespoke solution for a year-long Intern program</h2>
</div>
</div>

<div className="SAweb_homeCaseStudiesContent bg-white">
<div className="container CaseStudiesContainer">
  <h3>Overview:</h3>
  <p>An existing client sought assistance with building a mobility program to attract overseas executives to their
U.S. headquarters.
  </p>

  <div className="sideImageGrid">
    <div className="item">
      <h3>Client-Required Elements and Goals:</h3>

      <ul>
        <li> Location: the housing had to be near their headquarters campus</li>
        <li>Local presence: the client desired a company with local
operations/support</li>
        <li>Program solution: the client was seeking ideas from
trusted vendor partners to create a bespoke solution
to encourage and entice existing employees to
relocate</li>
        <li>Long-term requirements: the solution focused on asking executives to commit 2+ years in the United States
</li>
      </ul>
    </div>

    <div className="item">
      <div className="pic">
        <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/caseStudies/Casestudy_3_subright.jpg" alt="" width="100%"/>
      </div>
    </div>
  </div>

  <h3>Strategy and Approach: </h3>
  <p>SuiteAmerica’s longevity in the temporary housing industry provides our team with deep knowledge and
understanding of the problem. We recognized that our client needed suggestions and collaboration from
multiple internal departments and vendors to create a comprehensive program. This program had to be
attractive to international executives and simultaneously produce the client's desired results. We
represented the housing portion of the solution and worked with other stakeholders to craft the right
program inclusions. We also collaborated with the involved vendor partners to collectively impact the
process and make it easy for the transferees to take up the long-term assignment.</p>

  <p>Our local team leader gathered data and facts about the rental market while our inventory team went about
sourcing properties that would "wow" an executive. Our properties were the highlight of the program,
bringing a valuable and highly relevant benefit into play that helped make the relocation decision easier for
each executive.
</p>

  <p>Our final proposal leveraged our local team as property managers for a cluster of private homes and
provided a cost-effective management solution for the client. SuiteAmerica was responsible for the ongoing
sourcing, furnishing, management, outfitting, and overall upkeep of the homes. We offered a customized
pricing model for this unique program that offered an economical way for the client to provide high-quality
housing for these high-visibility executives.
</p>

  <h3>Results: </h3>
  <p>A happy client satisfied with our cost-efficient and bespoke mobility program that allowed them to retain
their existing executives and attract new talent to the industry.
</p>

</div>
</div>
        <Footer />
      </div>
    );
  }
}
export default CaseStudies3;