import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import AppController from "../../controller/appController";
import BookingController from "../travelBooking/controllers/bookingController";
import BookitApiService from "../../../services/bookit";

import SearchAndListView from "./bookNow/views/SearchAndListView";
import Loader from "../../common/loader";
import CommunityImagesModal from "./modals/CommunityImagesModal";
import CommunityImagesWhiteModal from "./modals/CommunityImagesWhiteModal";
import BookPropertyModal from "./modals/BookPropertyModal";
import MoreDetailsAndBookView from "./bookNow/views/MoreDetailsAndBookView";

// import { getCommunities } from "./common/getCommunities";

const BookNow = () => {
  const cardData = [
    {
      id: 1,
      imageSrc: "assets/images/small/img-5.jpg",
      title: "THE CARSON",
      address: "2121 Nw Savier St",
      location: "PORTLAND, OR, OREGON",
      distance: "0",
    },
    {
      id: 2,
      imageSrc: "assets/images/small/img-1.jpg",
      title: "COURTYARD SAN JOSE",
      address: "10605 North Wolfe Road",
      location: "CUPERTINO, CA",
      distance: "0.24",
    },
    {
      id: 3,
      imageSrc: "assets/images/small/img-5.jpg",
      title: "THE CARSON",
      address: "2121 Nw Savier St",
      location: "PORTLAND, OR, OREGON",
      distance: "0",
    },
    {
      id: 4,
      imageSrc: "assets/images/small/img-1.jpg",
      title: "COURTYARD SAN JOSE",
      address: "10605 North Wolfe Road",
      location: "CUPERTINO, CA",
      distance: "0.24",
    },
  ];

  const [state, setState] = useState({
    isLoggedIn: null,
    isLoading: false,
    errorMessage: "",
    ClientProfileCardDetails: null,
    communities: [],
    communityImagesModalData: [],
    communityName: "",
  });

  const [propertyMoreDetailsData, setPropertyMoreDetailsData] = useState({
    checkIn: null,
    checkOut: null,
    selectedCommunity: {},
    selectedRoomType: "1X",
  });

  const [view, setView] = useState("search-list-view");

  const history = useHistory();

  function startLoader() {
    updateState({ isLoading: true });
  }

  function stopLoader() {
    updateState({ isLoading: false });
  }

  function changeView(view) {
    setView(view);
  }

  function changeToSearchListView() {
    changeView("search-list-view");
  }

  function changeToMoreDetailsAndBookView() {
    changeView("more-details-and-book-view");
  }

  async function isUserLoggedIn() {
    try {
      const sessionResponse = await BookitApiService.checkSession();
      if (!sessionResponse.loggedIn) {
        return false;
      }
      updateState({
        ClientProfileCardDetails: sessionResponse.ClientProfileCardDetails,
        email: sessionResponse.email,
      });
      return true;
    } catch (err) {
      return false;
    }
  }

  async function checkUserLoginStatus() {
    if (!(await isUserLoggedIn())) {
      updateState({ isLoggedIn: false });
      return;
    }
    updateState({ isLoggedIn: true });
  }

  function updateState(updateObj) {
    setState((prevState) => ({ ...prevState, ...updateObj }));
  }

  function updatePropertyMoreDetailsData(updateObj) {
    setPropertyMoreDetailsData((prevState) => ({ ...prevState, ...updateObj }));
  }

  function getProgramForUser() {
    let ClientProfileCardDetails = state.ClientProfileCardDetails;
    if (ClientProfileCardDetails) {
      let clientTier = ClientProfileCardDetails.message.clientTiers[0];
      let program = clientTier.Program;
      return program.split(",");
    }
    return [""];
  }

  function countDaysBetween(checkInDate, checkOutDate) {
    var date1 = new Date(checkInDate);
    var date2 = new Date(checkOutDate);
    var timeDiff = Math.abs(date2.getTime() - date1.getTime());
    let diffDate = Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1;

    return diffDate;
  }

  function validation(data) {
    updateState({ errorMessage: "" });

    let mandatoryFields = ["moveIn", "moveOut", "selectLocation"];
    let errors = [];

    mandatoryFields.forEach((field) => {
      if (!data[field]) errors.push(field);
    });

    if (errors.length) {
      updateState({
        errorMessage: `please select ${errors.join(", ")} fields`,
      });

      return false;
    }

    let checkIn = Date.parse(data.moveIn);
    let checkOut = Date.parse(data.moveOut);

    if (checkIn > checkOut) {
      updateState({
        errorMessage:
          "Check Out date should be greater than or equal to Check In date",
      });
      return false;
    }
    return true;
  }

  async function fetchCommunities(data) {
    let checkInDate = moment(data.moveIn, "MM/DD/YYYY"),
      checkOutDate = moment(data.moveOut, "MM/DD/YYYY");

    updatePropertyMoreDetailsData({
      checkIn: checkInDate,
      checkOut: checkOutDate,
    });

    let reqObj = {
      checkin: moment(data.moveIn).format("MM/DD/YYYY"),
      checkout: moment(data.moveOut).format("MM/DD/YYYY"),
      roomsize: data.size,
      latitude: data.latitude,
      longitude: data.longitude,
      complexamenities: [],
      allowpets: false,
      confirmedMoveOut: true,
      program: getProgramForUser(),
      // program: ["Apple Travel"],
      radius: 30,
      record_limit: 0,
      reqDays: countDaysBetween(checkInDate, checkOutDate),
      unitamenities: [],
    };

    if (validation(data)) {
      startLoader();
      try {
        let response = await BookitApiService.GetTravelAvailableMasters(reqObj);
        let communities = await includeWalkScore(response);
        communities = includeRents(communities, checkInDate, checkOutDate);
        updateState({ communities: communities });
      } catch (err) {}
      stopLoader();
    }
  }

  async function includeWalkScore(communitiesData) {
    for (let community of communitiesData) {
      const communityInfo = {
        address: community.address,
        latitude: community.latitude,
        longitude: community.longitude,
      };
      const walkscoreResponse = await AppController.wallsckoreFun(
        communityInfo
      );
      community.walkScore = walkscoreResponse.walkscore;
    }

    return communitiesData;
  }

  function includeRents(communitiesData, checkin, checkout) {
    let dailyrate;
    let size1xRate = BookingController.dailyRent("1X", checkin, checkout);
    let size2xRate = BookingController.dailyRent("2X", checkin, checkout);
    let size3xRate = BookingController.dailyRent("3X", checkin, checkout);
    let sizeSTURate = BookingController.dailyRent("STU", checkin, checkout);

    console.log(dailyrate, size1xRate, size2xRate, size3xRate, sizeSTURate);
    for (let community of communitiesData) {
      dailyrate = community.Avail1X
        ? size1xRate
        : community.Avail2X
        ? size2xRate
        : community.Avail3X
        ? size3xRate
        : community.STU
        ? sizeSTURate
        : size1xRate;
      community.dailyrate = dailyrate;
      community.size1xRate = size1xRate;
      community.size2xRate = size2xRate;
      community.size3xRate = size3xRate;
      community.sizeSTURate = sizeSTURate;
    }
    return communitiesData;
  }

  function changeCommunityImagesModalData(imagesList) {
    updateState({ communityImagesModalData: imagesList });
  }

  function changeCommunityName(community_name) {
    updateState({ communityName: community_name });
  }

  function clearCommunityImagesModalData() {
    updateState({ communityImagesModalData: [] });
  }

  useEffect(() => {
    checkUserLoginStatus();
  }, []);

  if (state.isLoggedIn === true) {
    return (
      <>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            {view === "search-list-view" && (
              <SearchAndListView
                state={state}
                fetchCommunities={fetchCommunities}
                changeCommunityImagesModalData={changeCommunityImagesModalData}
                changeCommunityName={changeCommunityName}
                propertyMoreDetailsData={propertyMoreDetailsData}
                updatePropertyMoreDetailsData={updatePropertyMoreDetailsData}
                changeToMoreDetailsAndBookView={changeToMoreDetailsAndBookView}
              />
            )}

            {view === "more-details-and-book-view" && (
              <MoreDetailsAndBookView
                changeToSearchListView={changeToSearchListView}
                propertyMoreDetailsData={propertyMoreDetailsData}
              />
            )}
          </div>
        </div>

        <CommunityImagesWhiteModal
          communityALLImages={state.communityImagesModalData}
          community_name={state.communityName}
        />
        {/* <CommunityImagesModal
          communityImages={state.communityImagesModalData}
          clearCommunityImagesModalData={clearCommunityImagesModalData}
        /> */}

        {/* <BookPropertyModal
          checkin={bookPropertyModalData.checkIn}
          checkout={bookPropertyModalData.checkOut}
          selectedCommunity={bookPropertyModalData.selectedCommunity}
          selectedRoomType={bookPropertyModalData.selectedRoomType}
        /> */}
      </>
    );
  } else if (state.isLoggedIn === false) {
    history.push("/SuiteCare");
  }
  return <Loader />;
};

export default BookNow;
