   const FilghtDetailsSuccessModal = () => {       
           return (               
                   <div className="modal fade savesearch" id="flight-details" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content rm-border-radius">
                            <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="">
                            <span>×</span>
                            </button>
                            </div>
                            <div className="modal-body">
                            <h2>Thank You!</h2>
                            <p>Filght details saved successfully</p>
                            </div>
                        </div>
                    </div>
                </div>
           );

   }
   export default FilghtDetailsSuccessModal;