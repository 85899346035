import React from 'react'
import Footer from './footer';
import TransHeader from './transheader';
import OwlCarousel from 'react-owl-carousel';
class PropertyOwners extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  async componentDidMount() {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }
    let divtext = ""
    divtext = $("#multiple-carousel").find(".owl-item.active").next()[0];
    $(divtext).addClass('secondchild')
    $('.owl-prev').on('click', function(e) {
      $('.SAweb_updateUi .owl-carousel.owl-drag .owl-item').removeClass('secondchild');
       divtext = $("#multiple-carousel").find(".owl-item.active").next()[0];
      $(divtext).addClass('secondchild')
      // console.log(divtext,'prev')
    });
    $('.owl-next').on('click', function(e) {
      $('.SAweb_updateUi .owl-carousel.owl-drag .owl-item').removeClass('secondchild');
       divtext = $("#multiple-carousel").find(".owl-item.active").next()[0];
      $(divtext).addClass('secondchild')
      //console.log(divtext,'next')
    });
    $("#multiple-carousel").on('mousemove', function() {
      $("#multiple-carousel").trigger('click');
      $('.SAweb_updateUi .owl-carousel.owl-drag .owl-item').removeClass('secondchild');
      divtext = $("#multiple-carousel").find(".owl-item.active").next()[0];
      $(divtext).addClass('secondchild');
      // console.log(divtext,'mousemove')
      // alert("You swiped mousemove!");
    });
    $("#multiple-carousel").on('mousemove', function() {
      $("#multiple-carousel").trigger('click');
      $('.SAweb_updateUi .owl-carousel.owl-drag .owl-item').removeClass('secondchild');
      divtext = $("#multiple-carousel").find(".owl-item.active").next()[0];
      $(divtext).addClass('secondchild');
      
    });
    // $("#multiple-carousel").on('touchstart', function() {
    //   $('.SAweb_updateUi .owl-carousel.owl-drag .owl-item').removeClass('secondchild');
    //   divtext = $("#multiple-carousel").find(".owl-item.active").next()[0];
    //   $(divtext).addClass('secondchild');
    //   // console.log(divtext,'touchmove')
  
    //   })
    //   $(document).on('touchstart', function(event){
    //     $("#multiple-carousel").swiperight(function() {
    // alert("You swiped !");
             
    //      });
    //  });
      // $("#multiple-carousel").on('touchmove', function() {
      //   $('.SAweb_updateUi .owl-carousel.owl-drag .owl-item').removeClass('secondchild');
      //   divtext = $("#multiple-carousel").find(".owl-item.active").next()[0];
      //   $(divtext).addClass('secondchild');
      //   // console.log(divtext,'touchmove')
    
      //   })
    // $("#multiple-carousel").on('swipe', function() {

    // })

  }
 

  render() {
    return (
      <div className="SAweb_updateUi">
        <TransHeader />
    
<section className=" SAwebGuest_homeBanner">
  <div className="fixedNavSpace"></div>
<div className="container">
<div className="textView" >
  <h1  data-aos="fade-up"> Sit back. Collect rent. </h1>
       <p  data-aos="fade-up" className="p">  When you sign a lease with us, you'll enjoy the benefits of hands-off, full-service property management and care.  </p>


      </div>  
</div>

</section>

<section className="SAwebGuest_homeBannerBottom">
  <div className="container">
        <div className="box">
          <h2>Better than full-service property management</h2>

          <p >
            We take care of your home like it's our own. We design, furnish, and rent it out for long-term stays, 
            Seamless and easy for you, just sit back and collect rent and we'll take care of the rest.</p>
        </div>
  </div>
</section>

<section className="SAwebGuest_homefindTheans bg-white">
  <div className="container">
    
          <h3>Find the answers to anything you need in our Help Center</h3>

          <div className="tails">
            <div className="item">
              <div className="pic">
                <img src="assets/newUI-images/guestportal/heart.png" alt="" width="100%"/>
              </div>
                <p>Complete Peace of Mind</p>
                <i className="bi bi-chevron-down"></i>
            </div>
            <div className="item active">
              <div className="pic">   <img src="assets/newUI-images/guestportal/qualityHome.png" alt="" width="100%"/></div>
                <p>High Quality Residents </p>
                <i className="bi bi-chevron-down"></i>
            </div>
            <div className="item">
              <div className="pic">   <img src="assets/newUI-images/guestportal/hand-shake.png" alt="" width="100%"/></div>
                <p>Better Than A Property Manager</p>
                <i className="bi bi-chevron-down"></i>
            </div>
          </div>
  </div>
</section>

<section className="SAwebGuest_homeEastProcess circleImage">
      <div className="container">
        <h3>Our easy, no-hassle process</h3>
        <div className="boxes">
          <div className="circleBox">
            <div className="number">01</div>
            <p>See What Your Property Makes</p>
          </div>
          <div className="circleBox second">
            <div className="number">02</div>
            <p>Sign A Lease</p>
          </div>
          <div className="circleBox third">
            <div className="number">03</div>
            <p>Furnishing & Decor</p>
          </div>
          <div className="circleBox fourth">
            <div className="number">04</div>
            <p>Professional Cleaning & Maintenance</p>
          </div>
        </div>
      </div>
    </section>

<section className="SAwebGuest_homeSlider" data-aos="fade-up">

  <h2>All facilities are designed to meet all your needs</h2>

      <div >
      <OwlCarousel id="multiple-carousel" className="owl-theme owl-carousel" 
             {...{ loop: false,margin:20, nav:true, dots: false, items: 1,
             navContainerClass: 'owl-buttons',
             navText: ["<i className='bi bi-arrow-left'></i>",
             "<i className='bi bi-arrow-right'></i>"],
            responsive:{
             767:{ items:2,slideBy: 1},
             991:{ items:3,slideBy: 1}, 
            1099:{ items:4,slideBy: 1}}}}> 
           <div className="item" >
             <div className="pic"><img src="assets/newUI-images/guestportal/slider-search.png" alt="" width="100%"/></div>
             <h4>Through Inspection </h4>
             <p>We install Wi-Fi, digital locks, 
              and make other minor 
              upgrades-at no cost to you.</p>
           </div>
           <div className="item">
            <div className="pic"><img src="assets/newUI-images/guestportal/slider-touch.png" alt="" width="100%"/></div>
            <h4>Final Touches </h4>
            <p>We install Wi-Fi, digital locks, 
              and make other minor 
              upgrades-at no cost to you. </p>
          </div>
          <div className="item">
            <div className="pic"><img src="assets/newUI-images/guestportal/slider-rate.png" alt="" width="100%"/></div>
            <h4>First Rate Furnishing </h4>
            <p>We install Wi-Fi, digital locks, 
              and make other minor 
              upgrades-at no cost to you.</p>
          </div>
          <div className="item">
            <div className="pic"><img src="assets/newUI-images/guestportal/slider-maintained.png" alt="" width="100%"/></div>
            <h4>Well Maintained </h4>
            <p>We install Wi-Fi, digital locks, 
              and make other minor 
              upgrades-at no cost to you.</p>
          </div>

          <div className="item">
            <div className="pic"><img src="assets/newUI-images/guestportal/slider-search.png" alt="" width="100%"/></div>
            <h4>Through Inspection </h4>
            <p>We install Wi-Fi, digital locks, 
             and make other minor 
             upgrades-at no cost to you.</p>
          </div>
          <div className="item">
           <div className="pic"><img src="assets/newUI-images/guestportal/slider-touch.png" alt="" width="100%"/></div>
           <h4>Final Touches </h4>
           <p>We install Wi-Fi, digital locks, 
             and make other minor 
             upgrades-at no cost to you. </p>
         </div>
         <div className="item">
           <div className="pic"><img src="assets/newUI-images/guestportal/slider-rate.png" alt="" width="100%"/></div>
           <h4>First Rate Furnishing </h4>
           <p>We install Wi-Fi, digital locks, 
             and make other minor 
             upgrades-at no cost to you.</p>
         </div>
         <div className="item">
           <div className="pic"><img src="assets/newUI-images/guestportal/slider-maintained.png" alt="" width="100%"/></div>
           <h4>Well Maintained </h4>
           <p>We install Wi-Fi, digital locks, 
             and make other minor 
             upgrades-at no cost to you.</p>
         </div>
         </OwlCarousel>


      </div>
   
</section>


<section className="SAwebGuest_homeNewsletter">

  <div className="box">
    <div className="item">  <i className="bi bi-send-fill"></i></div>
    <div className="item">
      <p>Subscribe to Our Newsletter</p>
      <span>Get the latest news and interesting offers.</span>
      <form action="#" method="Post">
				<input type="text" name="text" placeholder="Enter e-mail address"/>
				<button type="button" className="btn black-btn">Subscribe</button>
			</form>
    </div>
  </div>

</section>
        <Footer />

      </div>
    );
  }
}
export default PropertyOwners;
    