/* Importing the node modules, child components, services and controllers used 
   inside NikeApprenticeshipHeader component */
   import {Link} from 'react-router-dom';
   import MainController from '../controller/mainController';
   /* NikeApprenticeshipHeader Component initialization */
   class NikeApprenticeshipHeader extends React.Component {
       /* Initializing objects of its NikeApprenticeshipHeader class */
       constructor(props) {
           super(props)
           /* NikeApprenticeshipHeader Component State variables Initialization */
           this.state = {
               name: '',
               company: '',
               location: '',
               phone: '',
               email: '',
               bookingDetails: {},
               BookingData: {}
           }
       }
       /* It is invoked immediately after a component is mounted */
       componentDidMount() {
           MainController.removeClassOnNavCollapse();
       }
       /* It is invoked to return html content */  
       render() {
           return (
            <div>
               <div>
            <div className="profile_submenu">
               <div className="container">
                  <nav className="navbar navbar-inverse">
                     <div className="navbar-header">
                        <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#guest-login" aria-expanded="false">
                        <span className="sr-only">Toggle navigation</span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        </button>
                        <a href="https://nike.com" target="_blank"><img src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Company-Nike-Logo.png"} alt="" className="nike_list_img_mo" /></a>
                     </div>
                     <div className="collapse navbar-collapse" id="guest-login">
                        <ul className="nav navbar-nav">
                           <li><Link to="/company/nike-apprenticeship/home">Home</Link></li>
                           <li><Link to="/company/nike-apprenticeship/communities">Communities</Link></li>
                           <li><Link to="/company/nike-apprenticeship/apartment-tour">Apartment Tour</Link></li>
                           <li><Link to="/company/nike-apprenticeship/faq">FAQ's</Link></li>
                           <li><Link to="/company/nike-apprenticeship/questionnaire">Questionnaire</Link></li>
                           <li><Link to="/company/nike-apprenticeship/contact-us">Contact Us</Link></li>
                        </ul>
                        <a href="https://nike.com" target="_blank"><img src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Company-Nike-Logo.png"} alt="" className="nike_list_img" /></a>
                     </div>
                  </nav>
               </div>
            </div>            
         </div>  
          <div className='subHeaderHeight'></div>
          </div>
        );
    }
}
export default NikeApprenticeshipHeader;