/* Importing the node modules, child components, services and controllers used 
   inside adobeHome component */    
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import AdobeHeader from "./common/adobeHeader";
   import {Link} from 'react-router-dom';
   import FAQ from "./common/faq";
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   /* adobeHome Component initialization */
   class AdobeHome extends React.Component {
       /* Initializing objects of its adobeHome class */
       constructor(props) {
           super(props);
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
           MainController.getInitialInformation();
        }
       /* It is invoked to return html content */
       render() {
        let img='../../assets/images/Adobe-logo-1.png'
           return (
            <div className="corporateHousing_Updates">
                <GuestHeader/>
                <header className="codeconveyHeader cc_image_text_main corporate-h">
                    <img className="img-responsive" src={"https://suiteam-website.s3-us-west-1.amazonaws.com/landing-images/Adobe/Adobe_building.jpg"} width="100%" alt=""  />
                    <div className="wow fadeInUp img-on-text top30 width500 text-center left50 img-on-text-center company_bannertxt" data-wow-delay="0.5s">
                        <img src={img} className="serviceNow_img" width="150px" alt="" />
                        <h1>SuiteAmerica is Proud to Offer Temporary Housing to Adobe Employees</h1>
                        <p>Whether You’re an Individual or a Group, 
                            We Can Take Care of Your Housing Needs.
                        </p>
                    </div>
                </header>
                <AdobeHeader />
                <div className="quote-section bg-white">
                    <div className="container">
                        <div className=" text-center col-md-10 col-md-offset-1 wow fadeInUp company_p" data-wow-delay="0.5s">
                            <p>These fully furnished and equipped apartments are offered at daily rates and are larger and better suited for long 
                            term stay as opposed to hotels. Averaging 1,000 sq. ft., SuiteAmerica’s corporate apartments are fully furnished 
                            and offer the amenities of a home, making long term stays as comfortable as possible. As a preferred vendor to 
                            adobe, SuiteAmerica is here to help you with your temporary housing needs and can take your reservations directly.
                            </p>
                            <h1 className="gotham-book mrg-top-80 mrg-btm-80">STAYING WITH SUITEAMERICA IS EASY!</h1>
                        </div>
                    </div>
                    <div className="who_weare_text service_ch_new company_p_height">
                        <div className="container">
                            <div className="row rows">
                            <div className="col-md-6 col-sm-6">
                                <div className="wow fadeInUp company_p dual_quote" data-wow-delay="0.5s">
                                    <p>Simply visit our locations page to see the various 
                                        communities available for your temporary stay 
                                        in adobe’s key areas.
                                    </p>
                                    <Link to="/company/adobe/locations" className="box-slide-btn">VIEW LOCATIONS</Link>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <div className="wow fadeInUp company_p dual_quote" data-wow-delay="0.5s">
                                    <p>Once you have chosen or would like more information 
                                        about other communities, click on the Reservation 
                                        Form to send us your information or Contact your 
                                        SuiteAmerica Representative.
                                    </p>
                                    <Link to="/company/adobe/reservation-form" className="box-slide-btn">RESERVE NOW</Link>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid bg-grey">
                    <div className="container">
                        <FAQ />
                    </div>
                </div>
                <SubFooter/>
                <Footer/>
            </div>   
           );
       }
   }
   export default AdobeHome;