/* Importing the node modules, child components, services and controllers used 
   inside IntranetCustomRequestfive component */   
   import TravelBookingHeader from '../../common/travelBookingHeader';
   import TravelBookingFooter from '../../common/travelBookingFooter';
   /* IntranetCustomRequestfive Component initialization */
   class SuccessPage extends React.Component{
       /* Initializing objects of its IntranetCustomRequestfive class */
       constructor(props) {
           super(props)
         }
   /* It is invoked to return html content */      
   render(){
     
       const url=this.props.match.path
       return(
           <div>
        <TravelBookingHeader/>
<div className="inet_custom_last">
{url=='/travel-booking-guestsuccess'? <div className="container">
   <h1 className="wow animated zoomIn">Thank you for selecting a Corporate Apartment.</h1>
   <p className="wow animated fadeInUp">The Apple Travel Extended Stay Desk will be in contact with you shortly to finalize your Corporate Apartment reservation.</p>
</div>:''}
{url=='/travel-booking-adminsuccess'? <div className="container">
<h1 className="wow animated zoomIn">Thank you for booking a Suite</h1>
{/* <p className="wow animated fadeInUp">The Apple Travel Extended Stay Desk will be in contact with you shortly to finalize your Corporate Apartment reservation.</p> */}
</div>:''}
{url=='/travel-booking-alreadysent' ? <div className="container">
   <h1 className="wow animated zoomIn">Thank you, your suite has been already placed.</h1>
   <p className="wow animated fadeInUp">Please check your mail or Phone for confirmation.</p>
</div>:''}
{url=='/travel-booking-quote' ? <div className="container">
   <h1 className="wow animated zoomIn">Thank you, Your Suite Request is placed.</h1>
   <p className="wow animated fadeInUp">You will receive the confirmation of your stay shortly!</p>
</div>:''}
{url=='/travel-booking-dateexpire' ? <div className="container">
<h1 className="wow animated zoomIn">Booking invitation is expired</h1>
<p className="wow animated fadeInUp">Please contact extendedstay@apple.com for any assistance.</p>
</div>:''}
</div>
<TravelBookingFooter/>
</div> 
       );
   }
   }
   export default SuccessPage;