import React from "react";
import BookitApiService from "../../../../services/bookit";

import { useHistory } from "react-router-dom";

const NewHeader = () => {
  const history = useHistory();

  async function logout() {
    await BookitApiService.deleteSession();
    history.push("/SuiteCare");
  }
  function openNav() {
    document.getElementById("wrapper").classList.add("enlarged");
  }

  return (
    <div>
      <div className="topbar">
        <nav className="navbar-custom">
          <ul className="list-inline float-right mb-0">
            {/* <li className="list-inline-item dropdown notification-list hide-phone">
            <a
              className="nav-link dropdown-toggle arrow-none waves-effect text-dark"
              data-toggle="dropdown"
              href="#"
              role="button"
              aria-haspopup="false"
              aria-expanded="false"
            >
              English
              <img
                src="assets/images/flags/us_flag.jpg"
                className="ms-2"
                height="16"
                alt=""
              />
            </a>
            <div className="dropdown-menu dropdown-menu-right language-switch">
              <a className="dropdown-item" href="#">
                <img
                  src="assets/images/flags/italy_flag.jpg"
                  alt=""
                  height="16"
                />
                <span> Italian </span>
              </a>
              <a className="dropdown-item" href="#">
                <img
                  src="assets/images/flags/french_flag.jpg"
                  alt=""
                  height="16"
                />
                <span> French </span>
              </a>
              <a className="dropdown-item" href="#">
                <img
                  src="assets/images/flags/spain_flag.jpg"
                  alt=""
                  height="16"
                />
                <span> Spanish </span>
              </a>
              <a className="dropdown-item" href="#">
                <img
                  src="assets/images/flags/russia_flag.jpg"
                  alt=""
                  height="16"
                />
                <span> Russian </span>
              </a>
            </div>
          </li> */}
            {/* <li className="list-inline-item dropdown notification-list">
            <a
              className="nav-link dropdown-toggle arrow-none waves-effect"
              data-toggle="dropdown"
              href="#"
              role="button"
              aria-haspopup="false"
              aria-expanded="false"
            >
              <i className="ti-email noti-icon"></i>
              <span className="badge badge-danger noti-icon-badge">5</span>
            </a>
            <div className="dropdown-menu dropdown-menu-right dropdown-arrow dropdown-menu-lg">
              <div className="dropdown-item noti-title">
                <h5>
                  <span className="badge badge-danger float-right">745</span>
                  Messages
                </h5>
              </div>

              <a
                // href="javascript:void(0);"
                className="dropdown-item notify-item"
              >
                <div className="notify-icon">
                  <img
                    src="assets/images/users/avatar-2.jpg"
                    alt="user-img"
                    className="img-fluid rounded-circle"
                  />
                </div>
                <p className="notify-details">
                  <b>Charles M. Jones</b>
                  <small className="text-muted">
                    Dummy text of the printing and typesetting industry.
                  </small>
                </p>
              </a>

              <a
                // href="javascript:void(0);"
                className="dropdown-item notify-item"
              >
                <div className="notify-icon">
                  <img
                    src="assets/images/users/avatar-3.jpg"
                    alt="user-img"
                    className="img-fluid rounded-circle"
                  />
                </div>
                <p className="notify-details">
                  <b>Thomas J. Mimms</b>
                  <small className="text-muted">
                    You have 87 unread messages
                  </small>
                </p>
              </a>

              <a
                // href="javascript:void(0);"
                className="dropdown-item notify-item"
              >
                <div className="notify-icon">
                  <img
                    src="assets/images/users/avatar-4.jpg"
                    alt="user-img"
                    className="img-fluid rounded-circle"
                  />
                </div>
                <p className="notify-details">
                  <b>Luis M. Konrad</b>
                  <small className="text-muted">
                    It is a long established fact that a reader will
                  </small>
                </p>
              </a>

              <a
                // href="javascript:void(0);"
                className="dropdown-item notify-item"
              >
                View All
              </a>
            </div>
          </li> */}

            {/* <li className="list-inline-item dropdown notification-list">
            <a
              className="nav-link dropdown-toggle arrow-none waves-effect"
              data-toggle="dropdown"
              href="#"
              role="button"
              aria-haspopup="false"
              aria-expanded="false"
            >
              <i className="ti-bell noti-icon"></i>
              <span className="badge badge-success noti-icon-badge">23</span>
            </a>
            <div className="dropdown-menu dropdown-menu-right dropdown-arrow dropdown-menu-lg">
              <div className="dropdown-item noti-title">
                <h5>
                  <span className="badge badge-danger float-right">87</span>
                  Notification
                </h5>
              </div>

              <a
                // href="javascript:void(0);"
                className="dropdown-item notify-item"
              >
                <div className="notify-icon bg-primary">
                  <i className="mdi mdi-cart-outline"></i>
                </div>
                <p className="notify-details">
                  <b>Your order is placed</b>
                  <small className="text-muted">
                    Dummy text of the printing and typesetting industry.
                  </small>
                </p>
              </a>

              <a
                // href="javascript:void(0);"
                className="dropdown-item notify-item"
              >
                <div className="notify-icon bg-success">
                  <i className="mdi mdi-message"></i>
                </div>
                <p className="notify-details">
                  <b>New Message received</b>
                  <small className="text-muted">
                    You have 87 unread messages
                  </small>
                </p>
              </a>

              <a
                // href="javascript:void(0);"
                className="dropdown-item notify-item"
              >
                <div className="notify-icon bg-warning">
                  <i className="mdi mdi-martini"></i>
                </div>
                <p className="notify-details">
                  <b>Your item is shipped</b>
                  <small className="text-muted">
                    It is a long established fact that a reader will
                  </small>
                </p>
              </a>

              <a
                // href="javascript:void(0);"
                className="dropdown-item notify-item"
              >
                View All
              </a>
            </div>
          </li> */}

            <li className="list-inline-item dropdown notification-list">
              <a
                className="nav-link dropdown-toggle arrow-none waves-effect nav-user "
                data-toggle="dropdown "
                href="#"
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
              >
                {/* <img
                src="assets/images/users/avatar-1.jpg"
                alt="user"
                className="rounded-circle"
              /> */}
                <i
                  className="mdi mdi-logout m-r-5 text-muted bookit-header-logout"
                  onClick={logout}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Logout"
                ></i>
              </a>

              {/* <div className="dropdown-menu dropdown-menu-right profile-dropdown">
              <div className="dropdown-item noti-title">
                <h5>Welcome</h5>
              </div>
              <a className="dropdown-item" href="#">
                <i className="mdi mdi-account-circle m-r-5 text-muted"></i>
                Profile
              </a>
              <a className="dropdown-item" href="#">
                <i className="mdi mdi-wallet m-r-5 text-muted"></i> My Wallet
              </a>
              <a className="dropdown-item" href="#">
                <span className="badge badge-success float-right">5</span>
                <i className="mdi mdi-settings m-r-5 text-muted"></i>
                Settings
              </a>
              <a className="dropdown-item" href="#">
                <i className="mdi mdi-lock-open-outline m-r-5 text-muted"></i>
                Lock screen
              </a>
              <div className="dropdown-divider"></div>
              <a className="dropdown-item" href="#" onClick={logout}>
                <i className="mdi mdi-logout m-r-5 text-muted"></i> Logout
              </a>
            </div> */}
            </li>
          </ul>

          <ul className="list-inline menu-left mb-0">
            <li className="float-left">
              <button
                className="button-menu-mobile open-left waves-light waves-effect"
                onClick={openNav}
              >
                <i className="mdi mdi-menu"></i>
              </button>
            </li>
            <li className="hide-phone app-search">
              {/* <form role="search" className="">
              <input
                type="text"
                placeholder="Search..."
                className="form-control"
              />
              <a href="">
                <i className="fa fa-search"></i>
              </a>
            </form> */}
            </li>
          </ul>
          <div className="clearfix"></div>
        </nav>
      </div>
      <div className="topbarHeight"></div>
    </div>
  );
};

export default NewHeader;
