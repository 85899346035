import $ from "jquery";
import AppController from "../../controller/appController";
import { Link } from "react-router-dom";
import TravelBookingApiService from "../../../services/travelBookingApiService";
import TravelBookingFooter from "../../common/travelBookingFooter";
import moment from "moment";
import StarbucksBookingHeader from "./starbucksBookingHeader";
import ReactDatePicker from "react-datepicker";
import _ from "lodash";
import StarbucksController from "./controllers/starbucksController";
import ClientApiService from "../../../services/clientApiService";

class Inventory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      MastersByProgram: [],
      defaultdaterange: [...Array(90)],
      modelCheckIn: null,
      modelCheckOut: null,
      showMsg: false,
      showBookNow: false,
      loadingStatus: false,
      AvailableBillableMasters: [],
      firstName: "",
      lastName: "",
      phoneNo: "",
      emailId: "",
      reservedbyemail: "",
      purchaseorder: "",
      Flight: "",
      ArrivalAirport: "",
      ArrivalDate: null,
      Airline: "",
      notes: "",
      pnr: "",
      type: "",
      showValidation: false,
      showDatesValid: false,
      showsuccmsg: "",
      emailExtenderror:''
    };
    this.setNumber = this.setNumber.bind(this);
    this.selectionArrivalDate = this.selectionArrivalDate.bind(this);
    this.bookDetails = this.bookDetails.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.getCommunities = this.getCommunities.bind(this);
    this.onChangevalue = this.onChangevalue.bind(this);
    this.closeModalpopUp = this.closeModalpopUp.bind(this);
  }
  async componentDidMount() {
    this.closeModalpopUp();
  }
  renderMonthHeaders(defaultdaterange) {
    let month = "";
    let startIndex = 0;
    let jsx = defaultdaterange.map((row, index) => {
      if (index == 0) {
        month = moment().format("MMMM");
      } else {
        let currentMonth = moment().add(index, "days").format("MMMM");
        // FOR THE LAST MONTH
        if (currentMonth == month) {
          if (index + 1 == defaultdaterange.length) {
            return (
              <th
                className="text-center"
                colSpan={index + 1 - startIndex}
                key={index}
              >
                {currentMonth}
              </th>
            );
          }
        } else {
          let prevMonth = JSON.parse(JSON.stringify(month));
          let tempIndex = JSON.parse(JSON.stringify(startIndex));
          month = currentMonth;
          startIndex = JSON.parse(JSON.stringify(index));
          return (
            <th className="text-center" colSpan={index - tempIndex} key={index}>
              {prevMonth}
            </th>
          );
        }
      }
    });

    return jsx;
  }

  setValue(field, e) {
    var object = {};
    object[field] = e.target.value;
    this.setState(object);
  }
  onChangevalue(e) {
    const re = /^[a-zA-Z0-9]+$/;
    if (e.target.value == "" || re.test(e.target.value)) {
      this.setState({ purchaseorder: e.target.value });
    }
  }
  async handleGraphicalView() {
    await this.setState({
      showBookNow: false,
      loadingStatus: false,
      showMsg: false,
      emailExtenderror:'',
      firstName: "",
      lastName: "",
      emailId: "",
      phoneNo: "",
      modelCheckIn: null,
      modelCheckOut: null,
      AvailableBillableMasters: [],
      Airline: "",
      Flight: "",
      pnr: "",
      reservedbyemail: "",
      purchaseorder: "",
      showDatesValid: false,
      showValidation: false,
      showsuccmsg: "",
    });

    $(document).ready(function () {
      jQuery(function ($) {
        $("#travelbooknow").modal("show");
      });
    });
    localStorage.setItem(
      "expireTime",
      Base64.encode(moment().format("DD/MM/YYYY HH:mm:ss"))
    );
  }
  async getCommunities() {
    await this.setState({ showMsg: false,emailExtenderror:'', showBookNow: false });
    if (this.state.modelCheckIn == null || this.state.modelCheckOut == null) {
      await this.setState({ showMsg: false,emailExtenderror:'', showDatesValid: true });
    } else {
      await this.setState({ loadingStatus: true });
      let requestObject = {
        program: ["Umpqua"],
        checkin: moment(this.state.modelCheckIn).format("MM/DD/YYYY"),
        checkout: moment(this.state.modelCheckOut).format("MM/DD/YYYY"),
        record_limit: 0,
        radius: 0,
        roomsize: "1X",
        reqDays: 0,
        allowpets: false,
        complexamenities: [],
        unitamenities: [],
        latitude:
          localStorage.getItem("starbucksaddressLat") != null &&
          localStorage.getItem("starbucksaddressLat") != undefined
            ? Number(localStorage.getItem("starbucksaddressLat"))
            : 47.6227512,
        longitude:
          localStorage.getItem("starbucksaddressLng") != null &&
          localStorage.getItem("starbucksaddressLng") != undefined
            ? Number(localStorage.getItem("starbucksaddressLng"))
            : -122.3361687,
        confirmedMoveOut: true,
      };
      console.log("requestObject", requestObject);
      const Data =
        await TravelBookingApiService.GetTravelAvailableBillableMasters(
          requestObject
        );
      if (Data.length > 0) {
        await this.setState({
          loadingStatus: false,
          AvailableBillableMasters: Data,
          showBookNow: true,
        });
      } else {
        await this.setState({
          showDatesValid: false,
          showValidation: true,
          loadingStatus: false,
        });
      }
      $(document).ready(function () {
        jQuery(function ($) {
          $("#phone3").intlTelInput();
        });
      });
    }
    localStorage.setItem(
      "expireTime",
      Base64.encode(moment().format("DD/MM/YYYY HH:mm:ss"))
    );
  }
  async closeModal() {
    await this.setState({
      showBookNow: false,
      loadingStatus: false,
      showMsg: false,
      emailExtenderror:'',
      firstName: "",
      lastName: "",
      emailId: "",
      phoneNo: "",
      modelCheckIn: null,
      modelCheckOut: null,
      AvailableBillableMasters: [],
      Airline: "",
      Flight: "",
      pnr: "",
      purchaseorder: "",
      showDatesValid: false,
     
    });
  }

  async closeModalpopUp() {
    this.setState({ MastersByProgram: [] });
    let reqObj = {
      program: "Umpqua",
    };
    let MastersByProgram = await ClientApiService.getInventoryByProgram(reqObj);
    await this.setState({
      MastersByProgram:
        Array.isArray(MastersByProgram.message) == true
          ? MastersByProgram.message
          : [],
    });
  }

  async selectionArrivalDate(date) {
    await this.setState({ ArrivalDate: moment(date) });
  }
  async setNumber(e) {
    const re = /^[0-9\b]+$/;
    if (e.target.value == "" || re.test(e.target.value)) {
      this.setState({ phoneNo: e.target.value });
    }
  }
  async bookDetails() {
    await this.setState({ loadingStatus: true });
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    
    const phoneReg = /^[0-9\b]+$/;
    let error = 0;
    if (
      this.state.firstName == "" ||
      this.state.lastName == "" ||
      this.state.emailId == "" ||
      this.state.phoneNo == "" ||
      this.state.reservedbyemail == ""
    ) {
      error++;
    }
    if (!reg.test(this.state.emailId)) {
      error++;
    }
    if (!reg.test(this.state.reservedbyemail)) {
      error++;
      this.setState({ emailExtenderror:''});
    }else if(!this.state.reservedbyemail.includes('@umpquabank.com')){
      this.setState({ emailExtenderror:'Invalid email id. (The extension must be @umpquabank.com)'});
      error++;
    }else{
      this.setState({ emailExtenderror:''});
    }
    if (!phoneReg.test(this.state.phoneNo) || this.state.phoneNo.length < 10) {
      error++;
    }
    if (error != 0) {
        if(this.state.emailExtenderror !== ''){
          this.setState({ showMsg: false,loadingStatus: false });
        }else{
          this.setState({ showMsg: true,emailExtenderror:'', loadingStatus: false });
        }
    } else {
      this.setState({ showMsg: true})
      const Code =
        $(".country.highlight.active").attr("data-dial-code") == undefined
          ? "+1"
          : "+" + $(".country.highlight.active").attr("data-dial-code");

      var Request = {
        firstName: this.state.firstName == "" ? "" : this.state.firstName,
        lastName: this.state.lastName == "" ? "" : this.state.lastName,
        emailId: this.state.emailId == "" ? "" : this.state.emailId,
        phoneNo: this.state.phoneNo == "" ? "" : Code + this.state.phoneNo,
        mail: "travelBooking",
        subject: "Travel Booking",
        checkIn: this.state.modelCheckIn.format("MM-DD-YYYY"),
        checkOut: this.state.modelCheckOut.format("MM-DD-YYYY"),
        communityName: this.state.AvailableBillableMasters.map(
          (val) => val.community_name
        )[0],
        city_name: this.state.AvailableBillableMasters.map(
          (val) => val.city_name
        )[0],
        airline: this.state.Airline == "" ? "" : this.state.Airline,
        flight: this.state.Flight == "" ? "" : this.state.Flight,
        pnr: this.state.pnr == "" ? "" : this.state.pnr,
        type: this.state.type == "" ? "" : this.state.type,
        ordertype: "admin",
        bookingType: 0,
        status: 0,
      };
      const phonenumber = AppController.formatPhoneNumber(
        this.state.phoneNo == "" ? "" : this.state.phoneNo
      );
      const dailyrate = StarbucksController.dailyRent(
        "1X",
        this.state.modelCheckIn,
        this.state.modelCheckOut
      );
      const clientDetails = "29660";
      const userLoginEmailEncode = localStorage.getItem("umpqua-email");
      const userLoginEmail =
        userLoginEmailEncode != undefined || userLoginEmailEncode != null
          ? Base64.decode(userLoginEmailEncode)
          : "";

      const bookingRequest = {
        program: ["Umpqua"],
        clientId: clientDetails,
        record_limit: 0,
        radius: 30,
        checkin: this.state.modelCheckIn.format("MM-DD-YYYY"),
        checkout: this.state.modelCheckOut.format("MM-DD-YYYY"),
        resident_firstname:
          this.state.firstName == "" ? "" : this.state.firstName,
        resident_lastname: this.state.lastName == "" ? "" : this.state.lastName,
        resident_middlename: "",
        resident_email: this.state.emailId == "" ? "" : this.state.emailId,
        resident_phone: phonenumber,
        community_id: this.state.AvailableBillableMasters.map(
          (val) => val.community_id
        )[0],
        countrycode: Code,
        dailyrate: 0,
        airline: this.state.Airline,
        flight: this.state.Flight,
        type: this.state.type,
        pnr: this.state.pnr,
        source: "ABE",
        changedby: userLoginEmail != "" ? userLoginEmail : this.state.emailId,
        todays: true,
        reservedby: "",
        reservedbyemail: this.state.reservedbyemail,
        purchaseorder: this.state.purchaseorder,
        checkintime: "3:00 PM",
        Checkouttime: "10:00 AM",
        maid: 1,
        freq: "WEE",
        entry: "Meet & Greet",
        roomsize: "1X",
        latitude: Number(
          this.state.AvailableBillableMasters.map((val) => val.latitude)[0]
        ),
        longitude: Number(
          this.state.AvailableBillableMasters.map((val) => val.longitude)[0]
        ),
        reqDays: 0,
        confirmedMoveOut: true,
        roleType: 2,
      };
      console.log(bookingRequest, "bookingRequest");
      const response = await TravelBookingApiService.CreateTravelBillableOrder(
        bookingRequest
      );

      //const response ={ error: '1'}

      if (response.error == "1") {
        this.setState({ showsuccmsg: "quote",emailExtenderror:'', loadingStatus: false });
        jQuery(function ($) {
          $("#travelbooknow").modal("hide");
          setTimeout(function () {
            $("#QuoteOrderModelPopUp").modal("show");
            // $('#booksuccesspopup').modal('show');
          }, 700);
        });
      } else {
        localStorage.removeItem("starbuckscheckIn");
        localStorage.removeItem("starbuckscheckOut");
        localStorage.removeItem("starbuckspnrId");
        localStorage.removeItem("starbucksbedSize");
        localStorage.removeItem("starbucksrecord_limit");
        localStorage.removeItem("starbucksreqDays");
        localStorage.removeItem("starbucksradius");
        localStorage.removeItem("starbuckslatitude");
        localStorage.removeItem("starbuckslongitude");
        localStorage.removeItem("starbuckssearchedLocation");
        if (response.orderId != "" && response.orderstatus == "QUOTE") {
          Request["status"] = 2;
          Request["OrderId"] = Number(response.orderId);
          const requestObj = {
            OrderUId: response.orderUId,
            Notes: this.state.notes,
            Flight: this.state.Flight,
            ArrivalAirport: this.state.ArrivalAirport,
            ArrivalDate:
              (this.state.ArrivalDate != "") & (this.state.ArrivalDate != null)
                ? this.state.ArrivalDate.format("YYYY-MM-DD HH:mm")
                : "",
            Airline: this.state.Airline,
            ChangedBy: Base64.decode(localStorage.getItem("umpqua-email")),
          };
          TravelBookingApiService.SaveTripInfo(requestObj);
          this.setState({ showsuccmsg: "quote",emailExtenderror:'', loadingStatus: false });
          //     jQuery(function ($) {
          //         $('#travelbooknow').modal('hide');
          //   })
          jQuery(function ($) {
            $("#travelbooknow").modal("hide");
            setTimeout(function () {
              $("#booksuccesspopup").modal("show");
            }, 400);
            setTimeout(function () {
              $("#booksuccesspopup").modal("hide");
            }, 3000);
          });

          this.closeModalpopUp();
          //   location.href = '/starbucks-travel-quote'
        } else {
          const pnrUpdateDetails = {
            PNR: "",
            OrderId: Number(response.orderId),
            status: 2,
            Traveler: this.state.firstName + " " + this.state.lastName,
            Email: this.state.emailId,
            phone: this.state.phoneNo,
            AirLineName: this.state.Airline,
            FlightNo: this.state.Flight,
            type: this.state.type,
            CheckInDate: this.state.modelCheckIn.format("MM-DD-YYYY"),
            OutDate: this.state.modelCheckOut.format("MM-DD-YYYY"),
            BookedBy: "admin",
          };
          Request["status"] = 2;
          Request["OrderId"] = Number(response.orderId);
          TravelBookingApiService.SaveBookingEmails(pnrUpdateDetails);

          const requestObj = {
            OrderUId: response.orderUId,
            Notes: this.state.notes,
            Flight: this.state.Flight,
            ArrivalAirport: this.state.ArrivalAirport,
            ArrivalDate:
              (this.state.ArrivalDate != "") & (this.state.ArrivalDate != null)
                ? this.state.ArrivalDate.format("YYYY-MM-DD HH:mm")
                : "",
            Airline: this.state.Airline,
            ChangedBy: Base64.decode(localStorage.getItem("umpqua-email")),
          };
          TravelBookingApiService.SaveTripInfo(requestObj);
          this.setState({ showsuccmsg: "adminsuccess",emailExtenderror:'', loadingStatus: false });
          //      jQuery(function ($) {
          //         $('#travelbooknow').modal('hide');
          //   })

          jQuery(function ($) {
            $("#travelbooknow").modal("hide");
            setTimeout(function () {
              $("#booksuccesspopup").modal("show");
            }, 400);
            setTimeout(function () {
              $("#booksuccesspopup").modal("hide");
            }, 3000);
          });
          this.closeModalpopUp();
          //  location.href = '/starbucks-travel-adminsuccess'
        }
      }
    }
    localStorage.setItem(
      "expireTime",
      Base64.encode(moment().format("DD/MM/YYYY HH:mm:ss"))
    );
  }
  render() {
    const defaultdaterange = this.state.defaultdaterange;
 
    return (
      <div>
        <StarbucksBookingHeader />
        <div className="travel_header-ht"></div>
        <div className="travel_header-ht-w"></div>

        <div className=" calendarViewWrapper">
          <div className="headingBtns">
            <h3 style={{ fontWeight: 500 }}>Umpqua Bank Availability Calendar</h3>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={() => this.handleGraphicalView()}
            >
              Book Now
            </button>
          </div>

          {!this.state.MastersByProgram.length > 0 ? (
            <div
              className="text-center"
              style={{
                fontSize: "20px",
              }}
            >
              <i className="fa fa-spinner fa-spin" />
              Please wait ...!
            </div>
          ) : (
            <div className="calendarView">
              <div className="">
                <table className="sideTable">
                  <thead>
                    <tr>
                      <th colSpan="4">&nbsp;</th>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <th>Master#</th>
                      <th>Community</th>
                      <th>Size</th>
                      <th>SuiteNo</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.values(
                      _.groupBy(this.state.MastersByProgram, "MasterId")
                    ).map((row, index) => {
                      return (
                        <tr key={index}>
                          <td>{row[0].MasterId}</td>
                          <td>{row[0].Community}</td>
                          <td>{row[0].SuiteSize}</td>
                          <td>{row[0].SuiteNo}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="table-responsive">
                <table className="mainTable">
                  <thead className="heading">
                    <tr>{this.renderMonthHeaders(defaultdaterange)}</tr>
                  </thead>
                  <thead>
                    <tr>
                      {defaultdaterange.map((row, index) => {
                        return (
                          <th key={index}>
                            {moment().add(index, "days").format("dddd") ==
                              "Sunday" ||
                            moment().add(index, "days").format("dddd") ==
                              "Saturday" ? (
                              <span className='dayred'>
                                {moment().add(index, "days").format("DD")}
                              </span>
                            ) : (
                              <span>
                                {moment().add(index, "days").format("DD")}
                              </span>
                            )}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {Object.values(
                      _.groupBy(this.state.MastersByProgram, "MasterId")
                    ).map((row, index) => {
                      return (
                        <tr key={index}>
                          {defaultdaterange.map((rowss, i) => {
                            let checking;
                            //    let colersclass =['green_color','red_color','blue_color','yellow_color']
                            //    let selectColor="";
                            return (
                              <th key={i}>
                                {row.map((eachRowInner, indexxxx) => {
                                  let start = moment(eachRowInner.AvailFrom);
                                  let end = moment(eachRowInner.AvailTo);
                                  let today;
                                  if (i == 0) {
                                    today = moment();
                                  } else {
                                    today = moment().add(i, "days");
                                  }
                                  if (!checking) {
                                    checking = moment(today).isBetween(
                                      moment(start),
                                      moment(end).add(1, "days")
                                    );
                                    // selectColor = colersclass[indexxxx]
                                  }
                                })}
                                <span
                                  className={checking ? ` ` : "smallbox"}
                                ></span>
                              </th>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
        <div
          className="modal fade savesearch bookingmodel"
          data-backdrop="static"
          id="travelbooknow"
          role="dialog"
        >
          <div className="modal-dialog">
            <div className="modal-content rm-border-radius">
              <div className="modal-header">
                <button
                  type="button"
                  onClick={() => this.closeModal()}
                  className="close btn"
                  data-dismiss="modal"
                  aria-label=""
                >
                 <i className="bi bi-x"></i>
                </button>
              </div>

              <div>
                <h4 className="modal-title text-center font-bold">Book Now</h4>
                {this.state.showMsg ? (
                  <div className="text-center" style={{ color: "red" }}>
                    {" "}
                    <span>
                      Please Enter Required Fields, Valid Email and Phone No
                    </span>
                    {" "}
                  </div>
                ) : 
                  (this.state.emailExtenderror !== '' ?   
                  <div className="text-center" style={{ color: "red" }}>
                  {" "}
                  <span>{this.state.emailExtenderror}</span>
                  {" "}
                </div>
                    : '' )
                }
                <div className="modal-body">
                  {this.state.showBookNow == true &&
                  this.state.AvailableBillableMasters.length > 0 ? (
                    <form role="form" method="post">
                      <div>
                        <div className="row">
                          <div className="col-sm-12 communityName">
                            <span>
                              <strong>Community Name:</strong>
                            </span>
                            <span className="text-red">
                              {" "}
                              {this.state.AvailableBillableMasters.length > 0 &&
                                this.state.AvailableBillableMasters.map(
                                  (val, index) => (
                                    <span key={index}>
                                      {val.community_name}
                                    </span>
                                  )
                                )}
                            </span>
                          </div>

                          <div className="col-sm-12 communityName">
                            <span>
                              <strong>City:</strong>
                            </span>
                            <span className="text-red">
                              {" "}
                              {this.state.AvailableBillableMasters.length > 0 &&
                                this.state.AvailableBillableMasters.map(
                                  (val, index) => (
                                    <span key={index}>
                                      {val.city_name}
                                    </span>
                                  )
                                )}
                            </span>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <ReactDatePicker
                                type="text"
                                className="form-control "
                                name="date"
                                disabled
                                placeholderText="Checkin Date *"
                                selected={this.state.modelCheckIn}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <ReactDatePicker
                                type="text"
                                disabled
                                className="form-control "
                                name="date"
                                placeholderText="Checkout Date *"
                                selected={this.state.modelCheckOut}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="First Name *"
                                value={this.state.firstName}
                               
                                onChange={(e) => this.setValue("firstName", e)}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Last Name *"
                                 value={this.state.lastName}
                                
                                onChange={(e) => this.setValue("lastName", e)}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <input
                                className="form-control"
                                id="phone3"
                                placeholder="Phone *"
                                maxLength="15"
                                onChange={(e) => this.setNumber(e)}
                                value={this.state.phoneNo}
                                
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Guest Email *"
                                 value={this.state.emailId}
                               
                                onChange={(e) => this.setValue("emailId", e)}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Reservedby Email *"
                                 value={this.state.reservedbyemail}
                                
                                onChange={(e) =>
                                  this.setValue("reservedbyemail", e)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                maxLength="15"
                                placeholder="Cost center"
                                value={this.state.purchaseorder}
                                onChange={(e) => this.onChangevalue(e)}
                              />
                            </div>
                          </div>
                        </div>

                        <hr></hr>

                        <div className="row">
                          <div className="col-sm-12 communityName">
                            <span>
                              <strong>Arrival Info:</strong>
                            </span>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Flight#"
                                value={this.state.flight}
                                onChange={(e) => this.setValue("Flight", e)}
                              />
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="ArrivalAirport"
                                value={this.state.ArrivalAirport}
                                onChange={(e) =>
                                  this.setValue("ArrivalAirport", e)
                                }
                              />
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="form-group">
                              <ReactDatePicker
                                type="text"
                                className="form-control"
                                name="date"
                                placeholderText="ArrivalDate"
                                selected={this.state.ArrivalDate}
                                onChange={(e) => this.selectionArrivalDate(e)}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                timeCaption="time"
                                dateFormat="MM/DD/YYYY HH:mm"
                                onKeyDown={(e) => {
                                  e.preventDefault();
                                }}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Airline"
                                value={this.state.airline}
                                onChange={(e) => this.setValue("Airline", e)}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <div className="form-group">
                              <textarea
                                className="form-control"
                                placeholder="Arrival Notes"
                                value={this.state.notes}
                                onChange={(e) => this.setValue("notes", e)}
                              ></textarea>
                            </div>
                          </div>
                        </div>

                        <div className="clearfix"></div>
                        {this.state.loadingStatus ? (
                          <div
                            className="text-center"
                            style={{
                              fontSize: "25px",
                              width: "100%",
                              margin: "auto",
                              textAlign: "center",
                            }}
                          >
                            <i className="fa fa-spinner fa-spin" />
                            Please wait ...!
                          </div>
                        ) : (
                          <button
                            className="btn location_btn mrg-btm-50"
                            type="button"
                            onClick={() => {
                              this.bookDetails();
                            }}
                          >
                            Book
                          </button>
                        )}
                      </div>
                    </form>
                  ) : (
                    <div>
                      {this.state.showDatesValid ? (
                        <div
                          className="text-center m-b-15"
                          style={{ color: "red" }}
                        >
                          {" "}
                          <span>
                            Please Select CheckIn and CheckOut Dates
                          </span>{" "}
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.showValidation ? (
                        <div
                          className="text-center m-b-15"
                          style={{ color: "red" }}
                        >
                          <span>
                            Selected dates are not available. Please check other
                            dates for availability or reach out to{" "}
                            <a href="mailto:umpquabank@suiteamerica.com">
                              umpquabank@suiteamerica.com
                            </a>{" "}
                            for further assistance.
                          </span>{" "}
                        </div>
                      ) : (
                        ""
                      )}
                      <form role="form" method="post">
                        <div>
                          <div className="row">
                            <div className="col-sm-2"></div>
                            <div className="col-sm-4">
                              <div className="form-group">
                                <ReactDatePicker
                                  placeholderText="Check In *"
                                  selected={this.state.modelCheckIn}
                                  minDate={moment()}
                                  maxDate={
                                    this.state.modelCheckOut &&
                                    moment(this.state.modelCheckOut).subtract(
                                      1,
                                      "days"
                                    )
                                  }
                                  onChange={(e) =>
                                    this.setState({ modelCheckIn: e })
                                  }
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="form-group">
                                <ReactDatePicker
                                  placeholderText="Check Out *"
                                  selected={this.state.modelCheckOut}
                                  minDate={
                                    this.state.modelCheckIn
                                      ? moment(this.state.modelCheckIn).add(
                                          1,
                                          "days"
                                        )
                                      : moment()
                                  }
                                  onChange={(e) =>
                                    this.setState({ modelCheckOut: e })
                                  }
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            <div className="col-sm-2"></div>
                          </div>

                          {this.state.loadingStatus ? (
                            <div
                              className="text-center"
                              style={{
                                fontSize: "25px",
                                width: "100%",
                                margin: "auto",
                                textAlign: "center",
                              }}
                            >
                              <i className="fa fa-spinner fa-spin" />
                              Please wait ...!
                            </div>
                          ) : (
                            <button
                              className="btn location_btn mrg-btm-50"
                              type="button"
                              onClick={() => this.getCommunities()}
                            >
                              Next
                            </button>
                          )}
                        </div>
                      </form>
                    </div>
                  )}
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="QuoteOrderModelPopUp" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content rm-border-radius">
              <div className="modal-header">
                <button
                  type="button"
                  className="close btn"
                  data-dismiss="modal"
                  aria-label=""
                >
                  <i className="bi bi-x"></i>
                </button>
                <img src="/assets/images/errorBanner.png" />
              </div>
              <div className="modal-body">
                <p className="text-center sa-clr f-15">
                  Sorry! The selected property is not available anymore. Please
                  search for options again.
                </p>
                <div className="modal-footer">
                  <a href="/umpqua-bank-admin">
                    <button type="button" className="btn btn-danger">
                      Search Again
                    </button>
                  </a>

                  {/* <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="booksuccesspopup"
          data-backdrop="static"
          role="dialog"
        >
          <div className="modal-dialog">
            <div className="modal-content rm-border-radius">
              <div className="modal-header">
                <button
                  type="button"
                  onClick={() => this.closeModalpopUp()}
                  className="close"
                  data-dismiss="modal"
                  aria-label=""
                >
                  <span>×</span>
                </button>
              </div>
              {this.state.showsuccmsg == "adminsuccess" ? (
                <div className="modal-body">
                  <h1 className="">
                    Your reservation is confirmed. Please check your inbox/spam
                    folder for confirmation.
                  </h1>
                </div>
              ) : (
                <div className="modal-body">
                  <h1 className="">Thank you, Your Suite Request is placed.</h1>
                  <p className="">
                    You will receive the confirmation of your stay shortly!
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <TravelBookingFooter />
      </div>
    );
  }
}
export default Inventory;