/* Importing the node modules, child components, services and controllers used 
   inside CorporationCorporateHousing component */    

   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   import LocationSlider from '../../common/locationSliderData';
   import ReserveYourStay from '../../common/reserveYourStay.js';
   import MetaTags from '../../common/metaTags';
   import ServerMetaTags from '../../../server/metaTags';   
   import NavigationFooter from '../../common/navigationFooter';
   import NavigationData from '../../common/navigation.json';
   /* CorporationCorporateHousing Component initialization */
   class CorporationCorporateHousing extends React.Component {
         /* Initializing objects of its CorporationCorporateHousing class */
         constructor(props) {
           super(props);
           this.state = {
              navigationData: NavigationData.corporationCorprateHousing
           }
        }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
           MainController.getProfilePageInformation(0, 1, 1);
        }   
       /* It is invoked to return html content */
       render() {
           const metaData = ServerMetaTags.urlString.getMetaData(this.props.match.url);
           return (
            <div className="corporateHousing_Updates">
            <MetaTags 
             metaTitle = {metaData.title}
             metaDesription = {metaData.description}
             metaUrl = {metaData.canonical} />
            <GuestHeader/>
            <header className="codeconveyHeader cc_image_text_main corporate-h video-wrapper">
               <img className="img-responsive relocation-corporate-housing" src="https://s3-us-west-1.amazonaws.com/suiteam-website/images-website/Corporation-Corporate-Housing-Banner.jpg" width="100%" alt=""  />
               <div className="wow fadeInUp img-on-text top40 left15 width500 text-center img-on-text-center" data-wow-delay="0.5s">
                  <h1>CORPORATE HOUSING <i>SOLUTIONS</i></h1>
                  <p>At SuiteAmerica, we are redefining what 
                      corporate housing can be, worldwide. We combine an unrivaled 
                      level of detail and care with innovative technology for
                      one goal: to create an environment where new is not 
                      daunting and discovery is effortless. 
                  </p>
               </div>
            </header>
            <ReserveYourStay />
            <div className="who_weare services_ch">
               <div className="cc_image_text_main">
                  <img className="img-responsive left-mo" src="https://s3-us-west-1.amazonaws.com/suiteam-website/images-website/Corporation-Corporate-Housing-Customize-Your-Stay.jpg" alt="" />
               </div>
            </div>
            <div className="new_txt_sec">
                <div className="new_txt_content container wow fadeInUp animated">
                    <div className="col-md-8 col-md-offset-2">
                        <h2>CUSTOMIZE YOUR STAY</h2>
                        <p>There’s no place like home, but with easy-to-get amenities, your transferee can make this place feel pretty darn close.</p>
                        <p> <strong>Electronics, Furniture, Outdoor Activities, Pets, Kids, Kitchen, Car Rental, Housekeeping, Starting Essentials </strong> <i>and more</i></p>
                    </div>
                </div>
                <div className="clearfix"></div>
            </div>
            <LocationSlider {...this.props}/>
            <div className="caption_text services_cs">
               <div className="container">
                  <div className="who_weare_text22 wow fadeInUp" data-wow-delay="0.5s">
                     <h1 className="font-bold">COMFORT ISN’T</h1>
                     <h2><span>it’s a</span></h2>
                     <h1>AN OPTION</h1>
                     <h2><span>necessity</span></h2>
                  </div>
               </div>
            </div>
            <div className="container-fluid bg-white">
                <div className="container">
            <p className="text-center review_rqst_button accomidation_acco_btn color-red collapsed" role="button" data-toggle="collapse" href="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
            Accommodations
            </p>
            <div className="collapse row accomidation_acco" id="collapseExample">
                    <div className="company_section wow fadeInUp">
                        <div className="col-md-6">
                            <div>
                                <p>ALL APARTMENTS INCLUDE</p>
                                <ul>
                                    <li>Complete furnishings</li>
                                    <li>Separate living, dining, & sleeping areas</li>
                                    <li>Full kitchens complete with housewares</li>
                                    <li>2 Televisions, DVD, & basic cable</li>
                                    <li>Washer & dryer in most apartments</li>
                                    <li>High-speed internet</li>
                                    <li>Utilities</li>
                                    <li>24/7 customer support</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div>
                                <p>MOST COMMUNITIES FEATURE</p>
                                <ul>
                                    <li>Fitness facilities</li>
                                    <li>Business Centers</li>
                                    <li>Swimming Pools</li>
                                    <li>Easy Access to Highways</li>
                                    <li>Near Public Transportation</li>
                                    <li>Controlled Access</li>
                                    <li>Parking available on site</li>
                                    <li>Pet-Friendly accommodations</li>
                                </ul>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                    </div>
            </div>
            </div>
            <div className="bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 company_section">
                            <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/images-website/Corporation-Corprate-Housing-Concierge-Services.jpg" className="img-responsive" alt="" /> 
                            <div className="accom_text wow fadeInUp">
                            <h2>Concierge Services</h2>
                            <p>Having the “little things” during the big move is 
                                what we’re here for. Let our Concierge Services 
                                team help your transferee settle in with additional 
                                amenities and services. From furniture upgrades to 
                                customized amenities, we’re only a call (or keystroke) away.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 company_section">
                            <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/images-website/Corporation-Corporate-Housing-Personal-Transition-Services.jpg" className="img-responsive" alt="" /> 
                            <div className="accom_text wow fadeInUp">
                            <h2>Personal Transition Services</h2>
                            <p>SuiteAmerica’s P.T.S. team offers it’s exclusive, 
                                personalized meet & greet services around the clock, 
                                so you can be assured that your guest will arrive safely 
                                and settle-in to their new home, hassle-free. Day or night, 
                                our team is there to open the door with a smile and provide 
                                a warm welcome.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 company_section">
                            <img src="/assets/newUI-images/Website-Images/Corporation-Corporate-Housing-Advanced-Interactive-Tools.jpg" className="img-responsive" alt="" /> 
                            <div className="accom_text wow fadeInUp">
                            <h2>Advanced Interactive Tools</h2>
                            <p>We’ve created a suite of custom, digital tools using
                                 the latest technologies to make our time with you and 
                                 your transferee more efficient and valuable. Our centralized
                                  data system ensures accurate real-time information, 
                                  tracking and reporting data about corporate housing 
                                  stays or Destination Services tours from any device.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="quote-section bg-grey quote_red">
               <div className="container">
                  <div className="wow fadeInUp" data-wow-delay="0.5s">
                     <h6 className="quote-s"> <img src={s3BucketUrl_WebsiteImages+"Corporation-Corporate-Housing-Quote.png"} alt=""/></h6>
                     <div className="col-md-10 col-md-offset-1">
                        <h5 className="quote-text">Your team has thought of everything to make it feel like 
                        a home away from home. The kids loved their new room, especially 
                        the cozy blankets and stuffed animals. After a long journey from London, 
                        it was hugely welcoming and very much appreciated.
                        </h5>
                     </div>
                  </div>
               </div>
            </div>
            <div className="clearfix"></div>
            <NavigationFooter {...this.state} />
            <SubFooter/>
            <Footer/>
         </div>   
           );
       }
   }
   export default CorporationCorporateHousing;