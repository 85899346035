import React from "react";

function Disclaimer() {
  return (
    <div className="bg-grey py-5">
      <div className="container">
        <p className="text-gray-200">
          <b className="text-dark">Disclaimer : </b> Ensure your housing
          registration is completed a minimum of 45 days prior to the
          commencement of your internship. Accommodations are allocated on a
          first-come, first-served basis, and historically, housing reaches
          capacity early in the summer.
        </p>
      </div>
    </div>
  );
}

export default Disclaimer;
