/* Importing the node modules, child components, services and controllers used 
   inside ServiceRequestForm component */

import $ from "jquery";
import AppController from "../../../controller/appController";
import MapController from "../../../controller/mapController";
import moment, { relativeTimeRounding } from 'moment'
import FivestarHeader from '../../../common/fivestarHeader';
import S3Upload from 'react-s3-uploader/s3upload';
import SuccessModal from '../../../common/successModal';
import SessionService from "../../../../services/sessionService";
import FiveStarApiServices from '../../../../services/fiveStarApiServices';
import FiveStarService from '../../../../services/fiveStarService';
import FirstRow from "./serviceRequestComponents/firstRow"
import SecondRow from "./serviceRequestComponents/secondRow"
import ThirdRow from "./serviceRequestComponents/thirdRow"
import ListButtons from "./serviceRequestComponents/listButtons"
import RequestForm from "./serviceRequestComponents/requestForm"
/* ServiceRequestForm Component initialization */
class ServiceRequestForm extends React.Component{
  /* Initializing objects of its ServiceRequestForm class */
    constructor(props) {
        super(props)
        /* ServiceRequestForm Component State variables Initialization */
        this.state = {
            requestDetails: [],
            res:[],
            notesAboutTheStop: '',
            listMissingItems: '',
            notesAboutTheStopError: '',
            listMissingItemsError: '',
            message:'',
            loadMoreIndex: -1,
            successMessage: '',
            distance: '0 mi',
            duration: '0 hours',
            directionsService: {},
            directionsDisplay:{},
            latitude:'',
            longitude:'',
            bannerimage: '',
            filesUpload: false,
            filesLists: [],
            filesList: [],
            loading: false,
            driverManagerInfo: {}
        }
        this.getMyLocation = this.getMyLocation.bind(this)
        this.redirectionToKeys = this.redirectionToKeys.bind(this)
        this.redirectionToCheckList = this.redirectionToCheckList.bind(this)
        this.redirectionToInspectionForm = this.redirectionToInspectionForm.bind(this)
        this.setValue = this.setValue.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this._handleImageChange = this._handleImageChange.bind(this)
        this.clearFields = this.clearFields.bind(this)
  }
  /* It is invoked immediately before a component is mounted */
    async componentWillMount() {
        $('html, body').animate({scrollTop: 0});
        AppController.stickyTitles();
        const driverInformation= await SessionService.SessionGet({type:'fivestar'});
        this.setState({driverManagerInfo:driverInformation})
        await this.get_Service_Schedule();
        this.getMyLocation();        
        await MapController.loadMapData(this.state.requestDetails,localStorage.getItem('requestFormId'),this, 'service', 'https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/location_pin_2.svg');
     }

     async get_Service_Schedule(){
      const TableId=localStorage.getItem('requestFormId')
      const driverDetails={
          uniqueid:TableId
      }
      const resOrders = await FiveStarApiServices.getServiceScheduleById(driverDetails);
      this.setState({requestDetails: resOrders[0],bannerimage:resOrders[0].CommunityImage!=null? resOrders[0].CommunityImage:''});
     }
     /* To get the current location */
     getMyLocation() {
      if (navigator.geolocation) { 
        navigator.geolocation.getCurrentPosition( (position) => {
           MapController.calculateDistance(this,position, this.state.requestDetails);
       }, 
      (error) => console.log(new Date(), error),  {
        enableHighAccuracy: false, 
        timeout: 10000, 
        maximumAge: 3000
      }); 
      }
  }
  /* To change the selected input field value */
  setValue(field, e) {
    var object = {};
    object[field] = e.target.value;
    this.setState(object);
  }
  /* To check the required field validations */
  checkValidation(){
    if(this.state.notesAboutTheStop == ''){
        this.setState({'notesAboutTheStopError': 'Notes About The Stop is required'});
    }
  }
  async _handleImageChange(type, index, e) {
    e.preventDefault();
  if(this.state.filesList.length<=3)
  {
    let reader = new FileReader();
    const fileIndex = e.target.name.split('-')
         const _this = this
        _.forEach(e.target.files, function(file) {
          let uniqueId=_this.state.requestDetails.UniqueId.replace(/ /g,"_")
          uniqueId=_.toUpper(uniqueId);
            const S3Uploader = new S3Upload({
                files: [file],
                signingUrl: '/s3/sign',
                s3path: _this.state.requestDetails.type+ '/'+uniqueId+'/'+moment().format("MM-DD-YYYY")+'/',
                signingUrlQueryParams: {},
                onProgress: _this.onProgress.bind(_this, type, index),
                onFinishS3Put: _this.onFinish.bind(_this, type, index, file),
                onError: _this.onError.bind(_this, type, index),
                uploadRequestHeaders: {'x-amz-acl': 'public-read'},
                contentDisposition: file.type,
                server: ''
            }) 
        })
    }
  else{
  this.setState({message:'Please select only four files',color:'red'})
  setTimeout(function() {
    this.setState({message:'',color:''})
  }.bind(this), 3000);
  }
  }
  /* Progress event for uploading image into s3 bucket */        
  async onProgress(type, fileIndex, progress) {
    this.setState({filesUpload: true})
  }
  /* Error event for uploading image into s3 bucket */    
  onError(type, fileIndex, err) {}
  async onFinish(type, fileIndex, FileDetails, info) {
  const filepath = 'https://suiteam-website.s3.amazonaws.com/' + info.filename;
    await this.setState( {filesUpload : false});
    await this.filesListUpdate(filepath, FileDetails, info ,-1)
  }
  async filesListUpdate(filepath, FileDetails, info, dataIndex) {
    if (dataIndex < 0) {
        await this.setState({
            filesLists: _.concat(this.state.filesLists, {
                filepath: filepath,
                FileDetails: FileDetails,
                info: info
            })
        })
    } else if (dataIndex >= 0) {
        await this.setState({
            filesLists: update(this.state.filesLists, {
                $splice: [
                    [dataIndex, 1]
                ]
            }, 0)
        })
    }
  }
  /* To insert the service request from details into database and send the details to corresponding email */
  async handleSubmit(value, e) {
    e.preventDefault();
    this.checkValidation();
    const finalString = this.state.notesAboutTheStop + ',' + this.state.listMissingItems;

    if(this.state.notesAboutTheStop != '' ){
      this.setState({loading: true,notesAboutTheStopError: ''})
      const pstDate = AppController.pstDate()
      const gsrInfo=(this.state.requestDetails.GSR==null || this.state.requestDetails.GSR=='' || this.state.requestDetails.GSR==undefined || this.state.requestDetails.GSR=='/')?'':this.state.requestDetails.GSR
     
      const gsrEmail=gsrInfo==''?[]:gsrInfo.split('/')
        var postObj = {
        typeCode: this.state.requestDetails.type,
        tmpCode:this.state.requestDetails.TypeCode,
        notes: this.state.notesAboutTheStop,
        missingItems: this.state.listMissingItems, 
        changedBy:'',
        enteredBy:this.state.driverManagerInfo.Identifier,
        status:1,
        UniqueId: this.state.requestDetails.UniqueId,
        finalString: finalString,
        orderId:this.state.requestDetails.TableId,
        orderType: this.state.requestDetails.OrderType,
        HotelName: this.state.requestDetails.HotelName,
        HotelAddress: this.state.requestDetails.hoteladdress,
        HotelCity: this.state.requestDetails.hotelcity,
        HotelState: this.state.requestDetails.hotelstate,
        HotelZipcode: this.state.requestDetails.hotelzipcode,
        HotelSuiteNo: this.state.requestDetails.hotelsuite,
        masterId: this.state.requestDetails.mastertableId,
        SuiteNo: this.state.requestDetails.SuiteNo,
        sTableId: this.state.requestDetails.sTableId,
        community_name:this.state.requestDetails.community_name,
        suiteAddress: this.state.requestDetails.AptAddr,
        suiteState: this.state.requestDetails.LeaseState,
        suiteCity: this.state.requestDetails.LeaseCity,
        suiteZipCode: this.state.requestDetails.AptZip,
        orderId:this.state.requestDetails.TableId,
        filesLists:this.state.filesLists,
        createdDate:pstDate,
        address:this.state.requestDetails.address,
        state_name:this.state.requestDetails.state_name,
        city_name:this.state.requestDetails.city_name,
        ZipCode:this.state.requestDetails.ZipCode,
        orderGsr:gsrEmail.length>0?gsrEmail[1]:''
      };
//console.log(postObj,"postObj");

      
        const res= await FiveStarApiServices.saveServiceScheduleNotes(postObj);
        if(res.error =='0'){
          const resEmail= await  FiveStarService.serviceRequest(postObj)
          await  FiveStarApiServices.CreateServiceRequestEmailLog(resEmail.responseData)
          await this.get_Service_Schedule()
          this.setState({notesAboutTheStopError: '', listMissingItemsError: '',notesAboutTheStop: '',listMissingItems: '',loading:false});
          if(value == 1) {
            this.setState({successMessage: '',filesLists:[]});
            jQuery(function($) {
              $('#successModal').modal('show');
              setTimeout(function(){
                $('#successModal').modal('hide');
              },1500);
            });
          }
          if(value == 2) {
            const _this = this
            this.setState({successMessage: ''});
            jQuery(function($) {
              $('#successModal').modal('show');
              setTimeout(function(){
                $('#successModal').modal('hide');
                _this.stopOrder();
              },700);
            });
          }      
        }
        else{
          this.setState({message:'Email Sending Failed',color:'red'}) 
        }
    }
  }
  /* To stop the corresponding order */
  async stopOrder() {
    const pstDate = AppController.pstDate()
    const postObj = {
        uniqueId: this.state.requestDetails.UniqueId,
        driverUId: this.state.driverManagerInfo.UniqueId, 
        dfsUId: this.state.requestDetails.dfsUId,
        type: this.state.requestDetails.type, 
        status: 5,
        stopTime: AppController.pstDateTime(),
        orderId:this.state.requestDetails.TableId,
        masterId:this.state.requestDetails.mastertableId,
        createdDate:pstDate,
       };
    await FiveStarApiServices.SaveDriverSchedules(postObj);
    location.href = '/drivers-schedule';
  }
  /* To load the more notes */
  loadMoreNotes(loadMoreIndex){
    this.setState({loadMoreIndex})
  }
  /* To redirected to key list page */
  redirectionToKeys(){    
    location.href = '/keylist';
  }
  redirectionToCheckList(){    
    location.href = '/schedule-checklist';
  }
  redirectionToInspectionForm(){
    location.href = '/fivestar-inspectionform';
  }
  /* To clear data from the service request form */
  clearFields() {
    this.setState({notesAboutTheStop: '', listMissingItems: '', notesAboutTheStopError: '', listMissingItemsError:''});
  }
  /* It is invoked to return html content */
    render(){
        return(
          <div>
          <FivestarHeader/>
            <div id="pages_5star">
            <FirstRow serviceRequest={true} requestDetails={this.state.requestDetails} distance={this.state.distance} duration={this.state.duration} getMyLocation={this.getMyLocation}/>
            <div className="container star_rform">
            <SecondRow requestDetails={this.state.requestDetails} driverManagerInfo={this.state.driverManagerInfo} serviceRequest={true}/>
            <ThirdRow requestDetails={this.state.requestDetails}/>
            <ListButtons requestDetails={this.state.requestDetails} redirectionToKeys={this.redirectionToKeys} redirectionToCheckList={this.redirectionToCheckList} redirectionToInspectionForm={this.redirectionToInspectionForm}/>
            <RequestForm  {...this.state} setValue={this.setValue} clearFields={this.clearFields} handleSubmit={this.handleSubmit} _handleImageChange={this._handleImageChange}/>
            </div> 
             <SuccessModal />
            </div>
            </div>
        );
    }
}
// export default ServiceRequestForm;
export default ServiceRequestForm;