const Home=(props)=>{
    return(
        <div>
          {props.communityInfo.DEFAULT.Data.map((row,index)=>{
            return(
              <div className={index % 2 == 0 ? "deamthubimg":"deamthubimg flex-direction-r-r"} key={index}>
              <div className="iteam">
                <img
                  className="img-responsive"
                  src={row.Image}
                />
              </div>
              <div className="iteam iteam-text">
                <h1> {row.Name} </h1>
                <p>
                 {row.Paragraph}
                </p>
              </div>
            </div>
            )
  
          })}

          
         
          </div>
    )
}
export default Home;