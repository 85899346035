'use strict'

import LocationService from '../../../../services/locationService';
import EncryptDecrypt from '../../../../services/encryptDecrypt';
class ListViewModal {
  constructor(thisObject, props) {
    this.Obj = thisObject
    this.propsObj = props
    this.state = {
      communityId: 0,
      communityData: [],
      coordinates: {},
      activeMarker: {},
      selectedPlace: {},
      communityName: '',
      showingInfoWindow: false,
      collapseIn: false,
      favourite: 'favourite_id',
      more: 2,
      less: 1,
      loading: true,
      loadingStatus: true,
      directionsDisplay: '',
      directionsService: '',
      loadMoreButton: false,
      loadMoreString: true,
      company: this.propsObj.companyName == undefined
        ? 'suiteamerica'
        : this.propsObj.companyName,
      pdfLoading: false,
      latitude: 0.0,
      longitude: 0.0,
      viewPlaceStatus: false,
    }
  }
  async favouriteCommunity(data) {
    await this.propsObj.favouriteCommunity(data)
  }
  async onFavouriteDelete(isFavourite) {
    await this.propsObj.unFavouriteCommunity(isFavourite);
  }
  async moreInfo(data, favouriteCheck) {
    if (favouriteCheck != undefined && favouriteCheck.favourite_id != '') {
      data[this.state.favourite] = favouriteCheck.favourite_id;
      data['companydetails'] = this.propsObj.companyName
      localStorage.setItem('moreData', JSON.stringify(data));
    } else {
      data[this.state.favourite] = '';
      data['companydetails'] = this.propsObj.companyName
      localStorage.setItem('moreData', JSON.stringify(data));
    }
    const webGuest = localStorage.getItem('_u_web');
    const response = await EncryptDecrypt.decryptData({'type': webGuest})
    const userData = response.recordset[0]
    if (userData != null) {
      const RecentlyViewed = {
        user_id: (userData != undefined && Object.keys(userData).length > 0)
          ? userData.Id
          : '',
        community_id: data.community_id,
        createdBy: (userData != undefined && Object.keys(userData).length > 0)
          ? userData.GuestEmail
          : ''
      }
       await LocationService.saveRecentlyViewedCommunity(RecentlyViewed)
    } 
  }
  async updateState(updateObject) {
    await this.Obj.setState(updateObject)
  }
}

export default ListViewModal