/* Importing the node modules, child components, services and controllers used 
   inside CorporationGlobal component */   
   import $ from "jquery";
   import AppController from "../../controller/appController";
   import MainController from "../../controller/mainController";
   import {Link} from 'react-router-dom';
   import SubFooter from "../../common/subFooter";
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   import NikeServices from '../../../services/nikeService';
   import LocationSlider from '../../common/locationSliderData';
   import ReserveYourStay from '../../common/reserveYourStay.js';
   import SuccessModal from '../../common/successModal';
   import MetaTags from '../../common/metaTags';
   import ServerMetaTags from '../../../server/metaTags';
   import NavigationFooter from '../../common/navigationFooter';
   import NavigationData from '../../common/navigation.json';
   /* CorporationGlobal Component initialization */
   class CorporationGlobal extends React.Component {
       /* Initializing objects of its CorporationGlobal class */
       constructor(props) {
           super(props)
           this.state={   
               firstName: '',
               lastName: '',
               email: '',
               subject: '',
               message: '',
               color:'',
               errorMessage:'',
               navigationData: NavigationData.corporationGlobal
           }
           this.setValue = this.setValue.bind(this);
           this.serviceRequest = this.serviceRequest.bind(this);   
       }
       /* It is invoked immediately before mounting occurs */
       componentWillMount() {
           MainController.getProfilePageInformation(1, 1, 1);
       }
       /* It is invoked immediately after mounting occurs */
       componentDidMount(){
           this.autoPlayVideo();
       }
       autoPlayVideo() {
           const id = '#global-background';
           const source = 'https://s3-us-west-1.amazonaws.com/suiteam-website/istock-518075926.mp4';
           AppController.autoPlayVideo(id, source);
       }
        /* Form Details object creations  */
   
      setValue(field, e) {
            var object = {};
            object[field] = e.target.value;
            this.setState(object);
      }
       /* Service Request Details send to mail  */
       async serviceRequest(){
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;  
        const _this = this
           const validationFields=['firstName','lastName','email']
           let error = 0;
           _.forEach(validationFields, function(value) {
              if(_this.state[value] == ""){
                error++;
              }else if(reg.test(_this.state.email) == false){
                error++;
              }
          });
         if(error == 0){
            const Request={
                firstName:this.state.firstName,
                lastName:this.state.lastName,
                email:this.state.email,
                subject: this.state.subject == '' ? 'NA' : this.state.subject,
                message: this.state.message == '' ? 'NA' : this.state.message,
                mail:'contactUs'
            }
    
        this.setState({
            firstName: '',
            lastName: '',
            email: '',
            subject: '',
            message: '',
            color:'',
            errorMessage:'',
           
   
        })
        AppController.showSuccessModal();
        const Response=await NikeServices.serviceRequest(Request)        
    }
    else{
        AppController.menuTopNavigation('serviceRequest');
        this.setState({errorMessage:'Please Enter Required Fields and Valid Email',color:'red'})
    }
        
       }
       /* It is invoked to return html content */
       render() { 
           const metaData = ServerMetaTags.urlString.getMetaData(this.props.match.url);       
           return (
               <div>
                   <MetaTags 
                    metaTitle = {metaData.title}
                    metaDesription = {metaData.description}
                    metaUrl = {metaData.canonical} />
                   <GuestHeader/>
                   <header className="codeconveyHeader corporate-h fullvideo cc_image_text_main video-wrapper iotdim">
                       <video autoPlay playsInline muted  loop id="global-background" preload="auto">
                           <source type="video/mp4" />
                       </video>
                       <div className="wow fadeInUp img-on-text no-tap-pad top40 text-center img-on-text-center img-on-text-center-w " data-wow-delay="0.5s">
                           <div className="container">
                           <h1>GLOBAL COVERAGE</h1>
                           <p>Driven by client demand, SuiteAmerica now offers our award winning serviced apartments in the EMEIA market. Our new international locations include London and Amsterdam, while additional locations in Ireland, India, China, and Singapore will be available soon.  We go where our clients want our consistent standard of excellence!
                           </p>
                           <Link to="#" className="img-slide-btn mrg-top-30">WELCOME TO LONDON</Link>
                           </div>
                       </div>
                   </header>
                   <ReserveYourStay />
                   <div className="who_weare services_ch">
                       <div className="cc_image_text_main">
                           <img className="img-responsive left-mo" src={s3BucketUrl_WebsiteImages+"Corporation-Global-Street-London.jpg"} alt="" />
                       </div>
                   </div>
                   <div className="new_txt_sec">
                       <div className="new_txt_content container wow fadeInUp animated">
                           <div className="col-md-8 col-md-offset-2">
                               <h2>WELCOME TO...</h2>
                               <p>Starting with our recent entry into the London, U.K. market, we are proud to announce SuiteAmerica is expanding our award-winning service and technology globally. Get the same, great SuiteAmerica experience you’ve come to expect, worldwide!
                               </p>
                               <div className="row">
                               <div className="col-sm-6">
                                   <div className="global-coverage-list-block wow fadeInUp">
                                       <ul>
                                           <li><img src={s3BucketUrl_WebsiteImages+"Individual-Global--Welcome-To-User.png"} /> <span>One Point of Contact</span></li>
                                           <li><img src={s3BucketUrl_WebsiteImages+"Individual-Global-Welcome-To-Hours.png"} /> <span>Around-the-Clock Service</span></li>
                                           
                                       </ul>
                                   </div>
                               </div>
                               <div className="col-sm-6">
                                   <div className="global-coverage-list-block wow fadeInUp">
                                       <ul>
                                       <li><img src={s3BucketUrl_WebsiteImages+"Individual-Global-Welcome-To-Mobile.png"} /> <span>Advanced Interactive Tools</span></li>
                                           <li><img src={s3BucketUrl_WebsiteImages+"Individual-Global-Welcome-To-Home.png"} /> <span>Customized Solutions</span></li>
                                       </ul>
                                   </div>
                               </div>
                               </div>
                           </div>
                       </div>
                       <div className="clearfix"></div>
                   </div>
                   <LocationSlider {...this.props}/>
                   <div className="container-fluid bg-grey">
                       <div className="container">
                           <div className="savesearch form-section col-sm-6 col-sm-offset-3 text-center" id="serviceRequest">
                               <h2>
                               Contact Our<br/> Global Sales Team Today!
                               </h2>
                               <h2 className="mrg-top-35 mrg-btm-35"><img src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Telephone.png"} width="25px"/>
                               {/* <span className="desktop-phone">800.367.9501</span> */}
                               <a href="tel:8003679501" className="mobile-phone">800.367.9501</a>
                               </h2>
                               <p className="text-left" style={{color:this.state.color}}>{this.state.errorMessage}</p>
                               <form role="form" method="post" action="" className="form-horizontal">
                               <div className="form-group row">
                                   <input type="text" className="form-control col-md-5" placeholder="*First Name" value={this.state.firstName} onChange={(e) => this.setValue('firstName', e)}/>
                               </div>
                               <div className="form-group row">
                                   <input type="text" className="form-control col-md-5" placeholder="*Last Name" value={this.state.lastName} 
                                   onChange={(e) => this.setValue('lastName', e)}/>
                               </div>
                               <div className="form-group row">
                                   <input type="text" className="form-control col-md-5" placeholder="*Email" value={this.state.email} 
                                   onChange={(e) => this.setValue('email', e)} />
                               </div>
                               <div className="form-group row">
                                   <input type="text" className="form-control col-md-5" placeholder="Subject" value={this.state.subject} 
                                   onChange={(e) => this.setValue('subject', e)}/>
                               </div>
                               <div className="form-group row">
                                   <textarea className="form-control col-md-5" placeholder="Message" value={this.state.message} 
                                   onChange={(e) => this.setValue('message', e)}/>
                               </div>
                               <div className="form-group row">
                                   <button className="btn company_btn" type="button" 
                                   onClick={this.serviceRequest}>SEND</button>
                               </div>
                               </form>
                           </div>
                       </div>
                   </div>
                   <div className="clearfix"></div>
                   <NavigationFooter {...this.state} />
                   <SubFooter />
                   <Footer/>
                   <SuccessModal/>
               </div>
   
           );
       }
   }
   export default CorporationGlobal;