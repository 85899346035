/* Importing the node modules, child components, services and controllers used 
   inside AdidasContactUs component */    
   import MainController from "../../controller/mainController";
   import AdidasHeader from "../../common/adidasHeader";
   import SubFooter from "../../common/subFooter";
   import ContactUs from "./common/contactUs";
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   import AdidasFooter from "./common/adidasFooter";
   import AdidasSubFooter from "./common/adidasSubFooter";
   /* AdidasContactUs Component initialization */
   class AdidasContactUs extends React.Component {
       /* Initializing objects of its AdidasContactUs class */
       constructor(props) {
           super(props);
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
           MainController.getInitialInformation();
        }
       /* It is invoked to return html content */
       render() {
           return (
            <div className="corporateHousing_Updates">
                <GuestHeader/>
                <div className="cover-tophd-ht"></div>    
                <AdidasHeader />
                <div className="container-fluid bg-grey">
                    <div className="container">
                        <ContactUs />
                    </div>
                </div>
                <div className="container ft-contact-adrs mrg-btm-30 mrg-top-30">
                    <AdidasSubFooter />
                <div className="section-line"></div>
                    <AdidasFooter />
                </div>
                <SubFooter />
                <Footer/>
            </div>   
           );
       }
   }
   export default AdidasContactUs;