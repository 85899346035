import React, { useState, useEffect } from "react";
import moment from "moment";
import { Table, Input, Button } from "antd";

import { filterDataBySearch } from "../common/utils";

const ServiceOrdersTable = ({
  tableData,
  searchText,
  setSearchText,
  filteredData,
  setFilteredData,
}) => {
  const columns = [
    {
      title: "Order#",
      dataIndex: "OrderId",
      key: "OrderId",
      sorter: (a, b) => a.OrderId - b.OrderId,
    },
    // {
    //   title: "Guest Name",
    //   dataIndex: "SubCategory",
    //   key: "SubCategory",
    //   render: (text) => {
    //     if (text) {
    //       const subCategory = text
    //         .toLowerCase()
    //         .replace(/\b\w/g, (c) => c.toUpperCase());
    //       return `${subCategory}`;
    //     } else {
    //       return ""; // or any default value you want to display when text is null
    //     }
    //   },
    //   sorter: (a, b) => {
    //     let nameOne = a.SubCategory || "";
    //     let nameTwo = b.SubCategory || "";
    //     return nameOne.localeCompare(nameTwo);
    //   },
    // },
    {
      title: "Request Date",
      dataIndex: "RequestDate",
      key: "RequestDate",
      render: (text, record) => {
        const computedValue = moment(record.RequestDate).format("MM/DD/YYYY");
        return computedValue;
      },
      sorter: (a, b) =>
        moment(a.RequestDate).isBefore(b.RequestDate) ? -1 : 1,
    },
    {
      title: "Community Name",
      dataIndex: "CommunityName",
      key: "CommunityName",
      sorter: (a, b) => {
        let nameOne = a.CommunityName || "";
        let nameTwo = b.CommunityName || "";
        return nameOne.localeCompare(nameTwo);
      },
    },
    {
      title: "Suite No",
      dataIndex: "SuiteNo",
      key: "SuiteNo",
      sorter: (a, b) => {
        let nameOne = a.SuiteNo || "";
        let nameTwo = b.SuiteNo || "";
        return nameOne.localeCompare(nameTwo);
      },
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
      sorter: (a, b) => {
        let nameOne = a.Description || "";
        let nameTwo = b.Description || "";
        return nameOne.localeCompare(nameTwo);
      },
    },
    // {
    //   title: "Created By",
    //   dataIndex: "CreatedBy",
    //   key: "CreatedBy",
    //   sorter: (a, b) => {
    //     let nameOne = a.CreatedBy || "";
    //     let nameTwo = b.CreatedBy || "";
    //     return nameOne.localeCompare(nameTwo);
    //   },
    // },
    {
      title: "Room",
      dataIndex: "Room",
      key: "Room",
      render: (text) => {
        if (text) {
          const Room = text
            .toLowerCase()
            .replace(/\b\w/g, (c) => c.toUpperCase());
          return `${Room}`;
        } else {
          return ""; // or any default value you want to display when text is null
        }
      },
      sorter: (a, b) => {
        let nameOne = a.Room || "";
        let nameTwo = b.Room || "";
        return nameOne.localeCompare(nameTwo);
      },
    },
    // {
    //   title: "Room Type",
    //   dataIndex: "RoomType",
    //   key: "RoomType",
    //   render: (text) => {
    //     if (text) {
    //       const roomType = text
    //         .toLowerCase()
    //         .replace(/\b\w/g, (c) => c.toUpperCase());
    //       return `${roomType}`;
    //     } else {
    //       return ""; // or any default value you want to display when text is null
    //     }
    //   },
    //   sorter: (a, b) => {
    //     let nameOne = a.RoomType || "";
    //     let nameTwo = b.RoomType || "";
    //     return nameOne.localeCompare(nameTwo);
    //   },
    // },
    {
      title: "Category",
      dataIndex: "CategoryCode",
      key: "CategoryCode",
      render: (text) => {
        if (text) {
          const CategoryCode = text
            .toLowerCase()
            .replace(/\b\w/g, (c) => c.toUpperCase());
          return `${CategoryCode}`;
        } else {
          return ""; // or any default value you want to display when text is null
        }
      },
      sorter: (a, b) => {
        let nameOne = a.CategoryCode || "";
        let nameTwo = b.CategoryCode || "";
        return nameOne.localeCompare(nameTwo);
      },
    },

    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      render: (text) => {
        if (text) {
          const Status = text
            .toLowerCase()
            .replace(/\b\w/g, (c) => c.toUpperCase());
          return `${Status}`;
        } else {
          return ""; // or any default value you want to display when text is null
        }
      },
      sorter: (a, b) => {
        let nameOne = a.Status || "";
        let nameTwo = b.Status || "";
        return nameOne.localeCompare(nameTwo);
      },
    },
  ];

  // const [searchText, setSearchText] = useState("");
  // const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (!searchText) {
      setFilteredData(tableData);
    }
  }, [searchText, tableData]);

  return (
    <div className="col-md-12 align-self-center p-3 card bg-white m-b-30">
      <div className="d-flex">
        <Input.Search
          placeholder="Search"
          style={{
            width: 200,
            marginBottom: 10,
            marginTop: 10,
            marginLeft: "auto",
          }}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onPressEnter={() =>
            setFilteredData(
              filterDataBySearch(searchText, tableData, [
                "OrderId",
                "RequestDate",
                "Description",
                "CreatedBy",
                "Room",
                "RoomType",
                "CategoryCode",
                "SubCategory",
              ])
            )
          }
        />
      </div>

      <Table
        columns={columns}
        dataSource={filteredData}
        pagination={{
          pageSize: 20,
          showSizeChanger: false,
          showQuickJumper: true,
        }}
        onChange={(pagination, filters, sorter) => {
          // Handle pagination, sorting, and filtering
        }}
      />
    </div>
  );
};

export default ServiceOrdersTable;
