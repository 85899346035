/* Importing the node modules, child components, services and controllers used 
   inside NikeAddedValue component */
import MainController from "../../controller/mainController";
import SubFooter from "../../common/subFooter";
import LinkedinInternsHeader from "./common/LinkedinInternsHeader";
import FAQ from "./common/LinkedinInternsFaq";
import Nike from "./common/nike";
import GuestHeader from "../../common/guestHeader";
import Footer from "../../common/footer";
import { Link } from "react-router-dom";
import Disclaimer from "./common/disclimar";
import SubFooterLinkedIn from "./common/subFooterlinkedIn";
/* NikeAddedValue Component initialization */
class LinkedinInternsAddedValue extends React.Component {
  /* Initializing objects of its NikeAddedValue class */
  constructor(props) {
    super(props);
    this.state = {
      nike: Nike,
    };
  }
  /* It is invoked immediately before mounting occurs */
  componentWillMount() {
    MainController.getInitialInformation();
  }
  /* It is invoked to return html content */
  render() {
    const { nike } = this.state;
    return (
      <div className="corporateHousing_Updates">
        <GuestHeader />

        <LinkedinInternsHeader />
        <div class="in-intern-home-banner SAweb_updateUi">
          <img
            class="bannerImg"
            src="/assets/images/linkedIn-interns/abt-suiteAmercia.png"
            alt=""
            width="100%"
          />
          <div class="in-intern-home-content">
            <div class="container">
              <div class="content-width">
                <img
                  src="https://sawebsite.suite.solutions/assets/newUI-images/logo/SA-full-logo-W.svg"
                  alt=""
                  width="300px"
                />

                <h3 class="text-white mrg-top-50 mb-4">
                  Who We Are & What We Do
                </h3>

                <p class="text-white p fw-200 m-0">
                  SuiteAmerica is a corporate housing provider with over 30
                  years of experience.
                </p>

                <p class="text-white p fw-200 my-4">
                  Specializing in group housing, we've fine tuned our program
                  management expertise.
                </p>

                <p class="text-white p fw-200">
                  Simply put, our furnished apartments await your move-in, and
                  we're here to pair you with other individuals looking for
                  roommates.
                </p>

                <Link to="/company/LinkedIn-interns/how-it-works">
                  <button class="btn">How Does it Work?</button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div class="SAweb_updateUi my-6 in-intern-content bg-white">
          <div class="container">
            <h3>
              Why choose SuiteAmerica when I could find a place on my own?
            </h3>
            <p class="p mt-4 fw-300">
              If you've got your roomies all set & have a place lined up, bravo!
              You're good to go.
            </p>
            <p class="p my-4 fw-300">
              But if you've got a stipend to use & don't know where to start
              finding roommates or an apartment, we're here for you.
            </p>
            <h4>Some benefits of using SuiteAmerica</h4>
            <ul class="listView">
              <li>
                <i class="bi bi-check2-square"></i>No credit checks
              </li>
              <li>
                <i class="bi bi-check2-square"></i> No security deposit
              </li>
              <li>
                <i class="bi bi-check2-square"></i> Utilities included - no need
                to set up
              </li>
              <li>
                <i class="bi bi-check2-square"></i> Wifi included
              </li>
              {/* <li>
                <i class="bi bi-check2-square"></i> Effortless roommate search
              </li> */}
              <li>
                <i class="bi bi-check2-square"></i> Effortless apartment search
              </li>
              {/* <li>
                <i class="bi bi-check2-square"></i> Options to add
                furniture/housewares/housekeeping
              </li> */}
            </ul>
            <p class="p fw-300">
              Sound good? Check out how it works & let's get started!
            </p>

            <Link to="/company/LinkedIn-interns/how-it-works">
              <button class="btn black-btn">Show me how it works</button>
            </Link>
          </div>
        </div>

        <div class="SAweb_updateUi py-6 in-intern-content">
          <div class="container">
            <div class="row">
              <div class="col-md-6">
                <h3 class="mb-4 fw-800">The SuiteAmerica Philosophy</h3>
                <p class="p mb-4">
                  SuiteAmerica strives to be the best in corporate housing,
                  using the right combination of technology and personal touch
                  to keep our clients coming back time and time again. We are
                  dedicated to delivering an optimal experience for every guest,
                  focusing on perfect arrivals and a comfortable, enjoyable
                  stay.
                </p>
                <p class="p">
                  To attain this, we stand by four pillars that shape our
                  philosophy. With these mainstays, we've been fortunate enough
                  to serve everyone from company interns to diplomats to Fortune
                  500 leaders.
                </p>
              </div>
              <div class="col-md-6">
                <ul class="listView listViewAbout">
                  <li>
                    <img
                      src="/assets/images/linkedIn-interns/icon-hands.png"
                      alt=""
                    />
                    Guest-first Approach
                  </li>
                  <li>
                    <img
                      src="/assets/images/linkedIn-interns/icon-hands-user.png"
                      alt=""
                    />
                    Unmatched Client Experience
                  </li>

                  <li>
                    <img
                      src="/assets/images/linkedIn-interns/icon-users.png"
                      alt=""
                    />
                    Dedicated Teams
                  </li>
                  <li>
                    <img
                      src="/assets/images/linkedIn-interns/icon-tech.png"
                      alt=""
                    />
                    Stellar Technology
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="SAweb_updateUi bg-white py-6 in-intern-content">
          <div class="container">
            <div class="imageOverlapBox">
              <div class="item">
                <img
                  src="/assets/images/linkedIn-interns/in-whyStayWithUs.png"
                  alt=""
                  width="100%"
                />
              </div>
              <div class="item text">
                <h3 class="mb-4">Why stay with us</h3>

                <p>
                  We're synonymous with comfort among relocating employees,
                  business travelers, interns, and groups. From our humble
                  beginnings over 30 years ago, we've come a long way in the
                  corporate housing industry. We take pride in our endeavor to
                  provide you with first-class, comfortable stays that surpass
                  the best of the best experiences.
                </p>
                <p class="mb-4">
                  Our meticulous attention to detail results in customized stays
                  to suit your preferences so you can relax and know your
                  housing experience will be a good one.
                </p>
                <Link to="/company/LinkedIn-interns/how-it-works">
                  <button class="btn black-btn">Show me how it works</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Disclaimer />
        {/* <SubFooter /> */}
        <SubFooterLinkedIn />
        <Footer />
      </div>
    );
  }
}
export default LinkedinInternsAddedValue;
