'use strict'

import serviceBase from './serviceBase'

const fiveStarService = {
    serviceRequest:(data)=>serviceBase.post('/fiveStar/servicerequest',data),
    keysList:(data)=>serviceBase.post('/fiveStar/keyslist',data),
    quickRequest:(data)=>serviceBase.post('/fiveStar/quickrequest',data),
    email:(data)=>serviceBase.post('/fiveStar/email',data),
    checkList:(data)=>serviceBase.post('/fiveStar/checklist',data),
    reschedule:(data)=>serviceBase.post('/fiveStar/reschedule',data),
    inspectionForm:(data)=>serviceBase.post('/fiveStar/inspectionform',data),
    s3BucketImages:(data)=>serviceBase.post('/fiveStar/s3bucketimages',data),
}
export default fiveStarService