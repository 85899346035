/* Importing the node modules, child components, services and controllers used 
inside AppleInternshipQuestionnaire component */
import $ from "jquery";
import DatePicker from "react-datepicker";
import ReCAPTCHA from "react-google-recaptcha";
import moment from "moment";

import AppController from "../../controller/appController";
import MainController from "../../controller/mainController";

import AppleServices from "../../../services/nikeService";

import SubFooter from "../../common/subFooter";
import Footer from "../../common/footer";
import GuestHeader from "../../common/guestHeader";
import SuccessModal from "../../common/successModal";

import ReserveNowApi from "../../../services/reserveNowApi";
import usersService from "../../../services/appleInternService";

import AppleInternShipHeader from "./common/AppleInternShipHeader";
import AppleInternFooter from "./common/AppleInternFooter";

class AppleInternshipQuestionnaire extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      CaptchaValue: "",
      name: "",
      PreferredName: "",
      Pronouns: "",
      email: "",
      phone: "",
      moveInDate: null,
      moveOutDate: null,
      loader: false,
      gender: "",
      genderslist: ["Male", "Female", "Non-Binary"],
      smoke: "",
      vehicle: "",
      identifyAsLGBTQIA: "",
      supportiveRoommate: "",
      comfortLiving: "",
      comfortLivingText: "",
      pursuingGender: "",
      roommateDisability: "",
      roommateAnimal: "",
      accommodationHousing: "",
      accommodationHousingText: "",
      prayersAccommondation: "",
      prayersAccommondationText: "",
      dietaryAccommodations: "",
      dietaryAccommodationsText: "",
      roommateSplAccommodation: "",
      additonalInfo: "",
      sleepHabbit: "",
      roomateName: "",
      educationLevel: "",
      apartmentsize: "",
      opensharingbedwithanotherintern: "",
      bedSize: "2 Bedrooms",
      message: "",
      color: "",
      errorMessage: "",
      roommate: "",
      sleepHabbitDropDown: ["Early Bird", "Night Owl"],
      appartmentSizeDropDown: ["3 Bedrooms", "2 Bedrooms", "1 Bedroom"],
      educationDropDown: ["Undergraduate", "Graduate"],
      firstname: "",
      lastname: "",
      appleemail: "",
      applemoveInDate: null,
      applemoveOutDate: null,
      applePhone: "",
      parking: "",
      appleNotes: "",
      address: "",
    };
    this.setValue = this.setValue.bind(this);
    this.setChecked = this.setChecked.bind(this);
    this.handleMoveIn = this.handleMoveIn.bind(this);
    this.handleMoveOut = this.handleMoveOut.bind(this);
    this.checkedStatus = this.checkedStatus.bind(this);
    this.serviceRequest = this.serviceRequest.bind(this);
    this.onChangeCaptcha = this.onChangeCaptcha.bind(this);
    this.selection = this.selection.bind(this);
    this.setNumber = this.setNumber.bind(this);
    this.handleAppleMoveOutDate = this.handleAppleMoveOutDate.bind(this);
    this.handleAppleMoveInDate = this.handleAppleMoveInDate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setAppleData = this.setAppleData.bind(this);
    this.setLocation = this.setLocation.bind(this);
  }
  /* It is invoked immediately before mounting occurs */
  componentWillMount() {
    MainController.getInitialInformation();
  }

  componentDidMount() {
    const _this = this;
    jQuery(
      function ($) {
        $("#applePhone").intlTelInput();
      }.bind(this)
    );
    if (!_this.state.dateCalendarAdded) {
      jQuery(function () {
        jQuery('input[name="daterange"]').daterangepicker(
          {
            autoUpdateInput: false,
            locale: {
              cancelLabel: "Clear",
            },
            minDate: moment(),
          },
          function (start, end, label) {
            _this.setState({
              dateCalendarAdded: true,
              date:
                start.format("MM/DD/YYYY") + " - " + end.format("MM/DD/YYYY"),
              applemoveInDate: start.format("MM/DD/YYYY"),
              applemoveOutDate: end.format("MM/DD/YYYY"),
            });
          }
        );
      });
    }
  }

  async onChangeCaptcha(CaptchaValue) {
    this.setState({ CaptchaValue });
  }
  /* Form Details object creations  */
  setValue(field, e) {
    if (field == "phone") {
      const re = /^[0-9\b]+$/;
      if (e.target.value == "" || re.test(e.target.value)) {
        this.setState({ phone: e.target.value });
      }
    } else {
      var object = {};
      object[field] = e.target.value;
      this.setState(object);
    }
  }
  setAppleData(field, e) {
    var object = {};
    object[field] = e.target.value;
    this.setState(object);
    //console.log(this.state.bedSize, "object")
  }
  /* Form Details object creations  */
  setChecked(field, e) {
    let obje = {};
    obje[field] = e.target.value;
    this.setState(obje);
    //    if (field == 'gender') {
    //        this.setState({ gender: e.target.value });
    //    } else if (field == 'smoke') {
    //        this.setState({ smoke: e.target.value });
    //    } else if (field == 'vehicle') {
    //        this.setState({ vehicle: e.target.value });
    //    }else if(field == 'parking'){
    //     this.setState({ parking: e.target.value });
    //    }
  }
  checkedStatus(field, e) {
    this.setState({ roommate: e.target.checked ? "Yes" : "No" });
  }
  /* MoveIndate and MoveOutDate   */
  async handleMoveIn(date) {
    this.setState({
      moveInDate: moment(date),
      moveInDateSelected: date.format("MM-DD-YYYY HH:mm:ss"),
    });
  }

  async handleAppleMoveInDate(date) {
    this.setState({
      applemoveInDate: moment(date),
      appleInDateSelected: date.format("MM-DD-YYYY HH:mm:ss"),
    });
  }
  async handleAppleMoveOutDate(date) {
    this.setState({
      applemoveOutDate: moment(date),
      moveInDateSelected: date.format("MM-DD-YYYY HH:mm:ss"),
    });
  }

  async selection(e) {
    if (e.target.value == "Yes") {
      const Request = {
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
        moveInDate: this.state.moveInDate.format("MM-DD-YYYY"),
        moveOutDate: this.state.moveOutDate.format("MM-DD-YYYY"),
        gender: this.state.gender == "" ? "NA" : this.state.gender,
        smoke: this.state.smoke == "" ? "No" : this.state.smoke,
        vehicle: this.state.vehicle == "" ? "No" : this.state.vehicle,
        sleepHabbit: this.state.sleepHabbit,
        roomateName:
          this.state.roomateName == "" ? "NA" : this.state.roomateName,
        educationLevel: this.state.educationLevel,
        apartmentsize: this.state.apartmentsize,
        opensharingbedwithanotherintern:
          this.state.opensharingbedwithanotherintern,
        bedSize: this.state.bedSize,
        message: this.state.message == "" ? "NA" : this.state.message,
        mail: "AppleInternShipQuestionnaire",
        roommate: this.state.roommate == "" ? "No" : this.state.roommate,
        subject: "Apple Intern Questionnaire",
      };
      this.setState({
        name: "",
        email: "",
        phone: "",
        moveInDate: moment(),
        moveOutDate: moment(),
        gender: "",
        smoke: "",
        vehicle: "",
        sleepHabbit: "",
        roomateName: "",
        educationLevel: "",
        apartmentsize: "",
        opensharingbedwithanotherintern: "",
        bedSize: "2 Bedroom",
        message: "",
        roommate: "",
        color: "",
        errorMessage: "",
      });

      $('input[name="1"]').prop("checked", false);
      $('input[name="2"]').prop("checked", false);
      $('input[name="3"]').prop("checked", false);
      $('input[name="4"]').prop("checked", false);
      jQuery(
        function ($) {
          setTimeout(
            function () {
              $("#internship-confirmation").modal("hide");
              AppController.showSuccessModal();
            }.bind(this),
            500
          );
        }.bind(this)
      );
      const Response = await AppleServices.serviceRequest(Request);
      //console.log(Response, "Response")
    } else {
      jQuery(
        function ($) {
          setTimeout(
            function () {
              $("#internship-confirmation").modal("hide");
            }.bind(this),
            500
          );
        }.bind(this)
      );
    }
  }
  async handleMoveOut(date) {
    this.setState({
      moveOutDate: moment(date),
      moveOutDateSelected: date.format("MM-DD-YYYY HH:mm:ss"),
    });
  }
  /* Service Request Details send to mail  */
  async serviceRequest() {
    this.setState({ loader: true });
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    const _this = this;
    const validationFields = [
      "name",
      "PreferredName",
      "Pronouns",
      "email",
      "phone",
      "moveInDate",
      "moveOutDate",
      "sleepHabbit",
      "educationLevel",
      "apartmentsize",
      "opensharingbedwithanotherintern",
      "gender",
      "smoke",
      "vehicle",
      "identifyAsLGBTQIA",
      "supportiveRoommate",
      "comfortLiving",
      "pursuingGender",
      "roommateDisability",
      "roommateAnimal",
      "accommodationHousing",
      "prayersAccommondation",
      "dietaryAccommodations",
      "roommateSplAccommodation",
      "additonalInfo",
    ];
    let error = 0;
    //    console.log(moment(moveOut).diff(moment(moveIn), 'days'), moment(moment(moveOut).format('YYYY-MM-DD HH:mm:ss')).diff(moment(moment(moveIn).format('YYYY-MM-DD HH:mm:ss')), 'days'), '=====')
    //    if(moveIn!='' && moveIn!=null && moveOut!='' && moveOut!=null){
    //      if(moment(moveOut).diff(moment(moveIn), 'days') < 0){
    //         error++;
    //      }
    //    }

    _.forEach(validationFields, function (value) {
      if (value == "moveInDate" || value == "moveOutDate") {
        if (_this.state[value] == "" || _this.state[value] == null) {
          error++;
        }
      } else if (_this.state[value] == "") {
        error++;
      } else if (reg.test(_this.state.email) == false) {
        error++;
      }
    });
    if (error == 0) {
      let userInfo = {
        PreferredName: this.state.PreferredName,
        Pronouns: this.state.Pronouns,
        sleepHabbit: this.state.sleepHabbit,
        educationLevel: this.state.educationLevel,
        apartmentsize: this.state.apartmentsize,
        opensharingbedwithanotherintern:
          this.state.opensharingbedwithanotherintern,
        gender: this.state.gender,
        smoke: this.state.smoke,
        identifyAsLGBTQIA: this.state.identifyAsLGBTQIA,
        supportiveRoommate: this.state.supportiveRoommate,
        comfortLiving: this.state.comfortLiving,
        pursuingGender: this.state.pursuingGender,
        roommateDisability: this.state.roommateDisability,
        roommateAnimal: this.state.roommateAnimal,
        accommodationHousing: this.state.accommodationHousing,
        accommodationHousingText: this.state.accommodationHousingText,
        prayersAccommondation: this.state.prayersAccommondation,
        prayersAccommondationText: this.state.prayersAccommondationText,
        dietaryAccommodations: this.state.dietaryAccommodations,
        dietaryAccommodationsText: this.state.dietaryAccommodationsText,
        roommateSplAccommodation: this.state.roommateSplAccommodation,
        additonalInfo: this.state.additonalInfo,
        message: this.state.message,
      };
      const reserveYourStay = {
        Location: "One Bowerman Dr, Beaverton, OR 97005",
        MoveInDate: moment(this.state.moveInDate).format("MM/DD/YYYY"),
        MoveOutDate: moment(this.state.moveOutDate).format("MM/DD/YYYY"),
        Size: "1X",
        SpecialNote: JSON.stringify(userInfo),
        GuestFirstName: this.state.name,
        GuestLastName: "",
        ClientReference: "",
        GuestEmail: this.state.email,
        GuestPhone: this.state.phone,
        Parking: this.state.vehicle,
        length: 0,
        CreatedBy: this.state.email != "" ? this.state.email : "---",
        BookedBy: "it@suiteamerica.com",
        PhContryCode: "+1",
      };
      const orderResponse = await ReserveNowApi.webLeadQuotes(reserveYourStay);
      if (orderResponse.error == 0) {
        let emailRequestObj = {
          name: this.state.name,
          PreferredName: this.state.PreferredName,
          Pronouns: this.state.Pronouns,
          email: this.state.email,
          phone: this.state.phone,
          MoveInDate: moment(this.state.moveInDate).format("MM/DD/YYYY"),
          MoveOutDate: moment(this.state.moveOutDate).format("MM/DD/YYYY"),
          sleepHabbit: this.state.sleepHabbit,
          educationLevel: this.state.educationLevel,
          apartmentsize: this.state.apartmentsize,
          opensharingbedwithanotherintern:
            this.state.opensharingbedwithanotherintern,
          gender: this.state.gender,
          smoke: this.state.smoke,
          vehicle: this.state.vehicle,
          identifyAsLGBTQIA: this.state.identifyAsLGBTQIA,
          supportiveRoommate: this.state.supportiveRoommate,
          comfortLiving: this.state.comfortLiving,
          pursuingGender: this.state.pursuingGender,
          roommateDisability: this.state.roommateDisability,
          roommateAnimal: this.state.roommateAnimal,
          accommodationHousing: this.state.accommodationHousing,
          accommodationHousingText: this.state.accommodationHousingText,
          prayersAccommondation: this.state.prayersAccommondation,
          prayersAccommondationText: this.state.prayersAccommondationText,
          dietaryAccommodations: this.state.dietaryAccommodations,
          dietaryAccommodationsText: this.state.dietaryAccommodationsText,
          roommateSplAccommodation: this.state.roommateSplAccommodation,
          additonalInfo: this.state.additonalInfo,
          message: this.state.message,
          orderId: orderResponse.message.orderId,
        };
        //const resReserveYourStay = await 
        usersService.reserveYourStay(
          emailRequestObj
        );
        this.setState({
          name: "",
          PreferredName: "",
          Pronouns: "",
          email: "",
          phone: "",
          moveInDateSelected: null,
          moveOutDateSelected: null,
          moveInDate: null,
          moveOutDate: null,
          sleepHabbit: "",
          educationLevel: "",
          apartmentsize: "",
          opensharingbedwithanotherintern: "",
          gender: "",
          smoke: "",
          vehicle: "",
          identifyAsLGBTQIA: "",
          supportiveRoommate: "",
          comfortLiving: "",
          pursuingGender: "",
          roommateDisability: "",
          roommateAnimal: "",
          accommodationHousing: "",
          accommodationHousingText: "",
          prayersAccommondation: "",
          prayersAccommondationText: "",
          dietaryAccommodations: "",
          dietaryAccommodations: "",
          roommateSplAccommodation: "",
          additonalInfo: "",
          message: "",
          loader: false,
          errorMessage: "",
        });
        $('input[name="Yes"]').prop("checked", false);
        $('input[name="No"]').prop("checked", false);
        jQuery(
          function ($) {
            setTimeout(
              function () {
                $("#applesuccessmodal").modal("show");
              }.bind(this),
              500
            );
          }.bind(this)
        );
      }
    } else {
      AppController.menuTopNavigation("serviceRequest");
      this.setState({
        loader: false,
        errorMessage: "Please Enter Required Fields *",
        color: "red",
      });
    }
  }

  setNumber(e) {
    const re = /^[0-9\b]+$/;
    if (e.target.value == "" || re.test(e.target.value)) {
      this.setState({ applePhone: e.target.value });
    }
  }

  validation() {
    const _this = this;
    const validationFields = [
      "firstname",
      "lastname",
      "appleemail",
      "applemoveInDate",
      "applemoveOutDate",
      "address",
      "applePhone",
      "appleNotes",
    ];
    let error = 0;
    _.forEach(validationFields, function (value) {
      if (_this.state[value] == "") {
        error++;
      }
    });
    return error;
  }

  // handleSubmit(e){
  //     e.preventDefault();
  //     const error=this.validation();
  //     console.log('hello',error);
  //   let requestdata = {

  //   }

  // }
  setLocation(field, e) {
    this.setState({ address: e.target.value });
    const icon =
      "https://s3-us-west-1.amazonaws.com/suiteam-website/Destination-Services/SuiteAme" +
      "rica-Destination-Services-Icon-Map-Marker-Green.png";
    const _this = this;
    var places = new google.maps.places.Autocomplete(
      document.getElementById("address")
    );
    google.maps.event.addListener(places, "place_changed", function () {
      var place = places.getPlace();
      const newLat = place.geometry.location.lat();
      const newLng = place.geometry.location.lng();
      _this.setState({
        latitude: newLat,
        longitude: newLng,
        address: place.formatted_address,
      });
    });
  }
  successMessage() {
    // this.props.calendarClose()
    this.setState({
      address: "",
      lengthOfStay: "",
      firstname: "",
      lastname: "",
      appleemail: "",
      applePhone: "",
      resPlace: "",
      appleNotes: "",
      message: "",
      color: "",
      emailmessage: "",
      emailcolor: "",
      budget: "",
      loader: false,
      applemoveInDate: "",
      applemoveOutDate: "",
      parking: "",
    });
    this.recaptcha.reset();
  }
  async handleSubmit(e) {
    e.preventDefault();
    const error = this.validation();
    this.setState({ emailmessage: "", emailcolor: "" });
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (error == 0) {
      if (
        reg.test(this.state.appleemail) == true &&
        this.state.applePhone.length == 10
      ) {
        this.setState({
          emailmessage: "",
          emailcolor: "",
          loader: true,
          closeButton: false,
        });
        const countryCode =
          $(".country.highlight.active").attr("data-dial-code") == undefined
            ? "+1"
            : "+" + $(".country.highlight.active").attr("data-dial-code");
        const reserveYourStay = {
          Location: this.state.address,
          MoveInDate: this.state.applemoveInDate,
          MoveOutDate: this.state.applemoveOutDate,
          Size: "1X",
          SpecialNote: this.state.appleNotes,
          GuestFirstName: this.state.firstname,
          GuestLastName: this.state.lastname,
          ClientReference: "",
          GuestEmail: this.state.appleemail,
          GuestPhone: this.state.applePhone,
          Parking: this.state.parking,
          length: 0,
          CreatedBy:
            this.state.appleemail != "" ? this.state.appleemail : "---",
          BookedBy: "it@suiteamerica.com",
          PhContryCode: countryCode,
        };
        // const orderResponse = {
        //     error:0
        // }
        const orderResponse = await ReserveNowApi.webLeadQuotes(
          reserveYourStay
        );
        if (orderResponse.error == 0) {
          const reserveYourStayEmail = {
            Location: this.state.address,
            MoveInDate: moment(this.state.applemoveInDate).format("YYYY-MM-DD"),
            MoveOutDate: moment(this.state.applemoveOutDate).format(
              "YYYY-MM-DD"
            ),
            GuestFirstName: this.state.firstname,
            GuestLastName: this.state.lastname,
            ClientReference: "",
            GuestEmail: this.state.appleemail,
            PhoneNumber: this.state.applePhone,
            length: 0,
            SpecialNote: this.state.appleNotes,
            parking: this.state.parking,
            GuestPhoneEmailTemp: countryCode + this.state.applePhone,
            community:
              this.props.CommunityDetails == undefined
                ? "---"
                : this.props.CommunityDetails.community_name,
            communityAddress:
              this.props.CommunityDetails == undefined
                ? "---"
                : this.props.CommunityDetails.address,
            communityState:
              this.props.CommunityDetails == undefined
                ? "---"
                : this.props.CommunityDetails.state_name,
            communityCity:
              this.props.CommunityDetails == undefined
                ? "---"
                : this.props.CommunityDetails.city_name,
            communityZipCode:
              this.props.CommunityDetails == undefined
                ? "---"
                : this.props.CommunityDetails.ZipCode,
            status: "1",
            OrderId: orderResponse.message.orderId,
          };
          const resReserveYourStay = await usersService.reserveYourStay(
            reserveYourStayEmail
          );
          if (resReserveYourStay.status) {
            jQuery(
              function ($) {
                //$('#community_book_now').modal('hide');
                $("#applesuccessmodal").modal("show");
              }.bind(this)
            );
            setTimeout(
              function () {
                jQuery("#applesuccessmodal").modal("hide");
              }.bind(this),
              3000
            );
            this.successMessage();
          } else {
            this.setState({
              message: "we are unable to sent Email",
              color: "red",
              loader: false,
            });
          }
        } else {
          this.setState({
            message: "Order Is Not Created ",
            color: "red",
            loader: false,
          });
        }
      } else {
        this.setState({
          message: "Please Enter Email formate and Phone Number Exactly",
          color: "Red",
          loader: false,
        });
      }
    } else {
      this.setState({
        message: "Please Enter All The Fields ",
        color: "Red",
        loader: false,
      });
    }
  }

  /* It is invoked to return html content */
  render() {
    return (
      <div className="corporateHousing_Updates">
        <GuestHeader />
        <div className="cover-tophd-ht"></div>
        <AppleInternShipHeader />
        <div className="container-fluid bg-grey" id="serviceRequest">
          <div className="container text-center" style={{ display: "none" }}>
            <h2 className="">Intern Questionnaire</h2>
            {/* <h3>Please note we have reached our capacity for intern housing at this time.</h3> */}
            <p>
              Please fill in the questionnaire below and press the Submit
              button. An email will then be sent to SuiteAmerica with your
              choices.
            </p>
          </div>
          <div className="container">
            <div className="savesearch form-section col-sm-8 col-sm-offset-2 text-center pad-bottom-0 ">
              <h2 className="">Apple Intern Questionnaire</h2>
            </div>
          </div>
        </div>
        <div className="container-fluid nike-datepicker bg-grey service_request">
          <div className="request_form company_form questionnaire_stripe">
            <div className="container">
              <form className="well">
                {this.state.errorMessage !== "" ? (
                  <p
                    className="sh-input-no mrg-btm-20 mrg-top-50"
                    style={{ color: this.state.color, textAlign: "initial" }}
                  >
                    {this.state.errorMessage}
                  </p>
                ) : (
                  ""
                )}

                <h1 className="mrg-top-0 ">Personal Information</h1>

                <div className="row">
                  <div className="col-sm-4 col-md-4">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Your Name *"
                      value={this.state.name}
                      onChange={(e) => this.setValue("name", e)}
                    />
                  </div>
                  <div className="col-sm-4 col-md-4">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Preferred Name (if applicable) *"
                      value={this.state.PreferredName}
                      onChange={(e) => this.setValue("PreferredName", e)}
                    />
                  </div>
                  <div className="col-sm-4 col-md-4">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Pronouns *"
                      value={this.state.Pronouns}
                      onChange={(e) => this.setValue("Pronouns", e)}
                    />
                  </div>
                  <div className="col-sm-4 col-md-4">
                    <input
                      type="text"
                      maxLength="10"
                      className="form-control"
                      placeholder="Phone Number *"
                      value={this.state.phone}
                      onChange={(e) => this.setValue("phone", e)}
                    />
                  </div>
                  <div className="col-sm-4 col-md-4">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Email Address *"
                      value={this.state.email}
                      onChange={(e) => this.setValue("email", e)}
                    />
                  </div>

                  <div className="col-sm-4 col-md-4">
                    <DatePicker
                      type="text"
                      className="form-control"
                      placeholderText="Arrival Date *"
                      selected={this.state.moveInDate}
                      onChange={this.handleMoveIn}
                    />
                  </div>

                  <div className="col-sm-4 col-md-4">
                    <DatePicker
                      type="text"
                      className="form-control"
                      placeholderText="Departure Date *"
                      selected={this.state.moveOutDate}
                      onChange={this.handleMoveOut}
                    />
                  </div>
                </div>
                <div className="row mt-5-px">
                  <div className="col-md-4">
                    <label>Self-Identification *</label>
                    <p className="sh-input-no">
                      <i>(All matches will be based upon gender)</i>
                    </p>
                  </div>
                  <div className="col-md-8">
                    <div className="row">
                      <div className="clearfix"></div>
                      <div className="col-sm-3">
                        <label className="custom_radio radio-inline">
                          Male
                          <input
                            type="radio"
                            name="1"
                            value="Male"
                            onChange={(e) => this.setChecked("gender", e)}
                            checked={this.state.gender == "Male" ? true : false}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="col-sm-3">
                        <label className="custom_radio radio-inline">
                          Female
                          <input
                            type="radio"
                            name="1"
                            value="Female"
                            onChange={(e) => this.setChecked("gender", e)}
                            checked={
                              this.state.gender == "Female" ? true : false
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="col-sm-3">
                        <label className="custom_radio radio-inline">
                          Non-Binary
                          <input
                            type="radio"
                            name="1"
                            value="Non-Binary"
                            onChange={(e) => this.setChecked("gender", e)}
                            checked={
                              this.state.gender == "Non-Binary" ? true : false
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="col-sm-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Write-in Option"
                          value={
                            this.state.gender == "Non-Binary" ||
                            this.state.gender == "Female" ||
                            this.state.gender == "Male" ||
                            this.state.gender == ""
                              ? ""
                              : this.state.gender
                          }
                          onChange={(e) => this.setValue("gender", e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-5-px">
                  <div className="col-md-4">
                    <label>Degree Level. *</label>
                    <p className="sh-input-no">
                      <i>(select one)</i>
                    </p>
                  </div>
                  <div className="col-md-8">
                    <div className="row">
                      <div className="clearfix"></div>
                      <div className="col-sm-3">
                        <label className="custom_radio radio-inline">
                          Undergraduate
                          <input
                            type="radio"
                            name="32"
                            value="Undergraduate"
                            onChange={(e) =>
                              this.setChecked("educationLevel", e)
                            }
                            checked={
                              this.state.educationLevel == "Undergraduate"
                                ? true
                                : false
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="col-sm-3">
                        <label className="custom_radio radio-inline">
                          Graduate
                          <input
                            type="radio"
                            name="32"
                            value="Graduate"
                            onChange={(e) =>
                              this.setChecked("educationLevel", e)
                            }
                            checked={
                              this.state.educationLevel == "Graduate"
                                ? true
                                : false
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-5-px">
                  <div className="col-md-4">
                    <label>Apartment size *</label>
                    {/* <p className="sh-input-no"><i>(select one)</i></p> */}
                  </div>
                  <div className="col-md-8">
                    <div className="row">
                      <div className="clearfix"></div>
                      <div className="col-sm-4">
                        <label className="custom_radio radio-inline">
                          1 bedroom, 1 bath
                          <input
                            type="radio"
                            name="35"
                            value="1 bedroom, 1 bath"
                            onChange={(e) =>
                              this.setChecked("apartmentsize", e)
                            }
                            checked={
                              this.state.apartmentsize == "1 bedroom, 1 bath"
                                ? true
                                : false
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <label className="custom_radio radio-inline">
                          2 bedroom, 2 bath
                          <input
                            type="radio"
                            name="35"
                            value="2 bedroom, 2 bath"
                            onChange={(e) =>
                              this.setChecked("apartmentsize", e)
                            }
                            checked={
                              this.state.apartmentsize == "2 bedroom, 2 bath"
                                ? true
                                : false
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <label className="custom_radio radio-inline">
                          3 bedroom, 2 bath
                          <input
                            type="radio"
                            name="35"
                            value="3 bedroom, 2 bath"
                            onChange={(e) =>
                              this.setChecked("apartmentsize", e)
                            }
                            checked={
                              this.state.apartmentsize == "3 bedroom, 2 bath"
                                ? true
                                : false
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-5-px">
                  <div className="col-md-4">
                    <label>
                      Open to sharing a bedroom with another intern *
                    </label>
                    {/* <p className="sh-input-no"><i>(select one)</i></p> */}
                  </div>
                  <div className="col-md-8">
                    <div className="row">
                      <div className="clearfix"></div>
                      <div className="col-sm-3">
                        <label className="custom_radio radio-inline">
                          Yes
                          <input
                            type="radio"
                            name="36"
                            value="Yes"
                            onChange={(e) =>
                              this.setChecked(
                                "opensharingbedwithanotherintern",
                                e
                              )
                            }
                            checked={
                              this.state.opensharingbedwithanotherintern ==
                              "Yes"
                                ? true
                                : false
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="col-sm-3">
                        <label className="custom_radio radio-inline">
                          No
                          <input
                            type="radio"
                            name="36"
                            value="No"
                            onChange={(e) =>
                              this.setChecked(
                                "opensharingbedwithanotherintern",
                                e
                              )
                            }
                            checked={
                              this.state.opensharingbedwithanotherintern == "No"
                                ? true
                                : false
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-5-px">
                  <div className="col-md-4">
                    <label>Do You Smoke? *</label>
                    {/* <p className="sh-input-no"><i>(All communities are non-smoking)</i></p> */}
                  </div>
                  <div className="col-md-8">
                    <div className="row">
                      <div className="clearfix"></div>
                      <div className="col-sm-3">
                        <label className="custom_radio radio-inline">
                          Yes
                          <input
                            type="radio"
                            name="2"
                            value="Yes"
                            checked={this.state.smoke == "Yes" ? true : false}
                            onChange={(e) => this.setChecked("smoke", e)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="col-sm-3">
                        <label className="custom_radio radio-inline">
                          No
                          <input
                            type="radio"
                            name="2"
                            value="No"
                            checked={this.state.smoke == "No" ? true : false}
                            onChange={(e) => this.setChecked("smoke", e)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="col-sm-3">
                        <label className="custom_radio radio-inline">
                          Occasionally
                          <input
                            type="radio"
                            name="2"
                            value="Occasionally"
                            checked={
                              this.state.smoke == "Occasionally" ? true : false
                            }
                            onChange={(e) => this.setChecked("smoke", e)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-5-px">
                  <div className="col-md-4">
                    <label>Do you plan to bring a vehicle? *</label>
                    <p className="sh-input-no">
                      <i>
                        (Parking is subject to additional fees and could be
                        located offsite)
                      </i>
                    </p>
                  </div>

                  <div className="col-md-8">
                    <div className="row">
                      <div className="clearfix"></div>
                      <div className="col-sm-3">
                        <label className="custom_radio radio-inline">
                          Yes
                          <input
                            type="radio"
                            name="3"
                            value="Yes"
                            checked={this.state.vehicle == "Yes" ? true : false}
                            onChange={(e) => this.setChecked("vehicle", e)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="col-sm-3">
                        <label className="custom_radio radio-inline">
                          No
                          <input
                            type="radio"
                            name="3"
                            value="No"
                            checked={this.state.vehicle == "No" ? true : false}
                            onChange={(e) => this.setChecked("vehicle", e)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-5-px">
                  <div className="col-md-4">
                    <label>
                      The following statement best describes my sleeping habits.
                      *
                    </label>
                    <p className="sh-input-no">
                      <i>(select one)</i>
                    </p>
                  </div>
                  <div className="col-md-8">
                    <div className="row">
                      <div className="clearfix"></div>
                      <div className="col-sm-3">
                        <label className="custom_radio radio-inline">
                          Early Bird
                          <input
                            type="radio"
                            name="31"
                            value="Early Bird"
                            onChange={(e) => this.setChecked("sleepHabbit", e)}
                            checked={
                              this.state.sleepHabbit == "Early Bird"
                                ? true
                                : false
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="col-sm-3">
                        <label className="custom_radio radio-inline">
                          Night Owl
                          <input
                            type="radio"
                            name="31"
                            value="Night Owl"
                            onChange={(e) => this.setChecked("sleepHabbit", e)}
                            checked={
                              this.state.sleepHabbit == "Night Owl"
                                ? true
                                : false
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <hr />
                <h1>Diversity & Inclusion</h1>
                <p>
                  <i>
                    We are committed to creating a supportive environment during
                    your stay with us. We celebrate the diversity of our
                    residents and seek to provide safe, inclusive, supportive,
                    and positive residential experiences for all interns. The
                    following questions are designed to help us place residents
                    in an environment that allows them to be their most
                    authentic selves.
                  </i>
                </p>

                <div className="row mt-5-px">
                  <div className="col-md-4">
                    <label>Do you identify as LGBTQIA+? *</label>
                    <p className="sh-input-no">
                      <i>
                        (Please only answer if you feel comfortable and safe
                        doing so)
                      </i>
                    </p>
                  </div>
                  <div className="col-md-8">
                    <div className="row">
                      <div className="clearfix"></div>
                      <div className="col-sm-3">
                        <label className="custom_radio radio-inline">
                          Yes
                          <input
                            type="radio"
                            name="5"
                            value="Yes"
                            checked={
                              this.state.identifyAsLGBTQIA == "Yes"
                                ? true
                                : false
                            }
                            onChange={(e) =>
                              this.setChecked("identifyAsLGBTQIA", e)
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="col-sm-3">
                        <label className="custom_radio radio-inline">
                          No
                          <input
                            type="radio"
                            name="5"
                            value="No"
                            checked={
                              this.state.identifyAsLGBTQIA == "No"
                                ? true
                                : false
                            }
                            onChange={(e) =>
                              this.setChecked("identifyAsLGBTQIA", e)
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="col-sm-6">
                        <label className="custom_radio radio-inline">
                          I choose not to answer
                          <input
                            type="radio"
                            name="5"
                            value="I choose not to answer"
                            checked={
                              this.state.identifyAsLGBTQIA ==
                              "I choose not to answer"
                                ? true
                                : false
                            }
                            onChange={(e) =>
                              this.setChecked("identifyAsLGBTQIA", e)
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-5-px">
                  <div className="col-md-4">
                    <label>
                      Would you be able to be an ally of a roommate who
                      identifies as LGTBQIA+? *
                    </label>
                  </div>
                  <div className="col-md-8">
                    <div className="row">
                      <div className="clearfix"></div>
                      <div className="col-sm-3">
                        <label className="custom_radio radio-inline">
                          Yes
                          <input
                            type="radio"
                            name="6"
                            value="Yes"
                            checked={
                              this.state.supportiveRoommate == "Yes"
                                ? true
                                : false
                            }
                            onChange={(e) =>
                              this.setChecked("supportiveRoommate", e)
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="col-sm-3">
                        <label className="custom_radio radio-inline">
                          No
                          <input
                            type="radio"
                            name="6"
                            value="No"
                            checked={
                              this.state.supportiveRoommate == "No"
                                ? true
                                : false
                            }
                            onChange={(e) =>
                              this.setChecked("supportiveRoommate", e)
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-5-px">
                  <div className="col-md-4">
                    <label>
                      Please indicate the gender identity/ies of people you
                      would be comfortable living with
                    </label>
                  </div>
                  <div className="col-md-8">
                    <div className="row">
                      <div className="clearfix"></div>
                      <div className="col-sm-3">
                        <label className="custom_radio radio-inline">
                          Male
                          <input
                            type="radio"
                            name="7"
                            value="Male"
                            onChange={(e) =>
                              this.setChecked("comfortLiving", e)
                            }
                            checked={
                              this.state.comfortLiving == "Male" ? true : false
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="col-sm-3">
                        <label className="custom_radio radio-inline">
                          Female
                          <input
                            type="radio"
                            name="7"
                            value="Female"
                            onChange={(e) =>
                              this.setChecked("comfortLiving", e)
                            }
                            checked={
                              this.state.comfortLiving == "Female"
                                ? true
                                : false
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>

                      <div className="col-sm-6">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Write-In Option"
                          value={
                            this.state.comfortLiving == "Female" ||
                            this.state.comfortLiving == "Male" ||
                            this.state.comfortLiving == ""
                              ? ""
                              : this.state.comfortLiving
                          }
                          onChange={(e) => this.setValue("comfortLiving", e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-5-px">
                  <div className="col-md-4">
                    <label>
                      Are you interested in actively pursuing Gender Inclusive
                      Housing? *
                    </label>
                    <p className="sh-input-no">
                      <i>(rooming with a person regardless of their gender)</i>
                    </p>
                  </div>
                  <div className="col-md-8">
                    <div className="row">
                      <div className="clearfix"></div>
                      <div className="col-sm-3">
                        <label className="custom_radio radio-inline">
                          Yes
                          <input
                            type="radio"
                            name="8"
                            value="Yes"
                            checked={
                              this.state.pursuingGender == "Yes" ? true : false
                            }
                            onChange={(e) =>
                              this.setChecked("pursuingGender", e)
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="col-sm-3">
                        <label className="custom_radio radio-inline">
                          No
                          <input
                            type="radio"
                            name="8"
                            value="No"
                            checked={
                              this.state.pursuingGender == "No" ? true : false
                            }
                            onChange={(e) =>
                              this.setChecked("pursuingGender", e)
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-5-px">
                  <div className="col-md-4">
                    <label>
                      Would you be able to be an ally of a roommate who has a
                      disability? *
                    </label>
                    {/* <p className="sh-input-no"><i>(rooming with a person regardless of their gender)</i></p> */}
                  </div>
                  <div className="col-md-8">
                    <div className="row">
                      <div className="clearfix"></div>
                      <div className="col-sm-3">
                        <label className="custom_radio radio-inline">
                          Yes
                          <input
                            type="radio"
                            name="9"
                            value="Yes"
                            checked={
                              this.state.roommateDisability == "Yes"
                                ? true
                                : false
                            }
                            onChange={(e) =>
                              this.setChecked("roommateDisability", e)
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="col-sm-3">
                        <label className="custom_radio radio-inline">
                          No
                          <input
                            type="radio"
                            name="9"
                            value="No"
                            checked={
                              this.state.roommateDisability == "No"
                                ? true
                                : false
                            }
                            onChange={(e) =>
                              this.setChecked("roommateDisability", e)
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-5-px">
                  <div className="col-md-4">
                    <label>
                      Would you be comfortable living with a roommate who has a
                      service/assistance animal? *
                    </label>
                  </div>
                  <div className="col-md-8">
                    <div className="row">
                      <div className="clearfix"></div>
                      <div className="col-sm-3">
                        <label className="custom_radio radio-inline">
                          Yes
                          <input
                            type="radio"
                            name="10"
                            value="Yes"
                            checked={
                              this.state.roommateAnimal == "Yes" ? true : false
                            }
                            onChange={(e) =>
                              this.setChecked("roommateAnimal", e)
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="col-sm-3">
                        <label className="custom_radio radio-inline">
                          No
                          <input
                            type="radio"
                            name="10"
                            value="No"
                            checked={
                              this.state.roommateAnimal == "No" ? true : false
                            }
                            onChange={(e) =>
                              this.setChecked("roommateAnimal", e)
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-5-px">
                  <div className="col-md-4">
                    <label>
                      Do you need accessible housing and/or an accommodation in
                      your housing? *
                    </label>
                  </div>
                  <div className="col-md-8">
                    <div className="row">
                      <div className="clearfix"></div>
                      <div className="col-sm-3">
                        <label className="custom_radio radio-inline">
                          Yes
                          <input
                            type="radio"
                            name="11"
                            value="Yes"
                            onChange={(e) =>
                              this.setChecked("accommodationHousing", e)
                            }
                            checked={
                              this.state.accommodationHousing == "Yes"
                                ? true
                                : false
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="col-sm-3">
                        <label className="custom_radio radio-inline">
                          No
                          <input
                            type="radio"
                            name="11"
                            value="No"
                            onChange={(e) =>
                              this.setChecked("accommodationHousing", e)
                            }
                            checked={
                              this.state.accommodationHousing == "No"
                                ? true
                                : false
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>

                      {this.state.accommodationHousing == "Yes" && (
                        <div className="col-sm-6">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Write-In Option"
                            value={this.state.accommodationHousingText}
                            onChange={(e) =>
                              this.setValue("accommodationHousingText", e)
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row mt-5-px">
                  <div className="col-md-4">
                    <label>
                      Do you have religious practices (such as prayers) that
                      require accommodations? *
                    </label>
                  </div>
                  <div className="col-md-8">
                    <div className="row">
                      <div className="clearfix"></div>
                      <div className="col-sm-3">
                        <label className="custom_radio radio-inline">
                          Yes
                          <input
                            type="radio"
                            name="12"
                            value="Yes"
                            onChange={(e) =>
                              this.setChecked("prayersAccommondation", e)
                            }
                            checked={
                              this.state.prayersAccommondation == "Yes"
                                ? true
                                : false
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="col-sm-3">
                        <label className="custom_radio radio-inline">
                          No
                          <input
                            type="radio"
                            name="12"
                            value="No"
                            onChange={(e) =>
                              this.setChecked("prayersAccommondation", e)
                            }
                            checked={
                              this.state.prayersAccommondation == "No"
                                ? true
                                : false
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>

                      {this.state.prayersAccommondation == "Yes" && (
                        <div className="col-sm-6">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Write-In Option"
                            value={this.state.prayersAccommondationText}
                            onChange={(e) =>
                              this.setValue("prayersAccommondationText", e)
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mt-5-px">
                  <div className="col-md-4">
                    <label>
                      Do you have a documented food allergy/dietary restriction
                      that requires accommodations? *
                    </label>
                    <p className="sh-input-no">
                      <i>(i.e. peanut or shellfish allergy)</i>
                    </p>
                  </div>
                  <div className="col-md-8">
                    <div className="row">
                      <div className="clearfix"></div>
                      <div className="col-sm-3">
                        <label className="custom_radio radio-inline">
                          Yes
                          <input
                            type="radio"
                            name="13"
                            value="Yes"
                            onChange={(e) =>
                              this.setChecked("dietaryAccommodations", e)
                            }
                            checked={
                              this.state.dietaryAccommodations == "Yes"
                                ? true
                                : false
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="col-sm-3">
                        <label className="custom_radio radio-inline">
                          No
                          <input
                            type="radio"
                            name="13"
                            value="No"
                            onChange={(e) =>
                              this.setChecked("dietaryAccommodations", e)
                            }
                            checked={
                              this.state.dietaryAccommodations == "No"
                                ? true
                                : false
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>

                      {this.state.dietaryAccommodations == "Yes" && (
                        <div className="col-sm-6">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Write-In Option"
                            value={this.state.dietaryAccommodationsText}
                            onChange={(e) =>
                              this.setValue("dietaryAccommodationsText", e)
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mt-5-px">
                  <div className="col-md-4">
                    <label>
                      Would you be willing to have a roommate who may require
                      special accommodations? *
                    </label>
                    <p className="sh-input-no">
                      <i>(religious, dietary, disability, etc)</i>
                    </p>
                  </div>
                  <div className="col-md-8">
                    <div className="row">
                      <div className="clearfix"></div>
                      <div className="col-sm-3">
                        <label className="custom_radio radio-inline">
                          Yes
                          <input
                            type="radio"
                            name="14"
                            value="Yes"
                            onChange={(e) =>
                              this.setChecked("roommateSplAccommodation", e)
                            }
                            checked={
                              this.state.roommateSplAccommodation == "Yes"
                                ? true
                                : false
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="col-sm-3">
                        <label className="custom_radio radio-inline">
                          No
                          <input
                            type="radio"
                            name="14"
                            value="No"
                            onChange={(e) =>
                              this.setChecked("roommateSplAccommodation", e)
                            }
                            checked={
                              this.state.roommateSplAccommodation == "No"
                                ? true
                                : false
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-5-px">
                  <div className="col-md-4">
                    <label>
                      Is there any additional information you would like us to
                      consider when matching you with a roommate/s or about the
                      sections above? *
                    </label>
                  </div>
                  <div className="col-md-8">
                    <div className="row">
                      <div className="clearfix"></div>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Write-In Option"
                          value={this.state.additonalInfo}
                          onChange={(e) => this.setValue("additonalInfo", e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <h1>Notes</h1>
                <div className="row">
                  <div className="col-sm-12 col-md-12">
                    <textarea
                      className="form-control"
                      placeholder="If you have a disability or special accommodations required for consideration, please note here:"
                      value={this.state.message}
                      onChange={(e) => this.setValue("message", e)}
                      rows="8"
                      cols="5"
                    ></textarea>
                  </div>
                </div>
              </form>
              {this.state.loader ? (
                <div className="panel-body_loader">
                  <span
                    style={{
                      fontSize: "25px",
                      width: "100%",
                      margin: "auto",
                      textAlign: "center",
                    }}
                  >
                    <i className="fa fa-spinner fa-spin" />
                    please wait ...!
                  </span>
                </div>
              ) : (
                <button
                  type="button"
                  className=" btn location_btn mrg-btm-70"
                  onClick={this.serviceRequest}
                >
                  {" "}
                  SUBMIT
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="container ft-contact-adrs mrg-btm-30 mrg-top-30">
          <AppleInternFooter />
        </div>
        <SubFooter />
        <Footer />
        <SuccessModal />
        <div
          className="modal fade intern-questionnaire"
          id="internship-confirmation"
          role="dialog"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content rm-border-radius">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label=""
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  By submitting an intern questionnaire, you understand that you
                  are committing to being matched with a fellow intern to share
                  a fully furnished apartment. SuiteAmerica will match you to
                  the best of their abilities with other interns in the Apple
                  Intern Program and will then send you a rental agreement via
                  DocuSign. Roommate matching and apartment rates are on a first
                  come, first serve basis.
                </p>
                <h4>Community Rates :</h4>

                <div className="downtown-text-list">
                  <h5>Suburb Communities</h5>
                  <p>
                    <i>Beaverton/Hillsboro/Portland Suburb Communities</i>
                  </p>
                  <p>1x1 (single or double occupancy): $150/day total</p>
                  <p>2x2 (double or quad occupancy): $190/day total</p>
                  <p>3x2 (single or multiple occupancy): $233/day total</p>
                </div>
                <p>
                  SuiteAmerica will make every effort to match you based on your
                  preferences listed on the intern questionnaire.{" "}
                  <strong>
                    Please note that these rates are subject to SuiteAmerica’s
                    availability and once our apartments are full, we will not
                    be able to place anymore interns.
                  </strong>{" "}
                  Therefore, it is recommended to send in your housing request
                  as soon as possible.
                </p>
                <p>
                  <strong>Please Note: </strong> Once rental paperwork has been
                  completed, you are obligated to the suite and move-in/move-out
                  dates may not be altered.
                </p>
                <div className="terms-wrap">
                  <h3>Have your read and agree to the terms?</h3>
                  <div className="terms-form">
                    <form role="form" method="post">
                      <label className="custom_checkbox checkbox-inline">
                        <span>Yes</span>
                        <input
                          name="Yes"
                          type="radio"
                          value="Yes"
                          onChange={(e) => this.selection(e)}
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="custom_checkbox checkbox-inline">
                        <span>No</span>
                        <input
                          name="No"
                          type="radio"
                          value="No"
                          onChange={(e) => this.selection(e)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </form>
                  </div>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade savesearch"
          id="applesuccessmodal"
          role="dialog"
        >
          <div className="modal-dialog">
            <div className="modal-content rm-border-radius">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label=""
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <p style={{ margin: "13px 0px 30px", fontWeight: "700" }}>
                  Your Request has been Submitted Successfully
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AppleInternshipQuestionnaire;
