import MainController from "../../controller/mainController";
import SubFooter from "../../common/subFooter";
import {Link} from 'react-router-dom';
import GuestHeader from '../../common/guestHeader';
import Footer from '../../common/footer';
import MetaTags from '../../common/metaTags';
import ServerMetaTags from '../../../server/metaTags';
import MediaKitData from './common/mediaKit.json';

/* MediaKit Component initialization */
class MediaKit extends React.Component {
    /* Initializing objects of its MediaKit class */
    constructor(props) {
        super(props);
        this.state = {
            mediaKitData : MediaKitData   
        }
    }
    /* It is invoked immediately before mounting occurs */
    componentWillMount() {
        MainController.getInitialInformation();
    }
    render() {
        const metaData = ServerMetaTags.urlString.getMetaData(this.props.match.url);
        return (
            <div>
            <MetaTags 
             metaTitle = {metaData.title}
             metaDesription = {metaData.description}
             metaUrl = {metaData.canonical} />
             <GuestHeader/>                
             <div className="services_ch">
                <div className="carousel banner_text">
                   <img className="img-responsive relocation-corporate-housing" src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Media-Kit-Banner.jpg"} alt="" />
                   <div className="img-on-text width500 left40 top40 width500 text-center img-on-text-center img-on-text-center-w wow fadeInUp"  data-wow-delay="0.5s">       
                      <h3 className="animated font300">MEDIA KIT</h3>
                   </div>
                </div>
             </div>
             <div className="quote-section bg-white">
                <div className="container mediakit">
                   <div className="plaine-text global-coverage-wrap col-lg-12 col-lg-offset-0 col-md-10 col-md-offset-1 wow fadeInUp" data-wow-delay="0.5s">
                      
                      <p>The following resources including but not limited to a brief corporate summary and breakdown of services 
                      have been provided on behalf of SuiteAmerica for journalistic purposes. To obtain a PDF of the contents 
                      provided in this kit, please reach out to our Media Contact for more information.
                      </p>
                      <div className="row mediakit_list">
                      {this.state.mediaKitData.map((row, index) => {
                        return (
                              <div key={index} className="col-sm-6 col-md-4">
                                    <Link to={row.url}>{row.name}</Link>
                              </div>
                           )
                         }
                      )}
                      </div>
                   </div>
                </div>
             </div>             
             <SubFooter />
             <Footer/>
            </div>
        );
    }
}
export default MediaKit;