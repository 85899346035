import React, { useState, useEffect, useCallback, memo, useRef } from "react";
import BookitApiService from "../../../../services/bookit";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Input } from "antd";
import { Link } from "react-router-dom";
import { Order, Reservation, Schedule } from "./modals";
import { SuiteCareContext, SuiteCareProvider } from "./context/Context";

// import moment from "moment";

// Parent Component
const InventorySuiteCare = () => {
  return (
    <SuiteCareProvider>
      <MemoizedFirstSiblingComponent />
      <Order />
      <Schedule />
      <Reservation />
    </SuiteCareProvider>
  );
};

// First Sibling Component
const FirstSiblingComponent = () => {
  const {
    suiteCareState,
    selectedDate,
    checkAndStoreSessionDetails,
    getClientData,
    changeActiveTab,
    handleSelectedDate,
    selectCity,
    filterAggregatedData,
    changeSearchtext,
  } = React.useContext(SuiteCareContext);

  const { session, activeTab } = suiteCareState;
  const { ClientProfileCardDetails } = session;
  const Availability = ClientProfileCardDetails?.Availability;

  useEffect(() => {
    checkAndStoreSessionDetails();
    $(document).ready(function () {
      jQuery(function ($) {
        $("#phoneNumber").intlTelInput();
      });
    });
  }, [suiteCareState.refresh]);

  useEffect(() => {
    if (suiteCareState.activeTab) getClientData();
  }, [suiteCareState.activeTab]);

  useEffect(() => {
    filterAggregatedData();
  }, [suiteCareState.selectedCity, suiteCareState.searchedText]);

  useEffect(() => {
    $(document).ready(function () {
      jQuery(function ($) {
        $("#phoneNumber").intlTelInput();
      });
    });
  }, []);
  const addDays = (date, days) => {
    const result = new Date(date); // Create a copy of the date
    result.setDate(result.getDate() + days); // Add days
    return result;
  };
  return (
    <div>
      <div className="sm-d-flex align-items-center justify-content-between">
        <div>
          {(Availability == "Normal" || Availability == "Both") && (
            <button
              className={
                activeTab == "normal"
                  ? "btn btn-dark mt-4 m-r-5 "
                  : "  btn btn-dark-active mt-4 m-r-5 "
              }
              onClick={() => changeActiveTab("normal")}
            >
              Normal Units
            </button>
          )}
          {(Availability == "Rotational" || Availability == "Both") && (
            <button
              className={
                activeTab == "billable"
                  ? "btn btn-dark mt-4"
                  : " btn btn-dark-active mt-4"
              }
              onClick={() => changeActiveTab("billable")}
            >
              Rotational Units
            </button>
          )}
        </div>
        {suiteCareState.cities.length > 0 && (
          <select
            className=" form-control mt-4"
            placeholder="Select a city"
            value={suiteCareState.selectedCity}
            onChange={(e) => selectCity(e.target.value)}
            style={{ width: "180px" }}
          >
            <option value={"All"}>All</option>
            {suiteCareState.cities.map((city, index) => (
              <option key={index} value={city}>
                {city}
              </option>
            ))}
          </select>
        )}
      </div>

      <div className="d-flex align-items-center justify-content-between my-3">
        <Input.Search
          placeholder="Search"
          style={{
            width: 200,
          }}
          value={suiteCareState.searchedText}
          onChange={(e) => changeSearchtext(e.target.value)}
        />
        <div className="d-flex ">
          <div className="caldenerWidth  me-4">
            <DatePicker
              id="moveIn"
              selected={selectedDate}
              dateFormat="MM/DD/yyyy"
              selectsStart
              minDate={addDays(new Date(), -180)}
              maxDate={addDays(new Date(), 90)}
              onChange={(date) => handleSelectedDate(date)}
              // placeholderText={"Service Request Date"}
              onKeyDown={(e) => e.preventDefault()}
              className="form-control width100per"
              autoComplete="off"
            />
          </div>
          <ul className="indicators">
            <li>
              <i className="fa fa-square"></i> Active
            </li>
            <li>
              <i className="fa fa-square"></i> Upcoming
            </li>
            <li>
              <i className="fa fa-square"></i> Moved Out
            </li>
          </ul>
        </div>
      </div>

      <div className=" calendarViewWrapper p-0">
        {suiteCareState.pageLoading ? (
          <div
            className="text-center"
            style={{
              fontSize: "20px",
            }}
          >
            <i className="fa fa-spinner fa-spin" />
            Please wait ...!
          </div>
        ) : suiteCareState.ordersMastersByProgram.length > 0 ? (
          <Calendar />
        ) : (
          <div className="error-sms-Quote mrg-top-30">
            <span className="alert alert-danger">
              <i
                className="fa fa-exclamation-circle mrg-right-10"
                aria-hidden="true"
              ></i>
              No Data Available
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

const MemoizedFirstSiblingComponent = memo(FirstSiblingComponent);

const Calendar = () => {
  const {
    suiteCareState,
    openScheduleModal,
    openOrderDetailsModal,
    openNewReservationModal,
  } = React.useContext(SuiteCareContext);
  const { aggregatedMastersDataFiltered, defaultdaterange } = suiteCareState;

  const widthRef = useRef(null);
  const targetDivRef = useRef(null);

  useEffect(() => {
    if (widthRef.current && targetDivRef.current) {
      const width = widthRef.current.offsetWidth; // Use offsetWidth for reliable width
      targetDivRef.current.style.width = `${width}px`;
      // console.log(width, "width");

      var tableContainer = $(".large-table-container-3");

      var fakeContainer = $(".large-table-fake-top-scroll-container-3");

      fakeContainer.scroll(function () {
        tableContainer.scrollLeft(fakeContainer.scrollLeft());
      });
      tableContainer.scroll(function () {
        fakeContainer.scrollLeft(tableContainer.scrollLeft());
      });
    }
  }, [aggregatedMastersDataFiltered]);

  // generate empty boxes
  const generateEmptyBoxes = (numberOfBoxes) => {
    return [...Array(numberOfBoxes)].map(() => <td></td>);
  };

  function renderSingleRow(row, masterData) {
    // Create an array with 90 empty slots
    const days = [];
    let indicator = 0;
    for (let i = 0; i < 90; i++) {
      if (
        row.length &&
        indicator < row.length &&
        i == row[indicator].startIndex
      ) {
        i = i + row[indicator].span - 1;

        row[indicator].guestName
          ? days.push(
              <td
                colSpan={row[indicator].span}
                style={{
                  backgroundColor: row[indicator].color,
                  textAlign: "left",
                  color: "black",
                  textOverflow: "clip",
                  maxWidth: 40,
                  overflow: "hidden",
                  textWrap: "nowrap",
                  paddingLeft: "20px",
                  fontWeight: "bold",
                }}
                data-order={JSON.stringify(row[indicator])}
                data-master={JSON.stringify(masterData)}
                onClick={(e) => orderDetailsPopUpModal(e)}
              >
                {row[indicator].guestName}
              </td>
            )
          : days.push(
              <td
                colSpan={row[indicator].span}
                style={{
                  backgroundColor: row[indicator].color,
                  color: "black",
                  fontWeight: "bold",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                data-schedule={JSON.stringify(row[indicator])}
                onClick={(e) => {
                  openSchedulePopUpModal(e);
                }}
              >
                {/* <img src="/images/bookit/HKA.png" width="30px" alt="sch" /> */}
                {row[indicator].TypeCode == "ROT" ? "RC" : "FC"}
              </td>
            );
        // row.shift();
        indicator++;
      } else {
        days.push(<td></td>);
      }
    }

    return <tr style={{ height: 36 }}>{days}</tr>;
  }

  // Function to render rows
  const renderBookingRows = (masterData, dateRange, index) => {
    const rows = masterData.rows;
    // console.log(rows, "rowsrows");

    if (rows.length == 0) {
      return (
        <tr key={index} style={{ height: 36 }}>
          {generateEmptyBoxes(dateRange.length)}
          <td colSpan={dateRange.length}></td>
        </tr>
      );
    }

    return rows.map((row) => {
      return renderSingleRow(row, masterData);
    });
  };

  // const renderMonthHeaders = (defaultdaterange) => {
  //   let month = "";
  //   let startIndex = 0;
  //   let jsx = defaultdaterange.map((row, index) => {
  //     if (index == 0) {
  //       month = moment().format("MMMM");
  //     } else {
  //       let currentMonth = moment().add(index, "days").format("MMMM");
  //       // FOR THE LAST MONTH
  //       if (currentMonth == month) {
  //         if (index + 1 == defaultdaterange.length) {
  //           return (
  //             <th
  //               className="text-center"
  //               colSpan={index + 1 - startIndex}
  //               key={index}
  //             >
  //               {currentMonth}
  //             </th>
  //           );
  //         }
  //       } else {
  //         let prevMonth = JSON.parse(JSON.stringify(month));
  //         let tempIndex = JSON.parse(JSON.stringify(startIndex));
  //         month = currentMonth;
  //         startIndex = JSON.parse(JSON.stringify(index));
  //         return (
  //           <th className="text-center" colSpan={index - tempIndex} key={index}>
  //             {prevMonth}
  //           </th>
  //         );
  //       }
  //     }
  //   });

  //   return jsx;
  // };

  // ----------------------------------------------------------------
  // Modals
  // ----------------------------------------------------------------

  const renderMonthHeaders = (defaultdaterange) => {
    if (defaultdaterange.length === 0) return null;

    let headers = [];
    let startIndex = 0;
    let month = moment(defaultdaterange[0]).format("MMMM");

    for (let i = 0; i < defaultdaterange.length; i++) {
      let currentDate = moment(defaultdaterange[i]);
      let currentMonth = currentDate.format("MMMM");

      // Check if month changes or if we're at the last date
      if (currentMonth !== month || i === defaultdaterange.length - 1) {
        let colSpan = i - startIndex;

        // If we're at the last date, include the last column in the colspan
        if (i === defaultdaterange.length - 1) {
          colSpan += 1;
        }

        headers.push(
          <th className="text-center" colSpan={colSpan} key={`month-${i}`}>
            {month}
          </th>
        );

        // Update month and startIndex for the new month
        month = currentMonth;
        startIndex = i;
      }
    }
    // console.log(headers, "headersheaders");

    return headers;
  };

  const renderDateHeaders = (defaultdaterange) => {
    if (defaultdaterange.length === 0) return null;

    let headers = [];
    for (let i = 0; i < defaultdaterange.length; i++) {
      let currentDate = moment(defaultdaterange[i]);
      let formattedDate = currentDate.format("DD");
      let dayOfWeek = currentDate.format("dddd");

      headers.push(
        <th key={i}>
          <span className={dayOfWeek.startsWith("S") ? "dayred" : ""}>
            {formattedDate}
          </span>
        </th>
      );
    }
    // console.log(headers, "renderDateHeadersrenderDateHeaders");

    return headers;
  };

  const orderDetailsPopUpModal = (e) => {
    const order = e.currentTarget.getAttribute("data-order");
    const master = e.currentTarget.getAttribute("data-master");
    // console.log(order, master);
    openOrderDetailsModal(order, master);
  };

  const openSchedulePopUpModal = (e) => {
    const schedule = e.currentTarget.getAttribute("data-schedule");
    openScheduleModal(JSON.parse(schedule));
  };

  return (
    <div className="calendarViewWrapper p-0">
      <div className="calendarView s-care">
        {/* left table */}
        <div className="">
          <table className="sideTable">
            <thead className="sideHeader">
              <tr>
                {/* <th rowspan="2">Master#</th> */}
                <th rowspan="2">Community</th>
                <th rowspan="2">Size</th>
                <th rowspan="2">SuiteNo</th>
                {suiteCareState.activeTab == "billable" ? (
                  <th rowSpan="2">BR</th>
                ) : null}
              </tr>
            </thead>

            <tbody>
              {aggregatedMastersDataFiltered.map((masterData, index) => {
                let communityName = masterData.communityname;
                const changedCommunityName = communityName?.replace(
                  /\s+/g,
                  "-"
                );
                return (
                  <tr
                    key={index}
                    style={{ height: 36 * masterData.rows.length || 36 }}
                  >
                    {/* <td>{masterData.masterId}</td> */}
                    {/* <td>{masterData.communityname}</td> */}
                    <td>
                      <Link
                        to={`/community/suiteamerica/${changedCommunityName}`}
                        target={"_blank"}
                      >
                        {masterData.communityname
                          .toLowerCase()
                          .replace(/\b\w/g, (c) => c.toUpperCase())}
                        {/* {text} */}
                      </Link>
                    </td>
                    <td>{masterData.suitesize}</td>
                    <td onClick={() => openNewReservationModal(masterData)}>
                      <a className="">{masterData.suiteno}</a>
                    </td>
                    {suiteCareState.activeTab == "billable" ? (
                      <td>{masterData.BR}</td>
                    ) : null}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        {/* right table */}
        <div className="table-responsive" style={{ marginTop: "-7px" }}>
          <div className="large-table-fake-top-scroll-container-3">
            <div ref={targetDivRef}>&nbsp;</div>
          </div>
          <div className="large-table-container-3">
            <table className="mainTable" ref={widthRef}>
              {/* Months Header */}
              <thead className="heading">
                <tr>{renderMonthHeaders(defaultdaterange)}</tr>
              </thead>

              {/* dates Header */}
              <thead>
                {/* <tr>
                  {defaultdaterange.map((row, index) => {
                    return (
                      <th key={index}>
                        <span
                          className={
                            moment()
                              .add(index, "days")
                              .format("dddd")
                              .startsWith("S")
                              ? "dayred"
                              : ""
                          }
                        >
                          {moment().add(index, "days").format("DD")}
                        </span>
                      </th>
                    );
                  })}
                </tr> */}
                <tr>{renderDateHeaders(defaultdaterange)}</tr>
              </thead>

              {/* orders */}
              <tbody>
                {aggregatedMastersDataFiltered.map((masterData, index) =>
                  renderBookingRows(masterData, defaultdaterange, index)
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventorySuiteCare;
