/* Importing the node modules, child components, services and controllers used 
   inside NikeLocations component */
import MainController from "../../controller/mainController";
import SubFooter from "../../common/subFooter";
import LinkedinInternsHeader from "./common/LinkedinInternsHeader";
import Location from "../location/locations";
import FAQ from "./common/LinkedinInternsFaq";
import Footer from "../../common/footer";
import Disclaimer from "./common/disclimar";
import SubFooterLinkedIn from "./common/subFooterlinkedIn";
/* NikeLocations Component initialization */
class LinkedinInternsLocations extends React.Component {
  /* Initializing objects of its NikeLocations class */
  constructor(props) {
    super(props);
    this.state = {
      companyname: "Abc",
    };
  }
  /* It is invoked immediately before mounting occurs */
  componentWillMount() {
    MainController.getInitialInformation();
  }
  /* It is invoked to return html content */
  render() {
    return (
      <div className="corporateHousing_Updates">
        <LinkedinInternsHeader />
        <div className="quote-section bg-white bottomSpaceHide">
          <h2>Sample Locations</h2>
        </div>
        <Location companyName={this.state.companyname} props={this.props} />
        <Disclaimer />
        {/* <SubFooter /> */}
        <SubFooterLinkedIn />
        <Footer />
      </div>
    );
  }
}
export default LinkedinInternsLocations;
