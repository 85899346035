const ScheduleMapViewHeading = props => {
    return (
        <div className="container">
              <div className="header-wrap">
                    <h1 className="align-center content-mail-title">Drivers Schedule</h1>
                    <div className="view-wrap align-center">
                        <div className="view-list-f">
                            <div className="view-list-f-item"><a className={props.activeIndex == 0 ? 'active-index' : null} onClick={()=> props.changeIndex(0)}><i className="fa fa-map-o active"></i> Map View</a></div>
                            <div className="view-list-f-item"><a className={props.activeIndex == 1 ? 'active-index' : null} onClick={()=> props.changeIndex(1)}><i className="fa fa-list-ul"></i> Schedule View </a></div>
                        </div>
                    </div>
              </div>
        </div>

    );
  };
  export default ScheduleMapViewHeading;