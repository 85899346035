import React, { useEffect, useState } from "react";
import SideBar from "./Common/SideBar";
import NewHeader from "./Common/NewHeader";
import NewFooter from "./Common/NewFooter";
import BookitApiService from "../../../services/bookit";
import Loader from "../../common/loader";
import { useHistory } from "react-router-dom";

const Reserved = () => {
  const [state, setState] = useState({
    isLoggedIn: null,
  });
  const history = useHistory();

  async function isUserLoggedIn() {
    try {
      const sessionResponse = await BookitApiService.checkSession();
      if (!sessionResponse.loggedIn) {
        return false;
      }
      return true;
    } catch (err) {
      return false;
    }
  }

  async function checkUserLoginStatus() {
    if (!(await isUserLoggedIn())) {
      updateState({ isLoggedIn: false });
      return;
    }
    updateState({ isLoggedIn: true });
  }

  function updateState(updateObj) {
    setState((prevState) => ({ ...prevState, ...updateObj }));
  }

  useEffect(() => {
    checkUserLoginStatus();
  }, []);

  if (state.isLoggedIn === true) {
    return (
      <div id="wrapper" className="sa-admin">
        <SideBar />

        <div className="content-page">
          <div className="content">
            <NewHeader />
            <div className="page-content-wrapper">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="page-title-box">
                      <div className="btn-group float-right">
                        <ol className="breadcrumb hide-phone p-0 m-0">
                          <li className="breadcrumb-item">
                            <a href="#">SuiteAmerica</a>
                          </li>
                          <li className="breadcrumb-item active">Book Now</li>
                        </ol>
                      </div>
                      <h4 className="page-title">Book Now</h4>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6">
                    <div className="card m-b-30">
                      <div className="card-body">
                        <h4 className="header-title mt-0">Personal Info</h4>

                        <div className="row form-material">
                          <div className="col-md-6">
                            <div className="form-group">
                              <h6 className="text-muted fw-400">Check In *</h6>

                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="mm/dd/yyyy"
                                  id="datepicker"
                                />
                                <div className="input-group-append bg-custom b-0">
                                  <span className="input-group-text">
                                    <i className="mdi mdi-calendar"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <h6 className="text-muted fw-400">Check Out *</h6>

                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="mm/dd/yyyy"
                                  id="datepicker"
                                />
                                <div className="input-group-append bg-custom b-0">
                                  <span className="input-group-text">
                                    <i className="mdi mdi-calendar"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row form-material">
                          <div className="col-md-6">
                            <div className="form-group">
                              <h6 className="text-muted fw-400">First Name</h6>

                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="First Name"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <h6 className="text-muted fw-400">Last Name</h6>

                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="last Name"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row form-material">
                          <div className="col-md-6">
                            <div className="form-group">
                              <h6 className="text-muted fw-400">
                                Phone Number
                              </h6>

                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Phone Number"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <h6 className="text-muted fw-400">Email</h6>

                              <div className="input-group">
                                <input
                                  type="email"
                                  className="form-control"
                                  placeholder="Email"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row form-material">
                          <div className="col-md-6">
                            <div className="form-group">
                              <h6 className="text-muted fw-400">
                                Reservedby Email *
                              </h6>

                              <div className="input-group">
                                <input
                                  type="email"
                                  className="form-control"
                                  placeholder="Reservedby Email *"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <h6 className="text-muted fw-400">
                                Cost center*
                              </h6>

                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Cost center*"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row form-material">
                          <div className="col-md-12">
                            <div className="form-group">
                              <h6 className="text-muted fw-400">Notes</h6>

                              <div className="input-group">
                                <textarea
                                  required=""
                                  className="form-control"
                                  rows="3"
                                  placeholder="Notes"
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg 6">
                    <div className="card m-b-30">
                      <div className="card-body">
                        <h4 className="header-title mt-0">Arrival Info</h4>
                        <div className="row form-material">
                          <div className="col-md-6">
                            <div className="form-group">
                              <h6 className="text-muted fw-400">Flight#</h6>

                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Flight#"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <h6 className="text-muted fw-400">
                                Arrival Airport
                              </h6>

                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Arrival Airport"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row form-material">
                          <div className="col-md-6">
                            <div className="form-group">
                              <h6 className="text-muted fw-400">
                                Arrival Date *
                              </h6>

                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="mm/dd/yyyy"
                                  id="datepicker"
                                />
                                <div className="input-group-append bg-custom b-0">
                                  <span className="input-group-text">
                                    <i className="mdi mdi-calendar"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <h6 className="text-muted fw-400">Airline</h6>

                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Airline"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row form-material">
                          <div className="col-md-6">
                            <div className="form-group">
                              <h6 className="text-muted fw-400">
                                Month and day of birth
                              </h6>

                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="mm/dd/yyyy"
                                  id="datepicker"
                                />
                                <div className="input-group-append bg-custom b-0">
                                  <span className="input-group-text">
                                    <i className="mdi mdi-calendar"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <h6 className="text-muted fw-400">
                                Emergency contact name
                              </h6>

                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Emergency contact name*"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row form-material">
                          <div className="col-md-6">
                            <div className="form-group">
                              <h6 className="text-muted fw-400">
                                Emergency contact number
                              </h6>

                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Emergency contact number*"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row form-material">
                          <div className="col-md-12">
                            <div className="form-group">
                              <h6 className="text-muted fw-400">
                                Arrival Notes
                              </h6>

                              <div className="input-group">
                                <textarea
                                  required=""
                                  className="form-control"
                                  rows="3"
                                  placeholder="Arrival Notes"
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="text-center">
                  <button type="button" className="btn btn btn-dark px-4 py-2">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
          <NewFooter />
        </div>
      </div>
    );
  } else if (state.isLoggedIn === false) {
    history.push("/bookit");
  }
  return <Loader />;
};

export default Reserved;
