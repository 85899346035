import DatePicker from 'react-datepicker';
import moment from "moment";
import NikeServices from '../../../services/nikeService';
class Hiro extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      erromsg: false,
      success: false,
      name: '',
      email: '',
      phoneNumber: '',
      moveInDate: null,
      moveOutDate: null,
      moveInDateSelected: '',
      moveOutDateSelected: '',
      notes: '',
      size: '',
      erromsg: false,
      successmsg: false
    }
    this.openBoking = this.openBoking.bind(this)
    this.serviceRequest = this.serviceRequest.bind(this)
    this.handleMoveIn = this.handleMoveIn.bind(this)
    this.handleMoveOut = this.handleMoveOut.bind(this)
    this.inputHandler = this.inputHandler.bind(this)
    this.scrollSection = this.scrollSection.bind(this)


  }
  componentDidMount() {
    let width = $(window).width()
    console.log(width, '-----')
if(width >= 575){
  $('#third').click(function(){
  styles('#fourth', 0, 0, 0, 1, shadow);
  styles('#first', 30, 90, -40, 0.4, 'none');
  styles('#second', 55, 170, -70, 0.1, 'none'); 
  styles('#third', 30, -65, -40, 0.8, 'none');
 });
 $('#fourth').click(function(){

  styles('#first', 0, 0, 0, 1, shadow);
  styles('#second', 30, 90, -40, 0.4, 'none');
  styles('#third', 55, 170, -70, 0.1, 'none'); 
  styles('#fourth', 30, -65, -40, 0.8, 'none');
});
 $('#first').click(function(){
 
  styles('#second', 0, 0, 0, 1, shadow);
  styles('#third', 30, 90, -40, 0.4, 'none');
  styles('#fourth', 55, 170, -70, 0.1, 'none'); 
  styles('#first', 30, -65, -40, 0.8, 'none');
 });
 $('#second').click(function(){

  styles('#third', 0, 0, 0, 1, shadow);
  styles('#fourth', 30, 90, -40, 0.4, 'none');
  styles('#first', 55, 170, -70, 0.1, 'none'); 
  styles('#second', 30, -65, -40, 0.8, 'none');
 });
  }else{

    $('#third').click(function(){
      styles('#fourth', 0, 0, 0, 1, shadow);
      styles('#first', 30, 55, -40, 0.4, 'none');
      styles('#second', 55, 110, -70, 0.1, 'none'); 
      styles('#third', 30, -30, -40, 0.8, 'none');
     });
     $('#fourth').click(function(){
    
      styles('#first', 0, 0, 0, 1, shadow);
      styles('#second', 30, 55, -40, 0.4, 'none');
      styles('#third', 55, 100, -70, 0.1, 'none'); 
      styles('#fourth', 30, -30, -40, 0.8, 'none');
    });
     $('#first').click(function(){
     
      styles('#second', 0, 0, 0, 1, shadow);
      styles('#third', 30, 55, -40, 0.4, 'none');
      styles('#fourth', 55, 110, -70, 0.1, 'none'); 
      styles('#first', 30, -30, -40, 0.8, 'none');
     });
     $('#second').click(function(){
    
      styles('#third', 0, 0, 0, 1, shadow);
      styles('#fourth', 30, 55, -40, 0.4, 'none');
      styles('#first', 55, 110, -70, 0.1, 'none'); 
      styles('#second', 30, -30, -40, 0.8, 'none');
     });
  }

    var shadow = '0 20px 50px rgba(0,34,45,0.5)';
    function styles(item_id, x, y, z, opacity, shadow) {
      console.log(item_id, "===", shadow)
      $(item_id).css({
        transform: 'translate3d(' + x + 'px, ' + y + 'px, ' + z + 'px) ',
        opacity: opacity,
        'box-shadow': shadow
      });
    }
  }

  async openBoking() {
    $(document).ready(function () {
      jQuery(function ($) {
        $("#phoneNumber").intlTelInput("setCountry", "us");
      });
    });
    $('#name').removeClass('validation');
    $('#email').removeClass('validation');
    $('#phoneNumber').removeClass('validation');
    $('#checkIn').removeClass('validation');
    $('#checkOut').removeClass('validation');
    $('#size').removeClass('validation');
    $('#notes').removeClass('validation');
    await this.setState({
      name: '',
      email: '',
      phoneNumber: '',
      moveInDate: null,
      moveOutDate: null,
      size: '',
      notes: '',
      loader: false,
      successmsg: false,
      erromsg: false
    });
    $(document).ready(function () {
      jQuery(function ($) {
        $("#phoneNumber").intlTelInput();
        $('#hiro_contactus').modal('show');
      });
    });
  }
  async serviceRequest(e) {
    e.preventDefault();
    const countryCode = $(".country.highlight.active").attr("data-dial-code") == undefined
      ? "+1"
      : "+" + $(".country.highlight.active").attr("data-dial-code")
    let errorVal = 0;
    const _this = this;
    await this.setState({ loader: true });
    if (this.state.name == '') {
      $('#name').addClass('validation');
      await this.setState({ loader: false });
      errorVal++;
    } else {
      $('#name').removeClass('validation');
    }
    if (this.state.email == '' || !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email))) {
      $('#email').addClass('validation');
      await this.setState({ loader: false });
      errorVal++;
    } else {
      $('#email').removeClass('validation');
    }
    if (this.state.phoneNumber == '') {
      $('#phoneNumber').addClass('validation');
      await this.setState({ loader: false });
      errorVal++;
    } else {
      $('#phoneNumber').removeClass('validation');
    }
    if (this.state.moveInDateSelected == '') {
      $('#checkIn').addClass('validation');
      await this.setState({ loader: false });
      errorVal++;
    } else {
      $('#checkIn').removeClass('validation');
    }
    if (this.state.moveOutDateSelected == '') {
      $('#checkOut').addClass('validation');
      await this.setState({ loader: false });
      errorVal++;
    } else {
      $('#checkOut').removeClass('validation');
    }
    if (this.state.size == '') {
      $('#size').addClass('validation');
      await this.setState({ loader: false });
      errorVal++;
    } else {
      $('#size').removeClass('validation');
    }
    if (this.state.notes == '') {
      $('#notes').addClass('validation');
      await this.setState({ loader: false });
      errorVal++;
    } else {
      $('#notes').removeClass('validation');
    }
    if (errorVal == 0) {
      let data = {
        name: this.state.name,
        email: this.state.email,
        phone: countryCode + ` ` + this.state.phoneNumber,
        checkIn: moment(this.state.moveInDateSelected).format('MM/DD/YYYY'),
        checkOut: moment(this.state.moveOutDateSelected).format('MM/DD/YYYY'),
        size: this.state.size,
        Notes: this.state.notes,
        mail: "hiroContactUs",
        subject: "Hiro Booking Information"
      }
      let sendata = await NikeServices.serviceRequest(data)
      await this.setState({ successmsg: true, erromsg: false, loader: false });

      // if (sendata.error == 0) {
      //   await this.setState({ successmsg: true, erromsg: false, loader: false });
      // } else {
      //   await this.setState({ successmsg: false, erromsg: true, loader: false });
      // }

      // console.log(data, 'data')
    }
  }
  async handleMoveIn(date) {
    await this.setState({
      moveInDate: moment(date),
      moveInDateSelected: date.format('YYYY-MM-DD HH:mm:ss')
    });
  }
  async handleMoveOut(date) {
    await this.setState({
      moveOutDate: moment(date),
      moveOutDateSelected: date.format('YYYY-MM-DD HH:mm:ss')
    });
  }

  async inputHandler(inputName, event) {
    event.persist();
    let inputVal = event.target.value;
    let inputObject = {};
    inputObject[inputName] = inputVal;
    if (inputName == "emailaddress" && !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(inputVal.trim()))) {
      $("#" + inputName).addClass('validation');
    }
    else {
      $("#" + inputName).removeClass('validation');
    }
    await this.setState(inputObject);
    if (inputName == 'phoneNumber') {
      var x = event.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      event.target.value = !x[2] ? x[1] : ('(' + x[1] + ') ' + x[2] + (x[3] && '-' + x[3] || ''))
      await this.setState({ phoneNumber: event.target.value })
    }
    event.persist();
  };

  scrollSection(id) {
    $('html,body').animate(
      {
        scrollTop: $("#" + id).offset().top
      }, 'slow');

  }
  render() {
    return (
      <div id="getwidth">
        <div className="SAweb_hiraComm">

          <header id="header" className="fixed-top">
            <div className="container">
              <nav className="navbar">
                <div className="d-flex align-items-center sm-flex-wrap">
                  <div className="navbar-header">
                    <a className="navbar-brand" href="javascript:void(0)" onClick={() => this.scrollSection('home')} >
                      <img src="/assets/newUI-images/SA-logo-icon-W-noSpace.svg" alt="" />
                    </a>
                    <button
                      type="button"
                      className="navbar-toggle collapsed"
                      data-toggle="collapse"
                      data-target="#navbar"
                      aria-expanded="false"
                      aria-controls="navbar"
                    >
                      <span className="sr-only">Toggle navigation</span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                    </button>
                  </div>
                  <div id="navbar" className="navbar-collapse collapse">
                    <ul className="nav m-auto">
                      <li className="nav-item">
                        <a className="nav-link text-white" onClick={() => this.scrollSection('locationBlock')}
                        >Location</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link text-white" onClick={() => this.scrollSection('amenitiesBlock')}
                        >Amenities</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link text-white" onClick={() => this.scrollSection('technologyBlock')}
                        > Technology</a>
                      </li>
                    </ul>

                    <form className="d-flex">
                      <button className="btn red-btn" type="button" onClick={() => this.openBoking()} >
                        Book Now
                      </button>
                    </form>
                  </div>
                </div>
              </nav>
            </div>
          </header>


          <div className="fixedNavSpace"></div>


          <div id="fullview">
            <div id="home" className=" bannerImage" >
              <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/images-website/Hiro.jpeg" width="100%" alt="" />

              <div className="bannerText">
                <img src="/assets/newUI-images/hiro/hiro_logo.svg" alt="" />
                <h2>
                  by <span>SUITE<b>AMERICA</b></span>
                </h2>
               
              </div>

            </div>
            <div id="locationBlock" className="addTopSpace">
              <div className="container">
              <div className="top text-center">
                <h3> UNRAVEL AN EXCLUSIVE CORPORATE HOUSING EXPERIENCE</h3>
                <p>
                  Get ready to explore corporate housing at its best with HIRO
                  <span> - managed and operated by SuiteAmerica.</span>
                </p>
              </div>

              <div className="location-cont">
                <div className="item">
                  <h3>FIND YOURSELF AT THE LOCUS OF ALL THINGS GREAT</h3>
                  <p>
                    Cut down on commute time with a boutique property situated right
                    in the heart of Silicon Valley.
                  </p>
                  {/* <button type="button" className="btn location-button">
                    Explore the area
                  </button> */}
                </div>
                <div className="item">
                  <img src="/assets/newUI-images/hiro/location-map.png" alt="" />
                </div>
              </div>
              </div>

            </div>
            <div id="amenitiesBlock" className="addTopSpace" >
              <div className="container">

                <div className="vSlider">
                  <div className="l-realise-slider-card-block">
                    <div className="card-block-wrap">
                      <div className="card a" id="first">
                        <img src="/assets/newUI-images/hiro/3dslider/slide-1.png" alt="" />
                      </div>
                      <div className="card b" id="second">
                        <img src="/assets/newUI-images/hiro/3dslider/slide-2.png" alt="" />
                      </div>
                      <div className="card c" id="third">
                        <img src="/assets/newUI-images/hiro/3dslider/slide-3.png" alt="" />
                      </div>
                      <div className="card d" id="fourth">
                        <img src="/assets/newUI-images/hiro/3dslider/slide-4.png" alt="" />
                      </div>


                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div id="technologyBlock" className="addTopSpace" >
            <div className="container">
              <div className="techView">
                <h3>
                  <span> TECHNOLOGY </span> IS ALWAYS AT OUR CORE FOR A SEAMLESS
                  GUEST EXPERIENCE
                </h3>
                <div className="tails">
                  <div className="item">
                    <div className="icons">
                      <img
                        src="/assets/newUI-images/hiro/keyless-icon.png"
                        width="100%"
                        height="100%"
                      />
                    </div>
                    <div className="text">
                      <p>Keyless Entry <span> with Latch</span></p>
                    </div>
                  </div>
                  <div className="item">
                    <div className="icons">
                      <img
                        src="/assets/newUI-images/hiro/wifi-icon.png"
                        width="100%"
                        height="100%"
                      />
                    </div>
                    <div className="text">
                      <p>High-speed Wi-Fi <span> across the community</span></p>
                    </div>
                  </div>
                  <div className="item">
                    <div className="icons">
                      <img src="/assets/newUI-images/hiro/tv-icon.png" width="100%" height="100%" />
                    </div>
                    <div className="text">
                      <p>4K TV in all <span> bedrooms</span></p>
                    </div>
                  </div>
                  <div className="item">
                    <div className="icons">
                      <img
                        src="/assets/newUI-images/hiro/automation-icon.png"
                        width="100%"
                        height="100%"
                      />
                    </div>
                    <div className="text">
                      <p>Home automation <span> and digital thermostats</span></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="section5">
            <div className="container">
              <div className="bookingOpen">
                <h3>FIND YOURSELF AT HOME WITH HIRO</h3>
                <button type="button" className="btn" onClick={() => this.openBoking()}>Bookings Now Open</button>
              </div>
            </div>

            <footer>
              <div className="top">Pre-book and Move In Starting May 1st, 2022</div>
              <div className="container">
                <div className="bannerText">
                  <img src="/assets/newUI-images/hiro/hiro_logo.svg" alt="" />
                  <h2>
                    by <span>SUITE AMERICA</span>
                  </h2>
                </div>

              </div>
            </footer>
          </div>


          <div className="modal fade savesearch" id="hiro_contactus" role="dialog">
            <div className="modal-dialog">
              <div className="modal-content rm-border-radius">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-label="">
                    <span>×</span>
                  </button>
                </div>
                <div className="modal-body">

                  {this.state.successmsg || this.state.erromsg ?
                    <div>
                      {this.state.successmsg && <div className="alert alert-success">
                        Your request has been sent successfully
                      </div>}
                      {this.state.erromsg && <div className="alert alert-danger">
                        Something went wrong !
                      </div>}
                    </div> :
                    <div>
                      <h2>Hiro Booking Request</h2>
                      <form role="form" method="post" className=""
                        onSubmit={(e) => this.serviceRequest(e)}>
                        <div className="form-group ">
                          <input type="text" autoComplete="off" className="form-control" placeholder="*Name" value={this.state.name} id="name"
                            onChange={this.inputHandler.bind(this, 'name')} />
                        </div>
                        <div className="form-group ">
                          <input type="text" autoComplete="off" className="form-control" placeholder="*Email Address" value={this.state.email} id="email"
                            onChange={this.inputHandler.bind(this, 'email')} />
                        </div>
                        <div className="form-group ">
                          <input type="text" id="phoneNumber"
                            autoComplete="off"
                            className="form-control"
                            placeholder="*Phone"
                            value={this.state.phoneNumber}
                            onChange={this.inputHandler.bind(this, 'phoneNumber')}
                          />
                        </div>



                        <div className="form-group calendarFormgroup ">

                          <DatePicker type="text" className="form-control date" placeholder="*CheckIn Date"
                            selected={this.state.moveInDate}
                            autoComplete="off"
                            id="checkIn"
                            minDate={new Date()}
                            maxDate={this.state.moveOutDate !== null ? moment(this.state.moveOutDate, "DD-MM-YYYY").subtract(1, 'days') : null}
                            onChange={this.handleMoveIn}
                            placeholderText="*Check In" />
                          <DatePicker type="text" className="form-control" placeholder="*CheckOut Date"
                            selected={this.state.moveOutDate}
                            autoComplete="off"
                            id="checkOut"
                            minDate={this.state.moveInDate !== null ? moment(this.state.moveInDate, "DD-MM-YYYY").add(1, 'days') : moment(new Date(), "DD-MM-YYYY")}
                            onChange={this.handleMoveOut}
                            placeholderText="*Check Out" />
                        </div>

                        <div className="form-group ">
                          <select value={this.state.size} id="size" onChange={this.inputHandler.bind(this, 'size')}
                            className="form-control">
                            <option value={""}>*Select size</option>
                            <option value={"1X"}>1X</option>
                            <option value={"2X"}>2X</option>
                            <option value={"3X"}>3X</option>
                          </select>
                        </div>
                        <div className="form-group ">
                          <textarea className="form-control" id="notes" placeholder="*Notes" rows={3} value={this.state.notes}
                            onChange={this.inputHandler.bind(this, 'notes')} />
                        </div>
                        <div className="clearfix"></div>
                        <div className="form-group ">

                          {this.state.loader ?
                            <div className='text-center'
                              style={{ fontSize: '25px', width: '100%', margin: 'auto', textAlign: 'center', color: 'red' }}>
                              <i className="fa fa-spinner fa-spin" /> Please wait ...!</div> : <button className="btn company_btn" type="submit" >Send</button>}
                        </div>
                      </form>
                    </div>}
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    );
  }
}
export default Hiro;
