const StripeInternFooter  = () => {
           return (            
                    <div className="text-center">
                           
                            <strong>RELOCATION COORDINATOR - KEY ACCOUNTS</strong> 
                            <div className="bottom_intern_add">
                            <div className="item">
                                    <h5 className="rdtxt-clr">Will Kerr</h5>
                            <h5><i className="fa fa-phone-square"></i>
                                <span><a href="tel:+1-916-573-5580">916.573.9029</a></span>
                            </h5>
                            <h5><a href="mailto:will@suiteamerica.com">will@suiteamerica.com</a></h5>
                                    </div>


                            {/* <div className="item">
                                    <h5 className="rdtxt-clr">Brandon Fujii</h5>
                            <h5><i className="fa fa-phone-square"></i>
                                <span><a href="tel:+1-916-573-5580">916.573.5580</a></span>
                            </h5>
                            <h5><a href="mailto:brandon@suiteamerica.com">brandon@suiteamerica.com </a></h5>
                                    </div>
                                    <div className="item">
                                    <h5 className="rdtxt-clr">Will Kerr</h5>
                            <h5><i className="fa fa-phone-square"></i>
                                <span><a href="tel:+1-916-573-9029">916.573.9029</a></span>
                            </h5>
                            <h5><a href="mailto:will@suiteamerica.com">will@suiteamerica.com </a></h5>
                                    </div> */}

                                    {/* <div className="item">
                                    <h5 className="rdtxt-clr">Nathan Kraft</h5>
                            <h5><i className="fa fa-phone-square"></i>
                                <span><a href="tel:+1-916-941-7970">916.941.7970 x612</a></span>
                            </h5>
                            <h5><a href="mailto:nathan@suiteamerica.com">nathan@suiteamerica.com</a></h5>
                                    </div>
                                    <div className="item">
                                    <h5 className="rdtxt-clr">Brittani Harbin</h5>
                            <h5><i className="fa fa-phone-square"></i>
                                <span><a href="tel:+1-916-941-7970">916.941.7970 x116</a></span>
                            </h5>
                            <h5><a href="mailto:brittani@suiteamerica.com">brittani@suiteamerica.com</a></h5>
                                            </div>
                                            <div className="item">
                                            <h5 className="rdtxt-clr">Michael Beer</h5>
                            <h5><i className="fa fa-phone-square"></i>
                                <span><a href="tel:+1-916-941-7970">916.941.7970 x150</a></span>
                            </h5>
                            <h5><a href="mailto:nike@suiteamerica.com">m.beer@suiteamerica.com</a></h5>
                                            </div> */}
                            </div>
                            
                           
                    </div>
            );
   }
   export default StripeInternFooter;