/* Importing the node modules, child components, services and controllers used 
   inside NikeFrequentQuestions component */
import MainController from "../../controller/mainController";
import NikeInternshipHeader from "../../common/nikeInternshipHeader";
import SubFooter from "../../common/subFooter";
import GuestHeader from "../../common/guestHeader";
import Footer from "../../common/footer";
import NikeInternFooter from "./common/nikeInternFooter";
/* NikeFrequentQuestions Component initialization */
class NikeInternshipFaq extends React.Component {
  /* Initializing objects of its NikeFrequentQuestions class */
  constructor(props) {
    super(props);
    this.state = {
      fqs: [
        {
          Question: "When will I find out where I am staying?",
          Answer:
            "SuiteAmerica can start confirming availability and make an apartment offer to you approximately 21-30 days prior to arrival.",
        },
        {
          Question: "Can I request a specific Community and/or Apartment Size?",
          Answer:
            "No. SuiteAmerica will match you with another roommate and will offer you an apartment based on availability.",
        },
        {
          Question: "How does the pricing work?",
          Answer:
            "The pricing is based on a first-come, first-served basis. In order to get the best rate available, we strongly recommend you book your housing with SuiteAmerica as early as possible.",
        },
        {
          Question:
            "If I fill out the intern questionnaire, will I be obligated to the apartment offer that SuiteAmerica sends me?",
          Answer:
            "No, you will not be obligated untill you sign a rental agreement via docusign.",
        },
        {
          Question:
            "Can I request a roommate if I have already found one within the program?",
          Answer:
            "Yes, you may list your requested roommate in the notes section of the questionnaire. Please keep in mind that if you choose to room with someone your dates should match up as close as possible to receive the best pricing.",
        },
        {
          Question: "When will my apartment location be confirmed?",
          Answer:
            "Your apartment location will be confirmed once you have completed your rental paperwork via DocuSign and we are approximately 21-30 days prior to your arrival.",
        },
        {
          Question: "What does my apartment include?",
          Answer:
            "Your apartment will include all furniture, housewares, linens, monthly housekeeping, utilities and wireless internet. Due to the climate typically enjoyed in Oregon, Air Conditioning is not available at most properties and therefore fans will be provided. Portable A/C units are available for an additional fee of $5/day.",
        },
        {
          Question: "Are there any other additional fee's or deposits?",
          Answer:
            "SuiteAmerica does not charge any deposits, taxes, move-out/cleaning charges. Customer will be responsible for the expense for any cleaning required by more than normal wear and tear.",
        },
        {
          Question: "Is parking included?",
          Answer:
            "No, parking is an additional expense and on an availability basis.",
        },
        {
          Question:
            "Once I complete my rental paperwork, may I cancel at any time?",
          Answer:
            "No, once you have completed your rental paperwork, you are obligated to fulfill the terms of your lease.",
        },
        {
          Question: "Are pets allowed in my apartment?",
          Answer:
            "Pets can be accepted dependent on property breed & weight restrictions for an additional cost. Service animals are allowed with supporting documentation provided to the property. Please note that if you are bringing a pet, SuiteAmerica will need to ensure that any intern we match you with is comfortable with having a pet in their shared apartment.",
        },
        {
          Question: "Does my apartment have air conditioning?",
          Answer:
            "Many Portland-area apartments do not have central air conditioning, which can make for some uncomfortable temperatures during a summer heat wave. Portable air conditioning units may be available to rent at an additional cost of $5/day, though they are in short supply and high demand.",
        },
        {
          Question:
            "Are the community options listed, the only ones available?",
          Answer:
            "Not necessarily. Inventory in the summer changes on a daily basis and in order to fulfill all requests received, we may have to offer different options in the Portland/Beaverton areas.",
        },
        {
          Question: "When is rent due?",
          Answer: (
            <ul className="bullet_list pad-left-20">
              <li>Billing for rent is monthly.</li>
              <li>
                For your first month’s pro-rated rent, the card provided on your
                Docusign will be charged a few days after your arrival.
              </li>
              <li>
                Every month after, you will be charged on the 1st of the month.
              </li>
              <li>
                If you need to change the card to be billed, please let us know
                and we will send a new Docusign to be completed.
              </li>
            </ul>
          ),
        },
        {
          Question:
            "Do I need renters insurance?",
          Answer:
            "Renters Insurance is NOT included with your reservation but we highly recommend adding it. The cost is only $2 per day to add renters insurance which Includes $50,000 of personal property insurance and up to $15,000 of accidental damage insurance.",
        },
      ],
    };
  }
  /* It is invoked immediately before mounting occurs */
  componentWillMount() {
    MainController.getInitialInformation();
  }
  /* It is invoked to return html content */
  render() {
    let { fqs } = this.state;
    return (
      <div className="corporateHousing_Updates">
        <GuestHeader />
        <div className="cover-tophd-ht"></div>
        <NikeInternshipHeader />
        <div className="container-fluid nike guest_sitenew">
          <div className="container intra_staff2 col-sm-8 col-sm-offset-2">
            <h2 className="text-center company-title">
              FREQUENT ASKED QUESTIONS
            </h2>
            <div
              id="accordion"
              className="panel-group mrg-btm-90 company_acdn"
              role="tablist"
              aria-multiselectable="true"
            >
              {fqs.map((row, index) => {
                return (
                  <div className="panel panel-default" key={index}>
                    <div className="panel-heading">
                      <h4 className="panel-title">
                        <a
                          className="collapsed"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href={"#collapse" + index}
                          aria-expanded="false"
                          aria-controls={row.id}
                        >
                          {row.Question}
                        </a>
                      </h4>
                    </div>
                    <div
                      id={"collapse" + index}
                      className="panel-collapse collapse"
                    >
                      <div className="panel-body">
                        <div className="profile-opener">
                          {row.Question == "How does the pricing work?" ? (
                            <span>
                              {" "}
                              1x1 (single or double occupancy): $159/day total{" "}
                              <br /> 2x2 (double or quad occupancy): $216/day
                              total <br />
                              {row.Answer}{" "}
                            </span>
                          ) : (
                            row.Answer
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="container ft-contact-adrs mrg-btm-30 mrg-top-30">
          <NikeInternFooter />
        </div>
        <SubFooter />
        <Footer />
      </div>
    );
  }
}
export default NikeInternshipFaq;
