/* Importing the node modules, child components, services and controllers used 
   inside BookNowCommunity component */   
   import $ from "jquery";
   import NikeServices from '../../../services/nikeService';

   /* BookNowCommunity Component initialization */
   class BookNowCommunity extends React.Component {
       /* Initializing objects of its BookNowCommunity class */
       constructor(props) {
           super(props)
           this.state={
            firstName: '',
            lastName: '',
            emailId: '',
            phoneNo: '',
            mail: '',
            subject:'',
            color: '',
            errorMessage: ''
        }
        this.setValue = this.setValue.bind(this);
        this.bookDetails = this.bookDetails.bind(this);
   }
   componentWillReceiveProps(nextProps){ 
    this.setState(({firstName: '', lastName: '', emailId: '', phoneNo: '', errorMessage: '', color: ''}));
   }

    clearData(){
        this.setState({firstName: '', lastName: '', emailId: '', phoneNo: ''});
    }
   setValue(field, e) {
    var object = {};
    object[field] = e.target.value;
    this.setState(object);
   }
   async bookDetails(){
    const _this = this
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    const validationFields=['firstName','lastName','emailId', 'phoneNo']
    let error = 0;
    _.forEach(validationFields, function(value) {
       if(_this.state[value] == ""){
         error++;
       }
   });
   if(!reg.test(_this.state.emailId)){
    error++;
  }
  if(error == 0){
     const Request={
         firstName: this.state.firstName == '' ? '':this.state.firstName,
         lastName: this.state.lastName == '' ? '':this.state.lastName,
         emailId: this.state.emailId == '' ? '':this.state.emailId,
         phoneNo: this.state.phoneNo == '' ? '':this.state.phoneNo,
         mail:'booking',
         subject: 'Travel Booking',
     }

    this.setState({
        firstName: '',
        lastName: '',
        emailId: '',
        phoneNo: '',
        subject: '',
        mail:'',
        color:'green',
        errorMessage:'Mail sent successfully',        

    })
    jQuery(function ($) {
        setTimeout(function () {
            $('#travel-booknowCommunity').modal('hide');
        }.bind(this), 3000);
    }.bind(this));

    await NikeServices.serviceRequest(Request);

}
else{
    this.setState({errorMessage:'Please Enter Required Fields and Valid Email',color:'red'});
}
}
       /* It is invoked to return html content */
       render() {
           return (
            <div className="modal fade savesearch" id="travel-booknowCommunity" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content rm-border-radius">
                        <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="">
                            <span>×</span>
                            </button>
                            <h4 className="modal-title">Book Now</h4>
                        </div>
                        <div className="text-center" style={{color: this.state.color}}>{this.state.errorMessage}</div>
                        <div className="modal-body">
                            
                            <form role="form" method="post" >
                                <div>                           
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="First Name *" value={this.state.firstName} onChange={(e) => this.setValue('firstName', e)}/>
                                        </div>
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Last Name *" value={this.state.lastName} onChange={(e) => this.setValue('lastName', e)}/>
                                        </div>
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Phone *" value={this.state.phoneNo} onChange={(e) => this.setValue('phoneNo', e)}/>
                                        </div>
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Email *" value={this.state.emailId} onChange={(e) => this.setValue('emailId', e)}/>
                                        </div>
                                         <button className="btn location_btn mrg-btm-50" type="button" onClick={this.bookDetails}>Submit</button>
                                     </div>
                                </div>
                            </form>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>
   
           );
       }
   }
   export default BookNowCommunity;