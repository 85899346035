

const cityPages = {
    
         "va-alexandria":{
             "image" : "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/city-images/Alexandria-iStock-685773914.jpg",
             "title" : "PREMIUM LOCATIONS",
             "subTitle": "WORLDWIDE",
             "city" : "ALEXANDRIA",
             "state" : "VA",
             "country" : "US",
             "countryId" : "2",
             "state_id" : '62',
             "city_id" : '53234',
             "address" : "VIRGINIA",
             "metaDescription":"SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apartments in Alexandria, VA that are fully customizable come with 24/7 support & exclusive personalized meet & greet services. Call 800 367 9501 or Book today at www.suiteamerica.com",
             "metaTitle" : "Corporate Housing Alexandria, VA  |Short Term Furnished Apartments Alexandria, VA | 800 367 9501",
             "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/va/alexandria",
             "cityIntro" : "Luxurious Corporate Housing and Short Term Furnished Apartments in Alexandria, Virginia, where cobblestone streets, red brick sidewalks, and easy walkability make the city a beautiful destination for relocating or a brief stay.  With small town charm and big city amenities, Alexandria perfectly blends its rich national history with stylish trends.  Enjoy modern comfort food in one of the many restaurants along the waterfront, try not to scream during one of the nightly ghost tours, and fall in love in one of the most romantic cities in the country.",
             "locationName" :"Corporate Housing Alexandria, VA" 
         },
         "va-arlington":{
             "image" : "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/city-images/Arlington-iStock-700212068.jpg",
             "title" : "PREMIUM LOCATIONS",
             "subTitle": "WORLDWIDE",
             "city" : "ARLINGTON",
             "state" : "VA",
             "country" : "US",
             "countryId" : "2",
             "state_id" : '62',
             "city_id" : '53263',
             "address" : "VIRGINIA",
             "metaDescription":"Suite America provides platinum-level solutions in Corporate Housing & Short Term Furnished Apartments in Arlington, VA. In Arlington, you can stay in a SuiteAmerica short term furnished apartment or corporate housing while exploring America’s revolutionary history.",
             "metaTitle" : "Corporate Housing Arlington, VA | Short Term Furnished Apartments  Arlington, VA  | 800 367 9501",
             "metaUrl": "https://www.suiteamerica.com/corporate/housing/furnished/apartments/va/arlington",
             "cityIntro" : "While its neighbor, Washington D.C., might be more well-known, Arlington is also a central part of American history with many famous monuments, museums, and historical sites.  Mostly home to government workers, Arlington, Virginia is a well-educated city that hosts annual food and drink festivals throughout the year.  In Arlington, you can stay in a SuiteAmerica short term furnished apartment or corporate housing while exploring America’s revolutionary history.",
             "locationName" :"Corporate Housing Arlington, VA" 
         },
         "texas-austin":{
             "image" : "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/city-images/Austin-iStock-905553516.jpg",
             "title" : "PREMIUM LOCATIONS",
             "subTitle": "WORLDWIDE",
             "city" : "AUSTIN",
             "state" : "TEXAS",
             "country" : "US",
             "countryId" : "2",
             "state_id" : '59',
             "city_id" : '30528',
             "address" : "TEXAS",
             "metaDescription":"SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apartments in Austin, Texas.  With SuiteAmerica’s corporate housing and short term furnished apartments, you can experience the Live Music Capital of the World no matter how long your stay.",
             "metaTitle" : "Corporate Housing Austin, TX | Short Term Furnished Apartments  Austin, Texas | 800 367 9501",
             "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/texas/austin",
             "cityIntro" : "Adventure awaits in Austin, Texas’ vibrant entertainment and food scene.  With SuiteAmerica’s corporate housing and short term furnished apartments, you can experience the Live Music Capital of the World no matter how long your stay.  Plenty of outdoor activities, live music venues, film festivals, and the local University of Texas Longhorns athletic teams make Austin a fun city to live and play.",
             "locationName" :"Corporate Housing Austin, Texas" 
    
         },
         "oregon-beaverton":{
             "image" : "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/city-images/Beaverton-iStock-503705204.jpg",
             "title" : "PREMIUM LOCATIONS",
             "subTitle": "WORLDWIDE",
             "city" : "BEAVERTON",
             "state" : "OREGON",
             "country" : "US",
             "countryId" : "2",
             "state_id" : '48',
             "city_id" : '37989',
             "address" : "OREGON",
             "metaDescription":"Suite America provides platinum-level solutions in Corporate Housing & Short Term Furnished Apartments in Beaverton, Oregon. Beaverton’s an ideal Pacific Northwest community with lush scenery, exceptional schools, and a high quality of life ranking.",
             "metaTitle" : "Corporate Housing Beaverton, OR | Short Term Furnished Apartments  Beaverton, Oregon | 800 367 9501",
             "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/oregon/beaverton",
             "cityIntro" : "Beaverton, Oregon has somehow remained a quiet Portland suburb located in the up and coming area known as Silicon Forest—the Pacific Northwest’s version of Silicon Valley.  Enjoy this family-friendly city while staying in SuiteAmerica’s world class corporate housing and short term furnished apartments. Beaverton’s an ideal Pacific Northwest community with lush scenery, exceptional schools, and a high quality of life ranking.",
             "locationName" :"Corporate Housing Beaverton, Oregon" 
    
         },
         "ma-boston":{
             "image" : "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/city-images/Boston-iStock-827345118.jpg",
             "title" : "PREMIUM LOCATIONS",
             "subTitle": "WORLDWIDE",
             "city" : "BOSTON",
             "state" : "MASSACHUSETTS",
             "country" : "US",
             "countryId" : "2",
             "state_id" : '25',
             "city_id" : '47927',
             "address" : "MASSACHUSETTS",
             "metaDescription":"SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apartments in Boston, MA that are fully customizable come with 24/7 support & exclusive personalized meet & greet services. Call 800 367 9501 or Book today at www.suiteamerica.com",
             "metaTitle" : "Corporate Housing Boston, MA  | Short Term Furnished Apartments  Boston, MA | 800 367 9501",
             "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/ma/boston",
             "cityIntro" : "Boston’s epic history has earned it a reputation for being a proud, patriotic city.  Now an international center for higher education, the capital of Massachusetts is a world leader in entrepreneurship and sustainable businesses.  Despite its high cost of living, SuiteAmerica’s short term furnished apartments provide cost effective corporate housing with platinum level service.  Explore history in this classic American city that never strays too far from its rowdy patriotic beginnings.",
             "locationName" :"Corporate Housing Boston, MA" 
    
         },
         "nc-charlotte":{
             "image" : "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/city-images/Charlotte-iStock-924114968.jpg",
             "title" : "PREMIUM LOCATIONS",
             "subTitle": "WORLDWIDE",
             "city" : "CHARLOTTE",
             "state" : "NORTH CAROLINA",
             "country" : "US",
             "countryId" : "2",
             "state_id" : '37',
             "city_id" : '54960',
             "address" : "NORTH CAROLINA",
             "metaDescription":"Suite America provides platinum-level solutions in Corporate Housing & Short Term Furnished Apartments in Charlotte, NC.  SuiteAmerica’s corporate housing and short term furnished apartments allow for a world class experience in the southern charm and cosmopolitan energy of Charlotte.",
             "metaTitle" : "Corporate Housing Charlotte, NC  | Short Term Furnished Apartments  Charlotte, NC | 800 367 9501",
             "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/nc/charlotte",
             "cityIntro" : "Charlotte’s thriving craft brewery scene, numerous outdoor trails, and professional sports teams offer plenty of fun.  As the most populated city in North Carolina, Charlotte has become one of the fastest growing metropolitan areas in the country.  SuiteAmerica’s corporate housing and short term furnished apartments allow for a world class experience in the southern charm and cosmopolitan energy of Charlotte.",
             "locationName" :"Corporate Housing Charlotte, NC" 
    
         },
         "il-chicago":{
             "image" : "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/city-images/Charlotte-iStock-924114968.jpg",
             "title" : "PREMIUM LOCATIONS",
             "subTitle": "WORLDWIDE",
             "city" : "CHICAGO",
             "state" : "ILLINOIS",
             "country" : "US",
             "countryId" : "2",
             "state_id" : '20',
             "city_id" : '8487',
             "address" : "ILLINOIS",
             "metaDescription":"SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apartments in Chicago, IL.  Its iconic skyline, deep dish pizza, and storied professional sports teams will keep you entertained throughout your stay in SuiteAmerica corporate housing or short term furnished apartments.",
             "metaTitle" : "Corporate Housing Chicago, IL   | Short Term Furnished Apartments  Chicago, IL | 800 367 9501",
             "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/IL/chicago",
             "cityIntro" : "Chicago’s thriving international trade and businesses make it the third largest city in the United States. Its iconic skyline, deep dish pizza, and storied professional sports teams will keep you entertained throughout your stay in SuiteAmerica corporate housing or short term furnished apartments. Visit The Bean, get to know Abe Lincoln’s hometown, and enjoy your time in this global city.",
             "locationName" :"Corporate Housing Chicago, IL" 
    
         },
         "ca-cupertino":{
             "image" : "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/city-images/Cupertino-iStock-627076784.jpg",
             "title" : "PREMIUM LOCATIONS",
             "subTitle": "WORLDWIDE",
             "city" : "CUPERTINO",
             "state" : "CALIFORNIA",
             "country" : "US",
             "countryId" : "2",
             "state_id" : '8',
             "city_id" : '23900',
             "address" : "CALIFORNIA",
             "metaDescription":"Suite America provides platinum-level solutions in Corporate Housing & Short Term Furnished Apartments in Cupertino, CA.  Experience one of the most well-known and family-friendly cities in Silicon Valley while staying in SuiteAmerica’s corporate housing and short term apartments in Cupertino.",
             "metaTitle" : "Corporate Housing Cupertino, CA  | Short Term Furnished Apartments  Cupertino, CA | 800 367 9501",
             "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/ca/cupertino",
             "cityIntro" : "With its moderate size, unique mix of technology firms, retail centers, parks, quality schools, and upscale residential areas, Cupertino and its innovative spirit attract a diverse array of entrepreneurs and visionaries to California. Experience one of the most well-known and family-friendly cities in Silicon Valley while staying in SuiteAmerica’s corporate housing and short term apartments in Cupertino.",
             "locationName" :"Corporate Housing Cupertino, CA" 
    
         },
         "texas-dallas":{
             "image" : "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/city-images/Dallas-iStock-929672604.jpg",
             "title" : "PREMIUM LOCATIONS",
             "subTitle": "WORLDWIDE",
             "city" : "DALLAS",
             "state" : "TEXAS",
             "country" : "US",
             "countryId" : "2",
             "state_id" : '59',
             "city_id" : '31060',
             "address" : "TEXAS",
             "metaDescription":"SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apartments in Dallas, TX.   Dallas is the place where cowboy hats meet cosmopolitan. While the city might be known as a place for dramatic TV legacies, oil, and American football, it’s also become a more urban city committed to expanding its upscale retail centers and neighborhoods.",
             "metaTitle" : "Corporate Housing Dallas, TX | Short Term Furnished Apartments  Dallas, TX  | 800 367 9501",
             "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/texas/dallas",
             "cityIntro" : "Still home to American Cowboys, Dallas reflects Texas’ reputation as a larger than life place in American culture. Dallas is the place where cowboy hats meet cosmopolitan. While the city might be known as a place for dramatic TV legacies, oil, and American football, it’s also become a more urban city committed to expanding its upscale retail centers and neighborhoods.",
             "locationName" :"Corporate Housing Dallas, TX" 
    
         },
         "nc-durham":{
             "image" : "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/city-images/Durham-iStock-478945686.jpg",
             "title" : "PREMIUM LOCATIONS",
             "subTitle": "WORLDWIDE",
             "city" : "Durham",
             "state" : "NORTH CAROLINA",
             "country" : "US",
             "countryId" : "2",
             "state_id" : '37',
             "city_id" : '31060',
             "address" : "NORTH CAROLINA",
             "metaDescription":"Suite America provides platinum-level solutions in Corporate Housing & Short Term Furnished Apartments in Durham, NC. Get to know your neighbors, and everyone else, while staying in SuiteAmerica’s corporate housing and short term furnished apartments in the relaxed and beautiful city of Durham.",
             "metaTitle" : "Corporate Housing Durham, NC  | Short Term Furnished Apartments  Durham, NC | 800 367 9501",
             "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/nc/durham",
             "cityIntro" : "Located in what’s called The Triangle of popular North Carolina cities, Durham may not have the name recognition of its neighbors, but it’s still home to great family-friendly neighborhoods, delicious restaurants, and local college rivalries. Get to know your neighbors, and everyone else, while staying in SuiteAmerica’s corporate housing and short term furnished apartments in the relaxed and beautiful city of Durham.",
             "locationName" :"Corporate Housing Durham, NC" 
    
         },
         "ca-fostercity":{
             "image" : "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/city-images/FosterCity-iStock-182813291.jpg",
             "title" : "PREMIUM LOCATIONS",
             "subTitle": "WORLDWIDE",
             "city" : "FOSTER CITY",
             "state" : "CALIFORNIA",
             "country" : "US",
             "countryId" : "2",
             "state_id" : '8',
             "city_id" : '24132',
             "address" : "CALIFORNIA",
             "metaDescription":"SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apartments in Foster City, CA. This small suburb is a great escape from the hustle and bustle of larger surrounding cities, and with world class amenities and an unrivaled level of service, you’ll feel truly at home in Foster City while staying in SuiteAmerica corporate housing or short term furnished apartments.",
             "metaTitle" : "Corporate Housing Foster City, CA  | Short Term Furnished Apartments  Foster City, CA | 800 367 9501",
             "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/ca/fostercity",
             "cityIntro" : "Foster City, California is considered one of the safest cities in the United States. Located in Silicon Valley, SuiteAmerica’s corporate housing and short term furnished apartments allow you to enjoy Foster City’s 200 parks, tennis courts, baseball and soccer fields, and bike trails along the San Francisco Bay. This small suburb is a great escape from the hustle and bustle of larger surrounding cities, and with world class amenities and an unrivaled level of service, you’ll feel truly at home in Foster City while staying in SuiteAmerica corporate housing or short term furnished apartments.",
             "locationName" :"Corporate Housing Foster City, CA" 
    
         },
         "england-london":{
             "image" : "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/city-images/London-iStock-689936034.jpg",
             "title" : "PREMIUM LOCATIONS",
             "subTitle": "WORLDWIDE",
             "city" : "LONDON",
             "state" : "England",
             "country" : "UK",
             "countryId" : "118",
             "state_id" : '74',
             "city_id" : '60611',
             "address" : "England",
             "metaDescription":"Suite America provides platinum-level solutions in Corporate Housing & Short Term Furnished Apartments in London, England. As the capital of England and the United Kingdom, London’s history dates back centuries with popular landmarks that won’t disappoint.  From West Minister Abbey to the House of Parliament and Big Ben, everywhere you turn, you’ll recognize parts of the city.",
             "metaTitle" : "Corporate Housing London, England | Short Term Furnished Apartments  London, England | 800 367 9501",
             "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/england/london",
             "cityIntro" : "As the capital of England and the United Kingdom, London’s history dates back centuries with popular landmarks that won’t disappoint.  From West Minister Abbey to the House of Parliament and Big Ben, everywhere you turn, you’ll recognize parts of the city. Take a photo in a classic red phone booth, grab a pint, and learn more about real football. SuiteAmerica’s corporate housing and short term furnished apartments in London allow you to enjoy the pubs and free museums at cost effective rates. Cheers!",
             "locationName" :"Corporate Housing London, England" 
    
         },
         "ca-losangeles":{
             "image" : "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/city-images/LosAngeles-iStock-896245220.jpg",
             "title" : "PREMIUM LOCATIONS",
             "subTitle": "WORLDWIDE",
             "city" : "LOS ANGELES",
             "state" : "CALIFORNIA",
             "country" : "US",
             "countryId" : "2",
             "state_id" : '8',
             "city_id" : '24584',
             "address" : "CALIFORNIA",
             "metaDescription":"SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apartments in  Los Angeles, CA. . Get ready for surfing, trendy health foods, and a whole lot of sunshine. L.A. moves fast—unless you’re on the freeway—so SuiteAmerica’s corporate housing and short term furnished apartments provide world class amenities with an unrivaled level of service for a relaxing and seamless relocation or temporary stay.",
             "metaTitle" : "Corporate Housing Los Angeles, CA  | Short Term Furnished Apartments  Los Angeles, CA | 800 367 9501",
             "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/ca/losangeles",
             "cityIntro" : "Perhaps the epitome of Southern California culture, Los Angeles bustles with an urban energy and California’s classic laidback atmosphere. Get ready for surfing, trendy health foods, and a whole lot of sunshine. L.A. moves fast—unless you’re on the freeway—so SuiteAmerica’s corporate housing and short term furnished apartments provide world class amenities with an unrivaled level of service for a relaxing and seamless relocation or temporary stay.",
             "locationName" :"Corporate Housing Los Angeles, CA" 
    
         },
         "oregon-portland":{
             "image" : "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/city-images/Portland-iStock-865183098.jpg",
             "title" : "PREMIUM LOCATIONS",
             "subTitle": "WORLDWIDE",
             "city" : "PORTLAND",
             "state" : "OREGON",
             "country" : "US",
             "countryId" : "2",
             "state_id" : '48',
             "city_id" : '38357',
             "address" : "OREGON",
             "metaDescription":"Suite America provides platinum-level solutions in Corporate Housing & Short Term Furnished Apartments in  Portland, OR.  Stay in SuiteAmerica’s corporate housing and short term furnished apartments in Portland to experience the laidback and offbeat city that prides itself on sustainability, friendliness, and being different.",
             "metaTitle" : "Corporate Housing Portland, OR  | Short Term Furnished Apartments  Portland, OR  | 800 367 9501",
             "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/oregon/portland",
             "cityIntro" : "Portland, Oregon is a unique city that loves microbreweries, soccer, and rain. They also like to keep things a little weird. Stay in SuiteAmerica’s corporate housing and short term furnished apartments in Portland to experience the laidback and offbeat city that prides itself on sustainability, friendliness, and being different.",
             "locationName" :"Corporate Housing Portland, OR" 
    
         },
         "nc-raleigh":{
             "image" : "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/city-images/Raleigh-iStock-698826868.jpg",
             "title" : "PREMIUM LOCATIONS",
             "subTitle": "WORLDWIDE",
             "city" : "Raleigh",
             "state" : "NORTH CAROLINA",
             "country" : "US",
             "countryId" : "2",
             "state_id" : '37',
             "city_id" : '0',
             "address" : "NORTH CAROLINA",
             "metaDescription":"Suite America provides platinum-level solutions in Corporate Housing & Short Term Furnished Apartments in  Portland, OR.  Stay in SuiteAmerica’s corporate housing and short term furnished apartments in Portland to experience the laidback and offbeat city that prides itself on sustainability, friendliness, and being different.",
             "metaTitle" : "Corporate Housing Raleigh, NC  | Short Term Furnished Apartments  Raleigh, NC  | 800 367 9501",
             "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/nc/raleigh",
             "cityIntro" : "Portland, Oregon is a unique city that loves microbreweries, soccer, and rain. They also like to keep things a little weird. Stay in SuiteAmerica’s corporate housing and short term furnished apartments in Portland to experience the laidback and offbeat city that prides itself on sustainability, friendliness, and being different.",
             "locationName" :"Corporate Housing Raleigh, NC" 
    
         },
         "ca-redwoodcity":{
             "image" : "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/city-images/RedwoodCity-iStock-865635330.jpg",
             "title" : "PREMIUM LOCATIONS",
             "subTitle": "WORLDWIDE",
             "city" : "REDWOOD CITY",
             "state" : "CALIFORNIA",
             "country" : "US",
             "countryId" : "2",
             "state_id" : '8',
             "city_id" : '25127',
             "address" : "CALIFORNIA",
             "metaDescription":"Suite America provides platinum-level solutions in Corporate Housing & Short Term Furnished Apartments in Redwood City, CA.  With an up and coming downtown area that boasts a vibrant and diverse food scene, Redwood City is the ideal place if you want to experience all that both San Francisco and the Silicon Valley have to offer.",
             "metaTitle" : "Corporate Housing Redwood City, CA  | Short Term Furnished Apartments  Redwood City, CA | 800 367 9501",
             "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/ca/redwoodcity",
             "cityIntro" : "Nestled between San Francisco and San Jose is Redwood City, California. SuiteAmerica’s corporate housing and short term furnished apartments in Redwood City allow you to enjoy what is quickly becoming the entertainment hub of the San Francisco Peninsula. With an up and coming downtown area that boasts a vibrant and diverse food scene, Redwood City is the ideal place if you want to experience all that both San Francisco and the Silicon Valley have to offer.",
             "locationName" :"Corporate Housing Redwood City, CA" 
    
         },
         "ca-sandiego":{
             "image" : "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/city-images/SanDiego-iStock-854518844.jpg",
             "title" : "PREMIUM LOCATIONS",
             "subTitle": "WORLDWIDE",
             "city" : "SAN DIEGO",
             "state" : "CALIFORNIA",
             "country" : "US",
             "countryId" : "2",
             "state_id" : '8',
             "city_id" : '25240',
             "address" : "CALIFORNIA",
             "metaDescription":"SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apartments in  San Diego, CA. In San Diego, California, every day is a beach day.  Pack your sunglasses and some sunscreen because you’ll be busy enjoying the sunshine while staying in SuiteAmerica’s corporate housing and short term furnished apartments. Tacos, good vibes, and street parking await.",
             "metaTitle" : "Corporate Housing San Diego, CA  | Short Term Furnished Apartments  San Diego, CA | 800 367 9501",
             "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/ca/sandiego",
             "cityIntro" : "In San Diego, California, every day is a beach day. A beautiful, laidback city, San Diego knows how to make the most of its beach fronts. Pack your sunglasses and some sunscreen because you’ll be busy enjoying the sunshine while staying in SuiteAmerica’s corporate housing and short term furnished apartments. Tacos, good vibes, and street parking await.",
             "locationName" :"Corporate Housing San Diego, CA" 
    
         },
         "ca-sanfrancisco":{
             "image" : "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/city-images/SanFrancisco-iStock-922779642.jpg",
             "title" : "PREMIUM LOCATIONS",
             "subTitle": "WORLDWIDE",
             "city" : "SAN FRANCISCO",
             "state" : "CALIFORNIA",
             "country" : "US",
             "countryId" : "2",
             "state_id" : '8',
             "city_id" : '25247',
             "address" : "CALIFORNIA",
             "metaDescription":"Suite America provides platinum-level solutions in Corporate Housing & Short Term Furnished Apartments in  San Francisco, CA. The streets might be a little steep, but every turn and slope in San Francisco provides a breathtaking view of the city and Pacific Ocean. Catch a cable car while exploring diverse, exciting San Francisco. When staying in SuiteAmerica’s corporate housing and short term furnished apartments, your time in S.F. is sure to be golden.",
             "metaTitle" : "Corporate Housing San Francisco, CA  | Short Term Furnished Apartments  San Francisco, CA  | 800 367 9501",
             "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/ca/sanfrancisco",
             "cityIntro" : "The streets might be a little steep, but every turn and slope in San Francisco provides a breathtaking view of the city and Pacific Ocean. Catch a cable car while exploring diverse, exciting San Francisco. When staying in SuiteAmerica’s corporate housing and short term furnished apartments, your time in S.F. is sure to be golden.",
             "locationName" :"Corporate Housing San Francisco, CA" 
    
         },
         "ca-sanjose":{
             "image" : "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/city-images/SanJose-iStock-824105890.jpg",
             "title" : "PREMIUM LOCATIONS",
             "subTitle": "WORLDWIDE",
             "city" : "SAN JOSE",
             "state" : "CALIFORNIA",
             "country" : "US",
             "countryId" : "2",
             "state_id" : '8',
             "city_id" : '25254',
             "address" : "CALIFORNIA",
             "metaDescription":"SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apartments in San Jose, CA.  SuiteAmerica’s corporate housing and short term furnished apartments in San Jose provide world class amenities, making your relocation or temporary stay a luxurious experience. Enjoy the innovative, bright, and active lifestyle of Silicon Valley’s central hub.",
             "metaTitle" : "Corporate Housing San Jose, CA  | Short Term Furnished Apartments  San Jose, CA  | 800 367 9501",
             "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/ca/sanjose",
             "cityIntro" : "San Jose’s immense growth over the last couple of decades has turned it into an urban California city with a diverse population and innovative spirit. SuiteAmerica’s corporate housing and short term furnished apartments in San Jose provide world class amenities, making your relocation or temporary stay a luxurious experience. Enjoy the innovative, bright, and active lifestyle of Silicon Valley’s central hub.",
             "locationName" :"Corporate Housing San Jose, CA" 
    
         },
         "ca-santaclara":{
             "image" : "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/city-images/SantaClara-iStock-524018442.jpg",
             "title" : "PREMIUM LOCATIONS",
             "subTitle": "WORLDWIDE",
             "city" : "SANTA CLARA",
             "state" : "CALIFORNIA",
             "country" : "US",
             "countryId" : "2",
             "state_id" : '8',
             "city_id" : '25289',
             "address" : "CALIFORNIA",
             "metaDescription":"Suite America provides platinum-level solutions in Corporate Housing & Short Term Furnished Apartments in Santa Clara, CA.  While in Santa Clara, you can learn exactly how the Silicon Valley got its name, see a San Francisco 49er game, and enjoy thrill rides at GreatAmerica. Experience Silicon Valley’s entertainment capital while staying in SuiteAmerica’s corporate housing and short term furnished apartments.",
             "metaTitle" : "Corporate Housing Santa Clara, CA  | Short Term Furnished Apartments  Santa Clara, CA | 800 367 9501",
             "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/ca/santaclara",
             "cityIntro" : "Located in Silicon Valley, Santa Clara is another innovative California city that has expanded significantly in the 21st century. While in Santa Clara, you can learn exactly how the Silicon Valley got its name, see a San Francisco 49er game, and enjoy thrill rides at GreatAmerica. Experience Silicon Valley’s entertainment capital while staying in SuiteAmerica’s corporate housing and short term furnished apartments.",
             "locationName" :"Corporate Housing Santa Clara, CA" 
    
         },
         "ca-santamonica":{
             "image" : "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/city-images/SantaMonica-iStock-132039231.jpg",
             "title" : "PREMIUM LOCATIONS",
             "subTitle": "WORLDWIDE",
             "city" : "SANTA MONICA",
             "state" : "CALIFORNIA",
             "country" : "US",
             "countryId" : "2",
             "state_id" : '8',
             "city_id" : '25298',
             "address" : "CALIFORNIA",
             "metaDescription":"SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apartments in Santa Monica, CA. You’ll recognize this California city by its famous pier and palm tree lined streets. The Pacific Coast Highway will become a regular part of your drive while staying in SuiteAmerica corporate housing and short term furnished apartments in Santa Monica. Enjoy this gorgeous, trendy city with a beach town vibe.",
             "metaTitle" : "Corporate Housing Santa Monica, CA  | Short Term Furnished Apartments  Santa Monica, CA  | 800 367 9501",
             "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/ca/santamonica",
             "cityIntro" : "Santa Monica’s sandy beaches, blue water, and pastel sunsets are absolutely enchanting. You’ll recognize this California city by its famous pier and palm tree lined streets. The Pacific Coast Highway will become a regular part of your drive while staying in SuiteAmerica corporate housing and short term furnished apartments in Santa Monica. Enjoy this gorgeous, trendy city with a beach town vibe.",
             "locationName" :"Corporate Housing Santa Monica, CA" 
    
         },
         "wa-seattle":{
             "image" : "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/city-images/Seattle-iStock-913242018.jpg",
             "title" : "PREMIUM LOCATIONS",
             "subTitle": "WORLDWIDE",
             "city" : "SEATTLE",
             "state" : "WASHINGTON",
             "country" : "US",
             "countryId" : "2",
             "state_id" : '65',
             "city_id" : '15274',
             "address" : "WASHINGTON",
             "metaDescription":"Suite America provides platinum-level solutions in Corporate Housing & Short Term Furnished Apartments in Seattle, WA.  With plenty of rain and coffee, it might seem like there’s not much else the Emerald City has to offer, but famous tourist spots like the Space Needle and Pike’s Place will keep you plenty busy. Enjoy Seattle’s fresh seafood, live music venues, and professional sports teams while staying in SuiteAmerica’s corporate housing and short term furnished apartments.",
             "metaTitle" : "Corporate Housing Seattle, WA  | Short Term Furnished Apartments  Seattle, WA | 800 367 9501",
             "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/wa/seattle",
             "cityIntro" : "Seattle’s proved to be an up and coming city for young professionals and tech companies alike. With plenty of rain and coffee, it might seem like there’s not much else the Emerald City has to offer, but famous tourist spots like the Space Needle and Pike’s Place will keep you plenty busy. Enjoy Seattle’s fresh seafood, live music venues, and professional sports teams while staying in SuiteAmerica’s corporate housing and short term furnished apartments.",
             "locationName" :"Corporate Housing Seattle, WA" 
    
         },
         "ca-sunnyvale":{
             "image" : "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/city-images/Sunnyvale-iStock-627076784.jpg",
             "title" : "PREMIUM LOCATIONS",
             "subTitle": "WORLDWIDE",
             "city" : "SUNNYVALE",
             "state" : "CALIFORNIA",
             "country" : "US",
             "countryId" : "2",
             "state_id" : '8',
             "city_id" : '25474',
             "address" : "CALIFORNIA",
             "metaDescription":"SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apartments in Sunnyvale, CA. Sunnyvale’s exceptional schools, parks, and neighborhoods make it a family-friendly city perfect for relocating or a temporary assignment. Enjoy Sunnyvale’s world class amenities while staying in SuiteAmerica corporate housing and short term furnished apartments.",
             "metaTitle" : "Corporate Housing Sunnyvale, CA  | Short Term Furnished Apartments  Sunnyvale, CA | 800 367 9501",
             "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/ca/sunnyvale",
             "cityIntro" : "Luxurious corporate housing and short term furnished apartments in Sunnyvale, California, where Silicon Valley is at its best. Sunnyvale’s exceptional schools, parks, and neighborhoods make it a family-friendly city perfect for relocating or a temporary assignment. Enjoy Sunnyvale’s world class amenities while staying in SuiteAmerica corporate housing and short term furnished apartments.",
             "locationName" :"Corporate Housing Sunnyvale, CA" 
    
         },
         "dc-washington":{
             "image" : "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/city-images/WashingtonDC-473207592.jpg",
             "title" : "PREMIUM LOCATIONS",
             "subTitle": "WORLDWIDE",
             "city" : "WASHINGTON",
             "state" : "DISTRICT OF COLUMBIA",
             "country" : "US",
             "countryId" : "2",
             "state_id" : '11',
             "city_id" : '14378',
             "address" : "DISTRICT OF COLUMBIA",
             "metaDescription":"Suite America provides platinum-level solutions in Corporate Housing & Short Term Furnished Apartments in Washington, DC.  While staying in SuiteAmerica’s corporate housing and short term furnished apartments in Washington D.C., you can see American history up close and personal during your relocation or temporary assignment.",
             "metaTitle" : "Corporate Housing Washington, DC | Short Term Furnished Apartments  Washington, DC | 800 367 9501",
             "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/dc/washington",
             "cityIntro" : "As America’s capital, Washington D.C. is home to some of the nation’s top monuments, museums, and historical sites. While staying in SuiteAmerica’s corporate housing and short term furnished apartments in Washington D.C., you can see American history up close and personal during your relocation or temporary assignment.",
             "locationName" :"Corporate Housing Washington, DC" 
    
         },
         "va-ashburn":{
             "image" : "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/city-images/Ashburn-iStock-623374026.jpg",
             "title" : "PREMIUM LOCATIONS",
             "subTitle": "WORLDWIDE",
             "city" : "WASHINGTON",
             "state" : "VIRGINIA",
             "country" : "US",
             "countryId" : "2",
             "state_id" : '62',
             "city_id" : '0',
             "address" : "VIRGINIA",
             "metaDescription":"SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apartments in Ashburn, VA. SuiteAmerica’s corporate housing and short term furnished apartments in Ashburn are within driving distance of the D.C. area’s most well-known attractions, making Ashburn a great place to live without the big city fuss.",
             "metaTitle" : "Corporate Housing Ashburn, VA  | Short Term Furnished Apartments  Ashburn, VA | 800 367 9502",
             "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/va/ashburn",
             "cityIntro" : "Northwest of D.C. is Ashburn, Virginia, a town that’s quietly growing into a top destination for government and tech workers.  With a strong economy, small community feel, and local universities, Ashburn has plenty of resources and family-friendly activities. SuiteAmerica’s corporate housing and short term furnished apartments in Ashburn are within driving distance of the D.C. area’s most well-known attractions, making Ashburn a great place to live without the big city fuss.",
             "locationName" :"Corporate Housing Ashburn, VA" 
    
         },
         "md-columbia":{
             "image" : "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/city-images/Columbia-iStock-811548276.jpg",
             "title" : "PREMIUM LOCATIONS",
             "subTitle": "WORLDWIDE",
             "city" : "COLUMBIA",
             "state" : "MARYLAND",
             "country" : "US",
             "countryId" : "2",
             "state_id" : '27',
             "city_id" : '171',
             "address" : "MARYLAND",
             "metaDescription":"Suite America provides platinum-level solutions in Corporate Housing & Short Term Furnished Apartments in Columbia, MD. Good schools, an array of retail centers, and an effort to make the city more bike and walking friendly create a high quality of life. Columbia prides itself on celebrating diversity and local history. SuiteAmerica’s corporate housing and short term furnished apartments make living in Columbia affordable and ideal.",
             "metaTitle" : "Corporate Housing Columbia, MD  | Short Term Furnished Apartments  Columbia, MD | 800 367 9503",
             "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/md/columbia",
             "cityIntro" : "Part of the Baltimore’s greater metro area, Columbia is a planned community in Maryland consisting of ten self-contained villages. Good schools, an array of retail centers, and an effort to make the city more bike and walking friendly create a high quality of life. Columbia prides itself on celebrating diversity and local history. SuiteAmerica’s corporate housing and short term furnished apartments make living in Columbia affordable and ideal.",
             "locationName" :"Corporate Housing Columbia, MD" 
    
         },
         "va-fairfax":{
             "image" : "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/city-images/Fairfax-iStock-904657440.jpg",
             "title" : "PREMIUM LOCATIONS",
             "subTitle": "WORLDWIDE",
             "city" : "FAIRFAX",
             "state" : "VIRGINIA",
             "country" : "US",
             "countryId" : "2",
             "state_id" : '62',
             "city_id" : '53641',
             "address" : "VIRGINIA",
             "metaDescription":"SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apartments in Fairfax, VA. Just 20 miles north of Washington D.C. are SuiteAmerica’s corporate housing and short term furnished apartments in Fairfax, Virginia. A small town with urban energy, Fairfax hosts concerts, festivals, movie screenings, and more to make everyone feel at home and welcome in this quaint and energetic suburb.",
             "metaTitle" : "Corporate Housing Fairfax, VA  | Short Term Furnished Apartments  Fairfax, VA | 800 367 9504",
             "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/va/fairfax",
             "cityIntro" : "Just 20 miles north of Washington D.C. are SuiteAmerica’s corporate housing and short term furnished apartments in Fairfax, Virginia. A small town with urban energy, Fairfax hosts concerts, festivals, movie screenings, and more to make everyone feel at home and welcome in this quaint and energetic suburb.",
             "locationName" :"Corporate Housing Fairfax, VA" 
        },
         "va-herndon":{ 
             "image" : "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/city-images/Herndon-iStock-623374026.jpg", 
             "title" : "PREMIUM LOCATIONS", 
             "subTitle": "WORLDWIDE", 
             "city" : "Herndon", 
             "state" : "VIRGINIA", 
             "country" : "US", 
             "countryId" : "2", 
             "state_id" : '62', 
             "city_id" : '53816', 
             "address" : "VIRGINIA", 
             "metaDescription":"Suite America provides platinum-level solutions in Corporate Housing & Short Term Furnished Apartments in Herndon, VA. Herndon is a beautiful town that has a lot to offer during your stay in SuiteAmerica’s corporate housing and short term furnished apartments. Enjoy the town’s numerous parks, free concerts, and festivals.", 
             "metaTitle" : "Corporate Housing Herndon, VA | Short Term Furnished Apartments Herndon, VA | 800 367 9505", 
             "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/va/herndon",
             "cityIntro" : "With plenty of sightseeing and outdoor activities, Herndon is a beautiful town that has a lot to offer during your stay in SuiteAmerica’s corporate housing and short term furnished apartments. Enjoy the town’s numerous parks, free concerts, and festivals. This charming Virginia town provides a relaxed atmosphere outside of Washington D.C. Experience a life at a slower pace while still being close to the big city." ,
             "locationName" :"Corporate Housing Herndon, VA" 
        },
         "va-mclean":{
             "image" : "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/city-images/Mclean-iStock-623374026.jpg",
             "title" : "PREMIUM LOCATIONS",
             "subTitle": "WORLDWIDE",
             "city" : "MCLEAN",
             "state" : "VIRGINIA",
             "country" : "US",
             "countryId" : "2",
             "state_id" : '62',
             "city_id" : '54029',
             "address" : "VIRGINIA",
             "metaDescription":"SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apartments in Mclean, VA. Known for luxurious homes and high-end shopping, the city is among one of the most expensive zip codes in Virginia and is also the third wealthiest city in the country. SuiteAmerica’s corporate housing and short term furnished apartments in McLean are cost effective and provide world class amenities in this upscale destination.",
             "metaTitle" : "Corporate Housing Mclean, VA | Short Term Furnished Apartments  Mclean, VA | 800 367 9506",
             "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/va/mclean",
             "cityIntro" :"Due to its proximity to Washington D.C., McLean is home to many diplomats, government officials, and members of Congress. Known for luxurious homes and high-end shopping, the city is among one of the most expensive zip codes in Virginia and is also the third wealthiest city in the country. SuiteAmerica’s corporate housing and short term furnished apartments in McLean are cost effective and provide world class amenities in this upscale destination.",
             "locationName" :"Corporate Housing Mclean, VA" 
    
         },
         "md-northbethesda":{
             "image" : "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/city-images/NorthBethesda-iStock-811548276.jpg",
             "title" : "PREMIUM LOCATIONS",
             "subTitle": "WORLDWIDE",
             "city" : "NORTH BETHESDA",
             "state" : "MARYLAND",
             "country" : "US",
             "countryId" : "2",
             "state_id" : '27',
             "city_id" : '543',
             "address" : "MARYLAND",
             "metaDescription":"Suite America provides platinum-level solutions in Corporate Housing & Short Term Furnished Apartments in North Bethesda, MD.  SuiteAmerica’s corporate housing and short term furnished apartments in North Bethesda provide world class amenities and an unrivaled level of service for those living outside Washington D.C.",
             "metaTitle" : "Corporate Housing North Bethesda, MD  | Short Term Furnished Apartments  North Bethesda, MD | 800 367 9507",
             "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/md/northbethesda",
             "cityIntro" :"Just four miles west of Washington D.C., North Bethesda, Maryland doesn’t have specific boundaries but consists of several neighborhoods, such as Garrett Park Estates, Randolph Hills, Wildwood, Old Farm, and Chase Crossing. SuiteAmerica’s corporate housing and short term furnished apartments in North Bethesda provide world class amenities and an unrivaled level of service for those living outside Washington D.C.",
             "locationName" :"Corporate Housing North Bethesda, MD" 
    
         },
         "va-reston":{
             "image" : "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/city-images/Reston-iStock-623374026.jpg",
             "title" : "PREMIUM LOCATIONS",
             "subTitle": "WORLDWIDE",
             "city" : "RESTON",
             "state" : "VIRGINIA",
             "country" : "US",
             "countryId" : "2",
             "state_id" : '62',
             "city_id" : '54311',
             "address" : "VIRGINIA",
             "metaDescription":"SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apartments in  Reston, VA.  Reston is also home to several performing arts groups and a free summer concert series. SuiteAmerica’s corporate housing and short term furnished apartments in Reston make it a great place to enjoy the outdoors while also feeling at home in SuiteAmerica’s world class housing.",
             "metaTitle" : "Corporate Housing Reston, VA  | Short Term Furnished Apartments  Reston, VA | 800 367 9508",
             "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/va/reston",
             "cityIntro" :"A planned city with a good balance of parks, homes, and retail centers, Reston has been ranked one of the best places to in live in Virginia. Known for its numerous parks, lakes, golf courses, and horse trails, Reston is also home to several performing arts groups and a free summer concert series. SuiteAmerica’s corporate housing and short term furnished apartments in Reston make it a great place to enjoy the outdoors while also feeling at home in SuiteAmerica’s world class housing.",
             "locationName" :"Corporate Housing Reston, Va" 
    
         },
         "md-silverspring":{
             "image" : "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/city-images/SilverSpring-iStock-811548276.jpg",
             "title" : "PREMIUM LOCATIONS",
             "subTitle": "WORLDWIDE",
             "city" : "SILVER SPRING",
             "state" : "MARYLAND",
             "country" : "US",
             "countryId" : "2",
             "state_id" : '27',
             "city_id" : '681',
             "address" : "MARYLAND",
             "metaDescription":"Suite America provides platinum-level solutions in Corporate Housing & Short Term Furnished Apartments in  Silver Springs, MD.  Downtown Silver Spring hosts festivals throughout the year, the most notable of which are Silverdocs, a documentary film festival, and the Silver Spring Jazz Festival. Enjoy the up and coming town of Silver Spring while staying in SuiteAmerica’s corporate housing and short term furnished apartments",
             "metaTitle" : "Corporate Housing Silver Springs, MD  | Short Term Furnished Apartments  Silver Springs, MD | 800 367 9509",
             "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/md/silverspring",
             "cityIntro" :"Silver Spring, Maryland is located on the Capital Beltway.  Its most southern part is a major business hub that borders northern Washington D.C. With the recent additions of major retail and residential developments, Silver Spring is going through a bit of a renaissance. Downtown Silver Spring hosts festivals throughout the year, the most notable of which are Silverdocs, a documentary film festival, and the Silver Spring Jazz Festival. Enjoy the up and coming town of Silver Spring while staying in SuiteAmerica’s corporate housing and short term furnished apartments.",
             "locationName" :"Corporate Housing Silver Springs, MD" 
    
         },
         "va-tysons":{
             "image" : "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/city-images/Tysons-iStock-685773914.jpg",
             "title" : "PREMIUM LOCATIONS",
             "subTitle": "WORLDWIDE",
             "city" : "TYSONS",
             "state" : "VIRGINIA",
             "country" : "US",
             "countryId" : "2",
             "state_id" : '62',
             "city_id" : '54544',
             "address" : "VIRGINIA",
             "metaDescription":"SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apartments in Tysons, VA. . Tysons has lofty dreams—and plans—to be transformed into a walkable, sustainable urban center where people can live, work, and play. Take advantage now, and stay in SuiteAmerica’s corporate housing and short term furnished apartments in Tysons before word gets out about this promising town.",
             "metaTitle" : "Corporate Housing Tysons, VA | Short Term Furnished Apartments  Tysons, VA | 800 367 9510",
             "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/va/tysons",
             "cityIntro" :"Formerly known as Tysons Corner, Tysons, Virginia started as a rural farming community and is now the corporate headquarters for several international companies. Tysons has lofty dreams—and plans—to be transformed into a walkable, sustainable urban center where people can live, work, and play. Take advantage now, and stay in SuiteAmerica’s corporate housing and short term furnished apartments in Tysons before word gets out about this promising town.",
             "locationName" :"Corporate Housing Tysons, VA" 
    
         },
         "tx-houston":{
             "image" : "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/city-images/Houston-iStock-884014956.jpg",
             "title" : "PREMIUM LOCATIONS",
             "subTitle": "WORLDWIDE",
             "city" : "Houston",
             "state" : "TEXAS",
             "country" : "US",
             "countryId" : "2",
             "state_id" : '59',
             "city_id" : '0',
             "address" : "TEXAS",
             "metaDescription":"Suite America provides platinum-level solutions in Corporate Housing & Short Term Furnished Apartments in Houston, TX. As the most diverse city in Texas, Houston has an active art scene in the Theater District, boasts over 7 million visitors per year to its Museum District, and is home to several professional sports teams. Staying in SuiteAmerica’s corporate housing and short term furnished apartments in Houston provides a world class experience in one of America’s most diverse cities.",
             "metaTitle" : "Corporate Housing Houston, TX | Short Term Furnished Apartments Houston, TX  | 800 367 9511",
             "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/tx/houston",
             "cityIntro" : "With the second most Fortune 500 headquarters in the country, Houston has become an energetic city for young professionals and families. As the most diverse city in Texas, Houston has an active art scene in the Theater District, boasts over 7 million visitors per year to its Museum District, and is home to several professional sports teams. Staying in SuiteAmerica’s corporate housing and short term furnished apartments in Houston provides a world class experience in one of America’s most diverse cities.",
             "locationName" :"Corporate Housing Houston, TX" 
         },
         "bayarea":{
             "image" : "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/city-images/BayArea-iStock-495033930.jpg",
             "title" : "PREMIUM LOCATIONS",
             "subTitle": "WORLDWIDE",
             "city" : "Bay Area",
             "state" : "CALIFORNIA",
             "country" : "US",
             "countryId" : "2",
             "state_id" : '8',
             "city_id" : '25247',
             "address" : "CALIFORNIA",
             "metaDescription":"SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apartments in the San Francisco, Bay Area. Here, you won’t encounter one culture or way of life, you’ll encounter thousands. SuiteAmerica’s corporate housing and short term furnished apartments in the Bay Area allow for an opportunity to explore this premier California area with seven professional sports teams, numerous tourist attractions, iconic entertainment venues, world renowned universities, and breathtaking views.",
             "metaTitle" : "Corporate Housing Bay Area | Short Term Furnished Apartments Bay Area| 800 367 9512",
             "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/bayarea",
             "cityIntro" : "An immensely diverse, busy, and beautiful place, the Bay Area is the ideal spot for relocating or a temporary assignment. Here, you won’t encounter one culture or way of life, you’ll encounter thousands. SuiteAmerica’s corporate housing and short term furnished apartments in the Bay Area allow for an opportunity to explore this premier California area with seven professional sports teams, numerous tourist attractions, iconic entertainment venues, world renowned universities, and breathtaking views.",
             "locationName" :"Corporate Housing Bay Area" 
    
         },
         "orangecounty":{
             "image" : "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/city-images/OrangeCounty-iStock-667239838.jpg",
             "title" : "PREMIUM LOCATIONS",
             "subTitle": "WORLDWIDE",
             "city" : "Orange County",
             "state" : "CALIFORNIA",
             "country" : "US",
             "countryId" : "2",
             "state_id" : '8',
             "city_id" : '24198',
             "address" : "CALIFORNIA",
             "metaDescription":"Suite America provides platinum-level solutions in Corporate Housing & Short Term Furnished Apartments in Orange County, Ca. Orange County is a family-friendly place with some of the greatest theme parks in the country. Visit Disneyland or Knott’s Berry Farm, catch an Angel’s game, learn to surf at Huntington Beach, or go shopping at Fashion Island. Enjoy some thrills and California sunshine during your stay in Orange County.",
             "metaTitle" : "Corporate Housing Orange County | Short Term Furnished Apartments  Orange County | 800 367 9513",
             "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/orangecounty",
             "cityIntro" : "SuiteAmerica’s luxurious corporate housing and short term furnished apartments in Orange County provide upscale accommodations during a relocation or temporary assignment. Orange County is a family-friendly place with some of the greatest theme parks in the country. Visit Disneyland or Knott’s Berry Farm, catch an Angel’s game, learn to surf at Huntington Beach, or go shopping at Fashion Island. Enjoy some thrills and California sunshine during your stay in Orange County.",
             "locationName" :"Corporate Housing Orange County" 
    
         },
         "siliconvalley":{
             "image" : "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/city-images/SiliconValley-iStock-913113340.jpg",
             "title" : "PREMIUM LOCATIONS",
             "subTitle": "WORLDWIDE",
             "city" : "Silicon Valley",
             "state" : "CALIFORNIA",
             "country" : "US",
             "countryId" : "2",
             "state_id" : '8',
             "city_id" : '25247',
             "address" : "CALIFORNIA",
             "metaDescription":"SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apartments in the Silicon Valley. Balanced by family-friendly neighborhoods, excellent schools, and high quality of life rankings, Silicon Valley is a great place to work and live. With SuiteAmerica’s corporate housing and short term furnished apartments, Silicon Valley relocations and temporary assignments become a world class experience for everyone looking to invent, invest, and enjoy life.",
             "metaTitle" : "Corporate Housing Silicon Valley | Short Term Furnished Apartments  Silicon Valley | 800 367 9514",
             "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/siliconvalley",
             "cityIntro" : "Silicon Valley, California is an international hub for technology, innovation, and start-ups. Balanced by family-friendly neighborhoods, excellent schools, and high quality of life rankings, Silicon Valley is a great place to work and live. With SuiteAmerica’s corporate housing and short term furnished apartments, Silicon Valley relocations and temporary assignments become a world class experience for everyone looking to invent, invest, and enjoy life.",
             "locationName" :"Corporate Housing Silicon Valley" 
    
         },
         "ga-atlanta":{
             "image" : "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/city-images/Atlanta-iStock-847273170.jpg",
             "title" : "PREMIUM LOCATIONS",
             "subTitle": "WORLDWIDE",
             "city" : "Atlanta",
             "state" : "GEORGIA",
             "country" : "US",
             "countryId" : "2",
             "state_id" : '15',
             "city_id" : '0',
             "address" : "GEORGIA",
             "metaDescription":"Suite America provides platinum-level solutions in Corporate Housing & Short Term Furnished Apartments in  Atlanta. GA. SuiteAmerica’s corporate housing and short term furnished apartments in Atlanta provide a world class experience in a city that’s constantly redefining American identity, activism, and global outreach.",
             "metaTitle" : "Corporate Housing Atlanta, GA | Short Term Furnished Apartments  Atlanta, GA | 800 367 9515",
             "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/ga/atlanta",
             "cityIntro" : "Home to the world’s busiest airport, Atlanta has a large impact on global commerce, finance, research, art, and entertainment. The capital of Georgia literally rose from the ashes after the Civil War to not only become the center of the 1960’s Civil Rights Movement but to also become a hub of increasingly diverse and dynamic American culture. SuiteAmerica’s corporate housing and short term furnished apartments in Atlanta provide a world class experience in a city that’s constantly redefining American identity, activism, and global outreach.",
             "locationName" :"Corporate Housing Atlanta, GA" 
         },
         "ny-newyork":{
             "image" : "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/city-images/NewYorkCity-iStock-918444850.jpg",
             "title" : "PREMIUM LOCATIONS",
             "subTitle": "WORLDWIDE",
             "city" : "Atlanta",
             "state" : "NEWYORK",
             "country" : "US",
             "countryId" : "2",
             "state_id" : '44',
             "city_id" : '0',
             "address" : "NEWYORK",
             "metaDescription":"SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apartments in New York, NY. The most populated city in the United States, NYC has five distinct boroughs, each with its own unique characteristics and offerings. SuiteAmerica’s luxurious corporate housing and short term furnished apartments in New York City allow for a seamless relocation or temporary assignment while exploring the place so deeply symbolic of American ideals.",
             "metaTitle" : "Corporate Housing New York, NY | Short Term Furnished Apartments  New York, NY | 800 367 9516",
             "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/ny/newyork",
             "cityIntro" : "As the epicenter of East Coast business and culture, New York City symbolizes American opportunity and diversity.  The most populated city in the United States, NYC has five distinct boroughs, each with its own unique characteristics and offerings. SuiteAmerica’s luxurious corporate housing and short term furnished apartments in New York City allow for a seamless relocation or temporary assignment while exploring the place so deeply symbolic of American ideals.",
             "locationName" :"Corporate Housing New York, NY" 
         }
    }
    
    
    module.exports = cityPages