import DatePicker from 'react-datepicker'
import moment from 'moment'
import AppController from "../../controller/appController";
import MainController from "../../controller/mainController";
import SubFooter from "../../common/subFooter";
import NikeServices from '../../../services/nikeService';
import GuestHeader from '../../common/guestHeader';
import Footer from '../../common/footer';
import SuccessModal from '../../common/successModal';
import ReCAPTCHA from "react-google-recaptcha"

class RelocationPriceForm extends React.Component{
constructor(props){
    super(props)
    this.state={
                       CaptchaValue:'',
                       requestedDate: moment().tz("America/Los_Angeles").format('MM/DD/YYYY hh:mm A'),
                       requestedBy: '',
                       email: '',
                       phone: '',
                       cellPhone: '',
                       guestArrivalDate:moment(),
                       guestArrivalDateSelected:'',
                       projectPricingInfo:'',
                       ProjectTypeIns: '',
                       pricingInfo: '',
                       additionalInfo: '',
                       arrivalDate: '',
                       color:'',
                       estimatedDate:moment().tz("America/Los_Angeles").format('MM/DD/YYYY hh:mm A'),
                       errorMessage:'',
                       projectTypeSelected:'Intern/Groups in Core',
                       relocationProviderSelected:'Alison',
                       projectType:['Intern/Groups in Core','Intern/Groups out of area','RFP for NBL in core'],
                       relocationProvider:['Alison','Amanda','Brenda','Brittani','Jennifer','Joseph','Katrina','Kimberly','Libby','Lisa','Lori S.','Lydia','Matt','Michael','Nathan','Wade']
                    }
                    this.setValue = this.setValue.bind(this);
                    this.clear = this.clear.bind(this);
                    this.serviceRequest = this.serviceRequest.bind(this);
                    this.setChecked = this.setChecked.bind(this);
                    this.handleMoveIn = this.handleMoveIn.bind(this);
                    this.onChangeCaptcha = this.onChangeCaptcha.bind(this);
                 }
                 /* It is invoked immediately before mounting occurs */
                 componentWillMount() {
                   MainController.getInitialInformation();
                }     
                /* Form Details object creations  */        
              setValue(field, e) {
                    var object = {};
                    object[field] = e.target.value;
                    this.setState(object);
                  }
              /* Form Details object creations  */        
              setChecked(e) {    
                this.setState({arrivalDate:e.target.checked});
              }            
             /* MoveIndate and MoveOutDate   */
              async handleMoveIn(date){
                    await this.setState({guestArrivalDate: moment(date),
                        guestArrivalDateSelected:date.format('YYYY-MM-DD HH:mm:ss') });
                } 
                clear(){
                    AppController.menuTopNavigation('serviceRequest');
                this.setState({
                    requestedDate: moment.utc(new Date()).format("MM/DD/YYYY hh:mm A"),
                    requestedBy: '',
                    email: '',
                    phone: '',
                    cellPhone: '',
                    ProjectTypeIns:'',
                    pricingInfo:'',
                    additionalInfo:'',
                    projectPricingInfo:'',
                    arrivalDate:false,
                    guestArrivalDate:moment(),
                    estimatedDate:moment.utc(new Date()).format("MM/DD/YYYY hh:mm A"),
                    errorMessage:'',
                    color:'green',
                })
                }            
               /* Service Request Details send to mail  */
               async serviceRequest(){
                 const moveIn = this.state.guestArrivalDateSelected == '' ? this.state.guestArrivalDate.format('YYYY-MM-DD HH:mm:ss'): this.state.guestArrivalDateSelected ;
                 const _this = this
                    const validationFields=['requestedBy','email','phone','cellPhone','ProjectTypeIns','pricingInfo','additionalInfo','projectPricingInfo','CaptchaValue']
                    let error = 0;
                    _.forEach(validationFields, function(value) {
                       if(_this.state[value] == ""){
                         error++;
                       }
                   });
                 if(error == 0){
                    const Request={
                        requestedDate:this.state.requestedDate,
                        phone:this.state.phone,
                        requestedBy:this.state.requestedBy,
                        email:this.state.email,
                        cellPhone:this.state.cellPhone,
                        ProjectTypeIns:this.state.ProjectTypeIns,
                        pricingInfo:this.state.pricingInfo,
                        additionalInfo:this.state.additionalInfo,
                        projectPricingInfo:this.state.projectPricingInfo,
                        relocationProviderSelected:this.state.relocationProviderSelected,
                        estimatedDate:this.state.estimatedDate,
                        projectTypeSelected:this.state.projectTypeSelected,
                        guestArrivalDate:moveIn,
                        arrivalDate:this.state.arrivalDate,
                        mail:'relocationRequest',
                        subject: 'Relocation Request Form'
                    }            
                this.setState({
                    requestedDate: moment.utc(new Date()).format("MM/DD/YYYY hh:mm A"),
                    requestedBy: '',
                    email: '',
                    phone: '',
                    cellPhone: '',
                    ProjectTypeIns:'',
                    pricingInfo:'',
                    additionalInfo:'',
                    projectPricingInfo:'',
                    arrivalDate:false,
                    guestArrivalDate:moment(),
                    estimatedDate:moment.utc(new Date()).format("MM/DD/YYYY hh:mm A"),
                    errorMessage:'',
                    color:'',
                    
                })

                AppController.menuTopNavigation('serviceRequest');
                jQuery(function($) {
                    $('#successModal').modal('show');
                    setTimeout(function(){
                      $('#successModal').modal('hide');
                    },4000);
                  });
                await NikeServices.serviceRequest(Request)   
            }
            else{
                AppController.menuTopNavigation('serviceRequest');
                this.setState({errorMessage:'Please Enter Required Fields ',color:'red'})
            }   
               }
               async onChangeCaptcha(CaptchaValue) {
                await this.setState({CaptchaValue})
                }

render(){
 return(<div className="corporateHousing_Updates">
 <GuestHeader/>
 <div className="cover-tophd-ht"></div>

<div className="container-fluid nike company_section" id="serviceRequest">
<div className="container text-center">
<h2 className="">Relocation Request Form
for Rates/Pricing Projects</h2>

</div>

</div>

<div className="container-fluid nike-datepicker bg-grey service_request">
<div className="request_form company_form" >
<div className="container">


<form className="well">
<p style={{color:this.state.color}}>{this.state.errorMessage}</p>
<h1>Contact Information</h1>

<div className="row">
<div className="col-sm-6 col-md-6">
<input type="text" className="form-control" placeholder="*Requested" value={this.state.requestedDate} onChange={(e) => this.setValue('requestedDate', e)} disabled/>
<input type="text" className="form-control" placeholder="*Requested By" value={this.state.requestedBy} onChange={(e) => this.setValue('requestedBy', e)} />
<input type="text" className="form-control" placeholder="*Cell Phone" value={this.state.cellPhone} onChange={(e) => this.setValue('cellPhone', e)} />

<div className="row">
 <div className="col-sm-6">
 
 </div>
 <div className="col-sm-6">
 
 </div>
 </div>

</div>
<div className="col-sm-6 col-md-6">
<input type="text" className="form-control" placeholder="*Phone" value={this.state.phone} onChange={(e) => this.setValue('phone', e)}/>
<input type="text" className="form-control" placeholder="*Email Address" value={this.state.email} onChange={(e) => this.setValue('email', e)} />

</div>

</div>
<hr />
<h1>Project Instructions</h1>
<div className="row">
<div className="col-sm-6 col-md-6">
<label className="custom-select">
     <select value={this.state.projectTypeSelected} 
     onChange={(e) => this.setValue('projectTypeSelected', e)}>
     <option >Non Selected</option>
     {this.state.projectType.map((row,index)=>{
         return(
             <option key={index} value={row}>{row}</option>
         )
     })}
 
 </select>
</label>
<textarea  rows="3" className="form-control" placeholder="Pricing Information, Lease Terms & Unit Sizes:" value={this.state.pricingInfo} onChange={(e) => this.setValue('pricingInfo', e)} />
<textarea  rows="3" className="form-control" placeholder="Communities & Additional Information:" value={this.state.additionalInfo} 
onChange={(e) => this.setValue('additionalInfo', e)} />
<input     type="text" className="form-control" placeholder="Estimated Delievery" value={this.state.estimatedDate} disabled/>
</div>
<div className="col-sm-6 col-md-6">
<DatePicker type='text' className="form-control" placeholder="Guest Arrival Date" selected={this.state.guestArrivalDate}  onChange={this.handleMoveIn} />
<textarea className="form-control" rows="3" placeholder="Project Type Instructions:" value={this.state.ProjectTypeIns} onChange={(e) => this.setValue('ProjectTypeIns', e)} />  
<div className="row">
 <div className="col-sm-12 ">
 <textarea className="form-control" rows="3" placeholder="Pricing Project Details:" value={this.state.projectPricingInfo} onChange={(e) => this.setValue('projectPricingInfo', e)} />  
 <label className="customCheck"><input type="checkbox"  checked={this.state.arrivalDate}  
 onChange={(e) => this.setChecked(e)} />   <span className="checkmark"></span> No Arrival Date</label>
 </div>
 <div className="col-sm-5 col-md-offset-1">
 
 </div>
 </div>
</div>
</div>
<hr />
<h1>Final Product Delivery</h1>
<div className="row">
<div className="col-sm-6 col-md-6">
<label className="custom-select">
     <select value={this.state.relocationProviderSelected} 
     onChange={(e) => this.setValue('relocationProviderSelected', e)}>
     <option >Non Selected</option>
     {this.state.relocationProvider.map((row,index)=>{
         return(
             <option key={index} value={row}>{row}</option>
         )
     })}
 
 </select>
</label>
<h6>A copy of the requested document will be sent to the Sales Manager for final proofing.</h6>
</div>

</div>
<div className="row">                                         
    <div className="col-sm-12 col-md-12">
        <ReCAPTCHA ref="recaptcha"sitekey="6Lcbw1AUAAAAAE5K5cYDyhoGu0RJY9C3CLtGFXAo" onChange={this.onChangeCaptcha}/>
    </div>
 </div>
</form>
<button type="button" className=" btn location_btn mrg-btm-50" onClick={this.clear}> CLEAR</button>
<button type="button" className=" btn location_btn mrg-btm-30" onClick={this.serviceRequest}> SUBMIT</button>
</div>
</div>
</div>
 
<SubFooter/>
<Footer/>
<SuccessModal/>
  </div>
  )

}

}


export default RelocationPriceForm