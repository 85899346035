/* Importing the node modules, child components, services and controllers used 
   inside Register component */
import $ from "jquery";
import AppController from "../../controller/appController";
import GuestHeader from '../../common/guestHeader';
import GuestServicesapi from '../../../services/guestServicesapi';
import SubFooter from "../../common/subFooter";
import Footer from '../../common/footer';
import SuccessModal from '../../common/successModal';


/* Register Component initialization */
class GuestRegistrationWithoutMail extends React.Component {
    /* Initializing objects of its Register class */
    constructor(props) {
        super(props)
        /* Register Component State variables Initialization */
        this.state = {
            email: '',
            password: '',
            confirmPassword: '',
            message: '',
            onNext: false,
            guestprospect: false,
            userData: [],
            color: '',
            loader: false,
            auth0Response: '',
            auth0UserExist: '',
            accessToken: ''
        }
        this.setValue = this.setValue.bind(this);
        this.register_guest_prospective = this.register_guest_prospective.bind(this);
        this.next = this.next.bind(this);
        this.clearAlerts = this.clearAlerts.bind(this);
    }
    /* It is invoked immediately before mounting occurs */
    componentWillMount() {
        AppController.removeLocalStorageData();
        $('html, body').animate({ scrollTop: 0 });
        AppController.stickyTitles()

    }
    async clearAlerts(e) {
        e.preventDefault();
        this.setState({ message: '', color: '', loader: false });
    }
    /* Update the state object of the form */
    setValue(field, e) {
        var object = {};
        object[field] = e.target.value;
        this.setState(object);
    }
    /* Email authentication and sending email to the respected email id */
    /**
       * Register Next Method
       * @param {*} e
       */
    async next(e) {
        e.preventDefault();
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (this.state.email != '' && reg.test(this.state.email) ) {
            this.setState({ message: '', color: '', loader: true })
            const login = {
                email: this.state.email
            }
            const userData = await GuestServicesapi.getSingleGuestDetails(login)
            console.log(userData, "userData");
            if (userData.OrderId != null && userData.OrderId != '') {
                let data = {
                    email: this.state.email,
                }
                const SendSignUpEmailLink = await GuestServicesapi.SendSignUpEmailLink(data);
                if (SendSignUpEmailLink.message == "Success") {
                    this.setState({ message: 'Please check your email for further instructions.', email: '', loader: false, color: 'green' })
                } else if (SendSignUpEmailLink.message == "Email already exists") {
                    this.setState({ message: SendSignUpEmailLink.message, email: '', color: 'red', loader: false })
                }else if (SendSignUpEmailLink.error == 1) {
                    this.setState({ message: "The email doesn't exist in our system. Please contact your SuiteAmerica contact to update your reservation with your email.", email: '', color: 'red', loader: false })
                }
               

            }
            else if (userData.webCount == 0 && userData.OrderId == '') {
                this.setState({ loader: false, email: '', message: "The email doesn't exist in our system. Please contact your SuiteAmerica contact to update your reservation with your email.", color: 'red' });
              
            } else if (userData == 'Failure') {
                this.setState({ message: "We do not have your email on file. please contact your POC to update your email.", email: '', color: 'red', loader: false })
            }

          
        } else {
            this.setState({ message: 'Enter valid email.', color: 'red' })
        }
    }
    async register_guest_prospective(e) {
        e.preventDefault();
        console.log('register')
        let regularExpression = /^(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@^_`{|}~]{10,16}$/;
        let minNumberofChars = 10;
        let maxNumberofChars = 16;
        let newPassword = this.state.password;
        let confirmPassword = this.state.confirmPassword;
        if ((this.state.password == this.state.confirmPassword) && (newPassword.length < minNumberofChars || newPassword.length > maxNumberofChars)) {
            this.setState({ message: 'Your password must be at least 10 characters', color: 'red' })
        }
        else if (regularExpression.test(newPassword) && regularExpression.test(confirmPassword)) {
            this.setState({ message: '', loader: true })
            const register = {
                guestEmailId: this.state.email,
                guestPwd: this.state.password,
                guestIsActive: 1,
                guestFirstName: this.state.userData.GuestFirstName != null
                    ? this.state.userData.GuestFirstName
                    : '',
                guestLastName: this.state.userData.GuestLastName != null
                    ? this.state.userData.GuestLastName
                    : '',
                guestPhoneNumber: this.state.userData.GuestPhoneNo != null
                    ? this.state.userData.GuestPhoneNo
                    : '',
                guestOrderId: this.state.userData.OrderId != null
                    ? this.state.userData.OrderId
                    : ''
            }
            const userData = await GuestServicesapi.saveGuestUserInfo(register)
            if (userData) {
                this.setState({ message: 'Registered Successfully', color: 'green', loader: false })
                setTimeout(function () {
                    this.setState({
                        login: true,
                        register: false,
                        email: '',
                        password: '',
                        message: '',
                        onNext: false
                    })
                }.bind(this), 2000);
            }
        } else {
            this.setState({ message: 'Password should contain atleast one number and one special character', color: 'red' })
        }
    }
    /* It is invoked to return html content */
    render() {
        return (
            <div className="corporateHousing_Updates bg-grey">

                <GuestHeader />
                <div className="login-form-SA">
                    <div className="l-form">
                        <div className="item item2">
                            <div className="card-body">
                                <h1 style={{fontSize: '23px'}}>{'Guest Portal Registration'}</h1>
                                {this.state.message ? <span style={{ color: this.state.color, textAlign: 'center' }}>
                                    <div className={this.state.color == 'red' ? "alert alert-danger alert-dismissible" : "alert alert-success alert-dismissible"}>
                                        <a href="#" className="close" data-dismiss="alert" aria-label="close" onClick={(e) => this.clearAlerts(e)}>&times;</a>
                                        {this.state.message}
                                    </div>
                                </span> : ''}
                                <div className="form-group">
                                    <input type="text" className="form-control" id="email" placeholder="Email" value={this.state.email}
                                        onChange={(e) => this.setValue('email', e)} />

                                </div>
                                {this.state.loader?
                                    <div className="panel-body_loader">
                                        <span style={{ fontSize: '20px', width: '100%', margin: 'auto', textAlign: 'center' }}><i className="fa fa-spinner fa-spin" />
                                            <span style={{ marginLeft: '10px' }}> please wait ...!</span> </span>
                                    </div>
                                    :
                                    <div>

                                        <div>
                                            <button type="button" className="btn btn-primary" onClick={this.state.onNext ? (e) => this.register_guest_prospective(e) :
                                                (e) => this.next(e)}>Send Registration Link</button>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                        <div className="item item3" >
                            <div className="right-side">
                                <h1>Hello, Guest</h1>
                                <p>Already registered? </p>
                                <a href="/guest-login" className="btn btn-primary">Sign In</a>
                            </div>

                        </div>

                        <div className="m-login-registercls">
                            Already registered?  <a href="/guest-login">Sign In</a>

                        </div>

                    </div>
                </div>
                <SubFooter />
                <Footer />
                <SuccessModal />
            </div>






        );
    }
}
export default GuestRegistrationWithoutMail;