import MainController from "../../controller/mainController";
import SubFooter from "../../common/subFooter";
import GuestHeader from '../../common/guestHeader';
import Footer from '../../common/footer';
import MediaFooter from "./common/mediaFooter";
import MetaTags from '../../common/metaTags';
import ServerMetaTags from '../../../server/metaTags';

/* MediaPointsOfDifference Component initialization */
class MediaPointsOfDifference extends React.Component {
    /* Initializing objects of its MediaPointsOfDifference class */
    constructor(props) {
        super(props);
    }
    /* It is invoked immediately before mounting occurs */
    componentWillMount() {
        MainController.getInitialInformation();
    }
    /* It is invoked to return html content */
    render() {
        const metaData = ServerMetaTags.urlString.getMetaData(this.props.match.url);
        return (
            <div>
            <MetaTags 
             metaTitle = {metaData.title}
             metaDesription = {metaData.description}
             metaUrl = {metaData.canonical} />
            <GuestHeader/>
            <div className="cover-tophd-ht company_ht_cover"></div>
            <div className="quote-section bg-white">
                <div className="container mediakit">
                    <div className="plaine-text global-coverage-wrap col-md-10 col-md-offset-1 wow fadeInUp" data-wow-delay="0.5s">
                        <h2 className="mrg-btm-50 color-red">POINTS OF DIFFERENCE</h2>
                        <p>Having over 25 years of experience in corporate housing and an industry leader you can trust, SuiteAmerica 
                        outweighs the competition. An award winning company, our culture is what makes all 
                        the difference in providing guest satisfaction time after time. 
                        </p>
                        
                        <h5 className="text-left mrg-btm-0 font700"><strong>24hr Online Account Access</strong></h5>
                        <ul>
                            <li>Providing easy access to your account</li>
                         </ul>   

                         
                         <h5 className="text-left mrg-btm-0 font700"><strong>A Single Point of Contact</strong></h5>
                        <ul>
                            <li>Streamlined services</li>
                         </ul> 

                         
                         <h5 className="text-left mrg-btm-0 font700"><strong>Complimentary Personal Transition Services</strong></h5>
                        <ul>
                            <li>A white glove, in-person service</li>
                         </ul> 

                         
                         <h5 className="text-left mrg-btm-0 font700"><strong>7 Days-a-Week Concierge Services</strong></h5>
                        <ul>
                            <li>Transportation, moving and same day delivery services</li>
                         </ul> 

                         <h5 className="text-left mrg-btm-0 font700"><strong>Guest Services Available 24/7</strong></h5>
                        <ul>
                            <li>A SuiteAmerica representative will always available</li>
                         </ul> 

                         <h5 className="text-left mrg-btm-0 font700"><strong>One Time Master Lease</strong></h5>
                        <ul>
                            <li>Hassle-free and easy renting</li>
                         </ul> 

                         <h5 className="text-left mrg-btm-0 font700"><strong>Centralized Reservations and Services</strong></h5>
                        <ul>
                            <li>Providing quality services for all time zones</li>
                         </ul> 

                         <h5 className="text-left mrg-btm-0 font700"><strong>Customized Apartment, Furniture and Amenities Packages</strong></h5>
                        <ul>
                            <li>A wide variety of options to meet each individual’s expectations</li>
                         </ul> 

                         <h5 className="text-left mrg-btm-0 font700"><strong>Complimentary High-Speed Internet</strong></h5>
                        <ul>
                            <li>Ensuring accessible internet for all</li>
                         </ul> 
                         <h5 className="text-left mrg-btm-0 font700"><strong>24 Hour-a-day Meet & Greet Services</strong></h5>
                        <ul>
                            <li>A friendly face to welcome you at your new home instead of a lock box</li>
                         </ul> 
                         <h5 className="text-left mrg-btm-0 font700"><strong>Permanent Housing Options</strong></h5>
                        <ul>
                            <li>Incentives towards permanent housing</li>
                         </ul> 
                         <h5 className="text-left mrg-btm-0 font700"><strong>Same Day Turn Arounds</strong></h5>
                        <ul>
                            <li>Quick and efficient preparation between guests</li>
                         </ul> 

                         
                    </div>
                    <MediaFooter />
                </div>
            </div>
            <SubFooter />
            <Footer/>
        </div>

        );
    }
}
export default MediaPointsOfDifference;