/* Importing the node modules, child components, services and controllers used 
   inside SuccessModal component */
   const ReservationSuccesModal = () => {
    return(
        <div className="modal fade savesearch" id="reservationsuccessmodal" role="dialog">
            <div className="modal-dialog">
                <div className="modal-content rm-border-radius">
                    <div className="modal-header">
                    <button type="button" className="close btn" data-dismiss="modal" aria-label="">
                    <i className="bi bi-x"></i>
                    </button>
                    </div>
                    <div className="modal-body">
                    <p>Thank you for your request!  A SuiteAmerica team member will respond with availability and rates shortly.  If your request is submitted outside of our normal business hours of Monday thru Friday, 6:00 AM to 5:00 PM, PST, we will respond to your request the next business day.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ReservationSuccesModal;