import React from "react";

// Custom CSS to supplement Bootstrap 1.11
const styles = {};

const GuestCheckoutNotice = ({ guestName, checkoutDate, checkoutTime }) => (
  <>
    <tr>
      <td style={{ fontSize: "0", lineHeight: "0" }} height="30">
        &nbsp;
      </td>
    </tr>

    <tr>
      <td>
        <table
          border="0"
          cellPadding="0"
          cellSpacing="0"
          style={{ width: "95%", margin: "auto" }}
        >
          <tr>
            <td
              style={{
                fontSize: "25px",
                fontFamily: "'Sora', sans-serif",
                color: "#000000",
                textAlign: "left",
                fontWeight: "700",
              }}
            >
              Hi {guestName},
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: "0", lineHeight: "0" }} height="15">
              &nbsp;
            </td>
          </tr>

          <tr>
            <td
              style={{
                fontSize: "18px",
                fontFamily: "'Sora', sans-serif",
                color: "#000000",
                textAlign: "left",
                fontWeight: "500",
              }}
            >
              Since your departure date is unconfirmed, would you like us to
              send you reminders about submitting your notice to vacate? If so,
              please choose the reminder frequency below.
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: "0", lineHeight: "0" }} height="15">
              &nbsp;
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "16px",
                fontFamily: "'Sora', sans-serif",
                color: "#000000",
                textAlign: "left",
                fontWeight: "300",
              }}
            >
              Please note: You will still need to submit your notice to vacate
              in writing within the timeframe specified in your lease.
            </td>
          </tr>
        </table>
      </td>
    </tr>

    <tr>
      <td style={{ fontSize: "0", lineHeight: "0" }} height="30">
        &nbsp;
      </td>
    </tr>

    <tr>
      <td>
        <table className="radioBtnTable"
          style={{ width: "95%", margin: "auto" }}
          border="0"
          cellpadding="0"
          cellspacing="0"
        >
          <tr>
            <td>
              <table>
                <tr>
                  <td
                    style={{
                      width: "25px",
                      height: "25px",
                      display: "inline-block",
                      background: "#f9f9f9",
                      borderRadius: "50%",
                      boxShadow: "0px 0px 5px #787878",
                    }}
                  ></td>
                  <td
                    style={{
                      fontSize: "18px",
                      fontFamily: "'Sora', sans-serif",
                      color: "#000000",
                      textAlign: "left",
                      fontWeight: "300",
                      paddingLeft: "10px",
                    }}
                  >
                    7 Days
                  </td>
                </tr>
                <tr>
                  <td style={{ fontSize: "0", lineHeight: "0" }} height="10">
                    &nbsp;
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: "25px",
                      height: "25px",
                      display: "inline-block",
                      background: "#f9f9f9",
                      borderRadius: "50%",
                      boxShadow: "0px 0px 5px #787878",
                    }}
                  ></td>
                  <td
                    style={{
                      fontSize: "18px",
                      fontFamily: "'Sora', sans-serif",
                      color: "#000000",
                      textAlign: "left",
                      fontWeight: "300",
                      paddingLeft: "10px",
                    }}
                  >
                    14 Days
                  </td>
                </tr>
                <tr>
                  <td style={{ fontSize: "0", lineHeight: "0" }} height="10">
                    &nbsp;
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: "25px",
                      height: "25px",
                      display: "inline-block",
                      background: "#f9f9f9",
                      borderRadius: "50%",
                      boxShadow: "0px 0px 5px #787878",
                    }}
                  ></td>
                  <td
                    style={{
                      fontSize: "18px",
                      fontFamily: "'Sora', sans-serif",
                      color: "#000000",
                      textAlign: "left",
                      fontWeight: "300",
                      paddingLeft: "10px",
                    }}
                  >
                    21 Days
                  </td>
                </tr>
                <tr>
                  <td style={{ fontSize: "0", lineHeight: "0" }} height="10">
                    &nbsp;
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: "25px",
                      height: "25px",
                      display: "inline-block",
                      background: "#f9f9f9",
                      borderRadius: "50%",
                      boxShadow: "0px 0px 5px #787878",
                    }}
                  ></td>
                  <td
                    style={{
                      fontSize: "18px",
                      fontFamily: "'Sora', sans-serif",
                      color: "#000000",
                      textAlign: "left",
                      fontWeight: "300",
                      paddingLeft: "10px",
                    }}
                  >
                    30 Days
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </td>
    </tr>

    <tr>
      <td style={{ fontSize: "0", lineHeight: "0" }} height="45">
        &nbsp;
      </td>
    </tr>

    <tr>
      <td>
        <table
          style={{ width: "95%", margin: "auto" }}
          border="0"
          cellpadding="0"
          cellspacing="0"
        >
          <tr>
            <td>
              <table style={{ width: "100%" }}>
                <tr>
                  <td>
                    <a
                      href="https://suiteamerica.com/homepage"
                      target="_blank"
                       className="a-btn"
                      style={{
                        color: "#ffffff",
                        background: "#000000",
                        fontSize: "13px",
                        fontFamily: "'Sora', sans-serif",
                        fontWeight: "600",
                        border: "0",
                        padding: "12px 30px",
                        borderRadius: "25px",
                        boxShadow: "0px 0px 10px #c9c9c9",
                        textDecoration: "none",
                      }}
                    >
                      Confirm Reminder
                    </a>
                    <a
                      href="https://suiteamerica.com/homepage"
                      target="_blank"
                      className="a-btn ms-4"
                      style={{
                        color: "#ffffff",
                        background: "#000000",
                        fontSize: "13px",
                        fontFamily: "'Sora', sans-serif",
                        fontWeight: "600",
                        border: "0",
                        padding: "12px 30px",
                        borderRadius: "25px",
                        boxShadow: "0px 0px 10px #c9c9c9",
                        textDecoration: "none",
                      }}
                    >
                      Submit Notice Now
                    </a>
                  </td>
                 
                </tr>
              </table>
            </td>
          </tr>

          <tr>
            <td style={{ fontSize: "0", lineHeight: "0" }} height="30">
              &nbsp;
            </td>
          </tr>

          <tr>
            <td style={{ fontSize: "0", lineHeight: "0" }} height="30">
              &nbsp;
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "18px",
                fontFamily: "'Sora', sans-serif",
                color: "#000000",
                textAlign: "left",
                fontWeight: "700",
              }}
            >
              Questions?
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: "0", lineHeight: "0" }} height="5">
              &nbsp;
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "18px",
                fontFamily: "'Sora', sans-serif",
                color: "#000000",
                textAlign: "left",
                fontWeight: "300",
              }}
            >
              <a
                href="mailto:someone@example.com"
                style={{
                  fontSize: "18px",
                  fontFamily: "'Sora', sans-serif",
                  color: "#077fd5",
                  textAlign: "left",
                  fontWeight: "400",
                }}
              >
                Send us an email
              </a>{" "}
              or give us a call 800.367.9501
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: "0", lineHeight: "0" }} height="30">
              &nbsp;
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "18px",
                fontFamily: "'Sora', sans-serif",
                color: "#000000",
                textAlign: "left",
                fontWeight: "700",
              }}
            >
              Download the app today
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: "0", lineHeight: "0" }} height="15">
              &nbsp;
            </td>
          </tr>
          <tr>
            <td>
              <a
                href="https://play.google.com/store/apps/details?id=com.suiteamerica.guestportal"
                target="_blank"
                style={{ paddingRight: "20px", textDecoration: "none" }}
              >
                <img
                  style={{ width: "130px" }}
                  src="https://chapp-bucket.s3.us-west-1.amazonaws.com/googlePlayStoreIcon.png"
                  alt=""
                />
              </a>
              <a
                href="https://apps.apple.com/us/app/guestportal/id1492423942"
                target="_blank"
                style={{ paddingRight: "20px", textDecoration: "none" }}
              >
                <img
                  style={{ width: "130px" }}
                  src="https://chapp-bucket.s3.us-west-1.amazonaws.com/appStoreIcon.png"
                  alt=""
                />
              </a>
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: "0", lineHeight: "0" }} height="15">
              &nbsp;
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "18px",
                fontFamily: "'Sora', sans-serif",
                color: "#000000",
                textAlign: "left",
                fontWeight: "300",
              }}
            >
              Or log into our passwordless{" "}
              <a
                href="https://www.suiteamerica.com/guest-login"
                target="_blank"
                style={{
                  fontSize: "18px",
                  fontFamily: "'Sora', sans-serif",
                  color: "#077fd5",
                  textAlign: "left",
                  fontWeight: "400",
                }}
              >
                Guestportal
              </a>
            </td>
          </tr>
        </table>
      </td>
    </tr>

    <tr>
      <td style={{ fontSize: "0", lineHeight: "0" }} height="40">
        &nbsp;
      </td>
    </tr>
  </>
);

export default GuestCheckoutNotice;
