/* Importing the node modules, child components, services and controllers used 
   inside clientPortalController object */
   'use strict'   
   import $ from "jquery";   
   
   /**
    * clientPortalController - controls application state and interaction among various components.
    */
   
   const clientPortalController = {     
     exportToExcel(title, row){
        var JSONData = title=="Active" ? _.map(row, function(o) { return _.pick(o, ['orderno', 'GuestFirstName', o.community_name != null ? 'community_name' : 'HotelName', o.address != null ? 'address' : 'HAddress1', o.city_name != null ? 'city_name' : 'HCity', o.statename != null ?'statename' : 'HState',  o.ZipCode != null ? 'ZipCode' : 'HZipCode', 'MoveInDate', 'MoveOutDate', 'Servicescount']); }) : _.map(row, function(o) { return _.pick(o, ['orderno', 'GuestFirstName', o.community_name != null ? 'community_name' : 'HotelName', o.address != null ? 'address' : 'HAddress1', o.city_name != null ? 'city_name' : 'HCity', o.statename != null ?'statename' : 'HState',  o.ZipCode != null ? 'ZipCode' : 'HZipCode', 'MoveInDate', 'MoveOutDate']); });
			var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
			var headerData = title=="Active" ? {
				"Order#": JSONData[0].orderno,
				"Resident": JSONData[0].GuestFirstName,
				"Community/Hotel": JSONData[0].community_name != undefined ? JSONData[0].community_name : JSONData[0].HotelName,
				"Address": JSONData[0].address != undefined ? JSONData[0].address : JSONData[0].HAddress1,
				"City Name": JSONData[0].city_name != undefined ? JSONData[0].city_name : JSONData[0].HCity,
				"State Name": JSONData[0].statename != undefined ? JSONData[0].statename : JSONData[0].HState,
				"Zip Code": JSONData[0].ZipCode != undefined ? JSONData[0].ZipCode : JSONData[0].HZipCode,
				"Move In Date": JSONData[0].MoveInDate,
				"Move Out Date": JSONData[0].MoveOutDate,
				"Services Count": JSONData[0].Servicescount,
				
			}:{
				"Order#": JSONData[0].orderno,
				"Resident": JSONData[0].GuestFirstName,
				"Community/Hotel": JSONData[0].community_name != undefined ? JSONData[0].community_name : JSONData[0].HotelName,
				"Address": JSONData[0].address != undefined ? JSONData[0].address : JSONData[0].HAddress1,
				"City Name": JSONData[0].city_name != undefined ? JSONData[0].city_name : JSONData[0].HCity,
				"State Name": JSONData[0].statename != undefined ? JSONData[0].statename : JSONData[0].HState,
				"Zip Code": JSONData[0].ZipCode != undefined ? JSONData[0].ZipCode : JSONData[0].HZipCode,
				"Move In Date": JSONData[0].MoveInDate,
				"Move Out Date": JSONData[0].MoveOutDate,				
			}
			var CSV = '';    
     		var ShowLabel = true;
			if (ShowLabel) {
				let rows = "";
				for (var index in headerData) {
					rows += index + ',';
				}
				rows = rows.slice(0, -1);
				CSV += rows + '\r\n';
			}
			
			for (var i = 0; i < arrData.length; i++) {
				var row = "";
				for (var index in arrData[i]) {
					const val = arrData[i][index];
					row += '"' + val + '",';
				}
				row.slice(0, row.length - 1);
				CSV += row + '\r\n';
			}
			if (CSV == '') {        
				alert("Invalid data");
				return;
			}   			
			var fileName = "";
			fileName += title.replace(/ /g,"_");   
			var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
			var link = document.createElement("a");    
			link.href = uri;
			link.style = "visibility:hidden";
			link.download = fileName + ".xlsx";
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
     },
     barChartGeneration(labels, average, color) {
        $(document).ready(function () {	 
			jQuery(function () {
				var barCanvas = document.getElementById("bar-chart");
				var bookedData = {
					label: 'Avg Daily Rate / City',
					data: average,
					backgroundColor: color,
					borderWidth: 2
				};
				var completeData = {
					labels: labels,
					datasets: [bookedData]
				};
				var chartOptions = {
					responsive: true,
					maintainAspectRatio: false,
					title: {
						display: true,
						fontSize: '24',
						text: 'Avg Daily Rate / City'
					},
					scales: {
						xAxes: [
							{
								barPercentage: 0.8,
								categoryPercentage: 0.6
							}
						]
					}
				};
				var barChart = new Chart(barCanvas, {
					type: 'bar',
					data: completeData,
					options: chartOptions
				});
				console.log(barChart)
			});
		})
     }
   }
   
   export default clientPortalController