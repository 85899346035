import React from 'react';
import Footer from './footer';
import Header from './header';
import { Link } from 'react-router-dom';
class CareerOpportunites extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="SAweb_updateUi">
                <Header />
                <div className=""></div>
                <div className="company_section testmonial_gap bg-grey ">
                    <div className="container privacy_policy">
                        <div className="global-coverage-wrap col-md-10 col-md-offset-1 wow fadeInUp" data-wow-delay="0.5s">
                            <h1 className="text-center  large-font mrg-btm-20">Career Opportunities</h1>
                            <div className="col-md-8 col-md-offset-2 text-center">
                                <p className="text-center">Outstanding people are the key to our success!</p>
                                <p className="text-center">Be a part of an award-winning company. Browse our current career opportunities.
                                </p>
                                <Link to="/employment-search" className="btn black-btn mrg-top-60 mrg-btm-60">CAREER OPENINGS</Link>
                            </div>
                            <div className="clearfix"></div>
                            <p className="mrg-btm-40">At SuiteAmerica, our mission is to provide quality corporate housing with an innovative spirit and world-class service, that is a recognized value to those we serve.
                            </p>
                            {/* <p className="mrg-btm-40">If you'd like more information about your EEO rights as an applicant, please <a href="https://www1.eeoc.gov/employers/upload/eeoc_self_print_poster.pdf" target="_blank" className="color-red"><strong style={{ fontWeight: '800',fontSize:'18px'}}>click here</strong></a>.</p> */}
                            <p className="mrg-btm-40">Our offices are located in El Dorado Hills CA, Santa Clara CA, San Diego CA, Lorton VA, Seattle WA, Portland OR
                            </p>
                            <p>SuiteAmerica is an Equal Employment Opportunity and Affirmative Action Employer that is committed to diversity, equity and inclusion. If you’d like more information about your EEO rights as an applicant, please <a href="https://www.eeoc.gov/sites/default/files/2022-10/EEOC_KnowYourRights_screen_reader_10_20.pdf" target="_blank" className="color-red"><strong style={{ fontWeight: '800',fontSize:'18px'}}>click here</strong></a>.
                            </p>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}
export default CareerOpportunites;