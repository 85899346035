var  seelctBoxData = {
    toDropDown : ['','All Inc','General','New Delivery','Rotation Unit','Tour', 'VIP','VIP/New Delivery'],
    reloDropDown: [
        {name:'Alison McGinnis',
        email:'alison@suiteamerica.com'},
        {name:'Amy Keh',
        email:'amy@suiteamerica.com'},
        {name:'Amanda Burri',
        email:'a.burri@suiteamerica.com'},
        {name:'Ashely Soares',
        email:'a.soares@suiteamerica.com'},
        {name:'Brenda Edwards',
        email:'b.edwards@suiteamerica.com'},
        {name:'Briana Deaver',
        email:'Briana@suiteamerica.com'},
        {name:'Brittani Harbin',
        email:'brittani@suiteamerica.com'},
        {name:'Catherine Goddard',
        email:'catherine@suiteamerica.com'},
        {name:'Cory Taylor-Bockman',
        email:'corey@suiteamerica.com'},
        {name:'Dustin Storie',
        email:'dustin@suiteamerica.com'},
        {name:'Jennifer Saylor',
        email:'jennifer@suiteamerica.com'},
        {name:'Joseph Hass',
        email:'joseph@suiteamerica.com'},
        {name:'Jason Corona',
        email:'jason@suiteamerica.com'},
        {name:'Katrina Sanders',
        email:'k.sanders@suiteamerica.com'},
        {name:'Leeann Larsen',
        email:'leeann@suiteamerica.com'},
        {name:'Lenee Lee',
        email:'lenee@suiteamerica.com'},
        {name:'Libby Machado',
        email:'libby@suiteamerica.com'},
        {name:'Lisa Lawhead-Briseno',
        email:'lisa@suiteamerica.com'},
        {name:'Lori Sullivan Abinanti',
        email:'l.sullivan@suiteamerica.com'},
        {name:'Lori Smylie',
        email:'lori@suiteamerica.com'},
        {name:'Michael Beer',
        email:'m.beer@suiteamerica.com'},
        {name:'Michael James',
        email:'michael@suiteamerica.com'},
        {name:'Nathan Kraft',
         email:'nathan@suiteamerica.com'},
        {name:'Olivia Pearsall',
         email:'olivia@suiteamerica.com'},
        {name:'Wade Monroe',
         email:'wade@suiteamerica.com'},
         {name:'Samantha Ellis',
         email:'s.ellis@suiteamerica.com'},
         {name:'Sofia Simeone',
         email:'s.simeone@suiteamerica.com'},
         {name:'Bud Long',
         email:'bud@suiteamerica.com'}   
        ],

         scheduleCopyDropDown: [{name:'Portland',
                                 email:'portland@suiteamerica.com,meetandgreetpnw@suiteamerica.com'},
                                {name:'Seattle',
                                 email:'seattle@suiteamerica.com,meetandgreetpnw@suiteamerica.com'},
                                // {name:'Apple',
                                //  email:'appleschedule@suiteamerica.com'},
                                {name:'Tours',
                                  email:'julie@suiteamerica.com'},
                                {name:'VIP',
                                 email:'julie@suiteamerica.com'},
                                {name:'Austin',
                                 email:'brittney@suiteamerica.com,Justyn@suiteamerica.com,meetandgreettxsocal@suiteamerica.com'},
                                {name:'San Diego',
                                 email:'darcy@suiteamerica.com,ettochi@suiteamerica.com,b.novela@suiteamerica.com,meetandgreettxsocal@suiteamerica.com'},
                                {name:'East Coast',
                                 email:'gs-east@suiteamerica.com'},
                                {name:'Charlotte',
                                 email:'gs-east@suiteamerica.com,j.clontz@suiteamerica.com'}
                                ],

                             sendCopyToDropDown: [{name:'Meet & Greet',
                                                   email:'meetandgreet@suiteamerica.com'},
                                                  {name:'Invoice',
                                                   email:'accounting@suiteamerica.com'},
                                                  {name:'Both',
                                                   email:'meetandgreet@suiteamerica.com,accounting@suiteamerica.com'}
                                                  ],

                             sendToJulieDropDown: ['Yes','No'],

                             HouseWaresDropDown:['Standard','Upgraded','Gilead Package','PG&E Package','Bridgestreet Package','Nomad Package','Primary/Cartus Package'],
}

export default seelctBoxData;