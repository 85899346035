/* Importing the node modules, child components, services and controllers used 
   inside ClientPortal component */
import $ from "jquery";
import moment from 'moment';
import ClientApiService from '../../../services/clientApiService';
import AppController from "../../controller/appController";
import {Link} from 'react-router-dom';
import GuestHeader from '../../common/guestHeader';
import Footer from '../../common/footer';
import EncryptDecrypt from '../../../services/encryptDecrypt';
import TravelBookingApiService from '../../../services/travelBookingApiService';
import approx from "approximate-number";
import ClientPortalController from "../../controller/clientPortalController";

/* ClientPortal Component initialization */
class ClientPortal extends React.Component{
	/* Initializing objects of its ClientPortal class */
	constructor(props) {
		super(props)
		/* ClientPortal Component State variables Initialization */
		this.state = {
			ordersData: [],
			loading: true,
			collapseIn: false,
			tableID: 0,
			selectValue:'Community',
			sortby: false,
			index: -1,
			servicesData: [],
			singleService: {},
			more: 1,
			loadMoreButton: false,
			loadMoreString: true,
			clientInfo:{},
			randNumber: -1,
			isLoading: false,
			loadGraphs: false,
			stayingData: [],
            bookedData: [],
			pastData: [],
			avgLengthofStay: 0,
            totalSpend: 0,
            savings: 0,
			totalordersGraph: [],
			searchValue: '',
			statusType: 'DASHBOARD',
			moveInDate: '',
			moveOutDate: '',
			type: 0,
			servicesOpenCount: 0
		  }
		  this.loadMore = this.loadMore.bind(this);
		  this.sortBy = this.sortBy.bind(this);
		  this.viewSelction=this.viewSelction.bind(this);
		  this.changeStatus = this.changeStatus.bind(this);
		  this.exportExcel = this.exportExcel.bind(this);
		  this.changeSearchValue = this.changeSearchValue.bind(this);
		  this.communitySelection = this.communitySelection.bind(this);
		  this.addressSelection = this.addressSelection.bind(this);
		  this.setValue = this.setValue.bind(this);
	  }

	  /* It is invoked immediately before mounting occurs */
	  async componentWillMount() {
		AppController.removeLocalStorageData();
		$('html, body').animate({scrollTop: 0});
		AppController.stickyTitles();		
		this.OrdersList(moment().startOf('year').format('MM-DD-YYYY'), moment().endOf('year').format('MM-DD-YYYY'), 1, this.state.statusType)
	}
	/* To change calendar data */
	calendar(randNumber){
		const _this = this
		jQuery( function () {
			var start = moment().startOf('year');
			var end = moment().endOf('year');
		
			function cb(starts, ends) {
				jQuery('#reportrange'+randNumber+' span').html(starts.format('MMMM D, YYYY') + ' - ' + ends.format('MMMM D, YYYY'));
			}
			async function myFun(starts, ends){
				jQuery('#reportrange'+randNumber+' span').html(starts.format('MMMM D, YYYY') + ' - ' + ends.format('MMMM D, YYYY'));
				await _this.OrdersList(starts.format('MM-DD-YYYY'), ends.format('MM-DD-YYYY'), -1, _this.state.statusType);
			}
		
			jQuery('#reportrange'+randNumber).daterangepicker({
				startDate: start,
				endDate: end,
				ranges: {
				   'Today': [moment(), moment()],
				   'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
				   'Last 7 Days': [moment().subtract(6, 'days'), moment()],
				   'Last 30 Days': [moment().subtract(29, 'days'), moment()],
				   'This Month': [moment().startOf('month'), moment().endOf('month')],
				   'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
				   'This Year': [moment().startOf('year'), moment().endOf('year')],
				}
			}, myFun);
		
			 cb(start, end);

		})
	}
	/* To get the order list */
	async OrdersList(moveIndate, moveOutdate, type, statusType) {
		if (type == 1) {
			const crypto = window.crypto || window.msCrypto;
			var array = new Uint16Array(1);
			crypto.getRandomValues(array);
			var randNumber = array[0] + 100000;
		} else {
			var randNumber = this.state.randNumber
		}
	await this.setState({isLoading: true, loadGraphs: true,  randNumber, type, moveInDate: moveIndate, moveOutDate: moveOutdate, statusType, ordersData: [], loading: true});
	if (type == 1) {
		await this.calendar(randNumber)
	}


	
	const webGuest = localStorage.getItem('_u_web');
	const userData = await EncryptDecrypt.decryptData({'type': webGuest})
	this.setState({clientInfo:userData.recordset[0]})
    const email =userData.recordset[0].GuestEmail;
	const userEmail={
		ClientEmail:email,
		LoadMore: 0,
		MoveInDate: moveIndate,
		MoveOutDate: moveOutdate,
		StatusCode: statusType == "DASHBOARD" ? 'ACTIVE' : statusType
	} 
	const ordersData= await ClientApiService.getOrdersByClientEmailId(userEmail)
	await this.setState({ordersData: ordersData,loading: false})
	if(statusType == 'DASHBOARD'){
	var clientId= userData.recordset[0].clientid;
	const requestObj = {clientId: clientId, moveindate: moveIndate, moveoutdate: moveOutdate}
	let orderListResult = await TravelBookingApiService.GetOrdersByClient(requestObj) 
	orderListResult = _.orderBy(orderListResult, ['orderid'], ['desc']);
	const stayingData = _.filter(orderListResult, function(o) { return o.statuscode=="ACTIVE"; }); 
	const bookedData = _.filter(orderListResult, function(o) { return (o.statuscode=="PENDING" || o.statuscode=="VOID"); }); 
	const pastData = _.filter(orderListResult, function(o) { return o.statuscode=="TERMED"; });

	const concatRecords = _.concat(this.state.totalordersGraph, stayingData, bookedData, pastData);

	const DataLength = _.sumBy(concatRecords, function (o) {
	   
		return (o!= undefined && o.daysofstay!=undefined && o.daysofstay!= null)? o.daysofstay : 0;
		})
		const DataRate = _.sumBy(concatRecords, function (o) {
	  
			return (o!= undefined && o.daysofstay!=undefined && o.daysofstay!= null)? (o.daysofstay * o.dailyrate) : 0; 
		})

	var groupbycity = _.groupBy(_.orderBy(concatRecords,['city'], ['asc']), 'city');
	var labels = []
	var average = []
	var color = []
	var i = 0;
	var colorVal = [
		'#1fa2ff',
		'#48962a',
		'#F09819',
		'#EB3349',
		'#7f8c8d',
		'#8e44ad',
		'#1fa2ff',
		'#48962a',
		'#F09819',
		'#EB3349',
		'#7f8c8d',
		'#8e44ad',
		'#1fa2ff',
		'#48962a',
		'#F09819',
		'#EB3349',
		'#7f8c8d',
		'#8e44ad'
	]
	_.forEach(groupbycity, function (row, index) {

		const labelindex = index == 'null'
			? 'OTHERS'
			: index
		labels.push(labelindex);
		var sumBy = _.sumBy(row, function (o) {
			return o!= undefined? o.dailyrate : 0;
		})
		average.push((sumBy / row.length).toFixed(2))
		color.push(colorVal[i])
		i++;

	})

	var stayData=stayingData!= undefined? stayingData: []
	var bookData=bookedData!= undefined? bookedData: []
	var historyData=pastData!= undefined? pastData: []
	var avgLengthofStay = ((DataLength)/(concatRecords.length))>0? ((DataLength)/(concatRecords.length)).toFixed(1) : 0

	const activeReqObject={
		ClientEmail:email,
		LoadMore: 0,
		MoveInDate: moveIndate,
		MoveOutDate: moveOutdate,
		StatusCode: 'ACTIVE'
	} 
	const activeData= await ClientApiService.getOrdersByClientEmailId(activeReqObject);
	const activeCount = activeData.length > 0 ? _.sumBy(activeData, function(o) { return o.Servicescount; }) : 0;

	await this.setState({
		stayingData: stayData,
		bookedData: bookData,
		pastData: historyData,
		isLoading: false,
		avgLengthofStay: isNaN(avgLengthofStay)? 0 : approx(avgLengthofStay),
		totalSpend: approx(DataRate, {prefix: '$', capital: true, decimal: '.'}),
		servicesOpenCount: activeCount
	})
	
		await this.loadGraphs(labels, average, colorVal);
		await this.loadLineCharts(moveIndate, moveOutdate, orderListResult);
	}
}
	lineChartCreation(lineCanvas, completeData, labelName) {
		var chartOptions = {
			responsive: true,
			maintainAspectRatio: false,
			title: {
				display: true,
				fontSize: '24',
				text: labelName
			}
		};
		console.log(chartOptions)
		var lineChart = new Chart(lineCanvas, {
			type: 'line',
			data: completeData,
			options: chartOptions
		});
		console.log(lineChart)
	}
	async generateLineChart(completeMoveInOutfinalData, xAxisLabels, moveInOutChart, moveInOutLabel, moveInOutColor) {
		const _this = this;
		$(document).ready(function () {	 
			jQuery(function () {
			var moveInCanvas = document.getElementById(moveInOutChart);
			var completeMoveInOutData = {
				labels: xAxisLabels,
				datasets: [{ 
					data: completeMoveInOutfinalData,
					label: moveInOutLabel,
					borderColor: moveInOutColor,
					fill: false
				}
			  ]
			};
			_this.lineChartCreation(moveInCanvas, completeMoveInOutData, moveInOutLabel);
		});
	  });
	}
	convertToArray(data) {
		let monthArray = [];
		let months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
		_.forEach(months, function(value) {
			monthArray.push(data[value] == undefined ? 0 : data[value].length);
		});
		return monthArray;
	}
	async loadLineCharts(moveIndate, moveOutdate, orderListResult) {
		var inDate = moment(moveIndate, 'MM-DD-YYYY');
		var outDate = moment(moveOutdate, 'MM-DD-YYYY');
		const startDate = moment([
            Number(moment(inDate).format('YYYY')),
            Number(moment(inDate).format('M')) - 1,
            Number(moment(inDate).format('D'))
        ]);
         const endDate = moment([
            Number(moment(outDate).format('YYYY')),
            Number(moment(outDate).format('M')) - 1,
            Number(moment(outDate).format('D'))
        ]);
		var diffDate = endDate.diff(startDate, 'days') + 1;
		var moveInChart = "movein-chart";
		var moveOutChart = "moveout-chart";
		var moveInLabel = "No.Of Move In's";
		var moveOutLabel = "No.Of Move Out's";
		var moveInColor = "#3e95cd";
		var moveOutColor = "#FF001E";
		if(diffDate == 365 || diffDate == 366) {
				var groupedByMoveInData = _.groupBy(orderListResult, function(item) {
					return item.moveindate.substring(5, 7); 
				});
				var groupedByMoveOutData = _.groupBy(orderListResult, function(item) {
					return item.moveoutdate.substring(5, 7); 
				});
				var xAxisLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
				var completeMoveInfinalData = this.convertToArray(groupedByMoveInData);
				var completeMoveOutfinalData = this.convertToArray(groupedByMoveOutData);				
				await this.generateLineChart(completeMoveInfinalData, xAxisLabels, moveInChart, moveInLabel, moveInColor);	
				await this.generateLineChart(completeMoveOutfinalData, xAxisLabels, moveOutChart, moveOutLabel, moveOutColor);
		} else if(diffDate < 365) {
			var MoveInDaysData = _.groupBy(orderListResult, function(item) {
				return item.moveindate;
			});
			var MoveOutDaysData = _.groupBy(orderListResult, function(item) {
				return item.moveoutdate;
			});
			var MoveInDaysLabels = [];
			var MoveOutDaysLabels = []; 
			var MoveInDaysCompleteData = [];
			var MoveOutDaysCompleteData = [];
			for (var prop in MoveInDaysData) {
				if (MoveInDaysData.hasOwnProperty(prop)) {
					MoveInDaysLabels.push((moment(prop).format('MM-DD-YYYY')));
					MoveInDaysCompleteData.push(MoveInDaysData[prop].length)
				}
			}
			for (var propss in MoveOutDaysData) {
				if (MoveOutDaysData.hasOwnProperty(propss)) {
					MoveOutDaysLabels.push((moment(propss).format('MM-DD-YYYY')));
					MoveOutDaysCompleteData.push(MoveOutDaysData[propss].length)
				}
			}
			await this.generateLineChart(MoveInDaysCompleteData, MoveInDaysLabels, moveInChart, moveInLabel, moveInColor);		
			await this.generateLineChart(MoveOutDaysCompleteData, MoveOutDaysLabels, moveOutChart, moveOutLabel, moveOutColor);
		} else {
			var MoveInYearsData = _.groupBy(orderListResult, function(item) {
				return item.moveindate.substring(0, 4);
			});
			var MoveOutYearsData = _.groupBy(orderListResult, function(item) {
				return item.moveoutdate.substring(0, 4);
			});
			var MoveInYearsLabels = [];
			var MoveOutYearsLabels = []; 
			var MoveInYearsCompleteData = [];
			var MoveOutYearsCompleteData = [];
			for (var propf in MoveInYearsData) {
				if (MoveInYearsData.hasOwnProperty(propf)) {
					MoveInYearsLabels.push(propf);
					MoveInYearsCompleteData.push(MoveInYearsData[propf].length)
				}
			}
			for (var propt in MoveOutYearsData) {
				if (MoveOutYearsData.hasOwnProperty(propt)) {
					MoveOutYearsLabels.push(propt);
					MoveOutYearsCompleteData.push(MoveOutYearsData[propt].length)
				}
			}
			await this.generateLineChart(MoveInYearsCompleteData, MoveInYearsLabels, moveInChart, moveInLabel, moveInColor);		
			await this.generateLineChart(MoveOutYearsCompleteData, MoveOutYearsLabels, moveOutChart, moveOutLabel, moveOutColor);
		}
	}
	/* To load the graphs */
	loadGraphs(labels, average, color) {	
		this.setState({loadGraphs: false});
		ClientPortalController.barChartGeneration(labels, average, color);
	}
	/* To change the status type */
	changeStatus(statusType) {
		this.setState({statusType: statusType, searchValue: '', index: -1});
		this.OrdersList(this.state.moveInDate, this.state.moveOutDate, this.state.type, statusType);
	}
	/* Search field value updattion */
	changeSearchValue(field, e) {
		var object = {};
		object[field] = e.target.value;
		this.setState(object);	
	}
	/* To change the input field values */
	async setValue(field, e) {
		  var object = {};
		  object[field] = e.target.value;
		  this.setState(object);
		  if(field=='selectValue'){
			const serviceRequest = _.find(this.state.servicesData, function (o) 
			{
				return o.UniqueId ==e.target.value;
			  })
			  this.setState({singleService:serviceRequest})
		   }
		} 
	/* To view the selected list*/	
	async viewSelction(row, index){
		if(!this.state.collapseIn || this.state.tableID !=row.orderno){
		this.setState({'index': index});
			$('.panel-collapse.collapse').removeClass("in")
			$('.panel-title a').addClass('collapsed')
			this.setState({ collapseIn: !this.state.collapseIn,tableID:row.orderno})
			const orderDetails={
				orderUId:row.UniqueId,
			}
			const servicesData= await ClientApiService.getServicesByOrderUId(orderDetails)
			await this.setState({servicesData:servicesData,selectValue:'Community'})
		}
	else{
		this.setState({'index': -1});
		this.setState({ collapseIn: !this.state.collapseIn})
	}
		
	}
	/* To sort the list by ascending or descending */
		async sortBy(name){
		this.setState({sortby: !this.state.sortby, sortId:!this.state.sortby});
		var sortType = ''
		if(this.state.sortby){
			sortType = 'desc'
		} else{
			sortType = 'asc'
		}
		const sortData=_.orderBy(this.state.ordersData, [name],[sortType]);
		await this.setState({ordersData:sortData})
		}

		async loadMore(){
			await this.setState({loadMoreButton: true});
			const userEmail={
				ClientEmail:this.state.clientInfo.GuestEmail,
				LoadMore: this.state.more+10,
				MoveInDate: this.state.moveInDate,
				MoveOutDate: this.state.moveOutDate,
				StatusCode: this.state.statusType
			} 
			const ordersData= await ClientApiService.getOrdersByClientEmailId(userEmail)
			await this.setState({more: this.state.more+1, ordersData: _.concat(this.state.ordersData,ordersData),loading: false,loadMoreButton:false,loadMoreString: ordersData.length>=10? true: false})
		}
		newWindowTargetOpen(href) {
			var link = document.createElement('a');
			link.href = href;
			link.target = '_blank';
			link.dispatchEvent(new MouseEvent('click'));
		}	
		addressSelection(row) {
			const href = 'https://www.google.com/maps/place/' + row.address +','+row.statename +','+row.city_name;
			this.newWindowTargetOpen(href);
		}	
		communitySelection(row) {
			const href = '/community/suiteamerica'+ '/'+ row.community_name.replace(/ /g, "-");
			this.newWindowTargetOpen(href);			
		}
		/* Download Excel sheet */
		exportExcel(title, row) {
			ClientPortalController.exportToExcel(title, row);
		}
/* It is invoked to return html content */
render(){
	const ordersData = this.state.ordersData;
	const searchValue = this.state.searchValue;
	const filterData = ordersData.filter(order => {
		return searchValue != '' ? ((order.GuestFirstName != null && order.GuestFirstName.toLowerCase().includes(searchValue.toLowerCase())) || (order.orderno != null && order.orderno.toString().includes(searchValue)) || (order.community_name != null && order.community_name.toLowerCase().includes(searchValue.toLowerCase()) ) || (order.address != null && order.address.toLowerCase().includes(searchValue.toLowerCase())) || (order.city_name != null && order.city_name.toLowerCase().includes(searchValue.toLowerCase())) || (order.statename != null && order.statename.toLowerCase().includes(searchValue.toLowerCase())) || (order.ZipCode != null && order.ZipCode.toLowerCase().includes(searchValue.toLowerCase()))):order;
	})
    return(
		<div>
			<GuestHeader/>
			<div className="client_portal">
				<div className="container">
					<div className="client-cover-tophd-ht"></div>
					<div className="row mrg-btm-50">
						<div className="col-md-6 col-sm-6">		
						<h4 className="color-red" style={{textTransform:"uppercase"}}>{this.state.clientInfo.GuestEmail} - ALL ACCOUNTS</h4>			
						</div>
						<div className="col-md-6 col-sm-6">
						<div id={"reportrange"+this.state.randNumber} className="pull-right" style={{background: "#fff", cursor: "pointer", padding: "5px 10px", border: "1px solid #ccc"}}>
						<i className="fa fa-calendar"></i>&nbsp;
						<span></span> <i className="fa fa-caret-down"></i>
						</div>
					</div>
				</div>
				<div className="community-tabs mrg-top-50">
					<ul className="nav nav-tabs" role="tablist">
						<li role="presentation" className="active">
						<a href="#one" aria-controls="home" role="tab" data-toggle="tab" onClick={() => this.changeStatus('DASHBOARD')}>Dashboard</a>
						</li>
						<li role="presentation">
						<a href="#three" aria-controls="profile" role="tab" data-toggle="tab" onClick={() => this.changeStatus('PENDING')}>Pending</a>
						</li>
						<li role="presentation">
						<a href="#two" aria-controls="profile" role="tab" data-toggle="tab" onClick={() => this.changeStatus('ACTIVE')}>Active</a>
						</li>
						<li role="presentation">
						<a href="#four" aria-controls="profile" role="tab" data-toggle="tab" onClick={() => this.changeStatus('TERMED')}>Termed</a>
						</li>
					</ul>
					<div className="tab-content travel-booking-slider">
						<div role="tabpanel" className="tab-pane active" id="one">
						<div className="clearfix"></div>
						<div className="row mrg-btm-30">
							<div className="col-md-2 col-sm-2">
								<div className="widget-wrap bg-widget-blue">
									<h3>Reservations#</h3>
									<h1>{this.state.isLoading
									? <i className="fa fa-spinner fa-spin"/>
									: this.state.bookedData.length}
									</h1>
								</div>
							</div>
							<div className="col-md-2 col-sm-2">
								<div className="widget-wrap bg-widget-green">
									<h3>Active#</h3>
									<h1>{this.state.isLoading
									? <i className="fa fa-spinner fa-spin"/>
									: this.state.stayingData.length}
									</h1>
								</div>
							</div>
							<div className="col-md-3 col-sm-3">
								<div className="widget-wrap bg-widget-gray">
									<h3>Avg. Stay Length</h3>
									<h1>{this.state.isLoading
									? <i className="fa fa-spinner fa-spin"/>
									: this.state.avgLengthofStay}
									</h1>
								</div>
							</div>
							<div className="col-md-3 col-sm-3">
								<div className="widget-wrap bg-widget-yellow2">
									<h3>Total Spend</h3>
									<h1>{this.state.isLoading
									? <i className="fa fa-spinner fa-spin"/>
									: this
									.state
									.totalSpend
									.toLocaleString('en-US', {
									style: 'currency',
									currency: 'USD'
									})}
									</h1>
								</div>
							</div>
							<div className="col-md-2 col-sm-2">
								<div className="widget-wrap bg-widget-red">
									<h3>Open Services</h3>
									<h1>{this.state.isLoading
									? <i className="fa fa-spinner fa-spin"/>
									: this.state.servicesOpenCount}
									</h1>
								</div>
							</div>

						</div>
						<div className="row">
							{this.state.loadGraphs
							? <div
							className='text-center'
							style={{
							fontSize: '20px'
							}}><i className="fa fa-spinner fa-spin"/>
							Please wait ...!
						</div>
						: 
						<div>
							<div className="col-md-6 col-sm-12 mrg-btm-50">
								<canvas id="bar-chart" height="400"></canvas>
							</div>
							<div className="col-md-6 col-sm-12 mrg-btm-50">
								<canvas id="movein-chart" height="400"></canvas>
							</div>
							<div className="col-md-6 col-sm-12 mrg-btm-50">
								<canvas id="moveout-chart" height="400"></canvas>
							</div>
							
						</div>
						}
						<div className="clearfix"></div>
						</div>
					</div>
					<div role="tabpanel" className="tab-pane " id="two">
						<div className="clearfix"></div>
						
						<div className="row">
							<div className="pull-right">
							<div className="form-group mrg-right-15">
								
								<input className="form-control pull-right" type="text" placeholder="Search" value={this.state.searchValue} 
								onChange={(e) => this.changeSearchValue('searchValue', e)}/>
							</div>
							</div>
							{filterData.length > 0 ? <button className="btn btn-primary pull-right mrg-right-10" 
							onClick={() => this.exportExcel('Active', filterData)}>Export To Excel</button>:null}
						</div>
						<div className="clearfix"></div>
			<div className="table-responsive">
			{this.state.loading? 
					<div className="panel-body_loader mrg-top-30"> <span style={{fontSize: '20px', width: '100%', margin: 'auto', textAlign: 'center'}}><i className="fa fa-spinner fa-spin"/> please wait ...!</span>
					</div>: <table className="table fold-table" >
					<thead>
						<tr>
						<th  onClick={()=> this.sortBy('orderno',1)} style={{cursor:'pointer'}}>Order#</th>
						<th  onClick={()=> this.sortBy('GuestFirstName')} style={{cursor:'pointer'}}> Resident</th>
						<th width="20%" onClick={()=> this.sortBy('community_name')} style={{cursor:'pointer'}}> Community</th>
						<th width="20%" onClick={()=> this.sortBy('address')} style={{cursor:'pointer'}}> Address</th>
						<th  onClick={()=> this.sortBy('MoveInDate')} style={{cursor:'pointer'}}>  Move In Date </th>
						<th  onClick={()=> this.sortBy('MoveOutDate')} style={{cursor:'pointer'}}>  Move Out Date </th>
						<th  onClick={()=> this.sortBy('Servicescount')} style={{cursor:'pointer'}}>  Services Open </th>
						<th  onClick={()=> this.sortBy('DailyRent')} style={{cursor:'pointer'}}>DRate</th>
						</tr>				
					</thead>
					{ filterData.length == 0 && !this.state.loading? 
					<tbody className=" table fold-table collapsed"  >
						<tr className= "view">
						<td className="text-center" colSpan="8" style={{color:'red'}}>No Records Found</td>
						</tr>
					</tbody>
					:filterData.map((row, index) => {
					const startDate = moment([Number(moment(row.MoveInDate).utc().format('YYYY')),Number(moment(row.MoveInDate).utc().format('M'))-1,Number(moment(row.MoveInDate).utc().format('D'))]);
					const endDate =  moment([Number(moment(row.MoveOutDate).utc().format('YYYY')),Number(moment(row.MoveOutDate).utc().format('M'))-1,Number(moment(row.MoveOutDate).utc().format('D'))]);
					const diffDate = endDate.diff(startDate, 'days');
					return (
					<tbody className=" table fold-table collapsed" key={index} >
						<tr  data-toggle="collapse"
						data-parent="#accordion"
						href={'#expand' + row.orderno} className={this.state.index == index ? "view open" : "view"} style={{background: row.Vip != null ? '#dddddd' : '#ffffff'}}>
						<td onClick={()=>this.viewSelction(row, index)}>{row.orderno}</td>
						<td onClick={()=>this.viewSelction(row, index)}>{row.GuestFirstName}</td>
						<td className="community-hover" 
						onClick={() => this.communitySelection(row)}>{row.community_name}</td>
						<td className="community-hover" 
						onClick={() => this.addressSelection(row)}>{(row.address == null ?  '---' : row.address) + ', ' + (row.city_name == null ? '---' : row.city_name) + ', ' + (row.statename == null ? '---' : row.statename) + ', ' + (row.ZipCode == null ? '---' : row.ZipCode)}</td>
						<td onClick={()=>this.viewSelction(row, index)}>{row.MoveInDate !=null ? AppController.utcDate(row.MoveInDate) :'---'}  </td>
						<td onClick={()=>this.viewSelction(row, index)}>{row.MoveOutDate !=null ? AppController.utcDate(row.MoveOutDate):'---'} </td>
						<td onClick={()=>this.viewSelction(row, index)}>{row.Servicescount}</td>
						<td onClick={()=>this.viewSelction(row, index)}>{row.DailyRent}</td>
						</tr>
						<tr  id={'expand' + row.orderno}
						className={this.state.index == index ? "panel-collapse collapse fold open" : "panel-collapse collapse fold"}>
						<td colSpan="8">
						<div className="fold-content">
							<h1>RESERVATION INFO</h1>
							<div className="row">
								<div className="col-md-6">
									<div className="row">
									<div className="form-group">
										<label className="col-md-6">Email</label>
										<div className="col-md-6">
											<label className="input_label">
												<Link to="#">
												{row.GuestEmail}</Link>
											</label>
										</div>
									</div>
									<div className="form-group">
										<label className="col-md-6">Phone</label>
										<div className="col-md-6">
											<label className="input_label">{row.GuestPhoneNo}</label>
										</div>
									</div>
									<div className="form-group">
										<label className="col-md-6">Size</label>
										<div className="col-md-6">
											<label className="input_label">{row.SuiteSizeCode}</label>
										</div>
									</div>
									<div className="form-group">
										<label className="col-md-6">Daily Rate</label>
										<div className="col-md-6">
											<label className="input_label">{row.DailyRent}</label>
										</div>
									</div>
									<div className="form-group">
										<label className="col-md-6">Pets</label>
										<div className="col-md-6">
											<label className="input_label">{row.petscount}</label>
										</div>
									</div>
									</div>
								</div>
								<div className="col-md-6">
									<div className="row">
									<div className="form-group">
										<label className="col-md-6">Check In Time</label>
										<div className="col-md-6">
											<label className="input_label">{row.CheckInTime}</label>
										</div>
									</div>
									<div className="form-group">
										<label className="col-md-6">Check Out Time</label>
										<div className="col-md-6">
											<label className="input_label">11:59 pm</label>
										</div>
									</div>
									<div className="form-group">
										<label className="col-md-6">Length of Stay</label>
										<div className="col-md-6">
											<label className="input_label">	{diffDate >=0 ? diffDate+1:'invalid date'}</label>
										</div>
									</div>
									<div className="form-group">
										<label className="col-md-6">Account #</label>
										<div className="col-md-6">
											<label className="input_label">{row.accountId}</label>
										</div>
									</div>
									<div className="form-group">
										<label className="col-md-6">PO #</label>
										<div className="col-md-6">
											<label className="input_label">{row.PurchaseOrder}</label>
										</div>
									</div>
									</div>
								</div>
							</div>
							<select  value={this.state.selectValue} 
							onChange={(e) => this.setValue('selectValue', e)}>
							<option value="Community" >Community Info</option>
							{this.state.servicesData.map((row, index) => {
							return (
							<option value={row.UniqueId} key={index}>Service Request {index +1}</option>
							)
							})}
							</select>
							{this.state.selectValue=='Community' ? 
							<div>
								<h1>Community Info</h1>
								<div className="row">
									<div className="col-md-6">
									<div className="row">
										<div className="form-group">
											<label className="col-md-6">Floor</label>
											<div className="col-md-6">
												<label className="input_label">{(row.Floor == null || row.Floor == '') ? "---" : row.Floor}</label>
											</div>
										</div>
										<div className="form-group">
											<label className="col-md-6">Suite Phone</label>
											<div className="col-md-6">
												<label className="input_label">{(row.SuitePhone == null || row.SuitePhone == '') ? "---" : row.SuitePhone}</label>
											</div>
										</div>
										<div className="form-group">
											<label className="col-md-6">Mailbox</label>
											<div className="col-md-6">
												<label className="input_label">{(row.MailBox == null || row.MailBox == '') ? "---" : row.MailBox}</label>
											</div>
										</div>
										<div className="form-group">
											<label className="col-md-6">Parking Space</label>
											<div className="col-md-6">
												<label className="input_label">					
												{(row.ParkingSpace1 == null || row.ParkingSpace1 == '') ? "---" : row.ParkingSpace1}
												</label>
											</div>
										</div>
										<div className="form-group">
											<label className="col-md-6">Square Feet</label>
											<div className="col-md-6">
												<label className="input_label">{(row.SquareFootage == null || row.SquareFootage == '') ? "---" : row.SquareFootage}</label>
											</div>
										</div>
									</div>
									</div>
									<div className="col-md-6">
									<div className="row">
										<div className="form-group">
											<label className="col-md-6">Suite Address</label>
											<div className="col-md-6 td_foldaddress">
												<p>{row.address} </p>
												<p>{row.statename}</p>
												<p>{row.city_name + ', ' + row.ZipCode}</p>
												<label className="input_label">
												<a href={'https://www.google.com/maps/place/' + row.address +','+row.statename +','+row.city_name} target="_blank">Get Directions</a>
												</label>
											</div>
										</div>
									</div>
									</div>
								</div>
							</div>
							:
							<div>
								<h1>SERVICE REQUEST </h1>
								<div className="row">
									<div className="form-group">
									<label className="col-md-3">Date/Time</label>
									<div className="col-md-9">
										<label className="input_label">{moment(this.state.singleService.RequestDate).format("MM/DD/YYYY | h:mm a")}</label>
									</div>
									</div>
									<div className="form-group">
									<label className="col-md-3">Request</label>
									<div className="col-md-9">
										<p>
											{this.state.singleService.Requested !=null ? this.state.singleService.Requested:'---'}	
										</p>
									</div>
									</div>
									<div className="form-group">
									<label className="col-md-3">Status </label>
									<div className="col-md-9">
										<label className="input_label">{this.state.singleService.FivestarStatus !=null? this.state.singleService.FivestarStatus :'---'}</label>
									</div>
									</div>
									<div className="form-group">
									<label className="col-md-3">Date/Time </label>
									<div className="col-md-9">
										{this.state.singleService.FivestarStartDateTime ==null || this.state.singleService.FivestarStopDateTime ==null ?<label className="input_label">{ this.state.singleService.FivestarStartDateTime != null  ?  moment(this.state.singleService.FivestarStartDateTime).format("MM/DD/YYYY | h:mm a"):'---'}</label>
										:<label className="input_label">{ this.state.singleService.FivestarStopDateTime != null  ?  moment(this.state.singleService.FivestarStopDateTime).format("MM/DD/YYYY | h:mm a"):'---'}</label>}
									</div>
									</div>
									<div className="form-group">
									<label className="col-md-3">Description</label>
									<div className="col-md-9">
										<label className="input_label">{this.state.singleService.Description !=null? this.state.singleService.Description:'---'}</label>
									</div>
									</div>
								</div>
							</div>
							} 
						</div>
						</td>
						</tr>
					</tbody>
					)
					}) }
				</table>}
				{this.state.loadMoreButton? 
				<div className="panel-body_loader loadmore" > <span style={{fontSize: '50px', width: '100%', margin: 'auto', textAlign: 'center'}}><i className="fa fa-spinner fa-spin"/> please wait ...!</span>
				</div>
				: '' }
				{(! this.state.loadMoreButton && filterData.length >=10 && this.state.loadMoreString) ?
				<div className="loadmore"> <a  onClick={()=>this.loadMore()}>Load More <i className="fa fa-plus"></i></a> </div>
				:null}
			</div>
					</div>
					<div role="tabpanel" className="tab-pane" id="three">
						<div className="clearfix"></div>						
						<div className="row">
							<div className="pull-right">
							<div className="form-group mrg-right-15">
								
								<input className="form-control pull-right" type="text" placeholder="Search" value={this.state.searchValue} 
								onChange={(e) => this.changeSearchValue('searchValue', e)}/>
							</div>
							</div>
							{filterData.length > 0 ? <button className="btn btn-primary pull-right mrg-right-10" 
							onClick={() => this.exportExcel('Pending', filterData)}>Export To Excel</button>:null}
						</div>
						<div className="clearfix"></div>
			<div className="table-responsive">
			{this.state.loading ? 
					<div className="panel-body_loader mrg-top-30"> <span style={{fontSize: '20px', width: '100%', margin: 'auto', textAlign: 'center'}}><i className="fa fa-spinner fa-spin"/> please wait ...!</span>
					</div> : <table className="table fold-table" >
					
					
					<thead>
						<tr>
						<th  onClick={()=> this.sortBy('orderno',1)} style={{cursor:'pointer'}}>Order#</th>
						<th  onClick={()=> this.sortBy('GuestFirstName')} style={{cursor:'pointer'}}> Resident</th>
						<th width="20%" onClick={()=> this.sortBy('community_name')} style={{cursor:'pointer'}}> Community</th>
						<th width="20%" onClick={()=> this.sortBy('address')} style={{cursor:'pointer'}}> Address</th>
						<th  onClick={()=> this.sortBy('MoveInDate')} style={{cursor:'pointer'}}>  Move In Date </th>
						<th  onClick={()=> this.sortBy('MoveOutDate')} style={{cursor:'pointer'}}>  Move Out Date </th>
						<th  onClick={()=> this.sortBy('DailyRent')} style={{cursor:'pointer'}}>DRate</th>
						</tr>
					</thead>
					{ filterData.length == 0 && !this.state.loading? 
					<tbody className=" table fold-table collapsed"  >
						<tr className= "view">
						<td className="text-center" colSpan="7" style={{color:'red'}}>No Records Found</td>
						</tr>
					</tbody>
					:filterData.map((row, index) => {
					const startDate = moment([Number(moment(row.MoveInDate).utc().format('YYYY')),Number(moment(row.MoveInDate).utc().format('M'))-1,Number(moment(row.MoveInDate).utc().format('D'))]);
					const endDate =  moment([Number(moment(row.MoveOutDate).utc().format('YYYY')),Number(moment(row.MoveOutDate).utc().format('M'))-1,Number(moment(row.MoveOutDate).utc().format('D'))]);
					const diffDate = endDate.diff(startDate, 'days');
					return (
					<tbody className=" table fold-table collapsed" key={index} >
						<tr  data-toggle="collapse"
						data-parent="#accordion"
						href={'#expand' + row.orderno} className={this.state.index == index ? "view open" : "view"} style={{background: row.Vip != null ? '#dddddd' : '#ffffff'}}>
						<td onClick={()=>this.viewSelction(row, index)}>{row.orderno}</td>
						<td onClick={()=>this.viewSelction(row, index)}>{row.GuestFirstName}</td>
						<td className="community-hover" 
						onClick={() => this.communitySelection(row)}>{row.community_name}</td>
						<td className="community-hover" 
						onClick={() => this.addressSelection(row)}>{(row.address == null ?  '---' : row.address) + ', ' + (row.city_name == null ? '---' : row.city_name) + ', ' + (row.statename == null ? '---' : row.statename) + ', ' + (row.ZipCode == null ? '---' : row.ZipCode)}</td>
						<td onClick={()=>this.viewSelction(row, index)}>{row.MoveInDate !=null ? AppController.utcDate(row.MoveInDate) :'---'}  </td>
						<td onClick={()=>this.viewSelction(row, index)}>{row.MoveOutDate !=null ? AppController.utcDate(row.MoveOutDate):'---'} </td>
						<td onClick={()=>this.viewSelction(row, index)}>{row.DailyRent}</td>
						</tr>
						<tr  id={'expand' + row.orderno}
						className={this.state.index == index ? "panel-collapse collapse fold open" : "panel-collapse collapse fold"}>
						<td colSpan="7">
						<div className="fold-content">
							<h1>RESERVATION INFO</h1>
							<div className="row">
								<div className="col-md-6">
									<div className="row">
									<div className="form-group">
										<label className="col-md-6">Email</label>
										<div className="col-md-6">
											<label className="input_label">
												<Link to="#">
												{row.GuestEmail}</Link>
											</label>
										</div>
									</div>
									<div className="form-group">
										<label className="col-md-6">Phone</label>
										<div className="col-md-6">
											<label className="input_label">{row.GuestPhoneNo}</label>
										</div>
									</div>
									<div className="form-group">
										<label className="col-md-6">Size</label>
										<div className="col-md-6">
											<label className="input_label">{row.SuiteSizeCode}</label>
										</div>
									</div>
									<div className="form-group">
										<label className="col-md-6">Daily Rate</label>
										<div className="col-md-6">
											<label className="input_label">{row.DailyRent}</label>
										</div>
									</div>
									<div className="form-group">
										<label className="col-md-6">Pets</label>
										<div className="col-md-6">
											<label className="input_label">{row.petscount}</label>
										</div>
									</div>
									</div>
								</div>
								<div className="col-md-6">
									<div className="row">
									<div className="form-group">
										<label className="col-md-6">Check In Time</label>
										<div className="col-md-6">
											<label className="input_label">{row.CheckInTime}</label>
										</div>
									</div>
									<div className="form-group">
										<label className="col-md-6">Check Out Time</label>
										<div className="col-md-6">
											<label className="input_label">11:59 pm</label>
										</div>
									</div>
									<div className="form-group">
										<label className="col-md-6">Length of Stay</label>
										<div className="col-md-6">
											<label className="input_label">	{diffDate >=0 ? diffDate+1:'invalid date'}</label>
										</div>
									</div>
									<div className="form-group">
										<label className="col-md-6">Account #</label>
										<div className="col-md-6">
											<label className="input_label">{row.accountId}</label>
										</div>
									</div>
									<div className="form-group">
										<label className="col-md-6">PO #</label>
										<div className="col-md-6">
											<label className="input_label">{row.PurchaseOrder}</label>
										</div>
									</div>
									</div>
								</div>
							</div>
							<div>
								<h1>Community Info</h1>
								<div className="row">
									<div className="col-md-6">
									<div className="row">
										<div className="form-group">
											<label className="col-md-6">Floor</label>
											<div className="col-md-6">
												<label className="input_label">{(row.Floor == null || row.Floor == '') ? "---" : row.Floor}</label>
											</div>
										</div>
										<div className="form-group">
											<label className="col-md-6">Suite Phone</label>
											<div className="col-md-6">
												<label className="input_label">{(row.SuitePhone == null || row.SuitePhone == '') ? "---" : row.SuitePhone}</label>
											</div>
										</div>
										<div className="form-group">
											<label className="col-md-6">Mailbox</label>
											<div className="col-md-6">
												<label className="input_label">{(row.MailBox == null || row.MailBox == '') ? "---" : row.MailBox}</label>
											</div>
										</div>
										<div className="form-group">
											<label className="col-md-6">Parking Space</label>
											<div className="col-md-6">
												<label className="input_label">
												{(row.ParkingSpace1 == null || row.ParkingSpace1 == '') ? "---" : row.ParkingSpace1}
												</label>
											</div>
										</div>
										<div className="form-group">
											<label className="col-md-6">Square Feet</label>
											<div className="col-md-6">
												<label className="input_label">{(row.SquareFootage == null || row.SquareFootage == '') ? "---" : row.SquareFootage}</label>
											</div>
										</div>
									</div>
									</div>
									<div className="col-md-6">
									<div className="row">
										<div className="form-group">
											<label className="col-md-6">Suite Address</label>
											<div className="col-md-6 td_foldaddress">
												<p>{row.address} </p>
												<p>{row.statename}</p>
												<p>{row.city_name + ', ' + row.ZipCode}</p>
												<label className="input_label">
												<a href={'https://www.google.com/maps/place/' + row.address +','+row.statename +','+row.city_name} target="_blank">Get Directions</a>
												</label>
											</div>
										</div>
									</div>
									</div>
								</div>
							</div>						
							
						</div>
						</td>
						</tr>
					</tbody>
					)
					}) }
				</table>}
				{this.state.loadMoreButton? 
				<div className="panel-body_loader loadmore" > <span style={{fontSize: '50px', width: '100%', margin: 'auto', textAlign: 'center'}}><i className="fa fa-spinner fa-spin"/> please wait ...!</span>
				</div>
				: '' }
				{(! this.state.loadMoreButton && filterData.length >=10 && this.state.loadMoreString) ?
				<div className="loadmore"> <a  onClick={()=>this.loadMore()}>Load More <i className="fa fa-plus"></i></a> </div>
				:null}
			</div>
					</div>
					<div role="tabpanel" className="tab-pane" id="four">
						<div className="clearfix"></div>
						<div className="row">
							<div className="pull-right">
							<div className="form-group mrg-right-15">
								
								<input className="form-control pull-right" type="text" placeholder="Search" value={this.state.searchValue} 
								onChange={(e) => this.changeSearchValue('searchValue', e)}/>
							</div>
							</div>
								{filterData.length > 0 ? <button className="btn btn-primary pull-right mrg-right-10" 
								onClick={() => this.exportExcel('Termed', filterData)}>Export To Excel</button>: null}
						</div>
			<div className="table-responsive">
			{this.state.loading? 
					<div className="panel-body_loader mrg-top-30"> <span style={{fontSize: '20px', width: '100%', margin: 'auto', textAlign: 'center'}}><i className="fa fa-spinner fa-spin"/> please wait ...!</span>
					</div>
					:<table className="table fold-table" >
					
					<thead>
						<tr>
						<th  onClick={()=> this.sortBy('orderno',1)} style={{cursor:'pointer'}}>Order#</th>
						<th  onClick={()=> this.sortBy('GuestFirstName')} style={{cursor:'pointer'}}> Resident</th>
						<th width="20%" onClick={()=> this.sortBy('community_name')} style={{cursor:'pointer'}}> Community</th>
						<th width="20%" onClick={()=> this.sortBy('address')} style={{cursor:'pointer'}}> Address</th>
						<th  onClick={()=> this.sortBy('MoveInDate')} style={{cursor:'pointer'}}>  Move In Date </th>
						<th  onClick={()=> this.sortBy('MoveOutDate')} style={{cursor:'pointer'}}>  Move Out Date </th>
						<th  onClick={()=> this.sortBy('DailyRent')} style={{cursor:'pointer'}}>DRate</th>
						</tr>
					</thead>
					{ filterData.length == 0 && !this.state.loading? 
					<tbody className=" table fold-table collapsed"  >
						<tr className= "view">
						<td className="text-center" colSpan="7" style={{color:'red'}}>No Records Found</td>
						</tr>
					</tbody>
					:filterData.map((row, index) => {
					const startDate = moment([Number(moment(row.MoveInDate).utc().format('YYYY')),Number(moment(row.MoveInDate).utc().format('M'))-1,Number(moment(row.MoveInDate).utc().format('D'))]);
					const endDate =  moment([Number(moment(row.MoveOutDate).utc().format('YYYY')),Number(moment(row.MoveOutDate).utc().format('M'))-1,Number(moment(row.MoveOutDate).utc().format('D'))]);
					const diffDate = endDate.diff(startDate, 'days');
					return (
					<tbody className=" table fold-table collapsed" key={index} >
						<tr  data-toggle="collapse"
						data-parent="#accordion"
						href={'#expand' + row.orderno} className={this.state.index == index ? "view open" : "view"} style={{background: row.Vip != null ? '#dddddd' : '#ffffff'}}>
						<td onClick={()=>this.viewSelction(row, index)}>{row.orderno}</td>
						<td onClick={()=>this.viewSelction(row, index)}>{row.GuestFirstName}</td>
						<td className="community-hover" 
						onClick={() => this.communitySelection(row)}>{row.community_name}</td>
						<td className="community-hover" 
						onClick={() => this.addressSelection(row)}>{(row.address == null ?  '---' : row.address) + ', ' + (row.city_name == null ? '---' : row.city_name) + ', ' + (row.statename == null ? '---' : row.statename) + ', ' + (row.ZipCode == null ? '---' : row.ZipCode)}</td>
						<td onClick={()=>this.viewSelction(row, index)}>{row.MoveInDate !=null ? AppController.utcDate(row.MoveInDate) :'---'}  </td>
						<td onClick={()=>this.viewSelction(row, index)}>{row.MoveOutDate !=null ? AppController.utcDate(row.MoveOutDate):'---'} </td>
						<td onClick={()=>this.viewSelction(row, index)}>{row.DailyRent}</td>
						</tr>
						<tr  id={'expand' + row.orderno}
						className={this.state.index == index ? "panel-collapse collapse fold open" : "panel-collapse collapse fold"}>
						<td colSpan="7">
						<div className="fold-content">
							<h1>RESERVATION INFO</h1>
							<div className="row">
								<div className="col-md-6">
									<div className="row">
									<div className="form-group">
										<label className="col-md-6">Email</label>
										<div className="col-md-6">
											<label className="input_label">
												<Link to="#">
												{row.GuestEmail}</Link>
											</label>
										</div>
									</div>
									<div className="form-group">
										<label className="col-md-6">Phone</label>
										<div className="col-md-6">
											<label className="input_label">{row.GuestPhoneNo}</label>
										</div>
									</div>
									<div className="form-group">
										<label className="col-md-6">Size</label>
										<div className="col-md-6">
											<label className="input_label">{row.SuiteSizeCode}</label>
										</div>
									</div>
									<div className="form-group">
										<label className="col-md-6">Daily Rate</label>
										<div className="col-md-6">
											<label className="input_label">{row.DailyRent}</label>
										</div>
									</div>
									<div className="form-group">
										<label className="col-md-6">Pets</label>
										<div className="col-md-6">
											<label className="input_label">{row.petscount}</label>
										</div>
									</div>
									</div>
								</div>
								<div className="col-md-6">
									<div className="row">
									<div className="form-group">
										<label className="col-md-6">Check In Time</label>
										<div className="col-md-6">
											<label className="input_label">{row.CheckInTime}</label>
										</div>
									</div>
									<div className="form-group">
										<label className="col-md-6">Check Out Time</label>
										<div className="col-md-6">
											<label className="input_label">11:59 pm</label>
										</div>
									</div>
									<div className="form-group">
										<label className="col-md-6">Length of Stay</label>
										<div className="col-md-6">
											<label className="input_label">	{diffDate >=0 ? diffDate+1:'invalid date'}</label>
										</div>
									</div>
									<div className="form-group">
										<label className="col-md-6">Account #</label>
										<div className="col-md-6">
											<label className="input_label">{row.accountId}</label>
										</div>
									</div>
									<div className="form-group">
										<label className="col-md-6">PO #</label>
										<div className="col-md-6">
											<label className="input_label">{row.PurchaseOrder}</label>
										</div>
									</div>
									</div>
								</div>
							</div> 
						</div>
						</td>
						</tr>
					</tbody>
					)
					}) }
				</table>}
				{this.state.loadMoreButton? 
				<div className="panel-body_loader loadmore" > <span style={{fontSize: '50px', width: '100%', margin: 'auto', textAlign: 'center'}}><i className="fa fa-spinner fa-spin"/> please wait ...!</span>
				</div>
				: '' }
				{(! this.state.loadMoreButton && filterData.length >=10 && this.state.loadMoreString) ?
				<div className="loadmore"> <a  onClick={()=>this.loadMore()}>Load More <i className="fa fa-plus"></i></a> </div>
				:null}
			</div>
					</div>
				</div>
			</div>
			</div>
			</div>
			<Footer/>
		</div>

    );
}
}
export default ClientPortal;

