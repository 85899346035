import Footer from "../../common/footer";
import Header from "../../common/header";
import OwlCarousel from "react-owl-carousel";
import RequestCallback from "./requestCallback";

class insuarnceLandingHawaii extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
  }

  handleClick() {
    $("#sideQuote").addClass("active");
    $(".overlay").addClass("active");
    $(".collapse.in").toggleClass("in");
    $("a[aria-expanded=true]").attr("aria-expanded", "false");
  }

  show3dView() {
    jQuery(
      function ($) {
        $("#threevideoView").modal("show");
      }.bind(this)
    );
  }

  render() {
    return (
      <div className="SAweb_updateUi back_layer">
        <Header />
        <section className="SAweb_insuranceBottomText">
          <h3>We care about you and your family. We’re here to serve you.</h3>
        </section>

        <section className="SAweb_ghousingUltimate bg-white">
          <div className="container">
            <div className="row" data-aos="fade-up">
              <div className="col-sm-6">
                <img
                  src="/assets/newUI-images/insurance-hawaii/Hawaii.jpg"
                  alt=""
                  width="100%"
                />
              </div>
              <div className="col-sm-6">
                <p className="p">
                  SuiteAmerica is here to help anyone that are affected by
                  recent wild fire disaster in Hawaii.
                </p>
                <p className="p">
                  SuiteAmerica has more than 200 fully furnished units in
                  Silicon Valley Area that are ready to serve your immediate
                  temporary housing needs.
                </p>
              </div>
            </div>
            <div className="text-center mt-5">
              <button
                className="btn black-btn"
                type="button "
                onClick={this.handleClick}
              >
                {" "}
                Reserve Now{" "}
              </button>
            </div>
          </div>
        </section>

        <section className="SAweb_insuranceSolutions">
          <div className="container">
            <div className="tails">
              <div className="item">
                <div className="pic top">
                  {" "}
                  <img
                    src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/insurance/slide-1.jpg"
                    alt=""
                    width="100%"
                  />{" "}
                </div>
                <div className="pic bottom">
                  {" "}
                  <img
                    src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/insurance/slide-2.jpg"
                    alt=""
                    width="100%"
                  />{" "}
                </div>
              </div>
              <div className="item">
                <h2>Agile Solutions & Superior Service</h2>
                <p className="p">
                  More than just a fast solution, SuiteAmerica’s fully-furnished
                  apartments come with 24/7 guest service, basic utilities,
                  grocery packages, and housekeeping.{" "}
                </p>

                <p className="p">
                  You have important things to worry about. Your temporary
                  housing should not be one of them.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="SAweb_insuranceWeCanHelp">
          <h2 className="">We can help</h2>
          <div className="container">
            <div className="tails">
              <div className="item">
                <h3>For Policyholders</h3>
                <ul>
                  <li>
                    <span>Efficient:</span>Get into temporary housing quickly.
                  </li>
                  {/* <li>
                    <span>Proximity:</span>We’ll secure your temporary housing
                    close to your location.
                  </li> */}
                  <li>
                    <span>Prepared:</span>Fully-furnished apartments, including
                    basic utilities, housekeeping options, and grocery starter
                    packages.
                  </li>
                  <li>
                    <span> Accessible:</span>SuiteAmerica’s 24/7 Guest
                    Experience Team will answer any questions that might come
                    up.
                  </li>
                  <li>
                    <span>Flexible:</span>Billing options include direct billing
                    to either the homeowner or the insurance company.
                  </li>
                </ul>
              </div>
              <div className="item">
                <h3>For Catastrophe Teams</h3>
                <ul>
                  <li>
                    <span>Experienced:</span>SuiteAmerica has been in the
                    temporary housing business for three decades.
                  </li>
                  <li>
                    <span>Dedicated:</span>Our team will take care of your
                    lodging needs easily and efficiently.
                  </li>
                  <li>
                    <span>Industry-Leading Technology:</span>Online booking
                    platform allows easy, quick booking.
                  </li>
                  <li>
                    <span>Ready:</span>Fully-furnished suites, prepared with
                    bathroom, kitchen, laundry, and grocery starter kits.
                  </li>
                  <li>
                    <span>24/7:</span>Our teams are accessible whenever you need
                    us. We’re here to serve.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <div className="text-center mb-5">
          <button
            className="btn black-btn"
            type="button "
            onClick={this.handleClick}
          >
            {" "}
            Reserve Now{" "}
          </button>
        </div>

        <section className="bg-white">
          <div className="container py-5" data-aos="fade-up">
            <div className="video3dViewText">
              <h2 className="text-center m-auto my-5 pt-1">
                Take a 3D tour of your new abode
              </h2>
            </div>
            <div className="SAweb_video mb-5">
              <img
                src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/videoImage.jpg"
                alt=""
                width="100%"
              />
              <a href="#" onClick={this.show3dView}>
                <i className="bi bi-play-circle"></i>
              </a>
            </div>
          </div>
        </section>

        <section className=" SAweb_guestExpSlider pt-5" data-aos="fade-up">
          <div className="container SAweb_owl-slider2  py-1">
            <h2 className=" pt-3">See What Our Guests Have To Say</h2>
            <div className="owl-slider SAweb_sliderNav my-5 pb-5">
              <div className=" SAweb_Sliderdots">
                <OwlCarousel
                  className="owl-theme owl-carousel"
                  {...{
                    loop: false,
                    margin: 20,
                    nav: false,
                    dots: true,
                    items: 1,
                    autoplay: false,
                    navContainerClass: "owl-buttons",
                    navText: [
                      "<i className='glyphicon glyphicon-menu-left'></i>",
                      "<i className='glyphicon glyphicon-menu-right'></i>",
                    ],
                    responsive: {
                      300: { items: 1 },
                      767: { items: 2 },
                      992: { items: 3 },
                    },
                  }}
                >
                  <div className="item">
                    <div className="caption">
                      <i className="bi-quote-image black-quote-image"></i>
                      <p>
                        Thanks for making our stay a memorable one! We really
                        liked the whole apartment and the setup and the
                        attention to details to make our stay comfortable! We
                        loved it!!
                      </p>
                      <h3>Haider, Cupertino </h3>
                      <i className="bi-quote-image black-quote-image"></i>
                    </div>
                  </div>
                  <div className="item">
                    <div className="caption">
                      <i className="bi-quote-image border-quote-image"></i>

                      <p>
                        Provided a nice apartment in a good locality with all
                        necessary furnitures and equipments. These apartments
                        are quite suitable for comfortable stay. I recommend it
                        strongly.
                      </p>
                      <h3>Dhiraj, Sunnyvale </h3>
                      <i className="bi-quote-image border-quote-image"></i>
                    </div>
                  </div>
                  <div className="item">
                    <div className="caption">
                      <i className="bi-quote-image black-quote-image"></i>

                      <p>
                        My landing into the apartment was smooth and pleasant.
                        My family liked it. Thank you.
                      </p>
                      <h3>Yaroslav, Mountain View </h3>
                      <i className="bi-quote-image black-quote-image"></i>
                    </div>
                  </div>
                  <div className="item">
                    <div className="caption">
                      <i className="bi-quote-image border-quote-image"></i>
                      <p>
                        Thank you for your attention to details. We really
                        enjoyed the set up and start up kit. In addition, the
                        toys for our 15-month old boy. Thank you!
                      </p>
                      <h3>Yuan, Hillsboro </h3>
                      <i className="bi-quote-image border-quote-image"></i>
                    </div>
                  </div>
                  <div className="item">
                    <div className="caption">
                      <i className="bi-quote-image black-quote-image"></i>
                      <p>
                        Extremely smooth move in process and very thoughtful
                        touches. Our dogs absolutely love the dog beds{" "}
                      </p>
                      <h3>Diana, Hillsboro </h3>
                      <i className="bi-quote-image black-quote-image"></i>
                    </div>
                  </div>
                  <div className="item">
                    <div className="caption">
                      <i className="bi-quote-image border-quote-image"></i>
                      <p>
                        The apartment is very nice and has room for our family.
                        It was very nice of you to supply so many goodies, not
                        just for us, but also for the dog.{" "}
                      </p>
                      <h3>Richard, San Diego </h3>
                      <i className="bi-quote-image border-quote-image"></i>
                    </div>
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </div>
        </section>

        <div
          className="modal modal-fullscreen-xl modal fade animated tab-modal guest-Ui SAweb_modalPoup"
          id="threevideoView"
          tabIndex={-1}
          data-backdrop="static"
          data-keyboard="false"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content newSRView">
              <div className="servicerequest-pop-header modal-header border-0">
                <button
                  type="button"
                  className="btn close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="bi bi-x"></i>
                </button>
              </div>
              <div className="modal-body">
                <iframe
                  src={"https://my.matterport.com/show/?m=iLeosSyv3Bn"}
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  allowFullScreen
                />
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
        <RequestCallback managerEmail="ashok@suiteamerica.com" />

        <Footer />
      </div>
    );
  }
}
export default insuarnceLandingHawaii;
