/* Importing the node modules, child components, services and controllers used 
   inside IntranetCustomRequestone component */   
   import $ from "jquery";
   import AppController from "../../../controller/appController";
   /* IntranetCustomRequestone Component initialization */
   class IntranetCustomRequestone extends React.Component{
       /* Initializing objects of its IntranetCustomRequestone class */
       constructor(props) {
           super(props)
           this.state = {
           projectType: '',
           companyName: '',
           color:'',
           message:'',
           projectData:[]
          }
          this.setValue = this.setValue.bind(this);
          this.next = this.next.bind(this);
           }
       /* It is invoked immediately after a component is mounted */	
       componentDidMount() {
                           $(document)
                       .ready(function() {
                           $('html, body').animate({
                               scrollTop: 0
                           });
                           AppController.stickyTitles()
                       })
               }
               next(data) {
                   if(this.props.projectType!='' && this.props.companyName!=''){
                    const request1={
                        page: data
                       }
                       this.props.next(request1)
                   }
                   else{
                        this.setState({color:'red',message:'Please Enter Required fields'})
                   }
               }

               setValue(field, e) {
                   this.setState({message:'',color:''})
                   var object = {};
                   object[field] = e.target.value;
                   this.props.setValue(object);
               }           
   /* It is invoked to return html content */			
   render(){
       return(
        <div className="service_request">
        <div className="header-ht-cover"></div>
        <div className="container">
           <h1 className="wow animated zoomIn">MARKETING REQUEST FORM</h1>
           <div className="inet_custom_form">
              <form id="msform" className="wow animated fadeInLeft">
                 {/* <!-- progressbar --> */}
                 <ul id="progressbar">
                    <li className="active"></li>
                    <li></li>
                    <li></li>
                    <li> </li>
                 </ul>
                 {/* <!-- fieldsets --> */}
              </form>
              <p style={{color:this.state.color,textAlign:'center'}}>{this.state.message}</p>
              
              <div role="form"  className="inet_custom2 wow animated fadeInRight">
                  
                 <div className="col-sm-12">
                    <div className="form-group">
                       <p>PROJECT</p>
                    </div>
                    <div className="form-group">
                    <select className="form-control"  value={this.props.projectType} 
                    onChange={(e) => this.setValue('projectType', e)}>
      <option disabled value="">Project Type </option> 
      {this.props.projectData.map((row, index) => {
        return(
            <option value={row.Code} key={index}>{row.Display}</option> 
        )
      })}
      </select> 
                     
                    </div>
                    <div className="form-group">
                      <input type='text' className="form-control" placeholder="Title / Full Company Name*" value={this.props.companyName} 
                      onChange={(e) => this.setValue('companyName', e)}/>
                    </div>
                 </div>
                 <div className="text-center">                     	     
                    <button type="button" className="button inet_button" 
                    onClick={() => this.next(2)}>NEXT<i className="fa fa-caret-right"></i> </button>  
                 </div>
              </div>
           </div>
        </div>
     </div>
       );
   }
   }
   export default IntranetCustomRequestone;