/* Importing the node modules, child components, services and controllers used 
   inside Apps component */
   import OwlCarousel from 'react-owl-carousel';   
   import AppData from './appData.json';
   /* Apps Component initialization */
   class Apps extends React.Component{
       /* Initializing objects of its Apps class */
       constructor(props) {
           super(props);
           /* Apps Component State variables Initialization */
           this.state = {
               appsInfo : AppData
           }
       }
       /* It is invoked to return html content */
       render(){
           return(
            <div className="guest_sitenew">
                <div className="intra_staff2">
                    <div className="panel-group">
                        <div className="panel panel-default no-border-btm">
                            <div className="panel-heading">
                            <div className="container">
                                <h4 className="panel-title">
                                    <a className="no-controls">
                                    Apps
                                    </a>                           
                                </h4>
                            </div>
                            </div>
                            <div className="container">
                            <div className="panel-collapse">
                            <div className="panel-body">
                                <div className="moving_third">
                                    <div className="appslider">
                                        <OwlCarousel
                                        ref={inst =>
                                        this.slider = inst}
                                        {...{ 
                                        loop: false, 
                                        nav:true, 
                                        dots: true, 
                                        items: 1, 
                                        margin: 50,
                                        navContainerClass: 'owl-buttons', 
                                        navText: ["<img src='"+s3BucketUrl_WebsiteImages+"SuiteAmerica-Carousel-Left-Arrow-Grey.png'></img>", "<img src='"+s3BucketUrl_WebsiteImages+"SuiteAmerica-Carousel-Right-Arrow-Grey.png'></img>"],
                                        responsive:{ 
                                        320:{ items:1, nav:true, loop:false }, 
                                        767:{ items:3, nav:true, loop:false }, 
                                        992:{ items:5, nav:true, loop:false }, 
                                        1199:{ items:7, nav:true, loop:false } }}}>
                                        {AppData.map((row, index) => {
                                        return (
                                            <div key={index} className="item">
                                            <a href={row.appUrl} target='_blank'>  <img className="img-responsive" src={row.appImage} alt="" /></a>
                                            </div>
                                           )
                                         })
                                       }
                                     </OwlCarousel>
                                    </div>
                                </div>
                            </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

           );
       }
   }
   export default Apps;