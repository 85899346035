/* Importing the node modules, child components, services and controllers used
   inside GSA component */
import GuestHeader from './guestHeader';
import SubFooter from "./subFooter";
import Footer from './footer';
import MainController from "../controller/mainController";
import {Link} from 'react-router-dom';
/* GSA Component initialization */
class GSA extends React.Component {
    /* Initializing objects of its GSA class */
    constructor(props) {
        super(props);
    }
    /* It is invoked immediately before mounting occurs */
    componentWillMount() {
        MainController.getInitialInformation();
    }
    /* It is invoked to return html content */
    render() {
        return (
            <div className="corporateHousing_Updates">
                <GuestHeader/>
                <div className="cover-tophd-ht"></div>
                <div className="container-fluid nike company_section" id="serviceRequest">
                    <div className="container text-center">
                        <h1>Federal Civilian Housing</h1>
                        <h3>Delivering Excellence for the Civil Service</h3>
                    </div>
                    <div className="container mrg-top-30">
                        <p className="mrg-top-20">
                            <strong>SuiteAmerica has provided the Civil Service with unparalleled excellence
                                in customer service for over 20 years. We provide temporary lodging solutions
                                that are customized for Federal travelers and we are dedicated to consistently
                                delivering customer service and temporary living quarters that exceeds a Federal
                                traveler's expectations.</strong>
                        </p>
                        <p className="mrg-top-20">SuiteAmerica has over 60 years of combined experience meeting the unique
                            demands of Federal civilian travelers with detailed oriented, stress-free travel
                            procurement through GSA Schedule 48 and all major Global Distribution Systems.</p>
                        <p className="mrg-top-20">SuiteAmerica is chosen by U.S. State Department consistently for Federal
                            contracts with a dedicated government services team that understands the
                            detailed and often complex needs of Federal travelers. Our government team's
                            knowledge allows agencies to better manage their complex logistics, while
                            SuiteAmerica handles the details of temporary assignments.</p>
                        <p className="mrg-top-20">Corporate housing is the logical choice for Federal travelers, because unlike
                            hotels, SuiteAmerica furnished apartments can be fully customized to exact
                            agency standards and specifications. SuiteAmerica offers detailed assistance
                            with end-to-end procurement management and support because no two Federal
                            assignments are exactly alike and we want your assignment to be second-to-none.</p>
                        <p className="mrg-top-20">Federal travelers can depend on SuiteAmerica quality without forgoing service
                            for cost. SuiteAmerica is dedicated to delivering award-winning customer service
                            24/7 through government representatives and mobile applications. We deliver the
                            service and flexibility that you depend on from SuiteAmerica at cost-effective
                            rates.</p>
                        <div className="text-center">
                            <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/GSA.png" alt=""/>
                            <Link to="/stay-with-us" className="btn btn-red mrg-top-60 mrg-btm-60">GSA REQUEST</Link>
                        </div>
                    </div>
                    <div className="container">
                        <h2 className="text-center">Federal Civilian Agencies Choose SuiteAmerica:</h2>
                        <h4 className="mrg-top-30">World Class Amenities:</h4>
                        <ul className="list-ul-disc">
                            <li>Two to three times more space than a typical hotel room</li>
                            <li>Fully furnished spacious living and dining areas, separate bedrooms, 2
                                televisions, DVD and basic cable, Hi speed Internet, fully equipped kitchens and
                                baths, washer and dryer in apartment*, with utilities included</li>
                            <li>Communities offer clubhouses, Internet, business centers, fitness centers,
                                swimming pools, and hot tubs in most locations</li>
                            <li>SuiteAmerica is dedicated to delivering assignment housing that exceeds
                                expectations with a quality inspection team delivering a housing experience that
                                is second-to-none</li>
                        </ul>
                        <h4 className="mrg-top-30">Award Winning Customer Service:</h4>
                        <ul className="list-ul-disc">
                            <li>Our Government Services Representative provides one point of contact for
                                your procurement office, providing seamless customization to meet government
                                travelers needs with 24/7 support</li>
                            <li>Nationwide presence with local service, through our offices in the U.S.</li>
                            <li>Dedicated government specialists with the speed, flexibility, and detail
                                oriented expertise to meet your discerning demands
                            </li>
                        </ul>
                        <h4 className="mrg-top-30">Ultimate Convenience:</h4>
                        <ul className="list-ul-disc">
                            <li>Superior accommodations that fall within TDY and most PCS per diems</li>
                            <li>Hundreds of communities with thousands of apartments nationwide that are
                                conveniently located to city centers and government offices</li>
                            <li>Consistent billing accuracy with multiple payment options</li>
                            <li>Government-issued credit cards accepted, along with Global Distribution
                                System (GDS) pre-approved rates</li>
                            <li>No service tax for Federal extended stay travelers</li>
                        </ul>
                        <h4 className="mrg-top-30">Top-Flight Security:</h4>
                        <ul className="list-ul-disc">
                            <li>Controlled-access apartments</li>
                            <li>Top-flight physical security and life safety systems</li>
                            <li>Secure SuiteAmerica Web portal for ultimate user data protection</li>
                        </ul>
                    </div>
                    <div className="container text-center mrg-top-40">
                        <h4>Choose SuiteAmerica for an assignment that is second-to-none</h4>
                    </div>
                </div>
                <SubFooter/>
                <Footer/>
            </div>
        );
    }
}
export default GSA;