import GuestHeader from "../../common/guestHeader";
import BungalowHomes from "./bungalowHomes";
import WhatsInclude from "./whatsInclude";
import Gallery from "./gallery";
import AppController from "../../controller/appController";
class Bungalow extends React.Component{
    constructor(props){
        super(props)
        this.state={

            homes:{
                heading:'New job in a new city? Bungalow has your back',
                BannerImage:'assets/images/bungalow/bungalow_banner.jpg',
                Paragraph:'Bungalow offers beautiful homes in neighborhoods near everything that matters to you, shared with amazing roommates.',
                data:[
                {Name:'Roommates, minus the stress',Paragraph:'Stop worrying about finding the perfect roommates or who pays for what bills. Bungalow has you covered.',Image:'assets/images/bungalow/roommates@2x.jpg'},
                {Name:'Everything’s taken care of',Paragraph:'With lightning fast WiFi, extra utilities, furnished common areas, and house cleaning provided, you’ll feel right at home from the moment you walk in.',Image:'assets/images/bungalow/furnished-spaces@2x.jpg'},
                {Name:'Build your community',Paragraph:'Build a community with the people you’re living with. Enjoy monthly hosted events and outings with your new friends.',Image:'assets/images/bungalow/community@2x.jpg'},
                {Name:'Handpicked Roommates',Paragraph:'We screen and interview all roommates to match you with people who share the same common interests',Image:'assets/images/bungalow/housemates@2x.jpg'},
            ]},
            images:{
                heading:'A built-in community, from day 1',
                Paragraph:'Our Bungalow members have access to exclusive community events with an instant network of friends. From BBQs to bar crawls, team sports and weekend trips, you’ll get a lot more than room and board.',
                data:[
                    {Image:'assets/images/bungalow/view_listing1.jpg'},
                    {Image:'assets/images/bungalow/view_listing2.jpg'},
                    {Image:'assets/images/bungalow/view_listing3.jpg'},
                    {Image:'assets/images/bungalow/view_listing4.jpg'},
                    {Image:'assets/images/bungalow/view_listing5.png'},
                    {Image:'assets/images/bungalow/view_listing6.png'},
                    {Image:'assets/images/bungalow/view_listing7.jpg'},
                    {Image:'assets/images/bungalow/view_listing8.png'},
                    {Image:'assets/images/bungalow/view_listing9.png'}
               
            ]},
            
            whatsInclude:{
                heading:'What’s included',
                Paragraph:'At Bungalow we take pride in providing you with an exceptional home renting experience. Here’s a look at what’s included in every Bungalow home.',
                data:[
                {Name:'Custom designed interiors',Paragraph:'Bungalow designers thoughtfully curate the common areas to make your new home welcoming and cozy. Furnish your room any way you choose.',Image:'assets/images/bungalow/interiors@2x.jpg'},
                {Name:'Kitchen essentials',Paragraph:'Bungalow kitchens are suitable for all aspiring home chefs and come with all the basics. No need to worry about shopping for cookware.',Image:'assets/images/bungalow/kitchen@2x.jpg'},
                {Name:'Flexible leasing',Paragraph:'4 to 18 month lease terms that best fit your timeframe.',Image:'assets/images/bungalow/flexible-leasing@2x.jpg'},
                {Name:'Lightning fast Wi-Fi',Paragraph:'Bungalow homes include a WiFi mesh network router that gives you unbelievably fast internet in every room. Plus, it’s already set up for you!',Image:'assets/images/bungalow/wi-fi@2x.jpg'},
                {Name:'Smart TVs',Paragraph:'Large smart TVs are installed in the living room for you and your roommates to stream your favorite shows and jam out to your regular playlists.',Image:'assets/images/bungalow/smart-tvs@2x.jpg'},
                {Name:'Regular cleaning',Paragraph:'Bungalow professional cleaning staff will ensure your home is spotless and taken care of with regular cleanings.',Image:'assets/images/bungalow/cleaning@2x.jpg'},
                {Name:'Move freely from city to city',Paragraph:'No longer be tied down to one home, Bungalow residents can move freely between homes and cities with 30 days notice.',Image:'assets/images/bungalow/city@2x.jpg'},
                {Name:'Private bedrooms',Paragraph:'Various types of private bedrooms, from cozy lofts to masters with en-suite bathrooms. Every room provides plenty of space for you to settle in.',Image:'assets/images/bungalow/bedrooms@2x.jpg'},
                
            ],
        
        left:[
          
        ]
    },
        }
    }
    async componentWillMount(){
        AppController.removeLocalStorageData();
        $('html, body').animate({scrollTop: 0});
        AppController.stickyTitles();
      }
render(){
    return(
        <div>
        <GuestHeader/>
        <div className="bungalowpage">
        <BungalowHomes {...this.state}/>
        <WhatsInclude {...this.state}/>
        <Gallery {...this.state}/>
        </div>
        </div>
    )
}
}
export default Bungalow;