'use strict'
import serviceBase from './serviceBase'
const locationService = {
    getCountryList:()=>serviceBase.post('/mysuiteamerica-location/apis/GetCountryList'),
    getStatesByCountryId:(data)=>serviceBase.post(`/mysuiteamerica-location/apis/GetStatesByCountryId&&countryId=${data.countryId}`),
    getCitysByStateId:(data)=>serviceBase.post(`/mysuiteamerica-location/apis/GetCitysByStateId&&stateid=${data.stateId}`),
    getAmenitiesList:()=>serviceBase.post('/mysuiteamerica-location/apis/GetAmenitiesList'),
    GetTeslaCommunities:(data)=>serviceBase.post('/mysuiteamerica-location/api/GetTeslaCommunities',data),
    getCommunityData:(data)=>serviceBase.post('/mysuiteamerica-location/api/GetCommunityData',data),
    getCommunityInfoByUid:(data)=>serviceBase.post(`/mysuiteamerica-location/apis/GetCommunityInfoByName&&communityUId=${data.communityUId}&imageType=${data.imageType}`),
    getCommunityInfoByName:(data)=>serviceBase.post(`/mysuiteamerica-location/apis/GetCommunityInfoByName&&communityName=${(data.communityName).replace("/", "$$$$")}&imageType=${data.imageType}`),
    getNearCommunitysByCommunity:(data)=>serviceBase.post('/mysuiteamerica-location/api/GetNearCommunitysByCommunity',data),
    saveFavouriteCommunity:(data)=>serviceBase.post('/mysuiteamerica-location/api/SaveFavouriteCommunity',data),
    unFavouriteCommunityByUser:(data)=>serviceBase.post('/mysuiteamerica-location/api/UnFavouriteCommunityByUser',data),
    saveRecentlyViewedCommunity:(data)=>serviceBase.post('/mysuiteamerica-location/api/SaveRecentlyViewedCommunity',data),
    getRecentlyViewedCommunitys:(data)=>serviceBase.post(`/mysuiteamerica-location/apis/GetRecentlyViewedCommunitys&&userid=${data.userid}`),
    saveSavedSearch:(data)=>serviceBase.post('/mysuiteamerica-location/api/SaveSavedSearch',data),
    getSavedSearch:(data)=>serviceBase.post(`/mysuiteamerica-location/apis/GetSavedSearch&&userid=${data.userid}`),
    getFavouritesListById:(data)=>serviceBase.post(`/mysuiteamerica-location/apis/GetFavouritesListById&&userId=${data.userid}&loadMore=${data.loadMore}`),
    LinkMastersByOrderUId:(data)=>serviceBase.post(`/mysuiteamerica-location/apis/LinkMastersByOrderUId&&orderUId=${data.orderUId}`),
    View360List:(data)=>serviceBase.post('/mysuiteamerica-location/api/GetComplex360ViewList',data),
    getCommunityInfoo:(data)=>serviceBase.post('/mysuiteamerica-location/api/v2$$GetCommunityData',data),

    GetCommunityInfoByNameInfo: (data) => serviceBase.post(`/mysuiteamerica-location/apis/GetCommunityInfoByNameInfo&&communityName=${data.communityName}&imageType=${data.imageType}&masterid=${data.masterid}`),
    GetMaidScheduleRemainderInfo: (data) =>
    serviceBase.post(
      `/mysuiteamerica-location/apis/GetMaidScheduleRemainderInfo&&scheduleUId=${data.id}`
    ),
}
export default locationService