import {Link} from 'react-router-dom';
import MediaKitData from './mediaKit.json';
const MediaFooter = () => {
    return (
        <div className="col-md-8 col-md-offset-2 wow fadeInUp" data-wow-delay="0.5s">
            <ul className="mediakit_footer_list">
                {MediaKitData.map((row, index) => {
                    return (
                        <li key={index}>
                            <Link to={row.url}>{row.name}</Link>
                        </li>
                    )
                })}
            </ul>
        </div>
    );

}
export default MediaFooter;