import DatePicker from 'react-datepicker';
import {CardElement, injectStripe} from 'react-stripe-elements';

class PaymentForm extends React.Component{
   constructor(props){
      super(props)
      this.submit = this.submit.bind(this);
   }
   async submit() {   
      await this.props.suiteBookingLoader()
        //await this.props.stripe.createToken({name: this.props.paymentName})
        let {token} = await this.props.stripe.createToken({name: this.props.paymentName});
  
      if(token==undefined){
          this.props.submit(token)
      }
      else{
          this.props.submit(token.id)
      }
      
    }
   render(){
      return(
         <div>
         {/* Payment */}
         <h4 className="text-red p_relative"> Payment   <img src="../../assets/images/payment1.png" className="payment_card_img" /> </h4>
         <div id="payment-information" className="panel-collapse collapse in">
            <div className="bookg-form">
               <div className="row">
                  <div className="col-sm-4">
                  <div className={(this.props.cardValidationError.paymentName == 1 || (this.props.submitStatus == 1 && this.props.paymentName == "")) ? "form-group error":"form-group"}>
                        {this.props.paymentName!= "" ?<label className="form-control-placeholder">Name On Card</label> : ""}
                       {this.props.paymentName != "" && this.props.cardValidationError.paymentName == 0 ?  <i className="fa fa-check succes"></i> : ""}
                        <input type="text" maxLength="30" autoComplete="paymentName" className="form-control" placeholder="Name On Card *"  value={this.props.paymentName}  onChange={(e)=>this.props.setValue('paymentName',e)} onKeyUp={(e) => this.props.CardValidation('paymentName', 3)}/>
                        {this.props.cardValidationError.paymentName == 1 || this.props.submitStatus == 1 ? <span className="text-danger font-12">{this.props.paymentName == "" ? "Please enter Name on card" :(this.props.cardValidationError.paymentName == 1 ? "Please enter at least 3 characters" : "")}</span> : ""}
                     </div>
                  </div>
                  <div className="col-sm-8">
                     <div className="form-group dupcard">
                        <label className="form-control-placeholder">Card Information</label>
                        <CardElement/>
                     </div>
                     <div id="card-errors" role="alert"></div>
                  </div>
                  <div className="col-sm-12">
                  <div className={(this.props.cardValidationError.paymentAddress == 1 || (this.props.submitStatus == 1 && this.props.paymentAddress == "")) ? "form-group error":"form-group"}>
                  {this.props.paymentAddress != "" && this.props.cardValidationError.paymentAddress == 0 ?  <i className="fa fa-check succes"></i> : ""}                    
                     {this.props.paymentAddress!= "" ? <label className="form-control-placeholder">Billing Address</label> : ""}
                        <input type="text" autoComplete="paymentAddress" className="form-control" placeholder="Billing Address *" value={this.props.paymentAddress} onChange={(e)=>this.props.setValue('paymentAddress',e)} onKeyUp={(e) => this.props.CardValidation('paymentAddress', 3)} />
                        {this.props.cardValidationError.paymentAddress == 1 || this.props.submitStatus == 1 ? <span className="text-danger font-12">{this.props.paymentAddress == "" ? "Please enter Billing address" :(this.props.cardValidationError.paymentAddress == 1 ? "Please enter at least 3 characters" : "")}</span> : ""}
                     </div>
                  </div>
                  <div className="col-sm-4">
                  <div className={(this.props.cardValidationError.paymentCity == 1 || (this.props.submitStatus == 1 && this.props.paymentCity == "")) ? "form-group error":"form-group"}>
                     {this.props.paymentCity!= "" ? <label className="form-control-placeholder">City</label> : ""}
                     {this.props.paymentCity != "" && this.props.cardValidationError.paymentCity == 0 ? <i className="fa fa-check succes"></i> : ""}
                        <input type="text" maxLength="20"  autoComplete="paymentCity" className="form-control" placeholder="City *" value={this.props.paymentCity} onChange={(e)=>this.props.setValue('paymentCity',e)} onKeyUp={(e) => this.props.CardValidation('paymentCity', 3)}/>
                        {this.props.paymentCity == ""  && this.props.submitStatus == 1 ? <span className="text-danger font-12">Please enter City</span> : ""}
                        {this.props.cardValidationError.paymentCity == 1 || this.props.submitStatus == 1 ? <span className="text-danger font-12">{this.props.paymentCity == "" ? "Please enter City" :(this.props.cardValidationError.paymentCity == 1 ? "Please enter at least 3 characters" : "")}</span> : ""}
                     </div>
                  </div>
                  <div className="col-sm-4">
                  <div className={(this.props.cardValidationError.paymentState == 1 || (this.props.submitStatus == 1 && this.props.paymentState == "")) ? "form-group error":"form-group"}>
                     {this.props.paymentState!= "" ?<label className="form-control-placeholder">State</label> : ""}
                     {this.props.paymentState != "" && this.props.cardValidationError.paymentState == 0 ? <i className="fa fa-check succes"></i> : ""}
                        <input type="text"  maxLength="10" autoComplete="paymentState" className="form-control" placeholder="State *" value={this.props.paymentState} onChange={(e)=>this.props.setValue('paymentState',e)} onKeyUp={(e) => this.props.CardValidation('paymentState', 2)}/>
                        {this.props.cardValidationError.paymentState == 1 || this.props.submitStatus == 1 ? <span className="text-danger font-12">{this.props.paymentState == "" ? "Please enter State" :(this.props.cardValidationError.paymentState == 1 ? "Please enter at least 2 characters" : "")}</span> : ""}
                     </div>
                  </div>
                  <div className="col-sm-4">
                  <div className={((this.props.cardValidationError.paymentZipCode == 1) || (this.props.submitStatus == 1 && (this.props.paymentZipCode == "" || this.props.paymentZipCode.length == 0))) ? "form-group error":"form-group"}>
                  {this.props.paymentZipCode != "" && this.props.cardValidationError.paymentZipCode == 0 ? <i className="fa fa-check succes"></i> : ""}
                     {this.props.paymentZipCode!= "" ?<label className="form-control-placeholder">Zip Code</label>  : ""}
                        <input type="text" autoComplete="paymentZipCode" className="form-control" placeholder="Zip Code *" value={this.props.paymentZipCode} onChange={(e)=>this.props.setValue('paymentZipCode',e)}  onKeyUp={(e) => this.props.CardValidation('paymentZipCode')} />
                        {(this.props.submitStatus == 1 || this.props.cardValidationError.paymentZipCode == 1) ? <span className="text-danger font-12">{this.props.paymentZipCode!="" ? (this.props.cardValidationError.paymentZipCode == 1 ? "Please enter valid Zip Code" :"") : "Please enter ZIP Code"}</span> : ""}
                     </div>
                  </div>
               </div>
            </div>
         </div>
         {/* Arrival */}
         <h4 className="text-red">
            Arrival
         </h4>
         <div id="arrival-information" className="panel-collapse collapse in">
            <div className="mrg-btm-20">
               <div className="bookg-form">
                  <div className="row">
                     <div className="col-sm-6">
                        <div className="form-group">
                        {this.props.airline!= "" ? <label className="form-control-placeholder">Airline</label> : ""}
                        {this.props.airline != "" ? <i className="fa fa-check succes"></i> : ""}
                           <input type="text" maxLength="20" autoComplete="airline" className="form-control" placeholder="Airline" value={this.props.airline} onChange={(e)=>this.props.setValue('airline',e)} />
                        </div>
                     </div>
                     <div className="col-sm-6">
                        <div className="form-group">
                        {this.props.flight!= "" ?<label className="form-control-placeholder">Flight#</label> : ""}
                        {this.props.flight != "" ? <i className="fa fa-check succes"></i> : ""}
                           <input type="text" maxLength="20" className="form-control" placeholder="Flight#" value={this.props.flight} onChange={(e)=>this.props.setValue('flight',e)} />
                        </div>
                     </div>
                     <div className="col-sm-6">
                        <div className="form-group">
                        {this.props.arrivalairport!= "" ?<label className="form-control-placeholder">Arrival Airport</label>: ""}
                        {this.props.arrivalairport != "" ? <i className="fa fa-check succes"></i> : ""}
                           <input type="text" maxLength="20" className="form-control" placeholder="Arrival Airport" value={this.props.arrivalairport} onChange={(e)=>this.props.setValue('arrivalairport',e)} />
                        </div>
                     </div>
                     <div className="col-sm-6">
                        <div className="form-group">
                        {this.props.ArrivalDate != null ? <label className="form-control-placeholder">Arrival Date</label> : ""} 
                           <DatePicker type="text" className="form-control" autoComplete="12" name="date" placeholderText="Arrival Date" selected={this.props.ArrivalDate} 
                              onChange={this.props.checkInSelection} 
                              onKeyDown={e => e.preventDefault()}
                              />
                        </div>
                     </div>
                     <div className="col-sm-6">
                        <div className="form-group">
                        {this.props.ArrivalTime != null ? <label className="form-control-placeholder">Arrival Time</label> : ""}
                           <DatePicker
                              type="text" 
                              className="form-control" 
                              autoComplete="12345"
                              name="date" 
                              onKeyDown={e => e.preventDefault()}
                              placeholderText="Arrival Time" 
                              selected={this.props.ArrivalTime}
                              onChange={this.props.timeSelection}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              dateFormat="LT"
                              timeCaption="Time"
                              />
   
   
                              
                        </div>
                     </div>
                     <div className="col-sm-6">
                        <div className="form-group">
                        {this.props.notes != "" ? <label className="form-control-placeholder">Notes</label> : ""}
                        {this.props.notes != "" ? <i className="fa fa-check succes"></i> : ""}
                           <textarea  rows="5" cols="50" className="form-control" placeholder="Notes" value={this.props.notes} onChange={(e)=>this.props.setValue('notes',e)} ></textarea>
                        </div>
                     </div>
                     <div className="col-sm-6">
                       <div className="form-group key_checkbox mrg-top-5">
                       <input type="checkbox" id="checkbox-f" name="checkbox"  onChange={()=>this.props.handleCheck()}  defaultChecked={this.props.notifyGuest} />
                       <label htmlFor="checkbox-f">Checkbox 6</label>
                       <p className="mrg-left-25">Notify Guest</p>
                   </div> 
               </div>
                     <div className="clearfix"></div>
                  </div>
                  <div className="col-sm-12 mrg-top-50">
                     <div className="form-group text-center">
                        <div>
                        {this.props.bookingLoader ? <div 
                    className='text-center'
                    style={{fontSize: '25px', width: '100%', margin: 'auto', textAlign: 'center'}}
                    ><i className="fa fa-spinner fa-spin"/>
                    Please wait ...!
                </div>
                :
                <div>
                           {Number(this.props.getInvoiceData.TotalInvoiceAmount) > 0 ?  <button style={{marginLeft: '10px'}} className="btn btn-primary btn-lg mrg-btm-20 mrg-top-30" type="button" onClick={this.submit}>{'Pay/$ ' + Number(this.props.getInvoiceData.TotalInvoiceAmount).toFixed(2)}</button> : ""}
                        </div>
                        }
                          
                           <div className="font-12 alert alert-secondary">
                              <p>We'll send you a confirmation email after you complete your reservation.</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
   }
}
export default injectStripe(PaymentForm);
