/* Importing the node modules, child components, services and controllers used 
   inside NikeInternshipContactUs component */    
   import MainController from "../../controller/mainController";
   import NikeInternshipHeader from "../../common/nikeInternshipHeader";
   import SubFooter from "../../common/subFooter";
   import ContactUs from "./common/contactUs";
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   import NikeInternFooter from "./common/nikeInternFooter";
   import NikeInternSubFooter from "./common/nikeInternSubFooter";
   /* NikeInternshipContactUs Component initialization */
   class NikeInternshipContactUs extends React.Component {
       /* Initializing objects of its NikeInternshipContactUs class */
       constructor(props) {
           super(props);
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
           MainController.getInitialInformation();
        }
       /* It is invoked to return html content */
       render() {
           return (
            <div className="corporateHousing_Updates">
                <GuestHeader/>
                <div className="cover-tophd-ht"></div>    
                <NikeInternshipHeader />
                <div className="container-fluid bg-grey">
                    <div className="container">
                        <ContactUs />
                    </div>
                </div>
                <div className="container ft-contact-adrs mrg-btm-30 mrg-top-30">
                    <NikeInternSubFooter />
                <div className="section-line"></div>
                    <NikeInternFooter />
                </div>
                <SubFooter />
                <Footer/>
            </div>   
           );
       }
   }
   export default NikeInternshipContactUs;