import GuestHeader from "../../common/guestHeader";
import Footer from "../../common/footer";
import SubFooter from "../../common/subFooter";
import MapboxController from "../../controller/mapboxController";
import AppController from "../../controller/appController";
class DeanAmenities extends React.Component {
	constructor(props) {
    super(props);
    this.state = {
    };
    this.scrollBottom = this.scrollBottom.bind(this);

	}
	scrollBottom(id,num){
		if(id=='myDiv2'){
			$('html, body').animate({
				scrollTop: $("#"+id).offset().top
		}, 1000);
			AppController.stickyTitles();
		}
		else{
			$('html, body').animate({scrollTop: 900});
			AppController.stickyTitles();
		}
		localStorage.removeItme('amenitieslink')
	}
	async componentWillMount() {
		AppController.removeLocalStorageData();
    $('html, body').animate({scrollTop: 0});
    AppController.stickyTitles();
		await  this.mapBox()
		await  this.mapBox2()
	
	   }
	 async  mapBox() {
		 const MapData=[{
			community_name: "THE DEAN",
			latitude: 37.404162,
			longitude: -122.111566
			},
			{community_name: "MADRONE APARTMENTS",
			latitude: 37.403456,
			longitude:-122.097297
			},
			]
	  const popupResult = await MapboxController.markerPopupContent(MapData);
	  await this.setState({jsonData: popupResult[0], flyData: popupResult[1]})
		mapboxgl.accessToken = 'pk.eyJ1IjoidGVqYWt1bWFyNjIiLCJhIjoiY2o2NGxlcjE4MXR6OTJxbzN5bGl3bm0xeCJ9.Oqwl3taxIQDaRDbqqVRwFQ';
		   const geojson = {
			  "type": "FeatureCollection",
			  "features": this.state.jsonData
			}
			const map = this.map =  new mapboxgl.Map({
			   container: 'map',
			   style: 'mapbox://styles/mapbox/streets-v9',
			   center: this.state.flyData[0].center,
			   zoom: 13,
			   maxZoom: 22
		  });
			   await MapboxController.mapboxInitialization(geojson, map, this, 1);
	  
	   }
  	 async  mapBox2() {
			const MapData=[{
			 community_name: "THE DEAN",
			 latitude: 37.404162,
			 longitude: -122.111566
			 },
			 {community_name: "MOUNTAIN VIEW",
			 latitude: 37.4038284,
			 longitude:-122.1103281
			 },
			 ]
		 const popupResult = await MapboxController.markerPopupContent(MapData);
		 await this.setState({jsonData: popupResult[0], flyData: popupResult[1]})
		 mapboxgl.accessToken = 'pk.eyJ1IjoidGVqYWt1bWFyNjIiLCJhIjoiY2o2NGxlcjE4MXR6OTJxbzN5bGl3bm0xeCJ9.Oqwl3taxIQDaRDbqqVRwFQ';
				const geojson = {
				 "type": "FeatureCollection",
				 "features": this.state.jsonData
			 }
			 const map = this.map =  new mapboxgl.Map({
					container: 'map-4',
					style: 'mapbox://styles/mapbox/streets-v9',
					center: this.state.flyData[0].center,
					zoom: 16,
					maxZoom: 22
			 });
					await MapboxController.mapboxInitialization(geojson, map, this, 1);
		 
			}
render(){
      return(
          <div>
       <GuestHeader/>
       <div className="deanpageonsep">
			<div className="deanpage_banner">
				<div className="deanbannerimg" style={{marginTop: '80px'}}>
					<img className="img-responsive" src="assets/images/DeanImg/banner.jpg" />
				</div>
				<div className="banner_text">
					<h2><span>SUITE<b>AMERICA</b> </span><b>|</b> THE DEAN </h2>
					<h3>ACCESS TO ALTERNATIVE AMENITIES</h3>
				</div>
			</div>
			<div className="search_port">
				<div className="item" >
					<h1>CHECK OUT THE</h1>
					<hr/>
					<h1>ALTERNATIVE AMENITIES WE HAVE LINED UP FOR OUR GUESTS AT THE DEAN</h1>
				</div>
				<div className="item" >
					<a onClick={()=>this.scrollBottom('myDiv',0)}>
						<img src="assets/images/DeanImg/biking.png" className="width100per" alt="" />
						<p > 24 HOUR FITNESS SUPER-SPORT</p>
					</a>
				</div>
				<div className="item">
					<a onClick={()=>this.scrollBottom('myDiv2',0)}>
						<img src="assets/images/DeanImg/ds4.png" className="width100per" alt="" />
						<p>MADRONE APARTMENTS</p>
					</a>
				</div>
				<div id="myDiv"></div>
			</div>
			<div  className="mountain-view" >
			<div className="container"  >
				<div className="row banner_bootm" >
				<div className="col-md-6" >
					<div className="gallery">
						<div className="itam">
							<h1>MOUNTAIN VIEW</h1>
							<img src="assets/images/DeanImg/24-hour-fitness-1.svg" className="width100per" alt="" />
						</div>
						<div className="itam">
						<p>Across the street from The Dean, 24 Hour Fitness Super Sport is available for your use while you are 
staying as our guest.</p><p> Step into this spacious gym, located a 5-minute walk from your suite, and you’ll feel the difference.  A 
vibrant community, unmatched amenities, and some of the best studio classes around Mountain View.  
Enjoy an indoor lap pool, group cycling, basketball, steam room, and more at this Super Sport club 
located next door to JoAnn Fabrics on California Street.</p>
				
						</div>
						{/* <div className="itam">
							<button className="btn"> Visit MADRONE </button>
						</div> */}

					</div>
				</div>
				<div className="col-md-6">
				<div className="row-imagesame">
						<img src="assets/images/DeanImg/biking.png" className="width100per" alt="" />
						<img src="assets/images/DeanImg/clubpage-personaltraining.png" className="width100per" alt="" />
						<img src="assets/images/DeanImg/24hrtreadmill.png" className="width100per" alt="" />
						<img src="assets/images/DeanImg/medicine-ball.png" className="width100per" alt="" />
					</div>
				
				</div>
			</div>
			</div>
			</div>
			<div className="opnsep-center">
				<img src="assets/images/DeanImg/iStock-1125482625.png" className="width100per" alt="" />
				<div className="opnsep-centerinner">
					<div className="row no-pad no-gutter">
						<div className="col-md-6 col-sm-6 first-row">
								<h1>24 HOUR FITNESS AMENITIES</h1>
								<div>
									<img src="assets/images/DeanImg/gym.svg" alt="" />
									<img src="assets/images/DeanImg/swimming-pool.svg" alt="" />
									<img src="assets/images/DeanImg/bicycle.svg" alt="" />
								</div>
						</div>
						<div className="col-md-6 col-sm-6">
							<ul>
								<li>CARDIO EQUIPMENT</li>
								<li>FREE WEIGHTS</li>
								<li>GROUP CYCLING </li>
								<li>GROUP EXERCISE</li>
								<li>INDOOR LAP POOL</li>
								<li>KIDS’ CLUB*</li>
								<li>PERSONAL TRAINING*</li>
								<li>PERSONAL TRAINING AREA </li>
								<li>PERSONAL VIEWING SCREENS</li>
								<li>PRACTICE BASKETBALL COURT </li>
								<li>PRO SHOP* </li>
								<li>SAUNA / STEAM ROOM</li>
								<li>TRAINING CLUB 24 - IGNITE*</li>
								<li>WHIRLPOOL </li>
						<li> <a href="#"><sup>*</sup>Additional fees apply</a> </li>	
							</ul>
						</div>
					</div>
				</div>
			</div>

			
   

		<div className="signup-3days">
				<img src="assets/images/DeanImg/signuop-3d.png" alt="" className="width100per" />
				<div className="text">
					<h1>SIGN UP FOR A<br/>
					3 DAY TRIAL PASS</h1>
					<div className="images">
						<div className="item">
							<img src="assets/images/DeanImg/id-card.svg" alt="" />
							<p>YOUR ID</p>
						</div>
						<div className="item">
							<img src="assets/images/DeanImg/screenshot.svg" alt="" />
							<p>SCREEN SHOT OF EMAIL INVITE</p>
						</div>
							<div className="item">
							<img src="assets/images/DeanImg/contract.svg" alt="" />
							<p>PROOF OF RESIDENCY LETTER</p>
						</div>	 
					</div>
					
					
					<h2>If you enjoy the 3 days, just let us know and we will provide you with a<br/>
					membership while you are a SuiteAmerica guest.</h2>
					{/* <buttton className="btn">SIGN UP NOW</buttton> */}
				</div>
			</div>
		<div className="sepdean_bottom">
				<div className="row no-gutter">
					<div className="col-md-6">
						<div className="">
						<h1>DIRECTIONS</h1>
						<h2> <b><i className="fa fa-home"></i></b> THE DEAN <span> <a href="https://maps.apple.com/maps?daddr=37.404162,-122.111566" target="_blank">480 San Antonio Rd, Mountain View, CA 94040</a></span> </h2>		
						<h2>  <b><i className="fa fa-map-marker"></i></b> MTN. VIEW 24 HOUR FITNESS SUPER-SPORT <span><a href="https://maps.apple.com/maps?daddr=37.4038284,-122.1103281" target="_blank"> 2535 California Street, Mountain View, CA 94040</a></span> </h2>
						</div>
						<div className="clearfix"></div>
						<div className="mapcircle-ame">
							<div className="lc_map">
								<div id="map-4" className=""></div>
							</div>
						</div>
					</div>
					<div className="col-md-6 red_bg">
						<h2><i>Head southeast toward San Antonio Rd</i></h2>
						<ul>
							<li> Turn <b>left</b>  onto <b>San Antonio Rd</b> </li>
							<li> Turn <b>right</b> before Bank of America Financial Center</li>
							<li>Turn <b>left</b> onto <b>San Antonio Road</b> </li>
							<li>Turn <b>right</b>  </li>
						</ul>
						
						<h2>
					
							<i><strong>*NOTE</strong>: You are looking for the 24 Hour Fitness <strong>Super Sport</strong> On California Drive, not the 24 Hour Fitness on Showers Drive.
	 </i>
	 <div id="myDiv2"></div>
	 </h2>

					</div>
				</div>
			</div>
		




			<div className="container" >
				<div className="row banner_bootm">
				<div className="col-md-6">
					<div className="gallery">
						<div className="itam">
							<h1>MADRONE <br/> APARTMENTS</h1>

						</div>
						<div className="itam">
							<p>The Dean’s sister property in Mountain View is available 
to our guests living at The Dean!  While The Dean’s fitness center, swimming pools, spas, and other 
amenities are still being constructed, take a short drive to Madrone Apartments in Mountain View where 
you can enjoy the fitness center, swimming pool, barbeques, fire pit, and more</p>
<p>Access to Madrone requires an electronic key fob, so contact us today if you are interested in taking 
advantage of this alternative during your stay with us.</p>
						</div>
						{/* <div className="itam">
							<button className="btn"> Visit MADRONE </button>
						</div> */}

					</div>
				</div>
				<div className="col-md-6">

					<div className="row-imagesame">
						<img src="assets/images/DeanImg/ds1.png" className="width100per" alt="" />
						<img src="assets/images/DeanImg/ds2.png" className="width100per" alt="" />
						<img src="assets/images/DeanImg/ds3.png" className="width100per" alt="" />
						<img src="assets/images/DeanImg/ds4.png" className="width100per" alt="" />
					</div>
			
				</div>
			</div>
			</div>
            <div className="opnsep-center" >
				<img src="assets/images/DeanImg/ds-bg.png" className="width100per" alt="" />
				<div className="opnsep-centerinner">
					<div className="row no-pad no-gutter">
						<div className="col-md-6 col-sm-6 first-row">
								<h1>MADRONE <br /> AMENITIES</h1>
								<div>
									<img src="assets/images/DeanImg/swimming-pool.svg" alt="" />
									<img src="assets/images/DeanImg/pawprint.svg" alt="" />
									<img src="assets/images/DeanImg/washing-machine.svg" alt="" />
								</div>
						</div>
						<div className="col-md-6 col-sm-6">
							<ul>
								<li> FITNESS CENTER</li>
								<li> HEATED POOL</li>
								<li> 100% NON-SMOKING</li>
								<li>  LUSH, PARK-LIKE GROUNDS</li>
								<li>BBQ & FIREPIT</li>
								<li> CLUBHOUSE WITH KITCHEN </li>
								<li>FOOSEBALL TABLE </li>
								<li> RESERVED & GUEST PARKING</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div>
			<img src="assets/images/DeanImg/mapdean.jpeg" alt="" className="width100per" />	 
		</div>


			<div className="sepdean_bottom">
				<div className="row no-gutter">
					<div className="col-md-6">
						<div className="">
						<h1>DIRECTIONS</h1>
						<h2> <b><i className="fa fa-home"></i></b> THE DEAN <span><a href="https://maps.apple.com/maps?daddr=37.404162,-122.111566" target="_blank">480 San Antonio Rd, Mountain View, CA 94040</a></span> </h2>		
						<h2> <b><i className="fa fa-map-marker"></i></b> MADRONE APARTMENTS <span> <a href="https://maps.apple.com/maps?daddr=37.4038284,-122.097297" target="_blank"> 111 N. Rengstorff Ave, Mountain View, CA 94043</a> </span> </h2>
						</div>
						<div className="clearfix"></div>
						<div className="mapcircle-ame">
							<div className="lc_map">
								<div id="map" className=""></div>
							</div>
						</div>
				
					</div>
					<div className="col-md-6 red_bg">
						<h2><i>Head southeast toward San Antonio Rd</i></h2>
						<ul >
							<li> Turn <b>right</b>  onto <b>San Antonio Rd</b> </li>
							<li> Make a <b>U-turn</b> at Fayette Dr </li>
							<li>Take the <b>Central Expressway </b> ramp to Mountain View</li>
							<li > Merge onto <b>Central Expy</b> </li>
							<li>Turn <b>left</b> onto <b>Rengstorff Ave</b> </li>
							<li > Turn <b>right</b> ; partial restricted usage road</li>
							<li > Turn <b>right</b>; restricted usage road </li>
						</ul>
					
						<h2 ><i>Destination will be on the left</i></h2>
					</div>

					
				</div>
			</div>
            </div>
            <SubFooter/>
            <Footer/>
          </div>
	  )
	  }
  }
	export default DeanAmenities;
	

	