/* Importing the node modules, child components, services and controllers used 
   inside ModalVideo component */ 
   /* ModalVideo Component initialization */
   class ModalVideo extends React.Component {
       /* Initializing objects of its ModalVideo class */
       constructor(props) {
           super(props);
       }
       /* It is invoked immediately after a component is mounted */
       componentDidMount() {
            $(document).ready(function() {            
                $("#dsvideo-modal").modal('hide');
                $('#dsvideo-modal').on('hidden.bs.modal', function () {
                    $("#dsvideo-modal iframe").attr("src", $("#dsvideo-modal iframe").attr("src"));
                    var video = $('#DS-background')[0];
                    video.src = this.props.floor3dData.url;    
                    video.play();
                });
            });
       }
       /* It is invoked to return html content */
       render() {
           return (
                <div className="modal animated zoomIn popup-video" id="3dView" role="dialog" >
                    <div className="modal-dialog">
                        <div className="modal-content rm-border-radius">
                            <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" data-dismiss="modal" onClick={this.props.closeModal}>
                            <span>×</span>
                            </button>
                            </div>
                            <div className="modal-body">
                            <iframe src={this.props.floor3dData.url} width="100%" height="100%" frameBorder="0" allowFullScreen/>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>
           );
       }
   }
   export default ModalVideo;
   