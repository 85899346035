/* Importing the node modules, child components, services and controllers used 
   inside AdidasHeader component */
   import {Link} from 'react-router-dom';
   import $ from "jquery";
   import MainController from '../controller/mainController';
   /* AdidasHeader Component initialization */
   class AdidasHeader extends React.Component {
       /* Initializing objects of its AdidasHeader class */
       constructor(props) {
           super(props)
           /* AdidasHeader Component State variables Initialization */
           this.state = {
               name: '',
               company: '',
               location: '',
               phone: '',
               email: '',
               bookingDetails: {},
               BookingData: {}
           }   
           this.selection = this.selection.bind(this);
           this.internQuestionnaire = this.internQuestionnaire.bind(this);
       }
       /* It is invoked immediately after a component is mounted */
       componentDidMount() {
           MainController.removeClassOnNavCollapse();
       }
       /* To uncheck the input fields type radio */
       internQuestionnaire(){
        $('input[name="Yes"]').prop('checked', false);
        $('input[name="No"]').prop('checked', false);
       }
       /* Condition checking for whether you want to proceed or not */
       selection(e){
            if(e.target.value == 'Yes'){
                location.href = '/company/adidas-internship/questionnaire';
            }else{
                jQuery(function ($) {
                    setTimeout(function () {
                        $('#internship-confirmation').modal('hide');
                    }.bind(this), 500);
                }.bind(this));
            }
       }
       /* It is invoked to return html content */  
       render() {
           return (
            <div>
                <div>
            <div className="profile_submenu">
                <div className="container">
                    <nav className="navbar navbar-inverse">
                        <div className="navbar-header">
                            <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#guest-login" aria-expanded="false">
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                        </div>
                        <div className="collapse navbar-collapse" id="guest-login">
                            <ul className="nav navbar-nav">
                                <li><Link to="/company/adidas-internship/home">Home</Link></li>
                                <li><Link to="/company/adidas-internship/communities">Communities</Link></li>
                                <li><Link to="/company/adidas-internship/apartment-tour">Apartment Tour</Link></li>
                                <li><Link to="/company/adidas-internship/faq">FAQ's</Link></li>
                                <li><a href="#" data-toggle="modal" data-target="#internship-confirmation" onClick={this.internQuestionnaire}>Intern Questionnaire</a></li>
                                <li><Link to="/company/adidas-internship/contact-us">Contact Us</Link></li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div> 
            <div className="modal fade intern-questionnaire" id="internship-confirmation" role="dialog">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content rm-border-radius">
                            <div className="modal-header">
                            <button type="button" className="close btn" data-dismiss="modal" aria-label="">
                            <i className="bi bi-x"></i>
                            </button>
                            </div>
                            <div className="modal-body">
                            <p>By Submitting an intern questionnaire to SuiteAmerica, you understand the SuiteAmerica will match you to the best of their abilities with other interns in the Adidas Intern Program. Matching and apartment offers are on a first come, first serve basis and the rates
                                are a flat, all-inclusive fee listed below (also listed under the communities section).
                            </p>
                            <h4>Community Rates :</h4>
                            <div className="downtown-text-list">
                            <h5>Downtown Communities</h5>
                            <p><i>We offer two options to choose from in the downtown Portland area.</i></p>
                            <p>2x2 (2 Occupants): $126 per person/day</p>
                            <p>2x2 (4 Occupants): $65 per person/day</p>
                            </div>
                            <p>
                            SuiteAmerica will make every effort to match you based on your preference. Once SuiteAmerica sends you an apartment offer, you will
                            need to respond back accepting the option and once rental agreements have been completed by you
                            and your roommates, the apartment will be secured and set up for your arrival.
                            </p>
                            <p><strong>Please Note: </strong>Once rental paperwork hase been completed, you are obligated to the suite and
                                move-in/move-out dates may not be altered.
                            </p>
                            <div className="terms-wrap">
                            <h3>Have your read and agree to the terms?</h3>
                            <div className="terms-form">

                            <form role="form" method="post">
                            <label className="custom_checkbox checkbox-inline"><span>Yes</span>
                             <input name="Yes" value="on" type="radio" value="Yes" 
                             onChange={(e) => this.selection(e)}/>
                             <span className="checkmark"></span>
                             </label>
                             <label className="custom_checkbox checkbox-inline"><span>No</span>
                             <input name="No" value="on" type="radio" value="No" 
                             onChange={(e) => this.selection(e)}/>
                             <span className="checkmark"></span>
                             </label>
                            </form>
                            </div>
                            </div>
                            <div className="clearfix"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    <div className='subHeaderHeight'></div>
    </div>
           );
       }
   }
   export default AdidasHeader;