/* Importing the node modules, child components, services and controllers used 
   inside FiveStarLanding component */   
   import $ from "jquery";
   import AppController from "../../controller/appController";
   /* FiveStarLanding Component initialization */
   class IntranetForgotPassword extends React.Component{
     /* Initializing objects of its FiveStarLanding class */
       constructor(props) {
       super(props)
       /* FiveStarLanding Component State variables Initialization */
       this.state={
         driversList: [],
         driverid: '',
         password: '',
         message: '',
         authentication: [],
         email: '',
         loader:false,
         color: '',
         emailerr: '',
         forgotResult:{}
       }
       this.forgotPassword = this.forgotPassword.bind(this);
       this.setValue = this.setValue.bind(this);
       }
       /* It is invoked immediately after a component is mounted */    
        async componentDidMount() {
           $('html, body').animate({scrollTop: 0});
           AppController.stickyTitles();
        }
   
   /* To change the selected input field value */
   setValue(field, e) {
     var object = {};
     object[field] = e.target.value;
     this.setState(object);
   }
   
   /* Authentication checking before login to the five star application */
   clearData(){
     this.setState({emailerr: '', color: '',loader:false,email:''})
   }
   
     async forgotPassword(e){
        e.preventDefault();
       var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
       if (this.state.email != '' && reg.test(this.state.email)) {
        const loginDetails={
            email:this.state.email
        } 
        await fetch("http://192.168.1.165:8085/api/Account/ForgotPassword?Email="+loginDetails.email+"",{ 
            method: 'POST', 
            //mode:'no-cors',
            //headers: new Headers({ 'Access-Control-Allow-Origin':'*' })
           })
           .then(res => res.json()) 
           .catch(error => this.setState({forgotResult:error})) 
           .then(response => this.setState({forgotResult:response}));
           if(this.state.forgotResult.Message){

            this.setState({emailerr:'Invalid Login',color:'red'})  
            }
            else{
              this.setState({emailerr:'Mail Sent to Your Email',color:'red'})  
            }

//       
   }else{
     this.setState({emailerr: 'Enter Valid Email', color: 'red',loader:false})
   }
     
     }
   /* It is invoked to return html content */
   render(){
       return(
         <div id="pages_5star" style={{'background': '#f5f5f5'}}>
         <div className="login_main">
         <div className="container-fluid">

   
   <div className="login_star">
   <img src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Logo.png"} alt="" />
    <div className="col-sm-12">
   
    {this.state.emailerr
      ? <span
          style={{
          color: this.state.color,
          textAlign: 'right'
        }}>{this.state.emailerr}</span>
      : ''}
   </div>
    <form onSubmit={(e) => this.forgotPassword(e)}>
         <div className="f-contact-form-style">
         <div className="form-group">
         <input type="text" className="form-control" id="exampleInputPassword1" placeholder="Email" value={this.state.email} onChange={(e) => this.setValue('email', e)}/>
         </div>
         {this.state.loader ? <div className="panel-body_loader"> <span style={{fontSize: '20px', width: '100%', margin: 'auto', textAlign: 'center'}}><i className="fa fa-spinner fa-spin"/> please wait ...!</span>
                 
                         </div>:
         <div className="mrg-btm-20"><button type="submit" className="btn"> Submit</button></div>
         }
         </div>
    </form>
   </div>
        </div>
             
        </div>

      </div>
                  
       );
   }
   }
   export default IntranetForgotPassword;