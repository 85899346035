/* Importing the node modules, child components, services and controllers used 
   inside WorldBankHeader component */
   import {Link} from 'react-router-dom';
   import MainController from "../controller/mainController";
   /* WorldBankHeader Component initialization */
   class WorldBankHeader extends React.Component {
       /* Initializing objects of its WorldBankHeader class */
       constructor(props) {
           super(props);
       }
       /* It is invoked immediately after a component is mounted */
       componentDidMount() {
           MainController.removeClassOnNavCollapse();
       }
       /* It is invoked to return html content */  
       render() {          
           return (  
               <div>
            <div className="profile_submenu">
                <div className="pdd-x-15 wbheader">
                    <nav className="navbar navbar-inverse">
                        <div className="navbar-header">
                            <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#guest-login" aria-expanded="false">
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>                            
                        </div>
                        <div className="collapse navbar-collapse" id="guest-login">
                            <ul className="nav navbar-nav">
                                <li><Link to="/world-bank/home">Home</Link></li>
                                <li><Link to="/world-bank/whats-included">What’s Included</Link></li>
                                <li><Link to="/world-bank/added-value">Added Value</Link></li>
                                <li><Link to="/world-bank/destination-services">Destination Services</Link></li>
                                <li><Link to="/world-bank/online-management">Online Management</Link></li>
                                <li><Link to="/world-bank/locations">Locations</Link></li>
                                <li><Link to="/world-bank/transportation">Public Transportation</Link></li>
                                <li><Link to="/world-bank/requestReservation">Reservation Form</Link></li>
                                <li><Link to="/world-bank/contact-us">Contact Us</Link></li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>   
             <div className='subHeaderHeight'></div>
             </div>
           );
       }
   }
   export default WorldBankHeader;