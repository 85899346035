/* Importing the node modules, child components, services and controllers used 
   inside MapView component */
   import MapController from "./mapController";
   /* MapView Component initialization */
   export class MapView extends React.Component {
     /* Initializing objects of its MapView class */
     constructor(props) {
       super(props);
       /* MapView Component State variables Initialization */
       this.state = {
         communityData: [],
         activeMarker: {},
         selectedPlace: {},
         showingInfoWindow: false,
         localSearchData: false
       }
       this.onMarkerClick = this.onMarkerClick.bind(this);
       this.onMapClicked = this.onMapClicked.bind(this);
     }
     /* It is invoked immediately before mounting occurs */
     async componentDidMount() {
       MapController.initMap(this.props.communityData, '', this.props.companyName);
     }
     /* Marker selection to active */
     onMarkerClick(props, marker, e) {
       this.setState({selectedPlace: props, activeMarker: marker, showingInfoWindow: true});
     }
     /* To hide the information window */
     onMapClicked(props) {
       if (this.state.showingInfoWindow) {
         this.setState({showingInfoWindow: false, activeMarker: null})
       }
     }
     /* To fetch the google maps places */
     fetchPlaces(mapProps, map) {
       const {google} = mapProps;
       const service = new google
         .maps
         .places
         .PlacesService(map);
   console.log(service)
     }
   /* It is invoked to return html content */
     render() {
       return (
         <div className="lc_map">
           {this.props.communityData.length > 0 ?
             <div
               id={'map'}
               className="map-alignment"></div>: <h1 style={{'textAlign': 'center','color': 'red'}}>No Results Found</h1>}
         </div>      
   
       );
     }
   }
   export default MapView
   
   