import React from "react";

function OnDemandFooter() {
  return (
    <div className="bannerBottomView">
      <div className="item">
        <h4>Live where you want, when you want</h4>
        <p>
          From traveling healthcare professionals to construction workers,
          business consultants, digital nomads, and more — we offer furnished
          temporary housing that matches your needs.
        </p>
      </div>
      <div className="item">
        <h4>Nearly unlimited locations</h4>
        <p>
          {" "}
          We provide housing in almost any location, serving 20,000 cities,
          towns, and villages in the United States.
        </p>
      </div>

      <div className="item">
        <h4>Streamlined process</h4>
        <p>
          Simply let us know your needs and we'll do the rest, so you can show
          up, unpack your bags, and start living.
        </p>
      </div>
      <div className="item">
        <h4>Completely customizable</h4>
        <p>
          Your home is tailored to your specific preferences, including
          location, size, budget and more.
        </p>
      </div>
    </div>
  );
}

export default OnDemandFooter;
