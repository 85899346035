import React from "react";

const CommunityImagesWhiteModal = ({ community_name, communityALLImages }) => {
  return (
    <div
      className="modal modal-fullscreen-xl modal fade animated tab-modal guest-Ui SAweb_modalPoup"
      id="fullmodel-Carousel1"
      tabIndex={-1}
      data-backdrop="static"
      data-keyboard="false"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content propertyGallery">
          <div className="modal-header border-0 p-0">
            <button
              type="button"
              className="btn close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i className="bi bi-x"></i>
            </button>
            <h2>{community_name}</h2>
          </div>
          <div className="modal-body">
            <div></div>
            <div>
              <ul>
                {communityALLImages.map((row, index) => {
                  return (
                    <li key={index}>
                      <img src={row} alt="" width="100%" />
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommunityImagesWhiteModal;
