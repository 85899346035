/* Importing the node modules, child components, services and controllers used 
   inside OurCommunities component */
import $ from "jquery";
import AppController from "../../controller/appController";
import { Link } from "react-router-dom";
import TravelBookingFooter from "../../common/travelBookingFooter";
import BookNow from "./booknow";
/* OurCommunities Component initialization */
class OurCommunities extends React.Component {
  /* Initializing objects of its OurCommunities class */
  constructor(props) {
    super(props);
    this.state = {
      communities: props.communities,
      communityImages: [],
      index: -1,
      filterCommunities: props.communities,
      loading: props.loading,
      communityObject: {},
      adminDailyRates: "223",
      indexData: 0,
      status: true,
      BookedType: "STU",
      communityRoomSelected: [],
    };
    this.getPhotos = this.getPhotos.bind(this);
    this.rateChange = this.rateChange.bind(this);
    this.getDetails = this.getDetails.bind(this);
    this.bookNowData = this.bookNowData.bind(this);
    this.galleryClose = this.galleryClose.bind(this);
  }
  /* It is invoked immediately before mounting occurs */
  componentWillMount() {
    $("html, body").animate({ scrollTop: 0 });
    AppController.stickyTitles();
  }
  async getDetails(row) {
    let program = JSON.stringify(this.props.program);
    localStorage.setItem("starbucksprogram", this.props.program);
    localStorage.setItem("starbuckscommunity_id", row.community_id);
    localStorage.setItem("starbuckstravelCommunityData", JSON.stringify(row));

    localStorage.setItem("starbucksreqDays", this.props.reqDays);
    localStorage.setItem("starbucksrecord_limit", this.props.record_limit);
    localStorage.setItem("starbucksradius", this.props.radius);
    localStorage.setItem("starbuckslatitude", this.props.latitude);
    localStorage.setItem("starbuckslongitude", this.props.longitude);
    if (
      this.props.travelerObJect != undefined ||
      Object.keys(this.props.travelerObJect).length != 0
    ) {
      localStorage.setItem("starbuckspnrId", this.props.travelerObJect.PNR);
    }
    this.props.propsData.history.push("/umpqua-bank-booking-details");
  }

  async getPhotos(commImages, ind) {
    this.setState({ index: ind });
    await this.setState({ communityImages: commImages });
    await AppController.travelBooking();
  }
  bookNowData(row, rate, check) {
    row["communityroomSelected"] = [rate];
    this.setState({ communityObject: row });
  }
  /* It is invoked to return html content */
  async moreInfo() {
    this.props.getMore();
  }
  async galleryClose() {
    await this.setState({ index: -1 });
    await this.setState({ communityImages: [] });
  }
  async rateChange(rate, row, BookedType) {
    const communityIndex = _.findIndex(
      this.state.communityRoomSelected,
      function (o) {
        return o.community_id == row.community_id;
      }
    );
    const communityRateIndex = _.findIndex(
      this.state.communityRoomSelected,
      function (o) {
        return o.community_id == row.community_id && o.BookedType == BookedType;
      }
    );
    await this.setState({
      communityRoomSelected:
        communityIndex < 0
          ? this.state.communityRoomSelected
          : update(this.state.communityRoomSelected, {
              $splice: [[communityIndex, 1]],
            }),
    });
    await this.setState({
      communityRoomSelected:
        communityRateIndex < 0
          ? _.concat(this.state.communityRoomSelected, {
              adminDailyRates: rate,
              status: false,
              community_id: row.community_id,
              BookedType,
            })
          : this.state.communityRoomSelected,
    });
  }
  render() {
    const userLoginEmailEncode = localStorage.getItem("umpqua-email");
    const userLoginEmail =
      userLoginEmailEncode != undefined || userLoginEmailEncode != null
        ? "admin"
        : "guest";
    var checkadminprogram =
      (userLoginEmail == "admin" || userLoginEmail == "guest") &&
      (this.props.program.toString() == "General" ||
        this.props.program.toString() == "Apple Travel - 2")
        ? true
        : false;

    return (
      <div>
        <div className="clearfix"></div>
        <div className="container travel-sec">
          <div className="text-center col-md-8 col-md-offset-2 community_header mrg-btm-50">
            <h2>Our Communities</h2>
            <p>
              Preferred properties, selected especially for the Starbucks
              Traveler
            </p>
          </div>
          <div className="clearfix"></div>

          {this.props.loading ? (
            <div className="panel-body_loader">
              {" "}
              <span
                style={{
                  fontSize: "20px",
                  width: "100%",
                  margin: "auto",
                  textAlign: "center",
                }}
              >
                <i className="fa fa-spinner fa-spin" /> please wait ...!
              </span>
            </div>
          ) : (
            <div>
              <div className="row startbucks_cards">
                {this.props.communities.map((row, index) => {
                  var commImages;
                  if (
                    row.compleximages != null &&
                    row.interiorimages != null &&
                    row.interiorimages != ""
                  ) {
                    commImages = (
                      row.compleximages +
                      "," +
                      row.interiorimages
                    ).split(",");
                  } else if (
                    row.compleximages != null &&
                    (row.interiorimages == null || row.interiorimages == "")
                  ) {
                    commImages = row.compleximages.split(",");
                  } else if (
                    row.compleximages == null &&
                    row.interiorimages != null
                  ) {
                    commImages = row.interiorimages.split(",");
                  } else {
                    commImages = [];
                  }
                  let defaultObject;

                  if (row.STU > 0) {
                    defaultObject = {
                      adminDailyRates: row.sizeSTURate,
                      status: false,
                      community_id: row.community_id,
                      BookedType: "STU",
                    };
                  } else if (row.Avail1X > 0) {
                    defaultObject = {
                      adminDailyRates: row.size1xRate,
                      status: false,
                      community_id: row.community_id,
                      BookedType: "1X",
                    };
                  } else if (row.Avail2X > 0) {
                    defaultObject = {
                      adminDailyRates: row.size2xRate,
                      status: false,
                      community_id: row.community_id,
                      BookedType: "2X",
                    };
                  } else if (row.Avail3X > 0) {
                    defaultObject = {
                      adminDailyRates: row.size3xRate,
                      status: false,
                      community_id: row.community_id,
                      BookedType: "3X",
                    };
                  } else {
                    defaultObject = {
                      adminDailyRates: row.sizeSTURate,
                      status: false,
                      community_id: row.community_id,
                      BookedType: "STU",
                    };
                  }

                  let STU = _.find(
                    this.state.communityRoomSelected,
                    function (o) {
                      return (
                        o.community_id == row.community_id &&
                        o.BookedType == "STU"
                      );
                    }
                  );
                  const oneBed = _.find(
                    this.state.communityRoomSelected,
                    function (o) {
                      return (
                        o.community_id == row.community_id &&
                        o.BookedType == "1X"
                      );
                    }
                  );
                  const twoBed = _.find(
                    this.state.communityRoomSelected,
                    function (o) {
                      return (
                        o.community_id == row.community_id &&
                        o.BookedType == "2X"
                      );
                    }
                  );
                  const threeBed = _.find(
                    this.state.communityRoomSelected,
                    function (o) {
                      return (
                        o.community_id == row.community_id &&
                        o.BookedType == "3X"
                      );
                    }
                  );
                  let selectedBed = _.find(
                    this.state.communityRoomSelected,
                    function (o) {
                      return o.community_id == row.community_id;
                    }
                  );
                  // selectedBed== undefined? (STU=defaultObject) : ""
                  selectedBed =
                    selectedBed == undefined ? defaultObject : selectedBed;
                  return (
                    <div className="col-md-4 col-sm-6" key={index}>
                      <div className="community-item">
                        <div className="img-div">
                          <img src={row.image_name} alt="" />
                          <div className="img-hover-dtls">
                            <label
                              className="community-photos pull-left"
                              htmlFor={"op" + index}
                            >
                              <ul
                                onClick={() =>
                                  this.getPhotos(commImages, index)
                                }
                              >
                                {commImages.map((image, comIndex) => {
                                  if (comIndex < 2) {
                                    return (
                                      <li key={comIndex}>
                                        <img src={image} alt="" />
                                      </li>
                                    );
                                  }
                                })}
                              </ul>
                              <span>{commImages.length} Photos</span>
                            </label>
                            {row.walkScore != "" &&
                            row.walkScore != null &&
                            row.walkScore != undefined ? (
                              <div className="walkscore pull-right">
                                <div className="arrow_box">
                                  {row.walkScore == "" ? 0 : row.walkScore}
                                </div>
                                <span>Walk Score</span>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="community-item-dtls">
                          <div className="community-head">
                            <h5>{row.community_name}</h5>
                          </div>
                          <p>{row.address}</p>
                          <span className="area">
                            <strong>{row.city_name},</strong>
                            <br />
                            {row.state_name}
                          </span>

                          <span className="distance">
                            <img
                              src="https://s3-us-west-1.amazonaws.com/suiteam-website/Travel-Booking/SuiteAmerica-Travel-Booking-Map.png"
                              alt=""
                            />
                            <strong>
                              {row.distance == "" ? 0 : row.distance}
                            </strong>{" "}
                            Miles away
                          </span>
                          <div className="clearfix"></div>
                          {userLoginEmail == "admin" &&
                          (this.props.program.toString() == "General" ||
                            this.props.program.toString() ==
                              "Apple Travel - 2") ? (
                            <ul className="bedroom_list list-inline">
                              {row.STU == 0 ? (
                                ""
                              ) : (
                                <li
                                  className="active"
                                  onClick={
                                    row.STU <= 0
                                      ? ""
                                      : () =>
                                          this.rateChange(
                                            row.sizeSTURate,
                                            row,
                                            selectedBed.BookedType == "STU"
                                              ? ""
                                              : "STU"
                                          )
                                  }
                                >
                                  <img
                                    src={
                                      "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/studiobed" +
                                      (selectedBed.BookedType == "STU"
                                        ? "-red"
                                        : "") +
                                      ".png"
                                    }
                                  />{" "}
                                  <p>Studio </p>
                                  <span className="badge studio">
                                    {row.STU}
                                  </span>
                                </li>
                              )}
                              {row.Avail1X == 0 ? (
                                ""
                              ) : (
                                <li
                                  onClick={
                                    row.Avail1X <= 0
                                      ? ""
                                      : () =>
                                          this.rateChange(
                                            row.size1xRate,
                                            row,
                                            "1X"
                                          )
                                  }
                                >
                                  <img
                                    src={
                                      "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/singlebedroom" +
                                      (selectedBed.BookedType == "1X"
                                        ? "-red"
                                        : "") +
                                      ".png"
                                    }
                                  />{" "}
                                  <p>1 Bedroom</p>
                                  <span className="badge">{row.Avail1X}</span>
                                </li>
                              )}
                              {row.Avail2X == 0 ? (
                                ""
                              ) : (
                                <li
                                  onClick={
                                    row.Avail2X <= 0
                                      ? ""
                                      : () =>
                                          this.rateChange(
                                            row.size2xRate,
                                            row,
                                            "2X"
                                          )
                                  }
                                >
                                  <img
                                    src={
                                      "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/doublebedroom" +
                                      (selectedBed.BookedType == "2X"
                                        ? "-red"
                                        : "") +
                                      ".png"
                                    }
                                  />{" "}
                                  <p>2 Bedroom</p>
                                  <span className="badge">{row.Avail2X}</span>
                                </li>
                              )}
                              {row.Avail3X == 0 ? (
                                ""
                              ) : (
                                <li
                                  onClick={
                                    row.Avail3X <= 0
                                      ? ""
                                      : () =>
                                          this.rateChange(
                                            row.size3xRate,
                                            row,
                                            "3X"
                                          )
                                  }
                                >
                                  <img
                                    src={
                                      "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/triplebedroom" +
                                      (selectedBed.BookedType == "3X"
                                        ? "-red"
                                        : "") +
                                      ".png"
                                    }
                                  />{" "}
                                  <p>3 Bedroom</p>
                                  <span className="badge">{row.Avail3X}</span>
                                </li>
                              )}
                            </ul>
                          ) : userLoginEmail == "admin" ? (
                            <ul className="bedroom_list list-inline">
                              {row.STU == 0 ? (
                                ""
                              ) : (
                                <li>
                                  <img
                                    src={
                                      "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/studiobed.png"
                                    }
                                  />{" "}
                                  <p>Studio </p>
                                  <span className="badge studio">
                                    {row.STU}
                                  </span>
                                </li>
                              )}
                              {row.Avail1X == 0 ? (
                                ""
                              ) : (
                                <li>
                                  <img
                                    src={
                                      "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/singlebedroom.png"
                                    }
                                  />{" "}
                                  <p>1 Bedroom</p>
                                  <span className="badge">{row.Avail1X}</span>
                                </li>
                              )}
                              {row.Avail2X == 0 ? (
                                ""
                              ) : (
                                <li>
                                  <img
                                    src={
                                      "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/doublebedroom.png"
                                    }
                                  />{" "}
                                  <p>2 Bedroom</p>
                                  <span className="badge">{row.Avail2X}</span>
                                </li>
                              )}
                              {row.Avail3X == 0 ? (
                                ""
                              ) : (
                                <li>
                                  <img
                                    src={
                                      "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/triplebedroom.png"
                                    }
                                  />{" "}
                                  <p>3 Bedroom</p>
                                  <span className="badge">{row.Avail3X}</span>
                                </li>
                              )}
                            </ul>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="community-footer">
                          <a onClick={() => this.getDetails(row)}>More Info</a>
                          {/* <span> Book Now <span>$ {selectedBed.adminDailyRates} / Day </span></span> */}
                          <Link
                            to="#"
                            data-toggle="modal"
                            data-target="#travelbooknow"
                            className="btn btn-bordered pull-right"
                            onClick={() =>
                              this.bookNowData(
                                row,
                                selectedBed,
                                checkadminprogram
                              )
                            }
                          >
                            <img
                              src="https://s3-us-west-1.amazonaws.com/suiteam-website/Travel-Booking/SuiteAmerica-Travel-Booking-Booknow-Image.png"
                              alt=""
                            />{" "}
                            <span> Book Now </span>
                          </Link>
                          <div className="clearfix"></div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="clearfix"></div>
            </div>
          )}
        </div>

        <TravelBookingFooter />
        <BookNow
          program={this.props.program}
          latitude={this.props.latitude}
          longitude={this.props.longitude}
          record_limit={this.props.record_limit}
          reqDays={this.props.reqDays}
          radius={this.props.radius}
          communityObject={this.state.communityObject}
          travelObj={this.props.travelerObJect}
        />
        <div id="login-modal-open"></div>
        <div id="register-modal-open"></div>
        {this.state.communityImages.length > 0 ? (
          <div className="custom-galery starbucksSlider">
            <input
              className="no-icheck gal"
              type="checkbox"
              id={"op" + this.state.index}
            />
            <div className="lower"></div>
            <div className="overlay overlay-hugeinc">
              <label
                htmlFor={"op" + this.state.index}
                onClick={this.galleryClose}
              ></label>
              <nav>
                <div className="owl-carousel owl-demo-3">
                  {this.state.communityImages.map((row, index) => {
                    return (
                      <div
                        key={index}
                        className="item"
                        style={{ backgroundImage: "url(" + row + ")" }}
                      ></div>
                    );
                  })}
                </div>
              </nav>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}
export default OurCommunities;
