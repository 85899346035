/* Importing the node modules, child components, services and controllers used 
   inside RequestReservation component */
   import $ from "jquery";
   import DatePicker from 'react-datepicker'
   import moment from 'moment'
   import AppController from "../../../controller/appController";
   import SubFooter from "../../../common/subFooter";
   import IntranetEmailService from "../../../../services/intranetEmailService";
   import IntranetServicesApi from "../../../../services/intranetServicesApi";
   import EmployeeHeader from '../../../common/employeeHeader';
   import Footer from '../../../common/footer'; 
   import ToFromData from './toFromData';
   import SuccessModal from '../../../common/successModal';

   /* RequestReservation Component initialization */
   class RequestReservation extends React.Component {
       /* Initializing objects of its RequestReservation class */
       constructor(props) {
           super(props)
           this.state={
               orderNumber:'',
               toSelect: '',
               toDropDown: ToFromData.toDropDown,
               relo: '',
               reloDropDown: ToFromData.reloDropDown,
               scheduleCopy:'',                 
               scheduleCopy1: '',
               scheduleCopyDropDown: ToFromData.scheduleCopyDropDown,
               sendCopyTo: '',
               sendCopyToDropDown: ToFromData.sendCopyToDropDown,
               sendToMeet_Greet: '',
               sendToMeet_GreetDropDown: ['Yes','No'],
               sendToJulie: '',
               sendToJulieDropDown: ['Yes','No'],
               masterNo: '',
               orderNo: '',
               company: '',
               guestName: '',
               guestPhone: '',
               dialCode: '',
               community: '',
               unitNumber: '',
               partners:'',
               sharedApartment:'',
               sharedApartmentDropDown:['Yes','No'],
               occupyApartment:'',
               occupyApartmentDropDown:['Yes','No'],
               deliveryDate: moment().tz("America/Los_Angeles").format('YYYY-MM-DD HH:mm:ss'),
               moveInDate:  moment().tz("America/Los_Angeles").format('YYYY-MM-DD HH:mm:ss'),
               inspectionPhotos: '',
               sendTo: '',
               inspectionPhotosDropDown: ['Yes','No'],
               vipLevel: '',
               vipComments: '',
               bedRoom1: '',
               bedRoom2: '',
               bedRoom3: '',
               bedRoom4: '',
               furniture: '',
               HouseWares: '',
               HouseWaresDropDown: ToFromData.HouseWaresDropDown,
               furnitureComments: '',
               nonRefundablePetFee: '',
               notifyCommunity: '',
               notifyCommunityDropDown: ['Yes','No'],
               billTo: '',
               billToDropDown: ['Company','Guest'],
               petRent: '',
               petDeposit: '',
               chargeTax:'',
               chargeTaxDropDown: ['Yes','No'],
               houseKeeping: '',
               paidBy: '',
               paidByDropDown: ['Company','Guest'],
               entry: '',
               time: '',
               email: '',
               cellPhone: '',
               travelDetails: '',
               contactGuest: '',
               contactGuestDropDown: ['Yes','No'],
               notes:'',
               message: '',
               color:'',
               errorMessage:'',
               arrivalDateSelected: '',
               departureDateSelected: '',
               open:false,
               sendCopyMail: '',
               orderData:[],
               frequency:'',
               communityCity:''
           }
           this.setValue = this.setValue.bind(this);
           this.setNumber = this.setNumber.bind(this);
           this.ordersByOrderNo = this.ordersByOrderNo.bind(this);
           this.handleMoveIn = this.handleMoveIn.bind(this);
           this.handleMoveOut = this.handleMoveOut.bind(this);
           this.setNotes = this.setNotes.bind(this);
           this.serviceRequest = this.serviceRequest.bind(this);
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
           AppController.stickyTitles();
           AppController.carousel();
           AppController.updateAnimation();
           $('html, body').animate({scrollTop: 0});
        }
        setNumber(e) {
            const re = /^[0-9\b]+$/;
            if (e.target.value == '' || re.test(e.target.value)) {
                this.setState({guestPhone: e.target.value})
            }
        }
   
        async ordersByOrderNo(e){
            e.preventDefault();
            this.setState({errorMessage:'',color:''})
            var reg = /^([+-]?([0-9]*[.])?[0-9]+)$/;
            if(reg.test(this.state.orderNumber) ){
                this.setState({loader:true,open:false})
            const orderDetails={
                orderid:this.state.orderNumber
            }
            const orderData=await IntranetServicesApi.getOrderInformation(orderDetails)
            if(orderData!=null){
                $(document).ready(function () {
                    jQuery(function($) {
                        $("#toFormPhone").intlTelInput({
                            initialCountry: (orderData.GuestCountryCode != null && orderData.GuestCountryCode != undefined) ? orderData.GuestCountryCode : "US"
                        });
                    });
                });
                let NotesData = orderData.Notes != undefined ? orderData.Notes.split('###') : [];
                NotesData.pop()
                this.setState({orderData: NotesData,  open:true,loader:false,guestName:((orderData.GuestFirstName==null) ? '':orderData.GuestFirstName) +' '+((orderData.GuestLastName == null) ? '':orderData.GuestLastName),
                    guestPhone: (orderData.PhoneNo!=null && orderData.PhoneNo!=undefined )? orderData.PhoneNo:'',
                    dialCode: (orderData.GuestPhCountryCode!=null && orderData.GuestPhCountryCode!=undefined )? '+'+orderData.GuestPhCountryCode:'+1',
                    masterNo:(orderData.masterNo!=null && orderData.masterNo!=undefined )? orderData.masterNo:'',
                    orderNo:(orderData.orderNo!=null && orderData.orderNo!=undefined )? orderData.orderNo:'',
                    community:(orderData.communityName!=null && orderData.communityName!=undefined )? orderData.communityName:'',
                    communityCity:(orderData.city_name!=null && orderData.city_name!=undefined )? orderData.city_name:'',
                    company:(orderData.clientName!=null && orderData.clientName!=undefined )? orderData.clientName:'',
                    moveInDate: orderData.MoveInDate == null ? null: moment(orderData.MoveInDate).utc(),
                    deliveryDate: orderData.MoveOutDate == null ? null : moment(orderData.MoveOutDate).utc(),
                    furnitureComments:(orderData.furnitureComments!=null && orderData.furnitureComments!=undefined )? orderData.furnitureComments:'',
                    furniture:(orderData.furniture!=null && orderData.furniture!=undefined )? orderData.furniture:'',
                    bedRoom1:(orderData.Bed1SizeCode!=null && orderData.Bed1SizeCode!=undefined )? orderData.Bed1SizeCode:'',
                    bedRoom2:(orderData.Bed2SizeCode!=null && orderData.Bed2SizeCode!=undefined )? orderData.Bed2SizeCode:'',
                    bedRoom3:(orderData.Bed3SizeCode!=null && orderData.Bed3SizeCode!=undefined )? orderData.Bed3SizeCode:'',
                    bedRoom4:(orderData.Bed4SizeCode!=null && orderData.Bed4SizeCode!=undefined )? orderData.Bed4SizeCode:'',
                    petDeposit:(orderData.petDeposit!=null && orderData.petDeposit!=undefined )?orderData.petDeposit:'',
                    petRent:(orderData.petRent!=null && orderData.petRent!=undefined )? orderData.petRent:'',
                    entry:(orderData.Entry!=null && orderData.Entry!=undefined )? orderData.Entry:'',
                    unitNumber:(orderData.Suiteno!=null && orderData.Suiteno!=undefined )? orderData.Suiteno:'',
                    time:(orderData.CheckInTime!=null && orderData.CheckInTime!=undefined )? orderData.CheckInTime:'',
                    email:(orderData.Email!=null && orderData.Email!=undefined )? orderData.Email:'',
                    cellPhone:(orderData.PhoneNo!=null && orderData.PhoneNo!=undefined )? orderData.PhoneNo:'',
                    houseKeeping:orderData.housekeeping? 'Yes' : 'No',
                    frequency:(orderData.MAIDFREQ!=null && orderData.MAIDFREQ!=undefined )? orderData.MAIDFREQ:'',
                  })
            }
            else{
                this.setState({errorMessage:'Please Enter valid order Number',color:'red',loader:false})
            }
                      
        }
        else{
            this.setState({errorMessage:'Please Enter only Numbers',color:'red'})
        }
      
        }
        /* Form Details object creations  */

      setValue(field, e) {
          if (field == 'scheduleCopy'){
            const email = e.target.value
            const id = email.split('_____')
            this.setState({scheduleCopy1:id[0]})
          }
        
        this.setState({errorMessage:'',color:''})
            var object = {};
            object[field] = e.target.value;
            this.setState(object);
          }

           async handleMoveOut(date){
            await this.setState({deliveryDate: moment(date),
                deliveryDateSelected:date.format('YYYY-MM-DD HH:mm:ss') });
        }

        async handleMoveIn(date){
            await this.setState({moveInDate: moment(date),
                moveInDateSelected:date.format('YYYY-MM-DD HH:mm:ss') });
        }
       /* Service Request Details send to mail  */
        async serviceRequest(e){
            e.preventDefault();
        const _this = this
        var error = 0;
        if (this.state.toSelect == 'All Inc'){
        const validationFields=['partners','masterNo','orderNo', 'company', 'guestName', 'guestPhone','community', 'unitNumber', 'deliveryDate', 'moveInDate','bedRoom1','furniture','chargeTax','notifyCommunity','houseKeeping','entry']
        
        _.forEach(validationFields, function(value) {
           if(_this.state[value] == "" || _this.state[value] ==null || _this.state[value] =='null' || _this.state[value] ==undefined || _this.state[value] =='undefined'){
             error++;
           }
       });
    }else if (this.state.toSelect == 'Rotation Unit') {
       const validationFields=['occupyApartment','sharedApartment','masterNo','orderNo', 'company', 'guestName', 'guestPhone', 'community', 'unitNumber', 'deliveryDate', 'moveInDate','bedRoom1','notifyCommunity','furniture','chargeTax','houseKeeping','entry']
       
       _.forEach(validationFields, function(value) {
          if(_this.state[value] == "" || _this.state[value] ==null || _this.state[value] =='null' || _this.state[value] ==undefined || _this.state[value] =='undefined'){
            error++;
          }
      });
    }else {
        const validationFields=['masterNo','orderNo','company', 'guestName', 'guestPhone','communityCity', 'community','unitNumber','deliveryDate','moveInDate','bedRoom1','furniture','chargeTax','notifyCommunity','houseKeeping','entry','sendToMeet_Greet']
        
        _.forEach(validationFields, function(value) {
           if(_this.state[value] == "" || _this.state[value] ==null || _this.state[value] =='null' || _this.state[value] ==undefined || _this.state[value] =='undefined'){
             error++;
           }
       });
     }
       if(error == 0){
     const schedule = this.state.scheduleCopy1.split(',');
     var scheduleCopyOne=''
     var scheduleCopytwo=''
     if(schedule.length == 2){
        scheduleCopyOne = schedule[0];
        scheduleCopytwo = schedule[1]
     }else{
        scheduleCopyOne = schedule[0];
        scheduleCopytwo = ''
     }
     const sendCopy = this.state.sendCopyTo.split(',');
     var sendCopyOne=''
     var sendCopytwo=''
     if(sendCopy.length == 2){
        sendCopyOne = sendCopy[0];
        sendCopytwo = sendCopy[1]
     }else{
        sendCopyOne = sendCopy[0];
     }
     var cc=[this.state.relo,sendCopyOne,sendCopytwo,scheduleCopyOne,scheduleCopytwo]
     
     if(this.state.sendToJulie == 'Yes'){
        cc.push('julie@suiteamerica.com')
     }
     if(this.state.notifyCommunity == 'Yes'){
        cc.push('supplychain@suiteamerica.com')
     }

     if ( this.state.chargeTax == 'Yes'){
         cc.push('accounting@suiteamerica.com')
     }
     if ( this.state.sendToMeet_Greet == 'Yes'){
        cc.push('meetandgreet@suiteamerica.com')
    }  
     if(this.state.toSelect == 'New Delivery'){
        cc.push('utility@suiteamerica.com')
     }
     var filteredcc = cc.filter(Boolean);

     const countryCode = $(".country.highlight.active").attr("data-dial-code") == undefined
                    ? this.state.dialCode
                    : "+" + $(".country.highlight.active").attr("data-dial-code")
        const Request={
            toSelect: (this.state.toSelect=='' || this.state.toSelect==null)? '---':this.state.toSelect,
            relo: (this.state.relo=='' || this.state.relo==null)? '---':this.state.relo,
            scheduleCopy1: (this.state.scheduleCopy1=='' || this.state.scheduleCopy1 == null)?  '---':this.state.scheduleCopy1,
            sendCopyTo: (this.state.sendCopyTo=='' || this.state.sendCopyTo==null)? '---':this.state.sendCopyTo,
            sendToJulie: this.state.sendToJulie,
            sendToMeet_Greet: this.state.sendToMeet_Greet,
            masterNo: (this.state.masterNo=='' || this.state.masterNo==null)? '---':this.state.masterNo,
            orderNo: (this.state.orderNo=='' || this.state.orderNo==null)? '---':this.state.orderNo,
            company: (this.state.company=='' || this.state.company==null)? '---':this.state.company,
            guestName: (this.state.guestName=='' || this.state.guestName==null)?'---':this.state.guestName,
            guestPhone: (this.state.guestPhone=='' || this.state.guestPhone==null)?'---':countryCode+this.state.guestPhone,
            community: (this.state.community=='' || this.state.community==null)? '---':this.state.community,
            communityCity: (this.state.communityCity=='' || this.state.communityCity==null)? '---':this.state.communityCity,
            unitNumber:(this.state.unitNumber== '' || this.state.unitNumber == null)? '---':this.state.unitNumber,
            deliveryDate:this.state.deliveryDate.format('YYYY-MM-DD '),
            moveInDate:this.state.moveInDate.format('YYYY-MM-DD '),
            inspectionPhotos: (this.state.inspectionPhotos=='' || this.state.inspectionPhotos ==null)? '---':this.state.inspectionPhotos,
            sendTo: (this.state.sendTo=='' || this.state.sendTo ==null)? '---':this.state.sendTo,
            vipLevel: (this.state.vipLevel=='' || this.state.vipLevel ==null) ? '---':this.state.vipLevel,
            vipComments: (this.state.vipComments=='' || this.state.vipComments ==null) ? '---':this.state.vipComments,
            bedRoom1: (this.state.bedRoom1==''|| this.state.bedRoom1==null)? '---':this.state.bedRoom1,
            bedRoom2: (this.state.bedRoom2 =='' || this.state.bedRoom2==null) ? '---':this.state.bedRoom2,
            bedRoom3: (this.state.bedRoom3 ==''  || this.state.bedRoom3==null) ? '---':this.state.bedRoom3,
            bedRoom4: (this.state.bedRoom4 ==''  || this.state.bedRoom4==null) ? '---':this.state.bedRoom4,
            furniture: this.state.furniture==''?'---':this.state.furniture,
            HouseWares: (this.state.HouseWares=='' || this.state.HouseWares==null)? '---':this.state.HouseWares,
            furnitureComments: (this.state.furnitureComments=='' || this.state.furnitureComments==null) ? '---':this.state.furnitureComments,
            nonRefundablePetFee: (this.state.nonRefundablePetFee==''  || this.state.nonRefundablePetFee==null ) ? '---':this.state.nonRefundablePetFee,
            notifyCommunity:(this.state.notifyCommunity=='' || this.state.notifyCommunity==null )? '---':this.state.notifyCommunity,
            billTo: (this.state.billTo=='' || this.state.billTo == null) ? '---':this.state.billTo,
            petRent: (this.state.petRent=='' || this.state.petRent == null)  ? '---':this.state.petRent,
            petDeposit: (this.state.petDeposit=='' || this.state.petDeposit == null) ? '---':this.state.petDeposit,
            chargeTax: this.state.chargeTax==''? '---':this.state.chargeTax,
            houseKeeping: (this.state.houseKeeping=='' || this.state.houseKeeping==null)?'---':this.state.houseKeeping,
            paidBy: (this.state.paidBy=='' || this.state.paidBy==null)? '---':this.state.paidBy,
            entry: (this.state.entry=='' || this.state.entry==null)? '---':this.state.entry,
            time: (this.state.time=='' || this.state.time==null) ? '---':this.state.time,
            email: (this.state.email=='' || this.state.email==null) ? '---':this.state.email,
            cellPhone: (this.state.cellPhone=='' || this.state.cellPhone == null)? '---':this.state.cellPhone,
            travelDetails: (this.state.travelDetails=='' || this.state.travelDetails==null) ? '---':this.state.travelDetails,
            contactGuest: (this.state.contactGuest=='' || this.state.contactGuest==null) ? '---':this.state.contactGuest,
            notes: (this.state.notes=='' || this.state.notes==null)? '---':this.state.notes,
            orderData:this.state.orderData,
            partners:this.state.partners,
            occupyApartment:this.state.occupyApartment,
            sharedApartment:this.state.sharedApartment,
            frequency: (this.state.frequency=='' || this.state.frequency==null) ? '---':this.state.frequency,
            subject: 'T/O :' + (this.state.toSelect=='' ? '---':this.state.toSelect) + '; '+ 'Master # ' + this.state.masterNo +'; '+ 'Order # ' + this.state.orderNo+' ; '+'Community : '+this.state.community+' ; '+'City : '+this.state.communityCity+'; '+' Unit : '+((this.state.unitNumber=='' || this.state.unitNumber==null)? '---':this.state.unitNumber)+' ; '+' Delivery Date : '+this.state.deliveryDate.format('YYYY-MM-DD ')+' ; '+'Arrival Date : '+this.state.moveInDate.format('YYYY-MM-DD ')+'; '+'Guest : '+((this.state.guestName=='' || this.state.guestName==null)?'---':this.state.guestName)+'; '+'Company : '+this.state.company+' ; '+'Relo : '+(this.state.relo=='' ?'---':this.state.relo)+'',
            cc: filteredcc,
            to: 'gs-schedule@suiteamerica.com',
            mail:'toForm'
        }
        this.setState({
            orderData:[],
            toSelect: '',
            relo: '',
            scheduleCopy: '',
            scheduleCopy1: '',
            sendCopyTo: '',
            sendToJulie: '',
            sendToMeet_Greet: '',
            masterNo: '',
            orderNo: '',
            company: '',
            guestName: '',
            guestPhone: '',
            community: '',
            unitNumber: '',
            deliveryDate: null,
            moveInDate: null,
            inspectionPhotos: '',
            sendTo: '',
            vipLevel: '',
            vipComments: '',
            bedRoom1: '',
            bedRoom2: '',
            bedRoom3: '',
            bedRoom4: '',
            furniture: '',
            HouseWares: '',
            furnitureComments: '',
            nonRefundablePetFee: '',
            notifyCommunity: '',
            billTo: '',
            petRent: '',
            petDeposit: '',
            chargeTax:'',
            houseKeeping: '',
            paidBy: '',
            entry: '',
            time: '',
            email: '',
            cellPhone: '',
            travelDetails: '',
            contactGuest: '',
            notes:'',
            message: '',
            color:'',
            errorMessage:'',
            loader:false,
            partners:'',
            occupyApartment:'',
            sharedApartment:'',
            frequency:'',
            communityCity:''    
        })          
        jQuery(function($) {
            $('#successModal').modal('show');
            setTimeout(function(){
              $('#successModal').modal('hide');
            },4000);
          });         
          const emailResponse=await IntranetEmailService.emailSent(Request);
          console.log(emailResponse)
       }
       else{
        AppController.menuTopNavigation('serviceRequest');
        this.setState({errorMessage:'Please Enter Required Fields',color:'red'})
    }

       }
      
setNotes(index, value,e){
    value[0] = e.target.value
    const order=update(this.state.orderData, { [index]: {$set: value.join('@@@')}});

    this.setState({orderData:order})

}
       /* It is invoked to return html content */
       render() {
         
           return (
            <div className="corporateHousing_Updates" id="serviceRequest">
    <EmployeeHeader/>
    <div className="header-ht-cover bg-grey"></div>

    <div className="container-fluid nike-datepicker bg-grey service_request mrg-top-20">
    <h1 className="wow animated zoomIn" style={{textAlign:'center'}}>T/O FORM</h1>
        <div className="request_form company_form">
            <div className="container">
                <form className="well">
                <p style={{color:this.state.color,textAlign:'center'}}>{this.state.errorMessage}</p>
{/* Mail Send */}
                    <div className="row">

                        <div className="col-sm-3 col-md-3">
                            Order Number <span style={{color: 'red'}}>*</span>
                            <input type="text" className="form-control" placeholder="Order #" value={this.state.orderNumber} 
                            onChange={(e) => this.setValue('orderNumber', e)} />
                            {this.state.loader ? 
              <div className="panel-body_loader" > <span style={{fontSize: '25px', width: '100%', margin: 'auto', textAlign: 'center'}}><i className="fa fa-spinner fa-spin"/> please wait ...!</span>
              </div>:<button type="submit" className=" btn location_btn mrg-btm-70" 
              onClick={(e) => this.ordersByOrderNo(e)}> SUBMIT</button>
                            }</div> 
        
                       
            </div>
            <hr />
            {this.state.open ?
<div>
                    <div className="row">
                        <div className="col-sm-3 col-md-3">
                            T/O:
                            <label className="custom-select">
                                        <select value={this.state.toSelect} 
                                        onChange={(e) => this.setValue('toSelect', e)}>                                        
                                        <option value='' disabled></option>
                                        {this.state.toDropDown.map((row, index) => {
                                        return(
                                        <option key={index} value={row}>{row}</option>
                                        )
                                        })}
                                        </select>
                                    </label>
                        </div>
                        <div className="col-sm-3 col-md-3">
                            Relo <span style={{color: 'red'}}>*</span>
                            <label className="custom-select">
                                        <select value={this.state.relo} 
                                        onChange={(e) => this.setValue('relo', e)}>                                        
                                        <option value='' disabled></option>
                                        {this.state.reloDropDown.map((row, index) => {
                                        return(
                                          
                                        <option key={index} value={row.email}>{row.name}</option>
                                        )
                                        })}
                                        </select>
                                    </label>
                        </div> 
                        <div className="col-sm-3 col-md-3">
                            Schedule Copy 
                            <label className="custom-select">
                                        <select value={this.state.scheduleCopy} 
                                        onChange={(e) => this.setValue('scheduleCopy', e)}>                                        
                                        <option value='' disabled></option>
                                        {this.state.scheduleCopyDropDown.map((row, index) => {
                                        return(
                                        <option key={index} value={row.email+"_____"+row.name}>{row.name}</option>
                                        )
                                        })}
                                        </select>
                                    </label>
                        </div> 
                        <div className="col-sm-3 col-md-3">
                            Send Copy To 
                            <label className="custom-select">
                            <select value={this.state.sendCopyTo} 
                            onChange={(e) => this.setValue('sendCopyTo', e)}>                                        
                            <option value='' disabled></option>
                            {this.state.sendCopyToDropDown.map((row, index) => {
                            return(
                            <option key={index} value={row.email}>{row.name}</option>
                            )
                            })}
                            </select>
                        </label>

                        </div>


                    </div>

                    { this.state.toSelect == 'Rotation Unit' ?    <div className="row">
                         <div className="col-sm-6 col-md-6">

                         Shared Accomodation <span style={{color: 'red'}}>*</span>
                            <label className="custom-select">
                                        <select value={this.state.sharedApartment} 
                                        onChange={(e) => this.setValue('sharedApartment', e)}>                                        
                                        <option value='' disabled></option>
                                        {this.state.sharedApartmentDropDown.map((row, index) => {
                                        return(
                                        <option key={index} value={row}>{row}</option>
                                        )
                                        })}
                                        </select>
                                    </label>
                        </div> 

                   <div className="col-sm-6 col-md-6">

                    Apartment Occupied <span style={{color: 'red'}}>*</span>
                            <label className="custom-select">
                                        <select value={this.state.occupyApartment} 
                                        onChange={(e) => this.setValue('occupyApartment', e)}>                                        
                                        <option value='' disabled></option>
                                        {this.state.occupyApartmentDropDown.map((row, index) => {
                                        return(
                                        <option key={index} value={row}>{row}</option>
                                        )
                                        })}
                                        </select>
                                    </label>
                        </div> 
             
                    </div> : null }

                    <div className="row">
                         <div className="col-sm-6 col-md-6">

                            Send To Julie <span style={{color: 'red'}}>*</span>
                            <label className="custom-select">
                                        <select value={this.state.sendToJulie} 
                                        onChange={(e) => this.setValue('sendToJulie', e)}>                                        
                                        <option value='' disabled></option>
                                        {this.state.sendToJulieDropDown.map((row, index) => {
                                        return(
                                        <option key={index} value={row}>{row}</option>
                                        )
                                        })}
                                        </select>
                                    </label>
                        </div> 

                        <div className="col-sm-6 col-md-6">

                        Send to Meet & Greet    <span style={{color: 'red'}}>*</span>
                        <label className="custom-select">
                                    <select value={this.state.sendToMeet_Greet} 
                                    onChange={(e) => this.setValue('sendToMeet_Greet', e)}>                                        
                                    <option value='' disabled></option>
                                    {this.state.sendToMeet_GreetDropDown.map((row, index) => {
                                    return(
                                    <option key={index} value={row}>{row}</option>
                                    )
                                    })}
                                    </select>
                                </label>
                        </div>

                   { this.state.toSelect == 'All Inc' ? <div className="col-sm-6 col-md-6">

                            Partners <span style={{color: 'red'}}>*</span>
                            <input type="text" className="form-control" placeholder="Partners" value={this.state.partners} 
                            onChange={(e) => this.setValue('partners', e)} />
                        </div> : null }
             
                    </div>

                    



                    <hr />
{/* Order Information */}                    
                    
                    <div className="row">

                        <div className="col-sm-3 col-md-3">
                            Master Number <span style={{color: 'red'}}>*</span>
                            <input type="text" className="form-control" placeholder="Master #" value={this.state.masterNo} 
                            onChange={(e) => this.setValue('masterNo', e)} />

                        </div>

                        <div className="col-sm-3 col-md-3">
                            Order Number <span style={{color: 'red'}}>*</span>
                            <input type="text" className="form-control" placeholder="Order #" value={this.state.orderNo} 
                            onChange={(e) => this.setValue('orderNo', e)} />
                        </div>

                        <div className="col-sm-3 col-md-3">
                            Company <span style={{color: 'red'}}>*</span>
                            <input type="text" className="form-control" placeholder="Company " value={this.state.company} 
                            onChange={(e) => this.setValue('company', e)} />
                        </div>
                        <div className="col-sm-3 col-md-3">
                            Guest Name <span style={{color: 'red'}}>*</span>
                            <input type="text" className="form-control" placeholder="Guest Name" value={this.state.guestName}  
                            onChange={(e) => this.setValue('guestName', e)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-3 col-md-3">
                            Guest Phone <span style={{color: 'red'}}>*</span>
                            <input type="text" 
                                className="form-control" 
                                placeholder="Guest Phone"
                                id="toFormPhone"
                                maxLength="20"
                                autoComplete="off"  
                                onChange={(e) => this.setNumber(e)} 
                                value={this.state.guestPhone}/>
                        </div>
                        <div className="col-sm-3 col-md-3">
                            Community <span style={{color: 'red'}}>*</span>
                            <input type="text" className="form-control" placeholder="Community" value={this.state.community} 
                            onChange={(e) => this.setValue('community', e)} />
                        </div>
                        <div className="col-sm-3 col-md-3">
                            City <span style={{color: 'red'}}>*</span>
                            <input type="text" className="form-control" placeholder="Community City" value={this.state.communityCity} 
                            onChange={(e) => this.setValue('communityCity', e)} />
                        </div>
                        <div className="col-sm-3 col-md-3">
                            Unit Number <span style={{color: 'red'}}>*</span>
                            <input type="text" className="form-control" value={this.state.unitNumber} onChange={(e) => this.setValue('unitNumber', e)} />
                        </div>
                       
                    </div>
                    <div className="row">
                        <div className="col-sm-6 col-md-6">
                            Delivery Date <span style={{color: 'red'}}>*</span>
                            <DatePicker type="text" className="form-control" placeholder="*Departure Date" selected={this.state.deliveryDate}  
                            onChange={this.handleMoveOut} />

                             Inspection Photos 
                             <label className="custom-select">
                              <select value={this.state.inspectionPhotos} 
                              onChange={(e) => this.setValue('inspectionPhotos', e)}>                                        
                              <option value='' disabled></option>
                              {this.state.inspectionPhotosDropDown.map((row, index) => {
                              return(
                              <option key={index} value={row}>{row}</option>
                              )
                              })}
                              </select>
                          </label>
                        </div>
                        <div className="col-sm-6 col-md-6">
                            Move in Date <span style={{color: 'red'}}>*</span>

                            <DatePicker type="text" className="form-control" placeholder="*Departure Date" selected={this.state.moveInDate}  
                            onChange={this.handleMoveIn} />
                             Send To 
                            <input type="text" className="form-control" placeholder="Send To" value={this.state.sendTo} 
                            onChange={(e) => this.setValue('sendTo', e)} />
                        </div>
                    </div>
                    <hr />
{/* VIP */}

                    <h1>VIP</h1>
                    <div className="row">
                        <div className="col-sm-3 col-md-3">
                            <input type="text" className="form-control" placeholder="Level" value={this.state.vipLevel} 
                            onChange={(e) => this.setValue('vipLevel', e)} />
                        </div>
                        <div className="col-sm-9 col-md-9">
                            <textarea className="form-control" placeholder="VIP Comments" value={this.state.vipComments} 
                            onChange={(e) => this.setValue('vipComments', e)} rows="4" cols="5"></textarea>
                        </div>
                    </div>
                    <hr />

{/* FUN STUFF */}
                    <h1>Fun Stuff</h1>
                    <div className="row">
                    <div className="col-sm-12 col-md-12">
                       <table className="table">
                          <thead>
                             <tr>
                                <th>Item</th>
                                <th>Notes</th>
                              
                             </tr>
                          </thead>
                          {this.state.orderData.length >0 ?
                          <tbody>
                              {this.state.orderData.map((row, index) => {
                                  
                                  var val = row.split('@@@')
                                  return(
                                <tr  key={index}>
                                <td><span dangerouslySetInnerHTML={{__html: val[2]}}></span></td>
                                <td><textarea className="form-control" value={val[0].replace(/&amp;/g, "&").replace(/&quot;/g, '"')} 
                                onChange={(e) => this.setNotes(index, val, e)}></textarea></td>
                               
                                
                             </tr>

                              )})}
                    
                          </tbody> : <tbody><tr><td></td><td>No Result Found</td></tr></tbody>
                              }
                       </table>
                 </div>
                    </div>
                    <hr />


{/* Furniture/Housewares */}

                    <h1>Furniture/Housewares</h1>
                    <div className="row">
                        <div className="col-sm-3 col-md-3">
                            Bedroom 1 <span style={{color: 'red'}}>*</span>
                            <input type="text" className="form-control"  value={this.state.bedRoom1} onChange={(e) => this.setValue('bedRoom1', e)} />
                        </div>

                        <div className="col-sm-3 col-md-3">
                            Bedroom 2
                            <input type="text" className="form-control" value={this.state.bedRoom2} onChange={(e) => this.setValue('bedRoom2', e)} />
                        </div>

                        <div className="col-sm-3 col-md-3">
                            Bedroom 3
                            <input type="text" className="form-control" value={this.state.bedRoom3} onChange={(e) => this.setValue('bedRoom3', e)} />
                        </div>
                        <div className="col-sm-3 col-md-3">
                            Bedroom 4
                            <input type="text" className="form-control" value={this.state.bedRoom4} onChange={(e) => this.setValue('bedRoom4', e)} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-6 col-md-6">
                            Furniture <span style={{color: 'red'}}>*</span>
                            <input type="text" className="form-control" value={this.state.furniture} onChange={(e) => this.setValue('furniture', e)} />
                        </div>

                        <div className="col-sm-6 col-md-6">
                            Housewares
                            <label className="custom-select">
                                        <select value={this.state.HouseWares} 
                                        onChange={(e) => this.setValue('HouseWares', e)}>                                        
                                        <option value='' disabled></option>
                                        {this.state.HouseWaresDropDown.map((row, index) => {
                                        return(
                                        <option key={index} value={row}>{row}</option>
                                        )
                                        })}
                                        </select>
                                    </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12">
                            <textarea className="form-control" placeholder="Furniture/Housewares  Comments" value={this.state.furnitureComments} 
                            onChange={(e) => this.setValue('furnitureComments', e)} rows="4" cols="5"></textarea>
                        </div>
                    </div>
                    <hr />
              
{/* Pet Billing*/}
                    <h1>Pet Billing </h1>
                    <div className="row">
                        <div className="col-sm-4 col-md-4">
                            Non Refunable Pet Fee
                            <input type="text" className="form-control" placeholder="Non Refunable Pet Fee" value={this.state.nonRefundablePetFee} 
                            onChange={(e) => this.setValue('nonRefundablePetFee', e)} />
                        </div>
                        <div className="col-sm-4 col-md-4">
                            Notify Community ? <span style={{color: 'red'}}>*</span>
                            <label className="custom-select">
                                                <select value={this.state.notifyCommunity} 
                                                onChange={(e) => this.setValue('notifyCommunity', e)}>                                        
                                                <option value='' disabled></option>
                                                {this.state.notifyCommunityDropDown.map((row, index) => {
                                                return(
                                                <option key={index} value={row}>{row}</option>
                                                )
                                                })}
                                                </select>
                                             </label>
                        </div>
                        <div className="col-sm-4 col-md-4">
                            Bill to: 
                            <label className="custom-select">
                                                <select value={this.state.billTo} 
                                                onChange={(e) => this.setValue('billTo', e)}>                                        
                                                <option value='' disabled></option>
                                                {this.state.billToDropDown.map((row, index) => {
                                                return(
                                                <option key={index} value={row}>{row}</option>
                                                )
                                                })}
                                                </select>
                                             </label>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 col-md-6">
                            Pet Rent 
                            <input type="text" className="form-control" placeholder="Amount" value={this.state.petRent} 
                            onChange={(e) => this.setValue('petRent', e)} />
                        </div>
                        <div className="col-sm-6 col-md-6">
                            Pet Deposit
                            <input type="text" className="form-control" placeholder="Amount" value={this.state.petDeposit} 
                            onChange={(e) => this.setValue('petDeposit', e)} />
                        </div>
                    </div>
                    <hr />

                    <div className="row">
                        <div className="col-sm-4 col-md-4">
                           Charge Taxes <span style={{color: 'red'}}>*</span>
                           <label className="custom-select">
                                        <select value={this.state.chargeTax} 
                                        onChange={(e) => this.setValue('chargeTax', e)}>                                        
                                        <option value='' disabled></option>
                                        {this.state.chargeTaxDropDown.map((row, index) => {
                                        return(
                                        <option key={index} value={row}>{row}</option>
                                        )
                                        })}
                                        </select>
                                    </label>
                        </div>
            
                    </div>

{/* HouseKeeping */}
                    <h1>HouseKeeping</h1>
                    <div className="row">
                        <div className="col-sm-4 col-md-4">
                            HouseKeeping <span style={{color: 'red'}}>*</span>
                            <input type="text" className="form-control"  value={this.state.houseKeeping} 
                            onChange={(e) => this.setValue('houseKeeping', e)} />
                        </div>
                        <div className="col-sm-4 col-md-4">
                            Frequency 
                            <input type="text" className="form-control"  value={this.state.frequency} onChange={(e) => this.setValue('frequency', e)} />
                        </div>
                        <div className="col-sm-4 col-md-4">
                            Paid By:
                            <label className="custom-select">
                                        <select value={this.state.paidBy} 
                                        onChange={(e) => this.setValue('paidBy', e)}>                                        
                                        <option value='' disabled></option>
                                        {this.state.paidByDropDown.map((row, index) => {
                                        return(
                                        <option key={index} value={row}>{row}</option>
                                        )
                                        })}
                                        </select>
                                    </label>
                        </div>
                    </div>
                    <hr />
 {/* Entry */}                   
                    <h1>Entry</h1>
                    <div className="row">
                        <div className="col-sm-3 col-md-3">
                            Entry <span style={{color: 'red'}}>*</span>
                            <input type="text" className="form-control" placeholder="Entry Option" value={this.state.entry} 
                            onChange={(e) => this.setValue('entry', e)} />
                        </div>
                        <div className="col-sm-3 col-md-3">
                            Time
                            <input type="text" className="form-control" placeholder="Time" value={this.state.time} 
                            onChange={(e) => this.setValue('time', e)} />
                        </div>
                        <div className="col-sm-3 col-md-3">
                            Email
                            <input type="text" className="form-control" placeholder="Email" value={this.state.email} 
                            onChange={(e) => this.setValue('email', e)} />
                        </div>
                        <div className="col-sm-3 col-md-3">
                            Cell
                            <input type="text" className="form-control" placeholder="Cell Phone" value={this.state.cellPhone} 
                            onChange={(e) => this.setValue('cellPhone', e)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 col-md-6">

                            Travel Details 
                            <input type="text" className="form-control" placeholder="Travel Details " value={this.state.travelDetails} 
                            onChange={(e) => this.setValue('travelDetails', e)} />

                        </div>
                        <div className="col-sm-6 col-md-6">
                            Contact Guest
                            <label className="custom-select">
                              <select value={this.state.contactGuest} 
                              onChange={(e) => this.setValue('contactGuest', e)}>                                        
                              <option value='' disabled></option>
                              {this.state.contactGuestDropDown.map((row, index) => {
                              return(
                              <option key={index} value={row}>{row}</option>
                              )
                              })}
                              </select>
                          </label>
                        </div>
                    </div>
                    <hr />
 {/* Notes */} 
                    <h1>Notes</h1>
                    <div className="row">
                        <div className="col-sm-12 col-md-12">
                            <textarea className="form-control" placeholder="" value={this.state.notes} onChange={(e) => this.setValue('notes', e)} rows="8" cols="5"></textarea>
                        </div>
                    </div>
</div>:null}
                </form>
                {this.state.open ?
                <button type="submit" className=" btn location_btn mrg-btm-70" 
                onClick={(e) => this.serviceRequest(e)}> SUBMIT</button>
:null}
            </div>
        </div>
    </div>
    <SubFooter/>
    <Footer/>
    <SuccessModal/>
   
</div>

   
           );
       }
   }
   export default RequestReservation;