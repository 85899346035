import React, { Component } from "react";
import { formatDateForDisplay } from "./utils";

class BlogPost extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.props.handleBlogSelect(this.props.blog);
  }

  render() {
    return (
      <div
        className={
          this.props.blogPostType === "firstRowFirstBlog"
            ? "tail longTail"
            : "tail"
        }
      >
        <figure onClick={this.handleClick}>
          <img src={this.props.coverImageUrl} alt="" width="100%" />
        </figure>
        <figcaption onClick={this.handleClick}>
          <div className="categories ">
            {this.props.categories.map((category, index) => {
              return (
                <a className="tag" key={index}>
                  {category}
                </a>
              );
            })}
          </div>

          <h1 onClick={this.handleClick}>{this.props.title}</h1>
          <p>{formatDateForDisplay(this.props.createdDate)}</p>
        </figcaption>
        <div className="short-description">{this.props.description}</div>
      </div>
    );
  }
}

export default BlogPost;
