const QuoteOrderModel = (props) => {
  let err =
    '"Sorry! The selected property is not available anymore. Please search for options again."';
  return (
    <div className="modal fade" id="QuoteOrderModelPopUp" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content rm-border-radius">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label=""
            >
              <span>×</span>
            </button>
            <img src="/assets/images/errorBanner.png" />
          </div>
          <div className="modal-body">
            <p className="text-center sa-clr f-15">{err}</p>
            <div className="modal-footer">
              <a href="/umpqua-bank-travel">
                <button type="button" className="btn btn-danger">
                  Search Again
                </button>
              </a>

              {/* <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuoteOrderModel;
