/* Importing the node modules, child components, services and controllers used 
   inside NikeWhatsIncluded component */
import MainController from "../../controller/mainController";
import SubFooter from "../../common/subFooter";
import PivotHeader from "../../common/PivotHeader";
import FAQ from "./common/abcfaq";
import GuestHeader from '../../common/guestHeader';
import Footer from '../../common/footer';
/* NikeWhatsIncluded Component initialization */
class AbcWhatsIncluded extends React.Component {
    /* Initializing objects of its NikeWhatsIncluded class */
    constructor(props) {
        super(props);
    }
    /* It is invoked immediately before mounting occurs */
    componentWillMount() {
        MainController.getInitialInformation();
    }
    /* It is invoked to return html content */
    render() {
        return (
            <div className="corporateHousing_Updates">
                <GuestHeader />
                <header className="codeconveyHeader cc_image_text_main corporate-h">
                    <img className="img-responsive" src={s3BucketUrl + "images-website/Pivot_agency.jpg"} width="100%" alt="" />
                    <div className="wow fadeInUp img-on-text top30 width600 text-center left50 img-on-text-center" data-wow-delay="0.5s">
                        <h1>What's Included In A Standard SuiteAmerica Apartment Home?</h1>
                    </div>
                </header>
                <PivotHeader />
                <div className="quote-section bg-white">
                    <div className="container">
                        <div className="text-center col-md-10 col-md-offset-1 wow fadeInUp company_p" data-wow-delay="0.5s">
                            <p>Our package includes a fully furnished apartment with all furniture, housewares, utilities, WIFI, and streaming or Cable TV included in the rental.
                            </p>
                        </div>

                    </div>


                    <div className="quote-section bg-white pad-top-0">
                        <hr className="hr" />
                        <div className="container-fluid mrg-btm-50 mrg-top-50">
                            <div className="img-header row" style={{ backgroundImage: "url(https://s3-us-west-1.amazonaws.com/suiteam-website/Website-Images/SuiteAmerica-Company-Nike-Internship-Housewares.jpg)" }}>
                                <h2>Housewares</h2>
                            </div>
                        </div>
                        <div className="container">
                            <div className="col-md-12 boxed_list">
                                <h4>BEDROOM</h4>
                                <ul>
                                    <li>Luxury Duvet Set with Alternative Down Comforter</li>
                                    <li>Accent Coverlet & Shams</li>
                                    <li>Two Sets of Luxurious Linens</li>
                                    <li>Mattress Pad</li>
                                    <li>Blanket</li>
                                    <li>Four Bed Pillows</li>
                                    <li>Clock Radio</li>
                                    <li>Twenty Hangers</li>

                                </ul>
                            </div>
                        </div>
                        <div className="container">
                            <div className="col-md-12 boxed_list">
                                <h4>BATHROOM</h4>
                                <ul>
                                    <li>Four Bath Towels</li>
                                    <li>Four Hand Towels</li>
                                    <li>Four Face Towels</li>
                                    <li>Bath Rug</li>
                                    <li>Shower Curtain</li>
                                    <li>Stainless Tooth Brush Holder & Soap Dish</li>
                                    <li>Wastebasket</li>
                                    <li>Artwork</li>
                                    <li>Hair Dryer</li>
                                    <li>Toilet Brush & Plunger</li>
                                </ul>
                            </div>
                        </div>
                        <div className="container">
                            <div className="col-md-12 boxed_list">
                                <h4>DINING</h4>
                                <ul>
                                    <li>Dinner ware</li>
                                    <li>Place Mats </li>
                                    <li>Napkins</li>
                                    <li>Flatware</li>
                                    <li>Glassware & Wine Glasses</li>
                                </ul>
                            </div>
                        </div>
                        <div className="container">
                            <div className="col-md-12 boxed_list">
                                <h4>KITCHEN</h4>
                                <ul>
                                    <li>Microwave</li>
                                    <li>Cookware</li>
                                    <li>Mixing Bowls</li>
                                    <li>Baking Dish</li>
                                    <li>Colander</li>
                                    <li>Stainless Utensil Holder</li>
                                    <li>Large Fork</li>
                                    <li>Large Spoon</li>
                                    <li>Spatula</li>
                                    <li>Whisk</li>
                                    <li>Measuring Spoon & Cup</li>
                                    <li>Carving & Paring Knives</li>
                                    <li>Four Steak Knives</li>
                                    <li>Cutting Board</li>
                                    <li>Salt & Pepper Set</li>
                                    <li>Wine Opener</li>
                                    <li>Coffee Maker</li>
                                    <li>Toaster</li>
                                    <li>Can Opener</li>
                                    <li>Flatware Tray</li>
                                    <li>Kitchen Waste basket</li>
                                    <li>Dish Towels</li>
                                    <li>Pot Holders</li>
                                    <li>Pitcher</li>
                                    <li>10” Non Stick Frying Pan</li>
                                    <li>Cookie Sheet</li>
                                    <li>Stainless Steel Electric Tea Kettle</li>
                                    <li>3 Piece Non-Metal (Spatula,Spoon, Pasta Spoon) Combo</li>
                                </ul>
                            </div>
                        </div>
                        <div className="container">
                            <div className="col-md-12 boxed_list">
                                <h4>MISCELLANEOUS</h4>
                                <ul>
                                    <li>Iron & Ironing Board</li>
                                    <li>Broom</li>
                                    <li>Dust Pan</li>
                                    <li>Vacuum</li>
                                    <li>Decor</li>
                                    <li>Laundry Basket</li>
                                </ul>
                            </div>
                        </div>
                        <div className="container">
                            <div className="col-md-12 boxed_list">
                                <h4>STARTER KIT</h4>
                                <ul>
                                    <li>Bath Tissue</li>
                                    <li>Soap</li>
                                    <li>Shampoo & Conditioner</li>
                                    <li>Paper Towels</li>
                                    <li>Sponge</li>
                                    <li>Laundry Soap</li>
                                    <li>Dishwasher Detergent</li>
                                    <li>Liquid Dish Soap</li>
                                    <li>Coffee</li>
                                    <li>Sugar</li>
                                    <li>Creamer</li>
                                    <li>Bottled Water</li>
                                    <li>Oatmeal</li>
                                    <li>Popcorn</li>
                                    <li>Welcome Information</li>
                                </ul>
                            </div>
                        </div>

                        <div className="container-fluid mrg-btm-50 mrg-top-50">
                            <div className="img-header row" style={{ backgroundImage: "url(https://s3-us-west-1.amazonaws.com/suiteam-website/Website-Images/SuiteAmerica-Company-Nike-Internship-Furniture.jpg)" }}>
                                <h2>Furniture</h2>
                            </div>
                        </div>
                        <div className="container">
                            <div className="col-md-12 boxed_list">
                                <h4>MASTER BEDROOM</h4>
                                <ul>
                                    <li>Queen Bed</li>
                                    <li>Head board</li>
                                    <li>Two Nightstands</li>
                                    <li>Dresser & Mirror</li>
                                    <li>Two Lamps</li>
                                    <li>Artwork</li>
                                    <li>LCD Television</li>
                                </ul>
                            </div>
                        </div>

                        <div className="container">
                            <div className="col-md-12 boxed_list">
                                <h4>LIVING ROOM</h4>
                                <ul>
                                    <li>Sofa</li>
                                    <li>Chair</li>
                                    <li>End Table</li>
                                    <li>Coffee Table</li>
                                    <li>Lamp</li>
                                    <li>LCD TV w/stand</li>
                                    <li>Artwork</li>
                                </ul>
                            </div>
                        </div>
                        <div className="container">
                            <div className="col-md-12 boxed_list">
                                <h4>ADDITIONAL BEDROOMS</h4>
                                <ul>
                                    <li>Queen or Two Twin Beds</li>
                                    <li>Headboard</li>
                                    <li>Nightstand</li>
                                    <li>Chest of Drawers</li>
                                    <li>Lamp</li>
                                    <li>Artwork</li>
                                </ul>
                            </div>
                        </div>

                        <div className="container">
                            <div className="col-md-12 boxed_list">
                                <h4>DINING ROOM</h4>
                                <ul>
                                    <li>Dining Table</li>
                                    <li>Dining Chairs</li>
                                    <li>Artwork</li>
                                </ul>
                            </div>
                        </div>

                        <div className="container">
                            <div className="col-md-12 boxed_list">
                                <h4>OPTIONAL</h4>
                                <ul>
                                    <li>Desk</li>
                                    <li>Chair</li>
                                    <li>Lamp</li>
                                    <li>Artwork</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <span>Please note : All items may not be available in all markets</span>
                </div>
                <div className="container-fluid bg-grey">
                    <div className="container">
                        <FAQ />
                    </div>
                </div>
                <SubFooter />
                <Footer />
            </div>

        );
    }
}
export default AbcWhatsIncluded;