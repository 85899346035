
import Schools from './schools';
const GreatSchools = (props) => {
    const {greatSchools} = props;
    // const privateScools =_.filter(greatSchools.schools.school, function (o) {
    //     return o.type._text=='private';
    //   })
    //   const publicScools=_.filter(greatSchools.schools.school, function (o) {
    //     return o.type._text=='public';
    //   })
    //   const charterScools=_.filter(greatSchools.schools.school, function (o) {
    //     return o.type._text=='charter';
    //   })
      const privateScools = _.filter(greatSchools.schools, function (o) {
        o.schoolSummary = o["school-summary"];
        o.overviewUrl = o["overview-url"];
        return o.type == "private";
      });
      const publicScools = _.filter(greatSchools.schools, function (o) {
        o.schoolSummary = o["school-summary"];
        o.overviewUrl = o["overview-url"];
        return o.type == "public";
      });
      const charterScools = _.filter(greatSchools.schools, function (o) {
        o.schoolSummary = o["school-summary"];
        o.overviewUrl = o["overview-url"];
        return o.type == "charter";
      });
{console.log('greatSchools123',greatSchools,privateScools,publicScools,charterScools)}
    return (
      <div className="">
      <div className="community-tabs tabs_school ">
        <ul className="nav nav-tabs" role="tablist" style={{paddingTop: '0px',zIndex: '10'}}>
          <li role="presentation" className="active">
            <a href="#public" aria-controls="home" role="tab" data-toggle="tab">
              PUBLIC SCHOOLS</a>
          </li>
          <li role="presentation"> <a href="#private" aria-controls="profile" role="tab" data-toggle="tab">
                          PRIVATE SCHOOLS</a>
          </li>{charterScools.length!=0?
          <li role="presentation"> <a href="#charter" aria-controls="messages" role="tab" data-toggle="tab">
                          CHARTER SCHOOLS</a>
          </li>:null}
          <li className="sch_powered">
            <a href="https://www.greatschools.org/" target="_blank"> <em>School data provided by </em>
              <span>GREAT SCHOOLS</span>
            </a>
          </li>
        </ul>
        <div className="tab-content travel-booking-slider">
          <div role="tabpanel" className="tab-pane active" id="public">
            <div className="lc_slider">
              <Schools SchoolInfo={publicScools} width={props.width}/>
            </div>
          </div>
          <div role="tabpanel" className="tab-pane" id="private">
            <div className="lc_slider">
              <Schools SchoolInfo={privateScools}  width={props.width}/>
            </div>
          </div>
          <div role="tabpanel" className="tab-pane" id="charter">
            <div className="lc_slider">
              <Schools SchoolInfo={charterScools}  width={props.width}/>
            </div>
          </div>
        </div>
      </div>
      <div className="white-gap"></div>
    </div>
    )

}

export default GreatSchools;
