/* Importing the node modules, child components, services and controllers used 
   inside CeReservationRequest component */ 
   import $ from "jquery";
   import DatePicker from 'react-datepicker'
   import moment from 'moment'
   import AppController from "../../controller/appController";
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import NikeServices from '../../../services/nikeService';
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   import SuccessModal from '../../common/successModal';
   /* CeReservationRequest Component initialization */
   class CeReservationRequest extends React.Component {
       /* Initializing objects of its CeReservationRequest class */
       constructor(props) {
           super(props)
           this.state={
               CaptchaValue: '',
               firstName: '',
               lastName: '',
               gender: '',
               genderDropDown:['Male','Female'],
               email: '',
               phone: '',
               arrivalDate:moment(),
               departureDate:moment(),
               arrivalDateSelected: '',
               departureDateSelected: '',
               size: '',
               sizeDropdown: ['1 Bedroom', '2 Bedroom Sharing'],
               area: '',
               groceryPackage: '',
               groceryPackageDropdown:['Standard','Standard - Lactose Free','Standard - Nut Free','Asian','Vegan','Vegetarian','Gluten Free','Halal',],
               parking: '',
               lineOfBusiness: '',  
               hostManager: '',
               envoyContact: '',
               sirvapo: '',
               authorization: '',
               specialNotes:'',
               color:'',
               errorMessage:'',
           }
           this.setValue = this.setValue.bind(this);
           this.handleMoveIn = this.handleMoveIn.bind(this);
           this.handleMoveOut = this.handleMoveOut.bind(this);
           this.serviceRequest = this.serviceRequest.bind(this);
           this.setChecked = this.setChecked.bind(this);
           this.onChangeCaptcha = this.onChangeCaptcha.bind(this);
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
           MainController.getInitialInformation();
        }
        /* Form Details object creations  */

      setValue(field, e) {
            var object = {};
            object[field] = e.target.value;
            this.setState(object);
          }
      /* Form Details object creations  */

      setChecked(e) {
        this.setState({parking:e.target.value});
      }
    
     /* MoveIndate and MoveOutDate   */
      async handleMoveIn(date){
            await this.setState({arrivalDate: moment(date),
                arrivalDateSelected:date.format('YYYY-MM-DD HH:mm:ss') });
        }
    
      async handleMoveOut(date){
            await this.setState({departureDate: moment(date),
                departureDateSelected:date.format('YYYY-MM-DD HH:mm:ss') });
        }
 
       /* Service Request Details send to mail  */
      
       async serviceRequest(){
        const moveIn = this.state.arrivalDateSelected == '' ? this.state.arrivalDate.format('YYYY-MM-DD HH:mm:ss'): this.state.arrivalDateSelected ;
        const moveOut = this.state.departureDateSelected == '' ? this.state.departureDate.format('YYYY-MM-DD HH:mm:ss'): this.state.departureDateSelected ;
        const _this = this
           const validationFields=['firstName','lastName','gender','email','phone','arrivalDate', 'departureDate', 'size','area','groceryPackage','parking','lineOfBusiness','hostManager','envoyContact','sirvapo','authorization','specialNotes']
           let error = 0;
           _.forEach(validationFields, function(value) {
              if(_this.state[value] == ""){
                error++;
              }
          });
         if(error == 0 ){
             if(moveIn <= moveOut){
                const Request={
                    firstName: this.state.firstName,
                    lastName: this.state.lastName,
                    gender: this.state.gender,
                    email: this.state.email,
                    phone: this.state.phone,
                    arrivalDate: this.state.arrivalDate.format('YYYY-MM-DD'),
                    departureDate: this.state.departureDate.format('YYYY-MM-DD'),
                    size: this.state.size,
                    area: this.state.area,
                    groceryPackage: this.state.groceryPackage,
                    parking: this.state.parking,
                    lineOfBusiness: this.state.lineOfBusiness, 
                    hostManager: this.state.hostManager,
                    envoyContact: this.state.envoyContact,
                    sirvapo: this.state.sirvapo,
                    authorization: this.state.authorization,
                    specialNotes: this.state.specialNotes,
                    mail:'CeReservationRequest',
                    subject: 'CE Reservation Request'
                }
        
            await this.setState({
                firstName: '',
                lastName: '',
                gender: '',
                email: '',
                phone: '',
                arrivalDate:moment(),
                departureDate:moment(),
                size: '',
                area: '',
                groceryPackage: '',
                parking: '',
                lineOfBusiness: '',
                hostManager: '',
                envoyContact: '',
                sirvapo: '',
                authorization: '',
                specialNotes: '',
                color:'',
                errorMessage:'',
                CaptchaValue:'',
     
            })
            $('input[name="radio"]').prop('checked', false);
            $('input[name="1"]').prop('checked', false);
            AppController.menuTopNavigation('serviceRequest');
            jQuery(function($) {
                $('#successModal').modal('show');
                setTimeout(function(){
                  $('#successModal').modal('hide');
                },4000);
              });
             
            await NikeServices.serviceRequest(Request)
             }else{
                AppController.menuTopNavigation('serviceRequest');
                this.setState({errorMessage:'Departure Date is greater than are equal to Arrival Date ',color:'red'})
             }
           
        
    }
    else{
        AppController.menuTopNavigation('serviceRequest');
        this.setState({errorMessage:'Please Enter Required Fields',color:'red'})
    }
        
       }
      
       async onChangeCaptcha(CaptchaValue) {
        await this.setState({CaptchaValue})
        }
       /* It is invoked to return html content */
       render() {
           return (
            <div className="corporateHousing_Updates">
                <GuestHeader/>
                <div className="cover-tophd-ht"></div>
                <div className="container-fluid nike company_section" id="serviceRequest">
                    <div className="container text-center">
                        <h2 className="">CE Reservation Request Form</h2>
                       
                    </div>
                </div>
                <div className="container-fluid nike-datepicker bg-grey service_request">
                    <div className="request_form company_form" >
                        <div className="container">
                            <form className="well">
                            <p style={{color:this.state.color}}>{this.state.errorMessage}</p>
                            <h1>Guest Information</h1>
                            <div className="row">
                                <div className="col-sm-6 col-md-6">
                                     <span style={{color: 'black'}}>*</span> First Name 
                                    <input type="text" className="form-control" placeholder="First Name" value={this.state.firstName} onChange={(e) => this.setValue('firstName', e)}/>
                                    <span style={{color: 'black'}}>*</span> Last Name 
                                    <input type="text" className="form-control" placeholder="Last Name" value={this.state.lastName} onChange={(e) => this.setValue('lastName', e)} />
                                    <span style={{color: 'black'}}>*</span> Gender
                                    <label className="custom-select">

                                        <select value={this.state.gender}  onChange={(e) => this.setValue('gender', e)}>  
                                        <option value='' disabled>Select</option>
                                        {this.state.genderDropDown.map((row, index) => {
                                           
                                        return(
                                        <option key={index} value={row}>{row}</option>
                                        )
                                        })}
                                        </select>
                                    </label>
                                </div>
                                <div className="col-sm-6 col-md-6">
                                <span style={{color: 'black'}}>*</span> Email 
                                <input type="text" className="form-control" placeholder="Email" value={this.state.email} onChange={(e) => this.setValue('email', e)}/>
                                <span style={{color: 'black'}}>*</span> Phone# 
                                <input type="text" className="form-control" placeholder="Phone#" value={this.state.phone} onChange={(e) => this.setValue('phone', e)} />                                

                                </div>
                            </div>
                            <hr />
                            <h1>Reservation Information</h1>
                            <div className="row">
                                <div className="col-sm-6 col-md-6">
                                <span style={{color: 'black'}}>*</span> Arrival Date 
                                <DatePicker type="text" className="form-control" placeholder="Arrival Date" selected={this.state.arrivalDate}  
                                    onChange={this.handleMoveIn} />
                                    <span style={{color: 'black'}}>*</span> Departure Date 
                                    <DatePicker type="text" className="form-control" placeholder="Departure Date" selected={this.state.departureDate}  
                                onChange={this.handleMoveOut} />   
                                <span style={{color: 'black'}}>*</span> Size 
                                    <label className="custom-select">
                                        <select value={this.state.size} onChange={(e) => this.setValue('size', e)}>    
                                        <option value='' disabled>Select</option>                                    
                                        {this.state.sizeDropdown.map((row, index) => {
                                        return(
                                        <option key={index} value={row}>{row}</option>
                                        )
                                        })}
                                        </select>
                                    </label>
                                    <span style={{color: 'black'}}>*</span> Area
                                    <input type="text" className="form-control" placeholder="Area" value={this.state.area} 
                                onChange={(e) => this.setValue('area', e)} />
                                <span style={{color: 'black'}}>*</span> Grocery Package
                                <label className="custom-select">
                                        <select value={this.state.groceryPackage} onChange={(e) => this.setValue('groceryPackage', e)}> 
                                        <option value='' disabled>Select</option>                                         
                                        {this.state.groceryPackageDropdown.map((row, index) => {
                                        return(
                                        <option key={index} value={row}>{row}</option>
                                        )
                                        })}
                                        </select>
                                    </label>
                                    <div className="row radiosec">
                                        <div className="col-sm-12">
                                        <span style={{color: 'black'}}>*</span> Need Parking
                                        <div className="row mrg-top-10" >
                                            <div className="clearfix"></div>
                                            <div className="col-sm-3">
                                                <label className="custom_radio radio-inline">Yes
                                                <input type="radio" name="radio" value="yes" 
                                                onChange={(e) => this.setChecked(
                                                    e)} />
                                                <span className="checkmark"></span>
                                                </label>
                                            </div>
                                            <div className="col-sm-3">
                                                <label className="custom_radio radio-inline">No
                                                <input type="radio" name="radio" value="no" 
                                                onChange={(e) => this.setChecked(
                                                    e)}/>
                                                <span className="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                        </div>
                                    </div> 
                                </div>
                                <div className="col-sm-6 col-md-6">
                                <span style={{color: 'black'}}>*</span> Line of Business
                                <input type="text" className="form-control" placeholder="Line of Business" value={this.state.lineOfBusiness} 
                                onChange={(e) => this.setValue('lineOfBusiness', e)} />
                                <span style={{color: 'black'}}>*</span> Host Manager
                                <input type="text" className="form-control" placeholder="Host Manager" value={this.state.hostManager} 
                                onChange={(e) => this.setValue('hostManager', e)} />
                                <span style={{color: 'black'}}>*</span> Envoy Contact
                                <input type="text" className="form-control" placeholder="Envoy Contact" value={this.state.envoyContact} 
                                onChange={(e) => this.setValue('envoyContact', e)} />
                                <span style={{color: 'black'}}>*</span> SIRVA PO#
                                <input type="text" className="form-control" placeholder="SIRVA PO#" value={this.state.sirvapo} 
                                onChange={(e) => this.setValue('sirvapo', e)} />
                                <span style={{color: 'black'}}>*</span> Authorization#
                                <input type="text" className="form-control" placeholder="Authorization#" value={this.state.authorization} 
                                onChange={(e) => this.setValue('authorization', e)} />
                                </div>
                                <div className="col-sm-12 col-md-12">
                                Special Note
                                    <textarea className="form-control" placeholder="Special Note" value={this.state.specialNotes} 
                                    onChange={(e) => this.setValue('specialNotes', e)} rows="8" cols="5"></textarea>
                                </div>
                            </div>
                         
                                {/* <div className="row">
               <div className="col-sm-12 col-md-12">
                 <ReCAPTCHA
                  ref={ ref => this.recaptcha = ref }
                  sitekey="6Lcbw1AUAAAAAE5K5cYDyhoGu0RJY9C3CLtGFXAo"
                  onChange={this.onChangeCaptcha}
                />
          </div>
          
       </div> */}
                            </form>
                            <button type="button" className=" btn location_btn mrg-btm-70" onClick={this.serviceRequest}> SUBMIT</button>
                        </div>
                    </div>
                </div>
                <SubFooter/>
                <Footer/>
                <SuccessModal/>
                </div>

   
           );
       }
   }
   export default CeReservationRequest;