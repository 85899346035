/* Importing the node modules, child components, services and controllers used
   inside Search component */
import SearchModal from "./modals/searchModal";
import LocationService from "../../../services/locationService";
import EncryptDecrypt from "../../../services/encryptDecrypt";
/* Search Component initialization */
class Search extends React.Component {
  /* Initializing objects of its Search class */
  constructor(props) {
    super(props);
    /* Search Component State variables Initialization */
    this.SearchModal = new SearchModal(this, props);
    this.state = Object.assign(this.SearchModal.state);
    //methods
    this.setValue = this.setValue.bind(this);
    this.Search = this.Search.bind(this);
    this.refreshSearchResult = this.refreshSearchResult.bind(this);
    this.setLocation = this.setLocation.bind(this);
    this.amenitiesSelect = this.amenitiesSelect.bind(this);
  }
  /* It is invoked immediately after a component is mounted */
  async componentDidMount() {
    this.SearchModal.DidMount();
  }
  async setValue(e) {
    this.SearchModal.setValue(e.target.name, e);
  }
  async Search(e) {
    e.preventDefault();
    this.SearchModal.Search();
  }
  async refreshSearchResult() {
    this.SearchModal.refreshSearchResult();
  }
  async amenitiesSelect(id, row, index) {
    this.SearchModal.amenitiesSelect(id, row, index);
  }
  setLocation(e) {
    e.preventDefault();
    this.SearchModal.setLocation(e);
  }
  /* To update the state in response to prop changes */
  async componentWillReceiveProps() {
    //localStorage.getItem('savedSearch')
    const localData = null;
    const spitLightCities = localStorage.getItem("spitLightCities");
    const individualdata = JSON.parse(localData);
    localStorage.setItem("spitLightCities", 0);
    if (individualdata != null && individualdata != "") {
      if (individualdata.amenities_id != null) {
        const amenitiesData = await LocationService.getAmenitiesList();
        var newFileData = individualdata.amenities_id;
        newFileData = newFileData.split(",");
        var dataDisplay = [];
        amenitiesData.forEach((row) => {
          newFileData.find((item) => {
            if (row.TableId == Number(item)) {
              row.is_active = true;
            }
          });
          dataDisplay.push(row);
        });

        await this.setState({ amenitiesList: dataDisplay });
      }
    }
    if (!this.state.localSearchData && localData != null) {
      document.getElementById("city-input1").value = individualdata.address;
      const stateData = {
        stateId: individualdata.state_id == 0 ? "" : individualdata.state_id,
        cityId: individualdata.city_id == 0 ? "" : individualdata.city_id,
        address: individualdata.address,
        communityName: individualdata.communityName,
        countryId: individualdata.countryId,
      };
      const webGuest = localStorage.getItem("_u_web");
      if (webGuest != null) {
        const userData = await EncryptDecrypt.decryptData({ type: webGuest });
        this.setState({ userData: userData.recordset[0] });
      }
      this.setState(stateData);
      if (
        individualdata.countryId != "" &&
        individualdata.countryId != undefined
      ) {
        const countrysList = await LocationService.getCountryList({
          countryId: individualdata.countryId,
        });
        this.setState({ countrysList: countrysList });
      }
      if (
        individualdata.countryId != "" &&
        individualdata.countryId != undefined
      ) {
        const stateData = await LocationService.getStatesByCountryId({
          countryId: individualdata.countryId,
        });
        this.setState({ stateList: stateData });
      }
      if (
        individualdata.state_id != "" &&
        individualdata.state_id != undefined
      ) {
        const cityData = await LocationService.getCitysByStateId({
          stateId: individualdata.state_id,
        });
        this.setState({ cityList: cityData });
      }
      if (spitLightCities == 1) {
        await this.SearchModal.Search();
      }
    }
  }
  /* To update the input field values */

  render() {
    const selectColor = "#000000";
    const refineSearch =
      this.props.refineSearch != undefined ? this.props.refineSearch : false;
    return (
      <div className="container">
        <div
          className="panel-group"
          id="accordion"
          role="tablist"
          aria-multiselectable="true"
        >
          <div className="location_search panel panel-default">
            <form>
              <div className="row">
                <div className="col-md-7">
                  <div className="row">
                    <div className="col-md-8 col-sm-12">
                      <div className="ls_refine">
                        <input
                          id="city-input1"
                          type="text"
                          name="search"
                          placeholder="City or Address"
                          onChange={(e) => this.setLocation(e)}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-12 field-commnuity">
                      <div className="ls_refine">
                        <input
                          id="city-input"
                          type="text"
                          name="communityName"
                          placeholder="Community"
                          onChange={(e) => this.setValue(e)}
                          value={this.state.communityName}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="col-md-6 col-sm-6 ">
                    <div className="panel-heading" role="tab" id="headingTwo">
                      <div className="loaction_accordian refine-search-wrap ">
                        {!refineSearch ? (
                          <h1 className="refine_h panel-title">
                            <span>Refine Search</span>
                            <a
                              className="collapsed"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            ></a>
                          </h1>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {this.state.loadingStatus == true ? (
                    <div className="panel-body_loader text-right">
                      <span
                        style={{
                          fontSize: "20px",
                          width: "100%",
                          margin: "auto",
                          textAlign: "center",
                          lineHeight: "2.5em",
                        }}
                      >
                        <i className="fa fa-spinner fa-spin" />
                        please wait ...!
                      </span>
                    </div>
                  ) : (
                    <div className="col-md-6 col-sm-6 text-right">
                      <button
                        className="btn location_btn mrg-right-10"
                        type="button"
                        onClick={this.Search}
                      >
                        Search
                      </button>
                      <button
                        type="button"
                        className="btn location_btn"
                        onClick={this.refreshSearchResult}
                      >
                        <i className="fa fa-refresh"></i>
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                <div
                  id="collapseTwo"
                  className={
                    !refineSearch ? "panel-collapse collapse" : "panel-collapse"
                  }
                  role="tabpanel"
                  aria-labelledby="headingTwo"
                >
                  <div className="panel-body">
                    <div className="location_rooms refine-search-block">
                      <div className="row">
                        <div className="col-md-12 col-md-6 col-lg-3">
                          <div className="form-group">
                            <select
                              className="form-control"
                              name="countryId"
                              value={this.state.countryId}
                              onChange={(e) => this.setValue(e)}
                              style={{ color: selectColor }}
                            >
                              <option value="">Country</option>
                              {this.state.countrysList.map((row, index) => {
                                return (
                                  <option value={row.TableId} key={index}>
                                    {row.CountryName}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-12 col-md-6 col-lg-3">
                          <div className="form-group">
                            <select
                              className="form-control"
                              name="stateId"
                              value={this.state.stateId}
                              onChange={(e) => this.setValue(e)}
                              style={{ color: selectColor }}
                            >
                              <option value="">States</option>
                              {Array.isArray(this.state.stateList) &&
                                this.state.stateList.map((row, index) => {
                                  return (
                                    <option
                                      value={row.state_tableid}
                                      key={index}
                                    >
                                      {row.state_name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-12 col-md-6 col-lg-3">
                          <div className="form-group">
                            <select
                              className="form-control"
                              name="cityId"
                              value={this.state.cityId}
                              onChange={(e) => this.setValue(e)}
                              style={{ color: selectColor }}
                            >
                              <option value="">City</option>
                              {this.state.cityList != undefined &&
                              this.state.cityList.length > 0
                                ? this.state.cityList.map((row, index) => {
                                    return (
                                      <option
                                        value={row.city_tableid}
                                        key={index}
                                      >
                                        {row.city_name}
                                      </option>
                                    );
                                  })
                                : null}
                            </select>
                          </div>
                        </div>
                      </div>

                      {this.props.companyName != "NvidiaTravel" && (
                        <div className="row">
                          <div className="amenities">
                            <h2>Amenities</h2>
                            <ul>
                              {this.state.amenitiesList.map((row, index) => {
                                return (
                                  <li
                                    key={index}
                                    onClick={() =>
                                      this.amenitiesSelect(
                                        row.amenities_id,
                                        row,
                                        index
                                      )
                                    }
                                  >
                                    <a
                                      id={row.amenities_id}
                                      value={row.amenities_id}
                                      className={
                                        row.is_active == true ? "active" : ""
                                      }
                                    >
                                      <img
                                        width="40"
                                        src={
                                          s3BucketUrl +
                                          "Amenities/" +
                                          row.amenities_name.replace(
                                            /[^A-Z0-9]+/gi,
                                            ""
                                          ) +
                                          ".png"
                                        }
                                      />
                                      {row.amenities_name}
                                    </a>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
export default Search;
