const GoogleMainPage = (props) => {
return ( 
    <div>
    <div className="container">
       <div className="wow fadeInUp mrg-top-100 mrg-btm-60 clearfix">
          <div className="col-md-8 col-md-push-2">
             <p className="text-center">A business travel experience that offers more space than a hotel and the ability to book for as few as 10 days at locations near a Google office.
                Averaging 1,000 sq. ft., SuiteAmerica’s corporate apartments are fully furnished and offer the amenities of a home, making your short term or long term stay as comfortable as possible.
             </p>
          </div>
       </div>
    </div>
    <div className="container google_items">
       <h2 className="text-center color-red mrg-btm-40 contact-titleone">STAYING WITH SUITEAMERICA IS EASY! </h2>
       <div className="row">
          <div className="col-sm-4" onClick={()=>props.pageChanges(1)}>
             <img src={s3BucketUrl+"landing-images/Google/google_img1.png"} className="img-responsive" alt="" />
             <div className="t_cont">
                <h1>UNIQUE EXPERIENCE</h1>
                <p>Unique Experiences Below the Hotel Cap </p>
             </div>
          </div>
          <div className="col-sm-4" onClick={()=>props.pageChanges(2)}>
             <img src={s3BucketUrl+"landing-images/Google/google_img2.png"}  className="img-responsive" alt="" />
             <div className="t_cont">
                <h1>EARN CREDIT</h1>
                <p>Earn Trip Credit for Booking Accommodations</p>
             </div>
          </div>
          <div className="col-sm-4" onClick={()=>props.pageChanges(3)}>
             <img src={s3BucketUrl+"landing-images/Google/google_img3.png"} className="img-responsive" alt="" />
             <div className="t_cont">
                <h1>STAY GOOGLE FRUGAL</h1>
                <p>Googlers get special rates when staying with SuiteAmerica —&gt;
                   we will offer a discounted rate of $5/day off for Google travel bookings through a specific date
                </p>
             </div>
          </div>
       </div>
       <div className="clearfix"></div>
    </div>
 </div>
    );

}
export default GoogleMainPage;
