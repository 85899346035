/* Importing the node modules, child components, services and controllers used 
   inside NikeInternshipHeader component */
import { Link } from 'react-router-dom';
import $ from "jquery";
import MainController from '../controller/mainController';
/* NikeInternshipHeader Component initialization */
class NikeInternshipHeader extends React.Component {
    /* Initializing objects of its NikeInternshipHeader class */
    constructor(props) {
        super(props)
        /* NikeInternshipHeader Component State variables Initialization */
        this.state = {
            name: '',
            company: '',
            location: '',
            phone: '',
            email: '',
            bookingDetails: {},
            BookingData: {}
        }
        // this.selection = this.selection.bind(this);
        this.internQuestionnaire = this.internQuestionnaire.bind(this);
    }
    /* It is invoked immediately after a component is mounted */
    componentDidMount() {
        MainController.removeClassOnNavCollapse();
    }
    /* To uncheck the input fields type radio */
    internQuestionnaire() {
        $('input[name="Yes"]').prop('checked', false);
        $('input[name="No"]').prop('checked', false);
    }
    /* Condition checking for whether you want to proceed or not */
    //    selection(e){
    //         if(e.target.value == 'Yes'){
    //             location.href = '/company/nike-internship/questionnaire';
    //         }else{
    //             jQuery(function ($) {
    //                 setTimeout(function () {
    //                     $('#internship-confirmation').modal('hide');
    //                 }.bind(this), 500);
    //             }.bind(this));
    //         }
    //    }
    /* It is invoked to return html content */
    render() {
        return (
            <div>
                <div className="profile_submenu">
                    <div className="container">
                        <nav className="navbar navbar-inverse">
                            <div className="navbar-header">
                                <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#guest-login" aria-expanded="false">
                                    <span className="sr-only">Toggle navigation</span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                                <a href="http://nike.com" target="_blank"><img src={"https://nvidia.wd5.myworkdayjobs.com/wday/cxs/nvidia/NVIDIAExternalCareerSite/sidebarimage/e64d788b7b8d01e4c34e99996322ec00"} alt="" className="nike_list_img_mo nvidia_image" /></a>
                            </div>
                            <div className="collapse navbar-collapse" id="guest-login">
                                <ul className="nav navbar-nav">
                                    <li><Link to="/company/nvidia/home">Home</Link></li>
                                    <li><Link to="/company/nvidia/communities">Communities</Link></li>
                                    <li><Link to="/company/nvidia/apartment-tour">Apartment Tour</Link></li>
                                    {/* <li><Link to="/company/nvidia/faq">FAQ's</Link></li> */}
                                    <li><Link to="/company/nvidia/questionnaire">Reservation Form</Link></li>
                                    {/* <li><Link to="#" data-toggle="modal" data-target="#internship-confirmation" onClick={this.internQuestionnaire}>Intern Questionnaire</Link></li> */}
                                    <li><Link to="/company/nvidia/contact-us">Contact Us</Link></li>
                                </ul>
                                <a href="http://nike.com" target="_blank"><img src={"https://nvidia.wd5.myworkdayjobs.com/wday/cxs/nvidia/NVIDIAExternalCareerSite/sidebarimage/e64d788b7b8d01e4c34e99996322ec00"} alt="" className="nike_list_img nvidia_image" /></a>
                            </div>
                        </nav>
                    </div>
                </div>
                <div className='subHeaderHeight'></div>
            </div>

        );
    }
}
export default NikeInternshipHeader;