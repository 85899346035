/* Importing the node modules, child components, services and controllers used 
   inside serviceNowInternFrequentQuestions component */    
   import MainController from "../../controller/mainController";
   import ServiceNowInternHeader from "../../common/serviceNowInternHeader";
   import SubFooter from "../../common/subFooter";
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   import ServiceNowInternFooter from "./common/serviceNowInternFooter";
   /* serviceNowInternFrequentQuestions Component initialization */
   class ServiceNowInternFaq extends React.Component {
       /* Initializing objects of its serviceNowInternFrequentQuestions class */
       constructor(props) {
           super(props);
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
           MainController.getInitialInformation();
        }
       /* It is invoked to return html content */
       render() {   
        return (
            <div className="corporateHousing_Updates">
                <GuestHeader/>
                <div className="cover-tophd-ht"></div>
                <ServiceNowInternHeader />
                <div className="container-fluid nike guest_sitenew">
                <div className="container intra_staff2 col-sm-8 col-sm-offset-2">
                    <h2 className="text-center company-title">FREQUENT ASKED QUESTIONS</h2>
                    <div id="accordion" className="panel-group mrg-btm-90 company_acdn" role="tablist" aria-multiselectable="true">
                        <div className="panel panel-default">
                        <div className="panel-heading">
                            <h4 className="panel-title">
                                <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse1" aria-expanded="false" aria-controls="collapse1">When will I find out where I am staying?</a>
                            </h4>
                        </div>
                        <div id="collapse1" className="panel-collapse collapse">
                            <div className="panel-body">
                                <div className="profile-opener">
                                    SuiteAmerica can start confirming availability and make an apartment offer to you approximately 30-40 days prior to arrival. 
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className="panel panel-default">
                        <div className="panel-heading">
                            <h4 className="panel-title">
                                <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse2" aria-expanded="false" aria-controls="collapse2">Can I request a specific Community and/or Apartment Size?</a>
                            </h4>
                        </div>
                        <div id="collapse2" className="panel-collapse collapse">
                            <div className="panel-body">
                                <div className="profile-opener">
                                   SuiteAmerica will make every effort to accommodate your requests but please note that due to apartment availability, we can't guarantee your exact preferences. 
                                </div>
                            </div>
                        </div>
                        </div>

                        <div className="panel panel-default">
                        <div className="panel-heading">
                            <h4 className="panel-title">
                                <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse3" aria-expanded="false" aria-controls="collapse3">How does the pricing work?</a>
                            </h4>
                        </div>
                        <div id="collapse3" className="panel-collapse collapse">
                            <div className="panel-body">
                                <div className="profile-opener">
                                   The pricing is a fixed rate per intern based on the size of the apartment and no of roommates(see community section).
                                </div>
                            </div>
                        </div>
                        </div>




                        <div className="panel panel-default">
                        <div className="panel-heading">
                            <h4 className="panel-title">
                                <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse4" aria-expanded="false" aria-controls="collapse4">If I fill out the intern questionnaire, will I be obligated to the apartment offer that SuiteAmerica sends me?</a>
                            </h4>
                        </div>
                        <div id="collapse4" className="panel-collapse collapse">
                            <div className="panel-body">
                                <div className="profile-opener">
                                   No, you will not be obligated untill you sign a rental agreement via docusign.
                                </div>
                            </div>
                        </div>
                        </div>



                        <div className="panel panel-default">
                        <div className="panel-heading">
                            <h4 className="panel-title">
                                <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse5" aria-expanded="false" aria-controls="collapse">Can I request a roommate if I have already found one within the program?</a>
                            </h4>
                        </div>
                        <div id="collapse5" className="panel-collapse collapse">
                            <div className="panel-body">
                                <div className="profile-opener">
                                   Yes, you may list your requested roommate in the notes section of the questionnaire. Please keep in mind that if you choose to room with someone your dates should match up as close as possible to receive the best pricing.
                                </div>
                            </div>
                        </div>
                        </div>



                        <div className="panel panel-default">
                        <div className="panel-heading">
                            <h4 className="panel-title">
                                <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse6" aria-expanded="false" aria-controls="collapse6">When will my apartment location be confirmed?</a>
                            </h4>
                        </div>
                        <div id="collapse6" className="panel-collapse collapse">
                            <div className="panel-body">
                                <div className="profile-opener">
                                    Few apartments will be confirmed once you and your roommates accept the offer from SuiteAmerica and complete your rental paper work.
                                </div>
                            </div>
                        </div>
                        </div>


                        <div className="panel panel-default">
                        <div className="panel-heading">
                            <h4 className="panel-title">
                                <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse7" aria-expanded="false" aria-controls="collapse7">What does my apartment include?</a>
                            </h4>
                        </div>
                        <div id="collapse7" className="panel-collapse collapse">
                            <div className="panel-body">
                                <div className="profile-opener">
                                   Your apartment will includes all furniture, housewares, linens, housekeeping every two weeks, utilities, cable, wireless internet and home phone service. For full listing of what is included, please visit the Apartment Tour section of the website.
                                </div>
                            </div>
                        </div>
                        </div>



                        <div className="panel panel-default">
                        <div className="panel-heading">
                            <h4 className="panel-title">
                                <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse8" aria-expanded="false" aria-controls="collapse8">Are there any other additional fee's or deposits?</a>
                            </h4>
                        </div>
                        <div id="collapse8" className="panel-collapse collapse">
                            <div className="panel-body">
                                <div className="profile-opener">
                                    SuiteAmerica does not charge any deposits, taxes, move-out/cleaning charges.
                                </div>
                            </div>
                        </div>
                        </div>


                        <div className="panel panel-default">
                        <div className="panel-heading">
                            <h4 className="panel-title">
                                <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse9" aria-expanded="false" aria-controls="collapse9">Is parking included?</a>
                            </h4>
                        </div>
                        <div id="collapse9" className="panel-collapse collapse">
                            <div className="panel-body">
                                <div className="profile-opener">
                                    Typically one parking space is included with an apartment if atleast one intern is bringing a vehicle. There ia an additional cost for more then one parking space.
                                </div>
                            </div>
                        </div>
                        </div>
                        </div>
                        </div>
                </div>
                <div className="container ft-contact-adrs mrg-btm-30 mrg-top-30">
                   <ServiceNowInternFooter />
                </div>    
            <SubFooter/>
            <Footer/>
        </div>
   
           );
       }
   }
   export default ServiceNowInternFaq;