/* Importing the node modules, child components, services and controllers used 
   inside FiveStarLanding component */   
   import $ from "jquery";
   import AppController from "../../controller/appController";
   import EmployeeHeader from '../../common/employeeHeader';
   import Footer from '../../common/footer';
   import SubFooter from "../../common/subFooter";
   /* FiveStarLanding Component initialization */
   class ChangePassword extends React.Component{
     /* Initializing objects of its FiveStarLanding class */
       constructor(props) {
       super(props)
       /* FiveStarLanding Component State variables Initialization */
       this.state={
        resetPassword: '',
        oldPassword: '',
        message: '',
        loader: false,
        color: '',
        loginResult:{}
       }
       this.setValue = this.setValue.bind(this);
       this.changePassword = this.changePassword.bind(this);
       }
       /* It is invoked immediately after a component is mounted */    
        async componentDidMount() {
            const staffdata = localStorage.getItem('staffdata')
            console.log(staffdata)
           $('html, body').animate({scrollTop: 0});
           AppController.stickyTitles()
      
   }
   /* To change the selected input field value */
   setValue(field, e) {
     var object = {};
     object[field] = e.target.value;
     this.setState(object);
   }
   /* Authentication checking before login to the five star application */
  async changePassword(e){
    e.preventDefault();
 
   if(this.state.oldPassword !='' && this.state.resetPassword !='')
     {
        this.setState({message:'',color:''})
         const staffdata = await  JSON.parse(localStorage.getItem('staffdata'));
        const loginDetails={
            OldPassword:this.state.oldPassword,
            NewPassword:this.state.resetPassword,
            Email:staffdata.Email
        } 
        await fetch("http://192.168.1.165:8085/api/Account/ChangePassword",{ 
            method: 'POST', 
            data:loginDetails
           })
            .then(res => res.json()) 
            .catch(error => console.log(error)) 
            .then(response => console.log(response));  
    }
   else{
    this.setState({message:'Please Enter Valid Details',color:'red'});
   }
  }
   /* It is invoked to return html content */
   render(){
       return(
           <div id="serviceRequest" className=" mrg-top-20">
        <EmployeeHeader/>
         <div id="pages_5star" style={{'background': '#f5f5f5'}}>
                <div className="login_main mrg-top-20">
                    <div className="container-fluid">
                        <div className="login_star mrg-top-20">

                                    <div className="col-sm-12">
                                            {this.state.message !='' ? 
                                                <span style={{color: this.state.color,textAlign: 'right'}}>{this.state.message}</span>
                                            : ''}
                                    </div>
                                    
                                    <form onSubmit={(e) => this.changePassword(e)}>
                                        <div className="f-contact-form-style">
                                            <div className="form-group">
                                            <input type="password" className="form-control"  placeholder="Old Password" value={this.state.oldPassword} 
                                            onChange={(e) => this.setValue('oldPassword', e)}/>
                                            </div>
                                            <div className="form-group">
                                            <input type="password" className="form-control"  placeholder="Reset Password" value={this.state.resetPassword} 
                                            onChange={(e) => this.setValue('resetPassword', e)}/>
                                            </div>
                                            {this.state.loader ? 
                                            <div className="panel-body_loader"> <span style={{fontSize: '20px', width: '100%', margin: 'auto', textAlign: 'center'}}><i className="fa fa-spinner fa-spin"/> please wait ...!</span></div>:
                                            <div className="mrg-btm-20"><button type="submit" className="btn"> Submit</button></div>
                                            }
                                        </div>
                                    </form>
                        </div>
                    </div>
                    
                </div>

      </div>
          <SubFooter/>
          <Footer/>    
          </div>     
       );
   }
   }
   export default ChangePassword;