import { Link } from 'react-router-dom';
import DestinationService from '../../../services/destinationService';
import moment from 'moment'
import AppController from '../../controller/appController';
import SiteLoader from '../../common/siteLoader';
import WalkScore from '../../common/community/walkScore';
import GreatSchools from '../../common/community/greatSchools';
import DSPlaces from '../../common/dsPlaces';
import $ from "jquery";
class communityDetails extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      latitude: 0.0,
      longitude: 0.0,
      propertyRating: 0,
      error: false,
      errorMessage: '',
      propertyIndex: 1,
      length: 1,
      details: {},
      propertyDetails: [],
      headerDetails: [],
      walkScore: '',
      appPrice: '',
      appRating: '',
      notes: '',
      status: -1,
      communityName: '',
      greatSchools: {},
      walkScoreDetails: [],
      walkscoreLoading: false,
      width: 0,
      // CaptchaValue: '',
      submitloader: false
    }
    this.previous = this.previous.bind(this);
    this.next = this.next.bind(this);
    this.onStarClick = this.onStarClick.bind(this);
    this.addNotes = this.addNotes.bind(this);
    this.saveNotes = this.saveNotes.bind(this);
  }
  /* async onChangeCaptcha(CaptchaValue) {
    await this.setState({ CaptchaValue })
  }  */
  async propertyDetails() {
    this.props.proprertyData.propertyIndex = this.state.propertyIndex
    const data = this.props.proprertyData
    const propertyDetails = await DestinationService.getDetails(data)
    const headerDetails = await DestinationService.headerDetails(data);
    const details = propertyDetails.recordset[0]
    const address = details.Address + "," + details.City + "," + details.StateCode + "," + details.ZipCode
    console.log(address, 'add')
    var price = ''
    if (details.Size == "1X1") {
      if (details.OneByOneRentHi != null || details.OneByOneRentHi != undefined) {
        price = details.OneByOneRentHi
      } else {
        price = details.OneByOneRentLo
      }

    } else if (details.Size == "1X1.5") {
      if (details.OneByOneFiveRentHi != null || details.OneByOneFiveRentHi != undefined) {
        price = details.OneByOneFiveRentHi
      } else {
        price = details.OneByOneFiveRentLo
      }
    }

    else if (details.Size == "1X2") {
      if (details.OneByTwoRentHi != null || details.OneByTwoRentHi != undefined) {
        price = details.OneByTwoRentHi
      } else {
        price = details.OneByTwoRentLo
      }
    }
    else if (details.Size == "1X1/Den") {
      if (details.OneByOneDenRentHi != null || details.OneByOneDenRentHi != undefined) {
        price = details.OneByOneDenRentHi
      } else {
        price = details.OneByOneDenRentLo
      }
    }
    else if (details.Size == "2X1") {
      if (details.TwoByOneRentHi != null || details.TwoByOneRentHi != undefined) {
        price = details.TwoByOneRentHi
      } else {
        price = details.TwoByOneRentLo
      }
    }
    else if (details.Size == "2X2") {
      if (details.TwoByTwoRentHi != null || details.TwoByTwoRentHi != undefined) {
        price = details.TwoByTwoRentHi
      } else {
        price = details.TwoByTwoRentLo
      }
    }
    else if (details.Size == "3X1") {
      if (details.ThreeByOneRentHi != null || details.ThreeByOneRentHi != undefined) {
        price = details.ThreeByOneRentHi
      } else {
        price = details.ThreeByOneRentLo
      }
    }
    else if (details.Size == "3X2") {
      if (details.ThreeByTwoRentHi != null || details.ThreeByTwoRentHi != undefined) {
        price = details.ThreeByTwoRentHi
      } else {
        price = details.ThreeByTwoRentLo
      }
    }

    else if (details.Size == "3X3") {
      if (details.ThreeByThreeRentHi != null || details.ThreeByThreeRentHi != undefined) {
        price = details.ThreeByThreeRentHi
      } else {
        price = details.ThreeByThreeRentLo
      }
    }
    else if (details.Size == "Studio") {
      if (details.StudioRentHi != null || details.StudioRentHi != undefined) {
        price = details.StudioRentHi
      } else {
        price = details.StudioRentLo
      }
    }


    else if (details.Size == "4+") {
      if (details.FourPlusHi != null || details.FourPlusHi != undefined) {
        price = details.FourPlusHi
      } else {
        price = details.FourPlusLo
      }
    }
    await this.setState({ headerDetails: headerDetails.recordset, propertyDetails: propertyDetails.recordset[0], appPrice: price, communityName: details.Community })
  }
  async componentWillMount() {

    const SortOrder = localStorage.getItem('SortOrder')
    const Length = localStorage.getItem('length')
    await this.setState({ propertyIndex: SortOrder, length: Length })
    await this.propertyDetails();

  }

  async componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    $('html, body').animate({ scrollTop: 0 });
    await this.mapLoad()
    AppController.removeSiteLoader();

  }
  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  mapLoad() {
    setTimeout(function () {
      var geocoder = new google.maps.Geocoder();
      var address = this.state.propertyDetails.Address + ', ' + this.state.propertyDetails.City + ', ' + this.state.propertyDetails.StateCode + ', ' + this.state.propertyDetails.ZipCode
      const _this = this
      geocoder.geocode({ 'address': address }, async function (results, status) {
        if (status == google.maps.GeocoderStatus.OK) {
          var latitude = results[0].geometry.location.lat();
          var longitude = results[0].geometry.location.lng();
          await _this.walkScoreInfo({ 'communitydata': _this.state.propertyDetails, 'latitude': Number(latitude), 'longitude': Number(longitude) });
          await _this.setState({ latitude, longitude })
          await _this.loadMap(_this.state.propertyIndex);
        }

      })
    }.bind(this), 2000);

  }
  loadMap(index) {
    var directionsService = new google.maps.DirectionsService();
    var directionsDisplay = new google.maps.DirectionsRenderer();
    this.setState({ 'directionsService': directionsService });
    this.setState({ 'directionsDisplay': directionsDisplay });
    var locations = {
      lat: Number(this.state.latitude),
      lng: Number(this.state.longitude)
    };
    var map = new google.maps.Map(document.getElementById('map' + index), {
      zoom: 15,
      center: locations,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    });
    this.state.directionsDisplay.setMap(map);
    var address = this.state.propertyDetails.Address + ', ' + this.state.propertyDetails.City + ', ' + this.state.propertyDetails.StateCode;
    var contentString = '<div id="content">' + address + '</div>';
    var infowindow = new google.maps.InfoWindow({ content: contentString });
    var marker = new google.maps.Marker({ position: locations, map: map });
    marker.addListener('click', function () {
      infowindow.open(map, marker);
    });
  }
  async previous() {
    if (this.state.propertyIndex > 1) {
      const index = Number(this.state.propertyIndex) - 1
      await this.setState({ propertyIndex: index, errorMessage: '', successMessage: '', notes: '' })
      this.propertyDetails()
      this.mapLoad()
    }
  }
  async next() {
    if (this.state.propertyIndex < this.state.length) {
      const index = Number(this.state.propertyIndex) + 1;
      await this.setState({ propertyIndex: index, errorMessage: '', successMessage: '', notes: '' })
      this.propertyDetails()
      this.mapLoad()
    }
  }

  async onStarClick(index, e) {
    await this.setState({ appRating: e.target.value, status: index })
  }
  addNotes(e) {
    this.setState({ notes: e.target.value })
  }
  async saveNotes() {

    if (this.state.notes == '' || this.state.notes == null) {
      this.setState({ error: true, errorMessage: 'Please Enter Notes', successMessage: '' })
    } else {

      //   const ratingUpdate ={      
      //     companyName:this.props.proprertyData.companyName,
      //     itineraryId:this.props.proprertyData.itineraryId,
      //     PropertyNotes:this.state.notes,
      //     PropertyRating: this.state.appRating,
      //     ItineraryPropertyID:this.state.propertyDetails.PropertyID,
      //     type:'individual'  
      //  }
      await this.setState({ submitloader: true })
      let rating = {
        value: 1,
        index: 0,
        rangeVa: (this.state.appRating != '' ? this.state.appRating : '-'),
        PropertyID: this.state.propertyDetails.PropertyID,
        Community: this.state.propertyDetails.Community

      }
      const ratingUpdate = {
        itineraryId: this.props.proprertyData.itineraryId,
        FileNum: this.props.proprertyData.FileNum,
        notes: [this.state.notes],
        rating: [rating],
        employeeName: this.state.headerDetails[0].EmpFirstName + " " + this.state.headerDetails[0].EmpLastName,
        address: this.state.headerDetails[0].City + ", " + this.state.headerDetails[0].State,
        to: this.state.headerDetails[0].DSPUserEmail,
        companyName: this.props.proprertyData.companyName,
        type: 'group',
        dsNotes: ''
      }
      await this.setState({ errorMessage: '', successMessage: 'Notes Successfully Added' })
      const loginResult = await DestinationService.updateRating(ratingUpdate)
      const _this = this
      setTimeout(async function () {
        $('.rating-num-block ul li').removeClass('active');
        //await this.recaptcha.reset();
        await _this.setState({ successMessage: '', notes: '', appRating: 0, submitloader: false })
      }.bind(this), 4000);
    }
  }
  async walkScoreInfo(data) {
    Loadjs('/assets/js/Chart.min1.js')
    this.setState({ walkscoreLoading: false })
    this.walkscoredata(data);

  }

  async walkscoredata(data) {
    const greatSchoolsReq = {
      state_name: data.communitydata.StateCode,
      latitude: data.latitude,
      longitude: data.longitude
    }
    const greatSchools = await AppController.greatSchools(greatSchoolsReq);
    await this.setState({ greatSchools: greatSchools, walkscoreLoading: true })


  }

  render() {


    const communityDetails = this.state.propertyDetails
    const ApartmentAmenities = communityDetails.ApartmentAmenities != null ? communityDetails.ApartmentAmenities.split(',') : null
    const CommunityAmenities = communityDetails.CommunityAmenities != null ? communityDetails.CommunityAmenities.split(',') : null
    if (communityDetails.Website != undefined) {
      const website = communityDetails.Website.search('www')
      if (website == 0) {
        var http = 'http://'
        var url = http.concat(communityDetails.Website)
      } else {
        var url = communityDetails.Website;
      }
    }

    return <section className="porpertyDetails-block">
      <div className="sliding-block">
        <div className="sliding-list"><a /> <a onClick={this.previous} className={this.state.propertyIndex == 1 ? "list-active" : null}> <i className="fa fa-chevron-left"></i> <span>Previous</span> </a> </div>
        <div className="sliding-list"> <Link to={'/destination-service/itinerary/' + this.props.proprertyData.companyName + '/' + this.props.proprertyData.FileNum + '/' + this.props.proprertyData.itineraryId}> <i className="fa fa-file-text-o"></i> <span>ITINERARY LIST</span></Link> </div>
        <div className="sliding-list"><Link to={'/destination-service/itinerary/property/mapview/' + this.props.proprertyData.companyName + '/' + this.props.proprertyData.FileNum + '/' + this.props.proprertyData.itineraryId}> <i className="fa fa-map-marker"></i> <span>ITINERARY MAP</span> </Link> </div>
        <div className="sliding-list"> <a className={this.state.propertyIndex == this.state.length ? "list-active" : null} onClick={this.next}> <i className="fa fa-chevron-right"></i> <span>Next</span> </a> </div>
      </div>
      <div className="container">

        <div className="prop-detail-addres-block">
          <div className="mapbox-pointer">
            <div className="mapbox-count"><span className="font-blod-x">{communityDetails.SortOrder}</span></div>
          </div>
          <div className="prop-detail-address">
            <h1 className="font-blod-x">{communityDetails.Community}</h1>
            <p>{communityDetails.Address}, {communityDetails.City}, {communityDetails.StateCode} {communityDetails.ZipCode}</p>
          </div>
          <div className="prop-detail-time"><span className="font-blod">{moment.utc(communityDetails.time).format('hh:mm A')}</span></div>
        </div>
        <div className="row">
          <div className="col-sm-7">
            <div className="detail-image-block"> <img src={'https://s3-us-west-1.amazonaws.com/destination-services-itinerary/' + communityDetails.Photo + '.jpg'} alt="" />
              <div className="detail-image-pros">
                <ul>
                  <li> <span>Size</span>
                    <h1 className="font-blod">{communityDetails.Size}</h1>
                  </li>
                  <li> <span>Sq Ft</span>
                    <h1 className="font-blod">{communityDetails.SqFt == null ? '-' : communityDetails}</h1>
                  </li>
                  <li> <span>Price</span>
                    <h1 className="font-blod">${this.state.appPrice}</h1>
                  </li>
                  <li> <span>App Fee</span>
                    <h1 className="font-blod">${communityDetails.ApplicationFeeHI != undefined ? communityDetails.ApplicationFeeHI : null}{communityDetails.ApplicationFeeLo != undefined ? communityDetails.ApplicationFeeLo : null}</h1>
                  </li>
                  <li> <span>Deposit</span>
                    <h1 className="font-blod">${communityDetails.DepositHi != undefined ? communityDetails.DepositHi : null}{communityDetails.DepositLo != undefined ? communityDetails.DepositLo : null}</h1>
                  </li>
                  {(this.state.walkScore != '' && this.state.walkScore != null && this.state.walkScore != undefined) ? <li> <span>Walkscore</span>
                    <Link to="#" data-toggle="modal" data-target="#viewPlaces"
                      style={{
                        'cursor': 'pointer'
                      }}> <h1 className="font-blod" >{this.state.walkScore}</h1></Link>
                  </li> : null}
                </ul>
              </div>
            </div>
            <div className="amenities-block">
              <div className="row">
                <div className="col-sm-6">
                  <div className="amenities-list">
                    <h1>Community Amenities</h1>
                    <ul>
                      {CommunityAmenities != null ? CommunityAmenities.map((row, index) => {
                        return (<li key={index}>{row}</li>)
                      }) : null}
                    </ul>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="amenities-list">
                    <h1>Apartment Amenities</h1>
                    <ul>
                      {ApartmentAmenities != null ? ApartmentAmenities.map((row, index) => {
                        return (<li key={index}>{row}</li>)
                      }) : null}
                    </ul>
                  </div>
                </div>

                <div className="clearfix"></div>

              </div>
            </div>
          </div>
          <div className="col-sm-5 new_bookingmap">
            <div className="location-block">
              <h1>Location</h1>
              <div className="location-map">
                <div id={'map' + this.state.propertyIndex} style={{ 'width': '100%', 'height': '365px' }}></div></div>
            </div>
            <div className="location-block mrg-top-45">
              <h1>Website : <a className="p-link" href={url} target="_blank">{communityDetails.Website}</a></h1>
            </div>
            <div className="location-block mrg-top-20">
              <h1>Phone : <a href={"tel:" + communityDetails.Phone}>{communityDetails.Phone != null ? communityDetails.Phone : 'NA'}</a> - <em className="itirnary-phone">{communityDetails.CommunityContactName != null ? communityDetails.CommunityContactName : 'NA'}</em></h1>
            </div>
            <div className="detail-rate-property-block mrg-top-30">
              <div className="rate-head">
                <h1 className="font-blod">{communityDetails.Community}</h1>
              </div>
              <div className="rating-block">
                <div className="property-rate-head"> <i className="fa fa-star-o"></i> Rate this property </div>
                <div className="rating-num-block">
                  <ul>
                    <li className={this.state.status == 0 ? "font-blod active" : "font-bold"} value='1' onClick={(e) => this.onStarClick(0, e)}>1</li>
                    <li className={this.state.status == 1 ? "font-blod active" : "font-bold"} value='2' onClick={(e) => this.onStarClick(1, e)}>2</li>
                    <li className={this.state.status == 2 ? "font-blod active" : "font-bold"} value='3' onClick={(e) => this.onStarClick(2, e)} >3</li>
                    <li className={this.state.status == 3 ? "font-blod active" : "font-bold"} value='4' onClick={(e) => this.onStarClick(3, e)}>4</li>
                    <li className={this.state.status == 4 ? "font-blod active" : "font-bold"} value='5' onClick={(e) => this.onStarClick(4, e)} >5</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="mrg-top-15 mrg-btm-15">
              <textarea name="" id="" cols="35" rows="5" className="form-control" placeholder="Notes" value={this.state.notes} onChange={(e) => this.addNotes(e)}></textarea>
            </div>
            {/* <ReCAPTCHA
                                            ref={ ref => this.recaptcha = ref }
                                            sitekey="6Lcbw1AUAAAAAE5K5cYDyhoGu0RJY9C3CLtGFXAo"
                                            onChange={this.onChangeCaptcha}
                                            /> */}

            <p></p>
            <div>
              {this.state.submitloader ?
                <button className="btn btn-primary btn-xxl text-uppercase">Save
                  <span className="btn-pleaseWait"><i class="fa fa-spinner" aria-hidden="true"></i> Please Wait</span>
                </button> :
                <button onClick={this.saveNotes} className="btn btn-primary btn-xxl text-uppercase">Save</button>
              }
              <div style={{ 'color': '#ff0000', 'marginTop': '15px' }}>{this.state.errorMessage}</div>
              <div style={{ 'color': 'green', 'marginTop': '15px' }}>{this.state.successMessage}</div>
            </div>
          </div>

          {this.state.walkscoreLoading ?
            <div className="New_locationcommunyties">
              <div className="container">
              </div>
              <div className="container">

                {this.state.walkscoreLoading && Object.keys(this.state.greatSchools).length > 0 ? <GreatSchools {...this.state} /> : null}
              </div>
              <div className="container">
                {this.state.walkscoreLoading ? <DSPlaces latitude={this.state.latitude} longitude={this.state.longitude} communityName={this.state.communityName} /> : null}
              </div>

            </div> : <div className="panel-body_loader mrg-top-30">
              <span style={{ fontSize: '20px', width: '100%', margin: 'auto', textAlign: 'center' }}>
                <i className="fa fa-spinner fa-spin" /> please wait ...!</span>
            </div>}

        </div>
      </div>
      <SiteLoader />
    </section>


  }
}
export default communityDetails;
