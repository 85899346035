/* Importing the node modules, child components, services and controllers used 
   inside CommunityLocation component */
   import $ from 'jquery'
   import AppController from "../controller/appController";
   import MapController from "../controller/mapController";
   import GuestHeader from '../common/guestHeader';
   import Spinner from '../common/spinner';
   import Footer from '../common/footer';
   import SubFooter from "./../common/subFooter";
   import CommunityLocationModal from './location/modals/communityLocationModal';
   import CommunityBookNowModal from '../controller/communityBookNowModal';
   import Login from './location/login';
   import MetaTags from './../common/metaTags';
   import WalkScore from '../common/community/walkScore';
   import GreatSchools from '../common/community/greatSchools';
   import SearchMap from '../common/community/SearchMap';
   import ReservationSuccesModal from '../controller/reservationSuccesModal';
   import PdfService from './../../services/pdfService';
   /* CommunityLocation Component initialization */
   class CommunityLocation extends React.Component {
     /* Initializing objects of its CommunityLocation class */
     constructor(props) {
       super(props); 
       /* CommunityLocation Component State variables Initialization */
       this.CommunityLocationModal = new CommunityLocationModal(this, props)
       this.state = Object.assign(this.CommunityLocationModal.state)
   
       this.onMarkerClick = this.onMarkerClick.bind(this);
       this.onMapClicked = this.onMapClicked.bind(this);
       this.onChange = (address) => this.setState({address})
       this.destinationLocation = this.destinationLocation.bind(this);
       this.handleFormSubmit = this.handleFormSubmit.bind(this);
       this.resetData = this.resetData.bind(this);
       this.favouriteCommunity = this.favouriteCommunity.bind(this);
       this.unFavouriteCommunity = this.unFavouriteCommunity.bind(this);
       this.moreInfoMethod = this.moreInfoMethod.bind(this);  
       this.calendarClose = this.calendarClose.bind(this);
       this.calendarInfo = this.calendarInfo.bind(this);
       this.pdfGeneration = this.pdfGeneration.bind(this);
     }
     /* It is invoked immediately before mounting occurs */
     async componentWillMount() {
      
       AppController.removeLocalStorageData();
       $('html, body').animate({scrollTop: 0});
       AppController.stickyTitles();
       await this.CommunityLocationModal.getDetails();
       await AppController.thumbnails(this.state.communityObject.community_id);
     }
     /* To get the apartment/community amenities */
   
     /* Display the position of community */
     /* Destination related function */
        destinationLocation(){
       var address = '';
       const _this = this;   
         var places = new google.maps.places.Autocomplete(document.getElementById('txtPlaces'));
         google.maps.event.addListener(places, 'place_changed', function () {
             var place = places.getPlace();
             address = place.formatted_address; 
             _this.setState({'destinationAdd': address}); 
         });
     }
     /* Map marker selection */
     onMarkerClick(props, marker, e) {
       this.setState({
         selectedPlace: props,
         activeMarker: marker,
         showingInfoWindow: true
       });
     }
      /* Map marker onMapClicked */
     onMapClicked(props) {
       if (this.state.showingInfoWindow) {
         this.setState({
           showingInfoWindow: false,
           activeMarker: null
         })
       }
     }
          //Location Resturants
          async onetype(row,type){
           await this.setState({type:[row.type],showlist:true,typeName:row.name,typeicon:type})
           this.loadPlacesWithMarkers(1,this.state.typeicon)
         }
         searchPlace(){
           this.setState({showSearch:true,showlist:true})
         }
      /* Display the root map for community to selected place */
     async handleFormSubmit() {
      const _this = this;
      var locations = {
        lat: Number(_this.state.communityObject.latitude),
        lng: Number(_this.state.communityObject.longitude)
      };
      const customMap = new google.maps.Map(document.getElementById('map'+_this.state.communityObject.community_id), {
        zoom: 7,
        center: locations,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      });
    await _this.setState({customMap});
      await MapController.customMarkers(this.state.communityObject, 'singlemark', this, '', 'https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/location_pin_2.svg', customMap);

                   const community_lat = this.state.communityObject.latitude;
                 const community_long = this.state.communityObject.longitude;            
                 var request = {
                   origin: {lat: Number(community_lat), lng: Number(community_long)}, 
                   destination: this.state.destinationAdd,
                   travelMode: google.maps.DirectionsTravelMode.DRIVING
                 };
            
                 _this.state.directionsService.route(request, function(response, status) {
                   if (status == google.maps.DirectionsStatus.OK) {
                     _this.state.directionsDisplay.setDirections(response);
                     var distance = response.routes[0].legs[0].distance.text;
                     var duration = response.routes[0].legs[0].duration.text; 
                     var dvDistance = document.getElementById("dvDistance");
                            dvDistance.innerHTML = "" ;
                            dvDistance.innerHTML += "<h4>YOUR COMMUTE SUMMARY</h4>" ;
                            dvDistance.innerHTML += "<p>Drive Time: " + duration + "</p>";
                            dvDistance.innerHTML += "<p>Distance: " + distance + "</p>";
                            function makeMarker(position, icon, title, map) {
                            let mark =  new google.maps.Marker({
                                   position: position,
                                   map: map,
                                   icon: icon,
                                   title: title
                               });
                               console.log(mark)
                             }
                            var leg = response.routes[0].legs[0];
                               makeMarker(leg.start_location, 'https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/location_pin_2.svg', "title", _this.state.customMap);
                               makeMarker(leg.end_location, s3BucketUrl_WebsiteImages+'SuiteAmerica-Map-Marker-White.png', 'title', _this.state.customMap);
                   }
                 });
         }
          /* Resets the Destination to community position */
         async resetData() {
           this.CommunityLocationModal.resetData()
         }
         /* To get the recently viewed data */
   
       /* Get the favourite community of the user */
        
   /* favourite insertion by user_id
      and also calling the favourites */
   
   async favouriteCommunity(data) { 
     await this.setState({spinner:data.community_id})
     await this.CommunityLocationModal.favouriteCommunity(data)
     await this.setState({spinner:-1})
   }
   
   /* Callback method to update user favorites */
   async unFavouriteCommunity(value){
   
    await  this.setState({spinner:value.community_id})
     await this.CommunityLocationModal.unFavouriteCommunity(value)
     await this.setState({spinner:-1})
   }
   /* To see the more content */
   async moreInfoMethod(data, favouriteCheck){
     await this.CommunityLocationModal.getDetails(data);
   }
   async checkPassword(e) {
     await this.CommunityLocationModal.checkPassword();
   }
   deleteState() {
     this.setState({'password': '', 'email': '', 'message': ''})
   }
   setValue(field, e) {
     var object = {};
     object[field] = e.target.value;
     this.setState(object);
   }
   updateDimensions() {
    this.setState({ width: window.innerWidth});
} 
    /* Invoked immediately before a component is unmounted and destroyed */
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }
  /* It is invoked immediately after a component is mounted */
  async componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
   
  }
  async calendarClose() {
    await this.setState({calendarStatus: false })
  }
  async calendarInfo() {
    await this.setState({calendarStatus: true})
  }
  async pdfGeneration(){
    this.setState({pdfLoading: true});
    const finalData = {community: this.state.communityObject, appAmenities: this.state.appartmentAmenities, commAmenities: this.state.communityAmenities ,communityImages: this.state.communityImages};
    await PdfService.pdf(finalData);
    var link = document.createElement('a');
    link.href = AppController.travelBookingUrl()+'community_pdf.pdf';
    link.target = '_blank';
    link.download = this.state.communityObject.community_name+'.pdf';
    link.dispatchEvent(new MouseEvent('click'));
    this.setState({pdfLoading: false});
 } 
   /* It is invoked to return html content */
     render() {
       const inputProps = {
         value: this.state.address,
         onChange: this.onChange
       }   
       const commName = this.state.communityObject.community_name != undefined ? this.state.communityObject.community_name + " | ": '';
       const descriptionName = this.state.communityObject.community_name != undefined ? this.state.communityObject.community_name : '';
       const communityALLImages=this.state.communityALLImages
       
       return (
        <div>
        <MetaTags metaTitle={commName + "Corporate Housing | 800 367 9501 | SuiteAmerica"} metaDesription={descriptionName + " Corporate Housing. Suite America provides platinum-level service in Corporate Housing, Short Term Apartments and GSA Contract Housing. Book today at www.suiteamerica.com or call 800 367 9501."} metaUrl={ "https://www.suiteamerica.com" + this.props.match.url} />
        <GuestHeader/>
        <div className="ht-w-200"></div>
        <div className="New_locationcommunyties">
        {this.state.PageLoader?
        <div>
          <div className="container">
            <div className="row">
              <div className="col-md-6">{Object.keys(this.state.communityObject).length > 0 ?
                <div className="lc_headings">
                  <h1>{this.state.communityObject.community_name != undefined ? this.state.communityObject.community_name : ''}</h1>
                  <p>{this.state.communityObject.address != undefined ? this.state.communityObject.address : ''}, {this.state.communityObject.city_name != undefined ? this.state.communityObject.city_name.toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ')  : ''}, {this.state.communityObject.state_name != undefined ? this.state.communityObject.state_name.toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ') : ''} {this.state.communityObject.ZipCode != undefined ? this.state.communityObject.ZipCode : ''}</p>
                </div>: null}
                </div>

                <div className="col-md-6 re_ri-text re_cs">
                {this.state.pdfLoading ? <i className="fa fa-spinner fa-spin"/>: <a onClick={() => this.pdfGeneration()}><i className="fa fa-file-pdf-o"></i></a>}
               {/* <button type="button" className="location_btn" data-toggle="modal" data-target="#community_book_now" onClick={() => this.calendarInfo()}>Reserve Now</button> */}
                </div>

                <div className="clearfix"></div>

                <div className="col-md-6">
                
                               
                <div className="outer">
                  <div id="big" className={"owl-carousel owl-theme comm_thum_main big"+ this.state.communityObject.community_id}>{communityALLImages.map((galleyImage, galleyImageIndex) => { return (
                    <div className="item" key={galleyImageIndex}>{galleyImage.image_name != null ?
                      <img className="img-responsive" src={galleyImage.image_name} alt="" />:
                      <img className="img-responsive" src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Guest-Portal-Main-Location_Down.jpg"} alt="" />}</div>) })}</div>
 {communityALLImages.length > 0 ?
                  <div id="thumbs" className={"owl-carousel owl-theme comm_thumbnail thumbs"+ this.state.communityObject.community_id}>{communityALLImages.map((galleyImage, galleyImageIndex) => { return (
                    <div className="item" key={galleyImageIndex}>{galleyImage.image_name != null ?
                      <img className="img-responsive" src={galleyImage.image_name} alt="" />:
                      <img className="img-responsive" src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Guest-Portal-Main-Location_Down.jpg"} alt="" />}</div>) })}</div>
                      :''}</div></div>
              <div className="col-md-6">
               
                <div className="location_com">
                    <div className="save_search">
                    <h2 className="color-red mrg-top-5 mrg-btm-5">Amenities</h2>
                    <div className="aminities_scroll_div">
                  <div className="col-sm-6">
                  {this.state.communityAmenities.length > 0 ?
                    <ul className="com_amenities_list">
                    {this.state.communityAmenities.map((row, index) => {
                        return (
                          <li key={index}>
                            <a value={row.aparment_amenity_id}><img width="40" src={s3BucketUrl+'Amenities/'+(row.aparment_amenity_name).replace(/[^A-Z0-9]+/ig, "")+'.png'} />{row.aparment_amenity_name}</a>
                          </li>
                        )
                      })}
                    </ul>: <div className='text-center' style={{fontSize: '15px', width: '100%', margin: 'auto', textAlign: 'center' }}>No Amenities Found</div>}
                  </div>

                  <div className="col-sm-6">
                  {this.state.appartmentAmenities.length > 0 ?                
                  <ul className="com_amenities_list">
                    {this
                      .state
                      .appartmentAmenities
                      .map((row, index) => {
                        return (
                          <li key={index}>
                            <a value={row.aparment_amenity_id}><img width="40" src={s3BucketUrl+'Amenities/'+(row.aparment_amenity_name).replace(/[^A-Z0-9]+/ig, "")+'.png'} />{row.aparment_amenity_name}</a>
                          </li>
                        )
                      })}
                     
                    </ul> : <div className='text-center' style={{fontSize: '15px', width: '100%', margin: 'auto', textAlign: 'center' }}>No Amenities Found</div>}
                    </div>
                    </div> 

                    </div>
                    <div className="clearfix"></div>

                  
                </div>
                {this.state.walkscoreLoading?
                <WalkScore {...this.state}/>:<Spinner fontSize="25px"/>}</div>
            </div>
          </div>
     <div className="container">{Object.keys(this.state.greatSchools).length > 0 ?
      <GreatSchools {...this.state}/>:!this.state.walkscoreLoading ?<Spinner fontSize="25px"/>:<div className="mrg-top-20"></div>}
   </div>
   </div>
       :<Spinner fontSize="35px"/>}
          
          <div className="container">
            <div className="row lc_mapview">
              <div className="col-md-8">
                <div className="lc_map">
                  <div className="map-alignment">
                    <div id={"map"+this.state.communityObject.community_id} className="map-alignment"></div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                  <p>WHAT’S MY COMMUTE?</p>
                  <input type="text" id="txtPlaces" placeholder="Enter a location" onChange={this.destinationLocation} />
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-xs-6">
                      <button type="button" className="location_btn" onClick={this.handleFormSubmit}>Search</button>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-6">
                      <button type="button" className="location_btn" onClick={this.resetData}>Reset</button>
                    </div>
                  </div>
                  <div id="dvDistance"></div>
                <SearchMap latitude={this.state.communityObject.latitude} longitude={this.state.communityObject.longitude} communityName={this.state.communityObject.community_name} communityObject={this.state.communityObject} handleFormSubmit={this.handleFormSubmit} status={1}/>
                </div>
            </div>
            <div className="white-gap"></div>   
          </div>{/*
          <RecentlyViewed self={this} favouriteCommunity={this.favouriteCommunity} recentlyViewData={this.state.recentlyViewData} userFavourites={this.state.userFavourites} unFavouriteCommunity={this.unFavouriteCommunity} moreInfoMethod={this.moreInfoMethod} redirectLink="2" headerText="YOU MAY ALSO LIKE" companyName={this.props.match.params.companyname} spinner={this.state.spinner}/>*/}</div>{/* {this.state.communityALLImages.length > 0 ?
        : null} */}
        <Login/>
        {this.state.calendarStatus
          ? <CommunityBookNowModal  calendarClose={this.calendarClose} CommunityDetails={this.state.communityObject} />:''}
         <ReservationSuccesModal/>  
        <SubFooter />
        <Footer/>        
      </div>
   
       );
     }
   }
   export default (CommunityLocation) ;