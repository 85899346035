/* Importing the node modules, child components, services and controllers used 
   inside LoginmodalController object */
   'use strict'
    import SuccessModal from '../common/successModal';
   class LoginmodalController extends React.Component{
        constructor(props) {
            super(props)
            /* LoginmodalController Component State variables Initialization */
            this.state = {
                guest: 0,
                company:1
            }
            this.imgChange = this.imgChange.bind(this);
            this.linkTo = this.linkTo.bind(this);
          }

imgChange(data){
            data==1?this.setState({ guest: 0,company:1}):this.setState({ guest: 1,company:0})
}
   linkTo(data){
    if(data=='guest'){
        location.href="/guest-login"
    }  else{
        location.href="/client-login"
    } 

   }
       render(){
        return(
        <div>
          <div className="modal fade savesearch" id="loginModal" role="dialog">
          <div className="modal-dialog">
              <div className="modal-content rm-border-radius">
                  <div className="modal-header">
                  <button type="button" className="close btn" data-dismiss="modal" aria-label="">
                  <i className="bi bi-x"></i>
                  </button>                    
                  </div>
                  <h4 className="text-center">Please login to SuiteAmerica Experience</h4>
                  <div className="modal-body text-center login_cnfm_user">

                    <div className="col-md-6">
                        <img className="pointer" src={this.state.guest==0?"https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/Guest_red.png":"https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/Guest.png"} 
                        onMouseEnter={() => this.imgChange(1)} 
                        onClick={() => this.linkTo('guest')}/>
                        <p>Guest Portal</p>
                    </div>
                    <div className="col-md-6">
                        <img className="pointer" src={this.state.company==1?"https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/Company.png":"https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/Comapny_red.png"}  
                        onMouseEnter={() => this.imgChange(0)} 
                        onClick={() => this.linkTo('clinet')}/>
                        <p>Client Portal</p>
                    </div>
                 <div className="clearfix"></div>
                 
                  </div>
              </div>
              
          </div>
          
      </div>
      <SuccessModal/>
</div>
            
        )
       }
           
       
      
   }
   
   export default LoginmodalController