const StateCityInfo = (props) => {
    console.log(props,"props");
    
           return ( 
                <div className="container">
                    <div className="mrg-top-25">
                    <div className="spotlight-cities">
                        <div>
                            <div className="cities-wrap">
                                <ul>
                                <span>North California</span>
                                {props.cities.NorthCalifornia.map((row,index)=>{
                                return(
                                <li key={index} style={{cursor: 'pointer'}} onClick={() => props.onClickCity(row)}>
                                {row.city}
                                </li>
                                )
                                })} 
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className="cities-wrap">
                                <ul>
                                <span>South California</span>
                                {props.cities.SouthCalifornia.map((row,index)=>{
                                return(
                                <li key={index} style={{cursor: 'pointer'}} onClick={() => props.onClickCity(row)}>
                                {row.city}
                                </li>
                                )
                                })} 
                                </ul>
                            </div>
                        </div>
                        <div>
                            
                           
                            <div className="cities-wrap">
                                <ul>
                                <span>WA</span>
                                {props.cities.wa.map((row,index)=>{
                                return(
                                <li key={index} style={{cursor: 'pointer'}} onClick={() => props.onClickCity(row)}>
                                {row.city}
                                </li>
                                )
                                })} 
                                </ul>
                            </div>
                            <div className="cities-wrap">
                                <ul>
                                <span>OR</span>
                                {props.cities.or.map((row,index)=>{
                                return(
                                <li key={index} style={{cursor: 'pointer'}} onClick={() => props.onClickCity(row)}>
                                {row.city}
                                </li>
                                )
                                })} 
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
         
           );

   }
   export default StateCityInfo;
   