import S3Upload from 'react-s3-uploader/s3upload'

class InspectionFormDining extends React.Component {
    constructor(props) {
        super(props)
        this.state={
            filesUpload:false,
            filesList:[]
        }
        this.setChecked = this.setChecked.bind(this);
        this.setValue = this.setValue.bind(this);
        this._handleImageChange =this._handleImageChange.bind(this);
    }
    setChecked(field, e) {
        var object = {};
        object[field] = e.target.value;
        this.setState(object);
        this.props.setChecked(object)
    }
    setValue(field, e) {
        var object = {};
        object[field] = e.target.value;
        this.setState(object);
        this.props.setValue(object)
    }
    _handleImageChange(type,index,e){
        e.preventDefault();
        let uniqueId=this.props.requestDetails.UniqueId.replace(/ /g,"_")
        uniqueId=_.toUpper(uniqueId);
        var path=this.props.requestDetails.type+'/'+uniqueId+'/'+moment().format("MM-DD-YYYY")+'/'
        if(this.state.filesList.length<1)
        {
             let reader = new FileReader();
            
             const fileIndex = e
                 .target
                 .name
                 .split('-')
                  const _this = this
                 _.forEach(e.target.files, function(file) {
                     const S3Uploader = new S3Upload({
                         files: [file],
                         signingUrl: '/s3/sign',
                         s3path: path,
                         signingUrlQueryParams: {},
                         onProgress: _this.onProgress.bind(_this, type, index),
                         onFinishS3Put: _this.onFinish.bind(_this, type, index, file),
                         onError: _this.onError.bind(_this, type, index),
                         uploadRequestHeaders: {
                             'x-amz-acl': 'public-read'
                         },
                         contentDisposition: file.type,
                         server: ''
                     }) 
                     
                 })
             }
     else{
         this.setState({message:'Please select only four files',color:'red'})
         setTimeout(function() {
             this.setState({message:'',color:''})
         }.bind(this), 3000);
     }

    }

    async onProgress(type, fileIndex, progress) {
        this.setState({filesUpload: true})
       
}
/* Error event for uploading image into s3 bucket */    
onError(type, fileIndex, err) {
}
async onFinish(type, fileIndex, FileDetails, info) {
const filepath = 'https://suiteam-website.s3.amazonaws.com/' + info.filename;
await this.setState( {filesUpload : false});
await this.props.filesListUpdate(filepath, FileDetails, info ,-1,'dining')
}
    render() {

        return (
            <div>
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <h4 className="panel-title">
                            <a
                                className="collapsed"
                                data-toggle="collapse"
                                data-parent="#accordion"
                                href="#dining"
                                aria-expanded="true"
                                aria-controls="collapse8">
                                Dining Room
                            </a>
                        </h4>
                    </div>
                    <div id="dining" className="panel-collapse collapse">
                        <div className="panel-body">
                            <div className="profile-opener">
                                <div className="row">
                                    <div className="col-sm-6 col-md-6">
                                        <div className="row radiosec">
                                            <div className="col-sm-12">
                                                <label className="col-sm-3">Carpet</label>

                                                <div className="col-sm-2">
                                                    <label className="custom_radio radio-inline">Ok
                                                        <input
                                                            type="radio"
                                                            name="17"
                                                            value="Ok"
                                                            onChange={(e) => this.setChecked('carpet', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <label className="custom_radio radio-inline">Has Stains
                                                        <input
                                                            type="radio"
                                                            name="17"
                                                            value="Has Stains"
                                                            onChange={(e) => this.setChecked('carpet', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="row radiosec">
                                            <div className="col-sm-12">
                                                <label className="col-sm-3">Mirror/Artwork</label>

                                                <div className="col-sm-2">
                                                    <label className="custom_radio radio-inline">Ok
                                                        <input
                                                            type="radio"
                                                            name="18"
                                                            value="Ok"
                                                            onChange={(e) => this.setChecked('mirror', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <label className="custom_radio radio-inline">Replace
                                                        <input
                                                            type="radio"
                                                            name="18"
                                                            value="Replace"
                                                            onChange={(e) => this.setChecked('mirror', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-sm-6 col-md-6">
                                        <div className="row radiosec">
                                            <div className="col-sm-12">
                                                <label className="col-sm-3">Dining Chairs</label>

                                                <div className="col-sm-2">
                                                    <label className="custom_radio radio-inline">Ok
                                                        <input
                                                            type="radio"
                                                            name="19"
                                                            value="Ok"
                                                            onChange={(e) => this.setChecked('diningChairs', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <label className="custom_radio radio-inline">Replace
                                                        <input
                                                            type="radio"
                                                            name="19"
                                                            value="Replace"
                                                            onChange={(e) => this.setChecked('diningChairs', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="row radiosec">
                                            <div className="col-sm-12">
                                                <label className="col-sm-3">Placemats/Napkins</label>

                                                <div className="col-sm-2">
                                                    <label className="custom_radio radio-inline">Ok
                                                        <input
                                                            type="radio"
                                                            name="20"
                                                            value="Ok"
                                                            onChange={(e) => this.setChecked('placemats', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <label className="custom_radio radio-inline">Replace
                                                        <input
                                                            type="radio"
                                                            name="20"
                                                            value="Replace"
                                                            onChange={(e) => this.setChecked('placemats', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-sm-6 col-md-6">
                                        <div className="row radiosec">
                                            <div className="col-sm-12">
                                                <label className="col-sm-3">Carpet Fraying/Damaged</label>

                                                <div className="col-sm-2">
                                                    <label className="custom_radio radio-inline">Ok
                                                        <input
                                                            type="radio"
                                                            name="21"
                                                            value="Ok"
                                                            onChange={(e) => this.setChecked('carpetDamaged', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <label className="custom_radio radio-inline">Replace
                                                        <input
                                                            type="radio"
                                                            name="21"
                                                            value="Replace"
                                                            onChange={(e) => this.setChecked('carpetDamaged', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="row radiosec">
                                            <div className="col-sm-12">
                                                <label className="col-sm-3">Smoke Detector Battery</label>

                                                <div className="col-sm-2">
                                                    <label className="custom_radio radio-inline">Ok
                                                        <input
                                                            type="radio"
                                                            name="22"
                                                            value="Ok"
                                                            onChange={(e) => this.setChecked('smokeDetectorBattery', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <label className="custom_radio radio-inline">Replace
                                                        <input
                                                            type="radio"
                                                            name="22"
                                                            value="Replace"
                                                            onChange={(e) => this.setChecked('smokeDetectorBattery', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-sm-6 col-md-6">
                                        <div className="row radiosec">
                                            <div className="col-sm-12">
                                                <label className="col-sm-3">Dining Table</label>

                                                <div className="col-sm-2">
                                                    <label className="custom_radio radio-inline">Ok
                                                        <input
                                                            type="radio"
                                                            name="23"
                                                            value="Ok"
                                                            onChange={(e) => this.setChecked('diningTable', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <label className="custom_radio radio-inline">Replace
                                                        <input
                                                            type="radio"
                                                            name="23"
                                                            value="Replace"
                                                            onChange={(e) => this.setChecked('diningTable', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="row radiosec">
                                            <div className="col-sm-12">
                                                <label className="col-sm-3">Floor Lamp</label>

                                                <div className="col-sm-2">
                                                    <label className="custom_radio radio-inline">Ok
                                                        <input
                                                            type="radio"
                                                            name="24"
                                                            value="Ok"
                                                            onChange={(e) => this.setChecked('floorLamp', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <label className="custom_radio radio-inline">Replace
                                                        <input
                                                            type="radio"
                                                            name="24"
                                                            value="Replace"
                                                            onChange={(e) => this.setChecked('floorLamp', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-sm-6 col-md-6">
                                        <div className="row radiosec">
                                            <div className="col-sm-12">
                                                <label className="col-sm-3">Celing Fixture Light Out
                                                </label>

                                                <div className="col-sm-2">
                                                    <label className="custom_radio radio-inline">Ok
                                                        <input
                                                            type="radio"
                                                            name="25"
                                                            value="Ok"
                                                            onChange={(e) => this.setChecked('celing', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <label className="custom_radio radio-inline">Replace
                                                        <input
                                                            type="radio"
                                                            name="25"
                                                            value="Replace"
                                                            onChange={(e) => this.setChecked('celing', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="row radiosec">
                                            <div className="col-sm-12">
                                                <label className="col-sm-3">Smoke Detector</label>

                                                <div className="col-sm-2">
                                                    <label className="custom_radio radio-inline">Ok
                                                        <input
                                                            type="radio"
                                                            name="26"
                                                            value="Ok"
                                                            onChange={(e) => this.setChecked('smokeDetector', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <label className="custom_radio radio-inline">Replace
                                                        <input
                                                            type="radio"
                                                            name="26"
                                                            value="Replace"
                                                            onChange={(e) => this.setChecked('smokeDetector', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-sm-6 col-md-6">
                                        <div className="row radiosec">
                                            <div className="col-sm-12">
                                                <label className="col-sm-3">Check Under Furniture</label>

                                                <div className="col-sm-2">
                                                    <label className="custom_radio radio-inline">Ok
                                                        <input
                                                            type="radio"
                                                            name="27"
                                                            value="Ok"
                                                            onChange={(e) => this.setChecked('diningFurniture', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="row radiosec">
                                            <div className="col-sm-12">
                                                <label className="col-sm-3">Touch up Paint</label>

                                                <div className="col-sm-3">
                                                    <label className="custom_radio radio-inline">Completed
                                                        <input
                                                            type="radio"
                                                            name="28"
                                                            value="Completed"
                                                            onChange={(e) => this.setChecked('touchUpPaint', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <label className="custom_radio radio-inline">Detailer
                                                        <input
                                                            type="radio"
                                                            name="28"
                                                            value="Detailer"
                                                            onChange={(e) => this.setChecked('touchUpPaint', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>

                                                <div className="col-sm-2 col-md-offset-3">
                                                    <label className="custom_radio radio-inline">Ok,Fixed
                                                        <input
                                                            type="radio"
                                                            name="28"
                                                            value="Ok,Fixed"
                                                            onChange={(e) => this.setChecked('touchUpPaint', e)}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>
                                {this.state.filesUpload ? <div className="text-center"><span><i className="fa fa-spinner fa-spin"/> please wait file is uploading...!</span></div> : '' }
                                { this.props.diningfilesLists.length <1 && !this.state.filesUpload ? <div className="col-sm-12"> 
                                <div className="form-group">
                  <input type="file" name="file-2[]" placeholder="jjj" id="file-2" className="inputfile inputfile-6" data-multiple-caption="{count} files selected" onChange={(e) => this._handleImageChange(2,0,e)} />
                  <label htmlFor="file-2"><span>Upload Attachment (Doc, PDF, JPG, ect.)</span> <strong><img src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Form-Cloud-Upload.png"} width="45" alt="" /></strong></label>
               </div>
               </div> : ''}
               {this.props.diningfilesLists.length>0 ?
            
            <div className="col-sm-12">
            {this.props.diningfilesLists.map((row, index) => {
                   return(
               <div className="intra_uploadfile"  key={index}>
             
                  <ul>
                     <li><span>File Name</span></li>
                     <li><span>File Size</span></li>
                  </ul>
                  <ul>
                     <li>{row.FileDetails.name}</li>
                     <li>{row.FileDetails.size} kb</li>
                  </ul>  
                 
               </div> )})}
            </div>:null}

                                <div className="row">
                                    <div className="col-sm-12 col-md-12">
                                        <textarea
                                            className="form-control"
                                            placeholder="Comments/Notes"
                                            value={this.props.diningComments}
                                            onChange={(e) => this.setValue('diningComments', e)}
                                            rows="4"
                                            cols="5"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default InspectionFormDining