/* Importing the node modules, child components, services and controllers used 
   inside NoMatchFound component */ 
   import MainController from "../controller/mainController";
   import SubFooter from "./subFooter";
   import GuestHeader from './guestHeader';
   import Footer from './footer';
   /* NoMatchFound Component initialization */
   class ServerErrors extends React.Component {
        /* Initializing objects of its NoMatchFound class */
        constructor(props) {
            super(props);    
            this.state={
                errorMessage:''
            }       
        }         
        /* It is invoked immediately before mounting occurs */
        componentWillMount() {
            MainController.getInitialInformation();
            this.errorMessages();
        }
        errorMessages(){
            switch (this.props.match.params.status) {
                case '400':
                this.setState({errorMessage:'Your request details are in correct'})
                break;
                case '411':
                this.setState({errorMessage:'Your request details are in correct'})
                break;
                case '412':
                this.setState({errorMessage:'Your request details are in correct'})
                break;
                default:
                this.setState({errorMessage:''})
                    break;
            }
        }
     
       /* It is invoked to return html content */
       render() {   
           const {errorMessage}=this.state
           return (
               <div className="corporateHousing_Updates">
                    <GuestHeader/>
                    <header className="codeconveyHeader cc_image_text_main corporate-h">
                        <img className="img-responsive" src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Page-Not-Found.jpg"} width="100%" alt=""  />
                        <div className="wow fadeInUp img-on-text top30 width800 text-center img-on-text-center-w page_not_found" data-wow-delay="0.5s">
                        <div className="page_not_found_dtls">
                            <p>Error {this.props.match.params.status}</p>
                            <h1>WE’RE SORRY!</h1>
                            <p>{errorMessage}</p>
                        </div>
                        </div>  
                    </header>
                    <SubFooter/>
                    <Footer/>
               </div>
           );
       }
   }
   export default ServerErrors;