/* Importing the node modules, child components, services and controllers used 
   inside NikeReservationForm component */
   import $ from "jquery";
   import DatePicker from "react-datepicker";
   import moment from "moment";
   import AppController from "../../controller/appController";
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import LinkedinInternsHeader from "./common/LinkedinInternsHeader";
   import NikeServices from "../../../services/nikeService";
   import GuestHeader from "../../common/guestHeader";
   import Footer from "../../common/footer";
   import ReCAPTCHA from "react-google-recaptcha";
   import SuccessModal from "../../common/successModal";
   import Disclaimer from "./common/disclimar";
   import SuccessModalLinkedIn from "./common/successModal";
   import SubFooterLinkedIn from "./common/subFooterlinkedIn";
   import ReserveNowApi from "../../../services/reserveNowApi";
   /* NikeReservationForm Component initialization */
   class LinkedinInternsReservationForm extends React.Component {
     /* Initializing objects of its NikeReservationForm class */
     constructor(props) {
       super(props);
       this.state = {
         CaptchaValue: "",
         firstName: "",
         lastName: "",
         address: "",
         city: "",
         state: "",
         zipCode: "",
         email: "",
         phone: "",
         emergencyContact: "",
         emergencyPhoneNumber: "",
         company: "",
         moveIn: null,
         moveOut: null,
         isChecked: false,
         selectedGender: "",
         roommateGenderPreference: "",
         pet: "",
         message: "",
         color: "",
         errorMessage: "",
         budget: "$3500-$4000/month",
         appartmentSize: "",
         needParking: "",
         preferredRoommateName_1: "",
         preferredRoommateEmail_1: "",
         preferredRoommateName_2: "",
         preferredRoommateEmail_2: "",
         preferredRoommateName_3: "",
         preferredRoommateEmail_3: "",
         budgetDropDown: [
           "$3500-$4000/month",
           "$4001-$4500/month",
           "$4501-$5000/month",
           "$5001-$5500/month",
           "$5501/month or higher",
         ],
       };
       // this.handleMoveIn = this.handleMoveIn.bind(this);
       // this.handleMoveOut = this.handleMoveOut.bind(this);
       this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
       this.setValue = this.setValue.bind(this);
       this.setChecked = this.setChecked.bind(this);
       this.serviceRequest = this.serviceRequest.bind(this);
       // this.sendEmailnBtn = this.sendEmailnBtn.bind(this);
       this.onChangeCaptcha = this.onChangeCaptcha.bind(this);
       this.renderRadioButton = this.renderRadioButton.bind(this);
       this.handleKeyDown = this.handleKeyDown.bind(this);
     }
   
     /* It is invoked immediately before mounting occurs */
     componentWillMount() {
       MainController.getInitialInformation();
     }
     /* Form Details object creations  */
     setValue(field, e) {
       let value;
       if (field === "phone" || field === "emergencyPhoneNumber") {
         value = e.target.value.replace(/[^0-9]/g, "");
       } else {
         value = e.target.value;
       }
       this.setState({ [field]: value });
     }
     handleCheckboxChange() {
       // console.log("hello");
       this.setState((prevState) => ({
         isChecked: !prevState.isChecked,
       }));
     }
     handleMoveInDate = (moveIn) => {
       // console.log(moveIn);
       this.setState({ moveIn });
     };
     handleChangeMoveOutDate = (moveOut) => {
       this.setState({ moveOut });
     };
     handleKeyDown = (e) => {
       // Check if the left arrow key is pressed
       if (e.key === "ArrowLeft") {
         const newPosition = e.target.selectionStart - 1;
         e.target.setSelectionRange(newPosition, newPosition);
       }
   
       // Check if the right arrow key is pressed
       if (e.key === "ArrowRight") {
         const newPosition = e.target.selectionStart + 1;
         e.target.setSelectionRange(newPosition, newPosition);
       }
     };
     /* Form Details object creations  */
     setChecked(e) {
       this.setState({ pet: e.target.value });
     }
   
     async onChangeCaptcha(CaptchaValue) {
       this.setState({ CaptchaValue });
     }
   
     renderRadioButton(label, value, stateKey) {
       return (
         <label className="custom_radio radio-inline" key={value}>
           {label}
           <input
             type="radio"
             name={stateKey}
             value={value}
             checked={this.state[stateKey] == label ? true : false}
             onChange={(e) => this.setValue(stateKey, e)}
           />
           <span className="checkmark"></span>
         </label>
       );
     }
     /* Service Request Details send to mail  */
     async serviceRequest() {
       var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
   
       const _this = this;
       const validationFields = [
         { key: "firstName", errorValue: "First Name" },
         { key: "lastName", errorValue: "Last Name" },
         { key: "address", errorValue: "Address" },
         { key: "city", errorValue: "City" },
         { key: "state", errorValue: "State" },
         { key: "zipCode", errorValue: "Zip Code" },
         { key: "email", errorValue: "E-mail Address" },
         { key: "phone", errorValue: "Phone Number" },
         { key: "emergencyContact", errorValue: "Emergency Contact " },
         { key: "emergencyPhoneNumber", errorValue: "Emergency Phone Number" },
         { key: "company", errorValue: "Company Name" },
         { key: "selectedGender", errorValue: "Gender" },
         { key: "appartmentSize", errorValue: "Appartment Size" },
         // { key: "budget", errorValue: "Budget" },
         // { key: "pet", errorValue: "pet Information" },
         // { key: "roommateGenderPreference", errorValue: "Roommate Preference" },
         { key: "isChecked", errorValue: "Check the Terms & conditions" },
         { key: "CaptchaValue", errorValue: "Captcha" },
       ];
       const errors = [];
       let error = 0;
       _.forEach(validationFields, function (field) {
         if (_this.state[field.key] == "") {
           error++;
           errors.push(field.errorValue);
         }
       });
       _.forEach(validationFields, function (field) {
         if (_this.state[field.key] === false) {
           error++;
           errors.push(field.errorValue);
         }
       });
       _.forEach(validationFields, function (value) {
         if (!reg.test(_this.state.email)) {
           error++;
         }
       });
       if (error == 0) {
         let userInfo = {
           address: this.state.address,
           city: this.state.city,
           state: this.state.state,
           zipCode: this.state.zipCode,
           emergencyContact: this.state.emergencyContact,
           emergencyPhoneNumber: this.state.emergencyPhoneNumber,
           company: this.state.company,
           selectedGender: this.state.selectedGender,
           appartmentSize: this.state.appartmentSize,
           preferredRoommateName_1: this.state.preferredRoommateName_1,
           preferredRoommateEmail_1: this.state.preferredRoommateEmail_1,
           preferredRoommateName_2: this.state.preferredRoommateName_2,
           preferredRoommateEmail_2: this.state.preferredRoommateEmail_2,
           preferredRoommateName_3: this.state.preferredRoommateName_3,
           preferredRoommateEmail_3: this.state.preferredRoommateEmail_3,
           message: this.state.message,
         };
         const reserveYourStay = {
           Location: "Cupertino, CA 95014, USA",
           MoveInDate: moment(this.state.moveIn).format("YYYY-MM-DD"),
           MoveOutDate: moment(this.state.moveOut).format("YYYY-MM-DD"),
           Size: "1X",
           SpecialNote: JSON.stringify(userInfo),
           GuestFirstName: this.state.firstName,
           GuestLastName: this.state.lastName,
           ClientReference: "LinkedinInternReservation",
           GuestEmail: this.state.email,
           GuestPhone: this.state.phone,
           Parking: this.state.needParking,
           length: 0,
           CreatedBy: this.state.email != "" ? this.state.email : "---",
           BookedBy: "ashok@suiteamerica.com",
           PhContryCode: "+1",
         };
         // console.log("reserveYourStay", reserveYourStay);
         const orderResponse = await ReserveNowApi.webLeadQuotes(reserveYourStay);
         this.setState({ orderId: orderResponse.message.orderId });
         if (orderResponse.error == 0 && orderResponse.message.orderId != "") {
           const Request = {
             orderId: this.state.orderId,
             firstName: this.state.firstName,
             lastName: this.state.lastName,
             address: this.state.address,
             city: this.state.city,
             state: this.state.state,
             zipCode: this.state.zipCode,
             email: this.state.email,
             phone: this.state.phone,
             emergencyContact: this.state.emergencyContact,
             emergencyPhoneNumber: this.state.emergencyPhoneNumber,
             company: this.state.company,
             moveIn: moment(this.state.moveIn).format("YYYY-MM-DD"),
             moveOut: moment(this.state.moveOut).format("YYYY-MM-DD"),
             selectedGender: this.state.selectedGender,
             appartmentSize: this.state.appartmentSize,
             // budget: this.state.budget,
             // pet: this.state.pet,
             // roommateGenderPreference: this.state.roommateGenderPreference,
             needParking: this.state.needParking,
             preferredRoommateName_1: this.state.preferredRoommateName_1,
             preferredRoommateEmail_1: this.state.preferredRoommateEmail_1,
             preferredRoommateName_2: this.state.preferredRoommateName_2,
             preferredRoommateEmail_2: this.state.preferredRoommateEmail_2,
             preferredRoommateName_3: this.state.preferredRoommateName_3,
             preferredRoommateEmail_3: this.state.preferredRoommateEmail_3,
             message: this.state.message,
   
             mail: "LinkedinInternReservation",
             subject: `LinkedIn Company Reservation Form Order Id : #${this.state.orderId}`,
           };
           $("#successModalLinkedIn").modal("show");
           await NikeServices.serviceRequest(Request);
           this.setState({
             firstName: "",
             lastName: "",
             address: "",
             city: "",
             state: "",
             zipCode: "",
             email: "",
             phone: "",
             emergencyContact: "",
             emergencyPhoneNumber: "",
             moveIn: null,
             moveOut: null,
             isChecked: false,
             company: "",
             selectedGender: "",
             // roommateGenderPreference: "",
   
             appartmentSize: "",
             // pet: "",
             // budget: "$3500-$4000/month",
             needParking: "",
             message: "",
             color: "",
             errorMessage: "",
             preferredRoommateName_1: "",
             preferredRoommateEmail_1: "",
             preferredRoommateName_2: "",
             preferredRoommateEmail_2: "",
             preferredRoommateName_3: "",
             preferredRoommateEmail_3: "",
           });
           $('input[name="radio"]').prop("checked", false);
         } else {
           this.setState({
             errorMessage: orderResponse.message,
             color: "red",
           });
         }
       } else {
         this.setState({
           errorMessage: `Please Enter Required Fields ${errors.join(", ")}`,
           color: "red",
         });
       }
     }
   
     // async sendEmailnBtn() {
     //   // $("#acknowledge").modal("hide");
   
     //   const Request = {
     //     firstName: this.state.firstName,
     //     lastName: this.state.lastName,
     //     address: this.state.address,
     //     city: this.state.city,
     //     state: this.state.state,
     //     zipCode: this.state.zipCode,
     //     email: this.state.email,
     //     phone: this.state.phone,
     //     emergencyContact: this.state.emergencyContact,
     //     emergencyPhoneNumber: this.state.emergencyPhoneNumber,
     //     company: this.state.company,
     //     moveIn: moment(this.state.moveIn).format("YYYY-MM-DD"),
     //     moveOut: moment(this.state.moveOut).format("YYYY-MM-DD"),
     //     selectedGender: this.state.selectedGender,
     //     appartmentSize: this.state.appartmentSize,
     //     // budget: this.state.budget,
     //     // pet: this.state.pet,
     //     // roommateGenderPreference: this.state.roommateGenderPreference,
     //     needParking: this.state.needParking,
     //     preferredRoommateName_1: this.state.preferredRoommateName_1,
     //     preferredRoommateEmail_1: this.state.preferredRoommateEmail_1,
     //     preferredRoommateName_2: this.state.preferredRoommateName_2,
     //     preferredRoommateEmail_2: this.state.preferredRoommateEmail_2,
     //     preferredRoommateName_3: this.state.preferredRoommateName_3,
     //     preferredRoommateEmail_3: this.state.preferredRoommateEmail_3,
     //     message: this.state.message,
   
     //     mail: "LinkedinInternReservation",
     //     subject: "LinkedIn Company Reservation Form",
     //   };
     //   // AppController.showSuccessModal();
     //   $("#successModalLinkedIn").modal("show");
   
     //   await NikeServices.serviceRequest(Request);
     //   this.setState({
     //     firstName: "",
     //     lastName: "",
     //     address: "",
     //     city: "",
     //     state: "",
     //     zipCode: "",
     //     email: "",
     //     phone: "",
     //     emergencyContact: "",
     //     emergencyPhoneNumber: "",
     //     moveIn: null,
     //     moveOut: null,
     //     company: "",
     //     selectedGender: "",
     //     // roommateGenderPreference: "",
   
     //     appartmentSize: "",
     //     // pet: "",
     //     // budget: "$3500-$4000/month",
     //     needParking: "",
     //     message: "",
     //     color: "",
     //     errorMessage: "",
     //     preferredRoommateName_1: "",
     //     preferredRoommateEmail_1: "",
     //     preferredRoommateName_2: "",
     //     preferredRoommateEmail_2: "",
     //     preferredRoommateName_3: "",
     //     preferredRoommateEmail_3: "",
     //   });
     //   $('input[name="radio"]').prop("checked", false);
     // }
     /* It is invoked to return html content */
     render() {
       const genderOptions = ["Female", "Male", "Non-Binary"];
       const roommateGenderPreferenceOptions = [
         "Female",
         "Male",
         "Non-Binary",
         "Non-Conforming",
         "Gender Queer",
         "Trans",
         "Trans Female",
         "Trans Male",
         "Gender Fluid",
         "No Preference",
       ];
       const appartmentSizeDropDown = [
         {
           option:
             "$2020/month per person - QUAD - (4 people share a 2 bedroom 2 bath)(Each bedroom has 2 Twin Beds)",
           value:
             "$2020/month per person - QUAD - (4 people share a 2 bedroom 2 bath)(Each bedroom has 2 Twin Beds)",
         },
         {
           option:
             "$3135/month per person - SINGLE DOUBLE - (2 people share a 1 bedroom 1 bath)(The bedroom has 2 Twin Beds)",
           value:
             "$3135/month per person - SINGLE DOUBLE - (2 people share a 1 bedroom 1 bath)(The bedroom has 2 Twin Beds)",
         },
         {
           option:
             "$4035/month per person - DOUBLE - (2 people share a 2 bedroom 2 bath)(Each bedroom has a Queen Bed)",
           value:
             "$4035/month per person - DOUBLE - (2 people share a 2 bedroom 2 bath)(Each bedroom has a Queen Bed)",
         },
         {
           option:
             "$6270/month - SINGLE - (The bedroom has a Queen Bed)(Private Apartment)",
           value:
             "$6270/month - SINGLE - (The bedroom has a Queen Bed)(Private Apartment)",
         },
       ];
       return (
         <div className="corporateHousing_Updates">
           <GuestHeader />
           <div className="cover-tophd-ht"></div>
           <LinkedinInternsHeader />
           <div
             className="container-fluid nike company_section "
             id="serviceRequest"
           >
             <div className="container text-left ">
               <div className="SAweb_updateUi bg-white">
                 <h3>Ready to Begin?</h3>
                 <h3 className="">Ready to Start Your Search?</h3>
                 <p className="p fw-300 py-4">
                   Fill out the form below to request a potential rooming
                   arrangement.
                 </p>{" "}
                 <h3>
                   Please submit your rooming form at least 45 days prior to your
                   internship start date.
                 </h3>
                 <p className="p fw-300">
                   Once you submit your request, you will receive an email
                   confirmation that the request was received. Your request will be
                   processed and you will hear from us 30-45 days prior to move-in
                   with rooming assignments via email.
                 </p>
               </div>
             </div>
           </div>
   
           <div className="container-fluid nike-datepicker bg-grey service_request">
             <div className="request_form company_form">
               <div className="container">
                 <form className="well">
                   <p style={{ color: this.state.color }}>
                     {this.state.errorMessage}
                   </p>
                   <h1>Contact Information</h1>
   
                   <div className="row pt-4">
                     <div className="col-sm-6 col-md-6">
                       <input
                         type="text"
                         className="form-control"
                         placeholder="*First Name"
                         value={this.state.firstName}
                         onChange={(e) => this.setValue("firstName", e)}
                         onKeyDown={this.handleKeyDown}
                       />
                       <input
                         type="text"
                         className="form-control"
                         placeholder="*Last Name"
                         value={this.state.lastName}
                         onChange={(e) => this.setValue("lastName", e)}
                         onKeyDown={this.handleKeyDown}
                       />
                       <input
                         type="text"
                         className="form-control"
                         placeholder="*Address"
                         value={this.state.address}
                         onChange={(e) => this.setValue("address", e)}
                         onKeyDown={this.handleKeyDown}
                       />
                       <input
                         type="text"
                         className="form-control"
                         placeholder="*City"
                         value={this.state.city}
                         onChange={(e) => this.setValue("city", e)}
                         onKeyDown={this.handleKeyDown}
                       />
                       <div className="row">
                         <div className="col-sm-6">
                           <input
                             type="text"
                             className="form-control"
                             placeholder="*State"
                             value={this.state.state}
                             onChange={(e) => this.setValue("state", e)}
                             onKeyDown={this.handleKeyDown}
                           />
                         </div>
                         <div className="col-sm-6">
                           <input
                             type="text"
                             className="form-control"
                             placeholder="*Zip Code"
                             value={this.state.zipCode}
                             onChange={(e) => this.setValue("zipCode", e)}
                             onKeyDown={this.handleKeyDown}
                           />
                         </div>
                       </div>
                     </div>
                     <div className="col-sm-6 col-md-6">
                       <input
                         type="text"
                         className="form-control"
                         placeholder="*E-mail Address"
                         value={this.state.email}
                         onChange={(e) => this.setValue("email", e)}
                         onKeyDown={this.handleKeyDown}
                       />
                       <input
                         type="text"
                         className="form-control"
                         placeholder="*Phone"
                         value={this.state.phone}
                         onChange={(e) => this.setValue("phone", e)}
                         onKeyDown={this.handleKeyDown}
                       />
                       <input
                         type="text"
                         className="form-control"
                         placeholder="*Emergancy Contact"
                         value={this.state.emergencyContact}
                         onChange={(e) => this.setValue("emergencyContact", e)}
                         onKeyDown={this.handleKeyDown}
                       />
                       <input
                         type="text"
                         className="form-control"
                         placeholder="*Emergancy Contact Phone Number"
                         value={this.state.emergencyPhoneNumber}
                         onChange={(e) => this.setValue("emergencyPhoneNumber", e)}
                         onKeyDown={this.handleKeyDown}
                       />
   
                       <input
                         type="text"
                         className="form-control"
                         placeholder="*Company"
                         value={this.state.company}
                         onChange={(e) => this.setValue("company", e)}
                         onKeyDown={this.handleKeyDown}
                       />
                     </div>
                   </div>
                   <hr />
                   <h1>Internship Dates</h1>
                   <div>
                     <div className="d-flex justify-content-between align-items-center ">
                       <div className="w-45">
                         <DatePicker
                           id="moveIn"
                           selected={this.state.moveIn}
                           dateFormat="MM/DD/yyyy "
                           selectsStart
                           minDate={new Date()}
                           maxDate={this.state.moveOut}
                           onChange={this.handleMoveInDate}
                           // showTimeSelect
                           placeholderText={"Move In"}
                           onKeyDown={(e) => e.preventDefault()}
                           className="form-control"
                           autoComplete="off"
                         />
                       </div>
                       <div className="w-45">
                         <DatePicker
                           id="moveOut"
                           dateFormat="MM/DD/yyyy"
                           selected={this.state.moveOut}
                           selectsEnd
                           autoComplete="off"
                           startDate={this.state.moveIn}
                           endDate={this.state.moveOut}
                           onChange={this.handleChangeMoveOutDate}
                           minDate={this.state.moveIn}
                           // showTimeSelect
                           placeholderText={"Move Out"}
                           onKeyDown={(e) => e.preventDefault()}
                           className="form-control "
                         />
                       </div>
                     </div>
                     <h3 className="text-dark">
                       <b>
                         *MOVE IN DAY is as after 4pm on the friday before your
                         internship starts and MOVE OUT DAY is before midnight on
                         the Sunday after your internship ends*
                       </b>
                     </h3>
                   </div>
   
                   <hr />
                   <h1>Gender</h1>
                   <div className="row-radio-btns pt-4">
                     {genderOptions.map((option) => (
                       <div className="item">
                         {this.renderRadioButton(option, option, "selectedGender")}
                       </div>
                     ))}
                   </div>
                   <hr />
   
                   <h1>Housing Information</h1>
                   <div className="row pt-4">
                     <div className="col-sm-6 col-md-6">
                       <label className="custom-select">
                         <select
                           value={this.state.appartmentSize}
                           onChange={(e) => this.setValue("appartmentSize", e)}
                         >
                           <option value="">None Selected</option>
                           {appartmentSizeDropDown.map((row, index) => {
                             return (
                               <option key={index} value={row.value}>
                                 {row.option}
                               </option>
                             );
                           })}
                         </select>
                       </label>
                       {/* <label className="custom-select">
                         <select
                           value={this.state.budget}
                           onChange={(e) => this.setValue("budget", e)}
                         >
                           {this.state.budgetDropDown.map((row, index) => {
                             return (
                               <option key={index} value={row}>
                                 {row}
                               </option>
                             );
                           })}
                         </select>
                       </label> */}
                     </div>
                     {/* <div className="col-sm-6 col-md-6">
                       <div className="row radiosec">
                         <div className="col-sm-5 col-md-offset-1">
                           <label>Pet</label>
                           <div className="row">
                             <div className="clearfix"></div>
                             <div className="col-sm-6">
                               <label className="custom_radio radio-inline">
                                 Yes
                                 <input
                                   type="radio"
                                   name="radio"
                                   value="yes"
                                   onChange={(e) => this.setChecked(e)}
                                 />
                                 <span className="checkmark"></span>
                               </label>
                             </div>
                             <div className="col-sm-6">
                               <label className="custom_radio radio-inline">
                                 No
                                 <input
                                   type="radio"
                                   name="radio"
                                   value="no"
                                   onChange={(e) => this.setChecked(e)}
                                 />
                                 <span className="checkmark"></span>
                               </label>
                             </div>
                           </div>
                         </div>
                       </div>
                     </div> */}
                   </div>
                   <hr />
   
                   {/* <h1>Roommate Preference</h1>
                   <div className="row-radio-btns pt-4">
                     {roommateGenderPreferenceOptions.map((option) => (
                       <div className="item">
                         {this.renderRadioButton(
                           option,
                           option,
                           "roommateGenderPreference"
                         )}
                       </div>
                     ))}
                   </div> 
                   <hr />*/}
   
                   <h1>Need Parking?</h1>
                   <div className="row-radio-btns flex flex-wrap pt-4">
                     {["Yes", "No"].map((option) =>
                       this.renderRadioButton(option, option, "needParking")
                     )}
                   </div>
                   <hr />
   
                   <h1>Have a Roommate in Mind Already?</h1>
                   <div className="row pt-4">
                     <div className="col-sm-6 col-md-6">
                       <input
                         type="text"
                         className="form-control"
                         placeholder="First & Last Name"
                         value={this.state.preferredRoommateName_1}
                         onChange={(e) =>
                           this.setValue("preferredRoommateName_1", e)
                         }
                         onKeyDown={this.handleKeyDown}
                       />
                     </div>
                     <div className="col-sm-6 col-md-6">
                       <input
                         type="text"
                         className="form-control"
                         placeholder="Email Address"
                         value={this.state.preferredRoommateEmail_1}
                         onChange={(e) =>
                           this.setValue("preferredRoommateEmail_1", e)
                         }
                         onKeyDown={this.handleKeyDown}
                       />
                     </div>
                   </div>
                   <div className="row pt-4">
                     <div className="col-sm-6 col-md-6">
                       <input
                         type="text"
                         className="form-control"
                         placeholder="First & Last Name"
                         value={this.state.preferredRoommateName_2}
                         onChange={(e) =>
                           this.setValue("preferredRoommateName_2", e)
                         }
                         onKeyDown={this.handleKeyDown}
                       />
                     </div>
                     <div className="col-sm-6 col-md-6">
                       <input
                         type="text"
                         className="form-control"
                         placeholder="Email Address"
                         value={this.state.preferredRoommateEmail_2}
                         onChange={(e) =>
                           this.setValue("preferredRoommateEmail_2", e)
                         }
                         onKeyDown={this.handleKeyDown}
                       />
                     </div>
                   </div>
                   <div className="row pt-4">
                     <div className="col-sm-6 col-md-6">
                       <input
                         type="text"
                         className="form-control"
                         placeholder="First & Last Name"
                         value={this.state.preferredRoommateName_3}
                         onChange={(e) =>
                           this.setValue("preferredRoommateName_3", e)
                         }
                         onKeyDown={this.handleKeyDown}
                       />
                     </div>
                     <div className="col-sm-6 col-md-6">
                       <input
                         type="text"
                         className="form-control"
                         placeholder="Email Address"
                         value={this.state.preferredRoommateEmail_3}
                         onChange={(e) =>
                           this.setValue("preferredRoommateEmail_3", e)
                         }
                         onKeyDown={this.handleKeyDown}
                       />
                     </div>
                   </div>
                   <hr />
   
                   <h1>Additional Comments</h1>
                   <div className="row pt-4">
                     <div className="col-sm-12 col-md-12">
                       <textarea
                         className="form-control"
                         placeholder="Message"
                         value={this.state.message}
                         onChange={(e) => this.setValue("message", e)}
                         onKeyDown={this.handleKeyDown}
                         rows="8"
                         cols="5"
                       ></textarea>
                     </div>
                   </div>
                   <div className="mrg-btm-25 terms-conditions">
                     <div className="custom_checkbox">
                       <input
                         type="checkbox"
                         checked={this.state.isChecked}
                         onChange={this.handleCheckboxChange}
                       />
                       <span className="checkmark"></span>
                       <span>
                         I have read the below & agree to these terms & conditions.
                       </span>
                     </div>
                     <ul>
                       <li>
                         {" "}
                         Housing is available on a first come first serve basis.
                       </li>
                       <li>
                         {" "}
                         Housing sells out QUICKLY every year, there will not be
                         enough housing for everyone.
                       </li>
                       <li>
                         {" "}
                         Once you are offered housing, you will have 48 hours to
                         confirm your reservation.
                       </li>
                       <li>
                         {" "}
                         Housing is NOT guaranteed until a lease has been signed.
                       </li>
                       <li>
                         {" "}
                         SuiteAmerica bears no liability for disputes, accidents,
                         security issues, damage, or any incidents that may occur
                         between roommates. Residents are solely responsible for
                         resolving such matters and serve as the official lease
                         holders.
                       </li>
                       <li>
                         {" "}
                         Damages will be the responsibility of each intern and in
                         some cases damage charges will be split evenly.
                       </li>
                       <li> Once a lease is signed there are no cancellations.</li>
                     </ul>
                   </div>
                   <div className="row">
                     <div className="col-sm-12 col-md-12">
                       <ReCAPTCHA
                         ref="recaptcha"
                         sitekey="6Lcbw1AUAAAAAE5K5cYDyhoGu0RJY9C3CLtGFXAo"
                         onChange={this.onChangeCaptcha}
                       />
                     </div>
                   </div>
                 </form>
   
                 <button
                   type="button"
                   className=" btn location_btn mrg-btm-70 mrg-top-40"
                   // data-toggle="modal"
                   // data-target="#acknowledge"
                   onClick={this.serviceRequest}
                 >
                   {" "}
                   SUBMIT
                 </button>
               </div>
             </div>
           </div>
           {/* Acknowledge Pop Up */}
           {/* <div
             class="modal fade savesearch tesimonial_form bd-example-modal-lg in bookingmodel flight-model"
             id="acknowledge"
             role="dialog"
           >
             <div class="modal-dialog">
               <div class="modal-content rm-border-radius">
                 <button
                   type="button"
                   className="close"
                   data-dismiss="modal"
                   aria-label=""
                 >
                   <span className="close-btn-modal">×</span>
                 </button>
                 <div class="modal-body py-5 text-left">
                   <div>
                     <h2>Please acknowledge:</h2>
                     <p>
                       Housing is available on a first come first service basic.
                     </p>
                     <p>
                       Housing sells out QUICKLY every year , there will not be
                       enough housing for everyone .
                     </p>
                     <p>
                       Once you are offered housing,you will have 48 hours to
                       confrim your reservation.
                     </p>
                     <p>
                       Housing is NOT guaranteed until a lease has been signed.
                     </p>
                     <p>
                       SuiteAmerica bears no liability for disputes
                       ,accidents,security issues, damage,or any incidents that may
                       occur between roommates.Residents are solely responsible for
                       resolving such matters and serve as the official lease
                       holders.
                     </p>
                     <p>
                       Damages will be the responsibility of each intern and in
                       some cases damage charges will be split evenly.
                     </p>
                     <p>Once a lease is signed there are no cancellations.</p>
                     <p>
                       I have read the above & agree to these terms & conditions.
                     </p>
                   </div>
                 </div>
                 <div class="modal-footer">
                   <button
                     type="button"
                     class="btn location_btn"
                     onClick={this.sendEmailnBtn}
                   >
                     Ok
                   </button>
                 </div>
               </div>
             </div>
           </div> */}
           <Disclaimer />
           {/* <SubFooter /> */}
           <SubFooterLinkedIn />
           <Footer />
           <SuccessModalLinkedIn />
         </div>
       );
     }
   }
   export default LinkedinInternsReservationForm;
   