/* Importing the node modules, child components, services and controllers used 
inside CommunityLocation component */
import $ from 'jquery'
import AppController from "../../controller/appController";
import GuestHeader from '../../common/guestHeader';
import Spinner from '../../common/spinner';
import Footer from '../../common/footer';
import SubFooter from "../../common/subFooter";
import CommunityLocationModal from '../location/modals/communityLocationModal';
import CommunityBookNowModal from '../../controller/communityBookNowModal';
import Login from '../location/login';
import MetaTags from './../../common/metaTags';
import GreatSchools from '../../common/community/greatSchools';
import SearchMap from './SearchMap';
import ReservationSuccesModal from '../../controller/reservationSuccesModal';
import PdfService from '../../../services/pdfService';
import _ from 'lodash';
import ImageGallery from 'react-image-gallery';
/* CommunityLocation Component initialization */
class CommunityLocation extends React.Component {
   /* Initializing objects of its CommunityLocation class */
   constructor(props) {
      super(props);
      /* CommunityLocation Component State variables Initialization */
      this.CommunityLocationModal = new CommunityLocationModal(this, props)
      this.state = Object.assign(this.CommunityLocationModal.state)
      this.onMarkerClick = this.onMarkerClick.bind(this);
      this.onMapClicked = this.onMapClicked.bind(this);
      this.onChange = (address) => this.setState({ address })
      this.destinationLocation = this.destinationLocation.bind(this);
      this.favouriteCommunity = this.favouriteCommunity.bind(this);
      this.unFavouriteCommunity = this.unFavouriteCommunity.bind(this);
      this.moreInfoMethod = this.moreInfoMethod.bind(this);
      this.calendarClose = this.calendarClose.bind(this);
      this.calendarInfo = this.calendarInfo.bind(this);
      this.pdfGeneration = this.pdfGeneration.bind(this);
   }
   /* It is invoked immediately before mounting occurs */
   async componentWillMount() {
      const script = document.createElement("script");
      script.src = "https://apicdn.walkscore.com/api/v1/traveltime_widget/js?wsid=8daf3ec7b708129023cb6ebcf8e99b56";
      script.async = true;
      script.integrity = '';
      document.body.appendChild(script);
      AppController.removeLocalStorageData();
      $('html, body').animate({ scrollTop: 0 });
      AppController.stickyTitles();
      await this.CommunityLocationModal.getDetails();
   }
   /* To get the apartment/community amenities */
   /* Display the position of community */
   /* Destination related function */
   destinationLocation() {
      var address = '';
      const _this = this;
      var places = new google.maps.places.Autocomplete(document.getElementById('txtPlaces'));
      google.maps.event.addListener(places, 'place_changed', function () {
         var place = places.getPlace();
         address = place.formatted_address;
         _this.setState({ 'destinationAdd': address });
      });
   }
   /* Map marker selection */
   onMarkerClick(props, marker, e) {
      this.setState({
         selectedPlace: props,
         activeMarker: marker,
         showingInfoWindow: true
      });
   }
   /* Map marker onMapClicked */
   onMapClicked(props) {
      if (this.state.showingInfoWindow) {
         this.setState({
            showingInfoWindow: false,
            activeMarker: null
         })
      }
   }
   //Location Resturants
   async onetype(row, type) {
      await this.setState({ type: [row.type], showlist: true, typeName: row.name, typeicon: type })
      this.loadPlacesWithMarkers(1, this.state.typeicon)
   }
   searchPlace() {
      this.setState({ showSearch: true, showlist: true })
   }   /* Display the root map for community to selected place */
   /* Resets the Destination to community position */
   /* To get the recently viewed data */
   /* Get the favourite community of the user */
   /* favourite insertion by user_id
   and also calling the favourites */
   async favouriteCommunity(data) {
      await this.setState({ spinner: data.community_id })
      await this.CommunityLocationModal.favouriteCommunity(data)
      await this.setState({ spinner: -1 })
   }
   /* Callback method to update user favorites */
   async unFavouriteCommunity(value) {
      await this.setState({ spinner: value.community_id })
      await this.CommunityLocationModal.unFavouriteCommunity(value)
      await this.setState({ spinner: -1 })
   }
   /* To see the more content */
   async moreInfoMethod(data, favouriteCheck) {
      await this.CommunityLocationModal.getDetails(data);
   }
   async checkPassword(e) {
      await this.CommunityLocationModal.checkPassword();
   }
   deleteState() {
      this.setState({ 'password': '', 'email': '', 'message': '' })
   }
   setValue(field, e) {
      var object = {};
      object[field] = e.target.value;
      this.setState(object);
   }
   updateDimensions() {
      this.setState({ width: window.innerWidth });
   }
   /* Invoked immediately before a component is unmounted and destroyed */
   componentWillUnmount() {
      window.removeEventListener("resize", this.updateDimensions.bind(this));
   }
   /* It is invoked immediately after a component is mounted */
   async componentDidMount() {
      this.updateDimensions();
      window.addEventListener("resize", this.updateDimensions.bind(this));
     
   }
   async calendarClose() {
      await this.setState({ calendarStatus: false })
   }
   async calendarInfo() {
      await this.setState({ calendarStatus: true })
   }
   async pdfGeneration() {
      this.setState({ pdfLoading: true });
      const finalData = { community: this.state.communityObject, appAmenities: this.state.appartmentAmenities, commAmenities: this.state.communityAmenities, communityImages: this.state.communityImages };
      let result = await PdfService.pdf(finalData);
      await window.open(result.FileUrl)
      this.setState({ pdfLoading: false });
   }
   /* It is invoked to return html content */
   render() {
      const inputProps = {
         value: this.state.address,
         onChange: this.onChange
      }
      const commName = this.state.communityObject.community_name != undefined ? this.state.communityObject.community_name + " | " : '';
      const descriptionName = this.state.communityObject.community_name != undefined ? this.state.communityObject.community_name : '';
      const communityALLImages = this.state.communityALLImages
      const urlArray = []
      let arrow = Object.keys(this.state.communityObject).length > 0 ?
         communityALLImages.map((galleyImage, galleyImageIndex) => {
            if(galleyImage.image_name != null){
               urlArray.push({"original":galleyImage.image_name ,"thumbnail":galleyImage.image_name })
            }
         }) : '';
       let filterIMages = _.uniqBy(urlArray, 'original');  
      return (
         <div>
            <MetaTags metaTitle={commName + "Corporate Housing | 800 367 9501 | SuiteAmerica"} metaDesription={descriptionName + " Corporate Housing. Suite America provides platinum-level service in Corporate Housing, Short Term Apartments and GSA Contract Housing. Book today at www.suiteamerica.com or call 800 367 9501."} metaUrl={"https://www.suiteamerica.com" + this.props.match.url} />
            <GuestHeader />
            {/* <div className="ht-w-200"></div> */}
            <div className="New_locationcommunyties cp-newpage">
               {this.state.PageLoader ?
                  <div>
                     <div className="container">
                        <div className="cp_newpage-heading">
                           <div className="item">
                           {Object.keys(this.state.communityObject).length > 0 ?
                                 <div className="lc_headings heaidng">
                                    <h1>{this.state.communityObject.community_name != undefined ? this.state.communityObject.community_name : ''}</h1>
                                    <p>{this.state.communityObject.address != undefined ? this.state.communityObject.address : ''}, {this.state.communityObject.city_name != undefined ? this.state.communityObject.city_name.toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ') : ''}, {this.state.communityObject.state_name != undefined ? this.state.communityObject.state_name.toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ') : ''} {this.state.communityObject.ZipCode != undefined ? this.state.communityObject.ZipCode : ''}</p>
                                 </div>
                                 : null}
                           </div>
                           {/* <div className="item2">
                           {this.state.pdfLoading ? <div className="new-loader-spin">
                              <i className="fa fa-spinner fa-spin"></i> </div>
                              :
                              <div className="">
                                 <div className="heading-right">
                                    <ul>
                                       <li>
                                          <a onClick={() => this.pdfGeneration()}>
                                             <i className="fa fa-file-pdf-o" data-toggle="tooltip" data-placement="top" title="Download Community Profile"></i></a></li>
                                    </ul>
                                 </div>
                              </div>
                           }
                              
                           </div> */}

                           </div>
                        <div className="row">
                           <div className="col-md-8">
                              <div className="outer">
                              </div>
                              { filterIMages.length>0? 
                              <ImageGallery items={filterIMages} autoPlay={true}/>:''}
                           </div>
                           <div className="col-md-4">
                              <h2>Amenities</h2>
                              <div className="amanities">
                                 {this.state.appartmentamenitieslist.length > 0 ?
                                    <ul>
                                       {this.state.appartmentamenitieslist.map((row, index) => {
                                          return (
                                             <li key={index}>
                                                <img width="40" src={s3BucketUrl + 'Amenities/' + (row.aparment_amenity_name).replace(/[^A-Z0-9]+/ig, "") + '.png'} />{row.aparment_amenity_name}
                                             </li>
                                          )
                                       })}
                                    </ul>
                                    : <div className='text-center' style={{ fontSize: '15px', width: '100%', margin: 'auto', textAlign: 'center' }}>No Amenities Found
            </div>
                                 }
                              </div>
                              <div className="clearfix"></div>
                           </div>
                        </div>
                     </div> 
                     <div className="container">
                        <div className="communities-view">
                           {Object.keys(this.state.communityObject).length > 0 ?
                              <div>
                                 <SearchMap {...this.state} latitude={this.state.communityObject.latitude} longitude={this.state.communityObject.longitude}
                                    communityName={this.state.communityObject.community_name}
                                    communityObject={this.state.communityObject} handleFormSubmit={this.handleFormSubmit}
                                    status={1} />
                              </div>
                              : ""}
                        </div>
                     </div>
                     <div className="container">
                        <div className="row d-flex mrg-top-20 mrg-btm-20">
                           <div className="col-md-8 center_block">
                              {Object.keys(this.state.greatSchools).length > 0 ?
                                 <GreatSchools {...this.state} />
                                 : !this.state.walkscoreLoading ?
                                    <Spinner fontSize="25px" />
                                    :
                                    <div className=""></div>
                              }
                           </div>
                           <div className="col-md-4">
                              <div className="score-details">
                                 {this.state.walkscore == null ? '' :
                                    <div className="item">
                                       {/* <i className="fas fa fa-male"></i> */}
                                       <div className="info">
                                          WALK SCORE
                  <span>{this.state.walkscore}</span>
                                       </div>
                                    </div>}
                                 {this.state.transitscore == null ? '' :
                                    <div className="item">
                                       {/* <i className="fas fa fa-bus"></i> */}
                                       <div className="info">
                                          TRANSIT SCORE
                  <span>{this.state.transitscore}</span>
                                       </div>
                                    </div>
                                 }
                                 {this.state.bikescore == null ? '' :
                                    <div className="item">
                                       {/* <i className="fas fa fa-motorcycle"></i> */}
                                       <div className="info">
                                          BIKE SCORE
                  <span>{this.state.bikescore}</span>
                                       </div>
                                    </div>}
                              </div>
                           </div>
                        </div>
                                 {/* <div className="row">
                                    <div className="col-md-12">
                                    <TavelTimeMap lat={this.state.communityObject.latitude} long={this.state.communityObject.longitude}/>
                                    </div>
                                 </div> */}
                        {/* <div className="row">
                           <div className="col-md-12">
                              {this.state.lines != undefined && this.state.lines != "" && this.state.lines != null ? 
                                <PublicTransit className="col-md-8" lines={this.state.lines} latitude={this.state.communityObject.latitude} longitude={this.state.communityObject.longitude} />:
                                ''}
                           </div>
                        </div> */}
                     </div>
                  </div>
                  :
                  <Spinner fontSize="35px" />
               }
            </div>
            <Login />
            {this.state.calendarStatus
               ?
               <CommunityBookNowModal calendarClose={this.calendarClose} CommunityDetails={this.state.communityObject} />
               : ''}
            <ReservationSuccesModal />
            <SubFooter />
            <Footer />
         </div>
      );
   }
}
export default (CommunityLocation);