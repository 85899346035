/* Importing the node modules, child components, services and controllers used 
   inside CallModal component */
import Conference from '../../services/conference';
import CallSuccessModal from './callSuccessModal';
/* CallModal Component initialization */
class CallModal extends React.Component {
    /* Initializing objects of its CallModal class */
    constructor(props) {
        super(props)
        /* CallModal Component State variables Initialization */
        this.state ={
            phoneNo:'',
            message:'',
            color:'',
            codeSelected:'+1',
            countryCodes:['+1','+91','+2']
        }
        this.callTo = this.callTo.bind(this);
        this.setValue = this.setValue.bind(this);
    }
    /* To clear the form field values */
    clearPhone(){
        this.setState({phoneNo:'',message:'',color:''})
    }
    /* Call to the entered number in the input field */
    callTo(){    
      const Code = $(".country.highlight.active").attr("data-dial-code") == undefined ? "+1" : "+"+$(".country.highlight.active").attr("data-dial-code")
      if(this.state.phoneNo.length <10){
        this.setState({message:'Please enter a valid number',color:'red'})
        }
        else{
            const numbers = [
                { number:'+18003679501'},
                { number: Code+this.state.phoneNo}];        
                 Conference.conference(numbers)

          $('#callModal').modal('hide');
          $('#thankyouModal').modal('show');
       
          setTimeout(async function() {
            jQuery(function($) {
                $('#thankyouModal').modal('hide');
            });
     }, 5000);
        }
    }
    /* It is invoked immediately after a component is mounted */
    componentDidMount() {
        $(document).ready(function () {
            $("#phone").intlTelInput();
        });        
    }
    /* To update the input field values */
    setValue(e) {
        const re = /^[0-9\b]+$/;    
        if (e.target.value == '' || re.test(e.target.value)) {
            this.setState({phoneNo: e.target.value})
        }
    }
     /* To update the state in response to prop changes */
    componentWillReceiveProps(nextProps){
        this.setState({phoneNo:'',message:''})
    }
    /* It is invoked to return html content */
    render(){
        return(<div> <div className="modal fade savesearch" id="callModal" role="dialog">
        <div className="modal-dialog">
            <div className="modal-content rm-border-radius">
                <div className="modal-header">
                <button type="button" className="close btn" data-dismiss="modal" aria-label="">
                <i className="bi bi-x"></i>
                </button>
                </div>
                <div className="modal-body">
                <h2>We'll Call You</h2>
                <p>Enter your phone number and we'll call to <br/>
                    assist you right away.</p>
                <form role="form" method="post" >
                    {this.state.message ? <span style={{color: this.state.color,textAlign: 'center'}}>
                    <h4>{this.state.message}</h4>
                    </span>: ''}
                    
                    <div className="form-group">
                     <div className="col-md-8 col-md-offset-2 mrg-btm-30">
                     <input className="form-control"  required   id="phone" placeholder='Phone Number' maxLength="10" 
                     onChange={(e) => this.setValue(e)} value={this.state.phoneNo}/>
                      </div>
                    </div>
                   <div className="clearfix"></div>
                   
                    <button
                        className="btn location_btn"
                        type="button"
                        onClick={this.callTo}>CONNECT WITH US</button>
                </form>
                </div>
            </div>
        </div>
    </div>
    <CallSuccessModal />
    </div>)
    }

}

export default CallModal