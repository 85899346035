/* Importing the node modules, child components, services and controllers used 
   inside AutoConfirmMain component */

import LocationService from "../../../services/locationService";
import AutoConfirmMains from "./nikeautoConfirmMain";
import AutoConfirmMain from "./autoConfirmMain";
import GuestHeader from "../../common/guestHeader";
import Footer from "../../common/footer";
/* AutoConfirmMain Component initialization */
class AutoConfirmMainlist extends React.Component {
  /* Initializing objects of its AutoConfirmMain class */
  constructor(props) {
    super(props);
    /* AutoConfirmMain Component State variables Initialization */
    this.state = {
      cfmid: '',
      list: 0,
      checking: null,
      erormessage:""
    };
    // this.loadMore = this.loadMore.bind(this)

  }
  /* It is invoked immediately before mounting occurs */
  componentDidMount() {
    const data1 = this.state.data1;
    //this.walkScore(data1);
    this.LinkMastersByOrderUId();
    //this.getCommunityByName();
  }



  //Get Link Masters By OrderUid
  async LinkMastersByOrderUId() {
    const req = { orderUId: _.toLower(this.props.match.params.id) };
    const LinkMasters = await LocationService.LinkMastersByOrderUId(req);
    if (LinkMasters.error == 0 && LinkMasters.message.length > 0) {
      var myPattern = new RegExp('(\\w*' + "nike" + '\\w*)', 'gi');
      var cli = LinkMasters.message[0].Client
      if (cli != undefined && cli != null) {
        var check = cli.match(myPattern);
      }
      this.setState({ LinkMasters: LinkMasters, cfmid: this.props.match.params.id, list: 1, checking: check });
    }else{
      this.setState({ erormessage: LinkMasters.message});
    }
  }

  // Communitys List By Community Name

  /* It is invoked to return html content */
  render() {

    return (
      <div>
        {this.state.list == 1 ?
          this.state.LinkMasters.message.length > 0 ?
            (this.state.checking != null && this.state.checking != undefined) ?
              <AutoConfirmMains cfmid={this.state.cfmid} /> :
              <AutoConfirmMain cfmid={this.state.cfmid} /> : '' : (this.state.erormessage!='' &&<div>
              <GuestHeader /><div className="error-sms-Quote mrg-top-40">
              <span className="alert alert-danger">
                <i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.erormessage}

              </span>
            </div> <Footer />
         </div>)}

      </div>
    );
  }
}
export default AutoConfirmMainlist;
