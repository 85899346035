/* Importing the node modules, child components, services and controllers used 
   inside Calender component */
import $ from "jquery";
import {Link} from 'react-router-dom';
import AppController from "../../../controller/appController";
/* Calender Component initialization */
class Calender extends React.Component {
    /* Initializing objects of its Calender class */
    constructor(props) {
        super(props)
        /* Calender Component State variables Initialization */
        this.state = {
            activeIndex: 2
        }
        this.changeIndex = this.changeIndex.bind(this);
    }
    /* It is invoked immediately before a component is mounted */  
    componentWillMount() {
        $('html, body').animate({scrollTop: 0});
        AppController.stickyTitles();
    }
    /* To active the selected date */
    changeIndex(index) {
        this.setState({activeIndex: index});
    }
    /* It is invoked to return html content */
    render() {
        return (
            <div>
                <div className="container">
                    <div className="header-wrap">
                        <h1 className="align-center content-mail-title">Drivers Schedule</h1>

                        <div className="view-wrap align-center">

                            <div className="view-list-f">
                                <div className="view-list-f-item">
                                    <Link to="/diver-schedulemap">
                                        <i className="fa fa-map-o active"></i>
                                        Map View</Link>
                                </div>
                                <div className="view-list-f-item">
                                    <Link to="/drivers-schedule">
                                        <i className="fa fa-list-ul"></i>
                                        Schedule View
                                    </Link>
                                </div>
                                <div className="view-list-f-item">
                                    <Link
                                        to="/calender"
                                        className={this.state.activeIndex == 2
                                        ? 'active-index'
                                        : null}
                                        onClick={() => this.changeIndex(2)}>
                                        <i className="fa fa-calendar"></i>
                                        Calendar</Link>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div
                    style={{
                    'width': '100%',
                    'textAlign': 'center',
                    'margin': '50px 0px'
                }}>

                    <img
                        className="img-responsive"
                        src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Coming-Soon.png"}
                        style={{
                        'margin': 'auto'
                    }}></img>
                </div>
            </div>
        );
    }
}
export default Calender;