const ContactList = () => {
     return (
        <div className="container ft-contact-adrs mrg-btm-30 mrg-top-30">    
        <div className="text-center">
            <h3>SUITEAMERICA</h3>
            <h5 className="mrg-top-20"><i className="fa fa-phone-square"></i>
                {/* <span className="desktop-phone">800.367.9501</span> */}
                <a href="tel:8003679501" className="mobile-phone">800.367.9501</a>
            </h5>
            <h5>4970 Windplay Dr. Suite C-1</h5>
            <h5>El Dorado Hills, CA 95762</h5>
        </div>
        <div className="section-line"></div>
        <div className="text-center">
            <h3>FOR RESERVATION AND INQUIRIES</h3>
            <h5 className="mrg-top-20">Lisa Briseno</h5>
            <h5>P: 800.367.9501 x140</h5>
            <h5>E: lisa@suiteamerica.com</h5>

            <h5 className="mrg-top-20">Alison McGinnis</h5>
            <h5>P: 800.367.9501 x134</h5>
            <h5>E: alison@suiteamerica.com</h5>
        </div>
        <div className="section-line"></div>
        <div className="text-center">
            <h3>FOR GROUP PLANNING OR ACCOUNT MANAGEMENT</h3>
            <h5 className="mrg-top-20">Kim Dunbar</h5>
            <h5>P: 703.690.2030</h5>
            <h5>E: dunbar@suiteamerica.com</h5>
        </div>                   
    </div>
   )
}
export default ContactList;