/* Importing the node modules, child components, services and controllers used 
   inside IntranetForm component */   
   import $ from "jquery";
   import AppController from "../../../controller/appController";
   import EmployeeHeader from '../../../common/employeeHeader';
   import EmployeeFooter from '../../../common/employeeFooter';
   import IntranetCustomRequestone from './intranetCustomRequestone';
   import IntranetCustomRequesttwo from './intranetCustomRequesttwo';
   import IntranetCustomRequestthree from './intranetCustomRequestthree';
   import IntranetCustomRequestfour from './intranetCustomRequestfour';
   import IntranetCustomRequestfive from './intranetCustomRequestfive';
   import moment from 'moment'
   import IntranetServicesApi from "../../../../services/intranetServicesApi";
   import SessionService from "../../../../services/sessionService";
   import IntranetEmailService from "../../../../services/intranetEmailService";
   
   /* IntranetForm Component initialization */
   class IntranetForm extends React.Component{
       /* Initializing objects of its IntranetForm class */
       constructor(props) {
           super(props)
           this.state = {
            page: 1,
            projectType: '',
            companyName: '',
            customProjectInstructions: '',
            projectPurpose: '',
            projectAudience: '',
            filesLists: [],
            deliveryMethod: '',
            dueDateVal: moment(),
            priority: '',
            deliveryMethodElectronic:false,
            deliveryMethodHard:false,
            ContactName: '',
            phone: '',
            email: '',
            address: '',
            specialInstruction: '',
            status: -1,
            loader: false,
            quantity:'',
            projectData: []
          }
          this.setValue = this.setValue.bind(this);
          this.next = this.next.bind(this);
          this.previous = this.previous.bind(this);
          this.filesListUpdate = this.filesListUpdate.bind(this);
          this.priority = this.priority.bind(this);
          this.dueDate = this.dueDate.bind(this);
          this.setCheckedElectronic = this.setCheckedElectronic.bind(this);
          this.setCheckedhard = this.setCheckedhard.bind(this);
          this.submit = this.submit.bind(this);
           }
           componentDidMount() {
            $(document).ready(function() {
                $('html, body').animate({
                    scrollTop: 0
                });
                AppController.stickyTitles()
            })
            this.projectData()
        }


        /* input filed  values update */
        setValue(object) {

            this.setState(object);
        }

        /* checkbox  values update */
        setCheckedElectronic(object) {

            this.setState(object);
        }
        /* checkbox  values update */
        setCheckedhard(object) {
            this.setState(object);
        }
        /* checkbox  values update */
        priority(object) {
            this.setState(object);
        }
        /* date  values update */

        dueDate(object) {
            this.setState(object);
        }

        /* getting project type from database */

        async projectData() {
           const intranetRes = await IntranetServicesApi.getProjectTypeDetails();
            this.setState({
                projectData: intranetRes
            })
        }
        /* next button */
        next(data) {
            $('html, body').animate({
                scrollTop: 0
            });
            this.setState(data)
        }
        /* previous button */
        previous(data) {
            $('html, body').animate({
                scrollTop: 0
            });
            this.setState({
                page: data
            })
        }

        async filesListUpdate(filepath, FileDetails, info, dataIndex) {
            if (dataIndex < 0) {
                await this.setState({
                    filesLists: _.concat(this.state.filesLists, {
                        filepath: filepath,
                        FileDetails: FileDetails,
                        info: info
                    })
                })
            } else if (dataIndex >= 0) {
                await this.setState({
                    filesLists: update(this.state.filesLists, {
                        $splice: [
                            [dataIndex, 1]
                        ]
                    }, 0)
                })
            }
        }

       async submit(){
       this.setState({loader:true})
       const userData = await SessionService.SessionGet({type:'intranet'})
       const filedata=this.state.filesLists
       var filePath = [];var fileName = [];
       for(var i=0; i< filedata.length; i++){
           fileName.push((filedata[i].filepath.split("/")[6]));
          filePath.push((filedata[i].filepath));
       } 
   
       const filenames=fileName.toString()
       const filepaths=filePath.toString()

           const mailDetails={
            projectType: this.state.projectType,
            companyName: this.state.companyName,
            customProjectInstructions: this.state.customProjectInstructions,
            projectPurpose: this.state.projectPurpose,
            projectAudience: this.state.projectAudience,
            deliveryMethodElectronic: this.state.deliveryMethodElectronic ? 'Yes':'No',
            deliveryMethodHard: this.state.deliveryMethodHard ? 'Yes':'No',
            dueDate: this.state.dueDateVal.format('YYYY-MM-DD'),
            priority: this.state.status==0?'low':(this.state.status==1?'Medium':(this.state.status==2? 'High':'No priority')),
            ContactName: this.state.ContactName,
            phone: this.state.phone,
            email: this.state.email,
            address: this.state.address,
            specialInstruction:this.state.specialInstruction,
            filesLists:this.state.filesLists,
            filePath:filepaths,
            fileName:filenames,
            Electronic: this.state.deliveryMethodElectronic ? 1:0,
            Hard: this.state.deliveryMethodHard ? 1:0,
            firstName:userData.FirstName,
            lastName:userData.LastName,
            changedBy:userData.EmailId,
            quantity:this.state.quantity,
            orderDate:moment().format('YYYY-MM-DD | HH:mm'),
            subject: 'Intranet Marketing Request Form',
            to: ['marketing@suiteamerica.com',this.state.email],
            mail:'marketingRequest',
            cc:[]
           }
           const intranetRes=await IntranetServicesApi.saveMarketingRequestDetails(mailDetails);
           console.log(intranetRes)
           const emailResponse=await IntranetEmailService.emailSent(mailDetails);
           if(emailResponse.response){
            this.setState({page:5,loader:false})
            $('html, body').animate({scrollTop: 0});
            setTimeout(function() { this.setState({page: 1,
            projectType: '',
            companyName: '',
            customProjectInstructions: '',
            projectPurpose: '',
            projectAudience: '',
            filesLists: [],
            deliveryMethod: '',
            dueDate: moment(),
            priority: '',
            deliveryMethodElectronic:false,
            deliveryMethodHard:false,
            ContactName: '',
            phone: '',
            email: '',
            address: '',
            specialInstruction: '',
            status: -1,loader:false,
            quantity:''})
        }.bind(this), 1000);
        this.projectData() 
    }else{
            this.setState({loader:true})
        }

       }
       
     
         
   /* It is invoked to return html content */			
   render(){
 
       return(
           <div>
    <div className="inet_custom">
        <EmployeeHeader/>

        {this.state.page==1 ? 
        <IntranetCustomRequestone 
        {...this.state} 
        setValue={this.setValue} 
        next={this.next}/>:null}
        {this.state.page==2 ?  
        <IntranetCustomRequesttwo 
        {...this.state} 
        filesListUpdate={this.filesListUpdate} 
        setValue={this.setValue} 
        next={this.next} 
        previous={this.previous} />:null }
        {this.state.page==3 ? 
        <IntranetCustomRequestthree 
        {...this.state}  
        priority={this.priority} 
        dueDate={this.dueDate}  
        setValue={this.setValue} 
        setCheckedElectronic={this.setCheckedElectronic}    
        setCheckedhard={this.setCheckedhard} 
        next={this.next} 
        previous={this.previous}/>:null}
        {this.state.page==4 ?  
        <IntranetCustomRequestfour  
        {...this.state} 
        setValue={this.setValue} 
        submit={this.submit} 
        previous={this.previous}/>:null}
        {this.state.page==5 ?  
        <IntranetCustomRequestfive />:null}
        
    </div>
    <EmployeeFooter/>
    </div>
       );
   }
   }
   export default IntranetForm;