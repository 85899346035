
   import Footer from './footer';
import Header from './header';
import OwlCarousel from 'react-owl-carousel';
import RequestCallback from './requestCallback';

   class PropertyOptionnew extends React.Component {
       constructor(props) {
           super(props);
           this.state = {
           }
       }
     
      async componentDidMount(){
        window.onbeforeunload = function () {
          window.scrollTo(0, 0);
        }
      }
      handleClick() {
        $('#sideQuote').addClass('active');
        $('.overlay').addClass('active');
        $('.collapse.in').toggleClass('in');
        $('a[aria-expanded=true]').attr('aria-expanded', 'false');
      }
       render() {
           return (
            <div className="SAweb_updateUi">
         <Header/>
           


  <section className="SAweb_banner-3 bg-white py-5">
    <div className="container">
    <div className="box mb-3">
      <div className="item">
        <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/screen-3/banner-1.jpg" alt="" />
        <button className="btn" type="button"> 3D Tour </button>
  
      </div>
      <div className="item">
       <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/screen-3/banner-2.jpg" alt="" />
      </div>
      <div className="item">
       <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/screen-3/banner-3.jpg" alt="" />
       <button className="btn photos" type="button"> 15 More
        Photos</button>
        
     </div>
    </div>
    <div className="subHeading py-4" data-aos="fade-up">
      <div className="row">
        <div className="col-sm-8">
          <h1>101 San Fernando</h1>
          <h2>101 San Fernando Street</h2>
          <p>This property perfectly encompasses the newest luxury rental homes at Santana Row. From the interior amenities to the surrounding retail, restaurant and entertainment options, 101 San Fernando offers the ideal choice for people looking forward to the next generation of innovative living.</p>
<ul>
  <li> <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/screen-3/bed.svg" alt="" width="20px" /> 2 Bedrooms </li>
  <li> <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/screen-3/bath.svg" alt="" width="20px" /> 2 Bathroom </li>
  <li> <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/screen-3/kitchen.svg" alt="" width="15px" /> Kitchen </li>
  <li> <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/screen-3/home.svg" alt="" width="17px"  /> 1 Garage </li>
</ul>
        </div>
        <div className="col-sm-4 text-end">

          <button className="btn btn-dark text-white  rounded-0 fs-5 py-3 px-3" type="button" onClick={this.handleClick}> Reserve Now </button>
          
        </div>
      </div>
    </div>





  </div>
</section>
<section className="SAweb_amenities secondPage  pb-5">
  <div className="container">
    <h2>Amenities</h2>
      <div className="box box_second">
        <div className="item">
                     <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/stayIcons/pool-white.png" width="55px" alt="" />
                     <span>Swimming Pool</span>
        </div>
        <div className="item">
          <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/stayIcons/house-white.png" width="45px"  alt="" />
          <span>Garage/ Parking Area</span>
</div>
<div className="item">
  <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/stayIcons/dishwasher-white.png" width="45px"  alt="" />
  <span>Dishwasher</span>
</div>
<div className="item">
  <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/stayIcons/dishwasher-white.png" width="45px"  alt="" />
  <span>Dishwasher</span>
</div>
<div className="item">
  <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/stayIcons/workout-white.png" width="55px"  alt="" />
  <span>Fitness Centre</span>
</div>
<div className="item">
  <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/stayIcons/wifi-white.png" width="45px"  alt="" />
  <span>Wifi Connection</span>
</div>
<div className="item">
  <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/stayIcons/air-conditioner-white.png" width="50px"  alt="" />
  <span>Airconditioner</span>
</div>
<div className="item">
  <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/stayIcons/air-conditioner-white.png" width="50px"  alt="" />
  <span>Airconditioner</span>
</div>

      </div>
<button type="button" className="btn modalBtn ">
  <i className="bi bi-plus"></i>
</button>




  </div>
</section>
<section className="SAweb_scoreView" >
  <div className="box mb-5" data-aos="fade-up">
  <div className="item">
    <div className="progress blue"> <span className="progress-left"> <span className="progress-bar"></span> </span> <span className="progress-right"> <span className="progress-bar"></span> </span>
      <div className="progress-value"> <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/screen-3/walk.svg" alt="" width="20px" /> </div>
  </div>
  <div className="caption">
    <h1>96</h1>
    <p>Walk Score</p>
  </div>

  </div>
  <div className="item">
    <div className="progress yellow"> <span className="progress-left"> <span className="progress-bar"></span> </span> <span className="progress-right"> <span className="progress-bar"></span> </span>
      <div className="progress-value"> <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/screen-3/bike.svg" alt="" width="40px" /> </div>
  </div>
  <div className="caption">
    <h1>36</h1>
    <p>Bike Score</p>
  </div>

  </div>
  <div className="item">
    <div className="progress green"> <span className="progress-left"> <span className="progress-bar"></span> </span> <span className="progress-right"> <span className="progress-bar"></span> </span>
      <div className="progress-value"> <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/screen-3/bus.svg" alt="" width="30px" /> </div>
  </div>
  <div className="caption">
    <h1>50</h1>
    <p>Transit Score</p>
  </div>
  </div>

</div>

<a href="#" className="howworks" data-aos="fade-up"> How Does It Work? </a>




</section>

<section>
  <div className="SAweb_neighbourhood py-5">

    <div className="  " data-aos="fade-up">
    <div className="container-fluid">
      <h2 className="ps-3">Neighbourhood</h2>
      <ul className="nav nav-tabs mt-5">
        <li >
          <button className="nav-link " id="commute-tab" data-toggle="tab" data-target="#commute" type="button" role="tab" aria-controls="commute" aria-selected="false">Commute</button>
        </li>
        <li className="active">
          <button className="nav-link active" id="restaurants-tab" data-toggle="tab" data-target="#restaurants" type="button" role="tab" aria-controls="restaurants" aria-selected="true">Restaurants</button>
        </li>
        <li>
          <button className="nav-link" id="bars-tab" data-toggle="tab" data-target="#bars" type="button" role="tab" aria-controls="bars" aria-selected="false">Bars</button>
        </li>
        <li>
          <button className="nav-link" id="groceries-tab" data-toggle="tab" data-target="#groceries" type="button" role="tab" aria-controls="groceries" aria-selected="false">Groceries</button>
        </li>
        <li>
          <button className="nav-link" id="park-tab" data-toggle="tab" data-target="#park" type="button" role="tab" aria-controls="park" aria-selected="false">Park</button>
        </li>
        <li>
          <button className="nav-link" id="shopping-tab" data-toggle="tab" data-target="#shopping" type="button" role="tab" aria-controls="shopping" aria-selected="false">Shopping</button>
        </li>
        <li>
          <button className="nav-link" id="entertainment-tab" data-toggle="tab" data-target="#entertainment" type="button" role="tab" aria-controls="entertainment" aria-selected="false">Entertainment</button>
        </li>
      </ul>
    </div>


      <div className="tab-content myTabContentVIew" >
        <div className="tab-pane fade" id="commute" role="tabpanel" aria-labelledby="commute-tab">  Commute </div>
        <div className="tab-pane fade  in active" id="restaurants" role="tabpanel" aria-labelledby="restaurants-tab"> 

          <div className="d-flex">
            <div className="col-sm-6">
              <ul className="distanceList">
                <li> <p>  Cardenas Markets <span>235 East Julian Street, San Jose</span> </p>
                  <p> 0.2 mi </p>
                </li>
                <li> <p>  Fairmont San Jose <span>235 East Julian Street, San Jose</span> </p>
                  <p> 0.2 mi </p>
                </li>
                <li> <p> Henry’s World Famous Hi-Life <span>235 East Julian Street, San Jose</span> </p>
                  <p> 0.2 mi </p>
                </li>
                <li> <p>  Il Fornaio San Jose <span>235 East Julian Street, San Jose</span> </p>
                  <p> 0.2 mi </p>
                </li>
                <li> <p>  Cardenas Markets <span>235 East Julian Street, San Jose</span> </p>
                  <p> 0.2 mi </p>
                </li>
                <li> <p>  Cardenas Markets <span>235 East Julian Street, San Jose</span> </p>
                  <p> 0.2 mi </p>
                </li>
                <li> <p>  Cardenas Markets <span>235 East Julian Street, San Jose</span> </p>
                  <p> 0.2 mi </p>
                </li>
                <li> <p>  Cardenas Markets <span>235 East Julian Street, San Jose</span> </p>
                  <p> 0.2 mi </p>
                </li>
                <li> <p>  Cardenas Markets <span>235 East Julian Street, San Jose</span> </p>
                  <p> 0.2 mi </p>
                </li>
                <li> <p>  Fairmont San Jose <span>235 East Julian Street, San Jose</span> </p>
                  <p> 0.2 mi </p>
                </li>
                <li> <p> Henry’s World Famous Hi-Life <span>235 East Julian Street, San Jose</span> </p>
                  <p> 0.2 mi </p>
                </li>
                <li> <p>  Il Fornaio San Jose <span>235 East Julian Street, San Jose</span> </p>
                  <p> 0.2 mi </p>
                </li>
                <li> <p>  Cardenas Markets <span>235 East Julian Street, San Jose</span> </p>
                  <p> 0.2 mi </p>
                </li>
         
              </ul>
            </div>
            <div className="col-sm-6">

              <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/screen-3/mapPic.jpg" alt="" width="100%" />

            </div>
          </div>

    
          
        
        
        </div>
        <div className="tab-pane fade" id="bars" role="tabpanel" aria-labelledby="bars-tab"> bars </div>
        <div className="tab-pane fade" id="groceries" role="tabpanel" aria-labelledby="groceries-tab"> groceries </div>
        <div className="tab-pane fade" id="park" role="tabpanel" aria-labelledby="park-tab"> park </div>
        <div className="tab-pane fade" id="shopping" role="tabpanel" aria-labelledby="shopping-tab"> shopping </div>
        <div className="tab-pane fade" id="entertainment" role="tabpanel" aria-labelledby="entertainment-tab"> bars </div>
      </div>
   





 
  
    </div>
</div>
</section>



<section className="SAweb_schools second border-top border-2 border-dark">
    <div className="SAweb_location bg-white ">
<div className="container">
      <div className="row   mx-0" >
           <div className="col-sm-1 ">
             <div className="HeadingRotate">
              <h2 className="h2_rotate">Schools</h2>
             </div>
                    
           </div>
           <div className="col-sm-11 py-5">
            <ul className="nav nav-tabs">
              <li className="nav-item" role="presentation">
                <button className="nav-link active" id="public-tab" data-toggle="tab" data-target="#public" type="button" role="tab" aria-controls="public" aria-selected="true">Public</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="private-tab" data-toggle="tab" data-target="#private" type="button" role="tab" aria-controls="private" aria-selected="false">Private</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="charter-tab" data-toggle="tab" data-target="#charter" type="button" role="tab" aria-controls="charter" aria-selected="false">Charter</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link splbtn" >Data provided by <a href="#"> GreatSchools </a> </button>
              </li>
            </ul>
            <div className="tab-content myTabContentVIew" >
              <div className="tab-pane fade in active" id="public" role="tabpanel" aria-labelledby="public-tab">   
                
                <section className=" bg-white pt-5">
           
                    
                    <div className="owl-slider pb-5">
                      <div id="carouselSchool">
                      <OwlCarousel className="owl-theme owl-carousel" 
             {...{ loop: false, margin:10, nav:true, dots:false,  items: 1,
             navContainerClass: 'owl-buttons',
             navText: ["<i className='glyphicon glyphicon-menu-left'></i>",
             "<i className='glyphicon glyphicon-menu-right'></i>"],
            responsive:{
              600:{ items:1,  loop:false },
              767:{ items:2,  loop:false }, 
             992:{ items:2.5,  loop:false }, 
            1199:{ items:3.5,  loop:false }}}}> 
                        <div className="item">
                  
                          <div className="caption">
                          
                           
                        
                          <p>Lorem ipsum dolor sit amet, consetetur.</p>
                          <p> <span>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et.</span> </p>
                          <ul>
                            <li> <i className="bi bi-star-fill"> </i> 04/10  </li>
                            <li>    <i className="bi bi-telephone"> </i> (080) 99999999</li>
                          </ul>
              
          
                        </div>
                        </div>

                        <div className="item">
                        
                          <div className="caption">
                        
                           
                        
                          <p>Horace Mann Elementary and more words</p>
                          <p> <span>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy</span> </p>
                          <ul>
                            <li> <i className="bi bi-star-half"> </i> 04/10  </li>
                            <li>    <i className="bi bi-telephone"> </i> (080) 99999999</li>
                          </ul>
              
          
                        </div>
                        </div>
                        <div className="item">
                        
                          <div className="caption">
                   
                           
                        
                          <p>Horace Mann Elementary</p>
                          <p> <span>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy</span> </p>
                          <ul>
                            <li> <i className="bi bi-star-fill"> </i> 04/10  </li>
                            <li>    <i className="bi bi-telephone"> </i> (080) 99999999</li>
                          </ul>
              
          
                        </div>
                        </div>
                        <div className="item">
                        
                          <div className="caption">
                          
                           
                        
                          <p>Horace Mann Elementary and more words</p>
                          <p> <span>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy</span> </p>
                          <ul>
                            <li> <i className="bi bi-star-half"> </i> 04/10  </li>
                            <li>    <i className="bi bi-telephone"> </i> (080) 99999999</li>
                          </ul>
              
          
                        </div>
                        </div>
                        <div className="item">
                        
                          <div className="caption">
             
                           
                        
                          <p>Horace Mann Elementary and more words</p>
                          <p> <span>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy</span> </p>
                          <ul>
                            <li> <i className="bi bi-star-fill"> </i> 04/10  </li>
                            <li>    <i className="bi bi-telephone"> </i> (080) 99999999</li>
                          </ul>
              
          
                        </div>
                        </div>
                        <div className="item">
                        
                          <div className="caption">

                           
                        
                          <p>Horace Mann Elementary and more words</p>
                          <p> <span>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy</span> </p>
                          <ul>
                            <li> <i className="bi bi-star-fill"> </i> 04/10  </li>
                            <li>    <i className="bi bi-telephone"> </i> (080) 99999999</li>
                          </ul>
              
          
                        </div>
                        </div>
                        </OwlCarousel>
                     
                
                
                      </div>
                      </div>
              
                </section>
              
              
              </div>
              <div className="tab-pane fade" id="private" role="tabpanel" aria-labelledby="private-tab"> private </div>
              <div className="tab-pane fade" id="charter" role="tabpanel" aria-labelledby="charter-tab"> charter </div>
            </div>
        
                
                
           

           </div>
      </div>
    </div>
 </div>
  </section>
<RequestCallback/>

        <Footer/>
          </div>
           );
       }
   }
   export default PropertyOptionnew;