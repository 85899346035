import ReactTable from "react-table";
import AppController from "../../controller/appController";
const OrdersTable=(props)=>{

  const filterMethod = (filter, row) => {
    const id = filter.pivotId || filter.id;
    if (row[id] !== null && typeof row[id] === "string") {
      return (row[id] !== undefined
        ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
        : true);
    } else if(row[id] !== null && typeof row[id] === "number") {
        const str = String(row[id]);
        return (str !== undefined
            ? String(str.toLowerCase()).includes(filter.value.toLowerCase())
            : true);
    }
  }
  const {
    airconditioning,
    availablefrom,
    availableto,
    backgroundreq,
    bathrooms,
    bedrooms,
    currency,
    disabilityaccess,
    floor,
    holdunit,
    internet,
    kitchentype,
    maidservice,
    masterId,
    minimumstay,
    notice,
    orderId,
    parking,
    parkingspaces,
    petfeesandrestrictions,
    petpolicy,
    portalId,
    rate,
    rateperday,
    smokingunit,
    squarefoot,
    style,
    supplier,
    unittype,
    utilitycap,
    washer_dryer,
    loader,
    ordersInfo,
    
    //  MasterId,
    //  ProposedRate,
    //  ComplexId,
    //  ComplexName,
    //  ParkingSpace1,
    //  ParkingSpace2,
    //  AvailabilityStartDate,
    //  AvailabilityEndDate,
    //  Aptaddress,
    //  AptCity,
    //  AptState,
    //  AptZip,
    //  Latitude,
    //  Longitude,
    //  FirmQuote,
    //  IsBR,
    //  HoldUntil,
    //  PetFriendly,
    //  IsMasterAvailable,
    //  ReloquestPortalId




    } = props;
return(
<ReactTable
multiSort={true}
allowCellsRecycling={true}
loading={loader}
defaultFilterMethod={filterMethod}
data={ordersInfo}
columns={[
    {
    
        accessor: d=> (<input type="checkbox" checked={_.findIndex(props.SubmitionsData, function(o) { return o.indexVal==d.masterId})>=0? true : false} onChange={()=>{props.checkData(d)}} /> ),
        id: "selectall",
        filterable: false,
        sortable: false,
        style: {'textAlign': 'center'},
        width: 50
      },
    {
        Header: "MasterId",
        id: 'masterId',
        style: {'textAlign': 'center'},
        show: masterId,
        accessor: row=> row.masterId,
    },
    {
        Header: "OrderId",
        id: 'orderId',
        style: {'textAlign': 'center'},
        show: orderId,
        accessor: row=> row.orderId,
    },
    {
        Header: "PortalId",
        id: 'portalId',
        style: {'textAlign': 'center'},
        width: 130,
        show: portalId,
        accessor: row=> row.portalId,
    },
    {
        Header: "AvailableFrom",
        id: 'availablefrom',
        style: {'textAlign': 'center'},
        width: 130,
        show: availablefrom,
        accessor: row=> (row.availablefrom !=null?AppController.DateFormate(row.availablefrom):'---'),
    },
    {
        Header: "AvailableTo",
        id: 'availableto',
        style: {'textAlign': 'center'},
        show: availableto,
        width: 130,
        accessor: row=> (row.availableto !=null?AppController.DateFormate(row.availableto):'---'),
    },
    {
        Header: "AirConditioning",
        id: 'airconditioning',
        style: {'textAlign': 'center'},
        show: airconditioning,
        width: 130,
        accessor: row=> row.airconditioning,
    },
  
    {
        Header: "BackgroundReq",
        id: 'backgroundreq',
        style: {'textAlign': 'center'},
        width: 130,
        show: backgroundreq,
        accessor: row=> row.backgroundreq,
    },
    {
        Header: "Bedrooms",
        id: 'bedrooms',
        style: {'textAlign': 'center'},
        width: 130,
        show: bedrooms,
        accessor: row=> row.bedrooms,
    },
    {
        Header: "Bathrooms",
        id: 'bathrooms',
        style: {'textAlign': 'center'},
        width: 130,
        show: bathrooms,
        accessor: row=> row.bathrooms,
    },
    {
        Header: "Currency",
        id: 'currency',
        style: {'textAlign': 'center'},
        width: 130,
        show: currency,
        accessor: row=> (row.currency),
    },
    {
        Header: "DisabilityAccess",
        id: 'disabilityaccess',
        style: {'textAlign': 'center'},
        width: 130,
        show: disabilityaccess,
        accessor: row=> (row.disabilityaccess),
    },
    {
        Header: "Floor",
        id: 'floor',
        style: {'textAlign': 'center'},
        show: floor,
        accessor: row=> row.floor,
    },
    {
        Header: "HoldUnit",
        id: 'holdunit',
        style: {'textAlign': 'center'},
        show: holdunit,
        accessor: row=> row.holdunit,
    },
    {
        Header: "Internet",
        id: 'internet',
        style: {'textAlign': 'center'},
        show: internet,
        accessor: row=> row.internet,
    },
    {
        Header: "KitchenType",
        id: 'kitchentype',
        style: {'textAlign': 'center'},
        show: kitchentype,
        accessor: row=> row.kitchentype,
    },
    {
        Header: "MaidService",
        id: 'maidservice',
        style: {'textAlign': 'center'},
        show: maidservice,
        accessor: row=> row.maidservice,
    },
    {
        Header: "MinimumStay",
        id: 'minimumstay',
        style: {'textAlign': 'center'},
        show: minimumstay,
        width: 130,
        accessor: row=> row.minimumstay,
    },
    {
        Header: "Notice",
        id: 'notice',
        style: {'textAlign': 'center'},
        show: notice,
        accessor: row=> row.notice,
    },

    {
        Header: "Parking",
        id: 'parking',
        style: {'textAlign': 'center'},
        show: parking,
        accessor: row=> row.parking,
    },
    {
        Header: "ParkingSpaces",
        id: 'parkingspaces',
        style: {'textAlign': 'center'},
        show: parkingspaces,
        accessor: row=> row.parkingspaces,
    },
    {
        Header: "PetfeesAndRestrictions",
        id: 'petfeesandrestrictions',
        style: {'textAlign': 'center'},
        width: 180,
        show: petfeesandrestrictions,
        accessor: row=> row.petfeesandrestrictions,
    },
    {
        Header: "PetPolicy",
        id: 'petpolicy',
        style: {'textAlign': 'center'},
        width: 130,
        show: petpolicy,
        accessor: row=> row.petpolicy,
    },

    {
        Header: "Rate",
        id: 'rate',
        style: {'textAlign': 'center'},
        width: 130,
        show: rate,
        accessor: row=> row.rate,
    },
    {
        Header: "RatePerday",
        id: 'rateperday',
        style: {'textAlign': 'center'},
        width: 130,
        show: rateperday,
        accessor: row=> row.rateperday,
    },
    {
        Header: "SmokingUnit",
        id: 'smokingunit',
        style: {'textAlign': 'center'},
        width: 130,
        show: smokingunit,
        accessor: row=> row.smokingunit,
    },
    {
        Header: "SquareFoot",
        id: 'squarefoot',
        style: {'textAlign': 'center'},
        width: 130,
        show: squarefoot,
        accessor: row=> row.squarefoot,
    },
    {
        Header: "Style",
        id: 'style',
        style: {'textAlign': 'center'},
        width: 130,
        show: style,
        accessor: row=> row.style,
    },
    {
        Header: "Supplier",
        id: 'supplier',
        style: {'textAlign': 'center'},
        width: 130,
        show: supplier,
        accessor: row=> row.supplier,
    },
    {
        Header: "UnitType",
        id: 'unittype',
        style: {'textAlign': 'center'},
        width: 130,
        show: unittype,
        accessor: row=> row.unittype,
    },

    {
        Header: "UtilityCap",
        id: 'utilitycap',
        style: {'textAlign': 'center'},
        width: 130,
        show: utilitycap,
        accessor: row=> row.utilitycap,
    },
    {
        Header: "Washer_Dryer",
        id: 'washer_dryer',
        style: {'textAlign': 'center'},
        width: 130,
        show: washer_dryer,
        accessor: row=> row.washer_dryer,
    },
]}

defaultPageSize={10}
pageSizeOptions={[5, 10, 15, 20, 25, 50, 100, ]}
sortable={true} 
className="-striped -highlight analsys_table"
filterable= {true}
style={{
    height: "500px"
  }}
/>

)

}


export default OrdersTable;

