import React from 'react'
import Footer from './footer';
import Header from './header';
class TimeLine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  async componentDidMount() {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }
  }

  render() {

    return (
      <div className="SAweb_updateUi">
        <Header />
        <div className="container newTimeline">
          <div className="timeline">
            <div className="line"></div>
            <span className="lineShadow"></span>
            <div className="section" data-aos="fade-up">
              <div className="bead">
                <span></span>
                <h2>1990</h2></div>
              <div className="content">
                <div className="pic">
                  <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/aboutUS/timeLine.jpg" alt="" />
                </div><p className="p">Started ‘California Suites’ from the home and garage of founders Jim and Robin</p>
              </div>
            </div>
            <div className="section" data-aos="fade-up">
              <div className="bead">
                <span></span>
                <h2>1991</h2></div>
              <div className="content">
                <div className="pic">
                  <img
                    // src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/aboutUS/timeLine-2.jpg"
                    src="/assets/newUI-images/timeline_images/hayward picture.jpg"
                    alt="" />
                </div> <p className="p">Rented first office and warehouse in Hayward CA</p>
              </div>
            </div>

            <div className="section" data-aos="fade-up">
              <div className="bead">
                <span></span>
                <h2>1993</h2></div>
              <div className="content">
                <div className="pic">
                  {/* <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/aboutUS/timeLine-4.jpg" alt="" /> */}
                </div> <p className="p">Acquired ‘The Hotel Alternative’</p>
              </div>
            </div>


            <div className="section" data-aos="fade-up">
              <div className="bead">
                <span></span>
                <h2>1995</h2></div>
              <div className="content">
                <div className="pic">
                  {/* <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/aboutUS/timeLine-3.jpg" alt="" /> */}
                </div> <p className="p">Acquired ‘Corporate Quarters’</p>
              </div>
            </div>
            <div className="section" data-aos="fade-up">
              <div className="bead">
                <span></span>
                <h2>1998</h2></div>
              <div className="content">
                <div className="pic">
                  <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/aboutUS/timeLine-4.jpg" alt="" />
                </div> <p className="p">Extended services to the East Coast of the United States and renamed the company to 'SuiteAmerica'</p>
              </div>
            </div>
            <div className="section" data-aos="fade-up">
              <div className="bead">
                <span></span>
                <h2>2000</h2></div>
              <div className="content">
                <div className="pic">
                  <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/aboutUS/EDH+office+front.jpeg" alt="" />
                </div> <p className="p">Set up a new office, the current Headquarters in EI Dorado Hills, California</p>
              </div>
            </div>
            <div className="section" data-aos="fade-up">
              <div className="bead">
                <span></span>
                <h2>2009</h2></div>
              <div className="content">
                <div className="pic">
                  {/* <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/aboutUS/timeLine-5.jpg" alt="" /> */}
                </div> <p className="p">Became the 1st corporate housing company to launch an App for iPhone</p>
              </div>
              <div className="content">
                <div className="pic">
                  {/* <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/aboutUS/timeLine-6.jpg" alt="" /> */}
                </div> <p className="p">Received the Most Prestigious Industry Award - Corporate Housing Company of the Year</p>
              </div>
            </div>
            <div className="section" data-aos="fade-up">
              <div className="bead">
                <span></span>
                <h2>2011</h2></div>
              <div className="content">
                <div className="pic suit2011">
                  <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/aboutUS/Timeline2011.png" alt="" />
                </div> <p className="p">Acquired U.S Suites</p>
              </div>
            </div>
            <div className="section" data-aos="fade-up">
              <div className="bead">
                <span></span>
                <h2>2016</h2></div>
              <div className="content">
                <div className="pic">
                  <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/aboutUS/Timeline2016.jpg" alt="" />
                </div> <p className="p">Became a team of over 200 employees</p>
              </div>
            </div>
            <div className="section" data-aos="fade-up">
              <div className="bead">
                <span></span>
                <h2>2018</h2></div>
              <div className="content">
                <div className="pic">
                  {/* <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/aboutUS/timeLine_Placeholder.jpg" alt="" /> */}
                </div> <p className="p">Launched a new Cloud-based platform, CHAPP</p>
              </div>
            </div>
            <div className="section" data-aos="fade-up">
              <div className="bead">
                <span></span>
                <h2>2020</h2></div>
              <div className="content">
                <div className="pic">
                  <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/aboutUS/timeLine2020.jpg" alt="" />
                </div> <p className="p">30th anniversary of SuiteAmerica</p>
              </div>
            </div>
            <div className="section" data-aos="fade-up">
              <div className="bead">
                <span></span>
                <h2>2021</h2></div>
              <div className="content">
                <div className="pic">
                  {/* <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/aboutUS/timeLine_Placeholder.jpg" alt="" /> */}
                </div> <p className="p">Served 7 Legacy Clients for over 30 years</p>
              </div>
            </div>
            <div className="section" data-aos="fade-up">
              <div className="bead">
                <span></span>
                <h2>2022</h2></div>
              <div className="content">
                <div className="pic">
                  <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/aboutUS/Timeline2022.jpg" alt="" />
                </div> <p className="p">First Corporate Housing company to launch an all-suite community in Silicon Valley</p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default TimeLine;