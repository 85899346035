/* Importing the node modules, child components, services and controllers used 
   inside ContactUs component */ 
 
   import AppController from "../controller/appController";
   import MainController from "../controller/mainController";
   import NikeServices from '../../services/nikeService';

   /* ContactUs Component initialization */
   class ContactUs extends React.Component {
       /* Initializing objects of its ContactUs class */
       constructor(props) {
           super(props)
           this.state={
               name: '',
               email: '',
               phoneNumber: '',
               company: '',
               title: '',
               color:'',
               errorMessage:''
           }
           this.serviceRequest = this.serviceRequest.bind(this);
           this.setValue = this.setValue.bind(this);
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
            MainController.getInitialInformation();
        }   
        /* Form Details object creations  */
        setValue(field, e) {
            var object = {};
            object[field] = e.target.value;
            this.setState(object);
        }    
       /* Service Request Details send to mail  */
       async serviceRequest(e){  
            e.preventDefault();
            const _this = this;
            var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
            const validationFields=['name','email','phoneNumber']
            let error = 0;
            _.forEach(validationFields, function(value) {
              if(_this.state[value] == ""){
                error++;
              }
            });
            _.forEach(validationFields, function(value) {
                if(!reg.test(_this.state.email)){
                    error++;
                  }
                });
            const companyName = this.state.company == "" ? "---" : this.state.company;
            if(error == 0){
                const Request={
                    name:this.state.name,
                    email:this.state.email,
                    phoneNumber: this.state.phoneNumber,
                    company: companyName,
                    title: this.state.title == "" ? "---" : this.state.title,
                    mail:'SalesManager',
                    subject: this.props.contactDetails.name + " | Contact Us | " + this.state.name + " | " + companyName,
                    type: this.props.contactDetails.type,
                    managerEmailId: this.props.managerEmailId
                }    
                this.setState({
                    name: '',
                    email: '',
                    phoneNumber: '',
                    company: '',
                    title: '',
                    color:'',
                    errorMessage:'', 
                })
                jQuery(function($) {
                    $('#successModal').modal('show');
                    setTimeout(function(){
                    $('#successModal').modal('hide');
                    },4000);
                });
                AppController.menuTopNavigation('serviceRequest');
                await NikeServices.serviceRequest(Request)
            }
            else{
                AppController.menuTopNavigation('serviceRequest');
                this.setState({errorMessage:'Please Enter Required Fields',color:'red'})
            } 
       }     
       /* It is invoked to return html content */
       render() {    
        return (                      
            <div className="container">
                <div className="text-center person_form">
                    <p className="text-center" style={{color:this.state.color}}>{this.state.errorMessage}</p>
                    <form role="form" method="post" className="" 
                    onSubmit={(e) => this.serviceRequest(e)}>
                        <div className="form-group col-md-6">
                            <input type="text" className="form-control" placeholder="*First & Last Name" value={this.state.name} onChange={(e) => this.setValue('name', e)}/>
                        </div>
                        <div className="form-group col-md-6">
                            <input type="text" className="form-control" placeholder="*Email Address" value={this.state.email} onChange={(e) => this.setValue('email', e)}/>
                        </div>
                        <div className="form-group col-md-6">
                            <input type="text" className="form-control" placeholder="*Phone Number" value={this.state.phoneNumber} onChange={(e) => this.setValue('phoneNumber', e)}/>
                        </div>
                        <div className="form-group col-md-6">
                            <input type="text" className="form-control" placeholder="Company" value={this.state.company} 
                            onChange={(e) => this.setValue('company', e)}/>
                        </div>
                        <div className="form-group col-md-6 col-md-offset-3">
                            <input type="text" className="form-control" placeholder="Title" value={this.state.title} 
                            onChange={(e) => this.setValue('title', e)}/>
                        </div>
                        <div className="clearfix"></div>
                        <div className="form-group col-md-12">
                            <button className="btn company_btn" type="submit" >SEND</button>
                        </div>
                    </form>
                </div>
            </div>
            
           );
       }
   }
   export default ContactUs;