/* Importing the node modules, child components, services and controllers used 
   inside Guestportal component */
   import GuestEmailService from '../../../services/guestEmailService';
   import SuccessModal from '../../common/successModal';
   /* Guestportal Component initialization */
   class GotAquestion extends React.Component{
       /* Initializing objects of its Guestportal class */
       constructor(props) {
           super(props)
           /* Guestportal Component State variables Initialization */
           this.state = {
                description:'',
                guestOrder:this.props.guestOrder
             }
             this.setValue = this.setValue.bind(this);
             this.questionRequest = this.questionRequest.bind(this);
           }
           setValue(field, e) {
            var object = {};
            object[field] = e.target.value;
            this.setState(object);
          }
          async questionRequest(){
            if(this.state.description !=''){
              const questionRequest={
            
                Requested : this.state.guestOrder.GuestFirstName,
                community : this.state.guestOrder.community_name,
                Description:this.state.description,
                phoneNo : this.state.guestOrder.GuestPhoneNo,
                CreatedBy : this.state.guestOrder.GuestEmail,
                conformationNo : this.state.guestOrder.orderno,
               
              }
              const mailResponse=await GuestEmailService.SendMailToSuite(questionRequest)
              if(mailResponse.status==200){
                this.setState({description:'',firstName:'',loader:false})
                jQuery(function($) {
                    $('#successModal').modal('show');
                    setTimeout(function(){
                      $('#successModal').modal('hide');
                    },1500);
                  });
                }
            }

          }
   render(){

       return(

   <div className="container-fluid bg-grey sec-pad">
      <div className="container">
         <div className="row">
            <div className="col-md-8 col-md-offset-2 text-center">
               <h4 className="new-title">GOT A QUESTION?</h4>
               <p>Connect with us anytime! Our Guest Services team will get
                  right back to you within the hour.
               </p>
               <div className="form-group">
                  <textarea className="form-control" rows="13" cols="50" placeholder="Send us an email" value={this.state.description} 
                  onChange={(e) => this.setValue('description', e)}>
                  </textarea>
               </div>
               <button className="location_btn btn btn-xxxlg" type="button" onClick={this.questionRequest}>SEND</button>
            </div>
         </div>
      </div>
      <SuccessModal/>
   </div>
 
       );
   }
   }
   export default GotAquestion;