import React, { useEffect } from "react";
import DatePicker from "react-datepicker";
import NikeService from "../../../../../services/nikeService";
import { SuiteCareContext } from "../context/Context";
import BookitApiService from "../../../../../services/bookit";

const Reservation = () => {
  const { suiteCareState, getClientData } = React.useContext(SuiteCareContext);
  const { activeTab, selectedMasterForReservation } = suiteCareState;
  // console.log(selectedMasterForReservation, "selectedMasterForReservation");
  const [reservationData, setReservationData] = React.useState({
    firstname: "",
    lastname: "",
    email: "",
    phoneNumber: "",
    moveIn: null,
    moveOut: null,
  });
  const [messageshow, setMessageShow] = React.useState(false);
  const [popupLoader, setPopUpLoader] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);

  const handleInput = (type, e) => {
    let value = e.target.value;

    setReservationData((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  const handlePhoneNumber = async (type, event) => {
    let value = event.target.value.replace(/\D/g, ""); // Remove all non-numeric characters

    if (type === "phoneNumber") {
      var x = value.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      value = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");

      await setReservationData((prev) => ({
        ...prev,
        phoneNumber: value,
      }));
    }
  };

  const handleMoveInDate = (moveIn) => {
    setReservationData((prev) => ({ ...prev, moveIn: moveIn }));
  };

  const handleMoveOutDate = (moveOut) => {
    setReservationData((prev) => ({ ...prev, moveOut: moveOut }));
  };

  const closeReservationModal = () => {
    $(function () {
      $("#createNewReservation").modal("hide");
      $("#firstname").removeClass("is-invalid");
      $("#lastname").removeClass("is-invalid");
      $("#email").removeClass("is-invalid");
      $("#phoneNumber").removeClass("is-invalid");
      $("#moveIn").removeClass("is-invalid");
      $("#moveOut").removeClass("is-invalid");
    });
    setMessageShow(false);
    setPopUpLoader(false);
    setSuccessMessage("");
    setSuccess(false);
    setError(false);
    setErrorMessage("");
    setReservationData({
      firstname: "",
      lastname: "",
      email: "",
      phoneNumber: "",
      moveIn: null,
      moveOut: null,
    });
  };

  const sendReservationSuccessmail = () => {
    $(function () {
      $("#createNewReservation").modal("hide");
      $("#firstname").removeClass("is-invalid");
      $("#lastname").removeClass("is-invalid");
      $("#email").removeClass("is-invalid");
      $("#phoneNumber").removeClass("is-invalid");
      $("#moveIn").removeClass("is-invalid");
      $("#moveOut").removeClass("is-invalid");
    });
    setMessageShow(false);
    setSuccess(false);
    setError(false);
    setErrorMessage("");
    setSuccessMessage("");
    setPopUpLoader(false);
    setReservationData({
      firstname: "",
      lastname: "",
      email: "",
      phoneNumber: "",
      moveIn: null,
      moveOut: null,
    });
  };

  const newReservationCreate = async () => {
    setPopUpLoader(true);
    const sessionResponse = await BookitApiService.checkSession();

    let userEmail = sessionResponse.email;
    let error = 0;
    if (reservationData.firstname == "") {
      error++;
      $("#firstname").addClass("is-invalid");
    } else {
      $("#firstname").removeClass("is-invalid");
    }
    if (reservationData.lastname == "") {
      error++;
      $("#lastname").addClass("is-invalid");
    } else {
      $("#lastname").removeClass("is-invalid");
    }
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reservationData.email !== "" && !reg.test(reservationData.email)) {
      error++;
      $("#email").addClass("is-invalid");
    } else {
      $("#email").removeClass("is-invalid");
    }
    // if (reservationData.phoneNumber == "") {
    //   error++;
    //   $("#phoneNumber").addClass("is-invalid");
    // } else {
    //   $("#phoneNumber").removeClass("is-invalid");
    // }
    if (reservationData.email == "" && reservationData.phoneNumber == "") {
      error++;
      $("#email").addClass("is-invalid");
      $("#phoneNumber").addClass("is-invalid");
      setError(true);
      setErrorMessage("Please enter either an email or a phone number.");
    } else {
      $("#email").removeClass("is-invalid");
      $("#phoneNumber").removeClass("is-invalid");
    }
    // Validate moveIn date
    if (!reservationData.moveIn) {
      error++;
      $("#moveIn").addClass("is-invalid");
    } else {
      $("#moveIn").removeClass("is-invalid");
    }

    // Validate moveOut date
    if (!reservationData.moveOut) {
      error++;
      $("#moveOut").addClass("is-invalid");
    } else if (reservationData.moveOut <= reservationData.moveIn) {
      error++;
      $("#moveOut").addClass("is-invalid");
      setError(true);
      setErrorMessage("Check out date should be greater than check in date");
    } else {
      $("#moveOut").removeClass("is-invalid");
    }

    if (error == 0) {
      try {
        //console.log(sessionResponse.ClientProfileCardDetails, "sessionResponse")
        const countryCode =
          $(".country.highlight.active").attr("data-dial-code") == undefined
            ? "+1"
            : "+" + $(".country.highlight.active").attr("data-dial-code");
        let requestObj = {
          clientId: sessionResponse.ClientProfileCardDetails.clientId,
          checkin: moment(reservationData.moveIn).format("MM/DD/YYYY"),
          checkout: moment(reservationData.moveOut).format("MM/DD/YYYY"),
          resident_firstname: reservationData.firstname,
          resident_lastname: reservationData.lastname,
          resident_email: reservationData.email,
          resident_phone: reservationData.phoneNumber,
          BR: selectedMasterForReservation.BR,
          countrycode: countryCode,
          changedby: userEmail,
          mastertype: activeTab == "normal" ? "M" : "B",
          leaseUId: selectedMasterForReservation.masterUId,
        };
        // if (activeTab == "billable") {
        //   requestObj.BR = selectedMasterForReservation.BR;
        // }
        let reservationRequest = await BookitApiService.CreateSuiteCareOrder(
          requestObj
        );

        if (reservationRequest.error == 0) {
          let requestData = {
            Uid: sessionResponse.ClientProfileCardDetails.ClientProfileUId,
          };
          let teamrepDetails = await BookitApiService.GetTeamData(requestData);
          let repDetails = teamrepDetails?.message?.filter(
            (team) => team.Type == "Reservation Rep"
          );
          repDetails = Array.isArray(repDetails) ? repDetails : [];
          const sendTo =
            repDetails[0]?.Email == null
              ? "geetha.kunda@devrabbit.com"
              : repDetails[0].Email;

          let object = {
            firstname: reservationData.firstname,
            lastname: reservationData.lastname,
            email: reservationData.email,
            checkIn: moment(reservationData.moveIn).format("MM/DD/YYYY"),
            checkOut: moment(reservationData.moveOut).format("MM/DD/YYYY"),
            mail: "NewReservationRequest",
            subject: `Reservation request - Master# ${selectedMasterForReservation.masterId} - Order# ${reservationRequest.orderId}`,
            toEmail: sendTo,
          };

          await NikeService.serviceRequest(object);
          setMessageShow(true);
          setSuccess(true);
          setSuccessMessage("Created Successfully");
          setPopUpLoader(false);
          await getClientData();
        } else {
          setError(true);
          setPopUpLoader(false);
          setErrorMessage(reservationRequest.message);
        }
      } catch (error) {}
    } else {
      setPopUpLoader(false);
    }
  };

  return (
    <div
      className="modal fade savesearch "
      data-backdrop="static"
      id="createNewReservation"
      role="dialog"
    >
      <div className="modal-dialog">
        <div className="modal-content rm-border-radius">
          <div className="modal-header">
            <button
              type="button"
              onClick={closeReservationModal}
              className="close btn"
              data-dismiss="modal"
              aria-label=""
            >
              <i className="bi bi-x"></i>
            </button>
          </div>
          {messageshow == false && (
            <div className="modal-body pb-5">
              <h3 className="mb-5 mt-0">Reservation request</h3>
              {error == true && (
                <h4 style={{ color: "red" }}>{errorMessage}</h4>
              )}
              <div>
                <div className="spl-form">
                  <div className="form-group">
                    <label>Community Name</label>
                    <label className="main">
                      {selectedMasterForReservation.communityname}
                    </label>
                  </div>
                  <div className="form-group">
                    <label>Suite No</label>
                    <label className="main">
                      {selectedMasterForReservation.suiteno}
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <label className=""> First Name *</label>
                  <b>:</b>
                  <input
                    type="text"
                    id="firstname"
                    placeholder="First name"
                    className="form-control width100per"
                    value={reservationData.firstname}
                    onChange={(e) => handleInput("firstname", e)}
                  />
                </div>
                <div className="form-group">
                  <label> Last Name *</label>
                  <b>:</b>
                  <input
                    type="text"
                    id="lastname"
                    placeholder="Last name"
                    className="form-control width100per"
                    value={reservationData.lastname}
                    onChange={(e) => handleInput("lastname", e)}
                  />
                </div>
                <div className="form-group">
                  <label>Guest Email</label>
                  <b>:</b>
                  <input
                    type="text"
                    id="email"
                    placeholder="Email"
                    className="form-control width100per"
                    value={reservationData.email}
                    onChange={(e) => handleInput("email", e)}
                  />
                </div>
                <div className="form-group">
                  <label>Guest Phone No</label>
                  <b>:</b>
                  <input
                    type="text"
                    id="phoneNumber"
                    autoComplete="off"
                    className="form-control width100per"
                    required
                    placeholder="Phone"
                    value={reservationData.phoneNumber}
                    onChange={(e) => handlePhoneNumber("phoneNumber", e)}
                  />
                </div>
                <div className="form-group">
                  <label className="">Check In Date *</label>
                  <b>:</b>
                  <div className="caldenerWidth width100per">
                    <DatePicker
                      id="moveIn"
                      selected={reservationData.moveIn}
                      dateFormat="MM/DD/yyyy "
                      selectsStart
                      minDate={new Date()}
                      onChange={(date) => handleMoveInDate(date)}
                      placeholderText={"Check In"}
                      onKeyDown={(e) => e.preventDefault()}
                      className="form-control width100per"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="">Check Out Date *</label>
                  <b>:</b>
                  <div className="caldenerWidth width100per">
                    <DatePicker
                      id="moveOut"
                      selected={reservationData.moveOut}
                      dateFormat="MM/DD/yyyy "
                      selectsStart
                      minDate={reservationData.moveIn}
                      onChange={(date) => handleMoveOutDate(date)}
                      placeholderText={"Check Out"}
                      onKeyDown={(e) => e.preventDefault()}
                      className="form-control width100per"
                      autoComplete="off"
                    />
                  </div>
                </div>
                {popupLoader ? (
                  <div
                    className="text-center"
                    style={{
                      fontSize: "20px",
                    }}
                  >
                    <i className="fa fa-spinner fa-spin" />
                    Please wait ...!
                  </div>
                ) : (
                  <button
                    onClick={() => newReservationCreate()}
                    className="btn btn-dark rounded-5 my-4"
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
          )}
          {messageshow == true && (
            <div className="modal-body">
              {success == true && (
                <div className="alert alert-success">{successMessage}</div>
              )}

              <button
                className="btn btn-dark rounded-5 mt-3 mb-4"
                onClick={() => sendReservationSuccessmail()}
              >
                Ok
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Reservation;
