/* Importing the node modules, child components, services and controllers used 
   inside LinkedInHome component */
import MainController from "../../controller/mainController";
import SubFooter from "../../common/subFooter";

import WellsfargoInternsHeader from "./common/WellsfargoInternsHeader";
import { Link } from "react-router-dom";
import FAQ from "./common/faq";
import GuestHeader from "../../common/guestHeader";
import Footer from "../../common/footer";
import Disclaimer from "./common/disclimar";
import SubFooterWellsfargo from "./common/subFooterWellsfargo";
/* NikeHome Component initialization */
class WellsfargoInternsHome extends React.Component {
  /* Initializing objects of its NikeHome class */
  constructor(props) {
    super(props);
  }
  /* It is invoked immediately before mounting occurs */
  componentWillMount() {
    MainController.getInitialInformation();
  }
  /* It is invoked to return html content */
  render() {
    return (
      <div className="corporateHousing_Updates">
        <GuestHeader />

        <div class="in-intern-home-banner SAweb_updateUi">
          <img
            class="bannerImg"
            src="/assets/images/linkedIn-interns/home-banner.jpg"
            alt=""
            width="100%"
          />
          <div class="in-intern-home-content">
            <div class="container">
              <div class="content-width">
                <img
                  src="https://www17.wellsfargomedia.com/assets/images/rwd/wf_logo_220x23.png"
                  alt=""
                  width="200px"
                />

                <h3 class="text-white mrg-top-50 mb-4">
                  Congratulations on your internship!
                </h3>

                <p class="text-white p fw-200 m-0">
                  SuiteAmerica has got you covered with a furnished apartment,
                  and you can use your stipend to make it happen.
                </p>
                {/* <p class="text-white p fw-200">Look no further!</p>
                <p class="text-white p fw-200 my-4">
                  At SuiteAmerica, we connect you up with fellow LinkedIn
                  stipend recipients in search of roommates, Once you've found
                  your match, we'll help you navigate the lease process.
                </p> */}

                <p class="text-white p fw-200 my-4">
                  Utilities & wifi are already included, and no credit checks
                  are needed.
                </p>

                <Link to="/company/Wellsfargo-interns/reservation-form">
                  <button class="btn">Let's Get Started</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <WellsfargoInternsHeader />
        <div className="quote-section bg-white pt-2">
          <div className="container">
            <div
              className=" text-center col-md-10 col-md-offset-1 wow fadeInUp company_p"
              data-wow-delay="0.5s"
            >
              <p>
                <h3 className=" mrg-top-20 mrg-btm-20 fw-700">
                  Hey there, Wells Fargo Interns
                </h3>
                <p>Looking for an apartment?</p>
                <p>
                  Imagine this: no credit checks, no security deposits, and
                  utilities already bundled in – that’s right, no more setting
                  up complicated bills! Plus, we’ve got the Wi-Fi covered
                  because, priorities, right? But hold on, it gets better.
                </p>
                <p>Finding roommates and your own apartment? Effortless.</p>
                <h3 className="mrg-top-20 mrg-btm-20 fw-500">
                  SuiteAmerica is not just about housing; it’s about making the
                  renting game stress-free and totally tailored to you.
                </h3>
              </p>
            </div>
          </div>
          <div className="who_weare_text service_ch_new company_p_height">
            <div className="container">
              <div className="row rows">
                <div className="col-md-6 col-sm-6">
                  <div
                    className="wow fadeInUp company_p dual_quote in-intern-para-box"
                    data-wow-delay="0.5s"
                  >
                    <p className="width500 m-auto">
                      Curious about the details?
                    </p>
                    <Link
                      to="/company/Wellsfargo-interns/how-it-works"
                      className="box-slide-btn mt-3"
                    >
                      How Does it Work?
                    </Link>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6">
                  <div
                    className="wow fadeInUp company_p dual_quote in-intern-para-box"
                    data-wow-delay="0.5s"
                  >
                    <p className="width500 m-auto">
                      Interested in being roommate matched? Fill out the form &
                      let us handle the rest.
                    </p>
                    <Link
                      to="/company/Wellsfargo-interns/reservation-form"
                      className="box-slide-btn mt-3"
                    >
                      Request Form
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Disclaimer />
        {/* <SubFooter /> */}
        <SubFooterWellsfargo />
        <Footer />
      </div>
    );
  }
}
export default WellsfargoInternsHome;
