import AppController from "../../controller/appController";
import ReactTable from "react-table";
const filterMethod = (filter, row) => {
  const id = filter.pivotId || filter.id;
  if (row[id] !== null && typeof row[id] === "string") {
    return (row[id] !== undefined
      ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
      : true);
  }
}
const oldXlsData = props => {

    return (

        <ReactTable
        multiSort={true}
        defaultFilterMethod={filterMethod}
        data={props.oldDataFilter}
        columns={[
              {
                Header: <input type="checkbox" data-id="1" onChange={props.conformationAlert} checked={props.selectAll}/>,
                accessor: d=> (<input type="checkbox" disabled={d.OrderId>0 ? true : false} checked={(_.findIndex(props.emailsData, function(o) { return o.indexVal==d['PNR']})>=0)? true : false} onChange={()=>{props.checkData(d)}} /> ),
               
                id: "selectall",
                filterable: false,
                sortable: false,
                style: {'textAlign': 'center'},
                width: 50
              },
              {
                Header: "Traveler",
                accessor: "Traveler",
                width: 150
              },
              {
                Header: "PNR",
                accessor: "PNR",
                id: "Pnr",
                width: 150
              },

              {
                Header: "Email",
                id: "email",
                accessor: "Email",
                width: 150
              },
              {
                Header: "CheckInDate",
                id: 'checkinDate',
                accessor:  row=> AppController.removeTime(row.CheckInDate),
                sortMethod: (a, b) => {
                  return new Date(a) - new Date(b);
              },
                width: 125
              },
              {
                Header: "CheckOutDate",
                id: 'checkoutDate',
                accessor: row=>AppController.removeTime(row.OutDate),
                sortMethod: (a, b) => {
                  return new Date(a) - new Date(b);
              },
                width: 125
              },
              {
                Header: "MailSentDate",
                id: 'mailsentDate',
                accessor: row=> row.MailSentDate!=null ? row.MailSentDate:'',
                width: 185
               
              },
      
              {
                Header: "#Sent",
                id: "Sent",
                accessor: d=> _.upperFirst(d.SendPromotion),
               
                style: {'textAlign': 'center'},
                width: 85,
                filterMethod: (filter, row) => {
                
                  if (filter.value === "all") {
                    return true;
                  }
                  else if (filter.value === "Yes") {
                    
                    return row.Sent == filter.value? true : false;
                  }
                  else if (filter.value === "No") {
                   
                    return row.Sent == filter.value? true : false;
                  }
                  
                },
                Filter: ({ filter, onChange }) =>
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                    
                  >
                    <option value="all">Select All</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                
              },
              {
                Header: "# of stay",
                accessor: "Nights",
                style: {'textAlign': 'center'},
                width: 75
              },
              {
                Header: "Rate",
                id: 'dailyrate',
                accessor: row=> row.RoomRate!=null?(row.RoomRate).toString():'',
                style: {'textAlign': 'center'},
                width: 50
                
              },
              {
                Header: "Order#",
                id: "Order",
                accessor: d=> d.OrderId!=null?(d.OrderId).toString():'',
                style: {'textAlign': 'center'},
                width: 85,
              },
              {
                Header: "City",
                accessor: "PropertyCity",
              },
              
              {
                Header: "Resend promotion",
                id: "Resend",
                filterable: false,
                style: {'textAlign': 'center'},
                accessor: d=> (<input type="checkbox" disabled={d.OrderId>0 ? true : false} checked={(_.findIndex(props.emailsData, function(o) { return o.indexVal==d['PNR']})>=0)? true : false} onChange={()=>{props.checkData(d)}} /> ),
              }  ]}
              defaultPageSize={25}
              filterable={true}
              sortable={true}
              defaultFiltered={[{id: "Sent", value: props.sendPromotionEmail==1? "Yes" : 'all'}]}
              className="-striped -highlight"
      />
                                

    );
  };

  export default oldXlsData;
