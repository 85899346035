/* Importing the node modules, child components, services and controllers used 
   inside TeslaLocations component */
import $ from "jquery";
import MainController from "../../controller/mainController";
import SubFooter from "../../common/subFooter";
import TangerineHeader from "../../common/tangerineHeader";
import Location from "../location/tangerineLocations"; 
import Footer from '../../common/footer';
import GuestHeader from '../../common/guestHeader';
/* TeslaLocations Component initialization */
class TangerineLocations extends React.Component {
    /* Initializing objects of its TeslaLocations class */
    constructor(props) {
        super(props)
        this.state = {
            companyname: 'TANGERINE'
        }
    }
    /* It is invoked immediately before mounting occurs */
    componentWillMount() {
        MainController.getInitialInformation();
    }
    /* It is invoked to return html content */
    render() {
        return (
            <div className="corporateHousing_Updates telsa-page-view">
               <GuestHeader/> 
               <TangerineHeader />
               <div className="container-view">
                    <header className="codeconveyHeader cc_image_text_main corporate-h">
                        <img className="img-responsive tangerineImage" src="/assets/newUI-images/tangerine/tangerine-home" width="100%" alt="" />
                        <div className="wow fadeInUp text-center img-on-text-center" data-wow-delay="0.5s">
                            <h1>Locations</h1>
                            <p className="p">Fully furnished and appointed corporate housing accommodations
                                throughout the United States.
                            </p>
                            <p className="p" style={{ fontStyle: "italic" }}>*All apartment accommodations are subject to
                                availability and minimum stay requirements may apply in some cities.</p>
                        </div>
                    </header>
                </div>
                 <div className="red-border"></div>
              {/*  <div className="container-fluid bg-grey">

                </div> */}
                <Location companyName={this.state.companyname} props={this.props} />
                
              
                <SubFooter />
                <Footer />
            </div>
        );
    }
}
export default TangerineLocations;