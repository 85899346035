const NikeApprenticeshipFooter = () => {
           return (            
                    <div className="text-center">
                    <strong>RELOCATION COORDINATOR - KEY ACCOUNTS</strong> 
                            <h5 className="rdtxt-clr">Nathan  Kraft</h5>
                            <h5><i className="fa fa-phone-square"></i>
                                <span className="desktop-phone">916.941.7970 x612</span>
                            </h5>
                            <h5><a href="mailto:nathan@suiteamerica.com">nathan@suiteamerica.com</a></h5>
                            <div className="section-line"></div>
                            <h5 className="rdtxt-clr">Jennifer Saylor</h5>
                            <h5><i className="fa fa-phone-square"></i>
                                <span className="desktop-phone">916.941.7970 x176</span>
                            </h5>
                            <h5><a href="mailto:jennifer@suiteamerica.com">jennifer@suiteamerica.com</a></h5>                            
                    </div>
            );
       }
   export default NikeApprenticeshipFooter;