import React, { Component } from 'react'
import SessionService from "../../../services/sessionService";
import GuestServicesapi from '../../../services/guestServicesapi';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import Loader from '../../common/loader';

export class ActiveOrderInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeOrderInfo: [],
      userData: '',
      orderInfo: {},
      ScheduleInfo: {},
      // tab: "",
      loader: false,
      showIfActiveInfoEmpty: false,
      showTripInfo: false,
      orderUid: "",
      ifLockBoxShowGuestPane: false,
      GuestInfoCommunityDetails: {},
      tab: 'communityDetails',
      selectedData:{}
    }
  }

  GetGuestInfoCommunityByOrderId(data) {
    this.setState({ GuestInfoCommunityDetails: {} })
    GuestServicesapi.GetGuestInfoCommunityByEmailId({ orderid: data.OrderId }).then(response => {
      this.setState({ GuestInfoCommunityDetails: response })
    })
  }

  async componentDidMount() {
    this.setState({ loader: true })
    if (Object.keys(this.state.orderInfo).length == 0) {
      SessionService.SessionGet({ type: 'guest' }).then((response) => {
        this.setState({ userData: response })
        var guestEmailObj = {
          guestEmail: response.GuestEmail,
          status: "active"
        }
        GuestServicesapi.GetOrdersByGuestEmail(guestEmailObj).then((responses) => {
          if (responses.error !== 1 && responses.message.Orders.length > 0) {
            responses.message.Orders.sort(function compare(a, b) {
              var dateA = new Date(a.MoveInDate);
              var dateB = new Date(b.MoveInDate);
              return dateA - dateB;
            });
            this.setState({
              activeOrderInfo: responses.message.Orders
              , loader: false
            });
          } else {
            this.setState({ loader: false, showIfActiveInfoEmpty: true })
          }
        })


      })
    }
  }

  navigateToMyReservation(image, OrderUId, ComplexId, Order, type) {
    var orderUIdObj = {
      OrderUId: OrderUId
    }
    if (type == "S") {
      GuestServicesapi.GetOrderInfoById(orderUIdObj).then((getOrderInfo) => {
        if (getOrderInfo.error == 0) {
          let obj = getOrderInfo.message.orderInfo;
          obj["CommunityImage"] = image;
          obj["ComplexId"] = ComplexId;
          obj["OrderType"] = type;
          this.props.history.push({
            pathname: "/my-reservations",
            state: obj
          })

        }
      }).catch(error => {
        console.log(error)
      })
    } else {
      this.props.selectedData(Order)
    }
  }

  render() {
    return (
      <div>
        <div>
        </div>
        {/* {this.state.showTripInfo == false ? */}
        {this.state.activeOrderInfo.length > 0 ?
          <div>
            {this.state.siteLoader &&
              <SiteLoader />
            }
            <div className="home-slider">
              {this.state.activeOrderInfo.map((activeOrderInfo, index) => {
                return (
                  <div className="card" key={index}>
                    <img src={(activeOrderInfo.CommunityImage == null || activeOrderInfo.CommunityImage == "") ? activeOrderInfo.OrderType == "H" ? "../images/s-dummyImage-2.png" : "../images/dummyCommunity.jpg" : activeOrderInfo.CommunityImage} alt="" className="card-img-top " />
                    <div className="card-body">
                      {activeOrderInfo.OrderType == "S" ? <h1>{activeOrderInfo.Community}</h1> : activeOrderInfo.HotelName ? <h1>{activeOrderInfo.HotelName}</h1> : <h1>Hotel Name</h1>}

                      {activeOrderInfo.OrderType == "S" &&
                        <p style={{ textTransform: 'capitalize' }}>{(activeOrderInfo.City).toLowerCase()}, {(activeOrderInfo.State).toLowerCase()}</p>}

                      {activeOrderInfo.OrderType == "H" &&
                        <p style={{ textTransform: 'capitalize' }}>{activeOrderInfo.HotelCity ? activeOrderInfo.HotelCity : "San Francisco"}, {activeOrderInfo.HotelState ? activeOrderInfo.HotelState : "California"}</p>
                      }

                      <span className="btm">
                        <a>{(moment(activeOrderInfo.MoveInDate).format('DD'))} {moment(activeOrderInfo.MoveInDate).format('MMMM')} {moment(activeOrderInfo.MoveInDate).format('YYYY')} - {(moment(activeOrderInfo.MoveOutDate).format('DD'))} {moment(activeOrderInfo.MoveOutDate).format('MMMM')} {moment(activeOrderInfo.MoveOutDate).format('YYYY')}</a>
                        {/* {Object.keys(this.state.orderInfo).length > 0 && */}
                        <button type="button" className="btn" onClick={() => this.navigateToMyReservation(activeOrderInfo.CommunityImage, activeOrderInfo.OrderUId, activeOrderInfo.ComplexId, activeOrderInfo, activeOrderInfo.OrderType)}>See Details</button>
                        {/* } */}
                      </span>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>

          : this.state.loader ?
            <Loader /> : this.state.showIfActiveInfoEmpty ?
              <p style={{ color: "red", marginTop: "35px", textAlign: "center" }}>You don't have any active reservations.</p>
              : null
        }

      </div>
    )
  }
}

export default withRouter(ActiveOrderInfo)