/* Importing the node modules, child components, services and controllers used 
   inside NikeApprenticeshipQuestions component */ 
   import MainController from "../../controller/mainController";
   import NikeApprenticeshipHeader from "../../common/nikeApprenticeshipHeader";
   import SubFooter from "../../common/subFooter";
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   import ApprenticeshipFooter from "./common/apprenticeshipFooter";   
   /* NikeApprenticeshipQuestions Component initialization */
   class NikeInternshipFaq extends React.Component {
       /* Initializing objects of its NikeApprenticeshipQuestions class */
       constructor(props) {
         super(props);
         this.state = {
             fqs: [{
                 'Question': 'When will I find out where I am staying?',
                 'Answer': 'SuiteAmerica can start confirming availability once the roommate matching has been completed',
             },
             {
                 'Question': 'Can I request a specific Community and/or Apartment Size?',
                 'Answer': 'Due to market availability and roommate matching, SuiteAmerica is unable to take requests for specific locations or apartment sizes.',
             },
             {
                 'Question': 'When will my apartment location be confirmed?',
                 'Answer': 'Your apartment will be confirmed once you have been matched with a roommate and your rental paperwork has been completed.',
             },
             {
                 'Question': 'What does my apartment include?',
                 'Answer': 'Your apartment will include all furniture, housewares, linens, utilities, cable, wireless internet and home phone service. For full listing of what is included, please visit the Apartment Tour section of the website.',
             },
             {
                 'Question': "Are there any other additional fee's or deposits?",
                 'Answer': 'SuiteAmerica does not charge any deposits, taxes, move-out/cleaning charges.',
             },
             {
                 'Question': 'Is parking included?',
                 'Answer': 'Typically one parking space is included per apartment.',
             }]
         }
     }
     /* It is invoked immediately before mounting occurs */
     componentWillMount() {
         MainController.getInitialInformation();
     }
     /* It is invoked to return html content */
     render() {
         let {fqs} = this.state;
            return (
                <div className="corporateHousing_Updates">
                <GuestHeader/>
                <div className="cover-tophd-ht"></div>
                <NikeApprenticeshipHeader />
                <div className="container-fluid nike guest_sitenew">
                  <div className="container intra_staff2 col-sm-8 col-sm-offset-2">
                     <h2 className="text-center company-title">FREQUENT ASKED QUESTIONS</h2>
                     <div id="accordion" className="panel-group mrg-btm-90 company_acdn" role="tablist" aria-multiselectable="true">
                        {fqs.map((row, index) => {
                        return (
                        <div className="panel panel-default" key={index}>
                           <div className="panel-heading">
                              <h4 className="panel-title">
                                 <a className="collapsed"
                                    data-toggle="collapse"
                                    data-parent="#accordion"
                                    href={'#collapse' + index}
                                    aria-expanded="false"
                                    aria-controls={row.id}
                                    >{row.Question}</a>
                              </h4>
                           </div>
                           <div id={'collapse' + index}
                              className="panel-collapse collapse">
                              <div className="panel-body">
                                 <div className="profile-opener">
                                    {row.Answer}
                                 </div>
                              </div>
                           </div>
                        </div>
                        )
                        })}
                     </div>
                  </div>
               </div>
                <div className="container ft-contact-adrs mrg-btm-30 mrg-top-30">
                   <ApprenticeshipFooter />
                </div>
                <SubFooter/>
                <Footer/>
             </div>
            );
       }
   }
   export default NikeInternshipFaq;