/* Importing the node modules, child components, services and controllers used 
   inside PrivacyPolicy component */
import MainController from "../controller/mainController";
import SubFooter from "./subFooter";
import GuestHeader from './guestHeader';
import Footer from './footer';
import MetaTags from './metaTags';
import ServerMetaTags from '../../server/metaTags';

/* PrivacyPolicy Component initialization */

class PrivacyPolicy extends React.Component {
    /* Initializing objects of its PrivacyPolicy class */
    constructor(props) {
        super(props);
    }
    /* It is invoked immediately before mounting occurs */
    componentWillMount() {
        MainController.getInitialInformation();
    }
    /* It is invoked to return html content */
    render() {
        const metaData = ServerMetaTags.urlString.getMetaData(this.props.match.url);
        return (
            <div>
            <MetaTags 
             metaTitle = {metaData.title}
             metaDesription = {metaData.description}
             metaUrl = {metaData.canonical} />
            <GuestHeader/>
            <div className="company_section testmonial_gap bg-grey mrg-top-100">
                <div className="container privacy_policy">
                    <div className="global-coverage-wrap col-md-10 col-md-offset-1 wow fadeInUp" data-wow-delay="0.5s">
                        <div className="col-md-8 col-md-offset-2">
                            <h1 className="text-center f-300 gotham-font large-font mrg-btm-40">SuiteAmerica Privacy Policy</h1>
                        </div>
                        <div className="clearfix"></div>
                        
                        <p>SuiteAmerica is committed to ensuring that your privacy is protected. Should we ask you to provide certain 
                        information by which you can be identified when using this website, then you can be assured that it will only 
                        be used in accordance with this privacy statement.
                        </p>
                        <p>SuiteAmerica may change this policy from time to time by updating this page. You should check this page from 
                        time to time to ensure that you are happy with any changes. This policy is effective from October 28, 2013.
                        </p>
                        <h5 className="secondary_head">What We Collect</h5>
                        <p>We may collect the following information:</p>
                        <ul>
                        <li>Name and job title</li>
                        <li>Contact information including email address</li>
                        <li>Demographic information such as postcode, preferences and interests</li>
                        <li>Other information relevant to customer surveys and/or offers</li>
                        </ul>
                        <h5 className="secondary_head">What We Do With The Information We Gather</h5>
                        <p>We require this information to understand your needs and provide you with a better service, and in particular 
                        for the following reasons:
                        </p>
                        <ul>
                        <li>Internal record keeping.</li>
                        <li>We may use the information to improve our products and services.</li>
                        <li> We may periodically send promotional email about new products, special oers or other information which 
                            we think you may find interesting using the email address which you have provided.
                        </li>
                        <li>From time to time, we may also use your information to contact you for market research purposes. We 
                            may contact you by email, phone, fax or mail.
                        </li>
                        <li>We may use the information to customize the website according to your interests.</li>
                        <li>We may provide your information to our third party partners for marketing or promotional purposes.</li>
                        <li>We will never sell your information.</li>
                        </ul>
                        <h5 className="secondary_head">Security</h5>
                        <p>We are committed to ensuring that your information is secure. In order to prevent unauthorized access or dis-
                        closure we have put in place suitable physical, electronic and managerial procedures to safeguard and secure 
                        the information we collect online.
                        </p>
                        <h5 className="secondary_head">How We Use Cookies</h5>
                        <p>A cookie is a small file which asks permission to be placed on your computer's hard drive. Once you agree, the 
                        file is added and the cookie helps analyze web trac or lets you know when you visit a particular site. Cookies 
                        allow web applications to respond to you as an individual. The web application can tailor its operations to 
                        your needs, likes and dislikes by gathering and remembering information about your preferences.
                        </p>
                        <p>We use traffic log cookies to identify which pages are being used. This helps us analyze data about web page 
                        traffic and improve our website in order to tailor it to customer needs. We only use this information for statis-
                        tical analysis purposes and then the data is removed from the system.
                        </p>
                        <p>Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find 
                        useful and which you do not. A cookie in no way gives us access to your computer or any information about 
                        you, other than the data you choose to share with us.
                        </p>
                        <p>You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can 
                        usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full 
                        advantage of the website.
                        </p>
                        <h5 className="secondary_head">Links To Other Websites</h5>
                        <p>Our website may contain links to enable you to visit other websites of interest easily. However, once you have 
                        used these links to leave our site, you should note that we do not have any control over that other website. 
                        Therefore, we cannot be responsible for the protection and privacy of any information which you provide 
                        whilst visiting such sites and such sites are not governed by this privacy statement. You should exercise cau-
                        tion and look at the privacy statement applicable to the website in question.
                        </p>
                    </div>
                </div>
            </div>
            <SubFooter />
            <Footer/>
            </div>     
        );
    }
}
export default PrivacyPolicy;