/* Importing the node modules, child components, services and controllers used 
   inside travelCalendar object */

'use strict'
const travelCalendar = {
    checkInCalendar(thisObj, changed, dateString) {    
        const _this = this
        const calObj = dateString!=''?  {    
            locale: {
                format: 'MM/DD/YYYY'
            },
            minDate: moment(),
            autoUpdateInput: false,
            singleDatePicker: true,
            showDropdowns: true,
            maxDate: changed == 1 ? dateString : ''
        } :  {    
            locale: {
                format: 'MM/DD/YYYY'
            },
            minDate: moment(),
            autoUpdateInput: false,
            singleDatePicker: true,
            showDropdowns: true,   
        }
        jQuery('input[name="checkin"], #CheckInRange')
            .daterangepicker( calObj, function (start, end, label) {
                thisObj.setState({
                    checkin: start.format('MM/DD/YYYY'),
                })
                _this.checkOutCalendar(thisObj, 1, start.format('MM/DD/YYYY'))
            });
        if (changed == -1) {
            setTimeout(function () {
                _this.checkOutCalendar(thisObj, 1, dateString)
            }, 250)
        }
    },
    checkOutCalendar(thisObj, changed, dateString) {   
        const calObj = dateString!=''?  {    
            locale: {
                format: 'MM/DD/YYYY'
            },
            minDate: changed == 1 ? dateString : moment(),
            autoUpdateInput: false,
            singleDatePicker: true,
            showDropdowns: true,
        } : {    
            locale: {
                format: 'MM/DD/YYYY'
            },
            autoUpdateInput: false,
            minDate: moment(),
            singleDatePicker: true,
            showDropdowns: true,
        }
        const _this = this
        jQuery('input[name="checkout"], #CheckOutRange')
            .daterangepicker(calObj, function (start, end, label) {
                thisObj.setState({
                    checkout: start.format('MM/DD/YYYY'),
                })
                _this.checkInCalendar(thisObj, 1, start.format('MM/DD/YYYY'))
            });
        if (changed == -1) {
            setTimeout(function () {
                _this.checkInCalendar(thisObj, 1, dateString)
            }, 250)
        }
    }

}

export default travelCalendar