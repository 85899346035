import MainController from "../../controller/mainController";
import SubFooter from "../../common/subFooter";
import GuestHeader from '../../common/guestHeader';
import Footer from '../../common/footer';
import MediaFooter from "./common/mediaFooter";
import MetaTags from '../../common/metaTags';
import ServerMetaTags from '../../../server/metaTags';

/* MediaPressReleases Component initialization */
class MediaPressReleases extends React.Component {
    /* Initializing objects of its MediaPressReleases class */
    constructor(props) {
        super(props);
    }
    /* It is invoked immediately before mounting occurs */
    componentWillMount() {
        MainController.getInitialInformation();
    }
    /* It is invoked to return html content */
    render() {
        const metaData = ServerMetaTags.urlString.getMetaData(this.props.match.url);
        return (
            <div>
            <MetaTags 
             metaTitle = {metaData.title}
             metaDesription = {metaData.description}
             metaUrl = {metaData.canonical} />
             <GuestHeader/> 
            <div className="cover-tophd-ht company_ht_cover"></div>
             <div className="quote-section bg-white">
                <div className="container mediakit">
                   <div className="plaine-text global-coverage-wrap col-md-10 col-md-offset-1 wow fadeInUp" data-wow-delay="0.5s">
                   <h2 className="mrg-btm-50 color-red">PRESS RELEASES</h2>
                        <p>The following PDF documents have been distributed by SuiteAmerica for press purposes.</p>
                        <h5 className="text-left mrg-btm-0 font700"><strong>Press Releases:</strong></h5>
                        <ul className="pressrelease_list">
                            <li><a className="color-red" href="/pdf/PR-2018 In Their Shoes Award.pdf" target="_blank">SUITEAMERICA WINS 2018 IN THEIR SHOES AWARD</a> </li>
                            <li><a className="color-red" href="/pdf/PR-2018 Supplier of the Year.pdf" target="_blank">SUITEAMERICA NAMED 2018 SUPPLIER OF THE YEAR</a> </li>
                        </ul>    
                   </div>
                 <MediaFooter />
                 </div>
              </div>   
             <SubFooter />
             <Footer/>
            </div>
        );
    }
}
export default MediaPressReleases;