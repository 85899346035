import {Link} from 'react-router-dom';
const NavigationFooter = (props) => {
    const {navigationData} = props;
    return(
        <div className="tech_gallery">
          <div className="row">
           {navigationData.map((row, index) => {
               console.log(row,'ppp')
           return (
                    <div className="col-md-4 col-sm-4 tech_thumb" key={index}>
                        <Link to={row.navUrl}>
                        <img className="img-responsive" src={row.navImage} alt="" />
                        <div className="techcaption new-d">
                            <div className="techblur"></div>
                            <div className="techcaption-text">
                                <h1>{row.navName}</h1>
                            </div>
                        </div>
                        </Link>
                    </div>
                   )
              })
           }
        </div>
     </div>
    )
}
export default NavigationFooter;