const AmenitesList=(props)=>{
   
   
const {indexval, communityAmenities, appartmentAmenities,LinkMaster} = props;


return(
<div className="widget auto_amenities mrg-top-20">
<div className="widget_header ">
   <ul className="nav nav-tabs community_tabs ameniteslistUl" role="tablist">
      <li role="presentation" className="active">
      <a href={"#community"+indexval} aria-controls="home" role="tab" data-toggle="tab">Community</a>
      </li>
      <li role="presentation">
      <a href={"#appartments"+indexval} aria-controls="profile" role="tab" data-toggle="tab">Apartment</a>
      </li>
      <li role="presentation">
      <a href={"#master"+indexval} aria-controls="profile" role="tab" data-toggle="tab">More Info</a>
      </li>
   </ul>
</div>
<div className="widget_body">
   <div className="tab-content">
      <div role="tabpanel" className="tab-pane active" id={"community"+indexval}>
         {communityAmenities.length != 0? 
         <ul className="amenities_list">
            {communityAmenities.map((row, index) => {
            return  <li key={index}><img width="40" src={s3BucketUrl+'Amenities/'+(row.aparment_amenity_name).replace(/[^A-Z0-9]+/ig, "")+'.png'} /> {row.aparment_amenity_name}</li>
            })}
         </ul>
         : null}
      </div>
      <div role="tabpanel" className="tab-pane" id={"appartments"+indexval}>
         {appartmentAmenities.length > 0? 
         <ul className="amenities_list">
            {appartmentAmenities.map((row, index) => {
            return  <li key={index}><img width="40" src={s3BucketUrl+'Amenities/'+(row.aparment_amenity_name).replace(/[^A-Z0-9]+/ig, "")+'.png'} /> {row.aparment_amenity_name}</li>
            })}
         </ul>
         : null}
      </div>
      <div role="tabpanel" className="tab-pane" id={"master"+indexval}>
         
         <ul className="amenities_list">
          {LinkMaster.Floor != null?
          <li>Floor: {LinkMaster.Floor}</li>:
          null
          }
           {LinkMaster.SquareFootage != null?
          <li>Sq. Feet: {LinkMaster.SquareFootage}</li>:
          null
          }
          {LinkMaster.SuiteSizeCode != null?
          <li>Size: {LinkMaster.SuiteSizeCode}</li>:
          null
          }
          {LinkMaster.IsShowPriceOnlineQuote != null && LinkMaster.IsShowPriceOnlineQuote == true?
          <li>Offered Rate: ${LinkMaster.OfferedRate}/day</li>
          :
          null
          }
          
         </ul>
         
      </div>
   </div>
</div>
</div>
)
}
export default AmenitesList;