/* Importing the node modules, child components, services and controllers used
   inside H1BVisaLanding component */   
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   import Location from '../location/locations';
   import ContactUs from '../../common/contactUs';
   import SuccessModal from '../../common/successModal';

   /* H1BVisaLanding Component initialization */
   class H1BVisaLanding extends React.Component {
       /* Initializing objects of its H1BVisaLanding class */
       constructor(props) {
           super(props)
           this.state={
            companyName:'suiteamerica',
            contactDetails: {
                name: "H1B Visa Campaign",
                type: "h1bvisa"                
            },
            managerEmailId: ['ashok@suiteamerica.com', 'Laura@suiteamerica.com']
           }               
       }
       /* It is invoked immediately before mounting occurs */
       componentWillMount() {
           MainController.getInitialInformation();
       }
       /* It is invoked to return html content */
       render() {   
           return (
            <div className="corporateHousing_Updates">
                <GuestHeader/>
                <header className="codeconveyHeader cc_image_text_main corporate-h video-wrapper worldbnk_banner">
                <img className="img-responsive" src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/H1BVisa/landing.jpg" width="100%" alt=""  />
                <div className="wow fadeInUp img-on-text top45 width500 text-center left10 img-on-text-center company_bannertxt" data-wow-delay="0.5s">
                    <h1 className="color-red">Your employees qualify for an H1B Visa discount</h1>
                </div>
                </header>
                <div className="quote-section bg-white">
                <div className="container mediakit company-info">
                    <div className="plaine-text global-coverage-wrap wow fadeInUp" data-wow-delay="0.5s">
                        <p>Welcome!</p>
                        <p>SuiteAmerica is offering a discount for your international employees.
                            We provide customized housing solutions tailored to the unique needs of
                            your H1B Visa employees. Give us a call now or leave your name, email,
                            and phone number, and we'll give you access to this once a year discount after 
                            discussing your company's housing needs.
                        </p>
                        <p>
                            For almost 30 years, SuiteAmerica has been providing award-winning corporate housing 
                            and destination services to many of the world's largest companies. Our concierge and
                            personal transition teams ensure all guests have a seamless, stress-free stay in 
                            their temporary homes. Through innovative technology and 24/7 live support,
                            SuiteAmerica's unrivaled level of service creates the best, most efficient client 
                            and guest experience.
                        </p>
                        <p>
                            This offer begins now through October 31, 2018.
                        </p>
                    </div>
                </div>
                </div>
                <div className="container text-center" id="serviceRequest">
                <h3>Call Us Now</h3>
                <h2><img src={"/assets/newUI-images/nike/phone.svg"} width="25px"/>
                                {/* <span className="desktop-phone text-red">408.833.8950</span> */}
                                <a href="tel:4088338950" className="contact-us-mobile mobile-phone text-red">408.833.8950</a>
                        </h2>
                        <h3 className="mrg-top-0">OR</h3>
                        <h3>We'll Contact You</h3>
                    </div>
                <ContactUs contactDetails={this.state.contactDetails} managerEmailId={this.state.managerEmailId}/>
                
                <div className="container">
                <div className="person_options mrg-top-50 mrg-btm-50">
                    <div>
                        <img src="/assets/images/ROBE-Campaign/per_option_1.png" alt="" />
                        <h4>Innovation</h4>
                    </div>
                    <div>
                        <img src="/assets/images/ROBE-Campaign/per_option_2.png" alt="" />
                        <h4>integrity</h4>
                    </div>
                    {/* <div>
                        <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/ROBE-Campaign/per_option_3.png" alt="" />
                        <h4>employee owned</h4>
                    </div> */}
                    <div>
                        <img src="/assets/images/ROBE-Campaign/per_option_4.png" alt="" />
                        <h4>commitment</h4>
                    </div>
                    <div>
                        <img src="/assets/images/ROBE-Campaign/per_option_5.png" alt="" />
                        <h4>30 years experience</h4>
                    </div>
                </div>
                <h3 className="color-red">Begin Your location search</h3>
                </div>
                <Location companyName={this.state.companyName}  props={this.props}/>
                <SubFooter/>
                <Footer/>
                <SuccessModal/>
            </div>
           );
       }
   }
   export default H1BVisaLanding;
   