/* Importing the node modules, child components, services and controllers used 
   inside HousingSolutions component */
import MainController from "../controller/mainController";
import SubFooter from "./subFooter";
import GuestHeader from './guestHeader';
import Footer from './footer';
/* HousingSolutions Component initialization */
class HousingSolutions extends React.Component {
    /* Initializing objects of its HousingSolutions class */
    constructor(props) {
        super(props);
    }
    /* It is invoked immediately before mounting occurs */
    componentWillMount() {
        MainController.getInitialInformation();
    }
    /* It is invoked to return html content */
    render() {
        return (
            <div>
                <GuestHeader/>
                <div className="services_ch">
                    <div className="carousel banner_text">
                        <img className="img-responsive relocation-corporate-housing" src={s3BucketUrl_WebsiteImages+"SuiteAmerica-About-Us-Banner-Image.jpg"} alt="" />
                        <div className="img-on-text width500 left40 top40 width500 text-center img-on-text-center img-on-text-center-w wow fadeInUp"  data-wow-delay="0.5s">
                            <h3 className="animated font300">HOUSING SOLUTIONS</h3>
                        </div>
                    </div>
                </div>
                <div className="quote-section bg-white">
                    <div className="container mediakit company-info">
                        <div className="plaine-text global-coverage-wrap col-md-10 col-md-offset-1 wow fadeInUp" data-wow-delay="0.5s">
                            <p>SuiteAmerica provides personalized corporate relocation for many industries:</p>
                            <h5 className="text-left mrg-btm-0 font700"><strong>Corporate Housing</strong></h5>
                            <p>For almost 30 years, SuiteAmerica has been a trusted source for corporate housing solutions to business
                            travelers for temporary assignments and relocation. Whether you need interim relocation housing for your move
                            or simply a place to call home while on a business project or assignment. You can count on SuiteAmerica.
                            </p>
                            <p>Do you have a project manager flying in for a few months stay? We can have accommodations ready within
                            24 hours.
                            </p>
                            <p>Whether you're relocating one manager or one hundred employees on a special project, if you need to arrive
                            early or extend your stay, SuiteAmerica is ready to assist you. You can depend on SuiteAmerica to handle all of
                            your corporate housing needs. With just one phone call, we can arrange everything from furniture and
                            housewares to cable TV and telephone service. From answering machines to welcome baskets with snacks,
                            SuiteAmerica guests immediately feel right at home in their corporate apartment.
                            </p>
                            <p>If you would like something extra, just ask us. We provide the service and attention to detail that are important
                            to both our guest and their company. We can confirm the dates and directions to your new residence by fax or
                            email. When you arrive you will find a beautifully furnished home-away-from-home. With our free iPhone and
                            iPad app, you have all of your reservation information available at any time. You can view confirmation details,
                            including reservation dates, your suite address, important contact i and parking information. View maps and get
                            directions to your suite. Submit a service request or check the status of any submitted service request. You can
                            also go to "My Account" on the SuiteAmerica website for all of this and more.
                            </p>
                            <p>We own our own furnishings, accessories, housewares and linens. To maintain our high quality standards, the
                            cleaning and maintenance teams are SuiteAmerica staff members. Our in-house laundry and housewares
                            department, along with our extensive inventory of furnishings, means we can respond quickly to your requests,
                            and you’re always assured that products meet our highest quality standards.
                            </p>
                            <h5 className="text-left mrg-btm-0 font700"><strong>Government Contracting</strong></h5>
                            <p>SuiteAmerica has been providing government contractors unparalleled excellence in customer service for over
                            20 years. We help government contractors accomplish the job at hand because they need not worry about
                            the stress of finding accommodations. We provide temporary lodging solutions that are customized for
                            government travelers and we consistently deliver customer service and temporary living quarters that exceed
                            the traveler's expectations.
                            </p>
                            <p>SuiteAmerica has over 30 years’ experience serving global, cost-reimbursable Federal contractors.
                            SuiteAmerica delivers stress free, cost-effective temporary lodging, while setting the standard of excellence in
                            the industry with end-to-end procurement management and support.
                            </p>
                            <p>SuiteAmerica's furnished apartments are available through all major Global Distribution Systems as well as
                            GSA Schedule 48. SuiteAmerica provides housing solutions that assist government contractors in the field
                            with fully equipped and customized furnished apartments nationwide, conveniently located to hundreds of
                            city centers, government offices, and military bases. SuiteAmerica provides single-source, company-wide
                            procurements, which help your organization simplify and streamline its travel expenditures while increasing
                            profitability and attracting new government contracts.
                            </p>
                            <p>SuiteAmerica is dedicated to delivering award-winning customer service 24/7 through government
                            representatives and mobile applications. We deliver the service and flexibility that you depend on from
                            SuiteAmerica at cost-effective rates.
                            </p>
                            <h5 className="text-left mrg-btm-0 font700"><strong>Federal Civilian Housing</strong></h5>
                            <p>SuiteAmerica has over 30 years’ experience meeting the unique demands of Federal civilian travelers with
                            detailed oriented, stress-free travel procurement through GSA Schedule 48 and all major Global
                            Distribution Systems.
                            </p>
                            <p>SuiteAmerica is consistently chosen by the U.S. State Department for Federal contracts with a dedicated
                            government services team that understands the detailed and often complex needs of Federal travelers. Our
                            government team's knowledge allows agencies to better manage their complex logistics, while SuiteAmerica
                            handles the details of temporary assignments.
                            </p>
                            <p>Corporate housing is the logical choice for Federal travelers, because unlike hotels, SuiteAmerica furnished
                            apartments can be fully customized to exact agency standards and specifications. SuiteAmerica offers
                            detailed assistance with end-to-end procurement management and support because no two Federal
                            assignments are exactly alike. 
                            </p>
                            <p>Federal travelers can depend on SuiteAmerica quality without forgoing service for cost. SuiteAmerica is
                            dedicated to delivering award-winning customer service 24/7 through government representatives and
                            mobile applications. We deliver the service and flexibility that you depend on from SuiteAmerica at
                            cost-effective rates.
                            </p>
                            <h5 className="text-left mrg-btm-0 font700"><strong>Military Lodging</strong></h5>
                            <p>SuiteAmerica provides the Defense Department with full-service lodging that the military depends on;
                            strategically located near military installations nationwide. SuiteAmerica's accommodations for military
                            lodging in San Diego and the DC Metro Area are custom-fit to meet the requirements of the mission.
                            </p>
                            <p>SuiteAmerica's dependable, professional team consistently delivers award-winning excellence the military
                            traveler can depend on 24/7 through government representatives and mobile applications. We deliver the
                            service and flexibility that you depend on from SuiteAmerica at cost-effective rates. SuiteAmerica offers
                            end-to-end procurement management and support with large-scale DOD contract options for lower costs and
                            easier administration. 
                            </p>
                            <h5 className="text-left mrg-btm-0 font700"><strong>U.S. Navy Lodging</strong></h5>
                            <p>SuiteAmerica provides the Department of Navy with full-service lodging that the military depends on. We
                            offer accommodations at hundreds of communities with thousands of apartments nationwide that are
                            conveniently located near the Naval Base (including NNSY and PSNS located in San Diego). SuiteAmerica's
                            accommodations are custom-designed to ensure temporary military lodging is a world-class experience.
                            </p>
                            <p>SuiteAmerica delivers service and flexibility at cost-effective rates. As a GSA contract holder, SuiteAmerica
                            offers luxury amenities and award-winning customer service that fall within TDY and PCS per diems.
                            </p>
                            <h5 className="text-left mrg-btm-0 font700"><strong>Entertainment Industry Solutions</strong></h5>
                            <p>For over 20 years, SuiteAmerica has been a trusted source for temporary housing solutions for the
                            entertainment industry. We have custom tailored unique housing requests for traveling theatre groups, film
                            and production companies, and a host of other entertainment sectors. Our vast experience in securing single
                            or group accommodations, in specific and even hard-to-find locations, has helped make SuiteAmerica a top
                            choice of entertainment travelers, and one of the most recognized national providers. 
                            </p>
                            <p>SuiteAmerica is the location expert in providing fully appointed and accessorized, short-term furnished
                            apartments in thousands of cities nationwide. Our dedicated team of corporate housing specialists
                            understands the challenges and complexities of finding a temporary home environment for your cast and
                            crew. Whether you want to be close to a performance venue, or need housing while filming in a small-town
                            setting, you can trust SuiteAmerica for all the details to make your corporate housing stay exceptional.
                            </p>
                            <h5 className="text-left mrg-btm-0 font700"><strong>Corporate Housing Solutions for Travel Managers</strong></h5>
                            <p>Travel managers throughout corporate America count on SuiteAmerica as their temporary housing solution.
                            SuiteAmerica sets a standard of excellence in corporate housing which managers can depend on consistently.
                            Whether you need housing for a single employee, or would like to create a corporate housing program as part
                            of your overall lodging policy, we are here to help. SuiteAmerica is the location expert in providing fully
                            appointed and accessorized, short-term furnished apartments in thousands of cities, for a month or longer.
                            </p>
                            <p>With today's travel departments facing increasing pressure to manage costs effectively, SuiteAmerica helps
                            travel managers blend nationwide corporate apartment solutions into their company's overall lodging
                            program. In addition to immediate cost-savings for the company, your employees will appreciate (and prefer) a
                            spacious, fully furnished corporate apartment as an alternative to a lengthy hotel stay. 
                            </p>
                            <h5 className="text-left mrg-btm-0 font700"><strong>Education & Internship Programs</strong></h5>
                            <p>SuiteAmerica has revolutionized how quickly and efficiently providing housing for your interns can be. Our
                            streamlined process and single point of contact allows you to focus on your business and more pressing
                            matters. SuiteAmerica provides a consistent quality service across the board so that every intern enjoys the
                            same experience, creating a strong bond and loyalty between interns and your company. By providing a
                            quality housing program, families of interns can be confident in sending their loved ones to a new place.
                            </p>
                            <p>SuiteAmerica is experienced in interns, so rest assured, if there is any question or issue, we know how to solve
                            it. As an award winning corporate housing company, we offer fully furnished apartments with exceptional
                            services; creating satisfied interns and ultimately producing better work performances. Less expensive per day
                            than a hotel or Airbnb and cost effective by having all interns in one location. Rates are based on minimum 30
                            day commitment and are subject to availability. Must have minimum number of interns to qualify
                            </p>
                            <h5 className="text-left mrg-btm-0 font700"><strong>Unfurnished Apartments</strong></h5>
                            <p>SuiteAmerica now offers short term leases on unfurnished apartments in many of our premium communities.
                            Whether you're looking for a temporary home while getting acquainted with a new area, or needing a place to
                            stay while you're in between permanent moves, our short-term unfurnished options are a perfect solution. Call
                            us anytime to learn more and let our award-winning team help you find the perfect fit.
                            </p>
                        </div>
                    </div>
                </div>
                <SubFooter />
                <Footer/>
            </div>
        );
    }
}
export default HousingSolutions;