/* Importing the node modules, child components, services and controllers used
   inside LandingScreen component */   
   import {Link} from 'react-router-dom';
   import TravelBookingHeader from '../../common/travelBookingHeader';
   import OurCommunties from './ourCommunities'
   import TravelModel from './model/travelModel';  

   /* LandingScreen Component initialization */
   class LandingScreen extends React.Component {
       /* Initializing objects of its LandingScreen class */
       constructor(props) {
           super(props)
           const checkIn = localStorage.getItem('checkIn') != null
               ? localStorage.getItem('checkIn')
               : '';
           const checkOut = localStorage.getItem('checkOut') != null
               ? localStorage.getItem('checkOut')
               : '';
           const searchedLocation = (localStorage.getItem('searchedLocation') != null && localStorage.getItem('searchedLocation') != undefined)
               ? localStorage.getItem('searchedLocation')
               : '1 Apple Park Way, Cupertino, CA, USA';
           this.state = {
               searchLocation: searchedLocation,
               checkin: checkIn,
               checkout: checkOut,
               communities: [],
               errorMessage: '',
               amenities: false,
               isSubmitted: false,
               loading: false,
               more: false,
               travelerObJect: [],
               loadingStatus: false,
               program: [],
               record_limit: 0,
               reqDays: 0,
               radius: 0,
               addressLat: 37.3330288,
               addressLng: -122.0112232,
               noOfDays: 0,
               defaultCommunitiesError: false,
               bedSizesList: [{'name':'All','value':''},{'name':'STU','value':'STU'},{'name':'1X','value':'1X'},{'name':'2X','value':'2X'},{'name':'3X','value':'3X'}],
               bedsize:localStorage.getItem('bedSize') != null ? localStorage.getItem('bedSize')
               : '',
            }
           this.TravelModel = new TravelModel(this, {})
           this.setLocation = this.setLocation.bind(this);
           this.setValue = this.setValue.bind(this);
           this.amenities = this.amenities.bind(this);
   
       }
       /* It is invoked immediately before mounting occurs */
       async componentDidMount() {
        this.TravelModel.DidMount(this.props)
       }
       
   
       async componentWillMount() {
        this.TravelModel.WillMount(this.props)
       }
      
 
       /* To get the Location */
       setLocation() {
        this.TravelModel.setLocation()
       }
       amenities() {
           this.setState({
               amenities: !this.state.amenities
           });
           // $('.new_slide_content').css('width', 'auto');
       }
       setValue(field, e) {
        var object = {};
        object[field] = e.target.value;
        this.setState(object);
      }
       /* It is invoked to return html content */
       render() {
        const userLoginEmailEncode=localStorage.getItem('travel-email');
        const userLoginEmail=(userLoginEmailEncode !=undefined || userLoginEmailEncode !=null )? 'admin':'guest'

           const email = localStorage.getItem('travel-email')
           const calendarDisable = ((email == null || email == undefined) && (this.props.match.params.pnr != "" || this.props.match.params.pnr != undefined))? true : false
           return (
               <div>
                   <TravelBookingHeader/>
                   <div className="travel_header-ht pad-top-60 d-block"></div>
                   <div className="fullslider " >
                       <div className="tb-slide-content mr-t-40">
                           <h1>Apple’s Preferred Travel Partner</h1><br/><br/>
                           <h1>Simple way to book your
                               <span className="pad-left-10">Hotel</span>
                               <span>Suite</span>
                           </h1>
                           <p>Suites for your
                               <span> 2 to 30 </span>
                               day stays and longer ...</p>
                           
                           {this.state.errorMessage!=""? <p style={{
                               color: 'red'
                           }}>{this.state.errorMessage}</p> : ""}
                           <div className="tb-slide-form">
                               <form className="form-inline">
                                   <div className="tb-slide-item">
                                       <input
                                           id="travel-booking"
                                           onChange={this.setLocation}
                                           type="text"
                                           className="form-control"
                                           placeholder="City, Address, or Landmark"/>
                                       <div className="info-field-icon">
                                           <Link to="#">
                                               <i className="fa fa-map-marker"></i>
                                           </Link>
                                       </div>
                                   </div>
                                   <div className="tb-slide-item">
                                       <input
                                                       type="text"
                                                       className="form-control"
                                                       name='checkin'
                                                       value={this.state.checkin}
                                                       placeholder="Check In"
                                                       disabled={calendarDisable}
                                                       autoComplete="off"
                                                       onChange={()=>{this.TravelModel.checkInCal()}}/>
                                               
                                       <div className="info-field-icon">
                                           <Link to="#">
                                               <i className="fa fa-calendar" id={calendarDisable? "" : "CheckInRange"} ></i>
                                           </Link>
                                       </div>
                                   </div>
                                   <div className="tb-slide-item">
                                       <input
                                                       type="text"
                                                       className="form-control"
                                                       name='checkout'      
                                                       value={this.state.checkout}              
                                                       placeholder="Check Out"
                                                       disabled={calendarDisable}
                                                       autoComplete="off"
                                                       onChange={()=>{this.TravelModel.checkOutCal()}}/>
                                              
                                       <div className="info-field-icon">
                                           <Link to="#">
                                               <i className="fa fa-calendar" id={calendarDisable? "" : "CheckOutRange"}></i>
                                           </Link>
                                       </div>
                                       
                                       
                                       
                                   </div>

                                   {userLoginEmail=='admin' ?<div className="tb-slide-item">
                                        
                                        <select className="form-control" id="sel1" value={this.state.bedsize} 
                                        onChange={(e) => this.setValue('bedsize', e)}>
                                            {this.state.bedSizesList.map((row,index)=>{
                                                return( <option value={row.value} key={index}>{row.name}</option>)
                                            })}
                                            
                                        
                                            </select>
                                            
                                    </div>:''}
                                   
                                           <button
                                               style={{width: '125px'}}
                                               type="button"
                                               onClick={(calendarDisable || this.state.loadingStatus)? null : ()=> {this.TravelModel.getCommunities()}}
                                               className="btn btn-default">{this.state.loadingStatus?<span><i className="fa fa-spinner fa-spin" style={{fontSize: '25px'}}/></span>  : "Search" }</button>
                                </form>
                                
                               <div className="text-center new_slide_wrap">
                              
                                   <button
                                       className="btn btn-primary new_slide_btn"
                                       onClick={this.amenities}>Amenities</button>
                                   <div
                                       className={this.state.amenities
                                       ? 'new_slide_content hid'
                                       : 'new_slide_content'}>
                                       <div id="" className="carousel slide">
                                           <div className="carousel-inner" role="listbox">
                                               <div className="item active">
                                                   <p>Washer / Dryer
                                                       <span>Fitness Center</span>
                                                       Storage (bike)</p>
                                               </div>
   
                                           </div>
   
                                       </div>
                                   </div>
                               </div>
   
                           </div>
                           {this.state.defaultCommunitiesError?'' : <span><br/>
                           <br/></span> }
                           <div className="col-md-2"></div>
                           <div
                               className="col-md-8"
                               style={{
                               lineHeight: 1.6
                           }}>
                               <p>Less is more…. SuiteAmerica’s luxurious apartment suites offer all the comforts of home at rates lower than hotels. Spacious and fully equipped with onsite amenities such as pools, fitness centers, in-suite laundry and so much more. Who knew saving money for Apple could be so good for you! </p>
                               
                           </div>
                           <div className="col-md-2"></div>
                       </div>
                   </div>
                   {this.state.communities.length>0
                       ? <div id="communityScroll"><OurCommunties
                               program={this.state.program}
                               record_limit={this.state.record_limit}
                               reqDays={this.state.reqDays}
                               latitude={this.state.addressLat}
                               longitude={this.state.addressLng}
                               radius={this.state.radius}
                               more={this.state.more}
                               propsData={this.props}
                               loading={this.state.loading}
                               communities={this.state.communities}
                               travelerName={this.state.traveler}
                               travelerEmail={this.state.travelerEmail}
                               travelerObJect={this.state.travelerObJect}/></div>
                       :  ""
                           }
                <ErrorModel user={userLoginEmail}/>
               </div>
           );
       }
   }
   export default LandingScreen;
   
   const ErrorModel = props => {

    return (<div className="modal fade savesearch" id="successTravelModal" role="dialog">
    <div className="modal-dialog">
        <div className="modal-content rm-border-radius">
            <div className="modal-header">
            <button type="button" className="close btn" data-dismiss="modal" aria-label="">
            <i className="bi bi-x"></i>
            </button>
            </div>
            <div className="modal-body" style={{width: '100%'}}>
            {props.user!='admin'?<h1>Hi!</h1>:<h2>Thank you for your request.</h2>}
            
            <h3 style={{color: 'red'}}>For additional availability, please contact <br/>{props.user!='admin'?'ExtendedStay@Apple.com':'AppleTravelers@suiteamerica.com'}</h3>
            </div>
        </div>
    </div>
</div>
                                

    );
  };
