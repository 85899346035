/* Importing the node modules, child components, services and controllers used 
   inside IntranetOrderProcess component */
import $ from "jquery";
import AppController from "../../../controller/appController";
import EmployeeHeader from '../../../common/employeeHeader';
import EmployeeFooter from '../../../common/employeeFooter';
import {Link} from 'react-router-dom';
import GuestBooks from './guestBooks.json';
/* IntranetOrderProcess Component initialization */
class IntranetOrderProcess extends React.Component{
  /* Initializing objects of its IntranetOrderProcess class */
  constructor(props) {
    super(props);
    this.state ={
      guestBooks: GuestBooks
    }
    this.menuNavigation = this.menuNavigation.bind(this);
	  }
  /* It is invoked immediately after a component is mounted */  
  componentDidMount() {
        $(document).ready(function () {
              $('html, body').animate({scrollTop: 0});
              AppController.stickyTitles();
					$(".expand").on( "click", function() {
						var expand = $(this).find(">:first-child");
						if(expand.text() == "+") {
							expand.text("-");
						} else {
							expand.text("+");
						}
					  });
			})
    }
  /* To move to the top of the page */
    menuNavigation(idName) {		
      $('html, body').animate({
          scrollTop: $('#' + idName).offset().top - 150
      },800);
    }
/* It is invoked to return html content */
render(){
    return(
        <div>
          <EmployeeHeader/>
            <div className="intra_staff">
  <div className="carousel banner_text"> <img className="img-responsive" src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Intranet-Matrial-Banner.jpg"} width="100%" alt="" />
    <div className="carousel-caption slide-text slide_text_third">
      <div className="slide_style_left">
        <h1  className="toggleHeading wow fadeInDown animated">MATERIALS</h1>
      </div>
    </div>
  </div>
</div>
<div className="services_heading material_menu_div intra_staff intra_order" >
  <div className="container">
    <div className="row">
      <div className="col-md-12 col-sm-12 col-xs-12">
        

<nav className="navbar">
    <div className="navbar-header">
      <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#material_menu" aria-expanded="false">
        <span className="sr-only">Toggle navigation</span>
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
      </button>
      <p className="mrg-btm-0">MATERIALS</p>
    </div>

    <div className="collapse navbar-collapse" id="material_menu">
    <ul className="nav navbar-nav navbar-right">
        <li><a onClick={() => this.menuNavigation('materials-guest-books')}>GUEST BOOKS</a></li>
        <li><span><Link to="#">REQUEST <i className="fa fa-caret-right"></i></Link></span></li>
    </ul>
    </div>
</nav>


      </div>
    </div>
  </div>
</div>
<div className="intra_staff2">
  <div className="container">
    <div className="panel-group" id="accordion">
      <div className="panel panel-default" id='materials-guest-books'>
        <div className="panel-heading">
          <h4 data-toggle="collapse" data-parent="#accordion" href="#collapse1" className="panel-title expand">
            <div className="right-arrow pull-right">+</div>
          </h4>
          <p>GUEST BOOKS</p>
        </div>
        <div className="panel-body">
          <div className="row">
          {this.state.guestBooks.slice(0, 4).map((row, index) => {
            return <a href={row.guestBookUrl} target="_blank" key={index}>
            <div className="col-md-3">
              <div className="second_box intra_order staff_box">
                <h3>{row.guestBookCity}</h3>
                <img className="img-responsive" src={row.guestBookImage} alt="" />
              </div>
            </div>
            </a>
          })}
          </div>
        </div>
        <div id="collapse1" className="panel-collapse collapse">
          <div className="panel-body">
            <div className="row">
            {this.state.guestBooks.slice(4, this.state.guestBooks.length).map((row, index) => {
              return <a href={row.guestBookUrl} target="_blank" key={index}>
                    <div className="col-md-3">
                      <div className="second_box intra_order staff_box">
                        <h3>{row.guestBookCity}</h3>
                        <img className="img-responsive" src={row.guestBookImage} alt="" />
                      </div>
                    </div>
                  </a>
            })}
            </div>
          </div>
        </div>
      </div>		
    </div>
  </div>
</div>
<EmployeeFooter/>
               </div>
    );
}
}
export default IntranetOrderProcess;
