import MainController from "../../controller/mainController";
import {Link} from 'react-router-dom';
import GuestHeader from '../../common/guestHeader';
import SubFooter from "../../common/subFooter";
import Footer from '../../common/footer'
class cspForm extends React.Component {
    /* Initializing objects of its PTSImpression class */
    constructor(props) {
        super(props)
        this.state = {
            communityNameOne: 'AVALON PARK CREST',
            communityNameTwo: 'RESERVE AT TYSONS CORNER',
            communityNameThree:'THE MARK AT DULLES STATION'
        }
        this.moreInfo = this.moreInfo.bind(this);
    }
    componentWillMount() {
        MainController.getInitialInformation();
    }
    async moreInfo(communityName) {
        var data = {}
        data['community_name'] = communityName
        localStorage.setItem('moreData', JSON.stringify(data));
    }
    render() {
        return (
            <div className="corporateHousing_Updates corporate_student_program">
                <GuestHeader/>
                <header className="codeconveyHeader cc_image_text_main corporate-h">
                    <img
                        className="img-responsive"
                        src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Company-Nike-Internship-Banner.jpg"}
                        width="100%"
                        alt=""/>
                    <div
                        className="wow fadeInUp img-on-text top30 width700 text-center img-on-text-center-w company_bannertxt"
                        data-wow-delay="0.5s">

                        <h1>Corporate Student
                            <br/>
                            Program 2019</h1>
                        <p>SuiteAmerica is pleased to arrange your shared housing accommodations.
                        </p>

                        <Link to='/corporate-student-form'>
                            <button type="button" className="btn btn-red mrg-top-60 mrg-btm-60">
                                MAKE YOUR RESERVATION</button>
                        </Link>
                    </div>
                </header>
                <div className="quote-section bg-white">
                    <div className="container">
                        <div
                            className="text-center col-md-12  wow fadeInUp company_p"
                            data-wow-delay="0.5s">

                            <h2>Important Area Information</h2>

                            <div className="col-md-10 col-md-offset-1 boxed_list important_area_info">

                                <ul>
                                    <li>
                                        <a href="https://s3-us-west-1.amazonaws.com/suiteam-website/PDF/CSP/Intern-Package.pdf" target="_blank">INTERN PACKAGE</a>
                                    </li>
                                    <li>
                                        <a href="https://online.fliphtml5.com/ngge/kfgl/" target="_blank">TYSONS AREA INFORMATION</a>
                                    </li>
                                    <li>
                                        <a href="https://www.wmata.com/" target='_blank'>VA METRO TRANSIT</a>
                                    </li>
                                    <li>
                                        <a href="https://www.vre.org/service/fares/" target='_blank'>VRE-COMMUTER RAIL SYSTEM</a>
                                    </li>
                                    <li>
                                        <a href="https://www.lyft.com/" target='_blank'>LYFT</a>
                                    </li>
                                    <li>
                                        <a href="https://www.uber.com/?exp=hp-c" target='_blank'>Uber</a>
                                    </li>

                                </ul>
                            </div>

                        </div>

                    </div>
                    <hr className="hr"/>
                    <div className="container-fluid nike company_section pad-top-0">
                        <div className="container text-center">

                            <div className="who_weare_text service_ch_new company_p">
                                <h2>Community Information</h2>
                                <h4 className="text-red mrg-btm-30">PLEASE SELECT ONE OF THE FOLLOWING ACCOMMODATIONS</h4>
                                <div className="row">
                                    <div className="col-md-4 col-sm-4">
                                        <div
                                            className="dual_quote list_block dual_quote_md wow fadeInUp"
                                            data-wow-delay="0.5s">
                                            <h4
                                                style={{color: '#000000',fontWeight:"700"}}>AVALAN PARK CREST</h4>
                                            <h4>8250 Westpark Drive</h4>
                                            <h4>Tysons, VA 22102</h4>
                                            <Link
                                            target="_blank"
                                                to={'/community/suiteamerica/' + this
                                                .state
                                                .communityNameOne
                                                .replace(/ /g, "-")}>
                                                <h5
                                                    style={{
                                                    color: '#7DC1EE'
                                                }}
                                                    onClick={() => this.moreInfo(this.state.communityNameOne)}>View more community info here.</h5>
                                            </Link>
                                            <h4>Two Bedroom Apartment</h4>
                                            <h4
                                                style={{
                                                marginTop: '20px'
                                            }}>
                                                <b>$43.00 per person/night</b>
                                            </h4>
                                            <h4>(sleeps up to 4 people Interns)</h4>
                                            Vehicle Parking  <b>$100.00 per month/parking space</b>
                                            <p><Link target="_blank" to='/corporate-student-form'>
                                                <button type="button" className="btn btn-red mrg-top-30 mrg-btm-30">
                                                    MAKE YOUR RESERVATION</button>
                                            </Link></p>
                                            (Parking is based on a first come first serve basis)
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-4">
                                        <div
                                            className="dual_quote list_block dual_quote_md  wow fadeInUp"
                                            data-wow-delay="0.5s">
                                            <h4
                                                style={{color: '#000000',fontWeight:"700"}}>RESERVE AT TYSONS</h4>
                                            <h4>8060 Crianza Place</h4>
                                            <h4>Tysons, VA 22182</h4>
                                            <Link
                                            target="_blank"
                                                to={'/community/suiteamerica/' + this
                                                .state
                                                .communityNameTwo
                                                .replace(/ /g, "-")}>
                                                <h5
                                                    style={{
                                                    color: '#7DC1EE'
                                                }}
                                                    onClick={() => this.moreInfo(this.state.communityNameTwo)}>View more community info here.</h5>
                                            </Link>
                                            <h4>Two Bedroom Apartment</h4>
                                            <h4
                                                style={{
                                                marginTop: '20px'
                                            }}>
                                                <b>$43.00 per person/night</b>
                                            </h4>
                                            <h4>(sleeps up to 4 people Interns)</h4>
                                            Vehicle Parking  <b>$100.00 per month/parking space</b>
                                            <p><Link target="_blank" to='/corporate-student-form'>
                                                <button type="button" className="btn btn-red mrg-top-30 mrg-btm-30">
                                                    MAKE YOUR RESERVATION</button>
                                            </Link></p>
                                            (Parking is based on a first come first serve basis)

                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-4">
                                        <div
                                            className="dual_quote list_block dual_quote_md wow fadeInUp"
                                            data-wow-delay="0.5s">
                                            <h4
                                               style={{color: '#000000',fontWeight:"700"}}>THE MARK AT DULLES STATION</h4>
                                            <h4>2323 Dulles station Boulevard</h4>
                                            <h4>Herndon, VA 20171</h4>
                                            <Link
                                            target="_blank"
                                                to={'/community/suiteamerica/' + this
                                                .state
                                                .communityNameThree
                                                .replace(/ /g, "-")}>
                                                <h5
                                                    style={{
                                                    color: '#7DC1EE'
                                                }}
                                                    onClick={() => this.moreInfo(this.state.communityNameThree)}>View more community info here.</h5>
                                            </Link>
                                            <h4>Two Bedroom Apartment</h4>
                                            <h4
                                                style={{
                                                marginTop: '20px'
                                            }}>
                                                <b>$39.00 per person/night</b>
                                            </h4>
                                            <h4>(sleeps up to 4 people Interns)</h4>
                                            Vehicle Parking  <b>$100.00 per month/parking space</b>
                                            <p><Link target="_blank" to='/corporate-student-form'>
                                                <button type="button" className="btn btn-red mrg-top-30 mrg-btm-30">
                                                    MAKE YOUR RESERVATION</button>
                                            </Link></p>
                                            (Parking is based on a first come first serve basis)
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <hr className="hr"/>
                        <h2>Welcome Reception & Orientation</h2>
                        <div className="container">
                            <div className="col-sm-8 col-sm-offset-2">
                                <img
                                    className="img-responsive"
                                    src={s3BucketUrl_WebsiteImages+"SuiteAmerica-About-Us-Our-Goal.jpg"}
                                    alt=""/>
                                <div className="text-center mrg-top-30">
                                    <p >Staying at a Suite America apartment with rest of your counterparts enables you to meet others quickly, form friendships, share rides to work, and reduce your overall expenses during your internship period. </p>
                                    <p >Customized and included specifically for Corporate Student Program 2019,
                                        SuiteAmerica is happy to meet you and go over any basic home operations, take
                                        the time to explain the mechanics of your new corporate rental and anything else
                                        you may need.</p>
                               
                                </div>
                            </div>
                        </div>

                        <hr className="hr"/>
                        <h2>Additional information</h2>
                        <div className="container">
                            <div className="col-sm-8 col-sm-offset-2">
                                <div className="text-left mrg-top-10">
                                    
                                <ul className="csp">
                            <li> No more than 4 students per two bedroom apartment is allowed</li>
                            <li> Pricing per person is based on quadruple occupancy per 2 bedroom apartment</li>
                           <li>Students are responsible for the entire duration of the lease (May 17, 2019 – August 17, 2019) regardless of when they arrive after May 17th</li>
                           <li> Student may arrive prior to May 17th but will be responsible for the nightly rate divided by the number of occupants who have arrived early</li>
                           <li> Students may extend their departure date beyond August 17th but will be responsible for the nightly rate divided by the number of occupants remaining in the apartment</li>
                           <li> Reservations will be taken on a first come, first served basis, and placed on a waiting list due to limited availability</li>
                           <li> A reservation is not a guarantee of housing</li>
                           <li>Students will be sent leasing terms and contracts once 4 students have been placed for housing</li>
                           <li> Tysons and Herndon Locations are requests only – Students may be asked to move to another CSP Apartment during their lease term to maintain the minimal nightly cost per student</li>
                            </ul>



                                    <hr className="hr"/>

                                    <h4 className="text-center">HAVE ANY QUESTIONS?</h4>
                                    <h4 className="text-center ">CALL US TODAY!</h4>

                                    <div className="text-center">
                                        <span className="helpitem help-icon">
                                            <img
                                                src={"/assets/newUI-images/nike/phone.svg"}/>
                                        </span>
                                        <span className="helpitem">
                                            <span className="help-text">
                                                <strong>+1 800.367.9501</strong>
                                            </span>
                                        </span>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    
                </div>
                <SubFooter/>
                    <Footer/>
            </div>
        )
    }
}

export default cspForm