const StatusInformation=(props)=>{
    const {servicesData, guestFirstName} = props;
    return(
        <div className="col-md-12">
              <table className="table table-striped table-bordered table-hover text-center ">
                <thead>
                    <tr className= "view">
                        <td>Service Date </td>
                        <td>Request</td>
                        <td>Status</td>
                        <td>Description</td>
                    </tr>
                </thead>
                <tbody >
                {servicesData.map((row, index) => {
                    return(
                            
                                 <tr className= "view" key={index}>
                                    <td className="text-center">{moment(row.RequestDate).format('L')}</td>
                                    <td className="text-center">{guestFirstName !=null ? guestFirstName:'---'}</td>
                                    <td className="text-center">{row.FivestarStatus !=null ? row.FivestarStatus:'---'}</td>
                                    <td className="text-center">{row.Description !=null ? row.Description:'---'}</td>
                                </tr>
                        
                        )})}
                        </tbody>
            </table>
        </div>
    )
    }

export default StatusInformation;