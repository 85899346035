/* Importing the node modules, child components, services and controllers used 
   inside WorldBankTransportation component */   
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   import WorldBankHeader from "../../common/worldBankHeader";
   
   /* WorldBankTransportation Component initialization */
   class WorldBankTransportation extends React.Component {
       /* Initializing objects of its WorldBankTransportation class */
          constructor(props) {
           super(props);  
          }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
           MainController.getInitialInformation();
         }
       /* It is invoked to return html content */
       render() {   
           return (
            <div className="corporateHousing_Updates">
                <GuestHeader/>
                <WorldBankHeader/>
                <header className="codeconveyHeader cc_image_text_main corporate-h video-wrapper worldbnk_banner">
                    <img className="img-responsive" src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/world-bank/Transportation-banner.jpg" width="100%" alt=""  />
                    <div className="wow fadeInUp img-on-text top40 width500 text-center left30 img-on-text-center company_bannertxt" data-wow-delay="0.5s">
                        <h1>Transportation Information</h1>
                    </div>
                </header>
                <div className="quote-section bg-white">
                    <div className="container">
                        <div className="text-center col-md-12 wow fadeInUp" data-wow-delay="0.5s">
                            <h2>Public Transportation</h2>
                        </div>
                        <div className="service_ch_new mrg-btm-60 public_trans_bnk">
                                
                                    <div className="col-md-4 col-sm-4">
                                        <div className="wow fadeInUp company_p dual_quote" data-wow-delay="0.5s">
                                            <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/world-bank/Metro.png" />
                                            <p className="font-size-12">The Washington Metropolitan Area Transit Authority (Metro) operates a balanced regional transportation system in the national capital area. Metrorail serves 86 stations and has 106 miles of track. Metrobus serves the nation’s capital 24 hours a day, seven days a week with 1,500 bus-es. Metrorail and Metrobus serve the population of 3.4 million within a 1,500-square mile jurisdiction. Metro began its paratransit service, MetroAccess, in 1994; it provides about 1.5 million trips per year.</p>
                                            <a href="https://www.wmata.com/" target="_blank" className="btn btn-red btn-block">Metro Transit</a>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-4">
                                        <div className="wow fadeInUp company_p dual_quote" data-wow-delay="0.5s">
                                            <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/world-bank/VRE.png" />
                                            <p className="font-size-12">A regional commuter rail system that connects Northern Virginia suburbs with Washington DC’s train thoroughfare in Union Station. VRE operates two lines: the Fredericks-burg Line originating 54 miles south in Fredericksburg, Virginia and the Manassas Line beginning 43 miles east in Bristow, Virginia. VRE Mobile lets travelers buy tickets from their phones including Single-Ride, Ten-Ride, Day Pass, Five-Day, Monthly, and Amtrak Step-Up Passes.</p>
                                            <a href="https://www.vre.org/service/fares/" target="_blank" className="btn btn-red btn-block">Vre</a>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-4">
                                        <div className="wow fadeInUp company_p dual_quote" data-wow-delay="0.5s">
                                            <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/world-bank/MARC.png" />
                                            <p className="font-size-12">The definition of a commuter train, MARC Train Service is a rail system whose Brunswick Line connects Washington¹s Union Station and Martins-burg, West Virginia with a branch line that reaches Frederick, Maryland. MARC’s Penn Line connects Union Station and Perryville, Maryland and is open on weekends. MARC’s lines operate primarily on weekdays. The Cam-den Line connects Union Station to Camden Station in Baltimore, Mary-land. Tickets can be purchased from Quik-Trak machines, Amtrak Ticket Agents, Commuter Direct Ticket Agents, as well as online.</p>
                                            <a href="https://mta.maryland.gov/marc-train" target="_blank" className="btn btn-red btn-block">Marc</a>
                                        </div>
                                    </div>
                            <h2 className="text-center col-md-12 wow fadeInUp" data-wow-delay="0.5s">Ride Share Programs</h2>
                                    <div className="col-md-4 col-sm-4 col-md-offset-2">
                                        <div className="wow fadeInUp company_p dual_quote" data-wow-delay="0.5s">
                                            <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/world-bank/Lyft.png" />
                                            <p className="font-size-12">Lyft is a rideshare program available in many cities. Lyft conducts background, criminal and DMV checks on all drivers. The Lyft app allows you to see a photo of the driver and car before arrival. Lyft cars have a pink mustache which makes them hard to miss.</p>
                                            <a href="https://www.lyft.com/" target="_blank" className="btn btn-red btn-block">Lyft</a>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-4">
                                        <div className="wow fadeInUp company_p dual_quote" data-wow-delay="0.5s">
                                            <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/world-bank/Uber.png" />
                                            <p className="font-size-12">Uber is a global transportation network. They conduct background checks and hire drivers who use their own car for transportation. The Uber App is available for instant ride access. When downloading the app, you can choose to register with either a local or foreign bank card.</p>
                                            <a href="https://www.uber.com/?exp=hp-c" target="_blank" className="btn btn-red btn-block">Uber</a>
                                        </div>
                                    </div>                         
                            </div>
                        </div>
                     </div>   
                <SubFooter/>
                <Footer/>
            </div>
           );
       }
   }
   export default WorldBankTransportation;