import React, { useEffect, useState } from "react";
import GuestHeader from "../../common/guestHeader";
import BlogHeader from "../newinterface/blogHeader";
import Footer from "../newinterface/footer";
import DatePicker from "react-datepicker";
import moment from "moment";
import nikeService from "../../../services/nikeService";

function Brokers() {
  const [state, setState] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    selectedBedRooms: "",
    location: "",
    budget: "",
    moveInDate: null,
    moveInDateSelected: "",
    loader: false,
    successMessage: false,
    latitude: "",
    longitude: "",
  });

  useEffect(() => {
    $(document).ready(function () {
      jQuery(function ($) {
        $("#phoneNumber").intlTelInput();
      });
    });
  }, []);
  const inputHandler = (value, type) => {
    // console.log(value, type, "value,type");

    if (value && value.trim() !== "") {
      $(`#${type}`).removeClass("validation");
    } else {
      $(`#${type}`).addClass("validation");
    }

    if (type === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidEmail = emailRegex.test(value);
      if (!isValidEmail) {
        $(`#${type}`).addClass("validation");
      } else {
        $(`#${type}`).removeClass("validation");
      }
    } else if (type === "phoneNumber") {
      var x = value.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      value = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + ((x[3] && "-" + x[3]) || "");
      setState((prev) => ({ ...prev, [type]: value }));
    }

    setState((prev) => ({ ...prev, [type]: value }));
  };
  const handleKeyPress = (e) => {
    const key = e.key;
    const regex = /[0-9.]/;

    if (e.keyCode === 8 || regex.test(key)) {
      return;
    }
    e.preventDefault();
  };

  const setLocation = async (e) => {
    e.persist();
    const locationValue = e.target.value;

    if (locationValue && locationValue.trim() !== "") {
      $("#location").removeClass("validation");
    } else {
      $("#location").addClass("validation");
    }

    setState((prev) => ({ ...prev, location: locationValue }));
    var places = new google.maps.places.Autocomplete(
      document.getElementById("location")
    );
    google.maps.event.addListener(places, "place_changed", function () {
      var place = places.getPlace();
      var placeDetails = {
        locality: "long_name",
        administrative_area_level_1: "short_name",
        country: "long_name",
      };
      place.address_components.forEach((i) => {
        var addressType = i.types[0];
        if (placeDetails[addressType]) {
          var val = i[placeDetails[addressType]];
          placeDetails[addressType] = val;
        }
      });
      let relocatedAddress = place.formatted_address;
      setState((prev) => ({
        ...prev,
        location: relocatedAddress,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
      }));
    });
  };

  const handleMoveIn = async (date) => {
    if (date) {
      $("#moveInDate").removeClass("validation");
    } else {
      $("#moveInDate").addClass("validation");
    }
    setState((prev) => ({
      ...prev,
      moveInDate: moment(date),
      moveInDateSelected: date.format("YYYY-MM-DD HH:mm:ss"),
    }));
  };
  const handleSubmit = async () => {
    setState((prev) => ({ ...prev, loader: true }));
    console.log(typeof state.moveInDate, "====");

    const {
      name,
      email,
      phoneNumber,
      selectedBedRooms,
      location,
      budget,
      moveInDateSelected,
      moveInDate,
      latitude,
      longitude,
    } = state;

    // Define an array of required fields
    const requiredFields = {
      name,
      email,
      phoneNumber,
      selectedBedRooms,
      location,
      budget,
    };
    let error = 0;
    // Iterate over fields and apply validation
    Object.entries(requiredFields).forEach(([key, value]) => {
      if (value && value.trim() !== "") {
        $(`#${key}`).removeClass("validation");
      } else {
        $(`#${key}`).addClass("validation");
        error++;
        setState((prev) => ({ ...prev, loader: false }));
      }
    });
    if (!moveInDate || Object.keys(moveInDate).length === 0) {
      $("#moveInDate").addClass("validation");
      error++;
    } else {
      $("#moveInDate").removeClass("validation");
    }
    // Handle latitude and longitude separately for location validation
    if (!latitude || !longitude || latitude === "" || longitude === "") {
      $("#location").addClass("validation");
      error++;
      setState((prev) => ({ ...prev, loader: false }));
    } else {
      $("#location").removeClass("validation");
    }

    try {
      // Get country code once
      const countryCodeElement = $(".country.highlight.active");
      const countryCode = countryCodeElement.length
        ? `+${countryCodeElement.attr("data-dial-code")}`
        : "+1";
      if (error == 0) {
        const mailData = {
          name: name,
          email: email,
          phoneNumber: phoneNumber,
          location: location,
          selectedBedRooms: selectedBedRooms,
          budget: budget,
          PhContryCode: countryCode,
          moveInDate: moment(state.moveInDateSelected).format("MM/DD/YYYY"),
          mail: "PatnerInformation",
          subject: "Partner Referral Guest Information",
        };

        await nikeService.serviceRequest(mailData);

        // Batch state updates with memoized reset state
        const resetState = {
          name: "",
          email: "",
          phoneNumber: "",
          location: "",
          selectedBedRooms: "",
          moveInDate: null,
          moveInDateSelected: "",
          budget: "",
          loader: false,
          successMessage: true,
        };

        setState((prev) => ({ ...prev, ...resetState }));

        // Single DOM update batch for cleanup
        requestAnimationFrame(() => {
          $(".validation").removeClass("validation");
        });
      }
    } catch (error) {
      console.error("Form submission failed:", error);
      setState((prev) => ({ ...prev, loader: false }));
    }
  };

  const startedBtn = () => {
    $(document).ready(function () {
      jQuery(function ($) {
        $("#phoneNumber").intlTelInput();
      });
    });
    $("#formPopUp").modal("show");
  };
  const closeModal = () => {
    $("#formPopUp").modal("hide");

    // Reset state
    setState((prev) => ({
      ...prev,
      name: "",
      email: "",
      phoneNumber: "",
      location: "",
      selectedBedRooms: "",
      moveInDate: null,
      moveInDateSelected: "",
      budget: "",
      loader: false,
      successMessage: false,
    }));

    // Remove validation classes
    $(".validation").removeClass("validation");
  };

  return (
    <div className="SAweb_updateUi ">
      {/* <GuestHeader /> */}
      <BlogHeader />

      <div className="bluegroundForBrokers">
        <div className="bannerImageBg">
          <div className="container ">
            <div className="bannerView">
              <div className="item">
                <h2>Partner with SuiteAmerica and Earn Referral Rewards</h2>
                <p>
                  Earn a 5% commission on the base rent for each guest you refer
                  who successfully books a SuiteAmerica apartment.
                </p>
                <p>
                  The rental amount refers to the base rent only and does not
                  include any additional fees such as taxes, utilities, or
                  cleaning charges.
                </p>
                <button
                  className="btn btn-callback"
                  type="button"
                  onClick={() => startedBtn()}
                >
                  Get Started
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade-scale"
        data-backdrop="static"
        id="formPopUp"
        role="dialog"
      >
        <div className="modal-dialog">
          <div className="modal-content rm-border-radius">
            <div className="mheader">
              {!state.successMessage ? <h4>Guest Information</h4> : <h4></h4>}
              <button
                type="button"
                onClick={closeModal}
                className="close btn"
                data-dismiss="modal"
                aria-label=""
              >
                <i className="bi bi-x"></i>
              </button>
            </div>
            {!state.successMessage ? (
              <div className="modal-body">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div>
                    <div className="form-group floating-label-group">
                      <input
                        type="text"
                        className="form-control"
                        value={state.name}
                        id="name"
                        required
                        onChange={(e) => inputHandler(e.target.value, "name")}
                      />
                      <label class="floating-label">Name</label>
                    </div>
                    <div className="form-group floating-label-group">
                      <input
                        type="text"
                        className="form-control"
                        value={state.email}
                        id="email"
                        required
                        onChange={(e) => inputHandler(e.target.value, "email")}
                      />
                      <label class="floating-label">Email</label>
                    </div>
                    <div className="form-group ">
                      <input
                        type="text"
                        id="phoneNumber"
                        autoComplete="off"
                        className="form-control"
                        placeholder="Phone"
                        value={state.phoneNumber}
                        onKeyDown={handleKeyPress}
                        onChange={(e) =>
                          inputHandler(e.target.value, "phoneNumber")
                        }
                      />
                    </div>
                    <div className="form-group ">
                      <select
                        className="form-control"
                        id="selectedBedRooms"
                        onChange={(e) =>
                          inputHandler(e.target.value, "selectedBedRooms")
                        }
                        value={state.selectedBedRooms}
                      >
                        <option value="" disabled selected>
                          Size
                        </option>
                        <option value="Studio">Studio</option>
                        <option value="One Bedroom">One Bedroom</option>
                        <option value="Two Bedroom">Two Bedroom</option>
                        <option value="Three Bedroom">Three Bedroom</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                    <div className="form-group floating-label-group">
                      <input
                        type="text"
                        className="form-control"
                        id="location"
                        required
                        onChange={(e) => setLocation(e)}
                        value={state.location}
                      />
                      <label class="floating-label">Location</label>
                    </div>
                    <div className="form-group floating-label-group">
                      <input
                        type="text"
                        className="form-control"
                        value={state.budget}
                        onKeyDown={handleKeyPress}
                        required
                        id="budget"
                        onChange={(e) => inputHandler(e.target.value, "budget")}
                      />
                      <label class="floating-label">Daily Budget ($)</label>
                    </div>
                    <div className="form-group floating-label-group">
                      <DatePicker
                        type="text"
                        className={
                          state.moveInDate == null
                            ? "form-control w-100"
                            : "form-control w-100 labelTop"
                        }
                        selected={state.moveInDate}
                        id="moveInDate"
                        minDate={new Date()}
                        onChange={(event) => handleMoveIn(event)}
                        required
                      />
                      <label class="floating-label">MoveIn Date</label>
                    </div>
                    {state.loader ? (
                      <div
                        className="text-center"
                        style={{
                          fontSize: "20px",
                        }}
                      >
                        <i className="fa fa-spinner fa-spin" />
                        Please wait ...!
                      </div>
                    ) : (
                      <button
                        type="submit"
                        className="btn black-btn"
                        onClick={() => handleSubmit()}
                      >
                        Contact us
                      </button>
                    )}
                  </div>
                </form>
              </div>
            ) : (
              <div className="modal-body">
                <div className="alert alert-success">
                  Email has been sent successfully!
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Brokers;
