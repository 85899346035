import React from "react";
import Footer from "./footer";
import TransHeader from "./transheader";
import platform from "platform";
import GuestServicesapi from "../../../services/guestServicesapi";
import authDetails from "../../common/authDetails";
import ThirdPartyApiService from "../../../services/thirdPartyApis";
import SessionService from "../../../services/sessionService";
class GuestLoginNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      message: "",
      color: "",
      loader: false,
      verifyemaillabel: false,
      verifyotplabel: false,
      clientId: "",
      clientSecret: "",
      OtpErrMsg: "",
      isOTPValid: false,
      passwordLessOtp: "",
      resendOtpTimer: 90,
      authCheckRes: {},
      resendMsg: false,
    };
  }

  async componentDidMount() {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
    const GetResponse = await SessionService.SessionGet({ type: "guest" });
    if (GetResponse.error == 0) {
      location.href = "/reservations";
    }
  }
  setValue(field, e) {
    var object = {};
    object[field] = e.target.value;
    object["message"] = "";
    this.setState(object);
  }
  async callOtp_skip(Otptype) {
    this.getAuth0UserInfo();
  }
  async callOtp(Otptype) {
    await this.setState({
      isOTPValid: false,
      passwordLessOtp: "",
      OtpErrMsg: "",
    });
    this.setState({
      clientId: authDetails.authCredentails().clientId,
      clientSecret: authDetails.authCredentails().clientSecret,
    });
    if (Otptype == "email") {
      const body = {
        email: this.state.email.trim(),
      };
      let sentemailotp = await ThirdPartyApiService.guestsentemailotp(body);
      console.log(sentemailotp);
      if (sentemailotp == "response") {
        this.setState({
          resendMsg: true,
          verifyotplabel: true,
          OtpType: "email",
          resendOtpTimer: 90,
          validateOtp: true,
          loader: false,
          phonecallStatus: false,
          otpStatus: true,
        });
        this.resendOtpTimerFunc();
      } else if (sentemailotp == "error") {
        this.setState({
          errorStatus: true,
          loaderlogin: false,
          message:
            "Looks like you don't have account with us, please register.",
        });
      }
    }
  }
  async authentication() {
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    await this.setState({ loader: true });

    if (this.state.email != "" && reg.test(this.state.email)) {
      const address = "000.00.000.000";
      const loginReqObj = {
        guestEmail: this.state.email,
        // guestPassword: this.state.auth0UserProfile.sub.split('|')[1],
        guestPassword: "",
        status: "guest",
        browsertype: platform.name,
        ipaddress: address,
      };

      const authCheckRes = await GuestServicesapi.guestUsersLogin(loginReqObj);
      if (authCheckRes == "") {
		    await this.setState({
          message: "Sorry something went wrong!Please try after some time",
          color: "red",
          loader: false,
          socailloader: false,
		    });
	     } else if (authCheckRes.error == "1") {
        await this.setState({
          message: "Sorry, we couldn't find an account with that username.",
          color: "red",
          loader: false,
          socailloader: false,
        });
      } else {
        this.setState({
          authCheckRes: authCheckRes,
          message: "",
          loader: false,
          verifyemaillabel: true,
          verifyotplabel: true,
        });
        this.callOtp("email");
      }
    } else {
      this.setState({
        message: "Enter valid email ",
        color: "red",
        loader: false,
      });
    }
  }
  async resendOtpTimerFunc() {
    if (this.state.resendOtpTimer != 0) {
      await this.setState({ resendOtpTimer: this.state.resendOtpTimer - 1 });
      let _this = this;
      setTimeout(function () {
        _this.resendOtpTimerFunc();
      }, 800);
    } else {
      this.setState({
        OtpType: "email",
        validateOtp: true,
        loader: false,
        verifyemaillabel: false,
        verifyotplabel: false,
        otpStatus: true,
        phonecallStatus: false,
      });
      this.nameInput.focus();
    }
  }

  passwordLessOtp(e) {
    this.setState({ passwordLessOtp: e.target.value });
    this.setState({ isOTPValid: false, resendMsg: false });
  }
  async passwordLessLoginOtp() {
    await this.setState({ isOTPValid: false, loader: true });
    let verifyObject = {
      email: this.state.email.trim(),
      verification_code: this.state.passwordLessOtp,
    };
    let sentotpverify = await ThirdPartyApiService.guestsentotpverify(
      verifyObject
    );
    if (sentotpverify == "success") {
      this.getAuth0UserInfo();
    } else if (sentotpverify == "wrong") {
      //this.getAuth0UserInfo();
      this.setState({ isOTPValid: true, resendMsg: false, loader: false });
    }
  }
  async getAuth0UserInfo() {
    const guestInfoReqObj = {
      userData: this.state.authCheckRes.message,
      type: "guest",
    };
    localStorage.setItem(
      "guestSession",
      this.state.authCheckRes.message.sessionId
    );
    await SessionService.SessionStore(guestInfoReqObj);

    const GetResponse = await SessionService.SessionGet({ type: "guest" });
    if (GetResponse.roleId == 2) {
      const guestDetails = {
        orderUid: GetResponse.OrderUId,
      };
      var guestEmailObj = {
        guestEmail: GetResponse.GuestEmail,
        status: "active",
      };
      const GetOrdersByGuestEmailResponse =
        await GuestServicesapi.GetOrdersByGuestEmail(guestEmailObj);
      if (GetOrdersByGuestEmailResponse.message.Orders.length > 0) {
        const tripInfoData = await GuestServicesapi.GetTripInfo(guestDetails);
        if (tripInfoData == null) {
          location.href = "/reservations";
          //this.setState({ loader: false })
        } else {
          location.href = "/reservations";
          //this.setState({ loader: false })
        }
      } else {
        location.href = "/reservations";
        // this.setState({ loader: false })
      }
    } else {
      location.href = "/";
    }
  }
  _handleKeyDown1(e) {
    if (e.key === "Enter") {
      this.authentication();
    }
  }
  _handleKeyDown2(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      this.passwordLessLoginOtp();
    }
  }
  handleClick() {
    $("#sideQuote-header").addClass("active");
    $(".overlay").addClass("active");
    $(".collapse.in").toggleClass("in");
    $("a[aria-expanded=true]").attr("aria-expanded", "false");
  }
  closed() {
    $("#sideQuote-header").removeClass("active");
    $(".overlay").removeClass("active");
  }
  render() {
    return (
      <div className="SAweb_updateUi">
        <header id="header" className="fixed-top guestlogin-header">
          <div className="container">
            <nav className="navbar ">
              <div className="d-flex align-items-center sm-flex-wrap">
                <div className="navbar-header">
                  <a className="navbar-brand" href="/homepage">
                    <img
                      className="mainLogo"
                      src="/assets/newUI-images/logo/SA-full-logo-W.svg"
                      alt=""
                    />
                    <img
                      className="logoIcon"
                      src="/assets/newUI-images/logo/SA-logo-icon-W.svg"
                      alt=""
                    />
                  </a>
                  <button
                    type="button"
                    className="navbar-toggle collapsed"
                    data-toggle="collapse"
                    data-target="#navbar"
                    aria-expanded="false"
                    aria-controls="navbar"
                  >
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </button>
                </div>
                <div id="navbar" className="navbar-collapse collapse">
                  <ul className="nav navbar-nav m-auto">
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link text-white  fs-6"
                        href="javascript:void(0)"
                      >
                        Hospitality <i className="bi bi-caret-down-fill"></i>{" "}
                      </a>
                      <div className="dropdownView">
                        <div>
                          <a
                            href="/corporatehousing"
                            className="dropdown-item "
                          >
                            {" "}
                            Corporate Housing{" "}
                          </a>
                          <a
                            href="/government-housing"
                            className="dropdown-item"
                          >
                            {" "}
                            Government Housing{" "}
                          </a>
                          <a href="/internHousing" className="dropdown-item">
                            {" "}
                            Intern & Group Housing{" "}
                          </a>
                          <a href="/business-travel" className="dropdown-item">
                            {" "}
                            Business Travel{" "}
                          </a>
                          <a
                            href="/insurance-landing"
                            className="dropdown-item"
                          >
                            {" "}
                            Insurance Housing{" "}
                          </a>
                        </div>
                      </div>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link text-white  fs-6"
                        href="/technology"
                      >
                        Technology
                      </a>
                    </li>

                    <li className="nav-item dropdown">
                      <a
                        className="nav-link text-white  fs-6"
                        href="javascript:void(0)"
                      >
                        Stay with us <i className="bi bi-caret-down-fill"></i>{" "}
                      </a>
                      <div className="dropdownView">
                        <div>
                          <a href="/guestExperience" className="dropdown-item ">
                            {" "}
                            Guest Experience{" "}
                          </a>
                          <a href="/spotlightCities" className="dropdown-item">
                            {" "}
                            Spotlight cities{" "}
                          </a>
                          <a href="/locations" className="dropdown-item">
                            {" "}
                            Locations{" "}
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>

                  <form className="d-flex">
                    <button className="btn " type="button">
                      <a href="/guest-login">Guest Login</a>
                    </button>
                    <button
                      className="btn  ms-3"
                      onClick={this.handleClick}
                      type="button"
                    >
                      <i className="bi bi-list"></i>
                    </button>
                  </form>
                </div>
              </div>
            </nav>
          </div>
        </header>

        <section id="sideQuote-header">
          <div className="quoteHeading">
            <div id="dismiss">
              <a onClick={this.closed}>
                <i className="bi bi-x"></i>
              </a>
            </div>
            <ul>
              <li>
                {" "}
                <a href="/company-info">About Us </a>
              </li>
              <li>
                {" "}
                <a href="/leadership-team">Leadership </a>
              </li>
              <li>
                {" "}
                <a href="/career-opportunities">Career </a>
              </li>
              <li>
                {" "}
                <a href="/privacy-policy">Privacy Policy </a>
              </li>

              <li>
                {" "}
                <a href="/contactUs">Contact Us </a>
              </li>
            </ul>
          </div>
          <div className="sideQuote_footer">
            <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/logo/full%20logo.svg" />
            <ul>
              <li>
                {" "}
                <i className="bi bi-telephone-fill pe-2"></i>&nbsp; +1
                800.367.9501{" "}
              </li>
              <li>
                <i className="bi bi-envelope pe-2"></i>&nbsp;
                support@suiteamerica.com{" "}
              </li>
            </ul>
          </div>
        </section>

        <section className="  SAweb_guestLoginBanner">
          <div className="container">
            <div className="loginForm">
              {!this.state.verifyemaillabel && !this.state.verifyotplabel ? (
                <div>
                  <div className="item phColor ">
                    <h2>Sign in to account</h2>
                    {this.state.message != "" && (
                      <div className="alert alert-danger">
                        {this.state.message}
                      </div>
                    )}
                    <input
                      onKeyPress={(e) => this._handleKeyDown1(e)}
                      onChange={(e) => this.setValue("email", e)}
                      value={this.state.email}
                      type="text"
                      className="form-control"
                      placeholder="Email"
                    />
                    {this.state.loader ? (
                      <span
                        style={{
                          fontSize: "20px",
                          width: "100%",
                          margin: "auto",
                          textAlign: "center",
                        }}
                      >
                        <i className="fa fa-spinner fa-spin" />
                        <span style={{ marginLeft: "10px" }}>
                          {" "}
                          please wait ...!
                        </span>{" "}
                      </span>
                    ) : (
                      <div>
                        <button
                          type="button"
                          onClick={() => this.authentication()}
                          className="btn black-btn"
                        >
                          {" "}
                          Password - Less Login
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              ) : this.state.verifyemaillabel && this.state.verifyotplabel ? (
                <div>
                  <div className="item phColor ">
                    <h3>{"Verify your identity"}</h3>
                    {this.state.isOTPValid && (
                      <div className="alert alert-danger">
                        {"Please enter valid verification code"}
                      </div>
                    )}
                    {this.state.resendMsg && (
                      <div className="alert alert-primary">
                        {
                          "The OTP has been sent to your registered email address."
                        }
                      </div>
                    )}
                    <input
                      onKeyPress={(e) => this._handleKeyDown2(e)}
                      type="text"
                      onChange={(e) => this.passwordLessOtp(e)}
                      value={this.state.passwordLessOtp}
                      className="form-control"
                      placeholder="Enter OTP"
                    />
                    {this.state.loader ? (
                      <div className="panel-body_loader">
                        <span
                          style={{
                            fontSize: "20px",
                            width: "100%",
                            margin: "auto",
                            textAlign: "center",
                          }}
                        >
                          <i className="fa fa-spinner fa-spin" />
                          <span style={{ marginLeft: "10px" }}>
                            {" "}
                            please wait ...!
                          </span>{" "}
                        </span>
                      </div>
                    ) : (
                      <div>
                        {this.state.resendOtpTimer == 0 ? null : (
                          <ul>
                            <li className="text-center">
                              <div className="alert-warning" disabled>
                                Resend OTP link visible after ({" "}
                                {this.state.resendOtpTimer} secs)
                              </div>
                            </li>
                          </ul>
                        )}
                        <button
                          type="button"
                          className="btn black-btn"
                          onClick={() => this.passwordLessLoginOtp()}
                          disabled={
                            this.state.passwordLessOtp.length > 5 ? false : true
                          }
                        >
                          Verify
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="item2">
                <h2>Hello Guest</h2>
                <p className="p">Don’t have a guest account with us?</p>
                <a href="/guest-registration">
                  <button type="button" className="btn">
                    {" "}
                    Register
                  </button>
                </a>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
export default GuestLoginNew;
