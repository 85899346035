import React, { Component } from 'react'
import OwlCarousel from "react-owl-carousel";

export class Schools extends Component {
    render() {
        return (
            <div>
                <section className="SAweb_schools second border-top border-2 border-dark">
                    <div className="SAweb_location bg-white ">
                        <div className="container">
                            <div className="row   mx-0">
                                <div className="col-sm-1 ">
                                    <div className="HeadingRotate">
                                        <h2 className="h2_rotate">Schools</h2>
                                    </div>
                                </div>
                                <div className="col-sm-11 py-5">
                                    <ul className="nav nav-tabs">
                                        <li
                                            className="nav-item active"
                                            role="presentation"
                                            id="public"
                                            onClick={() => this.props.handleClick("public")}
                                        >
                                            <button
                                                className="nav-link"
                                                type="button"
                                                aria-selected="true"
                                            >
                                                Public
                                            </button>
                                        </li>
                                        <li
                                            className="nav-item"
                                            role="presentation"
                                            id="private"
                                            onClick={() => this.props.handleClick("private")}
                                        >
                                            <button
                                                className="nav-link"
                                                type="button"
                                                aria-selected="true"
                                            >
                                                Private
                                            </button>
                                        </li>
                                        <li
                                            className="nav-item"
                                            role="presentation"
                                            id="charter"
                                            onClick={() => this.props.handleClick("charter")}
                                        >
                                            <button
                                                className="nav-link"
                                                type="button"
                                                aria-selected="true"
                                            >
                                                Charter
                                            </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link splbtn">
                                                Data provided by <a target={"_blank"} href="https://www.greatschools.org/">GreatSchools </a>
                                            </button>
                                        </li>
                                    </ul>
                                    <div className="tab-content myTabContentVIew">
                                        <div
                                            className="tab-pane fade in active"
                                            id="publicc"
                                            role="tabpanel"
                                            aria-labelledby="public-tab"
                                        >
                                            {/* public */}
                                            <section className=" bg-white pt-5">
                                                {this.props.publicScools.length > 0 ? (
                                                    <div className="owl-slider pb-5 SAweb_sliderBottomArrows billingSlider">
                                                        <div id="carouselSchool">
                                                            <OwlCarousel
                                                                className="owl-theme owl-carousel"
                                                                {...{
                                                                    loop: false,
                                                                    margin: 10,
                                                                    nav: true,
                                                                    dots: false,
                                                                    items: 1,
                                                                    navContainerClass: "owl-buttons",
                                                                    navText: [
                                                                        "<i class='glyphicon glyphicon-menu-left'></i>",
                                                                        "<i class='glyphicon glyphicon-menu-right'></i>",
                                                                    ],
                                                                    responsive: {
                                                                        600: { items: 1, loop: false },
                                                                        767: { items: 2, loop: false },
                                                                        992: { items: 3, loop: false },
                                                                        1199: { items: 3, loop: false },
                                                                    },
                                                                }}
                                                            >
                                                                {this.props.publicScools.map((row, index) => {
                                                                    return (
                                                                        <div className="item" key={index}>
                                                                            <div className="caption">
                                                                                <p>
                                                                                    <a
                                                                                        target="_blank"
                                                                                        href={row.overviewUrl}
                                                                                    >
                                                                                        {row.name}
                                                                                    </a>
                                                                                </p>
                                                                                <p>
                                                                                    <span>
                                                                                        {row.schoolSummary.substring(
                                                                                            0,
                                                                                            213
                                                                                        )}
                                                                                        {row.schoolSummary.length > 216
                                                                                            ? "... "
                                                                                            : ""}
                                                                                        {row.schoolSummary.length > 216 ? (
                                                                                            <a
                                                                                                target="_blank"
                                                                                                href={row.overviewUrl}
                                                                                            >
                                                                                                more
                                                                                            </a>
                                                                                        ) : (
                                                                                            ""
                                                                                        )}
                                                                                    </span>
                                                                                </p>
                                                                                <ul>
                                                                                    {row.rating != null ? (
                                                                                        <li>
                                                                                            <i className="bi bi-star-fill"></i>
                                                                                            {row.rating}/10
                                                                                        </li>
                                                                                    ) : (
                                                                                        <li></li>
                                                                                    )}
                                                                                    <li>
                                                                                        <i className="bi bi-telephone"></i>
                                                                                        {row.phone}
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </OwlCarousel>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="no_data_found">No Data Found!</div>
                                                )}
                                            </section>
                                        </div>
                                        <div className="tab-pane fade" id="privatee" role="tabpanel" aria-labelledby="private-tab">
                                            {/* private */}
                                            <section className=" bg-white pt-5">
                                                {this.props.privateScools.length > 0 ? (
                                                    <div className="owl-slider pb-5 SAweb_sliderBottomArrows billingSlider">
                                                        <div id="carouselSchool">
                                                            <OwlCarousel
                                                                className="owl-theme owl-carousel"
                                                                {...{
                                                                    loop: false,
                                                                    margin: 10,
                                                                    nav: true,
                                                                    dots: false,
                                                                    items: 1,
                                                                    navContainerClass: "owl-buttons",
                                                                    navText: [
                                                                        "<i class='glyphicon glyphicon-menu-left'></i>",
                                                                        "<i class='glyphicon glyphicon-menu-right'></i>",
                                                                    ],
                                                                    responsive: {
                                                                        600: { items: 1, loop: false },
                                                                        767: { items: 2, loop: false },
                                                                        992: { items: 3, loop: false },
                                                                        1199: { items: 3, loop: false },
                                                                    },
                                                                }}
                                                            >
                                                                {this.props.privateScools.map((row, index) => {
                                                                    return (
                                                                        <div className="item" key={index}>
                                                                            <div className="caption">
                                                                                <p>
                                                                                    <a
                                                                                        target="_blank"
                                                                                        href={row.overviewUrl}
                                                                                    >
                                                                                        {row.name}
                                                                                    </a>
                                                                                </p>
                                                                                <p>
                                                                                    <span>
                                                                                        {row.schoolSummary.substring(
                                                                                            0,
                                                                                            213
                                                                                        )}
                                                                                        {row.schoolSummary.length > 216
                                                                                            ? "... "
                                                                                            : ""}
                                                                                        {row.schoolSummary.length > 216 ? (
                                                                                            <a
                                                                                                target="_blank"
                                                                                                href={row.overviewUrl}
                                                                                            >
                                                                                                more
                                                                                            </a>
                                                                                        ) : (
                                                                                            ""
                                                                                        )}
                                                                                    </span>
                                                                                </p>
                                                                                <ul>
                                                                                    {row.rating != null ? (
                                                                                        <li>
                                                                                            <i className="bi bi-star-fill"></i>
                                                                                            {row.rating}/10
                                                                                        </li>
                                                                                    ) : (
                                                                                        <li></li>
                                                                                    )}
                                                                                    <li>
                                                                                        <i className="bi bi-telephone"></i>
                                                                                        {row.phone}
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </OwlCarousel>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="no_data_found">No Data Found!</div>
                                                )}
                                            </section>
                                        </div>
                                        <div className="tab-pane fade" id="charterr" role="tabpanel" aria-labelledby="charter-tab">
                                            {/* charter */}
                                            <section className=" bg-white pt-5">
                                                {this.props.charterScools.length > 0 ? (
                                                    <div className="owl-slider pb-5 SAweb_sliderBottomArrows billingSlider">
                                                        <div id="carouselSchool">
                                                            <OwlCarousel
                                                                className="owl-theme owl-carousel"
                                                                {...{
                                                                    loop: false,
                                                                    margin: 10,
                                                                    nav: true,
                                                                    dots: false,
                                                                    items: 1,
                                                                    navContainerClass: "owl-buttons",
                                                                    navText: [
                                                                        "<i class='glyphicon glyphicon-menu-left'></i>",
                                                                        "<i class='glyphicon glyphicon-menu-right'></i>",
                                                                    ],
                                                                    responsive: {
                                                                        600: { items: 1, loop: false },
                                                                        767: { items: 2, loop: false },
                                                                        992: { items: 3, loop: false },
                                                                        1199: { items: 3, loop: false },
                                                                    },
                                                                }}
                                                            >
                                                                {this.props.charterScools.map((row, index) => {
                                                                    return (
                                                                        <div className="item" key={index}>
                                                                            <div className="caption">
                                                                                <p>
                                                                                    <a
                                                                                        target="_blank"
                                                                                        href={row.overviewUrl}
                                                                                    >
                                                                                        {row.name}
                                                                                    </a>
                                                                                </p>
                                                                                <p>
                                                                                    <span>
                                                                                        {row.schoolSummary.substring(
                                                                                            0,
                                                                                            213
                                                                                        )}
                                                                                        {row.schoolSummary.length > 216
                                                                                            ? "... "
                                                                                            : ""}
                                                                                        {row.schoolSummary.length > 216 ? (
                                                                                            <a
                                                                                                target="_blank"
                                                                                                href={row.overviewUrl}
                                                                                            >
                                                                                                more
                                                                                            </a>
                                                                                        ) : (
                                                                                            ""
                                                                                        )}
                                                                                    </span>
                                                                                </p>
                                                                                <ul>
                                                                                    {row.rating != null ? (
                                                                                        <li>
                                                                                            <i className="bi bi-star-fill"></i>
                                                                                            {row.rating}/10
                                                                                        </li>
                                                                                    ) : (
                                                                                        <li></li>
                                                                                    )}
                                                                                    <li>
                                                                                        <i className="bi bi-telephone"></i>
                                                                                        {row.phone}
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </OwlCarousel>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="no_data_found">No Data Found!</div>
                                                )}
                                            </section>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default React.memo(Schools)