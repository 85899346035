import $ from "jquery";
import AppController from "../../controller/appController";
import GuestServicesapi from "../../../services/guestServicesapi";
import TransHeader from "./transheader";
import Footer from "./footer";

import ThirdPartyApiService from "../../../services/thirdPartyApis";

class NewGuestRegistration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      otp: "",
      message: "",
      userData: [],
      color: "",
      loader: false,
      userData: null,
      buttonType: "sendOtpForRegistration",
      inputType: "email",
      registrationSuccess: false,
    };
    this.setValue = this.setValue.bind(this);
    this.clearAlerts = this.clearAlerts.bind(this);
    this.startRegistration = this.startRegistration.bind(this);
    this.isValidEmail = this.isValidEmail.bind(this);
    this.checkUserStatusInExistingDataBase =
      this.checkUserStatusInExistingDataBase.bind(this);
    this.sendOtp = this.sendOtp.bind(this);
    this.onOtpSendSuccess = this.onOtpSendSuccess.bind(this);
    this.onOtpSendFailure = this.onOtpSendFailure.bind(this);
    this.verifyOtp = this.verifyOtp.bind(this);
    this.onOtpVerificationSuccess = this.onOtpVerificationSuccess.bind(this);
    this.onOtpVerificationFailure = this.onOtpVerificationFailure.bind(this);
    this.registerGuest = this.registerGuest.bind(this);
    this.completeRegistration = this.completeRegistration.bind(this);
  }

  /* It is invoked immediately before mounting occurs */
  componentWillMount() {
    AppController.removeLocalStorageData();
    $("html, body").animate({ scrollTop: 0 });
    AppController.stickyTitles();
  }

  async clearAlerts(e) {
    e.preventDefault();
    this.setState({ message: "", color: "", loader: false });
  }

  /* Update the state object of the form */
  setValue(field, e) {
    var object = {};
    if (field === "otp") {
      object[field] = e.target.value.replace(/\D/g, "");
    } else {
      object[field] = e.target.value;
    }
    object["message"] = "";
    this.setState(object);
  }

  isValidEmail(email) {
    if (
      !/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(email)
    ) {
      return false;
    }
    return true;
  }

  onOtpSendSuccess() {
    this.setState({
      message: "Please Check Your Email For Otp",
      color: "green",
      loader: false,
    });
    // this.resendOtpTimerFunc();
  }

  onOtpSendFailure() {
    this.setState({
      loader: false,
      message: "Looks like you don't have account with us, please register.",
      color: "red",
    });
  }

  async checkUserStatusInExistingDataBase(email) {
    const login = {
      email: email,
    };
    const userData = await GuestServicesapi.getSingleGuestDetails(login);
    this.setState({ userData });
    if (
      userData.OrderId != null &&
      userData.OrderId != "" &&
      userData.webCount === 0
    ) {
      this.sendOtp(email);
    } else if (userData.webCount == 0 && userData.OrderId == "") {
      this.setState({
        loader: false,
        email: "",
        message:
          "The email doesn't exist in our system. Please contact your SuiteAmerica contact to update your reservation with your email.",
        color: "red",
      });
    } else if (userData == "Failure") {
      this.setState({
        message:
          "We do not have your email on file. please contact your POC to update your email.",
        email: "",
        color: "red",
        loader: false,
      });
    } else if (userData.webCount > 0 && userData.OrderId == "") {
      this.setState({
        loader: false,
        email: "",
        message:
          "Email already exists in our system. But you dont have any active orders.Please contact to update your reservation with your email.",
        color: "red",
      });
    } else if (userData.webCount > 0 && userData.OrderId) {
      this.setState({
        loader: false,
        email: "",
        message: "You have already registered, Please Login",
        color: "red",
      });
    }
  }

  async sendOtp(email) {
    const body = {
      email: email.trim(),
    };
    let sentemailotp = await ThirdPartyApiService.guestsentemailotp(body);
    if (sentemailotp == "response") {
      this.setState({
        buttonType: "verifyOtp",
        inputType: "otp",
      });
      this.onOtpSendSuccess();
    } else if (sentemailotp == "error") {
      this.onOtpSendFailure();
    }
  }

  startRegistration(e) {
    e.preventDefault();
    if (this.isValidEmail(this.state.email)) {
      this.setState({ message: "", color: "", loader: true });
      this.checkUserStatusInExistingDataBase(this.state.email);
    } else {
      this.setState({ message: "Enter valid email.", color: "red" });
    }
  }

  onOtpVerificationSuccess() {
    // this.setState({
    //   loader: false,
    //   message: "otp verification successful",
    //   color: "green",
    // });
    this.registerGuest();
  }

  onOtpVerificationFailure() {
    this.setState({
      loader: false,
      message: "otp verification failed",
      color: "red",
    });
  }

  async verifyOtp(e) {
    e.preventDefault();
    this.setState({ loader: true });
    let verifyObject = {
      email: this.state.email.trim(),
      verification_code: this.state.otp,
    };
    let sentotpverify = await ThirdPartyApiService.guestsentotpverify(
      verifyObject
    );
    if (sentotpverify == "success") {
      this.onOtpVerificationSuccess();
    } else if (sentotpverify == "wrong") {
      this.onOtpVerificationFailure();
    }
  }

  async completeRegistration(reqObj) {
    const userData = await GuestServicesapi.saveGuestUserInfo(reqObj);
    if (userData.error == 0) {
      this.setState({
        message: "Registered successfully. Please login",
        color: "green",
        loader: false,
        registrationSuccess: true,
      });
    } else {
      this.setState({
        message: "sorry",
        color: "red",
        loader: false,
      });
    }
  }

  async registerGuest() {
    if (this.state.userData.webCount == 0) {
      const register = {
        guestEmailId: this.state.userData.GuestEmail || "",
        guestPwd: "",
        guestIsActive: 1,
        guestFirstName: this.state.userData.GuestFirstName || "",
        guestLastName: this.state.userData.GuestLastName || "",
        guestPhoneNumber: this.state.userData.GuestPhoneNo || "",
        guestOrderId: this.state.userData.OrderId || "",
      };
      this.completeRegistration(register);
    } else {
      const register = {
        guestEmailId: Base64.decode(this.props.match.params.email),
        // guestPwd: this.state.auth0Response._id,
        guestPwd: "",
        guestId: this.state.userData.WebInfo.Id,
      };
      this.completeRegistration(register);
    }
  }

  /* It is invoked to return html content */
  render() {
    return (
      <div className="SAweb_updateUi">
        <TransHeader />
        <section className="  SAweb_guestLoginBanner">
          <div className="container">
            <div className="loginForm">
              <div>
                <div className="item phColor ">
                  {this.state.registrationSuccess ? (
                    <MessageBox
                      message={this.state.message}
                      color={this.state.color}
                    />
                  ) : (
                    <>
                      <h3>Guest Portal Registration</h3>
                      <MessageBox
                        message={this.state.message}
                        color={this.state.color}
                      />
                      <Input
                        inputType={this.state.inputType}
                        setValue={this.setValue}
                        email={this.state.email}
                        otp={this.state.otp}
                      />
                      {this.state.loader ? (
                        <span
                          style={{
                            fontSize: "20px",
                            width: "100%",
                            margin: "auto",
                            textAlign: "center",
                          }}
                        >
                          <i className="fa fa-spinner fa-spin" />
                          <span style={{ marginLeft: "10px" }}>
                            {" "}
                            please wait ...!
                          </span>{" "}
                        </span>
                      ) : (
                        <Button
                          buttonType={this.state.buttonType}
                          startRegistration={this.startRegistration}
                          verifyOtp={this.verifyOtp}
                          otp={this.state.otp}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="item2">
                <h2>Hello Guest</h2>
                <p className="p">Already registered?</p>
                <a href="/guest-login">
                  {" "}
                  <button type="button" className="btn">
                    {" "}
                    Sign In
                  </button>
                </a>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
export default NewGuestRegistration;

class Button extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { startRegistration, buttonType, verifyOtp, otp } = this.props;
    if (buttonType === "sendOtpForRegistration") {
      return (
        <button
          type="button"
          onClick={(e) => startRegistration(e)}
          className="btn black-btn"
        >
          Sign Up
        </button>
      );
    }

    if (buttonType === "verifyOtp") {
      return (
        <button
          type="button"
          onClick={(e) => verifyOtp(e)}
          className="btn black-btn"
          disabled={otp.length < 5 ? true : false}
        >
          Verify OTP
        </button>
      );
    }
    return null;
  }
}

class Input extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { inputType, setValue, email, otp } = this.props;
    if (inputType === "email") {
      return (
        <input
          onChange={(e) => setValue("email", e)}
          value={email}
          type="text"
          className="form-control"
          placeholder="Email"
        />
      );
    }

    if (inputType === "otp") {
      return (
        <input
          onChange={(e) => setValue("otp", e)}
          value={otp}
          type="text"
          className="form-control"
          placeholder="Enter OTP"
        />
      );
    }
  }
}

class MessageBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { message, color } = this.props;
    return (
      <>
        {message != "" && (
          <div
            className={`alert ${
              color === "red"
                ? "alert-danger"
                : "alert-success alert-dismissible"
            }`}
            style={{ fontSize: "15px" }}
          >
            {message}
          </div>
        )}
      </>
    );
  }
}
