/* Importing the node modules, child components, services and controllers used
   inside RobeCampaignLanding component */   
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   import RobeCompaign from '../../common/roboCampaign';   
   import ContactUs from '../../common/contactUs';
   import SuccessModal from '../../common/successModal';
   /* RobeCampaignLanding Component initialization */
   class RobeCampaignLanding extends React.Component {
       /* Initializing objects of its RobeCampaignLanding class */
       constructor(props) {
           super(props);
           this.state = {
             robeCompaignData: RobeCompaign,
             defaultData: {
                "name": "---",
                "firstName": "---",
                "lastName": "---",
                "address": "---",
                "signature": "",
                "url": "---",
                "image": "",
                "email": "---",
                "phoneNo": "---"
             },
             contactDetails: {
                name: "ROBE Campaign",
                type: "robeCampaign"                
            }
           }               
       }
       /* It is invoked immediately before mounting occurs */
       componentWillMount() {
           MainController.getInitialInformation();
       }
       /* It is invoked to return html content */
       render() {
           const matchUrl = this.props.match.params.name;
           const findData = _.find(this.state.robeCompaignData, function(o) { return o.url == matchUrl; });
           const compaignData = findData != undefined ? findData : this.state.defaultData;
   
           return (
            <div className="corporateHousing_Updates">
                <GuestHeader/>
                <header className="codeconveyHeader cc_image_text_main corporate-h video-wrapper worldbnk_banner">
                <img className="img-responsive" src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/ROBE-Campaign/landing.jpg" width="100%" alt=""  />
                <div className="wow fadeInUp img-on-text top45 width500 text-center left10 img-on-text-center company_bannertxt" data-wow-delay="0.5s">
                    <h1 className="color-red">Experience the SuiteAmerica Standard</h1>
                    <p className="color-black">for Yourself</p>
                </div>
                </header>
                <div className="container">
                <div className="mrg-top-100 ">
                    <div className="col-md-4 text-center person_landing">
                        <div className="person_img">
                            <img src={compaignData.image} alt="" />
                        </div>
                        <h4>{compaignData.name}</h4>
                        <p>{compaignData.address}</p>
                        <div><img src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/ROBE-Campaign/per_mobile.png" className="mrg-btm-20 mrg-top-20 mrg-right-5" alt="" width="20" /><span className="">{compaignData.phoneNo}</span></div>
                        <div><img src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/ROBE-Campaign/per_mail.png" className="mrg-right-5" alt="" width="20" /><span className="">{compaignData.email}</span></div>
                    </div>
                    {matchUrl=='leslie'?
           <div className="col-md-8 mrg-btm-100 person_content">
           <p>Welcome to SuiteAmerica’s award-winning corporate housing and destination services.</p>
           <p>I personally selected you to receive a weekend getaway-on us.* Through our innovative technology and unrivaled level of care, SuiteAmerica provides a corporate housing experience like no other. And I want you to experience it for yourself.</p>
           {/* <p>{compaignData.firstName} personally selected you to receive a weekend getaway-on us.* Through our innovative technology and unrivaled level of care, SuiteAmerica provides a corporate housing experience like no other. And {compaignData.firstName} wants you to experience it for yourself.</p> */}
          <p>With SuiteAmerica’s concierge and personal transition teams, we create customized housing solutions tailored to your company’s specific needs. When we meet, you’ll receive an exclusive gift as well as come to know how we seamlessly blend our advanced technology and high level of care to create a better, more efficient client and guest experience.</p>
           {/* <p>With SuiteAmerica’s concierge and personal transition teams, we create customized housing solutions tailored to your company’s specific needs. When meeting with {compaignData.firstName}, you’ll receive an exclusive gift as well as come to know how we seamlessly blend our advanced technology and high level of care to create a better, more efficient client and guest experience.</p> */}
           <p>Enter your information below, and I will give you a call to discuss your corporate housing needs. Enjoy a weekend away and see how SuiteAmerica’s corporate housing can help your company’s relocating employees have an effortless, stress-free transition into their new home.</p>
           {/* <p>Enter your information below, and {compaignData.firstName} will give you a call to discuss your corporate housing needs. Enjoy a weekend away and see how SuiteAmerica’s corporate housing can help your company’s relocating employees have an effortless, stress-free transition into their new home.</p>                         */}

           <center className="mrg-top-45">
               <p>I look forward to speaking with you!</p>
               <img src={compaignData.signature} style={{height : "50px"}} alt="" />
           </center>
       </div>:
                  <div className="col-md-8 mrg-btm-100 person_content">
                  <p>Welcome to SuiteAmerica’s award-winning corporate housing and destination services.</p>
                  <p>{compaignData.firstName} personally selected you to receive a weekend getaway-on us.* Through our innovative technology and unrivaled level of care, SuiteAmerica provides a corporate housing experience like no other. And {compaignData.firstName} wants you to experience it for yourself.</p>
                  <p>With SuiteAmerica’s concierge and personal transition teams, we create customized housing solutions tailored to your company’s specific needs. When meeting with {compaignData.firstName}, you’ll receive an exclusive gift as well as come to know how we seamlessly blend our advanced technology and high level of care to create a better, more efficient client and guest experience.</p>
                  <p>Enter your information below, and {compaignData.firstName} will give you a call to discuss your corporate housing needs. Enjoy a weekend away and see how SuiteAmerica’s corporate housing can help your company’s relocating employees have an effortless, stress-free transition into their new home.</p>                        
                  <center className="mrg-top-45">
                      <p>I look forward to speaking with you!</p>
                      <img src={compaignData.signature} style={{height : "50px"}} alt="" />
                  </center>
              </div>

                    }
         
                    <div className="container text-center">
                        <h3>Contact {compaignData.firstName}</h3>
                    </div>
                    <div id="serviceRequest">    
                        <ContactUs contactDetails={this.state.contactDetails} managerEmailId={compaignData.email}/>
                    </div>
                    <div className="col-md-12 person_content mrg-top-50">
                        <p>*Weekend getaway & exclusive gift subject to availability and location.  Offer/promotion can’t be combined with any other offers/promotions.  In order to receive weekend getaway & gift in-person meeting is required*</p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="person_options mrg-top-50 mrg-btm-50">
                        <div>
                            <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/ROBE-Campaign/per_option_1.png" alt="" />
                            <h4>Innovation</h4>
                        </div>
                        <div>
                            <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/ROBE-Campaign/per_option_2.png" alt="" />
                            <h4>integrity</h4>
                        </div>
                        {/* <div>
                            <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/ROBE-Campaign/per_option_3.png" alt="" />
                            <h4>employee owned</h4>
                        </div> */}
                        <div>
                            <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/ROBE-Campaign/per_option_4.png" alt="" />
                            <h4>commitment</h4>
                        </div>
                        <div>
                            <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/ROBE-Campaign/per_option_5.png" alt="" />
                            <h4>30 years experience</h4>
                        </div>
                    </div>
                    <ul className="list-inline per_social_list text-center mrg-btm-45">
                        <li><a href="https://www.facebook.com/SuiteAmerica" target="_blank"><i className="fa fa-facebook"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/108459?trk=saber_s000001e_1000" target="_blank"><i className="fa fa-linkedin"></i></a></li>
                        <li><a href="https://twitter.com/SuiteAmerica" target="_blank"><i className="fa fa-twitter"></i></a></li>
                        <li><a href="https://www.youtube.com/user/SuiteAmerica?feature=mhum" target="_blank"><i className="fa fa-youtube-play"></i></a></li>
                    </ul>
                </div>
                </div>
                <SubFooter/>
                <Footer/>
                <SuccessModal/>
            </div>
           );
       }
   }
   export default RobeCampaignLanding;
   