import React, { Component } from 'react';
import SessionService from "../../../services/sessionService";
import GuestServicesapi from '../../../services/guestServicesapi';
import MainController from '../../controller/mainController';
import GuestController from '../../controller/guestController';

import FiveStarApiServices from '../../../services/fiveStarApiServices';
import { withRouter } from "react-router-dom"
import Loader from '../../common/loader';
import ThirdPartyApiService from "../../../services/thirdPartyApis";

class guestHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      guestSessionResponse: {},
      userData: {},
      roleId: 0,
      hideMenu: false,
      loader: false
    }
    this.changePassword = this.changePassword.bind(this);
  }

  async guest() {
    MainController.removeClassOnNavCollapse();
    const guestLoginResponse = await GuestController.guestLoginResponse();
    await this.setState({ userData: guestLoginResponse });
    var client = await SessionService.SessionGet({ type: 'client' });
    if (client.error == 0) {
      await this.setState({
        roleId: (client.roleId != undefined && Object.keys(client).length > 0) ? client.roleId : 0,
        ClientType: (client.ClientType != undefined && Object.keys(client).length > 0 ? client.ClientType : '')
      })
    }
    const GetResponse = await SessionService.SessionGet({ type: 'guest' });
    if (GetResponse.error == 0) {
      await this.setState({
        roleId: (GetResponse.roleId != undefined && Object.keys(GetResponse).length > 0) ? GetResponse.roleId : 0,
        ClientType: (GetResponse.ClientType != undefined && Object.keys(GetResponse).length > 0 ? GetResponse.ClientType : '')
      })
    }
    await this.setState({ guestSessionResponse: GetResponse });
    if (GetResponse.roleId == 2) {
      var guestEmailObj = {
        guestEmail: GetResponse.GuestEmail,
        status: "active"
      }
      const GetOrdersByGuestEmailResponse = await GuestServicesapi.GetOrdersByGuestEmail(guestEmailObj)
      if (GetOrdersByGuestEmailResponse.message.Orders.length == 0) {
        this.setState({ hideMenu: true })
      }
      // else {
      //   var currentDate = moment().format("YYYY-MM-DD");
      //   var navList = this.state.navigationList
      //   var orderUIdObj = {
      //     OrderUId: GetOrdersByGuestEmailResponse.message.Orders[0].OrderUId
      //   }
      // GuestServicesapi.GetOrderInfoById(orderUIdObj).then((getOrderInfo) => {
      //   var MoveInDate = moment(getOrderInfo.message.orderInfo.MoveInDate).format("YYYY-MM-DD");
      //   // var MoveInDate = "2020-11-25";
      //   // var MoveOutDate = "2020-11-25";
      //   var MoveOutDate = moment(getOrderInfo.message.orderInfo.MoveOutDate).format("YYYY-MM-DD");
      //   if (MoveInDate == currentDate) {
      //     GuestServicesapi.GetInOutOrdersLog({ orderUid: orderUIdObj.OrderUId }).then((GetInOutOrdersResponse) => {
      //       if (GetInOutOrdersResponse.length > 0) {
      //         var checkIn = GetInOutOrdersResponse.filter(e => e.Type == "IN")
      //         if (checkIn.length == 0) {
      //           navList.push({ name: 'CHECK IN', url: '/check-in' })
      //           this.setState({ navigationList: navList })
      //         } else {

      //         }
      //       } else {
      //         navList.push({ name: 'CHECK IN', url: '/check-in' })
      //         this.setState({ navigationList: navList })
      //       }
      //     })
      //   }
      //   if (MoveOutDate == currentDate) {
      //     GuestServicesapi.GetInOutOrdersLog({ orderUid: orderUIdObj.OrderUId }).then((GetInOutOrdersResponse) => {
      //       if (GetInOutOrdersResponse.length > 0) {
      //         var checkOut = GetInOutOrdersResponse.filter(e => e.Type == "OUT")
      //         if (checkOut.length == 0) {
      //           navList.push({ name: 'CHECK OUT', url: '/check-out' })
      //           this.setState({ navigationList: navList })
      //         } else {

      //         }
      //       } else {
      //         navList.push({ name: 'CHECK IN', url: '/check-in' })
      //         this.setState({ navigationList: navList })
      //       }
      //     })
      //   }

      // })
      // }
    }
  }

  async changePassword() {
    const requestObj = {
      "authkey": "",
      "sourcetype": "guest",
      "requestforkey": true
    }

    const TimeOut = await FiveStarApiServices.GetTimeoutKey(requestObj)

    let encodeEmail = Base64.encode(this.state.guestSessionResponse.GuestEmail);
    let encodeUserId = Base64.encode(this.state.userData.Id);
    let timeoutKey = TimeOut.timeoutkey;

    this.props.history.push({
      pathname: '/guest-forgotpassword/' + encodeEmail + '/' + encodeUserId + '/' + timeoutKey + '',
      type: "changePassword"
    })
  }

  componentDidMount() {
    this.guest()
  }

  async logout() {
    this.setState({ loader: true })
    if(this.state.roleId == 3){
      await GuestServicesapi.ClientUsersLogOut({ sessionId: localStorage.getItem('guestSession') })
    }  else{
      await GuestServicesapi.GuestUsersLogOut({ sessionId: localStorage.getItem('guestSession') })
    } 
    await localStorage.removeItem('guestSession');
    if (this.state.roleId == 2) {
      const userData = await SessionService.SessionDestory({ type: 'guest' });
      if (userData.error == 0) {
       await ThirdPartyApiService.logoutguest();
        location.href='/guest-login'
      }
    }
    if (this.state.roleId == 3) {
      const userData = await SessionService.SessionDestory({ type: 'client' });
      if(userData.error == 0){
        location.href = '/celogin' 
      }
    }
    this.setState({ loader: false })
  }

  render() {
    return (
      <div>

        <nav className="navbar navbar-expand-lg navbar-light ">
          <div className="container-fluid">
            <a className="navbar-brand" href="#">
              <img src="../images/orderImages/logo.png" alt="logo" width="100%" onClick={() => window.location.href = '/'} />
            </a>
            <button type="button" className="navbar-toggle navbar-toggler collapsed" data-toggle="collapse"
              data-target="#navbar" aria-expanded="false" aria-controls="navbar">
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbar">
              <ul className="navbar-nav m-auto">
                <li className="nav-item ">
                  <a className="nav-link" aria-current="page" href="/reservations">My Reservations</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/your-location">Explore Your Location</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/guest-services">Service Request</a>
                </li>
              </ul>
              <div className="nav-dropsDown nav-btns">
                <ul>
                  <li className="drop userAccount">
                    <a href="#"> <span className="user"> <img src="../images/orderImages/user-circle.svg" alt="" width="100%" />
                    </span> <span>{this.state.guestSessionResponse.GuestFirstName} <i>{this.state.guestSessionResponse.GuestEmail}</i> </span> </a>
                    <div className="dropdownContain">
                      <div className="dropOut">
                        <div className="triangle" />
                        <ul>
                          {/* <li onClick={this.changePassword}>Change Password</li> */}
                          <li onClick={() => this.logout()}>Sign Out</li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

            </div>
          </div>
        </nav>
        {this.state.loader && <div className="logoutLoader"><Loader /></div>}
      </div>
    )
  }
}

export default withRouter(guestHeader)
