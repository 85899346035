/* Importing the node modules, child components, services and controllers used 
   inside mapController object */
   'use strict'

   /**
    * Map controller - controls application state and interaction among various components.
    */
   
   const mapController = {
     /* To initialize the google maps */
     async loadMapData(community, this_obj, icon) {
       const _this=this_obj
       var directionsService = new google.maps.DirectionsService();
       var directionsDisplay = new google.maps.DirectionsRenderer();
       _this.setState({'directionsService': directionsService});
       _this.setState({'directionsDisplay': directionsDisplay});
       const imageName = community.image_name;
       const communityName = community.community_name;
       const communityAddress = community.address;
       const communityLat = community.latitude;
       const communityLong = community.longitude;
       const communityCity = community.city_name;
       const communityState = community.state_name;
       const communityZipcode = community.ZipCode;
       const detailAddress= communityAddress +', ' +communityCity + ', '+communityState + ' ' + communityZipcode
       var locations = {
         lat: Number(communityLat),
         lng: Number(communityLong)
       };
       var map = new google.maps.Map(document.getElementById('map'), {
         zoom: 7,
         center: locations,
         mapTypeId: google.maps.MapTypeId.ROADMAP
       });
       var contentString = '<div id="content">' +
           '<div id="siteNotice">' +
           '</div>' +
           '<div id="bodyContent">'+
           '<div class="bodycontentimg"><img width="100px" height="100px" src="' + imageName + '" />   </div>' +
           '<h1>'+ communityName +'</h1>' +
           '<p>'+ detailAddress +' </p><a href="https://www.google.com/maps?daddr='+communityLat+ ','+communityLong+'" target="_blank">Get driving directions</a>' +
         '</div>' +
         '</div>';
       _this.state.directionsDisplay.setMap(map);
       var infowindow = new google.maps.InfoWindow({
           content: contentString
       });
       var marker = new google.maps.Marker({
           position: locations,
           icon: icon,
           map: map
       });
       marker.addListener('click', function () {
         infowindow.open(map, marker);
       });
     },
     streetView(communityObject){
       var panorama = new google.maps.StreetViewPanorama(
         document.getElementById('street-view'),
         {
           position: {lat: Number(communityObject.latitude), lng: Number(communityObject.longitude)},
           pov: {heading: 165, pitch: 0},
           zoom: 1
         });
         console.log(panorama)
     }
   }
   
   export default mapController