const SuiteNotReadyModal = (props) => {
    return (
        <div className="modal fade savesearch" id="isSuiteReady" role="dialog">
            <div className="modal-dialog">
                <div className="modal-content rm-border-radius">
                    <div className="modal-header">
                    {props.modalLoader==true? '':<button type="button" className="close" data-dismiss="modal" aria-label="">
                    <span>×</span>
                    </button>}
                    </div>
                    <div className="modal-body">
                    <h2>Reason for suite not ready</h2>
                    <p className="text-center" style={{color:props.color}}>{props.message}</p>
                    <form role="form" method="post" >
                        <div className="form-group">
                        <div className="col-md-12 mrg-btm-30">
                        <textarea className="form-control" placeholder="Message" value={props.suiteNotReadyText} onChange={(e)=>props.setValue('suiteNotReadyText',e)}></textarea>
                        </div>
                        </div>
                    <div className="clearfix"></div>
                    {props.modalLoader==true? <div className="panel-body_loader" > <span style={{fontSize: '25px', width: '100%', margin: 'auto', textAlign: 'center'}}><i className="fa fa-spinner fa-spin"/> please wait ...!</span>
         </div>:<button
                className="btn location_btn"
                type="button" onClick={() =>props.reSchedule()}>Submit</button>}
                    </form>

                    </div>
                </div>
            </div>
        </div>
    )
}
export default SuiteNotReadyModal;