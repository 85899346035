'use strict'
import GuestServicesapi from '../../services/guestServicesapi';
import GuestEmailService from '../../services/guestEmailService';
import SessionService from "../../services/sessionService";
const GuestController = {
    
    async getGuestInformation() {
        const userData = await SessionService.SessionGet({type:'guest'});
        const guestInfo = {
            guestEmailid: (userData != undefined && userData.GuestEmail != null)
                ? userData.GuestEmail
                : '',
            orderid: (userData != undefined && userData.OrderID != null)
                ? userData.OrderID
                : ''
        }
     
         const guestOrder = await GuestServicesapi.getGuestInfoCommunityByEmailId(guestInfo)

                return guestOrder
    },
    async insertServiceRequest(data) {
        const guestInsertResponse = await GuestServicesapi.saveServicesRequestDetails(data)
        if (guestInsertResponse.error == 0) {
            const guestEmailResponse = await GuestEmailService.GuestServiceRequestEmail(data)
            return guestEmailResponse
        }
    },
    async getGuestReqRes(data) {
        const response = await GuestServicesapi.getServiceRequestByOrderId(data)
        return response
    },
    async getserviceNotesById(data) {
        const response = await GuestServicesapi.getServiceRequestNotestById(data)
        return response
    },
    async insertNotes(data) {
        const response = await GuestServicesapi.saveServiceRequestNotesDetails(data)
        return response
    },
    async guestLoginResponse(data) {
        const userData = await SessionService.SessionGet({type:'guest'});
        return userData
    }
}

export default GuestController
