import DatePicker from 'react-datepicker';
import PaymentForm from "./paymentForm";
import { Elements, StripeProvider } from 'react-stripe-elements';
const GuestForm=(props)=>{
    return(
<div className="col-sm-8 col-sm-pull-4">
<div className="clearfix"></div>
<form role="form" method="post" className="bookg-form">
   <h4 className="text-red">
      GUEST
   </h4>
   <div id="reservation-information" className="panel-collapse collapse in">
      <div className="row">
         <div className="col-sm-6">
            <div className={(props.cardValidationError.firstName == 1 || (props.submitStatus == 1 && props.firstName == "")) ? "form-group error":"form-group"}>
              {props.firstName!= "" ? <label className="form-control-placeholder">First Name</label> : ""}
              {props.firstName != "" && props.cardValidationError.firstName == 0 ? <i className="fa fa-check succes"></i> : ""}
               <input type="text" autoComplete="firstName" maxLength="10" className="form-control" placeholder="First Name *" value={props.firstName} onChange={(e) => props.setValue('firstName', e)} onKeyUp={(e) => props.CardValidation('firstName', 3)}/>
               {(props.cardValidationError.firstName == 1 || props.submitStatus == 1) && <span className="text-danger font-12">{props.firstName == "" ? "Please enter First Name" :(props.cardValidationError.firstName == 1 ? "Please enter at least 3 characters" : "")}</span>}
            </div>
         </div>
         <div className="col-sm-6">
            <div className={(props.cardValidationError.lastName == 1 || (props.submitStatus == 1 && props.lastName == "")) ? "form-group error":"form-group"}>
            {props.lastName!= "" ? <label className="form-control-placeholder">Last Name</label> : ""}
            {props.lastName != "" && props.cardValidationError.lastName == 0 ? <i className="fa fa-check succes"></i> : ""}
               <input type="text" maxLength="10" autoComplete="lastName" className="form-control" placeholder="Last Name *" value={props.lastName} onChange={(e) => props.setValue('lastName', e)} onKeyUp={(e) => props.CardValidation('lastName', 3)}/>
               {props.cardValidationError.lastName == 1 || props.submitStatus == 1 ? <span className="text-danger font-12">{props.lastName == "" ? "Please enter Last Name" :(props.cardValidationError.lastName == 1 ? "Please enter at least 3 characters" : "")}</span> : ""}
            </div>
         </div>
      </div>
      <div className="row">
         <div className="col-sm-6">
         <div className={(props.cardValidationError.emailId == 1 || (props.submitStatus == 1 && props.emailId == "")) ? "form-group error":"form-group"}>
            {props.emailId!= "" ?<label className="form-control-placeholder">Email Id</label> : ""}
            {props.emailId != "" && props.cardValidationError.emailId == 0? <i className="fa fa-check succes"></i> : ""}
               <input type="text" autoComplete="emailId" className="form-control" placeholder="Email *" value={props.emailId} onChange={(e) => props.setValue('emailId', e)} onKeyUp={(e) => props.CardValidation('emailId', 0, 0)}/>
               {(props.submitStatus == 1 || props.cardValidationError.emailId == 1) ? <span className="text-danger font-12">{props.emailId=="" ? "Please enter Email Id":(props.cardValidationError.emailId == 1 ? "Please enter valid Email Id" : "")}</span> : ""}
            </div>
         </div>
         <div className="col-sm-6">
                                                <div className={(props.cardValidationError.phoneNo == 1 || (props.submitStatus == 1 && props.phoneNo == "")) ? "form-group error":"form-group"}>
                                                        <input type="text" autoComplete="342342342" className="form-control" id="booking_phone" placeholder='Phone Number*' maxLength="10" onChange={(e) => props.setNumber('phoneNo', e)} onBlur={(e) => props.setNumber('phoneNo', e)} value={props.phoneNo} onKeyUp={(e) => props.CardValidation('phoneNo', 9, 12)}/>
                                                        {props.phoneNo != "" ? <label className="form-control-placeholder">Phone Number</label> : ""}
                                                        {(props.submitStatus == 1 || props.cardValidationError.phoneNo == 1) ? <span className="text-danger font-12">{props.phoneNo == "" ? "Please enter phone number" : (props.cardValidationError.phoneNo == 1 ? "Please enter valid phone number" : "")}</span> : ""}
                                                        
                                                        {props.phoneNo != "" && props.cardValidationError.phoneNo == 0 ? <i className="fa fa-check succes"></i> : ""}
                                                  
                                                    </div>
                                                </div>
      </div>
      <div className="row">
         <div className="col-sm-6">
            <div className="form-group">
               <label className="form-control-placeholder">Check In Date</label>
               <DatePicker type="text" className="form-control " name="date" placeholder="CheckIn" selected={moment(props.checkin)} disabled />
            </div>
         </div>
         <div className="col-sm-6">
            <div className="form-group">
               <label className="form-control-placeholder ">Check Out Date</label>
               <DatePicker type="text" className="form-control " name="date" placeholder="CheckOut" selected={moment(props.checkout)} disabled />
            </div>
         </div>
      </div>
   </div>
   <StripeProvider apiKey="pk_test_fLDeMDGbri4vi7EPgPqk23yR">
      <Elements>
         <PaymentForm {...props}/>
      </Elements>
   </StripeProvider>
</form>
</div>
)
}
export default GuestForm;
    