
import AppController from "../../controller/appController";
import MainController from "../../controller/mainController";
import SubFooter from "../../common/subFooter";
import GuestHeader from '../../common/guestHeader';
import Footer from '../../common/footer';
import MediaFooter from "./common/mediaFooter";
import NikeServices from '../../../services/nikeService';
import SuccessModal from '../../common/successModal';
import MetaTags from '../../common/metaTags';
import ServerMetaTags from '../../../server/metaTags';
import ReCAPTCHA from "react-google-recaptcha";
/* MediaContact Component initialization */
class MediaContact extends React.Component {
    /* Initializing objects of its MediaContact class */
    constructor(props) {
        super(props)
        this.state={
            firstName: '',
            lastName: '',
            email: '',
            subject: '',
            message: '',
            color:'',
            errorMessage:'',
            CaptchaValue:'',
        }
        this.setValue = this.setValue.bind(this);
        this.serviceRequest = this.serviceRequest.bind(this);
        this.onChangeCaptcha = this.onChangeCaptcha.bind(this);
    }
    /* It is invoked immediately before mounting occurs */
    componentWillMount() {
        MainController.getInitialInformation();
    }
     /* Form Details object creations  */
   setValue(field, e) {
         var object = {};
         object[field] = e.target.value;
         this.setState(object);
       }
    /* Service Request Details send to mail  */
    async serviceRequest(){
     var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;  
     const _this = this
        const validationFields=['firstName','lastName','email','CaptchaValue']
        let error = 0;
        _.forEach(validationFields, function(value) {
           if(_this.state[value] == ""){
             error++;
           }
       });
       if(!reg.test(_this.state.email)){
        error++;
      }
      if(error == 0){
         const Request={
             firstName:this.state.firstName,
             lastName:this.state.lastName,
             email:this.state.email,
             subject: this.state.subject == '' ? 'NA' : this.state.subject,
             message: this.state.message == '' ? 'NA' : this.state.message,
             mail:'contactUs'
         }
 
     this.setState({
         firstName: '',
         lastName: '',
         email: '',
         subject: '',
         message: '',
         color:'',
         errorMessage:'',
        

     })
     
    AppController.showSuccessModal();
     const Response=await NikeServices.serviceRequest(Request)
     console.log(Response)
 }
 else{
     AppController.menuTopNavigation('serviceRequest');
     this.setState({errorMessage:'Please Enter Required Fields and Valid Email',color:'red'})
 }
     
    }
    async onChangeCaptcha(CaptchaValue) {
        await this.setState({CaptchaValue})
      }
    /* It is invoked to return html content */
    render() {
        const metaData = ServerMetaTags.urlString.getMetaData(this.props.match.url);
        return (
            <div>
                <MetaTags 
                metaTitle = {metaData.title}
                metaDesription = {metaData.description}
                metaUrl = {metaData.canonical} />
                <GuestHeader/>
                <div className="cover-tophd-ht company_ht_cover"></div>
                <div className="container-fluid bg-grey">
                    <div className="container">
                        <div className="savesearch form-section text-center" id="serviceRequest">
                            <div className="col-md-4 col-md-offset-4 col-sm-6 col-sm-offset-3">
                            <h2 className="color-red">MEDIA CONTACT
                            <br/>
                            </h2>
                            <h2 className="mrg-btm-30"><img src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Telephone.png"} width="25px"/>
                                {/* <span className="desktop-phone">800.367.9501</span> */}
                                <a href="tel:8003679501" className="mobile-phone">800.367.9501</a>
                            </h2>
                            
                            <p className="text-left" style={{color:this.state.color}}>{this.state.errorMessage}</p>
                           
                            <form role="form" method="post" action="" className="form-horizontal">
                                <div className="form-group row">
                                    <input type="text" className="form-control col-md-5" placeholder="*First Name" value={this.state.firstName} 
                                    onChange={(e) => this.setValue('firstName', e)}/>
                                </div>
                                <div className="form-group row">
                                    <input type="text" className="form-control col-md-5" placeholder="*Last Name" value={this.state.lastName} 
                                    onChange={(e) => this.setValue('lastName', e)}/>
                                </div>
                                <div className="form-group row">
                                    <input type="text" className="form-control col-md-5" placeholder="*Email" value={this.state.email} 
                                    onChange={(e) => this.setValue('email', e)} />
                                </div>
                                <div className="form-group row">
                                    <input type="text" className="form-control col-md-5" placeholder="Subject" value={this.state.subject} 
                                    onChange={(e) => this.setValue('subject', e)}/>
                                </div>
                                <div className="form-group row">
                                    <textarea className="form-control col-md-5" placeholder="Message" value={this.state.message} 
                                    onChange={(e) => this.setValue('message', e)}/>
                                </div>
                                <div className="form-group row">
                                <div className="col-sm-12 col-md-12 over-hide">
                                    <ReCAPTCHA
                                        ref="recaptcha"
                                        sitekey="6Lcbw1AUAAAAAE5K5cYDyhoGu0RJY9C3CLtGFXAo"
                                        onChange={this.onChangeCaptcha}
                                        />
                                </div>
                            </div>
                                <div className="form-group row">
                                    <button className="btn company_btn" type="button" onClick={this.serviceRequest}>SEND</button>
                                </div>
                            </form>
                            </div>
                            <div className="clearfix"></div>
                            <MediaFooter />
                            <div className="clearfix"></div>
                            
                        </div>
                        
                    </div>
                </div>
                <SubFooter />
                <Footer/>
                <SuccessModal/>
                </div>

        );
    }
}
export default MediaContact;