import $ from "jquery";
import OwlCarousel from 'react-owl-carousel';
import AppController from "../../controller/appController";
import MapController from "../../controller/mapController";
import {Link} from 'react-router-dom';
import TravelBookingFooter from '../../common/travelBookingFooter';
import BookNow from './booknow';
import StarbucksBookingHeader from './starbucksBookingHeader';
import TravelBookingApiService from '../../../services/travelBookingApiService';
import moment from 'moment';
import Amenities from "./amenities";
/* OurCommunities Component initialization */
class BookingDetails extends React.Component {
/* Initializing objects of its OurCommunities class */
constructor(props) {
    super(props)
    this.state = {
        community: '',
        communityObject: {},
        appartmentAmenities: '',
        communityAmenities: '',
        communityImages: [],
        walkScore: '0',
        mapId: 0,
        distance: 0,
		travelObj: [],
		status: 'studio'
	}
	this.selectedList = this.selectedList.bind(this);
	this.mapView = this.mapView.bind(this);
	this.streetView = this.streetView.bind(this);
}
/* It is invoked immediately before mounting occurs */
async componentWillMount() {
    $('html, body').animate({
        scrollTop: 0
    });
    AppController.stickyTitles()
    await this.getDetails();
    var communityId = localStorage.getItem('starbuckscommunity_id')
    let pnrId = localStorage.getItem('starbuckspnrId')
    if (pnrId != '' && pnrId != null && pnrId != 'null' && pnrId != undefined && pnrId != 'undefined') {
		const userdetails = {
            pnrid: pnrId
        }
        const ResponseSent = await TravelBookingApiService.GetTravelBookingByPnrId(userdetails)
        await this.setState({
            travelObj: ResponseSent.message.length >=0 ?
                ResponseSent.message[0] :
                []
        })

    }

}

async getDetails() {

    const travelCommunityData = JSON.parse(localStorage.getItem('starbuckstravelCommunityData'))

    // var comImages = (travelCommunityData.compleximages != "" && travelCommunityData.compleximages != undefined && travelCommunityData.compleximages != null && travelCommunityData.compleximages != 'null') ?
    //     travelCommunityData
    //     .compleximages
    //     .split(',') :
    //     ""
    // var intImages = (travelCommunityData.interiorimage != "" && travelCommunityData.interiorimage != undefined && travelCommunityData.interiorimage != null && travelCommunityData.interiorimage != 'null') ?
    //     travelCommunityData
    //     .interiorimage
    //     .split(',') :
    //     ""
    // let concatImage = _.concat(this.state.communityImages, comImages, intImages)
	// concatImage = (travelCommunityData.interiorimage == "" || travelCommunityData.compleximages == "") ?
	// "" :
	// concatImage.filter(v => v != '')

	var commImages;
	if(travelCommunityData.compleximages != null && travelCommunityData.interiorimages != null &&  travelCommunityData.interiorimages != ""){
		 commImages = (travelCommunityData.compleximages+','+travelCommunityData.interiorimages).split(',');
	}else if(travelCommunityData.compleximages != null && (travelCommunityData.interiorimages == null || travelCommunityData.interiorimages == "")){
		 commImages = (travelCommunityData.compleximages).split(',');
	}else if(travelCommunityData.compleximages == null && travelCommunityData.interiorimages != null){
		 commImages = (travelCommunityData.interiorimages).split(',');
	}else{
		 commImages = [];
	}

    const unitamenities = (travelCommunityData.unitamenities != "" && travelCommunityData.unitamenities != undefined && travelCommunityData.unitamenities != null && travelCommunityData.unitamenities != 'null') ?
        travelCommunityData
        .unitamenities
        .split(',') :
        ""
    const complexamenities = (travelCommunityData.complexamenities != "" && travelCommunityData.complexamenities != undefined && travelCommunityData.complexamenities != null && travelCommunityData.complexamenities != 'null') ?
        travelCommunityData
        .complexamenities
        .split(',') :
        ""
    
    await this.setState({
        communityObject: travelCommunityData,
        appartmentAmenities: unitamenities,
        communityAmenities: complexamenities,
        communityImages:commImages
		//  concatImage
	});
	if(travelCommunityData.STU != 0) {
		await this.selectedList('STU',travelCommunityData.sizeSTURate, 'studio');
	} else if(travelCommunityData.Avail1X != 0) {
		await this.selectedList('1X',travelCommunityData.size1xRate, '1bedroom');
	} else if(travelCommunityData.Avail2X != 0) {
		await this.selectedList('2X',travelCommunityData.size2xRate, '2bedroom');
	} else if(travelCommunityData.Avail3X != 0) {
		await this.selectedList('3X',travelCommunityData.size3xRate, '3bedroom');
	}
	
    if (travelCommunityData.latitude == "" || travelCommunityData.latitude == undefined || travelCommunityData.latitude == null || travelCommunityData.longitude == "" || travelCommunityData.longitude == undefined || travelCommunityData.longitude == null) {
        await this.setState({
            walkScore: 0,
            distance: 0
        });
    } else {
        const finalData = {
            address: travelCommunityData.address,
            latitude: travelCommunityData.latitude,
            longitude: travelCommunityData.longitude
        };
        const walkscore = await AppController.wallsckoreFun(finalData);
        await this.setState({
            walkScore: walkscore.walkscore
        });

        const _this = this
        var directionsService = new google.maps.DirectionsService();
        var request = {
            origin: {
                lat: Number(travelCommunityData.latitude),
                lng: Number(travelCommunityData.longitude)
            },
            destination: (localStorage.getItem('starbuckssearchedLocation') != null && localStorage.getItem('starbuckssearchedLocation') != undefined) ?
                localStorage.getItem('starbuckssearchedLocation') :
                '440 Terry Ave N, Seattle, WA 98109',
            travelMode: google.maps.DirectionsTravelMode.DRIVING
        };
        directionsService.route(request, async function(response, status) {
            var distance = response.routes[0].legs[0].distance.text;
            await _this.setState({
                distance: distance
            });
        });
    }
}
async mapView() {
    await this.setState({
        mapId: this.state.mapId + 1
    });
    await this.loadMapData(this.state.communityObject);
}
/* To initialize the google maps data */
async loadMapData(communityObject) {
    await MapController.loadMapData(communityObject, 'singlemark', this, '', 'https://s3-us-west-1.amazonaws.com/suiteam-website/Website-Images/SuiteAmerica-M' +
        'ap-Marker-Red.png');
}
streetView() {
    const _this = this;
    setTimeout(function() {
        MapController.streetView(_this.state.communityObject);
    }, 500);
}				
selectedList(size,rate, status){
	this.setState({status: status})
	var program = localStorage.getItem('starbucksprogram');
	const userLoginEmailEncode=localStorage.getItem('starbuckstravel-email');
	const userLoginEmail=(userLoginEmailEncode !=undefined || userLoginEmailEncode !=null )?'admin':'guest'

	var row=this.state.communityObject
		row['communityroomSelected']=[{BookedType:size,adminDailyRates:rate}]
		this.setState({row})

// 	if((userLoginEmail=='admin'|| userLoginEmail=='guest') && (program=='General' || program=='Apple Travel - 2')){
// 		var row=this.state.communityObject
// 		row['communityroomSelected']=[{BookedType:size,adminDailyRates:rate}]
// 		this.setState({row})
// 	}
// else{
// 	var row=this.state.communityObject
// 	row['communityroomSelected']=[{BookedType:size,adminDailyRates:rate}]
// 	this.setState({row})
// }

}
render() {

	const record_limit = Number(localStorage.getItem('starbucksrecord_limit'))
	const radius = Number(localStorage.getItem('starbucksradius'))
	const community = this.state.communityObject;
	var checkIn = localStorage.getItem('starbuckscheckIn');
	var checkOut = localStorage.getItem('starbuckscheckOut');
	const finalCheckIn = moment(checkIn).format('Do MMMM YYYY');
	const finalCheckOut = moment(checkOut).format('Do MMMM YYYY');
	var pnrId = localStorage.getItem('starbuckspnrId');
	
	var userUrl = (pnrId != undefined && pnrId != null && pnrId != '' && pnrId != 'undefined') ?
		'/starbucks-travel/' + encodeData(pnrId) :
		'/starbucks-travel';
	const distance = this.state.distance != 0 ? this.state.distance.split(' ')[0] :0;								
	const userLoginEmailEncode=localStorage.getItem('starbuckstravel-email');
	const userLoginEmail=(userLoginEmailEncode !=undefined || userLoginEmailEncode !=null )? 'admin':'guest'
	var program = localStorage.getItem('starbucksprogram');
	localStorage.removeItem('starbuckssearchedLocation');
	return (
	<div>
	                   <StarbucksBookingHeader/>
	<div className="fullslider inner tb-back-wrap">
	   <div className="tb-back-button">
		
		  <Link to={userUrl}>
		  <i className="fa fa-chevron-left"></i>
		  <span>Back</span>
		  </Link>

	   </div>
	  
	   <img className="object_cover" src={community.banner_image} alt=""/>
	</div>
	<div className="container travel-sec">
	   <div className="col-md-8">
		  <div className="community-item community-item-full">
			 <div className="community-item-dtls">
				<h5>{community.community_name}</h5>
				<p>{community.address}</p>
				<div className="pull-left text-left">
				   <p className="area">
					  <strong>{community.city_name},</strong>
					  {community.state_name}
				   </p>
				   <p className="distance"><img src="https://s3-us-west-1.amazonaws.com/suiteam-website/Travel-Booking/SuiteAmerica-Travel-Booking-Map.png" alt=""/>
					  <strong>{distance}</strong>Miles away
				   </p>
				</div>
				{(this.state.walkScore != '' && this.state.walkScore != null && this.state.walkScore != undefined) ?  <div className="walkscore pull-right">
				   <div className="arrow_box">{this.state.walkScore}</div>
				   <span>Walk Score</span>
				</div>: null }
			 </div>
			 <div className="clearfix"></div>
			 <div className="clearfix"></div>
			 {userLoginEmail=='admin' && (program=='General' || program=='Apple Travel - 2')?<ul className="bedroom_list list-inline in-dtls">
			 			 {community.STU==0?'':<li>
							<label className="custom_checkbox radio-inline">
								<input type="radio" name="1" value="studio" disabled={community.STU==0?true:false} checked={this.state.status == 'studio' ? true : false} 
								onClick={() => this.selectedList('STU',community.sizeSTURate, 'studio')}/>
								<span className="checkmark"></span>
							</label>
							<img src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/studiobed.png"/> <p>Studio  </p>
							<span className="badge">{community.STU}</span>
							<div className="price">{community.sizeSTURate}</div>
						 </li>}
						 {community.Avail1X==0?'': <li>
						 	<label className="custom_checkbox radio-inline">
								<input type="radio" name="1" value="1bedroom" disabled={community.Avail1X==0?true:false} checked={this.state.status == '1bedroom' ? true : false} 
								onClick={() => this.selectedList('1X',community.size1xRate, '1bedroom')}/>
								<span className="checkmark"></span>
							</label>
							 <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/singlebedroom.png" /> <p>1 Bedroom</p>
							 <span className="badge">{community.Avail1X}</span>
							 <div className="price">{community.size1xRate}</div>
						</li>}
						{community.Avail2X==0?'':<li>
						 	<label className="custom_checkbox radio-inline">
								<input type="radio" name="1" value="2bedroom" disabled={community.Avail2X==0?true:false} checked={this.state.status == '2bedroom' ? true : false} 
								onClick={() => this.selectedList('2X',community.size2xRate, '2bedroom')}/>
								<span className="checkmark"></span>
							</label>
							 <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/doublebedroom.png" /> <p>2 Bedroom</p>
							 <span className="badge">{community.Avail2X}</span>
							 <div className="price">{community.size2xRate}</div>
						</li>}
                        {community.Avail3X==0?'': <li>
						 	<label className="custom_checkbox radio-inline">
								<input type="radio" name="1" value="3bedroom" disabled={community.Avail3X==0?true:false} checked={this.state.status == '3bedroom' ? true : false} 
								onClick={() => this.selectedList('3X',community.size3xRate, '3bedroom')}/>
								<span className="checkmark"></span>
							</label>
							 <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/triplebedroom.png" /> <p>3 Bedroom</p>
						 	 <span className="badge">{community.Avail3X}</span>
						 	 <div className="price">{community.size3xRate}</div>
						</li>}
                    </ul>:userLoginEmail=='admin'?<ul className="bedroom_list list-inline">
					{community.STU==0?'':<li ><img src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/studiobed.png"/> <p>Studio  </p><span className="badge studio">{community.STU}</span></li>}
					{community.Avail1X==0?'':<li><img src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/singlebedroom.png" /> <p>1 Bedroom</p><span className="badge">{community.Avail1X}</span></li>}
					{community.Avail2X==0?'':<li><img src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/doublebedroom.png" /> <p>2 Bedroom</p><span className="badge">{community.Avail2X}</span></li>}
					{community.Avail3X==0?'':<li><img src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/triplebedroom.png" /> <p>3 Bedroom</p><span className="badge">{community.Avail3X}</span></li>}
			  </ul>:''}
		  </div>
		  <div className="community-tabs">
			 <ul className="nav nav-tabs" role="tablist">
				<li role="presentation" className="active">
				   <a href="#one" aria-controls="home" role="tab" data-toggle="tab"><img src="" alt=""/>
				   <i className="icon icon-454"></i>Photos</a>
				</li>
				<li role="presentation" onClick={this.mapView}>
				   <a href="#two" aria-controls="profile" role="tab" data-toggle="tab">
				   <i className="icon icon-432"></i>Map</a>
				</li>
				<li role="presentation" onClick={this.streetView}>
				   <a href="#three" aria-controls="messages" role="tab" data-toggle="tab">
				   <i className="icon icon-425"></i>Street view</a>
				</li>
			 </ul>
			 <div className="tab-content travel-booking-slider">
				<div role="tabpanel" className="tab-pane active" id="one">
				{this.state.communityImages.length > 0
					? <OwlCarousel
				     ref={inst => this.slider = inst}
					 {...{ loop: false, 
					       nav:true, 
						   dots: true, 
						   items: 1, 
						   navContainerClass: 'owl-buttons', 
						   navText: ["<img src='https://s3-us-west-1.amazonaws.com/suiteam-website/Website-Images/SuiteAmerica-Carousel-Left-Arrow.png'>", "<img src='https://s3-us-west-1.amazonaws.com/suiteam-website/Website-Images/SuiteAmerica-Carousel-Right-Arrow.png'>"], responsive:{ 320:{ items:1, nav:true, loop:false }, 767:{ items:1, nav:true, loop:false }, 992:{ items:1, nav:true, loop:false }, 1199:{ items:1, nav:true, loop:false } }}}>
						{this.state.communityImages.map((row, index) => 
						{
						return <div className="item" key={index}><img className="img-responsive" src={row} alt={row}/></div>
						})}
				   </OwlCarousel>
				   : null}
				</div>
				<div role="tabpanel" className="tab-pane" id="two">
				   <div className="lc_map">
					  <div className="map-alignment">
						 <div id="map" className="map-alignment"></div>
					  </div>
				   </div>
				</div>
				<div role="tabpanel" className="tab-pane" id="three">
				   <div className="lc_map">
					  <div className="map-alignment">
						 <div id="street-view" className="map-alignment"></div>
					  </div>
				   </div>
				</div>
			 </div>
		  </div>
	   </div>
	   <div className="col-md-4">
		  <h4 className="widget-title">Booking Information</h4>
		  <div className="widget">
			 <div className="widget_body">
				<ul className="booking_info_list">
				   <li>
					  <span>Check In</span>
					  <h5>{finalCheckIn}</h5>
				   </li>
				   <li>
					  <span>Check Out</span>
					  <h5>{finalCheckOut}</h5>
				   </li>
				</ul>
				<Link to="#">
				{this.state.travelObj.length == 0 && (localStorage.getItem('starbuckstravel-email') == undefined || localStorage.getItem('starbuckstravel-email') == null)
				? <span><i className="fa fa-spinner fa-spin"/>
				Please wait ...!</span>
				: <button
				   data-toggle="modal"
				   data-target="#travelbooknow"
				   className="btn btn-block btn-primary">Book Now</button>}
				</Link>
			 </div>
		  </div>
		  <h4 className="widget-title">Amenities</h4>
		  <Amenities {...this.state}/>
	   </div>
	</div>
	<TravelBookingFooter/>
	<BookNow communityObject={this.state.communityObject} travelObj={this.state.travelObj}/>
 </div>
	   );

	}
}

export default BookingDetails

function encodeData(data) {
				let encodeString = data
				for (var i = 0; i < 10; i++) {
								encodeString = Base64.encode(encodeString)
				}
				return encodeString
}