import React, { useState } from "react";
import nikeService from "../../../services/nikeService";
import GuestHeader from "../../common/guestHeader";
import BlogHeader from "../newinterface/blogHeader";
import OnDemandFooter from "./common/onDemandFooter";
import Footer from "../newinterface/footer";

function OnDemand() {
  const [state, setState] = useState({
    fullName: "",
    emailId: "",
    desiredLocation: "",
    totalMonthlyBudget: "",
    phoneNo: "",
    aggered: false,
    loader: false,
  });

  const handleKeyPress = (e) => {
    const key = e.key;
    const regex = /[0-9.]/; // Allow numeric digits and a period (dot)

    if (e.keyCode === 8 || regex.test(key)) {
      return;
    }
    e.preventDefault();
  };
  const inputHandler = (value, type) => {
    // console.log(value, type, "value,type");

    if (type === "emailId") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidEmail = emailRegex.test(value);
      if (!isValidEmail) {
        $(`#${type}`).addClass("is-invalid");
      } else {
        $(`#${type}`).removeClass("is-invalid");
      }
    } else if (type === "phoneNo") {
      const phoneRegex = /^\d{0,10}$/;
      const isValidPhone = phoneRegex.test(value);
      if (!isValidPhone) {
        $(`#${type}`).addClass("is-invalid");
      } else {
        $(`#${type}`).removeClass("is-invalid");
      }
    }

    setState((prev) => ({ ...prev, [type]: value }));
  };

  const setLocation = async (e) => {
    e.persist();
    const locationValue = e.target.value;

    setState((prev) => ({ ...prev, desiredLocation: locationValue }));
    var places = new google.maps.places.Autocomplete(
      document.getElementById("desiredLocation")
    );
    google.maps.event.addListener(places, "place_changed", function () {
      var place = places.getPlace();
      var placeDetails = {
        locality: "long_name",
        administrative_area_level_1: "short_name",
        country: "long_name",
      };
      place.address_components.forEach((i) => {
        var addressType = i.types[0];
        if (placeDetails[addressType]) {
          var val = i[placeDetails[addressType]];
          placeDetails[addressType] = val;
        }
      });
      let relocatedAddress = place.formatted_address;
      setState((prev) => ({
        ...prev,
        desiredLocation: relocatedAddress,
      }));
    });
  };

  const submitBtn = async () => {
    setState((prev) => ({ ...prev, loader: true }));
    const {
      fullName,
      emailId,
      desiredLocation,
      totalMonthlyBudget,
      phoneNo,
      aggered,
    } = state;
    let isValid = 0;
    $(".is-invalid").removeClass("is-invalid");
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (
      fullName == "" ||
      emailId == "" ||
      phoneNo == "" ||
      aggered == false ||
      !emailRegex.test(emailId)
    ) {
      if (fullName == "") {
        $("#fullName").addClass("is-invalid");
        isValid++;
        setState((prev) => ({ ...prev, loader: false }));
      } else {
        $("#fullName").removeClass("is-invalid");
      }
      if (emailId == "" || !emailRegex.test(emailId)) {
        $("#emailId").addClass("is-invalid");
        isValid++;
        setState((prev) => ({ ...prev, loader: false }));
      }
      if (phoneNo == "") {
        $("#phoneNo").addClass("is-invalid");
        isValid++;
        setState((prev) => ({ ...prev, loader: false }));
      }
      if (aggered == false) {
        $("#aggered").addClass("is-invalid");
        isValid++;
        setState((prev) => ({ ...prev, loader: false }));
      }
    }
    if (isValid == 0) {
      const data = {
        fullName: fullName,
        email: emailId,
        desiredLocation: desiredLocation,
        phoneNo: phoneNo,
        totalMonthlyBudget: totalMonthlyBudget,
        aggered: aggered,
        mail: "DemandOfDestination",
        subject: "Demand of Destination",
      };
      await nikeService.serviceRequest(data);
      //   console.log(data, "data");
      setState({
        fullName: "",
        emailId: "",
        desiredLocation: "",
        totalMonthlyBudget: "",
        phoneNo: "",
        aggered: false,
        loader: false,
      });
      $(".is-invalid").removeClass("is-invalid");
    }
  };
  return (
    <div className="SAweb_updateUi ">
      <BlogHeader />
      {/* <GuestHeader /> */}

      <div className="onDemandView">
        <div className="bannerImageBg">
          <div className="item">
            <form onSubmit={(e) => e.preventDefault()}>
              <h4>What's your destination?*</h4>
              <p>Contact us to find your perfect, furnished home</p>
              <p>
                * Only available in the <b> United States</b>
              </p>
              <div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Full Name"
                    value={state.fullName}
                    id="fullName"
                    onChange={(e) => inputHandler(e.target.value, "fullName")}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Email"
                    value={state.emailId}
                    id="emailId"
                    onChange={(e) => inputHandler(e.target.value, "emailId")}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="desiredLocation"
                    placeholder="Desired US Destination"
                    onChange={(e) => setLocation(e)}
                    value={state.desiredLocation}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Phone Number"
                    value={state.phoneNo}
                    onKeyDown={handleKeyPress}
                    id="phoneNo"
                    maxLength={10}
                    onChange={(e) => inputHandler(e.target.value, "phoneNo")}
                  />
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Total Monthly Budget"
                    value={state.totalMonthlyBudget}
                    onKeyDown={handleKeyPress}
                    id="totalMonthlyBudget"
                    onChange={(e) =>
                      inputHandler(e.target.value, "totalMonthlyBudget")
                    }
                  />
                </div>

                <div className="privacy">
                  <label class="customCheck">
                    {"  "} I don't want to receive marketing emails from
                    Blueground. I can also opt out of receiving these at any
                    time via the link in the marketing emails. Read our 
                    <a href="#">Privacy Policy.</a>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={state.aggered}
                      id="aggered"
                      onChange={(e) =>
                        inputHandler(e.target.checked, "aggered")
                      }
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>

                {state.loader ? (
                  <div
                    className="text-center"
                    style={{
                      fontSize: "20px",
                    }}
                  >
                    <i className="fa fa-spinner fa-spin" />
                    Please wait ...!
                  </div>
                ) : (
                  <button
                    type="submit"
                    className="btn black-btn"
                    onClick={() => submitBtn()}
                  >
                    Contact us
                  </button>
                )}
              </div>
            </form>
          </div>
          <div className="container ">
            <div className="bannerView">
              <h2>Stay your way. Anywhere.</h2>
              <p>
                Save money, time, and hassle with flexible, on-demand housing.
              </p>
            </div>
          </div>
        </div>
        <OnDemandFooter />
        <Footer />
      </div>
    </div>
  );
}

export default OnDemand;
