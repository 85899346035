import React from "react";
import Footer from "./footer";
import Header from "./header";
import OwlCarousel from "react-owl-carousel";
import RequestCallback from "./requestCallback";

class GuestExperience extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
  }
  handleClick() {
    $("#sideQuote").addClass("active");
    $(".overlay").addClass("active");
    $(".collapse.in").toggleClass("in");
    $("a[aria-expanded=true]").attr("aria-expanded", "false");
  }
  closed() {
    $("#sideQuote").removeClass("active");
    $(".overlay").removeClass("active");
  }
  show3dView() {
    jQuery(
      function ($) {
        $("#threevideoView").modal("show");
      }.bind(this)
    );
  }
  render() {
    return (
      <div className="SAweb_updateUi">
        <Header />

        <div className="">
          <section className="SAweb_guesrExpBanner">
            <div className="textView">
              <h2>Our stays are custom-styled to make you feel at home</h2>
              <p className="p">
                With SuiteAmerica, you don’t move into just another corporate
                accommodation. You move into a home you can call yours.
              </p>
              <a href="/locations" className="btn" type="button">
                Check Out Our Stays
              </a>
            </div>
            <div className="bannerSection">
              <div className="flex-slide home"></div>

              <div className="flex-slide about"></div>
              <div className="flex-slide work"></div>
            </div>
          </section>
          <section className="SAweb_guesrExpBannerBottom">
            <div className="container">
              <h2>What Makes Us Stand Apart</h2>
              <div className="tails" data-aos="fade-up">
                <div className="item">
                  <div className="pic">
                    <svg className="animated-check" viewBox="0 0 24 24">
                      <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" />
                    </svg>
                  </div>

                  <p>
                    {" "}
                    <span>Award-winning Service </span>We employ a team of
                    experienced and caring industry experts.
                  </p>
                </div>
                <div className="item">
                  <div className="pic">
                    <svg className="animated-check" viewBox="0 0 24 24">
                      <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" />
                    </svg>
                  </div>

                  <p>
                    {" "}
                    <span>The Basics, and a Lot More</span>From linens and
                    housewares to luxury and high-end, we go above and beyond to
                    deliver an exceptional experience that fits your budget and
                    desires.
                  </p>
                </div>
                <div className="item">
                  <div className="pic">
                    <svg className="animated-check" viewBox="0 0 24 24">
                      <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" />
                    </svg>
                  </div>

                  <p>
                    {" "}
                    <span>Premiere Locations</span>Choose from accommodations in
                    the best locations.
                  </p>
                </div>
                <div className="item">
                  <div className="pic">
                    <svg className="animated-check" viewBox="0 0 24 24">
                      <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" />
                    </svg>
                  </div>
                  <p>
                    {" "}
                    <span>Always connected</span> All our guests enjoy
                    super-fast Wi-Fi throughout their stay.
                  </p>
                </div>
                <div className="item">
                  <div className="pic">
                    <svg className="animated-check" viewBox="0 0 24 24">
                      <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" />
                    </svg>
                  </div>

                  <p>
                    {" "}
                    <span>Seamless check-ins</span> Our uber focus on a perfect
                    arrival experience sets you up for a great stay.
                  </p>
                </div>
                <div className="item">
                  <div className="pic">
                    <svg className="animated-check" viewBox="0 0 24 24">
                      <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" />
                    </svg>
                  </div>
                  <p>
                    {" "}
                    <span>Round-the-clock support</span> Got requests or
                    queries? Our team is just a tap away
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section className="SAweb_guestExpCustomSearch">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-5">
                  <h2>Search. Customize. Book. Stay</h2>
                  <p className="p">
                    Explore from thousands of stays, personalize your
                    preferences, and make your booking, all in one go.
                  </p>
                  <p className="p">
                    <b>Come, stay the SuiteAmerica way.</b>
                  </p>

                  <button
                    type="button"
                    onClick={this.handleClick}
                    className="btn"
                  >
                    {" "}
                    Reserve Now{" "}
                  </button>
                </div>

                <div className="col-md-7">
                  <div className="tailBox">
                    <div className="item">
                      <span>1</span>
                      <div>
                        <h4>Search for Stays</h4>
                        <p>
                          Delve into our expansive inventory and pick a stay
                          that best suits your lifestyle.
                        </p>
                      </div>
                    </div>
                    <div className="item">
                      <span>2</span>
                      <div>
                        <h4>Customize Your Way</h4>
                        <p>
                          Tailor your stay to make the most of your experience.
                        </p>
                      </div>
                    </div>
                    <div className="item">
                      <span>3</span>
                      <div>
                        <h4>Book Instantly</h4>
                        <p>Book your stay within 30 seconds or less.</p>
                      </div>
                    </div>
                    <div className="item">
                      <span>4</span>
                      <div>
                        <h4>Stay</h4>
                        <p>Reside comfortably in a home you can call yours.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="SAweb_guestExpExpectations">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-4">
                  <h2>Designed to exceed expectations</h2>
                </div>
                <div className="col-md-8">
                  <div className="tailBox">
                    <div className="item">
                      <div className="pic">
                        {" "}
                        <img
                          src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/GuestExp/expectationsPic-1.jpg"
                          alt=""
                        />{" "}
                      </div>
                      <h4>Fully Furnished Modern Living</h4>
                      <p>
                        Need a vacuum cleaner and microwave? We got that. Need
                        Bluetooth speakers and a special armchair? We got that
                        too!
                      </p>
                    </div>
                    <div className="item">
                      <div className="pic">
                        {" "}
                        <img
                          src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/GuestExp/expectationsPic-2.jpg"
                          alt=""
                        />{" "}
                      </div>
                      <h4>Comfortable Stays in a Variety of Locations</h4>
                      <p>
                        Our vast inventory allows you to pick stays of your
                        choice from top neighborhoods in the U.S.
                      </p>
                    </div>
                    <div className="item">
                      <div className="pic">
                        {" "}
                        <img
                          src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/GuestExp/expectationsPic-3.jpg"
                          alt=""
                        />{" "}
                      </div>
                      <h4>Technology at Your Fingertips</h4>
                      <p>
                        Online guest portal and mobile app to access information
                        and request services with a few clicks.
                      </p>
                    </div>
                    <div className="item">
                      <div className="pic">
                        {" "}
                        <img
                          src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/GuestExp/expectationsPic-4.jpg"
                          alt=""
                        />{" "}
                      </div>
                      <h4>Award-Winning Experience</h4>
                      <p>
                        Our caring and responsive Guest Experience Teams work to
                        ensure the perfect stay, every time.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="SAweb_guestExpTech">
            <div className="item">
              <h2>Technology that keeps you in the loop</h2>
              <p className="p">
                Stay connected and gain real-time insights on the go with our
                tech-driven tools. Whether you want to check the details of your
                stay or need a care package for your pet, everything is
                structured coherently to make your stay convenient.
              </p>
              <a href="/guest-login" className="btn mrg-btm-20">
                Guest Login
              </a>
              <div>
                <a
                  href="https://apps.apple.com/app/guestportal/id1492423942"
                  target="_blank"
                >
                  <img
                    className="guest-app-store-btn"
                    src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/Download_on_the_App_Store_Badge_US-UK_RGB_wht_092917.svg"
                    alt="app store"
                  ></img>
                </a>{" "}<a
                  href="https://play.google.com/store/apps/details?id=com.suiteamerica.guestportal&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                  target="_blank"
                >
                  <img
                    className="guest-app-store-btn"
                    src="/assets/newUI-images/google-play-store-badge-w.svg"
                    alt="Play store"
                  ></img>
                </a>
              </div>
            </div>
            <div className="item">
              <img
                src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/GuestExp/image-2.jpg"
                alt=""
                width="100%"
              />
            </div>
          </section>

          <section className="SAweb_chInfo  SAweb_guestExp">
            <div className="container">
              <h2 className="my-5 pt-5 pb-3  text-center" data-aos="fade-up">
                Stay, the SuiteAmerica Way
              </h2>
              <div className="statBox pb-5" data-aos="fade-up">
                <div className="item">
                  <div className="image">
                    <img
                      src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/stayIcons/workout.png"
                      alt=""
                      width="50px"
                    />{" "}
                  </div>
                  <h5>The Hustle Bubble</h5>
                  <p>
                    A fully-equipped fitness center to keep you on top of your
                    game
                  </p>
                </div>
                <div className="item">
                  <div className="image">
                    <img
                      src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/stayIcons/house.png"
                      alt=""
                      width="40px"
                    />
                  </div>
                  <h5>Parking Stations</h5>
                  <p>Reserved parking spaces to rest your lean-mean machines</p>
                </div>
                <div className="item">
                  <div className="image">
                    <img
                      src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/stayIcons/pool.png"
                      alt=""
                      width="50px"
                    />{" "}
                  </div>
                  <h5>Pool of Tranquility</h5>
                  <p>
                    The perfect place to wash off all the stresses of your day
                  </p>
                </div>
                <div className="item">
                  <div className="image">
                    <img
                      src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/stayIcons/wifi.png"
                      alt=""
                      width="40px"
                    />
                  </div>

                  <h5>Connection Bay</h5>
                  <p>Hi-speed Wi-Fi that keeps you connected at all times</p>
                </div>
              </div>
            </div>
          </section>

          <section className="bg-white">
            <div className="container py-5" data-aos="fade-up">
              <div className="video3dViewText">
                <h2 className="text-center m-auto my-5 pt-1">
                  Take a 3D tour of your new abode
                </h2>
              </div>
              <div className="SAweb_video mb-5">
                <img
                  src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/videoImage.jpg"
                  alt=""
                  width="100%"
                />
                <a href="#" onClick={this.show3dView}>
                  <i className="bi bi-play-circle"></i>
                </a>
              </div>
            </div>
          </section>

          <section className=" SAweb_guestExpSlider pt-5" data-aos="fade-up">
            <div className="container SAweb_owl-slider2  py-1">
              <h2 className=" pt-3">See What Our Guests Have To Say</h2>
              <div className="owl-slider SAweb_sliderNav my-5 pb-5">
                <div className=" SAweb_Sliderdots">
                  <OwlCarousel
                    className="owl-theme owl-carousel"
                    {...{
                      loop: false,
                      margin: 20,
                      nav: false,
                      dots: true,
                      items: 1,
                      autoplay: false,
                      navContainerClass: "owl-buttons",
                      navText: [
                        "<i className='glyphicon glyphicon-menu-left'></i>",
                        "<i className='glyphicon glyphicon-menu-right'></i>",
                      ],
                      responsive: {
                        300: { items: 1 },
                        767: { items: 2 },
                        992: { items: 3 },
                      },
                    }}
                  >
                    <div className="item">
                      <div className="caption">
                        <i className="bi-quote-image black-quote-image"></i>
                        <p>
                          Thanks for making our stay a memorable one! We really
                          liked the whole apartment and the setup and the
                          attention to details to make our stay comfortable! We
                          loved it!!
                        </p>
                        <h3>Haider, Cupertino </h3>
                        <i className="bi-quote-image black-quote-image"></i>
                      </div>
                    </div>
                    <div className="item">
                      <div className="caption">
                        <i className="bi-quote-image border-quote-image"></i>

                        <p>
                          Provided a nice apartment in a good locality with all
                          necessary furnitures and equipments. These apartments
                          are quite suitable for comfortable stay. I recommend
                          it strongly.
                        </p>
                        <h3>Dhiraj, Sunnyvale </h3>
                        <i className="bi-quote-image border-quote-image"></i>
                      </div>
                    </div>
                    <div className="item">
                      <div className="caption">
                        <i className="bi-quote-image black-quote-image"></i>

                        <p>
                          My landing into the apartment was smooth and pleasant.
                          My family liked it. Thank you.
                        </p>
                        <h3>Yaroslav, Mountain View </h3>
                        <i className="bi-quote-image black-quote-image"></i>
                      </div>
                    </div>
                    <div className="item">
                      <div className="caption">
                        <i className="bi-quote-image border-quote-image"></i>
                        <p>
                          Thank you for your attention to details. We really
                          enjoyed the set up and start up kit. In addition, the
                          toys for our 15-month old boy. Thank you!
                        </p>
                        <h3>Yuan, Hillsboro </h3>
                        <i className="bi-quote-image border-quote-image"></i>
                      </div>
                    </div>
                    <div className="item">
                      <div className="caption">
                        <i className="bi-quote-image black-quote-image"></i>
                        <p>
                          Extremely smooth move in process and very thoughtful
                          touches. Our dogs absolutely love the dog beds{" "}
                        </p>
                        <h3>Diana, Hillsboro </h3>
                        <i className="bi-quote-image black-quote-image"></i>
                      </div>
                    </div>
                    <div className="item">
                      <div className="caption">
                        <i className="bi-quote-image border-quote-image"></i>
                        <p>
                          The apartment is very nice and has room for our
                          family. It was very nice of you to supply so many
                          goodies, not just for us, but also for the dog.{" "}
                        </p>
                        <h3>Richard, San Diego </h3>
                        <i className="bi-quote-image border-quote-image"></i>
                      </div>
                    </div>
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </section>
        </div>
        <RequestCallback />
        <div
          className="modal modal-fullscreen-xl modal fade animated tab-modal guest-Ui SAweb_modalPoup"
          id="threevideoView"
          tabIndex={-1}
          data-backdrop="static"
          data-keyboard="false"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content newSRView">
              <div className="servicerequest-pop-header modal-header border-0">
                <button
                  type="button"
                  className="btn close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="bi bi-x"></i>
                </button>
              </div>
              <div className="modal-body">
                <iframe
                  src={"https://my.matterport.com/show/?m=iLeosSyv3Bn"}
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  allowFullScreen
                />
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default GuestExperience;
