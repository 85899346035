const FirstRowFields = props => {   
const row= props.rowData
const manager=props.manager
return ( row.OrderType=='H'? <HotelOrder rowData={row} manager={manager}/>: <OrderContent rowData={row} manager={manager}/>)
}
export default FirstRowFields;
const HotelOrder= props => {
  const row= props.rowData
  return (<div className="col-sm-4"> 
  <div className="sc-order-fl-item">
 {props.manager? <ul className="mrg-btm-20">
   <li><span className="font-bold" style={{color:'#dd2a5a'}}>Start Time :</span> <span>  {row.FivestarStartDateTime == undefined || row.FivestarStartDateTime == null ?'--':moment.utc(row.FivestarStartDateTime).format('YYYY-MM-DD hh:mm:ss A')}</span></li>
   </ul>:null}
  <ul className="mrg-btm-20">
      <li><span className="font-bold">{row.TypeCode}</span></li>
    </ul>
    
  <ul className="mrg-btm-20">
    <li><span className="font-bold">Hotel :</span> <span>  {row.HotelName}</span></li>
  </ul>
  <ul className="mrg-btm-20">
    <li><span className="font-bold">SuiteNo :</span> <span>  {row.hotelsuite!=null? row.hotelsuite:'---'}</span></li>
  </ul>     
  <ul className="mrg-btm-20">
      <li><span className="font-bold">Address :</span></li>
      <li>{row.hoteladdress} {row.hoteladdress !=''? ',':null}</li>
      <li>{row.hotelstate} {row.hotelstate !=''? ',':null}</li>
      <li>{row.hotelcity} {row.hotelcity !=''? ',':null}</li>
      <li>{row.hotelzipcode}</li>
    </ul>
  
  </div>
  </div>)
}

const OrderContent= props => {
  const row= props.rowData
  return (<div className="col-sm-4">
  <div className="sc-order-fl-item">
  {props.manager? <ul className="mrg-btm-20">
  <li><span className="font-bold" style={{color:'#dd2a5a'}}>Start Time :</span> <span>  {row.FivestarStartDateTime == undefined || row.FivestarStartDateTime == null ?'--':moment.utc(row.FivestarStartDateTime).format('YYYY-MM-DD hh:mm:ss A')}</span></li>
  </ul>:null}
    <ul className="mrg-btm-20">
      <li><span className="font-bold">{row.TypeCode} {row.SuiteSizeCode}</span></li>
    </ul>
    <ul className="mrg-btm-20">
    <li><span className="font-bold">Community :</span> <span>  {row.community_name}</span></li>
    
  </ul>
  <ul className="mrg-btm-20">
  <li><span className="font-bold">AfterHoursNo :</span> <span>  {row.AfterHoursPhone == undefined || row.AfterHoursPhone == null ? '--':row.AfterHoursPhone}</span></li>
  </ul>
  <ul className="mrg-btm-20">
    <li><span className="font-bold">SuiteNo :</span> <span>  {row.SuiteNo}</span></li>
  </ul>
    <ul className="mrg-btm-20">
      <li><span className="font-bold">Address :</span></li>
      <li>{row.address} {row.address !=''? ',':null}</li>
      <li>{row.state_name} {row.state_name !=''? ',':null}</li>
      <li>{row.city_name} {row.city_name !=''? ',':null}</li>
      <li>{row.ZipCode}</li>
    </ul>
    <ul className="mrg-btm-20">
                <li><span className="font-bold">Suite Address :</span></li>
                <li>{row.AptAddr == undefined || row.AptAddr == null ? '--': row.AptAddr} {row.AptAddr !=''? ',':null}</li>
                <li>{row.LeaseState == undefined || row.LeaseState == null ? '--': row.LeaseState} {row.LeaseState !=''? ',':null}</li>
                <li>{row.LeaseCity == undefined || row.LeaseCity == null ? '--': row.LeaseCity} {row.LeaseCity !=''? ',':null}</li>
                <li>{row.AptZip == undefined || row.AptZip == null ? '--': row.AptZip}</li>
    </ul>
    <ul className="mrg-btm-20">
    <li><span className="font-bold">Mail Box :</span> <span>  {row.MailBox == undefined || row.MailBox == null ?'--':row.MailBox}</span></li>
  </ul>
  </div>
  </div> )
}

