/* Importing the node modules, child components, services and controllers used 
   inside NikeLocations component */
import MainController from "../../controller/mainController";
import SubFooter from "../../common/subFooter";
import WellsfargoInternsHeader from "./common/WellsfargoInternsHeader";
import Location from "../location/locations";
import FAQ from "./common/WellsfargoInternsFaq";
import Footer from "../../common/footer";
import Disclaimer from "./common/disclimar";
import SubFooterWellsfargo from "./common/subFooterWellsfargo";
/* NikeLocations Component initialization */
class WellsfargoInternsLocations extends React.Component {
  /* Initializing objects of its NikeLocations class */
  constructor(props) {
    super(props);
    this.state = {
      companyname: "Abc",
    };
  }
  /* It is invoked immediately before mounting occurs */
  componentWillMount() {
    MainController.getInitialInformation();
  }
  /* It is invoked to return html content */
  render() {
    return (
      <div className="corporateHousing_Updates">
        <WellsfargoInternsHeader />
        <div className="quote-section bg-white bottomSpaceHide">
          <h2>Sample Locations</h2>
        </div>
        <Location companyName={this.state.companyname} props={this.props} />
        <Disclaimer />
        {/* <SubFooter /> */}
        <SubFooterWellsfargo />
        <Footer />
      </div>
    );
  }
}
export default WellsfargoInternsLocations;
