/* Importing the node modules, child components, services and controllers used 
   inside CorporateStudentForm component */    
   import DatePicker from 'react-datepicker'
   import moment from 'moment'
   import AppController from "../../controller/appController";
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import NikeServices from '../../../services/nikeService';
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   import ReCAPTCHA from "react-google-recaptcha"
   import SuccessModal from '../../common/successModal';
   /* CorporateStudentForm Component initialization */
   class CorporateStudentForm extends React.Component {
       /* Initializing objects of its CorporateStudentForm class */
       constructor(props) {
           super(props)
           this.state={
              fullName: '',
              emailaddress: '',
              phoneNumber: '',
              moveInDate:  moment(),
              moveOutDate: moment(),
              genderList:['Male','FeMale'],
              sleepHabitsList:['Early Bird','Night Owl'],
              smokeList: ['Yes','No'],
              vehicleList: ['Yes','No'],
              preferesLocationList: ['Herndon, VA @ $39.00/night per student','Tysons Corner, VA @ $43.00/night per student'],
              gender:'',
              sleepHabbit: '',
              smoke: '',
              vehicle: '',
              preferedLocation: '',
              comments: ''        
           }
           this.setValue = this.setValue.bind(this);
           this.serviceRequest = this.serviceRequest.bind(this);
           this.handleMoveIn = this.handleMoveIn.bind(this);
           this.handleMoveOut = this.handleMoveOut.bind(this);
           this.onChangeCaptcha = this.onChangeCaptcha.bind(this);
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
           MainController.getInitialInformation();
        }
        /* Form Details object creations  */
      setValue(field, e) {
          this.setState({ errorMessage:' ',color: ''  })
            var object = {};
            object[field] = e.target.value;
            this.setState(object);
          }
      /* Form Details object creations  */
      async handleMoveOut(date){
        await this.setState({moveOutDate: moment(date),
            deliveryDateSelected:date.format('YYYY-MM-DD HH:mm:ss') });
    }
    async handleMoveIn(date){
        await this.setState({moveInDate: moment(date),
            moveInDateSelected:date.format('YYYY-MM-DD HH:mm:ss') });
    }
       /* Service Request Details send to mail  */
       async serviceRequest(){
           const _this = this;
        const validationFields=['CaptchaValue','fullName','emailaddress','phoneNumber','moveInDate','moveOutDate']
        let error = 0;
        _.forEach(validationFields, function(value) {
           if(_this.state[value] == ""){
             error++;
           }
       });
      if(error == 0){
            const Request={
               CaptchaValue:this.state.CaptchaValue,
               fullName: this.state.fullName,
               emailaddress: this.state.emailaddress,
               phoneNumber: this.state.phoneNumber,
               moveInDate: this.state.moveInDate.format('MM-DD-YYYY'),
               moveOutDate: this.state.moveOutDate.format('MM-DD-YYYY'),
               gender: this.state.gender == '' ? '---' : this.state.gender,
               sleepHabbit: this.state.sleepHabbit == '' ?'---' : this.state.sleepHabbit,
               smoke: this.state.smoke == '' ? '---' : this.state.smoke,
               vehicle: this.state.vehicle == '' ? '---' : this.state.vehicle,
               preferedLocation: this.state.preferedLocation == '' ? '---' : this.state.preferedLocation,
               mail:'corporateform',
               subject:'Corporate Student Program-2019 Reservation Request',
               comments: this.state.comments == '' ? '---' : this.state.comments,
            }    
        this.setState({
               CaptchaValue:'',
               fullName: '',
               emailaddress:'',
               phoneNumber: '',
               moveInDate:  moment(),
               moveOutDate: moment(),
               gender: '',
               sleepHabbit: '',
               smoke: '',
               vehicle: '',
               preferedLocation: '',
            color:'',
            errorMessage:'',   
            comments: ''  
        })
        
        jQuery(function($) {
            $('#successModal').modal('show');
            setTimeout(function(){
              $('#successModal').modal('hide');
            },4000);
          });
       await NikeServices.serviceRequest(Request) 
        }
        else{
            AppController.menuTopNavigation('serviceRequest');
            this.setState({errorMessage:'Please complete the required fields & Select Captcha',color:'red'})
        }
       }      
       async onChangeCaptcha(CaptchaValue) {
        await this.setState({CaptchaValue})
        }
       /* It is invoked to return html content */
       render() {
           return (
            <div className="corporateHousing_Updates" id="serviceRequest">
                <GuestHeader/>
                <div className="cover-tophd-ht"></div>
                <div className="container-fluid nike-datepicker bg-grey">
                    <div className="row">
                        <div className="container">
                            <div className="pts-smg-wrap">
                            <h1 className=""><i>Corporate Student Program-2019 <br/> Reservation Request</i></h1>
                            <div style={{textAlign:'right'}}>
                            <p>Are you ready to book your stay with us?<br/>Fill out the form below to request a reservation. Once you submit your request, you will receive an email confirmation that the request was received. Your request will be processed, and you will hear from us by phone or email within 2 business hours to confirm your reservation and to clear up any questions.

</p>
                           
                            </div></div>
                            <div className="service_request">
                            <div className="request_form company_form" >
                                <form className="well">
                                    <span>Personal Information:</span>
                                    <div className="text-center mrg-btm-20" style={{color:this.state.color}}>{this.state.errorMessage}</div>
                                    <div className="row">
                                        <div className="col-sm-6 col-md-6">
                                        <span style={{color: 'red'}}>*</span> First Name 
                                        <input type="text" className="form-control" placeholder="First Name" value={this.state.fullName} 
                                        onChange={(e) => this.setValue('fullName', e)}/>
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                        Gender
                                        <label className="custom-select">
                                                    <select value={this.state.gender} 
                                                    onChange={(e) => this.setValue('gender', e)}>                                        
                                                    <option value='' disabled>Make Selection</option>
                                                    {this.state.genderList.map((row, index) => {
                                                    return(
                                                    <option key={index} value={row}>{row}</option>
                                                    )
                                                    })}
                                                    </select>
                                                </label>
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                        <span style={{color: 'red'}}>*</span> E-mail Address
                                        <input type="text" className="form-control" placeholder="E-mail Address" value={this.state.emailaddress} 
                                        onChange={(e) => this.setValue('emailaddress', e)}/>
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                       What are your sleep habits :
                                       <label className="custom-select">
                                                    <select value={this.state.sleepHabbit} 
                                                    onChange={(e) => this.setValue('sleepHabbit', e)}>                                        
                                                    <option value='' disabled>Make Selection</option>
                                                    {this.state.sleepHabitsList.map((row, index) => {
                                                    return(
                                                    <option key={index} value={row}>{row}</option>
                                                    )
                                                    })}
                                                    </select>
                                                </label>
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                        <span style={{color: 'red'}}>*</span> Phone Number 
                                        <input type="text" className="form-control" placeholder="Phone Number" value={this.state.phoneNumber} onChange={(e) => this.setValue('phoneNumber', e)}/>
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                        Do you smoke :(*All apartments are non-smoking)
                                                <label className="custom-select">
                                                    <select value={this.state.smoke} 
                                                    onChange={(e) => this.setValue('smoke', e)}>                                        
                                                    <option value='' disabled>Make Selection</option>
                                                    {this.state.smokeList.map((row, index) => {
                                                    return(
                                                    <option key={index} value={row}>{row}</option>
                                                    )
                                                    })}
                                                    </select>
                                                </label>
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                        <span style={{color: 'red'}}>*</span> Move-In Date
   
                                        <DatePicker type="text" className="form-control" placeholder="*Departure Date" selected={this.state.moveInDate}  
                                        onChange={this.handleMoveIn} />
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                        Will you have a vehicle :
                                        <label className="custom-select">
                                        <select value={this.state.vehicle} onChange={(e) => this.setValue('vehicle', e)}>                                        
                                        <option value='' disabled>Make Selection</option>
                                        {this.state.vehicleList.map((row, index) => {
                                        return(
                                        <option key={index} value={row}>{row}</option>
                                        )
                                        })}
                                        </select>
                                    </label>
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                        <span style={{color: 'red'}}>*</span> Move-Out Date
                                        <DatePicker type="text" className="form-control" placeholder="*Departure Date" selected={this.state.moveOutDate}  
                                        onChange={this.handleMoveOut} />
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                      Preferred location : * Pricing is based on four students per two bedroom apartment 
                                      <label className="custom-select">
                                        <select value={this.state.preferedLocation} 
                                        onChange={(e) => this.setValue('preferedLocation', e)}>                                        
                                        <option value='' disabled>Make Selection</option>
                                        {this.state.preferesLocationList.map((row, index) => {
                                        return(
                                        <option key={index} value={row}>{row}</option>
                                        )
                                        })}
                                        </select>
                                    </label>
                                        </div>
                                    </div>
                                  <div className="row">

                                  <div className="col-sm-12 col-md-12">
                                  Comments/Questions/Special Requirements:
                                  <textarea rows="8" cols="5" className="form-control" placeholder="Comments" value={this.state.comments} onChange={(e) => this.setValue('comments', e)} />
                                  </div>
                                  </div>

                                    <div className="row">                                         
                                        <div className="col-sm-12 col-md-12">
                                        <ReCAPTCHA
                                            ref="recaptcha"
                                            sitekey="6Lcbw1AUAAAAAE5K5cYDyhoGu0RJY9C3CLtGFXAo"
                                            onChange={this.onChangeCaptcha}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                            </div>
                            <div className="pts-btn-block">
                            <button type="button" className=" btn location_btn" onClick={this.serviceRequest}> SUBMIT</button>                           
                        </div>
                        </div>
                    </div>
                </div>
                <SubFooter/>
                <Footer/>
                <SuccessModal/>
                </div>


   
           );
       }
   }
   export default CorporateStudentForm;