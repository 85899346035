/* Importing the node modules, child components, services and controllers used 
   inside TeslaWhatsIncluded component */    
   import $ from "jquery";
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import TangerineHeader from "../../common/tangerineHeader";
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   /* TeslaWhatsIncluded Component initialization */
   class TangerineWhatsIncluded extends React.Component {
       /* Initializing objects of its TeslaWhatsIncluded class */
       constructor(props) {
           super(props);
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
           MainController.getInitialInformation();
        }
       /* It is invoked to return html content */
       render() {
           return (
            <div className="corporateHousing_Updates">
                <GuestHeader/>
                <header className="codeconveyHeader cc_image_text_main corporate-h">
                     <img className="img-responsive" src="/assets/images/nv-portland-banner.jpg" width="100%" alt=""  />
                    <div className="wow fadeInUp img-on-text top30 width600 text-center left50 img-on-text-center" data-wow-delay="0.5s">
                        <h1>What's Included In A Standard SuiteAmerica Apartment Home?</h1>
                    </div>
                </header>
                <TangerineHeader />
                <div className="quote-section bg-white">
                    <div className="container">
                        <div  className="text-center col-md-10 col-md-offset-1 wow fadeInUp company_p" data-wow-delay="0.5s">
                       
                            <p>A Tangerine Travel corporate accommodation 
will include the following...</p>
<p>- 24/7, 365 days of the year exceptional Guest Care is included. A live SuiteAmerica 
Representative is ready to provide empathetic support to every Guest during 
business and non-business hours. </p>
<p>- Dedicated Housing Specialist. Tangerine Travel will have a dedicated, single point of 
contact that is well-versed in everything Tangerine Travel and aligned with your 
requirements and customizations.</p>
                        </div>
                    </div>
                </div>


                <div className="container">
                        
                  <div className="teals-foot-navbar">
                    <div className="row tesla-row">
                        <div className="col-md-3">
                            <h1>BEDROOM</h1>
                            <ul>
                                <li><a href="#">Queen size bed fitted with sheets,</a></li>
                                <li><a href="#">Pillows, duvet and down comforter</a></li>
                                <li><a href="#">Nightstand</a></li>
                                <li><a href="#">Dresser</a></li>
                                <li><a href="#">Mirror</a></li>
                                <li><a href="#">Twenty hangers</a></li>
                                <li><a href="#">Alarm clock</a></li>
                                <li><a href="#">Lamp</a></li>
                                <li><a href="#">TV</a></li>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <h1>BATHROOM</h1>
                            <ul>
                                <li><a href="#">Four bath, hand, and face towels</a></li>
                                <li><a href="#">Shower curtain and rug</a></li>
                                <li><a href="#">Tooth brush holder</a></li>
                                <li><a href="#">Soap dispenser</a></li>
                                <li><a href="#">Toilet brush and plunger</a></li>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <h1>KITCHEN</h1>
                            <ul>
                                <li><a href="#">Basic cookware essentials</a></li>
                                <li><a href="#">Coffee maker</a></li>
                                <li><a href="#">Toaster</a></li>
                                <li><a href="#">Dish Towels</a></li>
                                <li><a href="#">Dinnerware</a></li>
                                <li><a href="#">Glassware</a></li>
                                <li><a href="#">Utensils</a></li>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <h1>LIVING AND DINING ROOM</h1>
                            <ul>
                                <li><a href="#">Sofa</a></li>
                                <li><a href="#">Coffee table</a></li>
                                <li><a href="#">Dining table and chairs</a></li>
                                <li><a href="#">Lamp</a></li>
                                <li><a href="#">End table</a></li>
                                <li><a href="#">TV</a></li>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <h1>UTILITIES</h1>
                            <ul>
                                <li><a href="#">Basic Cable/Streaming TV</a></li>
                                <li><a href="#">High Speed Wireless Internet</a></li>
                                <li><a href="#">Water/Gas/Electric</a></li>
                            </ul>
                        </div>
                    </div>
                   </div>
              </div>



                <div className="container-fluid bg-grey">

                </div>
                <SubFooter/>
                <Footer/>
            </div>
            
           );
       }
   }
   export default TangerineWhatsIncluded;