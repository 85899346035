

/* CallModal Component initialization */
class OrderInfo extends React.Component {
    /* Initializing objects of its CallModal class */
    constructor(props) {
        super(props)
        /* CallModal Component State variables Initialization */
        this.state = {
            orderNumber: '',
            message: '',
            color: '',
            results: {},
            AppcodeMsg: '',
            errorMessge: '',
            waitResponse: false

        }
        this.orderSubmit = this.orderSubmit.bind(this);
        this.setValue = this.setValue.bind(this);

    }
    /* To clear the form field values */
    clearPhone() {
        this.setState({ orderNumber: '', message: '', color: '' })
    }
    /* Call to the entered number in the input field */
    async orderSubmit() {

        if (this.state.orderNumber.length < 6) {

            this.setState({ errorMessge: '', AppcodeMsg: '', message: 'Please enter a valid 6 digit order number', color: 'red' })
        }

        else {
            this.setState({ waitResponse: true });
            let result = await this.props.getOrderInfo(this.state.orderNumber);

            await this.setState({ results: result });
            //console.log(this.state.results,"results");
            if (this.state.results.error == 0) {
                if (this.state.results.message.OrderStatus == 'ACTIVE' || this.state.results.message.OrderStatus == 'PENDING') {
                    await this.setState({ message: '', color: '', waitResponse: false, AppcodeMsg: this.state.results.message.AppCode });
                } else if (this.state.results.message.OrderStatus == 'QUOTE') {
                    await this.setState({ message: '', color: '', waitResponse: false, AppcodeMsg: '', errorMessge: "The Order is not booked yet, App Codes are only generated after an Order is booked" });
                } else {
                    await this.setState({ message: '', color: '', waitResponse: false, AppcodeMsg: '', errorMessge: "ORDER IS " + this.state.results.message.OrderStatus });
                }

            } else {
                await this.setState({ message: '', color: '', waitResponse: false, errorMessge: 'NO ORDER EXISTS', AppcodeMsg: '' });
            }
            //  if(){

            //  }
            //console.log('orderInformation',this.props.orderInformation);

            //        const numbers = [
            //            { number:'+18003679501'},
            //            { number: Code+this.state.phoneNo}];        
            //             Conference.conference(numbers)

            //      $('#callModal').modal('hide');
            //      $('#thankyouModal').modal('show');

            //      setTimeout(async function() {
            //        jQuery(function($) {
            //            $('#thankyouModal').modal('hide');
            //        });
            // }, 5000);
        }
    }
    /* It is invoked immediately after a component is mounted */
    componentDidMount() {
        //console.log('componentDidMount');       
    }
    async componentWillMount() {
        if (this.props.orderFormValue == true) {
            await this.setState({ orderNumber: '', message: '', results: {}, AppcodeMsg: '', errorMessge: '' });
        }
        //console.log('componentWillMount');
    }
    /* To update the input field values */
    setValue(e) {
        const re = /^[0-9\b]+$/;
        if (e.target.value == '' || re.test(e.target.value)) {
            this.setState({ orderNumber: e.target.value })
        }
    }
    /* To update the state in response to prop changes */
    componentWillReceiveProps(nextProps) {
        //console.log('componentWillReceiveProps');

        this.setState({ orderNumber: '', message: '', results: {}, AppcodeMsg: '', errorMessge: '' })
    }
    /* It is invoked to return html content */
    render() {
        return (<div> <div className="modal fade savesearch" id="orderModal" role="dialog" >
            <div className="modal-dialog">
                <div className="modal-content rm-border-radius">
                    <div className="modal-header">
                        <div className="modal-title"> AppCode Information</div>
                        <button type="button" className="close btn" data-dismiss="modal" aria-label="" >
                            <i className="bi bi-x"></i>
                        </button>
                    </div>
                    <div className="modal-body">


                        {/* <p>Enter your phone number and we'll call to <br/>
                       assist you right away.</p> */}
                        <form role="form" method="post" >
                            {this.state.message ? <span style={{ color: this.state.color, textAlign: 'center' }}>
                                <h4>{this.state.message}</h4>
                            </span> : ''}

                            <div className="form-group">
                                <div className="col-md-8 col-md-offset-2 mrg-btm-30">
                                    <input className="form-control" required id="order" placeholder='Order Number' maxLength="6"
                                        onChange={(e) => this.setValue(e)} value={this.state.orderNumber} />
                                </div>
                            </div>
                            <div className="clearfix"></div>
                            {this.state.waitResponse ? <div className="panel-body_loader" style={{ minHeight: '30px' }} > <span style={{ fontSize: '25px', width: '100%', margin: 'auto', textAlign: 'center' }}><i className="fa fa-spinner fa-spin" /> please wait ...!</span> </div> :
                                <button
                                    className="btn location_btn"
                                    type="button"
                                    onClick={this.orderSubmit}>Submit</button>

                            }

                        </form>
                        {this.state.AppcodeMsg != '' ?
                            <p className="captcha"> {this.state.AppcodeMsg} </p>
                            : <span style={{ color: this.state.color, textAlign: 'center' }}>
                                <h4>{this.state.errorMessge}</h4>
                            </span>
                        }
                    </div>
                </div>
            </div>
        </div>

        </div>)
    }

}

export default OrderInfo