import { latestBlogs, formatDateForDisplay } from "./utils";
import HtmlParser from "react-html-parser";
class FullBlogPage extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  render() {
    const latestBlogsData = latestBlogs(
      this.props.blogsData,
      this.props.selectedBlog
    );
    const { selectedBlog } = this.props;
    return (
      <section className="sa-blog-view">
        <div className="container mt-4">
          <div className="blogs">
            <div className="row">
              <div className="col-lg-8">
                <h1>{selectedBlog.title}</h1>
                <ul className="meta list-inline mb-2 mt-2">
                  <li className="list-inline-item">
                    {formatDateForDisplay(selectedBlog.createdDate)}
                  </li>
                </ul>
                <div className="image mt-4">
                  <img src={selectedBlog.coverImageUrl} alt="" width="100%" />
                </div>
                <div className="blog-content mt-5">
                  {selectedBlog.sections.map((item, index) => {
                    if (item.type === "paragraph") {
                      return <p key={index}>{HtmlParser(item.content)}</p>;
                    } else if (item.type === "subheading") {
                      return <h3 key={index}>{item.content}</h3>;
                    } else if (item.type === "imageLeft") {
                      return (
                        <figure className="user-image-left" key={index}>
                          <img src={item.url} alt="" width="100%"></img>
                          {item.caption ? (
                            <figcaption>{item.caption}</figcaption>
                          ) : null}
                        </figure>
                      );
                    } else if (item.type === "imageRight") {
                      return (
                        <figure className="user-image-right" key={index}>
                          <img src={item.url} alt="" width="100%"></img>
                          {item.caption ? (
                            <figcaption>{item.caption}</figcaption>
                          ) : null}
                        </figure>
                      );
                    } else if (item.type === "imageCenter") {
                      return (
                        <div className="my-5 py-4 text-center" key={index}>
                          <img src={item.url} alt="" width="350px"></img>
                          {item.caption ? (
                            <figcaption>{item.caption}</figcaption>
                          ) : null}
                        </div>
                      );
                    } else if (item.type === "paraheading") {
                      return (
                        <p className="heading" key={index}>
                          {item.content}
                        </p>
                      );
                    } else if (item.type === "italic") {
                      return (
                        <p key={index}>
                          <i>{item.content}</i>
                        </p>
                      );
                    } else if (item.type === "gif") {
                      return (
                        <div className="mb-4">
                          <img src={item.url} width="100%" />
                        </div>
                      );
                    }
                  })}
                </div>
                <div className="clearfix"></div>
                <div className="post-bottom">
                  <div className="details-view mt-3">
                    <div className="">
                      {this.props.selectedBlog.categories.map(
                        (category, index) => {
                          return (
                            <a className="tag" key={index}>
                              {category}
                            </a>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="blog-sideview mb-4">
                  <h3>Recent Posts</h3>

                  {latestBlogsData.map((blog, index) => {
                    return (
                      <div className="post" key={index}>
                        <div className="thumb">
                          <img
                            src={blog.coverImageUrl}
                            alt="post-title"
                            width="100%"
                          />
                        </div>
                        <div className="details clearfix">
                          <h6
                            className="post-title my-0"
                            onClick={() => {
                              this.props.handleBlogSelect(blog);
                            }}
                          >
                            <a href="#">{blog.title}</a>
                          </h6>
                          <ul className="meta list-inline mt-1 mb-0">
                            <li className="list-inline-item">
                              {formatDateForDisplay(blog.createdDate)}
                            </li>
                          </ul>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default FullBlogPage;
