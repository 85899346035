/* Importing the node modules, child components, services and controllers used 
   inside S3BucketImages component */
import $ from "jquery";
import AppController from "../../../controller/appController";
import OwlCarousel from 'react-owl-carousel';

/* S3BucketImages Component initialization */
class S3BucketImages extends React.Component {
    /* Initializing objects of its S3BucketImages class */
    constructor(props) {
        super(props)
        /* S3BucketImages Component State variables Initialization */
        this.state = {
            activeIndex: 2
        }
    }
    /* It is invoked immediately before a component is mounted */
    componentWillMount() {
        $('html, body').animate({ scrollTop: 0 });
        AppController.stickyTitles();
    }
    /* To active the selected date */
    changeIndex(index) {
        this.setState({ activeIndex: index });
    }
    /* It is invoked to return html content */
    render() {
        return (
            <div className="modal fade view_more_img bd-example-modal-lg" id="morePhotos" role="dialog">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content rm-border-radius">

                        <div className="modal-body more-photos-loader">
                            <button type="button" className="btn close" data-dismiss="modal" aria-label="">
                                <i className="bi bi-x"></i>
                            </button>

                            {this.props.s3bucketImages.length > 0
                                ? <OwlCarousel
                                    ref={inst => this.slider = inst}
                                    {...{
                                        loop: false, nav: true, dots: true, items: 1, navContainerClass: 'owl-buttons', navText: ["<i class='glyphicon glyphicon-menu-left'></i>", "<i class='glyphicon glyphicon-menu-right'></i>"],
                                        responsive: { 320: { items: 1, nav: true, loop: false }, 767: { items: 1, nav: true, loop: false }, 992: { items: 1, nav: true, loop: false }, 1199: { items: 1, nav: true, loop: false } }
                                    }}>
                                    {this.props.s3bucketImages.map((galleyImage, galleyImageIndex) => {
                                        return galleyImage.Size > 0 ? <div className="view_images" key={galleyImageIndex}>
                                            {galleyImage.Key != null ?
                                                <img className="img-responsive" src={'https://suiteam-website.s3.amazonaws.com/' + galleyImage.Key} alt="" /> :
                                                ''
                                            }
                                        </div> : null
                                    })}</OwlCarousel>
                                : this.props.error == 400 ?
                                    <div className="panel-body_loader" style={{ lineHeight: '300px' }}>
                                        No Images found

                                    </div>
                                    : <div className="panel-body_loader" style={{ lineHeight: '300px' }} > <span style={{ fontSize: '25px', width: '100%', margin: 'auto', textAlign: 'center' }}><i className="fa fa-spinner fa-spin" /> please wait ...!</span> </div>}

                        </div>

                    </div>
                </div>
            </div>
        );
    }
}
export default S3BucketImages;