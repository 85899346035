const RiotGamesSubFooter = () => {
    return (            
         <div className="text-center">
             <h3>SUITEAMERICA</h3>
             <h5><i className="fa fa-phone-square"></i>
                 {/* <span className="desktop-phone">800.367.9501</span> */}
                 <a href="tel:8003679501" className="mobile-phone">800.367.9501</a>
             </h5>
             <h5>4970 Windplay Dr. Suite C-1</h5>
             <h5>El Dorado Hills, CA 95762</h5>
         </div>
         );
}
export default RiotGamesSubFooter;