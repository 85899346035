import moment from "moment";

function filterDataBySearch(searchText, tableData, searchFields) {
  const searchTextLower = searchText.toLowerCase().replace(/\s+/g, "");
  // console.log(searchTextLower, "searchTextLower");
  const filteredData = tableData.filter((item) =>
    searchFields.some((field) => {
      let fieldValue;
      // Create a combined name field
      const combinedGuestName =
        `${item.GuestFirstName}${item.GuestLastName}`.toLowerCase();
      const stateName = item.CommunityStateCode;
      const stateCode = stateName?.split("-")[1];
      const CombinedCItyState =
        `${item.CommunityCity},${stateCode}`.toLowerCase();
      if (field.toLowerCase().includes("date")) {
        fieldValue = moment(item[field]).format("MM/DD/YYYY").toLowerCase();
      } else if (field === "NumberOfStayDays") {
        fieldValue = noOfDays(item.MoveInDate, item.MoveOutDate).toString();
      } else if (field === "CombinedGuestName") {
        fieldValue = combinedGuestName.replace(/\s+/g, "");
      } else if (field === "CombinedCItyState") {
        fieldValue = CombinedCItyState.replace(/\s+/g, "");
      } else {
        fieldValue = item[field]
          ? item[field].toString().toLowerCase().replace(/\s+/g, "")
          : "";
      }
      return fieldValue.includes(searchTextLower);
    })
  );
  return filteredData;
}

function noOfDays(moveInDate, moveOutDate) {
  if (!moveInDate || !moveOutDate) {
    return 0;
  }
  const date1 = moment(moveInDate, "YYYY-MM-DDTHH:mm:ss");
  const date2 = moment(moveOutDate, "YYYY-MM-DDTHH:mm:ss");

  const numberOfDays = date2.diff(date1, "days");
  return numberOfDays;
}

function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

function extractCityName(city) {
  if (!city) return "";

  return city.split(",")[0];
}

export { filterDataBySearch, noOfDays, capitalizeFirstLetter, extractCityName };
