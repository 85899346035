import GuestHeader from '../../common/guestHeader';
import SubFooter from "../../common/subFooter";
import Footer from '../../common/footer';
import AppController from "../../controller/appController";
import MapView from './mapView';
import ContentLoader from 'react-content-loader';
import LocationService from '../../../services/locationService';
import CityData from "./cityData";
import GreatSchools from './greatSchools';
import Weather from './weather';
import WalkScore from './walkscore';
import Poi from './pointofinterests';
import DemoGraphics from './demoGraphics';
import MetaTags from '../../common/metaTags';
class CityHomePage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            commpanyname: '',
            communityData: [],
            cityIntro: "",
            cityImage: "",
            weather: [],
            poi: [],
            greatSchools: {},
            walkScoreDetails: [],
            walkscoreLoading: false,
            address: '',
            latitude: '',
            longitude: '',
            city: '',
            state: '',
            cityHighlights: [],
            cityInfo: '',
            demoGraphics: []

        }
        this.Search = this.Search.bind(this);
        this.refreshSearchResult = this.refreshSearchResult.bind(this);
    }
    async componentDidMount() {
        AppController.removeLocalStorageData();
        $('html, body').animate({ scrollTop: 0 });
        AppController.stickyTitles()
        Loadjs('/css/react-rater.css')
        this.getSearchResult();
    }
    async getSearchResult() {
        var companyName = 'suiteamerica';
        var paramsObj = {};
        var city = '';
        var state = '';
        if (this.props.match != undefined) {
            paramsObj = companyName != 'suiteamerica' ? this.props.props.match.params : this.props.match.params
            city = paramsObj.city ? paramsObj.city.toLowerCase() : undefined;
            state = paramsObj.state ? paramsObj.state.toLowerCase() : undefined;
        } else {
            paramsObj = 'suiteamerica'
            city = undefined;
            state = undefined;
        }
        let individualdata = "";
        if (city !== undefined && state !== undefined && CityData[state + '-' + city] !== undefined) {
            individualdata = CityData[state + '-' + city];
            const data = {
                companyname: companyName,
                latitude: '',
                longitude: '',
                address: individualdata.address != undefined ?
                    individualdata.address : '',
                state_id: (individualdata == undefined || (individualdata.state_id == undefined || individualdata.state_id == '' && individualdata.state_id == null)) ?
                    '' : individualdata.state_id,
                city_id: (individualdata == undefined || (individualdata.city_id == undefined || individualdata.city_id == '' && individualdata.city_id == null)) ?
                    '' : individualdata.city_id,
                amenitiesId: individualdata.amenities_id != undefined ?
                    individualdata.amenities_id : '',
                communityName: individualdata.communityName != undefined ?
                    individualdata.communityName : '',
            }
            await this.setState({
                locationName: individualdata.locationName,
                city: individualdata.city,
                state: individualdata.state,
                countryId: individualdata.countryId,
                tagLine: individualdata.tagLine,
                cityHighlights: individualdata.cityHighlights,
                cityInfo: individualdata.cityInfo,
                cityImage: individualdata.image,
                metaTitle: individualdata.metaTitle,
                metaDescription: individualdata.metaDescription,
                metaUrl: individualdata.metaUrl,
                demoGraphics: individualdata.demoGraphics,
            });
            
            this.communityInfo(data, individualdata)
            this.method(individualdata)
        }


    }
    async method(individualdata) {
        const apidata = {
            city: individualdata.city,
            state_name: individualdata.state,
            address: individualdata.address == '' ? individualdata.city : individualdata.address,
            latitude: individualdata.latitude,
            longitude: individualdata.longitude
        }

        this.weather(apidata)
        this.greatSchools(apidata);
        this.poi(apidata);
        this.walkScore(apidata)
    }
    async communityInfo(data, individualdata) {
  
        const defaultRequest={
            companyname: 'suiteamerica',
            latitude: '',
            longitude: '',
            address:  '',
            state_id: '',
            city_id: '',
            amenitiesId:  '',
            communityName: '',
           
        }
        const req=data.city_id=='25247'?defaultRequest:data
        this.setState({
            savedSearchData: '',
            communityData: [],
            isLoading: true,
        })
        const communityData = await LocationService.getCommunityData(req)
        await this.setState({
            savedSearchData: individualdata,
            communityData,
            isLoading: false,
        })
    }
    // Point of GreatSchools
    async greatSchools(apidata) {
        const greatSchools = await AppController.greatSchools(apidata);
        this.setState({ greatSchools: greatSchools, })
    }
    // Point of Interest
    async poi(apidata) {
        const poi = await AppController.poi(apidata)
        this.setState({ poi: poi.results })
    }
    // WalkScore
    async walkScore(apidata) {

        const result = await AppController.wallsckoreFun(apidata);
        const info = [{
            Score: result.walkscore == undefined ? null : result.walkscore,
            description: result.description == undefined ? null : result.description,
            name: 'WalkScore',
            chartName: 'chart1',
            colour: '#000000',
            image: 'WalkScore.png'
        },
        {
            Score: result.bike == undefined ? null : result.bike.score,
            description: result.bike == undefined ? null : result.bike.description,
            name: 'BikeScore',
            chartName: 'chart2',
            colour: '#000000',
            image: 'BikeScore.png'
        },

        ]
        this.setState({
            walkscoreLoading: true,
            walkScoreDetails: info,
        })
        const walkScoreDetails = this.state.walkScoreDetails
        for (var i = 0; i < walkScoreDetails.length; i++) {
            AppController.doughnutChart1(walkScoreDetails[i]);
        }

    }

    async weather(apidata) {
        const weather = await AppController.weather(apidata)
        var results = weather.list.map(function (el) {
            var o = Object.assign({}, el);
            o.date = o.dt_txt.split(" ")[0];
            return o;
        })
        var data1 = _.chain(results)
            .groupBy("date")
            .map(function (currentItem) {
                let minTEMP = _.minBy(currentItem, function (o) {
                    return o.main.temp_min;
                });
                let maxTEMP = _.maxBy(currentItem, function (o) {
                    return o.main.temp_max;
                });
                return {
                    weatherData: currentItem[0],
                    weatherDataMax: maxTEMP.main.temp_max,
                    weatherDataMin: minTEMP.main.temp_min
                }
            }).value();
        this.setState({
            weather: data1.splice(1, 4),
            weather_current: data1[0].weatherData,
            weather_currentCityDetails: weather.city,
        })
    }
    async refreshSearchResult() {
        $('#city-input1').val('');
    }

    setLocation(e) {
        const _this = this;

        this.setState({
            address: e.target.value
        });
        var opts = {
            types: ['(cities)']
        };
        var places = new google.maps.places.Autocomplete(document.getElementById('city-input1'), opts);

        google.maps.event.addListener(places, 'place_changed', function () {
            var place = places.getPlace();
            var formatted_address1 = place.formatted_address
            var formatted_address = place.formatted_address.split(',')
            const newLat = place.geometry.location.lat();
            const newLng = place.geometry.location.lng();
            _this.setState({
                latitude: newLat,
                longitude: newLng,
                address: formatted_address1,
                state: formatted_address[formatted_address.length - 2],
                city: formatted_address[formatted_address.length - 3]
            });
        });
    }

    async Search() {
        const communitySearch = {
            companyname: 'suiteamerica',
            latitude: this.state.latitude,
            longitude: this.state.longitude,
            address: this.state.address,
            state_id: '',
            city_id: '',
            amenitiesId: '',
            communityName: '',
        };

        const apidata = {
            city: this.state.city,
            state: this.state.state,
            address: this.state.address,
            latitude: this.state.latitude,
            longitude: this.state.longitude,
        }
        this.method(apidata);
        await this.communityInfo(communitySearch, '')

    }
    render() {
        const company = (this.state.companyname == undefined || this.state.companyname == '') ? 'SuiteAmerica' : this.state.commpanyname
        return (
            <div className="corporateHousing_Updates">
                <MetaTags
                    metaTitle={this.state.metaTitle != '' ? this.state.metaTitle : 'Corporate Housing | Short Term Apartments | 800 367 9501'}
                    metaDesription={this.state.metaDescription != '' ? this.state.metaDescription : "Suite America provides platinum-level service in Corporate Housing, Short Term A" +
                        "partments and GSA Contract Housing. At SuiteAmerica 800-367-9501, we’re using ga" +
                        "me-changing technology, 24/7 service and our nearly 30 years of excellence in cu" +
                        "stomer service to revolutionize the Corporate Housing experience around the glob" +
                        "e."}
                    metaUrl={this.state.metaUrl != '' ? this.state.metaUrl : 'https://www.suiteamerica.com/'}
                />
                <GuestHeader />

                {company == 'SuiteAmerica' ?
                    <div>
                        <div className="intra_staffNearbyCities">
                            <div className="carousel cityland_ban landingcity_banner_img">
                                <img className="img-responsNearbyCitiesive" src={this.state.cityImage !== "" ? this.state.cityImage : s3BucketUrl_WebsiteImages + "SuiteAmerica-Location-Banner.jpg"} width="100%" alt="" />
                                <div className="cityland-slide-text">
                                    <div className="slide_style_left ">
                                        <h1 className="toggleHeading wow fadeInDown animated no-margin">{this.state.city} <br />
                                            {this.state.state}
                                        </h1>
                                    </div>
                                </div>
                                {/* <div className="cityland_form text-center">
                        <div className="form-group name">
                           <input
                              id="city-input1"
                              type="text"
                              name="search"
                              placeholder="City or Address"
                              onChange={(e) => this.setLocation(e)}/>
                        </div>
                        {this.state.loadingStatus == true? 
                        <span
                        style={{
                        fontSize: '20px',
                        width: '100%',
                        margin: 'auto',
                        textAlign: 'center',
                        lineHeight: '2.5em'
                        }}><i className="fa fa-spinner fa-spin"/>
                        please wait ...!</span> : 
                        <div>
                           <button  className="btn btn-border"
                              type="button"
                              onClick={this.Search}>Search</button>
                           <button
                              type="button"
                              className="btn btn-border"
                              onClick={this.refreshSearchResult}>
                           <i className="fa fa-refresh"></i>
                           </button>
                        </div>
                        }
                     </div> */}
                            </div>
                        </div>
                        <div className="container">
                            <div className=" mrg-top-70 regeon-map-wrap cityland_con row">
                                <div className="col-md-7">
                                    <h4 className="txt-letter-gap-10">{this.state.tagLine} </h4>
                                    <p className="mrg-btm-50 txt-italic">
                                        {(this.state.cityInfo == "" )?
                                            <ContentLoader>
                                                <rect x="3" y="0" rx="3" ry="3" width="180" height="5" />
                                                <rect x="0" y="18" rx="0" ry="3" width="380" height="5" />
                                                <rect x="0" y="36" rx="3" ry="3" width="380" height="5" />
                                                <rect x="0" y="54" rx="3" ry="3" width="380" height="5" />
                                            </ContentLoader>
                                            : this.state.cityInfo}
                                    </p>
                                    <h4>city highlights</h4>
                                    <img src="/assets/images/cityhighlights.png" className="img-responsive pdd-right-60" alt="cityhighlights.png" />
                                    <ul className="cityland_list">
                                        {this.state.cityHighlights.map((row, index) => {
                                            return (<li key={index}>{row}</li>)
                                        })}
                                    </ul>
                                </div>
                                {this.state.communityData.length > 0 ?
                                    <div className="col-md-5">
                                       <div className="mapcircle">
                                            <MapView
                                                communityData={this.state.communityData}
                                                companyName={this.state.companyname} />
                                        </div>
                                    </div> :
                                    this.state.isLoading &&<ContentLoader height={200} width={200} speed={1}>
                                        <rect x="0" y="50" rx="5" ry="5" width="200" height="200" />
                                    </ContentLoader>
                                }
                            </div>
                            <DemoGraphics demoGraphics={this.state.demoGraphics}/>
                            <div className="row">
                                <div className="col-md-6">
                                    {this.state.walkscoreLoading &&
                                        <WalkScore {...this.state} />
                                        }
                                </div>
                                <div className="col-md-6">
                                    {this.state.weather_current != undefined &&
                                        <Weather {...this.state} weather={this.state.weather} weather_current={this.state.weather_current} weather_currentCityDetails={this.state.weather_currentCityDetails} />
                                        } </div>
                            </div>
                        </div>
                    </div>
                    : null}<br />
                <div className="container">
                    {Object.keys(this.state.greatSchools).length > 0 ?
                        <GreatSchools {...this.state} />
                        : ''}
                    {this.state.poi.length > 0 ?
                        <Poi {...this.state} />
                        : ''}
                </div>
                <div className="container cityland_con mrg-top-50 mrg-btm-50">

                    {/* <NearbyCities /> */}
                </div>
                <SubFooter />
                <Footer />
            </div>
        );
    }
}
export default CityHomePage;