/* Importing the node modules, child components, services and controllers used 
   inside adobeReservationForm component */    
   import $ from "jquery";
   import DatePicker from 'react-datepicker'
   import moment from 'moment'
   import AppController from "../../controller/appController";
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import AdobeHeader from "./common/adobeHeader";
   import NikeServices from '../../../services/nikeService';
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   import ReCAPTCHA from "react-google-recaptcha"
   import SuccessModal from '../../common/successModal';
   /* adobeReservationForm Component initialization */
   class AdobeReservationForm extends React.Component {
       /* Initializing objects of its adobeReservationForm class */
       constructor(props) {
           super(props)
           this.state={
            CaptchaValue:'',
               firstName: '',
               lastName: '',
               address: '',
               city: '',
               state: '',
               zipCode: '',
               email: '',
               phone: '',
               fax: '',
               cellPhone: '',
               company: '',
               locationRequested: '',
               moveInDate:moment(),
               moveOutDate:moment(),
               reserveDropDown: '',
               noOfAdults: '',
               noOfChildren: '',
               ageofChildren: '',
               bedSize: '',
               petInfo: '',
               pet: '',
               referrerName: '',
               referrerPhone: '',
               message: '',
               color:'',
               errorMessage:'',
               budget:'$3500-$4000/month',
               appartmentSize:'',
               budgetDropDown:['$3500-$4000/month','$4001-$4500/month','$4501-$5000/month','$5001-$5500/month','$5501/month or higher'],
               appartmentSizeDropDown:['1 Bedroom','2 Bedrooms','3 Bedrooms','Studio','other']
           }
           this.setValue = this.setValue.bind(this);
           this.handleMoveIn = this.handleMoveIn.bind(this);
           this.handleMoveOut = this.handleMoveOut.bind(this);
           this.setChecked = this.setChecked.bind(this);
           this.serviceRequest = this.serviceRequest.bind(this);
           this.onChangeCaptcha = this.onChangeCaptcha.bind(this);
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
           MainController.getInitialInformation();
        }
        /* Form Details object creations  */
      setValue(field, e) {
            var object = {};
            object[field] = e.target.value;
            this.setState(object);
          }
      /* Form Details object creations  */
      setChecked(e) {
        this.setState({pet:e.target.value});
      }
     /* MoveIndate and MoveOutDate   */
      async handleMoveIn(date){
            await this.setState({moveInDate: moment(date),
            moveInDateSelected:date.format('YYYY-MM-DD HH:mm:ss') });
        }
      async handleMoveOut(date){
            await this.setState({moveOutDate: moment(date),
            moveOutDateSelected:date.format('YYYY-MM-DD HH:mm:ss') });
        }
        async onChangeCaptcha(CaptchaValue) {
            await this.setState({CaptchaValue})
          }
       /* Service Request Details send to mail  */
       async serviceRequest(){
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        const moveIn = this.state.moveInDateSelected == '' ? this.state.moveInDate.format('YYYY-MM-DD HH:mm:ss'): this.state.moveInDateSelected ;
        const moveOut = this.state.moveOutDateSelected == '' ? this.state.moveOutDate.format('YYYY-MM-DD HH:mm:ss'): this.state.moveOutDateSelected ;
        const _this = this
           const validationFields=['firstName','lastName','address','city','state','zipCode','email','phone','company','moveInDate','moveOutDate','CaptchaValue']
           let error = 0;
           _.forEach(validationFields, function(value) {
              if(_this.state[value] == ""){
                error++;
              }
          });
          _.forEach(validationFields, function(value) {
             if(!reg.test(_this.state.email)){
              error++;
            }
        });

         if(error == 0 && moment(moveIn).isSameOrAfter(moveOut)){
        const Request={
            firstName:this.state.firstName,
            lastName:this.state.lastName,
            address:this.state.address,
            city:this.state.city,
            state:this.state.state,
            zipCode:this.state.zipCode,
            email:this.state.email,
            phone:this.state.phone,
            fax:this.state.fax,
            cellPhone:this.state.cellPhone,
            company:this.state.company,
            locationRequested:this.state.locationRequested,
            moveInDate:this.state.moveInDate.format('YYYY-MM-DD'),
            moveOutDate:this.state.moveOutDate.format('YYYY-MM-DD'),
            reserveDropDown:this.state.reserveDropDown,
            noOfAdults:this.state.noOfAdults,
            noOfChildren:this.state.noOfChildren,
            ageofChildren:this.state.ageofChildren,
            bedSize:this.state.bedSize,
            petInfo:this.state.petInfo,
            pet:this.state.pet,
            referrerName:this.state.referrerName,
            referrerPhone:this.state.referrerPhone,
            message:this.state.message,
            appartmentSize:this.state.appartmentSize,
            budget:this.state.budget,
            mail:'adobeReservation',
            subject: 'adobe Reservation Form'
        }
         this.setState({
            firstName: '',
            lastName: '',
            address: '',
            city: '',
            state: '',
            zipCode: '',
            email: '',
            phone: '',
            fax: '',
            cellPhone: '',
            company: '',
            locationRequested: '',
            moveInDate:moment(),
            moveOutDate:moment(),
            reserveDropDown: '',
            noOfAdults: '',
            noOfChildren: '',
            ageofChildren: '',
            bedSize: '',
            budget: '$3500-$4000/month',
            appartmentSize: '',
            petInfo: '',
            pet: '',
            referrerName: '',
            referrerPhone: '',
            message: '',
            color:'',
            errorMessage:'',
        })
        $('input[name="radio"]').prop('checked', false);
        AppController.showSuccessModal();
        await NikeServices.serviceRequest(Request)
    }
    else{
        AppController.menuTopNavigation('serviceRequest');
        this.setState({errorMessage:'Please Enter Required Fields, Valid Email, Captcha & MoveOutDate is greater than are equal to MoveInDate ',color:'red'})
    }        
       }
       /* It is invoked to return html content */
       render() {
   
           return (
            <div className="corporateHousing_Updates">
            <GuestHeader/>
            <div className="cover-tophd-ht"></div>
 <AdobeHeader />
 <div className="container-fluid nike company_section" id="serviceRequest">
 <div className="container text-center">
 <h2 className="">READY TO STAY WITH US?</h2>
 <p>Fill out the form below to request a reservation.</p>
 <p>
 Once you submit your request, you will receive an email confirmation that the request was received. Your request will be processed
and you will hear from us by phone or email within 2 business hours to confirm your reservation and to clear up any questions.</p>
 
</div>

 </div>

 <div className="container-fluid nike-datepicker bg-grey service_request">
 <div className="request_form company_form" >
 <div className="container">
    
    
    <form className="well">
        <p style={{color:this.state.color}}>{this.state.errorMessage}</p>
    <h1>Contact Information</h1>
       
       <div className="row">
          <div className="col-sm-6 col-md-6">
          <input type="text" className="form-control" placeholder="*First Name" value={this.state.firstName} onChange={(e) => this.setValue('firstName', e)}/>
          <input type="text" className="form-control" placeholder="*Last Name" value={this.state.lastName} onChange={(e) => this.setValue('lastName', e)} />
          <input type="text" className="form-control" placeholder="*Address" value={this.state.address} onChange={(e) => this.setValue('address', e)} />
          <input type="text" className="form-control" placeholder="*City" value={this.state.city} onChange={(e) => this.setValue('city', e)} />
          <div className="row">
            <div className="col-sm-6">
            <input type="text" className="form-control" placeholder="*State" value={this.state.state} onChange={(e) => this.setValue('state', e)} />
            </div>
            <div className="col-sm-6">
            <input type="text" className="form-control" placeholder="*Zip Code" value={this.state.zipCode} onChange={(e) => this.setValue('zipCode', e)} />
            </div>
            </div>
          
          
          </div>
          <div className="col-sm-6 col-md-6">
          <input type="text" className="form-control" placeholder="*E-mail Address" value={this.state.email} onChange={(e) => this.setValue('email', e)}/>
          <input type="text" className="form-control" placeholder="*Phone" value={this.state.phone} onChange={(e) => this.setValue('phone', e)} />
          <input type="text" className="form-control" placeholder="Fax" value={this.state.fax} onChange={(e) => this.setValue('fax', e)} />
          <input type="text" className="form-control" placeholder="Cell Phone" value={this.state.cellPhone} onChange={(e) => this.setValue('cellPhone', e)} />
          
          <input type="text" className="form-control" placeholder="*Company" value={this.state.company} onChange={(e) => this.setValue('company', e)} />
          </div>
          
       </div>
        <hr />
       <h1>Reservation Information</h1>
       <div className="row">
          <div className="col-sm-6 col-md-6">
          <input type="text" className="form-control" placeholder="Location Requested" value={this.state.locationRequested} onChange={(e) => this.setValue('locationRequested', e)} />
          <label className="custom-select">
    <select value={this.state.budget} onChange={(e) => this.setValue('budget', e)}>
    <option value={this.state.budgetDropDown[0]}>{this.state.budgetDropDown[0]}</option>
    {this.state.budgetDropDown.map((row, index) => {
            return(
                <option key={index} value={row}>{row}</option>
            )
        })}
        

    </select>
</label>
          </div>
          <div className="col-sm-6 col-md-6">
          <DatePicker type="text" className="form-control" placeholder="*Move-In Date" selected={this.state.moveInDate}  onChange={this.handleMoveIn} />
          <DatePicker type="text" className="form-control" placeholder="*Move-Out Date" selected={this.state.moveOutDate}  onChange={this.handleMoveOut} /> 
          </div>
       </div>
       
       <hr />
       <h1>Housing Information</h1>
       <div className="row">
          <div className="col-sm-6 col-md-6">
          <input type="text" className="form-control" placeholder="Number of Adults" value={this.state.noOfAdults} onChange={(e) => this.setValue('noOfAdults', e)} />
          <input type="text" className="form-control" placeholder="Number of Children" value={this.state.noOfChildren} onChange={(e) => this.setValue('noOfChildren', e)} />
           
          <label className="custom-select">
                <select value={this.state.appartmentSize} 
                onChange={(e) => this.setValue('appartmentSize', e)}>
                <option value=''>None Selected</option>
                {this.state.appartmentSizeDropDown.map((row,index)=>{
                    return(
                        <option key={index} value={row}>{row}</option>
                    )
                })}
            
            </select>
        </label>
          </div>
          <div className="col-sm-6 col-md-6">
          <input type="text" className="form-control" placeholder="Age of Children" value={this.state.ageofChildren} onChange={(e) => this.setValue('ageofChildren', e)} />
          <input type="text" className="form-control" placeholder="Bed Size Requested" value={this.state.bedSize} onChange={(e) => this.setValue('bedSize', e)} />  
          <div className="row radiosec">
            <div className="col-sm-6">
            <input type="text" className="form-control" placeholder="Pet Information" value={this.state.petInfo} onChange={(e) => this.setValue('petInfo', e)} />
            </div>
            <div className="col-sm-5 col-md-offset-1">
            <label>Pet</label>
                <div className="row">
                
                <div className="clearfix"></div>
                <div className="col-sm-6">
                <label className="custom_radio radio-inline">Yes
                <input type="radio" name="radio" value="yes" 
                onChange={(e) => this.setChecked(e)} />
                <span className="checkmark"></span>
                </label>
                </div>
                <div className="col-sm-6">

                <label className="custom_radio radio-inline">No
                <input type="radio" name="radio" value="no" 
                onChange={(e) => this.setChecked(e)}/>
                <span className="checkmark"></span>
                </label>
                </div>
                </div>
            </div>
            </div>
          </div>
       </div>
       
       
       <hr />
       <h1>Referred By</h1>
       <div className="row">
          <div className="col-sm-6 col-md-6">
          <input type="text" className="form-control" placeholder="Referrer Name" value={this.state.referrerName} onChange={(e) => this.setValue('referrerName', e)} />
          </div>
          <div className="col-sm-6 col-md-6">
          <input type="text" className="form-control" placeholder="Referrer Phone" value={this.state.referrerPhone} onChange={(e) => this.setValue('referrerPhone', e)} />
             
          </div>
       </div>
       <hr />
       <h1>Additional Comments</h1>
       <div className="row">
          <div className="col-sm-12 col-md-12">
          <textarea className="form-control" placeholder="Message" value={this.state.message} onChange={(e) => this.setValue('message', e)} rows="8" cols="5"></textarea>
          </div>
          
       </div>
       <div className="row">
          <div className="col-sm-12 col-md-12">
          <ReCAPTCHA
    ref="recaptcha"
    sitekey="6Lcbw1AUAAAAAE5K5cYDyhoGu0RJY9C3CLtGFXAo"
    onChange={this.onChangeCaptcha}
  />
          </div>
          
       </div>
     
    </form>
    
    <button type="button" className=" btn location_btn mrg-btm-70 mrg-top-40" 
    onClick={this.serviceRequest}> SUBMIT</button>
 </div>
</div>
   </div>
            
  <SubFooter/>
  <Footer/>
  <SuccessModal/>
             </div>
   
           );
       }
   }
   export default AdobeReservationForm;