import OrderStatusModal from './orderStatusModal';
const AverageCounts=(props)=>{
const {countsInfo, analysisCount, avgCountLoading, changeStatus} = props;
return(
    <div>
    <div className="row mrg-btm-30" >
        {countsInfo.map((row, index) => {
            return(
                <div className="col-md-4 col-sm-4" key={index} data-toggle="modal" style={{cursor:'pointer'}}data-target={row.status=='openservice'?"#analysisCountInfo":"#orderStatusModal"} onClick={row.status=='openservice'?()=>{analysisCount(row.moreInfo,1,'Started',2)}:()=>{changeStatus(row.status)}}>
                    <div className={'widget-wrap bg-widget-'+row.color}>
                        <h3>{row.name}</h3>
                        <h1>
                            {avgCountLoading? <i className="fa fa-spinner fa-spin"/>: row.counts}
                        </h1>
                    </div>
                </div>
            )
        })}
    </div>
    <OrderStatusModal {...props}/>
    </div>
)
}
export default AverageCounts;