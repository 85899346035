/* Importing the node modules, child components, services and controllers used 
   inside NikeApprenticeshipContactUs component */ 
   import MainController from "../../controller/mainController";
   import NikeApprenticeshipHeader from "../../common/nikeApprenticeshipHeader";
   import SubFooter from "../../common/subFooter";
   import ContactUs from "./common/contactUs";
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   import ApprenticeshipFooter from "./common/apprenticeshipFooter";
   import ApprenticeshipSubFooter from "./common/apprenticeshipSubFooter";

   /* NikeApprenticeshipContactUs Component initialization */
   class NikeApprenticeshipContactUs extends React.Component {
         /* Initializing objects of its NikeApprenticeshipContactUs class */
         constructor(props) {
           super(props);    
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
           MainController.getInitialInformation();
        }
   
       /* It is invoked to return html content */
       render() {
           return (
            <div className="corporateHousing_Updates">
                <GuestHeader/>
                <div className="cover-tophd-ht"></div>    
                <NikeApprenticeshipHeader />
                <div className="container-fluid bg-grey">
                    <div className="container">
                        <ContactUs />
                    </div>
                </div>
                <div className="container ft-contact-adrs mrg-btm-30 mrg-top-30">
                    <ApprenticeshipSubFooter />
                <div className="section-line"></div>
                    <ApprenticeshipFooter />
                </div>
                <SubFooter />
                <Footer/>
            </div>
           );
       }
   }
   export default NikeApprenticeshipContactUs;