/* Importing the node modules, child components, services and controllers used 
   inside LockheedHeader component */
   import {Link} from 'react-router-dom';
   import MainController from '../controller/mainController';
   
   /* LockheedHeader Component initialization */
   class LockheedHeader extends React.Component {
       /* Initializing objects of its LockheedHeader class */
       constructor(props) {
           super(props);
       }
       /* It is invoked immediately after a component is mounted */
       componentDidMount() {
           MainController.removeClassOnNavCollapse();
       }
       /* It is invoked to return html content */  
       render() {
           return (
               <div>
            <div className="profile_submenu">
                <div className="container">
                    <nav className="navbar navbar-inverse">
                        <div className="navbar-header">
                            <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#guest-login" aria-expanded="false">
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                        </div>
                        <div className="collapse navbar-collapse" id="guest-login">
                            <ul className="nav navbar-nav">
                                <li><Link to="/company/lockheed-martin/home">Home</Link></li>
                                <li><Link to="/company/lockheed-martin/whats-included">What’s Included</Link></li>
                                <li><Link to="/company/lockheed-martin/added-value">Added Value</Link></li>
                                <li><Link to="/company/lockheed-martin/online-management">Online Management</Link></li>
                                <li><Link to="/company/lockheed-martin/locations">Locations</Link></li>
                                <li><Link to="/company/lockheed-martin/reservation-form">Reservation Form</Link></li>
                                <li><Link to="/company/lockheed-martin/frequent-questions">Frequent Questions</Link></li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>   
    <div className='subHeaderHeight'></div>
    </div>
           );
       }
   }
   export default LockheedHeader;