import GuestHeader from "../../common/guestHeader";
import LocationService from "../../../services/locationService";
import AppController from "../../controller/appController";
import MapController from "../../controller/mapController";
import SmoothScrolling from "./smoothScrolling";
import FloorPlan from "./floorPlans";
import NeighBoorHood from "./neighborHood";
import Amenities from "./amenities";
import PhotoGallery from "./photoGallery";
import Home from "./home";
import FloorModal from "./floorModal";
import PhotoModal from "./photoModal";
import CommunityList from "./communitiesList";
import ModalVideo from "./modalVideo";
import AmenitiesModal from "./amenitiesModal";
import Footer from "../../common/footer";
import SubFooter from "../../common/subFooter";
import Faqs from "./faqs";
import Latchaccess from "./latchAccess";
import ParkingInfo from "./parkingInfo";
import DatePicker from 'react-datepicker'
import moment from 'moment'
import NikeServices from "../../../services/nikeService";
import TravelBookingLogin from '../starbucksTravel/login';

class CartusNolo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      communityInfo: this.props.match.path == "/starbucks-business-travel-suites" ? CommunityList["/thenolo"] : CommunityList[this.props.match.path],
      communityRequest: {
        communityName: this.props.match.path == "/starbucks-business-travel-suites" ? CommunityList["/thenolo"].Community : CommunityList[this.props.match.path].Community,
        imageType: "banner"
      },
      communityResponse: {},
      greatSchoolsResponse: {},
      walkScoreResponse: [],
      loader: false,
      communityResponseloader: false,
      poi: [],
      communityName: '',
      latitude: 0.0,
      longitude: 0.0,
      width: 0,
      communityDetails: false,
      floorModal: false,
      photomodal: false,
      floorObject: {},
      dataId: 0,
      floor3d: false,
      floor3dData: {},
      amenitiesModal: false,
      amenitesImage: '',
      defaultAmenities: ['Fitness Center', 'Dog Park', 'Spa / Hot Tub', 'Swimming Pool', 'Business Center', 'Electric Car Charging Stations', 'Picnic area', 'Game Room'],
      faqsModal: false,
      latchAccessModal: false,
      parkingInfoModal: false,
      checkIn: null,
      checkOut: null,
      firstName: '',
      lastName: '',
      phoneNo: '',
      emailId: '',
      reservedbyemail: '',
      costcenter: '',
      flight: '',
      ArrivalAirport: '',
      ArrivalDate: null,
      airline: '',
      notes: '',
      loadingStatus: false,
      errorMessage: '',
      color: '',
      successmessage: false
    };

    this.linkAddress = this.linkAddress.bind(this);
    this.amenities = this.amenities.bind(this);
    this.scrollUp = this.scrollUp.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.floorCorousel = this.floorCorousel.bind(this);
    this.gallaeryCorousel = this.gallaeryCorousel.bind(this);
    this.floorImages = this.floorImages.bind(this);
    this.headerChange = this.headerChange.bind(this);
    this.follorIn3d = this.follorIn3d.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.amentiesModalPopup = this.amentiesModalPopup.bind(this);
    this.checkInSelection = this.checkInSelection.bind(this);
    this.checkOutSelection = this.checkOutSelection.bind(this);
    this.setValue = this.setValue.bind(this);
    this.setNumber = this.setNumber.bind(this);
    this.selectionArrivalDate = this.selectionArrivalDate.bind(this);
    this.pdfGeneration = this.pdfGeneration.bind(this);
    this.booknowLogin = this.booknowLogin.bind(this);


  }

  async communityInfo() {
    const requestObj = this.state.communityRequest;
    console.log('requestObj', requestObj)
    let data = await LocationService.getCommunityInfoByName(
      requestObj
    );
    console.log('communityData', data)

    let responseObj = JSON.parse(data.AmenitiesList)
    let obj = { aparment_amenity_name: "Electric Car Charging Stations", communityname: "Community" }
    responseObj.splice(18, 0, obj);
    responseObj = JSON.stringify(responseObj)

    data["AmenitiesList"] = responseObj

    // data.latitude = 47.597870;
    // data.longitude = -122.332900;

    await this.setState({
      communityResponse: data,
      communityResponseloader: true
    });
    Object.keys(this.state.communityResponse).length > 0
      ? this.thirdPartyApis(this.state.communityResponse)
      : "";
    Object.keys(this.state.communityResponse).length > 0
      ? this.map(this.state.communityResponse)
      : "";
  }
  async thirdPartyApis(communityData) {
    const greatSchoolsRes = await AppController.greatSchools(communityData);
    const walkScoreRes = await AppController.wallsckoreFun(communityData);
    this.weather(communityData);
    this.poi(communityData)
    if (
      walkScoreRes != undefined &&
      walkScoreRes != null &&
      walkScoreRes != 0
    ) {
      this.setState({
        loader: true,
        greatSchoolsResponse: greatSchoolsRes,
        walkScoreResponse: [
          {
            Score:
              walkScoreRes.walkscore == undefined
                ? null
                : walkScoreRes.walkscore,
            description:
              walkScoreRes.description == undefined
                ? null
                : walkScoreRes.description,
            name: "WalkScore",
            chartName: "chart1" + communityData.community_id,
            colour: "#fab400",
            image: "WalkScore.png"
          },
          // {
          //   Score:
          //     walkScoreRes.bike == undefined ? null : walkScoreRes.bike.score,
          //   description:
          //     walkScoreRes.bike == undefined
          //       ? null
          //       : walkScoreRes.bike.description,
          //   name: "BikeScore",
          //   chartName: "chart3" + communityData.community_id,
          //   colour: "#008000",
          //   image: "BikeScore.png"
          // }
        ]
      });
      const walkScoreDetails = this.state.walkScoreResponse;
      for (var i = 0; i < walkScoreDetails.length; i++) {
        AppController.doughnutChart(walkScoreDetails[i]);
      }
    } else {
      this.setState({
        loader: true,
        greatSchoolsResponse: greatSchoolsRes,
        walkScoreResponse: []
      });
    }
  }
  async map(communityData) {
    // var locations = {
    //   lat: Number(communityData.latitude),
    //   lng: Number(communityData.longitude)
    // };
    //communityData.latitude = Number('47.597870');
    //communityData.longitude = Number('-122.332900');
    var locations = {
      lat: Number(communityData.latitude),
      lng: Number(communityData.longitude)
    };
    if ($("#map" + communityData.community_id).length) {
      const customMap = new google.maps.Map(document.getElementById("map" + communityData.community_id), {
        zoom: 17,
        center: locations,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      });

      await this.setState({ customMap });
      MapController.customMarkers(
        communityData,
        "singlemark",
        this,
        "",
        "https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/location_pin_2.svg",
        customMap
      );
    }
  }
  async componentWillMount() {
    AppController.removeLocalStorageData();
    $('html, body').animate({ scrollTop: 0 });
    AppController.stickyTitles();
    await this.communityInfo();
    await AppController.floorThumbs(this.state.communityResponse.community_id);

    // let CommunityImages=JSON.parse(this.state.communityResponse.CommunityImages)
    //   let ids=CommunityImages[0].image_name
    //   await localStorage.setItem('item', ids)
    await this.setState({ photomodal: true })
    // AppController.deanThumbs(15);
    console.log('-------9999')
  }

  amenities(amenities, type) {


    const { defaultAmenities } = this.state
    const url = this.props.match.path;
    var amenities = JSON.parse(amenities);
    var filteredAmenites =
      amenities != null && amenities != undefined
        ? _.filter(amenities, function (o) {
          return o.communityname == type;
        })
        : [];
    return (
      <ul>
        {filteredAmenites.map((row, index) => {
          let res = _.find(defaultAmenities, function (o) {
            return o == row.aparment_amenity_name && url == '/thedean';
          });

          //return <li key={index}>{res==undefined? row.aparment_amenity_name:row.aparment_amenity_name+'*'}</li>;
          return <li key={index}>{res == undefined ? row.aparment_amenity_name : row.aparment_amenity_name}</li>;
        })}
      </ul>
    );
  }
  scrollUp(id) {
    SmoothScrolling.scrollTo(id);
  }
  async weather(apidata) {
    const weather = await AppController.weather(apidata)

    var results = weather.list.map(function (el) {
      var o = Object.assign({}, el);
      o.date = o.dt_txt.split(" ")[0];
      return o;
    })
    var data1 = _.chain(results)
      .groupBy("date")
      .map(function (currentItem) {
        let minTEMP = _.minBy(currentItem, function (o) {
          return o.main.temp_min;
        });
        let maxTEMP = _.maxBy(currentItem, function (o) {
          return o.main.temp_max;
        });
        return {
          weatherData: currentItem[0],
          weatherDataMax: maxTEMP.main.temp_max,
          weatherDataMin: minTEMP.main.temp_min
        }
      }).value();
    this.setState({
      weather: data1.splice(1, 4),
      weather_current: data1[0].weatherData,
      weather_currentCityDetails: weather.city,
    })
  }
  async poi(apidata) {
    const data = {
      city: apidata.city_name
    }
    const poi = await AppController.poi(data)

    this.setState({ poi: poi.results })
  }

  handleFormSubmit() {

  }
  floorCorousel(data) {
    AppController.deanThumbs(data);

  }
  async gallaeryCorousel(id) {
    await localStorage.setItem('item', id)
    await this.setState({ photomodal: true })
    AppController.deanThumbs(15);
  }
  floorImages(row) {
    this.setState({ floorModal: true, floorObject: row })
  }
  scrollToDiv() {
    $('html,body').animate({
      scrollTop: $(".deanresponsive-tabs").offset().top
    }, 500);
    return false;
  }

  async headerChange(id) {
    $('.tab-pane').removeClass('panel-collapse collapse in');
    this.scrollToDiv()
    await this.setState({
      dataId: id,
      floorModal: false,
      floor3d: false,
      amenitiesModal: false,
      photomodal: false,
      faqsModal: false,
      latchAccessModal: false,
      parkingInfoModal: false
    })
    id == 1 ? this.floorCorousel(0) : ''
    if (id == 4) {
      // let CommunityImages=JSON.parse(this.state.communityResponse.CommunityImages)
      // let ids=CommunityImages[0].image_name
      // await localStorage.setItem('item', ids)
      await this.setState({ photomodal: true })
      // AppController.deanThumbs(15);
    } if (id == 6) {
      this.setState({ parkingInfoModal: true })
    }
    if (id == 7) {
      this.setState({ latchAccessModal: true })
    }
    if (id == 8) {
      this.setState({ faqsModal: true })
    }
  }
  follorIn3d(row) {
    this.setState({ floor3d: true, floor3dData: row })
  }
  closeModal() {
    $('#sync1' + 15).removeAttr('id');
    $('#sync2' + 15).removeAttr('id');
    this.setState({ floor3d: false, floor3dData: {}, photomodal: false })
  }

  amentiesModalPopup(image) {
    this.setState({ amenitiesModal: true, amenitesImage: image })
  }
  linkAddress(link) {
    localStorage.setItem('amenitieslink', link)
  }

  async componentDidMount() {
    var component = this;
    $('.latchAccess').click(function (e) {
      $('#tab7').addClass('tab-pane animated rollIn  panel-collapse collapse active');
      $('#presentationTab7').addClass("active")
      component.headerChange(7)
    })

    $('.neighborhood').click(function (e) {
      $('#tab2').addClass('tab-pane animated rollIn  panel-collapse collapse active');
      $('#presentationTab2').addClass("active")
      component.headerChange(2)
    })
    function toggleIcon(e) {
      $(e.target)
        .prev('.panel-heading')
        .find(".short-full")
        .toggleClass('glyphicon-plus glyphicon-minus');
    }
    $('.panel-group').on('hidden.bs.collapse', toggleIcon);
    $('.panel-group').on('shown.bs.collapse', toggleIcon);
    await this.setState({ photomodal: true })
    $(document).ready(function () {
      jQuery(function ($) {
        $("#phone2").intlTelInput();
      });
    });
  }



  async booknowModel() {
    await this.setState({
      checkIn: null,
      checkOut: null,
      firstName: '',
      lastName: '',
      phoneNo: '',
      emailId: '',
      reservedbyemail: '',
      costcenter: '',
      flight: '',
      ArrivalAirport: '',
      ArrivalDate: null,
      airline: '',
      notes: '',
      loadingStatus: false,
      errorMessage: '',
      color: '',
      successmessage: false
    })
    $(document).ready(function () {
      jQuery(function ($) {
        $("#phone2").intlTelInput();
      });
    });
    $('#travelbooknow').modal('show');
  }
  setNumber(e) {
    const re = /^[0-9\b]+$/;
    if (e.target.value == '' || re.test(e.target.value)) {
      this.setState({ phoneNo: e.target.value })
    }
  }
  async checkInSelection(date) {
    await this.setState({ checkIn: moment(date), errorMessage: '' });
  }
  async checkOutSelection(date) {
    await this.setState({ checkOut: moment(date), errorMessage: '' });
  }
  setValue(field, e) {
    var object = {};
    object[field] = e.target.value;
    object['errorMessage'] = ''
    this.setState(object);
  }
  async selectionArrivalDate(date) {
    await this.setState({ ArrivalDate: moment(date) });
  }

  async bookDetails() {
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    const phoneReg = /^[0-9\b]+$/;
    const validationFields = ['firstName', 'lastName', 'emailId', 'phoneNo', 'reservedbyemail', 'checkIn', 'checkOut']
    let _this = this
    let error = 0;
    _.forEach(validationFields, function (value) {
      if (_this.state[value] == "" || _this.state[value] == null) {
        error++;
      }
    });
    if (!reg.test(_this.state.emailId)) {
      error++;
    }
    if (!reg.test(_this.state.reservedbyemail)) {
      error++;
    }
    if (!phoneReg.test(_this.state.phoneNo)) {
      error++;
    }
    if (error == 0) {
      await this.setState({ loadingStatus: true })
      const countryCode = $(".country.highlight.active").attr("data-dial-code") == undefined
        ? "+1"
        : "+" + $(".country.highlight.active").attr("data-dial-code")
      let phoneNo = this.state.phoneNo
      var x = phoneNo.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      phoneNo = !x[2] ? x[1] : ('(' + x[1] + ') ' + x[2] + (x[3] && '-' + x[3] || ''))

      var Requestobj = {
        checkIn: this.state.checkIn.format('MM-DD-YYYY'),
        checkOut: this.state.checkOut.format('MM-DD-YYYY'),
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        phoneNo: countryCode + ' ' + phoneNo,
        emailId: this.state.emailId,
        reservedbyemail: this.state.reservedbyemail,
        costcenter: this.state.costcenter != '' ? this.state.costcenter : '----',
        flight: this.state.flight != '' ? this.state.flight : '----',
        ArrivalAirport: this.state.ArrivalAirport != '' ? this.state.ArrivalAirport : '----',
        ArrivalDate: this.state.ArrivalDate != null ? this.state.ArrivalDate.format('YYYY-MM-DD HH:mm') : '----',
        airline: this.state.airline != '' ? this.state.airline : '----',
        notes: this.state.notes != '' ? this.state.notes : '----',

        mail: 'starbucksthenolo',
        subject: 'Travel Booking'
      }
      const Response = await NikeServices.serviceRequest(Requestobj)
      await this.setState({ loadingStatus: false, successmessage: true })


    } else {
      this.setState({
        errorMessage: 'Please Enter Required Fields, Valid Email and Phone No',
        color: 'red'
      });
    }
  }
  async pdfGeneration() {
    let url = 'https://suiteam-website.s3.us-west-1.amazonaws.com/PDF/Starbucks_Business_Travel_Suites_FAQ_FINAL.pdf'
    await fetch(url).then(function (t) {
      return t.blob().then((b) => {
        var a = document.createElement("a");
        a.href = URL.createObjectURL(b);
        a.setAttribute("download", 'Starbucks_Business_Travel_Suites_FAQ_FINAL.pdf');
        a.click();
      }
      );
    });
  }

  async booknowLogin() {
    localStorage.removeItem("umpqua-email");
    localStorage.removeItem("umpqua-password");
    localStorage.removeItem("umpqua-isLogin");
    const data = {
      "email": "starbucks@suiteamerica.com",
      "password": "$tarbucks$uite1",
      "isLogin": "false"
      //logintype:"STARBUCKS"
    }
    localStorage.setItem('starbuckstravel-email', Base64.encode(data.email));
    localStorage.setItem('starbuckstravel-password', Base64.encode(data.password));
    localStorage.setItem('starbuckstravel-isLogin', data.isLogin);
    location.href = '/availability-calendar';

  }
  render() {
    const url = this.props.match.path;
    return (
      <div>
        {/* <GuestHeader /> */}

        <div className="deanpage altairDean altairDeanBlue">
          <div className="deanpage_banner starBucksbanner">
            <img className="altair-deanClogo starBucks" src="/assets/images/DeanImg/Starbucks-logo.png" />
            <a className="siteMiddleBookNow" onClick={() => this.booknowLogin()}>Book Now</a>

            {/* <a className="siteMiddleBookNow" onClick={()=>this.booknowModel()}>Book Now</a> */}
            <img className="altair-deanClogo S_logo" src="/assets/newUI-images/logo/SA_S-logo-W.svg" alt="" />
            <div className="deanbannerimg">

              <img src="https://s3-us-west-1.amazonaws.com/suite-ch/19739/19739_b.jpg" />

            </div>
            <div className="banner_text thenolo">
              <h2>  <span className="headstarbucks">Starbucks Business Travel Suite @ Premiere On Pine </span></h2>
              <div className="text_bg">
                <h1 className="ribbon">
                  <strong className="ribbon-content">
                    {/* {this.state.communityInfo.BanerName} */}
                    Exclusive Access to the Most Luxurious Silicon Valley Community
                  </strong>
                </h1>
                <div className="threeborders" />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="deanbeerbottom">
              <div className="bannerBottom"> <h1>
                {/* {this.state.communityInfo.HeadLine} */}
                Welcome to Starbucks Travel Suite @ Premiere On Pine – a convenient solution for <span className="starB">VP+</span> partners traveling to the SSC and ideal for partners staying 1 week or longer.
              </h1>
                <div>
                  <p>Enjoy Premiere On Pine’s stunning location to take in the best of the city. Seattle’s beautiful waterfront, iconic markets, restaurants, Starbucks Reserve Roastery and more are an arm’s length away from this perfect downtown location. The suite includes a view of Pine St, Pike Market and the Puget Sound.</p>
                  <p>The Travel Suite can be booked for any length of stay and is designed for those that are staying for longer business trips. The full kitchen, washer and dryer, living and dining areas provide extra space to relax, work, or watch a movie on one of two televisions, and the mini bar provides coffee (of course!), snacks, easy breakfast options, water, and juices to enjoy during your stay.</p>
                  <p>The electronic booking site is ready for use. Click the “Book Now” button above to view the availability calendar and request a reservation. You can also email us at  <a href="mailto:starbucksbtsuites@suiteamerica.com" className="mailtoLink">starbucksbtsuites@suiteamerica.com</a> so that a team member can assist you with availability and reservations. The cost for the Travel Suite is a daily rate of $194.00 plus a one-time cleaning and restocking fee of $225.00, which will be billed back to your cost center.</p>
                  {/* <p>While the electronic booking site is under construction, please email us with your desired dates of stay at <a href="mailto:starbucksbtsuites@suiteamerica.com" className="mailtoLink">starbucksbtsuites@suiteamerica.com</a> so that a team member can assist you with availability and reservations. The cost for the Travel Suite is a daily rate of $177.00 plus a one-time cleaning and restocking fee of $225.00, which will be billed back to your cost center.</p> */}
                  <p className="query">Click on the tabs below for more information that will help make any stay in the Travel Suite comfortable and enjoyable.</p>
                </div>
              </div>

              <div className="responsive-tabs deanresponsive-tabs">
                <ul className="nav nav-tabs deantabss" role="tablist">
                  <li role="presentation" className="">
                    <a href="#tab3" aria-controls="tab3" role="tab" data-toggle="tab" onClick={() => this.headerChange(3)}>Amenities & Inclusions </a>
                  </li>
                  <li role="presentation" className="active">
                    <a href="#tab4" aria-controls="tab4" role="tab" data-toggle="tab" onClick={() => this.headerChange(4)}> Photos </a>
                  </li>
                  {/* <li role="presentation" className="">
								<a href="#tab6" aria-controls="tab6" role="tab" data-toggle="tab" onClick={()=>this.headerChange(6)}> Parking Info </a>
							</li> */}
                  {/* <li role="presentation" id="presentationTab7" className="">
								<a href="#tab7" aria-controls="tab7" role="tab" data-toggle="tab" onClick={()=>this.headerChange(7)}> Latch Access </a>
							</li> */}
                  <li role="presentation" id="presentationTab2" className="">
                    <a href="#tab2" aria-controls="tab2" role="tab" data-toggle="tab" onClick={() => this.headerChange(2)}> Neighborhood </a>
                  </li>
                  <li role="presentation" className="">
                    <a href="#tab8" aria-controls="tab8" role="tab" data-toggle="tab" onClick={() => this.headerChange(8)}> FAQs </a>
                  </li>
                  <li role="presentation" className="">
                    <a href="#tab7" aria-controls="tab7" role="tab" data-toggle="tab" onClick={() => this.headerChange(7)}> Contact US </a>
                  </li>
                </ul>
                <div id="tabs-content" className="tab-content panel-group">
                  <div className="panel-heading" role="tab" id="heading3">
                    <a href="#tab3" className="collapsed" role="button" data-toggle="collapse" data-parent="tabs-content"
                      aria-expanded="true" aria-controls="tab3" onClick={() => this.headerChange(3)}>Amenities & Inclusions</a>
                  </div>
                  <div id="tab3" role="tabpanel" className="tab-pane animated  zoomIn panel-collapse collapse" aria-labelledby="heading3">

                    {/* <Amenities getdata={"thenolo"}{...this.state} amenities={this.amenities} amentiesModalPopup={this.amentiesModalPopup}/> */}

                    <div>
                      <div className=" mg-t-20 mg-b-40" id="AMENITIES" />
                      <div className="dean_amenities">
                        <div className="dean_amenities">

                          <div className="row">
                            <div className="col-md-6 col-sm-6">
                              <h1 className="text-left">Communal Amenities </h1>
                              <ul>
                                <li>40th Floor Rooftop Terrace with Outdoor Kitchen and Seating</li>
                                <li>40th Floor Resident Lounge with Kitchen, Pool Table & Shuffleboard</li>
                                <li>Community Theater</li>
                                <li>24/7 Concierge Ready to Assist your needs</li>
                                <li>Pet Friendly Community with Dog Park & Lounge</li>
                                <li>24/7 - Fitness Center & Classes</li>
                                <li>Business Center & Private Conference Room</li>
                                <li>Event Space with Exclusive Outdoor Terrace</li>
                                <li>Bike Racks & Storage</li>
                                <li>Work-From-Home Lounge</li>
                                <li>Wi-Fi in Common Areas</li>
                              </ul>
                            </div>

                            <div className="col-md-6 col-sm-6">
                              <h1 className="text-left">Apartment Amenities </h1>
                              <ul>
                                <li>731 Sq. Ft.</li>
                                <li>High-End Finishes</li>
                                <li>Stainless-Steel Appliances</li>
                                <li>Mobile Kitchen Island</li>
                                <li>Quartz Countertops with Backsplash</li>
                                <li>Washer and Dryer</li>
                                <li>Air Conditioning</li>
                                <li>King-size bed</li>
                                <li>Coffee maker with Starbucks coffee and tea options</li>
                                <li>Blender</li>
                                <li>Smart TV with Apple TV</li>
                                <li>YouTube TV</li>
                                <li>Large closet</li>
                                <li>Desk</li>
                                <li>Hair dryer</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <h6>
                          <span> &#9670; </span>  {this.state.communityInfo.AMENITIES.Heading}
                          <span> &#9670; </span>
                        </h6>


                        <div className="imporantThingBox">
                          {this.state.communityInfo.AMENITIES.Data.map((row, index) => {
                            return (
                              <div className="itemBox" key={index} >
                                <div className={row.ModalImage != '' ? "iteamdeanam cursor-pointer" : "iteamdeanam"}>
                                  <img className={row.Name == 'Clubhouse' ? "img-responsive " : "img-responsive"} src={row.Image} data-toggle="modal"
                                    data-target={row.ModalImage != '' ? "#amenitesImages" : ''} onClick={row.ModalImage != '' ? () => props.amentiesModalPopup(row.ModalImage) : null} />
                                  <h1>{row.Name}</h1>
                                  <p>{row.Content}</p>
                                </div>
                              </div>
                            )
                          })}
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="panel-heading" role="tab" id="heading4">
                    <a href="#tab4" className="collapsed" role="button" data-toggle="collapse" data-parent="tabs-content"
                      aria-expanded="true" aria-controls="tab4" onClick={() => this.headerChange(4)}>Photos </a>
                  </div>
                  <div id="tab4" role="tabpanel" className="tab-pane animated rotateIn active panel-collapse collapse" aria-labelledby="heading4">
                    {/* <PhotoGallery {...this.state} gallaeryCorousel={this.gallaeryCorousel} /> */}
                    {this.state.photomodal ?
                      <ul className="starbucksPhotes">

                        <li><img src="https://suiteam-website.s3.us-west-1.amazonaws.com/images/starbucks/1.jpg" alt="" /></li>
                        <li><img src="https://suiteam-website.s3.us-west-1.amazonaws.com/images/starbucks/2.jpeg" alt="" /></li>
                        <li><img src="https://suiteam-website.s3.us-west-1.amazonaws.com/images/starbucks/3.jpeg" alt="" /></li>
                        <li><img src="https://suiteam-website.s3.us-west-1.amazonaws.com/images/starbucks/4.jpeg" alt="" /></li>
                        <li><img src="https://suiteam-website.s3.us-west-1.amazonaws.com/images/starbucks/5.jpeg" alt="" /></li>
                        <li><img src="https://suiteam-website.s3.us-west-1.amazonaws.com/images/starbucks/6.jpeg" alt="" /></li>
                        <li><img src="https://suiteam-website.s3.us-west-1.amazonaws.com/images/starbucks/7.jpeg" alt="" /></li>
                        <li><img src="https://suiteam-website.s3.us-west-1.amazonaws.com/images/starbucks/8.jpeg" alt="" /></li>
                        <li><img src="https://suiteam-website.s3.us-west-1.amazonaws.com/images/starbucks/9.jpeg" alt="" /></li>
                        <li><img src="https://suiteam-website.s3.us-west-1.amazonaws.com/images/starbucks/10.jpeg" alt="" /></li>
                        <li><img src="https://suiteam-website.s3.us-west-1.amazonaws.com/images/starbucks/11.jpeg" alt="" /></li>
                        <li><img src="https://suiteam-website.s3.us-west-1.amazonaws.com/images/starbucks/12.jpg" alt="" /></li>
                        <li><img src="https://suiteam-website.s3.us-west-1.amazonaws.com/images/starbucks/13.jpg" alt="" /></li>
                        <li><img src="https://suiteam-website.s3.us-west-1.amazonaws.com/images/starbucks/14.jpg" alt="" /></li>
                        <li><img src="https://suiteam-website.s3.us-west-1.amazonaws.com/images/starbucks/15.jpg" alt="" /></li>

                      </ul>
                      : <div></div>}
                  </div>
                  {/* <div className="panel-heading" role="tab" id="heading6">
													<a href="#tab6" className="collapsed" role="button" data-toggle="collapse" data-parent="tabs-content" aria-expanded="true"
													   aria-controls="tab6" onClick={()=>this.headerChange(6)}>Parking Info</a>
												</div>
												<div id="tab6" role="tabpanel" className="tab-pane animated rollIn  panel-collapse collapse " aria-labelledby="heading6" >
                        <ParkingInfo/>
												</div> */}
                  <div className="panel-heading" role="tab" id="heading7">
                    <a href="#tab7" className="collapsed" role="button" data-toggle="collapse" data-parent="tabs-content" aria-expanded="true"
                      aria-controls="tab7" onClick={() => this.headerChange(7)}>Contact Us</a>
                  </div>
                  <div id="tab7" role="tabpanel" className="tab-pane animated rollIn  panel-collapse collapse contactUS" aria-labelledby="heading7" >
                    <h1>SuiteAmerica contact information</h1>
                    <p>Reservations site:<a href="https://suiteamerica.com/starbucks-business-travel-suites" target={"_blank"}> https://suiteamerica.com/starbucks-business-travel-suites</a></p>
                    <p>Guest Services: <a href="tel:+18003679501">800-367-9501</a></p>
                    <p> Questions, reservation requests, and reservations changes can be emailed to:
                      <a href="mailto:StarbucksBTSuites@SuiteAmerica.com" className="d-block">StarbucksBTSuites@SuiteAmerica.com</a> </p>
                  </div>
                  <div className="panel-heading" role="tab" id="heading2">
                    <a href="#tab2" className="collapsed" role="button" data-toggle="collapse" data-parent="tabs-content"
                      aria-expanded="true" aria-controls="tab2" onClick={() => this.headerChange(2)}>Neighborhood</a>
                  </div>
                  <div id="tab2" role="tabpanel" className="tab-pane animated bounceInUp panel-collapse collapse" aria-labelledby="heading2">
                    <NeighBoorHood {...this.state} handleFormSubmit={this.handleFormSubmit} />
                  </div>


                  <div className="panel-heading" role="tab" id="heading8">
                    <a href="#tab8" className="collapsed" role="button" data-toggle="collapse" data-parent="tabs-content" aria-expanded="true"
                      aria-controls="tab8" onClick={() => this.headerChange(8)}>FAQs</a>
                  </div>
                  <div id="tab8" role="tabpanel" className="tab-pane animated rollIn  panel-collapse collapse " aria-labelledby="heading8" >
                    {/* <Faqs/> */}
                  </div>








                  {/* <div role="tab" id="heading4">
                    <a href="#tab5" className="collapsed" role="button" data-toggle="collapse" data-parent="tabs-content"
                      aria-expanded="true" aria-controls="tab4" ></a>
                  </div>
                  <div id="tab5" role="tabpanel" className="tab-pane animated bounceInUp  panel-collapse collapse in" aria-labelledby="heading1">
                    {this.state.dataId == 0 && <Home {...this.state} GotoLatchAccess={() => this.headerChange(7)} />}
                  </div> */}
                  {/*  {this.state.dataId==0&&<div><button type="button" className="btn main-btn">Contact Your Altair Consultant</button></div>} */}

                </div>
              </div>
            </div>
            {this.state.floorModal ? <FloorModal floorObject={this.state.floorObject} /> : ''}
            {this.state.floor3d ? <ModalVideo {...this.state} closeModal={this.closeModal} /> : ''}
            {/* {this.state.photomodal? <PhotoModal closeModal={this.closeModal} CommunityImages={JSON.parse(this.state.communityResponse.CommunityImages)}/>:''} */}
            {this.state.amenitiesModal ? <AmenitiesModal {...this.state} /> : ''}
            {this.state.faqsModal ?
              <div>
                {/* <a className="pdfdown" onClick={() => this.pdfGeneration()} >Download a PDF of Fun Facts and FAQ's</a> */}
                <div className="faqView">
                  <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="headingOne">
                        <h4 className="panel-title">
                          <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            {/* <i className="short-full glyphicon glyphicon-plus" /> */}
                            What is the Starbucks Travel Suite program with SuiteAmerica?
                          </a>
                        </h4>
                      </div>
                      <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                        <div className="panel-body">
                          Starbucks business travel group has secured a furnished apartment near Starbucks Support Center (SSC) in Seattle to provide comfortable accommodations for VP+ travelers. The one-bedroom King suite is equipped with a full kitchen, washer/dryer, living room, dining table, dishes, towels, coffee and tea, and mini bar type items. Think hotel room with more space!
                        </div>
                      </div>
                    </div>
                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="headingTwo">
                        <h4 className="panel-title">
                          <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            {/* <i className="short-full glyphicon glyphicon-plus" /> */}
                            Why did Starbucks decide to offer the apartment for business travel?
                          </a>
                        </h4>
                      </div>
                      <div id="collapseTwo" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                        <div className="panel-body">
                          To offer executive-level partners traveling to the SSC a hotel alternative that offers more space and a kitchen for self-service meals. Hotel rates in Seattle continue to increase, so having exclusive access to the suite will help control the budget while also offering a premium travel experience.
                        </div>
                      </div>
                    </div>
                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="headingThree">
                        <h4 className="panel-title">
                          <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            {/* <i className="short-full glyphicon glyphicon-plus" /> */}
                            How do I book the Starbucks Travel Suite?
                          </a>
                        </h4>
                      </div>
                      <div id="collapseThree" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                        <div className="panel-body">
                          SuiteAmerica hosts a web-based info and reservation microsite with photos and descriptions.  Clicking the “Book Now” button above will take you to the reservations calendar that shows real-time availability. The link to the microsite is <a href="https://suiteamerica.com/Starbucks-business-travel-suites" target={"_blank"}> https://suiteamerica.com/Starbucks-business-travel-suites </a> or you can email the SuiteAmerica team (<a href="mailto:starbucksbtsuites@suiteamerica.com">starbucksbtsuites@suiteamerica.com</a>) for assistance.
                        </div>
                      </div>
                    </div>
                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="headingFour">
                        <h4 className="panel-title">
                          <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                            {/* <i className="short-full glyphicon glyphicon-plus" /> */}
                            Who should consider staying in the Travel Suite?
                          </a>
                        </h4>
                      </div>
                      <div id="collapseFour" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFour">
                        <div className="panel-body">
                          Executives that are staying in Seattle for more than 1 week should look to book the Travel Suite before reserving a hotel. However, the suite is available for short-term stays as well. A general guideline for a minimum stay is around 3 days.
                        </div>
                      </div>
                    </div>
                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="headingFive">
                        <h4 className="panel-title">
                          <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                            {/* <i className="short-full glyphicon glyphicon-plus" /> */}
                            What is the cost of the Travel Suite?
                          </a>
                        </h4>
                      </div>
                      <div id="collapseFive" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFive">
                        <div className="panel-body">
                          The cost is a rate of $194.00 per night, plus a one-time fee of $225.00 for restocking and cleaning. There are no additional fees or taxes. Each business unit will be billed back for their partner’s stay and expensed to the cost center provided at the time of booking.
                        </div>
                      </div>
                    </div>
                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="headingSix">
                        <h4 className="panel-title">
                          <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                            {/* <i className="short-full glyphicon glyphicon-plus" /> */}
                            What information is required to book the Travel Suite?
                          </a>
                        </h4>
                      </div>
                      <div id="collapseSix" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSix">
                        <div className="panel-body">
                          SuiteAmerica will need to know the traveler’s name, date of arrival, date of departure, cost center number, month and day of birth, emergency contact info and whether they are bringing a Certified Service Animal. No credit card is required, and the entire booking can be completed via the electronic booking site. The Travel Suite does not have parking, so travelers will need to use a ride share service from the airport to the apartment community and to SSC.
                        </div>
                      </div>
                    </div>
                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="headingSeven">
                        <h4 className="panel-title">
                          <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                            {/* <i className="short-full glyphicon glyphicon-plus" /> */}
                            What is included in the Travel Suite?
                          </a>
                        </h4>
                      </div>
                      <div id="collapseSeven" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSeven">
                        <div className="panel-body">
                          The Travel Suite is a home away from home environment and is a full-size one-bedroom apartment that is fully furnished and outfitted with all the essentials needed when traveling. The suite has a washer/dryer in case laundry needs to be done, and a full-size kitchen for cooking or enjoying a food service delivery meal. Travelers should bring the same personal toiletries they would bring when staying in a hotel. We have provided a list of recommendations for eating in or eating out in the suite. There are lots of great options within walking distance from Premiere on Pine!
                        </div>
                      </div>
                    </div>
                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="headingEight">
                        <h4 className="panel-title">
                          <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                            {/* <i className="short-full glyphicon glyphicon-plus" /> */}
                            If a traveler must change or cancel their reservation, what is the cancellation policy?
                          </a>
                        </h4>
                      </div>
                      <div id="collapseEight" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingEight">
                        <div className="panel-body">
                          Because Starbucks “owns” the Travel Suite, there is no penalty for cancelling. Please email the SuiteAmerica team immediately so they can offer up the suite to another traveler. For changes to an existing reservation, please email the SuiteAmerica team so they can check to see if changes can be made without disrupting another incoming traveler.
                        </div>
                      </div>
                    </div>
                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="headingNine">
                        <h4 className="panel-title">
                          <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                            {/* <i className="short-full glyphicon glyphicon-plus" /> */}
                            What housekeeping services are provided when staying in the Travel Suite?
                          </a>
                        </h4>
                      </div>
                      <div id="collapseNine" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingNine">
                        <div className="panel-body">
                          The suite will be clean and ready for each partner’s arrival. The suite will be ready for next-day arrivals after each departure. There will be no daily housekeeping services during the stay, but on-demand housekeeping service for longer stays can be requested for an additional cost.
                        </div>
                      </div>
                    </div>
                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="headingTen">
                        <h4 className="panel-title">
                          <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                            {/* <i className="short-full glyphicon glyphicon-plus" /> */}
                            How much advance notice is needed to book a reservation?
                          </a>
                        </h4>
                      </div>
                      <div id="collapseTen" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTen">
                        <div className="panel-body">
                          SuiteAmerica asks for 7 days’ advance bookings to ensure that the suite is clean and ready after the last guest departure. If a reservation is needed within 7 days of arrival date, please email the SuiteAmerica team.
                        </div>
                      </div>
                    </div>
                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="headingTen15">
                        <h4 className="panel-title">
                          <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTen15" aria-expanded="false" aria-controls="collapseTen15">
                            {/* <i className="short-full glyphicon glyphicon-plus" /> */}
                            When will SuiteAmerica send apartment arrival information?
                          </a>
                        </h4>
                      </div>
                      <div id="collapseTen15" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTen15">
                        <div className="panel-body">
                          Travelers will receive an instant reservation confirmation when booking a suite and will also receive detailed arrival information 1-2 days before the arrival date. The details will include step-by-step instructions on how to retrieve the Travel Suite keys, access the building, and enter the suite.
                        </div>
                      </div>
                    </div>
                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="headingTen1">
                        <h4 className="panel-title">
                          <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTen1" aria-expanded="false" aria-controls="collapseTen1">
                            {/* <i className="short-full glyphicon glyphicon-plus" /> */}
                            How should travelers get to the Travel Suite from the airport and to the SSC?
                          </a>
                        </h4>
                      </div>
                      <div id="collapseTen1" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTen1">
                        <div className="panel-body">
                          Travelers should take a shared ride service (Lyft) from the airport to Premiere on Pine, where they will retrieve their keys from the 24/7 concierge. There is no parking space associated with this travel suite, so rental cars are discouraged. Travelers should take a shared ride service from Premiere on Pine to SSC. If you will be arriving with a vehicle, there is hourly/daily paid parking location at the Convention Center nearby, which is public parking so please park at your own risk.
                        </div>
                      </div>
                    </div>
                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id=". ">
                        <h4 className="panel-title">
                          <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTen2" aria-expanded="false" aria-controls="collapseTen2">
                            {/* <i className="short-full glyphicon glyphicon-plus" /> */}
                            How do Starbucks travelers access their suite?
                          </a>
                        </h4>
                      </div>
                      <div id="collapseTen2" className="panel-collapse collapse" role="tabpanel" aria-labelledby=". ">
                        <div className="panel-body">
                          Travelers will arrive to the Premiere on Pine entrance where you will locate “Leasing Office” in the call box and press “Call”. In the event nobody answers the call box, you can call the property 206-774-2880 and press 2. The concierge will buzz you into the building where you will retrieve your key envelope from the 24/7 concierge desk. Inside the envelope you will find one key fob that works for the elevator, exterior and front door. Detailed instructions will be emailed to you in advance of your arrival.
                        </div>
                      </div>

                    </div>



                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="headingTen3">
                        <h4 className="panel-title">
                          <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTen3" aria-expanded="false" aria-controls="collapseTen3">
                            {/* <i className="short-full glyphicon glyphicon-plus" /> */}
                            What happens if a traveler loses the Travel Suite key?
                          </a>
                        </h4>
                      </div>

                      <div id="collapseTen3" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTen3">
                        <div className="panel-body">
                          Please contact the SuiteAmerica team for assistance at 1-800-367-9501 24/7. A Guest Experience team member will be able to assist.
                        </div>
                      </div>
                    </div>
                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="headingTen4">
                        <h4 className="panel-title">
                          <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTen4" aria-expanded="false" aria-controls="collapseTen4">
                            {/* <i className="short-full glyphicon glyphicon-plus" /> */}
                            Can travelers bring a friend, family member, or a pet?
                          </a>
                        </h4>
                      </div>

                      <div id="collapseTen4" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTen4">
                        <div className="panel-body">
                          The Travel Suite is meant to provide accommodations for Starbucks partners working at the SSC. There are no restrictions around bringing a friend or family member to stay in the suite, but pets are not allowed unless they are Certified Service Animals.
                        </div>
                      </div>
                    </div>
                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="headingTen5">
                        <h4 className="panel-title">
                          <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTen5" aria-expanded="false" aria-controls="collapseTen5">
                            {/* <i className="short-full glyphicon glyphicon-plus" /> */}
                            How do I check out of the suite?
                          </a>
                        </h4>
                      </div>

                      <div id="collapseTen5" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTen5">
                        <div className="panel-body">
                          Please place your keys back in the envelope you received them in and return to the 24/7 hour concierge desk located on 1st floor lobby; the same location you gathered them from upon check in.
                        </div>
                      </div>
                    </div>
                  </div>{/* panel-group */}
                </div></div> : ''}
            {/* {this.state.latchAccessModal?<Latchaccess/>:''} */}
            {/* {this.state.parkingInfoModal?<ParkingInfo/>:''} */}
          </div>

        </div>
        {/* <SubFooter/>
        <Footer/> */}


        <div className="modal fade savesearch bookingmodel" id="travelbooknow" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content rm-border-radius">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="">
                  <span>×</span>
                </button>
              </div>

              {!this.state.successmessage ? <div>
                <h4 className="modal-title text-center font-bold">Book Now</h4>
                <div className="text-center" style={{ color: this.state.color }}>{this.state.errorMessage}</div>
                <div className="modal-body">
                  <form role="form" method="post" >
                    <div>

                      <div className="row">
                        {/* <div className="col-sm-12 communityName">
                                <span><strong>Community Name:</strong></span>
                                <span className="text-red">{this.props.communityObject.community_name}</span>
                            </div> */}
                        <div className="col-sm-6">
                          <div className="form-group">
                            <DatePicker
                              type="text"
                              className="form-control "
                              name="date"
                              placeholderText="Checkin Date *"
                              selected={this.state.checkIn}
                              minDate={moment()}
                              maxDate={this.state.checkOut && moment(this.state.checkOut).subtract(1, 'days')}
                              onChange={this.checkInSelection} onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                              autoComplete='off' />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <DatePicker
                              type="text"
                              className="form-control "
                              name="date"
                              placeholderText="Checkout Date *"
                              selected={this.state.checkOut}
                              minDate={this.state.checkIn ? moment(this.state.checkIn).add(1, 'days') : moment()}
                              onChange={this.checkOutSelection} onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                              autoComplete='off' />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <input type="text" className="form-control" placeholder="First Name *" value={this.state.firstName} onChange={(e) => this.setValue('firstName', e)} />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <input type="text" className="form-control" placeholder="Last Name *" value={this.state.lastName} onChange={(e) => this.setValue('lastName', e)} />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <input className="form-control" id="phone2" placeholder='Phone *' maxLength="10" onChange={(e) => this.setNumber(e)} value={this.state.phoneNo} />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <input type="text" className="form-control" placeholder="Guest Email *" value={this.state.emailId}
                              onChange={(e) => this.setValue('emailId', e)} />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <input type="text" className="form-control" placeholder="Reservedby Email *" value={this.state.reservedbyemail}
                              onChange={(e) => this.setValue('reservedbyemail', e)} />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <input type="text" className="form-control" placeholder="Cost Center" value={this.state.costcenter}
                              onChange={(e) => this.setValue('costcenter', e)} />
                          </div>
                        </div>
                      </div>

                      <hr></hr>



                      <div className="row">
                        <div className="col-sm-12 communityName">
                          <span><strong>Arrival Info:</strong></span>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <input type="text" className="form-control" placeholder="Flight#" value={this.state.flight}
                              onChange={(e) => this.setValue('flight', e)} />
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="form-group">
                            <input type='text' className="form-control" placeholder="ArrivalAirport" value={this.state.ArrivalAirport} onChange={(e) => this.setValue('ArrivalAirport', e)} />
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="form-group">
                            <DatePicker type="text" className="form-control" name="date"
                              placeholderText="ArrivalDate"
                              selected={this.state.ArrivalDate}
                              onChange={(e) => this.selectionArrivalDate(e)}
                              showTimeSelect
                              timeFormat="HH:mm"
                              timeIntervals={15}
                              timeCaption="time"
                              dateFormat="MM/DD/YYYY HH:mm"
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                              autoComplete='off'
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <input type="text" className="form-control" placeholder="Airline" value={this.state.airline}
                              onChange={(e) => this.setValue('airline', e)} />
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="form-group">
                            <textarea className="form-control" placeholder="Arrival Notes" value={this.state.notes} onChange={(e) => this.setValue('notes', e)}></textarea>
                          </div>
                        </div>
                      </div>
                      <small className="smalltext">*The cost for the Travel Suite is a daily rate of $177.00 plus a one time cleaning and restocking fee of $225.00.</small>
                      <div className="clearfix"></div>
                      {this.state.loadingStatus ? <div
                        className='text-center'
                        style={{ fontSize: '25px', width: '100%', margin: 'auto', textAlign: 'center' }}
                      ><i className="fa fa-spinner fa-spin" />
                        Please wait ...!</div> : <button className="btn location_btn mrg-btm-50" type="button" onClick={() => { this.bookDetails() }}>Submit</button>}
                    </div>

                  </form>
                  <div className="clearfix"></div>
                </div>
              </div> : <div className="modal-body">
                <h2>Thank you for your request. We’ll check availability and get back to you with additional information.</h2>
              </div>}
            </div>
          </div>
        </div>
        <TravelBookingLogin />
      </div>
    );
  }
}
export default CartusNolo;