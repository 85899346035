import CommunityBookingInfo from "./components/communityBookingInfo";
import GuestForm from "./components/guestForm";
class BookingForm extends React.Component{
constructor(props){
    super(props)
}
render(){
    const {community}=this.props
    return(
<div id="bookingScroll">
   <div className="travel_header-ht-w"></div>
   <div className="container intra_staff2" id="reserveyoursuite">
      <div >
         <button  disabled={this.props.bookingLoader?true:false} className="btn btn-primary " onClick={() => this.props.pageChange([],1)}>Back</button>
      </div>
      <div className="text-center community_header mrg-btm-10 clearfix" id="our-communities">
         <div className="col-md-8">
            <h3>Reserve Your Suite!</h3>
         </div>
      </div>
      {/* Community Info */}
      <CommunityBookingInfo community={community} {...this.props}/>
      {/* Guest Form */}
      <GuestForm {...this.props}/>
   </div>
</div>
)
}
}
export default BookingForm;