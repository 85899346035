/* Importing the node modules, child components, services and controllers used 
   inside StripeInternshipHome component */    
   import $ from "jquery";
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import StripeInternshipHeader from "./common/stripeInternHeader";
   import {Link} from 'react-router-dom';
   import ContactUs from "./common/contactus";
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   import StripeInternFooter from "./common/stripeInternFooter";   
   /* StripeInternshipHome Component initialization */
   class StripeInternshipHome extends React.Component {
       /* Initializing objects of its StripeInternshipHome class */
       constructor(props) {
           super(props);
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
           MainController.getInitialInformation();
        }
       /* It is invoked to return html content */
       render() {   
           return (
            <div className="corporateHousing_Updates onlyGileadHeader">
                {/* <GuestHeader/> */}
                <header className="codeconveyHeader cc_image_text_main corporate-h">
                    <img className="img-responsive" src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Company-Nike-Internship-Banner.jpg"} width="100%" alt=""  />
                    <div className="wow fadeInUp img-on-text top30 width500 text-center left50 img-on-text-center company_bannertxt" data-wow-delay="0.5s">
                        {/* <img src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Company-Nike-Logo.png"} className="nike_img" width="150px" alt="" /> */}
                        <img src="https://www.gilead.com/-/media/gilead-corporate/images/global/gilead-create-possible-svg.svg?iar=0&hash=4A8C3B35AEA709965805BF3C2AF58FD6" className="nike_img stripe_img" width="150px" alt="" />
                        <img src="../../assets/images/Kite_logo.png" alt="" width="95px" className="mrg-left-20 nike_img stripe_img gilead2" />

                        <h1>Congratulations on Your Upcoming Internship</h1>
                        <p>SuiteAmerica has been selected by Gilead to arrange your shared housing accommodations. Please use the ‘housing registration’ page to fill out your roommate matching questionnaire and save your place in line for housing assignments.
                        </p>
                    </div>
                </header>
                <StripeInternshipHeader />
                <div className="quote-section bg-white">
                    <div className="container">
                        <div className=" text-center col-md-10 col-md-offset-1 wow fadeInUp company_p" data-wow-delay="0.5s">
                            <h2 className="gotham-book mrg-btm-20">Gilead’s Trusted Corporate Housing Partner</h2>
                            <p>SuiteAmerica has been providing corporate apartments and executive suites nationwide for Gilead for over 25 years. </p>
                            <p>Our goal is to make your summer internship both comfortable and easy!</p>
                            <p>We will match you up with a roommate and assign you a furnished two bedroom, two bathroom apartment close to Gilead offices and/or the Gilead shuttle system.</p>
                            <p>SuiteAmerica will provide you with all the housing basics that you will need to experience apartment living during your internship and you can bring personal items that make you feel at home.  Please note that pets are not allowed by Gilead unless your pet is a Certified Service Animal.</p>
                            <p>Browse the FAQ’s to learn more about what to expect from intern housing and please reach out to us if you have any questions that are not addressed there.</p>
                            <p>We are here to help!</p>
                            <p>Please click the link below to view sample properties in the Bay Area.</p>
                        </div>
                    </div>
                    <div className="who_weare_text service_ch_new">
                        <div className="container">
                            <div className="row">
                            <div className="col-md-6 col-sm-6">
                                <div className="wow fadeInUp company_p dual_quote" data-wow-delay="0.5s">
                                    <p>Simply visit our Locations page to see sample
                                    communities of what might be available for
                                    your temporary stay in Gilead's key areas.
                                    </p>
                                    <Link to="/company/gilead-internship/communities" className="box-slide-btn">VIEW LOCATIONS</Link>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <div className="wow fadeInUp company_p dual_quote" data-wow-delay="0.5s">
                                    <p>Fill in the Intern questionnaire so that we can best arrange your temporary accommodations.
                                    </p>
                                    <Link to="/company/gilead-internship/questionnaire" className="box-slide-btn">INTERN QUESTIONNAIRE</Link>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="container-fluid bg-grey">
                    <div className="container">
                        <ContactUs />
                    </div>
                </div>      */}
                <div className="container ft-contact-adrs mrg-btm-30 mrg-top-30">
                    <StripeInternFooter />
                </div>
                <SubFooter/>
                <Footer/>
            </div>   
           );
       }
   }
   export default StripeInternshipHome;