import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";

// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import moment from "moment";

import BookitApiService from "../../../services/bookit";
import { decrypt } from "../../common/utils";

import Loader from "../../common/loader";

// const stripePromise = loadStripe(
//   "pk_test_51KdytsSErFrSBLHfPxpcto3P8qKglP1o6dQre1poyKbO67P8On3ABi3q4DklgNW1LrF8ykQmkLYnJWTbE01834vD00ZdQwXtTg"
// );

const ReserveNow = () => {
  const [queryParamsObject, setQueryParamsObject] = useState({});
  const [state, setState] = useState({
    isLoggedIn: null,

    InvoiceAmount: 0,
    TotalTax: 0,
    TotalInvoiceAmount: 0,
    errorMessage: "",
    status: "",

    loadingStatus: false,

    firstName: "",
    lastName: "",
    emailId: "",
    phoneNo: "",
    checkIn: "",
    checkOut: "",

    paymentName: "",
    paymentAddress: "",
    paymentCity: "",
    paymentState: "",
    paymentZipCode: "",

    airline: "",
    flight: "",
    arrivalDate: null,
    arrivalTime: null,
    notes: "",
    notifyGuest: false,
    stripeToken: "",
  });
  const [errors, setErrors] = useState({});
  const history = useHistory();

  //   ---------------------------stripe logic start------------------------------------
  const stripe = useStripe();
  const elements = useElements();
  const [paymentError, setPaymentError] = useState("");

  const handleSubmit = async () => {
    // event.preventDefault();
    setPaymentError("");
    try {
      const result = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });
      if (result.error) {
        setPaymentError(result.error.message);
      } else {
        const paymentMethod = result.paymentMethod;
        handlePayment(paymentMethod);
        updateState({ stripeToken: paymentMethod.id });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handlePayment = async (paymentMethod) => {
    // Send paymentMethod to the server for further processing
    try {
      const response = await BookitApiService.CreatePaymentIntent({
        paymentMethod: paymentMethod.id,
      });
      confirmStripePayment(response.clientSecret);
    } catch (err) {
      console.error("Server error:", response.statusText);
    }
  };

  async function confirmStripePayment(clientSecret) {
    try {
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement), // Replace with your card element
          billing_details: {
            name: state.firstName,
          },
        },
      });

      console.log(result);

      if (result.paymentIntent.status === "succeeded") {
        console.log("stripe payment success");
        showPaymentSuccessPopup();
      }
    } catch (error) {
      showPaymentFailurePopup();
      console.log(error);
    }
  }

  function showPaymentSuccessPopup() {
    const successPopup = document.getElementById("success-popup");
    successPopup.style.display = "block";

    // Create and insert the backdrop element
    const backdrop = document.createElement("div");
    backdrop.className = "success-popup-backdrop";
    document.body.appendChild(backdrop);
    resetState();

    // Close the success popup and remove the backdrop after a few seconds (optional)
    setTimeout(() => {
      successPopup.style.display = "none";
      backdrop.remove();
    }, 5000); // Display for 5 seconds
  }
  function showPaymentFailurePopup() {
    const successPopup = document.getElementById("failure-popup");
    successPopup.style.display = "block";

    // Create and insert the backdrop element
    const backdrop = document.createElement("div");
    backdrop.className = "success-popup-backdrop";
    document.body.appendChild(backdrop);
    resetState();

    // Close the success popup and remove the backdrop after a few seconds (optional)
    setTimeout(() => {
      successPopup.style.display = "none";
      backdrop.remove();
    }, 5000); // Display for 5 seconds
  }

  function resetState() {
    updateState({
      InvoiceAmount: 0,
      TotalTax: 0,
      TotalInvoiceAmount: 0,
      errorMessage: "",
      status: "",

      loadingStatus: false,

      firstName: "",
      lastName: "",
      emailId: "",
      phoneNo: "",
      checkIn: "",
      checkOut: "",

      paymentName: "",
      paymentAddress: "",
      paymentCity: "",
      paymentState: "",
      paymentZipCode: "",

      airline: "",
      flight: "",
      arrivalDate: null,
      arrivalTime: null,
      notes: "",
      notifyGuest: false,
      stripeToken: "",
    });
    elements.getElement(CardElement).clear();
  }
  //   ---------------------------stripe logic end--------------------------------

  const s3BucketUrl_landingImages =
    "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/";

  function redirectToHomePage() {
    // redirect to /booknow-modal page

    console.log("Redirecting to book now page");
    history.push("/booknow-modal");
  }

  function extractDataFromQueryParams() {
    let queryString = window.location.search;
    if (!queryString) {
      redirectToHomePage();
      return {};
    }
    queryString = decrypt(queryString.split("?")[1]);
    if (queryString === "error") {
      redirectToHomePage();
      return {};
    }
    const queryParams = new URLSearchParams(queryString);
    const queryParamsObject = {};

    for (const [key, value] of queryParams.entries()) {
      queryParamsObject[key] = decodeURIComponent(value);
    }
    if (!Object.keys(queryParamsObject).length) redirectToHomePage();

    return queryParamsObject;
  }

  function changeInvoiceAmount(selectedRoomType) {
    let amount =
      selectedRoomType === "studio"
        ? queryParamsObject.sizeSTURate
        : selectedRoomType === "1bedroom"
        ? queryParamsObject.size1xRate
        : selectedRoomType === "2bedroom"
        ? queryParamsObject.size2xRate
        : selectedRoomType === "3bedroom"
        ? queryParamsObject.size3xRate
        : queryParamsObject.sizeSTURate;

    updateState({ InvoiceAmount: amount, TotalInvoiceAmount: amount });
  }

  function changeRoomType(selectedRoomType) {
    let status =
      selectedRoomType === "STU"
        ? "studio"
        : selectedRoomType === "1X"
        ? "1bedroom"
        : selectedRoomType === "2X"
        ? "2bedroom"
        : selectedRoomType === "3X"
        ? "3bedroom"
        : "studio";

    updateState({ status: status });
  }

  function changeErrorState(action, field) {
    if (action === "add") {
      // add the field to error state
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: true,
      }));
    } else if (action === "remove") {
      // remove the field to error state
      setErrors((prevErrors) => {
        const { [field]: _, ...rest } = prevErrors;
        return rest;
      });
    }
  }

  function setValue(field, event) {
    let value = event.target.value;
    if (field === "emailId") {
      validateEmailId(value)
        ? changeErrorState("remove", field)
        : changeErrorState("add", field);
      updateState({ [field]: value });
    } else if (field === "phoneNo") {
      validateNumber(value) && updateState({ [field]: value });
      validatePhoneNumber(value)
        ? changeErrorState("remove", field)
        : changeErrorState("add", field);
    } else if (field === "paymentState") {
      value.length >= 2
        ? changeErrorState("remove", field)
        : changeErrorState("add", field);
      updateState({ [field]: value });
    } else if (field === "paymentZipCode") {
      validateNumber(value) && updateState({ [field]: value });
      value.length >= 3
        ? changeErrorState("remove", field)
        : changeErrorState("add", field);
    } else {
      value.length >= 3
        ? changeErrorState("remove", field)
        : changeErrorState("add", field);
      updateState({ [field]: value });
    }
    !value && changeErrorState("remove", field);
  }

  function validateEmailId(emailId) {
    const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return emailPattern.test(emailId);
  }

  function validatePhoneNumber(phoneNumber) {
    const phonePattern = /^\d{10}$/;
    return phonePattern.test(phoneNumber);
  }

  function validateNumber(number) {
    const numberPattern = /^\d{0,10}$/;
    return numberPattern.test(number);
  }

  function checkInSelection(date) {
    updateState({ arrivalDate: date });
  }

  function timeSelection(dateTime) {
    updateState({ arrivalTime: dateTime });
  }

  function handleCheck() {
    updateState({
      notifyGuest: !state.notifyGuest,
    });
  }

  function validation() {
    let mandatoryFields = [
      "firstName",
      "lastName",
      "emailId",
      "phoneNo",
      //   "checkIn",
      //   "checkOut",
      "paymentName",
      "paymentAddress",
      "paymentCity",
      "paymentState",
      "paymentZipCode",
      //   "airline",
      //   "flight",
      //   "arrivalDate",
      //   "arrivalTime",
    ];
    let errors = [];
    for (let field of mandatoryFields) {
      if (!state[field]) {
        changeErrorState("add", field);
        errors.push(field);
      }
    }

    return errors.length > 0 ? false : true;
  }

  function submit() {
    let isValid = validation();
    if (isValid) {
      handleSubmit();
    }
  }

  function updateState(updateObj) {
    setState((prevState) => ({ ...prevState, ...updateObj }));
  }

  function createDateObjFromString(dateString) {
    const parts = dateString.split("/");
    if (parts.length === 3) {
      const [day, month, year] = parts;
      const parsedDate = moment(`${year}-${month}-${day}`);

      return parsedDate;
    }

    return null;
  }

  useEffect(() => {
    let queryParamsObject = extractDataFromQueryParams();
    if (Object.keys(queryParamsObject).length) {
      setQueryParamsObject(queryParamsObject);
    } else {
      // redirect to book-now page
    }
  }, []);

  useEffect(() => {
    // changes room type when the component is loaded for the first time
    if (queryParamsObject.selectedRoomType) {
      let selectedRoomType = queryParamsObject.selectedRoomType;
      changeRoomType(selectedRoomType);
    }
  }, [queryParamsObject.selectedRoomType]);

  useEffect(() => {
    // changes invoice amount when ever status changes
    if (state.status) {
      changeInvoiceAmount(state.status);
    }
  }, [state.status]);

  async function checkUserLoginStatus() {
    if (!(await isUserLoggedIn())) {
      updateState({ isLoggedIn: false });
      return;
    }
    updateState({ isLoggedIn: true });
  }

  async function isUserLoggedIn() {
    try {
      const sessionResponse = await BookitApiService.checkSession();
      if (!sessionResponse.loggedIn) {
        return false;
      }
      updateState({
        ClientProfileCardDetails: sessionResponse.ClientProfileCardDetails,
        email: sessionResponse.email,
      });
      return true;
    } catch (err) {
      return false;
    }
  }

  useEffect(() => {
    checkUserLoginStatus();
  }, []);

  //   if (queryParamsObject)
  if (state.isLoggedIn === true) {
    return (
      <>
        <div
          className="container intra_staff2 mrg-btm-50 pad-top-0"
          id="reserveyoursuite"
        >
          <div>
            {/* heading */}
            <div
              className="text-center community_header mrg-btm-10 clearfix"
              id="our-communities"
            >
              <div className="col-md-8">
                <h3>Reserve Your Suite!</h3>
              </div>
            </div>

            {/* community Information */}
            <div className="">
              {/* right side block */}
              <div className="col-sm-4 col-sm-push-8">
                <div>
                  <div className="clearfix"></div>
                  <div className="widget_head">
                    <h4 className="widget-title">
                      {queryParamsObject.community_name}
                    </h4>
                  </div>
                  <div className="community-item">
                    <div className="img-div">
                      <img src={queryParamsObject.image_name} alt="" />
                      <div className="img-hover-dtls">
                        {queryParamsObject.walkScore ? (
                          <div className="walkscore pull-right">
                            <div className="arrow_box">
                              {queryParamsObject.walkScore}
                            </div>
                            <span>Walk Score</span>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="community-item-dtls">
                      <p>
                        {_.startCase(_.toLower(queryParamsObject.address))},{" "}
                        {_.startCase(_.toLower(queryParamsObject.city_name))},{" "}
                        {_.startCase(_.toLower(queryParamsObject.state_name))}
                      </p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="clearfix"></div>
                    <div className="mrg-top-30">
                      <ul className="bedroom_list list-inline in-dtls pamt_bedroom_list">
                        {queryParamsObject.STU == 0 ? (
                          ""
                        ) : (
                          <li id="STU" onClick={() => changeRoomType("STU")}>
                            <label className="custom_checkbox radio-inline">
                              <input
                                type="radio"
                                name="1"
                                value="studio"
                                disabled={
                                  queryParamsObject.STU == 0 ? true : false
                                }
                                checked={
                                  state.status == "studio" ? true : false
                                }
                                readOnly
                              />
                              <span
                                className={
                                  state.status == "studio"
                                    ? "checkmark"
                                    : "unCheck"
                                }
                              ></span>
                            </label>
                            <img
                              src={s3BucketUrl_landingImages + "studiobed.png"}
                            />
                            <p>Studio </p>
                            <span className="badge">
                              {queryParamsObject.STU}
                            </span>
                            <div className="price">
                              ${queryParamsObject.sizeSTURate}
                            </div>
                          </li>
                        )}
                        {queryParamsObject.Avail1X == 0 ? (
                          ""
                        ) : (
                          <li id="1X" onClick={() => changeRoomType("1X")}>
                            <label className="custom_checkbox radio-inline">
                              <input
                                type="radio"
                                name="1"
                                value="1bedroom"
                                disabled={
                                  queryParamsObject.Avail1X == 0 ? true : false
                                }
                                checked={
                                  state.status == "1bedroom" ? true : false
                                }
                                readOnly
                              />
                              <span className="checkmark"></span>
                            </label>
                            <img
                              src={
                                s3BucketUrl_landingImages + "singlebedroom.png"
                              }
                            />
                            <p>1 Bedroom</p>
                            <span className="badge">
                              {queryParamsObject.Avail1X}
                            </span>
                            <div className="price">
                              ${queryParamsObject.size1xRate}
                            </div>
                          </li>
                        )}
                        {queryParamsObject.Avail2X == 0 ? (
                          ""
                        ) : (
                          <li id="2X" onClick={() => changeRoomType("2X")}>
                            <label className="custom_checkbox radio-inline">
                              <input
                                type="radio"
                                name="1"
                                value="2bedroom"
                                disabled={
                                  queryParamsObject.Avail2X == 0 ? true : false
                                }
                                checked={
                                  state.status == "2bedroom" ? true : false
                                }
                                readOnly
                              />
                              <span
                                className={
                                  state.status == "2bedroom"
                                    ? "checkmark"
                                    : "unCheck"
                                }
                              ></span>
                            </label>
                            <img
                              src={
                                s3BucketUrl_landingImages + "doublebedroom.png"
                              }
                            />
                            <p>2 Bedroom</p>
                            <span className="badge">
                              {queryParamsObject.Avail2X}
                            </span>
                            <div className="price">
                              ${queryParamsObject.size2xRate}
                            </div>
                          </li>
                        )}
                        {queryParamsObject.Avail3X == 0 ? (
                          ""
                        ) : (
                          <li id="3X" onClick={() => changeRoomType("3X")}>
                            <label className="custom_checkbox radio-inline">
                              <input
                                type="radio"
                                name="1"
                                value="3bedroom"
                                disabled={
                                  queryParamsObject.Avail3X == 0 ? true : false
                                }
                                checked={
                                  state.status == "3bedroom" ? true : false
                                }
                                readOnly
                              />
                              <span
                                className={
                                  state.status == "3bedroom"
                                    ? "checkmark"
                                    : "unCheck"
                                }
                              ></span>
                            </label>
                            <img
                              src={
                                s3BucketUrl_landingImages + "triplebedroom.png"
                              }
                            />
                            <p>3 Bedroom</p>
                            <span className="badge">
                              {queryParamsObject.Avail3X}
                            </span>
                            <div className="price">
                              ${queryParamsObject.size3xRate}
                            </div>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                  {/* invoice amount Info */}
                  <div>
                    <div className="widget_head">
                      <h4 className="widget-title ">Payment Summary</h4>
                    </div>
                    <div className="widget">
                      <div className="widget_body pmnt_summary">
                        <p>
                          {" "}
                          Subtotal :{" "}
                          <span>${Number(state.InvoiceAmount).toFixed(2)}</span>
                        </p>

                        <p className="mrg-top-2">
                          {" "}
                          Tax{" "}
                          <i
                            className="fa fa-info-circle"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="The actual tax amount will be calculated based on the applicable state and local sales taxes when your order is shipped."
                          ></i>{" "}
                          : <span>${Number(state.TotalTax).toFixed(2)}</span>
                        </p>
                        <p className="mrg-btm-0 t-amt">
                          {" "}
                          Total Amount:
                          <span>
                            ${Number(state.TotalInvoiceAmount).toFixed(2)}
                          </span>
                        </p>
                        <p className="mrg-btm-0 mrg-top-20 text-danger">
                          * You can cancel the reservation for no charge before
                          3 days of arrival date. (
                          <a onClick={() => CancelationModal()}>Learn more</a>)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* left side block */}
              <div className="col-sm-8 col-sm-pull-4">
                <div className="clearfix"></div>
                {state.errorMessage != "" ? (
                  <div className="text-center alert alert-danger mrg-btm-20">
                    {state.errorMessage}
                  </div>
                ) : (
                  ""
                )}
                <form
                  role="form"
                  method="post"
                  className="bookg-form bookit-search-form"
                >
                  {/* GUEST */}
                  <h4 className="text-red">GUEST</h4>
                  <div
                    id="reservation-information"
                    className="panel-collapse collapse in"
                  >
                    <div className="">
                      <div className="">
                        <div className="row">
                          <div className="col-sm-6">
                            <div
                              className={`form-group ${
                                errors.firstName ? "error" : ""
                              }`}
                            >
                              <input
                                type="text"
                                autoComplete="firstName"
                                maxLength="10"
                                className="form-control"
                                placeholder="First Name *"
                                value={state.firstName}
                                onChange={(e) => setValue("firstName", e)}
                              />
                              {state.firstName != "" ? (
                                <label className="form-control-placeholder">
                                  First Name
                                </label>
                              ) : (
                                ""
                              )}

                              {errors.firstName ? (
                                <span className="text-danger font-12">
                                  {state.firstName == ""
                                    ? "Please enter First Name"
                                    : "Please enter at least 3 characters"}
                                </span>
                              ) : (
                                ""
                              )}

                              {state.firstName && !errors.firstName ? (
                                <i className="fa fa-check succes"></i>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div
                              className={`form-group ${
                                errors.lastName ? "error" : ""
                              }`}
                            >
                              <input
                                type="text"
                                maxLength="10"
                                autoComplete="lastName"
                                className="form-control"
                                placeholder="Last Name *"
                                value={state.lastName}
                                onChange={(e) => setValue("lastName", e)}
                              />

                              {state.lastName != "" ? (
                                <label className="form-control-placeholder">
                                  Last Name
                                </label>
                              ) : (
                                ""
                              )}
                              {errors.lastName ? (
                                <span className="text-danger font-12">
                                  {state.lastName == ""
                                    ? "Please enter Last Name"
                                    : "Please enter at least 3 characters"}
                                </span>
                              ) : (
                                ""
                              )}
                              {state.lastName && !errors.lastName ? (
                                <i className="fa fa-check succes"></i>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6">
                            <div
                              className={`form-group ${
                                errors.emailId ? "error" : ""
                              }`}
                            >
                              <input
                                type="text"
                                autoComplete="emailId"
                                className="form-control"
                                placeholder="Email *"
                                value={state.emailId}
                                onChange={(e) => setValue("emailId", e)}
                              />
                              {state.emailId != "" ? (
                                <label className="form-control-placeholder">
                                  Email Id
                                </label>
                              ) : (
                                ""
                              )}

                              {errors.emailId ? (
                                <span className="text-danger font-12">
                                  {state.emailId == ""
                                    ? "Please enter Email Id"
                                    : "Please enter valid Email Id"}
                                </span>
                              ) : (
                                ""
                              )}

                              {state.emailId && !errors.emailId ? (
                                <i className="fa fa-check succes"></i>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div
                              className={`form-group ${
                                errors.phoneNo ? "error" : ""
                              }`}
                            >
                              <input
                                type="text"
                                autoComplete="342342342"
                                className="form-control"
                                id="phone3"
                                placeholder="Phone Number*"
                                maxLength="10"
                                onChange={(e) => setValue("phoneNo", e)}
                                onBlur={(e) => setValue("phoneNo", e)}
                                value={state.phoneNo}
                              />
                              {state.phoneNo != "" ? (
                                <label className="form-control-placeholder">
                                  Phone Number
                                </label>
                              ) : (
                                ""
                              )}
                              {errors.phoneNo ? (
                                <span className="text-danger font-12">
                                  {state.phoneNo == ""
                                    ? "Please enter phone number"
                                    : "Please enter valid phone number"}
                                </span>
                              ) : (
                                ""
                              )}
                              {state.phoneNo && !errors.phoneNo ? (
                                <i className="fa fa-check succes"></i>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label className="form-control-placeholder">
                                Check In Date
                              </label>

                              <DatePicker
                                type="text"
                                className="form-control"
                                name="date"
                                placeholder="Due Date"
                                selected={createDateObjFromString(
                                  queryParamsObject.checkIn || ""
                                )}
                                dateFormat="DD/MM/YYYY"
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label className="form-control-placeholder ">
                                Check Out Date
                              </label>
                              <DatePicker
                                type="text"
                                className="form-control"
                                name="date"
                                placeholder="Due Date"
                                selected={createDateObjFromString(
                                  queryParamsObject.checkOut || ""
                                )}
                                dateFormat="DD/MM/YYYY"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* PAYMENT */}
                  <h4 className="text-red d-flex justify-content-between align-items-center">
                    {" "}
                    Payment Information{" "}
                    <img
                      src="./assets/images/payment1.png"
                      height="30px"
                    />{" "}
                  </h4>
                  <div
                    id="payment-information"
                    className="panel-collapse collapse in"
                  >
                    <div>
                      <div className="bookg-form">
                        <div className="row">
                          <div className="col-sm-4">
                            <div
                              className={`form-group ${
                                errors.paymentName ? "error" : ""
                              }`}
                            >
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Name on card *"
                                value={state.paymentName}
                                onChange={(e) => setValue("paymentName", e)}
                              />
                              {state.paymentName != "" ? (
                                <label className="form-control-placeholder">
                                  Name on card
                                </label>
                              ) : (
                                ""
                              )}

                              {errors.paymentName ? (
                                <span className="text-danger font-12">
                                  {state.paymentName == ""
                                    ? "Please enter name on card"
                                    : "Please enter at least 3 characters"}
                                </span>
                              ) : (
                                ""
                              )}

                              {state.paymentName && !errors.paymentName ? (
                                <i className="fa fa-check succes"></i>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>

                          {/* <Elements stripe={stripePromise}> */}
                          <div className="col-sm-8">
                            <div className="form-group reserve_card dupcard">
                              <CardElement />
                            </div>
                            <div id="card-errors" role="alert"></div>
                          </div>
                          {/* </Elements> */}

                          <div className="col-sm-12">
                            <div
                              className={`form-group ${
                                errors.paymentAddress ? "error" : ""
                              }`}
                            >
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Address *"
                                value={state.paymentAddress}
                                onChange={(e) => setValue("paymentAddress", e)}
                              />
                              {state.paymentAddress != "" ? (
                                <label className="form-control-placeholder">
                                  Address
                                </label>
                              ) : (
                                ""
                              )}

                              {errors.paymentAddress ? (
                                <span className="text-danger font-12">
                                  {state.paymentAddress == ""
                                    ? "Please enter Address"
                                    : "Please enter at least 3 characters"}
                                </span>
                              ) : (
                                ""
                              )}

                              {state.paymentAddress &&
                              !errors.paymentAddress ? (
                                <i className="fa fa-check succes"></i>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div
                              className={`form-group ${
                                errors.paymentCity ? "error" : ""
                              }`}
                            >
                              <input
                                type="text"
                                className="form-control"
                                placeholder="City *"
                                value={state.paymentCity}
                                onChange={(e) => setValue("paymentCity", e)}
                              />

                              {state.paymentCity != "" ? (
                                <label className="form-control-placeholder">
                                  City
                                </label>
                              ) : (
                                ""
                              )}

                              {errors.paymentCity ? (
                                <span className="text-danger font-12">
                                  {state.paymentCity == ""
                                    ? "Please enter City"
                                    : "Please enter at least 3 characters"}
                                </span>
                              ) : (
                                ""
                              )}

                              {state.paymentCity && !errors.paymentCity ? (
                                <i className="fa fa-check succes"></i>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div
                              className={`form-group ${
                                errors.paymentState ? "error" : ""
                              }`}
                            >
                              <input
                                type="text"
                                className="form-control"
                                placeholder="State *"
                                value={state.paymentState}
                                onChange={(e) => setValue("paymentState", e)}
                              />
                              {state.paymentState != "" ? (
                                <label className="form-control-placeholder">
                                  State
                                </label>
                              ) : (
                                ""
                              )}

                              {errors.paymentState ? (
                                <span className="text-danger font-12">
                                  {state.paymentState == ""
                                    ? "Please enter State"
                                    : "Please enter at least 3 characters"}
                                </span>
                              ) : (
                                ""
                              )}

                              {state.paymentState && !errors.paymentState ? (
                                <i className="fa fa-check succes"></i>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div
                              className={`form-group ${
                                errors.paymentZipCode ? "error" : ""
                              }`}
                            >
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Zipcode *"
                                value={state.paymentZipCode}
                                onChange={(e) => setValue("paymentZipCode", e)}
                              />

                              {state.paymentZipCode != "" ? (
                                <label className="form-control-placeholder">
                                  Zip Code
                                </label>
                              ) : (
                                ""
                              )}

                              {errors.paymentZipCode ? (
                                <span className="text-danger font-12">
                                  {state.paymentZipCode == ""
                                    ? "Please enter Zip code"
                                    : "Please enter at least 3 characters"}
                                </span>
                              ) : (
                                ""
                              )}

                              {state.paymentZipCode &&
                              !errors.paymentZipCode ? (
                                <i className="fa fa-check succes"></i>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="clearfix"></div>

                          <div className="clearfix"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ARRIVAL */}
                  <h4 className="text-red ">Arrival Information</h4>
                  <div
                    id="arrival-information"
                    className="panel-collapse collapse in"
                  >
                    <div className="mrg-btm-20">
                      <div className="bookg-form">
                        <div className="row">
                          <div className="col-sm-6">
                            <div
                              className={`form-group ${
                                errors.airline ? "error" : ""
                              }`}
                            >
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Airline"
                                value={state.airline}
                                onChange={(e) => setValue("airline", e)}
                              />

                              {state.airline != "" ? (
                                <label className="form-control-placeholder">
                                  Airline
                                </label>
                              ) : (
                                ""
                              )}

                              {errors.airline ? (
                                <span className="text-danger font-12">
                                  {state.airline == ""
                                    ? "Please enter Airline"
                                    : "Please enter at least 3 characters"}
                                </span>
                              ) : (
                                ""
                              )}

                              {state.airline && !errors.airline ? (
                                <i className="fa fa-check succes"></i>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div
                              className={`form-group ${
                                errors.flight ? "error" : ""
                              }`}
                            >
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Flight#"
                                value={state.flight}
                                onChange={(e) => setValue("flight", e)}
                              />

                              {state.flight != "" ? (
                                <label className="form-control-placeholder">
                                  Flight
                                </label>
                              ) : (
                                ""
                              )}

                              {errors.flight ? (
                                <span className="text-danger font-12">
                                  {state.flight == ""
                                    ? "Please enter Flight"
                                    : "Please enter at least 3 characters"}
                                </span>
                              ) : (
                                ""
                              )}

                              {state.flight && !errors.flight ? (
                                <i className="fa fa-check succes"></i>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div
                              className={`form-group ${
                                errors.arrivalDate ? "error" : ""
                              }`}
                            >
                              <DatePicker
                                type="text"
                                className="form-control"
                                name="date"
                                placeholderText="Arrival Date *"
                                selected={state.arrivalDate}
                                onChange={checkInSelection}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div
                              className={`form-group ${
                                errors.arrivalTime ? "error" : ""
                              }`}
                            >
                              <DatePicker
                                type="text"
                                className="form-control"
                                name="date"
                                placeholderText="Arrival Time *"
                                selected={state.arrivalTime}
                                onChange={timeSelection}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                dateFormat="LT"
                                timeCaption="Time"
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <textarea
                                rows="5"
                                cols="50"
                                className="form-control"
                                placeholder="Notes"
                                value={state.notes}
                                onChange={(e) => setValue("notes", e)}
                              ></textarea>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group key_checkbox mrg-top-5">
                              <input
                                type="checkbox"
                                id="checkbox-f"
                                name="checkbox"
                                onChange={handleCheck}
                                checked={state.notifyGuest}
                              />
                              <label htmlFor="checkbox-f">Checkbox 6</label>
                              <p className="mrg-left-25">Notify Guest</p>
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <div className="form-group text-center">
                              {state.loadingStatus ? (
                                <div
                                  className="text-center"
                                  style={{
                                    fontSize: "25px",
                                    width: "100%",
                                    margin: "auto",
                                    textAlign: "center",
                                  }}
                                >
                                  <i className="fa fa-spinner fa-spin" />
                                  Please wait ...!
                                </div>
                              ) : (
                                <div>
                                  {state.stripeToken ? (
                                    <span
                                      id="stripe-token-generated"
                                      className="alert alert-success d-block"
                                    >
                                      Token: {state.stripeToken}
                                    </span>
                                  ) : null}

                                  {paymentError ? (
                                    <span
                                      id="stripe-error-generated"
                                      className="alert alert-danger d-block"
                                    >
                                      {paymentError}
                                    </span>
                                  ) : null}

                                  <button
                                    style={{ marginLeft: "10px" }}
                                    className="btn btn-primary btn-lg mrg-btm-50 mrg-top-30"
                                    type="button"
                                    // onClick={submit}
                                    onClick={submit}
                                  >
                                    Pay/$ {state.TotalInvoiceAmount}
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <PaymentSuccessModal />
        <PaymentFailureModal />
      </>
    );
  } else if (state.isLoggedIn === false) {
    history.push("/SuiteCare");
  }
  return <Loader />;
};

const PaymentSuccessModal = () => {
  return (
    <div class="payment-success-popup" id="success-popup">
      <div class="popup-content">
        <h2>Stripe Payment Successful</h2>
        <p>Thank you for your payment.</p>
      </div>
    </div>
  );
};

const PaymentFailureModal = () => {
  return (
    <div class="payment-success-popup" id="failure-popup">
      <div class="popup-content">
        <h2>Stripe Payment Failed</h2>
        <p>Please contact support.</p>
      </div>
    </div>
  );
};

export default ReserveNow;
