/* Importing the node modules, child components, services and controllers used 
   inside ServiceNowInternContactUs component */    
   import MainController from "../../controller/mainController";
   import ServiceNowInternHeader from "../../common/serviceNowInternHeader";
   import SubFooter from "../../common/subFooter";
   import ContactUs from "./common/contactUs";
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   import ServiceNowInternFooter from "./common/serviceNowInternFooter";
   import ServiceNowInternSubFooter from "./common/serviceNowInternSubFooter";
   /* serviceNowInternContactUs Component initialization */
   class ServiceNowInternContactUs extends React.Component {
       /* Initializing objects of its ServiceNowInternContactUs class */
       constructor(props) {
           super(props);
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
           MainController.getInitialInformation();
        }
       /* It is invoked to return html content */
       render() {
           return (
            <div className="corporateHousing_Updates">
                <GuestHeader/>
                <div className="cover-tophd-ht"></div>    
                <ServiceNowInternHeader />
                <div className="container-fluid bg-grey">
                    <div className="container">
                        <ContactUs />
                    </div>
                </div>
                <div className="container ft-contact-adrs mrg-btm-30 mrg-top-30">
                    <ServiceNowInternSubFooter />
                <div className="section-line"></div>
                    <ServiceNowInternFooter />
                </div>
                <SubFooter />
                <Footer/>
            </div>   
           );
       }
   }
   export default ServiceNowInternContactUs;