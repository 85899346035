/* Importing the node modules, child components, services and controllers used
   inside Testmonials component */
import MainController from "../controller/mainController";
import SubFooter from "./subFooter";
import GuestHeader from './guestHeader';
import Footer from './footer';
import NikeServices from '../../services/nikeService';
import SuccessModal from './successModal';
import MetaTags from './metaTags';
import ServerMetaTags from '../../server/metaTags';
import ReCAPTCHA from "react-google-recaptcha"
/* Testmonials Component initialization */
class Testmonials extends React.Component {
    /* Initializing objects of its Testmonials class */
    constructor(props) {
        super(props);
        /* Testmonials Component State variables Initialization */
        this.state = {
            name: '',
            email: '',
            residentName: '',
            phone: '',
            companyName: '',
            accountNumber: '',
            confirmNumber: '',
            comments: '',
            color: '',
            errorMessage: '',
            CaptchaValue: ''
        }
        this.setValue = this.setValue.bind(this);
        this.clearFields = this.clearFields.bind(this);
        this.serviceRequest = this.serviceRequest.bind(this);
        this.onChangeCaptcha = this.onChangeCaptcha.bind(this);
    }
    /* It is invoked immediately before mounting occurs */
    componentWillMount() {
        MainController.getInitialInformation();
    }
    /* To update the input field values */
    setValue(field, e) {
        var object = {};
        object[field] = e.target.value;
        this.setState(object);
    }
    /* Service Request Details send to mail  */
    async serviceRequest() {
        const _this = this
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        const validationFields = ['name', 'email', 'comments', 'CaptchaValue'];
        let error = 0;
        _.forEach(validationFields, function (value) {
            if (_this.state[value] == "") {
                error++;
            }
        });
        _.forEach(validationFields, function (value) {
           if (!reg.test(_this.state.email)) {
                error++;
            }
        });
        if (error == 0) {
            const Request = {
                name: this.state.name,
                email: this.state.email,
                residentName: this.state.residentName,
                phone: this.state.phone,
                companyName: this.state.companyName,
                accountNumber: this.state.accountNumber,
                confirmNumber: this.state.confirmNumber,
                comments: this.state.comments,
                subject: 'Testimonials',
                mail: 'testimonials'
            }

            this.setState({
                name: '',
                email: '',
                residentName: '',
                phone: '',
                companyName: '',
                accountNumber: '',
                confirmNumber: '',
                comments: '',
                color: '',
                errorMessage: ''
            })

            jQuery(function ($) {
                setTimeout(function () {
                    $('#testimonial').modal('hide');
                    $('#successModal').modal('show');
                }.bind(this), 10);
                setTimeout(function () {
                    $('#successModal').modal('hide');
                }.bind(this), 4000);
            }.bind(this));
             await NikeServices.serviceRequest(Request);
        } else {
            this.setState({errorMessage: 'Please Enter Required Fields and Valid Email', color: 'red'})
        }

    }
    /* To empty all the form fields */
    clearFields() {
        this.setState({
            name: '',
            email: '',
            residentName: '',
            phone: '',
            companyName: '',
            accountNumber: '',
            confirmNumber: '',
            comments: '',
            color: '',
            errorMessage: ''
        })
    }
    async onChangeCaptcha(CaptchaValue) {
        await this.setState({CaptchaValue})
    }
    /* It is invoked to return html content */
    render() {
        const metaData = ServerMetaTags
            .urlString
            .getMetaData(this.props.match.url);
        return (
            <div>
                <MetaTags
                    metaTitle={metaData.title}
                    metaDesription={metaData.description}
                    metaUrl={metaData.canonical}/>
                <GuestHeader/>
                <div className="company_section testmonial_gap bg-grey mrg-top-100">
                    <div className="container privacy_policy">
                        <div
                            className="global-coverage-wrap col-md-10 col-md-offset-1 wow fadeInUp"
                            data-wow-delay="0.5s">
                            <h1 className="text-center f-300 gotham-font large-font mrg-btm-20">SuiteAmerica Testimonials</h1>
                            <div className="col-md-8 col-md-offset-2 text-center mrg-btm-60">
                                <a
                                    className="btn btn-red mrg-top-60 mrg-btm-60"
                                    data-toggle="modal"
                                    data-target="#testimonial"
                                    onClick={this.clearFields}>SUBMIT A TESTIMONIAL</a>
                                <p className="text-center color-black">
                                    <strong>
                                        <i>Here's a sample of what people have told us ...</i>
                                    </strong>
                                </p>
                            </div>
                            <div className="clearfix"></div>
                            <div className="col-md-6">
                                <p className="quote-sym">
                                    <i className="fa fa-quote-left"></i>
                                </p>
                                <p>I had a wonderful time in CA. Thanks for your assistance in helping me find
                                    my new home.
                                </p>
                                <p className="quote-sym">
                                    <i className="fa fa-quote-left"></i>
                                </p>
                                <p>Thank you so much for helping me find a place today! I know I might have been
                                    indecisive at times, but I'm sure I'll be really happy with the place - it's
                                    better than what I expected to find.
                                </p>
                                <p className="quote-sym">
                                    <i className="fa fa-quote-left"></i>
                                </p>
                                <p>Nick was a thorough professional and a very good listener. He understood our
                                    requirements and was able to tell us what would be the best for us. He had the
                                    whole tour organized and planned the property visit at accurate times with on
                                    waiting or awkward times. He was very honest in what we should be looking out
                                    for. He went out of his way to help advocate with property management to get
                                    back a deposit we had made. It was great meeting Nick and we wish him the best
                                    for the future.
                                </p>
                                <p className="quote-sym">
                                    <i className="fa fa-quote-left"></i>
                                </p>
                                <p>Thank you for all the help you provided me. It was very kind of you to
                                    entertain my phone calls/messages even after the 2-day tour period. I wish there
                                    were more people like you in the housing industry, it would be so much less
                                    stressful. SuiteAmerica is very lucky to have you.
                                </p>
                                <p className="quote-sym">
                                    <i className="fa fa-quote-left"></i>
                                </p>
                                <p>We had the pleasure of working with Nick. He listened to what we were looking
                                    for. We found our new rental property which wouldn't have been possible without
                                    Nick's help. He even got us one month free rent when he asked about incentives
                                    with the leasing agent! Nick went above and beyond when he allowed us to bring
                                    our puppy along. He is also going to help us find a new vehicle and several
                                    other 'moving into the area' type items we need help with. Many Thanks!
                                </p>

                            </div>
                            <div className="col-md-6">
                                <p className="quote-sym">
                                    <i className="fa fa-quote-left"></i>
                                </p>
                                <p>Elizabeth gets a 10/10. The tours went very smoothly and she was very
                                    experienced and knew the leasing staff by name at every property. She knew
                                    exactly what I was looking for and wasted no time. I would highly recommend her
                                    to other clients.
                                </p>
                                <p className="quote-sym">
                                    <i className="fa fa-quote-left"></i>
                                </p>
                                <p>Marlo was awesome and a total pleasure to work with. He really took care in
                                    showing off the area, finding amazing spots to look at and setting the tone for
                                    a comfortable and exciting transition in to the South Bay.
                                </p>
                                <p className="quote-sym">
                                    <i className="fa fa-quote-left"></i>
                                </p>
                                <p>You are a really patient, marvelous and considerate person in accommodating
                                    my needs and requirements! I'll really recommend your service to my other Apple
                                    Friends.
                                </p>
                                <p className="quote-sym">
                                    <i className="fa fa-quote-left"></i>
                                </p>
                                <p>Thank you from both Perry and me for a wonderful stay. It was a wonderful
                                    apartment and the service and building were terrific. A great way to begin our
                                    new lives in San Francisco!!
                                </p>
                                <p className="quote-sym">
                                    <i className="fa fa-quote-left"></i>
                                </p>
                                <p>Thank you so much for the presents for our upcoming new addition! We were
                                    really happy to get them and we’ve set up everything already -- the bath kneeler
                                    for his first bath, the noggin stick for when he becomes more ‘interactive’ and
                                    changing pads for, well, immediate (and frequent) use. We feel very welcome in
                                    our temporary home and have really been enjoying the services from SuiteAmerica.
                                    Everything has been extremely professional and fast. Thank you for all your
                                    efforts.
                                </p>
                            </div>
                            <div className="col-md-12 mrg-btm-60">
                                <p
                                    style={{
                                    cursor: 'pointer'
                                }}
                                    className="color-red">Show more testimonials</p>
                            </div>
                        </div>
                        <div className="col-md-8 col-md-offset-2 text-center">
                            <a
                                className="btn btn-red mrg-top-60"
                                data-toggle="modal"
                                data-target="#testimonial"
                                onClick={this.clearFields}>SUBMIT A TESTIMONIAL</a>

                        </div>

                    </div>
                </div>
                <SubFooter/>
                <Footer/>

                <div
                    className="modal fade savesearch tesimonial_form bd-example-modal-lg"
                    id="testimonial"
                    role="dialog">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content rm-border-radius">
                            <div className="modal-header">
                                <button type="button" className="close btn" data-dismiss="modal" aria-label="">
                                <i className="bi bi-x"></i>
                                </button>
                            </div>
                            <div className="modal-body">
                                <h2>Send us your Testimonial</h2>
                                <p
                                    className="text-center"
                                    style={{
                                    color: this.state.color
                                }}>{this.state.errorMessage}</p>
                                <form role="form" method="post">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <input
                                                className="form-control"
                                                placeholder="Your Name*"
                                                type="text"
                                                value={this.state.name}
                                                onChange={(e) => this.setValue('name', e)}/>
                                        </div>
                                        <div className="form-group">
                                            <input
                                                className="form-control"
                                                placeholder="Email*"
                                                type="text"
                                                value={this.state.email}
                                                onChange={(e) => this.setValue('email', e)}/>
                                        </div>
                                        <div className="form-group">
                                            <input
                                                className="form-control"
                                                placeholder="Resident Name"
                                                type="text"
                                                value={this.state.residentName}
                                                onChange={(e) => this.setValue('residentName', e)}/>
                                        </div>
                                        <div className="form-group">
                                            <input
                                                className="form-control"
                                                placeholder="Phone"
                                                type="text"
                                                value={this.state.phone}
                                                onChange={(e) => this.setValue('phone', e)}/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <input
                                                className="form-control"
                                                placeholder="Company Name"
                                                type="text"
                                                value={this.state.companyName}
                                                onChange={(e) => this.setValue('companyName', e)}/>
                                        </div>
                                        <div className="form-group">
                                            <input
                                                className="form-control"
                                                placeholder="Account Number"
                                                type="text"
                                                value={this.state.accountNumber}
                                                onChange={(e) => this.setValue('accountNumber', e)}/>
                                        </div>
                                        <div className="form-group">
                                            <input
                                                className="form-control"
                                                placeholder="Confirmation Number"
                                                type="text"
                                                value={this.state.confirmNumber}
                                                onChange={(e) => this.setValue('confirmNumber', e)}/>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <textarea
                                                className="form-control"
                                                placeholder="Comments*"
                                                type="text"
                                                value={this.state.comments}
                                                onChange={(e) => this.setValue('comments', e)}></textarea>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12 form-group">
                                        <ReCAPTCHA
                                            ref="recaptcha"
                                            sitekey="6Lcbw1AUAAAAAE5K5cYDyhoGu0RJY9C3CLtGFXAo"
                                            onChange={this.onChangeCaptcha}/>
                                    </div>

                                    <div className="text-center col-sm-12 form-group">
                                        <button
                                            className="btn location_btn"
                                            type="button"
                                            onClick={this.serviceRequest}>SUBMIT</button>

                                    </div>
                                </form>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <SuccessModal/>
            </div>

        );
    }
}
export default Testmonials;
