/* Importing the node modules, child components, services and controllers used 
   inside PressReleases component */
import $ from "jquery";
import MainController from "../controller/mainController";
import SubFooter from "./subFooter";
import GuestHeader from './guestHeader';
import Footer from './footer';
/* PressReleases Component initialization */
class PressReleases extends React.Component {
    /* Initializing objects of its PressReleases class */
    constructor(props) {
        super(props);
    }
    /* It is invoked immediately before mounting occurs */
    componentWillMount() {
        MainController.getInitialInformation();
    }
    /* It is invoked to return html content */
    render() {
        return (
            <div>
             <GuestHeader/> 
             <div className="services_ch">
                <div className="carousel banner_text">
                   <img className="img-responsive relocation-corporate-housing" src={s3BucketUrl_WebsiteImages+"SuiteAmerica-About-Us-Banner-Image.jpg"} alt="" />
                    <div className="img-on-text width500 left40 top40 width500 text-center img-on-text-center img-on-text-center-w wow fadeInUp"  data-wow-delay="0.5s">
                        <h3 className="animated font300">PRESS RELEASES</h3>
                    </div>
                </div>
            </div>
             <div className="quote-section bg-white">
                <div className="container mediakit">
                   <div className="plaine-text global-coverage-wrap col-md-10 col-md-offset-1 wow fadeInUp" data-wow-delay="0.5s">
                        <h5 className="text-left mrg-btm-0 font700"><strong>Past press releases:</strong></h5>
                        <h5 className="text-left mrg-btm-0 font700"><strong>Press Releases:</strong></h5>
                        <ul className="pressrelease_list">
                            <li><a className="color-red" href="https://s3-us-west-1.amazonaws.com/suiteam-website/PDF/Press_Release/PR-2018 In Their Shoes Award.pdf" target="_blank">SUITEAMERICA WINS 2018 IN THEIR SHOES AWARD</a> </li>
                            <li><a className="color-red" href="https://s3-us-west-1.amazonaws.com/suiteam-website/PDF/Press_Release/PR-2018 Supplier of the Year.pdf" target="_blank">SUITEAMERICA NAMED 2018 SUPPLIER OF THE YEAR</a> </li>
                        </ul>    
                   </div>
                 </div>
              </div>   
             <SubFooter />
             <Footer/>
            </div>
        );
    }
}
export default PressReleases;
