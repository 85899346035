import React, { Component } from "react";
import BlogHeader from "../newinterface/blogHeader";
import Footer from "../newinterface/footer";
import BlogPost from "./blogPost";
import FiltersAndSearch from "./filtersAndSearch";
import blogsData from "./blogsData.js";
import FullBlogPage from "./fullBlogPage";
import { Pagination } from "antd";

// import Header from '../newinterface/header';
class blogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredBlogs: [...blogsData],
      activeCategory: null,
      DisplayBlogsCount: 8,
    };
    this.handleFilterByCategory = this.handleFilterByCategory.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleBlogSelect = this.handleBlogSelect.bind(this);
    this.handleloadMore = this.handleloadMore.bind(this);
    this.navigateTo = this.navigateTo.bind(this);
  }

  handleFilterByCategory(category) {
    const results = [];
    const data = blogsData;

    if (!category) {
      this.setState({ filteredBlogs: [...blogsData], activeCategory: null });
      return;
    }

    for (const blog of data) {
      if (blog.categories.includes(category)) {
        results.push(blog);
      }
    }

    this.setState({
      filteredBlogs: results,
      activeCategory: category,
      DisplayBlogsCount: 8,
    });
  }

  handleSearch(searchText) {
    const results = [];
    const data = blogsData;
    searchText = searchText.trim();
    for (const blog of data) {
      if (
        blog.description
          .toLocaleLowerCase()
          .includes(searchText.toLocaleLowerCase()) ||
        blog.title
          .toLocaleLowerCase()
          .includes(searchText.toLocaleLowerCase()) ||
        blog.textContent
          .toLocaleLowerCase()
          .includes(searchText.toLocaleLowerCase())
      ) {
        results.push(blog);
      }
    }

    this.setState({
      filteredBlogs: results,
      activeCategory: null,
      DisplayBlogsCount: 8,
    });
  }

  handleBlogSelect(blog) {
    if (!blog) {
      this.navigateTo("/blog");
      return;
    }
    this.setState({
      DisplayBlogsCount: 8,
    });
    const hiphenatedTitle = this.replaceSpacesWithHyphens(blog.title);
    this.navigateTo(`/blog/${hiphenatedTitle}`);
  }

  replaceSpacesWithHyphens(inputString) {
    return inputString.trim().replace(/[\s ?]+/g, "-");
  }

  navigateTo(path) {
    this.props.history.push(path);
  }

  handleloadMore() {
    this.setState({
      DisplayBlogsCount: this.state.DisplayBlogsCount + 8,
    });
  }

  checkForQueryParamsAndUpdate() {
    const queryString = this.props.location.search;
    const params = new URLSearchParams(queryString);
    const category = params.get("category");
    const searchText = params.get("searchText");
    if (category) {
      this.handleFilterByCategory(category);
    }
    if (searchText) {
      this.handleSearch(searchText);
    }
  }

  componentDidMount() {
    this.checkForQueryParamsAndUpdate();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.checkForQueryParamsAndUpdate();
    }
  }

  render() {
    const Blogs =
      this.state.DisplayBlogsCount > this.state.filteredBlogs.length
        ? this.state.filteredBlogs
        : this.state.filteredBlogs.slice(0, this.state.DisplayBlogsCount);
    return (
      <div className="SAweb_updateUi">
        <BlogHeader />

        <FiltersAndSearch
          handleFilterByCategory={this.handleFilterByCategory}
          handleSearch={this.handleSearch}
          handleBlogSelect={this.handleBlogSelect}
          activeCategory={this.state.activeCategory}
          navigateTo={this.navigateTo}
        />

        <>
          <section className="sa-blog-view">
            <div className="container">
              {Blogs.length >= 1 ? (
                <div className="blogs">
                  <div className="box">
                    {Blogs.map((blogItem, index) => {
                      return (
                        <BlogPost
                          {...blogItem}
                          key={index}
                          blog={blogItem}
                          handleBlogSelect={this.handleBlogSelect}
                        />
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div>sorry , No blogs are available</div>
              )}

              {this.state.DisplayBlogsCount <=
              this.state.filteredBlogs.length ? (
                <div className="text-center my-5" onClick={this.handleloadMore}>
                  <span className="btn black-btn">Load More...</span>
                </div>
              ) : null}
            </div>
          </section>
        </>

        <Footer />
      </div>
    );
  }
}
export default blogs;
