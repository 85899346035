/* Importing the node modules, child components, services and controllers used 
   inside lumentumWhatsIncluded component */    
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import LumentumHeader from "./common/lumentumHeader";
   import FAQ from "./common/faq";
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   /* lumentumWhatsIncluded Component initialization */
   class LumentumWhatsIncluded extends React.Component {
       /* Initializing objects of its lumentumWhatsIncluded class */
       constructor(props) {
           super(props);
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
           MainController.getInitialInformation();
        }
       /* It is invoked to return html content */
       render() {
           return (
            <div className="corporateHousing_Updates">
                <GuestHeader/>
                <header className="codeconveyHeader cc_image_text_main corporate-h">
                    <img className="img-responsive" src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Company-Nike-Internship-iStock-170231161.jpg"} width="100%" alt=""  />
                    <div className="wow fadeInUp img-on-text top30 width600 text-center left50 img-on-text-center" data-wow-delay="0.5s">
                        <h1>What's Included In A Standard SuiteAmerica Apartment Home?</h1>
                    </div>
                </header>
                <LumentumHeader />
                <div className="quote-section bg-white">
                    <div className="container">
                        <div className="text-center col-md-10 col-md-offset-1 wow fadeInUp company_p" data-wow-delay="0.5s">
                            <p>We’ll set up your apartment or home with everything you need to move in and be comfortable.
                            Do you need something we didn’t include? Give us a call or send us an email telling us what you need.
                            We’ll make every effort to have it waiting for you when you move in to your new home..
                            </p>
                        </div>
                    </div>
                    <div className="container-fluid nike guest_sitenew">
                        <div className="container intra_staff2 col-sm-8 col-sm-offset-2">
                            <div id="accordion" className="panel-group mrg-top-70 mrg-btm-50 company_acdn"  role="tablist" aria-multiselectable="true">
                            <div className="panel panel-default">
                                <div className="panel-heading"  >
                                    <h4 className="panel-title" >
                                        <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#standard-items" aria-expanded="false" aria-controls="collapse2">
                                        Standard items typically included in your new apartment or home
                                        </a>
                                    </h4>
                                </div>
                                <div id="standard-items" className="panel-collapse collapse">
                                    <div className="panel-body">
                                        <div className="profile-opener">
                                        We’ll set up your apartment or home with everything you need to move in and be comfortable.
                                        Do you need something we didn’t include? Give us a call or send us an email telling us what you need.
                                        We’ll make every effort to have it waiting for you when you move in to your new home..
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    <h4 className="panel-title">
                                        <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#furniture" aria-expanded="true" aria-controls="collapse3">
                                        Furniture
                                        </a>
                                    </h4>
                                </div>
                                <div id="furniture" className="panel-collapse collapse">
                                    <div className="panel-body">
                                        <div className="profile-opener">
                                        We’ll set up your apartment or home with everything you need to move in and be comfortable.
                                        Do you need something we didn’t include? Give us a call or send us an email telling us what you need.
                                        We’ll make every effort to have it waiting for you when you move in to your new home..
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    <h4 className="panel-title">
                                        <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#housewares" aria-expanded="true" aria-controls="collapse3">
                                        Housewares
                                        </a>
                                    </h4>
                                </div>
                                <div id="housewares" className="panel-collapse collapse">
                                    <div className="panel-body">
                                        <div className="profile-opener">
                                        We’ll set up your apartment or home with everything you need to move in and be comfortable.
                                        Do you need something we didn’t include? Give us a call or send us an email telling us what you need.
                                        We’ll make every effort to have it waiting for you when you move in to your new home..
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid bg-grey">
                    <div className="container">
                        <FAQ />
                    </div>
                </div>
                <SubFooter/>
                <Footer/>
            </div>
            
           );
       }
   }
   export default LumentumWhatsIncluded;