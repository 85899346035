const ErrorModel = (props) => {

    return (
            <div className="modal fade savesearch" id="community_master_contactInfo" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content rm-border-radius">
                        <div className="modal-header">
                        <button type="button" className="close btn" data-dismiss="modal" aria-label="">
                        <i className="bi bi-x"></i>
                        </button>
                        </div>
                        <div className="modal-body" style={{width: '100%'}}>
                        <h2>Thank you for your request.</h2>
                        <h3 style={{color: 'red'}}>For additional availability, please contact <br/>reservations@suiteamerica.com.</h3>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    export default ErrorModel;