/* Importing the node modules, child components, services and controllers used 
   inside MovingIn component */
import $ from "jquery";
import AppController from "../../controller/appController";
import Guest from "../../common/guest";
import GuestHeader from '../../common/guestHeader';
import Footer from '../../common/footer';
import QuickRequestForm from './quickRequestForm';

/* MovingIn Component initialization */
class MovingIn extends React.Component{
    /* Initializing objects of its MovingIn class */
    constructor(props) {
        super(props);
        this.state = {
            menuShow: false
        }		
      }
      /* It is invoked immediately before mounting occurs */
      componentWillMount() {
        AppController.removeLocalStorageData();
        $('html, body').animate({scrollTop: 0});
        AppController.stickyTitles()  
     }
/* It is invoked to return html content */
render(){
    return(
<div>
<GuestHeader/>
<div className="profile_submenu-ht"></div>
<Guest/>
<QuickRequestForm menuShow={this.state.menuShow}/>
    <div className="service_request moving_in">
        <div className="who_weare guest_site">
            <div className="carousel banner_text">
                <img className="img-responsive" src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Guest-Portal-Moving-In.jpg"} alt="" />
                <div className="carousel-caption slide-text_22">
                    <div className="">
                        <h1 className="toggleHeading">Moving In</h1>
                        <p className="">A guide for everything you may need to<br /> Moving In get settled into your new home!</p>
                    </div>
                </div>

            </div>
        </div>

        <div className="moving_outbottom">

            <h1>LET US HELP
  <br/>
  YOU GET SETTLED</h1>
            <p>At SuiteAmerica, comfort isn’t an option, it’s a necessity.</p>
            <div className="moving_inaccordion">
                <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    <div className="panel panel-default">
                        <div className="panel-heading" role="tab" id="headingOne">
                            <h4 className="panel-title"><div className="numberCircle">1</div>
          <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
            How is your mattress?
          </a>
          <p>We understand everyone’s idea of what makes a comfortable
bed is unique. Too firm? Too soft? Let our guest services team
know and we’ll take care of it right away, free of charge!</p>
        </h4>

                        </div>
                        <div id="collapseOne---" className="panel-collapse collapse in--" role="tabpanel" aria-labelledby="headingOne">
                            <div className="panel-body">We understand everyone’s idea of what makes a comfortable bed is unique. Too firm? Too soft? Let our guest services team know and we’ll take care of it right away, free of charge!</div>
                        </div>
                        <div className="arrowDown"></div>
                        <div className="arrowDown2"></div>
                    </div>
                    <div className="panel panel-default">
                        <div className="panel-heading" role="tab" id="headingTwo">
                            <h4 className="panel-title"><div className="numberCircle">2</div>
          <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
            How is your wifi working?
          </a>
        </h4>

                        </div>
                        <div id="collapseTwo----" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                            <div className="panel-body">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.</div>
                        </div>
                        <div className="arrowDown_new"></div>
                        <div className="arrowDown_new2"></div>
                    </div>
                    <div className="panel panel-default">
                        <div className="panel-heading" role="tab" id="headingThree">
                            <h4 className="panel-title"><div className="numberCircle">3</div>
          <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
           Do you need extra towels or linens?
          </a>
        </h4>

                        </div>
                        <div id="collapseThree----" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                            <div className="panel-body">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. </div>
                        </div>
                        <div className="arrowDown"></div>
                        <div className="arrowDown2"></div>
                    </div>

                    <div className="panel panel-default">
                        <div className="panel-heading" role="tab" id="headingFour">
                            <h4 className="panel-title"><div className="numberCircle">4</div>
          <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseFour" aria-expanded="false" aria-controls="collapseTFour">
           Do you need extra kitchen items?
          </a>
        </h4>

                        </div>
                        <div id="collapseFour---" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFour">
                            <div className="panel-body">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. </div>
                        </div>
                        <div className="arrowDown_new"></div>
                        <div className="arrowDown_new2"></div>
                    </div>
                    <div className="panel panel-default">
                        <div className="panel_bottm">
                            24/7 CUSTOMER SERVICE
                            <p><span>Day or night we are here for you!</span></p>
                            <h1><i className="fa fa-phone-square"></i>
                                {/* <span className="desktop-phone">1.800.367.9501</span> */}
                                <a href="tel:8003679501" className="mobile-phone">1.800.367.9501</a>
                            </h1>
                            <p>guestservices@suiteamerica.com</p>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
<Footer/>
</div>
               
    );
}
}
export default MovingIn;