const ParkingInfo=(props)=>{
    return(
<div className="parkingInfo">
        <div className="top_park">
          <div className="item">
            <img src="../images/altairImages/parkingInfo-1.png" alt="" width="100%" />
            <span className="indicator"> <i className="fa fa-times-circle" /> Entrances and Exits </span>
          </div>
          <div className="item">
          <span className="park_count">1</span>
            <img src="../images/altairImages/parkingInfo-2.png" alt="" width="100%" />
            <p>If you are looking for the parking garage on San Antonio Road, it will have a green wall labeled with the letter ‘A’ when you drop down into the garage.</p>
          </div>
        </div>
        <div className="top_park top_park2">
          <div className="item">
          <span className="park_count">2</span>
            <img src="../images/altairImages/parkingInfo-3.png" alt="" width="100%" />
            <p>If you are looking for the parking garage on Miller Ave., it will have a yellow wall labeled with the letter ‘B’ when you drop down into the garage.</p>
          </div>
          <div className="item">
          <span className="park_count">3</span>
            <img src="../images/altairImages/parkingInfo-4.png" alt="" width="100%" />
            <p>If you are looking for the parking garage on Fayette Drive, it will have a teal wall labeled with the letter ‘D’ when you drop down into the garage.
            </p>
          </div>
        </div>
        <div className="top_park top_park2">
          <div className="item">
          <span className="park_count">4</span>
            <img src="../images/altairImages/parkingInfo-5.png" alt="" width="100%" />
            <p>When first arriving, you can park in a Future Neighborhood
              Parking spot. After locating your suite and unloading luggage,
              you can repark your vehicle in your assigned parking spot.</p>
          </div>
          <div className="item">
          <span className="park_count">5</span>
            <img src="../images/altairImages/parkingInfo-6.png" alt="" width="100%" />
            <p>Your parking spot number will be indicated in your arrival
              email and welcome folder, located in your suite.
            </p>
          </div>
        </div>
      </div>
    )
}

export default ParkingInfo;