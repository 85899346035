/* Importing the node modules, child components, services and controllers used
   inside Location component */
import MapView from './mapView';
import ListView from './listView';
import Search from './search';
import RecentlyViewed from './recentlyViewed';
import $ from "jquery";
import AppController from "../../controller/appController";
import SubFooter from "../../common/subFooter";
import GuestHeader from '../../common/guestHeader';
import Spinner from '../../common/spinner';
import Footer from '../../common/footer';
import LocationController from "../../controller/locationController";
import ReservationSuccesModal from "../../controller/reservationSuccesModal";
import LocationModal from './modals/locationModal';
import Login from './login';
import MetaTags from '../../common/metaTags';

/* Location Component initialization */
class Location extends React.Component {
  /* Initializing objects of its Location class */
  constructor(props) {
    super(props)
    /* Location Component State variables Initialization */
    this.LocationModal = new LocationModal(this, props)
    this.state = Object.assign(this.LocationModal.state)
    this.getSearchResult = this.getSearchResult.bind(this)
    this.savedSearchFilter = this.savedSearchFilter.bind(this);
    this.clearStateValues = this.clearStateValues.bind(this);
    this.savedSearch = this.savedSearch.bind(this);
    this.saveSearchDetails = this.saveSearchDetails.bind(this);
    this.changeview = this.changeview.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.getFavourites = this.getFavourites.bind(this);
    this.favouriteCommunity = this.favouriteCommunity.bind(this);
    this.unFavouriteCommunity = this.unFavouriteCommunity.bind(this);
    this.moreInfoMethod = this.moreInfoMethod.bind(this);
  }

  /**
    * When user login then we get the favourites and the recently viewed data
    */
  async componentDidMount() {
    AppController.removeLocalStorageData();
    $('html, body').animate({ scrollTop: 0 });
    AppController.stickyTitles()
    this.LocationModal.DidMount()
  }
  async getSearchResult() {
    this.LocationModal.getSearchResult()
  }
  clearStateValues() {
    this.setState({ savedSearchData: [], less: 0, more: 1, default: 1, searchLatitude: '', searchLongitude: '' })
  }
  savedSearchFilter(savedSearchData) {
    this.setState({ savedSearchData: [savedSearchData], less: 0, more: 1 })
  }
  async wallsckore() {
    LocationController.wallsckore(this)
  }
  async loadMore() {
    await LocationController.loadMore(this)

  }

  /* Get Favourites  by user_id */
  async getFavourites() {
    this.LocationModal.getFavourites()
  }

  /* Map and listview display */
  changeview() {
    this.setState({ 'showView': !this.state.showView });
  }
  /* login form object creation */

  /* More information about the community */
  async moreInfo(data, favouriteCheck) {
    if (favouriteCheck != undefined && favouriteCheck.favourite_id != '') {
      data[this.state.favourite] = favouriteCheck.favourite_id;
      localStorage.setItem('moreData', JSON.stringify(data));
    } else {
      data[this.state.favourite] = '';
      localStorage.setItem('moreData', JSON.stringify(data));
    }

  }
  /* Update the state savedSearchData */
  async savedSearch(savedSearchData, communityData, searchLatitude, searchLongitude) {
    await this.setState({ savedSearchData, communityData, searchLatitude, searchLongitude, default: 0 })

  }

  /* Insert the search details */
  async saveSearchDetails(data) {
    this.LocationModal.saveSearchDetails(data)
  }
  /* Calback user favorites */
  async unFavouriteCommunity(data) {
    if (Object.keys(this.state.userData).length > 0) {
      const res = await this.LocationModal.unFavouriteCommunity(data)
      if (res[0] != undefined) {
        this.setState({ communityData: res[0] })
      }
      if (res[1] != undefined) {
        this.setState({ recentlyViewData: res[1] })
      }
    }

  }
  /* favourite insertion by user_id and also calling the favourites */
  async favouriteCommunity(data) {
    if (Object.keys(this.state.userData).length > 0) {
      const res = await this.LocationModal.favouriteCommunity(data)
      if (res[0] != undefined) {
        this.setState({ communityData: res[0] })
      }
      if (res[1] != undefined) {
        this.setState({ recentlyViewData: res[1] })
      }
    }

  }

  /* To show more information */
  async moreInfoMethod(data, favouriteCheck) {
    await this.getDetails(data);
  }

  /* It is invoked to return html content */
  render() {
    const userData = localStorage.getItem('_u_web')
    const localStorageVal = (localStorage.getItem('_u_web') == null) ? "#mysave1" : null
    const company = (this.state.companyname == undefined || this.state.companyname == '') ? 'SuiteAmerica' : this.state.companyname
    const recentlyViewDataShow = (userData != null && this.state.recentlyViewData.length > 0 && (this.state.companyname == undefined || this.state.companyname == '')) ? 1 : 0

    return (
      <div>
        <MetaTags
          metaTitle={this.state.metaTitle != '' ? this.state.metaTitle : 'Corporate Housing | Short Term Apartments | 800 367 9501'}
          metaDesription={this.state.metaDescription != '' ? this.state.metaDescription : "Suite America provides platinum-level service in Corporate Housing, Short Term A" +
            "partments and GSA Contract Housing. At SuiteAmerica 800-367-9501, we’re using ga" +
            "me-changing technology, 24/7 service and our nearly 30 years of excellence in cu" +
            "stomer service to revolutionize the Corporate Housing experience around the glob" +
            "e."}
          metaUrl={this.state.metaUrl != '' ? this.state.metaUrl : 'https://www.suiteamerica.com/'}
        />
        {company == 'GileadIntern' ? '' : <GuestHeader />}
        {company == 'SuiteAmerica'
          ? <div className="who_weare">
            <header className="codeconveyHeader cc_image_text_main corporate-h">

              <img
                className="img-responsive"
                src={this.state.cityImage !== "" ? this.state.cityImage : s3BucketUrl_WebsiteImages + "SuiteAmerica-Location-Banner.jpg"}
                alt="" />

              <div
                className="wow fadeInUp img-on-text top30 left50 text-center width600 top30-md"
                data-wow-delay="0.5s">
                <h1 className="toggleHeading">{this.state.locationName !== "" ? this.state.locationName : 'LOCATIONS'}</h1>
                <p className="toggleCaption">{this.state.cityIntro !== "" ? this.state.cityIntro : "Experience our premier corporate and serviced apartments in premium communities around the globe as we expand into London, Ireland and the Netherlands. See how nearly 30 years of excellence in service and detail creates a temporary housing experience like no other."}
                </p>

              </div>

            </header>

          </div>
          : null}

        {/*******************************SEARCH START **********************************************/}
        {company != 'StripeIntern' && company != 'GileadIntern' && company != 'NvidiaTravel' && <Search
          savedSearchFilter={this.savedSearchFilter}
          clearStateValues={this.clearStateValues}
          getSearchResult={this.getSearchResult}
          savedSearch={this.savedSearch}
          companyName={this.state.companyname}
          color={this.props.color}
          refineSearch={this.props.refineSearch} />}
        {/*******************************SEARCH END **********************************************/}

        {this.state.communityData.length > 0 && company != 'StripeIntern' ?
          <div className="location_heading wow fadeInUp" data-wow-delay="0.5s">
            <div className="container">
              <div className="row">
                <div className="col-md-7 col-sm-6">
                  {company == 'SuiteAmerica' && this.state.default == 1
                    ? <h2>SAN FRANCISCO AREA</h2>
                    : null}
                </div>
                <div className="col-md-5 col-sm-6">
                  {company == 'SuiteAmerica'
                    ? <ul>
                      {userData != null && Object.keys(this.state.savedSearchData).length > 2
                        ? this.state.loadingStatus
                          ? <Spinner fontSize="20px" />
                          : null
                        : null}
                      <MapButton showView={this.state.showView} changeview={this.changeview} />

                    </ul>
                    : <ul>
                      <MapButton showView={this.state.showView} changeview={this.changeview} />
                    </ul>}
                  <p
                    className="location_alert22"
                    style={{
                      'color': this.state.color,
                      'backgroundColor': this.state.backgroundColor,
                      'borderColor': this.state.borderColor,
                      'textAlign': 'center',
                      'lineHeight': '40px',
                      'marginTop': '5px'
                    }}>
                    {this.state.searcmessage}</p>
                  <Login />
                </div>
              </div>
            </div>
          </div> : (company == 'StripeIntern' ? <div className="location_heading wow fadeInUp" data-wow-delay="0.5s">
            <div className="container">
              <div className="row">
                <div className="text-center"><h3>COMING SOON</h3>
                </div></div></div></div> : "")}

        {/*******************************LIST VIEW AND MAP VIEW  START **********************************************/}
        {this.state.communityData.length > 0 && company != 'StripeIntern' ? this.state.showView
          ? <ListView
            communityData={this.state.communityData}
            isLoading={this.state.isLoading}
            loadMoreButton={this.state.loadMoreButton}
            loadMoreString={this.state.loadMoreString}
            loadMore={this.loadMore}
            self={this}
            stateData={this.state}
            searchLatitude={this.state.searchLatitude}
            searchLongitude={this.state.searchLongitude}
            getFavourites={this.getFavourites}
            favouriteCommunity={this.favouriteCommunity}
            userFavourites={this.state.userFavourites}
            searchCommunityData={this.state.searchCommunityData}
            unFavouriteCommunity={this.unFavouriteCommunity}
            isSearch={this.state.isSearch}
            companyName={this.state.companyname} />
          : <MapView
            communityData={this.state.communityData}
            companyName={this.state.companyname} /> : ''}
        {/*******************************LIST VIEW AND MAP VIEW END **********************************************/}
        {this.state.isLoading ? <div className="panel-body_loader loadmore" > <Spinner fontSize="30px" />
        </div> : this.state.communityData.length == 0 ? <h1 style={{ 'textAlign': 'center', 'color': '#000000' }}>No Results Found</h1> : ''}
        {/*******************************RECENTLY VIEWED COMMUNITYS START**********************************************/}
        {/* {recentlyViewDataShow==1 ? 
        <div className="white-gap">
            <RecentlyViewed
              self={this}
              favouriteCommunity={this.favouriteCommunity}
              recentlyViewData={this.state.recentlyViewData}
              userFavourites={this.state.userFavourites}
              unFavouriteCommunity={this.unFavouriteCommunity}
              moreInfoMethod={this.moreInfoMethod}
              redirectLink="1"
              headerText="RECENTLY VIEWED COMMUNITIES"
              companyName={this.state.companyname == undefined ? "suiteamerica": this.state.companyname}/>
        </div>
          : null} */}
        {/*******************************RECENTLY VIEWED COMMUNITYS END**********************************************/}
        {company == 'SuiteAmerica'
          ? <div><SubFooter />
            <Footer /></div>
          : null}
        <ReservationSuccesModal />
      </div>
    );
  }
}
export default Location;

const MapButton = (props) => (
  <li style={{ 'cursor': 'pointer' }} onClick={props.changeview}>
    <a>
      <i className={props.showView ? "fa fa-map-marker" : "fa fa-list-ul"}></i>
      {props.showView ? 'Map View' : 'List View'}</a>
  </li>);
