import React from "react";

// Custom CSS to supplement Bootstrap 1.11
const styles = {};

const GuestCheckoutNotice = ({
  guestName,
  checkoutDate,
  checkoutTime,
  requestToStayHandler,
  buttonLoader,
}) => (
  <>
    <tr>
      <td style={{ fontSize: 0, lineHeight: 0, height: "30px" }}>&nbsp;</td>
    </tr>

    <tr>
      <td>
        <table
          border={0}
          cellPadding={0}
          cellSpacing={0}
          style={{ width: "95%", margin: "auto" }}
        >
          <tr>
            <td
              style={{
                fontSize: "25px",
                fontFamily: "'Sora', sans-serif",
                color: "#000000",
                textAlign: "left",
                fontWeight: 700,
              }}
            >
              Hi {guestName},
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: 0, lineHeight: 0, height: "15px" }}>
              &nbsp;
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "18px",
                fontFamily: "'Sora', sans-serif",
                color: "#000000",
                textAlign: "left",
                fontWeight: 700,
                paddingRight: "40px",
              }}
            >
              It looks like your stay with us is coming to an end.
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "18px",
                fontFamily: "'Sora', sans-serif",
                color: "#000000",
                textAlign: "left",
                fontWeight: 500,
              }}
            >
              We've enjoyed having you!
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: 0, lineHeight: 0, height: "15px" }}>
              &nbsp;
            </td>
          </tr>
          <tr>
            <td
              style={{
                color: "#000000",
                fontSize: "17px",
                fontFamily: "'Sora', sans-serif",
                fontWeight: 300,
              }}
            >
              Detailed departure instructions will be sent 72 hrs before your
              confirmed departure.
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: 0, lineHeight: 0, height: "20px" }}>
              &nbsp;
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "18px",
                fontFamily: "'Sora', sans-serif",
                color: "#000000",
                textAlign: "left",
                fontWeight: 700,
              }}
            ></td>
          </tr>
        </table>
      </td>
    </tr>

    <tr>
      <td
        style={{
          fontSize: "18px",
          fontFamily: "'Sora', sans-serif",
          color: "#000000",
          textAlign: "left",
          fontWeight: 500,
        }}
      >
        <table
          style={{ width: "95%", margin: "auto" }}
          border={0}
          cellPadding={0}
          cellSpacing={0}
        >
          <tr>
            <td
              style={{
                fontSize: "18px",
                fontFamily: "'Sora', sans-serif",
                color: "#000000",
                textAlign: "left",
                fontWeight: 700,
              }}
            >
              Your check out date & time is:
            </td>
            <td style={{ width: "165px" }}></td>
          </tr>
          <tr>
            <td style={{ fontSize: 0, lineHeight: 0, height: "10px" }}>
              &nbsp;
            </td>
          </tr>
          <tr>
            <td
              style={{
                background: "#000000",
                color: "#ffffff",
                fontSize: "18px",
                fontFamily: "'Sora', sans-serif",
                padding: "10px",
                textAlign: "center",
                fontWeight: 600,
              }}
            >
              CHECK OUT
            </td>
          </tr>
          <tr>
            <td
              style={{
                background: "#ececec",
                color: "#000000",
                fontSize: "20px",
                fontFamily: "'Sora', sans-serif",
                padding: "10px",
                textAlign: "center",
                fontWeight: 800,
              }}
            >
              {checkoutDate}
              <span
                style={{
                  background: "#ececec",
                  color: "#000000",
                  fontSize: "18px",
                  fontFamily: "'Sora', sans-serif",
                  padding: "10px",
                  textAlign: "center",
                  fontWeight: 700,
                  display: "block",
                }}
              >
                {checkoutTime}
              </span>
            </td>
          </tr>
        </table>
      </td>
    </tr>

    <tr>
      <td>
        <table
          style={{ width: "95%", margin: "auto" }}
          border={0}
          cellPadding={0}
          cellSpacing={0}
        >
          <tr>
            <td style={{ fontSize: 0, lineHeight: 0, height: "30px" }}>
              &nbsp;
            </td>
          </tr>
          <tr>
            <td
              style={{
                color: "#f70743",
                fontSize: "17px",
                fontFamily: "'Sora', sans-serif",
                fontWeight: 700,
              }}
            >
              If you need to extend your stay, we will do our best to
              accommodate your request, but availability is not guaranteed.
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: 0, lineHeight: 0, height: "30px" }}>
              &nbsp;
            </td>
          </tr>
          <tr>
            <td>
              <table style={{ width: "100%" }}>
                <tr>
                  <td>
                    <button
                      style={{
                        color: "#ffffff",
                        background: "#000000",
                        fontSize: "15px",
                        fontFamily: "'Sora', sans-serif",
                        fontWeight: 600,
                        border: 0,
                        padding: "12px 30px",
                        borderRadius: "25px",
                        boxShadow: "0px 0px 10px #c9c9c9",
                        textDecoration: "none",
                      }}
                      onClick={() => requestToStayHandler()}
                      disabled={buttonLoader}
                    >
                      Request to Stay
                    </button>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: 0, lineHeight: 0, height: "30px" }}>
              &nbsp;
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "18px",
                fontFamily: "'Sora', sans-serif",
                color: "#000000",
                textAlign: "left",
                fontWeight: 700,
              }}
            >
              Anything we need to know?
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: 0, lineHeight: 0, height: "30px" }}>
              &nbsp;
            </td>
          </tr>
          <tr>
            <td
              style={{
                color: "#000000",
                fontSize: "17px",
                fontFamily: "'Sora', sans-serif",
                fontWeight: 500,
              }}
            >
              If anything is broken, damaged, or missing, please let us know as
              soon as possible.
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: 0, lineHeight: 0, height: "30px" }}>
              &nbsp;
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "18px",
                fontFamily: "'Sora', sans-serif",
                color: "#000000",
                textAlign: "left",
                fontWeight: 700,
              }}
            >
              Questions?
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: 0, lineHeight: 0, height: "5px" }}>
              &nbsp;
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "18px",
                fontFamily: "'Sora', sans-serif",
                color: "#000000",
                textAlign: "left",
                fontWeight: 300,
              }}
            >
              <a
                href="mailto:someone@example.com"
                style={{
                  fontSize: "18px",
                  fontFamily: "'Sora', sans-serif",
                  color: "#077fd5",
                  textAlign: "left",
                  fontWeight: 400,
                }}
              >
                Send us an email
              </a>{" "}
              or give us a call 800.367.9501
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: 0, lineHeight: 0, height: "30px" }}>
              &nbsp;
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "18px",
                fontFamily: "'Sora', sans-serif",
                color: "#000000",
                textAlign: "left",
                fontWeight: 700,
              }}
            >
              Download the app today
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: 0, lineHeight: 0, height: "15px" }}>
              &nbsp;
            </td>
          </tr>
          <tr>
            <td>
              <a
                href="https://play.google.com/store/apps/details?id=com.suiteamerica.guestportal"
                target="_blank"
                style={{ paddingRight: "20px", textDecoration: "none" }}
              >
                <img
                  style={{ width: "130px" }}
                  src="https://chapp-bucket.s3.us-west-1.amazonaws.com/googlePlayStoreIcon.png"
                  alt=""
                />
              </a>
              <a
                href="https://apps.apple.com/us/app/guestportal/id1492423942"
                target="_blank"
                style={{ paddingRight: "20px", textDecoration: "none" }}
              >
                <img
                  style={{ width: "130px" }}
                  src="https://chapp-bucket.s3.us-west-1.amazonaws.com/appStoreIcon.png"
                  alt=""
                />
              </a>
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: 0, lineHeight: 0, height: "15px" }}>
              &nbsp;
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "18px",
                fontFamily: "'Sora', sans-serif",
                color: "#000000",
                textAlign: "left",
                fontWeight: 300,
              }}
            >
              Or log into our passwordless{" "}
              <a
                href="https://www.suiteamerica.com/guest-login"
                target="_blank"
                style={{
                  fontSize: "18px",
                  fontFamily: "'Sora', sans-serif",
                  color: "#077fd5",
                  textAlign: "left",
                  fontWeight: 400,
                }}
              >
                Guestportal
              </a>
            </td>
          </tr>
        </table>
      </td>
    </tr>

    <tr>
      <td style={{ fontSize: 0, lineHeight: 0, height: 40 }}>&nbsp;</td>
    </tr>
  </>
);

export default GuestCheckoutNotice;
