import React from 'react'
import Footer from './footer';
import Header from './header';
import NikeServices from '../../../services/nikeService';

class ContactUsNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: '',
      lastname: '',
      email: '',
      subject: '',
      message: '',
      succmessage: '',
      errormessage: ''

    }
    this.placeRequest = this.placeRequest.bind(this);
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }

  }

  setValue(field, e) {
    var object = {};
    object[field] = e.target.value;
    if (this.state[field] != "") {
      $("#" + field).removeClass('validation');
    }
    this.setState(object);
  }
  async placeRequest() {

    let error = 0;
    if (this.state.firstname == "") {
      $("#firstname").addClass('validation');
      error++;
    } else {
      $("#firstname").removeClass('validation');
    }
    if (this.state.lastname == "") {
      $("#lastname").addClass('validation');
      error++;
    } else {
      $("#lastname").removeClass('validation');
    }
    if (this.state.email == "") {
      $("#email").addClass('validation');
      error++;
    } else {
      $("#email").removeClass('validation');
    }
    if (this.state.subject == "") {
      $("#subject").addClass('validation');
      error++;
    } else {
      $("#subject").removeClass('validation');
    }
    if (this.state.message == "") {
      $("#message").addClass('validation');
      error++;
    } else {
      $("#message").removeClass('validation');
    }
    if (error == 0) {
      const Request = {
        firstName: this.state.firstname,
        lastName: this.state.lastname,
        email: this.state.email,
        subject: this.state.subject == '' ? 'NA' : this.state.subject,
        message: this.state.message == '' ? 'NA' : this.state.message,
        mail: 'contactUs'
      }
      this.setState({ errormessage: '', firstname: '', lastname: '', email: '', subject: '', message: '' })
      jQuery(function ($) {
        $('#successModal').modal('show');
        setTimeout(function () {
          $('#successModal').modal('hide');
        }, 4000);
      });
      await NikeServices.serviceRequest(Request)
      // this.setState({succmessage:"successfully submitted!",})
    }
    else {
      this.setState({ succmessage: "", errormessage: 'Please fill all the required fields' })
    }

  }
  render() {
    return (
      <div className="SAweb_updateUi">

        <Header />
        <section className="SAweb_contactUs bg-white">
          <h2>Get In Touch</h2>

          <h2>We’re here for you 24/7</h2>
          <p> <i className="bi bi-telephone"></i> +1 800.367.9501</p>

          <form action="">
            {this.state.errormessage != "" && <h3 className="error">{this.state.errormessage}</h3>}
            {this.state.succmessage != "" && <h3 className="success">{this.state.succmessage}</h3>}
            <div className="floating-label-group">
              <input type="text" className="form-control" id="firstname" required
                onChange={(e) => this.setValue('firstname', e)}
                value={this.state.firstname} />
              <label className="floating-label">First Name</label>
            </div>
            <div className="floating-label-group">
              <input type="text" className="form-control" id="lastname"
                required onChange={(e) => this.setValue('lastname', e)}
                value={this.state.lastname} />
              <label className="floating-label">Last Name</label>
            </div>
            <div className="floating-label-group">
              <input type="text" className="form-control" id="email" required
                onChange={(e) => this.setValue('email', e)}
                value={this.state.email} />
              <label className="floating-label">Email Address</label>
            </div>
            <div className="floating-label-group">
              <input type="text" className="form-control" id="subject" required
                onChange={(e) => this.setValue('subject', e)}
                value={this.state.subject} />
              <label className="floating-label">Subject</label>
            </div>

            <div className="floating-label-group phColor">
              <textarea className="form-control" id="message" placeholder="Add Your Message"
                rows="3" required
                onChange={(e) => this.setValue('message', e)}
                value={this.state.message} />
              {/* <label className="floating-label">Add Your Message</label> */}
            </div>

            <button type="button" className="btn black-btn" onClick={this.placeRequest}> Submit </button>

          </form>
        </section>
        <section className="SAweb_aboutUsCorporateLocations" data-aos="fade-up">
          <h3 >Corporate Office</h3>
          <div className="container">
            <div className="accordion">
              <div className="card">
                <div className="card-head" id="headingOne">
                  <div className="tailBox">
                    <div className="item">
                      {/* <h4>Corporate Office: </h4> */}
                      <ul className='text-center'>
                        <li>El Dorado Hills, CA</li>
                        <li>4970 Windplay Dr, 95762</li>
                        <li>Tel: 800-367-9501 | 916-941-7970</li>
                        {/* <li>Fax: 916-941-7989</li> */}
                      </ul>

                    </div>
                    {/* <div className="item">
                      <h4>West Coast Warehouse & Distribution: </h4>
                      <ul>
                        <li>Hayward, CA</li>
                        <li>3300 Arden Rd.</li>
                        <li>Hayward, CA 94544</li>
                        <li>Tel: 510-429-9700</li>
                        <li>Fax:510-429-6780</li>
                      </ul>
                    </div> */}

                    {/* <div className="item">
                      <h4>West Coast Regional Sales Offices: </h4>
                      <ul>
                        <li>Mountain View, CA</li>
                        <li>800 West El Camino Real #180</li>
                        <li>Mountain View, CA 94040</li>
                        <li>Tel: 650-943-2305</li>
                      </ul>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <div className="modal fade savesearch" id="successModal" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="">
                        <span> <i className="bi bi-x"></i> </span>
                        </button>
                        </div>
                        <div className="modal-body">
                        <h2>Thank You!</h2>
                        <p>Mail has been sent successfully</p>
                        </div>
                    </div>
                </div>
            </div> */}

        <div className="modal fade savesearch SAweb_modalPoup" id="successModal" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="btn close" data-dismiss="modal" aria-label="">
                  <span> <i className="bi bi-x"></i> </span>
                </button>
              </div>
              <div className="modal-body width100per">


                <div class="alert alert-2 text-center">
                  <strong>Thank You!</strong> We’ll get back to you shortly.
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>


    );
  }
}
export default ContactUsNew;