/* Importing the node modules, child components, services and controllers used 
   inside GovernmentCustomization component */
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   import ReserveYourStay from '../../common/reserveYourStay.js';
   import MetaTags from '../../common/metaTags';
   import ServerMetaTags from '../../../server/metaTags';   
   import NavigationFooter from '../../common/navigationFooter';
   import NavigationData from '../../common/navigation.json';
   /* GovernmentCustomization Component initialization */
   class GovernmentCustomization extends React.Component {
       /* Initializing objects of its GovernmentCustomization class */
       constructor(props) {
           super(props);
           this.state = {
             navigationData: NavigationData.governmentCustomization
          }   
       }
       /* It is invoked immediately before a component is mounted */  
       componentWillMount() {
           MainController.getProfilePageInformation(12, 3, 3);
       }
       /* It is invoked to return html content */
       render() {
           const metaData = ServerMetaTags.urlString.getMetaData(this.props.match.url);
           return (
               <div>
                   <MetaTags 
                    metaTitle = {metaData.title}
                    metaDesription = {metaData.description}
                    metaUrl = {metaData.canonical} />
                   <GuestHeader/>
                   <header className="codeconveyHeader cc_image_text_main corporate-h video-wrapper">
                       <img className="img-responsive relocation-corporate-housing" src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Government-Customization-Banner.jpg"} width="100%" alt=""  />
                       <div className="wow fadeInUp img-on-text top40 width500 text-center left45 img-on-text-center" data-wow-delay="0.5s">
                           <h1>BE OUR GUEST</h1>
                           <p>There’s no place like home, but with easy-to-get amenities, you can make this place feel pretty darn close. Let our Concierge Services team help with the little things that make life a bit easier. We’re only a call away. 
                           </p>
                       </div>
                   </header>
                   <ReserveYourStay />
                   <div className="who_weare services_ch">
                    <div className="cc_image_text_main">
                        <img className="img-responsive left-mo" src={s3BucketUrl_WebsiteImages+"Government-Government-Housing-Top-Flight-Security.jpg"} alt="" />
                    </div>
                </div>
                <div className="new_txt_sec">
                    <div className="new_txt_content container wow fadeInUp animated">
                        <div className="col-md-8 col-md-offset-2">
                            <h2>CUSTOMIZE YOUR STAY</h2>
                            <p>Do you need something extra? Is something not quite right? Whether you need new linens, more towels, or stronger WiFi, our Guest Services team is ready to take care of it right away, with no charge. You’ll find our attention to detail is unmatched.
                            </p>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>
                <div className="bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 company_section">
                            <div className="accom_text wow fadeInUp">
                                <h2>Concierge Services</h2>
                                <p>Special packages are available through Concierge Services, offering amenities and extras to customize your stay. Let us know what you need or prefer, and our team will supply it. A minimal rental or delivery charge may apply in some areas. Simply contact the team, and we’ll be at your door with anything from electronics to pet supplies.
                                </p>
                                <p><strong>Electronics, Furniture, Outdoor Activities, Pets, Kids, Kitchen, Car Rental, Housekeeping, Starting Essentials </strong> <i>and more</i></p>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid bg-white">
                    <div className="container">
                        <p className="text-center review_rqst_button accomidation_acco_btn color-red collapsed" role="button" data-toggle="collapse" href="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                        Additional Services
                        </p>
                        <div className="collapse row accomidation_acco" id="collapseExample">
                            <div className="company_section wow fadeInUp">
                            <div className="col-md-6">
                                <div>
                                    <ul>
                                        <li>Package delivery</li>
                                        <li>Same day delivery</li>
                                        <li>Transportation services</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <ul>
                                        <li>Custom Intallations</li>
                                        <li>Moving help</li>
                                        <li>and more</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="quote-section bg-grey quote_red">
                    <div className="container">
                        <div className="wow fadeInUp" data-wow-delay="0.5s">
                            <h6 className="quote-s"> <img src={s3BucketUrl_WebsiteImages+"Corporation-Corporate-Housing-Quote.png"} alt=""/></h6>
                            <div className="col-md-10 col-md-offset-1">
                            <h5 className="quote-text">It was wonderful to see all those groceries in my refrigerator when I arrived. 
                            Lifted my spirit after a very long f light and arriving at mid-night, very thoughtful. 
                            Thank you again for all the great welcome and help!
                            </h5>
                            </div>
                        </div>
                    </div>
                </div>
                   <div className="clearfix"></div>
                   <NavigationFooter {...this.state} />
                   <SubFooter />
                   <Footer/>
                </div>
           );
       }
   }
   export default GovernmentCustomization;