/* Importing the node modules, child components, services and controllers used 
   inside NoMatchFound component */ 
   import MainController from "../controller/mainController";
   import SubFooter from "./subFooter";
   import {Link} from 'react-router-dom';
   import GuestHeader from './guestHeader';
   import Footer from './footer';
   /* NoMatchFound Component initialization */
   class NoMatchFound extends React.Component {
        /* Initializing objects of its NoMatchFound class */
        constructor(props) {
            super(props);           
        }         
        /* It is invoked immediately before mounting occurs */
        componentWillMount() {
            MainController.getInitialInformation();
        }
       /* It is invoked to return html content */
       render() {   
           return (
               <div className="corporateHousing_Updates">
                    <GuestHeader/>
                    <header className="codeconveyHeader cc_image_text_main corporate-h">
                        <img className="img-responsive" src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Page-Not-Found.jpg"} width="100%" alt=""  />
                        <div className="wow fadeInUp img-on-text top30 width800 text-center img-on-text-center-w page_not_found" data-wow-delay="0.5s">
                        <div className="page_not_found_dtls">
                            <p>Page Not Found</p>
                            <h1>WE’RE SORRY!</h1>
                            <p>We can’t seem to find the page you were 
                            looking for. Please check the URL, here are a few 
                            helpful links to help you find your way.</p>
                        </div>
                            <div className="col-md-12">
                            <div className="row">
                                <Link to="/" className="btn location_btn">HOME PAGE</Link>
                                <Link to="/locations" className="btn location_btn">LOCATIONS</Link>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                            <Link to="/contactUs" className="btn location_btn">CONTACT US</Link>
                        </div>  
                    </header>
                    <SubFooter/>
                    <Footer/>
               </div>
           );
       }
   }
   export default NoMatchFound;