/* Importing the node modules, child components, services and controllers used 
   inside RiotGamesHome component */
import MainController from "../../controller/mainController";
import SubFooter from "../../common/subFooter";
import RiotGamesHeader from "../../common/riotgamesHeader";
import { Link } from 'react-router-dom';
import ContactUs from "./common/contactUs";
import GuestHeader from '../../common/guestHeader';
import Footer from '../../common/footer';
import RiotGamesFooter from "./common/riotgamesFooter";
/* RiotGamesHome Component initialization */
class RiotGamesHome extends React.Component {
    /* Initializing objects of its RiotGamesHome class */
    constructor(props) {
        super(props);
    }
    /* It is invoked immediately before mounting occurs */
    componentWillMount() {
        MainController.getInitialInformation();
    }
    /* It is invoked to return html content */
    render() {
        return (
            <div className="corporateHousing_Updates">
                <GuestHeader />
                <header className="codeconveyHeader cc_image_text_main corporate-h">
                    <img className="img-responsive" src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/servicenow/Home - ServiceNow.jpg" width="100%" alt="" />
                    <div className="wow fadeInUp img-on-text top30 width500 text-center left50 img-on-text-center company_bannertxt" data-wow-delay="0.5s">
                        <img src="https://suiteam-website.s3-us-west-1.amazonaws.com/landing-images/riotgames/RiotGamesLogo.png" className="serviceNow_img riot-games" width="150px" alt="" />
                        <h1>Congratulations on Your Upcoming Internship</h1>
                        <p>SuiteAmerica is proud to be a trusted housing option for RiotGames interns staying in the Santa Clara & San Diego area!
                        </p>
                    </div>
                </header>
                <RiotGamesHeader />
                <div className="quote-section bg-white">
                    <div className="container">
                        <div className=" text-center col-md-10 col-md-offset-1 wow fadeInUp company_p" data-wow-delay="0.5s">
                            <h2 className="gotham-book mrg-btm-20">RiotGames’s #1 Corporate Housing Provider</h2>
                            <p>SuiteAmerica is proud to be a trusted housing option for RiotGames interns staying in the Santa Clara & San Diego area!
                            </p>

                        </div>
                    </div>
                    <div className="who_weare_text service_ch_new company_p_height">
                        <div className="container">
                            <div className="row rows">
                                <div className="col-md-6 col-sm-6">
                                    <div className="wow fadeInUp company_p dual_quote" data-wow-delay="0.5s">
                                        <p>Simply visit our Locations page to see sample
                                        communities of what might be available for
                                        your temporary stay in RiotGames's key areas.
                                    </p>
                                        <Link to="/company/riotgames-intern/communities" className="box-slide-btn">VIEW LOCATIONS</Link>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                    <div className="wow fadeInUp company_p dual_quote" data-wow-delay="0.5s">
                                        <p>Fill in the Intern questionnaire so that we can best arrange your temporary accommodations.
                                    </p>
                                        <Link to="/company/riotgames-intern/questionnaire" className="box-slide-btn">INTERN QUESTIONNAIRE</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="who_weare_text service_ch_new">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 ">
                                    <div className="wow fadeInUp company_p dual_quote" data-wow-delay="0.5s">
                                        <h3 className="gotham-book mrg-btm-0">Intern Car Rental Program</h3>
                                        <div className="mrg-btm-30"> Avalon Transportation Service</div>
                                        <div className="col-md-6 ">
                                            <h5>OUR PROGRAM</h5>
                                            <p>At Avalon Transportation, we can help you make the most of your time. We offer special car rental rates for interns, college students and young professionals even if you are under the age of 25. No underage fees are added to you rate. You can get extremely affordable rate programs 1 month to 12 month for car rentals.</p>
                                            <span >No Under Age Driver Fees!</span><br />
                                        </div>
                                        <div className="col-md-6 ">
                                            <h5>OUR VEHICLES</h5>
                                            <p>We have a well-maintained fleet of over 200 vehicles. All our vehicles are company owned allowing us to offer reduced rates. Our wonderfully helpful agents are here to assist you. We know how challenging it can be to get around the area without a car. Therefore, we've made renting a car for Interns and those who are under 25 year old EASY, UNIQUE and AFFORDABLE.</p>
                                            <span >Call us today and reserve your car 1.877.946.1930</span><br />
                                            <a href="https://avalontransportation.us/" target="_blank"><ins style={{color:"blue"}}>www.driveavalon.com</ins></a>
                                        </div>

                                        <div className="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
                </div>
                <div className="container-fluid bg-grey">
                    <div className="container">
                        <ContactUs />
                    </div>
                </div>
                <div className="container ft-contact-adrs mrg-btm-30 mrg-top-30">
                    <RiotGamesFooter />
                </div>
                <SubFooter />
                <Footer />
            </div>
        );
    }
}
export default RiotGamesHome;