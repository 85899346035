const Settings = props => {
    return (
        <div className="sr_form_wraper">
        <div className="qwer_bottom">
           <h1>iPhone</h1>
           <div className="qwer_bottom22">
              <div className="form-group">
                 <span className="switch switch-lg">
                 <input type="checkbox" className="switch" id="iphone-pickedup" />
                 <label htmlFor="iphone-pickedup">Picked Up</label>
                 </span>
              </div>
              <div className="form-group">
                 <span className="switch switch-lg">
                 <input type="checkbox" className="switch" id="iphone-delivered" />
                 <label htmlFor="iphone-delivered">Delivered</label>
                 </span>
              </div>
           </div>
        </div>
        <div className="qwer_bottom">
           <h1>Cell Booster </h1>
           <div className="qwer_bottom22">
              <div className="form-group">
                 <span className="switch switch-lg">
                 <input type="checkbox" className="switch" id="Cell-pickedup" />
                 <label htmlFor="Cell-pickedup">Picked Up</label>
                 </span>
              </div>
              <div className="form-group">
                 <span className="switch switch-lg">
                 <input type="checkbox" className="switch" id="Cell-delivered" />
                 <label htmlFor="Cell-delivered">Delivered</label>
                 </span>
              </div>
           </div>
        </div>
        <div className="qwer_bottom">
           <h1>Wifi Hotspot</h1>
           <div className="qwer_bottom22">
              <div className="form-group">
                 <span className="switch switch-lg">
                 <input type="checkbox" className="switch" id="wifi-pickedup" />
                 <label htmlFor="wifi-pickedup">Picked Up</label>
                 </span>
              </div>
              <div className="form-group">
                 <span className="switch switch-lg">
                 <input type="checkbox" className="switch" id="wifi-delivered" />
                 <label htmlFor="wifi-delivered">Delivered</label>
                 </span>
              </div>
           </div>
        </div>
     </div>
    );
  };

  export default Settings;