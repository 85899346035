import React, { Component } from 'react'

export class HotelOrdersModal extends Component {
constructor(props){
    super(props)
    this.state={

    }
}

    render() {
        return (
           <div className="modal modal-fullscreen-xl modal fade animated tab-modal" id="modalFullBookingDetails" tabIndex={-1} role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                      {this.props.selectedData.OrderType == "S" ?
                        <div className="modal-content">
                          <div >
                            <button type="button" className="close btn" data-dismiss="modal" aria-label="Close">
                            <i className="bi bi-x"></i>
                            </button>
                          </div>

                          <div className="modal-body">
                            <div className="bookingList">
                              <h2>{this.props.selectedData.Community}</h2>
                              <h3 style={{ textTransform: 'capitalize' }}>{!this.props.selectedData.address ? '' : this.props.selectedData.address}{!this.props.selectedData.city_name ? '' : `,  ${(this.props.selectedData.city_name).toLowerCase()}`}{!this.props.selectedData.statename ? '' : `,  ${(this.props.selectedData.statename).toLowerCase()}`}{!this.props.selectedData.ZipCode ? '' : `  ${this.props.selectedData.ZipCode}`} <span>Booking ID <b>#{this.props.selectedData.orderno}</b></span>
                              </h3>
                              <div className="row">
                                <div className="col-md-7">
                                  <div className="imageView mb-5">
                                    <img src={!this.props.selectedData.image_name ? "../images/dummyCommunity.jpg" : this.props.selectedData.image_name} alt="" width="100%" />
                                  </div>
                                </div>
                                <div className="col-md-5">
                                  <div className="cntView">
                                    <h1>Booking Details</h1>
                                    <h4>Check In</h4>
                                    <h5>{moment(this.props.selectedData.MoveInDate).format('DD')} {moment(this.props.selectedData.MoveInDate).format('MMMM')} {moment(this.props.selectedData.MoveInDate).format('YYYY')}<span>{this.props.selectedData.CheckInTime != null && this.props.selectedData.CheckInTime}</span></h5>
                                    <h4>Check Out</h4>
                                    <h5>{moment(this.props.selectedData.MoveOutDate).format('DD')} {moment(this.props.selectedData.MoveOutDate).format('MMMM')} {moment(this.props.selectedData.MoveOutDate).format('YYYY')} <span>{this.props.selectedData.CheckOutTime == null ? ' 11:59 PM' : this.props.selectedData.CheckOutTime}</span></h5>
                                  </div>
                                </div>
                              </div>
                              <h5 className="note">If you have any questions regarding your past reservations, please reach out to <a href="mailto:support@suiteamerica.com">support@suiteamerica.com</a>.</h5>
                            </div>
                          </div>
                        </div>
                        :
                        <div className="modal-content">
                          <div >
                            <button type="button" className="close btn" data-dismiss="modal" aria-label="Close">
                            <i className="bi bi-x"></i>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="bookingList">
                              <h2>{this.props.selectedData.HotelName}</h2>
                              <h3 style={{ textTransform: 'capitalize' }}>{!this.props.selectedData.HotelAddress1 ? '' : this.props.selectedData.HotelAddress1 + ','} {!this.props.selectedData.HotelAddress2 ? '' : this.props.selectedData.HotelAddress2} {!this.props.selectedData.HotelCity ? '' : `,  ${this.props.selectedData.HotelCity}`}{!this.props.selectedData.HotelState ? '' : `,  ${this.props.selectedData.HotelState}`}{!this.props.selectedData.HotelZipCode ? '' : `  ${this.props.selectedData.HotelZipCode}`} <span>Booking ID <b>#{this.props.selectedData.OrderId}</b></span>
                              </h3>
                              <div className="row">
                                {/* <div className="col-md-7">
                                  <div className="imageView mb-5">
                                    <img src="../images/s-dummyImage-2.png" alt="" width="100%" />
                                  </div>
                                </div> */}
                                <div className="col-md-5">
                                  <div className="cntView">
                                    <h1>Booking Details</h1>
                                    <h4>Check In</h4>
                                    <h5>{moment(this.props.selectedData.MoveInDate).format('DD')} {moment(this.props.selectedData.MoveInDate).format('MMMM')} {moment(this.props.selectedData.MoveInDate).format('YYYY')}<span>10:00AM</span></h5>
                                    <h4>Check Out</h4>
                                    <h5>{moment(this.props.selectedData.MoveOutDate).format('DD')} {moment(this.props.selectedData.MoveOutDate).format('MMMM')} {moment(this.props.selectedData.MoveOutDate).format('YYYY')} <span>11:00AM</span></h5>
                                  </div>
                                </div>
                              </div>
                              <h5 className="note">If you have any questions regarding your hotel reservations, please reach out to <a href="mailto:support@suiteamerica.com">support@suiteamerica.com</a>.</h5>
                            </div>
                          </div>

                        </div>
                      }
                    </div>
                  </div>
        )
    }
}

export default HotelOrdersModal
