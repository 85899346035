/* Importing the node modules, child components, services and controllers used 
   inside IndustryAwards component */
import MainController from "../controller/mainController";
import SubFooter from "./subFooter";
import GuestHeader from './guestHeader';
import Footer from './footer';
/* IndustryAwards Component initialization */

class IndustryAwards extends React.Component {
    /* Initializing objects of its IndustryAwards class */
    constructor(props) {
        super(props);
    }
    /* It is invoked immediately before mounting occurs */
    componentWillMount() {
        MainController.getInitialInformation();
    }
    /* It is invoked to return html content */
    render() {
        return (
            <div>
            <GuestHeader/>
            <div className="services_ch">
                <div className="carousel banner_text">
                   <img className="img-responsive relocation-corporate-housing" src={s3BucketUrl_WebsiteImages+"SuiteAmerica-About-Us-Banner-Image.jpg" }alt="" />
                    <div className="img-on-text width500 left40 top40 width500 text-center img-on-text-center img-on-text-center-w wow fadeInUp"  data-wow-delay="0.5s">
                        <h3 className="animated font300">INDUSTRY AWARDS</h3>
                    </div>
                </div>
            </div>
            <div className="quote-section bg-white">
                <div className="container mediakit company-info">
                    <div className="plaine-text global-coverage-wrap col-md-10 col-md-offset-1 wow fadeInUp" data-wow-delay="0.5s">
                        <p>SuiteAmerica is honored to have won numerous industry awards for our exceptional services, attention to
                        detail and ground-breaking technology. Some are:
                        </p>
                        
                        <h5 className="text-left mrg-btm-0 font700"><strong>CARTUS Global Network</strong></h5>
                        <ul>
                            <li>2016 Supplier Innovation Silver Award</li>
                            <li>2015 Supplier Innovation Gold Award</li>
                            <li>2014 Gold Commitment To Excellence Award</li>
                            <li>2013 Gold Commitment To Excellence Award</li>
                            <li>2013 Silver Supplier Innovation Award</li>
                            <li>2010 Gold Supplier Quality Innovation Award</li>
                            <li>2009 Commitment To Excellence Platinum Award</li>
                         </ul>   

                         
                         <h5 className="text-left mrg-btm-0 mrg-top-30 font700"><strong>MoveCenter's Relocation Supplier Awards</strong></h5>
                        <ul>
                            <li>2017 Supplier of the Year for Corporate Housing & Destination Services</li>
                         </ul> 

                         
                         <h5 className="text-left mrg-btm-0 mrg-top-30 font700"><strong>People's Choice Stevie Awards</strong></h5>
                        <ul>
                            <li>2011 Front-Line Customer Service Professional of the Year</li>
                            <li>2011 Customer Service Department of the Year</li>
                         </ul> 

                         
                         <h5 className="text-left mrg-btm-0 mrg-top-30 font700"><strong>Bridgestreet Global Alliance</strong></h5>
                        <ul>
                            <li>2010 Most Valuable Player</li>
                         </ul> 

                         
                         <h5 className="text-left mrg-btm-0 mrg-top-30 font700"><strong>CHPA</strong></h5>
                         <h5 className="text-left pull-left mrg-btm-0 mrg-top-0 font700"><strong>Corporate Housing Providers Association</strong></h5>
                         <div className="clearfix"></div>
                        <ul>
                            <li>2009 Provider Company Of The Year</li>
                            <li>Tower Of Excellence Award</li>
                         </ul> 

                         
                         <h5 className="text-left mrg-btm-0 mrg-top-30 font700"><strong>Trippel Survey & Research, LLC</strong></h5>
                         <h5 className="text-left pull-left mrg-btm-0 mrg-top-0 font700"><strong>Relocations Managers Survey</strong></h5>
                         <div className="clearfix"></div>
                        <ul>
                            <li>2014 Highest Overall Satisfaction</li>
                            <li>2013 Highest Overall Performance</li>
                            <li>2012 Highest Overall Satisfaction</li>
                            <li>2011 Highest Overall Satisfaction</li>
                         </ul> 

                         
                         <h5 className="text-left mrg-btm-0 mrg-top-30 font700"><strong>Trippel Survey & Research, LLC</strong></h5>
                         <h5 className="text-left pull-left mrg-btm-0 mrg-top-0 font700"><strong>Relocations Employees Survey</strong></h5>
                         <div className="clearfix"></div>
                        <ul>
                            <li>2012 #1 Temporary Housing Provider In Overall & Net Satisfaction</li>
                            <li>2010 #1 Temporary Housing Provider In Overall Satisfaction</li>
                         </ul> 
                    </div>
                </div>
            </div>
            <SubFooter />
            <Footer/>
        </div>
        );
    }
}
export default IndustryAwards;