/* Importing the node modules, child components, services and controllers used 
   inside appController object */

   'use strict'

   import $ from "jquery";
   import ThirdPartyApis from '../../services/thirdPartyApis';
   import moment from 'moment';
   import momentTimezone from 'moment-timezone'
   const apiUrl = "https://mysuiteamerica.com/"
   const travelApiUrl = (window.location.hostname=='localhost') ? 'http://localhost:8081/' : "https://www.suiteamerica.com/"
   /**
    * Application controller - controls application state and interaction among various components.
    */
   const appController = {
       
       /* To make header as a fixed on scroll */
       stickyTitles() {
          
           $( "body" ).removeClass('bodytrns320l no-scroll profile-open')
           var $window = $(window),
               $stickies;
           var load = function (stickies) {
               if (typeof stickies === "object" && stickies instanceof $ && stickies.length > 0) {
                   $stickies = stickies.each(function () {
                       if ($(this).parent().is('div.followWrap')) {
                           $(this).unwrap('div.followWrap');
                       }
                       var $thisSticky = $(this).wrap('<div class="followWrap" />');
                       $thisSticky
                           .data('originalPosition', $thisSticky.offset().top)
                           .data('originalHeight', $thisSticky.outerHeight())
                           .parent()
                           .height($thisSticky.outerHeight());
                   });
                   $window
                       .off("scroll.stickies")
                       .on("scroll.stickies", function () {
                           _whenScrolling();
                       });
               }
           };
   
           var _whenScrolling = function () {
               $stickies
                   .each(function (i) {
                       var $thisSticky = $(this),
                           $stickyPosition = $thisSticky.data('originalPosition');
   
                       if ($stickyPosition <= $window.scrollTop()) {
   
                           var $nextSticky = $stickies.eq(i + 1),
                               $nextStickyPosition = $nextSticky.data('originalPosition') - $thisSticky.data('originalHeight');
   
                           $thisSticky.addClass("fixed");
   
                           if ($nextSticky.length > 0 && $thisSticky.offset().top >= $nextStickyPosition) {
   
                               $thisSticky
                                   .addClass("absolute")
                                   .css("top", $nextStickyPosition);
                           }
                       } else {
                           var $prevSticky = $stickies.eq(i - 1);
                           $thisSticky.removeClass("fixed");
                           if ($prevSticky.length > 0 && $window.scrollTop() <= $thisSticky.data('originalPosition') - $thisSticky.data('originalHeight')) {
                               $prevSticky
                                   .removeClass("absolute")
                                   .removeAttr("style");
                           }
                       }
                   });
           };
           setTimeout(function () {
               load($(".followMeBar"));
           }, 250);
       },
       LosAngeles(date){
       //return moment(date).tz("America/california").add(1, 'days').format('MM/DD/YYYY');
       console.log(date)
       },
       /* To convert date and time into PST format */
       pstDateTime(){
        // var  pstDateTime = moment().tz("America/Los_Angeles").format('YYYY-MM-DD HH:mm:ss')
        var  pstDateTime = momentTimezone().tz("America/Los_Angeles").format('YYYY-MM-DD HH:mm:ss')   
        return pstDateTime;
       },
       pstDateTimeconverter(date){
           var  pstDateTime = moment(date).tz("America/Los_Angeles").format('YYYY-MM-DD HH:mm:ss')
           return pstDateTime;
       },
       /* To convert date into PST format */
       pstDateOldXl(){
           var  pstDate = momentTimezone().tz("America/Los_Angeles").format('MM/DD/YYYY')
           return pstDate;
       },
   
       removeTime(date){
           const onlyDate=date.split("T00:00:00.000Z")
           var  dateFormat = moment(onlyDate[0]).format('MM/DD/YYYY')
           return dateFormat;
       },
       
       pstDate(){
           var  pstDate = momentTimezone().tz("America/Los_Angeles").format('YYYY-MM-DD')
           return pstDate;
       },
        utcDate(date){
           var  utcdate = moment(date).utc().format('L')
           return utcdate;
       },
       utcDatetrv(date){
           var  utcdate = moment(date).utc().format('L')
           return utcdate;
       },
   
       DateFormate(date){
           var  dateFormat = moment(date).format('L')
           return dateFormat;
       },
   
       traveAddUtcDate(date){
           var  utcdate = moment(date).add(1, 'days').tz("UTC").format('L')
           return utcdate;
       },
       utcDateTime(date){
           var  utcdate = moment(date).utc().format('YYYY-MM-DD hh:mm:ss A')
           return utcdate;
       },
   
       /* To display images in carousel */
       async carousel(){
           $(document).ready(function () {
               (function ($) {
                   $(".owl-carousel").owlCarousel({
                       center: true,
                       merge:true,
                     items:3,
                     loop:true,
                     margin:0,
                       nav:true,
                       navText: ["<img src='https://s3-us-west-1.amazonaws.com/suiteam-website/Website-Images/SuiteAmerica-Carousel-Left-Arrow.png'></img>", "<img src='https://s3-us-west-1.amazonaws.com/suiteam-website/Website-Images/SuiteAmerica-Carousel-Right-Arrow.png'></img>"],
                       navClass: ["center-left", "center-right"],
                     responsive:{
                         600:{
                             items:3
                         }
                     }
                   });
               })(jQuery);
           });
               
       },
       /* To initialize the animation for the text */
       updateAnimation(){
           $(document).ready(function () {
               setTimeout(function() {
                   new WOW().init();
               }, 200);
           });
       },
       /* To display the deals list zig zag in area information page */
       async dealsList(){
           $(document).ready(function () {
               setTimeout(() => {
                   (function ($) {
                       $('.grid').masonry({
                           itemSelector: '.item',
                           horizontalOrder: true
                         });
                   })(jQuery);
               }, 1000);
           });        
       },
       accountPage(){
           $(document).ready(function () {
               (function ($) {
                   $('.owl-carousel').owlCarousel({
              loop:false,
              margin:10,
              responsiveClass:true,
              nav:true,
                       navText: ["<img src='https://s3-us-west-1.amazonaws.com/suiteam-website/Website-Images/SuiteAmerica-Carousel-Left-Arrow-Grey.png'></img>", "<img src='https://s3-us-west-1.amazonaws.com/suiteam-website/Website-Images/SuiteAmerica-Carousel-Right-Arrow-Grey.png'></img>"],
                       navClass: ["center-left", "center-right"],
              responsive:{
                  0:{
                      items:1,
                      nav:true
                  },
                  600:{
                      items:3,
                      nav:false
                  },
                  1000:{
                      items:5,
                      nav:true,
                      loop:false
                  }
              }
            })
               })(jQuery);
           });    
       },
   
       slimScroll(){
           $(document).ready(function () {
               (function ($) {
                 $('#testDiv').slimscroll({height: '470px'}); 
                 setTimeout(function() {                
                   $('.scroolDiv').slimscroll({height: '125px', width: '200px'}); 
                 }.bind(this), 3000);      
               })(jQuery);
             });
       },
       distance(lat1, lon1, lat2, lon2, unit) {   
           var radlat1 = Math.PI * lat1/180
           var radlat2 = Math.PI * lat2/180
           var theta = lon1-lon2
           var radtheta = Math.PI * theta/180
           var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
           dist = Math.acos(dist)
           dist = dist * 180/Math.PI
           dist = dist * 60 * 1.1515
           if (unit=="K") { dist = dist * 1.609344 }
           if (unit=="N") { dist = dist * 0.8684 }
           return dist.toFixed(2)
       },
       travelBooking(){
           $(document).ready(function () {
               (function ($) {
                   $(".owl-demo-3").owlCarousel({
                       items : 1,
                       pagination:false,
                       nav: true,
                       autoHeight:true,
                       itemsCustom: [1600, 1],
                       smartSpeed: 1000,
                       loop:false,
                       touchDrag:true,
                       mouseDrag:true,
                       dots: false,
                       navText: [
                       "<img class='fa-chevron-left' src='https://s3-us-west-1.amazonaws.com/suiteam-website/Website-Images/SuiteAmerica-Carousel-Left-Arrow.png'></img>",
                       "<img class='fa-chevron-right' src='https://s3-us-west-1.amazonaws.com/suiteam-website/Website-Images/SuiteAmerica-Carousel-Right-Arrow.png'></img>"
                       ]
                   });
               })(jQuery);
           });  
       }, 
       travelBookingUrl(){
           return(travelApiUrl)
       },
       availabilityApi(){
           return(apiUrl+ 'api/GetAvailableMasters')
       },
       CreateOrder(){
           return(apiUrl+ 'api/CreateOrder')
       } , 
       dailyRent(communityid,defaultCommunityInfo,diffDate){
   
           const filteredService = _.filter(defaultCommunityInfo, function (o) 
           {
               
               return o.communityid == communityid;
             })
             
   if((filteredService.length === 0)){
   return '220'
   }else{
       let differoneadd=diffDate+1
       switch(true){
           case (differoneadd <= 6) :
      
           return filteredService[0].six
           case (differoneadd>=7 && differoneadd<=13):
   
           return filteredService[0].thirteen
     
           case (differoneadd>=14 && differoneadd<29):
       
           return filteredService[0].twentynine
           
           case (differoneadd>=29):
         
           return filteredService[0].thirty
                     }
   }
   
       },
        formatPhoneNumber(s) {
           var s2 = (s).replace(/\D/g, '');
           var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
           return (!m) ? null : "(" + m[1] + ") " + m[2] + "-" + m[3];
         },
       encodeData(data){
           let encodeString = data
            for (var i=0; i < 10; i++) {
                  encodeString = Base64.encode(encodeString)
             }
           return  encodeString
         },
         decodeData(data){
           let decodeString = data
            for (var i=0; i < 10; i++) {
                  decodeString = Base64.decode(decodeString)
             }
           return  decodeString
         },
         removeLocalStorageData(){
           localStorage.setItem('navIndex', -1);
           localStorage.setItem('menuId', 0);
           localStorage.setItem('clickedMenuId', 0);
         },
         addIndividualLocalStorage(navIndex, menuId, clickedMenuId){
           localStorage.setItem('navIndex', navIndex);
           localStorage.setItem('menuId', menuId);
           localStorage.setItem('clickedMenuId', clickedMenuId);
         },
         showSuccessModal(){
           jQuery(function ($) {
               setTimeout(function () {                
                   $('#successModal').modal('show');
               }.bind(this), 10);
               setTimeout(function () {
                   $('#successModal').modal('hide');
               }.bind(this), 4000);
           }.bind(this));
         },
         dateDifference(checkIn,checkOut){
           var checkInDate = moment(checkIn, 'MM/DD/YYYY');
           var checkOutDate = moment(checkOut, 'MM/DD/YYYY');
           var date1 = new Date(checkInDate);
           var date2 = new Date(checkOutDate);
           var timeDiff = Math.abs(date2.getTime() - date1.getTime());
           const startDate = moment([
                Number(moment(checkIn).format('YYYY')),
                Number(moment(checkIn).format('M')) - 1,
                Number(moment(checkIn).format('D'))
           ]);
           const endDate = moment([
                Number(moment(checkOut).format('YYYY')),
                Number(moment(checkOut).format('M')) - 1,
                Number(moment(checkOut).format('D'))
           ]);
           const finalDate = endDate.diff(startDate, 'days');
           
           return finalDate;
         },
         menuTopNavigation(idName) {
           $('html, body').animate({
               scrollTop: $('#' + idName).offset().top
           }, 400);
         },
         generateGraphs(staYingCount, bookedCount, pastData, totalCount, labels, average, color, randNumber) {
           $(document).ready(function () {
               if ( $('#bar-chart').length>0 && $('#graph'+randNumber).length>0 &&  $('#graph'+randNumber)!=null &&  $('#graph'+randNumber)!=undefined) {
                 jQuery(function () {
                   if (bookedCount == 0 && staYingCount == 0 && pastData == 0) {
   
                   } else {
                      const datta = new Morris.Donut({
                           element: 'graph' + randNumber,
                           data: [
                               {
                                   value: bookedCount,
                                   label: 'Current Reservations'
                               }, {
                                   value: staYingCount,
                                   label: 'Active'
                               }, {
                                   value: pastData,
                                   label: 'History'
                               }
                           ],
                           backgroundColor: '#ccc',
                           labelColor: '#060',
                           colors: [
                               '#3eb3da', '#16a085', '#cf2e22'
                           ],
                           formatter: function (x) {
                               return x == 0 ? '0' + "%" : Math.round((x / totalCount) * 100) + "%"
                           }
                       });
                   }
                   var barCanvas = document.getElementById("bar-chart");
                   var bookedData = {
                       label: 'Avg Daily Rate / City',
                       data: average,
                       backgroundColor: color,
                       borderWidth: 2
                   };
   
                   var completeData = {
                       labels: labels,
                       datasets: [bookedData]
                   };
   
                   var chartOptions = {
                       responsive: true,
                       maintainAspectRatio: false,
                       title: {
                           display: true,
                           fontSize: '24',
                           text: 'Avg Daily Rate / City'
                       },
                       scales: {
                           xAxes: [
                               {
                                   barPercentage: 1,
                                   categoryPercentage: 0.6
                               }
                           ]
                       }
                   };
                   var barChart = new Chart(barCanvas, {
                       type: 'bar',
                       data: completeData,
                       options: chartOptions
                   });
   
               });
               }
               else{
                   $("#travelgraph").hide();
               }   
              })
   
         },
         autoPlayVideo(id, source) {
           var video = $(id)[0];
           video.src = source;
           $(document).ready(function() {        
               video.load();
               video.play()
           });
         },
         pauseVideo(id, source) {
           var video = $(id)[0];
           video.src = source;
           $(document).ready(function() {        
               video.pause();
           });
         },
         removeSiteLoader() {
           setTimeout(function(){
               $(".site_loader").remove();
             },1000);
         },
             /* To get individual address walkscore */
       async getIndividualWalkscore(data, latitude, longitude) {
           const finalData = {
               name:"walkScore",
               type:"walkscore",
               url: this.encodeData('?format=json&address='+data.address+'&lat='+latitude+'&lon='+longitude+'&transit=1&bike=1'),
               };
           const walkscore = await ThirdPartyApis.thirdPartyApi(finalData);
           return (walkscore.walkscore);
   },
         async wallsckoreFun(communityData){
           let wallsckoreVal = 0
           let condition=communityData.address!="" && communityData.address!= undefined && communityData.latitude!= "" &&  communityData.latitude !=undefined?true:false
           if(condition){
               const data = {
                 name:"walkScore",
                 type:"walkscore",
                 url: this.encodeData('?format=json&address='+communityData.address+'&lat='+communityData.latitude+'&lon='+communityData.longitude+'&transit=1&bike=1'),
                  };
               const wallsckore = await ThirdPartyApis.thirdPartyApi(data);
               wallsckoreVal = wallsckore
           }
          
           return wallsckoreVal;
       }, 
       async wallsckoreFuns(communityData){
           let wallsckoreVal = 0
           let condition=communityData.address!="" && communityData.address!= undefined && communityData.latitude!= "" &&  communityData.latitude !=undefined?true:false
           if(condition){
               const data = {
                  name:"walkScores",
                  type:"walkscore2",
                  url: this.encodeData('?format=json&address='+communityData.address+'&lat='+communityData.latitude+'&lon='+communityData.longitude+'&transit=1&bike=1'),
                  };
               const wallsckore = await ThirdPartyApis.thirdPartyApi(data);
               wallsckoreVal = wallsckore
           }
           return wallsckoreVal;
       },
         async greatSchools(data){
          
           const Request = {
               name:"greatSchools",
               type:"greatschools",
               url:this.encodeData('?state='+data.state_name+'&lat='+data.latitude+'&lon='+data.longitude+'&schoolType=public-private-charter&limit=10'),
           }
           const greatSchools= await ThirdPartyApis.thirdPartyApi(Request)
           return greatSchools.message=="error"?{}:greatSchools
         },
   
   
         async greatSchools2(data){
           const Request = {
               name:"greatSchools",
               type:"greatschools",
               url:this.encodeData('?&lat='+data.latitude+'&lon='+data.longitude+'&limit=10&distance=20'),
           }
           const greatSchools= await ThirdPartyApis.greatSchools(Request)
           return greatSchools.message=="error"?{}:greatSchools
         },
     
         async weather(data) {
           const Request = {
               name:"weather",
               type:"weather",
              url: this.encodeData('?lat='+ data.latitude+ '&lon=' + data.longitude + '&cnt=35&units=metric')
            //  url: this.encodeData('?q=SANTA CLARA,CALIFORNIA,US&cnt=35&units=metric')
           
            }
           const weather = await ThirdPartyApis.thirdPartyApi(Request)
           return weather.message == "error" ? {} : weather
       },
       async busrailslines(communityData){
           const Request = {
               name:"busrailslines",
               type:"busrailslines",
               url:this.encodeData('?lat='+communityData.latitude+'&lon='+communityData.longitude+'&city='+communityData.city_name+'&state='+communityData.state_name+'&research=yes'),
           }
           const busrailslines= await ThirdPartyApis.thirdPartyApi(Request)
           return busrailslines.message=="error"?{}:busrailslines
       },
         async poi(data) {
           const Request = {
               name:"poi",
               type:"poi",
               url: this.encodeData('?query='+data.city+'+point+of+interest&language=en&required=2')
           }
           const poi = await ThirdPartyApis.pointofinterest(Request)
           return poi.message == "error" ? {} : poi
       },
   
   
       doughnutChart1 (walkScoreDetails) {
           jQuery(function($) {
               var donutEl = document.getElementById(walkScoreDetails.chartName).getContext("2d");
                var data=[
                    {
                      color: walkScoreDetails.Score==null ? "#fff" : walkScoreDetails.colour,
                      highlight: walkScoreDetails.Score==null ? "#fff" : walkScoreDetails.colour,
                      value: 100,
                    },
                    {
                     color:"#fff",
                     highlight: "#fff",
                      value: (100-(walkScoreDetails.Score==null?100:walkScoreDetails.Score)),
           
                    }
                  ]
                  var options={
                    segmentShowStroke : true,
                    segmentStrokeWidth : 0,
                    percentageInnerCutout : 93,
                    animationSteps : 80,
                    animationEasing : "easeInCubic",
                    animateRotate : true,
                    animateScale : false,
                    responsive: true,
                    maintainAspectRatio: true,
                    showScale: false,
                  }
               var donut = new Chart(donutEl).Doughnut(data,options);
                 
             })
        },
   
   
         doughnutChart (walkScoreDetails) {
             jQuery(function($) {
              var donutEl = document.getElementById(walkScoreDetails.chartName).getContext("2d");
              var donut = new Chart(donutEl).Doughnut(
                [
                  {
                    color: walkScoreDetails.Score==null ? "#BEBEBE" : walkScoreDetails.colour,
                    highlight: walkScoreDetails.Score==null ? "#BEBEBE" : walkScoreDetails.colour,
                    value: 100,
                  },
                  {
                   color:"#BEBEBE",
                   highlight: "#BEBEBE",
                    value: (100-(walkScoreDetails.Score==null?100:walkScoreDetails.Score)),
         
                  }
                ],
                {
                  segmentShowStroke : true,
                  segmentStrokeColor : "rgba(255,255,255,0)",
                  segmentStrokeWidth : 0,
                  percentageInnerCutout : 93,
                  animationSteps : 80,
                  animationEasing : "easeInCubic",
                  animateRotate : true,
                  animateScale : false,
                  responsive: true,
                  maintainAspectRatio: true,
                  showScale: false,
                });
            })
          },
          thumbnails(id) {      
           $(document).ready(function() {
               (function($) {
                   $('.thumbs' + id).owlCarousel({
                   items: 4,
                   dots:false,
                   onChanged: function (event) {
                        var item = event.item.index; 
                         $('.big' + id).trigger('to.owl.carousel', item, 500);
                       }
                   });
                   $('.thumbs' + id).on("click", ".owl-item", function(e) {
                       e.preventDefault();
                       var number = $(this).index();
                       $('.big' + id).data("owl.carousel").to(number, 300, true);
                   });
   
                   $('.big' + id).owlCarousel({
                   items: 1,
                   slideSpeed: 2000,
                   dots:false,
                   nav: true,
                   autoplay: true,
                   responsiveRefreshRate: 200,
                   autoHeight: true,
                   navText: [
                                   "<img class='fa-chevron-left' src='https://s3-us-west-1.amazonaws.com/suiteam-website/Website-Images/SuiteAmerica-Carousel-Left-Arrow.png'></img>",
                                   "<img class='fa-chevron-right' src='https://s3-us-west-1.amazonaws.com/suiteam-website/Website-Images/SuiteAmerica-Carousel-Right-Arrow.png'></img>"
                                   ],
                       onChanged: function (event) {
                           var item = event.item.index; 
                           $('.thumbs' + id).trigger('to.owl.carousel', item, 500);
                   }
                   });
               })(jQuery);
           });
       },
       floorThumbs(id) {      
           $(document).ready(function() {
               (function($) {
                   $('.thumbs' + id).owlCarousel({
                   items: 4,
                   dots:false,
                   });
                   $('.thumbs' + id).on("click", ".owl-item", function(e) {
                       e.preventDefault();
                       var number = $(this).index();
                   });
               })(jQuery);
           });
       },
   
       deanThumbs(id) {      
           $(document).ready(function() {
               (function($) {         
                       var sync1 = $("#sync1"+id);
                       var sync2 = $("#sync2"+id);
                       var slidesPerPage = 4; //globaly define number of elements per page
                       var syncedSecondary = true;
                     
                       sync1.owlCarousel({
                         items : 1,
                         slideSpeed : 2000,
                         nav: true,
                         autoplay: false,
                         dots: false,
                         loop: true,
                         responsiveRefreshRate : 200,
                         animateOut: 'fadeOut',
                         navText:  [
                           "<i class='fa fa-angle-left'></i>",
                           "<i class='fa fa-angle-right'></i>",
                           ],
                       }).on('changed.owl.carousel', syncPosition);
                     
                       sync2
                         .on('initialized.owl.carousel', function () {
                           sync2.find(".owl-item").eq(0).addClass("current");
                         })
                         .owlCarousel({
                         items : slidesPerPage,
                         dots: false,
                         nav: true,
                         smartSpeed: 200,
                         slideSpeed : 5000,
                         animateOut: 'fadeOut',
                          
                         autoplay: false,
                         slideBy: slidesPerPage, //alternatively you can slide by 1, this way the active slide will stick to the first item in the second carousel
                         responsiveRefreshRate : 100,
                         navText:  [
                           "<i class='fa fa-angle-left'></i>",
                           "<i class='fa fa-angle-right'></i>",
                           ],
                       }).on('changed.owl.carousel', syncPosition2);
                     
                       function syncPosition(el) {
                         //if you set loop to false, you have to restore this next line
                         //var current = el.item.index;
                         
                         //if you disable loop you have to comment this block
                         var count = el.item.count-1;
                         var current = Math.round(el.item.index - (el.item.count/2) - .5);
                         
                         if(current < 0) {
                           current = count;
                         }
                         if(current > count) {
                           current = 0;
                         }
                         
                         //end block
                     
                         sync2
                           .find(".owl-item")
                           .removeClass("current")
                           .eq(current)
                           .addClass("current");
                         var onscreen = sync2.find('.owl-item.active').length - 1;
                         var start = sync2.find('.owl-item.active').first().index();
                         var end = sync2.find('.owl-item.active').last().index();
                         
                         if (current > end) {
                           sync2.data('owl.carousel').to(current, 100, true);
                         }
                         if (current < start) {
                           sync2.data('owl.carousel').to(current - onscreen, 100, true);
                         }
                       }
                       
                       function syncPosition2(el) {
                         if(syncedSecondary) {
                           var number = el.item.index;
                           sync1.data('owl.carousel').to(number, 100, true);
                         }
                       }
                       
                       sync2.on("click", ".owl-item", function(e){
                         e.preventDefault();
                         var number = $(this).index();
                         sync1.data('owl.carousel').to(number, 300, true);
                       });
                    
               })(jQuery);
           });
       },
   
   
   
       regularExpressions(Names){
           switch(Names){
               case 'Email':
               return  /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
   
           }
   
       },
       getMonthName(date){
           var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July','August','September','October','November','December'];
           var m = new Date(date);
           var monthName = months[m.getMonth()];
           return monthName;
       },
       getDayName(date){
           var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
           var m = new Date(date);
           var dayName = days[m.getDay()];
           return dayName;
       },
       getDay(date){
           var d = new Date(date);
           var date = d.getDate();
           return date;
       },
       ScriptFile(){
           const script = document.createElement("script");
           script.integrity = '';
           script.src = "https://js.stripe.com/v3/";
           script.async = true;
           document.body.appendChild(script);
         }
       
   }
   export default appController
   