import { useState } from "react";
import DatePicker from "react-datepicker";

const SearchForm = ({ fetchCommunities, errorMessage }) => {
  const [searchFormState, setSearchFormState] = useState({
    moveIn: null,
    moveOut: null,
    selectLocation: "",
    size: "",
    latitude: 0,
    longitude: 0,
  });

  function updateSearchFormState(updateObj) {
    setSearchFormState((prevState) => ({ ...prevState, ...updateObj }));
  }

  const handleDateChange = (type, date) => {
    type == "move-in"
      ? updateSearchFormState({ moveIn: date })
      : updateSearchFormState({ moveOut: date });
  };

  function setLocation(e) {
    updateSearchFormState({ selectLocation: e.target.value });
    var places = new google.maps.places.Autocomplete(
      document.getElementById("selectLocation")
    );
    google.maps.event.addListener(places, "place_changed", function () {
      updateSearchFormState({
        selectLocation: places.getPlace().formatted_address,
        latitude: places.getPlace().geometry.location.lat(),
        longitude: places.getPlace().geometry.location.lng(),
      });
    });
  }

  function handleDropDownSelect(e) {
    updateSearchFormState({ size: e.target.value });
  }

  function searchForProperties() {
    //triger the api call after validating form
    fetchCommunities({
      ...searchFormState,
    });
  }

  return (
    <div className="bookit-search-form">
      <div className="card m-b-30">
        <div className="card-body">
          <span>{errorMessage}</span>
          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                <h6 className="text-muted fw-400 fw-bold">Address</h6>

                <div className="input-group">
                  <input
                    id="selectLocation"
                    className="form-control"
                    type="text"
                    placeholder="Enter Address"
                    autoComplete="off"
                    required
                    onChange={(e) => setLocation(e)}
                    value={searchFormState.selectLocation}
                  />
                  <div className="input-group-append bg-custom b-0 bookit-google-places">
                    <span className="input-group-text">
                      <i className="mdi mdi-map-marker"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <h6 className="text-muted fw-400 fw-bold">Move In</h6>
                <div className="input-group">
                  <DatePicker
                    selected={searchFormState.moveIn}
                    onChange={(date) => handleDateChange("move-in", date)}
                    dateFormat="MM/DD/YYYY"
                    className="form-control"
                    placeholderText="mm/dd/yyyy"
                    minDate={new Date()}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <h6 className="text-muted fw-400 fw-bold">Move Out</h6>

                <div className="input-group">
                  <DatePicker
                    selected={searchFormState.moveOut}
                    onChange={(date) => handleDateChange("move-out", date)}
                    dateFormat="MM/DD/YYYY"
                    className="form-control"
                    placeholderText="mm/dd/yyyy"
                    minDate={new Date()}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <h6 className="text-muted fw-400 fw-bold">Size</h6>
                <div className="input-group">
                  <select
                    className="form-control"
                    onChange={handleDropDownSelect}
                    value={searchFormState.size}
                  >
                    <option value="">All</option>
                    <option value="stu">STU</option>
                    <option value="1x">1X</option>
                    <option value="2x">2X</option>
                    <option value="3x">3X</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <h6 className="text-muted fw-400 py-1 pb-3"></h6>
              <button
                type="button"
                className="btn btn-dark px-3 mrg-top-4"
                onClick={searchForProperties}
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchForm;
