import React from 'react'
import Footer from './footer';
import OwlCarousel from 'react-owl-carousel';
import TransHeader from './transheader';
import RequestCallback from './requestCallback';

class BusinessTravel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  async componentDidMount() {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }
  }
  handleClick() {
    $('#sideQuote').addClass('active');
    $('.overlay').addClass('active');
    $('.collapse.in').toggleClass('in');
    $('a[aria-expanded=true]').attr('aria-expanded', 'false');
  }
  closed() {
    $('#sideQuote').removeClass('active');
    $('.overlay').removeClass('active');
  }
  render() {

    return (
      <div className="SAweb_updateUi back_layer">
        <TransHeader />
        <div className="overlay sideQuote"></div>
        <section className="SAweb_busniessTravelBanner">
          <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/screen-10/banner.jpg" alt="" width="100%" />
          <div className="textView"  >
            <h1  >Book a comfortable stay within seconds</h1>
            <p  >Redefine business travel with us. Book comfortable, technology-driven stays that make your experience rewarding.</p>

            <button className="btn  " onClick={this.handleClick} type="button"> Reserve Now </button>
          </div>
        </section>

        <section className="SAweb_busniessTravelBannerBottom text-center my-5">
          <div className="container">
            <h2 >Stays, better than a hotel</h2>
            <p className='p'>We’re experts in offering you the best in business hospitality that will make your experience nothing short of extraordinary.</p>

            <div className="box-tails">
              <div className="item">
                <div className="image">
                  <img className='manIcon' src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/work_friendly.svg" alt="" />
                </div>
                <p>Work-Friendly
                  Setting</p>
              </div>

              <div className="item">
                <div className="image">
                  <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/wifi1.svg" alt="" />
                </div>
                <p>Quick & Stable
                  Wi-Fi</p>
              </div>

              <div className="item">
                <div className="image">
                  <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/contemporary_appliances.svg" alt="" />
                </div>
                <p>Modern Appliances</p>
              </div>

              <div className="item">
                <div className="image">
                  <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/efficient_technology.svg" alt="" />
                </div>
                <p>Efficient Technology</p>
              </div>

              <div className="item">
                <div className="image">
                  <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/best_service.svg" alt="" />
                </div>
                <p>Best-In-Class Services </p>
              </div>

              <div className="item">
                <div className="image">
                  <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/cloud_communication.svg" alt="" />
                </div>
                <p>Cloud-Based Communication</p>
              </div>


              <div className="item">
                <div className="image">
                  <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/customer_support.svg" alt="" />
                </div>
                <p>Round-the-clock-support</p>
              </div>

            </div>

            <a href="/locations"  > <button type="button" className="btn black-btn">Explore Our Stays </button></a>
          </div>
        </section>

        <section className="SAweb_experienceBg py-5">

          <div className="container">
            <div className="row py-5">
              <div className="col-sm-6">
                <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/screen-10/blackBg-1.jpg" alt="" width="100%" />
              </div>
              <div className="col-sm-6">
                <div className="textView">
                  <h2>Experience magnificence at your convenience</h2>
                  <p className="text-white p"> Whether you’re moving cities, traveling for a meeting, or catching up with friends or associates, we offer services designed to meet your specific needs. </p>
                  <p className="text-white p"> With uninterrupted support and state-of-the-art technology, we make your stay worthwhile. </p>
                </div>
              </div>
            </div>
            <div className="row py-5 flex-sm-row-reverse">
              <div className="col-sm-6">
                <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/screen-10/blackBg-2.jpg" alt="" width="100%" />
              </div>
              <div className="col-sm-6">
                <div className="textView">
                  <h2>Stay, work, collaborate, and leave the rest to us.</h2>
                  <p className="text-white p"> Our expertise over the years enables us to offer exceptional business hospitality so you can work with ease.</p>
                </div>
              </div>

            </div>
          </div>
        </section>

        <section className="bg-white text-center SAweb_busniessTravelBanner2">
          <div className="container">


            <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/screen-10/banner-2.jpg" alt="" width="100%" />
            <h2 >Stay close, work smarter!</h2>
            <p className="p">We have inventory in prime neighborhoods of the US. So you have the freedom to work from any location of your choice. The benefit – you save enough time.</p>
          </div>
        </section>



        <section className="SAweb_busniessTravelService">
          <div className="item">
            <div className="pic">
              <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/screen-10/boxTails-1.jpg" alt="" width="100%" />
            </div>
          </div>
          <div className="item textItem">
            <h3 className="text-white">We deliver a promise of trust and excellence.</h3>
            <p className="text-white p">With top-notch amenities and round-the-clock support, we guarantee you the best living experience. When experts in the industry serve you, be assured of perfection.</p>
          </div>

        </section>
        <section className="SAweb_busniessTravelService item2">

          <div className="item textItem ">
            <h3 className="text-white">Fueled by technology to ensure consistency</h3>
            <p className="text-white p">Our cutting-edge technology facilitates instant connectivity and a steady flow of work, so while you're getting the job done, you can be satisfied that everything else is in place.</p>

          </div>
          <div className="item">
            <div className="pic">
              <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/screen-10/boxTails-2.jpg" alt="" width="100%" />
            </div>
          </div>
        </section>

        <section className="bg-white SAweb_busniessTravelOurHotels">
          <div className="container">
            <div className=" headingWithButton my-5 pt-4" data-aos="fade-up">
              <div>
                <h2 className="heaidngHalf">Why SuiteAmerica over hotels?</h2>
                <p className="fw-bold p">Our stays are curated to your specific needs, so you feel at home.</p>

              </div>

              <a href="/locations" ><button className="btn black-btn" type="button">Explore Locations </button></a>
            </div>




            <div className="boxTails">
              <div className="item">
                <div className='pic'>
                  <img src='https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/quick_booking.svg' alt='' />
                </div>
                <p>Quick & Seamless Booking</p>
              </div>
              <div className="item">
                <div className='pic'>
                  <img src='https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/superior_hospitality.svg' alt='' />
                </div>
                <p>Superior Business Hospitality</p>
              </div>
              <div className="item">
                <div className='pic'>
                  <img src='https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/parcel_concierge.svg' alt='' />
                </div>
                <p>Concierge Services</p>
              </div>
              <div className="item">
                <div className='pic'>
                  <img src='https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/home_like_appliances.svg' alt='' />
                </div>
                <p>Home-Like Amenities</p>
              </div>
              <div className="item">
                <div className='pic'>
                  <img src='https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/spacious_stays.svg' alt='' />
                </div>
                <p>Spacious Apartments</p>
              </div>


            </div>



          </div>
        </section>


        <section>
          <div className=" py-5">
            <div className="container SAweb_owl-slider2 py-5">
              <div className="row" data-aos="fade-up">
                <div className="col-sm-5 d-flex align-items-center">
                  <h2 >Here’s what business travelers have to say</h2>
                </div>
                <div className="col-sm-7 ">
                  <div className="owl-slider SAweb_sliderNav">
                    <div  >
                      <OwlCarousel className="owl-theme owl-carousel"
                        {...{
                          loop: false, margin: 20, nav: false, dots: false, items: 1,
                          navContainerClass: 'owl-buttons',
                          navText: ["<i class='glyphicon glyphicon-menu-left'></i>",
                            "<i class='glyphicon glyphicon-menu-right'></i>"],
                          responsive: {
                            767: { items: 1, slideBy: 1 },
                            992: { items: 2, slideBy: 1 }
                          }
                        }}>
                        <div className="item">

                          <div className="caption">
                            <i className="bi bi-quote"></i>

                            <p>All my SA contacts are courteous and flexible.  They have been very responsive and accommodating of my requests.</p>
                            <h3>Rhodora, San Ramon </h3>
                            <i className="bi bi-quote"></i>
                          </div>
                        </div>
                        <div className="item">

                          <div className="caption">
                            <i className="bi bi-quote"></i>
                            <p>Awesome moving in experience. Apartment was well setup and clean. Thank you.</p>
                            <h3>Aditya, Sunnyvale </h3>
                            <i className="bi bi-quote"></i>
                          </div>
                        </div>

                      </OwlCarousel>

                    </div>
                  </div>

                </div>
              </div>
            </div>


          </div>
        </section>
        <RequestCallback />

        <Footer />
      </div>
    );
  }
}
export default BusinessTravel;