/* Importing the node modules, child components, services and controllers used
   inside MetaTags component */
import Helmet from "react-helmet";
const MetaTags = (props) => {
    const {metaTitle, metaDesription, metaUrl} = props;
    return (
        <Helmet>
            <meta charSet="utf-8"/>
            <title>{metaTitle}</title>
            <meta name="description" content={metaDesription}/>
            <link rel="canonical" href={metaUrl}/>
        </Helmet>
    )
}

export default MetaTags;