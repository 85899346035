/* Importing the node modules, child components, services and controllers used 
   inside WorldBankLocations component */
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   import WorldBankHeader from "../../common/worldBankHeader";
   import sliderData from './sliderData.json';
   
   /* WorldBankLocations Component initialization */
   class WorldBankLocations extends React.Component {
       /* Initializing objects of its WorldBankLocations class */
       constructor(props) {
           super(props)
           this.state = {
            sliderData: sliderData
           }
           this.moreInfo = this.moreInfo.bind(this);    
        }
        /* It is invoked immediately before mounting occurs */
        componentWillMount() {
           MainController.getInitialInformation();
        }
        moreInfo(data) {
            _.assign(data, {amenities_id : ''});            
           localStorage.setItem('savedSearch', JSON.stringify(data));
           setTimeout(function() { 
            this.props.history.push("/" + data.webUrl);
           }.bind(this), 50);
        }   
       /* It is invoked to return html content */
       render() {   
           return (
            <div className="corporateHousing_Updates">
                <GuestHeader/>
                <WorldBankHeader/>
                <header className="codeconveyHeader cc_image_text_main corporate-h video-wrapper worldbnk_banner">
                    <img className="img-responsive" src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/world-bank/WashingtonDCcapital.jpg" width="100%" alt=""  />
                    <div className="wow fadeInUp img-on-text top40 width500 text-center left30 img-on-text-center company_bannertxt" data-wow-delay="0.5s">
                        <h1>Locations</h1>
                        <p>Communities Located in The World Bank's Key Areas</p>
                    </div>
                </header>
                <div className="container-fluid bg-dark-blue">
                    <div className="container">
                        <div className="tech_gallery mrg-top-60 mrg-btm-60 location_bnk ">
                            <div className="row">
                                {this.state.sliderData.map((row, index) => {
                                    return <div key={index} className="col-md-4 col-sm-4 tech_thumb mrg-btm-15">
                                        <a style={{cursor: 'pointer'}} 
                                        onClick={() => this.moreInfo(row)}>
                                            <img className="img-responsive" src={row.image} alt="" />
                                            <div className="techcaption new-d">
                                                <div className="techblur"></div>
                                                <div className="techcaption-text">
                                                    <h1>{row.title}</h1>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                   })
                                }
                            </div>
                        </div>
                    </div>
                </div>            
                <SubFooter/>
                <Footer/>
            </div>   
           );
       }
   }
   export default WorldBankLocations;