'use strict'

import LocationService from '../../../../services/locationService';
import EncryptDecrypt from '../../../../services/encryptDecrypt';
class SearchModal {
    constructor(thisObject, props) {
        this.Obj = thisObject
        this.propsObj = props
        this.state = {
            address: '',
            stateId: '',
            countryId: '',
            state_name: '',
            cityId: '',
            areaId: 0,
            bedId: 0,
            bathroomId: 0,
            minSqft: '',
            maxSqft: '',
            pets: true,
            communityName: '',
            amenitiesId: [],
            bathroomData: [
                {
                    bathroom_id: 1,
                    bathroom_name: '1+'
                }, {
                    bathroom_id: 2,
                    bathroom_name: '2+'
                }, {
                    bathroom_id: 3,
                    bathroom_name: '3+'
                }
            ],
            bedroomData: [
                {
                    bed_id: 1,
                    bed_name: '1+'
                }, {
                    bed_id: 2,
                    bed_name: '2+'
                }, {
                    bed_id: 3,
                    bed_name: '3+'
                }
            ],
            amenitiesData: [],

            zoneData: [],
            areaData: [],
            community: {},
            localSearchData: false,
            more: 1,
            less: 0,
            latitude: '',
            longitude: '',
            loadingStatus: false,
            amenitiesList: [],
            countrysList: [],
            stateList: [],
            cityList: []
        }
    }
    async DidMount() {
        this.communityData()
    }
    async setValue(field, e) {
        var object = {};
        object[field] = e.target.value;
        this.updateState(object);
        if (field == 'countryId') {
            this.stateList(e.target.value)
        }
        if (field == 'stateId') {
            this.cityList(e.target.value)
        }
    }
    async stateList(id) {
        const countryId = {
            'countryId': id
        }
        const stateList = await LocationService.getStatesByCountryId(countryId)
        this.updateState({stateList: stateList, stateId: '', cityId: ''})
    }
    async cityList(id) {
        const stateId = {
            'stateId': id
        }
        const cityList = await LocationService.getCitysByStateId(stateId)
        this.updateState({cityList: cityList, cityId: ''})
    }
    async amenitiesSelect(id, row, index) {
        const existingPost = this.Obj.state.amenitiesList[index];
        const updatedPost = update(existingPost, {
            is_active: {
                $set: !row.is_active
            }
        });
        let updatedPosts = update(this.Obj.state.amenitiesList, {
            [index]: {
                $set: updatedPost
            }
        });
        await this.updateState({amenitiesList: updatedPosts});
        var ids = []
        for (var i = 0; i < updatedPosts.length; i++) {
            if (updatedPosts[i].is_active) {
                ids.push(updatedPosts[i].TableId);
            }
        }
        await this.updateState({amenitiesId: ids})
    }
    async refreshSearchResult()
    {
        if (this.Obj.state.address != '' || this.Obj.state.countryId != '' || this.Obj.state.stateId != '' || this.Obj.state.cityId != '' || this.Obj.state.communityName != '' || this.Obj.state.amenitiesId.length != 0) {
                 this.updateState({
                    countryId: '',
                    stateId: '',
                    cityId: '',
                    address: '',
                    statename: '',
                    latitude: '',
                    longitude: '',
                    communityName: '',
                    cityList: [],
                    amenitiesId: [],
                    stateList: [],
                });
                document.getElementById("city-input1").value = ''           
                this.communityData();
                $('#city-input').val('');
                await this.propsObj.clearStateValues()
                await this.propsObj.getSearchResult()
        }
        
    }
    async Search() {
        const webGuest = localStorage.getItem('_u_web');
        const response = await EncryptDecrypt.decryptData({'type': webGuest})
        const userData = response.recordset[0]
        let companyName = this.propsObj.companyName == undefined? '' : this.propsObj.companyName;

        switch (companyName) {
            case 'Nike':
                companyName = 'Nike';
                break;
            case 'NikeIntern':
                companyName = 'NikeIntern'
                break;
            case 'Adidas Intern':
                companyName = 'Adidas Intern'
                break;
            case 'ServiceNow Intern':
                companyName = 'ServiceNow Intern'
                break;
                case 'Adobe':
                companyName = 'Adobe'
                break;
            case 'Nike Apprenticeship':
                companyName = 'Nike Apprenticeship'
                break;    
                case 'Lumentum':
                companyName = 'Lumentum'
                break;
            case 'Tesla':
                companyName = 'Tesla';
                break;
            default:
                companyName = 'suiteamerica';
        }
        if (this.Obj.state.address != '' || this.Obj.state.stateId != 0 || this.Obj.state.cityId != 0 || this.Obj.state.communityName != '' || (this.Obj.state.pets || !this.Obj.state.pets)) {
            this.updateState({loadingStatus: true})
            var amenitiesArray = this.Obj.state.amenitiesId;
            var amenitesString = amenitiesArray.toString();
            const communitySearch = {
                startLimit:0,
                companyname: companyName,
                latitude: this.Obj.state.latitude,
                longitude: this.Obj.state.longitude,
                address: this.Obj.state.address,
                state_id: this.Obj.state.stateId,
                city_id: this.Obj.state.cityId,
                amenitiesId: amenitesString,
                communityName: this.Obj.state.communityName==undefined?'':this.Obj.state.communityName,
                countryId: this.Obj.state.countryId,
                userid: (userData != undefined && Object.keys(userData).length > 0)? userData.Id: ''
            }
           
            const SearchResult = await LocationService.getCommunityData(communitySearch)
            if (SearchResult) {
                this.updateState({loadingStatus: false})
            }
            await this.propsObj.savedSearch(communitySearch, SearchResult, this.Obj.state.latitude, this.Obj.state.longitude)
        }
    }
    setLocation(e) {
        const _this = this;
        this.updateState({address: e.target.value});
        const icon = 'https://s3-us-west-1.amazonaws.com/suiteam-website/Destination-Services/SuiteAme' +
                'rica-Destination-Services-Icon-Map-Marker-Green.png';
                console.log(icon)
        var places = new google.maps.places.Autocomplete(document.getElementById('city-input1'));
        google.maps.event.addListener(places, 'place_changed', function () {
                var place = places.getPlace();
                const newLat = place.geometry.location.lat();
                const newLng = place.geometry.location.lng();
                _this.updateState({latitude: newLat, longitude: newLng});
        });
    }
    async communityData()
    {
        const countrysList = await LocationService.getCountryList()
        const amenitiesList = await LocationService.getAmenitiesList()
        const addIsActiveAmenitiesList = amenitiesList.map((file) => {
            file.is_active = false;
            return file;
        });
        this.updateState({countrysList: countrysList,amenitiesList: addIsActiveAmenitiesList})
    }
    async updateState(updateObject) {
        await this.Obj.setState(updateObject)
    }
}

export default SearchModal