
import NikePropertyDetails from '../nikeDs/nikePropertyDetails';
import ApplePropertyDetails from '../appleDs/applePropertyDetails';
import NoPricingPropertyDetails from '../noPricingDs/noPricingPropertyDetails'
import VirtualPropertyDetails from '../virtual/virtualPropertyDetails'
class PropertyDetails extends React.Component {    
      constructor(props){
        super(props);
      }
      templateLoad(){       
        const companyInfo = this.props.match.params
        let data;
        if(companyInfo.companyName=='nike' || companyInfo.companyName=='pricing'){
            data= <NikePropertyDetails proprertyData={companyInfo}/>
        }else if(companyInfo.companyName=='apple'){
            data= <ApplePropertyDetails proprertyData={companyInfo}/>
        }else if(companyInfo.companyName=='nopricing'){
            data= <NoPricingPropertyDetails proprertyData={companyInfo}/>
        }else if(companyInfo.companyName=='virtual'){
          data= <VirtualPropertyDetails proprertyData={companyInfo}/>
      }
        return data;
      }
      render(){          
          return(<span>{this.templateLoad()}</span>)
      }
}

export default PropertyDetails