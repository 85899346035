import MainController from "../../controller/mainController";
import SubFooter from "../../common/subFooter";
import GuestHeader from '../../common/guestHeader';
import Footer from '../../common/footer';
import MediaFooter from "./common/mediaFooter";
import MetaTags from '../../common/metaTags';
import ServerMetaTags from '../../../server/metaTags';

/* MediaOurPhilosophy Component initialization */
class MediaOurPhilosophy extends React.Component {
    /* Initializing objects of its MediaContact class */
    constructor(props) {
        super(props);
    }
    /* It is invoked immediately before mounting occurs */
    componentWillMount() {
        MainController.getInitialInformation();
    }
    /* It is invoked to return html content */
    render() {
        const metaData = ServerMetaTags.urlString.getMetaData(this.props.match.url);
        return (
            <div>
            <MetaTags 
             metaTitle = {metaData.title}
             metaDesription = {metaData.description}
             metaUrl = {metaData.canonical} />
             <GuestHeader/>
            <div className="cover-tophd-ht company_ht_cover"></div>
             <div className="quote-section bg-white">
                <div className="container mediakit">
                   <div className="plaine-text global-coverage-wrap col-md-10 col-md-offset-1 wow fadeInUp" data-wow-delay="0.5s">
                   <h2 className="mrg-btm-50 color-red">OUR PHILOSOPHY</h2>
                        <p>While creating SuiteAmerica almost 30 years ago, we envisioned a company that puts culture first. One whose 
                        success and reputation hinges not on face-value marketing or trendy technology, but on dependable, trusted 
                        and enduring excellence. Our employees are key to that excellence. </p>
    <p>So we started developing SuiteAmerica by identifying the right employees, those who were innately friendly, 
    passionate and hardworking, and giving them the power to do whatever it takes to make our guests happy 
    and satisfied. We knew these folks would work harder and longer than most to make even the weariest 
    traveler feel welcomed and comfortable. They are why our hospitality continually comes from the soul. And 
    why, there's no place like SuiteAmerica.</p>
                   </div>
                 <MediaFooter />
                 </div>
              </div>   
             <SubFooter />
             <Footer/>
            </div>
        );
    }
}
export default MediaOurPhilosophy;