import React from 'react'
import Footer from './footer';
import Header from './header';

class CareerPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    async componentDidMount() {
      window.onbeforeunload = function () {
        window.scrollTo(0, 0);
      }
    }
    render() {
        return (
            <div className="SAweb_updateUi">
                <Header />
                <section className=" SAweb_careerPageBanner" data-aos="fade-up">
      <div className="container">
        <div className="box">
          <h1>Guest First, Always</h1>
          <p className="p">We're Building A Team That's Always At The Forefront When I Comes To Serving Guests. Individuals From Different Corners Country Work Together Towards Achieving One Goal - Provid Supreme Hospitality Experience To Every Guest.</p>
        </div>

      </div>

    </section>
    <section className="SAweb_carrerOurGoals">
      <div className="container">
        <div className="box">
          <div className="item">
            <h2>Our Values</h2>
            <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/Career/goalsImage-1.jpg" alt="" width="100%" />
          </div>
          <div className="item tails">
            <div className="items">
              <h4>Guest First, Always</h4>
              <p>We’re Building A Team That’s Always At The Forefront When It Comes To Serving Guests. Individuals From
                Different Corners Of The Country Work Together Towards Achieving One Goal – Providing A Supreme
                Hospitality Experience To Every Guest.</p>
            </div>
            <div className="items">
              <h4>A Team That’s Got Your Back</h4>
              <p>At SuiteAmerica, We Nurture A Positive Work Environment. Whether You’re Stuck Amid A New Project Or
                Need Advice On A Personal Matter, Your Team Is Always Right By Your Side.</p>
            </div>
            <div className="items">
              <h4>Technology And Innovation At Heart</h4>
              <p>We’re Always Looking To Hire Individuals With A Zeal For Innovation. With A Unified Approach And Shared
                Goals, We Strive To Bring Both A Touch Of Tech And A Whole Lot Of Innovation To Make Stays Better Than
                Ever.</p>
            </div>



          </div>
        </div>
      </div>
    </section>

    <section className="SAweb_carrerWorkingSA">
      <div className="container">
        <h2>Perks of working with SuiteAmerica</h2>
        <p className="p">We care about your wellbeing because when you’re at your best, you do your best at work!</p>
        <div className="box">
          <div className="item">
            <div className="pic"></div>
            <div className="text">
              <h4>Medical, Dental and Vision benefits</h4>
              <p>Health benefits that got you covered. No additional charges involved.</p>
            </div>
          </div>
          <div className="item">
            <div className="pic"></div>
            <div className="text">
              <h4>401 (k) Program</h4>
              <p>An exclusive retirement plan to save for the future.</p>
            </div>
          </div>
          <div className="item">
            <div className="pic"></div>
            <div className="text">
              <h4>Paid Time-off</h4>
              <p>Sick leave, 20 paid leaves per year, and company holidays so you can spend quality time with family.
              </p>
            </div>
          </div>
          <div className="item">
            <div className="pic"></div>
            <div className="text">
              <h4>Special employee discounts</h4>
              <p>Get significant discounts on furniture and housewares to create the abode of your dreams.</p>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section className="SAweb_carrerCurrentJob">
      <div className="container">
        <h2>View Current Jobs</h2>
        <p className="p">Can’t wait to join our team? Check out the current open positions and apply for a role that suits
          you best.</p>

        <div className="inputFileds">
          <div className="form-group SAweb_selectDrop">
            <select className="form-select" aria-label="Default select example">
              <option selected>Department</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div>
          <div className="form-group SAweb_selectDrop">
            <select className="form-select" aria-label="Default select example">
              <option selected>Location</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div>
        </div>

        <div className="resutls">
          <h3>Department Name</h3>
          <ul className="view">
            <li> Job opening name 1
              <span>City name, State Name</span>
            </li>
            <li> Job opening name 2
              <span>City name, State Name</span>
            </li>
            <li> Job opening name 3
              <span>City name, State Name</span>
            </li>
            <li> Job opening name 4
              <span>City name, State Name</span>
            </li>
            <li> Job opening name 5
              <span>City name, State Name</span>
            </li>
            <li> Job opening name 6
              <span>City name, State Name</span>
            </li>
            <li> Job opening name 7
              <span>City name, State Name</span>
            </li>
            <li> Job opening name 8
              <span>City name, State Name</span>
            </li>
          </ul>
        </div>



      </div>
    </section>
                <Footer />
            </div>


        );
    }
}
export default CareerPage;