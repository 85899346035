/* Importing the node modules, child components, services and controllers used 
   inside TravelBookingHeader component */
import { Link } from 'react-router-dom';
import CallModal from './callModal';
import BookNowCommunity from '../components/travelBooking/booknowCommunity';
import TravelBookingLogin from '../components/travelBooking/login';

/* TravelBookingHeader Component initialization */
class TravelBookingHeader extends React.Component {
    /* Initializing objects of its TravelBookingHeader class */
    constructor(props) {
        super(props);
        this.state = {
            phoneNo: '',
            message: '',
            color: ''
        }
        this.clearPhone = this.clearPhone.bind(this);
        this.logOut = this.logOut.bind(this);
    }
    /* To clear input field values */
    clearPhone() {
        this.setState({ phoneNo: '', message: '', color: '' });
    }
    /* To logout from the travel booking */
    logOut() {
        localStorage.clear()
        location.href = "/travel-booking"
    }
    /* It is invoked to return html content */
    render() {

        const emailId = localStorage.getItem('travel-email');
        const password = localStorage.getItem('travel-password');

        return (
            <div>
                <header className="travel_header">
                    <nav className="navbar navbar-default">
                        <div className="container-fluid">
                            <div className="navbar-header">
                                <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                                    <span className="sr-only">Toggle navigation</span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                                <Link className="navbar-brand" to={"/travel-booking"}>
                                    <img src={"/assets/newUI-images/logo/SA-full-logo.svg"} alt="" /></Link>
                            </div>
                            <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                                <ul className="nav navbar-nav">
                                    <li>
                                        <a href="#" className="contact" data-toggle="modal" data-target="#callModal" onClick={this.clearPhone}>
                                            <img src={"/assets/newUI-images/nike/phone.svg"} alt="" />+1 800.367.9501
                                        </a>
                                    </li>
                                </ul>
                                <Link className="navbar-brand travel_brand" to={"/travel-booking"}>
                                    <img src={"/assets/newUI-images/logo/SA-full-logo.svg"} alt="" /></Link>

                                <ul className="nav navbar-nav navbar-right tr_booking_nav bg-dark">
                                    {emailId != null && password != null ?
                                        <li>
                                            <Link to='/travel-booking-admin'>
                                                <i className="fa fa-user"></i> Admin</Link>
                                        </li> :
                                        ''}
                                    {emailId == null && password == null ?
                                        <li>
                                            <a data-toggle="modal" data-target="#travel-booking-login">
                                                <i className="fa fa-sign-in"></i> Login</a>
                                        </li> :
                                        <li>
                                            <a onClick={this.logOut}>
                                                <i className="fa fa-sign-out"></i> Logout</a>
                                        </li>}

                                </ul>
                            </div>
                        </div>
                    </nav>

                </header>
                <CallModal />
                <BookNowCommunity />
                <TravelBookingLogin />
            </div>

        );
    }
}
export default TravelBookingHeader;