import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

// dummy apis
import GuestServicesapi from "../../../services/guestServicesapi";
import ThirdPartyApiService from "../../../services/thirdPartyApis";
import BookitApiService from "../../../services/bookit";
import LoaderGif from "../../common/loader";

const BookItLogin = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const [state, setState] = useState({
    emailAddress: "",
    otp: "",
    view: "emailView",
    isLoading: false,
    message: "",
    color: "red",
    isLoggedIn: null,
  });

  const history = useHistory();

  const emailView = {
    text: "Send OTP",
    "button-id": "email-button",
    "input-id": "email-input",
    value: state.emailAddress,
    inputPlaceholder: "Your Email Address",
    headerText: "Hello.",
    subHeaderText:
      "Enter your email address to receive a one-time password (OTP) to login",
  };

  const otpView = {
    text: "Verify Otp",
    "button-id": "otp-button",
    "input-id": "otp-input",
    value: state.otp,
    inputPlaceholder: "Enter OTP",
    headerText: "Hello.",
  };

  function changeMessageAndColor(message, color) {
    updateState({ message, color });
  }

  function startLoader() {
    updateState({ isLoading: true });
  }

  function stopLoader() {
    updateState({ isLoading: false });
  }

  function getDetails(key) {
    if (state.view === "emailView") {
      return emailView[key];
    } else {
      return otpView[key];
    }
  }

  function startOtpVerification(email) {
    sendOtp(email);
  }

  async function sendOtp(email) {
    const body = {
      email: email.trim(),
    };
    let sentemailotp = await ThirdPartyApiService.guestsentemailotp(body);
    if (sentemailotp == "response") {
      onOtpSendSuccess();
    } else if (sentemailotp == "error") {
      onOtpSendFailure();
    }
  }

  function onOtpSendSuccess() {
    changeMessageAndColor("OTP has been sent to your email", "green");
    changeView("otpView");
    stopLoader();
  }

  function onOtpSendFailure() {
    changeMessageAndColor("something went wrong while sending OTP", "red");
    stopLoader();
  }

  function changeView(view) {
    updateState({ view });
  }

  async function handleLogin(e) {
    e.preventDefault();
    if (!isValidEmail(state.emailAddress)) {
      changeMessageAndColor("Please enter a valid email", "red");
      stopLoader();
      return;
    }
    if (!(await isUserExisting(state.emailAddress))) {
      changeMessageAndColor("Login Failed. User does not exists. ", "red");
      stopLoader();
      return;
    }

    startOtpVerification(state.emailAddress);
  }

  async function handleOtpValidation(e) {
    startLoader();
    e.preventDefault();
    if (!state.otp.length) {
      changeMessageAndColor("please enter a valid OTP", "red");
      stopLoader();
      return;
    }
    if (!(await isOtpValid(state.emailAddress, state.otp))) {
      onOtpVerificationFailure();
      stopLoader();
      return;
    }

    onOtpVerificationSuccess();
    stopLoader();
  }

  async function onOtpVerificationSuccess() {
    changeMessageAndColor("OtpVerification Success", "green");
    createSession(state.emailAddress);
  }

  function createSession(email) {
    BookitApiService.createSession({ email })
      .then((res) => {
        console.log(res);
        history.push("/dashboard");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function onOtpVerificationFailure() {
    console.log("OtpVerification Failure");
    changeMessageAndColor("OtpVerification Failure", "red");
  }

  async function isOtpValid(email, otp) {
    let verifyObject = {
      email: email.trim(),
      verification_code: otp,
    };

    let sentotpverify = await ThirdPartyApiService.guestsentotpverify(
      verifyObject
    );

    if (sentotpverify == "success") {
      return true;
    } else if (sentotpverify == "wrong") {
      return false;
    }
  }

  function handleClick(e) {
    const targetId = e.target.id;
    if (targetId) {
      startLoader();
    }
    if (targetId === "email-button") {
      handleLogin(e);
    } else if (targetId === "otp-button") {
      handleOtpValidation(e);
    }
  }

  function isValidEmail(email) {
    const emailRegExp =
      /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (!emailRegExp.test(email)) {
      return false;
    }
    return true;
  }

  function isValidNumber(number) {
    const numberRegex = /\d+/g;
    if (numberRegex.test(number)) {
      return true;
    }
    return false;
  }

  function handleChange(e) {
    const targetId = e.target.id;
    const value = e.target.value;
    if (targetId === "email-input") {
      state.message && changeMessageAndColor("", "red");
      updateState({ emailAddress: value });
    } else if (targetId === "otp-input" && isValidNumber(value)) {
      updateState({ otp: value });
    }
  }

  function handleArrowKeyPress(e) {
    if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
      // Prevent the default arrow key behavior (moving the cursor)
      e.preventDefault();
      // Implement your custom logic for moving the cursor here
      const inputValue = e.target.value;
      const inputElement = e.target;
      const cursorPosition = inputElement.selectionStart;

      if (e.key === "ArrowLeft" && cursorPosition > 0) {
        inputElement.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
      } else if (e.key === "ArrowRight" && cursorPosition < inputValue.length) {
        inputElement.setSelectionRange(cursorPosition + 1, cursorPosition + 1);
      }
    }
  }

  function updateState(updateObj) {
    setState((prevObj) => ({
      ...prevObj,
      ...updateObj,
    }));
  }

  async function isUserExisting(email) {
    // make api call to check if user exists
    const loginReqObj = {
      email: email,
      password: "",
    };

    try {
      const authCheckRes = await BookitApiService.ClientProfileLogin(
        loginReqObj
      );
      console.log(authCheckRes);
      if (authCheckRes.error == 0) {
        return true;
      }
      return false;
    } catch (err) {
      return false;
    }
  }

  async function isUserLoggedIn() {
    try {
      const sessionResponse = await BookitApiService.checkSession();
      if (!sessionResponse.loggedIn) {
        return false;
      }
      return true;
    } catch (err) {
      return false;
    }
  }

  async function checkUserLoginStatus() {
    if (!(await isUserLoggedIn())) {
      updateState({ isLoggedIn: false });
      return;
    }
    updateState({ isLoggedIn: true });
  }

  useEffect(() => {
    checkUserLoginStatus();
  }, []);

  useEffect(() => {
    const email = urlParams.get("email");

    if (email) {
      updateState({ emailAddress: email });
    }
  }, [location.search]);

  if (state.isLoggedIn === true) {
    history.push("/dashboard");
  } else if (state.isLoggedIn === false) {
    return (
      <div className="sa-admin-login">
        <div className="item">
          <img
            src="https://suiteam-website.s3.us-west-1.amazonaws.com/SuiteCareMain.jpeg"
            alt="logo"
            className="logoBanner"
          />
        </div>
        <div className="item">
          <form className="form-signin">
            <img src="/images/bookit/CAR.svg" height="50" alt="logo" />
           
            <Header
              headerText={getDetails("headerText")}
              subHeaderText={getDetails("subHeaderText")}
            />
             <MessageBox message={state.message} color={state.color} />
            <Input
              inputId={getDetails("input-id")}
              value={getDetails("value")}
              handleChange={handleChange}
              handleArrowKeyPress={handleArrowKeyPress}
              placeholder={getDetails("inputPlaceholder")}
            />

            {state.isLoading ? (
              <Loader />
            ) : (
              <div onClick={handleClick}>
                <Button
                  buttonText={getDetails("text")}
                  buttonId={getDetails("button-id")}
                />
              </div>
            )}
          </form>
        </div>
      </div>
    );
  }
  return <LoaderGif />;
};

const Button = ({ buttonText, buttonId }) => {
  return (
    <button className="btn btn-dark py-2 px-5 mt-3" id={buttonId}>
      {buttonText}
    </button>
  );
};

const Loader = () => {
  return (
    <div className="mt-3">
      <span
        style={{
          fontSize: "20px",
          width: "100%",
          margin: "auto",
          textAlign: "center",
        }}
      >
        <i className="fa fa-spinner fa-spin" />
        <span style={{ marginLeft: "10px" }}> please wait ...!</span>{" "}
      </span>
    </div>
  );
};

const Input = ({
  inputId,
  value,
  handleChange,
  handleArrowKeyPress,
  placeholder,
}) => {
  return (
    <div className="form-group input-group m-0">
      <span className="has-float-label">
        <input
          className="form-control"
          id={inputId}
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          onKeyDown={handleArrowKeyPress}
        />
      </span>
    </div>
  );
};

const MessageBox = ({ message, color }) => {
  return (
    <>
      {message != "" && (
        <div className="item phColor mt-3">
          {/* <h1 className="h3 mb-3 fw-normal pt-4">Hello.</h1> */}
          <div
            className={`alert ${
              color === "red"
                ? "alert-danger"
                : "alert-success alert-dismissible"
            }`}
            style={{ fontSize: "15px" }}
          >
            {message}
          </div>
        </div>
      )}
    </>
  );
};

const Header = ({ headerText, subHeaderText }) => {
  return (
    <>
      {headerText && <h1 className="h3 mb-3 fw-normal pt-4">{headerText}</h1>}
      {subHeaderText && <span>{subHeaderText}</span>}
    </>
  );
};

export default BookItLogin;
