/* Importing the node modules, child components, services and controllers used 
   inside NikeFrequentQuestions component */    
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import NikeHeader from "../../common/nikeHeader";
   import FAQ from "./common/faq"
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   /* NikeFrequentQuestions Component initialization */
   class NikeFrequentQuestions extends React.Component {
       /* Initializing objects of its NikeFrequentQuestions class */
       constructor(props) {
           super(props);
           this.state={
            faq: [{
                'Question':'Standard items typically included in your new apartment or home',
                'Answer': " SuiteAmerica's corporate apartments are substantially larger than any regular or extended stay hotel room. Our apartments average 1000 square feet, and are fully furnished to offer all the amenities of being at home, making your stay comfortable and enjoyable."
            },
            {
                'Question':'How do I make a reservation or request information from SuiteAmerica?',
                'Answer': " You can reserve your temporary accommodations by calling Nike’s Relocation Coordinator, Nathan Kraft at 916.941.7970 x612.Once your reservation is complete, you will receive a confirmation detailing your stay via email. The confirmation will include instructions on how to access your apartment, your move in date, move out date, temporary address, temporary phone number,and daily rate. Your confirmation also includes information on the amenities provided at your community, and directions from all local major airports.."
            },
            {
                'Question':'Can I bring my pet?',
                'Answer': " Many of SuiteAmerica’s communities allow small pets. Please specify the type of pet on the online reservation form, or feel free to discuss this with Nike’s Relocation Coordinator, Nathan Kraft at 916.941.7970 x612."
            },
            {
                'Question':'Can I make reservations directly with SuiteAmerica or do I need to use a travel management service?',
                'Answer': "As an authorized housing provider to Nike, SuiteAmerica can accept reservations directly. You do not need to go through a Travel Management Service. To make a reservation, call Nike’s Relocation Coordinator, Nathan Kraft at 916.941.7970 x612."
            }]
           }
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
           MainController.getInitialInformation();
        }
       /* It is invoked to return html content */
       render() {
           const faq = this.state.faq;
           return (
                <div className="corporateHousing_Updates">
                    <GuestHeader/>
                    <div className="cover-tophd-ht company_ht_cover"></div>
                    <NikeHeader />
                    <div className="container-fluid nike guest_sitenew">
                        <div className="container intra_staff2 col-sm-8 col-sm-offset-2">
                            <h2 className="text-center company-title">FREQUENT QUESTIONS</h2>
                            <div id="accordion" className="panel-group mrg-btm-90 company_acdn" role="tablist" aria-multiselectable="true">
                        {faq.map((row, index) => {
                        return (
                        <div className="panel panel-default" key={index}>
                           <div className="panel-heading">
                              <h4 className="panel-title">
                                 <a className="collapsed"
                                    data-toggle="collapse"
                                    data-parent="#accordion"
                                    href={'#collapse' + index}
                                    aria-expanded="false"
                                    aria-controls={row.id}
                                    >{row.Question}</a>
                              </h4>
                           </div>
                           <div id={'collapse' + index}
                              className="panel-collapse collapse">
                              <div className="panel-body">
                                 <div className="profile-opener">
                                    {row.Answer}
                                 </div>
                              </div>
                           </div>
                        </div>
                        )
                        })}
                     </div>
                        </div>
                    </div>
                    <div className="container-fluid bg-grey">
                        <div className="container">
                            <FAQ />
                        </div>
                    </div>
                    <SubFooter/>
                    <Footer/>
                </div>
           );
       }
   }
   export default NikeFrequentQuestions;