const Loader=()=>{
    return(
      <div className="gif_loader">
      <img src="assets/newUI-images/explore_icons/Icon-animation-loop.gif" />
    </div>
      //   <div id="guestLoader">
      //   <span className="dot" />
      //   <span className="dot" />
      //   <span className="dot" />
      //   <span className="dot" />
      // </div>
    )
}

export default Loader;