import MainController from "../../controller/mainController";
import SubFooter from "../../common/subFooter";
import GuestHeader from '../../common/guestHeader';
import Footer from '../../common/footer';
import MediaFooter from "./common/mediaFooter";
import MetaTags from '../../common/metaTags';
import ServerMetaTags from '../../../server/metaTags';

/* MediaOurServices Component initialization */
class MediaOurServices extends React.Component {
    /* Initializing objects of its MediaOurServices class */
    constructor(props) {
        super(props);
    }
    /* It is invoked immediately before mounting occurs */
    componentWillMount() {
        MainController.getInitialInformation();
    }
    /* It is invoked to return html content */
    render() {
        const metaData = ServerMetaTags.urlString.getMetaData(this.props.match.url);
        return (
            <div>
            <MetaTags 
             metaTitle = {metaData.title}
             metaDesription = {metaData.description}
             metaUrl = {metaData.canonical} />
             <GuestHeader/>  
            <div className="cover-tophd-ht company_ht_cover"></div>
             <div className="quote-section bg-white">
                <div className="container mediakit">
                   <div className="plaine-text global-coverage-wrap col-md-10 col-md-offset-1 wow fadeInUp" data-wow-delay="0.5s">
                   <h2 className="mrg-btm-50 color-red">SERVICES</h2>
                        <p>SuiteAmerica provides an array of suite packages and services to accommodate all guests' needs. See the 
                        below pdf's for additional information:  </p>
                        <h5 className="text-left mrg-btm-0 font700"><strong>Press Releases:</strong></h5>
                        <ul className="pressrelease_list color-red">
                            <li><a className="color-red" href="https://www.suiteamerica.com/images/mediakit/pdfs/Sample-Amenity-Sheet.pdf" target="_blank">SuiteAmerica Sample Amenity Sheet</a></li>
                            <li><a className="color-red" href="https://www.suiteamerica.com/pdfs/packages/All-Furnishing-Package-With-Pictures.pdf" target="_blank">SuiteAmerica Furnishings & Housewares Packages</a></li>
                            <li><a className="color-red" href="https://www.suiteamerica.com/pdfs/packages/Custom-Amenities.pdf" target="_blank">SuiteAmerica Furnishings & Housewares Extras</a></li>
                            <li><a className="color-red" href="https://www.suiteamerica.com/pdfs/packages/Destination-Services.pdf" target="_blank">SuiteAmerica Destination Services</a></li>
                            <li><a className="color-red" href="https://www.suiteamerica.com/pdfs/packages/Concierge-Serivces.pdf" target="_blank">SuiteAmerica Concierge Services</a></li>
                            <li><a className="color-red" href="https://www.suiteamerica.com/images/mediakit/pdfs/PTS-MeetGreet.pdf" target="_blank">SuiteAmerica Personal Transition Services/Meet & Greet</a></li>
                        </ul>
                        <h5 className="text-left mrg-btm-0 mrg-top-40 font700"><strong>Download the above SuiteAmerica Packages as a zip file:</strong></h5>  
                        <ul className="pressrelease_list">
                            <li><a className="color-red" href="https://www.suiteamerica.com/images/mediaKit/Zips/Products.zip" target="_blank">SuiteAmerica Services</a></li>
                        </ul>
                   </div>
                 <MediaFooter />
                 </div>
              </div>   
             <SubFooter />
             <Footer/>
            </div>
        );
    }
}
export default MediaOurServices;