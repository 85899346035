/* Importing the node modules, child components, services and controllers used 
   inside ReserveYourStay component */
   import $ from 'jquery';
   import {Link} from 'react-router-dom';
   import moment from 'moment'
   import usersService from '../../services/usersService';
   import ReserveNowApi from "../../services/reserveNowApi";
   import ReservationSuccesModal from "../controller/reservationSuccesModal";
   import CallModal from './callModal';
   import ReCAPTCHA from "react-google-recaptcha";
   /* ReserveYourStay Component initialization */
   class ReserveYourStay extends React.Component {
       /* Initializing objects of its ReserveYourStay class */
       constructor(props) {
           super(props);
           this.state={
               resLocation: '',
               resDate: "",
               resName: '',
               resCompany: '',
               resEmail: '',
               resPhone: '',
               resPlace:'',
               resBudget: '',
               phoneNo: '',
               message:'',
               color:'',
               emailmessage:'',
               emailcolor:'',
               loader:false,
               checkInDate: '',
               checkOutDate: '',
               CaptchaValue:'',
               pet: '',
               adult: '',
               children:'',
               contactMethod: '',
               petsList: [{ 'name': 'Pets', 'value': '' }, { 'name': '1', 'value': '1' }, { 'name': '2', 'value': '2' }, { 'name': '3', 'value': '3' }, { 'name': '4', 'value': '4' }],
               adultsList: [{ 'name': 'Adult', 'value': '' }, { 'name': '1', 'value': '1' }, { 'name': '2', 'value': '2' }, { 'name': '3', 'value': '3' }, { 'name': '4', 'value': '4' }, { 'name': '5', 'value': '5' }],
               childrensList: [{ 'name': 'Children', 'value': '' }, { 'name': '1', 'value': '1' }, { 'name': '2', 'value': '2' }, { 'name': '3', 'value': '3' }, { 'name': '4', 'value': '4' }, { 'name': '5', 'value': '5' }],
               contactMethods: [{ 'name': 'Contact Method', 'value': '', 'icon': '' }, { 'name': 'Phone', 'value': 'Phone', 'icon': "f2b6" }, { 'name': 'Email', 'value': 'Email', 'icon': '' }, { 'name': 'Text', 'value': 'Text', 'icon': '' }],
          
           }
           this.setLocation = this.setLocation.bind(this);
           this.setValue = this.setValue.bind(this);
           this.setNumber = this.setNumber.bind(this);
           this.setChecked = this.setChecked.bind(this);
           this.clearPhone = this.clearPhone.bind(this);
           this.reserveYourStay = this.reserveYourStay.bind(this);
           this.rangeval = this.rangeval.bind(this);
           this.openDatePicker = this.openDatePicker.bind(this);
           this.onChangeCaptcha=this.onChangeCaptcha.bind(this);
       }
       /* It is invoked immediately before a component is mounted */
       componentWillMount(){ 
                const _this = this
                $(document).ready(function () {
                    jQuery('input[name="daterangeBooking"]').daterangepicker({
                        locale: {
                        format: 'MM/DD/YYYY',
                        
                  }, autoUpdateInput: false, minDate:moment()},
                        function(start, end, label) {
                            _this.setState({resDate: start.format('MM/DD/YYYY') + ' - ' + end.format('MM/DD/YYYY'),
                            checkInDate:start.format('MM/DD/YYYY'),
                            checkOutDate:end.format('MM/DD/YYYY') })  
                    });
                });
                
       }
       componentDidMount() {
        $(document).ready(function () {
            jQuery(function($) {
                $("#landingPhone").intlTelInput();
            });
        });
    }  
       openDatePicker() {
            $(document).ready(function () {
                jQuery('input[name="daterangeBooking"]').data("daterangepicker").show();
            });
       }
       /* To update the location field auto complete value */
       setLocation(e) {
        this.setState({message:'',color:''})    

           const _this = this;
           _this.setState({resLocation: e.target.value});
              var places = new google.maps.places.Autocomplete(document.getElementById('city-input1'));
              google.maps.event.addListener(places, 'place_changed', function () {
                  _this.setState({resPlace: places.getPlace().formatted_address});  
              });     
        }
        /* To update the input field values */
        setValue(field,e){  
            this.setState({message:'',color:''})        
     
           if(field =='resEmail'){
            this.setState({emailmessage:'',emailcolor:''}) 
           }
            var object = {};
            object[field] = e.target.value;
            this.setState(object);
        }
        setChecked(e) {
           this.setState({resBudget: e.target.value});
        }
        /* To update the date field value */
         async dateSelection(date,e){
            this.setState({message:'',color:''})    
            await this.setState({resDate: moment(date)});
          }
 /* To clear the input field values after successfully mail sent */         
 successMessage(){
    $('html, body').animate({scrollTop: '-=250px'}, 600);
    //this.setState({message:'Mail successfully Sent ',color:'green',loader:false})
    setTimeout(function() { 
        this.setState({ 
        CaptchaValue:'',
        resLocation: '',
        resDate: '',
        resName: '',
        resCompany: '',
        resEmail: '',
        resPhone: '',
        resPlace:'',
        pet: '',
        adult: '',
        children: '',
        contactMethod: '',
        message:'',
        color:'',
        emailmessage:'',
        emailcolor:'',
        loader:false})
        document.getElementById("city-input1").value ='';
        $('input[name=exampleRadios]').prop('checked', false);
	this.recaptcha.reset()
        $(document).ready(function () {
            jQuery(function($) {
                 $("#landingPhone").intlTelInput("setCountry", "us");
            });
        });
        
    }.bind(this), 3000);
 
}
        /* To update the reserve stay details in database and sending email */
         async  reserveYourStay(){
            this.setState({emailmessage:'',emailcolor:''}) 
            var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
            const countryCode = $(".country.highlight.active").attr("data-dial-code") == undefined
                    ? "+1"
                    : "+" + $(".country.highlight.active").attr("data-dial-code")
             if(this.state.resCompany !='' && this.state.resName !='' && this.state.resPhone && this.state.resDate !='' && this.state.resLocation !=''   ){
              
                if(reg.test(this.state.resEmail)){
                    this.setState({emailmessage:'',emailcolor:'',loader:true}) 
                    var location=''
                    if(this.state.resPlace==''){
                        location=this.state.resLocation 
                    }
                    else{
                        location=this.state.resPlace
                    }
                const reserveYourStay={
                    Location: location,
                    MoveInDate: this.state.checkInDate,
                    MoveOutDate: this.state.checkOutDate,
                    Size:'1X',
                    GuestFirstName:this.state.resName,
                    ClientReference: this.state.resCompany,
                    GuestEmail: this.state.resEmail,
                    Pets: this.state.pet != '' ? this.state.pet : '---',
                    Adults: this.state.adult != '' ? this.state.adult : '---',
                    Child: this.state.children != '' ? this.state.children : '---',
                    contactMethod: this.state.contactMethod != '' ? this.state.contactMethod : '---',

                    SpecialNote: this.state.resBudget != '' ? this.state.resBudget : '---',
                    CreatedBy:this.state.resEmail!=''?this.state.resEmail:'---',
                    BookedBy: 'it@suiteamerica.com',
                    PhContryCode: countryCode,
                    GuestPhone:this.state.resPhone != '' ? this.state.resPhone : '---',
                    GuestPhoneEmailTemp: this.state.resPhone != '' ? countryCode + this.state.resPhone : '---',
                }
                const orderResponse = await ReserveNowApi.webLeadQuotes(reserveYourStay);
                if (orderResponse.error==0) {
                    reserveYourStay['OrderId']=orderResponse.message.orderId
                    const resReserveYourStay=await usersService.reserveYourStay(reserveYourStay);
                    if(resReserveYourStay.status){
                        jQuery(function ($) {
                            $('#reservationsuccessmodal').modal('show');
                        }.bind(this));
                        this.successMessage()
                       }else{
                           this.setState({message:'We Are unable to sent Mail ',color:'red'})
                       }
                }
                else {
                    this.setState({message: 'Order Is Not Created ', color: 'red'})
                }
                }
                else{
                    this.setState({emailmessage:'Please Enter Email formate',emailcolor:'Red'}) 
                }
             }
             else{
                this.setState({message:'Please Enter All The Fields ',color:'Red'})
             }


          }
        /* To clear input field values */
        clearPhone(){
            this.setState({phoneNo:'',message:'',color:''});
        }
        rangeval (val){
            console.log(val)
         }
         setNumber(e) {
            const re = /^[0-9\b]+$/;
            if (e.target.value == '' || re.test(e.target.value)) {
                this.setState({resPhone: e.target.value})
            }
        }
         async onChangeCaptcha(CaptchaValue) {
            await this.setState({CaptchaValue})
            }
       /* It is invoked to return html content */
       render() {
return (
               <div className="container-fluid bg-black-form" id="about_section">
                   <div className="container">
                       <div className="suite-request-info">
                           <div className="row">
                           <div className="col-md-12">
                            <h3 className="mrg-top-0">Reserve Your Stay</h3> 
                               <div className="row">
                               <div className="col-sm-8">
                                    <div className="row">
                                   <div className="col-sm-6">
                                       <div className="form-group">
                                       <input type="text" className="form-control" placeholder="Location Desired" id="city-input1" onChange={(e) => this.setLocation(e)} />
                                       <div className="info-field-icon"><Link to="#"><i className="fa fa-map-marker"></i>
                                           </Link>
                                       </div>
                                       </div>
                                       <div className="form-group">
                                       <input type="text" className="form-control" placeholder="Name" id="name" onChange={(e) => this.setValue('resName', e)} value={this.state.resName}/>
                                       </div>
                                       <div className="form-group" style={{color:this.state.emailcolor}}>
                                       {this.state.emailmessage}
                                       <input type="text" className="form-control" placeholder="Email" id="email"  onChange={(e) => this.setValue('resEmail', e)} value={this.state.resEmail}/>
                                       </div>
                                   </div>
                                   <div className="col-sm-6">
                                       <div className="form-group">
                                       <input type="text" className="form-control" placeholder='Dates Required' name="daterangeBooking" value={this.state.resDate} 
                                       onChange={(e) => this.rangeval(e)} />
                                       <div className="info-field-icon" onClick={this.openDatePicker}><Link to="#"><i className="fa fa-calendar"></i>
                                           </Link>
                                       </div>
                                       
                                       </div>
                                       <div className="form-group">
                                       <input type="text" className="form-control" placeholder="Company" id="company"  onChange={(e) => this.setValue('resCompany', e)} value={this.state.resCompany}/>
                                       </div>
                                       <div className="form-group">
                                       <input type="text" 
                                       className="form-control" 
                                       placeholder="Phone" 
                                       id="landingPhone"
                                       maxLength="10"
                                       autoComplete="off"  
                                       onChange={(e) => this.setNumber(e)} 
                                       value={this.state.resPhone}/>
                                       </div>
                                   </div>

                                        <div className="clearfix"></div>
                                   <div className="col-sm-12">
                                   <div className="form-group">
                                        <div className="reservestay_radio">
                                        <label htmlFor="inputPassword" className="col-sm-4 col-form-label">Price Per Night</label>
                                        <div className="col-sm-8">
                                            <div className="form-group">
                                                <input type="radio" className="radio_square" id="two" name="exampleRadios" value="$150-$250" 
                                                onChange={(e)=> this.setChecked(e)}/>
                                                <label htmlFor="two" className="radio_squarelbl">$150-$250</label>
                                            </div>
                                            <div className="form-group">
                                                <input type="radio" className="radio_square" id="three" name="exampleRadios" value="$250+" 
                                                onChange={(e)=> this.setChecked(e)}/>
                                                <label htmlFor="three" className="radio_squarelbl">$250+</label>
                                            </div>
                                        </div>
                                        <div className="clearfix"></div>
                                        </div>
                                    </div>
                                   </div>

                                   <div className="col-sm-12">
                                   <div className="form-group">
                                        <div className="flex flexgrow reserveflex">
                                            <div className="form-group">
                                            <select value={this.state.adult}  onChange={(e)=>
                                                {this.setValue('adult',e)}} className="form-control">
                                                {this.state.adultsList.map((row,index)=>{
                                                return(
                                                <option id="1" key={index} value={row.value}>{row.name}</option>
                                                )
                                                })}
                                            </select>
                                            </div>
                                            <div className="form-group">
                                            <select value={this.state.children}  onChange={(e)=>
                                                {this.setValue('children',e)}} className="form-control">
                                                {this.state.childrensList.map((row,index)=>{
                                                return(
                                                <option id="1" key={index} value={row.value}>{row.name}</option>
                                                )
                                                })}
                                            </select>
                                            </div>
                                            <div className="form-group">
                                            <select value={this.state.pet}  onChange={(e)=>
                                                {this.setValue('pet',e)}} className="form-control">
                                                {this.state.petsList.map((row,index)=>{
                                                return(
                                                <option id="1" key={index} value={row.value}>{row.name}</option>
                                                )
                                                })}
                                            </select>
                                            </div>
                                            <div className="form-group">
                                            <select value={this.state.contactMethod}  onChange={(e)=>
                                                {this.setValue('contactMethod',e)}} className="form-control">
                                                {this.state.contactMethods.map((row,index)=>{
                                                return(
                                                <option id="1" key={index} value={row.value}>{row.name}</option>
                                                )
                                                })}
                                            </select>
                                            </div>
                                        </div>
                                    </div>
                                   </div>
                                   </div>
                                  
                                   </div>
                                   <div className="col-sm-4 capchaWidth">
                                   <ReCAPTCHA
                    ref={ ref => this.recaptcha = ref }
                    sitekey="6Lcbw1AUAAAAAE5K5cYDyhoGu0RJY9C3CLtGFXAo"
                    onChange={this.onChangeCaptcha}
                    />
                    <br/>
                                       <div className="requiest-button" style={{color:this.state.color}}>
                                           {this.state.message}
                                      {this.state.loader ?   <div className="panel-body_loader" > <span style={{fontSize: '25px', width: '100%', margin: 'auto', textAlign: 'center'}}><i className="fa fa-spinner fa-spin"/> please wait ...!</span>
              </div>:<button className="btn button-border btn-block text-upper" onClick={this.reserveYourStay}>Reserve</button>} 
                                       </div>
                                       <p className="reuest-text">Our service team will call you with options within the hour.</p>
                                       <div className="request-contact text-upper">
                                       <a data-toggle="modal" data-target="#callModal" onClick={this.clearPhone}>CONTACT US BY PHONE.</a>
                                       </div>
                                   </div>
                                    

                               </div>
                           </div>
                           </div>
                       </div>
                   </div>
                   <ReservationSuccesModal/>
                   <CallModal />
               </div>
   
           );
       }
   }
   export default ReserveYourStay;