import MapController from "../../controller/mapController";
class SearchMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      latitude: 0.0,
      longitude: 0.0,
      types: [{
        name: 'Restaurants',
        type: 'restaurant',
        icon: '/assets/newUI-images/explore_icons/restarent.svg'
      }, {
        name: 'Bars',
        type: 'bar',
        icon: '/assets/newUI-images/explore_icons/bars.svg'
      }, {
        name: 'Groceries',
        type: 'supermarket',
        icon: '/assets/newUI-images/explore_icons/groceries.svg'
      }, {
        name: 'Parks',
        type: 'park',
        icon: '/assets/newUI-images/explore_icons/parks.svg'
      }, {
        name: 'Shopping',
        type: 'shopping_mall',
        icon: '/assets/newUI-images/explore_icons/shopping.svg'
      }, {
        name: 'Entertainment',
        type: 'night_club',
        icon: '/assets/newUI-images/explore_icons/entertainment.svg'
      }],
      type: ['restaurant'],
      typeName: '',
      location: [],
      placeSearched: [],
      placeSearchedMarkers: [],
      markerList: [],
      showlist: false,
      showSearch: false,
      typeicon: 0,
      directionsService: {},
      directionsDisplay: {},
      communityName: ''
    }
    this.mapClose = this.mapClose.bind(this);
    this.onetype = this.onetype.bind(this);
    this.searchPlace = this.searchPlace.bind(this);
    this.typeView = this.typeView.bind(this);
    this.flyToPlace = this.flyToPlace.bind(this);
  }
  async componentDidMount() {
    await this.setState({
      latitude: this.props.latitude,
      longitude: this.props.longitude,
      showlist: false,
      communityName: this.props.communityName
    })
    //this.loadPlacesWithMarkers(1, this.state.typeicon)
  }
    
   componentWillReceiveProps(nextProps) {
     this.setState({
      latitude: this.props.latitude,
      longitude: this.props.longitude,
      showlist: false,
      communityName: this.props.communityName
    })
    //this.loadPlacesWithMarkers(1, this.state.typeicon)
  }
  async loadPlacesWithMarkers(index) {
    const _this = this;
    const markers = []
    var pyrmont = {
      lat: this.state.latitude,
      lng: this.state.longitude
    };
    const map = this.map = new google.maps.Map(document.getElementById('map'+this.props.communityObject.community_id), {
      zoom: 14,
      center: pyrmont,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    });
    var service = new google.maps.places.PlacesService(map);
    const request = {
      location: pyrmont,
      radius: 2000,
      type: this.state.type
    };
    service.nearbySearch(request, async(data, status) => {
      var infowindow = new google.maps.InfoWindow();
      if ('OK' === google.maps.places.PlacesServiceStatus.OK) {
        const location = []
        var  results=_.orderBy(data, ['name'], ['asc']);
        for (var i = 0; i < results.length; i++) {
          location.push({
            latitude: results[i].geometry.location.lat(),
            longitude: results[i].geometry.location.lng(),
            name: results[i].name,
            address: results[i].vicinity
          })
          const marker = this.marker = new google.maps.Marker({
            map: map,
            position: results[i].geometry.location,
            center: results[i].geometry.location,
            icon: this.state.types[this.state.typeicon].icon,
          });
          google.maps.event.addListener(marker, 'click', (function (markertwo, j) {
            return function () {
              var contentString = '<h6><b>' + results[j].name + '</b></h6> <div id="content"><div class=""><div></div><p>' + results[j].vicinity + '</p> <a href="' + 'https://www.google.com/maps/place/' + results[j].vicinity + '" target="_blank">Get Directions</a></div>' + '</div>';
              infowindow.setContent(contentString);
              infowindow.open(map, this);
            }
          })(marker, i));
          markers.push(marker)
        }
        await _this.setState({
          location: location,
          markerList: markers
        })
        _this.getDistance()
      }
    })
    _this.setState({
      map
    });
    await MapController.customMarkers(this.props.communityObject, 'singlemark', this, '', 'https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/location_pin_2.svg', map);
    
  }
  flyToPlace(row, index, type) {
    var latLng = new google.maps.LatLng(row.latitude, row.longitude);
    this.map.panTo(latLng);
    const placemarker = type == 'locations' ? this.state.markerList : this.state.placeSearchedMarkers
    google.maps.event.trigger(placemarker[index], 'click');
  }
  setLocation() {
    const _this = this;
    const icon = 'https://s3-us-west-1.amazonaws.com/suiteam-website/Destination-Services/SuiteAmerica-Destination-Services-Icon-Map-Marker-Green.png';
    var places = new google.maps.places.Autocomplete(document.getElementById('pac-input'));
    google.maps.event.addListener(places, 'place_changed', function () {
      var place = places.getPlace();
      const newLat = place.geometry.location.lat();
      const newLng = place.geometry.location.lng();
      const markerSearch = this.markerSearch = new google.maps.Marker({
        position: new google.maps.LatLng(newLat, newLng),
        icon: icon,
        map: _this.map
      });
      var infowindow = new google.maps.InfoWindow();
      const data = _.concat(_this.state.placeSearched, {
        name: place.name,
        latitude: newLat,
        longitude: newLng,
        address: place.formatted_address
      })
      google.maps.event.addListener(markerSearch, 'click', (function (markerSearchto, i) {
        return function () {
          var image_name = place.photos !== undefined ? place.photos[0].getUrl({
            'maxWidth': 125,
            'maxHeight': 250
          }) : 'https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/SuiteAmerica-Destination-Services-Icon-Map-Marker-Red.png'
          var contentString = '<h6><b>' + place.name + '</b></h6> <div id="content"><div class="flexdiv"><div><img width="112px" height="112px" src="' + image_name + '" alt=""/></div><p>' + place.formatted_address + '</p> </div>' + '</div>';
          infowindow.setContent(contentString);
          infowindow.open(_this.map, this);
        }
      })(markerSearch));
      const markerData = _.concat(_this.state.placeSearchedMarkers, markerSearch)
      _this.setState({
        placeSearched: data,
        placeSearchedMarkers: markerData
      })
      var latLng = new google.maps.LatLng(newLat, newLng); //Makes a latlng
      _this.map.panTo(latLng);
    });
  }
  async getDistance() {
    for (var i = 0; i < this.state.location.length; i++) {
      if (this.state.location[i].latitude != '' && this.state.location[i].latitude != undefined && this.state.location[i].latitude != null && this.state.location[i].longitude != '' && this.state.location[i].longitude != undefined && this.state.location[i].longitude != null) {
        const distanceVal = this.distanceCal(this.state.latitude, this.state.longitude, this.state.location[i].latitude, this.state.location[i].longitude)
        let updatedPosts = update(this.state.location, {
          [i]: {
            distance: {
              $set: parseFloat(distanceVal)
            }
          }
        });
        await this.setState({
          location: updatedPosts
        });
      } else {
        let updatedPosts = update(this.state.location, {
          [i]: {
            distance: {
              $set: 0
            }
          }
        });
        await this.setState({
          location: updatedPosts
        });
      }
      if (this.state.location.length == (i + 1)) {
        await this.setState({
          location: _.sortBy(this.state.location, [

            function (o) {
              return o.distance;
            }
          ])
        });
      }
    }
  }
  distanceCal(lat1, lon1, lat2, lon2, unit) {
    var radlat1 = Math.PI * lat1 / 180
    var radlat2 = Math.PI * lat2 / 180
    var theta = lon1 - lon2
    var radtheta = Math.PI * theta / 180
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist)
    dist = dist * 180 / Math.PI
    dist = dist * 60 * 1.1515
    if (unit == "K") {
      dist = dist * 1.609344
    }
    if (unit == "N") {
      dist = dist * 0.8684
    }
    return dist.toFixed(2)
  }
  async onetype(row, type) {
    await this.setState({
      type: [row.type],
      showlist: true,
      typeName: row.name,
      typeicon: type
    })
    this.loadPlacesWithMarkers(1, this.state.typeicon)
  }
  typeView() {
    this.setState({
      showlist: false,
      showSearch: false
    })
  }
  searchPlace() {
    this.setState({
      showSearch: true,
      showlist: true
    })
  }
  mapClose() {
    this.setState({
      showlist: true,
      type: ['restaurant'],
      typeicon: 0,
      showSearch: false
    })
    this.props.viewPlaces()
  }
    render(){ 
      var  finalLocation=_.orderBy(this.state.location, ['name'], ['asc']);
      return (
      <div className="sch_mapview">
        <div className="row">{ this.state.showlist ? '':
          <div className="col-sm-12">
            <ul className="location_area_list">{this.state.types.map((row,index) => { return (
              <li key={index} onClick={() => this.onetype(row,index)}>{row.name}<i className="fa fa-angle-right"></i></li>) })}
             
            </ul>
          </div>} { this.state.showSearch ?
          <div className="col-sm-12">
            <button onClick={this.typeView} className="btn"><i className="fa fa-angle-left location_back"></i>
            </button>            
            <input id="pac-input" type="text" placeholder="Enter a location" onChange={this.setLocation}/>
            {(this.state.placeSearched.length >0)&&
            <ul className="location_area_list">{this.state.placeSearched.map((row,index) => { 
              return (
              <li key={index} onClick={()=>this.flyToPlace(row,index,'placeSearch')}>{row.name}</li>
              ) })}</ul>}</div>:''} 
              { (this.state.showlist) && (!this.state.showSearch) ?
          <div className="col-sm-12">
            <button onClick={this.typeView} className="btn"><i className="fa fa-angle-left location_back"></i>
            </button>
            <h2 className="dis-i-b location_search_head">{this.state.typeName}</h2>
            <ul className="location_area_list">{finalLocation.map((row,index) => { return (
              <li key={index} onClick={()=>this.flyToPlace(row,index,'locations')} > {row.name} <span>{row.address}</span>
                { <span className="location_distance">{row.distance} mi</span>}</li>) })}</ul>
                
          </div>:''}
          
          </div>
      </div>);
  } 
}
          
          export default SearchMap