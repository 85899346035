import DatePicker from 'react-datepicker'
const FlightInfoModal = props => {

    return (
<div className="modal fade savesearch tesimonial_form bd-example-modal-lg in bookingmodel flight-model" id="holdModal" role="dialog">
<div className="modal-dialog">
   <div className="modal-content rm-border-radius">
      <div className="modal-header">
         {props.modalLoader? '':
         <button type="button" className="close" data-dismiss="modal" aria-label="">
         <span>×</span>
         </button>
         }
         <h4 className="modal-title text-center font-bold">Guest Information</h4>
      </div>
      <div className="modal-body">
         <p className="text-center" style={{color:props.color}}>{props.message}</p>
         <form role="form" method="post" >
            <div>
               
               <div className="row">
               <div className="col-sm-12 communityName">
                  <span><strong>Community Name:</strong></span>
                  <span className="text-red">{props.community}</span>
               </div>
               <div className="col-sm-6">
                  <div className="form-group">
                     <DatePicker type="text" className="form-control " name="date" placeholder="Due Date" selected={props.checkIn}   disabled />
                  </div>
               </div>
               <div className="col-sm-6">
                  <div className="form-group">
                     <DatePicker type="text" className="form-control " name="date" placeholder="Due Date" selected={props.checkOut}  disabled/>
                  </div>
               </div>
               <div className="col-sm-6">
                  <div className="form-group">
                     <input type="text" className="form-control" placeholder="First Name *" value={props.firstName} disabled/>
                  </div>
               </div>
               <div className="col-sm-6">
                  <div className="form-group">
                     <input type="text" className="form-control" placeholder="Last Name *" value={props.lastName} disabled/>
                  </div>
               </div>
               <div className="col-sm-6">
                  <div className="form-group">
                     <input className="form-control"  id="phone2" placeholder='Phone' maxLength="10"  value={props.phoneNo} disabled/>
                  </div>
               </div>
               <div className="col-sm-6">
                  <div className="form-group">
                     <input type="text" className="form-control" placeholder="Email *" value={props.emailId} disabled/>
                  </div>
               </div>
               </div>
               <hr></hr>
               <div className="row">
               <div className="col-sm-12 communityName">
                  <span><strong>Arrival Info:</strong></span>
               </div>
               <div className="col-sm-6">
                  <div className="form-group">
                     <input type='text' className="form-control" placeholder="Flight"  value={props.Flight} onChange={(e)=>props.setValue('Flight',e)}/>
                  </div>
               </div>
               <div className="col-sm-6">
                  <div className="form-group">
                     <input type='text' className="form-control" placeholder="ArrivalAirport"  value={props.ArrivalAirport} onChange={(e)=>props.setValue('ArrivalAirport',e)}/>
                  </div>
               </div>
               <div className="col-sm-6">
                  <div className="form-group">
                    <DatePicker type="text" className="form-control" name="date" placeholder="ArrivalDate" selected={props.ArrivalDate} 
                    onChange={(e)=>props.checkInSelection(e)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="time"
                    dateFormat="MM/DD/YYYY HH:mm"
                    />
                  </div>
                </div>
               <div className="col-sm-6">
                  <div className="form-group">
                     <input type='text' className="form-control" placeholder="Airline"  value={props.Airline} onChange={(e)=>props.setValue('Airline',e)}/>
                  </div>
               </div>
               <div className="col-sm-12">
                  <div className="form-group">
                     <textarea className="form-control" placeholder="Arrival Notes" value={props.notes} onChange={(e)=>props.setValue('notes',e)}></textarea>
                  </div>
               </div>
               </div>
               <div className="clearfix"></div>
               {props.modalLoader? 
               <div className="panel-body_loader" > <span style={{fontSize: '25px', width: '100%', margin: 'auto', textAlign: 'center'}}><i className="fa fa-spinner fa-spin"/> please wait ...!</span>
               </div>
               :<button
                  className="btn location_btn"
                  type="button" onClick={() =>props.flightInfoSubmit()}>Submit</button>}
            </div>
         </form>
      </div>
   </div>
</div>
</div>

    );
  };

  export default FlightInfoModal;