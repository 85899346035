/* Importing the node modules, child components, services and controllers used
   inside GuestLogin component */
   import $ from "jquery";
   import AppController from "../controller/appController";
   import GuestHeader from './guestHeader';
   import Footer from './footer';
   import GuestServicesapi from '../../services/guestServicesapi';
   import EncryptDecrypt from '../../services/encryptDecrypt';
   /* GuestLogin Component initialization */
   class GuestLogin extends React.Component {
       /* Initializing objects of its GuestLogin class */
       constructor(props) {
           super(props)
           /* GuestLogin Component State variables Initialization */
           this.state = {
               guestEmail: '',
               password: '',
               message: '',
               color: '',
               userData: {},
               login: false,
               register: false,
               confirmPassword: '',
               guest:false
           }
           this.setValue = this.setValue.bind(this);
           this.register = this.register.bind(this);
           this.login = this.login.bind(this);
           this.next = this.next.bind(this);
       }
       /* It is invoked immediately before mounting occurs */
       componentDidMount() {
        $('html, body').animate({scrollTop: 0});
        AppController.stickyTitles()
        var urlEmail = this.props.location.search
         if(urlEmail.search("guestemailinfo=")){       
            var emailEncryption = urlEmail.replace('?guestemailinfo=','');
            var emailDecryption = AppController.decodeData(emailEncryption);
            this.setState({guestEmail:emailDecryption,guest:true})    
         }
       }
        /* Update the state object of the form */
        setValue(field, e) {
            var object = {};
            object[field] = e.target.value;
            this.setState(object);
        }
        /* Authentication checking */
        async next(e) {
            e.preventDefault();
            var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
            if (this.state.guestEmail != '' && reg.test(this.state.guestEmail)) {
                this.setState({message: '', color: '',loader:true})
                const login = {
                    email: this.state.guestEmail
                }
                const userData = await GuestServicesapi.getSingleGuestDetails(login)
                if (userData.OrderId !=null && userData.webCount == 0) {
                    this.setState({register: true,loader:false, userData: userData})
                } else if (userData.OrderId  !=null && userData.webCount != 0) {
                    this.setState({login: true,loader:false, userData: userData})
                } else{
                    this.setState({message: 'Your Email Is Not Registered with us', color: 'red',loader:false})
                }
            } else {
                this.setState({message: 'Enter Valid Email', color: 'red',loader:false})
            }
        }
        async register(e) {
            e.preventDefault();
            if (this.state.password == this.state.confirmPassword && this.state.password != '') {
                this.setState({message: '',loader:true})
                const register = {
                    guestEmailId: this.state.guestEmail,
                    guestPwd: this.state.password,
                    guestIsActive: 1,
                    guestFirstName: this.state.userData.GuestFirstName != null
                        ? this.state.userData.GuestFirstName
                        : '',
                        guestLastName: this.state.userData.GuestLastName != null
                        ? this.state.userData.GuestLastName
                        : '',
                        guestPhoneNumber: this.state.userData.GuestPhoneNo != null
                        ? this.state.userData.GuestPhoneNo
                        : '',
                        guestOrderId: this.state.userData.OrderId != null
                        ? this.state.userData.OrderId
                        : ''
                }
                const userData = await GuestServicesapi.saveGuestUserInfo(register)
                if (userData) {
                    this.setState({message: 'Registered Successfully', color: 'green',loader:false})
                    setTimeout(function () {
                        this.setState({
                            login: true,
                            register: false,
                            message: '',
                            password:''
                        })
                    }.bind(this), 2000);
                }
            } else {
                this.setState({message: 'Enter Valid Password', color: 'red'})
            }
        }
        async login(e){
            e.preventDefault();
            var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
            if((this.state.password != '') && (this.state.guestEmail != '' && reg.test(this.state.guestEmail))){
                this.setState({message: '', loader: true})
                const login = {
                    guestEmail: this.state.guestEmail,
                    guestPassword: this.state.password,
                    status:'guest'
                }
                const authCheckRes = await GuestServicesapi.guestUsersLogin(login)
                 console.log("authCheckRes-----:",authCheckRes);
                if (authCheckRes.error == "1") {
                    this.setState({message: 'Invalid Login Details', color: 'red', loader: false})
                } else {
                    const guestInfo=
                    {
                        userData:authCheckRes.message,
                        type:'guest',
                        
                    }
                    const guestEncryptResult = await EncryptDecrypt.encryptData(guestInfo)
                    localStorage.setItem('_u_web', guestEncryptResult.data)
                    const userData= await EncryptDecrypt.decryptData({'type': guestEncryptResult.data})
                    this.setState({loader: false})
                    jQuery(function ($) {
                        setTimeout(function () {
                            $('#myModal').modal('hide');
                        }.bind(this), 10);
                    }.bind(this));
                    switch (userData.recordset[0].roleId) {
                        case 1:
                            location.href = '/locations'
                            break;
                        case 2:
                        location.href = '/guestportal'
                            break;
                        case 3:
                            location.href = '/client-portal'
                            break;
                        case 4:
                            location.href = '/intranet-home'
                            break;
                    }
                }
            }
            else {
                this.setState({message: 'Enter Valid Email Password', color: 'red',loader:false})
            }
        }
       render() {
           return (
                <div>
                   <GuestHeader/>        
                   <div className="container" >
                       <div className="clearfix"></div>
                       <div className="client_rgr">
                               <div className="wow animated fadeInRight">
                                   <div className="col-md-4 col-md-offset-4">
                                   <h3 className="wow animated fadeInLeft"> Login/Register Now</h3>
                                   <p>For a SuiteAmerica Account as Guest</p>
                                   <p style={{color:this.state.color}}>{this.state.message}</p>
                                       <form>
                                           <div className="form-group">
                                               <input type="email" id="email" className="form-control" placeholder="Email" value={this.state.guestEmail} disabled={this.state.guest} 
                                               onChange={(e) => this.setValue('guestEmail', e)} />
                                           </div>
                                       
                                           {this.state.register?
                                           <div>
                                           <div className="form-group">
                                               <input type="password"  className="form-control" value={this.state.password} 
                                               onChange={(e) => this.setValue('password', e)} placeholder=" Password"/>
                                           </div>

                                           <div className="form-group">
                                               <input type="password"  className="form-control" value={this.state.confirmPassword} 
                                               onChange={(e) => this.setValue('confirmPassword', e)} placeholder="Confirm Password"/>
                                           </div>
                                           </div>:null}{this.state.login?<div className="form-group">
                                               <input type="password"  className="form-control" value={this.state.password} 
                                               onChange={(e) => this.setValue('password', e)} placeholder=" Password"/>
                                           </div>:null}

                                       <div className="text-center">
                                           {this.state.loader&&
                                           <div className='text-center' style={{fontSize: '25px', width: '100%', margin: 'auto', textAlign: 'center' ,color:'red'}}>
                                               <i className="fa fa-spinner fa-spin"/> Please wait ...!</div>}
                                                   {this.state.register&&
                                               <button type="submit" className="btn btn-red" onClick={(e) => this.register(e)}>Register</button>}
                                               
                                               {this.state.login?<button type="submit" className="btn btn-red" onClick={(e) => this.login(e)}>Login</button>:<button type="submit" className="btn btn-red" onClick={(e) => this.next(e)}>Next</button>}
                                       </div>
                                       </form>
                                   </div>
   
                               </div>
                               <div className="clearfix"></div>
                       </div>
                       
                   </div>
                   <Footer/>
   
               </div>
           );
       }
   }
   export default GuestLogin;