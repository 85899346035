import React from "react";
import { Link } from "react-router-dom";
import Footer from "./footer";
import Header from "./header";
import OwlCarousel from "react-owl-carousel";
import RequestCallback from "./requestCallback";

class CorporateHousing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.show3dView = this.show3dView.bind(this);
  }

  async componentDidMount() {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
  }
  handleClick() {
    $("#sideQuote").addClass("active");
    $(".overlay").addClass("active");
    $(".collapse.in").toggleClass("in");
    $("a[aria-expanded=true]").attr("aria-expanded", "false");
  }
  closed() {
    $("#sideQuote").removeClass("active");
    $(".overlay").removeClass("active");
  }
  show3dView() {
    jQuery(
      function ($) {
        $("#threevideoView").modal("show");
      }.bind(this)
    );
  }
  render() {
    return (
      <div className="SAweb_updateUi">
        <Header />
        <div className="overlay sideQuote"></div>
        <div className="container">
          <h1 className="my-5" data-aos="fade-up">
            We are the Gold Standard in <span> Corporate Housing </span>{" "}
          </h1>
          <div
            className="SAweb_callback bannerView border-2  border-top border-dark "
            data-aos="fade-up"
          >
            <div className="item pe-4 py-5">
              <p className="fs-2 mb-4">
                With our attention to detail, care for comfort, and
                best-in-class technology, we make sure your employees always
                have the best experience.
              </p>
              <div>
                <button
                  className="btn black-btn"
                  onClick={this.handleClick}
                  type="button"
                >
                  {" "}
                  Reserve Now{" "}
                </button>
              </div>
            </div>
            <div className="item py-5">
              <img
                src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/callbackImg.jpg"
                alt=""
                width="100%"
              />
            </div>
          </div>
        </div>
        <div className="bg-white  border-2  border-top border-dark">
          <div className="container">
            <div className="SAweb_scroolSlider py-5 my-5" data-aos="fade-up">
              <div className="swiper">
                <div className="swiper-wrapper">
                  <div className="swiper-slide slideItem1">
                    <h3 className="text-white">
                      You can count on us to take care of all the little things.{" "}
                      <span>
                        {" "}
                        <img
                          src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/slideThub-1.jpg"
                          alt=""
                        />{" "}
                      </span>{" "}
                    </h3>
                    <p className="text-white">
                      Whether it’s finding the best neighborhood for their kids,
                      or stocking up the refrigerator according to their
                      distinctive dietary needs, you can trust us to customize
                      experiences according to the distinctive needs of your
                      employees.
                    </p>
                  </div>
                  <div className="swiper-slide  slideItem2">
                    <h3 className="text-white">
                      {" "}
                      Ease and comfort for them. Peace of mind for you.{" "}
                      <span>
                        {" "}
                        <img
                          src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/slideThub-2.jpg"
                          alt=""
                        />{" "}
                      </span>{" "}
                    </h3>
                    <p className="text-white">
                      {" "}
                      With real-time availability, great photos of our suites,
                      instant booking confirmations, and a digital reservation
                      environment supported by our caring team members, we
                      provide an easy and convenient booking experience that
                      your employees will rave about.{" "}
                    </p>
                  </div>
                  <div className="swiper-slide  slideItem3">
                    <h3 className="text-dark">
                      Just the right amount of tech, and a whole lot of heart.{" "}
                      <span>
                        {" "}
                        <img
                          src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/slideThub-3.jpg"
                          alt=""
                        />{" "}
                      </span>{" "}
                    </h3>
                    <p className="text-dark">
                      {" "}
                      Our intuitive tech allows us to communicate with you and
                      your transferee without any hassles. This way, we are
                      always just a few taps away.{" "}
                    </p>
                  </div>
                </div>
                <div className="swiper-pagination"></div>
              </div>
              <div className=" d-flex justify-content-center align-items-center">
                <h2>What makes us the best</h2>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="SAweb_touchView py-5 my-5 " data-aos="fade-up">
            <div className="item pe-5 me-5">
              <h2 className=" mb-5">
                Your search for the best in corporate housing ends here.
              </h2>
              <div>
                <a href="/contactUs">
                  {" "}
                  <button className="btn black-btn" type="button">
                    {" "}
                    Get In Touch{" "}
                  </button>
                </a>
              </div>
            </div>
            <div className="item px-4">
              <div className="boxIcon">
                <img
                  src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/star.png"
                  alt=""
                />
                <p>A Record of Serving the Best in Business</p>
              </div>
              <div className="boxIcon">
                <img
                  src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/support.png"
                  alt=""
                />
                {/* <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/customer_support.svg" alt="" /> */}
                <p>Round-the-Clock Customer Support</p>
              </div>
              <div className="boxIcon">
                <img
                  src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/shake-hands.png"
                  alt=""
                />
                {/* <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/guest_relations.svg" alt="" /> */}
                <p>Dedicated Guest Relations Coordinators</p>
              </div>

              <div className="boxIcon">
                {/* <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/guest_experience.svg" alt="" /> */}
                <img
                  src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/heart.png"
                  alt=""
                />

                <p>Unparalleled Guest Experience</p>
              </div>
              <div className="boxIcon">
                {/* <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/customization.svg" alt="" /> */}

                <img
                  src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/sound-control.png"
                  alt=""
                />
                <p>Customized Packages For Ultimate Comfort</p>
              </div>
              <div className="boxIcon">
                <img
                  src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/badge.png"
                  alt=""
                />
                <p>Top of the Line Concierge Services</p>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white">
          <div className="container">
            <div className=" headingWithButton my-5 pt-4" data-aos="fade-up">
              <h2 className="heaidngHalf">
                Corporate housing made easy with our tech
              </h2>
              <a href="/technology">
                <button className="btn btn-border-black" type="button">
                  {" "}
                  Learn More{" "}
                </button>
              </a>
            </div>
            <div className="SAweb_chHousingMade SAweb_hometails SAweb_hometails py-5 my-5">
              <div className="box">
                <div className="item" data-aos="fade-up">
                  <h3>The Suite Marketplace</h3>

                  <ul>
                    <li> A platform to browse our stays</li>
                    <li> Access our nationwide inventory</li>
                    <li> Get connected to our vetted partners </li>
                  </ul>
                </div>
                <div className="item" data-aos="fade-up">
                  <h3>Book It</h3>
                  <ul>
                    <li> Book stays in under 30 seconds </li>
                    <li> Get instant email confirmations</li>
                    <li> Access live chat support</li>
                  </ul>
                </div>
                <div className="item" data-aos="fade-up">
                  <h3>Duty of Care Portal</h3>
                  <ul>
                    <li> Customize your employee’s stay </li>
                    <li> Keep track of their requests </li>
                    <li> Receive real-time updates & information</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <h2 className="pt-5 pb-4" data-aos="fade-up">
              Nationwide footprint, global standards
            </h2>

            <div className="SAweb_nationlFootprint">
              <ul data-aos="fade-up">
                <li>
                  {" "}
                  <a
                    href="/corporate-housing/furnished-apartments/ca/sanfrancisco"
                    target={"_blank"}
                  >
                    {" "}
                    <div className="pic">
                      {" "}
                      <img
                        src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/BayArea.jpg"
                        alt=""
                      />
                    </div>{" "}
                    <span>San Francisco Bay Area, CA</span>{" "}
                  </a>{" "}
                </li>
                <li>
                  {" "}
                  <a
                    href="/corporate-housing/furnished-apartments/ca/siliconvalley"
                    target={"_blank"}
                  >
                    {" "}
                    <div className="pic">
                      {" "}
                      <img
                        src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/Silicon+Valley1.jpeg"
                        alt=""
                      />
                    </div>{" "}
                    <span>Silicon Valley Area, CA</span>{" "}
                  </a>{" "}
                </li>
                <li>
                  {" "}
                  <a
                    href="/corporate-housing/furnished-apartments/ca/losangeles"
                    target={"_blank"}
                  >
                    {" "}
                    <div className="pic">
                      {" "}
                      <img
                        src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/LA.jpg"
                        alt=""
                      />
                    </div>{" "}
                    <span>Los Angeles, CA</span>{" "}
                  </a>{" "}
                </li>
                <li>
                  {" "}
                  <a
                    href="/corporate-housing/furnished-apartments/ca/sandiego"
                    target={"_blank"}
                  >
                    {" "}
                    <div className="pic">
                      {" "}
                      <img
                        src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/San+Diego.jpg"
                        alt=""
                      />
                    </div>{" "}
                    <span>San Diego, CA </span>{" "}
                  </a>{" "}
                </li>
                <li>
                  {" "}
                  <a
                    href="/corporate-housing/furnished-apartments/wa/seattle"
                    target={"_blank"}
                  >
                    {" "}
                    <div className="pic">
                      {" "}
                      <img
                        src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/Seattle1.jpg"
                        alt=""
                      />
                    </div>{" "}
                    <span>Seattle, WA </span>{" "}
                  </a>{" "}
                </li>
                <li>
                  {" "}
                  <a
                    href="/corporate-housing/furnished-apartments/oregon/portland"
                    target={"_blank"}
                  >
                    {" "}
                    <div className="pic">
                      {" "}
                      <img
                        src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/Portland2.jpg"
                        alt=""
                      />
                    </div>{" "}
                    <span>Portland, OR</span>{" "}
                  </a>{" "}
                </li>
                <li>
                  {" "}
                  <a
                    href="/corporate-housing/furnished-apartments/ma/boston"
                    target={"_blank"}
                  >
                    {" "}
                    <div className="pic">
                      {" "}
                      <img
                        src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/Boston.jpg"
                        alt=""
                      />
                    </div>{" "}
                    <span>Boston, MA</span>{" "}
                  </a>{" "}
                </li>
                <li>
                  {" "}
                  <a
                    href="/corporate-housing/furnished-apartments/texas/austin"
                    target={"_blank"}
                  >
                    {" "}
                    <div className="pic">
                      {" "}
                      <img
                        src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/Austin4.jpeg"
                        alt=""
                      />
                    </div>{" "}
                    <span>Austin, TX</span>{" "}
                  </a>{" "}
                </li>
                <li>
                  {" "}
                  <a
                    href="/corporate-housing/furnished-apartments/ny/newyork"
                    target={"_blank"}
                  >
                    {" "}
                    <div className="pic">
                      {" "}
                      <img
                        src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/New+York2.jpg"
                        alt=""
                      />
                    </div>{" "}
                    <span>New York City, NY</span>{" "}
                  </a>{" "}
                </li>
                <li>
                  {" "}
                  <a
                    href="/corporate-housing/furnished-apartments/nc/raleigh"
                    target={"_blank"}
                  >
                    {" "}
                    <div className="pic">
                      {" "}
                      <img
                        src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/Raleigh1.jpg"
                        alt=""
                      />
                    </div>{" "}
                    <span>Raleigh, NC</span>{" "}
                  </a>{" "}
                </li>
                <li>
                  {" "}
                  <a
                    href="/corporate-housing/furnished-apartments/va/arlington"
                    target={"_blank"}
                  >
                    {" "}
                    <div className="pic">
                      {" "}
                      <img
                        src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/Arlington-VA1.jpg"
                        alt=""
                      />
                    </div>{" "}
                    <span>Arlington, VA</span>{" "}
                  </a>{" "}
                </li>
                <li>
                  {" "}
                  <a
                    href="/corporate-housing/furnished-apartments/dc/washington"
                    target={"_blank"}
                  >
                    {" "}
                    <div className="pic">
                      {" "}
                      <img
                        src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/Washington+DC1.jpeg"
                        alt=""
                      />
                    </div>{" "}
                    <span>Washington DC</span>{" "}
                  </a>{" "}
                </li>
              </ul>

              <a href="/spotlightCities">
                {" "}
                <div className="my-5 pt-4 text-center">
                  {" "}
                  <button className="btn btn-border-black" type="button">
                    {" "}
                    View All{" "}
                  </button>
                </div>
              </a>
            </div>
          </div>
        </div>

        <div className="container" data-aos="fade-up">
          <div className="video3dViewText">
            <h2 className="text-center m-auto my-5 pt-5">
              Experience a SuiteAmerica property, from anywhere in the world.{" "}
            </h2>
            {/* <p className="text-center  m-auto pb-5">Before you make your booking, take a 3 D tour of our properties and see  what the hype is about, for yourself. </p> */}
          </div>

          <div className="SAweb_video mb-5">
            <img
              src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/videoImage.jpg"
              alt=""
              width="100%"
            />
            <a href="#" onClick={this.show3dView}>
              <i className="bi bi-play-circle"></i>
            </a>
          </div>
        </div>
        <div className="bg-white py-5">
          <div className="container SAweb_owl-slider2 py-5">
            <div className="row" data-aos="fade-up">
              <div className="col-sm-4 d-flex align-items-center pe-5">
                <h2>Here’s what our guests have to say about us</h2>
              </div>
              <div className="col-sm-8 ">
                <div className="owl-slider SAweb_sliderNav SAweb_sliderBottomArrows billingSlider">
                  <div id="carousel2">
                    <OwlCarousel
                      className="owl-theme owl-carousel"
                      {...{
                        loop: false,
                        margin: 20,
                        nav: true,
                        dots: false,
                        items: 1,
                        navContainerClass: "owl-buttons",
                        navText: [
                          "<i class='glyphicon glyphicon-menu-left'></i>",
                          "<i class='glyphicon glyphicon-menu-right'></i>",
                        ],
                        responsive: {
                          767: { items: 1, slideBy: 1 },
                          991: { items: 2, slideBy: 2 },
                          1199: { items: 2, slideBy: 2 },
                        },
                      }}
                    >
                      <div className="item">
                        <div className="caption">
                          <i className="bi bi-quote"></i>
                          <p>
                            Moving in and out was extremely smooth and hassle
                            free. The apartment was very good on the interior
                            and the community was also very quiet and peaceful.
                          </p>
                          <h3>Satya, Sunnyvale </h3>
                          <i className="bi bi-quote"></i>
                        </div>
                      </div>
                      <div className="item">
                        <div className="caption">
                          <i className="bi bi-quote"></i>
                          <p>
                            Absolutely great people, really nice place and lots
                            of thoughtful touches to make my family and I feel
                            welcome, thank you.{" "}
                          </p>
                          <h3>Brandon , Lake Oswego </h3>
                          <i className="bi bi-quote"></i>
                        </div>
                      </div>
                      <div className="item">
                        <div className="caption">
                          <i className="bi bi-quote"></i>
                          <p>
                            Awesome support from team for a new place move in
                            made it simple for us
                          </p>
                          <h3>Rama, Raleigh </h3>
                          <i className="bi bi-quote"></i>
                        </div>
                      </div>
                      <div className="item">
                        <div className="caption">
                          <i className="bi bi-quote"></i>
                          <p>
                            Very neat and clean apartment in very nice area.
                            Easy transition to new job and life in new area.{" "}
                          </p>
                          <h3>Patrick, Bethesda </h3>
                          <i className="bi bi-quote"></i>
                        </div>
                      </div>
                      <div className="item">
                        <div className="caption">
                          <i className="bi bi-quote"></i>
                          <p>
                            Astonishing levels of service and friendliness! We
                            are absolutely delighted in every dealing with
                            SuiteAmerica.{" "}
                          </p>
                          <h3>Jonathan, Mountain View </h3>
                          <i className="bi bi-quote"></i>
                        </div>
                      </div>
                    </OwlCarousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="border-top border-2 border-dark  bg-white">
          <div className="container">
            <div className="row SAweb_caseStudies " data-aos="fade-up">
              <div className="col-md-1">
                <div className="HeadingRotate ">
                  <h2 className="h2_rotate">Case Studies</h2>
                </div>
              </div>
              <div className="col-md-11">
                <div className="topHeading">
                  <p>
                    We’ve helped hundreds of managers and booking agents find
                    the right homes for their teams.
                  </p>
                  {/* <button type="buttton" className="btn ">View All <i className="bi bi-chevron-right"></i> </button> */}
                </div>
                <div className="boxes">
                  <div className="item hover-zoomin fullHeight">
                    <div className="image">
                      <Link to="/case-study-1" target="_blank">
                        <img
                          src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/caseStudies-1.jpg"
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="caption">
                      <h1>
                        Housing solution for a leading global tech company
                      </h1>
                      <p>
                        A global tech company in the Bay Area was looking for a
                        housing solution that could provide guaranteed inventory
                        for a group..{" "}
                        <Link to="/case-study-1" target="_blank">
                          <span>Read More </span>{" "}
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="item hover-zoomin">
                    <div className="image">
                      <Link to="/case-study-2" target="_blank">
                        <img
                          src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/caseStudies-2.jpg"
                          alt=""
                        />
                      </Link>
                    </div>
                    <h1>Accommodations for a large Government need (Navy)</h1>
                    <p>
                      In July of 2020, in the midst of the pandemic, a private
                      government contractor was in need of a large volume of
                      housing..{" "}
                      <Link to="/case-study-2" target="_blank">
                        {" "}
                        <span>Read More </span>
                      </Link>{" "}
                    </p>
                  </div>
                  <div className="item hover-zoomin">
                    <div className="image">
                      <Link to="/case-study-3" target="_blank">
                        <img
                          src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/caseStudies-3.jpg"
                          alt=""
                        />
                      </Link>
                    </div>
                    <h1>Bespoke solution for a year long Intern program</h1>
                    <p>
                      An existing client sought assistance with building a
                      mobility program to attract overseas executives to their
                      U.S. headquarters..{" "}
                      <Link to="/case-study-3" target="_blank">
                        <span>Read More </span>
                      </Link>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <RequestCallback />
        <div
          className="modal modal-fullscreen-xl modal fade animated tab-modal guest-Ui SAweb_modalPoup"
          id="threevideoView"
          tabIndex={-1}
          data-backdrop="static"
          data-keyboard="false"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content newSRView">
              <div className="servicerequest-pop-header modal-header border-0">
                <button
                  type="button"
                  className="btn close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="bi bi-x"></i>
                </button>
              </div>
              <div className="modal-body">
                <iframe
                  src={"https://my.matterport.com/show/?m=iLeosSyv3Bn"}
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  allowFullScreen
                />
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}
export default CorporateHousing;
