/* Importing the node modules, child components, services and controllers used 
   inside NikeFrequentQuestions component */    
   import $ from "jquery";
   import MainController from "../../controller/mainController";
   import StripeInternshipHeader from "./common/stripeInternHeader";
   import SubFooter from "../../common/subFooter";
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   import StripeInternFooter from "./common/stripeInternFooter";
   /* NikeFrequentQuestions Component initialization */
   class StripeInternshipFaq extends React.Component {
       /* Initializing objects of its NikeFrequentQuestions class */
       constructor(props) {
        super(props);
        this.state = {
            fqs: [{
                'Question': 'What is the intern housing program with Gilead/Kite?',
                'Answer': 'The housing program is provided for interns, subject to availability, and offers a temporary home away from home environment while you are working for Gilead/Kite.  Once you submit your housing registration and are matched to a roommate, you will receive confirmation from Suite America.  Interns will share a full-size, fully furnished shared apartment (a “suite”) outfitted with all the essentials needed for living comfortably.  Each suite has a living and dining area, an in-suite washer/dryer for laundry needs, at least one, but probably two, regular size bathrooms with showers, and a beautiful kitchen for making a meal or enjoying a food service delivery meal, with a full-size refrigerator, stove, and oven.  Gilead/Kite pays for the housing directly with SuiteAmerica.  Housing is limited and will be assigned on a first come, first served basis.',
            },
            {
                'Question': 'What is included in the intern apartment?',
                'Answer': 'The suite is outfitted with the essentials needed when traveling away from home.  Interns should bring the same personal items and toiletries you  would bring when staying in a hotel, plus any must-have items that will help you feel at home.  See the “apartment tour” page for more information.',
            },
            {
                'Question': 'When will housing details be provided?',
                'Answer': 'Gilead/Kite interns will receive their housing confirmation about 10-14 days before their arrival date.  The details will include step-by-step instructions on how to access the apartment parking area, the residential building, and enter the suite.',
            },
            {
                'Question': 'Is parking included with the intern apartment?',
                'Answer': 'Yes.  One parking space is included with the apartment so if both roommates are bringing cars, please let SuiteAmerica know right away. Any additional parking spaces are based on availability and any additional cost will be reviewed with Gilead / Kite.',
            },
            {
                'Question': 'Are pets allowed?',
                'Answer': 'Unless the intern’s pet is a certified service animal, pets are not allowed. All exceptions and service animal verification will need to be approved in advance by your Altair Global consultant.',
            },
            {
                'Question': 'Do interns need to pay for anything while staying in the apartment?',
                'Answer': 'Gilead/Kite and SuiteAmerica have provided all the basics needed for the apartment, but if you want to order specialty items during your stay, such as upgraded TV channels or additional furniture or housewares, you will be financially responsible for those costs.  Starter items like a roll of toilet paper, a roll of paper towels, a small bottle of dish soap, and a small box of detergent is provided on arrival, but you and your roommate will need to purchase those items as you  run out throughout your  stay.',
            },
            {
                'Question': 'Why do interns need to provide a credit card?',
                'Answer': 'While Gilead/Kite covers the cost of the suite, interns are responsible for any damages and incidentals. A credit card is required prior to move-in but won’t be charged unless an intern requests additional items to be delivered to their suite, or if there are missing or damaged items at departure.',
            },
            {
                'Question': 'Who do interns contact if there is an emergency?',
                'Answer': 'The Intern Service Team is here to help! \n• Dial 911 for any life-threatening emergencies, fires, or gas leaks.  •	For apartment emergencies, such as water coming through the ceiling, please call the Intern Service Team at 800-367-9501.•	After normal business hours interns may dial this same number and leave a detailed message and a Guest Service representative will call you back within a few minutes to assist.',
            },
            {
                'Question': 'If interns need to report a maintenance issue, make a maintenance request and/or report a damaged item, who should they reach out to?',
                'Answer': 'Please call the Intern Service Team at 800-367-9501 24 hours a day.  For a standard maintenance issue, please log into the Intern Guest Portal to report a maintenance request and/or damaged items.',
            },
            {
                'Question': 'What happens if I lose the key and/or fob to my intern apartment?',
                'Answer': 'Please contact the Suite America team for assistance at 1-800-367-9501 24/7.  A Guest Experience team member will be able to assist.  Please Note: A replacement fee will be charged to the COMPANY Intern',
            },
            {
                'Question': 'What happens if an intern locks themselves out of their apartment?',
                'Answer': 'Please contact the Suite America team for assistance at 1-800-367-9501 24/7.  A Guest Experience team member will be able to assist.  Please Note: A lock out fee may apply and will be billed to the intern.',
            },
            {
                'Question': 'What happens if an intern breaks something in the apartment?',
                'Answer': 'n the event an intern causes damage or breaks an item, SuiteAmerica will determine the replacement cost.  SuiteAmerica will work with both the intern and their Sirva Envoy to collect any payment due.',
            },
            {
                'Question': 'The apartment has amenities like a pool and fitness center:  are interns allowed to use them?',
                'Answer': 'Yes, interns are welcome to enjoy all the amenities that the community has to offer.',
            },
            {
                'Question': 'Can interns receive mail and packages while living in the apartment?',
                'Answer': 'Yes, each apartment will have an assigned mailbox at the community for receiving mail and small packages.  Most apartment communities have a package valet/locker system, and specific instructions will be provided at check-in, so every intern understands how to retrieve packages and deliveries.  Because the apartments are in SuiteAmerica’s name, package notifications are usually sent to our Guest Experience Team, who will then forward to the intern.',
            },
            {
                'Question': 'What are quiet hours in an apartment community?',
                'Answer': 'Quiet hours are between 10pm – 6am. Quiet hours exist to allow each resident in the building to enjoy peace and quiet in their apartment without having to worry about noise from neighbors.  Gilead will be notified if any intern causes excessive noise or violates apartment community rules.',
            },
            {
                'Question': 'Are overnight guests permitted?',
                'Answer': 'No, overnight guests are permitted in your apartment. For safety reasons, do not give anyone or let anyone borrow keys/fobs/remotes.',
            },
            {
                'Question': 'Is smoking allowed in the apartment?',
                'Answer': 'Smoking is strictly prohibited in all apartment communities (both inside the apartment and in all common areas of the community). Interns should inquire with their specific apartment community to locate any designated smoking areas outside of the property itself.',
            },
            {
                'Question': 'What are the check-out procedures?',
                'Answer': 'SuiteAmerica will contact all interns with specific departure instructions approximately 7 days before the end of their internship.  In general, you should leave all keys / remotes / parking permits on the kitchen counter and remove all personal items and discard all trash. If you have any unopened, non-perishable items and would like to donate to a food-collection charity called “Move for Hunger”, you  can use the paper bag provided in each apartment and the housing team will collect and donate.',
            },
            {
                'Question': 'Should interns clean out the refrigerator when they check-out or will someone do that for them?',
                'Answer': 'Interns should remove all perishable food items and dispose all trash in the bins located at the apartment community.  Interns can donate non-perishable food items in the “Move for Hunger” bag that was left on the kitchen countertop when the first arrived.  Interns can just fill and leave the bag on the counter, and SuiteAmerica will collect and donate it on their behalf.',
            }]
        }
    }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
           MainController.getInitialInformation();
        }
       /* It is invoked to return html content */
       render() {  
        let {fqs} = this.state; 
        return (
            <div className="corporateHousing_Updates onlyGileadHeader">
                {/* <GuestHeader/> */}
                {/* <div className="cover-tophd-ht"></div> */}
                <StripeInternshipHeader />
                <div className="container-fluid nike guest_sitenew mrg-top-50">
                  <div className="container intra_staff2 col-sm-8 col-sm-offset-2">
                     <h2 className="text-center company-title">FREQUENT ASKED QUESTIONS</h2>
                     <div id="accordion" className="panel-group mrg-btm-90 company_acdn" role="tablist" aria-multiselectable="true">
                        {fqs.map((row, index) => {
                        return (
                        <div className="panel panel-default" key={index}>
                           <div className="panel-heading">
                              <h4 className="panel-title">
                                 <a className="collapsed"
                                    data-toggle="collapse"
                                    data-parent="#accordion"
                                    href={'#collapse' + index}
                                    aria-expanded="false"
                                    aria-controls={row.id}
                                    >{row.Question}</a>
                              </h4>
                           </div>
                           <div id={'collapse' + index}
                              className="panel-collapse collapse">
                              <div className="panel-body">
                                 <div className="profile-opener">
                                 {row.Question == 'Who do interns contact if there is an emergency?' ? <span>The Intern Service Team is here to help! <br/><br/><ul><li style={{listStyle:"disc",marginBottom:"10px"}}>Dial 911 for any life-threatening emergencies, fires, or gas leaks.</li><li style={{listStyle:"disc",marginBottom:"10px"}}>For apartment emergencies, such as water coming through the ceiling, please call the Intern Service Team at 800-367-9501.</li><li style={{listStyle:"disc"}}>After normal business hours interns may dial this same number and leave a detailed message and a Guest Service representative will call you back within a few minutes to assist.</li></ul></span> : row.Answer}
                                 </div>
                              </div>
                           </div>
                        </div>
                        )
                        })}
                     </div>
                  </div>
               </div>
               <div className="container ft-contact-adrs mrg-btm-30 mrg-top-30">
                   <StripeInternFooter />
                </div>      
            <SubFooter/>
            <Footer/>
        </div>
   
           );
       }
   }
   export default StripeInternshipFaq;