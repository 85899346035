/* Importing the node modules, child components, services and controllers used 
   inside ContactUsForm component */
   import AppController from "../../controller/appController";
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import NikeServices from '../../../services/nikeService';
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   import SuccessModal from '../../common/successModal';
   import MetaTags from '../../common/metaTags';
   import ServerMetaTags from '../../../server/metaTags';
   import ReCAPTCHA from "react-google-recaptcha";
   import ContactInfo from './contactInfo';
   /* ContactUsForm Component initialization */
   class ContactUsForm extends React.Component {
       /* Initializing objects of its ContactUsForm class */
       constructor(props) {
           super(props)
           this.state={
               firstName: '',
               lastName: '',
               email: '',
               subject: '',
               message: '',
               color:'',
               errorMessage:'',
               CaptchaValue:'',
           }
           this.setValue = this.setValue.bind(this);
           this.serviceRequest = this.serviceRequest.bind(this);
           this.onChangeCaptcha = this.onChangeCaptcha.bind(this);
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
           MainController.getInitialInformation();
        }
        /* Form Details object creations  */
        setValue(field, e) {
            var object = {};
            object[field] = e.target.value;
            this.setState(object);
        }
       /* Service Request Details send to mail  */
       async serviceRequest(e){ 
            console.log('ppp')
        e.preventDefault();
        const _this = this
           const validationFields=['firstName','lastName','email','CaptchaValue']
           let error = 0;
           _.forEach(validationFields, function(value) {
              if(_this.state[value] == ""){
                error++;
              }
          });
         if(error == 0){
            const Request={
                firstName:this.state.firstName,
                lastName:this.state.lastName,
                email:this.state.email,
                subject: this.state.subject == '' ? 'NA' : this.state.subject,
                message: this.state.message == '' ? 'NA' : this.state.message,
                mail:'contactUs'
            }
    
        this.setState({
            firstName: '',
            lastName: '',
            email: '',
            subject: '',
            message: '',
            color:'',
            errorMessage:'',
 
        })
        
        jQuery(function($) {
            $('#successModal').modal('show');
            setTimeout(function(){
              $('#successModal').modal('hide');
            },4000);
          });
        AppController.menuTopNavigation('serviceRequest');
        await NikeServices.serviceRequest(Request) 
    }
    else{
        AppController.menuTopNavigation('serviceRequest');
        this.setState({errorMessage:'Please Enter Required Fields',color:'red'})
    }
        
       }
      
       async onChangeCaptcha(CaptchaValue) {
        await this.setState({CaptchaValue})
        }
       /* It is invoked to return html content */
       render() {
           const metaData = ServerMetaTags.urlString.getMetaData(this.props.match.url);
           return (
            <div className="corporateHousing_Updates bg-grey">
                <MetaTags 
                metaTitle = {metaData.title}
                metaDesription = {metaData.description}
                metaUrl = {metaData.canonical} />
                <GuestHeader/>
                <div className="cover-tophd-ht"></div>
                <div className="container-fluid nike" id="serviceRequest">
                    <div className="container text-center">
                        <h2 className="">Get In Touch</h2>
                        <h2 className="">We're here for you 24/7</h2>
                    </div>
                </div>
                <div className="container-fluid bg-grey">
                <div className="container cpacha_contact_us_cont">
                    <div className="savesearch form-section col-sm-8 col-sm-offset-2 text-center">
                        <h2><img src={"/assets/newUI-images/nike/phone.svg"} width="25px"/>
                                {/* <span className="desktop-phone">800.367.9501</span> */}
                                <a href="tel:8003679501" className="contact-us-mobile mobile-phone">800.367.9501</a>
                        </h2>
                        <p className="text-left" style={{color:this.state.color}}>{this.state.errorMessage}</p>
                        <form role="form" method="post" 
                        onSubmit={(e) => this.serviceRequest(e)} className="form-horizontal">
                            <div className="form-group row">
                            <input type="text" className="form-control col-md-5" placeholder="*First Name" value={this.state.firstName} 
                            onChange={(e) => this.setValue('firstName', e)}/>
                            </div>
                            <div className="form-group row">
                            <input type="text" className="form-control col-md-5" placeholder="*Last Name" value={this.state.lastName} 
                            onChange={(e) => this.setValue('lastName', e)}/>
                            </div>
                            <div className="form-group row">
                            <input type="text" className="form-control col-md-5" placeholder="*Email" value={this.state.email} 
                            onChange={(e) => this.setValue('email', e)} />
                            </div>
                            <div className="form-group row">
                            <input type="text" className="form-control col-md-5" placeholder="Subject" value={this.state.subject} 
                            onChange={(e) => this.setValue('subject', e)}/>
                            </div>
                            <div className="form-group row">
                            <textarea className="form-control col-md-5" placeholder="Message" value={this.state.message} 
                            onChange={(e) => this.setValue('message', e)}/>
                            </div>
                            <div className="form-group d-flex justify-content-center cpacha_contact_us">
                                            <ReCAPTCHA
                                            ref="recaptcha"
                                            sitekey="6Lcbw1AUAAAAAE5K5cYDyhoGu0RJY9C3CLtGFXAo"
                                            onChange={this.onChangeCaptcha}
                                            />
                                </div>
                            <div className="form-group row">
                            <button className="btn company_btn" type="submit" >SEND</button>
                            </div>
                        </form>
                    </div>
                    </div>
                </div>
                <ContactInfo />
                <SubFooter/>
                <Footer/>
                <SuccessModal/>
            </div>
           );
       }
   }
   export default ContactUsForm;