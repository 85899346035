import AmenitesList from "./amenitesList";
import WalkScore from "./walkscore";
import {Link} from 'react-router-dom';
import moment from 'moment'
const Communitys=(props)=>{
 
    const {communitysList} = props;   
    const booked = _.filter(communitysList, function(o) { return o.LinkMaster.IsGuestBooked==true});
    function AmenitiesRes(community,index){
        const appartmentAmenities =  community.AmenitiesList !=null  && community.AmenitiesList!=undefined?JSON.parse(community.AmenitiesList):community.AmenitiesList		
        const apparmentamenites = appartmentAmenities !=null  && appartmentAmenities!=undefined ?_.filter(appartmentAmenities, function (o) {
            return o.communityname == 'Unit';
          }):[];
          const communityamenites = appartmentAmenities !=null  && appartmentAmenities!=undefined ?_.filter(appartmentAmenities, function (o) {
            return o.communityname == 'Community';
          }):[];
         return <AmenitesList indexval={index} appartmentAmenities={apparmentamenites} communityAmenities={communityamenites} LinkMaster={community.LinkMaster}/>
    }
  
    return(
      
      
        communitysList.map((community,index)=>{
            return(
                <div className="jessalyne_proitem col-md-6" key={index}>
                <h1>{community.community_name}</h1>
                <p>
                  <i className="text-red">
                  {community.address+','+community.city_name+','+community.state_name+','+community.ZipCode}
                  </i>
                </p>
                <div className="img_div">
                  <img src={community.banner_image} alt="" />
                </div>
                <p>Available from: <span>{(community.LinkMaster.MoveInReady != null) ?moment(community.LinkMaster.MoveInReady).format('MMMM DD,YYYY'):community.LinkMaster.MoveInDate}</span> {community.LinkMaster.DontShowPrice == null || community.LinkMaster.DontShowPrice == false ?<b>Offered Rate: <span> ${community.LinkMaster.OfferedRate}/day </span></b>:null} </p>
                <div className="jessalyne_controll">
                  {community.LinkMaster.IsGuestBooked && booked.length>0?<p style={{color:'black'}}> You have already booked this suite</p>:<button className="btn btn-primary" onClick={booked.length>0?null:()=>props.bookNow(community)} disabled={booked.length>0?true:false}>Book Now</button>}
                  
                  <Link to={'/community/suiteamerica/'+ community.community_name.replace(/ /g, "-")}   target="_blank"><button className="btn btn-primary" >Know More</button>
                  </Link>
               
                  {community.web3d !=''? <button className="btn btn-primary" onClick={()=>props.webView(community)} >360 View</button>:''}
                </div>
                <div className="newpara-ptag">
                  {(community.LinkMaster.Notes != '' && community.LinkMaster.Notes != null)?
                <div className={"alert alert-info"}>
                <i className="fa fa-info-circle"></i>  {community.LinkMaster.Notes}
              </div>
               :null}

                </div>
                {AmenitiesRes(community,index)}
               {/* {props.walkscoreLoading? <WalkScore walkScoreDetails={props.walkScoreDetails[index]} />:''} */}
              </div>
            )
        })

        
       
    )
    }
    export default Communitys;
    
    
    