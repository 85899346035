const StateCityInfo = (props) => {
           return ( 
                <div className="container">
                    <div className="mrg-top-25">
                    <div className="spotlight-cities">
                        <div>
                            <div className="cities-wrap">
                                <ul>
                                <span>CA</span>
                                {props.cities.ca.map((row,index)=>{
                                return(
                                <li key={index} style={{cursor: 'pointer'}} onClick={() => props.onClickCity(row)}>
                                {row.city}
                                </li>
                                )
                                })} 
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className="cities-wrap">
                                <ul>
                                <span>D.C.</span>
                                {props.cities.dc.map((row,index)=>{
                                return(
                                <li key={index} style={{cursor: 'pointer'}} 
                                onClick={() => props.onClickCity(row)}>
                                {row.city}
                                </li>
                                )
                                })} 
                                </ul>
                            </div>
                            <div className="cities-wrap">
                                <ul>
                                <span>MD</span>
                                {props.cities.md.map((row,index)=>{
                                return(
                                <li key={index} style={{cursor: 'pointer'}} onClick={() => props.onClickCity(row)}>
                                {row.city}
                                </li>
                                )
                                })} 
                                </ul>
                            </div>
                            <div className="cities-wrap">
                                <ul>
                                <span>TX</span>
                                {props.cities.tx.map((row,index)=>{
                                return(
                                <li key={index} style={{cursor: 'pointer'}} onClick={() => props.onClickCity(row)}>
                                {row.city}
                                </li>
                                )
                                })} 
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className="cities-wrap">
                                <ul>
                                <span>GA</span>
                                {props.cities.ga.map((row,index)=>{
                                return(
                                <li key={index} style={{cursor: 'pointer'}} onClick={() => props.onClickCity(row)}>
                                {row.city}
                                </li>
                                )
                                })} 
                                </ul>
                            </div>
                            <div className="cities-wrap">
                                <ul>
                                <span>NC</span>
                                {props.cities.nc.map((row,index)=>{
                                return(
                                <li key={index} style={{cursor: 'pointer'}} onClick={() => props.onClickCity(row)}>
                                {row.city}
                                </li>
                                )
                                })} 
                                </ul>
                            </div>
                            <div className="cities-wrap">
                                <ul>
                                <span>VA</span>
                                {props.cities.va.map((row,index)=>{
                                return(
                                <li key={index} style={{cursor: 'pointer'}} onClick={() => props.onClickCity(row)}>
                                {row.city}
                                </li>
                                )
                                })} 
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className="cities-wrap">
                                <ul>
                                <span>IL</span>
                                {props.cities.il.map((row,index)=>{
                                return(
                                <li key={index} style={{cursor: 'pointer'}} onClick={() => props.onClickCity(row)}>
                                {row.city}
                                </li>
                                )
                                })} 
                                </ul>
                            </div>
                            <div className="cities-wrap">
                                <ul>
                                <span>NY</span>
                                {props.cities.ny.map((row,index)=>{
                                return(
                                <li key={index} style={{cursor: 'pointer'}} onClick={() => props.onClickCity(row)}>
                                {row.city}
                                </li>
                                )
                                })} 
                                </ul>
                            </div>
                            <div className="cities-wrap">
                                <ul>
                                <span>WA</span>
                                {props.cities.wa.map((row,index)=>{
                                return(
                                <li key={index} style={{cursor: 'pointer'}} onClick={() => props.onClickCity(row)}>
                                {row.city}
                                </li>
                                )
                                })} 
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className="cities-wrap">
                                <ul>
                                <span>MA</span>
                                {props.cities.ma.map((row,index)=>{
                                return(
                                <li key={index} style={{cursor: 'pointer'}} onClick={() => props.onClickCity(row)}>
                                {row.city}
                                </li>
                                )
                                })} 
                                </ul>
                            </div>
                            <div className="cities-wrap">
                                <ul>
                                <span>OR</span>
                                {props.cities.or.map((row,index)=>{
                                return(
                                <li key={index} style={{cursor: 'pointer'}} onClick={() => props.onClickCity(row)}>
                                {row.city}
                                </li>
                                )
                                })} 
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         
           );

   }
   export default StateCityInfo;
   