import React, { Component } from 'react'
import SiteLoader from '../../common/siteLoader'
import moment from "moment";
import SessionService from '../../../services/sessionService';
import GuestServicesapi from '../../../services/guestServicesapi';
import MainController from "../../controller/mainController";
import GuestController from '../../controller/guestController';
import authDetails from "../../common/authDetails";
import GuestHeaderNav from './guestHeader'

export class orderDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      orderInfo: this.props.location.state,
      siteLoader: true,
      isOpenorClose: true,
      navigationList: [
        // {name: 'HOME',url: '/guestportal'},
        // {name: 'ARRIVAL INFO',url: '/trip-info-data'},
        { name: 'MY BOOKINGS', url: '/reservations' },
        { name: 'SERVICE REQUEST', url: '/guest-services' },
        { name: 'INSTRUCTIONS', url: '/instructions' },
        { name: 'GUEST', url: '/order-details' },
        
        /* {name: 'RESERVATIONS',url: '/reservations'}, */
    ],
    userData: {},
    guestSessionResponse: {},
    hideMenu: false,
    GuestInfoCommunityDetails:{}
    }
    this.openOrCloseNav = this.openOrCloseNav.bind(this);
    this.expandImage=this.expandImage.bind(this);
  }

  async guest(){
    MainController.removeClassOnNavCollapse();
    const guestLoginResponse = await GuestController.guestLoginResponse();
    await this.setState({ userData: guestLoginResponse });
    const GetResponse = await SessionService.SessionGet({ type: 'guest' });
     if(GetResponse.error==0){
    await this.setState({roleId: (GetResponse.roleId!=undefined && Object.keys(GetResponse).length>0)? GetResponse.roleId : 0,
        ClientType:(GetResponse.ClientType!=undefined && Object.keys(GetResponse).length>0? GetResponse.ClientType : '')})
   }

    await this.setState({ guestSessionResponse: GetResponse });
    if (GetResponse.roleId == 2) {
        var guestEmailObj = {
            guestEmail: GetResponse.GuestEmail,
            status: "active"
        }
        const GetOrdersByGuestEmailResponse = await GuestServicesapi.GetOrdersByGuestEmail(guestEmailObj)
        const GetGuestInfoCommunityByEmailId=await GuestServicesapi.GetGuestInfoCommunityByEmailId({orderid:GetOrdersByGuestEmailResponse.message.Orders[0].OrderId})
        this.setState({GuestInfoCommunityDetails:GetGuestInfoCommunityByEmailId})
        if (GetOrdersByGuestEmailResponse.message.Orders.length == 0) {
            this.setState({ hideMenu: true })
        } else {
            var currentDate = moment().format("YYYY-MM-DD");
            var navList = this.state.navigationList
            var orderUIdObj = {
                OrderUId: GetOrdersByGuestEmailResponse.message.Orders[0].OrderUId
            }
            GuestServicesapi.GetOrderInfoById(orderUIdObj).then((getOrderInfo) => {
                  var MoveInDate=moment(getOrderInfo.message.orderInfo.MoveInDate).format("YYYY-MM-DD");
                  var MoveOutDate=moment(getOrderInfo.message.orderInfo.MoveOutDate).format("YYYY-MM-DD");
                if (MoveInDate == currentDate) {
                    GuestServicesapi.GetInOutOrdersLog({ orderUid: orderUIdObj.OrderUId }).then((GetInOutOrdersResponse) => {
                        if (GetInOutOrdersResponse.length > 0) {
                            var checkIn = GetInOutOrdersResponse.filter(e => e.Type == "IN")
                            if (checkIn.length == 0) {
                                navList.push({ name: 'CHECK IN', url: '/check-in' })
                                this.setState({ navigationList: navList })
                            } 
                        } else {
                            navList.push({ name: 'CHECK IN', url: '/check-in' })
                            this.setState({ navigationList: navList })
                        }
                    })
                }
                if (MoveOutDate == currentDate) {
                    GuestServicesapi.GetInOutOrdersLog({ orderUid: orderUIdObj.OrderUId }).then((GetInOutOrdersResponse) => {
                        if (GetInOutOrdersResponse.length > 0) {
                            var checkOut = GetInOutOrdersResponse.filter(e => e.Type == "OUT")
                            if (checkOut.length == 0) {
                                navList.push({ name: 'CHECK OUT', url: '/check-out' })
                                this.setState({ navigationList: navList })
                            } 
                        } else {
                            navList.push({ name: 'CHECK IN', url: '/check-in' })
                            this.setState({ navigationList: navList })
                        }
                    })
                }
  
            })
        }
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ siteLoader: false })
    }, 1500)
    this.guest();
  }


  async openOrCloseNav() {
    await this.setState({ isOpenorClose: !this.state.isOpenorClose })
    if (this.state.isOpenorClose) {
      if (document.getElementById("mySidepanel")) {
        document.getElementById("mySidepanel").style.left = "0px";
      }
    } else {
      if (document.getElementById("mySidepanel")) {
        document.getElementById("mySidepanel").style.left = "-320px";
      }
    }
  }

  expandImage(){
                $('#imagemodal').modal('show');
  }

  async logout() {
    if(this.state.roleId==3){
      await GuestServicesapi.ClientUsersLogOut({sessionId:localStorage.getItem('guestSession')})
    }else{
    await GuestServicesapi.GuestUsersLogOut({sessionId:localStorage.getItem('guestSession')})
    }
    localStorage.removeItem('guestSession');
    if(this.state.roleId==2){
        const userData= await SessionService.SessionDestory({type:'guest'});

        if(userData.error==0){
          location.href = "https://"+authDetails.authCredentails().hostName+"/v2/logout?client_id="+authDetails.authCredentails().clientId+"&returnTo="+authDetails.authCredentails().redirectUri
        }
    }
    if(this.state.roleId==3){
        const userData= await SessionService.SessionDestory({type:'client'});
        if(userData.error==0){
          location.href = '/celogin'
        }
        
    }
  }

  render() {
    const { navigationList, userData } = this.state;
        const navList = userData.status !== 'TERMED' ? navigationList : [{ name: 'ARRIVAL INFO', url: '/trip-info-data' }]
   console.log(navList)
        const nth = function (d) {
      if (d > 3 && d < 21) return 'th';
      switch (d % 10) {
        case 1: return "st";
        case 2: return "nd";
        case 3: return "rd";
        default: return "th";
      }
    }

    var moveOutDay = "",
      moveInDay = "",
      moveInDateYear = "",
      moveInDateMonth = "",
      moveInDate = "",
      moveOutDate = "",
      moveOutDateYear = "",
      moveOutDateMonth = "",
      communityName = "",
      communityCityName = "",
      communityStateName = "",
      communityZipCode = "",
      communityAddress = "",
      noticeToVacateDay = "",
      noticeToVacateYear = "",
      noticeToVacateMonth = "",
      noticeToVacateDate = "",
      suite = "",
      floor = "",
      bed = "",
      parkingSpace = "",
      gateCode = "",
      mailBox = "";
    if (this.state.orderInfo !== null) {
      const {
        MoveInDate,
        MoveOutDate,
        Community,
        CommunityCity,
        CommunityAddress,
        CommunityState,
        MasterSuite,
        MasterFloor,
        MasterNoOfBed,
        MasterParkingSpace,
        MasterGateCode,
        MasterMailBox,
        CommunityZipCode,
        NoticetoVacate
      } = this.state.orderInfo;
      moveInDay = moment(MoveInDate).format('dddd')
      moveInDateYear = moment(MoveInDate).format('YYYY')
      moveInDateMonth = moment(MoveInDate).format('MMMM')
      moveInDate = moment(MoveInDate).format('DD')
      moveOutDay = moment(MoveOutDate).format('dddd')
      moveOutDate = moment(MoveOutDate).format('DD')
      moveOutDateMonth = moment(MoveOutDate).format('MMMM')
      moveOutDateYear = moment(MoveOutDate).format('YYYY')
      communityName = Community
      communityCityName = CommunityCity&&CommunityCity+','
      communityStateName = CommunityState&&CommunityState+','
      communityZipCode = CommunityZipCode
      communityAddress = CommunityAddress&&CommunityAddress+','
      suite = MasterSuite
      floor = MasterFloor
      bed = MasterNoOfBed
      parkingSpace = MasterParkingSpace
      gateCode = MasterGateCode
      mailBox = MasterMailBox
      noticeToVacateDay = moment(NoticetoVacate).format('dddd')
      noticeToVacateYear = moment(NoticetoVacate).format('YYYY')
      noticeToVacateMonth = moment(NoticetoVacate).format('MMMM')
      noticeToVacateDate = moment(NoticetoVacate).format('DD')
    }
    return (
      <div>
<div className="guest-Ui">
{this.state.siteLoader &&
          <SiteLoader />
        }
        <GuestHeaderNav/>
         <div className="sliderView">
          <div className="container">
            <h1>My booking list</h1>
            <ul className="nav nav-tabs">
              <li><a data-toggle="tab" href="#tabUpcoming">UPCOMING</a></li>
              <li className="active"><a data-toggle="tab" href="#tabPast">PAST</a></li>
            </ul>
            <div className="tab-content">
              <div id="tabUpcoming" className="tab-pane fade ">
                <h3>HOME</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                  labore et dolore magna aliqua.</p>
              </div>
              <div id="tabPast" className="tab-pane fade in active">
                <div className="bookingList">
                  <a href="/reservations" className="backarrow"><i className="fa fa-arrow-left" />Back</a>
                  <h2>{communityName}</h2>
                  <h3>{this.state.GuestInfoCommunityDetails.address==null?'':this.state.GuestInfoCommunityDetails.address} {this.state.GuestInfoCommunityDetails.AptStreetAddr==null?'':`, ${this.state.GuestInfoCommunityDetails.AptStreetAddr}`} {this.state.GuestInfoCommunityDetails.AptAddr==null?'':`, ${this.state.GuestInfoCommunityDetails.AptAddr}`} <span>Booking ID <b>#{this.state.orderInfo.OrderId}</b></span>
                  </h3>
                  <div className="row">
                    <div className="col-md-7">
                      <div className="imageView mb-5">
                        <img src={!this.state.orderInfo.CommunityImage ? "../images/dummyCommunity.jpg" : this.state.orderInfo.CommunityImage} alt="" width="100%" />
                        <a className="pop">
                          <img src={!this.state.orderInfo.CommunityImage ? "../images/dummyCommunity.jpg" : this.state.orderInfo.CommunityImage} alt="" width="100%" />
                          <i className="fa fa-expand" onClick={this.expandImage}/>
                        </a>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="cntView">
                        <h1>Booking Details</h1>
                        <h4>Check In</h4>
                        <h5>{moveInDate} {moveInDateMonth} {moveInDateYear}
                         <span>10:00AM</span></h5>
                        <h4>Check Out</h4>
                        <h5>{moveOutDate} {moveOutDateMonth} {moveOutDateYear
                        } <span>11:00AM</span></h5>
                        <h4>Email</h4>
                        <h5>ashok@abccompany.com</h5>
                        <h4>Phone Number</h4>
                        <h5>081XXX999</h5>
                      </div>
                    </div>
                  </div>
                  <div className="panel-group" id="accordion">
                    <div className="panel panel-default">
                      <div className="panel-heading">
                        <h4 className="panel-title">
                          <a data-toggle="collapse" data-parent="#accordion" href="#collapse1" />
                          Community Details 
                        </h4>
                      </div>
                      <div id="collapse1" className="panel-collapse collapse in">
                        <div className="panel-body">
                          <div className="detialView">
                            <div className="item">
                              <p>Community</p>
                              <span>{communityName}</span>
                              <p>Floor</p>
                              <span>{this.state.GuestInfoCommunityDetails.Floor}</span>
                              <p>Size</p>
                              <span>{this.state.GuestInfoCommunityDetails.SuiteSizeCode}</span>
                              <p>Parking Space</p>
                              <span>{this.state.GuestInfoCommunityDetails.ParkingSpace1}</span>
                              <p>Wifi Network</p>
                              <span>{this.state.GuestInfoCommunityDetails.WiFiName}</span>
                            </div>
                            <div className="item">
                              <p>Suite Address</p>
                              <span>{this.state.GuestInfoCommunityDetails.address==null?'':this.state.GuestInfoCommunityDetails.address} {this.state.GuestInfoCommunityDetails.AptStreetAddr==null?'':`, ${this.state.GuestInfoCommunityDetails.AptStreetAddr}`} {this.state.GuestInfoCommunityDetails.AptAddr==null?'':`, ${this.state.GuestInfoCommunityDetails.AptAddr}`}
                              </span>
                              <div className="itemSub">
                                <div className>
                                  <p>Suite Phone</p>
                                  <span>{this.state.GuestInfoCommunityDetails.SuitePhone}</span>
                                </div>
                                <div className>
                                  <p>Housekeeping</p>
                                  <span>{this.state.GuestInfoCommunityDetails.NextHouseKeeping}</span>
                                </div>
                              </div>
                              <div className="itemSub">
                                <div className>
                                  <p>Entry</p>
                                  <span>{this.state.GuestInfoCommunityDetails.Entry}</span>
                                </div>
                                <div className>
                                  <p>Lockbox Code</p>
                                  <span>-</span>
                                </div>
                              </div>
                              <div className="itemSub">
                                <div className>
                                  <p>Pets</p>
                                  <span>{this.state.GuestInfoCommunityDetails.petscount}</span>
                                </div>
                                <div className>
                                  <p>Gate Code</p>
                                  <span>{this.state.GuestInfoCommunityDetails.GateCode}</span>
                                </div>
                              </div>
                              <div className="itemSub">
                                <div className>
                                  <p>Wifi Password</p>
                                  <span>{this.state.GuestInfoCommunityDetails
                                  .WiFiPassword}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="imagemodal" tabIndex={-1} role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <button type="button" className="close" data-dismiss="modal"><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
                <img src={!this.state.orderInfo.CommunityImage ? "../images/dummyCommunity.jpg" : this.state.orderInfo.CommunityImage} className="imagepreview" style={{width: '100%'}} />
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    )
  }
}

export default orderDetails
