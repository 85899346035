/* Importing the node modules, child components, services and controllers used 
   inside TravelBookingLogin component */
import $ from "jquery";
import TravelBookingApiService from '../../../services/travelBookingApiService';

/* TravelBookingLogin Component initialization */
class TravelBookingLogin extends React.Component {
    /* Initializing objects of its TravelBookingLogin class */
    constructor(props) {
        super(props)
        this.state = {
            emailId: '',
            password: '',
            errorMessage: '',
            color: ''
        }
        this.setValue = this.setValue.bind(this);
        this.authentication = this.authentication.bind(this);
        this.forgotPassword = this.forgotPassword.bind(this);
    }
    /* To update the state variables */
    setValue(field, e) {
        var object = {};
        object[field] = e.target.value;
        this.setState(object);
    }
    /* Login Authentication checking */
    async authentication(e) {
        e.preventDefault();
        var url = localStorage.getItem('url')
        localStorage.clear()
        localStorage.setItem('url', url)
        const data = {
            email: this.state.emailId,
            password: this.state.password
        }

        const response = await TravelBookingApiService.adminLogin(data)

        if (response.error == '0') {
            localStorage.setItem('travel-email', Base64.encode(this.state.emailId));
            localStorage.setItem('travel-password', Base64.encode(this.state.password));
            location.href = '/travel-booking-admin';


        } else {
            this.setState({ errorMessage: response.message, color: 'red' });
        }
    }
    /* Forgot password checking */
    forgotPassword() {

    }
    /* It is invoked to return html content */
    render() {
        return (
            <div className="modal fade savesearch" id="travel-booking-login" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content rm-border-radius">
                        <div className="modal-header">
                            <button type="button" className="close btn" data-dismiss="modal" aria-label="">
                                <i className="bi bi-x"></i>
                            </button>
                        </div>
                        <div className="text-center" style={{ color: this.state.color }}>{this.state.errorMessage}</div>
                        <div className="modal-body">
                            <h2>Login</h2>
                            <form role="form" onSubmit={(e) => this.authentication(e)}>
                                <div className="mrg-btm-30" >
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Email" value={this.state.emailId}
                                                onChange={(e) => this.setValue('emailId', e)} />
                                        </div>
                                        <div className="form-group">
                                            <input type="password" className="form-control" placeholder="Password" value={this.state.password}
                                                onChange={(e) => this.setValue('password', e)} />
                                        </div>
                                        <button className="btn location_btn" type="submit" >Login</button>
                                        <a onClick={this.forgotPassword}>Forgot your password?</a>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
export default TravelBookingLogin;