/* Importing the node modules, child components, services and controllers used 
   inside mapController object */
   'use strict'
   /**
    * Map controller - controls application state and interaction among various components.
    */
   const mapController = {
     /* To initialize the google maps */
     async loadMapData(community, index,this_obj, value, icon) {
       const _this=this_obj
       var directionsService = new google.maps.DirectionsService();
       var directionsDisplay = new google.maps.DirectionsRenderer();
       await  _this.setState({'directionsService': directionsService});
       await _this.setState({'directionsDisplay': directionsDisplay});
       const image_name = community.image_name;
       const communityName = community.community_name;
       const communityAddress = community.address;
       const communityLat = community.latitude;
       const communityLong = community.longitude;
       const communityCity = community.city_name;
       const communityState = community.state_name;
       const communityZipcode = community.ZipCode;
       const detailAddress= communityAddress +', ' +communityCity + ', '+communityState + ' ' + communityZipcode
       var locations = {
         lat: Number(communityLat),
         lng: Number(communityLong)
       };
       var map;
       var contentString;
       if (index == 'singlemark') {
          map = new google.maps.Map(document.getElementById('map'), {
             zoom: 7,
             center: locations,
             mapTypeId: google.maps.MapTypeId.ROADMAP
           });
          contentString = '<div id="content">' +
           '<div id="siteNotice">' +
           '</div>' +
           '<div id="bodyContent">'+
           '<div class="bodycontentimg"><img width="100px" height="100px" src="' + image_name + '" /> </div>' +
           '<h1>'+ communityName +'</h1>' +
           '<p>'+ detailAddress +' </p><a href="https://www.google.com/maps?daddr='+communityLat+ ','+communityLong+'" target="_blank">Get driving directions</a>' +
         '</div>' +
         '</div>';
       } else if(index == 'communityInfo') {      
            map = new google.maps.Map(document.getElementById('map'+community.community_id), {
               zoom: 7,
               center: locations,
               mapTypeId: google.maps.MapTypeId.ROADMAP
             });
            contentString = '<div id="content">' +
             '<div id="siteNotice">' +
             '</div>' +
             '<div id="bodyContent">'+
             '<div class="bodycontentimg "><img width="100px" height="100px" src="' + image_name + '" /> </div>' +
             '<h1>'+ communityName +'</h1>' +
             '<p>'+ detailAddress +' </p><a href="https://www.google.com/maps?daddr='+communityLat+ ','+communityLong+'" target="_blank">Get driving directions</a>' +
           '</div>' +
           '</div>';      
       }else {
          map = new google.maps.Map(document.getElementById('map' + index), {
             zoom: 7,
             center: locations,
             mapTypeId: google.maps.MapTypeId.ROADMAP
           });
           if(value == 'service'){ 
             if(_this.state.bannerimage != ''){
                contentString = '<div id="content">' +
               '<div id="siteNotice">' +
               '</div>' +
               '<div id="bodyContent">'+
               '<div class="bodycontentimg "><img width="100px" height="100px" src="' + _this.state.bannerimage + '" /></div>' +
               '<h1>'+ communityName +'</h1>' +
               '<p>'+ detailAddress +' </p><a href="https://www.google.com/maps?daddr='+communityLat+ ','+communityLong+'" target="_blank">Get driving directions</a>' +
             '</div>' +
             '</div>';
             }
             else{
                contentString = '<div id="content">' +
               '<div id="siteNotice">' +
               '</div>' +
               '<div id="bodyContent">'+
               '<h1>'+ communityName +'</h1>' +
               '<p>'+ detailAddress +' </p><a href="https://www.google.com/maps?daddr='+communityLat+ ','+communityLong+'" target="_blank">Get driving directions</a>' +
             '</div>' +
             '</div>';
             }
                   
           }else{
              contentString = '<div id="content">' +
               '<div id="siteNotice">' +
               '</div>' +
               '<div id="bodyContent" style="width: auto">' +
               '<h1>' + communityName + '</h1>' +
               '<p>' + detailAddress + '</p>' +
             '</div>' +
             '</div>';
           }
       }
       _this.state.directionsDisplay.setMap(map);
       var infowindow = new google.maps.InfoWindow({
           content: contentString
         });
       var marker = new google.maps.Marker({
           position: locations,
           icon: icon,
           map: map
         });
       marker.addListener('click', function () {
         infowindow.open(map, marker);
       });
     },
     /* To initialize the google maps */
     async initMap(data, value, companyName) {
       let locations = data.filter(row => row.latitude !== null).map((row, index) => {
             var address;
             const favourite=(row.favouritestatus != undefined && row.favouritestatus != null && (!row.favouritestatus))
             ? "fa fa-heart"
             : "";
             const color=(row.favouritestatus != undefined && row.favouritestatus != null && (!row.favouritestatus))
             ? 'red'
             : 'white';
             if(value == 'scheduleMap'){
               address = '<div id="content">' +
               '<div id="siteNotice">' +
               '</div>' +
               '<div id="bodyContent">'+
               '<h1>'+ row.community_name +'</h1>' +
               '<p>' + row.address +', ' +row.city_name + ', '+row.state_name +' '+ row.ZipCode + '</p>' +
               '<a href="https://www.google.com/maps?daddr='+row.latitude+ ','+row.longitude+'" target="_blank">Get driving directions</a>'+
               '</div>' +
               '</div>';
             }else{
               address = '<div id="content">' +
               '<div id="siteNotice">' +
               '</div>' +
               '<div id="bodyContent">'+
               '<div class="bodycontentimg citylandimgcircle"><img width="100px" height="100px" src="' + row.image_name + '" /> <i class="' + favourite + '" style="color:'+color+'"></i> </div>' +
               '<h1>'+ row.community_name +'</h1>' +
               '<p style="text-transform: capitalize">' + row.address +', ' +_.capitalize (row.city_name) + ', '+_.capitalize(row.state_name )+' '+ row.ZipCode + '</p>' +
               '<a href="https://www.google.com/maps?daddr='+row.latitude+ ','+row.longitude+'" target="_blank">Get Driving Directions</a>'+
               '</div>' +
               '</div>';
             }  
           return [address, row.latitude, row.longitude, index]
         })
       locations = locations.length > 0 ?
         locations : ["655 S. Fair Oaks", 0.00, -0.00, 0]
       if (data.length > 0) {
         var map = new google.maps.Map(document.getElementById('map'), {
             zoom: 15,
             center: new google.maps.LatLng(locations[0][1], locations[0][2]),
             mapTypeId: google.maps.MapTypeId.ROADMAP,
             gestureHandling: 'cooperative'
           });
         var infowindow = new google.maps.InfoWindow({});
         var marker,i;
         for (i = 0; i < locations.length; i++) {
           marker = new google.maps.Marker({
               position: new google.maps.LatLng(locations[i][1], locations[i][2]),
                 icon: '/assets/newUI-images/explore_icons/map_icon.svg',
               map: map,
               title: 'Hello World!'
             });
           google.maps.event.addListener(marker, 'click', (function (markers, j) {
               return function () {
                 infowindow.setContent(locations[j][0]);
                 infowindow.open(map, markers);
               }
             })(marker, i));
         }
       }
     },
     /* To calculate distance between source and destination locations */
     calculateDistance(thisObj,position, requestDetails) {  
       const _this = thisObj;
       const directionsService = new google.maps.DirectionsService;
       const sourceLat = position.coords.latitude;
       const sourceLong = position.coords.longitude;
       const destLat = requestDetails.latitude == null ? 0.00 : requestDetails.latitude;
       const destLong = requestDetails.longitude == null ? -0.00 : requestDetails.longitude;
       directionsService.route({
         origin: {lat: Number(sourceLat), lng: Number(sourceLong)},
         destination: {lat: Number(destLat), lng: Number(destLong)},
         travelMode: 'DRIVING'
       }, function(response, status) {
         if (status === 'OK') {
           _this.setState({distance: response.routes[0].legs[0].distance.text, duration: response.routes[0].legs[0].duration.text})
         } 
       });
     },
     customMarkers(community, index,this_obj, value, icon, customMap){
       const _this=this_obj
       var directionsService = new google.maps.DirectionsService();
       var directionsDisplay = new google.maps.DirectionsRenderer();
       _this.setState({'directionsService': directionsService});
       _this.setState({'directionsDisplay': directionsDisplay});
       const image_name = community.image_name;
       const communityName = community.community_name;
       const communityAddress = community.address;
       const communityLat = community.latitude;
       const communityLong = community.longitude;
       const communityCity = community.city_name;
       const communityState = community.state_name;
       const communityZipcode = community.ZipCode;
       const detailAddress= communityAddress +', ' +communityCity + ', '+communityState + ' ' + communityZipcode
       var locations = {
         lat: Number(communityLat),
         lng: Number(communityLong)
       };
         var contentString = '<div id="content">' +
           '<div id="siteNotice">' +
           '</div>' +
           '<div id="bodyContent">'+
           '<div class="bodycontentimg"><img width="100px" height="100px" src="' + image_name + '" />  </div>' +
           '<h1>'+ communityName +'</h1>' +
           '<p>'+ detailAddress +' </p><a href="https://www.google.com/maps?daddr='+communityLat+ ','+communityLong+'" target="_blank">Get driving directions</a>' +
         '</div>' +
         '</div>';
       _this.state.directionsDisplay.setMap(customMap);
       var infowindow = new google.maps.InfoWindow({
           content: contentString
         });
       var marker = new google.maps.Marker({
           position: locations,
           icon: icon,
           map: customMap
         });
       marker.addListener('click', function () {
         infowindow.open(customMap, marker);
       });
   
     },
     streetView(communityObject){
       var panorama = new google.maps.StreetViewPanorama(
         document.getElementById('street-view'),
         {
           position: {lat: Number(communityObject.latitude), lng: Number(communityObject.longitude)},
           pov: {heading: 165, pitch: 0},
           zoom: 1
         });
         console.log(panorama,'panorama')
     }
   }
   
   export default mapController