/* Importing the node modules, child components, services and controllers used 
   inside GuestHeader component */
   import {Link} from 'react-router-dom';
   import SessionService from "../../services/sessionService";
   import $ from "jquery";   
   import Conference from '../../services/conference';
   import CallModal from './callModal'
   import LoginModal from '../controller/loginmodalController';
   import SiteLoader from './siteLoader';
   import AppContoller from '../controller/appController';
   import GuestServicesapi from '../../services/guestServicesapi';
   import authDetails from "./authDetails";
   import Header from '../components/newinterface/header';
   /* GuestHeader Component initialization */
   class GuestHeader extends React.Component {
       /* Initializing objects of its GuestHeader class */
       constructor(props) {
           super(props)
           /* GuestHeader Component State variables Initialization */
           this.state = {
               name: '',
               company: '',
               location: '',
               phone: '',
               email: '',
               password: '',
               bookingDetails: {},
               iconStatus: false,
               login: false,
               register: false,
               forgotpassword: false,
               message: '',
               onNext: false,
               color: '',
               loader: false,
               menuId: 0,
               clickedMenuId: 0,
               guest:true,
               client:false,
               navIndex: -1,
               phoneNo:'',
               resLocation: '',
               resName: '',
               resCompany: '',
               resEmail: '',
               resPhone: '',
               resPlace:'',
               navigation: false ,
               roleId:0,
               ClientType:'',
               callModalLoading:false               
           }
           this.removeNavHover = this.removeNavHover.bind(this);
           this.changeIcon = this.changeIcon.bind(this);
           this.closePopup = this.closePopup.bind(this);
           this.loginClear = this.loginClear.bind(this);
           this.logout = this.logout.bind(this);
           this.navigation = this.navigation.bind(this);
           this.clearPhone = this.clearPhone.bind(this);
           this.menuClick = this.menuClick.bind(this);
           this.navSelection = this.navSelection.bind(this);   
       }
   /* It is invoked immediately after a component is mounted */
   async twocomponentDidMount() {
    $(document).ready(function () {
        $(".cc_mega_login2").click(function () {
          $('.navbar-collapse').removeClass('in');
        });
        $(window).scroll(function () {
           if ($(this).scrollTop() != 0) {
             $('li.dd_booknow').removeClass('open');
             $('li.new_userlog').removeClass('open');
           }
        });
     });
    $("body").removeClass('bodytrns320l no-scroll profile-open')
   }
       async componentDidMount() {
           this.twocomponentDidMount()
           AppContoller.removeSiteLoader();
           var client = await SessionService.SessionGet({type:'client'});
           if(client.error==0){
            await this.setState({roleId: (client.roleId!=undefined && Object.keys(client).length>0)? client.roleId : 0,
                ClientType:(client.ClientType!=undefined && Object.keys(client).length>0? client.ClientType : '')})
           }   
           var web = await SessionService.SessionGet({type:'guest'});
           if(web.error==0){
            await this.setState({roleId: (web.roleId!=undefined && Object.keys(web).length>0)? web.roleId : 0,
                ClientType:(web.ClientType!=undefined && Object.keys(web).length>0? web.ClientType : '')})
           }
           
       }
   
   /* To update the state values for the input fields */
   setValue(field, e) {
    var object = {};
    object[field] = e.target.value;
    this.setState(object);
}
       clearPhone(){
           this.setState({phoneNo:'',message:'',color:'',callModalLoading:true})
       }
       callTo(){
           if(this.state.phoneNo.length <10){
           this.setState({message:'Please enter a valid number',color:'red'})
           }
           else{
               const numbers = [
                   { number:'+18003679501'},
                   { number: '+1'+this.state.phoneNo}];               
               Conference.conference(numbers)
           }
       }
        /* To change the menu icon in mobile/tab view on menu open*/
       changeIcon() {
           this.setState({
               iconStatus: !this.state.iconStatus
           });
       }
       /* To close the opened menu */    
       closePopup() {    
        this.setState({
            resLocation: '',
            resName: '',
            resCompany: '',
            resEmail: '',
            resPhone: '',
            resPlace:''})
           $(".navbar-collapse").removeClass('in');
           $("#mobile-nav button.navbar-toggle").addClass('collapsed');
           this.setState({iconStatus: false, navIndex: -1, menuId : 0, clickedMenuId : 0});
           localStorage.setItem('navIndex', -1);
           localStorage.setItem('menuId', 0);
           localStorage.setItem('clickedMenuId', 0);
       }
       /* To clear the login form input fiels values */
        loginClear(){  
         this.setState({message: '',client:false,guest:true, color: '',phone:'', email:'',password:'',login :true,onNext :false ,forgotpassword : false})
       }
       menuClick(clickedMenuId, index){
        this.setState({clickedMenuId, navIndex: index});
        localStorage.setItem('clickedMenuId', clickedMenuId);
        localStorage.setItem('navIndex', index);
        localStorage.setItem('menuId', clickedMenuId);
       }
       navSelection(index){
        this.setState({navIndex: index});
        localStorage.setItem('navIndex', index);
       }
       removeNavHover(){
        this.setState({navIndex: -1, menuId : 0, clickedMenuId : 0});
        localStorage.setItem('navIndex', -1);
        localStorage.setItem('menuId', 0);
        localStorage.setItem('clickedMenuId', 0);
       }
      /* To clear the local storage data and logout from the fivestar application */
      async logout() {
        if(this.state.roleId==3){
            await GuestServicesapi.ClientUsersLogOut({sessionId:localStorage.getItem('guestSession')})
        }else{
            await GuestServicesapi.GuestUsersLogOut({sessionId:localStorage.getItem('guestSession')})
        }
        localStorage.removeItem('guestSession');
        if(this.state.roleId==2){
            const userData= await SessionService.SessionDestory({type:'guest'});
            //userData.error==0?location.href = '/guest-login':''
            if(userData.error==0){
                location.href = "https://"+authDetails.authCredentails().hostName+"/v2/logout?client_id="+authDetails.authCredentails().clientId+"&returnTo="+authDetails.authCredentails().redirectUri
            }
        }
        if(this.state.roleId==3){
            const userData= await SessionService.SessionDestory({type:'client'});
            if(userData.error==0){
                location.href = '/celogin'
            }
        }
      }
      navigation(){
          this.setState({navigation: !this.state.navigation});
      }
      closed() {
        $('#sideQuote-header').removeClass('active');
        $('.overlay').removeClass('active');
      }
       /* It is invoked to return html content */ 
       render() {
           const menuId = JSON.parse(localStorage.getItem('menuId'));
           const navIndex = JSON.parse(localStorage.getItem('navIndex'));
   
           return (
         <div className="SAweb_updateUi">
             <Header/>
             {/* <div className='fixedNavSpaceRemove'></div> */}
         </div>  
           );
       }
   }
   export default GuestHeader;

    
             {/*   <div className="corporate_menu">
                   <nav className="navbar navbar-inverse cc_megamenu top">
                       <div className="navbar-header">
                           <button
                           type="button"
                           className="navbar-toggle"
                           data-toggle="collapse"
                           data-target="#myNavbar"
                           onClick={this.changeIcon}>
                           <div id="nav-icon1">
                               <span></span>
                               <span></span>
                               <span></span>
                           </div>
                           <div id="nav-icon2">
                               <span></span>
                               <span></span>
                           </div>
                           </button>

                           <span className={this.state.navigation ? "sideweb_barmenu open" : "sideweb_barmenu"} onClick={this.navigation}>&#9776;</span>
                           {this.state.navigation ? <div id="sideweb_barmenu_toggler" className="sidenav">
                                <ul>
                                    <li><strong>Company</strong></li>
                                    <li><Link to="/company-info">About Us</Link></li>
                                    <li><Link to="/housing-solutions">Housing Solutions</Link></li>
                                    <li><Link to="/leadership-team">Leadership Team</Link></li>
                                    <li><Link to="/industry-awards">Industry Awards</Link></li>
                                    <li><Link to="/press-releases">Press Releases</Link></li>
                                    <li><Link to="/media-contact">Media Contact</Link></li>
                                </ul>
                            </div> : null}
                    
                           <button type="button" className="btn btn_submit res_right_btnss">
                           RESERVE NOW</button>
                       </div>
                       <div className="collapse navbar-collapse" id="myNavbar">

                       <ul className="nav-left-controls">
                       <li className="dd_booknow">
                                <Link to="/reserve-your-stay" className="dropdown-toggle">
                                <button onClick={this.closePopup}
                                    type="button"
                                    className="btn btn_submit"
                                    >
                                RESERVE NOW</button>
                                </Link>
                               </li>
                               <li>
                                    <div  data-toggle="modal" data-target="#callModal" onClick={this.clearPhone} className="helpblock cursor-pointer">
                                        <div className="helpitem help-icon">
                                        <img src={s3BucketUrl_WebsiteImages+"SA-Telephone-Icon-Red.png"}/>
                                        </div>
                                        <div className="helpitem">
                                        <div className="help-text">
                                        <strong>+1 800.367.9501</strong>
                                        </div>
                                        </div>
                                    </div>
                               </li>
                       </ul>
                           <ul className="nav navbar-nav ">
                               <li className={menuId == 1? "dropdown open" : 'dropdown'}>
                               <Link to="/corporation-corporate-housing" onClick={() => this.menuClick(1, 0)}>CORPORATION
                               <b className="caret"></b>
                               </Link>
                               </li>    
                               <li className={menuId == 2? "dropdown open" : 'dropdown'}>
                               <Link to="/relocation-corporate-housing" onClick={() => this.menuClick(2, 5)}>RELOCATION
                               <b className="caret"></b>
                               </Link>
                               </li> 
                               <li className={menuId == 3? "dropdown open" : 'dropdown'}>
                               <Link to="/government-housing" onClick={() => this.menuClick(3, 10)}>GOVERNMENT
                               <b className="caret"></b>
                               </Link>
                               </li> 
                               <li className={menuId == 4? "dropdown open" : 'dropdown'}>
                               <Link to="/individual-corporate-housing" onClick={() => this.menuClick(4, 13)}>INDIVIDUAL
                               <b className="caret"></b>
                               </Link>
                               </li>
                           </ul>
                           <div className="right_btnss">
                               <ul className="">
                               <li>
                                   <Link
                                   to="/locations"
                                   onClick={this.closePopup}>LOCATIONS</Link>
                               </li>
                               {this.state.roleId  == 0
                               ? 
                               <li className='hidden-xs'>
                                   <Link to="/guest-login">
                                   <button
                                       type="button"
                                       className="btn btn_login "
                                        onClick={this.loginClear}>
                                   GUEST LOGIN
                                   </button>
                                   </Link>
                               </li>
                               : 
                               <li className="dropdown new_userlog">
                                   {this.state.roleId == 2 &&
                              
                                   <div id="service-header" className="dropdown-toggle" data-toggle="dropdown">
                                       <Link to="#" onClick={this.closePopup}>
                                       <i className="fa fa-user"></i>
                                       </Link>
                                       <b className="caret"></b>
                                   </div>
                                   }
                                   {this.state.roleId == 3 &&
                                   <div id="service-header" className="dropdown-toggle" data-toggle="dropdown">
                                       <Link to="#">
                                       <i className="fa fa-user"></i>
                                       </Link>
                                       <b className="caret"></b>
                                   </div>
                                   } 
                                   <ul className="dropdown-menu ">
                                       {this.state.roleId == 2&&
                                       <li>
                                           <Link to="/reservations">
                                           Reservations</Link>
                                       </li>
                                       }
                                       {this.state.roleId == 3&&
                                       <li>
                                           {this.state.ClientType=='Normal'?
                                           <Link to="/client-portal">
                                           Client Portal</Link>: 
                                           <Link to="/cedashboard">
                                           Dashboard</Link>}
                                       </li>
                                       }
                                      
                                       <li>
                                           <a onClick={this.logout}>logout</a>
                                       </li>
                                   </ul>
                               </li>
                               }
                               </ul>
                           </div>
                       </div>
                       <div className="head-logo">
                       <Link className="" to="/" onClick={this.removeNavHover}>
                           <img src={s3BucketUrl_WebsiteImages+"SA-Logo.png"} alt=""/>
                           </Link>
                           <img className="star30-years-star" src="https://suiteam-website.s3-us-west-1.amazonaws.com/30-Years-Star.png" />
                           
                           </div>
                               
                           <div className="clearfix"></div>
                          { menuId == 1?
                           <div className="submenu">
                           <div className="container">
                               <h1>CORPORATION</h1>
                               <ul className="submenu_list pull-right">
                                   <li><Link className={(navIndex == 0) && 'active' } 
                                   onClick={() => this.navSelection(0)} to="/corporation-corporate-housing">Corporate Housing</Link></li>
                                   <li><Link className={(navIndex == 1) && 'active' } 
                                   onClick={() => this.navSelection(1)} to="/corporation-global">Global Serviced Apartments</Link></li>
                                    <li><Link className={(navIndex == 19) && 'active' } 
                                   onClick={() => this.navSelection(19)} to="/insurance-landing">Insurance Housing</Link></li>
                                   <li><Link className={(navIndex == 2) && 'active' } 
                                   onClick={() => this.navSelection(2)} to="/corporation-destination-services">Destination Services</Link></li>
                                   <li><Link className={(navIndex == 3) && 'active' } 
                                   onClick={() => this.navSelection(3)} to="/technology">Technology</Link></li>
                                   <li><Link className={(navIndex == 4) && 'active' } 
                                   onClick={() => this.navSelection(4)} to="/customization">Customization</Link></li>
                               </ul>
                               <div className="clearfix"></div>
                               </div>
                           </div> : null}
                           { menuId == 2?
                           <div className="submenu">
                           <div className="container">
                               <h1>RELOCATION</h1>
                               <ul className="submenu_list pull-right">
                                   <li><Link className={(navIndex == 5) && 'active' } 
                                   onClick={() => this.navSelection(5)} to="/relocation-corporate-housing">Corporate Housing</Link></li>
                                   <li><Link className={(navIndex == 6) && 'active' } 
                                   onClick={() => this.navSelection(6)} to="/global">Global Serviced Apartments</Link></li>
                                   <li><Link className={(navIndex == 20) && 'active' } 
                                   onClick={() => this.navSelection(20)} to="/insurance-landing">Insurance Housing</Link></li>
                                   <li><Link className={(navIndex == 7) && 'active' } 
                                   onClick={() => this.navSelection(7)} to="/destination-services">Destination Services</Link></li>
                                   <li><Link className={(navIndex == 8) && 'active' } 
                                   onClick={() => this.navSelection(8)} to="/relocation-technology">Technology</Link></li>
                                   <li><Link className={(navIndex == 9) && 'active' } 
                                   onClick={() => this.navSelection(9)} to="/relocation-customization">Customization</Link></li>
                               </ul>
                               <div className="clearfix"></div>
                               </div>
                           </div> : null}
                           { menuId == 3?
                           <div className="submenu">
                           <div className="container">
                               <h1>GOVERNMENT</h1>
                               <ul className="submenu_list pull-right">
                                   <li><Link className={(navIndex == 10) && 'active' } 
                                   onClick={() => this.navSelection(10)} to="/government-housing">Government Housing</Link></li>
                                   <li><Link className={(navIndex == 11) && 'active' } 
                                   onClick={() => this.navSelection(11)} to="/government-technology">Technology</Link></li>
                                   <li><Link className={(navIndex == 12) && 'active' } 
                                   onClick={() => this.navSelection(12)} to="/government-customization">Customization</Link></li>
                               </ul>
                               <div className="clearfix"></div>
                               </div>
                           </div> : null}
                           { menuId == 4?
                           <div className="submenu">
                           <div className="container">
                               <h1>INDIVIDUAL</h1>
                               <ul className="submenu_list pull-right">
                                   <li><Link className={(navIndex == 13) && 'active' } 
                                   onClick={() => this.navSelection(13)} to="/individual-corporate-housing">Corporate Housing</Link></li>
                                   <li><Link className={(navIndex == 14) && 'active' } 
                                   onClick={() => this.navSelection(14)} to="/individual-global">Global Serviced Apartments</Link></li>
                                  <li><Link className={(navIndex == 18) && 'active' } 
                                   onClick={() => this.navSelection(18)} to="/insurance-landing">Insurance Housing</Link></li>
                                   <li><Link className={(navIndex == 15) && 'active' } 
                                   onClick={() => this.navSelection(15)} to="/individual-destination-services">Destination Services</Link></li>
                                   <li><Link className={(navIndex == 16) && 'active' } 
                                   onClick={() => this.navSelection(16)} to="/individual-technology">Technology</Link></li>
                                   <li><Link className={(navIndex == 17) && 'active' } 
                                   onClick={() => this.navSelection(17)} to="/individual-customization">Customization</Link></li>
                               </ul>
                               <div className="clearfix"></div>
                               </div>
                           </div> : null}
                   </nav> 
                    <nav id="mobile-nav" className="navbar navbar-inverse cc_megamenu top fixed">
                       <div className="navbar-header">
                           <button
                           type="button" 
                           className="navbar-toggle collapsed" 
                           data-toggle="collapse" 
                           data-target="#myNavbar33"
                           onClick={this.changeIcon}>
                           <div id="nav-icon2">
                               <span></span>
                               <span></span>
                           </div>
                           <div id="nav-icon1">
                               <span></span>
                               <span></span>
                               <span></span>
                           </div>
                           </button>
                           <Link className="navbar-brand logo" to="/" onClick={this.closePopup}>
                           <img src={s3BucketUrl_WebsiteImages+"SA-Logo.png"} alt=""/>
                           </Link>
                           <img className="star30-years-star2" src="https://suiteam-website.s3-us-west-1.amazonaws.com/30-Years-Star.png" />

                           {this.state.roleId == 0
                           ?<Link to="/guest-login"
                           onClick={this.closePopup}>
                           <span className="cc_mega_login"  onClick={this.loginClear}>GUEST LOGIN</span>
                           </Link>
                           : 
                           <li className="dropdown new_userlog">
                               {this.state.roleId == 2 && 
                               <div id="service-header" className="dropdown-toggle" data-toggle="dropdown">
                               <Link to="#" onClick={this.closePopup}>
                               <i className="fa fa-user"></i>
                               </Link>
                               <b className="caret"></b>
                               </div>
       }
                               {this.state.roleId == 3 && 
                               <div id="service-header" className="dropdown-toggle" data-toggle="dropdown">
                               <Link to="#">
                               <i className="fa fa-user"></i>
                               </Link>
                               <b className="caret"></b>
                               </div>
                               } 
                             
                               <ul className="dropdown-menu ">
                               {this.state.roleId == 2 &&
                               <li>
                                   <Link to="/guestportal">
                                   Guest Portal</Link>
                               </li>
                               }
                               {this.state.roleId == 3&&
                               <li>
                               {(this.state.ClientType=='Normal')?
                               <Link to="/client-portal">
                               Client Portal</Link>: <Link to="/cedashboard">
                               Dashboard</Link>}
                                </li>
                               }
                               <li>
                                   <a onClick={this.logout}>logout</a>
                               </li>
                               </ul>
                           </li>
                           }
                       </div>
                       <div className="collapse navbar-collapse" id="myNavbar33">
                           <ul className="nav navbar-nav">
                               <li className="dropdown">
                                   <Link to="#" className="dropdown-toggle" data-toggle="dropdown">CORPORATION<b className="caret"></b></Link>
                                   <ul className="dropdown-menu">
                                       <li><Link onClick={this.closePopup} to="/corporation-corporate-housing">CORPORATE HOUSING</Link></li>
                                       <li><Link onClick={this.closePopup} to="/corporation-global">GLOBAL SERVICED APARTMENTS</Link></li>
                                       <li><Link onClick={this.closePopup} to="/insurance-landing">INSURANCE HOUSING</Link></li>
                                       <li><Link onClick={this.closePopup} to="/corporation-destination-services">DESTINATION SERVICES</Link></li>
                                       <li><Link onClick={this.closePopup} to="/technology">TECHNOLOGY</Link></li>
                                       <li><Link onClick={this.closePopup} to="/customization">CUSTOMIZATION</Link></li>
                                   </ul>                            
                               </li>
                               <li className="dropdown">
                                   <Link to="#" className="dropdown-toggle" data-toggle="dropdown">RELOCATION<b className="caret"></b></Link>
                                   <ul className="dropdown-menu">
                                       <li><Link onClick={this.closePopup} to="/relocation-corporate-housing">CORPORATE HOUSING</Link></li>
                                       <li><Link onClick={this.closePopup} to="/global">GLOBAL SERVICED APARTMENTS</Link></li>
                                       <li><Link onClick={this.closePopup} to="/insurance-landing">INSURANCE HOUSING</Link></li>
                                       <li><Link onClick={this.closePopup} to="/destination-services">DESTINATION SERVICES</Link></li>
                                       <li><Link onClick={this.closePopup} to="/relocation-technology">TECHNOLOGY</Link></li>
                                       <li><Link onClick={this.closePopup} to="/relocation-customization">CUSTOMIZATION</Link></li>
                                   </ul>
                               </li>
                               <li className="dropdown">
                                   <Link to="#" className="dropdown-toggle" data-toggle="dropdown">GOVERNMENT<b className="caret"></b></Link>
                                   <ul className="dropdown-menu">
                                       <li><Link onClick={this.closePopup} to="/government-housing">GOVERNMENT HOUSING</Link></li>
                                       <li><Link onClick={this.closePopup} to="/government-technology">TECHNOLOGY</Link></li>
                                       <li><Link onClick={this.closePopup} to="/government-customization">CUSTOMIZATION</Link></li>
                                   </ul>
                               </li>
                               <li className="dropdown">
                                   <Link to="#" className="dropdown-toggle" data-toggle="dropdown">INDIVIDUAL<b className="caret"></b></Link>
                                   <ul className="dropdown-menu">
                                       <li><Link onClick={this.closePopup} to="/individual-corporate-housing">CORPORATE HOUSING</Link></li>
                                       <li><Link onClick={this.closePopup} to="/individual-global">GLOBAL SERVICED APARTMENTS</Link></li>
                                       <li><Link onClick={this.closePopup} to="/insurance-landing">INSURANCE HOUSING</Link></li>
                                       <li><Link onClick={this.closePopup} to="/individual-destination-services">DESTINATION SERVICES</Link></li>
                                       <li><Link onClick={this.closePopup} to="/individual-technology">TECHNOLOGY</Link></li>
                                       <li><Link onClick={this.closePopup} to="/individual-customization">CUSTOMIZATION</Link></li>
                                   </ul>
                               </li>
                               <li className="dropdown">
                                   <Link to="#" className="dropdown-toggle" data-toggle="dropdown">COMPANY<b className="caret"></b></Link>
                                   <ul className="dropdown-menu">
                                       <li><Link onClick={this.closePopup} to="/company-info">ABOUT US</Link></li>
                                       <li><Link onClick={this.closePopup} to="/housing-solutions">HOUSING SOLUTIONS</Link></li>
                                       <li><Link onClick={this.closePopup} to="/leadership-team">LEADERSHIP TEAM</Link></li>
                                       <li><Link onClick={this.closePopup} to="/industry-awards">INDUSTRY AWARDS</Link></li>
                                       <li><Link onClick={this.closePopup} to="/press-releases">PRESS RELEASES</Link></li>
                                       <li><Link onClick={this.closePopup} to="/media-contact">MEDIA CONTACT</Link></li>
                                   </ul>
                               </li>
                           </ul>
                           <div className="right_btnss">
                               <ul className="">
                               <li>
                                   <Link
                                   to='/locations'
                                   onClick={this.closePopup}>LOCATIONS<b className="caret"></b>
                                   </Link>
                               </li>
                               {this.state.roleId == 0
                               ? 
                               <li className='hidden-xs'>
                                   <Link to="/guest-login">
                                   <button
                                       type="button"
                                       className="btn btn_login"
                                     onClick={this.loginClear}>
                                   GUEST LOGIN
                                   </button>
                                   </Link>
                               </li>
                               : 
                               <li className="dropdown new_userlog hidden-xs">
                                   {this.state.roleId == 2
                                   &&
                                   <div id="service-header" className="dropdown-toggle" data-toggle="dropdown">
                                       <Link to="#" onClick={this.closePopup}>
                                       <i className="fa fa-user"></i>
                                       </Link>
                                       <b className="caret"></b>
                                   </div>
                                   }
                                   {this.state.roleId == 3
                                &&
                                   <div id="service-header" className="dropdown-toggle" data-toggle="dropdown">
                                       <Link to="#">
                                       <i className="fa fa-user"></i>
                                       </Link>
                                       <b className="caret"></b>
                                   </div>
                                   } 
                                   <ul className="dropdown-menu ">
                                      
                                       {this.state.roleId == 2
                                     &&
                                       <li>
                                           <Link to="/guestportal">
                                           Guest Portal</Link>
                                       </li>
                                       }
                                       {this.state.roleId == 3 
                                        &&
                                        <li>
                                            {this.state.ClientType=='Normal'?<Link to="/client-portal">
                                            Client Portal</Link>: <Link to="/cedashboard">
                                            Dashboard</Link>}
                                        </li>
                                        }
                                       <li>
                                           <a onClick={this.logout}>logout</a>
                                       </li>
                                   </ul>
                               </li>
                               }
                               <li className="dropdown dd_booknow">
                               <Link to="/reserve-your-stay" className="dropdown-toggle" data-toggle="dropdown">
                               <button onClick={this.closePopup}
                                   type="button"
                                   className="btn btn_submit">
                               RESERVE NOW</button>
                               </Link>
                               </li>
                               <li className="cc_contactbtn">
                                   <Link to="/contactUs">
                                   <button type="button" className="btn" onClick={this.closePopup}>
                                   CONTACT US</button>
                                   </Link>
                               </li>
                               </ul>
                           </div>
                       </div>
                   </nav> 
                   <LoginModal /> 
                  {this.state.callModalLoading?<CallModal />:''} 
                   <div className="res-cover-heder-ht"></div>
                    <SiteLoader /> 
                   <div>*/}