const UpcomingEvents = () => {
    return (
        <div className="intra_body">
   <div className="row">
      <div className="col-md-8">
         <div className="inet_leftbody">
            <h1>UPCOMING EVENTS</h1>
            <div className="col-md-6">
               <div>
                  <div className="calendar"></div>
                  <div className="row">
                     <div className="col-md-6 col-xs-6 payday1">
                        <h5>
                           <span></span>Pay <br/>Days
                        </h5>
                     </div>
                     <div className="col-md-6 col-xs-6 payday2">
                        <h5>
                           <span></span>SuiteAmerica  <br/>Holidays
                        </h5>
                     </div>
                     <div className="col-md-6 col-xs-6 payday3">
                        <h5>
                           <span></span>Benefit <br/>Enrollment
                        </h5>
                     </div>
                     <div className="col-md-6 col-xs-6 payday4">
                        <h5>
                           <span></span>Customer <br/>Service Week
                        </h5>
                     </div>
                  </div>
               </div>
            </div>
            <div className="col-md-6">
               <div className="inet_events">
                  <div className="pay_periods">
                     <h4>2018 Pay Periods</h4>
                     <table className="table table-striped">
                        <thead>
                           <tr>
                              <th>Begin</th>
                              <th>End</th>
                              <th>Pay</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr>
                              <td>Dec25</td>
                              <td>Jan7</td>
                              <td>Jan12</td>
                           </tr>
                           <tr>
                              <td>Jan8</td>
                              <td>Jan21</td>
                              <td>Jan26</td>
                           </tr>
                           <tr>
                              <td>Jan22</td>
                              <td>Feb4</td>
                              <td>Feb9</td>
                           </tr>
                           <tr>
                              <td>Feb5</td>
                              <td>Feb18</td>
                              <td>Feb23</td>
                           </tr>
                           <tr>
                              <td>Feb19</td>
                              <td>Mar4</td>
                              <td>Mar9</td>
                           </tr>
                           <tr>
                              <td>Mar5</td>
                              <td>Mar18</td>
                              <td>Mar23</td>
                           </tr>
                           <tr>
                              <td>Mar19</td>
                              <td>Apr1</td>
                              <td>Apr6</td>
                           </tr>
                           <tr>
                              <td>Apr2</td>
                              <td>Apr15</td>
                              <td>Apr20</td>
                           </tr>
                           <tr>
                              <td>Arp16</td>
                              <td>Apr29</td>
                              <td>May4</td>
                           </tr>
                           <tr>
                              <td>Apr30</td>
                              <td>May13</td>
                              <td>May18</td>
                           </tr>
                           <tr>
                              <td>May14</td>
                              <td>May27</td>
                              <td>Jun1</td>
                           </tr>
                           <tr>
                              <td>May28</td>
                              <td>Jun10</td>
                              <td>Jun15</td>
                           </tr>
                           <tr>
                              <td>Jun11</td>
                              <td>Jun24</td>
                              <td>Jun29</td>
                           </tr>
                           <tr>
                              <td>May14</td>
                              <td>Jul8</td>
                              <td>Jul13</td>
                           </tr>
                           <tr>
                              <td>Jul9</td>
                              <td>Jul22</td>
                              <td>Jul27</td>
                           </tr>
                           <tr>
                              <td>Jul23</td>
                              <td>Aug5</td>
                              <td>Aug10</td>
                           </tr>
                           <tr>
                              <td>Aug6</td>
                              <td>Aug19</td>
                              <td>Aug24</td>
                           </tr>
                           <tr>
                              <td>Aug20</td>
                              <td>Sep2</td>
                              <td>Sep7</td>
                           </tr>
                           <tr>
                              <td>Sep3</td>
                              <td>Sep16</td>
                              <td>Sep21</td>
                           </tr>
                           <tr>
                              <td>Sep17</td>
                              <td>Sep30</td>
                              <td>Oct5</td>
                           </tr>
                           <tr>
                              <td>Oct1</td>
                              <td>Oct14</td>
                              <td>Oct19</td>
                           </tr>
                           <tr>
                              <td>Oct15</td>
                              <td>Oct28</td>
                              <td>Nov2</td>
                           </tr>
                           <tr>
                              <td>Oct29</td>
                              <td>Nov11</td>
                              <td>Nov16</td>
                           </tr>
                           <tr>
                              <td>Nov12</td>
                              <td>Nov25</td>
                              <td>Nov30</td>
                           </tr>
                           <tr>
                              <td>Nov26</td>
                              <td>Nov29</td>
                              <td>Dec14</td>
                           </tr>
                           <tr>
                              <td>Dec10</td>
                              <td>Dec23</td>
                              <td>Dec30</td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
                  <div className="observed_holidays">
                     <h4>2018 Observed Holidays</h4>
                     <table className="table">
                        <tbody>
                           <tr>
                              <td>New Year's Day</td>
                              <td className="text-right">Jan 1</td>
                           </tr>
                           <tr>
                              <td>President's Day</td>
                              <td className="text-right">Feb 19</td>
                           </tr>
                           <tr>
                              <td>Memorial Day</td>
                              <td className="text-right">May 28</td>
                           </tr>
                           <tr>
                              <td>Independence Day</td>
                              <td className="text-right">Jul 4</td>
                           </tr>
                           <tr>
                              <td>Labour Day</td>
                              <td className="text-right">Sep 3</td>
                           </tr>
                           <tr>
                              <td>Thanksgiving</td>
                              <td className="text-right">Nov 22-23</td>
                           </tr>
                           <tr>
                              <td>Christmas Day</td>
                              <td className="text-right">Dec 25</td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
    )
}
export default UpcomingEvents;