import React from 'react'
import Footer from './footer';
import OwlCarousel from 'react-owl-carousel';
import TransHeader from './transheader';
import RequestCallback from './requestCallback';

class InternHousing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  async componentDidMount() {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }
  }
  handleClick() {
    $('#sideQuote').addClass('active');
    $('.overlay').addClass('active');
    $('.collapse.in').toggleClass('in');
    $('a[aria-expanded=true]').attr('aria-expanded', 'false');
  }
  closed() {
    $('#sideQuote').removeClass('active');
    $('.overlay').removeClass('active');
  }
  render() {
    return (
      <div className="SAweb_updateUi">

        <TransHeader />
        <div className="overlay sideQuote"></div>

        <section className="SAweb_InternHousingBanner">
          <div className="container" >
            <div className="box" data-aos="fade-up">
              <div className="item">
                <h1>Housing solutions for your workforce</h1>

                <p>Accommodations for interns, employees, and other groups that’s tech-enabled and easy, keeping you feeling confident throughout the booking process.</p>
                <div><a href="#" onClick={this.handleClick} className="btn ">
                  Reserve Now</a>
                </div>           </div>
              <div className="item ">
                <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/screen-16/bannerImage.jpg" alt="" width="100%" />
                <h4 className='avery450'> SuiteAmerica <span> @ AVERY 450</span> </h4>
              </div>
            </div>

            <h2 className="text-center text-white" data-aos="fade-up">World-class amenities for your team</h2>

            <div className="boxTails" data-aos="fade-up">
              <div className="item">
                <div className="pic">
                  <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/screen-16/wifi.png" alt="" />
                </div>
                <p className="p">24/7 Wi-Fi
                  connectivity</p>
              </div>
              <div className="item">
                <div className="pic">
                  <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/screen-16/dashboard.png" alt="" />
                </div>
                <p className="p">Centralized
                  dashboards</p>
              </div>

              <div className="item">
                <div className="pic">
                  <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/House_Keeping_W.svg" alt="" />
                </div>
                <p className="p">Housekeeping services that fit your schedule</p>
              </div>
              <div className="item">
                <div className="pic">
                  <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/screen-16/cpu.png" alt="" />
                </div>
                <p className="p">Tech-driven easy
                  collaboration</p>
              </div>
              <div className="item">
                <div className="pic">
                  <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/screen-16/support.png" alt="" />
                </div>
                <p className="p">Round-the-clock support</p>
              </div>

            </div>

          </div>
        </section >

        <section className="SAweb_InternHousingPics">
          <div className="container">
            <div className="picTails" data-aos="fade-up">
              <div className="item">
                <div className="pic"> <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/screen-16/internPic-1.jpg" alt="" width="100%" /> </div>
                <h3>A scalable solution to accommodate your team</h3>
                <p>Housing a team, big or small, doesn’t need to be a concern.  Whether for 5 or 50 people, SuiteAmerica can support you and provide customized solutions to accommodate your needs.</p>
              </div>
              <div className="item">
                <div className="pic"> <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/screen-16/internPic-2.jpg" alt="" width="100%" /> </div>
                <h3>High-quality and comfortable temporary housing to make any stay superior</h3>
                <p>Duration doesn’t matter, but experience does.  Our focus on quality control and hospitality makes it easy for your employees to feel at home, even miles away.</p>
              </div>
              <div className="item">
                <div className="pic"> <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/screen-16/internPic-3.jpg" alt="" width="100%" /> </div>
                <h3>Pioneering technology to deliver the best experience</h3>
                <p>Our bespoke technology enhances any experience from accessing and customizing accommodations to staying up to date with their property information, it is all just a click away.  Easy.  Fast.  Convenient.</p>
              </div>
            </div>
          </div>

          <div className="text-center" data-aos="fade-up">
            <a href="/locations" > <button type="button" className="btn black-btn">Explore Our Stays </button></a>
          </div>
        </section>


        <section className='bg-white'>
          <div className=" py-5">
            <div className="container SAweb_owl-slider2 py-5">
              <div className="row" data-aos="fade-up">
                <div className="col-sm-5 d-flex align-items-center">
                  <h2 >What our guests have to say</h2>
                </div>
                <div className="col-sm-7 ">
                  <div className="owl-slider SAweb_sliderNav">
                    <div >
                      <OwlCarousel className="owl-theme owl-carousel"
                        {...{
                          loop: false, margin: 20, nav: false, dots: false, items: 1,
                          navContainerClass: 'owl-buttons',
                          navText: ["<i className='glyphicon glyphicon-menu-left'></i>",
                            "<i className='glyphicon glyphicon-menu-right'></i>"],
                          responsive: {
                            767: { items: 1, slideBy: 1 },
                            992: { items: 2, slideBy: 1 }
                          }
                        }}>
                        <div className="item">

                          <div className="caption">
                            <i className="bi bi-quote"></i>

                            <p>The community was well maintained and clean. I loved my stay. Thank you so much.
                            </p>
                            <h3>Omid, Portland </h3>
                            <i className="bi bi-quote"></i>
                          </div>
                        </div>
                        <div className="item">

                          <div className="caption">
                            <i className="bi bi-quote"></i>

                            <p>Touchless entry with the app is very good and works well. Room is very clean.</p>
                            <h3>Jiyu, Mountain View </h3>
                            <i className="bi bi-quote"></i>
                          </div>
                        </div>


                      </OwlCarousel >
                    </div>
                  </div>

                </div>
              </div>
            </div>


          </div>
        </section>
        <RequestCallback />
        {/* <section id="sideQuote">
          
          <div className="quoteHeading">
              <h4>We would love to help you </h4>
              <div id="dismiss">
               <a onClick={this.closed}><i className="bi bi-x"></i></a> 
            </div>
          </div>
          <div className="formQuote">
            <div className="btn-group" role="group" aria-label="Basic outlined example">
              <button type="button" className="btn active">For Businesses</button>
              <button type="button" className="btn ">For Individuals</button>
            </div>
            <form action="">
            
            <div className="floating-label-group">
  <input type="text"  className="form-control"   required />
    <label className="floating-label">Username</label>
  </div>
              <div className="floating-label-group">
              <input  type="text" className="form-control" required />
                <label className="floating-label">Enter Your Name</label>
              </div>
              <div className="phone">
                <div className="form-group SAweb_selectDrop min-width">
                  <select readOnly value="da" className="form-select" aria-label="Default select example">
                    <option value="">+011</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
          </div>

          <div className="floating-label-group">
  <input type="text"  className="form-control"   required />
    <label className="floating-label">Enter Your Phone Number</label>
  </div>
               
              </div>
              <div className="floating-label-group">
              <input  type="text" className="form-control" required />
                <label className="floating-label">Enter Your Email Address</label>
              </div>
              
               <div className="floating-label-group">
              <input  type="text" className="form-control" required />
                <label className="floating-label">Company Name</label>
              </div>
              
                      <div className="form-group SAweb_selectDrop min-width">
                        <select readOnly className="form-select" aria-label="Default select example">
                          <option value="">Select Location</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                      </div>
      
      
                      <div className="form-group">
              
                        <label className="form-control">Guests</label>
                        <div className="input-group guestCount">
                          
                          <span className="input-group-btn">
                              <button type="button" className="btn btn-default btn-number" disabled="disabled" data-type="minus" data-field="quant[1]">
                                <i className="bi bi-plus"></i>
                              </button>
                          </span>
              <input readOnly={true} type="text" className="form-control input-number" value="01" min="1" max="10" placeholder="Company Name" />

                          <span className="input-group-btn">
                              <button type="button" className="btn btn-default btn-number" data-type="plus" data-field="quant[1]">
                                <i className="bi bi-dash"></i>
                              </button>
                          </span>
                      </div>
                      </div>
      
                      <div className="datesView">

                      <div className="floating-label-group">
              <input  type="text" className="form-control" required />
                <label className="floating-label">Check In Date</label>
              </div>
              <div className="floating-label-group">
              <input  type="text" className="form-control" required />
                <label className="floating-label">Check Out Date</label>
              </div>
                        
                      </div>
      
                      <button type="button" className="btn"> Place Request </button>
      
      
            </form>
            
      
          </div>
         
      
      
      </section> */}


        <Footer />
      </div>


    );
  }
}
export default InternHousing;