


/* Importing the node modules, child components, services and controllers used 
   inside TeslaHome component */
import $ from "jquery";
import MainController from "../../controller/mainController";
import SubFooter from "../../common/subFooter";
import TangerineHeader from "../../common/tangerineHeader";
import { Link } from 'react-router-dom';
import Footer from '../../common/footer';
import GuestHeader from "../../common/guestHeader";
/* TeslaHome Component initialization */
class TangerineHome extends React.Component {
    /* Initializing objects of its TeslaHome class */
    constructor(props) {
        super(props);
    }
    /* It is invoked immediately before mounting occurs */
    componentWillMount() {
        MainController.getInitialInformation();
    }
    /* It is invoked to return html content */
    render() {
        return (
            <div className="corporateHousing_Updates telsa-page-view">
                <GuestHeader />

                <header className="codeconveyHeader cc_image_text_main corporate-h">
                    <div className="container-view ">
                        <img className="img-responsive tangerineImage" src="/assets/newUI-images/tangerine/tangerine-home" width="100%" alt="" />
                        <div className="wow fadeInUp    text-center  img-on-text-center company_bannertxt" data-wow-delay="0.5s">
                            {/* <img src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Company-Nike-Logo.png"} className="nike_img" width="150px" alt="" /> */}
                            <h1>SuiteAmerica is Proud to Offer Temporary Housing to Tangerine Travel</h1>
                            <p>Whether You’re Sourcing for an
                                Individual or a Group, We Can Take Care of Your Housing Needs.
                            </p>
                        </div>
                    </div>
                </header>
                <TangerineHeader />
                <div className="quote-section bg-white">
                    <div className="container">
                        <div className=" text-center col-md-10 col-md-offset-1 wow fadeInUp company_p" data-wow-delay="0.5s">
                            <p>SuiteAmerica’s apartments are beautifully appointed to provide a better environment
                                for optimal work-life balance. With strategic locations in close proximity to worksites
                                and essential services, Tangerine Travel’s road warriors will have a balanced and
                                stress-free experience in a SuiteAmerica accommodation. We invite you to explore this
                                dedicated Tangerine Travel site – your comfort and happiness are our priority.
                                SuiteAmerica looks forward to serving you.
                            </p>
                        </div>
                    </div>

                </div>
                <div className="who_weare_text service_ch_new company_p_height">
                    <div className="container">
                        <h1 className="gotham-book mrg-top-20 mrg-btm-40" style={{ "textTransform": "uppercase" }}>Staying with SuiteAmerica is Easy!</h1>
                        <div className="row rows">
                            <div className="col-md-6 col-sm-6">
                                <div className="wow fadeInUp company_p dual_quote" data-wow-delay="0.5s">


                                    {/* <p>Visit our location page to see the various communities Available in Tesla’s key areas.
                                    </p> */}
                                    <p>Click View Locations to review Tangerine Travel’s preferred properties and locations.</p>
                                    <Link to="/company/tangerine/locations" className="box-slide-btn">VIEW LOCATIONS</Link>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <div className="wow fadeInUp company_p dual_quote" data-wow-delay="0.5s">
                                    <p>Click the Reserve Now button to make a reservation request, or request for more information.</p>
                                    <Link to="/company/tangerine/reservation-form" className="box-slide-btn">RESERVE NOW</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid bg-grey">
                    <div className="container">
                        <div className="bottom-tesla-text">
                            <h1>Responses to COVID-19</h1>
                            <p className="covidpara">SuiteAmerica has invested in new and advanced cleaning technologies and processes.
                                Our enhanced protocols have been adopted for long term change, not just for the
                                short-term. Everyone’s safety, health and wellness are our top priority!</p>
                            <p> <i className="fa fa-circle"></i> <b>SuiteAmerica’s</b> response to COVID-19, <a href="/covid19-safety-measures" target="_blank">Click Here.</a></p>
                            <p> <i className="fa fa-circle"></i> <b>Tangerine Travel’s</b> response to COVID-19, <a href="https://www.tangerinetravel.com/importantinformation" target="_blank">Click Here.</a></p>
                        </div>

                    </div>
                </div>
                <SubFooter />
                <Footer />
            </div>
        );
    }
}
export default TangerineHome;