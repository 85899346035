/* Importing the node modules, child components, services and controllers used 
   inside NikeHeader component */
   import {Link} from 'react-router-dom';   
   import MainController from '../controller/mainController';
   /* NikeHeader Component initialization */
   class NikeHeader extends React.Component {
       /* Initializing objects of its NikeHeader class */
       constructor(props) {
           super(props)
           /* NikeHeader Component State variables Initialization */
           this.state = {
               name: '',
               company: '',
               location: '',
               phone: '',
               email: '',
               bookingDetails: {},
               BookingData: {}
           }   
       }
       /* It is invoked immediately after a component is mounted */
       componentDidMount() {
           MainController.removeClassOnNavCollapse();
       }
       /* It is invoked to return html content */  
       render() { 
           return (
               <div>
            <div className="profile_submenu">
                <div className="container">
                    <nav className="navbar navbar-inverse">
                        <div className="navbar-header">
                            <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#guest-login" aria-expanded="false">
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                            <img src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Company-Nike-Logo.png"} alt="" className="nike_list_img_mo" />
                        </div>
                        <div className="collapse navbar-collapse" id="guest-login">
                            <ul className="nav navbar-nav">
                                <li><Link to="/company/nike/home">Home</Link></li>
                                <li><Link to="/company/nike/whats-included">What’s Included</Link></li>
                                <li><Link to="/company/nike/added-value">Added Value</Link></li>
                                <li><Link to="/company/nike/online-management">Online Management</Link></li>
                                <li><Link to="/company/nike/locations">Locations</Link></li>
                                <li><Link to="/company/nike/reservation-form">Reservation Form</Link></li>
                                <li><Link to="/company/nike/frequent-questions">Frequent Questions</Link></li>
                            </ul>
                            <img src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Company-Nike-Logo.png"} alt="" className="nike_list_img" />
                        </div>
                    </nav>
                </div>
            </div>
            <div className='subHeaderHeight'></div>
            </div>
           );
       }
   }
   export default NikeHeader;