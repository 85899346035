/* Importing the node modules, child components, services and controllers used 
   inside lumentumLocations component */    
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import LumentumHeader from "./common/lumentumHeader";
   import FAQ from "./common/faq";
   import Footer from '../../common/footer';
   import GuestHeader from "../../common/guestHeader";
   import BookingProcess from "../BookingPortal/BookingProcess";
   /* lumentumLocations Component initialization */
   class LumentumLocations extends React.Component {
       /* Initializing objects of its lumentumLocations class */
       constructor(props) {
           super(props)
           this.state={
               companyname:'ServiceNow Intern'
           }
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
           MainController.getInitialInformation();
        }
       /* It is invoked to return html content */
       render() {
           return (
            <div className="corporateHousing_Updates">
                <GuestHeader/>
                <LumentumHeader />
                <BookingProcess/>
                <div className="container-fluid bg-grey">
                    <div className="container">
                        <FAQ />
                    </div>
                </div>
                <SubFooter />
            <Footer/>
            </div>   
           );
       }
   }
   export default LumentumLocations;