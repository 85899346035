const KeyListForm = props => {    
    return (
        <div className="f-contact-wrap mrg-btm-50 mrg-top-40">
        <div className="f-contact-form-style">
           <form>
           <div className="form-group">
           <div className="qwer_bottom">
              <h1>Did you check all keys are working on the front door?</h1>
              <div className="qwer_bottom22">
                 <div className="form-group mrg-right-30">
                 <button onClick={()=>props.buttonToogle('frontDoorKeys')} id="frontDoorKeys" type="button" className="btn btn-lg btn-toggle" data-toggle="button" aria-pressed={props.frontDoorKeys} autoComplete="off" >
                 <div className="handle"></div>
                  </button>           
                 </div>
              </div>
           </div>
        </div>
        <div className="form-group">
        <div className="qwer_bottom">
           <h1>Gate remote batteries checked?</h1>
           <div className="qwer_bottom22">
              <div className="form-group mrg-right-30">
              <button onClick={()=>props.buttonToogle('batteries')} id="batteries" type="button" className="btn btn-lg btn-toggle" data-toggle="button" aria-pressed={props.batteries} autoComplete="off" >
              <div className="handle"></div>
              </button>           
              </div>
           </div>
        </div>
     </div>
     <div className="form-group">
     <div className="qwer_bottom">
        <h1>Internet and Cable TV checked?</h1>
        <div className="qwer_bottom22">
           <div className="form-group mrg-right-30">
           <button onClick={()=>props.buttonToogle('internetTvCabel')} id="internetTvCabel" type="button" className="btn btn-lg btn-toggle" data-toggle="button" aria-pressed={props.internetTvCabel} autoComplete="off" >
           <div className="handle"></div>
           </button>
           </div>
        </div>
     </div>
  </div>
  <div className="form-group sr_keyforms">
  <div className="qwer_bottom">
     <h1>Unit Condition</h1>
     <div className="qwer_bottom22">
        <div className="form-group mrg-right-30" id="cleanParent">
            <button onClick={()=>props.buttonToogle('clean')} id="clean" type="button" className="btn btn-xs btn-toggle" data-toggle="button" aria-pressed={props.clean} autoComplete="off">
                 <div className="handle"></div>
            </button>  
           <span className="switch switch-lg">
            <label htmlFor="switch-lg4">&nbsp;&nbsp;Clean</label>
           </span>
        </div>
        <div className="form-group mrg-right-30" id="lightParent">
            <button onClick={()=>props.buttonToogle('light')} id="light" type="button" className="btn btn-xs btn-toggle" data-toggle="button" aria-pressed={props.light} autoComplete="off">
            <div className="handle"></div>
            </button>  
            <span className="switch switch-lg">
            <label htmlFor="switch-lg5">&nbsp;&nbsp;Light</label>
            </span>
        </div>
        <div className="form-group mrg-right-30" id="heavyParent">
            <button onClick={()=>props.buttonToogle('heavy')} id="heavy" type="button" className="btn btn-xs btn-toggle" data-toggle="button" aria-pressed="false" autoComplete="off">
            <div className="handle"></div>
            </button>  
            <span className="switch switch-lg">
            <label htmlFor="switch-lg6">&nbsp;&nbsp;Heavy</label>
            </span>
        </div>
        <div className="form-group mrg-right-30" id="superHeavyParent">
            <button onClick={()=>props.buttonToogle('superHeavy')} id="superHeavy" type="button" className="btn btn-xs btn-toggle" data-toggle="button" aria-pressed="false" autoComplete="off">
            <div className="handle"></div>
            </button>  
           <span className="switch switch-lg">
           <label htmlFor="switch-lg7">&nbsp;&nbsp;Super Heavy</label>
           </span>
        </div>
     </div>
  </div>
</div>
              <div style={{color:props.color}}>{props.message1}</div>
              <div className="form-group keylist">
                 <select style={{height: '1000px'}} multiple className="form-control selectpicker"  value={props.cc} onChange={(e)=> props.setValue('cc',3,e)}>
                    <option disabled>To</option>
                   {props.driversList.map((row, index) => {
                         return (
                             <option value={row.Email} key={index}>{row.Identifier}</option>
                         )
                     })}
                 </select>
              </div>
              <br/>
              <div className="form-group">
                 <input type="email" className="form-control" id="exampleInputEmail1" placeholder="Extras done in the apartment" value={props.extraAppartment} onChange={(e)=> props.setValue('extraAppartment', 3,e)}/>
              </div>
              <div className="form-group">
                 <textarea className="form-control" id="message" name="message" placeholder="Comment" rows="3" value={props.comment} onChange={(e)=> props.setValue('comment', 3,e)}></textarea>
              </div>
              {props.filesUpload ? 
                <div className="text-center"><span><i className="fa fa-spinner fa-spin"/> please wait file is uploading...!</span></div> : '' }	
                { props.filesLists.length <3 && !props.filesUpload ? 
                <div className="form-group">
                   <input type="file" name="file-7[]" placeholder="jjj" id="file-7" className="inputfile inputfile-6" data-multiple-caption="{count} files selected" multiple onChange={(e)=> props._handleImageChange(2, 0,e)} />
                   <label htmlFor="file-7"><span>Upload Attachment (Doc, PDF, JPG, ect.)</span> <strong><img src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Form-Cloud-Upload.png"} width="45" alt="" /></strong></label>
                </div> 
            : ''}
              {props.filesLists.length>0 ?
                          <div >
                          {props.filesLists.map((row, index) => {
                                 return(
                             <div className="intra_uploadfile"  key={index}>
                                <ul>
                                   <li><span>File Name</span></li>
                                   <li><span>File Size</span></li>
                                </ul>
                                <ul>
                                   <li>{row.FileDetails.name}</li>
                                   <li>{row.FileDetails.size} kb</li>
                                </ul>  
                             </div> )})}
                          </div>:null}
                <div className='text-center' style={{color:'green'}}>{props.message}</div>
             <div className="form-group key_checkbox">
                 <input type="checkbox" id="checkbox-f" name="checkbox"  value={props.flag} onChange={(e) => props.setValue('flag',2,e)}/>
                 <label htmlFor="checkbox-f">Checkbox 6</label>
                 <p className="mrg-left-25">Flag Email as Urgent</p>
              </div>
              <div className='text-center' style={{color:props.color}}>{props.message}</div>

              <div className="text-center mrg-top-20 mrg-btm-30">       
              {props.isSubmitting ? <span><i className="fa fa-spinner fa-spin"/> please wait...!</span> : <button type="button" className="btn star_button mrg-right-15" onClick={()=>props.keySubmit()}>SEND</button>}
              </div>    
               </form>
        </div>
                
     </div>
    );
  };

  export default KeyListForm;