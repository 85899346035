import React, { useEffect, useState } from "react";
import locationService from "../../../services/locationService";
import nikeService from "../../../services/nikeService";
import Loader from "../newinterface/guestPortal/loader";

function CancelHouseKeepingRequest(props) {
  const [isChecked, setIsChecked] = useState(false);
  const [error, setError] = useState("");
  const [invalid, setInvalid] = useState(false);
  const [loader, setLoader] = useState(true);
  const [scheduleId, setScheduleId] = useState(props.match.params.id);
  const [messageData, setMessageData] = useState(null);

  // const scheduleId = props.match.params.id;

  useEffect(() => {
    let data = {
      id: scheduleId,
    };
    let maidInformation = locationService.GetMaidScheduleRemainderInfo(data);
    maidInformation
      .then((result) => {
        if (result.error == "0") {
          const messageData = result.message;
          console.log(messageData.date, "message data");
          setMessageData(messageData); // Set messageData to state
          setLoader(false);
        } else {
          setInvalid(true); // Set messageData to state
          setLoader(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [scheduleId]);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
    setError(""); // Clear any previous error message
  };

  const yesButton = () => {
    if (!isChecked) {
      setError("Please check the checkbox to proceed.");
    } else {
      $("#myModalTwo").addClass("in manualModal");
      $("#myModalOne").removeClass("show manualModal");
    }
  };

  const confimButton = () => {
    $("#myModalThree").addClass("in manualModal");
    $("#myModalTwo").removeClass("in manualModal");

    setTimeout(async () => {
      let userData = {
        name: messageData.guestfirstname,
        date: messageData.date,
        guestEmail: messageData.guestemail,
        gsrEmail:"guestserve@suiteamerica.com",
        subject: `Housekeeping Cancellation - ScheduleId #${messageData.scheduleId} `,
        mail: "CancelHousekeepingService",
      };
      // console.log(user Data, "userData");
      await nikeService.serviceRequest(userData);
    }, 300);
  };
  return (
    <div className="cancelhk">
      {loader ? <Loader /> : ""}
      <div className="bgImage">
        <img
          src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/guestLoginBanner.jpg"
          alt=""
        />
      </div>

      {messageData && (
        <div className="modal show manualModal" id="myModalOne">
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-body px-5 py-5">
                {messageData.status === "Cancelled" ? (
                  <>
                    <p className="d-block text-center m-auto px-3 py-5 note">
                      The housekeeping service scheduled for {messageData.date}{" "}
                      has been canceled. For assistance, please contact{" "}
                      <a href="mailto:guestserve@suiteamerica.com">
                        guestserve@suiteamerica.com
                      </a>
                    </p>
                  </>
                ) : (
                  <>
                    <img
                      className="mb-5 mt-3"
                      src="/assets/images/housekeeping/image-2.png"
                      alt=""
                    />
                    <h1 className="mb-3">
                      You're about to cancel your upcoming housekeeping
                    </h1>
                    <h2 className="mb-4">
                      <img src="/assets/images/housekeeping/cal.png" alt="" />
                      {messageData.date}
                    </h2>
                    <p className="note">
                      Day of service cancellation is subject to a cancellation
                      fee. Please give us 24 hours notice when canceling
                      housekeeping.
                    </p>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        onChange={handleCheckboxChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        I have read the above & understand that if I cancel less
                        than 24 hours in advance. I will be charged a
                        cancellation fee.
                      </label>
                    </div>
                    {error && <span className="text-danger">{error}</span>}
                    <button className="btn theme-btn mt-4" onClick={yesButton}>
                      Yes, Cancel
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {invalid == true && (
        <div className="modal show manualModal" id="myModalOne">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content width100per">
              <div className="modal-body px-5 py-5">
                <div class="alert alert-warning width100per" role="alert">
                  Invalid request
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="modal fade" id="myModalTwo">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            {/* <div className="modal-header border-0 text-right">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
            >
              {" "}
              <img
                src="/assets/images/housekeeping/xmark.svg"
                alt=""
                style={{ width: "20px" }}
              />
            </button>
          </div> */}

            <div className="modal-body one two">
              <img
                className="mb-4 mt-4"
                src="/assets/images/housekeeping/image-2.png"
                alt=""
              />
              <h1 className="mb-4 mt-4">
                Are you sure you would like to cancel your housekeeping ?
              </h1>
              <h2 className="mb-4">
                <img src="/assets/images/housekeeping/cal.png" alt="" />
                {messageData ? messageData.date : ""}
              </h2>
              <button
                className="btn theme-btn mt-5"
                onClick={() => confimButton()}
              >
                Yes, Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="myModalThree">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            {/* <div className="modal-header border-0 text-right">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
            >
              <img
                src="/assets/images/housekeeping/xmark.svg"
                alt=""
                style={{ width: "20px" }}
              />
            </button>
          </div> */}

            <div className="modal-body one">
              <img
                className="mb-5 mt-5"
                src="/assets/images/housekeeping/circle-check.svg"
                alt=""
                style={{ width: "75px" }}
              />
              <h3 className="mb-5 mt-5">
                You have successfully canceled your upcoming housekeeping
              </h3>
              <p className="questions ">
                Questions?<a href="#"> 800.367.9501</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CancelHouseKeepingRequest;
