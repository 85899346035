/* Importing the node modules, child components, services and controllers used 
   inside lumentumHeader component */
   import {Link} from 'react-router-dom';   
   import MainController from '../../../controller/mainController';
   /* lumentumHeader Component initialization */
   class LumentumHeader extends React.Component {
       /* Initializing objects of its lumentumHeader class */
       constructor(props) {
           super(props)
           /* lumentumHeader Component State variables Initialization */
           this.state = {
               name: '',
               company: '',
               location: '',
               phone: '',
               email: '',
               bookingDetails: {},
               BookingData: {}
           }   
       }
       /* It is invoked immediately after a component is mounted */
       componentDidMount() {
           MainController.removeClassOnNavCollapse();
       }
       /* It is invoked to return html content */  
       render() { 
           let img='../../assets/images/lumentum_logo.svg'
           return (
               <div>
            <div className="profile_submenu">
                <div className="container">
                    <nav className="navbar navbar-inverse">
                        <div className="navbar-header">
                            <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#guest-login" aria-expanded="false">
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                            <img src={img} alt="" className="lumentum_list_img_mo" />
                        </div>
                        <div className="collapse navbar-collapse" id="guest-login">
                            <ul className="nav navbar-nav">
                                <li><Link to="/company/lumentum/home">Home</Link></li>
                                <li><Link to="/company/lumentum/whats-included">What’s Included</Link></li>
                                <li><Link to="/company/lumentum/added-value">Added Value</Link></li>
                                <li><Link to="/company/lumentum/online-management">Online Management</Link></li>
                                <li><Link to="/company/lumentum/locations">Locations</Link></li>
                                <li><Link to="/company/lumentum/reservation-form">Reservation Form</Link></li>
                            </ul>
                            <img src={img} alt="" className="lumentum_list_img" />
                        </div>
                    </nav>
                </div>
            </div>
            <div className='subHeaderHeight'></div>
            </div>
           );
       }
   }
   export default LumentumHeader;