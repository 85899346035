import blogsData from "./blogsData.js";
import BlogHeader from "../newinterface/blogHeader";
import Footer from "../newinterface/footer";
import FiltersAndSearch from "./filtersAndSearch";
import FullBlogPage from "./fullBlogPage";

class individualBlogPage extends React.Component {
  constructor(props) {
    super(props);
    this.blogTitle = this.props.match.params.blogTitle;
    this.handleBlogSelect = this.handleBlogSelect.bind(this);
    this.navigateTo = this.navigateTo.bind(this);
    this.handleFilterByCategory = this.handleFilterByCategory.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  handleBlogSelect(blog) {
    if (!blog) {
      this.navigateTo("/blog");
      return;
    }
    const hiphenatedTitle = this.replaceSpacesWithHyphens(blog.title);
    this.navigateTo(`/blog/${hiphenatedTitle}`);
  }

  navigateTo(path) {
    this.props.history.push(path);
  }

  handleFilterByCategory(category) {
    this.navigateTo(`/blog?category=${category}`);
  }

  handleSearch(searchText) {
    this.navigateTo(`/blog?searchText=${searchText}`);
  }

  componentDidUpdate() {
    this.blogTitle = this.props.match.params.blogTitle;
  }

  replaceSpacesWithHyphens(inputString) {
    return inputString.trim().replace(/[\s ?]+/g, "-");
  }

  render() {
    let selectedBlog;
    for (let index = 0; index < blogsData.length; index++) {
      const blog = blogsData[index];
      const hiphenatedTitle = this.replaceSpacesWithHyphens(blog.title);
      if (hiphenatedTitle === this.blogTitle) {
        selectedBlog = blog;
      }
    }
    return (
      <div className="SAweb_updateUi">
        <BlogHeader />

        <FiltersAndSearch
          handleFilterByCategory={this.handleFilterByCategory}
          handleSearch={this.handleSearch}
          handleBlogSelect={this.handleBlogSelect}
          navigateTo={this.navigateTo}
        />

        <FullBlogPage
          selectedBlog={selectedBlog}
          blogsData={blogsData}
          handleBlogSelect={this.handleBlogSelect}
        />

        <Footer />
      </div>
    );
  }
}

export default individualBlogPage;
