
import Footer from '../../newinterface/footer';
import Header from '../../newinterface/header';
import LocationController from "../../newinterface/locationController";
import EncryptDecrypt from '../../../../services/encryptDecrypt';
import LocationService from '../../../../services/locationService';
import Spinner from '../../../common/spinner';
import OwlCarousel from 'react-owl-carousel';
import Cities from '.././json/cities.json'
import { Progress, Spin, Select } from 'antd';
import RequestCallback from '.././requestCallback';
import FullPopup from '.././fullPopup';
import Loader from '.././guestPortal/loader';
// import TravelModel from './model/travelModel';
import TravelModel from './travelModel';
import moment from 'moment';
const { Option } = Select;
class NewLocations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            communityData: [],
            startLimit: 0,
            loadMoreButton: false,
            communityName: '',
            selectedaddress: '',
            selectedAmenties: '',
            selectedcity: '',
            filterData: '',
            spinLoader: false,
            map: '',
            activeNumber: -1,
            amenitiesList: [],
            amenitiesId: [],
            address: "",
            cityId: "",
            communityName: "",
            companyname: "",
            countryId: "",
            latitude: "",
            longitude: "",
            startLimit: 0,
            stateId: "",
            userid: "",
            // loadingStatus: false,
            imgloader: true,
            communityImages: [],
            addresstext: false,
            communitytext: false,
            cities: Cities,
            selectedAmentiesData: [],
            selectedcommunityName: null,
            fullpopdata: {},
            allcitisdata: [],

            checkin: '07/10/2025',
            checkout: "11/30/2025",
            loadingStatus: false,
            communities: [],
            addressLat: 37.3209401,
            addressLng: -122.0240663,
            mapLoader: false

        }
        this.TravelModel = new TravelModel(this, {})
        this.savedSearchFilter = this.savedSearchFilter.bind(this);
        this.clearStateValues = this.clearStateValues.bind(this);
        this.getSearchResult = this.getSearchResult.bind(this);
        this.savedSearch = this.savedSearch.bind(this);
        this.Search = this.Search.bind(this);
        this.refreshSearchResult = this.refreshSearchResult.bind(this);
        this.amenitiesSelectlist = this.amenitiesSelectlist.bind(this);
        this.onClickCity = this.onClickCity.bind(this);


    }

    async componentDidMount() {
        await this.TravelModel.emptymap()
        await this.TravelModel.getCommunities()
    }

    async componentDidUpdate() {
        // if (this.state.addressLat != 37.7749295 && this.state.addressLng != -122.4194155) {
        //     console.log('updateddd', this.state.addressLat, this.state.addressLng)
        //     // await this.TravelModel.getCommunities()
        // }
    }
    /* search functionality starting*/
    async setLocationAddress(e) {
        e.preventDefault();
        const _this = this;
        await this.setState({ address: e.target.value, addresstext: true });
        await this.handleFocus()
        const icon = 'https://s3-us-west-1.amazonaws.com/suiteam-website/Destination-Services/SuiteAme' +
            'rica-Destination-Services-Icon-Map-Marker-Green.png';
        var places = new google.maps.places.Autocomplete(document.getElementById('city-input1'));
        google.maps.event.addListener(places, 'place_changed', function () {
            var place = places.getPlace();
            var placeDetails = {
                locality: 'long_name',
                administrative_area_level_1: 'short_name',
                country: 'long_name',
            };
            place.address_components.forEach(i => {
                var addressType = i.types[0];
                if (placeDetails[addressType]) {
                    var val = i[placeDetails[addressType]];
                    placeDetails[addressType] = val;
                }
            })
            let relocatedAddress = place.formatted_address;
            const newLat = place.geometry.location.lat();
            const newLng = place.geometry.location.lng();
            _this.setState({ latitude: newLat, longitude: newLng, address: relocatedAddress });
        });
    }

    async Search() {
        if (this.state.latitude != '' || this.state.longitude != '' || this.state.communityName != '' || this.state.amenitiesId.length != 0
            || this.state.address != '' || this.state.addresstext == true || this.state.communitytext == true) {
            const webGuest = localStorage.getItem('_u_web');
            const response = await EncryptDecrypt.decryptData({ 'type': webGuest })
            const userData = response.recordset[0]

            let companyName = this.state.companyName == undefined ? '' : this.state.companyName;

            switch (companyName) {
                case 'Nike':
                    companyName = 'Nike';
                    break;
                case 'NikeIntern':
                    companyName = 'NikeIntern'
                    break;
                case 'Adidas Intern':
                    companyName = 'Adidas Intern'
                    break;
                case 'ServiceNow Intern':
                    companyName = 'ServiceNow Intern'
                    break;
                case 'Adobe':
                    companyName = 'Adobe'
                    break;
                case 'Nike Apprenticeship':
                    companyName = 'Nike Apprenticeship'
                    break;
                case 'Lumentum':
                    companyName = 'Lumentum'
                    break;
                case 'Tesla':
                    companyName = 'Tesla';
                    break;
                default:
                    companyName = 'suiteamerica';
            }
            if (this.state.address != '' || this.state.stateId != 0 || this.state.cityId != 0 || this.state.communityName != '' || (this.state.pets || !this.state.pets)
                || this.state.communitytext == true || this.state.addresstext == true) {
                // this.setState({ loadingStatus: true })
                var amenitiesArray = this.state.amenitiesId;
                var amenitesString = amenitiesArray.toString();
                const communitySearch = {
                    startLimit: 0,
                    companyname: companyName,
                    latitude: this.state.address != "" ? this.state.latitude : "",
                    longitude: this.state.address != "" ? this.state.longitude : "",
                    address: this.state.address,
                    state_id: this.state.stateId,
                    city_id: this.state.cityId,
                    amenitiesId: amenitesString,
                    communityName: this.state.communityName == undefined ? '' : this.state.communityName,
                    countryId: this.state.countryId,
                    userid: (userData != undefined && Object.keys(userData).length > 0) ? userData.Id : ''
                }
                const SearchResult = await LocationService.getCommunityData(communitySearch)
                if (SearchResult) {
                    this.setState({
                        addresstext: false,
                        communitytext: false
                    })
                }
                await this.savedSearch(communitySearch, SearchResult, this.state.latitude, this.state.longitude)
            }
        }
    }

    async setValue(e) {
        var object = {};
        object[e.target.name] = e.target.value;
        this.setState(object);
        if (e.target.name == 'countryId') {
            this.stateList(e.target.value)
        }
        if (e.target.name == 'stateId') {
            this.cityList(e.target.value)
        }
        this.setState({
            communitytext: true
        })
    }
    async amenitiesSelectlist(value) {
        let updatedPosts = value
        let ids = []
        for (var i = 0; i < updatedPosts.length; i++) {
            ids.push(JSON.parse(updatedPosts[i]).TableId);
        }
        await this.setState({ amenitiesId: ids })
        this.setState({
            selectedAmentiesData: value
        })
    }




    async refreshSearchResult() {

        if (this.state.amenitiesId.length > 0 || this.state.communityData.length > 10 || this.state.address != '' || this.state.communityName != '' ||
            this.state.addresstext == true || this.state.communitytext == true) {
            // this.setState({
            //     loadingStatus: true
            // })
            await this.setState({ selectedAmentiesData: [] })
            document.getElementById("city-input1").value = ''
            $('#city-input').val('');
            $('#selectedamenities').val('');
            const limitData = {
                "startLimit": 0,
                "companyname": "suiteamerica",
                "latitude": "",
                "longitude": "",
                "address": "",
                "state_id": "",
                "city_id": "",
                "amenitiesId": "",
                "communityName": "",
                "countryId": "",
                "userid": "",
                "cities": []
            }

            // await this.mapmethod(limitData, "refresh")
        }
        if (this.state.address != '' || this.state.countryId != '' || this.state.stateId != '' || this.state.cityId != '' || this.state.communityName != '' || this.state.amenitiesId.length != 0) {

            this.setState({
                countryId: '',
                stateId: '',
                cityId: '',
                address: '',
                statename: '',
                latitude: '',
                longitude: '',
                communityName: '',
                cityList: [],
                amenitiesId: [],
                stateList: [],
            });
            document.getElementById("city-input1").value = ''
            $('#city-input').val('');
            $('#selectedamenities').val('');
            const limitData = {
                "startLimit": 0,
                "companyname": "suiteamerica",
                "latitude": "",
                "longitude": "",
                "address": "",
                "state_id": "",
                "city_id": "",
                "amenitiesId": "",
                "communityName": "",
                "countryId": "",
                "userid": "",
                "cities": []
            }

            await this.mapmethod(limitData, "refresh")
        }

    }
    /* search functionality ending*/
    /*map method starting */




    async handleloadmore() {
        await this.setState({
            loadMoreButton: true,
            startLimit: this.state.startLimit + 10
        })
        const limitData = {
            "startLimit": this.state.startLimit,
            "companyname": "suiteamerica",
            "latitude": "",
            "longitude": "",
            "address": "",
            "state_id": "",
            "city_id": "",
            "amenitiesId": "",
            "communityName": "",
            "countryId": "",
            "userid": "",
            "cities": []
        }
        await this.mapmethod(limitData, "more")
        var element = document.getElementById("loadmoredown");
        element.scrollTop = element.scrollHeight;
    }
    async setValueAmenities(event) {
        this.setState({ selectedAmenties: event.target.value });
    }
    savedSearchFilter(savedSearchData) {
        this.setState({ savedSearchData: [savedSearchData], less: 0, more: 1 })
    }
    clearStateValues() {
        this.setState({ savedSearchData: [], less: 0, more: 1, default: 1, searchLatitude: '', searchLongitude: '' })
    }
    async getSearchResult() {
        this.setState({
            spinLoader: true
        })
        const limitData = {
            "startLimit": 0,
            "companyname": "suiteamerica",
            "latitude": "",
            "longitude": "",
            "address": "",
            "state_id": "",
            "city_id": "",
            "amenitiesId": "",
            "communityName": "",
            "countryId": "",
            "userid": "",
            "cities": []
        }

        await this.mapmethod(limitData, "search")
    }
    async mapmethod(limitData, type) {
        if (type !== 'search') {
          const communityapiData = await LocationController.getCommunityData(limitData)
          if (type == 'refresh') {
            this.setState({
              communityData: communityapiData,
              loadingStatus: false
            })
          }
    
          if (type == "did") {
            await this.setState({
              communityData: communityapiData,
              spinLoader: false,
              loadingStatus: false
            })
          } else if (type == "more") {
            let totalData = this.state.communityData;
            totalData.push(...communityapiData);
            await this.setState({
              loadMoreButton: false,
              communityData: totalData,
              loadingStatus: false
            })
    
          }
        }
    
    
        var _this = this;
        let locations = this.state.communityData.filter(row => row.latitude !== null).map((row, index) => {
          var address;
          const favourite = (row.favouritestatus != undefined && row.favouritestatus != null && row.favouritestatus != false)
            ? "fa fa-heart"
            : "";
          const color = (row.favouritestatus != undefined && row.favouritestatus != null && row.favouritestatus != false)
            ? 'red'
            : 'white';
    
          address = '<div id="content">' +
            '<div id="siteNotice">' +
            '</div>' +
            '<div id="bodyContent">' +
            '<div class="bodycontentimg"><img width="100px" height="100px" src="' + row.image_name + '" /> <i class="' + favourite + '" style="color:' + color + '"></i> </div>' +
            '<h1>' + row.community_name + '</h1>' +
            '<p>' + row.address + ', ' + row.city_name + ', ' + row.state_name + ' ' + row.ZipCode + '</p>' +
            '<a href="https://www.google.com/maps?daddr=' + row.latitude + ',' + row.longitude + '" target="_blank">Get driving directions</a>' +
            '</div>' +
            '</div>';
          return [address, row.latitude, row.longitude, index]
        })
        locations = locations.length > 0 ?
          locations : ["655 S. Fair Oaks", 0.00, -0.00, 0]
        if (this.state.communityData.length > 0) {
    
          const markers = []
          const map = this.map = new google.maps.Map(document.getElementById('map'), {
            zoom: 10,
            zoomControl: false,
            center: new google.maps.LatLng(locations[0][1], locations[0][2]),
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            backgroundColor: "#eeeeee",
            disableDefaultUI: true,
          });
          var style = [
            {
              "elementType": "geometry", "stylers": [
                { "saturation": -120 }
              ]
            }
          ];
          var mapType = new google.maps.StyledMapType(style, { name: "Grayscale" });
          map.mapTypes.set('grey', mapType);
          map.setMapTypeId('grey');
          var customZoomInControlDiv = document.createElement('div');
          this.CustomZoomInControl(customZoomInControlDiv, map);
          customZoomInControlDiv.index = 1;
          map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(customZoomInControlDiv);
          var infowindow = new google.maps.InfoWindow({});
          var i;
          for (i = 0; i < locations.length; i++) {
            const marker = this.marker = new google.maps.Marker({
              position: new google.maps.LatLng(locations[i][1], locations[i][2]),
              icon: '../assets/newUI-images/location_pin.png',
              map: map
            });
            google.maps.event.addListener(marker, 'click', (function (marker, i) {
              return async function () {
                infowindow.setContent(locations[i][0]);
                infowindow.open(map, marker);
                await this.setState({
                  activeNumber: this.state.communityData[i].community_name
                })
              }.bind(this)
            }.bind(this))(marker, i));
    
            markers.push(marker)
    
          }
          await this.setState({
            markerList: markers
    
          })
          _this.setState({
            map
          });
        }
    
      }
      CustomZoomInControl(controlDiv, map) {
        // Set CSS for the control border
        var controlUI = document.createElement("div");
    
        controlUI.style.cursor = "pointer";
        controlUI.style.display = "-webkit-inline-box";
        controlUI.style.margin = "18px 10px";
        controlUI.style.width = "30px";
        controlUI.style.height = "30px";
        controlDiv.appendChild(controlUI);
        // Set CSS for the control interior
        var controlText = document.createElement("div");
        controlText.innerHTML += `<img style="height:30px;width:30px;margin:0"  src='/assets/newUI-images/plus.svg' />`;
        controlUI.appendChild(controlText);
    
        // Setup the click event listeners
        google.maps.event.addDomListener(controlUI, "click", function () {
          map.setZoom(map.getZoom() + 1);
        });
    
        // Set CSS for the control border
        var controlUILeft = document.createElement("div");
    
        controlUILeft.style.display = "-webkit-inline-box";
        controlUILeft.style.marginLeft = "10px";
        controlUILeft.style.cursor = "pointer";
        controlUILeft.style.width = "30px";
        controlUILeft.style.height = "30px";
        controlDiv.appendChild(controlUILeft);
    
        var controlTextLeft = document.createElement("div");
    
        controlTextLeft.innerHTML += `<img style="height:30px;width:30px;margin:0"  src='/assets/newUI-images/minus.svg' />`;
        controlUILeft.appendChild(controlTextLeft);
    
        // Setup the click event listeners
        google.maps.event.addDomListener(controlUILeft, "click", function () {
          map.setZoom(map.getZoom() - 1);
        });
      }
    async savedSearch(savedSearchData, communityData, searchLatitude, searchLongitude) {
        await this.setState({ savedSearchData, communityData, searchLatitude, searchLongitude, default: 0, spinLoader: false })
        const limitData = {
            "startLimit": 0,
            "companyname": "suiteamerica",
            "latitude": "",
            "longitude": "",
            "address": this.state.address,
            "state_id": "",
            "city_id": "",
            "amenitiesId": "",
            "communityName": "",
            "countryId": "",
            "userid": "",
            "cities": []
        }
        await this.mapmethod(savedSearchData, 'search')
    }


    async handleSearch() {
        let filterData = this.state.communityData.filter((obj) => obj.city_name.toLowerCase() == this.state.selectedcity.toLowerCase() || obj.community_name == this.state.communityName.trim())
        await this.setState({
            filterData: filterData
        })
    }


    /* map functionality ending*/
    async handleOpenCarousel(row) {
//console.log("for images", row.interiorimages);
        let communityImages = row.interiorimages != null && row.interiorimages != undefined ? (row.interiorimages).split(',') : []
        if (row.CommunityImages != null && row.CommunityImages != undefined) {
            communityImages.unshift({ 'image_name': row.image_name })
        }
        //console.log("for images", communityImages);
        if (communityImages.length) {
            let popUPimages = [];
            communityImages.forEach(element => {
                popUPimages.push({
                    image_name:element
                })
            });
            
            row.CommunityImages = JSON.stringify(popUPimages);
            await this.setState({ fullpopdata: row, communityImages: communityImages, imgloader: false })
            setTimeout(function () {
                $('#fullmodel-Carousel1').modal('show');
            }.bind(this), 500);

        }
    }
    async handleclose() {
        $('document').ready(function () {
            $('#smallmodel-Carousel1').modal('hide');
        });
    }
    async handleFocus() {
        if (this.state.address == '') {
            $("#showpopup").addClass('popover__wrapper')
        } else {
            let addres = this.state.address
            let arr = this.state.allcitisdata
            let obj = arr.find(o => o.city == addres);
            let keyslenth = obj != undefined ? Object.keys(obj).length : {}
            if (keyslenth > 0) {
                $("#showpopup").addClass('popover__wrapper')
            } else {
                $("#showpopup").removeClass('popover__wrapper')
            }
        }
        await $("#city-input1").popover({
            html: true,
            content: function () {
                return $('#popp').html();
            }
        })
    }

    async handleRemovePop() {
        await $("#city-input1").popover('hide')
    }

    async _handleKeyDown1(e) {

        await $("#city-input1").popover('hide')
    }

    async onClickCity(data) {
        await this.setState({ address: data.city, latitude: "", longitude: "" })
        if (this.state.address == 'Bay Area') {
            this.setState({ latitude: 37.8271784, longitude: -122.2913078 })
        }
        if (this.state.address == 'New York City') {
            this.setState({ latitude: 40.7127753, longitude: -74.0059728 })
        }


    }
    handleClick(row) {

        window.open('/instant-booking-details/' + row.community_name.replace(/\/| /g, '-') + '-' + row.masterId, '_blank');

        // let communityName = communityname
        // this.setState({
        //     selectedcommunityName: communityName
        // })
        // $('#sideQuote').addClass('active');
        // $('.overlay').addClass('active');
        // $('.collapse.in').toggleClass('in');
        // $('a[aria-expanded=true]').attr('aria-expanded', 'false');
    }
    render() {

        let communityData = this.state.communityData
        let placeholder = this.state.selectedAmentiesData.length == 0 ? "Filters" : "Filters ( " + this.state.selectedAmentiesData.length + " )"
        let addclass = this.state.selectedAmentiesData.length > 4 ? "ulDropdown" : ""

        return (
            <div className="SAweb_updateUi">

                <Header />
                <section className="SAweb_searchCom my-5">
                    <div className="SAweb_container_searchPage">
                        <div className={"row inputSearch align-items-center " + addclass}>
                            <div className="col-auto">
                                <div className="form-group SAweb_selectDrop min-width popoverView">
                                    <div className="requestPlaceHolder" id="showpopup">
                                        <input
                                            onFocus={() => this.handleFocus()}
                                            value={this.state.address}
                                            onBlur={() => this.handleRemovePop()}
                                            id="city-input1"
                                            autoComplete="off"
                                            type="text"
                                            onKeyPress={(e) => this.handleFocus(e)}
                                            className="form-control popover_onfocus"
                                            name="search"
                                            placeholder="City or Address"
                                            onChange={(e) => this.setLocationAddress(e)} />
                                        <div className="popover__content ">
                                            <div >
                                                {Object.keys(this.state.cities).map((row, index) => {
                                                    return (
                                                        <div className="SAweb_nationlFootprint" key={index}>
                                                            <h2>{this.state.cities[row][0].statename}</h2>
                                                            <ul >
                                                                {this.state.cities[row].map((row, i) => {
                                                                    return (
                                                                        <li className="poH" key={row.city_id} style={{ cursor: 'pointer' }}
                                                                            onClick={() => this.onClickCity(row)}>
                                                                            <a href="javascript:" onClick={() => this.onClickCity(row)}>
                                                                                <div className='pic'>
                                                                                    <img src={row.image} alt="" /></div>
                                                                                <span>{row.city}</span> </a> </li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </div>)
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-auto requestPlaceHolder">
                                <div className={'SAweb_selectDrop min-width'}>
                                    <Select mode="tags" placeholder={placeholder} value={this.state.selectedAmentiesData}
                                        onChange={this.amenitiesSelectlist}>
                                        {Array.isArray(this.state.amenitiesList) && this.state.amenitiesList.map((row, index) => {
                                            return (<Option key={index} value={JSON.stringify(row)} >{(row.amenities_name == "On transit Line" ? "On Transit Line" : row.amenities_name)}</Option>
                                            )
                                        })}
                                    </Select>
                                </div>
                            </div>

                            <div className="col-auto">
                                <div className="form-group SAweb_selectDrop min-width floating-label-group">
                                    <div>
                                        <div className="searchBtns">
                                            <button
                                                className="btn black-btn"
                                                type="button"
                                                onClick={this.Search}>Search</button>
                                            <button
                                                type="button"
                                                className="btn black-btn"
                                                onClick={this
                                                    .refreshSearchResult}>
                                                <span> <i className="fa fa-refresh"></i></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className=" SAweb_container_searchPage">

                        <div className="searchboxTails mb-4">
                            {this.state.loadingStatus ?
                                <div className="items">
                                    <div className="property_loader"><Loader /></div>
                                </div>
                                :
                                // !this.state.loadingStatus ?
                                <div className="items">
                                    <div className="SAweb_searchResults">
                                        <h4>{this.state.communities.length} Results Found</h4>
                                    </div>
                                    <div id="loadmoredown" className="SAweb_searchResultsItems">
                                        {this.state.communities.map((row, index) =>
                                            <div key={index}>
                                                <div>
                                                    <div className="pic">
                                                        <img onClick={() => this.TravelModel.flyToPlace(row, index, 'locations')} src={row.banner_image} alt="" width="100%" />
                                                        <button type="button" className="btn" onClick={() => { this.handleOpenCarousel(row) }}>{row.interiorimages !== null ? (row.interiorimages).split(',').length + 1 : 0} Photos </button>
                                                    </div>
                                                    <div className="textView">
                                                        <div className='d-flex justify-content-between'>
                                                            <h4><a href={'/instant-booking-details/' + row.community_name.replace(/\/| /g, '-') + '-' + row.masterId} target="_blank">{row.community_name}</a></h4>
                                                            <h4>ID: {row.masterId}</h4>
                                                        </div>
                                                        <h4 style={{ textTransform: 'capitalize' }}>{(row.address).toLowerCase()},{" " + (row.city_name).toLowerCase()}</h4>
                                                        <div className='listing_amenities'>
                                                            <div className='amenity_first'>
                                                                <i className="fa fa-bed" aria-hidden="true"></i> <span>{row.bedrooms + ' ' + 'bed'}</span>
                                                            </div>
                                                            <div className='amenity' >
                                                                <i className="fa fa-bath" aria-hidden="true"></i><span>{row.bathrooms + ' ' + 'bath'}</span>
                                                            </div>
                                                            <div className='amenity'>
                                                                <i class="fa fa-arrows" aria-hidden="true"></i><span>{row.sqrfeet + ' ' + 'ft²'}</span>
                                                            </div>
                                                        </div>
                                                        <p> <a href="#" className='btn black-btn' onClick={() => this.handleClick(row)}>Request To Book</a> </p>
                                                        <div className='available_date' ><span >Available form: {moment(row.availabilityStartDate).format('DD MMM YYYY')}</span></div>

                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                </div>

                            }

                            <div className="items">

                                <div className="lc_map">
                                    <div className={this.state.mapLoader ? 'map-on-loader' : 'map-on-loader d-none'}>

                                    </div>
                                    <div
                                        id={'map'}
                                        className="map-alignment"></div>
                                </div>
                            </div>
                        </div>
                    </div>


                </section>

                <div id="smallmodel-Carousel1" className=" modal fade SAweb_modalPoup Saweb_MorePhotosPopup " role="dialog" data-backdrop="static">
                    <div className="modal-dialog modal-lg modal-dialog-centered modal-lg-2">
                        <div className="modal-content notification-popup borderRadius"
                        >
                            <div className="modal-header border-0" >
                                <button type="button" className="btn close" data-dismiss="modal" aria-label="Close"> <i className="bi bi-x"></i>  </button>
                            </div>
                            <div className="">

                                
                                {this.state.communityImages.length > 0 &&
                                    !this.state.imgloader ?
                                    <div className="">
                                        <Spinner fontSize="35px" />
                                    </div> :
                                    <div className="Saweb_MorePhotosSlider">

                                        <OwlCarousel className="owl-theme owl-carousel"
                                            {...{
                                                loop: false, nav: true, dots: true, items: 1,
                                                navContainerClass: 'owl-buttons',
                                                navText: ["<i class='glyphicon glyphicon-menu-left'></i>",
                                                    "<i class='glyphicon glyphicon-menu-right'></i>"],
                                                responsive: {
                                                    600: { items: 1, nav: true, loop: false },
                                                    767: { items: 1, nav: true, loop: false },
                                                    992: { items: 1, nav: true, loop: false },
                                                    1199: { items: 1, nav: true, loop: false }
                                                }
                                            }}>

                                            {this.state.communityImages.map((row, index) => {
                                                return (
                                                    <div className="item" key={index}>
                                                        <img className="img-responsive" src={row.image_name} alt={row} />
                                                    </div>
                                                )
                                            })}
                                        </OwlCarousel>

                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>




                <RequestCallback selectedcommunityName={this.state.selectedcommunityName} />
                <Footer />
                <FullPopup fullpopdata={this.state.fullpopdata} />




            </div>
        );
    }
}
export default NewLocations;