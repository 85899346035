/* Importing the node modules, child components, services and controllers used 
   inside WorldBankHome component */    
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import {Link} from 'react-router-dom';
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   import WorldBankHeader from "../../common/worldBankHeader";
   
   /* WorldBankHome Component initialization */
   class WorldBankHome extends React.Component {
       /* Initializing objects of its WorldBankHome class */
         constructor(props) {
           super(props);  
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
           MainController.getInitialInformation();
         }
       /* It is invoked to return html content */
       render() {
           return (
            <div className="corporateHousing_Updates">
                <GuestHeader/>
                <WorldBankHeader/>
                <header className="codeconveyHeader cc_image_text_main corporate-h video-wrapper worldbnk_banner">
                    <img className="img-responsive" src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/world-bank/DC-Skyline.png" width="100%" alt=""  />
                    <div className="wow fadeInUp img-on-text top30 width600 text-center img-on-text-center-w company_bannertxt" data-wow-delay="0.5s">
                        <h1>SuiteAmerica is Proud to offer Temporary Housing to The World Bank</h1>                        
                        <center><img src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/world-bank/world-bank-logo.png" alt="The World Bank" /></center>
                        <p>Whether You’re an Individual or Group, We Can Take Care of Your Housing Needs.</p>
                    </div>
                </header>
                <div className="bg-white mrg-top-50">
                    <div className="container">
                        <div className="col-md-12 wow fadeInUp" data-wow-delay="0.5s">
                            
                            <p className="text-left">These fully furnished and equipped apartments are offered at daily rates and are larger and better suited for long term stay as opposed to hotels. Averaging 600 sq. ft. or larger. SuiteAmerica’s corporate apartments are fully furnished and offer the amenities of a home, making long term stays as comfortable as possible. As a preferred vendor to The World Bank, SuiteAmerica is here to help you with your temporary housing needs and can take your reservations directly.
                            </p>
                            
                        </div>
                        <div className="text-center text-red">
                            <h1 className="mrg-btm-30">Staying With SuiteAmerica Is Easy!</h1>
                            <div className="service_ch_new stying_hl_bnk mrg-btm-60">
                                
                                    <div className="col-md-6 col-sm-6">
                                        <div className="wow fadeInUp company_p dual_quote" data-wow-delay="0.5s">
                                            <p className="number">1</p>
                                            <p>Simply visit our Locations page to see the various communities available for your temporary stay in The World Bank's key areas. 

                                            </p>
                                            <Link to="/world-bank/locations" className="btn btn-red">VIEW LOCATIONS</Link>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        <div className="wow fadeInUp company_p dual_quote" data-wow-delay="0.5s">
                                        <p className="number">2</p>
                                            <p>Once you have chosen or would like more information 
                                                about other communities, click on the Reservation Form 
                                                to send us your information or Contact your 
                                                SuiteAmerica Relocation Representative.

                                            </p>
                                            <Link to="/world-bank/requestReservation" className="btn btn-red">RESERVE NOW</Link>
                                        </div>
                                    </div>
                                    <div className="clearfix"></div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <SubFooter/>
                <Footer/>
            </div>   
           );
       }
   }
   export default WorldBankHome;