/* Importing the node modules, child components, services and controllers used 
   inside serviceNowInternApartmentTour component */ 
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import ServiceNowInternHeader from "../../common/serviceNowInternHeader";
   import ContactUs from "./common/contactUs";
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   import ServiceNowInternFooter from "./common/serviceNowInternFooter";   
   /* serviceNowInternApartmentTour Component initialization */
   class ServiceNowInternApartmentTour extends React.Component {
       /* Initializing objects of its serviceNowIntern class */
       constructor(props) {
           super(props);
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
           MainController.getInitialInformation();
        }
       /* It is invoked to return html content */
       render() {   
           return (
            <div className="corporateHousing_Updates">
                <GuestHeader/>
                <header className="codeconveyHeader cc_image_text_main corporate-h">
                    <img className="img-responsive" src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/servicenow/Apartment Tour - ServiceNow.jpg" width="100%" alt=""  />
                    <div className="wow fadeInUp img-on-text top30 width600 text-center left50 img-on-text-center" data-wow-delay="0.5s">
                        <h1>What's included in a standard SuiteAmerica Intern Apartment?</h1>
                    </div>
                </header>
                <ServiceNowInternHeader />
                <div className="quote-section bg-white">
                    <div className="container">
                        <div className="text-center col-md-10 col-md-offset-1 wow fadeInUp company_p" data-wow-delay="0.5s">
                            <p>We’ll set up your apartment with everything you need to move in and be comfortable. 
                                Do you need something we didn’t include? Give us a call or send us an email telling us what you need. We’ll make every effort to have it waiting for you when you move in to your new home.
                            </p>
                            <h2>Tour a sample of a SuiteAmerica Intern Apartment Now!</h2>
                            <div className="form-group row mrg-top-30">
                            </div>
                            <hr className="hr" />
                        </div>             
                        <div className="col-md-12 boxed_list">
                                <h4>STANDARD ITEMS TYPICALLY INCLUDED IN YOUR NEW INTERN APARTMENT</h4>
                                <ul>
                                    <li>Apartment Rent</li>
                                    <li>All Furnishings</li>
                                    <li>Housewares</li>
                                    <li>Linens</li>
                                    <li>Cable Television</li>
                                    <li>Microwave Oven</li>
                                    <li>DVD Player</li>
                                    <li>Utilities*</li>
                                    <li>Local Phone Service</li>
                                    <li>Suite Telephone</li>
                                    <li>Welcome folder and local information</li>
                                    <li>*A utility allowance of $100/mo is included</li>
                                </ul>
                            </div>
                    </div>
                    <div className="container-fluid mrg-btm-50 mrg-top-50">
                        <div className="img-header row" style={{backgroundImage:"url(https://s3-us-west-1.amazonaws.com/suiteam-website/Website-Images/SuiteAmerica-Company-Nike-Internship-Furniture.jpg)"}}>
                            <h2>Furniture</h2>
                        </div>
                    </div>
                    <div className="container">
                        <div className="col-md-12 boxed_list">
                                <h4>DINING ROOM</h4>
                                <ul>
                                    <li>Dining Chairs</li>
                                    <li>Dining Table</li>
                                    <li>Artwork</li>
                                </ul>
                            </div>
                            <div className="col-md-12 boxed_list">
                                <h4>MASTER BEDROOM</h4>
                                <ul>
                                    <li>Dresser and Mirror</li>
                                    <li>One or Two Headboards</li>
                                    <li>Lamp</li>
                                    <li>Two Nightstands</li>
                                    <li>Artwork</li>
                                    <li>Queen Bed or Two Twin Beds</li>
                                </ul>
                            </div>
                            <div className="col-md-12 boxed_list">
                                <h4>LIVING ROOM</h4>
                                <ul>
                                    <li>Chair</li>
                                    <li>Coffee Table</li>
                                    <li>32" TV</li>
                                    <li>End Table</li>
                                    <li>Lamp</li>
                                    <li>Artwork</li>
                                    <li>Sofa</li>
                                    <li>DVD Player</li>
                                </ul>
                            </div>
                            <div className="col-md-12 boxed_list">
                                <h4>ADDITIONAL BEDROOM</h4>
                                <ul>
                                    <li>Chest of Drawers</li>
                                    <li>One or Two Headboards</li>
                                    <li>Lamp</li>
                                    <li>Nightstand</li>
                                    <li>Queen Bed or Two Twin Beds</li>
                                </ul>
                            </div>
                    </div>
                    <div className="container-fluid mrg-btm-50 mrg-top-50">
                        <div className="img-header row" style={{backgroundImage:"url(https://s3-us-west-1.amazonaws.com/suiteam-website/Website-Images/SuiteAmerica-Company-Nike-Internship-Housewares.jpg)"}}>
                            <h2>Housewares</h2>
                        </div>
                    </div>
                    <div className="container">
                        <div className="col-md-12 boxed_list">
                                <h4>BATHROOM</h4>
                                <ul>
                                    <li>Bath Rug</li>
                                    <li>Four Bath Towels</li>
                                    <li>Four Hand Towels</li>
                                    <li>Four Face Towels</li>
                                    <li>Hair Dryer</li>
                                    <li>Waste Basket</li>
                                    <li>Shower Curtain</li>
                                    <li>Toilet Plunger</li>
                                </ul>
                            </div>
                            <div className="col-md-12 boxed_list">
                                <h4>BEDROOM</h4>
                                <ul>
                                    <li>Two Bed Pillows</li>
                                    <li>Bedspread</li>
                                    <li>One Sets of Sheets</li>
                                    <li>Alarm Radio</li>
                                    <li>Twenty Hangers</li>
                                </ul>
                            </div>
                            <div className="col-md-12 boxed_list">
                                <h4>DINING</h4>
                                <ul>
                                    <li>Cloth Place Mats</li>
                                    <li>Dinnerware</li>
                                    <li>Flatware</li>
                                    <li>Glassware (juice, water, wine)</li>
                                    <li>Napkins</li>
                                    <li>Wine Glasses</li>
                                </ul>
                            </div>
                            <div className="col-md-12 boxed_list">
                                <h4>KITCHEN</h4>
                                <ul>
                                    <li>Baking Dish</li>
                                    <li>Carving Knife</li>
                                    <li>Coffee Maker</li>
                                    <li>Colander</li>
                                    <li>Cork Puller</li>
                                    <li>Cutting Board</li>
                                    <li>Dish Towels</li>
                                    <li>Regular Can Opener</li>                                    
                                    <li>Flatware Tray</li>                                    
                                    <li>Kitchen Wastebasket</li>                                    
                                    <li>Measuring Cups</li>                                    
                                    <li>Measuring Spoons</li>                                    
                                    <li>Microwave</li>                                    
                                    <li>Mixing Bowls</li>
                                    <li>Paring Knife</li>
                                    <li>Pitcher</li>
                                    <li>Pot Holders</li>
                                    <li>Salt/Pepper Shakers</li>
                                    <li>Serving Spoons</li>
                                    <li>Set of Cookware</li>
                                    <li>Slotted Spoon</li>
                                    <li>Spatulas</li>
                                    <li>Four Steak Knives</li>
                                    <li>Toaster</li>
                                    <li>Whisk</li>
                                    <li>Frying Pan</li>
                                    <li>Three Piece Non-Metal (Spatula, Spoon, Paste Spoon) Combo</li>
                                </ul>
                            </div>
                            <div className="col-md-12 boxed_list">
                                <h4>MOVE-IN KIT</h4>
                                <ul>
                                    <li>Bath Tissue</li>
                                    <li>Coffee and Tea</li>
                                    <li>Dish Soap</li>
                                    <li>Laundry Soap</li>
                                    <li>Lotion</li>
                                    <li>Paper Towels</li>
                                    <li>Shampoo</li>
                                    <li>Soap</li>
                                    <li>Sponge</li>
                                    <li>Sugar/Creamer</li>
                                    <li>Welcome Basket</li>

                                </ul>
                            </div>
                            <div className="col-md-12 boxed_list">
                                <h4>MISCELLANEOUS</h4>
                                <ul>
                                    <li>Suite Telephone</li>
                                    <li>Broom</li>
                                    <li>Dust Pan</li>
                                    <li>Iron</li>
                                    <li>Ironing Board</li>
                                    <li>One Telephone</li>
                                    <li>Vacuum</li>
                                    <li>Laundry Basket</li>
                                </ul>
                            </div>
                    </div>
                </div>
                <div className="container-fluid bg-grey">
                    <div className="container">
                        <ContactUs />
                    </div>
                </div>
                <div className="container ft-contact-adrs mrg-btm-30 mrg-top-30">
                    <ServiceNowInternFooter />
                </div>
                <SubFooter/>
                <Footer/>
            </div>
            
           );
       }
   }
   export default ServiceNowInternApartmentTour;