import LoginModal from './modals/loginModal';
class Login extends React.Component{
  constructor(props){
  super(props)
    this.LoginModal = new LoginModal(this, props)
    this.state = Object.assign(this.LoginModal.state)
    this.setValue = this.setValue.bind(this);
    this.checkPassword = this.checkPassword.bind(this);
    this.deleteState = this.deleteState.bind(this);
    this.checkEmail = this.checkEmail.bind(this);
  }
   /* login form object creation */
   setValue(field, e) {
    var object = {};
    object[field] = e.target.value;
    this.setState(object);
  }

  /* Email Checking it is already exist or not (if email alerady exist we didnot
        insert if not email exist we update state) */
  async checkEmail(e) {
    e.preventDefault();
    this.LoginModal.checkEmail()
  }
  /* Authentication checking if user is not in db we insert the user  */
  async checkPassword(e) {
    e.preventDefault();
    this.LoginModal.checkPassword()
  }
  /* Reset input fields data */
  deleteState() {
    //this.setState({'password': '', 'email': '', 'message': ''})
    $('#loginPassword').val('')
    $('#loginConPassword').val('')
    $('#loginEmail').val('')
    $('#loginMessage').html('')
  }
  render(){

    return(
 <div>
          <div className="modal fade savesearch" id="mysave" role="dialog">
<div className="modal-dialog">
  <div className="modal-content rm-border-radius">
    <div className="modal-header">
      <button type="button" className="close" data-dismiss="modal" aria-label="">
        <span>
          &#10799;
        </span>
      </button>

    </div>
    <div className="modal-body">
      <h1>
        {this.state.alertMessage}
      </h1>
      <span className="form-horizontal">
        {this.state.message
          ? <span
              style={{
              color: this.state.color
            }}>{this.state.message}</span>
          : ''}
        {this.state.confPass
          ? <div className="form-group row">
              <input
                type="password"
                id="loginPassword"
                className="form-control col-md-5"
                value={this.state.confirmPassword}
                onChange={(e) => this.setValue('confirmPassword', e)}
                placeholder="confirmPassword"/>
            </div>
          : null}
        <div className="form-group row">
          <input
            type="password"
            id="loginConPassword"
            className="form-control col-md-5"
            value={this.state.password}
            onChange={(e) => this.setValue('password', e)}
            placeholder="Password"/>
        </div>
        <div className="form-group row">
        {this.state.loginLoader ?<span
            style={{
            fontSize: '20px',
            width: '100%',
            margin: 'auto',
            textAlign: 'center',
            lineHeight: '2.5em'
          }}><i className="fa fa-spinner fa-spin"/>
            please wait ...!</span>:<button
            className="btn location_btn"
            type="button"
            onClick={(e) => this.checkPassword(e)}>Login</button>}
        </div>
      </span>
    </div>
  </div>
</div>
</div>
<div className="modal fade savesearch" tabIndex="-1" id="mysave1" role="dialog">
<div className="modal-dialog">
  <div className="modal-content rm-border-radius">
    <div className="modal-header">
      <button
        type="button"
        className="close"
        data-dismiss="modal btn"
        aria-label=""
        onClick={this.deleteState}>
       <i className="bi bi-x"></i>
      </button>
    </div>
    <div className="modal-body">
      <h1>Sign in or register to save your search</h1>
      <span className="form-horizontal">
        {this.state.message
          ? <span
              id="loginMessage"
              style={{
              color: this.state.color
            }}>{this.state.message}</span>
          : ''}
        <div className="form-group row">
          <input
            type="email"
            id="loginEmail"
            className="form-control col-md-5"
            value={this.state.email}
            onChange={(e) => this.setValue('email', e)}
            placeholder="Email"/>
        </div>
        <div className="form-group row">
          {this.state.loginLoader?<span
            style={{
            fontSize: '20px',
            width: '100%',
            margin: 'auto',
            textAlign: 'center',
            lineHeight: '2.5em'
          }}><i className="fa fa-spinner fa-spin"/>
            please wait ...!</span>:<button
            className="btn location_btn"
            type="button"
            onClick={(e) => this.checkEmail(e)}>Submit</button>}
        </div>
      </span>
    </div>
  </div>
</div>
</div>
   </div>

    )
  }
}

export default Login