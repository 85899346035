/* Importing the node modules, child components, services and controllers used
   inside ClientRegister component */   
   import $ from "jquery";
   import AppController from "../../controller/appController";   
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   import ClientApiService from '../../../services/clientApiService';
   /* ClientRegister Component initialization */
   class ClientRegister extends React.Component {
       /* Initializing objects of its ClientRegister class */
       constructor(props) {
           super(props)
           /* ClientRegister Component State variables Initialization */
           this.state = {
              clientEmail: '',
              clientPassword: '',
              color: '',
              loader: '',
              message: ''
           }
           this.clientRegister = this.clientRegister.bind(this);
           this.setValue = this.setValue.bind(this);
       }
       /* It is invoked immediately before mounting occurs */
       componentDidMount() {
           $('html, body').animate({scrollTop: 0});
           AppController.stickyTitles()
           this.emailDecrypt()
       }
       async emailDecrypt(){
        const urlEmail = this.props.location.search
        var emailEncryption = urlEmail.replace('?email=','').replaceAll('/','$$$');
        const emailResponse=await ClientApiService.getClientEmailDecrypt({email:emailEncryption})
        this.setState({clientEmail:emailResponse})
       }
       /* Update the state object of the form */
       setValue(field, e) {
           var object = {};
           object[field] = e.target.value;
           this.setState(object);
       }
       /* Authentication checking */
       async clientRegister(e) {
           e.preventDefault();
           var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
           if (this.state.clientPassword != '' && (reg.test(this.state.clientEmail))) {
               this.setState({message: '',loader:true})
               const register = {
                    Title: '',
                    FirstName: '',
                    LastName: '',
                    UserName: '',
                    Email: this.state.clientEmail,
                    PasswordHash: this.state.clientPassword,
                    EmailConfirmed: true,
                    PhoneNumberConfirmed: false
               }
               const userData = await ClientApiService.saveClientRegister(register)
               if (userData.error=="0") {
                   this.setState({message: 'Registered Successfully', color: 'green',loader:false})
                   location.href = '/locations';
               } else if(userData.error=="2"){
                   this.setState({message: 'Your Details Already exist', color: 'red',loader:false})
                   location.href = '/locations';
               }else{
                this.setState({message: 'Something went Wrong', color: 'red',loader:false})
            }
           } else {
               this.setState({message: 'Please Enter Valid Email and Password', color: 'red'})
           }
       }
       render() {
           return (
               <div>
                   <GuestHeader/>
                   <div className="container" >
                       <div className="clearfix"></div>
                       <div className="client_rgr">
                               <div className="wow animated fadeInRight">
                                   <div className="col-md-4 col-md-offset-4">
                                   <h3 className="wow animated fadeInLeft"> Register Now</h3>
                                   <p>For a SuiteAmerica Account as Client</p>
                                   <p style={{color:this.state.color}}>{this.state.message}</p>
                                       <form onSubmit={(e) => this.clientRegister(e)}>
                                           <div className="form-group">
                                               <input type="email" id="email" className="form-control" placeholder="Email" value={this.state.clientEmail} 
                                               onChange={(e) => this.setValue('clientEmail', e)} disabled/>
                                           </div>
                                       
                                           <div className="form-group">
                                               <input type="password" id="subject" className="form-control" value={this.state.clientPassword} 
                                               onChange={(e) => this.setValue('clientPassword', e)} placeholder="Create Password"/>
                                           </div>

                                       <div className="text-center">
                                           {this.state.loader?<div className='text-center' style={{fontSize: '25px', width: '100%', margin: 'auto', textAlign: 'center' ,color:'red'}}><i className="fa fa-spinner fa-spin"/> Please wait ...!</div>:<button type="submit" className="btn btn-red" >REGISTER</button>}
                                       </div>
                                       </form>
                                   </div>
   
                               </div>
                               <div className="clearfix"></div>
                       </div>
                       
                   </div>
                   <Footer/>
   
               </div>
           );
       }
   }
   export default ClientRegister;