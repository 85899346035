import React, { useEffect } from "react";
import moment from "moment";
import BookitApiService from "../../../../../services/bookit";

const SuiteCareContext = React.createContext();

const generateDateRange = (startDate) => {
  // Check if startDate is a moment object, if not, convert it
  const startMoment = moment.isMoment(startDate)
    ? startDate
    : moment(startDate);

  return [...Array(90)].map((_, index) => {
    // Create a new moment object for each date
    const newDate = startMoment.clone().add(index, "days");
    return newDate; // Return the moment object for each date in the range
  });
};
let todayDate;
const initialState = {
  activeTab: null, // normal or billable
  refresh: false,
  session: {},
  pageLoading: false,
  // defaultdaterange: [...Array(90)].map((_, index) =>
  //   moment().add(index, "days")
  // ),
  defaultdaterange: generateDateRange(todayDate),
  // selectedDate: moment(),
  ordersMastersByProgram: [],
  aggregatedMastersData: [],
  aggregatedMastersDataFiltered: [], // can be filtered by city or input field

  cities: [],
  selectedCity: "All",
  searchedText: "",

  selectedOrder: {},
  selectedMasterForOrderDetails: {},

  selectedSchedule: {},

  selectedMasterForReservation: {},
};

const SuiteCareProvider = ({ children }) => {
  const [suiteCareState, setSuiteCareState] = React.useState(initialState);
  const [selectedDate, setSelectedDate] = React.useState(moment());
  todayDate = selectedDate; // Define todayDate here

  const updateState = (newState) => {
    setSuiteCareState((prevState) => ({ ...prevState, ...newState }));
  };

  const refreshPage = () => {
    updateState({ refresh: !suiteCareState.refresh });
  };

  const changePageLoadingStatus = (status) => {
    updateState({ pageLoading: status });
  };

  const changeActiveTab = (tab) => {
    updateState({ activeTab: tab });
  };

  const checkAndStoreSessionDetails = async () => {
    const sessionResponse = await BookitApiService.checkSession();
    updateState({ session: sessionResponse });

    if (sessionResponse.loggedIn == false) {
      window.location.href = "/SuiteCare";
    }

    const { ClientProfileCardDetails } = sessionResponse;
    const Availability = ClientProfileCardDetails?.Availability;

    if (Availability == "Rotational") {
      changeActiveTab("billable");
    } else {
      changeActiveTab("normal");
    }
  };

  const getClientData = async () => {
    changePageLoadingStatus(true);

    const { activeTab, session } = suiteCareState;
    const { ClientProfileCardDetails } = session;
    let program = ClientProfileCardDetails?.Program;

    let MasterType;
    if (activeTab == "billable") {
      MasterType = "B";
    } else {
      MasterType = "M";
    }

    let requestObj = {
      program: program ? [program] : ["Yodlee"],
      MasterType,
    };

    let ordersMastersByProgram = await BookitApiService.GetAllAvailableMasters(
      requestObj
    );
    // let ordersMastersByProgram = [
    //   {
    //     masterId: 365740,
    //     masterUId: "456a9705-7104-480e-9ebc-e3a42451d2b2",
    //     communityname: "THE PALMS AT BRIARWOOD",
    //     suiteno: "L304",
    //     suitesize: "2X2",
    //     leasestartdate: "2024-03-30T00:00:00",
    //     leaseenddate: "2024-09-30T00:00:00",
    //     moveindate: "2024-03-30T00:00:00",
    //     moveoutdate: "2024-09-30T00:00:00",
    //     city: "MIDLAND, TX",
    //     BR: 2,
    //     schedules: [
    //       {
    //         DateRequested: "2024-10-01T00:00:00",
    //         IsVendorConfirmed: null,
    //         StatusCode: null,
    //         TypeCode: "T/O",
    //         TableId: 0,
    //         OrderUId: null,
    //       },
    //       {
    //         DateRequested: "2024-10-01T00:00:00",
    //         IsVendorConfirmed: false,
    //         StatusCode: null,
    //         TypeCode: "T/O",
    //         TableId: 0,
    //         OrderUId: null,
    //       },
    //       {
    //         DateRequested: "2024-10-01T00:00:00",
    //         IsVendorConfirmed: false,
    //         StatusCode: null,
    //         TypeCode: "T/O",
    //         TableId: 0,
    //         OrderUId: null,
    //       },
    //     ],
    //     orders: [
    //       {
    //         orderId: 468490,
    //         orderUId: "2fd30e07-945b-4f68-996d-5d80ab16e620",
    //         guestfirstname: "Lazaro",
    //         guestlastname: "Armenteros - Family Housing",
    //         moveindate: "2024-06-05T00:00:00",
    //         moveoutdate: "2024-09-30T00:00:00",
    //         orderstatus: "TERMED",
    //         br: 2,
    //       },
    //     ],
    //   },
    // ];
    updateState({ ordersMastersByProgram: ordersMastersByProgram });

    // aggregate data
    let aggregatedMastersData;
    if (activeTab == "billable") {
      aggregatedMastersData = groupAndAggregateBillableData(
        ordersMastersByProgram
      );
    } else {
      aggregatedMastersData = groupAndAggregateNormalData(
        ordersMastersByProgram
      );
    }
    updateState({
      aggregatedMastersData,
      aggregatedMastersDataFiltered: JSON.parse(
        JSON.stringify(aggregatedMastersData)
      ),
    });

    // filter unique cities from aggregated data
    const cities = uniqueCities(aggregatedMastersData);
    updateState({ cities });

    changePageLoadingStatus(false);
  };

  // Handle date selection from DatePicker
  const handleSelectedDate = (newDate) => {
    todayDate = newDate;
    // updateState({
    //   selectedDate: newDate,
    //   defaultdaterange: generateDateRange(newDate), // Recompute defaultdaterange when a new date is selected
    // });
    setSelectedDate(newDate);
    setSuiteCareState((prev) => ({
      ...prev,
      // selectedDate: newDate,
      // pageLoading: true,
      defaultdaterange: generateDateRange(newDate),
    }));
    // let defaultupdatedrange = generateDateRange(newDate);
    // console.log(defaultupdatedrange, "defaultupdatedrange");

    // getClientData();
  };

  useEffect(() => {
    getClientData();
  }, [suiteCareState.defaultdaterange]);

  const selectCity = (city) => {
    updateState({ selectedCity: city });
  };

  const changeSearchtext = (text) => {
    text = text.toLowerCase();
    updateState({ searchedText: text });
  };

  const openScheduleModal = (schedule) => {
    $(function () {
      $("#schedule-modal").modal("show");
    });
    updateState({ selectedSchedule: JSON.parse(JSON.stringify(schedule)) });
  };

  const openNewReservationModal = (masterData) => {
    $(document).ready(function () {
      // console.log("hello");
      jQuery(function ($) {
        $("#phoneNumber").intlTelInput("setCountry", "us");
      });
    });
    $(function () {
      $("#createNewReservation").modal("show");
    });
    updateState({ selectedMasterForReservation: masterData });
  };

  const openOrderDetailsModal = (order, master) => {
    $(function () {
      $("#orderDetailsPopUp").modal("show");
    });
    updateState({
      selectedOrder: JSON.parse(order),
      selectedMasterForOrderDetails: JSON.parse(master),
    });
  };

  const closeOrderDetailsModal = () => {
    updateState({ selectedOrder: {}, selectedMasterForOrderDetails: {} });
  };

  // ----------------------------------------------------------------
  // Helper functions
  // ----------------------------------------------------------------
  const findAvailableRow = (rowEndIndexs, startIndex) => {
    for (let i = 0; i < rowEndIndexs.length; i++) {
      if (startIndex > rowEndIndexs[i]) {
        return i;
      }
    }
    return rowEndIndexs.length;
  };

  const groupAndAggregateNormalData = (data) => {
    // console.log("normal function");

    const grouped = _.groupBy(
      data,
      (item) => `${item.masterId}-${item.suitesize}`
    );
    // console.log(grouped, "grouped");

    const groupedData = Object.values(grouped).map((group) => {
      const firstItem = group[0];
      const orderIds = new Set();

      let orders = group.reduce((acc, item) => {
        item.orders.forEach((order) => {
          if (!orderIds.has(order.orderId)) {
            orderIds.add(order.orderId);
            order.schedules = [];
            //----------------------------------------------------------------
            item.schedules.forEach((schedule) => {
              if (
                schedule.OrderUId == order.orderUId &&
                schedule.DateRequested &&
                moment(schedule.DateRequested).isSameOrAfter(
                  suiteCareState.defaultdaterange[0]
                ) &&
                moment(schedule.DateRequested).isSameOrBefore(
                  suiteCareState.defaultdaterange[
                    suiteCareState.defaultdaterange.length - 1
                  ]
                )
                // &&
                // !(
                //   moment(schedule.DateRequested).isSameOrAfter(
                //     moment(order.moveindate)
                //   ) &&
                //   moment(schedule.DateRequested).isSameOrBefore(
                //     moment(order.moveoutdate)
                //   )
                // )
              ) {
                order.schedules.push(schedule);
              }
            });
            // console.log(order.schedules, "order.schedules");
            // ----------------------------------------------------------------
            acc.push(order);
          }
        });
        return acc;
      }, []);

      orders = orders.filter((order) => {
        const moveInDate = moment(order.moveindate).startOf("day");
        const moveOutDate = moment(order.moveoutdate).startOf("day");
        const rangeStart = moment(suiteCareState.defaultdaterange[0]).startOf(
          "day"
        );
        const rangeEnd = moment(
          suiteCareState.defaultdaterange[
            suiteCareState.defaultdaterange.length - 1
          ]
        ).startOf("day");

        if (
          (moveInDate.isBefore(rangeStart) &&
            moveOutDate.isBefore(rangeStart)) ||
          (moveInDate.isAfter(rangeEnd) && moveOutDate.isAfter(rangeEnd))
        ) {
          return false;
        } else {
          return true;
        }
      });
      // console.log(orders, "ordersorders");

      let ordersAndSchedulesForMaster = [];

      // making orders and schedules to a common format
      for (let i = 0; i < orders.length; i++) {
        const { schedules, ...rest } = orders[i];
        const { startIndex, span } = getBookingSpan(
          orders[i].moveindate,
          orders[i].moveoutdate
        );

        ordersAndSchedulesForMaster.push({
          ...rest,
          dataType: "order",
          startIndex,
          span,
          color: getColor(orders[i]),
          guestName: getGuestName(orders[i]),
        });

        ordersAndSchedulesForMaster.push(
          ...schedules.map((schedule) => {
            const { startIndex, span } = getBookingSpan(
              schedule.DateRequested,
              schedule.DateRequested
            );
            return {
              ...schedule,
              dataType: "schedule",
              startIndex,
              span,
              color: getScheduleColor(schedule.IsVendorConfirmed),
              guestName: null,
            };
          })
        );
      }
      // sort ordersAndSchedulesForMaster by start index
      ordersAndSchedulesForMaster.sort((a, b) => a.startIndex - b.startIndex);

      // Track the end date of the last drawn item in each row
      const rowEndIndexs = [];
      const rows = [];

      ordersAndSchedulesForMaster.forEach((O_S) => {
        const rowIndex = findAvailableRow(rowEndIndexs, O_S.startIndex);
        rowEndIndexs[rowIndex] = O_S.startIndex + O_S.span - 1;

        if (!rows[rowIndex]) rows[rowIndex] = [];
        rows[rowIndex].push(O_S);
      });

      return {
        masterId: firstItem.masterId,
        masterUId: firstItem.masterUId,
        communityname: firstItem.communityname,
        suiteno: firstItem.suiteno,
        suitesize: firstItem.suitesize,
        city: firstItem.city,
        BR: firstItem.BR,
        orders: orders,
        rows: rows,
      };
    });

    return groupedData;
  };

  const groupAndAggregateBillableData = (data) => {
    // Group data by masterId
    const groupedByMasterId = _.groupBy(data, "masterId");

    // Get keys and sort them in descending order (as numbers)
    const sortedMasterIds = Object.keys(groupedByMasterId).sort(
      (a, b) => b - a
    );

    // Process each masterId group
    const groupedData = sortedMasterIds
      .map((masterId) => {
        // Get the data for the current masterId
        const group = groupedByMasterId[masterId];
        // Sort the group by BR in ascending order
        const sortedGroup = group.sort((a, b) => {
          if (a.BR === undefined) return 1;
          if (b.BR === undefined) return -1;
          return a.BR - b.BR;
        });
        // Process each item in the sorted group
        const processedGroup = sortedGroup.map((item) => {
          const orderIds = new Set();
          let orders = item.orders.reduce((acc, order) => {
            if (!orderIds.has(order.orderId)) {
              orderIds.add(order.orderId);
              order.schedules = [];
              //----------------------------------------------------------------
              item.schedules.forEach((schedule) => {
                if (
                  schedule.OrderUId == order.orderUId &&
                  schedule.DateRequested &&
                  moment(schedule.DateRequested).isSameOrAfter(
                    suiteCareState.defaultdaterange[0]
                  ) &&
                  moment(schedule.DateRequested).isSameOrBefore(
                    suiteCareState.defaultdaterange[
                      suiteCareState.defaultdaterange.length - 1
                    ]
                  )
                  // &&
                  // !(
                  //   moment(schedule.DateRequested).isSameOrAfter(
                  //     moment(order.moveindate)
                  //   ) &&
                  //   moment(schedule.DateRequested).isSameOrBefore(
                  //     moment(order.moveoutdate)
                  //   )
                  // )
                ) {
                  order.schedules.push(schedule);
                }
              });
              // ----------------------------------------------------------------
              acc.push(order);
            }
            return acc;
          }, []);

          orders = orders.filter((order) => {
            if (
              (moment(order.moveindate).isBefore(
                suiteCareState.defaultdaterange[0]
              ) &&
                moment(order.moveoutdate).isBefore(
                  suiteCareState.defaultdaterange[0]
                )) ||
              (moment(order.moveindate).isAfter(
                suiteCareState.defaultdaterange[
                  suiteCareState.defaultdaterange.length - 1
                ]
              ) &&
                moment(order.moveoutdate).isAfter(
                  suiteCareState.defaultdaterange[
                    suiteCareState.defaultdaterange.length - 1
                  ]
                ))
            ) {
              return false;
            } else {
              return true;
            }
          });

          let ordersAndSchedulesForMaster = [];

          // making orders and schedules to a common format
          for (let i = 0; i < orders.length; i++) {
            const { schedules, ...rest } = orders[i];
            const { startIndex, span } = getBookingSpan(
              orders[i].moveindate,
              orders[i].moveoutdate
            );

            ordersAndSchedulesForMaster.push({
              ...rest,
              dataType: "order",
              startIndex,
              span,
              color: getColor(orders[i]),
              guestName: getGuestName(orders[i]),
            });

            ordersAndSchedulesForMaster.push(
              ...schedules.map((schedule) => {
                const { startIndex, span } = getBookingSpan(
                  schedule.DateRequested,
                  schedule.DateRequested
                );
                return {
                  ...schedule,
                  dataType: "schedule",
                  startIndex,
                  span,
                  color: getScheduleColor(schedule.IsVendorConfirmed),
                  guestName: null,
                };
              })
            );
          }

          // sort ordersAndSchedulesForMaster by start index
          ordersAndSchedulesForMaster.sort(
            (a, b) => a.startIndex - b.startIndex
          );

          // Track the end date of the last drawn item in each row
          const rowEndIndexs = [];
          const rows = [];

          ordersAndSchedulesForMaster.forEach((O_S) => {
            const rowIndex = findAvailableRow(rowEndIndexs, O_S.startIndex);
            rowEndIndexs[rowIndex] = O_S.startIndex + O_S.span - 1;

            if (!rows[rowIndex]) rows[rowIndex] = [];
            rows[rowIndex].push(O_S);
          });

          return {
            masterId: item.masterId,
            masterUId: item.masterUId,
            communityname: item.communityname,
            suiteno: item.suiteno,
            suitesize: item.suitesize,
            BR: item.BR,
            city: item.city,
            orders: orders,
            rows: rows,
          };
        });

        return processedGroup;
      })
      .flat();

    return groupedData;
  };

  const uniqueCities = (aggregatedData) => {
    return [...new Set(aggregatedData.map((item) => item.city))];
  };

  const filterAggregatedData = () => {
    const { aggregatedMastersData, selectedCity, searchedText } =
      suiteCareState;
    let filteredData;

    // filter for city selected
    if (selectedCity !== "All") {
      filteredData = aggregatedMastersData.filter(
        (item) => item.city === selectedCity
      );
    } else {
      filteredData = aggregatedMastersData;
    }

    // filter for searched text
    if (searchedText) {
      filteredData = filteredData.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(searchedText)
        )
      );
    }
    updateState({ aggregatedMastersDataFiltered: filteredData });
  };

  // Function to calculate the span for each booking
  const getBookingSpan = (moveindate, moveoutdate) => {
    const dateRange = suiteCareState.defaultdaterange;
    // console.log(dateRange, "dateRange getBookingSpan");

    const start = moment(moveindate);
    const end = moment(moveoutdate);
    let startIndex = dateRange.findIndex((date) => date.isSame(start, "day"));
    const endIndex = dateRange.findIndex((date) => date.isSame(end, "day"));
    let span;
    // ----------------------------------------------------------------
    // If both start and end indexes are -1 , it indicates that order/schedule
    // starts before displayed date range and end after the the displayed
    // date range
    // ----------------------------------------------------------------
    if (startIndex == -1 && endIndex == -1) {
      startIndex = 0;
      span = dateRange.length;
    }
    // ----------------------------------------------------------------
    // If both start index is -1 and endIndex is not -1, it indicates that order/schedule
    // starts before displayed date range and ends somewhere the the displayed
    // date range
    // ----------------------------------------------------------------
    else if (startIndex == -1 && endIndex != -1) {
      startIndex = 0;
      span = endIndex - startIndex + 1;
    }
    // ----------------------------------------------------------------
    // If both start index is not -1 and endIndex is not -1, it indicates
    // that order/schedule starts and ends in the the displayed date range
    // ----------------------------------------------------------------
    else if (startIndex != -1 && endIndex != -1) {
      span = endIndex - startIndex + 1;
    }
    // ----------------------------------------------------------------
    // If both start index is not -1 and endIndex is -1, it indicates
    // that order starts the the displayed date range , but ends outside
    // ----------------------------------------------------------------
    else if (startIndex != -1 && endIndex == -1) {
      span = dateRange.length - startIndex;
    }
    // console.log(startIndex, span, "startIndex, span");

    return { startIndex, span };
  };

  const getColor = (order) => {
    let colorObject = {
      PENDING: "#FF6961",
      ACTIVE: "#AEC6CF",
      TERMED: "#D3D3D3",
    };
    const color = colorObject[order.orderstatus]
      ? colorObject[order.orderstatus]
      : getRandomColor(order.orderId); // Assign a random color

    return color;
  };

  const getScheduleColor = (IsVendorConfirmed) => {
    if (IsVendorConfirmed) {
      return "#B0DAB6";
    } else {
      return "#F9B666";
    }
  };

  const getGuestName = (order) => {
    const guestName = `#${order.orderId} - ${
      order.guestfirstname || order.guestlastname
        ? `${order.guestfirstname} ${order.guestlastname}`
        : ""
    }`;
    return guestName;
  };

  return (
    <SuiteCareContext.Provider
      value={{
        suiteCareState,
        refreshPage,
        changeActiveTab,
        checkAndStoreSessionDetails,
        getClientData,
        changePageLoadingStatus,
        selectedDate,
        handleSelectedDate, // Expose the handleSelectedDate function for external use
        selectCity,
        filterAggregatedData,
        changeSearchtext,

        openOrderDetailsModal,
        closeOrderDetailsModal,

        openScheduleModal,

        openNewReservationModal,
      }}
    >
      {children}
    </SuiteCareContext.Provider>
  );
};

export { SuiteCareContext, SuiteCareProvider };
