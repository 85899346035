import $ from 'jquery';
import moment from 'moment'
import AppController from "../../../controller/appController";
import MainController from "../../../controller/mainController";
import FiveStarService from '../../../../services/fiveStarService';
import FiveStarApiServices from '../../../../services/fiveStarApiServices';
import FivestarHeader from '../../../common/fivestarHeader';
import SuccessModal from '../../../common/successModal';
import InspectFormMainteiance from './inspectFormMainteiance'
import InspectFormBathroom from './inspectFormBathroom'
import InspectFormBathroomTwo from './inspectFormBathroomTwo'
import InspectFormBathroomThree from './inspectFormBathroomThree'
import InspectionFormBedroom from './inspectFormBedroom'
import InspectionFormBedroomTwo from './inspectFormBedroomTwo'
import InspectionFormBedroomThree from './inspectFormBedroomThree'
import InspectFormKitchen from './inspectFormKitchen'
import InspectionFormDining from './inspectFormDining'
import InspectionFormLiving from './inspectFormLiving'
import InspectionFormApartment from './inspectFromApartment'
import ReCAPTCHA from "react-google-recaptcha"
import EncryptDecrypt from '../../../../services/encryptDecrypt';
import SessionService from "../../../../services/sessionService";
import InspectionFormCorbonMonoxide from './InspectionFormCarbon-Monoxide_Smoke-Alarm'
class InspectionForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            guestArrivalDate: '',
            inspectionDate: '',
            inspectorName: '',
            inspectorEmail: '',
            internetNetworkId: '',
            internetProvider: '',
            confirmationNumber: '',
            apartmentComplex: '',
            noOfBedroom: '',
            inspectorPhoneNumber: '',
            internetPassword: '',
            livingfilesLists: [],
            diningfilesLists: [],
            masterBedroomfilesLists: [],
            bedroomTwoLists: [],
            bedroomThreeLists: [],
            bathroomOneLists: [],
            bathroomTwoLists: [],
            bathroomThreeLists: [],
            CaptchaValue: '',
            blender: '',
            toster: '',
            kitchenTowels: '',
            coffeeMaker: '',
            upgradeCookware: '',
            wasteBasket: '',
            flatware: '',
            cookware: '',
            refrigerator: '',
            stove: '',
            sink: '',
            microwave: '',
            garbageDisposal: '',
            dishwasher: '',
            dishPackage: '',
            touchUpPaint: '',
            kitchenComments: '',
            carpet: '',
            mirror: '',
            diningChairs: '',
            placemats: '',
            carpetDamaged: '',
            smokeDetector: '',
            diningTable: '',
            floorLamp: '',
            touchUpPaint: '',
            celing: '',
            smokeDetectorBattery: '',
            diningComments: '',
            carpetLiving: '',
            dvdRemote: '',
            tvCart: '',
            tvRemote: '',
            endTable: '',
            cofffeTable: '',
            checkUnderFurnitureLiving: '',
            sofa: '',
            sofaChair: '',
            officeDesk: '',
            officeDeskLamp: '',
            touchUpPaintLiving: '',
            LivingComments: '',
            bathMatOne: '',
            showerCurtainOne: '',
            showerHeadOne: '',
            sinkStopperOne: '',
            toiletOne: '',
            sinkFaucetOne: '',
            bathroomKitOne: '',
            wasteBasketone: '',
            towelBarOne: '',
            towelbathOne: '',
            hairDryerOne: '',
            carpetBathroomOne: '',
            touchUpPaintBathroomOne: '',
            tubDrainOne: '',
            bathroomoneComments: '',
            bathMatTwo: '',
            showerCurtainTwo: '',
            showerHeadTwo: '',
            sinkStopperTwo: '',
            toiletTwo: '',
            sinkFaucetTwo: '',
            bathroomKitTwo: '',
            wasteBasketTwo: '',
            towelBarTwo: '',
            towelbathTwo: '',
            hairDryerTwo: '',
            carpetBathroomTwo: '',
            touchUpPaintBathroomTwo: '',
            tubDrainTwo: '',
            bathroomTwoComments: '',
            bathMatThree: '',
            showerCurtainThree: '',
            showerHeadThree: '',
            sinkStopperThree: '',
            toiletThree: '',
            sinkFaucetThree: '',
            bathroomKitThree: '',
            wasteBasketThree: '',
            towelBarThree: '',
            towelbathThree: '',
            hairDryerThree: '',
            carpetBathroomThree: '',
            touchUpPaintBathroomThree: '',
            tubDrainThree: '',
            bathroomThreeComments: '',
            bedframeOne: '',
            dresserOne: '',
            artworkOne: '',
            carprtBedroomOne: '',
            DeskOne: '',
            lampOne: '',
            mattressOne: '',
            nightStandOne: '',
            mirrorOne: '',
            clockRadioOne: '',
            phoneBedroomOne: '',
            touchUpPaintBedroomOne: '',
            checkFruntitureBedrromOne: '',
            bedroomOneComments: '',
            bedframeTwo: '',
            dresserTwo: '',
            artworkTwo: '',
            carprtBedroomTwo: '',
            DeskTwo: '',
            lampTwo: '',
            mattressTwo: '',
            nightStandTwo: '',
            mirrorTwo: '',
            clockRadioTwo: '',
            phoneBedroomTwo: '',
            touchUpPaintBedroomTwo: '',
            checkFruntitureBedrromTwo: '',
            bedroomTwoComments: '',
            bedframeThree: '',
            dresserThree: '',
            artworkThree: '',
            carprtBedroomThree: '',
            DeskThree: '',
            lampThree: '',
            mattressThree: '',
            nightStandThree: '',
            mirrorThree: '',
            clockRadioThree: '',
            phoneBedroomThree: '',
            touchUpPaintBedroomThree: '',
            checkFruntitureBedrromThree: '',
            bedroomThreeComments: '',
            heater: '',
            airConditioner: '',
            airConditionerFilter: '',
            guest: '',
            internetConnection: '',
            cable: '',
            lockbox: '',
            patioDeck: '',
            utilities: '',
            cordManagement: '',
            mainteinanceComments: '',
            keysInUnit: '',
            roomBulbs: '',
            color: '',
            errorMessage: '',
            requestDetails: [],
            driverManagerInfo: {},

            countSmokeAllarams:'',
            countMonoxideDetectors:'',
            smokeAllaram:'',
            monoxideDetectors:'',
            smokeAllaramsComments: '',

        }
        this.setValue = this.setValue.bind(this);
        this.setChecked = this.setChecked.bind(this);
        this.filesListUpdate = this.filesListUpdate.bind(this);
        this.clear = this.clear.bind(this);
        this.serviceRequest = this.serviceRequest.bind(this);
    }
    /* It is invoked immediately before mounting occurs */
    componentWillMount() {
        MainController.getInitialInformation();
        const _this = this
        jQuery(function () {
            setTimeout(function () {
                jQuery('input[name="guestDate"], #CheckInRange')
                    .daterangepicker({
                        "drops": "up",
                        locale: {
                            format: 'MM/DD/YYYY'
                        },
                        minDate: moment(),
                        autoUpdateInput: false,
                        singleDatePicker: true
                    }, function (start, end, label) {
                        _this.setState({
                            guestArrivalDate: start.format('MM/DD/YYYY')
                        })
                    });
                jQuery('input[name="inspectiondate"], #CheckOutRange').daterangepicker({
                    "drops": "up",
                    locale: {
                        format: 'MM/DD/YYYY'
                    },
                    minDate: moment(),
                    autoUpdateInput: false,
                    singleDatePicker: true
                }, function (start, end, label) {
                    _this.setState({
                        inspectionDate: start.format('MM/DD/YYYY')
                    })
                });
            }, 500)

        });
    }
    async componentDidMount() {
        const driverInformation= await SessionService.SessionGet({type:'fivestar'});
        this.setState({driverManagerInfo:driverInformation})

        const TableId=localStorage.getItem('requestFormId')
        const driverDetails={
            uniqueid:TableId
        }
        const resOrders = await FiveStarApiServices.getServiceScheduleById(driverDetails);
        this.setState({requestDetails: resOrders[0]});
        
     }
    /* Form Details object creations  */
    setValue(object) {
        this.setState(object);
    }
    /* Form Details object creations  */
    setChecked(object) {
        this.setState(object);
    }
    /* MoveIndate and MoveOutDate   */
    async onChangeCaptcha(CaptchaValue) {
        await this.setState({CaptchaValue})
    }
    clear() {
        AppController.menuTopNavigation('serviceRequest');
        this.setState({
            internetNetworkId: '',
            internetProvider: '',
            inspectorEmail: '',
            inspectionDate: '',
            inspectorName: '',
            inspectorPhoneNumber: '',
            apartmentComplex: '',
            internetPassword: '',
            guestArrivalDate: '',
            inspectionDate: '',
            confirmationNumber: '',
            noOfBedroom: '',
            CaptchaValue: '',

            blender: '',
            toster: '',
            kitchenTowels: '',
            coffeeMaker: '',
            upgradeCookware: '',
            wasteBasket: '',
            flatware: '',
            cookware: '',
            refrigerator: '',
            stove: '',
            sink: '',
            microwave: '',
            garbageDisposal: '',
            dishwasher: '',
            dishPackage: '',
            touchUpPaint: '',
            kitchenComments: '',

            carpet: '',
            mirror: '',
            diningChairs: '',
            placemats: '',
            carpetDamaged: '',
            smokeDetector: '',
            diningTable: '',
            floorLamp: '',
            touchUpPaint: '',
            celing: '',
            smokeDetectorBattery: '',
            diningComments: '',

            carpetLiving: '',
            dvdRemote: '',
            tvCart: '',
            tvRemote: '',
            endTable: '',
            cofffeTable: '',
            checkUnderFurnitureLiving: '',
            sofa: '',
            sofaChair: '',
            officeDesk: '',
            officeDeskLamp: '',
            touchUpPaintLiving: '',
            LivingComments: '',

            bathMatOne: '',
            showerCurtainOne: '',
            showerHeadOne: '',
            sinkStopperOne: '',
            toiletOne: '',
            sinkFaucetOne: '',
            bathroomKitOne: '',
            wasteBasketone: '',
            towelBarOne: '',
            towelbathOne: '',
            hairDryerOne: '',
            carpetBathroomOne: '',
            touchUpPaintBathroomOne: '',
            tubDrainOne: '',
            bathroomoneComments: '',

            bathMatTwo: '',
            showerCurtainTwo: '',
            showerHeadTwo: '',
            sinkStopperTwo: '',
            toiletTwo: '',
            sinkFaucetTwo: '',
            bathroomKitTwo: '',
            wasteBasketTwo: '',
            towelBarTwo: '',
            towelbathTwo: '',
            hairDryerTwo: '',
            carpetBathroomTwo: '',
            touchUpPaintBathroomTwo: '',
            tubDrainTwo: '',
            bathroomTwoComments: '',

            bathMatThree: '',
            showerCurtainThree: '',
            showerHeadThree: '',
            sinkStopperThree: '',
            toiletThree: '',
            sinkFaucetThree: '',
            bathroomKitThree: '',
            wasteBasketThree: '',
            towelBarThree: '',
            towelbathThree: '',
            hairDryerThree: '',
            carpetBathroomThree: '',
            touchUpPaintBathroomThree: '',
            tubDrainThree: '',
            bathroomThreeComments: '',

            bedframeOne: '',
            dresserOne: '',
            artworkOne: '',
            carprtBedroomOne: '',
            DeskOne: '',
            lampOne: '',
            mattressOne: '',
            nightStandOne: '',
            mirrorOne: '',
            clockRadioOne: '',
            phoneBedroomOne: '',
            touchUpPaintBedroomOne: '',
            checkFruntitureBedrromOne: '',
            bedroomOneComments: '',

            bedframeTwo: '',
            dresserTwo: '',
            artworkTwo: '',
            carprtBedroomTwo: '',
            DeskTwo: '',
            lampTwo: '',
            mattressTwo: '',
            nightStandTwo: '',
            mirrorTwo: '',
            clockRadioTwo: '',
            phoneBedroomTwo: '',
            touchUpPaintBedroomTwo: '',
            checkFruntitureBedrromTwo: '',
            bedroomTwoComments: '',

            bedframeThree: '',
            dresserThree: '',
            artworkThree: '',
            carprtBedroomThree: '',
            DeskThree: '',
            lampThree: '',
            mattressThree: '',
            nightStandThree: '',
            mirrorThree: '',
            clockRadioThree: '',
            phoneBedroomThree: '',
            touchUpPaintBedroomThree: '',
            checkFruntitureBedrromThree: '',
            bedroomThreeComments: '',

            heater: '',
            airConditioner: '',
            airConditionerFilter: '',
            guest: '',
            internetConnection: '',
            cable: '',
            lockbox: '',
            patioDeck: '',
            utilities: '',
            cordManagement: '',
            mainteinanceComments: '',
            keysInUnit: '',
            roomBulbs: '',

            color: 'green'
        })
        for (var i = 0; i < 300; i++) {
            $('input[name=' + i + ']').prop('checked', false);
        }
    }

    /* Service Request Details send to mail  */

    async serviceRequest() {
        const _this = this
        const validationFields = [
            'internetNetworkId',
            'internetProvider',
            'inspectorEmail',
            'inspectionDate',
            'inspectorName',
            'inspectorPhoneNumber',
            'apartmentComplex',
            'internetPassword',
            'guestArrivalDate',
            'inspectionDate',
            'noOfBedroom',
            //'CaptchaValue'
        ]
        let error = 0;
        _.forEach(validationFields, function (value) {
            if (_this.state[value] == "") {
                error++;
            }
        });
        if (error == 0) {
            const pstDate = AppController.pstDate()
            const gsrInfo=(this.state.requestDetails.GSR==null || this.state.requestDetails.GSR=='' || this.state.requestDetails.GSR==undefined || this.state.requestDetails.GSR=='/')?'':this.state.requestDetails.GSR
            const gsrEmail=gsrInfo==''?[]:gsrInfo.split('/')
            
            const orderBookedByinfo=(this.state.requestDetails.OrderBookedBy==null || this.state.requestDetails.OrderBookedBy=='' || this.state.requestDetails.OrderBookedBy==undefined || this.state.requestDetails.OrderBookedBy=='/')?'':this.state.requestDetails.OrderBookedBy
            const orderBookedEmail=orderBookedByinfo==''?[]:orderBookedByinfo.split('/')

            const Request = {
                internetNetworkId: this.state.internetNetworkId,
                internetProvider: this.state.internetProvider,
                inspectorEmail: this.state.inspectorEmail,
                inspectionDate: this.state.inspectionDate,
                inspectorName: this.state.inspectorName,
                inspectorPhoneNumber: this.state.inspectorPhoneNumber,
                apartmentComplex: this.state.apartmentComplex,
                internetPassword: this.state.internetPassword,
                guestArrivalDate: this.state.guestArrivalDate,
                inspectionDate: this.state.inspectionDate,
                confirmationNumber: this.state.confirmationNumber,
                noOfBedroom: this.state.noOfBedroom,

                blender: this.state.blender == ''
                    ? '---'
                    : this.state.blender,
                toster: this.state.toster == ''
                    ? '---'
                    : this.state.toster,
                kitchenTowels: this.state.kitchenTowels == ''
                    ? '---'
                    : this.state.kitchenTowels,
                coffeeMaker: this.state.coffeeMaker == ''
                    ? '---'
                    : this.state.coffeeMaker,
                upgradeCookware: this.state.upgradeCookware == ''
                    ? '---'
                    : this.state.upgradeCookware,
                wasteBasket: this.state.wasteBasket == ''
                    ? '---'
                    : this.state.wasteBasket,
                flatware: this.state.flatware == ''
                    ? '---'
                    : this.state.flatware,
                cookware: this.state.cookware == ''
                    ? '---'
                    : this.state.cookware,
                refrigerator: this.state.refrigerator == ''
                    ? '---'
                    : this.state.refrigerator,
                stove: this.state.stove == ''
                    ? '---'
                    : this.state.stove,
                sink: this.state.sink == ''
                    ? '---'
                    : this.state.sink,
                microwave: this.state.microwave == ''
                    ? '---'
                    : this.state.microwave,
                garbageDisposal: this.state.garbageDisposal == ''
                    ? '---'
                    : this.state.garbageDisposal,
                dishwasher: this.state.dishwasher == ''
                    ? '---'
                    : this.state.dishwasher,
                dishPackage: this.state.dishPackage == ''
                    ? '---'
                    : this.state.dishPackage,
                touchUpPaint: this.state.touchUpPaint == ''
                    ? '---'
                    : this.state.touchUpPaint,
                kitchenComments: this.state.kitchenComments == ''
                    ? '---'
                    : this.state.kitchenComments,

                carpetLiving: this.state.carpetLiving == ''
                    ? '---'
                    : this.state.carpetLiving,
                dvdRemote: this.state.dvdRemote == ''
                    ? '---'
                    : this.state.dvdRemote,
                tvCart: this.state.tvCart == ''
                    ? '---'
                    : this.state.tvCart,
                tvRemote: this.state.tvRemote == ''
                    ? '---'
                    : this.state.tvRemote,
                endTable: this.state.endTable == ''
                    ? '---'
                    : this.state.endTable,
                cofffeTable: this.state.cofffeTable == ''
                    ? '---'
                    : this.state.cofffeTable,
                checkUnderFurnitureLiving: this.state.checkUnderFurnitureLiving == ''
                    ? '---'
                    : this.state.checkUnderFurnitureLiving,
                sofa: this.state.touchUpPaint == ''
                    ? '---'
                    : this.state.touchUpPaint,
                sofaChair: this.state.sofaChair == ''
                    ? '---'
                    : this.state.sofaChair,
                officeDesk: this.state.officeDesk == ''
                    ? '---'
                    : this.state.officeDesk,
                officeDeskLamp: this.state.officeDeskLamp == ''
                    ? '---'
                    : this.state.officeDeskLamp,
                touchUpPaintLiving: this.state.touchUpPaintLiving == ''
                    ? '---'
                    : this.state.touchUpPaintLiving,
                LivingComments: this.state.LivingComments == ''
                    ? '---'
                    : this.state.LivingComments,

                carpet: this.state.carpet == ''
                    ? '---'
                    : this.state.carpet,
                mirror: this.state.mirror == ''
                    ? '---'
                    : this.state.mirror,
                diningChairs: this.state.diningChairs == ''
                    ? '---'
                    : this.state.diningChairs,
                placemats: this.state.placemats == ''
                    ? '---'
                    : this.state.placemats,
                carpetDamaged: this.state.carpetDamaged == ''
                    ? '---'
                    : this.state.carpetDamaged,
                smokeDetector: this.state.smokeDetector == ''
                    ? '---'
                    : this.state.smokeDetector,
                diningTable: this.state.diningTable == ''
                    ? '---'
                    : this.state.diningTable,
                floorLamp: this.state.floorLamp == ''
                    ? '---'
                    : this.state.floorLamp,
                touchUpPaint: this.state.touchUpPaint == ''
                    ? '---'
                    : this.state.touchUpPaint,
                celing: this.state.celing == ''
                    ? '---'
                    : this.state.celing,
                diningComments: this.state.diningComments == ''
                    ? '---'
                    : this.state.diningComments,
                smokeDetectorBattery: this.state.smokeDetectorBattery == ''
                    ? '---'
                    : this.state.smokeDetectorBattery,

                bathMatOne: this.state.bathMatOne == ''
                    ? '---'
                    : this.state.bathMatOne,
                showerCurtainOne: this.state.showerCurtainOne == ''
                    ? '---'
                    : this.state.showerCurtainOne,
                showerHeadOne: this.state.showerHeadOne == ''
                    ? '---'
                    : this.state.showerHeadOne,
                sinkStopperOne: this.state.sinkStopperOne == ''
                    ? '---'
                    : this.state.sinkStopperOne,
                toiletOne: this.state.toiletOne == ''
                    ? '---'
                    : this.state.toiletOne,
                sinkFaucetOne: this.state.sinkFaucetOne == ''
                    ? '---'
                    : this.state.sinkFaucetOne,
                bathroomKitOne: this.state.bathroomKitOne == ''
                    ? '---'
                    : this.state.bathroomKitOne,
                wasteBasketone: this.state.wasteBasketone == ''
                    ? '---'
                    : this.state.wasteBasketone,
                towelBarOne: this.state.towelBarOne == ''
                    ? '---'
                    : this.state.towelBarOne,
                towelbathOne: this.state.towelbathOne == ''
                    ? '---'
                    : this.state.towelbathOne,
                hairDryerOne: this.state.hairDryerOne == ''
                    ? '---'
                    : this.state.hairDryerOne,
                carpetBathroomOne: this.state.carpetBathroomOne == ''
                    ? '---'
                    : this.state.carpetBathroomOne,
                touchUpPaintBathroomOne: this.state.touchUpPaintBathroomOne == ''
                    ? '---'
                    : this.state.touchUpPaintBathroomOne,
                tubDrainOne: this.state.tubDrainOne == ''
                    ? '---'
                    : this.state.tubDrainOne,
                bathroomoneComments: this.state.bathroomoneComments == ''
                    ? '---'
                    : this.state.bathroomoneComments,

                bathMatTwo: this.state.bathMatTwo == ''
                    ? '---'
                    : this.state.bathMatTwo,
                showerCurtainTwo: this.state.showerCurtainTwo == ''
                    ? '---'
                    : this.state.showerCurtainTwo,
                showerHeadTwo: this.state.showerHeadTwo == ''
                    ? '---'
                    : this.state.showerHeadTwo,
                sinkStopperTwo: this.state.sinkStopperTwo == ''
                    ? '---'
                    : this.state.sinkStopperTwo,
                toiletTwo: this.state.toiletTwo == ''
                    ? '---'
                    : this.state.toiletTwo,
                sinkFaucetTwo: this.state.sinkFaucetTwo == ''
                    ? '---'
                    : this.state.sinkFaucetTwo,
                bathroomKitTwo: this.state.bathroomKitTwo == ''
                    ? '---'
                    : this.state.bathroomKitTwo,
                wasteBasketTwo: this.state.wasteBasketTwo == ''
                    ? '---'
                    : this.state.wasteBasketTwo,
                towelBarTwo: this.state.towelBarTwo == ''
                    ? '---'
                    : this.state.towelBarTwo,
                towelbathTwo: this.state.towelbathTwo == ''
                    ? '---'
                    : this.state.towelbathTwo,
                hairDryerTwo: this.state.hairDryerTwo == ''
                    ? '---'
                    : this.state.hairDryerTwo,
                carpetBathroomTwo: this.state.carpetBathroomTwo == ''
                    ? '---'
                    : this.state.carpetBathroomTwo,
                touchUpPaintBathroomTwo: this.state.touchUpPaintBathroomTwo == ''
                    ? '---'
                    : this.state.touchUpPaintBathroomTwo,
                tubDrainTwo: this.state.tubDrainTwo == ''
                    ? '---'
                    : this.state.tubDrainTwo,
                bathroomTwoComments: this.state.bathroomTwoComments == ''
                    ? '---'
                    : this.state.bathroomTwoComments,

                bathMatThree: this.state.bathMatThree == ''
                    ? '---'
                    : this.state.bathMatThree,
                showerCurtainThree: this.state.showerCurtainThree == ''
                    ? '---'
                    : this.state.showerCurtainThree,
                showerHeadThree: this.state.showerHeadThree == ''
                    ? '---'
                    : this.state.showerHeadThree,
                sinkStopperThree: this.state.sinkStopperThree == ''
                    ? '---'
                    : this.state.sinkStopperThree,
                toiletThree: this.state.toiletThree == ''
                    ? '---'
                    : this.state.toiletThree,
                sinkFaucetThree: this.state.sinkFaucetThree == ''
                    ? '---'
                    : this.state.sinkFaucetThree,
                bathroomKitThree: this.state.bathroomKitThree == ''
                    ? '---'
                    : this.state.bathroomKitThree,
                wasteBasketThree: this.state.wasteBasketThree == ''
                    ? '---'
                    : this.state.wasteBasketThree,
                towelBarThree: this.state.towelBarThree == ''
                    ? '---'
                    : this.state.towelBarThree,
                towelbathThree: this.state.towelbathThree == ''
                    ? '---'
                    : this.state.towelbathThree,
                hairDryerThree: this.state.hairDryerThree == ''
                    ? '---'
                    : this.state.hairDryerThree,
                carpetBathroomThree: this.state.carpetBathroomThree == ''
                    ? '---'
                    : this.state.carpetBathroomThree,
                touchUpPaintBathroomThree: this.state.touchUpPaintBathroomThree == ''
                    ? '---'
                    : this.state.touchUpPaintBathroomThree,
                tubDrainThree: this.state.tubDrainThree == ''
                    ? '---'
                    : this.state.tubDrainThree,
                bathroomThreeComments: this.state.bathroomThreeComments == ''
                    ? '---'
                    : this.state.bathroomThreeComments,

                bedframeOne: this.state.bedframeOne == ''
                    ? '---'
                    : this.state.bedframeOne,
                dresserOne: this.state.dresserOne == ''
                    ? '---'
                    : this.state.dresserOne,
                artworkOne: this.state.artworkOne == ''
                    ? '---'
                    : this.state.artworkOne,
                carprtBedroomOne: this.state.carprtBedroomOne == ''
                    ? '---'
                    : this.state.carprtBedroomOne,
                DeskOne: this.state.DeskOne == ''
                    ? '---'
                    : this.state.DeskOne,
                lampOne: this.state.lampOne == ''
                    ? '---'
                    : this.state.lampOne,
                mattressOne: this.state.mattressOne == ''
                    ? '---'
                    : this.state.mattressOne,
                nightStandOne: this.state.nightStandOne == ''
                    ? '---'
                    : this.state.nightStandOne,
                mirrorOne: this.state.mirrorOne == ''
                    ? '---'
                    : this.state.mirrorOne,
                clockRadioOne: this.state.clockRadioOne == ''
                    ? '---'
                    : this.state.clockRadioOne,
                phoneBedroomOne: this.state.phoneBedroomOne == ''
                    ? '---'
                    : this.state.phoneBedroomOne,
                touchUpPaintBedroomOne: this.state.touchUpPaintBedroomOne == ''
                    ? '---'
                    : this.state.touchUpPaintBedroomOne,
                checkFruntitureBedrromOne: this.state.checkFruntitureBedrromOne == ''
                    ? '---'
                    : this.state.checkFruntitureBedrromOne,
                bedroomOneComments: this.state.bedroomOneComments == ''
                    ? '---'
                    : this.state.bedroomOneComments,

                bedframeTwo: this.state.bedframeTwo == ''
                    ? '---'
                    : this.state.bedframeTwo,
                dresserTwo: this.state.dresserTwo == ''
                    ? '---'
                    : this.state.dresserTwo,
                artworkTwo: this.state.artworkTwo == ''
                    ? '---'
                    : this.state.artworkTwo,
                carprtBedroomTwo: this.state.carprtBedroomTwo == ''
                    ? '---'
                    : this.state.carprtBedroomTwo,
                DeskTwo: this.state.DeskTwo == ''
                    ? '---'
                    : this.state.DeskTwo,
                lampTwo: this.state.lampTwo == ''
                    ? '---'
                    : this.state.lampTwo,
                mattressTwo: this.state.mattressTwo == ''
                    ? '---'
                    : this.state.mattressTwo,
                nightStandTwo: this.state.nightStandTwo == ''
                    ? '---'
                    : this.state.nightStandTwo,
                mirrorTwo: this.state.mirrorTwo == ''
                    ? '---'
                    : this.state.mirrorTwo,
                clockRadioTwo: this.state.clockRadioTwo == ''
                    ? '---'
                    : this.state.clockRadioTwo,
                phoneBedroomTwo: this.state.phoneBedroomTwo == ''
                    ? '---'
                    : this.state.phoneBedroomTwo,
                touchUpPaintBedroomTwo: this.state.touchUpPaintBedroomTwo == ''
                    ? '---'
                    : this.state.touchUpPaintBedroomTwo,
                checkFruntitureBedrromTwo: this.state.checkFruntitureBedrromTwo == ''
                    ? '---'
                    : this.state.checkFruntitureBedrromTwo,
                bedroomTwoComments: this.state.bedroomTwoComments == ''
                    ? '---'
                    : this.state.bedroomTwoComments,

                bedframeThree: this.state.bedframeThree == ''
                    ? '---'
                    : this.state.bedframeThree,
                dresserThree: this.state.dresserThree == ''
                    ? '---'
                    : this.state.dresserThree,
                artworkThree: this.state.artworkThree == ''
                    ? '---'
                    : this.state.artworkThree,
                carprtBedroomThree: this.state.carprtBedroomThree == ''
                    ? '---'
                    : this.state.carprtBedroomThree,
                DeskThree: this.state.DeskThree == ''
                    ? '---'
                    : this.state.DeskThree,
                lampThree: this.state.lampThree == ''
                    ? '---'
                    : this.state.lampThree,
                mattressThree: this.state.mattressThree == ''
                    ? '---'
                    : this.state.mattressThree,
                nightStandThree: this.state.nightStandThree == ''
                    ? '---'
                    : this.state.nightStandThree,
                mirrorThree: this.state.mirrorThree == ''
                    ? '---'
                    : this.state.mirrorThree,
                clockRadioThree: this.state.clockRadioThree == ''
                    ? '---'
                    : this.state.clockRadioThree,
                phoneBedroomThree: this.state.phoneBedroomThree == ''
                    ? '---'
                    : this.state.phoneBedroomThree,
                touchUpPaintBedroomThree: this.state.touchUpPaintBedroomThree == ''
                    ? '---'
                    : this.state.touchUpPaintBedroomThree,
                checkFruntitureBedrromThree: this.state.checkFruntitureBedrromThree == ''
                    ? '---'
                    : this.state.checkFruntitureBedrromThree,
                bedroomThreeComments: this.state.bedroomThreeComments == ''
                    ? '---'
                    : this.state.bedroomThreeComments,

                heater: this.state.heater == ''
                    ? '---'
                    : this.state.heater,
                airConditioner: this.state.airConditioner == ''
                    ? '---'
                    : this.state.airConditioner,
                airConditionerFilter: this.state.airConditionerFilter == ''
                    ? '---'
                    : this.state.airConditionerFilter,
                countSmokeAllarams: this.state.countSmokeAllarams == ''
                    ? '---'
                    : this.state.countSmokeAllarams,
                countMonoxideDetectors: this.state.countMonoxideDetectors == ''
                    ? '---'
                    : this.state.countMonoxideDetectors,
                smokeAllaram: this.state.smokeAllaram == ''
                    ? '---'
                    : this.state.smokeAllaram,
                monoxideDetectors: this.state.monoxideDetectors == ''
                    ? '---'
                    : this.state.monoxideDetectors,
                smokeAllaramsComments: this.state.smokeAllaramsComments == ''
                    ? '---'
                    : this.state.smokeAllaramsComments,
                guest: this.state.guest == ''
                    ? '---'
                    : this.state.guest,
                internetConnection: this.state.internetConnection == ''
                    ? '---'
                    : this.state.internetConnection,
                cable: this.state.cable == ''
                    ? '---'
                    : this.state.cable,
                lockbox: this.state.lockbox == ''
                    ? '---'
                    : this.state.lockbox,
                patioDeck: this.state.patioDeck == ''
                    ? '---'
                    : this.state.patioDeck,
                utilities: this.state.utilities == ''
                    ? '---'
                    : this.state.utilities,
                cordManagement: this.state.cordManagement == ''
                    ? '---'
                    : this.state.cordManagement,
                roomBulbs: this.state.roomBulbs == ''
                    ? '---'
                    : this.state.roomBulbs,
                keysInUnit: this.state.keysInUnit == ''
                    ? '---'
                    : this.state.keysInUnit,
                livingfilesLists: this.state.livingfilesLists.length == 0
                    ? []
                    : this.state.livingfilesLists,
                diningfilesLists: this.state.diningfilesLists.length == 0
                    ? []
                    : this.state.diningfilesLists,
                masterBedroomfilesLists: this.state.masterBedroomfilesLists.length == 0
                    ? []
                    : this.state.masterBedroomfilesLists,
                bedroomTwoLists: this.state.bedroomTwoLists.length == 0
                    ? []
                    : this.state.bedroomTwoLists,
                bedroomThreeLists: this.state.bedroomThreeLists.length == 0
                    ? []
                    : this.state.bedroomThreeLists,
                bathroomOneLists: this.state.bathroomOneLists.length == 0
                    ? []
                    : this.state.bathroomOneLists,
                bathroomTwoLists: this.state.bathroomTwoLists.length == 0
                    ? []
                    : this.state.bathroomTwoLists,
                bathroomThreeLists: this.state.bathroomThreeLists.length == 0
                    ? []
                    : this.state.bathroomThreeLists,
                    mainteinanceComments:this.state.mainteinanceComments == ''
                    ? '---'
                    : this.state.mainteinanceComments,

                    masterId:(this.state.requestDetails.mastertableId == '' || this.state.requestDetails.mastertableId ==null)? '---' :this.state.requestDetails.mastertableId,
                    SuiteNo: (this.state.requestDetails.SuiteNo == '' || this.state.requestDetails.SuiteNo ==null)? '---' :this.state.requestDetails.SuiteNo,
                    sTableId: (this.state.requestDetails.sTableId == '' || this.state.requestDetails.sTableId ==null)? '---' :this.state.requestDetails.sTableId,
                    orderId:(this.state.requestDetails.TableId == '' || this.state.requestDetails.TableId ==null)? '---' :this.state.requestDetails.TableId,
                    createdDate:pstDate,
    
                    orderType: this.state.requestDetails.OrderType,
                    HotelName:(this.state.requestDetails.HotelName == '' || this.state.requestDetails.HotelName ==null)? '---' :this.state.requestDetails.HotelName,
                    HotelAddress:(this.state.requestDetails.hoteladdress == '' || this.state.requestDetails.hoteladdress ==null)? '---' :this.state.requestDetails.hoteladdress,
                    HotelCity:(this.state.requestDetails.hotelcity == '' || this.state.requestDetails.hotelcity ==null)? '---' :this.state.requestDetails.hotelcity,
                    HotelState:(this.state.requestDetails.hotelstate == '' || this.state.requestDetails.hotelstate ==null)? '---' :this.state.requestDetails.hotelstate,
                    HotelZipcode:(this.state.requestDetails.hotelzipcode == '' || this.state.requestDetails.hotelzipcode ==null)? '---' :this.state.requestDetails.hotelzipcode,
                    HotelSuiteNo:(this.state.requestDetails.hotelsuite == '' || this.state.requestDetails.hotelsuite ==null)? '---' :this.state.requestDetails.hotelsuite,
    
                    community_name:(this.state.requestDetails.community_name == '' || this.state.requestDetails.community_name ==null)? '---' :this.state.requestDetails.community_name,
                    address:(this.state.requestDetails.address == '' || this.state.requestDetails.address ==null)? '---' :this.state.requestDetails.address,
                    state_name:(this.state.requestDetails.state_name == '' || this.state.requestDetails.state_name ==null)? '---' :this.state.requestDetails.state_name,
                    city_name:(this.state.requestDetails.city_name == '' || this.state.requestDetails.city_name ==null)? '---' :this.state.requestDetails.city_name,
                    ZipCode:(this.state.requestDetails.ZipCode == '' || this.state.requestDetails.ZipCode ==null)? '---' :this.state.requestDetails.ZipCode,
                    SuiteNo: (this.state.requestDetails.SuiteNo == '' || this.state.requestDetails.SuiteNo ==null)? '---' :this.state.requestDetails.SuiteNo,
                    type: this.state.requestDetails.type,
                    suiteAddress: this.state.requestDetails.AptAddr,
                    suiteState: this.state.requestDetails.LeaseState,
                    suiteCity: this.state.requestDetails.LeaseCity,
                    suiteZipCode: this.state.requestDetails.AptZip,
                    driverName:this.state.driverManagerInfo.Identifier,
                    mail: 'inspectionForm',
                    subject:  (this.state.requestDetails.TableId == '' || this.state.requestDetails.TableId == null) ?  '5Star Inspection Form for Master # : '+this.state.requestDetails.mastertableId+' and Record # :'+this.state.requestDetails.sTableId:'5Star Inspection Form for Order # : '+this.state.requestDetails.TableId+' ; Master # :'+this.state.requestDetails.mastertableId +' ; Record # :'+this.state.requestDetails.sTableId ,
                    orderGsr:gsrEmail.length>0?gsrEmail[1]:'',
                    orderBooked:orderBookedEmail.length>0?orderBookedEmail[1]:''
           
                }

            this.setState({
                CaptchaValue: '',
                internetNetworkId: '',
                internetProvider: '',
                inspectorEmail: '',
                inspectionDate: '',
                inspectorName: '',
                inspectorPhoneNumber: '',
                apartmentComplex: '',
                internetPassword: '',
                guestArrivalDate: '',
                inspectionDate: '',
                confirmationNumber: '',
                noOfBedroom: '',

                livingfilesLists: [],
                diningfilesLists: [],
                masterBedroomfilesLists: [],
                bedroomTwoLists: [],
                bedroomThreeLists: [],
                bathroomOneLists: [],
                bathroomTwoLists: [],
                bathroomThreeLists: [],

                blender: '',
                toster: '',
                kitchenTowels: '',
                coffeeMaker: '',
                upgradeCookware: '',
                wasteBasket: '',
                flatware: '',
                cookware: '',
                refrigerator: '',
                stove: '',
                sink: '',
                microwave: '',
                garbageDisposal: '',
                dishwasher: '',
                dishPackage: '',
                touchUpPaint: '',
                kitchenComments: '',

                carpetLiving: '',
                dvdRemote: '',
                tvCart: '',
                tvRemote: '',
                endTable: '',
                cofffeTable: '',
                checkUnderFurnitureLiving: '',
                sofa: '',
                sofaChair: '',
                officeDesk: '',
                officeDeskLamp: '',
                touchUpPaintLiving: '',
                LivingComments: '',

                carpet: '',
                mirror: '',
                diningChairs: '',
                placemats: '',
                carpetDamaged: '',
                smokeDetector: '',
                diningTable: '',
                floorLamp: '',
                touchUpPaint: '',
                celing: '',
                diningComments: '',
                smokeDetectorBattery: '',

                bathMatOne: '',
                showerCurtainOne: '',
                showerHeadOne: '',
                sinkStopperOne: '',
                toiletOne: '',
                sinkFaucetOne: '',
                bathroomKitOne: '',
                wasteBasketone: '',
                towelBarOne: '',
                towelbathOne: '',
                hairDryerOne: '',
                carpetBathroomOne: '',
                touchUpPaintBathroomOne: '',
                tubDrainOne: '',
                bathroomoneComments: '',

                bathMatTwo: '',
                showerCurtainTwo: '',
                showerHeadTwo: '',
                sinkStopperTwo: '',
                toiletTwo: '',
                sinkFaucetTwo: '',
                bathroomKitTwo: '',
                wasteBasketTwo: '',
                towelBarTwo: '',
                towelbathTwo: '',
                hairDryerTwo: '',
                carpetBathroomTwo: '',
                touchUpPaintBathroomTwo: '',
                tubDrainTwo: '',
                bathroomTwoComments: '',

                bathMatThree: '',
                showerCurtainThree: '',
                showerHeadThree: '',
                sinkStopperThree: '',
                toiletThree: '',
                sinkFaucetThree: '',
                bathroomKitThree: '',
                wasteBasketThree: '',
                towelBarThree: '',
                towelbathThree: '',
                hairDryerThree: '',
                carpetBathroomThree: '',
                touchUpPaintBathroomThree: '',
                tubDrainThree: '',
                bathroomThreeComments: '',

                bedframeOne: '',
                dresserOne: '',
                artworkOne: '',
                carprtBedroomOne: '',
                DeskOne: '',
                lampOne: '',
                mattressOne: '',
                nightStandOne: '',
                mirrorOne: '',
                clockRadioOne: '',
                phoneBedroomOne: '',
                touchUpPaintBedroomOne: '',
                checkFruntitureBedrromOne: '',
                bedroomOneComments: '',

                bedframeTwo: '',
                dresserTwo: '',
                artworkTwo: '',
                carprtBedroomTwo: '',
                DeskTwo: '',
                lampTwo: '',
                mattressTwo: '',
                nightStandTwo: '',
                mirrorTwo: '',
                clockRadioTwo: '',
                phoneBedroomTwo: '',
                touchUpPaintBedroomTwo: '',
                checkFruntitureBedrromTwo: '',
                bedroomTwoComments: '',

                bedframeThree: '',
                dresserThree: '',
                artworkThree: '',
                carprtBedroomThree: '',
                DeskThree: '',
                lampThree: '',
                mattressThree: '',
                nightStandThree: '',
                mirrorThree: '',
                clockRadioThree: '',
                phoneBedroomThree: '',
                touchUpPaintBedroomThree: '',
                checkFruntitureBedrromThree: '',
                bedroomThreeComments: '',

                heater: '',
                airConditioner: '',
                airConditionerFilter: '',
                
                countSmokeAllarams:'',
                countMonoxideDetectors:'',
                smokeAllaram:'',
                monoxideDetectors:'',
                smokeAllaramsComments: '',

                guest: '',
                internetConnection: '',
                cable: '',
                lockbox: '',
                patioDeck: '',
                utilities: '',
                cordManagement: '',
                mainteinanceComments: '',
                keysInUnit: '',
                roomBulbs: '',
                errorMessage: '',
                color: ''
            })

            for (var i = 0; i < 300; i++) {
                $('input[name=' + i + ']').prop('checked', false);
            }
            const RequestParmas={
                "scheduleid":this.state.requestDetails.sTableId,
                "driveruid":this.state.driverManagerInfo.UniqueId,
                "jsondata":JSON.stringify(Request) ,
                "createdby":this.state.driverManagerInfo.Identifier,
            }         
            const Response = await FiveStarApiServices.SaveFiveStarInspection(RequestParmas)
            if(Response.error=="0"){
                const emailResponse = await FiveStarService.inspectionForm(Request);
                AppController.menuTopNavigation('serviceRequest');
                jQuery(function ($) {
                    $('#successModal').modal('show');
                    setTimeout(function () {
                        $('#successModal').modal('hide');
                    }, 4000);
                });
            }
        } else {
            AppController.menuTopNavigation('serviceRequest');
            this.setState({errorMessage: 'Please Enter Required Fields', color: 'red'})
        }
    }
    async filesListUpdate(filepath, FileDetails, info, dataIndex, type) {
        if (type == 'living') {
            if (dataIndex < 0) {
                await this.setState({
                    livingfilesLists: _.concat(this.state.livingfilesLists, {
                        filepath: filepath,
                        FileDetails: FileDetails,
                        info: info
                    })
                })
            } else if (dataIndex >= 0) {
                await this.setState({
                    livingfilesLists: update(this.state.livingfilesLists, {
                        $splice: [
                            [dataIndex, 1]
                        ]
                    }, 0)
                })
            }
        } else if (type == 'dining') {
            if (dataIndex < 0) {
                await this.setState({
                    diningfilesLists: _.concat(this.state.diningfilesLists, {
                        filepath: filepath,
                        FileDetails: FileDetails,
                        info: info
                    })
                })
            } else if (dataIndex >= 0) {
                await this.setState({
                    diningfilesLists: update(this.state.diningfilesLists, {
                        $splice: [
                            [dataIndex, 1]
                        ]
                    }, 0)
                })
            }
        } else if (type == 'masterbedroom') {
            if (dataIndex < 0) {
                await this.setState({
                    masterBedroomfilesLists: _.concat(this.state.masterBedroomfilesLists, {
                        filepath: filepath,
                        FileDetails: FileDetails,
                        info: info
                    })
                })
            } else if (dataIndex >= 0) {
                await this.setState({
                    masterBedroomfilesLists: update(this.state.masterBedroomfilesLists, {
                        $splice: [
                            [dataIndex, 1]
                        ]
                    }, 0)
                })
            }
        } else if (type == 'bedroomTwo') {
            if (dataIndex < 0) {
                await this.setState({
                    bedroomTwoLists: _.concat(this.state.bedroomTwoLists, {
                        filepath: filepath,
                        FileDetails: FileDetails,
                        info: info
                    })
                })
            } else if (dataIndex >= 0) {
                await this.setState({
                    bedroomTwoLists: update(this.state.bedroomTwoLists, {
                        $splice: [
                            [dataIndex, 1]
                        ]
                    }, 0)
                })
            }
        } else if (type == 'bedroomThree') {
            if (dataIndex < 0) {
                await this.setState({
                    bedroomThreeLists: _.concat(this.state.bedroomThreeLists, {
                        filepath: filepath,
                        FileDetails: FileDetails,
                        info: info
                    })
                })
            } else if (dataIndex >= 0) {
                await this.setState({
                    bedroomThreeLists: update(this.state.bedroomThreeLists, {
                        $splice: [
                            [dataIndex, 1]
                        ]
                    }, 0)
                })
            }
        } else if (type == 'bathroomOne') {
            if (dataIndex < 0) {
                await this.setState({
                    bathroomOneLists: _.concat(this.state.bathroomOneLists, {
                        filepath: filepath,
                        FileDetails: FileDetails,
                        info: info
                    })
                })
            } else if (dataIndex >= 0) {
                await this.setState({
                    bathroomOneLists: update(this.state.bathroomOneLists, {
                        $splice: [
                            [dataIndex, 1]
                        ]
                    }, 0)
                })
            }
        } else if (type == 'bathroomTwo') {
            if (dataIndex < 0) {
                await this.setState({
                    bathroomTwoLists: _.concat(this.state.bathroomTwoLists, {
                        filepath: filepath,
                        FileDetails: FileDetails,
                        info: info
                    })
                })
            } else if (dataIndex >= 0) {
                await this.setState({
                    bathroomTwoLists: update(this.state.bathroomTwoLists, {
                        $splice: [
                            [dataIndex, 1]
                        ]
                    }, 0)
                })
            }
        } else {
            if (dataIndex < 0) {
                await this.setState({
                    bathroomThreeLists: _.concat(this.state.bathroomThreeLists, {
                        filepath: filepath,
                        FileDetails: FileDetails,
                        info: info
                    })
                })
            } else if (dataIndex >= 0) {
                await this.setState({
                    bathroomThreeLists: update(this.state.bathroomThreeLists, {
                        $splice: [
                            [dataIndex, 1]
                        ]
                    }, 0)
                })
            }
        }
    }
    render() {
        return (
            <div className="pages_5star">
   <FivestarHeader/>
   <div className="container-fluid nike company_section" id="serviceRequest">
      <div className="container text-center">
         <h2 className="">Inspection Form
         </h2>
      </div>
   </div>
   <div className="container service_request">
      <div className="request_form company_form">
         <form className="well" style={{padding: '20px'}}>
         <p
            style={{
            color: this.state.color
            }}>{this.state.errorMessage}</p>
         <h1>Apartment Information</h1>
         <div className="row" >
            <div className="col-sm-6 col-md-6">
               <input
               type="text"
               className="form-control"
               name='guestDate'
               placeholder="* Guest Arrival Date"
               value={this.state.guestArrivalDate}
               onChange={(e) => this.setValue('guestArrivalDate', e)}/>
               <div className="row">
                  <div className="col-sm-6"></div>
                  <div className="col-sm-6"></div>
               </div>
            </div>
            <div className="col-sm-6 col-md-6">
               <input
               type="text"
               className="form-control"
               name="inspectiondate"
               placeholder="*Inspection Date "
               value={this.state.inspectionDate}
               onChange={(e) => this.setValue('confirmationNumber', e)}/>
            </div>
         </div>
         <InspectionFormApartment
         setChecked={this.setChecked}
         setValue={this.setValue}
         {...this.state}/>
         </form>
         <div className="">
            <div className="insepection_acco">
               <div
                  id="accordion"
                  className="panel-group mrg-top-70 mrg-btm-50 company_acdn"
                  role="tablist"
                  aria-multiselectable="true">
                  <InspectFormKitchen
                  setChecked={this.setChecked}
                  setValue={this.setValue}
                  {...this.state}/>
                  <InspectionFormDining
                  setChecked={this.setChecked}
                  filesListUpdate={this.filesListUpdate}
                  setValue={this.setValue}
                  {...this.state}/>
                  <InspectionFormLiving
                  setChecked={this.setChecked}
                  filesListUpdate={this.filesListUpdate}
                  setValue={this.setValue}
                  {...this.state}/>
                  <InspectionFormBedroom
                  setChecked={this.setChecked}
                  filesListUpdate={this.filesListUpdate}
                  setValue={this.setValue}
                  {...this.state}/>
                  <InspectionFormBedroomTwo
                  setChecked={this.setChecked}
                  filesListUpdate={this.filesListUpdate}
                  setValue={this.setValue}
                  {...this.state}/>
                  <InspectionFormBedroomThree
                  setChecked={this.setChecked}
                  filesListUpdate={this.filesListUpdate}
                  setValue={this.setValue}
                  {...this.state}/>
                  <InspectFormBathroom
                  setChecked={this.setChecked}
                  filesListUpdate={this.filesListUpdate}
                  setValue={this.setValue}
                  {...this.state}/>
                  <InspectFormBathroomTwo
                  setChecked={this.setChecked}
                  setValue={this.setValue}
                  filesListUpdate={this.filesListUpdate}
                  {...this.state}/>
                  <InspectFormBathroomThree
                  setChecked={this.setChecked}
                  filesListUpdate={this.filesListUpdate}
                  setValue={this.setValue}
                  {...this.state}/>
                  <InspectFormMainteiance
                  setChecked={this.setChecked}
                  setValue={this.setValue}
                  {...this.state}/>
                  <InspectionFormCorbonMonoxide
                  setChecked={this.setChecked}
                  setValue={this.setValue}
                  {...this.state}/>
               </div>
            </div>
         </div>
         <div className="text-center mrg-top-20">       
            <button
            type="button"
            className="btn star_button mrg-right-15"
            onClick={this.clear}>
            CLEAR</button>
            <button
            type="button"
            className="btn star_button"
            onClick={this.serviceRequest}>
            SUBMIT</button>
         </div>
      </div>
   </div>
   <SuccessModal/>
</div>
        )

    }

}

export default InspectionForm