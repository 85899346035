import OwlCarousel from 'react-owl-carousel';
import GuestHeader from '../common/guestHeader';
import Footer from '../common/footer';
import SubFooter from "../common/subFooter";
import SuccessModal from '../common/successModal';
import ReCAPTCHA from "react-google-recaptcha";
import NikeServices from '../../services/nikeService';
class testimonial extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            residentName: '',
            phone: '',
            companyName: '',
            accountNumber: '',
            confirmNumber: '',
            comments: '',
            color: '',
            errorMessage: '',
            CaptchaValue: ''
        }
        this.setValue = this.setValue.bind(this);
        this.clearFields = this.clearFields.bind(this);
        this.serviceRequest = this.serviceRequest.bind(this);
        this.onChangeCaptcha = this.onChangeCaptcha.bind(this);
    }
    setValue(field, e) {
        var object = {};
        object[field] = e.target.value;
        this.setState(object);
    }
    async serviceRequest() {
        const _this = this
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        const validationFields = ['name', 'email', 'comments', 'CaptchaValue'];
        let error = 0;
        _.forEach(validationFields, function (value) {
            if (_this.state[value] == "") {
                error++;
            }
        });
        if (!reg.test(_this.state.email)) {
            error++;
        }
        if (error == 0) {
            const Request = {
                name: this.state.name,
                email: this.state.email,
                residentName: this.state.residentName,
                phone: this.state.phone,
                companyName: this.state.companyName,
                accountNumber: this.state.accountNumber,
                confirmNumber: this.state.confirmNumber,
                comments: this.state.comments,
                subject: 'Testimonials',
                mail: 'testimonials'
            }

            this.setState({
                name: '',
                email: '',
                residentName: '',
                phone: '',
                companyName: '',
                accountNumber: '',
                confirmNumber: '',
                comments: '',
                color: '',
                errorMessage: ''
            })
            jQuery(function ($) {
                setTimeout(function () {
                    $('#testimonial').modal('hide');
                    $('#successModal').modal('show');
                }.bind(this), 10);
                setTimeout(function () {
                    $('#successModal').modal('hide');
                }.bind(this), 4000);
            }.bind(this));
            await NikeServices.serviceRequest(Request);
        } else {
            this.setState({errorMessage: 'Please Enter Required Fields and Valid Email', color: 'red'})
        }

    }
    clearFields() {
        this.setState({
            name: '',
            email: '',
            residentName: '',
            phone: '',
            companyName: '',
            accountNumber: '',
            confirmNumber: '',
            comments: '',
            color: '',
            errorMessage: ''
        })
    }
    async onChangeCaptcha(CaptchaValue) {
        await this.setState({CaptchaValue})
    }
    render() {
        return (
            <div>
                <GuestHeader />
                <div className="guest_testmonialspage">

                    <div className="banner">
                        <div className="item">
                            <h1>Guest Testimonials</h1>
                            <p>Curious what people are saying about us? Check out these testimonials…</p>
                            <a className="btn" data-toggle="modal"
                                data-target="#testimonial" href="#wt"
                                 onClick={this.clearFields} >SUBMIT A TESTIMONIAL</a>
                        </div>
                        <div className="item">
                            <img src="https://suiteam-website.s3-us-west-1.amazonaws.com/landing-images/Testimonials/testimonial_banner.png" style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="guest_testmonials_count guest_testmonials_container">
                        <div className="item">
                            <h1>30</h1>
                            <p>Years of Customer Service Excellence in Corporate Housing.</p>
                        </div>
                        <div className="item">
                            <h1> 468 </h1>
                            <p> Meet & Greet Services provided to our Guests in 2020.</p>
                        </div>
                        <div className="item">
                            <h1> 20%</h1>
                            <p>Of reservations include furry friends. Did you know we offer pet packages?</p>
                        </div>
                        
                        <div className="item" >
                            <h1 >1,178</h1>
                            <p id="wt">Kid friendly board games delivered to our smallest guests.</p>
                        </div>
                    </div>
                    
                    <div className="guest_testmonials_container text-right" >
                        <h1>Here’s what<br /> people are saying…</h1>
                        <p>At SuiteAmerica, our clients’ comfort is our #1 priority. We believe that our success is dependent on the level of service our guests receive.</p>
                    </div>


                    <div className="guest_testmonials_slider">
                        <OwlCarousel className="GreatSchools-community" {...{ loop: false, nav: true, dots: true, items: 1, navContainerClass: 'owl-buttons', navText: ["<i class='glyphicon glyphicon-menu-left'></i>", "<i class='glyphicon glyphicon-menu-right'></i>"], autoheight: 'true', refreshClass: 'owl-refresh', loadingClass: 'owl-loaded', responsive: { 320: { items: 1, nav: true, loop: false }, 767: { items: 2, nav: true, loop: false }, 992: { items: 2, nav: true, loop: false }, 1199: { items: 3, nav: true, loop: false, autoHeight: true } } }}>
                           
                           <div className="card" >
                                <i className="fa fa-smile-o" ></i>

                                <p>“Very responsive to our needs, allowing for a smooth transition in a new city.”</p>
                                <span>March. 2, 2020</span>
                            </div>
                            <div className="card" >
                                <i className="fa fa-thumbs-o-up" ></i>

                                <p>“Thank you for reaching out to me and providing me your great hospitality.”</p>
                                <span>Feb. 28, 2020</span>
                            </div>
                            <div className="card" >
                                <i className="fa fa-heart" ></i>

                                <p>“You are so great in what you do. I have really never experienced such a great customer
    service. You always reply immediately, with empathy and kindness.You’re making my
experience here great.”</p>
                                <span>Feb. 24, 2020</span>
                            </div>
                            <div className="card" >
                                <i className="fa fa-smile-o" ></i>
                                <p>“Thanks for the warm welcome, I really appreciate the hospitality and the meet-and-greet
appointment.”</p>
                                <span>Feb. 17, 2020</span>
                            </div>
                            <div className="card" >
                                <i className="fa fa-thumbs-o-up" ></i>
                                <p>“It was bittersweet to leave on Saturday. Thanks so much for everyone's hospitality during our
stay. Everyone was great in helping ease our transition to Seattle.”</p>
                                <span>Feb. 12, 2020</span>
                            </div>
                            <div className="card" >
                                <i className="fa fa-heart" ></i>
                                <p>“Thank you again for the lovely apartment and smooth move in! We have really enjoyed our
first night here.”</p>
                                <span>Feb. 9, 2020</span>
                            </div>
                            <div className="card" >
                                <i className="fa fa-smile-o" ></i>
                                <p>“Thanks for checking in! My move in went smooth and SuiteAmerica was very detailed and
welcoming. Thanks again!”</p>
                                <span>Feb. 3, 2020</span>
                            </div>
                            <div className="card" >
                                <i className="fa fa-thumbs-o-up" ></i>
                                <p>“Thank you so much for the amazing stay and for making my transition to Portland easier as I
looked for an apartment out here. You guys are great!”</p>
                                <span>Jan. 30, 2020</span>
                            </div>
                            <div className="card" >
                                <i className="fa fa-heart" ></i>
                                <p>“I just got home and everything looks great.  Thank you very much for your help, I truly, truly
appreciate it.”</p>
                                <span>Jan. 26, 2020</span>
                            </div>
                            <div className="card" >
                                <i className="fa fa-smile-o" ></i>
                                <p>“Thank you very much for the opportunity to stay at this wonderful community and temporary
home, and for bootstrapping us after our arrival.”</p>
                                <span>Jan. 23, 2020</span>
                            </div>
                            <div className="card" >
                                <i className="fa fa-thumbs-o-up" ></i>
                                <p>“We really appreciate your accommodations and hard work to make our family feel
comfortable during this transition.”</p>
                                <span>Jan. 19, 2020</span>
                            </div>
                            <div className="card" >
                                <i className="fa fa-heart" ></i>
                                <p>“Thanks! I also want to mention again SuiteAmerica goes above and beyond when cleaning
the unit to accommodate my little one.”</p>
                                <span>Jan. 16, 2020</span>
                            </div>
                            <div className="card" >
                                <i className="fa fa-smile-o" ></i>
                                <p>“I don’t often use the word hero, however in this case I feel it necessary. You are a hero.”</p>
                                <span>Jan. 11, 2020</span>
                            </div>
                            <div className="card" >
                                <i className="fa fa-thumbs-o-up" ></i>
                                <p>“Very good experience. Including staff that went above and beyond, waiting for me after a long
flight delay. Extremely great service.”</p>
                                <span>Jan. 6, 2020</span>
                            </div>
                            <div className="card" >
                                <i className="fa fa-heart" ></i>
                                <p>“Thank you so much for your kindness and researching the best option for me. I appreciate
your assistance in trying to find the best accommodation for me.”</p>
                                <span>Jan. 2, 2020</span>
                            </div>
                            <div className="card" >
                                <i className="fa fa-smile-o" ></i>
                                <p>“I did want to convey a big thanks from my wife to the SuiteAmerica team on the kitchen set up
you folks have provided… Inside the apartment all the stuff you have provided is  good quality.”</p>
                                <span>Dec. 24, 2019</span>
                            </div>
                            <div className="card" >
                                <i className="fa fa-thumbs-o-up" ></i>
                                <p>“We also loved the apartment and its proximity to parks & the library which
has been beneficial with the kids.”</p>
                                <span>Dec. 20, 2019</span>
                            </div>
                            <div className="card" >
                                <i className="fa fa-heart" ></i>
                                <p>“But mainly I want to thank SuiteAmerica for the nice apartment we got. It made our relocation
so much easier.”</p>
                                <span>Dec. 16, 2019</span>
                            </div>
                            <div className="card" >
                                <i className="fa fa-smile-o" ></i>
                                <p>“I’ve recently arrived in California and am so happy with our temporary home.  It was all the
small things that you did.”</p>
                                <span>Dec. 11, 2019</span>
                            </div>
                            <div className="card" >
                                <i className="fa fa-thumbs-o-up" ></i>
                                <p>“I wanted to thank you for your
help. I am in my new apartment for a week now and I love it!”</p>
                                <span>Dec. 6, 2019</span>
                            </div>
                            <div className="card" >
                                <i className="fa fa-heart" ></i>
                                <p>“Thank you to SuiteAmerica for all of their hospitality and help through this process. The housing
accommodation has been a great experience and truly a blessing.”</p>
                                <span>Dec. 2, 2019</span>
                            </div>
                            <div className="card" >
                                <i className="fa fa-smile-o" ></i>
                                <p>“You are awesome. SuiteAmerica’s response to our requests continues to amaze me.”</p>
                                <span>Nov. 28, 2019</span>
                            </div>
                            <div className="card" >
                                <i className="fa fa-thumbs-o-up" ></i>
                                <p>“Everything has been amazing! You and your team are a gift! Thank you for all you do for us.”</p>
                                <span>Nov. 21, 2019</span>
                            </div>
                            <div className="card" >
                                <i className="fa fa-heart" ></i>
                                <p>“Thank you so much! <strong>&#128578;</strong> I enjoyed my stay here. For the first time, I didn't miss home, even
though I didn’t have my family by my side. I am going to miss my Suite. <strong>&#128542;</strong>”</p>
                                <span>Nov. 15, 2019</span>
                            </div>
                            <div className="card" >
                                <i className="fa fa-smile-o" ></i>
                                <p>“This has been a wonderful stay and should I return I’d opt again for SuiteAmerica.”</p>
                                <span>Nov. 7, 2019</span>
                            </div>
                            <div className="card" >
                                <i className="fa fa-thumbs-o-up" ></i>
                                <p>“Thanks for the great apartment, the great service during our stay, and for always finding
solutions for us. We loved the location.”</p>
                                <span>Nov. 1, 2019</span>
                            </div>
                        </OwlCarousel>
                     </div> 
                    <div className="guest_testmonials_container">
                        <div className="guest_testmonials_art">
                            <h1>Are you ready to experience the Art of Welcome?</h1>
                            <div className="img_div">
                                <img src="https://suiteam-website.s3-us-west-1.amazonaws.com/landing-images/Testimonials/image1.png" />
                                <img src="https://suiteam-website.s3-us-west-1.amazonaws.com/landing-images/Testimonials/image2.png" />
                                <img src="https://suiteam-website.s3-us-west-1.amazonaws.com/landing-images/Testimonials/image3.png" />

                            </div>
                        </div>
                        <p>Consider us as an option for your corporate housing. Take a peek at the properties we have to offer utilizing our 360 view apartments tours. Our apartments come with a full kitchen, wifi, cable, living space and dining space. Skip the hotel and treat your employees to a furnished apartment.</p>
                    </div>
                    <div className="text-center">
                        <a className="btn" href="/reserve-your-stay" type="button">Book Now</a>
                    </div>
                </div>
                <div
                    className="modal fade savesearch tesimonial_form bd-example-modal-lg"
                    id="testimonial"
                    role="dialog">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content rm-border-radius">
                            <div className="modal-header">
                                <button type="button" className="close btn" data-dismiss="modal" aria-label="">
                                <i className="bi bi-x"></i>
                                </button>
                            </div>
                            <div className="modal-body">
                                <h2>Send us your Testimonial</h2>
                                <p
                                    className="text-center"
                                    style={{
                                    color: this.state.color
                                }}>{this.state.errorMessage}</p>
                                <form role="form" method="post">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <input
                                                className="form-control"
                                                placeholder="Your Name*"
                                                type="text"
                                                value={this.state.name}
                                                onChange={(e) => this.setValue('name', e)}/>
                                        </div>
                                        <div className="form-group">
                                            <input
                                                className="form-control"
                                                placeholder="Email*"
                                                type="text"
                                                value={this.state.email}
                                                onChange={(e) => this.setValue('email', e)}/>
                                        </div>
                                        <div className="form-group">
                                            <input
                                                className="form-control"
                                                placeholder="Resident Name"
                                                type="text"
                                                value={this.state.residentName}
                                                onChange={(e) => this.setValue('residentName', e)}/>
                                        </div>
                                        <div className="form-group">
                                            <input
                                                className="form-control"
                                                placeholder="Phone"
                                                type="text"
                                                value={this.state.phone}
                                                onChange={(e) => this.setValue('phone', e)}/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <input
                                                className="form-control"
                                                placeholder="Company Name"
                                                type="text"
                                                value={this.state.companyName}
                                                onChange={(e) => this.setValue('companyName', e)}/>
                                        </div>
                                        <div className="form-group">
                                            <input
                                                className="form-control"
                                                placeholder="Account Number"
                                                type="text"
                                                value={this.state.accountNumber}
                                                onChange={(e) => this.setValue('accountNumber', e)}/>
                                        </div>
                                        <div className="form-group">
                                            <input
                                                className="form-control"
                                                placeholder="Confirmation Number"
                                                type="text"
                                                value={this.state.confirmNumber}
                                                onChange={(e) => this.setValue('confirmNumber', e)}/>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <textarea
                                                className="form-control"
                                                placeholder="Comments*"
                                                type="text"
                                                value={this.state.comments}
                                                onChange={(e) => this.setValue('comments', e)}></textarea>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12 form-group">
                                        <ReCAPTCHA
                                            ref="recaptcha"
                                            sitekey="6Lcbw1AUAAAAAE5K5cYDyhoGu0RJY9C3CLtGFXAo"
                                            onChange={this.onChangeCaptcha}/>
                                    </div>

                                    <div className="text-center col-sm-12 form-group">
                                        <button
                                            className="btn location_btn"
                                            type="button"
                                            onClick={this.serviceRequest}>SUBMIT</button>

                                    </div>
                                </form>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <SuccessModal/>
                <SubFooter />
                <Footer />
            </div>
        )
    }
}
export default testimonial;
