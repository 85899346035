import OwlCarousel from "react-owl-carousel";
import Footer from "./footer";
import Header from "./header";
import LocationService from "../../../services/locationService";
import AppController from "../../controller/appController";
import Spinner from "../../common/spinner";
import { Progress, Spin } from "antd";
import RequestCallback from "./requestCallback";
import FullPopup from "./fullPopup";
import Loader from "./guestPortal/loader";
class PropertyDetailsnew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      latitude: 0.0,
      longitude: 0.0,
      appartmentAmenities: [],
      appartmentamenitieslist: [],
      communityAmenities: [],
      communityImages: [],
      communityALLImages: [],
      communityObject: {},
      showlist: false,
      showSearch: false,
      directionsService: {},
      directionsDisplay: {},
      destinationAdd: "",
      location: [],
      markerList: [],
      greatSchools: {},
      allScools: [],
      PageLoader: false,
      imgloader: true,
      types: [
        {
          name: "Restaurants",
          type: "restaurant",
          fa: "fas fa fa-cutlery",
          icon: "/assets/newUI-images/explore_icons/restarent.svg",
        },
        {
          name: "Bars",
          type: "bar",
          fa: "fas fa fa-beer",
          icon: "/assets/newUI-images/explore_icons/bars.svg",
        },
        {
          name: "Groceries",
          type: "supermarket",
          fa: "fas fa fa-shopping-cart",
          icon: "/assets/newUI-images/explore_icons/groceries.svg",
        },
        {
          name: "Park",
          type: "park",
          fa: "fas fa fa-tree",
          icon: "/assets/newUI-images/explore_icons/parks.svg",
        },
        {
          name: "Shopping",
          type: "shopping_mall",
          fa: "fas fa fa-shopping-bag",
          icon: "/assets/newUI-images/explore_icons/shopping.svg",
        },
        {
          name: "Entertainment",
          type: "night_club",
          fa: "fas fa fa-film",
          icon: "/assets/newUI-images/explore_icons/entertainment.svg",
        },
      ],
      type: ["restaurant"],
      typeicon: 0,
      placeSearchedMarkers: [],
      activeNumber: -1,
      walkscore: null,
      transitscore: null,
      bikescore: null,
      privateScools: [],
      publicScools: [],
      charterScools: [],
      fullpopdata: {},
    };
    this.typeView = this.typeView.bind(this);
    this.methodx = this.methodx.bind(this);
    this.destinationLocation = this.destinationLocation.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.resetData = this.resetData.bind(this);
    this.onetype = this.onetype.bind(this);
    this.flyToPlace = this.flyToPlace.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleOpenCarousel = this.handleOpenCarousel.bind(this);
    this.handleclose = this.handleclose.bind(this);
  }
  handleClick2() {
    $("#sideQuote").addClass("active");
    $(".overlay").addClass("active");
    $(".collapse.in").toggleClass("in");
    $("a[aria-expanded=true]").attr("aria-expanded", "false");
  }
  flyToPlace(row, index, type) {
    var latLng = new google.maps.LatLng(row.latitude, row.longitude);
    this.map.panTo(latLng);
    const placemarker =
      type == "locations"
        ? this.state.markerList
        : this.state.placeSearchedMarkers;
    google.maps.event.trigger(placemarker[index], "click");
    this.setState({ activeNumber: row.name });
  }
  async componentDidMount() {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href =
      "https://suiteam-website.s3.us-west-1.amazonaws.com/new/logo/SA-Icon.png";
    await this.getDetails();
    await this.methodx();
  }
  async getDetails() {
    const communitydetails = await JSON.parse(localStorage.getItem("moreData"));
    var propsCommunity =
      this.props.match.params.name == "BOULEVARD-APARTMENTS-FREMONT"
        ? "BOULEVARD APARTMENTS-FREMONT"
        : this.props.match.params.name == "THE-CARLYLE-SANTA-CLARA"
        ? "THE CARLYLE-SANTA CLARA"
        : this.props.match.params.name == "PARK-CENTRAL-CONCORD"
        ? "PARK CENTRAL/CONCORD"
        : this.props.match.params.name == "THE-COVE-TIBURON"
        ? "THE COVE/TIBURON"
        : this.props.match.params.name == "WATERFORD-PLACE-DUBLIN"
        ? "WATERFORD PLACE/DUBLIN"
        : this.props.match.params.name.replace(new RegExp("-", "gi"), " ");
    var communityName = propsCommunity;
    console.log(communityName, "------");
    const limitData = {
      communityName: encodeURIComponent(encodeURIComponent(communityName)),
      imageType: "banner",
    };
    if (limitData.communityName) {
      limitData.communityName =
        "" + limitData.communityName.replace(/%252B/g, "%2520");
    }
    console.log(limitData, "limitData");
    const details = await LocationService.getCommunityInfoByName(limitData);

    await this.setState({ communityObject: details });
    //this.getNearCommunitysByCommunity()
    const appartmentAmenities =
      this.state.communityObject.AmenitiesList != null &&
      this.state.communityObject.AmenitiesList != undefined
        ? JSON.parse(this.state.communityObject.AmenitiesList)
        : [];
    let communityImages =
      this.state.communityObject.CommunityImages != null &&
      this.state.communityObject.CommunityImages != undefined
        ? JSON.parse(this.state.communityObject.CommunityImages)
        : [];
    if (
      this.state.communityObject.CommunityImages != null &&
      this.state.communityObject.CommunityImages != undefined
    ) {
      communityImages.unshift({
        image_name: this.state.communityObject.image_name,
      });
    }
    const communityGroupImages =
      communityImages.length > 0 ? _.groupBy(communityImages, "imageType") : "";

    const complexImages =
      communityImages != null
        ? communityGroupImages.complex != undefined
          ? communityGroupImages.complex
          : communityGroupImages.interior
        : [];
    const apparmentamenites =
      appartmentAmenities != null && appartmentAmenities != undefined
        ? _.filter(appartmentAmenities, function (o) {
            return o.communityname == "Unit";
          })
        : [];
    const communityamenites =
      appartmentAmenities != null && appartmentAmenities != undefined
        ? _.filter(appartmentAmenities, function (o) {
            return o.communityname == "Community";
          })
        : [];
    await this.walkScoreInfo(details);

    let result = appartmentAmenities.map((a) =>
      console.log(
        a.aparment_amenity_name.replace(/[^A-Z0-9]+/gi, ""),
        "--appartmentAmenities"
      )
    );
    this.setState({
      latitude: this.state.communityObject.latitude,
      longitude: this.state.communityObject.longitude,
      appartmentAmenities: apparmentamenites,
      appartmentamenitieslist: appartmentAmenities,
      communityAmenities: communityamenites,
      communityImages: complexImages,
      communityALLImages: communityImages,
    });
    this.setState({ PageLoader: true });
  }

  async walkScoreInfo(communityData) {
    const results = await AppController.wallsckoreFuns(communityData);
    if (results != undefined && results != null && results != 0) {
      this.setState({
        walkscore: results.walkscore != undefined ? results.walkscore : "N/A",
        bikescore: results.bike != undefined ? results.bike.score : "N/A",
        transitscore: results.transit == null ? "N/A" : results.transit.score,
      });
    }
    const greatSchools = await AppController.greatSchools2(communityData);

    const privateScools = _.filter(greatSchools.schools, function (o) {
      o.schoolSummary = o["school-summary"];
      o.overviewUrl = o["overview-url"];
      return o.type == "private";
    });
    const publicScools = _.filter(greatSchools.schools, function (o) {
      o.schoolSummary = o["school-summary"];
      o.overviewUrl = o["overview-url"];
      return o.type == "public";
    });
    const charterScools = _.filter(greatSchools.schools, function (o) {
      o.schoolSummary = o["school-summary"];
      o.overviewUrl = o["overview-url"];
      return o.type == "charter";
    });
    this.setState({
      allScools: publicScools,
      greatSchools: greatSchools,
      privateScools: privateScools,
      publicScools: publicScools,
      charterScools: charterScools,
    });
  }
  async typeView() {
    $(".nav-link").removeClass("active");
    $("#name").addClass("active");
    this.setState({
      showlist: false,
      showSearch: false,
    });
    this.methodx();
  }
  async resetData() {
    this.methodx();
  }
  searchPlace() {
    this.setState({
      showSearch: true,
      showlist: true,
    });
  }
  async methodx() {
    await this.setState({
      directionsService: {},
      directionsDisplay: {},
      destinationAdd: "",
    });
    document.getElementById("dvDistance").innerHTML = "";
    $("#txtPlaces").val("");
    if ($("#map" + this.state.communityObject.community_id).length) {
      var locations = {
        lat: Number(this.state.communityObject.latitude),
        lng: Number(this.state.communityObject.longitude),
      };
      const map = new google.maps.Map(
        document.getElementById(
          "map" + this.state.communityObject.community_id
        ),
        {
          zoom: 7,
          center: locations,
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          backgroundColor: "#eeeeee",
          disableDefaultUI: true,
        }
      );
      var style = [
        {
          elementType: "geometry",
          stylers: [{ saturation: -40 }],
        },
      ];
      var mapType = new google.maps.StyledMapType(style, { name: "Grayscale" });
      map.mapTypes.set("grey", mapType);
      map.setMapTypeId("grey");
      var customZoomInControlDiv = document.createElement("div");
      this.CustomZoomInControl(customZoomInControlDiv, map);
      customZoomInControlDiv.index = 1;
      map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(
        customZoomInControlDiv
      );
      await this.setState({ customMap: map });

      // MapController.customMarkers(this.state.communityObject, 'singlemark', this, '', s3BucketUrl_WebsiteImages + 'SuiteAmerica-M' +
      //   'ap-Marker-Red.png',
      //   customMap);

      var directionsService = new google.maps.DirectionsService();
      var directionsDisplay = new google.maps.DirectionsRenderer();
      this.setState({ directionsService: directionsService });
      this.setState({ directionsDisplay: directionsDisplay });
      const image_name = this.state.communityObject.image_name;
      const communityName = this.state.communityObject.community_name;
      const communityAddress = this.state.communityObject.address;
      const communityLat = this.state.communityObject.latitude;
      const communityLong = this.state.communityObject.longitude;
      const communityCity = this.state.communityObject.city_name;
      const communityState = this.state.communityObject.state_name;
      const communityZipcode = this.state.communityObject.ZipCode;
      const detailAddress =
        communityAddress +
        ", " +
        communityCity +
        ", " +
        communityState +
        " " +
        communityZipcode;
      var locations = {
        lat: Number(communityLat),
        lng: Number(communityLong),
      };
      var contentString =
        '<div id="content">' +
        '<div id="siteNotice">' +
        "</div>" +
        '<div id="bodyContent">' +
        '<div class="bodycontentimg"><img width="100px" height="100px" src="' +
        image_name +
        '" /> </div>' +
        "<h1>" +
        communityName +
        "</h1>" +
        "<p>" +
        detailAddress +
        ' </p><a href="https://www.google.com/maps?daddr=' +
        communityLat +
        "," +
        communityLong +
        '" target="_blank">Get driving directions</a>' +
        "</div>" +
        "</div>";
      this.state.directionsDisplay.setMap(this.state.customMap);
      var infowindow = new google.maps.InfoWindow({
        content: contentString,
      });
      var marker = new google.maps.Marker({
        position: locations,
        icon: "/assets/newUI-images/explore_icons/map_icon.svg",
        // icon: icon,
        map: this.state.customMap,
      });
      marker.addListener("click", function () {
        if (this.state.customMap) {
          infowindow.open(this.state.customMap, marker);
        }
       // infowindow.open(this.state.customMap, marker);
      });
    }
  }

  CustomZoomInControl(controlDiv, map) {
    // Set CSS for the control border
    var controlUI = document.createElement("div");

    controlUI.style.cursor = "pointer";
    controlUI.style.display = "-webkit-inline-box";
    controlUI.style.margin = "18px 10px";
    controlUI.style.width = "30px";
    controlUI.style.height = "30px";
    controlDiv.appendChild(controlUI);
    // Set CSS for the control interior
    var controlText = document.createElement("div");
    controlText.innerHTML += `<img style="height:30px;width:30px;margin:0"  src='/assets/newUI-images/plus.svg' />`;
    controlUI.appendChild(controlText);

    // Setup the click event listeners
    google.maps.event.addDomListener(controlUI, "click", function () {
      map.setZoom(map.getZoom() + 1);
    });

    // Set CSS for the control border
    var controlUILeft = document.createElement("div");

    controlUILeft.style.display = "-webkit-inline-box";
    controlUILeft.style.marginLeft = "10px";
    controlUILeft.style.cursor = "pointer";
    controlUILeft.style.width = "30px";
    controlUILeft.style.height = "30px";
    controlDiv.appendChild(controlUILeft);

    var controlTextLeft = document.createElement("div");

    controlTextLeft.innerHTML += `<img style="height:30px;width:30px;margin:0"  src='/assets/newUI-images/minus.svg' />`;
    controlUILeft.appendChild(controlTextLeft);

    // Setup the click event listeners
    google.maps.event.addDomListener(controlUILeft, "click", function () {
      map.setZoom(map.getZoom() - 1);
    });
  }
  destinationLocation() {
    var address = "";
    const _this = this;
    var places = new google.maps.places.Autocomplete(
      document.getElementById("txtPlaces")
    );
    google.maps.event.addListener(places, "place_changed", function () {
      var place = places.getPlace();
      address = place.formatted_address;
      _this.setState({ destinationAdd: address });
    });
  }
  async handleFormSubmit() {
    var gmarkers = [];
    const _this = this;
    await _this.searchPlace();
    var locations = {
      lat: Number(this.state.communityObject.latitude),
      lng: Number(this.state.communityObject.longitude),
    };

    const customMap = new google.maps.Map(
      document.getElementById("map" + this.state.communityObject.community_id),
      {
        zoom: 7,
        center: locations,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        backgroundColor: "#eeeeee",
        disableDefaultUI: true,
        clickableIcons: false,
      }
    );
    var style = [
      {
        elementType: "geometry",
        stylers: [
          { saturation: -40, visibility: "off", clickableIcons: false },
        ],
      },
    ];
    var mapType = new google.maps.StyledMapType(style, { name: "Grayscale" });
    customMap.mapTypes.set("grey", mapType);
    customMap.setMapTypeId("grey");
    var customZoomInControlDiv = document.createElement("div");
    this.CustomZoomInControl(customZoomInControlDiv, customMap);
    customZoomInControlDiv.index = 1;
    customMap.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(
      customZoomInControlDiv
    );
    await this.setState({ customMap });

    var directionsService = new google.maps.DirectionsService();
    var directionsDisplay = new google.maps.DirectionsRenderer();

    const image_name = this.state.communityObject.image_name;
    const communityName = this.state.communityObject.community_name;
    const communityAddress = this.state.communityObject.address;
    const communityLat = this.state.communityObject.latitude;
    const communityLong = this.state.communityObject.longitude;
    const communityCity = this.state.communityObject.city_name;
    const communityState = this.state.communityObject.state_name;
    const communityZipcode = this.state.communityObject.ZipCode;
    const detailAddress =
      communityAddress +
      ", " +
      communityCity +
      ", " +
      communityState +
      " " +
      communityZipcode;
    var locations = {
      lat: Number(communityLat),
      lng: Number(communityLong),
    };

    const community_lat = this.state.communityObject.latitude;
    const community_long = this.state.communityObject.longitude;
    var request = {
      origin: { lat: Number(community_lat), lng: Number(community_long) },
      destination: this.state.destinationAdd,

      travelMode: google.maps.DirectionsTravelMode.DRIVING,
    };

    directionsService.route(request, function (response, status) {
      if (status == google.maps.DirectionsStatus.OK) {
        new google.maps.DirectionsRenderer({
          map: _this.state.customMap,
          directions: response,
          suppressMarkers: true,
        });

        var distance = response.routes[0].legs[0].distance.text;
        var duration = response.routes[0].legs[0].duration.text;
        var dvDistance = document.getElementById("dvDistance");
        dvDistance.innerHTML = "";
        dvDistance.innerHTML += "<h4>Your Commute Summary</h4>";
        dvDistance.innerHTML += "<p>Drive Time: " + duration + "</p>";
        dvDistance.innerHTML += "<p>Distance: " + distance + "</p>";

        function makeMarker(position, icon, title, map) {
          let mark = new google.maps.Marker({
            position: position,
            map: map,
            icon: icon,
            title: title,
            disableDefaultUI: true,
            styles: style,
          });
        }
        var icons = {
          start: new google.maps.MarkerImage(
            "/assets/newUI-images/explore_icons/map_icon.svg"
          ),
          end: new google.maps.MarkerImage(
            "https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/location_pin_2.svg"
          ),
        };

        var leg = response.routes[0].legs[0];
        makeMarker(
          leg.start_location,
          icons.start,
          "title",
          _this.state.customMap
        );
        makeMarker(leg.end_location, icons.end, "title", _this.state.customMap);
      }
    });
  }
  async onetype(row, type) {
    $(".nav-link").removeClass("active");
    $("#" + row.name).addClass("active");
    await this.setState({
      type: [row.type],
      showlist: true,
      typeName: row.name,
      typeicon: type,
      showSearch: false,
    });
    this.loadPlacesWithMarkers(1, this.state.typeicon);
  }
  async loadPlacesWithMarkers(index) {
    const _this = this;
    const markers = [];
    var pyrmont = {
      lat: this.state.latitude,
      lng: this.state.longitude,
    };
    const map = (this.map = new google.maps.Map(
      document.getElementById("map" + this.state.communityObject.community_id),
      {
        zoom: 14,
        center: pyrmont,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        backgroundColor: "#eeeeee",
        disableDefaultUI: true,
      }
    ));
    var style = [
      {
        elementType: "geometry",
        stylers: [{ saturation: -40 }],
      },
    ];
    var mapType = new google.maps.StyledMapType(style, { name: "Grayscale" });
    map.mapTypes.set("grey", mapType);
    map.setMapTypeId("grey");
    var customZoomInControlDiv = document.createElement("div");
    this.CustomZoomInControl(customZoomInControlDiv, map);
    customZoomInControlDiv.index = 1;
    map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(
      customZoomInControlDiv
    );

    var service = new google.maps.places.PlacesService(map);
    const request = {
      location: pyrmont,
      radius: 2000,
      type: this.state.type,
    };
    service.nearbySearch(request, async (data, status) => {
      var infowindow = new google.maps.InfoWindow();
      if ("OK" === google.maps.places.PlacesServiceStatus.OK) {
        const location = [];
        var results = _.orderBy(data, ["name"], ["asc"]);
        for (var i = 0; i < results.length; i++) {
          location.push({
            latitude: results[i].geometry.location.lat(),
            longitude: results[i].geometry.location.lng(),
            name: results[i].name,
            address: results[i].vicinity,
          });
          const marker = (this.marker = new google.maps.Marker({
            map: map,
            position: results[i].geometry.location,
            center: results[i].geometry.location,
            icon: this.state.types[this.state.typeicon].icon,
          }));
          google.maps.event.addListener(
            marker,
            "click",
            (function (markerd, ii) {
              return function () {
                var contentString = "";
                var prop = "photos";
                if (results[ii].hasOwnProperty(prop)) {
                  _this.setState({ activeNumber: results[ii].name });
                  contentString =
                    '<h3 style="font-size:20px; text-align:center">' +
                    results[ii].name +
                    '</h3><img src="' +
                    results[ii].photos[0].getUrl() +
                    '" alt="Loading" width="160" height="80"> <div id="content"><div className=""><div></div><p>' +
                    results[ii].vicinity +
                    '</p> <a href="' +
                    "https://www.google.com/maps/place/" +
                    results[ii].vicinity +
                    '" target="_blank">Get Directions</a></div>' +
                    "</div>";
                } else {
                  _this.setState({ activeNumber: results[ii].name });
                  contentString =
                    '<h3 style="font-size:20px; text-align:center">' +
                    results[ii].name +
                    '</h3> <div id="content"><div className=""><div></div><p>' +
                    results[ii].vicinity +
                    '</p> <a href="' +
                    "https://www.google.com/maps/place/" +
                    results[ii].vicinity +
                    '" target="_blank">Get Directions</a></div>' +
                    "</div>";
                }
                infowindow.setContent(contentString);
                infowindow.open(map, this);
              };
            })(marker, i)
          );
          markers.push(marker);
        }
        await _this.setState({
          location: location,
          markerList: markers,
        });
        _this.getDistance();
      }
    });
    _this.setState({
      map,
    });
    // await MapController.customMarkers(this.state.communityObject, 'singlemark', this, '', s3BucketUrl_WebsiteImages + 'SuiteAmerica-M' + 'ap-Marker-Red.png', map);
    await this.methodx();
  }
  async getDistance() {
    for (var i = 0; i < this.state.location.length; i++) {
      if (
        this.state.location[i].latitude != "" &&
        this.state.location[i].latitude != undefined &&
        this.state.location[i].latitude != null &&
        this.state.location[i].longitude != "" &&
        this.state.location[i].longitude != undefined &&
        this.state.location[i].longitude != null
      ) {
        const distanceVal = this.distanceCal(
          this.state.latitude,
          this.state.longitude,
          this.state.location[i].latitude,
          this.state.location[i].longitude
        );
        let updatedPosts = update(this.state.location, {
          [i]: {
            distance: {
              $set: parseFloat(distanceVal),
            },
          },
        });
        await this.setState({
          location: updatedPosts,
        });
      } else {
        let updatedPosts = update(this.state.location, {
          [i]: {
            distance: {
              $set: 0,
            },
          },
        });
        await this.setState({
          location: updatedPosts,
        });
      }
      if (this.state.location.length == i + 1) {
        await this.setState({
          location: _.sortBy(this.state.location, [
            function (o) {
              return o.distance;
            },
          ]),
        });
      }
    }
  }
  distanceCal(lat1, lon1, lat2, lon2, unit) {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit == "K") {
      dist = dist * 1.609344;
    }
    if (unit == "N") {
      dist = dist * 0.8684;
    }
    return dist.toFixed(2);
  }
  async handleClick(type) {
    $(".nav-item").removeClass("active");
    $("#" + type).addClass("active");
    var data = [];
    if (type == "private") {
      data = this.state.privateScools;
    } else if (type == "public") {
      data = this.state.publicScools;
    } else if (type == "charter") {
      data = this.state.charterScools;
    }
    await this.setState({
      allScools: data,
    });
  }
  async handleOpenCarousel(row) {
    await this.setState({ fullpopdata: row });
    jQuery(
      function ($) {
        setTimeout(
          function () {
            $("#fullmodel-Carousel1").modal("show");
          }.bind(this),
          500
        );
      }.bind(this)
    );
    // this.setState({ imgloader: false })
    // $('document').ready(function () {
    //   $('#smallmodel-Carousel1').modal('show');
    // });
    // jQuery(function ($) {
    //   setTimeout(function () {
    //     this.setState({ imgloader: true })
    //   }.bind(this), 500);
    // }.bind(this));
  }
  async handleclose() {
    $("document").ready(function () {
      $("#smallmodel-Carousel1").modal("hide");
    });
  }
  render() {
    const communityALLImages = this.state.communityALLImages;
    const urlArray = [];
    let arrow =
      Object.keys(this.state.communityObject).length > 0
        ? communityALLImages.map((galleyImage, galleyImageIndex) => {
            if (galleyImage.image_name != null) {
              urlArray.push({
                original: galleyImage.image_name,
                thumbnail: galleyImage.image_name,
              });
            }
          })
        : "";
    let filterIMages = _.uniqBy(urlArray, "original");
    var finalLocation = _.orderBy(this.state.location, ["name"], ["asc"]);
    console.log(this.state.PageLoader, "this.state.PageLoader");
    return (
      <div className="SAweb_updateUi">
        <Header />
        {!this.state.PageLoader ? (
          <Loader />
        ) : (
          <div>
            <section className="SAweb_banner-3 SAweb_pdBanner bg-white py-5">
              <div className="container">
                {communityALLImages.length > 0 ? (
                  <div className="box mb-3">
                    <div className="item">
                      <img src={communityALLImages[0].image_name} alt="" />
                      {/* <button className="btn" type="button"> 3D Tour </button> */}
                    </div>
                    <div className="item">
                      <img src={communityALLImages[1].image_name} alt="" />
                    </div>
                    <div className="item">
                      <img src={communityALLImages[2].image_name} alt="" />
                      {communityALLImages.length > 2 ? (
                        <button
                          className="btn photos"
                          onClick={() =>
                            this.handleOpenCarousel(this.state.communityObject)
                          }
                          type="button"
                        >
                          {
                            communityALLImages.slice(
                              2,
                              communityALLImages.length
                            ).length
                          }{" "}
                          More Photos
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="subHeading py-4">
                  <div className="row">
                    <div className="col-sm-8">
                      {Object.keys(this.state.communityObject).length > 0 ? (
                        <div>
                          <h1>
                            {this.state.communityObject.community_name !=
                            undefined
                              ? this.state.communityObject.community_name
                              : ""}
                          </h1>
                          <h2>
                            {this.state.communityObject.address != undefined
                              ? this.state.communityObject.address
                              : ""}
                            ,{" "}
                            {this.state.communityObject.city_name != undefined
                              ? this.state.communityObject.city_name
                                  .toLowerCase()
                                  .split(" ")
                                  .map(
                                    (s) =>
                                      s.charAt(0).toUpperCase() + s.substring(1)
                                  )
                                  .join(" ")
                              : ""}
                            ,{" "}
                            {this.state.communityObject.state_name != undefined
                              ? this.state.communityObject.state_name
                                  .toLowerCase()
                                  .split(" ")
                                  .map(
                                    (s) =>
                                      s.charAt(0).toUpperCase() + s.substring(1)
                                  )
                                  .join(" ")
                              : ""}{" "}
                            {this.state.communityObject.ZipCode != undefined
                              ? this.state.communityObject.ZipCode
                              : ""}
                          </h2>
                        </div>
                      ) : null}
                      {/* <p>This property perfectly encompasses the newest luxury rental homes at Santana Row. From the interior amenities to the surrounding retail, restaurant and entertainment options, 101 San Fernando offers the ideal choice for people looking forward to the next generation of innovative living.</p>
                    <ul>
                      <li> <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/screen-3/bed.svg" alt="" width="20px" /> 2 Bedrooms </li>
                      <li> <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/screen-3/bath.svg" alt="" width="20px" /> 2 Bathroom </li>
                      <li> <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/screen-3/kitchen.svg" alt="" width="15px" /> Kitchen </li>
                      <li> <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/screen-3/home.svg" alt="" width="17px" /> 1 Garage </li>
                    </ul> */}
                    </div>
                    <div className="col-sm-4 text-end">
                      <button
                        className="btn black-btn"
                        type="button"
                        onClick={this.handleClick2}
                      >
                        {" "}
                        Reserve Now{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="SAweb_amenities bg-white pb-5">
              <div className="container">
                <h2>Amenities</h2>
                {this.state.PageLoader ? (
                  this.state.appartmentamenitieslist.length > 0 ? (
                    <div className="box box_second">
                      {this.state.appartmentamenitieslist
                        .slice(0, 8)
                        .map((row, index) => {
                          return (
                            <div className="item" key={index}>
                              <img
                                width="55"
                                src={
                                  s3BucketUrl +
                                  "Amenities/" +
                                  row.aparment_amenity_name.replace(
                                    /[^A-Z0-9]+/gi,
                                    ""
                                  ) +
                                  ".png"
                                }
                              />
                              <span>{row.aparment_amenity_name}</span>
                            </div>
                          );
                        })}
                    </div>
                  ) : (
                    <div
                      className="text-center"
                      style={{
                        fontSize: "15px",
                        width: "100%",
                        margin: "auto",
                        textAlign: "center",
                      }}
                    >
                      No Amenities Found
                    </div>
                  )
                ) : (
                  ""
                )}
                {this.state.appartmentamenitieslist.length > 8 ? (
                  <button
                    type="button"
                    className="btn modalBtn"
                    data-toggle="modal"
                    data-target="#staticBackdrop"
                  >
                    <i className="bi bi-plus"></i>
                  </button>
                ) : (
                  ""
                )}
                <div
                  className="modal fade SAweb_modalPoup"
                  id="staticBackdrop"
                  data-backdrop="static"
                  data-keyboard="false"
                  tabIndex="-1"
                  aria-labelledby="staticBackdropLabel"
                  role="dialog"
                >
                  <div className="modal-dialog modal-lg-SAweb  modal-dialog-scrollable modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header border-0">
                        <button
                          type="button"
                          className="btn close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          {" "}
                          <i className="bi bi-x"></i>{" "}
                        </button>
                      </div>
                      <div className="modal-body border-0 mb-5">
                        <div className="SAweb_amenities ">
                          <div className="box">
                            {this.state.appartmentamenitieslist.map(
                              (row, index) => {
                                return (
                                  <div className="item" key={index}>
                                    <img
                                      src={
                                        s3BucketUrl +
                                        "Amenities/" +
                                        row.aparment_amenity_name.replace(
                                          /[^A-Z0-9]+/gi,
                                          ""
                                        ) +
                                        ".png"
                                      }
                                      width="55px"
                                      alt=""
                                    />
                                    <span>{row.aparment_amenity_name}</span>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section>
              <div className="SAweb_neighbourhood py-5">
                <div className="  ">
                  <div className="container-fluid">
                    <h2 className="ps-3">Neighborhood</h2>
                    <ul className="nav nav-tabs mt-5">
                      <li>
                        <button
                          className="nav-link active"
                          onClick={this.typeView}
                          id="name"
                          data-toggle="tab"
                          data-target="#commute"
                          type="button"
                          role="tab"
                          aria-controls="commute"
                          aria-selected="false"
                        >
                          Commute
                        </button>
                      </li>
                      {this.state.types.map((row, index) => {
                        return (
                          <li
                            key={index}
                            onClick={() => this.onetype(row, index)}
                          >
                            <button
                              className="nav-link "
                              id={row.name}
                              aria-selected="true"
                            >
                              {row.name}
                            </button>
                          </li>
                        );
                      })}
                    </ul>
                  </div>

                  <div className="tab-content myTabContentVIew">
                    <div
                      className="tab-pane fade in active"
                      id="commute"
                      role="tabpanel"
                      aria-labelledby="commute-tab"
                    >
                      <div className="sm-d-flex ">
                        <div className="col-sm-6">
                          {this.state.showlist && !this.state.showSearch ? (
                            <div className="tabsView">
                              <ul className="distanceList">
                                {finalLocation.map((row, index) => {
                                  return (
                                    <li
                                      className={
                                        this.state.activeNumber == row.name
                                          ? "item test"
                                          : "item"
                                      }
                                      key={index}
                                      onClick={() =>
                                        this.flyToPlace(row, index, "locations")
                                      }
                                    >
                                      <p>
                                        {" "}
                                        {row.name} <span>{row.address}</span>{" "}
                                      </p>
                                      <p> {row.distance} mi</p>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          ) : (
                            <div className="tabsView">
                              <h3>What’s My Commute?</h3>
                              <input
                                type="text"
                                id="txtPlaces"
                                placeholder="Enter a location"
                                onChange={this.destinationLocation}
                              />
                              <div className="view-btns">
                                <button
                                  type="button"
                                  className="btn black-btn"
                                  onClick={this.handleFormSubmit}
                                >
                                  Search
                                </button>
                                <button
                                  type="button"
                                  className="btn black-btn"
                                  onClick={this.resetData}
                                >
                                  Reset
                                </button>
                              </div>
                              <div id="dvDistance"></div>
                            </div>
                          )}
                        </div>
                        <div className="col-sm-6">
                          <div
                            id={"map" + this.state.communityObject.community_id}
                            className="map-alignment"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="SAweb_scoreView">
              <div className="box mb-5">
                {this.state.walkscore == null ? (
                  ""
                ) : (
                  <div className="item">
                    <div className="wlakScoreView">
                      <div className="score_view">
                        <div className="pic">
                          <img
                            src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/screen-3/walk.svg"
                            alt=""
                            width="20px"
                          />
                        </div>

                        <Progress
                          type="circle"
                          percent={this.state.walkscore}
                          format={(percent) => `${percent}`}
                        />
                      </div>
                      <p>Walk Score</p>
                    </div>
                  </div>
                )}

                {this.state.bikescore == null ? (
                  ""
                ) : (
                  <div className="item">
                    <div className="wlakScoreView">
                      <div className="score_view">
                        <div className="pic">
                          <img
                            src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/screen-3/bike.svg"
                            alt=""
                            width="40px"
                          />
                        </div>
                        <Progress
                          type="circle"
                          percent={this.state.bikescore}
                          format={(percent) => `${percent}`}
                        />
                      </div>{" "}
                      <p>Bike Score</p>
                    </div>
                  </div>
                )}
                {this.state.transitscore == null ? (
                  ""
                ) : (
                  <div className="item">
                    <div className="wlakScoreView">
                      <div className="score_view">
                        <div className="pic">
                          <img
                            src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/screen-3/bus.svg"
                            alt=""
                            width="30px"
                          />
                        </div>
                        <Progress
                          type="circle"
                          percent={this.state.transitscore}
                          format={(percent) => `${percent}`}
                        />
                      </div>
                      <p>Transit Score</p>
                    </div>
                  </div>
                )}
              </div>
              <a
                href="#"
                data-toggle="modal"
                data-target="#walkscoreInfoMadal"
                className="howworks"
              >
                {" "}
                How Does It Work?{" "}
              </a>
            </section>

            {/*------- great  schools --------*/}
            <section className="SAweb_schools second border-top border-2 border-dark">
              <div className="SAweb_location bg-white ">
                <div className="container">
                  <div className="row   mx-0">
                    <div className="col-sm-1 ">
                      <div className="HeadingRotate">
                        <h2 className="h2_rotate">Schools</h2>
                      </div>
                    </div>
                    <div className="col-sm-11 py-5">
                      {/* tabs */}
                      <ul className="nav nav-tabs">
                        <li
                          className="nav-item active"
                          id="public"
                          onClick={() => this.handleClick("public")}
                        >
                          <button
                            className="nav-link "
                            type="button"
                            aria-selected="true"
                          >
                            Public
                          </button>
                        </li>
                        <li
                          className="nav-item"
                          id="private"
                          onClick={() => this.handleClick("private")}
                        >
                          <button
                            className="nav-link "
                            type="button"
                            aria-selected="true"
                          >
                            Private
                          </button>
                        </li>
                        <li
                          className="nav-item"
                          id="charter"
                          onClick={() => this.handleClick("charter")}
                        >
                          <button
                            className="nav-link "
                            type="button"
                            aria-selected="true"
                          >
                            Charter
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <a
                            rel="nofollow"
                            href="https://www.greatschools.org"
                            target="_blank"
                          >
                            <img
                              className="great-schools-main-logo"
                              src="/images/greatSchools/logomain.png"
                            />
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content myTabContentVIew">
                        <div
                          className="tab-pane fade in active"
                          id="public"
                          role="tabpanel"
                          aria-labelledby="public-tab"
                        >
                          <section className=" bg-white pt-5 position-relative">
                            {this.state.PageLoader ? (
                              this.state.allScools.length > 0 ? (
                                <div className="owl-slider pb-5 SAweb_sliderBottomArrows billingSlider">
                                  <div id="carouselSchool">
                                    <OwlCarousel
                                      className="owl-theme owl-carousel btn-left"
                                      {...{
                                        loop: false,
                                        margin: 10,
                                        nav: true,
                                        dots: false,
                                        items: 1,
                                        navContainerClass: "owl-buttons",
                                        navText: [
                                          "<i class='glyphicon glyphicon-menu-left'></i>",
                                          "<i class='glyphicon glyphicon-menu-right'></i>",
                                        ],
                                        responsive: {
                                          600: { items: 1, loop: false },
                                          767: { items: 2, loop: false },
                                          992: { items: 3, loop: false },
                                          1199: { items: 3, loop: false },
                                        },
                                      }}
                                    >
                                      {this.state.allScools.map(
                                        (row, index) => {
                                          return (
                                            // individual schools card
                                            <div className="item" key={index}>
                                              <div className="caption">
                                                <p>
                                                  <a
                                                    target="_blank"
                                                    href={row.overviewUrl}
                                                    rel="nofollow"
                                                  >
                                                    {row.name}
                                                  </a>
                                                </p>
                                                <p>
                                                  {" "}
                                                  <span>
                                                    {row.schoolSummary.substring(
                                                      0,
                                                      213
                                                    )}
                                                    {row.schoolSummary.length >
                                                    216
                                                      ? "... "
                                                      : ""}
                                                    {row.schoolSummary.length >
                                                    216 ? (
                                                      <a
                                                        target="_blank"
                                                        href={row.overviewUrl}
                                                        rel="nofollow"
                                                      >
                                                        more
                                                      </a>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </span>{" "}
                                                </p>
                                                <ul>
                                                  {row.rating != null ? (
                                                    <li>
                                                      <a
                                                        target="_blank"
                                                        href={row.overviewUrl}
                                                        rel="nofollow"
                                                      >
                                                        <img
                                                          className="rating-image"
                                                          src={`/images/greatSchools/ratings/${row.rating}-medium.png`}
                                                        />
                                                      </a>
                                                      <span>
                                                        GreatSchools Summary
                                                        Rating
                                                      </span>
                                                    </li>
                                                  ) : (
                                                    <li>
                                                      <a
                                                        target="_blank"
                                                        href={row.overviewUrl}
                                                        rel="nofollow"
                                                      >
                                                        <img
                                                          className="rating-image"
                                                          src={`/images/greatSchools/ratings/na-medium.png`}
                                                        />
                                                      </a>
                                                      <span>
                                                        GreatSchools Summary
                                                        Rating
                                                      </span>
                                                    </li>
                                                  )}
                                                  <li>
                                                    {" "}
                                                    <i className="bi bi-telephone">
                                                      {" "}
                                                    </i>{" "}
                                                    {row.phone}
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </OwlCarousel>
                                  </div>
                                </div>
                              ) : (
                                <div className="no_data_found">
                                  No Data Found!
                                </div>
                              )
                            ) : (
                              ""
                            )}
                            <span className="school-copy-right">
                              Data provided by{" "}
                              <a
                                rel="nofollow"
                                href="https://www.greatschools.org"
                                target="_blank"
                              >
                                GreatSchools.org
                              </a>{" "}
                              © 2023. All rights reserved.
                            </span>
                          </section>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
        <RequestCallback />
        <div
          id="smallmodel-Carousel1"
          className=" modal fade SAweb_modalPoup Saweb_MorePhotosPopup "
          role="dialog"
          data-backdrop="static"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered modal-lg-2">
            <div className="modal-content notification-popup borderRadius">
              <div className="modal-header border-0">
                <button
                  type="button"
                  className="btn close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  {" "}
                  <i className="bi bi-x"></i>{" "}
                </button>
              </div>
              <div className="">
                {communityALLImages.length > 0 && !this.state.imgloader ? (
                  <div className="">''</div>
                ) : (
                  <div className="Saweb_MorePhotosSlider">
                    <OwlCarousel
                      className="owl-theme owl-carousel"
                      {...{
                        loop: false,
                        nav: true,
                        dots: true,
                        items: 1,
                        navContainerClass: "owl-buttons",
                        navText: [
                          "<i class='glyphicon glyphicon-menu-left'></i>",
                          "<i class='glyphicon glyphicon-menu-right'></i>",
                        ],
                        responsive: {
                          600: { items: 1, nav: true, loop: false },
                          767: { items: 1, nav: true, loop: false },
                          992: { items: 1, nav: true, loop: false },
                          1199: { items: 1, nav: true, loop: false },
                        },
                      }}
                    >
                      {communityALLImages.map((row, index) => {
                        return (
                          <div className="item" key={index}>
                            <img
                              className="img-responsive"
                              src={row.image_name}
                              alt={row}
                            />
                          </div>
                        );
                      })}
                    </OwlCarousel>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <FullPopup fullpopdata={this.state.fullpopdata} />

        <div
          id="walkscoreInfoMadal"
          className="modal fade SAweb_modalPoup"
          role="dialog"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header border-0">
                <button
                  type="button"
                  className="btn close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="bi bi-x"></i>{" "}
                </button>
              </div>
              <div className="">
                <div className="modal-body">
                  <div className="all-score-table">
                    <table width="100%">
                      <thead>
                        <tr>
                          <th width="30%">Transit Score</th>
                          <th width="70%">Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <b>90–100</b>
                          </td>
                          <td>
                            <b>Rider's Paradise</b>
                            <span>World-class public transportation.</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>70–89</b>
                          </td>
                          <td>
                            <b>Excellent Transit</b>
                            <span>Transit is convenient for most trips.</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>50–69</b>
                          </td>
                          <td>
                            <b>Good Transit</b>
                            <span>
                              Many nearby public transportation options.
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>25–49</b>
                          </td>
                          <td>
                            <b>Some Transit</b>
                            <span>
                              A few nearby public transportation options.
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>0-24</b>
                          </td>
                          <td>
                            <b>Minimal Transit</b>
                            <span>It is possible to get on a bus.</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table width="100%">
                      <thead>
                        <tr>
                          <th width="30%">Walk Score</th>
                          <th width="70%">Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <b>90–100</b>
                          </td>
                          <td>
                            <b>Walker's Paradise</b>
                            <span>Daily errands do not require a car.</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>70–89</b>
                          </td>
                          <td>
                            <b>Very Walkable</b>
                            <span>
                              Most errands can be accomplished on foot.
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>50–69</b>
                          </td>
                          <td>
                            <b>Somewhat Walkable</b>
                            <span>
                              Some errands can be accomplished on foot.
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>25–49</b>
                          </td>
                          <td>
                            <b>Car-Dependent</b>
                            <span>Most errands require a car.</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>0–24</b>
                          </td>
                          <td>
                            <b>Car-Dependent</b>
                            <span>Almost all errands require a car.</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table width="100%">
                      <thead>
                        <tr>
                          <th width="30%">Bike Score</th>
                          <th width="70%">Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <b>90–100</b>
                          </td>
                          <td>
                            <b>Biker's Paradise</b>
                            <span>
                              Daily errands can be accomplished on a bike.
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>70–89</b>
                          </td>
                          <td>
                            <b>Very Bikeable</b>
                            <span>Biking is convenient for most trips.</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>50–69</b>
                          </td>
                          <td>
                            <b>Bikeable</b>
                            <span>Some bike infrastructure.</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>0–49</b>
                          </td>
                          <td>
                            <b>Somewhat Bikeable</b>
                            <span>Minimal bike infrastructure.</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default PropertyDetailsnew;
