const ContactInfo = () => {
    return (
            <div className="container-fluid contact-adrs bg-white">
                    <div className="container contact-adrs mrg-btm-50 mrg-top-50">
                        <div className="col-md-4 contact-adrs-item">
                            <h2>Northern California</h2>
                            <strong>Corporate Headquarters: El Dorado Hills</strong> 
                            <ul>
                            <li>4970 Windplay Dr. El Dorado Hills, CA 95762 </li>
                            </ul>
                            <b>Accounting & Invoice Inquiries </b> 
                            <ul>
                            <li><i className="fa fa-phone"></i><span>800-410-4305</span> </li>
                            <li><i className="fa fa-phone"></i><span>916-941-7970</span></li>
                            <li><i className="fa fa-fax"></i><span>916-941-7989</span></li>
                            <li><i className="fa fa-envelope"></i> <a href="mailto:accounting@suiteamerica.com">Accounting</a> </li>
                            </ul>
                        </div>
                        <div className="col-md-4 contact-adrs-item">
                            <h2> &nbsp;</h2>
                            <strong>Regional Sales:San Jose</strong> 
                            <ul>
                            <li>2033 Gateway Pl. San Jose, CA 95110 </li>
                            </ul>
                            <b>Reservations & Customer Service </b> 
                            <ul>
                            <li><i className="fa fa-phone"></i><span>408-573-6925</span></li>
                            <li><i className="fa fa-phone"></i><span>800-410-4305</span></li>
                            <li><i className="fa fa-fax"></i><span>916-941-7989</span></li>
                            <li><i className="fa fa-envelope"></i><a href="mailto:info@suiteamerica.com">Western Region Sales</a> </li>
                            </ul>
                        </div>
                        <div className="col-md-4 contact-adrs-item">
                            <h2> &nbsp;</h2>
                            <strong>Regional Sales: San Francisco</strong> 
                            <ul>
                            <li>101 California St. #2710 San Francisco, CA 94111 </li>
                            </ul>
                            <b> Reservations & Customer Service </b> 
                            <ul>
                            <li><i className="fa fa-phone"></i><span>415-365-7155</span></li>
                            <li><i className="fa fa-phone"></i><span>800-410-4305</span></li>
                            <li><i className="fa fa-fax"></i><span>916-941-7989</span></li>
                            <li><i className="fa fa-envelope"></i><a href="mailto:info@suiteamerica.com">Western Region Sales</a> </li>
                            </ul>
                        </div>
                        <div className="col-md-4 contact-adrs-item">
                            <h2> &nbsp;</h2>
                            <strong>Regional Sales:Mountain View</strong> 
                            <ul>
                            <li>800 West El Camino Real #180 Mountain View, CA 94040 </li>
                            </ul>
                            <b> Reservations & Customer Service</b> 
                            <ul>
                            <li><i className="fa fa-phone"></i><span>650-943-2305</span></li>
                            <li><i className="fa fa-phone"></i><span>800-410-4305</span></li>
                            <li><i className="fa fa-fax"></i><span>916-941-7989</span></li>
                            <li><i className="fa fa-envelope"></i><a href="mailto:info@suiteamerica.com">Western Region Sales</a> </li>
                            </ul>
                        </div>
                        <div className="col-md-4 contact-adrs-item">
                            <h2> &nbsp;</h2>
                            <strong>Regional Sales:Sunnyvale </strong> 
                            <ul>
                            <li>640 West California Ave. #210 Sunnyvale, CA 94086 </li>
                            </ul>
                            <b> Reservations & Customer Service</b> 
                            <ul>
                            <li><i className="fa fa-phone"></i><span>408-769-6154</span></li>
                            <li><i className="fa fa-phone"></i><span>800-410-4305</span></li>
                            <li><i className="fa fa-fax"></i><span>916-941-7989</span></li>
                            <li><i className="fa fa-envelope"></i><a href="mailto:info@suiteamerica.com">Western Region Sales</a> </li>
                            </ul>
                        </div>
                        <div className="col-md-4 contact-adrs-item">
                            <h2> &nbsp;</h2>
                            <strong>Warehouse & Distribution: Santa Clara  </strong> 
                            <ul>
                            <li>354 Martin Ave. Santa Clara, CA 95050 </li>
                            </ul>
                            <b> Distribution Inquires </b> 
                            <ul>
                            <li><i className="fa fa-phone"></i><span>408-727-6949</span></li>
                            </ul>
                        </div>
                        <div className="clearfix"></div>
                        <div className="col-md-4 contact-adrs-item">
                            <h2> &nbsp;</h2>
                            <strong>Warehouse & Distribution: Stockton </strong> 
                            <ul>
                            <li>2479 Station Dr. Stockton, CA 95215 </li>
                            </ul>
                            <b>  Distribution Inquires </b> 
                            <ul>
                            <li><i className="fa fa-phone"></i><span>209-465-3383</span></li>
                            <li><i className="fa fa-fax"></i><span>209-465-3810</span></li>
                            </ul>
                        </div>
                        <div className="col-md-4 contact-adrs-item">
                            <h2> &nbsp;</h2>
                            <strong>Warehouse & Distribution:Hayward </strong> 
                            <ul>
                            <li>3300 Arden Rd. Hayward, CA 94544 </li>
                            </ul>
                            <b>  Distribution Inquires</b> 
                            <ul>
                            <li><i className="fa fa-phone"></i><span>800-367-9501</span></li>
                            <li><i className="fa fa-phone"></i><span>510-429-9700</span></li>
                            <li><i className="fa fa-fax"></i><span>510-429-6780</span></li>
                            </ul>
                        </div>
                        <div className="col-md-12 contact-adrs-item">
                            <div className="row">
                            <div className="col-md-4 contact-adrs-item">
                                <h2>Southern California</h2>
                                <strong>Sales & Distribution: San Diego</strong> 
                                <ul>
                                    <li>9597 Distribution Ave. San Diego, CA 92121 </li>
                                </ul>
                                <b> Reservations & Customer Service  </b> 
                                <ul>
                                    <li><i className="fa fa-phone"></i><span>858-549-1617</span></li>
                                    <li><i className="fa fa-phone"></i><span>800-410-4305</span></li>
                                    <li><i className="fa fa-fax"></i><span>858-549-1621</span></li>
                                    <li><i className="fa fa-envelope"></i><a href="mailto:info@suiteamerica.com">Western Region Sales</a> </li>
                                </ul>
                            </div>
                            </div>
                        </div>
                        <div className="col-md-4 contact-adrs-item">
                            <h2> Oregon</h2>
                            <strong>Regional Sales:Portland </strong> 
                            <ul>
                            <li>10150 SW Nimbus Ave., Suite E-4 Portland, OR 97223</li>
                            </ul>
                            <b>  Customer Service </b> 
                            <ul>
                            <li><i className="fa fa-phone"></i><span>503-443-2033</span></li>
                            <li><i className="fa fa-fax"></i><span>209-465-3810</span></li>
                            <li><i className="fa fa-envelope"></i><a href="mailto:info@suiteamerica.com">US Suites</a> </li>
                            </ul>
                        </div>
                        <div className="col-md-4 contact-adrs-item">
                            <h2> Washington</h2>
                            <strong>Regional Sales: Seattle Area </strong> 
                            <ul>
                            <li>26215 29th Ave. NW Stanwood, WA 98292</li>
                            </ul>
                            <b>  Reservations & Customer Service  </b> 
                            <ul>
                            <li><i className="fa fa-phone"></i><span>206-262-7470</span></li>
                            <li><i className="fa fa-phone"></i><span>800-410-4305</span></li>
                            <li><i className="fa fa-fax"></i><span>801-285-7401</span></li>
                            <li><i className="fa fa-envelope"></i><a href="mailto:info@suiteamerica.com">Western Region Sales</a> </li>
                            </ul>
                        </div>
                        <div className="col-md-4 contact-adrs-item">
                            <h2> Virginia</h2>
                            <strong>Sales & Distribution: Alexandria </strong> 
                            <ul>
                            <li>6464 General Green Way, Suite F Alexandria, VA 22312</li>
                            </ul>
                            <b>   Customer Service   </b> 
                            <ul>
                            <li><i className="fa fa-phone"></i><span>877-827-8483</span></li>
                            <li><i className="fa fa-fax"></i><span>703-461-0227</span></li>
                            <li><i className="fa fa-envelope"></i><a href="mailto:info@suiteamerica.com">Eastern Region Sales   </a> </li>
                            </ul>
                        </div>
                        <div className="col-md-4 contact-adrs-item">
                            <h2> Texas</h2>
                            <strong>Sales & Distribution: Austin </strong> 
                            <ul>
                            <li>701 Dessau Rd., Suite 801 Austin, TX 78754</li>
                            </ul>
                            <b> Reservations & Customer Service </b> 
                            <ul>
                            <li><i className="fa fa-phone"></i><span>512-300-0807</span></li>
                            <li><i className="fa fa-fax"></i><span>512-330-4729</span></li>
                            <li><i className="fa fa-envelope"></i><a href="mailto:info@suiteamerica.com">Western Region Sales</a> </li>
                            </ul>
                        </div>
                        <div className="col-md-4 contact-adrs-item">
                            <h2> North Carolina</h2>
                            <strong>Sales & Distribution: Charlotte</strong> 
                            <ul>
                            <li>6201 Fairview Rd., Suite 200 Charlotte , NC 22312</li>
                            </ul>
                            <b> Customer Service </b> 
                            <ul>
                            <li><i className="fa fa-phone"></i><span>877-827-8483</span></li>
                            <li><i className="fa fa-fax"></i><span>703-461-0227</span></li>
                            <li><i className="fa fa-envelope"></i><a href="mailto:info@suiteamerica.com">Eastern Region Sales</a> </li>
                            </ul>
                        </div>
                        <div className="col-md-12 contact-adrs-item">
                            <div className="row">
                            <div className="col-md-4 contact-adrs-item">
                                <h2>National Call Center</h2>
                                <strong>Sales & Distribution: San Diego</strong> 
                                <ul>
                                    <li>9597 Distribution Ave. San Diego, CA 92121 </li>
                                </ul>
                                <b> Reservations & Customer Service  </b> 
                                <ul>
                                    <li> 800-SUITE-4-1  </li>
                                    <li>  800-784-8341   </li>
                                    <li> <a href="mailto:info@suiteamerica.com">info@suiteamerica.com</a> </li>
                                </ul>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
    )
}
export default ContactInfo;