/* Importing the node modules, child components, services and controllers used 
   inside appController object */

   'use strict'
   import LocationService from '../../../services/locationService';   
   
   const locationController = {  
    async getCommunityData(dataJson){
         const communityData = await LocationService.getCommunityInfoo(dataJson);
         return communityData
     },
          async loadMore(thisObjest){  
                await thisObjest.setState({loadMoreButton: true});
                var companyName='';
                const userdata= await JSON.parse(localStorage.getItem('userData'))
                if(thisObjest.props.companyName=='Nike'){
                 companyName='Nike';
                }else if(thisObjest.props.companyName=='NikeIntern'){
                  companyName='NikeIntern';
                }else if(thisObjest.props.companyName=='ServiceNow Intern'){
                  companyName='ServiceNow Intern';
                }
                else if(thisObjest.props.companyName=='Adobe'){
                  companyName='Adobe';
                }
                else{
                 companyName='suiteamerica';
                }
                const limitData={
                  startLimit: thisObjest.state.startLimit,
                  companyname:companyName,
                  latitude: thisObjest.state.searchLatitude !=''? thisObjest.state.searchLatitude : '',
                  longitude: thisObjest.state.searchLongitude !=''? thisObjest.state.searchLongitude : '',
                  address: (thisObjest.state.savedSearchData.address ==undefined || thisObjest.state.savedSearchData.address =='')? '':thisObjest.state.savedSearchData.address,
                  state_id:( thisObjest.state.savedSearchData.state_id ==undefined || thisObjest.state.savedSearchData.state_id =='')? '':thisObjest.state.savedSearchData.state_id, 
                  city_id: (thisObjest.state.savedSearchData.city_id ==undefined || thisObjest.state.savedSearchData.city_id =='')? '':thisObjest.state.savedSearchData.city_id,
                  amenitiesId: (thisObjest.state.savedSearchData.amenitiesId ==undefined || thisObjest.state.savedSearchData.amenitiesId =='' )? '':thisObjest.state.savedSearchData.amenitiesId,
                  communityName: (thisObjest.state.savedSearchData.communityName ==undefined || thisObjest.state.savedSearchData.communityName =='')?'':thisObjest.state.savedSearchData.communityName,
                  countryId: (thisObjest.state.savedSearchData.countryId ==undefined || thisObjest.state.savedSearchData.countryId =='')?'': thisObjest.state.savedSearchData.countryId,
                  userid:(userdata!='' && userdata !=null && userdata !=undefined)? userdata.Id:''
                };
               const communityData = await LocationService.getCommunityData(limitData);
               await thisObjest.setState({startLimit: thisObjest.state.startLimit+10, less: thisObjest.state.less+1, communityData: _.concat(thisObjest.state.communityData, communityData), loadMoreButton: false, loadMoreString: communityData.length>=10? true: false});
           
              }
    
   }
   
   export default locationController
   