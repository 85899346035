import MainController from "../../controller/mainController";
import SubFooter from "../../common/subFooter";
import GuestHeader from '../../common/guestHeader';
import Footer from '../../common/footer';
import MediaFooter from "./common/mediaFooter";
import MetaTags from '../../common/metaTags';
import ServerMetaTags from '../../../server/metaTags';

/* MediaOurMission Component initialization */
class MediaOurMission extends React.Component {
    /* Initializing objects of its MediaContact class */
    constructor(props) {
        super(props);
    }
    /* It is invoked immediately before mounting occurs */
    componentWillMount() {
        MainController.getInitialInformation();
    }
    /* It is invoked to return html content */
    render() {
        const metaData = ServerMetaTags.urlString.getMetaData(this.props.match.url);
        return (
            <div>
            <MetaTags 
             metaTitle = {metaData.title}
             metaDesription = {metaData.description}
             metaUrl = {metaData.canonical} />
             <GuestHeader/> 
            <div className="cover-tophd-ht company_ht_cover"></div>
             <div className="quote-section bg-white">
                <div className="container mediakit">
                   <div className="plaine-text global-coverage-wrap col-md-10 col-md-offset-1 wow fadeInUp" data-wow-delay="0.5s">
                   <h2 className="mrg-btm-50 color-red">OUR MISSION</h2>
                        <p>We've always thought that a warm welcome was much more than offering 24/7 service and extra pillows. With 
                        every new customer, it's about making a connection on an emotional level. We welcomed our first guest in 
                        1990 with this philosophy in mind, and we've been opening our doors to everyone from interns to Fortune 500 
                        executives with the same level of detail and care ever since. </p>
    <p>We aim to delight guests with the unexpected, and look for opportunities to personalize our service, whether 
    expressly asked to or not. We believe that genuine gestures are contagious. We can't get enough of taking on 
    challenges that others would turn down.</p>
                   </div>
                 <MediaFooter />
                 </div>
              </div>   
             <SubFooter />
             <Footer/>
            </div>
        );
    }
}
export default MediaOurMission;