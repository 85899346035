import GreatSchools from "../../common/community/greatSchools";
import Spinner from "../../common/spinner";
import WalkScore from "../../common/community/walkScore";
import Weather from "../citylandingpage/weather";
import Poi from "../citylandingpage/pointofinterests";
import SearchMap from "../../common/community/SearchMap";
const NeighBoorHood = props => {
  const mapslist = () => {
    var list = [
      { name: "RESTAURANTS", faicons: "fa fa-cutlery" },
      { name: "BARS", faicons: "fa fa-glass" },
      { name: "GROCERIES", faicons: "fa-shopping-basket" },
      { name: "PARKS", faicons: "fa fa-tree" },
      { name: "SHOPPING", faicons: "fa fa-shopping-bag" },
      { name: "ENTERTAINMENT", faicons: "fa fa-ticket" }
    ];
    return (
      <ul>
        {list.map((maplist, mapindex) => {
          return (
            <li key={mapindex}>
              <a>
                <i className={maplist.faicons} />
                {maplist.name}
              </a>
            </li>
          );
        })}
      </ul>
    );
  };
  return (
    <div>
      <div className=" mg-t-20 mg-b-40" id="NEIGHBORHOOD" />
      <h6>
        {/* <span> &#9670; </span>  */}
        {/* {props.communityInfo.NEIGHBORHOOD.Heading} */}
        {/* <span> &#9670; </span> */}
      </h6>
      <div className="mrg-top-300" />
      <div className="row lc_mapview">
        <div className="col-md-12">
          <div className="lc_map">
            <div className="map-alignment">
              <div id={"map"+props.communityResponse.community_id} className="map-alignment" />
            </div>
            <div className="dean_mapinput">
            <i className="fa fa-map-marker"></i>
              <input type="text" className="form-control" placeholder=" What’s my commute? " />
            </div>

            {props.loader ?<div className="dean_mapheading">
            <SearchMap latitude={props.communityResponse.latitude} longitude={props.communityResponse.longitude} communityName={props.communityResponse.community_name} communityObject={props.communityResponse} handleFormSubmit={props.handleFormSubmit} status={1}/>
            </div>:''}
          </div>
        </div>
      </div>
      <div className="white-gap" />
      <div className="container">
        <div className="New_locationcommunyties">
          {Object.keys(props.greatSchoolsResponse).length > 0 ? (
            <GreatSchools greatSchools={props.greatSchoolsResponse} width={0} />
          ) : (
            ""
          )}
          <div className="row">
            <div className="col-md-6">
              {props.loader ? (
                <WalkScore
                  walkScoreDetails={props.walkScoreResponse}
                  communityName={"the dean"}
                />
              ) : (
                ""
              )}
            </div>
            <div className="col-md-6">
              {props.weather_current != undefined ? (
                <Weather
                  {...props}
                  city={props.communityResponse.city_name}
                  state={props.communityResponse.state_name}
                  weather={props.weather}
                  weather_current={props.weather_current}
                  weather_currentCityDetails={props.weather_currentCityDetails}
                />
              ) : (
                ""
              )}
            </div>
          </div>
          {props.poi.length > 0  ? <Poi {...props} /> : ""}
        </div>
      </div>
    </div>
  );
};
export default NeighBoorHood;
