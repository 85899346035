import moment from 'moment'
import ReactTable from "react-table";
const CommunityAnalysisModal = (props) => {
  const filterMethod = (filter, row) => {
    const id = filter.pivotId || filter.id;
    if (row[id] !== null && typeof row[id] === "string") {
      return (row[id] !== undefined
        ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
        : true);
    }
  }
  const { communityAnalysisName, analysisModalLoading, communityAnalysisCountInfo, communityAnalysisType } = props;
  return (
    <div className="modal fade" id="communityanalysisCountInfo" role="dialog">
      <div className="modal-dialog modal-lg">


        <div className="modal-content animated zoomIn">
          <div className="modal-header">
            <button type=" button" className="btn close" data-dismiss="modal">
              <i className="bi bi-x"></i>
            </button>
            <h4 className="modal-title">{communityAnalysisName} <span style={{ color: 'red' }}>({communityAnalysisType})</span></h4>
          </div>

          <div className="col-md-12 mrg-top-15">
            <ReactTable
              multiSort={true}
              loading={analysisModalLoading}
              defaultFilterMethod={filterMethod}
              data={communityAnalysisCountInfo}
              columns={[
                {
                  Header: 'Order#',
                  id: 'OrderId1',
                  accessor: row => (row.orderno).toString(),
                },
                {
                  Header: "Resident",
                  id: 'guestname',
                  style: { 'textAlign': 'left' },
                  accessor: row => (row.GuestFirstName),
                },
                {
                  Header: "Suite#",
                  id: 'suiteNo',
                  style: { 'textAlign': 'left' },
                  accessor: "SuiteNo",
                },
                {
                  Header: "MoveIn",
                  id: 'movein',
                  style: { 'textAlign': 'left' },
                  accessor: row => (moment(row.MoveInDate).format('L')),
                },
                {
                  Header: "MoveOut",
                  id: 'moveOut1',
                  style: { 'textAlign': 'left' },
                  accessor: row => (moment(row.MoveOutDate).format('L')),
                }

              ]}
              defaultPageSize={15}
              pageSizeOptions={[5, 10, 15, 20, 25, 50, 100, communityAnalysisCountInfo.length]}
              style={{ height: "500px" }}
              sortable={true}
              className="-striped -highlight analsys_table community_Analysis_Modal"
              filterable={true}
            />

          </div>

          <div className="modal-footer">

          </div>
        </div>

      </div>
    </div>


  )
}
export default CommunityAnalysisModal;

