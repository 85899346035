const NikeAmenitesList = (props) => {

    const { indexval, communityAmenities, appartmentAmenities, LinkMaster } = props;


   
    return (
        <div>
            <div id={"collapseOne" + indexval} className="panel-collapse collapse in " role="tabpanel" aria-labelledby={"headingOne" + indexval}>
                <div className="panel-body">
                    <div className="sub-tail">
                        <div className="item">
                            <h1>Community</h1>
                        </div>
                        <div className="item">
                            <h1>Apartment</h1>
                        </div>
                        <div className="item">
                            <h1>More Info</h1>
                        </div>
                    </div>
                    <div className="sub-tail content">
                        <div className="item">
                            <h2>Community</h2>
                            {communityAmenities.length != 0 ?
                                <ul>
                                    {communityAmenities.map((row, index) => {
                                        return <li key={index}><img width="40" src={s3BucketUrl + 'Amenities/' + (row.aparment_amenity_name).replace(/[^A-Z0-9]+/ig, "") + '.png'} /> {row.aparment_amenity_name}</li>
                                    })}
                                </ul>
                                : null}

                        </div>
                        <div className="item">

                            <h2>Apartment</h2>
                            {appartmentAmenities.length > 0 ?
                                <ul>
                                    {appartmentAmenities.map((row, index) => {
                                        return <li key={index}><img width="40" src={s3BucketUrl + 'Amenities/' + (row.aparment_amenity_name).replace(/[^A-Z0-9]+/ig, "") + '.png'} /> {row.aparment_amenity_name}</li>
                                    })}
                                </ul>
                                : null}
                        </div>
                        <div className="item">
                            <h2>More Info</h2>
                            <ul>
                                {LinkMaster.Floor != null ?
                                    <li>Floor: {LinkMaster.Floor}</li> :
                                    null
                                }
                                {LinkMaster.SquareFootage != null ?
                                    <li>Sq. Feet: {LinkMaster.SquareFootage}</li> :
                                    null
                                }
                                {LinkMaster.SuiteSizeCode != null ?
                                    <li>Size: {LinkMaster.SuiteSizeCode}</li> :
                                    null
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="panel-heading" role="tab" id={"headingOne" + indexval}>
                <h4 className="panel-title">
                    <a  role="button" data-toggle="collapse" data-parent="#accordion" href={"#collapseOne" + indexval}
                        aria-expanded="true" className="" aria-controls={"collapseOne" + indexval}>
                    </a>
                </h4>
            </div>
        </div>
    )
}
export default NikeAmenitesList;