import React from 'react'
import Footer from './footer';
import Header from './header';

class AboutUs extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  async componentDidMount() {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }
  }
  render() {

    return (


      <div className="SAweb_updateUi">

        <Header />
        <section className=" SAweb_aboutUsBanner" data-aos="fade-up">
          <div className="container">
            <h1 className="line">Our Story</h1>
            <h3>30+ years of delivering unparalleled housing solutions</h3>
          </div>

        </section>

        <section className=" SAweb_aboutUsBannerBottom" data-aos="fade-up">
          <div className='container'>
            <div className="tailBox ">
              <div className="item text">
                <h3>Where It All Began</h3>

                <p className="p">A home and a garage, that’s where our founders Jim and Robin began operations in January of 1990.  The start-up was then called California Suites and offered temporary furnished housing services.  Within a year, they managed to set up an office and a few years later, California Suites became SuiteAmerica. </p>
                <p className="p">Today, we’re an industry leader with expansive apartment inventory throughout key locations in the U.S. with only one goal:  offering temporary housing stays that are extraordinary, easy, comfortable, and memorable.</p>
              </div>
              <div className="item">
                <div className="pic">


                  <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/aboutUS/aboutus_fold1image.jpg" alt="" width="100%" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="SAweb_aboutUsOurMission bg-white" >

          <div className="container">
            <div className="mainDiv" data-aos="fade-up">

              <h2>Our Mission</h2>
              <p className="p">We strive to set an unbeatable standard for client and guest satisfaction through our caring, personalized, and technology-based solutions.</p>
            </div>
          </div>


        </section>


        <section className="SAweb_gHousingGuestExpc SAweb_aboutUs">
          <div className="container">
            <div className="row sm-d-flex align-items-center justify-content-between" data-aos="fade-up">
              <div className="col-md-6">
                <div>


                  <h2 className="mb-4">The SuiteAmerica Philosophy</h2>
                  <p className="p">SuiteAmerica strives to be the best in corporate housing, using the right combination of technology and personal touch to keep our clients coming back time and time again.  We are dedicated to delivering an optimal experience for every guest, focusing on perfect arrivals and a comfortable, enjoyable stay. </p>
                  <p className="p">To attain this, we stand by four pillars that shape our philosophy.  With these mainstays, we've been fortunate enough to serve everyone from company interns to diplomats to Fortune 500 leaders.</p>
                </div>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-5">
                <div className="tailBox">
                  <div className="item">
                    <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/personalized_greeting.svg" alt="" />
                    <p className="p">Guest-first Approach</p>
                  </div>
                  <div className="item">
                    <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/client_needs.svg" alt="" />
                    <p className="p">Unmatched Client Experience</p>
                  </div>
                  <div className="item">
                    <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/dyanamic_workforce.svg" alt="" />
                    <p className="p">Dedicated Teams</p>
                  </div>
                  <div className="item">
                    <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/innovative_tech.svg" alt="" />
                    <p className="p">Stellar Technology</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>


        <section className=" SAweb_aboutUsBannerBottom bg-white" >
          <div className='container'>
            <div className="tailBox tailBox2 " data-aos="fade-up">
              <div className="item">
                <div className="pic">
                  <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/aboutUS/aboutus_fold2image.jpg" alt="" width="100%" />
                </div>
              </div>
              <div className="item text">
                <h3>Why stay with us</h3>
                <p className="p">We’re synonymous with comfort among relocating employees, business travelers, interns, and groups. From our humble beginnings over 30 years ago, we’ve come a long way in the corporate housing industry. We take pride in our endeavor to provide you with first-class, comfortable stays that surpass the best of the best experiences. </p>
                <p className="p">Our meticulous attention to detail results in customized stays to suit your preferences so you can relax and know your housing experience will be a good one.</p>
              </div>
            </div>
          </div>
        </section>
        <section className="SAwebHeadingButton my-5 py-5">
          <div className="container">
            <div className="box" data-aos="fade-up">
              <h3 className="mb-0 width400 ">An insight into our growth over the years</h3>
              <a href='/timeline' target="_blank" className='btn black-btn'> View Timeline </a>

            </div>
          </div>
        </section>


        {/* <div className="modal fade SAwebFullModalScreen" id="modal-TimeLine" tabindex="-1" role="dialog"
aria-labelledby="myModalLabel" aria-hidden="true">
<div className="modal-dialog">
  <div className="modal-content">
    <div className="SAweb_onlyButton">
      <button type="button" className="SAweb_closeBtn" data-dismiss="modal" aria-hidden="true"><i className="bi bi-x"></i>
      </button>

    </div>
    <div className="modal-body p-0">
      <div className="SAweb_timeLineView">
        <ul className="timeline">
     
    
          <li>
            <div className="timeline-badge"> 1990 </div>
            <div className="timeline-panel">
              <div className="pic">
                <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/aboutUS/timeLine.jpg" alt="" />
              </div>
              <p className="p">Started ‘California Suites’ from the home and garage of founders Jim and Robin</p>
            </div>
          </li> 

          <li>
            <div className="timeline-badge danger"> 1991 </div>
            <div className="timeline-panel">
              <div className="pic">
                <img src="assets/newUI-images/aboutUS/timeLine-2.jpg" alt="" />
              </div>
              <p className="p">Started 'California Suites' from the home and Set up an actual office in Hayward, California</p>
            </div>
          </li>
          <li>
            <div className="timeline-badge danger"> 1995 </div>
            <div className="timeline-panel">
              <div className="pic">
                <img src="assets/newUI-images/aboutUS/timeLine-3.jpg" alt="" />
              </div>
              <p className="p">Acquired Corporate Quarters</p>
            </div>
          </li>
          <li>
            <div className="timeline-badge danger"> 1998 </div>
            <div className="timeline-panel">
              <div className="pic">
                <img src="assets/newUI-images/aboutUS/timeLine-4.jpg" alt="" />
              </div>
              <p className="p">Extended services to the East Coast of the United States and renamed the company to 'SuiteAmerica'</p>
            </div>
          </li>
          <li>
            <div className="timeline-badge danger"> 2000 </div>
            <div className="timeline-panel">
              <div className="pic">
                <img src="assets/newUI-images/aboutUS/timeLine_Placeholder.jpg" alt="" />
              </div>
              <p className="p">Set up a new office, the current Headquarters in EI Dorado Hills, California</p>
            </div>
          </li>
          <li>
            <div className="timeline-badge danger"> 2009 </div>
            <div className="timeline-panel">
              <div className="pic">
                <img src="assets/newUI-images/aboutUS/timeLine-5.jpg" alt="" />
              </div>
              <p className="p">Became the 1st corporate housing company to launch an App for iPhone</p>
            </div>
            <div className="timeline-panel">
              <div className="pic">
                <img src="assets/newUI-images/aboutUS/timeLine-6.jpg" alt="" />
              </div>
              <p className="p">Received the Most Prestigious Industry Award - Corporate Housing Company of the Year</p>
            </div>
          </li>

          <li>
            <div className="timeline-badge danger"> 2011 </div>
            <div className="timeline-panel">
              <div className="pic">
                <img src="assets/newUI-images/aboutUS/timeLine_Placeholder.jpg" alt="" />
              </div>
              <p className="p">Acquired U.S Suites</p>
            </div>
          </li> 
           <li>
            <div className="timeline-badge danger"> 2016 </div>
            <div className="timeline-panel">
              <div className="pic">
                <img src="assets/newUI-images/aboutUS/timeLine_Placeholder.jpg" alt="" />
              </div>
              <p className="p">Became a team of over 200 employees</p>
            </div>
          </li>
          <li>
            <div className="timeline-badge danger"> 2018 </div>
            <div className="timeline-panel">
              <div className="pic">
                <img src="assets/newUI-images/aboutUS/timeLine_Placeholder.jpg" alt="" />
              </div>
              <p className="p">Launched a new Cloud-based platform, CHAPP</p>
            </div>
          </li>
          <li>
            <div className="timeline-badge danger"> 2020 </div>
            <div className="timeline-panel">
              <div className="pic">
                <img src="assets/newUI-images/aboutUS/timeLine_Placeholder.jpg" alt="" />
              </div>
              <p className="p">30th anniversary of SuiteAmerica</p>
            </div>
          </li>
          <li>
            <div className="timeline-badge danger"> 2021 </div>
            <div className="timeline-panel">
              <div className="pic">
                <img src="assets/newUI-images/aboutUS/timeLine_Placeholder.jpg" alt="" />
              </div>
              <p className="p">Served 7 Legacy Clients for over 30 years</p>
            </div>
          </li>
        </ul>
      </div>

    </div>

  </div>
</div>
</div> */}




        <section className="SAweb_aboutUsWhatMakes" >

          <h2 data-aos="fade-up" className="width500 m-auto ">Our Founders</h2>

          <div className="tailBox">
            <div className="item" data-aos="fade-up">
              <div className="pic">
                <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/aboutUS/RobinMastenFounder.png" />
              </div>
              <p>Robin Masten</p>
              <p><span>President </span></p>

            </div>
            <div className="item" data-aos="fade-up">
              <div className="pic">
                <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/aboutUS/JamesMastenFounder.png" />
              </div>
              <p>James Masten</p>
              <p><span>Chief Executive Officer </span></p>
            </div>
          </div>
          {/* <button type="button" className="btn"> See The Team </button> */}
          <a href='/leadership-team' target="_blank" className='btn black-btn btn-border' > See The Leadership Team </a>
        </section>
        <section className="SAweb_aboutUsAwards">
          <div className="container">
            <h2>Awards</h2>
            <p className="p">With our outstanding business hospitality and superior services, we've managed to bag a
              couple of awards across various platforms. Our recent accomplishments include</p>
            <div className="box">
              <div className="item">
                <ul>
                  <li>  <span className='quote f-child'> <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 208.06 416.121">
                    <path id="Icon_metro-quote" data-name="Icon metro-quote" d="M528.506,113.67V9.64s-208.06,0-208.06,208.06v208.06h208.06V217.7H424.475S424.475,113.67,528.506,113.67Z" transform="translate(-320.445 -9.64)" />
                  </svg>   23</span> <span>  <b>Best Green Process Award</b> CHPA </span>  </li>


                  <li>  <span className='quote f-child'> <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 208.06 416.121">
                    <path id="Icon_metro-quote" data-name="Icon metro-quote" d="M528.506,113.67V9.64s-208.06,0-208.06,208.06v208.06h208.06V217.7H424.475S424.475,113.67,528.506,113.67Z" transform="translate(-320.445 -9.64)" />
                  </svg>   22</span> <span>  <b>Highest Net Satisfaction, Relocation Managers’ Survey </b> Trippel Survey & Research, LLC  </span>  </li>


                  <li>  <span className='quote'> <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 208.06 416.121">
                    <path id="Icon_metro-quote" data-name="Icon metro-quote" d="M528.506,113.67V9.64s-208.06,0-208.06,208.06v208.06h208.06V217.7H424.475S424.475,113.67,528.506,113.67Z" transform="translate(-320.445 -9.64)" />
                  </svg>   21</span> <span> ‘18, ‘19, ‘17, ‘14, ‘13  <b>Commitment to Excellence Award  </b> CARTUS Global Network  </span>  </li>


                  <li>  <span className='quote'> <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 208.06 416.121">
                    <path id="Icon_metro-quote" data-name="Icon metro-quote" d="M528.506,113.67V9.64s-208.06,0-208.06,208.06v208.06h208.06V217.7H424.475S424.475,113.67,528.506,113.67Z" transform="translate(-320.445 -9.64)" />
                  </svg>   20</span> <span>   <b> Highest Net-satisfaction for Corporate Housing </b> Trippel Survey & Research, LLC  </span>  </li>

                  <li>
                    <span className='quote'> <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 208.06 416.121">
                      <path id="Icon_metro-quote" data-name="Icon metro-quote" d="M528.506,113.67V9.64s-208.06,0-208.06,208.06v208.06h208.06V217.7H424.475S424.475,113.67,528.506,113.67Z" transform="translate(-320.445 -9.64)" />
                    </svg>   20</span>
                    <span>  <b> Highest Average Score Rental Assistance Provider, Destination Services &amp; Net Satisfaction in </b><b className="d-inline"> Corporate Housing</b> Trippel Survey &amp; Research, LLC </span>
                  </li>


                </ul>
              </div>

              <div className="item">

                <ul>
                  <li>  <span className='quote'> <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 208.06 416.121">
                    <path id="Icon_metro-quote" data-name="Icon metro-quote" d="M528.506,113.67V9.64s-208.06,0-208.06,208.06v208.06h208.06V217.7H424.475S424.475,113.67,528.506,113.67Z" transform="translate(-320.445 -9.64)" />
                  </svg>   19</span>  <span>  <b>Innovation of the Year - Tower of  <span>Excellence Award </span> </b> CHPA  </span>  </li>
                  <li> <span className='quote'><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 208.06 416.121">
                    <path id="Icon_metro-quote" data-name="Icon metro-quote" d="M528.506,113.67V9.64s-208.06,0-208.06,208.06v208.06h208.06V217.7H424.475S424.475,113.67,528.506,113.67Z" transform="translate(-320.445 -9.64)" />
                  </svg>   18</span> <span>   <b> #1 in Client Satisfaction </b> Trippel Survey & Research, LLC </span>  </li>
                  <li> <span className='quote'> <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 208.06 416.121">
                    <path id="Icon_metro-quote" data-name="Icon metro-quote" d="M528.506,113.67V9.64s-208.06,0-208.06,208.06v208.06h208.06V217.7H424.475S424.475,113.67,528.506,113.67Z" transform="translate(-320.445 -9.64)" />
                  </svg>   18</span>  <span>  <b> Move For Hunger's Corporate <span>Housing Company </span>  </b> Move For Hunger</span>  </li>
                  <li> <span className='quote'> <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 208.06 416.121">
                    <path id="Icon_metro-quote" data-name="Icon metro-quote" d="M528.506,113.67V9.64s-208.06,0-208.06,208.06v208.06h208.06V217.7H424.475S424.475,113.67,528.506,113.67Z" transform="translate(-320.445 -9.64)" />
                  </svg>  18</span>  <span>  <b> In Their Shoes Award  </b> Plus Relocation Partner Award</span>  </li>
                  <li> <span className='quote'><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 208.06 416.121">
                    <path id="Icon_metro-quote" data-name="Icon metro-quote" d="M528.506,113.67V9.64s-208.06,0-208.06,208.06v208.06h208.06V217.7H424.475S424.475,113.67,528.506,113.67Z" transform="translate(-320.445 -9.64)" />
                  </svg>   17</span>  <span> <b> Supplier of the Year in Corporate <span> Housing & Destination Services </span>  </b> MoveCenter Awards </span> </li>
                </ul>
              </div>



            </div>
          </div>
        </section>

        <section className="SAweb_aboutUsCorporateLocations">
          <h3 data-aos="fade-up">Corporate Locations</h3>



          <div className="container">
            <div className="accordion" id="accordionExample">
              <div className="card">
                <div className="card-head" id="headingOne">
                  <div className="tailBox items-3">
                    <div className="item">
                      <h4>Corporate Headquarters: </h4>
                      <ul>
                        <li><h4>El Dorado Hills</h4></li>
                        <li>4970 Windplay Dr.</li>
                        <li>El Dorado Hills, CA 95762</li>
                        <li>800.367.9501</li>
                        <li>916.941.7970</li>

                      </ul>

                    </div>
                    <div className="item">
                      <h4>Warehouse & Distribution:  </h4>
                      <ul>
                        <li><h4>Santa Clara</h4></li>
                        <li>1325 Norman Ave.</li>
                        <li>Santa Clara, CA 95054</li>
                        <li>800.367.9501</li>

                      </ul>

                    </div>
                    <div className="item">
                      <h4>SuiteAmerica @ The Dean </h4>
                      <ul>

                        <li>458 San Antonio Rd.</li>
                        <li>Mountain View, CA  94040</li>
                        <li>800.367.9501</li>
                      </ul>

                    </div>


                  </div>
                  <h2 className="mb-0 collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">

                  </h2>
                </div>

                <div id="collapseOne" className="collapse " aria-labelledby="headingOne" data-parent="#accordionExample">
                  <div className="card-body">
                    <div className="tailBox items-3">
                      <div className="item">
                        <h4>SuiteAmerica @ Hiro </h4>
                        <ul>

                          <li>761 W Middlefield Rd.</li>
                          <li>Mountain View, CA 94043</li>
                          <li>800.367.9501</li>
                        </ul>

                      </div>
                      <div className="item">
                        <h4>Portland Warehouse & Distribution:  </h4>
                        <ul>
                          <li>10150 SW Nimbus Ave #E4</li>
                          <li>Portland, OR 97223</li>
                          <li>503.443.2033</li>
                        </ul>

                      </div>
                      <div className="item">
                        <h4>San Diego Warehouse & Distribution: </h4>
                        <ul>
                          <li>9597 Distribution Ave</li>
                          <li>San Diego, CA 92121</li>
                          <li>858.229.8476 </li>
                        </ul>

                      </div>


                      <div className="item">
                        <h4>Virginia Warehouse & Distribution: </h4>
                        <ul>
                          <li>8580 Cinder Bed Road Suite 1900</li>
                          <li>Lorton, VA 22079</li>
                          <li>800.367.9501</li>

                        </ul>

                      </div>


                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>


        </section>
        <section className="SAweb_aboutUsWorkWithus bg-white">
          <div className="container">
            <div className="row align-items-center" data-aos="fade-up">
              <div className="col-md-5">
                <h3>Work With Us</h3>
                <p className="p">Embark on a journey to experience the best in business hospitality with a team that offers knowledge, experience, and customized solutions.</p>

                <a href='/career-opportunities' >    <button type="button" className="btn btn-border-black">Learn More</button></a>
              </div>

              <div className="col-md-7">
                <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/aboutUS/workwithus.jpg" alt="" width="100%" />
              </div>
            </div>
          </div>

        </section>
        <Footer />



      </div>
    );
  }
}
export default AboutUs;