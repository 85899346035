import GuestHeader from "../../common/guestHeader";
import AppController from "../../controller/appController";
import Footer from "../../common/footer";
import SubFooter from "../../common/subFooter";
class DeanParking extends React.Component{
    constructor(props){
        super(props)
        this.state={

      }
    }
    async componentWillMount(){
        AppController.removeLocalStorageData();
        $('html, body').animate({scrollTop: 0});
        AppController.stickyTitles();
      }
render(){
    return(
        <div>
        <GuestHeader/>
        <div className="dean-parkingpage">
			
			<div className="page_banner">
				<div className="bannerimg">
					<img className="width100per" src="assets/images/dean-parking/D-Parking-banner.jpg" />
				</div>
				<div className="banner_text">
					<h2><span>PARKING & LATCH KEYLESS ENTRY </span><b>|</b> THE DEAN </h2>
					<h3>SUITE<span>AMERICA</span></h3>
				</div>
			</div>
			
			
		
				<div className="banner_bootm">
					<div className="item">
						<img src="/assets/newUI-images/dean/parking1.jpg" alt="" />
					</div>
					<div className="item item_text">
						<img src="assets/images/dean-parking/parking2.jpg" alt="" />
						<div className="textonimage">
							    
							<p>EASY ACCESS VIA MILLER AVENUE JUST OFF SAN ANTONIO ROAD.</p>
							<p>HEAD <b>SOUTH</b>  DOWN SAN ANTONIO ROAD.</p>
							<p>TURN <b>RIGHT</b>  ONTO MILLER AVENUE. (IMMEDIATELY AFTER THE BANK OF AMERICA)</p>
							<p>PARKING GARAGE IS YOUR FIRST <b>LEFT.</b> </p>
							<p>LOOK FOR THE HANGING ‘RETAIL PARKING’ SIGN.</p>
							<p> PARK IN ONE OF THE SUITEAMERICA GUEST TEMPORARY PARKING SPACES.</p>
							<p> *KEEP IN MIND THIS IS AN ENERGY-EFFICIENT BUILDING & LIGHTS ARE MOTION ACTIVATED.</p>
						</div>
					</div>
					<div className="item">
						<img src="assets/images/dean-parking/parking3.jpg" alt="" />
						
					</div>
					<div className="item">
						<img src="assets/images/dean-parking/parking4.jpg" alt="" />
					</div>
				</div>
			
			<div className="parking-information">
				<div className="container">
					<h1>ONCE YOU’VE PARKED</h1>
					
					<ul>
						<li>Your SuiteAmerica representative will meet you in the Retail Parking Area and assist you with your luggage. </li>
						<li>They will escort you to the elevator and give you a tour of your new home. Your representative will show you into your suite, make sure you’re comfortable and have everything you need. They’ll also provide details about things like community amenities, picking up your mail, and where to take out the trash.</li>
						<li>Once you’ve settled in, you will be shown your secure parking spot in the underground garage and given a garage remote. When using the garage remote, hold down the number 1 until the garage opens.</li>
						<li> Be sure to download the <b>Latch app</b>, as that’s how you’ll get into the building and your suite.</li>
					</ul>
				</div>
			</div>
			<div className="banner_bootm banner_bootm22 ">
			<div className="item">
						<img src="assets/images/dean-parking/parking5.jpg" alt="" />
						
					</div>
					<div className="item">
						<img src="assets/images/dean-parking/parking6.jpg" alt="" />
					</div>
					</div>
			<div className="parking-information parking-information2">
				<div className="container">
					<h1>LATCH KEYLESS ENTRY</h1>
					<ul>
						<li>The Dean is a completely keyless property. You will be using the Latch app to access all doors, elevators, and common areas. Before you arrive, you will have received a link to sign up for Latch and download the app.</li>
						<li>When opening the app, you will have been granted access to The Dean and your suite the morning you move in. When you arrive, open the Latch app, and hold up your phone to the Latch lock on the door you’d like to open.</li>
						<li>The app will take a moment to unlock the door. If you are trying to access an elevator, hold up your phone to the Latch lock on the inside of the elevator, then select the floor you’d like to access.</li>
						<p> If you have any questions, please call us at 800-367-9501.</p>
					</ul>
				</div>
			</div>
			<div className="banner_bootm banner_bootm22 ">
			<div className="item">
						<img src="assets/images/dean-parking/parking7.jpg" alt="" />
						
					</div>
					<div className="item">
						<img src="assets/images/dean-parking/parking8.jpg" alt="" />
					</div>
					</div><div className="parking-information parking-information3">
				<div className="container">
					<h1>FIND PACKAGES EASILY</h1>
					<ul>
						<li>Amazon packages will be delivered to the Amazon locker area, which is located near the mailboxes on level B1.</li>
					</ul>
				</div>
			</div>

	</div>
	<SubFooter/>
	<Footer/>
        </div>
    )
}
}
export default DeanParking;