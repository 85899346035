import MainController from "../../controller/mainController";
import SubFooter from "../../common/subFooter";
import GuestHeader from '../../common/guestHeader';
import Footer from '../../common/footer';
import MediaFooter from "./common/mediaFooter";
import MetaTags from '../../common/metaTags';
import ServerMetaTags from '../../../server/metaTags';

/* MediaLeadershipTeam Component initialization */
class MediaLeadershipTeam extends React.Component {
    /* Initializing objects of its MediaLeadershipTeam class */
    constructor(props) {
        super(props);
    }
    /* It is invoked immediately before mounting occurs */
    componentWillMount() {
        MainController.getInitialInformation();
    }
    /* It is invoked to return html content */
    render() {
        const metaData = ServerMetaTags.urlString.getMetaData(this.props.match.url);
        return (
            <div>
            <MetaTags 
             metaTitle = {metaData.title}
             metaDesription = {metaData.description}
             metaUrl = {metaData.canonical} />
            <GuestHeader/>
            <div className="cover-tophd-ht company_ht_cover"></div>
            <div className="quote-section container-fluid bg-white team_section mediakit">
                <div className="col-md-12">
                    <h2 className="mrg-btm-50 color-red">LEADERSHIP TEAM</h2>
                    <div className="row L-team_member">
                        <div className="team_member">
                            <div className="pic">
                                <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/Staff/SuiteAmerica-Leadership-Staff-Robin+Masten-BW.png" />
                            </div>
                            <b>Robin Masten</b>
                            <p>President</p>
                        </div>
                        <div className="team_member">
                            <div className="pic">
                                <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/staffimages/Jim-Mastin-BW.png" />
                            </div>
                            <b>James Masten</b>
                            <p>Chief Executive Officer</p>
                        </div>
                        <div className="team_member">
                            <div className="pic">
                            <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/Mark-Holland-BW.png" />
                            </div>
                            <b>Mark Holland</b>
                            <p>National Director Supply Chain</p>
                        </div>
                        <div className="team_member">
                            <div className="pic">
                                <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/Staff/SuiteAmerica-Leadership-Staff-Lesley-Batsford-BW.png" />
                            </div>
                            <b>Leslie Batsford</b>
                            <p>Senior Director, National Sales</p>
                        </div>
                       
                        <div className="team_member">
                            <div className="pic">
                                <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/Staff/SuiteAmerica-Leadership-Staff-Laura-Gregersen-BW.png" />
                            </div>
                            <b>Laura Gregersen</b>
                            <p>Senior Director, National Sales</p>
                        </div>
                        <div className="team_member">
                            <div className="pic">
                                <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/Staff/SuiteAmerica-Leadership-Staff-Vanessa-Reiter-BW.png" />
                            </div>
                            <b>Vanessa Reiter</b>
                            <p>Senior Director, National Sales</p>
                        </div>
                        <div className="team_member">
                            <div className="pic">
                                <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/staffimages/Julie Mammini-BW.png" />
                            </div>
                            <b>Julie Mammini</b>
                            <p>Director of Personal Transition Services</p>
                        </div>
                        
                        <div className="team_member">
                            <div className="pic">
                                <img src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/staffimages/Shuford-Bowman-BW.png" />
                            </div>
                            <b>Shuford Bowman</b>
                            <p>Director of Accounting and Information Services</p>
                        </div>
                        <div className="team_member">
                            <div className="pic">
                                <img src="https://suiteam-website.s3-us-west-1.amazonaws.com/Staff/Kelly+Cockrell.png" />
                            </div>
                            <b>Kelly Cockrell</b>
                            <p>Vice President of Sales, Western Region</p>
                        </div>
                        <div className="team_member">
                            <div className="pic">
                                <img src="https://suiteam-website.s3-us-west-1.amazonaws.com/Staff/Ashok-Kandipati.png" />
                            </div>
                            <b>Ashok Kandipati</b>
                            <p>Information & Data Technology</p>
                        </div>
                    </div>
                    <MediaFooter />
                </div>
            </div>
            <SubFooter />
            <Footer/>
        </div>
        );
    }
}
export default MediaLeadershipTeam;