'use strict'
import {Link} from 'react-router-dom';
import moment from 'moment'
import usersService from '../../services/usersService';
class BookNowModal extends React.Component{
    constructor(props) {
        super(props)
        /* ListView Component State variables Initialization */
        this.state = {
            resLocation: '',
            resDate: moment(),
            resName: '',
            resCompany: '',
            resEmail: '',
            resPhone: '',
            resPlace:'',
            message:'',
            color:'',
            emailmessage:'',
            emailcolor:'',
            loader:false,
            lengthOfStay:'',
            budget:'',
            date:'',
            dateCalendarAdded: false,
           
           
        }
        this.setLocation = this.setLocation.bind(this);
        this.setValue = this.setValue.bind(this);
        this.rangeval = this.rangeval.bind(this);
        this.reserveYourStay = this.reserveYourStay.bind(this);
      }
      componentWillReceiveProps(nextProps){
        this.setState({ resLocation: '',resName: '',resCompany: '',resEmail: '',resPhone: '',resPlace:''})
        const _this = this
        if(!_this.state.dateCalendarAdded){
            jQuery(function() {
                jQuery('input[name="daterange"]').daterangepicker({
                    locale: {
                    format: 'MM/DD/YYYY'
              }},
                    function(start, end, label) {
                        _this.setState({dateCalendarAdded: true, date: start.format('MM/DD/YYYY') + ' - ' + end.format('MM/DD/YYYY') })  
                });
            }); 
        }
         
    }

          setLocation(e) {
          
           const _this = this;
           _this.setState({resLocation: e.target.value});
              var places = new google.maps.places.Autocomplete(document.getElementById('booknow_popup'));
              google.maps.event.addListener(places, 'place_changed', function () {
                  _this.setState({resPlace: places.getPlace().formatted_address});
              });
          }

        setValue(field,e){  
            this.setState({message:'',color:''})        
           if(e.target.value !='') {
            $('html, body').animate({scrollTop: '250px'}, 600);
           }
           else{
            $('html, body').animate({scrollTop: '-=250px'}, 600);
           }
           if(field =='resEmail'){
            this.setState({emailmessage:'',emailcolor:''}) 
           }
            var object = {};
            object[field] = e.target.value;
            this.setState(object);
        }

         async dateSelection(date,e){
            this.setState({message:'',color:''})    
             await this.setState({resDate: moment(date)});
          }

 successMessage(){
    $('html, body').animate({scrollTop: '-=250px'}, 600);
    this.setState({message:'Mail successfully Sent ',color:'green',loader:false})
    setTimeout(function() { 
        this.setState({ resLocation: '',
        resDate: moment(),
        resName: '',
        resCompany: '',
        resEmail: '',
        resPhone: '',
        resPlace:'',
        message:'',
        color:'',
        emailmessage:'',
        emailcolor:'',
        loader:false})
        document.getElementById("booknow_popup").value =''
    }.bind(this), 3000);
 
}

         async  reserveYourStay(e){
           
            e.preventDefault();
            this.setState({emailmessage:'',emailcolor:''}) 
            var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
             if(this.state.resCompany !='' && this.state.resName !='' && this.state.resPhone && this.state.resDate !='' && this.state.resLocation !='' ){
              
                if(reg.test(this.state.resEmail)){
                    this.setState({emailmessage:'',emailcolor:'',loader:true}) 
                    var location=''
                    if(this.state.resPlace==''){
                        location=this.state.resLocation 
                    }
                    else{
                        location=this.state.resPlace
                    }
                const reserveYourStay={
                    location: location,
                    date:this.state.date,
                    name:this.state.resName,
                    company: this.state.resCompany,
                    email: this.state.resEmail,
                    length: this.state.lengthOfStay,
                    budget: this.state.budget,
                    phone: this.state.resPhone,
                    status:'0'
                }
                    const resReserveYourStay=await usersService.reserveYourStay(reserveYourStay);

                    if(resReserveYourStay.response){
                        jQuery(function ($) {
                            $('#book_now').modal('hide');
                            $('#successModal').modal('show');
                        }.bind(this));
                        setTimeout(function() { jQuery('#successModal').modal('hide'); }.bind(this), 1500);
                        this.successMessage()
                       }else{
                           this.setState({message:'We Are unable to sent Mail ',color:'red'})
                       }

                }

                else{
                    this.setState({emailmessage:'Please Enter Email formate',emailcolor:'Red'}) 
                }
    
             }

             else{
                this.setState({message:'Please Enter All The Fields ',color:'Red'})
             }


          }
          rangeval (val){
              console.log('this.rangeval')
           }

   render(){
    return(
        <div className="modal fade savesearch tesimonial_form bd-example-modal-lg" id="book_now" role="dialog">
            <div className="modal-dialog modal-lg">
                <div className="modal-content rm-border-radius booknow-info">
                    <div className="modal-header">
                        <button type="button" className="close btn" data-dismiss="modal" aria-label="">
                        <i className="bi bi-x"></i>
                        </button>
                    </div>
                    <div className="modal-body">
                        <h2>Reserve Your Stay</h2>
                        <div style={{color:this.state.color}}>{this.state.message} </div>
                        <form role="form"   >
                        <div className="col-sm-6">
                            <div className="form-group">
                                <input className="form-control" placeholder="Location Desired"  id="booknow_popup" onChange={(e) => this.setLocation(e)}/>
                                <div className="info-field-icon">
                                    <Link to="#">
                                    <i className="fa fa-map-marker"></i></Link>
                                </div>
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="Name" onChange={(e) => this.setValue('resName', e)} value={this.state.resName}  />
                            </div>
                            <div className="form-group" style={{color:this.state.emailcolor}}>
                                {this.state.emailmessage}
                                <input className="form-control" placeholder="E-mail" 
                                onChange={(e) => this.setValue('resEmail', e)} value={this.state.resEmail}  type="text" />
                            </div>
                            <div className="form-group" style={{color:this.state.emailcolor}}>
                                       
                                       <input type="text" className="form-control" value={this.state.lengthOfStay} placeholder="Length Of Stay" id="lengthOfStay"  
                                       onChange={(e) => this.setValue('lengthOfStay', e)} />
                                       </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group booknow-date-picker">
                            <input type="text" className="form-control" placeholder='Select Date' name="daterange" value={this.state.date} onChange={this.rangeval} />
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="Company" id="company"  onChange={(e) => this.setValue('resCompany', e)} value={this.state.resCompany}/>
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="Phone" id="phone"  onChange={(e) => this.setValue('resPhone', e)} value={this.state.resPhone}/>
                            </div>
                            <div className="form-group" style={{color:this.state.emailcolor}}>
                                       
                                       <input type="text" className="form-control"value={this.state.budget} placeholder="Budget" id="budget"  onChange={(e) => this.setValue('budget', e)} />
                                       </div>
                        </div>


                        <div className="text-center col-sm-12 form-group">
                            {this.state.loader ?   
                            <div className="panel-body_loader" > <span style={{fontSize: '25px', width: '100%', margin: 'auto', textAlign: 'center'}}><i className="fa fa-spinner fa-spin"/> please wait ...!</span>
                            </div>
                            :<button className="btn location_btn mrg-top-40"  
                            onClick={(e) => this.reserveYourStay(e)}>Reserve</button>} 
                        </div>
                        <div className="text-center col-sm-6 col-sm-offset-3 mrg-btm-50 form-group">
                            <p>Our service team will call you with 
                                options within the hour.
                            </p>
                        </div>
                        </form>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>
        
    )
   }
       


}
export default BookNowModal