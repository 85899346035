/* Importing the node modules, child components, services and controllers used 
   inside TeslaReservationForm component */
   import $ from "jquery";
   import DatePicker from 'react-datepicker'
   import moment from 'moment'
   import AppController from "../../controller/appController";
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import TangerineHeader from "../../common/tangerineHeader";
   import NikeServices from '../../../services/nikeService';
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   import ReCAPTCHA from "react-google-recaptcha"
   import SuccessModal from '../../common/successModal';
   /* TeslaReservationForm Component initialization */
   class TangerineReservationForm extends React.Component {
     /* Initializing objects of its TeslaReservationForm class */
     constructor(props) {
       super(props)
       this.state = {
         CaptchaValue: '',
         tenantFirstName: '',
         tenantEmail: '',
         firstName: '',
         lastName: '',
         address: '',
         city: '',
         state: '',
         zipCode: '',
         email: '',
         phone: '',
         fax: '',
         cellPhone: '',
         landmark: '',
         company: '',
         locationRequested: '',
         moveInDate: moment(),
         moveOutDate: moment(),
         reserveDropDown: '',
         noOfAdults: '',
         noOfChildren: '',
         ageofChildren: '',
         bedSize: '',
         petInfo: '',
         pet: '',
         referrerName: '',
         referrerPhone: '',
         message: '',
         color: '',
         errorMessage: '',
         budget: '$3500-$4000/month',
         appartmentSize: '',
         payMentType: '',
         budgetDropDown: ['$3500-$4000/month', '$4001-$4500/month', '$4501-$5000/month', '$5001-$5500/month', '$5501/month or higher'],
         appartmentSizeDropDown: ['1 Bedroom', '2 Bedrooms', '3 Bedrooms', 'Studio', 'other'],
         dailyBudget:''
       }
       this.setValue = this.setValue.bind(this);
       this.handleMoveIn = this.handleMoveIn.bind(this);
       this.handleMoveOut = this.handleMoveOut.bind(this);
       this.setChecked = this.setChecked.bind(this);
       this.serviceRequest = this.serviceRequest.bind(this);
       this.onChangeCaptcha = this.onChangeCaptcha.bind(this);
       this.setEmailValue = this.setEmailValue.bind(this);
     }
     /* It is invoked immediately before mounting occurs */
     componentWillMount() {
       MainController.getInitialInformation();
     }
     /* Form Details object creations  */
     setValue(field, e) {
       if(field=="dailyBudget"){
         const re = /^[0-9\b]+$/;
         var object = {};
         if (re.test(e.target.value)==true) {
           object[field] = e.target.value;
           this.setState(object);
         }else{
           return;
         }
       }else{
           var object = {};
           object[field] = e.target.value;
           this.setState(object);
       }
         }
     /* Form Details object creations  */
     setChecked(e) {
       this.setState({ pet: e.target.value });
     }
   
     async setTenantEmailValue(field, e) {
       const _this = this
       //  alert(e.target.value);
   
       await this.setState({ tenantEmail: e.target.value });
   
       // var reg = /^([A-Za-z0-9_\-\.])+\@(tangerine.com)$/;
       var reg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
       if (reg.test(_this.state.tenantEmail) == false) {
         // this.setState({errorMessage:'Please enter Tangerine e-mail address.',color:'red'})
         $('#invalid-email').removeClass('display-none');
       }
       else {
         $('#invalid-email').addClass('display-none');
         this.setState({ errorMessage: '' })
       }
     }
   
     async setEmailValue(field, e) {
       const _this = this
       //  alert(e.target.value);
   
       await this.setState({ email: e.target.value });
   
       // var reg = /^([A-Za-z0-9_\-\.])+\@(tangerine.com)$/;
       var reg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
       if (reg.test(_this.state.email) == false) {
         // this.setState({errorMessage:'Please enter Tangerine e-mail address.',color:'red'})
         $('#invalid-email').removeClass('display-none');
       }
       else {
         $('#invalid-email').addClass('display-none');
         this.setState({ errorMessage: '' })
       }
     }
   
     /* MoveIndate and MoveOutDate   */
     async handleMoveIn(date) {
       await this.setState({
         moveInDate: moment(date),
         moveInDateSelected: date.format('YYYY-MM-DD HH:mm:ss')
       });
     }
     async handleMoveOut(date) {
       await this.setState({
         moveOutDate: moment(date),
         moveOutDateSelected: date.format('YYYY-MM-DD HH:mm:ss')
       });
     }
     async onChangeCaptcha(CaptchaValue) {
       await this.setState({ CaptchaValue })
     }
     /* Service Request Details send to mail  */
     async serviceRequest() {
       // var reg = /^([A-Za-z0-9_\-\.])+\@(tangerine.com)$/;
       var reg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
       const moveIn = this.state.moveInDateSelected == '' ? this.state.moveInDate.format('YYYY-MM-DD HH:mm:ss') : this.state.moveInDateSelected;
       const moveOut = this.state.moveOutDateSelected == '' ? this.state.moveOutDate.format('YYYY-MM-DD HH:mm:ss') : this.state.moveOutDateSelected;
       const _this = this
       const validationFields = [
         'firstName',
         'tenantFirstName',
         'tenantEmail',
         //  'lastName',
         //  'address',
         'city',
         'state',
         //  'zipCode',
         'email',
         'phone',
         //  'company',
         'dailyBudget',
         'moveInDate',
         'moveOutDate',
          'CaptchaValue'
       ]
       let error = 0;
       _.forEach(validationFields, function (value) {
         if (_this.state[value] == "") {
           error++;
         } else if (reg.test(_this.state.email) == false) {
           error++;
         } else if (reg.test(_this.state.tenantEmail) == false) {
           error++;
         }
       });
       
       if (error == 0 && moment(moveIn).isSameOrAfter(moveOut)) {
         const Request = {
           firstName: this.state.firstName,
           tenantFirstName: this.state.tenantFirstName,
           tenantEmail: this.state.tenantEmail,
           lastName: this.state.lastName,
           address: this.state.address,
           city: this.state.city,
           state: this.state.state,
           zipCode: this.state.zipCode,
           email: this.state.email,
           phone: this.state.phone,
           fax: this.state.fax,
           cellPhone: this.state.cellPhone,
           company: this.state.company,
           locationRequested: this.state.locationRequested,
           moveInDate: this.state.moveInDate.format('YYYY-MM-DD'),
           moveOutDate: this.state.moveOutDate.format('YYYY-MM-DD'),
           reserveDropDown: this.state.reserveDropDown,
           noOfAdults: this.state.noOfAdults,
           noOfChildren: this.state.noOfChildren,
           ageofChildren: this.state.ageofChildren,
           bedSize: this.state.bedSize,
           petInfo: this.state.petInfo,
           pet: this.state.pet,
           referrerName: this.state.referrerName,
           referrerPhone: this.state.referrerPhone,
           message: this.state.message,
           appartmentSize: this.state.appartmentSize,
           payMentType: this.state.payMentType,
          // budget: this.state.budget,
           dailyBudget:this.state.dailyBudget,
           mail: 'TangerineReservation',
           subject: 'Tangerine Reservation Form',
           landmark: this.state.landmark
         }
         this.setState({
           tenantFirstName: '',
           tenantEmail: '',
           firstName: '',
           lastName: '',
           address: '',
           city: '',
           state: '',
           zipCode: '',
           email: '',
           phone: '',
           fax: '',
           cellPhone: '',
           company: '',
           locationRequested: '',
           moveInDate: moment(),
           moveOutDate: moment(),
           reserveDropDown: '',
           noOfAdults: '',
           noOfChildren: '',
           ageofChildren: '',
           bedSize: '',
          // budget: '$3500-$4000/month',
           dailyBudget:'',
           appartmentSize: '',
           payMentType: '',
           petInfo: '',
           pet: '',
           referrerName: '',
           referrerPhone: '',
           message: '',
           color: '',
           errorMessage: '',
           landmark: ''
         })
         $('input[name="radio"]').prop('checked', false);
         AppController.showSuccessModal();
         const Response = await NikeServices.serviceRequest(Request)
       }
       else {
         AppController.menuTopNavigation('serviceRequest');
         this.setState({ errorMessage: 'Please Enter Required Fields, Valid Email, Daily Budget, Captcha & MoveOutDate is greater than are equal to MoveInDate ', color: 'red' })
       }
     }
     /* It is invoked to return html content */
     render() {
   
       return (
         <div className="corporateHousing_Updates telsa-page-view tesla-reservation-form">
           <GuestHeader />
           <div className="cover-tophd-ht"></div>
           <TangerineHeader />
           <div className="container-fluid nike company_section" id="serviceRequest">
             <div className="container text-center">
               <div className="container-view ">
                 <div className="cc_image_text_main tangerineImageHegiht">
                 <img className="img-responsive tangerineImage" src="/assets/newUI-images/tangerine/tangerine-home" width="100%" alt="" />
                 </div>
               </div>
               <h2 className="">READY TO STAY WITH US?</h2>
               <p>Fill out the form below to request a reservation.</p>
               {/* <p>
    Once you submit your request, you will receive an email confirmation that the request was received. Your request will be processed
   and you will hear from us by phone or email within 2 business hours to confirm your reservation* and to clear up any questions.</p> */}
               <p>Once you submit your request, you will receive an email confirmation that the request was received. Your request will be processed by SuiteAmerica’s dedicated Tangerine Travel Team. They will promptly confirm your reservation request details* and respond to any additional questions you may have.</p>
               <p style={{ fontStyle: "Italic" }}>*Lease addendums and tenant liability agreements may
   be required to secure a reservation.</p>
               <p></p>
               <p></p>
               <p></p>
               <p></p>
   
             </div>
   
           </div>
   
           <div className="container-fluid nike-datepicker bg-grey service_request">
             <div className="request_form company_form" >
               <div className="container">
   
   
                 <form className="well">
                   <p style={{ color: this.state.color }}>{this.state.errorMessage}</p>
                   <h1>Advisor Contact Information</h1>
   
                   <div className="row">
                     <div className="col-sm-6 col-md-6">
                       <input type="text" className="form-control" placeholder="*Name" value={this.state.firstName} onChange={(e) => this.setValue('firstName', e)} />
                       {/* <input type="text" className="form-control" placeholder="*Last Name" value={this.state.lastName} onChange={(e) => this.setValue('lastName', e)} /> */}
                       {/* <input type="text" className="form-control" placeholder="*Address" value={this.state.address} onChange={(e) => this.setValue('address', e)} /> */}
                       {/* <input type="text" className="form-control" placeholder="*City" value={this.state.city} onChange={(e) => this.setValue('city', e)} /> */}
                       {/* <div className="row">
               <div className="col-sm-6">
               <input type="text" className="form-control" placeholder="*State" value={this.state.state} onChange={(e) => this.setValue('state', e)} />
               </div>
               <div className="col-sm-6">
               <input type="text" className="form-control" placeholder="*Zip Code" value={this.state.zipCode} onChange={(e) => this.setValue('zipCode', e)} />
               </div>
               </div> */}
   
   
                     </div>
                     <div className="col-sm-6 col-md-6">
                       <div className="email-input-view">
                         <input type="text" className="form-control" placeholder="*Email" value={this.state.email} onChange={(e) => this.setEmailValue('email', e)} />
                       </div>
                       {/* <p className="input-invalid-validation display-none" id="invalid-email">Please enter Tangerine e-mail address.</p> */}
                       {/* <p className="emailNote">*No reservations can be processed without a Tangerine email address.</p>      */}
                       {/* <input type="text" className="form-control" placeholder="*Phone" value={this.state.phone} onChange={(e) => this.setValue('phone', e)} /> */}
                       {/* <input type="text" className="form-control" placeholder="Fax" value={this.state.fax} onChange={(e) => this.setValue('fax', e)} /> */}
                       {/* <input type="text" className="form-control" placeholder="Cell Phone" value={this.state.cellPhone} onChange={(e) => this.setValue('cellPhone', e)} /> */}
   
                       {/* <input type="text" className="form-control" placeholder="*Company" value={this.state.company} onChange={(e) => this.setValue('company', e)} /> */}
                     </div>
   
                   </div>
                   <hr />
   
                   <h1>Tenant Contact Information</h1>
   
                   <div className="row">
                     <div className="col-sm-6 col-md-6">
                       <input type="text" className="form-control" placeholder="*Name" value={this.state.tenantFirstName} onChange={(e) => this.setValue('tenantFirstName', e)} />
                       <div className="email-input-view">
                         <input type="text" className="form-control" placeholder="*Email" value={this.state.tenantEmail} onChange={(e) => this.setTenantEmailValue('tenantEmail', e)} />
                       </div>
                       {/* <input type="text" className="form-control" placeholder="*Last Name" value={this.state.lastName} onChange={(e) => this.setValue('lastName', e)} /> */}
                       {/* <input type="text" className="form-control" placeholder="*City" value={this.state.city} onChange={(e) => this.setValue('city', e)} /> */}
                       {/* <div className="row">
               <div className="col-sm-6">
               <input type="text" className="form-control" placeholder="*State" value={this.state.state} onChange={(e) => this.setValue('state', e)} />
               </div>
               <div className="col-sm-6">
               <input type="text" className="form-control" placeholder="*Zip Code" value={this.state.zipCode} onChange={(e) => this.setValue('zipCode', e)} />
               </div>
               </div> */}
   
   
                     </div>
                     <div className="col-sm-6 col-md-6">
                       
                       {/* <p className="input-invalid-validation display-none" id="invalid-email">Please enter Tangerine e-mail address.</p> */}
                       {/* <p className="emailNote">*No reservations can be processed without a Tangerine email address.</p>      */}
                       <input type="text" className="form-control" placeholder="*Phone" value={this.state.phone} onChange={(e) => this.setValue('phone', e)} />
                       {/* <input type="text" className="form-control" placeholder="Fax" value={this.state.fax} onChange={(e) => this.setValue('fax', e)} /> */}
                       {/* <input type="text" className="form-control" placeholder="Cell Phone" value={this.state.cellPhone} onChange={(e) => this.setValue('cellPhone', e)} /> */}
   
                       {/* <input type="text" className="form-control" placeholder="*Company" value={this.state.company} onChange={(e) => this.setValue('company', e)} /> */}
                     </div>
   
                   </div>
   
                   <hr />
                   <h1>Housing Inquiry</h1>
                   <div className="row">
                     <div className="col-sm-6 col-md-6">
                       <input type="text" className="form-control" placeholder="Number of Adults" value={this.state.noOfAdults} onChange={(e) => this.setValue('noOfAdults', e)} />
                       <input type="text" className="form-control" placeholder="Number of Children" value={this.state.noOfChildren} onChange={(e) => this.setValue('noOfChildren', e)} />
                       <input type="text" className="form-control" placeholder="Age of Children" value={this.state.ageofChildren} onChange={(e) => this.setValue('ageofChildren', e)} />
   
   
                     </div>
                     <div className="col-sm-6 col-md-6">
                       <input type="text" className="form-control" placeholder="Bed Size Requested" value={this.state.bedSize} onChange={(e) => this.setValue('bedSize', e)} />
                       <div className="row radiosec">
                         <div className="col-sm-6">
                           <input type="text" className="form-control" placeholder="Pet Information" value={this.state.petInfo} onChange={(e) => this.setValue('petInfo', e)} />
                         </div>
                         <div className="col-sm-5 col-md-offset-1">
                           <label>Pet</label>
                           <div className="row">
   
                             <div className="clearfix"></div>
                             <div className="col-sm-6">
                               <label className="custom_radio radio-inline">Yes
                   <input type="radio" name="radio" value="yes"
                                   onChange={(e) => this.setChecked(e)} />
                                 <span className="checkmark"></span>
                               </label>
                             </div>
                             <div className="col-sm-6">
   
                               <label className="custom_radio radio-inline">No
                   <input type="radio" name="radio" value="no"
                                   onChange={(e) => this.setChecked(e)} />
                                 <span className="checkmark"></span>
                               </label>
                             </div>
                           </div>
                         </div>
                         <div className="col-sm-12">
                         <p className="petinfo-cls p" style={{ marginLeft: '15px' }}>*Pet fees are not covered by Tangerine and are the sole responsibility of the Individual.</p>
                         {this.state.pet == "yes" ?
                           <p className="petinfo-cls p" style={{ marginLeft: '15px' }}>*A $500 non-refundable pet fee will apply. Breed restrictions may apply.</p>
                           : null}
                           </div>
                       </div>
                     </div>
                   </div>
   
   
   
                   <hr />
   
                   <h1>Reservation Information</h1>
                   <div className="row">
                     <div className="col-sm-6 col-md-6">
                       <input type="text" className="form-control" placeholder="Location Requested" value={this.state.locationRequested} onChange={(e) => this.setValue('locationRequested', e)} />
                       <input type="text" className="form-control" placeholder="*City" value={this.state.city} onChange={(e) => this.setValue('city', e)} />
                       <input type="text" className="form-control" placeholder="*State" value={this.state.state} onChange={(e) => this.setValue('state', e)} />
                       <input type="text" className="form-control" placeholder="Landmark/Hospital" value={this.state.landmark} onChange={(e) => this.setValue('landmark', e)} />
                       <p className="p" >*Ensure management approvals are obtained prior to booking. Review cancellation policy carefully and ensure that it aligns with the project duration.</p>
                     </div>
                     <div className="col-sm-6 col-md-6">
                     <input type="text"  className="form-control" placeholder="*Daily Budget ($)" value={this.state.dailyBudget} onChange={(e) => this.setValue('dailyBudget', e)} maxlength='8'/>
   
   
                       {/* <label className="custom-select">
                         <select value={this.state.budget} onChange={(e) => this.setValue('budget', e)}>
                           {this.state.budgetDropDown.map((row, index) => {
                             return (
                               <option key={index} value={row}>{row}</option>
                             )
                           })}
   
   
                         </select>
                       </label> */}
                       <label className="custom-select">
                         <select value={this.state.payMentType}
                           onChange={(e) => this.setValue('payMentType', e)}>
                           <option value=''>Payment</option>
                           <option value='Corporate Credit Card'>Corporate Credit Card</option>
                           <option value='Purchase Order (P.O)'>Purchase Order (P.O)</option>
   
                         </select>
                       </label>
   
                       <label className="custom-select">
                         <select value={this.state.appartmentSize}
                           onChange={(e) => this.setValue('appartmentSize', e)}>
                           <option value=''>Apartment Size</option>
                           {this.state.appartmentSizeDropDown.map((row, index) => {
                             return (
                               <option key={index} value={row}>{row}</option>
                             )
                           })}
   
                         </select>
                       </label>
   
                       <DatePicker type="text" className="form-control" placeholder="*Move-In Date" selected={this.state.moveInDate} onChange={this.handleMoveIn} />
                       <DatePicker type="text" className="form-control" placeholder="*Move-Out Date" selected={this.state.moveOutDate} onChange={this.handleMoveOut} />
                       
                       {/* <p className="p" >*For increases in credit card limits, follow this <a href="https://www.tangerine.ca/en/products/spending/creditcard" target="_blank">link</a>.</p>
             <p className="p" >*For PO request, follow this  <a href="https://www.tangerinetravel.com/create-profile" target="_blank">link</a>.</p> */}
                     </div>
                   </div>
   
                   <hr />
   
   
                   <h1>What Special Requests do you have?</h1>
                   <div className="row">
                     <div className="col-sm-12 col-md-12">
                       <textarea className="form-control" placeholder="Message" value={this.state.message} onChange={(e) => this.setValue('message', e)} rows="8" cols="5"></textarea>
                     </div>
   
                   </div>
                   <div className="row">
                     <div className="col-sm-12 col-md-12">
                       <ReCAPTCHA
                         ref="recaptcha"
                         sitekey="6Lcbw1AUAAAAAE5K5cYDyhoGu0RJY9C3CLtGFXAo"
                         onChange={this.onChangeCaptcha}
                       />
                     </div>
   
                   </div>
   
                 </form>
   
                 <button type="button" className=" btn location_btn mrg-btm-70 mrg-top-40"
                   onClick={this.serviceRequest}> SUBMIT</button>
               </div>
             </div>
           </div>
   
           <SubFooter />
           <Footer />
           <SuccessModal />
         </div>
   
       );
     }
   }
   export default TangerineReservationForm;