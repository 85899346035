import AppController from "../../controller/appController";
import ReactTable from "react-table";

const filterMethod = (filter, row) => {
  const id = filter.pivotId || filter.id;
  if (row[id] !== null && typeof row[id] === "string") {
    return (row[id] !== undefined
      ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
      : true);
  } else if(row[id] !== null && typeof row[id] === "number") {
      const str = String(row[id]);
      return (str !== undefined
          ? String(str.toLowerCase()).includes(filter.value.toLowerCase())
          : true);
  }
}
const XlsData = props => {
    const xlData = props.isFilter
            ? props.filterData
            : props.xlData
           
    return (
        <ReactTable
        data={xlData}
        defaultFilterMethod={filterMethod}
        columns={[
              {
                Header: <input type="checkbox" data-id="2" onChange={props.conformationAlert} checked={props.selectAll}/>,
                accessor: d=> (<input type="checkbox" checked={_.findIndex(props.emailsData, function(o) { return o.indexVal==d[3]})>=0? true : false} onChange={()=>{props.checkData(d)}} /> ),
                id: "selectall",
                filterable: false,
                sortable: false,
                style: {'textAlign': 'center'},
                width: 50
              },
              {
                Header: "Traveler",
                accessor: row=> row[4],
                id: 'Traveler',
                maxWidth: 150,
                style: {'textAlign': 'center'},
              },
              {
                Header: "PNR",
                accessor: row=> row[3],
                id: 'PNR',
                maxWidth: 100,
                style: {'textAlign': 'center'},
              },

              {
                Header: "Email",
                accessor: row=> row[6],
                id: 'Email',
                width: 150,
                style: {'textAlign': 'center'},
              },
              {
                Header: "CheckInDate",
                id: 'checkinDate',
                accessor:  row=> row[7],
                width: 125
              },
              {
                Header: "CheckOutDate",
                id: 'checkoutDate',
                accessor:  row=> row[8],
                width: 125
              },
              {
                Header: "City",
                id: 'City',
                accessor: row=> row[2],
                style: {'textAlign': 'center'},
               
              },
              {
                Header: "# of stay",
                id: 'stay',
                accessor: row=> row[11],
                style: {'textAlign': 'center'},
               
              },
              {
                Header: "Rate",
                id: 'Rate',
                accessor: row=> row[10],
                style: {'textAlign': 'center'},
                
              },
              {
                Header: "Send promotion",
                id: "Resend",
                filterable: false,
                style: {'textAlign': 'center'},
              accessor: d=> (<input type="checkbox" checked={_.findIndex(props.emailsData, function(o) { return o.indexVal==d[3]})>=0? true : false} onChange={()=>{props.checkData(d)}} /> ),
                
              }  ]}
        defaultPageSize={25}
        sortable={true} 
        filterable={true}
        className="-striped -highlight"
        
      />

        
    );
  };

  export default XlsData;
