/* Importing the node modules, child components, services and controllers used 
   inside FiveStarLanding component */
import $ from "jquery";
import AppController from "../../controller/appController";
import registerService from '../../../services/registerService';
import FiveStarApiServices from '../../../services/fiveStarApiServices';
/* FiveStarLanding Component initialization */
class ForgotPassword extends React.Component {
  /* Initializing objects of its FiveStarLanding class */
  constructor(props) {
    super(props)
    /* FiveStarLanding Component State variables Initialization */
    this.state = {
      driversList: [],
      driverid: '',
      password: '',
      message: '',
      authentication: [],
      email: '',
      loader: false,
      color: '',
      emailerr: ''
    }
    this.setValue = this.setValue.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
  }
  /* It is invoked immediately after a component is mounted */
  async componentDidMount() {
    $('html, body').animate({ scrollTop: 0 });
    AppController.stickyTitles();
  }

  /* To change the selected input field value */
  setValue(field, e) {
    var object = {};
    object[field] = e.target.value;
    this.setState(object);
  }

  /* Authentication checking before login to the five star application */
  clearData() {
    this.setState({ emailerr: '', color: '', loader: false, email: '' })
  }

  async forgotPassword(e) {
    e.preventDefault();
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (this.state.email != '' && reg.test(this.state.email) == true) {
      const requestObj = {
        "authkey": "",
        "sourcetype": "fivestar",
        "requestforkey": true
      }
      const TimeOut = await FiveStarApiServices.GetTimeoutKey(requestObj)

      this.setState({ loader: true, emailerr: '', color: '' })
      const emailSend = {
        email: this.state.email,
        encodeEmail: Base64.encode(this.state.email),
        fivestar: 2,
        timeoutKey: TimeOut.timeoutkey
      }
      const userData = await registerService.emailSendToGuest(emailSend)
      if (userData.response) {
        this.setState({ emailerr: 'Mail Sent To Your Account', color: 'green', loader: false, email: '' })

      } else {
        this.setState({ emailerr: 'We Are unable to sent to mail', color: 'red' })
      }
    } else {
      this.setState({ emailerr: 'Enter Valid Email', color: 'red', loader: false })
    }

  }
  /* It is invoked to return html content */
  render() {
    return (
      <div id="pages_5star" style={{ 'background': '#f5f5f5' }}>
        <div className="login_main">
          <div className="container-fluid">
            <div className="menu-links">
              <ul className="translation-links">
                <li><a data-lang="English" id='lang1'>English</a></li>
                <li className="devider">|</li>
                <li><a data-lang="Spanish" id='lang2'>Spanish</a></li>
              </ul>
            </div>

            <div className="login_star">


              <img src="/assets/newUI-images/5-starLogo.png" alt="" />
              <div className="col-sm-12">

                {this.state.emailerr
                  ? <span
                    style={{
                      color: this.state.color,
                      textAlign: 'right'
                    }}>{this.state.emailerr}</span>
                  : ''}
              </div>
              <form onSubmit={(e) => this.forgotPassword(e)}>
                <div className="f-contact-form-style">
                  <div className="form-group">
                    <input type="text" className="form-control" id="exampleInputPassword1" placeholder="Email" value={this.state.email}
                      onChange={(e) => this.setValue('email', e)} />
                  </div>
                  {this.state.loader ? <div className="panel-body_loader"> <span style={{ fontSize: '20px', width: '100%', margin: 'auto', textAlign: 'center' }}><i className="fa fa-spinner fa-spin" /> please wait ...!</span>

                  </div> :
                    <div className="mrg-btm-20"><button type="submit" className="btn"> Submit</button></div>
                  }
                </div>
              </form>
            </div>
          </div>

        </div>

      </div>

    );
  }
}
export default ForgotPassword;