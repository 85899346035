/* Importing the node modules, child components, services and controllers used 
   inside TeslaFrequentQuestions component */    
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import TeslaHeader from "../../common/teslaHeader";
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   /* TeslaFrequentQuestions Component initialization */
   class TeslaFrequentQuestions extends React.Component {
       /* Initializing objects of its TeslaFrequentQuestions class */
       constructor(props) {
           super(props);
           this.state={
            faq: [{
                'Question':'What items are typically included in SuiteAmerica’s Corporate Apartments?',
                'Answer': "All of SuiteAmerica’s apartments are fully furnished and appointed, offering all the amenities of being at home to make your stay more comfortable and enjoyable. For a complete list of included utilities, furnishings and housewares, please <a href='/company/tesla/whats-included'>click here.</a>"
            },
            {
                'Question':'Who do I contact to make a reservation or request more information?',
                'Answer': "Please visit our <a href='https://www.suiteamerica.com/company/tesla/reservation-form'>Reserve Now</a> page for details about how to place a reservation request, and what to expect. If you would like to speak with a representative directly, you may reach out to our dedicated Tesla Relocation Coordinator, Lisa Lawhead-Briseno, by calling 1-800-367-9501 ext.140 or by email, <a href='mailto:TESLA@SuiteAmerica.com'>TESLA@SuiteAmerica.com</a>"
            },
            {
                'Question':'Can I bring my pet?',
                'Answer': "Many of SuiteAmerica’s communities allow pets. Please specify the type of pet in our reservation form. A non-refundable pet fee and pet rent may apply, as well as breed restrictions. If you do bring a pet, make sure all vaccination records are up to date as you will be required to show a copy. If you have additional questions you may reach out to our dedicated Tesla Relocation Coordinator, Lisa Lawhead-Briseno, by calling 1-800-367-9501 ext. 140 or by email, <a href='mailto:TESLA@SuiteAmerica.com'>TESLA@SuiteAmerica.com</a>"
            },
            {
                'Question':'Can I make reservations directly with SuiteAmerica or do I need to use a travel management service?',
                'Answer': "As an authorized housing provider to Tesla Inc., SuiteAmerica can accept reservations directly. You do not need to go through a Travel Management Company. Please visit the <a href='https://www.suiteamerica.com/company/tesla/reservation-form'>Reserve Now</a> page for further information."
            },
        {
            'Question':'What are the standard Check In and Check Out times?',
            'Answer': "Standard Check In time is 3pm but it can be subject to change to either 4pm or 5pm. Your Housing Confirmation will provide the exact Check In time. Standard Check Out time is 11:59pm, the day of your departure."   
        },
        {
            'Question':'How do I access my apartment at Check In?',
            'Answer': "A lockbox or electronic keyless entry is the standard method used to access your apartments. Detailed arrival instructions on how to access your keys will be included in your reservation Housing Confirmation prior to your arrival."  
        }]
           }
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
           MainController.getInitialInformation();
        }
       /* It is invoked to return html content */
       render() {
           const faq = this.state.faq;
           return (
                <div className="corporateHousing_Updates">
                    <GuestHeader/>
                    <div className="cover-tophd-ht company_ht_cover"></div>
                    <TeslaHeader />
                    <div className="container-fluid nike guest_sitenew">
                        <div className="container intra_staff2 col-sm-8 col-sm-offset-2">
                            <h2 className="text-center company-title">FREQUENT QUESTIONS</h2>
                            <div id="accordion" className="panel-group mrg-btm-90 company_acdn" role="tablist" aria-multiselectable="true">
                        {faq.map((row, index) => {
                        return (
                        <div className="panel panel-default" key={index}>
                           <div className="panel-heading">
                              <h4 className="panel-title">
                                 <a className="collapsed"
                                    data-toggle="collapse"
                                    data-parent="#accordion"
                                    href={'#collapse' + index}
                                    aria-expanded="false"
                                    aria-controls={row.id}
                                    >{row.Question}</a>
                              </h4>
                           </div>
                           <div id={'collapse' + index}
                              className="panel-collapse collapse">
                              <div className="panel-body">
                                 <div className="profile-opener" dangerouslySetInnerHTML={{
    __html: row.Answer.replace("Nike’s","Tesla’s")
  }}>
                                 </div>
                              </div>
                           </div>
                        </div>
                        )
                        })}
                     </div>
                        </div>
                    </div>
                    <div className="container-fluid bg-grey">

                    </div>
                    <SubFooter/>
                    <Footer/>
                </div>
           );
       }
   }
   export default TeslaFrequentQuestions;