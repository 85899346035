/* Importing the node modules, child components, services and controllers used 
   inside IndividualTechnology component */   
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   import ReserveYourStay from '../../common/reserveYourStay.js';
   import MetaTags from '../../common/metaTags';
   import ServerMetaTags from '../../../server/metaTags';
   import NavigationFooter from '../../common/navigationFooter';
   import NavigationData from '../../common/navigation.json';

   /* IndividualTechnology Component initialization */
   class IndividualTechnology extends React.Component {
       /* Initializing objects of its IndividualTechnology class */
       constructor(props) {
           super(props);   
           this.state = {
            navigationData: NavigationData.individualTechnology
         }
       }
       /* It is invoked immediately before mounting occurs */
       componentWillMount() {
           MainController.getProfilePageInformation(16, 4, 4);
       }
       /* It is invoked to return html content */
       render() {
           const metaData = ServerMetaTags.urlString.getMetaData(this.props.match.url);
           return (<div>
               <MetaTags 
               metaTitle = {metaData.title}
               metaDesription = {metaData.description}
               metaUrl = {metaData.canonical} />
               <GuestHeader/>   
               <div className="res-md res-sm technology">
                   <header className="codeconveyHeader cc_image_text_main corporate-h video-wrapper">
                       <img className="img-responsive relocation-corporate-housing" src="/assets/newUI-images/Website-Images/Corporation-Corporate-Housing-Advanced-Interactive-Tools.jpg" width="100%" alt=""  />
                       <div className="wow fadeInUp img-on-text text-center top40 left10 width500 top35-md left20-md color-black" data-wow-delay="0.5s">
                           <h1>ALWAYS CONNECTED</h1>
                           <p>We have developed advanced web-based tools to create a level of connectivity never before seen in the industry.  Our centralized information platform connects you to a wealth of information updated in real-time and available anywhere in the world, 24/7. Settling in and staying in touch has never been easier.</p>
                       </div>
                   </header>
                   <ReserveYourStay />
                   <div className="who_weare services_ch">
                           <div className="cc_image_text_main">
                               <img className="img-responsive left-mo" src={s3BucketUrl_WebsiteImages+"Relocation-Corporate-Housing-Customize-Your-Stay.jpg"} alt="" />
                           </div>
                       </div>
                       <div className="new_txt_sec mrg-btm-0">
                           <div className="new_txt_content container wow fadeInUp animated">
                               <div className="col-md-8 col-md-offset-2">
                               <h2>CONNECTION THROUGH CONVERSATION AND TECHNOLOGY</h2>
                               <p>From area information, to service requests to apartment specifics, we’ve made communication on-demand for you, our guest. Our interactive tools are easily accessible from any desktop or mobile device.  
                               </p>
                               </div>
                           </div>
                           <div className="clearfix"></div>
                       </div>
                    <div className="services_ch grey-xs">
                           <div className="carousel banner_text">
                               <img className="img-responsive right-15" src={"/assets/newUI-images/Website-Images/Corporation-Technology-Guest-Portal.png"} alt="" />
                               <div className="img-on-text width500 left55 top30 color-dark md-text width300-md left60-md top50-md width300-sm left55-sm top30-sm wow fadeInUp" data-wow-delay="0.5s">
                               <h2>Guest Portal<br/>
                               Online Account Access
                               </h2>
                               <p>Our secure online portal allows you to view your suite information, place and track service requests, find things to do and receive special offers in your new community and customize your stay with custom amenities from our online store. 
                               </p>
                               </div>
                           </div>
                       </div>
                       <div className="caption_text services_cs">
                           <div className="container">
                               <div className="who_weare_text22 wow fadeInUp" data-wow-delay="0.5s">
                               <h1 className=" font-bold">THE MOST</h1>
                               <h2 className=""><span>are</span></h2>
                               <h1 className="">EFFECTIVE GESTURES</h1>
                               <h2 className=""><span>unseen</span></h2>
                               </div>
                           </div>
                       </div>
                       <div className="services_ch grey-xs">
                           <div className="carousel banner_text">
                               <img className="img-responsive" src={s3BucketUrl_WebsiteImages+"Corporation-Technology-Automated-Itineraries.jpg"} alt="" />
                               <div className="img-on-text width600 left50 top65 color-dark md-text left0-md width300-md top60-md left55-md width300-sm left55-sm top55-sm wow fadeInUp animated" data-wow-delay="0.5s">
                               <h2>Automated Itineraries</h2>
                               <p>Our Destination Services itineraries receive real-time updates from our centralized database, allowing drivers to edit selections and update routes in an instant. You can take notes, make changes and rate properties throughout your tour.
                               </p>
                               </div>
                           </div>
                       </div>
                       <div className="services_ch white-xs">
                           <div className="carousel banner_text">
                               <img className="img-responsive right-70" src={"/assets/newUI-images/Website-Images/Corporation-Technology-Corporate-Housing.jpg"} alt="" />
                               <div className="img-on-text width500 left5 top25 color-dark text-right md-text width300-md left10-md top30-md width300-sm top30-sm wow fadeInUp animated" data-wow-delay="0.5s">
                               <h2>Corporate Housing<br/>
                               Web Application
                               </h2>
                               <p>Our cloud-based end-to-end solution provides real-time booking, tracking and reporting, while providing a centralized data system that connects and powers all of our ground-breaking digital tools. 
                               </p>
                               </div>
                           </div>
                       </div>
                       <div className="quote-section bg-grey quote_red">
                           <div className="container">
                               <div className="wow fadeInUp" data-wow-delay="0.5s">
                               <h6 className="quote-s"> <img src={s3BucketUrl_WebsiteImages+"Corporation-Corporate-Housing-Quote.png"} alt=""/></h6>
                               <div className="col-md-10 col-md-offset-1">
                                   <h5 className="quote-text">I filed a service request through the web app and was amazed at how quickly 
                                   SuiteAmerica responded to me and handled things. I’m used to waiting at least a few 
                                   days for things to get fixed, but they handled it in less than an hour.
                                   </h5>
                               </div>
                               </div>
                           </div>
                       </div>
                   <div className="clearfix"></div>
                   <NavigationFooter {...this.state} />
                   <SubFooter />
                   <Footer/>
         </div>
         </div>
           );
       }
   }
   export default IndividualTechnology;