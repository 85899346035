/* Importing the node modules, child components, services and controllers used 
   inside Staff component */
import ExecutiveStaff from './executiveStaff';
import $ from "jquery";
import AppController from "../../../controller/appController";
import EmployeeHeader from '../../../common/employeeHeader';
/* Staff Component initialization */
class Staff extends React.Component{
	/* Initializing objects of its Staff class */
	constructor(props) {
		super(props)
		/* Staff Component State variables Initialization */
		this.state = {
		  showView: -1,
		  teamsData:[],
		  staffData:[],
		}
		this.menuNavigation = this.menuNavigation.bind(this);
		this.EmployeeDetails = this.EmployeeDetails.bind(this);
	  }
	/* It is invoked immediately after a component is mounted */  
	componentDidMount() {
		$(document).ready(function () {
			$('html, body').animate({scrollTop: 0});
			AppController.stickyTitles()
				$(".expand").on( "click", function() {
					var expand = $(this).find(">:first-child");	
					if(expand.text() == "+") {
						expand.text("-");					   
					} else {
						expand.text("+");
					}
				  });
		})
		
	}
	/* To move to the top of the page */
    menuNavigation(idName) {		
        $('html, body').animate({
            scrollTop: $('#' + idName).offset().top - 150
        },800);
	}
	EmployeeDetails(id){	
		this.setState({
			'showView': id 
		});
	}

/* It is invoked to return html content */	
render(){	
    return(
        <div>
			<EmployeeHeader/>
            <div className="intra_staff">
    <div className="carousel banner_text">
        <img className="img-responsive" src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Intranet-Staff-Banner-Image.jpg"} width="100%" alt="" />
          <div className="carousel-caption slide-text slide_text_third">
            <div className="slide_style_left">
                <h1  className="toggleHeading wow fadeInDown animated">STAFF DIRECTORY</h1>
            </div>
        </div>  
    </div>
</div>
<div className="services_heading intra_staff" >
	<div className="container">		
		<div className="row">
			<div className="col-sm-3">
				<p className="mrg-btm-0">STAFF DIRECTORY</p>
			</div>
			<div className="col-sm-9">
				<ul>
		
					<li><a onClick={() => this.menuNavigation('intranet-teams')}>TEAMS</a></li>
					<li><a onClick={() => this.menuNavigation('OFFICES')}>OFFICES</a></li>
					<li><a onClick={() => this.menuNavigation('intranet-everyone')}>EVERYONE</a></li>
					<li className="search_li">
					  		<div className="intra_sch">
								<input type="search" placeholder="Search" />
								<a href="">
									<i className="fa fa-search"></i>
								</a>
						</div>
					</li>
				</ul>
			</div>
		</div>
		
	</div>
	
</div>

    <ExecutiveStaff {...this.state}  EmployeeDetails={this.EmployeeDetails}/>

               </div>
    );
}
}
export default Staff;