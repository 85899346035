/* Importing the node modules, child components, services and controllers used 
   inside TravelBookingFooter component */
   import MainController from '../controller/mainController';
   import BookingSubFooter from './bookingSubFooter';
   import BookingFooter from './bookingFooter';
   import Footer from '../common/footer';
   
   /* TravelBookingFooter Component initialization */
   class TravelBookingFooter extends React.Component{
       /* Initializing objects of its TravelBookingFooter class */
       constructor(props) {
           super(props)
           /* TravelBookingFooter Component State variables Initialization */
           this.state = {
               currentYear: new Date().getFullYear()
           }   
       }
       /* It is invoked immediately after a component is mounted */
       componentDidMount() {
          MainController.removeClassOnNavCollapse(); 
       }
   /* It is invoked to return html content */      
   render(){  
       return(
        <div className="travel-footer">      
            <div className="new1-footer">
                <Footer/>
            {/* <BookingSubFooter />
            <BookingFooter {...this.state}/>       */}
            </div>
        </div>     
       );
   }
   }
   export default TravelBookingFooter;