const ThirdRowFields = props => {  
const row= props.rowData
var start = moment.utc(row.FivestarStartDateTime, "YYYY-MM-DD hh:mm:ss A");
var end = moment.utc(row.FivestarStopDateTime, "YYYY-MM-DD hh:mm:ss A");
var d = moment.duration(end.diff(start));
var timetaken = moment.utc(+d).format('HH:mm:ss ');
return ( 
  <div className="col-sm-4">
        <div className="sc-order-fl-item">
        {props.manager ?<ul className="mrg-btm-20">
             <li><span className="font-bold" style={{color:'#dd2a5a'}}>Time Taken:</span> <span>{row.FivestarStopDateTime == undefined || row.FivestarStartDateTime == undefined || row.FivestarStopDateTime == null || row.FivestarStartDateTime == null ?'--':timetaken} </span></li>
             </ul>:null}
        <ul className="mrg-btm-20">
            <li><span className="font-bold">Tasks:</span></li>
            {row.tasks == undefined || row.tasks == null?<li>--</li>:<li>{row.tasks}</li>}
          </ul>
          <ul className="mrg-btm-20">
            <li><span className="font-bold">Pick List:</span></li>
            {row.PickList == undefined || row.PickList == null?<li>--</li>:<li>{row.PickList}</li>}
          </ul>

          <ul className="mrg-btm-20">
            {props.NotesData.length > 0 ?
            
            <li className="notes-Owel mrg-btm-20">
            <span className="font-bold color-red">Notes</span>
              <div className="scrollbar" style={{marginTop: '20px'}}>
                {_.filter(props.NotesData, function(o) { return o!=''; }).map((notesText, index) => {
                  return <div key={index} dangerouslySetInnerHTML={{__html: $.parseHTML(notesText)[0].data}}></div>
                  })
                }
              </div>
            </li>:null}
          </ul>

        </div>
        
      </div>
      
)

}

export default ThirdRowFields;


