import AmenitesList from './AmenitesList';
import OwlCarousel from 'react-owl-carousel';
const CommunityMoreInfo = (props) => {
    const {community}=props
    var communityImages=[]
    var comImages=(community.compleximages!="" && community.compleximages!= undefined && community.compleximages !=null && community.compleximages !='null')? community.compleximages.split(',') :  ""
    var intImages=(community.interiorimage!="" && community.interiorimage!= undefined && community.interiorimage !=null && community.interiorimage !='null')? community.interiorimage.split(',') :  ""
    let  concatImage =_.concat(communityImages,comImages,intImages)
    concatImage = (community.interiorimage=="" || community.compleximages=="")? "" : concatImage.filter(v=>v!='')
    const unitamenities = (community.unitamenities!="" && community.unitamenities!= undefined && community.unitamenities !=null && community.unitamenities !='null')? community.unitamenities.split(',') :  ""
	const complexamenities = (community.complexamenities!="" && community.complexamenities!= undefined && community.complexamenities !=null && community.complexamenities !='null')? community.complexamenities.split(',') :  ""	
    return (
        <div className="modal fade community_info_popup" id="communitymoreinfo" role="dialog">
        <div className="modal-dialog">
           <div className="modal-content rm-border-radius">
              <div className="modal-header">
                 <button type="button" className="close btn" data-dismiss="modal" aria-label="">
                 <i className="bi bi-x"></i>
                 </button>
              </div>
              <div className="modal-body clearfix" >
              <div>
                 <div className="col-md-8 col-sm-8">
                    <div className="community-item community-item-full">
                       <div className="community-item-dtls">
                          <h5>{community.community_name}</h5>
                          <p>{community.address}</p>
                          <div className="pull-left text-left">
                             <p className="area"><strong>{community.city_name},</strong>{community.state_name}</p>
                             <p className="distance"><img src="https://s3-us-west-1.amazonaws.com/suiteam-website/Travel-Booking/SuiteAmerica-Travel-Booking-Map.png" alt=""/><strong>{community.distance}</strong> Miles away</p>
                          </div>
                          {(community.walkScore!='' && community.walkScore!=null && community.walkScore != undefined) ?
                          <div className="walkscore pull-right">
                             <div className="arrow_box">{community.walkScore}</div>
                             <span>Walk Score</span>
                          </div>
                          :''}
                       </div>
                       <div className="clearfix"></div>
                    </div>
                    <div className="community-tabs">
                       <ul className="nav nav-tabs" role="tablist">
                          <li role="presentation" className="active">
                             <a href="#one" aria-controls="home" role="tab" data-toggle="tab"><img src="" alt=""/><i className="icon icon-454"></i>Photos</a>
                          </li>
                          <li role="presentation" onClick={props.mapView}>
                             <a href="#two" aria-controls="profile" role="tab" data-toggle="tab"><i className="icon icon-432"></i>Map</a>
                          </li>
                          <li role="presentation" onClick={props.streetView}>
                             <a href="#three" aria-controls="messages" role="tab" data-toggle="tab"><i className="icon icon-425"></i>Street view</a>
                          </li>
                       </ul>
                       <div className="tab-content travel-booking-slider">
                          <div role="tabpanel" className="tab-pane active" id="one">
                             {concatImage.length > 0?
                             <OwlCarousel  {...{ loop: false, nav:true, dots: true, items: 1, navContainerClass: 'owl-buttons', navText: ["<img src='https://s3-us-west-1.amazonaws.com/suiteam-website/Website-Images/SuiteAmerica-Carousel-Left-Arrow.png'>", "<img src='https://s3-us-west-1.amazonaws.com/suiteam-website/Website-Images/SuiteAmerica-Carousel-Right-Arrow.png'>"], responsive:{ 320:{ items:1, nav:true, loop:false }, 767:{ items:1, nav:true, loop:false }, 992:{ items:1, nav:true, loop:false }, 1199:{ items:1, nav:true, loop:false } }}}>
                             {concatImage.map((row, index) => {
                             return <div className="item" key={index}><img className="img-responsive" src={row} alt={row}/></div>
                             })}
                             </OwlCarousel>
                             : null}
                          </div>
                          <div role="tabpanel" className="tab-pane" id="two">
                             <div className="lc_map">
                                <div className="map-alignment">
                                   <div id="map" className="map-alignment"></div>
                                </div>
                             </div>
                          </div>
                          <div role="tabpanel" className="tab-pane" id="three">
                             <div className="lc_map">
                                <div className="map-alignment">
                                   <div id="street-view" className="map-alignment"></div>
                                </div>
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
                 
                 <AmenitesList appartmentAmenities={unitamenities} communityAmenities={complexamenities}/>
                
              </div>
           </div>
        </div>
     </div>
     </div>
        );
    };
export default CommunityMoreInfo;