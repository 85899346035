/* Importing the node modules, child components, services and controllers used
   inside QuickRequestForm component */
import $ from "jquery";
import AppController from "../../controller/appController";
import GuestController from "../../controller/guestController";
/* QuickRequestForm Component initialization */
class QuickRequestForm extends React.Component {
    /* Initializing objects of its QuickRequestForm class */
    constructor(props) {
        super(props)
        this.state = {
            menuShow: props.menuShow,
            firstName: '',
            emailId: '',
            phoneNo: '',
            community: '',
            accountNo: '',
            conformationNo: '',
            description: '',
            orderUniqueId: '',
            LeaseUId: '',
            petAllowed: true,
            allRequest: '',
            chatData: '',
            response: '',
            ChangedBy: '',
            EntityUId: '',
            TypeCode: '',
            EntryDate: '',
            EnteredBy: '',
            Notes: '',
            responseEmail: '',
            message: '',
            loadingStatus: false,
            GuestEmail: '',
            orderno: '',
            loader: false,
            color: '',
            userData: {}
        }
        this.setValue = this.setValue.bind(this);
        this.allowed = this.allowed.bind(this);
        this.submitRequest = this.submitRequest.bind(this);
        this.menuToggle = this.menuToggle.bind(this);
    }
    /* It is invoked immediately before mounting occurs */
    componentWillMount() {
        $('html, body').animate({scrollTop: 0});
        AppController.stickyTitles();
    }
    /* It is invoked immediately after mounting occurs */
    async componentDidMount() {
        const GuestLoginInfo = await GuestController.guestLoginResponse()
        await this.setState({userData: GuestLoginInfo})
        const guestOrder = await GuestController.getGuestInformation()
        if (guestOrder != null && Object.keys(guestOrder).length > 0) {
            await this.setState({
                firstName: guestOrder.GuestFirstName,
                emailId: guestOrder.GuestEmail,
                phoneNo: guestOrder.GuestPhoneNo,
                community: guestOrder.community_name,
                accountNo: guestOrder.accountId,
                conformationNo: guestOrder.orderno,
                orderUniqueId: guestOrder.UniqueId,
                LeaseUId: guestOrder.LeaseUId,
                GuestEmail: guestOrder.GuestEmail,
                orderno: guestOrder.orderno
            })
        }
    }
    /* To change the input field values */
    setValue(field, e) {
        this.setState({color: '', message: ''})
        var object = {};
        object[field] = e.target.value;
        this.setState(object);
    }
    async allowed() {
        await this.setState({
            petAllowed: !this.state.petAllowed
        })
    }
    /* To submit the request */
    async submitRequest() {
        this.setState({loader: true})
        if (this.state.description != '') {
            const pstDate = AppController.pstDate()
            const serviceRequest = {
                orderUid: this.state.orderUniqueId,
                LeaseUId: this.state.LeaseUId,
                RequestDate: pstDate,
                CategoryCode: 'NOTE',
                CreatedDate: pstDate,
                Requested: this.state.firstName,
                CreatedBy: this.state.emailId,
                Description: this.state.description,
                phoneNo: this.state.phoneNo,
                community: this.state.community,
                accountNo: this.state.accountNo,
                conformationNo: this.state.conformationNo,
                petAllowed: this.state.petAllowed? 1: 0,
                source: 'ABE',
                changedby: this.state.emailId
            }

            const serviceResp = await GuestController.insertServiceRequest(serviceRequest)
            if (serviceResp.status == 200) {
                this.setState({description: '', loader: false})
                jQuery(function ($) {
                    $('#successModal').modal('show');
                    setTimeout(function () {
                        $('#successModal').modal('hide');
                    }, 1500);
                });
            } else {
                this.setState({description: '', color: 'green', message: 'Mail Not Sent', loader: false})
            }
        } else {
            this.setState({color: 'red', message: 'Please Enter Description', loader: false})
        }
    }
    menuToggle() {
        this.setState({
            menuShow: !this.state.menuShow
        });
    }
    /* It is invoked to return html content */
    render() {
        return (
            <div className="padding-top-25">


{/*   <div className="menu_toggler">
                <div className="container">
                    {this.state.menuShow && userData.status!='TERMED'
                        ? <form className="menu_toggler_form">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>QUICK SERVICE REQUEST</label>
                                        <textarea
                                            className="form-control"
                                            value={this.state.description}
                                            onChange={(e) => this.setValue('description', e)}></textarea>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>DO WE HAVE PERMISSION TO ENTER YOUR APARTMENT?</label>
                                        <div className="intra_switch2">

                                            <div className="one">
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        value={this.state.petAllowed
                                                        ? true
                                                        : false}
                                                        checked={this.state.petAllowed
                                                        ? true
                                                        : false}
                                                        onChange={this.allowed}/>
                                                    <span className="slider round"></span>
                                                </label>
                                                <sub>Yes</sub>
                                            </div>
                                            <div className="two">
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        value={!this.state.petAllowed
                                                        ? true
                                                        : false}
                                                        checked={!this.state.petAllowed
                                                        ? true
                                                        : false}
                                                        onChange={this.allowed}/>
                                                    <span className="slider round"></span>
                                                </label>
                                                <sub>No</sub>
                                            </div>
                                            <div className="clearfix"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="clearfix"></div>
                                <button
                                    type="button"
                                    className="btn button-border"
                                    onClick={this.submitRequest}>Submit</button>
                            </form>
                        : null}
                </div>
                <div
                    className="menu_toggler_btn"
                    onClick={this.menuToggle}>
                    <i className="fa fa-bars"></i>
                </div>
                <SuccessModal/>
          </div> */}

            </div>
         
        );
    }
}
export default QuickRequestForm;