/* Importing the node modules, child components, services and controllers used 
   inside ListView component */  
   import ListViewModal from './modals/listViewModal';
   import Community from './community';
   
   /* ListView Component initialization */
   class ListView extends React.Component {
     /* Initializing objects of its ListView class */
     constructor(props) {
       super(props)
       /* ListView Component State variables Initialization */
       this.ListViewModal = new ListViewModal(this, props)
       this.state = Object.assign(this.ListViewModal.state)
       this.loadMore = this.loadMore.bind(this);
     }
   /**
    * Click on load more we get morethan 10 records
    * @param {*more,less} limit 
    */
     async loadMore(){
        this.props.loadMore()
     }
     /**
    * get Detail information about single community like amenities,map,interior images
    * @param {communityid,row,index} limit 
    */

     /**
      * when the person is login then only insert his favourites
      * @param {*communityid} data 
      */
   /**
    * when we click on the moreInfo button detail insormation about the 
    * @param {*community details } data 
    * @param {*} favouriteCheck 
    */
     async moreInfo(data, favouriteCheck) {
      this.ListViewModal.moreInfo(data,favouriteCheck)
     }
   
    async calendarInfo(data){
      this.props.calendarInfo(data)
     }    
     async viewPlaces(row){
     await this.setState({latitude:row.latitude,longitude:row.longitude,communityName:row.community_name})
      }
     /* It is invoked to return html content */
     render() {
      
       const localStorageVal = (localStorage.getItem('_u_web') == null)
         ? "#mysave1"
         : null
       const communityData = this.props.communityData
    
       return (
         <div>
           {this.props.communityData == 0 && this.props.isLoading==false? <h1 style={{'textAlign': 'center','color': 'red'}}>No Results Found</h1>: null}
   
             {this.props.isLoading? null:
             <div>
           {communityData.map((row, index) => {
             const favouriteCheck = _.find(this.props.userFavourites, function (o) {
               return o.ComplexUId == row.community_id;
             })
             
             return (
               <Community 
               key={row.community_id}
               index = {index}
               row={row}
               localStorageVal= {localStorageVal}
               companyName={this.state.company}
               favouriteCommunity={this.props.favouriteCommunity}
              unFavouriteCommunity={this.props.unFavouriteCommunity}
               />

             )
           })}
           </div>}
           {this.props.loadMoreButton? <div className="panel-body_loader loadmore" > <span style={{fontSize: '30px', width: '100%', margin: 'auto', textAlign: 'center'}}><i className="fa fa-spinner fa-spin"/> please wait ...!</span>
                 </div> : '' }
           {(!this.props.loadMoreButton && this.props.communityData.length >=10 && this.props.loadMoreString) ?
           <div className="loadmore"> <a  onClick={this.loadMore}>Load More <i className="fa fa-plus"></i></a> </div>
           :null}
            
            </div>
   
           
   
       );
     }
   }
   export default ListView;
   