import React from "react";
import moment from "moment";
import { SuiteCareContext } from "../context/Context";
import NikeService from "../../../../../services/nikeService";
import BookitApiService from "../../../../../services/bookit";

const Schedule = () => {
  const { suiteCareState, getClientData } = React.useContext(SuiteCareContext);
  const { selectedSchedule } = suiteCareState;
  const [loader, setLoader] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");
  const [successMsg, setSuccessMsg] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const closeScheduleModal = () => {
    $(function () {
      $("#schedule-modal").modal("hide");
    });
    setErrorMessage("");
    setLoader(false);
    setSuccessMsg(false);
    setSuccessMessage("");
  };

  if (selectedSchedule == null) {
    return null;
  }

  const cancelCleanSchedule = async (selectedSchedule) => {
    // console.log(selectedSchedule, "selectedSchedule");
    setLoader(true);
    const sessionResponse = await BookitApiService.checkSession();
    let userEmail = sessionResponse.email;

    let requestObj = {
      serviceorscheduleuid: selectedSchedule.ScheduleUId,
      typecode: "Schedules",
      createdby: userEmail,
    };
    let cancelScheduleApi = await BookitApiService.CancelScheduleOrService(
      requestObj
    );
    // console.log(cancelSchedule, "cancelSchedule");
    if (cancelScheduleApi.message == "Success") {
      let object = {
        scheduleId: selectedSchedule.ScheduleId,
        serviceDate: moment(selectedSchedule.DateRequested).format(
          "YYYY-MM-DD"
        ),
        serivceType:
          selectedSchedule.TypeCode == "ROT"
            ? "Rommate Clean "
            : "Full Apartment Clean",
        mail: "CancelCleanSchedule",
        subject: `Cancelled clean schedule - ScheduledId# ${selectedSchedule.ScheduleId} `,
        // toEmail: sendTo,
      };
      await NikeService.serviceRequest(object);
      setLoader(false);
      setSuccessMsg(true);
      setSuccessMessage("Clean schedule has been cancelled successfully");
      await getClientData();
      // $(function () {
      //   $("#schedule-modal").modal("hide");
      // });
    } else {
      setErrorMessage(cancelScheduleApi.message);
      setLoader(false);
    }
  };
  const okButton = () => {
    $(function () {
      $("#schedule-modal").modal("hide");
    });
    setErrorMessage("");
    setLoader(false);
    setSuccessMsg(false);
    setSuccessMessage("");
  };
  return (
    <div
      className="modal fade savesearch "
      data-backdrop="static"
      id="schedule-modal"
      role="dialog"
    >
      <div className="modal-dialog">
        <div className="modal-content rm-border-radius">
          <div className="modal-header">
            <button
              type="button"
              onClick={closeScheduleModal}
              className="close btn"
              data-dismiss="modal"
              aria-label=""
            >
              <i className="bi bi-x"></i>
            </button>
          </div>
          {successMsg == false && (
            <div className="modal-body">
              <h3 className="mb-5">Clean schedule</h3>
              <h4 style={{ color: "red" }}>{errorMessage}</h4>
              <form>
                <div className="form-group">
                  <label>Type </label>
                  <b>:</b>
                  <label className="main">
                    {selectedSchedule.TypeCode == "ROT"
                      ? "Roommate Clean"
                      : "Full Apartment Clean"}
                  </label>
                </div>
                {/* <div className="form-group">
                <label>Status Code</label>
                <b>:</b>
                <label className="main">
                  {selectedSchedule.StatusCode || "null"}
                </label>
              </div> */}

                <div className="form-group">
                  <label>Clean Confirmed</label>
                  <b>:</b>
                  <label className="main">
                    {/* {selectedSchedule.IsVendorConfirmed ? "true" : "false"} */}
                    {selectedSchedule.IsVendorConfirmed
                      ? "confirmed"
                      : "pending"}
                  </label>
                </div>
                <div className="form-group">
                  {selectedSchedule.IsVendorConfirmed ? (
                    <label>Date Confirmed</label>
                  ) : (
                    <label>Date Requested</label>
                  )}
                  <b>:</b>
                  <label className="main">
                    {moment(selectedSchedule.DateRequested).format(
                      "MM/DD/YYYY"
                    )}
                  </label>
                </div>
              </form>
              <div>
                {loader ? (
                  <div
                    className="text-center"
                    style={{
                      fontSize: "20px",
                    }}
                  >
                    <i className="fa fa-spinner fa-spin" />
                    Please wait ...!
                  </div>
                ) : (
                  <button
                    className={"btn btn-dark m-r-5 "}
                    onClick={() => cancelCleanSchedule(selectedSchedule)}
                  >
                    Cancel Schedule
                  </button>
                )}
              </div>
            </div>
          )}
          {successMsg == true && (
            <div className="modal-body">
              <div className="alert alert-success">{successMessage}</div>
              <button
                className="btn btn-dark rounded-5 mt-3 mb-4"
                onClick={() => okButton()}
              >
                Ok
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Schedule;
