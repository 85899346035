/* Importing the node modules, child components, services and controllers used 
   inside VehicleCheckList component */
import $ from "jquery";
import AppController from "../../../controller/appController";
import FivestarHeader from '../../../common/fivestarHeader';
/* VehicleCheckList Component initialization */
class VehicleCheckList extends React.Component{
    /* Initializing objects of its VehicleCheckList class */
    constructor(props) {
		super(props)
      }
      /* It is invoked immediately before a component is mounted */
      componentWillMount() {
        $('html, body').animate({scrollTop: 0});
        AppController.stickyTitles()
      }
/* It is invoked to return html content */
render(){
    return(
        <div>
        <FivestarHeader/>
        <div style={{'width':'100%', 'textAlign':'center', 'margin':'50px 0px'}}>
                   <img className="img-responsive" src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Coming-Soon.png"} style={{'margin':'auto'}}></img>
                   <div> <h1>Vehicle CheckList Coming Soon ...</h1></div>
               </div>
               </div>
    );
}
}
export default VehicleCheckList;