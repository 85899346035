/* Importing the node modules, child components, services and controllers used
   inside ClientForGotPassword component */   
   import $ from "jquery";
   import AppController from "../../controller/appController";  
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   import ClientApiService from '../../../services/clientApiService';
   import SuccessModal from  './successModal'
   /* ClientForGotPassword Component initialization */
   class ClientForGotPassword extends React.Component {
       /* Initializing objects of its ClientForGotPassword class */
       constructor(props) {
           super(props)
           /* ClientForGotPassword Component State variables Initialization */
           this.state = {
               email: '',
               password: '',
               confirmPassword: '',
               message: '',
               color: ''
           }
           this.setValue = this.setValue.bind(this);
           this.guestforgotpassword = this.guestforgotpassword.bind(this);
       }
       /* It is invoked immediately before mounting occurs */
       componentWillMount() {
           $('html, body').animate({scrollTop: 0});
           AppController.stickyTitles();
       }
       /* Update the state object of the form */
       setValue(field, e) {
           var object = {};
           object[field] = e.target.value;
           this.setState(object);
       }
       /* Authentication checking */
       async guestforgotpassword(e) {
           e.preventDefault();
           var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
           const emailId = Base64.decode(this.props.match.params.email)
           if ((this.state.password == this.state.confirmPassword )&& (this.state.password != '') && (reg.test(emailId))) {
               this.setState({message: ''})
               const register = {
                  email: emailId,
                  newpassword: this.state.password,
               }

               const userData = await ClientApiService.ClientChangePassword(register)
               if (userData.error=="0") {
                this.setState({password: '', confirmPassword: ''})
                jQuery(function ($) {
                    $('#clientPasswordSuccess').modal('show');
                    setTimeout(function () {
                        $('#clientPasswordSuccess').modal('hide');  
                        location.href = '/client-login';
                    }.bind(this), 2000);
                   
                }.bind(this));
                   
               } else {
                   this.setState({message: 'Please Enter Valid Details', color: 'red'})
                
               }
           } else {
               this.setState({message: 'Enter valid Password', color: 'red'})
           }
       }
   
       render() {
   
           const email = Base64.decode(this.props.match.params.email)
   
           return (
            <div className="corporateHousing_Updates bg-grey">
    <GuestHeader/>
    <div className="cover-tophd-ht"></div>
    <div className="container-fluid bg-grey">
        <div className="container">
            <div className="savesearch form-section col-sm-6 col-sm-offset-3 text-center">
                <h2 >
                    Reset Your Password</h2>
                    <form role="form" method="post">
                    
                        {this.state.message ? <span style={{ color: this.state.color, textAlign: 'center' }}>
                        <h4>{this.state.message}</h4></span> : ''}
                            <div className="form-group">
                                <input type="email" id="email" className="form-control" value={email} disabled placeholder="Email" />
                            </div>
                   
                            <div className="form-group">
                                <input type="password"   id="password" className="form-control" value={this.state.password} 
                                onChange={(e) => this.setValue('password', e)} placeholder="New Password" />
                            </div>
                            <div className="form-group">
                                <input type="password" id="confirmPassword" className="form-control" value={this.state.confirmPassword} onChange={(e) => this.setValue('confirmPassword', e)} placeholder="Confirm New Password" />
                            </div>
                            
                        <div className="text-center">
                            <button className="btn company_btn" type="submit" 
                            onClick={(e) => this.guestforgotpassword(e)}>Reset Password</button>

                        </div>
                </form>
            </div>
        </div>
    </div>
    <SuccessModal/>
    <Footer/>

</div>
           );
       }
   }
   export default ClientForGotPassword;