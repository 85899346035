import React, { useEffect, useState } from "react";
import bookitApiService from "../../../services/bookit";
import { useHistory } from "react-router-dom";
import Loader from "../../common/loader";

function YourTeam() {
  const [state, setState] = useState({
    isLoggedIn: null,
    isLoading: true,
    teamData: "",
  });

  const history = useHistory();

  async function isUserLoggedIn() {
    try {
      const sessionResponse = await bookitApiService.checkSession();
      console.log(sessionResponse);
      if (!sessionResponse.loggedIn) {
        return false;
      }
      let clientProfileUId = sessionResponse.ClientProfileCardDetails.message;

      updateState({
        ClientProfileCardDetails: sessionResponse.ClientProfileCardDetails,
        ClientProfileUId:
        sessionResponse.ClientProfileCardDetails.ClientProfileUId,
      });
      return true;
    } catch (err) {
      return false;
    }
  }

  function updateState(updateObj) {
    setState((prevState) => ({ ...prevState, ...updateObj }));
  }

  async function checkUserLoginStatus() {
    if (!(await isUserLoggedIn())) {
      updateState({ isLoggedIn: false });
      return;
    }
    updateState({ isLoggedIn: true });
  }

  useEffect(() => {
    checkUserLoginStatus();
  }, []);

  useEffect(() => {
   
    if (state.isLoggedIn && state.ClientProfileUId) {
      // let requestData = {
      //   Uid: "508fd004-ce13-4566-ab6c-87e3bd465801",
      // };
      let requestData = {
        Uid: state.ClientProfileUId,
      };
     
      bookitApiService
        .GetTeamData(requestData)
        .then((result) => {
          updateState({  teamData: result.message.filter((order) => {
            return order.FullName !== null && order.Type !== "Reservation Rep";
          }), isLoading: false });
          // updateState({ teamData: [], isLoading: false });
        })
        .catch((error) => {
          console.log(error, "error");
        });
    }
  }, [state.isLoggedIn, state.ClientProfileUId]);

  if (state.isLoggedIn === true) {
    return (
      <>
        {state.isLoading ? (
          <Loader />
        ) : (
          <div className="">
            <div className="your-d-team  ">
              <div className="page-title-box">
                <div className="btn-group float-right"></div>
                <h4 className="page-title fw-blod">Your Dedicated Team</h4>
              </div>

              {Array.isArray(state.teamData) && state.teamData.length > 0 ? (
                state.teamData.map((teamMember, index) => (
                  <div className="tails" key={index}>
                    <img
                      src={
                        teamMember.PhotoS3link != null
                          ? teamMember.PhotoS3link
                          : "/images/bookit/profile-dummy.png"
                      }
                      alt=""
                    />
                    <div className="tails-text">
                      <h3>
                        {teamMember.FullName != null
                          ? teamMember.FullName
                          : "N/A"}
                      </h3>
                      <h4>
                        {teamMember.Title != null ? teamMember.Title : "N/A"}
                      </h4>
                      <p>
                        {teamMember.Type === "Sales Rep"
                          ? `${
                              teamMember.FullName != null
                                ? teamMember.FullName
                                : "N/A"
                            } is your dedicated point of contact for all your inquiries and needs. Whether you're interested in exploring new business opportunities or have questions about our range of programs, we're committed to providing support and guidance at every stage of the process.`
                          : `${
                              teamMember.FullName != null
                                ? teamMember.FullName
                                : "N/A"
                            } specializes in fostering exceptional relationships. You can rely on our expertise when encountering challenges or requiring assistance with your experience, as we are committed to promptly resolving issues and ensuring satisfaction.`}
                      </p>
                      <h4>
                        {teamMember.Email != null ? teamMember.Email : "N/A"}
                      </h4>
                      <h4>
                        {teamMember.PhoneNumber != null
                          ? "+ " + teamMember.PhoneNumber.replace('___','')
                          : "N/A"}
                      </h4>
                    </div>
                  </div>
                ))
              ) : (
                // <div class="your-d-team">
                <div className="tails-new">
                  <p>
                    We are still assigning a dedicated SuiteAmerica team to your
                    account. In the meantime, Please reach out to{"   "}
                    <a href="mailto:relocation@suiteamerica.com">
                      relocation@suiteamerica.com
                    </a>
                    {"   "}
                    for any housing needs, Please reach out to{"   "}
                    <a href="mailto:guestserve@suiteamerica.com">
                      guestserve@suiteamerica.com
                    </a>
                    {"   "}
                    for any service requests of your guests.
                  </p>
                </div>
                // </div>
              )}
            </div>
          </div>
        )}
      </>
    );
  } else if (state.isLoggedIn === false) {
    history.push("/SuiteCare");
  }
  return <Loader />;
}

export default YourTeam;