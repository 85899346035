const KeyDetails = props => {    
    return (
        <div className="col-md-12">
        <div className="row">
        <div className="mrg-top-40 bg-grey-box">
        <h3 className="content-mail-title">KEYS</h3>
         {props.keyListData.length != 0 ?
         <table className="table table-condensed table-hover table-striped keylist-table">
                 <thead>
                    <tr>
                        <th>Item</th>
                        <th>Guest Quantity</th>
                        <th>Picked</th>
                        <th width="150">Comments</th>
                    </tr>
               </thead>
                 <tbody>
                        {props.keyListData.length >0 ?(props.keyListData.map((keylist, index) => {
                            return <tr key={index} >
                            <td>{keylist.Identifier}</td>
                            <td>{keylist.Guest}</td>
                            <td>
                                    <form className="form-inline pull-left">
                                        <select className="form-control" name="keylistArray[]" id={'select'+index} value={props.keylistArraySelected[index]!= undefined? props.keylistArraySelected[index]: 0} onChange={()=> props.keylistArray()}>
                                            <option value="0" key={index}>0</option>
                                            {props.keyListQty.map((row, keyListQtyIndex) => {
                                                    return (
                                                        <option value={row} key={keyListQtyIndex}>{row}</option>
                                                    )
                                                })}
                                    </select>
                                </form>
                            </td>
                            <td>{(keylist.Guest==props.keylistArraySelected[index])? <div style={{color:'green'}}>ok</div> : (props.keylistArraySelected[index]==0 || props.keylistArraySelected[index] == undefined)? '' : (props.keylistArraySelected[index] > keylist.Guest ? <div style={{color:'orange'}}>{props.keylistArraySelected[index] - keylist.Guest +' excess keys'}</div>:<div style={{color:'red'}}>{keylist.Guest-props.keylistArraySelected[index]+' missing keys'}</div>)}</td>
                            </tr>
                            }))  :  
                            (<div className="panel-body_loader"> <span style={{fontSize: '20px', width: '100%', margin: 'auto', textAlign: 'center'}}><i className="fa fa-spinner fa-spin"/> please wait ...!</span></div>)}
                    
                     </tbody>
        </table> : <div>There are no Keys for this order</div>}
             </div>
         </div>
         </div>
    );
  };

  export default KeyDetails;