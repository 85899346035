import React from "react";

// Custom CSS to supplement Bootstrap 1.11
const styles = {};

const GuestCheckoutNotice = ({
  guestName,
  checkoutDate,
  checkoutTime,
  iamMoveoutHandler,
  buttonLoader,
}) => (
  <>
    <tr>
      <td style={{ fontSize: "0", lineHeight: "0" }} height="30">
        &nbsp;
      </td>
    </tr>

    <tr>
      <td>
        <table
          border="0"
          cellPadding="0"
          cellSpacing="0"
          style={{ width: "95%", margin: "auto" }}
        >
          <tr>
            <td
              style={{
                fontSize: "25px",
                fontFamily: "'Sora', sans-serif",
                color: "#000000",
                textAlign: "left",
                fontWeight: "700",
              }}
            >
              Good morning, {guestName}
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: "0", lineHeight: "0" }} height="15">
              &nbsp;
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "18px",
                fontFamily: "'Sora', sans-serif",
                color: "#000000",
                textAlign: "left",
                fontWeight: "700",
                paddingRight: "40px",
              }}
            >
              Today is your move out date
            </td>
          </tr>

          <tr>
            <td
              style={{
                fontSize: "18px",
                fontFamily: "'Sora', sans-serif",
                color: "#000000",
                textAlign: "left",
                fontWeight: "300",
              }}
            >
              We hope you enjoyed your stay with us!
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: "0", lineHeight: "0" }} height="15">
              &nbsp;
            </td>
          </tr>
        </table>
      </td>
    </tr>

    <tr>
      <td style={{ fontSize: "0", lineHeight: "0" }} height="20">
        &nbsp;
      </td>
    </tr>

    <tr>
      <td
        style={{
          fontSize: "18px",
          fontFamily: "'Sora', sans-serif",
          color: "#000000",
          textAlign: "left",
          fontWeight: "500",
        }}
      >
        <table
          style={{ width: "95%", margin: "auto" }}
          border="0"
          cellPadding="0"
          cellSpacing="0"
        >
          <tr>
            <td
              style={{
                fontSize: "18px",
                fontFamily: "'Sora', sans-serif",
                color: "#000000",
                textAlign: "left",
                fontWeight: "700",
              }}
            >
              Your check out time is:
            </td>
            <td style={{ width: "165px" }}></td>
          </tr>
          <tr>
            <td style={{ fontSize: "0", lineHeight: "0" }} height="10">
              &nbsp;
            </td>
          </tr>
          <tr>
            <th
              style={{
                background: "#000000",
                color: "#ffffff",
                fontSize: "18px",
                fontFamily: "'Sora', sans-serif",
                padding: "10px",
                textAlign: "center",
                fontWeight: "600",
              }}
            >
              CHECK OUT
            </th>
          </tr>
          <tr>
            <td
              style={{
                background: "#ececec",
                color: "#000000",
                fontSize: "20px",
                fontFamily: "'Sora', sans-serif",
                padding: "10px",
                textAlign: "center",
                fontWeight: "800",
              }}
            >
              {checkoutDate}
              <span
                style={{
                  background: "#ececec",
                  color: "#000000",
                  fontSize: "18px",
                  fontFamily: "'Sora', sans-serif",
                  padding: "10px",
                  textAlign: "center",
                  fontWeight: "700",
                  display: "block",
                }}
              >
                {checkoutTime}
              </span>
            </td>
          </tr>
        </table>
      </td>
    </tr>

    <tr>
      <td>
        <table
          style={{ width: "95%", margin: "auto" }}
          border="0"
          cellPadding="0"
          cellSpacing="0"
        >
          <tr>
            <td style={{ fontSize: "0", lineHeight: "0" }} height="30">
              &nbsp;
            </td>
          </tr>

          <tr>
            <td
              style={{
                fontSize: "18px",
                fontFamily: "'Sora', sans-serif",
                color: "#000000",
                textAlign: "left",
                fontWeight: "700",
              }}
            >
              Where do I leave the keys?
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: "0", lineHeight: "0" }} height="30">
              &nbsp;
            </td>
          </tr>
          <tr>
            <td
              style={{
                color: "#000000",
                fontSize: "17px",
                fontFamily: "'Sora', sans-serif",
                fontWeight: "500",
              }}
            >
              Leave any keys, remotes, fobs, and parking items on the kitchen
              counter.
            </td>
          </tr>
          <tr>
            <td
              style={{
                color: "#000000",
                fontSize: "17px",
                fontFamily: "'Sora', sans-serif",
                fontWeight: "300",
              }}
            >
              All access items to the building should be surrendered upon
              departure. If anything is missing, you will be charged replacement
              fees.
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: "0", lineHeight: "0" }} height="30">
              &nbsp;
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "18px",
                fontFamily: "'Sora', sans-serif",
                color: "#000000",
                textAlign: "left",
                fontWeight: "700",
              }}
            >
              Please let us know that you’ve moved out by confirming below
            </td>
          </tr>

          <tr>
            <td style={{ fontSize: "0", lineHeight: "0" }} height="40">
              &nbsp;
            </td>
          </tr>

          <tr>
            <td>
              <button
                style={{
                  color: "#ffffff",
                  background: "#00bf63",
                  fontSize: "15px",
                  fontFamily: "'Sora', sans-serif",
                  fontWeight: "600",
                  border: "0",
                  padding: "12px 30px",
                  borderRadius: "25px",
                  boxShadow: "0px 0px 10px #c9c9c9",
                  textDecoration: "none",
                }}
                disabled={buttonLoader}
                onClick={() => iamMoveoutHandler()}
              >
                I'm Moved Out
              </button>
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: "0", lineHeight: "0" }} height="30">
              &nbsp;
            </td>
          </tr>

          <tr>
            <td
              style={{
                fontSize: "18px",
                fontFamily: "'Sora', sans-serif",
                color: "#000000",
                textAlign: "left",
                fontWeight: "700",
              }}
            >
              Questions?
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: "0", lineHeight: "0" }} height="5">
              &nbsp;
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "18px",
                fontFamily: "'Sora', sans-serif",
                color: "#000000",
                textAlign: "left",
                fontWeight: "300",
              }}
            >
              <a
                href="mailto:someone@example.com"
                style={{
                  fontSize: "18px",
                  fontFamily: "'Sora', sans-serif",
                  color: "#077fd5",
                  textAlign: "left",
                  fontWeight: "400",
                }}
              >
                Send us an email
              </a>{" "}
              or give us a call 800.367.9501
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: "0", lineHeight: "0" }} height="30">
              &nbsp;
            </td>
          </tr>
        </table>
      </td>
    </tr>

    <tr>
      <td style={{ fontSize: "0", lineHeight: "0" }} height="40">
        &nbsp;
      </td>
    </tr>
  </>
);

export default GuestCheckoutNotice;
