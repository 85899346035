/* Importing the node modules, child components, services and controllers used 
   inside Header component */
   import GuestHeader from './guestHeader';
   import EmployeeHeader from './employeeHeader'
   import FivestarHeader from './fivestarHeader';
   import MainController from '../controller/mainController';
   /* Header Component initialization */
   class Header extends React.Component {
       /* Initializing objects of its Header class */
       constructor(props) {
           super(props)
           /* Header Component State variables Initialization */
           this.state = {
               user:0,
               urls:[    
                   "/drivers-schedule",
                   "/fivestar-service",
                   "/vehicle-checklist",
                   "/check-list",
                   "/email",
                   "/calender",
                   "/diver-schedulemap",
                   '/service-request-form',
                   '/key-list'    
               ],
           }
           this.logout = this.logout.bind(this);
           this.openPortal = this.openPortal.bind(this);
           this.closePortal = this.closePortal.bind(this);   
       }
        /* It is invoked immediately after a component is mounted */
       componentDidMount() {
           MainController.removeClassOnNavCollapse();     
       }   
        /* To clear the local storage data and logout from the fivestar application */
       logout() {
           localStorage.clear();
           location.href = '/'
       }
        /* To open the portal */    
       openPortal(){
           this.setState({user:1})
       }
        /* To close the portal */    
       closePortal(){
           this.setState({user:1})
       }    
        /* It is invoked to return html content */      
       render() {   
          const userData = localStorage.getItem('userData')
          const roleId = userData == null ? 0:JSON.parse(userData).role_id
          const loc=this.state.urls.indexOf(location.pathname)  
          const DSId= location.pathname.split('/')
          var index = ''
          if(DSId.length == 5){
            index =DSId[4]
          }else if(DSId.length ==6){
            index = DSId[5]
          }else{
            index = DSId[6]
          }
          var companyName =''
if(DSId.length == 5){
    companyName = DSId[3]
}else if(DSId.length ==6){
    companyName =DSId[4]
}else{
    companyName =DSId[5]
}
           
         
           return (   
               <div>
                  {loc !=-1  ?  <FivestarHeader/> : null}
                  {
                   loc ==-1 && location.pathname!='/destination-service/itinerary/property/mapview/' +companyName + '/' + index && location.pathname!='/fivestar' && location.pathname!='/destination-service/itinerary/'+companyName + '/' + index && location.pathname!='/destination-service/itinerary/property/' + companyName +'/' + index  && (userData == null || roleId == 2 || roleId == 1 || roleId == 3)
                       ? <GuestHeader logout={this.logout} openPortal={this.openPortal} closePortal={this.closePortal}/>
                       : null
                   }
                    {loc ==-1 && location.pathname!='/destination-service/itinerary/property/mapview/' +companyName + '/' + index && location.pathname!='/fivestar' && location.pathname!='/destination-service/itinerary/'+companyName + '/' + index  && (userData != null && (roleId == 4 || roleId == 5))
                       ? <EmployeeHeader logout={this.logout}/>
                       : null} 
               </div>
           );
       }
   }
   export default Header;