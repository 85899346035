/* Importing the node modules, child components, services and controllers used 
   inside TravelBookingLogin component */
import TravelBookingApiService from '../../../services/travelBookingApiService';
import moment from 'moment';
/* TravelBookingLogin Component initialization */
class TravelBookingLogin extends React.Component {
    /* Initializing objects of its TravelBookingLogin class */
    constructor(props) {
        super(props)
        this.state = {
            emailId: '',
            password: '',
            errorMessage: '',
            color: ''
        }
        this.setValue = this.setValue.bind(this);
        this.authentication = this.authentication.bind(this);
        // this.forgotPassword = this.forgotPassword.bind(this);
    }

    async componentDidMount() {

        let expireTime = Base64.decode(localStorage.getItem('expireTime'));
        let currentTime = moment().format('DD/MM/YYYY HH:mm:ss')
        let expireDuration = moment(currentTime).diff(moment(expireTime), 'minutes')
        if (expireDuration > 30) {
            await localStorage.clear()
            await location.reload();
            location.href = "/starbucks-business-travel-suites";
        } else {
            localStorage.setItem('expireTime', Base64.encode(moment().format("DD/MM/YYYY HH:mm:ss")))
        }

    }
    /* To update the state variables */
    setValue(field, e) {
        var object = {};
        object[field] = e.target.value;
        this.setState(object);
    }
    /* Login Authentication checking */
    async authentication(e) {
        e.preventDefault();
        var url = localStorage.getItem('starbucksurl')
        localStorage.clear()
        localStorage.setItem('starbucksurl', url)
        const data = {
            email: this.state.emailId.trim().toLowerCase(),
            password: this.state.password.trim(),
            logintype:"STARBUCKS"
        }
        const response = await TravelBookingApiService.adminLogin(data)
        if (response.error == '0') {
            localStorage.setItem('starbuckstravel-email', Base64.encode(data.email));
            localStorage.setItem('starbuckstravel-password', Base64.encode(this.state.password));
            localStorage.setItem('expireTime', Base64.encode(moment().format("DD/MM/YYYY HH:mm:ss")));
            localStorage.setItem('starbuckstravel-isLogin', 'true');
            $('#travel-booking-login').modal('hide');
            await this.setState({ emailId: '', password: '' });
            location.href = '/availability-calendar';
        } else {
            this.setState({ errorMessage: response.message, color: 'red' });
        }
    }
    /* Forgot password checking */
    //    forgotPassword(){
    //         console.log('forgot')
    //    }
    /* It is invoked to return html content */
    render() {
        return (
            <div className="modal fade savesearch" id="travel-booking-login" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content rm-border-radius">
                        <div className="modal-header">
                            <button type="button" className="btn close" data-dismiss="modal" aria-label="">
                                <i className="bi bi-x"></i>
                            </button>
                        </div>
                        <div className="text-center" style={{ color: this.state.color }}>{this.state.errorMessage}</div>
                        <div className="modal-body">
                            <h2>Login</h2>
                            <form role="form" onSubmit={(e) => this.authentication(e)}>
                                <div className="mrg-btm-30" >
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Email" value={this.state.emailId}
                                                onChange={(e) => this.setValue('emailId', e)} />
                                        </div>
                                        <div className="form-group">
                                            <input type="password" className="form-control" placeholder="Password" value={this.state.password}
                                                onChange={(e) => this.setValue('password', e)} />
                                        </div>
                                        <button className="btn location_btn" type="submit" >Login</button>
                                        {/* <a onClick={this.forgotPassword}>Forgot your password?</a> */}
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
export default TravelBookingLogin;