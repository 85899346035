import {Link} from 'react-router-dom';
import ImagesModalPopup from './imagesModalPopup';
  class CommunityList extends React.Component {
       constructor(props) {
           super(props);
           this.state ={
            limit:9,
        }
        this.loadMoreCommunities = this.loadMoreCommunities.bind(this);
        this.viewPlaces = this.viewPlaces.bind(this);
         }
         loadMoreCommunities(){
console.log('p')
         }
         viewPlaces(){
             console.log('o')
         }
       /* It is invoked to return html content */
       render() {   
        let community=this.props.communities!=undefined && this.props.communities.length>0?this.props.communities:[];
           return (
               <div> 
            <div className="clearfix"></div>
            <div className="container travel-sec" >
                    <div className="text-center col-md-8 col-md-offset-2 community_header mrg-btm-50" id="our-communities">
                        <h2>Our Communities</h2>
                        <p>Preferred Properties, Selected especially for YOU!</p>
                    </div>                    
                    <div className="clearfix"></div>
                    {this.props.communitesLoading ? <Spinner/>:
                    <div>
                    {community.map((row,index) =>{
                        var commImages = []
                        if(row.compleximages != null && row.interiorimages != null &&  row.interiorimages != ""){
                            commImages = (row.compleximages+','+row.interiorimages).split(',');
                        }else if(row.compleximages != null && (row.interiorimages == null || row.interiorimages == "")){
                            commImages = (row.compleximages).split(',');
                        }else if(row.compleximages == null && row.interiorimages != null){
                             commImages = (row.interiorimages).split(',');
                        }else{
                            commImages = [];
                        }
                        let defaultObject;
                            if(row.STU>0){
                            defaultObject = {adminDailyRates: row.CostSTU, status: false, community_id: row.community_id, BookedType: 'STU'}
                            }else if(row.Avail1X>0){
                            defaultObject = {adminDailyRates: row.Cost1x, status: false, community_id: row.community_id, BookedType: '1X'}
                            }else if(row.Avail2X>0){
                            defaultObject = {adminDailyRates: row.Cost2x, status: false, community_id: row.community_id, BookedType: '2X'}
                            }else if(row.Avail3X>0){
                            defaultObject = {adminDailyRates: row.Cost3x, status: false, community_id: row.community_id, BookedType: '3X'}
                            }else{
                            defaultObject = {adminDailyRates: row.CostSTU, status: false, community_id: row.community_id, BookedType: 'STU'}
                            }
                            let STU= _.find(this.props.communityRoomSelected, function(o) { return (o.community_id == row.community_id && o.BookedType == 'STU');  });
                        const oneBed= _.find(this.props.communityRoomSelected, function(o) { return (o.community_id == row.community_id && o.BookedType == '1X');  });
                        const twoBed= _.find(this.props.communityRoomSelected, function(o) { return (o.community_id == row.community_id && o.BookedType == '2X');  });
                        const threeBed= _.find(this.props.communityRoomSelected, function(o) { return (o.community_id == row.community_id && o.BookedType == '3X');  });
                        let selectedBed = _.find(this.props.communityRoomSelected, function(o) { return (o.community_id == row.community_id);  });
                        selectedBed  = selectedBed== undefined?  defaultObject : selectedBed
                        return(<div className="col-md-4 col-sm-6" key={index} id="communitysize">
                        <div className="community-item">
                            <div className="img-div">
                            <img src={row.image_name} alt="Community Images" />
                            <div className="img-hover-dtls">
                            <label className="community-photos pull-left" htmlFor={"op"+index} >
                                    <ul onClick={() => this.props.getPhotos(commImages, index)}>
                                    {commImages.map((image, comIndex) =>{
                                        if(comIndex < 2){
                                            return <li key={comIndex}><img src={image} alt="" /></li>     
                                        }                                        
                                    })}
                                    </ul>
                                    <span>{commImages.length} Photos</span>
                                </label>
                                {(row.walkScore!= '' && row.walkScore!=undefined && row.walkScore!=null) ? <div className="walkscore pull-right">
                                <div className="arrow_box" onClick={() => this.viewPlaces(row)}><a href="#" className="flat_num" data-toggle="modal" data-target="#viewPlaces"
                          style={{
                          'cursor': 'pointer'
                        }} >{row.walkScore ==  '' ? 0 : row.walkScore} </a></div>
                                <span>Walk Score</span>
                            </div>:''}
                            </div>
                            </div>
                            <div className="community-item-dtls">
                            <div className="community-head"><h5>{row.community_name}</h5></div>
                            <p>{row.address}</p>
                            <span className="area"><strong>{row.city_name},</strong><br/>{row.state_name}</span>
                            <span className="distance"><img src="https://s3-us-west-1.amazonaws.com/suiteam-website/Travel-Booking/SuiteAmerica-Travel-Booking-Map.png" alt="" /><strong>{row.distance == '' ? 0 : row.distance}</strong> Miles away</span>
                            </div>
                            <div className="clearfix"></div>
                            <ul className="bedroom_list list-inline">
                                {row.STU ==0?'' :<li className="active" onClick={row.STU<=0? '' : () => this.props.rateChange(row.CostSTU,row, (selectedBed.BookedType=='STU'? "" : "STU"))}><img src={s3BucketUrl+"landing-images/studiobed"+(selectedBed.BookedType=="STU"? "-red" : "") +".png"} /> <p>Studio </p><span className="badge studio">{row.STU}</span></li>}
                                {row.Avail1X ==0?'' :<li onClick={row.Avail1X<=0? '' : () => this.props.rateChange(row.Cost1x,row, "1X")}><img src={s3BucketUrl+"landing-images/singlebedroom"+(selectedBed.BookedType=='1X' ? "-red" : "") +".png"}/> <p>1 Bedroom</p><span className="badge">{row.Avail1X}</span></li>}
                                {row.Avail2X ==0?'' :<li onClick={row.Avail2X<=0? '' : () => this.props.rateChange(row.Cost2x,row,"2X")}><img src={s3BucketUrl+"landing-images/doublebedroom"+(selectedBed.BookedType=='2X'? "-red" : "") +".png"}/> <p>2 Bedroom</p><span className="badge">{row.Avail2X}</span></li>}
                                {row.Avail3X ==0?'' :<li onClick={row.Avail3X<=0? '' : () => this.props.rateChange(row.Cost3x,row, "3X")}><img src={s3BucketUrl+"landing-images/triplebedroom"+(selectedBed.BookedType=='3X'? "-red" : "") +".png"}/> <p>3 Bedroom</p><span className="badge">{row.Avail3X}</span></li>}
                             </ul>
                            <div className="community-footer">
                            <a onClick={() => this.props.communityMoreInfo(row,0)}>More Info</a>
                            <Link to="#"  className="btn btn-bordered pull-right" onClick={() => this.props.pageChange(row,2)}><img src="https://s3-us-west-1.amazonaws.com/suiteam-website/Travel-Booking/SuiteAmerica-Travel-Booking-Booknow-Image.png" alt="" /> <span> Book Now <span>$ {selectedBed.adminDailyRates} / Day </span></span></Link>
                            <div className="clearfix"></div>
                            </div>
                        </div>
                    </div>)
                    })}
                     <div className="clearfix"></div>
                    </div>}                                    
                </div>
                {this.props.communityImages.length>0? 
                <ImagesModalPopup {...this.props} galleryClose={this.props.galleryClose}/>:null}
                   </div>
            
           );
       }
   }
   export default CommunityList;