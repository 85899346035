/* Importing the node modules, child components, services and controllers used 
   inside LockheedLocations component */ 
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import LockheedHeader from "../../common/lockheedHeader";
   import Location from "../location/locations";
   import FAQ from "./common/faq";
   import Footer from '../../common/footer';
   /* LockheedLocations Component initialization */
   class LockheedLocations extends React.Component {
       /* Initializing objects of its LockheedLocations class */
       constructor(props) {
           super(props)
           this.state={
               companyname:'suiteamerica',
               cityData: {
                "city" : "Arlington",
                "state" : "VA",
                "country" : "US",
                "countryId" : "2",
                "state_id" : '62',
                "city_id" : '53263',
                "address" : "VIRGINIA",
                "amenities_id" : ''
               },
               color: '#555',
               refineSearch: true
           }
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
            MainController.getInitialInformation();
        }
        componentDidMount() {
            localStorage.setItem('savedSearch', JSON.stringify(this.state.cityData));
        }
       /* It is invoked to return html content */
       render() {
   
           return (
            <div className="corporateHousing_Updates">
                <header className="codeconveyHeader cc_image_text_main corporate-h">
                    <img className="img-responsive" src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/lockheed/LANDING-PG_iStock-477921526.jpg" width="100%" alt=""  />
                    <div className="wow fadeInUp img-on-text top50 width600 text-center img-on-text-center img-on-text-center-w" data-wow-delay="0.5s">
                        <h1 className="lockheed-color">Locations</h1>
                        <p className="lockheed-color">Furnished and unfurnished temporary
                            corporate housing apartments throughout
                            the continental United States.
                        </p>
                    </div>
                </header>
                <LockheedHeader />
                <Location companyName={this.state.companyname} props={this.state.cityData} color={this.state.color} refineSearch={this.state.refineSearch}/>
                <div className="container-fluid bg-grey">
                    <div className="container">
                        <FAQ />
                    </div>
                </div>
                <SubFooter />
            <Footer/>
            </div>

   
           );
       }
   }
   export default LockheedLocations;