import crypto from "crypto";
const secretKey = "YourSecretKeyjhgjhgkjgkjhgkjhgkj";

// Encryption
function encrypt(text) {
  const algorithm = "aes-256-cbc";
  const iv = crypto.randomBytes(16); // Generate a random IV
  const cipher = crypto.createCipheriv(algorithm, Buffer.from(secretKey), iv);

  let encrypted = cipher.update(text, "utf8", "hex");
  encrypted += cipher.final("hex");

  return iv.toString("hex") + encrypted; // Include IV in the result
}

// Decryption
function decrypt(encrypted) {
  try {
    const algorithm = "aes-256-cbc";
    const encryptedText = encrypted.slice(32); // Remove IV (16 bytes in hex is 32 characters)
    const iv = Buffer.from(encrypted.slice(0, 32), "hex"); // Extract IV
    const decipher = crypto.createDecipheriv(
      algorithm,
      Buffer.from(secretKey),
      iv
    );

    let decrypted = decipher.update(encryptedText, "hex", "utf8");
    decrypted += decipher.final("utf8");

    return decrypted;
  } catch (err) {
    return "error";
  }
}

export { encrypt, decrypt };
