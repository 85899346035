/* Importing the node modules, child components, services and controllers used 
   inside IntranetCustomRequestfive component */   
   
   /* IntranetCustomRequestfive Component initialization */
   class IntranetCustomRequestfive extends React.Component{
       /* Initializing objects of its IntranetCustomRequestfive class */
       constructor(props) {
           super(props)
         }
   /* It is invoked to return html content */      
   render(){
       return(
<div className="inet_custom_last">
<div className="container">
   <h1 className="wow animated zoomIn">Thank you, your request has been placed.</h1>
   <p className="wow animated fadeInUp">We’ve sent you an email confirmation.</p>
</div>
</div>
   
       );
   }
   }
   export default IntranetCustomRequestfive;