import Buttons from "./buttons";

const BungalowHomes = props => {
  return (
    <div>
<div className="bungalowpage_banner">
				<div className="bungalowpageimg">
					<img className="width100per" src={props.homes.BannerImage} />
				</div>
				<div className="banner_text">
					<h2>bungalow</h2>
					<p>{props.homes.heading}</p>
				</div>
			</div>
  	<div className="container">
				<div className="bungalowbannerbottom">
					<p> {props.homes.Paragraph}</p>
					{props.homes.data.map((row,index)=>{
            return(
              <div className={index % 2 == 0 ?"topcontent":"topcontent flex-r-r"} key={index}>
              <div className="col-md-8 col-sm-12 paddongzero">
                  <img className="img-responsive" src={row.Image} />
              </div>
              <div className="col-md-4 col-sm-12  itemtext paddongzero">
                <div className="item">
                  <h1> {row.Name} </h1>
                  <p> {row.Paragraph} </p>
                </div>
              </div>
            </div>
            )
          })}
          <Buttons Button={'VIEW LISTINGS'}/>
				</div>
			</div>
      <div className="bungalownameheaind">
        <div className="container">
            <h1>bungalow</h1>
          <h2>Giving employees a great community to come home to</h2>
        </div>
      </div>
      </div>
  );
};
export default BungalowHomes;
