'use strict'
import serviceBase from './serviceBase'

const destinationService = {
    
    getDetails:  (data) =>  serviceBase.post('/destination/getDetails',data),
    getUserEmail:  (data) =>  serviceBase.post('/destination/getUserEmail',data),
    getNotes:  (data) =>  serviceBase.post('/destination/getNotes',data),
    headerDetails:  (data) =>  serviceBase.post('/destination/headerDetails',data),
    updateRating: (data) =>  serviceBase.post('/destination/update', data),
    getTourDetails: (data) =>  serviceBase.post('/destination/orderno/gettourdetails', data)
    
}
export default destinationService