
import ServerMetaTags from '../../../server/metaTags';
import MetaTags from '../../common/metaTags';
class TransHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pathsdata: [
        {
          key: "corporatehousing",
          path: "/corporatehousing",
          component: "Hospitality",
        },
        {
          key: "governmentHousing",
          path: "/government-housing",
          component: "Hospitality",
        },
        {
          key: "internHousing",
          path: "/internHousing",
          component: "Hospitality",
        },
        {
          key: "businesstravel",
          path: "/business-travel",
          component: "Hospitality",
        },
        {
          key: "insurancepage",
          path: "/insurance-landing",
          component: "Hospitality",
        },
        {
          key: "technology",
          path: "/technology",
          component: "technology",
        },
        {
          key: "guestExperience",
          path: "/guestExperience",
          component: "Staywithus",
        },
        {
          key: "spotlightCities",
          path: "/spotlightCities",
          component: "Staywithus",
        },
        {
          key: "propertySearchPage",
          path: "/locations",
          component: "Staywithus",
        },
      ],
    };
  }

  async componentDidMount() {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
    let path = window.location.pathname;
    let obj = this.state.pathsdata.find((o) => o.path == path);
    if (obj != undefined) {
      $("#" + obj.key).addClass("active");
      $("#" + obj.component).addClass("active");
    }
  }
  handleClick() {
    $("#sideQuote-header").addClass("active");
    $(".overlay").addClass("active");
    $(".collapse.in").toggleClass("in");
    $("a[aria-expanded=true]").attr("aria-expanded", "false");
  }
  closed() {
    $("#sideQuote-header").removeClass("active");
    $(".overlay").removeClass("active");
  }

  render() {
   // console.log("===", window.location.pathname)
    const metaData = ServerMetaTags.urlString.getMetaData(window.location.pathname);
    return (
      <div>
        <MetaTags 
             metaTitle = {metaData.title}
             metaDesription = {metaData.description}
             metaUrl = {metaData.canonical} />
        <header id="header" className="fixed-top ">
          <div className="container">
            <nav className="navbar ">
              <div className="d-flex align-items-center sm-flex-wrap">
                <div className="navbar-header">
                  <a className="navbar-brand" href="/homepage">
                    <img
                      className="mainLogo"
                      src="/assets/newUI-images/logo/SA-full-logo-W.svg"
                      alt=""
                    />
                    <img
                      className="logoIcon"
                      src="/assets/newUI-images/logo/SA-logo-icon-W.svg"
                      alt=""
                    />
                  </a>
                  <button
                    type="button"
                    className="navbar-toggle collapsed"
                    data-toggle="collapse"
                    data-target="#navbar"
                    aria-expanded="false"
                    aria-controls="navbar"
                  >
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </button>
                </div>
                <div id="navbar" className="navbar-collapse collapse">
                  <ul className="nav navbar-nav m-auto">
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link text-white  fs-6"
                        id="Hospitality"
                        href="javascript:void(0)"
                      >
                        Hospitality <i className="bi bi-caret-down-fill"></i>{" "}
                      </a>
                      <div className="dropdownView">
                        <div>
                          <a
                            href="/corporatehousing"
                            id="corporatehousing"
                            className="dropdown-item "
                          >
                            {" "}
                            Corporate Housing{" "}
                          </a>
                          <a
                            href="/government-housing"
                            id="governmentHousing"
                            className="dropdown-item"
                          >
                            {" "}
                            Government Housing{" "}
                          </a>
                          <a
                            href="/internHousing"
                            id="internHousing"
                            className="dropdown-item"
                          >
                            {" "}
                            Intern & Group Housing{" "}
                          </a>
                          <a
                            href="/business-travel"
                            id="businesstravel"
                            className="dropdown-item"
                          >
                            {" "}
                            Business Travel{" "}
                          </a>
                          <a
                            href="/insurance-landing"
                            id="insurancepage"
                            className="dropdown-item"
                          >
                            {" "}
                            Insurance Housing{" "}
                          </a>
                        </div>
                      </div>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link text-white  fs-6"
                        id="technology"
                        href="/technology"
                      >
                        Technology
                      </a>
                    </li>

                    <li className="nav-item dropdown">
                      <a
                        className="nav-link text-white  fs-6"
                        id="Staywithus"
                        href="javascript:void(0)"
                      >
                        Stay with us <i className="bi bi-caret-down-fill"></i>{" "}
                      </a>
                      <div className="dropdownView">
                        <div>
                          <a
                            href="/guestExperience"
                            id="guestExperience"
                            className="dropdown-item "
                          >
                            {" "}
                            Guest Experience{" "}
                          </a>
                          <a
                            href="/spotlightCities"
                            id="spotlightCities"
                            className="dropdown-item"
                          >
                            {" "}
                            Spotlight cities{" "}
                          </a>
                          <a
                            href="/locations"
                            id="propertySearchPage"
                            className="dropdown-item"
                          >
                            {" "}
                            Locations{" "}
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>

                  <form className="d-flex">
                    <button className="btn " type="button">
                      <a href="/guest-login">Guest Login</a>
                    </button>
                    <button
                      className="btn ms-3"
                      onClick={this.handleClick}
                      type="button"
                    >
                      <i className="bi bi-list"></i>
                    </button>
                  </form>
                </div>
              </div>
            </nav>
          </div>
        </header>
        <section id="sideQuote-header">
          <div className="quoteHeading">
            <div id="dismiss">
              <a onClick={this.closed}>
                <i className="bi bi-x"></i>
              </a>
            </div>
            <ul>
              <li>
                {" "}
                <a href="/company-info">About Us </a>
              </li>
              <li>
                {" "}
                <a href="/leadership-team">Leadership </a>
              </li>
              <li>
                {" "}
                <a href="/career-opportunities">Career </a>
              </li>
              <li>
                {" "}
                <a href="/privacy-policy">Privacy Policy </a>
              </li>
              <li>
                {" "}
                <a href="/contactUs">Contact Us </a>
              </li>
            </ul>
          </div>
          <div className="sideQuote_footer">
            <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/logo/full%20logo.svg" />
            <ul>
              <li>
                {" "}
                <i className="bi bi-telephone-fill pe-2"></i>&nbsp; +1
                800.367.9501{" "}
              </li>
              <li>
                <i className="bi bi-envelope pe-2"></i>&nbsp;
                support@suiteamerica.com{" "}
              </li>
            </ul>
          </div>
        </section>
      </div>
    );
  }
}
export default TransHeader;
