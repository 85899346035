/* Importing the node modules, child components, services and controllers used 
   inside GovernmentHousing component */
import MainController from "../../controller/mainController";
import SubFooter from "../../common/subFooter";
import GuestHeader from '../../common/guestHeader';
import Footer from '../../common/footer';
import LocationSlider from '../../common/locationSliderData';
import ReserveYourStay from '../../common/reserveYourStay.js';
import MetaTags from '../../common/metaTags';
import ServerMetaTags from '../../../server/metaTags';
import NavigationFooter from '../../common/navigationFooter';
import NavigationData from '../../common/navigation.json';

/* GovernmentHousing Component initialization */
class GovernmentHousing extends React.Component {
    /* Initializing objects of its GovernmentHousing class */
    constructor(props) {
        super(props);
        this.state = {
            navigationData: NavigationData.governmentHousing,
            all: [
                "Complete furnishings", "Separate living, dining, & sleeping areas", "Full kitchens complete with housewares",
                "2 Televisions, DVD, & basic cable", "Washer & dryer in most apartments", "High-speed internet",
                "Utilities", "24/7 customer support"],
            most: [
                " Fitness facilities", "Business Centers", "Swimming Pools", "Easy Access to Highways",
                "Near Public Transportation", "Controlled Access", "Parking available on site", "Pet-Friendly accommodations"
            ]
        }
    }
    /* It is invoked immediately before a component is mounted */  
    componentWillMount() {
        MainController.getProfilePageInformation(10, 3, 3);
    }
    /* It is invoked to return html content */
    render() {
        const metaData = ServerMetaTags.urlString.getMetaData(this.props.match.url);
        const all = this.state.all;
        const most = this.state.most;
        return (
            <div>
                <MetaTags 
                metaTitle = {metaData.title}
                metaDesription = {metaData.description}
                metaUrl = {metaData.canonical} />
                <GuestHeader/>
                <header className="codeconveyHeader cc_image_text_main corporate-h video-wrapper">
                    <img className="img-responsive relocation-corporate-housing" src={s3BucketUrl_WebsiteImages+"Government-Government-Housing-Banner.jpg"} width="100%" alt=""  />
                    <div className="wow fadeInUp img-on-text top35 width600 text-center left50 img-on-text-center" data-wow-delay="0.5s">
                        <h1>TEMPORARY HOUSING <br/><i>SOLUTIONS</i></h1>
                        <p>SuiteAmerica provides low-cost housing solutions that assist 
                            government contractors in the field with fully equipped and 
                            customized furnished apartments nationwide, conveniently located 
                            near hundreds of city centers, government offices, and military bases. 
                            Our apartments are available through all major Global Distribution 
                            Systems as well as GSA Schedule 48. 
                        </p>
                    </div>
                </header>
                <ReserveYourStay />
                <div className="who_weare services_ch">
                    <div className="cc_image_text_main">
                        <img className="img-responsive left-mo" src="https://s3-us-west-1.amazonaws.com/suiteam-website/images-website/Corporation-Corporate-Housing-Personal-Transition-Services.jpg" alt="" />
                    </div>
                </div>
                <div className="new_txt_sec">
                    <div className="new_txt_content container wow fadeInUp animated">
                        <div className="col-md-8 col-md-offset-2">
                            <h2>ULTIMATE CONVENIENCE</h2>
                            <p>Our superior accommodations fall within TDY and most PCS per 
                                diems. You have access to hundreds of communities with 
                                thousands of apartments nationwide that are conveniently 
                                located to city centers, military installations and government
                                offices. Many of our locations are accessible to Metro, with Room
                                and Ride packages available.
                            </p>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>
                <LocationSlider {...this.props}/>
                <div className="caption_text services_cs">
                    <div className="container">
                        <div className="who_weare_text22 wow fadeInUp" data-wow-delay="0.5s">
                            <h1 className="font-bold">COMFORT ISN’T</h1>
                            <h2><span>it’s a</span></h2>
                            <h1>AN OPTION</h1>
                            <h2><span>necessity</span></h2>
                        </div>
                    </div>
                </div>
                <div className="container-fluid bg-white">
                    <div className="container">
                        <p className="text-center review_rqst_button accomidation_acco_btn color-red collapsed" role="button" data-toggle="collapse" href="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                        Accommodations
                        </p>
                        <div className="collapse row accomidation_acco" id="collapseExample">
                            <div className="company_section wow fadeInUp">
                            <div className="col-md-6">
                                <div>
                                    <p>ALL APARTMENTS INCLUDE</p>
                                    <ul>
                                            {all.map((row, i) => {
                                                return (<li key={i}>{row}</li>)
                                            })}
                                        </ul>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <p>MOST COMMUNITIES FEATURE</p>
                                    <ul>
                                        {most.map((row, i) => {
                                                return (<li key={i}>{row}</li>)
                                            })}
                                        </ul>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 company_section">
                            <img src={s3BucketUrl_WebsiteImages+"Government-Government-Housing-Hassele-Free-Billing.jpg"} className="img-responsive" alt="" /> 
                            <div className="accom_text wow fadeInUp">
                                <h2>Hassle-Free Billing</h2>
                                <p>With SuiteAmerica, you will receive consistent billing 
                                    accuracy with multiple payment options. We accept 
                                    Government-issued credit cards, along with Global 
                                    Distribution System (GDS) pre-approved rates, and 
                                    there is no service tax for Government extended stay travellers.
                                </p>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 company_section">
                            <img src={s3BucketUrl_WebsiteImages+"Government-Government-Housing-Top-Flight-Security.jpg"} className="img-responsive" alt="" /> 
                            <div className="accom_text wow fadeInUp">
                                <h2>Top-Flight Security</h2>
                                <p>Our controlled-access apartments feature 
                                    top-flight physical security and life safety systems. 
                                    Your information is safe with our secure web portal, 
                                    developed with user data protection in mind.
                                </p>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 company_section">
                            <img src="/assets/newUI-images/Website-Images/Corporation-Corporate-Housing-Advanced-Interactive-Tools.jpg" className="img-responsive" alt="" /> 
                            <div className="accom_text wow fadeInUp">
                                <h2>Advanced Interactive Tools</h2>
                                <p>We’ve created a suite of custom, digital tools using the latest technologies to make our time with you more efficient and valuable. From area information, to service requests to information about your community or apartment, you have it all right at your fingertips.
                                </p>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="quote-section bg-grey quote_red">
                    <div className="container">
                        <div className="wow fadeInUp" data-wow-delay="0.5s">
                            <h6 className="quote-s"> <img src={s3BucketUrl_WebsiteImages+"Corporation-Corporate-Housing-Quote.png"} alt=""/></h6>
                            <div className="col-md-10 col-md-offset-1">
                            <h5 className="quote-text">I wanted to thank you for all your help, the apartment is great and has made this 
                            difficult transition easier on me and my family. Since we are a military family we are 
                            used to moving but we were evacuated from Egypt and did not have time to take a 
                            lot. My 8 yr. old daughter was so happy to be in "her own room". I know you help 
                            many people, but I wanted to say thanks from all of us...
                            </h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="clearfix"></div>
                <NavigationFooter {...this.state} />        
                <SubFooter />
                <Footer/>
             </div>
        );
    }
}
export default GovernmentHousing;