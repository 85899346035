/* Importing the node modules, child components, services and controllers used
   inside Guestportal component */
   import $ from "jquery";
   import AppController from "../../controller/appController";
   import GuestHeader from '../../common/guestHeader';
   import Guest from "../../common/guest";
   import QuickRequestForm from './quickRequestForm';
   import Apps from './apps';
   import GuestBooks from './guestBooks';
   import GotAquestion from './gotAquestion';
   import Footer from '../../common/footer';
   import GuestController from "../../controller/guestController";
   import GuestFlightInfoModal from './modals/guestFlightInfoModal';
   import moment from 'moment'
   import FilghtDetailsSuccessModal from '../../components/travelBooking/model/filghtDetailsSuccessModal'
   import GuestServicesapi from '../../../services/guestServicesapi';
   import travelBookingApiService from '../../../services/travelBookingApiService';
   import SessionService from "../../../services/sessionService";
   /* Guestportal Component initialization */
   class Guestportal extends React.Component {
       /* Initializing objects of its Guestportal class */
       constructor(props) {
           super(props)
           /* Guestportal Component State variables Initialization */
           this.state = {
               eventsData: [],
               showloading: true,
               guestOrder: {},
               eventDataAddress: [],
               menuShow: true,
               guestDetails:false,
               OrderUId: '',
               notes: '',
               Flight: '',
               ArrivalAirport: '',
               ArrivalDate: moment(),
               Airline: '', 
               color: '',
               message: '', 
               userData: {}
           }
           this.setValue=this.setValue.bind(this)
           this.flightInfoSubmit=this.flightInfoSubmit.bind(this)
           this.checkInSelection=this.checkInSelection.bind(this)
       }
       /* It is invoked immediately before mounting occurs */
       async componentWillMount() {
           AppController.removeLocalStorageData();
           $('html, body').animate({scrollTop: 0});
           await AppController.stickyTitles(); 
       }
       async componentDidMount(){
           const userData=await SessionService.SessionGet({type:'guest'});
           this.setState({userData: userData});
           const guestDetails = {
               orderUid: userData.OrderUId
           }
           if(userData.OrderUId != null && userData.status !='TERMED') {
               const tripInfoData = await GuestServicesapi.GetTripInfo(guestDetails);
           this.setState({OrderUId: userData.OrderUId});
   
               const guestInfo = {
                   guestEmailid: (userData != undefined && userData.GuestEmail != null)
                       ? userData.GuestEmail
                       : '',
                   orderid: (userData != undefined && userData.OrderID != null)
                       ? userData.OrderID
                       : ''
               }     
               const guestOrderData = await GuestServicesapi.getGuestInfoCommunityByEmailId(guestInfo)
               this.setState({ArrivalDate: moment(guestOrderData.MoveInDate)});
               if(tripInfoData != null){
                   this.setState({
                       Flight: tripInfoData.Flight == null ? '' : tripInfoData.Flight, 
                       ArrivalAirport: tripInfoData.ArrivalAirport == null ? '':tripInfoData.ArrivalAirport, ArrivalDate: (tripInfoData.ArrivalDate != null && tripInfoData.ArrivalDate != '') ? moment(tripInfoData.ArrivalDate) : moment(guestOrderData.MoveInDate),
                       Airline: tripInfoData.Airline == null ? '':tripInfoData.Airline, 
                       notes: tripInfoData.Notes == null ? '':tripInfoData.Notes
                   })
               }       
            const startDate = moment([
                   Number(moment().utc().format('YYYY')),
                   Number(moment().utc().format('M')) - 1,
                   Number(moment().utc().format('D'))
           ]);
            const endDate = moment([
               Number(moment(guestOrderData.MoveInDate).utc().format('YYYY')),
               Number(moment(guestOrderData.MoveInDate).utc().format('M')) - 1,
               Number(moment(guestOrderData.MoveInDate).utc().format('D'))
              ]);
           const diffDate = endDate.diff(startDate, 'days');
           if(diffDate < 0) {
               jQuery(function ($) {
                   $('#holdModal').modal({backdrop: 'static', keyboard: false, show: false});
               }.bind(this));
           } 
           else if(tripInfoData == null) {
               jQuery(function ($) {
                   $('#holdModal').modal({backdrop: 'static', keyboard: false, show: true});
               }.bind(this));
           }
            else if((tripInfoData.Flight != null && tripInfoData.Flight != '') && (tripInfoData.ArrivalAirport != null && tripInfoData.ArrivalAirport != '') && (tripInfoData.ArrivalDate != null && tripInfoData.ArrivalDate != '') && (tripInfoData.Airline != null && tripInfoData.Airline != '')) {
               jQuery(function ($) {
                   $('#holdModal').modal({backdrop: 'static', keyboard: false, show: false});
               }.bind(this));
           } 
           else{
               jQuery(function ($) {
                   $('#holdModal').modal({backdrop: 'static', keyboard: false, show: true});
               }.bind(this));
           }
        }
    //        if(guestOrderData!==null){
    //            location.href='/guest-order-data'
    //        }
    //        else if(tripInfoData!==null){
    //            location.href='/trip-info-data'
    //        }else{
    //            location.href='/guest-login'
    //        }
    //    }else{
    //     location.href='/guest-order-data'
    //    }
           const guestOrder= await GuestController.getGuestInformation()
           await this.setState({guestOrder:guestOrder,guestDetails:true}) 
       }
       async flightInfoSubmit(){
           const _this = this;
              const requestObj = {
                  OrderUId: this.state.OrderUId,
                  Notes: this.state.notes,
                  Flight: this.state.Flight,
                  ArrivalAirport: this.state.ArrivalAirport,
                  ArrivalDate: this.state.ArrivalDate.format('YYYY-MM-DD HH:mm'),
                  Airline: this.state.Airline,
                  ChangedBy: this.state.userData.GuestEmail
              }
              let tripInfoResult = await travelBookingApiService.SaveTripInfo(requestObj);
              if(tripInfoResult.error == 0){ 
              jQuery(function ($) {
                  $('#holdModal').modal('hide');
                  $('#flight-details').modal('show');
                  setTimeout(async function () {                  
                      $('#flight-details').modal('hide');                      
                      await _this.setState({notes: '', Flight: '', ArrivalAirport: '', ArrivalDate: moment(), Airline: ''}); 
                  }.bind(this), 5000);
              }.bind(this));
              }else {
                  this.setState({message: 'Server side failure', color: 'red'});
              }
       }
       setValue(field, e) {
          var object = {};
          object[field] = e.target.value;
          this.setState(object);
        }
        async checkInSelection(date){          
          await this.setState({ArrivalDate: moment(date)});
       }
       /* It is invoked to return html content */
       render() {
           return (
               <div className="service_request">
                   <GuestHeader/>
                   <div className="profile_submenu-ht"></div>
                   <Guest/>
                   <QuickRequestForm menuShow={this.state.menuShow}/>
                   <Apps/>
                   <GuestBooks/>
                   {this.state.guestDetails ? <GotAquestion guestOrder={this.state.guestOrder}/>:null}
                   <Footer/>
                   <GuestFlightInfoModal {...this.state} flightInfoSubmit={this.flightInfoSubmit} setValue={this.setValue} checkInSelection={this.checkInSelection}/>
                   <FilghtDetailsSuccessModal/>
               </div>
           );
       }
   }
   export default Guestportal;