import React, { useEffect } from "react";
import GuestServicesapi from "../../../../../services/guestServicesapi";
import Loader from "../../../newinterface/guestPortal/loader";
import BookitApiService from "../../../../../services/bookit";

function ServiceRequest({ orderDetails, masterDetails }) {
  // console.log(orderDetails, "orderDetails", masterDetails, "masterDetails");
  const [state, setState] = React.useState({
    serviceCategory: [],
    roomServiceCategory: [],
    specificPlaceInfo: [],
    whatsHappeningInfo: [],
    loader: false,
    errMsg: false,
    whichRoom: "",
    whichPlace: "",
    description: "",
    isPte: true,
    serviceReqImg: "",
    serviceReqName: "",
    selectedService: "",
    description: "",
    categoryCode: "",
    tabIndex: 0,
    uniqueId: "",
    descriptionValid: false,
    hideBackButton: false,
    showSuccessMsg: false,
    statusMsg: "",
    whatsHappening: "",
    whatsHappeningUniqueId: "",
  });
  useEffect(() => {
    getServicesCategory();
  }, []);

  const updateState = (newState) => {
    setState((prevState) => ({ ...prevState, ...newState }));
  };
  const getServicesCategory = () => {
    updateState({ loader: true });
    GuestServicesapi.GetServicesCatergory({ type: "category" }).then(
      (response) => {
        if (response.error !== 1) {
          setState((prev) => ({
            ...prev,
            serviceCategory: response.message,
            loader: false,
          }));
        } else {
          setState((prev) => ({
            ...prev,
            loader: false,
          }));
        }
      }
    );
  };

  const setValue = async (image, name, code, id) => {
    if (state.categoryCode) {
      setState((prev) => ({
        ...prev,
        whichRoom: "",
        whichPlace: "",
        description: "",
        isPte: true,
        serviceReqImg: "",
        serviceReqName: name,
        categoryCode: code,
        tabIndex: state.tabIndex + 1,
        uniqueId: id,
      }));
    } else {
      await setState((prev) => ({
        ...prev,
        serviceReqImg: "",
        serviceReqName: name,
        categoryCode: code,
        tabIndex: state.tabIndex + 1,
        uniqueId: id,
      }));
    }
    await getRoomServices();
    updateState({
      selectedService: name,
    });
  };

  /*
   *Function is to get Room Services
   */
  const getRoomServices = async () => {
    await updateState({ loader: true });
    GuestServicesapi.GetRoomServices({ type: "room" }).then((response) => {
      if (response.error !== 1) {
        updateState({
          roomServiceCategory: response.message,
          loader: false,
        });
      } else {
        updateState({ loader: false });
      }
    });
  };

  const pickWhichRoom = async (room, id) => {
    if (state.whichRoom) {
      if (state.whichRoom !== room) {
        updateState({
          whichRoom: room,
          whichPlace: "",
          description: "",
          isPte: true,
          roomuid: id,
        });
      }
    } else {
      await updateState({ whichRoom: room, roomuid: id });
    }
  };
  const pickWhichPlace = (place, id) => {
    if (state.whichPlace) {
      if (state.whichPlace !== place) {
        updateState({
          description: "",
          isPte: true,
          whichPlace: place,
          roomtypeuid: id,
        });
      }
    } else {
      updateState({ whichPlace: place, roomtypeuid: id });
    }
  };
  const specificPlace = async () => {
    await updateState({ loader: true });
    if (state.whichRoom == "OTHER") {
      GuestServicesapi.GetSubCategoryService({
        type: "room",
        category: state.serviceReqName.replace("/", "-"),
        room: state.whichRoom,
      }).then((response) => {
        if (response.error !== 1) {
          let specificPlaceInfo = response.message;
          var sortingData = _.sortBy(specificPlaceInfo, "Display");
          sortingData.some(
            (item, idx) =>
              (item.Display == "MASTER BEDROOM" ||
                item.Display == "MASTER BATHROOM") &&
              sortingData.unshift(
                // remove the found item, in-place (by index with splice),
                // returns an array of a single item removed
                sortingData.splice(idx, 1)[0]
              )
          );
          //   console.log(sortingData, "sortingData ifffff");
          updateState({
            specificPlaceInfo: sortingData,
            loader: false,
          });
        } else {
          updateState({ loader: false });
        }
      });
    } else {
      GuestServicesapi.GetRoomTypeService({
        type: "roomtype",
        room: state.whichRoom,
      }).then((response) => {
        if (response.error !== 1) {
          let specificPlaceInfo = response.message;
          var sortingData = _.sortBy(specificPlaceInfo, "Display");
          sortingData.some(
            (item, idx) =>
              (item.Display == "MASTER BEDROOM" ||
                item.Display == "MASTER BATHROOM") &&
              sortingData.unshift(
                // remove the found item, in-place (by index with splice),
                // returns an array of a single item removed
                sortingData.splice(idx, 1)[0]
              )
          );
          //   console.log(sortingData, "sortingData");
          updateState({
            specificPlaceInfo: sortingData,
            loader: false,
          });
        } else {
          updateState({ loader: false });
        }
      });
    }
  };
  const description = (e) => {
    let value = e.target.value.replace(/^\\s*/g, "");
    if (value !== " ") {
      updateState({ description: e.target.value });
    }
  };
  const isPte = () => {
    updateState({ isPte: !state.isPte });
  };
  const submit = async () => {
    const sessionResponse = await BookitApiService.checkSession();
    let loginData = sessionResponse.ClientProfileCardDetails;
    await updateState({ loader: true });
    if (state.description !== "") {
      var serviceWebReqDetails = {
        CategoryCode: state.categoryCode,
        CreatedBy: sessionResponse.email,
        CreatedDate: moment().format("YYYY/MM/DD LT"),
        Description: state.description,
        LeaseUId: masterDetails.masterUId,
        RequestDate: moment().format("YYYY/MM/DD LT"),
        Requested: loginData.FirstName,
        community: masterDetails.communityname,
        orderUid: orderDetails.orderUId,
        petAllowed: state.isPte == false ? false : true,
        IsPte: state.isPte == false ? false : true,
        roomuid: state.roomuid,
        roomtypeuid: state.roomtypeuid,
        SubCategory: state.whatsHappening == "" ? "" : state.whatsHappening,
        ServiceDescriptionUId:
          state.whatsHappeningUniqueId == ""
            ? ""
            : state.whatsHappeningUniqueId,
        Mode: true,
      };
      // console.log(serviceWebReqDetails, "serviceWebReqDetails");
      GuestServicesapi.SaveServiceWebRequestDetails(serviceWebReqDetails).then(
        (response) => {
          if (response.message == "Inserted Successfully") {
            updateState({
              showSuccessMsg: true,
              loader: false,
              hideBackButton: true,
              descriptionValid: false,
              statusMsg: "Request Submitted Successfully.",
            });
            setTimeout(async () => {
              await closeServiceRequest();
              $("#myServiceRequestModal").modal("hide");
              // $("body").removeClass("modal-open");
              // $(".modal-backdrop").remove();
            }, 4000);
          } else {
            updateState({
              loader: false,
              statusMsg: "Failed to Submit. Please try again.",
            });
          }
        }
      );
    } else {
      updateState({ descriptionValid: true, loader: false });
    }
  };
  const back = async () => {
    updateState({ descriptionValid: false });
    if (state.tabIndex == 4) {
      await updateState({ tabIndex: state.tabIndex - 2, errMsg: false });
    } else {
      await updateState({ tabIndex: state.tabIndex - 1, errMsg: false });
    }
  };

  const next = async () => {
    if (state.tabIndex == 1) {
      if (state.whichRoom == "") {
        updateState({ errMsg: true });
      } else {
        await updateState({
          tabIndex: state.tabIndex + 1,
          errMsg: false,
        });
        specificPlace();
      }
    } else if (state.tabIndex == 2) {
      if (state.whichPlace == "") {
        updateState({ errMsg: true });
      } else {
        await updateState({ loader: true, errMsg: false });
        //Function is to get whats Happening Info
        GuestServicesapi.GetSubCategoryDescription({
          category: state.serviceReqName.replace("/", "-"),
          // category:  state.serviceReqName.replace('/', '$$'),
          room: state.whichRoom,
          subcategory: state.whichPlace.replace("/", "-"),
          // subcategory:  state.whichPlace.replace('/', '$$')
        }).then((response) => {
          if (response.message.length > 0) {
            updateState({
              whatsHappeningInfo: response.message,
              loader: false,
              tabIndex: state.tabIndex + 2,
            });
          } else if (response.message.length == 0) {
            updateState({ loader: false, tabIndex: state.tabIndex + 2 });
          }
        });
      }
    } else if (state.tabIndex == 3) {
      if (state.whatsHappening == "") {
        updateState({ errMsg: true });
      } else {
        await updateState({
          tabIndex: state.tabIndex + 1,
          errMsg: false,
        });
      }
    }
  };
  const closeServiceRequest = async () => {
    $("#orderDetailsPopUp").modal("hide").removeClass("d-none");
    await updateState({
      selectedService: "",
      serviceInfoReq: [],
      loader: false,
      serviceReqImg: "",
      serviceReqName: "",
      tabIndex: 0,
      categoryCode: "",
      uniqueId: "",
      whichRoom: "",
      roomuid: "",
      whatsHappeningInfo: [],
      errMsg: false,
      specificPlaceInfo: [],
      whichPlace: "",
      roomtypeuid: "",
      isPte: true,
      description: "",
      descriptionValid: false,
      whatsHappening: "",
      whatsHappeningUniqueId: "",
      IsActiveOrderExists: true,
      showSuccessMsg: false,
      hideBackButton: false,
      selectedOrder: "",
      statusMsg: "",
    });
  };
  return (
    <div className="SAweb_updateUi">
      {state.tabIndex == 0 ? (
        <div
          className="modal modal-fullscreen-xl modal fade animated tab-modal guest-Ui SAweb_modalPoup"
          id="myServiceRequestModal"
          tabIndex={-1}
          data-backdrop="static"
          data-keyboard="false"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content newSRView">
              <div className="servicerequest-pop-header">
                <h2>How can we help?</h2>
                <h4>{masterDetails?.communityname}</h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => closeServiceRequest()}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                {state.loader ? (
                  <Loader />
                ) : (
                  <div className="SAwebGuest_arrivalInfo">
                    <div className="SR-tials">
                      {state.serviceCategory?.length > 0
                        ? state.serviceCategory.map(
                            (serviceCategoryResponse) => {
                              return (
                                <div
                                  className={
                                    serviceCategoryResponse.UniqueId ==
                                    state.uniqueId
                                      ? "item active"
                                      : "item"
                                  }
                                  key={serviceCategoryResponse.UniqueId}
                                  onClick={() =>
                                    setValue(
                                      serviceCategoryResponse.ImageUrl,
                                      serviceCategoryResponse.Display,
                                      serviceCategoryResponse.Code,
                                      serviceCategoryResponse.UniqueId
                                    )
                                  }
                                >
                                  <img
                                    src={
                                      serviceCategoryResponse.Code ==
                                      "ELECTRICAL"
                                        ? state.categoryCode == "ELECTRICAL"
                                          ? "assets/newUI-images/guestportal/icons/plug-2.png"
                                          : "assets/newUI-images/guestportal/icons/plug.png"
                                        : serviceCategoryResponse.Code ==
                                          "FURNITURE"
                                        ? state.categoryCode == "FURNITURE"
                                          ? "assets/newUI-images/guestportal/icons/sofa-2.png"
                                          : "assets/newUI-images/guestportal/icons/sofa.png"
                                        : serviceCategoryResponse.Code ==
                                          "PLUMBING"
                                        ? state.categoryCode == "PLUMBING"
                                          ? "assets/newUI-images/guestportal/icons/tap-2.png"
                                          : "assets/newUI-images/guestportal/icons/tap.png"
                                        : serviceCategoryResponse.Code ==
                                          "PESTS"
                                        ? state.categoryCode == "PESTS"
                                          ? "assets/newUI-images/guestportal/icons/bug-2.png"
                                          : "assets/newUI-images/guestportal/icons/bug.png"
                                        : serviceCategoryResponse.Code == "KEYS"
                                        ? state.categoryCode == "KEYS"
                                          ? "assets/newUI-images/guestportal/icons/key-2.png"
                                          : "assets/newUI-images/guestportal/icons/key.png"
                                        : serviceCategoryResponse.Code ==
                                          "APPLIANCES"
                                        ? state.categoryCode == "APPLIANCES"
                                          ? "assets/newUI-images/guestportal/icons/washing-machine-2.png"
                                          : "assets/newUI-images/guestportal/icons/washing-machine.png"
                                        : serviceCategoryResponse.Code ==
                                          "HOUSEKEEPING"
                                        ? state.categoryCode == "HOUSEKEEPING"
                                          ? "assets/newUI-images/guestportal/icons/broom-2.png"
                                          : "assets/newUI-images/guestportal/icons/broom.png"
                                        : serviceCategoryResponse.Code ==
                                          "HEATING/AIR"
                                        ? state.categoryCode == "HEATING/AIR"
                                          ? "assets/newUI-images/guestportal/icons/hot-2.png"
                                          : "assets/newUI-images/guestportal/icons/hot.png"
                                        : serviceCategoryResponse.Code ==
                                          "WIFI/CABLE"
                                        ? state.categoryCode == "WIFI/CABLE"
                                          ? "assets/newUI-images/guestportal/icons/wifi-2.png"
                                          : "assets/newUI-images/guestportal/icons/wifi.png"
                                        : serviceCategoryResponse.Code ==
                                          "HOUSEWARES"
                                        ? state.categoryCode == "HOUSEWARES"
                                          ? "assets/newUI-images/guestportal/icons/housewares-2.png"
                                          : "assets/newUI-images/guestportal/icons/housewares.png"
                                        : serviceCategoryResponse.Code ==
                                          "WINDOWS"
                                        ? state.categoryCode == "WINDOWS"
                                          ? "assets/newUI-images/guestportal/icons/window-2.png"
                                          : "assets/newUI-images/guestportal/icons/window.png"
                                        : null
                                    }
                                    alt=""
                                  />
                                  <p>{serviceCategoryResponse.Display}</p>
                                </div>
                              );
                            }
                          )
                        : null}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : state.tabIndex == 1 ? (
        <div
          className="modal modal-fullscreen-xl modal fade animated tab-modal guest-Ui SAweb_modalPoup"
          id="myServiceRequestModal"
          tabIndex={-1}
          data-backdrop="static"
          data-keyboard="false"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content newSRView">
              <div className="servicerequest-pop-header ">
                <h2>Let's get specific</h2>
                <h4>{masterDetails?.communityname}</h4>
                <button
                  type="button"
                  className="btn close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => closeServiceRequest()}
                >
                  <i className="bi bi-x"></i>
                </button>
              </div>
              <div className="modal-body">
                {state.loader ? (
                  <Loader />
                ) : (
                  <div className="SAwebGuest_arrivalInfo">
                    <div className="serviceSelected">
                      <div className="type">
                        <img
                          src={
                            state.selectedService == "ELECTRICAL"
                              ? "assets/newUI-images/guestportal/icons/plug.png"
                              : state.selectedService == "FURNITURE"
                              ? "assets/newUI-images/guestportal/icons/sofa.png"
                              : state.selectedService == "PLUMBING"
                              ? "assets/newUI-images/guestportal/icons/tap.png"
                              : state.selectedService == "PESTS"
                              ? "assets/newUI-images/guestportal/icons/bug.png"
                              : state.selectedService == "KEYS"
                              ? "assets/newUI-images/guestportal/icons/key.png"
                              : state.selectedService == "APPLIANCES"
                              ? "assets/newUI-images/guestportal/icons/washing-machine.png"
                              : state.selectedService == "HOUSEKEEPING"
                              ? "assets/newUI-images/guestportal/icons/broom.png"
                              : state.selectedService == "HEATING/AIR"
                              ? "assets/newUI-images/guestportal/icons/hot.png"
                              : state.selectedService == "WIFI/CABLE"
                              ? "assets/newUI-images/guestportal/icons/wifi.png"
                              : state.selectedService == "HOUSEWARES"
                              ? "assets/newUI-images/guestportal/icons/housewares.png"
                              : state.selectedService == "WINDOWS"
                              ? "assets/newUI-images/guestportal/icons/window.png"
                              : null
                          }
                          alt=""
                        />
                        {state.selectedService}
                      </div>

                      <h4>What room are you in?</h4>

                      <div className="roomView ">
                        {state.roomServiceCategory.length > 0
                          ? state.roomServiceCategory.map(
                              (roomServiceCategoryRes) => {
                                return (
                                  <button
                                    key={roomServiceCategoryRes.UniqueId}
                                    className={
                                      state.whichRoom ==
                                      roomServiceCategoryRes.Display
                                        ? "btn active"
                                        : state.errMsg
                                        ? "btn validation"
                                        : "btn"
                                    }
                                    type="button"
                                    onClick={() =>
                                      pickWhichRoom(
                                        roomServiceCategoryRes.Display,
                                        roomServiceCategoryRes.UniqueId
                                      )
                                    }
                                    name={roomServiceCategoryRes.Display}
                                  >
                                    {roomServiceCategoryRes.Display}
                                  </button>
                                );
                              }
                            )
                          : null}
                      </div>

                      <div className="text-right">
                        <button
                          type="button"
                          className="btn"
                          onClick={() => back()}
                        >
                          {" "}
                          Cancel{" "}
                        </button>
                        <button
                          type="button"
                          className="btn black-btn next"
                          onClick={() => next()}
                        >
                          {" "}
                          Next{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : state.tabIndex == 2 ? (
        <div
          className="modal modal-fullscreen-xl modal fade animated tab-modal guest-Ui SAweb_modalPoup"
          id="myServiceRequestModal"
          tabIndex={-1}
          data-backdrop="static"
          data-keyboard="false"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content newSRView">
              <div className="servicerequest-pop-header">
                <h2>Where?</h2>
                <h4>{masterDetails?.communityname}</h4>
                <button
                  type="button"
                  className="btn close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => closeServiceRequest()}
                >
                  <i className="bi bi-x"></i>
                </button>
              </div>

              <div className="modal-body SAwebGuest_arrivalInfo">
                {state.loader ? (
                  <Loader />
                ) : (
                  <div className="serviceSelected">
                    <div className="type">
                      <img
                        src={
                          state.selectedService == "ELECTRICAL"
                            ? "assets/newUI-images/guestportal/icons/plug.png"
                            : state.selectedService == "FURNITURE"
                            ? "assets/newUI-images/guestportal/icons/sofa.png"
                            : state.selectedService == "PLUMBING"
                            ? "assets/newUI-images/guestportal/icons/tap.png"
                            : state.selectedService == "PESTS"
                            ? "assets/newUI-images/guestportal/icons/bug.png"
                            : state.selectedService == "KEYS"
                            ? "assets/newUI-images/guestportal/icons/key.png"
                            : state.selectedService == "APPLIANCES"
                            ? "assets/newUI-images/guestportal/icons/washing-machine.png"
                            : state.selectedService == "HOUSEKEEPING"
                            ? "assets/newUI-images/guestportal/icons/broom.png"
                            : state.selectedService == "HEATING/AIR"
                            ? "assets/newUI-images/guestportal/icons/hot.png"
                            : state.selectedService == "WIFI/CABLE"
                            ? "assets/newUI-images/guestportal/icons/wifi.png"
                            : state.selectedService == "HOUSEWARES"
                            ? "assets/newUI-images/guestportal/icons/housewares.png"
                            : state.selectedService == "WINDOWS"
                            ? "assets/newUI-images/guestportal/icons/window.png"
                            : null
                        }
                        alt=""
                      />
                      {state.selectedService}
                    </div>

                    <div className="roomView border-1 border-dark border-top pt-3">
                      {state.specificPlaceInfo.length > 0
                        ? state.specificPlaceInfo.map((specificPlaceReq) => {
                            return (
                              <button
                                key={specificPlaceReq.UniqueId}
                                className={
                                  specificPlaceReq.Display == state.whichPlace
                                    ? "btn active"
                                    : state.errMsg
                                    ? "btn validation"
                                    : "btn"
                                }
                                type="button"
                                onClick={() =>
                                  pickWhichPlace(
                                    specificPlaceReq.Display,
                                    specificPlaceReq.UniqueId
                                  )
                                }
                              >
                                {specificPlaceReq.Display}
                              </button>
                            );
                          })
                        : null}
                    </div>
                    <div className="text-right">
                      <button
                        className="btn"
                        type="button"
                        onClick={() => back()}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn black-btn next"
                        type="button"
                        onClick={() => next()}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : state.tabIndex == 4 ? (
        <div
          className="modal modal-fullscreen-xl modal fade animated tab-modal guest-Ui SAweb_modalPoup"
          id="myServiceRequestModal"
          tabIndex={-1}
          data-backdrop="static"
          data-keyboard="false"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content newSRView">
              <div className="servicerequest-pop-header">
                <h2>Service Request</h2>
                <h4>{masterDetails?.communityname}</h4>
                <button
                  type="button"
                  className="btn close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => closeServiceRequest()}
                >
                  <i className="bi bi-x"></i>
                </button>
              </div>

              <div className="modal-body SAwebGuest_arrivalInfo">
                {state.loader ? (
                  <Loader />
                ) : state.showSuccessMsg != true ? (
                  <div className="SR-tials-itemSelected">
                    <div className="items">
                      {/* {state.OrderType == "S" && ( */}
                      <div className="">
                        <div className="type">
                          <img
                            src={
                              state.selectedService == "ELECTRICAL"
                                ? "assets/newUI-images/guestportal/icons/plug.png"
                                : state.selectedService == "FURNITURE"
                                ? "assets/newUI-images/guestportal/icons/sofa.png"
                                : state.selectedService == "PLUMBING"
                                ? "assets/newUI-images/guestportal/icons/tap.png"
                                : state.selectedService == "PESTS"
                                ? "assets/newUI-images/guestportal/icons/bug.png"
                                : state.selectedService == "KEYS"
                                ? "assets/newUI-images/guestportal/icons/key.png"
                                : state.selectedService == "APPLIANCES"
                                ? "assets/newUI-images/guestportal/icons/washing-machine.png"
                                : state.selectedService == "HOUSEKEEPING"
                                ? "assets/newUI-images/guestportal/icons/broom.png"
                                : state.selectedService == "HEATING/AIR"
                                ? "assets/newUI-images/guestportal/icons/hot.png"
                                : state.selectedService == "WIFI/CABLE"
                                ? "assets/newUI-images/guestportal/icons/wifi.png"
                                : state.selectedService == "HOUSEWARES"
                                ? "assets/newUI-images/guestportal/icons/housewares.png"
                                : state.selectedService == "WINDOWS"
                                ? "assets/newUI-images/guestportal/icons/window.png"
                                : null
                            }
                            alt=""
                          />
                          {state.selectedService}
                        </div>
                      </div>
                      {/* )} */}
                      {/* {state.OrderType == "S" && ( */}
                      <h4>
                        {state.whichRoom} <i className="bi bi-arrow-right"></i>{" "}
                        {state.whichPlace}
                      </h4>
                      {/* )} */}
                    </div>

                    <div className="items2">
                      <h5>Notes*</h5>
                      <textarea
                        name="message"
                        rows={9}
                        placeholder="Please type your message here…."
                        defaultValue={""}
                        value={state.description}
                        onChange={description}
                        id="description"
                      />
                      {state.descriptionValid == true ? (
                        <span style={{ color: "red", textAlign: "right" }}>
                          Please enter Notes.
                        </span>
                      ) : null}
                      <div className="switchParent">
                        <div className="switchToggle">
                          Permission to enter
                          <i
                            className="fa fa-info-circle mrg-left-5"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="By granting permission to enter you are allowing
                      us access to your suite to address your
                      request(s) with or without your presence."
                            style={{ cursor: "pointer" }}
                          ></i>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={state.isPte}
                              onChange={() => isPte()}
                            />
                            <span className="slider" />
                          </label>
                        </div>
                        <div className="btns">
                          {state.hideBackButton == false ? (
                            <button
                              className="btn "
                              type="button"
                              onClick={() => back()}
                            >
                              Cancel
                            </button>
                          ) : null}
                          <button
                            className="btn black-btn cnl"
                            type="button"
                            onClick={() => submit()}
                            disabled={
                              state.showSuccessMsg == true ? true : false
                            }
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="alert alert-2 text-center">
                    <strong>Thanks!</strong> {state.statusMsg}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default ServiceRequest;
