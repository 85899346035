import React from 'react'
import Footer from './footer';
import Header from './header';
class CaseStudies1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
 async componentDidMount() {
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  }
  }

  render() {

    return (
      <div className="SAweb_updateUi">
        <Header />
        
    <section className="SAweb_homeCaseStudies_Banner bg-white">

<div className="container CaseStudiesContainer">
  <div className="banner">
    <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/caseStudies/Casestudy_1_banner.jpg" alt="" width="100%" />
  </div>
</div>

</section>

<div className="SAweb_homeCaseStudiesHeading">
<div className="container CaseStudiesContainer">
<h2>Housing solution for a leading global tech company</h2>
</div>
</div>

<div className="SAweb_homeCaseStudiesContent bg-white">
<div className="container CaseStudiesContainer">
  <h3>Client Project:  </h3>
  <p>A leading global tech company looking for high-quality accommodations and guest experience, with a
guaranteed inventory.</p>


  <h3>Client Overview:</h3>
  <p>A global tech company in the Bay Area was looking for a housing solution that could provide guaranteed
inventory for a group of VIP executives. They wanted to provide their executives with a high-quality, high-touch, consistent guest experience in an A+ level community near their global HQ. They reached out to
SuiteAmerica, knowing our reputation for unique, technology-driven solutions and award-winning guest
services. After a conversation to understand what problems this tech company was looking to solve,
SuiteAmerica’s experienced team of relationship and operations managers went to work to craft a bespoke
program that would perfectly suit their needs. </p>
  <h3>Client Requirements :</h3>
  <p>The client had several priorities driving their search for a solution:</p>
  <ul>
  <li>Apartments that were in a good and safe location</li>
<li>One community solution to create a sense of community amongst the group</li>
<li>Superior, A+ accommodations for every executive</li>
<li>24/7/365 guest services and onsite staffing to assist anyone in the group, any time of day</li>
<li>Company swag in each suite on arrival</li>
<li>A competitive rate that aligned with their program budget</li>
<li>Tech-driven experience that was easy and stress-free</li>
  </ul>

  <div className="imageGrid">
    <div className="item"> <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/caseStudies/Casestudy_1_subleft.jpg" alt="" width="100%" /> </div>
    <div className="item"> <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/caseStudies/Casestudy_1_subright.jpg" alt="" width="100%" /> </div>
  </div>

  <h3>Strategy and Approach: </h3>
  <p>The SuiteAmerica team knew that our ability to provide density (i.e. a large volume of apartments in one
location) and a tech-focused arrival experience would be the key to winning this program. Our exclusive
corporate housing relationship with a well-known regional property management and development company
enabled us to work quickly to secure over 50 apartments in a newly constructed community with terms and
pricing that enabled us to offer our clients a competitive but fair rate, with a few extras like boosted WiFi (for
those work at home executives) and complimentary bed swaps to accommodate different sized families. We
delivered outstanding value by being able to include personal property insurance as part of our rate package,
and an upgraded welcome basket to both surprise and delight the arriving executives. A final aspect of our
proposal was to include gender-neutral or gender-specific (depending on preferences at the time of
consultation) kid’s packages for families with children, which offers different bedroom décor and a game for
each child.  
  </p>
  <p>Our proposal was rooted in technology, using our in-house built solutions to deliver a reservation and arrival experience that was easy for the guest, giving them a web-based info page with FAQs, and consistent insight into their booking details via our online Guest Portal.
</p>

  <p>Technology also played a large role in the client and inventory management aspects of the program, and
SuiteAmerica’s in-house, built-for-corporate-housing operating platform used automation to match up a
guest with the suite that best fit their family size and arrival date in order to limit the number of unoccupied
days. We built an online dashboard, giving our client real-time information about in-house and pending
reservations that tracked spend, length of stay, guest information, and more data points that were valuable to
our client’s overall insight into their program.
</p>

  <h3>Results: </h3>
  <p>SuiteAmerica used our 30+ years of temporary housing and technology experience to execute and manage a
program solution that created maximum value for our client and maximum satisfaction for our guests. The
result was a winning proposal and a happy client!  
  </p>
</div>
</div>
        <Footer />
      </div>
    );
  }
}
export default CaseStudies1;