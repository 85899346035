/* Importing the node modules, child components, services and controllers used 
   inside CompanyInfo component */

import MainController from "../controller/mainController";
import SubFooter from "./subFooter";
import GuestHeader from './guestHeader';
import Footer from './footer';
import MetaTags from './metaTags';
import ServerMetaTags from '../../server/metaTags';
import NavigationFooter from './navigationFooter';
import NavigationData from './navigation.json';
/* CompanyInfo Component initialization */
class CompanyInfo extends React.Component {
    /* Initializing objects of its CompanyInfo class */
    constructor(props) {
        super(props);
        this.state = {
            navigationData: NavigationData.companyInfo
        }
    }
    /* It is invoked immediately before mounting occurs */
    componentWillMount() {
        MainController.getInitialInformation();
    }
    /* It is invoked to return html content */
    render() {
        const metaData = ServerMetaTags.urlString.getMetaData(this.props.match.url);
        return (
            <div>
            <MetaTags 
             metaTitle = {metaData.title}
             metaDesription = {metaData.description}
             metaUrl = {metaData.canonical} />
             <GuestHeader/>                
             <div className="services_ch">
                <div className="carousel banner_text">
                   <img className="img-responsive relocation-corporate-housing" src={s3BucketUrl_WebsiteImages+"SuiteAmerica-About-Us-Banner-Image.jpg"} alt="" />
                   <div className="img-on-text width500 left40 top40 width500 text-center img-on-text-center img-on-text-center-w wow fadeInUp"  data-wow-delay="0.5s">
                     
                      <h3 className="animated font300">THE SUITEAMERICA EXPERIENCE
                      </h3>
                   </div>
                </div>
             </div>
             <div className="quote-section bg-white">
                <div className="container">
                   <div className="plaine-text global-coverage-wrap col-md-10 col-md-offset-1 wow fadeInUp" data-wow-delay="0.5s">
                      <h2>ABOUT US</h2>
                      <p>We are one of the fastest growing corporate housing companies in the United States. Our culture
                      stems from great leadership and our dedication to creating experiences for our clients that are second to none.
                      Whether you need a furnished apartment for a temporary assignment, assistance with relocation, or interim housing
                      solutions for employees, our goal is to always exceed your expectations.
                      </p>
                      <p>Our unbeatable track record of outstanding customer service has garnered numerous awards, including the
                      Corporate Housing Providers Association Company of the Year. We were rated the number one Temporary Housing
                      Company in the Trippel Survey of Relocation Managers and Tranferees from 2010-2015, and earned the 2015 Cartus
                      Gold Supplier Innovation Award for Same-Day Turns.
                     </p>
                   </div>
                </div>
             </div>
             <div className="services_ch">
                <div className="carousel banner_text">
                   <img className="img-responsive imgHeight" src={s3BucketUrl_WebsiteImages+"SuiteAmerica-About-Us-Our-Goal.jpg"} alt="" />
                   <div className="img-on-para wow fadeInUp" data-wow-delay="0.5s">
                   <div className="flex_div">
                        <div className="container">
                        <h2 className="animated">WHAT WE DO</h2>
                        <p>We set an unbeatable standard for customer satisfaction. Our attention to detail and personalized services make
                        temporary assignments or relocations nearly stress free for our clients. We offer fully furnished luxury homes
                        and apartments while providing centralized reservations and guest services around the world.
                        </p>
                        <p>No matter when you need us, we’re available. We provide emergency services 24/7 to make sure you’re taken
                        care of whenever you need assistance. Online account access means round-the-clock availability of reservation
                        information, service requests, optional upgrades, and much more.</p>
                        <p>Whether you need personal transition services, meet-and-greets for employees, a pet package for the family
dog, or a bicycle to explore the neighborhood, we’ll make sure you get it.</p>
                        <p>We offer a wide range of furniture and housewares packages to meet and exceed your expectations. You’ll also
enjoy a customized area and walking guide with information on local neighborhoods, shops, attractions,
weather trends, and more to help you become oriented in your new place quickly.</p>
                        </div>
                        </div>
                   </div>
                </div>
             </div>
             <div className="quote-section bg-grey">
                <div className="container">
                   <div className="plaine-text global-coverage-wrap col-md-10 col-md-offset-1 wow fadeInUp" data-wow-delay="0.5s">
                      <h2>EVERYTHING UNDER ONE ROOF. OURS.</h2>
                      <ul className="company-information">
                        <li>Corporate Housing</li>
                        <li>Serviced Apartments</li>
                        <li>Destination Services</li>
                        <li>Advanced Interactive Tools</li>
                        <li>Real-Time Reporting Solutions</li>
                        <li>Global Coverage</li>
                      </ul>
                   </div>
                </div>
             </div>
             <div className="quote-section bg-white">
                <div className="container">
                   <div className="plaine-text global-coverage-wrap col-md-10 col-md-offset-1 wow fadeInUp" data-wow-delay="0.5s">
                      <h2>OUR MISSION</h2>
                      <p>We’re determined to provide platinum-level service to delight guests who are relocating temporarily or looking
for the perfect permanent home. We provide a place to live and thrive, not just a place to stay—and ensure that
every guest’s needs are met while staying with us.</p>
                   </div>
                </div>
             </div>
             <div className="services_ch">
                <div className="carousel banner_text">
                   <img className="img-responsive" src={s3BucketUrl_WebsiteImages+"SuiteAmerica-About-Us-Our-Culture.jpg"} alt="" />

                   <div className="img-on-para wow fadeInUp" data-wow-delay="0.5s">
                   <div className="flex_div">
                        <div className="container">
                        <h2 className="animated">OUR PHILOSOPHY</h2>
                      <p>Beginning nearly 30 years ago, we decided we wanted to be the absolute best provider of corporate housing.
                      Everything we do, every day, is centered on our clients. This philosophy—putting the clients’ needs first, hiring
                      the best people, listening to and implementing employee ideas for improvement, and investing in the best
                      products, service providers, and technology available—keeps us firmly in place as the best in the business. Our
                      personalized, world-class housing and destination services are available for everyone from interns to Fortune
                      500 leaders. Everyone is treated as an honored guest at SuiteAmerica.
                      </p>
                        </div>
                        </div>
                   </div>

                </div>
             </div>
             <div className="quote-section bg-white">
                <div className="container mediakit company-info">
                    <div className="plaine-text global-coverage-wrap col-md-10 col-md-offset-1 wow fadeInUp" data-wow-delay="0.5s">
                        <h2 className="mrg-btm-30">POINTS OF DIFFERENCE</h2>
                        <p>Almost three decades of experience in corporate housing makes us an industry leader you can trust.
                        SuiteAmerica is an award-winning and our culture makes all the difference in our
                        service and guest satisfaction.
                        </p>                        
                        <h5 className="text-left mrg-btm-0 font700"><strong>24-Hour Online Account Access</strong></h5>
                        <ul>
                            <li>Enjoy easy, anytime access to your account.</li>
                         </ul>
                         <h5 className="text-left mrg-btm-0 font700"><strong>Single Point of Contact</strong></h5>
                        <ul>
                            <li>Streamlined services make everything easier.</li>
                         </ul> 
                         <h5 className="text-left mrg-btm-0 font700"><strong>Complimentary Personal Transition Services</strong></h5>
                        <ul>
                            <li>A white-glove, customized in-person experience.</li>
                         </ul>
                         <h5 className="text-left mrg-btm-0 font700"><strong>Concierge Services 7 Days a Week</strong></h5>
                        <ul>
                            <li>A SuiteAmerica representative will always be available</li>
                         </ul>
                         <h5 className="text-left mrg-btm-0 font700"><strong>Guest Services Available 24/7</strong></h5>
                        <ul>
                            <li>A SuiteAmerica representative will always available</li>
                         </ul>
                         <h5 className="text-left mrg-btm-0 font700"><strong>One Time Master Lease</strong></h5>
                        <ul>
                            <li>Enjoy hassle-free, easy renting.</li>
                         </ul>
                         <h5 className="text-left mrg-btm-0 font700"><strong>Centralized Reservations and Services</strong></h5>
                        <ul>
                            <li>Quality services are available in all time zones.</li>
                         </ul>
                         <h5 className="text-left mrg-btm-0 font700"><strong>Customized Apartment, Furniture, and Amenities Packages</strong></h5>
                        <ul>
                            <li>Choose from a wide variety of options.</li>
                         </ul>
                         <h5 className="text-left mrg-btm-0 font700"><strong>Complimentary High-Speed Internet</strong></h5>
                        <ul>
                            <li>Ensures fast and accessible internet for all.</li>
                         </ul>
                         <h5 className="text-left mrg-btm-0 font700"><strong>24-Hour Meet & Greet Services</strong></h5>
                        <ul>
                            <li>A friendly face will greet you at your door so you don’t have to search for a lockbox.</li>
                         </ul> 
                         <h5 className="text-left mrg-btm-0 font700"><strong>Permanent Housing Options</strong></h5>
                        <ul>
                            <li>We’ll help you find the perfect home in your new location.</li>
                         </ul> 
                         <h5 className="text-left mrg-btm-0 font700"><strong>Same Day Turn Arounds</strong></h5>
                        <ul>
                            <li>Our employees guarantee quick and efficient preparation between guests.</li>
                         </ul> 
                    </div>
                </div>
            </div>             
             <div className="clearfix"></div>
             <NavigationFooter {...this.state} />
             <SubFooter />
             <Footer/>
            </div>
        );
    }
}
export default CompanyInfo;