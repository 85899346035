/* Importing the node modules, child components, services and controllers used 
   inside mapboxController object */
   'use strict'
     /**
    * Map controller - controls application state and interaction among various components.
    */
   const mapboxController = {
    markerPopupContent(attractionsSubResult) {
        let i;
        const jsonArray = []
        const flyArray = []
        for (i = 0; i < attractionsSubResult.length; i++) {
          const detailAddress = attractionsSubResult[i].address + ', ' + attractionsSubResult[i].city_name + ', ' + attractionsSubResult[i].state_name + ' ' + attractionsSubResult[i].ZipCode
          console.log(detailAddress)
          const jSon = {
            "type": "Feature",
            "properties": {
              "iconSize": [30, 30],
              "message": '<div id="content">' +
                '<div id="siteNotice">' +
                '</div>' +
                '<div id="bodyContent">' +
                '<h1>' + attractionsSubResult[i].community_name + '</h1>' +
                '</div>' +
                '</div>'
            },
            "geometry": {
              "type": "Point",
              "coordinates": [
                attractionsSubResult[i].longitude,
                attractionsSubResult[i].latitude
              ]
            }
          }
          const fly = { 'center': [attractionsSubResult[i].longitude, attractionsSubResult[i].latitude] }
          jsonArray.push(jSon);
          flyArray.push(fly);
        }
    
        return [jsonArray, flyArray, attractionsSubResult]
    
      },
  
      
      async mapboxInitialization(geojson, map, thisObj, type) {
        map.addControl(new mapboxgl.NavigationControl());
        if (type == 1) {
          const _this = thisObj
          geojson.features.forEach(function (marker, index) {
            // create a DOM element for the marker
            var el = document.createElement('div');
            el.className = 'marker index-' + index;
            el.innerHTML = index==1?'<i class="mapbg fa fa-map-marker"></i>':'<i class="mapbg fa fa-home"></i>';
            new mapboxgl.Marker(el)
              .setLngLat(marker.geometry.coordinates)
              .addTo(map);
            const popup = new mapboxgl.Popup({
              offset: [-5, -10]
            })
              .setHTML(marker.properties.message);
    
            // create the marker
            new mapboxgl.Marker(el)
              .setLngLat(marker.geometry.coordinates)
              .setPopup(popup) // sets a popup on this marker
              .addTo(map);
    
          });
          await _this.setState({ rowIndex: 0 })
        }
      },
  
   }
   
   export default mapboxController