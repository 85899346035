import React from 'react';
import Footer from './footer';
import Header from './header';
   class EmploymentSearchs extends React.Component {
       constructor(props) {
           super(props);
       }
      
       render() {
           return (
               <div className="SAweb_updateUi">
                   <Header/>
                   <div className="company_section testmonial_gap bg-grey ">
                       <div className="container privacy_policy">
                           <div className="global-coverage-wrap col-md-10 col-md-offset-1 wow fadeInUp" data-wow-delay="0.5s">
                               
                               <h1 className="text-center large-font mrg-btm-20">SuiteAmerica Career Search</h1>
                               <div className="col-md-8 col-md-offset-2 text-center">
                                   <p className="text-center mrg-btm-30">Browse our current career opportunities below.
                                   </p>                        
                               </div>
                           </div>
                           <div id="wowslider-container1">
                               <iframe src="https://www.paycomonline.net/v4/ats/index.php?/jobs&amp;clientkey=6CF34458764AECE5D85F85A6596F181E" scrolling="yes" allowtransparency="yes" width="100%" height="750px" frameBorder="0">
                                   
                               </iframe>
                           </div>
                       </div>
                   </div>
                   <Footer/>
               </div>             
           );
       }
   }
   export default EmploymentSearchs;