const cityPages = {

    "va-alexandria": {
        "image": "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/Alexandria2.jpg",
        "title": "PREMIUM LOCATIONS",
        "subTitle": "WORLDWIDE",
        "city": "ALEXANDRIA",
        "state": "VA",
        "country": "US",
        "countryId": "2",
        "state_id": '62',
        "city_id": '53234',
        "address": "VIRGINIA",
        "metaDescription": "SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apartments in Alexandria, VA that are fully customizable come with 24/7 support & exclusive personalized meet & greet services. Call 800 367 9501 or Book today at www.suiteamerica.com",
        "metaTitle": "Corporate Housing Alexandria, VA  |Short Term Furnished Apartments Alexandria, VA | 800 367 9501",
        "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/va/alexandria",
        "cityInfo": "Luxurious Corporate Housing and Short-term Furnished Apartments in Alexandria, Virginia, where cobblestone streets, red brick sidewalks, and easy walkability make the city a beautiful destination for relocating or a brief stay. With small-town charm and big-city amenities, Alexandria perfectly blends its rich national history with stylish trends. Enjoy modern comfort food in one of the many restaurants along the waterfront, try not to scream during one of the nightly ghost tours, and fall in love in one of the most romantic cities in the country.",
        "locationName": "Corporate Housing Alexandria, VA",
        "latitude": "38.8051",
        "longitude": "-77.047",
        "tagLine":"Virginia is for Lovers / The Fun Side of the Potomac",
        "cityHighlights": [
            "“Virginia is for Lovers” / “The Fun Side of the Potomac”",
            "Named the #1 Best Value U.S. Travel Destination 2018 by Money magazine",
            "#14 on Southern Living’s list of  ‘The South’s Prettiest Cities 2018”",
            "Alexandria's Old Town district was established in 1946 as only the third historic district designated in the United States.",
            "Home to 112 city parks."
        ],
        "demoGraphics":[
            {'statistic':'Per Capita Income','value':'$93,370'},
            {'statistic':'Median Household Income','value':'$123,341'},
            {'statistic':'Population Density','value':'10,221 per square mile'},
            {'statistic':'Total Population ','value':'160.035'},
            {'statistic':'Average Age','value':'36.4'},
            {'statistic':'Square Miles','value':'15.5'},
            {'statistic':'Average Rent','value':'$1,709'}
        ]

    },
    "va-arlington": {
        "image": "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/Arlington-VA1.jpg",
        "title": "PREMIUM LOCATIONS",
        "subTitle": "WORLDWIDE",
        "city": "ARLINGTON",
        "state": "VA",
        "country": "US",
        "countryId": "2",
        "state_id": '62',
        "city_id": '53263',
        "address": "VIRGINIA",
        "metaDescription": "Suite America provides platinum-level solutions in Corporate Housing & Short Term Furnished Apartments in Arlington, VA. In Arlington, you can stay in a SuiteAmerica short term furnished apartment or corporate housing while exploring America’s revolutionary history.",
        "metaTitle": "Corporate Housing Arlington, VA | Short Term Furnished Apartments  Arlington, VA  | 800 367 9501",
        "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/va/arlington",
        "cityInfo": "While its neighbor, Washington D.C., might be more well-known, Arlington is also a central part of American history with many famous monuments, museums, and historical sites. Mostly home to government workers. Arlington, Virginia is a well-educated city that hosts annual food and drink festivals throughout the year. In Arlington, you can stay in a SuiteAmerica short-term furnished apartment or corporate housing while exploring America’s revolutionary history.",
        "locationName": "Corporate Housing Arlington, VA",
        "latitude": "38.890759",
        "longitude": "-77.084747",
         "tagLine":"Arlington: The Washington Connection",
        "cityHighlights": [
            "Just across the Potomac River from the Nation’s Capital",
            "The Intelligent Community Forum ranked Arlington as one of the Top 7 Intelligent Communities in the world.",
            "Arlington is the most stable high quality local government in the nation, with Triple-AAA bond rating and the lowest tax rate in the region.",
            "Ranked #1 Fittest City in the U.S by the American College of Sports Medicine’s American Fitness Index – 2018",
            "Ranked #3 in the Best Cities for Millennials in America by Niche.com – 2018",
            "Arlington Earns Nation’s First LEED For Communities Platinum Certification from U.S. Green Building Council – 2017",
            "#2 Best Cities For Recent College Grads per Livability.com "
        ],
        "demoGraphics":[
            {'statistic':'Per Capita Income','value':'$112,138'},
            {'statistic':'Median Household Income','value':'$145,577'},
            {'statistic':'Population Density','value':'8309 per square mile'},
            {'statistic':'Total Population ','value':'632,309'},
            {'statistic':'Average Age','value':'34.4'},
            {'statistic':'Square Miles','value':'26'},
            {'statistic':'Average Rent','value':'$2,161'}
        ]
    },
    "texas-austin": {
        "image": "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/Austin4.jpeg",
        "title": "PREMIUM LOCATIONS",
        "subTitle": "WORLDWIDE",
        "city": "AUSTIN",
        "state": "TEXAS",
        "country": "US",
        "countryId": "2",
        "state_id": '59',
        "city_id": '30528',
        "address": "TEXAS",
        "metaDescription": "SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apartments in Austin, Texas.  With SuiteAmerica’s corporate housing and short term furnished apartments, you can experience the Live Music Capital of the World no matter how long your stay.",
        "metaTitle": "Corporate Housing Austin, TX | Short Term Furnished Apartments  Austin, Texas | 800 367 9501",
        "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/texas/austin",
        "cityInfo": "Adventure awaits in Austin, Texas’ vibrant entertainment and food scene. With SuiteAmerica’s corporate housing and short-term furnished apartments, you can experience the Live Music Capital of the World no matter how long your stay. Plenty of outdoor activities, live music venues, film festivals, and the local University of Texas Longhorns athletic teams make Austin a fun city to live and play in.",
        "locationName": "Corporate Housing Austin, Texas",
        "latitude": "30.264980",
        "longitude": "-97.746600",
        "tagLine":"Live Music Capital of the World / Keep Austin Weird  / Silicon Hills",
        "cityHighlights": [
            "#1 on Forbes, “Cities of the Future”",
            "#2 on Forbes, “Best Cities and Neighborhoods for Milennials”",
            "#13 Best Foodie City in the U.S. per U.S. News",
            "#8 Best Nightlife Scenes in the U.S. per U.S. News",
            "Home to Zilker Metropolitan Park, LBJ’s library, Blanton Art Museum and Sixth Street."
        ],
        "demoGraphics":[
            {'statistic':'Per Capita Income','value':'$63,717'},
            {'statistic':'Median Household Income','value':'$91,811'},
            {'statistic':'Population Density','value':'3358 per square mile'},
            {'statistic':'Total Population ','value':'632,309'},
            {'statistic':'Average Age','value':'32.7'},
            {'statistic':'Square Miles','value':'145'},
            {'statistic':'Average Rent','value':'$1,364'}
        ]
    },
    "oregon-beaverton": {
        "image": "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/Beaverton1.jpg",
        "title": "PREMIUM LOCATIONS",
        "subTitle": "WORLDWIDE",
        "city": "BEAVERTON",
        "state": "OREGON",
        "country": "US",
        "countryId": "2",
        "state_id": '48',
        "city_id": '37989',
        "address": "OREGON",
        "metaDescription": "Suite America provides platinum-level solutions in Corporate Housing & Short Term Furnished Apartments in Beaverton, Oregon. Beaverton’s an ideal Pacific Northwest community with lush scenery, exceptional schools, and a high quality of life ranking.",
        "metaTitle": "Corporate Housing Beaverton, OR | Short Term Furnished Apartments  Beaverton, Oregon | 800 367 9501",
        "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/oregon/beaverton",
        "cityInfo": "Beaverton, Oregon has somehow remained a quiet Portland suburb located in the up-and-coming area known as Silicon Forest—the Pacific Northwest’s version of Silicon Valley. Enjoy this family-friendly city while staying in SuiteAmerica’s world-class corporate housing and short-term furnished apartments. Beaverton’s an ideal Pacific Northwest community with lush scenery, exceptional schools, and a high quality of life ranking.",
        "locationName": "Corporate Housing Beaverton, Oregon",
        "latitude": "45.486600",
        "longitude": "-122.795610",
        "tagLine":"The Best of Oregon",
        "cityHighlights": [
            "Over 100 parks encompassing 1,000 acres. A park is located within a half mile of every home, and the city features 30 miles of hiking trails and a 25-mile network of bike paths",
            "Ski slopes to the east and ocean beaches to the west provide additional opportunities for recreation",
            "The largest shopping district in west metropolitan Portland and dozens of churches serving all denominations"
        ],
        "demoGraphics":[
            {'statistic':'Per Capita Income','value':'$64,619'},
            {'statistic':'Median Household Income','value':'$82,039'},
            {'statistic':'Population Density','value':'4794 per square mile'},
            {'statistic':'Total Population ','value':'97,514'},
            {'statistic':'Average Age','value':'36.1'},
            {'statistic':'Square Miles','value':'18.3'},
            // {'statistic':'Average Rent','value':'$1,337'}
        ]
    },
    "ma-boston": {
        "image": "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/Boston.jpg",
        "title": "PREMIUM LOCATIONS",
        "subTitle": "WORLDWIDE",
        "city": "BOSTON",
        "state": "MASSACHUSETTS",
        "country": "US",
        "countryId": "2",
        "state_id": '25',
        "city_id": '47927',
        "address": "MASSACHUSETTS",
        "metaDescription": "SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apartments in Boston, MA that are fully customizable come with 24/7 support & exclusive personalized meet & greet services. Call 800 367 9501 or Book today at www.suiteamerica.com",
        "metaTitle": "Corporate Housing Boston, MA  | Short Term Furnished Apartments  Boston, MA | 800 367 9501",
        "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/ma/boston",
        "cityInfo": "Boston’s epic history has earned it a reputation for being a proud, patriotic city. Now an international center for higher education, the capital of Massachusetts is a world leader in entrepreneurship and sustainable businesses. Despite its high cost of living, SuiteAmerica’s short-term furnished apartments provide cost-effective corporate housing with platinum level service. Explore history in this classic American city that never strays too far from its rowdy patriotic beginnings.",
        "locationName": "Corporate Housing Boston, MA",
        "latitude": "42.358990",
        "longitude": "-71.058630",
        "tagLine":"Beantown / Titletown",
        "cityHighlights": [
            "#2 in Best Historic Destinations in the U.S. per U.S. News (2018).",
            "#11 Healthiest Cities per Centrum ",
            " #1 Best City to be a Sports Fan per Bleacher Report (2018).",
            " Home to Fenway Park, Freedom Trail, Boston Commons, Quincy Market and Museum of Fine Arts."
        ],
        "demoGraphics":[
            {'statistic':'Per Capita Income','value':'$62,021'},
            {'statistic':'Median Household Income','value':'$95,114'},
            {'statistic':'Population Density','value':'5471 per square mile'},
            {'statistic':'Total Population ','value':'685,0964'},
            {'statistic':'Average Age','value':'37'},
            {'statistic':'Square Miles','value':'90'},
            {'statistic':'Average Rent','value':'$1,396'}
        ]
    },
    "nc-charlotte": {
        "image": "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/Charlotte1.jpg",
        "title": "PREMIUM LOCATIONS",
        "subTitle": "WORLDWIDE",
        "city": "CHARLOTTE",
        "state": "NORTH CAROLINA",
        "country": "US",
        "countryId": "2",
        "state_id": '37',
        "city_id": '54960',
        "address": "NORTH CAROLINA",
        "metaDescription": "Suite America provides platinum-level solutions in Corporate Housing & Short Term Furnished Apartments in Charlotte, NC.  SuiteAmerica’s corporate housing and short term furnished apartments allow for a world class experience in the southern charm and cosmopolitan energy of Charlotte.",
        "metaTitle": "Corporate Housing Charlotte, NC  | Short Term Furnished Apartments  Charlotte, NC | 800 367 9501",
        "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/nc/charlotte",
        "cityInfo": "Charlotte’s thriving craft brewery scene, numerous outdoor trails, and professional sports teams offer plenty of fun. As the most populated city in North Carolina, Charlotte has become one of the fastest growing metropolitan areas in the country. SuiteAmerica’s corporate housing and short-term furnished apartments allow for a world-class experience in the southern charm and cosmopolitan energy of Charlotte.",
        "locationName": "Corporate Housing Charlotte, NC",
        "latitude": "35.223790",
        "longitude": "-80.841140",
        "tagLine":"The Queen City",
        "cityHighlights": [
            "Second largest banking center in the U.S.",
            " Home to the NASCAR Hall of Fame.",
            " #7 Best Places For Business and Careers per Forbes.",
            " #2 Fastest Growing Food and Drink Cities per Eventbrite.",
            " #9 Most Wildlife Friendly Cities per National Wildlife Federation."
        ],
        "demoGraphics":[
            {'statistic':'Per Capita Income','value':'$58,202'},
            {'statistic':'Median Household Income','value':' $87,225'},
            {'statistic':'Population Density','value':' 1083 per square mile'},
            {'statistic':'Total Population ','value':'859,035'},
            {'statistic':'Average Age','value':'33.9'},
            {'statistic':'Square Miles','value':'297'},
            {'statistic':'Average Rent','value':'$1,179'}
        ]
    },
    "il-chicago": {
        "image": "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/Chicago.jpg",
        "title": "PREMIUM LOCATIONS",
        "subTitle": "WORLDWIDE",
        "city": "CHICAGO",
        "state": "ILLINOIS",
        "country": "US",
        "countryId": "2",
        "state_id": '20',
        "city_id": '8487',
        "address": "ILLINOIS",
        "metaDescription": "SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apartments in Chicago, IL.  Its iconic skyline, deep dish pizza, and storied professional sports teams will keep you entertained throughout your stay in SuiteAmerica corporate housing or short term furnished apartments.",
        "metaTitle": "Corporate Housing Chicago, IL   | Short Term Furnished Apartments  Chicago, IL | 800 367 9501",
        "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/IL/chicago",
        "cityInfo": "Chicago’s thriving international trade and businesses make it the third-largest city in the United States. Its iconic skyline, deep-dish pizza, and storied professional sports teams will keep you entertained throughout your stay in SuiteAmerica’s corporate housing or short-term furnished apartments. Visit The Bean, get to know Abe Lincoln’s hometown, and enjoy your time in this global city.",
        "locationName": "Corporate Housing Chicago, IL",
        "latitude": "41.883230",
        "longitude": "-87.632400",
        "tagLine":"Second to None",
        "cityHighlights": [
            " Restaurant City of the Year 2017 per Bon Appetit.",
            " Named top metro area in the nation for corporate relocation and investment for a fifth consecutive year per Site Selection.",
            " Named as the nation’s top tech hotspot for the future per Modis.",
            " Millenium Park named the #1 attraction in the Midwest, and is among the top 10 most-visited sites in the U.S.",
            " Home to the Art Institute of Chicago, Wrigley Field, and Chicago Riverwalk."
        ],
        "demoGraphics":[
            {'statistic':'Per Capita Income','value':'$52,497'},
            {'statistic':'Median Household Income','value':'$81,061'},
            {'statistic':'Population Density','value':'11095 per square mile'},
            {'statistic':'Total Population ','value':'2,716,450'},
            {'statistic':'Average Age','value':'34'},
            {'statistic':'Square Miles','value':'234'},
            {'statistic':'Average Rent','value':'$1,893'}
        ]
    },
    "ca-cupertino": {
        "image": "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/Cupertino.jpeg",
        "title": "PREMIUM LOCATIONS",
        "subTitle": "WORLDWIDE",
        "city": "CUPERTINO",
        "state": "CALIFORNIA",
        "country": "US",
        "countryId": "2",
        "state_id": '8',
        "city_id": '23900',
        "address": "CALIFORNIA",
        "metaDescription": "Suite America provides platinum-level solutions in Corporate Housing & Short Term Furnished Apartments in Cupertino, CA.  Experience one of the most well-known and family-friendly cities in Silicon Valley while staying in SuiteAmerica’s corporate housing and short term apartments in Cupertino.",
        "metaTitle": "Corporate Housing Cupertino, CA  | Short Term Furnished Apartments  Cupertino, CA | 800 367 9501",
        "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/ca/cupertino",
        "cityInfo": "With its moderate size, a unique mix of technology firms, retail centers, parks, quality schools, and upscale residential areas, Cupertino and its innovative spirit attracts a diverse array of entrepreneurs and visionaries to California. Experience one of the most well-known and family-friendly cities in Silicon Valley while staying in SuiteAmerica’s corporate housing and short-term apartments in Cupertino.",
        "locationName": "Corporate Housing Cupertino, CA",
        "latitude": "37.3230",
        "longitude": "-122.0322",
        "tagLine":"An Educated City",
        "cityHighlights": [
            " #5 on America’s Top 25 Towns to Live Well per Forbes.",
            " Ranks top 10 for sole proprietors and small businesses per capita.",
            " Home to Rancho San Antonio County Park, Ridge Vineyards, and Apple Inc. HQ."
        ],
        "demoGraphics":[
            {'statistic':'Per Capita Income','value':'$153,449'},
            {'statistic':'Median Household Income','value':'$181,767'},
            {'statistic':'Population Density','value':' 5179 per square mile'},
            {'statistic':'Total Population ','value':'60,777'},
            {'statistic':'Average Age','value':'41'},
            {'statistic':'Square Miles','value':'11.31'},
            // {'statistic':'Average Rent','value':'$3,370'}
        ]
    },
    "texas-dallas": {
        "image": "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/Dallas.jpg",
        "title": "PREMIUM LOCATIONS",
        "subTitle": "WORLDWIDE",
        "city": "DALLAS",
        "state": "TEXAS",
        "country": "US",
        "countryId": "2",
        "state_id": '59',
        "city_id": '31060',
        "address": "TEXAS",
        "metaDescription": "SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apartments in Dallas, TX.   Dallas is the place where cowboy hats meet cosmopolitan. While the city might be known as a place for dramatic TV legacies, oil, and American football, it’s also become a more urban city committed to expanding its upscale retail centers and neighborhoods.",
        "metaTitle": "Corporate Housing Dallas, TX | Short Term Furnished Apartments  Dallas, TX  | 800 367 9501",
        "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/texas/dallas",
        "cityInfo": "Still home to American Cowboys, Dallas reflects Texas’ reputation as a larger-than-life place in American culture. Dallas is the place where cowboy hats meet cosmopolitan. While the city might be known as a place for dramatic TV legacies, oil, and American football, it’s also become a more urban city committed to expanding its upscale retail centers and neighborhoods.",
        "locationName": "Corporate Housing Dallas, TX",
        "latitude": "32.777981",
        "longitude": "-96.796211",
        "tagLine":"Big Things Happen Here",
        "cityHighlights": [
            "#10 Best Places For Business And Careers per Forbes.",
            " #4 Best Cities to Relocate to in America per BestPlaces.",
            " #11 Most High-Tech Cities in the World per Business Insider.",
            " #16 Most Exciting Food Cities in America per Zagat.",
            "Home to Dallas Arboretum & Botanical Gardens, The Sixth Floor JFK Museum, Perot Museum of Nature and Science, and Klyde Warren Park."
        ],
        "demoGraphics":[
            {'statistic':'Per Capita Income','value':'$47,285'},
            {'statistic':'Median Household Income','value':'$75,925'},
            {'statistic':'Population Density','value':' 3944 per square mile'},
            {'statistic':'Total Population ','value':'1,341,075 '},
            {'statistic':'Average Age','value':'32'},
            {'statistic':'Square Miles','value':'385'},
            {'statistic':'Average Rent','value':'$1,190'}
        ]
    },
    "nc-durham": {
        "image": "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/Durham2.jpg",
        "title": "PREMIUM LOCATIONS",
        "subTitle": "WORLDWIDE",
        "city": "DURHAM",
        "state": "NORTH CAROLINA",
        "country": "US",
        "countryId": "2",
        "state_id": '37',
        "city_id": '55098',
        "address": "NORTH CAROLINA",
        "metaDescription": "Suite America provides platinum-level solutions in Corporate Housing & Short Term Furnished Apartments in Durham, NC. Get to know your neighbors, and everyone else, while staying in SuiteAmerica’s corporate housing and short term furnished apartments in the relaxed and beautiful city of Durham.",
        "metaTitle": "Corporate Housing Durham, NC  | Short Term Furnished Apartments  Durham, NC | 800 367 9501",
        "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/nc/durham",
        "cityInfo": "Located in what’s called The Triangle of popular North Carolina cities, Durham may not have the name recognition of its neighbors, but it’s still home to great family-friendly neighborhoods, delicious restaurants, and local college rivalries. Get to know your neighbors, and everyone else, while staying in SuiteAmerica’s corporate housing and short-term furnished apartments in the relaxed and beautiful city of Durham.",
        "locationName": "Corporate Housing Durham, NC",
        "latitude": "35.994",
        "longitude": "-78.8986",
        "tagLine":"Bull City / A County With MERIT (Medicine, Education, Research, Industry, and Technology)",
        "cityHighlights": [
            "“Bull City” / “A County With MERIT (Medicine, Education, Research, Industry, and Technology”",
            "#16 ranked city in the U.S. with the biggest influx of people, the most work opportunities, and the hottest business growth per Business Insider.", "  #9 Best Educated per Forbes.",
            " Home to Duke Gardens, Eno River State Park, Museum of Life and Science, and American Tobacco Trail."
        ],
        "demoGraphics":[
            {'statistic':'Per Capita Income','value':'$54,284'},
            {'statistic':'Median Household Income','value':'$77,357'},
            {'statistic':'Population Density','value':' 2426 per square mile'},
            {'statistic':'Total Population ','value':'267,743'},
            {'statistic':'Average Age','value':'33.6'},
            {'statistic':'Square Miles','value':'108'},
            {'statistic':'Average Rent','value':'$1,126'}
        ]
    },
    "ca-fostercity": {
        "image": "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/Foster+city.jpeg",
        "title": "PREMIUM LOCATIONS",
        "subTitle": "WORLDWIDE",
        "city": "FOSTER CITY",
        "state": "CALIFORNIA",
        "country": "US",
        "countryId": "2",
        "state_id": '8',
        "city_id": '24132',
        "address": "CALIFORNIA",
        "metaDescription": "SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apartments in Foster City, CA. This small suburb is a great escape from the hustle and bustle of larger surrounding cities, and with world class amenities and an unrivaled level of service, you’ll feel truly at home in Foster City while staying in SuiteAmerica corporate housing or short term furnished apartments.",
        "metaTitle": "Corporate Housing Foster City, CA  | Short Term Furnished Apartments  Foster City, CA | 800 367 9501",
        "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/ca/fostercity",
        "cityInfo": "Foster City, California is considered one of the safest cities in the United States. Located in Silicon Valley, SuiteAmerica’s corporate housing and short-term furnished apartments allow you to enjoy Foster City’s 200 parks, tennis courts, baseball and soccer fields, and bike trails along the San Francisco Bay. This small suburb is a great escape from the hustle and bustle of larger surrounding cities, and with world-class amenities and an unrivaled level of service, you’ll feel truly at home in Foster City while staying in SuiteAmerica corporate housing or short-term furnished apartments.",
        "locationName": "Corporate Housing Foster City, CA",
        "latitude": "37.5585",
        "longitude": "-122.2711",
        "tagLine":"The Safe City",
        "cityHighlights": [
            "#10 on America’s Top 25 Towns to Live Well per Forbes.",
            " Ranks top 5 nationally for number of patents per capita.",
            " Home to Leo J. Ryan Park, Boothbay Park, Bay Meadows and Sea Cloud Park."
        ],
        "demoGraphics":[
            {'statistic':'Per Capita Income','value':'$136,383'},
            {'statistic':'Median Household Income','value':'$166,947'},
            {'statistic':'Population Density','value':' 8685 per square mile'},
            {'statistic':'Total Population ','value':'34,412'},
            {'statistic':'Average Age','value':'40.4'},
            {'statistic':'Square Miles','value':'19.84'},
            // {'statistic':'Average Rent','value':'$3,304'}
        ]
    },
    "england-london": {
        "image": "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/city-images/London-iStock-689936034.jpg",
        "title": "PREMIUM LOCATIONS",
        "subTitle": "WORLDWIDE",
        "city": "LONDON",
        "state": "ENGLAND",
        "country": "UK",
        "countryId": "118",
        "state_id": '74',
        "city_id": '60611',
        "address": "England",
        "metaDescription": "Suite America provides platinum-level solutions in Corporate Housing & Short Term Furnished Apartments in London, England. As the capital of England and the United Kingdom, London’s history dates back centuries with popular landmarks that won’t disappoint.  From West Minister Abbey to the House of Parliament and Big Ben, everywhere you turn, you’ll recognize parts of the city.",
        "metaTitle": "Corporate Housing London, England | Short Term Furnished Apartments  London, England | 800 367 9501",
        "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/england/london",
        "cityInfo": "As the capital of England and the United Kingdom, London’s history dates back centuries with popular landmarks that won’t disappoint. From West Minister Abbey to the House of Parliament and Big Ben, everywhere you turn, you’ll recognize parts of the city. Take a photo in a classic red phone booth, grab a pint, and learn more about real football. SuiteAmerica’s corporate housing and short term furnished apartments in London allow you to enjoy the pubs and free museums at cost effective rates. Cheers!        ",
        "locationName": "Corporate Housing London, England",
        "latitude": "51.500150",
        "longitude": "-0.126240",
        "tagLine":"The Smoke",
        "cityHighlights": [
            "#2 Most Influential City in the World per Business Insider.",
            "#1 Business Community in the UK per Royal Mail.",
            "Home to Big Ben, The London Eye, Buckingham Palace, and Hyde Park."
        ],
        "demoGraphics":[
            {'statistic':'Per Capita Income','value':'$85,335'},
            {'statistic':'Median Household Income','value':'$123,426'},
            {'statistic':'Population Density','value':'3900 per square mile'},
            {'statistic':'Total Population ','value':'9,176,530'},
            {'statistic':'Average Age','value':'37'},
            {'statistic':'Square Miles','value':'607'},
            {'statistic':'Average Rent','value':'$3,226'}
        ]
    },
    "ca-losangeles": {
        "image": "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/LA.jpg",
        "title": "PREMIUM LOCATIONS",
        "subTitle": "WORLDWIDE",
        "city": "LOS ANGELES",
        "state": "CALIFORNIA",
        "country": "US",
        "countryId": "2",
        "state_id": '8',
        "city_id": '24584',
        "address": "CALIFORNIA",
        "metaDescription": "SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apartments in  Los Angeles, CA. . Get ready for surfing, trendy health foods, and a whole lot of sunshine. L.A. moves fast—unless you’re on the freeway—so SuiteAmerica’s corporate housing and short term furnished apartments provide world class amenities with an unrivaled level of service for a relaxing and seamless relocation or temporary stay.",
        "metaTitle": "Corporate Housing Los Angeles, CA  | Short Term Furnished Apartments  Los Angeles, CA | 800 367 9501",
        "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/ca/losangeles",
        "cityInfo": "Perhaps the epitome of Southern California culture, Los Angeles bustles with urban energy and California’s classic laidback atmosphere. Get ready for surfing, trendy health foods, and a whole lot of sunshine. L.A. moves fast—unless you’re on the freeway—so SuiteAmerica’s corporate housing and short-term furnished apartments provide world-class amenities with an unrivaled level of service for a relaxing and seamless relocation or temporary stay.",
        "locationName": "Corporate Housing Los Angeles, CA",
        "latitude": "34.052240",
        "longitude": "-118.243340",
        "tagLine":"City of Angels / La-La Land / The Entertainment Capital of the World",
        "cityHighlights": [
            "Largest city in terms of land area.",
            " Highest rate of income growth in the country.",
            " Best access to transit systems.",
            " Boasts a GDP larger than Switzerland, Sweden and Saudi Arabia.",
            " 329 days of sunshine a year.",
            "Home to Venice Beach, Hollywood Bowl, Rodeo Drive and the Griffith Observatory and Griffith Park."
        ],
        "demoGraphics":[
            {'statistic':'Per Capita Income','value':'$54,501'},
            {'statistic':'Median Household Income','value':'$86,758'},
            {'statistic':'Population Density','value':'7544 per square mile'},
            {'statistic':'Total Population ','value':'3,999,759'},
            {'statistic':'Average Age','value':'35'},
            {'statistic':'Square Miles','value':'503'},
            // {'statistic':'Average Rent','value':'$2,371'}
        ]
    },
    "oregon-portland": {
        "image": "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/Portland2.jpg",
        "title": "PREMIUM LOCATIONS",
        "subTitle": "WORLDWIDE",
        "city": "PORTLAND",
        "state": "OREGON",
        "country": "US",
        "countryId": "2",
        "state_id": '48',
        "city_id": '38357',
        "address": "OREGON",
        "metaDescription": "Suite America provides platinum-level solutions in Corporate Housing & Short Term Furnished Apartments in  Portland, OR.  Stay in SuiteAmerica’s corporate housing and short term furnished apartments in Portland to experience the laidback and offbeat city that prides itself on sustainability, friendliness, and being different.",
        "metaTitle": "Corporate Housing Portland, OR  | Short Term Furnished Apartments  Portland, OR  | 800 367 9501",
        "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/oregon/portland",
        "cityInfo": "Portland, Oregon is a unique city that loves microbreweries, soccer, and rain. They also like to keep things a little weird. Stay in SuiteAmerica’s corporate housing and short-term furnished apartments in Portland to experience the laidback and offbeat city that prides itself on sustainability, friendliness, and being different.",
        "locationName": "Corporate Housing Portland, OR",
        "latitude": "45.5155",
        "longitude": "-122.6793",
        "tagLine":"Keep Portland Weird",
        "cityHighlights": [
            "  #1 for The Best Places For Business And Careers per Forbes (2018).",
            "  #9 Greenest City in America per WalletHub (2017).",
            " #1 Best Food City in America per the Washington Post (2015).",
            "Celebrated microbrewery and coffee scenes.",
            " Home to the Oregon Zoo, the Portland Japanese Garden and the World Forestry Center."
        ],
        "demoGraphics":[
            {'statistic':'Per Capita Income','value':'$61,532'},
            {'statistic':'Median Household Income','value':'$85,335'},
            {'statistic':'Population Density','value':'4740 per square mile'},
            {'statistic':'Total Population ','value':'647,805'},
            {'statistic':'Average Age','value':'36.8'},
            {'statistic':'Square Miles','value':'145'},
            // {'statistic':'Average Rent','value':'$1,476'}
        ]
    },
    "nc-raleigh": {
        "image": "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/Raleigh1.jpg",
        "title": "PREMIUM LOCATIONS",
        "subTitle": "WORLDWIDE",
        "city": "RALEIGH",
        "state": "NORTH CAROLINA",
        "country": "US",
        "countryId": "2",
        "state_id": '37',
        "city_id": '55849',
        "address": "NORTH CAROLINA",
        "metaDescription": "Suite America provides platinum-level solutions in Corporate Housing & Short Term Furnished Apartments in  Portland, OR.  Stay in SuiteAmerica’s corporate housing and short term furnished apartments in Portland to experience the laidback and offbeat city that prides itself on sustainability, friendliness, and being different.",
        "metaTitle": "Corporate Housing Raleigh, NC  | Short Term Furnished Apartments  Raleigh, NC  | 800 367 9501",
        "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/nc/raleigh",
        "cityInfo": "North Carolina’s capital, Raleigh, sits near several nationally recognized universities, making it a great place for families and young professionals to work and then catch the Tar Heel game at happy hour. Historical neighborhoods with older-style homes, tree-lined streets, and well-ranked walkability make Raleigh an ideal city for those looking for a place with character, a relaxed atmosphere, and great summer music festivals. SuiteAmerica’s corporate housing and short-term furnished apartments in Raleigh allow you to enjoy this relaxed, picturesque city.",
        "locationName": "Corporate Housing Raleigh, NC",
        "latitude": "35.781300",
        "longitude": "-78.641680",
        "tagLine":"Southern Capital of Arts and Culture",
        "cityHighlights": [
            " #2 Best Places For Business And Careers (Forbes 2018)",
            " #2 Most Educated City in the U.S. per Forbes (2017)",
            " #18 Best Cities to live in America per Niche (2018)"
        ],
        "demoGraphics":[
            {'statistic':'Per Capita Income','value':'$61,505'},
            {'statistic':'Median Household Income','value':'$86,374'},
            {'statistic':'Population Density','value':'3203 per square mile'},
            {'statistic':'Total Population ','value':'464,758'},
            {'statistic':'Average Age','value':'33'},
            {'statistic':'Square Miles','value':'144'},
            {'statistic':'Average Rent','value':'$1,160'}
        ]
    },
    "ca-redwoodcity": {
        "image": "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/Redwoodcity.jpeg",
        "title": "PREMIUM LOCATIONS",
        "subTitle": "WORLDWIDE",
        "city": "REDWOOD CITY",
        "state": "CALIFORNIA",
        "country": "US",
        "countryId": "2",
        "state_id": '8',
        "city_id": '25127',
        "address": "CALIFORNIA",
        "metaDescription": "Suite America provides platinum-level solutions in Corporate Housing & Short Term Furnished Apartments in Redwood City, CA.  With an up and coming downtown area that boasts a vibrant and diverse food scene, Redwood City is the ideal place if you want to experience all that both San Francisco and the Silicon Valley have to offer.",
        "metaTitle": "Corporate Housing Redwood City, CA  | Short Term Furnished Apartments  Redwood City, CA | 800 367 9501",
        "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/ca/redwoodcity",
        "cityInfo": "Nestled between San Francisco and San Jose is Redwood City, California. SuiteAmerica’s corporate housing and short-term furnished apartments in Redwood City allow you to enjoy what is quickly becoming the entertainment hub of the San Francisco Peninsula. With an up-and-coming downtown area that boasts a vibrant and diverse food scene, Redwood City is the ideal place if you want to experience all that both San Francisco and Silicon Valley have to offer.",
        "locationName": "Corporate Housing Redwood City, CA",
        "latitude": "37.484780",
        "longitude": "-122.228150",
        "tagLine":"Climate Best By Government Test / Redwood City",
        "cityHighlights": [
            "#65 Best Place to Live in 2018 per Livability.com",
            " #2 Best Cities for Jobs for Millenials per Forbes.",
            " #1 on Cities That are Winning the Battle for Information Jobs per Forbes. ",
            " Not one, but two downtowns.",
            " Home to Purisma Creek Redwoods Preserve, Huddart Park, Hiller Aviation Museum, and Pulgas Water Temple."
        ],
        "demoGraphics":[
            {'statistic':'Per Capita Income','value':'$99,026'},
            {'statistic':'Median Household Income','value':'$135,253'},
            {'statistic':'Population Density','value':'3955 per square mile'},
            {'statistic':'Total Population ','value':'86,685'},
            {'statistic':'Average Age','value':'37'},
            {'statistic':'Square Miles','value':'34'},
            // {'statistic':'Average Rent','value':'$3,336'}
        ]
    },
    "ca-sandiego": {
        "image": "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/San+Diego.jpg",
        "title": "PREMIUM LOCATIONS",
        "subTitle": "WORLDWIDE",
        "city": "SAN DIEGO",
        "state": "CALIFORNIA",
        "country": "US",
        "countryId": "2",
        "state_id": '8',
        "city_id": '25240',
        "address": "CALIFORNIA",
        "metaDescription": "SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apartments in  San Diego, CA. In San Diego, California, every day is a beach day.  Pack your sunglasses and some sunscreen because you’ll be busy enjoying the sunshine while staying in SuiteAmerica’s corporate housing and short term furnished apartments. Tacos, good vibes, and street parking await.",
        "metaTitle": "Corporate Housing San Diego, CA  | Short Term Furnished Apartments  San Diego, CA | 800 367 9501",
        "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/ca/sandiego",
        "cityInfo": "In San Diego, California, every day is a beach day. A beautiful, laidback city, San Diego knows how to make the most of its beach fronts. Pack your sunglasses and some sunscreen because you’ll be busy enjoying the sunshine while staying in SuiteAmerica’s corporate housing and short-term furnished apartments. Tacos, good vibes, and street parking await.",
        "locationName": "Corporate Housing San Diego, CA",
        "latitude": "32.715760",
        "longitude": "-117.163820",
        "tagLine":"America’s Finest City",
        "cityHighlights": [
            " #5 Best Large City to Live in per WalletHub (2018).",
            " Home to Balboa Park, SeaWorld, and Museum of Contemporary Art San Diego.",
            "Home to largest naval fleet in the world.",
            " Annual host to Comic-Con."
        ],
        "demoGraphics":[
            {'statistic':'Per Capita Income','value':'$71,535'},
            {'statistic':'Median Household Income','value':'$98,632'},
            {'statistic':'Population Density','value':'4371 per square mile'},
            {'statistic':'Total Population ','value':'1,419,516'},
            {'statistic':'Average Age','value':'34'},
            {'statistic':'Square Miles','value':'372'},
            // {'statistic':'Average Rent','value':'$2,168'}
        ]
    },
    "ca-sanfrancisco": {
        "image": "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/Sanfrancisco.jpg",
        "title": "PREMIUM LOCATIONS",
        "subTitle": "WORLDWIDE",
        "city": "SAN FRANCISCO",
        "state": "CALIFORNIA",
        "country": "US",
        "countryId": "2",
        "state_id": '8',
        "city_id": '25247',
        "address": "CALIFORNIA",
        "metaDescription": "Suite America provides platinum-level solutions in Corporate Housing & Short Term Furnished Apartments in  San Francisco, CA. The streets might be a little steep, but every turn and slope in San Francisco provides a breathtaking view of the city and Pacific Ocean. Catch a cable car while exploring diverse, exciting San Francisco. When staying in SuiteAmerica’s corporate housing and short term furnished apartments, your time in S.F. is sure to be golden.",
        "metaTitle": "Corporate Housing San Francisco, CA  | Short Term Furnished Apartments  San Francisco, CA  | 800 367 9501",
        "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/ca/sanfrancisco",
        "cityInfo": "The streets might be a little steep, but every turn and slope in San Francisco provides a breathtaking view of the city and the Pacific Ocean. Catch a cable car while exploring diverse, exciting San Francisco. When staying in SuiteAmerica’s corporate housing and short-term furnished apartments, your time in S.F. is sure to be golden.",
        "locationName": "Corporate Housing San Francisco, CA",
        "latitude": "37.780080",
        "longitude": "-122.420170",
        "tagLine":"The City By The Bay / The Golden City",
        "cityHighlights": [
            "#3 in Job Growth Rank per Forbes (2018).",
            " #4 in Education per Forbes (2018).",
            " #1 Quality of Life in U.S. per Mercer.",
            " Home to the Golden Gate Bridge, Alcatraz Island, Alamo Square and Fisherman’s Wharf.",
            " At any given time there are more than 3,500 restaurants open."
        ],
        "demoGraphics":[
            {'statistic':'Per Capita Income','value':'$96,265'},
            {'statistic':'Median Household Income','value':'$137,761'},
            {'statistic':'Population Density','value':'18679 per square mile'},
            {'statistic':'Total Population ','value':'884,363'},
            {'statistic':'Average Age','value':'38'},
            {'statistic':'Square Miles','value':'46'},
            // {'statistic':'Average Rent','value':'$3,609'}
        ]
    },
    "ca-sanjose": {
        "image": "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/San+Jose.jpg",
        "title": "PREMIUM LOCATIONS",
        "subTitle": "WORLDWIDE",
        "city": "SAN JOSE",
        "state": "CALIFORNIA",
        "country": "US",
        "countryId": "2",
        "state_id": '8',
        "city_id": '25254',
        "address": "CALIFORNIA",
        "metaDescription": "SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apartments in San Jose, CA.  SuiteAmerica’s corporate housing and short term furnished apartments in San Jose provide world class amenities, making your relocation or temporary stay a luxurious experience. Enjoy the innovative, bright, and active lifestyle of Silicon Valley’s central hub.",
        "metaTitle": "Corporate Housing San Jose, CA  | Short Term Furnished Apartments  San Jose, CA  | 800 367 9501",
        "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/ca/sanjose",
        "cityInfo": "San Jose’s immense growth over the last couple of decades has turned it into an urban California city with a diverse population and innovative spirit. SuiteAmerica’s corporate housing and short-term furnished apartments in San Jose provide world-class amenities, making your relocation or temporary stay a luxurious experience. Enjoy the innovative, bright, and active lifestyle of Silicon Valley’s central hub.",
        "locationName": "Corporate Housing San Jose, CA",
        "latitude": "37.3382",
        "longitude": "-121.8863",
        "tagLine":"The Capital of Silicon Valley",
        "cityHighlights": [
            "Most ‘Future Ready’ City in the U.S. per Dell (2016).",
            " #3 Most Educated City in the U.S. per Forbes (2016).",
            " #2 Best City in America for Young Professionals per Forbes (2016).",
            " Home to Santana Row, Japanese Friendship Garden, Winchester Mystery House and San Jose Museum of Art."
        ],
        "demoGraphics":[
            {'statistic':'Per Capita Income','value':'$96,662'},
            {'statistic':'Median Household Income','value':'$124,356'},
            {'statistic':'Population Density','value':'5795 per square mile'},
            {'statistic':'Total Population ','value':'1,035,317'},
            {'statistic':'Average Age','value':'36'},
            {'statistic':'Square Miles','value':'180'},
            // {'statistic':'Average Rent','value':'$3,730'}
        ]
    },
    "ca-santaclara": {
        "image": "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/Santa+clara.jpg",
        "title": "PREMIUM LOCATIONS",
        "subTitle": "WORLDWIDE",
        "city": "SANTA CLARA",
        "state": "CALIFORNIA",
        "country": "US",
        "countryId": "2",
        "state_id": '8',
        "city_id": '25289',
        "address": "CALIFORNIA",
        "metaDescription": "Suite America provides platinum-level solutions in Corporate Housing & Short Term Furnished Apartments in Santa Clara, CA.  While in Santa Clara, you can learn exactly how the Silicon Valley got its name, see a San Francisco 49er game, and enjoy thrill rides at GreatAmerica. Experience Silicon Valley’s entertainment capital while staying in SuiteAmerica’s corporate housing and short term furnished apartments.",
        "metaTitle": "Corporate Housing Santa Clara, CA  | Short Term Furnished Apartments  Santa Clara, CA | 800 367 9501",
        "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/ca/santaclara",
        "cityInfo": "Located in Silicon Valley, Santa Clara is an innovative California city that has expanded significantly in the 21st century. While in Santa Clara, you can learn exactly how the Silicon Valley got its name, see a San Francisco 49er game, and enjoy thrill rides at GreatAmerica. Experience Silicon Valley’s entertainment capital while staying in SuiteAmerica’s corporate housing and short-term furnished apartments.",
        "locationName": "Corporate Housing Santa Clara, CA",
        "latitude": "37.3541",
        "longitude": "-121.9552",
        "tagLine":"The Mission City",
        "cityHighlights": [
            "#14 Best Cities for Milennials in America per Niche (2018).",
            " #7 Best Cities for Tech Jobs per Forbes.",
            " Home to Mission Santa Clara, Levi’s Stadium, Triton Museum of Art, and California’s Great America."
        ],
        "demoGraphics":[
            {'statistic':'Per Capita Income','value':'$108,609'},
            {'statistic':'Median Household Income','value':'$129,755'},
            {'statistic':'Population Density','value':'6327 per square mile'},
            {'statistic':'Total Population ','value':'127,134'},
            {'statistic':'Average Age','value':'34'},
            {'statistic':'Square Miles','value':'18'},
            // {'statistic':'Average Rent','value':'$3,890'}
        ]
    },
    "ca-santamonica": {
        "image": "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/Santa+Monica.jpg",
        "title": "PREMIUM LOCATIONS",
        "subTitle": "WORLDWIDE",
        "city": "SANTA MONICA",
        "state": "CALIFORNIA",
        "country": "US",
        "countryId": "2",
        "state_id": '8',
        "city_id": '25298',
        "address": "CALIFORNIA",
        "metaDescription": "SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apartments in Santa Monica, CA. You’ll recognize this California city by its famous pier and palm tree lined streets. The Pacific Coast Highway will become a regular part of your drive while staying in SuiteAmerica corporate housing and short term furnished apartments in Santa Monica. Enjoy this gorgeous, trendy city with a beach town vibe.",
        "metaTitle": "Corporate Housing Santa Monica, CA  | Short Term Furnished Apartments  Santa Monica, CA  | 800 367 9501",
        "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/ca/santamonica",
        "cityInfo": "Santa Monica’s sandy beaches, blue water, and pastel sunsets are enchanting. You’ll recognize this California city by its famous pier and palm tree-lined streets. The Pacific Coast Highway will become a regular part of your drive while staying in SuiteAmerica’s corporate housing and short-term furnished apartments in Santa Monica. Enjoy this gorgeous, trendy city with a beach town vibe.",
        "locationName": "Corporate Housing Santa Monica, CA",
        "latitude": "34.010930",
        "longitude": "-118.491510",
        "tagLine":"California’s Most Celebrated Beach City",
        "cityHighlights": [
            " #6 Best Places to Live in California per Niche (2018).",
            "#5 Best Cities for Recent College Grads per Livability.com",
            "  Home to Palisades Park, Santa Monica State Beach, Santa Monica Pier, Third Street Promenade and Tongva Park. "
        ],
        "demoGraphics":[
            {'statistic':'Per Capita Income','value':'$85,335'},
            {'statistic':'Median Household Income','value':'$123,426'},
            {'statistic':'Population Density','value':' 10989 per square mile'},
            {'statistic':'Total Population ','value':'632,309'},
            {'statistic':'Average Age','value':'37'},
            {'statistic':'Square Miles','value':'18'},
            // {'statistic':'Average Rent','value':'$3,703'}
        ]
    },
    "wa-seattle": {
        "image": "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/Seattle1.jpg",
        "title": "PREMIUM LOCATIONS",
        "subTitle": "WORLDWIDE",
        "city": "SEATTLE",
        "state": "WASHINGTON",
        "country": "US",
        "countryId": "2",
        "state_id": '65',
        "city_id": '15274',
        "address": "WASHINGTON",
        "metaDescription": "Suite America provides platinum-level solutions in Corporate Housing & Short Term Furnished Apartments in Seattle, WA.  With plenty of rain and coffee, it might seem like there’s not much else the Emerald City has to offer, but famous tourist spots like the Space Needle and Pike’s Place will keep you plenty busy. Enjoy Seattle’s fresh seafood, live music venues, and professional sports teams while staying in SuiteAmerica’s corporate housing and short term furnished apartments.",
        "metaTitle": "Corporate Housing Seattle, WA  | Short Term Furnished Apartments  Seattle, WA | 800 367 9501",
        "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/wa/seattle",
        "cityInfo": "Seattle’s proved to be an up-and-coming city for young professionals and tech companies alike. With plenty of rain and coffee, it might seem like there’s not much else the Emerald City has to offer, but famous tourist spots like the Space Needle and Pike’s Place will keep you plenty busy. Enjoy Seattle’s fresh seafood, live music venues, and professional sports teams while staying in SuiteAmerica’s corporate housing and short-term furnished apartments.",
        "locationName": "Corporate Housing Seattle, WA",
        "latitude": "47.603230",
        "longitude": "-122.330280",
        "tagLine":"The Emerald City / The City of Goodwill",
        "cityHighlights": [
            " #3 Best Places For Business And Careers (Forbes 2018)",
            " #8 Best Foodie Cities in the U.S. per U.S. News (2018)",
            " Home to the Space Needle, Museum of Pop Culture, Pacific Science Center, and Pike Place Market.",
            " Boasts thriving coffee and arts scenes."
        ],
        "demoGraphics":[
            {'statistic':'Per Capita Income','value':'$79,565'},
            {'statistic':'Median Household Income','value':'$111,232'},
            {'statistic':'Population Density','value':'7962 per square mile'},
            {'statistic':'Total Population ','value':'724,745'},
            {'statistic':'Average Age','value':'35'},
            {'statistic':'Square Miles','value':'83'},
            // {'statistic':'Average Rent','value':'$1,965'}
        ]
    },
    "ca-sunnyvale": {
        "image": "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/Sunnyvale4.jpeg",
        "title": "PREMIUM LOCATIONS",
        "subTitle": "WORLDWIDE",
        "city": "SUNNYVALE",
        "state": "CALIFORNIA",
        "country": "US",
        "countryId": "2",
        "state_id": '8',
        "city_id": '25474',
        "address": "CALIFORNIA",
        "metaDescription": "SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apartments in Sunnyvale, CA. Sunnyvale’s exceptional schools, parks, and neighborhoods make it a family-friendly city perfect for relocating or a temporary assignment. Enjoy Sunnyvale’s world class amenities while staying in SuiteAmerica corporate housing and short term furnished apartments.",
        "metaTitle": "Corporate Housing Sunnyvale, CA  | Short Term Furnished Apartments  Sunnyvale, CA | 800 367 9501",
        "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/ca/sunnyvale",
        "cityInfo": "Luxurious corporate housing and short-term furnished apartments in Sunnyvale, California, where Silicon Valley is at its best. Sunnyvale’s exceptional schools, parks, and neighborhoods make it a family-friendly city perfect for relocating or a temporary assignment. Enjoy Sunnyvale’s world-class amenities while staying in SuiteAmerica’s corporate housing and short-term furnished apartments.",
        "locationName": "Corporate Housing Sunnyvale, CA",
        "latitude": "37.3688",
        "longitude": "-122.0363",
        "tagLine":"The Heart of Silicon Valley",
        "cityHighlights": [
            "America's #1 Safest City, SmartAsset 2016, 2017",
            " #4 of Cities Creating the Most Technology Jobs (San Jose-Sunnyvale-Santa Clara), Forbes 2017",
            " Ranked 16th in Nation, Best Cities to Raise a Family, Niche 2017",
            " Home to Las Palmas Park, Computer History Museum, Shoreline Amphitheater and Flint Center for the Performing Arts."
        ],
        "demoGraphics":[
            {'statistic':'Per Capita Income','value':'$118,314'},
            {'statistic':'Median Household Income','value':' $151,042'},
            {'statistic':'Population Density','value':'6173 per square mile'},
            {'statistic':'Total Population ','value':'153,656'},
            {'statistic':'Average Age','value':'35'},
            {'statistic':'Square Miles','value':'22'},
            // {'statistic':'Average Rent','value':'$3,910'}
        ]
    },
    "ca-mountainview": {
        "image": "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/Mountain+View.jpg",
        "title": "PREMIUM LOCATIONS",
        "subTitle": "WORLDWIDE",
        "city": "MOUNTAIN VIEW",
        "state": "CALIFORNIA",
        "country": "US",
        "countryId": "2",
        "state_id": '8',
        "city_id": '23900',
        "address": "CALIFORNIA",
        "metaDescription": "SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apartments in Mountain View, CA that are fully customizable come with 24/7 support & exclusive personalized meet & greet services. Call 800 367 9501 or Book today at SuiteAmerica.com",
        "metaTitle": "Corporate Housing Mountain View, CA  |Short Term Furnished Apartments Mountain View, CA | 800 367 9501",
        "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/ca/mountainview",
        "cityInfo": "Mountain View is a city located in Santa Clara County, California, named for its views of the Santa Cruz Mountains. From its origins as a stagecoach stop, it grew into a large suburb with a pedestrian-friendly. The city borders Palo Alto and the San Francisco Bay to the north, Los Altos to the south, and Moffett Federal Airfield and Sunnyvale to the east.",
        "locationName": "Corporate Housing Cupertino, CA",
        "latitude": "37.3861",
        "longitude": "-122.0839",
        "tagLine":"Named for its views of the Santa Cruz Mountains",
        "cityHighlights": [
            "In 1956, Shockley Semiconductor Laboratory, the first company to develop silicon semiconductor devices in what came to be known as Silicon Valley",
            "Home of the largest collection of computer artefacts at the Computer History Museum",
            "The Original Byte Shop computer store was opened at 1063 El Camino Real",
            "Between 1929 and 1994, the city was the home of the Moffett Field Naval Air Station"
        ],
        "demoGraphics":[
            {'statistic':'Per Capita Income','value':'$120,351'},
            {'statistic':'Median Household Income','value':' $156,355'},
            {'statistic':'Population Density','value':' 6,034 per square mile'},
            {'statistic':'Total Population ','value':'81,438'},
            {'statistic':'Average Age','value':'35'},
            {'statistic':'Square Miles','value':'12.27'},
            // {'statistic':'Average Rent','value':'$4,235'}
        ]
    },
    "dc-washington": {
        "image": "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/Washington+DC1.jpeg",
        "title": "PREMIUM LOCATIONS",
        "subTitle": "WORLDWIDE",
        "city": "WASHINGTON",
        "state": "DISTRICT OF COLUMBIA",
        "country": "US",
        "countryId": "2",
        "state_id": '11',
        "city_id": '14378',
        "address": "DISTRICT OF COLUMBIA",
        "metaDescription": "Suite America provides platinum-level solutions in Corporate Housing & Short Term Furnished Apartments in Washington, DC.  While staying in SuiteAmerica’s corporate housing and short term furnished apartments in Washington D.C., you can see American history up close and personal during your relocation or temporary assignment.",
        "metaTitle": "Corporate Housing Washington, DC | Short Term Furnished Apartments  Washington, DC | 800 367 9501",
        "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/dc/washington",
        "cityInfo": "As America’s capital, Washington D.C. is home to some of the nation’s top monuments, museums, and historical sites. While staying in SuiteAmerica’s corporate housing and short-term furnished apartments in Washington D.C., you can see American history up close during your relocation or temporary assignment.",
        "locationName": "Corporate Housing Washington, DC",
        "latitude": "38.892060",
        "longitude": "-77.019910",
        "tagLine":"World Political Capital",
        "cityHighlights": [
            "#1 in Best Historic Destination in the U.S. per U.S. News (2018).",
            " #7 in Education per Forbes (2018).",
            "On the north bank of the Potomac River.",
            " Center of all three branches of the U.S. federal government.",
            " Home to The White House, Lincoln Memorial, National World War II Memorial, and Smithsonian."
        ],
        "demoGraphics":[
            {'statistic':'Per Capita Income','value':'$77,649'},
            {'statistic':'Median Household Income','value':'$116,090'},
            {'statistic':'Population Density','value':' 9800 per square mile'},
            {'statistic':'Total Population ','value':'713,224'},
            {'statistic':'Average Age','value':'34'},
            {'statistic':'Square Miles','value':'68'},
            {'statistic':'Average Rent','value':'$2,139'}
        ]
    },
    "va-ashburn": {
        "image": "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/city-images/Ashburn-iStock-623374026.jpg",
        "title": "PREMIUM LOCATIONS",
        "subTitle": "WORLDWIDE",
        "city": "WASHINGTON",
        "state": "VIRGINIA",
        "country": "US",
        "countryId": "2",
        "state_id": '62',
        "city_id": '0',
        "address": "VIRGINIA",
        "metaDescription": "SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apartments in Ashburn, VA. SuiteAmerica’s corporate housing and short term furnished apartments in Ashburn are within driving distance of the D.C. area’s most well-known attractions, making Ashburn a great place to live without the big city fuss.",
        "metaTitle": "Corporate Housing Ashburn, VA  | Short Term Furnished Apartments  Ashburn, VA | 800 367 9502",
        "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/va/ashburn",
        "cityInfo": "Northwest of D.C. is Ashburn, Virginia, a town that’s quietly growing into a top destination for government and tech workers. With a strong economy, small community feel, and local universities, Ashburn has plenty of resources and family-friendly activities. SuiteAmerica’s corporate housing and short term furnished apartments in Ashburn are within driving distance of the D.C. area’s most well-known attractions, making Ashburn a great place to live without the big city fuss.        ",
        "locationName": "Corporate Housing Ashburn, VA",
        "latitude": "39.703590",
        "longitude": "-104.930660",
        "tagLine":"Farmwell",
        "cityHighlights": [
            "#13 Best Places to Live in Virginia per Niche.",
            " Loudon County named #1 in Nation per Patch.",
            " Home to Ashburn Park, Potomac Heritage Trail and Bles Park."
        ],
        "demoGraphics":[
            {'statistic':'Per Capita Income','value':' $124,399'},
            {'statistic':'Median Household Income','value':'$143,789'},
            {'statistic':'Population Density','value':'2893 per square mile'},
            {'statistic':'Total Population ','value':'43,511'},
            {'statistic':'Average Age','value':'35'},
            {'statistic':'Square Miles','value':'17'},
            {'statistic':'Average Rent','value':'$1,744'}
        ]
    },
    "md-columbia": {
        "image": "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/Columbia-Maryland.jpg",
        "title": "PREMIUM LOCATIONS",
        "subTitle": "WORLDWIDE",
        "city": "COLUMBIA",
        "state": "MARYLAND",
        "country": "US",
        "countryId": "2",
        "state_id": '27',
        "city_id": '171',
        "address": "MARYLAND",
        "metaDescription": "Suite America provides platinum-level solutions in Corporate Housing & Short Term Furnished Apartments in Columbia, MD. Good schools, an array of retail centers, and an effort to make the city more bike and walking friendly create a high quality of life. Columbia prides itself on celebrating diversity and local history. SuiteAmerica’s corporate housing and short term furnished apartments make living in Columbia affordable and ideal.",
        "metaTitle": "Corporate Housing Columbia, MD  | Short Term Furnished Apartments  Columbia, MD | 800 367 9503",
        "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/md/columbia",
        "cityInfo": "Part of Baltimore’s greater metro area, Columbia is a planned community in Maryland consisting of ten self-contained villages. Good schools, an array of retail centers, and an effort to make the city more bike and walk-friendly create a high quality of life. Columbia prides itself on celebrating diversity and local history. SuiteAmerica’s corporate housing and short-term furnished apartments make living in Columbia affordable and ideal.",
        "locationName": "Corporate Housing Columbia, MD",
        "latitude": "39.217520",
        "longitude": "-76.868730",
        "tagLine":"The Next America",
        "cityHighlights": [
            "  #1 Best Places to Live per Money Magazine (2016).",
            "  #4 Top Amphitheaters in America per Rolling Stone.",
            "#7 on America’s Top 25 Towns To Live Well per Forbes.",
            "Home to Merriweather Post Pavilion, Ellicott City, Centennial Park, and Columbia Sports Park."
        ],
        "demoGraphics":[
            {'statistic':'Per Capita Income','value':'$103,707'},
            {'statistic':'Median Household Income','value':'$123,711'},
            {'statistic':'Population Density','value':'3983 per square mile'},
            {'statistic':'Total Population ','value':'99,915'},
            {'statistic':'Average Age','value':'37'},
            {'statistic':'Square Miles','value':'32'},
            {'statistic':'Average Rent','value':'$1,637'}
        ]
    },
    "va-fairfax": {
        "image": "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/Fairfax1.jpeg",
        "title": "PREMIUM LOCATIONS",
        "subTitle": "WORLDWIDE",
        "city": "FAIRFAX",
        "state": "VIRGINIA",
        "country": "US",
        "countryId": "2",
        "state_id": '62',
        "city_id": '53641',
        "address": "VIRGINIA",
        "metaDescription": "SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apartments in Fairfax, VA. Just 20 miles north of Washington D.C. are SuiteAmerica’s corporate housing and short term furnished apartments in Fairfax, Virginia. A small town with urban energy, Fairfax hosts concerts, festivals, movie screenings, and more to make everyone feel at home and welcome in this quaint and energetic suburb.",
        "metaTitle": "Corporate Housing Fairfax, VA  | Short Term Furnished Apartments  Fairfax, VA | 800 367 9504",
        "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/va/fairfax",
        "cityInfo": "Just 20 miles north of Washington D.C. are SuiteAmerica’s corporate housing and short-term furnished apartments in Fairfax, Virginia. A small town with urban energy, Fairfax hosts concerts, festivals, movie screenings, and more to make everyone feel at home and welcome in this quaint and energetic suburb.",
        "locationName": "Corporate Housing Fairfax, VA",
        "latitude": "38.847120",
        "longitude": "-77.306320",
        "tagLine":"Live Life Connected",
        "cityHighlights": [
            " #3 on America’s Top 25 Towns to Live Well per Forbes.",
            " Ranks #1 in start-ups per capita.",
            " #6 Healthiest City in the U.S. by U.S. News & World Report.",
            " Home to the NRA National Firearms Museum, Eaglebank Arena and Burke Lake Park."
        ],
        "demoGraphics":[
            {'statistic':'Per Capita Income','value':'$106,870'},
            {'statistic':'Median Household Income','value':'$128,527'},
            {'statistic':'Population Density','value':'3786 per square mile'},
            {'statistic':'Total Population ','value':'24,097'},
            {'statistic':'Average Age','value':'39'},
            {'statistic':'Square Miles','value':'6'},
            {'statistic':'Average Rent','value':'$1,826'}
        ]
    },
    "va-herndon": {
        "image": "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/Herndon-VA1.jpg",
        "title": "PREMIUM LOCATIONS",
        "subTitle": "WORLDWIDE",
        "city": "Herndon",
        "state": "VIRGINIA",
        "country": "US",
        "countryId": "2",
        "state_id": '62',
        "city_id": '53816',
        "address": "VIRGINIA",
        "metaDescription": "Suite America provides platinum-level solutions in Corporate Housing & Short Term Furnished Apartments in Herndon, VA. Herndon is a beautiful town that has a lot to offer during your stay in SuiteAmerica’s corporate housing and short term furnished apartments. Enjoy the town’s numerous parks, free concerts, and festivals.",
        "metaTitle": "Corporate Housing Herndon, VA | Short Term Furnished Apartments Herndon, VA | 800 367 9505",
        "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/va/herndon",
        "cityInfo": "With plenty of sightseeing and outdoor activities, Herndon is a beautiful town that has a lot to offer during your stay in SuiteAmerica’s corporate housing and short-term furnished apartments. Enjoy the town’s numerous parks, free concerts, and festivals. This charming Virginia town provides a relaxed atmosphere outside of Washington D.C. Experience life at a slower pace while still being close to the big city.",
        "locationName": "Corporate Housing Herndon, VA",
        "latitude": "38.969730",
        "longitude": "-77.383873",
        "tagLine":"Healthy and Sustainable ",
        "cityHighlights": [
            "  #12 on Forbes list of “America’s Most Friendly Towns”",
            " #20 Best Places for Millenials in Virginia per Niche.",
            "Home to the Frying Pan Farm Park, Steven F. Udvar-Hazy Center, Escape Room Herndon, and Bready Park."
        ],
        "demoGraphics":[
            {'statistic':'Per Capita Income','value':'$108,523'},
            {'statistic':'Median Household Income','value':'$122,913'},
            {'statistic':'Population Density','value':'5129 per square mile'},
            {'statistic':'Total Population ','value':' 24,532'},
            {'statistic':'Average Age','value':'35'},
            {'statistic':'Square Miles','value':'4'},
            {'statistic':'Average Rent','value':'$1,787'}
        ]
    },
    "va-mclean": {
        "image": "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/Mclean1.jpeg",
        "title": "PREMIUM LOCATIONS",
        "subTitle": "WORLDWIDE",
        "city": "MCLEAN",
        "state": "VIRGINIA",
        "country": "US",
        "countryId": "2",
        "state_id": '62',
        "city_id": '54029',
        "address": "VIRGINIA",
        "metaDescription": "SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apartments in Mclean, VA. Known for luxurious homes and high-end shopping, the city is among one of the most expensive zip codes in Virginia and is also the third wealthiest city in the country. SuiteAmerica’s corporate housing and short term furnished apartments in McLean are cost effective and provide world class amenities in this upscale destination.",
        "metaTitle": "Corporate Housing Mclean, VA | Short Term Furnished Apartments  Mclean, VA | 800 367 9506",
        "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/va/mclean",
        "cityInfo": "Due to its proximity to Washington D.C., McLean is home to many diplomats, government officials, and members of Congress. Known for luxurious homes and high-end shopping, the city is among one of the most expensive zip codes in Virginia and is also the third wealthiest city in the country. SuiteAmerica’s corporate housing and short-term furnished apartments in McLean are cost-effective and provide world-class amenities in this upscale destination.",
        "locationName": "Corporate Housing Mclean, VA",
        "latitude": "38.937590",
        "longitude": "-77.183780",
        "tagLine":"Virginia is for Lovers",
        "cityHighlights": [
            " #6 Most Educated Places in America per Forbes.",
            " #16 Best Place to Raise a Family in Virginia per Niche.",
            " Home to Scott’s Run Nature Preserve, Clemyjontri Park, Claude Moore Colonial Farm and Wolf Trap National Park for the Performing Arts."
        ],
        "demoGraphics":[
            {'statistic':'Per Capita Income','value':'$193,426'},
            {'statistic':'Median Household Income','value':'$283,992'},
            {'statistic':'Population Density','value':'1972 per square mile'},
            {'statistic':'Total Population ','value':'632,309'},
            {'statistic':'Average Age','value':'46'},
            {'statistic':'Square Miles','value':'24'},
            {'statistic':'Average Rent','value':' $2,232'}
        ]
    },
    "md-northbethesda": {
        "image": "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/Bethesda-Maryland1.jpeg",
        "title": "PREMIUM LOCATIONS",
        "subTitle": "WORLDWIDE",
        "city": "NORTH BETHESDA",
        "state": "MARYLAND",
        "country": "US",
        "countryId": "2",
        "state_id": '27',
        "city_id": '543',
        "address": "MARYLAND",
        "metaDescription": "Suite America provides platinum-level solutions in Corporate Housing & Short Term Furnished Apartments in North Bethesda, MD.  SuiteAmerica’s corporate housing and short term furnished apartments in North Bethesda provide world class amenities and an unrivaled level of service for those living outside Washington D.C.",
        "metaTitle": "Corporate Housing North Bethesda, MD  | Short Term Furnished Apartments  North Bethesda, MD | 800 367 9507",
        "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/md/northbethesda",
        "cityInfo": "Just four miles west of Washington D.C., North Bethesda, Maryland doesn’t have specific boundaries but consists of several neighborhoods, such as Garrett Park Estates, Randolph Hills, Wildwood, Old Farm, and Chase Crossing. SuiteAmerica’s corporate housing and short-term furnished apartments in North Bethesda provide world-class amenities and an unrivaled level of service for those living outside Washington D.C.",
        "locationName": "Corporate Housing North Bethesda, MD",
        "latitude": "39.044990",
        "longitude": "-77.116850",
        "tagLine":"House of Mercy ",
        "cityHighlights": [
            " #1 Best Places to Live in Maryland per Niche (2018).",
            " #1 Most Educated Places in America per Forbes.",
            " Home to Clara Barton National Historic Site, Turkey Run Park, Round House Theatre, and George Washington Memorial Parkway."
        ],
        "demoGraphics":[
            {'statistic':'Per Capita Income','value':'$103,194'},
            {'statistic':'Median Household Income','value':'$136,879'},
            {'statistic':'Population Density','value':'5635 per square mile'},
            {'statistic':'Total Population ','value':'46,646'},
            {'statistic':'Average Age','value':'39'},
            {'statistic':'Square Miles','value':'8.9'},
            {'statistic':'Average Rent','value':'$1,970'}
        ]
    },
    "va-reston": {
        "image": "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/Reston-VA1.jpeg",
        "title": "PREMIUM LOCATIONS",
        "subTitle": "WORLDWIDE",
        "city": "RESTON",
        "state": "VIRGINIA",
        "country": "US",
        "countryId": "2",
        "state_id": '62',
        "city_id": '54311',
        "address": "VIRGINIA",
        "metaDescription": "SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apartments in  Reston, VA.  Reston is also home to several performing arts groups and a free summer concert series. SuiteAmerica’s corporate housing and short term furnished apartments in Reston make it a great place to enjoy the outdoors while also feeling at home in SuiteAmerica’s world class housing.",
        "metaTitle": "Corporate Housing Reston, VA  | Short Term Furnished Apartments  Reston, VA | 800 367 9508",
        "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/va/reston",
        "cityInfo": "A planned city with a good balance of parks, homes, and retail centers, Reston has been ranked one of the best places to live in Virginia. Known for its numerous parks, lakes, golf courses, and horse trails, Reston is also home to several performing arts groups and a free summer concert series. SuiteAmerica’s corporate housing and short-term furnished apartments in Reston make it a great place to enjoy the outdoors while also feeling at home in SuiteAmerica’s world-class housing.",
        "locationName": "Corporate Housing Reston, Va",
        "latitude": "38.960190",
        "longitude": "-77.354500",
        "tagLine":"Work, Play, Live",
        "cityHighlights": [
            "“Virginia is for Lovers”",
            " #12 Best Counties for Families in America per Niche.",
            " #13 Healthiest Counties in America per Niche.",
            "Home to Lake Fairfax Park, Water Mine Family Swimmin’ Hole, Lake Anne and Fred Crabtree Park."
        ],
        "demoGraphics":[
            {'statistic':'Per Capita Income','value':'$113,703'},
            {'statistic':'Median Household Income','value':'$143,266'},
            {'statistic':'Population Density','value':'3920 per square mile'},
            {'statistic':'Total Population ','value':'58,404'},
            {'statistic':'Average Age','value':'39'},
            {'statistic':'Square Miles','value':'15'},
            {'statistic':'Average Rent','value':'$1,986'}
        ]
    },
    "md-silverspring": {
        "image": "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/Silver+spring+Maryland1.jpeg",
        "title": "PREMIUM LOCATIONS",
        "subTitle": "WORLDWIDE",
        "city": "SILVER SPRING",
        "state": "MARYLAND",
        "country": "US",
        "countryId": "2",
        "state_id": '27',
        "city_id": '681',
        "address": "MARYLAND",
        "metaDescription": "Suite America provides platinum-level solutions in Corporate Housing & Short Term Furnished Apartments in  Silver Springs, MD.  Downtown Silver Spring hosts festivals throughout the year, the most notable of which are Silverdocs, a documentary film festival, and the Silver Spring Jazz Festival. Enjoy the up and coming town of Silver Spring while staying in SuiteAmerica’s corporate housing and short term furnished apartments",
        "metaTitle": "Corporate Housing Silver Springs, MD  | Short Term Furnished Apartments  Silver Springs, MD | 800 367 9509",
        "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/md/silverspring",
        "cityInfo": "Silver Spring, Maryland is located on the Capital Beltway. Its most southern part is a major business hub that borders northern Washington D.C. With the recent additions of major retail and residential developments, Silver Spring is going through a bit of a renaissance. Downtown Silver Spring hosts festivals throughout the year, the most notable of which are Silverdocs, a documentary film festival, and the Silver Spring Jazz Festival. Enjoy the up-and-coming town of Silver Spring while staying in SuiteAmerica’s corporate housing and short-term furnished apartments.",
        "locationName": "Corporate Housing Silver Springs, MD",
        "latitude": "38.997660",
        "longitude": "-77.027020",
        "tagLine":"The Original Spring",
        "cityHighlights": [
            "  #20 on America’s Top 25 Towns To Live Well per Forbes.",
            "   #2 in Education Rank per Forbes.",
            "  Home to Sligo Creek Trail, AFI Silver Theater, National Museum of Health and Medicine, and Washington DC Temple.",
            "  Annual hosts to the Silverdocs Film Festival and Silver Springs Jazz Festival"
        ],
        "demoGraphics":[
            {'statistic':'Per Capita Income','value':'$76,608'},
            {'statistic':'Median Household Income','value':'$100,424'},
            {'statistic':'Population Density','value':'9021 per square mile'},
            {'statistic':'Total Population ','value':'71,452'},
            {'statistic':'Average Age','value':'34'},
            {'statistic':'Square Miles','value':'8'},
            {'statistic':'Average Rent','value':'$1,650'}
        ]
    },
    "va-tysons": {
        "image": "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/Tysons.jpg",
        "title": "PREMIUM LOCATIONS",
        "subTitle": "WORLDWIDE",
        "city": "TYSONS",
        "state": "VIRGINIA",
        "country": "US",
        "countryId": "2",
        "state_id": '62',
        "city_id": '54544',
        "address": "VIRGINIA",
        "metaDescription": "SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apartments in Tysons, VA. . Tysons has lofty dreams—and plans—to be transformed into a walkable, sustainable urban center where people can live, work, and play. Take advantage now, and stay in SuiteAmerica’s corporate housing and short term furnished apartments in Tysons before word gets out about this promising town.",
        "metaTitle": "Corporate Housing Tysons, VA | Short Term Furnished Apartments  Tysons, VA | 800 367 9510",
        "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/va/tysons",
        "cityInfo": "Formerly known as Tysons Corner, Tysons, Virginia started as a rural farming community and is now the corporate headquarters for several international companies. Tysons has lofty dreams—and plans—to be transformed into a walkable, sustainable urban center where people can live, work, and play. Take advantage now, and stay in SuiteAmerica’s corporate housing and short-term furnished apartments in Tysons before word gets out about this promising town.",
        "locationName": "Corporate Housing Tysons, VA",
        "latitude": "38.912000",
        "longitude": "-77.227550",
        "tagLine":"Edge City ",
        "cityHighlights": [
            "“Virginia is for Lovers”",
            "Home to Wolf Trap National Park for the Performing Arts, Meadowlark Botanical Gardens, and Great Falls Park.",
            "  #54 Best Suburbs for Millennials in America per Niche.",
            "  5 Separate Tyson companies ranked on the 2018 Fortune 500."
        ],
        "demoGraphics":[
            {'statistic':'Per Capita Income','value':'$112,034'},
            {'statistic':'Median Household Income','value':' $143,278'},
            {'statistic':'Population Density','value':'5395 per square mile'},
            {'statistic':'Total Population ','value':'22,437'},
            {'statistic':'Average Age','value':'35'},
            {'statistic':'Square Miles','value':'4'},
            {'statistic':'Average Rent','value':'$2,299'}
        ]
    },
    "tx-houston": {
        "image": "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/Houston.jpg",
        "title": "PREMIUM LOCATIONS",
        "subTitle": "WORLDWIDE",
        "city": "HOUSTON",
        "state": "TEXAS",
        "country": "US",
        "countryId": "2",
        "state_id": '59',
        "city_id": '31655',
        "address": "TEXAS",
        "metaDescription": "Suite America provides platinum-level solutions in Corporate Housing & Short Term Furnished Apartments in Houston, TX. As the most diverse city in Texas, Houston has an active art scene in the Theater District, boasts over 7 million visitors per year to its Museum District, and is home to several professional sports teams. Staying in SuiteAmerica’s corporate housing and short term furnished apartments in Houston provides a world class experience in one of America’s most diverse cities.",
        "metaTitle": "Corporate Housing Houston, TX | Short Term Furnished Apartments Houston, TX  | 800 367 9511",
        "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/tx/houston",
        "cityInfo": "With the second most Fortune 500 headquarters in the country, Houston has become an energetic city for young professionals and families. As the most diverse city in Texas, Houston has an active art scene in the Theater District, boasts over 7 million visitors per year to its Museum District, and is home to several professional sports teams. Staying in SuiteAmerica’s corporate housing and short-term furnished apartments in Houston provides a world-class experience in one of America’s most diverse cities.",
        "locationName": "Corporate Housing Houston, TX",
        "latitude": "29.7589",
        "longitude": "-95.3677",
        "tagLine":"The City With No Limits / Space City",
        "cityHighlights": [
            " #1 America’s Fastest Growing Cities per Forbes (2016).",
            "#7 Best Place to Live per U.S. News & World Report.",
            " Fourth most populous city in the nation.",
            " #17 Most Diverse City in America per Niche.",
            " Home to the Houston Zoo, Space Center Houston, Downtown Aquarium, and Museum of Fine Arts."
        ],
        "demoGraphics":[
            {'statistic':'Per Capita Income','value':'$49,339'},
            {'statistic':'Median Household Income','value':'$79,344'},
            {'statistic':'Population Density','value':'3633 per square mile'},
            {'statistic':'Total Population ','value':'2,312,717'},
            {'statistic':'Average Age','value':'33'},
            {'statistic':'Square Miles','value':'669'},
            {'statistic':'Average Rent','value':'$2,092'}
        ]
    },
    "ca-bayarea": {
        "image": "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/BayArea.jpg",
        "title": "PREMIUM LOCATIONS",
        "subTitle": "WORLDWIDE",
        "city": "BAY AREA",
        "state": "CALIFORNIA",
        "country": "US",
        "countryId": "2",
        "state_id": '8',
        "city_id": '25247',
        "address": "CALIFORNIA",
        "metaDescription": "SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apartments in the San Francisco, Bay Area. Here, you won’t encounter one culture or way of life, you’ll encounter thousands. SuiteAmerica’s corporate housing and short term furnished apartments in the Bay Area allow for an opportunity to explore this premier California area with seven professional sports teams, numerous tourist attractions, iconic entertainment venues, world renowned universities, and breathtaking views.",
        "metaTitle": "Corporate Housing Bay Area | Short Term Furnished Apartments Bay Area| 800 367 9512",
        "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/ca/bayarea",
        "cityInfo": "An immensely diverse, busy, and beautiful place, the Bay Area is the ideal spot for relocating or a temporary assignment. Here, you won’t encounter one culture or way of life, you’ll encounter thousands. SuiteAmerica’s corporate housing and short-term furnished apartments in the Bay Area allow an opportunity to explore this premier California area with seven professional sports teams, numerous tourist attractions, iconic entertainment venues, world-renowned universities, and breathtaking views.",
        "locationName": "Corporate Housing Bay Area",
        "latitude": "38.008278",
        "longitude": "-122.086075",
        "tagLine":"West Coast Best Coast ",
        "cityHighlights": [
            " Comprised of San Francisco, Oakland, San Jose, Berkeley, Palo Alto, Mountain View, Sunnyvale, and more.",
            " #1 on Cities That are Winning the Battle for Information Jobs per Forbes.",
            " If Bay Area were a nation, it would be the world’s 19th largest economy based upon GDP.",
            "Home to the Golden Gate Bridge, Fisherman’s Wharf, Alcatraz Island, Lombard Street, and Union Square."
        ],
        "demoGraphics":[
            {'statistic':'Per Capita Income','value':'$96,265'},
            {'statistic':'Median Household Income','value':'$137,761'},
            {'statistic':'Population Density','value':'1113 per square mile'},
            {'statistic':'Total Population ','value':'7,100,000'},
            {'statistic':'Average Age','value':'38'},
            {'statistic':'Square Miles','value':'6900'},
            // {'statistic':'Average Rent','value':'$4,109'}
        ]
    },
    "ca-orangecounty": {
        "image": "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/Orange+county.jpeg",
        "title": "PREMIUM LOCATIONS",
        "subTitle": "WORLDWIDE",
        "city": "ORANGE COUNTY",
        "state": "CALIFORNIA",
        "country": "US",
        "countryId": "2",
        "state_id": '8',
        "city_id": '24198',
        "address": "CALIFORNIA",
        "metaDescription": "Suite America provides platinum-level solutions in Corporate Housing & Short Term Furnished Apartments in Orange County, Ca. Orange County is a family-friendly place with some of the greatest theme parks in the country. Visit Disneyland or Knott’s Berry Farm, catch an Angel’s game, learn to surf at Huntington Beach, or go shopping at Fashion Island. Enjoy some thrills and California sunshine during your stay in Orange County.",
        "metaTitle": "Corporate Housing Orange County | Short Term Furnished Apartments  Orange County | 800 367 9513",
        "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/ca/orangecounty",
        "cityInfo": "SuiteAmerica’s luxurious corporate housing and short-term furnished apartments in Orange County provide upscale accommodations during a relocation or temporary assignment. Orange County is a family-friendly place with some of the greatest theme parks in the country. Visit Disneyland or Knott’s Berry Farm, catch an Angel’s game, learn to surf at Huntington Beach, or go shopping at Fashion Island. Enjoy some thrills and California sunshine during your stay in Orange County.",
        "locationName": "Corporate Housing Orange County",
        "latitude": "33.7175",
        "longitude": "-117.8311",
        "tagLine":"The Tech Coast ",
        "cityHighlights": [
            " Comprised of Irvine, Anaheim, Newport Beach, Orange, Laguna Beach, Santa Ana, Fullerton, and more.",
            "   #11 Best Counties for Outdoor Activities in America per Niche.",
            " Home to Disney California Adventures, Knott’s Berry Farm, Balboa Island and Huntington Beach Pier."
        ],
        "demoGraphics":[
            {'statistic':'Per Capita Income','value':' $75,430'},
            {'statistic':'Median Household Income','value':'$81,851'},
            {'statistic':'Population Density','value':' 4133 per square mile'},
            {'statistic':'Total Population ','value':'3,190,000'},
            {'statistic':'Average Age','value':'38'},
            {'statistic':'Square Miles','value':'948'},
            // {'statistic':'Average Rent','value':'$2,071'}
        ]
    },
    "ca-siliconvalley": {
        "image": "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/Silicon+Valley1.jpeg",
        "title": "PREMIUM LOCATIONS",
        "subTitle": "WORLDWIDE",
        "city": "SILICON VALLEY",
        "state": "CALIFORNIA",
        "country": "US",
        "countryId": "2",
        "state_id": '8',
        "city_id": '25247',
        "address": "CALIFORNIA",
        "metaDescription": "SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apartments in the Silicon Valley. Balanced by family-friendly neighborhoods, excellent schools, and high quality of life rankings, Silicon Valley is a great place to work and live. With SuiteAmerica’s corporate housing and short term furnished apartments, Silicon Valley relocations and temporary assignments become a world class experience for everyone looking to invent, invest, and enjoy life.",
        "metaTitle": "Corporate Housing Silicon Valley | Short Term Furnished Apartments  Silicon Valley | 800 367 9514",
        "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/ca/siliconvalley",
        "cityInfo": "Silicon Valley, California is an international hub for technology, innovation, and start-ups. Balanced by family-friendly neighborhoods, excellent schools, and high quality of life rankings, Silicon Valley is a great place to work and live. With SuiteAmerica’s corporate housing and short-term furnished apartments, Silicon Valley relocations and temporary assignments become a world-class experience for everyone looking to invent, invest, and enjoy life.",
        "locationName": "Corporate Housing Silicon Valley",
        "latitude": "37.334790",
        "longitude": "-121.888140",
        "tagLine":"Keep the Valley Wired ",
        "cityHighlights": [
            " Comprised of Palo Alto, San Jose, Mountain View, Los Gatos, Cupertino, Sunnyvale, Los Altos, and more.",
            " Home to Winchester Mystery House, Downtown San Jose, Shoreline Amphitheatre, California’s Great America, and The Tech Museum of Innovation."
        ],
        "demoGraphics":[
            {'statistic':'Per Capita Income','value':' $96,662'},
            {'statistic':'Median Household Income','value':' $124,356'},
            {'statistic':'Population Density','value':'5256 per square mile'},
            {'statistic':'Total Population ','value':'3,000,000'},
            {'statistic':'Average Age','value':'30'},
            {'statistic':'Square Miles','value':'47'},
            // {'statistic':'Average Rent','value':' $4,108'}
        ]
    },
    "ga-atlanta": {
        "image": "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/Atlanta1.jpg",
        "title": "PREMIUM LOCATIONS",
        "subTitle": "WORLDWIDE",
        "city": "ATLANTA",
        "state": "GEORGIA",
        "country": "US",
        "countryId": "2",
        "state_id": '15',
        "city_id": '27721',
        "address": "GEORGIA",
        "metaDescription": "Suite America provides platinum-level solutions in Corporate Housing & Short Term Furnished Apartments in  Atlanta. GA. SuiteAmerica’s corporate housing and short term furnished apartments in Atlanta provide a world class experience in a city that’s constantly redefining American identity, activism, and global outreach.",
        "metaTitle": "Corporate Housing Atlanta, GA | Short Term Furnished Apartments  Atlanta, GA | 800 367 9515",
        "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/ga/atlanta",
        "cityInfo": "Home to the world’s busiest airport, Atlanta has a large impact on global commerce, finance, research, art, and entertainment. The capital of Georgia literally rose from the ashes after the Civil War to not only become the center of the 1960’s Civil Rights Movement but to also become a hub of increasingly diverse and dynamic American culture. SuiteAmerica’s corporate housing and short-term furnished apartments in Atlanta provide a world-class experience in a city that’s constantly redefining American identity, activism, and global outreach.",
        "locationName": "Corporate Housing Atlanta, GA",
        "latitude": "33.7491",
        "longitude": "-84.3902",
        "tagLine":"City in a Forest / Hollywood of the South / The ATL",
        "cityHighlights": [
            "#8 Best Place For Business And Careers (Forbes 2018)",
            "#1 Moving Destination in the Nation per Penske.",
            "#1 Most Affordable Big City per WalletHub.",
            "Fifth most populated city in the nation.",
            "Home to Centennial Olympic Park, World of Coca-Cola, Georgia Aquarium, and Atlanta Botanical Garden."
        ],
        "demoGraphics":[
            {'statistic':'Per Capita Income','value':'$51,701'},
            {'statistic':'Median Household Income','value':'$92,186'},
            {'statistic':'Population Density','value':'3644 per square mile'},
            {'statistic':'Total Population ','value':'486,290'},
            {'statistic':'Average Age','value':'33'},
            {'statistic':'Square Miles','value':'134'},
            {'statistic':'Average Rent','value':'$1,398'}
        ]
    },
    "ny-newyork": {
        "image": "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/New+York2.jpg",
        "title": "PREMIUM LOCATIONS",
        "subTitle": "WORLDWIDE",
        "city": "New York",
        "state": "NEWYORK",
        "country": "US",
        "countryId": "2",
        "state_id": '44',
        "city_id": '41454',
        "address": "New York",
        "metaDescription": "SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apartments in New York, NY. The most populated city in the United States, NYC has five distinct boroughs, each with its own unique characteristics and offerings. SuiteAmerica’s luxurious corporate housing and short term furnished apartments in New York City allow for a seamless relocation or temporary assignment while exploring the place so deeply symbolic of American ideals.",
        "metaTitle": "Corporate Housing New York, NY | Short Term Furnished Apartments  New York, NY | 800 367 9516",
        "metaUrl": "https://www.suiteamerica.com/corporate-housing/furnished-apartments/ny/newyork",
        "cityInfo": "As the epicenter of East Coast business and culture, New York City symbolizes American opportunity and diversity. The most populated city in the United States, NYC has five distinct boroughs, each with its own unique characteristics and offerings. SuiteAmerica’s luxurious corporate housing and short-term furnished apartments in New York City allow for a seamless relocation or temporary assignment while exploring the place so deeply symbolic of American ideals.",
        "locationName": "Corporate Housing New York, NY",
        "latitude": "42.5542",
        "longitude": "-77.4725",
        "tagLine":"The Big Apple / The Empire City / The City So Nice They Named It Twice",
        "cityHighlights": [
            "#1 Most Influential City in the World per Business Insider.",
            " #6 Most Diverse Cities in America per Niche.",
            " 52 companies on the Fortune 500.",
            " Home to the Statue of Liberty, Central Park, Empire State Building, and Times Square."
        ],
        "demoGraphics":[
            {'statistic':'Per Capita Income','value':'$59,782'},
            {'statistic':'Median Household Income','value':'$93,196'},
            {'statistic':'Population Density','value':'27000 per square mile'},
            {'statistic':'Total Population ','value':'8,622,698'},
            {'statistic':'Average Age','value':'36'},
            {'statistic':'Square Miles','value':'302'},
            {'statistic':'Average Rent','value':'$4,188'}
        ]
    } 
}


module.exports = cityPages