import GuestHeader from "../../common/guestHeader";
import LocationService from "../../../services/locationService";
import AppController from "../../controller/appController";
import MapController from "../../controller/mapController";
import SmoothScrolling from "./smoothScrolling";
import FloorPlan from "./floorPlans";
import NeighBoorHood from "./neighborHood";
import Amenities from "./amenities";
import PhotoGallery from "./photoGallery";
import Home from "./home";
import FloorModal from "./floorModal";
import PhotoModal from "./lakePhotoModal";
import CommunityList from "./communitiesList";
import ModalVideo from "./modalVideo";
import AmenitiesModal from "./amenitiesModal";
import Footer from "../../common/footer";
import SubFooter from "../../common/subFooter";
import Faqs from "./faqs";
import Latchaccess from "./latchAccess";
import ParkingInfo from "./lakeParkInfo";

class LakSide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      communityInfo:this.props.match.path=="/1250-lakeside"?CommunityList["/1250-lakeside"]:CommunityList[this.props.match.path],
      communityRequest: {
        communityName: this.props.match.path=="/1250-lakeside"?CommunityList["/1250-lakeside"].Community:CommunityList[this.props.match.path].Community,
        imageType: "banner"
      },
      communityResponse: {},
      greatSchoolsResponse: {},
      walkScoreResponse: [],
      loader: false,
      communityResponseloader: false,
      poi: [],
      communityName:'',
      latitude: 0.0,
      longitude:0.0,
      width:0 ,
      communityDetails: false,
      floorModal:false,
      photomodal:false,
      floorObject:{},
      dataId:0,
      floor3d:false,
      floor3dData:{},
      amenitiesModal:false,
      amenitesImage:'',
      defaultAmenities:['Fitness Center','Dog Park','Spa / Hot Tub','Swimming Pool','Business Center','Electric Car Charging Stations','Picnic area','Game Room'],
      faqsModal:false,
      latchAccessModal:false,
      parkingInfoModal:false,
      photosFloorPlans:[{image_name:"/assets/newUI-images/lakeImages/capture2.png"},{image_name:"/assets/newUI-images/lakeImages/capture3.png"},{image_name:"/assets/newUI-images/lakeImages/capture4.png"},{image_name:"/assets/newUI-images/lakeImages/capture5.png"},{image_name:"/assets/newUI-images/lakeImages/1250lakesidedrsunnyvale-26_final_t_1.png"},{image_name:"/assets/newUI-images/lakeImages/1250lakesidedrsunnyvale-3_final_t_2.png"},{image_name:"/assets/newUI-images/lakeImages/1250 lakeside_generation marketing_062921-2.png"},{image_name:"/assets/newUI-images/lakeImages/1250 lakeside_generation marketing_062921-1(1).png"},{image_name:"/assets/newUI-images/lakeImages/capture6(2).png"}]
    };
    
    this.linkAddress = this.linkAddress.bind(this);
    this.amenities = this.amenities.bind(this);
    this.scrollUp = this.scrollUp.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.floorCorousel= this.floorCorousel.bind(this);
    this.gallaeryCorousel= this.gallaeryCorousel.bind(this);
    this.floorImages= this.floorImages.bind(this);
    this.headerChange= this.headerChange.bind(this);
    this.follorIn3d= this.follorIn3d.bind(this);
    this.closeModal=this.closeModal.bind(this);
    this.amentiesModalPopup=this.amentiesModalPopup.bind(this);
  }

  async communityInfo() {
    const requestObj = this.state.communityRequest;
    const data = await LocationService.getCommunityInfoByName(
      requestObj
    );

    let responseObj=JSON.parse(data.AmenitiesList)
    let obj={aparment_amenity_name: "Electric Car Charging Stations", communityname: "Community"}
    responseObj.splice(18, 0, obj);
    _.each(responseObj, o => _.each(o, (v, k) => o[k] = v.trim()))
    let resObj=responseObj.filter(function(obj){
      return ((obj.aparment_amenity_name!=="Parcel Concierge") && (obj.aparment_amenity_name!=="Storage (bike)") && (obj.aparment_amenity_name!=="Game Room") && (obj.aparment_amenity_name!=="Keyless Front Door") && (obj.aparment_amenity_name!=="Granite Counter Tops") && (obj.aparment_amenity_name!=="Private balcony and patios") )
    })

    responseObj=resObj
    responseObj=JSON.stringify(responseObj)
    data["AmenitiesList"]=responseObj
    this.setState({
      communityResponse: data,
      communityResponseloader: true
    });
    Object.keys(this.state.communityResponse).length > 0
      ? this.thirdPartyApis(this.state.communityResponse)
      : "";
    Object.keys(this.state.communityResponse).length > 0
      ? this.map(this.state.communityResponse)
      : "";
  }
  async thirdPartyApis(communityData) {
    let apiData =  {
      "city": "BAY AREA",
      "state_name": "CALIFORNIA",
      "address": "CALIFORNIA",
      "latitude": "38.008278",
      "longitude": "-122.086075"
  }
    const greatSchoolsRes = await AppController.greatSchools2(apiData);
    const walkScoreRes = await AppController.wallsckoreFun(communityData);
    this.weather(communityData);
    this.poi(communityData)
    if (
      walkScoreRes != undefined &&
      walkScoreRes != null &&
      walkScoreRes != 0
    ) {
      this.setState({
        loader: true,
        greatSchoolsResponse: greatSchoolsRes,
        walkScoreResponse: [
          {
            Score:
              walkScoreRes.walkscore == undefined
                ? null
                : walkScoreRes.walkscore,
            description:
              walkScoreRes.description == undefined
                ? null
                : walkScoreRes.description,
            name: "WalkScore",
            chartName: "chart1" + communityData.community_id,
            colour: "#000000",
            image: "WalkScore.png"
          },
          // {
          //   Score:
          //     walkScoreRes.bike == undefined ? null : walkScoreRes.bike.score,
          //   description:
          //     walkScoreRes.bike == undefined
          //       ? null
          //       : walkScoreRes.bike.description,
          //   name: "BikeScore",
          //   chartName: "chart3" + communityData.community_id,
          //   colour: "#008000",
          //   image: "BikeScore.png"
          // }
        ]
      });
      const walkScoreDetails = this.state.walkScoreResponse;
      for (var i = 0; i < walkScoreDetails.length; i++) {
        AppController.doughnutChart(walkScoreDetails[i]);
      }
    } else {
      this.setState({
        loader: true,
        greatSchoolsResponse: greatSchoolsRes,
        walkScoreResponse: []
      });
    }
  }
  async map(communityData) {
    var locations = {
      lat: Number(communityData.latitude),
      lng: Number(communityData.longitude)
    };
    if ($("#map"+communityData.community_id).length) {
      const customMap = new google.maps.Map(document.getElementById("map"+communityData.community_id), {
        zoom: 7,
        center: locations,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      });
      await this.setState({ customMap });
      MapController.customMarkers(
        communityData,
        "singlemark",
        this,
        "",
        "https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/location_pin_2.svg",
        customMap
      );
    }
  }
  async componentWillMount(){
    AppController.removeLocalStorageData();
    $('html, body').animate({scrollTop: 0});
    AppController.stickyTitles();
    await this.communityInfo();
    await AppController.floorThumbs(this.state.communityResponse.community_id); 
  }

  amenities(amenities, type) {
    
    
    const {defaultAmenities}=this.state
    const url=this.props.match.path;
    var amenities = JSON.parse(amenities);
    var filteredAmenites =
      amenities != null && amenities != undefined
        ? _.filter(amenities, function(o) {
            return o.communityname == type;
          })
        : [];
    return (
      <ul>
        {filteredAmenites.map((row, index) => {
          let res=_.find(defaultAmenities, function(o) { 
            return o==row.aparment_amenity_name && url=='/thedean'; });

          //return <li key={index}>{res==undefined? row.aparment_amenity_name:row.aparment_amenity_name+'*'}</li>;
          return <li key={index}>{res==undefined? row.aparment_amenity_name:row.aparment_amenity_name}</li>;
        })}
      </ul>
    );
  }
  scrollUp(id) {
    SmoothScrolling.scrollTo(id);
  }
  async weather(apidata) {
    const weather = await AppController.weather(apidata)

    var results = weather.list.map(function (el) {
        var o = Object.assign({}, el);
        o.date = o.dt_txt.split(" ")[0];
        return o;
    })
    var data1 = _.chain(results)
        .groupBy("date")
        .map(function (currentItem) {
            let minTEMP = _.minBy(currentItem, function (o) {
                return o.main.temp_min;
            });
            let maxTEMP = _.maxBy(currentItem, function (o) {
                return o.main.temp_max;
            });
            return {
                weatherData: currentItem[0],
                weatherDataMax: maxTEMP.main.temp_max,
                weatherDataMin: minTEMP.main.temp_min
            }
        }).value();
    this.setState({
        weather: data1.splice(1, 4),
        weather_current: data1[0].weatherData,
        weather_currentCityDetails: weather.city,
    })
}
async poi(apidata) {
  const data={
    city:apidata.city_name
  }
  const poi = await AppController.poi(data)
  
  this.setState({ poi: poi.results })
}

  handleFormSubmit(){

  }
   floorCorousel(data) {
    AppController.deanThumbs(data);
   
      }
  async gallaeryCorousel(id){
    await localStorage.setItem('item', id)
    await this.setState({photomodal:true})
    AppController.deanThumbs(15);
  }
  floorImages(row){
    this.setState({floorModal:true,floorObject:row})
  }
  scrollToDiv(){
    $('html,body').animate({
      scrollTop: $(".deanresponsive-tabs").offset().top
    }, 500);
    return false;
  }

  async headerChange(id){
    $('.tab-pane').removeClass('panel-collapse collapse in');
    this.scrollToDiv()
    await this.setState({dataId:id,
      floorModal:false,
      floor3d:false,
      amenitiesModal:false,
      photomodal:false,
    faqsModal:false,
  latchAccessModal:false,
  parkingInfoModal:false})
    id==1?this.floorCorousel(0):''
    if(id==4){
      let CommunityImages=JSON.parse(this.state.communityResponse.CommunityImages)
  let ids=CommunityImages[0].image_name
    await localStorage.setItem('item', ids)
    await this.setState({photomodal:true})
    await setTimeout(async () => {
      await AppController.deanThumbs(15);
    }, 500);
    }if(id==6){
      this.setState({parkingInfoModal:true})
    }
    if(id==7){
      this.setState({latchAccessModal:true})
    }
    if(id==8){
      this.setState({faqsModal:true})
    }
  }
  follorIn3d(row){
    this.setState({floor3d:true,floor3dData:row})
  }
  closeModal(){
       $('#sync1'+15).removeAttr('id');
      $('#sync2'+15).removeAttr('id');
    this.setState({floor3d:false,floor3dData:{},photomodal:false})
  }
  
  amentiesModalPopup(image){
  this.setState({amenitiesModal:true,amenitesImage:image})
  }
  linkAddress(link){
    localStorage.setItem('amenitieslink',link)
  }
 
  componentDidMount() {
    var component = this;
    $('.latchAccess').click(function(e){
      $('#tab7').addClass('tab-pane animated rollIn  panel-collapse collapse active');
      $('#presentationTab7').addClass("active")
      component.headerChange(7)
    })

    $('.neighborhood').click(function(e){
      $('#tab2').addClass('tab-pane animated rollIn  panel-collapse collapse active');
      $('#presentationTab2').addClass("active")
      component.headerChange(2)
    })

  }
  
  render() {
       const url=this.props.match.path;
    return (
      <div>
        {/* <GuestHeader /> */}
        
        <div className="deanpage lakesideComm altairDean">
        <div className="deanpage_banner ">
          {/* <img className="altair-deanClogo" src="/assets/newUI-images/altairImages/altair_logo.jpeg" alt="" /> */}
        <div className="deanbannerimg">
        
                <img src="/assets/newUI-images/lakeImages/the-dean-exterior1a.png" />
            
				</div>
        <div className="banner_text">
          <h2> SUITEAMERICA  <span>  <b> |</b>  1250 LAKESIDE </span></h2>
          <div className="text_bg">
            <h1 className="ribbon">
              <strong className="ribbon-content">
              {/* {this.state.communityInfo.BanerName} */}
              Welcome to Sunnyvale’s exclusive oasis of modern living.
              </strong>
            </h1>
            <div className="threeborders" />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="deanbeerbottom">
         <div className="bannerBottom"> <h1>
          {/* {this.state.communityInfo.HeadLine} */}
          Now renting the newest, luxury apartments in Sunnyvale.
          </h1>
        
        
        
            <div>
          <p>1250 Lakeside is the newest, premium property in the South Bay. The 2021 Grand Opening makes 1250 the most beautiful, modern property in Silicon Valley. A tranquil oasis of community-life, 1250 has ample co-workspaces, and relaxing, state-of-the-art amenities. Step into this revitalizing way of life and explore all the ways you can relax, unwind, and live luxuriously.</p>
         </div> 
         </div>

          <div className="responsive-tabs deanresponsive-tabs">
						<ul className="nav nav-tabs deantabss" role="tablist">
            <li role="presentation" className="">
								<a href="#tab3" aria-controls="tab3" role="tab" data-toggle="tab" onClick={()=>this.headerChange(3)}>Amenities & Inclusions </a>
							</li>
              <li role="presentation" className="">
								<a href="#tab4" aria-controls="tab4" role="tab" data-toggle="tab" onClick={()=>this.headerChange(4)}> Photos & Floorplans </a>
							</li>
                           
              <li role="presentation" id="presentationTab2" className="">
								<a href="#tab2" aria-controls="tab2" role="tab" data-toggle="tab" onClick={()=>this.headerChange(2)}> Neighborhood </a>
							</li>

              <li role="presentation" className="">
								<a href="#tab6" aria-controls="tab6" role="tab" data-toggle="tab" onClick={()=>this.headerChange(6)}> Parking & Keys </a>
							</li>
							
							
							
						</ul>
            <div id="tabs-content" className="tab-content panel-group">
            {/* <div className="panel-heading" role="tab" id="heading1">
													<a href="#tab1" className="collapsed" role="button" data-toggle="collapse" data-parent="tabs-content" aria-expanded="true"
													   aria-controls="tab1" onClick={()=>this.headerChange(1)}>FLOORPLANS</a>
												</div>
												<div id="tab1" role="tabpanel" className="tab-pane animated rollIn  panel-collapse collapse " aria-labelledby="heading1" >
                        <FloorPlan {...this.state} floorCorousel={this.floorCorousel} floorImages={this.floorImages} gallaeryCorousel={this.gallaeryCorousel}
                        follorIn3d={this.follorIn3d}
                        />
												</div> */}
                        <div className="panel-heading" role="tab" id="heading3">
													<a href="#tab3" className="collapsed" role="button" data-toggle="collapse" data-parent="tabs-content"
													   aria-expanded="true" aria-controls="tab3" onClick={()=>this.headerChange(3)}>Amenities & Inclusions</a>
												</div>
												<div id="tab3" role="tabpanel" className="tab-pane animated  zoomIn panel-collapse collapse" aria-labelledby="heading3">
                        <Amenities {...this.state} amenities={this.amenities} amentiesModalPopup={this.amentiesModalPopup} type="lakside"/>
                        </div>
                        <div className="panel-heading" role="tab" id="heading4">
													<a href="#tab4" className="collapsed" role="button" data-toggle="collapse" data-parent="tabs-content"
													   aria-expanded="true" aria-controls="tab4" onClick={()=>this.headerChange(4)}>Photos & Floorplans</a>
												</div>
												<div id="tab4" role="tabpanel" className="tab-pane animated rotateIn panel-collapse collapse" aria-labelledby="heading4">
                        {/* <PhotoGallery {...this.state} gallaeryCorousel={this.gallaeryCorousel} /> */}
                        {this.state.photomodal? <PhotoModal closeModal={this.closeModal} CommunityImages={this.state.photosFloorPlans} type="lakside"/>:<div></div>}
                        </div>

                        <div className="panel-heading" role="tab" id="heading2">
													<a href="#tab2" className="collapsed" role="button" data-toggle="collapse" data-parent="tabs-content"
													   aria-expanded="true" aria-controls="tab2" onClick={()=>this.headerChange(2)}>Neighborhood</a>
												</div>
												<div id="tab2" role="tabpanel" className="tab-pane animated bounceInUp panel-collapse collapse" aria-labelledby="heading2">
												<NeighBoorHood {...this.state} handleFormSubmit={this.handleFormSubmit}/> 
												</div>

                        <div className="panel-heading" role="tab" id="heading6">
													<a href="#tab6" className="collapsed" role="button" data-toggle="collapse" data-parent="tabs-content" aria-expanded="true"
													   aria-controls="tab6" onClick={()=>this.headerChange(6)}>Parking & Keys</a>
												</div>
												<div id="tab6" role="tabpanel" className="tab-pane animated rollIn  panel-collapse collapse " aria-labelledby="heading6" >
                        <ParkingInfo type="lakside"/>
												</div>

												
                          
                        {/* <div  role="tab" id="heading4">
													<a href="#tab5" className="collapsed" role="button" data-toggle="collapse" data-parent="tabs-content"
													   aria-expanded="true" aria-controls="tab4" ></a>
												</div>
                        <div id="tab5" role="tabpanel" className="tab-pane animated bounceInUp  active panel-collapse collapse in" aria-labelledby="heading1">
                        {this.state.dataId==0&&<Home {...this.state} GotoLatchAccess={()=>this.headerChange(7)}/>}
                        </div> */}
                      {/*  {this.state.dataId==0&&<div><button type="button" className="btn main-btn">Contact Your Altair Consultant</button></div>} */}
                        
            </div>
            </div>
        </div>
        {this.state.floorModal? <FloorModal floorObject={this.state.floorObject}/>:''}
        {this.state.floor3d? <ModalVideo {...this.state} closeModal={this.closeModal}/>:''}
        {/* {this.state.photomodal? <PhotoModal closeModal={this.closeModal} CommunityImages={JSON.parse(this.state.communityResponse.CommunityImages)}/>:''} */}
        {this.state.amenitiesModal?<AmenitiesModal {...this.state} />:''}
        {this.state.faqsModal?<Faqs/>:''}
        {/* {this.state.latchAccessModal?<Latchaccess/>:''} */}
        {/* {this.state.parkingInfoModal?<ParkingInfo/>:''} */}
      </div>
      
        </div>
        {/* <SubFooter/>
        <Footer/> */}
      </div>
    );
  }
}
export default LakSide;