import React from "react";
import GuestSidebar from "./guestSidebar";
import GuestServicesapi from "../../../../services/guestServicesapi";
import SessionService from "../../../../services/sessionService";
import Loader from "./loader";

class NewGuestService extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userData: "",
      selectedOrder: "",
      serviceInfoReq: [],
      openResponse: [],
      closeResponse: [],
      serviceCategory: [],
      loader: false,
      serviceReqImg: "",
      serviceReqName: "",
      tabIndex: this.props.location.orderData
        ? this.props.location.orderData.OrderType == "H"
          ? 4
          : 0
        : 0,
      categoryCode: "",
      uniqueId: "",
      whichRoom: "",
      roomuid: "",
      roomServiceCategory: [],
      whatsHappeningInfo: [],
      errMsg: false,
      specificPlaceInfo: [],
      whichPlace: "",
      roomtypeuid: "",
      isPte: true,
      description: "",
      descriptionValid: false,
      activeOrderInfo: [],
      pendingOrderInfo: [],
      activeandPendingOrdersInfo: [],
      isActiveandPendingOrdersInfoExists: false,
      whatsHappening: "",
      whatsHappeningUniqueId: "",
      IsActiveOrderExists: true,
      showSuccessMsg: false,
      hideBackButton: false,
      status: "open",
      openRequestStatus: false,
      foldOpenIndex: 0,
      foldOrOpen: {},
      allResponse: [],
      orderInfo: "",
      statusMsg: "",
      selectedOrderInfo: this.props.location.orderData
        ? this.props.location.orderData
        : {},
      OrderType: this.props.location.orderData
        ? this.props.location.orderData.OrderType
        : "",
      selectedData: {},
      termedOrderInfo: [],
      selectedService: "",
      loader2: false,
      modelcommunityname: "",
    };
    this.description = this.description.bind(this);
  }

  async componentDidMount() {
    await this.setState({ loader: true });
    let locdata = await this.props.location.orderData;
    if (locdata) {
      await this.setState({ modelcommunityname: locdata.Community });
      $("#myModalInstructions").modal("show");
    }

    await SessionService.SessionGet({ type: "guest" }).then((response) => {
      this.setState({ userData: response });
    });

    await this.getTermedOrderInfo(this.state.userData.GuestEmail);
    await this.getPendingOrderInfo(this.state.userData.GuestEmail);
    await this.getActiveOrderInfo(this.state.userData.GuestEmail);

    await this.getServicesCategory();

    $(document).ready(function () {
      $("th").each(function (col) {
        $(this).hover(
          function () {
            $(this).addClass("focus");
          },
          function () {
            $(this).removeClass("focus");
          }
        );
        $(this).click(function () {
          let sortOrder;
          if ($(this).is(".asc")) {
            $(this).removeClass("asc");
            $(this).addClass("desc selected");
            sortOrder = -1;
          } else {
            $(this).addClass("asc selected");
            $(this).removeClass("desc");
            sortOrder = 1;
          }
          $(this).siblings().removeClass("asc selected");
          $(this).siblings().removeClass("desc selected");
          var arrData = $("table").find("tbody >tr:has(td)").get();
          arrData.sort(function (a, b) {
            var val1 = $(a).children("td").eq(col).text().toUpperCase();
            var val2 = $(b).children("td").eq(col).text().toUpperCase();
            if ($.isNumeric(val1) && $.isNumeric(val2))
              return sortOrder == 1 ? val1 - val2 : val2 - val1;
            else return val1 < val2 ? -sortOrder : val1 > val2 ? sortOrder : 0;
          });
          $.each(arrData, function (index, row) {
            $("tbody").append(row);
          });
        });
      });
    });
  }
  async getTermedOrderInfo(email) {
    var guestEmailObj = {
      guestEmail: email,
      status: "termed",
    };
    await GuestServicesapi.GetOrdersByGuestEmail(guestEmailObj).then(
      (response) => {
        if (response.error !== 1) {
          var result = response.message.Orders;
          let termedInfo = result.map((a) => a.OrderId);
          this.setState({ termedOrderInfo: termedInfo });
        }
        if (response.message.Orders.length == 0) {
          this.setState({ termedOrderInfo: [] });
        }
      }
    );
  }
  /*
   *Function for seperating particular object from array based on particular property
   */
  groupBy(arr, property) {
    return arr.reduce(function (memo, x) {
      if (!memo[x[property]]) {
        memo[x[property]] = [];
      }
      memo[x[property]].push(x);
      return memo;
    }, {});
  }

  /*
   *Function is to get categories
   */
  async getServicesCategory() {
    this.getServiceInfoByUserId();

    GuestServicesapi.GetServicesCatergory({ type: "category" }).then(
      (response) => {
        if (response.error !== 1) {
          this.setState({
            serviceCategory: response.message,
            loader: false,
          });
        } else {
          this.setState({
            loader: false,
          });
        }
      }
    );

    let activeandPendingOrdersInfo = this.state.activeOrderInfo.concat(
      this.state.pendingOrderInfo
    );
    activeandPendingOrdersInfo = activeandPendingOrdersInfo.filter(
      (e) => e.OrderType == "S"
    );
    this.setState({
      activeandPendingOrdersInfo,
      isActiveandPendingOrdersInfoExists: this.props.location.orderData
        ? false
        : activeandPendingOrdersInfo.length > 0
        ? true
        : false,
    });
  }

  getServiceInfoByUserId() {
    let userInfoObj = {
      email: this.state.userData.GuestEmail,
      userId: this.state.userData.Id,
    };

    GuestServicesapi.GetServicesInfoRequestByUserId(userInfoObj).then(
      (response) => {
        if (response.length > 0) {
          response.forEach((item, index) => {
            if (item.Status == "Not Started") {
              item.Status = "Open";
            }
            if (item.Status == "on hold") {
              item.Status = "On-hold";
            }
            if (item.Status == "started") {
              item.Status = "In-progress";
            }
            if (item.Status == "completed") {
              item.Status = "Completed";
            }
          });
          var groupByResponse = this.groupBy(response, "Status");
          let newResponse = Object.assign({}, groupByResponse);
          delete newResponse["Completed"];
          let openResponse = Object.keys(newResponse).reduce(function (
            acc,
            val
          ) {
            return acc.concat(newResponse[val]);
          },
          []);

          this.setState({
            openResponse,
            closeResponse:
              groupByResponse.Completed != undefined
                ? groupByResponse.Completed
                : "",
            serviceInfoReq: response,
          });
        }
      }
    );
  }

  /*
   *Assigning Selected Room to state
   */
  async pickWhichRoom(room, id) {
    if (this.state.whichRoom) {
      if (this.state.whichRoom !== room) {
        this.setState({
          whichRoom: room,
          whichPlace: "",
          description: "",
          isPte: true,
          roomuid: id,
        });
      }
    } else {
      await this.setState({ whichRoom: room, roomuid: id });
    }
  }

  /*
   *Function is to get Room Services
   */
  async getRoomServices() {
    await this.setState({ loader: true });
    GuestServicesapi.GetRoomServices({ type: "room" }).then((response) => {
      if (response.error !== 1) {
        this.setState({
          roomServiceCategory: response.message,
          loader: false,
        });
      } else {
        this.setState({ loader: false });
      }
    });
  }

  async back() {
    this.setState({ descriptionValid: false });
    if (this.state.tabIndex == 4) {
      await this.setState({ tabIndex: this.state.tabIndex - 2, errMsg: false });
    } else {
      await this.setState({ tabIndex: this.state.tabIndex - 1, errMsg: false });
    }
  }

  async next() {
    if (this.state.tabIndex == 1) {
      if (this.state.whichRoom == "") {
        this.setState({ errMsg: true });
      } else {
        await this.setState({
          tabIndex: this.state.tabIndex + 1,
          errMsg: false,
        });
        this.specificPlace();
      }
    } else if (this.state.tabIndex == 2) {
      if (this.state.whichPlace == "") {
        this.setState({ errMsg: true });
      } else {
        await this.setState({ loader: true, errMsg: false });
        //Function is to get whats Happening Info
        GuestServicesapi.GetSubCategoryDescription({
          category: this.state.serviceReqName.replace("/", "-"),
          // category: this.state.serviceReqName.replace('/', '$$'),
          room: this.state.whichRoom,
          subcategory: this.state.whichPlace.replace("/", "-"),
          // subcategory: this.state.whichPlace.replace('/', '$$')
        }).then((response) => {
          if (response.message.length > 0) {
            this.setState({
              whatsHappeningInfo: response.message,
              loader: false,
              tabIndex: this.state.tabIndex + 2,
            });
          } else if (response.message.length == 0) {
            this.setState({ loader: false, tabIndex: this.state.tabIndex + 2 });
          }
        });
      }
    } else if (this.state.tabIndex == 3) {
      if (this.state.whatsHappening == "") {
        this.setState({ errMsg: true });
      } else {
        await this.setState({
          tabIndex: this.state.tabIndex + 1,
          errMsg: false,
        });
      }
    }
  }

  /*
   *Function is to get Places
   */
  async specificPlace() {
    await this.setState({ loader: true });
    if (this.state.whichRoom == "OTHER") {
      GuestServicesapi.GetSubCategoryService({
        type: "room",
        category: this.state.serviceReqName.replace("/", "-"),
        room: this.state.whichRoom,
      }).then((response) => {
        if (response.error !== 1) {
          let specificPlaceInfo = response.message;
          var sortingData = _.sortBy(specificPlaceInfo, "Display");
          sortingData.some(
            (item, idx) =>
              (item.Display == "MASTER BEDROOM" ||
                item.Display == "MASTER BATHROOM") &&
              sortingData.unshift(
                // remove the found item, in-place (by index with splice),
                // returns an array of a single item removed
                sortingData.splice(idx, 1)[0]
              )
          );
          this.setState({
            specificPlaceInfo: sortingData,
            loader: false,
          });
        } else {
          this.setState({ loader: false });
        }
      });
    } else {
      GuestServicesapi.GetRoomTypeService({
        type: "roomtype",
        room: this.state.whichRoom,
      }).then((response) => {
        if (response.error !== 1) {
          let specificPlaceInfo = response.message;
          var sortingData = _.sortBy(specificPlaceInfo, "Display");
          sortingData.some(
            (item, idx) =>
              (item.Display == "MASTER BEDROOM" ||
                item.Display == "MASTER BATHROOM") &&
              sortingData.unshift(
                // remove the found item, in-place (by index with splice),
                // returns an array of a single item removed
                sortingData.splice(idx, 1)[0]
              )
          );
          this.setState({
            specificPlaceInfo: sortingData,
            loader: false,
          });
        } else {
          this.setState({ loader: false });
        }
      });
    }
  }

  /*
   *Assigning Selected Place to state
   */
  pickWhichPlace(place, id) {
    if (this.state.whichPlace) {
      if (this.state.whichPlace !== place) {
        this.setState({
          description: "",
          isPte: true,
          whichPlace: place,
          roomtypeuid: id,
        });
      }
    } else {
      this.setState({ whichPlace: place, roomtypeuid: id });
    }
  }

  isPte() {
    this.setState({ isPte: !this.state.isPte });
  }

  description(e) {
    let value = e.target.value.replace(/^\\s*/g, "");
    if (value !== " ") {
      this.setState({ description: e.target.value });
    }
  }

  async submit() {
    await this.setState({ loader: true });
    if (this.state.description !== "") {
      await this.getActiveOrderInfo(this.state.userData.GuestEmail);
      var serviceWebReqDetails = {
        CategoryCode: this.state.categoryCode,
        CreatedBy: this.state.userData.GuestEmail,
        CreatedDate: moment().format("YYYY/MM/DD LT"),
        Description: this.state.description,
        LeaseUId: this.state.selectedOrderInfo.MasterUid,
        RequestDate: moment().format("YYYY/MM/DD LT"),
        Requested: this.state.userData.GuestFirstName,
        community:
          this.state.OrderType == "S"
            ? this.state.selectedOrderInfo.Community
            : this.state.selectedOrderInfo.HotelName,
        orderUid: this.state.selectedOrderInfo.OrderUId,
        petAllowed: this.state.isPte == false ? false : true,
        IsPte: this.state.isPte == false ? false : true,
        roomuid: this.state.roomuid,
        roomtypeuid: this.state.roomtypeuid,
        SubCategory:
          this.state.whatsHappening == "" ? "" : this.state.whatsHappening,
        ServiceDescriptionUId:
          this.state.whatsHappeningUniqueId == ""
            ? ""
            : this.state.whatsHappeningUniqueId,
        Mode: true,
      };
      GuestServicesapi.SaveServiceWebRequestDetails(serviceWebReqDetails).then(
        (response) => {
          if (response.message == "Inserted Successfully") {
            this.setState({
              showSuccessMsg: true,
              loader: false,
              hideBackButton: true,
              descriptionValid: false,
              statusMsg: "Request Submitted Successfully.",
            });
            setTimeout(async () => {
              await this.closeServiceRequest();
              $("#myModalInstructions").modal("hide");
              $("body").removeClass("modal-open");
              $(".modal-backdrop").remove();
              this.setStatusOpen();
            }, 4000);
          } else {
            this.setState({
              loader: false,
              statusMsg: "Failed to Submit. Please try again.",
            });
          }
        }
      );
    } else {
      this.setState({ descriptionValid: true, loader: false });
    }
  }

  /*
   *Function to get Active Orders for logged in user
   */
  async getActiveOrderInfo(email) {
    var guestEmailObj = {
      guestEmail: email,
      status: "active",
    };

    await GuestServicesapi.GetOrdersByGuestEmail(guestEmailObj).then(
      (response) => {
        if (response.error !== 1) {
          this.setState({ activeOrderInfo: response.message.Orders });
        }
      }
    );
  }

  async setStatusOpen() {
    await this.setState({
      status: "open",
      loader: true,
      openRequestStatus: false,
      foldOrOpen: {},
    });
    await this.getServiceInfoByUserId();
    this.setState({ loader: false });
  }

  foldOrOpenNotes(index) {
    let foldOrOpen = { [index]: !this.state.foldOrOpen[index] };
    let obj = Object.assign(this.state.foldOrOpen, foldOrOpen);
    this.setState({ foldOrOpen: obj });
  }

  async setStatusClose() {
    await this.setState({
      status: "close",
      loader: true,
      openRequestStatus: false,
      foldOrOpen: {},
    });
    await this.getServiceInfoByUserId();
    this.setState({ loader: false });
  }

  async getAllServiceRequests() {
    await this.setState({ loader: true, foldOrOpen: {} });
    await this.getServiceInfoByUserId();
    // let allResponse = this.state.openResponse.concat(this.state.closeResponse);
    let allResponse = "";
    if (this.state.openResponse != "" && this.state.closeResponse != "") {
      allResponse = this.state.openResponse.concat(this.state.closeResponse);
    } else if (this.state.openResponse != "") {
      allResponse = this.state.openResponse;
    } else if (this.state.closeResponse != "") {
      allResponse = this.state.closeResponse;
    }

    await this.setState({ allResponse, status: "all" });
    await this.setState({ loader: false });
  }

  async closeServiceRequest() {
    await this.setState({ loader: true });
    let locdatas = await this.props.location.orderData;
    if (locdatas) {
      await this.showCommunityDetailsbyOrder(
        this.props.location.orderData,
        "details"
      );
    }
    await this.setState({
      selectedService: "",
      serviceInfoReq: [],
      loader: false,
      serviceReqImg: "",
      serviceReqName: "",
      tabIndex: 0,
      categoryCode: "",
      uniqueId: "",
      whichRoom: "",
      roomuid: "",
      whatsHappeningInfo: [],
      errMsg: false,
      specificPlaceInfo: [],
      whichPlace: "",
      roomtypeuid: "",
      isPte: true,
      description: "",
      descriptionValid: false,
      whatsHappening: "",
      whatsHappeningUniqueId: "",
      IsActiveOrderExists: true,
      showSuccessMsg: false,
      hideBackButton: false,
      status: "open",
      openRequestStatus: false,
      foldOpenIndex: 0,
      foldOrOpen: {},
      selectedOrder: "",
      isActiveandPendingOrdersInfoExists: this.props.location.orderData
        ? false
        : this.state.activeandPendingOrdersInfo.length > 0
        ? true
        : false,
      statusMsg: "",
    });
  }

  async showCommunityDetailsbyOrder(orderInfo) {
    let findOrderId = this.state.termedOrderInfo.find(
      (o) => o == orderInfo.OrderId
    );
    if (findOrderId != undefined) {
      await GuestServicesapi.GetGuestInfoCommunityByEmailId({
        orderid: orderInfo.OrderId,
      }).then((response) => {
        let obj = {};
        obj["OrderType"] = "S";
        obj["Community"] = orderInfo.CommunityName;
        obj["address"] = response.address;
        obj["city_name"] = response.city_name;
        obj["statename"] = response.statename;
        obj["ZipCode"] = response.ZipCode;
        obj["orderno"] = response.orderno;
        obj["image_name"] = response.image_name;
        obj["MoveInDate"] = response.MoveInDate;
        obj["MoveOutDate"] = response.MoveOutDate;
        this.setState({ selectedData: obj });
        $("#modalFullBookingDetails").modal("show");
      });
    } else {
      var orderUIdObj = {
        OrderUId: orderInfo.OrderUId,
      };
      await GuestServicesapi.GetOrderInfoById(orderUIdObj)
        .then((getOrderInfo) => {
          if (getOrderInfo.error == 0) {
            let obj = getOrderInfo.message.orderInfo;
            obj["ComplexId"] = obj.CommunityId;
            this.props.history.push({
              pathname: "/my-reservations",
              state: obj,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  async getPendingOrderInfo(email) {
    // this.setState({ loader: true })
    var guestEmailObj = {
      guestEmail: email,
      status: "pending",
    };
    await GuestServicesapi.GetOrdersByGuestEmail(guestEmailObj).then(
      (response) => {
        if (response.error !== 1) {
          var pendingInfo = response.message.Orders;

          pendingInfo.forEach((item, index) => {
            var community = item.Community;
            community = community && community.split(" ");

            var city = item.City;
            city = city && city.split(" ");

            var state = item.State;
            state = state && state.split(" ");

            for (var i = 0, x = community && community.length; i < x; i++) {
              community[i] =
                community[i][0].toUpperCase() + community[i].substr(1);
            }

            for (var i = 0, x = city && city.length; i < x; i++) {
              city[i] = city[i][0].toUpperCase() + city[i].substr(1);
            }

            for (var i = 0, x = state && state.length; i < x; i++) {
              state[i] = state[i][0].toUpperCase() + state[i].substr(1);
            }

            state = state && state.join(" ");
            city = city && city.join(" ");
            community = community && community.join(" ");
            state = state && state.charAt(0).toUpperCase() + state.substr(1);
            city = city && city.charAt(0).toUpperCase() + city.substr(1);
            community =
              community &&
              community.charAt(0).toUpperCase() + community.substr(1);
            pendingInfo[index].State = state;
            pendingInfo[index].City = city;
            pendingInfo[index].Community = community;
            this.setState({ pendingOrderInfo: pendingInfo });
          });
        }
      }
    );
  }

  async pickOrderId(data) {
    let obj = data;
    GuestServicesapi.GetGuestInfoCommunityByEmailId({
      orderid: data.OrderId,
    }).then((response) => {
      obj["CommunityAddress"] = response.address;
      obj["CommunityCity"] = response.city_name;
      obj["CommunityState"] = response.statename;
    });
    await this.setState({
      modelcommunityname: obj.Community,
      isActiveandPendingOrdersInfoExists: false,
      selectedOrderInfo: obj,
      OrderType: data.OrderType,
      tabIndex: data.OrderType == "S" ? 0 : 4,
    });
  }

  async setValue(image, name, code, id) {
    if (this.state.categoryCode) {
      this.setState({
        whichRoom: "",
        whichPlace: "",
        description: "",
        isPte: true,
        serviceReqImg: "",
        serviceReqName: name,
        categoryCode: code,
        tabIndex: this.state.tabIndex + 1,
        uniqueId: id,
      });
    } else {
      await this.setState({
        serviceReqImg: "",
        serviceReqName: name,
        categoryCode: code,
        tabIndex: this.state.tabIndex + 1,
        uniqueId: id,
      });
    }
    await this.getRoomServices();
    this.setState({
      selectedService: name,
    });
  }
  async openCommunitymodel() {
    await this.setState({ modelcommunityname: "" });
    $("#myModalInstructions").modal("show");
  }

  render() {
    return (
      <div className="SAweb_updateUi">
        <div className="SAwebGuest_reservePage bg-white">
          <GuestSidebar />
          <div id="content">
            <div className="SAwebGuest_arrivalInfo SAwebGuest_serviceInfo ">
              <div className="heading closeIcon">
                <h3>Service Request </h3>
                {/* <span>101 San Fernando</span> */}
                {/* <i className="bi bi-x"></i> */}
              </div>
              <ul className="nav nav-tabs">
                <li>
                  <a onClick={() => this.openCommunitymodel()}>New</a>
                </li>
                <li className="active">
                  <a
                    data-toggle="tab"
                    href="#tabOpen"
                    onClick={() => this.setStatusOpen()}
                  >
                    Open
                  </a>
                </li>
                <li>
                  <a
                    data-toggle="tab"
                    onClick={() => this.setStatusClose()}
                    href="#tabClosed"
                  >
                    Closed
                  </a>
                </li>
              </ul>
              <div className="tab-content serviceRequestTabs">
                <div id="tabNew" className="tab-pane fade ">
                  <h4>Let us know if you need help with something!</h4>

                  <div className="newServcieId">
                    <div className="item">
                      <img
                        className="ele"
                        src="images/guestportal/sr-images/electrical.png"
                        alt=""
                      />
                      Electrical
                    </div>
                    <div className="item">
                      <img
                        src="images/guestportal/sr-images/furniture.png"
                        alt=""
                      />
                      Furniture
                    </div>
                    <div className="item">
                      <img
                        src="images/guestportal/sr-images/plumbing.png"
                        alt=""
                      />
                      Plumbing
                    </div>
                    <div className="item">
                      <img
                        src="images/guestportal/sr-images/pests.png"
                        alt=""
                      />
                      Pests
                    </div>
                    <div className="item">
                      <img src="images/guestportal/sr-images/keys.png" alt="" />
                      Keys
                    </div>
                    <div className="item">
                      <img
                        src="images/guestportal/sr-images/appliances.png"
                        alt=""
                      />
                      Appliances
                    </div>
                    <div className="item">
                      <img
                        src="images/guestportal/sr-images/housekeeping.png"
                        alt=""
                      />
                      Housekeeping
                    </div>
                    <div className="item">
                      <img
                        src="images/guestportal/sr-images/heating.png"
                        alt=""
                      />
                      Heating/Air
                    </div>
                    <div className="item">
                      <img
                        src="images/guestportal/sr-images/wifi_cable.png"
                        alt=""
                      />
                      WiFi/Cable
                    </div>
                    <div className="item">
                      <img
                        src="images/guestportal/sr-images/housewares.png"
                        alt=""
                      />
                      Housewares
                    </div>
                    <div className="item">
                      <img
                        src="images/guestportal/sr-images/windows.png"
                        alt=""
                      />
                      Windows
                    </div>
                    <div className="item">Other Repairs</div>
                  </div>
                </div>
                {this.state.status == "open" ? (
                  <div id="tabOpen" className="tab-pane fade in active">
                    <div className="serviceRequestOpenTable ">
                      <div className="table-responsive serviceInfo">
                        {this.state.loader ? (
                          <Loader />
                        ) : (
                          <table width="100%">
                            <thead className="border-bottom">
                              <tr>
                                <th>Request ID</th>
                                <th>Community</th>
                                <th>Type</th>
                                <th>Location</th>
                                <th>Service Date</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.openRequestStatus == false ? (
                                this.state.openResponse.length > 0 ? (
                                  this.state.openResponse.map(
                                    (openResponseInfo, i) => {
                                      return (
                                        <React.Fragment key={i}>
                                          <tr
                                            className={
                                              !this.state.foldOrOpen[i]
                                                ? "open"
                                                : "view open"
                                            }
                                          >
                                            <td>
                                              #{openResponseInfo.SerivceId}
                                            </td>
                                            <td>
                                              <a
                                                onClick={() =>
                                                  this.showCommunityDetailsbyOrder(
                                                    openResponseInfo,
                                                    "service"
                                                  )
                                                }
                                              >
                                                {openResponseInfo.CommunityName}
                                              </a>
                                            </td>
                                            <td>
                                              {openResponseInfo.CategoryCode}
                                            </td>
                                            <td>
                                              {openResponseInfo.RoomType ||
                                                "OTHER"}
                                            </td>
                                            <td>
                                              {moment(
                                                openResponseInfo.EntryDate
                                              ).format("Do MMM YYYY")}
                                            </td>
                                            {/* <td>{moment(openResponseInfo.EntryDate).format("Do MMM YYYY") + ' - ' + moment(openResponseInfo.EntryDate).format('LT')}</td> */}
                                            <td>
                                              <i
                                                style={
                                                  openResponseInfo.Status ==
                                                  "Open"
                                                    ? { color: "#d3d3d3" }
                                                    : openResponseInfo.Status ==
                                                      "On-hold"
                                                    ? { color: "red" }
                                                    : openResponseInfo.Status ==
                                                      "In-progress"
                                                    ? { color: "yellow" }
                                                    : openResponseInfo.Status ==
                                                      "Completed"
                                                    ? { color: "#90ee90" }
                                                    : { color: "white" }
                                                }
                                                className="fa fa-circle"
                                              />
                                              {" " + openResponseInfo.Status}
                                            </td>
                                          </tr>
                                        </React.Fragment>
                                      );
                                    }
                                  )
                                ) : this.state.loader == false &&
                                  this.state.openRequestStatus.length == 0 ? (
                                  <tr>
                                    <td className="validationTag" colSpan="6">
                                      <p
                                        style={{
                                          color: "red",
                                          marginTop: "35px",
                                          textAlign: "center",
                                        }}
                                      >
                                        No open requests found.
                                      </p>
                                    </td>
                                  </tr>
                                ) : null
                              ) : null}
                            </tbody>
                          </table>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div id="tabClosed" className="tab-pane fade">
                    <div className="serviceRequestOpenTable ">
                      <div className="table-responsive serviceInfo">
                        {this.state.loader == true ? (
                          <Loader />
                        ) : (
                          <table width="100%">
                            <thead className="border-bottom">
                              <tr>
                                <th>Request ID</th>
                                <th>Community</th>
                                <th>Type</th>
                                <th>Location</th>
                                <th>Service Date</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.closeResponse.length > 0 ? (
                                this.state.closeResponse.map(
                                  (openResponseInfo, i) => {
                                    return (
                                      <React.Fragment key={i}>
                                        <tr className="open">
                                          <td>#{openResponseInfo.SerivceId}</td>
                                          <td>
                                            <a
                                              onClick={() =>
                                                this.showCommunityDetailsbyOrder(
                                                  openResponseInfo
                                                )
                                              }
                                            >
                                              {openResponseInfo.CommunityName}
                                            </a>
                                          </td>
                                          <td>
                                            {openResponseInfo.CategoryCode}
                                          </td>
                                          <td>
                                            {openResponseInfo.RoomType ||
                                              "OTHER"}
                                          </td>
                                          <td>
                                            {moment(
                                              openResponseInfo.EntryDate
                                            ).format("Do MMM YYYY")}
                                          </td>
                                          {/* <td>{moment(openResponseInfo.EntryDate).format("Do MMM YYYY") + ' - ' + moment(openResponseInfo.EntryDate).format('LT')}</td> */}
                                          <td>
                                            <i
                                              style={
                                                openResponseInfo.Status ==
                                                "Open"
                                                  ? { color: "#d3d3d3" }
                                                  : openResponseInfo.Status ==
                                                    "On-hold"
                                                  ? { color: "red" }
                                                  : openResponseInfo.Status ==
                                                    "In-progress"
                                                  ? { color: "yellow" }
                                                  : openResponseInfo.Status ==
                                                    "Completed"
                                                  ? { color: "#90ee90" }
                                                  : { color: "white" }
                                              }
                                              className="fa fa-circle"
                                            />
                                            {" " + openResponseInfo.Status}
                                          </td>
                                        </tr>
                                      </React.Fragment>
                                    );
                                  }
                                )
                              ) : this.state.loader == false &&
                                this.state.openRequestStatus.length == 0 ? (
                                <tr>
                                  <td className="validationTag" colSpan="6">
                                    <p
                                      style={{
                                        color: "red",
                                        marginTop: "35px",
                                        textAlign: "center",
                                      }}
                                    >
                                      No closed requests found.
                                    </p>
                                  </td>
                                </tr>
                              ) : null}
                            </tbody>
                          </table>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {this.state.tabIndex == 0 ? (
            <div
              className="modal modal-fullscreen-xl modal fade animated tab-modal guest-Ui SAweb_modalPoup"
              id="myModalInstructions"
              tabIndex={-1}
              data-backdrop="static"
              data-keyboard="false"
              role="dialog"
              aria-hidden="true"
            >
              {this.state.isActiveandPendingOrdersInfoExists && (
                <div className="modal-dialog" role="document">
                  <div className="modal-content newSRView">
                    <div className="servicerequest-pop-header ">
                      <h2>New Service Request</h2>
                      <h4>Select Your Reservation</h4>
                      <button
                        type="button"
                        className="btn close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => this.closeServiceRequest()}
                      >
                        <i className="bi bi-x"></i>
                      </button>
                    </div>
                    <div className="modal-body">
                      {this.state.loader ? (
                        <Loader />
                      ) : (
                        <div className="SR-tials SR-slectOrID">
                          <div className="">
                            <div className="home-slider" key={i}>
                              {this.state.activeandPendingOrdersInfo.length > 0
                                ? this.state.activeandPendingOrdersInfo.map(
                                    (orderInfo, i) => {
                                      return (
                                        <div className="card" key={i}>
                                          <img
                                            src={
                                              orderInfo.CommunityImage ==
                                                null ||
                                              orderInfo.CommunityImage == ""
                                                ? orderInfo.OrderType == "S"
                                                  ? "../images/dummyCommunity.jpg"
                                                  : "../images/s-dummyImage-2.png"
                                                : orderInfo.CommunityImage
                                            }
                                            alt=""
                                            className="card-img-top "
                                          />
                                          <div className="card-body">
                                            {orderInfo.OrderType == "S" ? (
                                              <h1>{orderInfo.Community}</h1>
                                            ) : (
                                              <h1>{orderInfo.HotelName}</h1>
                                            )}
                                            {orderInfo.OrderType == "S" && (
                                              <p
                                                style={{
                                                  textTransform: "capitalize",
                                                }}
                                              >
                                                {orderInfo.City.toLowerCase()},{" "}
                                                {orderInfo.State.toLowerCase()}
                                              </p>
                                            )}
                                            {orderInfo.OrderType == "H" && (
                                              <p
                                                style={{
                                                  textTransform: "capitalize",
                                                }}
                                              >
                                                {orderInfo.HotelCity},{" "}
                                                {orderInfo.HotelState}
                                              </p>
                                            )}
                                            <span className="btm">
                                              <a href="#">
                                                {moment(
                                                  orderInfo.MoveInDate
                                                ).format("DD")}{" "}
                                                {moment(
                                                  orderInfo.MoveInDate
                                                ).format("MMMM")}{" "}
                                                {moment(
                                                  orderInfo.MoveInDate
                                                ).format("YYYY")}{" "}
                                                -{" "}
                                                {moment(
                                                  orderInfo.MoveOutDate
                                                ).format("DD")}{" "}
                                                {moment(
                                                  orderInfo.MoveOutDate
                                                ).format("MMMM")}{" "}
                                                {moment(
                                                  orderInfo.MoveOutDate
                                                ).format("YYYY")}{" "}
                                              </a>
                                              <button
                                                type="button"
                                                className="btn"
                                                onClick={() =>
                                                  this.pickOrderId(orderInfo)
                                                }
                                              >
                                                Continue
                                              </button>
                                            </span>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )
                                : null}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {this.state.isActiveandPendingOrdersInfoExists == false && (
                <div className="modal-dialog" role="document">
                  <div className="modal-content newSRView">
                    <div className="servicerequest-pop-header">
                      <h2>How can we help?</h2>
                      <h4>{this.state.modelcommunityname}</h4>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => this.closeServiceRequest()}
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>

                    <div className="modal-body">
                      {this.state.loader ? (
                        <Loader />
                      ) : (
                        <div className="SAwebGuest_arrivalInfo">
                          <div className="SR-tials">
                            {this.state.serviceCategory.length > 0
                              ? this.state.serviceCategory.map(
                                  (serviceCategoryResponse) => {
                                    return (
                                      // <div className={serviceCategoryResponse.UniqueId == this.state.uniqueId ? "item" : "item"} key={serviceCategoryResponse.UniqueId} onClick={() => this.setValue(serviceCategoryResponse.ImageUrl, serviceCategoryResponse.Display, serviceCategoryResponse.Code, serviceCategoryResponse.UniqueId)}>

                                      <div
                                        className={
                                          serviceCategoryResponse.UniqueId ==
                                          this.state.uniqueId
                                            ? "item active"
                                            : "item"
                                        }
                                        key={serviceCategoryResponse.UniqueId}
                                        onClick={() =>
                                          this.setValue(
                                            serviceCategoryResponse.ImageUrl,
                                            serviceCategoryResponse.Display,
                                            serviceCategoryResponse.Code,
                                            serviceCategoryResponse.UniqueId
                                          )
                                        }
                                      >
                                        <img
                                          src={
                                            serviceCategoryResponse.Code ==
                                            "ELECTRICAL"
                                              ? this.state.categoryCode ==
                                                "ELECTRICAL"
                                                ? "assets/newUI-images/guestportal/icons/plug-2.png"
                                                : "assets/newUI-images/guestportal/icons/plug.png"
                                              : serviceCategoryResponse.Code ==
                                                "FURNITURE"
                                              ? this.state.categoryCode ==
                                                "FURNITURE"
                                                ? "assets/newUI-images/guestportal/icons/sofa-2.png"
                                                : "assets/newUI-images/guestportal/icons/sofa.png"
                                              : serviceCategoryResponse.Code ==
                                                "PLUMBING"
                                              ? this.state.categoryCode ==
                                                "PLUMBING"
                                                ? "assets/newUI-images/guestportal/icons/tap-2.png"
                                                : "assets/newUI-images/guestportal/icons/tap.png"
                                              : serviceCategoryResponse.Code ==
                                                "PESTS"
                                              ? this.state.categoryCode ==
                                                "PESTS"
                                                ? "assets/newUI-images/guestportal/icons/bug-2.png"
                                                : "assets/newUI-images/guestportal/icons/bug.png"
                                              : serviceCategoryResponse.Code ==
                                                "KEYS"
                                              ? this.state.categoryCode ==
                                                "KEYS"
                                                ? "assets/newUI-images/guestportal/icons/key-2.png"
                                                : "assets/newUI-images/guestportal/icons/key.png"
                                              : serviceCategoryResponse.Code ==
                                                "APPLIANCES"
                                              ? this.state.categoryCode ==
                                                "APPLIANCES"
                                                ? "assets/newUI-images/guestportal/icons/washing-machine-2.png"
                                                : "assets/newUI-images/guestportal/icons/washing-machine.png"
                                              : serviceCategoryResponse.Code ==
                                                "HOUSEKEEPING"
                                              ? this.state.categoryCode ==
                                                "HOUSEKEEPING"
                                                ? "assets/newUI-images/guestportal/icons/broom-2.png"
                                                : "assets/newUI-images/guestportal/icons/broom.png"
                                              : serviceCategoryResponse.Code ==
                                                "HEATING/AIR"
                                              ? this.state.categoryCode ==
                                                "HEATING/AIR"
                                                ? "assets/newUI-images/guestportal/icons/hot-2.png"
                                                : "assets/newUI-images/guestportal/icons/hot.png"
                                              : serviceCategoryResponse.Code ==
                                                "WIFI/CABLE"
                                              ? this.state.categoryCode ==
                                                "WIFI/CABLE"
                                                ? "assets/newUI-images/guestportal/icons/wifi-2.png"
                                                : "assets/newUI-images/guestportal/icons/wifi.png"
                                              : serviceCategoryResponse.Code ==
                                                "HOUSEWARES"
                                              ? this.state.categoryCode ==
                                                "HOUSEWARES"
                                                ? "assets/newUI-images/guestportal/icons/housewares-2.png"
                                                : "assets/newUI-images/guestportal/icons/housewares.png"
                                              : serviceCategoryResponse.Code ==
                                                "WINDOWS"
                                              ? this.state.categoryCode ==
                                                "WINDOWS"
                                                ? "assets/newUI-images/guestportal/icons/window-2.png"
                                                : "assets/newUI-images/guestportal/icons/window.png"
                                              : null
                                          }
                                          alt=""
                                        />
                                        <p>{serviceCategoryResponse.Display}</p>
                                      </div>
                                    );
                                  }
                                )
                              : null}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : this.state.tabIndex == 1 ? (
            <div
              className="modal modal-fullscreen-xl modal fade animated tab-modal guest-Ui SAweb_modalPoup"
              id="myModalInstructions"
              tabIndex={-1}
              data-backdrop="static"
              data-keyboard="false"
              role="dialog"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content newSRView">
                  <div className="servicerequest-pop-header ">
                    <h2>Let's get specific</h2>
                    <h4>{this.state.modelcommunityname}</h4>
                    <button
                      type="button"
                      className="btn close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => this.closeServiceRequest()}
                    >
                      <i className="bi bi-x"></i>
                    </button>
                  </div>

                  <div className="modal-body">
                    {this.state.loader ? (
                      <Loader />
                    ) : (
                      <div className="SAwebGuest_arrivalInfo">
                        <div className="serviceSelected">
                          <div className="type">
                            <img
                              src={
                                this.state.selectedService == "ELECTRICAL"
                                  ? "assets/newUI-images/guestportal/icons/plug.png"
                                  : this.state.selectedService == "FURNITURE"
                                  ? "assets/newUI-images/guestportal/icons/sofa.png"
                                  : this.state.selectedService == "PLUMBING"
                                  ? "assets/newUI-images/guestportal/icons/tap.png"
                                  : this.state.selectedService == "PESTS"
                                  ? "assets/newUI-images/guestportal/icons/bug.png"
                                  : this.state.selectedService == "KEYS"
                                  ? "assets/newUI-images/guestportal/icons/key.png"
                                  : this.state.selectedService == "APPLIANCES"
                                  ? "assets/newUI-images/guestportal/icons/washing-machine.png"
                                  : this.state.selectedService == "HOUSEKEEPING"
                                  ? "assets/newUI-images/guestportal/icons/broom.png"
                                  : this.state.selectedService == "HEATING/AIR"
                                  ? "assets/newUI-images/guestportal/icons/hot.png"
                                  : this.state.selectedService == "WIFI/CABLE"
                                  ? "assets/newUI-images/guestportal/icons/wifi.png"
                                  : this.state.selectedService == "HOUSEWARES"
                                  ? "assets/newUI-images/guestportal/icons/housewares.png"
                                  : this.state.selectedService == "WINDOWS"
                                  ? "assets/newUI-images/guestportal/icons/window.png"
                                  : null
                              }
                              alt=""
                            />
                            {this.state.selectedService}
                          </div>

                          <h4>What room are you in?</h4>

                          <div className="roomView ">
                            {this.state.roomServiceCategory.length > 0
                              ? this.state.roomServiceCategory.map(
                                  (roomServiceCategoryRes) => {
                                    return (
                                      <button
                                        key={roomServiceCategoryRes.UniqueId}
                                        className={
                                          this.state.whichRoom ==
                                          roomServiceCategoryRes.Display
                                            ? "btn active"
                                            : this.state.errMsg
                                            ? "btn validation"
                                            : "btn"
                                        }
                                        type="button"
                                        onClick={() =>
                                          this.pickWhichRoom(
                                            roomServiceCategoryRes.Display,
                                            roomServiceCategoryRes.UniqueId
                                          )
                                        }
                                        name={roomServiceCategoryRes.Display}
                                      >
                                        {roomServiceCategoryRes.Display}
                                      </button>
                                    );
                                  }
                                )
                              : null}
                          </div>

                          <div className="text-right">
                            <button
                              type="button"
                              className="btn"
                              onClick={() => this.back()}
                            >
                              {" "}
                              Cancel{" "}
                            </button>
                            <button
                              type="button"
                              className="btn black-btn next"
                              onClick={() => this.next()}
                            >
                              {" "}
                              Next{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : this.state.tabIndex == 2 ? (
            <div
              className="modal modal-fullscreen-xl modal fade animated tab-modal guest-Ui SAweb_modalPoup"
              id="myModalInstructions"
              tabIndex={-1}
              data-backdrop="static"
              data-keyboard="false"
              role="dialog"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content newSRView">
                  <div className="servicerequest-pop-header">
                    <h2>Where?</h2>
                    <h4>{this.state.modelcommunityname}</h4>
                    <button
                      type="button"
                      className="btn close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => this.closeServiceRequest()}
                    >
                      <i className="bi bi-x"></i>
                    </button>
                  </div>

                  <div className="modal-body SAwebGuest_arrivalInfo">
                    {this.state.loader ? (
                      <Loader />
                    ) : (
                      <div className="serviceSelected">
                        <div className="type">
                          <img
                            src={
                              this.state.selectedService == "ELECTRICAL"
                                ? "assets/newUI-images/guestportal/icons/plug.png"
                                : this.state.selectedService == "FURNITURE"
                                ? "assets/newUI-images/guestportal/icons/sofa.png"
                                : this.state.selectedService == "PLUMBING"
                                ? "assets/newUI-images/guestportal/icons/tap.png"
                                : this.state.selectedService == "PESTS"
                                ? "assets/newUI-images/guestportal/icons/bug.png"
                                : this.state.selectedService == "KEYS"
                                ? "assets/newUI-images/guestportal/icons/key.png"
                                : this.state.selectedService == "APPLIANCES"
                                ? "assets/newUI-images/guestportal/icons/washing-machine.png"
                                : this.state.selectedService == "HOUSEKEEPING"
                                ? "assets/newUI-images/guestportal/icons/broom.png"
                                : this.state.selectedService == "HEATING/AIR"
                                ? "assets/newUI-images/guestportal/icons/hot.png"
                                : this.state.selectedService == "WIFI/CABLE"
                                ? "assets/newUI-images/guestportal/icons/wifi.png"
                                : this.state.selectedService == "HOUSEWARES"
                                ? "assets/newUI-images/guestportal/icons/housewares.png"
                                : this.state.selectedService == "WINDOWS"
                                ? "assets/newUI-images/guestportal/icons/window.png"
                                : null
                            }
                            alt=""
                          />
                          {this.state.selectedService}
                        </div>

                        <div className="roomView border-1 border-dark border-top pt-3">
                          {this.state.specificPlaceInfo.length > 0
                            ? this.state.specificPlaceInfo.map(
                                (specificPlaceReq) => {
                                  return (
                                    <button
                                      key={specificPlaceReq.UniqueId}
                                      className={
                                        specificPlaceReq.Display ==
                                        this.state.whichPlace
                                          ? "btn active"
                                          : this.state.errMsg
                                          ? "btn validation"
                                          : "btn"
                                      }
                                      type="button"
                                      onClick={() =>
                                        this.pickWhichPlace(
                                          specificPlaceReq.Display,
                                          specificPlaceReq.UniqueId
                                        )
                                      }
                                    >
                                      {specificPlaceReq.Display}
                                    </button>
                                  );
                                }
                              )
                            : null}
                        </div>
                        <div className="text-right">
                          <button
                            className="btn"
                            type="button"
                            onClick={() => this.back()}
                          >
                            Cancel
                          </button>
                          <button
                            className="btn black-btn next"
                            type="button"
                            onClick={() => this.next()}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : this.state.tabIndex == 4 ? (
            <div
              className="modal modal-fullscreen-xl modal fade animated tab-modal guest-Ui SAweb_modalPoup"
              id="myModalInstructions"
              tabIndex={-1}
              data-backdrop="static"
              data-keyboard="false"
              role="dialog"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content newSRView">
                  <div className="servicerequest-pop-header">
                    <h2>Service Request</h2>
                    <h4>{this.state.modelcommunityname}</h4>
                    <button
                      type="button"
                      className="btn close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => this.closeServiceRequest()}
                    >
                      <i className="bi bi-x"></i>
                    </button>
                  </div>

                  <div className="modal-body SAwebGuest_arrivalInfo">
                    {this.state.loader ? (
                      <Loader />
                    ) : this.state.showSuccessMsg != true ? (
                      <div className="SR-tials-itemSelected">
                        <div className="items">
                          {this.state.OrderType == "S" && (
                            <div className="">
                              <div className="type">
                                <img
                                  src={
                                    this.state.selectedService == "ELECTRICAL"
                                      ? "assets/newUI-images/guestportal/icons/plug.png"
                                      : this.state.selectedService ==
                                        "FURNITURE"
                                      ? "assets/newUI-images/guestportal/icons/sofa.png"
                                      : this.state.selectedService == "PLUMBING"
                                      ? "assets/newUI-images/guestportal/icons/tap.png"
                                      : this.state.selectedService == "PESTS"
                                      ? "assets/newUI-images/guestportal/icons/bug.png"
                                      : this.state.selectedService == "KEYS"
                                      ? "assets/newUI-images/guestportal/icons/key.png"
                                      : this.state.selectedService ==
                                        "APPLIANCES"
                                      ? "assets/newUI-images/guestportal/icons/washing-machine.png"
                                      : this.state.selectedService ==
                                        "HOUSEKEEPING"
                                      ? "assets/newUI-images/guestportal/icons/broom.png"
                                      : this.state.selectedService ==
                                        "HEATING/AIR"
                                      ? "assets/newUI-images/guestportal/icons/hot.png"
                                      : this.state.selectedService ==
                                        "WIFI/CABLE"
                                      ? "assets/newUI-images/guestportal/icons/wifi.png"
                                      : this.state.selectedService ==
                                        "HOUSEWARES"
                                      ? "assets/newUI-images/guestportal/icons/housewares.png"
                                      : this.state.selectedService == "WINDOWS"
                                      ? "assets/newUI-images/guestportal/icons/window.png"
                                      : null
                                  }
                                  alt=""
                                />
                                {this.state.selectedService}
                              </div>
                            </div>
                          )}
                          {this.state.OrderType == "S" && (
                            <h4>
                              {this.state.whichRoom}{" "}
                              <i className="bi bi-arrow-right"></i>{" "}
                              {this.state.whichPlace}
                            </h4>
                          )}
                        </div>

                        <div className="items2">
                          <h5>Notes*</h5>
                          <textarea
                            name="message"
                            rows={9}
                            placeholder="Please type your message here…."
                            defaultValue={""}
                            value={this.state.description}
                            onChange={this.description}
                            id="description"
                          />
                          {this.state.descriptionValid == true ? (
                            <span style={{ color: "red", textAlign: "right" }}>
                              Please enter Notes.
                            </span>
                          ) : null}
                          <div className="switchParent">
                            <div className="switchToggle">
                              Permission to enter
                              <i
                                className="fa fa-info-circle mrg-left-5"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="By granting permission to enter you are allowing
                                us access to your suite to address your
                                request(s) with or without your presence."
                                style={{ cursor: "pointer" }}
                              ></i>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={this.state.isPte}
                                  onChange={() => this.isPte()}
                                />
                                <span className="slider" />
                              </label>
                            </div>
                            <div className="btns">
                              {this.state.hideBackButton == false ? (
                                <button
                                  className="btn "
                                  type="button"
                                  onClick={() => this.back()}
                                >
                                  Cancel
                                </button>
                              ) : null}
                              <button
                                className="btn black-btn cnl"
                                type="button"
                                onClick={() => this.submit()}
                                disabled={
                                  this.state.showSuccessMsg == true
                                    ? true
                                    : false
                                }
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="alert alert-2 text-center">
                        <strong>Thanks!</strong> {this.state.statusMsg}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
export default NewGuestService;
