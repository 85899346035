'use strict'
import AppController from "./appController";
const MainController = {
    getInitialInformation() {        
        AppController.removeLocalStorageData();
        AppController.stickyTitles();
		AppController.carousel();
		AppController.updateAnimation();
        $('html, body').animate({scrollTop: 0});
    },
    removeClassOnNavCollapse() {
        $(document).ready(function () {
            $(".ulnamecls").click(function () {
               $('.navbar-collapse').removeClass('in');
            });
        });
    },
    getProfilePageInformation(navIndex, menuId, clickedMenuId) {      
        AppController.addIndividualLocalStorage(navIndex, menuId, clickedMenuId);
        AppController.stickyTitles();
        AppController.carousel();
        AppController.updateAnimation();
        $('html, body').animate({scrollTop: 0});
    }
}
export default MainController;
