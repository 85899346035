/* Importing the node modules, child components, services and controllers used 
   inside Register component */
import $ from "jquery";
import AppController from "../../../controller/appController";
import GuestHeader from "../../../common/guestHeader";
import GuestServicesapi from "../../../../services/guestServicesapi";
import Footer from "../../../common/footer";
import SuccessModal from "../../../common/successModal";
import FiveStarApiServices from "../../../../services/fiveStarApiServices";
/* Register Component initialization */
class guestSignupWithMail extends React.Component {
  /* Initializing objects of its Register class */
  constructor(props) {
    super(props);
    /* Register Component State variables Initialization */
    this.state = {
      email: "",
      password: "",
      confirmPassword: "",
      message: "",
      onNext: true,
      guestprospect: false,
      userData: [],
      color: "",
      loader: false,
      auth0Response: "",
      expired: true,
    };
    this.setValue = this.setValue.bind(this);
    this.register_guest_prospective =
      this.register_guest_prospective.bind(this);
    //this.next = this.next.bind(this);
    this.checkExpiration = this.checkExpiration.bind(this);
    this.clearAlerts = this.clearAlerts.bind(this);
  }
  /* It is invoked immediately before mounting occurs */
  async componentWillMount() {
    //console.log(Base64.decode(this.props.match.params.email),"timeoutkey");

    AppController.removeLocalStorageData();
    $("html, body").animate({ scrollTop: 0 });
    AppController.stickyTitles();
    await this.checkExpiration(this.props.match.params.timeoutkey);
    await this.getUserData(this.props.match.params.email);
  }
  async clearAlerts(e) {
    e.preventDefault();
    this.setState({ message: "", color: "", loader: false });
  }
  async getUserData(email) {
    // console.log(email,'email')
    const login = {
      email: Base64.decode(email),
    };
    // console.log(login,'login')
    const userData = await GuestServicesapi.getSingleGuestDetails(login);
    //console.log(userData,"email");

    this.setState({ userData: userData });
  }
  async checkExpiration(key) {
    const requestObj = {
      authkey: key,
      sourcetype: "guest",
      requestforkey: false,
    };
    const TimeOut = await FiveStarApiServices.GetTimeoutKey(requestObj);
    //console.log(TimeOut,"TimeOut");

    if (TimeOut.error == "0") {
      this.setState({ expired: true });
    } else {
      this.setState({ expired: false });
    }
  }
  /* Update the state object of the form */
  setValue(field, e) {
    var object = {};
    object[field] = e.target.value;
    this.setState(object);
  }
  /* Email authentication and sending email to the respected email id */
  /**
   * Register Next Method
   * @param {*} e
   */
  // async next(e) {
  //     e.preventDefault();
  //     var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  //     if (this.state.email != '' && reg.test(this.state.email) == true) {
  //         this.setState({message: '', color: '',loader:true})
  //         const login = {
  //             email: this.state.email
  //         }
  //         const userData = await GuestServicesapi.getSingleGuestDetails(login)
  //         const requestObj = {
  //             "authkey":"",
  //             "sourcetype":"guest",
  //             "requestforkey":true
  //                 }
  //           const TimeOut = await FiveStarApiServices.GetTimeoutKey(requestObj);
  //           const emailSend = {
  //             email: this.state.email,
  //             encodeEmail: Base64.encode(this.state.email),
  //             mail:'Verifyyouremail',
  //             timeoutKey:TimeOut.timeoutkey
  //         }
  //         const mailsRes = await userEmails.sendMail(emailSend)

  //         if (userData.OrderId !=null && userData.webCount == 0) {

  //             //this.setState({onNext: true,loader:false, userData: userData})
  //         } else if (userData.OrderId  ==null && userData.webCount == 0) {
  //             this.setState({loader:false, email: '', message: 'The email does not exist on any active reservation with us. An online account can be created for future/current Guest only.', color: 'red'});
  //             jQuery(function ($) {
  //                 $('#errorGuestModal').modal('show');
  //                 setTimeout(async function () {
  //                 }.bind(this), 5000);
  //             }.bind(this));

  //         } else {
  //             this.setState({message: 'Your Email Already Registered', color: 'green',loader:false})
  //             setTimeout(function () {
  //                 this.setState({login: true, register: false, email: '', password: '', message: ''})
  //             }.bind(this), 2000);
  //         }
  //     } else {
  //         this.setState({message: 'Enter Valid Email', color: 'red'})
  //     }
  // }
  async register_guest_prospective(e) {
    e.preventDefault();
    let regularExpression =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@^_`{|}~]{10,16}$/;
    let minNumberofChars = 10;
    let maxNumberofChars = 16;
    let newPassword = this.state.password;
    let confirmPassword = this.state.confirmPassword;
    // if (newPassword.length < minNumberofChars || newPassword.length > maxNumberofChars) {
    //   this.setState({ message: 'yes', color: 'red' })
    // } else if (this.state.password != this.state.confirmPassword) {
    //   this.setState({ message: "Password & Confirm password don't match.", color: 'red' })
    // }
    // else if (regularExpression.test(newPassword) && regularExpression.test(confirmPassword)) {
    this.setState({ message: "", loader: true });

    // await fetch("https://" + authDetails.authCredentails().hostName + "/dbconnections/signup", {
    //   method: 'POST',
    //   headers: { 'content-type': 'application/json' },
    //   body: JSON.stringify({
    //     client_id: authDetails.authCredentails().clientId,
    //     email: _.toLower(Base64.decode(this.props.match.params.email)),
    //     password: this.state.password,
    //     connection: "Username-Password-Authentication"
    //   })
    // })
    //   .then(res => res.json())
    //   .catch(error => console.log('Error:', error))
    //   .then(response => this.setState({ auth0Response: response }))
    //console.log(this.state.auth0Response._id,"auth0Response");
    // if (Object.keys(this.state.auth0Response).length > 0 && this.state.auth0Response._id != undefined) {

    //console.log(Base64.decode(this.props.match.params.email),"email");
    if (this.state.userData.webCount == 0) {
      const register = {
        guestEmailId: Base64.decode(this.props.match.params.email),
        // guestPwd: this.state.auth0Response._id,
        guestPwd: "",
        guestIsActive: 1,
        guestFirstName:
          this.state.userData.GuestFirstName != null
            ? this.state.userData.GuestFirstName
            : "",
        guestLastName:
          this.state.userData.GuestLastName != null
            ? this.state.userData.GuestLastName
            : "",
        guestPhoneNumber:
          this.state.userData.GuestPhoneNo != null
            ? this.state.userData.GuestPhoneNo
            : "",
        guestOrderId:
          this.state.userData.OrderId != null
            ? this.state.userData.OrderId
            : "",
      };
      //console.log(register,'register1')
      const userData = await GuestServicesapi.saveGuestUserInfo(register);
      if (userData.error == 0) {
        await this.setState({
          message: "Registered Successfully",
          onNext: false,
          color: "green",
          loader: false,
        });
      } else {
        await this.setState({
          message: "sorry",
          onNext: false,
          color: "red",
          loader: false,
        });
      }
    } else {
      const register = {
        guestEmailId: Base64.decode(this.props.match.params.email),
        // guestPwd: this.state.auth0Response._id,
        guestPwd: "",
        guestId: this.state.userData.WebInfo.Id,
      };
      //console.log(register,'register2')
      const userData = await GuestServicesapi.saveGuestUserInfo(register);
      if (userData.error == 0) {
        await this.setState({
          message: "Registered Successfully",
          onNext: false,
          color: "green",
          loader: false,
        });
      } else {
        await this.setState({
          message: "sorry",
          onNext: false,
          color: "red",
          loader: false,
        });
      }
    }

    //console.log(register,"dsahdasjhdk");

    //    if(userData.error==0){
    //    await this.setState({ message: 'Registered Successfully',onNext:false, color: 'green', loader: false })
    //   } else {
    //  this.setState({ message: "The email doesn't exist in our system. Please contact your SuiteAmerica contact to update your reservation with your email.",onNext:false, color: 'red', loader: false })
    //  }

    // if (userData) {
    //     this.setState({message: 'Registered Successfully', color: 'green',loader:false})
    //     setTimeout(function () {
    //         this.setState({
    //             login: true,
    //             register: false,
    //             email: '',
    //             password: '',
    //             message: '',
    //             onNext: false
    //         })
    //     }.bind(this), 2000);
    // }
    //}
    // else {
    //   this.setState({ message: 'yes', color: 'red' })
    // }
  }
  handleClick() {
    $("#sideQuote-header").addClass("active");
    $(".overlay").addClass("active");
    $(".collapse.in").toggleClass("in");
    $("a[aria-expanded=true]").attr("aria-expanded", "false");
  }
  closed() {
    $("#sideQuote-header").removeClass("active");
    $(".overlay").removeClass("active");
  }
  /* It is invoked to return html content */
  render() {
    //console.log(this.state.userData.webCount);

    return (
      <div className="SAweb_updateUi">
        <header id="header" className="fixed-top guestlogin-header">
          <div className="container">
            <nav className="navbar ">
              <div className="d-flex align-items-center sm-flex-wrap">
                <div className="navbar-header">
                  <a className="navbar-brand" href="/homepage">
                    <img
                      className="mainLogo"
                      src="/assets/newUI-images/logo/SA-full-logo-W.svg"
                      alt=""
                    />
                    <img
                      className="logoIcon"
                      src="/assets/newUI-images/logo/SA-logo-icon-W.svg"
                      alt=""
                    />
                  </a>
                  <button
                    type="button"
                    className="navbar-toggle collapsed"
                    data-toggle="collapse"
                    data-target="#navbar"
                    aria-expanded="false"
                    aria-controls="navbar"
                  >
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </button>
                </div>
                <div id="navbar" className="navbar-collapse collapse">
                  <ul className="nav navbar-nav m-auto">
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link text-white  fs-6"
                        href="javascript:void(0)"
                      >
                        Hospitality <i className="bi bi-caret-down-fill"></i>{" "}
                      </a>
                      <div className="dropdownView">
                        <div>
                          <a
                            href="/corporatehousing"
                            className="dropdown-item "
                          >
                            {" "}
                            Corporate Housing{" "}
                          </a>
                          <a
                            href="/government-housing"
                            className="dropdown-item"
                          >
                            {" "}
                            Government Housing{" "}
                          </a>
                          <a href="/internHousing" className="dropdown-item">
                            {" "}
                            Intern & Group Housing{" "}
                          </a>
                          <a href="/business-travel" className="dropdown-item">
                            {" "}
                            Business Travel{" "}
                          </a>
                          <a
                            href="/insurance-landing"
                            className="dropdown-item"
                          >
                            {" "}
                            Insurance Housing{" "}
                          </a>
                        </div>
                      </div>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link text-white  fs-6"
                        href="/technology"
                      >
                        Technology
                      </a>
                    </li>

                    <li className="nav-item dropdown">
                      <a
                        className="nav-link text-white  fs-6"
                        href="javascript:void(0)"
                      >
                        Stay with us <i className="bi bi-caret-down-fill"></i>{" "}
                      </a>
                      <div className="dropdownView">
                        <div>
                          <a href="/guestExperience" className="dropdown-item ">
                            {" "}
                            Guest Experience{" "}
                          </a>
                          <a href="/spotlightCities" className="dropdown-item">
                            {" "}
                            Spotlight cities{" "}
                          </a>
                          <a href="/locations" className="dropdown-item">
                            {" "}
                            Locations{" "}
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>

                  <form className="d-flex">
                    <button className="btn " type="button">
                      <a href="/guest-login">Guest Login</a>
                    </button>
                    <button
                      className="btn  ms-3"
                      onClick={this.handleClick}
                      type="button"
                    >
                      <i className="bi bi-list"></i>
                    </button>
                  </form>
                </div>
              </div>
            </nav>
          </div>
        </header>

        <section id="sideQuote-header">
          <div className="quoteHeading">
            <div id="dismiss">
              <a onClick={this.closed}>
                <i className="bi bi-x"></i>
              </a>
            </div>
            <ul>
              <li>
                {" "}
                <a href="/company-info">About Us </a>
              </li>
              <li>
                {" "}
                <a href="/leadership-team">Leadership </a>
              </li>
              <li>
                {" "}
                <a href="/career-opportunities">Career </a>
              </li>
              <li>
                {" "}
                <a href="/privacy-policy">Privacy Policy </a>
              </li>

              <li>
                {" "}
                <a href="/contactUs">Contact Us </a>
              </li>
            </ul>
          </div>
          <div className="sideQuote_footer">
            <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/logo/full%20logo.svg" />
            <ul>
              <li>
                {" "}
                <i className="bi bi-telephone-fill pe-2"></i>&nbsp; +1
                800.367.9501{" "}
              </li>
              <li>
                <i className="bi bi-envelope pe-2"></i>&nbsp;
                support@suiteamerica.com{" "}
              </li>
            </ul>
          </div>
        </section>

        <section className="  SAweb_guestLoginBanner">
          <div className="container">
            <div className="loginForm">
              <div>
                <div className="item phColor ">
                  <h2>{"Complete Your Registration"}</h2>

                  {this.state.expired ? (
                    <div>
                      {this.state.message ? (
                        <span
                          style={{
                            color: this.state.color,
                            textAlign: "center",
                          }}
                        >
                          <div
                            className={
                              this.state.color == "red"
                                ? "alert alert-danger alert-dismissible"
                                : "alert alert-success alert-dismissible"
                            }
                          >
                            {/* <a href="#" className="close" data-dismiss="alert" aria-label="close" onClick={(e) => this.clearAlerts(e)}>&times;</a> */}

                            {this.state.message == "yes" && (
                              <div>
                                <ul className="alert-passwordtype">
                                  <li>- Minimum of 10 Characters Length</li>
                                  <li>
                                    - At least one Lower Case Letter (a-z)
                                  </li>
                                  <li>
                                    - At least one Upper Case Letter (A-Z)
                                  </li>
                                  <li>- At least one Number (0-9)</li>
                                  <li>
                                    -At least one Special Character (!@#$%^&*)
                                  </li>
                                </ul>
                              </div>
                            )}
                            {this.state.message ==
                              "Registered Successfully" && (
                              <div>
                                {" "}
                                Registered successfully. Please click{" "}
                                <a
                                  style={{ color: "#0d6efd" }}
                                  href="/guest-login"
                                >
                                  here
                                </a>{" "}
                                to login
                              </div>
                            )}
                            {this.state.message == "sorry" && (
                              <div>
                                {" "}
                                Sorry, we couldn't find an account with that
                                email. If you are a SuiteAmerica guest, Please
                                click{" "}
                                <a
                                  style={{ color: "#0d6efd" }}
                                  href="/guest-registration"
                                >
                                  here
                                </a>{" "}
                                to register.
                              </div>
                            )}
                          </div>
                        </span>
                      ) : (
                        ""
                      )}
                      {this.state.onNext && (
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                          placeholder="Email"
                          value={Base64.decode(this.props.match.params.email)}
                          disabled
                        />
                      )}
                      {this.state.loader ? (
                        <div className="panel-body_loader">
                          <span
                            style={{
                              fontSize: "20px",
                              width: "100%",
                              margin: "auto",
                              textAlign: "center",
                            }}
                          >
                            <i className="fa fa-spinner fa-spin" />
                            <span style={{ marginLeft: "10px" }}>
                              {" "}
                              please wait ...!
                            </span>{" "}
                          </span>
                        </div>
                      ) : this.state.message ? (
                        ""
                      ) : (
                        <div>
                          <div>
                            <button
                              type="button"
                              className="btn black-btn"
                              onClick={(e) =>
                                this.register_guest_prospective(e)
                              }
                            >
                              Sign Up
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      <h1>Your Email is Expired</h1>
                    </div>
                  )}
                </div>
              </div>
              <div className="item2">
                {/* <div className="right-side"> */}
                <h2>Hello Guest</h2>
                <p>Already registered?</p>
                <a href="/guest-login" className="btn btn-primary">
                  Sign In
                </a>
                {/* </div> */}
              </div>
            </div>
          </div>
        </section>
        <Footer />
        <SuccessModal />
      </div>
    );
  }
}
export default guestSignupWithMail;
