import $ from "jquery";
import GuestHeader from "../../common/guestHeader";
import Footer from "../../common/footer";
import {Link} from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import MapController from "../../controller/mapController";
import Amenities from "../travelBooking/amenities";
import moment from 'moment';
import { confirmAlert } from "react-confirm-alert";
import SuccessModal from "../../common/successModal";
import QuoteOrderService from "../../../services/quoteOrderService";
class MoreDetails extends React.Component {
    /* Initializing objects of its OurCommunities class */
    constructor(props) {
        super(props)
        this.state = {
            community: '',
            communityObject: {},
            appartmentAmenities: '',
            communityAmenities: '',
            communityImages: [],
            walkScore: '0',
            mapId: 0,
            distance: 0,
            travelObj: [],
            status: 'studio'
        }
        this.mapView = this.mapView.bind(this);
        this.streetView = this.streetView.bind(this);
        this.bookNow = this.bookNow.bind(this);
    }
    async componentDidMount(){
        var community = JSON.parse(localStorage.getItem('moreInfo'));
        let communityImages = community.CommunityImages !=null  && community.CommunityImages!=undefined? JSON.parse(community.CommunityImages): []
        community.CommunityImages !=null  && community.CommunityImages!=undefined? communityImages.unshift({'image_name':community.image_name}): community.CommunityImages
       
        const appartmentAmenities =  community.AmenitiesList !=null  && community.AmenitiesList!=undefined?JSON.parse(community.AmenitiesList):community.AmenitiesList		
        const apparmentamenites = appartmentAmenities !=null  && appartmentAmenities!=undefined ?_.filter(appartmentAmenities, function (o) {
            return o.communityname == 'Unit';
          }):[];
          const communityamenites = appartmentAmenities !=null  && appartmentAmenities!=undefined ?_.filter(appartmentAmenities, function (o) {
            return o.communityname == 'Community';
          }):[];
         const finalcommunities=communityamenites.map(function (obj) {
            return obj.aparment_amenity_name;
          });
          const finalamenities=apparmentamenites.map(function (obj) {
            return obj.aparment_amenity_name;
          });
        await this.setState({
            communityObject: community,
            communityImages:communityImages,
            appartmentAmenities:finalamenities,
            communityAmenities:finalcommunities
        });
       
        
    }
    bookNow(data) {
        const messageString = `Are you sure you want to book "${data.community_name}" Suite !!!`;
        confirmAlert({
          title: "",
          message: messageString,
          childrenElement: () => "",
          confirmLabel: "Yes",
          cancelLabel: "No",
          onConfirm: () => {
            this.bookNowProcess(data);
          },
          onCancel: () => {}
        });
      }
      async bookNowProcess(bookNow) {
        if (
          bookNow.LinkMaster.GuestEmail != null &&
          bookNow.LinkMaster.GuestEmail != undefined &&
          bookNow.LinkMaster.GuestEmail != ""
        ) {
          const request = {
            CommunityName: bookNow.community_name,
            CommunityAddress: bookNow.address,
            CommunityState: bookNow.state_name,
            CommunityCity: bookNow.city_name,
            MoveInDate: bookNow.LinkMaster.MoveInDate,
            MoveOuDate: bookNow.LinkMaster.MoveOuDate,
            OfferedRate: bookNow.LinkMaster.OfferedRate,
            Size: bookNow.LinkMaster.Size,
            DailyRent: bookNow.LinkMaster.DailyRent,
            OrderId: bookNow.LinkMaster.OrderId,
            MasterId: bookNow.LinkMaster.MasterId,
            GuestEmail: bookNow.LinkMaster.GuestEmail,
            GuestName: bookNow.LinkMaster.GuestName,
            BookedBy: bookNow.LinkMaster.BookedByEmail,
          };
          const ResponseApi = await QuoteOrderService.UpdateLinkMaster(request);
          //ResponseApi.error="0";
          if (ResponseApi.error == "0") {
            const ResponseMailer = await QuoteOrderService.QuoteOrderBookingEmail(
              request
            );
            jQuery(
              function($) {
                $("#successModal").modal("show");
                setTimeout(
                  async function() {
                    $("#successModal").modal("hide");
                    location.href='quotes/'+this.state.communityObject.userUrl;
                  }.bind(this),
                  2000
                );
              }.bind(this)
            );
            this.setState({
              communityAmenities: [],
              appartmentAmenities: [],
              walkScoreDetails: [],
              walkscoreLoading: false,
              communitysList: [],
              LinkMasters: [],
              error: false
            });
          
          } else {
            alert("Something went wrong in our side");
          }
        } else {
          alert("we dont have Guest EmailId For Booking Suite");
        }
      }
    async mapView() {
        await this.setState({
            mapId: this.state.mapId + 1
        });
        await this.loadMapData(this.state.communityObject);
    }
    async loadMapData(communityObject) {
        await MapController.loadMapData(communityObject, 'singlemark', this, '', 'https://s3-us-west-1.amazonaws.com/suiteam-website/Website-Images/SuiteAmerica-M' +
            'ap-Marker-Red.png');
    }
    streetView() {
        const _this = this;
        setTimeout(function() {
            MapController.streetView(_this.state.communityObject);
        }, 500);
    }
    render() {
        const community = this.state.communityObject;
        var finalCheckIn ='';
            var finalCheckOut = '';
        if(Object.keys(community).length>0){
     var checkIn = community.LinkMaster.MoveInDate;
	  var checkOut = community.LinkMaster.MoveOuDate;
	  var finalCheckIn = moment(checkIn).format('Do MMMM YYYY');
      var finalCheckOut = moment(checkOut).format('Do MMMM YYYY');
        }
    
      
        return (
            <div>
                <GuestHeader />
                <div className="fullslider inner tb-back-wrap">
	   <div className="tb-back-button">
		
		  <Link to={`quotes/${community.userUrl}`}>
		  <i className="fa fa-chevron-left"></i>
		  <span>Back</span>
		  </Link>

	   </div>
	   <img src={community.banner_image} alt=""/>
	</div>
    <div className="container travel-sec">
    <div className="col-md-8 col-sm-8">
		  <div className="community-item community-item-full">
			 <div className="community-item-dtls">
				<h5>{community.community_name}</h5>
				<p>{community.address}</p>
				<div className="pull-left text-left">
				   <p className="area">
					  <strong>{community.city_name},</strong>
					  {community.state_name}
				   </p>
				   
				</div>
				
			 </div>
			 <div className="clearfix"></div>
			 <div className="clearfix"></div>
			 
		  </div>
		  <div className="community-tabs">
			 <ul className="nav nav-tabs" role="tablist">
				<li role="presentation" className="active">
				   <a href="#one" aria-controls="home" role="tab" data-toggle="tab"><img src="" alt=""/>
				   <i className="icon icon-454"></i>Photos</a>
				</li>
				<li role="presentation" onClick={this.mapView}>
				   <a href="#two" aria-controls="profile" role="tab" data-toggle="tab">
				   <i className="icon icon-432"></i>Map</a>
				</li>
				<li role="presentation" onClick={this.streetView}>
				   <a href="#three" aria-controls="messages" role="tab" data-toggle="tab">
				   <i className="icon icon-425"></i>Street view</a>
				</li>
			 </ul>
			 <div className="tab-content travel-booking-slider">
				<div role="tabpanel" className="tab-pane active" id="one">
				{this.state.communityImages.length > 0
					? <OwlCarousel
				     ref={inst => this.slider = inst}
					 {...{ loop: false, 
					       nav:true, 
						   dots: true, 
						   items: 1, 
						   navContainerClass: 'owl-buttons', 
						   navText: ["<img src='https://s3-us-west-1.amazonaws.com/suiteam-website/Website-Images/SuiteAmerica-Carousel-Left-Arrow.png'>", "<img src='https://s3-us-west-1.amazonaws.com/suiteam-website/Website-Images/SuiteAmerica-Carousel-Right-Arrow.png'>"], responsive:{ 320:{ items:1, nav:true, loop:false }, 767:{ items:1, nav:true, loop:false }, 992:{ items:1, nav:true, loop:false }, 1199:{ items:1, nav:true, loop:false } }}}>
						{this.state.communityImages.map((row, index) => 
						{
						return <div className="item" key={index}><img className="img-responsive" src={row.image_name} alt={row.image_name}/></div>
						})}
				   </OwlCarousel>
				   : null}
				</div>
				<div role="tabpanel" className="tab-pane" id="two">
				   <div className="lc_map">
					  <div className="map-alignment">
						 <div id="map" className="map-alignment"></div>
					  </div>
				   </div>
				</div>
				<div role="tabpanel" className="tab-pane" id="three">
				   <div className="lc_map">
					  <div className="map-alignment">
						 <div id="street-view" className="map-alignment"></div>
					  </div>
				   </div>
				</div>
			 </div>
		  </div>
	   </div>

       <div className="col-md-4 col-sm-4">
		  <h4 className="widget-title">Booking Information</h4>
		  <div className="widget">
			 <div className="widget_body">
				<ul className="booking_info_list">
				   <li>
					  <span>Check In</span>
					  <h5>{finalCheckIn}</h5>
				   </li>
				   <li>
					  <span>Check Out</span>
					  <h5>{finalCheckOut}</h5>
				   </li>
				</ul>
				
				 {/* <button
				   
				   className="btn btn-block btn-primary" onClick={()=>this.bookNow(community)}>Book Now</button>
				 */}
			 </div>
		  </div>
		  <h4 className="widget-title">Amenities</h4>
	<Amenities {...this.state}/>
	   </div>
    </div>
    <SuccessModal />
                <Footer />
            </div>
                )
    }

}    
export default MoreDetails