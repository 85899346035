import React, { useState } from "react";
import ServiceOrdersTable from "../tables/ServiceOrdersTable";

const ServiceOrdersModal = ({ tableData }) => {
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const closeOpenseriveModal = () => {
    setSearchText("");
    setFilteredData([]);
  };
  return (
    <div
      className="modal modal-fullscreen-xl modal fade animated tab-modal guest-Ui SAweb_modalPoup"
      id="service-orders-modal"
      tabIndex={-1}
      data-backdrop="static"
      data-keyboard="false"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div
          className="modal-content newSRView"
          style={{ contentVisibility: "auto" }}
        >
          <div className="page-title-box pad-top-0">
            <h4 className="page-title fw-bold">Open Services</h4>
            <button
              type="button"
              className="btn close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => closeOpenseriveModal()}
            >
              <i className="bi bi-x"> </i>
            </button>
          </div>

          {/* <div className="servicerequest-pop-header">
          </div> */}

          <div className="modal-body overflow-visible SAwebGuest_arrivalInfo pad-top-0 row">
            <ServiceOrdersTable
              tableData={tableData}
              searchText={searchText}
              setSearchText={setSearchText}
              filteredData={filteredData}
              setFilteredData={setFilteredData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceOrdersModal;
