const GoogleHeader= (props) => {
return ( 
<div className="google_header">
   <div className="social-header">
      <ul >
         <li><a href="https://www.instagram.com/suite_america/" target="_blank" ><i className="fa fa-instagram"></i></a></li>
         <li><a href="https://www.facebook.com/SuiteAmerica" target="_blank" ><i className="fa fa-facebook-square"></i></a></li>
         <li><a href="https://www.linkedin.com/company/108459?trk=saber_s000001e_1000" target="_blank"><i className="fa fa-linkedin"></i></a></li>
         <li><a href="https://twitter.com/SuiteAmerica" target="_blank"><i className="fa fa-twitter"></i></a></li>
      </ul>
   </div>
   <img className="img-responsive" src={s3BucketUrl+"landing-images/Google/google+clouds-01.jpg"} width="100%" alt="" />
   <div className="head-title">
      <h1>Google Furnished Apartments with <span className="color-red">SuiteAmerica</span></h1>
      <p>A business travel experience that offers more space than a hotel </p>
   </div>
</div>
);}
export default GoogleHeader;
    