const ListButtons = props => {
    const requestDetails = props.requestDetails
    return (
        <div className="text-center mrg-top-30 mrg-btm-30">
        <button type="button" className="btn star_button" onClick={()=>props.redirectionToKeys()}>KEYS</button> <span></span>
        {requestDetails.TypeCode=='M/G'?
        <button type="button" className="btn star_button" onClick={()=>props.redirectionToCheckList()}>Meet & Greet Recap</button>
        :null}
        {(requestDetails.TypeCode=='T/O' || requestDetails.TypeCode=='W/T' || requestDetails.TypeCode=='EXEW/T')?
        <button type="button" className="btn star_button" onClick={()=>props.redirectionToInspectionForm()}>INSPECTION FORM</button>
        :null}
     </div>
    );
  };

  export default ListButtons;