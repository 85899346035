import moment from 'moment'
import React, { Component } from 'react'
import Footer from '../../common/footer'
import Guest from '../../common/guest'
import GuestHeader from '../../common/guestHeader'
import SubFooter from '../../common/subFooter'
import QuickRequestForm from './quickRequestForm';
import SessionService from "../../../services/sessionService";
import GuestServicesapi from '../../../services/guestServicesapi';
import platform from 'platform';
import publicIp from 'public-ip';

export class checkIn extends Component {
    constructor(props) {
        super(props)
        this.state = {
            menuShow: false,
            orderUId:"",
            guestEmail:"",
            checkInNotes:"",
            checkInNotesValid:true,
            checkInStatus:"",
            isAlreadySubmitted:false,
            alreadySubmitted:""
        }
    }

componentDidMount(){
    SessionService.SessionGet({ type: 'guest' }).then((sessionResponse) => {
        var guestEmailObj = {
          guestEmail: sessionResponse.GuestEmail,
          status: "active"
        }
        GuestServicesapi.GetOrdersByGuestEmail(guestEmailObj).then((response) => {
          if (response.error !== 1 && response.message.Orders.length > 0) {
            this.setState({ orderUId: response.message.Orders[0].OrderUId,guestEmail:sessionResponse.GuestEmail });
}
        })
    })
}

   async checkIn(){
        const address = await publicIp.v4().then(ip => {
            return ip
          });
var checkInObj={
        OrderUId:this.state.orderUId,
        Type:"IN",
        Time:moment().format("YYYY-MM-DD HH:MM:SS.SSS"),
        BrowserInfo:platform.name, //browser name
        IPAddress:address,
        Source:"Web",
        createdby:this.state.guestEmail,
        CreatedDate:moment().format("YYYY-MM-DD HH:MM:SS.SSS"),
        Notes:this.state.checkInNotes
}
if(this.state.checkInNotes=="" || this.state.checkInNotes==undefined){
    this.setState({checkInNotesValid:false})
}else{
    GuestServicesapi.SaveInOutOrdersLog(checkInObj).then((response) => {
if(response.message=="Success"){
    this.setState({checkInStatus:"Submitted Successfully",checkInNotesValid:true,isAlreadySubmitted:false})
}
if(response.error==1){
    this.setState({alreadySubmitted:"You are not allowed to submit multiple times.",isAlreadySubmitted:true,checkInNotesValid:true})
}
    })
}
    }

    checkInNotes(e){
        this.setState({checkInNotes:e.target.value})
    }

    render() {
        return (
            <div>
                <GuestHeader />
                <Guest />
                <QuickRequestForm menuShow={this.state.menuShow} />
                <div className="sampleTesingView">
                    <h1>Check In</h1>
                    <textarea placeholder="Enter Notes..." name="checkIn" onChange={(e)=>this.checkInNotes(e)} value={this.state.checkInNotes}></textarea>
                    {this.state.checkInNotesValid?<p style={{color:"red"}}>Please enter Notes</p>:null}
                    {
            this.state.isAlreadySubmitted?<p style={{color:"red"}}>{this.state.alreadySubmitted}</p>:null
        }
        {this.state.checkInStatus!==""?<p style={{color:"green"}}>{this.state.checkInStatus}</p>:null}
                    <button type="button" className="btn" onClick={()=>this.checkIn()}>Submit</button>
                </div>
                <SubFooter />
                <Footer />
            </div>
        )
    }
}

export default checkIn
