/* Importing the node modules, child components, services and controllers used 
   inside TeslaLocations component */    
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import TeslaHeader from "../../common/teslaHeader";
   import Location from "../location/teslaLocations";
   import Footer from '../../common/footer';
   import GuestHeader from '../../common/guestHeader';

   /* TeslaLocations Component initialization */
   class TeslaLocations extends React.Component {
       /* Initializing objects of its TeslaLocations class */
       constructor(props) {
           super(props)
           this.state={
               companyname:'Tesla'
           }
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
           MainController.getInitialInformation();
        }
       /* It is invoked to return html content */
       render() {
           return (
            <div className="corporateHousing_Updates telsa-page-view">
                <GuestHeader/>
                <div className="red-border"></div>
                <TeslaHeader />
                <div className="container-view">
                <header className="codeconveyHeader cc_image_text_main corporate-h">
                <img className="img-responsive" src="/assets/images/tesla/banner.png" width="100%" alt=""  />
                    <div className="wow fadeInUp text-center img-on-text-center" data-wow-delay="0.5s">
                        <h1>Locations</h1>
                        <p className="p">Furnished temporary
                            corporate housing apartments throughout
                            the continental United States.
                        </p>
                        <p className="p" style={{fontStyle:"italic"}}>*All apartments and communities are subject to availability.</p>
                    </div>
                </header>
                </div>
                <Location companyName={this.state.companyname} props={this.props}/>
                <div className="container-fluid bg-grey">

                </div>
                <SubFooter />
            <Footer/>
            </div>   
           );
       }
   }
   export default TeslaLocations;