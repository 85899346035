'use strict'
import TravelBookingApiService from '../../../../services/travelBookingApiService';
import BookingController from '../controllers/bookingController';
import AppController from "../../../controller/appController";
import NikeServices from '../../../../services/nikeService';
import moment from 'moment';
class BookNowModel {
    constructor(thisObject) {
        this.Obj = thisObject
    }
    async bookDetails() {
        this.state = this.Obj.state
        this.props = this.Obj.props   
        const _this = this.Obj
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        const phoneReg = /^[0-9\b]+$/;
        const validationFields = ['firstName', 'lastName', 'emailId','phoneNo' ]

        let error = 0;
        _.forEach(validationFields, function (value) {
            if (_this.state[value] == "") {
                error++;
            } else if (reg.test(_this.state.emailId) == false) {
                error++;
            }
            else if (phoneReg.test(_this.state.phoneNo) == false) {
                error++;
            }
        });
        if (error == 0) {
            this.Obj.setState({
                loadingStatus: true
            })

            const Code = $(".country.highlight.active").attr("data-dial-code") == undefined ? "+1" : "+" + $(".country.highlight.active").attr("data-dial-code")

            var Request = {
                firstName: this.state.firstName == '' ? '' : this.state.firstName,
                lastName: this.state.lastName == '' ? '' : this.state.lastName,
                emailId: this.state.emailId == '' ? '' : this.state.emailId,
                phoneNo: this.state.phoneNo == '' ? '' : Code + this.state.phoneNo,
                mail: 'travelBooking',
                subject: 'Travel Booking' ,
                checkIn: this.state.checkIn.format('MM-DD-YYYY'),
                checkOut: this.state.checkOut.format('MM-DD-YYYY'),
                communityName: this.props.communityObject.community_name,
                city_name: this.props.communityObject.city_name,
                airline: this.state.airline == '' ? '' : this.state.airline,
                flight: this.state.flight == '' ? '' : this.state.flight,
                pnr: this.state.pnr == '' ? '' : this.state.pnr,
                type: this.state.type == '' ? '' : this.state.type,
                ordertype: this.props.travelObj != undefined && Object.keys(this.props.travelObj).length != 0 ? 'excel' : 'admin',
                bookingType: 0 ,
                status: 0
            }
            const phonenumber = AppController.formatPhoneNumber(this.state.phoneNo == '' ? '' : this.state.phoneNo)
            const dailyrate = BookingController.dailyRent( this.props.communityObject.communityroomSelected.length>0?this.props.communityObject.communityroomSelected[0].BookedType:localStorage.getItem('bedSize'),this.state.checkIn,this.state.checkOut)
            const clientDetails = "16919"
            const userLoginEmailEncode=localStorage.getItem('travel-email');
            const userLoginEmail=(userLoginEmailEncode !=undefined || userLoginEmailEncode !=null )? Base64.decode(userLoginEmailEncode):''
            
            const bookingRequest = {
                program: this.props.program == undefined ? localStorage.getItem('program').split(",") : this.props.program,
                clientId: clientDetails,
                record_limit: this.props.record_limit == undefined ? Number(localStorage.getItem('record_limit')) : this.props.record_limit,
                radius: this.props.radius == undefined ? Number(localStorage.getItem('radius')) : this.props.radius,
                checkin: this.state.checkIn.format('MM-DD-YYYY'),
                checkout: this.state.checkOut.format('MM-DD-YYYY'),
                resident_firstname: this.state.firstName == '' ? '' : this.state.firstName,
                resident_lastname: this.state.lastName == '' ? '' : this.state.lastName,
                resident_middlename: '',
                resident_email: this.state.emailId == '' ? '' : this.state.emailId,
                resident_phone: phonenumber,
                community_id: this.props.communityObject.community_id,
                countrycode: Code,
                dailyrate:dailyrate,
                airline: this.state.airline,
                flight: this.state.flight,
                type: this.state.type,
                pnr: this.state.pnr,
                source: 'ABE',
                changedby: userLoginEmail !=''? userLoginEmail : this.state.emailId,
                todays: false,
                reservedby: 'Brad Driskell',
                reservedbyemail: 'extendedstay@apple.com',
                checkintime: '3:00 PM',
                Checkouttime: '10:00 AM',
                maid: 1,
                freq: 'WEE',
                entry: 'Meet & Greet',
                roomsize: this.props.communityObject.communityroomSelected.length>0?this.props.communityObject.communityroomSelected[0].BookedType:localStorage.getItem('bedSize'),
                latitude:  this.props.latitude == undefined ? Number(localStorage.getItem('latitude')) : this.props.latitude,
                longitude: this.props.longitude == undefined ? Number(localStorage.getItem('longitude')) : this.props.longitude,
                reqDays:this.props.reqDays == undefined ? Number(localStorage.getItem('reqDays')) : this.props.reqDays,
                confirmedMoveOut: true,
                roleType: this.props.travelObj != undefined && Object.keys(this.props.travelObj).length != 0 ? 1 : 2
            }   
            const response = await TravelBookingApiService.CreateTravelOrder(bookingRequest)
                await this.Obj.setState({
                orderResponse: response
            })
           
            if (response.error == '1') {
                
                jQuery(function($) {
                    $('#travelbooknow').modal('hide');
                    setTimeout(function(){
                        $('#QuoteOrderModelPopUp').modal('show');
                    },700);
                  });
                  
            } else {
            localStorage.removeItem('checkIn');
            localStorage.removeItem('checkOut');
            localStorage.removeItem('pnrId');
            localStorage.removeItem('bedSize');
            localStorage.removeItem('record_limit');
            localStorage.removeItem('reqDays');
            localStorage.removeItem('radius');
            localStorage.removeItem('latitude');
            localStorage.removeItem('longitude');
            localStorage.removeItem('searchedLocation')
            if(response.orderId != '' && response.orderstatus == "QUOTE"){
                Request['status'] = (this.props.travelObj != undefined && Object.keys(this.props.travelObj).length != 0) ? 1 : 2
                Request['OrderId'] = Number(response.orderId)
                //await NikeServices.serviceRequest(Request)
                location.href='/travel-booking-quote'  
            }
            else{
                    if (this.props.travelObj != undefined && Object.keys(this.props.travelObj).length != 0) {
                        const pnrUpdateDetails = {
                            PNR: this.props.travelObj.PNR,
                            OrderId: Number(response.orderId),
                            status: 1,
                            FlightNo: this.state.flight,
                            AirLineName: this.state.airline,
                            Email: this.state.emailId,
                           
                        }
                        Request['status'] = 1
                        Request['OrderId'] = Number(response.orderId)
                        //NikeServices.serviceRequest(Request)
                        await TravelBookingApiService.SaveBookingEmails(pnrUpdateDetails)
                        location.href='/travel-booking-guestsuccess'
                    } else {
                        const pnrUpdateDetails = {
                            PNR:'',
                            OrderId: Number(response.orderId),
                            status: 2,
                            Traveler: this.state.firstName+' '+this.state.lastName,
                            Email: this.state.emailId,
                            phone: this.state.phoneNo,
                            AirLineName: this.state.airline,
                            FlightNo: this.state.flight,
                            type: this.state.type,
                            CheckInDate: this.state.checkIn.format('MM-DD-YYYY'),
                            OutDate: this.state.checkOut.format('MM-DD-YYYY'), 
                            BookedBy:'admin',              
                        }
                        Request['status'] = 2
                        Request['OrderId'] = Number(response.orderId)
                        //NikeServices.serviceRequest(Request)
                        await TravelBookingApiService.SaveBookingEmails(pnrUpdateDetails)
                        location.href='/travel-booking-adminsuccess'
                    }
                }
              

            }
        } else {
            this.Obj.setState({
                errorMessage: 'Please Enter Required Fields, Valid Email and Phone No',
                color: 'red'
            });
        }

    }
}

export default BookNowModel
