'use strict'
import serviceBase from './serviceBase'

const guestServicesapi = {
    guestUsersLogin: (data) => serviceBase.post('/mysuiteamerica-guest/api/v2$$GuestUsersLogin', data),
    getGuestInfoCommunityByEmailId: (data) => serviceBase.post(`/mysuiteamerica-guest/apis/GetGuestInfoCommunityByEmailId&&orderid=${data.orderid}`),
    SendSignUpEmailLink: (data) => serviceBase.post(`/mysuiteamerica-guest/apis/v2$$SendSignUpEmailLink&&email=${data.email}`),
    saveServicesRequestDetails: (data) => serviceBase.post('/mysuiteamerica-guest/api/SaveServicesRequestDetails', data),
    getServiceRequestByOrderId: (data) => serviceBase.post(`/mysuiteamerica-guest/apis/GetServiceRequestByOrderId&&orderId=${data.orderId}`),
    getServiceRequestNotestById: (data) => serviceBase.post(`/mysuiteamerica-guest/apis/GetServiceRequestNotesById&&entityUid=${data.entityUid}`),
    saveServiceRequestNotesDetails: (data) => serviceBase.post('/mysuiteamerica-guest/api/SaveServiceRequestNotesDetails', data),
    getSingleGuestDetails: (data) => serviceBase.post(`/mysuiteamerica-guest/apis/v2$$GetSingleGuestDetails&&guestEmail=${data.email}`),
    saveGuestUserInfo: (data) => serviceBase.post('/mysuiteamerica-guest/api/v2$$SaveGuestUserInfo', data),
    GetTripInfo: (data) => serviceBase.post(`/mysuiteamerica-guest/apis/GetTripInfo&&orderUid=${data.orderUid}`),
    GuestUsersLogOut: (data) => serviceBase.post(`/mysuiteamerica-guest/apis/GuestUsersLogOut&&sessionId=${data.sessionId}`),
    ClientUsersLogOut: (data) => serviceBase.post(`/mysuiteamerica-guest/apis/ClientUsersLogOut&&sessionId=${data.sessionId}`),
    CheckCESurveyInfoByOrderUId: (data) => serviceBase.post(`/mysuiteamerica-guest/apis/CheckCESurveyInfoByOrderUId&&orderUId=${data.orderUId}`),
    SaveSurveyFeedBack: (data) => serviceBase.post('/mysuiteamerica-guest/api/SaveSurveyFeedBack', data),
    CheckGuestSurveyFeedBack: (data) => serviceBase.post(`/mysuiteamerica-guest/apis/CheckGuestSurveyFeedBack&&orderUId=${data.orderUId}&type=${data.type}`),
    SaveOrderTripDetails: (data) => serviceBase.post('/mysuiteamerica-guest/api/v2$$SaveOrderTripDetails', data),
    GetOrderInfoById: (data) => serviceBase.post(`/mysuiteamerica-guest/apis/v2$$GetOrderInfoById&&OrderUId=${data.OrderUId}`),
    GetOrdersByGuestEmail: (data) => serviceBase.post(`/mysuiteamerica-guest/apis/v2$$GetOrdersByGuestEmail&&guestemail=${data.guestEmail}&status=${data.status}`),
    GetServicesCatergory: (data) => serviceBase.post(`/mysuiteamerica-guest/apis/v2$$GetServicesCatergory&&type=${data.type}`),
    GetRoomServices: (data) => serviceBase.post(`/mysuiteamerica-guest/apis/v2$$GetRoomServices&&type=${data.type}`),
    GetSubCategoryService: (data) => serviceBase.post(`/mysuiteamerica-guest/apis/v2$$GetSubCategoryService&&type=${data.type}&category=${data.category}&room=${data.room}`),
    GetSubCategoryDescription: (data) => serviceBase.post(`/mysuiteamerica-guest/apis/v2$$GetSubCategoryDescription&&category=${data.category}&room=${data.room}&subcategory=${data.subcategory}`),
    GetRoomTypeService: (data) => serviceBase.post(`/mysuiteamerica-guest/apis/v2$$GetRoomTypeService&&type=${data.type}&room=${data.room}`),
    GetServiceInfoRequestByOrderId: (data) => serviceBase.post(`/mysuiteamerica-guest/apis/v2$$GetServiceInfoRequestByOrderId&&orderid=${data.orderid}`),
    SaveServiceWebRequestDetails: (data) => serviceBase.post('/mysuiteamerica-guest/api/v2$$SaveServiceWebRequestDetails', data),
    GetServiceRequestNotesById: (data) => serviceBase.post(`/mysuiteamerica-guest/apis/v2$$GetServiceRequestNotesById&&entityUid=${data.entityUid}`),
    SaveServiceRequestNotesDetails: (data) => serviceBase.post('/mysuiteamerica-guest/api/v2$$SaveServiceRequestNotesDetails', data),
    GetOrderInformation: (data) => serviceBase.post(`/mysuiteamerica-guest/apis/GetOrderInformation&&orderid=${data.orderid}`),
    GetGuestInfoCommunityByEmailId: (data) => serviceBase.post(`/mysuiteamerica-guest/apis/GetGuestInfoCommunityByEmailId&&orderid=${data.orderid}`),
    GetServicesInfoRequestByUserId: (data) => serviceBase.post(`/mysuiteamerica-guest/apis/v2$$GetServicesInfoRequestByUserId&&email=${data.email}&userId=${data.userId}`),
    GetOrderCommunityNoteDetails: (data) => serviceBase.post('/mysuiteamerica-guest/api/GetOrderCommunityNoteDetails', data),
    GetBookedGSRByOrderUId: (data) => serviceBase.post(`/mysuiteamerica-guest/apis/v2$$GetBookedGSRByOrderUId&&OrderUId=${data.OrderUId}`),
    GetContractDetails: (data) => serviceBase.post(`/mysuiteamerica-guest/api/GetContractDetails`,data),
    SaveContractDetails: (data) => serviceBase.post(`/mysuiteamerica-guest/api/SaveContractDetails`,data),
    GetMaidSchedulesById: (data) =>
    serviceBase.post(
      `/mysuiteamerica-guest/apis/v2$$GetMaidSchedulesById&&orderUId=${data.orderUId}`
    )
}
export default guestServicesapi

