/* Importing the node modules, child components, services and controllers used 
   inside ForgotpaswordLink component */    
   import $ from "jquery";   
   import AppController from "../../controller/appController";
   /* ForgotpaswordLink Component initialization */
   class ForgotpaswordLink extends React.Component {
     /* Initializing objects of its ForgotpaswordLink class */
     constructor(props) {
       super(props)
       /* ForgotpaswordLink Component State variables Initialization */
       this.state = {
         email: '',
         password: '',
         confirmPassword:'',
         message: '',
         onNext: false,
         guestprospect:false,
         userData: [],
         color:''
       }
       this.setValue = this.setValue.bind(this);
       this.authentication = this.authentication.bind(this);
     }
     /* It is invoked immediately before mounting occurs */ 
     componentWillMount() {
       $('html, body').animate({scrollTop: 0});
       AppController.stickyTitles();   
     }
     /* Update the state object of the form */
     setValue(field, e) {
       var object = {};
       object[field] = e.target.value;
       this.setState(object);
     }   
     /* Authentication checking */
     async authentication(e) {
       e.preventDefault();
       if (this.state.password != '') {
console.log('empty')
       }
   else{
     this.setState({message: 'Enter valid Password',color:'red'})
   }
     }
     /* It is invoked to return html content */
     render() {
       return (
        <div id="pages_5star" style={{'background': '#f5f5f5'}}>
        <div className="login_main">
            <div className="container-fluid">
                <div className="login_star">
                            <img src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Logo.png"} alt="" />
                            <div className="col-sm-12">
                                    {this.state.message !='' ? 
                                        <span style={{color: this.state.color,textAlign: 'right'}}>{this.state.message}</span>
                                    : ''}
                            </div>
                            <form onSubmit={(e) => this.authentication(e)}>
                                <div className="f-contact-form-style">
                                    <div className="form-group">
                                    <input type="text" className="form-control"  placeholder="Email" value={this.state.email} 
                                    onChange={(e) => this.setValue('email', e)} />
                                    </div>
                                    <div className="form-group">
                                    <input type="password" className="form-control"  placeholder="Password" value={this.state.password} 
                                    onChange={(e) => this.setValue('password', e)}/>
                                    </div>
                                    <div className="form-group">
                                    <input type="password" className="form-control"  placeholder="Confirm Password" value={this.state.confirmPassword} onChange={(e) => this.setValue('confirmPassword', e)}/>
                                    </div>
                                    {this.state.loader ? 
                                    <div className="panel-body_loader"> <span style={{fontSize: '20px', width: '100%', margin: 'auto', textAlign: 'center'}}><i className="fa fa-spinner fa-spin"/> please wait ...!</span></div>:
                                    <div className="mrg-btm-20"><button type="submit" className="btn"> Login</button></div>
                                    
                                    }

                                </div>
                            </form>
                            
                </div>
            </div>
            
        </div>

</div>
       );
     }
   }
   export default ForgotpaswordLink;