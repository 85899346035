/* Importing the node modules, child components, services and controllers used 
   inside IntranetCustomRequestthree component */   
   import DatePicker from 'react-datepicker'
   import moment from 'moment'
   /* IntranetCustomRequestthree Component initialization */
   class IntranetCustomRequestthree extends React.Component{
       /* Initializing objects of its IntranetCustomRequestthree class */
       constructor(props) {
           super(props)
           this.state = {
            deliveryMethod: '',
            priority: '',
            deliveryMethodElectronic:false,
            deliveryMethodHard:false,
            dueDate: moment(),
            status: -1,
            color:'',
            message:'',
           }
           this.setCheckedElectronic = this.setCheckedElectronic.bind(this);
           this.setCheckedhard = this.setCheckedhard.bind(this);
           this.setValue = this.setValue.bind(this);
           this.dueDate = this.dueDate.bind(this);
           this.priority = this.priority.bind(this);
           this.previous = this.previous.bind(this);
           this.next = this.next.bind(this);
         }
         next(data){          
          var str = this.props.quantity.toString();
          console.log(str)
          var reg = /^([+-]?([0-9]*[.])?[0-9]+)$/;
          if(this.props.quantity!='' && reg.test(this.props.quantity)){
            const request3={
              page: data
             }
          this.props.next(request3)
           }
           else{
                this.setState({color:'red',message:'Please Enter only number'})
           }
            } 


     previous(data){
                this.props.previous(data)    
                }
                async dueDate(date){
                  var selectedDate=moment(date)
                   var object = {};
                    object['dueDateVal']=moment(date.format('YYYY-MM-DD'))
                    await this.props.dueDate(object); 
                    console.log(selectedDate)
                }
               
                setCheckedElectronic(field, e) {
                    var object = {};
                    object[field] =e.target.checked;
                    this.props.setCheckedElectronic(object);
                  }

                  setCheckedhard(field, e){
                    var object = {};
                    object[field] =e.target.checked;
                    this.props.setCheckedhard(object);
                  }
                  priority(index){
                    var object = {};
                  if(index==0){
                    object['status'] =index;
                    this.props.priority(object);
                  } else if(index==1){
                    object['status'] =index;
                    this.props.priority(object);
                  }
                  else if(index==2){
                    object['status'] =index;
                    this.props.priority(object);
                  }
                   
                  }
                  setValue(field, e) {
                    this.setState({message:'',color:''})
                    var object = {};
                    object[field] = e.target.value;
                    this.props.setValue(object);
                }
   /* It is invoked to return html content */      
   render(){
 
       return(
          <div className="inet_custom">
   <div className="container">
      <h1 className="wow animated zoomIn">MARKETING REQUEST FORM</h1>
      <div className="inet_custom_form">
         <form id="msform" className="wow animated fadeInLeft">
            {/* <!-- progressbar --> */}
            <ul id="progressbar">
               <li className="active"></li>
               <li className="active"></li>
               <li className="active"></li>
               <li> </li>
            </ul>
            {/* <!-- fieldsets --> */}
         </form>
         <p style={{color:this.state.color,textAlign:'center'}}>{this.state.message}</p>
         <form className="inet_custom2 wow animated fadeInRight">
            <div className="col-sm-12">
               <div className="form-group">
                  <p>FINAL PROJECT INSTRUCTIONS</p>
               </div>
            </div>
            <div className="col-sm-12">
               <div className="intra_switch">
                  <h3>Delivery Method </h3>
                  <div className="col-md-6">
                     <div className={this.props.deliveryMethodElectronic ? "intra_switch2 selected" : "intra_switch2"}>
                        <div className="text-right">
                           <label className="switch">
                           <input type="checkbox" checked={this.props.deliveryMethodElectronic ? 'checked' : ''} 
                           onChange={(e) => this.setCheckedElectronic('deliveryMethodElectronic', e)}/>
                           <span className="slider round"></span>
                           </label>
                        </div>
                        <i className="fa fa-television"></i>
                        <p className={this.props.deliveryMethodElectronic ? "intra_switch2 selected" : "intra_switch2"}>Electronic File</p>
                     </div>
                  </div>
                
                  <div className="col-md-6">
                     <div className={this.props.deliveryMethodHard ? "intra_switch3 selected" : "intra_switch3"}>
                        <div className="text-right">	
                           <label className="switch">
                           <input type="checkbox" checked={this.props.deliveryMethodHard ? 'checked' : ''} onChange={(e) => this.setCheckedhard('deliveryMethodHard', e)} />
                           <span className="slider round"></span>
                           </label>
                        </div>
                        <i className="fa fa-file-text-o"></i>
                        <p className={this.props.deliveryMethodHard ? "intra_switch3 selected" : "intra_switch3"}>Printed Hard Copy</p>
                     </div>
                  </div>
               </div>
            </div>
            <div className="col-sm-12">
                <div className="form-group">
                    <input type='text' className="form-control" placeholder="Quantity*" value={this.props.quantity} onChange={(e) => this.setValue('quantity', e)}/>
                </div>
            </div>
            <div className="col-sm-12 intra_datepicker">
               <div className="form-group">
                  <div className="">
                  <div className="date">
                  <div className="form-group">
                  <DatePicker type="text" className="form-control" name="date" placeholder="Due Date" selected={this.props.dueDateVal}  
                  onChange={this.dueDate} />
                    </div>
              
                  </div>
                  </div>
               </div>
            </div>
            <div className="col-sm-12">
               <div className="form-grouplist">
                  <ul>
                     <li className="text-left">Priority</li>
                     <li><button type="button" className={this.props.status == 0 ? "high_button" : "medium_button"} onClick={() => this.priority(0)}> Low</button></li>
                     <li><button type="button" className={this.props.status == 1 ? "high_button" : "medium_button"} onClick={() => this.priority(1)}> Medium</button></li>
                     <li><button type="button" className={this.props.status == 2 ? "high_button" : "medium_button"} onClick={() => this.priority(2)}> High</button></li>
                  </ul>
               </div>
            </div>
            <div className="col-sm-12">
               <div className="intra_info">
                  <h1>i</h1>
                  <h2>Timeline Info</h2>
                  <p>The average turnaround time for a reorder/reprint will vary </p>
               </div>
            </div>
            <div className="text-center">  
               <button type="button" className="button inet_button" onClick={() => this.previous(2)}><i className="fa fa-caret-left"></i> PREVIOUS</button>                     	     
               <button type="button" className="button inet_button" onClick={() => this.next(4)}>NEXT<i className="fa fa-caret-right"></i> </button>  
            </div>
         </form>
      </div>
   </div>
</div>   
                 
   
       );
   }
   }
   export default IntranetCustomRequestthree;