
const BedsList = props => {
    const row = props.rowData
    return (
        <div className="col-sm-12"> 
        { row.Bed1SizeCode == undefined || row.Bed1SizeCode == null ? null: <span><span className="font-bold">Bed1: </span>{row.Bed1SizeCode}  &nbsp;</span> }
        { row.Bed2SizeCode == undefined || row.Bed2SizeCode == null ? null: <span><span className="font-bold">Bed2: </span>{row.Bed2SizeCode}  &nbsp;</span> }
        { row.Bed3SizeCode == undefined || row.Bed3SizeCode == null ? null: <span><span className="font-bold">Bed3: </span>{row.Bed3SizeCode}  &nbsp;</span> }
        { row.Bed4SizeCode == undefined || row.Bed4SizeCode == null ? null: <span><span className="font-bold">Bed4: </span>{row.Bed4SizeCode}  &nbsp;</span> }
        { row.Bed5SizeCode == undefined || row.Bed5SizeCode == null ? null: <span><span className="font-bold">Bed5: </span>{row.Bed5SizeCode}  &nbsp;</span> }
        { row.Bed6SizeCode == undefined || row.Bed6SizeCode == null ? null: <span><span className="font-bold">Bed6: </span>{row.Bed6SizeCode}  &nbsp;</span> }
        { row.Bed7SizeCode == undefined || row.Bed7SizeCode == null ? null: <span><span className="font-bold">Bed7: </span>{row.Bed7SizeCode}  &nbsp;</span> }
        { row.Bed8SizeCode == undefined || row.Bed8SizeCode == null ? null: <span><span className="font-bold">Bed8: </span>{row.Bed8SizeCode}  &nbsp;</span> }
        { row.Bed9SizeCode == undefined || row.Bed9SizeCode == null ? null: <span><span className="font-bold">Bed9: </span>{row.Bed9SizeCode}  &nbsp;</span> }
        { row.Bed10SizeCode == undefined || row.Bed10SizeCode == null ? null: <span><span className="font-bold">Bed10: </span>{row.Bed10SizeCode}  &nbsp;</span> }
  </div>

    );
  };

  export default BedsList;