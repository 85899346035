const EarnTripCredit = props => {
  return (
    <div className="googlec banner">
    <p className="backbtn"  onClick={()=>props.pageChanges(0)}>Back</p>
      <div className="container">
        <h1>
          BENEFITS OF A <span>SUITEAMERICA </span> CORPORATE APARTMENT VS.
          HOTELUNIQUE EXPERIENCE
        </h1>
        <div className="mrg-top-40">
          <img className="img-responsive" src={s3BucketUrl+"landing-images/Google/scale.png"}/>
        </div>
        <div className="row googlec_bannertext">
          <div className="col-md-6 border_red">
            <p>
              <span>300 sq. feet average </span> • Basic Cable • Wifi* •
              Parking* • Pet Friendly* • Gym? • Small Refrigerator • Bed &
              Bathroom
            </p>
            <h2>*May be subject to additional fees</h2>
          </div>

          <div className="col-md-6">
            <p>
              <span>700 sq. feet average </span>• Expanded Cable • Wifi •
              Designated Parking • Pet Friendly • Gym • Full Size Refrigerator •
              Full Size Oven • Dishwasher • Private Bedroom & Full Bathroom •
              Living Room • Washer & Dryer • Full Size Closet • Dining Area •
              Mailbox • Concierge Services • 24/7 Customer Care & Support
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EarnTripCredit;
