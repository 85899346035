/* Importing the node modules, child components, services and controllers used 
   inside WorldBankOnlineManagement component */    
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import {Link} from 'react-router-dom';
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   import WorldBankHeader from "../../common/worldBankHeader";   
   /* WorldBankOnlineManagement Component initialization */
   class WorldBankOnlineManagement extends React.Component {
       /* Initializing objects of its WorldBankOnlineManagement class */
         constructor(props) {
           super(props);  
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
           MainController.getInitialInformation();
        }
       /* It is invoked to return html content */
       render() {
           return (
            <div className="corporateHousing_Updates">
                <GuestHeader/>
                <WorldBankHeader/>
                <header className="codeconveyHeader cc_image_text_main corporate-h video-wrapper worldbnk_banner">
                    <img className="img-responsive" src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/world-bank/Online-management-dark.jpg" width="100%" alt=""  />
                    <div className="wow fadeInUp img-on-text top40 width500 text-center left30 img-on-text-center company_bannertxt" data-wow-delay="0.5s">
                        <h1>Online Management</h1>
                    </div>
                </header>
                <div className="quote-section bg-white">
                    <div className="container">
                        <div className="text-center wow fadeInUp company_p" data-wow-delay="0.5s">
                            <h2>SuiteAmerica has designed our website and apps with you in mind!</h2>
                            <p>With our new application for the iPhone and iPads, you have access to all your SuiteAmerica details including reservation dates, suite address, phone number, and parking information. View a map with directions directly to your suite, submit service requests and check the status of any submitted service requests. All with just a single touch.
                            </p>
                        </div>
                        <div className="service_ch_new mrg-btm-60 public_trans_bnk">
                            <div className="col-md-12">
                                <h4 className="text-red text-left">HERE’S WHAT TO DO:</h4>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="wow fadeInUp company_p dual_quote" data-wow-delay="0.5s">
                                <img className="black_img_clr" src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/world-bank/RegisterNow-icon.png" />
                                <p className="font-size-12">Register on our website under the Login link. Once you’ve signed up and logged in, you will be able to view your suite information, reprint invoices, submit service requests, view Suite Extras and let us know how we are doing.</p>
                                <Link to="#" className="btn btn-red btn-block">
                                Register Now</Link>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="wow fadeInUp company_p dual_quote" data-wow-delay="0.5s">
                                <img className="black_img_clr" src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/world-bank/DownloadApp-icon.png" />
                                <p className="font-size-12">Simply download the application from the App Store or iTunes, and, using the same user name and login from our website, you’ll have immediate access to all account information.</p>
                                <a href="https://itunes.apple.com/us/app/suiteamerica/id354097247?mt=8" target="_blank" className="btn btn-red btn-block">Download Now</a>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="wow fadeInUp company_p dual_quote" data-wow-delay="0.5s">
                                <img className="black_img_clr" src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/world-bank/Customize-icon.png" />
                                <p className="font-size-12">Add “extras” to your suite! You can view Custom Amenities on our website. Want to see pricing and order your Custom Amenities online? Simply sign into your account and view prices, pictures, even submit your request online.</p>
                                <Link to="#" className="btn btn-red btn-block">
                                Customize Your Stay</Link>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="wow fadeInUp company_p dual_quote" data-wow-delay="0.5s">
                                <img className="black_img_clr" src="https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/world-bank/ServiceRequest-icon.png" />
                                <p className="font-size-12">Have anything to bring to our attention? Simply log onto your account and go to our Service Request tab and submit your form online. Once you have submitted your request, you can track the progress and status online.</p>
                                <Link to="#" className="btn btn-red btn-block">
                                Submit A Request</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  
                <SubFooter/>
                <Footer/>
            </div>   
           );
       }
   }
   export default WorldBankOnlineManagement;