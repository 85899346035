/* Importing the node modules, child components, services and controllers used 
   inside AltairLogin object */
   'use strict'
   import AltairHeader from '../../common/altairHeader';

   import MainController from '../../controller/mainController';
   import userEmails from '../../../services/userEmails';
   import SessionService from "../../../services/sessionService";
    class AltairLogin extends React.Component{
        constructor(props) {
            super(props)
            /* ClientLogin Component State variables Initialization */
            this.state = {
                email: '',
                password: '',
                message: '',
                color: '',
                loader: false,
                guest:false,
                client:true,
                forGotpassword:false,
                url:this.props.match.path
            }
            this.authentication = this.authentication.bind(this);
            this.sendForGotPassword = this.sendForGotPassword.bind(this);
            this.setValue = this.setValue.bind(this);
            this.changeRoles = this.changeRoles.bind(this);
          }
          async componentWillMount() {
            MainController.getInitialInformation();
         }      

          setValue(field, e) {
            var object = {};
            object[field] = e.target.value;
            this.setState(object);
        }

      /**
      * To login into the application with corresponding login credentials
      * @param {*} e
      */
      async authentication(e) {
        e.preventDefault();
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if(this.state.client){
    if ((this.state.client && this.state.password != '') && (this.state.email != '' && reg.test(this.state.email) == true)) {
        this.setState({message: '', loader: true})
        const req={
            email:'altairdean@suiteamerica.com',
            password:'Altair!2021#',
            firstName:'Altair',
            lastName:'Dean',
            //clientType:'CE',
            clientType:'Altair',
        }
      

        if ((this.state.email== req.email&& this.state.password==req.password ) || (this.state.email==req.anotheremail && this.state.password==req.anotherpassword)) {
          
            const clientInfo={userData:{
                'ClientEmail': req.email,
                'ClientFirstName': req.firstName,
                'ClientLastName': req.lastName,
                'ClientId': '',
                'clientid': '',
                'ClientType':req.clientType,
            },type:'altair'}

            const Response = await SessionService.SessionStore(clientInfo);
            const GetResponse = await SessionService.SessionGet({type:'altair'});

            { console.log("GetResponse:", GetResponse) }

          
         if (GetResponse.roleId==4) {
                    location.href = '/altair-dashboard'
            }
        }
        else{
            this.setState({message: 'Invalid username or password', color: 'red',loader: false}) 
        }
    }
    else{
        this.setState({message: 'Enter Valid Email & Password', color: 'red',loader: false}) 
     }
}
    }
      /**
      * To update the client forgotpassword details 
      * @param {*} e
      */
      async sendForGotPassword(e){
        e.preventDefault();
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if(this.state.email != '' && reg.test(this.state.email) == true){
            this.setState({message: '', loader: true})
            const clientInfo={
              email: this.state.email,
              encodeEmail: Base64.encode(this.state.email),
              mail:'clientForgotPassword'
            }
            const mailsRes = await userEmails.sendMail(clientInfo)
            this.setState({loader: false})
            if (mailsRes.response) {
              jQuery(function ($) {
                  $('#successModal').modal('show');
                  setTimeout(function () {
                      $('#successModal').modal('hide');    
                  }.bind(this), 2000);
                
              }.bind(this));
             
              this.setState({forGotpassword: false,email:''})
          } else {
              this.setState({message: 'We Are unable to sent to mail', color: 'red'})
          }
        }
        else{
            this.setState({message: 'Enter Valid Email', color: 'red',loader: false}) 
         }
    }
     /**
      * To hide and show the login and forgotpassword details
      * @param {*} e
      */ 
    changeRoles(status){
        this.setState({forGotpassword:status,message: '', color: ''})
        }

       render(){
        return(
            <div className="altairDB altairDeanBlue">
    <AltairHeader/>
    <div className="">
        <div className="container">
            <div className="savesearch form-section col-sm-6 col-sm-offset-3 text-center">

                <h2>Altair Login</h2>

                <form role="form" method="post">
                    {this.state.message ? <span style={{color: this.state.color,textAlign: 'center'}}>
                            <h4>{this.state.message}</h4>
                            </span>: ''}

                    <div className="form-group">
                        <input type="text" className="form-control" id="email" placeholder="Email" value={this.state.email} onChange={(e) => this.setValue('email', e)}/>
                    </div>
                    {!this.state.forGotpassword?<div className="form-group">
                            <input type="password" className="form-control" placeholder={!this.state.forGotpassword?"Password":"New Password"} value={this.state.password} onChange={(e) => this.setValue('password', e)}/>
                    </div>:''}

                    <div className="form-group row">
                    {this.state.loader == true ? 
                            <div className="panel-body_loader">
                            <span style={{ fontSize: '20px', width: '100%', margin: 'auto', textAlign: 'center' }}><i className="fa fa-spinner fa-spin"/>
                            <span style={{marginLeft:'10px'}}> please wait ...!</span> </span>
                        </div>
                        :<span><button className="btn company_btn" type="submit" onClick={!this.state.forGotpassword?(e) => this.authentication(e): (e) => this.sendForGotPassword(e)}>{!this.state.forGotpassword ?'Login':'Submit'}</button>
                        {/* <a onClick={() => this.changeRoles(!this.state.forGotpassword?true:false)} >{!this.state.forGotpassword ?'Forgot your password?':'Login'}</a> */}
                        </span>
                    }

                        <br/>

                    </div>

                </form>
            </div>
        </div>
    </div>

</div>
        )
       }
           
       
      
   }
   
   export default AltairLogin