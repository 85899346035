import React from "react";
import Footer from "./footer";
import OwlCarousel from "react-owl-carousel";
import TransHeader from "./transheader";
import CityData from "./json/cityData";
import SubImage from "./json/subimage";
import ContentLoader from "react-content-loader";
import AppController from "../../controller/appController";
import LocationService from "../../../services/locationService";
import Spinner from "./guestPortal/spinner";
import Schools from "./Schools";


class Location extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      commpanyname: "",
      communityData: [],
      cityIntro: "",
      cityImage: "",
      address: "",
      latitude: "",
      longitude: "",
      city: "",
      state: "",
      cityHighlights: [],
      cityInfo: "",
      demoGraphics: [],
      weather: [],
      allScools: [],
      privateScools: [],
      publicScools: [],
      charterScools: [],
      greatSchools: {},
      subimgurl: "",
      types: [
        {
          name: "Restaurants",
          type: "restaurant",
          fa: "fas fa fa-cutlery",
          id: 0,
          icon: "/assets/newUI-images/explore_icons/restarent.svg",
        },
        {
          name: "Bars",
          type: "bar",
          fa: "fas fa fa-beer",
          id: 1,
          icon: "/assets/newUI-images/explore_icons/bars.svg",
        },
        {
          name: "Shopping",
          type: "shopping_mall",
          fa: "fas fa fa-shopping-bag",
          id: 2,
          icon: "/assets/newUI-images/explore_icons/shopping.svg",
        },
      ],
      type: "",
      popupname: "",
      showlist: false,
      location: [],
      markerList: [],
      activeNumber: -1,
      Propertiesdetails: [],
      propertiesloader: false,
      carowselLoader: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.mapgetmethod = this.mapgetmethod.bind(this);
  }

  async componentDidMount() {
    await this.setState({ carowselLoader: true });
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href =
      "https://suiteam-website.s3.us-west-1.amazonaws.com/new/logo/SA-Icon.png";
    this.getSearchResult();
  }

  async weather(apidata) {
    const weather = await AppController.weather(apidata);
    var results = weather.list.map(function (el) {
      var o = Object.assign({}, el);
      o.date = o.dt_txt.split(" ")[0];
      return o;
    });
    var data1 = _.chain(results)
      .groupBy("date")
      .map(function (currentItem) {
        let minTEMP = _.minBy(currentItem, function (o) {
          return o.main.temp_min;
        });
        let maxTEMP = _.maxBy(currentItem, function (o) {
          return o.main.temp_max;
        });
        return {
          weatherData: currentItem[0],
          weatherDataMax: maxTEMP.main.temp_max,
          weatherDataMin: minTEMP.main.temp_min,
        };
      })
      .value();
    this.setState({
      weather: data1.splice(1, 4),
      weather_current: data1[0].weatherData,
      weather_currentCityDetails: weather.city,
    });
  }

  async getSearchResult() {
    var companyName = "suiteamerica";
    var paramsObj = {};
    var city = "";
    var state = "";
    if (this.props.match != undefined) {
      paramsObj =
        companyName != "suiteamerica"
          ? this.props.props.match.params
          : this.props.match.params;
      city = paramsObj.city ? paramsObj.city.toLowerCase() : undefined;
      state = paramsObj.state ? paramsObj.state.toLowerCase() : undefined;
    } else {
      paramsObj = "suiteamerica";
      city = undefined;
      state = undefined;
    }
    let individualdata = "";
    let subindividualdata = "";
    if (
      city !== undefined &&
      state !== undefined &&
      CityData[state + "-" + city] !== undefined &&
      SubImage[state + "-" + city]
    ) {
      individualdata = CityData[state + "-" + city];
      subindividualdata = SubImage[state + "-" + city];
      const data = {
        companyname: companyName,
        latitude: "",
        longitude: "",
        address:
          individualdata.address != undefined ? individualdata.address : "",
        state_id:
          individualdata == undefined ||
          individualdata.state_id == undefined ||
          (individualdata.state_id == "" && individualdata.state_id == null)
            ? ""
            : individualdata.state_id,
        city_id:
          individualdata == undefined ||
          individualdata.city_id == undefined ||
          (individualdata.city_id == "" && individualdata.city_id == null)
            ? ""
            : individualdata.city_id,
        amenitiesId:
          individualdata.amenities_id != undefined
            ? individualdata.amenities_id
            : "",
        communityName:
          individualdata.communityName != undefined
            ? individualdata.communityName
            : "",
      };
      await this.setState({
        locationName: individualdata.locationName,
        city: individualdata.city,
        state: individualdata.state,
        countryId: individualdata.countryId,
        tagLine: individualdata.tagLine,
        cityHighlights: individualdata.cityHighlights,
        cityInfo: individualdata.cityInfo,
        cityImage: individualdata.image,
        metaTitle: individualdata.metaTitle,
        metaDescription: individualdata.metaDescription,
        metaUrl: individualdata.metaUrl,
        demoGraphics: individualdata.demoGraphics,
        subimgurl: subindividualdata.image,
        latitude: individualdata.latitude,
        longitude: individualdata.longitude,
        propertiesloader: true,
      });
      this.communityInfo(data, individualdata);
      this.method(individualdata);
      this.getProperties();
    }
  }

  async method(individualdata) {
    const apidata = {
      city: individualdata.city,
      state_name: individualdata.state,
      address:
        individualdata.address == ""
          ? individualdata.city
          : individualdata.address,
      latitude: individualdata.latitude,
      longitude: individualdata.longitude,
    };

    this.weather(apidata);
    this.greatSchools(apidata);
    // this.poi(apidata);
    //this.walkScore(apidata)
  }

  async communityInfo(data, individualdata) {
    const defaultRequest = {
      companyname: "suiteamerica",
      latitude: "",
      longitude: "",
      address: "",
      state_id: "",
      city_id: "",
      amenitiesId: "",
      communityName: "",
    };
    const req = data.city_id == "25247" ? defaultRequest : data;
    this.setState({
      savedSearchData: "",
      communityData: [],
      isLoading: true,
    });
    const communityData = await LocationService.getCommunityData(req);
    await this.setState({
      savedSearchData: individualdata,
      communityData,
      isLoading: false,
    });
  }
  // Point of GreatSchools
  async greatSchools(apidata) {
    const greatSchools = await AppController.greatSchools2(apidata);
    const privateScools = _.filter(greatSchools.schools, function (o) {
      o.schoolSummary = o["school-summary"];
      o.overviewUrl = o["overview-url"];
      return o.type == "private";
    });
    const publicScools = _.filter(greatSchools.schools, function (o) {
      o.schoolSummary = o["school-summary"];
      o.overviewUrl = o["overview-url"];
      return o.type == "public";
    });
    const charterScools = _.filter(greatSchools.schools, function (o) {
      o.schoolSummary = o["school-summary"];
      o.overviewUrl = o["overview-url"];
      return o.type == "charter";
    });
    this.setState({
      allScools: publicScools,
      greatSchools: greatSchools,
      privateScools: privateScools,
      publicScools: publicScools,
      charterScools: charterScools,
    });
  }
  // Point of Interest
  async poi(apidata) {
    const poi = await AppController.poi(apidata);
    this.setState({ poi: poi.results });
  }
  async handleClick(type) {
    $(".nav-item").removeClass("active");
    $("#" + type).addClass("active");

    if (type == "public") {
      $("#privatee").removeClass("in active");
      $("#charterr").removeClass("in active");

      $("#publicc").addClass("in active");
    }
    if (type == "private") {
      $("#publicc").removeClass("in active");
      $("#charterr").removeClass("in active");

      $("#privatee").addClass("in active");
    }
    if (type == "charter") {
      $("#publicc").removeClass("in active");
      $("#privatee").removeClass("in active");

      $("#charterr").addClass("in active");
    }

    // var data = [];
    // if (type == "private") {
    //   data = this.state.privateScools;
    // } else if (type == "public") {
    //   data = this.state.publicScools;
    // } else if (type == "charter") {
    //   data = this.state.charterScools;
    // }
    // await this.setState({
    //   allScools: data,
    // });
  }

  async onOpenpopup(type) {
    let obj = this.state.types.find((o) => o.type == type);
    await this.setState({
      type: type,
      popupname: obj.name,
      showlist: false,
      typeicon: obj.id,
      activeNumber: -1,
    });
    await this.mapgetmethod();
    jQuery(
      function ($) {
        setTimeout(
          function () {
            $("#explore_map").modal("show");
            this.setState({ showlist: true });
          }.bind(this),
          100
        );
      }.bind(this)
    );
  }

  CustomZoomInControl(controlDiv, map) {
    // Set CSS for the control border
    var controlUI = document.createElement("div");

    controlUI.style.cursor = "pointer";
    controlUI.style.display = "-webkit-inline-box";
    controlUI.style.margin = "18px 10px";
    controlUI.style.width = "30px";
    controlUI.style.height = "30px";
    controlDiv.appendChild(controlUI);
    // Set CSS for the control interior
    var controlText = document.createElement("div");
    controlText.innerHTML += `<img style="height:30px;width:30px;margin:0"  src='/assets/newUI-images/plus.svg' />`;
    controlUI.appendChild(controlText);

    // Setup the click event listeners
    google.maps.event.addDomListener(controlUI, "click", function () {
      map.setZoom(map.getZoom() + 1);
    });

    // Set CSS for the control border
    var controlUILeft = document.createElement("div");

    controlUILeft.style.display = "-webkit-inline-box";
    controlUILeft.style.marginLeft = "10px";
    controlUILeft.style.cursor = "pointer";
    controlUILeft.style.width = "30px";
    controlUILeft.style.height = "30px";
    controlDiv.appendChild(controlUILeft);

    var controlTextLeft = document.createElement("div");

    controlTextLeft.innerHTML += `<img style="height:30px;width:30px;margin:0"  src='/assets/newUI-images/minus.svg' />`;
    controlUILeft.appendChild(controlTextLeft);

    // Setup the click event listeners
    google.maps.event.addDomListener(controlUILeft, "click", function () {
      map.setZoom(map.getZoom() - 1);
    });
  }
  async mapgetmethod(index) {
    const _this = this;
    const markers = [];
    var pyrmont = {
      lat: Number(this.state.latitude),
      lng: Number(this.state.longitude),
    };
    console.log(pyrmont, "pyrmont");
    const map = (this.map = new google.maps.Map(
      document.getElementById("mapview"),
      {
        zoom: 14,
        center: pyrmont,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        backgroundColor: "#eeeeee",
        disableDefaultUI: true,
      }
    ));
    var style = [
      {
        elementType: "geometry",
        stylers: [{ saturation: -40 }],
      },
    ];
    var mapType = new google.maps.StyledMapType(style, { name: "Grayscale" });
    map.mapTypes.set("grey", mapType);
    map.setMapTypeId("grey");
    var customZoomInControlDiv = document.createElement("div");
    this.CustomZoomInControl(customZoomInControlDiv, map);
    customZoomInControlDiv.index = 1;
    map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(
      customZoomInControlDiv
    );

    var service = new google.maps.places.PlacesService(map);
    const request = {
      location: pyrmont,
      radius: 2000,
      type: this.state.type,
    };
    service.nearbySearch(request, async (data, status) => {
      var infowindow = new google.maps.InfoWindow();
      if ("OK" === google.maps.places.PlacesServiceStatus.OK) {
        const location = [];
        var results = _.orderBy(data, ["name"], ["asc"]);
        for (var i = 0; i < results.length; i++) {
          location.push({
            latitude: results[i].geometry.location.lat(),
            longitude: results[i].geometry.location.lng(),
            name: results[i].name,
            address: results[i].vicinity,
          });
          const marker = (this.marker = new google.maps.Marker({
            map: map,
            position: results[i].geometry.location,
            center: results[i].geometry.location,
            icon: this.state.types[this.state.typeicon].icon,
          }));
          google.maps.event.addListener(
            marker,
            "click",
            (function (marker, i) {
              return function () {
                var contentString = "";
                var prop = "photos";
                if (results[i].hasOwnProperty(prop)) {
                  _this.setState({ activeNumber: results[i].name });
                  contentString =
                    '<h3 style="font-size:20px; text-align:center">' +
                    results[i].name +
                    '</h3><img src="' +
                    results[i].photos[0].getUrl() +
                    '" alt="Loading" width="160" height="80"> <div id="content"><div className=""><div></div><p>' +
                    results[i].vicinity +
                    '</p> <a href="' +
                    "https://www.google.com/maps/place/" +
                    results[i].vicinity +
                    '" target="_blank">Get Directions</a></div>' +
                    "</div>";
                } else {
                  _this.setState({ activeNumber: results[i].name });
                  contentString =
                    '<h3 style="font-size:20px; text-align:center">' +
                    results[i].name +
                    '</h3> <div id="content"><div className=""><div></div><p>' +
                    results[i].vicinity +
                    '</p> <a href="' +
                    "https://www.google.com/maps/place/" +
                    results[i].vicinity +
                    '" target="_blank">Get Directions</a></div>' +
                    "</div>";
                }
                infowindow.setContent(contentString);
                infowindow.open(map, this);
              };
            })(marker, i)
          );
          markers.push(marker);
        }
        await _this.setState({
          location: location,
          markerList: markers,
        });
        _this.getDistance();
      }
    });
    _this.setState({
      map,
    });
  }
  async getDistance() {
    for (var i = 0; i < this.state.location.length; i++) {
      if (
        this.state.location[i].latitude != "" &&
        this.state.location[i].latitude != undefined &&
        this.state.location[i].latitude != null &&
        this.state.location[i].longitude != "" &&
        this.state.location[i].longitude != undefined &&
        this.state.location[i].longitude != null
      ) {
        const distanceVal = this.distanceCal(
          this.state.latitude,
          this.state.longitude,
          this.state.location[i].latitude,
          this.state.location[i].longitude
        );
        let updatedPosts = update(this.state.location, {
          [i]: {
            distance: {
              $set: parseFloat(distanceVal),
            },
          },
        });
        await this.setState({ location: updatedPosts });
      } else {
        let updatedPosts = update(this.state.location, {
          [i]: {
            distance: {
              $set: 0,
            },
          },
        });
        await this.setState({ location: updatedPosts });
      }
      if (this.state.location.length == i + 1) {
        await this.setState({
          location: _.sortBy(this.state.location, [
            function (o) {
              return o.distance;
            },
          ]),
        });
      }
    }
  }
  distanceCal(lat1, lon1, lat2, lon2, unit) {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit == "K") {
      dist = dist * 1.609344;
    }
    if (unit == "N") {
      dist = dist * 0.8684;
    }
    return dist.toFixed(2);
  }
  flyToPlace(row, index, type) {
    var latLng = new google.maps.LatLng(row.latitude, row.longitude);
    this.map.panTo(latLng);
    const placemarker =
      type == "locations"
        ? this.state.markerList
        : this.state.placeSearchedMarkers;
    google.maps.event.trigger(placemarker[index], "click");
    this.setState({ activeNumber: row.name });
  }
  async onRenderpage() {
    let city = this.state.city
      .toLowerCase()
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
    await localStorage.setItem("city", city);
    window.open("/locations", "_blank");
  }
  async getProperties() {
    let city_name = this.state.city
      .toLowerCase()
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
    let communitySearch = {
      startLimit: 0,
      companyname: "suiteamerica",
      latitude: this.state.latitude,
      longitude: this.state.longitude,
      address: city_name,
      state_id: "",
      city_id: "",
      amenitiesId: "",
      communityName: "",
      countryId: "",
      userid: "",
    };
    const SearchResult = await LocationService.getCommunityData(
      communitySearch
    );
    await this.setState({
      Propertiesdetails: SearchResult,
      propertiesloader: false,
      carowselLoader: false,
    });
  }
  async onRenderurl(url) {
    window.open(url, "_blank");
  }
  testName(cmtyname) {
    let cmty = cmtyname
      .toLowerCase()
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
    return cmty.replace(" Ii", " II");
  }

  render() {
    var finalLocation = _.orderBy(this.state.location, ["name"], ["asc"]);
    return (
      <div className="SAweb_updateUi">
        <TransHeader />
        <section className="singleBannerSA">
          <img
            src={
              this.state.cityImage !== ""
                ? this.state.cityImage
                : s3BucketUrl_WebsiteImages + "SuiteAmerica-Location-Banner.jpg"
            }
            alt=""
            width="100%"
          />
          <h1>{this.state.city} </h1>
        </section>
        <section className="SAweb-empireCity bg-white">
          <div className="container">
            <div className="box">
              <div className="item">
                <h2>{this.state.tagLine}</h2>
                <p>
                  {this.state.cityInfo == "" ? (
                    <ContentLoader>
                      <rect x="3" y="0" rx="3" ry="3" width="180" height="5" />
                      <rect x="0" y="18" rx="0" ry="3" width="380" height="5" />
                      <rect x="0" y="36" rx="3" ry="3" width="380" height="5" />
                      <rect x="0" y="54" rx="3" ry="3" width="380" height="5" />
                    </ContentLoader>
                  ) : (
                    this.state.cityInfo
                  )}
                </p>
              </div>
              <div className="item">
                <img
                  src={
                    this.state.subimgurl !== ""
                      ? this.state.subimgurl
                      : "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/screen-7/empireCity.jpg"
                  }
                  alt=""
                  width="100%"
                />
              </div>
            </div>
          </div>
        </section>

        {/* <section className="SAweb_awardView text-white text-center py-5">
          <div className="container">
            <div className="box my-5">
              <div className="item">
                <div className="image">
                  <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/screen-7/LaurelWreath.svg" alt="" />
                  <h1>1 <sup>st</sup></h1>
                </div>

                <h2>Most Influential City in the World</h2>
                <p>Business Insider</p>
              </div>
              <div className="item">
                <div className="image">
                  <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/screen-7/LaurelWreath.svg" alt="" />
                  <h1>6 <sup>th</sup></h1>
                </div>

                <h2>Most Diverse Cities in America</h2>
                <p>Niche</p>
              </div>
              <div className="item">
                <div className="image">
                  <img src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/screen-7/LaurelWreath.svg" alt="" />
                  <h1>52 <sup>nd</sup></h1>
                </div>

                <h2>On the list of Fortune 500</h2>
                <p>Fortune</p>
              </div>
            </div>
            <h2>Home to the Statue of Liberty, Central Park,
              Empire State Building, and Times Square..</h2>

          </div>

        </section> */}

        <section>
          <div className="SAweb_location bg-white border-2 border-dark border-bottom border-top">
            <div className="container">
              <div className="row   mx-0" data-aos="fade-up">
                <div className="col-md-1 ">
                  <div className="HeadingRotate">
                    <h2 className="h2_rotate">Demographics</h2>
                  </div>
                </div>
                <div className="col-md-11 ms-2 ps-5 my-5 py-5">
                  <h3 className="text-dark">Statistics</h3>
                  <div className="demographics">
                    {this.state.demoGraphics.map((obj, index) => {
                      return (
                        <div className="item" key={index}>
                          <h4>{obj.statistic}</h4>
                          <p>{obj.value}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="SAweb_featuredProperty bg-white py-5">
          <div className="container">
            {this.state.Propertiesdetails.length != 0 ? (
              <div className="topHeaing">
                <h2>
                  Featured Properties in{" "}
                  {this.state.city
                    .toLowerCase()
                    .split(" ")
                    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                    .join(" ")}
                </h2>
                <button
                  type="buttton"
                  className="btn"
                  onClick={() => this.onRenderpage()}
                >
                  View All <i className="bi bi-chevron-right"></i>
                </button>
              </div>
            ) : (
              ""
            )}

            {this.state.carowselLoader ? (
              <div className="cityspinner">
                <Spinner />
              </div>
            ) : (
              <div className="imageList_slider owl-slider SAweb_sliderBottomArrows billingSlider text-center">
                <OwlCarousel
                  className="owl-theme owl-carousel"
                  {...{
                    loop: false,
                    margin: 10,
                    nav: true,
                    dots: false,
                    items: 1,
                    navContainerClass: "owl-buttons",
                    navText: [
                      "<i class='glyphicon glyphicon-menu-left'></i>",
                      "<i class='glyphicon glyphicon-menu-right'></i>",
                    ],
                    responsive: {
                      600: { items: 1, loop: false },
                      767: { items: 2, loop: false },
                      992: { items: 3, loop: false },
                      1199: { items: 3, loop: false },
                    },
                  }}
                >
                  {this.state.Propertiesdetails.length > 0 &&
                    this.state.Propertiesdetails.map((row, index) => {
                      return (
                        <div
                          className="item"
                          key={index}
                          onClick={() =>
                            this.onRenderurl(
                              "/community/suiteamerica/" +
                                row.community_name.replace(/\/| /g, "-")
                            )
                          }
                        >
                          <div className="image">
                            <img src={row.banner_image} alt="" width="100%" />
                          </div>
                          <h3>{this.testName(row.community_name)}</h3>
                          {/* <h3>{(row.community_name)}</h3> */}
                        </div>
                      );
                    })}
                </OwlCarousel>
              </div>
            )}
          </div>
        </section>

        {this.state.weather_current != undefined &&
        this.state.weather != undefined ? (
          <section className="SAweb-weather text-center text-white">
            <div className="overlay"></div>
            <div className="container">
              <h3>
                Weather in{" "}
                {this.state.city
                  .toLowerCase()
                  .split(" ")
                  .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                  .join(" ")}
              </h3>
              <div className="owl-slider weatherCloud  ">
                <div>
                  <OwlCarousel
                    className="owl-theme owl-carousel"
                    {...{
                      loop: false,
                      nav: false,
                      dots: false,
                      items: 1,
                      navContainerClass: "owl-buttons",
                      navText: [
                        "<i class='glyphicon glyphicon-menu-left'></i>",
                        "<i class='glyphicon glyphicon-menu-right'></i>",
                      ],
                      responsive: {
                        767: { items: 2 },
                        991: { items: 3 },
                        1199: { items: 5 },
                      },
                    }}
                  >
                    <div className="item">
                      <h4>Today</h4>

                      {/* <img src={"https://openweathermap.org/img/w/" + this.state.weather_current.weather[0].icon + ".png"} alt="" title="" 
                    style={{width:'88px'}}/> */}
                      <h5>
                        {(
                          this.state.weather_current.main.temp_max * (9 / 5) +
                          32
                        ).toFixed(0)}
                        <sup>o</sup> F high
                      </h5>
                      <h5>
                        <div>
                          {this.state.weather_current.main.temp_max.toFixed(0)}
                          <sup>o</sup>C |
                          {this.state.weather_current.main.temp_min.toFixed(0)}
                          <sup>o</sup>C
                        </div>
                      </h5>
                      <p>{this.state.weather_current.weather[0].description}</p>
                    </div>
                    {this.state.weather.map((row, index) => {
                      let dateResult = row.weatherData.date.split("-");
                      let date =
                        dateResult[0] +
                        "/" +
                        dateResult[1] +
                        "/" +
                        dateResult[2];
                      return (
                        <div className="item" key={index}>
                          <h4>
                            {AppController.getDayName(date)}
                            <span>
                              {AppController.getMonthName(date) +
                                " " +
                                AppController.getDay(date)}
                            </span>
                          </h4>

                          {/* <img src={"https://openweathermap.org/img/w/" + row.weatherData.weather[0].icon + ".png"} alt="" title=""
                     style={{width:'88px'}}/> */}
                          <h5>
                            {(row.weatherDataMax * (9 / 5) + 32).toFixed(0)}
                            <sup>o</sup> F high
                          </h5>
                          <h5>
                            {row.weatherDataMax.toFixed(0)}
                            <sup>o</sup>C | {row.weatherDataMin.toFixed(0)}
                            <sup>o</sup>C
                          </h5>
                          <p>{row.weatherData.weather[0].description}</p>
                        </div>
                      );
                    })}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </section>
        ) : null}

        <section className="SAweb_featuredProperty bg-white py-5">
          <div className="container">
            <h2 className="pb-4">
              Explore{" "}
              {this.state.city
                .toLowerCase()
                .split(" ")
                .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                .join(" ")}
            </h2>

            <div className="imageList">
              <div className="item">
                <div className="image">
                  <img
                    style={{ cursor: "pointer" }}
                    onClick={() => this.onOpenpopup("restaurant")}
                    src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/screen-7/explore-1.jpg"
                    alt=""
                    width="100%"
                  />
                </div>
                <h3>Restaurants</h3>
                {/* <p>With wide varieties of cuisines</p> */}
              </div>
              <div className="item">
                <div className="image">
                  <img
                    style={{ cursor: "pointer" }}
                    onClick={() => this.onOpenpopup("bar")}
                    src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/screen-7/explore-2.jpg"
                    alt=""
                    width="100%"
                  />
                </div>
                <h3>Bars</h3>
                {/* <p>Lorem ipsum dolor sit amet.</p> */}
              </div>
              <div className="item">
                <div className="image">
                  <img
                    style={{ cursor: "pointer" }}
                    onClick={() => this.onOpenpopup("shopping_mall")}
                    src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/screen-7/explore-3.jpg"
                    alt=""
                    width="100%"
                  />
                </div>
                <h3>Shopping</h3>
                {/* <p>Lorem ipsum dolor sit amet.</p> */}
              </div>
            </div>
          </div>
        </section>

        <Schools
          handleClick={this.handleClick}
          privateScools={this.state.privateScools}
          publicScools={this.state.publicScools}
          charterScools={this.state.charterScools}
        />

        <Footer />

        <div
          className="modal modal-fullscreen-xl modal fade animated tab-modal guest-Ui SAweb_modalPoup"
          id="explore_map"
          tabIndex={-1}
          data-backdrop="static"
          data-keyboard="false"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content propertyGallery">
              <div className="modal-header border-0">
                <button
                  type="button"
                  className="btn close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="bi bi-x"></i>
                </button>
                <h2>{this.state.popupname}</h2>
              </div>
              <div className="modal-body">
                <div className="sm-d-flex row ">
                  <div className="col-sm-6">
                    {this.state.showlist && finalLocation.length > 0 ? (
                      <div className="tabsView">
                        <ul className="distanceList">
                          {finalLocation.map((row, index) => {
                            return (
                              <li
                                style={{ cursor: "pointer" }}
                                className={
                                  this.state.activeNumber == row.name
                                    ? "item test"
                                    : "item"
                                }
                                key={index}
                                onClick={() =>
                                  this.flyToPlace(row, index, "locations")
                                }
                              >
                                {row.name}
                                <span>{row.address}</span>
                                {
                                  <span className="location_distance">
                                    {row.distance} mi
                                  </span>
                                }
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    ) : (
                      <div>No Data Found!</div>
                    )}
                  </div>
                  <div className="col-sm-6">
                    <div id={"mapview"} className="map-alignment"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Location;
