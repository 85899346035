import OwlCarousel from "react-owl-carousel";
import Footer from "./footer";
import TransHeader from "./transheader";
import RequestCallback from "./requestCallback";
import BlogHeader from "./blogHeader";
import { Link } from "react-router-dom";
class NewHomepage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
    // console.log(this.props.location.pathname,'0000000')
  }

  async closeWelcomeModal() {
    await jQuery(function ($) {
      $("#welcomeMessage").removeClass("show");
    });

    await localStorage.setItem("shownModal", true);
  }

  handleClick() {
    $("#sideQuote").addClass("active");
    $(".overlay").addClass("active");
  }

  async openPropertySearch(type) {
    // let city = type == 'Bay Area' ? 'Mountain View' : type
    await localStorage.setItem("city", type);
    location.href = "/locations";
  }

  render() {
    let welcomeModal = localStorage.getItem("shownModal");
    return (
      <div className="SAweb_updateUi">
        {welcomeModal == 12 && (
          <div
            id="welcomeMessage"
            class="modal welcomeVideoModal show"
            tabindex="-1"
            role="dialog"
          >
            <div className="overlay"></div>
            <div
              class="modal-dialog modal-lg"
              role="document"
              data-aos="fade-down"
            >
              <div class="modal-content">
                <div class="modal-body">
                  <div className="welcomeVideoGIF">
                    <button
                      onClick={this.closeWelcomeModal}
                      type="button"
                      class="close btn"
                    >
                      <i class="bi bi-x"></i>
                    </button>
                    <div className="">
                      <img
                        src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/SAWeblogo/salogosmulti_logo_gif.gif"
                        width="100%"
                      />
                    </div>
                    <p>We're excited to show you the new SuiteAmerica. </p>
                    <p>
                      Learn more about our brand identity{" "}
                      <a href="/new-brand-identity" target="blank">
                        here.
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <BlogHeader />
        <div className="overlay sideQuote"></div>
        <section id="hero">
          <div className="hero-container">
            <div
              id="heroCarousel"
              className="carousel slide carousel-fade"
              data-bs-ride="carousel"
              data-bs-interval="5000"
            >
              <div className="carousel-inner" role="listbox">
                <div className="">
                  <div className=""></div>
                </div>
                <OwlCarousel
                  className="owl-theme owl-carousel"
                  {...{
                    loop: true,
                    // autoplay: true,
                    // autoplayTimeout: 3000,
                    nav: false,
                    dots: true,
                    items: 1,
                    navContainerClass: "owl-buttons",
                    navText: [
                      "<i class='glyphicon glyphicon-menu-left'></i>",
                      "<i class='glyphicon glyphicon-menu-right'></i>",
                    ],
                    responsive: {
                      767: { items: 1, loop: true },
                      992: { items: 1, loop: true },
                      1199: { items: 1, loop: true },
                    },
                  }}
                >  <div
                className="carousel-item"
                style={{
                  backgroundImage: `url("https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/homepage/Dean.jpg")`,
                }}
              >
                <div className="carousel-container ">
                  <div
                    className="container first-banner"
                    data-aos="fade-up"
                  >
                    <h2 className=" ">
                      Experience business hospitality at its finest
                    </h2>
                    <p className=" ">
                      Whether it’s relocation for a new job, a temporary
                      work assignment, a short work trip, or a month-long
                      getaway, our experiences are crafted to exceed your
                      expectations.
                    </p>

                    <div
                      onClick={this.handleClick}
                      className="btn btn-callback"
                    >
                      Reserve Now
                    </div>

                    <p>
                      {" "}
                      The Dean <span> | Mountain View, CA </span>{" "}
                    </p>
                  </div>
                </div>
              </div>
                    <div
                    className="carousel-item "
                    style={{
                      backgroundImage: `url("https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/homepage/Premier_on_Pine_Rooftop.jpeg")`,
                    }}
                  >
                    <div className="carousel-container">
                      <div className="container" data-aos="fade-up">
                        <h2 className=" ">Luxurious views, complimentary</h2>
                        <p className=" ">
                          Outdoor spaces to relax after work, included.{" "}
                          <span className="para-break"></span>Friends to "ooh &
                          ahh", not included.
                        </p>

                        <div
                          onClick={this.handleClick}
                          className="btn btn-callback"
                        >
                          Reserve Now
                        </div>

                        <p>
                          <span>Premier on Pine </span> | Seattle, WA
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="carousel-item "
                    style={{
                      backgroundImage: `url("https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/homepage/15_park_row_5.jpeg")`,
                    }}
                  >
                    <div className="carousel-container">
                      <div className="container" data-aos="fade-up">
                        <h2 className=" ">Reside in safe spaces</h2>
                        <p className=" ">
                          Our exclusive, vetted properties are in the areas you
                          want to be. <span className="para-break"></span>Arrive
                          & trust us with the rest.
                        </p>

                        <div
                          onClick={this.handleClick}
                          className="btn btn-callback"
                        >
                          Reserve Now
                        </div>

                        <p>
                          <span>15 Park Row </span> | New York , NY
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <div className="carousel-item active" style={{ backgroundImage: `url("/assets/newUI-images/homepage/banner.jpg")` }} >
                  <div className="carousel-container">
                    <div className="container" data-aos="fade-up">
                      <h2 className=" ">Experience business hospitality at its finest</h2>
                      <p className=" ">Whether it’s relocation for a new job, a temporary work assignment, a short work trip, or a month-long getaway, our experiences are crafted to exceed your expectations.</p>


                      <a href="#" onClick={this.handleClick} className="btn">
                        Reserve Now</a>

                      <h4> The Dean <span> |  Mountain View, CA </span> </h4>
                    </div>
                  </div>
                  </div> */}
                  <div
                    className="carousel-item active"
                    style={{
                      backgroundImage: `url("https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/homepage/Saltwood+5.jpeg")`,
                    }}
                  >
                    <div className="carousel-container">
                      <div className="container" data-aos="fade-up">
                        <h2 className=" ">Amenities, within reach</h2>
                        <p className=" ">
                          Ample community spaces to enjoy.{" "}
                          <span className="para-break"></span>Socializing,
                          optional
                        </p>

                        <div
                          onClick={this.handleClick}
                          className="btn btn-callback"
                        >
                          Reserve Now
                        </div>

                        <p>
                          <span>Saltwood </span> | Portland, OR
                        </p>
                      </div>
                    </div>
                  </div>
                
                

                  <div
                    className="carousel-item "
                    style={{
                      backgroundImage: `url("https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/homepage/Watermark+seaport.jpeg")`,
                    }}
                  >
                    <div className="carousel-container">
                      <div className="container" data-aos="fade-up">
                        <h2 className=" ">Dinner & a movie on the couch</h2>
                        <p className=" ">
                          Full-size kitchens to cook inspired meals. Or grab
                          take-out. <span className="para-break"></span>It's
                          your space, Enjoy.
                        </p>

                        <div
                          onClick={this.handleClick}
                          className="btn btn-callback"
                        >
                          Reserve Now
                        </div>

                        <p>
                          <span>Watermark Seaport </span>| Boston , MA
                        </p>
                      </div>
                    </div>
                  </div>
                </OwlCarousel>
                {/* <OwlCarousel className="owl-theme owl-carousel"
                  {...{
                    loop: false, nav: false, dots: true, items: 1,
                    navContainerClass: 'owl-buttons',
                    navText: ["<i class='glyphicon glyphicon-menu-left'></i>",
                      "<i class='glyphicon glyphicon-menu-right'></i>"],
                    responsive: {
                      767: { items: 1, loop: false },
                      992: { items: 1, loop: false },
                      1199: { items: 1, loop: false }
                    }
                  }}>

                  <div className="carousel-item active" style={{ backgroundImage: `url("https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/banner.jpg")` }} >
                    <div className="carousel-container">
                      <div className="container" data-aos="fade-up">
                        <h2 className=" ">Experience business hospitality at its finest</h2>
                        <p className=" ">Whether it’s relocation for a new job, a temporary work
                          assignment, a short work- trip, or a month-long getaway our experiences are crafted to exceed your
                          expectations.</p>


                        <a href="#" onClick={this.handleClick} className="btn">
                          Reserve Now</a>


                      </div>
                    </div>
                  </div>



                  <div className="carousel-item" style={{ backgroundImage: `url("https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/banner-2.jpg")` }} >
                    <div className="carousel-container">
                      <div className="container" data-aos="fade-up">
                        <h2 className=" ">Experience business hospitality at its finest</h2>
                        <p className=" ">Whether it’s relocation for a new job, a temporary work
                          assignment, a short work- trip, or a month-long getaway our experiences are crafted to exceed your
                          expectations.</p>
                        <a href="#" onClick={this.handleClick} className="btn ">
                          Reserve Now</a>
                      </div>
                    </div>
                  </div>





                  <div className="carousel-item" style={{ backgroundImage: `url("https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/banner-3.jpg")` }} >
                    <div className="carousel-container">
                      <div className="container" data-aos="fade-up">
                        <h2 className=" ">Experience business hospitality at its finest</h2>
                        <p className=" ">Whether it’s relocation for a new job, a temporary work
                          assignment, a short work- trip, or a month-long getaway our experiences are crafted to exceed your
                          expectations.</p>
                        <a href="#" onClick={this.handleClick} className="btn ">
                          Reserve Now</a>
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item" style={{ backgroundImage: `url("https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/banner-4.jpg")` }} >
                    <div className="carousel-container">
                      <div className="container" data-aos="fade-up">
                        <h2 className=" ">Experience business hospitality at its finest</h2>
                        <p className=" ">Whether it’s relocation for a new job, a temporary work
                          assignment, a short work- trip, or a month-long getaway our experiences are crafted to exceed your
                          expectations.</p>
                        <a href="#" onClick={this.handleClick} className="btn ">
                          Reserve Now</a>
                      </div>
                    </div>
                  </div>
                </OwlCarousel> */}
              </div>
            </div>
          </div>
        </section>

        <RequestCallback />

        <section className="SAwebour_commitment text-white text-center py-5">
          <div className="container">
            <h3 className="breakPoint">
              Our commitment - to make every experience a memorable one.
            </h3>
            <div className="box my-5">
              <div className="item">
                <div className="image">
                  <img
                    src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/star-w.png"
                    width="40px"
                    alt=""
                  />
                </div>
                <p>30+ years of Excellence</p>
              </div>
              <div className="item">
                <div className="image">
                  <img
                    src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/World_Beating_Tech_W.svg"
                    width="40px"
                    alt=""
                  />
                </div>
                <p>Award Winning Technology</p>
              </div>
              <div className="item">
                <div className="image">
                  <img
                    src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/Elegant_Properties_W.svg"
                    width="30px"
                    alt=""
                  />
                </div>

                <p>170+ Elegant Properties</p>
              </div>

              <div className="item">
                <div className="image">
                  <img
                    src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/Confirmed_Stays_W.svg"
                    width="31px"
                    alt=""
                  />
                </div>
                <p>1 Million + </p>
                <p>Stays</p>
              </div>
            </div>
          </div>
        </section>

        <section className="SAweb_makeBest  bg-white py-5 " data-aos="fade-up">
          <div className="container">
            <h2 className="text-center pt-5 pb-3">What makes us the best</h2>
            <div className="boxTails">
              <div className="item">
                <div className="image">
                  <img
                    src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/makeBest-1.jpg"
                    alt=""
                  />
                </div>
                <div className="text-view">
                  <h3>A legacy of 30+ years</h3>
                  <p>
                    For over 30 years, the best in the business have trusted us
                    by putting the comfort and hospitality of their employees in
                    our hands.
                  </p>
                </div>
              </div>
              <div className="item flex-row-reverse">
                <div className="image">
                  <img
                    src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/makeBest-2.jpg"
                    alt=""
                  />
                </div>
                <div className="text-view">
                  <h3>Attention to detail</h3>
                  <p>
                    For us, the details are everything. Before you make the big
                    move, our service team ensures your important criteria are
                    taken care of.
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="image">
                  <img
                    src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/makeBest-3.jpg"
                    alt=""
                  />
                </div>
                <div className="text-view">
                  <h3>Nationwide footprint, global standards</h3>
                  <p>
                    Our premier serviced apartments provide coast-to-coast
                    options in some of the most coveted neighborhoods.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="SAweb_expDrive bg-white pt-5 ">
          <div className="box">
            <div className="image">
              <img
                src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/experiencePic.jpg"
                alt=""
              />
              <div className="caption">
                <h4>Technology-driven</h4>
                <h2>Experience</h2>
              </div>
            </div>

            <div className="item">
              <h2>Indulge in our stellar technology</h2>
              <p>
                At SuiteAmerica, we pride ourselves on developing proprietary
                technologies that allow us to provide you customized services in
                line with your needs.
              </p>
              <p>
                We use just the right amount of tech at every step to deliver
                faster response times, increase efficiencies, streamline
                communication, and deliver a better experience at all times.
              </p>
              <ul
                data-aos="fade-left"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1000"
              >
                <li>
                  <div className="gif_pic pic">
                    <img src="assets/newUI-images/homepage/tick-18.gif" />
                  </div>{" "}
                  100% Cloud{" "}
                </li>
                <li>
                  <div className="gif_pic pic">
                    <img src="assets/newUI-images/homepage/tick-18.gif" />
                  </div>{" "}
                  120+ APIs{" "}
                </li>
                <li>
                  <div className="gif_pic pic">
                    <img src="assets/newUI-images/homepage/tick-18.gif" />
                  </div>{" "}
                  Secure & Scalable{" "}
                </li>
              </ul>
              <div>
                <a href="/technology" className="btn black-btn btn-border">
                  {" "}
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="SAweb_gHousingGuestExpc SAweb_homeYourStay bg-white">
          <div className="warapperDiv">
            <div className="row align-items-center" data-aos="fade-up">
              <div className="col-sm-7">
                <div>
                  <h2>Facilitating Your Stay With Our Technology Suite</h2>
                  <p>
                    {" "}
                    Raise service requests, explore the neighborhood or check
                    your reservation information and property details with our
                    secure suite of Web and Mobile Applications.{" "}
                  </p>
                  <a href="/technology" className="btn black-btn">
                    {" "}
                    Learn More
                  </a>
                </div>
              </div>
              <div className="col-sm-5">
                <div className="tailBox">
                  <a href="/guest-login">
                    <div className="item">
                      <img
                        src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/gHousing/laptop.png"
                        alt=""
                      />
                      <div>
                        <h3>Guest Portal</h3>
                        <p>
                          An interactive portal to access information from
                          anywhere.
                        </p>
                      </div>
                    </div>
                  </a>
                  <a
                    href="https://apps.apple.com/app/guestportal/id1492423942"
                    target="_blank"
                  >
                    {" "}
                    <div className="item">
                      <img
                        src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/gHousing/mobileApp.png"
                        alt=""
                      />
                      <div>
                        <h3>Mobile App</h3>
                        <p>
                          User-friendly mobile app to personalize your stay on
                          the go.
                        </p>
                      </div>
                    </div>
                  </a>
                  <div className="item">
                    <img
                      src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/gHousing/apple-tv.png"
                      alt=""
                    />
                    <div>
                      <h3>TV App</h3>
                      <p>
                        An App on your TV so you’re in control of the
                        nitty-gritty throughout your stay.
                      </p>
                    </div>
                  </div>
                  <div className="item">
                    <img src="" alt="" />
                    <div>{/* <h4>*Q2 2023</h4> */}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ---------new section ========= */}
        <section className="SAweb_gHousingGuestExpc bg-white py-5">
          <div className="warapperDiv container py-3 appStoreViewParant">
            <div className="row align-items-center" data-aos="fade-up">
              <div className="col-sm-6">
                <div>
                  <h1 className="m-0">Your ultimate </h1>
                  <h1>stay hub</h1>
                  <p className="p">Reduced touchpoints</p>

                  <>
                    <ul className="appStore-listview">
                      <li> Our app centralizes everything </li>
                      <li>
                        <span>
                          <img
                            src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/chapp_webapp.svg"
                            alt="laptop_icon"
                            width={55}
                          />
                        </span>
                        <span>One spot for stay info</span>
                      </li>
                      <li>
                        <span>
                          <img
                            src="/images/icons/touch_icon.png"
                            alt=""
                            width={55}
                          />
                        </span>
                        <span>Submit service requests</span>
                      </li>
                      <li>
                        <span>
                          <img
                            src="/images/icons/time.png"
                            alt=""
                            height={35}
                          />
                        </span>
                        <span>24/7 support</span>
                      </li>
                      <li className="mt-5">
                        <a
                          href="https://apps.apple.com/app/guestportal/id1492423942"
                          target="_blank"
                        >
                          <img
                            src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"
                            alt="app store"
                            width={150}
                          ></img>
                        </a>{" "}
                        <a
                          href="https://play.google.com/store/apps/details?id=com.suiteamerica.guestportal&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                          target="_blank"
                          className="mrg-left-10"
                        >
                          <img
                            src="/assets/newUI-images/google-play-store-badge.svg"
                            alt="Play store"
                            height={50}
                          ></img>
                        </a>
                      </li>
                    </ul>
                  </>
                  <></>
                  <></>
                </div>
              </div>
              <div className="col-sm-6 text-center">
                <img
                  width="90%"
                  src="https://suiteam-website.s3.us-west-1.amazonaws.com/images-website/iphone-mockup.png"
                  alt="mobile app image"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="SAweb_chInfo  SAweb_guestExp">
          <div className="container">
            <h2 className="my-5 pt-5 pb-3  text-center" data-aos="fade-up">
              Stay, the SuiteAmerica Way
            </h2>
            <div className="statBox pb-5" data-aos="fade-up">
              <div className="item">
                <div className="image">
                  <img
                    src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/stayIcons/workout.png"
                    alt=""
                    width="50px"
                  />{" "}
                </div>
                <h5>The Hustle Bubble</h5>
                <p>
                  A fully-equipped fitness center to keep you on top of your
                  game
                </p>
              </div>
              <div className="item">
                <div className="image">
                  <img
                    src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/stayIcons/house.png"
                    alt=""
                    width="40px"
                  />
                </div>
                <h5>Parking Stations</h5>
                <p>A reserved parking space to rest your lean-mean machines</p>
              </div>
              <div className="item">
                <div className="image">
                  <img
                    src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/stayIcons/pool.png"
                    alt=""
                    width="50px"
                  />{" "}
                </div>
                <h5>Pool of Tranquility</h5>
                <p>
                  The perfect place to wash off all the stresses of your day
                </p>
              </div>
              <div className="item">
                <div className="image">
                  <img
                    src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/stayIcons/wifi.png"
                    alt=""
                    width="40px"
                  />
                </div>

                <h5>Connection Bay</h5>
                <p>High-speed WiFi that keeps you connected at all times</p>
              </div>
            </div>
          </div>
        </section>
        <section className="SAweb_featureProperty bg-white">
          <div className="container">
            <div className="SAweb_caseStudies mb-3 py-5 ">
              <div className="topHeading mt-4">
                <h2>Featured Properties </h2>
                <a href="/locations" className="btn viewall-btn">
                  {" "}
                  View All <i className="bi bi-chevron-right"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="imageBox">
            <div className="item hover-zoomin">
              <img
                src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/property-1.jpg"
                alt=""
              />
              <div className="hvrbox-layer_top hvrbox-layer_slideup">
                <div className="hvrbox-text">
                  <h1> The Dean </h1>
                  <p>
                    The right balance of modern design centered around a
                    thriving community that allows you to find your bliss.
                  </p>
                  <span>
                    {" "}
                    <i className="bi bi-geo-alt"></i> Mountain View, California{" "}
                  </span>
                </div>
              </div>
            </div>
            <div className="item hover-zoomin">
              <img
                src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/property-2.jpg"
                alt=""
              />
              <div className="hvrbox-layer_top hvrbox-layer_slideup">
                <div className="hvrbox-text">
                  {" "}
                  <h1> Premiere on Pine </h1>
                  <p>
                    Experience euphoria at a stunning location with contemporary
                    amenities amid a welcoming community.
                  </p>
                  <span>
                    {" "}
                    <i className="bi bi-geo-alt"></i> Seattle, Washington
                  </span>
                </div>
              </div>
            </div>
            <div className="item hover-zoomin">
              <img
                src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/property-3.jpg"
                alt=""
              />
              <div className="hvrbox-layer_top hvrbox-layer_slideup">
                <div className="hvrbox-text">
                  {" "}
                  <h1> The Carson </h1>
                  <p>
                    Discover state-of-the-art apartments with modern-day
                    amenities at a prime spot in Portland.
                  </p>
                  <span>
                    {" "}
                    <i className="bi bi-geo-alt"></i> Portland, Oregon{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="SAweb_location bg-white pb-4">
            <div
              className="row SAweb_caseStudies  mx-0 d-flex"
              data-aos="fade-up"
            >
              <div className="col-md-1">
                <div className="HeadingRotate">
                  <h2 className="h2_rotate">Locations</h2>
                </div>
              </div>
              <div className="col-md-11">
                <div className="topHeading SAweb_locationTabs mt-5 pt-5">
                  <ul className="nav nav-tabs listView">
                    <li className="nav-item active" role="presentation">
                      <button
                        className="nav-link "
                        id="sanfrance-tab"
                        data-toggle="tab"
                        data-target="#sanfrance"
                        type="button"
                        role="tab"
                        aria-controls="sanfrance"
                        aria-selected="true"
                      >
                        Bay Area
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="losangels-tab"
                        data-toggle="tab"
                        data-target="#losangels"
                        type="button"
                        role="tab"
                        aria-controls="losangels"
                        aria-selected="false"
                      >
                        Los Angeles
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="seattle-tab"
                        data-toggle="tab"
                        data-target="#seattle"
                        type="button"
                        role="tab"
                        aria-controls="seattle"
                        aria-selected="false"
                      >
                        Seattle
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="austin-tab"
                        data-toggle="tab"
                        data-target="#austin"
                        type="button"
                        role="tab"
                        aria-controls="austin"
                        aria-selected="false"
                      >
                        Austin
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="newyork-tab"
                        data-toggle="tab"
                        data-target="#newyork"
                        type="button"
                        role="tab"
                        aria-controls="newyork"
                        aria-selected="false"
                      >
                        New York
                      </button>
                    </li>

                    <li className="nav-item" role="presentation">
                      <a href="/spotlightCities" className="btn viewall-btn">
                        All Cities <i className="bi bi-chevron-right"></i>{" "}
                      </a>{" "}
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div
                      className="tab-pane fade in active"
                      id="sanfrance"
                      role="tabpanel"
                      aria-labelledby="sanfrance-tab"
                    >
                      <div className="locationItem">
                        <div className="image">
                          <img
                            src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/BayArea_Prado.jpeg"
                            alt=""
                          />
                        </div>
                        <div className="caption">
                          <h1>
                            The Benton <span>Santa Clara, California</span>{" "}
                          </h1>
                          <p>
                            Situated in one of the hippest and sought-after
                            neighborhoods of the city, The Benton is at the
                            center of a thriving community. Our amenities are
                            crafted to help you experience California like never
                            before.
                          </p>
                          <a
                            onClick={() => this.openPropertySearch("Bay Area")}
                            className="btn"
                          >
                            {" "}
                            View All Properties
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="losangels"
                      role="tabpanel"
                      aria-labelledby="losangels-tab"
                    >
                      <div className="locationItem">
                        <div className="image">
                          <img
                            src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/LA_cobalt.jpg"
                            alt=""
                          />
                        </div>
                        <div className="caption">
                          <h1>
                            Cobalt <span>Los Angeles, California</span>{" "}
                          </h1>
                          <p>
                            Located in one of the prime US cities, staying at
                            Cobalt means you’re close to Hollywood and
                            surrounded by eminent job clusters – the best of
                            both worlds offered by SuiteAmerica.
                          </p>
                          <a
                            onClick={() =>
                              this.openPropertySearch("Los Angeles")
                            }
                            className="btn"
                          >
                            {" "}
                            View All Properties
                          </a>
                        </div>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade"
                      id="seattle"
                      role="tabpanel"
                      aria-labelledby="seattle-tab"
                    >
                      <div className="locationItem">
                        <div className="image">
                          <img
                            src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/Seattle_Stazion.jpg"
                            alt=""
                          />
                        </div>
                        <div className="caption">
                          <h1>
                            Stazione25<span>Seattle, Washington</span>{" "}
                          </h1>
                          <p>
                            Located on the West Coast of the US, Seattle is home
                            to leading tech hubs, including Microsoft and
                            Amazon. The elite vicinity and vibrant culture at
                            Stazione25 make your stay one of the most desirable.
                          </p>
                          <a
                            onClick={() => this.openPropertySearch("Seattle")}
                            className="btn"
                          >
                            {" "}
                            View All Properties
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="austin"
                      role="tabpanel"
                      aria-labelledby="austin-tab"
                    >
                      <div className="locationItem">
                        <div className="image">
                          <img
                            src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/Austin_Amlion2nd.jpg"
                            alt=""
                          />
                        </div>
                        <div className="caption">
                          <h1>
                            AMLI ON 2ND <span>Austin, Texas</span>{" "}
                          </h1>
                          <p>
                            Thrive in the live music capital amid serene
                            surroundings and the busy work culture at Amli On
                            2ND to complement your lifestyle with fully
                            furnished homes and great amenities.
                          </p>
                          <a
                            onClick={() => this.openPropertySearch("Austin")}
                            className="btn"
                          >
                            {" "}
                            View All Properties
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade  "
                      id="newyork"
                      role="tabpanel"
                      aria-labelledby="newyork-tab"
                    >
                      <div className="locationItem">
                        <div className="image">
                          <img
                            src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/NY_AvalonFortGreen.jpg"
                            alt=""
                          />
                        </div>
                        <div className="caption">
                          <h1>
                            Avalon Fort Greene <span>Brooklyn, New York</span>{" "}
                          </h1>
                          <p>
                            Located a few minutes away from Times Square and
                            Wall Street, settle in this fast-paced city at the
                            Avalon Fort Greene, with stunning views overlooking
                            Manhattan and Brooklyn.{" "}
                          </p>
                          <a
                            onClick={() => this.openPropertySearch("New york")}
                            className="btn"
                          >
                            {" "}
                            View All Properties
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="SAweb_guestSlider bg-white pt-5" data-aos="fade-up">
          <div className="container SAweb_owl-slider2 py-1">
            <h2 className=" pt-3">What our guests have to say</h2>
            <div className="owl-slider SAweb_sliderNav my-5 pb-5">
              <OwlCarousel
                className="owl-theme owl-carousel"
                {...{
                  loop: false,
                  margin: 20,
                  nav: false,
                  dots: true,
                  items: 1,
                  autoplay: false,
                  navContainerClass: "owl-buttons",
                  navText: [
                    "<i class='glyphicon glyphicon-menu-left'></i>",
                    "<i class='glyphicon glyphicon-menu-right'></i>",
                  ],
                  responsive: {
                    767: { items: 2, slideBy: 2, loop: false },
                    992: { items: 2, slideBy: 2, loop: false },
                    1199: { items: 3, slideBy: 3, loop: false },
                  },
                }}
              >
                <div className="item">
                  <div className="caption">
                    <i className="bi bi-quote"></i>
                    <p>
                      Everything was great. The team was so helpful and really
                      made sure my family was as comfortable as possible during
                      our stay. Really really outstanding service!
                    </p>
                    <h3>Ryan, Los Angeles </h3>
                    <i className="bi bi-quote"></i>
                  </div>
                </div>
                <div className="item">
                  <div className="caption">
                    <i className="bi bi-quote"></i>
                    <p>
                      Everything here is amazing - the apartment is beautiful
                      and the personal touches are incredible and very
                      thoughtful. Suite America ALWAYS surprises and delights.
                      Thank you so much!{" "}
                    </p>
                    <h3>Deb, Seattle </h3>
                    <i className="bi bi-quote"></i>
                  </div>
                </div>
                <div className="item">
                  <div className="caption">
                    <i className="bi bi-quote"></i>
                    <p>
                      My move in over the weekend was super smooth. The suite
                      America team has been very thoughtful and have not missed
                      any details in making this a wonderful stay. My family and
                      my kitty are going to be super pleased when they arrive in
                      a few days too. Thank you 😊
                    </p>
                    <h3>Shweta, Hillsboro </h3>
                    <i className="bi bi-quote"></i>
                  </div>
                </div>
                <div className="item">
                  <div className="caption">
                    <i className="bi bi-quote"></i>
                    <p>
                      The move-in process was incredibly smooth. Everyone was
                      very accommodating. Additionally, I requested a few items
                      for our newborn. We received the items requested and more.
                      It was so nice to have everything needed for a hungry,
                      sleepy baby after a long day of travel. Thank you so much.{" "}
                    </p>
                    <h3>Eva, West Hollywood </h3>
                    <i className="bi bi-quote"></i>
                  </div>
                </div>
                <div className="item">
                  <div className="caption">
                    <i className="bi bi-quote"></i>
                    <p>
                      smooth and seamless transition. Thank you for having
                      everything set up, food in the fridge, significantly
                      better welcome than in a 5 star hotel. Thanks! your team
                      has done amazing job!
                    </p>
                    <h3>Laura , Portland </h3>
                    <i className="bi bi-quote"></i>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}
export default NewHomepage;
