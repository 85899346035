import React from "react";

import SearchForm from "../components/SearchForm";
import CardComponent from "../components/CardComponent";
import Loader from "../../../../common/loader";

const SearchAndListView = ({
  state,
  fetchCommunities,
  changeCommunityImagesModalData,
  changeCommunityName,
  propertyMoreDetailsData,
  updatePropertyMoreDetailsData,
  changeToMoreDetailsAndBookView,
}) => {
  return (
    <>
      {/* bread crumbs */}
      <div className="row">
        <div className="col-sm-12">
          <div className="page-title-box">
            <div className="btn-group float-right">
              <ol className="breadcrumb hide-phone p-0 m-0">
                <li className="breadcrumb-item">
                  <a href="#">SuiteAmerica</a>
                </li>
                <li className="breadcrumb-item active">Book Now</li>
              </ol>
            </div>
            <h4 className="page-title">Book Now</h4>
          </div>
        </div>
      </div>

      {/* search form */}
      <SearchForm
        fetchCommunities={fetchCommunities}
        errorMessage={state.errorMessage}
      />
      {/* comunities List */}
      <div className="row">
        {state.isLoading ? (
          <Loader />
        ) : (
          state.communities.map((row, index) => (
            <CardComponent
              row={row}
              index={index}
              key={index}
              changeCommunityImagesModalData={changeCommunityImagesModalData}
              changeCommunityName={changeCommunityName}
              propertyMoreDetailsData={propertyMoreDetailsData}
              updatePropertyMoreDetailsData={updatePropertyMoreDetailsData}
              changeToMoreDetailsAndBookView={changeToMoreDetailsAndBookView}
            />
          ))
        )}
      </div>
    </>
  );
};

export default SearchAndListView;
