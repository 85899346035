/* Importing the node modules, child components, services and controllers used 
   inside WorldBankContactUS component */ 
   import AppController from "../../controller/appController";
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   import WorldBankHeader from "../../common/worldBankHeader";
   import SuccessModal from '../../common/successModal';
   import ReCAPTCHA from "react-google-recaptcha";
   import NikeServices from '../../../services/nikeService';
   import ContactList from './contactList';   
   /* WorldBankContactUS Component initialization */
   class WorldBankContactUS extends React.Component {
       /* Initializing objects of its WorldBankContactUS class */
       constructor(props) {
           super(props)
           this.state = {
            firstName: '',
            lastName: '',
            email: '',
            subject: '',
            message: '',
            color:'',
            errorMessage:'',
            CaptchaValue:'',
           }
           this.serviceRequest = this.serviceRequest.bind(this);
           this.setValue = this.setValue.bind(this);    
           this.onChangeCaptcha = this.onChangeCaptcha.bind(this);
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
           MainController.getInitialInformation();
        }
        setValue(field, e) {
            var object = {};
            object[field] = e.target.value;
            this.setState(object);
        }
       /* Service Request Details send to mail  */
       async serviceRequest(e){  
        e.preventDefault();
        const _this = this
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
           const validationFields=['firstName','lastName','email', 'CaptchaValue']
           let error = 0;
           _.forEach(validationFields, function(value) {
              if(_this.state[value] == ""){
                error++;
              } 
          });
          if(!reg.test(_this.state.email)){
            error++;
          }
         if(error == 0){
            const Request={
                firstName:this.state.firstName,
                lastName:this.state.lastName,
                email:this.state.email,
                subject: this.state.subject == '' ? '---' : this.state.subject,
                message: this.state.message == '' ? '---' : this.state.message,
                mail:'WorldBankContactUs'
            }
    
        this.setState({
            firstName: '',
            lastName: '',
            email: '',
            subject: '',
            message: '',
            color:'',
            errorMessage:'',
            CaptchaValue: ''  
 
        })
        
        jQuery(function($) {
            $('#successModal').modal('show');
            setTimeout(function(){
              $('#successModal').modal('hide');
            },4000);
          });
        AppController.menuTopNavigation('serviceRequest');
        await NikeServices.serviceRequest(Request)
    }
    else{
        AppController.menuTopNavigation('serviceRequest');
        this.setState({errorMessage:'Please Enter Required Fields & Select Captcha',color:'red'})
    }
        
       }
       async onChangeCaptcha(CaptchaValue) {
        await this.setState({CaptchaValue})
      }
       /* It is invoked to return html content */
       render() {
           return (
            <div className="corporateHousing_Updates" id="serviceRequest">
                <GuestHeader />
                <div className="cover-tophd-ht"></div>
                <WorldBankHeader />
                <div className="container-fluid bg-grey">
                <div className="container">
                    <div className="savesearch form-section col-sm-8 col-sm-offset-2 text-center">
                        <h2>Contact Us</h2>
                        <p className="text-left" style={{color:this.state.color}}>{this.state.errorMessage}</p>
                        <form role="form" method="post" onSubmit={(e) => this.serviceRequest(e)} className="form-horizontal">
                            <div className="form-group row">
                            <input type="text" className="form-control col-md-5" placeholder="*First Name" value={this.state.firstName} 
                            onChange={(e) => this.setValue('firstName', e)}/>
                            </div>
                            <div className="form-group row">
                            <input type="text" className="form-control col-md-5" placeholder="*Last Name" value={this.state.lastName} 
                            onChange={(e) => this.setValue('lastName', e)}/>
                            </div>
                            <div className="form-group row">
                            <input type="text" className="form-control col-md-5" placeholder="*Email" value={this.state.email} 
                            onChange={(e) => this.setValue('email', e)} />
                            </div>
                            <div className="form-group row">
                            <input type="text" className="form-control col-md-5" placeholder="Subject" value={this.state.subject} 
                            onChange={(e) => this.setValue('subject', e)}/>
                            </div>
                            <div className="form-group row">
                            <textarea className="form-control col-md-5" placeholder="Message" value={this.state.message} onChange={(e) => this.setValue('message', e)}/>
                            </div>
                            <div className="form-group row">
                                <div className="col-sm-12 col-md-12 over-hide">
                                    <ReCAPTCHA
                                        ref="recaptcha"
                                        sitekey="6Lcbw1AUAAAAAE5K5cYDyhoGu0RJY9C3CLtGFXAo"
                                        onChange={this.onChangeCaptcha}
                                        />
                                </div>
                            </div>
                            <div className="form-group row">
                                <button className="btn company_btn" type="submit" >SEND</button>
                            </div>
                        </form>
                    </div>
                    </div>
                </div>
                <ContactList />
                <SubFooter/>
                <Footer/>
                <SuccessModal/>
            </div>   
           );
       }
   }
   export default WorldBankContactUS;