import React, { Component } from 'react'
import GuestServicesapi from '../../../services/guestServicesapi';
import moment from 'moment';
import TripInfoData from './tripInfoData';

export class ActiveOrderInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeOrderInfo: [],
      userData: '',
      orderInfo: {},
      ScheduleInfo: {},
      tab: "",
      loader: false,
      showIfActiveInfoEmpty: false,
      showTripInfo: false,
      orderUid: "",
      ifLockBoxShowGuestPane: false
    }
  }


  async componentDidMount() {
    this.setState({ loader: true })
    if (Object.keys(this.state.orderInfo).length == 0) {
      let ordersid = {
        orderid: this.props.getorderid,
    }
    GuestServicesapi.GetOrderInformation(ordersid).then((responses) => {
      console.log(responses,'response')
        this.setState({ userData: responses })
        var guestEmailObj = {
          guestEmail: responses.GuestEmail,
          status: "active"
        }
        GuestServicesapi.GetOrdersByGuestEmail(guestEmailObj).then((response) => {
          if (response.error !== 1 && response.message.Orders.length > 0) {
            this.setState({ activeOrderInfo: response.message.Orders, loader: false });
            var orderUIdObj = {
              OrderUId: this.state.activeOrderInfo[0].OrderUId
            }
            GuestServicesapi.GetTripInfo({ orderUid: this.state.activeOrderInfo[0].OrderUId }).then((tripInfoData) => {
              if (tripInfoData == null) {
                this.setState({ showTripInfo: true, loader: false })
                GuestServicesapi.GetOrderInfoById(orderUIdObj).then((getOrderInfo) => {
                  if (getOrderInfo.Message !== "The request is invalid.") {
                    if (getOrderInfo.message.orderInfo.Entry == "Lockbox") {
                      this.setState({ ifLockBoxShowGuestPane: true, loader: false })
                    }
                  }
                })
              } else {
                GuestServicesapi.GetOrderInfoById(orderUIdObj).then((getOrderInfo) => {
                  if (getOrderInfo.Message !== "The request is invalid.") {
                    this.setState({ orderInfo: getOrderInfo.message.orderInfo, ScheduleInfo: getOrderInfo.message.ScheduleInfo, loader: false })
                  } else {
                    this.props.history.push({
                      pathname: "/reservations",
                      state: orderUIdObj.OrderUId,
                      isShowGuestPane: false
                    })
                  }
                })
              }
            })
          } else {
            this.setState({ loader: false, showIfActiveInfoEmpty: true })
          }
        })


      })
    }
  }

  render() {
    const nth = function (d) {
      if (d > 3 && d < 21) return 'th';
      switch (d % 10) {
        case 1: return "st";
        case 2: return "nd";
        case 3: return "rd";
        default: return "th";
      }
    }

    var moveOutDay = "",
      moveInDay = "",
      moveInDateYear = "",
      moveInDateMonth = "",
      moveInDate = "",
      moveOutDate = "",
      moveOutDateYear = "",
      moveOutDateMonth = "",
      communityName = "",
      communityCityName = "",
      communityStateName = "",
      communityZipCode = "",
      communityAddress = "",
      noticeToVacateDay = "",
      noticeToVacateYear = "",
      noticeToVacateMonth = "",
      noticeToVacateDate = "",
      suite = "",
      floor = "",
      bed = "",
      parkingSpace = "",
      gateCode = "",
      mailBox = "";
    if (this.state.orderInfo !== null) {
      const {
        MoveInDate,
        MoveOutDate,
        Community,
        CommunityCity,
        CommunityAddress,
        CommunityState,
        MasterSuite,
        MasterFloor,
        MasterNoOfBed,
        MasterParkingSpace,
        MasterGateCode,
        MasterMailBox,
        CommunityZipCode,
        NoticetoVacate
      } = this.state.orderInfo;
      moveInDay = moment(MoveInDate).format('dddd')
      moveInDateYear = moment(MoveInDate).format('YYYY')
      moveInDateMonth = moment(MoveInDate).format('MMMM')
      moveInDate = moment(MoveInDate).format('DD')
      moveOutDay = moment(MoveOutDate).format('dddd')
      moveOutDate = moment(MoveOutDate).format('DD')
      moveOutDateMonth = moment(MoveOutDate).format('MMMM')
      moveOutDateYear = moment(MoveOutDate).format('YYYY')
      communityName = Community
      communityCityName = CommunityCity
      communityStateName = CommunityState
      communityZipCode = CommunityZipCode
      communityAddress = CommunityAddress
      suite = MasterSuite
      floor = MasterFloor
      bed = MasterNoOfBed
      parkingSpace = MasterParkingSpace
      gateCode = MasterGateCode
      mailBox = MasterMailBox
      noticeToVacateDay = moment(NoticetoVacate).format('dddd')
      noticeToVacateYear = moment(NoticetoVacate).format('YYYY')
      noticeToVacateMonth = moment(NoticetoVacate).format('MMMM')
      noticeToVacateDate = moment(NoticetoVacate).format('DD')
    }
    return (
      <div>
     {/*   <div>
           {this.state.orderInfo !== null && this.state.showIfActiveInfoEmpty == false ?
            <Guest />
            : null} 
        </div>*/}
        {this.state.showTripInfo == false ?
          this.state.activeOrderInfo.length > 0 ?
            <div>
              <h2>Thank you for choosing to stay with us. <br />We look forward to having you in our suite. Here are your
                reservation details:</h2>
              <div className="text-right">
                <button type="button" className="btn"> <img src="/assets/images/guestPortalArrival/share-icon.svg" alt="" /> Text/Email </button>
                <button type="button" className="btn"> <img src="/assets/images/guestPortalArrival/download-icon.svg" alt="" /> Download </button>
              </div>
              <div className="boxes">
                <div className="item">
                  <img src="/assets/images/guestPortalArrival/check-box-with-check-sign.svg" alt="" />
                  <h1>CHECK IN</h1>
                  <p>{moveInDay}</p>
                  <p> {moveInDateMonth} {moveInDate}<sup>{nth(moveInDate)}</sup>, {moveInDateYear} (11 am)</p>
                  <span>Standard check in time is between 3p-10p</span>
                </div>
                <div className="item">
                  <img src="/assets/images/guestPortalArrival/portfolio.svg" alt="" />
                  <h1>CHECK OUT</h1>
                  <p>{moveOutDay}</p>
                  <p> {moveOutDateMonth} {moveOutDate}<sup>{nth(moveOutDate)}</sup>, {moveOutDateYear} (3 pm)</p>
                </div>
                <div className="item">
                  <img src="/assets/images/guestPortalArrival/urban-buildings.svg" alt="" />
                  <h1>COMMUNITY</h1>
                  <p>{communityName}</p>
                  <p> {communityAddress}</p>
                  <p>{communityCityName}, {communityStateName} {communityZipCode}</p>
                  <a className="popupview-btns" data-toggle="modal" data-target="#take360Tour">TAKE 360 TOUR</a>
                </div>
                <div className="item">
                  <img src="/assets/images/guestPortalArrival/home-icon.svg" alt="" />
                  <h1>APARTMENT #</h1>
                  <p> #{suite}, Floor {floor}</p>
                  <p> {bed} Bed, 1 Bath</p>
                  <a className="popupview-btns" data-toggle="modal" data-target="#viewSiteMap">VIEW SITEMAP</a>
                </div>
                <div className="item">
                  <img src="/assets/images/guestPortalArrival/hourglass.svg" alt="" />
                  <h1>NOTICE TO VACATE</h1>
                  <p>{noticeToVacateDay}</p>
                  <p>{noticeToVacateMonth} {noticeToVacateDate}<sup>{nth(noticeToVacateDate)}</sup>, {noticeToVacateYear}</p>
                </div>
                <div className="item">
                  <img src="/assets/images/guestPortalArrival/spring-calendar-rounded-symbol.svg" alt="" />
                  <h1>DEPARTURE STATUS</h1>
                  <p> Click here for more info</p>
                </div>
              </div>
              {this.state.orderInfo.Entry == 'Lockbox' ?
                <div className="arrival">
                  <div className="">
                    <div className="text-center">
                      <h1>ARRIVAL - <span>LOCKBOX</span></h1>
                    </div>
                    <div className="arrival-text map-pin">
                      <div className="item">
                        <img src="/assets/images/guestPortalArrival/pin.svg" alt="" />
                        <button type="button" className="btn" data-toggle="modal" data-target="#myModalLocateLockbox">LOCATE MY
                    LOCKBOX</button>
                      </div>
                    </div>
                    {/* Modal */}
                    <div className="modal fade" id="myModalLocateLockbox" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h1>Sharon Green <span>Lockbox Instructions</span> </h1>
                            <div className="close-view">
                              <button type="button" className="btn"> <img src="/assets/images/guestPortalArrival/download-icon.svg" alt="" /> Download </button>
                              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                              </button>
                            </div>
                          </div>
                          <div className="modal-body">
                            <div className="main-view">
                              {Object.keys(this.state.ScheduleInfo).length > 0 ?
                                this.state.ScheduleInfo.Images.length > 0 ?
                                  this.state.ScheduleInfo.Images.map((img, i) => {
                                    return (
                                      <div className="item" key={i}>
                                        <img src={img} alt="" />
                                      </div>
                                    )
                                  })
                                  : <p>Your images will appear in 24 hoours</p>
                                : <p>Your images will appear in 24 hoours</p>}
                              <div className="item">
                                <div className="text">
                                  <h1> <img src="/assets/images/guestPortalArrival/map-pin-border.svg" alt="" /> LOCKBOX LOCATION</h1>
                                  <h2>How to use the Lockbox</h2>
                                  <ul>
                                    <li>1. From Monte Rosa Dr, enter by the Leasing Center sign.</li>
                                    <li>2. Enter the lower level parking garage and look for the mailboxes on your right.</li>
                                    <li>3. There is a railing installed to the left of the mailboxes with the lockboxes attached.
                              </li>
                                    <li>4. Look for the SUITEAMERICA labeled lockbox and enter your code to retrieve the keys.</li>
                                  </ul>
                                </div>
                              </div>
                              <div className="item">
                                <img src="/assets/images/guestPortalArrival/take360tour.png" alt="" />
                              </div>
                              <div className="item">
                                <img src="/assets/images/guestPortalArrival/take360tour.png" alt="" />
                              </div>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <img src="/assets/images/guestPortalArrival/sa-logo.png" alt="" />
                            <span>4970 Windplay Drive, EI Dorado Hills, CA 95762 / 800.367.9501 / suiteamerica.com</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="text-center"><i>*your lockbox location photos will be available 24 hours before your arrival</i></p>
                  </div>
                </div>
                : this.state.orderInfo.Entry == 'Meet & Greet' ?
                  <div className="">
                    <div className="arrival">
                      <div className="container">
                        <div className="text-center">
                          <h1>ARRIVAL - <span>MEET & GREET</span></h1>
                        </div>

                        <div className="arrival-text">
                          <div className="item">
                            <div className="images">
                            </div>

                          </div>
                          <div className="item">
                            <h2>{this.state.ScheduleInfo.Identifier} </h2>
                            <p>PTS Supervisor</p>
                            <p>Emma@suiteamerica.com</p>
                            <p> 123.456.7890 </p>
                          </div>
                        </div>
                        <p className="text-center"><i>If there are any changes to your arrival schedule, please contact {this.state.ScheduleInfo.Identifier}.</i></p>
                      </div>
                    </div>
                  </div>
                  :
                  <div className="" style={{ margin: "15px 0px", paddingBottom: "20px", borderRadius: "12px" }}>

                  </div>}
              <div className="">
                <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                  <div className="panel panel-default">
                    <div className="panel-heading" role="tab" id="headingOne">
                      <h4 className="panel-title">
                        <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                          <span>
                            <img className="car-parking-img" src="/assets/images/guestPortalArrival/car-parking-icon.svg" alt="" /></span>PARKING SPOT #{parkingSpace}
                        </a>
                      </h4>
                    </div>
                    <div id="collapseOne" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                      <div className="panel-body">
                        Please be sure to follow all parking rules, which may include, but are not limited to: parking in your
                        assigned parking spot (if applicable), utilizing only one parking spot per vehicle, posting parking
                        stickers in your vehicle (if applicable), not blocking garages or driveways, not parking in fire zones,
                        and not parking illegally in handicapped spots. Failure to follow all parking rules may result in your
                        vehicle being ticketed or towed. Please also keep in mind that other violations of state laws (such as
                        expired registration) may result in similar actions. SuiteAmerica is not responsible for any fees
                        associated with parking tickets or towing. Please do not leave any valuables in your vehicle if you have
                        any questions, please be sure to contact your SuiteAmerica representative.
                  </div>
                    </div>
                  </div>
                  <div className="panel panel-default">
                    <div className="panel-heading" role="tab" id="headingTwo">
                      <h4 className="panel-title">
                        <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          <span><img className="gate-icon-img" src="/assets/images/guestPortalArrival/gate-icon.svg" alt="" /> </span>GATE CODE FOB #{gateCode}
                        </a>
                      </h4>
                    </div>
                    <div id="collapseTwo" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                      <div className="panel-body">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Officia neque voluptatum,
                      magnam veniam non natus veritatis dolores accusantium sapiente culpa perspiciatis tenetur perferendis,
                    enim molestias. Voluptates aperiam non ad nemo. </div>
                    </div>
                  </div>
                  <div className="panel panel-default">
                    <div className="panel-heading" role="tab" id="headingThree">
                      <h4 className="panel-title">
                        <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                          <span><img className="phone-icon-img" src="/assets/images/guestPortalArrival/phone-icon.svg" alt="" /></span> PHONE SERVICE
                    </a>
                      </h4>
                    </div>
                    <div id="collapseThree" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                      <div className="panel-body">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus, numquam amet!
                      Natus tempora id repellat mollitia quaerat molestiae exercitationem impedit nemo eum aliquam architecto
                    totam, modi unde eaque numquam veritatis. </div>
                    </div>
                  </div>
                  <div className="panel panel-default">
                    <div className="panel-heading" role="tab" id="headingFour">
                      <h4 className="panel-title">
                        <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                          <span><img className="text-icon-img" src="/assets/images/guestPortalArrival/text-icon.svg" alt="" /></span> RENTER’S INSURANCE
                    </a>
                      </h4>
                    </div>
                    <div id="collapseFour" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFour">
                      <div className="panel-body"> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore minima
                      perspiciatis maxime fugit consequatur sapiente a accusantium minus cupiditate omnis eligendi, quia natus
                    ratione, dolor dignissimos porro soluta. Fuga, facere. </div>
                    </div>
                  </div>
                  <div className="panel panel-default">
                    <div className="panel-heading" role="tab" id="headingFive">
                      <h4 className="panel-title">
                        <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                          <span><img className="mail-icon-img" src="/assets/images/guestPortalArrival/mail-box.svg" alt="" /></span> MAILBOX #{mailBox}
                        </a>
                      </h4>
                    </div>
                    <div id="collapseFive" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFive">
                      <div className="panel-body">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore minima
                      perspiciatis maxime fugit consequatur sapiente a accusantium minus cupiditate omnis eligendi, quia natus
                    ratione, dolor dignissimos porro soluta. Fuga, facere. </div>
                    </div>
                  </div>
                  <div className="panel panel-default">
                    <div className="panel-heading" role="tab" id="headingSix">
                      <h4 className="panel-title">
                        <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                          <span><img className="sofa-img" src="/assets/images/guestPortalArrival/sofa.svg" alt="" /></span> FURNITURE PACKAGE
                    </a>
                      </h4>
                    </div>
                    <div id="collapseSix" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSix">
                      <div className="panel-body"> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore minima
                      perspiciatis maxime fugit consequatur sapiente a accusantium minus cupiditate omnis eligendi, quia natus
                    ratione, dolor dignissimos porro soluta. Fuga, facere. </div>
                    </div>
                  </div>
                  <div className="panel panel-default">
                    <div className="panel-heading" role="tab" id="headingSeven">
                      <h4 className="panel-title">
                        <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                          <span><img className="sofa-img" src="/assets/images/guestPortalArrival/clothes-hanger.svg" alt="" /></span> HOUSEWARES
                    </a>
                      </h4>
                    </div>
                    <div id="collapseSeven" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSeven">
                      <div className="panel-body"> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore minima
                      perspiciatis maxime fugit consequatur sapiente a accusantium minus cupiditate omnis eligendi, quia natus
                    ratione, dolor dignissimos porro soluta. Fuga, facere. </div>
                    </div>
                  </div>
                </div>
                <div className="view-buttons">
                  {/* <button type="button" className="btn" data-toggle="modal" data-target="#take360Tour">TAKE 360 TOUR</button> */}
                  {/* <button type="button" className="btn" data-toggle="modal" data-target="#viewSiteMap">VIEW SITEMAP</button> */}
                </div>
              </div>
              <div className="sa-team">
                <div className="">
                  <h1>SUITEAMERICA TEAM</h1>
                  <div className="members">
                    <div className="item">
                      <div className="images">
                        <img src="/assets/images/guestPortalArrival/Pam-Herman.png" alt="" />
                      </div>
                      <h2>PAM JONES</h2>
                      <h3> Relocation Coordinator</h3>
                      <h3> Pam@suiteamerica.com </h3>
                      <h4>800.367.9501 x 182</h4>
                      <p>• Arranged your accommodations</p>
                    </div>
                    <div className="item">
                      <div className="images">
                        <img src="/assets/images/guestPortalArrival/Emma-Lynn-Anderson.png" alt="" />
                      </div>
                      <h2>EMMA SMITH</h2>
                      <h3> PTS Supervisor</h3>
                      <h3> Emma@suiteamerica.com </h3>
                      <h4> 456.902.2846</h4>
                      <p>• Meets you once for a community tour</p>
                    </div>
                    <div className="item">
                      <div className="images">
                        <img src="/assets/images/guestPortalArrival/Corey-Taylor-Bockmann.png" alt="" />
                      </div>
                      <h2>COREY MATTHEWS</h2>
                      <h3> Guest Experience</h3>
                      <h3> Corey@suiteamerica.com </h3>
                      <h4> 800.367.9501 x 129 </h4>
                      <p>• Your personal assistant throughout your stay</p>
                    </div>
                  </div>
                </div>
              </div>
              <h6>If you have any further questions, please feel free to contact your SuiteAmerica Team.</h6>
              {/* Modal */}
              <div className="modal fade" id="take360Tour" role="dialog">
                <div className="modal-dialog">
                  {/* Modal content*/}
                  <div className="modal-content">
                    <div className="modal-header">
                      <button type="button" className="close" data-dismiss="modal">×</button>
                    </div>
                    <div className="modal-body">
                      <img src="/assets/images/guestPortalArrival/take360tour.png" width="100%" alt="" />
                    </div>
                    <div className="modal-footer">
                      <h1>360 TOUR</h1>
                    </div>
                  </div>
                </div>
              </div>
              {/* Modal */}
              <div className="modal fade" id="viewSiteMap" role="dialog">
                <div className="modal-dialog">
                  {/* Modal content*/}
                  <div className="modal-content">
                    <div className="modal-header">
                      <button type="button" className="close" data-dismiss="modal">×</button>
                    </div>
                    <div className="modal-body">
                      <div className="backend-values">
                        <div className="red">
                          <i className="fa fa-map-marker" />
                          <ul>
                            <li>SUITE <span>3-309</span></li>
                            <li>FLOOR <span>3</span></li>
                            <li>LEASING OFFICE <span /></li>
                          </ul>
                        </div>
                        <div className="gray">
                          <i className="fa fa-map-marker" />
                        </div>
                      </div>
                      <img src="/assets/images/guestPortalArrival/mapview.png" alt="" />
                    </div>
                    <div className="modal-footer">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            : this.state.loader ?
              <div className="panel-body_loader" style={{ marginTop: '50px', marginBottom: '50px' }}>
                <span style={{ fontSize: '20px', width: '100%', margin: 'auto', textAlign: 'center' }}><i className="fa fa-spinner fa-spin" />
                  <span style={{ marginLeft: '10px' }}> please wait ...!</span> </span>
              </div> : this.state.showIfActiveInfoEmpty ?
                <p style={{ color: "red", marginTop: "35px", textAlign: "center" }}>You don't have any active reservations.</p>
                : null
          : <div className="removeMargin">
              <TripInfoData
              getorderid={this.props.getorderid}
            activeTabTripInfoIsNull={true}
            ifLockBoxShowGuestPane={this.state.ifLockBoxShowGuestPane}
          />
          </div>
        }
      </div>
    )
  }
}

export default ActiveOrderInfo
