import React from "react";
import Footer from "./footer";
import TransHeader from "./transheader";
import SpotLightCities from "./json/spotlightCities.json";
import Cities from "./json/cities.json";
class SpotlightCities extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyname: "suiteamerica",
      changeStaus: false,
      headerCities: SpotLightCities,
      cities: Cities,
    };
  }

  async componentDidMount() {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
  }
  onClickCity(data) {
    // let urlname = data.webUrl.replace(
    //   "/corporate-housing/furnished-apartments/",
    //   ""
    // );
    // let url = "/city/" + urlname;
    window.open(data.webUrl, "_blank");
  }
  render() {
    return (
      <div className="SAweb_updateUi">
        <TransHeader />
        <section className="SAweb_spotlightCitiesBanner">
          <div className="container">
            <div className="textView">
              <h1 data-aos="fade-up"> Spotlight Cities </h1>
              <p className="p" data-aos="fade-up">
                Experience our premier corporate and serviced apartments in
                premium communities across the United States. See how 30+ years
                of excellence creates a temporary housing experience like no
                other.
              </p>
            </div>
          </div>
        </section>

        <section className="SAweb_spotlightCitiesGridView bg-white ">
          <div className="container ">
            {Object.keys(this.state.cities).map((row, index) => {
              return (
                <div className="SAweb_nationlFootprint " key={index}>
                  <h2>{this.state.cities[row][0].statename}</h2>
                  <ul data-aos="fade-up">
                    {this.state.cities[row].map((row, i) => {
                      return (
                        <li
                          key={row.city_id}
                          style={{ cursor: "pointer" }}
                          onClick={() => this.onClickCity(row)}
                        >
                          <a href="javascript:">
                            <div className="pic">
                              <img src={row.image} alt="" />
                            </div>
                            <span>{row.city}</span>{" "}
                          </a>{" "}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
export default SpotlightCities;
