import MainController from "../../controller/mainController";
import SubFooter from "../../common/subFooter";
import {Link} from 'react-router-dom';
import GuestHeader from '../../common/guestHeader';
import Footer from '../../common/footer';
import MediaFooter from "./common/mediaFooter";
import MetaTags from '../../common/metaTags';
import ServerMetaTags from '../../../server/metaTags';

/* MediaWhoWeAre Component initialization */
class MediaWhoWeAre extends React.Component {
    /* Initializing objects of its MediaContact class */
    constructor(props) {
        super(props);
    }
    /* It is invoked immediately before mounting occurs */
    componentWillMount() {
        MainController.getInitialInformation();
    }
    /* It is invoked to return html content */
    render() {
        const metaData = ServerMetaTags.urlString.getMetaData(this.props.match.url);
        return (
            <div>
            <MetaTags 
             metaTitle = {metaData.title}
             metaDesription = {metaData.description}
             metaUrl = {metaData.canonical} />
             <GuestHeader/>
            <div className="cover-tophd-ht company_ht_cover"></div>
             <div className="quote-section bg-white">
                <div className="container mediakit">
                   <div className="plaine-text global-coverage-wrap col-md-10 col-md-offset-1 wow fadeInUp" data-wow-delay="0.5s">
                   <h2 className="mrg-btm-50 color-red">WHO WE ARE</h2>
                        <p>SuiteAmerica is one of the fastest growing corporate housing companies in the United 
    States thanks to Robin and James Masten, who co-founded the company in 1990. Creating a unique culture 
    and positive atmosphere from the start, our SuiteAmerica employees excel in creating a corporate housing 
    experience that is second to none. Whether you are searching for a furnished apartment for a temporary 
    assignment, or for a relocation, our goal is to always exceed your expectations. </p>
    <p>SuiteAmerica has an unbeatable track record of outstanding customer service that has garnered our 
    corporate housing company numerous awards, including the Corporate Housing Providers Association 
    Company of the Year, and the 2016 Cartus Silver Supplier Innovation Award for Same Day Turns. </p>
    <p>Providing quick response times and meeting the specific needs of our clients are what helped us realize our 
    goal. In addition, we are continually refining and upgrading our systems and procedures to meet the 
    demands of today’s rapidly changing environment. These practices have allowed us to more efficiently 
    service our customers. </p>
    <p>SuiteAmerica is committed to staying abreast of industry trends and developments. To accomplish this, we 
    are active members in many organizations throughout the country, including ERC (Employee Relocation 
    Council), BAMM (Bay Area Mobility Management), SHRM (Society of Human Resource Managers), and CHPA 
    (Corporate Housing Providers Association). </p>
    <p>To learn more about our company, visit <Link to="/company-info" className="color-red">About Us</Link></p>
                   </div>
                 <MediaFooter />
                 </div>
              </div>   
             <SubFooter />
             <Footer/>
            </div>
        );
    }
}
export default MediaWhoWeAre;