/* Importing the node modules, child components, services and controllers used 
   inside DriversSchedule component */
import $ from "jquery";
import { Link } from "react-router-dom";
import AppController from "../../../controller/appController";
import FiveStarApiServices from "../../../../services/fiveStarApiServices";
import FiveStarService from "../../../../services/fiveStarService";
import SessionService from "../../../../services/sessionService";
import DatePicker from "react-datepicker";
import moment from "moment";
import DiverScheduleMap from "./diverScheduleMap";
import DriverManagerSchedule from "./driverManagerSchedule";
import FivestarHeader from "../../../common/fivestarHeader";
import { confirmAlert } from "react-confirm-alert";
import EncryptDecrypt from "../../../../services/encryptDecrypt";
import FirstRowFields from "./scheduleComponents/firstRowFields";
import SecondRowFields from "./scheduleComponents/secondRowFields";
import ThirdRowFields from "./scheduleComponents/thirdRowFields";
import BedsList from "./scheduleComponents/bedsList";
import Buttons from "./scheduleComponents/buttons";
import HoldModal from "./scheduleComponents/holdModal";
import SuiteReadyButton from "./scheduleComponents/suiteReadyButton";
import ScheduleMapViewHeading from "./scheduleComponents/scheduleMapViewHeading";
import S3bucketImages from "./s3bucketImages";
import RescheduleModal from "./rescheduleModal";
import FunStuffModal from "./funStuffModal";
import CancelSuiteReadyModal from "./suiteNotReadyModal";
import SuccessModal from "../../../common/successModal";
import OrderInfo from "./orderInfo";
import QuickRequest from "./scheduleComponents/quickModal";
import S3BucketUpload from "./s3bucketUpload";

/* DriversSchedule Component initialization */
class DriversSchedule extends React.Component {
  /* Initializing objects of its DriversSchedule class */
  constructor(props) {
    super(props);
    const startDate =
      localStorage.getItem("startDate") == null
        ? localStorage.setItem("startDate", moment().format("YYYY-MM-DD"))
        : localStorage.getItem("startDate");
    /* DriversSchedule Component State variables Initialization */
    this.state = {
      ordersData: [],
      activeIndex: 1,
      startDate: moment(startDate),
      selectedDate: startDate,
      loadingStatus: true,
      loadTab: true,
      loadMoreIndex: -1,
      Identifier: "",
      holdText: "",
      holdData: {},
      message: "",
      color: "",
      orderStatusLoading: false,
      modalLoader: false,
      loaderaptReady: -1,
      driverManagerInfo: {},
      isStarted: -1,
      s3bucketImages: [],
      error: 0,
      reScheduleText: "",
      suiteNotReadyText: "",
      reSchedule: {},
      reScheduleIndex: -1,
      funstuff: {},
      filterValue: "",
      orderInformation: {},
      orderFormValue: false,
      quickText: "",
      quicksuccess: "",
      quickobj: {},
      requestDetails: []
    };
    this.quickRequest = this.quickRequest.bind(this);
    this.quickSubmit = this.quickSubmit.bind(this);
    this.holdService = this.holdService.bind(this);
    this.changeIndex = this.changeIndex.bind(this);
    this.conformationAlert = this.conformationAlert.bind(this);
    this.holdSubmit = this.holdSubmit.bind(this);
    this.setValue = this.setValue.bind(this);
    this.statusChange = this.statusChange.bind(this);
    this.openServiceRerquestForm = this.openServiceRerquestForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.imagesGet = this.imagesGet.bind(this);
    this.clearFormData = this.clearFormData.bind(this);
    this.reSchedule = this.reSchedule.bind(this);
    this.suiteNotReady = this.suiteNotReady.bind(this);
    this.funstuff = this.funstuff.bind(this);
    this.mastersSorting = this.mastersSorting.bind(this);
    this.showOrderForm = this.showOrderForm.bind(this);
    this.getOrderInfo = this.getOrderInfo.bind(this);
  }
  /* To get the orders list */
  async ordersData() {
    const driverInformation = await SessionService.SessionGet({
      type: "fivestar",
    });
    this.setState({ driverManagerInfo: driverInformation });
    const requestObj = {
      uniqueid: this.state.driverManagerInfo.UniqueId,
      isManager: this.state.driverManagerInfo.IsManager == 1 ? true : false,
      toDate: localStorage.getItem("startDate"),
      IsVendorManager: this.state.driverManagerInfo.IsVendorManager,
      Company: this.state.driverManagerInfo.Company,
    };
    const resultData =
      await FiveStarApiServices.GetServicesSchedulesByDriverIdManager(
        requestObj
      );
    await this.orderByData(resultData, false);
  }
  async orderByData(resultData, loadingStatus) {
    let isStarted;
    const ordersData = _.orderBy(
      resultData,
      [
        (o) =>
          o.AptReady == false &&
          (o.TypeCode == "T/O" || o.TypeCode == "W/T") &&
          o.ServiceStatus == "completed" &&
          o.StatusHold != "hold",
        (o) => o.StartTime != null && o.EndTime == null && o.StatusHold == null,
        (o) => o.StatusHold == "hold",
        (o) => o.StartTime == null && o.EndTime == null && o.StatusHold == null,
      ],
      ["desc", "desc", "desc", "desc", "desc"]
    );
    isStarted = _.find(ordersData, function (o) {
      return (
        (o.AptReady == false &&
          (o.TypeCode == "T/O" || o.TypeCode == "W/T") &&
          o.ServiceStatus == "completed" &&
          o.StatusHold != "hold") ||
        (o.StartTime != null && o.EndTime == null && o.StatusHold != "hold")
      );
    });
    await this.setState({
      ordersData,
      loadingStatus,
      isStarted: isStarted == undefined ? -1 : isStarted.UniqueId,
    });
    $("html, body").animate({ scrollTop: 0 });
  }
  /* It is invoked immediately after a component is mounted */
  async componentDidMount() {
    $("html, body").animate({ scrollTop: 0 });
    AppController.stickyTitles();
    await this.ordersData();
  }
  async getOrderInfo(id) {
    const requestObj = {
      orderid: id,
    };

    const response = await FiveStarApiServices.GetAppCode(requestObj);

    return response;
  }
  /* To active the Schedule view / Map view */
  async changeIndex(index) {
    await this.setState({ activeIndex: index, loadTab: !this.state.loadTab });
  }
  /* To get the selected date from date picker */
  async handleChange(date) {
    await this.setState({
      startDate: moment(date),
      selectedDate: localStorage.setItem(
        "startDate",
        date.format("YYYY-MM-DD")
      ),
      loadingStatus: true,
      ordersData: [],
    });
    await this.ordersData();
  }
  async setValue(field, e) {
    var object = {};
    object[field] = e.target.value;
    object["message"] = "";
    await this.setState(object);
    if (field == "filterValue") {
      await this.mastersSorting();
    }
  }
  /* To redircted to the service request form */
  openServiceRerquestForm(data) {
    localStorage.setItem("requestFormId", data.UniqueId);
  }
  async quickRequest(row) {
    let pstDate = AppController.pstDate();
    row.createdDate = pstDate;
    (row.driverName = this.state.driverManagerInfo.Identifier),
      await this.setState({
        quicksuccess: "",
        message: "",
        color: "",
        quickText: "",
        quickobj: row,
      });
    jQuery(
      function ($) {
        $("#quickModal").modal({
          backdrop: "static",
          keyboard: false,
          show: true,
        });
      }.bind(this)
    );
  }
  async quickSubmit() {
    if (this.state.quickText != "") {
      let subdata = {
        subject:
          this.state.quickobj.TableId == "" ||
            this.state.quickobj.TableId == null
            ? "Urgent Notifications for Master # : " +
            this.state.quickobj.mastertableId +
            " and Record # :" +
            this.state.quickobj.sTableId
            : "Urgent Notifications for Order # : " +
            this.state.quickobj.TableId +
            " ; Master # :" +
            this.state.quickobj.mastertableId +
            " ; Record # :" +
            this.state.quickobj.sTableId,
        quickobj: this.state.quickobj,
        notes: this.state.quickText,
        email: "guestserve@suiteamerica.com",
        ccemail:
          "edhscheduling@suiteamerica.com, drivermanagers@suiteamerica.com",
      };
      this.setState({ modalLoader: true });
      const mailsRes = await FiveStarService.quickRequest(subdata);
      this.setState({ modalLoader: false });
      if (mailsRes.response) {
        this.setState({
          message: "",
          color: "",
          quicksuccess: "Your urgent request has been sent successfully!",
        });
      } else {
        this.setState({
          quicksuccess: "",
          message: "Something went Wrong!",
          color: "red",
        });
      }
    } else {
      this.setState({
        message: "Please Enter Reason for Urgent Request ",
        color: "red",
      });
    }
  }
  /* To hold the selected order */
  async holdService(row, index, statusType, statusString) {
    await this.setState({
      holdData: { index, data: row, statusType, statusString },
    });
    jQuery(
      function ($) {
        $("#holdModal").modal({
          backdrop: "static",
          keyboard: false,
          show: true,
        });
      }.bind(this)
    );
  }
  async holdSubmit() {
    const stateData = this.state.holdData;
    this.statusChange(
      stateData.data,
      stateData.index,
      stateData.statusType,
      stateData.statusString
    );
    if (this.state.holdText != "") {
      setTimeout(
        async function () {
          jQuery(
            function ($) {
              $("#holdModal").modal("hide");
              this.setState({ holdText: "", message: "", color: "" });
            }.bind(this)
          );
        }.bind(this),
        700
      );
      $("html, body").animate({ scrollTop: 0 }, 1000);
    } else {
      this.setState({ message: "Please Enter Reason for hold", color: "red" });
    }
  }
  async statusChange(row, index, statusType, statusString) {
    let postObj;
    let updateObject;
    let isStarted;
    await this.setState({
      orderStatusLoading: true,
      ScheduleUniqueId: row.UniqueId,
    });
    const Identifier = this.state.driverManagerInfo.Identifier;
    const pstTime = AppController.pstDateTime();
    const pstDate = AppController.pstDate();

    postObj = {
      uniqueId: row.UniqueId,
      driverUId: this.state.driverManagerInfo.UniqueId,
      type: row.type,
      status: statusType,
      FivestarStatus: statusString,
    };

    if (statusType == 1) {
      _.assign(postObj, {
        startTime: pstTime,
      });
      updateObject = {
        [index]: {
          StartTime: { $set: pstTime },
          startedDriver: { $set: 1 },
          StatusHold: { $set: null },
        },
      };
      isStarted = row.UniqueId;
    } else if (statusType == 2) {
      _.assign(postObj, {
        dfsUId: row.dfsUId,
        stopTime: pstTime,
        createdDate: pstDate,
      });
      updateObject = {
        [index]: {
          EndTime: { $set: pstTime },
          ServiceStatus: { $set: "completed" },
        },
      };
      isStarted = -1;
    } else {
      _.assign(postObj, {
        stopTime: pstTime,
        serviceStatus: "hold",
        holdstatus: this.state.holdText,
      });
      updateObject = { [index]: { StatusHold: { $set: "hold" } } };
      isStarted = row.UniqueId;
    }

    const updateDataResponce = await FiveStarApiServices.SaveDriverSchedules(
      postObj
    );
    let updatedPosts = update(this.state.ordersData, updateObject);
    statusType != 2
      ? (updatedPosts = update(updatedPosts, {
        [index]: { dfsUId: { $set: updateDataResponce.message.uniqueid } },
      }))
      : "";
    await this.setState({ ordersData: [] });
    await this.orderByData(updatedPosts, false);
    await this.setState({ orderStatusLoading: false, ScheduleUniqueId: "" });
  }
  /* To load the more notes */
  loadMoreNotes(loadMoreIndex) {
    this.setState({ loadMoreIndex });
  }
  conformationAlert(data, index) {
    confirmAlert({
      title: "",
      message: "Is the suite completely ready for guest to move in ?",
      childrenElement: () => "",
      confirmLabel: "Yes",
      cancelLabel: "No",
      onConfirm: this.updateSuiteMoveIn.bind(this, data, index),
      onCancel: () => "",
    });
  }
  async showOrderForm() {
    await this.setState({ orderFormValue: true });
  }
  async updateSuiteMoveIn(data, index) {
    this.setState({ loaderaptReady: index });
    const Update = await FiveStarApiServices.updateSuiteId({
      AptReady: 1,
      MasterId: data.mastertableId,
      ChangedBy: this.state.driverManagerInfo.Email,
    });
    let updatedaptReady = update(this.state.ordersData, {
      [index]: { AptReady: { $set: true } },
    });
    await this.orderByData(updatedaptReady, false);
    await this.setState({ loaderaptReady: -1 });
  }

  async updateReschedule(data, index) {
    this.setState({ modalLoader: true });
    let requestObj = {
      UniqueId: data.UniqueId,
      IsReSchedule: 1,
      ReSchedule: this.state.reScheduleText,
      Type: data.type,
      ChangedBy: this.state.driverManagerInfo.Email,
    };
    data["ReScheduleInfo"] = this.state.reScheduleText;
    data["Driver"] = this.state.driverManagerInfo.Identifier;
    data["RequestedDate"] = AppController.pstDate();

    const Update = await FiveStarApiServices.updateReScheduleDetails(
      requestObj
    );
    let updatedaptReady = update(this.state.ordersData, {
      [index]: {
        IsReSchedule: { $set: 1 },
        ReScheduleReason: { $set: this.state.reScheduleText },
      },
    });
    const resEmail = await FiveStarService.reschedule(data);
    await FiveStarApiServices.CreateServiceRequestEmailLog(
      resEmail.responseData
    );
    this.setState({ ordersData: updatedaptReady, modalLoader: false });
  }

  /* It is invoked to return html content */
  async imagesGet(row) {
    this.setState({
      s3bucketImages: [],
      error: 0,
    });
    const request = {
      path:
        row.type + "/" + row.UniqueId.toUpperCase().replace(/ /g, "_") + "/",
    };
    const response = await FiveStarService.s3BucketImages(request);
    this.setState({
      s3bucketImages: response.result,
      error: response.result.length == 0 ? 400 : 200,
    });
  }
  async reSchedule(data, index) {
    if (this.state.reScheduleText != "") {
      await this.updateReschedule(data, index);

      jQuery(
        function ($) {
          $("#reschedule").modal("hide");
          this.setState({ reScheduleText: "", message: "", color: "" });
        }.bind(this)
      );
      jQuery(function ($) {
        $("#successModal").modal("show");
        setTimeout(function () {
          $("#successModal").modal("hide");
        }, 700);
      });

      $("html, body").animate({ scrollTop: 0 }, 1000);
    } else {
      this.setState({
        message: "Please Enter Reason for reschedule",
        color: "red",
      });
    }
  }
  suiteNotReady() {
    this.clearFormData();
    if (this.state.suiteNotReadyText != "") {
      setTimeout(
        async function () {
          jQuery(
            function ($) {
              $("#isSuiteReady").modal("hide");
              this.setState({ suiteNotReadyText: "", message: "", color: "" });
            }.bind(this)
          );
        }.bind(this),
        700
      );
      $("html, body").animate({ scrollTop: 0 }, 1000);
    } else {
      this.setState({
        message: "Please Enter Reason for suite not ready",
        color: "red",
      });
    }
  }
  clearFormData(row, index) {
    this.setState({
      reScheduleText: "",
      suiteNotReadyText: "",
      message: "",
      color: "",
      modalLoader: false,
      reSchedule: row,
      reScheduleIndex: index,
    });
  }
  async funstuff(row) {
    await this.setState({ funstuff: row });
  }
  async mastersSorting() {
    var name_ordertype = this.state.filterValue.split(" ");
    const filteredMaster = _.orderBy(
      this.state.ordersData,
      [name_ordertype[0]],
      [name_ordertype[1]]
    );
    await this.setState({ ordersData: filteredMaster });
  }
  async setTableId(row) {
    const driverDetails = {
      uniqueid: row.UniqueId
    }
    const resOrders = await FiveStarApiServices.getServiceScheduleById(driverDetails);
    this.setState({ requestDetails: resOrders[0] })
  }
  render() {
    const uniqueOrders = _.filter(this.state.ordersData, function (o) {
      return (
        o.ServiceStatus == null ||
        o.ServiceStatus == "started" ||
        o.ServiceStatus == "completed"
      );
    });
    const filterOrders = _.uniqBy(uniqueOrders, "UniqueId");
    const startIndex = _.findIndex(filterOrders, function (o) {
      return o.StartTime != null && o.EndTime == null;
    });
    let today = moment();
    let yesterday = moment().add(-1, 'days');
    let selectedDate = moment(this.state.startDate).format('YYYY-MM-DD');
    return (
      <div>
        <div
          className="chat-apple-icon"
          data-toggle="modal"
          data-target="#orderModal"
          onClick={this.showOrderForm}
        >
          <img src={"/assets/images/chat-apple-icon.svg"} />
        </div>
        <FivestarHeader />
        {this.state.driverManagerInfo.IsManager == 0 &&
          this.state.driverManagerInfo.IsVendorManager != true ? (
          <div id="pages_5star">
            <ScheduleMapViewHeading
              activeIndex={this.state.activeIndex}
              changeIndex={this.changeIndex}
            />
            <div className="container">
              <div className="schedule-header">
                <div className="stop-dates">
                  <div className="f-star-schedule-page-head">
                    <h2>
                      STOPS:
                      {this.state.loadingStatus
                        ? null
                        : this.state.ordersData.length}
                    </h2>
                  </div>
                  <DatePicker
                    maxDate={
                      this.state.driverManagerInfo.Type == "PTS"
                        ? moment().add(10, "d")
                        : moment()
                    }
                    minDate={
                      this.state.driverManagerInfo.Type == "PTS"
                        ? moment().subtract(3, "d")
                        : null
                    }
                    selected={this.state.startDate}
                    onChange={this.handleChange}
                  />
                </div>

                <select
                  id="sel3"
                  className="form-control master-filters"
                  onChange={(e) => this.setValue("filterValue", e)}
                  value={this.state.filterValue}
                >
                  <option value="">Sort</option>
                  <option value="community_name asc">Community ASC</option>
                  <option value="community_name desc">Community DESC</option>
                </select>
              </div>

              <div className="clearfix"></div>
              {this.state.loadingStatus ? (
                <div
                  className="panel-body_loader"
                  style={{ minHeight: "300px" }}
                >
                  {" "}
                  <span
                    style={{
                      fontSize: "25px",
                      width: "100%",
                      margin: "auto",
                      textAlign: "center",
                    }}
                  >
                    <i className="fa fa-spinner fa-spin" /> please wait ...!
                  </span>{" "}
                </div>
              ) : !this.state.loadingStatus &&
                this.state.ordersData.length == 0 ? (
                <div
                  style={{
                    color: "red",
                    textAlign: "center",
                    fontSize: "25px",
                    minHeight: "300px",
                  }}
                >
                  No Orders For This Date
                </div>
              ) : null}
              {this.state.activeIndex == 1 ? (
                <div>
                  {this.state.ordersData.map((row, index) => {
                    const NotesData =
                      row.Notes == "" || row.Notes == null
                        ? []
                        : row.Notes.split("####");
                    const notesLimit =
                      this.state.loadMoreIndex == index
                        ? _.filter(NotesData, function (o) {
                          return o != "";
                        })
                        : NotesData.slice(0, 3);
                    const otherString = [];
                    row.driverName != null
                      ? otherString.push(row.driverName)
                      : "";
                    row.cleaner != null ? otherString.push(row.cleaner) : "";
                    row.helper != null ? otherString.push(row.helper) : "";
                    let user = row.IsGuestActive ? (
                      <i className="fa fa-users" style={{ color: "blue" }}></i>
                    ) : (
                      ""
                    );
                    let pet =
                      row.petscount == 0 ? (
                        ""
                      ) : (
                        <i className="fa fa-paw" style={{ color: "blue" }}></i>
                      );
                    let isPte = row.IsPte ? (
                      <i
                        className="fa fa-check-square"
                        style={{ color: "blue" }}
                      >
                        PTE has been granted
                      </i>
                    ) : (
                      ""
                    );
                    let data = _.isMatch(row, { TypeCode: "EXE" });
                    let Info = row.TypeCode.match(/EXE.*/) ? true : false;

                    return (
                      <div
                        className="schedule-order-contain mrg-btm-30"
                        key={index}
                      >
                        <div className="order-header ohr">
                          <div className="order-header-item">
                            {row.StartTime != null && row.EndTime != null ? (
                              <a className="sc-order-id">
                                Order :{" "}
                                {row.TableId == null ? " --- " : row.TableId}
                                ,Master :{" "}
                                {row.mastertableId == null
                                  ? " --- "
                                  : row.mastertableId}{" "}
                                {user} {pet} {isPte}{" "}
                                {row.type == "schedule" &&
                                  (row.TypeCode == "T/O" ||
                                    row.TypeCode == "W/T") &&
                                  row.AptReady == false ? (
                                  <span style={{ color: "green" }}>
                                    (Suite Ready To Move In)
                                  </span>
                                ) : (
                                  <span style={{ color: "green" }}>
                                    (Completed)
                                  </span>
                                )}
                              </a>
                            ) : (
                              <Link
                                to="/service-request-form"
                                onClick={() =>
                                  this.openServiceRerquestForm(row)
                                }
                                className="sc-order-id"
                              >
                                {" "}
                                Order :{" "}
                                {row.TableId == null ? " --- " : row.TableId} ,
                                Master :{" "}
                                {row.mastertableId == null
                                  ? " --- "
                                  : row.mastertableId}{" "}
                                {user} {pet} {isPte}{" "}
                                {row.StatusHold == "hold" ? (
                                  <span style={{ color: "#0c65f4" }}>
                                    (Hold)
                                  </span>
                                ) : null}
                              </Link>
                            )}
                          </div>
                          <div>
                            {otherString.length > 0 ? (
                              <div className="order-header-item pull-left">
                                <p className="bold">
                                  <a className="sc-order-id">
                                    {otherString.length > 0 ? "Others: " : ""}{" "}
                                    {otherString.length > 0
                                      ? otherString.join(", ")
                                      : ""}
                                  </a>{" "}
                                </p>
                              </div>
                            ) : null}
                            {row.StartTime == null &&
                              row.EndTime == null &&
                              row.StatusHold != "hold" &&
                              !Info ? (
                              <a
                                href="#"
                                data-toggle="modal"
                                data-target="#reschedule"
                                onClick={() => this.clearFormData(row, index)}
                                className={
                                  row.IsReSchedule
                                    ? "reschedule pull-right mrg-left-10 btn btn-success"
                                    : "reschedule pull-right mrg-left-10 btn btn-danger"
                                }
                              >
                                {row.IsReSchedule
                                  ? "Reschedule Requested"
                                  : "Reschedule"}{" "}
                              </a>
                            ) : null}
                            <a
                              href="#"
                              onClick={() => this.quickRequest(row)}
                              className={
                                "reschedule pull-right mrg-left-10 btn btn-danger"
                              }
                            >
                              {"Urgent Request"}{" "}
                            </a>
                          </div>
                        </div>

                        <div
                          className="chat-apple-icon"
                          data-toggle="modal"
                          data-target="#orderModal"
                          onClick={this.showOrderForm}
                        >
                          <img src={"/assets/images/chat-apple-icon.svg"} />
                        </div>

                        <div className="row">
                          <FirstRowFields rowData={row} manager={false} />
                          <SecondRowFields rowData={row} />
                          <ThirdRowFields rowData={row} NotesData={NotesData} />
                          <BedsList rowData={row} />
                        </div>
                        <div className="order-header-item more-phots pull-right mrg-top-30 mrg-right-15">
                          {row.TypeCode == "T/O" ||
                            row.TypeCode == "W/T" ||
                            row.TypeCode == "EXEW/T" ||
                            row.TypeCode == "M/G" ||
                            row.TypeCode == "EXET/O" ||
                            row.TypeCode == "T/O SS" ? (
                            <span className="mrg-right-15">
                              {row.AppCode != null ? (
                                <i className="fa fa-television"></i>
                              ) : null}{" "}
                              {row.AppCode}
                            </span>
                          ) : null}
                          {
                            (moment(selectedDate).isSame(today, 'day') || moment(selectedDate).isSame(yesterday, 'day')) ?
                              <a href="#" data-toggle="modal"
                                data-target="#uploadFilesData"
                                className="color-red font-size-18" style={{ padding: '0px 10px' }}>
                                <i className="fa fa-upload" onClick={() => this.setTableId(row)}></i></a>
                              :
                              null}
                          <a
                            href="#"
                            data-toggle="modal"
                            data-target="#morePhotos"
                            className="color-red font-size-18"
                            onClick={() => this.imagesGet(row)}
                          >
                            <i className="fa fa-camera"></i>
                          </a>
                          {row.FunStuffList != null ? (
                            <a
                              href="#"
                              data-toggle="modal"
                              data-target="#funstuff"
                              className="color-red font-size-18 mrg-left-10"
                              onClick={() => this.funstuff(row)}
                            >
                              <i className="fa fa-truck"></i>
                            </a>
                          ) : null}
                        </div>

                        {row.StartTime != null && row.EndTime != null ? (
                          <SuiteReadyButton
                            rowData={row}
                            loaderaptReady={this.state.loaderaptReady}
                            index={index}
                            conformationAlert={this.conformationAlert}
                          />
                        ) : !row.IsReSchedule ? (
                          <Buttons
                            statusChange={this.statusChange}
                            rowData={row}
                            index={index}
                            holdService={this.holdService}
                            orderStatusLoading={this.state.orderStatusLoading}
                            ScheduleUniqueId={this.state.ScheduleUniqueId}
                            openServiceRerquestForm={
                              this.openServiceRerquestForm
                            }
                            {...this.state}
                          />
                        ) : null}
                        <div className="clearfix"></div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <DiverScheduleMap ordersData={this.state.ordersData} />
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        {this.state.driverManagerInfo.IsManager == 1 ||
          this.state.driverManagerInfo.IsVendorManager == true ? (
          <DriverManagerSchedule
            ordersData={filterOrders}
            stateValues={this.state}
            changeIndex={this.changeIndex}
            handleChange={this.handleChange}
          />
        ) : null}
        <HoldModal
          {...this.state}
          holdSubmit={this.holdSubmit}
          setValue={this.setValue}
        />
        <QuickRequest
          {...this.state}
          quickSubmit={this.quickSubmit}
          setValue={this.setValue}
        />
        <S3bucketImages {...this.state} />
        <S3BucketUpload {...this.state} />
        <RescheduleModal
          {...this.state}
          index={this.state.reScheduleIndex}
          rowData={this.state.reSchedule}
          reSchedule={this.reSchedule}
          setValue={this.setValue}
        />

        <FunStuffModal
          {...this.state}
          index={this.state.reScheduleIndex}
          rowData={this.state.funstuff}
        />
        <CancelSuiteReadyModal
          {...this.state}
          suiteNotReady={this.suiteNotReady}
          setValue={this.setValue}
        />
        <SuccessModal />
        <OrderInfo
          getOrderInfo={this.getOrderInfo}
          orderInformation={this.state.orderInformation}
          orderFormValue={this.state.orderFormValue}
        />
      </div>
    );
  }
}
export default DriversSchedule;
