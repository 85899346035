import React, { Component } from "react";

class FiltersAndSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.filterByTag = this.filterByTag.bind(this);
  }

  handleInput(e) {
    this.setState({
      searchText: e.target.value,
    });
  }

  handleKeyPress(e) {
    if (e.key === "Enter") {
      this.props.handleSearch(this.state.searchText);
    }
  }

  handleClick() {
    this.props.handleFilterByCategory(null);
    this.setState({ searchText: "" });
    this.props.handleBlogSelect(null);
  }

  filterByTag(event) {
    if (event.target.hasAttribute("aria-label")) {
      let ariaLabelValue = event.target.getAttribute("aria-label");
      // this.props.handleFilterByCategory(ariaLabelValue);
      this.props.navigateTo(`/blog?category=${ariaLabelValue}`);
    }
  }

  render() {
    const { activeCategory } = this.props;
    return (
      <div className="sa-blog-second-header">
        <div className="container">
          <div className="logo-section">
            <div className="another-logo" onClick={this.handleClick}>
              <img src="/assets/newUI-images/logo/SA_S-logo-B.svg" />
              <h1>Blog</h1>
            </div>
            <div className="input-group blog-search">
              <input
                type="text"
                className="form-control"
                placeholder="Search for..."
                onChange={(e) => this.handleInput(e)}
                onKeyDown={this.handleKeyPress}
                value={this.state.searchText}
              />
              <span className="input-group-btn">
                <button
                  className="btn btn-default"
                  type="button"
                  onClick={() => this.props.handleSearch(this.state.searchText)}
                >
                  <i className="bi bi-search"></i>
                </button>
              </span>
            </div>
          </div>

          <>
            <div className="tags-navigation">
              <ul className="nav navbar-nav d-flex" onClick={this.filterByTag}>
                <li className="nav-item">
                  <button
                    className={`btn black-btn ${
                      activeCategory === "Corporate Housing" ? "active" : ""
                    }`}
                    type="button"
                    aria-label="Corporate Housing"
                  >
                    Corporate Housing
                  </button>
                </li>

                <li className="nav-item">
                  <button
                    className={`btn black-btn ${
                      activeCategory === "Technology" ? "active" : ""
                    }`}
                    type="button"
                    aria-label="Technology"
                  >
                    Technology
                  </button>
                </li>

                <li className="nav-item">
                  <button
                    className={`btn black-btn ${
                      activeCategory === "News" ? "active" : ""
                    }`}
                    type="button"
                    aria-label="News"
                  >
                    News
                  </button>
                </li>

                <li className="nav-item">
                  <button
                    className={`btn black-btn ${
                      activeCategory === "Global Mobility" ? "active" : ""
                    }`}
                    type="button"
                    aria-label="Global Mobility"
                  >
                    Global Mobility
                  </button>
                </li>

                <li className="nav-item">
                  <button
                    className={`btn black-btn ${
                      activeCategory === "Travel" ? "active" : ""
                    }`}
                    type="button"
                    aria-label="Travel"
                  >
                    Travel
                  </button>
                </li>

                <li className="nav-item">
                  <button
                    className={`btn black-btn ${
                      activeCategory === "Social Initiatives" ? "active" : ""
                    }`}
                    type="button"
                    aria-label="Social Initiatives"
                  >
                    Social Initiatives
                  </button>
                </li>

                <li className="nav-item">
                  <button
                    className={`btn black-btn ${
                      activeCategory === "Events" ? "active" : ""
                    }`}
                    type="button"
                    aria-label="Events"
                  >
                    Events
                  </button>
                </li>

                <li className="nav-item">
                  <button
                    className={`btn black-btn ${
                      activeCategory === "Relocation" ? "active" : ""
                    }`}
                    type="button"
                    aria-label="Relocation"
                  >
                    Relocation
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`btn black-btn ${
                      activeCategory === "Lifestyle" ? "active" : ""
                    }`}
                    type="button"
                    aria-label="Lifestyle"
                  >
                    Lifestyle
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`btn black-btn ${
                      activeCategory === "Awards" ? "active" : ""
                    }`}
                    type="button"
                    aria-label="Awards"
                  >
                    Awards
                  </button>
                </li>
              </ul>
            </div>
          </>
        </div>
      </div>
    );
  }
}

export default FiltersAndSearch;
