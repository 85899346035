/* Importing the node modules, child components, services and controllers used
   inside FiveStarLanding component */
import $ from "jquery";
import AppController from "../../controller/appController";
import FiveStarApiServices from '../../../services/fiveStarApiServices';
import moment from 'moment';
import SessionService from "../../../services/sessionService";
/* FiveStarLanding Component initialization */
class FiveStarLanding extends React.Component {
  /* Initializing objects of its FiveStarLanding class */
  constructor(props) {
    super(props)
    /* FiveStarLanding Component State variables Initialization */
    this.state = {
      driversList: [],
      driverid: '',
      password: '',
      message: '',
      authentication: [],
      email: '',
      loader: false,
      color: '',
      emailerr: '',
      loginUsersLoading: false
    }
    this.login = this.login.bind(this);
    this.setValue = this.setValue.bind(this);
  }
  /* It is invoked immediately after a component is mounted */
  async componentDidMount() {
    $('html, body').animate({scrollTop: 0});
    AppController.stickyTitles()
    this.setState({loginUsersLoading: true})
    const resultData = await FiveStarApiServices.GetDriversManagerList({
      toDate: moment().format('YYYY-MM-DD')
    })
    this.setState({driversList: resultData, loginUsersLoading: false})
  }
  /* To change the selected input field value */
  setValue(field, e) {
    var object = {};
    object[field] = e.target.value;
    this.setState(object);
  }
  /* Authentication checking before login to the five star application */
  async login(e) {
    e.preventDefault();
    localStorage.removeItem('startDate')
    if (this.state.driverid != '' && this.state.password != '') {
      const loginDetails = {
        uniqueid: this.state.driverid,
        loginPassword: this.state.password
      }
      const AuthenticationResult = await FiveStarApiServices.CheckDriverAuthentication(loginDetails)
      if (AuthenticationResult=="" || AuthenticationResult==null) {
        this.setState({message: 'An error occurred. Please try again later.'})
      } else if (AuthenticationResult.error == 1) {
        this.setState({message: 'Invalid Login'})
      } else {
        const fiveStarInfo = {
          userData: AuthenticationResult.message,
          type: 'fivestar'
        }
        const Response = await SessionService.SessionStore(fiveStarInfo);
        this.props.history.push("/drivers-schedule")
      }
    } else {
      this.setState({message: 'Invalid Login'})
    }
  }
  clearData() {
    this.setState({emailerr: '', color: '', loader: false, email: ''})
  }
  /* It is invoked to return html content */
  render() {
    return (
      <div id="pages_5star" style={{
        'background': '#f5f5f5'
      }}>
        <div className="login_main">
          <div className="container-fluid">
            <div className="menu-links">
              <ul className="translation-links">
                <li>
                  <a data-lang="English" id='lang1'>English</a>
                </li>
                <li className="devider">|</li>
                <li>
                  <a data-lang="Spanish" id='lang2'>Spanish</a>
                </li>
              </ul>
            </div>

            <div className="login_star">

              <img src="/assets/newUI-images/5-starLogo.png" alt=""/> {this.state.loginUsersLoading
                ? <div
                    className="panel-body_loader loadmore"
                    style={{
                    background: 'transparent',
                    padding: '15px 0px',
                    margin: '0px'
                  }}>
                    <span
                      style={{
                      fontSize: '30px',
                      width: '100%',
                      margin: 'auto',
                      textAlign: 'center'
                    }}><i className="fa fa-spinner fa-spin"/>
                      please wait ...!</span>
                  </div>
                : <div>
                  <div className="col-sm-12">

                    {this.state.message
                      ? <span
                          style={{
                          color: 'red',
                          textAlign: 'right'
                        }}>{this.state.message}</span>
                      : ''}
                  </div>
                  <form onSubmit={(e) => this.login(e)}>

                    <div className="f-contact-form-style">

                      <div className="form-group">
                        <select
                          className="form-control"
                          value={this.state.driverid}
                          onChange={(e) => this.setValue('driverid', e)}>
                          <option value="">Select Drivers</option>
                          {Array.isArray(this.state.driversList) && this
                            .state
                            .driversList
                            .map((row, index) => {
                              return (
                                <option value={row.UniqueId} key={index}>{row.Identifier}</option>
                              )
                            })}
                        </select>
                      </div>
                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control"
                          id="exampleInputPassword1"
                          placeholder="Password"
                          value={this.state.password}
                          onChange={(e) => this.setValue('password', e)}/>
                      </div>
                      <div className="mrg-btm-20">
                        <button type="submit" className="btn">
                          LOGIN</button>
                      </div>

                    </div>
                    <div className="clearfix"></div>
                    <a href="https://suiteamerica.com/forgotpassword" target="_blank">
                      Forgot Password</a>
                  </form>

                </div>}
            </div>
          </div>

        </div>

      </div>

    );
  }
}
export default FiveStarLanding;