const FloorPlan = (props) => {
  let tabsLists = props.communityInfo.FLOORPLANS.Data
  const tabsListDisplay = () => {
    return (
      <ul className="nav nav-tabs deantabss" role="tablist">
        {tabsLists.map((tab, tabid) => {
          return (
            <li role="presentation" className={tabid > 0 ? "" : "active"} key={tabid}>
              <a
                href={"#" + tab.id}
                aria-controls={tab.name}
                role="tab"
                data-toggle="tab"
                onClick={tabid > 0 ? () => props.floorCorousel(tabid) : null}
              >
                {tab.name}
              </a>
            </li>
          )
        })}
      </ul>
    )
  }

  const thumb = (row, index) => {
    return (
      <div className="item" key={index}>
        <li>{row.name}</li>
        <li>{row.squareFeet}</li>
      </div>
    );
  };

  const bigImages = (row, index, length) => {
    return (
      <div className="item wow animated zoomIn " key={index}>
        <div className="floor_pln">
          <div className="row">
            <div className="col-md-7">
              <div className="floorimg">
                <div
                  className="floorimg_expand"
                  data-toggle="modal"
                  data-target="#floorview"
                  onClick={() => props.floorImages(row)}
                >
                  <i className="fa fa-plus-circle" />
                  <p>EXPAND</p>
                </div>
                <img src={row.image} />
                <h2> {index + 1} OF {length} FLOORPLANS </h2>
              </div>
            </div>
            <div className="col-md-5">
              <div className="floorimg_textside">
                <h1>{row.plan}</h1>
                <h2>{row.name}</h2>
                <h2 className="mg-b-40">{row.squareFeet}</h2>

                {(row.video3dUrl) !== undefined ? <button type="button" className="btn" data-toggle="modal"
                  data-target={row.video3dUrl != '' ? "#3dView" : ''} onClick={row.video3dUrl ? () => props.follorIn3d({ 'url': row.video3dUrl }) : null}>
                  VIEW 3D FLOORPLAN </button> : ''}

                {(props.communityInfo.Video.url) !== "" ? <button type="button" className="btn btn-danger" data-toggle="modal"
                  data-target={props.communityInfo.Video.url != '' ? "#3dView" : ''}
                  onClick={props.communityInfo.Video.url ? () => props.follorIn3d({ 'url': props.communityInfo.Video.url })
                    : null}>
                  <i className="fa fa-refresh" aria-hidden="true"></i> <br />
                  360 <br /> VIEW
                </button> : ''}
                {(props.communityInfo.Video3d.url) !== "" ? <button type="button" className="btn btn-danger" data-toggle="modal"
                  data-target={props.communityInfo.Video3d.url != '' ? "#3dView" : ''} onClick={props.communityInfo.Video3d.url ? () => props.follorIn3d({ 'url': props.communityInfo.Video3d.url }) : null}>
                  <i className="fa fa-cube" aria-hidden="true"></i><br />
                  3D <br /> View</button> : ''}

                <h3>
                  <i className="fa fa-mobile" /> 800.367.9501
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <div className="container">
        <div className="dean_tour " id="FLOORPLANS">
          <h6 className="mg-t-20">
            <span> &#9670; </span> {props.communityInfo.FLOORPLANS.Heading}
            <span> &#9670; </span>
          </h6>
        </div>
        <div className="dean_floorplan">
          <div className="responsive-tabs floorplans-tabs">
            {tabsListDisplay()}
            <div id="tabs-content1" className="tab-content panel-group">
              {props.communityInfo.FLOORPLANS.Data.map((tabs, tabindex) => {

                return (
                  <div id={tabs.id} role="tabpanel" className={tabindex != 0 ? "tab-pane animated  zoomIn panel-collapse collapse " : "tab-pane animated  zoomIn active  panel-collapse collapse in"}
                    aria-labelledby={"heading" + tabindex + 1} key={tabindex} >
                    <div className="outer">
                      <div id={"sync2" + tabindex} className="deanfloor_smslider owl-carousel owl-theme">
                        {tabs.floors.map(
                          (row, index) => {
                            return thumb(row, index);
                          }
                        )}
                      </div>
                      <div id={"sync1" + tabindex} className="deanfloor_bgslider owl-carousel owl-theme">
                        {tabs.floors.map((row, index) => {
                          return bigImages(row, index, tabs.floors.length);
                        }
                        )}
                      </div>
                    </div>
                  </div>
                )
              })}
              <div className="row">
                <div className="col-md-7" />
                <div className={(props.communityInfo.Video.url !== "" || props.communityInfo.Video3d.url !== "") ? "col-md-5" : "col-md-5 without-viewimages"}>
                  <div className="deanfloor_bgthumb">
                    {props.communityResponseloader ? (
                      <div
                        id="thumbs"
                        className={
                          "owl-carousel owl-theme comm_thumbnail thumbs" +
                          props.communityResponse.community_id
                        }
                      >
                        {JSON.parse(
                          props.communityResponse.CommunityImages
                        ).map((galleyImage, galleyImageIndex) => {
                          return (
                            <div
                              className="item"
                              key={galleyImageIndex}
                              data-toggle="modal"
                              data-target="#galleryview"
                              onClick={() => props.gallaeryCorousel(galleyImage.image_name)}
                            >
                              {galleyImage.image_name != null ? (
                                <img
                                  className="img-responsive"
                                  src={galleyImage.image_name}
                                  alt=""
                                />
                              ) : (
                                <img
                                  className="img-responsive"
                                  src={
                                    s3BucketUrl_WebsiteImages +
                                    "SuiteAmerica-Guest-Portal-Main-Location_Down.jpg"
                                  }
                                  alt=""
                                />
                              )}
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      ""
                    )}
                  </div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FloorPlan;
