/* Importing the node modules, child components, services and controllers used 
   inside NikeInternshipQuestionnaire component */
import $ from "jquery";
import DatePicker from 'react-datepicker'
import moment from 'moment'
import AppController from "../../controller/appController";
import MainController from "../../controller/mainController";
import SubFooter from "../../common/subFooter";
import StripeInternshipHeader from "../gileadIntern/common/stripeInternHeader";
import NikeServices from '../../../services/nikeService';
import GuestHeader from '../../common/guestHeader';
import Footer from '../../common/footer';
import NikeInternFooter from "../gileadIntern/common/stripeInternFooter";
import ReCAPTCHA from "react-google-recaptcha"
import SuccessModal from '../../common/successModal';
/* StripeInternQuestionnaire Component initialization */
class StripeInternQuestionnaire extends React.Component {
    /* Initializing objects of its NikeInternshipQuestionnaire class */
    constructor(props) {
        super(props)
        this.state = {
            CaptchaValue: '',
            name: '',
            email: '',
            phone: '',
            moveInDate: null,
            moveOutDate: null,
            gender: null,
            genderslist: ['Male', 'Female', 'Non-Binary'],
            smoke: '',
            vehicle: '',
            sleepHabbit: 'Early Bird',
            roomateName: '',
            educationLevel: 'Undergraduate',
            bedSize: '2 Bedrooms',
            notes: '',
            color: '',
            errorMessage: '',
            roommate: '',
            sleepHabbitDropDown: ['Early Bird', 'Night Owl'],
            appartmentSizeDropDown: ['3 Bedrooms', '2 Bedrooms', '1 Bedroom'],
            educationDropDown: ['Undergraduate', 'Graduate'],

            allergies: '',
            neat: '',
            roommate: '',
            supportiveRoommate: '',
            genderIdentity: null,
            pursuingGender: '',
            identifyAsLGBTQIA: '',
            sharebedroom: '',
            validation: {},
            genderwritein: '',
            genderIdentitywritein: '',
            chosenRoomMateName: '',
            loader: false
        }
        this.setValue = this.setValue.bind(this);
        this.setChecked = this.setChecked.bind(this);
        this.handleMoveIn = this.handleMoveIn.bind(this);
        this.handleMoveOut = this.handleMoveOut.bind(this);
        this.checkedStatus = this.checkedStatus.bind(this);
        this.serviceRequest = this.serviceRequest.bind(this);
        this.onChangeCaptcha = this.onChangeCaptcha.bind(this);
        this.selection = this.selection.bind(this);
        this.handlegenderwritein = this.handlegenderwritein.bind(this);
        this.handlegenderIdentity = this.handlegenderIdentity.bind(this);
    }
    /* It is invoked immediately before mounting occurs */
    componentWillMount() {
        MainController.getInitialInformation();
    }


    async onChangeCaptcha(CaptchaValue) {
        await this.setState({ CaptchaValue })
    }
    /* Form Details object creations  */
    setValue(field, e) {
        var object = {};
        object[field] = e.target.value;
        this.setState(object);
        //console.log(this.state.bedSize, "object")
    }
    /* Form Details object creations  */
    setChecked(field, e) {
        if (field == 'gender') {
            this.setState({ gender: e.target.value, genderwritein: '' });
        } else if (field == 'smoke') {
            this.setState({ smoke: e.target.value });
        } else if (field == 'vehicle') {
            this.setState({ vehicle: e.target.value });
        } else if (field == 'neat') {
            this.setState({ neat: e.target.value })
        } else if (field == 'roommate') {
            this.setState({ roommate: e.target.value })
        } else if (field == 'supportiveRoommate') {
            this.setState({ supportiveRoommate: e.target.value })
        } else if (field == 'genderIdentity') {
            this.setState({ genderIdentity: e.target.value, genderIdentitywritein: '' })
        } else if (field == 'pursuingGender') {
            this.setState({ pursuingGender: e.target.value })
        } else if (field == 'identifyAsLGBTQIA') {
            this.setState({ identifyAsLGBTQIA: e.target.value })
        }
    }
    handlegenderwritein(e) {
        this.setState({ genderwritein: e.target.value, gender: '' });
    }
    handlegenderIdentity(e) {
        this.setState({ genderIdentitywritein: e.target.value, genderIdentity: '' });
    }
    checkedStatus(field, e) {
        this.setState({ sharebedroom: e.target.checked ? 'Yes' : 'No' });
    }
    /* MoveIndate and MoveOutDate   */
    async handleMoveIn(date) {
        await this.setState({
            moveInDate: moment(date),
            moveInDateSelected: date.format('MM-DD-YYYY HH:mm:ss')
        });
    }
    async selection(e) {
        if (e.target.value == 'Yes') {
            let gendervalue = this.state.genderwritein == '' ? this.state.gender : this.state.genderwritein;
            let genderIdentityvalue = this.state.genderIdentitywritein == '' ? this.state.genderIdentity : this.state.genderIdentitywritein;

            const Request = {
                name: this.state.name,
                email: this.state.email,
                phone: this.state.phone,
                moveInDate: this.state.moveInDate.format('MM-DD-YYYY'),
                moveOutDate: this.state.moveOutDate.format('MM-DD-YYYY'),
                gender: (gendervalue == '' || gendervalue == null) ? 'NA' : gendervalue,
                smoke: this.state.smoke == '' ? 'No' : this.state.smoke,
                vehicle: this.state.vehicle == '' ? 'No' : this.state.vehicle,
                sleepHabbit: this.state.sleepHabbit,
                roomateName: this.state.roomateName == '' ? 'NA' : this.state.roomateName,
                educationLevel: this.state.educationLevel,
                bedSize: this.state.bedSize,
                message: this.state.notes == '' ? 'NA' : this.state.notes,
                mail: 'gileadInternShipQuestionnaire',
                roommate: this.state.roommate == '' ? 'No' : this.state.roommate,
                subject: 'Gilead Internship - Housing Registration',
                allergies: this.state.allergies == '' ? 'NA' : this.state.allergies,
                neat: this.state.neat == '' ? 'NA' : this.state.neat,
                roommate: this.state.roommate == '' ? 'NA' : this.state.roommate,
                supportiveRoommate: this.state.supportiveRoommate == '' ? 'NA' : this.state.supportiveRoommate,
                genderIdentity: (genderIdentityvalue == '' || genderIdentityvalue == null) ? 'NA' : genderIdentityvalue,
                pursuingGender: this.state.pursuingGender == '' ? 'NA' : this.state.pursuingGender,
                identifyAsLGBTQIA: this.state.identifyAsLGBTQIA == '' ? 'NA' : this.state.identifyAsLGBTQIA,
                sharebedroom: this.state.sharebedroom == '' ? 'NA' : this.state.sharebedroom,
                chosenRoomMateName: this.state.chosenRoomMateName
            }

            this.setState({
                name: '',
                email: '',
                phone: '',
                moveInDate: moment(),
                moveOutDate: moment(),
                gender: 'Male',
                smoke: '',
                vehicle: '',
                sleepHabbit: 'Early Bird',
                roomateName: '',
                educationLevel: 'Undergraduate',
                bedSize: '2 Bedroom',
                notes: '',
                roommate: '',
                color: '',
                errorMessage: '',
                allergies: '',
                genderwritein: '',
                neat: '',
                roommate: '',
                supportiveRoommate: '',
                genderIdentity: null,
                genderIdentitywritein: '',
                pursuingGender: '',
                identifyAsLGBTQIA: '',
                validation: {},
                chosenRoomMateName: ''
            })

            $('input[name="0"]').prop('checked', false);
            $('input[name="1"]').prop('checked', false);
            $('input[name="2"]').prop('checked', false);
            $('input[name="3"]').prop('checked', false);
            $('input[name="4"]').prop('checked', false);
            $('input[name="5"]').prop('checked', false);
            $('input[name="6"]').prop('checked', false);
            $('input[name="7"]').prop('checked', false);
            $('input[name="8"]').prop('checked', false);
            $('input[name="9"]').prop('checked', false);
            jQuery(function ($) {
                setTimeout(function () {
                    $('#internship-confirmation').modal('hide');
                    AppController.showSuccessModal();
                }.bind(this), 500);
            }.bind(this));
            console.log('Request', Request);
            const Response = await NikeServices.serviceRequest(Request);
            //console.log(Request, "Response")

        } else {
            jQuery(function ($) {
                setTimeout(function () {
                    $('#internship-confirmation').modal('hide');
                }.bind(this), 500);
            }.bind(this));
        }
    }
    async handleMoveOut(date) {
        await this.setState({
            moveOutDate: moment(date),
            moveOutDateSelected: date.format('MM-DD-YYYY HH:mm:ss')
        });
    }
    /* Service Request Details send to mail  */
    async serviceRequest() {

        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        const moveIn = this.state.moveInDateSelected == '' ? this.state.moveInDate.format('YYYY-MM-DD HH:mm:ss') : this.state.moveInDateSelected;
        const moveOut = this.state.moveOutDateSelected == '' ? this.state.moveOutDate.format('YYYY-MM-DD HH:mm:ss') : this.state.moveOutDateSelected;
        const _this = this
        const validationFields = ['name', 'email', 'phone', 'moveInDate', 'moveOutDate', 'allergies', 'educationLevel', 'sleepHabbit',
            'gender', 'smoke', 'vehicle', 'neat', 'roommate', 'notes',
            'CaptchaValue'
        ]
        //const validationFields = ['name', 'email', 'phone', 'moveInDate', 'moveOutDate', ]
        let error = 0;
        //    console.log(moment(moveOut).diff(moment(moveIn), 'days'), moment(moment(moveOut).format('YYYY-MM-DD HH:mm:ss')).diff(moment(moment(moveIn).format('YYYY-MM-DD HH:mm:ss')), 'days'), '=====')
        //    if(moveIn!='' && moveIn!=null && moveOut!='' && moveOut!=null){
        //      if(moment(moveOut).diff(moment(moveIn), 'days') < 0){
        //         error++;
        //      }
        //    }
        let validationarr = []
        let dateValidation = ""
        _.forEach(validationFields, async function (value) {
            if (_this.state[value] == "" || _this.state[value] == null) {
                if (value == "moveInDate") {
                    validationarr.push("Arrival date")
                } else if (value == "moveOutDate") {
                    validationarr.push("Departure date")
                } else if (value == "phone") {
                    validationarr.push("Phone number")
                } else if (value == "email") {
                    validationarr.push("Email")
                } else if (value == "name") {
                    validationarr.push("Name")
                } else {
                    validationarr.push(value)
                }
                error++;
            }
            // else if (reg.test(_this.state.email) == false) {
            //     validationarr.push(`valid email`)
            //     // validationobj['email'] = `*please enter valid email`
            //     error++;
            // }else if( this.state.moveInDate != null && this.state.moveOutDate != null){
            //     validationarr.push(`Departure Date is greater than are equal to Arrival Date`)
            // }
        });

        if (_this.state.email != '') {
            if (reg.test(_this.state.email) == false) {
                validationarr.push(`valid email`)
                error++;
            }
            else if (this.state.moveInDate != null && this.state.moveOutDate != null) {

                if (this.state.moveInDate > this.state.moveOutDate) {
                    error++;
                    dateValidation += 'Departure Date is greater than or equal to Arrival Date'
                    // validationarr.push('Departure Date is greater than are equal to Arrival Date')

                }
            }
        }


        //    await this.setState({ validation: validationobj });

        if (error == 0) {
            await this.setState({ loader: true })
            let gendervalue = this.state.genderwritein == '' ? this.state.gender : this.state.genderwritein;
            let genderIdentityvalue = this.state.genderIdentitywritein == '' ? this.state.genderIdentity : this.state.genderIdentitywritein;

            const Request = {
                name: this.state.name,
                email: this.state.email,
                phone: this.state.phone,
                moveInDate: this.state.moveInDate.format('MM-DD-YYYY'),
                moveOutDate: this.state.moveOutDate.format('MM-DD-YYYY'),
                gender: (gendervalue == '' || gendervalue == null) ? 'NA' : gendervalue,
                smoke: this.state.smoke == '' ? 'No' : this.state.smoke,
                vehicle: this.state.vehicle == '' ? 'No' : this.state.vehicle,
                sleepHabbit: this.state.sleepHabbit,
                roomateName: this.state.roomateName == '' ? 'NA' : this.state.roomateName,
                educationLevel: this.state.educationLevel,
                bedSize: this.state.bedSize,
                message: this.state.notes == '' ? 'NA' : this.state.notes,
                mail: 'gileadInternShipQuestionnaire',
                roommate: this.state.roommate == '' ? 'No' : this.state.roommate,
                subject: 'Gilead Internship - Housing Registration',
                allergies: this.state.allergies == '' ? 'NA' : this.state.allergies,
                neat: this.state.neat == '' ? 'NA' : this.state.neat,
                // roommate: this.state.roommate == '' ? 'NA' : this.state.roommate,
                // supportiveRoommate: this.state.supportiveRoommate == '' ? 'NA' : this.state.supportiveRoommate,
                // genderIdentity: (genderIdentityvalue == '' || genderIdentityvalue == null) ? 'NA' : genderIdentityvalue,
                // pursuingGender: this.state.pursuingGender == '' ? 'NA' : this.state.pursuingGender,
                // identifyAsLGBTQIA: this.state.identifyAsLGBTQIA == '' ? 'NA' : this.state.identifyAsLGBTQIA,
                // sharebedroom: this.state.sharebedroom == '' ? 'NA' : this.state.sharebedroom,
                chosenRoomMateName: this.state.chosenRoomMateName
            }

            const Response = await NikeServices.serviceRequest(Request);
            await this.setState({ loader: false })

            await setTimeout(function () {
                jQuery('#nikesuccessmodal').modal('show');
            }.bind(this), 1000);


            this.setState({
                name: '',
                email: '',
                phone: '',
                moveInDate: null,
                moveOutDate: null,
                gender: 'Male',
                smoke: '',
                vehicle: '',
                sleepHabbit: 'Early Bird',
                roomateName: '',
                educationLevel: 'Undergraduate',
                bedSize: '2 Bedroom',
                notes: '',
                roommate: '',
                color: '',
                errorMessage: '',
                allergies: '',
                genderwritein: '',
                neat: '',
                roommate: '',
                supportiveRoommate: '',
                genderIdentity: null,
                genderIdentitywritein: '',
                pursuingGender: '',
                identifyAsLGBTQIA: '',
                validation: {},
                chosenRoomMateName: ''
            })

            $('input[name="0"]').prop('checked', false);
            $('input[name="1"]').prop('checked', false);
            $('input[name="2"]').prop('checked', false);
            $('input[name="3"]').prop('checked', false);
            $('input[name="4"]').prop('checked', false);
            $('input[name="5"]').prop('checked', false);
            $('input[name="6"]').prop('checked', false);
            $('input[name="7"]').prop('checked', false);
            $('input[name="8"]').prop('checked', false);
            $('input[name="9"]').prop('checked', false);

            // $('input[name="Yes"]').prop('checked', false);
            // $('input[name="No"]').prop('checked', false);
            // jQuery(function ($) {
            //     setTimeout(function () {
            //         $('#internship-confirmation').modal('show');
            //     }.bind(this), 500);
            // }.bind(this));

        }
        else {
            AppController.menuTopNavigation('serviceRequest');
            if (dateValidation.length > 0) {
                this.setState({ errorMessage: dateValidation, color: 'red' })
            } else {
                this.setState({ errorMessage: `Please fill all the mandatory fields`, color: 'red' })
            }
        }
    }
    /* It is invoked to return html content */
    render() {

        return (
            <div className="corporateHousing_Updates onlyGileadHeader">
                <div className="modal fade savesearch" id="nikesuccessmodal" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content rm-border-radius">
                            <div className="modal-header">
                                <button type="button" className="btn close" data-dismiss="modal" aria-label="">
                                    <i className="bi bi-x"></i>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p style={{ margin: '13px 0px 30px', fontWeight: '700' }}>Your Request has been Submitted Successfully</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <GuestHeader />
                   <div className="cover-tophd-ht"></div> */}
                <StripeInternshipHeader />
                <div className="container-fluid nike company_section" id="serviceRequest">
                    <div className="container text-center">
                        <h2 className="">Gilead Housing Registration </h2>
                        {/* <h3>Please note we have reached our capacity for intern housing at this time.</h3> */}
                        {/* <p>Please fill in the questionnaire below and press the Submit button. An email will then be sent to SuiteAmerica with your choices.</p> */}
                    </div>
                </div>
                <div className="container-fluid nike-datepicker bg-grey service_request" >
                    <div className="request_form company_form questionnaire_stripe" >
                        <div className="container">
                            <form className="well">
                                {this.state.errorMessage !== '' ? <p className="sh-input-no mrg-btm-20" style={{ color: this.state.color, textAlign: 'center' }}>{this.state.errorMessage}</p> : ''}

                                <h1 className="mrg-top-0 ">Personal Information & Preferences</h1>


                                <div className="row">
                                    <div className="col-sm-4 col-md-4">
                                        <input type="text" className="form-control" placeholder="*Your Name" value={this.state.name} onChange={(e) => this.setValue('name', e)} />
                                    </div>
                                    <div className="col-sm-4 col-md-4">
                                        <input type="text" className="form-control" placeholder="*Phone Number" value={this.state.phone}
                                            onChange={(e) => this.setValue('phone', e)} />
                                    </div>
                                    <div className="col-sm-4 col-md-4">
                                        <input type="text" className="form-control" placeholder="*Email" value={this.state.email} onChange={(e) => this.setValue('email', e)} />
                                    </div>

                                    <div className="col-sm-4 col-md-4">
                                        <input type="text" className="form-control" placeholder="*Do you have any allergies"
                                            value={this.state.allergies}
                                            onChange={(e) => this.setValue('allergies', e)} />
                                    </div>

                                    <div className="col-sm-4 col-md-4">
                                        <DatePicker type="text" className="form-control" placeholderText="*Arrival Date" selected={this.state.moveInDate}
                                            onChange={this.handleMoveIn} />
                                    </div>

                                    <div className="col-sm-4 col-md-4">
                                        <DatePicker type="text" className="form-control" placeholderText="*Departure Date" selected={this.state.moveOutDate}
                                            onChange={this.handleMoveOut} />
                                    </div>

                                    <div className="col-sm-4 col-md-4">
                                        <label className="custom-select">
                                            <select value={this.state.sleepHabbit}
                                                onChange={(e) => this.setValue('sleepHabbit', e)}>
                                                {this.state.sleepHabbitDropDown.map((row, index) => {
                                                    return (
                                                        <option key={index} value={row}>{row}</option>
                                                    )
                                                })}
                                            </select>
                                        </label>
                                    </div>

                                    <div className="col-sm-4 col-md-4">
                                        <label className="custom-select">
                                            <select value={this.state.educationLevel}
                                                onChange={(e) => this.setValue('educationLevel', e)}>
                                                {this.state.educationDropDown.map((row, index) => {
                                                    return (
                                                        <option key={index} value={row}>{row}</option>
                                                    )
                                                })}
                                            </select>
                                        </label>
                                    </div>



                                </div>

                                <div className="row mt-5-px">
                                    <div className="col-md-4">
                                        <label>*Self-Identification</label>
                                        <p className="sh-input-no"><i>(All matches will be based upon gender)</i></p>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="row">
                                            <div className="clearfix"></div>
                                            <div className="col-sm-2">
                                                <label className="custom_radio radio-inline">Male
                                                    <input type="radio" name="1" value="male"
                                                        onChange={(e) => this.setChecked('gender', e)} checked={this.state.gender == 'male' ? true : false} />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                            <div className="col-sm-2">
                                                <label className="custom_radio radio-inline">Female
                                                    <input type="radio" name="1" value="female"
                                                        onChange={(e) => this.setChecked('gender', e)}
                                                        checked={this.state.gender == 'female' ? true : false} />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                            <div className="col-sm-3">
                                                <label className="custom_radio radio-inline">Non-Binary
                                                    <input type="radio" name="1" value="Non-Binary"
                                                        onChange={(e) => this.setChecked('gender', e)}
                                                        checked={this.state.gender == 'Non-Binary' ? true : false} />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                            <div className="col-sm-4">
                                                <label className="custom_radio radio-inline">Prefer not to disclose
                                                    <input type="radio" name="1" value="Prefer not to disclose"
                                                        onChange={(e) => this.setChecked('gender', e)}
                                                        checked={this.state.gender == 'Prefer not to disclose' ? true : false} />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5-px">
                                    <div className="col-md-4">
                                        <label>*Do You Smoke?</label>
                                        <p className="sh-input-no"><i>(All communities are non-smoking)</i></p>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="row">
                                            <div className="clearfix"></div>
                                            <div className="col-sm-3">
                                                <label className="custom_radio radio-inline">Yes
                                                    <input type="radio" name="2" value="yes"
                                                        onChange={(e) => this.setChecked('smoke', e)} />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                            <div className="col-sm-3">
                                                <label className="custom_radio radio-inline">No
                                                    <input type="radio" name="2" value="no"
                                                        onChange={(e) => this.setChecked('smoke', e)} />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5-px">
                                    <div className="col-md-4">
                                        <label>*Do you plan to bring a vehicle?</label>
                                        <p className="sh-input-no"><i>(Parking may be subject to additional fees and could be located off site.
                                            As a reminder parking fees are not covered by your Gilead Internship benefits.
                                            )</i></p>
                                    </div>


                                    <div className="col-md-8">
                                        <div className="row">
                                            <div className="clearfix"></div>
                                            <div className="col-sm-3">
                                                <label className="custom_radio radio-inline">Yes
                                                    <input type="radio" name="3" value="yes"
                                                        onChange={(e) => this.setChecked('vehicle', e)} />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                            <div className="col-sm-3">
                                                <label className="custom_radio radio-inline">No
                                                    <input type="radio" name="3" value="no"
                                                        onChange={(e) => this.setChecked('vehicle', e)} />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5-px">
                                    <div className="col-md-4">
                                        <label>*I would describe my room at home as</label>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="row">
                                            <div className="clearfix"></div>
                                            <div className="col-sm-3">
                                                <label className="custom_radio radio-inline">Neat & Tidy
                                                    <input type="radio" name="4" value="Neat & Tidy"
                                                        onChange={(e) => this.setChecked('neat', e)} />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                            <div className="col-sm-3">
                                                <label className="custom_radio radio-inline">Cluttered
                                                    <input type="radio" name="4" value="Cluttered"
                                                        onChange={(e) => this.setChecked('neat', e)} />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5-px">
                                    <div className="col-md-4">
                                        <label>Chosen Roommate Name (If Applicable)</label>
                                    </div>

                                    <div className="col-sm-4 col-md-4">
                                        <input type="text" className="form-control" placeholder="Chosen Roommate Name" value={this.state.chosenRoomMateName} onChange={(e) => this.setValue('chosenRoomMateName', e)} />
                                        <p className="sh-input-no"><i>(*Not guaranteed)</i></p>
                                    </div>


                                </div>






                                {/* <hr />
                                   <h1>Diversity & Inclusion</h1> */}


                                {/* <div className="row mt-5-px">
                                       <div className="col-md-4">
                                           <label>Do you identify as LGBTQIA+</label>
                                           <p className="sh-input-no"><i>(Please only answer if you feel comfortable and safe doing so)</i></p>
                                       </div>
                                       <div className="col-md-8">
                                           <div className="row">
                                               <div className="clearfix"></div>
                                               <div className="col-sm-3">
                                                   <label className="custom_radio radio-inline">Yes
                                                       <input type="radio" name="5" value="Yes"
                                                           onChange={(e) => this.setChecked('identifyAsLGBTQIA', e)} />
                                                       <span className="checkmark"></span>
                                                   </label>
                                               </div>
                                               <div className="col-sm-3">
                                                   <label className="custom_radio radio-inline">No
                                                       <input type="radio" name="5" value="No"
                                                           onChange={(e) => this.setChecked('identifyAsLGBTQIA', e)} />
                                                       <span className="checkmark"></span>
                                                   </label>
                                               </div>
                                               <div className="col-sm-6">
                                                   <label className="custom_radio radio-inline">I choose not to answer
                                                       <input type="radio" name="5" value="I choose not to answer"
                                                           onChange={(e) => this.setChecked('identifyAsLGBTQIA', e)} />
                                                       <span className="checkmark"></span>
                                                   </label>
                                               </div>
   
                                           </div>
                                       </div>
                                   </div> */}

                                {/* <div className="row mt-5-px">
                                       <div className="col-md-4">
                                           <label>Would you be able to be a supportive roommate to an LGTBQIA+ roommate</label>
                                       </div>
                                       <div className="col-md-8">
                                           <div className="row">
                                               <div className="clearfix"></div>
                                               <div className="col-sm-3">
                                                   <label className="custom_radio radio-inline">Yes
                                                       <input type="radio" name="6" value="yes"
                                                           onChange={(e) => this.setChecked('supportiveRoommate', e)} />
                                                       <span className="checkmark"></span>
                                                   </label>
                                               </div>
                                               <div className="col-sm-3">
                                                   <label className="custom_radio radio-inline">No
                                                       <input type="radio" name="6" value="no"
                                                           onChange={(e) => this.setChecked('supportiveRoommate', e)} />
                                                       <span className="checkmark"></span>
                                                   </label>
                                               </div>
   
                                           </div>
                                       </div>
                                   </div> */}

                                {/* <div className="row mt-5-px">
                                       <div className="col-md-4">
                                           <label>Please indicate the gender identity/ies of people you would be comfortable living with</label>
   
                                       </div>
                                       <div className="col-md-8">
                                           <div className="row">
                                               <div className="clearfix"></div>
                                               <div className="col-sm-3">
                                                   <label className="custom_radio radio-inline">Male
                                                       <input type="radio" name="7" value="Male"
                                                           onChange={(e) => this.setChecked('genderIdentity', e)}
                                                           checked={this.state.genderIdentity == "Male" ? true : false} />
                                                       <span className="checkmark"></span>
                                                   </label>
                                               </div>
                                               <div className="col-sm-3">
                                                   <label className="custom_radio radio-inline">Female
                                                       <input type="radio" name="7" value="Female"
                                                           onChange={(e) => this.setChecked('genderIdentity', e)}
                                                           checked={this.state.genderIdentity == "Female" ? true : false} />
                                                       <span className="checkmark"></span>
                                                   </label>
                                               </div>
   
                                               <div className="col-sm-6">
                                                  
                                                   <input type="text" className="form-control" placeholder="Write-In Option"
                                                       value={this.state.genderIdentitywritein}
                                                       onChange={(e) => this.handlegenderIdentity(e)} />
                                               </div>
                                           </div>
                                       </div>
                                   </div> */}

                                {/* <div className="row mt-5-px">
                                       <div className="col-md-4">
                                           <label>Are you interested in actively pursuing Gender Inclusive Housing </label>
                                           <p className="sh-input-no"><i>(rooming with a person regardless of their gender)</i></p>
                                       </div>
                                       <div className="col-md-8">
                                           <div className="row">
                                               <div className="clearfix"></div>
                                               <div className="col-sm-3">
                                                   <label className="custom_radio radio-inline">Yes
                                                       <input type="radio" name="8" value="yes"
                                                           onChange={(e) => this.setChecked('pursuingGender', e)} />
                                                       <span className="checkmark"></span>
                                                   </label>
                                               </div>
                                               <div className="col-sm-3">
                                                   <label className="custom_radio radio-inline">No
                                                       <input type="radio" name="8" value="no"
                                                           onChange={(e) => this.setChecked('pursuingGender', e)} />
                                                       <span className="checkmark"></span>
                                                   </label>
                                               </div>
   
                                           </div>
                                       </div>
                                   </div> */}

                                <div className="row mt-5-px">
                                    <div className="col-md-4">
                                        <label>*Would you be comfortable living with a roommate who has a service/assistance animal</label>

                                    </div>
                                    <div className="col-md-8">
                                        <div className="row">
                                            <div className="clearfix"></div>
                                            <div className="col-sm-3">
                                                <label className="custom_radio radio-inline">Yes
                                                    <input type="radio" name="9" value="yes"
                                                        onChange={(e) => this.setChecked('roommate', e)} />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                            <div className="col-sm-3">
                                                <label className="custom_radio radio-inline">No
                                                    <input type="radio" name="9" value="no"
                                                        onChange={(e) => this.setChecked('roommate', e)} />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>

                                        </div>
                                    </div>
                                </div>





                                <hr />
                                <h1>*Notes</h1>
                                <div className="row">
                                    <div className="col-sm-12 col-md-12">
                                        <textarea className="form-control" placeholder="If you have a disability or special accommodations required for consideration, please note here:" value={this.state.notes}
                                            onChange={(e) => this.setValue('notes', e)} rows="8" cols="5"></textarea>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-12 col-md-12">
                                        <ReCAPTCHA
                                            ref="recaptcha"
                                            sitekey="6Lcbw1AUAAAAAE5K5cYDyhoGu0RJY9C3CLtGFXAo"
                                            onChange={this.onChangeCaptcha}
                                        />
                                    </div>

                                </div>


                            </form>
                            {this.state.loader ?
                                <div className='text-center' style={{ fontSize: '25px' }}><i className="fa fa-spinner fa-spin" /> Please wait ...!</div>
                                :
                                <button type="button" className=" btn location_btn mrg-btm-70"
                                    onClick={this.serviceRequest}> SUBMIT</button>}
                        </div>
                    </div>
                </div>
                <div className="container ft-contact-adrs mrg-btm-30 mrg-top-30">
                    <NikeInternFooter />
                </div>
                <SubFooter />
                <Footer />
                <SuccessModal />
                <div className="modal fade intern-questionnaire" id="internship-confirmation" role="dialog">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content rm-border-radius">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="">
                                    <span>×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>By submitting an intern questionnaire, you understand that you are committing to being matched with a fellow intern to share a fully furnished apartment. SuiteAmerica will match you to the best of their abilities with other interns in the Gilead Intern Program and will then send you a rental agreement via DocuSign. Roommate matching and apartment rates are on a first come, first serve basis.
                                </p>
                                <h4>Community Rates :</h4>
                                {/* <div className="downtown-text-list">
                                     <h5>Downtown Communities</h5>
                                     <p><i>We offer two options to choose from in the downtown Portland area.</i></p>
                                     <p>1x1 (1 Occupant): $158 per person/day</p>
                                     <p>2x2 (2 Occupants): $96 per person/day</p>
                                     </div> */}
                                <div className="downtown-text-list">
                                    <h5>Suburb Communities</h5>
                                    <p><i>Beaverton/Hillsboro/Portland Suburb Communities</i></p>
                                    <p>1x1 (single or double occupancy): $150/day total</p>
                                    <p>2x2 (double or quad occupancy): $190/day total</p>
                                    <p>3x2 (single or multiple occupancy): $233/day total</p>
                                </div>
                                <p>
                                    SuiteAmerica will make every effort to match you based on your preferences listed on the intern questionnaire. <strong>Please note that these rates are subject to SuiteAmerica’s availability and once our apartments are full, we will not be able to place anymore interns.</strong> Therefore, it is recommended to send in your housing request as soon as possible.
                                </p>
                                <p><strong>Please Note: </strong> Once rental paperwork has been completed, you are obligated to the suite and move-in/move-out dates may not be altered.
                                </p>
                                <div className="terms-wrap">
                                    <h3>Have your read and agree to the terms?</h3>
                                    <div className="terms-form">

                                        <form role="form" method="post">
                                            <label className="custom_checkbox checkbox-inline"><span>Yes</span>
                                                <input name="Yes" type="radio" value="Yes"
                                                    onChange={(e) => this.selection(e)} />
                                                <span className="checkmark"></span>
                                            </label>
                                            <label className="custom_checkbox checkbox-inline"><span>No</span>
                                                <input name="No" type="radio" value="No"
                                                    onChange={(e) => this.selection(e)} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </form>
                                    </div>
                                </div>
                                <div className="clearfix"></div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default StripeInternQuestionnaire;