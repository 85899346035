"use strict";
import serviceBase from "./serviceBase";
const noticeToVacate = {
  noticeToVacateGuestDetails: (data) =>
    serviceBase.post("/noticeToVacate/api/NoticeToVacateGuestDetails", data),
  moveOutRequest: (data) =>
    serviceBase.post("/noticeToVacate/api/OrderConfirmMoveOut", data),
  requestToStay: (data) =>
    serviceBase.post("/noticeToVacate/api/OrderRequestToStay", data),
  iamMoveout: (data) =>
    serviceBase.post("/noticeToVacate/api/OrderRequestIamMovedOut", data),
};
export default noticeToVacate;
