'use strict'
import serviceBase from './serviceBase'
const clientApiService = {
    GetOrdersCountByClientEmailId:(data)=>serviceBase.post('/mysuiteamerica-client/api/GetOrdersCountByClientEmailId',data),
    getOrdersByClientEmailId:(data)=>serviceBase.post('/mysuiteamerica-client/api/GetOrdersByClientEmailId',data),
    getServicesByOrderUId:(data)=>serviceBase.post(`/mysuiteamerica-client/apis/GetServicesByOrderUId&&orderUId=${data.orderUId}`) ,
    getClientEmailDecrypt:(data)=>serviceBase.post(`/mysuiteamerica-client/apis/GetClientEmailDecrypt&&email=${data.email}`) ,
    clientUsersLogin:(data)=>serviceBase.post('/mysuiteamerica-client/api/ClientUsersLogin',data),
    saveClientRegister:(data)=>serviceBase.post('/mysuiteamerica-client/api/SaveClientRegister',data),
    ClientChangePassword:(data)=>serviceBase.post('/mysuiteamerica-client/api/ClientChangePassword',data),
    GetWebCategoryServicesInfo:(data)=>serviceBase.post(`/mysuiteamerica-client/apis/GetWebCategoryServicesInfo&&ClientId=${data.clientemail}&FromDate=${data.fromdate}&ToDate=${data.todate}`,{}),
    GetWebServicesInfo:(data)=>serviceBase.post(`/mysuiteamerica-client/apis/GetWebServicesInfo&&ClientId=${data.clientemail}&FromDate=${data.fromdate}&ToDate=${data.todate}`,{}),
    CommunityAnalysis:(data)=>serviceBase.post('/mysuiteamerica-client/api/CommunityAnalysis',data),
    ServiceAnalysis:(data)=>serviceBase.post('/mysuiteamerica-client/api/ServiceAnalysis',data),
    GetAllCESurveyInfo:(data)=>serviceBase.post('/mysuiteamerica-client/apis/GetAllCESurveyInfo',data),
    getInventoryByProgram:(data)=>serviceBase.post('/mysuiteamerica-client/apis/GetAvailabilityMastersByProgram&&program='+data.program),

    GetInternOrdersCountByClientEmailId: (data) => serviceBase.post('/mysuiteamerica-client/api/GetInternOrdersCountByClientEmailId', data),
    GetInternOrdersByClientEmailId: (data) => serviceBase.post('/mysuiteamerica-client/api/GetInternOrdersByClientEmailId', data),
    GetInternServicesByOrderUId: (data) => serviceBase.post(`/mysuiteamerica-client/apis/GetInternServicesByOrderUId&&orderUId=${data.orderUId}`),
}
export default clientApiService