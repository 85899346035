/* Importing the node modules, child components, services and controllers used 
   inside NikeHome component */    
   import $ from "jquery";
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   /* NikeHome Component initialization */
   class DeanBuildingA extends React.Component {
       /* Initializing objects of its NikeHome class */
       constructor(props) {
           super(props);
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
           MainController.getInitialInformation();
        }
       /* It is invoked to return html content */
       render() {
           return (
            <div className="corporateHousing_Updates telsa-page-view">
            <GuestHeader/>

            <div className="latch_web latch_webHeader">

    <div className="container">

      <h1 className="text-center m-t-40 m-b-30 p-b-10">The Dean Arrival Instructions - Building A</h1>

      <div className="latch_web2_sec borderred-box">
        <img src="/assets/newUI-images/latchimages/Latch2-1.png" alt="" width="100%" />
        <div className="item">
          <h2> Parking & Finding Elevators</h2>
          <p>Use the parking garage entrance located on San Antonio Road. The garage is to the right of the leasing
            office.
          </p>
          <p>We suggest parking on the first level in Future Guest Parking before you have your garage clicker, which
            is in your suite.
          </p>
          <p>Once parked in Future Guest Parking, follow the signs to the leasing office. Look for the <span
              className="green">green wall</span>. Head
            toward the wall, and you will find the main lobby doors that lead to the elevator and mailboxes.
          </p>
          <p>Here, you will use the Latch app to enter the building and use the elevator. To learn how to use Latch, go <a href="/latch" target="_blank">here</a>.
          </p>
        </div>
      </div>
      <div className="latch_web2_sec2">
        <div className="item">
          <p><i>Green wall that leads to Lobby Doors.</i></p>
          <div className="borderred-box">
            <img src="/assets/newUI-images/latchimages/Latch2-2.png" alt="" width="100%" />
          </div>
        </div>
        <div className="item">
          <p><i>Doors that lead to the building.</i></p>
          <div className="borderred-box">
            <img src="/assets/newUI-images/latchimages/Latch2-3.png" alt="" width="100%" />
          </div>
        </div>
      </div>

      <div className="latch_web_sec3 borderred-box m-b-30">
        <div className="item">
          <img src="/assets/newUI-images/latchimages/Latch2-4.png" alt="" width="100%" />
        </div>
        <div className="item">
          <h2>Finding Your Assigned Parking Space</h2>
          <p>Your garage clicker is located in your suite. Once you get into your suite, you will find it in the Welcome
            packet materials.
          </p>
          <p>This garage clicker will get you into level B2 in the parking garage. B2 parking is in same garage located
            on
            San Antonio Road.
          </p>
          <p>Once in that garage, continue through until you see the silver roll up gate. To enter, press and hold "1"
            on
            your clicker for about 5 seconds. Then, proceed to your assigned parking space.
          </p>
        </div>
      </div>





    </div>


  </div>
  </div>
           );
       }
   }
   export default DeanBuildingA;