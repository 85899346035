import ImageGallery from 'react-image-gallery';
class CommunityMoreInfo extends React.Component {

  constructor() {
      super();
  }

render(){
  const {complexImages,AmenitiesList,pdfLoading} = this.props;
  const urlArray = []
  let arrow = complexImages.length > 0 ?
  complexImages.map((galleyImage, galleyImageIndex) => {
    if(galleyImage.image_name != null){
      urlArray.push({"original":galleyImage.image_name,"thumbnail":galleyImage.image_name})
    }
     }) : '';
     console.log(arrow,'arr')
   let filterIMages = _.uniqBy(urlArray, 'original');
  
    return (  
    <div className="row">
     <div className="col-md-12 col-sm-12">
        <div className="row">
        <div className="col-md-4 col-sm-4">
        { filterIMages.length>0? 
       <ImageGallery items={filterIMages} showThumbnails={false}/>:''}

 </div>
 <div className="col-md-4 col-sm-4">
    {Object.keys(AmenitiesList).length > 0  &&  AmenitiesList.Community != undefined ? <h1>Community Amenities</h1>:''}
      {Object.keys(AmenitiesList).length > 0  &&  AmenitiesList.Community != undefined ? <ul className="comm-Amen">
      {
        AmenitiesList.Community.map((row, index) => {

          return (
            <li key={index}>
                <a value={row.aparment_amenity_id}><img width="40" src={s3BucketUrl+'Amenities/'+(row.aparment_amenity_name).replace(/[^A-Z0-9]+/ig, "")+'.png'} />{row.aparment_amenity_name}</a>
            </li>

          )
        })}
      </ul> : ''}
  
    </div>
    <div className="col-md-4 col-sm-4">
    {Object.keys(AmenitiesList).length > 0  &&  AmenitiesList.Unit != undefined ?<h1>Apartment Amenities</h1>:''}
      {Object.keys(AmenitiesList).length > 0  &&  AmenitiesList.Unit != undefined ? <ul className="comm-Amen">
      {AmenitiesList.Unit.map((row, index) => {
          return (
            <li key={index}>
              <a value={row.aparment_amenity_id}><img width="40" src={s3BucketUrl+'Amenities/'+(row.aparment_amenity_name).replace(/[^A-Z0-9]+/ig, "")+'.png'} />{row.aparment_amenity_name}</a>
            </li>

          )
        })}
      </ul> : ''}
    </div>
        </div>
      </div>
    
   
    
  </div>

               ) }
    }
export default CommunityMoreInfo;