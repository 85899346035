import React, { Component } from 'react';
import SessionService from "../../../../services/sessionService";
import GuestServicesapi from '../../../../services/guestServicesapi';
import MainController from '../../../controller/mainController';
import GuestController from '../../../controller/guestController';
import { withRouter } from "react-router-dom"
import ThirdPartyApiService from "../../../../services/thirdPartyApis";

class guestSidebar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            guestSessionResponse: {},
            userData: {},
            roleId: 0,
            hideMenu: false,
            loader: false,
            path:''
        }
        this.handleOpen = this.handleOpen.bind(this)
        this.handleClose = this.handleClose.bind(this)

    }

    async guest() {
        MainController.removeClassOnNavCollapse();
        const guestLoginResponse = await GuestController.guestLoginResponse();
        await this.setState({ userData: guestLoginResponse });
        var client = await SessionService.SessionGet({ type: 'client' });
        if (client.error == 0) {
            await this.setState({
                roleId: (client.roleId != undefined && Object.keys(client).length > 0) ? client.roleId : 0,
                ClientType: (client.ClientType != undefined && Object.keys(client).length > 0 ? client.ClientType : '')
            })
        }
        const GetResponse = await SessionService.SessionGet({ type: 'guest' });
        if (GetResponse.error == 0) {
            await this.setState({
                roleId: (GetResponse.roleId != undefined && Object.keys(GetResponse).length > 0) ? GetResponse.roleId : 0,
                ClientType: (GetResponse.ClientType != undefined && Object.keys(GetResponse).length > 0 ? GetResponse.ClientType : '')
            })
        }
        await this.setState({ guestSessionResponse: GetResponse });
        if (GetResponse.roleId == 2) {
            var guestEmailObj = {
                guestEmail: GetResponse.GuestEmail,
                status: "active"
            }
            const GetOrdersByGuestEmailResponse = await GuestServicesapi.GetOrdersByGuestEmail(guestEmailObj)
            if (GetOrdersByGuestEmailResponse.message.Orders.length == 0) {
                this.setState({ hideMenu: true })
            }
        }
    }



  async  componentDidMount() {
        this.guest()
        let path = window.location.pathname
       await this.setState({ path: path })
    //    $(document).ready(function () {
    //     $('#sidebarCollapse').on('click', function () {
            
    //     });
    // });

    }

    async logout() {
        this.setState({ loader: true })
        if (this.state.roleId == 3) {
            await GuestServicesapi.ClientUsersLogOut({ sessionId: localStorage.getItem('guestSession') })
        } else {
            await GuestServicesapi.GuestUsersLogOut({ sessionId: localStorage.getItem('guestSession') })
        }
        await localStorage.removeItem('guestSession');
        if (this.state.roleId == 2) {
            const userData = await SessionService.SessionDestory({ type: 'guest' });
            if (userData.error == 0) {
                await ThirdPartyApiService.logoutguest();
                location.href = '/guest-login'
            }
        }
        if (this.state.roleId == 3) {
            const userData = await SessionService.SessionDestory({ type: 'client' });
            if (userData.error == 0) {
                location.href = '/celogin'
            }
        }
        this.setState({ loader: false })
    }
    handleOpen(){
      $('#sidebar').toggleClass('active');
    }
    handleClose(){
      $('#sidebar').toggleClass('active').siblings().removeClass('checked');
    }
     render() {
        return (
          <div>
                <nav id="sidebar">
                         
                       <button type='button' className='btn closeSidebtn' onClick={this.handleClose}> <i className="bi bi-x"></i> </button>
                            
                      
                    <div className="sidebar-header">
                        <div className="user-icon">
                            <i className="bi bi-person-fill"></i>
                        </div>
                        <div className="heading">
                            <h2>Hello </h2>
                            <p>Let's get you started</p>
                        </div>
                    </div>

                    <ul className="list-unstyled components">

                        <li className={(this.state.path=="/reservations"|| this.state.path=="/my-reservations")?"active":''}>
                            <a href="/reservations">My Reservations</a>
                        </li>

                        <li className={this.state.path=="/your-location"?"active":''}>
                            <a href="/your-location">Explore Your Location</a>
                        </li>
                        <li className={this.state.path=="/service-request"?"active":''}>
                            <a href="/service-request">Service Request</a>
                        </li>
                        {/* <li className={this.state.path=="/moveout"?"active":''}>
                            <a href="/moveout">Moving Out</a>
                        </li> */}
                        <li>
                            <a href="javascript:void(0)" onClick={() => this.logout()}>Sign Out</a>
                        </li>
                    </ul>
                </nav>
                 <button type="button"  className="btn btn-info openbtn" onClick={this.handleOpen}>
                 <i className="bi bi-list"></i>
             </button>
</div>
                // {this.state.loader && <div className="logoutLoader"><Loader /></div>}
         
        )
    }
}

export default withRouter(guestSidebar)
