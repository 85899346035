/* Importing the node modules, child components, services and controllers used 
   inside ExecutiveStaff component */
   /* ExecutiveStaff Component initialization */
   class ExecutiveStaff extends React.Component{
       /* Initializing objects of its ExecutiveStaff class */
       constructor(props) {
           super(props)
           this.EmployeeDetails = this.EmployeeDetails.bind(this);
         }
       /* To get the employee details based on id */
       EmployeeDetails(id){
       this.props.EmployeeDetails(id);
       }
   /* It is invoked to return html content */	
   render(){
       const row=this.props.rowdata
       const index=this.props.index
       var valueWithName = ''+index+','+row.Name+'';
       var seperateValue= valueWithName.split(",")[0];       
       return(
        <div className="col-md-3 team_view" id="abc" style={{'cursor':'pointer'}} 
        onClick={() => this.EmployeeDetails(this.props.showView != index+','+row.Name  ? index+','+row.Name : -1)} >
            <div className="second_box staff_box">
            <div className="second_boxin">
                <div className="second_centerimage">
                <img src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Black-User-Male-Shape.png"} width="190" alt="" />
                </div>
            </div>
                <h2>{row.userName}</h2>
            <p>{row.Name}</p>
        </div>
        {this.props.showView == index+','+row.Name  ?<div className={seperateValue%2 ==1 ?"team_dtls team_dtls_last ":"team_dtls"}>
            <div className="third_box staff_box">
            <div className="second_boxin second_boxin2">
                <div className="second_centerimage">
                <img src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Black-User-Male-Shape.png"} width="190" alt="" />
                </div>
            </div>
            <div className="second_boxview second_boxin2">
                <div className="third_boxin">
                    <h1>{row.userName}</h1>
                    <h2>{row.Name}</h2>
                    <div>
                    <p>Ph : <span>{row.PhoneNumber}</span></p>
                     <p>E : <span><a >{row.Email}</a></span></p>
                    </div>
                    <div className="paddingtop">
                        <p>Team : <span> {row.teams}</span></p>
                        <p>Office : <span> {row.office}</span></p>		
                        <p>Coverage : <span> {row.coverage_area}</span></p>
                        <p>Employee Since : <span></span></p>
                    </div>
                </div>
                <a onClick={() => this.EmployeeDetails(-1)}  className="team_dtls_hide"><i className="fa fa-close"></i></a>
            </div>	
            </div>
        </div> : null
        }
        </div>
   
   );
   }
   }
   export default ExecutiveStaff;
   
   