const FloorModal=(props)=>{

return(

    <div className="modal " id="floorview" tabIndex="-1"  role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog" role="document">
      <div className="modal-content animated zoomIn">
        <div className="modal-header">
        {props.floorObject !=undefined ?
        <div className="floorexpandheader">
        
                <div className="item">{props.floorObject.plan} </div>
                <div className="item">{props.floorObject.name} </div>
                <div className="item">{props.floorObject.squareFeet} </div>
            </div>:''}
          <button type="button" className="close btn" data-dismiss="modal" aria-label="Close">
            
          <i className="bi bi-x"></i>
          </button>
        </div>
        <div className="modal-body">
        <img src={props.floorObject.image} />
        </div>
  
      </div>
    </div>
  </div>
)
}
export default FloorModal;