const SecondRow = props => {
    const requestDetails = props.requestDetails
    const mapIndex = localStorage.getItem('requestFormId');
    return (
        <div>
        {!props.serviceRequest?'':(requestDetails.latitude=='' || requestDetails.latitude==null ||  requestDetails.latitude==undefined)? '':
        //map View Display
        <div
        style={{'width': '100%','height': '500px'}}>
        <div id={'map'+mapIndex} style={{'width': '100%','height': '500px'}}></div>
        </div>}
        {/* Second Row */}
        <div className="sr_form_wraper mrg-top-40">
        <div className="qwer1">
           <h1>DRIVER:</h1>
           <p>{props.driverManagerInfo.Identifier}</p>
        </div>
        <div className="qwer1">
           <h1>STARTED:</h1>
           <p>{requestDetails.StartTime == null ? '---' : moment.utc(requestDetails.StartTime).format('hh:mm A')}</p>
        </div>
        {!props.serviceRequest?'':<div className="qwer1">
           <h1>SERVICE TYPE:</h1>
           <p>{requestDetails.OrderType=='H'? requestDetails.TypeCode:requestDetails.TypeCode + ' ' + requestDetails.SuiteSizeCode}</p>
        </div>}
        <div className="qwer1">
           <h1>ORDER #</h1>
           <p>{requestDetails.TableId == null ? '---' : requestDetails.TableId}</p>
        </div>
        <div className="qwer1">
           <h1>MASTER #</h1>
           <p>{requestDetails.mastertableId == null ? '---' : requestDetails.mastertableId}</p>
        </div>
     </div>
     </div>
    );
  };

  export default SecondRow;