import {Link} from 'react-router-dom';
import $ from 'jquery';
import DestinationService from '../../../services/destinationService';
import moment from 'moment'
import appController from '../../controller/appController';
class AppleLanding extends React.Component {
      constructor(props){
        super(props);
        this.state ={
          headerDetails: [],
          propertyDetails: [],
          length:'',
          notesArray:[],
          starRating:[],
          errorMessage:'',
          successMessage:'',
          //CaptchaValue: ''
          submitloader:false
       }
       this.propertyIds = this.propertyIds.bind(this);
       this.setCheck = this.setCheck.bind(this);
       this.clear = this.clear.bind(this);
       this.addStarNotes = this.addStarNotes.bind(this);  
       this.moveItinerary = this.moveItinerary.bind(this);
      }
      async componentWillMount(){
        await this.InitialData()
      }
    /*  async onChangeCaptcha(CaptchaValue) {
         await this.setState({ CaptchaValue })
       }  */
      componentDidMount(){   
        $(document).scroll(function() {
          var y = $(this).scrollTop();
          if (y > 1250) {
          $('.itineraryBlock').fadeIn();
          } else {
          $('.itineraryBlock').fadeOut();
          }
        });
      }
      propertyIds(SortOrder){
        localStorage.setItem('SortOrder', SortOrder)
        localStorage.setItem('length', this.state.length)
      }
      async clear(){        
        for (var i = 0; i < this.state.propertyDetails.length; i++) {
          ($('#text'+i).val(''))
          const rating = 'rating' + i; 
          $('input[name='+rating+']').prop('checked', false);
         }
         await this.setState({starRating:''})
       }

      async InitialData(){
        const propertyData = this.props.itineraryData;
        const propertyDetails = await DestinationService.getDetails(propertyData);
        const headerDetails = await DestinationService.headerDetails(propertyData);
        await this.setState({propertyDetails: propertyDetails.recordset,headerDetails:headerDetails.recordset,length:propertyDetails.recordset.length})
      }
      async addStarNotes(){
        const stars = _.orderBy(this.state.starRating,['index'],['asc'])
           var notesArray=[];
           for (var i = 0; i < this.state.propertyDetails.length; i++) {
             if($('#text'+i).val() != '')
             {
               notesArray.push($('#text'+i).val())
             } 
             else{
               notesArray.push("")
             }
           } 
           const value = notesArray.indexOf("")           
           if( value == -1 && this.state.starRating.length == this.state.propertyDetails.length){
            await this.setState({submitloader:true})
            const update ={
                itineraryId:this.state.propertyDetails[0].ItineraryID,
                FileNum:this.state.propertyDetails[0].FileNum,
                notes:notesArray,
                rating:stars,
                employeeName:this.state.headerDetails[0].EmpFirstName + " "+this.state.headerDetails[0].EmpLastName,
                address:this.state.headerDetails[0].City +", " + this.state.headerDetails[0].State,
                to:this.state.headerDetails[0].DSPUserEmail,
                type:'group',
                companyName:this.props.itineraryData.companyName,
               }
               
               await this.setState({errorMessage:'',successMessage:'Notes Successfully Added'})
               await DestinationService.updateRating(update)
             
               const _this=this
               setTimeout(async function() {
                for (var j = 0; j < this.state.propertyDetails.length; j++) {
                  ($('#text'+j).val(''))
                  const rating = 'rating' + j; 
                  $('input[name='+rating+']').prop('checked', false);
                 }
                  await _this.setState({successMessage:'',starRating:'',submitloader:false})
                  }.bind(this), 4000);  
              }
              else{
                this.setState({errorMessage:'Please Enter Notes/Rating',successMessage:''})
              }
          }
          async setCheck(index,PropertyID,Community,e){
            let data;
            const indexData = _.findIndex(this.state.starRating, function(o) { return o.index == index; })
            if(indexData<0){
              data = _.concat(this.state.starRating,{rangeVa:e.target.value, index: index,PropertyID: PropertyID,Community:Community});
            }else{
              data = update(this.state.starRating, { [index]: {rangeVa: {$set: e.target.value} , index: {$set: index},PropertyID:{$set:PropertyID},Community: {$set: Community}}});
            }    
            await this.setState({starRating: data});
          }
          moveItinerary() {
             appController.menuTopNavigation("p01");
          }

    render(){
        const headerDetail = this.state.headerDetails[0]
       return <div className="page-wrapper">
        <a className="itineraryBlock" onClick={this.moveItinerary}><img src="https://www.suiteamerica.com/images/icons/icon-paper-white.png" alt="" /><br />Itinerary</a>
   <div className="intro-info">
      <h1 className="title-first">Welcome To {headerDetail != undefined ? headerDetail.label.replace(' Tour','') :   ''}</h1>
      <center>
         <img className="sun-skyline" src="https://s3-us-west-1.amazonaws.com/suiteam-website/Destination-Services/SuiteAmerica-Destination-Services-Apple-SouthBay.jpg" alt="" />
      </center>
      <div className="firstname-lastname">
         <center>{headerDetail != undefined ? headerDetail.EmpFirstName :   ''} {headerDetail != undefined ? headerDetail.EmpLastName :   ''}</center>
      </div>
     
      <div className="itinerary-city-state">
         Itinerary for {headerDetail != undefined ? headerDetail.label.replace(' Tour','') :   ''}, {headerDetail != undefined ? headerDetail.State :   ''}
      </div>
    
      <div className="month-day-year">
      {headerDetail != undefined ? moment.utc(headerDetail.ServiceDate).format('MMMM Do YYYY'): ''}
      </div>
      
   </div>
   
   <div className="column-wrapper">
      <div className="column-left itinerary-top">
         <a name="itinerary"></a>
         <div className="itinerary-data">
            <div className="calendarApple">
               <div className="c-month"><strong>{headerDetail != undefined ? moment.utc(headerDetail.ServiceDate).format('MMMM'): ''}</strong></div>
             
               <div className="c-day">{headerDetail != undefined ? moment.utc(headerDetail.ServiceDate).format('DD'): ''}</div>
               
            </div>
            
            <span className="name">{headerDetail != undefined ? headerDetail.EmpFirstName :   ''} {headerDetail != undefined ? headerDetail.EmpLastName :   ''}</span>
            Welcome to {headerDetail != undefined ? headerDetail.label.replace(' Tour','') :   ''}, {headerDetail != undefined ? headerDetail.State :   ''}
            <br/><br/>
            <div className="itinerary-schedule">
               <h2>Your Itinerary For Today</h2>
               <div className="time-property"><a><span className="time">{headerDetail != undefined ? (moment.utc(headerDetail.MeetupTime).format('hh:mm A')):''}</span> <span className="property">General meet and greet &amp; view property map</span></a></div>
               {this.state.propertyDetails.map((row,index) =>{ 
               return <Link key={index} to={'/destination-service/itinerary/property/'+ this.props.itineraryData.companyName+ '/' + this.props.itineraryData.FileNum+'/'+this.props.itineraryData.itineraryId} 
               onClick={() => this.propertyIds(row.SortOrder)}>
                  <div className="time-property">
                     <span className="time">{moment.utc(row.time).format('hh:mm A')}</span>
                     <span className="property">{row.Community}</span>
                  </div>
               </Link>
    })
}               
              
            </div>
          
            <div className="consultant-info">
               <div className="c-column">
                  <h2>Destination Consultant</h2>
                  <span className="dc-name">{headerDetail != undefined && (headerDetail.DSP == null ? 'NA':headerDetail.DSP)}</span>
                  <span className="dc-title">{headerDetail != undefined && (headerDetail.DSPUserTitle == null ? 'NA':headerDetail.DSPUserTitle)}</span><br/>
                  <a className="dc-phone" href={"tel:"+(headerDetail != undefined ? headerDetail.DSPUserPhone :   '')}><img className="icons phone" src="https://s3-us-west-1.amazonaws.com/suiteam-website/Destination-Services/SuiteAmerica-Destination-Services-Apple-Icon-Phone.png"/>{headerDetail != undefined ? headerDetail.DSPUserPhone :   ''}</a>
                  <a className="dc-email" href={"mailto:"+(headerDetail != undefined ? headerDetail.DSPUserEmail :   '')}><img className="icons email" src="https://s3-us-west-1.amazonaws.com/suiteam-website/Destination-Services/SuiteAmerica-Destination-Services-Apple-Icon-Email.png"/>{headerDetail != undefined ? headerDetail.DSPUserEmail :   ''}</a>
               </div>
              
               <div className="c-column">
                  <h2>Sirva Contact</h2>
                  <span className="dc-name">{headerDetail != undefined && (headerDetail.ReloUserName == null ? 'NA':headerDetail.ReloUserName)}</span>
                  <span className="dc-title">{headerDetail != undefined && headerDetail.ReloUserTitle ==null ? 'NA':headerDetail.ReloUserTitle}</span><br/>
                  <a className="dc-phone" href={"tel:"+(headerDetail != undefined&&headerDetail.ReloUserPhone == null ? 'NA' :headerDetail.ReloUserPhone)}><img className="icons phone" src="https://s3-us-west-1.amazonaws.com/suiteam-website/Destination-Services/SuiteAmerica-Destination-Services-Apple-Icon-Phone.png"/>{headerDetail != undefined && headerDetail.ReloUserPhone == null ? 'NA' :headerDetail.ReloUserPhone}</a>
                  <a className="dc-email" href={"mailto:"+(headerDetail != undefined && headerDetail.ReloUserEmail == null ? 'NA':headerDetail.ReloUserEmail)}><img className="icons email" src="https://s3-us-west-1.amazonaws.com/suiteam-website/Destination-Services/SuiteAmerica-Destination-Services-Apple-Icon-Email.png"/>{headerDetail != undefined && headerDetail.ReloUserEmail == null ? 'NA':headerDetail.ReloUserEmail}</a>
               </div>
              
            </div>
            
            <div className="clear"></div>
           
         </div>
         
      </div>
      
      <div className="column-right itinerary-top">
         {this.state.propertyDetails.slice(0,2).map((row,index) =>{   
          
          return <Link key={index} to={'/destination-service/itinerary/property/'+this.props.itineraryData.companyName+'/'+ this.props.itineraryData.FileNum+'/'+this.props.itineraryData.itineraryId} onClick={() => this.propertyIds(row.SortOrder)}>
            <div className="property-img-wrapper" id="p01" style={{backgroundImage:'url('+'https://s3-us-west-1.amazonaws.com/destination-services-itinerary/'+row.Photo+'.jpg' +')'}}>
               <div className="property-details">
                  <div className="property-number"><span>{row.SortOrder}</span></div>
                  <span className="property-name">{row.Community}</span>
                  <div className="p-time">{moment.utc(row.time).format('hh:mm A')}</div>
                  <div className="p-address">{row.Address}, {row.City} {row.StateCode} {row.ZipCode}</div>
               </div>
               
            </div>
            
        </Link>
         })
        }
         
      </div>
      
   </div>
  
   <div className="clear"></div>
  
   <div className="column-wrapper nomargin">
   {this.state.propertyDetails.slice(2).map((row,index) =>{ 
      const img = 'https://s3-us-west-1.amazonaws.com/destination-services-itinerary/'+row.Photo+'.jpg';
     return <div key={index} className={index%2==0? "column-left" : "column-right"}>
        <Link key={index} to={'/destination-service/itinerary/property/'+this.props.itineraryData.companyName+'/'+ this.props.itineraryData.FileNum+'/'+this.props.itineraryData.itineraryId} onClick={() => this.propertyIds(row.SortOrder)}>
        <div className="property-img-wrapper" id="p01" style={{backgroundImage:'url('+ img +')'}}>
           <div className="property-details">
              <div className="property-number"><span>{row.SortOrder}</span></div>
              <span className="property-name">{row.Community}</span>
              <div className="p-time">{moment.utc(row.time).format('hh:mm A')}</div>
              <div className="p-address">{row.Address}, {row.City} {row.StateCode} {row.ZipCode}</div>
           </div>
           
        </div>
        
     </Link>
      </div>
     
   })
}
      <div className="clear"></div>
     
   </div>
 
   <a name="survey"></a>
   <div className="form-wrapper">
      <form name="property-rating" id="property-rating" action="https://www.suiteamerica.com/itineraries/DB/itinerary-Apple/_email_results.cfm?ItineraryID=2068" method="post">
      {this.state.propertyDetails.map((row,index) =>{   
         const img = 'https://s3-us-west-1.amazonaws.com/destination-services-itinerary/'+row.Photo+'.jpg';
      return<div key={index} className="form-input-section">
           <Link key={index} to={'/destination-service/itinerary/property/'+this.props.itineraryData.companyName+'/'+ this.props.itineraryData.FileNum+'/'+this.props.itineraryData.itineraryId} onClick={() => this.propertyIds(row.SortOrder)}>
               <div className="form-property-details">
                  <div className="property-number"><span>{row.SortOrder}</span></div>
                  <div className="form-property-img-wrapper" style={{backgroundImage:'url( '+ img +')'}}></div>
                  <span className="form-property-name">{row.Community}</span>
               </div>
             
            </Link>
            <div className="ratings">
            <textarea name="" id={'text'+index} placeholder="Notes"></textarea>
               <div className="rating-radio-buttons">
               <div>
               <input name={'rating'+index}  id={'rating'+index} type="radio" value="1" onChange={(e) => this.setCheck(index,row.PropertyID,row.Community, e)}/><label>1</label>
               <input name={'rating'+index}  id={'rating'+index} type="radio" value="2" onChange={(e) => this.setCheck(index,row.PropertyID,row.Community, e)}/><label>2</label>
               <input name={'rating'+index}  id={'rating'+index} type="radio" value="3" onChange={(e) => this.setCheck(index,row.PropertyID,row.Community, e)}/><label>3</label>
               <input name={'rating'+index}  id={'rating'+index} type="radio" value="4" onChange={(e) => this.setCheck(index,row.PropertyID,row.Community, e)}/><label>4</label>
               <input name={'rating'+index}  id={'rating'+index} type="radio" value="5" onChange={(e) => this.setCheck(index,row.PropertyID,row.Community, e)}/><label>5</label>
                 </div>  
               </div>
            </div>
         </div>
        })
    }
    <center>
                                        {/* <ReCAPTCHA
                                            ref={ ref => this.recaptcha = ref }
                                            sitekey="6Lcbw1AUAAAAAE5K5cYDyhoGu0RJY9C3CLtGFXAo"
                                            onChange={this.onChangeCaptcha}
                                            /> */}
                                            </center>
         <center>
            <input type="button" onClick={this.clear} name="clear" defaultValue="Clear" className="blueButton"  />
            {this.state.submitloader?
        <div  className="blueButton text-uppercase">
        <span className="btn-pleaseWait"><i className="fa fa-spinner" aria-hidden="true"></i> Please Wait</span>
        </div>:
            <input type="button" onClick={this.addStarNotes} name="submit" defaultValue="Submit" className="blueButton" />
    }
            <div style={{'color': '#ff0000','marginTop':'15px'}}>{this.state.errorMessage}</div>
          <div style={{'color': 'green','marginTop':'15px'}}>{this.state.successMessage}</div>
         </center>
         <input type="hidden" name="formfield1234567891" id="formfield1234567891" defaultValue="427"/>
         <input type="hidden" name="formfield1234567892" id="formfield1234567892" defaultValue=""/>
         <input type="hidden" name="formfield1234567893" defaultValue="39920422,19744851"/>
         <span style={{display:"none"}}>Leave this field empty <input type="text" name="formfield1234567894" defaultValue="" /></span>
      </form>
   </div>
   
</div>


        
    }
}
 export default AppleLanding