/* Importing the node modules, child components, services and controllers used 
   inside NikeHome component */    
   import $ from "jquery";
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   /* NikeHome Component initialization */
   class LatchHome extends React.Component {
       /* Initializing objects of its NikeHome class */
       constructor(props) {
           super(props);
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
           MainController.getInitialInformation();
        }
       /* It is invoked to return html content */
       render() {
           return (
            <div className="corporateHousing_Updates telsa-page-view">
            <GuestHeader/>

            <div className="latch_web latch_webHeader">

    <div className="container">

      <div className="latch_web_sec1">

        <div className="image">
          <img src="/assets/newUI-images/latchimages/Latch-1.png" alt="" width="100%" />

        </div>
        <div className="item">
          <h1> Welcome to <img src="/assets/newUI-images/latchimages/latchLOGO.png" alt="" width="100px" /></h1>
          <p>Your community uses the Latch app for remote, keyless entry. Latch will give you access to
            buildings, amenities, and elevators, and will unlock the door to your suite.
          </p>

        </div>

      </div>

      <div className="latch_web_sec2 borderred-box">


        <div className="item"  style={{paddingLeft:"60px"}}>
          <h2>Activating the Latch app</h2>
          <ul>
          <li>Expect an email directly from Latch (not SuiteAmerica) for electronic key registration.</li>

          <li>Register before your arrival date (check your junk email if not in your inbox).</li>

          <li>If traveling internationally, wait until you're in the U.S. to download the Latch app.</li>

          <li><b>Crucial:</b> Log out after downloading and registering. When you arrive, log in to see your 'keys.' Do not skip this step.</li>
            {/* <li> You will be receiving an email directly from Latch (not from SuiteAmerica) on how to register for access to your
              electronic keys that you will need to enter the building upon arrival.
            </li>
            <li>Complete this registration prior to your arrival date (check your junk email folder if you don't see an
              email from Latch in your in-box).
            </li>
            <li>If you are traveling internationally, you may need to wait until you are in the U.S. to download the Latch
              app.
            </li>
            <li> <b> Important: </b> You <u>MUST</u> log out of the Latch app after downloading and registering. Then, when you
              arrive at your community, <u>log back into the app</u>, and your 'keys' will appear. DO <u>NOT</u> miss this step.
            </li> */}
          </ul>

        </div>
        <div className="item">
          <img src="/assets/newUI-images/latchimages/Latch-2.png" alt="" width="100%" />
        </div>

      </div>
      <div className="latch_web_sec3 borderred-box">
        <div className="item">
          <img src="/assets/newUI-images/latchimages/Latch-3.png" alt="" width="100%" />
        </div>
        <div className="item"  style={{paddingLeft:"60px"}}>
          <h2>Unlocking a Door with the Latch app</h2>
          {/* <p>When approaching a door, including your suite, follow these steps to unlock it:</p> */}
          <ul>
          <li>Open the Latch app on your phone.</li>

          <li>Hold your phone to the Latch reader on the door, keeping it facing you. The blue bar at the top will turn green, and dots will connect as your phone syncs with the reader.</li>

          <li>Once the bar is green and the dots connect, the door is unlocked, and you can proceed.</li>


            {/* <li>Open the Latch app on your phone.</li>
            <li>Hold your phone up to the Latch reader on the door. Keep your phone facing you. There is a bar at the top of the app that will be blue at first.
            </li>
            <li>As your phone syncs with the Latch reader, the blue bar will turn green, and dots will connect on the
              reader. This means the door is unlocked, and you may proceed through!
            </li> */}
          </ul>
          {/* <p><i> Important: The first time you use Latch, it may take up to 90 seconds to sync.</i>
          </p> */}
          <p><i><b>Note:</b> The first time you use Latch, it may take up to 90 seconds to sync.</i>
          </p>
        </div>
      </div>
      <div className="latch_web_sec3 latch_web_sec4  borderred-box">

        <div className="item"  style={{paddingLeft:"60px"}}>
          <h2>Using Latch on an Elevator</h2>
          {/* <p> Remember, you will use Latch to use the elevator. it's the same process as opening a door. You must use
            Latch in order to select your floor number. Once on the elevator, follow these steps to access your floor.
          </p> */}
          <ul>
          <li>Use the Latch app on your phone.</li>

          <li>Hold your phone near the Latch sensor.</li>

          <li>Wait for the 'unlocked' signal and sensor dots to connect.</li>

          <li>Select your floor number.</li>


            {/* <li>Open the Latch app on your phone.</li>
            <li>Hold the phone up the Latch sensor.
            </li>
            <li>Once your phone syncs to the sensor, you will hear an 'unlocked' signal, and the dots on the sensor will
              connect.
            </li>
            <li>Then, you may select your floor number.</li> */}
          </ul>
          {/* <p><i> Important: Unlocking the elevator may take up to 5 seconds, and you must hear the 'unlocked' signal
              before entering your floor number.</i>
          </p> */}
          <p><i> <b>Note:</b> Wait up to 5 seconds for the 'unlocked' signal before choosing your floor.</i>
          </p>
        </div>

        <div className="item">
          <img src="/assets/newUI-images/latchimages/Latch-4.png" alt="" width="100%" />
        </div>
      </div>





    </div>
    <div className="container-fluid nike guest_sitenew">
                  <div className="container intra_staff2 col-sm-8 col-sm-offset-2">
                     <h2 className="text-center company-title">FREQUENTLY ASKED QUESTIONS</h2>
                     <div id="accordion" className="panel-group mrg-btm-90 company_acdn" role="tablist" aria-multiselectable="true">
                        {/* {fqs.map((row, index) => {
                        return ( */}
                        <div className="panel panel-default" key='1'>
                           <div className="panel-heading">
                              <h4 className="panel-title">
                                 <a className="collapsed"
                                    data-toggle="collapse"
                                    data-parent="#accordion"
                                    href='#collapse1'
                                    aria-expanded="false"
                                    aria-controls='1'
                                    >What do I do if I am having trouble accessing my latch account or it isn’t working properly?</a>
                              </h4>
                           </div>
                           <div id='collapse1'
                              className="panel-collapse collapse">
                              <div className="panel-body">
                                 <div className="profile-opener">
                                   <ul>
                                <li>Please make sure to check that your Bluetooth is turned on, that you are signed into the LATCH app, and holding your phone within a few inches of the LATCH reader (please note that it may take anywhere from 30-90 seconds to engage initially, and you will need to hold the phone near the reader for that time).</li><br/>
                                 <li>If you are experiencing any issues opening doors, please go to your settings in your phone, application manager, and ensure that the application is up to date. If that doesn’t work, try logging out and back in and trying again. You may need to “force quit” the Latch app and re-open the app.</li><br/>
                                 <li>If you are still having issues, you may try uninstalling and reinstalling the application.</li><br/>
                                <li>If you have tried all steps above and are still experiencing difficulties connecting to the doors, please reach out to us and we will try additional troubleshooting steps with you.</li><br/>
                                </ul>
                                 </div>
                              </div>
                           </div>
                        </div>


                        <div className="panel panel-default" key='2'>
                           <div className="panel-heading">
                              <h4 className="panel-title">
                                 <a className="collapsed"
                                    data-toggle="collapse"
                                    data-parent="#accordion"
                                    href='#collapse2'
                                    aria-expanded="false"
                                    aria-controls='2'
                                    >How do I update my door on the Latch app?</a>
                              </h4>
                           </div>
                           <div id='collapse2'
                              className="panel-collapse collapse">
                              <div className="panel-body">
                                 <div className="profile-opener">
                                   <ul>
                                 <li>On the “My Doors” page, tap on the door that you would like to update. A few lines below the door name, click the blue “update” text. This takes you to the next page which has a blue “update” button and shows the last time and date that the door has been updated. Once the update has gone through, there will be a green checkmark in a circle.</li>
                                 </ul>
                                 </div>
                              </div>
                           </div>
                        </div>


                        <div className="panel panel-default" key='3'>
                           <div className="panel-heading">
                              <h4 className="panel-title">
                                 <a className="collapsed"
                                    data-toggle="collapse"
                                    data-parent="#accordion"
                                    href={'#collapse3'}
                                    aria-expanded="false"
                                    aria-controls='3'
                                    >How do I let a guest in from Latch?</a>
                              </h4>
                           </div>
                           <div id='collapse3'
                              className="panel-collapse collapse">
                              <div className="panel-body">
                                 <div className="profile-opener">
                                   <ul>
                               <li>Select “Let Someone In” on your homepage and select the type of access you would like to give them. Options include granting guests a code for either 15 mins or a full day, or the option to download Latch for a more specific window of entry.</li>
                               </ul>
                                 </div>
                              </div>
                           </div>
                        </div>


                        <div className="panel panel-default" key='4'>
                           <div className="panel-heading">
                              <h4 className="panel-title">
                                 <a className="collapsed"
                                    data-toggle="collapse"
                                    data-parent="#accordion"
                                    href='#collapse4'
                                    aria-expanded="false"
                                    aria-controls='4'
                                    >Other ways to unlock your door with Latch</a>
                              </h4>
                           </div>
                           <div id='collapse4'
                              className="panel-collapse collapse">
                              <div className="panel-body">
                                 <div className="profile-opener">
                                <ul>
                                <li>Swipe up to your Door List and tap the Door you want to unlock.</li><br/>
                                <li>You can also enter a 7-digit Doorcode. You will find your Doorcodes in the left menu of the Latch App.  Tap the center of the black Latch Lens on the device and enter your Doorcode.</li>
                                </ul>
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div className="panel panel-default" key='5'>
                           <div className="panel-heading">
                              <h4 className="panel-title">
                                 <a className="collapsed"
                                    data-toggle="collapse"
                                    data-parent="#accordion"
                                    href='#collapse5'
                                    aria-expanded="false"
                                    aria-controls='5'
                                    >What if my LATCH battery is running low?</a>
                              </h4>
                           </div>
                           <div id='collapse5'
                              className="panel-collapse collapse">
                              <div className="panel-body">
                                 <div className="profile-opener">
                                 <ul>
                                 <li>The LATCH is battery powered and needs to be changed about every 12 months. When the LATCH battery is running low, our team will reach out to you for Permission to Enter your suite, as the community maintenance team will need to access your door from inside the suite. To change the batteries only takes a few minutes. If our team reaches out to you with this the request, please respond as soon as possible because if the LATCH batteries run out, this will create a lock out of your suite.</li>
                                 </ul>
                                 </div>
                              </div>
                           </div>
                        </div>

                        {/* )
                        })} */}
                     </div>
                  </div>
               </div>

  </div> 
  <SubFooter/>
  <Footer/>
  </div>
           );
       }
   }
   export default LatchHome;