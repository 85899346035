/* Importing the node modules, child components, services and controllers used 
   inside IntranetCustomRequestfive component */   
   import StarbucksBookingHeader from './starbucksBookingHeader';
   import TravelBookingFooter from '../../common/travelBookingFooter';
   /* IntranetCustomRequestfive Component initialization */
   class SuccessPage extends React.Component{
       /* Initializing objects of its IntranetCustomRequestfive class */
       constructor(props) {
           super(props)
         }
   /* It is invoked to return html content */      
   render(){
     
       const url=this.props.match.path
       return(
           <div>
         <StarbucksBookingHeader/>
<div className="inet_custom_last">
{url=='/starbucks-travel-guestsuccess'? <div className="container">
   <h1 className="">Thank you for selecting a Corporate Apartment.</h1>
   <p className="">The Starbucks Travel Extended Stay Desk will be in contact with you shortly to finalize your Corporate Apartment reservation.</p>
</div>:''}
{url=='/starbucks-travel-adminsuccess'? <div className="container">
<h1 className="">Thank you for booking a Suite</h1>
{/* <p className="">The Apple Travel Extended Stay Desk will be in contact with you shortly to finalize your Corporate Apartment reservation.</p> */}
</div>:''}
{url=='/starbucks-travel-alreadysent' ? <div className="container">
   <h1 className="">Thank you, your suite has been already placed.</h1>
   <p className="">Please check your mail or Phone for confirmation.</p>
</div>:''}
{url=='/starbucks-travel-quote' ? <div className="container">
   <h1 className="">Thank you, Your Suite Request is placed.</h1>
   <p className="">You will receive the confirmation of your stay shortly!</p>
</div>:''}
{url=='/starbucks-travel-dateexpire' ? <div className="container">
<h1 className="">Booking invitation is expired</h1>
<p className="">Please contact starbuckstravel@apple.com for any assistance.</p>
</div>:''}
</div>
<TravelBookingFooter/>
</div> 
       );
   }
   }
   export default SuccessPage;