/* Importing the node modules, child components, services and controllers used 
   inside NikeInternshipHeader component */
   import {Link} from 'react-router-dom';
   import $ from "jquery";
   import MainController from '../../../controller/mainController';
  
   /* NikeInternshipHeader Component initialization */
   class StripeInternshipHeader extends React.Component {
       /* Initializing objects of its NikeInternshipHeader class */
       constructor(props) {
           super(props)
           /* NikeInternshipHeader Component State variables Initialization */
           this.state = {
               name: '',
               company: '',
               location: '',
               phone: '',
               email: '',
               bookingDetails: {},
               BookingData: {}
           }   
          // this.selection = this.selection.bind(this);
           this.internQuestionnaire = this.internQuestionnaire.bind(this);
       }
       /* It is invoked immediately after a component is mounted */
       componentDidMount() {
           MainController.removeClassOnNavCollapse();
       }
       /* To uncheck the input fields type radio */
       internQuestionnaire(){
        $('input[name="Yes"]').prop('checked', false);
        $('input[name="No"]').prop('checked', false);
       }
       /* Condition checking for whether you want to proceed or not */
    //    selection(e){
    //         if(e.target.value == 'Yes'){
    //             location.href = '/company/nike-internship/questionnaire';
    //         }else{
    //             jQuery(function ($) {
    //                 setTimeout(function () {
    //                     $('#internship-confirmation').modal('hide');
    //                 }.bind(this), 500);
    //             }.bind(this));
    //         }
    //    }
       /* It is invoked to return html content */  
       render() {
           return (
            <div>
            <div className="profile_submenu stripe_view">
                <div className="container">
                    <nav className="navbar navbar-inverse">
                        <div className="navbar-header">
                            <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#guest-login" aria-expanded="false">
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                         
                            <a href="https://gilead.com" target="_blank">
                                {/* <img src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Company-Nike-Logo.png"} alt="" className="nike_list_img_mo" /> */}
                                <img src="https://www.gilead.com/-/media/gilead-corporate/images/global/gilead-create-possible-svg.svg?iar=0&hash=4A8C3B35AEA709965805BF3C2AF58FD6" alt="" className="nike_list_img_mo gilead" />
                            </a>
                            <a href="https://gilead.com" target="_blank">
                                {/* <img src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Company-Nike-Logo.png"} alt="" className="nike_list_img" /> */}
                                <img src="../../assets/images/Kite_logo.png" alt="" className="nike_list_img_mo gilead2" />
                            </a>
                        </div>
                        <div className="collapse navbar-collapse" id="guest-login">
                            <ul className="nav navbar-nav">
                                <li><Link to="/company/gilead-internship/home">Home</Link></li>
                                <li><Link to="/company/gilead-internship/communities">Communities</Link></li>
                                <li><Link to="/company/gilead-internship/apartment-tour">Apartment Tour</Link></li>
                                 <li><Link to="/company/gilead-internship/faq">FAQ's</Link></li> 
                                <li><Link to="/company/gilead-internship/questionnaire">Housing Registration</Link></li>
                                {/* <li><Link to="#" data-toggle="modal" data-target="#internship-confirmation" onClick={this.internQuestionnaire}>Intern Questionnaire</Link></li> */}
                                <li><Link to="/company/gilead-internship/contact-us">Contact Us</Link></li>
                            </ul>
                            <a href="https://gilead.com" target="_blank">
                                {/* <img src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Company-Nike-Logo.png"} alt="" className="nike_list_img" /> */}
                                <img src="../../assets/images/Kite_logo.png" alt="" className="nike_list_img gilead2" />
                            </a>
                            <a href="https://gilead.com" target="_blank">
                                {/* <img src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Company-Nike-Logo.png"} alt="" className="nike_list_img" /> */}
                                <img src="https://www.gilead.com/-/media/gilead-corporate/images/global/gilead-create-possible-svg.svg?iar=0&hash=4A8C3B35AEA709965805BF3C2AF58FD6" alt="" className="nike_list_img gilead" />
                            </a>
                        </div>
                    </nav>
                </div>
            </div> 
            
            </div>
   
           );
       }
   }
   export default StripeInternshipHeader;