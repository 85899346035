/* Importing the node modules, child components, services and controllers used 
   inside BechtelPdf component */

   /* BechtelPdf Component initialization */
   class BechtelPdf extends React.Component{
       /* Initializing objects of its BechtelPdf class */
       constructor(props) {
           super(props)
           /* BechtelPdf Component State variables Initialization */
           this.state = {
              
           }
       }
       /* It is invoked immediately after a component is mounted */
      


       componentWillMount(){
        window.location = "https://s3-us-west-1.amazonaws.com/suiteam-website/PDF/Bechtel.pdf";
       }



   /* It is invoked to return html content */      
   render(){
    var PDF="https://s3-us-west-1.amazonaws.com/suiteam-website/PDF/Bechtel.pdf"
       return(   
             <div className="pdfdownload">
            <img src="assets/images/pdfbg.png" className="pdfbg" />
            <div className="pdfdownload_con">
            <img src="/assets/newUI-images/dean/SA_logoB_widthSM.svg" />
            <div className="col-md-5">
            <p>Your web browser doesn't have a PDF plugin.
            Instead you can click here to</p>
            </div>
            <a href={PDF} className="btn btn-rounded clearfix">Download PDF</a>
            </div>
        </div>
       );
   }
   }
   export default BechtelPdf;

   
   