import { Drawer } from "antd";
import React, { useState } from "react";

function Detailsmodal({ state, setState }) {
  const data = state.rowRecord;
  console.log(data, "data");
  function onClose() {
    setState((prev) => ({ ...prev, showDrawer: false }));
  }
  return (
    <Drawer
      title="Details"
      closable={false}
      onClose={onClose}
      visible={state.showDrawer}
      width="400"
    >
      <table className="table tabletop-border">
        <tbody>
          <tr>
            <th scope="row">
              <span className="drawerLabels">PO</span>
            </th>
            <td>
              {data.PurchaseOrder == "" || data.PurchaseOrder == null ? (
                <span className="draweroptions">N/A</span>
              ) : (
                <span className="draweroptions">{data.PurchaseOrder}</span>
              )}
            </td>
          </tr>
          <tr>
            <th scope="row">
              <span className="drawerLabels">Parking Space Number</span>
            </th>
            <td>
              {data.MasterParkingSpace == "" ||
              data.MasterParkingSpace == null ? (
                <span className="draweroptions">N/A</span>
              ) : (
                <span className="draweroptions">{data.MasterParkingSpace}</span>
              )}
            </td>
          </tr>
          <tr>
            <th scope="row">
              <span className="drawerLabels">Mailbox Number</span>
            </th>
            <td>
              {data.MasterMailBox == "" || data.MasterMailBox == null ? (
                <span className="draweroptions">N/A</span>
              ) : (
                <span className="draweroptions">{data.MasterMailBox}</span>
              )}
            </td>
          </tr>

          <tr>
            <th scope="row">
              <span className="drawerLabels">Wifi Name</span>
            </th>

            <td>
              {data.WifiName == "" || data.WifiName == null ? (
                <span className="draweroptions">N/A</span>
              ) : (
                <span className="draweroptions">{data.WifiName}</span>
              )}
            </td>
          </tr>
          <tr>
            <th scope="row">
              <span className="drawerLabels">Wifi Password</span>
            </th>
            <td>
              {data.wifiPassword == "" || data.wifiPassword == null ? (
                <span className="draweroptions">N/A</span>
              ) : (
                <span className="draweroptions">{data.wifiPassword}</span>
              )}
            </td>
          </tr>
          <tr>
            <th scope="row">
              <span className="drawerLabels">Floor Level</span>
            </th>

            <td>
              {data.Floornumber == "" || data.Floornumber == null ? (
                <span className="draweroptions">N/A</span>
              ) : (
                <span className="draweroptions">{data.Floornumber}</span>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </Drawer>
  );
}

export default Detailsmodal;
