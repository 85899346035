class PhotoModal extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        result: [],
        img: "https://my.matterport.com/show/?m=hj8MZKxXxx6"
      }
    }
    componentDidMount() {
        this.setState({result:this.props.CommunityImages})
    }
  
    setImage(url) {
      this.setState({ img: url })
    }
  
    render() {
      return (
  
        <div>
          <div >
            <div >
              <div >
                <div className="outer">
                  <div id={"sync1" + 15} className="photoG_bgG owl-carousel owl-theme">
                    {this.state.result.map((row, index) => {
                      return (
                        <div className="item" key={index} >
                          <img className="img-responsive image" src={row.image_name} />
                        </div>)
                    })}</div>
  
                  <div id={"sync2" + 15} className="photoG_smG owl-carousel owl-theme">
                    {this.state.result.map((row, index) => {
                      return (
                        <div className="item" key={index}>
                          <img className="img-responsive image" src={row.image_name} />
                        </div>
                      )
                    })}</div>
                </div>
                <div className="floor_gallery">
                  <div className="image">
                  
                <img src="/assets/newUI-images/lakeImages/floorDummy.png"/>  
                  </div>
                  <div className="detailsFloor">
                    <h1>Floorplans</h1>
                    <ul>
                    <li ><a>1x1 ( 469 sq ft)</a></li>
                    <li ><a>1x1 ( 481 sq ft)</a></li>
                    <li ><a>1x1 ( 727 sq ft)</a></li>
                    <li ><a>1x1 ( 817 sq ft)</a></li>
                    <li ><a>1x1 ( 900 sq ft)</a></li>
                    <li ><a>1x1 ( 829 sq ft)</a></li>
                    <li><a>2x2 ( 944 sq ft)</a></li>
                    <li><a>2x2 ( 1304 sq ft)</a></li>
                    <li><a>2x2 ( 1232 sq ft)</a></li>
                  </ul>
                  </div>
  
                </div>
                </div>
                </div>
          </div>
        </div>
      )
    }
  }
  export default PhotoModal;