import React from "react";

// Custom CSS to supplement Bootstrap 1.11
const styles = {};

const GuestCheckoutNotice = ({ guestName, checkoutDate, checkoutTime }) => (
  <>
    <tr>
      <td style={{ fontSize: 0, lineHeight: 0, height: "30px" }}>&nbsp;</td>
    </tr>

    <tr>
      <td>
        <table
          border="0"
          cellPadding="0"
          cellSpacing="0"
          style={{ width: "95%", margin: "auto" }}
        >
          <tr>
            <td
              style={{
                fontSize: "25px",
                fontFamily: "'Sora', sans-serif",
                color: "#000000",
                textAlign: "left",
                fontWeight: 700,
              }}
            >
              Hi {guestName},
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: 0, lineHeight: 0, height: "15px" }}>
              &nbsp;
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "18px",
                fontFamily: "'Sora', sans-serif",
                color: "#000000",
                textAlign: "left",
                fontWeight: 700,
                paddingRight: "40px",
              }}
            >
              It looks like your stay with us is coming to an end.
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "18px",
                fontFamily: "'Sora', sans-serif",
                color: "#000000",
                textAlign: "left",
                fontWeight: 500,
              }}
            >
              We've enjoyed having you!
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: 0, lineHeight: 0, height: "15px" }}>
              &nbsp;
            </td>
          </tr>
        </table>
      </td>
    </tr>

    <tr>
      <td style={{ fontSize: 0, lineHeight: 0, height: "20px" }}>&nbsp;</td>
    </tr>

    <tr>
      <td
        style={{
          fontSize: "18px",
          fontFamily: "'Sora', sans-serif",
          color: "#000000",
          textAlign: "left",
          fontWeight: 500,
        }}
      >
        <table
          style={{ width: "95%", margin: "auto" }}
          border="0"
          cellPadding="0"
          cellSpacing="0"
        >
          <tr>
            <td
              style={{
                fontSize: "18px",
                fontFamily: "'Sora', sans-serif",
                color: "#000000",
                textAlign: "left",
                fontWeight: 700,
              }}
            >
              Your check out date & time is:
            </td>
            <td style={{ width: "165px" }}></td>
          </tr>
          <tr>
            <td style={{ fontSize: 0, lineHeight: 0, height: "10px" }}>
              &nbsp;
            </td>
          </tr>
          <tr>
            <th
              style={{
                background: "#000000",
                color: "#ffffff",
                fontSize: "18px",
                fontFamily: "'Sora', sans-serif",
                padding: "10px",
                textAlign: "center",
                fontWeight: 600,
              }}
            >
              CHECK OUT
            </th>
          </tr>
          <tr>
            <td
              style={{
                background: "#ececec",
                color: "#000000",
                fontSize: "20px",
                fontFamily: "'Sora', sans-serif",
                padding: "10px",
                textAlign: "center",
                fontWeight: 800,
              }}
            >
              {checkoutDate}
              <span
                style={{
                  background: "#ececec",
                  color: "#000000",
                  fontSize: "18px",
                  fontFamily: "'Sora', sans-serif",
                  padding: "10px",
                  textAlign: "center",
                  fontWeight: 700,
                  display: "block",
                }}
              >
                {checkoutTime}
              </span>
            </td>
          </tr>
        </table>
      </td>
    </tr>

    <tr>
      <td>
        <table
          style={{ width: "95%", margin: "auto" }}
          border="0"
          cellpadding="0"
          cellspacing="0"
        >
          <tr>
            <td style={{ fontSize: "0", lineHeight: "0" }} height="30">
              &nbsp;
            </td>
          </tr>

          <tr>
            <td
              style={{
                fontSize: "18px",
                fontFamily: "'Sora', sans-serif",
                color: "#000000",
                textAlign: "left",
                fontWeight: "700",
              }}
            >
              Anything we need to know?
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: "0", lineHeight: "0" }} height="30">
              &nbsp;
            </td>
          </tr>
          <tr>
            <td
              style={{
                color: "#000000",
                fontSize: "17px",
                fontFamily: "'Sora', sans-serif",
                fontWeight: "500",
              }}
            >
              If anything is broken, damaged, or missing, please let us know as
              soon as possible.
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: "0", lineHeight: "0" }} height="30">
              &nbsp;
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "18px",
                fontFamily: "'Sora', sans-serif",
                color: "#000000",
                textAlign: "left",
                fontWeight: "700",
              }}
            >
              How to Prepare
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: "0", lineHeight: "0" }} height="30">
              &nbsp;
            </td>
          </tr>
          <tr>
            <td
              style={{
                color: "#000000",
                fontSize: "17px",
                fontFamily: "'Sora', sans-serif",
                fontWeight: "500",
              }}
            >
              We expect that you will leave the apartment in the condition you
              found it in.
            </td>
          </tr>
          <tr>
            <td
              style={{
                color: "#000000",
                fontSize: "17px",
                fontFamily: "'Sora', sans-serif",
                fontWeight: "300",
              }}
            >
              Please remove all trash and double-check that you have collected
              all personal items. Do not unplug the modem or TV. We will handle
              all the linens and towels and clean for the next guest.
              <ul>
                <li
                  style={{
                    color: "#000000",
                    fontSize: "17px",
                    fontFamily: "'Sora', sans-serif",
                    fontWeight: "300",
                  }}
                >
                  A $25 fee will be charged for each trash bag we have to remove
                </li>
                <li
                  style={{
                    color: "#000000",
                    fontSize: "17px",
                    fontFamily: "'Sora', sans-serif",
                    fontWeight: "300",
                  }}
                >
                  If the apartment is excessively dirty, a cleaning fee will be
                  applied
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: "0", lineHeight: "0" }} height="20">
              &nbsp;
            </td>
          </tr>
          <tr>
            <td
              style={{
                color: "#f70743",
                fontSize: "17px",
                fontFamily: "'Sora', sans-serif",
                fontWeight: "700",
              }}
            >
              Helpful tip: Sign out of all your streaming services
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: "0", lineHeight: "0" }} height="30">
              &nbsp;
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "18px",
                fontFamily: "'Sora', sans-serif",
                color: "#000000",
                textAlign: "left",
                fontWeight: "700",
              }}
            >
              Morning of Move
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: "0", lineHeight: "0" }} height="10">
              &nbsp;
            </td>
          </tr>
          <tr>
            <td
              style={{
                color: "#000000",
                fontSize: "17px",
                fontFamily: "'Sora', sans-serif",
                fontWeight: "500",
              }}
            >
              Leave any keys, remotes, fobs, and parking items on the kitchen
              counter.
            </td>
          </tr>
          <tr>
            <td
              style={{
                color: "#000000",
                fontSize: "17px",
                fontFamily: "'Sora', sans-serif",
                fontWeight: "300",
              }}
            >
              All access items to the building should be surrendered upon
              departure. If anything is missing, you will be charged replacement
              fees.
            </td>
          </tr>

          <tr>
            <td style={{ fontSize: "0", lineHeight: "0" }} height="30">
              &nbsp;
            </td>
          </tr>

          <tr>
            <td
              style={{
                fontSize: "18px",
                fontFamily: "'Sora', sans-serif",
                color: "#000000",
                textAlign: "left",
                fontWeight: "700",
              }}
            >
              How was your stay?
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: "0", lineHeight: "0" }} height="30">
              &nbsp;
            </td>
          </tr>
          <tr>
            <td>
              <img
                src="https://suiteam-website.s3-us-west-1.amazonaws.com/survey-icons/emoji3.png"
                alt=""
                style={{ height: "70px", paddingRight: "20px" }}
              />
              <img
                src="https://suiteam-website.s3-us-west-1.amazonaws.com/survey-icons/emoji1.png"
                alt=""
                style={{ height: "70px" }}
              />
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: "0", lineHeight: "0" }} height="30">
              &nbsp;
            </td>
          </tr>

          <tr>
            <td>
              <a
                href="https://suiteamerica.com/homepage"
                target="_blank"
                style={{
                  color: "#ffffff",
                  background: "#000000",
                  fontSize: "15px",
                  fontFamily: "'Sora', sans-serif",
                  fontWeight: "600",
                  border: "0",
                  padding: "12px 30px",
                  borderRadius: "25px",
                  boxShadow: "0px 0px 10px #c9c9c9",
                  textDecoration: "none",
                }}
              >
                Find more info in app
              </a>
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: "0", lineHeight: "0" }} height="30">
              &nbsp;
            </td>
          </tr>

          <tr>
            <td
              style={{
                fontSize: "18px",
                fontFamily: "'Sora', sans-serif",
                color: "#000000",
                textAlign: "left",
                fontWeight: "700",
              }}
            >
              Questions?
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: "0", lineHeight: "0" }} height="5">
              &nbsp;
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "18px",
                fontFamily: "'Sora', sans-serif",
                color: "#000000",
                textAlign: "left",
                fontWeight: "300",
              }}
            >
              <a
                href="mailto:someone@example.com"
                style={{
                  fontSize: "18px",
                  fontFamily: "'Sora', sans-serif",
                  color: "#077fd5",
                  textAlign: "left",
                  fontWeight: "400",
                }}
              >
                Send us an email
              </a>{" "}
              or give us a call 800.367.9501
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: "0", lineHeight: "0" }} height="30">
              &nbsp;
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "18px",
                fontFamily: "'Sora', sans-serif",
                color: "#000000",
                textAlign: "left",
                fontWeight: "700",
              }}
            >
              Download the app today
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: "0", lineHeight: "0" }} height="15">
              &nbsp;
            </td>
          </tr>
          <tr>
            <td>
              <a
                href="https://play.google.com/store/apps/details?id=com.suiteamerica.guestportal"
                target="_blank"
                style={{ paddingRight: "20px", textDecoration: "none" }}
              >
                <img
                  style={{ width: "130px" }}
                  src="https://chapp-bucket.s3.us-west-1.amazonaws.com/googlePlayStoreIcon.png"
                  alt=""
                />
              </a>
              <a
                href="https://apps.apple.com/us/app/guestportal/id1492423942"
                target="_blank"
                style={{ paddingRight: "20px", textDecoration: "none" }}
              >
                <img
                  style={{ width: "130px" }}
                  src="https://chapp-bucket.s3.us-west-1.amazonaws.com/appStoreIcon.png"
                  alt=""
                />
              </a>
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: "0", lineHeight: "0" }} height="30">
              &nbsp;
            </td>
          </tr>
        </table>
      </td>
    </tr>

    <tr>
      <td
        style={{
          fontSize: "18px",
          fontFamily: "'Sora', sans-serif",
          color: "#000000",
          textAlign: "left",
          fontWeight: 300,
        }}
      >
        Or log into our passwordless{" "}
        <a
          href="https://www.suiteamerica.com/guest-login"
          target="_blank"
          style={{
            fontSize: "18px",
            fontFamily: "'Sora', sans-serif",
            color: "#077fd5",
            textAlign: "left",
            fontWeight: 400,
          }}
        >
          Guestportal
        </a>
      </td>
    </tr>

    <tr>
      <td style={{ fontSize: "0", lineHeight: "0" }} height="40">
        &nbsp;
      </td>
    </tr>
  </>
);

export default GuestCheckoutNotice;
