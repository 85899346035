'use strict'
import EncryptDecrypt from '../../../../services/encryptDecrypt';
import LocationService from '../../../../services/locationService';
import LocationController from "../../../controller/locationController";
import CityData from "../../../../constants";
class LocationModal {
  constructor(thisObject, props) {
    this.Obj = thisObject
    this.propsObj = props
    this.state = {
      color: '',
      userData: {},
      checkEmail: [],
      userFavourites: [],
      recentlyViewData: [],
      searchCommunityData: [],
      savedSearchData: [],
      community: {},
      isSearch: false,
      showView: true,
      favourite: 'favourite_id',
      confPass: true,
      role: 0,
      successMessage: '',
      searcmessage: '',
      companyname: this.propsObj.companyName,
      searchType: '',
      searchLatitude: '',
      searchLongitude: '',
      more: 1,
      less: 0,
      communityData: [],
      isLoading: false,
      loadMoreButton: false,
      loadMoreString: true,
      company: this.propsObj.companyName == undefined
        ? 'suiteamerica'
        : this.propsObj.companyName,
      default: 1,
      loadingStatus: false,
      calendarStatus: false,
      calendercomunityinfo: {},
      startLimit: 0,
      cityIntro: "",
      cityImage: "",
      locationName: "",
      cityList: [],
      isCityList: false,
      cityIndex: -1,
      cities: [{ city: "Bay Area, CA", subCity: ["Fremont, CA", "Pleasanton, CA", "Palo Alto, CA", "Sunnyvale, CA", "Mountain View, CA", "San Jose, CA", "Cupertino, CA"] },
      { city: "Los Angeles, CA", subCity: ["Santa Monica, CA"] },
      { city: "Lathrop, CA", subCity: [] },
      { city: "San Francisco, CA", subCity: [] },
      { city: "Santa Clara, CA", subCity: [] },
      { city: "Sparks, NV", subCity: [] },
      { city: "Buffalo, NY", subCity: [] },
      { city: "Austin, TX", subCity: [] }]
    }
  }
  async DidMount() {
    await this.getSearchResult()
    await this.getRecentlyViewedCommunitys()
  }
  async getSearchResult(cities) {
    console.log('this.propsObj.companyName', this.propsObj.companyName)
    var companyName = '';
    await this.updateState({ isLoading: true })
    switch (this.propsObj.companyName) {
      case 'Nike':
        companyName = 'Nike';
        break;
      case 'NikeIntern':
        companyName = 'NikeIntern'
        break;
      case 'GileadIntern':
        companyName = 'GileadIntern'
        break;
      case 'Adidas Intern':
        companyName = 'Adidas Intern'
        break;
      case 'NvidiaTravel':
        companyName = 'NvidiaTravel'
        break;  
      case 'ServiceNow Intern':
        companyName = 'ServiceNow Intern'
        break;
      case 'Adobe':
        companyName = 'Adobe'
        break;
      case 'Nike Apprenticeship':
        companyName = 'Nike Apprenticeship'
        break;
      case 'Lumentum':
        companyName = 'Lumentum'
        break;
      case 'Tesla':
        companyName = 'Tesla';
        break;
      default:
        companyName = 'suiteamerica';
    }
    var paramsObj = {};
    var city = '';
    var state = '';
    var area = '';
    if (this.propsObj.match != undefined) {
      paramsObj = companyName != 'suiteamerica' ? this.propsObj.props.match.params : this.propsObj.match.params
      //console.log(paramsObj,'paramsObj')
      city = paramsObj.city ? paramsObj.city.toLowerCase() : undefined;
      state = paramsObj.state ? paramsObj.state.toLowerCase() : undefined;
      area = paramsObj.area ? paramsObj.area.toLowerCase() : undefined;
    } else {
      paramsObj = 'suiteamerica'
      city = undefined;
      state = undefined;
      area = undefined;
    }
    let localData = "";
    let individualdata = "";
    if (city !== undefined && state !== undefined && CityData[state + '-' + city] !== undefined) {
      localStorage.setItem("savedSearch", JSON.stringify(CityData[state + '-' + city]));
      localData = CityData[state + '-' + city];
      individualdata = CityData[state + '-' + city];
      await this.updateState({ locationName: individualdata.locationName, cityIntro: individualdata.cityIntro, cityImage: individualdata.image, metaTitle: individualdata.metaTitle, metaDescription: individualdata.metaDescription, metaUrl: individualdata.metaUrl });
    } else if (area !== undefined && CityData[area] !== undefined) {
      localStorage.setItem("savedSearch", JSON.stringify(CityData[area]));
      localData = CityData[area];
      individualdata = CityData[area];
      await this.updateState({ locationName: individualdata.locationName, cityIntro: individualdata.cityIntro, cityImage: individualdata.image, metaTitle: individualdata.metaTitle, metaDescription: individualdata.metaDescription, metaUrl: individualdata.metaUrl });
    } else {
      //localData = localStorage.getItem('savedSearch')
      localData = null
      individualdata = JSON.parse(localData)
        ? JSON.parse(localData)
        : {
          countryId: '',
          pets: false
        }
    }
    const webGuest = localStorage.getItem('_u_web');
    if (webGuest != null) {
      const userData = await EncryptDecrypt.decryptData({ 'type': webGuest })
      this.updateState({ userData: userData.recordset[0] })
    }
    const limitData = {
      startLimit: 0,
      companyname: companyName,
      latitude: (individualdata == undefined || (individualdata.latitude == undefined || individualdata.latitude == '' && individualdata.latitude == null))
        ? this.Obj.state.searchLatitude : individualdata.latitude,
      longitude: (individualdata == undefined || (individualdata.longitude == undefined || individualdata.longitude == '' && individualdata.longitude == null))
        ? this.Obj.state.searchLongitude : individualdata.longitude,
      address: individualdata.address != undefined
        ? individualdata.address
        : '',
      state_id: (individualdata == undefined || (individualdata.state_id == undefined || individualdata.state_id == '' && individualdata.state_id == null))
        ? ''
        : individualdata.state_id,

      city_id: (individualdata == undefined || (individualdata.city_id == undefined || individualdata.city_id == '' && individualdata.city_id == null))
        ? ''
        : individualdata.city_id,

      amenitiesId: individualdata.amenities_id != undefined
        ? individualdata.amenities_id
        : '',

      communityName: individualdata.communityName != undefined
        ? individualdata.communityName
        : '',

      countryId: (individualdata == undefined || (individualdata.countryId == undefined || individualdata.countryId == '' && individualdata.countryId == null))
        ? ''
        : individualdata.countryId,

      userid: (this.Obj.state.userData != undefined && Object.keys(this.Obj.state.userData).length > 0)
        ? this.Obj.state.userData.Id
        : '',

      cities: cities == undefined ? [] : cities
    }
    //console.log(limitData,'limitData')
    const communityData = await LocationController.getCommunityData(limitData)
    // console.log(communityData,'communityData')
    await this.updateState({
      default: (localData != null || localData != undefined)
        ? 0
        : this.Obj.state.default,
      savedSearchData: individualdata,
      communityData,
      isLoading: false,
      startLimit: this.Obj.state.startLimit + 10
    })
    localStorage.removeItem('savedSearch')
  }
  wallsckore() {
    LocationController.wallsckore(this.Obj)
  }
  async favouriteCommunity(data) {
    if (Object.keys(this.Obj.state.userData).length > 0) {
      const communityId = data.community_id
      const favouritedetails = {
        user_id: this.Obj.state.userData.Id,
        community_id: communityId,
        createdBy: this.Obj.state.userData.GuestEmail
      }
      const favourite = await LocationService.saveFavouriteCommunity(favouritedetails)
      const result = await this.getFavourites(favourite, data)

      return result
    } else {
      this.updateState({ community: data })
    }
  }
  /**
    * When user login then we get the favourites and the recently viewed data
    */
  async getFavourites(favouriteid, data) {
    const checkCommunityIdInCommunityList = _.findIndex(this.Obj.state.communityData, function (o) {
      return o.community_id == data.community_id;
    })
    const checkCommunityIdInRecentlyViewData = _.findIndex(this.Obj.state.recentlyViewData, function (o) {
      return o.community_id == data.community_id;
    })
    if (checkCommunityIdInCommunityList != -1) {
      var addfavStatusInCommunityList = update(this.Obj.state.communityData, {
        [checkCommunityIdInCommunityList]: {
          favouritestatus: {
            $set: true
          },
          favouriteId: {
            $set: favouriteid.uniqueid
          }
        }
      });
    }
    if (checkCommunityIdInRecentlyViewData != -1) {
      var addfavStatusInRecentlyViewed = update(this.Obj.state.recentlyViewData, {
        [checkCommunityIdInRecentlyViewData]: {
          favouritestatus: {
            $set: true
          },
          favouriteId: {
            $set: favouriteid.uniqueid
          }
        }
      });
    }
    return [addfavStatusInCommunityList, addfavStatusInRecentlyViewed]
  }
  async unFavouriteCommunity(data) {
    const checkCommunityIdInCommunityList = _.findIndex(this.Obj.state.communityData, function (o) {
      return o.favouriteId == data.favouriteId;
    })
    const checkCommunityIdInRecentlyViewData = _.findIndex(this.Obj.state.recentlyViewData, function (o) {
      return o.favouriteId == data.favouriteId;
    })
    if (checkCommunityIdInCommunityList != -1) {
      var addfavStatusInCommunityList = update(this.Obj.state.communityData, {
        [checkCommunityIdInCommunityList]: {
          favouritestatus: {
            $set: false
          }
        }
      });
    }
    if (checkCommunityIdInRecentlyViewData != -1) {
      var addfavStatusInRecentlyViewed = update(this.Obj.state.recentlyViewData, {
        [checkCommunityIdInRecentlyViewData]: {
          favouritestatus: {
            $set: false
          }
        }
      });
    }
    const favouritedetails = {
      favourite_id: data.favouriteId,
      user_id: this.Obj.state.userData.Id
    }
    await LocationService.unFavouriteCommunityByUser(favouritedetails)
    return [addfavStatusInCommunityList, addfavStatusInRecentlyViewed]
  }
  /**
      * When user login then we get the recently viewed data
      */
  async getRecentlyViewedCommunitys() {
    if (Object.keys(this.Obj.state.userData).length > 0) {
      const userdetails = {
        userid: this.Obj.state.userData.Id
      }
      const recentlyViewData = await LocationService.getRecentlyViewedCommunitys(userdetails)
      this.updateState({ recentlyViewData: recentlyViewData })
    }
  }
  async saveSearchDetails(data) {
    if (data.length == 0) {
      this.updateState({ searcmessage: 'please search atleast one community', color: 'red' })
    } else {
      this.updateState({ loadingStatus: true })
      if (Object.keys(this.Obj.state.userData).length > 0) {
        const searchData = {
          user_id: this.Obj.state.userData.Id,
          state_id: data.state_id,
          area_id: '',
          bathroom_id: '',
          bed_id: '',
          city_id: data.city_id,
          amenitiesId: data.amenitiesId,
          countryId: data.countryId,
          createdBy: this.Obj.state.userData.GuestEmail,
          CommunityName: data.communityName,
          address: data.address
        }
        const savedsearch = await LocationService.saveSavedSearch(searchData)
        if (savedsearch) {
          this.updateState({ searcmessage: 'Search Details successfully inserted', color: 'green', backgroundColor: '#dff0d8', loadingStatus: false, borderColor: ' #d6e9c6' })
          setTimeout(function () {
            this.updateState({ searcmessage: '', color: '' })
          }.bind(this), 1000);
        }
      }
    }
  }
  async updateState(updateObject) {
    await this.Obj.setState(updateObject)
  }
}
export default LocationModal