/* Importing the node modules, child components, services and controllers used 
   inside WorldBankHome component */    
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import {Link} from 'react-router-dom';
   import GuestHeader from '../../common/guestHeader';
   import Footer from '../../common/footer';
   import WorldBankHeader from "../../common/worldBankHeader";
   import Add from "./addedvalue.json";

   /* WorldBankHome Component initialization */
   class Home2 extends React.Component {
       /* Initializing objects of its WorldBankHome class */
         constructor(props) {
           super(props);  
           this.state = {
            add: Add
          };
         }
         /* It is invoked immediately before mounting occurs */
     componentWillMount() {
        MainController.getInitialInformation();
      }
    
      addedvalues(arrayList, className) {
        return (
          <ul className={className}>
            {arrayList.map((row, i) => {
              return <li key={i}>{row}</li>;
            })}
          </ul>
        );
      }
    
      /* It is invoked to return html content */
      render() {
        const add = this.state.add;
        return (
          <div className="corporateHousing_Updates">
            <GuestHeader />
            <WorldBankHeader />
            <header className="codeconveyHeader cc_image_text_main corporate-h video-wrapper worldbnk_banner">
              <img
                className="img-responsive"
                src={s3BucketUrl + "landing-images/world-bank/added-value-dark.jpg"}
                width="100%"
                alt=""
              />
              <div
                className="wow fadeInUp img-on-text top45 width500 text-center left30 img-on-text-center company_bannertxt"
                data-wow-delay="0.5s"
              >
                <h1>Added Value</h1>
              </div>
            </header>
            <div className="quote-section bg-white">
              <div className="container">
                <div
                  className=" text-center col-md-10 col-md-offset-1 wow fadeInUp company_p"
                  data-wow-delay="0.5s"
                >
                  <h2 className="gotham-book mrg-btm-20">
                    Personal Transition Services
                  </h2>
                  <p>
                    Offering a wide array of complimentary and concierge services to
                    assist today’s business traveler, and ensure the transition to
                    your corporate suite or extended stay apartment is smooth and
                    stress free.
                  </p>
                </div>
                </div>
                <div className="service_ch_new stying_hl_bnk mrg-btm-60 company_p_height">
                <div className="container">  <div className="row rows">
                    <div className="col-md-6 col-sm-6">
                    <div
                      className="wow fadeInUp company_p dual_quote text-left pad-left-50"
                      data-wow-delay="0.5s"
                    >
                      <h5 className="text-red">COMPLIMENTARY SERVICES INCLUDE:</h5>
                      {this.addedvalues(add.complimentary, "personal_t_list")}
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div
                      className="wow fadeInUp company_p dual_quote text-left pad-left-50"
                      data-wow-delay="0.5s"
                    >
                      <h5 className="text-red">CONCIERGE SERVICES INCLUDE:</h5>
                      {this.addedvalues(add.concierge, "personal_t_list")}
                      <i className="font-size-12">
                        *Additional fees may apply/Available in select areas
                      </i>
                    </div>
                    </div>
                  </div>
                  </div>
                </div>
             
              <div className="container-fluid mrg-btm-50 mrg-top-50">
                <div
                  className="img-header row"
                  style={{
                    backgroundImage:
                      "url(" +
                      s3BucketUrl +
                      "landing-images/world-bank/Meet-and-Greet-dark.jpg)"
                  }}
                >
                  <h2>
                    SuiteAmerica offers Meet and
                    <br /> Greet Orientations
                  </h2>
                </div>
              </div>
              <div className="container">
                <div
                  className=" text-center col-md-10 col-md-offset-1 wow fadeInUp company_p"
                  data-wow-delay="0.5s"
                >
                  <h2 className="gotham-book mrg-btm-20">Meet and Greet</h2>
                  <p>
                    We will meet with your employees and assist with any of the
                    following:
                  </p>
                </div>
                <div className="service_ch_new stying_hl_bnk mrg-btm-60 ">
                  <div className="col-md-6 col-sm-6">
                    <div
                      className="wow fadeInUp company_p dual_quote text-left pad-left-50"
                      data-wow-delay="0.5s"
                    >
                      <h5 className="text-red">REVIEW THE BASIC OPERATIONS OF:</h5>
                      {this.addedvalues(add.review, "personal_t_list")}
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div
                      className="wow fadeInUp company_p dual_quote text-left pad-left-50"
                      data-wow-delay="0.5s"
                    >
                      <h5 className="text-red">
                        COMMUNITY TOURS ALSO AVAILABLE TO INCLUDE:
                      </h5>
                      {this.addedvalues(add.community, "personal_t_list")}
                      <i className="font-size-12">*Additional fees may apply</i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid mrg-btm-50 mrg-top-50">
                <div
                  className="img-header row"
                  style={{
                    backgroundImage:
                      "url(" +
                      s3BucketUrl +
                      "landing-images/world-bank/grocery-package-dark.jpg)"
                  }}
                >
                  <h2>
                    Grocery Packages
                    <h3>Have the following waiting for you when you arrive.</h3>
                    </h2>
                </div>
              </div>
              <div className="container">
                <div className="col-md-12 boxed_list">
                  <h4>STARTER GROCERY: $115.00</h4>
                  <h5>Breakfast</h5>
                  {this.addedvalues(add.breakfast, "")}
                </div>
                <div className="col-md-12 boxed_list">
                  <h5>Lunch</h5>
                  {this.addedvalues(add.lunch, "")}
                </div>
                <div className="col-md-12 boxed_list">
                  <h5>Dinner</h5>
                  {this.addedvalues(add.dinner, "")}
                </div>
                <div className="col-md-12 boxed_list">
                  <h5>Beverages</h5>
                  {this.addedvalues(add.beverages, "")}
                </div>
                <div className="col-md-12 boxed_list">
                  <h5>Staples</h5>
                  {this.addedvalues(add.staples, "")}
                </div>
                <div className="col-md-12 boxed_list">
                  <h4>ASIAN GROCERY: $115.00</h4>
                  {this.addedvalues(add.line1, "")}
                </div>
              </div>
            </div>
            <SubFooter />
            <Footer />
          </div>
        );
      }
    }
   export default Home2;