import React, { Component } from 'react'
import GuestHeader from '../common/guestHeader';
import SubFooter from "../common/subFooter";
import Footer from '../common/footer';

export class dsHome extends Component {
    render() {
        return (
<div className="ds-webpage-view">
      <GuestHeader/>
        <div className="ds-w-v-view-banner-bg">
          <div className="container">
            <h1>Welcome to SuiteAmerica’s <span> New Destination Experience. </span> </h1>
            <p>One convenient App and two customizable programs.</p>
            <div className="icons">
              <div className="item">
                <img src="/assets/newUI-images/world/icon-1.png" alt="" />
              </div>
              <div className="item">
                <img src="/assets/newUI-images/world/icon-2.png" alt="" />
              </div>
              <div className="item">
                <img src="/assets/newUI-images/world/icon-3.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="ds-webpage-view-top-view">
            <h1>Introducing the most flexible, innovative, and cost-effective Destination Experience. <i>Ever.</i>
            </h1>
            <div className="image-view">
              <div className="item">
                <img src="/assets/newUI-images/world/phon-view.png" alt="" />
              </div>
              <div className="item">
                <p>Through API integration with trusted industry data providers, ReloNavigator provides comprehensive area insight in a single cloud based research environment.  Set your priorities, and Jina recommends cities best fitting your lifestyle.
                <span>RightScore, our proprietary dynamic scoring system, compares home options to your priorities making it easy to find the home that suits you best.</span></p>
              </div>
            </div>
            <div className="image-view">
              <div className="item">
                <p>Customizable programs with virtual or in-person support. Connect with experienced
                  SuiteAmerica Destination Services Consultants. Accessible support for every question that
                  might come up. Settling-In services available.</p>
              </div>
              <div className="item ">
                <div className="image-view-text">
                  <img src="/assets/newUI-images/world/laptop.png" alt="" />
                  <div className="side-para">
                  <p>Find out more about Jina  
                     <a href="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/Jina_Intro.pdf" target="_blank">here</a>.
                    </p>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ds-webpage-view-dark-section">
          <div className="container">
            <p>Our tiered programs include the most innovative relocation platform, experienced consultants, and
              flexible options. </p>
            <p>Packages are available in flat rates, hourly, and monthly access.</p>
            <p>Bundle ReloNavigator with a corporate housing reservation for a cost-saving, convenient solution.</p>
          </div>
        </div>
        <div className="container">
          <div className="ds-webpage-view-tails">
            <div className="item">
              <i>The App*</i>
              <div className="view">
                <div className="image">
                  <img src="/assets/newUI-images/world/icon-1.png" alt="" />
                </div>
                {/* <h3>RELONAVIGATOR</h3> */}
                <img className="relo-logo" src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/ReloNavigator_Logo.png"/>
                <h4>Self-service App*</h4>
                <p className="p">Instant, comprehensive area information, including school ratings and neighborhood stats.</p>
                <p>Interactive relocation checklist for before, during, and after your move.</p>
                <p />
                <p />
                <p />
                <p />
                <p />
              
              </div>
            </div>
            <div className="item item2">
              <i><span>The Programs</span></i>
              <div className="sub-view">
                <div className="view">
                  <div className="image">
                    <img src="/assets/newUI-images/world/icon-2.png" alt="" />
                  </div>
                  <h3>EXPLORER</h3>
                  <h4>Self-service App with virtual support</h4>
                  <h5>Access to ReloNavigator</h5>
                  <p>Instant, comprehensive area information, including school ratings and neighborhood stats.
                  </p>
                  <p>Interactive relocation checklist for before, during, and after your move.</p>
                  <p>Assigned and dedicated SuiteAmerica Destination Services consultant.</p>
                  <p>Ability to schedule time with consultant via their calendar.</p>
                  <p>Message center to connect with your consultant</p>
                  <p />
                  <p />
                </div>
                <div className="view">
                  <div className="image">
                    <img src="/assets/newUI-images/world/icon-3.png" alt="" />
                  </div>
                  <h3>COMMANDER</h3>
                  <h4>Self-service App with live support</h4>
                  <h5>Access to ReloNavigator</h5>
                  <p>Instant, comprehensive area information, including school ratings and neighborhood stats.
                  </p>
                  <p>Interactive relocation checklist for before, during, and after your move.</p>
                  <p>Assigned and dedicated SuiteAmerica Destination Services consultant.</p>
                  <p>Ability to schedule time with consultant via their calendar.</p>
                  <p>Message center to connect with your consultant</p>
                  <p>Home-finding tours, chauffeured in our safe SuiteAmerica vehicles</p>
                  <p>Customized settling-in services, such as school registration and utility set-up
                    assistance.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix" />
          <div className="disclaimer">
            <span>*Web App available now.</span>
            <span>Mobile App coming Q4 2020.</span>
          </div>
        </div>
        <div className="red-stripe">
          Are you a ReloNavigator, Explorer or Commander?
        </div>
        <div className="bottom-view-text">
          <p>Experience the most personalized and innovative relocation assistance App and Destination Services
            programs.</p>
          <i>Your Adventure Begins at Home.</i>
          <div className="container">
          <div className="image">
            <button className="btn-cls" type="button">Let's Find it</button>
            <img src="/assets/newUI-images/world/map.png" alt="" />
          </div>
          </div>
        </div>
        <SubFooter/>
        <Footer/>
      </div>
        )
    }
}

export default dsHome
