/* Importing the node modules, child components, services and controllers used 
   inside CheckList component */
   import $ from "jquery";
   import AppController from "../../../controller/appController";
   import FivestarHeader from '../../../common/fivestarHeader';
   import FiveStarApiServices from '../../../../services/fiveStarApiServices';
   import moment from 'moment'
   /* CheckList Component initialization */
   class OccupancyChecker extends React.Component {
     /* Initializing objects of its CheckList class */
     constructor(props) {
       super(props);
       this.state = {
         data: {
           provinces: [
             { id: 1, name: 'P1' },
             { id: 2, name: 'P2' },
             { id: 3, name: 'P3' },
             { id: 4, name: 'P4' },
           ],
           cities: [
             { id: 1, name: 'C1', provinceId: 1 },
             { id: 2, name: 'C2', provinceId: 1 },
             { id: 3, name: 'C3', provinceId: 1 },
             { id: 4, name: 'C4', provinceId: 2 },
             { id: 5, name: 'C5', provinceId: 2 },
             { id: 6, name: 'C6', provinceId: 2 },
             { id: 7, name: 'C7', provinceId: 3 },
             { id: 8, name: 'C8', provinceId: 3 },
             { id: 9, name: 'C9', provinceId: 3 },
             { id: 10, name: 'C10', provinceId: 4 },
             { id: 11, name: 'C11', provinceId: 4 },
           ]
         },
         activeCommunities:[],
         activeSuites:[],
         loadingStatus:false,
         masterId:'',
         showIcon:false,
         isOccuiped:'',
         cityList: '',
         errorMessage:''
       }
       this.onSelect = this.onSelect.bind(this);
     }
     /* It is invoked immediately before a component is mounted */
    async componentWillMount() {
       $('html, body').animate({ scrollTop: 0 });
       AppController.stickyTitles();
       await this.getActiveCommunities();
      
     }
     async getActiveCommunities(){
      const requestObj={};
      const resultData = await FiveStarApiServices.GetActiveCommunities(requestObj);
     
       if(resultData.error == '0' && resultData.message.length > 0){
          this.setState({activeCommunities:resultData.message});
       }
    }
    async onSelect(event) {
       
       this.setState({loadingStatus:true,showIcon:false});
       if(event.target.value != ""){
        let id = parseInt(event.target.value);

        const requestObj= {
          "communityId" : id
          }
          const result=await FiveStarApiServices.GetMastersByCommunityId(requestObj);
      
      if(result.error == '0' && result.message.length > 0){
        this.setState({activeSuites:result.message,loadingStatus:false,showIcon:false});
     }
      
        }else{
          this.setState({activeSuites:[],loadingStatus:false,showIcon:false});
        }
      
     }
     async isOccuiped(event){
       
       this.setState({loadingStatus:true});
      if(event.target.value != ""){
        let cDate=moment().format('MM-DD-YYYY');
        
        let id = parseInt(event.target.value);
        const requestObj= {
          "masterId" : id,
          "currentdate":cDate
          }
         
          
          const result=await FiveStarApiServices.GetMastersOccupancyInfo(requestObj);
      
      
      if(result.error == "0"){
        await this.setState({loadingStatus:false,errorMessage:'',showIcon:true,isOccuiped:result.isoccupied});
      }else{
        await this.setState({loadingStatus:false,errorMessage:result.message,showIcon:false,isOccuiped:''});
      }
        }else{
          await this.setState({loadingStatus:false,errorMessage:'',showIcon:false,isOccuiped:''});
        }
     }
     
     /* It is invoked to return html content */
     render() {
       
       
       return (
         <div>
           <FivestarHeader />
           <div className="occupancy-checkerpage">
           <h1>OCCUPANCY CHECKER</h1>
             <div className="item">
               <div className="dropdown form-group">
                 <label >Communities</label>
                 <select className="form-control" id="sel1" onChange={this.onSelect}>
                   <option value="">Select Community</option>
                   {this.state.activeCommunities.map((row, index) => {
                     return (
                       <option
                         key={row.complexId}
                         value={row.complexId}
                       //selected={this.selectedId === row.id}
                       >{row.complexname}
                       </option>
                     )
                   })}
                 </select>
               </div>
               <div className="dropdown form-group">
                 <label >Suite# </label>
                 <select className="form-control" id="sel1" onChange={(e)=>this.isOccuiped(e)}>
                   <option value="">Select Suite#</option>
                   {this.state.activeSuites != undefined && this.state.activeSuites.length > 0
                     ? this.state.activeSuites.map((row, index) => {
                       return (<option
                         key={row.masterId}
                         value={row.masterId}
                       >{row.suitenumber}
                       </option>
                       )
                     }) : ''}
                 </select>
               </div>
               {/* <div className="dropdown">
                     <button type="button" className="btn" onClick={()=>this.isOccuiped}>SUBMIT </button>
               </div> */}
             </div>
             {this.state.loadingStatus ? <div className="panel-body_loader" style={{ minHeight: '35px'}} > <span style={{fontSize: '25px', width: '100%', margin: 'auto', textAlign: 'center'}}><i className="fa fa-spinner fa-spin"/> please wait ...!</span> </div>: ''}
             {this.state.showIcon?
             <div className="item">
             {this.state.isOccuiped?
              <div className="green">
                 <img src="/images/tick.svg" />
                 <p>Occupied</p>
               </div>
               :
               <div className="red">
                          <img src="/images/cancel.svg"/>
                          <p> Not Occupied  </p>
                       </div>
             }
             </div>
             :
             <div className="red">{this.state.errorMessage}</div>
             }
             {/* {this.state.showIcon && this.errorMessage == '' ?
             <div className="item">
             {this.state.isOccuiped?
              <div className="green">
                 <img src="/images/tick.svg" />
                 <p>Occupied</p>
               </div>
               :
               <div className="red">
                          <img src="/images/cancel.svg"/>
                          <p> Not Occupied  </p>
                       </div>
             }
                </div>
             
                :<div className="red">{this.state.errorMessage}</div>
             } */}
            
   
           </div>
         </div>
       );
     }
   }
   export default OccupancyChecker;