/* Importing the node modules, child components, services and controllers used 
   inside StripeInternshipCommunities component */ 
   import $ from "jquery";
   import MainController from "../../controller/mainController";
   import SubFooter from "../../common/subFooter";
   import StripeInternshipHeader from "./common/stripeInternHeader";
   import Location from "../location/locations";
   import ContactUs from "./common/contactus";
   import Footer from '../../common/footer';
   import StripeInternFooter from "./common/stripeInternFooter";
   /* StripeInternshipCommunities Component initialization */
   class StripeInternshipCommunities extends React.Component {
       /* Initializing objects of its StripeInternshipCommunities class */
       constructor(props) {
           super(props)
           this.state={
               companyname:'StripeIntern'
           }
         }
         /* It is invoked immediately before mounting occurs */
         componentWillMount() {
           MainController.getInitialInformation();
         }
       /* It is invoked to return html content */
       render() {
           return (
            <div className="corporateHousing_Updates">
                <header className="codeconveyHeader cc_image_text_main corporate-h">
                    {/* <img className="img-responsive" src={s3BucketUrl_WebsiteImages+"SuiteAmerica-Guest-Portal-Main-Location_Down.jpg"} width="100%" alt=""  /> */}
                    <img className="img-responsive" src='https://s3-us-west-1.amazonaws.com/suiteam-website/Website-Images/SuiteAmerica-Company-Nike-Internship-Banner.jpg' width="100%" alt=""  />
                    <div className="wow fadeInUp img-on-text top30 width600 text-center left50 img-on-text-center" style={{marginTop:40}} data-wow-delay="0.5s" >
                        <h1>Stripe Communities</h1>
                        <p>Conveniently Located Corporate Housing</p>
                    </div>
                </header>
                <StripeInternshipHeader />
                {/* <div className="container-fluid nike company_section">
                    <div className="container text-center">             
                        <p> Your actual apartment will be assigned by SuiteAmerica approximately 21 days prior to your arrival date so the sooner you register, the sooner we can match you up with a roommate.  Housing is not guaranteed and is a first come, first served program, so fill out the questionnaire as soon as possible to have the best chance of an assignment.</p>
                       
                        <div className="who_weare_text service_ch_new company_p cm_Rates">
                        <h2 className="">Community Rates</h2>
                            <div className="row d-flex justify-content-center">
                               
                                <div className="col-md-6 col-sm-6">
                                <div className="dual_quote list_block dual_quote_md wow fadeInUp" data-wow-delay="0.5s">
                                    <h3>SUBURB COMMUNITIES</h3>
                                    <p>Beaverton/Hillsboro/Portland Suburb Communities</p>
                                    <div className="">
                                     
                                            <div>1x1 (single or double occupancy): $150/day total</div>
                                            
                                            <div>2x2 (double or quad occupancy): $190/day total</div>
                                            <div>3x2 (single or multiple occupancy): $233/day total</div>
                                           
                                      
                                    </div>
                                </div>
                                </div>
                            </div>
                    </div>
                    <p className="small-font">*In order to get the best rate available, please book as early as possible so that SuiteAmerica can pair you with a roommate and an apartment. Pricing and availability is on a first come, first served basis. Please note that once our apartment inventory is full or if it’s within 30 days from your arrival, SuiteAmerica will be unable to provide any further housing options.</p>
                    
                    
                    </div>
                    
                </div>

                <div className="container-fluid nike text-center">
                <div className="container">
                <p>Click on the properties below for more information. Please click <a href="https://www.google.com/maps/d/u/2/edit?mid=12Gcl-jlCOfhtqQrIOQVNMU6QuV1gWiT0&usp=sharing" target="_blank" style={{'color':'#0066cc', 'fontSize':'16px'}}><b>here</b></a> to view Public transit information.</p>
                        <h2>Communities</h2>
                    </div>
                    </div> */}

                
                
                <Location companyName={this.state.companyname} props={this.props}/>

                <div className="container-fluid bg-grey">
                    <div className="container">
                        <ContactUs />
                    </div>
                </div>
                <div className="container ft-contact-adrs mrg-btm-30 mrg-top-30">
                    <StripeInternFooter />
                </div>
                <SubFooter />
                <Footer/>
            </div>   
           );
       }
   }
   export default StripeInternshipCommunities;