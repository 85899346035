/* Importing the node modules, child components, services and controllers used 
   inside DriverManagerSchedule component */
import $ from "jquery";
import { Link } from "react-router-dom";
import AppController from "../../../controller/appController";
import DiverScheduleMap from "./diverScheduleMap";
import DatePicker from "react-datepicker";
import FiveStarApiServices from "../../../../services/fiveStarApiServices";
import ScheduleMapViewHeading from "./scheduleComponents/scheduleMapViewHeading";
import FirstRowFields from "./scheduleComponents/firstRowFields";
import SecondRowFields from "./scheduleComponents/secondRowFields";
import ThirdRowFields from "./scheduleComponents/thirdRowFields";
import BedsList from "./scheduleComponents/bedsList";
import S3bucketImages from "./s3bucketImages";
import FiveStarService from "../../../../services/fiveStarService";
import RescheduleModal from "./rescheduleModal";
import FunStuffModal from "./funStuffModal";
import SessionService from "../../../../services/sessionService";
import S3BucketUpload from './s3bucketUpload';

/* DriverManagerSchedule Component initialization */
class DriverManagerSchedule extends React.Component {
  /* Initializing objects of its DriverManagerSchedule class */
  constructor(props) {
    super(props);
    /* DriverManagerSchedule Component State variables Initialization */
    this.state = {
      orderData: props.ordersData,
      stateValues: props.stateValues,
      driversList: [],
      cc: "Drivers",
      status: "clear",
      statusType: "Status Code",
      typeCode: "Type Code",
      filterData: [],
      isFilter: false,
      statusList: ["started", "On Hold", "completed"],
      states: "States",
      profitCode: "Profit Code",
      s3bucketImages: [],
      error: 0,
      reScheduleText: "",
      reSchedule: {},
      reScheduleIndex: -1,
      funstuff: {},
      filterValue: "",
      Driver_Cleaner_HelperList: [],
      driverid: "",
      cleanerid: "",
      helperid: "",
      recordUniqueId: "",
      msg: "",
      recordType: "",
      errmsg: "",
      driverManagerInfo: {},
      isSort: false,
      requestDetails: []
    };
    this.changeIndex = this.changeIndex.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.statusVal = this.statusVal.bind(this);
    this.statescode = this.statescode.bind(this);
    this.typeCode = this.typeCode.bind(this);
    this.profitCode = this.profitCode.bind(this);
    this.statusType = this.statusType.bind(this);
    this.setValue = this.setValue.bind(this);
    this.imagesGet = this.imagesGet.bind(this);
    this.reSchedule = this.reSchedule.bind(this);
    this.clearFormData = this.clearFormData.bind(this);
    this.funstuff = this.funstuff.bind(this);
    this.mastersSorting = this.mastersSorting.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
  }
  /* It is invoked immediately before a component is mounted */
  async componentWillMount() {
    $("html, body").animate({ scrollTop: 0 });
    AppController.stickyTitles();
  }
  async componentDidMount() {
    const driverInformation = await SessionService.SessionGet({
      type: "fivestar",
    });
    let obj = {
      Company: driverInformation.Company,
      UniqueId: driverInformation.UniqueId,
    };
    if (driverInformation.IsVendorManager == true) {
      const resultData = await FiveStarApiServices.GetDriversListByCompany(obj);
      if (resultData.error != 1) {
        this.setState({ Driver_Cleaner_HelperList: resultData.message });
      }
    }
    this.setState({ driverManagerInfo: driverInformation });

    // await this.driversList();
  }
  async driversList() {
    const request = { date: localStorage.getItem("startDate") };
    let driversList = []; //await FiveStarApiServices.getDriverList(request);

    //await this.setState({driversList:driversList});
  }
  // Drivers List Filter
  async setValue(field, e) {
    this.setState({
      sFilter: false,
      cc: e.target.value,
      status: "clear",
      profitCode: "Profit Code",
      typeCode: "Type Code",
      statusType: "Status Code",
      states: "States",
      filterValue: "Sort",
      isSort: false,
    });
    var filteredDriver = {};
    filteredDriver = _.filter(this.state.orderData, function (o) {
      return (
        o.DriverUId == e.target.value ||
        o.CleanerUid == e.target.value ||
        o.HelperUid == e.target.value
      );
    });
    this.setState({ filterData: filteredDriver, isFilter: true });
  }
  //filters
  async onChangeValue(field, e) {
    var object = {};
    object[field] = e.target.value;
    await this.setState(object);
    await this.mastersSorting();
  }
  // Schedules Service List
  async statusVal(field, e) {
    this.setState({
      sFilter: false,
      status: e.target.value,
      cc: "Drivers",
      profitCode: "Profit Code",
      typeCode: "Type Code",
      statusType: "Status Code",
      states: "States",
      filterValue: "Sort",
      isSort: false,
    });
    if (e.target.value == "schedule") {
      var filteredService = {};
      filteredService = _.filter(this.state.orderData, function (o) {
        return o.type == e.target.value;
      });
      this.setState({ filterData: filteredService, isFilter: true });
    } else if (e.target.value == "service") {
      var filteredService = {};
      filteredService = _.filter(this.state.orderData, function (o) {
        return o.type == e.target.value;
      });
      this.setState({ filterData: filteredService, isFilter: true });
    } else {
      this.setState({ filterData: this.state.orderData, isFilter: true });
    }
  }

  // statusType
  async statusType(field, e) {
    this.setState({
      sFilter: false,
      statusType: e.target.value,
      cc: "Drivers",
      profitCode: "Profit Code",
      typeCode: "Type Code",
      status: "clear",
      states: "States",
      filterValue: "Sort",
      isSort: false,
    });
    var filteredStatusType = {};
    filteredStatusType = _.filter(this.state.orderData, function (o) {
      return o.FivestarStatus == e.target.value;
    });
    this.setState({ filterData: filteredStatusType, isFilter: true });
  }
  // typeCode
  async typeCode(field, e) {
    this.setState({
      sFilter: false,
      typeCode: e.target.value,
      cc: "Drivers",
      profitCode: "Profit Code",
      status: "clear",
      statusType: "Status Code",
      states: "States",
      filterValue: "Sort",
      isSort: false,
    });
    var filteredTypeCode = {};
    filteredTypeCode = _.filter(this.state.orderData, function (o) {
      return o.TypeCode == e.target.value;
    });
    this.setState({ filterData: filteredTypeCode, isFilter: true });
  }
  // profitCode
  async profitCode(field, e) {
    this.setState({
      sFilter: false,
      profitCode: e.target.value,
      cc: "Drivers",
      typeCode: "Type Code",
      status: "clear",
      statusType: "Status Code",
      states: "States",
      filterValue: "Sort",
      isSort: false,
    });
    var filteredProfitCode = {};
    filteredProfitCode = _.filter(this.state.orderData, function (o) {
      return o.ProfitCenterCode == e.target.value;
    });
    this.setState({ filterData: filteredProfitCode, isFilter: true });
  }
  // states
  async statescode(field, e) {
    this.setState({
      sFilter: false,
      states: e.target.value,
      cc: "Drivers",
      typeCode: "Type Code",
      profitCode: "Profit Code",
      status: "clear",
      statusType: "Status Code",
      filterValue: "Sort",
      isSort: false,
    });
    var filteredStateName = {};
    filteredStateName = _.filter(this.state.orderData, function (o) {
      return o.state_name == e.target.value;
    });
    this.setState({ filterData: filteredStateName, isFilter: true });
  }
  /* To update the state in response to prop changes */
  async componentWillReceiveProps(nextProps) {
    let driversList = [];

    _.forEach(nextProps.ordersData, function (data) {
      if (data.DriverUId != null) {
        driversList.push({
          UniqueId: data.DriverUId,
          Identifier: data.driverName,
        });
      }
      if (data.CleanerUid != null) {
        driversList.push({
          UniqueId: data.CleanerUid,
          Identifier: data.cleaner,
        });
      }
      if (data.HelperUid != null) {
        driversList.push({
          UniqueId: data.HelperUid,
          Identifier: data.helper,
        });
      }
    });
    console.log(nextProps.ordersData, "===", driversList);
    await this.setState({
      driversList: _.uniqBy(driversList, "UniqueId"),
      orderData:
        this.state.isSort == false
          ? nextProps.ordersData
          : this.state.orderData,
      stateValues: nextProps.stateValues,
    });
  }
  /* To active the Schedule View / Map View */
  changeIndex(index) {
    this.props.changeIndex(index);
  }
  /* To get the selected date from date picker */
  async handleChange(date) {
    this.setState({
      cc: "Drivers",
      status: "clear",
      typeCode: "Type Code",
      statusType: "Status Code",
      isFilter: false,
      states: "States",
      filterValue: "Sort",
      isSort: false,
    });
    this.props.handleChange(date);
    await this.driversList();
  }
  async imagesGet(row) {
    this.setState({
      s3bucketImages: [],
      error: 0,
    });
    const request = {
      path:
        row.type + "/" + row.UniqueId.toUpperCase().replace(/ /g, "_") + "/",
    };
    const response = await FiveStarService.s3BucketImages(request);
    this.setState({
      s3bucketImages: response.result,
      error: response.result.length == 0 ? 400 : 200,
    });
  }

  reSchedule() {
    if (this.state.reScheduleText != "") {
      setTimeout(
        async function () {
          jQuery(
            function ($) {
              $("#reschedule").modal("hide");
              this.setState({ reScheduleText: "", message: "", color: "" });
            }.bind(this)
          );
        }.bind(this),
        700
      );
      $("html, body").animate({ scrollTop: 0 }, 1000);
    } else {
      this.setState({
        message: "Please Enter Reason for reschedule",
        color: "red",
      });
    }
  }
  clearFormData(row, index) {
    this.setState({
      modalLoader: false,
      reSchedule: row,
      reScheduleIndex: index,
    });
  }
  async funstuff(row) {
    await this.setState({ funstuff: row });
  }
  async mastersSorting() {
    var name_ordertype = this.state.filterValue.split(" ");
    console.log(this.state.orderData, "===1");
    const filteredMaster = _.orderBy(
      this.state.orderData,
      [name_ordertype[0]],
      [name_ordertype[1]]
    );
    await this.setState({
      isSort: true,
      isFilter: false,
      typeCode: "Type Code",
      cc: "Drivers",
      profitCode: "Profit Code",
      status: "clear",
      statusType: "Status Code",
      states: "States",
      orderData: filteredMaster,
    });
  }
  async setValues(field, e) {
    var object = {};
    object[field] = e.target.value;
    $("#" + field).removeClass("validation");
    await this.setState(object);
  }
  async driverSel(row) {
    const requestObj = {
      uniqueid: this.state.driverManagerInfo.UniqueId,
      isManager: this.state.driverManagerInfo.IsManager == 1 ? true : false,
      toDate: localStorage.getItem("startDate"),
      IsVendorManager: this.state.driverManagerInfo.IsVendorManager,
      Company: this.state.driverManagerInfo.Company,
    };
    //const resultData = await FiveStarApiServices.GetServicesSchedulesByDriverIdManager(requestObj);
    console.log(this.state.orderData, "1");
    const resultData = Object.assign(this.state.orderData);
    let testingdata = resultData.filter((x) => x.UniqueId == row.UniqueId);
    let ddata = this.state.Driver_Cleaner_HelperList;
    let DriverUId = ddata.filter(
      (id) => id.UniqueId == testingdata[0].DriverUId
    );
    let CleanerUid = ddata.filter(
      (id) => id.UniqueId == testingdata[0].CleanerUid
    );
    let HelperUid = ddata.filter(
      (id) => id.UniqueId == testingdata[0].HelperUid
    );
    $("#driverid").removeClass("validation");
    await this.setState({
      recordUniqueId: row.UniqueId,
      recordType: row.type,
      msg: "",
      errmsg: "",
      driverid:
        DriverUId !== undefined && DriverUId.length > 0
          ? DriverUId[0].UniqueId
          : "",
      cleanerid:
        CleanerUid !== undefined && CleanerUid.length > 0
          ? CleanerUid[0].UniqueId
          : "",
      helperid:
        HelperUid !== undefined && HelperUid.length > 0
          ? HelperUid[0].UniqueId
          : "",
    });

    jQuery(
      function ($) {
        $("#driverselection").modal("show");
      }.bind(this)
    );
  }
  async submitSelction() {
    const validationFields = ["driverid"];
    let error = 0;
    let _this = this;
    _.forEach(validationFields, function (value) {
      if (_this.state[value] == "") {
        $("#" + value).addClass("validation");
        error++;
      } else {
        $("#" + value).removeClass("validation");
      }
    });
    if (error == 0) {
      let obj = {
        UniqueId: this.state.recordUniqueId,
        DriverUId: this.state.driverid,
        CleanerUId: this.state.cleanerid,
        HelperUId: this.state.helperid,
        RecordType:
          this.state.recordType.charAt(0).toUpperCase() +
          this.state.recordType.slice(1),
      };
      const resultData = await FiveStarApiServices.AssignDriver(obj);
      if (resultData.error == 1) {
        await this.setState({ errmsg: resultData.message });
      } else {
        let ddata = this.state.Driver_Cleaner_HelperList;
        let listdataDriver = [this.state.driverid];
        let listdataCleaner = [this.state.cleanerid];
        let listdataHelper = [this.state.helperid];

        let fullresultData = Object.assign(this.state.orderData);
        let arrayStopindex = fullresultData.findIndex(
          (x) => x.UniqueId == this.state.recordUniqueId
        );
        fullresultData[arrayStopindex].DriverUId = this.state.driverid;
        fullresultData[arrayStopindex].HelperUid = this.state.helperid;
        fullresultData[arrayStopindex].CleanerUid = this.state.cleanerid;
        fullresultData[arrayStopindex].driverName = ddata.filter((id) =>
          listdataDriver.includes(id.UniqueId)
        )[0].Identifier;
        if (this.state.cleanerid != "")
          fullresultData[arrayStopindex].cleaner = ddata.filter((id) =>
            listdataCleaner.includes(id.UniqueId)
          )[0].Identifier;
        if (this.state.helperid != "")
          fullresultData[arrayStopindex].helper = ddata.filter((id) =>
            listdataHelper.includes(id.UniqueId)
          )[0].Identifier;

        let FilterfullresultData = Object.assign(this.state.filterData);
        let FilterarrayStopindex = FilterfullresultData.findIndex(
          (x) => x.UniqueId == this.state.recordUniqueId
        );
        if (FilterarrayStopindex >= 0) {
          FilterfullresultData[FilterarrayStopindex].DriverUId =
            this.state.driverid;
          FilterfullresultData[FilterarrayStopindex].HelperUid =
            this.state.helperid;
          FilterfullresultData[FilterarrayStopindex].CleanerUid =
            this.state.cleanerid;
          FilterfullresultData[FilterarrayStopindex].driverName = ddata.filter(
            (id) => listdataDriver.includes(id.UniqueId)
          )[0].Identifier;
          if (this.state.cleanerid != "")
            FilterfullresultData[FilterarrayStopindex].cleaner = ddata.filter(
              (id) => listdataCleaner.includes(id.UniqueId)
            )[0].Identifier;
          if (this.state.helperid != "")
            FilterfullresultData[FilterarrayStopindex].helper = ddata.filter(
              (id) => listdataHelper.includes(id.UniqueId)
            )[0].Identifier;
        }

        let filterdata = ddata.filter((id) =>
          listdataDriver.includes(id.UniqueId)
        );
        if (this.state.cleanerid != "")
          filterdata.push(
            ddata.filter((id) => listdataCleaner.includes(id.UniqueId))[0]
          );
        if (this.state.helperid != "")
          filterdata.push(
            ddata.filter((id) => listdataHelper.includes(id.UniqueId))[0]
          );

        let smarr = filterdata.map((obj) => obj.Identifier);
        $("#driverssid" + this.state.recordUniqueId).html(smarr.toString());
        await this.setState({
          msg: resultData.message,
          orderData: fullresultData,
          filterdata: FilterfullresultData,
        });
      }
    }
  }
  async setTableId(row) {
    const driverDetails = {
      uniqueid: row.UniqueId
    }
    const resOrders = await FiveStarApiServices.getServiceScheduleById(driverDetails);
    this.setState({ requestDetails: resOrders[0] })
  }
  /* It is invoked to return html content */
  render() {
    const orderData = this.state.isFilter
      ? this.state.filterData
      : this.state.orderData;
    const finalOrder = _.uniqBy(this.state.orderData, "TypeCode");
    const filteredOrder = _.filter(finalOrder, function (o) {
      return o.TypeCode != null;
    });
    const finalstates = _.uniqBy(this.state.orderData, "state_name");
    const filteredStates = _.filter(finalstates, function (o) {
      return o.state_name != null;
    });
    const finalProfitCenterCode = _.uniqBy(
      this.state.orderData,
      "ProfitCenterCode"
    );
    const filteredProfitCenterCode = _.filter(
      finalProfitCenterCode,
      function (o) {
        return o.ProfitCenterCode != null;
      }
    );
    let today = moment();
    let yesterday = moment().add(-1, 'days');
    let selectedDate = moment(this.state.startDate).format('YYYY-MM-DD')

    return (
      <div id="pages_5star">
        <ScheduleMapViewHeading
          activeIndex={this.state.stateValues.activeIndex}
          changeIndex={this.changeIndex}
        />
        <div className="container">
          <div className="driver_schedule">
            <div className="">
              <form className="form-inline">
                <div className="f-star-schedule-page-head">
                  <h2>
                    STOPS:
                    {this.state.stateValues.loadingStatus == true
                      ? null
                      : orderData.length}
                  </h2>
                </div>
                <div className="driver-manager-date-picker mrg-btm-15">
                  <DatePicker
                    maxDate={
                      this.state.driverManagerInfo.IsVendorManager == true &&
                      moment().add(7, "d")
                    }
                    minDate={
                      this.state.driverManagerInfo.IsVendorManager == true &&
                      moment().subtract(60, "d")
                    }
                    selected={this.state.stateValues.startDate}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-group">
                  <select
                    id="sel3"
                    className="form-control"
                    value={this.state.status}
                    onChange={(e) => this.statusVal("status", e)}
                  >
                    <option value="clear">All</option>
                    <option value="service">Service</option>
                    <option value="schedule">Schedule</option>
                  </select>
                </div>
                <div className="form-group">
                  <select
                    id="sel3"
                    className="form-control"
                    value={this.state.states}
                    onChange={(e) => this.statescode("states", e)}
                  >
                    <option value="States" disabled>
                      States
                    </option>
                    {filteredStates.map((row, index) => {
                      return (
                        <option value={row.state_name} key={index}>
                          {row.state_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-group">
                  <select
                    id="sel3"
                    className="form-control"
                    value={this.state.typeCode}
                    onChange={(e) => this.typeCode("typeCode", e)}
                  >
                    <option value="Type Code" disabled>
                      Type Code
                    </option>
                    {filteredOrder.map((row, index) => {
                      return (
                        <option value={row.TypeCode} key={index}>
                          {row.TypeCode}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-group">
                  <select
                    id="sel3"
                    className="form-control"
                    value={this.state.profitCode}
                    onChange={(e) => this.profitCode("profitCode", e)}
                  >
                    <option value="Profit Code" disabled>
                      Profit Code
                    </option>
                    {filteredProfitCenterCode.map((row, index) => {
                      return (
                        <option value={row.ProfitCenterCode} key={index}>
                          {row.ProfitCenterCode}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-group">
                  <select
                    id="sel2"
                    className="form-control"
                    value={this.state.statusType}
                    onChange={(e) => this.statusType("statusType", e)}
                  >
                    <option value="Status Code" disabled>
                      Status Code
                    </option>
                    {this.state.statusList.map((row, index) => {
                      return (
                        <option value={row} key={index}>
                          {row}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-group">
                  <select
                    className="form-control"
                    id="sel1"
                    value={this.state.cc}
                    onChange={(e) => this.setValue("cc", e)}
                  >
                    <option value="Drivers" disabled>
                      Drivers{" "}
                    </option>
                    {this.state.driversList.map((row, index) => {
                      return (
                        <option value={row.UniqueId} key={index}>
                          {row.Identifier}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="form-group">
                  <select
                    id="sel3"
                    className="form-control master-filters"
                    onChange={(e) => this.onChangeValue("filterValue", e)}
                    value={this.state.filterValue}
                  >
                    <option value="">Sort</option>
                    <option value="community_name asc">Community ASC</option>
                    <option value="community_name desc">Community DESC</option>
                  </select>
                </div>
              </form>
            </div>
          </div>
          <div className="clearfix"></div>
          {this.state.stateValues.loadingStatus == true ? (
            <div className="panel-body_loader" style={{ minHeight: "300px" }}>
              {" "}
              <span
                style={{
                  fontSize: "25px",
                  width: "100%",
                  margin: "auto",
                  textAlign: "center",
                }}
              >
                <i className="fa fa-spinner fa-spin" /> please wait ...!
              </span>
            </div>
          ) : null}
          {this.state.stateValues.loadingStatus == false &&
            orderData.length == 0 ? (
            <div
              style={{
                color: "red",
                textAlign: "center",
                fontSize: "25px",
                minHeight: "300px",
              }}
            >
              No Orders For This Date
            </div>
          ) : null}

          {this.state.stateValues.activeIndex == 1 ? (
            <div>
              {orderData.map((row, index) => {
                const NotesData =
                  row.Notes == "" || row.Notes == null
                    ? []
                    : row.Notes.split("####");
                const notesLimit =
                  this.state.stateValues.loadMoreIndex == index
                    ? _.filter(NotesData, function (o) {
                      return o != "";
                    })
                    : NotesData.slice(0, 3);
                const otherString = [];
                row.driverName != null ? otherString.push(row.driverName) : "";
                row.cleaner != null ? otherString.push(row.cleaner) : "";
                row.helper != null ? otherString.push(row.helper) : "";
                let user = row.IsGuestActive ? (
                  <i className="fa fa-users" style={{ color: "blue" }}></i>
                ) : (
                  ""
                );
                let pet =
                  row.petscount == 0 ? (
                    ""
                  ) : (
                    <i className="fa fa-paw" style={{ color: "blue" }}></i>
                  );
                let isPte = row.IsPte ? (
                  <i className="fa fa-check-square" style={{ color: "blue" }}>
                    PTE has been granted
                  </i>
                ) : (
                  ""
                );
                let reschedule = (
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target="#reschedule"
                    onClick={() => this.clearFormData(row, index)}
                    className={
                      row.IsReSchedule
                        ? "reschedule pull-right mrg-left-10 btn btn-success"
                        : "reschedule pull-right mrg-left-10 btn btn-danger"
                    }
                  >
                    {row.IsReSchedule ? "Reschedule Requested" : "Reschedule"}
                  </a>
                );
                let driverselect = (
                  <a
                    href="#"
                    onClick={() => this.driverSel(row)}
                    className="color-red font-size-18 mrg-left-10"
                  >
                    <i className="bi bi-truck" style={{ color: "blue" }}></i>
                  </a>
                );
                return (
                  <div
                    className="schedule-order-contain mrg-btm-30"
                    key={index}
                  >
                    {row.FivestarStartDateTime != null &&
                      row.FivestarStopDateTime != null ? (
                      <a disabled className="sc-order-id clearfix ohr">
                        <p className="pull-left bold">
                          Order : {row.TableId == null ? " --- " : row.TableId}{" "}
                          , Master :{" "}
                          {row.mastertableId == null
                            ? " --- "
                            : row.mastertableId}{" "}
                          {user} {pet} {isPte}{" "}
                          <span style={{ color: "green" }}>(Completed)</span>{" "}
                        </p>{" "}
                        {row.IsReSchedule ? reschedule : null}{" "}
                        <p className="pull-right bold">
                          {this.state.driverManagerInfo.IsVendorManager
                            ? driverselect
                            : ""}
                          <span id={"driverssid" + row.UniqueId}>
                            {otherString.toString()}
                          </span>
                        </p>{" "}
                      </a>
                    ) : row.FivestarStartDateTime != null &&
                      row.FivestarStopDateTime == null ? (
                      <a disabled className="sc-order-id clearfix ohr">
                        {" "}
                        {row.IsReSchedule ? reschedule : null}
                        <p className="pull-right bold"> </p>
                        <p className="pull-left bold">
                          Order : {row.TableId == null ? " --- " : row.TableId}{" "}
                          , Master :{" "}
                          {row.mastertableId == null
                            ? " --- "
                            : row.mastertableId}{" "}
                          {user} {pet} {isPte}{" "}
                          <span style={{ color: "orange" }}>(pending)</span>{" "}
                        </p>{" "}
                        <p className="pull-right bold">
                          {this.state.driverManagerInfo.IsVendorManager
                            ? driverselect
                            : ""}
                          <span id={"driverssid" + row.UniqueId}>
                            {" "}
                            {otherString.toString()}
                          </span>
                        </p>{" "}
                      </a>
                    ) : (
                      <a disabled className="sc-order-id clearfix">
                        {row.IsReSchedule ? reschedule : null}
                        <p className="pull-right bold">
                          {this.state.driverManagerInfo.IsVendorManager
                            ? driverselect
                            : ""}
                          <span id={"driverssid" + row.UniqueId}>
                            {" "}
                            {otherString.toString()}
                          </span>
                        </p>
                        <p className="pull-left bold">
                          Order : {row.TableId == null ? " --- " : row.TableId}{" "}
                          , Master :{" "}
                          {row.mastertableId == null
                            ? " --- "
                            : row.mastertableId}{" "}
                          {user} {pet} {isPte}{" "}
                          {row.ServiceStatus == "started" ? (
                            <span style={{ color: "#0c65f4" }}>(Hold)</span>
                          ) : null}
                        </p>{" "}
                      </a>
                    )}
                    <div className="row">
                      <FirstRowFields rowData={row} manager={true} />
                      <SecondRowFields rowData={row} manager={true} />
                      <ThirdRowFields
                        rowData={row}
                        NotesData={NotesData}
                        manager={true}
                      />
                      <BedsList rowData={row} />
                      <div className="col-sm-12">
                        {row.type == "schedule" &&
                          (row.TypeCode == "T/O" || row.TypeCode == "W/T") &&
                          row.AptReady == "1" ? (
                          <div
                            className="mrg-top-25 fivestaraptupdate pull-right"
                            style={{ cursor: "pointer" }}
                          >
                            Suite Is Ready
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="order-header-item more-phots pull-right mrg-top-30 mrg-right-15 ">
                          {row.TypeCode == "T/O" ||
                            row.TypeCode == "W/T" ||
                            row.TypeCode == "EXEW/T" ||
                            row.TypeCode == "M/G" ||
                            row.TypeCode == "EXET/O" ||
                            row.TypeCode == "T/O SS" ? (
                            <span className="mrg-right-15">
                              {row.AppCode != null ? (
                                <i className="fa fa-television"></i>
                              ) : null}{" "}
                              {row.AppCode}
                            </span>
                          ) : null}
                          {
                            (moment(selectedDate).isSame(today, 'day') || moment(selectedDate).isSame(yesterday, 'day')) ?
                              <a href="#" data-toggle="modal"
                                data-target="#uploadFilesData"
                                className="color-red font-size-18" style={{ padding: '0px 10px' }}>
                                <i className="fa fa-upload" onClick={() => this.setTableId(row)}></i></a>
                              :
                              null}
                          <a
                            href="#"
                            data-toggle="modal"
                            data-target="#morePhotos"
                            className="color-red font-size-18"
                            onClick={() => this.imagesGet(row)}
                          >
                            <i className="fa fa-camera"></i>
                          </a>
                          {row.FunStuffList != null ? (
                            <a
                              href="#"
                              data-toggle="modal"
                              data-target="#funstuff"
                              className="color-red font-size-18 mrg-left-10"
                              onClick={() => this.funstuff(row)}
                            >
                              <i className="fa fa-truck"></i>
                            </a>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}
          {this.state.stateValues.activeIndex == 0 ? (
            <DiverScheduleMap ordersData={orderData} />
          ) : null}
        </div>
        <S3bucketImages {...this.state} />
        <S3BucketUpload {...this.state} />
        <RescheduleModal
          {...this.state}
          index={this.state.reScheduleIndex}
          rowData={this.state.reSchedule}
          reSchedule={this.reSchedule}
          setValue={this.setValue}
        />
        <FunStuffModal {...this.state} rowData={this.state.funstuff} />
        <div
          className="modal fade savesearch"
          data-backdrop="static"
          id="driverselection"
          role="dialog"
        >
          <div className="modal-dialog">
            <div className="modal-content rm-border-radius">
              <div className="modal-header">
                <button
                  type="button"
                  className="close btn"
                  data-dismiss="modal"
                  aria-label=""
                >
                  <i className="bi bi-x"></i>
                </button>
              </div>
              <div className="modal-body">
                {/* <label>Driver</label>
                    <select></select>
                    <label>Cleaner</label>
                    <label>Helper</label> */}
                {this.state.msg !== "" ? (
                  <div className="alert alert-success bold">
                    {this.state.msg}
                  </div>
                ) : (
                  <div>
                    <h3>Add/Update Personnel</h3>
                    {this.state.errmsg !== "" ? (
                      <div className="alert alert-danger bold">
                        {this.state.errmsg}
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="f-contact-form-style">
                      <div className="form-group">
                        <label>
                          Driver <span style={{ color: "red" }}>*</span>{" "}
                          <b>:</b>
                        </label>{" "}
                        <select
                          className="form-control"
                          id="driverid"
                          value={this.state.driverid}
                          onChange={(e) => this.setValues("driverid", e)}
                        >
                          <option value="">Select Driver</option>
                          {this.state.Driver_Cleaner_HelperList.map(
                            (row, index) => {
                              if (
                                row.UniqueId == this.state.cleanerid ||
                                row.UniqueId == this.state.helperid
                              ) {
                                return;
                              }
                              return (
                                <option value={row.UniqueId} key={index}>
                                  {row.Identifier}
                                </option>
                              );
                            }
                          )}
                        </select>
                      </div>
                      <div className="form-group">
                        <label>
                          Cleaner <b>:</b>
                        </label>{" "}
                        <select
                          className="form-control"
                          id="cleanerid"
                          value={this.state.cleanerid}
                          onChange={(e) => this.setValues("cleanerid", e)}
                        >
                          <option value="">Select Cleaner</option>
                          {this.state.Driver_Cleaner_HelperList.map(
                            (row, index) => {
                              if (
                                row.UniqueId == this.state.driverid ||
                                row.UniqueId == this.state.helperid
                              ) {
                                return;
                              }
                              return (
                                <option value={row.UniqueId} key={index}>
                                  {row.Identifier}
                                </option>
                              );
                            }
                          )}
                        </select>
                      </div>
                      <div className="form-group">
                        <label>
                          Helper <b>:</b>
                        </label>{" "}
                        <select
                          className="form-control"
                          id="helperid"
                          value={this.state.helperid}
                          onChange={(e) => this.setValues("helperid", e)}
                        >
                          <option value="">Select Helper</option>
                          {this.state.Driver_Cleaner_HelperList.map(
                            (row, index) => {
                              if (
                                row.UniqueId == this.state.cleanerid ||
                                row.UniqueId == this.state.driverid
                              ) {
                                return;
                              }
                              return (
                                <option value={row.UniqueId} key={index}>
                                  {row.Identifier}
                                </option>
                              );
                            }
                          )}
                        </select>
                      </div>
                      <button
                        type="submit"
                        onClick={(e) => this.submitSelction(e)}
                        className="btn black-btn"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default DriverManagerSchedule;
