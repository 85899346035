/* Importing the node modules, child components, services and controllers used 
   inside Guest component */
import { Link } from 'react-router-dom';
import MainController from '../controller/mainController';
import GuestController from '../controller/guestController';
import SessionService from "../../services/sessionService";
import GuestServicesapi from '../../services/guestServicesapi';
import moment from "moment";
/* Guest Component initialization */
class Guest extends React.Component {
    /* Initializing objects of its Guest class */
    constructor(props) {
        super(props)
        /* Guest Component State variables Initialization */
        this.state = {
            navigationList: [
                // {name: 'HOME',url: '/guestportal'},
                // {name: 'ARRIVAL INFO',url: '/trip-info-data'},
                { name: 'MOVING IN', url: '/moving-in' },
                { name: 'MOVING OUT', url: '/moving-out' },
                { name: 'INSTRUCTIONS', url: '/instructions' },
                { name: 'SERVICE REQUEST', url: '/guest-services' },
                /* {name: 'RESERVATIONS',url: '/reservations'}, */
            ],
            userData: {},
            guestSessionResponse: {},
            hideMenu: false
        }
    }
    /* It is invoked immediately after a component is mounted */
    async componentDidMount() {
        MainController.removeClassOnNavCollapse();
        const guestLoginResponse = await GuestController.guestLoginResponse();
        await this.setState({ userData: guestLoginResponse });
        const GetResponse = await SessionService.SessionGet({ type: 'guest' });
        await this.setState({ guestSessionResponse: GetResponse });
        if (GetResponse.roleId == 2) {
            var guestEmailObj = {
                guestEmail: GetResponse.GuestEmail,
                status: "active"
            }
            const GetOrdersByGuestEmailResponse = await GuestServicesapi.GetOrdersByGuestEmail(guestEmailObj)
            if (GetOrdersByGuestEmailResponse.message.Orders.length == 0) {
                this.setState({ hideMenu: true })
            } else {
                var currentDate = moment().format("YYYY-MM-DD");
                var navList = this.state.navigationList
                var orderUIdObj = {
                    OrderUId: GetOrdersByGuestEmailResponse.message.Orders[0].OrderUId
                }
                GuestServicesapi.GetOrderInfoById(orderUIdObj).then((getOrderInfo) => {
                      var MoveInDate=moment(getOrderInfo.message.orderInfo.MoveInDate).format("YYYY-MM-DD");
                      var MoveOutDate=moment(getOrderInfo.message.orderInfo.MoveOutDate).format("YYYY-MM-DD");
                    if (MoveInDate == currentDate) {
                        GuestServicesapi.GetInOutOrdersLog({ orderUid: orderUIdObj.OrderUId }).then((GetInOutOrdersResponse) => {
                            console.log(GetInOutOrdersResponse, "GetInOutOrdersResponse")
                            if (GetInOutOrdersResponse.length > 0) {
                                var checkIn = GetInOutOrdersResponse.filter(e => e.Type == "IN")
                                if (checkIn.length == 0) {
                                    navList.push({ name: 'CHECK IN', url: '/check-in' })
                                    this.setState({ navigationList: navList })
                                } 
                            } else {
                                navList.push({ name: 'CHECK IN', url: '/check-in' })
                                this.setState({ navigationList: navList })
                            }
                        })
                    }
                    if (MoveOutDate == currentDate) {
                        GuestServicesapi.GetInOutOrdersLog({ orderUid: orderUIdObj.OrderUId }).then((GetInOutOrdersResponse) => {
                            console.log(GetInOutOrdersResponse, "GetInOutOrdersResponse")
                            if (GetInOutOrdersResponse.length > 0) {
                                var checkOut = GetInOutOrdersResponse.filter(e => e.Type == "OUT")
                                if (checkOut.length == 0) {
                                    navList.push({ name: 'CHECK OUT', url: '/check-out' })
                                    this.setState({ navigationList: navList })
                                } 
                            } else {
                                navList.push({ name: 'CHECK IN', url: '/check-in' })
                                this.setState({ navigationList: navList })
                            }
                        })
                    }

                })
            }
        }
    }

    /* It is invoked to return html content */
    render() {
        const { navigationList, userData } = this.state;
        const navList = userData.status !== 'TERMED' ? navigationList : [{ name: 'ARRIVAL INFO', url: '/trip-info-data' }]
        return (
            <div className="profile_submenu">
                <div className="container">
                    <nav className="navbar navbar-inverse">
                        <div className="navbar-header">
                            <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#guest-login" aria-expanded="false">
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                        </div>
                        {(!this.state.hideMenu)?
                            <div className="collapse navbar-collapse" id="guest-login">
                                <ul className="nav navbar-nav">
                                    {navList.map((row, index) => {
                                        return (<li key={index}><Link to={row.url}>{row.name}</Link></li>)
                                    })}
                                </ul>
                            </div>
                            : null}
                    </nav>

                    <div className="side-image-icon">
                        <Link to="/reservations">Reservations <img src="images/reservations.png" /></Link>
                    </div>

                </div>
            </div>
        );
    }
}
export default Guest;