const ImagesModalPopup = (props) => {
    const {index,communityImages}=props
    let Images=communityImages.map((row, i) => {
        return <div key={i} className="item" style={{"backgroundImage": "url("+row+")"}}></div>
    })
    return (
        <div className="custom-galery">
        <input className="no-icheck gal" type="checkbox" id={"op"+index} />
        <div className="lower"></div>
        <div className="overlay overlay-hugeinc">
            <label htmlFor={"op"+index} onClick={props.galleryClose}></label>
            <nav>
                <div className="owl-carousel owl-demo-3">
                     {Images}                             
                </div>                            
            </nav>
        </div>
    </div>
    )
}
export default ImagesModalPopup; 