import React, { Component } from "react";
import { Link } from "react-router-dom";

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.activeTab = window.location.pathname;
  }
  render() {
    return (
      <div className="left side-menu">
        <button
          type="button"
          className="button-menu-mobile button-menu-mobile-topbar open-left waves-effect"
        >
          <i className="ion-close"></i>
        </button>

        <div className="topbar-left">
          <div className="text-center">
            <a href="/" className="logo">
              <img
                src="/assets/newUI-images/logo/SA-full-logo.svg"
                height="30"
                alt="logo"
              />
            </a>
          </div>
        </div>

        <div className="sidebar-inner slimscrollleft">
          <div id="sidebar-menu">
            <ul>
              {/* <li className="menu-title">Main</li> */}

              <li id="dashboard">
                <Link
                  to="/dashboard"
                  className={`waves-effect ${
                    this.activeTab == "/dashboard" ? "active" : ""
                  }  `}
                  aria-label="dashboard"
                >
                  <i className="mdi mdi-airplay"></i>
                  <span> Dashboard </span>
                </Link>
              </li>

              <li id="booknow">
                <Link
                  to="/booknow-modal"
                  className={`waves-effect ${
                    this.activeTab == "/booknow-modal" ? "active" : ""
                  }  `}
                  aria-label="booknow"
                >
                  <i className="mdi mdi-calendar-today"></i>
                  <span> Book Now </span>
                </Link>
              </li>

              {/* <li className="menu-title">Components</li> */}

              {/* <li id="reservation">
                <Link
                  to="/reservation-lts"
                  className={`waves-effect ${
                    this.activeTab == "/reservation-lts" ? "active" : ""
                  }  `}
                  aria-label="reservation"
                >
                  <i className="mdi mdi-calendar-clock"></i>
                  <span>Reservations </span>
                </Link>
              </li> */}

              <li>
                <a href="#" className="waves-effect">
                  <i className="mdi mdi-file-multiple"></i>
                  <span>Reports </span>
                </a>
              </li>
              {/* <li>
                <a href="#" className="waves-effect">
                  <i className="mdi mdi-account-multiple"></i>
                  <span>Your Team </span>
                </a>
              </li> */}

              {/* <li className="menu-title">Extra</li>

              <li id="login">
                <a
                  href="/BookIT"
                  className={`waves-effect ${
                    this.activeTab == "/BookIT" ? "active" : ""
                  }  `}
                  aria-label="login"
                >
                  <i className="mdi mdi-login"></i>
                  <span>Login </span>
                </a>
              </li>
              <li>
                <a href="#" className="waves-effect">
                  <i className="mdi mdi-account-plus"></i>
                  <span>Register </span>
                </a>
              </li> */}
            </ul>
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
    );
  }
}

export default SideBar;
