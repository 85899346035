/* Importing the node modules, child components, services and controllers used 
   inside NikeInternshipHome component */
import MainController from "../../controller/mainController";
import SubFooter from "../../common/subFooter";
import NikeInternshipHeader from "../../common/nikeInternshipHeader";
import { Link } from "react-router-dom";
import ContactUs from "./common/contactUs";
import GuestHeader from "../../common/guestHeader";
import Footer from "../../common/footer";
import NikeInternFooter from "./common/nikeInternFooter";
/* NikeInternshipHome Component initialization */
class NikeInternshipHome extends React.Component {
  /* Initializing objects of its NikeInternshipHome class */
  constructor(props) {
    super(props);
  }
  /* It is invoked immediately before mounting occurs */
  componentWillMount() {
    MainController.getInitialInformation();
  }
  /* It is invoked to return html content */
  render() {
    return (
      <div className="corporateHousing_Updates">
        <GuestHeader />
        <header className="codeconveyHeader cc_image_text_main corporate-h">
          <img
            className="img-responsive"
            src={
              s3BucketUrl_WebsiteImages +
              "SuiteAmerica-Company-Nike-Internship-Banner.jpg"
            }
            width="100%"
            alt=""
          />
          <div
            className="wow fadeInUp img-on-text top30 width500 text-center left50 img-on-text-center company_bannertxt"
            data-wow-delay="0.5s"
          >
            <img
              src={
                s3BucketUrl_WebsiteImages + "SuiteAmerica-Company-Nike-Logo.png"
              }
              className="nike_img"
              width="150px"
              alt=""
            />
            <h1>Congratulations on Your Upcoming Internship</h1>
            <p>
              SuiteAmerica has been selected by Nike to arrange your shared
              housing accommodations.
            </p>
          </div>
        </header>
        <NikeInternshipHeader />
        <div className="quote-section bg-white">
          <div className="container">
            <div
              className=" text-center col-md-10 col-md-offset-1 wow fadeInUp company_p"
              data-wow-delay="0.5s"
            >
              <h2 className="gotham-book mrg-btm-20">
                Nike's #1 Corporate Housing Provider
              </h2>
              <p>
                SuiteAmerica has been providing corporate apartments and
                executive suites nationwide for over twenty five years. We will
                provide a fully furnished one or two bedroom apartment at one of
                our select properties.
              </p>
              <p>
                Are you ready to preview properties available through
                SuiteAmerica for your upcoming Internship?
              </p>
            </div>
          </div>
          <div className="who_weare_text service_ch_new company_p_height">
            <div className="container">
              <div className="row rows">
                <div className="col-md-6 col-sm-6">
                  <div
                    className="wow fadeInUp company_p dual_quote"
                    data-wow-delay="0.5s"
                  >
                    <p>
                      Simply visit our Locations page to see sample communities
                      of what might be available for your temporary stay in
                      Nike's key areas.
                    </p>
                    <Link
                      to="/company/nike-internship/communities"
                      className="box-slide-btn"
                    >
                      VIEW LOCATIONS
                    </Link>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6">
                  <div
                    className="wow fadeInUp company_p dual_quote"
                    data-wow-delay="0.5s"
                  >
                    <p>
                      Fill in the Intern questionnaire so that we can best
                      arrange your temporary accommodations.
                    </p>
                    <Link
                      to="/company/nike-internship/questionnaire"
                      className="box-slide-btn"
                    >
                      INTERN QUESTIONNAIRE
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid bg-grey">
          <div className="container">
            <ContactUs />
          </div>
        </div>
        <div className="container ft-contact-adrs mrg-btm-30 mrg-top-30">
          <NikeInternFooter />
        </div>
        <SubFooter />
        <Footer />
      </div>
    );
  }
}
export default NikeInternshipHome;
