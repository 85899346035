/* Importing the node modules, child components, services and controllers used 
   inside S3BucketImages component */
import $ from "jquery";
import AppController from "../../../controller/appController";
import SessionService from "../../../../services/sessionService";
import FiveStarApiServices from '../../../../services/fiveStarApiServices';
import FiveStarService from '../../../../services/fiveStarService';
import S3Upload from 'react-s3-uploader/s3upload';
//import MapController from "../../../controller/mapController";

/* S3BucketImages Component initialization */
class S3BucketUpload extends React.Component {
  /* Initializing objects of its S3BucketImages class */
  constructor(props) {
    super(props)
    /* S3BucketImages Component State variables Initialization */
    this.state = {
      activeIndex: 2,
      filesLists: [],
      filesList: [],
      requestDetails: [],
      bannerimage: '',
      notesAboutTheStop: 'sample',
      stopError: '',
      donedisable: true,
      loading: false
    }
  }
  /* It is invoked immediately before a component is mounted */
  //    async componentWillMount() {
  //     $('html, body').animate({scrollTop: 0});
  //     AppController.stickyTitles();
  //     const driverInformation= await SessionService.SessionGet({type:'fivestar'});
  //     this.setState({driverManagerInfo:driverInformation})
  //     await this.get_Service_Schedule();
  //  }

  async componentDidMount() {
    $('html, body').animate({ scrollTop: 0 });
    AppController.stickyTitles();
    const driverInformation = await SessionService.SessionGet({ type: 'fivestar' });
    this.setState({ driverManagerInfo: driverInformation })
    //await this.get_Service_Schedule();
  }

  //  async get_Service_Schedule(){
  //    console.log('testfdsfsdg')
  //   this.setState({requestDetails: this.props.requestDetails});
  //  }

  async handleImageChange(type, index, e) {
    e.preventDefault();
    let requestDetails = this.props.requestDetails == undefined ? [] : this.props.requestDetails;
    console.log('requestDetails789', requestDetails)

    this.setState({
      loading: true
    })
    if (this.state.filesList.length <= 3) {
      let reader = new FileReader();
      console.log(reader)
      console.log(e.target.files, 'filessss')
      // const fileIndex = e.target.name.split('-')
      // console.log(fileIndex,'fileIndex')
      const _this = this
      _.forEach(e.target.files, function (file) {

        let uniqueId = requestDetails.UniqueId.replace(/ /g, "_")
        uniqueId = _.toUpper(uniqueId);
        const S3Uploader = new S3Upload({
          files: [file],
          signingUrl: '/s3/sign',
          s3path: requestDetails.type + '/' + uniqueId + '/' + moment().format("MM-DD-YYYY") + '/' + moment().format("MMDDYYYYHMSSS"),
          signingUrlQueryParams: {},
          onProgress: _this.onProgress.bind(_this, type, index),
          onFinishS3Put: _this.onFinish.bind(_this, type, index, file),
          onError: _this.onError.bind(_this, type, index),
          uploadRequestHeaders: { 'x-amz-acl': 'public-read' },
          contentDisposition: file.type,
          server: ''
        })
        console.log(S3Uploader)
      })
    }
    else {
      this.setState({ message: 'Please select only four files', color: 'red' })
      setTimeout(function () {
        this.setState({ message: '', color: '' })
      }.bind(this), 3000);
    }
  }
  /* Progress event for uploading image into s3 bucket */
  async onProgress(type, fileIndex, progress) {
    this.setState({ filesUpload: true })
  }
  /* Error event for uploading image into s3 bucket */
  async onError(type, fileIndex, err) {
    await this.setState({
      donedisable: true,
      loading: false,

    })
  }
  async onFinish(type, fileIndex, FileDetails, info) {
    console.log('filepath')
    const filepath = 'https://suiteam-website.s3.amazonaws.com/' + info.filename;

    await this.setState({ filesUpload: false, donedisable: false, loading: false });
    await this.filesListUpdate(filepath, FileDetails, info, -1)
  }


  async filesListUpdate(filepath, FileDetails, info, dataIndex) {
    if (dataIndex < 0) {
      await this.setState({

        filesLists: _.concat(this.state.filesLists, {
          filepath: filepath,
          FileDetails: FileDetails,
          info: info
        })
      })
    } else if (dataIndex >= 0) {
      await this.setState({
        filesLists: update(this.state.filesLists, {
          $splice: [
            [dataIndex, 1]
          ]
        }, 0)
      })
    }
  }

  async handleSubmit(value, e) {
    e.preventDefault();
    //this.checkValidation();
    let requestDetails = this.props.requestDetails == undefined ? [] : this.props.requestDetails;
    const finalString = this.state.notesAboutTheStop + ',' + this.state.listMissingItems;

    if (this.state.filesLists.length > 0) {
      this.setState({ loading: true, stopError: '' })
      const pstDate = AppController.pstDate()
      const gsrInfo = (requestDetails.GSR == null || requestDetails.GSR == '' || requestDetails.GSR == undefined || requestDetails.GSR == '/') ? '' : requestDetails.GSR

      const gsrEmail = gsrInfo == '' ? [] : gsrInfo.split('/')
      var postObj = {
        typeCode: requestDetails.type,
        tmpCode: requestDetails.TypeCode,
        notes: this.state.notesAboutTheStop,
        missingItems: this.state.listMissingItems,
        changedBy: '',
        enteredBy: this.state.driverManagerInfo.Identifier,
        status: 1,
        UniqueId: requestDetails.UniqueId,
        finalString: finalString,
        orderId: requestDetails.TableId,
        orderType: requestDetails.OrderType,
        HotelName: requestDetails.HotelName,
        HotelAddress: requestDetails.hoteladdress,
        HotelCity: requestDetails.hotelcity,
        HotelState: requestDetails.hotelstate,
        HotelZipcode: requestDetails.hotelzipcode,
        HotelSuiteNo: requestDetails.hotelsuite,
        masterId: requestDetails.mastertableId,
        SuiteNo: requestDetails.SuiteNo,
        sTableId: requestDetails.sTableId,
        community_name: requestDetails.community_name,
        suiteAddress: requestDetails.AptAddr,
        suiteState: requestDetails.LeaseState,
        suiteCity: requestDetails.LeaseCity,
        suiteZipCode: requestDetails.AptZip,
        filesLists: this.state.filesLists,
        createdDate: pstDate,
        address: requestDetails.address,
        state_name: requestDetails.state_name,
        city_name: requestDetails.city_name,
        ZipCode: requestDetails.ZipCode,
        orderGsr: gsrEmail.length > 0 ? gsrEmail[1] : ''
      };
      console.log('postobj1234', postObj)
      const res = {
        error: 0
      }; //await FiveStarApiServices.saveServiceScheduleNotes(postObj);
      if (res.error == '0') {
        jQuery(function ($) {
          $('#uploadFilesData').modal('hide');

        });
        // const resEmail= await  FiveStarService.serviceRequest(postObj)
        // await  FiveStarApiServices.CreateServiceRequestEmailLog(resEmail.responseData)
        //await this.get_Service_Schedule()
        this.setState({ stopError: '', listMissingItemsError: '', notesAboutTheStop: '', listMissingItems: '', loading: false, donedisable: true });

        // if(value == 1) {
        this.setState({ successMessage: '', filesLists: [] });
        jQuery(function ($) {
          $('#successModal1').modal('show');
          setTimeout(function () {
            $('#successModal1').modal('hide');
          }, 1500);
        });
        // }
        // if(value == 2) {
        //   const _this = this
        //   this.setState({successMessage: '',filesLists:[]});
        //   jQuery(function($) {
        //     $('#successModal1').modal('show');
        //     setTimeout(function(){
        //       $('#successModal1').modal('hide');
        //       _this.stopOrder();
        //     },700);
        //   });
        // }      
      }
      else {
        this.setState({ message: 'Email Sending Failed', color: 'red' })
      }
    } else {
      this.setState({ loading: true, stopError: 'Upload files is required' })
    }
  }

  async handleClose() {
    jQuery(function ($) {
      $('#uploadFilesData').modal('hide');
    });
    await this.setState({
      stopError: '',
      loading: false,
      donedisable: true
    })
  }
  render() {

    return (
      <div>
        <div className="modal fade view_more_img bd-example-modal-lg" id="uploadFilesData" role="dialog">
          <div className="modal-dialog modal-lg">
            <div className="modal-content rm-border-radius">

              <div className="modal-body more-photos-loader">
                <button type="button" className="close btn"
                  //data-dismiss="modal" 
                  aria-label="">
                  <i className="bi bi-x" onClick={() => this.handleClose()}></i>
                </button>

                <input type="file" name="file-7[]" placeholder="jjj" id="file-7" className="inputfile inputfile-6" data-multiple-caption="{count} files selected" multiple onChange={(e) => this.handleImageChange(2, 0, e)} />
                <label htmlFor="file-7"><span>Upload Attachment (Doc, PDF, JPG, ect.)</span> <strong>
                  <img src={s3BucketUrl_WebsiteImages + "SuiteAmerica-Form-Cloud-Upload.png"} width="45" alt="" />
                </strong></label>
                {this.state.filesLists.length > 0 ?
                  <div className="col-sm-12">
                    {this.state.filesLists.map((row, index) => {
                      return (
                        <div className="intra_uploadfile" key={index}>
                          <ul>
                            <li><span>File Name</span></li>
                            <li><span>File Size</span></li>
                          </ul>
                          <ul>
                            <li>{row.FileDetails.name}</li>
                            <li>{row.FileDetails.size} kb</li>
                          </ul>
                        </div>)
                    })}
                  </div> : null}
                {this.state.loading == true ?
                  <div className="panel-body_loader" >
                    <span style={{
                      fontSize: '25px', width: '100%',
                      margin: 'auto', textAlign: 'center'
                    }}>
                      <i className="fa fa-spinner fa-spin" /> please wait ...!</span>
                  </div> : null}
                <div style={{ 'color': 'red' }}>{this.state.stopError}</div>
                <button type="button" className="btn star_button"
                  disabled={this.state.donedisable} onClick={(e) => this.handleSubmit(1, e)}>DONE</button>
              </div>

            </div>
          </div>
        </div>
        <div className="modal fade savesearch" id="successModal1" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content rm-border-radius">
              <div className="modal-header">
                <button type="button" className="close btn" data-dismiss="modal" aria-label="">
                  <i className="bi bi-x"></i>
                </button>
              </div>
              <div className="modal-body">
                <h2>Thank You!</h2>
                <p>Files has been uploaded successfully</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default S3BucketUpload;