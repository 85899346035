/* Importing the node modules, child components, services and controllers used 
   inside IntranetCustomRequestfive component */
   import GuestHeader from '../../common/guestHeader';
   import LumentumHeader from "../../components/lumentum/common/lumentumHeader";
   /* IntranetCustomRequestfive Component initialization */
   class BookingRedirectionLinks extends React.Component{
       /* Initializing objects of its IntranetCustomRequestfive class */
       constructor(props) {
           super(props)
         }
   /* It is invoked to return html content */   
   CompanysList(companyName){
     if(companyName == 'lumentum'){
        return <LumentumHeader/>
     }
    // switch(companyName){
    //     case 'lumentum':
    //         return <LumentumHeader/>
    //     break;
    // }
   }   
   render(){
    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
      })
       const url=this.props.match.path;
       const companyName=this.props.match.params.companyName;
       const guestEmail =localStorage.getItem('guestEmail')
       return(
        
           <div>
               <GuestHeader/>
           {this.CompanysList(companyName)}
<div className="inet_custom_last">
{url=='/company/:companyName/company_booking_quote' ? <div className="container">
   <h1 className="wow animated zoomIn">Thank you, Your Suite Request is placed.</h1>
   <p className="wow animated fadeInUp">You will receive the confirmation of your stay shortly!</p>
</div>:''}

{url=='/company/:companyName/company_booking_orderFailed' ? <div className="container">
   <h1 className="wow animated zoomIn">Your Order Booking Falied </h1>
   <p className="wow animated fadeInUp">Please Contact us at +1 800.367.9501</p>
</div>:''}

{url=='/company/:companyName/company_booking_guestsuccess' ? <div className="container">
   <h1 className="wow animated zoomIn">Thank you, Your Reservation is confirmed.</h1>
   <p className="wow animated fadeInUp">Please Sign Up for guest portal
   <i  className="fa fa-info-circle mrg-left-20" data-toggle="tooltip" data-placement="bottom" title="Guest Portal provides information & tools to help you with your stay. Guest portal is home for Guest Books, Deals, Reservation Information. You can also submit Service requests through Guest portal. Simply said - It is one stop to manage your stay with us"></i>
   </p>
   <a href={"http://ec2-52-53-162-243.us-west-1.compute.amazonaws.com/register.cfm/?guestemailinfo="+guestEmail}><button className="btn location_btn mrg-btm-50" type="button" >Sign Up <i className="fa fa-arrow-right"></i> </button></a>
</div>:''}
</div>
</div> 
       );
   }
   }
   export default BookingRedirectionLinks;