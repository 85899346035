import React, { useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";

import moment from "moment";
import CommunityImagesWhiteModal from "../../modals/CommunityImagesWhiteModal";
import { encrypt } from "../../../../common/utils";
import { capitalizeFirstLetter, extractCityName } from "../../common/utils";

const MoreDetailsAndBookView = ({
  changeToSearchListView,
  propertyMoreDetailsData,
}) => {
  let {
    checkIn,
    checkOut,
    selectedCommunity,
    selectedRoomType: defaultRoomType,
  } = propertyMoreDetailsData;

  const [communityALLImages, setCommunityALLImages] = useState([]);
  const [selectedRoomType, setSelectedRoomType] = useState(
    selectedCommunity.Avail1X
      ? "1X"
      : selectedCommunity.Avail2X
      ? "2X"
      : selectedCommunity.Avail3X
      ? "3X"
      : selectedCommunity.STU
      ? "STU"
      : "1X"
  );
  const [dailyrate, setDailyrate] = useState(selectedRoomType.dailyrate);

  const roomTypesList = [
    {
      key: "STU",
      BookedType: "STU",
      name: "Studio",
      imageSrc:
        "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/studiobed.png",
    },
    {
      key: "Avail1X",
      BookedType: "1X",
      name: "1 Bedroom",
      imageSrc:
        "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/singlebedroom.png",
    },
    {
      key: "Avail2X",
      BookedType: "2X",
      name: "2 Bedroom",
      imageSrc:
        "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/doublebedroom.png",
    },
    {
      key: "Avail3X",
      BookedType: "3X",
      name: "3 Bedroom",
      imageSrc:
        "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/triplebedroom.png",
    },
  ];

  let finalCheckIn = moment(checkIn).format("DD/MM/YYYY");
  let finalCheckOut = moment(checkOut).format("DD/MM/YYYY");
  let communityAmenities = stringToList(selectedCommunity.complexamenities);
  let appartmentAmenities = stringToList(selectedCommunity.unitamenities);

  function stringToList(str) {
    if (!str) return [];
    return str.split(",");
  }

  function handleOpenCarousel() {}

  function changeActiveRoomType(roomType) {
    rateChange(roomType);
    setSelectedRoomType(roomType.BookedType);
  }

  function rateChange(roomType) {
    let dailyrate;
    if (roomType.BookedType === "STU") {
      dailyrate = selectedCommunity.sizeSTURate;
    } else if (roomType.BookedType === "1X") {
      dailyrate = selectedCommunity.size1xRate;
    } else if (roomType.BookedType === "2X") {
      dailyrate = selectedCommunity.size2xRate;
    } else if (roomType.BookedType === "3X") {
      dailyrate = selectedCommunity.size3xRate;
    }
    setDailyrate(dailyrate);
  }

  function mapView() {
    let locations = {
      lat: Number(selectedCommunity.latitude),
      lng: Number(selectedCommunity.longitude),
    };
    var map = new google.maps.Map(document.getElementById("map"), {
      zoom: 7,
      center: locations,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    });
    const marker = new google.maps.Marker({
      position: locations,
      map: map,
      title: "Marker Title", // You can customize the marker's title
    });
  }

  function streetView() {
    var panorama = new google.maps.StreetViewPanorama(
      document.getElementById("street-view"),
      {
        position: {
          lat: Number(selectedCommunity.latitude),
          lng: Number(selectedCommunity.longitude),
        },
        pov: { heading: 165, pitch: 0 },
        zoom: 1,
      }
    );
    console.log(panorama);
  }

  function objectToQueryString(obj) {
    const params = new URLSearchParams();

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        params.append(key, obj[key]);
      }
    }

    return params.toString();
  }

  function bookNowData(selectedCommunity, selectedRoomType) {
    // updatePropertyMoreDetailsData({
    //   selectedCommunity: selectedCommunity,
    //   selectedRoomType: selectedRoomType,
    // });

    const queryParamsObject = {
      ...selectedCommunity,
      selectedRoomType,
      checkIn: moment(propertyMoreDetailsData.checkIn).format("DD/MM/YYYY"),
      checkOut: moment(propertyMoreDetailsData.checkOut).format("DD/MM/YYYY"),
    };
    let queryString = objectToQueryString(queryParamsObject);
    const baseUrl = "/reserve-now";

    queryString = encrypt(queryString);
    window.open(
      `${baseUrl}${baseUrl.includes("?") ? "&" : "?"}${queryString}`,
      "_blank"
    );
  }

  useEffect(() => {
    if (selectedCommunity) {
      let images = [
        ...stringToList(selectedCommunity.compleximages),
        ...stringToList(selectedCommunity.interiorimages),
      ];
      setCommunityALLImages(images);
    }
  }, [propertyMoreDetailsData.selectedCommunity]);

  return (
    <>
      <a
        href="#"
        className="btn btn-dark mrg-top-10 mrg-btm-10"
        onClick={() => changeToSearchListView()}
      >
        <i className="fa fa-chevron-left mrg-right-10"></i>
        <span>Back</span>
      </a>

      {/* images block */}
      {communityALLImages.length > 0 ? (
        <div className="SAweb_updateUi">
          <section className="SAweb_banner-3 SAweb_pdBanner bg-white pt-5">
            <div className="container">
              <div className="box mb-3">
                <div className="item">
                  <img src={communityALLImages[0]} alt="" />
                </div>
                <div className="item">
                  <img src={communityALLImages[1]} alt="" />
                </div>
                <div className="item">
                  <img src={communityALLImages[2]} alt="" />
                  {communityALLImages.length > 2 ? (
                    <button
                      className="btn photos"
                      onClick={() => handleOpenCarousel()}
                      type="button"
                      data-toggle="modal"
                      data-target="#fullmodel-Carousel1"
                    >
                      {
                        communityALLImages.slice(2, communityALLImages.length)
                          .length
                      }{" "}
                      More Photos
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : null}

      {/* information and book now block */}
      <div className="SAweb_updateUi">
        <section className="SAweb_banner-3 SAweb_pdBanner bg-white pb-5">
          <div className="container travel-sec">
            {/* left block */}
            <div className="col-md-8 col-sm-8">
              <div className="community-item community-item-full">
                <div className="community-item-dtls">
                  <h5>{selectedCommunity.community_name}</h5>
                  <p>
                    {selectedCommunity.address},{" "}
                    {capitalizeFirstLetter(
                      extractCityName(selectedCommunity.city_name)
                    )}
                    , {capitalizeFirstLetter(selectedCommunity.state_name)}
                  </p>
                  <div className="pull-left text-left">
                    <p className="distance">
                      <img
                        src="https://s3-us-west-1.amazonaws.com/suiteam-website/Travel-Booking/SuiteAmerica-Travel-Booking-Map.png"
                        alt=""
                      />
                      <strong className="me-2">
                        {selectedCommunity.distance}
                        {"  "}
                      </strong>{" "}
                      Miles away
                    </p>
                  </div>
                  {selectedCommunity.walkScore != "" &&
                  selectedCommunity.walkScore != null &&
                  selectedCommunity.walkScore != undefined ? (
                    <div className="walkscore pull-right">
                      <div className="arrow_box">
                        {selectedCommunity.walkScore}
                      </div>
                      <span>Walk Score</span>
                    </div>
                  ) : null}
                </div>
                <div className="clearfix"></div>
                <div className="clearfix"></div>
              </div>

              <div className="community-tabs">
                <ul className="nav nav-tabs" role="tablist">
                  <li role="presentation" className="active">
                    <a
                      href="#one"
                      aria-controls="home"
                      role="tab"
                      data-toggle="tab"
                    >
                      <img src="" alt="" />
                      <i className="icon icon-454"></i>Photos
                    </a>
                  </li>
                  <li role="presentation" onClick={mapView}>
                    <a
                      href="#two"
                      aria-controls="profile"
                      role="tab"
                      data-toggle="tab"
                    >
                      <i className="icon icon-432"></i>Map
                    </a>
                  </li>
                  <li role="presentation" onClick={streetView}>
                    <a
                      href="#three"
                      aria-controls="messages"
                      role="tab"
                      data-toggle="tab"
                    >
                      <i className="icon icon-425"></i>Street view
                    </a>
                  </li>
                </ul>
                <div className="tab-content travel-booking-slider">
                  <div role="tabpanel" className="tab-pane active" id="one">
                    {communityALLImages.length > 0 ? (
                      <OwlCarousel
                        // ref={(inst) => (this.slider = inst)}
                        {...{
                          loop: false,
                          nav: true,
                          dots: true,
                          items: 1,
                          navContainerClass: "owl-buttons",
                          navText: [
                            "<img src='https://s3-us-west-1.amazonaws.com/suiteam-website/Website-Images/SuiteAmerica-Carousel-Left-Arrow.png'>",
                            "<img src='https://s3-us-west-1.amazonaws.com/suiteam-website/Website-Images/SuiteAmerica-Carousel-Right-Arrow.png'>",
                          ],
                          responsive: {
                            320: { items: 1, nav: true, loop: false },
                            767: { items: 1, nav: true, loop: false },
                            992: { items: 1, nav: true, loop: false },
                            1199: { items: 1, nav: true, loop: false },
                          },
                        }}
                      >
                        {communityALLImages.map((row, index) => {
                          return (
                            <div className="item" key={index}>
                              <img
                                className="img-responsive"
                                src={row}
                                alt={row}
                              />
                            </div>
                          );
                        })}
                      </OwlCarousel>
                    ) : null}
                  </div>
                  <div role="tabpanel" className="tab-pane" id="two">
                    <div className="lc_map">
                      <div className="map-alignment">
                        <div id="map" className="map-alignment"></div>
                      </div>
                    </div>
                  </div>
                  <div role="tabpanel" className="tab-pane" id="three">
                    <div className="lc_map">
                      <div className="map-alignment">
                        <div id="street-view" className="map-alignment"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* right block */}
            <div className="col-md-4 col-sm-4">
              <h4 className="widget-title">Booking Information</h4>
              <div className="widget">
                <div className="widget_body">
                  <ul className="booking_info_list">
                    <li>
                      <span>Check In</span>
                      <h5>{finalCheckIn}</h5>
                    </li>
                    <li>
                      <span>Check Out</span>
                      <h5>{finalCheckOut}</h5>
                    </li>
                  </ul>

                  {/* 1 bed 2 bed 3 bed studio */}
                  <ul className="bedroom_list list-inline">
                    {roomTypesList.map((roomType, index) => {
                      return selectedCommunity[roomType.key] ? (
                        <li
                          className="active"
                          key={index}
                          onClick={() => changeActiveRoomType(roomType)}
                        >
                          <img src={roomType.imageSrc} /> <p>{roomType.name}</p>
                          <span
                            className={`badge studio ${
                              selectedRoomType === roomType.BookedType
                                ? "active"
                                : ""
                            }`}
                          >
                            {selectedCommunity[roomType.key]}
                          </span>
                        </li>
                      ) : null;
                    })}
                    {!selectedCommunity.STU &&
                    !selectedCommunity.Avail1X &&
                    !selectedCommunity.Avail2X &&
                    !selectedCommunity.Avail3X ? (
                      <li className="active">
                        <img src={roomTypesList[0].imageSrc} /> <p>No data</p>
                        <span className="badge studio">0</span>
                      </li>
                    ) : null}
                  </ul>
                  <a
                    href="#"
                    onClick={() =>
                      bookNowData(selectedCommunity, selectedRoomType)
                    }
                  >
                    <button className="btn btn-block btn-primary btn-dark">
                      Book Now
                    </button>
                  </a>
                </div>
              </div>

              <h4 className="widget-title">Amenities</h4>
              <Amenities
                communityAmenities={communityAmenities}
                appartmentAmenities={appartmentAmenities}
              />
            </div>
          </div>
        </section>
      </div>
      <CommunityImagesWhiteModal
        communityALLImages={communityALLImages}
        community_name={selectedCommunity.community_name}
      />
    </>
  );
};

export default MoreDetailsAndBookView;

const Amenities = ({ communityAmenities, appartmentAmenities }) => {
  return (
    <div className="widget">
      <div className="widget_header">
        <ul className="nav nav-tabs community_tabs" role="tablist">
          <li role="presentation" className="active">
            <a
              href="#community"
              aria-controls="home"
              role="tab"
              data-toggle="tab"
            >
              Community
            </a>
          </li>
          <li role="presentation">
            <a
              href="#appartments"
              aria-controls="profile"
              role="tab"
              data-toggle="tab"
            >
              Apartments
            </a>
          </li>
        </ul>
      </div>
      <div className="widget_body">
        <div className="tab-content">
          <div role="tabpanel" className="tab-pane active" id="community">
            {communityAmenities.length != 0 ? (
              <ul className="amenities_list">
                {communityAmenities.map((row, index) => {
                  return (
                    <li key={index}>
                      <img
                        width="40"
                        src={
                          s3BucketUrl +
                          "Amenities/" +
                          row.replace(/[^A-Z0-9]+/gi, "") +
                          ".png"
                        }
                      />{" "}
                      {row}
                    </li>
                  );
                })}
              </ul>
            ) : null}
          </div>
          <div role="tabpanel" className="tab-pane" id="appartments">
            {appartmentAmenities.length > 0 ? (
              <ul className="amenities_list">
                {appartmentAmenities.map((row, index) => {
                  return (
                    <li key={index}>
                      <img
                        width="40"
                        src={
                          s3BucketUrl +
                          "Amenities/" +
                          row.replace(/[^A-Z0-9]+/gi, "") +
                          ".png"
                        }
                      />{" "}
                      {row}
                    </li>
                  );
                })}
              </ul>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
