/* Importing the node modules, child components, services and controllers used 
   inside ListView component */  
   import ListViewModal from './modals/listViewModal';
   import Community from './teslaCommunity';
   import OwlCarousel from 'react-owl-carousel';
   import CommunityBookNowModal from '../../controller/communityBookNowModal';
   /* ListView Component initialization */
   class ListView extends React.Component {
     /* Initializing objects of its ListView class */
     constructor(props) {
       super(props)
       this.state={
        calendarStatus: false
       }
       /* ListView Component State variables Initialization */
       this.ListViewModal = new ListViewModal(this, props)
       this.state = Object.assign(this.ListViewModal.state)
       this.loadMore = this.loadMore.bind(this);
       this.reserveInfo=this.reserveInfo.bind(this)
       this.calendarClose = this.calendarClose.bind(this);
       
     }
   /**
    * Click on load more we get morethan 10 records
    * @param {*more,less} limit 
    */
     async loadMore(){
        this.props.loadMore()
     }
     async reserveInfo(data) {
      await this.setState({calendarStatus: true,selectedrow:data})
     }
     async calendarClose() {
      await this.setState({calendarStatus: false })
    }
     /**
    * get Detail information about single community like amenities,map,interior images
    * @param {communityid,row,index} limit 
    */

     /**
      * when the person is login then only insert his favourites
      * @param {*communityid} data 
      */
   /**
    * when we click on the moreInfo button detail insormation about the 
    * @param {*community details } data 
    * @param {*} favouriteCheck 
    */
     async moreInfo(data, favouriteCheck) {
      this.ListViewModal.moreInfo(data,favouriteCheck)
     }
   
    async calendarInfo(data){
      this.props.calendarInfo(data)
     }    
     async viewPlaces(row){
     await this.setState({latitude:row.latitude,longitude:row.longitude,communityName:row.community_name})
      }
     /* It is invoked to return html content */
     render() {
      
       const localStorageVal = (localStorage.getItem('_u_web') == null)
         ? "#mysave1"
         : null
       const communityData = this.props.communityData
    
       return (
         <div>
           {(this.props.communityData == 0 && this.props.isLoading)&& <h1 style={{'textAlign': 'center','color': '#000000'}}>No Results Found</h1>}
   
             {this.props.isLoading ? null:
             <div className="tab-content travel-booking-slider tesla">
                
                 <div className="container-fluid">
 <OwlCarousel  className="GreatSchools-community" {...{ loop: false, nav:true, dots: true, items: 1, navContainerClass: 'owl-buttons', navText:this.props.communityData || this.props.width<900 ? ["<i class='glyphicon glyphicon-menu-left'></i>", "<i class='glyphicon glyphicon-menu-right'></i>"]:['',''], autoheight:'true',refreshClass:'owl-refresh',loadingClass: 'owl-loaded',responsive:{ 320:{ items:1, nav:true, loop:false }, 767:{ items:2, nav:true, loop:false }, 992:{ items:2, nav:true, loop:false }, 1199:{ items:4, nav:true, loop:false ,autoHeight: true } }}}>  
           {communityData.map((row, index) => {
             const favouriteCheck = _.find(this.props.userFavourites, function (o) {
               return o.ComplexUId == row.community_id;
             })
              console.log(favouriteCheck)
             return (
                 
                
               <Community 
               key={row.community_id}
               index = {index}
               row={row}
               localStorageVal= {localStorageVal}
               companyName={this.state.company}
               favouriteCommunity={this.props.favouriteCommunity}
              unFavouriteCommunity={this.props.unFavouriteCommunity}
              communityData={this.props.communityData}
              reserveInfo={this.reserveInfo}
               />
               
           )
            
           })}

</OwlCarousel>
</div>

{this.state.calendarStatus
          ? <CommunityBookNowModal calendarClose={this.calendarClose} CommunityDetails={this.state.selectedrow}/>
          : ''}
           </div>}
           {this.props.loadMoreButton? <div className="panel-body_loader loadmore" > <span style={{fontSize: '30px', width: '100%', margin: 'auto', textAlign: 'center'}}><i className="fa fa-spinner fa-spin"/> please wait ...!</span>
                 </div> : '' }
           
            
            </div>
   
           
   
       );
     }
   }
   export default ListView;
   