/* Importing the node modules, child components, services and controllers used 
   inside NikeInternshipCommunities component */
import MainController from "../../controller/mainController";

import SubFooter from "../../common/subFooter";
import Footer from "../../common/footer";
import Location from "../location/locations";

import AppleInternShipHeader from "./common/AppleInternShipHeader";
import AppleInternFooter from "./common/AppleInternFooter";
import ContactUs from "./common/contactUs";
/* NikeInternshipCommunities Component initialization */
class AppleInternshipCommunities extends React.Component {
  /* Initializing objects of its NikeInternshipCommunities class */
  constructor(props) {
    super(props);
    this.state = {
      companyname: "NikeIntern",
    };
  }
  /* It is invoked immediately before mounting occurs */
  componentWillMount() {
    MainController.getInitialInformation();
  }
  /* It is invoked to return html content */
  render() {
    return (
      <div className="corporateHousing_Updates">
        <header className="codeconveyHeader cc_image_text_main corporate-h">
          <img
            className="img-responsive"
            src={
              s3BucketUrl_WebsiteImages +
              "SuiteAmerica-Company-Nike-Internship-OR.jpg"
            }
            width="100%"
            alt=""
          />
          <div
            className="wow fadeInUp img-on-text top30 width600 text-center left50 img-on-text-center"
            data-wow-delay="0.5s"
          >
            <h1>Apple Communities</h1>
            <p>Conveniently Located Corporate Housing</p>
          </div>
        </header>
        <AppleInternShipHeader />
        <div className="container-fluid nike company_section">
          <div className="container text-center">
            <p>
              {" "}
              Your actual apartment will be selected by SuiteAmerica
              approximately 30 days prior to your arrival date. SuiteAmerica is
              pleased to offer fully furnished apartments for you during your
              internship with Apple in Beaverton, Oregon. We provide all
              furniture, housewares, linens, utilities, wireless internet and
              monthly housekeeping.
            </p>
            <div className="who_weare_text service_ch_new company_p cm_Rates">
              <h2 className="">Community Rates</h2>
              <div className="row d-flex justify-content-center">
                <div className="col-md-6 col-sm-6">
                  <div
                    className="dual_quote list_block dual_quote_md wow fadeInUp"
                    data-wow-delay="0.5s"
                  >
                    <h3>SUBURB COMMUNITIES</h3>
                    <p>Beaverton/Hillsboro/Portland Suburb Communities</p>
                    <div className="">
                      <div>
                        1x1 (single or double occupancy): $154/day total
                      </div>

                      <div>2x2 (double or quad occupancy): $196/day total</div>
                      <div>
                        3x2 (single or multiple occupancy): $233/day total
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p className="small-font">
              *In order to get the best rate available, please book as early as
              possible so that SuiteAmerica can pair you with a roommate and an
              apartment. Pricing and availability is on a first come, first
              served basis. Please note that once our apartment inventory is
              full or if it’s within 30 days from your arrival, SuiteAmerica
              will be unable to provide any further housing options.
            </p>
          </div>
        </div>

        <div className="container-fluid nike text-center">
          <div className="container">
            <p>Click on the properties below for more information.</p>
            <h2>Communities</h2>
          </div>
        </div>

        <div className="rm_header">
          <Location companyName={this.state.companyname} props={this.props} />
        </div>
        <div className="container-fluid bg-grey">
          <div className="container">
            <ContactUs />
          </div>
        </div>
        <div className="container ft-contact-adrs mrg-btm-30 mrg-top-30">
          <AppleInternFooter />
        </div>
        <SubFooter />
        <Footer />
      </div>
    );
  }
}
export default AppleInternshipCommunities;
